"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611105;
const slug = exports.slug = 'reference/cms/hubl/filters';
const title = exports.title = 'HubL-Filter';
const name = exports.name = 'HubL-Filter';
const metaDescription = exports.metaDescription = 'HubL-Filterliste und Beispiele für HubSpot CMS-Entwickler.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "abs",
  "label": "abs",
  "parent": null
}, {
  "depth": 0,
  "id": "add",
  "label": "add",
  "parent": null
}, {
  "depth": 0,
  "id": "attr",
  "label": "attr",
  "parent": null
}, {
  "depth": 0,
  "id": "batch",
  "label": "batch",
  "parent": null
}, {
  "depth": 0,
  "id": "between_times",
  "label": "between_times",
  "parent": null
}, {
  "depth": 0,
  "id": "bool",
  "label": "bool",
  "parent": null
}, {
  "depth": 0,
  "id": "capitalize",
  "label": "capitalize",
  "parent": null
}, {
  "depth": 0,
  "id": "center",
  "label": "center",
  "parent": null
}, {
  "depth": 0,
  "id": "convert_rgb",
  "label": "convert_rgb",
  "parent": null
}, {
  "depth": 0,
  "id": "cut",
  "label": "cut",
  "parent": null
}, {
  "depth": 0,
  "id": "datetimeformat-veraltet",
  "label": "datetimeformat (veraltet)",
  "parent": null
}, {
  "depth": 0,
  "id": "default",
  "label": "default",
  "parent": null
}, {
  "depth": 0,
  "id": "dictsort",
  "label": "dictsort",
  "parent": null
}, {
  "depth": 0,
  "id": "difference",
  "label": "difference",
  "parent": null
}, {
  "depth": 0,
  "id": "divide",
  "label": "divide",
  "parent": null
}, {
  "depth": 0,
  "id": "divisible",
  "label": "divisible",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_html",
  "label": "escape_html",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_attr",
  "label": "escape_attr",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_jinjava",
  "label": "escape_jinjava",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_js",
  "label": "escape_js",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_url",
  "label": "escape_url",
  "parent": null
}, {
  "depth": 0,
  "id": "escapejson",
  "label": "escapejson",
  "parent": null
}, {
  "depth": 0,
  "id": "filesizeformat",
  "label": "filesizeformat",
  "parent": null
}, {
  "depth": 0,
  "id": "first",
  "label": "first",
  "parent": null
}, {
  "depth": 0,
  "id": "float",
  "label": "float",
  "parent": null
}, {
  "depth": 0,
  "id": "forceescape",
  "label": "forceescape",
  "parent": null
}, {
  "depth": 0,
  "id": "format",
  "label": "format",
  "parent": null
}, {
  "depth": 0,
  "id": "format_currency-veraltet",
  "label": "format_currency (veraltet)",
  "parent": null
}, {
  "depth": 0,
  "id": "format_currency_value",
  "label": "format_currency_value",
  "parent": null
}, {
  "depth": 0,
  "id": "format_date",
  "label": "format_date",
  "parent": null
}, {
  "depth": 0,
  "id": "format_datetime",
  "label": "format_datetime",
  "parent": null
}, {
  "depth": 0,
  "id": "format_time",
  "label": "format_time",
  "parent": null
}, {
  "depth": 0,
  "id": "fromjson",
  "label": "fromjson",
  "parent": null
}, {
  "depth": 0,
  "id": "geo_distance",
  "label": "geo_distance",
  "parent": null
}, {
  "depth": 0,
  "id": "groupby",
  "label": "groupby",
  "parent": null
}, {
  "depth": 0,
  "id": "indent",
  "label": "indent",
  "parent": null
}, {
  "depth": 0,
  "id": "int",
  "label": "int",
  "parent": null
}, {
  "depth": 0,
  "id": "intersect",
  "label": "intersect",
  "parent": null
}, {
  "depth": 0,
  "id": "ipaddr",
  "label": "ipaddr",
  "parent": null
}, {
  "depth": 0,
  "id": "join",
  "label": "join",
  "parent": null
}, {
  "depth": 0,
  "id": "last",
  "label": "last",
  "parent": null
}, {
  "depth": 0,
  "id": "length",
  "label": "length",
  "parent": null
}, {
  "depth": 0,
  "id": "list",
  "label": "list",
  "parent": null
}, {
  "depth": 0,
  "id": "log",
  "label": "log",
  "parent": null
}, {
  "depth": 0,
  "id": "lower",
  "label": "lower",
  "parent": null
}, {
  "depth": 0,
  "id": "map",
  "label": "map",
  "parent": null
}, {
  "depth": 0,
  "id": "md5",
  "label": "md5",
  "parent": null
}, {
  "depth": 0,
  "id": "minus_time",
  "label": "minus_time",
  "parent": null
}, {
  "depth": 0,
  "id": "multiply",
  "label": "multiply",
  "parent": null
}, {
  "depth": 0,
  "id": "plus_time",
  "label": "plus_time",
  "parent": null
}, {
  "depth": 0,
  "id": "pprint",
  "label": "pprint",
  "parent": null
}, {
  "depth": 0,
  "id": "random",
  "label": "random",
  "parent": null
}, {
  "depth": 0,
  "id": "regex_replace",
  "label": "regex_replace",
  "parent": null
}, {
  "depth": 0,
  "id": "reject",
  "label": "reject",
  "parent": null
}, {
  "depth": 0,
  "id": "rejectattr",
  "label": "rejectattr",
  "parent": null
}, {
  "depth": 0,
  "id": "render",
  "label": "render",
  "parent": null
}, {
  "depth": 0,
  "id": "replace",
  "label": "replace",
  "parent": null
}, {
  "depth": 0,
  "id": "reverse",
  "label": "reverse",
  "parent": null
}, {
  "depth": 0,
  "id": "root",
  "label": "root",
  "parent": null
}, {
  "depth": 0,
  "id": "round",
  "label": "round",
  "parent": null
}, {
  "depth": 0,
  "id": "safe",
  "label": "safe",
  "parent": null
}, {
  "depth": 0,
  "id": "sanitize_html",
  "label": "sanitize_html",
  "parent": null
}, {
  "depth": 0,
  "id": "select",
  "label": "select",
  "parent": null
}, {
  "depth": 0,
  "id": "selectattr",
  "label": "selectattr",
  "parent": null
}, {
  "depth": 0,
  "id": "shuffle",
  "label": "shuffle",
  "parent": null
}, {
  "depth": 0,
  "id": "slice",
  "label": "slice",
  "parent": null
}, {
  "depth": 0,
  "id": "sort",
  "label": "sort",
  "parent": null
}, {
  "depth": 0,
  "id": "split",
  "label": "split",
  "parent": null
}, {
  "depth": 0,
  "id": "string",
  "label": "string",
  "parent": null
}, {
  "depth": 0,
  "id": "striptags",
  "label": "striptags",
  "parent": null
}, {
  "depth": 0,
  "id": "strtotime",
  "label": "strtotime",
  "parent": null
}, {
  "depth": 0,
  "id": "sum",
  "label": "sum",
  "parent": null
}, {
  "depth": 0,
  "id": "symmetric_difference",
  "label": "symmetric_difference",
  "parent": null
}, {
  "depth": 0,
  "id": "title",
  "label": "title",
  "parent": null
}, {
  "depth": 0,
  "id": "tojson",
  "label": "tojson",
  "parent": null
}, {
  "depth": 0,
  "id": "trim",
  "label": "trim",
  "parent": null
}, {
  "depth": 0,
  "id": "truncate",
  "label": "truncate",
  "parent": null
}, {
  "depth": 0,
  "id": "truncatehtml",
  "label": "truncatehtml",
  "parent": null
}, {
  "depth": 0,
  "id": "unescape_html",
  "label": "unescape_html",
  "parent": null
}, {
  "depth": 0,
  "id": "union",
  "label": "union",
  "parent": null
}, {
  "depth": 0,
  "id": "unique",
  "label": "unique",
  "parent": null
}, {
  "depth": 0,
  "id": "unixtimestamp",
  "label": "unixtimestamp",
  "parent": null
}, {
  "depth": 0,
  "id": "upper",
  "label": "upper",
  "parent": null
}, {
  "depth": 0,
  "id": "urlencode",
  "label": "urlencode",
  "parent": null
}, {
  "depth": 0,
  "id": "urldecode",
  "label": "urldecode",
  "parent": null
}, {
  "depth": 0,
  "id": "urlize",
  "label": "urlize",
  "parent": null
}, {
  "depth": 0,
  "id": "wordcount",
  "label": "wordcount",
  "parent": null
}, {
  "depth": 0,
  "id": "wordwrap",
  "label": "wordwrap",
  "parent": null
}, {
  "depth": 0,
  "id": "xmlattr",
  "label": "xmlattr",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      strong: "strong",
      a: "a",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubL-Filter"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filter beeinflussen die endgültige Ausgabe Ihres HubL-Codes. Sie können auf verschiedene HubL-Anweisungen und -Ausdrücke angewendet werden, um das vom Server ausgegebene Template-Markup zu ändern."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die grundlegende Syntax eines Filters ist ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|filtername"
      }), ". Der Filter wird direkt nach der Anweisung oder dem Ausdruck innerhalb seiner Trennzeichen eingefügt. Einige Filter haben zusätzliche Parameter, die in Klammern hinzugefügt werden können. Die grundlegende Syntax eines Filters mit einem String, einer Zahl und einem booleschen Parameter lautet: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|filtername(\"stringParameter\", 10, true)"
      }), ". Beachten Sie, dass String-Parameter in Anführungszeichen geschrieben werden sollten. Beachten Sie auch, dass HubL-Filter einen Alias haben, der für denselben Zweck wie der primäre Filter verwendet werden kann."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der folgende Artikel enthält alle unterstützten HubL-Filter."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Sie können HubL-Filter auf ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/personalize-your-content",
          children: "Personalisierungstoken"
        }), " wie Kontakt- und Unternehmens-Token auf HubSpot CMS- und Blog-Seiten anwenden, jedoch ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " in E-Mails."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "abs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ruft den Absolutwert einer Zahl ab. Sie können diesen Filter verwenden, um sicherzustellen, dass eine Zahl positiv ist."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_number = -53 %}\n{{ my_number|abs }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "add"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Addiert einen numerischen Wert zu einem anderen numerischen Wert. Dieser Filter funktioniert genauso wie der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#math",
        children: "Operator +"
      }), ". Der Parameter in Klammern ist der Summand, den Sie mit Ihrem numerischen Ausgangswert kombinieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 40 %}\n{{ my_num|add(13) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "attr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gibt das Attribut eines Dictionarys wieder. Dieser Filter entspricht dem Ausgeben einer Variablen, die in einem Dictionary vorhanden ist, z. B. content.absolute_url."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt an, welches Attribut gedruckt werden soll"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content|attr(\"absolute_url\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "https://developers.hubspot.com/docs/cms/hubl/filters\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "batch"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein batch-Filter fasst Elemente innerhalb einer Sequenz zusammen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "linecount"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Anzahl der in den Batch aufzunehmenden Elemente"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "fill_with"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt an, was eingeschlossen werden soll, um fehlende Elemente zu ergänzen"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im folgenden Beispiel gibt es eine Variable, die eine Sequenz von Obstsorten enthält. Der batch-Filter wird auf eine Schleife angewendet, die die Sequenz durchläuft. Die geschachtelte Schleife wird dreimal durchlaufen, um 3 Obstsorten pro Zeile auszugeben, bevor die äußere Schleife erneut läuft. Beachten Sie in der endgültigen Ausgabe, dass, da es nur 5 Obstsorten gibt, das letzte Element durch ein &nbsp (den zweiten Parameter) ersetzt wird."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set rows = [\"apples\", \"oranges\", \"pears\", \"grapes\", \"blueberries\"] %}\n\n<table>\n{% for row in rows|batch(3, \" \") %}\n   <tr>\n    {% for column in row %}\n        <td>{{ column }}</td>\n    {% endfor %}\n    </tr>\n{% endfor %}\n</table>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<table>\n  <tbody>\n    <tr>\n      <td>apples</td>\n      <td>oranges</td>\n      <td>pears</td>\n    </tr>\n    <tr>\n      <td>grapes</td>\n      <td>blueberries</td>\n      <td></td>\n    </tr>\n  </tbody>\n</table>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "between_times"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Berechnet die Zeit zwischen zwei datetime-Objekten in einer bestimmten Zeiteinheit."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Sie sollten diesen Filter ", (0, _jsxRuntime.jsx)("u", {
          children: "nur"
        }), " für Variablen verwenden, die ein Datum zurückgeben. Ab dem 30. September 2024 gibt dieser Filter nicht mehr das aktuelle Datum zurück, wenn ein Nullwert übergeben wird. Nach diesem Datum wird bei einem Nullwert im Filter „30. September 2024“ zurückgegeben."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das endende Datetime-Objekt"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gültige Zeiteinheiten sind ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " , und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set begin = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{% set end = \"2018-07-20T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ begin|between_times(end, \"days\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "6\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "bool"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Konvertiert einen Text-String-Wert in einen booleschen Wert."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if \"true\"|bool == true %}hello world{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "hello world\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "capitalize"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Großschreibung des ersten Buchstabens eines Variablenwerts. Das erste Zeichen wird großgeschrieben, alle anderen Buchstaben werden kleingeschrieben. Bei nachfolgenden Wörtern, die durch Leerzeichen oder Bindestriche getrennt sind, wird der erste Buchstabe nicht großgeschrieben."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set sentence = \"the first letter of a sentence should always be capitalized.\" %}\n{{ sentence|capitalize }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The first letter of a sentence should always be capitalized.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "center"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der center-Filter verwendet Leerzeichen, um Text innerhalb einer bestimmten Feldlänge zu zentrieren. Dieser Filter wird nicht empfohlen und ist auch nicht besonders nützlich, da der HTML-Compiler von HubSpot die Leerzeichen automatisch entfernt; er ist hier jedoch der Vollständigkeit halber aufgeführt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt die Länge des Leerraums an, in dem der Text zentriert werden soll."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das folgende Beispiel zeigt, wie ein center-Filter auf eine Variable in einem pre-Tag angewendet wird, sodass die Leerzeichen nicht entfernt werden."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<pre>\n{% set var = \"string to center\" %}\nbefore{{ var|center(80) }}after\n</pre>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<pre>\nbefore                                string to center                                after\n</pre>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "convert_rgb"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Konvertiert einen HEX-Wert in einen RGB-String. Dies ist nützlich, wenn Sie Farbvariablen in RGB konvertieren müssen, um sie mit einer RGBA-CSS-Deklaration zu verwenden. Im folgenden Beispiel wird der von einem Farbmodul festgelegte Wert in einen RGB-Wert umgewandelt und in einer RGBA-CSS-Deklaration verwendet."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_color = \"#FFFFFF\" %}\n{{ my_color|convert_rgb }}\n\n{% color \"my_color\" color=\"#000000\", export_to_template_context=True %}\n<div style=\"background: rgba({{ widget_data.my_color.color|convert_rgb }}, .5)\"></div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "255, 255, 255\n\n<div style=\"background: rgba(0, 0, 0, .5)\"></div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "cut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Entfernt einen String aus einem Wert. Dieser Filter kann verwendet werden, um einen bestimmten Teil eines Strings abzugleichen und auszuschneiden. Der Parameter gibt den Teil des Strings an, der entfernt werden soll. Im folgenden Beispiel werden das Leerzeichen und das Wort „world“ aus dem ursprünglichen Variablenwert entfernt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "characters_to_cut"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Teil des Strings, der entfernt werden soll."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_string = \"Hello world.\" %}\n{{ my_string|cut(\" world\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Hello.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "datetimeformat (veraltet)"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Dieser Filter ist mittlerweile ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/deprecated#datetimeformat",
          children: "veraltet"
        }), ". Verwenden Sie stattdessen den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-datetime",
          children: "format_datetime"
        }), "-Filter, der eine stärker standardisierte Syntax verwendet."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "default"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn der Wert undefiniert ist, wird der erste Parameter zurückgegeben, andernfalls wird der Wert der Variablen gedruckt. Wenn Sie default mit Variablen verwenden wollen, die als false ausgewertet werden, müssen Sie den zweiten Parameter auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "true"
      }), " festlegen. Das erste Beispiel unten würde die Meldung drucken, wenn die Variable nicht definiert ist. Das zweite Beispiel wendet den Filter auf einen leeren String an, die nicht undefiniert ist, gibt aber aufgrund des zweiten Parameters eine Meldung aus."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default_variable"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wert, der zurückgegeben wird, wenn die Variable undefiniert ist. Wenn die Variable definiert ist, wird stattdessen der Wert der Variable zurückgegeben."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt den ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "default_value"
            }), " zurück, wenn die Variable ein leerer String ist"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ my_variable|default(\"my_variable is not defined\") }}\n{{ \"\"|default(\"the string was empty\", true) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "my_variable is not defined the string was empty\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dictsort"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sortiert ein Dict und ergibt (Schlüssel, Wert) Paare. Dictionaries sind standardmäßig unsortiert, Sie können ein Dictionary jedoch nach Schlüssel oder Wert sortiert ausgeben. Der erste Parameter ist ein boolescher Wert, der angibt, ob bei der Sortierung zwischen Groß- und Kleinschreibung unterschieden wird oder nicht. Der zweite Parameter bestimmt, ob das Dict nach Schlüssel oder Wert sortiert werden soll. Das folgende Beispiel druckt ein sortiertes Kontaktverzeichnis mit allen bekannten Details zum Kontakt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "case_sensitive"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Legt fest, ob bei der Sortierung zwischen Groß- und Kleinschreibung unterschieden wird"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "sort_by"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Bestimmt, ob nach ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "key"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            }), " sortiert werden soll"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for item in contact|dictsort(false, \"value\") %}\n    {{item}}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A sorted contact dictionary\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "difference"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieser Filter gibt die Differenz von zwei Mengen oder Listen zurück. Die vom Filter zurückgegebene Liste enthält alle eindeutigen Elemente, die in der ersten Liste, aber nicht in der zweiten Liste enthalten sind."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die zweite Liste, die zum Vergleich herangezogen wird, um Unterschiede zur ursprünglichen Liste zu finden."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|difference([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "divide"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Teilt den aktuellen Wert durch einen Divisor. Der übergebene Parameter ist der Divisor. Dieser Filter ist eine Alternative zum Operator /."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "divisor"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Zahl, durch die die Variable geteilt werden soll."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numerator = 106 %}\n{{ numerator|divide(2) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "divisible"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Als Alternative zum Test des Ausdrucks „divisibleby“ wird der divisible-Filter zu true ausgewertet, wenn der Wert durch die angegebene Zahl teilbar ist."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "divisor"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Zahl, die verwendet werden soll, wenn geprüft wird, ob der Wert teilbar ist."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set num = 10 %}\n{% if num|divisible(2) %}\nThe number is divisble by 2\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The number is divisible by 2\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_html"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Versieht den Inhalt mit Escape-Zeichen einer HTML-Eingabe. Akzeptiert einen String und konvertiert die Zeichen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ">"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "‘"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "”"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " in HTML-sichere Sequenzen. Verwenden Sie diesen Filter für HubL-Variablen, die in HTML-Codes verwendet werden, aber kein HTML zulassen dürfen."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<div>This markup is printed as text</div>\" %}\n{{ escape_string|escape_html }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div>This markup is printed as text</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_attr"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Versieht den Inhalt mit Escape-Zeichen einer HTML-Attributeingabe. Akzeptiert einen String und konvertiert die Zeichen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "‘"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "”"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " in HTML-sichere Sequenzen. Verwenden Sie diesen Filter für HubL-Variablen, die HTML-Attributen hinzugefügt werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beachten Sie, dass Sie beim Escaping von Werten von Attributen, die URLs akzeptieren, wie z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "href"
      }), ", stattdessen den Filter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_url"
      }), " verwenden müssen."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"This <br> markup is printed as text\" %}\n<img src=\"test.com/imageurl\" alt=\"{{escape_string|escape_attr}}\">\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<img src=\"test.com/imageurl\" alt=\"This <br> markup is printed as text\" />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_jinjava"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Konvertiert die Zeichen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "}"
      }), " in Strings in Jinjava-sichere Sequenzen. Verwenden Sie diesen Filter, wenn Sie Text anzeigen müssen, der solche Zeichen in Jinjava enthalten könnte."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"{{This markup is printed as text}}\" %}\n{{ escape_string|escape_jinjava }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{{This markup is printed as text}}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Versieht Strings mit Escape-Zeichen, einschließlich ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), ", sodass sie sicher in eine JavaScript-Variablendeklaration eingefügt werden können. Verwenden Sie diesen Filter für HubL-Variablen, die in HTML-Skriptelementen verwendet werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"\\tThey said 'This string can safely be inserted into JavaScript.'\" %}\n{{ escape_string|escape_js }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "\\tThey said \\x27This string can safely be inserted into JavaScript.\\x27\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_url"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Versieht den Inhalt einer URL-Eingabe mit Escape-Zeichen, erzwingt festgelegte Protokolle, entfernt ungültige und gefährliche Zeichen und kodiert HTML-Entitäten. Gibt eine leere Antwort zurück, wenn eine URL gültig ist. Verwenden Sie diesen Filter für HubL-Variablen, die in HTML-Attributen verwendet werden. Es müssen gültige URLs sein."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"https://www.google.com<\" %}\n <a href=\"{{ escape_string|escape_url }}\">\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<a href=\"\"></a>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escapejson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Versieht Strings mit Escape-Zeichen, sodass sie als JSON-Werte verwendet werden können."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<script>alert('oh no!')</script>\" %}\n{% require_js position=\"head\" %}\n<script data-search_input-config=\"config_{{ name }}\" type=\"application/json\">\n{\n  \"autosuggest_results_message\": \"{{ escape_string|escapejson }}\"\n}\n</script>\n{% end_require_js %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<script\n  data-search_input-config=\"config_widget_1234567\"\n  type=\"application/json\"\n>\n  {\n    \"autosuggest_results_message\": \"<script>alert('oh no!')<\\/script>\"\n  }\n</script>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "filesizeformat"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formatieren Sie den Wert wie eine „menschenlesbare“ Dateigröße (z. B. 13 kB oder 4,1 MB, 102 Bytes usw.). Standardmäßig werden dezimale Präfixe verwendet (Mega, Giga usw.), wenn der Parameter auf true gesetzt ist, werden binäre Präfixe verwendet (Mebi, Gibi)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bei true werden binäre Präfixe wie Mebi & Gibi verwendet."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set bytes = 100000 %}\n{{ bytes|filesizeformat }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "100.0 KB\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "first"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gibt das erste Element in einer Sequenz zurück."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{{ my_sequence|first }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Item 1\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "float"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wandelt den Wert in eine Fließkommazahl um. Wenn die Umrechnung nicht funktioniert, wird 0,0 zurückgegeben. Sie können diese Vorgabe mit dem ersten Parameter außer Kraft setzen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer, der zurückgegeben wird, wenn die Konvertierung nicht funktioniert."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_text\" value=\"25\", export_to_template_context=True %}\n{{ widget_data.my_text.value|float + 28 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "forceescape"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Strenge Durchsetzung von HTML-Escaping In der HubSpot-Umgebung gibt es nicht wirklich einen Anwendungsfall für doppeltes Escaping, daher verhält sich dieser Filter im Allgemeinen genauso wie der escape-Filter."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<div>This markup is printed as text</div>\" %}\n{{ escape_string|forceescape }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div>This markup is printed as text</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wendet die Python-String-Formatierung auf ein Objekt an. %s kann durch eine andere Variable ersetzt werden."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"Hi %s %s\"|format(contact.firstname, contact.lastname) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Hi Brian Halligan\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_currency (veraltet)"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Dieser Filter ist mittlerweile ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/deprecated",
          children: "veraltet"
        }), ". Verwenden Sie stattdessen den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-currency-value",
          children: "format_currency_value"
        }), "-Filter."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_currency_value"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Formatiert eine gegebene Zahl als Währung basierend auf der Standardwährung des Portals und dem als Parameter übergebenen Gebietsschema. Ersetzt den veralteten ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/deprecated#format-currency",
        children: "format_currency-Filter"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 100 | format_currency_value(locale='en-GB', currency='EUR', maxDecimalDigits=6, minDecimalDigits=1) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "€100.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.oracle.com/java/technologies/javase/jdk8-jre8-suported-locales.html",
              children: "Das Sprach-Tag des Java-Gebietsschemas"
            }), ". Der Standard ist das locale.Format der Seite: ISO639LanguageCodeInLowercase-ISO3166CountryCodeInUppercase"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "currency"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://en.wikipedia.org/wiki/ISO_4217",
              children: "alphabetische ISO 4217-Code"
            }), " der Währung, Standard ist die Standardwährung des Portals. Numerische Codes werden nicht akzeptiert."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "minDecimalDigits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Mindestanzahl an zu verwendenden Dezimalstellen. Standardmäßig wird die Standardanzahl der Dezimalstellen der Währung verwendet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "maxDecimalDigits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die maximale Anzahl an zu verwendenden Dezimalstellen. Standardmäßig wird die Standardanzahl der Dezimalstellen der Währung verwendet."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_date"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formatiert die date-Komponente eines date-Objekts."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Sie sollten diesen Filter ", (0, _jsxRuntime.jsx)("u", {
          children: "nur"
        }), " für Variablen verwenden, die ein Datum zurückgeben. Ab dem 30. September 2024 gibt dieser Filter nicht mehr das aktuelle Datum zurück, wenn ein Nullwert übergeben wird. Nach diesem Datum wird bei einem Nullwert im Filter „30. September 2024“ zurückgegeben."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Das zu verwendende Format. Kann eines der folgenden sein:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Ein benutzerdefiniertes Muster gemäß ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "Unicode LDML"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Zeitzone des Ausgabedatums im ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "IANA-TZDB-Format"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Gebietsschema, das für Formate verwendet werden soll, die das Gebietsschema berücksichtigen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date | format_date('long') }}\n{{ content.publish_date | format_date('yyyyy.MMMM.dd') }}\n{{ content.publish_date | format_date('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "November 28, 2022 02022.November.28 28.11.2022\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_datetime"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Formatiert sowohl die date- als auch die time-Komponente eines date-Objekts. Dieser Filter ersetzt den veralteten ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/deprecated#datetimeformat-nbsp-",
        children: "datetimeformat"
      }), "-Filter. Standardmäßig wird eine Datetime in der Zeitzone UTC-00:00 zurückgegeben."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Sie sollten diesen Filter ", (0, _jsxRuntime.jsx)("u", {
          children: "nur"
        }), " für Variablen verwenden, die ein Datum zurückgeben. Ab dem 30. September 2024 gibt dieser Filter nicht mehr das aktuelle Datum zurück, wenn ein Nullwert übergeben wird. Nach diesem Datum wird bei einem Nullwert im Filter „30. September 2024“ zurückgegeben."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Das zu verwendende Format. Kann eines der folgenden sein:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Ein benutzerdefiniertes Muster gemäß ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "Unicode LDML"
                })]
              })]
            }), "Wenn ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "long"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "full"
            }), " verwendet wird, enthält der Zeitstempel ein Z, um die Nullpunktverschiebung UTC-Zeit (d. h. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2:23:00 PM Z"
            }), ") zu bezeichnen. Um den Z-Bezeichner zu entfernen, geben Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), " an."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Zeitzone des Ausgabedatums im ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "IANA-TZDB-Format"
            }), ". Standardmäßig wird die UTC-Zeit zurückgegeben."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Gebietsschema, das für Formate verwendet werden soll, die das Gebietsschema berücksichtigen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date | format_datetime('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "12/31/69 7:00 PM\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formatiert die Uhrzeitkomponente eines date-Objekts."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Sie sollten diesen Filter ", (0, _jsxRuntime.jsx)("u", {
          children: "nur"
        }), " für Variablen verwenden, die ein Datum zurückgeben. Ab dem 30. September 2024 gibt dieser Filter nicht mehr das aktuelle Datum zurück, wenn ein Nullwert übergeben wird. Nach diesem Datum wird bei einem Nullwert im Filter „30. September 2024“ zurückgegeben."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Das zu verwendende Format. Kann eines der folgenden sein:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Ein benutzerdefiniertes Muster gemäß ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "Unicode LDML"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Zeitzone des Ausgabedatums im ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "IANA-TZDB-Format"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Gebietsschema, das für Formate verwendet werden soll, die das Gebietsschema berücksichtigen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.updated | format_time('long') }}\n{{ content.updated | format_time('hh:mm a') }}\n{{ content.updated | format_time('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "3:25:06 PM Z 03:25 PM 10:25:44\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "fromjson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Konvertiert einen JSON-String in ein Objekt."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set obj =\"{ \"name\":\"Brian\",\"role\":\"Owner\" }\" %}\n{{ obj|fromjson }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{role=Owner, name=Brian}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "geo_distance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Berechnet den ellipsoiden 2D-Abstand zwischen zwei Punkten auf der Erde."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<!-- in the example below\nthe HubDB Location =\n42.3667, -71.1060 (Cambridge, MA) |\nChicago, IL = 37.3435, -122.0344 -->\n{{ row.location | geo_distance(37.3435, -122.0344, \"mi\") }} MI\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "861.1655563461395 MI\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "groupby"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der groupby-Filter gruppiert eine Sequenz von Objekten nach einem gemeinsamen Attribut. Der Parameter legt das gemeinsame Attribut fest, nach dem gruppiert werden soll."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Attribut, nach dem gruppiert werden soll."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<ul>\n{% for group in contents|groupby(\"blog_post_author\") %}\n    <li>{{ group.grouper }}\n      <ul>\n        {% for content in group.list %}\n          <li>{{ content.name }}</li>\n        {% endfor %}\n      </ul>\n    </li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>\n    Blog author 1\n    <ul>\n      <li>Post by Blog author 1</li>\n      <li></li>\n      <li>Post by Blog author 1</li>\n      <li></li>\n      <li>Post by Blog author 1</li>\n      <li></li>\n    </ul>\n  </li>\n  <li>\n    Blog author 2\n    <ul>\n      <li>Post by Blog author 2</li>\n      <li></li>\n      <li>Post by Blog author 2</li>\n      <li></li>\n      <li>Post by Blog author 2</li>\n      <li></li>\n    </ul>\n  </li>\n  <li>\n    Blog author 3\n    <ul>\n      <li>Post by Blog author 3</li>\n      <li></li>\n      <li>Post by Blog author 3</li>\n      <li></li>\n      <li>Post by Blog author 3</li>\n      <li></li>\n    </ul>\n  </li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "indent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der indent-Filter verwendet Leerzeichen, um Text innerhalb einer bestimmten Feldlänge einzurücken. Dieser Filter wird nicht empfohlen und ist auch nicht besonders nützlich, da der HTML-Compiler von HubSpot die Leerzeichen automatisch entfernt; er ist hier jedoch der Vollständigkeit halber aufgeführt. Das folgende Beispiel zeigt, wie ein center-Filter auf eine Variable in einem pre-Tag angewendet wird, sodass die Leerzeichen nicht entfernt werden. Der erste Parameter steuert die Menge an Leerzeichen, und der zweite boolesche Parameter bestimmt, ob die erste Zeile eingerückt werden soll."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Menge an Leerzeichen, die angewendet werden soll."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein boolescher Wert, der angibt, ob die erste Zeile eingerückt werden soll."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<pre>\n{% set var = \"string to indent\" %}\n{{ var|indent(2, true) }}\n</pre>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "string to indent\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "int"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Konvertiert den Wert in eine Ganzzahl. Wenn die Konvertierung nicht funktioniert, wird 0 zurückgegeben. Sie können diese Vorgabe mit dem ersten Parameter außer Kraft setzen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer, der zurückgegeben wird, wenn die Konvertierung nicht funktioniert."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_text\" value=\"25\", export_to_template_context=True %}\n{{ widget_data.my_text.value|int + 28 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "intersect"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieser Filter gibt die Schnittmenge von zwei Mengen oder Listen zurück. Die vom Filter zurückgegebene Liste enthält alle eindeutigen Elemente, die in beiden Listen enthalten sind."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die zweite Liste, mit der verglichen werden soll, um herauszufinden, wo sich die Liste mit der ursprünglichen Liste überschneidet."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|intersect([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[2, 3]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ipaddr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wird zu true ausgewertet, wenn der Wert eine gültige IPv4- oder IPv6-Adresse ist."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set ip = \"1.0.0.1\" %}\n{% if ip|ipaddr %}\n    The string is a valid IP address\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The string is a valid IP address\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "join"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gibt einen String zurück, die die Verkettung der Strings in der Sequenz ist. Das Trennzeichen zwischen den Elementen ist standardmäßig ein leerer String, Sie können es mit dem optionalen Parameter definieren. Der zweite Parameter kann verwendet werden, um ein zu verbindendes Attribut anzugeben."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "delimiter"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Trennzeichen, das bei der Verkettung von Strings verwendet wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubL-Variable"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attribut des Werts, der einem Objekt hinzugefügt werden soll."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_list = [1, 2, 3] %}\n{% set sep = \"---\" %}\n{{ my_list|join }}\n{{ my_list|join(\"|\") }}\n{{ my_list|join(sep) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "123 1|2|3 1---2---3\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "last"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gibt das letzte Element einer Sequenz zurück."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{% my_sequence|last %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Item 3\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "length"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gibt die Anzahl der Elemente einer Sequenz oder einer Zuordnung zurück."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set services = [\"Web design\", \"SEO\", \"Inbound Marketing\", \"PPC\"] %}\n{{ services|length }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "4\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "list"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Konvertieren Sie die Zahlenwerte in eine Liste. Wenn es sich um einen String handelt, ist die zurückgegebene Liste eine Liste von Zeichen. Um Strings zu einer Sequenz hinzuzufügen, fügen Sie sie einfach den String-Variablen an den Sequenztennzeichen [ ] hinzu."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set one = 1 %}\n{% set two = 2 %}\n{% set three = 3 %}\n{% set four = [\"four\"] %}\n{% set list_num = one|list + two|list + three|list + four|list %}\n{{ list_num }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1,2,3]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "log"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Berechnet den natürlichen Logarithmus einer Zahl."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "base"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Berechnen Sie den Logarithmus zur n-ten Basis."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 10|log }}\n{{ 65536|log(2) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2.302585092994046 16.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "lower"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Konvertiert einen Wert in Kleinbuchstaben."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ text \"text\" value=\"Text to MAKE Lowercase\", export_to_template_context=True }}\n{{ widget_data.text.value|lower }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "text to make lowercase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "map"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wendet einen Filter auf eine Sequenz von Objekten an oder sucht nach einem Attribut. Dies ist nützlich, wenn man mit Listen von Objekten arbeitet, aber eigentlich nur an einem bestimmten Wert interessiert ist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die grundlegende Verwendung ist das Mapping auf ein Attribut. Wenn Sie z. B. mithilfe von bedingter Logik prüfen wollen, ob ein Wert in einem bestimmten Attribut eines Dict vorhanden ist. Alternativ können Sie auch einen Filter aufrufen lassen, indem Sie den Namen des Filters und die Argumente übergeben."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attribut, das in der Reihenfolge der Objekte zurückgegeben werden soll."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "filter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Filter, der auf die Sequenz von Objekten angewendet werden soll."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set seq = [\"item1\", \"item2\", \"item3\"] %}\n{{ seq|map(\"upper\") }}\n{{ content|map(\"currentState\")}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[ITEM1, ITEM2, ITEM3] DRAFT\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "md5"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Berechnet den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/MD5",
        children: "md5-Hash"
      }), " des angegebenen Objekts"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.absolute_url|md5 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "923adb4ce05a4c6342c04c80be88d15e\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "minus_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zieht eine Zeitdauer von einem datetime-Objekt ab."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "diff"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abzuziehende Dauer."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gültige Zeiteinheiten sind ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " , und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set date = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ date }}\n{{ date|minus_time(2, \"months\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2018-07-14 14:31:30 2018-05-14 14:31:30\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "multiply"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Multipliziert einen Wert mit einer Zahl. Funktioniert genauso wie der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "Operator *"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set n = 20 %}\n{{ n|multiply(3) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "60\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "plus_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Addiert eine Zeitdauer zu einem datetime-Objekt hinzu."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "diff"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hinzuzufügende Dauer."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gültige Zeiteinheiten sind ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " , und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set date = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ date }}\n{{ date|plus_time(5, \"days\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2018-07-14 14:31:30 2018-07-19 14:31:30\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "pprint"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gibt eine Variable als pretty print aus. Dies druckt den Typ der Variablen und andere Informationen, die für das Debugging nützlich sind."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set this_var =\"Variable that I want to debug\" %}\n{{ this_var|pprint }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(String: Variable that I want to debug)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "random"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gibt ein zufälliges Element aus der Sequenz zurück."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Bei Verwendung dieses Filters wird die Seite nicht jedes Mal, wenn der Seiteninhalt aktualisiert wird, sondern in regelmäßigen Abständen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "vorgerendert"
        }), ". Dies bedeutet, dass der gefilterte Inhalt ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " bei jedem erneuten Laden der Seite aktualisiert wird."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Dies ist möglicherweise kein Problem für bestimmte Arten von Inhalten, z. B. die Anzeige einer zufälligen Liste von Blog-Beiträgen. Wenn Inhalte jedoch bei jedem Laden der Seite zufällig geändert werden müssen, sollten Sie stattdessen JavaScript verwenden, um den Inhalt client-seitig zu randomisieren."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|random %}\n<div class=\"post-item\">Post item markup</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Random post</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "regex_replace"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sucht nach einem Regex-Muster und ersetzt es durch eine Sequenz von Zeichen. Das erste Argument ist ein Regex-Muster im RE2-Stil, das zweite ist der Ersatz-String."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Informationen über die RE2-Regex-Syntax finden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/google/re2/wiki/Syntax",
        children: "hier"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"contact-us-2\"|regex_replace(\"[^a-zA-Z]\", \"\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "contactus\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "reject"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Filtert eine Sequenz von Objekten, indem ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "Ausdruckstest"
      }), " auf das Objekt angewendet wird und die Objekte, bei denen der Test erfolgreich war, zurückgewiesen werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Ausdruckstest, der auf das Objekt angewendet werden soll."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_numbers = [10, 12, 13, 3, 5, 17, 22] %}\n{{ some_numbers|reject(\"even\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[13, 3, 5, 17]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "rejectattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filtert eine Sequenz von Objekten, indem ein Test auf ein Attribut eines Objekts angewendet wird und die Objekte abgelehnt werden, bei denen der Test erfolgreich war."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt das auszuwählende Attribut an. Sie können mithilfe der Punktnotation auf verschachtelte Attribute zugreifen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_test"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der zu testende Ausdruck"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "val"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wert, auf den getestet werden soll."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|rejectattr(\"post_list_summary_featured_image\") %}\n<div class=\"post-item\">\n{% if content.post_list_summary_featured_image %}\n    <div class=\"hs-featured-image-wrapper\">\n            <a href=\"{{content.absolute_url}}\" title=\"\" class=\"hs-featured-image-link\">\n            <img src=\"{{ content.post_list_summary_featured_image }}\" class=\"hs-featured-image\">\n            </a>\n    </div>\n{% endif %}\n{{ content.post_list_content|safe }}\n</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Post with no featured image</div>\n<div class=\"post-item\">Post with no featured image</div>\n<div class=\"post-item\">Post with no featured image</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "render"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Rendering von Strings, die HubL enthalten, damit die Ausgabe an andere Filter weitergegeben werden kann."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ personalization_token(\"contact.lastname\", \"default value\")|render|lower }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "mclaren\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "replace"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ersetzt alle Instanzen eines Substrings durch eine neue."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "old"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Substring, der ersetzt werden soll."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "new"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ersatz-String."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "count"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Falls angegeben, werden nur die Vorkommen der ersten Anzahl ersetzt."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if topic %}\n<h3>Posts about {{ page_meta.html_title|replace(\"Blog | \", \"\") }}</h3>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>Posts about topic name</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "reverse"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Kehren Sie das Objekt um oder geben Sie einen Iterator zurück, der das Objekt in umgekehrter Reihenfolge durchläuft. Um eine Liste umzukehren, verwenden Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#reverse",
        children: ".reverse()"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] %}\n{% for num in nums|reverse %}\n{{ num }}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "10 9 8 7 6 5 4 3 2 1\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "root"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Berechnet die Quadratwurzel eines Wertes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "nth_root"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Berechnen Sie die n-te Wurzel von einer Zahl."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 16|root }}\n{{ 625|root(4) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "4 5\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "round"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Rundet die Zahl auf eine bestimmte Genauigkeit."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "precision"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt die Genauigkeit der Rundung an."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "rounding_method"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Zu den Optionen gehören ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "common"
            }), " – rundet entweder auf oder ab (Standard); ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ceil"
            }), " – rundet immer auf; ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "floor"
            }), " – rundet immer ab. Wenn Sie keine angeben, wird eine ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "common"
            }), "-Methode verwendet."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 52.5|round }}\n{{ 52.5|round(0, \"floor\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53 52\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "safe"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Markieren Sie den Wert als sicher, was bedeutet, dass diese Variable in einer Umgebung, in der automatisches Escaping aktiviert ist, nicht mit Escape-Zeichen versehen wird."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.post_list_content|safe }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>HTML post content that is not escaped.</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sanitize_html"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bereinigt den Inhalt einer HTML-Eingabe für die Ausgabe von Rich-Text-Inhalten. Akzeptiert einen String und entfernt dann HTML-Tags, die nicht zulässig sind. Verwenden Sie diesen Filter für HubL-Variablen, die in HTML-Codes verwendet werden und sicheres HTML ermöglichen sollen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie diesen Filter verwenden, können Sie die folgenden Parameter einfügen, um bestimmte Arten von HTML-Tags zuzulassen: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORMATTING"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BLOCKS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STYLES"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LINKS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TABLES"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IMAGES"
      }), ". Zum Beispiel: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sanitize_html(IMAGES)"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sanitize_html"
      }), " werden alle Parameter in den Filter aufgenommen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können auch einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STRIP"
      }), "-Parameter einfügen, um ", (0, _jsxRuntime.jsx)("u", {
        children: "alle"
      }), " HTML-Codes zu entfernen. Alle Inhalte werden auch über ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " ausgeführt, um eine verschachtelte Interpretation zu verhindern."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"This <br> <div>markup is <img src='test.com/image'> <span>printed</span> as text.</div>\" %}\n{{ escape_string|sanitize_html(\"IMAGES\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This  markup is <img src=\"test.com/image\"> printed as text.</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "select"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filtert eine Sequenz von Objekten, indem ein Test auf das Objekt angewendet wird und nur die Objekte ausgewählt werden, bei denen der Test erfolgreich war."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Ausdruckstest, der auf das Objekt angewendet werden soll."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_numbers = [10, 12, 13, 3, 5, 17, 22] %}\n{{ some_numbers|select(\"even\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[10, 12, 22]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "selectattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filtert eine Sequenz von Objekten, indem er einen Test auf ein Attribut eines Objekts anwendet und nur die Objekte auswählt, bei denen der Test erfolgreich war."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt das auszuwählende Attribut an. Sie können mithilfe der Punktnotation auf verschachtelte Attribute zugreifen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_test"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der zu testende Ausdruck"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "val"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wert, auf den getestet werden soll."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|selectattr(\"post_list_summary_featured_image\") %}\n<div class=\"post-item\">\n  {% if content.post_list_summary_featured_image %}\n    <div class=\"hs-featured-image-wrapper\">\n       <a href=\"{{content.absolute_url}}\" title=\"\" class=\"hs-featured-image-link\">\n         <img src=\"{{ content.post_list_summary_featured_image }}\" class=\"hs-featured-image\">\n       </a>\n    </div>\n  {% endif %}\n{{ content.post_list_content|safe }}\n</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">\n  <div class=\"hs-featured-image-wrapper\">\n    <a\n      href=\"http://blog.hubspot.com/marketing/how-to-get-a-job\"\n      title=\"\"\n      class=\"hs-featured-image-link\"\n    >\n      <img\n        src=\"//cdn2.hubspot.net/hub/53/hubfs/00-Blog-Related_Images/landing-a-job-featured-image.png?t=1431452322770&width=761\"\n        class=\"hs-featured-image\"\n      />\n    </a>\n  </div>\n  Post with featured image\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "shuffle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Legt die Reihenfolge der Iteration durch eine Sequenz wird zufällig fest. Im folgenden Beispiel wird eine Standard-Blogschleife gemischt."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Bei Verwendung dieses Filters wird die Seite nicht jedes Mal, wenn der Seiteninhalt aktualisiert wird, sondern in regelmäßigen Abständen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "vorgerendert"
        }), ". Dies bedeutet, dass der gefilterte Inhalt ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " bei jedem erneuten Laden der Seite aktualisiert wird."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Dies ist möglicherweise kein Problem für bestimmte Arten von Inhalten, z. B. die Anzeige einer zufälligen Liste von Blog-Beiträgen. Wenn Inhalte jedoch bei jedem Laden der Seite zufällig geändert werden müssen, sollten Sie stattdessen JavaScript verwenden, um den Inhalt client-seitig zu randomisieren."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|shuffle %}\n<div class=\"post-item\">Markup of each post</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Markup of each post 5</div>\n<div class=\"post-item\">Markup of each post 3</div>\n<div class=\"post-item\">Markup of each post 1</div>\n<div class=\"post-item\">Markup of each post 2</div>\n<div class=\"post-item\">Markup of each post 4</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "slice"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zerschneiden eines Iterators und Rückgabe einer Liste von Listen, die diese Elemente enthalten. Der erste Parameter gibt an, wie viele Elemente in Scheiben geschnitten werden sollen, und der zweite Parameter gibt an, mit welchen Zeichen leere Scheiben gefüllt werden sollen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "slices"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wie viele Elemente werden geschnitten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "filler"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt Zeichen an, mit denen leere Slices aufgefüllt werden."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set items = [\"laptops\", \"tablets\", \"smartphones\", \"smart watches\", \"TVs\"] %}\n<div class=\"columwrapper\">\n  {% for column in items|slice(3,\" \") %}\n    <ul class=\"column-{{ loop.index }}\">\n    {% for item in column %}\n      <li>{{ item }}</li>\n    {% endfor %}\n    </ul>\n  {% endfor %}\n</div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"columwrapper\">\n  <ul class=\"column-1\">\n    <li>laptops</li>\n    <li>tablets</li>\n    <li>smartphones</li>\n  </ul>\n\n  <ul class=\"column-2\">\n    <li>smart watches</li>\n    <li>TVs</li>\n    <li></li>\n  </ul>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sort"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sortiert eine Iterable. Bei diesem Filter müssen alle Parameter nach einem Attribut in HubSpot sortiert werden. Der erste Parameter ist ein boolescher Wert, der die Sortierreihenfolge umkehrt. Der zweite Parameter bestimmt, ob bei der Sortierung zwischen Groß- und Kleinschreibung unterschieden wird. Und der letzte Parameter gibt ein Attribut an, nach dem sortiert werden soll. Im folgenden Beispiel werden Beiträge aus einem Blog gerendert und nach Namen alphabetisch geordnet."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "reverse"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolescher Wert zur Umkehrung der Sortierreihenfolge."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "case_sensitive"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolescher Wert, der bestimmt, ob bei der Sortierung zwischen Groß- und Kleinschreibung unterschieden wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attribut, nach dem sortiert werden soll. Beim Sortieren einer Liste auslassen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_posts = blog_recent_posts(\"default\", limit=5) %}\n\n{% for item in my_posts|sort(False, False, \"name\") %}\n{{ item.name }}<br>\n\n\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A post<br />\nB post<br />\nC post<br />\nD post<br />\nE post<br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "split"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Teilt den Eingabez-String in eine Liste mit dem angegebenen Separator auf. Der erste Parameter gibt den Separator an, mit dem die Variable geteilt werden soll. Der zweite Parameter bestimmt, wie oft die Variable aufgeteilt werden soll. Alle übrigen Elemente verbleiben dann in der Gruppe. Im folgenden Beispiel wird einen String von Namen am „;“ für die ersten 4 Namen geteilt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "character_to_split_by"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt den Separator an, durch den die Variable geteilt werden soll."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_splits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bestimmt, wie oft die Variable geteilt werden soll. Alle übrigen Elemente bleiben gruppiert."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set string_to_split = \"Stephen; David; Cait; Nancy; Mike; Joe; Niall; Tim; Amanda\" %}\n{% set names = string_to_split|split(\";\", 4) %}\n<ul>\n{% for name in names %}\n  <li>{{ name }}</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>Stephen</li>\n  <li>David</li>\n  <li>Cait</li>\n  <li>Nancy; Mike; Joe; Niall; Tim; Amanda</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "string"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Konvertiert einen anderen Variablentyp in einen String. Im folgenden Beispiel wird eine Ganzzahl in einen String umgewandelt (pprint wird verwendet, um die Änderung des Variablentyps zu bestätigen)."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set number_to_string = 45 %}\n{{ number_to_string|string|pprint }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(String: 45)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "striptags"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Entfernt SGML/XML-Tags und ersetzt angrenzende Leerzeichen durch ein einziges Leerzeichen. Dieser Filter kann verwendet werden, um alle HTML-Tags aus einer Variablen zu entfernen."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_html = \"<div><strong>Some text</strong></div>\" %}\n{{ some_html|striptags }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "some text\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "strtotime"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Konvertiert einen datetime-String und ein datetime-Format in ein datetime-Objekt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "datetimeFormat"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://docs.oracle.com/javase/7/docs/api/java/text/SimpleDateFormat.html",
              children: "Datums- und Uhrzeitmuster."
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\")|unixtimestamp }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1531558890000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sum"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Addiert numerische Werte in einer Sequenz. Der erste Parameter kann ein optionales Attribut angeben und der zweite Parameter legt einen Wert fest, der zurückgegeben wird, wenn die zu summierende Variable nichts enthält."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zu summierendes Attribut."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "return_if_nothing"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wert, der zurückgegeben wird, wenn die zu summierende Variable nichts enthält."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set sum_this = [1, 2, 3, 4, 5] %}\n{{ sum_this|sum }}\nTotal: {{ items|sum(attribute=\"price:\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "15 Total: 20\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "symmetric_difference"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieser Filter gibt die symmetrische Differenz von zwei Mengen oder Listen zurück. Die vom Filter zurückgegebene Liste enthält alle eindeutigen Elemente, die in der ersten Liste enthalten sind, aber nicht in der zweiten, oder die in der zweiten Liste enthalten sind, aber nicht in der ersten."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die zweite Liste, mit der die symmetrische Differenz zur ursprünglichen Liste ermittelt wird."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|symmetric_difference([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1, 4, 5]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "title"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gibt eine Title-Case-Version des Wertes zurück. D.h., Wörter beginnen mit Großbuchstaben, alle anderen Zeichen sind in Kleinbuchstaben geschrieben."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_title\" label=\"Enter a title\", value=\"My title should be titlecase\", export_to_template_context=True %}\n{{ widget_data.my_title.value|title }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "My Title Should Be Titlecase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "tojson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Schreibt ein Objekt als JSON-String."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n  {{ content.blog_post_author|tojson }}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte JSON-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 1234567,\n  \"id\": 12312253109,\n  \"created\": 1566413741989,\n  \"updated\": 1566414012799,\n  \"deletedAt\": 0,\n  \"fullName\": \"Sample User\",\n  \"email\": \"sampleUser@example.com\",\n  \"userId\": null,\n  \"username\": null,\n  \"slug\": \"sample-user\",\n  \"jsonBody\": {\n    \"avatar\": \"https://app.hubspot.com/settings/avatar/109d6874a0cb066c1c7263ac5df6ce7a\",\n    \"bio\": \"Sample Bio\",\n    \"facebook\": \"\",\n    \"linkedin\": \"\",\n    \"twitter\": \"\",\n    \"website\": \"https://www.hubspot.com\"\n  },\n  \"bio\": \"Sample Bio\",\n  \"facebook\": \"\",\n  \"linkedin\": \"\",\n  \"avatar\": \"https://app.hubspot.com/settings/avatar/109d6874a0cb066c1c7263ac5df6ce7a\",\n  \"gravatarUrl\": \"https://app.hubspot.com/settings/avatar/108bb5ac667ded34796271437dfe8d58\",\n  \"twitterUsername\": \"\",\n  \"hasSocialProfiles\": false,\n  \"website\": \"https://www.hubspot.com\",\n  \"twitter\": \"\",\n  \"displayName\": \"Sample User\"\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "trim"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Entfernt vor- und nachgestellte Leerzeichen. HubSpot schneidet bereits Leerzeichen aus dem Markup ab, aber dieser Filter ist der Vollständigkeit halber dokumentiert."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \" remove whitespace \" }}\n{{ \" remove whitespace \"|trim }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "remove whitespace remove whitespace\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "truncate"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Schneidet den Text nach einer bestimmten Anzahl von Zeichen ab. Der Standardwert ist 255. Bitte beachten Sie, dass HTML-Zeichen in dieser Zahl enthalten sind. Die Länge wird mit dem ersten Parameter angegeben, der standardmäßig 255 beträgt. Wenn der zweite Parameter true ist, schneidet der Filter den Text den Text auf die angegebene Länge ab. Andernfalls wird das letzte Wort verworfen. Wenn der Text tatsächlich abgeschnitten wurde, wird ein Auslassungszeichen („…“) angehängt. Wenn Sie ein anderes Auslassungszeichen als „…“ wünschen, können Sie es mit dem dritten Parameter angeben."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_characters"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anzahl der Zeichen, um die der Text gekürzt werden soll. Der Standardwert ist 255."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "breakword"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolescher Wert. Bei true schneidet der Filter den Text auf die angegebene Länge ab. Wenn false, wird das letzte Wort verworfen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Überschreiben Sie die standardmäßigen „…“ Zeichen nach dem Abschneiden."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"I only want to show the first sentence. Not the second.\"|truncate(40) }}\n{{ \"I only want to show the first sentence. Not the second.\"|truncate(35, True, \"..........\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "I only want to show the first sentence. I only want to show the first\nsente..........\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "truncatehtml"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Kürzt einen vorgegebenen String unter Berücksichtigung des HTML-Markups (d. h., alle verschachtelten Tags werden korrekt geschlossen). Dadurch wird verhindert, dass ein Tag nach dem Abschneiden offen bleibt. HTML-Zeichen werden nicht auf die Gesamtzahl der Zeichen angerechnet. Dieser Filter hat einen Parameter für die Länge und einen Parameter für Abschneiden-Symbol. Es gibt einen dritten booleschen Parameter, der angibt, ob die Wörter bei der angegebenen Länge umgebrochen werden sollen. Dieser Parameter ist standardmäßig false, damit die Länge der Wörter erhalten bleibt. Wenn Sie nur einen der optionalen Parameter verwenden, benutzen Sie Schlüsselwortargumente wie truncatehtml(70, breakwords = false)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_characters"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anzahl der Zeichen, um die der Text gekürzt werden soll. Der Standardwert ist 255."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Überschreiben Sie die standardmäßigen „…“ Zeichen nach dem Abschneiden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "breakword"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolescher Wert. Bei true schneidet der Filter den Text auf die angegebene Länge ab. Wenn false, wird das letzte Wort verworfen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set html_text = \"<p>I want to truncate this text without breaking my HTML<p>\" %}\n{{ html_text|truncatehtml(28, \"...\" , false) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>I want to truncate this..</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unescape_html"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Konvertiert Text mit HTML-codierten Entitäten in ihre Unicode-Äquivalente."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"me & you\" %}\n{{ escape_string|unescape_html }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "me & you\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "union"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieser Filter gibt die Vereinigung von zwei Mengen oder Listen zurück. Die vom Filter zurückgegebene Liste enthält alle eindeutigen Elemente, die in beiden Listen enthalten sind."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die zweite Liste zur Vereinigung mit der ursprünglichen Liste."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|union([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1, 2, 3, 4, 5]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unique"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieser Filter extrahiert eine eindeutige Menge aus einer Sequenz oder einem Dict von Objekten. Wenn Sie ein Dict filtern, z. B. eine Liste von Beiträgen, die von einer Funktion zurückgegeben wird, können Sie angeben, welches Attribut verwendet wird, um Elemente im Dict zu deduplizieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attr"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt das Attribut an, das beim Filtern eines dict-Wertes verwendet werden soll"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"one\", \"one\", \"two\", \"three\" ] %}\n{{ my_sequence|unique }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[one, two, three]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unixtimestamp"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieser Filter wandelt ein datetime-Objekt in einen Unix-Zeitstempel um."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Sie sollten diesen Filter ", (0, _jsxRuntime.jsx)("u", {
          children: "nur"
        }), " für Variablen verwenden, die ein Datum zurückgeben. Ab dem 30. September 2024 gibt dieser Filter nicht mehr das aktuelle Datum zurück, wenn ein Nullwert übergeben wird. Nach diesem Datum wird bei einem Nullwert im Filter „30. September 2024“ zurückgegeben."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ local_dt }}\n{{ local_dt|unixtimestamp }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2017-01-30 17:11:44 1485814304000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "upper"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Konvertiert einen Wert in Großbuchstaben."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"text\" value=\"text to make uppercase\", export_to_template_context=True %}\n{{ widget_data.text.value|upper }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "TEXT TO MAKE UPPERCASE\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urlencode"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Versieht einen String mit Escape-Zeichen und URL-codiert ihn mit UTF-8-Formatierung. Akzeptiert sowohl Dictionaries und reguläre Strings als auch paarweise Iterablen."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"encode\" value=\"Escape & URL encode this string\", label=\"Enter slug\", export_to_template_context=True %}\n{{ widget_data.encode.value|urlencode }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Escape+%26+URL+encode+this+string\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urldecode"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Decodiert codierte URL-Strings zurück zur die ursprünglichen URL. Akzeptiert sowohl Dictionaries und reguläre Strings als auch paarweise Iterablen."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"decode\" value=\"Escape+%26+URL+decode+this+string\", label=\"Enter slug\", export_to_template_context=True %}\n{{ widget_data.decode.value|urldecode }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Escape & URL encode this string\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urlize"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Konvertiert URLs im Klartext in klickbare Links um. Wenn Sie dem Filter eine zusätzliche ganze Zahl übergeben, werden die URLs auf diese Zahl gekürzt. Der zweite Parameter ist ein boolescher Wert, der angibt, ob der Link rel=\"no follow\" ist. Mit dem letzten Parameter können Sie angeben, ob der Link in einer neuen Registerkarte geöffnet werden soll."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "shorten_text"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl, die die URLs auf die gewünschte Anzahl kürzt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "no_follow"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolescher Wert, der angibt, ob der Link rel=\"no follow\" ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "target=\"_blank\""
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt an, ob der Link in einem neuen Tab geöffnet wird."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"http://hubspot.com/\"|urlize }}\n{{ \"http://hubspot.com/\"|urlize(10,true) }}\n{{ \"http://hubspot.com/\"|urlize(\"\",true) }}\n{{ \"http://hubspot.com/\"|urlize(\"\",false,target=\"_blank\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<a href=\"//hubspot.com/\">http://hubspot.com/</a>\n<a href=\"//hubspot.com/\" rel=\"nofollow\">http://...</a>\n<a href=\"//hubspot.com/\" rel=\"nofollow\">http://hubspot.com/</a>\n<a href=\"//hubspot.com/\" target=\"_blank\">http://hubspot.com/</a>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "wordcount"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zählt die Anzahl der Wörter in einem String."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Wenn der String HTML enthält, verwenden Sie den Filter striptags, um eine genaue Zählung zu erhalten."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{%  set count_words = \"Count the number of words in this variable\"  %}\n{{ count_words|wordcount }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "8\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "wordwrap"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bewirkt den Umbruch von Wörtern bei einer bestimmten Zeichenanzahl. Dies funktioniert am besten in einem <pre>, da HubSpot standardmäßig Leerzeichen entfernt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "character_count"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anzahl der Zeichen, mit denen der Inhalt umbrochen werden soll."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set wrap_text = \"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam efficitur, ipsum non sagittis euismod, ex risus rhoncus lectus, vel maximus leo enim sit amet dui. Ut laoreet ultricies quam at fermentum.\" %}\n{{ wrap_text|wordwrap(10) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam efficitur, ipsum\nnon sagittis euismod, ex risus rhoncus lectus, vel maximus leo enim sit amet\ndui. Ut laoreet ultricies quam at fermentum.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "xmlattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Erstellen Sie einen HTML/XML-Attribut-String, der auf den Elementen in einem Dict basiert. Alle Werte, die weder „keine“ noch undefiniert sind, werden automatisch mit Escape-Zeichen versehen. Es wird automatisch ein Leerzeichen vor das Element gesetzt, wenn der Filter etwas zurückgegeben hat, es sei denn, der erste Parameter ist falsch."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "autospace"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolescher Wert, der automatisch ein Leerzeichen vor das Element setzt, sofern er nicht auf false gesetzt ist."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set html_attributes = {\"class\": \"bold\", \"id\": \"sidebar\"} %}\n<div {{ html_attributes|xmlattr }}></div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Gerenderte HTML-Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"bold\" id=\"sidebar\"></div>\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}