/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import enviro from 'enviro';
function loadScript(url, onLoad, onError) {
  const scriptElement = document.createElement('script');
  if (onError) {
    scriptElement.onerror = onError;
  }
  if (onLoad) {
    scriptElement.onload = onLoad;
  }
  document.head.appendChild(scriptElement);
  scriptElement.src = url;
}
function loadScriptPromise(url) {
  return new Promise((resolve, reject) => {
    loadScript(url, resolve, reject);
  });
}
export function loadWistiaApi() {
  loadScript('//fast.wistia.net/assets/external/E-v1.js');
}
export function loadVidyardApi(onLoad, onError) {
  loadScript('//play.vidyard.com/embed/v4.js', onLoad, onError);
}
let hsVideoLoadPromise;
export function loadHubSpotVideoApi() {
  if (hsVideoLoadPromise) {
    return hsVideoLoadPromise;
  }
  if (enviro.getShort('VIDEO_EMBED') === 'local') {
    hsVideoLoadPromise = loadScriptPromise('https://local.hsappstatic.net/video-embed/static/bundles/loader.js');
    return hsVideoLoadPromise;
  }
  const path = enviro.isQa() ? 'loader-qa.js' : 'loader.js';
  hsVideoLoadPromise = loadScriptPromise(`https://static.hsappstatic.net/video-embed/ex/${path}`);
  return hsVideoLoadPromise;
}