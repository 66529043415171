"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816620926;
const slug = exports.slug = 'guides/apps/authentication/oauth-quickstart-guide';
const title = exports.title = 'Travailler avec OAuth | Guide de démarrage rapide pour OAuth';
const name = exports.name = 'EMEA (FR) vNext Docs DP - OAuth Quickstart';
const metaDescription = exports.metaDescription = "Connectez-vous à HubSpot avec OAuth à l'aide de notre guide de démarrage rapide et de notre application Node.js.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "avant-de-commencer",
  "label": "Avant de commencer",
  "parent": null
}, {
  "depth": 1,
  "id": "fonctionnement",
  "label": "Fonctionnement",
  "parent": "avant-de-commencer"
}, {
  "depth": 1,
  "id": "dans-ce-guide",
  "label": "Dans ce guide",
  "parent": "avant-de-commencer"
}, {
  "depth": 0,
  "id": "application-de-d%C3%A9marrage-rapide",
  "label": "Application de démarrage rapide",
  "parent": null
}, {
  "depth": 0,
  "id": "obtenir-des-jetons-oauth-2.0",
  "label": "Obtenir des jetons OAuth 2.0",
  "parent": null
}, {
  "depth": 1,
  "id": "%C3%A9tape-1--cr%C3%A9er-l-url-d-autorisation-et-rediriger-l-utilisateur-vers-le-serveur-oauth-2.0-de-hubspot",
  "label": "Étape 1 : créer l'URL d'autorisation et rediriger l'utilisateur vers le serveur OAuth 2.0 de HubSpot",
  "parent": "obtenir-des-jetons-oauth-2.0"
}, {
  "depth": 3,
  "id": "exemples",
  "label": "Exemples",
  "parent": "obtenir-des-jetons-oauth-2.0"
}, {
  "depth": 3,
  "id": "encodage-d-un-%C3%A9tat-utilisateur-de-redirection-suppl%C3%A9mentaire",
  "label": "Encodage d'un état utilisateur de redirection supplémentaire",
  "parent": "obtenir-des-jetons-oauth-2.0"
}, {
  "depth": 1,
  "id": "%C3%A9tape-2--demande-de-consentement-de-l-utilisateur-par-hubspot",
  "label": "Étape 2 : demande de consentement de l'utilisateur par HubSpot",
  "parent": "obtenir-des-jetons-oauth-2.0"
}, {
  "depth": 1,
  "id": "%C3%A9tape-3--traitement-de-la-r%C3%A9ponse-du-serveur-oauth-2.0",
  "label": "Étape 3 : traitement de la réponse du serveur OAuth 2.0",
  "parent": "obtenir-des-jetons-oauth-2.0"
}, {
  "depth": 3,
  "id": "exemple-",
  "label": "Exemple :",
  "parent": "obtenir-des-jetons-oauth-2.0"
}, {
  "depth": 1,
  "id": "%C3%A9tape-4--%C3%A9change-du-code-d-autorisation-contre-des-jetons",
  "label": "Étape 4 : échange du code d'autorisation contre des jetons",
  "parent": "obtenir-des-jetons-oauth-2.0"
}, {
  "depth": 3,
  "id": "exemple-",
  "label": "Exemple :",
  "parent": "obtenir-des-jetons-oauth-2.0"
}, {
  "depth": 0,
  "id": "utilisation-des-jetons-oauth-2.0",
  "label": "Utilisation des jetons OAuth 2.0",
  "parent": null
}, {
  "depth": 3,
  "id": "exemple-",
  "label": "Exemple :",
  "parent": "utilisation-des-jetons-oauth-2.0"
}, {
  "depth": 0,
  "id": "actualisation-des-jetons-oauth-2.0",
  "label": "Actualisation des jetons OAuth 2.0",
  "parent": null
}, {
  "depth": 3,
  "id": "exemple-",
  "label": "Exemple :",
  "parent": "actualisation-des-jetons-oauth-2.0"
}, {
  "depth": 2,
  "id": "documents-associ%C3%A9s",
  "label": "Documents associés",
  "parent": "actualisation-des-jetons-oauth-2.0"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      hr: "hr",
      h3: "h3",
      ol: "ol",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      h5: "h5",
      pre: "pre",
      img: "img",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Guide de démarrage rapide pour OAuth"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Avant de commencer"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avant de commencer à utiliser OAuth avec HubSpot, vous devrez disposer :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/developers",
          children: "d'un compte de développeur"
        }), " ;"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "d'une application"
        }), " associée à votre compte de développeur ;"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["d'un compte HubSpot* sur lequel installer votre application (vous pouvez utiliser un compte existant ou ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "créer un compte de test"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "* Vous devez être un super administrateur pour installer une application dans un compte HubSpot."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fonctionnement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot prend en charge le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.okta.com/blog/2018/04/10/oauth-authorization-code-grant-type",
        children: "type d'octroi du code d'autorisation OAuth 2.0"
      }), ", qui peut être divisé en quatre étapes basiques :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Votre application ouvre une fenêtre de navigateur pour renvoyer l'utilisateur au serveur OAuth 2.0 de HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "L'utilisateur examine les autorisations demandées et accorde l'accès à l'application."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "L'utilisateur est redirigé vers l'application avec un code d'autorisation dans la chaîne de requête."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "L'application envoie une demande au serveur OAuth 2.0 pour échanger le code d'autorisation contre un jeton d'accès."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Dans ce guide"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#quick-start-app",
          children: "Application de démarrage rapide"
        }), " : une application de démonstration Node.js qui s'authentifie avec le serveur OAuth 2.0 de HubSpot"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#getting_tokens",
          children: "Obtenir des jetons OAuth 2.0"
        }), " : comment autoriser votre application avec les utilisateurs"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#using_tokens",
          children: "Utiliser des jetons OAuth 2.0"
        }), " : comment effectuer des requêtes avec un jeton"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#refreshing_tokens",
          children: "Actualiser des jetons OAuth 2.0"
        }), " : comment utiliser le jeton d'actualisation fourni par HubSpot"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Remarque :"
      }), " Les exemples de code dans ce guide sont écrits en JavaScript (Node.js)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Application de démarrage rapide"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous utilisez pour la première fois l'authentification OAuth avec les API de HubSpot, nous vous recommandons vivement de consulter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
        children: "l'application de démarrage rapide d'OAuth 2.0"
      }), ", rédigée en Node.js. Cette application est conçue pour vous aider à bien démarrer avec OAuth 2.0, en vous en montrant toutes les étapes décrites ci-dessous dans ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "Obtenir des jetons OAuth 2.0"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
          children: "Obtenir l'application sur Github"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtenir des jetons OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Étape 1 : créer l'URL d'autorisation et rediriger l'utilisateur vers le serveur OAuth 2.0 de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous envoyez un utilisateur au serveur OAuth 2.0 de HubSpot, la première étape consiste à créer l'URL d'autorisation. Cela identifiera votre application et définira les ressources (périmètres d'accès) demandées au nom de l'utilisateur. Les paramètres de requête que vous pouvez transmettre dans une URL d'autorisation sont affichés ci-dessous. Pour plus d'informations sur cette étape, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "documentation de référence"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Obligatoire ?"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemple"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Oui"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de client identifie votre application. Trouvez-le sur la page des paramètres de votre application."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Oui"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth#scopes",
              children: "périmètres d'accès"
            }), " demandés par votre application, séparés par des espaces en format URL-encoded."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contacts%20social"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Oui"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'URL vers laquelle l'utilisateur sera redirigé après avoir autorisé votre application pour les périmètres d'accès demandés. ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Pour les applications de production, https est obligatoire."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.exemple.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "optional_scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Non"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les périmètres d'accès qui sont facultatifs pour votre application seront abandonnées si le portail HubSpot sélectionné n'a pas accès à ces produits."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Non"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une valeur de chaîne unique pouvant être utilisée pour maintenir l'état de l'utilisateur lorsqu'il est redirigé vers votre application."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WeHH_yy2irpl8UYAvv-my"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois votre URL créée, commencez le processus OAuth 2.0 en y redirigeant l'utilisateur."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Exemples"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilisation d'une redirection côté serveur :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Build the auth URL\nconst authUrl =\n  'https://app.hubspot.com/oauth/authorize' +\n  `?client_id=${encodeURIComponent(CLIENT_ID)}` +\n  `&scope=${encodeURIComponent(SCOPES)}` +\n  `&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;\n+`&state=${encodeURIComponent(STATE)}`;\n\n// Redirect the user\nreturn res.redirect(authUrl);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilisation d'un lien HTML :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a\n  href=\"https://app.hubspot.com/oauth/authorize?scope=contacts%20social&redirect_uri=https://www.example.com/auth-callback&client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&state=xxxxxxxx\"\n  >Install</a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Encodage d'un état utilisateur de redirection supplémentaire"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Certaines applications peuvent avoir besoin de rediriger l'utilisateur vers différents endroits. Par exemple, une application peut souhaiter rediriger les utilisateurs vers différents sous-domaines de leur intégration (par exemple, utilisateurA.integration.com et utilisateurB.integration.com). Pour ce faire, utilisez le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "state"
      }), " pour encoder plus d'informations sur le statut de l'utilisateur :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "1. Générez et stockez une valeur nonce pour le paramètre de statut."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "2. Stockez le statut de l'utilisateur dans une banque de données locale en utilisant le nonce comme clé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "3. Incluez la valeur nonce comme paramètre de statut dans l'URL d'autorisation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "4. Lorsque l'utilisateur s'authentifie et est redirigé vers l'URL de redirection, validez le paramètre de statut et utilisez-le comme clé pour récupérer le statut de l'utilisateur stocké."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "5. À partir de là, redirigez l'utilisateur au besoin (par exemple, rediriger à nouveau vers une URL spécifique à l'utilisateur)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Étape 2 : demande de consentement de l'utilisateur par HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot affiche une fenêtre de consentement à l'utilisateur montrant le nom de votre application et une brève description des services d'API de HubSpot auxquels elle demande l'accès. L'utilisateur peut ensuite accorder l'accès à votre application."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/%5BExample%5D%20Install%20Screen.png",
        alt: "[Exemple] Écran d'installation"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Remarque :"
      }), " L'utilisateur installant l'application doit avoir accès à tous les périmètres d'accès demandés. Autrement, l'installation échouera et il sera dirigé vers une page d'erreur. Si cette page d'erreur s'affiche, l'utilisateur devra demander à un super administrateur d'installer l'application."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Votre application n'exécute aucune action à cette étape. Une fois l'accès octroyé, le serveur OAuth 2.0 de HubSpot enverra une requête à l'URI de rappel (callback) définie dans l'URL d'autorisation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Étape 3 : traitement de la réponse du serveur OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque l'utilisateur a répondu à la demande de consentement de l'étape 2, le serveur OAuth 2.0 envoie une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à l'URI de redirection indiquée dans votre URL d'authentification. S'il n'y a aucun problème et que l'utilisateur a approuvé la demande d'accès, la requête à l'URI de redirection aura un retour avec le paramètre de requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "code"
      }), " joint. Si l'utilisateur n'autorise pas l'accès, aucune requête ne sera envoyée."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "app.get('/oauth-callback', async (req, res) => {\n  if (req.query.code) {\n    // Handle the received code\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Étape 4 : échange du code d'autorisation contre des jetons"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que votre application reçoit un code d'autorisation de la part du serveur OAuth 2.0, elle peut échanger ce code contre un jeton d'accès et un jeton d'actualisation en envoyant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " en format form-urlencoded à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/oauth/v1/token"
      }), " avec les valeurs affichées ci-dessous. Pour plus d'informations sur cette étape, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "documentation de référence"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemple"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Doit être ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID de client de votre application"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Secret de client de votre application"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7c3ce02c-0f0c-4c9f-9700-92440c9bdf2d"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URI de redirection lorsque l'utilisateur autorise votre application"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.exemple.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le code d'autorisation reçu de la part du serveur OAuth 2.0"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5771f587-2fe7-40e8-8784-042fb4bc2c31"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const formData = {\n  grant_type: 'authorization_code',\n  client_id: CLIENT_ID,\n  client_secret: CLIENT_SECRET,\n  redirect_uri: REDIRECT_URI,\n  code: req.query.code\n};\n\nrequest.post('https://api.hubapi.com/oauth/v1/token', { form: formData }, (err, data) => {\n  // Handle the returned tokens\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le corps de la réponse du jeton sera composé de données JSON comme suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"refresh_token\": \"6f18f21e-a743-4509-b7fd-1a5e632fffa1\",\n  \"access_token\": \"CN2zlYnmLBICAQIYgZXFLyCWp1Yoy_9GMhkAgddk-zDc-H_rOad1X2s6Qv3fmG1spSY0Og0ACgJBAAADAIADAAABQhkAgddk-03q2qdkwdXbYWCoB9g3LA97OJ9I\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Remarque :"
      }), " Le jeton d'accès expirera après le nombre de secondes indiqué dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " de la réponse (actuellement 30 minutes). Pour plus d'informations sur la création d'un nouveau jeton d'accès, consultez Actualiser des jetons OAuth 2.0."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisation des jetons OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois le flux de code d'autorisation terminé, votre application est autorisée à faire des demandes au nom de l'utilisateur. Pour cela, fournissez le jeton en tant que jeton Bearer dans l'en-tête HTTP ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), ". Des détails spécifiques sont disponibles dans la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "documentation de référence"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "request.get(\n  'https://api.hubapi.com/contacts/v1/lists/all/contacts/all?count=1',\n  {\n    headers: {\n      Authorization: `Bearer ${ACCESS_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Les jetons d'accès reflètent les périmètres d'accès demandés à l'application et pas les autorisations ou les limites de ce qu'un utilisateur peut faire dans son compte HubSpot. Par exemple, si un utilisateur dispose des autorisations pour afficher uniquement les contacts détenus, mais autorise une demande pour le périmètre d'accès ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.read"
        }), ", le jeton d'accès résultant peut afficher tous les contacts du compte et pas seulement ceux détenus par l'utilisateur autorisant."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualisation des jetons OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les jetons d'accès OAuth expirent régulièrement. Ainsi, s'ils sont corrompus, les pirates informatiques n'y auront accès que pour une courte durée. Le cycle de vie du jeton est indiqué dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " lorsqu'un code d'autorisation est échangé contre un jeton d'accès."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Votre application peut échanger le jeton d'actualisation reçu contre un nouveau jeton d'accès en envoyant une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " en format form-urlencoded à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/oauth/v1/token"
      }), " avec les valeurs ci-dessous. Pour plus d'informations sur cette étape, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "documentation de référence"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemple"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Doit être ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID de client de votre application"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Secret de client de votre application"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7c3ce02c-0f0c-4c9f-9700-92440c9bdf2d"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URI de redirection lorsque l'utilisateur autorise votre application"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.exemple.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le jeton d'actualisation reçu lorsque l'utilisateur autorise votre application"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "b9443019-30fe-4df1-a67e-3d75cbd0f726"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const formData = {\n  grant_type: 'refresh_token',\n  client_id: CLIENT_ID,\n  client_secret: CLIENT_SECRET,\n  redirect_uri: REDIRECT_URI,\n  refresh_token: REFRESH_TOKEN\n};\n\nrequest.post('https://api.hubapi.com/oauth/v1/token', { form: formData }, (err, data) => {\n  // Handle the returned tokens\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le corps de la réponse du jeton sera composé de données JSON comme suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Sample response\n{\n  \"refresh_token\": \"6f18f21e-a743-4509-b7fd-1a5e632fffa1\",\n  \"access_token\": \"CN2zlYnmLBICAQIYgZXFLyCWp1Yoy_9GMhkAgddk-zDc-H_rOad1X2s6Qv3fmG1spSY0Og0ACgJBAAADAIADAAABQhkAgddk-03q2qdkwdXbYWCoB9g3LA97OJ9I\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le nouveau jeton d'accès peut ensuite être utilisé pour effectuer des appels au nom de l'utilisateur. Lorsque le nouveau jeton expire, vous pouvez suivre les mêmes étapes pour en récupérer un nouveau."
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documents associés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "Méthodes d'authentification sur HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "Travailler avec OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "Gérer les jetons"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}