"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 169342160030;
const slug = exports.slug = 'reference/api/marketing/calls-to-action-sdk';
const title = exports.title = 'Calls to Action widget SDK';
const name = exports.name = 'Calls to Action widget SDK';
const metaDescription = exports.metaDescription = 'You can use the Calls-to-actions JavaScript API to programmatically control HubSpot CTAs on your website.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "initializing",
  "label": "Initializing",
  "parent": null
}, {
  "depth": 0,
  "id": "refresh-ctas",
  "label": "Refresh CTAs",
  "parent": null
}, {
  "depth": 0,
  "id": "close-a-single-cta",
  "label": "Close a single CTA",
  "parent": null
}, {
  "depth": 0,
  "id": "close-all-ctas-on-a-page",
  "label": "Close all CTAs on a page",
  "parent": null
}, {
  "depth": 0,
  "id": "register-an-event-listener",
  "label": "Register an event listener",
  "parent": null
}, {
  "depth": 0,
  "id": "unregister-an-event-listener",
  "label": "Unregister an event listener",
  "parent": null
}, {
  "depth": 0,
  "id": "events",
  "label": "Events",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Calls-to-action JavaScript API"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Calls-to-action JavaScript API allows you to render HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/ctas/create-calls-to-action",
        children: "Calls-To-Actions"
      }), " on your website. You can use this API to programmatically control when a CTA appears or listen to events triggered when a user interacts with one of your CTAs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Initializing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The API is housed in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.HubSpotCallsToActions"
      }), " object, which provides access to all available methods. The object is created by the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code",
        children: "HubSpot tracking code"
      }), ", but may not be available immediately on page load. To defer accessing the API until it's initialized, you can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsCallsToActionReady"
      }), " helper."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsCallsToActionReady"
      }), " is an optional field you can define on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window"
      }), " object which enables you to specify code to be executed as soon as the API becomes available. If used, this field should be set to an array of functions. Once the API has been initialized, this event handler will check for the existence of this array and execute the functions in order."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "if (window.HubSpotCallsToActions) {\n  console.log('The api is already initialized');\n} else {\n  window.hsCallsToActionsReady = [\n    () => {\n      console.log('Now the api is ready');\n    },\n  ];\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Refresh CTAs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotCallsToActions.refresh"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Refresh and re-render CTAs, given the current page URL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you're using CTAs in a single-page application, this function can be useful to refresh the CTAs shown based on a route change (i.e., dynamically show a CTA based on a specific route). If HubSpotCallsToActions.refresh is called on a route where there are no CTAs present, any visible CTAs will be removed."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "HubSpotCallsToActions.refresh();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Close a single CTA"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotCallsToActions.close"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Closes a specific CTA based on its ID. Display frequency rules will be applied to a CTA once it's closed. Calling this function will have no effect on embedded CTAs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Closes a CTA with an ID of 5000\nHubSpotCallsToActions.close(5000);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Close all CTAs on a page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotCallsToActions.closeAll"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Close all CTAs on a page. Calling this function will have no effect on embedded CTAs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "HubSpotCallsToActions.closeAll();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Register an event listener"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotCallsToActions.on"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Register an event listener that will fire based on two arguments:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "event"
        }), ": the name of the event you want to define the handler for, provided as a string."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "handler"
        }), ": a function that defines the behavior you want to occur when the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "event"
        }), " fires."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "See the example snippet below for an example of defining a handler for the onCallToActionReady event. You can also review a full list of events you can define handlers in the table below."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "HubSpotCallsToActions.on('onCallToActionReady', ({ id }) => {\n  console.log('Call To Action rendered with id', id);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unregister an event listener"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotCallsToActions.off"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Unregister a previously registered event listener"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "event"
        }), ": the name of the event you want to unregister your previously defined handler for."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "handler"
        }), ": a function that will fire when successfully unregistered."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CTAs will emit various events throughout its lifecycle. These events are defined in the table below. You can define listeners for each of these events using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotCallsToAction.on"
      }), " function, as described in the section above."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Event name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onCallToActionReady"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The CTA has finished loading, and has been fully rendered."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onCallToActionViewed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The CTA has entered the user's viewport. This event will only fire once."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onCallToActionNavigated"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A link was clicked within the CTA."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onCallToActionFormSubmitted"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A form within the CTA was submitted. When triggered, the event payload will include the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "formId"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Events will also be posted to the parent window, which you can define listeners for using window.addEventListener."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the snippet below defines an event listener that checks for the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onCallToActionReady"
      }), " event being emitted."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.addEventListener('message', function handler({ data }) {\n  if (\n    data.type === 'hsCallsToActionCallback' &&\n    data.eventName === 'onCallToActionReady'\n  ) {\n    console.log('Call To Action rendered');\n  }\n});\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}