"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 38575989237;
const slug = exports.slug = 'guides/api/analytics-and-events/custom-events/custom-event-completions';
const title = exports.title = 'Enviar conclusões de eventos personalizados';
const name = exports.name = 'Enviar conclusões de eventos personalizados';
const metaDescription = exports.metaDescription = 'Os eventos personalizados permitem rastrear atividades avançadas com a API HTTP ou JavaScript. A API de eventos pode ser usada para obter detalhes sobre seus eventos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "definir-o-evento",
  "label": "Definir o evento",
  "parent": null
}, {
  "depth": 0,
  "id": "enviar-dados-de-evento",
  "label": "Enviar dados de evento",
  "parent": null
}, {
  "depth": 0,
  "id": "recuperar-dados-de-evento",
  "label": "Recuperar dados de evento",
  "parent": null
}, {
  "depth": 0,
  "id": "propriedades-de-evento",
  "label": "Propriedades de evento",
  "parent": null
}, {
  "depth": 0,
  "id": "relat%C3%B3rios-de-atribui%C3%A7%C3%A3o",
  "label": "Relatórios de atribuição",
  "parent": null
}, {
  "depth": 1,
  "id": "tipo-de-ativo",
  "label": "Tipo de ativo",
  "parent": "relat%C3%B3rios-de-atribui%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "t%C3%ADtulo-de-ativo",
  "label": "Título de ativo",
  "parent": "relat%C3%B3rios-de-atribui%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "fontes-de-intera%C3%A7%C3%A3o",
  "label": "Fontes de interação",
  "parent": "relat%C3%B3rios-de-atribui%C3%A7%C3%A3o"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      em: "em",
      strong: "strong",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Tag,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Enviar conclusões de eventos personalizados"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'sales_hub-enterprise', 'cms-enterprise', 'service_hub-enterprise', 'operations_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eventos personalizados são eventos definidos por conta que armazenam detalhes de eventos em propriedades de eventos. Há três tipos de eventos personalizados que você pode criar no HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
          children: "Elemento clicado"
        }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
          children: "URL visitado"
        }), " são eventos personalizados que o código de rastreamento preenche automaticamente com dados. Você pode personalizar ainda mais esses eventos atualizando seu código de rastreamento com a função ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code#events-js-api",
          children: "trackCustomEvent"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
          children: "Eventos rastreados manualmente"
        }), " são eventos personalizados exclusivos do seu negócio que não são capturados automaticamente pelo HubSpot ou por uma integração. Você pode enviar dados manualmente para esses eventos por meio dessa API."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba como criar um evento personalizado rastreado manualmente, enviar dados de evento por meio da API e usar dados de evento depois de capturados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Definir o evento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para enviar dados de conclusão de evento para o HubSpot, primeiro você precisa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "definir o evento"
      }), ", incluindo seus metadados, associações de objetos do CRM e propriedades. Você pode definir eventos usando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions",
        children: "API de definição de evento personalizado"
      }), " ou, se você tiver uma assinatura do ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        }), " Enterprise"]
      }), ", poderá ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events",
        children: "criar o evento no HubSpot"
      }), ". Ao criar o evento, o HubSpot incluirá um conjunto de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
        children: "propriedades de evento padrão"
      }), " que você pode usar para armazenar os dados do evento. Você também pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "criar propriedades adicionais"
      }), " para o evento. Essas propriedades podem ser criadas ou editadas a qualquer momento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de configurar o evento, você pode enviar dados para ele através da API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Enviar dados de evento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para enviar dados de evento ao HubSpot, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/events/v3/send"
      }), " com os dados do evento no corpo da solicitação. Antes de enviar dados de evento, revise os limites abaixo, pois exceder esses limites resultará em erro."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST to https://api.hubspot.com/events/v3/send\n{\n  \"eventName\": \"pe1234567_login_event\",\n  \"objectId\": \"608051\",\n  \"occurredAt\": \"2024-06-28T12:09:31Z\",\n  \"properties\": {\n    \"hs_city\": \"Cambridge\",\n    \"hs_country\": \"United States\",\n    \"hs_page_id\": \"53005768010\",\n    \"hs_page_content_type\": \"LANDING_PAGE\",\n    \"hs_touchpoint_source\": \"DIRECT_TRAFFIC\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O nome interno do evento. Você pode encontrar isso ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#get-existing-event-definitions",
              children: "consultando suas definições de evento existentes"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/reports/create-custom-behavioral-events-with-the-code-wizard#find-internal-name",
              children: "no aplicativo HubSpot"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ID do registro do CRM com o qual o evento será associado. Para contatos, você pode alternativamente usar o campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "utk"
            }), " para identificar o contato pelo endereço de e-mail ou pelo ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/tracking-code",
              children: "Token de Usuário da HubSpot"
            }), ". Todos os outros tipos de objetos exigem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Por padrão, o HubSpot definirá a data/hora de conclusão do evento para a hora em que a solicitação foi enviada. Para especificar a hora de conclusão do evento, inclua um carimbo de data/hora em um campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            }), " no corpo da solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " (", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://en.wikipedia.org/wiki/ISO_8601",
              children: "Formato ISO 8601"
            }), "). Isso pode ser especialmente útil para datar retroativamente dados de eventos para refletir com mais precisão a conclusão de eventos reais."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["As propriedades de evento às quais enviar dados. Isso pode incluir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
              children: "propriedades de evento padrão do HubSpot"
            }), " ou qualquer ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
              children: "propriedades personalizadas"
            }), " você definiu para o evento. A maioria das propriedades de evento padrão são propriedades de string, mas você pode revisar todas as propriedades de evento ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#get-existing-event-definitions",
              children: "consultando a definição do evento"
            }), " ou navegando até o evento no HubSpot. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#event-properties",
              children: "propriedades de evento"
            }), " abaixo."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Exceder qualquer um dos limites a seguir resultará em falha na solicitação:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "O rótulo da propriedade e o nome interno são limitados a 50 caracteres."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Propriedades de URL e de referência podem ter até 1024 caracteres, enquanto todas as outras propriedades podem ter até 256 caracteres."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Cada conclusão de evento pode conter dados para até 50 propriedades."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Os nomes internos da propriedade devem começar com uma letra e conter apenas letras minúsculas de a-z, números de 0-9 e sublinhados."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "As propriedades com o mesmo nome interno após minúsculas serão consideradas duplicatas e apenas uma delas será usada na conclusão. O HubSpot classificará em ordem lexicográfica ascendente e manterá as últimas propriedades vistas entre as primeiras 50 propriedades."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Há um limite de 500 definições de eventos exclusivos por conta."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Há um limite de 30 milhões de finalizações de eventos por mês."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar dados de evento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/event-analytics",
        children: "recuperar os dados de evento de um contato"
      }), ", faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/eventType={EVENT_NAME}&objectType=contact&objectId={CONTACT_ID}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A solicitação acima inclui:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "eventType:"
        }), " o nome interno do evento."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectType:"
        }), " o tipo de objeto do registro."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectId:"
        }), " o ID de contato"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propriedades de evento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os dados de eventos são armazenados em propriedades, seja no conjunto de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#hubspot-s-default-event-properties",
        children: "propriedades de evento padrão"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-definitions#define-new-properties",
        children: "propriedades definidas pelo cliente"
      }), ". Ao enviar dados de eventos, você poderá atualizar as propriedades incluindo-as em um objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"properties\": {\n    \"property1\": \"string\",\n    \"property2\": \"string\",\n    \"property3\": \"string\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os valores que você envia dependerão do tipo de propriedade de evento. A maioria das propriedades de evento padrão é texto de linha única (string). Entretanto, você pode criar propriedades personalizadas de qualquer tipo para cada evento. Revise a tabela abaixo ao formatar os valores de propriedade."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriedade Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enumeration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma string que representa um conjunto de opções. Ao enviar vários valores, separe-os com um ponto e vírgula. No HubSpot, esse tipo corresponde a propriedades de seleção suspensa, botão de opções e várias caixas de seleção."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Data/hora na forma de milissegundos de época ou ISO8601. No HubSpot, este tipo corresponde a propriedades de seletor de data."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "string"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma string de texto simples, com no máximo 65.536 caracteres. No HubSpot, esse tipo corresponde a propriedades de texto de linha única e de várias linhas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um valor numérico que contém dígitos numéricos e, na maioria das vezes, um número decimal. No HubSpot, este tipo corresponde às propriedades de número e cálculo."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para exibir as propriedades disponíveis de um evento:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, navegue até ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Relatórios"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Gerenciamento de dados"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eventos personalizados"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " do evento."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Propriedades"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Na tabela de propriedades, veja o tipo sob o nome da propriedade."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-event-properties-table.png",
        alt: "custom-event-properties-table"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Relatórios de atribuição"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eventos JavaScript, como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
        children: "elemento clicado"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
        children: "URL visitada"
      }), ", são preenchidos automaticamente com o tipo de ativo e os dados de interação para os relatórios de atribuição. Para incluir os mesmos dados para eventos rastreados manualmente, você precisará incluir manualmente os dados no corpo da solicitação usando as propriedades do evento. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/analyze-custom-behavioral-events",
        children: "análises de eventos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, conheça os valores disponíveis para tipos de ativos e origens de interação, juntamente com solicitações de exemplo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tipo de ativo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para atribuir um tipo de ativo específico a uma solicitação de evento comportamental personalizado, inclua a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_content_type"
      }), " no corpo da solicitação. Por exemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request body\n{\n  \"eventName\": \"pe1234567_manually_tracked_event\",\n  \"properties\": {\n    \"hs_page_id\": \"53005768010\",\n    \"hs_page_content_type\": \"LANDING_PAGE\"\n  },\n  \"objectId\": \"6091051\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Você também pode usar a propriedade ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), ". Se ambos ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_page_content_type"
        }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), " estiverem incluídos em uma única solicitação, ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_page_content_type"
        }), " substituirá o valor ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_asset_type"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os tipos de conteúdo padrão do HubSpot, como landing pages e posts de blog, podem ser representados com os seguintes valores:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Valor"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "STANDARD_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com uma página do site."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LANDING_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com uma landing page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com um post de blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_ARTICLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com um artigo da central de conhecimento."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para todos os outros tipos de ativos, use os seguintes valores:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Valor"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "AD"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com um anúncio, como um anúncio do Facebook ou do Google."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CALL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com uma chamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT_IMPORT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação através de uma importação de contato."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONVERSATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação relacionada a uma conversa HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CUSTOM_BEHAVIORAL_EVENT_NAME"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O nome interno de um evento personalizado, como", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pe123456_manually_tracked_event"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com um e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com uma página externa."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATIONS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação via integração."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma interação com um ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "evento de marketing"
            }), " ."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MEDIA_BRIDGE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma interação via ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/cms/media-bridge",
              children: "media bridge"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MEETING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com uma reunião."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SALES_EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com um e-mail de vendas individual."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SEQUENCE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com uma sequência."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com um post de mídia social."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OTHER"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma interação com um ativo que não está em uma das categorias acima."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Título de ativo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para atribuir um evento personalizado a um ativo, inclua a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_title"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_asset_title"
      }), " em sua solicitação com o nome do ativo formatado como uma string. Por exemplo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_page_title:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request body\n{\n  \"eventName\": \"pe1234567_manually_tracked_event\",\n  \"properties\": {\n    \"hs_page_title\": \"Sweepstakes Sign Up\",\n    \"hs_page_content_type\": \"LANDING_PAGE\"\n  },\n  \"objectId\": \"6091051\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fontes de interação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para atribuir um evento comportamental personalizado a uma fonte específica, inclua a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_touchpoint_source"
      }), " em sua solicitação com um dos seguintes valores:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Valor"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONVERSATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte da interação é uma conversa."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DIRECT_TRAFFIC"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte da interação é tráfego direto."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL_MARKETING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte da interação é um e-mail de marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_CRM"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte da interação é o CRM da HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte da interação é uma integração."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A fonte da interação é um ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "evento de marketing"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OFFLINE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte da interação é offline."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ORGANIC_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte de interação é a busca orgânica."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OTHER_CAMPAIGNS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte da interação é uma campanha não categorizada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAID_SEARCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte da interação é um anúncio de pesquisa pago."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAID_SOCIAL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte da interação é um anúncio de redes sociais pago."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "REFERRALS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte da interação é uma referência."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SALES"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte da interação é vendas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_MEDIA"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A fonte da interação é mídias sociais (não um anúncio de redes sociais pago)."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}