"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694136;
const slug = exports.slug = 'guides/cms/content/forms';
const title = exports.title = 'Formulaires HubSpot';
const name = exports.name = 'Formulaires HubSpot';
const metaDescription = exports.metaDescription = `Découvrez comment ajouter des formulaires à votre site web HubSpot ou à des pages externes à l'aide de modules, de HubL ou d'un code d'intégration. Ensuite, découvrez comment les styliser.`;
const toc = exports.toc = [{
  "depth": 0,
  "id": "le-module-de-formulaire-par-d%C3%A9faut",
  "label": "Le module de formulaire par défaut",
  "parent": null
}, {
  "depth": 1,
  "id": "clonage-du-module-par-d%C3%A9faut",
  "label": "Clonage du module par défaut",
  "parent": "le-module-de-formulaire-par-d%C3%A9faut"
}, {
  "depth": 0,
  "id": "champs-de-formulaire-dans-les-modules-personnalis%C3%A9s",
  "label": "Champs de formulaire dans les modules personnalisés",
  "parent": null
}, {
  "depth": 1,
  "id": "limiter-les-options-de-formulaire-dans-l-%C3%A9diteur",
  "label": "Limiter les options de formulaire dans l'éditeur",
  "parent": "champs-de-formulaire-dans-les-modules-personnalis%C3%A9s"
}, {
  "depth": 0,
  "id": "utilisation-du-code-d-int%C3%A9gration-de-formulaire",
  "label": "Utilisation du code d'intégration de formulaire",
  "parent": null
}, {
  "depth": 1,
  "id": "ajouter-une-internationalisation",
  "label": "Ajouter une internationalisation",
  "parent": "utilisation-du-code-d-int%C3%A9gration-de-formulaire"
}, {
  "depth": 0,
  "id": "%C3%A9v%C3%A9nements-de-formulaires",
  "label": "Événements de formulaires",
  "parent": null
}, {
  "depth": 0,
  "id": "style-de-formulaire",
  "label": "Style de formulaire",
  "parent": null
}, {
  "depth": 1,
  "id": "styliser-des-formulaires-via-le-module-de-formulaire-par-d%C3%A9faut-ou-la-balise-hubl",
  "label": "Styliser des formulaires via le module de formulaire par défaut ou la balise HubL",
  "parent": "style-de-formulaire"
}, {
  "depth": 1,
  "id": "styliser-des-formulaires-via-un-module-personnalis%C3%A9",
  "label": "Styliser des formulaires via un module personnalisé",
  "parent": "style-de-formulaire"
}, {
  "depth": 1,
  "id": "styliser-des-formulaires-ajout%C3%A9s-via-le-code-d-int%C3%A9gration-de-formulaire",
  "label": "Styliser des formulaires ajoutés via le code d'intégration de formulaire",
  "parent": "style-de-formulaire"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      strong: "strong",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Formulaires HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez des formulaires HubSpot pour capturer les informations des visiteurs du site, auxquelles vous pouvez accéder via HubSpot. Vous pouvez partager des liens vers des formulaires directement avec les utilisateurs, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/forms",
        children: "soumettre des données de formulaire via l'API"
      }), " et les intégrer sur les pages de votre site web à l'aide du CMS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les formulaires font partie intégrante de HubSpot et peuvent être créés dans un compte HubSpot de n'importe quel niveau d'abonnement. Non seulement les formulaires sont importants pour la conversion des clients, mais aussi parce que les données des formulaires peuvent être utilisées dans d'autres outils et ressources HubSpot, tels que le contenu intelligent, les listes, les workflows, la personnalisation du contenu, etc."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Après avoir ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms",
        children: "créé un formulaire HubSpot"
      }), ", vous pouvez l'ajouter à vos modèles et pages. Il existe plusieurs façons d'ajouter un formulaire à un modèle, en fonction de votre cas d'utilisation :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#the-default-form-module",
          children: "Utilisation du module de formulaire par défaut"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#form-fields-in-custom-modules",
          children: "Ajout d'un champ de formulaire à un module personnalisé"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-the-form-hubl-tag"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-the-form-embed-code",
          children: "Intégration à l'aide du code d'intégration de formulaire"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Le module de formulaire par défaut"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si votre modèle comporte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "des zones de glisser-déposer"
      }), ", les créateurs de contenu peuvent ajouter le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#form",
        children: "module de formulaire par défaut"
      }), " à une page à partir de l'éditeur de page, puis configurer les options de formulaire dans la barre latérale gauche."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour coder un module de formulaire directement dans un modèle avec des zones de glisser-déposer, mentionnez-le en tant que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"dnd_area\" class='body-container body-container__landing', label='Main section' %}\n {% dnd_section vertical_alignment='MIDDLE' %}\n  {% dnd_column width=6, offset=6 %}\n   {% dnd_row %}\n\n    <!-- Form module tag for use in templates -->\n    {% dnd_module path='@hubspot/form' %}\n    {% end_dnd_module %}\n\n   {% end_dnd_row %}\n  {% end_dnd_column %}\n {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour ajouter un module de formulaire à un modèle en dehors d'une zone de glisser-déposer, mentionnez-le plutôt comme un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), " standard."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"form\"\n path=\"@hubspot/form\"\n form={\n  \"form_id\": \"9e633e9f-0634-498e-917c-f01e355e83c6\",\n  \"response_type\": \"redirect\",\n  \"message\": \"Thanks for submitting the form.\",\n  \"redirect_id\": null,\n  \"redirect_url\": \"http://www.google.com\"\n }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Avec l'une ou l'autre implémentation, vous pouvez ajouter des paramètres au libellé de module pour spécifier des paramètres tels que le formulaire à utiliser et les options de redirection, comme indiqué dans l'exemple de code ci-dessus. Voir la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#form",
        children: "documentation des modules par défaut"
      }), " pour plus d'informations sur les paramètres disponibles."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Clonage du module par défaut"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En plus d'utiliser le module par défaut tel quel, vous pouvez le cloner pour le rendre modifiable, ce qui vous permet de le personnaliser au besoin. Par exemple, vous pouvez cloner le module de formulaire par défaut, ajouter un champ de couleur, puis envelopper le code HTML du module dans un libellé ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section>"
      }), " avec un style pour ajouter la couleur en arrière-plan :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le gestionnaire de conception de la barre latérale gauche, accédez au dossier ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "@hubspot"
        }), ", puis cliquez avec le bouton droit sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "form.module"
        }), " et sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cloner le module"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/clone-form-module.png",
        alt: "clone-form-module"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la barre latérale droite, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ajouter un champ"
        }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Couleur"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ajoutez un libellé ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<section>"
        }), " autour du contenu HTML, puis ajoutez un style pour référencer le champ de couleur, tel que :"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section style=\"background:{{ module.color_field.color }}\">"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/default-form-module-clone-section.png",
        alt: "default-form-module-clone-section"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Champs de formulaire dans les modules personnalisés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la création d'un module personnalisé, vous pouvez y inclure un formulaire en ajoutant un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#form",
        children: "champ de formulaire"
      }), ", ainsi que le bloc de code du champ au module HTML. Par exemple, vous pouvez ajouter un formulaire de demande de consultation à un module qui contient une image d'un produit et une proposition de valeur descriptive."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ajouter un champ de formulaire à un module personnalisé à partir du gestionnaire de conception :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la barre latérale droite de l'éditeur de module, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ajouter un champ"
        }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Formulaire"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-manager-select-form-field.png",
        alt: "design-manager-select-form-field"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Après avoir ajouté le champ, passez la souris sur le champ dans la barre latérale droite, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), " et sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copier le bloc de texte prédéfini"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-field-copy-snippet.png",
        alt: "module-field-copy-snippet"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Collez le bloc de code dans le champ HTML du module."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-field-paste-snippet.png",
        alt: "module-field-paste-snippet"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Limiter les options de formulaire dans l'éditeur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois ajouté à une page, les créateurs de contenu ont généralement le contrôle sur de nombreux aspects du formulaire, y compris le formulaire à utiliser et les champs de formulaire eux-mêmes. Cependant, vous pouvez limiter la quantité de contrôle donnée dans l'éditeur de page en modifiant ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "localement"
      }), " le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " du module de formulaire pour inclure les champs suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disable_inline_form_editing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet de définir la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "disable_inline_form_editing"
            }), " sur la valeur", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " pour masquer tous les contrôles de modification de formulaire intraligne dans le module de formulaire. Cela inclut les champs du formulaire, le texte du bouton d'envoi, les options de confidentialité et de consentement des données et le CAPTCHA."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "required_property_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : un tableau qui indique les formulaires pouvant être sélectionnés en fonction des types de propriétés des champs de formulaire. Les valeurs sont : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"CONTACT\""
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"COMPANY\""
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"TICKET\""
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, si vous avez créé un module qui ne doit être utilisé que pour les formulaires permettant aux visiteurs de contacter les différents services de votre entreprise, vous pouvez autoriser les créateurs de contenu à ne sélectionner que les formulaires qui utilisent les propriétés des tickets."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Form field\n{\n  \"id\": \"843b4f0f-0ed7-5b10-e86a-5cc8a0f11a0c\",\n  \"name\": \"form_field_1\",\n  \"display_width\": null,\n  \"label\": \"Form\",\n  \"required\": false,\n  \"locked\": false,\n  \"type\": \"form\",\n  \"disable_inline_form_editing\": true,\n  \"required_property_types\": [\"TICKET\"],\n  \"default\": {\n    \"response_type\": \"inline\",\n    \"message\": \"Thanks for submitting the form.\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisation du code d'intégration de formulaire"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu’un module ou une balise HubL n’est pas idéal, ou si vous souhaitez ajouter un formulaire à une page externe, vous pouvez l’intégrer à la place en utilisant le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#add-the-form-embed-code",
        children: "code d’intégration de formulaire"
      }), ". Vous pouvez également personnaliser le code d’intégration du formulaire pour étendre les fonctionnalités du formulaire. Affichez toutes les options de personnalisation du code d’intégration de formulaire et plus encore dans le guide de référence des formulaires."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les options de personnalisation de formulaire sont uniquement disponibles pour les formulaires créés dans HubSpot qui ont été ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#with-css-in-your-external-stylesheet-marketing-hub-professional-enterprise-or-legacy-marketing-hub-basic-only",
            children: "définis comme HTML brut"
          }), ". Le compte HubSpot doit disposer d’un abonnement ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            })
          }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "Content Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Pro"
          }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Entreprise"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les formulaires HubSpot ne doivent être chargés qu’à l’aide du fichier JavaScript hébergé par HubSpot. Faire une copie du code d'intégration du formulaire et l'héberger soi-même est ", (0, _jsxRuntime.jsx)("u", {
            children: "pas"
          }), " pris en charge Les améliorations apportées par HubSpot pour améliorer la sécurité, l’antispam, l’accessibilité et les performances ne se répercuteront pas sur votre formulaire si vous décidez d’héberger vous-même le code intégré de formulaire HubSpot."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous un exemple de code d’intégration de formulaire sans aucune personnalisation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script\n  charset=\"utf-8\"\n  type=\"text/javascript\"\n  src=\"//js.hsforms.net/forms/embed/v2.js\"\n></script>\n<script>\n  hbspt.forms.create({\n    region: 'na1',\n    portalId: '123456',\n    formId: 'df93f0c1-2919-44ef-9446-6a29f9a7f',\n  });\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre ou chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l'ID du compte HubSpot dans lequel le formulaire a été créé. Il est utilisé pour récupérer la définition du formulaire."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "formId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l’ID du formulaire, qui est utilisé pour récupérer la définition du formulaire."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "region"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-cloud-infrastructure-and-data-hosting-frequently-asked-questions",
              children: "région"
            }), " du compte dans laquelle le formulaire a été créé. Il est utilisé pour récupérer la définition du formulaire. Les valeurs possibles sont : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "na1"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eu1"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ajouter une internationalisation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par défaut, HubSpot fournit un nombre de messages de validation dans de nombreuses langues différentes, auxquels vous pouvez accéder à l’aide du paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "locale"
      }), ". Vous pouvez également ajouter des langues personnalisées ou remplacer les messages d’erreur et les mois/jours du sélecteur de date affichés sur le formulaire à l’aide du paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "translation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur l’internationalisation et les messages d’erreur de validation de formulaire dans la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/forms",
        children: "documentation de référence sur les formulaires"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hbspt.forms.create({\n  portalId: '',\n  formId: '',\n  locale: 'en',\n  translations: {\n    en: {\n      fieldLabels: {\n        email: 'Electronic mail',\n      },\n      required: \"Hey! That's required!\",\n      submitText: 'Custom Submit Button Text',\n      submissionErrors: {\n        MISSING_REQUIRED_FIELDS: 'Please fill in all required fields!',\n      },\n    },\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Événements de formulaires"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les formulaires offrent deux façons de lier des fonctionnalités à des événement : les rappels dans le code intégré de formulaire HubSpot et les événements de formulaire global. Pour en savoir plus sur les rappels de code d’intégration de formulaire disponibles et les événements de formulaire globaux, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/forms",
        children: "documentation de référence sur les formulaires"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example form embed code callback\nhbspt.forms.create({\n  portalId: '',\n  formId: '',\n  onBeforeFormSubmit: function ($form) {\n    // YOUR SCRIPT HERE\n  },\n});\n\n// Example listener\nwindow.addEventListener('message', (event) => {\n  if (\n    event.data.type === 'hsFormCallback' &&\n    event.data.eventName === 'onFormSubmitted'\n  ) {\n    someAnalyticsLib('formSubmitted');\n    console.log('Form Submitted! Event data: ${event.data}');\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Style de formulaire"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bien que HubSpot propose ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/set-global-form-colors-and-fonts",
        children: "un style de formulaire à partir d'un paramètre global"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms#style-and-preview-your-form",
        children: "un niveau de paramètre spécifique au formulaire"
      }), ", vous pouvez également créer un style de formulaire en fonction de son ajout à vos pages CMS."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Tous les formulaires générés sur le CMS Hub (sauf via le code d'intégration de formulaire) ignoreront les styles configurés via les paramètres de formulaire globaux ou les paramètres spécifiques à chaque formulaire."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Styliser des formulaires via le module de formulaire par défaut ou la balise HubL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les formulaires HubSpot ajoutés aux pages HubSpot peuvent être stylisés à l'aide de la CSS de votre site web. HubSpot comprend un certain nombre de classes et d'attributs différents sur les formulaires générés où vous pouvez appliquer un style. Pour commencer, veuillez consulter la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/elements/_forms.css",
        children: "CSS de formulaire du boilerplate HubSpot"
      }), ", qui présente les bonnes pratiques pour styliser les formulaires."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Styliser des formulaires via un module personnalisé"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les formulaires à l'intérieur de modules personnalisés peuvent être stylisés via une CSS dans le volet CSS du module dans le gestionnaire de conception. En étendant la CSS au module, le style accompagnera le module chaque fois qu'il sera ajouté à une page. Il est recommandé d'ajouter un encadrement au formulaire, puis d'utiliser ce wrapper en tant que sélecteur de niveau principal pour la CSS. Cela empêchera le style de formulaire de votre module personnalisé d'être remplacé par des styles supplémentaires dans la feuille de style principale de vos sites web. Voici une capture d'écran du module personnalisé avec un style de formulaire ajouté au volet CSS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/form-module-css-pane-4.jpg",
        alt: "Module personnalisé avec CSS"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Styliser des formulaires ajoutés via le code d'intégration de formulaire"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous utilisez le code d'intégration de formulaire, vous pouvez styliser le formulaire en utilisant les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/set-global-form-colors-and-fonts",
        children: "paramètres de style de formulaire globaux"
      }), " ou en utilisant la CSS de votre site web."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'utilisation des paramètres de style de formulaire globaux vous permet de configurer les paramètres par défaut pour chaque formulaire du compte. Vous pouvez également ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms#style-and-preview-your-form",
        children: "remplacer ces styles pour un formulaire dans l'éditeur de formulaire"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/global-form-styling-options-4.png",
        alt: "Styles de formulaire globaux"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous disposez d'un abonnement ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Pro"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), ", vous pouvez sélectionner l'option ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Définir comme formulaire HTML brut"
      }), " lors de la création d'un formulaire. Ce paramètre restitue le formulaire en HTML au lieu d'un iFrame, ce qui facilite le style du formulaire incorporé avec la CSS."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site",
        children: "style des formulaires intégrés dans la base de connaissances"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/set-as-raw-html-form-4.jpg",
        alt: "Paramètre Définir comme formulaire HTML brut"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}