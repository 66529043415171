import { APP_STARTED, INITIALIZE_AUTH } from './ActionTypes';
export const startApp = () => ({
  type: APP_STARTED
});
export const initializeAuth = ({
  user,
  gates,
  portal
}) => ({
  type: INITIALIZE_AUTH,
  user,
  gates,
  portal
});