'use es6';

import { Record } from 'immutable';
import PropTypes from 'prop-types';
const defaults = {
  uninitialized: false,
  pending: false,
  succeeded: false,
  warning: false,
  failed: false,
  errorDetails: null
};
class RequestStatus extends Record(defaults) {}
RequestStatus.uninitialized = new RequestStatus({
  uninitialized: true
});
RequestStatus.pending = new RequestStatus({
  pending: true
});
RequestStatus.succeeded = new RequestStatus({
  succeeded: true
});
RequestStatus.warning = new RequestStatus({
  warning: true
});
RequestStatus.failed = new RequestStatus({
  failed: true
});
export default RequestStatus;
export const RequestStatusPropType = PropTypes.instanceOf(RequestStatus);