"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694132;
const slug = exports.slug = 'guides/cms/content/modules/overview';
const title = exports.title = 'Überblick über Module';
const name = exports.name = 'Überblick über Module';
const metaDescription = exports.metaDescription = 'Ein Verständnis der Module ist der Schlüssel zum Verständnis von CMS Hub und seiner Leistungsfähigkeit. Module sind wiederverwendbare Komponenten, die in Vorlagen verwendet oder über Drag-&-Drop-Bereiche und flexible Spalten zu Seiten hinzugefügt werden können.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/team-member-editor.png';
const featuredImageAltText = exports.featuredImageAltText = 'Teammitglied-Modul-Editor';
const toc = exports.toc = [{
  "depth": 0,
  "id": "ein-beispiel",
  "label": "Ein Beispiel",
  "parent": null
}, {
  "depth": 1,
  "id": "die-benutzeroberfl%C3%A4che-f%C3%BCr-die-bearbeitung",
  "label": "Die Benutzeroberfläche für die Bearbeitung",
  "parent": "ein-beispiel"
}, {
  "depth": 0,
  "id": "modul-vs.-modulinstanz",
  "label": "Modul vs. Modulinstanz",
  "parent": null
}, {
  "depth": 0,
  "id": "fields.json",
  "label": "fields.json",
  "parent": null
}, {
  "depth": 1,
  "id": "verwendung-von-modulfelddaten-zum-rendern-von-html",
  "label": "Verwendung von Modulfelddaten zum Rendern von HTML",
  "parent": "fields.json"
}, {
  "depth": 0,
  "id": "verwenden-von-modulen-in-vorlagen",
  "label": "Verwenden von Modulen in Vorlagen",
  "parent": null
}, {
  "depth": 0,
  "id": "module-sind-eine-gro%C3%9Fartige-option-bei-den-tools-f%C3%BCr-barrierefreiheit",
  "label": "Module sind eine großartige Option bei den Tools für Barrierefreiheit",
  "parent": null
}, {
  "depth": 0,
  "id": "module-k%C3%B6nnen-die-lokalisierung-erleichtern",
  "label": "Module können die Lokalisierung erleichtern",
  "parent": null
}, {
  "depth": 0,
  "id": "erste-schritte",
  "label": "Erste Schritte",
  "parent": null
}, {
  "depth": 0,
  "id": "weitergehende-ressourcen",
  "label": "Weitergehende Ressourcen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ol: "ol",
      li: "li",
      h2: "h2",
      img: "img",
      h3: "h3",
      ul: "ul",
      strong: "strong",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Überblick über Module"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Verständnis der Module ist der Schlüssel zum Verständnis von CMS Hub und seiner Leistungsfähigkeit. Module sind wiederverwendbare Komponenten, die in Vorlagen verwendet oder über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "Drag-&-Drop-Bereiche"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
        children: "flexible Spalten"
      }), " zu Seiten hinzugefügt werden können. Zusätzlich zu den Modulen, die HubSpot bereitstellt, können Entwickler ihre eigenen Module für alles von Kundenreferenzen bis zu Fotogalerien erstellen. Module werden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "mit den Tools für lokale Entwicklung"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/create-and-edit-modules",
        children: "mithilfe des Design-Managers"
      }), " erstellt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Modul besteht aus zwei Teilen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#the-user-interface-for-editing",
          children: "Benutzeroberfläche"
        }), ", die aus einer Liste von Feldern besteht, die der Benutzer bei der Bearbeitung einer Modulinstanz sieht."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ein HTML+HubL-Vorlagenfragment mit zugehörigem CSS und JS, das definiert, wie HTML generiert wird"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein Beispiel"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um besser zu verstehen, was ein Modul ist, sehen wir uns ein einfaches „Teammitglied“-Modul an. Das Modul besteht aus einem Foto, dem Namen des Teammitglieds, seinem Titel und einem kurzen Lebenslauf und sieht wie ein Teil einer CMS-Webseite aus:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/team-member-module-instance.png",
        alt: "Teammitglied-Modulinstanz"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Die Benutzeroberfläche für die Bearbeitung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Entwickler erstellt die Benutzeroberfläche (UI) für Module mithilfe von Feldern. Der Entwickler wählt dann die gewünschten Felder anhand der Art des zu erstellenden Moduls, der benötigten Daten und des Bearbeitungserlebnisses aus. In diesem Fall umfasst das Modul:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ein Bildfeld für das Foto eines Teammitglieds"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "zwei Textfelder für den Namen und die Position des Teammitglieds"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ein Rich-Text-Feld für einen kurzen Lebenslauf."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn ein Content-Autor ein Modul bearbeitet, wird die Benutzeroberfläche auf der Grundlage der Felder, die der Entwickler dem Modul hinzugefügt hat, und der jeweiligen Konfiguration der einzelnen Felder aufgebaut."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/team-member-editor.png",
        alt: "Teammitglied-Modul-Editor"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modul vs. Modulinstanz"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt zwei Begriffe, die häufig im Zusammenhang mit Modulen verwendet werden. Es ist wichtig, den Unterschied zwischen ihnen zu kennen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modul"
        }), " – wiederverwendbare Komponenten, die zu Vorlagen und Seiten hinzugefügt werden können."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modulinstanz"
        }), " – die einzelnen gerenderten Module auf der Seite. Sie können separate Feldwerte haben und daher anders aussehen als andere Modulinstanzen, die für dasselbe Modul verwendet werden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "fields.json"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Felder für ein Modul werden in JSON als ein Array von Objekten definiert. Jedes Feld verfügt über einen Namen, einen Typ und einen Standardwert. Je nach Art des Feldes, das die Bearbeitung steuert, sind auch andere Eigenschaften verfügbar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n[\n  {\n    \"name\": \"team_member_photo\",\n    \"label\": \"Team Member Photo\",\n    \"required\": true,\n    \"responsive\": true,\n    \"resizable\": true,\n    \"type\": \"image\",\n    \"default\": {\n      \"src\": \"\",\n      \"alt\": \"\"\n    }\n  },\n  {\n    \"name\": \"team_member_name\",\n    \"label\": \"Team member name\",\n    \"required\": true,\n    \"type\": \"text\",\n    \"default\": \"Joshua Beck\"\n  },\n  {\n    \"name\": \"team_member_position\",\n    \"label\": \"Team member position\",\n    \"required\": true,\n    \"type\": \"text\",\n    \"default\": \"CEO, Co-Founder\"\n  },\n  {\n    \"name\": \"team_member_bio\",\n    \"label\": \"Team member bio\",\n    \"required\": true,\n    \"type\": \"richtext\",\n    \"default\": \"<p>Joshua has over 20 years of experience in the tech industry. He helped start this company in 2015 with the mission of helping people grow. In his spare time he loves hanging out with his kids, going to the beach, and cooking.</p>\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Weitere Informationen zu allen verfügbaren Feldern finden Sie im Artikel zu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "Modul- und Designfeldern"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Verwendung von Modulfelddaten zum Rendern von HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Werte für die einzelnen Felder sind im HTML+HubL-Fragment für ein Modul über eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), "-Variable verfügbar. Die Daten für die einzelnen Felder können über die Eigenschaften der Modulvariablen abgerufen werden. Beim Beispiel des Teammitglied-Moduls kann der Name des Teammitglieds über ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ module.team_member_name }}"
      }), " aufgerufen werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<section class=\"team-member\">\n  <img class=\"team-member__image\" src=\"{{ module.team_member_image.src }}\" alt=\"{{ module.team_member_image.alt }}\">\n  <h3 class=\"team-member__name\">{{ module.team_member_name }}</h3>\n  <p class=\"team-member__position\">{{ module.team_member_position }}</p>\n  <div class=\"team-member__bio\">{{ module.team_member_bio }}</div>\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwenden von Modulen in Vorlagen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Module werden mithilfe des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates#basic-module-syntax",
        children: "module"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates#block-syntax",
        children: "module_block"
      }), "- oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_module"
      }), "-Tags und durch Angabe des Pfades zum Modul als Parameter zu Vorlagen hinzugefügt. Die Standardwerte für Felder in einem Modul können auch auf der Vorlagenebene überschrieben werden, indem wie im zweiten Teil des folgenden Beispiels gezeigt dem module-Tag, das dem Feldnamen entspricht, Parameter hinzugefügt werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"unique_identifier\" path=\"/modules/team-member.module\" %}\n\n\n{# override default values in a module instance #}\n\n{% module \"unique_identifier\"\n  path=\"/modules/team-member.module\",\n  team_member_name=\"Brian Halligan\",\n  team_member_position=\"CEO\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Module können nicht ineinander verschachtelt werden. In den meisten Fällen, in denen Sie dies vorhaben, geschieht dies in der Regel aus Layout-Gründen. Abschnitte in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "Drag-&-Drop-Bereichen"
        }), " sind oft die bessere Lösung."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Module sind eine großartige Option bei den Tools für Barrierefreiheit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Module werden auf der gesamten Website verwendet, manchmal auf mehreren Seiten, sogar mehrfach auf einer Seite. Aus diesem Grund kann ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "die Erstellung des HTML, CSS und JS Ihres Moduls im Hinblick auf mehr Barrierefreiheit"
      }), " einen großen Einfluss darauf haben, wie benutzerfreundlich Ihre Website für Menschen mit und ohne Behinderungen oder Beeinträchtigungen ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Module können die Lokalisierung erleichtern"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ähnlich wie bei der Barrierefreiheit ermöglicht die Erstellung von Modulen, bei denen der gesamte Inhalt auf Feldern basiert, eine spätere Lokalisierung. Sie können zum Beispiel ein Modul „Ausgewählte Artikel“ haben. Anstatt den Text „Ausgewählte Artikel“ zu hart zu codieren, verwenden Sie ein Text- oder Rich-Text-Feld. Dann kann der Text für andere Sprachen geändert werden. Mehr über die Lokalisierung im CMS erfahren Sie unter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/multi-language-content",
        children: "Mehrsprachigkeit"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erste Schritte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zum Einstieg können Sie sich unserer Tutorial ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "Erste Schritte mit Modulen"
      }), " ansehen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Weitergehende Ressourcen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Konfigurieren eines Moduls"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Verwenden von Modulen in Vorlagen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Standardmodule"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Der Modul-Editor"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}