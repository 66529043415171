"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77308536928;
const slug = exports.slug = 'reference/api/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration';
const title = exports.title = 'Migrate an existing ecommerce bridge API integration';
const name = exports.name = 'Migrate an existing ecommerce bridge API integration';
const metaDescription = exports.metaDescription = 'If your Ecommerce Bridge integration uses API keys, you will need to update it to a private app, then implement the following API changes. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "migrating-contact%2C-product%2C-deal-%26-line-items-sync",
  "label": "Migrating contact, product, deal & line items sync",
  "parent": null
}, {
  "depth": 0,
  "id": "migrating-the-external-object-id",
  "label": "Migrating the external object id",
  "parent": null
}, {
  "depth": 0,
  "id": "migrating-ecommerce-properties",
  "label": "Migrating ecommerce properties",
  "parent": null
}, {
  "depth": 0,
  "id": "migrating-import-api",
  "label": "Migrating import API",
  "parent": null
}, {
  "depth": 0,
  "id": "migrating-ecommerce-pipeline",
  "label": "Migrating ecommerce pipeline",
  "parent": null
}, {
  "depth": 0,
  "id": "migrating-vast-email-templates",
  "label": "Migrating VAST email templates",
  "parent": null
}, {
  "depth": 1,
  "id": "new-customer-email-template",
  "label": "New customer email template",
  "parent": "migrating-vast-email-templates"
}, {
  "depth": 1,
  "id": "re-engaging-email-template",
  "label": "Re-engaging email template",
  "parent": "migrating-vast-email-templates"
}, {
  "depth": 1,
  "id": "abandon-cart-email-template",
  "label": "Abandon cart email template",
  "parent": "migrating-vast-email-templates"
}, {
  "depth": 0,
  "id": "migrating-the-ecommerce-dashboard",
  "label": "Migrating the ecommerce dashboard",
  "parent": null
}, {
  "depth": 0,
  "id": "migrating-embedded-workflows",
  "label": "Migrating embedded workflows",
  "parent": null
}, {
  "depth": 0,
  "id": "ecommerce-settings-page-in-app",
  "label": "Ecommerce settings page in app",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      strong: "strong",
      em: "em",
      h3: "h3",
      br: "br",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Migrate an existing Ecommerce Bridge API integration"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
        children: "API keys will be sunsetted"
      }), " on November 30th, 2022. If your Ecommerce Bridge integration uses API keys, you will need to update it to a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
        children: "private app"
      }), ", then implement the following API changes below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrating contact, product, deal & line items sync"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Learn about the different APIs you can use for the contact, product, deal and line items sync. The respective API documentation includes details on relevant scope requirements."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For contact sync, you can instead use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "Contacts API"
        }), ". Specifically, you can make ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DELETE"
        }), " requests to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For product sync, you can instead use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/products",
          children: "Products API"
        }), ". Specifically, you can make ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DELETE"
        }), " requests to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/products"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For deal sync, you can instead use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "Deals API"
        }), ". Specifically, you can use make ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DELETE"
        }), " requests to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For line item sync you can instead use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "Line Items API"
        }), ". Specifically, you can make ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DELETE"
        }), " requests to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to creating and maintaining these objects, you will also need to establish the relationships between them using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "Associations API"
      }), ". You can create associations by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/associations"
      }), ". For example, you associate contacts to deals, or line items to deals and products."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The above APIs do not automatically retry if they return errors. If your integration experiences errors interacting with the above APIs your system will need to retry API calls."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " when using the products, deals, and line items API, deduplication will not happen automatically. When you create an object using one of these APIs, you should record the ID so you know when to update existing products, deals, or line items instead of creating new ones."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrating the external object id"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Ecommerce Bridge API used the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "externalObjectId"
      }), " field to sync objects. Moving forward, you should maintain the HubSpot record IDs for each record in your internal system."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For product, deals, and line items, you can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "create a unique identifier"
      }), " for your records and use this identifier when creating and updating records. When creating this unique identifier, make sure to set the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hasUniqueValue"
      }), " field to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " so that any records created in the future will not have the same value. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "creating unique identifiers"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrating ecommerce properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any properties prefixed with _ip__ecomm_bridge___ will remain in existing users' HubSpot accounts, but for net new integrations, developers will need to use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "Properties API"
      }), "to define their own properties."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following properties were created automatically in your account when using the Ecommerce Bridge API:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Contact"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ecommerce contact"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Source store"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Deal"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Abandoned cart URL"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Discount savings"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ecommerce deal"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Order number"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Shipment IDs"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Source store"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tax price"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Product"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ecommerce product"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Image URL"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Source store"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrating import API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For import, you can instead use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/imports",
        children: "Import API"
      }), ". Specifically, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrating ecommerce pipeline"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pipelines are a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "paid feature"
      }), ". With this sunset, there will no longer be an option to create a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "free"
      }), " ecommerce pipeline in every account. Moving forward, ecommerce pipelines can only be created in accounts that have not reached its pipeline limit. If the ecommerce pipeline has already been created in a user's account, when the API is sunsetted that pipeline will ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "not be removed"
      }), ". Developers should use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/pipelines",
        children: "Pipeline API"
      }), " to find all existing pipelines and continue to sync deals in that existing pipeline. Specifically, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/pipelines/deal"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Or, you can store the ecommerce tag or pipeline name in a property on the deal record."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you attempt to create a pipeline in a free account, you will receive the following error: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "context:{maximum pipelines:[\"1\" ]},category:\"API_LIMIT\"}."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrating VAST email templates"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot sunsetted VAST email templates in 2021. As a result, these templates will also be sunsetted for the Ecommerce Bridge API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "New customer email template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Professional"
      }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " user, you can add the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/add-a-product-module-to-your-marketing-email",
        children: "product module in-app"
      }), " to marketing emails. Or you can create a coded file with the email template type using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#email",
        children: "Template overview documentation"
      }), ". Make sure to publish the email template so it is available to use when creating emails."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Re-engaging email template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can create a coded file with the email template type using this ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#email",
        children: "Template overview documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abandon cart email template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://knowledge.hubspot.com/email/add-a-product-module-to-your-marketing-email#add-an-abandoned-cart-module",
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: "Abandoned cart"
        }), " module"]
      }), " only works when using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/connect-hubspot-and-shopify",
        children: "Shopify integration"
      }), ", and is ", (0, _jsxRuntime.jsx)("u", {
        children: "not"
      }), " currently supported when building a custom integration."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrating the ecommerce dashboard"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ecommerce dashboard and template will be sunsetted for ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "net new users"
      }), ". Accounts that already have a ecommerce dashboard or the ecommerce dashboard template can still use it, but can no longer create an ecommerce template via open APIs."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Moving forward, learn how to build ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/create-reports-with-the-custom-report-builder",
        children: "reports"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/dashboards/manage-your-dashboards",
        children: "dashboards"
      }), " in HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Migrating embedded workflows"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Customer welcome"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Abandon cart"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Re-engaging customer"
      }), " embedded workflow, will also be sunsetted."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create workflows, you can instead use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/create-manage-workflows#create-a-workflow",
        children: "Create & Manage Workflows API"
      }), ". Specifically, you can make a POST request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/automation/v3/workflows"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you cannot recreate the abandon cart workflow using the workflows API because it does not support deal-based workflows."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ecommerce settings page in app"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ecommerce"
      }), " navigation item in the side menu and the ecommerce settings page will be sunsetted.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/ecomm.png",
        alt: "ecomm"
      }), "As partners move their integrations to public apps, their apps will then be listed on the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Connected Apps"
      }), " page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/list-of-apps.png",
        alt: "list-of-apps"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If developers would like to have a settings page for their integration they can build one using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/create-an-app-settings-page",
        children: "Settings App API"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "."
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}