"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45280249506;
const slug = exports.slug = 'guides/api/crm/commerce/quotes';
const title = exports.title = 'API du CRM | Devis';
const name = exports.name = 'EMEA (FR) vNext Docs DP - Quotes';
const metaDescription = exports.metaDescription = "Les devis sont utilisés pour partager des informations tarifaires avec des prospects. Les points de terminaison des devis vous permettent de récupérer ces données et de les synchroniser entre HubSpot et d'autres systèmes.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "pr%C3%A9sentation",
  "label": "Présentation",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-un-devis",
  "label": "Créer un devis",
  "parent": null
}, {
  "depth": 1,
  "id": "activer-les-signatures-%C3%A9lectroniques",
  "label": "Activer les signatures électroniques",
  "parent": "cr%C3%A9er-un-devis"
}, {
  "depth": 1,
  "id": "activer-les-paiements",
  "label": "Activer les paiements",
  "parent": "cr%C3%A9er-un-devis"
}, {
  "depth": 0,
  "id": "ajout-d-associations",
  "label": "Ajout d'associations",
  "parent": null
}, {
  "depth": 1,
  "id": "r%C3%A9cup%C3%A9ration-des-identifiants-pour-les-associations",
  "label": "Récupération des identifiants pour les associations",
  "parent": "ajout-d-associations"
}, {
  "depth": 1,
  "id": "cr%C3%A9ation-d-associations",
  "label": "Création d'associations",
  "parent": "ajout-d-associations"
}, {
  "depth": 1,
  "id": "associer-des-signataires-de-devis",
  "label": "Associer des signataires de devis",
  "parent": "ajout-d-associations"
}, {
  "depth": 0,
  "id": "cr%C3%A9er-un-devis-avec-les-associations-requ%C3%AAte-unique",
  "label": "Créer un devis avec les associations (requête unique)",
  "parent": null
}, {
  "depth": 0,
  "id": "mettre-%C3%A0-jour-l-%C3%A9tat-du-devis",
  "label": "Mettre à jour l'état du devis",
  "parent": null
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-des-devis",
  "label": "Récupérer des devis",
  "parent": null
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s-d%C3%A9finies-par-l-%C3%A9tat-du-devis",
  "label": "Propriétés définies par l'état du devis",
  "parent": "r%C3%A9cup%C3%A9rer-des-devis"
}, {
  "depth": 0,
  "id": "p%C3%A9rim%C3%A8tres-d-acc%C3%A8s",
  "label": "Périmètres d'accès",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      h2: "h2",
      ol: "ol",
      li: "li",
      code: "code",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Table,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Table) _missingMdxReference("Table", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Devis"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez l'API Devis pour créer, gérer et récupérer des devis afin de partager des informations sur les prix avec des acheteurs potentiels. Une fois configuré, un devis peut être partagé avec un acheteur via une URL spécifiée ou un PDF. Les utilisateurs peuvent également ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/use-quotes#manage-quotes",
        children: "gérer les devis dans HubSpot"
      }), " pour ajouter des détails, mettre à jour les associations, etc."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous avez configuré des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/set-up-payments",
        children: "paiements"
      }), " ou le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
        children: "traitement des paiements Stripe"
      }), ", vous pouvez configurer un devis à payer via cette API. En savoir plus sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes#enable-payments",
        children: "devis payables"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/example-quote-api.png",
        alt: "example-quote-api"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de cas d'utilisation :"
      }), " vous devez créer une proposition de contrat pour un client qui souhaite acheter l'un de vos forfaits annuels de services d'audit SEO."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous comment créer un devis via l'API et configurer ses différentes propriétés, associations, états, etc."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Présentation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le processus de création de devis peut être divisé en plusieurs étapes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer un devis :"
        }), " créez un devis avec quelques détails, tels que le nom et la date d'expiration. Vous pouvez également configurer le devis pour ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#enable-e-signatures",
          children: "activer les signatures électroniques"
        }), " et les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#enable-payments",
          children: "paiements"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configurer des associations :"
        }), " associez le devis à d'autres types d'objets CRM, tels que des lignes de produits, un modèle de devis, une transaction, etc. Au cours de l'étape suivante, le devis héritera des valeurs de propriété de certaines de ces fiches d'informations associées ainsi que des paramètres du compte."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Définir l'état du devis :"
        }), " définissez l'état du devis pour refléter sa prédisposition à être partagé avec les acheteurs. Lorsque vous définissez l'état du devis, par exemple en le transformant en brouillon modifiable ou en devis publié accessible au public, il ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#properties-set-by-quote-state",
          children: "héritera de certaines propriétés"
        }), " de ses fiches d'informations CRM et paramètres de compte associés."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Partager le devis :"
        }), " une fois un devis publié, vous pouvez le partager avec vos acheteurs."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un devis"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un devis, vous devez d'abord configurer ses détails de base en effectuant une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quotes"
      }), ". Vous devez ensuite effectuer un appel distinct pour associer le devis à d'autres objets, tels que le modèle de devis, les lignes de produit ou une transaction."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["En fonction de votre workflow préféré, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-quote-with-associations-single-request-",
          children: "créer un devis avec des associations via une seule requête POST"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans le corps de l'article, ajoutez les propriétés requises suivantes pour configurer ses détails de base."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100.018%",
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The name of the quote."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100.018%",
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The date that the quote expires."
                  })]
                })
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The above are just the minimum required properties to get a quote started but ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/quotes#adding-associations",
              children: "other properties"
            }), " are required to publish a quote. To see all available quote properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm/v3/properties/quotes"
            }), ". Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/properties",
              children: "properties API"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\"\n  }\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La réponse comprendra un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "identifiant"
      }), ", que vous utiliserez pour continuer à configurer le devis. Vous pouvez mettre à jour les propriétés du devis à tout moment en effectuant une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quotes/{quoteId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Activer les signatures électroniques"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour activer les signatures électroniques sur le devis, incluez la propriété booléenne ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_esign_enabled"
      }), " dans votre corps de demande avec la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". Notez que vous ne pourrez pas ajouter de contresignataires via l'API. Ceux-ci devront donc être ajoutés dans HubSpot avant de publier le devis. Vous ne pouvez pas non plus publier de devis avec la signature électronique activée si vous avez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes#monitor-e-signature-usage",
        children: "dépassé votre limite mensuelle de signatures électroniques"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\",\n    \"hs_esign_enabled\": \"true\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous devrez associer le devis aux signataires du devis ultérieurement. Bien que les contacts signant le devis existent en tant que contacts dans HubSpot, ils sont stockés en tant que types d'association distincts des contacts. Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#associating-quote-signers",
        children: "association de devis avec des signataires de devis"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Activer les paiements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour activer les paiements sur un devis, incluez la propriété booléenne ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_enabled"
      }), " dans votre corps de la requête avec la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". En fonction de votre prestataire de services de paiement et des modes de paiement acceptés, vous devrez également définir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_type"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_allowed_payment_methods"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " le compte HubSpot doit avoir configuré les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/set-up-payments",
          children: "paiements"
        }), " ou le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
          children: "traitement des paiements Stripe"
        }), " avant d'utiliser cette fonctionnalité."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_payment_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Lorsqu'il est défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", il permet au devis de collecter le paiement en utilisant les paiements ou le traitement des paiements Stripe. Par défaut sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_payment_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Détermine le prestataire de services de paiement à utiliser. La valeur peut être ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BYO_STRIPE"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_allowed_payment_methods"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les modes de paiement à utiliser (par exemple, carte de crédit)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_collect_billing_address"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Lorsqu'elle est définie sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", permet à l'acheteur de saisir son adresse de facturation lors du paiement."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_collect_shipping_address"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Lorsqu'elle est définie sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", permet à l'acheteur de saisir son adresse de livraison lors du paiement."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, pour créer un devis et activer les paiements par carte de crédit/débit ou ACH, votre demande ressemblera à ceci :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\",\n    \"hs_payment_enabled\": \"true\",\n    \"hs_payment_type\": \"HUBSPOT\",\n    \"hs_allowed_payment_methods\": \"CREDIT_OR_DEBIT_CARD;ACH\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour suivre le paiement, HubSpot mettra automatiquement à jour les propriétés ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_status"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_date"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lorsque vous publiez un devis avec les paiements activés, HubSpot définit automatiquement la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), " sur EN ATTENTE."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous utilisez ACH, lorsque le paiement est traité, HubSpot définira automatiquement la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), " sur TRAITEMENT."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Une fois le paiement confirmé, HubSpot définira automatiquement la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), " sur PAYÉ."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Une fois le devis payé, HubSpot définira automatiquement ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_date"
        }), " à la date et à l'heure de confirmation du paiement."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Une fois le paiement confirmé, le paiement est automatiquement associé au devis. Si vous souhaitez obtenir plus d'informations sur le paiement, reportez-vous à l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "API outils de paiement"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ajout d'associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un devis complet, vous devez l'associer à d'autres fiches d'informations CRM, telles que des lignes de produit, à l'aide de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "l'API des associations"
      }), ". Le tableau ci-dessous indique quelles associations de fiches d'informations CRM sont requises pour un devis complet et lesquelles sont facultatives. Poursuivez votre lecture pour en savoir plus sur la récupération des identifiants et leur utilisation pour créer les associations requises."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Type d'objet"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Obligatoire"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/line-items",
              children: "Lignes de produit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Oui"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les biens et/ou services vendus via le devis. Vous pouvez créer des lignes de produits à partir de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "produits de votre bibliothèque de produits"
            }), " ou créer des lignes de produits autonomes personnaliséss."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/quotes/create-custom-quote-templates",
              children: "Modèle de devis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Oui"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le modèle qui crée le devis et fournit certains de ses paramètres de configuration, tels que la langue. Chaque devis peut être associé à un modèle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/deals",
              children: "Transaction"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Oui"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fiche d'informations de transaction pour le suivi des revenus et du cycle de vie des ventes. Un devis hérite des valeurs de la transaction associée, y compris le propriétaire et la devise. Chaque devis peut être associé à une transaction."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "Contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Non"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les acheteurs spécifiques auxquels vous vous adressez dans le devis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "Entreprise"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Non"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une entreprise spécifique à laquelle vous vous adressez dans le devis. Chaque devis peut être associé à une entreprise."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/discounts",
              children: "Remises"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/fees",
              children: "frais"
            }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/taxes",
              children: "taxes"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Non"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'ensemble des remises, frais et taxes à appliquer au moment du paiement. Pour déterminer le montant total du devis, HubSpot applique d'abord des remises, puis des frais, puis des taxes. Vous pouvez utiliser le champ ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sort_order"
            }), " pour réorganiser des objets du même type. Peut être défini sur des valeurs fixes ou des pourcentages en définissant ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_type"
            }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FIXED"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PERCENT"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Récupération des identifiants pour les associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour effectuer chaque association, vous devez d'abord récupérer l'identifiant de chaque objet que vous souhaitez associer. Pour récupérer chaque identifiant, vous devez effectuer une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " au point de terminaison de l'objet concerné, qui suivra le même schéma pour chaque objet CRM. Dans chaque requête, vous pouvez également inclure un paramètre de requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " pour renvoyer des propriétés spécifiques, si nécessaire. Vous trouverez ci-dessous des exemples de requêtes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " pour chaque type d'objet."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "GET request for line items\n/crm/v3/objects/line_items?properties=name\n\nGET request for quote templates\n/crm/v3/objects/quote_template?properties=hs_name\n\nGET request for deals\n/crm/v3/objects/deals?properties=dealname\n\nGET request for contacts\n/crm/v3/objects/contacts?properties=email\n\nGET request for companies\n/crm/v3/objects/companies?properties=name\n\nGET request for discounts\ncrm/v3/objects/discounts?properties=hs_type,hs_value\n\nGET request for fees\ncrm/v3/objects/fees?properties=hs_type,hs_value\n\nGET request for taxes\ncrm/v3/objects/taxes?properties=hs_type,hs_value\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "#GET request for line items\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/line_items?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for quote templates\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/quote_templates?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for deals\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/deals?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for contacts\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/contacts?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for companies\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/companies?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for discounts\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/discounts?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for fees\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/fees?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for taxes\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/taxes?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Chaque appel réussi renverra une réponse de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " avec des détails pour chaque type d'objet récupéré. Vous utiliserez cette valeur dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " pour définir des associations à l'étape suivante."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example quote template GET response\n{\n  \"results\": [\n    {\n      \"id\": \"235425923863\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Basic\",\n        \"hs_object_id\": \"235425923863\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"235425923864\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Modern\",\n        \"hs_object_id\": \"235425923864\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"235425923865\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Original\",\n        \"hs_object_id\": \"235425923865\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Création d'associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois vos identifiants récupérés, vous pouvez appeler ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "l'API des associations"
      }), " pour créer des associations."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour chaque type d'objet que vous souhaitez associer à un devis, vous devrez procéder à un appel distinct en effectuant une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à l'aide de la structure d'URL ci-dessous :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/quotes/{fromObjectId}/associations/default/{toObjectType}/{toObjectId}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID du devis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type d'objet associé. Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_items"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deals"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "quote_template"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de l'objet auquel vous associez le devis."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous trouverez ci-dessous des exemples de requêtes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " pour chaque type d'objet."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "PUT request to associate a line item\n/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{lineItemId}\n\nPUT request to associate a quote template\n/crm/v4/objects/quotes/{quoteId}/associations/default/quote_template/{quoteTemplateId}\n\nPUT request to associate a deal\n/crm/v4/objects/quotes/{quoteId}/associations/default/deals/{dealId}\n\nPUT request to associate contacts\n/crm/v4/objects/quotes/{quoteId}/associations/default/contacts/{contactId}\n\nPUT request to associate companies\n/crm/v4/objects/quotes/{quoteId}/associations/default/companies/{companyId}\n\nPUT request to associate discounts\n/crm/v4/objects/quotes/{quoteId}/associations/default/discounts/{discountId}\n\nPUT request to associate fees\n/crm/v4/objects/quotes/{quoteId}/associations/default/fees/{feeId}\n\nPUT request to associate taxes\n/crm/v4/objects/quotes/{quoteId}/associations/default/taxes/{taxId}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "#PUT request to associate line items\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{lineItemId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a quote template\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quote_ID}/associations/default/quote_template/{quoteTemplateId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a deal\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/deals/{dealId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate contacts\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/contacts/{contactId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a company\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/companies/{companyId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate discounts\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/discounts/{discountId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate fees\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/fees/{feeId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate taxes\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{taxId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As an example, if your quote has an ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456"
      }), ", the requests to associate the quote might include the following:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Line items (IDs: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "55555"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "66666"
          }), "):"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/objects/quotes/123456/associations/default/line_items/55555"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/objects/quotes/123456/associations/default/line_items/66666"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Quote template (ID:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "987654"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: "):"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/objects/quotes/123456/associations/default/quote_template/987654"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Deal (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "345345"
          }), "):"]
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/objects/quotes/123456/associations/default/deals/345345"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Chaque association réussie renverra une réponse de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " ainsi que des détails sur l'association. Les appels ci-dessus associeront les objets dans les deux directions, chaque direction ayant son propre ID. Par exemple, si vous associez le devis à un modèle de devis, la réponse décrira l'association des deux côtés. Dans l'exemple de réponse ci-dessous, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "286"
      }), " est l'ID du type d'association de devis à modèle de devis, et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "285"
      }), " est l'ID du type d'association de modèle de devis à devis."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"115045534742\"\n      },\n      \"to\": {\n        \"id\": \"102848290\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 285\n      }\n    },\n    {\n      \"from\": {\n        \"id\": \"102848290\"\n      },\n      \"to\": {\n        \"id\": \"115045534742\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 286\n      }\n    }\n  ],\n  \"startedAt\": \"2023-10-12T16:48:40.624Z\",\n  \"completedAt\": \"2023-10-12T16:48:40.712Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Lorsque vous associez un devis à un modèle de devis, tenez compte des limites suivantes :"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles de devis doivent être ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/quotes/create-custom-quote-templates",
            children: "créés"
          }), " avant de pouvoir être associés à un devis."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Un devis ne peut être associé qu'à un seul modèle de devis."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Cette API ne prend pas en charge les devis hérités ou d'offre. Seul le type de modèle ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CUSTOMIZABLE_QUOTE_TEMPLATE"
          }), " peut être utilisé."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associer des signataires de devis"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#enable-e-signatures",
        children: "activez le devis pour les signatures électroniques"
      }), ", vous devrez également créer une association entre le devis et les contacts qui signent en utilisant un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations#associate-records-with-a-label",
        children: "libellé d'association"
      }), " spécifique de devis à contact."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Plutôt que d'utiliser les points de terminaison d'association par défaut indiqués ci-dessus, vous devrez effectuer une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à l'URL suivante :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/quote/{quoteId}/associations/contact/{contactId}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le corps de la demande, vous devrez spécifier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), ", comme indiqué ci-dessous :"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body\n[\n  {\n    \"associationCategory\": \"HUBSPOT_DEFINED\",\n    \"associationTypeId\": 702\n  }\n]\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body\n[\n  {\n    \"associationCategory\": \"HUBSPOT_DEFINED\",\n    \"associationTypeId\": 702\n  }\n]\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un devis avec les associations (requête unique)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le corps de requête suivant créera un nouveau devis avec des associations à un modèle de devis, à une transaction, à deux lignes de produits et à un contact."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quote"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%",
              "height": "590.032px"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "135.672px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px",
                    "height": "135.672px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "properties"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "object"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Quote details, which can be retrieved through the ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/api/crm/properties",
                      children: "properties API"
                    }), ". Required properties are: ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), "."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The name of the quote."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The date that the quote expires."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "135.672px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "135.672px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_status"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "#update-quote-status",
                      children: "quote status"
                    }), ". Omitting this property on create will prevent users from being able to edit the quote in HubSpot."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "associations"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "array"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The quote's associated records. For a quote to be publishable, it must have an associated deal and quote template. The line items should be created separately from the line items on the associated deal."
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "To set each association, include a separate object in the associations array with the following fields:"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "to: the ID of the record to associate with the quote."
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: ["associationCategory: the ", (0, _jsxRuntime.jsx)(_components.a, {
                        href: "/guides/api/crm/associations#retrieve-association-types",
                        children: "type of association"
                      }), ". Can be HUBSPOT_DEFINED or USER_DEFINED."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: ["associationTypeId: the ID of the type of association being made:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
                        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                          children: "286: quote to quote template"
                        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                          children: "64: quote to deal"
                        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                          children: "67: quote to line item"
                        }), "\n"]
                      }), "\n"]
                    }), "\n"]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/api/crm/associations#association-type-id-values",
                      children: "association type IDs"
                    }), "."]
                  })]
                })
              })]
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// POST request to https://api.hubapi.com/crm/v3/objects/quote\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-09-30\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 115045534742\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 286\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 14795354663\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 64\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 75895447\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 67\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 256143985\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 67\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " ces lignes de produit doivent être différentes des lignes de produit d'autres objets, même si elles sont associées (par exemple, associer un devis à une transaction). Voir la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "documentation de l'API des lignes de produit"
        }), " pour plus d'informations."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mettre à jour l'état du devis"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'état d'un devis décrit sa progression dans le processus de création, de sa configuration initiale à sa publication et son accès public. L'état du devis peut également refléter son ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/approve-quotes",
        children: "processus d'approbation"
      }), ", si les approbations de devis sont activées pour le compte. Lorsque vous définissez l'état d'un devis, HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#properties-set-by-quote-state",
        children: "renseigne automatiquement certaines propriétés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez mettre à jour l'état d'un devis en effectuant une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quote/{quoteId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'état d'un devis est basé sur le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_status"
      }), ". Certains états de devis permettent aux utilisateurs de modifier, de publier et d'utiliser le devis dans les workflows d'approbation des devis. Vous trouverez ci-dessous les états de devis disponibles."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aucun état :"
        }), " si aucune valeur n'est fournie pour le champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_status"
        }), ", le devis aura le statut ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Minimal"
        }), ". Le devis apparaîtra sur la page index de l'outil Devis, mais ne pourra pas être modifié directement. Les devis à l'état Minimal peuvent toujours être utilisés dans l'automatisation, tels que l'outil Séquences, et sont également disponibles pour l'analyse dans l'outil Rapports."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DRAFT"
        }), " : permet de modifier le devis dans HubSpot. Cet état peut être utile lorsque le devis n'est pas entièrement configuré ou si vous préférez autoriser les commerciaux à terminer le processus de configuration du devis dans HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "APPROVAL_NOT_NEEDED"
        }), " : publie le devis à une URL publiquement accessible (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), ") sans nécessiter d'être ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "approuvé"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING_APPROVAL"
        }), " : indique que le devis est en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "attente d'approbation"
        }), " avant de pouvoir être publié."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "APPROVED"
        }), " : le devis a été ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "approuvé"
        }), " et est maintenant publié à une URL publiquement accessible (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "REJECTED"
        }), " : indique que le devis a été configuré, mais qu'il n'a pas été ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "approuvé"
        }), " pour publication et qu'il doit être modifié avant de pouvoir être soumis à nouveau pour approbation."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Si vous ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#enabling-e-signatures",
          children: "activez les signatures électroniques"
        }), " sur le devis, vous ne pourrez pas publier le devis si vous avez dépassé votre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes#monitor-e-signature-usage",
          children: "limite mensuelle de signatures électroniques"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, la requête suivante permet de publier le devis à une URL publiquement accessible."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PATCH request to https://api.hubapi.com/crm/v3/objects/quote/{QUOTE_ID}\n{\n  \"properties\": {\n    \"hs_status\": \"APPROVAL_NOT_NEEDED\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Par défaut, HubSpot définira la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_template_type du devis"
        }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CUSTOMIZABLE_QUOTE_TEMPLATE"
        }), " après avoir ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#update-quote-state",
          children: "mis à jour l'état du devis"
        }), ". Ce type de modèle est pris en charge par l'API v3, tandis que les types de modèles suivants sont des modèles hérités qui ne sont plus pris en charge :"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "QUOTE"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROPOSAL"
          })
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer des devis"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez récupérer des devis individuellement ou par lots."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour récupérer un devis individuel, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/quotes/{quoteID}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour demander une liste de tous les devis, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/quotes"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ces points de terminaison, vous pouvez inclure les paramètres suivants dans l'URL de la requête :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés à renvoyer dans la réponse. Si le devis demandé n'a pas de valeur pour une propriété, il n'apparaîtra pas dans la réponse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une liste séparée par des virgules des propriétés actuelles et historiques à renvoyer dans la réponse. Si le devis demandé n'a pas de valeur pour une propriété, il n'apparaîtra pas dans la réponse."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une liste séparée par des virgules des objets pour lesquels récupérer les ID associés. Les associations spécifiées qui n'existent pas ne seront pas renvoyées dans la réponse. Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API des associations."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour récupérer un lot de devis spécifiques via leurs ID, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/quotes/batch/read"
      }), " et incluez les ID dans le corps de la requête. Vous pouvez également inclure un tableau de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propriétés"
      }), " pour spécifier les propriétés à renvoyer. Le point de terminaison du lot ne peut pas récupérer les associations. Découvrez comment lire par lots les associations avec l'API ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "des associations"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n \"inputs\": [\n  {\"id\": \"342007287\"},\n  {\"id\":\"81204505203\"}\n ],\n \"properties\": [\n  \"hs_content\", \"hs_sentiment\",\n  \"hs_submission_timestamp\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés définies par l'état du devis"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La mise à jour de l'état du devis mettra à jour les propriétés suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_amount"
        }), " : calculé sur la base des lignes de produits, taxes, remises et frais associées."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_domain"
        }), " : défini à partir du modèle de devis associé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_slug"
        }), " : généré aléatoirement si pas fourni explicitement."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_total_preference"
        }), " : défini en fonction des paramètres de votre compte. Si vous n'avez pas configuré ce paramètre, la valeur du champ total_first_payment s'affiche par défaut."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_timezone"
        }), " : par défaut, le fuseau horaire de votre compte HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_locale"
        }), " : défini à partir du modèle de devis associé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_number"
        }), " : défini en fonction de la date et de l'heure actuelles, à moins qu'une date ne soit fournie."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_language"
        }), " : défini à partir du modèle de devis associé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_currency"
        }), " : défini en fonction de la transaction associée. Si vous n'avez pas associé une transaction à la proposition, celle-ci sera réglée par défaut sur la devise par défaut de votre compte HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En outre, les propriétés ci-dessous seront calculées lorsque le devis passera à l'état Publié :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_pdf_download_link"
        }), " : rempli avec une URL d'un PDF pour le devis."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_locked"
        }), " : défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), ". Pour modifier des propriétés après avoir publié un devis, vous devez tout d'abord mettre à jour le statut ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_status"
        }), " du devis pour le ramener sur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "BROUILLON"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "EN ATTENTE D'APPROBATION"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "REJETÉ"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), " : l'URL du devis accessible au public. Il s'agit d'une propriété en lecture seule qui ne peut pas être définie via l'API après publication."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_esign_num_signers_required"
        }), " : si vous avez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#enable-e-signatures",
          children: "activé les signatures électroniques"
        }), ", affiche le nombre de signatures requises."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), " : statut de la collecte des paiements, si vous avez activé les paiements. Lors de la publication avec les paiements activés, cette propriété sera définie sur EN ATTENTE. Une fois que l'acheteur a effectué le paiement via le devis, le statut sera automatiquement mis à jour en conséquence. En savoir plus sur l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#enable-payments",
          children: "activation des paiements"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Périmètres d'accès"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les périmètres suivants sont requis pour une application afin de créer un devis publiable valide :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.quotes.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.quotes.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.line_items.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.line_items.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.owners.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.read"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.quote.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.line_items.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.contacts.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.deals.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.companies.read"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}