'use es6';

import { Kind, specifiedRules, validate, NoUnusedFragmentsRule, KnownFragmentNamesRule } from "../graphql";
import { ExecutableDefinitions } from "../graphql/validation/rules/ExecutableDefinitions";
export function validateWithCustomRules(schema, ast, customRules, isRelayCompatMode) {
  const rules = specifiedRules.filter(rule => {
    if (rule === NoUnusedFragmentsRule || rule === ExecutableDefinitions) {
      return false;
    }
    if (isRelayCompatMode && rule === KnownFragmentNamesRule) {
      return false;
    }
    return true;
  });
  if (customRules) {
    Array.prototype.push.apply(rules, customRules);
  }
  const errors = validate(schema, ast, rules);
  return errors.filter(error => {
    if (error.message.indexOf('Unknown directive') !== -1 && error.nodes) {
      const node = error.nodes[0];
      if (node && node.kind === Kind.DIRECTIVE) {
        const name = node.name.value;
        if (name === 'arguments' || name === 'argumentDefinitions') {
          return false;
        }
      }
    }
    return true;
  });
}