"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35877708550;
const slug = exports.slug = 'guides/cms/content/templates/overview';
const title = exports.title = 'テンプレートの概要';
const name = exports.name = 'APAC JAPAN (ja) | [new] Templates_JA | 202011';
const metaDescription = exports.metaDescription = 'テンプレートは再利用可能なページラッパーまたはEメールラッパーです。通常はテンプレートによってモジュールやパーシャルがレイアウトに配置されます。制作担当者は使用するテンプレートを選択してから、ページやEメールの作成を始めます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "テンプレートを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E3%81%99%E3%82%8B",
  "label": "テンプレートをプレビューする",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC%E3%81%A7%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E3%81%99%E3%82%8B",
  "label": "デザインマネージャーでプレビューする",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E6%96%B0%E3%81%97%E3%81%84%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%A7%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E3%81%99%E3%82%8B",
  "label": "新しいページでプレビューする",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97",
  "label": "テンプレートのタイプ",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8",
  "label": "ページ",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB",
  "label": "Eメール",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB",
  "label": "パーシャル",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB",
  "label": "グローバルパーシャル",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0",
  "label": "ブログ",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E4%B8%80%E8%A6%A7",
  "label": "ブログ一覧",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%98%E4%BA%8B",
  "label": "ブログ記事",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%98%E4%BA%8B%E3%81%A8%E3%83%96%E3%83%AD%E3%82%B0%E3%83%AA%E3%82%B9%E3%83%88%E3%81%AE%E7%B5%B1%E5%90%88%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88",
  "label": "ブログ記事とブログリストの統合テンプレート",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E3%83%9A%E3%83%BC%E3%82%B8",
  "label": "システムページ",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E3%82%A8%E3%83%A9%E3%83%BC%E3%83%9A%E3%83%BC%E3%82%B8",
  "label": "エラーページ",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E9%85%8D%E4%BF%A1%E8%A8%AD%E5%AE%9A",
  "label": "Eメール配信設定",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%83%90%E3%83%83%E3%82%AF%E3%82%A2%E3%83%83%E3%83%97%E9%85%8D%E4%BF%A1%E8%A7%A3%E9%99%A4",
  "label": "Eメールバックアップ配信解除",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E9%85%8D%E4%BF%A1%E8%A7%A3%E9%99%A4%E7%A2%BA%E8%AA%8D",
  "label": "Eメール配信解除確認",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E3%83%91%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E3%83%97%E3%83%AD%E3%83%B3%E3%83%97%E3%83%88",
  "label": "パスワードプロンプト",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E6%A4%9C%E7%B4%A2%E7%B5%90%E6%9E%9C%E3%83%9A%E3%83%BC%E3%82%B8",
  "label": "検索結果ページ",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E6%A8%A9%E8%A8%AD%E5%AE%9A",
  "label": "アクセス権設定",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B7%E3%83%83%E3%83%97%E3%81%AE%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3",
  "label": "メンバーシップのログイン",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B7%E3%83%83%E3%83%97%E3%81%AE%E7%99%BB%E9%8C%B2",
  "label": "メンバーシップの登録",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B7%E3%83%83%E3%83%97%E3%83%91%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E3%81%AE%E3%83%AA%E3%82%BB%E3%83%83%E3%83%88",
  "label": "メンバーシップパスワードのリセット",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B7%E3%83%83%E3%83%97%E3%81%AE%E3%83%AA%E3%82%BB%E3%83%83%E3%83%88%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88",
  "label": "メンバーシップのリセットリクエスト",
  "parent": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%B3%E3%82%AF%E3%83%AB%E3%83%BC%E3%83%89%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8Bcms%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB",
  "label": "インクルードされているCMSファイル",
  "parent": null
}, {
  "depth": 1,
  "id": "jquery",
  "label": "jQuery",
  "parent": "%E3%82%A4%E3%83%B3%E3%82%AF%E3%83%AB%E3%83%BC%E3%83%89%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8Bcms%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB"
}, {
  "depth": 1,
  "id": "layout.css",
  "label": "layout.css",
  "parent": "%E3%82%A4%E3%83%B3%E3%82%AF%E3%83%AB%E3%83%BC%E3%83%89%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8Bcms%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB"
}, {
  "depth": 1,
  "id": "hubspot%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%E3%82%B3%E3%83%BC%E3%83%89",
  "label": "HubSpotトラッキングコード",
  "parent": "%E3%82%A4%E3%83%B3%E3%82%AF%E3%83%AB%E3%83%BC%E3%83%89%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8Bcms%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB"
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "関連コンテンツ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      h3: "h3",
      img: "img",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "テンプレートの概要"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テンプレートは、HubSpotのページ、Eメール、テーマのレイアウトを定義します。テンプレートはモジュールとパーシャルで構成され、スタイルシートやJavaScriptファイルなどのアセットを参照できます。テンプレートの作成には、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "HubSpot CLI"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/design-manager/create-page-email-and-blog-templates-in-the-layout-editor",
        children: "HubSpotのデザインマネージャー"
      }), "を使用できます。制作担当者がページまたはEメールを作成するときに最初に選ぶのが、テンプレートです。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ここではテンプレートの作成方法、テンプレートの種類、テンプレートに含まれる内容について説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレートを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テンプレートは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/design-manager/create-page-email-and-blog-templates-in-the-layout-editor",
        children: "HubSpot上で"
      }), "、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "CLIを使用"
      }), "して作成できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CLIを使用してテンプレートを作成するには、次のコマンドを実行します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template <name> [dest]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テンプレートの名前"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dest"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テンプレートを作成するローカルディレクトリーのパス。このパラメーターが含まれない場合、テンプレートは現在のディレクトリーに作成されます。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["矢印キーを使用して、作成する", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#template-types",
          children: "テンプレートのタイプ"
        }), "に移動し、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), "キーを押します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["その後、テンプレートはローカルで作成されます。ご使用のHubSpotアカウント上でテンプレートを使用するには、アカウントにテンプレートを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "アップロード"
      }), "します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "watch"
      }), "コマンドを使用して、現在の作業ディレクトリーと子ディレクトリー内の新しいファイルおよび既存のファイルの編集内容を自動的にアップロードすることも可能です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレートをプレビューする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テンプレートを更新したらプレビューして、期待どおりに表示されて動作することを確認できます。次のように、HubSpotにはテンプレートをプレビューする方法がいくつかあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**デザインマネージャーでテンプレートをプレビューする：**簡単な目視チェックを行う場合や、ブログ記事／リスト／統合テンプレートをプレビューする必要がある場合に最適です。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**テンプレートから新しいアセットを作成する：**ドラッグ＆ドロップエディターとコンテンツ作成エクスペリエンスのテストに最適です。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "デザインマネージャーでプレビューする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャーによるテンプレートのプレビューは、簡単な目視チェックを行う際に特に便利です。テンプレートプレビューアーでは、ビューポートの寸法などの表示オプションも設定できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャーでテンプレートをプレビューするには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントにて、［マーケティング］>［ファイルとテンプレート］>［デザインツール］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["左サイドバーのファイルエクスプローラーを使用して、プレビューする", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "テンプレート"
        }), "をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["右上にある［プレビュー］をクリックします。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［表示オプションを使用したライブプレビュー］を選択し、応答性とドメイン設定（スタイルシートなど）をテストするオプションを含むテンプレートをプレビューします。このオプションでは、iframe内のページが表示されます。ブログテンプレート用にブログとブログ記事、またはリストビューを選択することもできます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［表示オプションを使用せずにプレビュー］を選択し、追加オプションのないテンプレートをプレビューします。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/template-preview-options.png",
        alt: "template-preview-options"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "新しいページでプレビューする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ドラッグ＆ドロップエリアを変更する場合、モジュールに設定されている既定のパラメーター値を変更する場合、またはその他に大きな変更を加える場合は、テンプレートを使用して、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/website-pages/create-and-customize-pages",
        children: "ウェブサイトのページ"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/blog/create-a-new-blog",
        children: "ブログ"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/blog/create-and-publish-blog-posts",
        children: "ブログ記事"
      }), "、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/email/create-marketing-emails-in-the-drag-and-drop-email-editor",
        children: "Eメール"
      }), "を作成するのが最適です。その上でモジュールのさまざまなフィールド値を試し、テンプレートが実際にどのように表示されるかをテストして、制作担当者にとって使いやすくなるように最適化できます。これらのアセットを公開することも、テスト用に下書きモードのままにすることもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["さらに、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cms-general/redesign-and-relaunch-your-site-with-content-staging#new",
        children: "コンテンツのステージング"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "開発者サンドボックスアカウント"
      }), "を使用して、本番アカウントに影響を与えることなくアセットを作成および表示できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレートのタイプ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ウェブサイトページやブログ記事など、さまざまなタイプのコンテンツでテンプレートを使用できます。コードテンプレートでは、ファイルの先頭部分にアノテーションを追加してテンプレートのタイプを指定します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "さまざまなタイプのテンプレートと、各タイプを指定するためのアノテーションについて、以下で説明します。"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**制作担当者は、テンプレートに「", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "」タグが含まれているかどうかに応じて、ページのテンプレートを同じタイプの別のテンプレートと入れ替えることができます。"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["ビジュアル ドラッグ＆ドロップ レイアウト エディター上で作成したテンプレートは、「", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "」タグの有無に関係なく、他のドラッグ＆ドロップテンプレートまたはコードテンプレートと入れ替えることができます。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["dnd_areaタグを含むコードテンプレートは、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "タグを含む他のコードテンプレートとのみ入れ替えることできます。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "タグを含まないコードテンプレートは、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "タグを含まない他のコードテンプレートとのみ入れ替えることできます。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ページ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: page\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページテンプレートは最も自由度の高いテンプレートタイプです。任意のウェブページまたは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.jp/what-is-a-landing-page",
        children: "ランディングページ"
      }), "として使用できます。ページ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#layout-css",
        children: "レイアウト"
      }), "に事前に取り込まれるコンポーネントはありません。コード ページ テンプレートには、わずかなマークアップ（メタ情報に提案されるHubLタグ、タイトル、必須のヘッダー／フッター", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#included-cms-files",
        children: "インクルード"
      }), "など）が事前に取り込まれます。ページテンプレートを使用するページには通常、次の要素が含まれますが、これらに限定されません。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/home.html",
          children: "ホームページ"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/about.html",
          children: "会社概要"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/contact.html",
          children: "お問い合わせ先"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eメール"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメールテンプレートは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.jp/products/marketing/email",
        children: "Eメールツール"
      }), "で使用します。Eメールテンプレートはさまざまなメールクライアントで表示されること、そして到達率を担保するためのベストプラクティスに従わなければならないことから、さまざまな厳しい条件に対応する必要があります。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML+HubL"
      }), "とデザインマネージャーのドラッグ＆ドロップEメールテンプレートにはいずれも、作成時に基本コンポーネントが取り込まれます。以下の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType"
      }), "を指定して作成したテンプレートは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/email/create-and-send-marketing-emails",
        children: "Eメールを作成"
      }), "するときにだけテンプレート選択画面に表示されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/email/can-i-customize-my-can-spam-email-footer",
        children: "CAN-SPAM"
      }), "に準拠するためには、Eメールテンプレートに必要な", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-email-template-variables",
        children: "必須の変数"
      }), "を含める必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメールテンプレートには、特殊なクラス名またはデータ属性を設定して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), "要素に追加される", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/email-template-markup#hs-inline-css-and-data-hse-inline-css",
        children: "inline-css"
      }), "に対する機能も組み込まれています。EメールにおけるCSSのインライン化は、さまざまなEメールクライアントにわたる対応を強化するために行います。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://litmus.com/blog/do-email-marketers-and-designers-still-need-to-inline-css",
        children: "利用者の多いEメールクライアントのほとんどが埋め込みCSSに対応"
      }), "していますが、受信者によっては例外的なEメールクライアントを使用している場合もあります。受信者を考慮して適切に判断してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/email-template-markup",
        children: "Eメールテンプレートの作成に関する詳細を参照してください。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "パーシャル"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: page isAvailableForNewContent: false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "パーシャルは、他のコードファイルに組み込むことができるテンプレートファイルです。パーシャルは、グローバルパーシャルと異なり、パーシャルの他のインスタンスに影響を与えることなく個別に編集できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#partials",
        children: "パーシャル"
      }), "について詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "グローバルパーシャル"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: global_partial\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "グローバルパーシャルは、HTMLとHubLを使用して作成されたテンプレートの一種であり、ウェブサイト全体にわたって再利用できます。典型的なパーシャルは、ウェブサイトのヘッダー、サイドバー、フッターです。グローバルパーシャルを更新すると、グローバルパーシャルのインスタンスが全て更新されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
        children: "グローバルパーシャル"
      }), "と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "グローバルコンテンツ"
      }), "について詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ブログ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cos-blog/cos-blog-how-do-i-create-a-cos-blog",
        children: "ブログを作成"
      }), "する場合、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "ブログテンプレート"
      }), "の構造は標準", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#page-templates",
        children: "ページテンプレート"
      }), "と似ています。大きな違いは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "コンテンツ設定"
      }), "ではブログテンプレートを選択できますが、ページテンプレートは選択できない点です。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_listing"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog"
      }), "のいずれかの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType"
      }), "を使用して作成されたテンプレートは、ユーザーがウェブページを作成するときに、テンプレート選択画面に", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "表示されません"
      }), "。ブログテンプレートには、実際にはブログ リスト ページとブログ記事の詳細ページという2つの形式があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ブログ一覧"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog_listing\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "ブログ リスト テンプレート"
      }), "は、ブログのURLにアクセスしたユーザーに対して表示されるテンプレートです。このテンプレートは通常、ブログの全記事の要約、タイトル、キービジュアルを一覧形式で表示するために使用します。また、このテンプレートでは通常、古い記事を辿るためのページ番号も示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ブログ記事"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog_post\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ブログ記事テンプレートは、ブログの各記事を表示するときにユーザーに対して表示されるテンプレートです。このテンプレートでは通常、記事全体の内容を表示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ブログ記事とブログリストの統合テンプレート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1つのブログテンプレートで、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog#if-is-listing-view-statement",
        children: "リストページと詳細ページの両方"
      }), "のレイアウトを扱うこともできます。ただし、別個のテンプレートに分けるのが一般的です。統合テンプレートは、ブログ設定内のリストとブログ記事の両方に、選択可能なテンプレートとして表示されます。記事専用またはリスト専用のテンプレートを作成する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_listing"
      }), "を使用する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["開発の簡素化や制作担当者の操作を求める場合は、統合テンプレートではなく、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateTypes"
        }), "に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_post"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_listing"
        }), "を個別に使用することをお勧めします。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "システムページ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["システム ページ テンプレートは、特定の用途を示す内部フラグがあります。アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "コンテンツ設定"
      }), "で、システムタブの指定された用途に対応するテンプレートを選択できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**CLIを使用して作成できるシステム ページ テンプレートのタイプは、検索結果ページテンプレートだけです。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "エラーページ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "コンテンツ設定"
      }), "では、エラーページを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/404.html",
        children: "404"
      }), "ページまたは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/500.html",
        children: "500"
      }), "ページとして設定できます。どちらのテンプレートも同じtemplateTypeを使用します。以下のtemplateTypeを指定して作成したテンプレートは、ユーザーがウェブページを作成するときにテンプレート作成画面に表示されません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: error_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Eメール配信設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメール配信設定ページ。ユーザーがオプトインまたはオプトアウトできる、全ての利用可能な配信カテゴリー(サブスクリプションタイプ)のリストが表示されます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% email_subscriptions \"email_subscriptions\"  %}"
      }), "HubLタグを含める場合は必須です。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/subscription-preferences.html",
        children: "cms-theme-boilerplateの配信設定テンプレートを参照してください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_subscription_preferences_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Eメールバックアップ配信解除"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメール配信解除ページのシステムテンプレート。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% email_simple_subscription \"email_simple_subscription\" %}"
      }), "HubLタグを含める場合は必須です。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/backup-unsubscribe.html",
        children: "cms-theme-boilerplateのEメールバックアップ配信解除テンプレートを参照してください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_backup_unsubscribe_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Eメール配信解除確認"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメール配信解除確認ページのシステムテンプレート。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#email-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ unsubscribe_link_all }}"
        })
      }), "変数によって生成されたURLにユーザーが移動すると、ここにリダイレクトされます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/subscriptions-confirmation.html",
        children: "cms-theme-boilerplateの配信登録確認テンプレートを参照してください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_subscriptions_confirmation_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "パスワードプロンプト"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["パスワード入力テンプレートは、ブランド化されたページを生成します。制作担当者はこのページにパスワードを入力するよう促すメッセージを表示し、訪問者がパスワードを入力しなければページのコンテンツを閲覧できないようにすることができます。パスワード入力テンプレートを設定するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "コンテンツ設定"
      }), "を使用します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cos-pages-editor/how-can-i-password-protect-my-pages",
        children: "HubSpotパスワードで保護されたページの作成方法を参照してください"
      }), "。ボイラープレートでの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/password-prompt.html",
        children: "保護されたページのパスワード入力"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: password_prompt_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "検索結果ページ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["組み込みCMSサイト検索リスト機能のシステムテンプレート。ボイラープレートでの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/search-results.html",
        children: "検索結果ページテンプレート"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: search_results_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "アクセス権設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "アクセス権設定（メンバーシップ）機能"
      }), "（", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "CMS Hub"
      }), " Enterpriseのみ）を使用できるHubSpotアカウントでは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cms-pages-editor/control-audience-access-to-pages",
        children: "CRMの特定のリストに含まれるユーザーだけがアクセスできるページをサイト上に作成"
      }), "できます。これにより、サイト訪問者はログイン資格情報が設定されたアカウントを保持できるようになります。これらのテンプレートを使用して、ページの外観を制御できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["メンバーシップテンプレートとして設定できるのは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML+HubLテンプレート"
        }), "のみです。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "メンバーシップのログイン"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これは、アクセス権設定機能でアクセスが制御されたコンテンツにユーザーがアクセスしようとすると表示される、ログインページです。通常は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% member_login \"member_login\" %}"
      }), "モジュールを含んでいます。ボイラープレートでの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-login.html",
        children: "メンバーシップ ログイン テンプレート"
      }), "の例を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_login_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "メンバーシップの登録"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これは、ユーザー登録ページです。このページでユーザーはアカウントを作成して、このリストに含まれるユーザーがアクセスできるコンテンツを閲覧できるようになります。通常は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% member_register \"member_register\" %}"
      }), "HubLタグを含んでいます。ボイラープレートでの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-register.html",
        children: "メンバーシップ登録テンプレート"
      }), "の例を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_register_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "メンバーシップパスワードのリセット"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これは、パスワード リセット ページです。このページで、ユーザーは新しいパスワードを設定できます。通常は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% password_reset \"password_reset\" %}"
      }), "HubLタグを含んでいます。ボイラープレートでの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-reset-password.html",
        children: "メンバーシップ パスワード リセット テンプレート"
      }), "の例を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_reset_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "メンバーシップのリセットリクエスト"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これは、パスワードのリセットをリクエストするページです。パスワードリセットEメールをリクエストするフォームが表示されます。通常は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% password_reset_request \"password_reset_request\" %}"
      }), "HubLタグを含んでいます。ボイラープレートでの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-reset-password-request.html",
        children: "メンバーシップ パスワード リセット リクエスト テンプレート"
      }), "の例を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_reset_request_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "インクルードされているCMSファイル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMSテンプレートには、特定のJavaScriptファイルとCSSファイルが関連付けられます。一部のファイルは自動的にインクルードされて削除できませんが、他のファイルは任意にインクルードできます。CMSコンテンツにスタイルシートが関連付けられる順序については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cos-general/create-edit-and-attach-css-files-to-style-your-site",
        children: "こちらの記事をご覧ください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["任意で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jquery.com/",
        children: "jQuery"
      }), "をHubSpotテンプレートのheadタグにインクルードできます。インクルードした場合、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), " HubL変数の一部としてレンダリングされます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["［設定］>［ウェブサイト］>［ページ］で、jQueryのバージョンを1.11.x、バージョン1.7.1に変更したり、jQueryを完全に無効にしたりできます。古いブラウザーとの後方互換性を維持するためにjQuery移行スクリプトをインクルードすることもできます。ページのパフォーマンス向上を目的に、jQueryをフッターに移動することも可能ですが、jQueryを移動すると、jQueryに依存しているJavaScriptが機能しなくなることがあります。移動する前に、ウェブサイトページのURLの最後に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsMoveJQueryToFooter=True"
      }), "を追加して、テストすることをお勧めします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["従来JQueryは既定で付属していましたが、現在CMS HubにjQueryは必須ではありません。JQueryの機能の大半には、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://youmightnotneedjquery.com/",
        children: "同等の最新かつ簡素なJavaScript"
      }), "があるので、こちらを使用することをお勧めします。jQueryを使用する必要がある場合は、設定で既定のバージョンを無効にし、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</body>"
      }), "タグの前に最新バージョンが読み込まれるようにすることをお勧めします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サイトでjQueryを削除しても問題が発生しないかどうかをテストするには、サイト上の操作可能な要素が特に多く含まれているページを表示し、URLの末尾に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsNoJQuery=true"
      }), "を追加します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "layout.css"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このcss（旧称", (0, _jsxRuntime.jsx)(_components.code, {
        children: "required_base.css"
      }), "）は、HubSpotのレスポンシブグリッドをスタイル設定するファイルです。このファイルは自動的にドラッグ＆ドロップテンプレートに組み込まれますが、カスタム コード テンプレートに既定で組み込まれることは", (0, _jsxRuntime.jsx)("u", {
        children: "ありません"
      }), "。コード化されたHTML+HubLテンプレートで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area"
      }), "タグを使用している場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "layout.css"
      }), "ファイルを組み込む必要はありませんが、このファイルを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "CMSボイラープレートに組み込んでおく"
      }), "と、素早く簡単に稼働を開始できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このファイルにはレスポンシブグリッドCSSに加え、さまざまなビューポートで要素を表示または非表示にするために使用できるクラスが含まれています。詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/327485/Designers_Docs_2015/layout.css",
        children: "ファイルを直接確認"
      }), "してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubSpotトラッキングコード"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/account/how-does-hubspot-track-visitors",
        children: "HubSpotトラッキングコード"
      }), "は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "standard_footer_includes"
        })
      }), " HubL変数と共に、HubSpotテンプレートに自動的に追加されます（Eメールテンプレートを除く）。トラッキングコードは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "your_HubID.js"
      }), "（例：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "158015.js"
      }), "）という名前のアナリティクスJavaScriptファイルを読み込みます。このトラッキングコードは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/account/how-do-i-turn-on-gdpr-functionality-in-my-hubspot-account",
        children: "HubSpotのGDPR機能"
      }), "に直接統合されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連コンテンツ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "ドラッグ＆ドロップエリア"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections",
          children: "セクションテンプレート"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "テンプレートとモジュールでウェブコンポーネントを使用する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/global-content",
          children: "グローバルコンテンツを使用してヘッダーやフッターなどを構築する"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}