import { keyToNavData } from 'developer-docs-shared-components/config/navData/keys';
import { legacyVersionInfo } from 'developer-docs-shared-components/config/versionInfo';
import { proxiedSubdomainBasePath } from 'developer-docs-shared-components/utils/urls';
import omit from 'hs-lodash/omit';
import { SUPPORTED_API_MDX_DOCS, SUPPORTED_GET_STARTED_DOCS, ctxKeyToPath } from 'developer-docs-mdx-components/utils/urls';
import { breadcrumbs } from 'developer-docs-shared-components/config/navData/translations/breadcrumbs';
import { isBreadcrumb } from 'developer-docs-shared-components/config/navData/types';

/**
 * A UIBadge.
 */

/**
 * `NavItem` is a generic term for either a page link or folder containing
 * child items.
 */

/**
 * A nav item with label and child items array. Folders cannot have badges;
 * only links can.
 */

/**
 * Secondary nav config, shown on the left sidebar at desktop.
 * @example 'API Endpoints' within the 'API & Reference' primary nav
 */

/**
 * Primary nav item shown at the top-level, in the header. Has secondary nav
 * as a child.
 *
 * @example 'API & Reference'
 */

export const apiMdxDocKeys = [...SUPPORTED_API_MDX_DOCS].map(key => ctxKeyToPath(key).replace('/reference/api', ''));
export const gettingStartedMdxDocKeys = [...SUPPORTED_GET_STARTED_DOCS].map(key => ctxKeyToPath(key).replace('/getting-started', ''));
export const allNonMdxApiReferenceKeys = Object.keys(omit(keyToNavData['/reference/api'], apiMdxDocKeys));
export const allNonLegacyApiKeys = allNonMdxApiReferenceKeys.filter(key => !legacyVersionInfo[key] || legacyVersionInfo[key] && !legacyVersionInfo[key].some(v => v.isDefaultSelected));
export const allLegacyApiKeys = allNonMdxApiReferenceKeys.filter(key => legacyVersionInfo[key] && legacyVersionInfo[key].some(v => v.isDefaultSelected));
export const allSupportedGuideKeys = Object.keys(keyToNavData['/guides/api']);

/**
 * Gets path names, excluding the proxied subdomain base directory.
 *
 * @example
 * ```tsx
 * const { pathname } = useLocation();
 * const { primaryDir, secondaryDir } = getPathNames(pathname);
 * ```
 */
export const getPathNames = pathname => {
  const computedPathname = pathname.replace(proxiedSubdomainBasePath, '');
  const [, primaryDir, secondaryDir] = computedPathname.split('/');
  return {
    primaryDir,
    secondaryDir
  };
};
export function assertBadgeIsValid(badge) {
  if (!badge) return;
  if (badge !== 'NEW' && badge !== 'BETA') {
    throw new Error(`Invalid badge: ${badge}`);
  }
}
const getBreadcrumbOrEmptyString = breadcrumbKey => {
  if (!isBreadcrumb(breadcrumbKey)) {
    console.warn(`Invalid breadcrumb key: ${breadcrumbKey}`);
  }
  return isBreadcrumb(breadcrumbKey) ? breadcrumbs[breadcrumbKey]() : '';
};
export const overrideLabelOnItemAndCheckBadge = (item, referenceData, commonPath) => {
  if ('items' in item) {
    return Object.assign({}, item, {
      label: getBreadcrumbOrEmptyString(item.breadcrumbOverride || item.dir),
      items: item.items.map(childItem => overrideLabelOnItemAndCheckBadge(childItem, referenceData, commonPath))
    });
  } else {
    if (item.external) {
      return {
        label: getBreadcrumbOrEmptyString(item.url),
        url: item.url
      };
    }
    if (!referenceData[item.url]) {
      throw new Error(`No reference data found for url: ${item.url}`);
    }
    // check to see if the item badge is supported
    const badge = item.badge;
    assertBadgeIsValid(badge);
    return Object.assign({}, item, {
      badge,
      url: `${commonPath}${item.url}`,
      label: referenceData[item.url].label() || ''
    });
  }
};