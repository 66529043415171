"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 99666557245;
const slug = exports.slug = 'guides/cms/marketplace/module-requirements';
const title = exports.title = 'テンプレートマーケットプレイス | モジュール要件';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Page | Asset Marketplace | Module requirements | 202304';
const metaDescription = exports.metaDescription = 'HubSpotテンプレートマーケットプレイスへの提出時にテーマのモジュールが満たす必要のある要件について説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E5%88%B6%E9%99%90%E4%BA%8B%E9%A0%85",
  "label": "モジュールの制限事項",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "モジュールのコンテンツ",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%83%A9%E3%83%99%E3%83%AB%E3%81%A8%E3%83%98%E3%83%AB%E3%83%97%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88",
  "label": "モジュールラベルとヘルプテキスト",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84"
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%AE%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "既定のコンテンツ",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84"
}, {
  "depth": 1,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%A2%E3%82%A4%E3%82%B3%E3%83%B3",
  "label": "モジュールアイコン",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84"
}, {
  "depth": 1,
  "id": "%E3%82%B5%E3%83%BC%E3%83%89%E3%83%91%E3%83%BC%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%8C%E5%BF%85%E8%A6%81%E3%81%AA%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "サードパーティーのアカウントが必要なモジュール",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84"
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89",
  "label": "モジュールフィールド",
  "parent": null
}, {
  "depth": 1,
  "id": "fields.json%E3%81%8A%E3%82%88%E3%81%B3module.html%E3%81%AE%E8%A8%AD%E5%AE%9A",
  "label": "fields.jsonおよびmodule.htmlの設定",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89"
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%BC%E3%83%89%E5%93%81%E8%B3%AA",
  "label": "モジュールのコード品質",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AF%E8%87%AA%E5%B7%B1%E5%AE%8C%E7%B5%90%E5%9E%8B%E3%81%A7%E3%81%AA%E3%81%91%E3%82%8C%E3%81%B0%E3%81%AA%E3%82%89%E3%81%AA%E3%81%84",
  "label": "モジュールは自己完結型でなければならない",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%BC%E3%83%89%E5%93%81%E8%B3%AA"
}, {
  "depth": 2,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "テーマモジュール",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%BC%E3%83%89%E5%93%81%E8%B3%AA"
}, {
  "depth": 2,
  "id": "%E7%8B%AC%E7%AB%8B%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "独立モジュール",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%BC%E3%83%89%E5%93%81%E8%B3%AA"
}, {
  "depth": 1,
  "id": "%E7%8B%AC%E7%AB%8B%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%BC%E3%83%89%E5%88%B6%E9%99%90",
  "label": "独立モジュールのコード制限",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%BC%E3%83%89%E5%93%81%E8%B3%AA"
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%83%86%E3%82%B4%E3%83%AA%E3%83%BC",
  "label": "カテゴリー",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%BC%E3%83%89%E5%93%81%E8%B3%AA"
}, {
  "depth": 1,
  "id": "%E3%82%AF%E3%83%A9%E3%82%B9%E5%90%8D%E3%82%BB%E3%83%AC%E3%82%AF%E3%82%BF%E3%83%BC",
  "label": "クラス名セレクター",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%BC%E3%83%89%E5%93%81%E8%B3%AA"
}, {
  "depth": 1,
  "id": "%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E3%81%A8javascript",
  "label": "スタイルとJavascript",
  "parent": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B3%E3%83%BC%E3%83%89%E5%93%81%E8%B3%AA"
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E6%A7%8B%E6%88%90",
  "label": "フィールドの構成",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%82%BF%E3%83%96",
  "label": "コンテンツタブ",
  "parent": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E6%A7%8B%E6%88%90"
}, {
  "depth": 1,
  "id": "%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E3%82%BF%E3%83%96",
  "label": "スタイルタブ",
  "parent": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E6%A7%8B%E6%88%90"
}, {
  "depth": 1,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E6%A7%8B%E6%88%90%E3%81%AE%E4%BE%8B",
  "label": "フィールドの構成の例",
  "parent": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E6%A7%8B%E6%88%90"
}, {
  "depth": 2,
  "id": "%E3%83%97%E3%83%AA%E3%82%BB%E3%83%83%E3%83%88",
  "label": "プリセット",
  "parent": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E6%A7%8B%E6%88%90"
}, {
  "depth": 2,
  "id": "%E8%A4%87%E6%95%B0%E3%83%AC%E3%83%99%E3%83%AB%E3%81%AE%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%E5%8C%96",
  "label": "複数レベルのグループ化",
  "parent": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E6%A7%8B%E6%88%90"
}, {
  "depth": 2,
  "id": "%E5%80%8B%E3%80%85%E3%81%AE%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%E5%8C%96",
  "label": "個々のフィールドのグループ化",
  "parent": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E6%A7%8B%E6%88%90"
}, {
  "depth": 0,
  "id": "%E3%82%A8%E3%82%A4%E3%83%AA%E3%82%A2%E3%82%B9",
  "label": "エイリアス",
  "parent": null
}, {
  "depth": 1,
  "id": "%E5%8D%98%E7%B4%94%E3%81%AA%E5%AE%9F%E8%A3%85",
  "label": "単純な実装",
  "parent": "%E3%82%A8%E3%82%A4%E3%83%AA%E3%82%A2%E3%82%B9"
}, {
  "depth": 0,
  "id": "example-of-a-v0-module",
  "label": "Example of a v0 module",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v1-module",
  "label": "Example of a v1 module",
  "parent": null
}, {
  "depth": 0,
  "id": "complex-implementation",
  "label": "Complex implementation",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v0-module",
  "label": "Example of a v0 module",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v1-module",
  "label": "Example of a v1 module",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      h3: "h3",
      img: "img",
      code: "code",
      h4: "h4",
      pre: "pre",
      ol: "ol",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      Alert,
      DndModule,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpotテンプレートマーケットプレイスでのモジュール要件"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テンプレートマーケットプレイスにモジュールを提出するための要件について説明します。これらの要件は、テーマ内のモジュールおよび独立したモジュールの両方に当てはまります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュールの制限事項"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "サーバーレス関数"
      }), "の呼び出し、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#crm-object",
        children: "CRMオブジェクトフィールド"
      }), "をモジュールに含めてはなりません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "独立したモジュールとして、次のモジュールタイプを構築しないでください"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "全幅モジュール"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "フォームとマルチステップフォーム"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "スペーサーモジュール、または非UIページ構造を作成するモジュール"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "既定のモジュール機能と重複するモジュール"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "コマース固有のモジュール"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Eメール固有のモジュール"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュールのコンテンツ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールラベルとヘルプテキスト、フィールド、既定のコンテンツの要件については、以下で詳しく説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "モジュールラベルとヘルプテキスト"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "モジュールには、モジュールの目的を明確に示す記述的なラベルを付ける必要があります。例えば、「視差スクロールを備えたヒーローバナー」というラベルは分かりやすく記述されていますが、「ヒーローバナー」および「ギャラリー」というラベルは説明が不十分です。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "モジュールラベルには、「ヒーローバナー01」のように数字を含めることはできません。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "モジュールラベルにアンダースコアを含めることはできません。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "モジュールラベルには、「カラム（Column）」を表す「Col」のような略語を含めることはできません"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["モジュールの使用方法をさらに詳しく伝えるために、モジュールには（該当する場合）", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration#meta-json",
          children: "インラインのヘルプテキスト"
        }), "を含める必要があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["モジュールに", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "既定のモジュール"
        }), "と同じ名前を付けることはできません。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "独立モジュールの場合、モジュールラベルはテンプレート掲載情報での名前と一致する必要があります。例えば、テンプレート掲載情報で「スクロール可能なSuperAwesomeバナー」となっている場合は、モジュールのラベルもこれと同じにする必要があります。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-name.png",
          alt: "listing-name"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-label.png",
          alt: "module-label"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既定のコンテンツ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "既定のフィールドに「Lorem ipsum」テキストを含めることはできません。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["既定のフィールドコンテンツはフィールドの目的を表す必要があります。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "メニューフィールドを含める場合、モジュールでは既定のコンテンツオプションとして「メニューを選択」を使用する必要があります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "フォームフィールドを含める場合、モジュールでは既定のコンテンツオプションとして「フォームを選択」を使用する必要があります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ブログ セレクター フィールドを含める場合、モジュールでは既定のコンテンツオプションとして「ブログを選択」を使用する必要があります。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["モジュールに既定のコンテンツを追加する必要がない場合は、代わりに", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#editor-placeholders",
          children: "モジュールプレースホルダー"
        }), "を使用して、コンテンツクリエイターがコンテンツを配置するスペースを視覚的に把握できるようにしてください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "モジュールアイコン"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールには、モジュールに割り当てられたカスタムアイコン（既定のアイコンの代わりとなるもの）を含める必要があります。会社のロゴ（AppleやAmazonのロゴなど）をアイコンとして使用しないでください。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-an-icon",
        children: "モジュールアイコンの追加方法"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "サードパーティーのアカウントが必要なモジュール"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "個々のモジュールの場合、モジュールにサードパーティーのアカウントが必要な場合は、テンプレートの説明にその旨を記載する必要があります。例えば、モジュールでGoogle Maps Platformを使用している場合、「このモジュールを使用するにはGoogle Cloud（Google Maps Platform）のアカウントが必要です」という注意書きをテンプレートの説明に含める必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュールフィールド"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマ内のモジュールおよび独立したモジュールに関する以下の特定の要件をご確認ください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーマ内のモジュール：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "特定のフィールドに関するインライン ヘルプ テキストと具体的な既定のコンテンツが含まれている必要があります。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["テーマの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#color",
              children: "色"
            }), "と", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#logo",
              children: "ロゴ"
            }), "の部分は、アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/fields/brand-and-settings-inheritance",
              children: "ブランド設定"
            }), "を継承する必要があります。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "少なくとも3つの色フィールドは、アカウントのブランド設定から色を継承する必要があります。他の色フィールドでは、白と黒を含む他の色を既定の色として使用できます。"
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "少なくとも1つのロゴフィールドは、アカウントのブランド設定を継承する必要があります。画像フィールドを使用してロゴをレンダリングする場合、画像フィールドはブランド設定を継承する必要はありません。"
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テーマ内のモジュールと独立モジュールの両方：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "モジュールフィールド名は、フィールドの目的を説明するものでなければなりません。例えば、役職・職種(役職名)の入力用にテキストフィールドが作成されている場合、「役職・職種(役職名)」は説明として適切ですが、「名称」は不適切です。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "提出される全てのテンプレートで、HubSpotの全ての既定のモジュールが適切にスタイル設定され表示される必要があります。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "fields.jsonおよびmodule.htmlの設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマと独立モジュールの間の機能互換性を確保するには、モジュールが色フィールドとフォントフィールドを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#inherited-fields",
        children: "継承する"
      }), "必要があります。そのためには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイル内で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "default_value_path"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property_value_paths"
      }), "のどちらかまたは両方を定義します。また、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "ファイル内でテーマフィールドへの参照を追加します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/general-requirements",
        children: "これらの要件について詳しくご確認ください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュールのコード品質"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "モジュールは自己完結型でなければならない"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "テーマモジュール"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テーマモジュールに必要な全てのファイル（CSSやJavaScriptなど）がテーマフォルダーに格納されてテーマディレクトリーに入っている必要があります。デザインマネージャーで、リンク済みファイル機能を使用できます。あるいは、相対ファイルパスを指定した", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: "require_js()"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-css",
        children: "require_css()"
      }), "関数を使用してファイルをインクルードします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["slick.jsなどの一般的なライブラリーの場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js()"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css()"
      }), "関数で、ホスティング場所となっているCDNへの絶対URLを使ってこれらを含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**クロスポータル参照は解決されないので、開発ポータルに含まれるアセットへの絶対URLを使用しないでください。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "独立モジュール"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["独立したモジュールの場合、全てのCSSおよびJavascriptファイルが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), "に含まれている必要があります。あるいは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js()"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css()"
      }), "関数で、ホスティング場所となっているCDNへの絶対URLを使ってファイルを含めます。リンク済みファイル機能をデザインマネージャーで使用することはできません（テーマモジュールでのみ使用可能です）。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), "はDOM内で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), "ファイルの前に含まれるので、以下のアノテーションを使用して、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), "セクションに含まれるJavascriptを遅延させる必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "document.addEventListener('DOMContentLoaded', function () {\n  // Put Javascript here\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全てのスクリプトとファイルは、モジュールの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: "HTML"
      }), "の先頭でレンダリングされる必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "独立モジュールのコード制限"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の制限は、独立したモジュールにのみ当てはまります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["可能であれば", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://vanilla-js.com/",
          children: "簡素なJS"
        }), "を使用することをお勧めします。jQueryを使用しないサイトにjQueryライブラリーを追加すると、競合が発生してウェブサイトページの速度が低下する可能性があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["jQueryライブラリーを使用する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: "require_js()"
        }), "関数を使用してライブラリーをインクルードします。これにより、複数のjQueryライブラリーによる競合を避けるためにアカウント設定のチェックボックス（ブール値）でjQueryが無効にされている場合でも、ライブラリーがインクルードされます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "{% if not site_settings.include_jquery %}\n{{ require_js(\"https://code.jquery.com/jquery-3.7.0.min.js\", \"footer\") }}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カテゴリー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["全ての独立モジュールには、少なくとも1つのカテゴリーが必要です。テーマの一部分として提出されるモジュールではカテゴリーが必須ではありませんが、ベストプラクティスとして少なくとも1つを含めることをお勧めします。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "モジュールにカテゴリーを追加する"
        }), "方法についてご確認ください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "クラス名セレクター"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["クラス名セレクターの前にモジュール名の接頭辞を付ける必要があり、その際にはスペースをハイフンに置き換えます。例えば、以下に示すのは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "example-button"
        }), "というボタン用の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), "ファイルで、それぞれのクラス名とCSSセレクターがその名前を反映しています。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<style>\n{% scope_css %}\n{# Button wrapper #}\n {% if module.styles.group_alignment.alignment %}\n  .example-button-wrapper {\n   text-align: {{ module.styles.group_alignment.alignment.horizontal_align }};\n   }\n {% endif %}\n\n{# Button #}\n\n.example-button {\n {% if module.styles.group_background.color.color %}\n  background-color: rgba({{ module.styles.group_background.color.color|convert_rgb }}, {{ module.styles.group_background.color.opacity / 100 }});\n {% endif %}\n }\n {% end_scope_css %}\n</style>\n{% end_require_css %}\n\n{##### Module HTML #####}\n\n<div class=\"example-button-wrapper\">\n <a href=\"{{ href }}\" class=\"example-button\"\n {% if module.button_link.open_in_new_tab %}target=\"_blank\"{% endif %}\n {% if rel %}rel=\"{{ rel|join(\" \") }}\"{% endif %}\n >\n  {{ module.button_text }}\n </a>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "スタイルとJavascript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["スタイル：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "モジュールには空でないスタイルグループが含まれる必要があります。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "モジュール内でインラインスタイルをハードコーディングすることは推奨されません。代わりに、フィールドでスタイルを制御できるようにして、動的インラインスタイルを使用してください。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["JavaScript：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "JavaScriptは、モジュールの複数インスタンスを表すことができる必要があります。JSペインのJavaScriptは、モジュールの使用回数にかかわらず、1ページにつき一度だけ読み込まれます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "JavaScriptではモジュール固有のクラス名でDOM要素を参照して、モジュール外部の要素に意図しない影響が及ばないようにする必要があります。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールを作成する際には、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{name}}"
      }), "という組み込み変数を使用できます。この変数は、複雑なモジュールのCSSとJSマークアップで役立つ、モジュールのインスタンスID（HTML+HubLパネルでのみ使用可能）を取得します。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/files#require-css-block",
        children: "開発者ドキュメント"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フィールドの構成"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下のフィールドの構成とグループ化の要件を満たす必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "コンテンツタブ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "フィールドグループ内に少なくとも1つの制御設定がある場合は、全ての制御設定を、その機能によってラベル付けされたカテゴリーにグループ化する必要があります。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［コンテンツ］タブに追加されるモジュールフィールドで、モジュールのコンテンツをカスタマイズできるようにする必要があります。例えば、画像、アイコン、代替（Alt）テキストのコントロールや、リンク制御設定などです。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "スタイルタブ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールのスタイル フィールド グループは一貫性を持ち、パターンに従う必要があります。以下は、スタイル フィールド グループの推奨される順序です。これらのグループは、トップレベルに配置することも、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#multi-level-grouping",
        children: "1つのグループにネスト"
      }), "することもできます。また、空のグループは削除できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#presets",
          children: "プリセット"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テキスト"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "背景"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "境界線"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "マウスオーバー"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "角"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "間隔"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "位置合わせ"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "上記以外のカスタム スタイル グループ"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "詳細"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のフィールドタイプが存在する場合は、［スタイル］タブに含める必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#alignment",
          children: "位置合わせ"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#background-image",
          children: "背景画像"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#border",
          children: "境界線"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#color",
          children: "色"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#font",
          children: "フォント"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#gradient",
          children: "グラデーション"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#spacing",
          children: "間隔"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#text-alignment",
          children: "テキスト位置合わせ"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["［コンテンツ］タブから［スタイル］タブにフィールドを移動する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#aliases",
          children: "エイリアスマッピングを使用"
        }), "して、ライブページですでに使用されているモジュールのスタイルを維持する方法を確認してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "アニメーションオプションは、常にフィールド グループ リストの末尾近くに置く必要があります。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "コンテンツクリエイターがコードスニペットまたはCSSを追加できるようにするオプションは、フィールド グループ リストの末尾にある「詳細」というラベルのフィールドにグループ化する必要があります。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "コントロールは、全てのモジュールで標準化する必要があります。例えば、境界線の半径のコントロールを持つことができる全ての要素は、そのコントロールを提供する必要があります。一部のモジュールで、他のモジュールに存在しないコントロールを提供することは避けてください。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［スタイル］タブに追加されたモジュールフィールドは、モジュールのスタイルを設定する方法を提供する必要があります。例として、以下のような場合が挙げられます。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "色、テキストスタイル、位置合わせ、間隔、境界線、角の半径などのスタイルオプション。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ホバー効果やスライドイン効果などのアニメーション。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "さまざまなスタイルを同時に変更することを目的とする、ダークテーマとライトテーマなどのプリセット。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "フィールドの構成の例"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "プリセット"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プリセットは、限られたオプションのセットをコンテンツクリエイターに提供したい場合に使用でき、通常テーマ設定に紐付けられています。例えば、Growthテーマに含まれている「Icon（アイコン）」モジュールには「ダーク」カラーと「ライト」カラーのプリセットが含まれており、ウェブサイト全体で使用すると一貫性を提供できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "複数レベルのグループ化"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "スタイルフィールドをトップレベルに配置するかネストするかを決定する際には、次の例を考慮してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "Growthテーマに含まれている「Icon（アイコン）」モジュールは、単一のコンポーネントであるため、全てのスタイルオプションがトップレベルに配置されます。したがって、全てのスタイルオプションがこの1つのコンポーネントに影響します。"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/growth-theme-icon-module.png",
            alt: "growth-theme-icon-module"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "Growthテーマに含まれている「スピーカーカード」モジュールには、カードの画像とテキストコンテンツという複数のコンポーネントが含まれています。したがって、モジュールスタイルはコンポーネントごとにグループ化されており、コンテンツクリエイターが各コンポーネントのスタイルを設定するためのプロセスが明確化されます。"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/growth-theme-speaker-card.png",
            alt: "growth-theme-speaker-card"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "個々のフィールドのグループ化"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下のボタンモジュールには、［プリセット］、［テキスト］、［背景］などのグループが含まれています。［角］フィールドグループには角の半径のコントロールしか含まれていませんが、一貫性のあるコンテンツ作成体験を提供するためにグループ化されています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-requirements-2_1.png",
        alt: "module-requirements-2_1"
      }), (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/button-styles.png",
        alt: "button-styles"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "エイリアス"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "エイリアスマッピングを使用すると、モジュール内にフィールドマッピングを作成して、モジュールを使用しているページに影響を及ぼすことなく、そのフィールドの移動、名前変更、置換を行うことができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、ライブページでモジュールが使用されているとします。色やフォントなどの一部のフィールドを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "［スタイル］タブ"
      }), "に移動したいのですが、コンテンツ作成者がエディターでそれらのフィールドの値をすでに選択しています。エイリアスマッピングを設定せずにそれらのフィールドを移動した場合、HubSpotはそれらのフィールドを再配置できず、既定値に戻ります。そのため、ライブページのスタイルは元に戻されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["代わりに、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "aliases_mapping"
      }), "プロパティーをフィールドに追加して、別のフィールドにマッピングすることができます。次に、元のフィールドに値が設定されていない場合、HubSpotはマッピングされたフィールドに値が存在するかどうかを確認します。マッピングされたフィールドに値が存在しない場合は、代わりに既定値が使用されます。このプロパティーは、元のフィールドの格納済みのデータ型が新しいフィールドの格納済みのデータ型と同じである場合にのみ、モジュールの異なるバージョン間でフィールド値をマッピングするために使用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この機能を視覚的に解説している以下の動画をご覧ください。"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/SvESTX8SLJQ",
      maxHeight: "367px",
      maxWidth: "700px",
      allowFullScreen: true
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "既存のフィールドをエイリアスに移行する手順は、以下のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["新しいフィールドを作成し、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aliases_mapping"
        }), "プロパティーを使用して古いフィールドにマッピングします。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "古いフィールド定義を削除します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["新しいフィールド定義を使用するように、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), "ファイルを更新します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "データ型が異なるフィールドを相互にマッピングすることはできません。例えば、背景グラデーションフィールドを画像フィールドにマッピングすることはできません。格納される値は、新しいフィールドのタイプで有効な値でなければなりません。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "元のフィールドへのエイリアスがマッピングされた新しいフィールドを作成する場合、両方のフィールドの既定値と必須プロパティーが同一でなければなりません。"
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "単純な変更と複雑な変更の両方について、これを実装する例を以下に示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#simple-implementation",
          children: "単純な実装"
        }), "：色フィールドを別の新しい色フィールドにマッピングする。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#complex-implementation",
          children: "複雑な実装"
        }), "：数値フィールドをフォントフィールドの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "size"
        }), "サブフィールドにマッピングして、フォントサイズを制御する。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "単純な実装"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "単純な実装の場合は、元のフィールドのフィールドタイプと新しいフィールドのフィールドタイプが同一でなければなりません。例として、以下のような場合が挙げられます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "元の色フィールドから新しい色フィールド。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "元のテキストフィールドから新しいテキストフィールド。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "元の間隔フィールドから新しい間隔フィールド。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールの［コンテンツ］タブから［スタイル］に色フィールドを移動する場合に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "aliases_mapping"
      }), "を使用する例を以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v0 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"label\": \"Button Color\",\n    \"name\": \"old_button_color_field\",\n    \"type\": \"color\",\n    \"required\": true,\n    \"default\": {\n      \"color\": \"#FFFFFF\",\n      \"opacity\": 100\n    }\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v1 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"label\": \"Styles\",\n    \"name\": \"styles\",\n    \"type\": \"group\",\n    \"tab\": \"STYLE\",\n    \"children\": [\n      {\n        \"label\": \"Button Color\",\n        \"name\": \"new_button_color_field\",\n        \"type\": \"color\",\n        \"required\": true,\n        \"aliases_mapping\": {\n          \"property_aliases_paths\": {\n            \"new_button_color_field\": [\"old_button_color_field\"]\n          }\n        },\n        \"default\": {\n          \"color\": \"#FFFFFF\",\n          \"opacity\": 100\n        }\n      }\n    ]\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Complex implementation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "より複雑な実装の場合は、データ型が同一で、新しいフィールドのサブフィールドタイプが一致していれば、フィールドをサブフィールドまたは他のモジュールのフィールドタイプにマッピングすることもできます。サブフィールドは、フィールドの格納済みの値オブジェクト内のプロパティーです。例として、以下のような場合が挙げられます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "「リッチテキスト」フィールドと「テキスト」フィールドの値は両方とも文字列として格納されるため、前者を後者にマッピングします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["フォントサイズの数値フィールドを（フォント サイズ サブフィールドを持つ）フォントフィールドに変更するなど、タイポグラフィーフィールドを統合します。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "size"
        }), "サブフィールドのエイリアスを追加し、ドット表記を使用して元の数値フィールドにマッピングできます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "フォント サイズ オプションを数値フィールドからフォント サイズ サブフィールドを持つフォントフィールドに変更する例を以下に示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v0 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"name\": \"my_number_field\",\n    \"label\": \"Number field\",\n    \"required\": false,\n    \"locked\": false,\n    \"display\": \"text\",\n    \"step\": 1,\n    \"type\": \"number\",\n    \"min\": null,\n    \"max\": null,\n    \"inline_help_text\": \"\",\n    \"help_text\": \"\",\n    \"default\": null\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v1 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"name\": \"my_font_field\",\n    \"label\": \"font_field\",\n    \"required\": false,\n    \"locked\": false,\n    \"inline_help_text\": \"\",\n    \"help_text\": \"\",\n    \"load_external_fonts\": true,\n    \"type\": \"font\",\n    \"aliases_mapping\": {\n      \"property_aliases_paths\": {\n        \"my_font_field.size\": [\"my_number_field\"]\n      }\n    },\n    \"default\": {\n      \"size\": 12,\n      \"font\": \"Merriweather\",\n      \"font_set\": \"GOOGLE\",\n      \"size_unit\": \"px\",\n      \"color\": \"#000\",\n      \"styles\": {}\n    }\n  }\n]\n"
          })
        })
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}