'use es6';

import Theme from 'react-codemirror/enum/Theme';
import { omit } from './miniUnderscore.js';
const hsThemes = ['HUBSPOT_CANVAS_DARK', 'HUBSPOT_CANVAS_LIGHT'];
const nonHsThemes = omit(Theme, ...hsThemes);

/**
 * Display order of themes by `Theme` key.
 *
 * @type {Array.<String>}
 */
export const THEME_ORDER = Object.freeze([...hsThemes, ...Object.keys(nonHsThemes).sort()]);

/**
 * All theme values. These are the values accepted by CodeMirror
 * to create the 'cm-<theme-name>' classes.
 *
 * @type {Set.<String>}
 */
export const THEME_VALUES = new Set(THEME_ORDER.map(key => Theme[key]));

/**
 * Map of UI theme display names by `Theme` key.
 *
 * @type {Object.<String, String>}
 */
export const THEME_DISPLAY_NAMES = Object.freeze(THEME_ORDER.reduce((coll, key) => {
  const value = Theme[key];
  if (value === Theme.DEFAULT) {
    coll[key] = 'Basic';
    return coll;
  }
  const parts = value.split(/-|\s/);
  const upperCamel = parts.map(str => {
    if (/\d/.test(str[0])) {
      return str;
    }
    return `${str[0].toUpperCase()}${str.slice(1)}`;
  }).join(' ');
  coll[key] = upperCamel;
  return coll;
}, {}));

/**
 * Value to be used with UISelectInput#options
 *
 * @type {Array.<Object>}
 */
export const UISELECT_THEME_OPTIONS = Object.freeze(THEME_ORDER.map(key => ({
  value: Theme[key],
  text: THEME_DISPLAY_NAMES[key]
})));