/**
 * Table of contents width, in pixels, on wider screens.
 */
export const TOC_WIDTH = 293;

/**
 * Table of contents width on narrower viewports, in pixels.
 */
export const TOC_WIDTH_NARROW = 200;

/**
 * The right offset of the TOC, in pixels.
 */
export const TOC_RIGHT_OFFSET = 36;