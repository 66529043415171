'use es6';

import { Set as ImmutableSet } from 'immutable';
const keyBy = (array, key) => /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
(array || []).reduce((r, x) => Object.assign({}, r, {
  [key ? x[key] : x]: x
}), {});
export const collectionKeyBy = (collection, key) => {
  const c = collection || {};
  return Array.isArray(c) ? keyBy(c, key) : Object.values(keyBy(c, key));
};
export const isNil = value => value === undefined || value === null;
export const unique = arr => [...new ImmutableSet(arr)];
export const get = (obj, path, defaultValue) => {
  // If path is not defined or it has false value
  if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  // Find value if exist return otherwise return undefined value;
  const value = pathArray.reduce((prevObj, key) => prevObj && Object.prototype.hasOwnProperty.call(prevObj, key) ? prevObj[key] : undefined, obj);
  return value === undefined ? defaultValue : value;
};
export const has = (obj, path) => {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  return !!pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj);
};
export const pull = (arr, ...removeList) => {
  const removeSet = new ImmutableSet(removeList);
  return arr.filter(el => {
    return !removeSet.has(el);
  });
};
export const jsonEquals = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};
export const deepCopy = value => {
  if (value instanceof Array) {
    const arr = [];
    for (let i = 0; i < value.length; i++) {
      arr.push(deepCopy(value[i]));
    }
    return arr;
  }
  if (value instanceof Object) {
    const clone = {};
    for (const i in value) {
      if (value[i] !== null && value[i] instanceof Object) clone[i] = deepCopy(value[i]);else clone[i] = value[i];
    }
    return clone;
  }
  return value;
};