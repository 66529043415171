"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816620917;
const slug = exports.slug = 'guides/api/app-management/oauth/tokens';
const title = exports.title = 'OAuth | Administración de tokens';
const name = exports.name = 'EMEA ES vNext Docs DP - OAuth Tokens_ES';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "obtener-tokens-de-acceso-y-de-actualizaci%C3%B3n-de-oauth-2.0",
  "label": "Obtener tokens de acceso y de actualización de OAuth 2.0:",
  "parent": null
}, {
  "depth": 0,
  "id": "obt%C3%A9n-informaci%C3%B3n-sobre-los-tokens-de-acceso-o-de-actualizaci%C3%B3n-de-oauth-2.0",
  "label": "Obtén información sobre los tokens de acceso o de actualización de OAuth 2.0:",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-el-token-de-actualizaci%C3%B3n-de-oauth-2.0",
  "label": "Eliminar el token de actualización de OAuth 2.0:",
  "parent": null
}, {
  "depth": 0,
  "id": "uso-de-los-tokens-de-acceso-de-oauth-2.0",
  "label": "Uso de los tokens de acceso de OAuth 2.0:",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      strong: "strong",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 10,
        children: [(0, _jsxRuntime.jsx)(_components.h1, {
          children: "Administración de tokens"
        }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtener tokens de acceso y de actualización de OAuth 2.0:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usa el código que obtienes después de que un usuario autorice que obtengas un token de acceso y un token de actualización para tu aplicación. El token de acceso se utilizará para autenticar las solicitudes que realice tu aplicación. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Los tokens de acceso vencen después de seis horas"
      }), ", por lo que puedes usar el punto final del token de actualización para obtener un nuevo token de acceso cuando venza el primer token de acceso."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usa un token de actualización obtenido anteriormente para generar un nuevo token de acceso. Los tokens de acceso vencen después de seis horas. Si necesitas acceso sin conexión a los datos de HubSpot, almacena el token de actualización que obtienes cuando inicies tu integración de OAuth y lo usas para generar un nuevo token de acceso una vez que venza el inicial."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Nota"
      }), ": Los tokens de acceso de HubSpot variarán de tamaño a medida que cambiemos la información codificada. Recomendamos permitir que los tokens tengan hasta 300 caracteres para tener en cuenta cualquier cambio."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtén información sobre los tokens de acceso o de actualización de OAuth 2.0:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Obtén los metadatos para un token de acceso o de actualización. Esto se puede usar para obtener la dirección de correo electrónico del usuario de HubSpot para el que se creó el token, así como el ID de Hub con el que está asociado el token."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar el token de actualización de OAuth 2.0:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Elimina un token de actualización. Puedes usar esto para eliminar tu token de actualización si un usuario desinstala tu aplicación."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Nota:"
      }), " Esto solo eliminará el token de actualización. Los tokens de acceso generados con el token de actualización no serán afectados. Además, esto no desinstalará una aplicación de una cuenta de HubSpot ni inhibirá la sincronización de datos entre una cuenta y una aplicación conectada."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uso de los tokens de acceso de OAuth 2.0:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "// Authorization: Bearer {token}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "curl --request GET \\"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "--url 'https://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false' \\"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "--header 'authorization: Bearer CJSP5qf1KhICAQEYs-gDIIGOBii1hQIyGQAf3xBKmlwHjX7OIpuIFEavB2-qYAGQsF4'"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{contacts: .....}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En este ejemplo, el token de acceso es:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "CJSP5qf1KhICAQEYs-gDIIGOBii1hQIyGQAf3xBKmlwHjX7OIpuIFEavB2-qYAGQsF4"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Notas:"
      }), " Si usas tokens de acceso de OAuth 2.0, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "no"
      }), " deberías incluir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hapikey="
      }), " en la URL de solicitud. El encabezado de autorización se utiliza en lugar de ese parámetro de consulta."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}