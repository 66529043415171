"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 51261306631;
const slug = exports.slug = 'guides/api/crm/associations';
const title = exports.title = 'API del CRM |  Asociaciones v4';
const name = exports.name = 'vNext Docs DP - Asociaciones v4';
const metaDescription = exports.metaDescription = 'Los puntos de terminación del CRM se utilizan para administrar asociaciones entre tickets, productos, elementos de pedido y sus contactos, empresas y negocios relacionados.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tipos-de-asociaciones-definidos-por-hubspot",
  "label": "Tipos de asociaciones definidos por HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "etiquetas-individuales-vs.-emparejadas",
  "label": "Etiquetas individuales vs. emparejadas",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-tipos-de-asociaciones",
  "label": "Crear tipos de asociaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "obtener-tipos-de-asociaciones",
  "label": "Obtener tipos de asociaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "asociar-registros",
  "label": "Asociar registros",
  "parent": null
}, {
  "depth": 1,
  "id": "asociar-registros-sin-etiquetas",
  "label": "Asociar registros sin etiquetas",
  "parent": "asociar-registros"
}, {
  "depth": 1,
  "id": "asociar-registros-con-etiquetas",
  "label": "Asociar registros con etiquetas",
  "parent": "asociar-registros"
}, {
  "depth": 0,
  "id": "definir-y-gestionar-l%C3%ADmites-de-asociaciones",
  "label": "Definir y gestionar límites de asociaciones",
  "parent": null
}, {
  "depth": 1,
  "id": "crear-o-actualizar-l%C3%ADmites-de-asociaciones",
  "label": "Crear o actualizar límites de asociaciones",
  "parent": "definir-y-gestionar-l%C3%ADmites-de-asociaciones"
}, {
  "depth": 1,
  "id": "obtener-l%C3%ADmites-de-asociaciones",
  "label": "Obtener límites de asociaciones",
  "parent": "definir-y-gestionar-l%C3%ADmites-de-asociaciones"
}, {
  "depth": 1,
  "id": "eliminar-l%C3%ADmites-de-asociaciones",
  "label": "Eliminar límites de asociaciones",
  "parent": "definir-y-gestionar-l%C3%ADmites-de-asociaciones"
}, {
  "depth": 0,
  "id": "informes-sobre-alto-uso-de-asociaciones",
  "label": "Informes sobre alto uso de asociaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "valores-de-id-de-tipos-de-asociaciones",
  "label": "Valores de ID de tipos de asociaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "asociaciones-v1-anterior",
  "label": "Asociaciones v1 (anterior)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      em: "em",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Asociaciones v4"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para la versión anterior, consulta la documentación de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/associations/association-details/v3",
          children: "API de asociaciones v3"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las asociaciones representan las relaciones entre objetos y actividades en el CRM. Pueden existir asociaciones de registros entre registros de diferentes objetos (por ejemplo, contacto con empresa), así como de un mismo objeto (por ejemplo, empresa con empresa). Puedes usar los puntos de terminación de asociaciones para crear, obtener, actualizar o eliminar asociaciones entre registros o entre registros y actividades."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los puntos de terminación del esquema de asociaciones te permiten ver los tipos de asociaciones admitidos en tu cuenta, así como crear tus propios tipos de asociaciones y asignar etiquetas a las relaciones de los registros. Se admiten etiquetas de asociación entre contactos, empresas, negocios, tickets y objetos personalizados, y se pueden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels#use-association-labels-in-hubspot-tools",
        children: "usar en herramientas de HubSpot"
      }), ", como listas y workflows."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre las API de objetos, registros, propiedades y asociaciones en la guía ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Comprender el CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": La API de asociaciones v4 es compatible con la versión 9.0.0 o posterior del cliente NodeJS HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de asociaciones definidos por HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot proporciona un conjunto de tipos de asociaciones predefinidos (por ejemplo, contacto con empresa sin etiqueta), pero los administradores de cuentas pueden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "definir sus propias etiquetas de asociación"
      }), " para proporcionar información adicional de las relaciones entre los registros (por ejemplo, mánager y empleado). Existen dos tipos de asociaciones definidas por HubSpot:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Principal:"
        }), " la empresa principal con la que está asociado el otro registro. Las asociaciones principales se pueden utilizar en herramientas de HubSpot, como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/associations-enhancements-beta#use-associations-in-hubspot-tools",
          children: "listas y workflows"
        }), ". En los registros con varias empresas asociadas, esta API permite cambiar cuál empresa se considera la principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sin etiqueta:"
        }), " un tipo de asociación agregado cuando se asocia cualquier contacto, empresa, negocio, ticket o registro de objeto personalizado. Este tipo indica que existe una asociación y siempre se devolverá en las respuestas con un valor de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "etiqueta"
        }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), ". Cuando un registro tiene una asociación principal o una etiqueta de asociación personalizada, esos tipos se enumerarán junto con el tipo de asociación sin etiqueta."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes ver todos los tipos de asociaciones definidos por HubSpot en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#association-type-id-values",
        children: "esta sección"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Etiquetas individuales vs. emparejadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hay dos tipos de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "etiquetas de asociación"
      }), " que puedes usar para describir las relaciones entre registros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Individual:"
        }), " una etiqueta que se aplica a ambos registros en la relación. Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Amigo"
        }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Colega"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Emparejadas"
        }), ": un par de etiquetas para cuando se utilizan diferentes palabras para describir cada lado de la relación de los registros asociados. Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Padre"
        }), " e ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hijo"
        }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Empleador"
        }), " y ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Empleado"
        }), ". Para crear etiquetas emparejadas, debes incluir el campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inverseLabel"
        }), " en la solicitud para darle un nombre a la segunda etiqueta en el par."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear tipos de asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes crear tipos de asociaciones personalizadas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "en HubSpot"
      }), " o a través del punto de terminación de la API del esquema de asociaciones. Puedes crear hasta 10 tipos de asociaciones entre cada par de objetos (por ejemplo, contactos y empresas, contactos y contactos)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear un tipo de asociación a través de la API, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
      }), " e incluye lo siguiente:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), ": el nombre interno del tipo de asociación. Este valor ", (0, _jsxRuntime.jsx)("u", {
          children: "no puede"
        }), " incluir guiones ni comenzar con un carácter numérico."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "etiqueta"
        }), ": el nombre de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
          children: "etiqueta de asociación tal como se muestra en HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "inverseLabel"
        }), " (solo etiquetas emparejadas): el nombre de la segunda etiqueta del par de etiquetas."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, tu solicitud podría tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "///Example request body - Single label\n{\n  \"label\": \"Partner\",\n  \"name\": \"partner\"\n}\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "///Example request body - Paired labels\n{\n  \"label\": \"Manager\",\n  \"inverseLabel\": \"Employee\",\n  \"name\": \"manager_employee\"\n}\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtener tipos de asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver los tipos de asociaciones entre objetos específicos, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Recibirás una matriz en la que cada elemento contiene:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "categoría:"
        }), " si el tipo de asociación fue creado por HubSpot (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "HUBSPOT_DEFINED"
        }), ") o por un usuario (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "USER_DEFINED"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "typeId:"
        }), " el ID numérico de ese tipo de asociación. Se utiliza para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#associate-with-label",
          children: "definir una etiqueta al asociar registros"
        }), ". Consulta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#association-type-id-values",
          children: "esta lista"
        }), " para ver todos los valores ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "typeId"
        }), " definidos por HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "etiqueta:"
        }), " la etiqueta alfanumérica. Será ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " para el tipo de asociación sin etiqueta."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes encontrar estos valores en HubSpot en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/create-and-use-association-labels#association-label-api-details",
        children: "configuración de asociaciones"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Asociar registros"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociar registros sin etiquetas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes crear una asociación sin etiqueta predeterminada entre dos registros o configurar asociaciones sin etiqueta para registros en bloque. Para configurar una asociación individual predeterminada entre dos registros, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/{fromObjectType}/{fromObjectId}/associations/default/{toObjectType}/{toObjectId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En la URL de la solicitud, incluye:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectType"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " el ID del objeto que estás asociando. Para encontrar los valores de ID, consulta esta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "lista de ID de tipos de objetos,"
        }), " o, para contactos, empresas, negocios, tickets y notas, puedes usar el nombre del objeto (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectId"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " el ID del registro que se asociará."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectType"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " el ID del objeto con el que estás asociando el registro. Para encontrar los valores de ID, consulta esta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "lista de ID de tipos de objetos,"
        }), " o, para contactos, empresas, negocios, tickets y notas, puedes usar el nombre del objeto (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectId"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " el ID del registro con el que se va a hacer la asociación"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, para asociar un registro de contacto cuyo ID es 12345 con un registro de empresa cuyo ID es ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "67891"
      }), ", la URL de tu solicitud sería: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/contact/12345/associations/default/company/67891"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para configurar asociaciones predeterminadas en bloque, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v4/associations/{fromObjectType}/{toObjectType}/batch/associate/default"
      }), ". En el cuerpo de la solicitud, incluye valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " para los registros que quieres asociar."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": El número de asociaciones que puede tener un registro depende ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/hubspot-product-and-services-catalog#TechnicalLimits:~:text=CRM%20Record%20Association%20Limits",
          children: "del objeto y de tu suscripción a HubSpot."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociar registros con etiquetas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para asociar dos registros y definir una etiqueta para describir la asociación, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/{objectType}/{objectId}/associations/{toObjectType}/{toObjectId}"
      }), ". En el cuerpo de la solicitud, incluye ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " para indicar el tipo de asociación que quieres crear."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás creando asociaciones sin etiqueta, puedes usar los puntos de terminación predeterminados descritos en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#associate-records-without-a-label",
        children: "sección anterior"
      }), " que no requieren ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), " ni ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), ". Si estás creando asociaciones con una etiqueta, puedes consultar esta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#association-type-id-values",
        children: "lista de ID de tipos predeterminados"
      }), " o tendrás que obtener los tipos de asociaciones personalizados entre esos objetos."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": En cuanto a las relaciones de etiquetas multiobjeto y emparejadas, asegúrate de usar el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "typeId"
        }), " que se refiere a la dirección correcta (por ejemplo, contacto a empresa vs. empresa a contacto, empleado a mánager vs. mánager a empleado)."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, para asociar un contacto con un negocio usando una etiqueta personalizada:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1. Haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/contact/deal/labels"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["2. En la respuesta, fíjate en los valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), " de la etiqueta. El ID será un número (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "36"
      }), "), y la categoría siempre será ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "USER_DEFINED"
      }), " para las etiquetas personalizadas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["3. Haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/contact/{objectId}/associations/deal/{toObjectId}"
      }), "con el siguiente cuerpo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "/// Example request body\n[\n  {\n    \"associationCategory\": \"USER_DEFINED\",\n    \"associationTypeId\": 36\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Definir y gestionar límites de asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes definir ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/set-limits-for-record-associations",
        children: "límites"
      }), " para el número de registros asociados entre objetos o la frecuencia con la que se puede utilizar una etiqueta para describir asociaciones. También hay ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "límites técnicos y límites basados en tu suscripción de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear o actualizar límites de asociaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes crear límites de asociaciones nuevos o actualizar los existentes entre los objetos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para crear límites, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/create"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para actualizar los límites existentes, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/update"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el cuerpo de la solicitud, incluye ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), " con lo siguiente:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "category"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La categoría de la asociación para la que estás fijando un límite, ya sea ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_DEFINED"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "USER_DEFINED"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "typeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID numérico del tipo de asociación para la que quieres fijar un límite. Consulta ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#association-type-id-values",
              children: "esta lista"
            }), " de valores ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "typeId"
            }), " predeterminados u ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#retrieve-association-types",
              children: "obtén el valor"
            }), " de las etiquetas personalizadas."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxToObjectIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número máximo de asociaciones permitido para el tipo de asociación."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, para fijar límites para que un negocio se pueda asociar con un máximo de cinco contactos con un solo contacto con la etiqueta ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "punto de contacto"
      }), " en un negocio, tu solicitud debería lucir así:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request POST crm/v4/associations/definitions/configurations/deal/contact/batch/create\n{\n  \"inputs\": [\n    {\n      \"category\": \"HUBSPOT_DEFINED\",\n      \"typeId\": 3,\n      \"maxToObjectIds\": 5\n    },\n    {\n      \"category\": \"USER_DEFINED\",\n      \"typeId\": 35,\n      \"maxToObjectIds\": 1\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obtener límites de asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para leer todos los límites de asociaciones definidos, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/definitions/configurations/all"
        }), ". Se devolverán los límites de asociaciones definidos en todos los objetos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para leer límites de asociaciones entre dos objetos específicos, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En ambas solicitudes, la respuesta devolverá los valores de las asociaciones para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "maxToObjectIds"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), ". Por ejemplo, si se obtienen los límites entre negocios y contactos, la respuesta sería similar a:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example response GET crm/v4/associations/definitions/configurations/deal/contact\n{\n  \"results\": [\n    {\n      \"category\": \"HUBSPOT_DEFINED\",\n      \"typeId\": 3,\n      \"userEnforcedMaxToObjectIds\": 5,\n      \"label\": null\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eliminar límites de asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar límites de asociaciones específicos, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/purge"
      }), ". En el cuerpo de la solicitud, incluye los valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), " de los tipos de asociaciones para los cuáles quieres eliminar los límites."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, para eliminar el límite ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Punto de contacto"
      }), " entre negocios y contactos, la solicitud luciría de esta forma:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request POST crm/v4/associations/definitions/configurations/deal/contact/batch/purge\n{\n  \"inputs\": [\n    {\n      \"category\": \"USER_DEFINED\",\n      \"typeId\": 35\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si se hace correctamente, recibirás una respuesta 204 y el límite incluido volverá al valor predeterminado del sistema (es decir, Muchos contactos pueden tener la etiqueta ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Punto de contact"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Informes sobre alto uso de asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Existen límites técnicos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "en cuanto al número de asociaciones que puede tener un registro"
      }), ". Puedes usar la API de asociaciones para obtener un informe de los registros que se están aproximando al máximo límite de asociaciones o que ya lo alcanzaron."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener el informe, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v4/associations/usage/high-usage-report/{userID}"
      }), ". El archivo incluye registros que usan el 80% o más de su límite de asociaciones. Por ejemplo, si se puede asociar una empresa con hasta 50.000 contactos, la empresa se incluirá en el archivo si tiene 40.000 o más registros asociados. El archivo se enviará al correo del usuario cuyo ID se incluyó en la URL de la solicitud. Descubre cómo obtener ID de usuario con la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/users/user-provisioning",
        children: "API de usuarios"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Valores de ID de tipos de asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las siguientes tablas incluyen los valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " definidos por HubSpot que especifican el tipo de asociación. Los tipos de asociaciones varían dependiendo de los objetos incluidos y de la dirección de la asociación (por ejemplo, contacto a empresa es diferente de empresa a contacto). Si creas objetos personalizados o etiquetas de asociaciones personalizadas, los tipos de asociaciones relacionados tendrán valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), " únicos que deberás ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#retrieve-association-type",
        children: "obtener"
      }), " o localizar en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/create-and-use-association-labels#association-label-api-details",
        children: "configuración de asociaciones de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": Los tipos de asociaciones de empresas predeterminadas incluyen un tipo de asociación sin etiqueta y un tipo de asociación principal. Si un registro tiene más de una empresa asociada, solo una puede ser la empresa principal. Las otras asociaciones pueden ser sin etiqueta o tener etiquetas de asociación personalizadas."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Contacto a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "449"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacta a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "279"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a empresa (principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "4"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "15"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "193"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a llamada"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "197"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a correo"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "199"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a reunión"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "201"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a nota"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "203"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a tarea"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "82"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a comunicación (mensaje SMS, de WhatsApp o de LinkedIn)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "454"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a correo postal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "587"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a carrito"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "508"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "178"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a factura"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "388"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a pago"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "296"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto a suscripción"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Empresa a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "450"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "14"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa secundaria a empresa principal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "13"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa principal a empresa secundaria"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "280"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "2"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a contacto (principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "342"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "6"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a negocio (principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "340"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "25"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a ticket (principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "181"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a llamada"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "185"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a correo"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "187"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a reunión"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "189"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a nota"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "191"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a tarea"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "88"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a comunicación (mensaje SMS, de WhatsApp o de LinkedIn)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "460"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a correo postal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "180"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a factura"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "510"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "390"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a pago"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "298"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa a suscripción"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Negocio a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "451"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "3"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "341"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "5"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a empresa (principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "27"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "205"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a llamada"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "209"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a correo"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "211"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a reunión"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "213"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a nota"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "215"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a tarea"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "86"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a comunicación (mensaje SMS, de WhatsApp o de LinkedIn)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "458"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a correo postal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "313"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a división del negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "19"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a elemento de pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "176"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a factura"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "511"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "392"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a pago"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "630"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a producto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "63"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a cotización"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "300"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negocio a suscripción"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Ticket a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "452"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "16"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "339"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "26"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a empresa (principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "28"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "219"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a llamada"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "223"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a correo"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "225"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a reunión"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "227"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a nota"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "229"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a tarea"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "84"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a comunicación (mensaje SMS, de WhatsApp o de LinkedIn)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "456"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a correo postal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "32"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a cadena"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "278"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a conversación"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "526"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket a pedido"
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsx)(_components.h3, {
          children: "Lead a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "578"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead a contacto principal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "596"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead a llamada"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "598"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead a correo"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "600"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead a reunión"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "602"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead a comunicación"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "608"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "610"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "646"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead a tarea"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Llamada a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "194"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Llamada a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "182"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Llamada a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "206"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Llamada a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "220"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Llamada a ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Correo a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "198"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Correo a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "186"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Correo a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "210"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Correo a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "224"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Correo a ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Reunión a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "200"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Reunión a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "188"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Reunión a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "212"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Reunión a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "226"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Reunión a ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Nota a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "202"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Nota a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "190"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Nota a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "214"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Nota a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "228"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Nota a ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Correo postal a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "453"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Correo postal a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "459"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Correo postal a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "457"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Correo postal a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "455"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Correo postal a ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Cotización a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "69"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "71"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "64"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "67"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a elemento de pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "286"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a plantilla de cotización"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "362"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a descuento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "364"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a tarifa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "366"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a impuestos"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "702"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contacto firmante (para firmas electrónicas)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "733"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a carrito"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "408"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a factura"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "731"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "398"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a pago"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "304"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Cotización a suscripción"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Tarea a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "204"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Tarea a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "192"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Tarea a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "216"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Tarea a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "230"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Tarea a ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Comunicación (mensaje SMS, de WhatsApp o de LinkedIn) a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "81"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Comunicación (mensaje SMS, de WhatsApp o de LinkedIn) a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "87"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Comunicación (mensaje SMS, de WhatsApp o de LinkedIn) a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "85"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Comunicación (mensaje SMS, de WhatsApp o de LinkedIn) a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "83"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Comunicación (mensaje SMS, de WhatsApp o de LinkedIn) a ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Pedido a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "593"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a carrito"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "507"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "509"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "512"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a negocio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "519"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a descuento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "521"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a código de descuento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "518"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a factura"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "513"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a elemento de pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "523"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a pago"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "730"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a cotización"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "516"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a suscripción"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "726"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a tarea"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "525"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido a ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Carrito a objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID de tipo"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de asociación"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "586"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrito a contacto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "588"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrito a descuento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "590"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrito a elemento de pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "592"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrito a pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "732"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrito a cotización"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "728"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrito a tarea"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "594"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrito a ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Asociaciones v1 (anterior)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si estás usando la API de asociaciones v1, consulta la tabla que aparece abajo para obtener más información sobre qué ID usar al asociar registros."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de asociación"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "ID"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacto a empresa"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa a contacto (predeterminado)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "2"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa a contacto (todas las etiquetas)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "280"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Negocio a contacto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "3"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacto a negocio"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "4"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Negocio a empresa"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "5"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa a negocio"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "6"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa a interacción"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "7"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Interacción a empresa"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "8"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacto a interacción"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "9"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Interacción a contacto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "10"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Negocio a interacción"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "11"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Interacción a negocio"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "12"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa principal a empresa secundaria"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "13"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa secundaria a empresa principal"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "14"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Contacto a ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "15"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket a contacto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "16"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket a interacción"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "17"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Interacción a ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "18"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Negocio a elemento de pedido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "19"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Elemento de pedido a negocio"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "20"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa a ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "25"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket a empresa"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "26"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Negocio a ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "27"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket a negocio"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}