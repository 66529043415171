"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611098;
const slug = exports.slug = 'guides/cms/content/data-driven-content/dynamic-pages/hubdb';
const title = exports.title = 'Dynamische Seiten mithilfe von HubDB erstellen';
const name = exports.name = 'Dynamische Seiten mithilfe von HubDB erstellen';
const metaDescription = exports.metaDescription = 'Erfahren Sie, wie Sie mithilfe von HubDB-Daten dynamische Website-Seiten erstellen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-eine-hubdb-tabelle-erstellen",
  "label": "1. Eine HubDB-Tabelle erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-vorlage-erstellen",
  "label": "2. Vorlage erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-die-dynamische-seiten-erstellen",
  "label": "3. Die dynamische Seiten erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-live-seiten-anzeigen",
  "label": "4. Live-Seiten anzeigen",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-eine-neue-tabellenzeile-hinzuf%C3%BCgen",
  "label": "5. Eine neue Tabellenzeile hinzufügen",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-daten-von-dynamischen-seiten-anzeigen",
  "label": "6. Daten von dynamischen Seiten anzeigen",
  "parent": null
}, {
  "depth": 0,
  "id": "weitere-hubdb-fokussierte-tutorials",
  "label": "Weitere HubDB-fokussierte Tutorials",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      code: "code",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Dynamische Seiten mithilfe von HubDB erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "Dynamische Seiten"
      }), " sind CMS-Seiten, die ihre Inhalte von einer strukturierten Datenquelle abrufen. Je nachdem, wie Sie Ihre dynamische Seite konfigurieren, übernimmt HubSpot Daten von der ausgewählten Quelle und erstellt automatisch eine Reihe von Seiten. Dies umfasst eine Listing-Seite, die Zusammenfassungen der Daten anzeigt, und einzelne Seiten für jeden Datenquelleneintrag."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mithilfe einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB-Tabelle"
      }), " als Datenquelle können Sie eine dynamische Seite erstellen, die dann eine Seite für jede Zeile in der Tabelle generiert. Jede dynamische Seite hat ihre eigene, SEO-freundliche URL und bietet seitenspezifische Analytics."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Sie können bis zu 10 dynamische Seiten pro Datenquelle erstellen. Für dynamische HubDB-Seiten bedeutet dies, dass bis zu 10 dynamische Seiten dieselbe HubDB-Tabelle verwenden können. Die Anzahl der Zeilen in der Tabelle (und damit der untergeordneten Seiten) wird bei diesem Limit nicht berücksichtigt. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/storage/hubdb/overview#hubdb-technical-limits",
            children: "technische Beschränkungen in HubDB"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Dynamische HubDB-Seiten sind auf 50.000 Indexupdates der ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/content-search",
            children: "Inhaltssuche"
          }), " pro Account und Tag beschränkt. Alle Updates dieser Seiten, die dieses Limit überschreiten, werden bei der Inhaltssuche an diesem Tag nicht berücksichtigt."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In diesem Tutorial erfahren Sie, wie Sie einen Satz dynamischer Seiten mithilfe von HubDB als Datenquelle erstellen. Für dieses Tutorial benötigen Sie:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Content Hub"
          }), " Professional"]
        }), " oder ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Einige Vorkenntnisse in HTML und CSS"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Weitere Informationen zum Erstellen datenbasierter CMS-Seiten finden Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "Kurs zu CMS-datengestütztem Content"
        }), " der HubSpot Academy."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Eine HubDB-Tabelle erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So erstellen Sie eine neue HubDB-Tabelle:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie dazu in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Content"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tabelle erstellen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie im Dialogfeld das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Label"
        }), " und den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " der Tabelle ein und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Erstellen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ist die Tabelle erstellt, können Sie festlegen, dass sie für dynamische Seiteninhalte verwendet werden soll:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aktionen"
        }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Einstellungen verwalten"
        }), " aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im rechten Bereich auf den Schalter ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Erstellen dynamischer Seiten mithilfe von Zeilendaten aktivieren"
        }), ", um ihn zu aktivieren."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie können optional die Metabeschreibung, das Feature-Bild und die kanonische URL der einzelnen dynamischen Seiten auswählen. Wenn Sie diese Werte leer lassen, übernimmt jede Seite die entsprechenden Werte von ihrer übergeordneten Seite."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Damit eine Seite die Werte aus den Spalten „Metabeschreibung“, „Feature-Bild“ und „Kanonische URL“ verwenden kann, muss die Seite die folgenden ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "page_meta"
        }), "-HubL-Variablen anstelle von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), "-Variablen enthalten:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.meta_description}}"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.featured_image_URL}}"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.canonical_url}}"
          })
        }), "\n"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Beispielsweise beziehen HubSpot-Vorlagen ihre Metabeschreibung standardmäßig vom ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{content.meta_description}}"
        }), "-Tag. Sie müssen stattdessen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{page_meta.meta_description}}"
        }), " verwenden."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie anschließend auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Speichern"
        }), ", um die Änderungen zu übernehmen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-table-settings-sidebar-save.png",
        alt: "hubdb-table-settings-sidebar-save"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie die Tabelleneinstellungen aktualisiert haben, werden die Spalten ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Seitentitel"
      }), " und ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Seitenpfad"
      }), " zur Tabelle hinzugefügt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Seitentitel:"
        }), " der Name dieser Seite, wie im HTML-Titel-Tag zu sehen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Seitenpfad:"
        }), " das letzte Segment der URL für die dynamische Seite, die von jeder Zeile in der Tabelle erstellt wird."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die folgende Tabelle ist ein Beispiel, das einer „Über uns“-Seite für Mitglieder des Vorstandsteams eines Unternehmens nachempfunden ist. Diese Tabelle wird verwendet, um dynamische Seiten mit Pfaden zu erstellen, die in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cfo-harlow"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ceo-jeff"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cto-bristow"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pd-hugo"
      }), " enden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Seitentitel"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Seitenpfad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Rolle"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Biografie"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CFO Harlow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cfo-harlow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CFO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Harlow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das ist Harlow, der die Finanzen bis ins letzte Detail kennt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CEO Jeff"
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ceo-jeff"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Jeff ist der CEO, was bedeutet, dass er hier normalerweise die Dinge leitet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTO Bristow"
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "cto-bristow"
            }), "CTO"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bristow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das ist unser CTO, Bristow, ein Tüftler vor dem Herrn."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Chief PD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pd-hugo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CPD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hugo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hugo, unser Chefproduktdesigner, entwirft gerne Produkte."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-hubdb-table.png",
        alt: "example-hubdb-table"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Obwohl Sie Seitenpfade als Kleinbuchstaben eingeben müssen, berücksichtigen die resultierenden URLs keine Groß- und Kleinschreibung. Wenn im obigen Beispiel jemand zu ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/CEO-Jeff"
        }), " geht, wird ihm die gleiche Seite wie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/ceo-jeff"
        }), " anstelle eines 404-Fehlers angezeigt."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie bereit sind, die Daten aus Ihrer Tabelle zu verwenden, um Ihre Seiten zu erstellen, klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Veröffentlichen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Vorlage erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Als Nächstes erstellen Sie eine Vorlage sowohl für die Listing-Seite als auch als individuelle Detailseite für jede Zeile, ähnlich wie auch Blog-Vorlagen für Listing- und Beitragsdetailseiten verwendet werden können. So erstellen Sie die Seitenvorlage:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie dazu in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Content"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design-Manager"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie im linken Seitenmenü zu dem Ordner, in dem Sie die Vorlage erstellen möchten. Um einen neuen Ordner zu erstellen, klicken Sie oben links auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Datei"
        }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Neuer Ordner"
        }), " aus. Klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Datei"
        }), " und wählen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Neue Datei"
        }), " aus."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden Sie im Dialogfeld das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dropdown-Menü"
        }), ", um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HTML + HubL"
        }), " als Dateityp auszuwählen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Weiter"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/create-html-hubl-template.jpg",
        alt: "Erstellen einer neue HTML + HubL-Vorlage"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie im Feld ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dateiname"
        }), " den Namen der Vorlage ein."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Unter ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Dateispeicherort"
        }), " können Sie den Speicherort der Vorlage in Ihrem Design-Manager ändern, indem Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ändern"
        }), "_ klicken._"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Erstellen"
        }), ", um die Vorlage zu erstellen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn eine dynamische Seite für die Verwendung dieser Vorlage festgelegt ist und das Ende der Seiten-URL mit der Pfadspalte übereinstimmt, können Sie auf die Variablen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_table_id"
      }), " in der Vorlage zugreifen. Zum Erstellen einer Profilseite für eine Führungskraft zeigt beispielsweise der folgende Code, wie Sie Felder von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), " verwenden können, um die Informationen einer Führungskraft anzuzeigen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_name"
        }), ": der zugehörige ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Seitentitel"
        }), " für die HubDB-Zeile."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": der Name der Führungskraft."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "role"
        }), ": die Rolle der Führungskraft."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n    <h1>{{ dynamic_page_hubdb_row.hs_name }}</h1>\n    <h2>{{ dynamic_page_hubdb_row.name }}</h2>\n    <h3>{{ dynamic_page_hubdb_row.role }}</h3>\n    <p>{{dynamic_page_hubdb_row.bio}}</p>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Als Nächstes können Sie Handling für den Fall hinzufügen, dass jemand Ihre dynamische Seite lädt (ohne zusätzliche Pfade aus Ihrer Tabelle). Normalerweise wird dies als Listing-Seite verwendet, um Links zu den Seiten für die Zeilen in Ihrer HubDB-Tabelle zu listen. Ersetzen Sie Ihren Code durch:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n    <h1>{{ dynamic_page_hubdb_row.hs_name }}</h1>\n    <h2>{{ dynamic_page_hubdb_row.name }}</h2>\n    <h3>{{ dynamic_page_hubdb_row.role }}</h3>\n    <p>{{dynamic_page_hubdb_row.bio}}</p>\n{% elif dynamic_page_hubdb_table_id %}\n    <ul>\n    {% for row in hubdb_table_rows(dynamic_page_hubdb_table_id) %}\n        <li><a href=\"{{ request.path }}/{{ row.hs_path }}\">{{ row.hs_name }}</a></li>\n    {% endfor %}\n    </ul>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Code innerhalb des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "-Blocks durchläuft alle Zeilen in der Tabelle der Führungskraft und zeigt jeden Eintrag in einer Liste mit einem Link zu ihrem eindeutigen Pfad an."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im Design-Manager auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vorschau"
        }), ", um eine Vorschau der Vorlage anzuzeigen. Die Vorschau ist leer, da sie sich auf den Kontext der Seite stützt, um die Variablen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_row"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_table_id"
        }), " festzulegen."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um Ihren Code auf der Vorlagenebene zu testen, fügen Sie den folgenden temporären Code oben in Ihrer Vorlage hinzu und stellen Sie sicher, dass Sie ihn vor der Veröffentlichung entfernen:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set dynamic_page_hubdb_table_id = <YOUR_HUBDB_TABLE_ID> %}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Nachdem Sie den oben genannten Code hinzugefügt haben, sollte Ihre Vorlage nun eine Liste von Hyperlinks rendern und Daten aus der HubDB-Tabelle holen, die Sie erstellt haben."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-template-preview.png",
        alt: "hubdb-template-preview"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Entfernen Sie nach dem Anzeigen der Vorschau der Vorlage den obigen temporären Code. Klicken Sie dann oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Veröffentlichen"
        }), ", um ihn für das Erstellen von Seiten verfügbar zu machen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Die dynamische Seiten erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So erstellen Sie von Ihrer Vorlage aus eine dynamische Seite:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wenn Ihre neue Vorlage im Design-Manager geöffnet ist, klicken Sie oben im Finder auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Aktionen"
          }), " und wählen Sie dann ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Seite erstellen"
          }), " aus."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/create-page-from-design-manager.png",
            alt: "create-page-from-design-manager"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wählen Sie im Dialogfeld ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Website-Seite"
          }), " aus und geben Sie einen ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Seitennamen"
          }), " ein."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Seite erstellen"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie oben im Seiten-Editor auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Einstellungen"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Geben Sie im Feld ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Seitentitel"
          }), " einen Seitennamen ein, mit dem Sie später nach Traffic-Analytics suchen können."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Geben Sie im ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), "-Feld eine ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " von ein ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/executives"
          }), " einen. Die URL ist die Basis-URL für Ihre dynamische Seite."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Erweiterte Optionen"
          }), ", um zusätzliche Einstellungen anzuzeigen."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Scrollen Sie nach unten zum Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Dynamische Seiten"
          }), " und klicken Sie dann auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Datenquellen"
          }), ". Wählen Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "HubDB-Tabelle"
          }), " aus, die Sie erstellt haben."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/page%20editor%20hubdb%20dynamic%20.png",
        alt: "Erweiterte Optionen in den Seiteneinstellungen für das Verlinken zur HubDB-Tabelle"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie fertig sind, klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Veröffentlichen"
        }), ". Ihre Seiten sind jetzt bereit zum Anzeigen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Live-Seiten anzeigen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Jetzt können Sie Ihre neue dynamische Seite und alle ihre Pfade besuchen, wie in Ihrer HubDB-Tabelle definiert."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie zur dynamischen Listing-Seite unter der URL, die Sie im Seiten-Editor festgelegt haben. Dieses Tutorial verwendet ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/executives"
        }), " für seine dynamische Seiten-URL. In diesem Fall würden Sie zu folgender Adresse gehen: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.yourdomain.com/executives"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf der Listing-Seite auf die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " in der Aufzählung, um die Detailseite für diese Führungskraft anzuzeigen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/2022-11-28_15-55-47%20(1).gif",
        alt: "2022-11-28_15-55-47 (1)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Eine neue Tabellenzeile hinzufügen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Ihre dynamische Seite HubDB-Daten lädt, gehen Sie zurück zur Tabelle und fügen eine neue Zeile hinzu. Nach der Veröffentlichung der Tabelle wird Ihre Live-Seite dann dynamisch mit den neuen HubDB-Daten aktualisiert."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dateien und Vorlagen"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " der Tabelle, die Sie erstellen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zeile hinzufügen"
        }), " und füllen Sie dann jede Spalte aus. Nachfolgend finden Sie einen Beispieldatensatz."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Seitentitel"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Seitenpfad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Rolle"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Biografie"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CMO Hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cmo-hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hobbes ist unser absoluter Katzenliebhaber."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Veröffentlichen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie auf einer anderen Registerkarte zurück zur Listing-Seite (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/executives"
        }), " in diesem Beispiel). Sie sollten nun sehen, dass die neue Führungskraft auf der Listing-Seite angezeigt wird. Wenn Sie dann auf ihren Namen klicken, wird ihre Detailseite angezeigt."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Daten von dynamischen Seiten anzeigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald Sie Ihre dynamische Seite besuchen, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/analyze-performance-for-individual-pages-and-blog-posts",
        children: "die Performance einzelner Seiten messen "
      }), "oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-your-site-traffic-with-the-traffic-analytics-tool",
        children: "alle Seitendaten im Traffic-Analytics-Tool anzeigen"
      }), ". Auch wenn die Seiten der einzelnen Führungskräfte aus derselben dynamischen Seite erstellt wurden, werden jeder Seite Traffic-Daten wie Seitenaufrufe zugeordnet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So zeigen Sie die Daten für Seitenaufrufe in HubSpot an:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Gehen Sie in Ihrem HubSpot-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Berichterstattung"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing-Analytics"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Gehen Sie in der linken Seitenleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Web Traffic Analytics"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Seiten"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Zeigen Sie die Tabelle an, um die Traffic-Daten für die einzelnen übergeordneten und untergeordneten Seiten zu sehen. Untergeordnete Seiten werden mit ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "> Pfeilsymbolen"
          }), " gekennzeichnet, um ihre Beziehung zu übergeordneten Seiten anzuzeigen."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-traffic-data-2.png",
            alt: "example-traffic-data-2"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beachten Sie Folgendes, wenn Sie nicht die erwarteten Traffic-Daten sehen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/exclude-traffic-from-your-site-analytics",
          children: "Ihre IP-Adressen in den Berichtseinstellungen des Accounts ausgeschlossen haben"
        }), ", stellen Sie sicher, dass Sie von außerhalb Ihres Netzwerks auf Ihre Seiten zugreifen, damit Ihre Seitenaufrufe registriert werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Es kann ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/how-often-do-analytics-in-hubspot-update",
          children: "bis zu 40 Minuten "
        }), " dauern, bis neue Seitendaten in HubSpot angezeigt werden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Weitere HubDB-fokussierte Tutorials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Zusammenführen mehrerer HubDB-Tabellen"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "Erstellen einer dynamischen Seite für Teammitglieder mithilfe von HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "Hinzufügen von Videos zu dynamischen Seiten"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "Erstellen von dynamischen Seiten mit mehreren Ebenen mithilfe von HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Erstellen wir mithilfe von HubDB eine Seite mit einer Zuordnung"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}