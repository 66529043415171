"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 52098077555;
const slug = exports.slug = 'guides/cms/content/themes/child-themes';
const title = exports.title = '子テーマ';
const name = exports.name = 'APAC JAPAN (ja) | [Developers] Child Themes_JA | 202109';
const metaDescription = exports.metaDescription = '子テーマとは、元の親テーマのコピーです。子テーマは、親テーマに変更を加えることなく編集できます。HubSpotテーマ、マーケットプレイステーマ、カスタムテーマなどの子テーマを作成できます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%80%81%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%83%E3%83%88%E3%83%97%E3%83%AC%E3%82%A4%E3%82%B9%E3%83%86%E3%83%BC%E3%83%9E%E3%81%BE%E3%81%9F%E3%81%AFhubspot%E3%81%AE%E6%97%A2%E5%AE%9A%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E5%AD%90%E3%83%86%E3%83%BC%E3%83%9E%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "デザインマネージャーを使用して、マーケットプレイステーマまたはHubSpotの既定テーマの子テーマを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC%E4%B8%8A%E3%81%A7%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%83%E3%83%88%E3%83%97%E3%83%AC%E3%82%A4%E3%82%B9%E4%BB%A5%E5%A4%96%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E5%AD%90%E3%83%86%E3%83%BC%E3%83%9E%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "デザインマネージャー上でマーケットプレイス以外のテーマの子テーマを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "cli%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E5%AD%90%E3%83%86%E3%83%BC%E3%83%9E%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "CLIを使用して子テーマを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%88%B6%E9%99%90",
  "label": "制限",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E3%81%94%E8%B3%AA%E5%95%8F%EF%BC%88faq%EF%BC%89",
  "label": "よくあるご質問（FAQ）",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      a: "a",
      pre: "pre",
      ol: "ol",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "子テーマを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise', 'cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "子テーマ"
      }), "とは、元の", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "親テーマ"
      }), "のコピーです。子テーマは、親テーマに変更を加えることなく編集できます。HubSpotテーマ、マーケットプレイステーマ、カスタムテーマなどの子テーマを作成できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "デザインマネージャーを使用して、マーケットプレイステーマまたはHubSpotの既定テーマの子テーマを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャーでは、マーケットプレイスで購入したテーマまたは既定のHubSpotテーマの子テーマを作成できます。デザインマネージャー内でこれらのアセットから子テーマを作成すると、以下のファイルが子テーマに追加されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "：親テーマにリンクするための適切なextends文が含まれています。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "child.css"
        }), "と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "child.js"
        }), "：空のCSSファイルとJSファイルです。これらのファイルに追加するコードは、子テーマにのみ影響します。これらのファイル名は、子テーマ作成ウィザードの詳細オプションでカスタマイズできます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/cms/hubl/variables#required-page-template-variables",
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "standard_header_includes"
          }), " HubL変数"]
        }), "を含むファイル。通常は「ベース」または「メイン」テンプレートファイルなどです。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/main/src/templates/layouts",
          children: "この例はボイラープレートで確認"
        }), "できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "元のテーマが編集できない場合でも、子テーマは編集できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャー内でマーケットプレイステーマまたはHubSpotの既定テーマの子テーマを作成するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントにて、［マーケティング］>［ファイルとテンプレート］>［デザインツール］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["左側のサイドバーで、（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@marketplace"
        }), "フォルダーにある）マーケットプレイステーマまたは（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot"
        }), "フォルダーにある）HubSpotの既定テーマを", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "右クリック"
        }), "し、［子テーマを作成］を選択します**。**"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["子テーマの名前を入力し、テーマの場所を選択し、［子テーマを作成］をクリックします。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "詳細オプションを開き、子テーマを使用して作成される子CSSファイルと子JSファイルの名前をカスタマイズすることもできます。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "子テーマが作成されると、子テーマの公開準備ができたことを示す成功メッセージが表示されます。［閉じる］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左側のサイドバーに子テーマとその内容が表示されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "デザインマネージャー上でマーケットプレイス以外のテーマの子テーマを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャー上で子テーマを作成できます。子テーマには、親テーマからアセットとファイルが継承されます。元のテーマが編集できない場合でも、子テーマは編集できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャー内で子テーマを作成するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントにて、［マーケティング］>［ファイルとテンプレート］>［デザインツール］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左側のサイドバーでファイルをクリックして［新しいテーマ］を選択します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［テーマの開始点］として［空のテーマ］を選択します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テーマの名前を入力し、［作成］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["新しいテーマの", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "theme.json"
        }), "ファイルが開きます。", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "theme.json"
        }), "ファイルを編集して、行5の下に新しい行を追加します。行5の末尾にコンマを追加してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["行6に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "extends"
        }), "文を追加します。値は、使用する親テーマのパスです。Barricadeテーマから子テーマを作成する場合の例を次に示します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"extends\": \"path/to/theme\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["新しいテーマには空の", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "fields.json"
        }), "ファイルがあります。親テーマの元の", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "fields.json"
        }), "ファイルの内容をコピーするか、またはファイルを必要に応じて更新します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CLIを使用して子テーマを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["子テーマは、CLIを使用して作成できます。子テーマを作成する前に、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/guides/getting-started-with-local-development",
        children: "CLIの設定プロセス"
      }), "を完了しておく必要があります。子テーマには、親テーマからテンプレートとファイルが継承されます。この新しいテーマは、CLIとデザインマネージャーの両方で編集できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CLIでテーマを拡張するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テーマ用の新しいディレクトリーを作成します。例："
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "mkdir cms-project\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "新しいディレクトリーで、親テーマのtheme.jsonファイルのコピーを作成します。例："
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd cms-project\ncp ~/src/cms-sprout-theme/src/theme.json\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["コピーした", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "theme.json"
        }), "ファイルを編集し、行5の下（または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "responsive_breakpoints"
        }), "がある場合はその最終行の下）に新しい行を追加します。行5の末尾にコンマを追加してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["行6（または行14、あるいはテーマの該当する行）に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "extends"
        }), "文を追加します。値は、使用する親テーマのパスです。Barricadeテーマから子テーマを作成する場合の例を次に示します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"extends\": \"@hubspot/barricade\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["新しいテーマには空の", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "fields.json"
        }), "ファイルがあります。親テーマの元の", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "fields.json"
        }), "ファイルの内容をコピーするか、またはファイルを必要に応じて更新します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "新しいテーマとファイルをポータルにアップロードします。例："
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs upload cms-project cms-project\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "制限"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "保持できる子テーマの合計数には、CMS Hubのご契約に基づいて上限があります。この上限は次のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**Marketing/CMS Hub Professional：**5個の子テーマ"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**Marketing/CMS Hub Enterprise：**10個の子テーマ"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "よくあるご質問（FAQ）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "親テーマがマーケットプレイスのテーマの場合、別のポータルに子テーマをコピーできますか？"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "はい。「extends」パスが新しいポータルでも同じなら可能です。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "親テーマからはどのアセットが継承されますか？"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "子テーマ内で上書きされない限り、親テーマの全てのファイルが継承されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "特定のアセットを親テーマとは変える方法は？"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "子テーマの同じ相対パスにあるファイルによって、親テーマからの同等のファイルが上書きされます。例えば", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@marketplace/parent/theme/templates/about.html"
        }), "を上書きするには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/child/theme/templates/about.html"
        }), "ファイルを作成し、この新しいファイルを編集します。新しいファイルが、継承されたファイルの代わりとして有効になります。これはfields.jsonファイル以外に、テーマ内の他のファイルにも適用されることに注意してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "子テーマを使用して新しいページを作成する方法は？"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "新しいページを作成する場合、子テーマはページ作成モーダルの［自分のテーマ］に選択肢として表示されます。テーマを使用してページを作成する方法については、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/website-pages/create-website-content-using-a-theme",
          children: "ナレッジベース"
        }), "を参照してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "親テーマの代わりに子テーマを使用して既存のページを編集する方法は？"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "ページテンプレートを新しいテーマの同等のテンプレートに置き換えることができます。例えば、親テーマのテンプレート", (0, _jsxRuntime.jsx)(_components.code, {
          children: "landing-page.html"
        }), "を新しいテーマのテンプレート", (0, _jsxRuntime.jsx)(_components.code, {
          children: "landing-page.html"
        }), "に", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/cos-general/how-do-i-edit-my-blog-email-page-template#use-a-different-template",
          children: "置き換える"
        }), "ことができます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ページ作成インターフェイスでテンプレートのラベルを編集する方法は？"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "テンプレートのラベルは、HTMLファイルを編集することで変更できます。ラベルはテーマファイル冒頭のコメント内にあります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "子テーマからさらに子テーマを作成できますか？"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "現在、子テーマから子テーマを作成することはできません。"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}