"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129093046;
const slug = exports.slug = 'guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions';
const title = exports.title = 'Primeros pasos con funciones sin servidor';
const name = exports.name = 'LATAM (ES) Getting started with serverless functions';
const metaDescription = exports.metaDescription = 'Crea tu primera función sin servidor en el CMS de HubSpot y obtén una respuesta de ella.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1-1.-crear-una-nueva-carpeta-de-proyecto",
  "label": "1 1. Crear una nueva carpeta de proyecto",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-crear-una-carpeta-de-funciones",
  "label": "2. Crear una carpeta de funciones",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-crear-un-archivo-de-configuraci%C3%B3n",
  "label": "3. Crear un archivo de configuración",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-crear-un-archivo-de-funci%C3%B3n",
  "label": "4. Crear un archivo de función",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-registrar-tu-funci%C3%B3n-en-tu-serverless.json",
  "label": "5. Registrar tu función en tu serverless.json",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-probar-tu-funci%C3%B3n",
  "label": "6. Probar tu función",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-crear-una-plantilla-b%C3%A1sica-llamando-a-tu-funci%C3%B3n",
  "label": "7. Crear una plantilla básica llamando a tu función",
  "parent": null
}, {
  "depth": 0,
  "id": "8.-crear-una-p%C3%A1gina-usando-la-plantilla-test-function.html",
  "label": "8. Crear una página usando la plantilla test-function.html",
  "parent": null
}, {
  "depth": 0,
  "id": "depuraci%C3%B3n-de-la-funci%C3%B3n-sin-servidor",
  "label": "Depuración de la función sin servidor",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFqu%C3%A9-deber%C3%ADas-hacer%3F",
  "label": "¿Qué deberías hacer?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFqu%C3%A9-sigue%3F",
  "label": "¿Qué sigue?",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      br: "br",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Primeros pasos con funciones sin servidor"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si estás creando una función sin servidor como parte de un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "proyecto de desarrollador"
        }), ", visita en su lugar la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "documentación de la función sin servidor del proyecto de desarrollador"
        }), ". La siguiente documentación es para crear funciones sin servidor fuera de la plataforma del proyecto del desarrollador."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las funciones sin servidor ofrecen una manera de escribir código del lado del servidor que se relaciona con HubSpot y servicios de terceros a través de las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "API"
      }), ". Las API que requieren autentificación no son seguras para el front-end de un sitio web, ya que tus credenciales quedarían expuestas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las funciones sin servidor pueden actuar como intermediario entre los servicios front-end y back-end que requieren autenticación. Con funciones sin servidor, los desarrolladores no necesitan crear y gestionar nuevos servidores. Las funciones sin servidor requieren menos gastos y son más fáciles de escalar a medida que un negocio crece. Tenemos un resumen de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "alto nivel de lo que son las funciones sin servidor de HubSpot y cómo funcionan"
      }), ", recomendamos leerlo antes de hacer este tutorial."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Este tutorial te guiará por la creación de tu primera función sin servidor."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Crearás una carpeta de funciones sin servidor, configurarás su carpeta de configuración, crearás una función y obtendrás una respuesta de ella."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Lo que debes hacer antes de tomar este tutorial:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ten acceso a una cuenta de CMS Hub Enterprise o una cuenta de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/standalone-cms-developer",
          children: "CMS Developer Sandbox"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Asegúrate de tener al menos una comprensión básica del ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "concepto de lo que es una función sin servidor"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Familiarízate con las herramientas de desarrollo local de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot"
        }), ", ya que son necesarias para trabajar con funciones sin servidor."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Asegúrate de tener la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cms-cli#install-or-upgrade",
          children: "última versión de las herramientas de desarrollo local de HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ya deberías tener las herramientas de desarrollo local ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#auth",
          children: "autenticadas con tu cuenta de HubSpot"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1 1. Crear una nueva carpeta de proyecto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abre tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " y asegúrate de que tu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cms-cli#authentication",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "defaultPortal"
        })
      }), " esté configurado en tu cuenta de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/standalone-cms-developer",
        children: "CMS Developer Sandbox"
      }), " o en una cuenta con CMS Hub Enterprise."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el equipo, en la carpeta que contiene el archivo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cms-cli#authentication",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "crea una carpeta"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "."
      }), " Esta carpeta contendrá todos nuestros archivos, tanto las propias funciones como una plantilla que utilizará la función."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En tu terminal ejecuta el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "comando watch"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch serverless-tutorial serverless-tutorial\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esto hará que cualquier edición en esta carpeta resulte en cargas al administrador de diseño. Dado que la carpeta actualmente no tiene contenido, este comando simplemente indicará que Watcher está listo y viendo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Crear una carpeta de funciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dentro de la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "crea una carpeta"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "."
      }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Esto es similar a las carpetas de módulos personalizados que terminan en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".module"
      }), ". ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " sirve para comunicar que la carpeta contiene funciones sin servidor. Los archivos almacenados en esta carpeta no son de acceso público."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Debido a que esta carpeta está vacía, el comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cms-cli#watch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        })
      }), " que tiene en ejecución aún no creará esta carpeta en el Administrador de diseño."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Crear un archivo de configuración"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Crea un nuevo archivo en tu carpeta"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: ". Nombrarlo"
      }), " ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "."]
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " es un archivo requerido contenido dentro de una carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), ". Sirve como un archivo de configuración para funciones sin servidor. Definición del entorno de tiempo de ejecución, la versión de la función sin servidor y los puntos finales disponibles. Para un resumen de todo lo que se define en este archivo, consulta nuestra ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions",
        children: "referencia sin servidor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si creaste y guardaste el archivo vacío, recibirás un mensaje de error en tu terminal indicando que no puedes cargar un archivo vacío ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), ". Está bien ignorarlo porque vas a agregar ese código y luego guardarlo, activando una nueva carga que tendrá éxito."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pega el siguiente código en tu serverless.json:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// place this in your serverless.json file, without this comment\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"endpoints\": {}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Guarda el archivo."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mantén este archivo abierto en tu editor de código. Volveremos a él."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Debido a que ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cms-cli#watch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        })
      }), " se está ejecutando, si actualizas tu administrador de diseño, verás tu ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), " y las carpetas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), " y tu nuevo archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Crear un archivo de función"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Crea un nuevo archivo en tu carpeta"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "my-first-function.functions**"
      }), ", nómbralo** ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "congratulation.js"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este es el archivo de función real, el archivo que ejecutará y realizará una tarea."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pega el siguiente código:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n  // your code called when the function is executed\n  const functionResponse =\n    \"Congrats! You've just deployed a Serverless Function.\";\n\n  // sendResponse is a callback function you call to send your response.\n  sendResponse({ body: functionResponse, statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta función sin servidor cuando se ejecuta devuelve una cadena ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Congrats! You just deployed a Serverless Function.\""
      }), " y un código de estado de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), ", lo que indica éxito."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "En un escenario del mundo real, es probable que puedas usar API o realizar algunos cálculos que no deseas que sean públicos. Devolverías el resultado de esos cálculos o, un código de estado simple basado en el éxito de tus transacciones API."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aún no has terminado, no hay manera de ejecutar esta función todavía."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Registrar tu función en tu serverless.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abre de nuevo tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), ". En tu archivo, encuentra tu objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "«endpoints»"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Actualiza el objeto para que luzca de la siguiente manera:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// update the endpoints object in your serverless.json to reflect this object.\n\"endpoints\": {\n   \"congratulation\": {\n     \"method\": \"GET\",\n     \"file\": \"congratulation.js\"\n   }\n }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El objeto endpoints contiene un objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"congratulation\""
      }), ". ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"congratulation\""
      }), " es el punto de terminación que estás creando. El nombre del punto de terminación es lo que define la ruta que usará para llamar a tu función sin servidor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las funciones sin servidor se exponen a través de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "una ruta en el dominio de tu cuenta de HubSpot CMS."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se puede acceder a estas funciones en:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<https://www.example.com>/_hcms/api/<endpoint-name/path>"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el caso de este punto de terminación de \"congratulation\" que has creado, será"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<https://www.example.com>/_hcms/api/congratulation"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Debido a esto, generalmente es una buena idea nombrar tu punto de terminación de manera similar al nombre de tu archivo de función, y ambos deben nombrarse según la información contra la que actúan, no la acción tomada contra esa información. Debes usar el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods",
        children: "método o los métodos HTTP"
      }), " para comunicar el tipo de acción que estás realizando contra esa información. El parámetro \"method\" define el método HTTP que admite tu función. Puede ser una sola cadena o una matriz de cadenas que denotan los métodos que la función admite."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Probar tu función"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La forma más sencilla de probar una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a tu función sin servidor es ir a la URL de tu punto de terminación directamente en el navegador.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://your-domain.com/_hcms/api/congratulation",
        children: "https://your-domain.com/_hcms/api/congratulation"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Reemplazar your-domain.com con el dominio de tu sitio de HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Deberías ver ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Congrats! You just deployed a serverless function\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "¡Bien, lo lograste!"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.em, {
        children: "Si no obtienes esa respuesta, comienza desde la parte superior de este tutorial leyendo cuidadosamente cada paso y verificando el código. Es probable que las instrucciones en los pasos 4 o 5 no se hayan seguido correctamente."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para solicitudes más complicadas, es útil usar una herramienta como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.postman.com/downloads/",
          children: "Postman."
        }), " Postman hace que sea más fácil probar y depurar las API. Una característica útil para los desarrolladores front-end es su ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://learning.postman.com/docs/postman/sending-api-requests/generate-code-snippets/",
          children: "generación de código"
        }), " que puede generar un punto de partida para tu llamada javascript a tu función."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Crear una plantilla básica llamando a tu función"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando una nueva ventana de terminal navega en tu carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), " usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cd"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ejecuta el siguiente comando en tu terminal:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template \"test-function\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esto crea un archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test-function.html"
      }), ". ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Abre este archivo en tu editor de códigos."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Arriba de la etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</head>"
      }), " ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["agrega ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<script></script>"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copia el javascript a continuación:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var requestOptions = {\n  method: 'GET',\n  headers: {\n    'Content-Type': 'application/json',\n  },\n};\n\nfetch('https://www.example.com/_hcms/api/congratulation', requestOptions)\n  .then((response) => response.text())\n  .then((result) => console.log(result))\n  .catch((error) => console.log('error', error));\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Las funciones sin servidor de HubSpot solo admiten el tipo de contenido ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), ". Dejarlo fuera ocasionará un error de \"Tipo de medio no compatible\"."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dentro de la etiqueta del script que has creado anteriormente, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "pega tu código JavaScript copiado"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Cambia ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "www.example.com"
        }), " al dominio de tu cuenta."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Guarda el archivo."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "8. Crear una página usando la plantilla test-function.html"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el administrador de diseño, busca tu archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test-function.html"
      }), " (es posible que debas actualizar)"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Haz clic con el botón derecho en el archivo, elige \"crear página\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nombra tu página \"Función de prueba\". ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Crea una página"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Haz clic en Vista preliminar, vista preliminar en una nueva ventana"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Inspecciona la página haciendo ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "clic con el botón derecho en cualquier lugar"
      }), " de la página y ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "seleccionando \"inspeccionar\"."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si has hecho todo correctamente deberías ver en tu consola el mensaje de felicitaciones."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Felicitaciones, has llamado a tu función sin servidor desde una página de CMS de HubSpot."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Si bien este tutorial te permite llamar a la función sin servidor en el nivel de plantilla, puedes llamar a las funciones sin servidor en cualquier lugar donde puedas agregar JavaScript en tu sitio alojado en CMS. El lugar más común donde puedes hacer esto es dentro de tus módulos personalizados."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Depuración de la función sin servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En esta etapa, tu función sin servidor debería funcionar bien. Cuanto más complicada se vuelva tu función, más difícil será solucionar el problema. Al igual que la consola en tu navegador es útil para depurar javascript en tu front-end, puedes obtener registros similares para tus funciones sin servidor usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cms-cli#logs",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs logs"
        })
      }), ". Echa un vistazo a la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: "referencia de desarrollo local"
      }), " para obtener más información sobre este comando."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs logs <endpoint-name> --follow\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Qué deberías hacer?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Haz creado una carpeta de función sin servidor, con un archivo de configuración sin servidor.json y un archivo de función llamado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "congratulation.js"
      }), ". Usaste \"GET\" para obtener el texto de felicitaciones de la función sin servidor. Usaste javascript para hacer una llamada a tu función sin servidor desde una página en el CMS de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora que comprendes cómo se relacionan el archivo de configuración, el archivo de función y la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), ", la CLI tiene un comando práctico que puedes usar para crear tus funciones más rápido la próxima vez."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create function <function name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta función crea una carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), ", un archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " y un archivo de función con los nombres que proporciones."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Qué sigue?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "Descripción general de alto nivel de las funciones sin servidor"
        }), " en el CMS de HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/serverless-functions",
          children: "Referencia sin servidor"
        }), " que desglosa las partes de una función sin servidor"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/courses/introduction-to-hubspot-apis",
          children: "Introducción al curso académico de las API de HubSpot."
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/github-integration",
          children: "Integración continua con GitHub"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}