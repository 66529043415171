"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611101;
const slug = exports.slug = 'guides/cms/setup/github-integration';
const title = exports.title = 'Set up continuous integration with a GitHub repository using GitHub Actions';
const name = exports.name = 'Set up continuous integration with a GitHub repository using GitHub Actions';
const metaDescription = exports.metaDescription = 'Set up continuous integration with a GitHub repository using GitHub Actions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "send-local-files-to-github",
  "label": "Send local files to GitHub",
  "parent": null
}, {
  "depth": 0,
  "id": "use-the-hubspot-cms-deploy-github-action-recommended",
  "label": "Use the HubSpot CMS Deploy GitHub Action (recommended)",
  "parent": null
}, {
  "depth": 0,
  "id": "create-and-merge-a-pull-request-in-main",
  "label": "Create and merge a pull request in main",
  "parent": null
}, {
  "depth": 0,
  "id": "lock-your-asset-in-the-design-manager",
  "label": "Lock your asset in the design manager",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Set up continuous integration with a GitHub repository using GitHub Actions"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As a part of your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "development workflow"
      }), ", you might prefer to keep your production codebase source of truth in version control. This would be especially helpful if you work as a part of a development team so that you can track changes and quickly roll them back if needed."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/features/actions",
        children: "GitHub Actions"
      }), ", you can set up a continuous integration with a GitHub repository. This guide walks through the integration process, and assumes that you're familiar with:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.github.com/en/get-started/using-git",
          children: "Using Git"
        }), " and GitHub"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Building websites using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot CLI"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn how to set up the integration using the HubSpot CMS Deploy GitHub Action (recommended) or manually."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Send local files to GitHub"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before you can integrate with GitHub, you'll first need to gather your files locally."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you have an existing CMS asset that lives in HubSpot, such as a theme or set of templates, you can fetch it by running the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#fetch",
          children: "fetch"
        }), " command as follows: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch <HubSpot_src> <local_dest>"
        }), ". Alternatively, you can download all files in the account's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview#developer-file-system",
          children: "developer file system"
        }), " by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch /"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To create a new local project, it's recommended to start with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "CMS theme boilerplate"
        }), ". If you haven't worked with the CMS theme boilerplate before, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "quickstart guide"
        }), ". If you've already installed the HubSpot CLI and configured your local environment, you can create a new local theme from the boilerplate by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs create website-theme <new-theme-name>"
        }), ". You'll then need to upload your files to HubSpot with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#upload",
          children: "hs upload"
        }), " command."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With your code available locally, you'll then ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.github.com/en/get-started/importing-your-projects-to-github/importing-source-code-to-github/adding-locally-hosted-code-to-github",
        children: "add it to a GitHub repository"
      }), ". After adding your files to GitHub, proceed to the next step to either install HubSpot's pre-made GitHub Action (recommended) or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manually-configure-the-action",
        children: "configure the Action manually"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Use the HubSpot CMS Deploy GitHub Action (recommended)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To streamline the process, HubSpot created a GitHub Action that you can install to your GitHub project to handle automatically deploying changes from a branch to your production HubSpot account."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "e0132707-395d-4617-bd9d-0b21c8b129d5",
      external: true,
      label: "Install automatic CMS deploy GitHub action"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create and merge a pull request in main"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "With your secrets, workflows, and scripts in your GitHub repository, create a pull request and merge it into main."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After merging the pull request, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), ". You should see your deploy Action run, which will then deploy your code to your HubSpot account."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lock your asset in the design manager"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Now that your source of truth lives in GitHub, you should lock your asset in HubSpot to prevent edits from being made there. This ensures that changes only come through the deploy action."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To lock assets in the design manager:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Files and Templates"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Design Tools"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Locate your asset's folder in the left sidebar, then ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "right-click"
        }), " and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lock folder"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-lock-folder.png",
        alt: "design-manager-lock-folder"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}