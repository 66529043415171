/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/prefer-hubspot-url-utils */

/* eslint-disable hubspot-dev/no-private-classes */
import enviro from 'enviro';
const serverUrl = enviro.isProd() ? 'https://api.hubapi.com/' : 'https://api.hubapiqa.com/';
export const mockPropertiesOasSpec = {
  openapi: '3.0.1',
  info: {
    title: 'Properties',
    description: 'All HubSpot objects store data in default and custom properties. These endpoints provide access to read and modify object properties in HubSpot.',
    version: 'v3'
  },
  servers: [{
    url: serverUrl
  }],
  tags: [{
    name: 'Core'
  }, {
    name: 'Batch'
  }, {
    name: 'Groups'
  }],
  paths: {
    '/crm/v3/properties/{objectType}': {
      get: {
        tags: ['Core'],
        summary: 'Read all properties',
        description: 'Read all existing properties for the specified object type and HubSpot account.',
        operationId: 'get-/crm/v3/properties/{objectType}_getAll',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'archived',
          in: 'query',
          description: 'Whether to return only results that have been archived.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'boolean',
            default: false
          }
        }],
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/CollectionResponseProperty'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2: ['crm.objects.contacts.read']
        }, {
          private_apps: ['crm.objects.deals.read']
        }, {
          private_apps_legacy: ['timeline']
        }, {
          oauth2: ['crm.objects.deals.read']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.read']
        }, {
          private_apps: ['crm.objects.contacts.read']
        }, {
          oauth2_legacy: ['crm.schemas.custom.read']
        }, {
          private_apps: ['crm.objects.companies.read']
        }, {
          private_apps: ['crm.objects.deals.write']
        }, {
          oauth2: ['crm.schemas.quotes.read']
        }, {
          private_apps: ['crm.schemas.quotes.read']
        }, {
          oauth2: ['crm.objects.companies.read']
        }, {
          oauth2: ['crm.objects.deals.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.read']
        }, {
          private_apps_legacy: ['crm.schemas.custom.read']
        }, {
          private_apps_legacy: ['crm.objects.goals.read']
        }, {
          private_apps: ['crm.schemas.deals.read']
        }, {
          oauth2: ['crm.schemas.deals.read']
        }, {
          oauth2_legacy: ['timeline']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.schemas.contacts.read']
        }, {
          private_apps: ['crm.schemas.contacts.read']
        }, {
          oauth2_legacy: ['tickets']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.read']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.objects.contacts.write']
        }, {
          private_apps: ['crm.objects.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.read']
        }, {
          oauth2: ['crm.schemas.companies.read']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          oauth2_legacy: ['crm.objects.goals.read']
        }, {
          private_apps: ['crm.schemas.line_items.read']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.read']
        }, {
          oauth2: ['crm.schemas.line_items.read']
        }]
      },
      post: {
        tags: ['Core'],
        summary: 'Create a property',
        description: 'Create and return a copy of a new property for the specified object type.',
        operationId: 'post-/crm/v3/properties/{objectType}_create',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/PropertyCreate'
              }
            }
          },
          required: false
        },
        responses: {
          '201': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/Property'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2: ['crm.schemas.deals.write']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.schemas.contacts.write']
        }, {
          private_apps_legacy: ['crm.properties.write']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.write']
        }, {
          oauth2: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.write']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.schemas.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.properties.write']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.schemas.custom.write']
        }, {
          private_apps: ['crm.schemas.deals.write']
        }]
      }
    },
    '/crm/v3/properties/{objectType}/batch/archive': {
      post: {
        tags: ['Batch'],
        summary: 'Archive a batch of properties',
        description: 'Archive a provided list of properties. This method will return a 204 No Content response on success regardless of the initial state of the property (e.g. active, already archived, non-existent).',
        operationId: 'post-/crm/v3/properties/{objectType}/batch/archive_archive',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/BatchInputPropertyName'
              }
            }
          },
          required: false
        },
        responses: {
          '204': {
            description: 'No content',
            content: {}
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2: ['crm.schemas.deals.write']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.schemas.contacts.write']
        }, {
          private_apps_legacy: ['crm.properties.write']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.write']
        }, {
          oauth2: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.write']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.schemas.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.properties.write']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.schemas.custom.write']
        }, {
          private_apps: ['crm.schemas.deals.write']
        }]
      }
    },
    '/crm/v3/properties/{objectType}/batch/create': {
      post: {
        tags: ['Batch'],
        summary: 'Create a batch of properties',
        description: 'Create a batch of properties using the same rules as when creating an individual property.',
        operationId: 'post-/crm/v3/properties/{objectType}/batch/create_create',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/BatchInputPropertyCreate'
              }
            }
          },
          required: false
        },
        responses: {
          '201': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/BatchResponseProperty'
                }
              }
            }
          },
          '207': {
            description: 'multiple statuses',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/BatchResponseProperty'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2: ['crm.schemas.deals.write']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.schemas.contacts.write']
        }, {
          private_apps_legacy: ['crm.properties.write']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.write']
        }, {
          oauth2: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.write']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.schemas.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.properties.write']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.schemas.custom.write']
        }, {
          private_apps: ['crm.schemas.deals.write']
        }]
      }
    },
    '/crm/v3/properties/{objectType}/batch/read': {
      post: {
        tags: ['Batch'],
        summary: 'Read a batch of properties',
        description: 'Read a provided list of properties.',
        operationId: 'post-/crm/v3/properties/{objectType}/batch/read_read',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/BatchReadInputPropertyName'
              }
            }
          },
          required: false
        },
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/BatchResponseProperty'
                }
              }
            }
          },
          '207': {
            description: 'multiple statuses',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/BatchResponseProperty'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2: ['crm.objects.contacts.read']
        }, {
          private_apps: ['crm.objects.deals.read']
        }, {
          private_apps_legacy: ['timeline']
        }, {
          oauth2: ['crm.objects.deals.read']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.read']
        }, {
          private_apps: ['crm.objects.contacts.read']
        }, {
          oauth2_legacy: ['crm.schemas.custom.read']
        }, {
          private_apps: ['crm.objects.companies.read']
        }, {
          private_apps: ['crm.objects.deals.write']
        }, {
          oauth2: ['crm.schemas.quotes.read']
        }, {
          private_apps: ['crm.schemas.quotes.read']
        }, {
          oauth2: ['crm.objects.companies.read']
        }, {
          oauth2: ['crm.objects.deals.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.read']
        }, {
          private_apps_legacy: ['crm.schemas.custom.read']
        }, {
          private_apps_legacy: ['crm.objects.goals.read']
        }, {
          private_apps: ['crm.schemas.deals.read']
        }, {
          oauth2: ['crm.schemas.deals.read']
        }, {
          oauth2_legacy: ['timeline']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.schemas.contacts.read']
        }, {
          private_apps: ['crm.schemas.contacts.read']
        }, {
          oauth2_legacy: ['tickets']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.read']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.objects.contacts.write']
        }, {
          private_apps: ['crm.objects.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.read']
        }, {
          oauth2: ['crm.schemas.companies.read']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          oauth2_legacy: ['crm.objects.goals.read']
        }, {
          private_apps: ['crm.schemas.line_items.read']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.read']
        }, {
          oauth2: ['crm.schemas.line_items.read']
        }]
      }
    },
    '/crm/v3/properties/{objectType}/groups': {
      get: {
        tags: ['Groups'],
        summary: 'Read all property groups',
        description: 'Read all existing property groups for the specified object type and HubSpot account.',
        operationId: 'get-/crm/v3/properties/{objectType}/groups_getAll',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }],
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/CollectionResponsePropertyGroup'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2: ['crm.objects.contacts.read']
        }, {
          private_apps: ['crm.objects.deals.read']
        }, {
          private_apps_legacy: ['timeline']
        }, {
          oauth2: ['crm.objects.deals.read']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.read']
        }, {
          private_apps: ['crm.objects.contacts.read']
        }, {
          oauth2_legacy: ['crm.schemas.custom.read']
        }, {
          private_apps: ['crm.objects.companies.read']
        }, {
          private_apps: ['crm.objects.deals.write']
        }, {
          oauth2: ['crm.schemas.quotes.read']
        }, {
          private_apps: ['crm.schemas.quotes.read']
        }, {
          oauth2: ['crm.objects.companies.read']
        }, {
          oauth2: ['crm.objects.deals.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.read']
        }, {
          private_apps_legacy: ['crm.schemas.custom.read']
        }, {
          private_apps_legacy: ['crm.objects.goals.read']
        }, {
          private_apps: ['crm.schemas.deals.read']
        }, {
          oauth2: ['crm.schemas.deals.read']
        }, {
          oauth2_legacy: ['timeline']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.schemas.contacts.read']
        }, {
          private_apps: ['crm.schemas.contacts.read']
        }, {
          oauth2_legacy: ['tickets']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.read']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.objects.contacts.write']
        }, {
          private_apps: ['crm.objects.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.read']
        }, {
          oauth2: ['crm.schemas.companies.read']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          oauth2_legacy: ['crm.objects.goals.read']
        }, {
          private_apps: ['crm.schemas.line_items.read']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.read']
        }, {
          oauth2: ['crm.schemas.line_items.read']
        }]
      },
      post: {
        tags: ['Groups'],
        summary: 'Create a property group',
        description: 'Create and return a copy of a new property group.',
        operationId: 'post-/crm/v3/properties/{objectType}/groups_create',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/PropertyGroupCreate'
              }
            }
          },
          required: false
        },
        responses: {
          '201': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/PropertyGroup'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2: ['crm.schemas.deals.write']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.schemas.contacts.write']
        }, {
          private_apps_legacy: ['crm.properties.write']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.write']
        }, {
          oauth2: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.write']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.schemas.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.properties.write']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.schemas.custom.write']
        }, {
          private_apps: ['crm.schemas.deals.write']
        }]
      }
    },
    '/crm/v3/properties/{objectType}/groups/{groupName}': {
      get: {
        tags: ['Groups'],
        summary: 'Read a property group',
        description: 'Read a property group identified by {groupName}.',
        operationId: 'get-/crm/v3/properties/{objectType}/groups/{groupName}_getByName',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'groupName',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }],
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/PropertyGroup'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2: ['crm.objects.contacts.read']
        }, {
          private_apps: ['crm.objects.deals.read']
        }, {
          private_apps_legacy: ['timeline']
        }, {
          oauth2: ['crm.objects.deals.read']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.read']
        }, {
          private_apps: ['crm.objects.contacts.read']
        }, {
          oauth2_legacy: ['crm.schemas.custom.read']
        }, {
          private_apps: ['crm.objects.companies.read']
        }, {
          private_apps: ['crm.objects.deals.write']
        }, {
          oauth2: ['crm.schemas.quotes.read']
        }, {
          private_apps: ['crm.schemas.quotes.read']
        }, {
          oauth2: ['crm.objects.companies.read']
        }, {
          oauth2: ['crm.objects.deals.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.read']
        }, {
          private_apps_legacy: ['crm.schemas.custom.read']
        }, {
          private_apps_legacy: ['crm.objects.goals.read']
        }, {
          private_apps: ['crm.schemas.deals.read']
        }, {
          oauth2: ['crm.schemas.deals.read']
        }, {
          oauth2_legacy: ['timeline']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.schemas.contacts.read']
        }, {
          private_apps: ['crm.schemas.contacts.read']
        }, {
          oauth2_legacy: ['tickets']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.read']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.objects.contacts.write']
        }, {
          private_apps: ['crm.objects.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.read']
        }, {
          oauth2: ['crm.schemas.companies.read']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          oauth2_legacy: ['crm.objects.goals.read']
        }, {
          private_apps: ['crm.schemas.line_items.read']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.read']
        }, {
          oauth2: ['crm.schemas.line_items.read']
        }]
      },
      delete: {
        tags: ['Groups'],
        summary: 'Archive a property group',
        description: 'Move a property group identified by {groupName} to the recycling bin.',
        operationId: 'delete-/crm/v3/properties/{objectType}/groups/{groupName}_archive',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'groupName',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }],
        responses: {
          '204': {
            description: 'No content',
            content: {}
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2: ['crm.schemas.deals.write']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.schemas.contacts.write']
        }, {
          private_apps_legacy: ['crm.properties.write']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.write']
        }, {
          oauth2: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.write']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.schemas.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.properties.write']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.schemas.custom.write']
        }, {
          private_apps: ['crm.schemas.deals.write']
        }]
      },
      patch: {
        tags: ['Groups'],
        summary: 'Update a property group',
        description: 'Perform a partial update of a property group identified by {groupName}. Provided fields will be overwritten.',
        operationId: 'patch-/crm/v3/properties/{objectType}/groups/{groupName}_update',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'groupName',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/PropertyGroupUpdate'
              }
            }
          },
          required: false
        },
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/PropertyGroup'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2: ['crm.schemas.deals.write']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.schemas.contacts.write']
        }, {
          private_apps_legacy: ['crm.properties.write']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.write']
        }, {
          oauth2: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.write']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.schemas.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.properties.write']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.schemas.custom.write']
        }, {
          private_apps: ['crm.schemas.deals.write']
        }]
      }
    },
    '/crm/v3/properties/{objectType}/{propertyName}': {
      get: {
        tags: ['Core'],
        summary: 'Read a property',
        description: 'Read a property identified by {propertyName}.',
        operationId: 'get-/crm/v3/properties/{objectType}/{propertyName}_getByName',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'propertyName',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'archived',
          in: 'query',
          description: 'Whether to return only results that have been archived.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'boolean',
            default: false
          }
        }],
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/Property'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2: ['crm.objects.contacts.read']
        }, {
          private_apps: ['crm.objects.deals.read']
        }, {
          private_apps_legacy: ['timeline']
        }, {
          oauth2: ['crm.objects.deals.read']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.read']
        }, {
          private_apps: ['crm.objects.contacts.read']
        }, {
          oauth2_legacy: ['crm.schemas.custom.read']
        }, {
          private_apps: ['crm.objects.companies.read']
        }, {
          private_apps: ['crm.objects.deals.write']
        }, {
          oauth2: ['crm.schemas.quotes.read']
        }, {
          private_apps: ['crm.schemas.quotes.read']
        }, {
          oauth2: ['crm.objects.companies.read']
        }, {
          oauth2: ['crm.objects.deals.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.read']
        }, {
          private_apps_legacy: ['crm.schemas.custom.read']
        }, {
          private_apps_legacy: ['crm.objects.goals.read']
        }, {
          private_apps: ['crm.schemas.deals.read']
        }, {
          oauth2: ['crm.schemas.deals.read']
        }, {
          oauth2_legacy: ['timeline']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.schemas.contacts.read']
        }, {
          private_apps: ['crm.schemas.contacts.read']
        }, {
          oauth2_legacy: ['tickets']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.read']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.objects.contacts.write']
        }, {
          private_apps: ['crm.objects.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.read']
        }, {
          oauth2: ['crm.schemas.companies.read']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          oauth2_legacy: ['crm.objects.goals.read']
        }, {
          private_apps: ['crm.schemas.line_items.read']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.read']
        }, {
          oauth2: ['crm.schemas.line_items.read']
        }]
      },
      delete: {
        tags: ['Core'],
        summary: 'Archive a property',
        description: 'Move a property identified by {propertyName} to the recycling bin.',
        operationId: 'delete-/crm/v3/properties/{objectType}/{propertyName}_archive',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'propertyName',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }],
        responses: {
          '204': {
            description: 'No content',
            content: {}
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2: ['crm.schemas.deals.write']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.schemas.contacts.write']
        }, {
          private_apps_legacy: ['crm.properties.write']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.write']
        }, {
          oauth2: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.write']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.schemas.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.properties.write']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.schemas.custom.write']
        }, {
          private_apps: ['crm.schemas.deals.write']
        }]
      },
      patch: {
        tags: ['Core'],
        summary: 'Update a property',
        description: 'Perform a partial update of a property identified by {propertyName}. Provided fields will be overwritten.',
        operationId: 'patch-/crm/v3/properties/{objectType}/{propertyName}_update',
        parameters: [{
          name: 'objectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'propertyName',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/PropertyUpdate'
              }
            }
          },
          required: false
        },
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/Property'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          oauth2_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2_legacy: ['crm.pipelines.tickets.write']
        }, {
          oauth2: ['crm.schemas.deals.write']
        }, {
          private_apps_legacy: ['e-commerce']
        }, {
          oauth2: ['crm.schemas.contacts.write']
        }, {
          private_apps_legacy: ['crm.properties.write']
        }, {
          private_apps_legacy: ['crm.pipelines.deals.write']
        }, {
          oauth2: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.pipelines.deals.write']
        }, {
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.schemas.contacts.write']
        }, {
          private_apps: ['crm.schemas.companies.write']
        }, {
          oauth2_legacy: ['crm.properties.write']
        }, {
          oauth2_legacy: ['e-commerce']
        }, {
          private_apps_legacy: ['tickets']
        }, {
          private_apps_legacy: ['crm.schemas.custom.write']
        }, {
          private_apps: ['crm.schemas.deals.write']
        }]
      }
    }
  },
  components: {
    schemas: {
      PropertyName: {
        required: ['name'],
        type: 'object',
        properties: {
          name: {
            type: 'string',
            description: 'The name of the property to read or modify.'
          }
        },
        example: {
          name: 'my_custom_property'
        }
      },
      StandardError: {
        required: ['category', 'context', 'errors', 'links', 'message', 'status'],
        type: 'object',
        properties: {
          status: {
            type: 'string'
          },
          id: {
            type: 'string'
          },
          category: {
            $ref: '#/components/schemas/ErrorCategory'
          },
          subCategory: {
            type: 'object',
            properties: {}
          },
          message: {
            type: 'string'
          },
          errors: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/ErrorDetail'
            }
          },
          context: {
            type: 'object',
            additionalProperties: {
              type: 'array',
              items: {
                type: 'string'
              }
            }
          },
          links: {
            type: 'object',
            additionalProperties: {
              type: 'string'
            }
          }
        }
      },
      BatchResponseProperty: {
        required: ['completedAt', 'errors', 'results', 'startedAt', 'status'],
        type: 'object',
        properties: {
          results: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/Property'
            }
          },
          numErrors: {
            type: 'integer',
            format: 'int32'
          },
          errors: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/StandardError'
            }
          },
          status: {
            type: 'string',
            enum: ['PENDING', 'PROCESSING', 'CANCELED', 'COMPLETE']
          },
          requestedAt: {
            type: 'string',
            format: 'date-time'
          },
          startedAt: {
            type: 'string',
            format: 'date-time'
          },
          completedAt: {
            type: 'string',
            format: 'date-time'
          }
        }
      },
      CollectionResponseProperty: {
        required: ['results'],
        type: 'object',
        properties: {
          results: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/Property'
            }
          },
          paging: {
            $ref: '#/components/schemas/Paging'
          }
        }
      },
      PropertyGroup: {
        required: ['archived', 'displayOrder', 'label', 'name'],
        type: 'object',
        properties: {
          name: {
            type: 'string',
            description: 'The internal property group name, which must be used when referencing the property group via the API.'
          },
          label: {
            type: 'string',
            description: 'A human-readable label that will be shown in HubSpot.'
          },
          displayOrder: {
            type: 'integer',
            description: 'Property groups are displayed in order starting with the lowest positive integer value. Values of -1 will cause the property group to be displayed after any positive values.',
            format: 'int32'
          },
          archived: {
            type: 'boolean'
          }
        },
        description: 'An ID for a group of properties',
        example: {
          name: 'mypropertygroup',
          displayOrder: -1,
          label: 'My Property Group'
        }
      },
      PropertyCreate: {
        required: ['fieldType', 'groupName', 'label', 'name', 'type'],
        type: 'object',
        properties: {
          name: {
            type: 'string',
            description: 'The internal property name, which must be used when referencing the property via the API.'
          },
          label: {
            type: 'string',
            description: 'A human-readable property label that will be shown in HubSpot.',
            example: 'My Contact Property'
          },
          type: {
            type: 'string',
            description: 'The data type of the property.',
            enum: ['string', 'number', 'date', 'datetime', 'enumeration'],
            example: 'enumeration'
          },
          fieldType: {
            type: 'string',
            description: 'Controls how the property appears in HubSpot.',
            enum: ['textarea', 'text', 'date', 'file', 'number', 'select', 'radio', 'checkbox', 'booleancheckbox'],
            example: 'select'
          },
          groupName: {
            type: 'string',
            description: 'The name of the property group the property belongs to.',
            example: 'contactinformation'
          },
          description: {
            type: 'string',
            description: 'A description of the property that will be shown as help text in HubSpot.'
          },
          options: {
            type: 'array',
            description: 'A list of valid options for the property. This field is required for enumerated properties.',
            items: {
              $ref: '#/components/schemas/OptionInput'
            }
          },
          displayOrder: {
            type: 'integer',
            description: 'Properties are displayed in order starting with the lowest positive integer value. Values of -1 will cause the property to be displayed after any positive values.',
            format: 'int32'
          },
          hasUniqueValue: {
            type: 'boolean',
            description: "Whether or not the property's value must be unique. Once set, this can't be changed."
          },
          hidden: {
            type: 'boolean',
            description: "If true, the property won't be visible and can't be used in HubSpot."
          }
        },
        example: {
          groupName: 'contactinformation',
          hidden: false,
          displayOrder: 2,
          options: [{
            label: 'Option A',
            description: 'Choice number one',
            value: 'A',
            displayOrder: 1,
            hidden: false
          }, {
            label: 'Option B',
            description: 'Choice number two',
            value: 'B',
            displayOrder: 2,
            hidden: false
          }],
          label: 'My Contact Property',
          hasUniqueValue: false,
          type: 'enumeration',
          fieldType: 'select'
        }
      },
      OptionInput: {
        required: ['hidden', 'label', 'value'],
        type: 'object',
        properties: {
          label: {
            type: 'string',
            description: 'A human-readable option label that will be shown in HubSpot.',
            example: 'Option A'
          },
          value: {
            type: 'string',
            description: 'The internal value of the option, which must be used when setting the property value through the API.',
            example: 'A'
          },
          description: {
            type: 'string',
            description: 'A description of the option.',
            example: 'Choice number one'
          },
          displayOrder: {
            type: 'integer',
            description: 'Options are shown in order starting with the lowest positive integer value. Values of -1 will cause the option to be displayed after any positive values.',
            format: 'int32',
            example: 1
          },
          hidden: {
            type: 'boolean',
            description: "Hidden options won't be shown in HubSpot.",
            example: false
          }
        },
        example: {
          hidden: false,
          displayOrder: 1,
          description: 'Choice number one',
          label: 'Option A',
          value: 'A'
        }
      },
      Paging: {
        type: 'object',
        properties: {
          next: {
            $ref: '#/components/schemas/NextPage'
          }
        }
      },
      Error: {
        required: ['category', 'correlationId', 'message'],
        type: 'object',
        properties: {
          message: {
            type: 'string',
            description: 'A human readable message describing the error along with remediation steps where appropriate',
            example: 'An error occurred'
          },
          correlationId: {
            type: 'string',
            description: 'A unique identifier for the request. Include this value with any error reports or support tickets',
            format: 'uuid',
            example: 'aeb5f871-7f07-4993-9211-075dc63e7cbf'
          },
          category: {
            type: 'string',
            description: 'The error category'
          },
          subCategory: {
            type: 'string',
            description: 'A specific category that contains more specific detail about the error'
          },
          errors: {
            type: 'array',
            description: 'further information about the error',
            items: {
              $ref: '#/components/schemas/ErrorDetail'
            }
          },
          context: {
            type: 'object',
            additionalProperties: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            description: 'Context about the error condition',
            example: {
              invalidPropertyName: ['propertyValue'],
              missingScopes: ['scope1', 'scope2']
            }
          },
          links: {
            type: 'object',
            additionalProperties: {
              type: 'string'
            },
            description: 'A map of link names to associated URIs containing documentation about the error or recommended remediation steps'
          }
        },
        example: {
          message: 'Invalid input (details will vary based on the error)',
          correlationId: 'aeb5f871-7f07-4993-9211-075dc63e7cbf',
          category: 'VALIDATION_ERROR',
          links: {
            'knowledge-base': 'https://www.hubspot.com/products/service/knowledge-base'
          }
        }
      },
      BatchInputPropertyCreate: {
        required: ['inputs'],
        type: 'object',
        properties: {
          inputs: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/PropertyCreate'
            }
          }
        }
      },
      ErrorDetail: {
        required: ['message'],
        type: 'object',
        properties: {
          message: {
            type: 'string',
            description: 'A human readable message describing the error along with remediation steps where appropriate'
          },
          in: {
            type: 'string',
            description: 'The name of the field or parameter in which the error was found.'
          },
          code: {
            type: 'string',
            description: 'The status code associated with the error detail'
          },
          subCategory: {
            type: 'string',
            description: 'A specific category that contains more specific detail about the error'
          },
          context: {
            type: 'object',
            additionalProperties: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            description: 'Context about the error condition',
            example: {
              missingScopes: ['scope1', 'scope2']
            }
          }
        }
      },
      PropertyUpdate: {
        type: 'object',
        properties: {
          label: {
            type: 'string',
            description: 'A human-readable property label that will be shown in HubSpot.',
            example: 'My Contact Property'
          },
          type: {
            type: 'string',
            description: 'The data type of the property.',
            enum: ['string', 'number', 'date', 'datetime', 'enumeration'],
            example: 'enumeration'
          },
          fieldType: {
            type: 'string',
            description: 'Controls how the property appears in HubSpot.',
            enum: ['textarea', 'text', 'date', 'file', 'number', 'select', 'radio', 'checkbox', 'booleancheckbox'],
            example: 'select'
          },
          groupName: {
            type: 'string',
            description: 'The name of the property group the property belongs to.',
            example: 'contactinformation'
          },
          description: {
            type: 'string',
            description: 'A description of the property that will be shown as help text in HubSpot.',
            example: 'Choice number one'
          },
          options: {
            type: 'array',
            description: 'A list of valid options for the property.',
            items: {
              $ref: '#/components/schemas/OptionInput'
            }
          },
          displayOrder: {
            type: 'integer',
            description: 'Properties are displayed in order starting with the lowest positive integer value. Values of -1 will cause the Property to be displayed after any positive values.',
            format: 'int32',
            example: 2
          },
          hidden: {
            type: 'boolean',
            description: "If true, the property won't be visible and can't be used in HubSpot.",
            example: false
          }
        }
      },
      BatchReadInputPropertyName: {
        required: ['archived', 'inputs'],
        type: 'object',
        properties: {
          archived: {
            type: 'boolean'
          },
          inputs: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/PropertyName'
            }
          }
        }
      },
      PropertyGroupCreate: {
        required: ['label', 'name'],
        type: 'object',
        properties: {
          name: {
            type: 'string',
            description: 'The internal property group name, which must be used when referencing the property group via the API.'
          },
          label: {
            type: 'string',
            description: 'A human-readable label that will be shown in HubSpot.'
          },
          displayOrder: {
            type: 'integer',
            description: 'Property groups are displayed in order starting with the lowest positive integer value. Values of -1 will cause the property group to be displayed after any positive values.',
            format: 'int32'
          }
        },
        example: {
          name: 'mypropertygroup',
          displayOrder: -1,
          label: 'My Property Group'
        }
      },
      PropertyModificationMetadata: {
        required: ['archivable', 'readOnlyDefinition', 'readOnlyValue'],
        type: 'object',
        properties: {
          archivable: {
            type: 'boolean'
          },
          readOnlyDefinition: {
            type: 'boolean'
          },
          readOnlyOptions: {
            type: 'boolean'
          },
          readOnlyValue: {
            type: 'boolean'
          }
        }
      },
      ErrorCategory: {
        required: ['httpStatus', 'name'],
        type: 'object',
        properties: {
          httpStatus: {
            type: 'string',
            enum: ['CONTINUE', 'SWITCHING_PROTOCOLS', 'PROCESSING', 'OK', 'CREATED', 'ACCEPTED', 'NON_AUTHORITATIVE_INFORMATION', 'NO_CONTENT', 'RESET_CONTENT', 'PARTIAL_CONTENT', 'MULTI_STATUS', 'ALREADY_REPORTED', 'IM_USED', 'MULTIPLE_CHOICES', 'MOVED_PERMANENTLY', 'FOUND', 'SEE_OTHER', 'NOT_MODIFIED', 'USE_PROXY', 'TEMPORARY_REDIRECT', 'PERMANENT_REDIRECT', 'BAD_REQUEST', 'UNAUTHORIZED', 'PAYMENT_REQUIRED', 'FORBIDDEN', 'NOT_FOUND', 'METHOD_NOT_ALLOWED', 'NOT_ACCEPTABLE', 'PROXY_AUTHENTICATION_REQUIRED', 'REQUEST_TIMEOUT', 'CONFLICT', 'GONE', 'LENGTH_REQUIRED', 'PRECONDITION_FAILED', 'REQUEST_ENTITY_TOO_LARGE', 'REQUEST_URI_TOO_LONG', 'UNSUPPORTED_MEDIA_TYPE', 'REQUESTED_RANGE_NOT_SATISFIABLE', 'EXPECTATION_FAILED', 'IM_A_TEAPOT', 'MISDIRECTED_REQUEST', 'UNPROCESSABLE_ENTITY', 'LOCKED', 'FAILED_DEPENDENCY', 'UPGRADE_REQUIRED', 'PRECONDITION_REQUIRED', 'TOO_MANY_REQUESTS', 'REQUEST_HEADERS_FIELDS_TOO_LARGE', 'INTERNAL_STALE_SERVICE_DISCOVERY', 'UNAVAILABLE_FOR_LEGAL_REASONS', 'INTERNAL_SERVER_ERROR', 'NOT_IMPLEMENTED', 'BAD_GATEWAY', 'SERVICE_UNAVAILABLE', 'GATEWAY_TIMEOUT', 'HTTP_VERSION_NOT_SUPPORTED', 'VARIANT_ALSO_NEGOTIATES', 'INSUFFICIENT_STORAGE', 'LOOP_DETECTED', 'NOT_EXTENDED', 'NETWORK_AUTHENTICATION_REQUIRED']
          },
          name: {
            type: 'string'
          }
        }
      },
      BatchInputPropertyName: {
        required: ['inputs'],
        type: 'object',
        properties: {
          inputs: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/PropertyName'
            }
          }
        }
      },
      Option: {
        required: ['hidden', 'label', 'value'],
        type: 'object',
        properties: {
          label: {
            type: 'string',
            description: 'A human-readable option label that will be shown in HubSpot.'
          },
          value: {
            type: 'string',
            description: 'The internal value of the option, which must be used when setting the property value through the API.'
          },
          description: {
            type: 'string',
            description: 'A description of the option.'
          },
          displayOrder: {
            type: 'integer',
            description: 'Options are displayed in order starting with the lowest positive integer value. Values of -1 will cause the option to be displayed after any positive values.',
            format: 'int32'
          },
          hidden: {
            type: 'boolean',
            description: 'Hidden options will not be displayed in HubSpot.'
          }
        },
        example: {
          hidden: false,
          displayOrder: 1,
          description: 'Choice number one',
          label: 'Option A',
          value: 'A'
        }
      },
      CollectionResponsePropertyGroup: {
        required: ['results'],
        type: 'object',
        properties: {
          results: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/PropertyGroup'
            }
          },
          paging: {
            $ref: '#/components/schemas/Paging'
          }
        }
      },
      PropertyGroupUpdate: {
        type: 'object',
        properties: {
          label: {
            type: 'string',
            description: 'A human-readable label that will be shown in HubSpot.'
          },
          displayOrder: {
            type: 'integer',
            description: 'Property groups are displayed in order starting with the lowest positive integer value. Values of -1 will cause the property group to be displayed after any positive values.',
            format: 'int32'
          }
        },
        example: {
          displayOrder: -1,
          label: 'My Property Group'
        }
      },
      NextPage: {
        required: ['after'],
        type: 'object',
        properties: {
          after: {
            type: 'string'
          },
          link: {
            type: 'string'
          }
        }
      },
      Property: {
        required: ['description', 'fieldType', 'groupName', 'label', 'name', 'options', 'type'],
        type: 'object',
        properties: {
          updatedAt: {
            type: 'string',
            format: 'date-time'
          },
          createdAt: {
            type: 'string',
            format: 'date-time'
          },
          archivedAt: {
            type: 'string',
            description: 'When the property was archived.',
            format: 'date-time'
          },
          name: {
            type: 'string',
            description: 'The internal property name, which must be used when referencing the property via the API.'
          },
          label: {
            type: 'string',
            description: 'A human-readable property label that will be shown in HubSpot.'
          },
          type: {
            type: 'string',
            description: 'The property data type.'
          },
          fieldType: {
            type: 'string',
            description: 'Controls how the property appears in HubSpot.'
          },
          description: {
            type: 'string',
            description: 'A description of the property that will be shown as help text in HubSpot.'
          },
          groupName: {
            type: 'string',
            description: 'The name of the property group the property belongs to.'
          },
          options: {
            type: 'array',
            description: 'A list of valid options for the property. This field is required for enumerated properties, but will be empty for other property types.',
            items: {
              $ref: '#/components/schemas/Option'
            }
          },
          createdUserId: {
            type: 'string',
            description: 'The internal user ID of the user who created the property in HubSpot. This field may not exist if the property was created outside of HubSpot.'
          },
          updatedUserId: {
            type: 'string',
            description: 'The internal user ID of the user who updated the property in HubSpot. This field may not exist if the property was updated outside of HubSpot.'
          },
          referencedObjectType: {
            type: 'string',
            description: "If this property is related to other object(s), they'll be listed here."
          },
          displayOrder: {
            type: 'integer',
            description: 'Properties are shown in order, starting with the lowest positive integer value.',
            format: 'int32'
          },
          calculated: {
            type: 'boolean',
            description: 'For default properties, true indicates that the property is calculated by a HubSpot process. It has no effect for custom properties.'
          },
          externalOptions: {
            type: 'boolean',
            description: 'For default properties, true indicates that the options are stored externally to the property settings.'
          },
          archived: {
            type: 'boolean',
            description: 'Whether or not the property is archived.'
          },
          hasUniqueValue: {
            type: 'boolean',
            description: "Whether or not the property's value must be unique. Once set, this can't be changed."
          },
          hidden: {
            type: 'boolean',
            description: "Whether or not the property will be hidden from the HubSpot UI. It's recommended this be set to false for custom properties."
          },
          hubspotDefined: {
            type: 'boolean',
            description: 'This will be true for default object properties built into HubSpot.'
          },
          showCurrencySymbol: {
            type: 'boolean',
            description: 'Whether or not the property will display the currency symbol set in the account settings.'
          },
          modificationMetadata: {
            $ref: '#/components/schemas/PropertyModificationMetadata'
          },
          formField: {
            type: 'boolean',
            description: 'Whether or not the property can be used in a HubSpot form.'
          }
        },
        example: {
          groupName: 'contactinformation',
          hidden: false,
          modificationMetadata: {
            readOnlyOptions: false,
            readOnlyValue: false,
            readOnlyDefinition: false,
            archivable: true
          },
          name: 'my_contact_property',
          displayOrder: 2,
          options: [{
            label: 'Option A',
            description: 'Choice number one',
            value: 'A',
            displayOrder: 1,
            hidden: false
          }, {
            label: 'Option B',
            description: 'Choice number two',
            value: 'B',
            displayOrder: 2,
            hidden: false
          }],
          label: 'My Contact Property',
          hasUniqueValue: false,
          type: 'enumeration',
          fieldType: 'select'
        }
      }
    },
    responses: {
      Error: {
        description: 'An error occurred.',
        content: {
          '*/*': {
            schema: {
              $ref: '#/components/schemas/Error'
            }
          }
        }
      }
    },
    securitySchemes: {
      oauth2_legacy: {
        type: 'oauth2',
        flows: {
          authorizationCode: {
            // eslint-disable-next-line hubspot-dev/prefer-hubspot-url-utils
            authorizationUrl: 'https://app.hubspot.com/oauth/authorize',
            tokenUrl: 'https://api.hubapi.com/oauth/v1/token',
            scopes: {
              'crm.pipelines.tickets.read': 'View details about your pipelines including pipeline stage information',
              timeline: 'Create timeline events',
              'crm.objects.goals.read': 'Read goals',
              'crm.schemas.custom.read': 'View custom object definitions',
              tickets: 'Read and write tickets',
              'crm.pipelines.deals.read': 'View details about your deal pipelines including pipeline stage information',
              'e-commerce': 'e-commerce',
              'crm.properties.write': 'Testing out properties write',
              'crm.pipelines.tickets.write': 'Create, delete, or make to your ticket pipelines or their respective stages',
              'crm.pipelines.deals.write': 'Create, delete, or make to your deal pipelines or their respective stages'
            }
          }
        }
      },
      oauth2: {
        type: 'oauth2',
        flows: {
          authorizationCode: {
            // eslint-disable-next-line hubspot-dev/prefer-hubspot-url-utils
            authorizationUrl: 'https://app.hubspot.com/oauth/authorize',
            tokenUrl: 'https://api.hubapi.com/oauth/v1/token',
            scopes: {
              'crm.schemas.contacts.read': ' ',
              'crm.objects.companies.write': ' ',
              'crm.objects.companies.read': ' ',
              'crm.schemas.companies.read': ' ',
              'crm.objects.deals.read': ' ',
              'crm.schemas.line_items.read': 'Line Items schemas',
              'crm.objects.deals.write': ' ',
              'crm.schemas.deals.read': ' ',
              'crm.objects.contacts.read': ' ',
              'crm.schemas.quotes.read': 'Quotes schemas',
              'crm.objects.contacts.write': ' ',
              'crm.schemas.contacts.write': ' ',
              'crm.schemas.companies.write': ' ',
              'crm.schemas.deals.write': ' '
            }
          }
        }
      },
      private_apps_legacy: {
        type: 'apiKey',
        name: 'private-app-legacy',
        in: 'header'
      },
      private_apps: {
        type: 'apiKey',
        name: 'private-app',
        in: 'header'
      }
    }
  },
  'x-hubspot-available-client-libraries': ['PHP', 'Node', 'Python', 'Ruby']
};
export const mockCompaniesOasSpec = {
  openapi: '3.0.1',
  info: {
    title: 'Companies',
    version: 'v3'
  },
  servers: [{
    url: serverUrl
  }],
  tags: [{
    name: 'Associations'
  }, {
    name: 'Basic'
  }, {
    name: 'Batch'
  }, {
    name: 'Search'
  }, {
    name: 'Public_Object'
  }],
  paths: {
    '/crm/v3/objects/companies': {
      get: {
        tags: ['Basic'],
        summary: 'List',
        description: 'Read a page of companies. Control what is returned via the `properties` query param.',
        operationId: 'get-/crm/v3/objects/companies_getPage',
        parameters: [{
          name: 'limit',
          in: 'query',
          description: 'The maximum number of results to display per page.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'integer',
            format: 'int32',
            default: 10
          }
        }, {
          name: 'after',
          in: 'query',
          description: 'The paging cursor token of the last successfully read resource will be returned as the `paging.next.after` JSON property of a paged response containing more results.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'string'
          }
        }, {
          name: 'properties',
          in: 'query',
          description: 'A comma separated list of the properties to be returned in the response. If any of the specified properties are not present on the requested object(s), they will be ignored.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'array',
            items: {
              type: 'string'
            }
          }
        }, {
          name: 'propertiesWithHistory',
          in: 'query',
          description: 'A comma separated list of the properties to be returned along with their history of previous values. If any of the specified properties are not present on the requested object(s), they will be ignored. Usage of this parameter will reduce the maximum number of objects that can be read by a single request.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'array',
            items: {
              type: 'string'
            }
          }
        }, {
          name: 'associations',
          in: 'query',
          description: 'A comma separated list of object types to retrieve associated IDs for. If any of the specified associations do not exist, they will be ignored.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'array',
            items: {
              type: 'string'
            }
          }
        }, {
          name: 'archived',
          in: 'query',
          description: 'Whether to return only results that have been archived.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'boolean',
            default: false
          }
        }],
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/CollectionResponseSimplePublicObjectWithAssociationsForwardPaging'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps: ['crm.objects.companies.read']
        }, {
          oauth2: ['crm.objects.companies.read']
        }, {
          private_apps_legacy: ['contacts']
        }]
      },
      post: {
        tags: ['Basic'],
        summary: 'Create',
        description: 'Create a company with the given properties and return a copy of the object, including the ID. Documentation and examples for creating standard companies is provided.',
        operationId: 'post-/crm/v3/objects/companies_create',
        parameters: [],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/SimplePublicObjectInputForCreate'
              }
            }
          },
          required: true
        },
        responses: {
          '201': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/SimplePublicObject'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }]
      }
    },
    '/crm/v3/objects/companies/batch/archive': {
      post: {
        tags: ['Batch'],
        summary: 'Archive a batch of companies by ID',
        operationId: 'post-/crm/v3/objects/companies/batch/archive_archive',
        parameters: [],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/BatchInputSimplePublicObjectId'
              }
            }
          },
          required: true
        },
        responses: {
          '204': {
            description: 'No content',
            content: {}
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }]
      }
    },
    '/crm/v3/objects/companies/batch/create': {
      post: {
        tags: ['Batch'],
        summary: 'Create a batch of companies',
        operationId: 'post-/crm/v3/objects/companies/batch/create_create',
        parameters: [],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/BatchInputSimplePublicObjectInputForCreate'
              }
            }
          },
          required: true
        },
        responses: {
          '201': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/BatchResponseSimplePublicObject'
                }
              }
            }
          },
          '207': {
            description: 'multiple statuses',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/BatchResponseSimplePublicObjectWithErrors'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }]
      }
    },
    '/crm/v3/objects/companies/batch/read': {
      post: {
        tags: ['Batch'],
        summary: 'Read a batch of companies by internal ID, or unique property values',
        operationId: 'post-/crm/v3/objects/companies/batch/read_read',
        parameters: [{
          name: 'archived',
          in: 'query',
          description: 'Whether to return only results that have been archived.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'boolean',
            default: false
          }
        }],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/BatchReadInputSimplePublicObjectId'
              }
            }
          },
          required: true
        },
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/BatchResponseSimplePublicObject'
                }
              }
            }
          },
          '207': {
            description: 'multiple statuses',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/BatchResponseSimplePublicObjectWithErrors'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps: ['crm.objects.companies.read']
        }, {
          oauth2: ['crm.objects.companies.read']
        }, {
          private_apps_legacy: ['contacts']
        }]
      }
    },
    '/crm/v3/objects/companies/batch/update': {
      post: {
        tags: ['Batch'],
        summary: 'Update a batch of companies',
        operationId: 'post-/crm/v3/objects/companies/batch/update_update',
        parameters: [],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/BatchInputSimplePublicObjectBatchInput'
              }
            }
          },
          required: true
        },
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/BatchResponseSimplePublicObject'
                }
              }
            }
          },
          '207': {
            description: 'multiple statuses',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/BatchResponseSimplePublicObjectWithErrors'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }]
      }
    },
    '/crm/v3/objects/companies/merge': {
      post: {
        tags: ['Public_Object'],
        summary: 'Merge two companies with same type',
        operationId: 'post-/crm/v3/objects/companies/merge_merge',
        parameters: [],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/PublicMergeInput'
              }
            }
          },
          required: true
        },
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/SimplePublicObject'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }]
      }
    },
    '/crm/v3/objects/companies/search': {
      post: {
        tags: ['Search'],
        operationId: 'post-/crm/v3/objects/companies/search_doSearch',
        parameters: [],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/PublicObjectSearchRequest'
              }
            }
          },
          required: true
        },
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/CollectionResponseWithTotalSimplePublicObjectForwardPaging'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps: ['crm.objects.companies.read']
        }, {
          oauth2: ['crm.objects.companies.read']
        }, {
          private_apps_legacy: ['contacts']
        }],
        'x-hubspot-rate-limit-exemptions': ['ten-secondly']
      }
    },
    '/crm/v3/objects/companies/{companyId}': {
      get: {
        tags: ['Basic'],
        summary: 'Read',
        description: 'Read an Object identified by `{companyId}`. `{companyId}` refers to the internal object ID by default, or optionally any unique property value as specified by the `idProperty` query param.  Control what is returned via the `properties` query param.',
        operationId: 'get-/crm/v3/objects/companies/{companyId}_getById',
        parameters: [{
          name: 'companyId',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'properties',
          in: 'query',
          description: 'A comma separated list of the properties to be returned in the response. If any of the specified properties are not present on the requested object(s), they will be ignored.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'array',
            items: {
              type: 'string'
            }
          }
        }, {
          name: 'propertiesWithHistory',
          in: 'query',
          description: 'A comma separated list of the properties to be returned along with their history of previous values. If any of the specified properties are not present on the requested object(s), they will be ignored.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'array',
            items: {
              type: 'string'
            }
          }
        }, {
          name: 'associations',
          in: 'query',
          description: 'A comma separated list of object types to retrieve associated IDs for. If any of the specified associations do not exist, they will be ignored.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'array',
            items: {
              type: 'string'
            }
          }
        }, {
          name: 'archived',
          in: 'query',
          description: 'Whether to return only results that have been archived.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'boolean',
            default: false
          }
        }, {
          name: 'idProperty',
          in: 'query',
          description: 'The name of a property whose values are unique for this object type',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'string'
          }
        }],
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/SimplePublicObjectWithAssociations'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps: ['crm.objects.companies.read']
        }, {
          oauth2: ['crm.objects.companies.read']
        }, {
          private_apps_legacy: ['contacts']
        }]
      },
      delete: {
        tags: ['Basic'],
        summary: 'Archive',
        description: 'Move an Object identified by `{companyId}` to the recycling bin.',
        operationId: 'delete-/crm/v3/objects/companies/{companyId}_archive',
        parameters: [{
          name: 'companyId',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }],
        responses: {
          '204': {
            description: 'No content',
            content: {}
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }]
      },
      patch: {
        tags: ['Basic'],
        summary: 'Update',
        description: 'Perform a partial update of an Object identified by `{companyId}`. `{companyId}` refers to the internal object ID by default, or optionally any unique property value as specified by the `idProperty` query param. Provided property values will be overwritten. Read-only and non-existent properties will be ignored. Properties values can be cleared by passing an empty string.',
        operationId: 'patch-/crm/v3/objects/companies/{companyId}_update',
        parameters: [{
          name: 'companyId',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'idProperty',
          in: 'query',
          description: 'The name of a property whose values are unique for this object type',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'string'
          }
        }],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/SimplePublicObjectInput'
              }
            }
          },
          required: true
        },
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/SimplePublicObject'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }]
      }
    },
    '/crm/v4/objects/companies/{companyId}/associations/{toObjectType}': {
      get: {
        tags: ['Associations'],
        summary: 'List',
        description: 'List all associations of a company by object type. Limit 1000 per call.',
        operationId: 'get-/crm/v4/objects/companies/{companyId}/associations/{toObjectType}_getAll',
        parameters: [{
          name: 'companyId',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'integer',
            format: 'int64'
          }
        }, {
          name: 'toObjectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'after',
          in: 'query',
          description: 'The paging cursor token of the last successfully read resource will be returned as the `paging.next.after` JSON property of a paged response containing more results.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'string'
          }
        }, {
          name: 'limit',
          in: 'query',
          description: 'The maximum number of results to display per page.',
          required: false,
          style: 'form',
          explode: true,
          schema: {
            type: 'integer',
            format: 'int32',
            default: 500
          }
        }],
        responses: {
          '200': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/CollectionResponseMultiAssociatedObjectWithLabelForwardPaging'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps: ['crm.objects.companies.read']
        }, {
          oauth2: ['crm.objects.companies.read']
        }, {
          private_apps_legacy: ['contacts']
        }]
      }
    },
    '/crm/v4/objects/companies/{companyId}/associations/{toObjectType}/{toObjectId}': {
      put: {
        tags: ['Associations'],
        summary: 'Create',
        description: 'Set association labels between two records.',
        operationId: 'put-/crm/v4/objects/companies/{companyId}/associations/{toObjectType}/{toObjectId}_create',
        parameters: [{
          name: 'companyId',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'integer',
            format: 'int64'
          }
        }, {
          name: 'toObjectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'toObjectId',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'integer',
            format: 'int64'
          }
        }],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                type: 'array',
                items: {
                  $ref: '#/components/schemas/AssociationSpec'
                }
              }
            }
          },
          required: true
        },
        responses: {
          '201': {
            description: 'successful operation',
            content: {
              'application/json': {
                schema: {
                  $ref: '#/components/schemas/LabelsBetweenObjectPair'
                }
              }
            }
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }]
      },
      delete: {
        tags: ['Associations'],
        summary: 'Delete',
        description: 'deletes all associations between two records.',
        operationId: 'delete-/crm/v4/objects/companies/{companyId}/associations/{toObjectType}/{toObjectId}_archive',
        parameters: [{
          name: 'companyId',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'integer',
            format: 'int64'
          }
        }, {
          name: 'toObjectType',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'string'
          }
        }, {
          name: 'toObjectId',
          in: 'path',
          required: true,
          style: 'simple',
          explode: false,
          schema: {
            type: 'integer',
            format: 'int64'
          }
        }],
        responses: {
          '204': {
            description: 'No content',
            content: {}
          },
          default: {
            $ref: '#/components/responses/Error'
          }
        },
        security: [{
          private_apps_legacy: ['contacts']
        }, {
          private_apps: ['crm.objects.companies.write']
        }, {
          oauth2: ['crm.objects.companies.write']
        }]
      }
    }
  },
  components: {
    schemas: {
      StandardError: {
        required: ['category', 'context', 'errors', 'links', 'message', 'status'],
        type: 'object',
        properties: {
          status: {
            type: 'string'
          },
          id: {
            type: 'string'
          },
          category: {
            $ref: '#/components/schemas/ErrorCategory'
          },
          subCategory: {
            type: 'object',
            properties: {}
          },
          message: {
            type: 'string'
          },
          errors: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/ErrorDetail'
            }
          },
          context: {
            type: 'object',
            additionalProperties: {
              type: 'array',
              items: {
                type: 'string'
              }
            }
          },
          links: {
            type: 'object',
            additionalProperties: {
              type: 'string'
            }
          }
        }
      },
      CollectionResponseAssociatedId: {
        required: ['results'],
        type: 'object',
        properties: {
          results: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/AssociatedId'
            }
          },
          paging: {
            $ref: '#/components/schemas/Paging'
          }
        }
      },
      LabelsBetweenObjectPair: {
        required: ['fromObjectId', 'fromObjectTypeId', 'labels', 'toObjectId', 'toObjectTypeId'],
        type: 'object',
        properties: {
          fromObjectTypeId: {
            type: 'string'
          },
          fromObjectId: {
            type: 'integer',
            format: 'int32'
          },
          toObjectTypeId: {
            type: 'string'
          },
          toObjectId: {
            type: 'integer',
            format: 'int32'
          },
          labels: {
            type: 'array',
            items: {
              type: 'string'
            }
          }
        }
      },
      PublicAssociationsForObject: {
        required: ['to', 'types'],
        type: 'object',
        properties: {
          to: {
            $ref: '#/components/schemas/PublicObjectId'
          },
          types: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/AssociationSpec'
            }
          }
        }
      },
      BatchResponseSimplePublicObject: {
        required: ['completedAt', 'results', 'startedAt', 'status'],
        type: 'object',
        properties: {
          status: {
            type: 'string',
            enum: ['PENDING', 'PROCESSING', 'CANCELED', 'COMPLETE']
          },
          results: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/SimplePublicObject'
            }
          },
          requestedAt: {
            type: 'string',
            format: 'date-time'
          },
          startedAt: {
            type: 'string',
            format: 'date-time'
          },
          completedAt: {
            type: 'string',
            format: 'date-time'
          },
          links: {
            type: 'object',
            additionalProperties: {
              type: 'string'
            }
          }
        }
      },
      FilterGroup: {
        required: ['filters'],
        type: 'object',
        properties: {
          filters: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/Filter'
            }
          }
        }
      },
      MultiAssociatedObjectWithLabel: {
        required: ['associationTypes', 'toObjectId'],
        type: 'object',
        properties: {
          toObjectId: {
            type: 'integer',
            format: 'int32'
          },
          associationTypes: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/AssociationSpecWithLabel'
            }
          }
        }
      },
      ErrorDetail: {
        required: ['message'],
        type: 'object',
        properties: {
          message: {
            type: 'string',
            description: 'A human readable message describing the error along with remediation steps where appropriate'
          },
          in: {
            type: 'string',
            description: 'The name of the field or parameter in which the error was found.'
          },
          code: {
            type: 'string',
            description: 'The status code associated with the error detail'
          },
          subCategory: {
            type: 'string',
            description: 'A specific category that contains more specific detail about the error'
          },
          context: {
            type: 'object',
            additionalProperties: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            description: 'Context about the error condition',
            example: {
              missingScopes: ['scope1', 'scope2']
            }
          }
        }
      },
      ForwardPaging: {
        type: 'object',
        properties: {
          next: {
            $ref: '#/components/schemas/NextPage'
          }
        }
      },
      SimplePublicObjectId: {
        required: ['id'],
        type: 'object',
        properties: {
          id: {
            type: 'string'
          }
        }
      },
      AssociationSpecWithLabel: {
        required: ['category', 'typeId'],
        type: 'object',
        properties: {
          category: {
            type: 'string',
            enum: ['HUBSPOT_DEFINED', 'USER_DEFINED', 'INTEGRATOR_DEFINED']
          },
          typeId: {
            type: 'integer',
            format: 'int32'
          },
          label: {
            type: 'string'
          }
        }
      },
      BatchReadInputSimplePublicObjectId: {
        required: ['inputs', 'properties', 'propertiesWithHistory'],
        type: 'object',
        properties: {
          properties: {
            type: 'array',
            items: {
              type: 'string'
            }
          },
          propertiesWithHistory: {
            type: 'array',
            items: {
              type: 'string'
            }
          },
          idProperty: {
            type: 'string'
          },
          inputs: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/SimplePublicObjectId'
            }
          }
        }
      },
      BatchInputSimplePublicObjectId: {
        required: ['inputs'],
        type: 'object',
        properties: {
          inputs: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/SimplePublicObjectId'
            }
          }
        }
      },
      ValueWithTimestamp: {
        required: ['sourceType', 'timestamp', 'value'],
        type: 'object',
        properties: {
          value: {
            type: 'string'
          },
          timestamp: {
            type: 'string',
            format: 'date-time'
          },
          sourceType: {
            type: 'string'
          },
          sourceId: {
            type: 'string'
          },
          sourceLabel: {
            type: 'string'
          },
          updatedByUserId: {
            type: 'integer',
            format: 'int32'
          }
        }
      },
      CollectionResponseWithTotalSimplePublicObjectForwardPaging: {
        required: ['results', 'total'],
        type: 'object',
        properties: {
          total: {
            type: 'integer',
            format: 'int32'
          },
          results: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/SimplePublicObject'
            }
          },
          paging: {
            $ref: '#/components/schemas/ForwardPaging'
          }
        }
      },
      SimplePublicObject: {
        required: ['createdAt', 'id', 'properties', 'updatedAt'],
        type: 'object',
        properties: {
          id: {
            type: 'string'
          },
          properties: {
            type: 'object',
            additionalProperties: {
              type: 'string'
            }
          },
          propertiesWithHistory: {
            type: 'object',
            additionalProperties: {
              type: 'array',
              items: {
                $ref: '#/components/schemas/ValueWithTimestamp'
              }
            }
          },
          createdAt: {
            type: 'string',
            format: 'date-time'
          },
          updatedAt: {
            type: 'string',
            format: 'date-time'
          },
          archived: {
            type: 'boolean'
          },
          archivedAt: {
            type: 'string',
            format: 'date-time'
          }
        },
        example: {
          id: '512',
          properties: {
            city: 'Cambridge',
            createdate: '2019-10-30T03:30:17.883Z',
            domain: 'biglytics.net',
            hs_lastmodifieddate: '2019-12-07T16:50:06.678Z',
            industry: 'Technology',
            name: 'Biglytics',
            phone: '(877) 929-0687',
            state: 'Massachusetts'
          },
          createdAt: '2019-10-30T03:30:17.883Z',
          updatedAt: '2019-12-07T16:50:06.678Z',
          archived: false
        }
      },
      PublicObjectId: {
        required: ['id'],
        type: 'object',
        properties: {
          id: {
            type: 'string',
            example: '101'
          }
        }
      },
      Paging: {
        type: 'object',
        properties: {
          next: {
            $ref: '#/components/schemas/NextPage'
          },
          prev: {
            $ref: '#/components/schemas/PreviousPage'
          }
        }
      },
      PublicObjectSearchRequest: {
        required: ['after', 'filterGroups', 'limit', 'properties', 'sorts'],
        type: 'object',
        properties: {
          filterGroups: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/FilterGroup'
            }
          },
          sorts: {
            type: 'array',
            items: {
              type: 'string'
            }
          },
          query: {
            type: 'string'
          },
          properties: {
            type: 'array',
            items: {
              type: 'string'
            }
          },
          limit: {
            type: 'integer',
            format: 'int32'
          },
          after: {
            type: 'integer',
            format: 'int32'
          }
        }
      },
      Error: {
        required: ['category', 'correlationId', 'message'],
        type: 'object',
        properties: {
          message: {
            type: 'string',
            description: 'A human readable message describing the error along with remediation steps where appropriate',
            example: 'An error occurred'
          },
          correlationId: {
            type: 'string',
            description: 'A unique identifier for the request. Include this value with any error reports or support tickets',
            format: 'uuid',
            example: 'aeb5f871-7f07-4993-9211-075dc63e7cbf'
          },
          category: {
            type: 'string',
            description: 'The error category'
          },
          subCategory: {
            type: 'string',
            description: 'A specific category that contains more specific detail about the error'
          },
          errors: {
            type: 'array',
            description: 'further information about the error',
            items: {
              $ref: '#/components/schemas/ErrorDetail'
            }
          },
          context: {
            type: 'object',
            additionalProperties: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            description: 'Context about the error condition',
            example: {
              invalidPropertyName: ['propertyValue'],
              missingScopes: ['scope1', 'scope2']
            }
          },
          links: {
            type: 'object',
            additionalProperties: {
              type: 'string'
            },
            description: 'A map of link names to associated URIs containing documentation about the error or recommended remediation steps'
          }
        },
        example: {
          message: 'Invalid input (details will vary based on the error)',
          correlationId: 'aeb5f871-7f07-4993-9211-075dc63e7cbf',
          category: 'VALIDATION_ERROR',
          links: {
            'knowledge-base': 'https://www.hubspot.com/products/service/knowledge-base'
          }
        }
      },
      BatchResponseSimplePublicObjectWithErrors: {
        required: ['completedAt', 'results', 'startedAt', 'status'],
        type: 'object',
        properties: {
          status: {
            type: 'string',
            enum: ['PENDING', 'PROCESSING', 'CANCELED', 'COMPLETE']
          },
          results: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/SimplePublicObject'
            }
          },
          numErrors: {
            type: 'integer',
            format: 'int32'
          },
          errors: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/StandardError'
            }
          },
          requestedAt: {
            type: 'string',
            format: 'date-time'
          },
          startedAt: {
            type: 'string',
            format: 'date-time'
          },
          completedAt: {
            type: 'string',
            format: 'date-time'
          },
          links: {
            type: 'object',
            additionalProperties: {
              type: 'string'
            }
          }
        }
      },
      SimplePublicObjectInput: {
        required: ['properties'],
        type: 'object',
        properties: {
          properties: {
            type: 'object',
            additionalProperties: {
              type: 'string'
            }
          }
        },
        example: {
          properties: {
            city: 'Cambridge',
            domain: 'biglytics.net',
            industry: 'Technology',
            name: 'Biglytics',
            phone: '(877) 929-0687',
            state: 'Massachusetts'
          }
        }
      },
      CollectionResponseSimplePublicObjectWithAssociationsForwardPaging: {
        required: ['results'],
        type: 'object',
        properties: {
          results: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/SimplePublicObjectWithAssociations'
            }
          },
          paging: {
            $ref: '#/components/schemas/ForwardPaging'
          }
        }
      },
      AssociationSpec: {
        required: ['associationCategory', 'associationTypeId'],
        type: 'object',
        properties: {
          associationCategory: {
            type: 'string',
            enum: ['HUBSPOT_DEFINED', 'USER_DEFINED', 'INTEGRATOR_DEFINED']
          },
          associationTypeId: {
            type: 'integer',
            format: 'int32',
            example: 2
          }
        }
      },
      PublicMergeInput: {
        required: ['objectIdToMerge', 'primaryObjectId'],
        type: 'object',
        properties: {
          primaryObjectId: {
            type: 'string'
          },
          objectIdToMerge: {
            type: 'string'
          }
        }
      },
      ErrorCategory: {
        required: ['httpStatus', 'name'],
        type: 'object',
        properties: {
          name: {
            type: 'string'
          },
          httpStatus: {
            type: 'string',
            enum: ['CONTINUE', 'SWITCHING_PROTOCOLS', 'PROCESSING', 'OK', 'CREATED', 'ACCEPTED', 'NON_AUTHORITATIVE_INFORMATION', 'NO_CONTENT', 'RESET_CONTENT', 'PARTIAL_CONTENT', 'MULTI_STATUS', 'ALREADY_REPORTED', 'IM_USED', 'MULTIPLE_CHOICES', 'MOVED_PERMANENTLY', 'FOUND', 'SEE_OTHER', 'NOT_MODIFIED', 'USE_PROXY', 'TEMPORARY_REDIRECT', 'PERMANENT_REDIRECT', 'BAD_REQUEST', 'UNAUTHORIZED', 'PAYMENT_REQUIRED', 'FORBIDDEN', 'NOT_FOUND', 'METHOD_NOT_ALLOWED', 'NOT_ACCEPTABLE', 'PROXY_AUTHENTICATION_REQUIRED', 'REQUEST_TIMEOUT', 'CONFLICT', 'GONE', 'LENGTH_REQUIRED', 'PRECONDITION_FAILED', 'REQUEST_ENTITY_TOO_LARGE', 'REQUEST_URI_TOO_LONG', 'UNSUPPORTED_MEDIA_TYPE', 'REQUESTED_RANGE_NOT_SATISFIABLE', 'EXPECTATION_FAILED', 'IM_A_TEAPOT', 'MISDIRECTED_REQUEST', 'UNPROCESSABLE_ENTITY', 'LOCKED', 'FAILED_DEPENDENCY', 'UPGRADE_REQUIRED', 'PRECONDITION_REQUIRED', 'TOO_MANY_REQUESTS', 'REQUEST_HEADERS_FIELDS_TOO_LARGE', 'INTERNAL_STALE_SERVICE_DISCOVERY', 'UNAVAILABLE_FOR_LEGAL_REASONS', 'MIGRATION_IN_PROGRESS', 'INTERNAL_SERVER_ERROR', 'NOT_IMPLEMENTED', 'BAD_GATEWAY', 'SERVICE_UNAVAILABLE', 'GATEWAY_TIMEOUT', 'HTTP_VERSION_NOT_SUPPORTED', 'VARIANT_ALSO_NEGOTIATES', 'INSUFFICIENT_STORAGE', 'LOOP_DETECTED', 'NOT_EXTENDED', 'NETWORK_AUTHENTICATION_REQUIRED']
          }
        }
      },
      SimplePublicObjectWithAssociations: {
        required: ['createdAt', 'id', 'properties', 'updatedAt'],
        type: 'object',
        properties: {
          id: {
            type: 'string'
          },
          properties: {
            type: 'object',
            additionalProperties: {
              type: 'string'
            }
          },
          propertiesWithHistory: {
            type: 'object',
            additionalProperties: {
              type: 'array',
              items: {
                $ref: '#/components/schemas/ValueWithTimestamp'
              }
            }
          },
          createdAt: {
            type: 'string',
            format: 'date-time'
          },
          updatedAt: {
            type: 'string',
            format: 'date-time'
          },
          archived: {
            type: 'boolean'
          },
          archivedAt: {
            type: 'string',
            format: 'date-time'
          },
          associations: {
            type: 'object',
            additionalProperties: {
              $ref: '#/components/schemas/CollectionResponseAssociatedId'
            }
          }
        },
        example: {
          properties: {
            city: 'Cambridge',
            createdate: '2019-10-30T03:30:17.883Z',
            domain: 'biglytics.net',
            hs_lastmodifieddate: '2019-12-07T16:50:06.678Z',
            industry: 'Technology',
            name: 'Biglytics',
            phone: '(877) 929-0687',
            state: 'Massachusetts'
          }
        }
      },
      Filter: {
        required: ['operator', 'propertyName'],
        type: 'object',
        properties: {
          value: {
            type: 'string'
          },
          highValue: {
            type: 'string'
          },
          values: {
            type: 'array',
            items: {
              type: 'string'
            }
          },
          propertyName: {
            type: 'string'
          },
          operator: {
            type: 'string',
            description: 'null',
            enum: ['EQ', 'NEQ', 'LT', 'LTE', 'GT', 'GTE', 'BETWEEN', 'IN', 'NOT_IN', 'HAS_PROPERTY', 'NOT_HAS_PROPERTY', 'CONTAINS_TOKEN', 'NOT_CONTAINS_TOKEN']
          }
        }
      },
      BatchInputSimplePublicObjectBatchInput: {
        required: ['inputs'],
        type: 'object',
        properties: {
          inputs: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/SimplePublicObjectBatchInput'
            }
          }
        }
      },
      BatchInputSimplePublicObjectInputForCreate: {
        required: ['inputs'],
        type: 'object',
        properties: {
          inputs: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/SimplePublicObjectInputForCreate'
            }
          }
        }
      },
      PreviousPage: {
        required: ['before'],
        type: 'object',
        properties: {
          before: {
            type: 'string'
          },
          link: {
            type: 'string'
          }
        }
      },
      CollectionResponseMultiAssociatedObjectWithLabelForwardPaging: {
        required: ['results'],
        type: 'object',
        properties: {
          results: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/MultiAssociatedObjectWithLabel'
            }
          },
          paging: {
            $ref: '#/components/schemas/ForwardPaging'
          }
        }
      },
      SimplePublicObjectBatchInput: {
        required: ['id', 'properties'],
        type: 'object',
        properties: {
          properties: {
            type: 'object',
            additionalProperties: {
              type: 'string'
            }
          },
          id: {
            type: 'string'
          }
        },
        example: {
          id: '1',
          properties: {
            city: 'Cambridge',
            domain: 'biglytics.net',
            industry: 'Technology',
            name: 'Biglytics',
            phone: '(877) 929-0687',
            state: 'Massachusetts'
          }
        }
      },
      AssociatedId: {
        required: ['id', 'type'],
        type: 'object',
        properties: {
          id: {
            type: 'string'
          },
          type: {
            type: 'string'
          }
        }
      },
      NextPage: {
        required: ['after'],
        type: 'object',
        properties: {
          after: {
            type: 'string'
          },
          link: {
            type: 'string'
          }
        },
        example: {
          after: 'NTI1Cg%3D%3D',
          link: '?after=NTI1Cg%3D%3D'
        }
      },
      SimplePublicObjectInputForCreate: {
        required: ['associations', 'properties'],
        type: 'object',
        properties: {
          properties: {
            type: 'object',
            additionalProperties: {
              type: 'string'
            },
            example: {}
          },
          associations: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/PublicAssociationsForObject'
            }
          }
        },
        example: {
          properties: {
            city: 'Cambridge',
            domain: 'biglytics.net',
            industry: 'Technology',
            name: 'Biglytics',
            phone: '(877) 929-0687',
            state: 'Massachusetts'
          },
          associations: [{
            to: {
              id: '101'
            },
            types: [{
              associationCategory: 'HUBSPOT_DEFINED',
              associationTypeId: 2
            }]
          }]
        }
      }
    },
    responses: {
      Error: {
        description: 'An error occurred.',
        content: {
          '*/*': {
            schema: {
              $ref: '#/components/schemas/Error'
            }
          }
        }
      }
    },
    securitySchemes: {
      oauth2: {
        type: 'oauth2',
        flows: {
          authorizationCode: {
            // eslint-disable-next-line hubspot-dev/prefer-hubspot-url-utils
            authorizationUrl: 'https://app.hubspot.com/oauth/authorize',
            tokenUrl: 'https://api.hubapi.com/oauth/v1/token',
            scopes: {
              'crm.objects.companies.read': ' ',
              'crm.objects.companies.write': ' '
            }
          }
        }
      },
      private_apps_legacy: {
        type: 'apiKey',
        name: 'private-app-legacy',
        in: 'header'
      },
      private_apps: {
        type: 'apiKey',
        name: 'private-app',
        in: 'header'
      }
    }
  },
  'x-hubspot-available-client-libraries': ['PHP', 'Node', 'Python', 'Ruby']
};