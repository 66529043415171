"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 44206971922;
const slug = exports.slug = 'getting-started/slack/code-of-conduct';
const title = exports.title = 'O Código de Conduta da Comunidade de Desenvolvedores da HubSpot no Slack';
const name = exports.name = 'Código de Conduta do Slack de Desenvolvedores';
const metaDescription = exports.metaDescription = 'O Slack de Desenvolvedores da HubSpot é uma comunidade onde os desenvolvedores mais engajados se reúnem para apoiar e colaborar uns com os outros na plataforma HubSpot.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Blog%20-%20Right%20CTA%20-%20Slack-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Colaborar no Slack do desenvolvedor';
const toc = exports.toc = [{
  "depth": 0,
  "id": "apresenta%C3%A7%C3%A3o",
  "label": "Apresentação",
  "parent": null
}, {
  "depth": 0,
  "id": "atualiza%C3%A7%C3%B5es-deste-c%C3%B3digo-de-conduta",
  "label": "Atualizações deste Código de Conduta",
  "parent": null
}, {
  "depth": 0,
  "id": "expectativas-de-suporte-da-hubspot",
  "label": "Expectativas de suporte da HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "comportamento-esperado",
  "label": "Comportamento esperado",
  "parent": null
}, {
  "depth": 1,
  "id": "seja-humilde",
  "label": "Seja humilde",
  "parent": "comportamento-esperado"
}, {
  "depth": 1,
  "id": "seja-emp%C3%A1tico",
  "label": "Seja empático",
  "parent": "comportamento-esperado"
}, {
  "depth": 1,
  "id": "seja-flex%C3%ADvel",
  "label": "Seja flexível",
  "parent": "comportamento-esperado"
}, {
  "depth": 1,
  "id": "seja-not%C3%A1vel",
  "label": "Seja notável",
  "parent": "comportamento-esperado"
}, {
  "depth": 1,
  "id": "seja-transparente",
  "label": "Seja transparente",
  "parent": "comportamento-esperado"
}, {
  "depth": 0,
  "id": "comportamento-inaceit%C3%A1vel",
  "label": "Comportamento inaceitável",
  "parent": null
}, {
  "depth": 1,
  "id": "amea%C3%A7as-de-viol%C3%AAncia",
  "label": "Ameaças de violência",
  "parent": "comportamento-inaceit%C3%A1vel"
}, {
  "depth": 1,
  "id": "cr%C3%ADticas-pessoais",
  "label": "Críticas pessoais",
  "parent": "comportamento-inaceit%C3%A1vel"
}, {
  "depth": 1,
  "id": "personifica%C3%A7%C3%A3o-de-funcion%C3%A1rios-da-hubspot",
  "label": "Personificação de funcionários da HubSpot",
  "parent": "comportamento-inaceit%C3%A1vel"
}, {
  "depth": 1,
  "id": "linguagem-pejorativa%2Fprejudicial-e-ass%C3%A9dio",
  "label": "Linguagem pejorativa/prejudicial e assédio",
  "parent": "comportamento-inaceit%C3%A1vel"
}, {
  "depth": 1,
  "id": "solicita%C3%A7%C3%A3o-indesejada-de-servi%C3%A7os",
  "label": "Solicitação indesejada de serviços",
  "parent": "comportamento-inaceit%C3%A1vel"
}, {
  "depth": 1,
  "id": "abuso-de-chamadas-de-voz%2Fv%C3%ADdeo",
  "label": "Abuso de chamadas de voz/vídeo",
  "parent": "comportamento-inaceit%C3%A1vel"
}, {
  "depth": 1,
  "id": "uso-n%C3%A3o-divulgado-de-ia-em-perguntas%2C-respostas-ou-conversas",
  "label": "Uso não divulgado de IA em perguntas, respostas ou conversas",
  "parent": "comportamento-inaceit%C3%A1vel"
}, {
  "depth": 1,
  "id": "envio-de-spam-nos-canais-e-para-os-usu%C3%A1rios",
  "label": "Envio de spam nos canais e para os usuários",
  "parent": "comportamento-inaceit%C3%A1vel"
}, {
  "depth": 1,
  "id": "influ%C3%AAncia-de-comportamento-inaceit%C3%A1vel",
  "label": "Influência de comportamento inaceitável",
  "parent": "comportamento-inaceit%C3%A1vel"
}, {
  "depth": 0,
  "id": "uso-do-slack-de-desenvolvedores-da-hubspot-para-conversas-privadas-de-equipe",
  "label": "Uso do Slack de Desenvolvedores da HubSpot para conversas privadas de equipe",
  "parent": null
}, {
  "depth": 0,
  "id": "chamadas-de-voz%2Fv%C3%ADdeo-%2B-compartilhamento-de-tela",
  "label": "Chamadas de voz/vídeo + compartilhamento de tela",
  "parent": null
}, {
  "depth": 0,
  "id": "uso-de-%E2%80%9Caplicativos%E2%80%9D-do-slack",
  "label": "Uso de “aplicativos” do Slack",
  "parent": null
}, {
  "depth": 0,
  "id": "agrupamento-de-t%C3%B3picos-encadeamento-de-mensagens",
  "label": "Agrupamento de tópicos (encadeamento de mensagens)",
  "parent": null
}, {
  "depth": 0,
  "id": "gifs-e-emojis",
  "label": "Gifs e emojis",
  "parent": null
}, {
  "depth": 0,
  "id": "pesquisas-e-coleta-de-opini%C3%A3o-entre-os-usu%C3%A1rios-do-slack-de-desenvolvedores",
  "label": "Pesquisas e coleta de opinião entre os usuários do Slack de Desenvolvedores",
  "parent": null
}, {
  "depth": 0,
  "id": "denunciar-viola%C3%A7%C3%B5es-do-c%C3%B3digo-de-conduta",
  "label": "Denunciar violações do Código de Conduta",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      a: "a",
      strong: "strong",
      h3: "h3",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Código de Conduta do Slack de Desenvolvedores"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Apresentação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O Slack de Desenvolvedores da HubSpot é uma comunidade onde os desenvolvedores se reúnem para apoiar e colaborar uns com os outros na plataforma HubSpot. Ao usar nosso Slack de Desenvolvedores, você concorda em seguir o Código de Conduta do Slack de Desenvolvedores (“Código de Conduta”), a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/acceptable-use",
        children: "Política de Uso aceitável da HubSpot"
      }), ", conforme aplicável, e a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://slack.com/acceptable-use-policy",
        children: "Política de Uso Aceitável do Slack"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Atualizações deste Código de Conduta"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A HubSpot reserva-se o direito de atualizar este Código de Conduta a qualquer momento. Comunicaremos qualquer atualização pelo canal #releases-and-updates no Slack de Desenvolvedores. No entanto, como nos baseamos em situações comuns e não podemos antecipar todas as situações possíveis, podemos precisar atualizar o Código de Conduta e agir em relação a violações não descritas explicitamente aqui, sem notificação prévia à comunidade."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A HubSpot reserva-se o direito de rebaixar ou desativar o Slack de Desenvolvedores a qualquer momento, mas se compromete a avisar a comunidade com antecedência."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Expectativas de suporte da HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O Slack de Desenvolvedores não é um canal de suporte oficial da HubSpot. Embora haja funcionários da HubSpot neste Slack que podem ajudar, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "evite mencionar ou enviar mensagens diretas a eles com perguntas"
      }), ". Se precisar de suporte direto da HubSpot, acesse ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://help.hubspot.com/",
        children: "help.hubspot.com"
      }), " ou os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/",
        children: "fóruns da comunidade"
      }), ". A HubSpot reserva-se o direito de aplicar, interpretar e estender essas diretrizes para o benefício da comunidade."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comportamento esperado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Na HubSpot, não temos páginas de políticas ou procedimentos extensos. Em vez disso, temos uma política simples: usar o bom senso e agir com o CORAÇÃO. Incentivamos nossas comunidades a também usarem o bom senso e compartilharem os seguintes valores fundamentais da HubSpot:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seja humilde"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nada é perfeito. Seja consciente e sempre aja com respeito."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seja empático"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tente entender os comentários de alguém e aja com compaixão e respeito por todos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seja flexível"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Seja curioso e esteja sempre pronto para mudar. Seja um eterno aprendiz."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seja notável"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nas palavras do sábio Bill S. Preston, Esquire: “Sejam excelentes uns com os outros”. Compartilhe seu conhecimento e não tenha medo de ser incrível."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seja transparente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Seja aberto e honesto com os outros, principalmente com você mesmo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para saber mais sobre nossos valores, confira o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/blog/tabid/6307/bid/34234/the-hubspot-culture-code-creating-a-company-we-love.aspx",
        children: "Código de Cultura da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comportamento inaceitável"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os seguintes comportamentos são considerados inaceitáveis e não serão tolerados por nenhum usuário no espaço de trabalho do Slack."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ameaças de violência"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ameaças de violência contra outro usuário não são aceitáveis. Isso inclui incentivar a violência contra um ou mais usuários, ou publicar/ameaçar publicar informações de identificação pessoal de outras pessoas (conhecido como “Doxxing”)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Críticas pessoais"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sempre haverá momentos em que duas partes terão opiniões divergentes. Embora possa ser frustrante, nunca deve se transformar em ataques pessoais. Criticar pessoas por suas opiniões, crenças pessoais, ideias ou sugestões não é aceitável. Mantenha sempre uma postura profissional e respeitosa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Personificação de funcionários da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Qualquer usuário que se passar por um funcionário da HubSpot (ou empregado pela HubSpot) será banido do espaço de trabalho do Slack. Esta é uma regra de tolerância zero."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Linguagem pejorativa/prejudicial e assédio"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Linguagem prejudicial contra usuários:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Plano de fundo"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Família"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Identidade ou expressão de gênero"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Orientação sexual"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Raça/etnia"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Características físicas (tamanho do corpo, forma, cicatrizes etc.)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Idade"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Idioma nativo"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Habilidades"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Nacionalidade"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Localização"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Religião"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Afiliação política"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Outros atributos pessoais"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "não é aceitável. Sempre use o bom senso."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Solicitação indesejada de serviços"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Não é aceitável enviar mensagens diretas aos usuários oferecendo seus serviços, a menos que o usuário tenha solicitado especificamente essa informação. Isso também inclui mensagens diretas sobre oportunidades de emprego ou projetos para autônomos. Criamos o canal #jobs em nosso espaço de trabalho do Slack onde esses tipos de mensagens podem ser publicados, e o canal #showcase para destacar qualquer trabalho realizado com orgulho na comunidade. Esses são os únicos dois canais onde qualquer forma de solicitação ou autopromoção é permitida."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abuso de chamadas de voz/vídeo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O Slack permite o uso de chamadas de voz/vídeo dentro da sua plataforma. Não é aceitável usar esse recurso para ligar diretamente para usuários sem permissão. Entendemos que erros podem acontecer e botões podem ser acionados por engano. Se isso ocorrer, seja profissional e envie uma mensagem de desculpas ao usuário."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Uso não divulgado de IA em perguntas, respostas ou conversas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você usar uma ferramenta de IA para gerar conteúdo no Slack de Desenvolvedores, deverá divulgar claramente o uso dessas ferramentas em todas as ocasiões. Você também deve estar atento às informações fornecidas por uma ferramenta de IA e não compartilhar nenhuma informação confidencial ou sensível do HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Envio de spam nos canais e para os usuários"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O Slack de Desenvolvedores inclui vários canais dedicados a diferentes funcionalidades da nossa plataforma e muitos usuários. Ao publicar uma mensagem, use o canal apropriado. Não é aceitável simplesmente copiar/colar sua mensagem em vários canais para obter mais visibilidade. Também não é aceitável publicar a mesma mensagem ou mensagens semelhantes com frequência no mesmo canal e/ou enviar spam diretamente aos usuários."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Influência de comportamento inaceitável"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "É inaceitável influenciar qualquer usuário a se envolver nos comportamentos inaceitáveis mencionados acima."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uso do Slack de Desenvolvedores da HubSpot para conversas privadas de equipe"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao ingressar no Slack de Desenvolvedores da HubSpot, você se compromete a contribuir para a grande comunidade de desenvolvedores da HubSpot. Não há restrições quanto aos tópicos de conversa (desde que estejam de acordo com este Código de Conduta), mas solicitamos que você não utilize o Slack de Desenvolvedores para realizar negócios privados da empresa através de mensagens diretas ou outros meios. Se tal conduta for descoberta, reservamo-nos o direito de remover você e sua equipe do Slack de Desenvolvedores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Chamadas de voz/vídeo + compartilhamento de tela"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O Slack permite aos usuários usarem chamadas de voz/vídeo com a capacidade adicional de compartilhamento de tela. Ao usar esses recursos, seja consciente e respeite os outros. Use bom senso ao compartilhar sua tela e esteja atento a outras janelas que possam estar abertas em seu computador contendo informações confidenciais ou pessoais."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uso de “aplicativos” do Slack"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O Slack permite adicionar aplicativos ao espaço de trabalho para ajudar a expandir suas funcionalidades. Não permitimos que usuários instalem aplicativos por conta própria no espaço de trabalho do Slack de Desenvolvedores. Todas as instalações de aplicativos serão feitas pela HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Agrupamento de tópicos (encadeamento de mensagens)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um dos grandes recursos do Slack é a capacidade de encadear comentários. Para ajudar a manter as conversas organizadas e facilmente pesquisáveis/escaneáveis, recomendamos usar esse recurso. Ele ajuda a manter os canais principais limpos e agrupa o tópico das conversas em um só lugar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gifs e emojis"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao usar GIFs nos canais ou comentários encadeados, esteja atento ao conteúdo/contexto do GIF. Da mesma forma, o Slack permite usar emojis para expressar emoções em relação aos comentários. Esteja atento aos emojis que você usa ao se comunicar com outras pessoas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pesquisas e coleta de opinião entre os usuários do Slack de Desenvolvedores"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O espaço de trabalho do Slack de Desenvolvedores deve ser usado para conversas relacionadas ao desenvolvimento na web e na plataforma HubSpot. É permitido criar pesquisas, e fornecemos o aplicativo Polly no nosso espaço de trabalho para uso por qualquer pessoa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao usar o Polly, exigimos o seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A pesquisa deve estar relacionada ao desenvolvimento na web (como bibliotecas CSS ou JavaScript) ou na plataforma HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "\"Mostrar respostas\" deve sempre permanecer marcado."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O seguinte não é permitido ao pesquisar indivíduos no Slack de Desenvolvedores:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Solicitação de informações privadas, incluindo informações demográficas, médicas ou outras informações pessoais dos usuários.*"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Links para pesquisas hospedadas em sites de terceiros (como formulários do Google, formulários de pesquisas ou sites pessoais).*"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Envia mensagens diretamente aos usuários solicitando que preencham pesquisas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "* = Os funcionários da HubSpot têm o direito de pesquisar o Slack de Desenvolvedores da HubSpot em busca de melhorias no produto HubSpot. Os funcionários da HubSpot farão um esforço para deixar claro que sua pesquisa é uma pesquisa oficial da HubSpot se estiver hospedada em outro lugar."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Denunciar violações do Código de Conduta"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para relatar uma violação deste Código de Conduta, ou qualquer situação que tenha causado desconforto a você ou a outros usuários na comunidade de desenvolvedores da HubSpot, entre em contato diretamente com um de nossos administradores. Todos os administradores atuais serão indicados em um post fixado no canal #general."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se preferir relatar a violação anonimamente ou não quiser enviar uma mensagem direta para um administrador, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://form.asana.com/",
        children: "preencha este formulário"
      }), " e seremos notificados imediatamente."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}