"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 178555761496;
const slug = exports.slug = 'guides/api/crm/embed';
const title = exports.title = 'CRM API | HubSpot CRM Embed';
const name = exports.name = 'vNext Docs DP - CRM Embed';
const metaDescription = exports.metaDescription = 'Learn how to embed a HubSpot view such as a record timeline, property history, or meeting link in a third-party platform.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "supported-hubspot-views-and-tools",
  "label": "Supported HubSpot views and tools",
  "parent": null
}, {
  "depth": 0,
  "id": "linking-to-a-specific-view-in-your-hubspot-account",
  "label": "Linking to a specific view in your HubSpot account",
  "parent": null
}, {
  "depth": 0,
  "id": "linking-to-a-specific-view-in-your-hubspot-account",
  "label": "Linking to a specific view in your HubSpot account",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot CRM Embed"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The HubSpot CRM Embed feature allows you to display interactive HubSpot interfaces inside your app. You can directly embed views from specific tools from your account, including the object timeline, along with tabs with the record's enrollment history and management for a workflow, property management, and a view to schedule and manage meetings."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " this functionality is distinct from the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/products/content/embedabble-content-blocks",
          children: "content embed"
        }), " feature and and the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/contacts-timeline-embed",
          children: "content timeline embed"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supported HubSpot views and tools"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following tools are available to embed within your app:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Object timeline:"
        }), " review activities related to an object record, including any upcoming meetings or tasks, along with a fully interactive sidebar. You can apply activity filters and access association cards within the embedded view (e.g., related contacts, companies, deals, etc.)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Workflows tab:"
        }), " track workflow enrollment history, enroll records into workflows, or search workflows by name."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sequences tab:"
        }), " if you're embedding the view for a contact, you can manage sequences, enroll contacts, or track progress through a sequence."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Properties tab:"
        }), " search for and filter properties, view property history, and hide blank fields you're not actively using."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Meetings tab:"
        }), " schedule meetings, or manage and review details of upcoming meetings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Company & Deal Functionality:"
        }), " if you're embedding a view for a company or deal, you can review the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Target Accounts"
        }), " tab for metrics such as emails and meetings, along with other aggregate metrics."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Linking to a specific view in your HubSpot account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To link to a specific view in your HubSpot account, you'll need to customize an embed URL based on the associated record and tool you want to appear:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Each URL will have the following structure:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://app.hubspot.com/embed/{hubId}/{objectType}/{recordId}/{view}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "hubId:"
        }), " the Hub ID of the HubSpot account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectType:"
        }), " the object type you want to link to (e.g., a contact, company, deal, etc.)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "recordId:"
        }), " the instance of the object you want to link to."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "view:"
        }), " the specific tool or view you want to link to. The available view types you can specify here include timeline, workflows, sequences,"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to embed a view for the contact timeline when the contact has an ID of 251, and the Hub ID was 12345, the resulting embed URL would be:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://app.hubspot.com/embed/12345/0-1/251/timeline"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A user will need to be logged into their HubSpot account to view the embedded view. If a user isn't authenticated, they'll be prompted to log in to their account."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/hubspot-embed-view-not-logged-in.png",
        alt: "hubspot-embed-view-not-logged-in"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can follow the steps in the section below to use the embed sandbox to explore and test how different views from your account would appear in an embedded window."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Linking to a specific view in your HubSpot account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot provides an embed sandbox environment you can use to find specific records in your account and preview how they would appear in an embedded window."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/hubspot-embed-sandbox-ui",
          children: "embed sandbox environment"
        }), " for your account. If you're prompted with an account selection page, select the specific HubSpot account with the data you want to embed in your app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Object Type ID"
        }), " dropdown menu to select an object type, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Object ID"
        }), " dropdown menu and select a specific instance of that object."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the embedded preview, you can interactive with the object timeline, or click the tabs at the top to use other tools in the context of that record."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You can click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copy"
        }), " to copy the associated ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Embed URL"
        }), " to the clipboard, where you can then embed it within your app. You'll need to append the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "view"
        }), " to the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Embed URL"
        }), " to ensure that URL loads correctly (e.g., to link directly to the sequences associated with a specific contact, the resulting URL would be: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/embed/12345/0-1/251/sequences"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/embedded-hubspot-view-example-snippets.png",
        alt: "embedded-hubspot-view-example-snippets"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}