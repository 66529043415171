"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29837710048;
const slug = exports.slug = 'guides/cms/setup/getting-started-with-local-development';
const title = exports.title = 'Commencer avec le développement local';
const name = exports.name = 'Commencer avec le développement local';
const metaDescription = exports.metaDescription = 'Démarrer avec le développement local sur HubSpot CMS. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/local_development.png';
const featuredImageAltText = exports.featuredImageAltText = 'Commencer avec le développement local';
const toc = exports.toc = [{
  "depth": 0,
  "id": "installer-les-d%C3%A9pendances",
  "label": "Installer les dépendances",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-cr%C3%A9er-un-r%C3%A9pertoire-de-travail",
  "label": "1. Créer un répertoire de travail",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-configurer-les-outils-de-d%C3%A9veloppement-local",
  "label": "2. Configurer les outils de développement local",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-cr%C3%A9er-une-ressource-%C3%A0-r%C3%A9cup%C3%A9rer-dans-hubspot",
  "label": "3. Créer une ressource à récupérer dans HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-r%C3%A9cup%C3%A9rer-le-module-dans-votre-environnement-local",
  "label": "4. Récupérer le module dans votre environnement local",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-apporter-des-modifications-et-t%C3%A9l%C3%A9charger",
  "label": "5. Apporter des modifications et télécharger",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-ex%C3%A9cuter-une-commande-watch-pour-t%C3%A9l%C3%A9charger-automatiquement-les-modifications",
  "label": "6. Exécuter une commande watch pour télécharger automatiquement les modifications",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A9tapes-suivantes",
  "label": "Étapes suivantes",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      h2: "h2",
      ol: "ol",
      strong: "strong",
      br: "br",
      em: "em",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Commencer avec le développement local"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "interface de ligne de commande"
      }), " (ILC) de HubSpot connecte votre environnement local à HubSpot, ce qui signifie que vous aurez des copies locales de vos ressources web HubSpot. Cela vous permet d'utiliser le contrôle des versions, votre éditeur de texte favori et diverses technologies de développement web sur CMS Hub."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce guide s'adresse aux personnes qui se sont déjà familiarisées avec le CMS, mais qui veulent apprendre à travailler avec l'ILC. Si vous êtes complètement novice dans le développement du CMS Hub de HubSpot, nous vous conseillons de consulter le guide de démarrage rapide."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "Démarrage rapide du développement CMS Hub"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans ce tutoriel, vous découvrirez :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Comment installer l'ILC et la connecter à votre compte HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Comment récupérer un module à partir de votre compte HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Comment mettre à jour le module localement, puis télécharger vos modifications."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Comment utiliser la commande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        }), " pour continuer à télécharger les modifications enregistrées."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour plus de commandes et de formats de fichiers locaux, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "référence sur les outils de développement local"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Installer les dépendances"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour développer sur HubSpot localement, vous devrez :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Installer ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: "Node.js"
        }), ", un environnement d'exécution JavaScript qui permet d'utiliser les outils locaux. Les versions 16 ou supérieures de Node sont prises en charge, mais nous recommandons la version LTS (long-term support)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Exécuter ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install -g @hubspot/cli"
        }), " dans votre ligne de commande pour installer l'ILC de HubSpot de manière globale. Pour installer les outils dans votre répertoire actuel uniquement, exécutez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install @hubspot/cli"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous préférez, vous pouvez également utiliser ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://classic.yarnpkg.com/en/docs/install",
        children: "Yarn"
      }), ". Si vous utilisez Yarn, les commandes sont exécutées avec le préfixe ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yarn"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vous obtenez une erreur EACCES lors de l'installation ?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Voir ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.npmjs.com/resolving-eacces-permissions-errors-when-installing-packages-globally",
          children: "NPM Résoudre les erreurs de permissions EACCESS lors de l'installation globale de paquets"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Créer un répertoire de travail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Créez un dossier pour le travail que vous effectuerez ci-dessous. Pour les besoins de ce tutoriel, nommez le dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "local-dev-tutorial"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez le faire localement en exécutant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mkdir local-dev-tutorial"
      }), " dans la ligne de commande, ce qui créera le répertoire. Ensuite, exécutez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cd local-dev-tutorial"
      }), " pour accéder à ce répertoire."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Configurer les outils de développement local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puis, exécutez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs init"
      }), " pour connecter les outils à votre compte HubSpot. Cette commande vous guidera à travers les étapes ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Pour connecter l'ILC à un compte HubSpot, vous devez copier la clé d'accès personnelle du compte. Lorsque vous y êtes invité, appuyez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Entrée"
          }), " pour ouvrir hubspot.com sur la ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/l/personal-access-key",
            children: "page de clé d'accès personnelle du compte"
          }), ". Si vous avez plusieurs comptes, vous serez invité dans le navigateur à sélectionner un compte."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Sur la page des clés d'accès personnelles, vous pouvez générer une nouvelle clé d'accès personnelle ou copier la valeur de clé existante si elle existe déjà."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si vous créez une clé pour la première fois, sélectionnez les domaines auxquels la clé a accès. Vous devrez sélectionner au moins ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Gestionnaire de conception"
            }), " pour interagir avec les outils de conception du compte."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Après avoir sélectionné les autorisations de la clé, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Générer une clé d'accès personnelle"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Une fois que la clé a été générée, copiez sa valeur en cliquant d'abord sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Afficher"
          }), " sous la clé, puis sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copier"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Collez la clé dans la ligne de commande, puis appuyez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Entrée"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Ensuite, saisissez un nom pour le compte. Ce nom sera vu et utilisé uniquement par vous lors de l'exécution des commandes. Par exemple, vous pouvez utiliser « sandbox » si vous utilisez un développeur sandbox ou « entreprise.com » si vous utilisez un compte client complet. Ce nom ne doit contenir aucun espace."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Appuyez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Entrée"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que vous avez terminé ce flux ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "init"
      }), ", un message s'affiche confirmant qu'un fichier de configuration,", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: " hubspot.config.yml"
      }), ", a été créé avec succès dans votre répertoire actuel."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Votre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " ressemblera à peu près à ceci :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: mainProd\nportals:\n  - name: mainProd\n    portalId: 123456\n    defaultMode: publish\n    authType: personalaccesskey\n    auth:\n      tokenInfo:\n        accessToken: >-\n          {accessTokenValue}\n\n\n        expiresAt: '2023-06-27T19:45:58.557Z'\n    personalAccessKey: >-\n      {personalAccessKeyValue}\n\n\n    sandboxAccountType: null\n    parentAccountId: null\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultPortal"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : le compte avec lequel vous interagissez par défaut lors de l'exécution des commandes d'ILC. Pour interagir avec un compte authentifié qui n'est pas défini par défaut, vous pouvez ajouter un indicateur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account="
            }), " à la commande, suivi du nom ou de l'ID du compte. Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account=12345"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account=mainProd"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : sous ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "portals"
            }), ", vous trouverez une entrée pour chaque compte connecté. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " indique le nom du compte. Vous pouvez utiliser ce nom lors de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/local-development-cli#set-default-account",
              children: "définition d'un nouveau compte par défaut"
            }), " ou de la spécification d'un compte avec l'indicateur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : l''ID du compte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultMode"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : lors du téléchargement vers le compte, il définit l'état par défaut pour charger le contenu. Peut être soit ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "draft"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "authType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatoire"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : la forme d'authentification utilisée pour authentifier le compte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "sandboxAccountType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : si le compte est un compte sandbox, indique l'ID du compte de production parent."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "parentAccountId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Facultatif"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "parentAccountId"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        }), " prend en charge plusieurs comptes. Pour authentifier plus de comptes, exécutez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#auth",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs auth"
          })
        }), " et suivez les instructions."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Créer une ressource à récupérer dans HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans le cadre de ce tutoriel, vous allez d'abord créer une nouvelle ressource dans HubSpot afin de pouvoir la récupérer dans votre environnement local à l'aide de l'interface de ligne de commande."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Fichiers et modèles"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Outils de conception"
          }), ". Cela ouvrira le gestionnaire de conception, où vous pouvez accéder aux fichiers que vous téléchargez à l'aide de l'interface de ligne de commande. Cette arborescence de fichiers et de dossiers est également appelée le ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/overview#developer-file-system",
            children: "système de fichiers du développeur"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la barre latérale gauche du gestionnaire de conception, sélectionnez le dossier ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "@hubspot"
          }), " pour afficher les ressources par défaut de HubSpot, telles que les thèmes et les modules."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/design-manager-hubspot-folder.png",
            alt: "design-manager-hubspot-folder"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la barre latérale gauche, faites défiler vers le bas et cliquez avec le bouton droit sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "l'icône"
          }), " du module, puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Cloner le module"
          }), ". Le module sera cloné à la racine du système de fichiers du développeur et votre nouvelle copie de module sera ouverte à droite."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/default-icon-module-cloned.png",
            alt: "default-icon-module-cloned"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En haut de la barre latérale gauche, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Actions"
          }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copier le chemin"
          }), ". Cela copiera le chemin relatif vers le module dans le système de fichiers du développeur, que vous utiliserez à l'étape suivante pour récupérer le module localement."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-actions-copy-path.png",
            alt: "module-actions-copy-path"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Récupérer le module dans votre environnement local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois le module cloné, vous allez maintenant utiliser la commande ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetch"
        })
      }), " pour l'introduire dans votre environnement local."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le terminal, exécutez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch '/icon copy.module'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch '/icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le module sera téléchargé sous la forme d'un répertoire contenant cinq fichiers :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "fields.json"
          }), " : contient le code des différents champs du module. Dans ce cas, cela inclut le champ d'icône, deux champs d'option d'accessibilité et un ensemble de champs de style. Vous pouvez voir ces champs dans la barre latérale droite de l'éditeur de module dans HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "meta.json"
          }), " : contient les informations de base du module, telles que son libellé, son ID et les types de modèles dans lesquels il peut être utilisé. Ces informations seront affichées dans la barre latérale droite de l'éditeur de module."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.css"
          }), " : contient le CSS du module, que vous pouvez également voir dans le volet CSS de l'éditeur de module dans HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.html"
          }), " : contient le code HTML du module, que vous pouvez également voir dans le volet HubL + HTML de l'éditeur de module dans HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.js"
          }), " : contient le code JavaScript du module, que vous pouvez également voir dans le volet JS de l'éditeur de module dans HubSpot."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-directory-local.png",
            alt: "module-directory-local"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ensuite, vous allez mettre à jour le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " du module, puis le télécharger sur votre compte et afficher la modification dans HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Apporter des modifications et télécharger"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tout d'abord, apportez une modification au module :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans votre éditeur de code préféré, ouvrez le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "meta.json"
          }), " du module."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-meta-json-file-open.png",
            alt: "module-meta-json-file-open"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Mettez à jour le champ ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "label"
          }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"Icon\""
          }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"CMS tutorial module\""
          }), ". Puis, enregistrez le fichier."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-meta-json-file-label-updated.png",
            alt: "module-meta-json-file-label-updated"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Avant d'exécuter la commande suivante, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload"
      }), ", décomposons la commande et les arguments qu'elle prend. Cette commande prend deux arguments : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload <src> <dest>"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "src"
        }), " : le chemin relatif du dossier source que vous téléchargez depuis votre environnement local."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "dest"
        }), " : le chemin du répertoire de destination dans HubSpot, local vers la racine du système de fichiers du développeur. Vous pouvez créer un nouveau répertoire dans le compte en spécifiant le nom du répertoire, tel que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload <src> /new-directory"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Dans cet esprit, puisque vous téléchargez vers la racine du système de fichiers du développeur, exécutez la commande suivante :"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "hs upload 'icon copy.module' 'icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Une fois que l'ILC a confirmé que le module a été téléchargé avec succès, actualisez le gestionnaire de conception pour afficher votre modification dans HubSpot. Vous devriez maintenant voir que le champ ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Libellé"
        }), " affiche votre nouvelle valeur."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-label-updated.png",
        alt: "module-label-updated"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Exécuter une commande watch pour télécharger automatiquement les modifications"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Maintenant que vous avez utilisé la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), " pour exécuter un téléchargement unique de vos fichiers locaux, vous allez maintenant utiliser la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " pour télécharger en continu les modifications enregistrées. Cette commande prend les mêmes arguments que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), ", vous pouvez donc indiquer les mêmes éléments ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<src>"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<dest>"
      }), " que ci-dessus."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Exécuter ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch 'icon copy.module' 'icon copy.module'"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "hs watch 'icon copy.module' 'icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque la commande watch est exécutée, les modifications enregistrées sont automatiquement téléchargées sur le compte HubSpot. À titre de démonstration, apportez les modifications locales suivantes au module :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "meta.json"
        }), ", mettez à jour le champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "host_template_types"
        }), " pour supprimer ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"BLOG_LISTING\""
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"BLOG_POST\""
        }), " afin que le module ne soit disponible que pour les pages :", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"host_template_types\"``:[\"PAGE\"]"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Puis, enregistrez le fichier. Ainsi, l'ILC téléchargera automatiquement le fichier sur HubSpot."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Une fois le fichier téléchargé, actualisez le gestionnaire de conception dans HubSpot pour afficher votre modification. La section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Types de modèles"
        }), " de la barre latérale droite ne doit désormais inclure que ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Page"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-editor-template-type.png",
        alt: "module-editor-template-type"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour mettre fin à la commande regarder, appuyez sur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Control + C"
        }), ". Il est important de noter que vous ne pourrez pas exécuter d'autres commandes dans la même fenêtre de terminal que celle dans laquelle la commande watch est en cours d'exécution. Pour exécuter d'autres commandes pendant l'exécution de watch, vous devez plutôt ouvrir une autre fenêtre de terminal et y exécuter vos commandes."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Étapes suivantes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Maintenant que vous avez découvert comment utiliser les commandes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fetch"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), ", vous pouvez consulter le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "guide de référence complet des commandes d'ILC"
      }), " pour découvrir les autres possibilités de l'ILC."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il est également recommandé de consulter les tutoriels suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Créer un workflow de développement efficace"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/github-integration",
          children: "Configurer une intégration continue avec GitHub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Premiers pas avec les modules personnalisés"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Premiers pas avec les thèmes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "Démarrer avec les zones de glisser-déposer"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}