"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611103;
const slug = exports.slug = 'guides/cms/content/global-content';
const title = exports.title = 'Globaler Content';
const name = exports.name = 'Globaler Content';
const metaDescription = exports.metaDescription = 'Globaler Content ist Inhalt, der in allen Vorlagen wiederverwendet werden kann, um konsistente Informationen wie Header und Footer, Seitenleisten, Logos oder andere Komponenten zu erstellen.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/5Cdocs/global-content/global-content-editor-4.png';
const featuredImageAltText = exports.featuredImageAltText = 'Editor für globalen Content';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%C3%BCberblick",
  "label": "Überblick",
  "parent": null
}, {
  "depth": 1,
  "id": "globale-teilvorlagen-vs.-globale-module",
  "label": "Globale Teilvorlagen vs. Globale Module",
  "parent": "%C3%BCberblick"
}, {
  "depth": 0,
  "id": "globale-teilvorlagen",
  "label": "Globale Teilvorlagen",
  "parent": null
}, {
  "depth": 1,
  "id": "eine-globale-teilvorlage-erstellen",
  "label": "Eine globale Teilvorlage erstellen",
  "parent": "globale-teilvorlagen"
}, {
  "depth": 1,
  "id": "drag-%26-drop-bereiche-zu-globalen-teilvon-hinzuf%C3%BCgen",
  "label": "Drag-&-Drop-Bereiche zu globalen Teilvon hinzufügen",
  "parent": "globale-teilvorlagen"
}, {
  "depth": 1,
  "id": "eine-teilvorlage-in-ihrer-vorhandenen-vorlage-ber%C3%BCcksichtigen",
  "label": "Eine Teilvorlage in Ihrer vorhandenen Vorlage berücksichtigen",
  "parent": "globale-teilvorlagen"
}, {
  "depth": 0,
  "id": "globale-module",
  "label": "Globale Module",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      pre: "pre",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Globaler Content"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Globaler Content ist Inhalt, der über Teile einer Website verteilt ist. Häufige Beispiele sind Website-Header, -Footer und Seitenleisten. Als Entwickler legen Sie fest, welche Komponenten global sein sollen, indem sie globale Teilvorlagen verwenden oder Module global machen. HubSpot stellt Content-Autoren eine andere Bearbeitungsumgebung bereit, die es ihnen erleichtert, den globalen Content zu bearbeiten und die Änderungen vor der Veröffentlichung seitenübergreifend in der Vorschau anzuzeigen. Um mehr darüber zu erfahren, wie Sie Ihren globalen Content bearbeiten können, lesen Sie bitte den Artikel über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
        children: "Verwendung von globalem Content in mehreren Vorlagen"
      }), " in der HubSpot-Wissensdatenbank."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/global-content/global-content-editor-4.png",
        alt: "Editor für globalen Content"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Überblick"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Globaler Content wird am besten zur Anzeige der gleichen Informationen auf mehreren Seiten verwendet. Zum Beispiel den Header und Footer Ihrer Website, z. B. den Header oben auf dieser Seite."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubspot-developers-header.png",
        alt: "hubspot-developers-header"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden finden Sie einige weitere Beispiele für Bereiche, in denen Sie globalen Content verwenden können:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sekundäre Navigation für verschiedene Abschnitte Ihrer Website"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Copyright-Footer (oder Unterfußzeilen)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Seitenleisten für Blogbeiträge (zur Anzeige der letzten Beiträge, Autorenlisten und mehr)"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Da globaler Content an mehreren Stellen auf einer Website verwendet wird, ist es umso wichtiger, dass Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Ihre globalen Teilvorlagen und Module barrierefrei gestalten und entwickeln"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Globale Teilvorlagen vs. Globale Module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Als Entwickler können Sie globale Teilvorlagen und globale Module erstellen, mit einigen wesentlichen Unterschieden zwischen ihnen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Globale Teilvorlagen sind eine Art von Vorlage, die mit HTML & HubL erstellt wurde und auf Ihrer gesamten Website wiederverwendet werden kann. Die gebräuchlichsten Arten von globalen Teilverlagen sind Header, Seitenleisten- und Footer von Websites."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Globale Module sind Module, die aus einzelnen oder mehreren Inhalten bestehen, die auf mehreren Seiten Ihrer Website verwendet werden können. Einige häufige Arten von globalen Modulen können Elemente wie Blog-Abonnementformulare, sekundäre Navigationselemente und Calls-to-Action sein."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie sollten es vermeiden, globale Module in globale Teilvorlagen aufzunehmen, da dies zu einem negativen Content-Bearbeitungserlebnis führen kann."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Alle Module und Felder in Ihren globalen Teilvorlagen und globalen Modulen lassen sich problemlos im ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
          children: "Editor für globalen Content"
        }), " bearbeiten."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Globale Teilvorlagen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine globale Teilvorlage erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine globale Teilvorlage ist ein Typ von Vorlage, den Sie lokal über das HubSpot CLI erstellen können, indem Sie den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#create",
        children: "create-Befehl"
      }), " wie unten gezeigt verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template <partial-file-name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie aufgefordert werden, einen Typ von Vorlage auszuwählen, wählen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global partial"
      }), " aus."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dadurch wird Ihre Vorlage im gewünschten Verzeichnis erstellt und die HTML-Datei enthält die folgenden Annotationen zur Vorlage."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: global_partial\n  label: Page Header\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Beispiel für eine Teilvorlage mit globalen Content bietet ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/partials/header.html",
        children: "unsere Boilerplate bei GitHub."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Drag-&-Drop-Bereiche zu globalen Teilvon hinzufügen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Drag-&-Drop-Funktionen für Inhalte in Ihren Teilvorlage mit globalen Content aktivieren, indem Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "-Tags hinzufügen, ähnlich wie bei der Aktivierung in Seitenvorlagen. Weitere Informationen finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "Dokumentation zum Drag-&-Drop-Bereich"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine Teilvorlage in Ihrer vorhandenen Vorlage berücksichtigen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Teilvorlage mit globalen Content in eine Ihrer bestehenden Vorlagen einzufügen, verwenden Sie das HubL-Tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global_partial"
      }), " und verweisen dabei auf den Pfad zu Ihrer Teilvorlage. Nachfolgend finden Sie ein Beispiel aus ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html#L21",
        children: "der CMS-Boilerplate"
      }), ", das dieses Tag verwendet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% global_partial path=\"../partials/header.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn globale Teilvorlagen ausgegeben werden, enthalten sie ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), "-Wrapping um die globale Teilvorlage. Dies wird vom Seiten-Editor verwendet, um zu erkennen, dass es sich um eine globale Teilvorlage handelt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div\n  data-global-resource-path=\"cms-theme-boilerplate/templates/partials/header.html\"\n>\n  <!-- Your header.html code is output here -->\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Verwenden Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "global_partial"
        }), " nicht innerhalb des ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), " einer Vorlage. Dies würde zu ungültigem HTML führen."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["In den meisten Situationen, in denen Sie eine globale Teilvorlage im Header verwenden sollten, ist es möglicherweise sinnvoller, stattdessen ein globales Modul mit einem ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-head",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{%require_head%}"
          })
        }), " zu verwenden, um benutzerdefinierten Code in den Header einzufügen und trotzdem Modulfelder bereitzustellen."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Globale Module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können globale Module wie jedes andere Modul mithilfe des CLI erstellen, indem Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create"
      }), "-Befehl wie unten gezeigt ausführen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create module <module_name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein globales Modul unterscheidet sich durch das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global"
      }), "-Flag in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "-Datei des Moduls."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json file\n{\n  \"css_assets\": [],\n  \"external_js\": [],\n  \"global\": true,\n  \"help_text\": \"\",\n  \"host_template_types\": [\"PAGE\"],\n  \"js_assets\": [],\n  \"other_assets\": [],\n  \"smart_type\": \"NOT_SMART\",\n  \"tags\": [],\n  \"is_available_for_new_content\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/create-and-edit-modules",
        children: "globale Module in HubSpot mithilfe des Design-Managers erstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Erfahren Sie mehr über das Arbeiten mit Modulen in den folgenden verwandten Ressourcen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Überblick über Module"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Standardmodule von HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Konfigurieren von Modulen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Verwenden von Modulen in Vorlagen"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}