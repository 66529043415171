import I18n from 'I18n';
export const SharedLegacyDocsAlertText = () => I18n.text('developer-docs-shared-components.legacyHeaderContent.sharedLegacyDocsAlertText');
export const SharedDeprecatedDocsAlertText = () => I18n.text('developer-docs-shared-components.legacyHeaderContent.sharedDeprecatedDocsAlertText');
const legacyDocsIntroContent = () => ({
  alert: SharedLegacyDocsAlertText(),
  alertType: 'warning'
});
export const legacyVersionInfo = {
  '/app-management/oauth': [{
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      // FIXME: Related documents do not render - currently requires an intro and or useCase to render
      relatedDocuments: {
        title: I18n.text('developer-docs-shared-components.legacyHeaderContent.oauth.title'),
        href: '/guides/api/oauth/tokens'
      }
    }),
    versionName: 'v1',
    legacyUrl: '/methods/oauth/v1',
    isDefaultSelected: true
  }],
  '/crm/associations/association-details': [{
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.associationDetails.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.associationDetails.useCase')
    }),
    versionName: 'v1'
  }],
  '/cms/blogs/blog-authors': [{
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.blogAuthors.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.blogAuthors.useCase')
    }),
    versionName: 'v3-legacy'
  }],
  '/cms/blogs/blog-topics': [{
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.blogTopics.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.blogTopics.useCase')
    }),
    versionName: 'v3',
    isDefaultSelected: true
  }],
  '/cms/blogs/blog-comments': [{
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.blogComments.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.blogComments.useCase')
    }),
    versionName: 'v3',
    isDefaultSelected: true
  }],
  '/cms/url-redirects': [{
    versionName: 'v3-legacy',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.urlRedirects.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.urlRedirects.useCase')
    })
  }],
  '/cms/blogs/blog-posts': [{
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.blogPosts.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.blogPosts.useCase')
    })
  }],
  '/cms/domains': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsDomains.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsDomains.useCase')
    })
  }],
  '/crm/owners': [{
    versionName: 'v2',
    introContent: legacyDocsIntroContent
  }],
  '/crm/contacts-timeline-embed': [{
    versionName: 'v1',
    introContent: legacyDocsIntroContent,
    isDefaultSelected: true
  }],
  '/crm/engagements/engagement-details': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmEngagementDetails.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmEngagementDetails.useCase'),
      relatedDocuments: {
        href: '/guides/api/crm/engagements/engagement-details',
        title: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmEngagementDetails.relatedDocumentsTitle')
      }
    }),
    isDefaultSelected: true
  }],
  '/crm/extensions/extension-details': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      relatedDocuments: {
        href: '/guides/apps/extensions/overview',
        title: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmExtensionDetails.relatedDocumentsTitle')
      }
    }),
    isDefaultSelected: true
  }],
  '/crm/extensions/timeline': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmExtensionsTimeline.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmExtensionsTimeline.useCase')
    })
  }],
  '/crm/lists': [{
    versionName: 'v1-contacts',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmLists.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmLists.useCase')
    })
  }],
  '/crm/objects/companies': [{
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmCompanies.useCase'),
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmCompanies.intro')
    })
  }, {
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmCompanies.useCase'),
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmCompanies.intro')
    })
  }],
  '/crm/objects/contacts': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmContacts.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmContacts.useCase')
    })
  }],
  '/crm/objects/deals': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmDeals.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmDeals.useCase')
    })
  }],
  '/crm/objects/line-items': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmLineItems.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmLineItems.useCase')
    })
  }],
  '/crm/objects/products': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmProducts.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmProducts.useCase')
    })
  }],
  '/crm/objects/tickets': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmTickets.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmTickets.useCase')
    })
  }],
  '/crm/pipelines': [{
    versionName: 'v1',
    introContent: legacyDocsIntroContent
  }],
  '/crm/properties': [{
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmProperties.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.crmProperties.useCase')
    })
  }, {
    versionName: 'v1-deals',
    introContent: legacyDocsIntroContent
  }, {
    versionName: 'v1-contacts',
    introContent: legacyDocsIntroContent
  }, {
    versionName: 'v1-companies',
    introContent: legacyDocsIntroContent
  }],
  '/cms/hubdb': [{
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsHubdb.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsHubdb.useCase')
    })
  }],
  '/cms/site-search': [{
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsSiteSearch.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsSiteSearch.useCase')
    })
  }],
  '/cms/pages': [{
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsPages.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsPages.useCase')
    })
  }],
  '/files': [{
    versionName: 'v3-legacy',
    legacyUrl: '/files/v3-legacy',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.files.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.files.useCase')
    })
  }, {
    legacyUrl: '/files/v2',
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.files.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.files.useCase')
    })
  }],
  '/marketing/forms': [{
    versionName: 'v3-legacy',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.marketingForms.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.marketingForms.useCase')
    })
  }, {
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.marketingForms.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.marketingForms.useCase')
    })
  }, {
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.marketingForms.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.marketingForms.useCase')
    })
  }],
  '/analytics-and-events/email-analytics': [{
    versionName: 'v1',
    isDefaultSelected: true,
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.emailAnalytics.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.emailAnalytics.useCase'),
      relatedDocuments: {
        title: 'Email events API overview',
        href: '/guides/api/analytics-and-events/email-analytics'
      }
    })
  }],
  '/marketing/emails/marketing-emails': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.marketingEmails.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.marketingEmails.useCase'),
      relatedDocuments: {
        title: I18n.text('developer-docs-shared-components.legacyHeaderContent.marketingEmails.relatedDocumentsTitle'),
        href: '/guides/api/marketing/emails/marketing-emails'
      }
    })
  }],
  '/marketing/marketing-events': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent())
  }],
  '/marketing/subscriptions-preferences': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.subscriptionsPreferences.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.subscriptionsPreferences.useCase')
    })
  }],
  '/marketing/emails/transactional-emails': [{
    versionName: 'v1',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.transactionalEmails.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.transactionalEmails.useCase')
    })
  }],
  '/settings/account-activity-api': [{
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.accountActivity.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.accountActivity.useCase')
    }),
    versionName: 'v1'
  }],
  '/analytics-and-events/reporting': [{
    isDefaultSelected: true,
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.analyticsReporting.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.analyticsReporting.useCase'),
      relatedDocuments: {
        href: '/guides/api/analytics-and-events/reporting',
        title: I18n.text('developer-docs-shared-components.legacyHeaderContent.analyticsReporting.relatedDocumentsTitle')
      }
    })
  }],
  '/automation/create-manage-workflows': [{
    versionName: 'v3',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.createManageWorkflows.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.createManageWorkflows.useCase'),
      relatedDocuments: {
        title: I18n.text('developer-docs-shared-components.legacyHeaderContent.createManageWorkflows.relatedDocumentsTitle'),
        href: '/guides/api/automation/create-manage-workflows'
      }
    })
  }, {
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.createManageWorkflows.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.createManageWorkflows.useCase'),
      relatedDocuments: {
        title: I18n.text('developer-docs-shared-components.legacyHeaderContent.createManageWorkflows.relatedDocumentsTitle'),
        href: '/guides/api/automation/create-manage-workflows'
      }
    })
  }],
  '/cms/layouts': [{
    isDefaultSelected: true,
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsLayouts.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsLayouts.useCase')
    })
  }],
  '/cms/modules': [{
    isDefaultSelected: true,
    versionName: 'v4',
    introContent: legacyDocsIntroContent
  }],
  '/cms/templates': [{
    isDefaultSelected: true,
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsTemplates.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsTemplates.useCase')
    })
  }],
  '/cms/blogs/blog-details': [{
    isDefaultSelected: true,
    versionName: 'v2',
    introContent: () => Object.assign({}, legacyDocsIntroContent(), {
      intro: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsBlogDetails.intro'),
      useCase: I18n.text('developer-docs-shared-components.legacyHeaderContent.cmsBlogDetails.useCase')
    })
  }],
  '/deprecated/social-media': [{
    isDefaultSelected: true,
    versionName: 'v1',
    introContent: () => ({
      alert: SharedDeprecatedDocsAlertText()
    })
  }]
};
export const endpointToNameAndGroup = {
  '/crm/associations/association-details': {
    name: 'Associations',
    group: 'CRM',
    introContent: {
      postmanCollectionId: '8c5dfb3216553289fa6d'
    }
  },
  '/crm/associations/associations-schema': {
    name: 'Associations Schema',
    group: 'CRM',
    introContent: {
      postmanCollectionId: '8c5dfb3216553289fa6d'
    }
  },
  '/cms/blogs/blog-authors': {
    name: 'Authors',
    group: 'CMS',
    introContent: {
      postmanCollectionId: '26126890-451fedfa-4e89-4940-8aaa-223cb248ee10'
    }
  },
  '/cms/url-redirects': {
    name: 'Url Redirects',
    group: 'CMS',
    introContent: {
      postmanCollectionId: '3a914984238cf84b48e5'
    }
  },
  '/cms/blogs/blog-posts': {
    name: 'Posts',
    group: 'CMS',
    introContent: {
      postmanCollectionId: '26126890-0bedaf71-dce8-4614-82f3-76e95ac2ef15'
    }
  },
  '/cms/domains': {
    name: 'Domains',
    group: 'CMS',
    introContent: {
      postmanCollectionId: '85a10386746564133db1'
    }
  },
  '/cms/blogs/blog-tags': {
    name: 'Tags',
    group: 'CMS'
  },
  '/crm/owners': {
    name: 'Crm Owners',
    group: 'CRM',
    introContent: {
      postmanCollectionId: '78f10c037170a05f3b3a'
    }
  },
  '/crm/objects/schemas': {
    name: 'Schemas',
    group: 'CRM'
  },
  '/crm/objects/leads': {
    name: 'Leads',
    group: 'CRM'
  },
  '/crm/engagements/calls': {
    name: 'Calls',
    group: 'CRM',
    introContent: {
      postmanCollectionId: 'dd75d149eaa15806754f'
    }
  },
  '/crm/engagements/communications': {
    name: 'Communications',
    group: 'CRM'
  },
  '/crm/engagements/email': {
    name: 'Emails',
    group: 'CRM',
    introContent: {
      postmanCollectionId: 'dd75d149eaa15806754f'
    }
  },
  '/crm/engagements/meetings': {
    name: 'Meetings',
    group: 'CRM',
    introContent: {
      postmanCollectionId: 'dd75d149eaa15806754f'
    }
  },
  '/crm/engagements/notes': {
    name: 'Notes',
    group: 'CRM',
    introContent: {
      postmanCollectionId: 'dd75d149eaa15806754f'
    }
  },
  '/crm/engagements/postal-mail': {
    name: 'Postal Mail',
    group: 'CRM'
  },
  '/crm/engagements/tasks': {
    name: 'Tasks',
    group: 'CRM',
    introContent: {
      postmanCollectionId: 'dd75d149eaa15806754f'
    }
  },
  '/crm/extensions/calling-sdk': {
    name: 'Calling Extensions',
    group: 'CRM',
    introContent: {
      postmanCollectionId: 'b768e64f0ba435fdb59e'
    }
  },
  '/crm/extensions/crm-cards': {
    name: 'Public App Crm Cards',
    group: 'CRM',
    introContent: {
      postmanCollectionId: '77b1d2834b0387a286be'
    }
  },
  '/crm/extensions/timeline': {
    name: 'Timeline',
    group: 'CRM',
    introContent: {
      postmanCollectionId: 'e5a01a5644f5e061f744'
    }
  },
  '/crm/extensions/video-conferencing': {
    name: 'Video Conferencing Extension',
    group: 'CRM',
    introContent: {
      postmanCollectionId: '3a914984238cf84b48e5'
    }
  },
  '/crm/exports': {
    name: 'Exports',
    group: 'CRM'
  },
  '/crm/imports': {
    name: 'Imports',
    group: 'CRM',
    introContent: {
      postmanCollectionId: 'f30b00bcfdc29170b2e8'
    }
  },
  '/crm/lists': {
    name: 'Lists',
    group: 'CRM'
  },
  '/crm/objects/companies': {
    name: 'Companies',
    group: 'CRM',
    introContent: {
      postmanCollectionId: 'caf616003f52c955b5f6'
    }
  },
  '/crm/objects/contacts': {
    name: 'Contacts',
    group: 'CRM',
    introContent: {
      postmanCollectionId: 'dd75d149eaa15806754f'
    }
  },
  '/crm/objects/custom-objects': {
    name: 'Custom Objects',
    group: 'CRM',
    introContent: {
      postmanCollectionId: '40a2452fc88665fbe786'
    }
  },
  '/crm/objects/deals': {
    name: 'Deals',
    group: 'CRM',
    introContent: {
      postmanCollectionId: '8b5f74a661b06f5a54bd'
    }
  },
  '/crm/objects/deal-splits': {
    name: 'Deal Splits',
    group: 'CRM'
  },
  '/crm/objects/feedback-submissions': {
    name: 'Feedback Submissions',
    group: 'CRM'
  },
  '/crm/objects/line-items': {
    name: 'Line Items',
    group: 'CRM',
    introContent: {
      postmanCollectionId: 'c6e6832eb67946d66554'
    }
  },
  '/crm/objects/products': {
    name: 'Products',
    group: 'CRM',
    introContent: {
      postmanCollectionId: 'b4ad6241d1138c449a57'
    }
  },
  '/crm/objects/tickets': {
    name: 'Tickets',
    group: 'CRM',
    introContent: {
      postmanCollectionId: '26126890-98d91102-ed47-41b0-8304-23f04f12ed0f'
    }
  },
  '/crm/commerce/quotes': {
    name: 'Quotes',
    group: 'CRM',
    introContent: {
      postmanCollectionId: '9a45b2a3a1488cb66931'
    }
  },
  '/crm/pipelines': {
    name: 'Pipelines',
    group: 'CRM',
    introContent: {
      postmanCollectionId: '2c67a53481327b3678ec'
    }
  },
  '/crm/properties': {
    name: 'Properties',
    group: 'CRM',
    introContent: {
      postmanCollectionId: '33493e154afc38038092'
    }
  },
  '/cms/content-audit': {
    group: 'CMS',
    name: 'Cms Content Audit',
    introContent: {
      postmanCollectionId: '63a8c35ef79295bd96b1'
    }
  },
  '/cms/hubdb': {
    group: 'CMS',
    name: 'Hubdb'
  },
  '/cms/site-search': {
    group: 'CMS',
    name: 'Site Search',
    introContent: {
      postmanCollectionId: '5e5c4c70292db4e72738'
    }
  },
  '/cms/source-code': {
    group: 'CMS',
    name: 'Source Code'
  },
  '/cms/pages': {
    group: 'CMS',
    name: 'Pages'
  },
  '/cms/media-bridge': {
    group: 'CMS',
    name: 'Media Bridge'
  },
  '/conversations/inbox-and-messages': {
    group: 'Conversations',
    name: 'Conversations Inbox & Messages',
    introContent: {
      postmanCollectionId: '7b60f79f04424dd71c50'
    }
  },
  '/conversations/create-custom-channels': {
    name: 'Custom Channels',
    group: 'Conversations'
  },
  '/conversations/visitor-identification': {
    group: 'Conversations',
    name: 'Visitor Identification'
  },
  '/app-management/webhooks': {
    group: 'Webhooks',
    name: 'Webhooks',
    introContent: {
      postmanCollectionId: '3a914984238cf84b48e5'
    }
  },
  '/analytics-and-events/event-analytics': {
    group: 'Events',
    name: 'Events',
    introContent: {
      postmanCollectionId: '95a3ba30b9140023cf3b'
    }
  },
  '/analytics-and-events/custom-events/custom-event-completions': {
    group: 'Events',
    name: 'Send Event Completions'
  },
  '/analytics-and-events/custom-events/custom-event-definitions': {
    group: 'Events',
    name: 'Manage Event Definitions'
  },
  '/automation/custom-workflow-actions': {
    group: 'Automation',
    name: 'Actions V4'
  },
  '/automation/sequences': {
    group: 'Automation',
    name: 'Sequences'
  },
  '/library/files': {
    group: 'Files',
    name: 'Files'
  },
  '/library/meetings': {
    group: 'Scheduler',
    name: 'Meetings'
  },
  '/marketing/campaigns': {
    group: 'Marketing',
    name: 'Campaigns Public Api',
    introContent: {
      postmanCollectionId: '26126890-c8e3f08f-8fa9-4a9f-9929-bfa7964ceb21'
    }
  },
  '/marketing/forms': {
    group: 'Marketing',
    name: 'Forms'
  },
  '/marketing/emails/marketing-emails': {
    group: 'Marketing',
    name: 'Marketing Emails'
  },
  '/marketing/emails/single-send-api': {
    group: 'Marketing',
    name: 'Single-send'
  },
  '/marketing/marketing-events': {
    group: 'Marketing',
    name: 'Marketing Events',
    introContent: {
      postmanCollectionId: '26126890-00607362-8bc1-41d1-852e-61cec8104cff'
    }
  },
  '/marketing/subscriptions': {
    group: 'Communication Preferences',
    name: 'Subscriptions'
  },
  '/marketing/subscriptions-preferences': {
    group: 'Communication Preferences',
    name: 'Subscriptions'
  },
  '/marketing/emails/transactional-emails': {
    group: 'Marketing',
    name: 'Transactional Single Send'
  },
  '/settings/account-information-api': {
    group: 'Account',
    name: 'Account Info'
  },
  '/settings/business-units-api': {
    group: 'Business Units',
    name: 'Business Units'
  },
  '/settings/currencies': {
    group: 'Settings',
    name: 'Multicurrency'
  },
  '/settings/users/user-provisioning': {
    group: 'Settings',
    name: 'User Provisioning'
  },
  '/settings/users/user-details': {
    group: 'CRM',
    name: 'Users'
  },
  '/settings/account-activity-api': {
    group: 'Account',
    name: 'Audit Logs'
  },
  '/crm/commerce/taxes': {
    group: 'CRM',
    name: 'Taxes',
    introContent: {
      postmanCollectionId: '9a45b2a3a1488cb66931'
    }
  },
  '/crm/commerce/discounts': {
    group: 'CRM',
    name: 'Discounts'
  },
  '/crm/commerce/invoices': {
    group: 'CRM',
    name: 'Invoices'
  },
  '/crm/commerce/carts': {
    group: 'CRM',
    name: 'Carts'
  },
  '/crm/commerce/orders': {
    group: 'CRM',
    name: 'Orders'
  },
  '/crm/commerce/payments': {
    group: 'CRM',
    name: 'Commerce Payments'
  },
  '/crm/commerce/subscriptions': {
    group: 'CRM',
    name: 'Commerce Subscriptions'
  },
  '/crm/commerce/fees': {
    group: 'CRM',
    name: 'Fees'
  },
  '/crm/objects/goals': {
    group: 'CRM',
    name: 'Goal Targets'
  },
  '/automation/create-manage-workflows': {
    group: 'Automation',
    name: 'Automation V4'
  },
  '/app-management/feature-flags': {
    name: 'Public App Feature Flags V3',
    group: 'CRM',
    introContent: {
      postmanCollectionId: '26126890-b4ebfe97-5852-4a5d-a9be-d171474df3b1'
    }
  }
};
export const getVersionUrlOfDefaultSelected = versions => {
  if (!versions) return '';
  const version = versions.find(v => v.isDefaultSelected);
  return version ? `/${version.versionName}` : '';
};
export const generateUrlWithVersionFromBaseUrl = url => {
  return `/api${url}${getVersionUrlOfDefaultSelected(legacyVersionInfo[url])}`;
};