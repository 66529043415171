"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 41185684467;
const slug = exports.slug = 'guides/cms/content/data-driven-content/serverless-functions/overview';
const title = exports.title = 'サーバーレス関数の概要';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Page | Serverless Overview | 202209';
const metaDescription = exports.metaDescription = 'サーバーレス関数はJavaScriptで記述し、Node.jsランタイムを使用します。HubSpotのCMSで構築したウェブサイトに高度な機能を追加する際に使用します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E4%BE%8B",
  "label": "例",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%88%B6%E9%99%90",
  "label": "制限",
  "parent": null
}, {
  "depth": 1,
  "id": "%E5%AE%9F%E8%A1%8C%E5%88%B6%E9%99%90",
  "label": "実行制限",
  "parent": "%E5%88%B6%E9%99%90"
}, {
  "depth": 1,
  "id": "%E4%BE%9D%E5%AD%98%E9%96%A2%E4%BF%82%E3%81%AE%E5%88%86%E5%89%B2",
  "label": "依存関係の分割",
  "parent": "%E5%88%B6%E9%99%90"
}, {
  "depth": 0,
  "id": "%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%83%AC%E3%82%B9%E9%96%A2%E6%95%B0%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9",
  "label": "サーバーレス関数へのアクセス",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%83%AC%E3%82%B9%E9%96%A2%E6%95%B0%E3%83%95%E3%82%A9%E3%83%AB%E3%83%80%E3%83%BC",
  "label": "サーバーレス関数フォルダー",
  "parent": "%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%83%AC%E3%82%B9%E9%96%A2%E6%95%B0%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9"
}, {
  "depth": 1,
  "id": "serverless.json",
  "label": "serverless.json",
  "parent": "%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%83%AC%E3%82%B9%E9%96%A2%E6%95%B0%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9"
}, {
  "depth": 1,
  "id": "function.js",
  "label": "function.js",
  "parent": "%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%83%AC%E3%82%B9%E9%96%A2%E6%95%B0%E3%81%B8%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9"
}, {
  "depth": 0,
  "id": "%E3%82%B7%E3%83%BC%E3%82%AF%E3%83%AC%E3%83%83%E3%83%88",
  "label": "シークレット",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%83%AC%E3%82%B9%E9%96%A2%E6%95%B0%E3%83%AD%E3%82%B0%E3%81%AE%E8%A1%A8%E7%A4%BA",
  "label": "サーバーレス関数ログの表示",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      ol: "ol",
      code: "code",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      DndSection,
      DndModule,
      HubspotVideoPlayer
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "サーバーレス関数"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "開発者プロジェクト"
        }), "の一部としてサーバーレス関数を作成する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "開発者プロジェクトのサーバーレス関数のドキュメント"
        }), "を参照してください。以下のドキュメントは、開発者プロジェクトプラットフォーム以外でサーバーレス関数を作成する場合に使用します。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サーバーレス関数を使用すると、HubSpotやサードパーティーのサービスを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "API"
      }), "経由で操作できるサーバーサイドコードを作成できます。認証が必要なAPIでは資格情報が表示されるため、ウェブサイトのフロントエンドでの使用には適していません。サーバーレス関数は仲介処理として機能することで、資格情報を保護できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サーバーレス関数を使用する場合、新たにサーバーを作成、管理する必要はありません。サーバーレス関数はオーバーヘッド（間接的な負荷）が小さいため、ビジネスの成長に合わせて拡大することも容易です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/standalone-cms-developer",
        children: "CMS開発者用サンドボックスアカウント"
      }), "を使用して、サーバーレス関数を試すことができます。初めてのサーバーレス関数を作成する際には、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/guides/getting-started-with-serverless-functions",
        children: "サーバーレス関数の使い方"
      }), "をご参照ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "例"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "HubSpotのサーバーレス関数は、幅広い用途に活用できます。例えば、次のような目的に使用できます。"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "データを収集し、HubDBやHubSpot CRMに保存"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "複雑なデータ計算ツール"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "他のシステムからのデータを動的に表示"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.hubspot.jp/docs/cms/data/serverless-functions/event-registration-app",
                children: "イベント登録システム"
              }), "（ベータ版）"]
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "フォーム送信を通じて他のシステムにデータを送信"
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172853952400"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベント登録システムを例に説明します。サーバーレス関数を使用して登録を処理する方法と、イベントの登録枠がいくつ空いているかを更新する方法に分けて紹介します。このフローは次のように機能します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ウェブサイトへの訪問者がイベント登録ページにアクセスすると、あと15人イベントに参加できることが表示されます。訪問者はイベントに登録するためにカスタムフォームに入力し、送信します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["このフォーム送信によって、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "リクエストが", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yourwebsite.com/_hcms/api/event/participants"
        }), "に送信されるように設定しており、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "event/participants"
        }), "がサーバーレス関数に該当します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "サーバーレス関数は、ユーザーが送信したデータを受信し、ブラウザーにレスポンスを返す前にいくつかの処理を行います。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "フォーム フィールド データをHubSpotフォーム送信APIに送ることにより、このフォーム送信の情報をHubSpot CRMに追加します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubDB APIを使用して、HubDBに格納されているこのイベントの参加者のカウントから1を減算します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ウェブブラウザーにレスポンスを返します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ページのJavascriptは、サーバーレス関数からのレスポンスを受け取り、エンドユーザーに確認メッセージを表示し、残っている参加枠の数を調整します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのサーバーレス関数は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/ja-JP/docs/Web/JavaScript",
        children: "JavaScript"
      }), "で記述し、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/ja/about/",
        children: "Node.js"
      }), "ランタイムを使用します。HubSpotのサーバーレス関数の用途は、高度なフォーム送信のサポートや他のAPIからのデータの取り込みなど、HubSpotサイトへの機能追加です。HubSpotに関係のないコードを実行する、汎用的なコンピューティングプラットフォームとしての使用は意図されていません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "制限"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サーバーレス関数は、高速の特定用途を対象として設計されています。高速性によって素早い呼び出しとレスポンスが可能になるため、ウェブサイトやアプリのフロントエンドでの利用に最適です。HubSpotのサーバーレス関数には、次のような制限があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "1アカウントあたり50件のシークレット。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "128MBのメモリー。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントあたり100件以下のエンドポイント。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["関数を呼び出す場合はcontentType ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "呼び出しペイロードあたり6MB。サーバーレス関数を使用してファイルをアップロードする場合などに到達することがあります。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "記録できるデータの量は4KB。この制限に達した場合は、最終出力ではなく、個々のアクションの後に記録することをお勧めします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "実行制限"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "各関数の実行時間は最大10秒に制限されます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "各アカウントでは、1分あたり実行時間が合計600秒に制限されています。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "つまり、1分以内に実行できる操作は、次のいずれかになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "完了までに10秒かかる関数を最大60回実行する。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "完了までに100ミリ秒かかる関数を最大6,000回実行する。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらの制限を超える関数は、エラーになります。実行数と時間の制限には、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), "のレスポンスが返されます。各関数の実行時間は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#viewing-serverless-function-logs",
        children: "サーバーレス関数ログ"
      }), "に記録されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "依存関係の分割"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["複数のJavaScriptファイルがデプロイされている場合、サーバーレス関数ではこれらのJavaScriptファイルの分割に対応できません。代わりにサーバーレス関数では、関数を実行するための1つのJavaScriptファイルを含む必要があります。複数のJavaScriptファイルを使用してサーバーレス関数を構築する場合は、共有コードを1つのJavaScriptファイルにコピーするか、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://webpack.js.org/",
        children: "webpack"
      }), "を使用してコードをまとめる必要があります。webpackをソリューションとして使用する方法について詳細は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/APIs-Integrations/Import-packages-in-serverless-functions/m-p/346620",
        children: "HubSpotのコミュニティー"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サーバーレス関数へのアクセス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでは、サーバーレス関数は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/key-concepts#developer-file-system",
        children: "デベロッパー ファイル システム"
      }), "に格納され、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cos-general/a-quick-tour-of-the-design-manager",
        children: "デザインマネージャー"
      }), "で表示できます。CLIを使用して、サーバーレス関数にローカル環境で利用して編集可能です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発者プロジェクトにサーバーレス関数を含める場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "JavaScriptビルディングブロックのドキュメント"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "サーバーレス関数フォルダー"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["HubSpotのサーバーレス関数は、関数フォルダー内に配置されます。このフォルダーの名前は自由に付けることができますが、", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".functions"
            }), "を末尾に付ける必要があります。このフォルダーに格納されているファイルは公開されません。"]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["functionsフォルダー内に、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), "ファイルと、関数が記述されている", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#function-js",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: ".js"
              })
            }), "ファイルを格納します。また、関数の用途や動作、関数を作成するためのビルドプロセスがあるかどうかを説明するために、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#readme-md",
              children: "README"
            }), "マークダウンファイルを追加してください。"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-03%20at%208.24.31%20AM.png",
            alt: "サーバーレス.functionsフォルダー"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["デザインマネージャー内で誤って編集することを防ぐために、フォルダーをロックできます。フォルダーをロックするには、デザインマネージャーを開き、次に", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "フォルダー"
        }), "を右クリックし、［フォルダーをロック］を選択します。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "serverless.json"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "開発者プロジェクト"
        }), "に含まれるサーバーレス関数は、新しい", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "スキーマを含むプラットフォームバージョン", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2023.2"
        }), "以降で更新されています。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/developer-projects/platform-versioning",
          children: "プロジェクトプラットフォームのバージョン管理"
        }), "について詳細をご確認ください。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/features/serverless-functions/reference#serverless-json",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        })
      }), "は、サーバーレス関数の設定ファイルです。このファイルで、ランタイム環境と、関数で使用することを検討している全ての", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
        children: "環境変数"
      }), "を指定します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["エンドポイントのルーティングもこのファイルによって設定します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#function-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "function.js"
        })
      }), "ファイルにマップする", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/features/serverless-functions/reference#endpoints",
        children: "エンドポイント"
      }), "パスを指定します。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "ファイルの例については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/features/serverless-functions/reference#serverless-json",
        children: "サーバーレス関数リファレンスガイド"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "function.js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["実際のサーバーレス関数は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/features/serverless-functions/reference#function-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".jsファイル"
        })
      }), "が必要ですが、名前は自由に付けることができます。サーバーレス関数が機能するためには、", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developers.hubspot.com/jp/docs/cms/features/serverless-functions/reference#serverless-json",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "ファイルに定義した"]
      }), "エンドポイントに関数をマッピングする必要があります。トラブルシューティングの観点から、", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".js"
      }), "ファイルには", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "設定ファイルに定義したエンドポイント名と似た名前を付けることが推奨されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "シークレット"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サーバーレス関数による呼び出しを認証する場合、セキュリティー上、シークレットを使用してAPIキー、非公開アプリのアクセストークン、およびその他の認証情報を保存する必要があります。これにより、キーまたはアクセストークンを公開せずに認証できるようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["シークレットを作成および管理するには、次のような", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/developer-reference/local-development-cli#serverless-function-commands",
        children: "HubSpot CLIコマンド"
      }), "を使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add"
        }), "：新しいシークレットを作成します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets list"
        }), "：現在使用可能なシークレットの名前を表示します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets update"
        }), "：既存のシークレットを更新します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CLIを使用して追加されたシークレットは、そのシークレット名を含む", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secrets"
      }), "配列を含めることで、関数で使用できるようになります。これにより、関数コードを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "バージョン管理"
      }), "に保存し、シークレットを公開せずに使用できるようになります。ただし、コンソールログを使用して、またはレスポンスとして、シークレットの値を", (0, _jsxRuntime.jsx)("u", {
        children: "返さないでください"
      }), "。そうすると、ログや、サーバーレス関数を呼び出すフロントエンドページで、シークレットが公開される恐れがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**キャッシュ保存のため、更新されたシークレット値が表示されるまでには、1分ほどかかる場合があります。シークレットを更新したばかりで、まだ古い値が表示されている場合は、1分ほどしてからもう一度ご確認ください。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サーバーレス関数ログの表示"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サーバーレス関数のトラブルシューティングを支援するために、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/developer-reference/local-development-cli",
        children: "CLI"
      }), "には、関数のログを表示するための", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/developer-reference/local-development-cli#logs",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs logs"
        }), "コマンド"]
      }), "があります。各関数の呼び出しのレスポンス、実行時刻、実行時間に加えて、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "console.log"
      }), "ステートメントも関数ログに表示されます。APIキーなどのシークレットは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "console.log"
      }), "に記録しないでください。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}