"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921781;
const slug = exports.slug = 'guides/api/crm/search';
const title = exports.title = 'CRM API | 検索';
const name = exports.name = 'vNext Docs DP - 検索';
const metaDescription = exports.metaDescription = 'CRM検索エンドポイントを使用すると、開発者はさまざまなCRMオブジェクトタイプ間での絞り込み、並べ替え、検索を実行して効率的にデータを取得できます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%A4%9C%E7%B4%A2%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%AE%E9%80%81%E4%BF%A1",
  "label": "検索リクエストの送信",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%A4%9C%E7%B4%A2%E5%8F%AF%E8%83%BD%E3%81%AAcrm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%A8%E3%82%A8%E3%83%B3%E3%82%B2%E3%83%BC%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "検索可能なCRMオブジェクトとエンゲージメント",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88",
  "label": "オブジェクト",
  "parent": "%E6%A4%9C%E7%B4%A2%E5%8F%AF%E8%83%BD%E3%81%AAcrm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%A8%E3%82%A8%E3%83%B3%E3%82%B2%E3%83%BC%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88"
}, {
  "depth": 1,
  "id": "%E3%82%A8%E3%83%B3%E3%82%B2%E3%83%BC%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "エンゲージメント",
  "parent": "%E6%A4%9C%E7%B4%A2%E5%8F%AF%E8%83%BD%E3%81%AAcrm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%A8%E3%82%A8%E3%83%B3%E3%82%B2%E3%83%BC%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88"
}, {
  "depth": 0,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%A7%E6%A4%9C%E7%B4%A2%E5%8F%AF%E8%83%BD%E3%81%AA%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%81%AE%E6%A4%9C%E7%B4%A2",
  "label": "既定で検索可能なプロパティーの検索",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%A4%9C%E7%B4%A2%E7%B5%90%E6%9E%9C%E3%81%AE%E7%B5%9E%E3%82%8A%E8%BE%BC%E3%81%BF",
  "label": "検索結果の絞り込み",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%81%AE%E6%A4%9C%E7%B4%A2",
  "label": "関連付けの検索",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%A4%9C%E7%B4%A2%E7%B5%90%E6%9E%9C%E3%81%AE%E4%B8%A6%E3%81%B9%E6%9B%BF%E3%81%88",
  "label": "検索結果の並べ替え",
  "parent": null
}, {
  "depth": 0,
  "id": "%E7%B5%90%E6%9E%9C%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AE%E5%87%A6%E7%90%86",
  "label": "結果ページの処理",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%88%B6%E9%99%90%E4%BA%8B%E9%A0%85",
  "label": "制限事項",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "検索"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRM全体でオブジェクト、レコード、エンゲージメントの絞り込み、並べ替え、検索を行うには、CRM検索エンドポイントを使用します\n。例えば、これらのエンドポイントを使用して、アカウント内のコンタクトのリストや 進行中の全取引のリストを取得できます。アプリからこれらのエンドポイントを使用するには、CRMスコープが\n必要です。目的を達成するためにどの程度詳細なCRMスコープを使用できるかは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "使用可能なスコープのリスト"
      }), "\nを参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "検索リクエストの送信"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRM内を検索するには、オブジェクトの検索エンドポイントに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを送信します。CRM検索エンドポイントは、次の形式で記述されます：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/{object}/search"
      }), "。リクエスト本文に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#filter-search-results",
        children: "フィルター"
      }), "を含めて、CRMプロパティー値で検索を絞り込みます。例えば、以下のコードスニペットでは、特定の会社のEメールアドレスを持つ全てのコンタクトのリストが取得されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to /crm/v3/objects/contacts/search\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"email\",\n          \"operator\": \"CONTAINS_TOKEN\",\n          \"value\": \"*@hubspot.com\"\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["オブジェクトごとに、検索されたときに返される", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#crm-objects",
        children: "既定のプロパティー"
      }), "のセットがあります。例えば、コンタクトの検索では", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdate"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastmodifieddate"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastname"
      }), "が返されます。特定のプロパティーのみを返すには、リクエスト本文に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), "配列を含めます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to /crm/v3/objects/companies/search\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"annualrevenue\",\n          \"operator\": \"GT\",\n          \"value\": \"10000000\"\n        }\n      ]\n    }\n  ],\n  \"properties\": [\"annualrevenue\", \"name\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), "配列を含めると、既定で返されるプロパティーが上書きされるため、既定のプロパティーを返す必要がある場合は、それらを配列で指定する必要があります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "検索可能なCRMオブジェクトとエンゲージメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "オブジェクト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の表は、オブジェクト検索エンドポイント、それらが参照するオブジェクト、および既定で返されるプロパティーを示しています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "検索エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定で返されるプロパティー"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/carts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "カート"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/companies/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "会社"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "domain"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/contacts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "firstname"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastname"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deals/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "取引"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "dealname"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "closedate,"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pipeline"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealstage"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deal_split/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/discounts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "値引き"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/feedback_submissions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィードバック送信"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/fees/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "手数料"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/invoices/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "請求書"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/leads/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リード"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/line_items/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "quantity"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/orders/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "注文"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/commerce_payments/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "支払い"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/products/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "製品"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/quotes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もり"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_expiration_date"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_public_url_key"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_status"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/subscriptions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配信登録（コマース）"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/taxes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "税金"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tickets/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "チケット"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline_stage"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_category"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_priority"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "subject"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "エンゲージメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の表は、エンゲージメント検索エンドポイント、それらが参照するエンゲージメント、および既定で返されるプロパティーを示しています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "検索エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "エンゲージメント"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定で返されるプロパティー"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/calls/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コール"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/emails/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメール"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/meetings/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ミーティング"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/notes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メモ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tasks/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "タスク"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "既定で検索可能なプロパティーの検索"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["指定した文字列を含む値が含まれる全てのレコードを見つけるには、指定したオブジェクトのレコードにある全ての既定のテキストプロパティーを検索します。既定では、結果はオブジェクト作成順（古い順）で返されますが、この動作は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#sorting",
        children: "並べ替え"
      }), "により変更できます。例えば、次のリクエストでは、既定のテキストプロパティー値に文字", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X"
      }), "が含まれる全てのコンタクトが検索されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"query\": \"x\"\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記の方法によって既定で検索されるプロパティーを以下に示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "検索エンドポイント"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定の検索可能なプロパティー"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/calls/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コール"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_title"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_body_preview"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/companies/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "会社"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "website"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phone"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "domain"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/contacts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "firstname"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastname"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phone"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_additional_emails"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fax"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mobilephone"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_marketable_until_renewal"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/{objectType}/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "カスタムオブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択された最大20個のプロパティー"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deals/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "取引"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "dealname"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pipeline"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealstage"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealtype"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/emails/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメール"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_subject"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/feedback_submissions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィードバック送信"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_submission_name"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_content"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/meetings/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ミーティング"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_title"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_body"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/notes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メモ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_note_body"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/products/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "製品"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sku"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/quotes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もり"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_firstname"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_lastname"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_proposal_slug"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_company_name"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_email"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_quote_number"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_public_url_key"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tasks/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "タスク"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_task_body"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_task_subject"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tickets/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "チケット"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "subject"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline_stage"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_category"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "検索結果の絞り込み"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プロパティー値が一致するレコードのみを結果に表示するには、リクエスト本文にフィルターを使用します。例えば、以下のリクエストでは、名が「Alice」である全てのコンタクトが検索されます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注"
        }), "：コール、コミュニケーション、Eメール、ミーティング、メモ、またはタスクの検索結果を絞り込む場合、プロパティー", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_body_preview_html"
        }), "はサポートされません。Eメールでは、プロパティー", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_email_html"
        }), "および", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_body_preview"
        }), "もサポートされません。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n\n  --data '{\n    \"filterGroups\":[\n      {\n        \"filters\":[\n          {\n            \"propertyName\": \"firstname\",\n            \"operator\": \"EQ\",\n            \"value\": \"Alice\"\n          }\n        ]\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["複数のフィルター条件を含めるには、次のように**", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filterGroups"
      }), "**内に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filters"
      }), "をグループ化します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: "AND"
        }), "ロジックを適用するには、1つの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), "のセット内に複数の条件をカンマで区切って指定します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: "OR"
        }), "ロジックを適用するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroup"
        }), "内に複数の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), "を指定します。最大5個の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), "と、グループごとに最大10個の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), "を指定でき、合計では最大25個のフィルターを指定できます。指定したグループまたはフィルターが多すぎる場合は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "VALIDATION_ERROR"
        }), "エラーレスポンスが返されます。例えば次のリクエストでは、名が", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Alice"
        }), "で、かつ（AND）姓が", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Smith"
        }), "以外であるか、または（OR）", (0, _jsxRuntime.jsx)(_components.code, {
          children: "email"
        }), "プロパティーに値が設定されていないコンタクトが検索されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"firstname\",\n          \"operator\": \"EQ\",\n          \"value\": \"Alice\"\n        },\n        {\n          \"propertyName\": \"lastname\",\n          \"operator\": \"NEQ\",\n          \"value\": \"Smith\"\n        }\n      ]\n    },\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"email\",\n          \"operator\": \"NOT_HAS_PROPERTY\"\n        }\n      ]\n    }\n  ]\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "フィルターで演算子を使用して、どのレコードを返すかを指定できます。フィルターの値では大文字と小文字が区別されませんが、次の2つの例外があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "列挙プロパティーをフィルター処理する場合、検索の全てのフィルター演算子で大文字と小文字が区別されます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "IN"
        }), "演算子と", (0, _jsxRuntime.jsx)(_components.code, {
          children: "NOT_IN"
        }), "演算子を使用して文字列プロパティーをフィルターする場合、検索対象の値は小文字で指定する必要があります。使用可能なフィルター演算子は次のとおりです。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "演算子"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "指定された値より小さい。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LTE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "指定された値以下である。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "指定された値より大きい。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GTE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "指定された値以上である。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EQ"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "指定された値と等しい。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NEQ"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "指定された値と等しくない。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BETWEEN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["指定の範囲内にある。リクエストで、キーと値のペアを使用して", (0, _jsxRuntime.jsx)(_components.code, {
              children: "highValue"
            }), "と", (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            }), "を設定します。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#between-operator",
              children: "以下の例"
            }), "を参照してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["指定のリストに含まれる。完全一致を検索します。リクエストで、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            }), "配列にリスト値を含めます。この演算子を使用して文字列プロパティーを検索する場合、値は小文字で指定する必要があります。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#in-operator",
              children: "以下の例"
            }), "を参照してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_IN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["指定のリストに含まれない。リクエストで、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            }), "配列にリスト値を含めます。この演算子を使用して文字列プロパティーを検索する場合、値は小文字で指定する必要があります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_PROPERTY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "指定されたプロパティーに値がある。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_HAS_PROPERTY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "指定されたプロパティーに値がない。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTAINS_TOKEN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["トークンが含まれる。リクエストで、ワイルドカード（*）を使用して部分検索を実行することができます。例えば、HubSpotのEメールアドレスを持つコンタクトを検索するには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "*@hubspot.com"
            }), "という値を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_CONTAINS_TOKEN"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "トークンが含まれない。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BETWEEN"
      }), "演算子を使用して、特定の期間内に最終の更新が加えられた全てのタスクを検索できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/tasks/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n   \"filterGroups\":[{\n      \"filters\":[\n        {\n          \"propertyName\":\"hs_lastmodifieddate\",\n          \"operator\":\"BETWEEN\",\n          \"highValue\": \"1642672800000\",\n          \"value\":\"1579514400000\"\n        }\n      ]\n    }]\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["または、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IN"
      }), "演算子を使用して、ドロップダウンプロパティーで選択された指定の値を持つ会社を検索できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/companies/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"filterGroups\":[\n      {\n        \"filters\":[\n          {\n           \"propertyName\":\"enumeration_property\",\n           \"operator\":\"IN\",\n          \"values\": [\"value_1\", \"value_2\"]\n        }\n        ]\n      }\n    ],\n   \"properties\": [\"annualrevenue\", \"enumeration_property\", \"name\"]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連付けの検索"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["他の特定のレコードに関連付けられているレコードを検索するには、疑似プロパティー", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations.{objectType}"
      }), "を使用します。例えば、次のリクエストでは、コンタクトIDが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123"
      }), "のコンタクトに関連付けられている全てのチケットが検索されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/tickets/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"filters\": [\n      {\n        \"propertyName\": \"associations.contact\",\n        \"operator\": \"EQ\",\n        \"value\": \"123\"\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**現在のところ、カスタムオブジェクトの関連付けで検索するオプションは、検索エンドポイントではサポートされていません。カスタムオブジェクトの関連付けを検索するには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "関連付けAPI"
        }), "を使用できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "検索結果の並べ替え"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "並べ替え規則は、結果を昇順または降順で表示するために、リクエスト本文の中で使用します。1回の検索に適用できる並べ替え規則は1つのみです。例えば、次のリクエストでは、返されたコンタクトを作成日時が新しい順に並べ替えます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"sorts\": [\n      {\n        \"propertyName\": \"createdate\",\n        \"direction\": \"DESCENDING\"\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "結果ページの処理"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既定では、結果エンドポイントは1回につき10個のレコードが含まれるページを返します。これは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "limit"
      }), "パラメーターをリクエスト本文に指定して変更できます。1ページあたりに指定できるオブジェクトの最大数は100です。例えば、次のリクエストでは、それぞれ20件の結果を含むページが返されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"limit\": 20\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["結果の次ページを取得するには、前のレスポンスの**", (0, _jsxRuntime.jsx)(_components.code, {
        children: "paging.next.after"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "プロパティーで提供された"
      }), (0, _jsxRuntime.jsx)(_components.code, {
        children: "after"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "パラメーターを渡す必要があります。"
      }), (0, _jsxRuntime.jsx)(_components.code, {
        children: "paging.next.after"
      }), "**プロパティーが提供されなかった場合、それ以上表示される結果はありません。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "after"
      }), "パラメーターの値は、整数の形式で指定する必要があります。例えば、以下のリクエストでは結果の次ページが返されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"after\": \"20\"\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "制限事項"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "新規に作成または更新されたCRMオブジェクトが検索結果に表示されるまでには、若干時間がかかることがあります。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アーカイブ済みのCRMオブジェクトは検索結果に表示されません。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["検索エンドポイントへのリクエストには、1秒当たり4回の", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details",
          children: "レート制限"
        }), "が適用されます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "クエリーは最大3,000文字まで入力できます。リクエストの本文が3,000文字を超えている場合は、400エラーが返されます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "検索エンドポイントには、1クエリーにつき合計結果数10,000件という上限があります。10,000件を超える部分のページを取得しようとすると、エラー400が発生します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#filter-search-results",
          children: "フィルター"
        }), "を適用する場合、最大5個の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), "と、グループごとに最大10個の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), "を指定でき、合計では最大25個のフィルターを指定できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["電話番号の検索では、HubSpotは特殊な計算プロパティーを使用して形式を標準化します。このようなプロパティーは全て", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_searchable_calculated_*"
        }), "で始まります。この標準化の過程で、HubSpotは市外局番と市内番号のみを使用します。国コードは検索またはフィルター条件に含めないでください。"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}