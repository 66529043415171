"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35219164725;
const slug = exports.slug = 'guides/cms/content/data-driven-content/emails-with-programmable-content';
const title = exports.title = 'Créer des e-mails au contenu programmable';
const name = exports.name = 'Créer des e-mails au contenu programmable';
const metaDescription = exports.metaDescription = 'Utilisez du contenu programmable pour personnaliser vos e-mails avec des données depuis votre compte HubSpot via HubL.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "limites-d-envoi-d-e-mails",
  "label": "Limites d'envoi d'e-mails",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-cr%C3%A9er-un-module-d-e-mail-programmable",
  "label": "1. Créer un module d'e-mail programmable",
  "parent": null
}, {
  "depth": 1,
  "id": "objets-standard",
  "label": "Objets standard",
  "parent": "1.-cr%C3%A9er-un-module-d-e-mail-programmable"
}, {
  "depth": 1,
  "id": "objets-personnalis%C3%A9s",
  "label": "Objets personnalisés",
  "parent": "1.-cr%C3%A9er-un-module-d-e-mail-programmable"
}, {
  "depth": 1,
  "id": "hubdb",
  "label": "HubDB",
  "parent": "1.-cr%C3%A9er-un-module-d-e-mail-programmable"
}, {
  "depth": 0,
  "id": "2.-ajouter-le-module-%C3%A0-un-e-mail",
  "label": "2. Ajouter le module à un e-mail",
  "parent": null
}, {
  "depth": 0,
  "id": "plus-de-didacticiels-sur-hubdb",
  "label": "Plus de didacticiels sur HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-academy",
  "label": "HubSpot Academy",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h2: "h2",
      code: "code",
      img: "img",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Créer des e-mails au contenu programmable"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Cette fonctionnalité est actuellement en version bêta. En utilisant cette fonctionnalité, vous acceptez les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "conditions d'utilisation des outils bêta destinés aux développeurs"
        }), ". Ce guide fait référence aux fonctionnalités disponibles uniquement via cette version bêta. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-programmable-emails",
          children: "Inscrivez-vous à la version bêta dans vos modèles ou modules."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Utilisez du contenu programmable pour personnaliser vos e-mails avec des données depuis votre compte HubSpot via HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les sources de données que vous pouvez utiliser dans un e-mail programmable dépendent de votre abonnement HubSpot :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous disposez d'un abonnement ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketing Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Pro"
        }), ", vous pouvez utiliser des données à partir d'objets CRM standard, tels que des contacts, des sociétés et des produits."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous avez un abonnement à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketing Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Entreprise"
        }), ", vous pouvez aussi utiliser des sources de données structurées telles que ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "les tableau HubDB"
        }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/use-custom-objects",
          children: "les objets personnalisés"
        }), ". Ces données peuvent être filtrées en fonction des propriétés de contact du destinataire."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, un site web d'agence immobilière peut demander à ses prospects de remplir un formulaire décrivant leurs besoins en matière de logement. La soumission de formulaire peut alors déclencher un workflow qui envoie au prospect un e-mail avec des maisons susceptibles de l'intéresser."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites d'envoi d'e-mails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez inclure les fonctions CRM HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "crm_object"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "crm_objects"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: "crm_associations"
      }), " dans un e-mail programmable, mais tout e-mail qui inclut ces fonctions spécifiques est soumis aux limites suivantes, en fonction du nombre de destinataires à qui vous envoyez l'e-mail :"]
    }), "\n", (0, _jsxRuntime.jsx)("table", {
      children: (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("strong", {
              children: "Nombre total de destinataires"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("strong", {
              children: "Nombre maximal de fonctions CRM HubL"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "500 000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "1"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "250 000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "2"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "165 000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "3"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "125 000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "4"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "100 000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "5"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'envoi d'un e-mail qui atteint ou dépasse l'une des limites ci-dessous retardera ou annulera l'envoi de votre e-mail."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Outre les limites indiquées ci-dessus, tenez compte des avertissements supplémentaires énumérés ci-dessous :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si vous clonez un e-mail programmable, il ne peut pas être envoyé tant que l'original est encore en état de traitement. Vous devez attendre au moins 1 heure entre chaque envoi d'e-mails."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous ne pouvez pas effectuer d'A/B testing pour un e-mail programmable comprenant une fonction HubL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects"
        }), ", ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Créer un module d'e-mail programmable"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour créer le module d'e-mail afin d'accéder à vos données HubDB ou d'objet personnalisé :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Fichiers et modèles"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Outils de conception"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans l'angle supérieur gauche, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Fichier"
          }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Nouveau fichier"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la boîte de dialogue, sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Module"
          }), " pour le type de fichier, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Next"
          }), ". Ensuite, sélectionnez la case à cocher ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "E-mails"
          }), " et saisissez un ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nom"
          }), " pour le fichier."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Créer"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Pour activer la messagerie programmable du module :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Dans l'inspecteur à droite, activez l'interrupteur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Enable module for programmable email beta"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/programmable-email-switch0.png",
            alt: "programmable-email-switch0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Vous pouvez également activer les e-mails programmables pour un modèle d'e-mail codé en ajoutant ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "isEnabledForEmailV3Rendering: true"
          }), " en haut du fichier."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hs-fs/hubfs/Imported%20sitepage%20images/enable-design-manager-template-for-programmable-email.png?width=510&name=enable-design-manager-template-for-programmable-email.png",
            alt: "enable-design-manager-template-for-programmable-email"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois le module créé, vous ajouterez ensuite du code pour accéder aux données de votre CRM. Les exemples suivants ci-dessous montrent comment effectuer des requêtes à partir de différentes sources de données."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objets standard"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez utiliser les fonctions HubL telles que ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "crm_object"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "crm_objects"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: "crm_associations"
      }), " pour interroger des données à partir d'objets standards de votre compte, tels que des contacts, des entreprises ou des produits."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le code ci-dessous utilise la fonction HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), " pour interroger les données d'un produit avec un ID de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2444498793"
      }), " et restituer le nom, la description et le prix :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set product = crm_object(\"product\", 2444498793, \"name,description,price\") %}\n\n<div>\n  <p>\n    <span>Name: </span>\n    <span>{{ product.name }}</span>\n  </p>\n  <p>\n    <span>Description: </span>\n    <span>{{ product.description }}</span>\n  </p>\n  <p>\n    <span>Price: </span>\n    <span>{{ product.price }}</span>\n  </p>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objets personnalisés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous disposez d'un compte ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        }), " Entreprise"]
      }), ", vous pouvez interroger les données d'un objet personnalisé que vous avez créé."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le code ci-dessous récupère les données d'un objet personnalisé nommé ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Propriété"
      }), ", et renvoie les valeurs (par exemple : emplacement, prix) stockées dans les propriétés de l'objet personnalisé."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Notez que l'exemple ci-dessous utilise le nom complet de l'objet personnalisé comme premier argument lors de l'appel de la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm_objects"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "de HubL"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le nom complet commence par l'ID du compte HubSpot (affichant le préfix ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), "), suivi d'un tiret bas et du nom pluriel en minuscule de l'objet personnalisé (par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous pouvez récupérer le ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fullyQualifiedName"
        }), " d'un objet en effectuant une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " auprès de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/custom-objects",
          children: "l'API schéma d'objets CRM"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set real_estate_listings = crm_objects(\"p2990812_properties\", \"\", \"listing_name,location, price, address, type\") %}\n\n{% for home in real_estate_listings.results %}\n    {{ home.address}} <br>\n\t{{ home.price }} <br>\n\t<img\n\talt=\"{{ home.name }}\"\n\tsrc=\"{{ home.hero_image }}\"\n\tstyle=\"outline: none; max-width: 100%;\"\n\twidth=\"260px\"\n\t/>\n\t<br>\n\t<hr>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " si le nom de votre objet personnalisé contient des traits d'union (par exemple : ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "My-Custom-Object"
        }), "), ses propriétés ne peuvent ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), " être restituées dans un e-mail programmable. Vous pouvez recréer l'objet personnalisé en omettant les traits d'union ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-custom-objects",
          children: "directement dans votre compte HubSpot"
        }), " ou vous pouvez utiliser ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "l'API d'objet personnalisé."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour filtrer les données renvoyées pour chaque destinataire, vous pouvez ajouter un paramètre de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), " qui filtrera les résultats selon les propriétés de contact du destinataire. Consulter la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#get-table-rows",
        children: "liste complète d'options de filtre"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set query = \"price__lte=\"~contact.budget_max|int~\"&price__gte=\"~contact.budget_min|int~\"&city=\"~contact.city\"&order=listing_name\" %}\n{% set real_estate_listings = crm_objects(\"p2990812_Property\", query, \"listing_name,location, price, address, type\") %}\n{% for home in real_estate_listings.results %}\n\n  ...\n\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous disposez d'un compte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), ", vous utilisez les données d'un tableau HubDB dans votre e-mail."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le code ci-dessous utilise la fonction HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-rows",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_rows"
        })
      }), " pour récupérer toutes les données du tableau. Cela répertoriera toutes les propriétés immobilières dans l'e-mail, en affichant les détails de chaque propriété, ainsi que leur image."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set real_estate_listings = hubdb_table_rows(1234567) %}\n\n{% for home in real_estate_listings%}\n    {{ home.address}} <br>\n\t{{ home.price }} <br>\n\t<img\n\talt=\"{{ home.name }}\"\n\tsrc=\"{{ home.hero_image.url }}\"\n\tstyle=\"outline: none; max-width: 100%;\"\n\twidth=\"260px\"\n\t/>\n\t<br>\n\t<hr>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour filtrer les données renvoyées pour chaque destinataire, vous pouvez ajouter un paramètre de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), " qui filtrera les résultats selon les propriétés de contact spécifiques. Consulter la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb#filter-returned-rows",
        children: "liste complète d'options de filtre"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set query = \"price__lte=\"~contact.budget_max|int~\"&price__gte=\"~contact.budget_min|int~\"&persona=\"~contact.hs_persona.value~\"&order=listing_name\" %}\n\n{% for home in real_estate_listings %}\n\n\t...\n\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Dans l'exemple ci-dessus, la propriété de contact ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Budget max"
        }), " est référencée avec ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.budget_max"
        }), ", tandis que ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Buyer persona"
        }), " est référencée avec ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.hs_persona.value"
        }), ". En effet, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Buyer persona"
        }), " est une ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/property-field-types-in-hubspot",
          children: "propriété d'énumération"
        }), ", qui nécessite un élément ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".value"
        }), " supplémentaire pour analyser la valeur de propriété, contrairement aux autres types de propriétés."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Ajouter le module à un e-mail"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois le module publié, vous pourrez désormais l'ajouter au corps de l'e-mail glisser-déposer."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, naviguez vers ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-mail"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sélectionnez l'", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "e-mail"
        }), " que vous avez créé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la barre latérale gauche, sous ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content"
        }), ", cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "More"
        }), ". Trouvez votre module d'e-mail programmable, puis faites-le glisser dans le corps de l'e-mail."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/drag-and-drop-email-editor-more-modules.png",
        alt: "drag-and-drop-email-editor-more-modules"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous avez configuré le module pour filtrer les données selon des propriétés de contact spécifiques, l'aperçu de l'e-mail apparaîtra vide. En effet, l'outil e-mail n'a pas été configuré pour prévisualiser l'e-mail d'un contact spécifique."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour prévisualiser l'e-mail d'un contact spécifique :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En haut à droite, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preview"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/email-actions-menu0.png",
        alt: "email-actions-menu0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sur l'écran suivant, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Afficher en tant que contact spécifique"
        }), ", puis sélectionnez un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "contact"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/preview-email-as-specific-contact0.png",
        alt: "preview-email-as-specific-contact0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous ne devriez désormais voir que les données pertinentes pour le destinataire, filtrées par ses propriétés de contact."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/preview%20window.png",
        alt: "Capture d'écran de l'aperçu de l'e-mail"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si un modèle d'e-mail ou un module contient du code personnalisé avec un HubL non résolu, un module de variable apparaîtra à sa place."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/placeholder-module-in-programmable-email.png",
        alt: "placeholder-module-in-programmable-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Il est important de toujours disposer de données de secours à envoyer au cas où aucune ligne HubDB ou fiche d'informations d'objet personnalisé ne réponde aux critères définis. Sinon, le destinataire pourrait recevoir un e-mail vide."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Cette version bêta peut entraîner des problèmes avec les modèles existants. Il est important de tester soigneusement toute modification avant de publier et d'envoyer un e-mail en direct."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Plus de didacticiels sur HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "Création de pages dynamiques avec HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Joindre des tableaux HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "Comment créer une page d'équipe dynamique avec HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "Créer des pages dynamiques multi-niveaux via HubDB"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot Academy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/lessons/other_data_sources_hubdb_and_custom_objects",
          children: "Utiliser HubDB et les objets personnalisés dans le CMS Hub"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}