"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = exports.badge = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 132739541401;
const slug = exports.slug = 'guides/apps/extensions/calling-extensions/receive-incoming-calls';
const title = exports.title = 'CRM API | Receive calls in HubSpot when using calling apps (BETA)';
const name = exports.name = 'vNext Docs DP - Receive calls in HubSpot when using calling apps (BETA)';
const metaDescription = exports.metaDescription = 'You can receive calls in HubSpot when using calling apps.';
const badge = exports.badge = 'BETA';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      blockquote: "blockquote",
      h3: "h3",
      pre: "pre",
      code: "code",
      ul: "ul",
      li: "li",
      img: "img",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Receive calls in HubSpot when using calling apps (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/extensions/calling-extensions/receive-incoming-calls#ungate-your-account",
          children: "ungate your account"
        }), " for this beta. By using these instructions you agree to adhere to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/hs-developer-terms",
          children: "HubSpot's Developer Terms"
        }), " & ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "HubSpot's Developer Beta Terms"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.blockquote, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Developers using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/calling-sdk",
          children: "Calling SDK"
        }), " can now enable inbound calling within HubSpot. When a user receives and answers a call through your app in HubSpot, they can access call records directly in HubSpot, eliminating the need to switch back to the calling app. Calls are automatically logged in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/calling/review-calls-in-the-call-index",
          children: "Call Index Page"
        }), " where users can take real-time notes and review the call after it ends."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Install the latest version of Calling SDK"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For npm, run:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "npm i -s @hubspot/calling-extensions-sdk@latest\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For yarn, run:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "yarn add @hubspot/calling-extensions-sdk@latest\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Set user availability"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can set the user's availability using on of the following events:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Via the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "initialized"
        }), " event:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const payload = {\n  // Optional: Whether a user is logged-in\n  isLoggedIn: boolean,\n  // Optional: Whether a user is available for inbound calling\n  isAvailable: boolean,\n  // Optional: The desired widget size\n  sizeInfo: {\n    height: number,\n    width: number,\n  },\n};\n\nextensions.initialized(payload);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Via the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "userAvailable"
        }), " event:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "extensions.userAvailable();\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Via the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "userUnavailable"
        }), " event:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "extensions.userUnavailable();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Send message to notify HubSpot that an inbound call started"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You will be able to send calling lifecycle events, such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callAnswered"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callCompleted"
      }), ", in the same way it is done for outgoing calls."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const callInfo = {\n  fromNumber: string, // Required: The caller's number\n  toNumber: string, // Required: The recipient's number\n  createEngagement: boolean, // Whether HubSpot should create an engagement for this call\n};\nextensions.incomingCall(callInfo);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you’ve set ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "createEngagement"
        }), " to true, you can subscribe to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "onCreateEngagementSucceeded"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "onCreateEngagementFailed"
        }), ". It is recommended you do this so that you can enable your calling app to support ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "custom objects"
        }), ". This will allow future integration into other areas of HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCreateEngagementSucceeded(data) {\n    const {\n      /* A HubSpot created engagement id. */\n      engagementId: number,\n    } = data;\n      ...\n  }\n\nonCreateEngagementFailed(data) {\n    const {\n      error: { message: string }\n    } = data;\n      ...\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "4. Receive caller ID matches"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You will be able to subscribe to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "onCallerIdMatchSucceeded"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "onCalledIdMatchFailed"
        }), ". This will enable you to receive contact matching data for the incoming call that previously had to be obtained via the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search",
          children: "Search API"
        }), ", and will solve its rate limitations."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCallerIdMatchSucceeded: data => {\n      /* HubSpot has fetched caller id matches for this call. */\n    const {\n      callerIdMatches: (ContactIdMatch | CompanyIdMatch)[];\n    } = data;\n    }\n\nonCallerIdMatchFailed: data => {\n      /* HubSpot has failed to fetch caller id matches for this call. */\n    const {\n      error: { message: string }\n    } = data;\n    }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "type ObjectCoordinates = {\n  portalId: number;\n  objectTypeId: string;\n  objectId: number;\n}\n\ntype ContactIdMatch = {\n  callerIdType: 'CONTACT';\n  objectCoordinates: ObjectCoordinates;\n  firstName: string;\n  lastName: string;\n  email: string;\n}\n\ntype CompanyIdMatch = {\n  callerIdType: 'COMPANY';\n  objectCoordinates: ObjectCoordinates;\n  name: string;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "5. Navigate to a record page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you receive the caller ID matches, you can send HubSpot a message to navigate to a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "contact or company record page"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const data = {\n  objectCoordinates: ObjectCoordinates, // from onCallerIdMatchSucceeded\n};\n\nextensions.navigateToRecord(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once the call engagement is created, HubSpot will redirect to the contact page specified in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "navigateToRecord"
      }), " payload and will sync with the SDK in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onReady"
      }), " event. You'll need to re-initialize the SDK using the engagement ID and show an incoming call within the iframe."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Receive an engagementId for an existing inbound call\ntype Payload = {\n  engagementId: number | undefined\n}\n\n// Message indicating that HubSpot is ready to receive messages\nonReady(payload) {\n    // Send initialized message to HubSpot to indicate that the call widget is also ready\n    extensions.initialized(payload);\n    if (payload.engagementId) {\n      // Initialize calling state in the app for existing inbound call\n      ...\n    }\n    ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the following sections, preview how the incoming call feature will work in your calling app."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "6. Ungate your account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To ungate your account for this beta, open your browser developer console from a HubSpot tab, and set the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "localStorage['LocalSettings:Calling:supportsInboundCalling'] = true;\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "7. Set the provider"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before logging in to your calling app, you'll need to select the provider from your call settings:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "General"
        }), ". Then, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Calling"
        }), " tab at the top."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Make and receive calls through"
        }), " dropdown menu, then select your calling app."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/Callin%20apps-%20make%20calls.png",
        alt: "Callin apps- make calls"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once the preferred provider is selected, incoming calls will ", (0, _jsxRuntime.jsx)("u", {
        children: "only"
      }), " be received through the selected provider. HubSpot will not support receiving incoming calls from multiple providers in this version."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you wish to change the provider for receiving calls, you'll have to go back to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#call-settings",
        children: "your call settings"
      }), " to make the change."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note**"
        }), ":** for outbound calls, you can ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/calling/integrate-a-third-party-calling-provider-with-hubspot",
          children: "continue to switch providers"
        }), "from the contact record."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/Calling%20apps-%20call%20contact.png",
        alt: "Calling apps- call contact"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "8. Receive incoming calls"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you've not already set up an integration with any of the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps/sales/calling",
        children: "calling apps"
      }), ", click ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/integrate-a-third-party-calling-provider-with-hubspot",
        children: "here"
      }), " to learn more."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Log in to your calling app through the call widget in HubSpot. The call widget can be accessed on the main navigation bar."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/Calling%20apps-%20make%20a%20call.png",
        alt: "Calling apps- make a call"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Set availability to e HubSpot to start receiving calls."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/Calling%20apps-%20availability.png",
        alt: "Calling apps- availability"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Answer inbound calls from the call remote."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the behavior may vary slightly based on each calling apps' implementation."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/answer-call-bananaphone.png",
        alt: "answer-call-bananaphone"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once the call is completed, the inbound call gets logged in the Call Index page. Missed calls will also get logged here."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if the call widget is minimized but you're set to ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Available"
        }), ", you will still receive calls. If the call tab is closed during an ongoing call, the call will get disconnected."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "9. Notify users"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "supportsInboundCalling"
      }), " is set to false by default, HubSpot displays a banner under each user's preferences dropdown."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/banner.png",
        alt: "banner"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you have set your app settings ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/calling-sdk#using-the-calling-settings-endpoint",
        children: "using the calling settings endpoint"
      }), ", use the PATCH endpoint to change ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "supportsInboundCalling"
      }), "to true. This will remove the banner, and lets HubSpot users know your calling app has completed all the steps necessary to receive inbound calls."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request PATCH \\\n --url 'https://api.hubapi.com/crm/v3/extensions/calling/APP_ID/settings?hapikey=DEVELOPER_ACCOUNT_API_KEY' \\\n --header 'accept: application/json' \\\n --header 'content-type: application/json' \\\n --data '{\"supportsInboundCalling\":true}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once the above steps are complete, you can continue to extend the functionality of your calling app in HubSpot by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/third-party-calling",
        children: "setting up third-party calling in help desk (BETA)"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}