"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45278701401;
const slug = exports.slug = 'guides/api/app-management/webhooks/overview';
const title = exports.title = 'Webhooks-API ';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Webhooks API';
const metaDescription = exports.metaDescription = 'Übersicht für die Webhooks-API, die es Ihnen ermöglicht, Events zu abonnieren, die in einem HubSpot-Account auftreten, in dem Ihre Integration installiert ist.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "bereiche",
  "label": "Bereiche",
  "parent": null
}, {
  "depth": 0,
  "id": "webhook-einstellungen",
  "label": "Webhook-Einstellungen",
  "parent": null
}, {
  "depth": 1,
  "id": "einstellungen-in-ihrem-entwickler-account-verwalten",
  "label": "Einstellungen in Ihrem Entwickler-Account verwalten",
  "parent": "webhook-einstellungen"
}, {
  "depth": 1,
  "id": "einstellungen-%C3%BCber-api-verwalten",
  "label": "Einstellungen über API verwalten",
  "parent": "webhook-einstellungen"
}, {
  "depth": 0,
  "id": "webhook-abonnements",
  "label": "Webhook-Abonnements",
  "parent": null
}, {
  "depth": 1,
  "id": "abonnements-in-ihrem-entwickler-account-erstellen",
  "label": "Abonnements in Ihrem Entwickler-Account erstellen",
  "parent": "webhook-abonnements"
}, {
  "depth": 1,
  "id": "abonnements-%C3%BCber-api-erstellen",
  "label": "Abonnements über API erstellen",
  "parent": "webhook-abonnements"
}, {
  "depth": 1,
  "id": "abonnements-abrufen",
  "label": "Abonnements abrufen",
  "parent": "webhook-abonnements"
}, {
  "depth": 1,
  "id": "ein-neues-abonnement-erstellen",
  "label": "Ein neues Abonnement erstellen",
  "parent": "webhook-abonnements"
}, {
  "depth": 1,
  "id": "ein-abonnement-aktualisieren",
  "label": "Ein Abonnement aktualisieren",
  "parent": "webhook-abonnements"
}, {
  "depth": 1,
  "id": "ein-abonnement-l%C3%B6schen",
  "label": "Ein Abonnement löschen",
  "parent": "webhook-abonnements"
}, {
  "depth": 0,
  "id": "webhook-payloads",
  "label": "Webhook-Payloads",
  "parent": null
}, {
  "depth": 0,
  "id": "datenschutzkonforme-kontaktl%C3%B6schungen",
  "label": "Datenschutzkonforme Kontaktlöschungen",
  "parent": null
}, {
  "depth": 0,
  "id": "sicherheit",
  "label": "Sicherheit",
  "parent": null
}, {
  "depth": 0,
  "id": "erneute-versuche",
  "label": "Erneute Versuche",
  "parent": null
}, {
  "depth": 0,
  "id": "beschr%C3%A4nkungen",
  "label": "Beschränkungen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      em: "em",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Webhooks"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Webhooks-API ermöglicht es Ihnen, Events zu abonnieren, die in einem HubSpot-Account auftreten, bei dem Ihre Integration installiert ist. Anstatt einen API-Aufruf vorzunehmen, wenn ein Event in einem verknüpften Account stattfindet, kann HubSpot eine HTTP-Anfrage an einen Endpunkt senden, den Sie konfigurieren. Sie können abonnierte Events in den Einstellungen Ihrer App oder mithilfe der unten aufgeführten Endpunkte konfigurieren. Webhooks können skalierbarer sein als ein regelmäßiges Abfragen auf Änderungen, insbesondere für Apps mit einer großen Installationsbasis."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für die Verwendung der Webhooks-API ist Folgendes erforderlich:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie müssen eine HubSpot-App für die Verwendung von Webhooks einrichten, indem Sie die Events abonnieren, über die Sie informiert werden möchten, und eine URL angeben, um diese Benachrichtigungen zu senden. Weitere Informationen zur Erstellung einer App finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/integration-platform-api-requirements",
          children: "Dokumentation der Voraussetzungen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sie müssen einen öffentlich verfügbaren und sicheren (HTTPS)-Endpunkt für diese URL bereitstellen, der die in dieser Dokumentation angegebenen Webhook-Payloads verarbeiten kann."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Webhooks werden für eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "HubSpot-App"
      }), " eingerichtet, nicht für einzelne Accounts. Alle Accounts, die Ihre App installieren, indem Sie den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0",
        children: "OAuth-Prozess"
      }), " durchlaufen, abonnieren deren Webhook-Abonnements."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können CRM-Objekt-Events abonnieren, die Kontakte, Unternehmen, Deals, Tickets, Produkte und Einzelposten sowie Konversationen-Events umfassen."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Sie können auch ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
            children: "Webhooks in einer privaten App verwalten"
          }), ". In privaten Apps können Webhook-Einstellungen nur in den Einstellungen innerhalb Ihrer privaten App und derzeit nicht über die API bearbeitet werden können."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Um Konversationen-Webhooks zu abonnieren, benötigen Sie Zugriff auf die ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/conversations/inbox-and-messages",
            children: "Konversationen-Postfach- und Nachrichten-APIs"
          }), ", die sich derzeit in der ", (0, _jsxRuntime.jsx)("u", {
            children: "Beta-Phase"
          }), " befindet."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bereiche"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Webhooks zum Abonnieren von CRM-Events verwenden zu können, muss Ihre App so konfiguriert werden, dass sie den zugeordneten Bereich erfordert, der dem CRM-Objekttyp entspricht, den Sie abonnieren möchten. Wenn Sie beispielsweise Kontakt-Events abonnieren möchten, müssen Sie den Bereich ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm.objects.contacts.read"
      }), " anfragen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie Abonnements in den Einstellungen Ihrer öffentlichen App erstellen, werden Sie aufgefordert, den erforderlichen Bereich im Bereich ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Neue Webhook-Abonnements erstellen"
        }), " hinzuzufügen, bevor Sie die Erstellung Ihres Abonnements abschließen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie ein Abonnement erstellen, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "-Anfrage an den Endpunkt ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/webhooks/v3/{appId}/subscriptions"
        }), " durchführen, enthält die Antwort einen Fehler, der den Namen des Bereichs angibt, den Sie in den Einstellungen der Benutzeroberfläche Ihrer öffentlichen App konfigurieren müssen."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Ihre App bereits Webhooks verwendet, können Sie keine Bereiche entfernen, die für aktive Webhook-Abonnements erforderlich sind, ohne die Abonnements zuvor zu pausieren und zu entfernen."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie können die erforderlichen Bereiche für jeden Webhook-Abonnementtyp in der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#webhook-subscriptions",
          children: "folgenden Tabelle"
        }), " überprüfen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0#scopes",
        children: "Weitere Informationen zu Bereichen"
      }), " und zum ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/app-management/oauth/tokens#initiate-an-integration-with-oauth-2.0",
        children: "Einrichten der Autorisierungs-URL"
      }), " für Ihre App finden Sie in der OAuth-Dokumentation."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Webhook-Einstellungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bevor Sie Ihre Webhook-Abonnements einrichten, müssen Sie eine URL angeben, an die diese Benachrichtigungen gesendet werden. Befolgen Sie die Anweisungen in den folgenden Abschnitten, um zu erfahren, wie Sie vollständig Abonnements für Ihre App konfigurieren."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Webhook-Einstellungen können bis zu fünf Minuten zwischengespeichert werden. Wenn Sie Änderungen an der Webhook-URL, den Gleichzeitigkeitsbeschränkungen oder den Abonnementeinstellungen vornehmen, kann es bis zu fünf Minuten dauern, bis Ihre Änderungen wirksam werden."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "HubSpot legt eine Gleichzeitigkeitsbeschränkung von 10 Anfragen fest, wenn Abonnement-Event-Daten gesendet werden, die mit einem Account verknüpft sind, der Ihre App installiert hat. Diese Gleichzeitigkeitsbeschränkung ist die maximale Anzahl laufender Anfragen, die HubSpot gleichzeitig versucht. Jede Anfrage kann bis zu 100 Events enthalten."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einstellungen in Ihrem Entwickler-Account verwalten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Ihre URL und Ihre Event-Steuerungsbeschränkung über die Konfigurationsseite Ihrer App in Ihrem Entwickler-Account verwalten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Gehen Sie in Ihrem Entwickler-Account zu Ihrem ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "App"
          }), "-Dashboard."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Namen"
          }), " der App, für die Sie Webhooks einrichten möchten."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/app_id_list.png?width=600&name=app_id_list.png",
            alt: "app_id_list"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Gehen Sie im Menü der Seitenleiste links zu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Webhooks"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Geben Sie im Feld ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Ziel-URL"
          }), " die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " ein, an die HubSpot eine POST-Anfrage sendet, wenn Events ausgelöst werden."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Verwenden Sie die Einstellung ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Event-Steuerung"
          }), ", um die maximale Anzahl von Evenrts festzulegen, die HubSpot zu senden versucht."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/webhook_settings.png?width=600&name=webhook_settings.png",
        alt: "webhook_settings"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Speichern"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einstellungen über API verwalten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können die folgenden Endpunkte und Ihren ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/integrations/how-do-i-get-my-hubspot-api-key",
        children: "Entwickler-API-Schlüssel"
      }), " verwenden, um die Webhook-Einstellungen für eine App programmgesteuert zu konfigurieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um alle Webhook-Einstellungen anzuzeigen, die derzeit für eine App konfiguriert sind, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/settings"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie müssen die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/faq/how-do-i-find-the-app-id",
        children: "App-ID"
      }), " in der Anfrage angeben, die Sie unter dem Namen der App in Ihrem ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "App"
      }), "-Dashboard oder auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Authentifizierung"
      }), " in den Einstellungen Ihrer App finden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Einstellungen-Objekt enthält die folgenden Felder:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "webhookUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die URL, an die HubSpot Webhook-Benachrichtigungen sendet. Diese URL muss über HTTPS bereitgestellt werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Gleichzeitigkeitsbeschränkung für die Webhook-URL. Dieser Wert muss eine Zahl größer als fünf sein."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Änderungen an diesen Einstellungen vorzunehmen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/settings"
      }), " durch und fügen Sie die folgenden Felder in den Anfragetext ein:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "targetUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die öffentlich verfügbare URL für HubSpot zum Aufrufen und an die Event-Payloads geliefert werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "throttling"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Konfigurieren Sie Webhook-Steuerungsdetails in diesem Objekt. Das Steuerungsobjekt enthält die Felder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Zeitskala für diese Einstellung. Kann entweder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SECONDLY"
            }), " (pro Sekunde) oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ROLLING_MINUTE"
            }), " (pro Minute) sein."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxConcurrentRequests"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die maximale Anzahl von HTTP-Anfragen, die HubSpot innerhalb eines durch ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "period"
            }), " festgelegten Zeitraums an Ihre App zu senden versucht."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre Anfrage kann beispielsweise wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PUT request to https://api.hubapi.com/webhooks/v3/{appId}/settings\n\n{\n  \"throttling\": {\n    \"period\": \"SECONDLY\",\n    \"maxConcurrentRequests\": 10\n  },\n  \"targetUrl\": \"https://www.example.com/hubspot/target\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Webhook-Abonnements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie Ihre Webhook-URL und Ihre Event-Steuerungsbeschränkung eingerichtet haben, müssen Sie ein oder mehrere Abonnements erstellen. Webhook-Abonnements teilen HubSpot mit, welche Events Ihre spezifische App erhalten möchten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abonnements gelten für alle Kunden, die Ihre Integration installiert haben. Dies bedeutet, dass Sie nur einmal festlegen müssen, welche Abonnements Sie benötigen. Nachdem Sie ein Abonnement für eine Anwendung aktiviert haben, beginnt sie automatisch, Webhooks für alle Kunden abzurufen, die Ihre Anwendung installiert haben, und Ihre Integration beginnt, Webhook-Trigger von allen neuen Kunden zu empfangen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei allen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationChange"
      }), "-Webhook-Abonnements löst der Webhook zwei Events für beide Seiten der Zuordnung aus."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie zwei Kontakte zuordnen, löst ein Abonnement für ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.associationChange"
        }), " zwei Events aus, die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 1 to contact 2"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 2 to contact 1"
        }), " darstellen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie beim Zuordnen eines Unternehmens die zwei Webhook-Abonnements ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.associationChange"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company.associationChange"
        }), "haben, erhalten Sie zwei Events. Diese stellen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact 1 to company 1"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company 1 to contact 1"
        }), " dar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die folgenden Abonnementtypen werden unterstützt und können als Wert für das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), "-Feld beim Erstellen von Abonnements über API verwendet werden:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Abonnementtyp"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Erforderlicher Bereich"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Kontakt im Account eines Kunden erstellt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Kontakt im Account eines Kunden gelöscht wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Kontakt mit einem anderen zusammengeführt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn bei einem Kontakt eine Zuordnung zwischen sich und einem anderen unterstützten Webhook-Objekt (Kontakt, Unternehmen, Deal, Ticket, Einzelposten oder Produkt) hinzugefügt oder entfernt wurde."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein gelöschter Kontakt wiederhergestellt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Sie erhalten eine Benachrichtigung, wenn ein Kontakt aus ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview",
              children: "Datenschutzschutzgründen"
            }), " gelöscht wird."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn eine bestimmte Eigenschaft für einen Kontakt im Account eines Kunden geändert wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Unternehmen im Account eines Kunden erstellt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Unternehmen im Account eines Kunden gelöscht wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn eine bestimmte Eigenschaft für ein Unternehmen im Account eines Kunden geändert wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn bei einem Unternehmen eine Zuordnung zwischen sich und einem anderen unterstützten Webhook-Objekt (Kontakt, Unternehmen, Deal, Ticket, Einzelposten oder Produkt) hinzugefügt oder entfernt wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein gelöschtes Unternehmen wiederhergestellt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Unternehmen mit einem anderen zusammengeführt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Deal im Account eines Kunden erstellt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Deal im Account eines Kunden gelöscht wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn bei einem Deal eine Zuordnung zwischen sich und einem anderen unterstützten Webhook-Objekt (Kontakt, Unternehmen, Deal, Ticket, Einzelposten oder Produkt) hinzugefügt oder entfernt wurde."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein gelöschter Deal wiederhergestellt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Deal mit einem anderen zusammengeführt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn eine bestimmte Eigenschaft für einen Deal im Account eines Kunden geändert wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Ticket im Account eines Kunden erstellt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Ticket im Account eines Kunden gelöscht wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn eine bestimmte Eigenschaft für ein Ticket im Account eines Kunden geändert wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn bei einem Ticket eine Zuordnung zwischen sich und einem anderen unterstützten Webhook-Objekt (Kontakt, Unternehmen, Deal, Ticket, Einzelposten oder Produkt) hinzugefügt oder entfernt wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein gelöschtes Ticket wiederhergestellt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Ticket mit einem anderen zusammengeführt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Produkt im Account eines Kunden erstellt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Produkt im Account eines Kunden gelöscht wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein gelöschtes Produkt wiederhergestellt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Produkt mit einem anderen zusammengeführt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein bestimmtes Produkt für ein beliebiges Produkt im Account eines Kunden geändert wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Artikel im Account eines Kunden erstellt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Artikel im Account eines Kunden gelöscht wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.associationChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn bei einem Produkt/Artikel eine Zuordnung zwischen sich und einem anderen unterstützten Webhook-Objekt (Kontakt, Unternehmen, Deal, Ticket, Einzelposten oder Produkt) hinzugefügt oder entfernt wurde."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.restore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein gelöschter Artikel wiederhergestellt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.merge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Artikel mit einem anderen zusammengeführt wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn eine bestimmte Eigenschaft für einen Artikel im Account eines Kunden geändert wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die folgenden Konversationen-Abonnementtypen stehen Ihnen zum Abonnieren zur Verfügung, wenn Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/inbox-and-messages",
        children: "Conversations-Postfach- und Nachrichten-API"
      }), " verwenden, die sich derzeit ", (0, _jsxRuntime.jsx)("u", {
        children: "in der Beta-Phase"
      }), " befindet:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Abonnementtyp"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Bereich"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.creation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein neuer Thread in einem Account erstellt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.deletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Thread in einem Account archiviert oder vorläufig gelöscht wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.privacyDeletion"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn ein Thread in einem Account dauerhaft gelöscht wird."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.propertyChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn eine Eigenschaft in einem Thread geändert wurde."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversation.newMessage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sie erhalten eine Benachrichtigung, wenn eine neue Nachricht in einem Thread empfangen wurde."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für Abonnements von Eigenschaftsänderungen müssen Sie angeben, für welche Eigenschaft Sie benachrichtigt werden möchten: Sie können mehrere Abonnements von Eigenschaftsänderungen angeben. Wenn der Account eines Kunden nicht über die Eigenschaft verfügt, die Sie in einem Abonnement angeben, erhalten Sie keine Webhooks von diesem Kunden für diese Eigenschaft."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bestimmte Eigenschaften sind für Abonnements von CRN-Eigenschaftsänderungen nicht verfügbar. Diese Eigenschaften sind:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "num_unique_conversion_events"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_lastmodifieddate"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/conversations/inbox-and-messages",
        children: "Conversations-Postfach- und Nachrichten-API"
      }), " verwenden, derzeit ", (0, _jsxRuntime.jsx)("u", {
        children: "in der Beta-Phase"
      }), ", sind die folgenden Eigenschaften verfügbar:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "assignedTo"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " Der Konversationsthread wurde neu zugewiesen oder nicht zugewiesen. Wenn der Thread neu zugewiesen wurde, ist der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " eine Akteur-ID in der Webhooks-Payload. Wenn er nicht zugewiesen wird, ist er leer."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "status"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " Der Status des Konversationsthreads hat sich geändert. In den Webhooks-Payload ist der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " entweder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "OPEN"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CLOSED"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "isArchived"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " Der Konversationsthread wurde wiederhergestellt. Der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyValue"
        }), " in der Webhooks-Payload ist immer ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "FALSE"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abonnements in Ihrem Entwickler-Account erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Webhook-Abonnements in Ihrem HubSpot-Entwickler-Account erstellen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem HubSpot-Entwickler-Account zum ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "-Dashboard."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " einer App."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie im Menü der Seitenleiste links zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Abonnement erstellen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im rechten Bereich auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Welche Objekttypen"
        }), " und wählen Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Objekte"
        }), " aus, für die Sie ein Abonnement erstellen möchten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Welche Events beobachten?"
        }), " und wählen Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Event-Typen"
        }), " aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/create-contact-create-subscription.png",
        alt: "create-contact-create-subscription"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wenn Sie ein Abonnement für Eigenschaftsänderungs-Events erstellen, klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Welche Eigenschaften?"
          }), " und wählen Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Eigenschaften"
          }), " aus, auf die überwacht werden soll."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://developers.hubspot.com/hs-fs/hubfs/webhook_select_properties.png?width=450&name=webhook_select_properties.png",
            alt: ""
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Abonnieren"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Abonnement wird in Ihren Webhooks-Einstellungen angezeigt. Neue Abonnements werden in einem pausierten Zustand erstellt, daher müssen Sie das Abonnement aktivieren, damit Webhooks Folgendes senden können:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bewegen Sie den Mauszeiger im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Event-Abonnements"
        }), " über den Objekttyp und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Abonnements anzeigen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Aktivieren Sie das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kontrollkästchen"
        }), " neben dem Event und klicken Sie dann im Tabellen-Header auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aktivieren"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/activate-subscription.png",
        alt: "activate-subscription"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abonnements über API erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können mithilfe der folgenden Endpunkte programmgesteuert Abonnements erstellen. Sie müssen Ihren Entwickler-API-Schlüssel verwenden, wenn Sie Anfragen an diese Endpunkte stellen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein Abonnementobjekt kann die folgenden Felder enthalten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Nummer, die die eindeutige ID eines Abonnements darstellt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Zeitpunkt in Millisekunden, zu dem dieses Abonnement erstellt wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Benutzer-ID, die dem Benutzer zugeordnet ist, der das Abonnement erstellt hat."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Dies zeigt an, ob das Abonnement aktiviert ist und aktiv Benachrichtigungen auslöst. Der Wert kann ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " sein."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ des Abonnements. Die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview#webhook-subscriptions",
              children: "Tabelle"
            }), " am Anfang dieses Abschnitts enthält die verfügbaren Abonnementtypen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name der Eigenschaft, für die das Abonnement auf Änderungen überwacht. Dies wird nur für Abonnementtypen für Eigenschaftsänderungen benötigt."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Abonnements abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die Liste der Abonnements abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Antwort ist ein Array von Objekten, die Ihre Abonnements darstellen. Jedes Objekt enthält Informationen zum Abonnement wie die ID, Erstellungsdatum, Typ und Angabe, ob es derzeit aktiv ist oder nicht. Eine Antwort könnte beispielsweise so aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example GET request to https://api.hubapi.com/webhooks/v3/{appId}/subscriptions\n\n[\n  {\n    \"id\": 25,\n    \"createdAt\": 1461704185000,\n    \"createdBy\": 529872,\n    \"eventType\": \"contact.propertyChange\",\n    \"propertyName\": \"lifecyclestage\",\n    \"active\": false\n  },\n  {\n    \"id\": 59,\n    \"createdAt\": 1462388498000,\n    \"createdBy\": 529872,\n    \"eventType\": \"company.creation\",\n    \"active\": false\n  },\n  {\n    \"id\": 108,\n    \"createdAt\": 1463423132000,\n    \"createdBy\": 529872,\n    \"eventType\": \"deal.creation\",\n    \"active\": true\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ein neues Abonnement erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein neues Abonnement zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Anfragetext können Sie die folgenden Felder einfügen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Typ des Abonnements."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name der Eigenschaft, für die das Abonnement auf Änderungen überwacht. Dies wird nur für Abonnementtypen für Eigenschaftsänderungen benötigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Dies zeigt an, ob das Abonnement aktiviert ist und aktiv Benachrichtigungen auslöst. Der Wert kann ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " sein."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie müssen nicht ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdAt"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdBy"
      }), " einschließen, da diese Felder automatisch festgelegt werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihr Anfragetext kann beispielsweise wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to https://api.hubapi.com/webhooks/v3/{appId}/subscriptions\n\n{\n  \"eventType\": \"company.propertyChange\",\n  \"propertyName\": \"companyname\",\n  \"active\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), " muss ein gültiger Abonnementtyp wie im Abschnitt oben definiert sein und der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyName"
      }), " muss ein gültiger Eigenschaftsname sein. Wenn ein Kunde keine Eigenschaft definiert hat, die diesem Wert entspricht, erhalten Sie bei diesem Abonnement keine Benachrichtigungen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ein Abonnement aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Abonnement zu aktivieren oder zu pausieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions/{subscriptionId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Schließen Sie im Anfragetext Folgendes ein:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Dies zeigt an, ob das Abonnement aktiviert ist und aktiv Benachrichtigungen auslöst. Der Wert kann ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " sein."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ein Abonnement löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Abonnement zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks/v3/{appId}/subscriptions/{subscriptionId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Webhook-Payloads"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Endpunkt unter der Ziel-URL, die Sie in den Webhooks Ihrer App angeben, erhält ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfragen, die JSON-formatierte Daten von HubSpot enthalten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um sicherzustellen, dass die Anfragen, die Sie auf Ihrem Webhook-Endpunkt erhalten, tatsächlich von HubSpot stammen, füllt HubSpot einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signatur"
      }), "-Header mit einem SSHA-256-Hash aus, der mithilfe des Client-Geheimnisses Ihrer App in Kombination mit Details der Anfrage erstellt wurde. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "Validierung von Anfragesignaturen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verwenden Sie die folgenden Tabellen, um Details zu Feldern anzuzeigen, die in der Payload enthalten sein können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ID des Objekts, das erstellt, geändert oder gelöscht wurde. Bei Kontakten ist das die Kontakt-ID, bei Unternehmen die Unternehmens-ID, bei Deals die Deal-ID, und bei Konversationen die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "Thread-ID"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dies wird nur für Eigenschaftsänderungsabonnements gesendet und ist der Name der Eigenschaft, die geändert wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyValue"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dies wird nur für Eigenschaftsänderungsabonnements gesendet und stellt den neuen Wert dar, der für die Eigenschaft festgelegt ist, die die Benachrichtigung ausgelöst hat."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "changeSource"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Quelle der Änderung. Dies kann eine der Änderungsquellen sein, die in Verläufen von Kontakteigenschaften angezeigt werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Events, das diese Benachrichtigung ausgelöst hat. Es ist nicht gewährleistet, dass dieser Wert eindeutig ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Abonnements, das eine Benachrichtigung über das Event ausgelöst hat."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/de/account/manage-multiple-hubspot-accounts#check-your-current-account",
              children: "HubSpot-Account-ID"
            }), " des Kunden, in der das Event aufgetreten ist."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID Ihrer Anwendung. Dies wird in Fällen verwendet, wenn Sie mehrere Anwendungen haben, die auf dieselbe Webhook-URL verwiesen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wann dieses Event aufgetreten ist, als Millisekunden-Zeitstempel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Typ des Events, für das diese Benachrichtigung ist. Überprüfen Sie dazu die Liste der unterstützten Abonnementtypen oben im Abschnitt zu Webhooks-Abonnements."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attemptNumber"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Beginnend mit 0, der wievielte Versuch dies ist, Ihren Service über dieses Event zu benachrichtigen. Wenn Ihr Service eine Zeitüberschreitung oder wie unter ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Erneute Versuche"
            }), " unten beschriebenen eine Fehlermeldung verursacht, versucht HubSpot, die Benachrichtigung erneut zu senden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dies wird nur angezeigt, wenn ein Webhook auf neue Nachrichten zu einem Thread überwacht. Es ist die ID der neuen Nachricht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "messageType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Dies wird nur angezeigt, wenn ein Webhook auf neue Nachrichten zu einem Thread überwacht. Es stellt den Typ der Nachricht dar, die Sie senden. Dieser Wert kann entweder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "MESSAGE"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMMENT"
            }), " sein."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Gewinners der Zusammenführung, also des Datensatzes, der nach der Zusammenführung verbleibt. Auf der HubSpot-Benutzeroberfläche für das Zusammenführen ist dies der Datensatz auf der rechten Seite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mergedObjectIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Array von IDs, die die Datensätze darstellen, die mit dem Zusammenführungsgewinner zusammengeführt werden. Auf der HubSpot-Benutzeroberfläche für das Zusammenführen ist dies der Datensatz auf der linken Seite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "newObjectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ID des Datensatzes, der als Ergebnis der Zusammenführung erstellt wird. Dies ist getrennt von ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            }), ", da in einigen Fällen aufgrund der Zusammenführung ein neuer Datensatz erstellt wird."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numberOfPropertiesMoved"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Ganzzahl, die angibt, wie viele Eigenschaften während der Zusammenführung übertragen wurden."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ der Zuordnung. Folgende Typen gibt es:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTACT_TO_CONTACT"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "COMPANY_TO_COMPANY"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_LINE_ITEM"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_TICKET"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DEAL_TO_DEAL"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_CONTACT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_COMPANY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_DEAL"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TICKET_TO_TICKET"
                }), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "LINE_ITEM_TO_DEAL"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Datensatzes, von dem aus die Zuordnungsänderung vorgenommen wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des sekundären Datensatzes im Zuordnungs-Event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationRemoved"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein boolescher Wert, der Folgendes darstellt:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": Der Webhook wurde durch Entfernen einer Zuordnung ausgelöst."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), ": Der Webhook wurde durch Erstellen einer Zuordnung ausgelöst."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isPrimaryAssociation"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein boolescher Wert, der Folgendes darstellt:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": Der sekundäre Datensatz ist die ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/de/crm-setup/associate-records#primary-company-information",
                  children: "primäre Zuordnung"
                }), " des Datensatzes, von dem aus die Zuordnungsänderung vorgenommen wurde."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), ": Der Datensatz ist ", (0, _jsxRuntime.jsx)("u", {
                  children: "nicht"
                }), " die primäre Zuordnung des Datensatzes, von dem aus die Zuordnungsänderung vorgenommen wurde. "]
              })]
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Bitte beachten"
            }), ": Wenn Sie eine primäre Zuordnungsinstanz zwischen zwei Objektdatensätzen erstellen, wird auch die entsprechende nicht primäre Zuordnung erstellt. Dies kann zu zwei Webhook-Nachrichten führen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n[\n  {\n    \"objectId\": 1246965,\n    \"propertyName\": \"lifecyclestage\",\n    \"propertyValue\": \"subscriber\",\n    \"changeSource\": \"ACADEMY\",\n    \"eventId\": 3816279340,\n    \"subscriptionId\": 25,\n    \"portalId\": 33,\n    \"appId\": 1160452,\n    \"occurredAt\": 1462216307945,\n    \"eventType\": \"contact.propertyChange\",\n    \"attemptNumber\": 0\n  },\n  {\n    \"objectId\": 1246978,\n    \"changeSource\": \"IMPORT\",\n    \"eventId\": 3816279480,\n    \"subscriptionId\": 22,\n    \"portalId\": 33,\n    \"appId\": 1160452,\n    \"occurredAt\": 1462216307945,\n    \"eventType\": \"contact.creation\",\n    \"attemptNumber\": 0\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wie oben gezeigt, sollten Sie eigentlich ein Array von Objekten in einer einzigen Anfrage erhalten. Die Batch-Größe kann variieren, liegt aber unter 100 Benachrichtigungen. HubSpot sendet mehrere Benachrichtigungen, wenn innerhalb eines kurzen Zeitraums sehr viele Events aufgetreten sind. Wenn Sie beispielsweise neue Kontakte abonniert haben und ein Kunde eine große Anzahl von Kontakten importiert, sendet HubSpot Ihnen die Benachrichtigungen für diese importierten Kontakte batchweise und nicht einen pro Anfrage."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot gibt keine Gewährleistung, dass Sie diese Benachrichtigungen in der Reihenfolge erhalten, in der sie aufgetreten ist. Verwenden Sie die o", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ccurredAt"
      }), "-Eigenschaft für jede Benachrichtigung, um zu ermitteln, wann das Event aufgetreten ist, das die Benachrichtigung ausgelöst hat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot gibt ebenfalls keine Gewährleistung, dass Sie nur eine einzelne Benachrichtigung für ein Event erhalten. Obwohl dies nur selten vorkommen sollte, ist es möglich, dass HubSpot Ihnen ein und dieselbe Benachrichtigung mehrmals sendet."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Datenschutzkonforme Kontaktlöschungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot-Benutzer haben die Möglichkeit, einen Kontaktdatensatz dauerhaft zu löschen, um Datenschutzgesetze einzuhalten. Erfahren Sie mehr über die Durchführung einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/contacts/how-do-i-perform-a-gdpr-delete-in-hubspot",
        children: "DSGVO-konformen Löschung"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können den Abonnementtyp ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact.privacyDeletion"
      }), " abonnieren, um Webhook-Benachrichtigungen zu erhalten, wenn ein Benutzer eine datenschutzkonforme Kontaktlöschung durchführt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Benachrichtigungen zu Löschungen aus Datenschutzgründen weisen einige spezielle Verhaltensweisen auf:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Eine Löschung aus Datenschutzgründen löst auch das Event der Kontaktlöschung aus, sodass Sie zwei Benachrichtigungen erhalten, wenn Sie beide Events abonniert haben."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Diese Benachrichtigungen werden nicht unbedingt in einer bestimmten Reihenfolge oder im selben Batch von Nachrichten gesendet. Sie müssen die Objekt-ID verwenden, um die separaten Nachrichten abzugleichen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sicherheit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um sicherzustellen, dass die Anfragen, die Sie auf Ihrem Webhook-Endpunkt erhalten, tatsächlich von HubSpot stammen, füllt HubSpot einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signatur"
      }), "-Header mit einem SSHA-256-Hash der Verkettung des App-Geheimnisses für Ihre Anwendung und dem Anfragetext, den HubSpot sendet."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um diese Signatur zu verifizieren, verketten Sie das App-Geheimnis Ihrer Anwendung und den nicht-geparsten Anfragetext der Anfrage, die Sie verarbeiten, und rufen Sie einen SHA-256-Hash des Ergebnisses ab. Vergleichen Sie resultierenden Hash mit dem Wert der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), ". Wenn diese Werte übereinstimmen, wird dadurch verifiziert, dass diese Anforderung von HubSpot stammt. Oder die Anfrage kam von jemand anderem, der Ihr App-Geheimnis kennt. Es ist wichtig, diesen Wert geheim zu halten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn diese Werte nicht übereinstimmen, wurde diese Anfrage möglicherweise während des Transfers manipuliert, oder jemand spooft Webhook-Benachrichtigungen an Ihren Endpunkt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "die Validierung von Signaturanfragen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erneute Versuche"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn bei Ihrem Service Probleme bei der Datenverarbeitung zu irgendeinem Zeitpunkt auftreten, versucht HubSpot, diese Benachrichtigungen bis zu 10 Mal erneut zu senden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot versucht es in den folgenden Fällen erneut:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Verbindung fehlgeschlagen:"
        }), " HubSpot kann eine http-Verbindung mit der angegebenen Webhook-URL nicht öffnen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Timeout:"
        }), " Ihr Service benötigt länger als fünf Sekunden, um eine Antwort zurück an einen Batch an Benachrichtigungen zu senden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fehlercodes:"
        }), " Ihr Service antwortet mit einem beliebigen HTTP-Statuscode (4xx oder 5xx)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Benachrichtigungen werden bis zu 10 Mal erneut versucht. Diese erneuten Versuche werden mit wechselnden Verzögerungen zwischen den Anfragen über die nächsten 24 Stunden verteilt. Bei einzelnen Benachrichtigungen wird etwas Randomisierung angewendet, um zu verhindern, dass eine große Anzahl erneuter Versuche zum exakt gleichen Zeitpunkt gleichzeitig fehlschlägt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beschränkungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "Anfragen, die HubSpot über Ihre Webhook-Abonnements an Ihren Dienst sendet, werden ", (0, _jsxRuntime.jsx)("u", {
        children: "nicht"
      }), " auf die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "API-Ratenbeschränkungen Ihrer App"
      }), " angerechnet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können maximal 1000 Abonnements pro Anwendung erstellen. Wenn Sie versuchen, mehr Sie zu erstellen, erhalten Sie einen Statuscode 400 Bad Request mit dem folgenden Text:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n{\n  \"status\": \"error\",\n  \"message\": \"Couldn't create another subscription. You've reached the maximum number allowed per application (1000).\",\n  \"correlationId\": \"2c9beb86-387b-4ff6-96f7-dbb486c00a95\",\n  \"requestId\": \"919c4c84f66769e53b2c5713d192fca7\"\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}