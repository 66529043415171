"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32159895393;
const slug = exports.slug = 'reference/api/analytics-and-events/tracking-code';
const title = exports.title = 'トラッキングコードAPIの概要｜HubSpot（ハブスポット）';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Page | vNext Docs DP - Tracking code | 202211';
const metaDescription = exports.metaDescription = 'ウェブ アナリティクス エンドポイントを使用することで、開発者はCRMオブジェクト（タイプにかかわらず）に関連付けられているイベントを検索し、絞り込むことができます。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%82%92%E8%AD%98%E5%88%A5%E3%81%99%E3%82%8B",
  "label": "コンタクトを識別する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A8%AA%E5%95%8F%E8%80%85%E3%82%92%E8%AD%98%E5%88%A5%E3%81%99%E3%82%8B",
  "label": "訪問者を識別する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%8D%98%E4%B8%80%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%A2%E3%83%97%E3%83%AA%E3%81%A7%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%E3%82%92%E8%A1%8C%E3%81%86",
  "label": "単一ページアプリでトラッキングを行う",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%83%91%E3%82%B9%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "ページパスを設定する",
  "parent": "%E5%8D%98%E4%B8%80%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%A2%E3%83%97%E3%83%AA%E3%81%A7%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%E3%82%92%E8%A1%8C%E3%81%86"
}, {
  "depth": 1,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%83%93%E3%83%A5%E3%83%BC%E3%82%92%E8%BF%BD%E8%B7%A1%E3%81%99%E3%82%8B",
  "label": "ページビューを追跡する",
  "parent": "%E5%8D%98%E4%B8%80%E3%83%9A%E3%83%BC%E3%82%B8%E3%82%A2%E3%83%97%E3%83%AA%E3%81%A7%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%E3%82%92%E8%A1%8C%E3%81%86"
}, {
  "depth": 0,
  "id": "%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%E3%81%AE%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC",
  "label": "トラッキングのプライバシーポリシー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AF%E3%83%AD%E3%82%B9%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E3%83%AA%E3%83%B3%E3%82%AF%E3%81%AE%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "クロスドメインリンクのパラメーターを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%8A%E3%83%AA%E3%83%86%E3%82%A3%E3%82%AF%E3%82%B9-%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88-%E3%83%8F%E3%83%B3%E3%83%89%E3%83%A9%E3%83%BC%E3%82%92%E5%86%8D%E9%81%A9%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "アナリティクス イベント ハンドラーを再適用する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E8%A1%8C%E5%8B%95%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%81%AE%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%EF%BC%88marketing-hub-enterprise%E3%81%AE%E3%81%BF%EF%BC%89",
  "label": "カスタム行動イベントのトラッキング（Marketing Hub Enterpriseのみ）",
  "parent": null
}, {
  "depth": 1,
  "id": "trackcustombehavioralevent",
  "label": "trackCustomBehavioralEvent",
  "parent": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E8%A1%8C%E5%8B%95%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%81%AE%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%EF%BC%88marketing-hub-enterprise%E3%81%AE%E3%81%BF%EF%BC%89"
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E8%A1%8C%E5%8B%95%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%87%E3%83%BC%E3%82%BF%E3%81%8C%E9%80%81%E4%BF%A1%E3%81%95%E3%82%8C%E3%82%8B%E3%82%88%E3%81%86%E3%81%AB%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%9E%E3%82%A4%E3%82%BA%E3%81%99%E3%82%8B",
  "label": "カスタム行動イベントデータが送信されるようにトラッキングコードをカスタマイズする",
  "parent": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E8%A1%8C%E5%8B%95%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%81%AE%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%EF%BC%88marketing-hub-enterprise%E3%81%AE%E3%81%BF%EF%BC%89"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      pre: "pre",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      strong: "strong",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "トラッキングコードAPIの概要｜HubSpot（ハブスポット）"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["このページは、新しいカスタム行動イベントの使い方を紹介するために更新されました。従来のカスタムイベントについては、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code/v1",
          children: "レガシードキュメント"
        }), "（英語）をご参照ください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotトラッキングコードを使用すると、ページビューのトラッキングに加えて、訪問者の特定やイベントのトラッキングが可能になります。また、ページを再読み込みすることなくページビューを手動で追跡することも可能になります。トラッキングコードAPIを使用すると、HubSpotでイベントを動的に作成したり、イベントデータを追跡したりできるようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ウェブサイトに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-enable-a-privacy-policy-alert-if-you-are-doing-business-in-europe",
        children: "プライバシーに関する同意バナー"
      }), "を設置している場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner/cookie-banner-api",
        children: "CookieバナーAPI"
      }), "によって訪問者のブラウザーに追加されるCookieを管理する方法をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["関数呼び出しは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), "配列に含めます。例として、以下のような場合が挙げられます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', { path string }])\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この記事では、トラッキングコードに関数を追加して、訪問者を識別したり、ページビューを追跡したり、イベントデータを送信したりする方法を説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コンタクトを識別する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotアナリティクスツールでは、次の2組のデータを使用してコンタクトを識別します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "usertoken（訪問者の「hubspotutk」ブラウザーCookieに格納されます）"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "コンタクトのEメールアドレス"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotトラッキングコードにより訪問者のアクション（ページビューなど）が追跡される際、そのアクションは訪問者のusertokenに自動的に関連付けられます。トラッキングコードAPIを使用してEメールアドレスで訪問者を特定する場合、アナリティクスシステムによってEメールアドレスがusertokenと結び付けられることで、既存のコンタクトレコードの更新や新規作成を行える仕組みです。usertokenに関連付けられているアナリティクスデータ（ページビューやオリジナルソースなど）が、コンタクトレコードに表示されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["訪問者のID（Eメールアドレスなど）が判明している場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#identify-a-visitor",
        children: "identify"
      }), "関数を使用してトラッカー内にIDを設定できます。次に、個別の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#events-js-api",
        children: "trackCustomBehavioralEvent"
      }), "呼び出しを行って、IDをHubSpotに送信できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この関数を使用する場合は、次の点に留意してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["不明な訪問者用のプレースホルダーEメールアドレスを使用することは避けてください。HubSpotによってプレースホルダーEメールアドレスを持つコンタクトレコードが作成され、訪問者の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "usertoken"
        }), " Cookieがそのレコードに関連付けられるからです。その結果、全ての不明な訪問者がプレースホルダーコンタクトに関連付けられます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "コンタクトレコードにIDを設定し、固有のプロパティーがある場合、一意性に違反するプロパティーは全てIDから削除されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "訪問者を識別する"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**アカウントが2021年9月8日より前に作成され、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/prevent-contact-properties-update-through-tracking-code-api",
          children: "トラッキングコードでのコンタクトプロパティーの更新が可能"
        }), "な場合は、他のコンタクトプロパティーもこの関数による更新の対象に含めることができます。2021年9月8日以降に作成されたアカウントの場合、この機能は非推奨になります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push([\"identify\", { {identity details} }]);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このエンドポイントは、ウェブサイトの訪問者やコンタクトを特定するために使用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**訪問者：**HubSpotコンタクトであるかどうかにかかわらず、全てのウェブサイト訪問者を指します。HubSpotでは、コンタクトの場合と同様に訪問者に関するレコードは作成されません。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**コンタクト：**HubSpot内にレコードが存在する訪問者またはオフラインコンタクトを指します。コンタクトは、一意のEメールアドレスで識別することができます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "訪問者またはコンタクトを手動で識別するには、Eメールアドレスと一意の外部IDのいずれかを使用することができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**Eメール：**既存のコンタクトを更新したり、新しいコンタクトを作成したりする場合に、Eメールアドレスで訪問者を識別します。そのEメールアドレスを持つコンタクトがHubSpot内に存在する場合は、そのコンタクトレコードがアナリティクスデータで更新されます。そのEメールアドレスを持つコンタクトが存在しない場合は、新しいレコードが作成されます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**ID：**訪問者を識別するためのカスタム外部ID。このIDで訪問者を識別すると、その訪問者にアナリティクスデータが関連付けられます。ただし、IDを使用するだけでは、HubSpotでコンタクトは作成されません。アナリティクスデータは、以下の場合にのみ、この方法を通して既存のコンタクトに関連付けることができます。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "訪問者がIDとEメールの両方で識別されたことがある。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "訪問者がIDで識別されたことがあり、そのレコードにフォーム送信が関連付けられている。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**この外部IDは、HubSpotトラッキングコードでのみ使用できます。他のHubSpotツールまたはAPIでのレコードの取得や更新には使用できません。訪問者のEメールアドレスが分かっている場合は、そのEメールアドレスを一意の識別子として使用することをお勧めします。同様に、IDで訪問者を識別するのは、Eメールアドレスが分かっていない場合に限られます。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "訪問者のIDのみを使用してHubSpotにアナリティクスデータを送信した場合は、後でIDとEメールアドレスの両方を含めることで、そのIDからのデータをコンタクトに関連付けることができます。そうすれば、既存のコンタクトが更新されるか、コンタクトが存在しない場合は新規に作成されます。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nThe below example gets the value of a query string parameter '?email='\nand uses that to identify the visitor\n*/\n\nfunction getParameterByName(name) {\n  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);\n  return match && decodeURIComponent(match[1].replace(/\\+/g, ' '));\n}\n\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: getParameterByName('email'),\n  },\n]);\n\n/*\nThe below example sets the email,\nas well as a custom property favorite_color\n*/\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: getParameterByName('email'),\n    favorite_color: 'orange',\n  },\n]);\n\n/*\nThe below example sets the email and a custom external ID for the visitor. \nThis assumes that your site includes a variable named \n'user' that includes that user's ID in an 'id' \nproperty, and their email in an 'email' property.\n*/\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: user.email,\n    id: user.id,\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この関数を使用する場合は、次の点に留意してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["この関数呼び出しでは、データがトラッカーに保存されますが、この呼び出しによってデータがHubSpotに渡されるわけではありません。データは、ページビューまたはイベントの追跡時にのみ渡されます（", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#track-page-view",
          children: "trackPageView"
        }), "関数または", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackEvent"
        }), "関数のいずれかを使用）。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "コンタクトには、IDやEメールアドレスを1つしか関連付けることができません。1つのEメールに2つのIDを割り当てようとすると、最初のIDだけがEメールアドレスに関連付けられます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "データをコンタクトに結びつけるには、Eメールアドレスを含める必要があります。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アカウントが2021年9月8日より前に作成され、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/prevent-contact-properties-update-through-tracking-code-api",
          children: "トラッキングコードでのコンタクトプロパティーの更新が可能"
        }), "な場合は、他のコンタクトプロパティーもこの関数による更新の対象に含めることができます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["この関数では、以前に削除されたコンタクトは復元できません。そのようなコンタクトは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/restore-deleted-contacts-companies-deals-or-tickets",
          children: "HubSpot上で復元する"
        }), "必要があります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "単一ページアプリでトラッキングを行う"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotトラッキングコードでは、コードの初回読み込み時にページビューが記録されます。しかし、トラッキングコードを再読み込みすることなく単一ページアプリのページビューを手動で追跡することもできます。現在のページを更新してトラッキングを行うには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-path",
        children: "setPath"
      }), "関数と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView"
      }), "関数を使用します。例として、以下のような場合が挙げられます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<!-- Set up the path for the initial page view -->\n<script>\n  var _hsq = window._hsq = window._hsq || [];\n  _hsq.push(['setPath', '/home']);\n</script>\n\n<!-- Load the HubSpot tracking code -->\n<!-- Start of HubSpot Embed Code -->\n  <script type=\"text/javascript\" id=\"hs-script-loader\" async defer src=\"//js.hs-scripts.com/{hubId}.js\">\n</script>\n<!-- End of HubSpot Embed Code -->\n\n<!-- Tracking subsequent page views -->\n<script>\n  var _hsq = window._hsq = window._hsq || [];\n  _hsq.push(['setPath', '/about-us']);\n  _hsq.push(['trackPageView']);\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ページパスを設定する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsq.push(['setPath', { path string }])"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["トラッカーに保存された現在のページのパスを更新します。単一ページアプリで、ページ読み込み時に現在のページを更新する場合は、この関数を使用してください。この関数を使用してパスを更新した後、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView関数"
      }), "を呼び出して現在のページの表示を追跡する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["単一ページアプリでは、トラッキングコードの読み込み前にsetPath呼び出しを_hsqに含めて、最初のページビューにおいて追跡されるURLを設定する必要があります。例として、後述する「", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "ページビューを追跡する"
      }), "」セクションを参照してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "SetPath"
      }), "を呼び出すときは、現在のページのパスを指定します。指定したパスは、表示されている現在のドメインとの相対パスとして処理されます。パスの先頭は常にスラッシュでなければなりません。URLにパラメーターが含まれている場合、それらもパスに含める必要があります。例として、上記コードをご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "この関数を使用する場合は、次の点に留意してください。"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), "関数を使用してパスを設定すると、Refererヘッダーのデータが変更されます。したがって、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), "を1度呼び出した後は、トラッキングを行うビューごとに", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), "を使用してパスを更新する必要があります。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), "を繰り返し呼び出すと、リファラーが上書きされます。これは、トラッキングリクエストがいつ送信されたかによって、コンタクトのオリジナルソースに影響することがあります。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "この関数で更新できるのは、URLのパス部分のみです。ドメインは、読み込み時のページのURLに基づいて自動的に設定されます。この関数を使用して設定するパスは常に、検出されたドメインに対する相対パスとして処理されます。"
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "Example usage:\n\n// These examples assume that the domain of the site is\n// www.mydomain.com\n\n// Set the path to '/' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/\n\n// Set the path to '/contact-us' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/contact-us']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/contact-us\n\n// Set the path to '/blog/post?utm_campaign=my-campaign' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/blog/post?utm_campaign=my-campaign']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/blog/post?utm_campaign=my-campaign\n\n// Set the path to '/#/about-us' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/#/about-us']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/#/about-us\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ページビューを追跡する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['trackPageView']);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "現在のページのページビューを追跡します。この関数はページでトラッキングコードが読み込まれたときに自動的に呼び出されます。単一ページアプリの場合、この関数を手動で呼び出して以降のビューを追跡することができます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**初回のページ読み込みの前または読み込み中にこの関数を手動で呼び出すと、トラッキング対象のビューが重複するおそれがあります。"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この関数には引数を追加できません。追跡されるページタイトルが、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "document.title"
      }), "の現在の値になります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "追跡されるURLは、次のいずれか1つに基づきます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath関数"
        }), "を使用して設定したパス。単一ページアプリとして作成されたサイトの場合、トラッキング対象パスの設定方法としてこの関数が推奨されます。この関数に関する免責事項については、前述の", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), "セクションをご覧ください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["「setPath」が一度も呼び出されていない場合、トラッキング対象URLは、訪問者のブラウザーからHubSpotのトラッキングサーバーに向けて行われたリクエスト内のReferer HTTPヘッダーになります（", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/ja/docs/Web/API/History_API#Adding_and_modifying_history_entries",
          children: "ブラウザーの履歴に変更を加える"
        }), "と、ヘッダーに使用される値が更新されます）。RefererヘッダーではURLフラグメント（URLの#より後の部分）はサポートされません。フラグメントはRefererヘッダーに含められないからです。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "Example usage:\n\n// Track a new page using setPath:\n// Update the path stored in the tracker:\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/#/new-page']);\n// Track the page view for the new page\n_hsq.push(['trackPageView']);\n\n\n// Track a new page by updating the browser state:\n// Update the browser state, showing \"updated.html\" in the browser address bar\nvar stateObj = { foo: 'updated' };\nhistory.pushState(stateObj, \"updated page\", \"updated.html\");\n//Track the page view for the new page, '/updated.html'\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['trackPageView']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この関数を使用する場合は、次の点に留意してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["トラッキングコードの読み込み時にこの関数が自動的に呼び出されることは回避できませんが、そのページについて記録されるURLを制御することは可能です。そのためには、トラッキングコードの読み込み前に、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "setPath"
        }), "の呼び出しを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "_hsq"
        }), "に含めます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["サイトが単一ページアプリの場合、ページに", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"canonical\">"
        }), "タグを含めないでください。ブラウザーの履歴状態を更新するだけの場合、検出される全てのURLはlinkタグに設定されたcanonical URLによって変更されるため、ページ内で", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"canonical\">"
        }), "タグを使用する場合、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), "関数を使用して新しいページのパスを更新する必要があります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "トラッキングのプライバシーポリシー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ウェブサイトに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/customize-your-cookie-tracking-settings-and-privacy-policy-alert",
        children: "プライバシーに関する同意バナー"
      }), "を設置している場合、関数を使用して、訪問者のブラウザーに設定されているCookieの確認および管理ができます。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner/cookie-banner-api",
        children: "プライバシーに関する同意バナーCookieを管理する"
      }), "方法をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "クロスドメインリンクのパラメーターを取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['addIdentityListener', function(hstc, hssc, hsfp) {}])"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotトラッキングコードは、ドメインの異なる複数のウェブサイトにわたって使用できます。この関数を使用することで、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/set-up-your-site-domains#track-multiple-domains-with-cross-domain-linking",
        children: "異なるドメイン全体で"
      }), "訪問者のトラッキングを可能にするリンクの作成に必要なクエリーパラメーターを取得できます。HubSpotトラッキングコードはこれらのクエリーパラメーターを使用して、追跡された1人の訪問者に異なるドメインの別個のCookieを結び付けることで、複数のドメイン間で訪問者を識別できます。クロスドメイン クエリー パラメーターは、トラッキングコードの読み込み後、ページに動的に追加されるリンク内でも使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["クロスドメインリンクが必要になるのは、単一のHubSpotアカウント上で同様にトラッキング対象となっている別のドメイン（「domain-one.com」と「domain-two.com」など）にリンクする場合に限定されます。サブドメイン間（例：「", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://www.domain-one.com%E3%80%8D%E3%81%A8%E3%80%8Cblog.domain-one.com%E3%80%8D%EF%BC%89%E3%81%AE%E8%A8%AA%E5%95%8F%E3%82%92%E8%BF%BD%E8%B7%A1%E3%81%99%E3%82%8B%E5%A0%B4%E5%90%88%E3%80%81%E3%82%AF%E3%83%AD%E3%82%B9%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3",
        children: "www.domain-one.com」と「blog.domain-one.com」）の訪問を追跡する場合、クロスドメイン"
      }), " リンク パラメーターは不要です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Get the cross-domain query parameters and store them in a string,\n//so that they can be appended to links as needed.\n_hsq.push([\n  'addIdentityListener',\n  function (hstc, hssc, hsfp) {\n    // Add these query parameters to any links that point to a separate tracked domain\n    crossDomainTrackingParams =\n      '&__hstc=' + hstc + '&__hssc=' + hssc + '&__hsfp=' + hsfp;\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アナリティクス イベント ハンドラーを再適用する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['refreshPageHandlers'])"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この関数は、HubSpotアカウントのアナリティクス設定でアナリティクス イベント ハンドラーが設定されている場合に、再適用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/events/create-clicked-element-events",
        children: "要素クリックイベント"
      }), "が設定されている場合は、その再適用も含まれます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この関数を使用すると、コンテンツのセクションの更新やページ上でのモーダルウィンドウの表示など、ページ上のコンテンツが更新された時点で、クリックハンドラーを自動的に再適用できます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**この機能は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code"
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "setPath"
        }), "関数の一部として自動的にトリガーされるため、この関数が必要なのは、トラッキング対象ページURLを更新することなく、コンテンツを更新する場合に限られます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Reapply event handlers after updating the page content\n_hsq.push(['refreshPageHandlers']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "カスタム行動イベントのトラッキング（Marketing Hub Enterpriseのみ）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタム行動イベントを使用すると、イベントの完了をコンタクトレコードに結び付けて、イベントに関するメタデータをイベントプロパティーに入力することができます。カスタム行動イベントを取得するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/event-analytics",
        children: "ウェブアナリティクスAPI"
      }), "を使用します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このAPIにより、イベントの内部名を使用してイベントをトリガーできます。内部名は、イベントを作成したときに自動的に割り当てられます。イベントの内部名は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events#define-the-api-call",
        children: "HubSpot"
      }), "上で、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/reporting#get-events",
        children: "イベントAPIを使用"
      }), "（英語）して、確認できます。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events#define-the-api-call",
        children: "イベントの内部名を調べる"
      }), "方法をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Imported%20sitepage%20images/custom-event-internal-name.png",
        alt: "カスタムイベント内部名"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot上で作成できるイベントには、次の3つのタイプがあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
          children: "要素クリックイベント"
        }), "：ウェブサイトページ上のクリック可能な要素に結び付けられたイベント。トラッキングコードを介して、既定の一連のHubSpotイベントプロパティーが自動入力されます。このタイプのイベントは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackCustomBehavioralEvent"
        }), "関数で詳細をさらにカスタマイズできます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
          children: "URL訪問イベント"
        }), "：指定されたURLのページ読み込みに結び付けられたイベント。トラッキングコードを介して、既定の一連のHubSpotイベントプロパティーが自動入力されます。このタイプのイベントは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackCustomBehavioralEvent"
        }), "関数で詳細をさらにカスタマイズできます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
          children: "手動で追跡される行動イベント"
        }), "：貴社に固有のカスタムイベントと、HubSpotまたは連携によって自動的に取り込まれないイベント。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
          children: "HTTP API"
        }), "を介して、手動でHubSpotイベントにデータを送信します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotの各イベントタイプには、UTMパラメーターや、デバイスとオペレーティングシステムのメタデータを含む特定のメタデータを完了時に取り込むことが可能な、標準プロパティーのセットが用意されています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この関数はHubSpotのアナリティクストラッキングと連動しているため、JavaScript APIによってトリガーされたイベントが訪問者の「hubspotutk」Cookieに自動的に関連付けられます。これにより、イベントを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#identities-overview",
        children: "ユーザートークンに関連付けられたコンタクト"
      }), "へと自動的に結び付けることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "trackCustomBehavioralEvent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push([\"trackCustomBehavioralEvent\", { {event details} }]);"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JavaScriptおよびHubSpotのトラッキングコードを使用して", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/events-user-guide-v2/a-quick-tour-of-events",
        children: "イベント"
      }), "を追跡するには、この関数を使用します。イベントを使用して、ウェブサイトの訪問者が完了した特定のアクティビティーを追跡することができます。トラッキング対象のイベントは、コンタクトのタイムラインに表示されることがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nExample code to fire a custom behavioral event using the name \"clicked Buy Now button\" when a visitor clicks an element with the 'buyNow' id.\n*/\n\ndocument.getElementById(\"buyNow\").onclick = function() {_hsq.push([\"trackCustomBehavioralEvent\", {\n   name: \"pe123456_course_registration\",\n   properties: {\n       course_id: \"Math101\"\n   }\n}]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "引数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "使い方"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "name:\"internal_name\""
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotで作成したイベントのevent_idまたは内部名。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "property_name: \"property_value\""
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["キーと値のペアのリスト。プロパティーごとにキーと値のペアがあります。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_name"
            }), "はイベントについて作成したイベントプロパティーの内部名で、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_value"
            }), "はプロパティーに追加する値です。 また、未定義のプロパティーを追跡し、イベントの追跡後にこうしたプロパティーの作成に戻ることもできます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カスタム行動イベントデータが送信されるようにトラッキングコードをカスタマイズする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既定では、作成するイベントごとに、HubSpotによって一連のプロパティーが作成されます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
        children: "要素クリックイベント"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
        children: "URL訪問イベント"
      }), "の場合、HubSpotによってこうしたプロパティーの一部にデータが自動入力されます。しかし、イベントのプロパティーにデータが送信されるようにトラッキングコードをカスタマイズすることもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントにて、［レポート］>［アナリティクスツール］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［カスタム行動イベント］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["追跡するイベントの", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "名前"
        }), "をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［プロパティー］のイベントの", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "内部名"
        }), "をコピーします。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-clicked-element-event-internal-name0.png",
        alt: "カスタム要素クリックイベント-内部名0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["次に、プロパティーの表で、データの送信先となるイベントプロパティーの", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "名前"
          }), "をクリックします。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "右側のパネルで**</>ソースアイコン**をクリックして、プロパティーの内部名を表示します。この名前は、トラッキングコードをカスタマイズする際に使用します。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-behavioral-event-property-internal-name0.png",
            alt: "カスタム行動イベントプロパティー-内部名0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["イベントとイベント プロパティー データが用意できたら、", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "設定"
          }), "アイコンをクリックしてアカウント設定に進みます。次に、左のサイドバーメニューで、［トラッキングとアナリティクス］>［トラッキングコード］の順に進みます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［JavaScriptのカスタマイズ］をクリックします。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/tracking-code-customize-javascript0.png",
            alt: "トラッキングコード-JavaScriptのカスタマイズ0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "右上の［カスタムJavaScriptを追加］をクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["右側のサイドバーでカスタムJavaScriptの", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "名前"
          }), "を入力し、次に", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#events-js-api",
            children: "trackCustomBehavioralEvent"
          }), "関数が含まれるJavaScriptを入力します。このJavaScriptは、ページのトラッキングコードの読み込み後に実行されます。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example usage\n_hsq.push(['trackCustomBehavioralEvent',{\n   name: '((behavioral_event_internal_name))”,\n   properties: { internal_property_name: property_value}\n}\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、HTML ID「", (0, _jsxRuntime.jsx)(_components.code, {
        children: "register_for_econ101"
      }), "」のボタンがクリックされたときに、イベントによってコースの登録を追跡する場合、次のようなJavaScriptを記述します。", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/customize-tracking-code0-1.png",
        alt: "トラッキングコードのカスタマイズ0-1"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［保存］をクリックして、JavaScriptを保存します。これで、トラッキングコードの読み込みがカスタムJavaScriptと共に行われるようになりました。"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}