"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 128892586584;
const slug = exports.slug = 'guides/cms/content/templates/default-email-modules';
const title = exports.title = 'Update email templates to use email-specific default modules';
const name = exports.name = 'Update email templates to use email-specific default modules';
const metaDescription = exports.metaDescription = 'Learn how to update your email templates to use the new email-specific default modules.';
const position = exports.position = 2;
const toc = exports.toc = [{
  "depth": 0,
  "id": "updating-to-the-new-modules",
  "label": "Updating to the new modules",
  "parent": null
}, {
  "depth": 1,
  "id": "updating-the-module-by-path",
  "label": "Updating the module by path",
  "parent": "updating-to-the-new-modules"
}, {
  "depth": 1,
  "id": "updating-the-module-by-id",
  "label": "Updating the module by ID",
  "parent": "updating-to-the-new-modules"
}, {
  "depth": 0,
  "id": "new-default-email-modules",
  "label": "New default email modules",
  "parent": null
}, {
  "depth": 1,
  "id": "email-blog-post-filter",
  "label": "Email blog post filter",
  "parent": "new-default-email-modules"
}, {
  "depth": 1,
  "id": "email-blog-post-listing",
  "label": "Email blog post listing",
  "parent": "new-default-email-modules"
}, {
  "depth": 1,
  "id": "email-call-to-action",
  "label": "Email call-to-action",
  "parent": "new-default-email-modules"
}, {
  "depth": 1,
  "id": "email-header",
  "label": "Email header",
  "parent": "new-default-email-modules"
}, {
  "depth": 1,
  "id": "email-linked-image",
  "label": "Email linked image",
  "parent": "new-default-email-modules"
}, {
  "depth": 1,
  "id": "email-logo",
  "label": "Email logo",
  "parent": "new-default-email-modules"
}, {
  "depth": 1,
  "id": "email-one-line-of-text",
  "label": "Email one line of text",
  "parent": "new-default-email-modules"
}, {
  "depth": 1,
  "id": "email-section-header",
  "label": "Email section header",
  "parent": "new-default-email-modules"
}, {
  "depth": 1,
  "id": "email-social-sharing",
  "label": "Email social sharing",
  "parent": "new-default-email-modules"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      h2: "h2",
      h3: "h3",
      strong: "strong",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Update email templates to use default email modules"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When building email templates, HubSpot provides a set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-email-modules",
        children: "default email modules"
      }), " to get you started. These modules are similar to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "default web modules"
      }), ", but are split up to enable HubSpot to release updates to these modules for better email client support, while also releasing updates to the web versions of the modules separately. Existing email templates using the web modules will still function, but you'll need to update your email templates to use these new modules for forwards compatibility and to avoid errors in the design manager and CLI."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following are the new email-specific modules that should replace existing usage of the web default modules:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules#email-call-to-action",
          children: "email_cta"
        }), " (replaces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cta"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules#email-header",
          children: "email_header"
        }), " (replaces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "header"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules#email-linked-image",
          children: "email_linked_image"
        }), " (replaces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "linked_image"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules#email-logo",
          children: "email_logo"
        }), " (replaces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "logo"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules#email-blog-post-filter",
          children: "email_post_filter"
        }), " (replaces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "post_filter"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules#email-blog-post-listing",
          children: "email_post_listing"
        }), " (replaces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "post_listing"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules#email-section-header",
          children: "email_section_header"
        }), " (replaces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "section_header"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules#email-social-sharing",
          children: "email_social_sharing"
        }), " (replaces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "social_sharing"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules#email-one-line-of-text",
          children: "email_text"
        }), " (replaces ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "text"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, read more about the new email modules and how to update your email templates to use them."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Updating to the new modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update an email template to use the new default email modules, you'll need to update either the module path or ID. All email module paths are prepended by ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email_"
      }), ", and all email modules have been assigned a new ID. You can r", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#new-default-email-modules",
        children: "eview the section below"
      }), " for a full list of new module paths and IDs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is an example of updating an email template to use the new email logo module, either by referencing its new path or ID."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Updating the module by path"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Original"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo_with_path\" path=\"@hubspot/logo\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Updated"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo_with_path\" path=\"@hubspot/email_logo\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Updating the module by ID"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Original"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo_with_id\" module_id=\"1155232\" label=\"Logo\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Updated"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo_with_id\" module_id=\"122980089981\" label=\"Logo\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "New default email modules"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following modules have been added for email templates. When reference these modules in your email templates, you can use either the module path or ID."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#email-blog-post-filter",
          children: "Email blog post filter"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#email-blog-post-listing",
          children: "Email blog post listing"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#email-call-to-action",
          children: "Email call-to-action"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#email-header",
          children: "Email header"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#email-linked-image",
          children: "Email linked image"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#email-logo",
          children: "Email logo"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#email-one-line-of-text",
          children: "Email one line of text"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#email-section-header",
          children: "Email section header"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#email-social-sharing",
          children: "Email social sharing"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Email blog post filter"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"email_post_filter\" path=\"@hubspot/email_post_filter\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Path"
          })
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ID"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/post_filter"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/email_post_filter"
            })]
          }), "\n"]
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1366743"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "122980089983"
            })]
          }), "\n"]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Email blog post listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"email_post_listing\" path=\"@hubspot/email_post_listing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Path"
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "ID"
            })
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/post_listing"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/email_post_listing"
            })]
          }), "\n"]
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1367088"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "122980089986"
            })]
          }), "\n"]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Email call-to-action"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"email_cta\" path=\"@hubspot/email_cta\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Path"
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "ID"
            })
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/cta"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/email_cta"
            })]
          }), "\n"]
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1790629"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "122980089970"
            })]
          }), "\n"]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Email header"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"email_header\" path=\"@hubspot/email_header\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Path"
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "ID"
            })
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/header"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/email_header"
            })]
          }), "\n"]
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1155826"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "122980089978"
            })]
          }), "\n"]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Email linked image"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"email_linked_image\" path=\"@hubspot/email_linked_image\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Path"
          })
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ID"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/linked_image"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/email_linked_image"
            })]
          }), "\n"]
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1155231"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "122960526478"
            })]
          }), "\n"]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Email logo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"email_logo\" path=\"@hubspot/email_logo\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Path"
          })
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ID"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/logo"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/email_logo"
            })]
          }), "\n"]
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1155232"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "122980089981"
            })]
          }), "\n"]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Email one line of text"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"email_text\" path=\"@hubspot/email_text\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Path"
          })
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ID"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/text"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/email_text"
            })]
          }), "\n"]
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1843376"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "122980089988"
            })]
          }), "\n"]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Email section header"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"email_section_header\" path=\"@hubspot/email_section_header\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Path"
          })
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ID"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/section_header"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/email_section_header"
            })]
          }), "\n"]
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1155240"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "122980089987"
            })]
          }), "\n"]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Email social sharing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"email_social_sharing\" path=\"@hubspot/email_social_sharing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Path"
          })
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ID"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/social_sharing"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "@hubspot/email_social_sharing"
            })]
          }), "\n"]
        })
      }), (0, _jsxRuntime.jsx)("div", {
        children: (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Old:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1155241"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "New:"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "122980537516"
            })]
          }), "\n"]
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}