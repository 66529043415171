"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45280489381;
const slug = exports.slug = 'guides/api/crm/pipelines';
const title = exports.title = 'API do CRM | Pipelines';
const name = exports.name = 'EMEA PT (pt-BR) vNext Docs DP - Pipelines';
const metaDescription = exports.metaDescription = 'Os endpoints de pipelines são usados para visualizar, criar e gerenciar pipelines no HubSpot CRM e em ferramentas de serviço.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h3: "h3",
      code: "code",
      a: "a",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Pipelines"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No HubSpot, um pipeline é o local em que as fases do negócio ou os status de tíquetes são definidos. As equipes de vendas podem usar os pipelines para prever receitas e identificar obstáculos. Já as equipes de serviço podem usar os pipelines para gerenciar status dos tíquetes e analisar os bloqueadores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cada fase em um pipeline é identificada por um ID interno exclusivo, o que significa que só pode ser membro de um pipeline. Cada pipeline sempre tem pelo menos uma fase; cada conta tem pelo menos um pipeline."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pipelines padrão"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Inicialmente, cada conta contém um pipeline padrão com o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pipelineId"
      }), " “default”. Nas contas com um único pipeline, a propriedade do pipeline de qualquer objeto também será automaticamente definida como “padrão”. Em contas com vários pipelines, se estiver configurando uma fase que não está no pipeline padrão, você também precisará definir a propriedade do pipeline correspondente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vários pipelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Apenas contas do Sales Hub Professional ou do Enterprise podem criar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "vários pipelines de negócios"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da mesma forma, você precisa ter uma assinatura do Service Hub Professional ou do Enterprise para criar vários ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/tickets/customize-ticket-pipelines-and-statuses",
        children: "pipelines de tíquete"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre os níveis de assinatura da HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de casos de uso:"
      }), " Nos negócios, uma conta pode ter um pipeline para “Novas vendas” e outro para “Renovações de contrato”. Para tíquetes, você pode ter uma fila de suporte principal e uma fila separada para escalações. Cada uma dessas filas seria um pipeline de tíquete separado. Os endpoints de pipelines podem ser usados para sincronizar pipelines a um CRM externo."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}