"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160720238423;
const slug = exports.slug = 'guides/api/crm/commerce/payments';
const title = exports.title = 'Payments API';
const name = exports.name = 'Payments API';
const metaDescription = exports.metaDescription = "Learn about using HubSpot's APIs to retrieve information about payments.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "requirements",
  "label": "Requirements",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-payments",
  "label": "Retrieve payments",
  "parent": null
}, {
  "depth": 1,
  "id": "properties",
  "label": "Properties",
  "parent": "retrieve-payments"
}, {
  "depth": 1,
  "id": "search-for-payments-by-properties",
  "label": "Search for payments by properties",
  "parent": "retrieve-payments"
}, {
  "depth": 0,
  "id": "associations",
  "label": "Associations",
  "parent": null
}, {
  "depth": 1,
  "id": "retrieving-a-payment-with-associated-contact",
  "label": "Retrieving a payment with associated contact",
  "parent": "associations"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Payments"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the payments API to fetch information about an account's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/manage-payments",
        children: "payments"
      }), ". This is a read-only API, so it cannot be used for creating new or managing existing payments."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, use this API to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#search-for-payments-by-properties",
        children: "fetch all refunded payments"
      }), " in an account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Requirements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To use this API, the account must be set up to collect payments through either ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/set-up-payments",
        children: "HubSpot payments"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
        children: "Stripe payment processing"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve payments"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depending on the information you need, there are a few ways to retrieve payments:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve all payments, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/commerce_payments"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a payment, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to the above URL and specify an payment ID. For example: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/commerce_payments/44446244097"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve payments that meet a specific set of criteria, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to the search endpoint and include filters in the request body. See an example of using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#search-for-invoices-by-filter-criteria",
          children: "search endpoint below"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include a few default properties, including the create date, last modified date."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"44446244097\",\n  \"properties\": {\n    \"hs_createdate\": \"2023-03-08T14:54:17.333Z\",\n    \"hs_lastmodifieddate\": \"2024-03-01T22:33:09.011Z\",\n    \"hs_object_id\": \"44446244097\"\n  },\n  \"createdAt\": \"2023-03-08T14:54:17.333Z\",\n  \"updatedAt\": \"2024-03-01T22:33:09.011Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To return specific properties, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " query parameter in the request URL along with comma-separated property names. For example, making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the following URL would result in the response below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/commerce_payments?properties=hs_customer_email,hs_latest_status"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"40744976671\",\n  \"properties\": {\n    \"hs_createdate\": \"2022-09-02T15:03:40.828Z\",\n    \"hs_customer_email\": \"name@emailaddress.com\",\n    \"hs_lastmodifieddate\": \"2024-02-27T15:03:53.620Z\",\n    \"hs_object_id\": \"40744976671\",\n    \"hs_latest_status\": \"succeeded\"\n  },\n  \"createdAt\": \"2022-09-02T15:03:40.828Z\",\n  \"updatedAt\": \"2024-02-27T15:03:53.620Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all available payment properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/properties/commerce_payments"
      }), ". Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are some common payment properties that you may want to query."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Label in UI"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_latest_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties#payment-properties:~:text=created%20the%20payment.-,Status,-%3A%C2%A0the%20current",
              children: "Status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The current status of the payment. Values include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "succeeded"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "refunded"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "processing"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "failed"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_initial_amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties#payment-properties:~:text=made%20the%20payment.-,Gross%20amount,-%3A%C2%A0the%20total",
              children: "Gross amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The total amount that the buyer was charged."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_customer_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties#payment-properties:~:text=be%20in%20USD.-,Customer,-%3A%C2%A0the%20email",
              children: "Customer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The buyer's email address."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_initiated_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties#payment-properties:~:text=can%27t%20be%20changed.-,Payment%20date,-%3A%C2%A0the%20date",
              children: "Payment date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The date that the payment was created."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_payment_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties#payment-properties:~:text=is%20updated%20automatically.-,Payment%20ID,-%3A%C2%A0the%20unique",
              children: "Payment ID"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The payment's unique ID."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Search for payments by properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the search endpoint to retrieve payments that meet a specific set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search#filter-search-results",
        children: "filter criteria"
      }), ". This will be a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request that includes your filter criteria in the request body."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to search for all refunded payments, you would make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/commerce_payments/search"
      }), " with the following request body:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example search request body\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"hs_latest_status\",\n          \"value\": \"refunded\",\n          \"operator\": \"EQ\"\n        }\n      ]\n    }\n  ],\n  \"properties\": [\"hs_latest_status\", \"hs_customer_email\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note that the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filters"
      }), " array specifies the search criteria, while the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " array specifies which properties to return."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While you cannot set associations using this API, you can retrieve association information by making a GET request to the following URL:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/commerce_payments/{hs_object_id}/associations/{associatedObjectName}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Associated objects can include ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts",
        children: "contacts"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies",
        children: "companies"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals",
        children: "deals"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/invoices",
        children: "invoices"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes",
        children: "quotes"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/line-items",
        children: "line items"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/subscriptions",
        children: "subscriptions"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/discounts",
        children: "discounts"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/fees",
          children: "fees"
        })
      }), ", and ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: "taxes"
        })
      }), ". These associates are based on the associations set on the invoice, payment link, or quote used for transaction. To manage these associations, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/manage-payments#view-payment-records",
        children: "update the payment in HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is an example of how you might use this API combined with another API to get a specific set of association information."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " when retrieving line items from different objects created in HubSpot, you should expect to receive different IDs. This is because line items should only be associated with one object, which HubSpot handles automatically by creating copies of line items rather than using the same line item across multiple objects."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieving a payment with associated contact"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve a payment and contact associated with it, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/commerce_payments/{hs_object_id}/associations/contact"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This will return the IDs of the currently associated contact, along with meta information about the association type."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"results\": [\n    {\n      \"id\": \"301\",\n      \"type\": \"commerce_payment_to_contact\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can then use the returned IDs to request more information about the line items through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts",
        children: "contacts API"
      }), ". For example, you could retrieve the contact using its ID by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/contacts/{contactId}"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"301\",\n  \"properties\": {\n    \"createdate\": \"2022-09-27T13:13:31.004Z\",\n    \"email\": \"tom.bombadil@oldforest.com\",\n    \"firstname\": \"Tom\",\n    \"hs_object_id\": \"301\",\n    \"lastmodifieddate\": \"2023-11- 07T17:14:00.841Z\",\n    \"lastname\": \"Bombadil\"\n  },\n  \"createdAt\": \"2022-09-27T13:13:31.004Z\",\n  \"updatedAt\": \"2023-11-07T17:14:00.841Z\",\n  \"archived\": false\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}