"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816705715;
const slug = exports.slug = 'guides/apps/authentication/oauth-quickstart-guide';
const title = exports.title = 'Trabajando con OAuth | Guía de inicio rápido de OAuth';
const name = exports.name = 'LATAM (ES) vNext Docs DP - OAuth Quickstart';
const metaDescription = exports.metaDescription = 'Conéctate a HubSpot con OAuth usando nuestra Guía de inicio rápido y la aplicación de muestra node.js.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "antes-de-comenzar",
  "label": "Antes de comenzar",
  "parent": null
}, {
  "depth": 1,
  "id": "c%C3%B3mo-funciona",
  "label": "Cómo funciona",
  "parent": "antes-de-comenzar"
}, {
  "depth": 1,
  "id": "en-esta-gu%C3%ADa",
  "label": "En esta guía",
  "parent": "antes-de-comenzar"
}, {
  "depth": 0,
  "id": "aplicaci%C3%B3n-de-inicio-r%C3%A1pido",
  "label": "Aplicación de inicio rápido",
  "parent": null
}, {
  "depth": 0,
  "id": "obtener-tokens-de-oauth-2.0",
  "label": "Obtener tokens de OAuth 2.0",
  "parent": null
}, {
  "depth": 1,
  "id": "paso-1-crea-la-url-de-autorizaci%C3%B3n-y-dirige-al-usuario-al-servidor-oauth-2.0-de-hubspot",
  "label": "Paso 1: crea la URL de autorización y dirige al usuario al servidor OAuth 2.0 de HubSpot",
  "parent": "obtener-tokens-de-oauth-2.0"
}, {
  "depth": 3,
  "id": "ejemplos",
  "label": "Ejemplos",
  "parent": "obtener-tokens-de-oauth-2.0"
}, {
  "depth": 3,
  "id": "codificaci%C3%B3n-de-un-estado-de-usuario-de-redireccionamiento-adicional",
  "label": "Codificación de un estado de usuario de redireccionamiento adicional",
  "parent": "obtener-tokens-de-oauth-2.0"
}, {
  "depth": 1,
  "id": "paso-2-hubspot-solicita-al-usuario-su-consentimiento",
  "label": "Paso 2: HubSpot solicita al usuario su consentimiento",
  "parent": "obtener-tokens-de-oauth-2.0"
}, {
  "depth": 1,
  "id": "paso-3-maneja-la-respuesta-del-servidor-oauth-2.0",
  "label": "Paso 3: maneja la respuesta del servidor OAuth 2.0",
  "parent": "obtener-tokens-de-oauth-2.0"
}, {
  "depth": 3,
  "id": "ejemplo",
  "label": "Ejemplo:",
  "parent": "obtener-tokens-de-oauth-2.0"
}, {
  "depth": 1,
  "id": "paso-4-intercambia-el-c%C3%B3digo-de-autorizaci%C3%B3n-de-intercambio-para-tokens",
  "label": "Paso 4: intercambia el código de autorización de intercambio para tokens",
  "parent": "obtener-tokens-de-oauth-2.0"
}, {
  "depth": 3,
  "id": "ejemplo",
  "label": "Ejemplo:",
  "parent": "obtener-tokens-de-oauth-2.0"
}, {
  "depth": 0,
  "id": "uso-de-tokens-de-oauth-2.0",
  "label": "Uso de tokens de OAuth 2.0",
  "parent": null
}, {
  "depth": 3,
  "id": "ejemplo",
  "label": "Ejemplo:",
  "parent": "uso-de-tokens-de-oauth-2.0"
}, {
  "depth": 0,
  "id": "actualizaci%C3%B3n-de-tokens-de-oauth-2.0",
  "label": "Actualización de tokens de OAuth 2.0",
  "parent": null
}, {
  "depth": 3,
  "id": "ejemplo",
  "label": "Ejemplo:",
  "parent": "actualizaci%C3%B3n-de-tokens-de-oauth-2.0"
}, {
  "depth": 2,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": "actualizaci%C3%B3n-de-tokens-de-oauth-2.0"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      hr: "hr",
      h3: "h3",
      ol: "ol",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      h5: "h5",
      pre: "pre",
      img: "img",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Guía de inicio rápido de OAuth"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Antes de comenzar"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de comenzar a usar OAuth con HubSpot, necesitarás:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/developers",
          children: "Una cuenta de desarrollador"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "Una aplicación"
        }), " asociada con tu cuenta de desarrollador"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Una cuenta de HubSpot* para instalar tu aplicación (puedes usar una cuenta existente o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "crear una cuenta de prueba"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "*Debes ser un Súper administrador para instalar una aplicación en una cuenta de HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Cómo funciona"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot soporta el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.okta.com/blog/2018/04/10/oauth-authorization-code-grant-type",
        children: "tipo de otorgamiento del Código de autorización de OAuth 2.0"
      }), ", que puede dividirse en cuatro pasos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tu aplicación abre una ventana del navegador para enviar al usuario al servidor HubSpot OAuth 2.0"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "El usuario revisa los permisos solicitados y otorga acceso a la aplicación"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "El usuario es redirigido a la aplicación con un código de autorización en la cadena de consulta"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La aplicación envía una solicitud al servidor de OAuth 2.0 para intercambiar el código de autorización por un token de acceso"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "En esta guía"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#quick-start-app",
          children: "Aplicación de inicio rápido"
        }), ": una aplicación de demostración en Node.js que se autentica con el servidor OAuth 2.0 de HubSpot"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#getting_tokens",
          children: "Obtener tokens de OAuth 2.0"
        }), ": cómo autorizar tu aplicación con los usuarios"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#using_tokens",
          children: "Uso de tokens de OAuth 2.0"
        }), ": cómo realizar consultas con un token"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#refreshing_tokens",
          children: "Actualización de tokens de OAuth 2.0"
        }), ": cómo usar el token de actualización proporcionado por HubSpot"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Nota:"
      }), " todos los ejemplos de código de esta guía están escritos en JavaScript (Node.js)"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Aplicación de inicio rápido"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si es la primera vez que usas la autenticación OAuth con las API de HubSpot, te recomendamos que consultes la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
        children: "Aplicación de inicio rápido de OAuth 2.0"
      }), ", escrita en Node.js. Esta aplicación de muestra está diseñada para que puedas comenzar a usar OAuth 2.0 lo más rápido posible mediante la demostración de todos los pasos que se detallan a continuación en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "Obtener tokens de OAuth 2.0"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
          children: "Obtén la aplicación en Github"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtener tokens de OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Paso 1: crea la URL de autorización y dirige al usuario al servidor OAuth 2.0 de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al enviar un usuario al servidor OAuth 2.0 de HubSpot, el primer paso es crear la URL de autorización. Esto identificará tu aplicación y definirá los recursos (los alcances) a los que solicita acceso en nombre del usuario. Los parámetros de consulta que puedes pasar como parte de una URL de autorización se muestran a continuación. Para obtener información más detallada sobre este paso, lee el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "documento de referencia"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "¿Requerido?"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ejemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sí"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID del cliente identifica tu aplicación. Encuéntralo en la página de configuración de tu aplicación."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sí"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/es/docs/api/working-with-oauth#scopes",
              children: "alcances"
            }), " que tu aplicación solicita, separados por espacios codificados de URL."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contacts%20social"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sí"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La URL a la que el usuario será redirigido después de autorizar a tu aplicación para los alcances solicitados. ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Para las aplicaciones de producción, se requiere https."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "optional_scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los alcances que son opcionales para tu aplicación y se eliminarán si el portal de HubSpot seleccionado no tiene acceso a esos productos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un valor de cadena que se puede usar para mantener el estado del usuario cuando se redirecciona de regreso a tu aplicación."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WeHH_yy2irpl8UYAvv-my"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que hayas creado tu URL, envía al usuario a OAuth 2.0 para iniciar el proceso."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Ejemplos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando una redirección del lado del servidor:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Build the auth URL\nconst authUrl =\n  'https://app.hubspot.com/oauth/authorize' +\n  `?client_id=${encodeURIComponent(CLIENT_ID)}` +\n  `&scope=${encodeURIComponent(SCOPES)}` +\n  `&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;\n+`&state=${encodeURIComponent(STATE)}`;\n\n// Redirect the user\nreturn res.redirect(authUrl);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando un enlace HTML:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a\n  href=\"https://app.hubspot.com/oauth/authorize?scope=contacts%20social&redirect_uri=https://www.example.com/auth-callback&client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&state=xxxxxxxx\"\n  >Install</a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Codificación de un estado de usuario de redireccionamiento adicional"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Algunas aplicaciones pueden necesitar redirigir al usuario a diferentes ubicaciones. Por ejemplo, es posible que una aplicación desee redirigir a los usuarios a diferentes subdominios de su integración (por ejemplo, userA.integration.com y userB.integration.com). Para ello, utiliza el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "state"
      }), " para codificar más información sobre el estado del usuario:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "1 1. Genera y almacena un valor nonce para el parámetro de estado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "2. Almacena el estado del usuario en un almacén de datos local utilizando el nonce como su clave."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "3. Incluye el valor nonce como el parámetro de estado en la URL de autorización."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "4. Cuando el usuario se autentica y es redirigido a tu URL de redirección, valida el parámetro de estado y úsalo como la clave para recuperar el estado de usuario que estaba almacenado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "5. Desde allí, redirige al usuario según sea necesario (por ejemplo, redirige de nuevo a una URL específica del usuario)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Paso 2: HubSpot solicita al usuario su consentimiento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot muestra una ventana de consentimiento al usuario que muestra el nombre de tu aplicación y una breve descripción de los servicios de la API de HubSpot a los que solicita permiso para acceder. El usuario puede otorgar acceso a tu aplicación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/%5BExample%5D%20Install%20Screen.png",
        alt: "[Ejemplo] Pantalla de instalación"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Nota:"
      }), " el usuario que instala la aplicación debe tener acceso a todos los alcances solicitados. Si no tienen el acceso requerido, la instalación fallará y serán dirigidos a una página de error. Si un usuario ve esta página de error de permisos, necesitará que un Súper administrador instale la aplicación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tu aplicación no hace nada en esta etapa. Una vez que se otorga el acceso, el servidor de HubSpot OAuth 2.0 enviará una solicitud a la URI de devolución de llamada definida en la URL de autorización."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Paso 3: maneja la respuesta del servidor OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando el usuario haya completado la solicitud de consentimiento del paso 2, el servidor de OAuth 2.0 envía una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " al URI de redireccionamiento especificado en tu URL de autentificación. Si no hay problemas y el usuario aprueba la solicitud de acceso, la solicitud al URI de redireccionamiento se devolverá con un parámetro de consulta de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "code"
      }), " adjunto. Si el usuario no otorga acceso, no se enviará ninguna solicitud."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Ejemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "app.get('/oauth-callback', async (req, res) => {\n  if (req.query.code) {\n    // Handle the received code\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Paso 4: intercambia el código de autorización de intercambio para tokens"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de que tu aplicación reciba un código de autorización del servidor Oauth 2.0, puede intercambiar ese código por un token de acceso y actualización al enviar una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " codificada URL-form a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/oauth/v1/token"
      }), " con los valores que se muestran a continuación. Para obtener información más detallada sobre este paso, dedica un minuto a leer este ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "documento de referencia"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ejemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Debe ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID de cliente de tu aplicación"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La clave del cliente de tu aplicación"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7c3ce02c-0f0c-4c9f-9700-92440c9bdf2d"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El URI de redireccionamiento desde el momento en que el usuario autorizó tu aplicación"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El código de autorización recibido del servidor OAuth 2.0"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5771f587-2fe7-40e8-8784-042fb4bc2c31"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Ejemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const formData = {\n  grant_type: 'authorization_code',\n  client_id: CLIENT_ID,\n  client_secret: CLIENT_SECRET,\n  redirect_uri: REDIRECT_URI,\n  code: req.query.code\n};\n\nrequest.post('https://api.hubapi.com/oauth/v1/token', { form: formData }, (err, data) => {\n  // Handle the returned tokens\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El cuerpo de la respuesta del token serán datos JSON con la forma:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"refresh_token\": \"6f18f21e-a743-4509-b7fd-1a5e632fffa1\",\n  \"access_token\": \"CN2zlYnmLBICAQIYgZXFLyCWp1Yoy_9GMhkAgddk-zDc-H_rOad1X2s6Qv3fmG1spSY0Og0ACgJBAAADAIADAAABQhkAgddk-03q2qdkwdXbYWCoB9g3LA97OJ9I\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Nota:"
      }), " el token de acceso vencerá después de la cantidad de segundos indicada en el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " de la respuesta, actualmente, 30 minutos. Para obtener información sobre cómo obtener un nuevo token de acceso, consulta Tokens de actualización de OAuth 2.0."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uso de tokens de OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que se complete el flujo de código de autorización, tu aplicación está autorizada para realizar solicitudes en nombre del usuario. Para ello, proporciona el token como token de portador en el encabezado HTTP ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), ". Los detalles específicos se pueden encontrar en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "documento de referencia"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Ejemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "request.get(\n  'https://api.hubapi.com/contacts/v1/lists/all/contacts/all?count=1',\n  {\n    headers: {\n      Authorization: `Bearer ${ACCESS_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " los tokens de acceso reflejan los alcances solicitados desde la aplicación y no reflejan los permisos o limitaciones de lo que un usuario puede hacer en su cuenta de HubSpot. Por ejemplo, si un usuario tiene permisos para ver solo los contactos de su propiedad, pero autoriza una solicitud para el alcance ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.read"
        }), ", el token de acceso resultante puede ver todos los contactos de la cuenta y no solo los que son propiedad del usuario autorizante."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualización de tokens de OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los tokens de acceso de OAuth vencen periódicamente. Esto es para asegurarse de que, si se ven comprometidos, los atacantes solo tendrán acceso por un corto tiempo. La vida útil del token se especifica en el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " cuando se intercambia un código de autorización para un token de acceso."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tu aplicación puede intercambiar el token de actualización recibido por un nuevo token de acceso enviando una solicitud codificada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " de formulario de URL a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/oauth/v1/token"
      }), " con los siguientes valores. Para obtener información más detallada sobre este paso, consulta el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "documento de referencia"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ejemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Debe ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID de cliente de tu aplicación"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La clave del cliente de tu aplicación"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7c3ce02c-0f0c-4c9f-9700-92440c9bdf2d"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El URI de redireccionamiento desde el momento en que el usuario autorizó tu aplicación"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El token de actualización recibido cuando el usuario autorizó a tu aplicación"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "b9443019-30fe-4df1-a67e-3d75cbd0f726"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Ejemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const formData = {\n  grant_type: 'refresh_token',\n  client_id: CLIENT_ID,\n  client_secret: CLIENT_SECRET,\n  redirect_uri: REDIRECT_URI,\n  refresh_token: REFRESH_TOKEN\n};\n\nrequest.post('https://api.hubapi.com/oauth/v1/token', { form: formData }, (err, data) => {\n  // Handle the returned tokens\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El cuerpo de la respuesta del token serán datos JSON con la forma:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Sample response\n{\n  \"refresh_token\": \"6f18f21e-a743-4509-b7fd-1a5e632fffa1\",\n  \"access_token\": \"CN2zlYnmLBICAQIYgZXFLyCWp1Yoy_9GMhkAgddk-zDc-H_rOad1X2s6Qv3fmG1spSY0Og0ACgJBAAADAIADAAABQhkAgddk-03q2qdkwdXbYWCoB9g3LA97OJ9I\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El nuevo token de acceso puede usarse para hacer llamadas en nombre del usuario. Cuando el nuevo token venza, puedes seguir los mismos pasos nuevamente para recuperar uno nuevo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "Métodos de autentificación en HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "Trabajando con OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "Administrar tokens"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}