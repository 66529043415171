"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 102629641400;
const slug = exports.slug = 'guides/crm/overview';
const title = exports.title = 'Présentation des outils de développement CRM (BÊTA)';
const name = exports.name = 'Présentation des outils de développement CRM (BÊTA)';
const metaDescription = exports.metaDescription = "L'API de HubSpot permet aux développeurs de créer des applications et des intégrations personnalisées. Commencez par lire la présentation des API et par créer un compte de développeur.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "outils-de-d%C3%A9veloppement-crm",
  "label": "Outils de développement CRM",
  "parent": null
}, {
  "depth": 1,
  "id": "projets",
  "label": "Projets",
  "parent": "outils-de-d%C3%A9veloppement-crm"
}, {
  "depth": 1,
  "id": "applications-priv%C3%A9es",
  "label": "Applications privées",
  "parent": "outils-de-d%C3%A9veloppement-crm"
}, {
  "depth": 1,
  "id": "extensions-d-iu",
  "label": "Extensions d'IU",
  "parent": "outils-de-d%C3%A9veloppement-crm"
}, {
  "depth": 1,
  "id": "fonctions-sans-serveur",
  "label": "Fonctions sans serveur",
  "parent": "outils-de-d%C3%A9veloppement-crm"
}, {
  "depth": 1,
  "id": "sandbox-de-d%C3%A9veloppement",
  "label": "Sandbox de développement",
  "parent": "outils-de-d%C3%A9veloppement-crm"
}, {
  "depth": 1,
  "id": "int%C3%A9gration-github",
  "label": "Intégration GitHub",
  "parent": "outils-de-d%C3%A9veloppement-crm"
}, {
  "depth": 0,
  "id": "premiers-pas",
  "label": "Premiers pas",
  "parent": null
}, {
  "depth": 0,
  "id": "plus-de-ressources",
  "label": "Plus de ressources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      img: "img",
      h2: "h2",
      h3: "h3",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      code: "code",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Présentation des outils de développement CRM (BÊTA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-enterprise', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les outils de développement CRM de HubSpot vous permettent de personnaliser votre CRM avec des extensions d'interface utilisateur, des fonctionnalités telles que les sandbox de développement, le contrôle des versions et l'intégration GitHub pour simplifier votre processus de développement. À partir de votre environnement local, vous pouvez utiliser ces outils pour créer et déployer des extensions d'interface utilisateur dans les fiches d'informations CRM afin de personnaliser l'expérience utilisateur, tout en envoyant et en récupérant des données si nécessaire."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, vous pouvez créer une carte d’application qui récupère les données d’une source externe."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/custom-extension-example.png",
        alt: "custom-extension-example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous des informations sur les outils de développement CRM et sur leur utilisation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Outils de développement CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Projets"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les projets sont les conteneurs de plus haut niveau que vous créerez à l'aide des outils de développement CRM. Les projets vous permettent de concevoir et de déployer localement des applications privées, des extensions d'interface utilisateur et des fonctions sans serveur à l'aide de l'ILC. Après le déploiement sur un compte, vous pouvez voir et gérer le projet ainsi que ses applications et extensions d'interface utilisateur dans HubSpot. Il s'agit notamment de l'historique des versions et du suivi des appels d'API."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-a-project",
        children: "la création de projets"
      }), ", et consultez la liste croissante des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "exemples de projets"
      }), " de HubSpot pour découvrir toutes les possibilités."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Applications privées"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les applications privées vous permettent d'envoyer et de récupérer des données en utilisant le jeton d'accès de l'application privée pour l'authentification. Les applications privées exécutent également des fonctions sans serveur qui servent de back-end pour les extensions d'interface utilisateur. Un projet peut contenir une application privée, et chaque application privée peut contenir plusieurs extensions d'interface utilisateur et fonctions sans serveur."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-private-apps-with-projects",
        children: "la création d'applications privées"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Les applications privées créées avec des projets ne permettent actuellement que de créer des extensions d'interface utilisateur. Si vous souhaitez créer une application privée pour travailler avec les autres extensions de HubSpot, découvrez les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "différents types d'applications et ce qu'elles prennent en charge"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Extensions d'IU"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les extensions d'interface utilisateur sont des éléments d'interface utilisateur personnalisés que les utilisateurs du compte HubSpot peuvent visualiser et avec lesquels ils peuvent interagir. Pour l’instant, les extensions d’interface utilisateur possibles incluent des cartes d’application pour les fiches d’informations du CRM et le centre de support. Par exemple, vous pouvez créer une carte qui permet aux utilisateurs d’envoyer des données de formulaire à une base de données externe à partir de n’importe quelle fiche d’informations de contact, ou une carte que les agents du support client peuvent utiliser pour prendre des mesures sur les tickets ouverts dans l’interface utilisateur du centre de support."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les extensions d'interface utilisateur sont créées à l'aide de projets et se composent d'une partie front-end et d'une partie back-end :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Avant plan de l’extension d’interface utilisateur :"
        }), " la partie de l’extension orientée utilisateur sous la forme d’une carte d’application composée de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/overview",
          children: "composants fournis par HubSpot"
        }), ". Outre l'affichage d'informations, les utilisateurs peuvent interagir avec les composants pour effectuer diverses actions. Vous créerez le front-end avec React ou TypeScript."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Back-end de l'extension d'IU :"
        }), " la fonction sans serveur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/serverless-functions"
        }), " qui permet à une extension de l'interface utilisateur d'envoyer et de récupérer des données à afficher dans les composants. Alimentée par l'application privée du projet, une fonction sans serveur peut être réutilisée par plusieurs composants selon les besoins."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les cartes d'application que vous pouvez créer avec les projets sont distinctes de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/extensions/overview",
        children: "l'autre extension d'API de cartes CRM de HubSpot"
      }), " et ne peuvent pas être créées de manière interchangeable."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/overview",
        children: "le fonctionnement des extensions d'interface utilisateur"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fonctions sans serveur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fonction sans serveur exécute un code JavaScript côté serveur pour fournir une fonctionnalité de back-end à une extension d'interface utilisateur. Les fonctions sans serveur sont contenues dans des applications privées. Les fonctions sans serveur se composent d'un dossier qui contient un ou plusieurs fichiers JavaScript qui exportent une fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "main"
      }), " et d'un fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " qui enregistre et configure vos fonctions."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/serverless-functions",
        children: "la création de fonctions sans serveur avec des projets"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Bien que similaires dans leur concept, les fonctions sans serveur utilisées dans les projets sont différentes des fonctions sans serveur utilisées par le CMS HubSpot. Voici quelques-unes de ces différences :"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Vous ne pouvez pas créer une fonction sans serveur dans un projet avec la commande ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs create function"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Les fonctions sans serveur créées dans les projets ne peuvent pas être utilisées pour les pages CMS."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Les fonctions sans serveur créées dans les projets n'apparaissent pas dans le gestionnaire de conception."
        }), "\n"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour concevoir des fonctions sans serveur pour les pages CMS, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "documentation pour les développeurs CMS"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sandbox de développement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les sandbox de développement vous permettent de développer des projets dans un environnement de test léger afin de vous assurer que les composants de votre projet fonctionnent comme prévu avant de les déployer dans une sandbox standard ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "ou dans un compte de production"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les sandbox de développement sont créés par l'intermédiaire de l'ILC et sont accessibles à partir du compte HubSpot de production. Les sandbox de développement synchronisent certaines ressources du compte lors de la création, mais pas tous. De plus, ils sont soumis à des limites par rapport aux sandbox standard."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/developer-projects-setup",
        children: "la configuration d'une sandbox de développement"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Intégration GitHub"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous préférez utiliser GitHub pour le contrôle des versions, vous pouvez connecter un répertoire GitHub ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/link-a-github-repository-to-a-project",
        children: "à un projet"
      }), " afin de déclencher automatiquement la création du projet lorsque vous apportez une modification au répertoire connecté. Vous pouvez ainsi utiliser les outils et les workflows de GitHub pour simplifier le développement, que vous travailliez seul ou en équipe."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Premiers pas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous êtes un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/user-management/hubspot-user-permissions-guide#super-admin",
        children: "super administrateur"
      }), " d’un compte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Sales Hub"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Service Hub** Entreprise**"
      }), ", vous pouvez rejoindre cette version bêta depuis la page ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Mises à jour produits"
      }), " de votre compte HubSpot :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans votre compte HubSpot, cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nom de votre compte"
          }), " dans l'angle supérieur droit, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Mises à jour produits"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la barre latérale gauche, sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "En version bêta"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la liste des versions bêta, recherchez ou faites défiler jusqu'à la version bêta de ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Outils de développement CRM pour créer des extensions d'IU avec React en front-end"
          }), ", puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Rejoindre la version bêta"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/crm-development-tools-join-beta.png",
            alt: "crm-development-tools-join-beta"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Après avoir rejoint la version bêta, commencez par effectuer l'une des actions suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Suivez le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/quickstart",
          children: "guide de démarrage rapide"
        }), " pour créer et déployer rapidement un exemple de carte d'application."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Consultez les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/sample-projects/overview",
          children: "exemples de projets de HubSpot"
        }), " pour découvrir toutes les possibilités."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Créez votre projet à partir de zéro avec le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "guide des projets HubSpot"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Plus de ressources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/CRM-Development-Tools-Beta/gh-p/crm-development-tools-beta",
          children: "Outils de développement CRM dans la communauté HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/crm-development-tools-for-hubspot-developers-beta",
          children: "Outils de développement CRM sur le blog des développeurs HubSpot"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}