"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32159895388;
const slug = exports.slug = 'reference/api/crm/associations/association-details/v3';
const title = exports.title = 'CRM API｜関連付け｜HubSpot（ハブスポット）';
const name = exports.name = 'APAC JAPAN (ja) | vNext Docs DP - Associations_JA | 202007';
const metaDescription = exports.metaDescription = 'CRM関連付けエンドポイントは、チケット、製品、および商品項目と関係があるコンタクト、会社、取引との間の関連付けの管理に使用します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%81%AE%E5%AE%9A%E7%BE%A9",
  "label": "関連付けの定義",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "関連付けを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "関連付けを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "関連付けを削除する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      strong: "strong",
      h2: "h2",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "関連付けv3"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["There is a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/associations/association-details",
          children: "new version of the Associations API"
        }), " that adds the ability to create multiple types of associations between two object types."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "関連付けは、HubSpotのCRM上のさまざまなオブジェクトとアクティビティー間の関係を表します。関連付けエンドポイントを使用することで、関連付けを一括で作成、取得、または削除できます。以下のオブジェクトまたはエンゲージメントを関連付けることも可能です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Contacts"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Companies"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Deals"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Tickets"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Calls"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Quotes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Line_items"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Meetings"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Products"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Feedback_submissions"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Custom objects"
        }), "（カスタムオブジェクトの名前に置き換えます）"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["オブジェクト、レコード、プロパティー、および関連付けAPIの詳細については、「", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "CRMについて"
      }), "」のガイドをご確認ください。HubSpotのオブジェクトとレコードに関する一般的な情報については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/contacts/a-guide-to-using-records",
        children: "CRMデータベースを管理する方法"
      }), "をご参照ください。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/contacts-user-guide"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": this API ", (0, _jsxRuntime.jsx)("u", {
          children: "doesn't"
        }), " support parent-child associations or special contact-to-company associations. Learn more about how you can use these associations with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/associations/association-details/v1#associate-crm-objects",
          children: "previous version of the associations API"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連付けの定義"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "関連付けは、オブジェクトと方向によって定義されます。関連付けのタイプは一方向なので、関連元のオブジェクトタイプに応じて異なる定義を使用する必要があります。例："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["あるコンタクトに関連する全てのチケットを確認するには、リクエストURL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/associations/Contacts/Tickets/batch/read"
        }), "のように関連付けを定義します。コンタクトは、リクエスト本文中の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectId"
        }), "で識別します。この例では、Contactsは「fromObjectType」で、Ticketsは「toObjectType」です。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["コンタクトレコードをカスタム オブジェクト レコードに関連付けるには、カスタムオブジェクトがPetsの場合、リクエストURL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/associations/Pets/Contacts/batch/create"
        }), "のように関連付けを定義します。関連付けるコンタクトとペットのレコードは、リクエスト本文中の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectIds"
        }), "で指定します。この例では、Petsは「fromObjectType」で、Contactsは「toObjectType」です。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["定義されている、オブジェクト間の関連付けタイプの全てを確認するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/associations/{fromObjectType}/{toObjectType}/types"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連付けを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot内の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/crm-setup/associate-records",
        children: "レコードを関連付ける"
      }), "には、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/associations/{fromObjectType}/{toObjectType}/batch/create"
      }), "に送信します。リクエストには、関連付けるレコードの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "値と、そのレコードが属する関連付けの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type"
      }), "を含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、コンタクトを会社に関連付ける場合、リクエストURLは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/associations/Contacts/Companies/batch/create"
      }), "の形式になり、リクエストの内容は次のようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"inputs\": [\n    {\n      \"from\": {\n        \"id\": \"53628\"\n      },\n      \"to\": {\n        \"id\": \"12726\"\n      },\n      \"type\": \"contact_to_company\"\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連付けを取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["関連付けられたレコードを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/associations/{fromObjectType}/{toObjectType}/batch/read"
      }), "に送信します。リクエストには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fromObjectType"
      }), "の特定のレコードの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "を含めることができます。レスポンスでは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "toObjectType"
      }), "で関連付けられた全てのレコードの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "値が返されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、会社に関連付けられている全ての取引を取得する場合、リクエストURLは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/associations/Companies/Deals/batch/read"
      }), "のようになります。レスポンスには、関連付けられている全ての取引の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "値が含まれます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連付けを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レコード間の関連付けを削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/associations/{fromObjectType}/{toObjectType}/batch/archive"
      }), "に送信します。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}