'use es6';

import { AuthTypes } from '../constants';
const reducerNameMap = {
  oauth2: 'oAuthToken',
  oauth2_legacy: 'oAuthToken',
  developer_hapikey: 'apiKey',
  private_apps: 'oAuthToken',
  private_apps_legacy: 'oAuthToken'
};
function getKey(authDetails, scheme) {
  switch (scheme.type) {
    case 'oauth2':
    case 'oauth2_legacy':
    case 'apiKey':
      return authDetails[scheme._key] || authDetails[reducerNameMap[scheme._key]] || scheme['x-default'] || '';
    case 'http':
      return '';
    default:
      return '';
  }
}
export function getAuth({
  oas,
  authDetails
}) {
  if (Object.keys(oas.components || {}).length === 0 || Object.keys(oas.components.securitySchemes || {}).length === 0) return {};
  return Object.keys(oas.components.securitySchemes).map(scheme => {
    return {
      [scheme]: getKey(authDetails, Object.assign({}, oas.components.securitySchemes[scheme], {
        _key: scheme
      }))
    };
  }).reduce((prev, next) => Object.assign(prev, next), {});
}
export function isSendButtonDisabled(authDetails, isConfirming) {
  const {
    authType,
    apiKey,
    oAuthToken
  } = authDetails;
  if (isConfirming) {
    return false;
  }
  const isMissingApiKey = authType === AuthTypes.DEVELOPER_API_KEY && !apiKey;
  const isMissingToken = (authType === AuthTypes.OAUTH_TOKEN || authType === AuthTypes.PRIVATE_APPS) && !oAuthToken;
  return isMissingApiKey || isMissingToken;
}