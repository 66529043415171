"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.orderedTitles = exports.orderedHttpMethods = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 2;
const slug = exports.slug = 'api/marketing/marketing-events';
const title = exports.title = 'Marketing Events API';
const name = exports.name = 'Marketing Events API';
const metaDescription = exports.metaDescription = 'Marketing Events API';
const orderedTitles = exports.orderedTitles = ['Events HTTP API'];
const orderedHttpMethods = exports.orderedHttpMethods = ['GET'];
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h4: "h4",
      p: "p",
      em: "em",
      strong: "strong",
      a: "a",
      h3: "h3",
      code: "code",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      EndpointCard,
      EndpointLeftColumn,
      Endpoint,
      Alert,
      EndpointRequirements,
      EndpointParameters,
      EndpointParameter,
      EndpointRightColumn,
      EndpointExample
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!Endpoint) _missingMdxReference("Endpoint", true);
  if (!EndpointCard) _missingMdxReference("EndpointCard", true);
  if (!EndpointExample) _missingMdxReference("EndpointExample", true);
  if (!EndpointLeftColumn) _missingMdxReference("EndpointLeftColumn", true);
  if (!EndpointParameter) _missingMdxReference("EndpointParameter", true);
  if (!EndpointParameters) _missingMdxReference("EndpointParameters", true);
  if (!EndpointRequirements) _missingMdxReference("EndpointRequirements", true);
  if (!EndpointRightColumn) _missingMdxReference("EndpointRightColumn", true);
  return (0, _jsxRuntime.jsxs)(EndpointCard, {
    children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
      children: [(0, _jsxRuntime.jsx)(_components.h4, {
        children: "Events HTTP API"
      }), (0, _jsxRuntime.jsx)(Endpoint, {
        method: "GET",
        path: "track.hubspot.com/v1/event/"
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["If you have a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " account, use this API to trigger a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/events/create-custom-events",
          children: "custom behavioral event"
        }), " using an HTTP GET request. Event completions can be tracked in HubSpot and used to segment contacts, score leads, and more."]
      }), (0, _jsxRuntime.jsx)(Alert, {
        type: "warning",
        children: (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Please note:"
          }), " if you have an EU portal you must use the tracking server track-eu1.hubspot.com."]
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Please note:"
      }), (0, _jsxRuntime.jsx)(_components.h3, {
        children: "Response details"
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["If the request is successful, it will have a status code of 200 with no data in the body. The content-type will be image/gif, so it's possible to use HTTP API urls with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<img>"
        }), " tags, though we recommend using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "JavaScript API"
        }), " on webpages when possible."]
      }), (0, _jsxRuntime.jsx)(_components.h3, {
        children: "Parameters"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "All parameters below are used in the request URL."
      }), (0, _jsxRuntime.jsxs)(Alert, {
        type: "warning",
        children: [(0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Please note:"
          }), " all symbols in a contact's email address must be ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.w3schools.com/tags/ref_urlencode.ASP",
            children: "encoded"
          }), ", except for the following:"]
        }), (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "@"
            }), " symbol"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["hyphen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "-"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["underscore ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "_"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["period ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "."
            })]
          }), "\n"]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRequirements, {
        scope: "business-intelligence"
      }), (0, _jsxRuntime.jsxs)(EndpointParameters, {
        children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
          name: "_a",
          required: "true",
          schemaGroup: "Path",
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Your HubSpot Hub ID. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/articles/kcs_article/account/where-can-i-find-my-hub-id",
              children: "this page"
            }), " for help finding your Hub ID."]
          })
        }), (0, _jsxRuntime.jsx)(EndpointParameter, {
          name: "_n",
          required: "true",
          schemaGroup: "Path",
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ID or name of the event you want to trigger. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/tracking-code#idsandnames",
              children: "here"
            }), " for more details."]
          })
        }), (0, _jsxRuntime.jsx)(EndpointParameter, {
          name: "email",
          schemaGroup: "Query",
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "Associates the event with a known HubSpot contact. If the contact doesn't yet exist in HubSpot, it will be created."
          })
        }), (0, _jsxRuntime.jsx)(EndpointParameter, {
          name: "_m",
          schemaGroup: "Query",
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Any numerical amount that you provide here will increment the contact's ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Revenue"
            }), " field within the Web Analytics History section."]
          })
        }), (0, _jsxRuntime.jsx)(EndpointParameter, {
          name: "Any Contact Property",
          schemaGroup: "Query",
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["If your account was created before September 8, 2021 and is set up to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/prevent-contact-properties-update-through-tracking-code-api",
              children: "allow contact properties to be updated through the tracking code"
            }), ", you can also include any contact properties with the event that you’re triggering. This will populate the corresponding contact property for the contact specified by the email parameter. For example, if you have a property with the name ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "favorite_color"
            }), ", you can populate that property using ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&favorite_color=orange"
            })]
          })
        }), (0, _jsxRuntime.jsx)(EndpointParameter, {
          name: "id",
          schemaGroup: "Query",
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Ties the event to a record based on an external ID (not the HubSpot contact's ID). Unlike email, including this id will not automatically create a contact record. You would need to have some event associating the ID with an email, such as a previous HTTP Event call with both the ID and email, or by using the ID with the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/tracking-code",
              children: "JavaScript API"
            }), " for a visitor that was associated with an email address."]
          })
        })]
      })]
    }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
      children: (0, _jsxRuntime.jsx)(EndpointExample, {
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: "CkV4YW1wbGUgVVJMOgpodHRwczovL3RyYWNrLmh1YnNwb3QuY29tL3YxL2V2ZW50P19uPTAwMDAwMDAwMTYyNSZfYT02MjUxNSZlbWFpbD10ZXN0aW5nYXBpc0BodWJzcG90LmNvbQ=="
        })
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}