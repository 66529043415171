"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937503;
const slug = exports.slug = 'guides/api/crm/extensions/calling-sdk';
const title = exports.title = 'CRM API | 電話拡張SDK';
const name = exports.name = 'vNext Docs DP - 電話拡張SDK';
const metaDescription = exports.metaDescription = '電話拡張SDKの概要をご紹介します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%87%E3%83%A2%E7%94%A8%E3%82%B3%E3%83%BC%E3%83%AB%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E5%AE%9F%E8%A1%8C%E3%81%99%E3%82%8B",
  "label": "デモ用コールアプリを実行する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%87%E3%83%A2%E7%94%A8%E3%82%B3%E3%83%BC%E3%83%AB%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E3%81%99%E3%82%8B",
  "label": "デモ用コールアプリをインストールする",
  "parent": "%E3%83%87%E3%83%A2%E7%94%A8%E3%82%B3%E3%83%BC%E3%83%AB%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E5%AE%9F%E8%A1%8C%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "hubspot%E3%81%8B%E3%82%89%E3%83%87%E3%83%A2%E7%94%A8%E3%82%B3%E3%83%BC%E3%83%AB%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E8%B5%B7%E5%8B%95%E3%81%99%E3%82%8B",
  "label": "HubSpotからデモ用コールアプリを起動する",
  "parent": "%E3%83%87%E3%83%A2%E7%94%A8%E3%82%B3%E3%83%BC%E3%83%AB%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E5%AE%9F%E8%A1%8C%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E9%9B%BB%E8%A9%B1%E6%8B%A1%E5%BC%B5sdk%E3%82%92%E3%82%B3%E3%83%BC%E3%83%AB%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AB%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E3%81%99%E3%82%8B",
  "label": "電話拡張SDKをコールアプリにインストールする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%9B%BB%E8%A9%B1%E6%8B%A1%E5%BC%B5sdk%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "電話拡張SDKを使用する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E3%83%86%E3%82%B9%E3%83%88%E3%81%99%E3%82%8B",
  "label": "アプリをテストする",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B3%E3%83%BC%E3%83%AB%E8%A8%AD%E5%AE%9Aapi%E3%82%A8%E3%83%B3%E3%83%89%E3%83%9D%E3%82%A4%E3%83%B3%E3%83%88%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "コール設定APIエンドポイントを使用する",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E3%83%86%E3%82%B9%E3%83%88%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "localstorage%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E6%8B%A1%E5%BC%B5%E6%A9%9F%E8%83%BD%E3%81%AE%E8%A8%AD%E5%AE%9A%E3%82%92%E4%B8%8A%E6%9B%B8%E3%81%8D%E3%81%99%E3%82%8B",
  "label": "localStorageを使用して拡張機能の設定を上書きする",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E3%83%86%E3%82%B9%E3%83%88%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E6%9C%AC%E7%95%AA%E7%92%B0%E5%A2%83%E3%81%AB%E5%90%91%E3%81%91%E3%81%A6%E6%BA%96%E5%82%99%E3%81%99%E3%82%8B",
  "label": "アプリを本番環境に向けて準備する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B3%E3%83%BC%E3%83%AB%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92hubspot%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%83%E3%83%88%E3%83%97%E3%83%AC%E3%82%A4%E3%82%B9%E3%81%AB%E5%85%AC%E9%96%8B%E3%81%99%E3%82%8B",
  "label": "コールアプリをHubSpotマーケットプレイスに公開する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88",
  "label": "イベント",
  "parent": null
}, {
  "depth": 1,
  "id": "hubspot%E3%81%AB%E3%83%A1%E3%83%83%E3%82%BB%E3%83%BC%E3%82%B8%E3%82%92%E9%80%81%E4%BF%A1%E3%81%99%E3%82%8B",
  "label": "HubSpotにメッセージを送信する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "initialized",
  "label": "initialized",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "userloggedin",
  "label": "userLoggedIn",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "userloggedout",
  "label": "userLoggedOut",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "outgoingcall",
  "label": "outgoingCall",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "callanswered",
  "label": "callAnswered",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "callended",
  "label": "callEnded",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "callcompleted",
  "label": "callCompleted",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "senderror",
  "label": "sendError",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "resizewidget",
  "label": "resizeWidget",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 1,
  "id": "hubspot%E3%81%8B%E3%82%89%E3%81%AE%E3%83%A1%E3%83%83%E3%82%BB%E3%83%BC%E3%82%B8%E3%81%AE%E5%8F%97%E4%BF%A1",
  "label": "HubSpotからのメッセージの受信",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "onready",
  "label": "onReady",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "ondialnumber",
  "label": "onDialNumber",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "onengagementcreated",
  "label": "onEngagementCreated",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "oncreateengagementsucceeded",
  "label": "onCreateEngagementSucceeded",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "oncreateengagementfailed",
  "label": "onCreateEngagementFailed",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "onvisibilitychanged",
  "label": "onVisibilityChanged",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 2,
  "id": "defaulteventhandler",
  "label": "defaultEventHandler",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88"
}, {
  "depth": 0,
  "id": "%E9%9B%BB%E8%A9%B1%E6%8B%A1%E5%BC%B5sdk-%7C-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F",
  "label": "電話拡張SDK | よくある質問",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E8%AA%8D%E8%A8%BC%E3%81%AF%E3%81%A9%E3%81%AE%E3%82%88%E3%81%86%E3%81%AB%E5%87%A6%E7%90%86%E3%81%95%E3%82%8C%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "ユーザー認証はどのように処理されますか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%9B%BB%E8%A9%B1%E6%8B%A1%E5%BC%B5%E3%81%AFcdn%E3%81%A7%E3%83%9B%E3%82%B9%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0%E3%81%95%E3%82%8C%E3%82%8B%E3%81%AE%E3%81%A7%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "電話拡張はCDNでホスティングされるのですか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A8%E3%83%B3%E3%82%B2%E3%83%BC%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88%E3%81%AF%E3%81%A9%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%9F%E3%83%B3%E3%82%B0%E3%81%A7%E4%BD%9C%E6%88%90%E3%81%BE%E3%81%9F%E3%81%AF%E6%9B%B4%E6%96%B0%E3%81%95%E3%82%8C%E3%82%8B%E3%81%AE%E3%81%A7%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "エンゲージメントはどのタイミングで作成または更新されるのですか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%80%A3%E6%90%BA%E3%81%AE%E4%B8%80%E9%83%A8%E3%81%A8%E3%81%97%E3%81%A6%E5%BF%85%E8%A6%81%E3%81%AA%E3%82%B9%E3%82%B3%E3%83%BC%E3%83%97%E3%81%AF%E4%BD%95%E3%81%A7%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "連携の一部として必要なスコープは何ですか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%81%93%E3%81%AE%E6%A9%9F%E8%83%BD%E3%82%92%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%83%E3%83%88%E3%83%97%E3%83%AC%E3%82%A4%E3%82%B9%E3%81%AE%E6%97%A2%E5%AD%98%E3%81%AE%E3%82%A2%E3%83%97%E3%83%AA%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AB%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B%E3%81%93%E3%81%A8%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F%E3%81%9D%E3%82%8C%E3%81%A8%E3%82%82%E6%96%B0%E3%81%97%E3%81%84%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E4%BD%9C%E6%88%90%E3%81%97%E3%81%A6%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B%E3%81%AE%E3%81%A7%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "この機能をマーケットプレイスの既存のアプリケーションに追加することはできますか？それとも新しいアプリを作成して追加するのですか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AE%E3%82%BD%E3%83%95%E3%83%88-%E3%83%95%E3%82%A9%E3%83%B3-%E3%82%A2%E3%83%97%E3%83%AA%E3%82%B1%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%92sdk%E3%81%A7%E9%80%A3%E6%90%BA%E3%81%95%E3%81%9B%E3%82%8B%E3%81%93%E3%81%A8%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "既存のソフト フォン アプリケーションをSDKで連携させることはできますか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%AF%E8%A4%87%E6%95%B0%E3%81%AE%E9%80%A3%E6%90%BA%E3%82%92%E5%90%8C%E6%99%82%E3%81%AB%E4%BD%BF%E7%94%A8%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "ユーザーは複数の連携を同時に使用できますか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E7%84%A1%E6%96%99%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%AF%E3%82%A2%E3%83%97%E3%83%AA%E9%80%A3%E6%90%BA%E3%82%92%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "無料ユーザーはアプリ連携をインストールできますか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%8C%E6%97%A2%E3%81%AB%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E3%81%97%E3%81%A6%E3%81%84%E3%82%8B%E5%A0%B4%E5%90%88%E3%80%81%E9%80%A3%E6%90%BA%E3%81%AF%E8%87%AA%E5%8B%95%E7%9A%84%E3%81%AB%E8%A1%A8%E7%A4%BA%E3%81%95%E3%82%8C%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "ユーザーが既にアプリをインストールしている場合、連携は自動的に表示されますか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%81%A9%E3%81%AE%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%A7%E3%82%82%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E3%81%BE%E3%81%9F%E3%81%AF%E3%82%A2%E3%83%B3%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "どのユーザーでもアプリをインストールまたはアンインストールできますか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%81%AE%E3%82%B3%E3%83%BC%E3%83%AB%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B%E3%81%93%E3%81%A8%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "カスタムのコールプロパティーを作成することはできますか？",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%8B%E3%82%89%E9%9B%BB%E8%A9%B1%E3%82%92%E3%81%8B%E3%81%91%E3%82%8B%E3%81%93%E3%81%A8%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F",
  "label": "カスタムオブジェクトから電話をかけることはできますか？",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      code: "code",
      pre: "pre",
      ol: "ol",
      img: "img",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "電話拡張SDK"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**HubSpotのコールアプリのパートナー様は、コールエンゲージメントを手動で作成したり、更新したりする必要がなくなりました。HubSpotが代わりにコールエンゲージメントの作成と更新に対処します。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: "こちら"
        }), "をご覧ください。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/calling-extensions-sdk",
        children: "電話拡張SDK"
      }), "を使用すると、CRM内のレコードから直接、HubSpotユーザーに対してカスタムの通話オプションを提供できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "電話拡張は、次の3つのコンポーネントで構成されています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1.", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/calling-extensions-sdk",
        children: "電話拡張SDK"
      }), "（英語）。貴社のアプリとHubSpot間の通信を可能にするJavaScript SDK。2. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "コール設定エンドポイント"
      }), "。貴社のアプリのコール設定のために使用するAPIエンドポイント。この設定は、アプリに接続する各HubSpotアカウントで使用されます。3. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "コールiframe"
      }), "。貴社のアプリをHubSpotユーザーに対して表示する場所。コール設定APIエンドポイントを使用して設定されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリ内でのコール操作について詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/use-the-calling-tool#call-from-your-phone",
        children: "こちらのナレッジベース記事"
      }), "をご覧ください。電話拡張を有効にしたアプリがHubSpotに接続されると、ユーザーが", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/use-the-calling-tool",
        children: "HubSpotから電話をかける"
      }), "際に、コール切り替え機能のオプションとして表示されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリをまだ用意していない場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types#app-developer-accounts",
        children: "HubSpot開発者アカウントを使用してアプリを作成"
      }), "できます。HubSpot開発者アカウントをまだお持ちでない場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "こちら"
      }), "から登録してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**現在サポートされているのは発信通話のみです。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "デモ用コールアプリを実行する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "2つの異なるデモ用アプリで電話拡張SDKをテストするオプションがあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/tree/master/demos/demo-minimal-js",
          children: "demo-minimal-js"
        }), "では、JavaScript、HTML、CSSを使用して必要最小限のSDKが実装されています。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/blob/project-demo-v1/demos/demo-minimal-js/index.js",
          children: "index.js"
        }), "でSDKをインスタンス化する方法を確認できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/tree/master/demos/demo-react-ts",
          children: "demo-react-ts"
        }), "では、アプリのブループリントとしての役割を果たす、React、TypeScript、Styled Componentsを使用した実用的なSDKが実装されています。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/calling-extensions-sdk/blob/master/demos/demo-react-ts/src/hooks/useCti.ts",
          children: "useCti.ts"
        }), "でSDKをインスタンス化する方法を確認できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**これらのデモ用アプリは完全に機能するコールアプリではありませんが、モックデータを使用してより現実的なエクスペリエンスを実現します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "デモ用コールアプリをインストールする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "インストールの有無にかかわらず、デモ用アプリを実行できます。ローカル環境にデモをインストールするには："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1.お使いの環境に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en",
        children: "Node.js"
      }), "をインストールします。2. このリポジトリーの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/calling-extensions-sdk/archive/refs/heads/master.zip",
        children: "ZIPをダウンロード"
      }), "、複製、またはフォークします。3. ターミナルを開き、プロジェクトのルートディレクトリーに移動します。4. 次のコマンドのいずれかを実行します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "demo-minimal-js"
        }), "の場合："]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd demos/demo-minimal-js && npm i && npm start\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "demo-react-ts"
        }), "の場合："]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd demos/demo-react-ts && npm i && npm start\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これによって、目的のデモディレクトリーに切り替わり、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "npm CLI"
      }), "を使用してプロジェクトに必要な", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.npmjs.com/cli/v9",
        children: "Node.js"
      }), "の依存関係がインストールされ、アプリが起動されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm start"
        }), "コマンドを実行すると、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://localhost:9025/",
          children: "https://localhost:9025/"
        }), "に接続された新しいブラウザータブが自動的に開きます。アプリケーションにアクセスするには、「接続がセキュリティーで保護されていない」ことを通知するメッセージをバイパスする必要があります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubSpotからデモ用コールアプリを起動する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "1.レコードに移動します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**コンタクト：**HubSpotアカウントで、［コンタクト］＞［コンタクト］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**会社：**HubSpotアカウントで、［コンタクト］＞［会社］に移動します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      start: "2",
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ブラウザーの開発者コンソールを開き、次のコマンドを実行します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-react-ts"
            }), "のインストール手順が完了している場合："]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'local');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["インストール手順をスキップした場合：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "demo-minimal-js"
            }), "の場合："]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'app:js');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "demo-react-ts"
        }), "の場合："]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "localStorage.setItem('LocalSettings:Calling:installDemoWidget', 'app');\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1.ページを更新し、左のサイドバーで", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "コール"
      }), "アイコンをクリックします。発信元］ドロップダウンメニューをクリックして、その中からデモ用アプリの名前を選択します（例：Demo App Local、Demo App JS、Demo App React）。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/call-app-in-record.png",
        alt: "call-app-in-record"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      start: "2",
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［コール］をクリックして、デモ用アプリが電話拡張SDKを介してHubSpotとどのように連携しているかを確認します。また、ブラウザーの開発者コンソールに記録されたイベントも表示できます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/calling-sdk-in-app.png",
        alt: "calling-sdk-in-app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "電話拡張SDKをコールアプリにインストールする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["電話拡張SDKを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), "の依存関係としてコールアプリに追加するには："]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "npmの場合は次を実行します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i --save @hubspot/calling-extensions-sdk\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "yarnの場合は次を実行します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "yarn add @hubspot/calling-extensions-sdk\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "電話拡張SDKを使用する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["電話拡張SDKは、メッセージを交換するために、HubSpot対応のシンプルなAPIとコールアプリを公開します。メッセージは、SDKで公開されるメソッドを介して送信され、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventHandlers"
      }), "を介して受信されます。利用可能なイベントを網羅したリストが、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#events",
        children: "「イベント」セクション"
      }), "に記載されています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベントの説明は次のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**番号をダイヤルする：**HubSpotが番号のダイヤルイベントを送信します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**発信通話が開始された：**通話が開始されると、アプリがHubSpotに通知します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**エンゲージメントを作成する：**HubSpotは、アプリから要求された場合、最小限の情報で", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: "コールエンゲージメント"
        }), "を作成します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**エンゲージメントが作成された：**HubSpotがエンゲージメントを作成しました。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**EngagementIdがアプリに送信された：**HubSpotが", (0, _jsxRuntime.jsx)(_components.code, {
          children: "engagementId"
        }), "をアプリに送信します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**通話が終了した：**通話が終了するとアプリが通知します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**通話が完了した：**ユーザーがアプリのユーザー体験を完了するとアプリが通知します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**エンゲージメントを更新する：**アプリは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "engagementId"
        }), "によってエンゲージメントを取得し、その他の通話の詳細とエンゲージメントをマージして更新します。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls#update-calls",
          children: "APIを介したコールエンゲージメントの更新"
        }), "、または", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: "SDKを介したコールエンゲージメントの更新"
        }), "について詳細をご確認ください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["まず、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CallingExtensions"
      }), "オブジェクトのインスタンスを作成します。拡張機能インスタンスを作成するときにオプションのオブジェクトを指定することで、拡張機能の動作を定義できます。このオプションのオブジェクトは、拡張機能の動作を指定できる", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventHandlers"
      }), "フィールドを提供します。次のコードブロックは、使用可能なオプションと定義可能なイベントハンドラーを示しています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import CallingExtensions from \"@hubspot/calling-extensions-sdk\";\n\nconst options = {\n  /** @property {boolean} debugMode - Whether to log various inbound/outbound debug messages to the console. If false, console.debug will be used instead of console.log */\n  debugMode: boolean,\n  // eventHandlers handle inbound messages\n  eventHandlers: {\n    onReady: () => {\n      /* HubSpot is ready to receive messages. */\n    },\n    onDialNumber: event => {\n      /* HubSpot sends a dial number from the contact */\n    },\n    onCreateEngagementSucceeded: event => {\n      /* HubSpot has created an engagement for this call. */\n    }\n    onEngagementCreatedFailed: event => {\n      /* HubSpot has failed to create an engagement for this call. */\n    }\n    onUpdateEngagementSucceeded: event => {\n      /* HubSpot has updated an engagement for this call. */\n    },\n    onUpdateEngagementFailed: event => {\n      /* HubSpot has failed to update an engagement for this call. */\n    }\n    onVisibilityChanged: event => {\n      /* Call widget's visibility is changed. */\n    }\n  }\n};\n\nconst extensions = new CallingExtensions(options);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリをテストする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "エンドユーザー向けの電話拡張iFrameを起動するには、HubSpotに次のiFrameパラメーターが必要です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n   name: string /* The name of your calling app to display to users. */,\n   url: string  /* The URL of your calling app, built with the Calling Extensions SDK */,\n   width: number /* The iFrame's width */,\n   height: number /* The iFrame's height */,\n   isReady: boolean /* Whether the widget is ready for production (defaults to true) */,\n   supportsCustomObjects : true /* Whether calls can be placed from a custom object */\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "コール設定APIエンドポイントを使用する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["APIツール（Postmanなど）を使用して、次のペイロードをHubSpotの設定APIに送信します。必ずコールアプリのAPP_IDとアプリの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#authentication",
        children: "DEVELOPER_ACCOUNT_API_KEY"
      }), "を取得してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "isReady"
        }), "フラグは、アプリが本番環境に向けて準備できているかどうかを示します。テスト中は、このフラグをfalseに設定する必要があります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Example payload to add the call widget app settings\ncurl --request POST \\\n --url 'https://api.hubapi.com/crm/v3/extensions/calling/APP_ID/settings?hapikey=DEVELOPER_ACCOUNT_API_KEY' \\\n --header 'accept: application/json' \\\n --header 'content-type: application/json' \\\n --data '{\"name\":\"demo widget\",\"url\":\"https://mywidget.com/widget\",\"height\":600,\"width\":400,\"isReady\":false}'\n\n\n# Note that this endpoint also supports PATCH, GET and DELETE\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "localStorageを使用して拡張機能の設定を上書きする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テスト目的で、拡張機能の設定を上書きできます。HubSpotタブからブラウザーの開発者コンソールを開き、以下の設定を編集して、コマンドを実行します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const myExtensionSettings = {\n  isReady: true,\n  name: 'My app name',\n  url: 'My local/qa/prod URL',\n};\n\nlocalStorage.setItem(\n  'LocalSettings:Calling:CallingExtensions',\n  JSON.stringify(myExtensionSettings)\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリを本番環境に向けて準備する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリのテスト時に既にPOSTエンドポイントを使用している場合は、PATCHエンドポイントを使用して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "isReady"
      }), "をtrueに変更できます。それ以外の場合は、APIツール（Postmanなど）を使用して、このペイロードをHubSpotの設定APIに送信します。必ずコールアプリのAPP_IDとアプリの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview#authentication",
        children: "DEVELOPER_ACCOUNT_API_KEY"
      }), "を取得してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Example payload to add the call widget app settings\ncurl --request POST \\\n --url 'https://api.hubapi.com/crm/v3/extensions/calling/APP_ID/settings?hapikey=DEVELOPER_ACCOUNT_API_KEY' \\\n --header 'accept: application/json' \\\n --header 'content-type: application/json' \\\n --data '{\"name\":\"demo widget\",\"url\":\"https://mywidget.com/widget\",\"height\":600,\"width\":400,\"isReady\":true}'\n\n\n# Note that this endpoint also supports PATCH, GET and DELETE\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "コールアプリをHubSpotマーケットプレイスに公開する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリのセットアップが完了したら、最後のステップとしてHubSpotマーケットプレイスにコールアプリを掲載します。詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "こちら"
      }), "をご覧ください。このアプリケーションが社内専用である場合は、マーケットプレイスに掲載しないという選択肢もあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "使用可能な通話イベント："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["HubSpotへのメッセージの送信", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#demo-minimal-js"
          }), (0, _jsxRuntime.jsx)(_components.a, {
            href: "#yarn"
          })]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#initialized",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "initialized"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedIn",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "userLoggedIn"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#userLoggedOut",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "userLoggedOut"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#outgoingCall",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "outgoingCall"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callAnswered",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callAnswered"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callEnded",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callEnded"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#callCompleted",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "callCompleted"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#sendError",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "sendError"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#resizeWidget",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "resizeWidget"
              })
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "HubSpotからのメッセージの受信"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onReady",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onReady"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onDial",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onDialNumber"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onEngagementCreated",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onEngagementCreated"
              })
            }), "（非推奨）"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onEngagementCreated"
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementSucceeded",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onCreateEngagementSucceeded"
              })
            })]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onUpdateEngagementFailed"
              })
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onVisibilityChanged",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "onVisibilityChanged"
              })
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "#onVisibilityChanged"
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "#defaultEventHandler",
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "defaultEventHandler"
              })
            })]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubSpotにメッセージを送信する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions"
      }), "オブジェクトは、HubSpotにメッセージを送信したり、他の動作を指定して関連付けたりするために呼び出すことができる、次のイベントハンドラーを提供します。以下の例を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "initialized"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ソフトフォンがインタラクションに対応できる状態であることを通知するメッセージを送信します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const payload = {\n  isLoggedIn: boolean,\n};\n\nextensions.initialized(payload);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isLoggedIn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール型"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザーがログインしているかどうかをチェックします。"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "userLoggedIn"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ユーザーがログインしたことを通知するメッセージを送信します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// This message is only needed when user isn't logged in when initialized\nextensions.userLoggedIn();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "userLoggedOut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ユーザーがログアウトしたことを通知するメッセージを送信します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "extensions.userLoggedOut();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "outgoingCall"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "発信通話が開始されたことをHubSpotに通知するメッセージを送信します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const callInfo = {\n  phoneNumber: string /** @deprecated Use toNumber instead **/,\n  callStartTime: number,\n  createEngagement: true,\n  toNumber: string,\n  fromNumber: string,\n};\nextensions.outgoingCall(callInfo);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callStartTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値型"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コールの開始時刻（ミリ秒単位）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createEngagement"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール型"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["HubSpotに当該コールのエンゲージメントを作成させるかどうかを指定します。trueの場合、HubSpotは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementSucceeded",
              children: "onCreateEngagementSucceeded"
            }), "または", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed",
              children: "onCreateEngagementFailed"
            }), "で応答します。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onCreateEngagementFailed"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列型"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "受信者の電話番号。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列型"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "発信者の電話番号。必須パラメーター"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callAnswered"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "発信通話に応答中であることをHubSpotに通知するメッセージを送信します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "extensions.callAnswered();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callEnded"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "通話が終了したことをHubSpotに通知するメッセージを送信します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the call ended event, the user can navigate away, can close the call widget.\nextensions.callEnded({\n  engagementId: number,\n  callEndStatus: EndStatus,\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値型"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotによって作成されるエンゲージメントID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callEndStatus"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "列挙"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["終了時のコールのステータス。有効なステータス：", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_COMPLETED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_FAILED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_CANCELED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_BUSY"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_NO_ANSWER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_REJECTED"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "INTERNAL_MISSED"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "callCompleted"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コールが完了したことをHubSpotに通知するメッセージを送信します。エンゲージメントのプロパティーは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/calls",
        children: "HubSpotが所有"
      }), "するため、手動で作成または更新する必要がなくなりました（強調表示されている箇所を参照）。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**ユーザーが", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Call"
        }), "タスクタイプのタスクキューに入れられている場合、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hideWidget"
        }), "プロパティーは無視されます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the call completed event, HubSpot will\n//   1) insert the engagement into the timeline\n//   2) set the default associations on the engagement\n//   3) closes the widget unless `hideWidget` is set to false.\n//   4) update the engagement with any engagement properties\nconst data = {\n  engagementId: number,\n  hideWidget: boolean,\n  engagementProperties: { [key: string]: string },\n  externalCallId: number,\n}\nextensions.callCompleted(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値型"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotによって作成されるエンゲージメントID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hideWidget"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール型"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "通話の終了時にウィジェットを非表示にするかどうかを指定します。任意指定のパラメーターです。既定ではtrueに設定されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列型"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/calls#properties",
              children: "プロパティーを追加"
            }), "して、HubSpot所有のエンゲージメントにオプトインします。これにより、HubSpotは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onUpdateEngagementSucceeded",
              children: "onUpdateEngagementSucceeded"
            }), "または", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#onUpdateEngagementFailed",
              children: "onUpdateEngagementFailed"
            }), "で応答するようになります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalCallId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値型"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コールアプリによって作成されるコールID。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/extensions/third-party-calling",
              children: "ヘルプデスクでの通話"
            }), "を有効にするために使用されます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "sendError"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コールアプリでエラーが発生したことをHubSpotに通知するメッセージを送信します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the sendError event, HubSpot will display an alert popup to the user with the error message provided.\nconst data = {\n  message: string,\n};\nextensions.sendError(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列型"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アラートポップアップに表示されるエラーメッセージ。"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "resizeWidget"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コールアプリのサイズを変更する必要があることをHubSpotに通知するメッセージを送信します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// After receiving the resizeWidget event, HubSpot will use the provided height and width to resize the call widget.\nconst data = {\n  height: number,\n  width: number,\n};\nextensions.resizeWidget(data);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値型"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "望ましいコールウィジェットの高さ。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値型"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "望ましいコールウィジェットの幅。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubSpotからのメッセージの受信"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions"
      }), "オブジェクトは、HubSpotでメッセージを受信したり、または他の動作を指定して関連付けたりするために呼び出すことができる、次のイベントハンドラーを提供します。以下の例を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onReady"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotがメッセージを受信できる状態であることを通知するメッセージ。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example snippet for handling onReady event\nonReady() {\n    extensions.initialized(payload);\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onDialNumber"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このイベントは、HubSpotでユーザーが発信通話を開始するとトリガーされます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onDialNumber"
      }), "イベントのペイロードには、コールに関連付けられている全てのフィールドが含まれます。これらのフィールドについて、以下の表で詳しく説明します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onDialNumber(data) {\n  const {\n    phoneNumber: string,\n    ownerId: number,\n    subjectId: number,\n    objectId: number,\n    objectType: CONTACT | COMPANY,\n    portalId: number,\n    countryCode: string,\n    calleeInfo {\n        calleeId: number,\n        calleeObjectTypeId: string,\n    },\n    startTimestamp: number,\n    toPhoneNumberSrc: string,\n   } = data;\n    ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["| プロパティー | 型 | 説明 |\n| --- | --- | --- | --- |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "phoneNumber"
      }), " | 文字列型 | HubSpotユーザーが電話をかけた相手の電話番号。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ownerId"
      }), " | 文字列型 | 数値型 | HubSpotにログインしているユーザーのID。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "subjectId"
      }), " | 数値型 | 件名のID。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " | 数値型 | 電話番号のオブジェクトタイプ。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " | 文字列型 | ダイヤルされた電話番号（コンタクトまたは会社の電話番号など）に関連付けられているオブジェクトタイプ。有効な値は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"CONTACT\""
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"COMPANY\""
      }), "です。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), " | 数値型 | HubSpotポータルのID。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "countryCode"
      }), " | 文字列型 | 電話番号の国コード。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "calleeInfo"
      }), " | 配列型 | 着信者に関する情報。以下を含める必要があります。", (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "calleeID: number"
          })
        }), (0, _jsxRuntime.jsx)("li", {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "calleeObjectTypeId: string"
          })
        })]
      }), " |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "startTimestamp"
      }), " | 数値型 | 通話開始時のタイムスタンプ。 |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "toPhoneNumberSrc"
      }), " | 文字列型 | ' 電話番号の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/properties/create-and-edit-properties",
        children: "HubSpotでのプロパティー名"
      }), "。プロパティーには、標準のプロパティー値またはカスタムプロパティーを指定できます。例えばコンタクトに3つの連絡先電話番号がある場合、それぞれ「", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Office"
      }), "」、「", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Personal"
      }), "」、「", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Mobile"
      }), "」という関連付けラベルを設定できます。 |"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onEngagementCreated"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["非推奨。代わりに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#onCreateEngagementSucceeded",
        children: "onCreateEngagementSucceeded"
      }), "を使用してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/** @deprecated Use onCreateEngagementSucceeded instead **/\nonEngagementCreated(data) {\n    const {\n      engagementId: number,\n    } = data;\n      ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "engagementId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値型"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotによって作成されるエンゲージメントID。"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onCreateEngagementSucceeded"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotが、エンゲージメントが正常に更新されたことを通知するメッセージをコールアプリのパートナー様に送信します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCreateEngagementSucceeded: (event) => {};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onCreateEngagementFailed"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotが、エンゲージメントを作成できなかったことを通知するメッセージをコールアプリのパートナー様に送信します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onCreateEngagementFailed: (event) => {};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "onVisibilityChanged"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ユーザーがコールアプリを最小化または非表示にしたかどうかを通知するメッセージ。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "onVisibilityChanged(data) {\n    const { isMinimized, isHidden } = data;\n    ...\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "defaultEventHandler"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "イベントの既定のハンドラー。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "defaultEventHandler(event) {\n   console.info(\"Event received. Do you need to handle it?\", event);\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "電話拡張SDK | よくある質問"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ユーザー認証はどのように処理されますか？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コールアプリが認証を処理します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "電話拡張はCDNでホスティングされるのですか？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["はい。電話拡張SDKは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.jsdelivr.com/",
        children: "jsDeliver"
      }), "経由でインストールできます。例えば、calling-extensions-sdk@0.2.2をインストールするには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn.jsdelivr.net/npm/@hubspot/calling-extensions-sdk@0.2.2/dist/main.js",
        children: "https://cdn.jsdelivr.net/npm/@hubspot/calling-extensions-sdk@0.2.2/dist/main.js"
      }), "を使用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "エンゲージメントはどのタイミングで作成または更新されるのですか？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ユーザーは、HubSpot UI内からもHubSpot UI外からも通話を開始できます（モバイルアプリ、リダイレクトされた番号など）。コールがHubSpot UI内から開始された場合、HubSpotはコールエンゲージメントを作成し、コールアプリにエンゲージメントを送信します。通話が終了すると、コールアプリはコールのその他の詳細を追加し、このエンゲージメントを更新できます。通話がHubSpot UIの外部で開始された場合、アプリは通話エンゲージメントを作成します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "連携の一部として必要なスコープは何ですか？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "連絡先とタイムラインのスコープを追加する必要があります。これらのスコープにより、アプリケーションは連絡先にアクセスでき、CRMでコールエンゲージメントを作成および更新することができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "この機能をマーケットプレイスの既存のアプリケーションに追加することはできますか？それとも新しいアプリを作成して追加するのですか？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コール ユース ケースに対応する既存のアプリを既に持っている場合は、この機能を既存のアプリに直接追加できます。既にアプリをインストールしている全てのお客様は、アプリを再度インストールしなくても、この新しい機能を利用できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "既存のソフト フォン アプリケーションをSDKで連携させることはできますか？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "はい。既存のソフト フォン アプリケーションとの連携は非常に簡単です。上記のドキュメントの手順に従って、アプリケーションを起動して実行してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ユーザーは複数の連携を同時に使用できますか？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "はい。ユーザーは複数のサードパーティーのコール連携を同時に使用できます。コールボタンをクリックした後に表示される電話事業者を切り替える機能を使用して、電話事業者をシームレスに切り替えることができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "無料ユーザーはアプリ連携をインストールできますか？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "はい。全てのユーザーがアプリをインストールできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ユーザーが既にアプリをインストールしている場合、連携は自動的に表示されますか？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "はい。ユーザーが既にアプリをインストールしており、電話拡張機能で同じアプリを更新している場合、連携が自動的に表示されます。現在、開発者がコールアプリを一部の顧客のみに有効にする方法はありません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "どのユーザーでもアプリをインストールまたはアンインストールできますか？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["いいえ。必要な権限を持つユーザーのみ、アプリをインストールおよびアンインストールできます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/edit-user-permissions",
        children: "ユーザーの権限を確認する"
      }), "方法について詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "カスタムのコールプロパティーを作成することはできますか？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["はい。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "プロパティーAPI"
      }), "を使用してカスタムのコールプロパティーを作成できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "カスタムオブジェクトから電話をかけることはできますか？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["はい。コール連携がSDKのみを使用してコールを行っている限り、カスタムオブジェクトから電話を発信できます。各連携では、通話拡張SDKのみを使用してコールが行われていること、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "outgoingCall"
      }), "イベントでHubSpotに通知されることを確認する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "まず、outgoingCallイベントでエンゲージメントを作成するのに、連携が電話拡張SDKを使用していることを確認します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "outgoingCall({ createEngagement: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "createEngagement"
      }), "の場合、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#get-your-app-ready-for-production",
        children: "こちら"
      }), "からアプリ情報を更新する方法をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "outgoingCall"
      }), "イベント全体の例を以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const callInfo = {\n  phoneNumber: string, // optional unless call is initiated by the widget\n  createEngagement: true // whether HubSpot should create an engagement for this call\n  callStartTime: number // optional unless call is initiated by the widget\n};\nextensions.outgoingCall(callInfo);\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}