"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65495023168;
const slug = exports.slug = 'reference/api/deprecated';
const title = exports.title = 'HubSpot-APIs | Veraltete APIs';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Deprecated APIs';
const metaDescription = exports.metaDescription = 'Eine Liste veralteter HubSpot-APIs';
const toc = exports.toc = [{
  "depth": 0,
  "id": "eingestellte-apis",
  "label": "Eingestellte APIs",
  "parent": null
}, {
  "depth": 0,
  "id": "veraltete-apis",
  "label": "Veraltete APIs",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      br: "br",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Eingestellte und veraltete APIs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die in den folgenden Abschnitten aufgeführten APIs erhalten ", (0, _jsxRuntime.jsx)("u", {
        children: "nichtlänger"
      }), " zukünftige Updates der Funktionalität und werden in einigen Fällen vollständig eingestellt und für die Verwendung in Ihren Integrationen nicht mehr zur Verfügung gestellt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es wird dringend empfohlen, das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "HubSpot-Entwickler-Änderungsprotokoll"
      }), " zu abonnieren, um in Bezug auf die neuesten Updates, Breaking Changes und andere wichtige Änderungen an der Funktionalität auf dem neuesten Stand zu bleiben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eingestellte APIs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden APIs wurden vollständig entfernt und geben einen Fehler zurück, wenn ein Aufruf an ihre zugehörigen Endpunkte erfolgt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Seit dem 20. November 2022 sind API-Schlüssel als Authentifizierungsmethode eingestellt. Erfahren Sie in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "dieser Ankündigung in unserem Änderungsprotokoll"
        }), " mehr über dieses Update und in folgendem Artikel mehr dazu, wie Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "API-Schlüssel-Integration migrieren, um stattdessen eine private App zu verwenden"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Seit dem 28. Februar 2023 sind die folgenden APIs nicht mehr verfügbar:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated",
              children: "E-Commerce-Bridge-APIs"
            }), ": Wenn Sie zuvor eine Integration mit diesen APIs erstellt haben, können Sie mithilfe ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration",
              children: "dieses Migrationsleitfadens"
            }), " Ihre Integration auf die Verwendung privater Apps umstellen."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated",
              children: "API-Erweiterung für Buchhaltungsprogramme"
            }), ": Wenn Sie zuvor eine Integration mit diesen APIs erstellt haben, können Sie mithilfe ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-account-extension-integration",
              children: "dieses Migrationsleitfadens"
            }), " Ihre Integration auf die Verwendung privater Apps umstellen.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Seit dem 31. August 2023 sind die Kalender-APIs nicht mehr verfügbar. Dies beinhaltet die folgenden Endpunkte:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Content-Events auflisten"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Social-Media-Events auflisten"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Kalenderaufgaben-Events auflisten"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Alle Kalender-Events auflisten"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Kalenderaufgabe erstellen"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Kalenderaufgabe nach ID abrufen"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Kalenderaufgabe aktualisieren"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Eine Kalenderaufgabe löschen"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Veraltete APIs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die unten aufgeführten älteren Endpunkte erhalten ", (0, _jsxRuntime.jsx)("u", {
        children: "kein"
      }), " Versions-Update. Diese Endpunkte sind funktional und stabil, aber Sie werden nicht über ihre aktuelle Version hinaus aktualisiert. HubSpot unterstützt diese weiterhin auf absehbare Zeit und kündigt zukünftige Änderungen mit genügend Vorlaufzeit in seinem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Entwickler-Änderungsprotokoll"
      }), " an."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Social-Media-API:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-publishing-channels",
              children: "Veröffentlichungskanäle abrufen"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-broadcast-messages",
              children: "Broadcast-Nachrichten abrufen"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-a-broadcast-message",
              children: "Eine Broadcast-Nachricht abrufen"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message",
              children: "Eine Broadcast-Nachricht erstellen"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message",
              children: "Eine Broadcast-Nachricht abbrechen"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}