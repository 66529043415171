"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77661586541;
const slug = exports.slug = 'guides/api/settings/account-activity-api';
const title = exports.title = 'Account Activity API';
const name = exports.name = 'vNext Docs DP - Account Activity';
const metaDescription = exports.metaDescription = 'Learn more about the account activity API endpoints. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "get-a-centralized-audit-log-of-user-actions",
  "label": "Get a centralized audit log of user actions",
  "parent": null
}, {
  "depth": 0,
  "id": "get-login-activity-for-your-hubspot-account",
  "label": "Get login activity for your HubSpot account",
  "parent": null
}, {
  "depth": 0,
  "id": "get-security-activity-for-your-hubspot-account",
  "label": "Get security activity for your HubSpot account",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      em: "em",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Account activity"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following endpoints provide information about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/export-account-activity-history#account-login-history",
        children: "login"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/export-account-activity-history#security-activity-history",
        children: "security activity"
      }), " in your HubSpot account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get a centralized audit log of user actions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use this endpoint to export of a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/view-and-export-account-activity-history-in-a-centralized-audit-log",
        children: "centralized audit log"
      }), " of user actions across your account (", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        })
      }), " subscriptions only)", (0, _jsxRuntime.jsx)(_components.em, {
        children: "."
      }), " In order to utilize this endpoint, you must first follow the documentation on ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "making API calls with your app's access token"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For each centralized audit log, the response body will show:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "user action category (e.g. CRM object, login)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "user action subcategory (e.g. playbook, login succeeded)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "user action (e.g. create, update, perform)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "the ID of the affected object"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "the time and date the action occurred"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "the user's email address"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get login activity for your HubSpot account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use this endpoint to retrieve the previous 90 days of user login history. This includes login attempts to app.hubspot.com and the HubSpot mobile app. Login history exports contain the following information about each login attempt:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "login timestamp"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "user email address"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "IP address"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "location"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "type of login"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "user agent (information about the device used for logging in)"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get security activity for your HubSpot account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use this endpoint to export security activity history to see a list of security-related actions that users have taken in the account. For each user action, the response body will show:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "the time of the action"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "the type of action"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "the user's email address"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "the ID of the affected object"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "the approximate location"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "a link to the URL where the action was taken in the account"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}