"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 37833970855;
const slug = exports.slug = 'guides/api/cms/media-bridge';
const title = exports.title = 'API do CMS | Media Bridge';
const name = exports.name = 'vNext DP de documentos - Media Bridge';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "usar-a-api-de-media-bridge",
  "label": "Usar a API de media bridge",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-e-personalizar-as-defini%C3%A7%C3%B5es-dos-objetos-de-m%C3%ADdia",
  "label": "Criar e personalizar as definições dos objetos de mídia",
  "parent": null
}, {
  "depth": 0,
  "id": "conectar-o-aplicativo-de-media-bridge-a-uma-conta-de-usu%C3%A1rio-da-hubspot",
  "label": "Conectar o aplicativo de media bridge a uma conta de usuário da HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-seus-objetos-de-m%C3%ADdia",
  "label": "Criar seus objetos de mídia",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-m%C3%B3dulos-do-cms-para-incorporar-m%C3%ADdia",
  "label": "Criar módulos do CMS para incorporar mídia",
  "parent": null
}, {
  "depth": 1,
  "id": "configurar-um-dom%C3%ADnio-oembed",
  "label": "Configurar um domínio oEmbed",
  "parent": "criar-m%C3%B3dulos-do-cms-para-incorporar-m%C3%ADdia"
}, {
  "depth": 0,
  "id": "criar-um-m%C3%B3dulo-personalizado",
  "label": "Criar um módulo personalizado",
  "parent": null
}, {
  "depth": 0,
  "id": "enviar-eventos-de-m%C3%ADdia",
  "label": "Enviar eventos de mídia",
  "parent": null
}, {
  "depth": 1,
  "id": "conectar-um-evento-a-um-registro-de-contato",
  "label": "Conectar um evento a um registro de contato",
  "parent": "enviar-eventos-de-m%C3%ADdia"
}, {
  "depth": 1,
  "id": "conex%C3%A3o-de-um-evento-a-uma-pe%C3%A7a-de-m%C3%ADdia",
  "label": "Conexão de um evento a uma peça de mídia",
  "parent": "enviar-eventos-de-m%C3%ADdia"
}, {
  "depth": 1,
  "id": "conectar-um-evento-a-uma-p%C3%A1gina",
  "label": "Conectar um evento a uma página",
  "parent": "enviar-eventos-de-m%C3%ADdia"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API de Media Bridge"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A API de media bridge permite que os integradores enviem objetos de mídia, como arquivos de vídeo e áudio, bem como dados de consumo de mídia para o HubSpot. Também cria os seguintes recursos na conta da HubSpot do usuário:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos para incorporar os objetos de mídia aos editores de arrastar e soltar do HubSpot para páginas e e-mails."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Eventos da linha do tempo do CRM que mostram quando os prospects ou clientes se envolveram com vídeos, áudio e outros tipos de mídia."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Listas segmentadas para experiências direcionadas e personalizadas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fluxos de trabalho para automatizar interações, com base em eventos de consumo de mídia."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Relatórios para avaliar o impacto dos ativos de mídia."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O media bridge usa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objetos personalizados"
      }), " e eventos unificados — o sistema de rastreamento de eventos da HubSpot. Assim, você pode usar a API de media bridge e a API de objetos personalizados para criar sua integração."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar a API de media bridge"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você precisa de uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "conta de desenvolvedor da HubSpot"
      }), " para registrar seu aplicativo de media bridge e configurar suas definições iniciais de objeto de mídia antes de conectar seu aplicativo a uma conta de usuário da HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar e personalizar as definições dos objetos de mídia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para definir um objeto de mídia, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/settings/object-definitions"
      }), ". Você usará o parâmetro ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaTypes"
      }), " para definir o objeto: ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "VIDEO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AUDIO"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "DOCUMENT"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "IMAGE"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "OTHER"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de definir seus objetos de mídia, crie e modifique as propriedades deles fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/schemas/{objectType}"
      }), " e uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/properties/{objectType}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todas as chamadas de API feitas incluirão seu ID de conta de desenvolvedor como o parâmetro de consulta ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "portalID"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Conectar o aplicativo de media bridge a uma conta de usuário da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para conectar seu aplicativo de media bridge a uma conta de usuário da HubSpot, você deve criar uma definição de aplicativo em sua conta de desenvolvedor da HubSpot. As definições do aplicativo incluem:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Detalhes como o logotipo e o texto a serem mostrados para o usuário do HubSpot quando sua integração tenta estabelecer uma conexão inicial com sua conta."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Analisa as necessidades de integração na conta da HubSpot do usuário."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para conectar seu aplicativo de media bridge a uma conta de usuário da HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crie uma ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "definição de aplicativo"
        }), " em sua conta de desenvolvedor para o aplicativo de media bridge."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Inclua os seguintes escopos ao definir o aplicativo:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use a autenticação ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "OAuth"
        }), " ao autenticar chamadas feitas pelo aplicativo. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/intro-to-auth",
          children: "métodos de autenticação"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para verificar se o aplicativo está instalado corretamente no portal de um cliente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Acesse ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/media-bridge-demo/{HubID}"
        }), ", substituindo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{HubID}"
        }), " pelo ID da conta."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicativo"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "aplicativo de media bridge"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "No aplicativo, você pode visualizar os tipos de mídia aceitos pelo aplicativo e criar exemplos de mídias."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de instalar o aplicativo de media bridge no portal de um cliente, você pode:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-your-media-objects",
          children: "Criar objetos de mídia"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-cms-modules-to-embed-media",
          children: "Crie módulos CMS para incorporar seus objetos de mídia"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#send-your-media-events",
          children: "Enviar eventos de mídia"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar seus objetos de mídia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de criar as definições de objeto de mídia e instalar o aplicativo de media bridge na conta de um usuário, você pode usar o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "token de OAuth"
      }), " para criar e modificar objetos de mídia na conta. Como os objetos de mídia são objetos personalizados, use os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects#tab-2",
        children: "pontos de extremidade da API de objetos personalizados"
      }), " para criá-los:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/media-bridge/v1/{appId}/settings/object-definitions/{mediaType}"
        }), " para encontrar ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectType"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/{objectType}"
        }), " para criar o objeto de mídia na conta do usuário."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um objeto de mídia representa um conteúdo incorporável em um sistema de terceiros. Assim que um objeto de mídia for adicionado ao media bridge, ele poderá ser incorporado ao conteúdo do HubSpot CMS e associado aos eventos de mídia."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para objetos de mídia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "VIDEO"
      }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AUDIO"
      }), ", as tabelas abaixo listam todas as propriedades padrão e obrigatórias (* indica obrigatoriedade):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um ID usado para identificar a peça específica de mídia no sistema de media bridge da HubSpot. Isso é gerado automaticamente pela HubSpot e não pode ser definido pelos desenvolvedores."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_duration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A duração da mídia em milissegundos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_oembed_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um URL que deve retornar uma resposta oEmbed válida que segue a especificação ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://oembed.com/#section2",
              children: "oEmbed."
            }), " Requer tipo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rich"
            }), " com um iframe em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_file_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A URL do arquivo de mídia bruto. Futuramente, isso pode ser usado para ajudar a oferecer suporte à incorporação social."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_thumbnail_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URL de uma imagem usada como miniatura para incorporar a mídia ao conteúdo. O tamanho ideal para essa miniatura é de 640x480 pixels."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_poster_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL de uma imagem que representa a mídia. Essa imagem deve ter as mesmas dimensões da mídia original e pode ser usada em locais em que um espaço reservado para a imagem é necessário (por exemplo, quando a mídia é inserida em um e-mail)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da mídia no sistema de terceiro. Isso permite que os integradores busquem a mídia do media bridge com base no mesmo ID que eles usam em seu próprio sistema. (Este é o ponto de extremidade de API que aproveita esse mapeamento)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_folder_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um caminho fornecido pelo provedor para o objeto, destinado a representar a localização do objeto no sistema de pastas do terceiro (se houver). A HubSpot tentará representar essa estrutura de diretório ao exibir esses objetos para o usuário, mas pode aninhar os objetos e pastas de cada provedor em uma pasta de nível superior com o nome do provedor."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome da mídia. Isso será mostrado na interface do usuário da HubSpot em locais como o seletor de mídia."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_details_page_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um URL que permite que um usuário visualize ou interaja com a mídia no sistema do provedor de mídia. Isso é usado na interface de usuário da HubSpot para que os usuários possam identificar a mídia sem depender apenas do título."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para objetos de mídia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "IMAGE"
      }), ", as tabelas abaixo listam todas as propriedades padrão e obrigatórias (* indica obrigatoriedade):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um ID usado para identificar a peça específica de mídia no sistema de media bridge da HubSpot. Isso é gerado automaticamente pela HubSpot e não pode ser definido pelos desenvolvedores."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_oembed_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um URL que deve retornar uma resposta oEmbed válida que segue a especificação ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://oembed.com/#section2",
              children: "oEmbed."
            }), " Requer tipo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rich"
            }), " com um iframe em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_file_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A URL do arquivo de mídia bruto. Futuramente, isso pode ser usado para ajudar a oferecer suporte à incorporação social."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_thumbnail_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL para uma imagem que será usada como a miniatura para incorporar a mídia ao conteúdo em lugares como o seletor de mídia. O tamanho ideal para essa miniatura é de 640x480 pixels."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_poster_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL de uma imagem que representa a mídia. Essa imagem deve ter as mesmas dimensões da mídia original e pode ser usada em locais em que um espaço reservado para a imagem é necessário (por exemplo, quando a mídia é inserida em um e-mail)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da mídia no sistema de terceiro. Isso permite que os integradores busquem a mídia do media bridge com base no mesmo ID que eles usam em seu próprio sistema. (Este é o ponto de extremidade de API que aproveita esse mapeamento)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_folder_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um caminho fornecido pelo provedor para o objeto, destinado a representar a localização do objeto no sistema de pastas do terceiro (se houver). A HubSpot tentará representar essa estrutura de diretório ao exibir esses objetos para o usuário, mas pode aninhar os objetos e pastas de cada provedor em uma pasta de nível superior com o nome do provedor."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome da mídia. Isso será mostrado na interface do usuário da HubSpot em locais como o seletor de mídia."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_details_page_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um URL que permite que um usuário visualize ou interaja com a mídia no sistema do provedor de mídia. Isso é usado na interface de usuário da HubSpot para que os usuários possam identificar a mídia sem depender apenas do título."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar módulos do CMS para incorporar mídia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada provedor de aplicativos de media bridge é responsável por criar seu próprio ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulo"
      }), " para renderizar mídias no HubSpot CMS."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando um aplicativo de media bridge é instalado em uma conta da HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#embed",
        children: "Incorporar campo"
      }), " no módulo tem um tipo de fonte adicional para ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Integração de mídia"
      }), ". Assim, o usuário pode selecionar a mídia do aplicativo instalado para ser incorporada na página do site."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois que o usuário seleciona uma peça de mídia para incorporar, o ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "oembed_url"
      }), " e o ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "oembed_response"
      }), " da mídia ficam disponíveis no HubL para renderizar facilmente os reprodutores. Além disso, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "id"
      }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "media_type"
      }), " da mídia selecionada são armazenados para permitir a consulta do objeto CRM subjacente por meio da função HubL ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "crm_objects"
      }), ". Isso pode ser usado para buscar uma ou todas as propriedades que fazem parte de um objeto de mídia."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um exemplo de uso da função crm_objects do HubL com um objeto de mídia, em que os ids são ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "459"
      }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "922"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set objects = crm_objects(\"a123_Videos\", [459,922]) %} {{ objects }}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para buscar uma imagem específica com o mesmo objeto: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set object = crm_object(\"a123_Images\", 459) %} {{ object }}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os aplicativos podem buscar o tipo de objeto (“a123_Videos” no exemplo) fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/{appId}/settings/object-definitions/{mediaType}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os desenvolvedores devem usar os pontos de extremidade da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/source-code",
        children: "API de código-fonte do CMS"
      }), " para enviar seu código de módulo personalizado para as contas dos clientes, assim que eles se conectarem via oAuth. Assim que o código do módulo for enviado para a conta do cliente, ele poderá usar automaticamente o módulo do desenvolvedor no seu conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configurar um domínio oEmbed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para usar a função ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#oembed",
        children: "oEmbed"
      }), " do HubL, o domínio usado para buscar a resposta oEmbed deve ser registrado fazendo uma solicitação para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/settings/oembed-domains"
      }), ". Os seguintes parâmetros devem ser incluídos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Esquema:"
          }), " o padrão de URL para as URLs de mídia. Este padrão de URL é usado para corresponder o URL passado para a função oEmbed do HubL à API oEmbed. Valores curingas podem ser usados com ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "*"
          }), " (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "www.domain.com/*"
          }), ")."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL:"
          }), " o URL da sua API oEmbed. A URL de mídia é passada para essa URL por meio de um parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "URL"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Descoberta (Booleano):"
          }), " determina se sua API oEmbed suporta ou não o recurso ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://oembed.com/#section4",
            children: "Descoberta"
          }), " de oEmbed."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por padrão, os domínios oEmbed registrados serão disponibilizados para todos os clientes que instalaram o aplicativo. Se você tiver domínios personalizados que são exclusivos para cada cliente, poderá especificar em qual conta um domínio oEmbed pode ser usado, passando um valor portalId na solicitação da API ao configurar o domínio oEmbed. Isso garantirá que apenas a conta da HubSpot especificada possa usar esse domínio oEmbed."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um módulo personalizado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar um módulo personalizado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Arquivos e modelos"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ferramentas de design"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior esquerdo, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Arquivo"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Novo arquivo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na caixa de diálogo, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "O que você gostaria de construir hoje?"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Módulo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Próximo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Marque a caixa de seleção ao lado de cada tipo de conteúdo no qual o módulo será usado: ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "páginas"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "posts de blog"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "listas de blogs"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "e-mails"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "orçamentos"
        }), ". Os módulos usados em modelos de e-mail não podem incluir CSS ou JavaScript."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecione se esse módulo será um **módulo local **ou um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "módulo global"
        }), ". Se você criar um", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/design-manager-user-guide-v2/how-to-use-global-content-across-multiple-templates",
          children: "módulo global"
        }), ", a edição do conteúdo desse módulo atualizará cada local onde o módulo é usado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Insira um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome de arquivo"
        }), " para seu módulo e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Campos"
        }), " à direita, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Adicionar campo"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Incorporar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh3.googleusercontent.com/_cARgVZZLUD-nsJUIaG8XMUdSm1GRLr4Gfw96HeZ6SyXFG0t6yiiPKt5ToSe7nq8arSnG569_wg5X2XsD0XEtmEGiX9sbQxH7deUIJKGshXamME6vXrLN2oVSAt-cmrkTN2nggDsdkChDnEOxKrw5mo",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Tipos de fonte compatíveis"
          }), ", selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Integração de mídia"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Incorporar conteúdo padrão"
          }), ", clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Selecionar do [aplicativo de media bridge]"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No painel direito, selecione a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "mídia"
          }), " que você deseja incorporar ao módulo."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Defina qualquer uma das opções do editor, condições de exibição e opções do repetidor do campo."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Em ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Nome da variável do HubL"
          }), ", clique em **Copiar **> ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copiar snippet"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cole o snippet na seção ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "module.html"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Para visualizar como o módulo será exibido na sua página, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Visualizar"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na seção à esquerda, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Selecionar do [aplicativo de media bridge]"
          }), " e selecione a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "mídia"
          }), " que você deseja visualizar."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Enviar eventos de mídia"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um evento de mídia é um evento que acontece em relação a um objeto de mídia, como um evento de reprodução. Uma vez enviado um evento de mídia para o aplicativo de media bridge, ele poderá ser usado nos relatórios e nos cartões de linha do tempo do CRM."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há três tipos de eventos de mídia:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Evento Reproduzido:"
          }), " representa quando um usuário começou a reproduzir uma peça de mídia."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Evento Quartil:"
          }), " representa quando um usuário atingiu marcos do quartil (0%, 25%, 50%, 75%, 100%) na peça de mídia que está visualizando."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Evento Atenção"
          }), ": representa quando um usuário consumiu totalmente uma peça de mídia ou quando o usuário concluiu sua sessão."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os eventos podem ser enviados fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/media-played"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/media-played-percent"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/attention-span respectively"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para que os eventos de mídia sejam exibidos na linha do tempo de contato do usuário no HubSpot, um evento ", (0, _jsxRuntime.jsx)("u", {
        children: "Reproduzido"
      }), " deve ser enviado ao aplicativo de media bridge para cada sessão. Os eventos de uma única sessão serão mostrados em um cartão na linha do tempo de atividades do contato."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando os eventos são enviados usando os pontos de extremidade de evento v2, eles são processados de forma assíncrona, ao contrário dos enviados por meio dos pontos de extremidade v1. Portanto, recomendamos o seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A versão v1 dos pontos de extremidade deve ser usada para qualquer teste, pois uma solicitação incorreta ocasionará em erro imediatamente."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A versão v2 dos pontos de extremidade deve ser usada na produção, pois sua natureza assíncrona ajudará a evitar atrasos no cliente enquanto o evento estiver sendo gravado na media bridge. Os eventos também são retidos e repetidos em caso de falha temporária no servidor de media bridge."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conectar um evento a um registro de contato"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para conectar um evento de mídia a um registro de contato, você deve fornecer um ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), " ou um ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactUtk"
      }), ". Se apenas um ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactUtk"
      }), " for fornecido, ele será convertido em um ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), ". Se ambos forem fornecidos na solicitação, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), " será usado como fonte da verdade. Este parâmetro permite que o aplicativo de media bridge crie uma associação entre o registro do contato e o evento."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de conectar um evento de mídia a um registro de contato, o evento poderá ser usado nos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/create-reports-with-the-custom-report-builder",
        children: "relatórios entre objetos"
      }), ". Assim, os clientes poderão vincular seus eventos de mídia a registros de contato, bem como a empresas e negócios associados."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conexão de um evento a uma peça de mídia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para associar um evento de mídia a uma peça de mídia, os parâmetros ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaID"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "externalID"
      }), " devem ser incluídos na solicitação. Se ambos forem fornecidos, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaID"
      }), " será usado como fonte da verdade."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conectar um evento a uma página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para associar um evento de mídia a uma página do HubSpot, os seguintes parâmetros devem ser incluídos na solicitação:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se a página estiver hospedada no HubSpot CMS, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageId"
        }), " deverá ser fornecido."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se a página ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " estiver hospedada no HubSpot CMS, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageName"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageUrl"
        }), " deverão ser incluídos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A tabela abaixo descreve as propriedades permitidas para os três eventos de mídia:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de evento"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos os eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da mídia a qual este evento está relacionado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ID da mídia no sistema de terceiro. Isso permite que os desenvolvedores se refiram à mídia no media bridge com base no mesmo id que eles usam em seu próprio sistema. Isso pode ser usado em vez do ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " em eventos. Se um ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalId"
            }), " e um ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " forem fornecidos, o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), " será usado e o externalId será ignorado.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sessionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos os eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um identificador exclusivo para representar uma sessão de visualização. Isso pode significar coisas diferentes para diferentes provedores, e a HubSpot está permitindo que os provedores decidam o que uma sessão significa para eles. Isso será usado para agrupar eventos que aconteceram na mesma sessão. Espera-se que isso seja gerado pelo sistema do terceiro."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos os eventos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ID do contato no sistema da HubSpot que consumiu a mídia. Isso pode ser obtido usando a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/objects/contacts/v1#get-a-contact-by-its-user-token",
              children: " API Obter contato por token de usuário (utk) da HubSpot"
            }), ". A API também pode fornecer um token de usuário e fará a conversão dele em um ID de contato automaticamente."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactUtk"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos os eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O token de usuário (utk) que identifica qual contato consumiu a mídia."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos os eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID de conteúdo da página em que um evento aconteceu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos os eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome ou o título da página em que um evento aconteceu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos os eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A URL da página na qual um evento aconteceu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredTimestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos os eventos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A marca de data/hora em que esse evento ocorreu, em milissegundos desde a época."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attentionSpanMapString / attentionSpanMap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Intervalo de atenção"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Estes são os dados brutos que fornecem os dados mais granulares sobre os intervalos da mídia e quantas vezes cada intervalo foi consumido pelo usuário. Exemplo: considere um vídeo de 10 segundos em que cada segundo é um intervalo. Se um visitante assistir aos primeiros 5 segundos do vídeo e, em seguida, reiniciar o vídeo e assistir novamente aos primeiros 2 segundos, o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "attentionSpanMapString"
            }), " resultante seria ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "“0=2;1=2;2=1;3=1;4=1;5=0;6=0;7=0;8=0;9=0;”"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalPercentPlayed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Intervalo de atenção"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A porcentagem da mídia que o usuário consumiu. Os provedores podem calcular isso de forma diferente dependendo de como consideram visualizações repetidas da mesma porção de mídia. Por esse motivo, a API não tentará validar o totalPercentWatched em relação às informações de intervalo de atenção para o evento. Se estiver faltando, a HubSpot calculará isso por meio do mapa de intervalo de atenção da seguinte forma: (número de intervalos com um valor de 1 ou mais)/(número total de intervalos)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalSecondsPlayed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Intervalo de atenção"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Os segundos que um usuário ficou consumindo a mídia. O media bridge calcula isso como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalPercentPlayed"
            }), "*", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaDuration"
            }), ". Se um provedor quiser que isso seja calculado de forma diferente, ele pode fornecer o valor pré-calculado quando criar o evento"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "playedPercent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evento de quartil"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um valor percentual de quartil (0, 25, 50, 75, 100) para a quantidade de mídia consumida até o momento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "iframeUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Evento reproduzido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URL que pode ser usado para exibir dados de um sistema externo usando um iFrame. Quando presente, o evento na linha do tempo do contato exibirá um link que, quando clicado, abrirá uma janela modal com o conteúdo do iFrame."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de mídia ao qual o evento pertence (por exemplo, VÍDEO ou ÁUDIO) Isso nos permite atribuir corretamente o evento aos objetos corretos quando um único provedor tem suporte a vários tipos de mídia.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}