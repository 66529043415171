"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 158156695720;
const slug = exports.slug = 'reference/ui-components/standard-components/dropdown';
const title = exports.title = 'Dropdown | UI components';
const name = exports.name = 'Dropdown | UI components';
const metaDescription = exports.metaDescription = 'Learn about the Dropdown component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variants",
  "label": "Variants",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      strong: "strong",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tag
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Dropdown | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Dropdown"
      }), " component renders a dropdown menu that can appear as a button or hyperlink. Use this component to enable users to select from multiple options in a compact list. This component includes sizing options."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extensions-dropdown-variants.gif",
        alt: "ui-extensions-dropdown-variants"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Dropdown } from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  const ddOptions = [\n    {\n      label: 'Clone',\n      onClick: () => console.log({ message: 'Clone group' }),\n    },\n    {\n      label: 'Delete',\n      onClick: () => console.log({ message: 'Delete group' }),\n    },\n  ];\n\n  return (\n    <Dropdown\n      options={ddOptions}\n      variant=\"primary\"\n      buttonSize=\"md\"\n      buttonText=\"More\"\n    />\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Prop"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The options included in the dropdown menu. For each option, include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "label"
                }), ": the text label for the option."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "onClick"
                }), ": the function that gets invoked when the option is selected."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "variant"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'primary'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'secondary'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'transparent'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of dropdown button to display. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'primary'"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'secondary'"
            }), " will display a blue and grey button, respectively, while ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'transparent'"
            }), " will display a blue hyperlink."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "buttonText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The button text."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "buttonSize"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'xs'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'extra-small'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'sm'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'small'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'md'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'medium'"
            }), " (default)"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The size of the button."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", the dropdown button cannot be focused or clicked. Set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " by default."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variant"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "buttonSize"
      }), " props, you can set the type of button along with its size."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "'primary'"
          }), " buttons with size set to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'xs'"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'sm'"
          }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'md'"
          }), " respectively:"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/dropdown-buttons-primary.png",
            alt: "dropdown-buttons-primary"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "'secondary'"
          }), " buttons with size set to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'xs'"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'sm'"
          }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'md'"
          }), " respectively:"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/dropdown-buttons-secondary.png",
            alt: "dropdown-buttons-secondary"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "'transparent'"
          }), " buttons with size set to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'sm'"
          }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'md'"
          }), " respectively:"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/dropdown-buttons-transparent.png",
            alt: "dropdown-buttons-transparent"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-action-link",
          children: "CrmActionLink"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-card-actions",
          children: "CrmCardActions"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button",
          children: "Button"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}