'use es6';

import { argumentType } from '../../common/constants.js';
import { toCamelCase } from '../../common/stringutils';
const reservedWords = new Array('from');
export function formatArgumentKey(key) {
  return toCamelCase(key);
}
export function formatArgumentValue({
  key,
  value,
  type
}) {
  switch (type) {
    case argumentType.OBJECT:
    case argumentType.REF:
      return typeof value === 'object' ? formatArgumentKey(key) : formatArgumentKey(value);
    case argumentType.STRING:
      if (typeof value === 'undefined') {
        return '';
      } else if (value.includes('\n')) {
        return `\`${value}\``;
      }
      return `"${value}"`;
    case argumentType.ARRAY:
      value = JSON.stringify([value].flat());
      for (const reservedWord of reservedWords) {
        value = value.replaceAll(reservedWord, `_${reservedWord}`);
      }
      return value;
    default:
      return value;
  }
}