"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937480;
const slug = exports.slug = 'guides/api/crm/owners';
const title = exports.title = 'API du CRM | Propriétaires';
const name = exports.name = 'vNext Docs DP - Propriétaires';
const metaDescription = exports.metaDescription = 'HubSpot utilise les propriétaires pour attribuer des objets de CRM à des personnes spécifiques. Ces points de terminaison sont utilisés pour obtenir une liste des propriétaires disponibles pour un compte. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-une-liste-de-propri%C3%A9taires",
  "label": "Récupérer une liste de propriétaires",
  "parent": null
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-des-informations-sur-un-propri%C3%A9taire-individuel",
  "label": "Récupérer des informations sur un propriétaire individuel",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Propriétaires"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les propriétaires HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-to-set-an-owner",
        children: "attribuent"
      }), " des utilisateurs spécifiques à des fiches d'informations, des activités ou des tâches marketing, et peuvent être utilisés dans des jetons de personnalisation pour votre contenu. Les propriétaires sont automatiquement créés et mis à jour dans HubSpot lorsque de nouveaux utilisateurs sont ajoutés ou que les propriétaires existants sont synchronisés depuis ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/salesforce/how-will-a-salesforce-owner-will-be-recognized-by-hubspot",
        children: "Salesforce"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les points de terminaison de l'API des propriétaires sont en lecture seule. Vous pouvez donc les utiliser pour récupérer les informations d'identification d'un propriétaire, y compris l'ID du propriétaire. Cet identifiant peut ensuite être utilisé pour attribuer la propriété à des fiches d'informations de CRM dans HubSpot, via une intégration ou des appels d'API de modification de propriété."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer une liste de propriétaires"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour récupérer les propriétaires actuels de votre compte, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/owners"
      }), ". La réponse renverra le nom de chaque utilisateur, son adresse e-mail, les valeurs de son identifiant, les dates de création/mise à jour et, le cas échéant, les informations de l'équipe. Deux valeurs d'ID sont renvoyées, qui sont utilisées à des fins différentes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " : l'ID du propriétaire. Cette valeur doit être utilisée lors de la récupération d'informations sur un propriétaire spécifique et lors de l'attribution d'un propriétaire à une fiche d'informations ou à une activité."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), " : l'ID de l'utilisateur. Cette valeur peut être utilisée pour spécifier des utilisateurs dans l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-provisioning",
          children: "API des paramètres"
        }), ", mais entraînera une erreur si elle est utilisée pour attribuer la propriété."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Votre réponse ressemblera à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response GET crm/v3/owners\n{\n  \"results\": [\n    {\n      \"id\": \"41629779\",\n      \"email\": \"email@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"HubSpot\",\n      \"lastName\": \"Test Owner\",\n      \"userId\": 9586504,\n      \"userIdIncludingInactive\": 9586504,\n      \"createdAt\": \"2019-12-25T13:01:35.228Z\",\n      \"updatedAt\": \"2023-08-22T13:40:26.790Z\",\n      \"archived\": false,\n      \"teams\": [\n        {\n          \"id\": \"368389\",\n          \"name\": \"Sales Team\",\n          \"primary\": true\n        }\n      ]\n    },\n    {\n      \"id\": \"60158084\",\n      \"email\": \"email@gmail.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"Test\",\n      \"lastName\": \"Email\",\n      \"userId\": 9274996,\n      \"userIdIncludingInactive\": 9274996,\n      \"createdAt\": \"2021-02-10T17:59:04.891Z\",\n      \"updatedAt\": \"2023-02-09T17:41:52.767Z\",\n      \"archived\": false,\n      \"teams\": [\n        {\n          \"id\": \"368389\",\n          \"name\": \"Sales Team\",\n          \"primary\": true\n        }\n      ]\n    },\n    {\n      \"id\": \"81538190\",\n      \"email\": \"salesmanager@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"Sales\",\n      \"lastName\": \"Manager Example\",\n      \"userId\": 3892666,\n      \"userIdIncludingInactive\": 3892666,\n      \"createdAt\": \"2021-05-27T16:55:57.242Z\",\n      \"updatedAt\": \"2022-08-02T18:34:35.039Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également récupérer des propriétaires archivés pour afficher les utilisateurs qui ont été désactivés. Pour ce faire, ajoutez le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "archived"
      }), " avec la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". Pour les utilisateurs archivés, il y a toujours une valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", mais la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userId"
      }), " sera ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "null"
      }), ". Au lieu de cela, l'ID utilisateur est stocké dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userIdIncludingInactive"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response GET crm/v3/owners/?archived=true\n{\n  \"results\": [\n    {\n      \"id\": \"42103462\",\n      \"email\": \"useremail@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"\",\n      \"lastName\": \"\",\n      \"userId\": null,\n      \"userIdIncludingInactive\": 9685555,\n      \"createdAt\": \"2020-01-09T20:28:50.080Z\",\n      \"updatedAt\": \"2020-01-09T20:28:50.080Z\",\n      \"archived\": true\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer des informations sur un propriétaire individuel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour récupérer un propriétaire spécifique, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/owners/{ownerId}"
      }), ". Vous devez utiliser la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " pour spécifier le propriétaire pour lequel vous souhaitez obtenir plus de détails."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "updatedAt"
        }), " de la réponse change en fonction des mises à jour apportées à l'objet Propriétaire lui-même. Elle ne sera pas mise à jour pour des modifications apportées à l'objet d'utilisateur. Par exemple, la modification des autorisations d'un utilisateur ne mettra ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), " à jour la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "updatedAt"
        }), "."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}