/**
 * Maps locale to its top-level domain (TLD).
 */
export const localeToTLDMapping = {
  'pt-br': 'com',
  'de-de': 'de',
  'en-us': 'com',
  'es-es': 'es',
  'fr-fr': 'fr',
  'ja-jp': 'jp'
};
/**
 * Maps a locale to its hrefLang output in the DOM. Occasionally some locales
 * should have a more generic language-only hrefLang, particularly when the
 * pages are suitably localized for visitors in other regions that use the
 * same language (e.g. `en-us` content should be appropriate for English users
 * in `en-gb`, or English users in Great Britain).
 */
export const hrefLangOverrides = {
  'en-us': 'en',
  'es-es': 'es'
};
const underscoredLocales = ['de_de', 'en_us', 'es_es', 'fr_fr', 'ja_jp', 'pt_br'];
export const localeToUnderscoredLocale = locale => {
  const underscoredLocale = locale.replace(/-/g, '_');
  if (!isUnderscoredLocale(underscoredLocale)) {
    throw new Error(`Invalid locale: ${underscoredLocale}`);
  }
  return underscoredLocale;
};
function isUnderscoredLocale(locale) {
  return underscoredLocales.includes(locale);
}
/**
 * Gets full subdomain from a locale.
 */
export const getSubdomainFromLocale = (locale, isQa = false) => {
  const [, regionCode] = locale ? locale.split('-') : [null, 'en'];
  const isPortuguese = regionCode === 'br';
  return `https://${isPortuguese ? 'br.' : ''}developers.${isQa ? 'wt' : ''}hubspot.${localeToTLDMapping[locale]}`;
};
export const getFolderPrefixFromLocale = locale => {
  const [, regionCode] = locale ? locale.split('-') : ['en', 'us'];
  if (regionCode === 'us') {
    return null;
  }
  return regionCode;
};