"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 115766035067;
const slug = exports.slug = 'guides/crm/ui-extensions/overview';
const title = exports.title = 'UI Extensions overview (BETA)';
const name = exports.name = 'UI Extensions overview (BETA)';
const metaDescription = exports.metaDescription = 'Learn about how to develop UI extensions in HubSpot using serverless functions and React.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "how-ui-extensions-work",
  "label": "How UI extensions work",
  "parent": null
}, {
  "depth": 0,
  "id": "structure-and-schema",
  "label": "Structure and schema",
  "parent": null
}, {
  "depth": 0,
  "id": "location-and-object-support",
  "label": "Location and object support",
  "parent": null
}, {
  "depth": 1,
  "id": "locations",
  "label": "Locations",
  "parent": "location-and-object-support"
}, {
  "depth": 1,
  "id": "crm-objects",
  "label": "CRM objects",
  "parent": "location-and-object-support"
}, {
  "depth": 0,
  "id": "working-with-data",
  "label": "Working with data",
  "parent": null
}, {
  "depth": 1,
  "id": "fetch-data-via-api-call",
  "label": "Fetch data via API call",
  "parent": "working-with-data"
}, {
  "depth": 1,
  "id": "fetch-hubspot-crm-data",
  "label": "Fetch HubSpot CRM data",
  "parent": "working-with-data"
}, {
  "depth": 0,
  "id": "best-practices",
  "label": "Best practices",
  "parent": null
}, {
  "depth": 1,
  "id": "using-serverless-functions-in-private-apps",
  "label": "Using serverless functions in private apps",
  "parent": "best-practices"
}, {
  "depth": 1,
  "id": "handling-failure-with-serverless-functions",
  "label": "Handling failure with serverless functions",
  "parent": "best-practices"
}, {
  "depth": 0,
  "id": "limitations",
  "label": "Limitations",
  "parent": null
}, {
  "depth": 1,
  "id": "file-path-character-limit",
  "label": "File path character limit",
  "parent": "limitations"
}, {
  "depth": 1,
  "id": "non-hubspot-component-libraries",
  "label": "Non-HubSpot component libraries",
  "parent": "limitations"
}, {
  "depth": 1,
  "id": "accessing-dom-elements",
  "label": "Accessing DOM elements",
  "parent": "limitations"
}, {
  "depth": 2,
  "id": "examples-of-unsupported-hooks",
  "label": "Examples of unsupported hooks",
  "parent": "limitations"
}, {
  "depth": 1,
  "id": "custom-styles-on-hubspot-components",
  "label": "Custom styles on HubSpot components",
  "parent": "limitations"
}, {
  "depth": 1,
  "id": "making-client-side-http-requests",
  "label": "Making client-side HTTP requests",
  "parent": "limitations"
}, {
  "depth": 1,
  "id": "using-cookies-to-store-session-information",
  "label": "Using cookies to store session information",
  "parent": "limitations"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      em: "em",
      strong: "strong",
      img: "img",
      h3: "h3",
      pre: "pre",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "UI Extensions overview (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot UI extensions enable you to customize HubSpot’s UI to suit your needs. UI extensions can be created for CRM records and the ticket panels within help desk, and they can interact with both HubSpot and external data using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/creating-private-apps",
        children: "private"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/creating-public-apps",
        children: "public app"
      }), " included in the project."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about how UI extensions work, including how they fetch data, along with best practices."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "How UI extensions work"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "UI extensions are built using React as a primary front-end framework. For UI extensions in private apps, the back-end is provided by HubSpot using serverless functions for operations such as fetching data. For UI extensions in public apps, you'll need to provide a custom back-end to handle OAuth authentication."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Extensions are built locally using the HubSpot CLI, which you'll use to run a HubSpot local development server for the front-end and deploy back-end changes to the HubSpot account. Extensions are powered by the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk",
        children: "UI extensions SDK,"
      }), " which offers a number of utilities, including UI components to create the visual elements of the extension. The UI extensions SDK is published as an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.npmjs.com/package/@hubspot/ui-extensions/v/next",
        children: "npm package"
      }), ", which you'll include in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "package.json"
      }), " file within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extensions"
      }), " directory."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The UI extensions SDK enables you to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Register an extension with HubSpot"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Run serverless functions (private apps only)"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Add actions to your extension, such as opening an iframe in a modal, adding success and failure alerts, and fetching CRM properties"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Build the extension's UI with components"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Fetch user and account data", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#fetch-user-and-account-data"
          })]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create#add-ui-extension-functionality",
        children: "adding functionality to your extension through the SDK"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["While a ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sales Hub"
          })
        }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Service Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " account is needed to create UI extensions, a paid seat is not required to view UI extensions or custom tabs on CRM records. Any user in the account will be able to view and use UI extensions once uploaded to the account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Structure and schema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Within the project structure, UI extensions are stored in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/src/app/extensions"
      }), " directory. The extension then uses ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/overview",
        children: "UI components"
      }), " to render a UI and display information retrieved by the app’s serverless function. UI components are provided by the UI Extension SDK and can be customized through their included fields. Because you’re using React, you can also create your own component building blocks by packaging these components and importing them as needed."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "At a high level, a React-based UI extension consists of:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "A back-end:"
        }), " for ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/creating-private-apps",
          children: "private apps"
        }), ", the back-end is provided for you through ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "serverless functions"
        }), ". The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "app.functions"
        }), " directory contains the serverless function JavaScript file and a JSON configuration. You can also add a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "package.json"
        }), " file to include any needed dependencies. The serverless function sends and fetches data that your React components can later use. For ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/creating-public-apps",
          children: "public apps"
        }), ", this directory is not needed, as you'll provide your own back-end."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "React front-end:"
        }), " an extensions directory containing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".jsx"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".tsx"
        }), " files to render components, along with the app card’s ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".json"
        }), " configuration and a required ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "package.json"
        }), " that includes any needed dependencies."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023_2024/public-apps-vs-private-apps.png?width=800&name=public-apps-vs-private-apps.png",
        alt: "public-apps-vs-private-apps"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["As shown in the screenshot above, the front-end and back-end directories both include their own ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "package.json"
        }), " file. You can use these files to include dependencies as needed."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Location and object support"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "UI extensions can be created for a variety of locations and CRM objects in HubSpot. An extension can only appear in one location, but can be appear in that location for multiple CRM objects."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Locations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "UI extensions can be built for the following locations:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "The middle column of CRM record pages"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-card-location-middle-column.png",
            alt: "uie-card-location-middle-column"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "The right sidebar of CRM record pages"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-card-location-right-sidebar.png",
            alt: "uie-card-location-right-sidebar"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "The CRM record preview panel that appears on the right side throughout HubSpot"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/uie-card-location-preview-panel.png",
            alt: "uie-card-location-preview-panel"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "The ticket sidebars within the help desk tool (both the preview sidebar and the help desk ticket record view)"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/help-desk-uie-example-ticket-page.png",
            alt: "help-desk-uie-example-ticket-page"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create#extension-location",
        children: "extension locations"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CRM objects"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "UI extensions can be built for the following CRM objects:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Contacts"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Companies"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Deals"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tickets"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Custom objects"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition, the following CRM objects are supported if you've enabled the corresponding ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/data-management/data-model-templates",
        children: "data model template (BETA)"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Appointments and services (Healthcare template)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Courses (Education template)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Listings (Real estate template)"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create#compatible-objects",
        children: "compatible objects"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Working with data"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can fetch data in multiple ways, depending on the data source:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "To fetch third-party data, you can make API requests authenticated by secrets."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To fetch HubSpot data, you can:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["use the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fetchCrmObjectProperties"
            }), " action to fetch data from the currently displaying CRM record."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "use HubSpot's API endpoints to fetch data outside of the currently displaying CRM record."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "use GraphQL to query CRM data directly."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To fetch data with a private app, you'll use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions",
        children: "serverless functions"
      }), ". To fetch data with a public app, you'll use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/fetching-data",
        children: "hubspot.fetch API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fetch data via API call"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For private apps:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["You can fetch data from external APIs using an API client, such as Axios or other third-party clients, in your serverless functions. To authenticate these requests, learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/private-apps/serverless-functions#including-secrets-in-a-function",
              children: "create and use secrets in serverless functions"
            }), ". To see an example of using a serverless function to fetch data from a third-party source, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/mapbox-api",
              children: "mapbox-api code sample extension"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["You can fetch data from HubSPot's APIs using serverless functions, but instead of authenticating with a secret, you'll use the private app's access token. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/private-apps/serverless-functions#authenticate-hubspot-api-calls",
              children: "authenticating HubSpot API calls with private app access tokens"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For public apps, you can fetch data using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/fetching-data",
          children: "hubspot.fetch API"
        }), ". You'll need to provide authentication through your self-hosted back-end."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fetch HubSpot CRM data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To fetch data from the HubSpot account, such as displaying property data from the record you’re viewing, you’ll pass the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fetchCrmObjectProperties"
      }), " method to the extension via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.extend()"
      }), " in your React files. This method automatically handles authentication, so you don't need to include a private app access token. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create#fetch-crm-property-data",
        children: "the fetchCrmObjectProperties method"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hubspot.extend(({ actions }) => (\n  <HelloWorld fetchProperties={actions.fetchCrmObjectProperties} />\n));\n\nconst HelloWorld = ({ runServerless, fetchProperties }) => {\n  const [firstName, setFirstName] = useState('');\n  const [lastName, setLastName] = useState('');\n\n  useEffect(() => {\n    fetchProperties(['firstname', 'lastname']).then((properties) => {\n      setFirstName(properties.firstname);\n      setLastName(properties.lastname);\n    });\n  }, [fetchProperties]);\n\n  return (\n    <Text>\n      Hello {firstName} {lastName}\n    </Text>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fetchCrmObjectProperties"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-data-components/overview",
        children: "CRM data components"
      }), " can fetch and visualize HubSpot data out of the box. You can also use GraphQL to query CRM data through the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/collector/graphql"
      }), " endpoint. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/graphql/query-hubspot-data-using-graphql",
        children: "querying CRM data using GraphQL"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Best practices"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Using serverless functions in private apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For security, the React front-end cannot fetch data directly with APIs. For private apps, you can fetch data on the back-end using serverless functions. Then, on the front-end you'll use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "runServerlessFunction"
      }), " to call the serverless function and send your JSON payload as parameters. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk#runserverlessfunction",
        children: "runServerlessFunction"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Because UI extensions are split between front-end and back-end, you can call multiple serverless functions from the same card. Or, you can reuse the same serverless function to run different operations and pass them as needed to the front-end."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your serverless function requires secrets, learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions#managing-secrets",
        children: "managing secrets for deployed serverless functions and local development"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Handling failure with serverless functions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There are two ways that ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "runServerlessFunction"
      }), " can fail:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A failure in executing the serverless function"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A failure in executing your code inside the serverless function"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can handle both scenarios by catching the error inside your serverless function with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "try ... catch"
      }), " blocks. On the React front-end side, you'll need to check for ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "status"
      }), " in the response, treating ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SUCCESS"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ERROR"
      }), " appropriately. To ensure a good user experience in HubSpot, you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/error-state",
        children: "ErrorState component"
      }), " to communicate error messages with next steps."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " functions have a response limit of 15 seconds. Functions that take longer to execute will fail."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limitations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To render UI extensions, HubSpot uses ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#sandbox",
        children: "sandboxed iframes"
      }), " for isolation, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Using_web_workers",
        children: "web workers"
      }), " for untrusted code execution, and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://shopify.engineering/remote-rendering-ui-extensibility",
        children: "Shopfiy's remote-ui library"
      }), " for UI abstraction. This enables you to build with familiar tools like React and JavaScript, and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "remote-ui"
      }), " translates that into specific components to the host via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "postmessage"
      }), ". This process is essentially serializing a React element tree and sending it through ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "postmessage"
      }), " for the host to evaluate. This results in benefits, such as:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Helping to protect both you and HubSpot with a sound and resilient solution for isolated code execution, while ensuring a consistent in-app user experience through the use of HubSpot's component library."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Providing you with a productive and delightful developer experience by leveraging mainstream frameworks like React."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "However, it also separates the UI extensions from typical web applications built with React. Below, learn more about some of differences you can expect when developing React-based UI extensions."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "File path character limit"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A project cannot contain file paths that exceed 500 characters. If your project includes a file whose path exceeds that limit, you'll receive the following error in the console when uploading your project:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"[ERROR] File paths must not exceed 500 characters. Shorten the following file path, and then try again. {Relevant erroring file path}.\""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Non-HubSpot component libraries"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When building UI extensions on HubSpot, you can only use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/overview",
        children: "components"
      }), " provided through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create#add-ui-extension-functionality",
        children: "UI extensions SDK"
      }), ". Each component has a set of parameters that you can use for customization, but these components cannot be customized beyond those parameters."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Accessing DOM elements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Because UI extensions are rendered through sandboxed iframes, they cannot directly access the DOM. This restriction means that common methods of DOM manipulation and event listening, such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "document.getElementById"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "document.addEventListener"
      }), ", are unavailable within the iframe's local script context. However, some components provide callback functions that you can use for certain events, like clicks, focus, and input."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Examples of unsupported hooks"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["React Router's ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "useNavigate()"
        }), " hook will not function as expected within a UI extension. This hook manipulates the browser's window object to change the URL, which is not allowed within a sandboxed iframe."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "useForm()"
        }), " hook from react-hook-form is not supported because it relies on creating event listeners directly on DOM elements for form validation and submission, which is not allowed within a sandboxed iframe."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Custom styles on HubSpot components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Because you can't access the DOM and components don't pass the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "style"
      }), " prop to the renderer, you can only style components with the provided component props."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Making client-side HTTP requests"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You cannot make client-side HTTP requests through UI extensions, meaning browser features like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fetch"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "XMLHttpRequest"
      }), " will not work, nor will libraries like Axios, which is built around those features. Instead, requests should be made through the serverless function, executed by HubSpot behind the scenes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Using cookies to store session information"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Requests made within the sandbox do not contain cookies, so rather than trying to store session information with cookies, you can look to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context"
      }), " object, which is passed to the extension component via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.extend"
      }), ". This object contains information related to the authenticated user and HubSpot account. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/create#fetch-account-and-user-information",
        children: "fetching account and user data"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}