"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742924;
const slug = exports.slug = 'guides/api/crm/extensions/video-conferencing';
const title = exports.title = 'API de CRM | Extensión de conferencia en video';
const name = exports.name = 'LATAM (ES) vNext Docs DP - VCE';
const metaDescription = exports.metaDescription = 'Estos puntos de terminación se utilizan para conectarse al flujo de creación de reuniones dentro de HubSpot y agregar información de conferencia en video.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "api-de-configuraci%C3%B3n",
  "label": "API de configuración",
  "parent": null
}, {
  "depth": 1,
  "id": "definici%C3%B3n-de-objeto-de-configuraci%C3%B3n",
  "label": "Definición de objeto de configuración",
  "parent": "api-de-configuraci%C3%B3n"
}, {
  "depth": 1,
  "id": "crear-o-actualizar-la-configuraci%C3%B3n-de-la-extensi%C3%B3n-de-conferencia-en-video",
  "label": "Crear o actualizar la configuración de la extensión de conferencia en video",
  "parent": "api-de-configuraci%C3%B3n"
}, {
  "depth": 1,
  "id": "obtener-configuraci%C3%B3n-de-la-extensi%C3%B3n-de-conferencia-en-video",
  "label": "Obtener configuración de la extensión de conferencia en video",
  "parent": "api-de-configuraci%C3%B3n"
}, {
  "depth": 1,
  "id": "webhook-de-crear-reuniones",
  "label": "Webhook de crear reuniones",
  "parent": "api-de-configuraci%C3%B3n"
}, {
  "depth": 1,
  "id": "webhook-de-actualizar-reuniones",
  "label": "Webhook de actualizar reuniones",
  "parent": "api-de-configuraci%C3%B3n"
}, {
  "depth": 1,
  "id": "webhook-de-eliminar-reuni%C3%B3n",
  "label": "Webhook de eliminar reunión",
  "parent": "api-de-configuraci%C3%B3n"
}, {
  "depth": 1,
  "id": "webhook-de-verificaci%C3%B3n-de-usuario",
  "label": "Webhook de verificación de usuario",
  "parent": "api-de-configuraci%C3%B3n"
}, {
  "depth": 1,
  "id": "firma-de-webhook",
  "label": "Firma de webhook",
  "parent": "api-de-configuraci%C3%B3n"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ol: "ol",
      li: "li",
      h2: "h2",
      a: "a",
      h3: "h3",
      code: "code",
      strong: "strong",
      pre: "pre",
      ul: "ul",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Extensión de conferencia en video"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La API de conferencia en video ofrece a los integradores la capacidad de adaptarse al flujo de creación de reuniones dentro de HubSpot y agregar información de conferencias de video. El uso de esta API implicará los siguientes pasos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Configurar tu aplicación con webhooks de Extensiones de conferencia en video. Ofrecerás URI a HubSpot que HubSpot utilizará para notificar en qué momento los clientes están creando o actualizando reuniones."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Manejar los webhooks de creación de reuniones y, opcionalmente, los webhooks de actualización de reuniones."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Manejar los webhooks de verificación de identidad de usuario, si es necesario."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API de configuración"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los desarrolladores usarán esta API de configuración para configurar una aplicación existente. Puedes usar el token de acceso de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "app privada"
      }), " para autenticar la solicitud."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definición de objeto de configuración"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El objeto de configuración tiene los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "createMeetingUri"
      }), ": la URI donde enviaremos solicitudes de conferencias en video nuevas. Esto debe usar el protocolo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "updateMeetingUri:"
      }), " (opcional) La URI donde enviaremos actualizaciones a las reuniones existentes. Por lo general, cuando el usuario cambia el tema o los horarios de una reunión. Esto debe usar el protocolo https."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "deleteMeetingUri:"
      }), " (opcional) La URI donde te notificaremos de reuniones que se han eliminado en HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "userVerifyUri:"
      }), " (opcional) La URI que usaremos para verificar que un usuario existe en tu sistema."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear o actualizar la configuración de la extensión de conferencia en video"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Solicitud de ejemplo:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT /crm/v3/extensions/videoconferencing/settings/{appId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update-meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Respuesta de ejemplo:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example 200 response\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update-meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Respuesta de ejemplo:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los valores opcionales deben excluir de la solicitud, proporcionar cadenas vacías u otros valores probablemente ocasionará comportamientos no deseados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obtener configuración de la extensión de conferencia en video"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Solicitud de ejemplo:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET /crm/v3/extensions/videoconferencing/settings/{appId}   "
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ejemplo de respuesta:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example 200 response\n{\n\"createMeetingUrl\": \"https://example.com/create-meeting\",\n\"updateMeetingUrl\": \"https://example.com/update-meeting\"\n\"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n\"userVerifyUrl\": \"https://example.com/user-verify\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhook de crear reuniones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando se crea una reunión, HubSpot enviará una solicitud a la ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createMeetingUri"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Solicitud de ejemplo:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request\n{\n\"portalId\": 123123,\n\"userId\": 123,\n\"userEmail\": \"test.user@example.com\",\n\"topic\": \"A Test Meeting\",\n\"source\": \"MEETINGS\"\n\"startTime\": 1534197600000,\n\"endTime\": 1534201200000\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los campos de esta solicitud son:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "portalId"
        }), ": el ID de la cuenta de HubSpot (llamada un portal)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), ": el ID único del usuario de HubSpot que posee la reunión."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userEmail"
        }), ": la dirección de correo electrónico del usuario de HubSpot que posee la reunión."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "topic"
        }), ": el tema/título de la reunión."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "source"
        }), ": MEETINGS o MANUAL, que indica la característica de reunión dentro de", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "la aplicación HubSpot donde se ha creado la reunión. MEETINGS corresponde a", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "la función «enlace de reunión» y MANUAL corresponde a una reunión creada en", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "el Comunicador de CRM."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "startTime"
        }), ": la hora de inicio de la reunión (en milisegundos de epoch)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "endTime"
        }), ": la hora de finalización de la reunión (en milisegundos de epoch)"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para manejar correctamente este webhook, el desarrollador debe generar una conferencia en video para esta reunión (o enlace a una línea de conferencias existente) y responder con información sobre esta conferencia."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ejemplo de respuesta:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example response\n{\n  \"conferenceId\": \"some-unique-id\",\n  \"conferenceUrl\": \"https://example.com/join\",\n  \"conferenceDetails\": \"Click here to join: https://example.com/join\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los campos previstos en esta respuesta son:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceId"
        }), ": un ID único asociado con la conferencia en este evento.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Este ID debe ser globalmente único dentro de tu sistema. Devolveremos este ID", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "en el webhook de actualización."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceUrl:"
        }), " el enlace para unirse a la conferencia creada. Esto puede estar", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "en el campo de eventos «location»."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceDetails"
        }), ": información de \"invitation\" en texto sin formato. Esto debe describir", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "cómo los asistentes del evento pueden acceder a la conferencia en video para este", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "evento. Las nuevas líneas se mantendrán en representaciones de este texto dentro de nuestro", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "sistema, pero no se admiten otros formatos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhook de actualizar reuniones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si especificaste un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "updateMeetingUri"
      }), ", HubSpot enviará esta URI a una solicitud cuando se hayan modificado detalles relevantes de una reunión. Esta notificación es necesaria si necesitas mantener el tema o los horarios más actualizados para una conferencia en video."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Solicitud de ejemplo:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"conferenceId\": \"some-unique-id\",\n  \"userId\": 123,\n  \"userEmail\": \"test.user@example.com\",\n  \"portalId\": 123123,\n  \"topic\": \"A Test Meeting (updated)\",\n  \"startTime\": 1534197600000,\n  \"endTime\": 1534201200000\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los campos previstos en esta respuesta son:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceId"
        }), ": el ID único de la conferencia proporcionado por tu integración en la respuesta al webhook de crear reunión."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), ": el ID único del usuario de HubSpot que posee la reunión. Esto siempre será el mismo userId que la solicitud de crear reunión."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userEmail"
        }), ": la dirección de correo electrónico del usuario de HubSpot que posee la reunión. Esto siempre será el mismo usuario que la solicitud de crear reunión."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "topic"
        }), ": el tema/título de la reunión."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "startTime"
        }), ": la hora de inicio de la reunión (en milisegundos de epoch)"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "endTime"
      }), ": la hora de finalización de la reunión (en milisegundos de epoch)"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No se requiere un cuerpo de respuesta al responder a estas solicitudes. Solo necesitamos", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "un código de respuesta 200 o 204 para informarnos que este webhook se recibió", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "correctamente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhook de eliminar reunión"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando se elimine una reunión en HubSpot, enviaremos una solicitud a la ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "deleteMeetingUri."
      }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Solicitud de ejemplo:"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"conferenceId\": \"some-unique-id\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta solicitud solo contiene la conferenceId de la reunión que fue eliminada.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "No se requiere un cuerpo de respuesta al responder a estas solicitudes. Solo necesitamos", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "un código de respuesta 200 o 204 para informarnos que este webhook se recibió", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "correctamente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhook de verificación de usuario"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los sistemas de HubSpot siempre se comunican contigo sobre sus usuarios en términos de su ID de usuario de HubSpot y su dirección de correo electrónico de cuenta de HubSpot. Existe la posibilidad de que un usuario en el sistema de HubSpot pueda existir en tu sistema con una dirección de correo electrónico o un identificador diferente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Antes de hacer una llamada a tu sistema para crear, actualizar o eliminar un enlace de conferencia web, primero verificaremos tu configuración para un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userVerifyUri"
      }), ", y si esa URI ha sido configurada, te haremos una llamada para recuperar el identificador de usuario nativo. Luego enviaremos ese identificador como la dirección de correo electrónico del usuario en la", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "llamada subsiguiente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay lugares en la aplicación de HubSpot donde podríamos hacer esta llamada para validar que el usuario existe en tu sistema antes de que mostremos componentes de UI con los que puede interactuar, como un tipo de verificación previa. Si no configuras esta URI, siempre asumiremos que la identidad del usuario está verificada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depende de ti si admites esta función. Si necesitas mantener un mapeo de usuarios dentro de tu sistema, tal vez desees mapear el ID de usuario de HubSpot o el correo electrónico a tu ID de usuario interno en cada llamada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "La solicitud se verá así:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"portalId\": 123123,\n  \"userEmail\": \"test.user@example.com\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes volver a usar un código 200 o cualquier código de error (404 sería adecuado). Si devuelves un 200, debes devolver una carga que contenga la nueva identificación que debemos usar en lugar de \"correo electrónico\":"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example response\n{\n  \"id\": \"any-string-id\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Firma de webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos los webhooks enviados por HubSpot están firmados por HMAC usando el \"secreto de la aplicación\" de tu aplicación. Consulta la sección «Seguridad» de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "esta página"
      }), ": (El resto de esta página Resumen de webhooks no se aplica a estos webhooks de la extensión de conferencia en video)"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}