'use es6';

export const EVENTS = {
  REQUEST_INTERACTION: 'request-interaction',
  PARAM_FORM_INTERACTION: 'param-form-interaction',
  TEST_CALL: 'test-call',
  HELP_LINK_INTERACTION: 'help-link-interaction'
};
export const ACTIONS = {
  // REQUEST INTERACTION
  CHANGE_LANGUAGE: 'change language',
  COPY_REQUEST: 'copy request',
  // PARAM FORM INTERACTION
  MODIFY_FIELD_VALUE: 'modify field value',
  // TEST CALL
  MAKE_TEST_CALL: 'make test call',
  // HELP LINK INTERACTION
  CLICK_DEVELOPER_API_KEY_HELP: 'clicked developer API key docs link',
  CLICK_OAUTH_HELP: 'clicked OAuth learn more link',
  CLICK_PRIVATE_APPS_HELP: 'clicked Private apps learn more link'
};