"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 52098077561;
const slug = exports.slug = 'guides/cms/content/themes/responsive-breakpoints';
const title = exports.title = 'テーマのレスポンシブブレークポイント';
const name = exports.name = 'APAC JAPAN (ja) | [Developers] Responsive Breakpoints for Themes_JA | 202109';
const metaDescription = exports.metaDescription = 'テーマの設定で、レスポンシブブレークポイントを作成する方法を説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%86%E3%83%BC%E3%83%9E%E3%81%A7%E3%81%AE%E3%83%96%E3%83%AC%E3%83%BC%E3%82%AF%E3%83%9D%E3%82%A4%E3%83%B3%E3%83%88%E3%81%AE%E5%AE%9A%E7%BE%A9",
  "label": "テーマでのブレークポイントの定義",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_area",
  "label": "dnd_area",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "モジュール",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "テーマのレスポンシブブレークポイント"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テーマでのブレークポイントの定義"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "responsive_breakpoints"
      }), "オブジェクトをthemes.jsonファイルに追加することで、テーマにブレークポイントを設定できます。このオブジェクトには、ブレークポイントに関する情報が格納されるキーと値のさまざまなペアがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// themes.json\n{\n  \"label\": \"My Theme\",\n  \"preview_path\": \"./path/to/preview.html\",\n  \"screenshot_path\": \"./images/template-previews/home.png\",\n  \"responsive_breakpoints\": [\n    {\n      \"name\": \"mobile\",\n      \"mediaQuery\": \"@media (max-width: 767px)\",\n      \"previewWidth\": {\n        \"value\": 520\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらのキーと値のペアの詳細については、以下の表を参照してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "キー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ブレークポイントの名前。現時点では", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"mobile\""
            }), "のみ利用できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaQuery"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["レンダラー／エディターによって、レスポンシブCSSの生成に使用されるメディアクエリー文字列。例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"@media (max-width: 767px)\""
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "previewWidth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Key/Value Pair"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["プレビューiframeを表示するサイズをエディターに示します。例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"value\":520}"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h2, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "タグ内でHubLを使用してレスポンシブスタイルを定義する"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "タグの内部で要素を使用する場合には、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/hubl/tags/dnd-areas",
        children: "既存のスタイル設定"
      }), "を引き続き使用できます。さらに、これらの属性のレスポンシブスタイルも定義できます。以下の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/hubl/tags/dnd-areas#drag-and-drop-section-code-dnd-section-code-",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        })
      }), "の例を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section padding={ 'top': 100, 'bottom': 100 } %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "「mobile」ブレークポイントのパディングを変更するだけの場合は、「default」ブレークポイント（この例ではデスクトップビューが考慮されます）と「mobile」ブレークポイントの両方に以下のように値を指定します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section padding={\n    'default': { 'top': 100, 'bottom': 100 },\n    'mobile': { 'top': 20, 'bottom': 20 }\n  }\n%}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この機能は現在、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), "、および", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), "タグで機能します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュール"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "現在、モジュールには、コンテンツエディター上でモバイルオプションを設定できます。テンプレートでのモジュールにレスポンシブの既定値を設定することは、まだサポートされていません。この機能は今後のご提供を予定しています。その際、本ページを更新し、ご紹介いたします。"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}