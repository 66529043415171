import { retrieveSchema, ADDITIONAL_PROPERTY_FLAG } from './schemaUtils';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import getComponentTypes from '../constants/ComponentTypes';
import SHOULD_USE_JSON from '../constants/ShouldUseJson';
import keys from 'hs-lodash/keys';

// https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/core/src/utils.js
/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function isObject(thing) {
  if (typeof File !== 'undefined' && thing instanceof File) {
    return false;
  }
  return typeof thing === 'object' && thing !== null && !Array.isArray(thing);
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function isFixedItems(schema) {
  return Array.isArray(schema.items) && schema.items.length > 0 && /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
  schema.items.every(item => isObject(item));
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function isCrmPropertiesField(schema, schemaType, idSchema, name) {
  return schemaType === 'object' && idSchema.$id.match(/^(?:post|patch)-\/crm/m) && (keys(schema.properties).length === 1 && 'properties' in schema.properties && !(ADDITIONAL_PROPERTY_FLAG in schema.properties.properties) || name === 'properties');
}
export const isEmptyArrayObjectField = ( /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
schema, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
schemaType, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
parentSchemaType) => {
  return parentSchemaType === 'array' && schemaType === 'object' && Object.keys(schema.properties).length === 0;
};

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function shouldUseJsonField(schema, schemaId) {
  return schema.$$ref && SHOULD_USE_JSON.some( /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
  ({
    idSchemaRegex,
    models
  }) => schemaId.match(idSchemaRegex) && models.some(model => schema.$$ref === `#/components/schemas/${model}`));
}

// In the case where we have to implicitly create a schema, it is useful to know what type to use
//  based on the data we are defining
/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export const guessType = function guessType(value) {
  if (Array.isArray(value)) {
    return 'array';
  } else if (typeof value === 'string') {
    return 'string';
  } else if (value == null) {
    return 'null';
  } else if (typeof value === 'boolean') {
    return 'boolean';
  } else if (!isNaN(value)) {
    return 'number';
  } else if (typeof value === 'object') {
    return 'object';
  }
  // Default to string if we can't figure it out
  return 'string';
};

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export const getSchemaTypeI18nKey = (schema, definitions) => {
  let schemaType = schema.type;
  if (schemaType && schema.format) {
    if (`${schemaType}.${schema.format}` in getComponentTypes()) {
      return `types.${schemaType}/${schema.format}`;
    }
    schemaType = schema.format;
  }
  if (schemaType !== 'array') {
    return `types.${schemaType}`;
  }
  let i18nKey = 'types.';
  if (schema.items.type) {
    i18nKey += `${schemaType}.${schema.items.type}`;
  } else if (schema.items.$ref) {
    const itemsSchema = retrieveSchema(schema.items, definitions);
    if (itemsSchema.type) {
      i18nKey += `${schemaType}.${itemsSchema.type}`;
    }
  }
  if (!isValidI18nKey(i18nKey)) {
    return `types.array.fallback`;
  } else {
    return i18nKey;
  }
};