"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92705767247;
const slug = exports.slug = 'guides/api/crm/engagements/communications';
const title = exports.title = 'Engagements | Communications';
const name = exports.name = 'Engagements | Communications';
const metaDescription = exports.metaDescription = 'Learn how to use the communication APIs, which allow you to log WhatsApp, LinkedIn, and SMS messages to the CRM record timeline.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-whatsapp%2C-linkedin%2C-or-sms-message",
  "label": "Create a WhatsApp, LinkedIn, or SMS message",
  "parent": null
}, {
  "depth": 1,
  "id": "properties",
  "label": "Properties",
  "parent": "create-a-whatsapp%2C-linkedin%2C-or-sms-message"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "create-a-whatsapp%2C-linkedin%2C-or-sms-message"
}, {
  "depth": 0,
  "id": "retrieve-messages",
  "label": "Retrieve messages",
  "parent": null
}, {
  "depth": 0,
  "id": "update-messages",
  "label": "Update messages",
  "parent": null
}, {
  "depth": 1,
  "id": "associate-an-existing-message-with-a-record",
  "label": "Associate an existing message with a record",
  "parent": "update-messages"
}, {
  "depth": 1,
  "id": "remove-an-association",
  "label": "Remove an association",
  "parent": "update-messages"
}, {
  "depth": 0,
  "id": "pin-a-message-on-a-record",
  "label": "Pin a message on a record",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-messages",
  "label": "Delete messages",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Communications"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can log external communications via WhatsApp, LinkedIn, or SMS messages on CRM records to add information about the message to the record timeline."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "log a message directly in your HubSpot account"
      }), " or using the API endpoints below. You can review all available endpoints and the associated parameters available in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/crm/engagements/communications",
        children: "reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": the Communications API does not apply to marketing SMS messages. Learn how to create and view ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/sms/create-and-send-sms-messages",
          children: "marketing SMS messages in HubSpot"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a WhatsApp, LinkedIn, or SMS message"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a message, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/communications"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the request body, add message details in a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), " object. You can also add an ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associations"
      }), " object to associate your new message with an existing record (e.g., contacts, companies)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the properties object, you can include the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_communication_channel_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The channel type of the message that you sent or received from the contact. Supported values are ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WHATS_APP"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LINKEDIN_MESSAGE"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SMS"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_communication_logged_from"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enum used to differentiate between conversations objects. This must be set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CRM"
            }), " in your request."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_communication_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The text body of the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID of the owner"
            }), " associated with the message. This field determines the user listed as the message creator on the record timeline."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This field marks the message's time of creation and determines where the message appears on the record timeline. You can use either a Unix timestamp in milliseconds or UTC format."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create and associate a postal mail engagement with existing records, include an associations object in your request. For example, if you want to log an SMS message and associate it with a contact and company, your request body might resemble the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to https://api.hubapi.com/crm/v3/objects/communications\n{\n  \"properties\": {\n    \"hs_communication_channel_type\": \"SMS\",\n    \"hs_communication_logged_from\": \"CRM\",\n    \"hs_communication_body\": \"Texted Linda to confirm that we're ready to move forward with the contract.\",\n    \"hs_timestamp\": \"2022-11-12T15:48:22Z\",\n    \"hubspot_owner_id\": 1234567\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 9001\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 87\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 1234\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 81\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the associations object, you should include the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The record you want to associate with the message, specified by its unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " value."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of the association between the message and the record. Include the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Default association type IDs are listed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
              children: "here"
            }), ", or you can retrieve the value for custom association types (i.e. labels) via the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#retrieve-association-types",
              children: "associations API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response to your request will include an ID that you can use to update or associate the message with a record:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response from POST request to https://api.hubapi.com/crm/v3/objects/communications\n{\n  \"id\": \"12021896773\",\n  \"properties\": {\n    \"hs_communication_channel_type\": \"SMS \",\n    \"hs_communication_logged_from\": \"CRM\",\n    \"hs_communication_body\": \"Texted John to confirm that we're ready to move forward with the contract.\",\n    \"hs_timestamp\": \"2022-11-12T15:48:22Z\",\n    \"hs_createdate\": \"2022-11-29T18:35:00.484Z\",\n    \"hs_lastmodifieddate\": \"2022-11-29T18:35:00.484Z\",\n    \"hs_object_id\": \"12021896773\"\n  },\n  \"createdAt\": \"2022-11-29T18:35:00.484Z\",\n  \"updatedAt\": \"2022-11-29T18:35:00.484Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve messages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can retrieve messages individually or in batches. To retrieve an individual messages, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/communication/{communicationId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To request a list of all of logged WhatsApp, LinkedIn, and SMS messages, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/communications"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For both endpoints, you can include the following query parameters in the request URL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the properties to be returned in the response. If the requested message doesn't have a value for a property, it will not appear in the response."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A comma separated list of object types to retrieve associated IDs for. Any specified associations that don't exist will not be returned in the response. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4",
              children: "associations API."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to retrieve messages with their text content and any associated contact IDs, your request URL might look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/communications?limit=10&properties=hs_communication_body&associations=contact&archived=false"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about retrieving a batch of messages by internal ID or unique property value on the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Endpoints"
        })
      }), " tab at the top of this article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update messages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can update messages individually or in batches. To update an individual message by its communication ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/communications/{communicationId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the request body, include the message properties that you want to update:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example PATCH request to https://api.hubapi.com/crm/v3/objects/communications/{communicationId}\n{\n  \"properties\": {\n    \"hs_communication_body\": \"Sent a follow-up message to Carla.\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will ignore values for read-only and non-existent properties. To clear a property value, pass an empty string for the property in the request body."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch updating messages on the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Endpoints"
        })
      }), " tab at the top of this article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associate an existing message with a record"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To associate a message with other CRM records, such as a contact, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/communications/{communicationId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". The request URL contains the following fields:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communicationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of your WhatsApp, LinkedIn, or SMS message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The type of object that you want to associate the message with (e.g., contact or company)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the record that you want to associate the message with."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A unique identifier to indicate the association type between the message and the other object. The ID can be represented numerically or in snake case (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_to_contact"
            }), "). You can retrieve the value through the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "associations API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request URL might look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/communications/12021896773/associations/contact/581751/communication_to_contact"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remove an association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove an association between a message and a record, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to the same URL as above:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/communications/{communicationId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pin a message on a record"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "pin a message"
      }), " on a record so it remains on the top of the record's timeline. The message must already be associated with the record prior to pinning, and you an only pin one activity per record. To pin a message, include the message's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " field when creating or updating a record via the object APIs. Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "companies,"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contacts"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "deals"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "custom objects"
      }), " APIs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete messages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can delete messages individually or in batches, which will add the message to the recycling bin in HubSpot. You can later ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "restore the message from the record timeline"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete an individual message by its ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/communications/{communicationId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch deleting messages on the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Endpoints"
        })
      }), " tab at the top of this article."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}