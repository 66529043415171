"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42720143715;
const slug = exports.slug = 'guides/cms/content/provide-a-good-editor-experience';
const title = exports.title = 'How to provide a good experience in the page editor';
const name = exports.name = 'How to provide a good experience in the page editor';
const metaDescription = exports.metaDescription = 'How to make sure your CSS/JS displays well in the HubSpot CMS editors.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "test-in-the-editor",
  "label": "Test in the editor",
  "parent": null
}, {
  "depth": 0,
  "id": "be-specific",
  "label": "Be specific",
  "parent": null
}, {
  "depth": 1,
  "id": "css-specificity",
  "label": "CSS specificity",
  "parent": "be-specific"
}, {
  "depth": 1,
  "id": "avoid-using-!important-tags",
  "label": "Avoid using !important tags",
  "parent": "be-specific"
}, {
  "depth": 0,
  "id": "include-editor-specific-code",
  "label": "Include editor-specific code",
  "parent": null
}, {
  "depth": 1,
  "id": "rendering-css-with-.hs-inline-edit",
  "label": "Rendering CSS with .hs-inline-edit",
  "parent": "include-editor-specific-code"
}, {
  "depth": 1,
  "id": "rendering-javascript-window.hsineditor",
  "label": "Rendering JavaScript window.hsInEditor",
  "parent": "include-editor-specific-code"
}, {
  "depth": 1,
  "id": "rendering-content-with-hubl",
  "label": "Rendering content with HubL",
  "parent": "include-editor-specific-code"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      img: "img",
      pre: "pre",
      code: "code",
      h3: "h3",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "How to provide a good experience in the page editor"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The page editor provides an inline editing experience to help content authors create and edit their content in a what you see is what you get (WYSIWYG) interface. To do this, HubSpot renders content within an iframe with a preview of the page that includes code from modules and templates as well as the HubSpot application CSS/JavaScript. Because of this extra iframe layer and HubSpot app code, sometimes CSS/JavaScript from a template or module renders unexpectedly in the editors."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about best practices to avoid issues when your code is rendered in the context of HubSpot's editors."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Test in the editor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "It’s important to test your assets in HubSpot’s content editors before delivering them. By testing in the editor, you can identify styling conflicts to create a more seamless experience for the content creator."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "I's recommended to test the following functionalities in the editor to ensure your asset works as expected:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Text formatting using the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Style"
          }), " dropdown menu as well as other toolbar options such as alignment and colors."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/rich-text-toolbar.png",
            alt: "rich-text-toolbar"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Insert options in the rich text toolbar, such as embed codes, images, links, and personalization tokens."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/toolbar-insert-options.png",
            alt: "toolbar-insert-options"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Inline rich text element configuration options that appear on click, such as when editing an inserted hyperlink or image."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/insert-link-edit-options.png",
            alt: "insert-link-edit-options"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Be specific"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Broadly speaking, you may run into issues in the content editor when your CSS or JavaScript is not specific enough. This can manifest differently depending on how the code is written. Consider the function below as an example."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "$('body').click(function (event) {\n  $('.blog-listing-wrapper .post-listing .post-item')\n    .siblings()\n    .find('.right')\n    .removeClass('social-active');\n\n  event.stopPropagation();\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This function will run when there’s a click on the body element. Because the code calls ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "event.stopPropagation()"
      }), ", the event will not bubble up to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "document"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window"
      }), ". If there are event listeners on those elements, the code in those listeners will not run. The issue with the above code is that the click handler will run on every click, which causes problems in the inline editor because it adds click handlers to the window element via React. Instead of listening for every click, it would be more appropriate to only add the click handler when needed — for example, after a user has clicked a button to open a side menu — and then removing the handler once the click has fired."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CSS specificity"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Problems can occur in CSS when using selectors that are generic like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), ". When possible, you should instead use selectors that are specific to a portion of the webpage, such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-form label"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Being more specific with CSS selectors allows you to pinpoint the elements you want to style without impacting other elements unintentionally. You can also take advantage of our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/tools/boilerplate-css",
        children: "boilerplate CSS file"
      }), " to have a better sense of selectors that should be used to avoid CSS bleed issues."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Avoid using !important tags"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "!important"
      }), " tags are used to make styling rules take precedence over others. While you can use an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "!important"
      }), " tag when styling is being overridden, it's not recommended to use this tag on bare element selectors like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "input[type=\"text\"]"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, you might consider applying an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "!important"
      }), " tag for styling the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<label>"
      }), " element, with the intent to ensure that all ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<label>"
      }), " elements in a form are white."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "label,\nlegend {\n  color: white !important;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While this rule theoretically works when content is rendered on your live website page, it will also render all ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<label>"
      }), " tags in the content editor as white, as shown below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/label-color-render.png",
        alt: "label-color-render"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Instead, you should use more specific selectors, as shown in the code below, to target only the form module labels."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".hs-form label,\n.hs-form .hs-form-field > label,\n.hs-form .hs-field-desc {\n  color: white;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Include editor-specific code"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When developing a theme, template, or module, you can use CSS classes, JavaScript variables, and HubL to change how content is rendered with content editors and preview screens. This enables you to provide extra context for users in the content editors and preview pages, while still controlling the output on the live page. In addition, you can use these methods to prevent problematic code from rendering within HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about the different methods of conditionally rendering content based on whether it's rendered in HubSpot or on the live page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Rendering CSS with .hs-inline-edit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The editor uses an iframe to load a preview of the content into HubSpot’s content editor, and the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<html>"
      }), " element within this iframe is assigned a class of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-inline-edit"
      }), ". Using this class, you can write CSS that conditionally renders based on the presence of that iframe."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the following CSS takes advantage of the pseudo-class ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ":not()"
      }), " so that the rule does not apply when loaded in the editor:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ":not(.hs-inline-edit) label {\n  color: white;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As another example, if you're seeing that the rich text toolbar in the editor is being hidden behind a page's header due to a z-index rule, you could update your CSS to apply a lower z-index value to the header. By using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-inline-edit class"
      }), ", the new rule will only apply in the editor, not the live page. For reference, the rich text toolbar's z-index is ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2147483647"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".hs-inline-edit .header {\n  z-index: 2147483600;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Rendering JavaScript window.hsInEditor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When content is loaded in the editor, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsInEditor"
      }), " will return ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". You can include this variable in your JavaScript to conditionally run code based on whether the content is within the context of the editor. This can be useful when JavaScript is negatively impacting the in-app editing experience."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, you might find that your JavaScript isn't working as expected because it's running before the page editor has loaded. If your site uses jQuery, you could use the document ready handler to run the code only once the page editor has loaded fully."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "jQuery(document).ready(function ($) {\n  if (window.hsInEditor) {\n    return;\n  }\n  // other stuff\n  $('.some_widget').fancyThing();\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Rendering content with HubL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot provides a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#in-app-editor-and-preview-variables",
        children: "set of HubL variables"
      }), " that will return ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " in various editor and preview contexts. This includes variables that can check for any editor or preview context, as well as specific editor and preview contexts."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, the if statement below would render its content only when the user is in the blog post editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if is_in_blog_post_editor %}\nHelpful contextual information for blog authors.\n{% endif %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}