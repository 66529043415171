"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694134;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/tutorial';
const title = exports.title = 'Créer une zone de glisser-déposer';
const name = exports.name = '[Nouveau] premiers pas avec les zones de glisser-déposer';
const metaDescription = exports.metaDescription = 'Utilisez des zones de glisser-déposer pour aider les créateurs de contenu à apporter facilement des modifications de mise en page, de style et de contenu.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-cr%C3%A9er-un-nouveau-mod%C3%A8le-html",
  "label": "1. Créer un nouveau modèle HTML",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-cr%C3%A9er-une-zone-de-glisser-d%C3%A9poser",
  "label": "2. Créer une zone de glisser-déposer",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-cr%C3%A9er-une-section-avec-un-module",
  "label": "3. Créer une section avec un module",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-inclure-plusieurs-modules",
  "label": "4. Inclure plusieurs modules",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-incorporer-des-colonnes-et-des-lignes",
  "label": "5. Incorporer des colonnes et des lignes",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-d%C3%A9finir-des-styles-g%C3%A9n%C3%A9riques-pour-les-composants-de-glisser-d%C3%A9poser",
  "label": "6. Définir des styles génériques pour les composants de glisser-déposer",
  "parent": null
}, {
  "depth": 0,
  "id": "tutoriels-connexes",
  "label": "Tutoriels connexes",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      em: "em",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Démarrer avec les zones de glisser-déposer"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Les zones de glisser-déposer"
      }), " permettent aux développeurs de créer des sections de page qui prennent en charge les modifications de mise en page, de style et de contenu directement dans les éditeurs de contenu. Cela permet aux développeurs de créer quelques modèles avec une structure globale, qui permettent aux créateurs de contenu de créer une multitude de pages avec des objectifs et des mises en page différents, sans jamais se perdre dans le code ou nécessiter de nouveaux modèles pour de petites modifications de la mise en page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/a%20new%20approach.gif",
        alt: "Animation de modules glissés sur une page, avec ajustement des colonnes et des lignes"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les développeurs peuvent spécifier des zones de dépôt vides pour les zones de glisser-déposer, où les créateurs de contenu créent leur propre contenu et leur propre mise en page. Ils peuvent également pré-remplir les zones de glisser-déposer avec divers modules, mises en page, styles et contenus pour servir de point de départ aux créateurs de contenu."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ce tutoriel vous montrera comment configurer une zone de glisser-déposer simple. Pour plus de ressources de développement sur les zones de glisser-déposer, consultez le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/home.html",
        children: "boilerplate"
      }), " et découvrez les bonnes pratiques de mise en œuvre ainsi que la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "documentation de référence sur les balises HubL pour les zones de glisser-déposer"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Un créateur de contenu peut échanger le modèle d'une page contre un autre modèle du même type, selon qu'il possède ou non des balises ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/cms/hubl/tags/dnd-areas",
            children: "dnd_area"
          })
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles de glisser-déposer créés avec l'éditeur visuel de mise en page peuvent être remplacés par d'autres modèles de glisser-déposer ou par des modèles codés avec ou sans les balises ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles codés avec des balises ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " ne peuvent être échangés que contre d'autres modèles codés avec des balises ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles codés sans balises ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " ne peuvent être échangés que contre d'autres modèles codés sans balises ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Créer un nouveau modèle HTML"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Créez un nouveau modèle HTML pour héberger le HubL et le HTML qui constitueront votre section de glisser-déposer."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Les zones de glisser-déposer sont basées sur une grille réactive à 12 colonnes. Les balises par glisser-déposer rendent le balisage avec des noms de classe désignant les colonnes et les lignes. Vous devez ajouter une feuille de style pour cibler ces noms de classe. Un exemple des styles de mise en page que vous pouvez mettre en œuvre se trouve dans : ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
          children: "CMS-Theme-Boilerplate"
        }), ". Votre feuille de style peut être ajoutée au modèle en utilisant ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-css",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ require_css() }}"
          })
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Créer une zone de glisser-déposer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " est un conteneur qui rend une partie de la page web modifiable en termes de structure, de conception et de contenu. Le corps d'une balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " fournit le contenu par défaut de la zone de glisser-déposer."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cette balise génère à elle seule une zone de dépôt dans laquelle les créateurs de contenu peuvent faire glisser des modules."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n\t<!-- generates an empty drag and drop area drop-section -->\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Créer une section avec un module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " est une ligne de niveau supérieur et ne peut être qu'un enfant direct d'un élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ". Les sections prennent en charge une variété de paramètres qui contrôlent les valeurs par défaut des contrôles stylistiques dont disposent les créateurs de contenu pour les sections au sein des créateurs de contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Définissons une image d'arrière-plan ainsi qu'un alignement vertical centré et une largeur maximale de 1 000 px pour le contenu enfant. Pour obtenir une liste complète des paramètres pris en charge par les balises HubL de type glisser-déposer, consultez la documentation de référence sur les balises HubL de type glisser-déposer."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour pré-remplir la section avec du contenu, nous pouvons utiliser la balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " pour inclure un module en mentionnant son chemin d'accès. Dans cet exemple, nous faisons référence à un module HubSpot par défaut, mais vous pouvez également inclure des modules que vous avez créés, en spécifiant leur chemin d'accès dans l'arborescence de fichier des outils de conception."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour spécifier une valeur par défaut pour notre élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ", nous pouvons utiliser la balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_attribute"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n    {% dnd_section\n        background_image = {\n            'backgroundPosition': 'MIDDLE_CENTER',\n            'backgroundSize': 'cover',\n            'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n          },\n          max_width=1000,\n          vertical_alignment='MIDDLE'\n    %}\n        {% dnd_module path='@hubspot/rich_text' %}\n            {% module_attribute \"html\"%}\n                This is your main headline.\n                Use this space to tell everyone about what you have to offer.\n            {% end_module_attribute %}\n        {% end_dnd_module %}\n    {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Maintenant, nous pouvons voir que notre zone de glisser-déposer contient un module que les créateurs de contenu peuvent modifier dans l'éditeur de contenu. Nous pouvons également voir comment la définition de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "max_width"
      }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " affecte notre contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_rich_text.png",
        alt: "Capture d'écran de l'éditeur de page avec la barre d'outils du module affichée"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Inclure plusieurs modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour inclure plusieurs modules, nous pouvons utiliser plusieurs balises ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ". En réglant les paramètres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), " qui sont basés sur une grille à 12 colonnes, nous pouvons placer un module d'image à côté de notre module de texte enrichi."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n    {% dnd_section\n        background_image={\n            'backgroundPosition': 'MIDDLE_CENTER',\n            'backgroundSize': 'cover',\n            'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n        },\n        max_width=1000,\n        vertical_alignment='MIDDLE'\n    %}\n        {% dnd_module path='@hubspot/rich_text', width=8, offset=0, label=\"Rich Text\" %}\n            {% module_attribute \"html\"%}\n            \t<h1>This is your main headline.</h1>\n            \t<p>Use this space to tell everyone about what you have to offer.</p>\n            {% end_module_attribute %}\n        {% end_dnd_module %}\n        {% dnd_module path='@hubspot/linked_image',\n          width=4,\n          offset=8,\n          img={\n            \"src\": \"https://www.dragndrop.com/placeholder-image.jpg\",\n            \"alt\": \"Stock placeholder image\"\n          }\n        %}\n        {% end_dnd_module %}\n    {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Désormais, nous disposons également d'un module d'image modifiable ainsi que d'une poignée de glissement permettant aux créateurs de contenu de modifier la largeur et le décalage des modules. Nous pouvons également voir comment la définition d'un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vertical_alignment"
      }), " sur le ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " permet de centrer verticalement notre contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_rich_image.png",
        alt: "capture d'écran de l'éditeur de page montrant un module d'image ajouté à une section"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Incorporer des colonnes et des lignes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour rendre notre zone de glisser-déposer plus complexe, nous pouvons incorporer des lignes et des colonnes à l'aide des balises ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), ". Les lignes et les colonnes agissent de la même manière que les sections dans l'éditeur de contenu, où les créateurs de contenu peuvent les faire glisser ainsi que les cloner, les supprimer et les styliser."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n  {% dnd_section\n    background_image={\n        'backgroundPosition': 'MIDDLE_CENTER',\n        'backgroundSize': 'cover',\n        'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n    },\n    max_width=1000,\n    vertical_alignment='MIDDLE'\n  %}\n    {% dnd_module path='@hubspot/linked_image',\n      width=6,\n      img={\n        \"src\": \"https://www.dragndrop.com/placeholder-image.jpg\",\n        \"alt\": \"Stock placeholder image\"\n      }\n    %}\n    {% end_dnd_module %}\n    {% dnd_column width=6, offset=6 %}\n      {% dnd_row\n        padding={\n            'bottom': 15\n        }\n      %}\n        {% dnd_module path='@hubspot/rich_text' %}\n          {% module_attribute \"html\"%}\n              <h1>This is your main headline.</h1>\n              <p>Use this space to tell everyone about what you have to offer.</p>\n          {% end_module_attribute %}\n        {% end_dnd_module %}\n      {% end_dnd_row %}\n      {% dnd_row %}\n        {% dnd_module path='@hubspot/form' %}\n        {% end_dnd_module %}\n      {% end_dnd_row %}\n    {% end_dnd_column %}\n  {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Désormais, nos créateurs de contenu pourront contrôler davantage le style et la mise en page de lignes et de colonnes spécifiques, en plus des modules et des sections."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_area_full.png",
        alt: "Capture d'écran de l'éditeur de page montrant une ligne avec deux colonnes, un module d'image à gauche, un module de texte enrichi et un module de formulaire à droite."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Définir des styles génériques pour les composants de glisser-déposer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les différents composants des zones de glisser-déposer, des sections, des colonnes, des lignes et des modules possèdent tous des classes qui peuvent être stylisées à l'aide de CSS. Les styles et options modifiables de ces composants peuvent être définis à l'aide de CSS plutôt que de HubL. Par exemple, le remplissage par défaut peut être défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_sections"
      }), " avec la CSS :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".dnd-section {\n  padding: 80px 20px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les sélecteurs CSS génériques pour les composants de la zone de glisser-déposer sont ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-section"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-column"
      }), ",", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-row"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-module"
      }), ". En dehors de ces classes préfixées dnd, les noms des classes de la grille dans le balisage sont basés sur des noms Bootstrap 2. Cela ne signifie pas que vous devez utiliser Bootstrap 2 avec des zones de glisser-déposer. Lorsque vous ajoutez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " à votre page, vous devez fournir les styles qui permettent à la grille de fonctionner. Un exemple des styles de mise en page que vous pouvez mettre en œuvre se trouve dans : ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/css/objects",
        children: "CMS-Theme-Boilerplate"
      }), ". Votre feuille de style peut être ajoutée au modèle en utilisant ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#require-css",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ require_css() }}"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour plus de ressources de développement sur les zones de glisser-déposer, consultez le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "boilerplate"
      }), " et découvrez les bonnes pratiques de mise en œuvre ainsi que la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "documentation de référence sur les balises HubL pour les zones de glisser-déposer"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriels connexes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Premiers pas avec les thèmes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Premiers pas avec les modules personnalisés"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites",
          children: "Ajouter des fonctionnalités de thème à un site existant"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}