"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79935313585;
const slug = exports.slug = 'guides/cms/marketplace/listing-templates';
const title = exports.title = 'Listar e atualizar modelos no Marketplace de modelos da HubSpot';
const name = exports.name = 'LATAM BR (PT) Marketplace Requirements Overview > Listing CMS Assets';
const metaDescription = exports.metaDescription = 'Após criar uma conta de fornecedor do Marketplace de modelos, você pode usá-la para criar listagens de modelos e enviá-los para aprovação. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-um-modelo-de-an%C3%BAncio",
  "label": "Criar um modelo de anúncio",
  "parent": null
}, {
  "depth": 0,
  "id": "atualizar-listagem-de-modelo",
  "label": "Atualizar listagem de modelo",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      img: "img",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Listar e atualizar modelos no Marketplace de modelos da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de criar uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "conta de fornecedor no Marketplace de modelos"
      }), ", você poderá usá-la para criar listagens de modelos e enviá-los para aprovação para serem publicados no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "Marketplace de modelos da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " você somente pode ter ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "um"
        }), " novo envio de cada tipo (módulo ou modelo) em análise por vez. Um envio é considerado \"novo\" se não estiver publicado ou aprovado no momento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de listar seu modelo no Marketplace de modelos, preencha seu perfil de fornecedor:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta de fornecedor do Marketplace de modelos, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace de modelos"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Perfil do fornecedor"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informações da empresa"
        }), ", preencha todos os campos. Esses campos aparecerão nas listagens dos seus modelos. Você não poderá editar o nome da sua empresa depois que ele for escolhido e precisará entrar em contato com o Suporte da HubSpot para alterá-lo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informações de contato"
        }), ", preencha todos os campos nas seções ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contato principal"
        }), " e ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Contato do desenvolvedor"
        }), ". Essas informações somente serão usadas se a HubSpot precisar entrar em contato com você sobre uma listagem e elas ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " aparecerão em nenhuma página de listagem."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informações de pagamento"
        }), ", adicione as formas de pagamento que você aceita. Você pode conectar o PayPal ou o Stripe como gateways de pagamento. Se suas informações de pagamento fizerem com que o gateway de pagamento deixe de funcionar, os modelos serão temporariamente removidos da lista para evitar um impacto negativo no cliente."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um modelo de anúncio"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar e enviar uma listagem de temas ou módulos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta de fornecedor do Marketplace de modelos, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace de modelos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar lista"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar listagem de tema"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar listagem de módulo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revise as informações na página ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informações do fornecedor"
        }), " e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Próximo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revise suas informações de pagamento na página ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informações de pagamento"
        }), " e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Avançar"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecione o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tema ou módulo"
        }), " que deseja listar e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Salvar e continuar"
        }), ". Você será encaminhado ao editor de listagens"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/theme-listing-marketplace.png",
        alt: "theme-listing-marketplace"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O editor de anúncios é dividido em cinco guias: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Informações da listagem"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Detalhes do tema/módulo"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Categoria"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Suporte"
      }), " e ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Avaliação"
      }), ". Todas as informações são obrigatórias, salvo indicação em contrário. Consulte todos os requisitos da página de listagem", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/marketplace-guidelines/general-requirements",
        children: "aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Revisar"
        }), ", valide o código do modelo e visualize sua listagem. Se tudo estiver correto, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enviar para aprovação."
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " não altere o nome da pasta do modelo depois de enviá-lo. A listagem de modelos é diretamente vinculada a esta pasta após o envio. Você não poderá reenviar ou atualizar o modelo após alterar o nome da pasta."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Atualizar listagem de modelo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de criar uma listagem de modelo, você poderá atualizá-la na sua conta de fornecedor do Marketplace de modelos. Se você fez alterações em um modelo, precisará revalidá-lo para que as alterações sejam enviadas. Se você não revalidar, somente o conteúdo da página de listagem no Marketplace será atualizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para atualizar uma listagem de modelo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na sua conta de fornecedor do Marketplace de modelos, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketplace de modelos"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listagens"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome"
          }), " da listagem que você deseja atualizar. Você será redirecionado para o editor de listagem."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Faça as alterações necessárias nas informações da listagem, nos detalhes do modelo, nas categorias e nas opções de suporte e clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Revisar"
          }), " para revisar suas alterações."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Revisar"
          }), ", verifique se o código do modelo foi alterado desde a última validação:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se você não fez alterações no modelo, o HubSpot exibirá uma mensagem de confirmação no bloco de ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Código-fonte do tema"
            }), " ou de ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Código-fonte do módulo"
            }), ", mostrando que sua validação está atualizada."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/asset-marketplace-validation-up-to-date.png",
            alt: "asset-marketplace-validation-up-to-date"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se você fez alterações no modelo, o HubSpot exibirá um alerta nos blocos de código-fonte. Para enviar as alterações para o Marketplace, primeiro você precisa clicar em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Executar validação"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/asset-marketplace-run-validation.png",
            alt: "asset-marketplace-run-validation"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "5."
          }), " No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enviar para revisão"
          }), "."]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}