"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400672;
const slug = exports.slug = 'guides/cms/content/templates/overview';
const title = exports.title = "Vue d'ensemble des modèles";
const name = exports.name = 'EMEA FRANCE (fr) - Templates';
const metaDescription = exports.metaDescription = "Les modèles sont des encadreurs de pages ou d'e-mails réutilisables qui placent généralement des modules et des partials dans une mise en page. Les créateurs de contenu sélectionnent un modèle à utiliser avant de créer une page ou un e-mail.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-un-mod%C3%A8le",
  "label": "Créer un modèle",
  "parent": null
}, {
  "depth": 0,
  "id": "aper%C3%A7u-d-un-mod%C3%A8le",
  "label": "Aperçu d'un modèle",
  "parent": null
}, {
  "depth": 1,
  "id": "aper%C3%A7u-avec-le-gestionnaire-de-conception",
  "label": "Aperçu avec le gestionnaire de conception",
  "parent": "aper%C3%A7u-d-un-mod%C3%A8le"
}, {
  "depth": 1,
  "id": "aper%C3%A7u-avec-une-nouvelle-page",
  "label": "Aperçu avec une nouvelle page",
  "parent": "aper%C3%A7u-d-un-mod%C3%A8le"
}, {
  "depth": 0,
  "id": "types-de-mod%C3%A8le",
  "label": "Types de modèle",
  "parent": null
}, {
  "depth": 1,
  "id": "page",
  "label": "Page",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 1,
  "id": "e-mails",
  "label": "E-mails",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 1,
  "id": "partial",
  "label": "Partial",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 1,
  "id": "partial-global",
  "label": "Partial global",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 1,
  "id": "blog",
  "label": "Blog",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 1,
  "id": "listing-du-blog",
  "label": "Listing du blog",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 1,
  "id": "article-de-blog",
  "label": "Article de blog",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 2,
  "id": "mod%C3%A8le-combin%C3%A9-d-article-de-blog-et-de-listing",
  "label": "Modèle combiné d'article de blog et de listing",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 1,
  "id": "pages-syst%C3%A8me",
  "label": "Pages système",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 2,
  "id": "pages-d-erreur",
  "label": "Pages d'erreur",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 2,
  "id": "page-de-gestion-des-inscriptions-par-e-mails",
  "label": "Page de gestion des inscriptions par e-mails",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 2,
  "id": "d%C3%A9sabonnement-alternatif-des-e-mails",
  "label": "Désabonnement alternatif des e-mails",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 2,
  "id": "confirmation-de-d%C3%A9sabonnement-par-e-mail",
  "label": "Confirmation de désabonnement par e-mail",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 2,
  "id": "invite-de-mot-de-passe",
  "label": "Invite de mot de passe",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 2,
  "id": "page-des-r%C3%A9sultats-de-recherche",
  "label": "Page des résultats de recherche",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 1,
  "id": "adh%C3%A9sion",
  "label": "Adhésion",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 2,
  "id": "connexion-des-membres",
  "label": "Connexion des membres",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 2,
  "id": "registre-des-membres",
  "label": "Registre des membres",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 2,
  "id": "r%C3%A9initialisation-du-mot-de-passe-de-membre",
  "label": "Réinitialisation du mot de passe de membre",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 2,
  "id": "demande-de-r%C3%A9initialisation-de-l-adh%C3%A9sion",
  "label": "Demande de réinitialisation de l'adhésion",
  "parent": "types-de-mod%C3%A8le"
}, {
  "depth": 0,
  "id": "fichiers-cms-inclus",
  "label": "Fichiers CMS inclus",
  "parent": null
}, {
  "depth": 1,
  "id": "jquery",
  "label": "jQuery",
  "parent": "fichiers-cms-inclus"
}, {
  "depth": 1,
  "id": "layout.css",
  "label": "layout.css",
  "parent": "fichiers-cms-inclus"
}, {
  "depth": 1,
  "id": "code-de-suivi-hubspot",
  "label": "Code de suivi HubSpot",
  "parent": "fichiers-cms-inclus"
}, {
  "depth": 0,
  "id": "ressources-associ%C3%A9es",
  "label": "Ressources associées",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      h3: "h3",
      img: "img",
      em: "em",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Vue d'ensemble des modèles"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles définissent la mise en page de vos pages, e-mails et thèmes HubSpot. Un modèle se compose de modules et de partials, et peut faire référence à d'autres ressources telles que des feuilles de style et des fichiers JavaScript. Les modèles peuvent être créés soit à l'aide de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "HubSpot CLI"
      }), ", soit dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/design-manager/create-page-email-and-blog-templates-in-the-layout-editor",
        children: "Gestionnaire de conception de HubSpot"
      }), ". Pour les créateurs de contenu, le modèle est la première chose qu'ils sélectionneront, à la création d'une page ou d'un e-mail."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous comment créer un modèle, les différents types de modèles et ce qui est inclus dans vos modèles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un modèle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez créer un modèle soit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/create-page-email-and-blog-templates-in-the-layout-editor",
        children: "dans HubSpot"
      }), ", soit en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "utilisant le CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pour créer un modèle à l'aide du CLI, exécutez la commande suivante :"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template <name> [dest]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom du modèle"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dest"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le chemin du répertoire local dans lequel vous voulez créer le modèle. S'il n'est pas inclus, le modèle sera créé dans le répertoire dans lequel vous vous trouvez actuellement."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["À l'aide des touches flèches, accédez au ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#template-types",
          children: "type de modèle"
        }), " que vous souhaitez créer, puis appuyez sur la touche ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Entrée"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le modèle sera alors créé localement. Lorsque vous souhaitez rendre le modèle disponible pour une utilisation dans votre compte HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "charger"
      }), " le modèle sur votre compte. Vous pouvez également utiliser la commande ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "consulter"
      }), " pour télécharger automatiquement tous les nouveaux fichiers et les modifications apportées aux fichiers existants dans le répertoire de travail actuel et les répertoires enfants."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Aperçu d'un modèle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Après avoir mis à jour un modèle, vous pouvez le prévisualiser pour vous assurer qu'il s'affiche et agit comme vous l'attendez. Il existe plusieurs façons dans HubSpot de prévisualiser un modèle :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Prévisualisation d'un modèle dans le gestionnaire de conception :"
        }), " idéal pour les vérifications visuelles rapides ou lorsque vous devez prévisualiser un article de blog/une liste/un modèle combiné."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Création d'une nouvelle ressource à partir d'un modèle :"
        }), " idéal pour tester l'éditeur en glisser-déposer et l'expérience de création de contenu."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aperçu avec le gestionnaire de conception"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Prévisualiser des modèles à l'aide du gestionnaire de conception peut être particulièrement utile pour des vérifications visuelles rapides. L'aperçu du modèle vous permet également de configurer des options d'affichage, telles que les dimensions de viewport."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour prévisualiser un modèle dans le gestionnaire de conception :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fichiers et modèles"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Outils de conception"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["À l'aide de l'explorateur de fichiers de la barre latérale de gauche, cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "modèle"
        }), " que vous souhaitez prévisualiser."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans l'angle supérieur droit, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aperçu"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Aperçu en ligne avec options d'affichage"
            }), " pour afficher un aperçu du modèle avec des options pour tester la réactivité et les paramètres de domaine tels que les feuilles de style. Cette option affiche la page dans un iFrame. Cela vous permet également de choisir entre les blogs et l'article de blog ou la vue de liste pour les modèles de blog."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Aperçu sans options d'affichage"
            }), " pour prévisualiser le modèle sans options supplémentaires."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/template-preview-options.png",
        alt: "template-preview-options"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aperçu avec une nouvelle page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour modifier les zones de glisser-déposer, les valeurs des paramètres par défaut des modules ou d'autres changements importants, il est préférable de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/website-pages/create-and-customize-pages",
        children: "créer une page de site web"
      }), ", un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/blog/create-a-new-blog",
        children: "blog"
      }), ", un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/blog/create-and-publish-blog-posts",
        children: "article de blog"
      }), " ou un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/email/create-marketing-emails-in-the-drag-and-drop-email-editor",
        children: "e-mail"
      }), " en utilisant le modèle. Vous pouvez ensuite essayer différentes valeurs de champ de module et tester ce à quoi ressemblera votre modèle une fois en ligne et l'optimiser pour offrir la meilleure expérience au créateur de contenu. Vous pouvez publier ces ressources ou les laisser en mode brouillon à des fins de test."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En outre, vous pouvez utiliser la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cms-general/redesign-and-relaunch-your-site-with-content-staging#new",
        children: "préparation de contenu"
      }), " ou un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "compte sandbox de développeur"
      }), " pour créer et afficher des ressources sans avoir d'impact sur un compte de production."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Types de modèle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les modèles peuvent être utilisés pour différents types de contenu, tels que des pages de site web et des articles de blog. Dans les modèles codés, vous désignez le type de modèle en ajoutant une annotation en haut du fichier."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous les différents types de modèles et les annotations que vous pouvez utiliser pour désigner chaque type."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Un créateur de contenu peut échanger le modèle d'une page contre un autre modèle du même type, selon qu'il possède ou non des balises ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles construits avec l'éditeur visuel de mise en page par glisser-déposer peuvent être remplacés par d'autres modèles par glisser-déposer ou par des modèles codés avec ou sans les balises", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          })]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles codés avec des balises ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " ne peuvent être échangés que contre d'autres modèles codés avec des balises ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Les modèles codés ", (0, _jsxRuntime.jsx)("u", {
            children: "sans"
          }), " balises ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " ne peuvent être échangés que contre d'autres modèles codés sans balises ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: page\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles de page correspondent au type de modèle le plus ouvert. Ils peuvent servir de page web ou de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/blog/tabid/6307/bid/7177/what-is-a-landing-page-and-why-should-you-care.aspx",
        children: "page de destination"
      }), ", quelle qu'en soit la forme. Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#layout-css",
        children: "mises en page"
      }), " ne sont pas pré-remplies avec des composants. Les modèles de page codés sont préremplis avec un balisage sommaire comprenant des balises HubL suggérées pour les méta-informations, le titre, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#included-cms-files",
        children: "notamment"
      }), " les en-têtes et pieds de page obligatoires. Les exemples de pages qui utilisent généralement un modèle de page incluent, mais ne sont pas limités à :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/home.html",
          children: "Page d'accueil"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/about.html",
          children: "À propos de nous"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/contact.html",
          children: "Contact"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "E-mails"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles d'e-mails sont utilisés par l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/marketing/email",
        children: "outil e-mail"
      }), ". Ils sont soumis aux exigences les plus strictes, car ils doivent être affichés dans de nombreux clients de messagerie différents et se conformer aux meilleures pratiques pour garantir une bonne délivrabilité. Les modèles d'e-mails ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML et HubL"
      }), " et ceux du gestionnaire de conception par glisser-déposer sont préremplis de composants de base lors de leur création. Les modèles avec ce ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType"
      }), " sont uniquement visibles pour la sélection de modèles lors de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/email/create-and-send-marketing-emails",
        children: "création d'un e-mail"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Afin de rester conformes à la norme ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/email/can-i-customize-my-can-spam-email-footer",
        children: "CAN-SPAM"
      }), ", les modèles d'e-mails comportent un ensemble de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-email-template-variables",
        children: "variables obligatoires"
      }), " qui doivent être incluses."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles d'e-mail disposent également d'une fonctionnalité intégrée permettant d'ajouter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/email-template-markup#hs-inline-css-and-data-hse-inline-css",
        children: "inline-css"
      }), " aux éléments ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), " avec un nom de classe ou un attribut de données spécial. L'insertion de CSS dans les e-mails est une méthode utilisée pour obtenir une meilleure prise en charge dans les clients de messagerie. Heureusement, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://litmus.com/blog/do-email-marketers-and-designers-still-need-to-inline-css",
        children: "la plupart des clients de messagerie connus prennent désormais en charge la css intégrée"
      }), ", qui n'est toutefois pas représentatif de vos destinataires spécifiques. Faites preuve de discernement et faites ce qui est juste pour vos destinataires."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/email-template-markup",
        children: "En savoir plus sur la création de modèles d'e-mails."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Partial"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: page isAvailableForNewContent: false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les partials sont des fichiers de modèle qui peuvent être inclus dans d'autres fichiers codés. Contrairement aux partials globaux, les partials peuvent être modifiés individuellement sans impact sur les autres instances du partial."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#partials",
        children: "partials"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Partial global"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: global_partial\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les partials globaux sont un type de modèle créé à l'aide de HTML et HubL qui peut être réutilisé sur l'ensemble de votre site web. Les types de partials les plus courants sont les en-têtes de sites Web, les barres latérales et les pieds de page. La mise à jour d'un partial global mettra à jour toutes les instances du partial global."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
        children: "partials globaux"
      }), " et le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "contenu global"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-blog/cos-blog-how-do-i-create-a-cos-blog",
        children: "création d'un blog"
      }), ", les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "modèles de blog"
      }), " ont une structure similaire à celle des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#page-templates",
        children: "modèles de page"
      }), " standards. La différence essentielle est qu'ils peuvent être sélectionnés dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "paramètres de contenu"
      }), " comme modèles de blog, alors que les modèles de page ne le peuvent pas. Les modèles créés avec ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_listing"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "n'apparaissent pas"
      }), " lorsqu'un utilisateur crée une page web, dans l'écran de sélection des modèles. Les modèles de blog ont en fait deux formes, les pages de listing de blog et les pages de détail des articles de blog."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Listing du blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog_listing\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "modèle de listing de blogs"
      }), " est le modèle que les utilisateurs verront lorsqu'ils accèderont à l'URL du blog. Ce modèle est généralement utilisé pour répertorier les résumés, les titres et les images en vignette de tous les articles du blog. Il affiche également une pagination pour accéder aux articles les plus anciens."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Article de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog_post\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le modèle d'article de blog est le modèle que les utilisateurs verront lorsqu'ils consulteront un article individuel dans le blog. Ces modèles affichent généralement le contenu complet de l'article."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Modèle combiné d'article de blog et de listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un seul modèle de blog peut gérer la mise en page des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog#if-is-listing-view-statement",
        children: "pages de listing et pages de détails"
      }), ", mais le plus souvent, ces dernières sont séparées dans des modèles distincts. Les modèles combinés apparaîtront dans les paramètres du blog comme pouvant être sélectionnés à la fois pour les options de listing et d'article de blog. Si vous créez un modèle destiné à être utilisé uniquement pour des articles ou des listings, vous devez utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_listing"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: blog\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour simplifier le développement et l'expérience de création de contenu, il est recommandé d'utiliser les ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateTypes"
        }), " distincts ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_post"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_listing"
        }), " plutôt que des modèles combinés."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pages système"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles de pages du système sont marqués en interne pour leur objectif spécifique. Dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "paramètres de contenu"
      }), " de votre compte, vous pouvez sélectionner ces modèles pour leur usage spécifique dans l'onglet de système."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque : Le seul"
        }), " type de modèle de page système qui peut être créé via l'interface de ligne de commande est le modèle de page de résultats de recherche."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Pages d'erreur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les pages d'erreur peuvent être définies dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "paramètres de contenu"
      }), " comme des pages ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/404.html",
        children: "404"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/500.html",
        children: "500"
      }), ". Les deux modèles utilisent le même templateType. Les modèles créés avec ce templateType n'apparaissent pas sur l'écran de sélection des modèles lorsqu'un utilisateur crée une page web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: error_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Page de gestion des inscriptions par e-mails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La page de gestion des inscriptions par e-mail Répertorie tous les types d'abonnement disponibles auxquels un utilisateur peut s'inscrire ou se désinscrire. Requis pour contenir la balise HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% email_subscriptions \"email_subscriptions\"  %}"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/subscription-preferences.html",
        children: "Voir le modèle des pages de gestion des inscriptions dans le cms-theme-boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_subscription_preferences_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Désabonnement alternatif des e-mails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un modèle de système pour les pages de désabonnement aux e-mails. Requis pour contenir la balise HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% email_simple_subscription \"email_simple_subscription\" %}"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/backup-unsubscribe.html",
        children: "Voir le modèle de désabonnement alternatif des emails dans le cms-theme-boilerplate."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_backup_unsubscribe_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Confirmation de désabonnement par e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un modèle de système pour les pages de confirmation de désabonnement aux e-mails C'est là que les utilisateurs sont envoyés lorsqu'ils se rendent à l'URL générée par la variable ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#email-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ unsubscribe_link_all }}"
        })
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/subscriptions-confirmation.html",
        children: "Voir le modèle de confirmation d'abonnement dans le cms-theme-boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: email_subscriptions_confirmation_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Invite de mot de passe"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles d'invite de mot de passe fournissent une page de marque que les créateurs de contenu peuvent afficher pour demander un mot de passe avant qu'un visiteur puisse voir le contenu réel de la page. Les modèles d'invite de mot de passe sont définis via les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "paramètres de contenu"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cos-pages-editor/how-can-i-password-protect-my-pages",
        children: "Comment rendre une page sur HubSpot protégée par un mot de passe"
      }), ". Voir l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/password-prompt.html",
        children: "invite de la page protégée par mot de passe"
      }), " dans le boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: password_prompt_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Page des résultats de recherche"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un modèle de système pour la fonctionnalité de listing de recherche de site intégrée au CMS. Voir le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/search-results.html",
        children: "modèle de page de résultats de recherche"
      }), " dans le boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: search_results_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Adhésion"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les comptes HubSpot disposant de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "fonctionnalité d'adhésion"
      }), " (", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "CMS Hub"
      }), " Entreprise ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "uniquement"
      }), ") peuvent ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cms-pages-editor/control-audience-access-to-pages",
        children: "créer des pages sur leurs sites auxquelles seuls les utilisateurs appartenant à des listes spécifiques dans le CRM peuvent accéder"
      }), ". Cela permet aux visiteurs du site d'avoir des comptes avec des identifiants de connexion. Ces modèles vous permettent de contrôler l'apparence de ces pages."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Seuls les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "modèles HTML et HubL"
        }), " peuvent être des modèles de contrôle d'accès."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Connexion des membres"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il s'agit de la page de connexion qui s'affiche lorsqu'un utilisateur tente d'accéder à un contenu dont l'accès est contrôlé par la fonctionnalité d'adhésion. Contient généralement le module ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% member_login \"member_login\" %}"
      }), ". Voir l'exemple de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-login.html",
        children: "modèle de connexion des membres"
      }), " dans le boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_login_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Registre des membres"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il s'agit de la page d'enregistrement des utilisateurs qui permet aux utilisateurs de créer un compte pour voir le contenu auquel les utilisateurs de cette liste peuvent accéder. Contient généralement la balise HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% member_register \"member_register\" %}"
      }), ". Voir l'exemple de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-register.html",
        children: "modèle d'inscription des membres"
      }), " dans le boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_register_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Réinitialisation du mot de passe de membre"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il s'agit de la page de réinitialisation du mot de passe. Les utilisateurs indiquent leur nouveau mot de passe sur cette page. Contient généralement la balise HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% password_reset \"password_reset\" %}"
      }), ". Voir l'exemple de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-reset-password.html",
        children: "modèle de réinitialisation du mot de passe de membre"
      }), " dans le boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_reset_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Demande de réinitialisation de l'adhésion"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il s'agit de la page de demande de réinitialisation du mot de passe. Affichage d'un formulaire pour demander un e-mail de réinitialisation du mot de passe. Contient généralement la balise HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% password_reset_request \"password_reset_request\" %}"
      }), ". Voir l'exemple de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/membership-reset-password-request.html",
        children: "modèle de demande de réinitialisation du mot de passe de membre"
      }), " dans le boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "templateType: membership_reset_request_page\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fichiers CMS inclus"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il y a certains fichiers JavaScript et CSS qui sont attachés aux modèles CMS. Certains fichiers sont automatiquement inclus et ne peuvent être supprimés, tandis que d'autres peuvent être inclus de manière facultative. Pour en savoir plus sur l'ordre dans lequel les feuilles de style sont attachées au contenu du CMS, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cos-general/create-edit-and-attach-css-files-to-style-your-site",
        children: "consultez cet article"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jquery.com/",
        children: "jQuery"
      }), " est inclus de manière facultative dans la balise d'en-tête des modèles HubSpot. S'il est inclus, il est rendu comme faisant partie de la variable HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Paramètres > Site web > Pages"
      }), ", vous pouvez changer la version de jQuery pour 1.11.x, ou la version 1.7.1 ou la désactiver complètement. Vous pouvez également choisir d'inclure un script jQuery Migrate pour assurer la rétrocompatibilité avec les anciens navigateurs. Vous pouvez déplacer jQuery vers le pied de page pour améliorer les performances de la page, mais cela peut corrompre JavaScript si vous vous en servez. Il est recommandé de tester cela avant le déplacement en ajoutant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsMoveJQueryToFooter =True"
      }), " à la fin des URL des pages de votre site web."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alors que jQuery était historiquement inclus par défaut, CMS Hub ne nécessite actuellement pas jQuery. La plupart des fonctionnalités de jQuery ont maintenant des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://youmightnotneedjquery.com/",
        children: "équivalents Vanilla JavaScript modernes"
      }), ", et il est recommandé de les utiliser à la place. Si vous devez utiliser jQuery, nous vous encourageons à désactiver la version par défaut dans les paramètres et à utiliser la dernière version chargée au-dessus de la balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</body>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour vérifier si la suppression de jQuery sur votre site aura des conséquences, ajoutez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsNoJQuery=true"
      }), " à la fin de l'URL lorsque vous consultez votre site, en particulier les pages très interactives."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "layout.css"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anciennement connu sous le nom ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "required_base.css"
      }), ", ce fichier détermine le style de la grille réactive de HubSpot. Ce fichier est automatiquement inclus dans tout modèle de glisser-déposer, mais ", (0, _jsxRuntime.jsx)("u", {
        children: "n'est pas"
      }), " inclus par défaut dans les modèles codés personnalisés. Si vous utilisez les balises ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area"
      }), " dans les modèles HTML et HubL codés, il n'est pas nécessaire de charger le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "layout.css"
      }), ", mais une version de celui-ci est ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "incluse dans le boilerplate du thème CMS"
      }), " pour faciliter une mise en route rapide."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En plus de la grille CSS adaptative, le fichier comprend des classes qui peuvent être utilisées pour afficher et masquer des éléments dans différentes fenêtres d'affichage. Pour en savoir plus, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/327485/Designers_Docs_2015/layout.css",
        children: "consultez le fichier directement"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Code de suivi HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/how-does-hubspot-track-visitors",
        children: "code de suivi HubSpot"
      }), " est ajouté automatiquement à tout modèle HubSpot, à l'exception des modèles d'email, avec la variable HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "standard_footer_includes"
        })
      }), ". Le code de suivi charge un fichier JavaScript analytique nommé ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "your_HubID.js"
      }), " (exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "158015.js"
      }), "). Ce code de suivi est directement intégré à la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/account/how-do-i-turn-on-gdpr-functionality-in-my-hubspot-account",
        children: "fonctionnalité RGPD de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ressources associées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "Zones de glisser-déposer"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections",
          children: "Modèles de section"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "Comment utiliser des composants web dans les modèles et les modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/global-content",
          children: "Créer des pieds de page ou encore des en-têtes avec du contenu global"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}