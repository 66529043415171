"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79935313443;
const slug = exports.slug = 'reference/cms/hubl/quote-variables';
const title = exports.title = 'Variables du modèle de devis';
const name = exports.name = 'EMEA FRANCE (fr) Quote template variables';
const metaDescription = exports.metaDescription = "Utilisez HubL pour accéder et afficher des données de CRM dans votre modèle de devis personnalisé. L'objet template_data contient la plupart des données associées au devis. Pour tout ce qui n'y est pas inclus, vous pouvez soit associer la fiche d'informations objet au devis, soit utiliser les fonctions HubL CRM_object.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "template_data-object",
  "label": "Template_data object",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-de-devis",
  "label": "Variables de devis",
  "parent": null
}, {
  "depth": 0,
  "id": "objets-associ%C3%A9s",
  "label": "Objets associés",
  "parent": null
}, {
  "depth": 0,
  "id": "objets-personnalis%C3%A9s",
  "label": "Objets personnalisés",
  "parent": null
}, {
  "depth": 0,
  "id": "autres-ressources",
  "label": "Autres ressources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      pre: "pre",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      br: "br",
      a: "a",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Variables du modèle de devis"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les modèles de devis personnalisés peuvent accéder aux données de devis et à certains objets associés directement depuis les modèles. Les données disponibles dépendent de celles que vous avez dans votre CRM, ainsi que de celles qui ont été ajoutées au devis lui-même."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de l'élaboration d'un modèle de devis, vous pouvez utiliser les données de simulation fournies par HubSpot pour remplir le modèle, ce qui peut aider à prévisualiser le modèle. Dans le dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot"
      }), ", accédez au dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cms-quotes-theme"
      }), ". Dans le dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templates"
      }), ", affichez les modèles ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "basic.html"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "modern.html"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "original.html"
      }), ". Le haut de ces modèles contient le bloc de code suivant :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% from \"../imports/mock_data.html\" import SAMPLE_TEMPLATE_DATA as mock_data %}\n{% from \"../imports/module_defaults.html\" import MODULE_DEFAULTS as module_defaults %}\n{% set QUOTE = template_data.quote || mock_data.quote %}\n{% set CURRENCY = QUOTE.hs_currency || \"USD\" %}\n{% set LOCALE = QUOTE.hs_locale || \"en-US\" %}\n{% set ASSOCIATED_OBJECTS = QUOTE.associated_objects %}\n{% set LINE_ITEMS = ASSOCIATED_OBJECTS.line_items %}\n{% set ADDITIONAL_FEES = ASSOCIATED_OBJECTS.additional_fees %}\n{% set TOTALS = ASSOCIATED_OBJECTS.totals || ASSOCIATED_OBJECTS.totals %}\n{% set QUOTE_TOTAL = TOTALS.total %}\n{% set SUB_TOTALS = TOTALS.subtotals %}\n{% set DEAL = ASSOCIATED_OBJECTS.deal %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les données simulées sont d'abord importées à partir du fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mock_data.html"
      }), ", puis sont définies sur la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), " pour utiliser les données trouvées dans ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), ", le cas échéant. La variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), " est également utilisée pour remplir les autres variables de cette liste, telles que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ASSOCIATED_OBJECTS"
      }), ", afin de rendre l'accès à ces données moins verbeux. Toutefois, vous pouvez structurer vos données différemment, en fonction de vos préférences."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le code ci-dessus, vous remarquerez que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " est également utilisé pour définir la principale variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), ". L'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " contient toutes les données réelles pour le devis et la transaction dans la page. Si cet objet n'est pas trouvé dans le modèle, HubSpot charge les données à partir de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mock_data.html"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Template_data object"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La grande majorité des données sont directement accessibles via l'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), ". Vous pouvez utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ template_data|pprint }}"
      }), " dans votre modèle pour afficher l'intégralité de l'objet fourni."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un dictionnaire contenant le devis, quote.associated_objects, et les dictionnaires totaux."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables de devis"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les informations spécifiques à ce devis en particulier."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dictionnaire contenant toutes les données du devis lui-même."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.associated_objects.deal.hs_object_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID de transaction"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID du propriétaire de transaction"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_all_owner_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "integer or array of integers"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID des propriétaires de transaction"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_created_by_user_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Utilisateur qui a créé le devis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_lastmodifieddate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date de la dernière modification du devis. Au format epoch."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hubspot_owner_assigneddate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date à laquelle le devis a été attribué à un propriétaire. Au format epoch."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_createdate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date et heure auxquelles le devis a été créé. Au format epoch."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_expiration_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date d'expiration du devis. Au format epoch."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Titre du devis"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_template_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "\"CUSTOMIZABLE_QUOTE_TEMPLATE\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Slug d'URL pour la page web de devis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_proposal_template_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chemin du système de fichiers développeur vers le modèle (inclut l'extension de fichier)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_quote_amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Montant monétaire"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_currency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Devise dans laquelle le montant du devis est exprimé, en code de devise ISO 4217 à 3 caractères.\"USD\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Code de langue\"fr\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_locale"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Code cadre\"fr-fr\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_terms"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texte des conditions fourni par le créateur du devis"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Prénom de la personne qui envoie le devis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nom de la société de la personne qui a envoyé le devis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_company_image_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Logo de l'entreprise de la personne qui envoie le devis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "État du devis.\"APPROVAL_NOT_NEEDED\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_primary_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string/hex color code"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "\"#425b76\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_quote_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Numéro d'identification unique du devis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_payment_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "À utiliser pour tester si les champs de paiement doivent être affichés."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_esign_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "À utiliser pour tester si des champs d'e-signature doivent être affichés."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vous ne trouvez pas la variable que vous cherchez ?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Vous pouvez accéder à d'autres variables dans ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "template_data"
        }), ". Utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|pprint"
        }), " pour les voir. En outre, certaines variables dans les associations de devis peuvent n'être disponibles qu'en fonction du devis/de la transaction."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Nous allons compléter cette documentation pour présenter et expliquer davantage les données auxquelles vous avez accès. En dehors de l'impression élégante, vous pouvez consulter le fichier de données simulées dans le cms-quote-theme, pour voir ce qui est disponible et la structure dans laquelle il se trouve."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objets associés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans un modèle de devis, vous pouvez accéder aux données des enregistrements associés à un devis, tels que des transactions ou des entreprises, en utilisant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associated_objects"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, vous pouvez ajouter à un devis le logo de l'entreprise associée au destinataire du devis en utilisant le code suivant :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set company_avatar_url = template_data.quote.associated_objects.company.hs_avatar_filemanager_key %}\n{% if company_avatar_url %}\n  <img src=\"{{ template_data.quote.associated_objects.company.hs_avatar_filemanager_key }}\" width=\"400\" alt=\"{{ template_data.quote.associated_objects.company.name }}\">\n{% else %}\n  <!-- company does not have an assigned image-->\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Seuls les logos définis manuellement apparaîtront. Les logos détectés automatiquement n'apparaîtront pas, pour éviter que les logos involontaires n'apparaissent sur le modèle de devis."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le code ci-dessus définit d'abord une variable qui recherche le logo de l'entreprise associée au devis. Ensuite, à l'aide d'une instruction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", le modèle affiche ce logo, le cas échéant. Si aucun logo n'a été défini manuellement pour l'entreprise, aucun logo n'est affiché."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objets personnalisés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les données d'objet personnalisés peuvent être affichées ou utilisées dans un devis de différentes manières. Étant donné que la structure de chaque objet personnalisé peut varier, vous devez obtenir des propriétés spécifiques selon la façon dont vous avez structuré votre objet personnalisé."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " du devis par défaut contient des objets associés personnalisés. Par exemple, des objets personnalisés associés aux transactions sont inclus."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour y accéder, vous pouvez utiliser le code suivant :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set quote_associated_custom_objects = template_data.quote.associated_objects.deal.associated_objects.custom_objects %}\n\n{{ quote_associated_custom_objects|pprint }}\n{# |pprint is useful for understanding the structure of the data, you can leave it off when outputting values for display. #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Étant donné que les objets personnalisés sont propres à chaque compte, les données simulées n'incluent pas d'exemple d'objet personnalisé. Cela signifie que dans l'aperçu du modèle dans le gestionnaire de conception, vous pouvez voir une erreur ou les données d'objet personnalisés ne s'affichent tout simplement pas. Vous devrez donc prévisualiser le modèle avec vos données CRM réelles, ce que vous pouvez faire en créant un devis à partir du modèle."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez ensuite accéder à chaque type d'objet personnalisé en ajoutant son ID de type d'objet personnalisé formaté avec des tirets bas. Par exemple :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data.quote.associated_objects.deal.associated_objects.custom_objects._2_2193031"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également rechercher un objet personnalisé en utilisant les fonctions ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations()"
        })
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects()"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, si vous souhaitez rechercher un objet personnalisé associé à une transaction, vous pouvez transmettre les données de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set quote_associated_object = crm_associations(template_data.quote.associated_objects.deal.hs_object_id, \"USER_DEFINED\", 152) %}\n{# 152 is an example of an association type id, you would need to use the appropriate id for your use-case. #}\n{{ quote_associated_object }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autres ressources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/quotes",
          children: "Modèles de devis personnalisés"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/getting-started-from-the-cms-quotes-theme-beta",
          children: "Premiers pas avec le thème des devis CMS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/fr/deals/create-custom-quote-templates",
          children: "Créer et utiliser des modèles de devis personnalisés (du point de vue des commerciaux, des opérations de vente/du manager)"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}