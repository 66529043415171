"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921797;
const slug = exports.slug = 'guides/api/crm/extensions/timeline';
const title = exports.title = 'API du CRM | Événements de chronologie';
const name = exports.name = 'CRM | Événements de chronologie';
const metaDescription = exports.metaDescription = "Découvrez une vue d'ensemble et une explication de l'API Chronologie.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-un-mod%C3%A8le-d-%C3%A9v%C3%A9nement",
  "label": "Créer un modèle d'événement",
  "parent": null
}, {
  "depth": 1,
  "id": "cr%C3%A9er-des-mod%C3%A8les-d-%C3%A9v%C3%A9nement-via-l-api",
  "label": "Créer des modèles d'événement via l'API",
  "parent": "cr%C3%A9er-un-mod%C3%A8le-d-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "cr%C3%A9er-des-mod%C3%A8les-d-%C3%A9v%C3%A9nement-dans-hubspot",
  "label": "Créer des modèles d'événement dans HubSpot",
  "parent": "cr%C3%A9er-un-mod%C3%A8le-d-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "d%C3%A9finir-des-jetons-d-%C3%A9v%C3%A9nement",
  "label": "Définir des jetons d'événement",
  "parent": "cr%C3%A9er-un-mod%C3%A8le-d-%C3%A9v%C3%A9nement"
}, {
  "depth": 2,
  "id": "cr%C3%A9er-des-jetons-d-%C3%A9v%C3%A9nement-via-l-api",
  "label": "Créer des jetons d'événement via l'API",
  "parent": "cr%C3%A9er-un-mod%C3%A8le-d-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "d%C3%A9finir-des-mod%C3%A8les-d-en-t%C3%AAte-et-de-d%C3%A9tails",
  "label": "Définir des modèles d'en-tête et de détails",
  "parent": "cr%C3%A9er-un-mod%C3%A8le-d-%C3%A9v%C3%A9nement"
}, {
  "depth": 2,
  "id": "d%C3%A9finir-des-mod%C3%A8les-d-en-t%C3%AAte-et-de-d%C3%A9tails-via-l-api",
  "label": "Définir des modèles d'en-tête et de détails via l'API",
  "parent": "cr%C3%A9er-un-mod%C3%A8le-d-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "d%C3%A9finir-tous-les-aspects-d-un-mod%C3%A8le-d-%C3%A9v%C3%A9nement-dans-un-seul-appel",
  "label": "Définir tous les aspects d'un modèle d'événement dans un seul appel",
  "parent": "cr%C3%A9er-un-mod%C3%A8le-d-%C3%A9v%C3%A9nement"
}, {
  "depth": 0,
  "id": "cr%C3%A9er-un-%C3%A9v%C3%A9nement",
  "label": "Créer un événement",
  "parent": null
}, {
  "depth": 1,
  "id": "d%C3%A9finir-l-horodatage-d-%C3%A9v%C3%A9nement",
  "label": "Définir l'horodatage d'événement",
  "parent": "cr%C3%A9er-un-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "associer-un-%C3%A9v%C3%A9nement-%C3%A0-un-objet-de-crm",
  "label": "Associer un événement à un objet de CRM",
  "parent": "cr%C3%A9er-un-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "extensions-de-chronologie",
  "label": "Extensions de chronologie",
  "parent": "cr%C3%A9er-un-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "horodater-des-donn%C3%A9es-d-%C3%A9v%C3%A9nement-dans-des-propri%C3%A9t%C3%A9s-d-objet-de-crm",
  "label": "Horodater des données d'événement dans des propriétés d'objet de CRM",
  "parent": "cr%C3%A9er-un-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "comprendre-",
  "label": "Comprendre ",
  "parent": "cr%C3%A9er-un-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "configurer-une-ic%C3%B4ne-personnalis%C3%A9e",
  "label": "Configurer une icône personnalisée",
  "parent": "cr%C3%A9er-un-%C3%A9v%C3%A9nement"
}, {
  "depth": 2,
  "id": "documents-associ%C3%A9s",
  "label": "Documents associés",
  "parent": "cr%C3%A9er-un-%C3%A9v%C3%A9nement"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      h3: "h3",
      pre: "pre",
      em: "em",
      strong: "strong",
      h4: "h4",
      ul: "ul",
      li: "li",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Événements de chronologie"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les extensions de CRM permettent aux autres systèmes d'apparaître sur des objets de contact, d'entreprise ou de transaction HubSpot. Les points de terminaison des événements de chronologie permettent de créer des événements chronologiques personnalisés. Si vous préférez que vos données soient modifiables par les utilisateurs, mais qu'aucun des objets CRM par défaut ne correspond à vos besoins, découvrez les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objets personnalisés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/timeline_api/event_expanded-2.png",
        alt: "event_expanded-2"
      }), "Par exemple, vous souhaitez mieux segmenter vos contacts en fonction de leurs interactions avec votre entreprise et votre contenu. Pour cela, vous devez en savoir davantage sur eux. Votre application peut créer des événements personnalisés (contacts inscrits mais n'ayant pas assisté à un webinar récent, variante d'un flux de souscription complété par un contact, etc.) qui offrent davantage de contexte sur les interactions des contacts avec votre entreprise."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un modèle d'événement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avant de commencer à créer des événements, vous devez créer un modèle d'événement. Les modèles d'événement décrivent les actions que votre application ajoutera à la chronologie d'un contact, d'une entreprise ou d'une transaction dans HubSpot. Ces actions comprennent la consultation d'une vidéo, l'inscription à un webinar ou encore la réponse à une enquête. Une seule application peut créer jusqu'à 750 modèles d'événement."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles d'événement sont créés pour les contacts par défaut, mais ils peuvent être créés pour des entreprises ou des transactions via le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), ". Consultez la création d'un modèle d'événement de chronologie pour plus de détails."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Chaque modèle d'événement possède ses propres jetons et modèles. Vous pouvez utiliser des événements créés pour les contacts comme critères lors de la création de nouvelles listes de contacts ou de workflows, comme : « Créer une liste de tous les contacts avec une mention J'aime pour une vidéo, où le nom de la vidéo contient XYZ, où votre modèle d'événement est intitulé « Mention J'aime pour la vidéo » et possède un jeton d'événement intitulé « nom de la vidéo ».\""
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer des modèles d'événement via l'API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans cet exemple, un nouveau modèle d'événement, « Exemple d'inscription au webinar », sera créé. Pour l'authentification, utilisez la clé d'API de développeur trouvée dans votre compte de développeur d'applications."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST\n-H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"Example Webinar Registration\",\n  \"objectType\": \"contacts\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>''\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Assurez-vous de remplacer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<<appId>>"
      }), " par votre propre ID d'application, disponible sur les pages ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Mes applications"
      }), " et Détails de l'application de votre compte de développeur. Vous devrez également remplacer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<<developerHapikey>>"
      }), " par votre propre clé d'API de développeur, disponible en accédant à ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Applications"
      }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Obtenir la clé d'API HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les propriétés ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "headerTemplate"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "detailTemplate"
      }), " peuvent également être renseignées ici (voir Définition de modèles ci-dessous)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " renvoie la définition complète du modèle d'événement enregistré. Veillez à noter la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " dans cette réponse. Il s'agit de l'ID du modèle d'événement, que vous devrez mettre à jour ainsi que les jetons à l'avenir."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez voir tous les modèles d'événement définis pour une application via cette commande GET, qui retournera également les ID de modèle d'événement :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X GET 'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer des modèles d'événement dans HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Outre l'utilisation de l'API pour créer et gérer des modèles d'événement de chronologie, vous pouvez également gérer les modèles d'événements dans votre compte de développeur HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans les paramètres de votre application, accédez à Événements de chronologie et utilisez le bouton Créer un type d'événement pour créer un nouveau modèle d'événement pour cette application. Si vous avez créé des modèles d'événement auparavant, vous les verrez ici."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/image%20(3)-Jul-23-2020-10-02-24-50-PM.png",
        alt: "image (3)-Jul-23-2020-10-02-24-50-PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous commencerez avec un brouillon de votre nouveau modèle d'événement. Une fois que vous avez défini le type d'objet ainsi que les modèles de détails et d'en-tête pour l'événement, cliquez sur « Créer.\""
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/image%20(4)-Jul-23-2020-10-02-24-66-PM.png",
        alt: "image (4)-Jul-23-2020-10-02-24-66-PM"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous créez ou modifiez votre modèle d'événement, définissez tous les jetons que vous souhaitez utiliser dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Données"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/data-tab-1.png",
        alt: "data-tab-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " si vous supprimez un modèle, les événements existants utilisant ce modèle seront définitivement supprimés des comptes sur lesquels est installée votre application. Vous ne pourrez plus créer de nouveaux événements de ce type, mais vous verrez les données d'anciens événements dans les listes et les rapports. L'application de ces modifications dans HubSpot peut prendre plusieurs heures."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir des jetons d'événement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois que vous avez défini un modèle d'événement, vous souhaiterez peut-être définir ses jetons. Les jetons de modèles d'événement vous permettent de joindre des données personnalisées aux événements qui peuvent être affichées dans la chronologie et, pour les contacts, utilisées pour la segmentation de liste et l'automatisation. Vous pouvez créer jusqu'à 500 jetons par modèle d'événement de chronologie."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " les événements d'entreprise et de transaction ne peuvent ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), " être utilisés dans la segmentation ou l'automatisation de liste."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Créer des jetons d'événement via l'API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En utilisant l'ID du modèle d'événement créé à l'étape 1, des jetons seront ajoutés pour identifier les webinars auxquels les contacts se sont inscrits."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarName\",\n  \"label\": \"Webinar Name\",\n  \"type\": \"string\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n\ncurl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarId\",\n  \"label\": \"Webinar Id\",\n  \"type\": \"string\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n\ncurl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"webinarType\",\n  \"label\": \"Webinar Type\",\n  \"type\": \"enumeration\",\n  \"options\": [\n    {\n      \"value\": \"regular\",\n      \"label\": \"Regular\"\n    },\n    {\n      \"value\": \"ama\",\n      \"label\": \"Ask me anything\"\n    }\n  ]\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["De même, une commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " renverra tous les jetons définis sur un modèle d'événement :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X GET -H \"Content-Type: application/json\" 'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les types de jeton pris en charge comprennent :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "number"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "enumeration"
        }), "— Un ensemble d'options. Voir l'exemple de webinarType ci-dessous."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "date"
        }), " — Toutes les dates doivent être en millisecondes selon Unix."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : Les jetons d'événement ne peuvent pas être appelés log ou lookup. Ces jetons sont réservés en tant qu'aides par Handlebars.js, la bibliothèque utilisée pour afficher les événements dans l'application. Pour plus d'informations, consultez les documents relatifs à Handlebars.js ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://handlebarsjs.com/builtin_helpers.html",
          children: "ici."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir des modèles d'en-tête et de détails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles d'en-tête et de détails définissent l'affichage d'un événement de chronologie. Vous pouvez spécifier les documents ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://daringfireball.net/projects/markdown/syntax",
        children: "Markdown"
      }), " avec les modèles ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://handlebarsjs.com/",
        children: "Handlebars"
      }), ". Le modèle d'en-tête doit être une description d'une ligne de l'événement et le modèle de détails est la vue d'exploration de l'événement (exemples ci-dessous)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les jetons d'événement sont transmis en tant que données aux modèles. En utilisant cet exemple, vous pouvez mentionner le jeton ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), " dans le modèle en utilisant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{webinarName}}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le code ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " d'un événement (voir « Comprendre extraData\"ci-dessous) peut être mentionné dans le modèle de détails."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Définir des modèles d'en-tête et de détails via l'API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles d'en-tête et de détails peuvent être définis sur le modèle d'événement via les points de terminaison des modèles d'événement. Par exemple, il est possible d'ajouter des modèles à « Exemple d'inscription au webinar » en modifiant cela avec ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X PUT -H \"Content-Type: application/json\" -d '\n{\n  \"id\": \"<<eventTemplateId>>\",\n  \"name\": \"Example Name Change\",\n  \"headerTemplate\": \"Registered for [{{webinarName}}](https://mywebinarsystem/webinar/{{webinarId}})\",\n  \"detailTemplate\": \"Registration occurred at {{#formatDate timestamp}}{{/formatDate}}\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Notez l'utilisation de la directive", (0, _jsxRuntime.jsx)(_components.code, {
        children: "#formatDate"
      }), ", définie pour permettre un format de date convivial."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que l'événement est créé pour un contact à l'aide de cela (voir \"", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#creating-an-event",
        children: "Création d'un événement"
      }), "\" ci-dessous), voici ce qui apparaît dans la chronologie du contact :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_collapsed.png?width=640&name=event_collapsed.png",
        alt: "event_collapsed.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un clic sur Afficher les détails affiche le modèle de détails :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_expanded.png?width=640&name=event_expanded.png",
        alt: "event_expanded.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour définir l'icône affichée à côté des événements, consultez \"", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline#customicon",
        children: "Configurer une icône personnalisée\""
      }), " ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le texte « Example App Name » ci-dessus est le nom de l'application. Dans la chronologie du CRM, les événements peuvent être filtrés par application."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir tous les aspects d'un modèle d'événement dans un seul appel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Maintenant que vous avez vu chaque aspect d'un modèle d'événement, vous pouvez tous les définir dans un seul appel ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" -d '\n{\n  \"name\": \"Another Webinar Registration\",\n  \"objectType\": \"contacts\",\n  \"headerTemplate\": \"Registered for [{{webinarName}}](https://mywebinarsystem/webinar/{{webinarId}})\",\n  \"detailTemplate\": \"Registration occurred at {{#formatDate timestamp}}{{/formatDate}}\",\n  \"tokens\": [\n    {\n      \"name\": \"webinarName\",\n      \"label\": \"Webinar Name\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"webinarId\",\n      \"label\": \"Webinar Id\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"webinarType\",\n      \"label\": \"Webinar Type\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"value\": \"regular\",\n          \"label\": \"Regular\"\n        },\n        {\n          \"value\": \"ama\",\n          \"label\": \"Ask me anything\"\n        }\n      ]\n    }\n  ]\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates?hapikey=<<developerAPIkey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un événement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois qu'un modèle d'événement est configuré avec des jetons et des modèles, il est possible de créer des événements pour les contacts, les entreprises, les transactions et les tickets des clients. Les exemples ci-dessous concernent le modèle d'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contacts"
      }), " créé ci-dessus. Si le modèle d'événement ci-dessus n'est pas configuré pour disposer des jetons ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarId"
      }), ", vous recevrez une erreur lors de la tentative de création d'événement. Voici un exemple ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " pour la création d'un événement :"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : les clés d'API de développeur et les jetons d'accès aux applications privées ne peuvent ", (0, _jsxRuntime.jsx)("u", {
          children: "pas"
        }), " être utilisés comme authentification lors de la création d'événements. Pour créer un événement, le compte HubSpot associé doit accorder l'accès à votre application via ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth"
        }), ". Une fois que vous recevez un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "jeton d'accès Oauth"
        }), ", vous pouvez l'utiliser pour ajouter des événements au compte."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cela génère un événement sur la chronologie de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "a.test.contact@email.com"
      }), "'(en supposant les modèles décrits dans Définition de modèles ci-dessus) :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/event_collapsed.png?width=640&name=event_collapsed.png",
        alt: "event_collapsed.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir l'horodatage d'événement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'horodatage d'événement détermine où l'événement apparaîtra dans la chronologie de l'objet. Par défaut, l'horodatage d'événement correspond à l'envoi de la commande POST. Vous pouvez personnaliser l'heure de l'événement en la fournissant dans le corps de la demande dans une propriété d'horodatage :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"timestamp\": \"2020-03-18T15:30:32Z\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cela est recommandé si vous connaissez l'heure exacte d'une action. Dans cet exemple, si l'horodatage pour l'inscription au webinar est connu, il est recommandé de le fournir dans cette commande POST."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les horodatages peuvent être en millisecondes ou au format ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/ISO_8601",
        children: "ISO 8601"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associer un événement à un objet de CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour créer un événement, vous devez associer l'événement à un contact, une entreprise ou une transaction dans le compte du client."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans les exemples ci-dessus, objectType a été défini sur Contact et nous avons utilisé l'adresse e-mail pour associer l'événement à un contact. Les adresses e-mail doivent être uniques pour les contacts dans HubSpot. Si un contact avec l'adresse e-mail fournie existe déjà, ce contact sera mis à jour. S'il n'y a aucun contact existant, un nouveau contact sera créé. Par défaut, seule la propriété d'adresse e-mail sera fournie pour ce nouveau contact. Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#stamp-event-data-onto-crm-object-properties",
        children: "l'horodatage de données d'événement dans les propriétés de contact"
      }), " pour ajouter des données supplémentaires aux propriétés de contact."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous travaillez avec des contacts connus, vous pouvez également utiliser le ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vid"
      }), " du contact pour associer l'événement. Dans ces cas, vous utiliserez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " dans le JSON de requête. Vous devez inclure le vid d'un contact existant, car vous ne pourrez pas créer de nouveaux contacts à l'aide de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ". Cet exemple utilise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " au lieu de l'adresse e-mail :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"objectId\": \"29851\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également associer un événement avec un contact via un jeton d'utilisateur, ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), ". Le jeton d'utilisateur est utilisé par le code de suivi HubSpot pour suivre les visiteurs et stocké dans le cookie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspotutk"
      }), ". Utilisez le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " pour associer un événement à un contact via un jeton d'utilisateur. Remarque : Il n'est pas possible d'associer des événements à des visiteurs anonymes via un jeton d'utilisateur. Par conséquent, si l'événement est associé uniquement à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " et que le jeton fourni n'est pas déjà associé à un contact, aucun nouveau contact ne sera créé et l'événement ne sera pas visible dans HubSpot. Toutefois, l'événement apparaîtra dans la chronologie si un nouveau contact est associé au jeton d'utilisateur via un autre moyen (généralement via une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/marketing/forms/v3-legacy#submit-data-to-a-form-supporting-authentication",
        children: "soumission de formulaire comprenant hutk"
      }), ", ou via la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/tracking-code",
        children: "méthode d'identification de l'API Code de suivi"
      }), "). C'est pourquoi nous recommandons d'inclure ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " en plus de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " pour vous assurer que l'événement est associé à un contact nouveau ou existant."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous travaillez avec un modèle d'événement pour les contacts, il est possible d'inclure plusieurs paramètres d'identification avec l'événement, afin que toute combinaison des paramètres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), " puisse être incluse. Si plusieurs paramètres sont inclus, objectId (vid) aura la priorité la plus élevée lors de la détermination du contact à associer à l'événement, suivi de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "utk"
      }), ", et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " sera le paramètre le moins prioritaire. Cela signifie que vous pouvez mettre à jour l'adresse e-mail d'un objet existant en incluant une nouvelle adresse e-mail dans le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " avec le ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vid"
      }), " d'un objet connu dans ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ". Cet exemple utilise l'adresse e-mail et le jeton d'utilisateur :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"utk\": \"89b5afb740d41f4cd6651ac5237edf09\"\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Outre les contacts, il est également possible de créer des modèles d'événement pour des entreprises et des transactions. Pour ces modèles d'événement, vous devez utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " pour associer l'événement à l'entreprise ou à la transaction. Pour les entreprises, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " doit être défini sur le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyId"
      }), " de l'entreprise à laquelle vous souhaitez associer l'événement. Pour les transactions, vous définirez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " sur le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealId"
      }), " de la transaction."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l'exemple ci-dessous, en supposant que le modèle d'événement a été défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPANY"
      }), " pour ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), ", cet événement sera associé à l'entreprise avec le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyId"
      }), " 528253914 :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"objectId\": \"3001\",\n  \"tokens\": {\n    \"dealProperty\": \"Custom property for deal\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Extensions de chronologie"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les extensions de chronologie peuvent être utilisées pour afficher des données provenant d'un système externe via un iFrame. Une fois inclus, l'événement affichera un lien vers une fenêtre modale qui affichera le contenu de l'iFrame. Les détails de l'iFrame sont définis dans le champ timelineIFrame, qui contient les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "linkLabel"
        }), " - Le texte utilisé pour afficher le lien qui affichera l'iFrame."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "headerLabel"
        }), " - Le libellé de la fenêtre modale qui affiche le contenu de l'iFrame."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "url"
        }), " - L'URI du contenu iFrame."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "width"
        }), " - La largeur de la fenêtre modale."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "height"
        }), " - La hauteur de la fenêtre modale."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, l'utilisation de ces données pour un événement :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "// {\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"A Test Webinar\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  },\n  \"timelineIFrame\": {\n    \"linkLabel\":\"View external data\",\n    \"headerLabel\":\"Example iframe\",\n    \"url\":\"https://www.example.com\",\n    \"width\":800,\n    \"height\":300\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Créerait cet événement, y compris le lien « Afficher les données externes » :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/external_data_link.png?width=640&name=external_data_link.png",
        alt: "external_data_link.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un clic sur ce lien ouvrira une fenêtre modale qui affichera la page définie dans ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "url"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/iframe_modal.png?width=640&name=iframe_modal.png",
        alt: "iframe_modal.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Horodater des données d'événement dans des propriétés d'objet de CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans de nombreux cas, vous souhaiterez modifier les propriétés des contacts, des entreprises ou des transactions auxquels vous ajoutez des événements. Cela se produit souvent dans les cas où l'ajout de l'événement crée un contact. Vous devrez probablement mettre à jour les propriétés de prénom et de nom pour le contact afin de ne pas créer de contact avec uniquement une adresse e-mail et un événement."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez horodater les données sur l'objet associé à partir d'un événement par mappage de jetons d'événement personnalisés pour les propriétés de contact, d'entreprise et de transaction."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tenez compte de la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " pour mettre à jour un modèle d'événement personnalisé ainsi que du champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectPropertyName"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X PUT -H \"Content-Type: application/json\" -d '\n{\n  \"label\" : \"Updated Webinar Name\",\n  \"objectPropertyName\": \"zz_webinar_name\"\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/<<appId>>/event-templates/<<eventTemplateId>>/tokens/<<tokenName>>?hapikey=<<developerHapikey>>'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectPropertyName"
      }), " est utilisé pour mapper ce jeton d'événement personnalisé à la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), " de l'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), ". Cela signifie que lorsqu'un nouvel événement précisant un jeton ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webinarName"
      }), " est créé, la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), " du ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), " associé sera également définie. Vous pouvez définir cela pour des propriétés HubSpot prédéfinies ou personnalisées."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, supposons que nous avons déjà créé un jeton ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "companyName"
      }), " mentionnant une propriété personnalisée ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_company_name"
      }), " sur le contact. La création d'un événement comme celui-ci définira les propriétés ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_company_name"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "zz_webinar_name"
      }), " sur le contact avec l'adresse e-mail ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "mailto:a.test.contact@email.com",
        children: "a.test.contact@email.com"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"Test Webinar will update contact property\",\n    \"companyName\": \"TestCo\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/set_property.png?width=1024&name=set_property.png",
        alt: "set_property.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Remarque : Si un jeton d'événement est horodaté pour une propriété personnalisée et que la propriété personnalisée n'est pas présente pour un compte HubSpot, la valeur sera toujours définie pour l'événement, mais elle sera ignorée pour l'objet correspondant."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h3, {
      children: ["Comprendre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous devrez peut-être ajouter des données détaillées à un événement qui ne correspondent pas à la structure jeton-valeur simple utilisée par les jetons de modèle d'événement. Vous devrez peut-être ajouter une liste ou une répartition hiérarchique à un événement d'intégration. C'est ici que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " entre en jeu."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez ajouter un attribut ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " au corps JSON d'un événement. La valeur de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " peut être tout JSON valide. Par exemple :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -X POST -H \"Content-Type: application/json\" \\\n-H \"Authorization: Bearer <<OAuth2AccessToken>>\" \\\n-d '\n{\n  \"eventTemplateId\": \"<<eventTemplateId>>\",\n  \"email\": \"a.test.contact@email.com\",\n  \"tokens\": {\n    \"webinarName\": \"Test Webinar will update contact property\",\n    \"companyName\": \"TestCo\",\n    \"webinarId\": \"001001\",\n    \"webinarType\": \"regular\"\n  },\n  \"extraData\": {\n    \"pollData\": [\n      {\n        \"question\": \"How excited are you for this webinar?\",\n        \"answer\":\"Quite!\"\n      },\n      {\n        \"question\": \"How frequently do you use our product?\",\n        \"answer\":\"Daily\"\n      }\n    ],\n    \"coWorkers\": [\n      {\n        \"name\": \"Joe Coworker\",\n        \"email\":\"joe.coworker@testco.com\"\n      },\n      {\n        \"name\": \"Jane Coworker\",\n        \"email\":\"jane.coworker@testco.com\"\n      }\n    ]\n  }\n}' \\\n'https://api.hubapi.com/crm/v3/timeline/events'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Voici un exemple d'utilisation de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " dans un modèle de détails :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "//\nRegistration occurred at {{#formatDate timestamp}}{{/formatDate}}\n\n#### Poll Questions\n{{#each extraData.pollData}}\n  **{{question}}**: {{answer}}\n{{/each}}\n\n#### Co-Workers\n{{#each extraData.coWorkers}}\n  * {{name}}\n{{/each}}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cela générera un événement de chronologie qui ressemblera à ceci :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/extra_data.png?width=640&name=extra_data.png",
        alt: "extra_data.png"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Remarque : L'attribut ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "extraData"
      }), " ne peut être mentionné que dans le modèle de détails pour un événement. Il ne peut pas être utilisé dans le modèle d'en-tête ou dans la segmentation de liste."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configurer une icône personnalisée"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ajouter un attrait visuel à vos éléments de chronologie, vous souhaiterez ajouter une icône personnalisée."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce fichier d'image pour cette icône doit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Avoir une forme approximativement carrée ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Avoir un arrière-plan transparent ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Présenter son contenu au centre de l'icône ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pouvoir être réduit jusqu'à 30 x 30 pixels ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Être égal ou inférieur à 5 Mo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour définir l'icône utilisée pour les événements de chronologie, accédez à Événements de chronologie. Cliquez sur l'image de l'espace réservé ou sur l'icône existante pour la définir ou la mettre à jour."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/timeline_assets.png",
        alt: "timeline_assets"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois que vous avez défini une icône, celle-ci sera affichée à côté des événements de chronologie associés à cette application :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/timeline_api/timeline_icon.png?width=640&name=timeline_icon.png",
        alt: "timeline_icon.png"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documents associés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Comprendre le CRM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "Cartes CRM"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}