import { useContext } from 'react';
import devLogger from 'react-utils/devLogger';
import { ViewportContext } from '../context/ViewportContext';

/**
 * Used _only_ to conditionally render components. Typically,
 * for performance reasons, you should use `mediaQueries` within a
 * styled component when a component's CSS properties change
 * across breakpoints but there is no need to conditionally render it or move
 * the HTML/markup.
 *
 * Use alongside `ViewportProvider` to check whether the current viewport
 * matches the provided media query strings.
 *
 * @example
 * ```tsx
 * const { isDesktop, isMobile } = useViewport(); // example return of { isDesktop: true, isMobile: false }
 * ```
 *
 * @see https://private.hubteamqa.com/developer-docs-shared-components-storybook/?path=/story/utils-media-queries-and-breakpoints--page
 *
 */
export const useViewport = () => {
  const context = useContext(ViewportContext);
  if (!context) {
    devLogger.warn({
      message: 'useViewport must be used within ViewportProvider',
      key: null
    });
  }
  // Exclude null for better IDE documentation
  return context;
};