"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816705709;
const slug = exports.slug = 'guides/apps/public-apps/overview';
const title = exports.title = 'Aplicaciones públicas';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Creating and installing apps';
const metaDescription = exports.metaDescription = 'Descubre cómo crear e instalar una aplicación en HubSpot. Las aplicaciones son integraciones que pueden instalarse en las cuentas de clientes a través de un proceso de conexión de OAuth.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-una-api-p%C3%BAblica",
  "label": "Crear una API pública",
  "parent": null
}, {
  "depth": 1,
  "id": "configurar-alcances",
  "label": "Configurar alcances",
  "parent": "crear-una-api-p%C3%BAblica"
}, {
  "depth": 2,
  "id": "tipos-de-alcance",
  "label": "Tipos de alcance",
  "parent": "crear-una-api-p%C3%BAblica"
}, {
  "depth": 2,
  "id": "configurar-los-alcances-p%C3%BAblicos-de-tu-aplicaci%C3%B3n",
  "label": "Configurar los alcances públicos de tu aplicación",
  "parent": "crear-una-api-p%C3%BAblica"
}, {
  "depth": 0,
  "id": "instalar-una-aplicaci%C3%B3n",
  "label": "Instalar una aplicación",
  "parent": null
}, {
  "depth": 1,
  "id": "autorizar-tu-aplicaci%C3%B3n-con-una-cuenta-de-cliente",
  "label": "Autorizar tu aplicación con una cuenta de cliente",
  "parent": "instalar-una-aplicaci%C3%B3n"
}, {
  "depth": 1,
  "id": "generar-los-tokens-oauth-iniciales",
  "label": "Generar los tokens OAuth iniciales",
  "parent": "instalar-una-aplicaci%C3%B3n"
}, {
  "depth": 0,
  "id": "gestionar-aplicaciones-p%C3%BAblicas-en-hubspot",
  "label": "Gestionar aplicaciones públicas en HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "find-an-app-s-id",
  "label": "Find an app's ID",
  "parent": "gestionar-aplicaciones-p%C3%BAblicas-en-hubspot"
}, {
  "depth": 1,
  "id": "monitorear-del-comportamiento-de-la-aplicaci%C3%B3n",
  "label": "Monitorear del comportamiento de la aplicación",
  "parent": "gestionar-aplicaciones-p%C3%BAblicas-en-hubspot"
}, {
  "depth": 0,
  "id": "agregar-un-dominio-verificado",
  "label": "Agregar un dominio verificado",
  "parent": null
}, {
  "depth": 1,
  "id": "agregar-un-dominio-verificado",
  "label": "Agregar un dominio verificado",
  "parent": "agregar-un-dominio-verificado"
}, {
  "depth": 2,
  "id": "notas-adicionales",
  "label": "Notas adicionales",
  "parent": "agregar-un-dominio-verificado"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      em: "em",
      ul: "ul",
      li: "li",
      h2: "h2",
      strong: "strong",
      img: "img",
      h3: "h3",
      code: "code",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Aplicaciones públicas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En HubSpot, una aplicación pública es un tipo de integración que puede instalarse en las cuentas de clientes o anunciarse para la venta en el Mercado de aplicaciones. Requiere autentificación a través de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), ". Una vez que un usuario ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#installing-apps",
        children: "instala"
      }), " tu aplicación en su cuenta de HubSpot, podrás hacer llamadas de API a esa cuenta usando un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "token de acceso de OAuth"
      }), ". Tu aplicación también aparecerá en la configuración de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Aplicaciones conectadas"
      }), " de la cuenta."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las aplicaciones conectadas también pueden aprovechar la posibilidad de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "suscribirse a cambios usando webhooks"
      }), " y creando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline",
        children: "eventos de línea de tiempo"
      }), " personalizados."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, aprenderás a hacer lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-public-app",
          children: "Crear una API pública"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#install-an-app",
          children: "Instalar una aplicación pública en una cuenta"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#manage-public-apps-in-hubspot",
          children: "Gestionar la aplicación, incluido el monitoreo del uso"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-a-verified-domain",
          children: "Agregar un dominio verificado a la aplicación"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una API pública"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando creas una aplicación en HubSpot, básicamente estás asociando una aplicación que hayas creado con una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "cuenta de desarrollador"
      }), ". Para comenzar a crear tu aplicación HubSpot:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de desarrollador de aplicaciones, ve a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones"
        }), " en la barra de navegación principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear aplicación"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A continuación, completa la información básica y la configuración de tu aplicación. Cuando los usuarios autentican tu aplicación con su cuenta de HubSpot, verán el nombre, la descripción, el logotipo y cualquier información de contacto de asistencia técnica que proporciones en esta página."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": el nombre de la aplicación se usará dondequiera que se muestre tu aplicación en HubSpot. Esto incluye la instalación de la aplicación, así como el pie de página de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Desarrollado por"
        }), " para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "tarjetas de CRM"
        }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/timeline",
          children: "eventos de la línea de tiempo"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/new_app_setup.png?width=600&name=new_app_setup.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Autenticación"
        }), " para ver tu ID de cliente y tu secreto de cliente, así como los alcances asignados de la aplicación. Necesitarás esta información para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "iniciar una conexión de OAuth"
        }), " entre tu aplicación y HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/app_auth_settings.png?width=600&name=app_auth_settings.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configurar alcances"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los alcances determinan los permisos de tu aplicación para acceder a fuentes de datos o herramientas en una cuenta que haya instalado tu aplicación. Los alcances que configures aparecerán como los parámetros de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "scope"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "optional_scope"
      }), " en una URL de instalación que luego puedes proporcionar a los usuarios."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Tipos de alcance"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Autenticación"
      }), ", hay tres tipos de alcances diferentes disponibles para que los configures. Debes especificar los alcances que tu aplicación requerirá para la instalación, pero también puedes especificar otros dos tipos de alcances: alcances condicionalmente requeridos y alcances opcionales."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Alcances requeridos:"
        }), " alcances que deben ser autorizados por el usuario ", (0, _jsxRuntime.jsx)("u", {
          children: "y"
        }), " deben estar presentes en el parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), " en la URL de instalación de tu aplicación para una instalación exitosa."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Alcances condicionalmente requeridos:"
        }), " alcances que deben ser autorizados por el usuario solo si están presentes en el parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), " en la URL de instalación de tu aplicación para una instalación exitosa.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Este tipo de alcances te permite ser flexible y proporcionar una URL de instalación separada para funciones o alcances en niveles que solo se requieren cuando los usuarios habilitan ciertas funciones en tu aplicación. Por ejemplo, podrías ofrecer dos URL de instalación a tus usuarios: una URL de instalación podría incluir el alcance condicionalmente requerido en el parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            }), " para usuarios con acceso a una característica, mientras que otra URL de instalación omite ese alcance en el parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            }), " para usuarios sin acceso."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si un alcance condicionalmente requerido está presente en la URL de instalación de tu aplicación y un usuario sin acceso a la función asociada intenta instalar tu aplicación utilizando esa URL, la instalación fallará."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Alcances opcionales:"
        }), " alcances que ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " son necesarios para instalar correctamente tu aplicación. Estos alcances se especifican en el parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), " en la URL de instalación de tu aplicación. Por ejemplo, si quieres que tu aplicación pueda obtener datos de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "objetos personalizados"
        }), " (que solo están disponibles para las cuentas de HubSpot ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), "), puedes agregar el alcance ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.custom.read"
        }), " como alcance opcional. Luego, si una cuenta tiene acceso a los objetos personalizados, se autorizará el alcance. De lo contrario, aún se podrá instalar la aplicación sin el alcance de los objetos personalizados."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Configurar los alcances públicos de tu aplicación"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para personalizar tu configuración de alcances y agregar nuevos alcances:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para configurar los alcances condicionalmente requeridos u opcionales, haz clic en para activar el botón ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Activar la configuración avanzada de alcances"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/turn-on-advanced-scope-settings-toggle-in-public-app-auth-tab.png",
        alt: "turn-on-advanced-scope-settings-toggle-in-public-app-auth-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " a partir del 21 de octubre de 2024, se requerirán configuraciones avanzadas de alcance para todas las aplicaciones. Más información sobre el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/advanced-auth-and-scope-settings-for-public-apps",
          children: "Changelog de desarrollador de HubSpot"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Alcances"
        }), ", haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Agregar alcance nuevo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el panel derecho, usa la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "barra de búsqueda"
        }), " para buscar un alcance y luego selecciona la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "casilla de verificación"
        }), " junto a cualquier alcance que desees que el usuario autorice. Si activaste la configuración avanzada del alcance, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "menú desplegable"
        }), " junto al alcance y selecciona un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tipo de alcance"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actualizar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/add-new-scope-panel-in-public-app-setup.png",
        alt: "add-new-scope-panel-in-public-app-setup"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revisa tus alcances configurados. Si activaste la configuración avanzada del alcance, puedes cambiar el tipo de cualquier alcance haciendo clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "menú desplegable"
        }), " que aparece a su lado. También puedes hacer clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eliminar"
        }), " para eliminar uno de los alcances de tu aplicación."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/review-new-scope-settings-in-public-app-setup.png",
        alt: "review-new-scope-settings-in-public-app-setup"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cuando hayas terminado de configurar la aplicación, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear aplicación"
        }), " en la parte inferior izquierda."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con tu aplicación creada, ahora puedes recorrer el proceso de instalación."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " se recomienda ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-a-verified-domain",
          children: "agregar un dominio verificado"
        }), " a la aplicación para agregar otro nivel de confianza para los usuarios que instalan tu aplicación. De lo contrario, la aplicación mostrará un banner que indica que la aplicación no está verificada."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Instalar una aplicación"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " antes de instalar la aplicación, ten en cuenta lo siguiente:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Una aplicación no aparecerá en la página ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Aplicaciones conectadas"
          }), " de una cuenta hasta que se creen tokens de acceso inicial y de actualización."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Solo los usuarios con acceso a los alcances requeridos o condicionalmente requeridos de una aplicación pueden instalarla."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las aplicaciones no se pueden instalar en las cuentas de desarrollador. Para probar tu aplicación, deberás crear una ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types",
            children: "cuenta de prueba"
          }), " en tu cuenta de desarrollador de aplicaciones e instalarla allí."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Instalar una aplicación puede dividirse en dos pasos: autorización y generación de tokens."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Autorizar tu aplicación con una cuenta de cliente"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para autorizar tu aplicación con una cuenta de HubSpot, deberás crear una URL de autorización. Haz esto al obtener el ID del cliente para tu aplicación e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "iniciar el proceso de OAuth"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Una vez que tu URL esté lista, ábrela en tu navegador para ver una lista de todas tus cuentas de HubSpot. Esto también es lo que verán los usuarios una vez que comienzas a dirigirlos a esta URL."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecciona la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "cuenta"
        }), " donde deseas instalar tu aplicación."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/select_account-1.webp",
        alt: "select_account-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Después de elegir una cuenta, recibirás una lista de alcances basados en los parámetros ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&scopes="
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&optional_scope="
        }), " que configuraste para la URL de autorización."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si incluyes un ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), " y la cuenta seleccionada no tiene acceso a é (como el alcance del contenido para una cuenta de CRM), no se incluirá en la lista."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Autorizar acceso"
        }), " para autorizar la conexión."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/approve_scopes-1.webp",
        alt: "approve_scopes-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Después de otorgar acceso, se te redireccionará según el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&redirect_uri="
        }), " en la URL de autorización original y un parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?code="
        }), " se anexará a la URL. Usa ese código en el siguiente paso para generar un token de acceso."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Generar los tokens OAuth iniciales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para generar tus tokens de acceso inicial y de actualización, necesitaras el código del parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?code="
      }), " de la URL de autorización, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "redirect_url"
      }), ", ID de cliente y secreto del cliente. Las instrucciones detalladas están ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que hayas autorizado tu aplicación y de que hayas generado el token inicial, la instalación estará completa. Se incluirá en la página ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/integrations-beta",
        children: "Aplicaciones conectadas"
      }), " y comenzarás a recibir solicitudes de búsqueda de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "webhook"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "Tarjetas de CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/connected_apps-1.webp",
        alt: "connected_apps-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gestionar aplicaciones públicas en HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Find an app's ID"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes encontrar el ID de una aplicación pública en tu cuenta de desarrollador de aplicaciones utilizando cualquiera de los siguientes métodos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de desarrollador, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones"
        }), " en la barra de navegación principal y luego ve el ID de la ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "aplicación que"
        }), " aparece debajo del nombre de tu aplicación."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/find-app-id.png",
        alt: "find-app-id"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de desarrollador, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones"
        }), " en la barra de navegación principal y luego haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " de la aplicación. En la página ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Información básica"
        }), ", haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Autenticación"
        }), " y luego ve el ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "ID de la aplicación"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/find-app-id-auth-settings.png",
        alt: "find-app-id-auth-settings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Monitorear del comportamiento de la aplicación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot registra todas las solicitudes realizadas desde o hacia una aplicación conectada, incluidas las solicitudes entrantes usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "un token de acceso OAuth"
      }), " o las solicitudes salientes de webhooks o tarjetas de CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ver este registro de solicitud:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de desarrollador, ve a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones"
        }), " en la barra de navegación principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " de la aplicación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el menú de la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Monitorización"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usa las ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "pestañas"
        }), " para ver los diferentes tipos de solicitudes que se realizan desde o hacia la aplicación. Mientras ves estos registros, puedes hacer clic en una solicitud individual para ver más información sobre ella, que incluye:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["para solicitudes ", (0, _jsxRuntime.jsx)("u", {
              children: "exitosas"
            }), ", el método de solicitud, la ruta y el tiempo de solicitud."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["para solicitudes ", (0, _jsxRuntime.jsx)("u", {
              children: "fallidas"
            }), ", información de error adicional, como los encabezados y el cuerpo de la respuesta."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/request_details.webp",
        alt: "request_details"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, obtén más información sobre cada pestaña de la página ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Monitorización"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Llamadas de API:"
        }), " la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Llamadas de API"
        }), " muestra todas las solicitudes realizadas a tu aplicación usando un token de acceso OAuth. Se puede filtrar por método HTTP, código de respuesta, período o URL de solicitud."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks:"
        }), " la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Webhooks"
        }), " muestra las solicitudes de HubSpot para cualquiera de las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks/overview",
          children: "suscripciones a webhook"
        }), " de tu aplicación. Filtrar por respuesta (incluyendo tiempos de espera y fallas de conexión), estado (exitoso, volverá a intentar o falla), tipo de suscripción, período de tiempo, intento, lote, evento o ID de cuenta."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " el ID de intento sería una combinación de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriptionId"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "eventId"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "attemptNumber"
        }), " de una solicitud específica."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Extensiones de CRM:"
        }), " la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Extensiones de CRM"
        }), " muestra las solicitudes de las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "tarjetas de CRM"
        }), " de su aplicación. Filtrar por tipo de objeto de extensión, tipo de objeto de CRM (contacto, empresa, ticket o negocio), tipo de error o advertencia, período de tiempo, ID de solicitud o ID de registro de CRM (es decir, un ID de contacto específico)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configuración de la aplicación:"
        }), " la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Configuración de la aplicación"
        }), " te permite configurar la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/create-an-app-settings-page",
          children: "página de configuración"
        }), " que viene con tu aplicación."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En cada pestaña, si se produjo algún evento asociado en los últimos 30 días (por ejemplo, se produjo un desencadenante de webhook o se realizó una llamada a la API), puedes hacer clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exportar registros"
      }), " para exportar los datos del evento asociado a un CSV:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "En el cuadro de diálogo, configura cuántos días de datos exportar (hasta 30 días)."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportar"
        }), ". Se enviará una notificación por correo electrónico a la dirección de correo asociada con tu usuario en la configuración de HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Agregar un dominio verificado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando los usuarios de HubSpot instalan una aplicación, consienten en dar acceso al desarrollador de la aplicación a los datos de su cuenta. La identidad y la reputación del desarrollador juegan un papel importante en la decisión de un usuario de continuar con la instalación. Para garantizar el pleno consentimiento del usuario al instalar una aplicación, HubSpot mostrará un mensaje en la pantalla de instalación de la aplicación para indicar el nivel de verificación de la aplicación y el anuncio en el mercado de aplicaciones:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Cuando una aplicación no tiene un dominio verificado, HubSpot mostrará un banner en la pantalla de instalación que dice que la aplicación no ha sido verificada."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/not-verified.png",
            alt: "not-verified"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cuando la aplicación tiene un dominio verificado pero no ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/listing-your-app",
            children: "aparece en el Mercado de aplicaciones"
          }), ", HubSpot mostrará el dominio verificado junto con un banner en la pantalla de instalación que dice que la aplicación no ha sido revisada o aprobada por HubSpot."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verified-not-listed.png",
        alt: "verified-not-listed"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Cuando una aplicación se anuncia en el mercado, pasando el proceso de revisión de aplicaciones de HubSpot, HubSpot no mostrará ninguno de los banners anteriores. No es necesario que verifiques el dominio si tu aplicación aparece en el Mercado de aplicaciones."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verified-and-listed.png",
            alt: "verified-and-listed"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Agregar un dominio verificado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para agregar un dominio verificado a la aplicación, primero deberás agregar el dominio a la configuración de la aplicación y luego agregar un registro TXT a la configuración de DNS del dominio:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de desarrollador de aplicaciones, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " de la aplicación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contacto y asistencia técnica"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el campo ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Dominio de la empresa"
        }), ", escribe tu dominio y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Guardar"
        }), ". Aparecerá un mensaje debajo del ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "dominio de la empresa"
        }), " indicando que el dominio aún no ha sido verificado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Verificar ahora"
        }), " para comenzar el proceso de verificación."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/domain-verification-for-app.png",
        alt: "domain-verification-for-app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el panel derecho, confirma que el dominio se ha introducido correctamente y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Siguiente"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Copia el valor de registro TXT requerido haciendo clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copiar"
          }), " en la columna ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Valor"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verify-app-domain-copy-value.png",
            alt: "verify-app-domain-copy-value"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "En tu proveedor de DNS, crea un registro TXT con el valor copiado. A continuación, aparecen instrucciones para algunos proveedores de DNS comunes:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.godaddy.com/help/add-a-txt-record-19232",
              children: "GoDaddy"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://my.bluehost.com/hosting/help/559#add",
              children: "BlueHost"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.namecheap.com/support/knowledgebase/article.aspx/317/2237",
              children: "Namecheap"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://support.cloudflare.com/hc/en-us/articles/360019093151-Managing-DNS-records-in-Cloudflare#h_60566325041543261564371",
              children: "Cloudflare"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://help.hover.com/hc/en-us/articles/217282457-How-to-Edit-DNS-records-A-AAAA-CNAME-MX-TXT-SRV-",
              children: "Hover"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.name.com/support/articles/115004972547-Adding-a-TXT-Record",
              children: "Name"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://help.uniteddomains.com/hc/en-us/articles/115000887125-How-to-set-up-a-TXT-record-on-a-domain-name",
              children: "United Domains"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Después de actualizar la configuración de tu DNS, vuelve a HubSpot y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Siguiente"
          }), " en el panel derecho. Los registros DNS pueden tardar hasta 48 horas en actualizarse, por lo que es posible que HubSpot no reconozca el cambio de inmediato. Puedes volver a esta pantalla en cualquier momento seleccionando ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Verificar ahora"
          }), " de nuevo en la ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "página de configuración"
          }), " Información de la empresa."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Una vez verificado, verás un indicador de estado de éxito en el campo ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Dominio de la empresa"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Domain%20verified__export.png",
        alt: "Domain verified__export"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Notas adicionales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Para garantizar la propiedad continua del dominio, HubSpot continuará verificando que el registro de TXT esté presente de forma regular. La advertencia de instalación volverá si el registro TXT se elimina o modifica."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Actualmente, solo puedes tener un dominio verificado por cuenta de desarrollador. Todas las aplicaciones en una cuenta comparten el dominio verificado. El dominio en la página de instalación se enlaza con tu dominio raíz."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si eliminas tu dominio verificado, todas las aplicaciones de tu cuenta de desarrollador volverán a recibir la advertencia de instalación. Puedes verificar otro dominio, pero el proceso tardará algunas horas."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}