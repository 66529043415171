"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77668268378;
const slug = exports.slug = 'guides/apps/authentication/intro-to-auth';
const title = exports.title = 'APIs da HubSpot | Métodos de autenticação na HubSpot';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Authentication methods on HubSpot';
const metaDescription = exports.metaDescription = 'As APIs da HubSpot possibilitam dois meios de autenticação: OAuth e chaves de API. Embora a maioria dos endpoints seja compatível com os dois métodos, em geral o OAuth é o método recomendado.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "oauth",
  "label": "OAuth",
  "parent": null
}, {
  "depth": 0,
  "id": "tokens-de-acesso-do-app-privado",
  "label": "Tokens de acesso do app privado",
  "parent": null
}, {
  "depth": 2,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": "tokens-de-acesso-do-app-privado"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      strong: "strong",
      a: "a",
      h1: "h1",
      h2: "h2",
      pre: "pre",
      code: "code",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " a partir de 30 de novembro de 2022, as chaves de API da HubSpot serão desativadas e não terão mais suporte. Continuar usando as chaves de API da HubSpot representará um risco de segurança para sua conta e dados. Durante esta fase, a HubSpot poderá desativar sua chave a qualquer momento."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Você deve autenticar usando um token de acesso de aplicativo privado ou OAuth. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "essa alteração"
        }), " e como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migrar uma integração de chave de API"
        }), " para usar um aplicativo privado."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Métodos de autenticação na HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Há duas maneiras de autenticar chamadas para as APIs do HubSpot: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " e tokens de acesso a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/private-apps",
        children: "aplicativos privados"
      }), ". Abaixo, saiba mais sobre cada método e como incluí-los em seu código para autorização."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estava usando anteriormente uma chave de API para autenticar, saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/migrate-an-api-key-integration-to-a-private-app",
        children: "migrar para o uso de um token de acesso de aplicativo privado"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " as integrações projetadas para uso de vários clientes ou em listagens no Marketplace de aplicativos devem ser criadas como um aplicativo que usa o protocolo OAuth da HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para fazer uma solicitação usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/oauth/tokens",
        children: "OAuth"
      }), ", inclua o token de acesso OAuth no cabeçalho da autorização:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "/~curl --header \"Authorization: Bearer C4d***sVq\"\nhttps://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tokens de acesso do app privado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Semelhante ao OAuth, para fazer uma solicitação usando um token de acesso a app privado, inclua o token no cabeçalho de autorização:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "/~curl --header \"Authorization: Bearer ***-***-*********-****-****-****-************\"\nhttps://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/working-with-oauth",
        children: "Trabalhar com o OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/oauth-quickstart-guide",
        children: "Guia de início rápido do OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/private-apps",
        children: "Apps privados"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}