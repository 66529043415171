"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611098;
const slug = exports.slug = 'guides/cms/content/data-driven-content/dynamic-pages/hubdb';
const title = exports.title = 'Construye páginas dinámicas utilizando HubDB';
const name = exports.name = 'Construye páginas dinámicas utilizando HubDB';
const metaDescription = exports.metaDescription = 'Aprende a crear páginas de sitio web dinámicas utilizando datos de HubDB.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-crear-una-tabla-de-hubdb",
  "label": "1. Crear una tabla de HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-crear-una-plantilla",
  "label": "2. Crear una plantilla",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-crear-la-p%C3%A1gina-din%C3%A1mica",
  "label": "3. Crear la página dinámica",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-ver-p%C3%A1ginas-en-vivo",
  "label": "4. Ver páginas en vivo",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-agregar-una-nueva-fila-de-tabla",
  "label": "5. Agregar una nueva fila de tabla",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-ver-datos-de-p%C3%A1gina-din%C3%A1mica",
  "label": "6. Ver datos de página dinámica",
  "parent": null
}, {
  "depth": 0,
  "id": "m%C3%A1s-tutoriales-enfocados-en-hubdb",
  "label": "Más tutoriales enfocados en HubDB",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      code: "code",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Construye páginas dinámicas utilizando HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "páginas dinámicas"
      }), " son páginas CMS que obtienen su contenido de una fuente de datos estructurada. Según cómo configures tu página dinámica, HubSpot extraerá datos de la fuente seleccionada y creará automáticamente un conjunto de páginas. Esto incluye una página de listado que muestra resúmenes de los datos y páginas individuales para cada entrada de fuente de datos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "tabla HubDB"
      }), " como fuente de datos, puedes crear una página dinámica que luego genera una página para cada fila de la tabla. Cada página dinámica incluye su propia URL optimizada para motores de búsqueda y ofrece analíticas específicas."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Puedes crear hasta 10 páginas dinámicas por fuente de datos. Para las páginas dinámicas de HubDB, esto significa que hasta 10 páginas dinámicas pueden usar la misma tabla de HubDB. El número de filas de la tabla (y, por tanto, de páginas secundarias) no se tiene en cuenta en este límite. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/storage/hubdb/overview#hubdb-technical-limits",
            children: "los límites técnicos de HuDB"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las páginas dinámicas de HubDB están limitadas a 50.000 actualizaciones del índice ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/content-search",
            children: "de búsqueda de contenido"
          }), " por cuenta por día. Cualquier actualización de estas páginas que supere este límite no se reflejará en la búsqueda de contenido de ese día."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este tutorial explica cómo crear un conjunto de páginas dinámicas utilizando HubDB como fuente de datos. Para seguir este tutorial, necesitarás:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Content Hub"
          }), "Pro"]
        }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Algunos conocimientos previos de HTML y CSS"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Puedes obtener más información sobre la creación de páginas de CMS basadas en datos en el curso de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "Contenido basado en datos de CMS"
        }), " de HubSpot Academy."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Crear una tabla de HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para crear una nueva tabla de HubDB:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contenido"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear tabla"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el cuadro de diálogo, introduce la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "etiqueta"
        }), " y el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " de la tabla y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al crear la tabla, puedes configurarla para usarla para contenido de páginas dinámicas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Acciones"
        }), " y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Administrar configuración"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el panel derecho, haz clic para activar el interruptor ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Activar la creación de páginas dinámicas utilizando datos de filas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Opcionalmente, puedes seleccionar la metadescripción, la imagen destacada y la URL canónica de las páginas dinámicas individuales. Si dejas estos valores vacíos, cada página heredará los valores respectivos de su página principal."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " para que una página utilice los valores de las columnas de metadescripción, imagen destacada y URL canónica, la página debe incluir las siguientes variables ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "page_meta"
        }), "L en lugar de las variables ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), ":"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.meta_description}}"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.featured_image_URL}}"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.canonical_url}}"
          })
        }), "\n"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Por ejemplo, las plantillas de HubSpot extraen su metadescripción de la etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{content.meta_description}}"
        }), " de forma predeterminada. En cambio, deberás usar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{page_meta.meta_description}}"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Guardar"
        }), " para guardar tus cambios."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-table-settings-sidebar-save.png",
        alt: "hubdb-table-settings-sidebar-save"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de actualizar la configuración de la tabla, se agregarán a la tabla las columnas ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Título de página"
      }), " y ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ruta de página"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**Título de página: **el nombre de esta página como se ve en la etiqueta de título HTML."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ruta de página:"
        }), " el último segmento de la URL de la página dinámica creada por cada fila de la tabla."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La siguiente tabla es un ejemplo modelado a partir de una página «Acerca de nosotros» para los miembros de un equipo ejecutivo de una empresa. Esta tabla se utilizará para crear páginas dinámicas con rutas que terminan en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cfo-harlow"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ceo-jeff"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cto-bristow"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pd-hugo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Título de la página"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ruta de la página"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Rol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Biografía"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CFO Harlow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cfo-harlow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CFO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Harlow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Este es Harlow, que generalmente economiza en todo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CEO Jeff"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ceo-jeff"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CEO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Jeff"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Jeff es el CEO, lo que significa que generalmente maneja las cosas por aquí."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTO Bristow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cto-bristow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CTO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bristow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Este es nuestro CTO, Bristow, a quien le gusta jugar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Chief PD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pd-hugo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CPD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hugo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hugo, nuestro jefe de diseño de productos, disfruta diseñando productos."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-hubdb-table.png",
        alt: "example-hubdb-table"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " aunque debes introducir las rutas de página en minúsculas, las URL resultantes no distinguen entre mayúsculas y minúsculas. En el ejemplo anterior, cuando alguien navega hasta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/CEO-Jeff"
        }), " verá la misma página que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/ceo-jeff"
        }), ", en lugar de un error 404."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando estés listo para usar los datos de la tabla para comenzar a crear tus páginas, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "publicar"
      }), " en la parte superior derecha."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Crear una plantilla"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, crearás una plantilla tanto para la página de catálogo como para las páginas de detalles individuales de cada fila, de manera similar a cómo se pueden usar las plantillas de blog para las páginas de contenido y de detalles de publicaciones. Para crear la plantilla de página:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contenido"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Administrador de Diseño"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el menú de la barra lateral izquierda, navega hasta la carpeta en la que deseas crear la plantilla. Para crear una nueva carpeta, en la parte superior izquierda haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Archivo"
        }), " y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nueva carpeta"
        }), ". Luego, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Archivo"
        }), " y selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nuevo archivo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el cuadro de diálogo, usa el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "menú desplegable"
        }), " para seleccionar ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HTML + HubL"
        }), " como tipo de archivo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haga clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Siguiente"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/create-html-hubl-template.jpg",
        alt: "Creación de una nueva plantilla HTML + HubL"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el campo ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nombre de archivo"
        }), ", escribe el nombre de la plantilla."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Ubicación del archivo"
        }), ", puedes cambiar la ubicación de la plantilla en el administrador de diseño haciendo clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cambiar"
        }), (0, _jsxRuntime.jsx)(_components.em, {
          children: "."
        }), " Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear"
        }), " para crear la plantilla."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando una página dinámica se configura para usar esta plantilla y el final de la URL coincide con la columna ruta, puedes acceder a las variables", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_table_id"
      }), " en la plantilla. Por ejemplo, para crear una página de perfil de ejecutivo, el siguiente código muestra cómo puedes usar los campos de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), " para mostrar información de un ejecutivo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_name"
        }), ": el ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Título de página"
        }), " asociado para la fila de HubDB."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": el nombre del ejecutivo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "role"
        }), ": el rol del ejecutivo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n    <h1>{{ dynamic_page_hubdb_row.hs_name }}</h1>\n    <h2>{{ dynamic_page_hubdb_row.name }}</h2>\n    <h3>{{ dynamic_page_hubdb_row.role }}</h3>\n    <p>{{dynamic_page_hubdb_row.bio}}</p>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, puedes agregar el manejo para el caso en el que alguien cargue tu página dinámica (sin ninguna ruta adicional de la tabla). Por lo general, esto se usa como página de contenido, para enumerar enlaces a las páginas de las filas en tu tabla de HubDB. Reemplaza tu código con:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n    <h1>{{ dynamic_page_hubdb_row.hs_name }}</h1>\n    <h2>{{ dynamic_page_hubdb_row.name }}</h2>\n    <h3>{{ dynamic_page_hubdb_row.role }}</h3>\n    <p>{{dynamic_page_hubdb_row.bio}}</p>\n{% elif dynamic_page_hubdb_table_id %}\n    <ul>\n    {% for row in hubdb_table_rows(dynamic_page_hubdb_table_id) %}\n        <li><a href=\"{{ request.path }}/{{ row.hs_path }}\">{{ row.hs_name }}</a></li>\n    {% endfor %}\n    </ul>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El código que está dentro del bloque ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), " se repite sobre todas las filas en la tabla del ejecutivo y muestra cada entrada en una lista, con un enlace a su ruta única."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el administrador de diseño, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vista preliminar"
        }), " para obtener una vista preliminar de la plantilla. La vista preliminar de esta plantilla estará en blanco porque se basa en el contexto de la página para establecer las variables ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_row"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_table_id"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Para probar tu código en el nivel de plantilla, agrega el siguiente código temporal a la parte superior de la plantilla, asegurándote de eliminarlo antes de publicarlo:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set dynamic_page_hubdb_table_id = <YOUR_HUBDB_TABLE_ID> %}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Después de agregar el código anterior, tu plantilla ahora debería representar una lista de hipervínculos, extrayendo datos de la tabla HubDB que creaste."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-template-preview.png",
        alt: "hubdb-template-preview"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Después de obtener una vista preliminar de la plantilla, elimina el código temporal anterior. Luego, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publicar"
        }), " en la parte superior derecha para que esté disponible para crear páginas."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Crear la página dinámica"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para crear una página dinámica desde tu plantilla:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Con tu nueva plantilla abierta en el administrador de diseño, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Acciones"
          }), " en la parte superior del buscador y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Crear página"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/create-page-from-design-manager.png",
            alt: "create-page-from-design-manager"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el cuadro de diálogo, selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Página web"
          }), " y luego escribe un ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre de página"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Crear página"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la parte superior del editor de página, haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Configuración"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el campo ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Título de la página"
          }), ", escribe un nombre de página, que puedes usar más tarde para buscar análisis de tráfico."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el campo ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), ", escribe una ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/executives"
          }), ". El URL será la URL base de tu página dinámica."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Opciones avanzadas"
          }), " para ver opciones adicionales."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Desplázate hacia abajo hasta la sección ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Páginas dinámicas"
          }), " y luego haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Fuentes de datos"
          }), ". Selecciona la ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "tabla de HubDB"
          }), " que creaste."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/page%20editor%20hubdb%20dynamic%20.png",
        alt: "Opciones avanzadas en la configuración de la página para enlazar con la tabla de HubDB"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cuando hayas terminado, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publicar"
        }), " en la parte superior derecha. Tus páginas ya están listas para verlas."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Ver páginas en vivo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ahora puedes visitar la nueva página dinámica y todas sus rutas, según lo definido por tu tabla de HubDB."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navega a la página de listado dinámico en la URL que estableciste en el editor de página. Este tutorial utiliza", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/executives"
        }), " para su URL de página dinámica, por lo que en ese caso navegarías a: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.yourdomain.com/executives"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Desde la página de listado, haz clic en los ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombres"
        }), " de la lista con viñetas para ver la página de detalles de ese ejecutivo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/2022-11-28_15-55-47%20(1).gif",
        alt: "2022-11-28_15-55-47 (1)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Agregar una nueva fila de tabla"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con tu página dinámica cargando datos de HubDB, navega a la tabla y agrega una nueva fila. Después de publicar la tabla, verás que tu página en vivo se actualiza dinámicamente con los nuevos datos de HubDB."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, navega hasta ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Archivos y plantillas"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " de la tabla que has creado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Agregar fila"
        }), " y luego completa cada columna. A continuación se muestra un conjunto de datos de muestra."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Título de la página"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ruta de la página"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Rol"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Biografía"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CMO Hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cmo-hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hobbes es nuestro entusiasta de los gatos."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publicar"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En otra pestaña, vuelve a la página de listado (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/executives"
        }), " en este ejemplo). Ahora deberías ver al nuevo ejecutivo aparecer en la página de listado, y hacer clic en su nombre revelará su página de detalles."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Ver datos de página dinámica"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que haya visitas a tu página dinámica, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/analyze-performance-for-individual-pages-and-blog-posts",
        children: "medir el rendimiento de la página individual "
      }), "o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-your-site-traffic-with-the-traffic-analytics-tool",
        children: "ver todos los datos de la página en la herramienta de analíticas de tráfico"
      }), ". A pesar de que las páginas ejecutivas individuales se construyen a partir de la misma página dinámica, los datos de tráfico, como las vistas de página, se atribuyen a cada página."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ver los datos de visita de tu página en HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En tu cuenta de HubSpot, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Informes"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Analíticas de marketing"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Análisis del tráfico web"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Páginas"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Ve la tabla para ver los datos de tráfico de las páginas principales y secundarias individuales. Las páginas secundarias se indicarán con ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "iconos de flecha >"
          }), " para mostrar su relación con las páginas principales."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-traffic-data-2.png",
            alt: "example-traffic-data-2"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ten en cuenta lo siguiente si no estás viendo los datos de tráfico que esperas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si has ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/exclude-traffic-from-your-site-analytics",
          children: "excluido las direcciones IP en la configuración del informe de la cuenta"
        }), ", asegúrate de acceder a las páginas desde fuera de la red para que se registren las vistas de página."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Puede tardar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/how-often-do-analytics-in-hubspot-update",
          children: "hasta 40 minutos"
        }), " para que aparezcan nuevos datos de página en HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Más tutoriales enfocados en HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Cómo unir varias tablas de HubDB"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "Cómo crear una página dinámica para miembros del equipo con HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "Cómo agregar videos a páginas dinámicas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "Cómo crear páginas dinámicas multinivel usando HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Construyamos una página con un mapa utilizando HubDB"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}