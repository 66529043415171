"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32603668762;
const slug = exports.slug = 'guides/api/cms/blogs/blog-tags';
const title = exports.title = 'CMS API | Blog Tags';
const name = exports.name = 'CMS API | Blog Tags';
const metaDescription = exports.metaDescription = 'Perform create, read, update, and delete actions on CMS Hub blog tags using the blog tags API.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "changes-in-v3",
  "label": "Changes in V3",
  "parent": null
}, {
  "depth": 0,
  "id": "search-blog-tags",
  "label": "Search blog tags",
  "parent": null
}, {
  "depth": 1,
  "id": "filtering",
  "label": "Filtering",
  "parent": "search-blog-tags"
}, {
  "depth": 1,
  "id": "sorting-and-paginating",
  "label": "Sorting and paginating",
  "parent": "search-blog-tags"
}, {
  "depth": 0,
  "id": "create-blog-tags",
  "label": "Create blog tags",
  "parent": null
}, {
  "depth": 0,
  "id": "edit-blog-tags",
  "label": "Edit blog tags",
  "parent": null
}, {
  "depth": 0,
  "id": "multi-language-management",
  "label": "Multi-language management",
  "parent": null
}, {
  "depth": 1,
  "id": "create-a-new-language-variant",
  "label": "Create a new language variant",
  "parent": "multi-language-management"
}, {
  "depth": 1,
  "id": "attach-a-blog-tag-to-an-existing-mutli-language-group",
  "label": "Attach a blog tag to an existing mutli-language group",
  "parent": "multi-language-management"
}, {
  "depth": 1,
  "id": "detach-a-blog-tag-from-a-multi-language-group",
  "label": "Detach a blog tag from a multi-language group",
  "parent": "multi-language-management"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      em: "em",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Blog Tags"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the blog tags API to manage tags for your blog posts. Learn more about how to create and maintain your blog on the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website/topics#blog",
        children: "HubSpot Knowledge Base."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Changes in V3"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "description"
      }), " property has been deprecated and will not be included in the response of any of the V3 endpoints."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Search blog tags"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve blog tags, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/blogs/tags"
      }), ". You can filter and sort the tags returned in the response using the operators and properties below. You can also use the standard filters using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdAt"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "updatedAt"
      }), " dates."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Filtering"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Provide any filters as query parameters in your request by adding the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "property name"
      }), ", followed by ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "two underscore characters"
      }), ", then include the associated ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "operator"
      }), " as a suffix. For example, you can filter the results to only include blog tags where the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " property contains the word ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "marketing"
      }), " using the parameter: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&name__icontains=marketing"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can include any number of filters as query parameters in the request URL. All filters are ANDed together. ORing filters is not currently supported."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The available filter types are listed below:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operator"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eq"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Equal to"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ne"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Not equal to"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contains"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icontains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contains (case sensitive)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Less than"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Less than or equal to"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Greater than"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Greater than or equal to"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Null"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Not null"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Like"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Not like"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startswith"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Value starts with"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "In"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The table below lists the properties that can be filtered on, along with their supported filter types."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Supported filters"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq, in"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq, contains"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq, gt, gte, lt, lte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deletedAt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq, gt, gte, lt, lte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdById"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "updatedById"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "in, not_null"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "translatedFromId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "null, not_null"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To filter blog tags based on a multi-language group, you can include one of the query parameters from the table below. For example, to get blog tags from the German variation of your blog, you'd include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "language__in=de"
      }), " as a query parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " languages with locales (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "en-us"
        }), ") are not supported with the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "language"
        }), " filter."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sorting and paginating"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can provide sorting and pagination options as query parameters. Specify the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "property name"
      }), " as the value to the sort query parameter to return the blog tags in the natural order of that property. You can reverse the sorting order by including a dash character before the property name (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort=-createdAt"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By combining query parameters for filtering, sorting, and paging, you can retrieve blog tags that match more advanced search criteria. For example, the request below fetches blog tags that have a language assigned, ordered by the most recently updated. Including the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "limit"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " parameters below returns the second page of results."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl\nhttps://api.hubapi.com/cms/v3/blogs/tags?sort=-updatedAt&&language__not_null&limit=10&offset=10 \\\n  --request POST \\\n  --header \"Content-Type: application/json\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create blog tags"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a blog tag, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/blog/posts"
      }), " and include a JSON payload that represents the blog tag model, as shown in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/cms/blogs/blog-tags",
        children: "endpoints reference documentation"
      }), ". The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " field is required when creating a blog tag. To set the URL of a blog tag listing page, you must include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "slug"
      }), " field in your request."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Edit blog tags"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update a blog tag, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/blog/posts/{objectId}"
      }), " where ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " is the ID of the tag you want to update. In your request, include a JSON payload should include the blog tag model, as shown in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/cms/blogs/blog-tags",
        children: "endpoints reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Multi-language management"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To help you maintain blog tags across multiple languages, HubSpot's CMS allows you to group together blog tags of language variants of the same content. A tag with a language set may only be used on blog posts of the same language. Tags that do not have a language set are considered global and may be used on all blog posts."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To learn more about working with multi-language blog tags, check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/blog/create-a-multi-language-blog#create-blog-authors-in-multiple-languages",
        children: "this Knowledge Base article"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a new language variant"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a new language variant for an existing blog tag, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/multi-language/create-language-variant"
      }), " and include a JSON payload containing the ID of the blog tag to clone and the language identifier of the new variant."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Attach a blog tag to an existing mutli-language group"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add a blog tag to an existing multi-language group, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/multi-language/attach-to-lang-group"
      }), " and include a JSON payload containing the ID of the target blog tag, the language identifier of the blog tag being added, and the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primaryId"
      }), " of the blog tag designated as the primary blog tag in the target multi-language group."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Detach a blog tag from a multi-language group"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove a blog tag from a multi-language group, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/multi-language/detach-from-lang-group"
      }), " and include a JSON payload containing the ID of the target blog tag."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}