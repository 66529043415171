"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 69236691992;
const slug = exports.slug = 'guides/apps/public-apps/create-an-app-settings-page';
const title = exports.title = 'Developer tools | Create a settings page for your app';
const name = exports.name = 'vNext Docs DP - Create a settings page for your app';
const metaDescription = exports.metaDescription = 'Learn how to create a settings page for your app.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "set-up-the-account-component",
  "label": "Set up the account component",
  "parent": null
}, {
  "depth": 0,
  "id": "set-up-the-control-component",
  "label": "Set up the control component",
  "parent": null
}, {
  "depth": 1,
  "id": "add-a-button-control-type",
  "label": "Add a button control type",
  "parent": "set-up-the-control-component"
}, {
  "depth": 1,
  "id": "open-an-iframe-with-a-button-control-type",
  "label": "Open an iframe with a button control type",
  "parent": "set-up-the-control-component"
}, {
  "depth": 1,
  "id": "add-a-toggle-control-type",
  "label": "Add a toggle control type",
  "parent": "set-up-the-control-component"
}, {
  "depth": 1,
  "id": "add-a-dropdown-control-type",
  "label": "Add a dropdown control type",
  "parent": "set-up-the-control-component"
}, {
  "depth": 0,
  "id": "set-up-feature-cards",
  "label": "Set up feature cards",
  "parent": null
}, {
  "depth": 0,
  "id": "restore-a-previous-version-of-an-app-settings-page",
  "label": "Restore a previous version of an app settings page",
  "parent": null
}, {
  "depth": 0,
  "id": "test-your-app-settings-page",
  "label": "Test your app settings page",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      em: "em",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Create a settings page for your app"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Create a settings page for your public app to give users more control over how they use your app in their HubSpot account. Developers can create a settings page for their app using the settings builder so that users can access the settings from the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/connect-apps-to-hubspot",
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Connected Apps"
        })
      }), " page in HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create the app settings page:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "developer account"
        }), ", click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "app"
        }), " you want to create a settings page for."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App settings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To create a settings page from a template, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Build from a template"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To create a settings page from scratch, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Start from scratch"
        }), ". You'll then be brought to the app settings page builder."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/app-settings-intro.png",
        alt: "app-settings-intro"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you select the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Build from a template"
        }), " option, ensure that all placeholder control component URLs are updated to your own API endpoints."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are two tabs on the settings page that you can customize:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "General settings"
        }), " tab, users can interact with selected components such as buttons and toggles to configure their app settings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Feature discovery"
        }), " tab, add cards highlighting your app’s features, explain how the features work, and link to further documentation or resources."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The app settings builder is organized around three sections: accounts, controls, and feature cards."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn how to set up the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "An account component to enable users to configure user-specific settings."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Control components to enable users to configure settings with buttons, dropdown menus, and more."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Feature cards to provide context to users as they configure their settings."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set up the account component"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "App settings can be uniquely configured for each user. The account component is a dropdown menu of all users in the connected HubSpot account. When viewing the settings page, a user can select any user in the account to view and set user-specific settings for the app."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the account component is required and only one component can be added per app."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For an account component to work:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "That account component must have a URL for fetching account data."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The account data fetched from the URL must include a list of connected users for the integration."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "accountID"
        }), " returned to HubSpot is what’s passed on to the control and feature card components so you’ll know which account HubSpot is interacting with."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/app-settings-accounts.png",
        alt: "app-settings-accounts"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "account"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "URL"
      }), " is required to populate a list of user accounts on the customer’s app setting page. Users can then pick any of the accounts listed. Whichever account is selected will be sent on future action calls for toggles, buttons, and other action types. This allows the use of multiple account configurations in a single account that installs your app. You should use all the parameters HubSpot sends to determine which account you are making future changes for."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot will send a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " header to verify that the request came from HubSpot. Read more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "request signatures"
      }), " for details."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/account-settings-url.png",
        alt: "account-settings-url"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add an account component:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create an endpoint that’s configured to handle either a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request from HubSpot. This will be called to populate a list of user accounts on the customer’s app settings page."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you select ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " as the HTTP method for your endpoint, the fields will be included as query params. For example:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "https://api.hubapi.com/application/settings/test/v1/actions/accounts-fetch?actionType=ACCOUNTS_FETCH&appId=999&portalId=1234&userId=1001&userEmail=test%40example.com\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you select ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " as the HTTP method, the fields will be included in the request body as a JSON object. For example:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Request body for POST request to /settings-account-fetch\n{\n  “actionType”: “ACCOUNTS_FETCH”,\n  “appId”: 123,\n  “portalId”: 456\n  “userId”: 1\n  “userEmail”: “test@example.com”,\n }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the request handler for your app’s endpoint, you should return a JSON object to HubSpot that includes a response field, which contains the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "accountId"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "accountName"
        }), " of the user, along with an optional ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "accountLogoUrl"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"response\": {\n    \"accounts\": [ # Object[] REQUIRED\n      {\n        \"accountId\": \"abc123\", # String REQUIRED\n        \"accountName\": \"Joe Cool\", # String REQUIRED\n        \"accountLogoUrl\": # Url OPTIONAL\n      }\n    ]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set up the control component"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can set up controls for users such as buttons, toggles, or a dropdown menu to manage general app settings and functions in the control component. Controls are optional. If you choose to add a control, each control must contain a short header and description describing its purpose or function."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add a component, populate the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Header"
        }), ": what the control does."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Description"
        }), ": a description of the control."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Control type"
        }), ": select from three control types: button, toggle, or dropdown menu."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot will send a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " header to verify that the request came from HubSpot. Read more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "request signatures"
      }), " for details."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will attempt to parse responses to actions as JSON and look for a message property. This message will be displayed to the user on either success or failure. Response status code of 2xx will show a success message and status codes of 4xx or 5xx will show an error message."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "An example response may look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example GET to https://api.hubapi.com/application/settings/test/v1/actions/toggle-update\n\n{\n  \"message\": \"You have successfully enabled your data sync\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/app-settings-control.png",
        alt: "app-settings-control"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add a button control type"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use a button to initiate an action via a call to your API endpoint. You will supply the API endpoint and choose the request method (either ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "). While no data is returned, HubSpot will communicate the resulting success, failure, or timeout status of the action to the customer. You should use all parameters HubSpot sends to determine which account you are making changes for."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add a button control type::"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "button"
        }), " control type."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configure button behavior"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hit an external endpoint"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Button text"
        }), " field, enter text for the button. This should tell the customer what it does."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create an endpoint that’s configured to handle either a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PUT"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DELETE"
        }), " request from HubSpot. HubSpot will provide the associated identifying fields for the customer based on the HTTP method you select when configuring your button."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The fields will be included in the request body as a JSON object. For example:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "curl -i -X POST \\\n -H 'Content-Type: application/json' \\\n -d '\n{\n \"actionType\": \"BUTTON_UPDATE\",\n \"appId\": 999,\n \"portalId\": 1234,\n \"userId\": 1001,\n \"userEmail\": \"jondoe@example.com\",\n \"accountId\": \"jondoe@example.com\"\n}\n' \\\n'https://api.hubapi.com/application/settings/test/v1/actions/button-update'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the request handler for your app’s endpoint, you should return a JSON object to HubSpot. This should include a response field along with an optional message to the user. For example:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"response\": null, # Ignored for beta\n  \"message\": \"Custom message to user\" # Optional\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Open an iframe with a button control type"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also use a button to open a modal displaying an iframe. To do so:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Select a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "button"
          }), " control type."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Configure button behavior"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Open an external URL within an iframe"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Button text"
          }), " field, enter text for the button. This should tell the customer what it does."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "HTTP Method"
          }), " (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "GET"
          }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "POST"
          }), ")."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Endpoint URL"
          }), ". ", (0, _jsxRuntime.jsx)("u", {
            children: "Do not"
          }), " input the iframe URL."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If you select ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "GET"
          }), " as the HTTP method for your endpoint, the fields will be included as query parameters. For example:"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "https://api.hubapi.com/application/settings/test/v1/actions/iframe-fetch?actionType=IFRAME_FETCH&appId=999&portalId=1234&userId=1001&userEmail=test%40example.com&accountId=joedoe@example.com\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you select ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " as the HTTP method, the fields will be included in the request body as a JSON object. For example:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "curl -i -X POST \\\n -H 'Content-Type: application/json' \\\n -d '\n{\n \"actionType\": \"IFRAME_FETCH\",\n \"appId\": 999,\n \"portalId\": 1234,\n \"userId\": 1001,\n \"userEmail\": \"jondoe@example.com\",\n \"accountId\": \"jondoe@example.com\"\n}\n' \\\n'https://api.hubapi.com/application/settings/test/v1/actions/iframe-fetch'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the request handler for your app’s endpoint, you should return a JSON object to HubSpot. This should include a response field containing the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "iframeURL"
        }), ":"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"response\": { \"iframeUrl\": \"https://en.wikipedia.org/wiki/Flywheel\" }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "All iframe modals will open from within your app’s settings page."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add a toggle control type"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The toggle control type provides users with a toggle option that they can turn on or off. HubSpot retrieves a toggle’s current state from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TOGGLE_FETCH"
      }), " URL. When a user clicks the toggle, HubSpot calls the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TOGGLE_UPDATE"
      }), " URL to send the toggle’s new state. Other identifiers such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "accountId"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), " are also passed – this allows you to identify the user triggering the toggle."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add a toggle:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Toggle"
        }), " control type."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configure toggle behavior"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Toggle settings"
        }), " pop-up, set up the toggle status and update toggle sections."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you’re fetching the toggle status:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Create an endpoint that’s configured to handle either a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " request from HubSpot, which will be called to fetch the existing toggle state for a customer when they visit the settings page for your app."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you select ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " as the HTTP method for your endpoint, the fields will be included as query parameters. For example:"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "https://api.hubapi.com/application/settings/test/v1/actions/toggle-fetch?actionType=TOGGLE_FETCH&appId=999&portalId=1234&userId=1001&userEmail=test%40example.com&accountId=jondoe%40example.com\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you select ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " as the HTTP method, the fields will be included in the request body as a JSON object. For example:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "curl -i -X POST \\\n -H 'Content-Type: application/json' \\\n -d '\n{\n \"actionType\": \"TOGGLE_FETCH\",\n \"appId\": 999,\n \"portalId\": 1234,\n \"userId\": 1001,\n \"userEmail\": \"jondoe@example.com\",\n \"accountId\": \"jondoe@example.com\",\n \"enabled\": false\n}\n' \\\n'https://api.hubapi.com/application/settings/test/v1/actions/toggle-fetch'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the request handler for your app’s endpoint, you should return a JSON object to HubSpot that includes a response field, which contains the current enabled state of the toggle as a boolean, along with an optional message to the user. For example:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"response\": { \"enabled\": true },\n  \"message\": \"Optional custom message to user\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To configure the toggle when it’s turned on or off:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Create an endpoint that’s configured to handle either a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PUT"
            }), " request from HubSpot, which will be called when the toggle is turned on or off."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "The fields will be included in the request body as a JSON object. For example:"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "curl -i -X POST \\\n -H 'Content-Type: application/json' \\\n -d '\n{\n \"actionType\": \"TOGGLE_UPDATE\",\n \"appId\": 999,\n \"portalId\": 1234,\n \"userId\": 1001,\n \"userEmail\": \"jondoe@example.com\",\n \"accountId\": \"jondoe@example.com\",\n \"enabled\": false\n}\n' \\\n'https://api.hubapi.com/application/settings/test/v1/actions/toggle-update'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the request handler for your app’s endpoint, you should return a JSON object to HubSpot that includes a response field, which contains the current enabled state of the toggle as a boolean, along with an optional message to the user. For example:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"response\": {\"enabled\": false}, # Boolean REQUIRED\n  \"message\": \"Custom message to user\" # Optional\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add a dropdown control type"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The dropdown control type provides the customer with a list of options they can select from. HubSpot retrieves the list from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPDOWN_FETCH"
      }), " URL. Your API may also return a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "selectedOption"
      }), " field to display which option the user has selected, as well as a placeholder field signifying what text to display when no option is selected. By passing the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "accountId"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), ", and other identifiers, you can identify which user triggered the control."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When a user clicks on an option in the dropdown menu, HubSpot calls the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPDOWN_UPDATE"
      }), " URL to send the same identifiers along with the selected option."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add a dropdown menu:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "dropdown"
          }), " control type."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Configure dropdown behavior"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Dropdown settings"
          }), " pop-up box, configure the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Dropdown fetch"
          }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Dropdown update"
          }), " sections."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "To fetch the list of dropdown options:"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Create an endpoint that’s configured to handle either a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "GET"
          }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "POST"
          }), " request from HubSpot, which will be called to fetch the list of dropdown options from the app settings page."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If you select ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "GET"
          }), " as the HTTP method for your endpoint, the fields will be included as query parameters. For example:"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// GET request to /dropdown-fetch\n\nhttps://api.hubapi.com/application/settings/test/v1/actions/dropdown-fetch?actionType=DROPDOWN_FETCH&appId=123&portalId=456&userId=1&userEmail=jkurien@hubspot.com&accountId=jkurien@hubspot.com\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you select ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " as the HTTP method, the fields will be included in the request body as a JSON object. For example:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "curl -i -X POST \\\n -H 'Content-Type: application/json' \\\n -d '\n{\n \"actionType\": \"DROPDOWN_FETCH\",\n \"appId\": 999,\n \"portalId\": 1234,\n \"userId\": 1001,\n \"userEmail\": \"jondoe@example.com\",\n \"accountId\": \"jondoe@example.com\",\n \"enabled\": false\n}\n' \\\n'https://api.hubapi.com/application/settings/test/v1/actions/dropdown-fetch'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the request handler for your app’s endpoint, you should return a JSON object to HubSpot that includes a response field, which contains the current enabled state of the toggle as a boolean, along with an optional message to the user. For example:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"response\": {\n    \"options\": [\n      {\n        \"text\": \"Option 1\",\n        \"value\": \"option1\"\n      },\n      {\n        \"text\": \"Option 2\",\n        \"value\": \"option2\"\n      }\n    ],\n    \"selectedOption\": \"option2\" \t\t# optional\n    \"placeholder\": \"Choose an Option\"\t# optional\n  },\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To configure what happens when an option is selected from the dropdown menu:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Create an endpoint that’s configured to handle either a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PUT"
            }), " request from HubSpot. The fields will be included in the body as a JSON object:"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "curl -i -X POST \\\n -H 'Content-Type: application/json' \\\n -d '\n{\n \"actionType\": \"DROPDOWN_UPDATE\",\n \"appId\": 999,\n \"portalId\": 1234,\n \"userId\": 1001,\n \"userEmail\": \"jondoe@example.com\",\n \"accountId\": \"jondoe@example.com\",\n \"selectedOption\": \"option1\"\n}\n' \\\n'https://api.hubapi.com/application/settings/test/v1/actions/dropdown-update'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the request handler for your app’s endpoint, you should return a JSON object to HubSpot that includes a response field, which contains the current enabled state of the toggle as a boolean, along with an optional message to the user. For example:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"response\": {\n    \"actionType\": \"DROPDOWN_UPDATE\",\n    \"selectedOption\": \"option2\"\n    \"message\": \"null\"\n  },\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set up feature cards"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use feature cards to introduce, describe, and help users set up app features. You can add up to ", (0, _jsxRuntime.jsx)("u", {
        children: "six feature cards"
      }), " per app."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add a feature card:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Set up the following fields for the card"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Card header:"
            }), " enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "header"
            }), " – this is the title of a feature."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            }), ": enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "description"
            }), " for the feature."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "CTA button text"
            }), ": enter ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "text"
            }), " for the CTA button."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "CTA button URL"
            }), ": enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "URL."
            }), " It is recommended to have the CTA direct users to where the feature appears in HubSpot."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Illustration:"
            }), " select an illustration for the feature card."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Add image or video:"
            }), " provide a preview carousel of the feature to your users.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Upload an image or video file. You can upload up to six images or videos."
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Add a ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "description"
                }), " of the image or video."]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Once you’ve set up all the fields, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Add to settings."
          })]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you’ve added all the components to the settings page builder, click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Deploy changes"
      }), " in the upper-right to deploy the settings page to all users."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Restore a previous version of an app settings page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The app settings builder supports versioning and allows you to restore previously deployed settings pages. To do so:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Version history"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Next to the version you want to deploy, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Restore"
        }), ". The selected version of the app settings page will load in the builder."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "From there, you can make new edits to the settings page and deploy it."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/app-settings-version.png",
        alt: "app-settings-version"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Test your app settings page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To test your app’s settings page, set up a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "test account"
      }), " from your developer account, then install the app on that test account. The app settings page will load on the test account’s connected app."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot also logs all requests made to URLs in the app settings page. To view this request log:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your developer account, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "app"
        }), " you want to create a settings page for."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Monitoring"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App settings"
        }), " tab."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " clicking buttons or toggles will make calls to the URLs you have configured, so ensure that you are using a true developer test account."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}