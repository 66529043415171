"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 158597848455;
const slug = exports.slug = 'reference/ui-components/standard-components/multi-select';
const title = exports.title = 'MultiSelect';
const name = exports.name = 'MultiSelect | UI components';
const metaDescription = exports.metaDescription = 'Learn about the MultiSelect component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tag
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "MultiSelect | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "MultiSelect"
      }), " component renders a dropdown menu select field where a user can select multiple values. Commonly used within the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/form",
        children: "Form"
      }), " component."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extensions-component-multiselect.png",
        alt: "ui-extensions-component-multiselect"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Form, MultiSelect, Button } from '@hubspot/ui-extensions';\n\nfunction MultiSelectControlledExample() {\n  const [formValue, setFormValue] = useState([]);\n  return (\n    <Form preventDefault={true} onSubmit={() => console.log(formValue, 'hola')}>\n      <MultiSelect\n        value={formValue}\n        placeholder=\"Pick your Products\"\n        label=\"Select Mutiple Products\"\n        name=\"selectProduct\"\n        required={true}\n        onChange={(value) => setFormValue(value)}\n        options={[\n          { label: 'Amazing Product 1', value: 'p1' },\n          { label: 'Amazing Product 2', value: 'p2' },\n          { label: 'Amazing Product 3', value: 'p3' },\n          { label: 'Amazing Product 4', value: 'p4' },\n          { label: 'Amazing Product 5', value: 'p5' },\n          { label: 'Amazing Product 6', value: 'p6' },\n        ]}\n      />\n      <Button type=\"submit\">Submit</Button>\n    </Form>\n  );\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The input's unique identifier."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The text that displays above the dropdown menu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The options to display in the dropdown menu. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            }), " will be used as the display text, and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            }), " should be the option's unique identifier, which is submitted with the form."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String | number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The value of the input."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "required"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", displays a required field indicator."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tooltip"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The text that displays in a tooltip next to the label."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text that describes the field's purpose."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "validationMessage"
            }), " is displayed as an error message if provided. The input will also render its error state to let the user know there's an error. If left ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " (default), ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "validationMessage"
            }), " is displayed as a success message."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "validationMessage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The text to display if the input has an error."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(value: (string | number)[]) => void"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A callback function that is invoked when the value is committed."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/form",
          children: "Form"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/date-input",
          children: "DateInput"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/number-input",
          children: "NumberInput"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/select",
          children: "Select"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}