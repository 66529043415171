"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694135;
const slug = exports.slug = 'reference/cms/hubl/variables';
const title = exports.title = 'HubL-Variablen';
const name = exports.name = 'HubL-Variablen';
const metaDescription = exports.metaDescription = 'HubSpot-Vorlagen können eine Vielzahl vordefinierter Variablen verwenden, die zur Darstellung praktischer Website- und E-Mail-Elemente genutzt werden können. Diese Seite ist eine Referenzauflistung dieser Variablen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "in-allen-vorlagen-verf%C3%BCgbare-variablen",
  "label": "In allen Vorlagen verfügbare Variablen",
  "parent": null
}, {
  "depth": 0,
  "id": "e-mail-variablen",
  "label": "E-Mail-Variablen",
  "parent": null
}, {
  "depth": 1,
  "id": "erforderliche-variablen-f%C3%BCr-e-mail-vorlagen",
  "label": "Erforderliche Variablen für E-Mail-Vorlagen",
  "parent": "e-mail-variablen"
}, {
  "depth": 1,
  "id": "farb--und-schriftarteinstellungen",
  "label": "Farb- und Schriftarteinstellungen",
  "parent": "e-mail-variablen"
}, {
  "depth": 1,
  "id": "e-mail-content",
  "label": "E-Mail-Content",
  "parent": "e-mail-variablen"
}, {
  "depth": 1,
  "id": "variablen-f%C3%BCr-e-mails-mit-privatem-content",
  "label": "Variablen für E-Mails mit privatem Content",
  "parent": "e-mail-variablen"
}, {
  "depth": 0,
  "id": "variablen-f%C3%BCr-website-seiten",
  "label": "Variablen für Website-Seiten",
  "parent": null
}, {
  "depth": 1,
  "id": "erforderliche-variablen-f%C3%BCr-seitenvorlagen",
  "label": "Erforderliche Variablen für Seitenvorlagen",
  "parent": "variablen-f%C3%BCr-website-seiten"
}, {
  "depth": 1,
  "id": "inhalts--und-kontaktvariablen",
  "label": "Inhalts- und Kontaktvariablen",
  "parent": "variablen-f%C3%BCr-website-seiten"
}, {
  "depth": 0,
  "id": "http-anfragevariablen",
  "label": "HTTP-Anfragevariablen",
  "parent": null
}, {
  "depth": 0,
  "id": "blog-variablen",
  "label": "Blog-Variablen",
  "parent": null
}, {
  "depth": 0,
  "id": "dynamische-seiten-mit-crm-objekten",
  "label": "Dynamische Seiten mit CRM-Objekten",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb-variablen",
  "label": "HubDB-Variablen",
  "parent": null
}, {
  "depth": 0,
  "id": "men%C3%BCknotenvariablen",
  "label": "Menüknotenvariablen",
  "parent": null
}, {
  "depth": 0,
  "id": "in-app-editor-und-vorschauvariablen",
  "label": "In-App-Editor und Vorschauvariablen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubL-Variablen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot-Vorlagen können eine Vielzahl vordefinierter Variablen verwenden, die zur Darstellung praktischer Website- und E-Mail-Elemente genutzt werden können. Diese Seite ist eine Referenzauflistung dieser Variablen. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "Erfahren Sie mehr über das Erstellen Ihre eigenen Variablen"
      }), " in einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HubL-Vorlage"
      }), " oder einem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Modul"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Während die meisten der auf dieser Seite aufgeführten Variablen optional sind, gibt es einige wenige Variablen, die für die Erstellung von E-Mails und Seiten aus Ihren Vorlagen erforderlich sind."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Die unten aufgeführten Variablen können einzeln verwendet werden, indem sie wie auf unserer ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/variables-macros-syntax",
          children: "Seite für Variablen und Makros"
        }), " beschrieben in das ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "}}"
        }), "-Trennzeichen eingeschlossen werden. Sie können diese Variablen optional mit anderen Teilen der HubL-Vorlagensprache wie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/loops",
          children: "Schleifen"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/filters",
          children: "Filtern"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions",
          children: "Funktionen"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags",
          children: "Tags"
        }), " und mehr verwenden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "In allen Vorlagen verfügbare Variablen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden HubL-Variablen können in E-Mail-, Seiten- oder Blog-Vorlagen verwendet werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um zusätzliche Informationen zur Ausgabe dieser Variablen zu erhalten, können Sie den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters#pprint",
        children: "pprint-Filter"
      }), " verwenden, wie unten gezeigt."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Eingabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set variable = content %}\n{{variable|pprint}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(ContentMeta: { \"canonicalUrl\" : \"https://www.website.com/url-slug\",\n\"deferDynamicValues\" : false, \"featuredImageAltText\" : \"\", \"featuredImageHeight\"\n: 0, \"featuredImageUrl\" : \"\", \"featuredImageWidth\" : 0, \"htmlTitle\" : \"\",\n\"linkRelCanonicalUrl\" : \"https://www.website.com/url-slug\", \"metaDescription\" :\n\"\", \"metaKeywords\" : \"\", \"name\" : \"\", \"unescapedHtmlTitle\" : \"\",\n\"unescapedMetaDescription\" : null })\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "account"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Diese Variable ist ein Dictionary, in dem die Unternehmenspersonalisierungseigenschaften für einen bekannten Kontakt gespeichert werden. Auf Eigenschaften kann von diesem Dictionary aus zugegriffen werden, indem ein Punkt und der Name der Eigenschaft hinzugefügt werden. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "account.name"
            }), " würde zum Beispiel den Unternehmensnamen eines Kontakts ausgeben. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Durch die Verwendung dieser Variable wird das Speichern von Seiten im Cache deaktiviert."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company_domain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Druckt die Unternehmens-Domain von ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Website"
            }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "> Seiten > Branding > Logo-Link"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Diese Variable ist ein Dictionary, in dem die Kontaktpersonalisierungseigenschaften für einen bekannten Kontakt gespeichert werden. Auf Eigenschaften kann von diesem Dictionary aus zugegriffen werden, indem ein Punkt und der Name der Eigenschaft hinzugefügt werden. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.firstname"
            }), " würde zum Beispiel den Vornamen eines Kontakts ausgeben. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Durch die Verwendung dieser Variable wird das Speichern von Seiten im Cache deaktiviert."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Diese Variable ist ein Dictionary, das verschiedene Eigenschaften speichert, die sich auf einen bestimmten Inhalt wie eine E-Mail, eine Seite oder einen Beitrag beziehen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.absolute_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Druckt die vollständige URL der Seiten-, Beitrags- oder Webseite-Version einer E-Mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.archived"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Diese Variable wird als True ausgewertet, wenn die Seite oder E-Mail vom Benutzer als archiviert markiert wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.author_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die E-Mail-Adresse des Content-Autors."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.author_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Vor- und Nachname des Content-Autors."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.author_username"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der HubSpot-Benutzername des Content-Autors."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.campaign"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die GUID für die Marketingkampagne, mit der diese Seite oder E-Mail verknüpft ist. Diese eindeutige ID finden Sie in der URL einer bestimmten Kampagne im Kampagnen-Tool."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.campaign_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name der Marketingkampagne, mit der diese Seite, dieser Beitrag oder diese E-Mail verknüpft ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.created"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Datetime"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Datetime-Objekt, das in UTC-Zeit angibt, wann der Inhalt ursprünglich erstellt wurde. Diese Variable kann mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/filters#datetimeformat",
              children: "Datetime-Filter"
            }), " formatiert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.meta_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Beim Abrufen der Metabeschreibung einer Seite ist es besser, die Variable ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.meta_description"
            }), " zu verwenden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name eines Beitrags, einer E-Mail oder einer Seite. Bei Seiten und E-Mails wird der interne Name des Inhalts gedruckt, bei Beiträgen der Beitragstitel. Bei Blogbeiträgen ist dies der Titel des Beitrags, der angezeigt wird. Bei anderen Inhaltstypen handelt es sich in der Regel um einen internen Namen. Diese Variable enthält einen Wrapper, sodass sie über die Benutzeroberfläche bearbeitet werden kann, wenn sie in Blogbeiträgen enthalten ist. Wenn Sie den Namen des Inhalts ohne einen Wrapper ausgeben möchten, verwenden Sie page_meta.name."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Datetime"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Datetime-Objekt, das in UTC-Zeit angibt, wann der Inhalt veröffentlicht wurde. Diese Variable kann mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/filters#format-datetime",
              children: "format_datetime-Filter"
            }), " formatiert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date_localized"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Zeichenfolge, die das Datum und die Uhrzeit angibt, zu der der Inhalt unter Verwendung der in den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/change-your-language-and-region-settings",
              children: "Standardeinstellungen"
            }), " des Accounts definierten Zeitzone veröffentlicht wurde. Diese Variable unterliegt auch den Sprach- und Datumsformateinstellungen in ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Einstellungen > Website > Blog > Datumsformate"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.template_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Dateipfad des Design-Managers zu Ihrer Vorlage (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom/page/web_page_basic/my_template.html"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der URL-Slug der Seiten-, Beitrags- oder Webseite-Version einer E-Mail. Dies ist der Wert, der auf die Domain folgt. In ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://example.com/about-us"
            }), " ist der Slug beispielsweise ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "about-us"
            }), ". Verwenden Sie stattdessen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.absolute_url"
            }), " für die vollständige URL."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.updated"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Datetime"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Datetime-Objekt, das in UTC-Zeit angibt, wann der Benutzer den Inhalt zuletzt aktualisiert hat. Diese Variable kann mit dem Datetime-Filter formatiert werden. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entspricht nicht"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date"
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "bei der ersten Veröffentlichung. Verwenden Sie den"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/filters#between-times",
              children: (0, _jsxRuntime.jsx)("code", {
                children: "|between_times"
              })
            }), (0, _jsxRuntime.jsx)(_components.em, {
              children: "-Filter, um zu prüfen, ob ein Beitrag nach der Veröffentlichung aktualisiert wurde."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Druckt die eindeutige ID für eine Seite, einen Beitrag oder eine E-Mail. Diese ID ist in der URL des Editors zu finden. Sie können diese Variable als Alias für content.id verwenden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "favicon_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Druckt die Quelle des Favicons. Dieses Bild wird unter ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Einstellungen > Website > Seiten > Branding"
            }), " festgelegt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hub_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Portal-ID Ihres HubSpot-Accounts."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_analytics_tracking_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enthält den Analytics-Tracking-Code. Dieses Tag ist nicht erforderlich, da ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_footer_includes"
            }), " den Tracking-Code bereits rendert."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "local_dt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Datetime"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Datetime-Objekt mit der aktuellen Zeit in der Zeitzone, die in Ihren Berichtseinstellungen definiert ist. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Durch die Verwendung dieser Variablen wird das Speichern von Seiten im Cache deaktiviert, um die aktuelle Zeit zurückzugeben. Kann die Performance der Seite beeinträchtigen. Verwenden Sie stattdessen JavaScript, um das aktuelle Datum und die Uhrzeit auf eine cachefähige Weise abzurufen."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "local_time_zone"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Zeitzone, wie in Ihren HubSpot-Berichtseinstellungen konfiguriert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.canonical_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die offizielle URL, unter der diese Seite aufgerufen werden sollte. Enthält normalerweise keine Abfragezeichenfolgenparameter. Verwenden Sie dies für das ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rel=\"canonical\""
            }), "-Tag. HubSpot kanonisiert URLs automatisch."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.html_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Titel der Seite. Diese Variable sollte im ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<title>"
            }), "-Tag von HTML-Vorlagen verwendet werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.meta_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Metabeschreibung einer Seite. Diese Variable sollte im \"description\" ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<meta>"
            }), "-Tag von HTML-Vorlagen verwendet werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Alias für ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.name"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portal_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Alias für hub_id"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request_contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das Daten über den angeforderten Kontakt enthält. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Durch die Verwendung dieser Variable wird das Speichern von Seiten im Cache deaktiviert."
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Nicht in E-Mail-Vorlagen verfügbar."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das site_settings-Dict enthält verschiedene Einstellungen wie Farben und Schriftarten (siehe unten)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "year"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Druckt das aktuelle Jahr."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "E-Mail-Variablen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachfolgend finden Sie die HubL-Variablen, die in E-Mail-Vorlagen verwendet werden können. Einige Variablen, z. B. die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#color-and-font-settings",
        children: "Farb- und Schriftarteinstellungsvariablen"
      }), ", können in anderen Inhaltstypen verwendet werden, wenn Sie Ihre E-Mail-Farb- und Schriftarteinstellungen an anderer Stelle referenzieren möchten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Erforderliche Variablen für E-Mail-Vorlagen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/can-i-customize-my-can-spam-email-footer",
        children: "CAN-SPAM"
      }), "-konform zu sein, müssen für alle über HubSpot versendeten E-Mails bestimmte Unternehmens- und Opt-out-Informationen angegeben werden. Diese Informationen werden durch die folgenden Variablen festgelegt, die enthalten sein müssen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt zusätzliche E-Mail-Variablen, die optional sind und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#email-variables",
        children: "weiter unten auf dieser Seite"
      }), " aufgeführt sind."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_city"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Druckt die Stadt des Unternehmens (festgelegt unter ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Einstellungen > Marketing > E-Mail > Konfiguration > Footer"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Druckt den Unternehmensnamen (festgelegt unter ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Einstellungen > Marketing > E-Mail > Konfiguration > Footer"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Druckt das Bundeland des Unternehmens (festgelegt unter ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Einstellungen > Marketing > E-Mail > Konfiguration > Footer"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_street_address_1"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Druckt die Unternehmensadresse (festgelegt untern ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Einstellungen > Marketing > E-Mail > Konfiguration > Footer"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Druckt die URL der Seite, die es den Empfängern ermöglicht, die Abonnementeinstellungen zu verwalten oder sich von der E-Mail-Kommunikation abzumelden. Diese Variable sollte in der href-Attribut eines <a> verwendet werden."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Farb- und Schriftarteinstellungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt mehrere grundlegende Farb- und Schriftartsteuerelemente in Ihren ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/marketing-email/manage-your-marketing-email-account-settings",
        children: "Marketing-E-Mail-Einstellungen"
      }), ", die in E-Mails gedruckt werden können. Wenn angegeben, können Sie einige dieser Variablen in Seiten- und Blog-Vorlagen verwenden, indem Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "site_settings."
      }), " vor der Variable hinzufügen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Einstellung der Hintergrundfarbe als Hexadezimalwert. Um diesen Wert in Seiten- oder Blog-Vorlagen zu drucken, verwenden Sie:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.background_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_border_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Einstellung für die Rahmenfarbe für Text als Hexadezimalwert. Diese Option ist verfügbar, wenn Sie in der Dropdown-Liste ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Optionen für Rahmenfarbe"
            }), " den ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Eintrag E-Mail-Rahmenfarbe manuell festlegen"
            }), " auswählen. Um diesen Wert in Seiten- oder Blog-Vorlagen zu drucken, verwenden Sie:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.body_border_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_border_color_choice"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Wert für diese Variable wird über das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Optionen für Rahmenfarbe"
            }), " in den Einstellungen gesteuert. Werte sind: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BORDER_AUTOMATIC"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BORDER_MANUAL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BORDER_NONE"
            }), ". Um diesen Wert in Seiten- oder Blog-Vorlagen zu drucken, verwenden Sie:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.body_border_color_choice"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Einstellung für die Textfarbe als Hexadezimalwert. Um diesen Wert in Seiten- oder Blog-Vorlagen zu drucken, verwenden Sie:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.body_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_accent_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Einstellung für primäre Akzentfarbe als Hexadezimalwert. Um diesen Wert in Seiten- oder Blog-Vorlagen zu drucken, verwenden Sie:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_accent_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aufzählung"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Einstellung für primäre Schriftart als Zeichenfolge. Um diesen Wert in Seiten- oder Blog-Vorlagen zu drucken, verwenden Sie:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Einstellung für primäre Schriftartfarbe als Hexadezimalwert. Um diesen Wert in Seiten- oder Blog-Vorlagen zu drucken, verwenden Sie:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font_size"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Einstellung für primäre Schriftgröße als ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "-Wert. Um diesen Wert in Seiten- oder Blog-Vorlagen zu drucken, verwenden Sie:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font_size"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font_size_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Zahl der primären Schriftart, ausgenommen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_accent_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Einstellung für sekundäre Akzentfarbe als Hexadezimalwert. Um diesen Wert in Seiten- oder Blog-Vorlagen zu drucken, verwenden Sie:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.secondary_accent_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_font"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aufzählung"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Einstellung für sekundäre Schriftart als Zeichenfolge. Um diesen Wert in Seiten- oder Blog-Vorlagen zu drucken, verwenden Sie:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.secondary_font"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_font_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Einstellung für sekundäre Schriftartfarbe als Hexadezimalwert. Um diesen Wert in Seiten- oder Blog-Vorlagen zu drucken, verwenden Sie:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.secondary_font_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_font_size_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Zahl der sekundären Schriftart, ausgenommen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "E-Mail-Content"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.create_page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ist ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", wenn es eine Webseiten-Version der E-Mail gibt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.email_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich-Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Haupttext der E-Mail. Diese Variable stellt ein Rich-Text-Modul dar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.emailbody_plaintext"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die optionale Überschreibung des Klartext-E-Mail-Textes"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.from_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Absendername des E-Mail-Absenders"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.reply_to"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Antwortadresse für die E-Mail"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.subject"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Betreff der E-Mail"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_body_padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/manage-your-marketing-email-account-settings#size",
              children: "Einstellung für die Auffüllung des E-Mail-Textes"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_body_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/manage-your-marketing-email-account-settings#size",
              children: "Einstellung für die Breite des E-Mail-Textes"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_street_address_2"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Druckt die Adresszeile 2 aus den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/can-i-customize-my-can-spam-email-footer",
              children: "CAN-SPAM-Footer-Einstellungen"
            }), " des Accounts."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.office_location_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Druckt den Namen des Bürostandorts aus den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/can-i-customize-my-can-spam-email-footer",
              children: "CAN-SPAM-Footer-Einstellungen"
            }), " des Accounts."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscription_confirmation_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Druckt die URL der Bestätigungsseite für die Abonnementeinstellungen. Diese URL wird beim Senden dynamisch generiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscription_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Druckt den Namen des für diese E-Mail angegebenen E-Mail-Typs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_anchor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Generiert ein Anchor-Tag mit dem Wort \"unsubscribe\", verlinkt mit Ihrer Abmeldeseite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_link_all"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rendert einen Link zur Abmeldung von sämtlicher E-Mail-Kommunikation, im Gegensatz zu einem Link zur Verwaltung der Abonnementeinstellungen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_section"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rendert einen Abschnitt zur Abmeldung, der einen Abmeldelink sowie einen Hilfetext enthält."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "view_as_page_section"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Generiert einen Link mit Hilfetext, der zu einer Webseiten-Version einer E-Mail führt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "view_as_page_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Generiert einen Link, der zu einer Webseiten-Version einer E-Mail führt."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Variablen für E-Mails mit privatem Content"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die folgende Liste von Variablen ist nur innerhalb von E-Mail-Vorlagen verfügbar, die für ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages#customize-your-membership-registration-page-and-email-templates",
        children: "E-Mail-Vorlagen mit privatem Content"
      }), " verwendet werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Unternehmensname, wie er in den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/manage-private-content-settings#edit-general-settings-for-private-content",
              children: "Content-Einstellungen"
            }), " des Accounts festgelegt ist."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_domain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Domain der Website mit privatem Content."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_logo_src"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die URL des anzuzeigenden Logos, wie in den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors",
              children: "Brand-Kit-Einstellungen"
            }), " des Accounts festgelegt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_logo_alt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Das Attribut des Logos ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), ", wie in den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors",
              children: "Brand-Kit-Einstellungen"
            }), " des Accounts festgelegt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_registration_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link zur Registrierungsseite für die Website mit privatem Content."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_password_saved_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link zur Seite mit dem gespeicherten Passwort. Der Link leitet den Besucher auf eine zufällige eingeschränkte Seite weiter, auf die er Zugriff hat."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_password_reset_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link zur Seite zum Zurücksetzen des Passworts für die Website mit privatem Content."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_passwordless_auth_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link zur passwortlosen Anmeldeseite für Mitglieder."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_verify_registration_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link für die Seite zur Verifizierung der Registrierung."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_website_admin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Name des Administrators der Website, wie in den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/manage-private-content-settings#edit-general-settings-for-private-content",
              children: "Einstellungen für privaten Content"
            }), " des Accounts festgelegt."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variablen für Website-Seiten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Variablen sind für Website-Seiten, Landingpages, Systemseiten und Blogs verfügbar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Erforderliche Variablen für Seitenvorlagen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Code-Datei als bearbeitbare Seite oder Blog-Vorlage zu veröffentlichen, müssen die folgenden Variablen enthalten sein. Um eine HTML-Datei ohne diese Variablen zu veröffentlichen, um sie in einer anderen Vorlage zu verwenden, schließen Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "isAvailableForNewContent: false"
      }), " in die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "Vorlagenannotationen"
      }), " ein."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_footer_includes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Rendert den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview#hubspot-tracking-code",
              children: "HubSpot-Tracking-Code"
            }), " und jeden anderen Code, der in Ihrem Footer-HTML in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://app.hubspot.com/l/settings/website/pages/all-domains/page-templates",
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "den Content-Einstellungen"
              })
            }), " oder in den Optionen einer bestimmten Seite enthalten ist. Dieses Tag sollte direkt vor dem schließenden body-Tag eingefügt werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_header_includes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Fügt ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview#included-cms-files",
              children: "jQuery, layout.css"
            }), ", alle angehängten Stylesheets, ein Meta-Viewport-Tag, Google-Analytics-Tracking-Code, andere Seiten-Meta-Informationen sowie Code hinzu, der dem Head-Tag auf Domain-/Vorlagen-/Seitenebene hinzugefügt wird. Dieser Variable sollte in den <head> von HTML-Vorlagen eingefügt werden."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Inhalts- und Kontaktvariablen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "builtin_body_classes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Diese Variable gibt dynamisch praktische Klassen aus, die dabei helfen, das Markup von Inhalten, die mit dieser Vorlage erstellt wurden, zu unterscheiden (z. B. Typ von Inhalt, Name des Inhalts usw.). Dies erleichtert die Formatierung verschiedener Inhaltstypen oder bestimmter Seiten. Diese Variable sollte im class-Attribut des body-Tags von Code-Vorlagen verwendet werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request_contact.is_logged_in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Diese Variable legt fest, ob der anfragende Kontakt bei den geschützten Inhalten einer Website angemeldet ist oder nicht (weitere Informationen finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages",
              children: "Dokumentation zur Steuerung des Zielgruppenzugriffs"
            }), "). Der Wert dieser Variablen gibt true zurück, wenn der anfragende Kontakt angemeldet ist, und false, wenn der anfragende Kontakt abgemeldet ist. Ein Kontakt kann abgemeldet werden, indem er zur URL ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.yourdomain.com/_hcms/mem/logout"
            }), " weitergeleitet wird. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Durch die Verwendung dieser Variable wird das Speichern von Seiten im Cache deaktiviert."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request_contact.list_memberships"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Diese Variable gibt eine Dict von IDs zurück, die die Listen darstellen, in denen der Kontakt Mitglied ist. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Durch die Verwendung dieser Variable wird das Speichern von Seiten im Cache deaktiviert."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Diese Variable gibt ein Dictionary von Informationen zu den Spracheinstellungen einer Seite zurück. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ content.language.languageTag }}"
            }), " gibt die Sprachkennung einer Seite zurück (z. B. „en“ oder „es“). ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ content.language.textDirection.value }}"
            }), " gibt die Textrichtung der Sprache der Seite zurück (z. B. „rtl“ oder „ltr“)."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTTP-Anfragevariablen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Variablen geben Informationen zur HTTP-Seitenanfrage aus."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.cookies"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Dictionary mit Cookie-Namen, die Cookie-Werten zugeordnet sind. Durch die Verwendung dieser Variable wird das Speichern von Seiten im Cache deaktiviert._"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.domain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die für den Zugriff auf diese Seite verwendete Domain"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.full_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die für den Zugriff auf diese Seite verwendete URL."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Pfadkomponente der URL"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.path_and_query"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Pfad- und Abfragekomponente der URL"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.query"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Abfragezeichenfolgenkomponente der URL. request.query_dict teilt die Abfragezeichenfolgen automatisch in Schlüssel-Wert-Paare auf und wird für die meisten Anwendungsfälle gegenüber der Raw Query empfohlen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.query_dict"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "DictDie Abfragezeichenfolge, die in ein Name->Wert-Dictionary umgewandelt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.referrer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der HTTP-Referrer, die URL der Seite, die auf die aktuelle Seite. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Durch die Verwendung dieser Variable wird das Speichern von Seiten im Cache deaktiviert."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.remote_ip"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [": Die IP-Adresse des Besuchers. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Durch die Verwendung dieser Variable wird das Speichern von Seiten im Cache deaktiviert."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.scheme"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Protokoll der Anfrage (entweder http oder https)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.search_engine"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Suchmaschine, mit der diese Seite gefunden wurde, falls zutreffend. Zum Beispiel: Google, AOL, Llive, Yahoo, images.google usw."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.search_keyword"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Suchbegriff, mit dem diese Seite gefunden wurde, falls zutreffend"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.headers"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine Dictionary von verfügbaren Anfrage-Headern. Durch die Verwendung dieser Variablen wird das Speichern von Seiten im Cache deaktiviert, um individualisierte Header für jede Anfrage zu interpretieren. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Kann die Performance der Seite beeinträchtigen."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blog-Variablen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Variablen sind für Blog-Vorlagen verfügbar. Einige Variablen sind nur für Beitrags-Listings verfügbar, während andere nur für Blogbeiträge verfügbar sind."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Diese Variable enthält Informationen zum Blog-Autor für Listing-Seiten von Blog-Autoren. Sie kann verwendet werden, um bedingte Logik zum ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/blog#if-blog-author-statement",
              children: "Darstellen von Markup für Blog-Autoren-Listings"
            }), " zu erstellen. Sie enthält außerdem die folgenden Eigenschaften:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.avatar"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.bio"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.display_name"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.email"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.facebook"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.google_plus"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.has_social_profiles"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.linkedin"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.twitter"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.website"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.blog_post_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Diese Variable enthält individuelle Informationen zum Blogbeitragsautor für einen bestimmten Beitrag. Sie kann die folgenden Eigenschaften enthalten:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.avatar"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.bio"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.display_name"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.email"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.facebook"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.google_plus"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.has_social_profiles"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.linkedin"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.slug"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.twitter"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.website"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Alias für Gruppe."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.comment_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Anzahl der Kommentare für den aktuellen Blogbeitrag."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.comment_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Liste der Kommentare für den aktuellen Blogbeitrag."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "current_page_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der ganzzahlige Index der aktuellen Seite von Blogbeitrageiträgen in der Ansicht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Quell-URL des Feature-Bildes, das bei der Veröffentlichung des Blogs ausgewählt wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.featured_image_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der ALT-Text des Feature-Bildes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "last_page_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der ganzzahlige Index der letzten Seite von Blogbeiträgen in der Ansicht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "next_page_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der ganzzahlige Index der nächsten Seite von Blogbeiträgen in der Ansicht."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die URL des Feature-Bildes des nächsten Blogbeitrags, sofern ein solcher existiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_featured_image_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ALT-Text für das Feature-Bild des nächsten Beitrags, wenn ALT-Text vorhanden ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name des nächsten Blogbeitrags, sofern ein solcher existiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der URL-Slug des nächsten Blogbeitrags, sofern ein solcher existiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Text des Blogbeitrags."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_list_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Inhalt des Blogbeitrags, geändert für die Listing-Seite. Die endgültige Ausgabe wird durch die Zusammenfassungseinstellungen unter ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Einstellungen > Website > Blog"
            }), " gesteuert Wenn in den Einstellungen Feature-Bilder aktiviert sind, entfernt diese Variable automatisch alle Bilder oberhalb des „Mehr erfahren“-Trennzeichens."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_list_summary_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Das Feature-Bild für Beitragszusammenfassungen, das in Listing-Vorlagen verwendet werden soll. Diese Variable wird von den Einstellungen in ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Einstellungen > Website > Blog"
            }), " gesteuert."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_summary"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Zusammenfassung des Blogbeitrags. Dieser Inhalt wird durch das „Mehr erfahren“-Trennzeichen im Blog-Editor bestimmt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die URL des Feature-Bildes des vorherigen Blogbeitrags, sofern ein solcher existiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_featured_image_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ALT-Text für das Feature-Bild des vorherigen Beitrags, wenn ALT-Text vorhanden ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name des vorherigen Blogbeitrags, sofern ein solcher existiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der URL-Slug des vorherigen Blogbeitrags, sofern ein solcher existiert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date_localized"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Zeichenfolge, die das Datum und die Uhrzeit der Veröffentlichung des Blogbeitrags angibt, formatiert entsprechend den Sprach- und Datumsformatierungseinstellungen des Blogs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "simple_list_page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein boolescher Wert, der angibt, ob es sich bei der angeforderten Seite um die Seite „Alle Beiträge“ handelt, die Links zu allen Blogbeiträgen enthält."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.topic_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Kann verwendet werden, um Markup für eine Themenübersicht zu rendern, indem sie in einer Schleife durchlaufen wird. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{% for topic in content.topic_list %}"
            }), "Die Elemente darin enthalten die folgenden Eigenschaften: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "slug"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["contents ist eine Abfolge Ihrer Blogbeiträge, die mithilfe einer for-Schleife durchlaufen werden, verfügbar auf ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/blog#if-is-listing-view-statement",
              children: "Blog-Listing-Seiten (is_listing_view)"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents.total_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gesamtzahl an Beiträgen in einem Listing (normal, Themen, Autoren usw.)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents.total_page_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gesamtzahl der Seiten mit Beiträgen, basierend auf der Anzahl der Beiträge pro Seite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents_topics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ruft eine Liste aller Blog-Themen in der contents-Abfolge von Beiträgen ab."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Dictionary mit den Variablen, die sich auf einen gesamten Blog beziehen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.absolute_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Basis-URL eines Blogs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.allow_comments"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wird als True ausgewertet, wenn Kommentare erlaubt sind."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Metabeschreibung des Blogs aus ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Einstellungen > Website > Blog"
            }), ". Wird für die Metabeschreibung auf bestimmten Listing-Seiten verwendet."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Header des Blogs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.html_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Titel dieses Blogs, wie er im ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<title>"
            }), "-Tag angezeigt werden soll."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die eindeutige ID eines Blogs. Diese ID ist in der URL des Blog-Dashboards für einen bestimmten Blog zu finden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das Informationen zur Sprache eines Blogs enthält. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ group.language.languageTag }}"
            }), " kann in Bedingungen verwendet werden, um unterschiedliche Inhalte für die verschiedenen Sprachvarianten eines mehrsprachigen Blogs darzustellen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.public_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Titel dieses Blogs, wie er oben auf den gerenderten Seiten erscheinen soll."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.show_summary_in_listing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein boolescher Wert von ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Einstellungen > Website > Blog"
            }), ", der angibt, ob Zusammenfassungen in Beitrags-Listings angezeigt werden sollen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Pfad zu diesem Blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.use_featured_image_in_summary"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein boolescher Wert von ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Einstellungen > Website > Blog"
            }), ", der angibt, ob Feature-Bilder in Beitragszusammenfassungen angezeigt werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "archive_list_page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt true zurück, wenn die Seite eine Blog-Archivseite ist. Bsp.: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/blog/archive/2020/02"
            }), " würde ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurückgeben."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Dynamische Seiten mit CRM-Objekten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die folgenden Variablen werden verwendet, um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#crm-object-dynamic-pages",
        children: "dynamische Seiten mit CRM-Objekten zu erstellen"
      }), ". Diese Variablen sind nur für dynamische Seiten mit CRM-Objekten verfügbar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_crm_object"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Das CRM-Objekt der dynamischen Seite, das mit dem Seitenanfragepfad übereinstimmt. Wenn die Anfrage an die Listing-Seite gerichtet ist, lautet dieser Wert ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_crm_object_type_fqn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der vollständig qualifizierte Name (Fully Qualified Name, FQN) des crm-Objekts. Der FQN ist eine zugewiesene eindeutige ID für das Objekt, einschließlich Portal-ID und Objektname. Der FQN kann in der ", (0, _jsxRuntime.jsxs)(_components.a, {
              href: "/reference/cms/hubl/functions#crm-objects",
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "crm_objects"
              }), "-Funktion verwendet werden."]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB-Variablen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die folgenden Variablen werden für die Erstellung dynamischer Seiten mithilfe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), " verwendet. Diese Variablen sind nur für ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#hubdb-dynamic-pages",
        children: "dynamische HubDB-Seiten"
      }), "verfügbar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_hubdb_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lang"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID der Tabelle, die auf der Registerkarte „Erweiterte Einstellungen\\“ des Seiten-Editors ausgewählt wurde."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_hubdb_row"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die HubDB-Zeile der dynamischen Seite, die mit dem Seitenanfragepfad übereinstimmt. Wenn die Anfrage an die Listing-Seite gerichtet ist, lautet dieser Wert ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lang"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die interne ID einer HubDB-Zeile."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name der HubDB-Zeile."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Pfad der HubDB-Zeile. Wird verwendet, um eine Anfrage an eine einzelne Zeile in der Tabelle aufzulösen, die durch ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_hubdb_table_id"
            }), " angegeben wird."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_child_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lang"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID der untergeordneten Tabelle der HubDB-Zeile. Kann verwendet werden, um verschachtelte Vorlagen zu erstellen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_parent_row"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die übergeordnete Zeile der HubDB-Zeile. Kann nur verwendet werden, wenn untergeordnete Tabellen für verschachtelte Vorlagen verwendet werden."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_route_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Aktuelle Tiefe einer Seite in einer dynamischen Vorlage mit mehreren Ebenen. Der Wert beginnt bei ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " und wird mit jeder zusätzlichen Tabellenebene erhöht."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Menüknotenvariablen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die folgenden Variablen können für das Objekt verwendet werden, das von der Funktion ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#menu",
        children: "menu-Funktion von HubL"
      }), " zurückgegeben wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Menülabel der Seite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL der Seite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.pageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID der Seite, wenn in HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.contentGroupId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog-ID der Seite, wenn es sich um einen HubSpot-Blogbeitrag handelt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.parentNode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der übergeordnete Knoten des aktuellen Knotens. Der übergeordnete Knoten enthält den aktuellen Knoten in seiner ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "children"
            }), "-Eigenschaft."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.children"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Liste"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Liste der untergeordneten Knoten für den aktuellen Knoten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.activeBranch"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "True, wenn sich der Knoten in der obersten Verzweigung befindet, in der sich die aktuelle Seite befindet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.activeNode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "BooleschTrue, wenn der Knoten die aktuelle Seite ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Anzahl der Ebenen der Tiefe, die der aktuelle Knoten von den Knoten der obersten Ebene entfernt ist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.pageTitle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Name der Inhaltsseite, sofern in HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pfad-Slug der Seite."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.linkTarget"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link-Ziel der Seite."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "In-App-Editor und Vorschauvariablen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können die folgenden Variablen verwenden, um zu überprüfen, ob der Inhalt im Content-Editor oder der Vorschau gerendert wird. Sie können diese beispielsweise verwenden, um zu verhindern, dass Code im Editor ausgeführt wird, während der Code noch auf Live-Seiten ausgeführt wird."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if is_in_page_editor %}\nDisplay something different within the page editor.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_hs_app"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb der HubSpot-App gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb der HubSpot-App gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_global_content_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb des Editors für globalen Content gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_theme_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [": Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb des Design-Editors gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_page_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb der HubSpot-App gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_blog_post_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb des Blogbeitrag-Editors gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_email_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb des E-Mail-Editors gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb eines beliebigen Vorschaukontextes gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_theme_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb der Design-Vorschau gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_template_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb der Vorlage-Vorschau gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_page_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb der Seitenvorschau gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_blog_post_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb der Blogbeitragsvorschau gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_email_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb der E-Mail-Vorschau gerendert werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_module_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " zurück, wenn Inhalte innerhalb der Modulvorschau gerendert werden."]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}