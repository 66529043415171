"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32177354787;
const slug = exports.slug = 'guides/cms/marketplace/template-guidelines';
const title = exports.title = 'HubSpot Template Marketplace Guidelines';
const name = exports.name = '[Developers] HubSpot Template Marketplace Guidelines';
const metaDescription = exports.metaDescription = 'The HubSpot Template Marketplace helps thousands of customers without direct access to a developer grow their online presence. It is powered by developers, like you, who are creating beautiful and easy to use themes, templates, and modules.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "why-should-i-sell-templates-on-the-marketplace%3F",
  "label": "Why should I sell templates on the marketplace?",
  "parent": null
}, {
  "depth": 0,
  "id": "overview-and-requirements",
  "label": "Overview and requirements",
  "parent": null
}, {
  "depth": 0,
  "id": "list-and-update-themes-on-the-template-marketplace",
  "label": "List and update themes on the Template Marketplace",
  "parent": null
}, {
  "depth": 0,
  "id": "supported-payment-gateways",
  "label": "Supported payment gateways",
  "parent": null
}, {
  "depth": 0,
  "id": "refund-requests",
  "label": "Refund requests",
  "parent": null
}, {
  "depth": 0,
  "id": "manage-your-transactions",
  "label": "Manage your transactions",
  "parent": null
}, {
  "depth": 0,
  "id": "removal-of-template-listings",
  "label": "Removal of template listings",
  "parent": null
}, {
  "depth": 0,
  "id": "frequently-asked-questions",
  "label": "Frequently Asked Questions",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      em: "em",
      br: "br",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ContentCardsRow,
      ContentCard,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ContentCard) _missingMdxReference("ContentCard", true);
  if (!ContentCardsRow) _missingMdxReference("ContentCardsRow", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot Template Marketplace Guidelines"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "HubSpot Template Marketplace"
      }), " helps thousands of customers without direct access to a developer grow their online presence. It is powered by developers who create easy to use templates for customers."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can sell two types of templates on the HubSpot Template Marketplace: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/website",
        children: "themes"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/modules",
        children: "modules"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Why should I sell templates on the marketplace?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The HubSpot Marketplace has a lot to offer developers or agencies who are looking to create a new revenue stream."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Non-exclusive:"
        }), " You’ve worked hard at creating your templates. You shouldn’t be tied down to only having your template on our marketplace and nowhere else. You’re free to use your templates outside of the marketplace for your own projects as well."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Build once, sell multiple times:"
        }), " Once your template is listed on the marketplace, it becomes a 1:many opportunity. You simply build your template once and it can be resold to multiple customers."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Grow your business:"
        }), " Many of the developers who sell their templates (both free and paid) on our marketplace have seen their businesses grow both from marketplace revenue and from additional services requested by buyers."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Join a community of creators and showcase your skills:"
        }), " Connect with a growing and supportive ecosystem to help shape the future of the HubSpot Template Marketplace while bringing more visibility to your offerings and services."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Overview and requirements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get started selling on the Template Marketplace, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "create a Template Marketplace provider account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Any template submission must adhere to the compliance, design, and code quality requirements. Each template type also has its own requirements that must be met in order to be listed in the marketplace. You can view these requirements below:"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " before you begin creating templates for the marketplace, HubSpot strongly recommends reviewing the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/template-providers",
          children: "Building for the HubSpot Template Marketplace"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/themes/branding-guidelines",
          children: "Branding"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/tm-usage-guidelines",
          children: "Trademark Usage"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/content-usage-guidelines",
          children: "Content Usage"
        }), " guidelines. Templates that violate these guidelines will immediately be removed from the marketplace as described in HubSpot’s additional terms."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(ContentCardsRow, {
      children: [(0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Theme Requirements"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "A portable and contained set of templates, modules, global content, and style settings designed to work together to enable a marketer-friendly content editing experience."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/beta-docs/guides/cms/marketplace/theme-requirements",
            children: "View theme requirements"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Module requirements"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Submitted themes will contain modules, which are reusable components that can be used in templates or added to pages through drag and drop areas and flexible columns. You can also submit standalone modules. These are modules that can be added to any theme in HubSpot's content editor."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/beta-docs/guides/cms/marketplace/module-requirements",
            children: "View module requirements"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Listing Page Requirements"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Requirements one must adhere to when submitting templates and their listing pages to the HubSpot Template Marketplace. These standards apply to all templates on the Marketplace."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/marketplace/general-requirements",
            children: "View listing page requirements"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Template Marketplace Policies"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Requirements and compliance details for listing templates in the Template Marketplace."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/marketplace/template-policies",
            children: "View Template Marketplace policies"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "List and update themes on the Template Marketplace"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you've created your template, submit it for approval to be listed on the HubSpot Template Marketplace. Once submitted, templates will undergo a review and approval process by the HubSpot Template Marketplace team. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "Learn how to list and update your templates in the Template Marketplace"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supported payment gateways"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot currently offers the ability for marketplace providers to connect the following payment gateways to receive payments:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["PayPal (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.paypal.com/us/webapps/mpp/country-worldwide",
          children: "supported countries"
        }), " | ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.paypal.com/us/smarthelp/article/what-are-the-cross-border-fees-when-selling-international-faq2927",
          children: "cross border payments"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stripe (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/global",
          children: "supported countries"
        }), " | ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/docs/connect/cross-border-payouts",
          children: "cross border payments"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Payments from customers are received through the payment connected in your Marketplace Provider account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Provider Info"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Payment Method"
        }), " section, view or update your payment information."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot is not responsible for any payment gateway issues that may occur during the purchase process. HubSpot does not take a cut of any transactions, and all transactions are handled independently of the HubSpot platform. If your payment method stops working for any reason, your assets may be delisted temporarily to avoid a negative customer experience."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " HubSpot has customers worldwide. When setting up your payment gateways, we strongly recommend accepting payments from international currencies (cross border payments)."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["HubSpot strongly encourages Template Marketplace Providers to use the Stripe payment gateway if it is available in their country. If Stripe is not ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/global",
          children: "available in your country"
        }), ", then we recommend using the PayPal payment gateway."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Refund requests"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You may run into the occasional request from a purchaser for a refund. HubSpot recommends clearly stating your refund policy in a listing's description and providing a link to documentation about your refund policy. HubSpot encourages providers to honor valid refund requests. Learn more about general ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/resources/marketplace-refund-guidelines",
        children: "guidelines for refund requests"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Manage your transactions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A deal record is created in your Template Marketplace provider account for every template that a customer downloads. All deals will populate in a deal pipeline called ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Marketplace Transactions"
      }), " with the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Transactions"
      }), " deal stage. When a refund is issued for a transaction, the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Refunded at"
      }), " property will be set on the deal record."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Providers are able to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "customize the deal pipeline and deal stages,"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows/choose-your-workflow-actions#property-management",
        children: "use the deal properties in workflows"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Removal of template listings"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot reserves the right to remove Marketplace listings for reasons including, but not limited to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Bugs present in the template."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Third-party templates in the submission break."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The template is incompatible with ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/resources/which-browsers-are-supported-by-hubspot",
          children: "HubSpot-supported browsers."
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use of copyrighted material such as images, icons, fonts, audio, and video that are not licensed for distribution."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Templates that track users without their informed, explicit opt-in consent."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Templates (including linking to content from the Template Marketplace or from your template demo) that include:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Promoting or engaging in illegal activities."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Derogatory, disparaging, and/or malicious content."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Dishonest or false claims."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Offensive content about race, religion, age, gender, or sexual orientation."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Modules will be removed if the module affects other elements on the page/email that it is added to."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Frequently Asked Questions"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Does the European Union P2B regulation (July 12,2020) apply to the App and Template Marketplaces?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No, the most recent European Union P2B regulation that took effect on July 12, 2020, does not apply to the HubSpot App and Template Marketplaces. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ec.europa.eu/digital-single-market/en/business-business-trading-practices",
          children: "Learn More"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Is there a specific HubSpot account type I need to submit to the Marketplace?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://offers.hubspot.com/free-marketplace-account",
          children: "Marketplace provider account"
        }), " is needed to submit to the Template Marketplace."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Some partners manage their Marketplace listings in their Partner account to use the templates in the same account. Other partners manage their listings in a separate Marketplace account to keep the templates separate from other templates in their design manager tool."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Do products published in the Marketplace live in my account or in the HubSpot Marketplace system?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Products submitted to or published in the Marketplace live in the HubSpot Marketplace system. This ensures that providers can continue to edit them in their own account without the changes affecting customers’ purchases. To update a Marketplace listing, a provider must resubmit the listing so it can be reviewed and republished."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Customers who have purchased previous versions of a product in a listing can ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/articles/kcs_article/resources/marketplace-customers-frequently-asked-questions#update",
          children: "update to the latest version"
        }), " free of charge."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How do I update my company name?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Once you have listed a template in the marketplace, you will need to contact ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://help.hubspot.com/",
          children: "HubSpot Support"
        }), "  to change your company name."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Why don't I see all of my products when trying to create a listing?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Possible reasons you may not see a product when trying to create a listing include:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template is currently listed in the Marketplace or contains elements from a currently listed template."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template isn't supported in the Marketplace (transactional emails, ticket emails, etc.)"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template is a clone of a template from the Marketplace."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "What should I use as a preview link?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["When creating preview URLs you must use your own domain name if available. If you don't have a domain available to use, HubSpot provides each account with a system domain in the following URL structure:", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[AccountID].hs-sites.com"
        })]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": HubSpot does not support template previews that use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "preview.hs-sites.com"
        }), " subdomain."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Can a single template be used in multiple themes?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Yes, a single template can be used in multiple themes. A common example of when a template may be used in multiple themes is if a provider offers a Starter and Premium version of the same theme."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Do I have to resubmit a theme or module if I make changes to it?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Yes. All changes to a theme or module must be re-submitted to the Marketplace for pre-validation and approval by the quality team. Once the changes are approved, the listing and template will be updated in the Marketplace for customers."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How do I adjust the price of a theme or module?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "To adjust the price of a submitted product, you must resubmit it to the Marketplace with updated information. Prices cannot be changed from free to paid, or vice versa once a template is published."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "How do I receive Marketplace payments?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Payments are received through the payment method that you've connected in your Marketplace Provider Profile. In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Provider Info"
        }), ". In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Payment Method"
        }), " section, view or update your payment information."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot doesn’t take a cut of any transactions, and all transactions are handled independently of the HubSpot platform."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Where do I go to get peer-to-peer feedback or connect with other Marketplace providers? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Join the #marketplace channel in HubSpot's Developer Slack by signing up at ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "https://developers.hubspot.com/slack"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "When a customer requests a refund, do their downloaded files remain in their HubSpot account?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "All files associated with the template as well as any dependencies (e.g. pages created with the template, cloned templates, and modules) will be removed once the refund is initiated."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How can I stay informed on the latest developer requirements and changes? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To stay informed of the latest changes, visit and subscribe to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "HubSpot Developer Changelog"
        }), "."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}