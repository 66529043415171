"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 85488833916;
const slug = exports.slug = 'guides/api/crm/exports';
const title = exports.title = 'CRM API | エクスポート';
const name = exports.name = 'CRM API | エクスポート';
const metaDescription = exports.metaDescription = 'エクスポートAPIを使用して、HubSpotアカウントからビューまたはリストのレコードをエクスポートする方法をご紹介します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A8%E3%82%AF%E3%82%B9%E3%83%9D%E3%83%BC%E3%83%88%E3%82%92%E9%96%8B%E5%A7%8B%E3%81%99%E3%82%8B",
  "label": "エクスポートを開始する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%93%E3%83%A5%E3%83%BC%E3%82%92%E3%82%A8%E3%82%AF%E3%82%B9%E3%83%9D%E3%83%BC%E3%83%88%E3%81%99%E3%82%8B",
  "label": "ビューをエクスポートする",
  "parent": "%E3%82%A8%E3%82%AF%E3%82%B9%E3%83%9D%E3%83%BC%E3%83%88%E3%82%92%E9%96%8B%E5%A7%8B%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%AA%E3%82%B9%E3%83%88%E3%82%92%E3%82%A8%E3%82%AF%E3%82%B9%E3%83%9D%E3%83%BC%E3%83%88%E3%81%99%E3%82%8B",
  "label": "リストをエクスポートする",
  "parent": "%E3%82%A8%E3%82%AF%E3%82%B9%E3%83%9D%E3%83%BC%E3%83%88%E3%82%92%E9%96%8B%E5%A7%8B%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%82%A8%E3%82%AF%E3%82%B9%E3%83%9D%E3%83%BC%E3%83%88%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "エクスポートを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E4%B8%8A%E9%99%90",
  "label": "上限",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      pre: "pre",
      strong: "strong",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "エクスポート"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["エクスポートAPIを使用すると、HubSpotアカウントからレコードやプロパティーデータをエクスポートしたり、エクスポートファイルのダウンロードURLを取得したり、エクスポートのステータスを確認したりできます。HubSpotでは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/export-contacts-companies-deals-or-tickets",
        children: "レコードをエクスポート"
      }), "したり、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-a-log-of-your-users-exports-in-your-account",
        children: "アカウントでの過去のエクスポートのログを表示"
      }), "したりできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "エクスポートを開始する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["エクスポートを開始するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/exports/export/async"
      }), "に送信します。リクエスト本文では、ファイル形式、エクスポートするオブジェクトとプロパティー、実行するエクスポートの種類（例：オブジェクトビューまたはリストのエクスポート）などの情報を指定する必要があります。特定の演算子に基づいてエクスポートするプロパティーデータを絞り込むこともできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ビューとリストのどちらのエクスポートの場合も、リクエストに以下のフィールドを含めることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exportType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["エクスポートのタイプ。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "VIEW"
            }), "（オブジェクトのインデックスページからビューをエクスポートする場合）または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LIST"
            }), "（リストをエクスポートする場合）のどちらかを指定します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ファイル形式。オプションは、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "XLSX"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSV"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "XLS"
            }), "です。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exportName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "エクスポートの名前。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["エクスポートファイルの言語。オプションは、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DE"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EN"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ES"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FI"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FR"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IT"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "JA"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NL"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PL"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PT"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SV"
            }), "です。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-language-offerings",
              children: "HubSpotの対応言語の詳細をご確認ください。"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["エクスポートするオブジェクトの名前またはID。標準オブジェクトの場合はオブジェクトの名前（例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT"
            }), "）を使用できますが、カスタムオブジェクトの場合は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypeId"
            }), "値を使用する必要があります。この値は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/schemas"
            }), "に送信することで取得できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associatedObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "エクスポートに含める、関連付けられたオブジェクトの名前またはID。関連付けられたオブジェクトを含める場合、エクスポートにはそのオブジェクトに関連付けられているレコードIDと、そのレコードのプライマリー表示プロパティー値（名前など）が含まれることになります。エクスポートできる関連付けられたオブジェクトの数は、1回のリクエストにつき1つのみです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "エクスポートに含めるプロパティーのリスト。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ビューをエクスポートする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "インデックスページのビュー"
      }), "をエクスポートする場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exportType"
      }), "の値を", (0, _jsxRuntime.jsx)(_components.code, {
        children: "VIEW"
      }), "に設定する必要があります。エクスポートするレコードの絞り込みや並べ替えを行うには、以下のフィールドを追加します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publicCrmSearchRequest"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["特定のプロパティー値と検索クエリーに基づいてエクスポートするデータを指定します。このオブジェクトには、次のフィールドを含めることができます。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "filters"
            }), "：レコードを絞り込む基準とするプロパティーとプロパティー値。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "sorts"
            }), "：プロパティーの値の並べ替え順。昇順（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ASC"
            }), "）、降順（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "DES"
            }), "）のいずれかです。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "query"
            }), "：レコードの値で検索する対象の文字列。"]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、コンタクトとコンタクトに関連付けられている会社レコードのビューを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), "プロパティーを基準に絞り込んでエクスポートする場合、リクエストは次のようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"exportType\": \"VIEW\",\n  \"exportName\": \"All contacts\",\n  \"format\": \"xlsx\",\n  \"language\": \"DE\",\n  \"objectType\": \"CONTACT\",\n  \"objectProperties\": [\"email, firstname, lastname\"],\n  \"associatedObjectType\": \"COMPANY\",\n  \"publicCrmSearchRequest\": {\n    \"filters\": [\n      {\n        \"value\": \"hello@test.com\",\n        \"propertyName\": \"email\",\n        \"operator\": \"EQ\"\n      }\n    ],\n    \"query\": \"hello\",\n    \"sorts\": [\n      {\n        \"propertyName\": \"email\",\n        \"order\": \"ASC\"\n      }\n    ]\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "リストをエクスポートする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/lists/create-active-or-static-lists",
        children: "リスト"
      }), "をエクスポートする場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exportType"
      }), "の値を", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LIST"
      }), "に設定する必要があります。また、次のフィールドを使用して、エクスポートするリストを指定する必要もあります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "listId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["エクスポートするリストの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/lists/lists-faq#ils-list",
              children: "ILSリストID"
            }), "。ILSリストIDの値は、HubSpotのリストの詳細から確認できます。［コンタクト］＞［リスト］の順に進み、表内のいずれかの", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "リスト"
            }), "にカーソルを合わせて、［詳細］をクリックします。右側のパネルで、ILSリストIDの値の横に表示される**［リストIDをコピー］**をクリックします。コンタクトリストには2つの異なるID値がありますが、リクエストではILSリストIDの値を使用することが", (0, _jsxRuntime.jsx)("u", {
              children: "必須"
            }), "です。"]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、コンタクトのEメールを含むリストをエクスポートする場合、リクエストは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"exportType\": \"LIST\",\n  \"listId\": 1234567,\n  \"exportName\": \"Marketing email contacts\",\n  \"format\": \"xlsx\",\n  \"language\": \"EN\",\n  \"objectType\": \"CONTACT\",\n  \"objectProperties\": [\"email\"]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "エクスポートを取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["エクスポートが正常に完了すると、レスポンスでエクスポートの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "が返されます。HubSpotアカウントからエクスポートを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/exports/export/async/tasks/{exportId}/status"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["エクスポートを取得する際は、エクスポートの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "status"
      }), "も返されます。有効なステータスには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPLETE"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PENDING"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROCESSING"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CANCELED"
      }), "があります。エクスポートのステータスが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "COMPLETE"
      }), "の場合、エクスポートしたファイルをダウンロードするために使用できるURLが返されます。ダウンロードURLは、リクエストが完了してから5分後に期限切れになります。期限切れになったら、別の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを実行して、新しい一意のURLを生成できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**有効期限が切れるまでは、追加の承認なしでエクスポートのダウンロードURLにアクセスできます。データを保護するために、URLの共有や、このAPIを介したHubSpotとの連携は慎重に進めてください。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "上限"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次の上限が適用されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["エクスポートのフィルターを設定する場合は、最大3つの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), "を追加し、各グループに最大3つの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), "を含めることができます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "24時間の期間ごとに、最大30回のエクスポートを完了できます。ただし、同時に実行できるエクスポートは1つのみです。追加のエクスポートは、前回のエクスポートが完了するまでキューに入れられます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "エクスポートされるCSVファイルの最終的なファイルサイズが2MBを超える場合は、自動的にファイルが圧縮されます。"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}