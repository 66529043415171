module.exports = {
  "request-interaction": {
    "name": "request interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "change language",
        "copy request"
      ],
      "language": {
        "type": "string"
      },
      "endpoint": {
        "type": "string"
      },
      "method": {
        "type": "string"
      }
    },
    "namespace": "developer-docs-ui",
    "version": "1"
  },
  "test-call": {
    "name": "test call",
    "class": "usage",
    "properties": {
      "action": [
        "make test call"
      ],
      "authType": {
        "type": [
          "developer_hapikey",
          "oauth",
          "private_apps",
          "noauth"
        ]
      },
      "endpoint": {
        "type": "string"
      },
      "method": {
        "type": "string"
      },
      "status": {
        "type": "number"
      },
      "demo": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "developer-docs-ui",
    "version": "1"
  },
  "param-form-interaction": {
    "name": "param form interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "modify field value"
      ],
      "paramType": {
        "type": "string"
      }
    },
    "namespace": "developer-docs-ui",
    "version": "1"
  },
  "help-link-interaction": {
    "name": "help link interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked API key account settings link",
        "clicked developer API key docs link",
        "clicked OAuth learn more link",
        "clicked Private apps learn more link"
      ]
    },
    "namespace": "developer-docs-ui",
    "version": "1"
  }
};