"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 75327506237;
const slug = exports.slug = 'reference/api/other-resources/error-handling';
const title = exports.title = 'エラー処理';
const name = exports.name = 'エラー処理';
const metaDescription = exports.metaDescription = 'HubSpotのAPIを使用して開発する際によくあるAPIエラーを処理する方法を説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%9E%E3%83%AB%E3%83%81%E3%82%B9%E3%83%86%E3%83%BC%E3%82%BF%E3%82%B9%E3%82%A8%E3%83%A9%E3%83%BC",
  "label": "マルチステータスエラー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%86%8D%E8%A9%A6%E8%A1%8C",
  "label": "再試行",
  "parent": null
}, {
  "depth": 1,
  "id": "webhook",
  "label": "Webhook",
  "parent": "%E5%86%8D%E8%A9%A6%E8%A1%8C"
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%82%B3%E3%83%BC%E3%83%89%E3%81%AE%E3%83%AF%E3%83%BC%E3%82%AF%E3%83%95%E3%83%AD%E3%83%BC%E3%82%A2%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "カスタムコードのワークフローアクション",
  "parent": "%E5%86%8D%E8%A9%A6%E8%A1%8C"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      ul: "ul",
      li: "li",
      a: "a",
      pre: "pre",
      h2: "h2",
      h3: "h3",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "エラーを処理する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特に明記されていない限り、大半のHubSpotエンドポイントは、リクエストが正常に完了すると", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " OKレスポンスを返します。他のステータスコードを返すエンドポイントについては、エンドポイントのドキュメントに、どのレスポンスが返されるかが明記されています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "さらに、HubSpotには、複数のAPIに共通する次のようなエラーレスポンスがあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "207 Multi-Status"
        }), "：複数の異なるステータス（エラーと成功など）がある場合、このエラーレスポンスが返されます。こうした状況が発生するのは、オブジェクトAPIバッチ作成エンドポイントで", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#multi-status-errors",
          children: "マルチステータスのエラー処理"
        }), "が有効にされている場合です。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "401 Unauthorized"
        }), "：APIに渡された認証情報が無効な場合、このエラーレスポンスが返されます。APIリクエストの認証について詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "認証の概要"
        }), "（英語）をご覧ください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "403 Forbidden"
        }), "：APIに渡された認証対象にその特定のURLに対するアクセス権限が付与されていない場合、このエラーレスポンスが返されます。例えば、コンテンツのアクセス権限しか付与されていないOAuthトークンによって取引API（コンタクトアクセス権限が必要）にアクセスしようとすると、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), "が返されます。APIキーまたは非公開アプリに対する権限が必要であることを確認した場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "HubSpotのサポートチーム"
        }), "にお問い合わせください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "429 Too many requests"
        }), "：アカウントまたはアプリが、該当するAPI", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details",
          children: "レート制限"
        }), "を超えている場合、このエラーレスポンスが返されます。レート制限内で作業するための推奨事項については、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/working-within-the-hubspot-api-rate-limits",
          children: "こちら"
        }), "（英語）をご覧ください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "477 Migration in Progress"
        }), "：HubSpotアカウントが現在", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/eu-data-centre",
          children: "データのホスティング場所間で移行中"
        }), "の場合、このエラーレスポンスが返されます。HubSpotは、リクエストを再試行するまでに何秒待つかを示す", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Retry-After",
          children: "Retry-After"
        }), "レスポンスヘッダーを返します（通常は最大24時間）。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "502/504 timeouts"
        }), "：HubSpotの処理制限に達している場合、このエラーレスポンスが返されます。これらの制限は、単一のクライアントによってパフォーマンスの低下が招かれないようにするために設定されています。長時間にわたって大量のリクエストを送信すると、これらのタイムアウトレスポンスが表示されます。これらのレスポンスのいずれかが表示されたら、数秒間リクエストを一時停止してから再試行してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "503 service temporarily unavailable"
        }), "：HubSpotが一時的に利用できない場合、このエラーレスポンスが返されます。このレスポンスが表示されたら、数秒間リクエストを一時停止してから再試行してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "521 web server is down"
        }), "：HubSpotのサーバーがダウンしている場合、このエラーレスポンスが返されます。これは一時的な問題であるはずです。このレスポンスが表示されたら、数秒間リクエストを一時停止してから再試行してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "522 connnection timed out"
        }), "：HubSpotとアプリケーション間の接続がタイムアウトになった場合、このエラーレスポンスが返されます。このレスポンスが表示された場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "HubSpotのサポートチーム"
        }), "にお問い合わせください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "523 origin is unreachable"
        }), "：HubSpotがアプリケーションに接続できない場合、このエラーレスポンスが返されます。このレスポンスが表示されたら、数秒間リクエストを一時停止してから再試行してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "524 timeout"
        }), "：100秒以内に応答が受信されない場合、このエラーレスポンスが返されます。大規模なデータクエリーなどによってHubSpotのサーバーが過負荷状態になっていると、このエラーが発生する可能性があります。このレスポンスが表示されたら、数秒間リクエストを一時停止してから再試行してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "525/526 SSL issues"
        }), "：SSL証明書が無効であるか、またはSSLハンドシェイクが失敗した場合、このエラーレスポンスが返されます。このレスポンスが表示された場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "HubSpotのサポートチーム"
        }), "にお問い合わせください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記の全般的なエラーを除いて、HubSpotのエラーレスポンスは基本的に読みやすく記述されています。ほとんどのエンドポイントから、エラーコードではなく、エラーに関する詳細を記載したJSON形式のレスポンスが返されます。エンドポイント固有のエラーの詳細は、該当するエンドポイントのドキュメントページに記載されています。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**以下のレスポンスの例に示されている全てのフィールドは、エラー解析時には任意指定として扱ってください。APIごとに使用する具体的なフィールドは異なるため、エラー解析では、レスポンスに特定のフィールドが欠落していても許容する必要があります。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Structure of an example error from HubSpot\n{\n  \"status\": \"error\",\n  \"message\": \"This will be a human readable message with details about the error.\",\n  \"errors\": [\n    {\n      \"message\": \"This will be a message with additional details about the error\",\n      \"in\": \"name\"\n    }\n  ],\n  \"category\": \"VALIDATION_ERROR\",\n  \"correlationId\": \"a43683b0-5717-4ceb-80b4-104d02915d8c\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "マルチステータスエラー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "オブジェクトAPI'"
      }), "バッチ作成APIエンドポイントでは、部分的なエラーが含まれるマルチステータスのレスポンスを有効にすることができます。つまり、レスポンスには、どのレコードが作成され、どのレコードが作成されなかったかが示されます。このようにする場合は、リクエスト内の入力ごとに一意の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectWriteTraceId"
      }), "値を含めます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectWriteTraceId"
      }), "には、任意の一意の文字列を指定できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、チケットの作成リクエストは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request to POST crm/v3/objects/tickets/batch/create\n{\n  \"inputs\": [\n    {\n      \"properties\": {\n        \"objectWriteTraceId\": \"549b1c2a9350\",\n        \"hs_pipeline_stage\": \"1\"\n      },\n      \"properties\": {\n        \"objectWriteTraceId\": \"549b1c2a9351\",\n        \"missing\": \"1\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "レスポンスではステータスがグループ化されるため、どのチケットの作成が成功し、どのチケットの作成が失敗したかを確認できます。上記のリクエストに対するレスポンスは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"id\": \"1145814089\",\n      \"properties\": {\n        \"createdate\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_helpdesk_sort_timestamp\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_last_message_from_visitor\": \"false\",\n        \"hs_lastmodifieddate\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_object_id\": \"1145814089\",\n        \"hs_object_source\": \"API\",\n        \"hs_object_source_label\": \"INTERNAL_PROCESSING\",\n        \"hs_pipeline\": \"0\",\n        \"hs_pipeline_stage\": \"1\",\n        \"hs_ticket_id\": \"1145814089\"\n      },\n      \"createdAt\": \"2024-08-15T17:09:13.648Z\",\n      \"updatedAt\": \"2024-08-15T17:09:13.648Z\",\n      \"archived\": false\n    }\n  ],\n  \"numErrors\": 1,\n  \"errors\": [\n    {\n      \"status\": \"error\",\n      \"category\": \"VALIDATION_ERROR\",\n      \"message\": \"Property values were not valid: [{\\\"isValid\\\":false,\\\"message\\\":\\\"Property \\\\\\\"missing\\\\\\\" does not exist\\\",\\\"error\\\":\\\"PROPERTY_DOESNT_EXIST\\\",\\\"name\\\":\\\"missing\\\",\\\"localizedErrorMessage\\\":\\\"Property \\\\\\\"missing\\\\\\\" does not exist\\\",\\\"portalId\\\":891936587}]\",\n      \"context\": {\n        \"objectWriteTraceId\": [\"549b1c2a9351\"]\n      }\n    }\n  ],\n  \"startedAt\": \"2024-08-15T17:09:13.610Z\",\n  \"completedAt\": \"2024-08-15T17:09:13.910Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "再試行"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリまたは連携で、HubSpotが呼び出すAPIエンドポイント（Webhookサブスクリプションなど）が提供されている場合、エンドポイントからどのエラーがスローされても、HubSpotはリクエストを再試行します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhook"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "貴社のサービスに通知の処理の問題が発生した場合はいつでも、HubSpotは最大10回、失敗した通知の再送を試みます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotが再試行する場合は次のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**接続失敗：**指定されたWebhook URLへのHTTP接続をHubSpotが開始できない場合。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**タイムアウト：**一括通知に対するレスポンスが貴社のサービスから5秒以内に返されない場合。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**エラーコード：**貴社のサービスがHTTPステータスコード（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4xx"
        }), "または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "5xx"
        }), "）で応答した場合。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["4xxシリーズのレスポンス ステータス コードを受信した後、ワークフローは再試行", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "されません"
      }), "。このルールの1つの例外は429レート制限エラーです。429レスポンスを受け取った後、ワークフローは自動的に再試行されます。また、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Retry-After"
      }), "ヘッダーが存在する場合は、このヘッダーでの指定に従います。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Retry-After"
      }), "値はミリ秒単位であることに注意してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "通知の送信は最大で10回再試行されます。再試行は、24時間にわたりリクエストの送信間隔を変えながら実行されます。失敗が同時に多発した場合の再試行時刻が完全に同じにならないように、個々の通知にある程度のばらつきが確保されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カスタムコードのワークフローアクション"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ワークフローで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/custom-code-actions",
        children: "カスタム コード アクション"
      }), "を作成する場合、レート制限エラーや、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "axios"
      }), "あるいは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/api-client"
      }), "から返された", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "5XX"
      }), "エラーが原因でアクションでのAPI呼び出しが失敗すると、HubSpotはエラー発生の1分後から最大3日間、そのアクションを再試行します。以降のアクション再試行が失敗した場合、徐々に間隔を延ばしてアクションが再試行されます。再試行の間隔は最大で8時間です。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}