"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32924700882;
const slug = exports.slug = 'guides/apps/marketplace/measuring-app-performance';
const title = exports.title = 'Messen der App-Performance';
const name = exports.name = 'App-Performance messen';
const metaDescription = exports.metaDescription = 'Erfahren Sie in der Detailansicht auf der App-Listing-Seite mehr über die Messung der Performance Ihrer App.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "die-analytics-ihrer-app-%C3%BCberpr%C3%BCfen",
  "label": "Die Analytics Ihrer App überprüfen",
  "parent": null
}, {
  "depth": 1,
  "id": "marketplace-analytics",
  "label": "Marketplace-Analytics",
  "parent": "die-analytics-ihrer-app-%C3%BCberpr%C3%BCfen"
}, {
  "depth": 0,
  "id": "kundenfeedback",
  "label": "Kundenfeedback",
  "parent": null
}, {
  "depth": 0,
  "id": "wichtige-ressourcen",
  "label": "Wichtige Ressourcen",
  "parent": null
}, {
  "depth": 0,
  "id": "interaktion-mit-utm-parametern-messen",
  "label": "Interaktion mit UTM-Parametern messen",
  "parent": null
}, {
  "depth": 2,
  "id": "verwandte-dokumente",
  "label": "Verwandte Dokumente",
  "parent": "interaktion-mit-utm-parametern-messen"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      em: "em",
      img: "img",
      code: "code",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "App-Performance messen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Performance-Kennzahlen für alle im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "App Marketplace"
      }), " gelisteten Apps in Ihrem Entwickler-Account anzeigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die Analytics Ihrer App überprüfen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "App-Entwickler-Account"
        }), " zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App-Listings"
        }), ". Dadurch gelangen Sie zu einem Dashboard mit all Ihren App Marketplace-Listings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf eine ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Marketplace-Analytics"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Überprüfen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Installationen"
      }), " detaillierte Kennzahlen zur Performance Ihrer App. Um Daten über einen bestimmten Zeitraum zu analysieren, klicken Sie oben auf der Seite auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Häufigkeit"
      }), " und wählen Sie einen Zeitrahmen aus."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können folgende Daten analysieren:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "App-Installationen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "App-Deinstallationen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "App-Installationen vs. Deinstallationen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Kostenlose vs. kostenpflichtige Installationen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Kostenlose vs. kostenpflichtige Deinstallationen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Installationen nach Land"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Installationen nach Produkt"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/marketplace-app-analytics.png",
        alt: "marketplace-app-analytics"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sehen Sie sich im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Listing-Analytics"
      }), " die Kennzahlen für die Listing-Seite der App an. Um Daten über einen bestimmten Zeitraum zu analysieren, klicken Sie oben auf der Seite auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Häufigkeit"
      }), " und wählen Sie einen Zeitrahmen aus."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können folgende Daten analysieren:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Seitenaufrufe"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Seitenaufrufquelle"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CTA-Nutzung"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Kostenlose vs. kostenpflichtige Seitenaufrufe"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Seitenaufrufe nach Land"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Seitenaufrufe nach Produkt"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-analytics.png",
        alt: "listing-analytics"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kundenfeedback"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um alle Rezensionen anzuzeigen, die Kunden für Ihre App eingesendet haben, klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Kundenfeedback"
      }), " ."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/customer-feedback-tab.png",
        alt: "customer-feedback-tab"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Bewertungen & Rezensionen"
      }), " können Sie die Rezensionen nach der Anzahl der vergebenen Sterne, der Branche des Kunden und der Unternehmensgröße des Kunden filtern."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So antworten Sie auf eine Rezension:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in einer Rezension auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Antworten"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie im Pop-up-Feld Ihre ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Antwort"
        }), " an den Kunden ein und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Senden"
        }), ". Diese Antwort ist in Ihrem App-Listing öffentlich sichtbar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So bearbeiten Sie Benachrichtigungen für erhaltene Rezensionen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aktionen"
        }), " und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Benachrichtigungen bearbeiten"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Suchen Sie auf der Seite „Benachrichtigungen“ nach ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " und wählen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Neue Listing-Bewertungen"
        }), " aus, um benachrichtigt zu werden, wenn ein Kunde eine Rezension abgibt."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So senden Sie Ihren Bewertungslink an einen Kunden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aktionen"
        }), " und klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bewertungslink kopieren"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Senden Sie eine E-Mail oder diesen Link an Ihren Kunden, damit er eine Rezension abgeben kann."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Überprüfen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Umfrageantworten zur Deinstallation"
      }), " alle Antworten der Umfrage."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um die Antworten zu exportieren, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportieren"
        }), " und wählen Sie das Dateiformat aus."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Überprüfen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Privates Feedback"
      }), " alle Antworten auf privates Feedback an Ihr Team."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Wichtige Ressourcen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Überprüfen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Wichtige Ressourcen"
      }), " die folgenden Ressourcen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Der Leitfaden zu den Vorteilen des App-Partnerprogramms, in dem alle Leistungen aufgeführt sind, die Sie als Partner mit einer im Hubspot App Marketplace gelisteten App erhalten."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Eine Feedbackumfrage, die Sie ausfüllen können, um Feedback zum App-Partner-Erlebnis zu geben."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die Kontaktinformationen deines App-Partner-Managers."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Interaktion mit UTM-Parametern messen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zusätzlich zu den oben genannten Kennzahlen können Sie die Interaktion weiter messen, indem Sie UTM-Parameter in die URLs auf Ihrer App-Listing-Seite aufnehmen. Auf diese Weise können Sie mithilfe von HubSpot, Google Analytics oder anderen Analytics-Plattformen Ihrer Wahl sehen, wie viel Traffic von Ihrer Listing-Seite zu Ihrer Website gelangt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es wird empfohlen, UTM-Parameter zu den folgenden URLs auf Ihrer Listing-Seite hinzuzufügen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Unterstützender Content auf Ihrer App Marketplace-Listing-Seite, z. B. Ihre Unternehmenswebsite, Begleitdokumentation, Fallstudie und Datenschutzrichtlinie."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die OAuth-Installations-URL, die Kunden bei der Installation der App verwenden."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie könnten beispielsweise die folgende Zeichenfolge von UTM-Parametern am Ende Ihrer Dokumentations-URL hinzufügen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "?utm_campaign=appmarketplacelisting&utm_medium=referral&utm_source=hubspot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Es wird empfohlen, UTM-Parameter zu verwenden, die mit anderen UTM-Tracking-Funktionen übereinstimmen, die Sie oder Ihr Marketingteam möglicherweise verwenden. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/customers/understanding-basics-utm-parameters",
          children: "Grundlagen der UTM-Parameter"
        }), " und wie Sie UTM-Tracking-URLs mit ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/how-do-i-create-a-tracking-url",
          children: "HubSpot"
        }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/what-are-utm-tracking-codes-ht",
          children: "Google Analytics"
        }), " erstellen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So fügen Sie UTM-Parameter zur OAuth-Installations-URL hinzu:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem App-Entwickler-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " der App, um ihre Details zu bearbeiten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in der linken Seitenleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Grundlegende Informationen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Authentifizierung"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Aktualisieren Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Weiterleitungs-URLs"
        }), " Ihre Weiterleitungs-URL, damit sie Ihre UTM-Parameter enthält. Dadurch wird die Installations-URL der App nach dem Speichern aktualisiert. Sie müssen also sicherstellen, dass Sie alle benutzerorientierten Links aktualisiert haben, um die neue URL zu verwenden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/update-redirect-url.png",
        alt: "update-redirect-url"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Speichern als"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Das Feld für die URL der Installationsschaltfläche hat ein Limit von 250 Zeichen. Wenn Ihre UTM-Parameter zu einer Überschreitung dieses Limits führen, müssen Sie möglicherweise einen URL-Shortener wie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://bitly.com/",
          children: "Bitly"
        }), " verwenden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So fügen Sie UTM-Parameter zu den unterstützenden Inhalten der App hinzu:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Gehen Sie in Ihrem App-Entwickler-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "App Marketplace"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listings"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie in der Tabelle ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketplace-Listings"
          }), " auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Namen"
          }), " der App."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Aktualisieren Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Listing-Informationen"
          }), " die URL im Feld „", (0, _jsxRuntime.jsx)(_components.em, {
            children: "URL"
          }), " der Installationsschaltfläche“ mit Ihren UTM-Parametern."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/install-button-url.png",
            alt: "install-button-url"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Support-Informationen"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Aktualisieren Sie in den Abschnitten ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Kontaktinformationen"
          }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Support-Ressourcen"
          }), " sowie ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Nutzungsbedingungen und Datenschutzrichtlinie"
          }), " die URLs mit Ihren UTM-Parametern."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/support-resources-section.png",
            alt: "support-resources-section"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Nachdem Sie Ihre URLs aktualisiert haben, klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Zur Überprüfung einreichen"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nach der Überprüfung und Genehmigung werden die URLs auf der Listing-Seite Ihrer App mit Ihren UTM-Parametern aktualisiert. Sie können dann Analytics-Tools wie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-your-site-traffic-with-the-traffic-analytics-tool",
        children: "HubSpot"
      }), " oder Google Analytics verwenden, um den von Ihren URLs kommenden Traffic nach Ihren UTM-Parametern zu kategorisieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachte:"
        }), " Da dein App-Listing über Google und andere Suchmaschinen gefunden werden kann, sollte auch unbedingt sichergestellt werden, dass Ihr Listing SEO-freundlich ist. Eine empfohlene Strategie zur Verbesserung Ihrer SEO sind Backlinks. Unabhängig davon, ob Sie Website-Content schreiben, E-Mail-Newsletter versenden oder Social-Media-Nachrichten verfassen, sollten Sie Links zu Ihrer Listing-Seite zusammen mit relevanten Informationen zu Ihrer Integration hinzufügen. Sie können Ihre Reichweite durch Strategien wie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/guest-blogging",
          children: "Gastbeiträge auf anderen Blogs"
        }), " weiter ausbauen, um die Website-Autorität zu verbessern."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/backlink-strategies",
          children: "kreative Möglichkeiten, Backlinks zu verdienen"
        }), ", und sehen Sie sich die kostenlose ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/lessons/link-building-tutorial",
          children: "HubSpot Academy-Lektion zum Thema Link-Building"
        }), " an."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Verwandte Dokumente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "Voraussetzungen für die App-Zertifizierung"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "App-Listing-Voraussetzungen"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}