"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76685378216;
const slug = exports.slug = 'guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app';
const title = exports.title = 'Eine Integration mit API-Schlüssel zu einer privaten App migrieren';
const name = exports.name = 'EMEA DACH (DE) Migrate an API key integration to a private app';
const metaDescription = exports.metaDescription = 'Erfahren Sie, wie Sie eine API-Schlüsselintegration zu einer privaten App migrieren.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "in-diesem-leitfaden",
  "label": "In diesem Leitfaden",
  "parent": null
}, {
  "depth": 0,
  "id": "eine-neue-private-app-erstellen",
  "label": "Eine neue private App erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "die-autorisierungsmethode-der-api-anfragen-ihrer-integration-aktualisieren",
  "label": "Die Autorisierungsmethode der API-Anfragen Ihrer Integration aktualisieren",
  "parent": null
}, {
  "depth": 0,
  "id": "anfragen-%C3%BCberpr%C3%BCfen-und-protokolle-%C3%BCberwachen",
  "label": "Anfragen überprüfen und Protokolle überwachen",
  "parent": null
}, {
  "depth": 0,
  "id": "implementierungsbeispiele",
  "label": "Implementierungsbeispiele",
  "parent": null
}, {
  "depth": 1,
  "id": "serverlose-funktionen",
  "label": "Serverlose Funktionen",
  "parent": "implementierungsbeispiele"
}, {
  "depth": 1,
  "id": "einmalige-aufgaben",
  "label": "Einmalige Aufgaben",
  "parent": "implementierungsbeispiele"
}, {
  "depth": 1,
  "id": "benutzerdefinierte-objekte-erstellen",
  "label": "Benutzerdefinierte Objekte erstellen",
  "parent": "implementierungsbeispiele"
}, {
  "depth": 1,
  "id": "workflow-aktionen-mit-benutzerdefiniertem-code",
  "label": "Workflow-Aktionen mit benutzerdefiniertem Code",
  "parent": "implementierungsbeispiele"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      em: "em",
      h2: "h2",
      strong: "strong",
      img: "img",
      code: "code",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Migrate an API key integration to a private app"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Wenn Sie in Ihrem Account ein Banner zur Deaktivierung Ihres API-Schlüssels sehen:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Stellen Sie sicher, dass Sie alle betroffenen Integrationen migriert haben, und ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/de/integrations/how-do-i-get-my-hubspot-api-key#deactivate-your-api-key",
              children: "deaktivieren Sie dann den API-Schlüssel"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Um zu überprüfen, ob der API-Schlüssel des Accounts in den letzten sieben Tagen verwendet wurde, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/de/integrations/how-do-i-get-my-hubspot-api-key#view-your-api-key-call-log",
              children: "den Verlauf des Aufrufprotokolls für Ihren API-Schlüssel anzeigen"
            }), ". Im Aufrufprotokoll werden keine mit dem Schlüssel durchgeführten Anfragen angezeigt, die älter als sieben Tagen sind."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Apps, die auf der ", (0, _jsxRuntime.jsxs)(_components.a, {
              href: "https://knowledge.hubspot.com/de/integrations/connect-apps-to-hubspot#:~:text=You%20can%20view%20all%20your,Connected%20Apps%20page.",
              children: ["Seite ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Verknüpfte Apps"
              }), " Ihrer Account-Einstellungen"]
            }), " aufgeführt sind, müssen nicht migriert werden, da sie sich mit OAuth authentifizieren."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Die API-Schlüssel für Entwickler sind von den Standard-API-Schlüsseln von HubSpot getrennt und werden ", (0, _jsxRuntime.jsx)("u", {
              children: "nicht"
            }), " eingestellt. Entwickler-API-Schlüssel werden für die Verwaltung von Einstellungen im Zusammenhang mit Ihren HubSpot-Apps verwendet, einschließlich ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview",
              children: "Webhooks-API"
            }), "-Abonnements und ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/extensions/timeline",
              children: "Chronik-Events-API"
            }), "-Event-Typen."]
          }), "\n"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine interne Integration erstellt haben, die einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/integrations/how-do-i-get-my-hubspot-api-key",
        children: "HubSpot-API-Schlüssel"
      }), " verwendet, bietet Ihr API-Schlüssel sowohl Lese- als auch Schreibzugriff auf alle Ihre HubSpot-CRM-Daten. Dies kann ein Sicherheitsrisiko darstellen, wenn Ihr API-Schlüssel kompromittiert wird. Durch die Migration zu einer privaten App können Sie die spezifischen Bereiche autorisieren, die Ihre Integration erfordert. Dadurch wird ein Zugriffstoken generiert, das die Daten begrenzt, die Ihre Integration in Ihrem Account anfragen oder ändern kann."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Befolgen Sie die Schritte unten, um eine vorhandene Integration mit API-Schlüssel zu einer privaten App zu migrieren. Es wird empfohlen, zuerst eine Testumgebung zu verwenden, z. B. einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "Entwickler-Test-Account"
      }), " oder einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/account/set-up-a-hubspot-sandbox-account",
        children: "Sandboxk-Account"
      }), ", bevor Sie Änderungen in der Produktion vornehmen. Wenn Sie während der Migration Ihrer App Fragen haben, besuchen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "Entwickler-Community"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eine Videoanleitung zum Migrieren einer API-Schlüsselintegration zu einer privaten App finden Sie im HubSpot-Entwickler-Video unten:"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/F3Qd9Hp4giM?start=29&feature=oembed",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "In diesem Leitfaden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-new-private-app",
          children: "Eine neue private App erstellen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#update-the-authorization-method-of-your-integration-s-api-requests",
          children: "Die Autorisierungsmethode der API-Anfragen Ihrer Integration aktualisieren"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#verify-requests-and-monitor-logs",
          children: "Anfragen überprüfen und Protokolle überwachen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#implementation-examples",
          children: "Implementierungsbeispiele"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Private Apps unterstützen nicht Webhooks und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/extensions/overview",
          children: "bestimmte Typen von Erweiterungen"
        }), ". Wenn Ihre vorhandene Integration eine dieser Funktionen verwendet, sollten Sie stattdessen mithilfe von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth"
        }), " eine öffentliche App erstellen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine neue private App erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie in Ihrem HubSpot-Account in der Hauptnavigationsleiste auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Zahnradsymbol"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Gehen Sie in der linken Seitenleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Integrationen"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Private Apps"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Private App erstellen"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Konfigurieren Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Grundlegende Informationen"
          }), " die Details Ihrer App:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Geben Sie den ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Namen"
            }), " Ihrer App ein."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Bewegen Sie den Mauszeiger über das Platzhalterlogo und klicken Sie auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Symbol zum Hochladen"
            }), ", um ein quadratisches Bild hochzuladen, das als Logo für Ihre App dienen soll."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Geben Sie eine ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Beschreibung"
            }), " für Ihre App ein."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Bereiche"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Wählen Sie als Nächstes die Bereiche aus, die basierend auf den von Ihrer Integration verwendeten APIs autorisiert werden sollen. So finden Sie heraus, welche Bereiche Ihre App benötigt:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Stellen Sie eine Liste der HubSpot-APIs zusammen, die Ihre vorhandene Integration verwendet."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Gehen Sie für jede API-Anfrage zur zugehörigen Entwicklerdokumentation (z. B. zu der für die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "Kontakte-API"
            }), ")."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Endpunkte"
            }), " und scrollen Sie dann zu dem Endpunkt, den Ihre Integration verwendet."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Suchen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Anforderungen"
            }), " die Bereiche, die für die Verwendung des Endpunkts erforderlich sind. Wenn möglich, sollten Sie sich für die unter ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Granulare Bereich(e)"
            }), " aufgeführten Bereiche entscheiden, und nicht für die unter ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Standardbereich(e)"
            }), ". Wenn keine granularen Bereiche aufgeführt sind, verwenden Sie die Standardbereiche."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/locate-scope-in-endpoints-tab-for-private-app-migration.png",
            alt: "locate-scope-in-endpoints-tab-for-private-app-migration"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Gehen Sie zurück zu den Einstellungen für Ihre private App und aktivieren Sie die Kontrollkästchen ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Lesen"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Schreiben"
            }), " neben den entsprechenden Bereichen. Sie können auch über die Suchleiste ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Bereich suchen"
            }), " nach einem Bereich suchen."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/select-matching-scope-for-private-app.png",
            alt: "select-matching-scope-for-private-app"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Nachdem Sie Ihre Bereiche ausgewählt haben, klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "App erstellen"
          }), ". Sie können jederzeit Änderungen an Ihrer App vornehmen, nachdem Sie diese erstellt haben."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Überprüfen Sie im Dialogfeld die Informationen zum Zugriffstoken Ihrer App und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Mit dem Erstellen fortfahren"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Ihre private App erstellt ist, können Sie mithilfe von ihrem Zugriffstoken API-Anfragen erstellen. Klicken Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Details"
      }), " auf der Einstellungsseite Ihrer privaten App unter Ihrem Zugriffstoken auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Token anzeigen"
      }), ", um es zu enthüllen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-migration-guide.png",
        alt: "show-private-app-access-token-migration-guide"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die Autorisierungsmethode der API-Anfragen Ihrer Integration aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anstatt einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hapiKey"
      }), "-Abfrageparameter zu verwenden, um API-Anfragen durchzuführen, werden Zugriffstoken von privaten Apps im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), "-Header Ihrer Anfrage aufgenommen. Legen Sie bei einer Anfrage den Wert des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), "-Headers auf ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Bearer YOUR_ACCESS_TOKEN"
      }), " fest. Sofern nicht anders angegeben, ist diese Autorisierungsmethode mit allen öffentlichen API-Endpunkten kompatibel, einschließlich der in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "HubSpot-Legacy-Entwicklerdokumentation"
      }), " aufgeführten Legacy-APIs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre Anfrage kann wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "axios.get(\n  'https://api.hubapi.com/crm/v3/objects/contacts',\n  {\n    headers: {\n      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "$headers = [\n    'Content-Type: application/json',\n    'Authorization: Bearer ' . YOUR_ACCESS_TOKEN,\n];\n\n$curl = curl_init();\n\ncurl_setopt($curl, CURLOPT_HTTPHEADER, $headers);\ncurl_setopt($curl, CURLOPT_URL, 'https://api.hubapi.com/crm/v3/objects/contacts');\ncurl_setopt($curl, CURLOPT_RETURNTRANSFER, true);\n\n$contacts = curl_exec($curl);\ncurl_close($curl);\n\nvar_dump($contacts);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "require 'uri'\nrequire 'net/http'\nrequire 'openssl'\n\nurl = URI(\"https://api.hubapi.com/crm/v3/objects/contacts\")\n\nhttp = Net::HTTP.new(url.host, url.port)\nhttp.use_ssl = true\nhttp.verify_mode = OpenSSL::SSL::VERIFY_NONE\n\nrequest = Net::HTTP::Get.new(url)\nrequest['content-type'] = 'application/json'\ntoken = 'YOUR_ACCESS_TOKEN'\nrequest['authorization'] = \"Bearer #{token}\"\n\nresponse = http.request(request)\nputs response.read_body\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "import requests\n\nurl = \"https://api.hubapi.com/crm/v3/objects/contacts\"\n\nheaders = {\n  'content-type': 'application/json',\n  'authorization': 'Bearer %s' % YOUR_ACCESS_TOKEN\n}\n\nresponse = requests.request(\"GET\", url, headers=headers)\n\nprint(response.text)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zugriffstoken von privaten Apps werden auf Basis von OAuth implementiert, sodass Sie auch authentifizierte Aufrufe mit Ihrem Zugriffstoken unter Verwendung einer der HubSpot-Client-Bibliotheken durchführen können. Wenn Sie zum Beispiel die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-nodejs",
        children: "Node.js-Client-Bibliothek"
      }), " verwenden, können Sie einen OAuth-Client instanziieren, indem Sie das Zugriffstoken Ihrer App übergeben."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "const hubspotClient = new hubspot.Client({ accessToken: YOUR_ACCESS_TOKEN });\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "$hubSpot = \\HubSpot\\Factory::createWithAccessToken('access-token');\n$response = $hubSpot->crm()->contacts()->basicApi()->getPage();\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "# Load the gem\nrequire 'hubspot-api-client'\n\n# Setup client\nclient = Hubspot::Client.new(access_token: 'YOUR_ACCESS_TOKEN')\n\n# Get contacts\ncontacts = client.crm.contacts.basic_api.get_page\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "from hubspot import HubSpot\n\napi_client = HubSpot(access_token='YOUR_ACCESS_TOKEN')\napi_client.crm.contacts.get_page()\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die Migration zu Ihrer privaten App abzuschließen, entfernen Sie alle Verweise auf den HubSpot-API-Schlüssel aus Ihrem Code und verwenden Sie stattdessen den oben genannten Ansatz, um das Zugriffstoken Ihrer privaten App zu verwenden. Abhängig von der Anfrage, die Sie durchzuführen, sollten Sie ein Geheimnis erstellen, um Ihr Token zu speichern, anstatt es in Ihren Anfragen zu programmieren. Die Verwendung eines Geheimnisses verhindert, dass Ihr Token offengelegt wird, z. B. wenn ein Token in einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#serverless-functions",
        children: "serverlosen Funktion verwendet wird"
      }), ". So speichern Sie das Zugriffstoken als Geheimnis:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Führen Sie im Terminal ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add secretName"
        }), " aus. Es wird empfohlen, dem Geheimnis einen einfachen Namen zu geben, damit Sie in Zukunft leicht darauf verweisen können."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie das Zugriffstoken in das Terminal ein und drücken Sie dann die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eingabetaste"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können dann auf Ihr Geheimnis als Umgebungsvariable zugreifen. Erfahren Sie mehr dazu im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#serverless-functions",
        children: "folgenden Beispiel für serverlose Funktionen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um zu bestätigen, dass alle Verweise auf Ihren API-Schlüssel entfernt wurden, können Sie das Aufrufprotokoll in Ihrem HubSpot-Account überprüfen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in Ihrem HubSpot-Account in der Hauptnavigationsleiste auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zahnradsymbol"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in der linken Seitenleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrationen > API-Schlüssel"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Überprüfen Sie auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "API-Aufrufe"
        }), " die letzten Anfragen, um zu bestätigen, dass seit dem Entfernen aller vorherigen Verweise keine neuen Anfragen durchgeführt wurden, um das Zugriffstoken Ihrer privaten App zu verwenden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/check-api-key-call-log-after-migration.png",
        alt: "check-api-key-call-log-after-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie einen kostenpflichtigen ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        })
      }), "-Account mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/contacts/marketing-contacts",
        children: "Marketingkontakten"
      }), " haben und Sie zuvor ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/integrations/manage-marketing-contacts-settings-for-your-integrations#set-contacts-created-by-api-key-apps-as-marketing-contacts",
        children: "Kontakte festgelegt haben, die durch Integrationen mit Ihrem API-Schlüssel als Marketingkontakte erstellt wurden"
      }), ", müssen Sie dies auch für Ihre private App tun:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in Ihrem HubSpot-Account in der Hauptnavigationsleiste auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zahnradsymbol"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in der linken Seitenleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrationen > Marketingkontakte"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Verwenden Sie unter ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Ihre verknüpften Apps"
        }), " die Suchleiste, um Ihre private App zu suchen, und klicken Sie dann auf den Schalter ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aktivieren, um Kontakte als Marketingkontakte zu synchronisieren"
        }), ", um ihn zu aktivieren."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/set-private-app-contacts-as-marketing-contacts.png",
        alt: "set-private-app-contacts-as-marketing-contacts"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie die Einrichtung Ihrer privaten App abgeschlossen haben und bestätigt haben, dass alle Verweise auf Ihren API-Schlüssel in Ihrem Code entfernt wurden, können Sie den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/integrations/how-do-i-get-my-hubspot-api-key#deactivate-your-api-key",
        children: "Schlüssel deaktivieren"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anfragen überprüfen und Protokolle überwachen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie alle Verweise auf den HubSpot-API-Schlüssel in Ihrem Code entfernt und stattdessen durch Verweise auf das Zugriffstoken Ihrer privaten App ersetzt haben, sind keine weiteren Änderungen am Code erforderlich."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie die oben beschriebenen Schritte in einem Entwickler-Test- oder Sandbox-Account befolgt haben, wiederholen Sie denselben Vorgang in Ihrem Produktions-Account. Überwachen Sie dann die API-Aufrufprotokolle Ihrer privaten App und bestätigen Sie, dass keine Ihrer App-Anfragen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "400"
      }), "-Fehler zurückgibt:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in Ihrem HubSpot-Account in der Hauptnavigationsleiste auf das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zahnradsymbol"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in der linken Seitenleiste zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrationen"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Namen"
        }), " Ihrer privaten App."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Protokolle"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Eine nicht erfolgreiche API-Anfrage, die aufgrund eines fehlenden Bereichs fehlgeschlagen ist, wird als ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), "-Fehler angezeigt. Wenn Sie die Laufzeitprotokolle Ihrer Integration aufrufen, sollte die Antwort aus der entsprechenden API-Anfrage eine Fehlermeldung mit Details zu fehlenden Bereichen enthalten."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/403-error-after-private-app-migration.png",
        alt: "403-error-after-private-app-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie einen neuen Bereich für Ihre private App hinzufügen müssen:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Details"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Details bearbeiten"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Klicken Sie oben auf der Seite auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Bereiche"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Aktivieren Sie das ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Kontrollkästchen"
            }), " neben allen fehlenden Bereichen und klicken Sie dann oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Änderungen übernehmen"
            }), ", wenn Sie fertig sind."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/select-missing-scopes-private-app-migration.png",
        alt: "select-missing-scope-private-app-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "Leitfaden für private Apps"
      }), " mehr über das Erstellen und Verwalten von privaten Apps und deren Obergrenzen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Implementierungsbeispiele"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie, wie Sie häufig verwendete API-Schlüssel verwenden und wie Sie stattdessen zu Zugriffstoken für private Apps migrieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Serverlose Funktionen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie einen API-Schlüssel innerhalb einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "serverlosen Funktion"
      }), " verwenden, können Sie das Zugriffstoken der privaten App ebenfalls zur Authentifizierung verwenden. Sie müssen sicherstellen, dass die private App über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "Bereiche"
      }), " verfügt, die die Funktion ausführen muss."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So authentifizieren Sie eine serverlose Funktion mit dem Zugriffstoken einer privaten App:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf der Karte ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Zugriffstoken"
          }), " auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Token anzeigen"
          }), " , um Ihr Zugriffstoken zu enthüllen. Klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Kopieren"
          }), ", um das Token in Ihre Zwischenablage zu kopieren."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-1.png",
            alt: "show-private-app-access-token-1"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Erstellen Sie mit kopiertem Zugriffstoken ein neues Geheimnis, um das Token zu speichern:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Führen Sie im Terminal ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs secrets add secretName"
            }), " aus. Es wird empfohlen, dem Geheimnis einen einfachen Namen zu geben, damit Sie in Zukunft leicht darauf verweisen können."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Fügen Sie das Zugriffstoken in das Terminal ein und drücken Sie dann die ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Eingabetaste"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Fügen Sie in der ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "serverless.json"
          }), "-Datei Ihrer serverlosen Funktion den Geheimnisnamen zum ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "secrets"
          }), "-Array hinzu:"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example serverless.json file\n\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"secrets\": [\"secretName\"],\n  \"endpoints\": {\n    \"getPrompts\": {\n      \"method\": \"GET\",\n      \"file\": \"serverlessFunction.js\"\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Legen Sie in der JavaScript-Datei Ihrer serverlosen Funktion den Wert des ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Authorization"
        }), "-Headers auf ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Bearer secretName"
        }), " fest. Wenn Sie zum Beispiel die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "Kontakte-API"
        }), " mithilfe von Node.js und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), " aufrufen, würde die Anfrage wie folgt aussehen:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example serverless function\nconst axios = require('axios');\n\nexports.main = (context, sendResponse) => {\n  axios.get(`https://api.hubapi.com/crm/v3/objects/contacts`, {\n    headers: {\n      Authorization: `Bearer ${process.env.secretName}`,\n      'Content-Type': 'application/json',\n    },\n  });\n  sendResponse({ statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "das Durchführen von API-Aufrufen mit dem Token Ihrer App"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einmalige Aufgaben"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie einen API-Schlüssel zum Ausführen von einmaligen Aufgaben verwenden, z. B. zum ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "Erstellen einer Eigenschaft"
      }), ", können Sie stattdessen eine private App erstellen und das Zugriffstoken verwenden, um den Aufruf zu authentifizieren. Sobald eine private App erstellt wurde, können Sie ihr Zugriffstoken für einmalige Aufgaben wiederverwenden, solange die private App über die entsprechenden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "Bereiche"
      }), " verfügt. Sie können die Bereiche einer privaten App jederzeit über die Einstellungen der privaten App in HubSpot aktualisieren. Sie können auch die private App löschen und eine neue erstellen, die spezifisch für die auszuführende Aufgabe ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/private-app-edit-scopes.png",
        alt: "private-app-edit-scopes"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Benutzerdefinierte Objekte erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anstatt einen API-Schlüssel zum ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "Erstellen eines benutzerdefinierten Objekts"
      }), " zu verwenden, können Sie stattdessen eine private App erstellen und, sofern die App über die erforderlichen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "Bereiche"
      }), " verfügt, den Aufruf mithilfe von ihrem Zugriffstoken authentifizieren Wenn Sie beispielsweise Postman zum Erstellen eines benutzerdefinierten Objekts verwenden, legen Sie den Autorisierungstyp auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Bearer-Token"
      }), " fest und geben Sie dann im Feld ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Token"
      }), " das Token ein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/postman-private-app-access-token-field.png",
        alt: "postman-private-app-access-token-field"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/how-to-build-a-custom-object-using-private-apps",
        children: "Entwicklerblog von HubSpot"
      }), " mehr über das Erstellen eines benutzerdefinierten Objekts mithilfe einer privaten App."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Workflow-Aktionen mit benutzerdefiniertem Code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie einen API-Schlüssel in einer ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/api/automation/custom-code-actions#create-a-custom-code-action",
        children: ["Workflow-Aktion mit ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "benutzerdefiniertem Code"
        })]
      }), " verwenden, können Sie stattdessen das Zugriffstoken der privaten App verwenden, sofern die App über die erforderlichen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "Bereiche"
      }), " verfügt. Um die Aktualisierung durchzuführen, öffnen Sie die benutzerdefinierte Aktion im Workflow-Editor und führen Sie dann die folgenden Aktualisierungen durch:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/conversations/working-with-chatbots/code-snippets",
            children: "Fügen Sie zunächst ein neues Geheimnis"
          }), " hinzu, das das Zugriffstoken der privaten App enthält."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/workflow-action-add-secret.png",
            alt: "workflow-aktion-add-secret"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/automation/custom-code-actions#secret",
            children: "Aktualisieren Sie dann den Code der Aktion"
          }), " mit dem neuen Geheimnis."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspotClient = new hubspot.Client({\n  accessToken: process.env.secretName,\n});\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}