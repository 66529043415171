"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45279037453;
const slug = exports.slug = 'guides/api/crm/pipelines';
const title = exports.title = 'CRM-API | Pipelines';
const name = exports.name = 'EMEA DACH (de) vNext Docs DP - Pipelines';
const metaDescription = exports.metaDescription = 'Die Endpunkte der Pipelines werden verwendet, um Pipelines im HubSpot CRM und in Kundenservicetools anzuzeigen, zu erstellen und zu verwalten.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h3: "h3",
      code: "code",
      a: "a",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Pipelines"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In HubSpot ist eine Pipeline der Ort, an dem Deal-Phasen oder Ticket-Status festgelegt sind. Im Vertrieb können mit Pipelines Umsätze vorhergesagt und Hindernisse identifiziert werden. Beim Kundenservice können Pipelines verwendet werden, um Ticket-Status zu verwalten und Hindernisse zu analysieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Jede Phase in einer Pipeline wird durch eine eindeutige interne ID identifiziert, d. h., sie kann nur ein Mitglied einer Pipeline sein. Jede Pipeline hat immer mindestens eine Phase, und jeder Account hat mindestens eine Pipeline."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Standard-Pipelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jeder Account enthält ursprünglich eine Standard-Pipeline mit der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pipelineId"
      }), " „Standard”. In Accounts mit einer einzigen Pipeline wird die Pipeline-Eigenschaft für jedes Objekt ebenfalls automatisch auf „Standard” festgelegt. In Accounts mit mehreren Pipelines müssen Sie, wenn Sie eine Phase festlegen, die nicht in der Pipeline-Eigenschaft ist, ebenfalls die entsprechende Pipeline-Eigenschaft festlegen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mehrere Pipelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nur Sales Hub Professional- oder Enterprise-Accounts können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "mehrere Deal-Pipelines"
      }), " erstellen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Gleichermaßen müssen Sie über einen Service Hub Professional- oder Enterprise-Abonnement verfügen, um mehrere ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/tickets/customize-ticket-pipelines-and-statuses",
        children: "Ticket-Pipelines"
      }), " zu erstellen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "in diesem Artikel"
      }), " mehr über die Abonnementstufen von HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispiele für Anwendungsfälle:"
      }), " Wenn Sie mit Deals arbeiten, kann ein Account eine Pipeline für „Neues Verkäufe” und für „Vertragsverlängerungen” haben Für Tickets haben Sie möglicherweise eine Haupt-Support-Warteschlange und eine separate Warteschlange für Eskalationen. Jeder dieser Warteschlangen wäre eine separate Ticket-Pipeline. Die Endpunkte der Pipelines können verwendet werden, um eine der Pipelines mit einem externen CRM zu synchronisieren."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}