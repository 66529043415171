"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694135;
const slug = exports.slug = 'reference/cms/hubl/variables';
const title = exports.title = 'Variáveis HubL';
const name = exports.name = 'Variáveis HubL';
const metaDescription = exports.metaDescription = 'Os modelos da HubSpot podem usar uma série de variáveis predefinidas que podem ser usadas para renderizar elementos úteis de sites e e-mails. Esta página é uma lista de referência dessas variáveis.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "vari%C3%A1veis-dispon%C3%ADveis-em-todos-os-modelos",
  "label": "Variáveis disponíveis em todos os modelos",
  "parent": null
}, {
  "depth": 0,
  "id": "vari%C3%A1veis-de-e-mail",
  "label": "Variáveis de e-mail",
  "parent": null
}, {
  "depth": 1,
  "id": "vari%C3%A1veis-de-modelos-de-e-mail-necess%C3%A1rias",
  "label": "Variáveis de modelos de e-mail necessárias",
  "parent": "vari%C3%A1veis-de-e-mail"
}, {
  "depth": 1,
  "id": "configura%C3%A7%C3%B5es-de-cor-e-fonte",
  "label": "Configurações de cor e fonte",
  "parent": "vari%C3%A1veis-de-e-mail"
}, {
  "depth": 1,
  "id": "conte%C3%BAdo-do-e-mail",
  "label": "Conteúdo do e-mail",
  "parent": "vari%C3%A1veis-de-e-mail"
}, {
  "depth": 1,
  "id": "vari%C3%A1veis-de-e-mail-para-conte%C3%BAdo-privado",
  "label": "Variáveis de e-mail para conteúdo privado",
  "parent": "vari%C3%A1veis-de-e-mail"
}, {
  "depth": 0,
  "id": "vari%C3%A1veis-de-p%C3%A1ginas-do-site",
  "label": "Variáveis de páginas do site",
  "parent": null
}, {
  "depth": 1,
  "id": "vari%C3%A1veis-de-modelo-de-p%C3%A1gina-necess%C3%A1rias",
  "label": "Variáveis de modelo de página necessárias",
  "parent": "vari%C3%A1veis-de-p%C3%A1ginas-do-site"
}, {
  "depth": 1,
  "id": "vari%C3%A1veis-de-conte%C3%BAdo-e-contato",
  "label": "Variáveis de conteúdo e contato",
  "parent": "vari%C3%A1veis-de-p%C3%A1ginas-do-site"
}, {
  "depth": 0,
  "id": "vari%C3%A1veis-de-solicita%C3%A7%C3%A3o-http",
  "label": "Variáveis de solicitação HTTP",
  "parent": null
}, {
  "depth": 0,
  "id": "vari%C3%A1veis-de-blog",
  "label": "Variáveis de blog",
  "parent": null
}, {
  "depth": 0,
  "id": "p%C3%A1ginas-din%C3%A2micas-de-objetos-do-crm",
  "label": "Páginas dinâmicas de objetos do CRM",
  "parent": null
}, {
  "depth": 0,
  "id": "vari%C3%A1veis-do-hubdb",
  "label": "Variáveis do HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "vari%C3%A1veis-de-n%C3%B3s-de-menu",
  "label": "Variáveis de nós de menu",
  "parent": null
}, {
  "depth": 0,
  "id": "editor-no-app-e-vari%C3%A1veis-de-visualiza%C3%A7%C3%A3o",
  "label": "Editor no app e variáveis de visualização",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Variáveis HubL"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os modelos da HubSpot podem usar uma série de variáveis predefinidas que podem ser usadas para renderizar elementos úteis de sites e e-mails. Esta página é uma lista de referência dessas variáveis. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "Saiba mais sobre como criar suas próprias variáveis"
      }), " em um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "modelo"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulo da HubL"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Embora a maioria das variáveis listadas nesta página seja opcional, há algumas variáveis obrigatórias para criar e-mails e páginas a partir de seus modelos."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["As variáveis listadas abaixo podem ser usadas individualmente colocando-as no delimitador ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "}}"
        }), " conforme observado em nossa página ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/variables-macros-syntax",
          children: "Variáveis e Macros"
        }), ". Opcionalmente, você pode usar essas variáveis com outras partes do HubL Templating Language, como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/loops",
          children: "loops"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/filters",
          children: "filtros"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions",
          children: "funções"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags",
          children: "tags"
        }), " e muito mais."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis disponíveis em todos os modelos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As variáveis HubL abaixo podem ser usadas em modelos de e-mail, página ou blog."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver informações adicionais sobre a saída dessas variáveis, você pode usar o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters#pprint",
        children: "filtro de impressão"
      }), ", como mostrado abaixo."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set variable = content %}\n{{variable|pprint}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(ContentMeta: { \"canonicalUrl\" : \"https://www.website.com/url-slug\",\n\"deferDynamicValues\" : false, \"featuredImageAltText\" : \"\", \"featuredImageHeight\"\n: 0, \"featuredImageUrl\" : \"\", \"featuredImageWidth\" : 0, \"htmlTitle\" : \"\",\n\"linkRelCanonicalUrl\" : \"https://www.website.com/url-slug\", \"metaDescription\" :\n\"\", \"metaKeywords\" : \"\", \"name\" : \"\", \"unescapedHtmlTitle\" : \"\",\n\"unescapedMetaDescription\" : null })\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "account"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Essa variável é um dicionário que armazena as propriedades de personalização da empresa para um contato conhecido. As propriedades podem ser acessadas a partir desse dicionário, adicionando um ponto final e o nome da propriedade. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "account.name"
            }), " imprime o nome da empresa de um contato.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "O uso desta variável desabilitará o cache de páginas."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company_domain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Imprime o domínio da empresa em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Site> Páginas > Branding > Link do logotipo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Essa variável é um dicionário que armazena as propriedades de personalização de contato para um contato conhecido. As propriedades podem ser acessadas a partir desse dicionário, adicionando um ponto final e o nome da propriedade. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact.firstname"
            }), " imprime o nome de um contato.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "O uso desta variável desabilitará o cache de páginas."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Essa variável é um dicionário que armazena várias propriedades pertencentes a um conteúdo específico, como um e-mail, uma página ou um post."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.absolute_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Imprime a URL completo de uma página, post ou versão de página web de um e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.archived"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Essa variável é avaliada como True, se a página ou o e-mail foi marcado como arquivado pelo usuário."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.author_email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O endereço de e-mail do criador do conteúdo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.author_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nome e sobrenome do criador do conteúdo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.author_username"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome de usuário HubSpot do criador do conteúdo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.campaign"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O GUID da campanha de marketing à qual essa página ou e-mail está associado. Essa ID exclusiva pode ser encontrada na URL de uma campanha específica na ferramenta da Campanha."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.campaign_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome da campanha de marketing à qual esta página, este post ou este e-mail está associado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.created"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Datetime"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um objeto datetime para quando o conteúdo foi criado originalmente, na hora UTC. Essa variável pode ser formatada com o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/filters#datetimeformat",
              children: "filtro datetime"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.meta_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ao extrair a metadescrição de uma página, é melhor usar a variável ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.meta_description"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome de um post, e-mail ou página. Para páginas e e-mails, isso imprimirá o nome do conteúdo interno, enquanto para posts, isso imprimirá o título do post. Para posts de blog, esse é o título do post que é exibido. Para outros tipos de conteúdo, esse geralmente é um nome interno. Essa variável inclui um wrapper para que seja editável por meio da IU, quando incluída em postagens de blog. Caso queira imprimir o nome do conteúdo sem um wrapper, use page_meta.name."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Datetime"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um objeto datetime que representa quando o conteúdo foi publicado, na hora UTC. Essa variável pode ser formatada com o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/filters#format-datetime",
              children: "filtro format_datetime"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date_localized"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma string que representa a data/hora em que o conteúdo foi publicado usando o fuso horário definido nas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/change-your-language-and-region-settings",
              children: "configurações padrão"
            }), " da conta. Esta variável também está sujeita às configurações de idioma e formato de data em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Configurações > Site > Blog > Formatos de data"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.template_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O caminho do arquivo no Gerenciador de Design para o seu modelo (ou seja, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom/page/web_page_basic/my_template.html"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Imprime o URL completo de uma página, post ou versão de página web de um e-mail. Este é o valor que segue o domínio. Por exemplo, em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://example.com/about-us"
            }), ", o slug é ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "about-us"
            }), ".Para o URL completo, use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.absolute_url"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.updated"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Data/hora"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um objeto datetime para quando o usuário atualizou o conteúdo pela última vez, na hora UTC. Essa variável pode ser formatada com o filtro datetime. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Diferente de"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date"
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "na publicação inicial. Usar"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/filters#between-times",
              children: (0, _jsxRuntime.jsx)("code", {
                children: "|between_times"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "filtro para testar se um post foi atualizado após a publicação."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Imprime o ID exclusivo de uma página, post ou e-mail. Esse ID pode ser encontrado na URL do editor. Você pode usar essa variável como um alias para content.id."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "favicon_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Imprime o URL de origem do favicon. Essa imagem está definida em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Configurações > Site > Páginas > Branding"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hub_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do portal da sua conta da HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_analytics_tracking_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Inclui o código de rastreamento de análise. Essa tag não é necessária, porque ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_footer_includes"
            }), " já renderiza o código de rastreamento."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "local_dt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Datetime"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um objeto datetime da hora atual no fuso horário definido em suas Configurações de Relatório. ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "O uso dessa variável desativará o cache da página para retornar para a hora atual. Pode prejudicar o desempenho da página. Em vez disso, use JavaScript para obter a data e a hora atuais de maneira armazenável em cache."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "local_time_zone"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O fuso horário, conforme configurado nas Configurações de Relatório da HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.canonical_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A URL oficial em que esta página deve ser acessada. Normalmente não inclui nenhum parâmetro de string de consulta. Use isso para tag ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rel=\"canonical\""
            }), ". A HubSpot canoniza automaticamente as URLs."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.html_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O título da página. Essa variável deve ser usada na tag ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<title>"
            }), " de modelos HTML."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.meta_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A metadescrição de uma página. Essa variável deve ser usada na tag \"descrição\" ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<meta>"
            }), " dos modelos HTML."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "page_meta.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um alias para ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.name"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portal_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um alias para hub_id"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request_contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário contendo dados sobre o contato solicitado.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "O uso dessa variável desativará o cache da página."
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Não disponível nos modelos de e-mail."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O dicionário site_settings contém várias configurações, como cores e fontes (veja abaixo)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "year"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Imprime o ano atual."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis de e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja a seguir as variáveis HubL que podem ser usadas em modelos de e-mail. Algumas variáveis, como as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#color-and-font-settings",
        children: "variáveis de configurações de cores e fontes"
      }), ", podem ser usadas em outros tipos de conteúdo se você quiser consultar as configurações de cor e fonte de seu e-mail em outro lugar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Variáveis de modelos de e-mail necessárias"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ser compatível com ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/can-i-customize-my-can-spam-email-footer",
        children: "CAN-SPAM"
      }), ", todos os e-mails enviados pela HubSpot exigem determinadas informações da empresa e opt-out. Esta informação é definida pelas variáveis a seguir, que devem ser incluídas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Existem variáveis de e-mail adicionais que são opcionais e estão listadas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#email-variables",
        children: "mais abaixo nesta página"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_city"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Imprime a cidade da empresa (definida em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Configurações > Marketing > E-mail > Configuração > Rodapé"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Imprime o nome da empresa (definido em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Configurações > Marketing > E-mail > Configuração > Rodapé"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Imprime o estado da empresa (definido em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Configurações > Marketing > E-mail > Configuração > Rodapé"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_street_address_1"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Imprime o endereço da empresa (definido em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Configurações > Marketing > E-mail > Configuração > Rodapé"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Imprime a URL da página que permite que os destinatários gerenciem as preferências de assinatura ou cancelem a assinatura de comunicações por e-mail. Essa variável deve ser usada no atributo href de <a>."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configurações de cor e fonte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Existem vários controles básicos de cores e fontes nas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/marketing-email/manage-your-marketing-email-account-settings",
        children: "configurações de e-mail marketing"
      }), " que podem ser impressos em e-mails. Onde indicado, você pode usar algumas dessas variáveis em modelos de página e blog adicionando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "site_settings."
      }), " antes da variável."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configuração da cor do plano de fundo como um valor hexadecimal. Para imprimir esse valor em modelos de página ou blog, use:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.background_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_border_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configuração da cor da borda do corpo como um valor hexadecimal. Essa opção fica disponível quando você seleciona ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Definir manualmente a cor da borda do e-mail"
            }), " no menu suspenso ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Opções de cor da borda"
            }), " nas configurações. Para imprimir esse valor em modelos de página ou blog, use: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.body_border_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_border_color_choice"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O valor desta variável é controlado pelo menu suspenso ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Opções de cor da borda"
            }), " nas configurações. Os valores são: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BORDER_AUTOMATIC"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BORDER_MANUAL"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BORDER_NONE"
            }), ".Para imprimir esse valor em modelos de página ou blog, use:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.body_border_color_choice"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configuração da cor do corpo como um valor hexadecimal.Para imprimir esse valor em modelos de página ou blog, use:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.body_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_accent_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configuração da cor de ênfase principal como um valor hexadecimal.Para imprimir esse valor em modelos de página ou blog, use:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_accent_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeração"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configuração de fonte principal como uma string.Para imprimir este valor em modelos de página ou blog, use:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configuração da cor da fonte principal como um valor hexadecimal.Para imprimir esse valor em modelos de página ou blog, use:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font_size"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configuração do tamanho da fonte principal como um valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ".Para imprimir esse valor em modelos de página ou blog, use:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.primary_font_size"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primary_font_size_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Número do tamanho da fonte primária, excluindo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_accent_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configuração da cor de ênfase secundária como valor hexadecimal.Para imprimir esse valor em modelos de página ou blog, use:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.secondary_accent_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_font"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeração"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configuração de fonte secundária como uma string.Para imprimir esse valor em modelos de página ou blog, use:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.secondary_font"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_font_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configuração da cor da fonte secundária como valor hexadecimal.Para imprimir esse valor em modelos de página ou blog, use:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.secondary_font_color"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secondary_font_size_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Número do tamanho da fonte secundária, excluindo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conteúdo do e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.create_page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Essa variável é ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", se houver uma versão de página da web do e-mail."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.email_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Richtext"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O corpo principal do e-mail. Essa variável renderiza um módulo de rich text."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.emailbody_plaintext"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A substituição opcional do corpo do e-mail em texto simples"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.from_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome do remetente do e-mail"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.reply_to"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O endereço de resposta do e-mail"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.subject"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O assunto do e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_body_padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/manage-your-marketing-email-account-settings#size",
              children: "configuração de preenchimento do corpo do e-mail"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email_body_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/manage-your-marketing-email-account-settings#size",
              children: "configuração da largura do corpo do e-mail"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.company_street_address_2"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Imprime a linha 2 do endereço a partir das ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/can-i-customize-my-can-spam-email-footer",
              children: "Configurações de rodapé CAN-SPAM"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_settings.office_location_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Imprime o nome da localização do escritório a partir das ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/marketing-email/can-i-customize-my-can-spam-email-footer",
              children: "configurações de rodapé CAN-SPAM"
            }), ". da conta."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscription_confirmation_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Imprime a URL da página de confirmação das preferências de assinatura. Essa URL é gerada dinamicamente ao enviar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscription_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Imprime o nome do tipo de e-mail especificado para esse e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_anchor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gera uma tag de âncora com o termo \"unsubscribe\" vinculado à sua página de cancelamento de inscrição."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_link_all"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Renderiza um link para cancelar a assinatura de todas as comunicações por e-mail, ao contrário de um link para gerenciar as preferências de assinatura."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unsubscribe_section"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Renderiza uma seção de cancelamento de inscrição que inclui um link de cancelamento de inscrição, bem como texto de ajuda."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "view_as_page_section"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gera um link com texto de ajuda que leva a uma versão de página da web de um e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "view_as_page_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gera um link que leva a uma categoria de página da web de um e-mail."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Variáveis de e-mail para conteúdo privado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A lista de variáveis a seguir está disponível apenas dentro de modelos de e-mail usados para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages#customize-your-membership-registration-page-and-email-templates",
        children: "modelos de e-mail de conteúdo privado"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O nome da empresa, conforme definido nas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/manage-private-content-settings#edit-general-settings-for-private-content",
              children: "configurações de conteúdo privado"
            }), "da conta."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_domain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esse é o domínio do site de conteúdo privado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_logo_src"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O URL do logotipo a ser exibido, conforme definido nas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors",
              children: "configurações do kit de marca"
            }), " da conta."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_logo_alt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O atributo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " do logotipo, conforme definido nas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors",
              children: "configurações do kit de marca"
            }), " da conta."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_registration_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link para a página de registro do site de conteúdo privado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_password_saved_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link para a página de senha salva. O link redirecionará o visitante para uma página restrita aleatória à qual ele tem acesso."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_password_reset_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link para a página de redefinição de senha do site de conteúdo privado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_passwordless_auth_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link para a página de login do membro sem senha."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_verify_registration_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link para a página de verificação de registro."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "membership_website_admin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O nome do administrador do site, conforme definido nas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/manage-private-content-settings#edit-general-settings-for-private-content",
              children: "configurações de conteúdo privado"
            }), "da conta."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis de páginas do site"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As seguintes variáveis estão disponíveis para páginas de site, landing pages, páginas de sistema e blogs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Variáveis de modelo de página necessárias"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para publicar um arquivo codificado como uma página editável ou modelo de blog, as variáveis a seguir devem ser incluídas. Para publicar um arquivo HTML sem essas variáveis e usar em outro modelo, inclua ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "isAvailableForNewContent: false"
      }), " na caixa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "anotações do modelo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_footer_includes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Renderiza o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview#hubspot-tracking-code",
              children: "código de rastreamento da HubSpot"
            }), " e qualquer outro código incluído na HTML do rodapé em ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://app.hubspot.com/l/settings/website/pages/all-domains/page-templates",
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Configurações de Conteúdo"
              })
            }), " ou nas opções de uma página específica. Essa tag deve ser inserida diretamente antes da tag de fechamento do corpo."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_header_includes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Adiciona ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview#included-cms-files",
              children: "jQuery, layout.css"
            }), ", quaisquer folhas de estilo anexadas, uma tag meta viewport, código de rastreamento do Google Analytics, outras metainformações de página e código adicionado à tag head no nível do domínio/modelo/página. Essa variável deve ser adicionada ao <head> dos modelos HTML."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Variáveis de conteúdo e contato"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "builtin_body_classes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Essa variável imprime dinamicamente classes úteis que ajudam a diferenciar a marcação do conteúdo criado com esse modelo (ou seja, tipo de conteúdo, nome do conteúdo etc.). Isso torna mais fácil estilizar diferentes tipos de conteúdo ou páginas específicas. Essa variável deve ser usada no atributo classe da tag corpo em modelos codificados."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request_contact.is_logged_in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Essa variável define se o contato solicitante está ou não conectado ao conteúdo fechado de um site (consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages",
              children: "documentação de controle de acesso do público"
            }), " para obter mais informações). O valor dessa variável retornará verdadeiro se o contato solicitante estiver logado e falso se o contato solicitante tiver feito logout. Um contato pode ser desconectado direcionando-o para o URL ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.yourdomain.com/_hcms/mem/logout"
            }), ".O uso dessa variável desabilitará o cache de páginas."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request_contact.list_memberships"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Essa variável retorna um dicionário de ids que representa as listas das quais o contato é membro.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "O uso desta variável desativará o cache da página."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Essa variável retorna um dicionário de informações sobre as configurações de idioma de uma página. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ content.language.languageTag }}"
            }), " retorna o identificador de idioma de uma página (ou seja, \"en\" ou \"es\"). ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ content.language.textDirection.value }}"
            }), " retorna a direção do texto do idioma da página (ou seja, \"rtl\" ou \"ltr\")."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis de solicitação HTTP"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As variáveis a seguir imprimem informações sobre a solicitação de página HTTP."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.cookies"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário de nomes de cookies mapeados para valores de cookies.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "O uso dessa variável desativará o cache da página."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.domain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O domínio utilizado para acessar essa página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.full_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URL utilizado para acessar essa página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O componente Path da URL"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.path_and_query"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O caminho e o componente de consulta do URL"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.query"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O componente de string de consulta da URL. request.query_dict divide automaticamente as strings de consulta em pares de chave-valor e é recomendado em vez da consulta bruta para a maioria dos casos de uso."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.query_dict"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A string de consulta convertida em um dicionário de nome->valor."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.referrer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O referenciador HTTP, o URL da página vinculada à página atual.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "O uso dessa variável desativará o cache da página."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.remote_ip"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O endereço IP do visitante.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "O uso dessa variável desativará o cache da página."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.scheme"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O protocolo da solicitação (http ou https)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.search_engine"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O mecanismo de pesquisa utilizado para encontrar essa página, se aplicável. Exemplo: Google, Aol, Live, Yahoo, images.google, etc."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.search_keyword"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A frase-chave utilizada para encontrar essa página, se aplicável."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "request.headers"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário dos cabeçalhos de solicitação disponíveis.", (0, _jsxRuntime.jsx)(_components.em, {
              children: "O uso dessa variável desativará o cache da página para interpretar cabeçalhos individualizados para cada solicitação. Pode prejudicar o desempenho da página."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As seguintes variáveis estão disponíveis para modelos de blog. Algumas variáveis estão disponíveis apenas para listagens de posts, enquanto outras podem estar disponíveis apenas para posts de blog."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Essa variável contém informações do autor do blog para páginas de listagem de autores de blog. Pode ser usada para criar lógica condicional para >", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/blog#if-blog-author-statement",
              children: "renderizar marcações para listagens de autores de blogs"
            }), ". Também contém as seguintes propriedades:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.avatar"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.bio"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.display_name"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.email"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.facebook"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.google_plus"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.has_social_profiles"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.linkedin"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.twitter"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_author.website"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.blog_post_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Essa variável contém informações individuais do autor do post dos blogs para um determinado post. Contém as seguintes propriedades:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.avatar"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.bio"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.display_name"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.email"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.facebook"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.google_plus"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.has_social_profiles"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.linkedin"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.slug"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.twitter"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content.blog_post_author.website"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um alias para o grupo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.comment_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número de comentários para o post atual do blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.comment_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista dos comentários para o post atual do blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "current_page_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O índice inteiro da página atual de posts do blog na exibição."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URL de origem da imagem em destaque, selecionada quando o blog foi publicado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.featured_image_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto alternativo da imagem em destaque."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "last_page_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O índice inteiro da última página de posts do blog na exibição. "
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "next_page_num"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O índice inteiro da próxima página de posts do blog na exibição."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URL da imagem em destaque do próximo post do blog, se houver."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_featured_image_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto alternativo para a imagem em destaque do próximo post, se houver texto alternativo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome do próximo post do blog, se houver."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.next_post_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O slug de URL do próximo post do blog, se houver."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O corpo do post do blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_list_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O conteúdo de post do blog do corpo, modificado para a página de listagem. A saída final é afetada pelas configurações de resumo em** Configurações > Site > Blog**. Se as imagens em destaque estiverem habilitadas nas configurações, essa variável removerá quaisquer imagens acima do separador leia mais automaticamente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_list_summary_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A imagem em destaque de resumos de posts a serem usados em modelos de listagem. Essa variável é afetada pelas configurações em ** Configurações > Site > Blog**."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.post_summary"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Resumo do post de blog. Esse conteúdo é determinado pelo separador leia mais no editor do blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URL da imagem em destaque do post anterior do blog, se houver."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_featured_image_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto alternativo para a imagem em destaque do post anterior, se houver texto alternativo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome do post anterior do blog, se houver."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.previous_post_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O slug de URL do post anterior do blog, se houver."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.publish_date_localized"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma string que representa a data/hora em que o post do blog foi publicado, formatado de acordo com as configurações de idioma e formatação de data do blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "simple_list_page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um booleano para indicar se a página solicitada é a página “todos os posts” contendo links para todos os posts do blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content.topic_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Pode ser usado para renderizar a marcação de uma lista de tópicos fazendo um loop nela. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{% for topic in content.topic_list %}"
            }), " Os itens do contêm as propriedades: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "slug"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O conteúdo é uma sequência de posts do seu blog que são iteradas usando um loop “for”, disponível nas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/blog#if-is-listing-view-statement",
              children: "páginas de listagem do blog (is_listing_view)"
            }), ". "]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents.total_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número total de posts em uma listagem (regular, tópicos, autores, etc.)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents.total_page_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número total de páginas de posts com base no seu número de posts por página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contents_topics"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obter uma lista de todos os tópicos do blog na sequência de conteúdo dos posts."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O dicionário contém variáveis que dizem respeito a um inteiro."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.absolute_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URL base de um blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.allow_comments"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostra o valor True, se comentários forem permitidos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A metadescrição do blog em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Configurações > Site > Blog"
            }), ". Usado para a metadescrição em determinadas páginas de listagem."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O cabeçalho do blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.html_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O título deste blog como deveria aparecer na ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<title>"
            }), " tag."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID exclusivo de um blog. Esse ID pode ser encontrado na URL do Painel do Blog de um determinado blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um dicionário contendo informações sobre o idioma de um blog. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{{ group.language.languageTag }}"
            }), "pode ser usado em condicionais para renderizar diferentes conteúdos nas diferentes variações de idioma de um blog multilíngue."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.public_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O título deste blog como deve aparecer no topo das páginas renderizadas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.show_summary_in_listing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um booleano em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Configurações > Site > Blog"
            }), " para indicar se deseja mostrar resumos nas listagens de posts."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O caminho para esse blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group.use_featured_image_in_summary"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um booleano em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Configurações > Site > Blog"
            }), " para indicar se as imagens em destaque são mostradas em resumos de posts."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "archive_list_page"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna verdadeiro se a página for uma página de arquivo do blog. Exemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/blog/archive/2020/02"
            }), " retornaria ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Páginas dinâmicas de objetos do CRM"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As seguintes variáveis são usadas para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#crm-object-dynamic-pages",
        children: "construir páginas dinâmicas com objetos do CRM"
      }), ". Essas variáveis estão disponíveis apenas para páginas dinâmicas de objetos do CRM."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_crm_object"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O objeto do CRM da página dinâmica que corresponde ao caminho da solicitação da página. Se a solicitação for para a página de listagem, esse valor será ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_crm_object_type_fqn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O nome totalmente qualificado (FQN) do objeto do CRM. O FQN é um ID exclusivo atribuído ao objeto, incluindo o ID do portal e o nome do objeto. O FQN pode ser usado na função ", (0, _jsxRuntime.jsxs)(_components.a, {
              href: "/reference/cms/hubl/functions#crm-objects",
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "crm_objects"
              }), "."]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis do HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As seguintes variáveis são utilizadas para criar páginas dinâmicas com o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), ". Essas variáveis estão disponíveis apenas para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#hubdb-dynamic-pages",
        children: "páginas dinâmicas do HubDB"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_hubdb_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Longo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da tabela selecionada na guia “Configurações Avançadas\\” do editor de página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_hubdb_row"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A linha do HubDB da página dinâmica que corresponde ao caminho da solicitação da página. Se a solicitação for para a página de listagem, esse valor será ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Longo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID interno de uma fileira HubDB."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome da fileira do HubDB."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O caminho da linha do HubDB. Usado para resolver uma solicitação para uma fileira na tabela especificada por ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_hubdb_table_id"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_child_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Longo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da tabela-filha da fileira HubDB. Pode ser usado para criar modelos aninhados."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_parent_row"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A linha principal da linha do HubDB. Só pode ser usada ao usar tabelas filhas para modelos aninhados."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamic_page_route_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Profundidade atual de uma página em um modelo dinâmico multinível. O valor começa em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " e aumenta com cada camada de tabela adicional."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variáveis de nós de menu"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As variáveis a seguir estão disponíveis para uso no objeto retornado pela ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#menu",
        children: "função de menu do HubL"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O rótulo de menu da página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL da página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.pageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID da página se estiver no HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.contentGroupId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID do blog da página se for um post de blog do HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.parentNode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O nó principal do nó atual. O nó-pai terá o nó atual em sua propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "children"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.children"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lista"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A lista de nós filhos para o nó atual."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.activeBranch"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verdadeiro se o nó estiver na ramificação de nível superior em que a página atual está."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.activeNode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verdadeiro se o nó for a página atual."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número de níveis abaixo do nó atual é a partir dos nós de nível superior."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.pageTitle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nome da página de conteúdo se estiver no HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Slug de caminho da página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "node.linkTarget"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link alvo da página."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Editor no app e variáveis de visualização"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode usar as variáveis a seguir para verificar se o conteúdo está sendo renderizado no editor ou visualizador de conteúdo. Por exemplo, você pode querer usá-las para evitar a execução de código no editor enquanto ainda executa o código em páginas ativas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if is_in_page_editor %}\nDisplay something different within the page editor.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variável"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_hs_app"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado no aplicativo HubSpot."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado em qualquer editor de conteúdo."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_global_content_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado dentro do editor de conteúdo global."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_theme_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado no editor de temas."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_page_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado no editor de página."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_blog_post_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado no editor de posts do blog."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_email_editor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado no editor de e-mails."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado em qualquer contexto de visualização."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_theme_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado no visualizador de temas."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_template_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado no visualizador de modelos."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_page_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado no visualizador de páginas."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_blog_post_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado no visualizador de posts de blog."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_email_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado no visualizador de e-mails."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_in_module_previewer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Retorna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " se o conteúdo estiver sendo renderizado no visualizador de módulos."]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}