"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611094;
const slug = exports.slug = 'guides/cms/tools/design-manager';
const title = exports.title = 'Administrador de diseño';
const name = exports.name = '[nuevo] Administrador de diseño';
const metaDescription = exports.metaDescription = 'El Administrador de diseño es un entorno de desarrollo integrado basado en web que puede utilizarse opcionalmente para crear plantillas, módulos, CSS y JavaScript.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/module%20editor.png';
const featuredImageAltText = exports.featuredImageAltText = 'El administrador de diseño';
const toc = exports.toc = [{
  "depth": 0,
  "id": "configuraci%C3%B3n-del-administrador-de-dise%C3%B1o",
  "label": "Configuración del Administrador de diseño",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      img: "img",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Administrador de diseño"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El Administrador de diseño es un entorno de desarrollo integrado basado en la web que se puede usar opcionalmente para crear ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "plantillas"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), ", CSS y JavaScript. El Administrador de diseño se encuentra navegando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/design-manager/",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing+ > Archivos y plantillas > Herramientas de diseño"
        })
      }), " en la barra de navegación superior."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si prefieres desarrollar localmente utilizando tu editor de código, workflows y tecnologías preferidos, puedes utilizar en su lugar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CMS CLI"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Independientemente de si desarrollas localmente o utilizas el Administrador de diseño para crear activos, todo el sistema de archivos de desarrollador de tu cuenta estará representado y será editable en el Administrador de diseño."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/designmanager%203-20-2020.png",
        alt: "Captura de pantalla del Administrador de diseño de HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Algunos desarrolladores o equipos optan por utilizar un workflow ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "en el que se utiliza una mezcla del Administrador de diseño y el desarrollo local"
      }), ". Puedes utilizar el comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch"
        })
      }), " de la CLI ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS"
      }), " para arrastrar las ediciones del Administrador de diseño a tu entorno local. Alternativamente, puedes evitar la edición y tener toda tu base de código ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "continuamente integrada con el control de código fuente"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consulta este artículo de la Base de conocimientos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager"
      }), " para obtener una visión general del Administrador de diseño y sus distintos componentes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuración del Administrador de diseño"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El Administrador de diseño tiene la configuración básica de IDE que puedes configurar para que la experiencia se adapte a ti y a tus workflows. Aparece un botón de configuración junto al botón de ayuda en la barra inferior y se puede utilizar para definir la configuración."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "Puedes definir la configuración para:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Tema del editor"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Fuente"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Tamaño de la pestaña"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Unidad de sangría"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Pestañas tenues"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Sangría inteligente"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Sangría al dar formato a los datos"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Sangría con tabulaciones"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Ajuste de línea"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Cerrar corchetes automáticamente"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Igualar corchetes"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Igualar etiquetas"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "ESLint"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["y más, consulta ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://knowledge.hubspot.com/cos-general/customize-design-manager-settings",
                children: "este artículo de la Base de Conocimientos"
              }), " para ver un desglose de la configuración y obtener más información."]
            }), "\n"]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Si estas funciones no satisfacen tus necesidades, te recomendamos que pruebes nuestras ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/local-development-cli",
              children: "herramientas de desarrollo local"
            }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/creating-an-efficient-development-workflow",
              children: "workflow"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/Design%20Manger%20Settings-1.png",
            alt: "Captura de pantalla de la configuración del administrador de diseño"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}