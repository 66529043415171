"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76633369011;
const slug = exports.slug = 'guides/cms/marketplace/theme-requirements';
const title = exports.title = 'Requisitos para temas do Marketplace de modelos da HubSpot';
const name = exports.name = 'Marketplace de modelos | Requisitos de tema (2022)';
const metaDescription = exports.metaDescription = 'Saiba mais sobre os requisitos que um tema precisa atender para ser enviado ao Marketplace de ativos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "do-tema",
  "label": "do tema",
  "parent": null
}, {
  "depth": 0,
  "id": "requisitos-gerais-para-temas",
  "label": "Requisitos gerais para temas",
  "parent": null
}, {
  "depth": 1,
  "id": "estrutura-de-arquivos-do-tema",
  "label": "Estrutura de arquivos do tema",
  "parent": "requisitos-gerais-para-temas"
}, {
  "depth": 1,
  "id": "caminhos-relativos-de-arquivos-locais-para-modelos",
  "label": "Caminhos relativos de arquivos locais para modelos",
  "parent": "requisitos-gerais-para-temas"
}, {
  "depth": 0,
  "id": "desempenho-do-tema",
  "label": "Desempenho do tema",
  "parent": null
}, {
  "depth": 1,
  "id": "urls-de-visualiza%C3%A7%C3%A3o-para-tema",
  "label": "URLs de visualização para tema",
  "parent": "desempenho-do-tema"
}, {
  "depth": 0,
  "id": "usando-jquery",
  "label": "Usando jQuery",
  "parent": null
}, {
  "depth": 0,
  "id": "configura%C3%A7%C3%A3o-do-tema-theme.json",
  "label": "Configuração do tema (Theme.json)",
  "parent": null
}, {
  "depth": 0,
  "id": "configura%C3%A7%C3%B5es-do-tema-fields.json",
  "label": "Configurações do tema (Fields.json)",
  "parent": null
}, {
  "depth": 0,
  "id": "modelos-css%2C-se%C3%A7%C3%B5es%2C-modelos-de-p%C3%A1gina-etc.",
  "label": "Modelos (CSS, Seções, Modelos de página etc.)",
  "parent": null
}, {
  "depth": 1,
  "id": "se%C3%A7%C3%B5es",
  "label": "Seções",
  "parent": "modelos-css%2C-se%C3%A7%C3%B5es%2C-modelos-de-p%C3%A1gina-etc."
}, {
  "depth": 1,
  "id": "modelos-de-p%C3%A1gina",
  "label": "Modelos de página",
  "parent": "modelos-css%2C-se%C3%A7%C3%B5es%2C-modelos-de-p%C3%A1gina-etc."
}, {
  "depth": 1,
  "id": "nomenclatura-do-modelo-de-p%C3%A1gina",
  "label": "Nomenclatura do modelo de página",
  "parent": "modelos-css%2C-se%C3%A7%C3%B5es%2C-modelos-de-p%C3%A1gina-etc."
}, {
  "depth": 0,
  "id": "m%C3%B3dulos",
  "label": "Módulos",
  "parent": null
}, {
  "depth": 0,
  "id": "conte%C3%BAdo-global",
  "label": "Conteúdo global",
  "parent": null
}, {
  "depth": 1,
  "id": "parciais-globais",
  "label": "Parciais globais",
  "parent": "conte%C3%BAdo-global"
}, {
  "depth": 0,
  "id": "suporte-a-v%C3%A1rios-idiomas",
  "label": "Suporte a vários idiomas",
  "parent": null
}, {
  "depth": 0,
  "id": "elementos-m%C3%B3veis-e-responsivos",
  "label": "Elementos móveis e responsivos",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      Tabs,
      Tab,
      IFrame
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Requisitos para temas do Marketplace de modelos da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre os requisitos que um tema deve atender para ser enviado ao Marketplace de modelos da HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você está apenas começando sua jornada de criação de temas, recomendamos usar nosso boilerplate gratuito de temas do CMS, que pode ser baixado em nosso ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "Repositório Git"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.youtube.com/watch",
        children: "importado na interface do gerenciador de design da sua conta"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além disso, revise os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "requisitos da página de listagem"
      }), " ao enviar seu modelo para o Marketplace de modelos da HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "do tema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lembre-se dos seguintes limites por tema:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["As contas gratuitas do ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "CMS Hub"
            })
          }), " não podem usar a pesquisa do site, a funcionalidade de CTAs nem vídeo nativo do HubSpot. Saiba mais sobre o que está incluído nas assinaturas do ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "CMS Hub"
            })
          }), " no ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
            children: "catálogo de produtos e serviços da HubSpot"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Os temas não podem conter mais de:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 modelos"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 módulos"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 seções"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Os temas ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "não"
          }), " devem conter:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Modelos de e-mail"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Funcionalidades do HubDB"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Funções sem servidor"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Campos de objeto do CRM"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/drag-and-drop-templates#flexible-columns",
              children: "Colunas flexíveis em modelos"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Módulos x seções"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "As seções são úteis porque os criadores de conteúdo somente podem soltá-las em áreas com largura total na página, o que ajuda você, como desenvolvedor, a garantir um ótimo produto final."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Em especial, as seções de layout fixo, nas quais o criador de conteúdo não pode mover os elementos, são uma ótima ferramenta para fornecer formatação e layout criativos que, de outra forma, não poderiam ser criados usando o editor de arrastar e soltar."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "As seções também oferecem benefícios extras de usabilidade para o criador de conteúdo, pois podem selecionar módulos individuais dentro da seção, fazendo com que os formulários dos módulos sejam mais curtos e mais direcionados ao elemento que estão editando."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Requisitos gerais para temas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Todos os temas enviados devem ser distintos e originais. Por exemplo, o mesmo tema com conteúdo de texto ou espaços reservados diferentes não conta como um tema distinto. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/template-policies#template-marketplace-compliance",
          children: "Saiba mais sobre a conformidade com o Marketplace de modelos da HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um tema deve ser criado com modelos HTML + HubL e tags ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os temas devem respeitar uma grade de 12 colunas."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estrutura de arquivos do tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos os temas devem conter uma estrutura de pastas adequada e ser agrupados em uma pasta principal, que deve descrever a listagem do produto. Por exemplo, se você criar um tema chamado “SuperAwesome” para o marketplace, sua estrutura deve ser semelhante à imagem abaixo. Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-file-structure",
        children: "estrutura de arquivos do tema"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/template-name.png",
          alt: "template-name"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/file-structure.png",
          alt: "file-structure"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Caminhos relativos de arquivos locais para modelos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você ", (0, _jsxRuntime.jsx)("u", {
        children: "deve"
      }), " usar caminhos de arquivo locais relativos ao referenciar os ativos do tema. A melhor maneira de incluí-los é usar a função ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#get-asset-url",
        children: "get_asset_url"
      }), ", que retorna a URL pública de um ativo, arquivo ou modelo. Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#get-asset-url",
        children: "gerar automaticamente essa função"
      }), " no aplicativo clicando com o botão direito do mouse em um ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "arquivo"
      }), " e selecionando ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copiar URL público"
      }), ", ou clicando em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ações"
      }), " e selecionando ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copiar URL público"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, uma folha de estilo referenciada por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "get_asset_url"
      }), " deve ser formatada da seguinte forma:"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrada",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{require_css(get_asset_url('../../css/main.css')) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "//cdn2.hubspot.net/hub/1234567/hub_generated/template_assets/1565970767575/custom/styles/style.min.css\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No vídeo abaixo, analise as diferenças na estrutura de arquivos na sua conta de desenvolvedor em relação aos arquivos entregues a um cliente do marketplace:"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/SSOdhqOciuk?feature=oembed",
      maxHeight: "144.64px",
      maxWidth: "256px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Desempenho do tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando o Google Lighthouse, um tema deve ter uma pontuação ", (0, _jsxRuntime.jsx)("u", {
        children: "superior"
      }), " aos seguintes limites:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Acessibilidade em desktops:"
        }), " 65"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Práticas recomendadas para desktops:"
        }), " 80"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Desempenho em desktops:"
        }), " 70"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Desempenho em dispositivos móveis:"
        }), " 40"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#evaluate-themes-and-templates-for-seo-and-accessibility",
        children: "gerar um relatório do Google Lighthouse para seu tema usando a CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os arquivos do tema devem poder ser minificados."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Todos os arquivos de imagem devem ter menos de 1 MB."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Todas as tags de imagem devem ter um atributo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "alt"
        }), " (um valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"\""
        }), " é aceitável)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Todas as tags de imagem devem ter um atributo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "loading"
        }), " (um valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"\""
        }), " é aceitável)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "URLs de visualização para tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você deve usar seu próprio nome de domínio ao criar URLs de visualização. Você não pode usar o domínio fornecido pela HubSpot com esta estrutura de URL: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[AccountID].hs-sites.com"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Deve ser usado um site ativo, não uma imagem de um site de demonstração."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se, a qualquer momento, sua demonstração ativa se tornar inacessível, a HubSpot reserva-se o direito, com notificação ao fornecedor, de retirar/remover seu tema até que a demonstração ativa fique acessível novamente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usando jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["o jQuery ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/include-jquery-across-your-hubspot-pages",
        children: "não está habilitado por padrão"
      }), " na conta da HubSpot de um cliente. Se o seu tema depende de jQuery, uma versão do jQuery deve ser incluída para garantir que o tema funcione conforme o esperado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, se você incluir um módulo que exija jQuery em um site que não usa jQuery, precisará usar o seguinte código para carregá-lo jQuery:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# this checks if the \"Include jQuery\" option in Settings > CMS > Pages is checked #}\n{% if not site_settings.include_jquery %}\n  {{ require_js(\"../jquery-3.4.1.js\", \"footer\") }}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuração do tema (Theme.json)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " deve incluir os seguintes parâmetros:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// theme.json\n{\n  \"label\": \"Cool Theme\",\n  \"preview_path\": \"./templates/home-page.html\",\n  \"screenshot_path\": \"./images/templates/homepage.jpg\",\n  \"enable_domain_stylesheets\": false,\n  \"version\": \"1.0\",\n  \"author\": {\n    \"name\": \"Jon McLaren\",\n    \"email\": \"noreply@hubspot.com\",\n    \"url\": \"https://theme-provider.com/\"\n  },\n  \"documentation_url\": \"https://theme-provider.com/cool-theme/documentation\",\n  \"license\": \"./license.txt\",\n  \"example_url\": \"https://theme-provider.com/cool-theme/demo\",\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verifique o seu arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " e certifique-se do seguinte:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O nome do rótulo corresponde ao nome na sua listagem de temas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se você usar o boilerplate de tema gratuito do CMS da HubSpot, os valores do boilerplate não devem estar presentes. Inclui informações do autor, URL da documentação, URL de exemplo etc."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O URL da documentação apresenta a documentação sobre como usar o seu tema."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O caminho de visualização é um arquivo válido no seu tema."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O caminho da captura de tela é um arquivo válido e está relacionado ao seu tema."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O URL de exemplo leva a uma demonstração do seu tema. Não use subdomínios ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "preview.hs-sites.com"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[AccountID].hs-sites.com"
        }), " no URL de exemplo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-json",
        children: "parâmetros theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurações do tema (Fields.json)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " controla os campos e grupos de campos disponíveis no editor de temas, incluindo campos de estilo. Os campos que você decide incluir dependem de quanto controle você quer que os criadores de conteúdo tenham no editor de páginas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " deve conter pelo menos três campos de cor."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para garantir compatibilidade entre temas e módulos independentes, os temas devem incluir as seguintes convenções de nomenclatura padrão para fonte e cor: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "heading_font"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "body_font"
        }), ". Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/beta-docs/guides/cms/marketplace/template-policies#module-compatibility-with-themes",
          children: "compatibilidade entre temas e módulos"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se os campos do tema não tiverem os campos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primary_color"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secondary_color"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "heading_font"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body_font"
      }), ", eles poderão usar o campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "alternate_names"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre esses ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#fields-json",
        children: "parâmetros fields.json"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/fields.json",
        children: "veja um exemplo de arquivo fields.json"
      }), " no boilerplate do HubSpot CMS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As configurações do tema também devem:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Não entrar em conflito com os estilos do editor ou com os estilos definidos por meio de um módulo. Por exemplo, não use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "!important"
        }), " em sua folha de estilo CSS, pois isso dificulta a substituição pelos usuários finais e pode causar um conflito."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Usar rótulos descritivos para cada configuração, para que os criadores de conteúdo saibam o que estão atualizando."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ser aplicadas a todos os modelos de um tema, a menos que haja um caso de uso específico para estilos adicionais. Por exemplo, as alterações no estilo e tamanho dos cabeçalhos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " nas configurações de tema devem ser aplicadas a todas as tags ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " no tema."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um tema ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "deve"
      }), " incluir no mínimo os seguintes campos:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Campos de tipografia:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Campo da fonte de texto do corpo (tags ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Campos de fonte ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h6"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cor do hiperlink (tags ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "a"
        }), "), incluindo estilo ao passar o mouse"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Campos de formulário:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Plano de fundo do título do formulário"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Texto do título do formulário, incluindo pelo menos estilos de cores de fonte"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cor do plano de fundo do formulário"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cor da borda do formulário"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cor do rótulo do formulário"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cor da borda do campo do formulário"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Botão de formulário - inclui configurações para texto do botão, cor de fundo e estilo ao passar o mouse."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além disso:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os campos do seu tema devem ser agrupados de forma lógica, quando apropriado. Por exemplo, vários campos relacionados à tipografia devem ser agrupados em um grupo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Typography"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os campos de tema devem ter controles de cor e fonte separados para botões e formulários, além de controles de cor, logotipo e fonte separados para cabeçalho e rodapé."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Uma parte dos campos de cor e logotipo do tema deve ser herdada das configurações de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/brand-and-settings-inheritance",
          children: "marca da conta"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Pelo menos dois campos de cor devem herdar as cores das configurações de marca da conta. Campos de cor adicionais podem ser predefinidos para outras cores, incluindo preto e branco."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se os módulos em um tema estiverem usando logotipos, pelo menos um campo de logotipo deve ser herdado das configurações de marca da conta. Se estiver usando um campo de imagem para renderizar um logotipo, o campo de imagem não precisa ser herdado das configurações da marca."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Como as cores da marca afetam a estética do seu tema"
      })
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/voyJupcZYeQ?feature=oembed",
      maxHeight: "144.64px",
      maxWidth: "256px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modelos (CSS, Seções, Modelos de página etc.)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seções"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você deve usar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections#reusable-sections",
          children: "seções"
        }), " sempre que aplicável. ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Deve"
        }), " haver um mínimo de cinco seções em um tema."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "As seções devem ter capturas de tela únicas e funcionais."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Seções e módulos não devem ser redundantes."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modelos de página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um tema ", (0, _jsxRuntime.jsx)("u", {
        children: "deve"
      }), " incluir no mínimo os seguintes tipos de modelos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Um modelo de página de site ou landing page."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ao incluir vários modelos de página, cada modelo deve ter um propósito diferente. Por exemplo, uma página principal, uma página ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Sobre"
            }), ", uma landing page de largura inteira e uma landing page com uma barra lateral direita."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "É recomendado incluir pelo menos oito modelos de página em um tema."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Modelos separados de listagem de blog e de post de blog."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modelo de listagem de blog"
            }), ": a página que mostra todos os posts de blog em formato de listagem (conhecido como a rolagem de blog). O título do modelo deve indicar que se trata de uma página de listagem."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modelo de post de blog"
            }), ": a página de detalhes do post de blog que exibe posts de blog individuais. O título do modelo deve indicar que se trata de uma página de post de blog."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Além disso, as caixas de comentários e autor do blog devem ser estilizadas para corresponder ao tema."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Os seguintes modelos de página do sistema:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modelo de erro 404"
            }), ": exibido quando os visitantes acessam uma página que não existe."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modelo de erro 500"
            }), ": exibido quando o site encontra um erro interno."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modelo de solicitação de senha"
            }), ": exibido quando uma página é protegida por senha."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modelo de assinatura"
            }), ": uma página de preferências de assinatura onde os destinatários de e-mail podem gerenciar os tipos de e-mails que assinaram."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modelo de atualização de assinatura"
            }), ": uma página de confirmação exibida quando um destinatário de e-mail atualiza suas preferências de assinatura."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modelo de cancelamento de assinatura de backup"
            }), ": a página que aparecerá para destinatários de e-mail que estão tentando cancelar a assinatura, caso o HubSpot não possa determinar seu endereço de e-mail."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modelo de resultados de pesquisa"
            }), ": exibe os resultados de pesquisa que são retornados ao usar a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/content-search",
              children: "pesquisa do site"
            }), ". Disponível somente para contas pagas do ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "CMS Hub"
              })
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Nomenclatura do modelo de página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se você tiver modelos com nomes semelhantes, adicione palavras descritivas que denotem a diferença entre eles."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use letras maiúsculas e minúsculas de forma consistente, remova hifens e evite usar formas abreviadas (por exemplo, escreva background em vez de usar bg)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O nome da sua empresa ou do tema não precisa ser incluído no nome do modelo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre os requisitos para módulos de temas e módulos individuais ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/module-requirements",
        children: "aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Conteúdo global"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Parciais globais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "Parciais globais"
      }), " são um tipo de modelo criado usando HTML e HubL que pode ser reutilizado em todo o site. Os tipos mais comuns de parciais são cabeçalhos do site, barras laterais e rodapés. Saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content#creating-a-global-content-partial-template-using-local-development-tools",
        children: "criar parciais globais"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os temas ", (0, _jsxRuntime.jsx)("u", {
          children: "devem"
        }), " incluir parciais globais."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["As parciais globais ", (0, _jsxRuntime.jsx)("u", {
          children: "devem"
        }), " incluir ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "áreas de arrastar e soltar"
        }), " usáveis. Por exemplo, você não pode ocultar a área de arrastar e soltar com uma classe \"hide\"."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você ", (0, _jsxRuntime.jsx)("u", {
          children: "deve"
        }), " incorporar áreas utilizáveis de arrastar e soltar em cabeçalhos e rodapés."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para os menus usados globalmente em um site, os usuários também devem poder ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/menus-and-navigation",
          children: "selecionar um menu de navegação do HubSpot"
        }), " criado nas configurações da conta."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": evite incluir módulos globais em parciais globais, pois pode criar uma experiência negativa para o usuário final."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suporte a vários idiomas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os temas ", (0, _jsxRuntime.jsx)("u", {
        children: "devem"
      }), " oferecer suporte a versões em vários idiomas e devem especificar os idiomas disponíveis. Faça isso adicionando o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#language-switcher",
        children: "módulo de alternador de idioma"
      }), " a um cabeçalho global, o que permitirá que os clientes localizem facilmente as opções de idioma e escolham seu idioma desejado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você ", (0, _jsxRuntime.jsx)("u", {
          children: "deve"
        }), " exibir apenas um idioma por vez. Por exemplo, evite exibir texto em inglês e espanhol na interface do usuário ao mesmo tempo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Evite usar texto embutido em código. Por exemplo, em vez de codificar o texto de um botão de listagem de blog como ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Leia mais"
        }), ", defina o texto de um campo para que usuário final possa atualizá-lo sem precisar entrar no código."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Elementos móveis e responsivos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os temas devem ser capazes de adaptar o conteúdo ao dispositivo em que são visualizados. Também devem proporcionar uma boa experiência ao usuário em vários dispositivos. Isso inclui, mas não está limitado a:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Navegação principal"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Controles deslizantes e guias"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Imagens grandes"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Evitar rolagem horizontal (a menos que seja intencional)"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}