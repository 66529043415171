import ArrayField from '../components/fields/array/ArrayField';
import ArrayOneOfField from '../components/fields/array/ArrayOneOfField';
import BinaryField from '../components/fields/BinaryField';
import BooleanField from '../components/fields/BooleanField';
import DateField from '../components/fields/DateField';
import DateTimeField from '../components/fields/DateTimeField';
import EnumField from '../components/fields/EnumField';
import IntegerField from '../components/fields/IntegerField';
import JsonField from '../components/fields/JsonField';
import NumberField from '../components/fields/NumberField';
import ObjectField from '../components/fields/ObjectField';
import ObjectOneOfField from '../components/fields/ObjectOneOfField';
import StringField from '../components/fields/StringField';
const getComponentTypes = () => {
  return {
    array: ArrayField,
    'string.binary': BinaryField,
    'string.byte': BinaryField,
    boolean: BooleanField,
    'string.date': DateField,
    'string.date-time': DateTimeField,
    enum: EnumField,
    integer: IntegerField,
    json: JsonField,
    number: NumberField,
    object: ObjectField,
    'oneOf.array': ArrayOneOfField,
    'oneOf.object': ObjectOneOfField,
    string: StringField
    // null: 'NullField',
  };
};
export default getComponentTypes;