"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45939803992;
const slug = exports.slug = 'reference/cms/modules/export-to-template-context';
const title = exports.title = 'export_to_template_context';
const name = exports.name = 'APAC JAPAN (JA) | [new] export_to_template_context | 202106';
const metaDescription = exports.metaDescription = 'export_to_template_contextは、モジュールを実際に表示せずに、モジュールのパラメーターをテンプレート環境で使用できるようにするパラメーターです。このパラメーターは、全てのHubLモジュールタグに使用できます。これらのパラメーターを取得し、変数に保存し、テンプレートのロジックに含めるには、widget_dataタグを使用します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E5%86%85%E3%81%A7%E3%81%AE%E5%88%A9%E7%94%A8",
  "label": "カスタムモジュール内での利用",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%8C%E9%81%B8%E6%8A%9E%E3%81%A7%E3%81%8D%E3%82%8B%E8%83%8C%E6%99%AF%E7%94%BB%E5%83%8F",
  "label": "ユーザーが選択できる背景画像",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%AE%9A%E7%BE%A9%E6%B8%88%E3%81%BF%E3%81%AE%E3%83%9E%E3%83%BC%E3%82%AF%E3%82%A2%E3%83%83%E3%83%97%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B%E3%81%9F%E3%82%81%E3%81%AE%E9%81%B8%E6%8A%9E%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89",
  "label": "定義済みのマークアップを表示するための選択フィールド",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AB%E3%81%99%E3%81%A7%E3%81%AB%E8%A1%A8%E7%A4%BA%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%8B%E3%82%89%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "テンプレートにすでに表示されているモジュールからパラメーターを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "hubl%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E6%83%85%E5%A0%B1%E3%82%92%E3%83%96%E3%83%AD%E3%82%B0%E3%83%AA%E3%82%B9%E3%83%88%E3%81%AB%E5%87%BA%E5%8A%9B%E3%81%99%E3%82%8B",
  "label": "HubLモジュール情報をブログリストに出力する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      pre: "pre",
      h2: "h2",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "export_to_template_context"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), "は、HubLタグを実際に表示することなく、HubLタグのパラメーターをテンプレート環境で使用するためのパラメーターです。このパラメーターは、全ての", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "HubLタグ"
      }), "に使用できます。これらのパラメーターを取得し、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "変数"
      }), "に保存し、テンプレートのロジックに含めるには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " dict（ディクショナリー）を使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "表示されていないHubLタグのパラメーターをテンプレートのコンテキストで使用できれば、コンテンツエディターでのテンプレートの表示方法をユーザーが決めることが可能になります。例えば、ユーザーがフィールドに値を入力したときに、特定のコードブロックのみを表示する必要があるとします。これは、このパラメーターを使用することで実現できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["まず、HubLタグに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context=True"
      }), "を追加する必要があります。次に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data.module.parameter_you_want_to_retreive"
      }), "を使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"job_title\" path=\"@hubspot/text\", label=\"Enter a Job Title\", value=\"Chief Morale Officer\", export_to_template_context=True %}\n{# Makes the parameters available to be stored and used in template logic #}\n\n{{ widget_data.job_title.body.value }}\n{# Prints the value of the HubL tag but can also be used in template logic #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下では、この考え方の適用例をいくつか紹介します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "カスタムモジュール内での利用"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context=True"
      }), "は、カスタムモジュールにとっては実質的な機能はないため、カスタムモジュールではサポートされません。テンプレート内のモジュールには", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#retrieving-parameters-from-modules-already-rendered-on-the-template",
        children: "すでにアクセスできる"
      }), "ので、モジュール内の値を取得するために", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), "を使用する必要がありません。モジュールの出力を非表示にする必要がある場合は、何も出力しないようにモジュールを作成できます。つまり、モジュールで何かを表示するかどうかを有効または無効にするブール値フィールドを含めます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ユーザーが選択できる背景画像"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この例では、画像HubLタグを作成し、それを表示することなくテンプレートのコンテキストにエクスポートします。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), "タグで", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), "パラメーターを取得し、styleタグの背景画像のソースとして表示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"background_image\" path=\"@hubspot/image\" label='Select a background image',\nsrc='http://cdn2.hubspot.net/hub/428357/file-2117441560-jpg/img/dev-bg-compressed.jpg',\nexport_to_template_context=True %}\n\n\n<!--Sample markup -->\n<div class=\"bg-image-section\" style=\"background:url('{{ widget_data.background_image.src }}'); background-size:cover; min-height: 500px;\">\n    <h1>Supercharge your app with HubSpot</h1>\n    <h3>Build powerful integrations for a global community of users</h3>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これはコードテンプレートでも実現できますが、通常はカスタムモジュールを作成して、ページエディターのユーザーに最適な編集環境を提供することをお勧めします。このようなHubLタグは個別のフィールドとして表示されますが、関連するフィールドが複数存在する場合もあります。カスタムモジュールを使用すると、ページエディターではモジュールの全てのフィールドがグループ化された状態で表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "定義済みのマークアップを表示するための選択フィールド"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下の例では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), "パラメーターと", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#choice",
        children: "選択モジュール"
      }), "を組み合わせて、求人ページのバナーメッセージを変更しています。ユーザーがUIを使用して部門を選択すると、ユーザーが実際にコンテンツを編集しなくても見出しが変更されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"department\" path=\"@hubspot/choice\", label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?!</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これと同じ機能を、カスタムモジュール内の選択フィールドを使用して再現できます。カスタムモジュールのUIでは、値とラベルの両方を含む選択オプションを非常に簡単に作成できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレートにすでに表示されているモジュールからパラメーターを取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページにすでに表示されているモジュールまたはタグからパラメーターを取得する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgets"
      }), "という名前のdict内でそのモジュールにアクセスできます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), "パラメーターは必須ではありません。構文は次のとおりです。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// HubL\n{{ content.widgets.name_of_module.body.parameter }}\n\n{{ content.widgets.my_text.body.value }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "content.widgets"
        }), "はグローバルモジュールにアクセスしないので、上記のメソッドはグローバルモジュールのフィールドからの値の取得をサポートしません。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["フィールドによってデータの保存形式が異なるため、表示するデータへのアクセス方法を確認するには、多くの場合、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting#developer-info",
        children: "開発者情報"
      }), "を使用すると便利です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubLモジュール情報をブログリストに出力する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ブログテンプレートは、一般的にはブログに使用されますが、他の種類のリストを作成するために流用することもできます。前述の手法を使用してこの目的を達成できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、会社が受信した報道記事のリストレイアウトを作成するとして、記事にリンクせずに、リストを別のページにリンクする場合があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/projects",
        children: "academy.hubspot.com/projects"
      }), "でこの考え方の実例を確認できます。プロジェクト リスト ページはブログ リスト テンプレートですが、個々の記事は通常のHubSpotページにリンクしています。制作担当者はエディターでリンク先を指定します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["個々のブログ記事のコードの先頭で、テキストフィールドを定義します。記事にこのテキストを表示しない場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), "を使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"custom_blog_link\" path=\"@hubspot/text\", label=\"Link to external press item\", export_to_template_context=True %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このテキストフィールドはブログ記事ごとに編集できます。次に、リスト内のリンクを定義する必要があります。しかし、widget_dataは記事のコンテキストにのみ存在するため、別の構文を使用してウィジェットデータを取得し、リンクに設定する必要があります。この場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content.widgets.custom_blog_link.body.value"
      }), "を使用します。ブログリストでは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), "を使用できませんが、そのフィールドの値は個々のコンテンツのウィジェットのコンテキスト内に保存されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "各記事を含むこのカスタムリンクを表示する基本的なブログリストのループを以下に示します。この方法を使用する場合は、ブログ記事ごとに自動的に作成されたサブディレクトリーをrobots.txtファイルに追加して、空の記事がGoogle などのクローラーによってクロールされないようにする必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n<a href=\"{{ content.widgets.custom_blog_link.body.value }}\">\n    Click here to see this press feature!\n</a>\n{% endfor %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}