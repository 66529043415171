"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611090;
const slug = exports.slug = 'guides/cms/overview';
const title = exports.title = 'Visão geral do HubSpot CMS';
const name = exports.name = 'Visão geral do HubSpot CMS';
const metaDescription = exports.metaDescription = 'Visão geral de alto nível do Content Hub da HubSpot para desenvolvedores, mostrando todos os principais conceitos, como temas, modelos e módulos, e como eles se encaixam.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "ativa%C3%A7%C3%A3o-de-conte%C3%BAdo",
  "label": "Ativação de conteúdo",
  "parent": null
}, {
  "depth": 0,
  "id": "tipos-de-conte%C3%BAdo",
  "label": "Tipos de conteúdo",
  "parent": null
}, {
  "depth": 1,
  "id": "p%C3%A1ginas-do-site-e-landing-pages",
  "label": "Páginas do site e landing pages",
  "parent": "tipos-de-conte%C3%BAdo"
}, {
  "depth": 1,
  "id": "blogs",
  "label": "Blogs",
  "parent": "tipos-de-conte%C3%BAdo"
}, {
  "depth": 1,
  "id": "e-mails",
  "label": "E-mails",
  "parent": "tipos-de-conte%C3%BAdo"
}, {
  "depth": 0,
  "id": "conte%C3%BAdo-estruturado",
  "label": "Conteúdo estruturado",
  "parent": null
}, {
  "depth": 0,
  "id": "sistema-de-arquivos-do-desenvolvedor",
  "label": "Sistema de arquivos do desenvolvedor",
  "parent": null
}, {
  "depth": 0,
  "id": "temas%2C-modelos%2C-m%C3%B3dulos-e-campos",
  "label": "Temas, modelos, módulos e campos",
  "parent": null
}, {
  "depth": 1,
  "id": "temas",
  "label": "Temas",
  "parent": "temas%2C-modelos%2C-m%C3%B3dulos-e-campos"
}, {
  "depth": 1,
  "id": "modelos",
  "label": "Modelos",
  "parent": "temas%2C-modelos%2C-m%C3%B3dulos-e-campos"
}, {
  "depth": 1,
  "id": "m%C3%B3dulos",
  "label": "Módulos",
  "parent": "temas%2C-modelos%2C-m%C3%B3dulos-e-campos"
}, {
  "depth": 1,
  "id": "campos",
  "label": "Campos",
  "parent": "temas%2C-modelos%2C-m%C3%B3dulos-e-campos"
}, {
  "depth": 0,
  "id": "a-linguagem-do-hubl",
  "label": "A linguagem do HubL",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      img: "img",
      h3: "h3",
      ul: "ul",
      li: "li",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral do HubSpot CMS"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta seção foi projetada para ajudá-lo a compreender os principais aspectos do ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS"
        })
      }), " da HubSpot e a criar sites excelentes grandes com base nele. Para tirar o máximo proveito disso, é esperado um entendimento de nível profissional dos conceitos básicos sobre desenvolvimento web, incluindo HTML, JavaScript e CSS."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você está começando a construir no CMS, primeiro confira o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "tutorial de início rápido"
      }), " e depois consulte esta visão geral conforme necessário."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ativação de conteúdo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O CMS da HubSpot foi projetado para ajudar as empresas a aumentar sua presença na Web, com ênfase na criação e no gerenciamento de conteúdo pelos profissionais de marketing. O conteúdo, a coleta de leads e a análise do site também estão integrados ao ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/crm",
        children: "HubSpot CRM"
      }), ", facilitando a criação de experiências personalizadas para os visitantes, integrando-as ao resto do negócio."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como programador do CMS da HubSpot, você não apenas cria ativos como temas, modelos e módulos, como também os cria de forma a permitir que os criadores de conteúdos os personalizem com conteúdo conforme necessário. Dessa forma, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "você terá que"
        })
      }), " equilibrar as necessidades dos desenvolvedores com as necessidades dos criadores de conteúdo por meio de seu sistema de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "temas"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "áreas de arrastar e soltar"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/code-no-code-graphic.png",
        alt: "Os modelos e módulos podem ser usados tanto pelos desenvolvedores quanto pelos profissionais de marketing"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um site devidamente criado deve ser desenvolvido em estreita colaboração com os criadores de conteúdo para compreender suas necessidades. Assim, exigirá menos suporte e manutenção contínua por parte de um desenvolvedor. Para isso, é recomendado que você ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow#testing",
        children: "É experimente como é a experiência de construir a página para os criadores de conteúdo"
      }), " enquanto você mesmo a desenvolve. Isso garante que eles possam trabalhar de forma independente com o site, tanto quanto possível."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/making%20a%20page.gif",
        alt: "Animação mostrando o usuário criando uma página de arrastar e soltar"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A HubSpot se encarrega da hospedagem e da manutenção das suas páginas, ou seja, você não precisa se preocupar com o gerenciamento de plugins, atualizações, hospedagem, escalonamento ou segurança. A desvantagem é que o sistema impõe mais algumas restrições no que você pode fazer do que CMSs auto-hospedados. Por exemplo, você não pode alterar ou estender os fundamentos do sistema manualmente ou por meio de plugins, manipular renderizações de baixo nível ou acessar e alterar diretamente o conteúdo na base de dados."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O conteúdo criado pelo desenvolvedor (por exemplo, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "temas"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modelos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), ", JavaScript e CSS) é desenvolvido em um sistema de arquivos de desenvolvedor, enquanto o conteúdo da página (páginas, posts de blog) é disposto e criado em um bloco poderoso baseado em um editor WYSIWYG (o que você vê é o que você recebe) e os arquivos de mídia (imagens criadas pelo criador do conteúdo, PDFs etc.) são armazenados em um gerenciador de arquivos baseado em um aplicativo web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando uma página é renderizada, o HubSpot encaminha o pedido para um dos muitos servidores baseados no domínio, renderiza a página em nossos servidores e a armazena em cache em uma rede de entrega de conteúdo (CDN), se possível."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de conteúdo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há muitos tipos de conteúdo que você pode criar usando o CMS da HubSpot. A interface do usuário para criadores de conteúdo é ligeiramente diferente dependendo do tipo de conteúdo, com implicações que, você, como desenvolvedor, precisa saber."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Páginas do site e landing pages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O site e as landing pages são criados de forma independente, mas todas as páginas são baseadas em modelos. Para os criadores de conteúdo, o processo de criação de uma landing page ou uma página do site é quase idêntico. A diferença entre elas é que as páginas do site são feitas para apresentar informações que fazem parte do seu site e são projetadas para serem encontradas de forma orgânica, enquanto que uma landing page ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/marketing/landing-page-best-practices",
        children: "geralmente está associada a uma oferta ou campanha de marketing específica"
      }), " (por exemplo, um link em um e-mail de marketing enviado para uma lista específica de contatos)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Na IU para profissionais de marketing, a análise e a organização desses tipos de páginas também são independentes, já que, muitas vezes, as landing pages têm objetivos de conversão específicos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blogs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os blogs da HubSpot têm duas visualizações: uma para a página de listagem e outra para a página de posts individuais, e ambas são preenchidas com cada post de blog. Você pode definir um blog para compartilhar o mesmo modelo para posts de blog e páginas de listagem ou ter modelos separados. Os posts de blog devem compartilhar o mesmo modelo. Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "marcação do modelo de blog"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/blog/manage-your-blog-template-and-settings",
        children: "como criar e gerenciar blogs no HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "E-mails"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os e-mails podem ser criados de algumas maneiras no HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-mail clássico:"
        }), " crie modelos e módulos de e-mail de forma semelhante à criação de sites e landing pages. Você também pode criar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/email-template-markup",
          children: "modelos de e-mail codificados"
        }), " para ter controle total da marcação."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-mails do tipo arrastar e soltar:"
        }), " crie modelos de e-mail personalizáveis do tipo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-marketing-emails-in-the-drag-and-drop-email-editor",
          children: "arrastar e soltar"
        }), " que permitem aos criadores de conteúdo criar layout e conteúdo de e-mail usando a interface de arrastar e soltar da HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Conteúdo estruturado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além de criar o conteúdo da página por meio de editores no aplicativo ou codificação em modelos, você também pode usar fontes de dados estruturadas para preencher o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "conteúdo dinâmico da página"
      }), " com o HubL. Você pode usar as seguintes fontes de dados para preencher as páginas:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#hubdb-dynamic-pages",
          children: "HubDB"
        }), ": armazene dados em células de tabelas HubDB."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/overview#crm-object-dynamic-pages",
          children: "Registros do CRM"
        }), ": armazene dados em registros do CRM, como contatos, empresas ou objetos personalizados."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Criar páginas dinâmicas usando conteúdo estruturado significa que você pode criar, editar e remover páginas do site e conteúdo da página atualizando diretamente as fontes de dados. Semelhante a um blog da HubSpot, um conjunto de páginas dinâmicas incluirá uma única página de listagem para exibir as instâncias da sua fonte de dados e, em seguida, uma página separada para cada instância individual. Com o HubL, você pode configurar totalmente os dados exibidos nas páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, você pode criar uma tabela HubDB que armazena uma linha de informações para cada membro de uma equipe de vendas. Usando essa tabela do HubDB, o HubSpot pode gerar uma página de listagem para exibir os principais detalhes de cada linha da tabela (como um nome e uma imagem para cada representante de vendas), juntamente com uma página separada por representante de vendas para exibir mais informações (como sua biografia e número de telefone). Se um representante de vendas for promovido posteriormente para uma equipe diferente, você poderá excluir sua linha da tabela HubDB e o HubSpot excluirá automaticamente sua página de detalhes, removendo-o da página de listagem."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sistema de arquivos do desenvolvedor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ativos principais (modelos, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "temas"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), ", bem como os arquivos de JavaScript, CSS e imagens que os suportam) são criados em um sistema de arquivos do desenvolvedor. Você pode ver este sistema de arquivos no painel esquerdo do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Gerenciador de design"
      }), " ou em pastas sincronizadas localmente usando as ferramentas de desenvolvimento local. Dentro do sistema de arquivos, os ativos podem se referir uns aos outros com caminhos absolutos ou relativos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em segundo plano, esses arquivos são mapeados para entradas em um banco de dados. É por isso que o acesso ao sistema de arquivos do desenvolvedor se dá por meio das ferramentas de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI"
      }), " da HubSpot em vez do acesso direto SSH ou FTP, e alguns recursos do sistema de arquivos que você pode esperar, como permissões e links simbólicos, não são oferecidos no sistema de arquivos do desenvolvedor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Isso difere da abordagem dos CMSs tradicionais, mas significa que as referências quebradas entre erros de arquivo ou sintaxe são capturadas no momento da publicação e não no momento da execução, proporcionando um isolamento extra contra falhas acidentais quando o tráfego dinâmico atinge um site."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os modelos no sistema de arquivos serão detectados automaticamente e serão mostrados aos criadores de conteúdo ao criarem novas páginas, então a estrutura do sistema de arquivos fica a seu critério. Não é necessário que os módulos residam em uma pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/modules/"
      }), " ou o JavaScript em uma pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/js/"
      }), ". No entanto, é recomendável organizar seus ativos de maneira semelhante ao ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "código de exemplo do boilerplate para o CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " por padrão, o HubSpot reduz automaticamente o JavaScript e o CSS incluídos no gerenciador de design para remover espaços, quebras de linha e comentários desnecessários. Isso também se aplica ao JavaScript e ao CSS ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "carregados no gerenciador de design por meio da CLI"
        }), ". Isso significa que você não deve adicionar um código já reduzido diretamente no gerenciador de design."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#javascript-and-css-minification",
          children: "Minificação de Javascript e CSS"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Temas, modelos, módulos e campos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "Temas"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modelos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "campos"
      }), " são os objetos com os quais você mais trabalhará. A utilização eficaz destes diferentes objetos oferece aos criadores de conteúdos a liberdade de trabalhar e iterar nos sites de forma independente, mantendo-se dentro das contenções de estilo e layout definidas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os temas e módulos contêm campos, que são configurações de tipos de dados específicos, como números, strings, rich text e imagens. Você pode controlar como os objetos são usados ao renderizá-los e como eles devem ser organizados e aparecer no editor ", (0, _jsxRuntime.jsx)("abbr", {
        title: "O que você vê é o que você recebe",
        children: "WYSIWYG"
      }), ". Os criadores de conteúdo podem definir valores para os campos no editor WYSIWYG, que são aplicados ao tema ou módulo no momento da renderização."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Temas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "temas"
      }), " são objetos de alto nível que definem a aparência de um site e criam uma experiência de edição de conteúdo simples. Esses ativos podem incluir modelos, módulos, arquivos CSS, arquivos JavaScript, imagens e muito mais."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com os temas, você pode criar um conjunto de campos que os criadores de conteúdo usam para ganhar controle sobre o estilo global de um site sem ter que editar o CSS. Você pode especificar no CSS onde esses controles são aplicados, organizar controles que são herdados de outros controles e gerenciar como são apresentados e organizados para os profissionais de marketing no Editor de tema. Os criadores de conteúdo usam o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-fields",
        children: "Editor de tema"
      }), " para modificar os campos do tema, visualizar essas alterações em relação aos modelos existentes dentro de um tema e publicar suas alterações."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esses campos de tema podem ser definidos globalmente em um site ou substituídos em nível de página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Vitality%20Theme%20Editor.png",
        alt: "Editor de tema"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modelos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modelos"
      }), " definem a marcação e o estilo de base de um conjunto de páginas que os utilizam. Eles podem conter HubL, HTML e links para arquivos JavaScript e CSS e podem ser limitados para uso com tipos específicos de conteúdo. Você tem total flexibilidade na marcação e estilo que pode incluir em um modelo, mas é encorajado a aderir a algumas práticas recomendadas e usar alguns recursos importantes do ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content Hub"
        })
      }), " para garantir que os profissionais de marketing possam editar as páginas com facilidade. Algumas das práticas recomendadas incluem:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Criar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview",
          children: "modelos"
        }), " como parte de um tema e usar CSS em nível de tema, incluindo campos de tema, para aplicar a maioria do estilo dentro de um modelo. Isso facilitará aos criadores de conteúdo fazer alterações de estilo globais e locais de forma consistente, sem a necessidade de editar o CSS."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "módulos"
        }), " para a maioria dos componentes da página, o que permite reorganizá-los e reutilizá-los em um site. Saiba mais sobre os módulos abaixo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/drag-and-drop-templates",
          children: "áreas de arrastar e soltar"
        }), " sempre que possível para o conteúdo da página principal, especialmente em páginas internas. As áreas de arrastar e soltar permitem definir um layout padrão para os módulos que compõem uma página e oferecem aos profissionais de marketing flexibilidade para editar o layout e o estilo de forma independente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
          children: "parciais globais"
        }), " para conter o conteúdo compartilhado, como cabeçalhos e rodapés, que você quer manter consistente em um site."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os modelos podem ser criados com ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL"
      }), " ou com uma interface de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "arrastar e soltar"
      }), " no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Gerenciador de design"
      }), ". No entanto, é recomendável codificar modelos, pois eles fornecem mais controle e funcionalidade do que os modelos de arrastar e soltar, como o suporte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "áreas de arrastar e soltar"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Vitality%20Homepage%20Template%20html.png",
        alt: "Código VS com modelo HTML e HubL aberto"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), " são componentes reutilizáveis que você pode colocar em modelos e páginas. Eles incluem controles para profissionais de marketing e contêm marcação HubL/HTML, CSS e JavaScript que, juntos, criam os componentes reutilizáveis e editáveis de um site."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os controles de um módulo são definidos nos campos, então construir um módulo grande significa considerar tanto a aparência resultante em uma página quanto a experiência de edição para editores de conteúdo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "_O HubSpot inclui um conjunto de _"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "módulos padrão"
      }), " comuns, como cabeçalhos, rich text, imagens, botões e CTAs, que você usará como componentes fundamentais. Provavelmente, você também pode querer criar elementos que possam ter layouts mais interessantes e se encaixem no seu tema e modelos. Alguns exemplos comuns de módulos que você pode criar incluem painéis sanfonados, controles deslizantes e grupos de abas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Module%20form%20in%20editor.png",
        alt: "Forma do módulo no editor"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode considerar um módulo como um objeto, e módulos em páginas como instâncias desse objeto, o que significa que as atualizações no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), ", no CSS ou no JavaScript de um módulo são aplicadas em todas as instâncias desse módulo dentro de páginas ou modelos em um determinado portal. Os módulos também podem ser transferidos entre os portais: ao usar a ferramenta de cópia integrada no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Gerenciador de design"
      }), ", vender no Marketplace ou compartilhar o código diretamente com as ferramentas de desenvolvimento locais. Isso significa que você pode implementar uma solução de design uma vez e usá-la em várias páginas e portais, e quando precisar atualizá-la, as alterações poderão ser aplicadas nas páginas sem a necessidade de editar várias páginas ou vários modelos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os módulos também podem ser incluídos em temas, o que permite utilizar campos de tema para manipular a aparência dos módulos e garantir que eles sejam exibidos de forma proeminente no editor de páginas para que os criadores de conteúdo possam ter acesso fácil a módulos que combinam com o design que você criou."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "guia de visão geral dos módulos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Campos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "campos"
      }), " são os controles que os criadores de conteúdo utilizam para ajustar os parâmetros que são passados para seus temas e módulos. Os campos são digitados, incluindo tipos simples, como booleano, texto, URL, escolha e arquivo, mas também incluem campos mais complexos, como fonte com estilo, bem como campos específicos do HubSpot, como links para outros conteúdos ou formulários no sistema HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os campos também podem ser colocados dentro de repetidores que irão passar uma matriz para o módulo — um exemplo disso pode ser um carrossel de imagens em que você quer que um conjunto de imagens seja passado com o texto `alt` associado. Em vez de criar vários campos de imagem e texto, você pode criar um de cada e colocá-los em um grupo de repetição."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os campos de um módulo são especificados no Gerenciador de design ou por ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#the-user-interface-for-editing",
        children: "esta sintaxe em um arquivo fields.json."
      }), " Os campos de um tema devem ser especificados no arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " na raiz do tema."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "A linguagem do HubL"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A principal linguagem que você usará para criar ativos de sites no CMS da HubSpot é a HubSpot Markup Language, ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), " (pronuncia-se \"Hubble\"). O HubL é a extensão da HubSpot do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/jinjava",
        children: "Jinjava"
      }), ", um mecanismo de modelagem baseado em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://palletsprojects.com/p/jinja/",
        children: "Jinja"
      }), ". O HubL usa uma quantidade razoável de marcação que é exclusiva da HubSpot e não suporta todos os recursos do Jinja. É executado completamente no lado do servidor quando uma página é renderizada."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubL tem os recursos que você esperaria de uma linguagem de modelos simples, como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "variáveis"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "loops For"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "instruções If"
      }), ", mas também suporta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "macros"
      }), " de renderização mais complexa, busca de dados e mapeamento com ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "tags"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "funções"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "filtros"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você alcançar os limites do que é possível com o HubL, a HubSpot oferece APIs para criar soluções mais personalizadas. As contas do **", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Content Hub"
      }), " **", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " podem utilizar as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "funções sem servidor"
      }), ", permitindo uma programação mais sofisticada do lado do servidor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode consultar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "Referência da linguagem HubL"
      }), " para obter mais detalhes sobre recursos específicos da linguagem."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}