"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279892;
const slug = exports.slug = 'guides/cms/content/performance/overview';
const title = exports.title = 'Présentation du réseau de diffusion de contenu, de la sécurité et de la performance';
const name = exports.name = 'EMEA FR CDN, security, and performance overview';
const metaDescription = exports.metaDescription = "CMS Hub offre des outils de sécurité, de fiabilité, de performance et de maintenance ainsi qu'un réseau de diffusion de contenu rapide et global";
const toc = exports.toc = [{
  "depth": 0,
  "id": "r%C3%A9seau-de-diffusion-de-contenu",
  "label": "Réseau de diffusion de contenu",
  "parent": null
}, {
  "depth": 0,
  "id": "protocole-ssl-secure-socket-layer",
  "label": "Protocole SSL (Secure Socket Layer)",
  "parent": null
}, {
  "depth": 0,
  "id": "protocole-http%2F2",
  "label": "Protocole HTTP/2",
  "parent": null
}, {
  "depth": 0,
  "id": "protocole-ipv6",
  "label": "Protocole IPv6",
  "parent": null
}, {
  "depth": 0,
  "id": "javascript-et-minification-css",
  "label": "JavaScript et minification CSS",
  "parent": null
}, {
  "depth": 0,
  "id": "mise-en-cache-du-navigateur-et-du-serveur",
  "label": "Mise en cache du navigateur et du serveur",
  "parent": null
}, {
  "depth": 0,
  "id": "r%C3%A9%C3%A9criture-de-domaine",
  "label": "Réécriture de domaine",
  "parent": null
}, {
  "depth": 0,
  "id": "compression-de-texte",
  "label": "Compression de texte",
  "parent": null
}, {
  "depth": 0,
  "id": "compression%2Foptimisation-et-redimensionnement-automatique-d-image",
  "label": "Compression/optimisation et redimensionnement automatique d'image",
  "parent": null
}, {
  "depth": 0,
  "id": "pages-mobiles-acc%C3%A9l%C3%A9r%C3%A9es-amp",
  "label": "Pages mobiles accélérées (AMP)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Présentation du réseau de diffusion de contenu, de la sécurité et de la performance"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le CMS de HubSpot est l'un des systèmes de gestion de contenu les plus avancés et optimisés du marché. Étant donné que CMS Hub est une solution SaaS, nous gérons ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.fr/security",
        children: "la sécurité"
      }), ", la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.fr/reliability",
        children: "fiabilité"
      }), ", la maintenance et plus encore, afin que votre équipe puisse se concentrer sur la rédaction de code et la création d'expériences utilisateur conviviales."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si HubSpot apporte beaucoup d'améliorations des performances pour vous, il vous reste beaucoup à faire. Découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "optimiser les performances sur CMS Hub."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Réseau de diffusion de contenu"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le réseau de diffusion de contenu mondial de CMS Hub garantit des temps de chargement de page ultra-rapides, où que se trouvent vos visiteurs. Aucune configuration, aucune installation ou aucun compte supplémentaire n'est nécessaire pour tirer parti du réseau de diffusion de contenu pour héberger du contenu multimédia ou des pages. Le système prend en charge la distribution et l'invalidation de cache pour vous afin que vous puissiez vous concentrer sur la conception d'un excellent site tandis qu'un pare-feu d'application web et des mesures de sécurité intégrées vous protègent contre les attaques en ligne."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Protocole SSL (Secure Socket Layer)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le protocole SSL est inclus et automatiquement mis à disposition gratuitement sur tous les domaines connectés dans les comptes CMS Hub et Marketing Hub. Chaque domaine dispose de son propre certificat SAN, avec des options configurables telles que la désactivation de la prise en charge de certaines versions de TLS, la redirection des requêtes effectuées sur HTTP vers HTTPS et le service de l'en-tête HSTS afin que les futures requêtes soient effectuées sur HTTPS. Sur demande, les certificats SSL personnalisés peuvent être hébergés moyennant des frais mensuels supplémentaires."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Protocole HTTP/2"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'ensemble du trafic SSL sur les sites hébergés par HubSpot applique le protocole HTTP/2. Le protocole ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://http2.github.io/",
        children: "HTTP/2"
      }), " remplace la façon dont le HTTP est exprimé en cours de transmission. Il ne s'agit pas d'une réécriture fondamentale du protocole. Les méthodes HTTP, les codes de statut et la sémantique sont les mêmes. Il devrait être possible d'utiliser les mêmes API que HTTP/1.x (éventuellement avec quelques petits ajouts) pour représenter le protocole."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ce protocole est centré sur la performance, notamment sur le temps de latence perçu par l'utilisateur final, sur le réseau et sur l'utilisation des ressources du serveur. Un objectif majeur est de permettre l'utilisation d'une connexion unique depuis les navigateurs vers un site web."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Protocole IPv6"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tous les sites hébergés par HubSpot incluent des adresses IPv6 afin de pouvoir y accéder de façon native sur le protocole IPv6. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.google.com/intl/en/ipv6/index.html",
        children: "IPv6"
      }), " est la version la plus récente du protocole Internet et étend le nombre d'adresses disponibles à un nombre pratiquement illimité de 340 billions de billions de billions d'adresses. Internet est à court d'adresses IPv4. La transition vers IPv6 permet de poursuivre la croissance d'Internet et de développer de nouveaux services innovants, car davantage d'appareils peuvent se connecter à Internet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "JavaScript et minification CSS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous rédigez des pages JavaScript et CSS pour HubSpot, vous pouvez vous attendre au comportement de minification suivant :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpot minifie automatiquement le code JavaScript et CSS inclus dans le gestionnaire de conception afin de supprimer les espaces inutiles, les sauts de ligne et les commentaires. Cela s'applique également aux fichiers JavaScript et CSS ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "chargés dans le gestionnaire de conception via l'interface d'ILC"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Puisque HubSpot minifie automatiquement le code JavaScript et CSS dans le gestionnaire de conception, vous ", (0, _jsxRuntime.jsx)("u", {
          children: "ne devez pas"
        }), " ajouter de code minifié directement dans le gestionnaire de conception."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour inclure du code minifié, vous devez charger le fichier dans le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/file-manager",
          children: "gestionnaire de fichiers"
        }), ", puis le joindre via le gestionnaire de conception. Pour lier localement un fichier minifié à un module, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration#adding-css-and-javascript-dependencies",
          children: "mettre à jour l'élément meta.json du module afin d'inclure l'élément css_assets ou js_assets"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpot ne minifie pas les fichiers JavaScript ou CSS chargés dans le gestionnaire de fichiers ou référencés via des liens externes. Vous devez vous assurer que ces fichiers sont minifiés avant le chargement."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["À chaque fois que vous mettez à jour un fichier JavaScript ou CSS dans le gestionnaire de conception ou via le chargement local, HubSpot le minifie à nouveau automatiquement. Cela peut entraîner un léger retard avant que la version ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".min.js"
        }), " de votre fichier ne soit utilisée sur les pages en direct. Pendant cette période, HubSpot utilisera la version non minifiée pour s'assurer que les visiteurs du site reçoivent toujours la dernière version de vos fichiers."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les erreurs de syntaxe peuvent empêcher HubSpot de minifier un fichier."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En plus de la minification, vous pouvez utiliser ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#including-files-in-files",
        children: "les inclusions HubL pour combiner plusieurs fichiers CSS dans un seul fichier"
      }), " et augmenter davantage les performances."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mise en cache du navigateur et du serveur"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot met automatiquement en cache les pages et les fichiers au niveau du serveur et du navigateur afin d'assurer le chargement rapide de l'ensemble des ressources pour les visiteurs du site web. Lorsqu'une page ou l'une de ses dépendances (comme un modèle ou un module) est modifiée, le cache expire automatiquement."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Réécriture de domaine"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Chaque domaine supplémentaire utilisé sur votre site web entraîne une recherche et une connexion DNS supplémentaires. Plus vous utilisez de domaines, plus vite votre site web se chargera. HTTP/2 prend en charge le chargement simultané de plusieurs fichiers sur la même connexion. Par conséquent, les anciennes directives pour le « découpage » de vos actifs entre plusieurs domaines ne s'appliquent plus."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les URL des éléments référencés dans les pages CMS tels que les fichiers système des développeurs, les CSS, JS et les images sont automatiquement réécrites pour correspondre au domaine de la page actuelle lorsque cela est possible. Donc, si vous référencez une image à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/53/HubSpot_Logos/HSLogo_gray.svg",
        children: "http://cdn2.hubspot.net/hubfs/53/HubSpot_Logos/HSLogo_gray.svg"
      }), " sur une page servie sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/",
        children: "www.hubspot.com"
      }), ", l'URL sera automatiquement mise à jour avec ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/hubfs/HubSpot_Logos/HSLogo_gray.svg",
        children: "https://www.hubspot.com/hubfs/HubSpot_Logos/HSLogo_gray.svg"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Compression de texte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fichiers de texte tels que HTML, CSS et JS sont tous compressés à l'aide de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/google/brotli",
        children: "Brotli"
      }), " avant d'être utilisés sur les navigateurs. La compression avec Brotli est encore plus importante qu'avec GZIP. Si le client n'indique pas que la compression Brotli est prise en charge, alors la compression GZIP sera appliquée."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alors que la minification accélère le temps d'analyse des fichiers CSS et JS dans le navigateur, la compression permet d'accéder plus rapidement à ces fichiers dans le navigateur."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Compression/optimisation et redimensionnement automatique d'image"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous chargez une image dans le gestionnaire de fichiers, les images sont automatiquement optimisées. Plus précisément, les fichiers JPEG et PNG sont dépouillés de leurs métadonnées (EXIF et autres éléments qui agrandissent les images). Toutes les images (sauf les fichiers GIF) sont également recompressées pour ne présenter aucune perte visuelle. De plus, nous pouvons utiliser des images dans un encodage différent si celui-ci peut être représenté comme un fichier PNG plus petit qu'un JPEG."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Les images sont réenregistrées à 72 dpi, quelle que soit leur résolution d'origine."
      }), " Si vous chargez un fichier à 300 dpi, initialement créé pour l'impression, il sera converti en 72 dpi, ce qui est la norme pour le web."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Nous améliorons progressivement les images pour utiliser le format WebP pour les navigateurs qui le prennent en charge."
      }), " Les images seront utilisées au format WebP si la taille de fichier de cette version de l'image est plus petite. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Cette conversion se produit côté serveur et ne modifie pas l'extension de fichier dans l'URL"
      }), ". Une image chargée en tant que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".jpg"
      }), " sera toujours affichée en tant que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".jpg"
      }), " dans l'URL, mais sera utilisée au format WebP. Cela garantit que les liens vers cette image fonctionneront pour tout le monde, indépendamment de la prise en charge du format WebP."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "images hébergées par HubSpot et placées sur le contenu CMS seront automatiquement redimensionnées par HubSpot"
      }), ", ce qui empêchera le navigateur de solliciter des ressources à cette fin. Pour ce faire, HubSpot ajoute des paramètres de requête de hauteur et/ou de largeur à l'URL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " de toutes les images qui ont un attribut de largeur ou de hauteur. Remarque : Le redimensionnement de l'image prend moins d'une minute. Si une page est demandée avant le redimensionnement de l'image, l'image non redimensionnée sera utilisée pour cette demande. Les navigateurs disposent de plusieurs options pour la résolution de l'image à charger, de sorte que vos images seront nettes sur les écrans standard et haute résolution."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'optimisation/la compression et le redimensionnement automatique d'image sont activés par défaut pour les pages et fichiers hébergés par HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/img_opt.png",
        alt: "Capture d'écran de l'élément img avec l'élément srcset automatiquement ajouté avec différentes URL de redimensionnement"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour les fichiers JPG individuels, si l'URL de l'image a le paramètre de requête ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "quality=high"
        }), ", l'image ne sera pas compressée."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les développeurs qui utilisent CMS Hub peuvent également fournir un contexte HubSpot supplémentaire aux images pour contrôler davantage le redimensionnement des images. Cela se fait via la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#resize-image-url",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "resize_image_url()"
        })
      }), ". Cela permet aux développeurs de créer des garde-fous dans des modules pour empêcher les créateurs de contenu d'afficher des images surdimensionnées sur les pages et les e-mails. La fonction peut également être utile dans les situations où la taille d'une image n'est pas dictée par des attributs de hauteur et de largeur dans le code HTML, comme une image d'arrière-plan."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pages mobiles accélérées (AMP)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'AMP ou Pages mobiles accélérées, est un format de page spécifique pour les mobiles qui charge le contenu instantanément. Le format AMP peut être activé instantanément sur vos articles de blog hébergés par HubSpot. Consultez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cos-general/how-to-use-accelerated-mobile-pages-amp-in-hubspot",
        children: "Utiliser les Pages mobiles accélérées (AMP) dans HubSpot"
      }), " pour plus de détails."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}