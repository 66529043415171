"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044561;
const slug = exports.slug = 'guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions';
const title = exports.title = 'Erste Schritte mit serverlosen Funktionen';
const name = exports.name = 'EMEA DACH (DE) Getting started with serverless functions';
const metaDescription = exports.metaDescription = 'Erstellen Sie Ihre erste serverlose Funktion im CMS Hub und rufen Sie eine Antwort von dieser.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-einen-projektordner-erstellen",
  "label": "1. Einen Projektordner erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-einen-functions-ordner-erstellen",
  "label": "2. Einen functions-Ordner erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-eine-konfigurationsdatei-erstellen",
  "label": "3. Eine Konfigurationsdatei erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-eine-funktionsdatei-erstellen",
  "label": "4. Eine Funktionsdatei erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-ihre-funktion-in-ihrer-serverless.json-registrieren",
  "label": "5. Ihre Funktion in Ihrer serverless.json registrieren",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-ihre-funktion-testen",
  "label": "6. Ihre Funktion testen",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-eine-einfach-vorlage-erstellen%2C-die-ihre-funktion-aufruft",
  "label": "7. Eine einfach Vorlage erstellen, die Ihre Funktion aufruft",
  "parent": null
}, {
  "depth": 0,
  "id": "8-eine-seite-mithilfe-der-test-function.html-vorlage-erstellen",
  "label": "8 Eine Seite mithilfe der test-function.html-Vorlage erstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "debuggen-ihrer-serverlosen-funktion",
  "label": "Debuggen Ihrer serverlosen Funktion",
  "parent": null
}, {
  "depth": 0,
  "id": "was-haben-sie-getan%3F",
  "label": "Was haben Sie getan?",
  "parent": null
}, {
  "depth": 0,
  "id": "die-n%C3%A4chsten-schritte%3F",
  "label": "Die nächsten Schritte?",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      br: "br",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Erste Schritte mit serverlosen Funktionen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Sie eine serverlose Funktion als Teil eines ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "Entwicklerprojekts"
        }), " erstellen, besuchen Sie stattdessen die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "Dokumentation der serverlosen Funktion für Entwicklerprojekte"
        }), ". Die folgende Dokumentation dient zum Erstellen serverloser Funktionen außerhalb der Entwicklerprojektplattform."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit serverlosen Funktionen können Sie serverseitigen Code schreiben, der über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "APIs"
      }), " mit HubSpot und Drittanbieterdiensten interagiert. APIs, für die eine Authentifizierung erforderlich ist, können aus Sicherheitsgründen nicht für das Front-End einer Website genutzt werden – Ihre Anmeldedaten wären zugänglich und die Website damit Angriffen ausgesetzt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Serverlose Funktionen können eine Mittlerfunktion zwischen Ihren Front-End- und Back-End-Diensten übernehmen, die eine Authentifizierung erfordern. Mit serverlosen Funktionen müssen Entwickler keine neuen Server aufsetzen und verwalten. Der Aufwand ist viel geringer und serverlose Funktionen lassen sich einfach anpassen und skalieren, wenn ein Unternehmen wächst. In diesem Artikel erhalten Sie einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "Überblick darüber, was serverlose Funktionen von HubSpot sind und wie sie funktionieren"
      }), ". Wir empfehlen, ihn vor der Durchführung dieses Tutorials zu lesen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Dieses Tutorial führt Sie durch die Erstellung Ihrer ersten serverlosen Funktion."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie erstellen einen Ordner für serverlose Funktionen, richten Ihren Konfigurationsordner ein, erstellen eine Funktion und erhalten eine Antwort von ihr."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Was Sie tun sollten, bevor Sie dieses Tutorial machen:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie haben Zugriff auf einen CMS Hub Enterprise-Account oder einen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/standalone-cms-developer",
          children: "CMS-Entwickler-Sandbox-Account"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stellen Sie sicher, dass Sie zumindest ein grundlegendes Verständnis des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "Konzepts einer serverlosen Funktion haben"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Machen Sie sich mit den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "Tools für lokale Entwicklung von HubSpot"
        }), " vertraut, da sie für die Arbeit mit serverlosen Funktionen erforderlich sind."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vergewissern Sie sich, dass Sie über die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#install-or-upgrade",
          children: "neueste Version der Tools für lokale Entwicklung von HubSpot"
        }), " verfügen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie sollten die Tools für lokale Entwicklung bereits", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#auth",
          children: "mit Ihrem HubSpot-Account authentifiziert haben"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Einen Projektordner erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Öffnen Sie Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), "-Datei und stellen Sie sicher, dass Ihr ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "defaultPortal"
        })
      }), " entweder auf Ihren ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/standalone-cms-developer",
        children: "CMS-Entwickler-Sandbox"
      }), "-Account oder auf einen Account bei CMS Hub Enterprise festgelegt ist."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Danach müssen Sie auf Ihrem Computer in dem Ordner, der Ihre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        })
      }), "-Datei enthält, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "einen"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "-Ordner erstellen"
      }), ". Dieser Ordner enthält alle unsere Dateien, sowohl die Funktionen selbst als auch eine Vorlage, die die Funktion verwendet."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Führen Sie in Ihrem Terminal den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "watch-Befehl"
      }), " aus:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch serverless-tutorial serverless-tutorial\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dies bewirkt, dass alle Änderungen an diesem Ordner zu Uploads an den Design-Manager führen. Da der Ordner derzeit keinen Inhalt hat, zeigt dieser Befehl einfach an, dass Watcher bereit ist und überwacht."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Einen functions-Ordner erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erstellen Sie im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), "-Ordner ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "einen"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "-Ordner."
      }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Dies ähnelt Ordnern für benutzerdefinierte Module, die in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".module"
      }), " enden. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), " dient zur Kommunikation, dass der Ordner serverlose Funktionen enthält. Die in diesem Ordner gespeicherten Dateien sind nicht öffentlich zugänglich."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da dieser Ordner derzeit leer ist, erstellt der von Ihnen ausgeführte", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        })
      }), "-Befehl noch nicht diesen Ordner im Design-Manager."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Eine Konfigurationsdatei erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Erstellen Sie eine neue Datei in Ihrem"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "-Ordner und benennen Sie sie"
      }), " ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "."]
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " ist eine erforderliche Datei innerhalb eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), "-Ordners. Sie dient als Konfigurationsdatei für serverlose Funktionen. Definieren der Laufzeitumgebung, der Version der serverlosen Funktionen und der verfügbaren Endpunkte. Eine Übersicht über alles, was in dieser Datei definiert wird, finden Sie in unserer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions",
        children: "Referenz für serverlose Funktionen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Wenn Sie die Datei leer erstellt und gespeichert haben, erhalten Sie eine Fehlermeldung in Ihrem Terminal, dass Sie keine leere ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "-Datei hochladen können. Sie können diese ignorieren, da Sie diesen Code noch hinzufügen und dann speichern – dies löst dann einen neuen Upload aus, der erfolgreich ist."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Fügen Sie den folgenden Code in Ihre serverless.json ein:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// place this in your serverless.json file, without this comment\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"endpoints\": {}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Speichern Sie die Datei."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lassen Sie diese Datei in Ihrem Code-Editor geöffnet, wir kommen darauf noch zurück."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        })
      }), " ausgeführt wird, sehen Sie nun, wenn Sie Ihren Design-Manager aktualisieren, Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-first-function.functions"
      }), "-Ordner sowie Ihre neue ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "-Datei."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Eine Funktionsdatei erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Erstellen Sie eine neue Datei in Ihrem"
      }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "my-first-function.functions**"
      }), "-Ordner und benennen Sie sie** ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "gratulation.js"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dies ist die eigentliche Funktionsdatei, die Datei, die eine Aufgabe ausführt und ausführt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Fügen Sie den folgenden Code ein:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n  // your code called when the function is executed\n  const functionResponse =\n    \"Congrats! You've just deployed a Serverless Function.\";\n\n  // sendResponse is a callback function you call to send your response.\n  sendResponse({ body: functionResponse, statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese serverlose Funktion gibt bei Ausführung Folgendes zurück: die Zeichenfolge ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Glückwunsch! Sie haben gerade eine serverlose Funktion bereitgestellt.\""
      }), " und den Status-Code ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), ", der auf Erfolg hinweist."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "In einem realen Szenario ist es wahrscheinlich, dass Sie APIs verwenden oder einige Berechnungen durchführen, die Sie nicht öffentlich sein sollen. Sie würden das Ergebnis dieser Berechnungen oder einen einfachen Statuscode basierend auf dem Erfolg Ihrer API-Transaktionen zurückgeben."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie sind noch nicht fertig, es gibt noch keine Möglichkeit, diese Funktion auszuführen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Ihre Funktion in Ihrer serverless.json registrieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Öffnen Sie Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "-Datei erneut. Suchen Sie in Ihrer Datei nach Ihrem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"endpoints\""
      }), "-Objekt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aktualisieren Sie das Objekt wie folgt:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// update the endpoints object in your serverless.json to reflect this object.\n\"endpoints\": {\n   \"congratulation\": {\n     \"method\": \"GET\",\n     \"file\": \"congratulation.js\"\n   }\n }\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das Endpunkt-Objekt enthält ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"congratulation\""
      }), "-Objekt. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"congratulation\""
      }), " ist der Endpunkt, den Sie erstellen. Der Name des Endpunkts definiert den Pfad, mit dem Sie Ihre serverlose Funktion aufrufen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Serverlose Funktionen werden über ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "einen Pfad in der Domain Ihres HubSpot CMS-Accounts bereitgestellt."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Diese Funktionen können unter folgender Adresse aufgerufen werden:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<https://www.example.com>/_hcms/api/<endpoint-name/path>"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Falle dieses von Ihnen erstellten \"congratulation\"-Endpunkts ist dies"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<https://www.example.com>/_hcms/api/congratulation"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aus diesem Grund ist es in der Regel ratsam, Ihrem Endpunkt einen ähnlich Namen wie den Ihrer Funktionsdatei zu geben, und beide sollten basierend auf den Informationen, anhand deren sie wirken, benannt werden, nicht basierend auf der Aktion, die anhand dieser Informationen durchgeführt wird. Sie sollten die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods",
        children: "HTTP-Methode"
      }), " oder die HTTP-Methoden verwenden, um den Typ der Aktion mitzuteilen, die Sie anhand dieser Informationen durchführen. Der \"method\"-Parameter definiert die von Ihrer Funktion unterstützte HTTP-Methode. Dies kann eine einzelne Zeichenfolge oder ein Array von Zeichenfolgen sein, die die Methoden bezeichnen, die die Funktion unterstützt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Ihre Funktion testen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der einfachste Weg, eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an Ihre serverlose Funktion zu testen, ist, direkt im Browser zur URL Ihres Endpunkts zu gehen.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://your-domain.com/_hcms/api/congratulation",
        children: "https://your-domain.com/_hcms/api/congratulation"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ersetzen Sie ihre-domain.com durch die Domain Ihrer HubSpot-Website."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie sollten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"Glückwunsch! Sie haben gerade eine serverlose Funktion bereitgestellt\""
      }), " sehen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sehr gut. Sie haben es geschafft."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.em, {
        children: "Wenn Sie diese Antwort nicht erhalten, gehen Sie noch einmal von oben in diesem Tutorial sorgfältig die einzelnen Schritte durch und überprüfen Sie den Code. Wahrscheinlich wurden die Anweisungen in Schritt 4 oder 5 nicht korrekt befolgt."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Für kompliziertere Anfragen ist es hilfreich, ein Tool wie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.postman.com/downloads/",
          children: "Postman"
        }), " zu verwenden. Postman erleichtert das Testen und Debuggen von APIs. Eine praktische Funktion für Front-End-Entwickler ist dessen ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://learning.postman.com/docs/postman/sending-api-requests/generate-code-snippets/",
          children: "Code-Generierung"
        }), ", die einen Startpunkt für Ihren JavaScript-Aufruf Ihrer Funktion generieren kann."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Eine einfach Vorlage erstellen, die Ihre Funktion aufruft"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Gehen Sie über ein neues Terminalfenster mithilfe von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cd"
      }), " in Ihren ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless-tutorial"
      }), "-Ordner."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Führen Sie den folgenden Befehl in Ihrem Terminal aus:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template \"test-function\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dadurch wird eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test-function.html"
      }), "-Datei erstellt. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Öffnen Sie diese Datei in Ihrem Code-Editor."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fügen Sie über dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</head>"
      }), "-Tag ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "<script></script>"
        }), " hinzu."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Kopieren Sie das folgende JavaScript:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var requestOptions = {\n  method: 'GET',\n  headers: {\n    'Content-Type': 'application/json',\n  },\n};\n\nfetch('https://www.example.com/_hcms/api/congratulation', requestOptions)\n  .then((response) => response.text())\n  .then((result) => console.log(result))\n  .catch((error) => console.log('error', error));\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Serverlose Funktionen von HubSpot unterstützen nur den Content-Typ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), ". Wenn Sie es auslassen, wird ein Fehler vom Typ „Nicht unterstützter Medientyp“ angezeigt."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fügen Sie innerhalb des script-Tags, das Sie zuvor erstellt haben, Ihren ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "kopierten JavaScript-Code"
      }), " ein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Ändern Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "www.example.com"
        }), " in die Domain Ihres Accounts."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Speichern Sie die Datei."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "8 Eine Seite mithilfe der test-function.html-Vorlage erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Design-Manager finden Sie Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test-function.html"
      }), "-Datei (Sie müssen möglicherweise aktualisieren)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Klicken Sie mit der rechten Maustaste auf die Datei und wählen Sie „Seite erstellen“ aus"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nennen Sie Ihre Seite „Testfunktion“. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Seite erstellen"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Klicken Sie auf „Vorschau“. Die Vorschau wird in einem neuem Fenster angezeigt"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Überprüfen Sie die Seite, indem Sie mit der ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "rechten Maustaste"
      }), " auf eine beliebige Stelle auf der Seite klicken und ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "„Untersuchen“ auswählen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie alles richtig gemacht haben, sollten Sie in Ihrer Konsole die Glückwunschnachricht sehen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Herzlichen Glückwunsch, Sie haben Ihre serverlose Funktion von einer CMS Hub-Seite aus aufgerufen."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Bei diesem Tutorial rufen Sie die serverlose Funktion auf der Vorlagenebene auf. Serverlose Funktionen können jedoch überall aufgerufen werden, wo Sie JavaScript auf Ihrer von CMS gehosteten Website hinzufügen können. Der häufigste Ort, an dem Sie dies tun können, ist in Ihren benutzerdefinierten Modulen."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Debuggen Ihrer serverlosen Funktion"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In dieser Phase sollte Ihre serverlose Funktion einwandfrei funktionieren. Je komplizierter Ihre Funktion wird, desto schwieriger ist es, Fehler zu beheben. So wie die Konsole in Ihrem Browser beim Debuggen von JavaScript auf Ihrem Frontend hilft, können Sie ähnliche Protokolle für Ihre serverlosen Funktionen mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs logs"
        })
      }), " erhalten. Weitere Informationen zu diesem Befehl finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: "Referenz für lokale Entwicklung"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs logs <endpoint-name> --follow\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Was haben Sie getan?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie haben einen Ordner für serverlose Funktionen mit einer serverless.json-Konfigurationsdatei und einer Funktionsdatei mit dem Namen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "congratulation.js"
      }), " erstellt. Sie haben „GET“ verwendet, um einen Glückwunschtext von der serverlosen Funktion abzurufen. Sie haben JavaScript verwendet, um Ihre serverlose Funktion von einer Seite im CMS Hub aus aufzurufen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können jetzt nachvollziehen, wie die Konfigurationsdatei, die Funktionsdatei und der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), "-Ordner zusammenhängen. Das CLI verfügt über einen praktischen Befehl, den Sie verwenden können, um Ihre Funktionen beim nächsten Mal schneller zu erstellen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create function <function name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Funktion erstellt einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), "-Ordner eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "-Datei und eine Funktionsdatei mit den von Ihnen angegebenen Namen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die nächsten Schritte?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "Allgemeine Übersicht über serverlose Funktionen"
        }), " im CMS Hub."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/serverless-functions",
          children: "Referenzleitfaden für serverlose Funktionen"
        }), ", in dem die Teile einer serverlosen Funktion aufschlüsselt werden"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/courses/introduction-to-hubspot-apis",
          children: "Academy-Kurs „Einführung in die HubSpot-APIs“"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/github-integration",
          children: "Kontinuierliche Integration mit GitHub"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}