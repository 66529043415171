"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358688;
const slug = exports.slug = 'guides/api/marketing/forms';
const title = exports.title = 'API de Marketing | Formulários ';
const name = exports.name = 'vNext DP de documentos - Formulários';
const metaDescription = exports.metaDescription = 'Esses pontos de extremidade são usados para criar e gerenciar formulários em sua conta da HubSpot.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Formulários"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use um formulário da HubSpot para coletar informações de leads sobre seus visitantes e contatos. Você pode usar os pontos de extremidade descritos aqui para configurar novos formulários ou obter detalhes dos formulários que você criou anteriormente. Se quiser enviar dados de envio de formulário, use a opção ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/marketing/forms/v3-legacy#submit-data-to-a-form-supporting-authentication",
        children: "Enviar dados para um ponto de extremidade de formulário"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O tipo do formulário indica sua finalidade e é definido como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot"
      }), " por padrão. Você pode utilizar os seguintes valores para seu ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "formType"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot"
        }), ": esses formulários oferecem uma variedade de tipos de campo e opções de estilo e podem ser incorporados em páginas do HubSpot ou em páginas externas. Esses formulários podem ser criados e editados usando os pontos de extremidade descritos aqui. Você também pode criar esses formulários em sua conta da HubSpot. Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/create-forms",
          children: "criar formulários da HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "captured"
        }), ": esses formulários correspondem a formulários HTML em sites externos. Se a ferramenta de formulários coletados estiver habilitada e houver envios de formulário em ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code",
          children: "uma página rastreada"
        }), ", o formulário será criado automaticamente no HubSpot. Saiba mais sobre o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/use-non-hubspot-forms",
          children: "uso de formulários coletados"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "flow"
        }), ": esses são formulários pop-up que podem ser usados em páginas do HubSpot ou em páginas externas. Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/create-pop-up-forms",
          children: "ferramenta de formulários pop-up da HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_comment"
        }), ": esses formulários são criados automaticamente para as páginas de blog do HubSpot para coletar comentários em posts de blog. Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/blog/set-up-and-moderate-your-blog-comments",
          children: "configurar e moderar comentários do blog"
        }), "."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}