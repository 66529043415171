'use es6';

import PropTypes from 'prop-types';
export const AuthTypes = {
  DEVELOPER_API_KEY: 'DEVELOPER_API_KEY',
  OAUTH_TOKEN: 'OAUTH_TOKEN',
  PRIVATE_APPS: 'PRIVATE_APPS',
  NONE: 'NONE'
};
export const AuthTypesWithVersions = {
  DEVELOPER_API_KEY: 'DEVELOPER_API_KEY',
  GRANULAR_OAUTH_TOKEN: 'GRANULAR_OAUTH_TOKEN',
  LEGACY_OAUTH_TOKEN: 'LEGACY_OAUTH_TOKEN',
  LEGACY_PRIVATE_APPS: 'LEGACY_PRIVATE_APPS'
};
export const DocsToSwaggerAuthTypes = {
  OAUTH_TOKEN: 'oauth2',
  LEGACY_OAUTH_TOKEN: 'oauth2_legacy',
  GRANULAR_OAUTH_TOKEN: 'oauth2',
  DEVELOPER_API_KEY: 'developer_hapikey',
  PRIVATE_APPS: 'private_apps',
  LEGACY_PRIVATE_APPS: 'private_apps_legacy'
};
export const SwaggerToDocsAuthTypes = Object.keys(DocsToSwaggerAuthTypes).reduce((acc, key) => {
  acc[DocsToSwaggerAuthTypes[key]] = key;
  return acc;
}, {});
export const TrackingAuthTypes = {
  DEVELOPER_API_KEY: 'developer_hapikey',
  GRANULAR_OAUTH_TOKEN: 'oauth',
  OAUTH_TOKEN: 'oauth',
  PRIVATE_APPS: 'private_apps',
  NO_AUTH: 'noauth'
};
export const AUTH_TYPE_KEYS = Object.keys(AuthTypes);
export const AuthTypePropType = PropTypes.oneOf(AUTH_TYPE_KEYS.map(key => AuthTypes[key]));
export const AUTH_TYPE_WITH_VERSIONS_KEYS = Object.keys(AuthTypesWithVersions).sort();
export const AuthTypeWithVersionsPropType = PropTypes.oneOf(AUTH_TYPE_WITH_VERSIONS_KEYS.map(key => AuthTypesWithVersions[key]));
export const DEVELOPER_API_KEY_DOCS_URL = 'https://developers.hubspot.com/docs/api/developer-tools-overview#developer-api-keys';
export const OAUTH_DOCS_URL = 'https://developers.hubspot.com/docs/methods/oauth2/oauth2-overview';
export const PRIVATE_APPS_URL = 'https://developers.hubspot.com/docs/api/private-apps';