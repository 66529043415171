"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831425;
const slug = exports.slug = 'guides/cms/content/accessibility';
const title = exports.title = 'Acessibilidade';
const name = exports.name = 'LATAM BR (PT) Accessibility';
const metaDescription = exports.metaDescription = 'A HubSpot está comprometida em garantir o fornecimento das ferramentas necessárias para ajudá-lo a tornar seu conteúdo acessível. Embora não seja um guia definitivo, veja algumas etapas que você pode seguir para tornar seu conteúdo acessível.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "acessibilidade-n%C3%A3o-%C3%A9-um-recurso",
  "label": "Acessibilidade não é um recurso",
  "parent": null
}, {
  "depth": 0,
  "id": "forne%C3%A7a-alternativas-de-texto-para-qualquer-conte%C3%BAdo-que-n%C3%A3o-seja-de-texto",
  "label": "Forneça alternativas de texto para qualquer conteúdo que não seja de texto",
  "parent": null
}, {
  "depth": 1,
  "id": "use-solu%C3%A7%C3%B5es-a11y-amig%C3%A1veis-de-carregamento-lento",
  "label": "Use soluções a11y amigáveis de carregamento lento",
  "parent": "forne%C3%A7a-alternativas-de-texto-para-qualquer-conte%C3%BAdo-que-n%C3%A3o-seja-de-texto"
}, {
  "depth": 0,
  "id": "verifique-se-todos-os-links-e-entradas-de-formul%C3%A1rio-incluem-texto-descritivo",
  "label": "Verifique se todos os links e entradas de formulário incluem texto descritivo",
  "parent": null
}, {
  "depth": 0,
  "id": "adicionar-um-link-para-pular-para-o-conte%C3%BAdo",
  "label": "Adicionar um link para pular para o conteúdo",
  "parent": null
}, {
  "depth": 0,
  "id": "usar-marca%C3%A7%C3%A3o-sem%C3%A2ntica",
  "label": "Usar marcação semântica",
  "parent": null
}, {
  "depth": 0,
  "id": "navega%C3%A7%C3%A3o-por-teclado",
  "label": "Navegação por teclado",
  "parent": null
}, {
  "depth": 0,
  "id": "fa%C3%A7a-fallback-para-fun%C3%A7%C3%B5es%2C-se-necess%C3%A1rio",
  "label": "Faça fallback para funções, se necessário",
  "parent": null
}, {
  "depth": 0,
  "id": "mais-dicas-de-design",
  "label": "Mais dicas de design",
  "parent": null
}, {
  "depth": 0,
  "id": "grava%C3%A7%C3%A3o-de-conte%C3%BAdo",
  "label": "Gravação de conteúdo",
  "parent": null
}, {
  "depth": 0,
  "id": "recomenda%C3%A7%C3%B5es-espec%C3%ADficas-da-hubspot",
  "label": "Recomendações específicas da HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "use-m%C3%B3dulos-e-modelos-personalizados-que-sigam-as-melhores-pr%C3%A1ticas-de-a11y",
  "label": "Use módulos e modelos personalizados que sigam as melhores práticas de a11y",
  "parent": "recomenda%C3%A7%C3%B5es-espec%C3%ADficas-da-hubspot"
}, {
  "depth": 0,
  "id": "cuidado-com-solu%C3%A7%C3%B5es-que-se-dizem-completas",
  "label": "Cuidado com soluções que se dizem completas",
  "parent": null
}, {
  "depth": 0,
  "id": "podcast-para-desenvolvedores-da-hubspot",
  "label": "Podcast para desenvolvedores da HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "mais-informa%C3%A7%C3%B5es-sobre-acessibilidade",
  "label": "Mais informações sobre acessibilidade",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      code: "code",
      pre: "pre",
      h3: "h3",
      ul: "ul",
      li: "li",
      ol: "ol",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      IFrame
    } = _components;
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Acessibilidade"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À medida que os sites continuam a se tornar cada vez mais essenciais para as empresas, garantir que seu conteúdo seja acessível a todos os visitantes se tornou ainda mais importante. Nos Estados Unidos, isso é frequentemente chamado de conformidade com a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://www.section508.gov/",
        children: "Seção 508"
      }), ", que se refere à seção da Reauthorized Rehabilitation Act de 1998, que exige que as agências federais tornem a tecnologia eletrônica e de informação acessível às pessoas portadoras de deficiência. Embora a conformidade com a Seção 508 seja um bom ponto de partida, cada vez mais as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility/Understanding_WCAG",
        children: "Diretrizes de Acessibilidade para Conteúdo Web (WCAG)"
      }), " são usadas como padrão para criar conteúdo acessível na web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Acessibilidade não é um recurso"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você não deve se basear apenas no aspecto legal para fornecer uma boa experiência para os usuários. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.cdc.gov/ncbddd/disabilityandhealth/infographic-disability-impacts-all.html",
        children: "Um em cada quatro americanos é portador de alguma deficiência de acordo com o CDC"
      }), ". Optar por não fornecer uma boa experiência para 25% dos visitantes é como ter uma loja física que nega a entrada de um a cada quatro visitantes. Não só esses clientes ficarão insatisfeitos, como provavelmente não retornarão nem recomendarão os seus serviços."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Uma afirmação comum entre os desenvolvedores é que \"a acessibilidade não é um recurso\". A ideia é não tratar a acessibilidade como um extra, ou algo para lidar no final de um projeto."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A acessibilidade requer reflexão, empatia e compreensão. Quando abordada no início de um projeto, é possível projetar e desenvolver soluções em vez de precisar reformular algo mais tarde."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Muitas vezes, resolver a acessibilidade resolve outras prioridades como desenvolvedor"
      }), ": experiência do usuário, desempenho e SEO são algumas das principais preocupações que estão diretamente relacionadas com a acessibilidade. Ao melhorar uma, muitas vezes, você também melhora outra."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A empatia é uma parte importante da acessibilidade e é muito fácil ignorá-la. Como desenvolvedores, queremos automatizar tudo: se algo é difícil ou demorado de fazer, queremos ferramentas que façam a tarefa por nós ou que nos digam como fazê-la. Infelizmente, essas ferramentas são superficiais, não substituem o trabalho humano, não podem realmente experimentar a página da mesma forma que um ser humano. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "É tecnicamente possível criar uma página web que seja aprovada com sucesso na maioria dos testes de acessibilidade automatizados, mas que seja completamente inútil para todos os seres humanos, sejam eles portadores ou não de deficiência visual, daltonismo ou função motora limitada."
      }), " Tecnicamente, é possível atender aos requisitos, mas fornecer uma experiência frustrante e inútil, que afaste completamente as pessoas. A conclusão é esta: não confie nas ferramentas, teste o seu trabalho, tenha empatia com os usuários, obtenha feedback."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Embora não seja um guia definitivo, veja algumas etapas que você pode seguir para tornar seu conteúdo acessível, bem como recursos para se aprofundar ainda mais."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Forneça alternativas de texto para qualquer conteúdo que não seja de texto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todas as imagens, ícones, vídeos e botões que transmitem significado ou são interativos devem ter uma alternativa de texto. Não só é positivo para os visitantes que estão consumindo seu conteúdo através de um leitor de tela, como também é ótimo para os mecanismos de busca. O texto alternativo deve descrever o conteúdo da imagem. A maioria das imagens em seu site provavelmente pode ser editada no editor de páginas e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/cos-pages-editor/how-do-i-add-alt-text-to-my-image",
        children: "é fácil configurar o texto alternativo no editor de páginas"
      }), ". No entanto, dentro de módulos e modelos personalizados, você precisa garantir que o texto alternativo especificado nos editores de página seja honrado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Isso significa garantir que você tenha um elemento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<img>"
      }), " em que o texto alternativo fornecido pelo usuário é realmente usado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good:\n<img src=\"{{ module.image.src }}\" alt=\"{{ module.image.alt }}\" />\n\nBad:\n<img src=\"{{ module.image.src }}\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se por algum motivo você não tornou uma imagem editável no editor de página, deverá codificar o texto alternativo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Há uma exceção a essa regra de texto alternativo. Se sua imagem não fornece contexto adicional e é puramente representativa, é melhor ter um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.w3.org/WAI/tutorials/images/tips/",
        children: "valor de texto alternativo \"nulo\""
      }), " do que não ter o atributo alternativo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good if only presentational: <img src=\"{{ module.image.src }}\" alt=\"\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para vídeos, use um serviço que ofereça suporte a legendas e considere incluir uma transcrição. Alguns serviços que permite o upload de transcrições incluem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.vidyard.com/hc/en-us/articles/360009876234-Make-your-Vidyard-players-accessible",
        children: "Vidyard"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://support.google.com/youtube/answer/2734799",
        children: "YouTube"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://help.vimeo.com/hc/en-us/articles/224968828-Captions-and-subtitles",
        children: "Vimeo"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://wistia.com/support/player/captions",
        children: "Wistia"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Use soluções a11y amigáveis de carregamento lento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O carregamento lento de imagens é uma técnica comum de aprimoramento de desempenho para a construção de sites. O método costuma carregar as coisas lentamente para fins de acessibilidade."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As soluções JavaScript para isso normalmente exigem que o atributo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " das imagens seja algo falso (como um pequeno arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".gif"
      }), ") em vez da verdadeira origem da imagem, que é mantida em um atributo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "data-src"
      }), " até que o usuário role a imagem para perto da visualização. Isso significa que, inicialmente, a imagem fica inacessível para um usuário de leitor de tela que está navegando na página, especialmente um que usa um rotor para ver todo o conteúdo da página sem sequer rolar. Além disso, se o JavaScript não for carregado em uma página, essas soluções de carregamento lento falharão e, portanto, deixarão os usuários de tecnologia assistiva sem as imagens adequadas na página."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usar o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/native-lazy-loading/",
        children: "carregamento lento de imagem nativo"
      }), " evita esse problema, mantendo a mesma marcação da imagem, independentemente de o JavaScript ser carregado ou não."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "Good:\n<img\n  src=\"{{ module.image.src }}\"\n  alt=\"{{ module.image.alt }}\"\n  loading=\"lazy\"\n  height=\"200px\"\n  width=\"400px\"\n/>\n\nBad:\n<img\n  src=\"1px.gif\"\n  data-src=\"{{ module.image.src }}\"\n  alt=\"{{ module.image.alt }}\"\n/>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubSpot oferece suporte ao ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/lazy-loading",
        children: "carregamento lento baseado em navegador"
      }), " nos módulos personalizados. É recomendado usá-lo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verifique se todos os links e entradas de formulário incluem texto descritivo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Links, botões e elementos de formulário precisam ter texto real que indique o que fazem ou para onde vão. Caso contrário, os leitores de tela dirão que há um link ou botão selecionado, mas não informarão o que ele faz."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao usar um ícone, forneça o texto do leitor de tela. Por exemplo, alguns modelos padrão da HubSpot usam um código curto de ícone:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a href=\"https://www.facebook.com\"\n  ><i class=\"fab fa-facebook-f\"></i\n  ><span class=\"screen-reader-text\">Facebook</span></a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Não use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'display: none'"
      }), " para ocultar rótulos de formulários e outros textos descritivos. Isso impede que o texto seja lido em voz alta para pessoas portadoras de deficiência visual, além de prejudicar os usuários que podem ver, mas têm dificuldade em identificar o texto devido à falta de contraste no texto do espaço reservado. Além disso, todo mundo já preencheu ou teve um formulário preenchido automaticamente sem fazer ideia se as informações foram inseridas corretamente porque os rótulos de campo do formulário estavam invisíveis."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você precisa ocultar o texto, ou se o texto oculto puder fornecer contexto adicional, que de outra forma não seria necessário para um usuário não portador de deficiência visual, use CSS para tornar o texto invisível sem ocultá-lo dos leitores de tela ou adicione a classe ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'.screen-reader-text'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".screen-reader-text {\n  clip: rect(1px, 1px, 1px, 1px);\n  height: 1px;\n  overflow: hidden;\n  position: absolute !important;\n  width: 1px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adicionar um link para pular para o conteúdo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando um visitante navega usando um leitor de tela ou simplesmente seu teclado, é super útil ter uma maneira de pular as partes da página que se repetem, como um cabeçalho. Podemos fazer isso adicionando um link que aponta para o conteúdo principal da página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No cabeçalho, adicione o HTML com o seguinte conteúdo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a href=\"#content\" class=\"screen-reader-text\"\n  >Five ways to make your site more accessible</a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Em cada modelo, certifique-se de que haja um elemento com um atributo de ID de conteúdo. No exemplo, usamos o título do artigo como texto ao qual pular. Assim, os mecanismos de busca sabem para onde o link leva. É uma maneira mais semântica de pular para o conteúdo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<main id=\"content\">\n  <!-- your page or post's actual content -->\n</main>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar marcação semântica"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Marcação semântica é um HTML que transmite significado. Alguns exemplos de elementos que são semânticos incluem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<header>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<main>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<footer>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<nav>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<time>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<code>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<aside>"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<article>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alguns exemplos de elementos que não são semânticos incluem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<span>"
      }), ". Os elementos não semânticos ainda têm função, mas devem ser usados principalmente para apresentação e não para transmitir significado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os elementos semânticos podem ser entendidos pelos mecanismos de busca e tecnologias assistivas, afetando positivamente o SEO e melhorando a experiência do usuário."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Não use elementos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), " como elementos interativos, como botões, guias ou links, a menos que tenha certeza de que forneceu uma boa experiência por meio de atributos aria."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use links (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<a />"
      }), ") e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<button />"
      }), " corretamente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use links para vincular a seções de uma página e navegar para outras páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use os botões para interações que não fazem o visitante sair da página ou navegar para um ID."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao trabalhar com tabelas, se uma tabela tiver um título descritivo, inclua-o em um elemento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<caption>"
      }), " logo após o elemento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<table>"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use elementos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<th>"
      }), " com o atributo de escopo apropriado em tabelas para cabeçalhos de colunas e linhas e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<thead>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<tbody>"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<tfoot>"
      }), " para denotar seções."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use o atributo de escopo em atributos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<th>"
      }), " para indicar se o cabeçalho é de linha ou coluna."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Precisa de um acordeão? Não se esqueça dos elementos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<summary>"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<details>"
      }), ", que fornecerão a maior parte desta funcionalidade gratuitamente e ainda são acessíveis. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://caniuse.com/#feat=details",
        children: "Certifique-se de adicionar um polyfill ou fallback se precisar oferecer suporte a navegadores antigos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Navegação por teclado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alguns usuários usam o teclado para navegar pelas páginas web e formulários, mas não por preferência. Alguns visitantes precisam usar o teclado ou algum tipo de dispositivo auxiliar que emule um teclado para navegar pelos sites."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Certifique-se de definir", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3schools.com/cssref/sel_focus.asp",
          children: "':focus'"
        }), " de modo que, quando um usuário navegar usando o teclado, ele possa ver o que está em foco na tela. Somente desative a propriedade CSS de contorno se fornecer um indicador visual alternativo aceitável. Use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/almanac/selectors/f/focus-within/",
          children: ":focus-within"
        }), " se ajudar a direcionar a atenção dos usuários de forma útil."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao ocultar/mostrar partes da página com ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3schools.com/cssref/sel_hover.asp",
          children: "':hover'"
        }), " ou via Javascript, certifique-se de que os usuários também sejam capazes de navegar por esses controles via Javascript ou que exista um caminho alternativo até a informação."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Verifique se a navegação principal do seu site oferece suporte à navegação por teclado: um problema comum é deixar menus suspensos inacessíveis. Assim, os usuários não poderão acessar partes críticas do seu site."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Forneça e atualize os atributos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hidden"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-expanded"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-current"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aria-selected"
        }), ", entre outros atributos de estado, conforme necessário, para garantir que os leitores de tela comuniquem adequadamente o estado dos elementos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Faça fallback para funções, se necessário"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao criar modelos ou arquivos codificados, você deve usar os elementos semânticos corretos (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<header>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<main>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<footer>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<nav>"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<dialog>"
      }), " etc.) para comunicar aos navegadores web e leitores de tela o tipo de conteúdo que incluem."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se elementos semânticos improváveis não são apropriados para o seu cenário, você deve adicionar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles",
        children: "atributos de função"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A adição de pontos de referência facilita para os usuários que utilizam leitores de tela pularem entre as seções principais de uma página web. O HubSpot inclui atributos 'role' para menu, menu simples e módulos de pesquisa do Google."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mais dicas de design"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Verifique se o conteúdo permanece visível e legível quando um usuário amplia o navegador para 200%."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Não é aconselhável usar fontes muito menores do que 16 px, pois pode dificultar a leitura pelos visitantes."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Evite usar cor como a única maneira de comunicar informações. Um percentual significativo da população mundial é daltônico."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Verifique se há contraste suficiente entre a cor do texto e o plano de fundo para que usuários com visão limitada possam ler o conteúdo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Evite animações que pisquem rapidamente (mais de três vezes por segundo), pois podem desencadear convulsões em alguns visitantes."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Considere oferecer suporte a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://css-tricks.com/introduction-reduced-motion-media-query/",
          children: "prefers-reduced-motion"
        }), " se você tiver animações em seu site."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Hoje, construir um site responsivo é considerado uma obrigação. Confira se todos os elementos interativos funcionam como esperado em dispositivos móveis. Os elementos interativos devem ser fáceis de tocar e deve haver espaço suficiente entre as regiões de toque."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gravação de conteúdo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "É fácil de esquecer que a acessibilidade também deve ser considerada em seu conteúdo de texto."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Evite a linguagem direcional, como \"veja a barra lateral à esquerda\". Se o usuário estiver no celular, é provável que isso não faça sentido para ele, pois o conteúdo geralmente é exibido empilhado."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use cabeçalhos como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<h1>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<h2>"
        }), " etc. e aninhe-os consecutivamente. Não pule um cabeçalho para obter efeitos visuais."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ao adicionar um link, nunca use texto como \"Clique aqui\", pois não transmite significado para os leitores de tela, além de não fazer nenhum sentido em telas sensíveis ao toque ou em outros tipos de dispositivos. Em vez disso, o texto deve indicar o que consta no link. Isso também é positivo para o SEO, pois os mecanismos de busca sabem para onde o link leva."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você usar um estilo especial no texto para transmitir significado, certifique-se de usar a tag semântica apropriada ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<mark>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<strong>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<em>"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<sup>"
        }), " etc., caso contrário, o significado poderá ser perdido para alguns visitantes."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se seu público é composto por visitantes de todo o mundo, evite fazer piadas regionais. Ao traduzir seu site, evite trocadilhos. Por mais que gostemos de uma piada de tiozão, por vezes elas não se traduzem bem."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use ferramentas para ajudar a melhorar sua gramática e ortografia. Isso ajuda na compreensão e resulta em traduções melhores."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Escreva o conteúdo de uma forma que se adapte ao público do seu site. Você não explicaria astrofísica para um aluno do quinto ano da mesma forma que faria a um físico talentoso. Dito isto, evite preciosismos, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.nngroup.com/articles/plain-language-experts/",
          children: "quanto mais claro melhor"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recomendações específicas da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A maior parte do trabalho para melhorar a acessibilidade não é específica da HubSpot. Dito isso, queremos prepará-lo para o sucesso no HubSpot e há algumas coisas que você pode fazer como desenvolvedor ou criador de conteúdo para fornecer uma experiência melhor para os usuários."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Use módulos e modelos personalizados que sigam as melhores práticas de a11y"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você está instalando um módulo ou modelo obtido no marketplace, desenvolvendo-os ou construindo-os para você, é melhor garantir que eles sigam as práticas recomendadas. Os módulos personalizados podem ter o maior impacto na acessibilidade do seu site, pois são frequentemente usados várias vezes, até em uma mesma página. Da mesma forma, um único modelo pode ser usado centenas ou milhares de vezes em um mesmo site. Se seu site não é acessível, provavelmente você está perdendo dinheiro. Da mesma forma que acontece com a publicidade, faz sentido pagar um pouco mais para garantir que seu site atinja um público mais amplo. Um dos benefícios dos módulos é que muitas vezes você pode melhorar o código posteriormente, à medida que as diretrizes e as práticas recomendadas evoluem, melhorando a acessibilidade em todas as páginas em que o módulo é usado de uma só vez."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No marketplace da HubSpot, procure módulos e modelos que falem sobre acessibilidade e atendam aos requisitos da WCAG em suas descrições. Se você estiver trabalhando com um desenvolvedor, deixe claro desde o início que a acessibilidade é importante para você. Nem todos os desenvolvedores criam com a11y em mente desde o início. Abordar a acessibilidade no final de um projeto provavelmente vai custar mais do que incluí-la no início do processo, pois em vez de priorizá-la desde o início, os desenvolvedores precisarão corrigir retroativamente os problemas de acessibilidade."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você for um desenvolvedor, crie módulos e modelos que facilitem o cumprimento de todas as diretrizes para os criadores de conteúdo. Use cabeçalhos apropriados, HTML semântico e faça com que os elementos interativos forneçam funções e contexto adequados. Os criadores de conteúdo que estão cientes dos problemas de acessibilidade geralmente ficam felizes em pagar um pouco mais por módulos e modelos que sejam inclusivos, ou seja, você precisa mostrar que seus módulos levam em consideração a acessibilidade. Inclua links para pular em modelos e adicione acessibilidade aos grupos globais e parciais globais usados em seus modelos — eles são usados no site todo e podem ter o maior impacto na usabilidade de um site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você cria para o marketplace da HubSpot, saiba que seu conteúdo pode ser usado em milhares, até milhões, de páginas na web. A sua escolha afeta as pessoas ao publicar seu conteúdo e compartilhar seu trabalho, independentemente de ser inclusivo ou não."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cuidado com soluções que se dizem completas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Existem dezenas de ferramentas que afirmam coisas como ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "\"Basta adicionar nosso script ao seu site para torná-lo acessível\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Algumas dessas ferramentas tentarão dar sentido a partes da página e alterar o html e os atributos para tentar corrigir alguns problemas. Elas fazem um trabalho de adivinhação e podem errar, acabar com a funcionalidade do seu site ou piorar as coisas para os usuários."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Essas ferramentas nem sempre funcionam como esperado e às vezes fornecem seu próprio sistema de leitura de tela. Com uma interface do usuário complicada, seu site poderá se tornar mais difícil de operar por meio da ferramenta injetada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Devido a esses problemas, ferramentas como essas não substituem a construção do seu site com acessibilidade em mente. Invista tempo e dinheiro para fornecer a melhor experiência para todos os usuários. Confiar em soluções completas pode custar o mesmo ou mais do que simplesmente construir as coisas de maneira correta. Ao testar e construir considerando a acessibilidade, você também terá o benefício da empatia e compreensão dos seus clientes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para deixar claro, isso não se refere a ferramentas de teste, especificamente aquelas que afirmam resolver problemas de acessibilidade para você. As ferramentas de teste são ótimas e você deve usá-las, além de realizar testes manuais."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://overlayfactsheet.com/",
        children: "Saiba mais sobre scripts/sobreposições de acessibilidade."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Podcast para desenvolvedores da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Em janeiro de 2021, os HubSpotters, juntamente com Jon Sasala, da Morey Creative, tiveram uma conversa sobre acessibilidade."
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/xOVTQN8phIs?feature=oembed",
      maxHeight: "113px",
      maxWidth: "200px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mais informações sobre acessibilidade"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há excelentes recursos disponíveis para a construção de sites acessíveis. Não deixe de conferir!"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://a11yproject.com/",
          children: "O Projeto A11Y"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/Accessibility",
          children: "Documentos sobre acessibilidade da MDN"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3.org/WAI/",
          children: "Web Accessibility Initiative"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/",
          children: "WebAIM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/standards/wcag/checklist",
          children: "Lista de verificação simplificada da WCAG 2"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://webaim.org/standards/508/checklist",
          children: "Lista de verificação da Seção 508"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.w3.org/TR/WCAG22/",
          children: "Recomendações detalhadas da WCAG 2.2"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.deque.com/axe/",
          children: "AXE por Deque"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://wave.webaim.org/",
          children: "WAVE"
        }), " - uma ferramenta para testar a acessibilidade de uma página web"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/website/web-accessibility",
          children: "O guia definitivo sobre acessibilidade na web"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}