"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358768;
const slug = exports.slug = 'guides/api/cms/hubdb';
const title = exports.title = 'API del CMS | HubDB';
const name = exports.name = 'API del CMS | HubDB';
const metaDescription = exports.metaDescription = 'Los puntos de terminación de HubDB se utilizan para obtener y administrar datos en tus tablas de datos de HubDB.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "l%C3%ADmites-de-tasa",
  "label": "Límites de tasa",
  "parent": null
}, {
  "depth": 0,
  "id": "tablas-en-borrador-vs-tablas-en-vivo",
  "label": "Tablas en borrador vs tablas en vivo",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-una-tabla-de-hubdb",
  "label": "Crear una tabla de HubDB",
  "parent": null
}, {
  "depth": 1,
  "id": "agregar-columnas-a-la-tabla",
  "label": "Agregar columnas a la tabla",
  "parent": "crear-una-tabla-de-hubdb"
}, {
  "depth": 1,
  "id": "agregar-filas-a-la-tabla",
  "label": "Agregar filas a la tabla",
  "parent": "crear-una-tabla-de-hubdb"
}, {
  "depth": 1,
  "id": "importar-filas-desde-csv",
  "label": "Importar filas desde CSV",
  "parent": "crear-una-tabla-de-hubdb"
}, {
  "depth": 1,
  "id": "formato-de-fecha",
  "label": "Formato de fecha",
  "parent": "crear-una-tabla-de-hubdb"
}, {
  "depth": 1,
  "id": "opciones-de-restablecimiento",
  "label": "Opciones de restablecimiento",
  "parent": "crear-una-tabla-de-hubdb"
}, {
  "depth": 0,
  "id": "recuperar-datos-de-hubdb",
  "label": "Recuperar datos de HubDB",
  "parent": null
}, {
  "depth": 1,
  "id": "filtrar-filas-obtenidas",
  "label": "Filtrar filas obtenidas",
  "parent": "recuperar-datos-de-hubdb"
}, {
  "depth": 1,
  "id": "ordenar-filas-obtenidas",
  "label": "Ordenar filas obtenidas",
  "parent": "recuperar-datos-de-hubdb"
}, {
  "depth": 0,
  "id": "configurando-tablas-hubdb-para-p%C3%A1ginas-din%C3%A1micas",
  "label": "Configurando tablas HubDB para páginas dinámicas",
  "parent": null
}, {
  "depth": 0,
  "id": "cambios-en-la-v3",
  "label": "Cambios en la v3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      strong: "strong",
      em: "em",
      h2: "h2",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API del CMS | HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubDB es un almacén de datos relacional que presenta datos como filas, columnas y celdas en una tabla, muy parecido a una hoja de cálculo. Las tablas de HubDB se pueden agregar o modificar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview#creating-your-first-table",
        children: "dentro de tu cuenta de HubSpot"
      }), ", pero también puedes usar los puntos de terminación de API documentados aquí. Para obtener información sobre el uso de datos de tablas de HubDB en tu sitio web o en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "correos electrónicos programables"
      }), ", echa un vistazo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "a la documentación para desarrolladores de CMS de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al igual que las páginas del sitio web de HubSpot, las tablas de HubDB son compatibles con las versiones ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "draft"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "published"
      }), ". Esto te permite actualizar los datos de la tabla, ya sea para realizar pruebas o para permitir un proceso de aprobación manual, sin que ello afecte a las páginas publicadas. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#draft-vs-live-tables",
        children: "tablas en borrador vs. tablas en vivo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si una tabla está configurada para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "permitir el acceso público"
      }), ", puedes acceder a la versión publicada de la tabla y las filas sin ninguna autenticación especificando el ID de tu cuenta de HubSpot a través del parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás migrando desde la versión 2 de la API de HubDB, obtén más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#changes-in-v3",
        children: "cambios en la API actual (v3)"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " los puntos de terminación que admiten ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " también admiten ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CORS"
        }), ", por lo que puedes acceder a los datos de una tabla del lado del cliente usando JavaScript y tu ID de cuenta. Otros métodos y el punto de terminación ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Obtener todas las tablas"
        }), " requieren autenticación y no son compatibles con ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CORS"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites de tasa"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las solicitudes de API de HubDB tienen diferentes límites de tasa, según el tipo de solicitud:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Las solicitudes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " realizadas que no requieren autentificación (incluidas las solicitudes de JavaScript del lado del cliente) están limitadas a 10 solicitudes por segundo. Estas solicitudes no contarán para el límite diario."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Todas las demás solicitudes ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "que utilizan la autenticación"
        }), " siguen los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api",
          children: "límites estándar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tablas en borrador vs tablas en vivo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las tablas de HubDB tienen versiones en borrador y en vivo, y las versiones en vivo se pueden publicar o no publicar. Esto te permitirá actualizar los datos de la tabla, ya sea para vistas preliminares o pruebas de páginas, o para permitir un proceso de aprobación manual, sin afectar ninguna página activa."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En esta API, se designan puntos de terminación separados para las versiones en borrador y publicadas de una tabla. Por ejemplo, puedes recuperar la versión publicada de una tabla haciendo una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " al siguiente punto de terminación:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Y para recuperar cualquier contenido que haya sido redactado pero que aún no haya sido publicado, agregarías ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/draft"
      }), " al final de la URL:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/draft"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los datos en borrador se pueden revisar y luego enviar en HubSpot, o con el punto de terminación ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/push-live"
      }), ". Los datos en borrador también se pueden descartar a través del punto de terminación ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/reset"
      }), ", lo que te permite volver a la versión actual en vivo de los datos sin interrupciones."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una tabla de HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear una tabla de HubDB, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el cuerpo de la solicitud, especifica los siguientes campos obligatorios:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ejemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre interno de la tabla. Este nombre no se puede cambiar una vez creada la tabla. Los nombres solo pueden incluir letras minúsculas, dígitos y guiones bajos y no pueden comenzar con un número."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"my_table\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: ": La etiqueta de la tabla que ven los usuarios al editar la tabla en HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"label\":\"My table\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además, puedes especificar los siguientes campos opcionales:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ejemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "useForPages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si la tabla se puede utilizar para ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#configuring-hubdb-tables-for-dynamic-pages",
              children: "la creación de páginas dinámicas"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"useForPages\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowPublicAPIAccess"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Si la tabla se puede leer sin autorización."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"allowPublicApiAccess\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowChildTables"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Si se pueden crear tablas secundarias para la tabla."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"allowChildTables\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableChildTablePages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
              children: "las páginas dinámicas multinivel"
            }), " deben crearse utilizando tablas secundarias."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"enableChildTablePages\": false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columns"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Las columnas de la tabla. Obtén más información sobre las propiedades de las columnas en las secciones ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-table-columns",
              children: "Agregar columnas de tabla"
            }), " ."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "See \"Add table columns\" section below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si aún no se han agregado columnas, tu solicitud de creación podría tener el siguiente aspecto:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"name\": \"test_table\",\n  \"label\": \"Test Table\",\n  \"useForPages\": false,\n  \"allowPublicApiAccess\": false,\n  \"allowChildTables\": true,\n  \"enableChildTablePages\": false,\n  \"columns\": []\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Agregar columnas a la tabla"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cada columna en una tabla de HubDB se puede definir con las siguientes propiedades:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ejemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obligatorio. El nombre interno de la columna. No se puede cambiar después de creada la columna."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"row_name\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Opcional. La etiqueta para la columna que verán los usuarios al editar la tabla en HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"label\": \"Row label\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de datos de la columna. Debe ser uno de los siguientes:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "TEXT"
                }), ": un campo de texto."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "RICHTEXT"
                }), ": un campo de texto que admite el formato HTML básico. No se recomienda para HTML sin procesar, ya que puede afectar si el HTML es editable en HubSpot. Editar el código en HubSpot también puede afectar la forma en que se renderiza el código. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "NUMBER"
                }), ": un campo numérico."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOOLEAN"
                }), ": representado como una casilla de verificación en HubSpot. Usar ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "0"
                }), " para desmarcado y ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                }), " para marcado."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DATE"
                }), ": almacena una fecha específica como una marca de tiempo de milisegundos establecida en la medianoche UTC."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "DATETIME"
                }), ": almacena una fecha y una hora como una marca de tiempo de milisegundos."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "SELECT"
                }), ": la columna solo se puede establecer en una de un conjunto de opciones. Consulta el campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), " a continuación para las propiedades requeridas."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MULTISELECT"
                }), ": la columna se puede establecer en una o más de un conjunto de opciones. Consulta el campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), " a continuación para las propiedades requeridas."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "LOCATION"
                }), ": almacena una ubicación de latitud y longitud."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "IMAGE"
                }), ": almacena la URL de una imagen."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "VIDEO"
                }), ": almacena el ID del reproductor del video."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FOREIGN_ID"
                }), ": la columna hará referencia a una columna de otra tabla de HubDB. Además, debes definir la otra tabla de HubDB con las siguientes propiedades:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: "foreignTableId: el ID de la otra tabla de HubDB. "
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: "foreignColumnId: el ID de la columna en la otra tabla de HubDB."
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CURRENCY"
                }), ": almacena el número como un valor de divisa."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "FILE"
                }), ": almacena un archivo del archivo mánager. También deberá incluir un campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "fileType"
                }), " para especificar si el campo puede almacenar todos los tipos de archivo (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FILE"
                }), ") o sólo tipos de documento como PDF (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DOCUMENT"
                }), ")."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"type\": \"type\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [": Una lista de opciones para las columnas de selección y selección múltiple. Cada opción se define con un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " junto con un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            }), " igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "option"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"option\": [{\"name\":\"Option 1\", \"type\":\"option\"}, {\"name\": \"Option 2\", \"type\": \"option\"}]"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando los campos anteriores, tu solicitud para crear una nueva tabla de HubDB podría verse de la siguiente manera:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"label\": \"Test Table\",\n  \"name\": \"test_table\",\n  \"columns\": [\n    {\n      \"name\": \"text_column\",\n      \"label\": \"Text Column\",\n      \"archived\": false,\n      \"type\": \"TEXT\"\n    },\n    {\n      \"name\": \"number_column\",\n      \"label\": \"Number Column\",\n      \"archived\": false,\n      \"type\": \"NUMBER\"\n    },\n    {\n      \"name\": \"multiselect\",\n      \"label\": \"Multi Select Column\",\n      \"archived\": false,\n      \"type\": \"multiselect\",\n      \"options\": [\n        {\n          \"name\": \"Option 1\",\n          \"type\": \"option\"\n        },\n        {\n          \"name\": \"Option 2\",\n          \"type\": \"option\"\n        }\n      ]\n    }\n  ],\n  \"useForPages\": true,\n  \"allowChildTables\": true,\n  \"enableChildTablePages\": false,\n  \"allowPublicApiAccess\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de crear una tabla, a las columnas se les asignarán ID en orden ascendente. Al actualizar columnas existentes, incluye el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de la columna en el objeto de entrada."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Agregar filas a la tabla"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes agregar filas manualmente a través de la API, o puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#import-rows-from-csv",
        children: "importar filas desde un archivo CSV"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para agregar filas a una tabla de HubDB, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/rows"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para cada fila de la tabla, puedes incluir los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ejemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [": Una lista de pares clave-valor con el nombre de la columna y el valor que deseas agregarle.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "Si no deseas establecer un valor específico para una columna, puedes omitir el nombre de la columna de la lista de pares clave-valor."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"values\": { \"text_column\": \"sample text\", \"number_column\": 76}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [": En el caso de las tablas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "habilitadas para páginas dinámicas"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), " es el sufijo de la ruta utilizado para la página creada para esta fila."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"path\": \"example_url_path\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [": Para las tablas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "habilitadas para páginas dinámicas"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " es el título HTML utilizado para la página creada para esta fila."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"Example Title\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Al crear ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
              children: "páginas dinámicas de varios niveles"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            }), " especifica el ID de la tabla secundaria."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"childTableId\": 123456"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando los campos anteriores, el cuerpo de tu solicitud puede tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request\n{\n  \"values\": {\n    \"text_column\": \"sample text value\",\n    \"number_column\": 76,\n    \"rich_text_column\": \"<strong>This is a styled paragraph.</strong>\",\n    \"date_column\": 1591228800000,\n    \"date_time_column\": 1604450520000,\n    \"boolean_column\": 1,\n    \"select_column\": {\n      \"name\": \"option 1\",\n      \"type\": \"option\"\n    },\n    \"multiselect_column\": [\n      {\n        \"name\": \"Option 1\",\n        \"type\": \"option\"\n      },\n      {\n        \"name\": \"Option 2\",\n        \"type\": \"option\"\n      }\n    ],\n    \"url_column\": \"https://www.hubspot.com/marketing\",\n    \"video_column\": 3392210008,\n    \"image_column\": {\n      \"url\": \"https://f.hubspotusercontentqa00.net/hubfs/99992002/image3%20(1).jpg\",\n      \"width\": 1600,\n      \"height\": 900,\n      \"type\": \"image\"\n    },\n    \"foreign_id_column\": [\n      {\n        \"id\": \"4364402239\",\n        \"type\": \"foreignid\"\n      },\n      {\n        \"id\": \"4364402240\",\n        \"type\": \"foreignid\"\n      }\n    ]\n  },\n  \"path\": \"test_path\",\n  \"name\": \"test_title\",\n  \"childTableId\": \"1902373\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Importar filas desde CSV"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para importar datos a una tabla de HubDB desde un archivo CSV, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/cms/v3/hubdb/tables/{tableIdOrName}/draft/import"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El punto de terminación de importación acepta una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "config"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " un conjunto de opciones con formato JSON para la importación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "file"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " el archivo CSV que deseas importar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "config"
      }), ", incluye los siguientes campos como cadena JSON:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Ejemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "skipRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El número de filas de encabezados que se deben omitir. El valor predeterminado de este campo es ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), ", omitiendo la primera fila y tratándola como un título. Establécelo en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " si todas las filas son datos válidos."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"skipRows\": 0"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "separator"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El delimitador de columna en el archivo CSV. Establecer en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\",\""
            }), " de forma predeterminada."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"separator\": \",\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "idSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El índice de la columna del archivo de origen que contiene el ID de la fila (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), "). Si se especifica esta columna, la importación actualizará las filas existentes en la tabla para los ID de fila coincidentes del archivo CSV. Esto es opcional y puedes ignorarlo la primera vez que importes datos a una tabla. Consulte la sección ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#reset-options",
              children: "Opciones de restablecimiento"
            }), " a continuación para obtener información más detallada."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"idSourceColumn\": 1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "resetTable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El valor predeterminado es ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", lo que significa que las filas de la tabla se actualizarán sin eliminar ninguna fila existente. Si se establece en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", las filas de la hoja de cálculo sobrescribirán los datos de la tabla, lo que significa que se eliminará cualquier fila de la tabla que no esté en la hoja de cálculo. Consulte la sección ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#reset-options",
              children: "Opciones de restablecimiento"
            }), " a continuación para obtener información más detallada."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"resetTable\": true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nameSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para las tablas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "habilitadas para páginas dinámicas"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nameSourceColumn"
            }), " especifica la columna del archivo CSV que contiene los ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " de la fila. Los números de las columnas están en orden ascendente, siendo la primera columna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"nameSourcecolumn\": 5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pathSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para las tablas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "habilitadas para páginas dinámicas"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pathSourceColumn"
            }), " especifica la columna del archivo CSV que contiene los ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), " de la fila. Los números de las columnas están en orden ascendente, siendo la primera columna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"pathSourcecolumn\": 6"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableSourceColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Especifica la columna del archivo CSV que contiene los ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "childTableId"
            }), " de la fila. Los números de las columnas están en orden ascendente, siendo la primera columna ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"childTableSourcecolumn\": 3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una lista de asignaciones de las columnas del archivo de origen a las columnas de la tabla de HubDB de destino. Cada asignación debe tener el siguiente formato: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"source\":1,\"target”:\"columnIdOrName\"}"
            }), " ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "origen:"
                }), " el índice de columna en el archivo de origen. Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "2"
                }), " para la segunda columna."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "objetivo:"
                }), " el ID o nombre de la columna de la tabla de HubDB. Puedes obtener el ID o el nombre de una columna ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#retrieve-hubdb-data",
                  children: "obteniendo los detalles de la tabla"
                }), "."]
              })]
            }), "Si tu archivo tiene una columna", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), ", no deberías incluirla en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            }), ". En cambio, inclúyelo como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "idSourceColumn"
            }), " para actualizar las filas existentes."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"columnMappings\": [{\"source\":1, \"target\": 2}, {\"source\": 2, \"target\": \"column_name\"}]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryKeyColumn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre de una columna de la tabla de HubDB objetivo que se utilizará para la desduplicación. El ID de la columna no se puede utilizar para este campo."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"primaryKeyColumn\": \"column_name\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "encoding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de codificación del archivo. Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "utf-8"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ascii"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-8859-2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-8859-5"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "iso-2022-jp"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "windows-1252"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"encoding\": \"utf-8\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Solo se admiten archivos CSV."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"format\": \"csv\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando la tabla anterior, tu ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "config"
      }), " JSON podría verse de la siguiente manera:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example config JSON\n{\n  \"skipRows\": 1,\n  \"separator\": \",\",\n  \"idSourceColumn\": 1,\n  \"resetTable\": false,\n  \"columnMappings\": [\n    {\n      \"target\": 1,\n      \"source\": 2\n    },\n    {\n      \"target\": 2,\n      \"source\": \"zip_code\"\n    }\n  ],\n  \"primaryKeyColumn\": \"name\",\n  \"encoding\": \"utf-8\",\n  \"format\": \"csv\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si utilizas cURL, el comando podría tener el siguiente aspecto:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl -L -X POST 'https://api.hubspotqa.com/hubdb/api/v2/tables/xxxxxx/import?portalId=xxxxxxx' \\\n-H 'Content-Type: multipart/form-data' \\\n-F 'config=\"{\\\"skipRows\\\":1,\\\"format\\\":\\\"csv\\\",\\\"separator\\\":\\\",\\\",\\\"encoding\\\":\\\"iso-8859-1\\\",\\\"columnMappings\\\":[{\\\"target\\\":1,\\\"source\\\":7},{\\\"target\\\":3,\\\"source\\\":8}],\\\"idSourceColumn\\\":1,\\\"pathSourceColumn\\\":null,\\\"nameSourceColumn\\\":null,\\\"childTableSourceColumn\\\":null,\\\"resetTable\\\":true}\"' \\\n-F 'file=@\"/Users/xxxxxxxxxxxxx/Downloads/filename.csv\"'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formato de fecha"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay varios formatos que puedes utilizar al importar datos a una columna de tipo fecha."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Enteros"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyy/mm/dd"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyy/mm/dd"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "mm/dd/yyyy"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "mm/dd/yy"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Estos formatos requieren que el mes preceda al día (es decir, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dd/mm/yy"
      }), " no se acepta). Los enteros pueden separarse con guiones (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), ") o barras diagonales (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Fechas relajadas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "También puede importar formatos de fecha que estén menos estandarizados que las fechas basadas en números enteros. Por ejemplo:**"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "The 1st of March in the year 2022"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Fri Mar 4 2022"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "March 4th '22"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Fechas relativas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot analizará los siguientes formatos de fecha en relación con el día actual:**"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "next Thursday"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "Today"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "tomorrow"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "3 days from now"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Opciones de restablecimiento"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al importar datos de un archivo CSV a una tabla de HubDB, puedes establecer el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "resetTable"
      }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " (por opción predeterminada) para administrar si los datos de la fila de HubDB se sobrescriben."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Si ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "resetTable"
          }), " se establece en ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), ":"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si las filas del archivo CSV no tienen una columna de ID de fila (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            }), " o el ID de fila se especifica como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), ", esas filas se insertarán con los nuevos ID de fila generados."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si los ID de fila del archivo CSV ya existen en la tabla objetivo, las filas existentes en la tabla se actualizarán con los nuevos valores de los archivos de entrada."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si la tabla tiene filas pero el archivo CSV de entrada no tiene esos ID de fila, esas filas se eliminarán de la tabla objetivo."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si los ID de fila del archivo CSV de entrada no existen en la tabla objetivo, esas filas se insertarán con los nuevos ID de fila generados y se ignorarán los ID de fila indicados en el archivo de entrada."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si el archivo CSV de entrada no contiene la columna ID de fila, todas las filas se eliminarán de la tabla objetivo y las filas del archivo de entrada se insertarán con los nuevos ID de fila generados."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Si ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "resetTable"
          }), " se establece en ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), " (por opción predeterminada):"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si los ID de fila del archivo CSV ya existen en la tabla objetivo, las filas existentes en la tabla se actualizarán con los nuevos valores de los archivos de entrada."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si la tabla tiene filas pero el archivo CSV de entrada no tiene esos ID de fila, esas filas ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "no"
            }), " se eliminarán de la tabla objetivo y esas filas permanecerán sin cambios."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si los ID de fila del archivo CSV de entrada no existen en la tabla objetivo, esas filas se insertarán con los nuevos ID de fila generados y se ignorarán los ID de fila indicados en el archivo de entrada."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si las filas del archivo CSV no tienen una columna de ID de fila o el ID de fila se especifica como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), ", esas filas se insertarán con los nuevos ID de fila generados."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar datos de HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay varias maneras de recuperar los datos de HubDB, dependiendo de si estás buscando detalles de la tabla o las filas de una tabla:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar los detalles de la tabla de todas las tablas publicadas, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar los detalles de la tabla de una tabla publicada específica, realiza una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar todas las filas de una tabla específica, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}/rows"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar una fila específica de una tabla, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/cms/v3/hubdb/tables{tableIdOrName}/rows/{rowId}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando recuperes datos de filas, puedes filtrar y ordenar aún más los resultados."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si una tabla está configurada para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "permitir el acceso público"
      }), ", puedes acceder a la versión publicada de la tabla y las filas sin ninguna autenticación especificando el ID de tu cuenta de HubSpot a través del parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "portalId"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Filtrar filas obtenidas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al recuperar datos de la tabla de HubDB, puedes aplicar filtros como parámetros de consulta para recibir datos específicos. Los parámetros de consulta de filtro se construyen de la siguiente manera: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "columnName__operator"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, si tienes una columna numérica denominada ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "bar"
      }), ", puedes filtrar los resultados para incluir solo las filas en las que ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "bar"
      }), " sea mayor que 10: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&bar__gt=10"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Todos los filtros se suman con Y (actualmente no se admiten filtros O)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al filtrar, ten en cuenta lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Al pasar valores para las columnas ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "multiselect"
        }), ", los valores deben estar separados por comas (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "multiselect_column__contains=1,2"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para los filtros ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "datetime"
        }), ", puedes utilizar fechas relativas en lugar de marcas de tiempo para especificar un valor relativo a la hora actual. Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "-3h"
        }), " correspondería a la marca de tiempo 3 horas antes de ahora, mientras que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "10s"
        }), " correspondería a 10 segundos en el futuro. Las unidades de tiempo admitidas son ms (milisegundos), s (segundos), m (minutos), h (horas), d (días). La hora actual se puede utilizar especificando un valor cero: 0s"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A los efectos de estos filtros, la columna incorporada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_id"
      }), " es una columna ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "number"
      }), ", la columna ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_created_at"
      }), " es un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "datetime"
      }), ", y las columnas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_path"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_name"
      }), " son columnas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "text"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, descubre qué operadores se pueden aplicar a qué tipos de columna:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operador"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eq (or none)"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Es igual a"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos los tipos de columna Este filtro se aplica si no se utiliza ningún operador. Cuando se utiliza con columnas de selección múltiple, devuelve filas que coinciden exactamente con los valores suministrados."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ne"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No es igual a"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Todos los tipos de columna."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contiene"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Texto, texto enriquecido y selección múltiple. Cuando se utiliza con columnas de selección múltiple, devuelve filas que contienen todos los valores proporcionados. Este filtro ", (0, _jsxRuntime.jsx)("u", {
              children: "distingue entre mayúsculas y minúsculas"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menor que"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número, fecha y hora."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menor que o igual a"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número, fecha y hora."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mayor que"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número, fecha y hora."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gte"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mayor que o igual a"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número, fecha y hora."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nulo"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Todos los tipos de columna, excepto booleanos. Este filtro no requiere un valor (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&exampleColumn__is_null="
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_null"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No nulo"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Todos los tipos de columnas, excepto booleano. Este filtro no requiere un valor (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&exampleColumn__not_null="
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Como"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto y texto enriquecido."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "not_like"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No como"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto y texto enriquecido."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icontains"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contiene"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Texto y texto enriquecido. Este filtro ", (0, _jsxRuntime.jsx)("u", {
              children: "no distingue entre mayúsculas y minúsculas"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startswith"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comienza con"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto y texto enriquecido."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "En"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Número, seleccione y multiselección. Devuelve filas en las que la columna incluye al menos una de las opciones pasadas. Cuando no hay otro ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sort"
            }), " en el parámetro de consulta, los resultados se ordenarán en el orden en que se especifiquen los valores en el operador", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ordenar filas obtenidas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al recuperar datos de HubDB, puedes aplicar la ordenación como parámetro de consulta para determinar el orden de los datos obtenidos. Para ordenar los datos, agrega un parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort"
      }), " y especifica el nombre de la columna:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "&sort=columnName"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["De forma predeterminada, los datos se devolverán en el orden natural de la columna especificada. Puedes invertir la ordenación agregando un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), " al nombre de la columna:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "&sort=-columnName"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes incluir este parámetro varias veces para ordenar por varias columnas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además de ordenar por una columna, hay tres funciones que se pueden utilizar:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "geo_distance(location_column_name, latitud, longitud):"
          }), " toma el nombre de una columna de localización y las coordenadas, devuelve las filas ordenadas según la distancia entre el valor de la columna de localización especificada y las coordenadas proporcionadas."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "longitud(column_name):"
          }), " toma el nombre de una columna, devuelve las filas ordenadas por la longitud del valor de la columna (calculada como una cadena)"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "random():"
          }), " devuelve las filas en orden aleatorio."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Estas funciones también admiten la ordenación inversa. Por ejemplo, la siguiente ordenación ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), " devuelve primero los elementos que están más lejos:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "sort=-geo_distance(location_column,42.37,-71.07)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurando tablas HubDB para páginas dinámicas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con CMS de HubSpot, puedes usar una tabla de HubDB como fuente de datos para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "generar páginas dinámicas"
      }), ". Por ejemplo, puedes crear una tabla que contenga una fila para cada miembro de tu equipo ejecutivo, con columnas que contengan la información que desees mostrar en una página. Después de seleccionar esa tabla como fuente de datos dinámicos para una página, esa página generará un página de índice que muestra todas las filas como elementos de resumen, junto con páginas separadas para cada fila, similar a un página de índice de blog y páginas de publicación de blog."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para permitir que una tabla se seleccione como fuente de datos en el editor de contenido, deberás establecer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "useForPage"
      }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". Opcionalmente, puedes incluir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamicMetaTags"
      }), " para especificar qué columnas usar para los metadatos de cada página."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Nota: **cuando especifiques ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamicMetaTags"
        }), ", deberás asegurarte de que la página utilice etiquetas HubL ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "page_meta"
        }), " en lugar de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), ". Más información en la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb#metadata",
          children: "guía de páginas dinámicas"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, el código siguiente crearía una tabla que se puede usar para páginas dinámicas y especifica las tres columnas que se usarán para los metadatos de página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to create table\n{\n  \"name\": \"dynamic_page_table\",\n  \"label\": \"Dynamic page table\",\n  \"useForPages\": true,\n  \"columns\": [\n    {\n      \"name\": \"meta_description\",\n      \"label\": \"Meta description\",\n      \"archived\": false,\n      \"type\": \"TEXT\"\n    },\n    {\n      \"name\": \"featured_image\",\n      \"label\": \"Featured image\",\n      \"archived\": false,\n      \"type\": \"IMAGE\"\n    },\n    {\n      \"name\": \"canonical_url\",\n      \"label\": \"Canonical URL\",\n      \"archived\": false,\n      \"type\": \"URL\"\n    }\n  ],\n  \"dynamicMetaTags\": {\n    \"DESCRIPTION\": 1,\n    \"FEATURED_IMAGE_URL\": 2,\n    \"LINK_REL_CANONICAL_URL\": 3\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "useForPages"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Establecer en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " para permitir que la tabla se utilice como fuente de datos para páginas dinámicas."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "dynamicMetaTags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Especifica las columnas por ID que se utilizarán para los metadatos de cada página dinámica. Puede contener:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "DESCRIPTION"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "FEATURED_IMAGE_URL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "LINK_REL_CANONICAL_URL"
                })
              })]
            }), " Para cualquier campo de metadatos no especificado, las páginas heredarán los valores respectivos de su página principal."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DESCRIPTION"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID numérico de la columna que se utilizará para la metadescripción de cada página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FEATURED_IMAGE_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID numérico de la columna que se utilizará para la URL de la imagen destacada de cada página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LINK_REL_CANONICAL_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID numérico de la columna que se utilizará para la URL canónica de cada página."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cambios en la v3"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Las tablas deben tener tanto ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), ". Este nombre no se puede cambiar una vez creada la tabla. Los nombres solo pueden incluir letras minúsculas, dígitos y guiones bajos y no pueden comenzar con un número. Ambos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " deben ser únicos en la cuenta."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La API admite tanto la tabla ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " las rutas URL."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " Los puntos de terminación de fila devuelven la columna ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " en lugar de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " en el campo", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), ". Además, los puntos de terminación de fila ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PUT"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " requieren una columna ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), " en lugar de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " en el campo", (0, _jsxRuntime.jsx)(_components.code, {
          children: "values"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los puntos de terminación de actualización de fila ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " ahora aceptan actualizaciones dispersas, lo que significa que solo puedes especificar los valores de columna que necesita actualizar (mientras que tenías que especificar todos los valores de columna en las versiones anteriores). Al actualizar una columna con una lista de valores como multiselect, debes especificar la lista de todos los valores. Para eliminar el valor de una columna, debes especificar la columna con el valor como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " en la solicitud."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se han eliminado los puntos de terminación ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "update"
        }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "delete"
        }), " a una celda de fila en favor de los puntos de terminación de actualización de fila", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El punto de terminación de importación ahora admite un campo opcional ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "idSourceColumn"
        }), " junto con los campos existentes en las opciones con formato JSON. Puedes utilizar este campo para especificar la columna del archivo csv que contiene los ID de fila. Para importar nuevas filas junto con los nuevos valores de las filas existentes, simplemente puedes especificar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0"
        }), " como el id de fila para las nuevas filas y los id de fila válidos para las columnas existentes. Ver más detalles a continuación en la sección Importar. También puedes utilizar nombres o identificadores de columnas en el campo de destino de las asignaciones de columnas en las opciones con formato JSON."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Clonar el punto de terminación requiere un nuevo nombre y una nueva etiqueta."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}