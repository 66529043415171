"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129093123;
const slug = exports.slug = 'guides/cms/content/themes/default-themes';
const title = exports.title = 'Temas predeterminados';
const name = exports.name = 'EMEA ES Default Themes';
const metaDescription = exports.metaDescription = 'HubSpot proporciona una variedad de temas para que puedas usar, modificar o usar como referencia para el tema de tu propio sitio web. También puedes descargar estos temas a tu entorno local utilizando nuestras herramientas CLI.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "modificaci%C3%B3n-de-los-temas-predeterminados",
  "label": "Modificación de los temas predeterminados",
  "parent": null
}, {
  "depth": 0,
  "id": "listado-de-temas-predeterminado",
  "label": "Listado de temas predeterminado",
  "parent": null
}, {
  "depth": 1,
  "id": "tema-crecimiento",
  "label": "Tema Crecimiento",
  "parent": "listado-de-temas-predeterminado"
}, {
  "depth": 0,
  "id": "temas-predeterminados-en-modo-de-mantenimiento",
  "label": "Temas predeterminados en modo de mantenimiento",
  "parent": null
}, {
  "depth": 1,
  "id": "tema-barricada",
  "label": "Tema Barricada",
  "parent": "temas-predeterminados-en-modo-de-mantenimiento"
}, {
  "depth": 1,
  "id": "tema-educaci%C3%B3n",
  "label": "Tema Educación",
  "parent": "temas-predeterminados-en-modo-de-mantenimiento"
}, {
  "depth": 1,
  "id": "tema-martech",
  "label": "Tema Martech",
  "parent": "temas-predeterminados-en-modo-de-mantenimiento"
}, {
  "depth": 1,
  "id": "tema-rally",
  "label": "Tema Rally",
  "parent": "temas-predeterminados-en-modo-de-mantenimiento"
}, {
  "depth": 1,
  "id": "tema-sesi%C3%B3n",
  "label": "Tema Sesión",
  "parent": "temas-predeterminados-en-modo-de-mantenimiento"
}, {
  "depth": 1,
  "id": "tema-sprout",
  "label": "Tema Sprout",
  "parent": "temas-predeterminados-en-modo-de-mantenimiento"
}, {
  "depth": 1,
  "id": "tema-vitalidad",
  "label": "Tema Vitalidad",
  "parent": "temas-predeterminados-en-modo-de-mantenimiento"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      em: "em",
      h2: "h2",
      h3: "h3",
      img: "img",
      h4: "h4",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Temas predeterminados"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ofrece un conjunto de temas predeterminados que los creadores de contenido pueden usar para crear páginas web sin necesidad de modificar el tema base. Estos temas son los más adecuados para los creadores de contenido que tienen menos acceso a los recursos para desarrolladores."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si deseas desarrollar tu propio tema, es recomendable comenzar con el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "Boilerplate de CMS de HubSpot"
      }), ". Sin embargo, puedes descargar y modificar temas predeterminados usando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, descubre los temas predeterminados disponibles actualmente."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " las cuentas de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "CMS Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Starter"
        }), " solo tienen acceso al tema de Crecimiento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modificación de los temas predeterminados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los temas predeterminados de HubSpot se encuentran en una carpeta etiquetada @hubspot en el sistema de archivos de desarrolladores. Esta carpeta es de solo lectura y HubSpot solo puede modificar el contenido. Si quieres modificar un tema, deberás ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cms-cli#fetch",
        children: "buscar"
      }), " el tema que deseas y luego ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cms-cli#upload",
        children: "cargar"
      }), " el tema con los cambios a una carpeta diferente a @hubspot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listado de temas predeterminado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Siéntete libre de descargar nuestros temas predeterminados usando los comandos CLI de la lista. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cms-cli#fetch",
        children: "Más información sobre el comando fetch"
      }), " en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI de CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Crecimiento"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/5Cdocs/default-themes/growth-homepage.jpg",
            alt: "Página principal del tema Crecimiento"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/growth/home",
              children: "View Growth theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Any"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "12 Sections"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "16 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/growth <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Temas predeterminados en modo de mantenimiento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los siguientes temas pasaron al modo de mantenimiento. Ya no se actualizarán con nuevas funciones y solo recibirán actualizaciones relacionadas con la seguridad, la accesibilidad y las correcciones de errores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Barricada"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un tema orientado a los negocios de la construcción."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-barricade-theme-thumb-4.jpg",
            alt: "Captura de pantalla de tema Barricada"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/barricade/home",
              children: "View Barricade theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Construction"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "10 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/barricade <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Educación"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un tema diseñado para Escuelas, Universidades u otras instituciones educativas."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-education-theme-thumb-4.jpg",
            alt: "Captura de pantalla del tema Educación"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/education/home",
              children: "View Education theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Education"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "13 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/education <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Martech"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un tema de sitio web orientado a las empresas de tecnología de marketing."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-martech-theme-thumb-4.jpg",
            alt: "Captura de pantalla de tema Martech"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/martech/home",
              children: "View Martech theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Marketing Agency"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "9 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/martech <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Rally"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un tema diseñado para Empresas y Agencias."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-rally-theme-thumbnail-1-4.png",
            alt: "Captura de pantalla del tema Rally"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/rally/home",
              children: "View Rally theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Marketing Agency"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "14 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "19 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/cms-rally <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Sesión"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un tema diseñado para empresas."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-session-theme-thumb-4.jpg",
            alt: "Captura de pantalla del tema Sesión"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/session/home",
              children: "View Session theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "9 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/session <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Sprout"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un tema diseñado para Empresas y Agencias."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-sprout-theme-thumb-4.jpg",
            alt: "Captura de pantalla del tema Sprout"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/sprout/home",
              children: "View Sprout theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "15 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "21 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/sprout <destination-folder>\n"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tema Vitalidad"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un tema diseñado para Empresas y Agencias."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 5,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/default-themes/cms-vitality-theme-thumb-4.jpg",
            alt: "Captura de pantalla de tema Vitalidad"
          })
        })
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 7,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://design-assets.hubspot.com/vitality/home",
              children: "View Vitality theme live demo"
            })
          })
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h4, {
            children: "Industry:"
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: "Business Services, Healthcare, Consumer Services"
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "Features:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "8 Modules"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "18 Templates"
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.h4, {
            children: "How to download:"
          })]
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "hs fetch @hubspot/vitality <destination-folder>\n"
          })
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}