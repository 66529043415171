import styled from 'styled-components';
import { mediaQueries } from 'developer-docs-shared-components/utils/mediaQueries';
import UIIllustration from 'UIComponents/image/UIIllustration';
import UIMedia from 'UIComponents/layout/UIMedia';
import UIMediaBody from 'UIComponents/layout/UIMediaBody';
import UIMediaRight from 'UIComponents/layout/UIMediaRight';
export const Illustration = styled(UIIllustration).attrs({
  width: 150
}).withConfig({
  displayName: "sharedStyledComponents__Illustration",
  componentId: "jpnkup-0"
})(["", "{width:100px;}"], mediaQueries.belowMobile);
export const Right = styled(UIMediaRight).withConfig({
  displayName: "sharedStyledComponents__Right",
  componentId: "jpnkup-1"
})(["display:flex;justify-content:center;min-width:150px;width:calc(100% - 800px);", "{&&{margin-left:0;}}", "{min-width:100px;}"], mediaQueries.belowTablet, mediaQueries.belowMobile);
export const Media = styled(UIMedia).withConfig({
  displayName: "sharedStyledComponents__Media",
  componentId: "jpnkup-2"
})(["", "{flex-direction:column-reverse !important;}"], mediaQueries.belowTablet);
export const MediaBody = styled(UIMediaBody).withConfig({
  displayName: "sharedStyledComponents__MediaBody",
  componentId: "jpnkup-3"
})(["max-width:800px;"]);