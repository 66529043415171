"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45935558252;
const slug = exports.slug = 'reference/cms/hubl/operators-and-expression-tests';
const title = exports.title = '演算子と式評価';
const name = exports.name = 'APAC JAPAN (ja) | [new] Operators & Expression Tests_JA | 202106';
const metaDescription = exports.metaDescription = 'HubLでは、テンプレートのロジックと機能を拡張するための主な演算子と式評価をサポートしています。演算子を使用すると、算術関数の実行、比較、テンプレートロジックの複雑化、マークアップのレンダリングの変更を行うことができます。この記事では、HubLで使用できる式評価も含めて紹介します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%BC%94%E7%AE%97%E5%AD%90",
  "label": "演算子",
  "parent": null
}, {
  "depth": 1,
  "id": "%E7%AE%97%E8%A1%93%E6%BC%94%E7%AE%97",
  "label": "算術演算",
  "parent": "%E6%BC%94%E7%AE%97%E5%AD%90"
}, {
  "depth": 1,
  "id": "%E6%AF%94%E8%BC%83",
  "label": "比較",
  "parent": "%E6%BC%94%E7%AE%97%E5%AD%90"
}, {
  "depth": 1,
  "id": "%E8%AB%96%E7%90%86",
  "label": "論理",
  "parent": "%E6%BC%94%E7%AE%97%E5%AD%90"
}, {
  "depth": 1,
  "id": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AEhubl%E6%BC%94%E7%AE%97%E5%AD%90",
  "label": "その他のHubL演算子",
  "parent": "%E6%BC%94%E7%AE%97%E5%AD%90"
}, {
  "depth": 0,
  "id": "%E5%BC%8F%E8%A9%95%E4%BE%A1",
  "label": "式評価",
  "parent": null
}, {
  "depth": 1,
  "id": "boolean",
  "label": "boolean",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "containing",
  "label": "containing",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "containingall",
  "label": "containingall",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "defined",
  "label": "defined",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "divisibleby",
  "label": "divisibleby",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "equalto",
  "label": "equalto",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "even",
  "label": "even",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "float",
  "label": "float",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "integer",
  "label": "integer",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "iterable",
  "label": "iterable",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "lower",
  "label": "lower",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "mapping",
  "label": "mapping",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "none",
  "label": "none",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "number",
  "label": "number",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "odd",
  "label": "odd",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "sameas",
  "label": "sameas",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "sequence",
  "label": "sequence",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "string",
  "label": "string",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "string_containing",
  "label": "string_containing",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "string_startingwith",
  "label": "string_startingwith",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "truthy",
  "label": "truthy",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "undefined",
  "label": "undefined",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "upper",
  "label": "upper",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}, {
  "depth": 1,
  "id": "within",
  "label": "within",
  "parent": "%E5%BC%8F%E8%A9%95%E4%BE%A1"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "演算子と式評価"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubLでは、テンプレートのロジックと機能を拡張するための主な演算子と式評価をサポートしています。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#operators",
        children: "演算子"
      }), "を使用すると、算術関数の実行、比較、テンプレートロジックの複雑化、マークアップのレンダリングの変更を行うことができます。この記事では、HubLで使用できる", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
        children: "式評価"
      }), "も含めて紹介します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "演算子"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "演算子は、HubLコンパイラーに対して各種の演算を行うよう指示する記号です。これらの演算の結果、マークアップが出力されます。以下に、サポートされる全てのHubL演算子を示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "算術演算"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "標準の算術演算子を使用して、テンプレートのコンテキストで値を計算できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "記号"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "+"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["2つのオブジェクト同士を加算します。これは通常、数値の加算に使用します。リストの文字列を連結する場合は、代わりに", (0, _jsxRuntime.jsx)(_components.code, {
              children: "~"
            }), "を使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "-"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ある数値を別の数値から減算します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "/"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値を除算します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "%"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値を除算した余りを返します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "//"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["2つの数値を除算した余りを整数に切り捨てて返します。例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "&#123;%raw%&#125;&#123;&#123; 20 // 7 &#125;&#125;&#123;%endraw%&#125;"
            }), "は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            }), "です。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "*"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値を乗算します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "**"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "左辺のオペランドを右辺のオペランドで累乗します。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 11 %}\n{% set my_number = 2 %}\n\n{{ my_num + my_number }}<br/>  <!-- 11 + 2 = 13 -->\n{{ my_num - my_number }}<br/>  <!-- 11 - 2 = 9 -->\n{{ my_num / my_number }}<br/>  <!-- 11 / 2 = 5.5 -->\n{{ my_num % my_number }}<br/>  <!-- 11 % 2 = 1 -->\n{{ my_num // my_number }}<br/> <!-- 11 // 2 = 5 -->\n{{ my_num * my_number }}<br/>  <!-- 11 * 2 = 22 -->\n{{ my_num ** my_number }}<br/> <!-- 11 ** 2 = 121 -->\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "13 9 5.5 1 5 22 121\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "比較"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["比較演算子を使用すると、テンプレートロジックに対して値を評価できます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "このリンク先のif文"
      }), "で、比較演算子の使用例を確認できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "記号"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "短縮形"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "=="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "eq"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の値に等しい。2つのオブジェクトが等しい場合、trueと評価されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "!="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の値に等しくない。2つのオブジェクトが等しくない場合、trueと評価されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: ">"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "gt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の値より大きい。左辺の値が右辺の値よりも大きい場合、trueと評価されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: ">="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "gte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の値以上。左辺の値が右辺の値と同じかそれよりも大きい場合、trueと評価されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "<"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の値より小さい。左辺の値が右辺の値よりも小さい場合、trueと評価されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "<="
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "次の値以下。左辺の値が右辺の値と同じかそれよりも小さい場合、trueと評価されます。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["短縮形の比較演算子は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|selectattr()"
        }), "などの式を評価するフィルターで使用できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 11 %}\n{% set my_number = 2 %}\n\n{{ my_num == my_number }}<br/>  <!-- false -->\n{{ my_num != my_number }}<br/>  <!-- true -->\n{{ my_num > my_number }}<br/>   <!-- true -->\n{{ my_num >= my_number }}<br/>  <!-- true -->\n{{ my_num < my_number }}<br/>   <!-- false -->\n{{ my_num <= my_number }}<br/>  <!-- false -->\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "false true true true false false\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "論理"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "論理演算子を使用すると、複数の式を1つのステートメント（文）として結合できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "記号"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "and"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "左辺のオペランドと右辺のオペランドの両方がtrueと評価される場合、trueを返します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "or"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "左辺のオペランドまたは右辺のオペランドのいずれかがtrueと評価される場合、trueを返します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "not"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ステートメントの否定に、isと併せて使用します。後述の例を参照してください。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "(expr)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "演算順を示すために、式をグループ化します。例：(10 - 2) * 変数"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "?:"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/if-statements",
              children: "3項演算子"
            }), "には3つの引数（式、true条件、false条件）があります。式を評価し、該当した条件を返します。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "その他のHubL演算子"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下に、さまざまなタスクを実行するために使用できる、その他の重要なHubL演算子を示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "記号"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "in"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "値がシーケンス内に含まれているかどうかをチェックします。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "is"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
              children: "式評価"
            }), "を実行します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "|"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィルターを適用します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "~"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "値を連結します。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "式評価"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "式評価は、論理演算子を使用して評価できる各種のブール条件です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "boolean"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "boolean"
      }), "式評価は、オブジェクトがブール値かどうかを確認します（厳密なチェックであり、truthy式の評価ではありません）。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set isActive = false %}\n\n{% if isActive is boolean %}\nisActive is a boolean\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "isActive is a boolean\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "containing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "containing"
      }), "式評価では、変数のリストに値が含まれているかどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if numbers is containing 2 %}\n\tSet contains 2!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Set contains 2!\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "containingall"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "containingall"
      }), "式評価では、変数のリストに別のリストの全ての値が含まれているかどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if numbers is containingall [2, 3] %}\n\tSet contains 2 and 3!\n{% endif %}\n\n{% if numbers is containingall [2, 4] %}\n\tSet contains 2 and 4!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Set contains 2 and 3!\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "defined"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "defined"
      }), "式評価では、変数がテンプレートのコンテキスト内で定義済みかどうかをチェックします。演算子を使わずにif文を記述してこの式評価を使用することもできますが、その場合は既定で、変数が定義されているかどうかだけがチェックされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例では、カラーモジュールのcolorパラメーターが評価されます。colorパラメーターに値がない場合、既定背景色の黒がテンプレートによってレンダリングされます。値が定義されている場合、ユーザーが設定した背景色がテンプレートによってレンダリングされます。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% color \"my_color\" color=\"#930101\", export_to_template_context=True %}\n<style>\n{% if widget_data.my_color.color is defined %}\nbody{\n    background: {{ widget_data.my_color.color }};\n}\n{% else %}\nbody{\n    background: #000;\n}\n{% endif %}\n</style>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "HTML出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<style>\n  body {\n    background: #930101;\n  }\n</style>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "divisibleby"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "divisibleby"
      }), "式評価を使用すると、オブジェクトを別の数値で割り切れるかどうかを評価できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、以下のforループは、動物の種類のリストを反復処理するために作成されています。動物の各種類がdiv内に出力され、5つ目ごとのdivに異なるインラインスタイルが適用されます（幅：100％）。このコンセプトをブログに適用すると、特定のパターンの記事に別のマークアップをレンダリングできます。forループとloop.indexの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "こちらの記事を参照してください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set animals = [\"lions\", \"tigers\", \"bears\", \"dogs\", \"sharks\"] %}\n{% for animal in animals %}\n    {% if loop.index is divisibleby 5 %}\n    <div style=\"width:100%\">{{animal}}</div>\n    {% else %}\n    <div style=\"width:25%\">{{animal}}</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "HTML出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:25%\">lions</div>\n<div style=\"width:25%\">tigers</div>\n<div style=\"width:25%\">bears</div>\n<div style=\"width:25%\">dogs</div>\n<div style=\"width:100%\">sharks</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "equalto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "equalto"
      }), "式評価では、変数の値が定数または別の変数の値と等しいかどうかをチェックします。これと同じ評価は、==演算子を使用して行うこともできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例では、ループ内の記事の合計数に応じてブログ記事の幅を調整します。この出力例では、ブログに4件の記事があると仮定しています。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n    {% if loop.length is equalto 2 %}\n        <div style=\"width:50%;\">Post content</div>\n    {% elif loop.length is equalto 3 %}\n        <div style=\"width:33.333332%;\">Post content</div>\n    {% elif loop.length is equalto 4 %}\n        <div style=\"width:25%;\">Post content</div>\n    {% else %}\n        <div style=\"width:100%;>Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "HTML出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n<div style=\"width:25%;\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "even"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "even"
      }), "式評価では、数値型変数の値が偶数かどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例は、ブログ一覧の単純なループを示しています。この例では、ループの現在の反復数（イテレーション）が偶数の場合、その記事項目のdivにeven-postクラスを割り当てます。それ以外の場合は、odd-postクラスを割り当てます。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n   {% if loop.index is even %}\n        <div class=\"post-item even-post\">Post content</div>\n    {% else %}\n        <div class=\"post-item odd-post\">Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "HTML出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "float"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "float"
      }), "式評価では、数値型変数の値が浮動小数点数かどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set quantity = 1.20 %}\n{% if quantity is float %}\n  quantity is a floating point number\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "HTML出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "quantity is a floating-point number\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "integer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["変数が", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "整数"
      }), "かどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set quantity = 120 %}\n{% if quantity is integer %}\n  quantity is an integer\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "HTML出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "quantity is an integer\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "iterable"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["変数が", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "反復可能"
      }), "であり、ループ処理を適用できるかどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下の例では、「jobs」という変数が反復可能かどうかをチェックします 。この変数にジョブのリストが格納されている場合、if文がtrueと評価されて、ループが実行されます。変数に1つの値しか格納されていない場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if文"
      }), "により、別のマークアップを使用してその値が出力されます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "ループの詳細については、こちらを参照してください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set jobs = [\"Accountant\", \"Developer\", \"Manager\", \"Marketing\", \"Support\"] %}\n\n{% if jobs is iterable %}\n<h3>Available positions</h3>\n<ul>\n{% for job in jobs %}\n    <li>{{ job }}</li>\n{% endfor %}\n</ul>\n{% else %}\n<h3>Available position</h3>\n<div class=\"single-position\">{{ jobs }}</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "HTML出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>Available positions</h3>\n<ul>\n  <li>Accountant</li>\n  <li>Developer</li>\n  <li>Manager</li>\n  <li>Marketing</li>\n  <li>Support</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "lower"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "lower"
      }), "式評価は、文字列が小文字の場合にtrueと評価されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下の例では、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements#unless-statements",
        children: "unless文"
      }), "と小文字フィルターを使用して、テキストモジュールに入力されるテキストの文字列が常に小文字になるようにしています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"my_text\" path=\"@hubspot/text\" label=\"Enter text\", value=\"Some TEXT that should be Lowercase\", export_to_template_context=True %}\n\n{% unless widget_data.my_text.value is lower %}\n{{ widget_data.my_text.value|lower }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "some text that should be lowercase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "mapping"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "mapping"
      }), "式評価では、オブジェクトがディクショナリー（辞書型）かどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例では、contactオブジェクトが辞書型かどうかをチェックしています。この例の場合、オブジェクトは辞書型に該当します。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if contact is mapping %}\nThis object is a dictionary.\n{% else %}\nThis object is not a dictionary.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This object is a dictionary.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "none"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "none"
      }), "式評価では、変数にnull値が格納されているかどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"user_email\" path=\"@hubspot/text\" label=\"Enter user email\", value=\"example@hubspot.com\", export_to_template_context=True %}\n{% unless widget_data.user_email.value is none %}\n{{ widget_data.user_email.value }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "example@hubspot.com\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "number"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "number"
      }), "式評価では、変数の値が数値かどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例では、変数をチェックして、数かどうかを調べ、数の場合は百万に変換します。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_var = 40 %}\n{% if my_var is number %}\n{{ my_var * 1000000 }}\n{% else %}\nmy_var is not a number.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "40000000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "odd"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "odd"
      }), "式評価では、数値型変数の値が奇数かどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例は、前述のeven式評価の例の逆バージョンです。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n   {% if loop.index is odd %}\n        <div class=\"post-item odd-post\">Post content</div>\n    {% else %}\n        <div class=\"post-item even-post\">Post content</div>\n    {% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "HTML出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n<div class=\"post-item odd-post\">Post content</div>\n<div class=\"post-item even-post\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "sameas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "sameas"
      }), "式評価では、2つの変数の値が同じかどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例では、2つの変数を設定してから、これらの変数の値が同じかどうかをチェックします。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set var_one = True %}\n{% set var_two = True %}\n{% if var_one is sameas var_two  %}\nThe variables values are the same.\n{% else %}\nThe variables values are different.\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The variables values are the same.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "sequence"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "sequence"
      }), "式評価は、変数がシーケンスかどうかをチェックするという点で", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "iterable"
      }), "式評価と似ています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例では、変数がシーケンスかどうかをチェックしてから、音楽ジャンルのシーケンスを反復処理します。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set genres = [\"Pop\", \"Rock\", \"Disco\", \"Funk\", \"Folk\", \"Metal\", \"Jazz\", \"Country\", \"Hip-Hop\", \"Classical\", \"Soul\", \"Electronica\" ] %}\n{% if genres is sequence %}\n<h3>Favorite genres</h3>\n<ul>\n{% for genre in genres %}\n    <li>{{ genre }}</li>\n{% endfor %}\n</ul>\n{% else %}\n<h3>Favorite genre:</h3>\n    <div class=\"single-genre\">{{ genres }}</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "HTML出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>Pop</li>\n  <li>Rock</li>\n  <li>Disco</li>\n  <li>Funk</li>\n  <li>Folk</li>\n  <li>Metal</li>\n  <li>Jazz</li>\n  <li>Country</li>\n  <li>Hip-Hop</li>\n  <li>Classical</li>\n  <li>Soul</li>\n  <li>Electronica</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "string"
      }), "式評価では、変数の値が文字列かどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例では、変数をチェックして、それが文字列かどうかを調べます、文字列の場合はタイトルフィルターを適用して大文字に変更します。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_var = \"title of section\" %}\n{% if my_var is string %}\n{{ my_var|title }}\n{% else %}\nmy_var is not a string\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Title Of Section\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string_containing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この評価では、文字列が別の文字列に含まれているかどうかをチェックします。この式評価は「is」演算子と組み合わせて使用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if content.domain is string_containing \".es\" %}\nMarkup that will only render on content hosted on .es domains\n{% elif content.domain is string_containing \".jp\" %}\nMarkup that will only render on content hosted on .jp domains\n{% else %}\nMarkup that will render on all other domains\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Markup that will render on all other domains\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "string_startingwith"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この式評価では、文字列が特定の文字列で始まるかどうかをチェックします。「is」演算子と組み合わせて使用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if content.slug is string_startingwith \"es/\" %}\nMarkup that will only render on content hosted in a /es/ subdirectory\n{% elif content.slug is string_startingwith \"jp/\" %}\nMarkup that will only render on content hosted in a /jp/ subdirectory\n{% else %}\nMarkup that will render on all subdirectories\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Markup that will render on all subdirectories\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "truthy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "truthy"
      }), "式評価では、式がtrueと評価されるかどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例では、ブール値チェックボックスモジュールを使用してアラートメッセージを表示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL/HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% boolean \"check_box\" label=\"Show alert\", value=True, export_to_template_context=True %}\n\n{% if widget_data.check_box.value is truthy %}\n<div class=\"alert\">Danger!</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "HTML出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"alert\">Danger!</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "undefined"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "undefined"
      }), "式評価では、テンプレートのコンテキスト内で変数が未定義かどうかをチェックします。この評価はnone式評価とは異なります。undefined式評価でtrueが返されるのは、変数が存在しても値が格納されていない場合です。一方、none式評価では変数にnull値が格納されている場合にtrueが返されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例では、テンプレートで変数「my_var」の有無をチェックします。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if my_var is undefined %}\nA variable named \"my_var\" does not exist on this template.\n{% else %}\n{{ my_var }}\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A variable named \"my_var\" does not exist on this template.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "upper"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "upper"
      }), "式評価は、文字列が大文字の場合にtrueと評価されます。以下の例は、前述のlower式評価の例の逆バージョンです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"my_text\" path=\"@hubspot/text\" label=\"Enter text\", value=\"Some TEXT that should be Uppercase\", export_to_template_context=True %}\n\n{% unless widget_data.my_text.value is upper %}\n{{ widget_data.my_text.value|upper }}\n{% endunless %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "SOME TEXT THAT SHOULD BE UPPERCASE\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "within"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "within"
      }), "式評価では、変数がリスト内にあるかどうかをチェックします。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numbers = [1, 2, 3] %}\n\n{% if 2 is within numbers %}\n\t2 is in the list!\n{% endif %}\n\n{% if 4 is within numbers %}\n\t4 is in the list!\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "出力のレンダリング",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2 is in the list!\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}