"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 102021266312;
const slug = exports.slug = 'guides/api/settings/business-units-api';
const title = exports.title = 'Business Units API';
const name = exports.name = 'vNext Docs DP - Business Units';
const metaDescription = exports.metaDescription = "HubSpot's business units API endpoint provides information about business units tied to a user.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "get-business-units-tied-to-a-user",
  "label": "Get business units tied to a user",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Business Units"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following endpoint provides information about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account-settings/manage-brands-with-business-unit",
        children: "business units"
      }), " tied to a user. This may also include information about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account-settings/edit-your-logo-favicon-and-brand-colors",
        children: "logos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the business units API currently only supports retrieving business unit data and does ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " support associating assests with a business unit, nor creating a new business unit."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get business units tied to a user"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get the business units that a user has access to, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/business-units/v3/business-units/user/{userId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following is an example of what the response body should include:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example GET request to /business-units/v3/business-units/user/{userId}\n{\n  \"logoMetadata\": {\n    \"logoAltText\": \"logo sample text\",\n    \"resizedUrl\": \"sillystring\",\n    \"logoUrl\": \"examplelogourl.com\"\n  },\n  \"name\": \"sample business unit name\",\n  \"id\": \"101\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For more information on how to use the business units API, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/settings/business-units-api",
        children: "reference documentation"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}