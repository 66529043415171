"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358754;
const slug = exports.slug = 'guides/api/crm/objects/contacts';
const title = exports.title = 'CRM-API | Kontakte';
const name = exports.name = 'vNext Docs DP - Kontakte';
const metaDescription = exports.metaDescription = 'In Kontaktdatensätzen werden Informationen zu Einzelpersonen gespeichert. Die Kontakte-Endpunkte ermöglichen es Ihnen, diese Daten zu verwalten und zwischen HubSpot und anderen Systemen zu synchronisieren.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "kontakte-erstellen",
  "label": "Kontakte erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "eigenschaften",
  "label": "Eigenschaften",
  "parent": "kontakte-erstellen"
}, {
  "depth": 1,
  "id": "zuordnungen",
  "label": "Zuordnungen",
  "parent": "kontakte-erstellen"
}, {
  "depth": 0,
  "id": "kontakte-nach-datensatz-id%2C-e-mail-oder-benutzerdefinierter-%E2%80%9Eeindeutiger-wert%E2%80%9C-eigenschaft-abrufen",
  "label": "Kontakte nach Datensatz-ID, E-Mail oder benutzerdefinierter „Eindeutiger Wert“-Eigenschaft abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "kontakte-aktualisieren",
  "label": "Kontakte aktualisieren",
  "parent": null
}, {
  "depth": 1,
  "id": "bestehende-kontakte-mit-datens%C3%A4tzen-oder-aktivit%C3%A4ten-verkn%C3%BCpfen",
  "label": "Bestehende Kontakte mit Datensätzen oder Aktivitäten verknüpfen",
  "parent": "kontakte-aktualisieren"
}, {
  "depth": 1,
  "id": "eine-zuordnung-entfernen",
  "label": "Eine Zuordnung entfernen",
  "parent": "kontakte-aktualisieren"
}, {
  "depth": 0,
  "id": "eine-aktivit%C3%A4t-an-einen-kontaktdatensatz-anpinnen",
  "label": "Eine Aktivität an einen Kontaktdatensatz anpinnen",
  "parent": null
}, {
  "depth": 0,
  "id": "kontakte-l%C3%B6schen",
  "label": "Kontakte löschen",
  "parent": null
}, {
  "depth": 0,
  "id": "beschr%C3%A4nkungen",
  "label": "Beschränkungen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Kontakte"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In HubSpot speichern Kontakte Informationen zu den einzelnen Personen, die mit Ihrem Unternehmen interagieren. Die Kontakte-Endpunkte ermöglichen es Ihnen, Kontaktdatensätze in Ihrem HubSpot-Account zu erstellen und zu verwalten sowie Kontaktdaten zwischen HubSpot und anderen Systemen zu synchronisieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über Objekte, Datensätze, Eigenschaften und Zuordnungen-APIs im Leitfaden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Grundlegendes zum CRM"
      }), ". Weitere allgemeine Informationen zu Objekten und Datensätzen in HubSpot finden Sie in diesem Artikel über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://knowledge.hubspot.com/contacts-user-guide",
        children: "Verwalten Ihrer CRM-Datenbank"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kontakte erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um neue Kontakte zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Schließen Sie in Ihrer Anfrage Ihre Kontaktdaten in ein properties-Objekt ein. Sie können auch ein associations-Objekt hinzufügen, um Ihrem neuen Kontakt vorhandene Datensätze (z. B. Unternehmen, Deals) oder Aktivitäten (z. B. Meetings, Notizen) zuzuordnen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Kontaktdetails werden in Kontakteigenschaften gespeichert. Es gibt ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/hubspots-default-contact-properties",
        children: "Standard-HubSpot-Kontakteigenschaften"
      }), ", Sie können jedoch auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "benutzerdefinierte Eigenschaften erstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie einen neuen Kontakt erstellen, sollten Sie ", (0, _jsxRuntime.jsx)("u", {
        children: "mindestens eine"
      }), " der folgenden Eigenschaften in Ihre Anfrage aufnehmen: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastname"
      }), ". Es wird empfohlen, immer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " einzuschließen, da E-Mail-Adressen die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/deduplication-of-contacts-companies-deals-tickets#automatic-deduplication-in-hubspot",
        children: "primäre eindeutige ID"
      }), " sind, um doppelte Kontakte in HubSpot zu vermeiden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um alle verfügbaren Eigenschaften anzuzeigen, können Sie eine Liste der Kontakteigenschaften Ihres Accounts abrufen, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/contacts"
      }), " durchführen. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "Eigenschaften-API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Wenn Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lifecyclestage"
        }), " in Ihre Anfrage aufgenommen haben, müssen sich die Werte auf den internen Namen der Lifecycle-Phase beziehen. Die internen Namen der Standardphasen sind ", (0, _jsxRuntime.jsx)("u", {
          children: "Text"
        }), "-Werte und ändern sich auch nicht, wenn Sie das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties#:~:text=the%20properties%20settings.-,Label/Name%3A,-enter%20a%20unique",
          children: "Label"
        }), " der Phase bearbeiten (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriber"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "marketingqualifiedlead"
        }), "). Die internen Namen von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages",
          children: "benutzerdefinierten Phasen"
        }), " sind ", (0, _jsxRuntime.jsx)("u", {
          children: "numerische"
        }), " Werte. Sie können die interne ID einer Phase in Ihren ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages#:~:text=To%20edit%20a%20lifecycle%20stage%2C%20hover%20over%20the%20stage%20and%20click%20Edit.%20In%20the%20right%20panel%2C%20edit%20the%20Stage%20name%2C%20then%20click%20Edit%20lifecycle%20stage%20to%20confirm.%20Click%20the%20code%20codcode%20icon%20to%20view%20the%20stage%27s%20internal%20ID%2C%20which%20is%20used%20by%20integrations%20and%20APIs.",
          children: "Einstellungen für Lifecycle-Phasen"
        }), " finden, oder indem Sie die Lifecycle-Phase-Eigenschaft über die API abrufen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um beispielsweise einen neuen Kontakt zu erstellen, kann Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"company\": \"HubSpot\",\n    \"website\": \"hubspot.com\",\n    \"lifecyclestage\": \"marketingqualifiedlead\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zuordnungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Erstellen eines neuen Kontakts können Sie den Kontakt auch mit bestehenden Datensätzen oder Aktivitäten verknüpfen. können Sie den Kontakt auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "bestehenden Datensätzen"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "Aktivitäten"
      }), " zuordnen, indem Sie ein associations-Objekt einschließen. Um beispielsweise einen neuen Kontakt mit einem bestehenden Unternehmen und einer vorhandenen E-Mail zu verknüpfen, würde Ihre Anfrage wie folgt aussehen:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"company\": \"HubSpot\",\n    \"website\": \"hubspot.com\",\n    \"lifecyclestage\": \"marketingqualifiedlead\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 279\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 556677\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 197\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im associations-Objekt sollten Sie Folgendes einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Datensatz oder die Aktivität, den bzw. die Sie dem Kontakt zuordnen möchten, angegeben durch seinen bzw. ihren eindeutigen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "-Wert."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ der Zuordnung zwischen dem Kontakt und dem Datensatz/der Aktivität. Beziehen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), " ein. Standardzuordnungstyp-IDs sind ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "hier"
            }), " aufgeführt. Sie können den Wert für benutzerdefinierte Zuordnungstypen (d. h. Label) über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "Zuordnungen-API"
            }), " abrufen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kontakte nach Datensatz-ID, E-Mail oder benutzerdefinierter „Eindeutiger Wert“-Eigenschaft abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Kontakte einzeln oder in Batches abrufen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um einen einzelnen Kontakt abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{contactId} or"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{email}?idProperty=email"
        }), " durch."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um eine Liste aller Kontakte anzufordern, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts"
        }), " durch."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können für diese Endpunkte die folgenden Abfrageparameter in die Anfrage-URL einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der Eigenschaften, die in der Antwort zurückgegeben werden sollen. Wenn der angefragte Kontakt keinen Wert für eine Eigenschaft hat, wird er nicht in der Antwort angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der aktuellen und historischen Eigenschaften, die in der Antwort zurückgegeben werden sollen. Wenn der angefragte Kontakt keinen Wert für eine Eigenschaft hat, wird er nicht in der Antwort angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine durch Kommas getrennte Liste von Objekten, für die zugehörige IDs abgerufen werden sollen. Alle angegebenen Zuordnungen, die nicht vorhanden sind, werden nicht in der Antwort zurückgegeben. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "Zuordnungen-API"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um einen Batch von bestimmten Kontakten nach Datensatz-ID, E-Mail-Adresse oder einer ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "benutzerdefinierten „Eindeutige ID“-Eigenschaft abzurufen"
        }), ", führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/contacts/batch/read"
        }), " durch. Der Batch-Endpunkt kann Zuordnungen ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " abrufen. Erfahren Sie, wie Sie Zuordnungen mit der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "Zuordnungen-API"
        }), " batchweise lesen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für den Batch-Leseendpunkt können Sie den optionalen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "-Parameter verwenden, um Kontakte nach ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " oder einer benutzerdefinierten ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "„Eindeutige ID“-Eigenschaft"
      }), " abzurufen. Standardmäßig beziehen sich die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "-Werte in der Anfrage auf die Datensatz-ID (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), sodass der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "-Parameter beim Abrufen nach Datensatz-ID nicht erforderlich ist. Wenn Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " oder eine benutzerdefinierte „Eindeutiger Wert“-Eigenschaft zum Abrufen von Kontakten verwenden, müssen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "-Parameter angeben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um beispielsweise einen Batch von Kontakten basierend auf Datensatz-ID-Werten abzurufen, könnte Ihre Anfrage wie folgt aussehen (nur aktuelle Werte oder aktuelle und historische Werte):"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body with record ID (current values)\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"inputs\": [\n    {\n      \"id\": \"1234567\"\n    },\n    {\n      \"id\": \"987456\"\n    }\n  ]\n}\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"lifecyclestage\", \"hs_lead_status\"],\n  \"inputs\": [\n    {\n      \"id\": \"1234567\"\n    },\n    {\n      \"id\": \"987456\"\n    }\n  ]\n}\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um Kontakte basierend auf der E-Mail-Adresse oder einer benutzerdefinierten „Eindeutige ID“-Eigenschaft (z. B. eine für Ihr Unternehmen eindeutige Kunden-ID-Nummer) abzurufen, sieht Ihre Anfrage wie folgt aus:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with email address\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"idProperty\": \"email\",\n  \"inputs\": [\n    {\n      \"id\": \"lgilmore@thedragonfly.com\"\n    },\n    {\n      \"id\": \"sstjames@thedragonfly.com\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with a unique value property\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"idProperty\": \"internalcustomerid\",\n  \"inputs\": [\n    {\n      \"id\": \"12345\"\n    },\n    {\n      \"id\": \"67891\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kontakte aktualisieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Kontakte einzeln oder mehrere gleichzeitig aktualisieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ", (0, _jsxRuntime.jsx)("u", {
        children: "einzelne"
      }), " Kontakte zu aktualisieren, können Sie die Datensatz-ID (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ") oder die E-Mail-Adresse des Kontakts (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ") verwenden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um einen einzelnen Kontakt anhand seiner Datensatz-ID zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{contactId}"
        }), " durch und schließen Sie die Daten ein, die Sie aktualisieren möchten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um einen einzelnen Kontakt anhand seiner E-Mail-Adresse zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{email}?idProperty=email"
        }), " durch und schließen Sie die Daten ein, die Sie aktualisieren möchten."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zum Beispiel:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with record ID\n// PATCH /crm/v3/objects/contacts/123456789\n{\n  \"properties\": {\n    \"favorite_food\": \"burger\",\n    \"jobtitle\": \"Manager\",\n    \"lifecyclestage\": \"Customer\"\n  }\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with email address\n// PATCH /crm/v3/objects/contacts/lgilmore@thedragonfly.com?idProperty=email\n{\n  \"properties\": {\n    \"favorite_food\": \"Donut\",\n    \"jobtitle\": \"Owner\",\n    \"phone\": \"5555555555\"\n  }\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Kontakte in ", (0, _jsxRuntime.jsx)("u", {
        children: "Batches"
      }), " zu aktualisieren, können Sie die Datensatz-ID-Werte der Kontakte (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ") verwenden. Um mehrere Kontakte zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/batch/update"
      }), " durch. Geben Sie in Ihrem Anforderungstext die Datensatz-ID jedes Kontakts als ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " und berücksichtigen Sie die Eigenschaften, die Sie aktualisieren möchten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zum Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n// POST /crm/v3/objects/contacts/batch/update\n{\n  \"inputs\": [\n    {\n      \"id\": \"123456789\",\n      \"properties\": {\n        \"favorite_food\": \"burger\"\n      }\n    },\n    {\n      \"id\": \"56789123\",\n      \"properties\": {\n        \"favorite_food\": \"Donut\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bestehende Kontakte mit Datensätzen oder Aktivitäten verknüpfen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Kontakt anderen CRM-Datensätzen oder einer Aktivität zuzuordnen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Um den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), "-Wert abzurufen, verweisen Sie auf ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "diese Liste"
        }), " der Standardwerte oder führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), " durch."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "Zuordnungen-API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine Zuordnung entfernen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Zuordnung zwischen einem Kontakt und einem Datensatz oder einer Aktivität zu entfernen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an die folgende URL durch: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactID}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Aktivität an einen Kontaktdatensatz anpinnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können eine Aktivität an einen Kontaktdatensatz ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "anpinnen"
      }), " , indem Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "-Feld in Ihre Anfrage aufnehmen. Geben Sie im Feld die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " der anzupinnenden Aktivität an, die über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "Interaktionen-APIs"
      }), " abgerufen werden kann. Sie können eine Aktivität pro Datensatz anpinnen, und die Aktivität muss bereits vor dem Anpinnen mit dem Kontakt verknüpft sein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um die angepinnte Aktivität eines Kontakts festzulegen oder zu aktualisieren, könnte Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body PATCH /crm/v3/objects/contacts/{contactId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können auch einen Kontakt erstellen, ihn mit einer vorhandenen Aktivität verknüpfen und die Aktivität in derselben-Anfrage anpinnen. Zum Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body POST /crm/v3/objects/contacts\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 201\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kontakte löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Kontakte einzeln oder mehrere gleichzeitig löschen, wodurch der Kontakte in den Papierkorb in HubSpot verschoben wird. Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "den Kontakt später in HubSpot wiederherstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen einzelnen Kontakt anhand seiner ID zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das gleichzeitige Löschen von mehreren Kontakten, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Endpunkte"
      }), " klicken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beschränkungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Batch-Vorgänge sind auf jeweils 100 Datensätze beschränkt. Sie können beispielsweise nicht mehr als 100 Kontakte in einer Anfrage per Batch aktualisieren. Es gibt auch Limits für ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/api/faq#limits_contacts",
        children: "Kontakte und Formulareinsendungen"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}