"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 49334172328;
const slug = exports.slug = 'guides/cms/content/fields/best-practices';
const title = exports.title = 'Best Practices zu Modul- und Designfeldern';
const name = exports.name = 'Best Practices zu Modul- und Designfeldern';
const metaDescription = exports.metaDescription = 'Die Möglichkeit, Felder zu erstellen und Felder zu gruppieren, wirft ein Problem hinsichtlich des Nutzererlebnisses (UX) auf. Wir empfehlen Ihnen, diese Seite als Leitfaden zu verwenden, um Content-Autoren eine intuitive Erfahrung zu bieten.';
const featuredImage = exports.featuredImage = 'https://www.hubspot.com/hubfs/options%20and%20style%20tabs.png';
const featuredImageAltText = exports.featuredImageAltText = 'Registerkarte „Modulstilfelder“ im CMS Hub-Seiten-Editor';
const toc = exports.toc = [{
  "depth": 0,
  "id": "stilfelder-vs.-inhaltsfelder",
  "label": "Stilfelder vs. Inhaltsfelder",
  "parent": null
}, {
  "depth": 0,
  "id": "felder-organisieren",
  "label": "Felder organisieren",
  "parent": null
}, {
  "depth": 1,
  "id": "beispiel",
  "label": "Beispiel",
  "parent": "felder-organisieren"
}, {
  "depth": 0,
  "id": "erforderliche-felder",
  "label": "Erforderliche Felder",
  "parent": null
}, {
  "depth": 0,
  "id": "typografie",
  "label": "Typografie",
  "parent": null
}, {
  "depth": 0,
  "id": "schalter-vs.-kontrollk%C3%A4stchen-in-booleschen-feldern",
  "label": "Schalter vs. Kontrollkästchen in booleschen Feldern",
  "parent": null
}, {
  "depth": 0,
  "id": "verwandte-artikel",
  "label": "Verwandte Artikel",
  "parent": null
}, {
  "depth": 2,
  "id": "tutorials",
  "label": "Tutorials",
  "parent": "verwandte-artikel"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      h2: "h2",
      a: "a",
      em: "em",
      img: "img",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Best Practices zu Modul- und Designfeldern"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie Felder in Ihr Modul oder Design einbinden, sollten Sie einige Best Practices beachten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Felder in Gruppen zusammenfassen"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Felder modulübergreifend logisch organisieren"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ein einheitliches Formatierungserlebnis mit Stilfeldern bieten"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In diesem Artikel erfahren Sie mehr über einige empfohlene Best Practices, um Content-Autoren eine effiziente und konsistente Bearbeitung von Modul- und Designfeldern zu ermöglichen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Stilfelder vs. Inhaltsfelder"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können sowohl zu Modulen als auch zu Designs ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "Stilfelder"
      }), " hinzufügen, mit denen der Content-Autor das Erscheinungsbild des Moduls anpassen kann, z. B. Rahmen oder Hintergrundbilder hinter Text. Stilfelder sollten keine Bedeutung vermitteln oder erforderlich sein, um die Inhalte der Seite zu verstehen. Wenn Sie beispielsweise ein Text- und ein Bildmodul haben und das Bild zum Verständnis des Textinhalts erforderlich ist, sollte das Bild ein Inhaltsfeld und kein Stilfeld sein."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn sie einem Modul hinzugefügt werden, werden die Stiloptionen auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Stile"
      }), " des Seiten-Editors angezeigt. Wenn sie einem Design hinzugefügt werden, erscheinen die Stiloptionen in der linken Seitenleiste des Design-Editors."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/style-field-module-editor0.png",
        alt: "style-field-module-editor0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Denken Sie an die Barrierefreiheit, wenn Sie entscheiden, ob Sie ein Bild- oder Hintergrundbildfeld verwenden möchten. Wenn der Content-Autor die Möglichkeit haben soll, ALT-Text hinzuzufügen, verwenden Sie ein Bildfeld anstelle eines Hintergrundbildfeldes."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Felder organisieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Organisieren von Feldern spielt eine wichtige Rolle dafür, dass der Content-Autor schnell Änderungen vornehmen kann. Nachfolgend finden Sie empfohlene Best Practices für die Organisation von Feldern:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Gruppieren Sie Stilfelder basierend auf der Komponente, die sie steuern, und nicht auf der Grundlage des stilistischen Elements."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Lassen Sie nur die übergeordneten, wichtigsten Felder außerhalb von Gruppen."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Bevorzugen Sie das Erstellen einer Komponentengruppe gegenüber nicht verschachtelten Gruppen. Wenn Sie Ihrem Modul später Funktionen hinzufügen müssen, können Sie Ihre Module später nicht in eine Gruppe verschieben, ohne alle Instanzen des Moduls auf Seiten manuell zu aktualisieren."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Ordnen Sie die Feldgruppen in der Reihenfolge an, in der die Komponenten basierend auf der primären Sprache der Mehrheit der Content-Autoren angezeigt werden, die die Website warten. Beispiel: Englisch wird von links nach rechts, von oben nach unten gelesen. Wenn die Benutzer, die die Website warten, normalerweise von rechts nach links in ihrer Sprache lesen, sollten Sie sie in dieser Reihenfolge bereitstellen. Im Zweifelsfall sollten Sie sich dabei auf die primäre Sprache der Inhalte Ihrer Website stützen."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#field-groups",
        children: "Feldgruppen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Beispiel"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden finden Sie ein Beispiel für ein Kartenmodul:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/card%20and%20style%20settings.png",
        alt: "Ein typisches Kartemlayout, das ein Symbol, Text und eine Schaltfläche enthält. Formatierungsoptionen werden auf der rechten Seite gruppiert in den Kategorien „Symbol“, „Schaltfläche“ und „Karte“ angezeigt."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Bereich für Stile gruppiert die Modulfelder in 3 Gruppen, basierend auf Komponenten in der Karte, die formatiert werden können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Symbol"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Schaltfläche"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Karte"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Anzeigen der Karte wird zuerst das Symbol angezeigt, dann der Text und die Schaltfläche. Die Gruppe „Symbol“ und ihreFormatierungsoptionen werden vor der Gruppe „Schaltfläche“ und ihren Formatierungsoptionen angezeigt. Daher würde das Farbfeld für das Symbol in der Gruppe ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Symbol"
      }), " zu finden sein, während das Farbfeld für die Hintergrundfarbe der Schaltfläche in der Gruppe ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Schaltfläche"
      }), " zu finden wäre."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erforderliche Felder"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pflichtfelder sind Felder, die der Content-Autor festlegen muss, um das Modul anzuzeigen und die Seite zu veröffentlichen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Legen Sie das Vorhandenseins eines Werts für Felder nur dann als erforderlich fest, wenn das Modul dadurch fehlerhaft wird, wenn es keinen Wert hat."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie ein erforderliches Feld benötigen, geben Sie, wenn möglich, einen Standardwert an."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beispiel: Sie erstellen ein Bildkarussellmodul, mit dem Sie konfigurieren können, wie viele Folien gleichzeitig angezeigt werden sollen. Es wird ein Mindestwert von 1 benötigt, und ohne einen Wert wissen Sie nicht, wie das Bildkarussell angezeigt werden soll. In dieser Situation kann es sinnvoll sein, einen Wert zu verlangen, aber einen oder zwei Standardwerte festzulegen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Typografie"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Da Rich-Text Felder mehr typografische Kontrolle bieten, empfiehlt es sich, Rich-Text Felder nach Möglichkeit einer Kombination aus Textfeld und Schriftartfeld vorzuziehen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es kann vorkommen, dass Sie typografische Stile angeben müssen, die für mehrere Content-Elemente innerhalb desselben Moduls gelten, z. B. ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#text",
        children: "Textfeld"
      }), ", das für Überschriften gedacht ist. In diesem Fall können Sie die Arbeit des Content-Autors möglicherweise effizienter gestalten, indem Sie die Stilfelder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#font",
        children: "Schriftart"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#color",
        children: "Farbe"
      }), " im Textfeld angeben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beim Einfügen von Rich-Text-Feldern wird empfohlen, dass die typografischen Steuerelemente des Feldes manuell hinzugefügte Stilfelder überschreiben. Wenn ein Stilfeld ein Rich-Text Feld steuert, kann es sich lohnen, Hilfetext festzulegen, um sicherzustellen, dass der Content-Autor sich dessen bewusst ist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Schalter vs. Kontrollkästchen in booleschen Feldern"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#boolean",
        children: "boolesches Feld"
      }), " berücksichtigen, können Sie festlegen, dass es entweder als Schalter oder als Kontrollkästchen angezeigt wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Legen Sie den booleschen Wert auf einen Schalter fest, wenn das Feld eine wichtige Design- oder Layoutoption steuert. Zum Beispiel das Konvertieren des Layouts einer Karte von vertikaler in eine horizontale Ausrichtung."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Legen Sie den booleschen Wert auf ein Kontrollkästchen fest, wenn die Änderung weniger drastisch ist. Zum Beispiel das Ausblenden oder Anzeigen eines Veröffentlichungsdatums für einen Blogbeitrag in einem Blogbeiträgemodul."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwandte Artikel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields",
          children: "Modul- und Designfeldtypen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "Überblick über Modul- und Designfelder"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Überblick über Module"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/overview",
          children: "Übersicht über Designs"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Tutorials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Erste Schritte mit Modulen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Erste Schritte mit Designs"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}