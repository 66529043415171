"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044614;
const slug = exports.slug = 'guides/cms/content/multi-language-content';
const title = exports.title = 'Contenu multilingue';
const name = exports.name = 'EMEA FR Multi-language';
const metaDescription = exports.metaDescription = 'Les développeurs qui créent des sites web sur HubSpot peuvent facilement concevoir un site web multilingue. Certaines étapes de développement peuvent être nécessaires en fonction de vos propres activités.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "fonctionnalit%C3%A9s-multilingues-par-d%C3%A9faut-de-hubspot",
  "label": "Fonctionnalités multilingues par défaut de HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "ce-que-hubspot-ne-fait-pas",
  "label": "Ce que HubSpot ne fait pas",
  "parent": "fonctionnalit%C3%A9s-multilingues-par-d%C3%A9faut-de-hubspot"
}, {
  "depth": 0,
  "id": "d%C3%A9finir-des-variables-de-langue",
  "label": "Définir des variables de langue",
  "parent": null
}, {
  "depth": 0,
  "id": "utiliser-des-modules-modifiables-pour-chaque-page",
  "label": "Utiliser des modules modifiables pour chaque page",
  "parent": null
}, {
  "depth": 0,
  "id": "inclure-les-traductions-de-champs-dans-des-modules-personnalis%C3%A9s-et-des-th%C3%A8mes",
  "label": "Inclure les traductions de champs dans des modules personnalisés et des thèmes",
  "parent": null
}, {
  "depth": 1,
  "id": "d%C3%A9veloppement-local",
  "label": "Développement local",
  "parent": "inclure-les-traductions-de-champs-dans-des-modules-personnalis%C3%A9s-et-des-th%C3%A8mes"
}, {
  "depth": 1,
  "id": "gestionnaire-de-conception",
  "label": "Gestionnaire de conception",
  "parent": "inclure-les-traductions-de-champs-dans-des-modules-personnalis%C3%A9s-et-des-th%C3%A8mes"
}, {
  "depth": 0,
  "id": "traduire-des-pages-syst%C3%A8me",
  "label": "Traduire des pages système",
  "parent": null
}, {
  "depth": 0,
  "id": "inclure-un-s%C3%A9lecteur-de-langue",
  "label": "Inclure un sélecteur de langue",
  "parent": null
}, {
  "depth": 0,
  "id": "appliquer-la-recherche-sur-des-sites-web-multilingues",
  "label": "Appliquer la recherche sur des sites web multilingues",
  "parent": null
}, {
  "depth": 0,
  "id": "utiliser-des-modules-et-des-partiels-globaux",
  "label": "Utiliser des modules et des partiels globaux",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      h3: "h3",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Contenu multilingue"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Toute entreprise qui opère dans plusieurs pays ou avec une clientèle qui parle plusieurs langues doit être en mesure d'échanger avec son public dans la langue de celui-ci. Avec le CMS de HubSpot, les utilisateurs peuvent ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cos-general/how-to-manage-multi-language-content-with-hubspots-cos",
        children: "créer des variantes multilingues"
      }), " de leur contenu qui permettent à l'utilisateur final de visualiser le contenu dans une langue qu'il comprend."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot met automatiquement en place un certain nombre de facettes d'un site web multilingue pour vous. Cependant, des étapes sont indispensables pour les développeurs afin de s'assurer que leur site web est disponible en plusieurs langues."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fonctionnalités multilingues par défaut de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsqu'une variante multilingue est créée pour une page dans HubSpot, nous procédons automatiquement aux opérations suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "création d'une nouvelle entrée dans le plan du site XML indiquant le nom et l'URL de la page traduite ;"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["spécification de la langue du contenu dans la page ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "<head>"
          }), " pour les modèles créés à l'aide de la fonctionnalité de glisser-déposer ;"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["identification d'autres pages au sein du groupe de contenu multilingue suivant le format normalisé approprié, qui marque les autres pages comme des alternatives pour éviter les erreurs de contenu en double et identification du code ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes",
            children: "ISO 639-1"
          }), " associé à la (aux) traduction(s) de la langue :"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "<link rel=\"alternate\" hreflang=\"[**_lang_code_**](https://support.google.com/webmasters/answer/189077#language-codes)\" href=\"_url_of_page_\" />"
          }), " ;"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "réécriture des liens sur les pages de langue pour accéder aux versions intra-langue de la page liée afin de s'assurer que les visiteurs restent dans la même langue et d'éviter d'avoir à mettre à jour chaque lien sur chaque traduction de page. Pour un élément donné, vous pouvez désactiver cette réécriture en ajoutant la classe \"hs-skip-lang-url-rewrite\" à l'élément."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ce que HubSpot ne fait pas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avec CMS Hub, HubSpot n'effectue pas automatiquement les opérations suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "traduction du contenu de la page pour vous ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "redirection des utilisateurs vers une variante multilingue selon GeoIP ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "intégration d'un module de sélection de langue dans votre en-tête ou site web ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "définition de la langue d'une page pour les fichiers codés ;"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "définition de l'attribut directionnel de contenu pour les traductions dans une langue qui se lit de droite à gauche plutôt que de gauche à droite pour les fichiers codés."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Définir des variables de langue"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Étant donné que les fichiers codés n'incluent pas automatiquement les déclarations de langue ou les attributs directionnels de langue de contenu, ceux-ci devront être configurés manuellement pour les modèles codés. Les variables de langue peuvent être définies en HTML ou renseignées via HubL, comme dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html#L2",
        children: "modèle du boilerplate CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La définition de ces propriétés à l'aide de HubL permettra à ces données d'être renseignées dynamiquement dans le code HTML d'une page en fonction de la langue définie pour la page dans CMS Hub."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<html lang=\"{{ html_lang }}\" {{ html_lang_dir }}>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utiliser des modules modifiables pour chaque page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Afin de s'assurer que le contenu peut être localisé à travers chaque instance d'utilisation d'un modèle, tirez parti de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules personnalisés"
      }), " à la place du contenu HTML codé en dur dès que possible. La création de modules pouvant être modifiés au niveau de la page permettra aux créateurs de contenu de définir du contenu spécifique qui apparaîtra sur chaque page sans avoir à modifier le code de modèle. Cela permet également d'utiliser un contenu unique entre des pages issues du même modèle."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Inclure les traductions de champs dans des modules personnalisés et des thèmes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour soutenir votre équipe internationale, vous pouvez publier des traductions des modules que vous avez créés dans HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois que vous avez traduit le contenu du module et l'avez publié dans les langues des membres de votre équipe, les utilisateurs verront les libellés de champs pour ce module dans leur langue par défaut de compte. Remarque : Le contenu d'un module traduit ne sera pas traduit automatiquement ; vous devrez le faire vous-même. Vous pouvez créer des traductions de votre module dans n'importe quelle langue."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez définir des traductions à l'aide des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#local-module-file-structure",
        children: "outils de développement local"
      }), " ou via le gestionnaire de conception."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Développement local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour définir des traductions à l'aide d'outils de développement local, chaque dossier de module et de thème peut contenir un dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_locales"
      }), ", avec des sous-dossiers de paramètres de langue, chacun avec un fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "messages.json"
      }), " contenant des traductions de champ de module."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/local%20module%20translations.png",
        alt: "Capture d'écran de la modification des traductions des modules localement plutôt qu'avec un code"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Gestionnaire de conception"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour définir des traductions de champs via le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "gestionnaire de conception"
      }), ", lorsque vous affichez le module, accédez à l'option « Ajouter des traductions » à droite de l'écran. Sélectionnez les langues de travail de votre équipe dans le menu déroulant. À partir de là, vous pouvez sélectionner chaque langue et spécifier les conventions d'appellation pour chaque champ dans chaque langue."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/field_translations.gif",
        alt: "Capture d'écran des traductions de champs dans le gestionnaire de conception"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Les traductions de champs de thème n'ont pas d'interface dans le gestionnaire de conception et doivent être modifiées via les fichiers ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".json"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Traduire des pages système"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour configurer des traductions pour les pages système, y compris les pages de réinitialisation de mot de passe et d'abonnement aux e-mails, vous pouvez personnaliser les champs de module personnalisé et de balise HubL avec votre contenu traduit. Découvrez-en davantage sur les champs disponibles pour les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "modules"
      }), " et les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#system-page-tags",
        children: "balises HubL de page système"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Inclure un sélecteur de langue"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour permettre aux utilisateurs finaux de basculer entre les traductions disponibles, il est conseillé d'ajouter un module de sélection de langue à votre site."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/09ad0b2c3bbc8400e64c5617268cb0504392e8e5/src/templates/partials/header.html#L26-L47",
        children: "exemple de la façon d'implémenter un sélecteur de langue"
      }), " est disponible dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "boilerplate du thème CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Header navigation row one #}\n      <div class=\"header__row-1\">\n        {% if content.translated_content.values()|selectattr('published')|length || is_listing_view && group.translations %}\n          <div class=\"header__language-switcher header--element\">\n            <div class=\"header__language-switcher--label\">\n              {% module 'language-switcher' path='@hubspot/language_switcher',\n                label='Language switcher',\n                display_mode='localized'\n              %}\n              <div class=\"header__language-switcher--label-current\"> {{ locale_name(locale) }}</div>\n            </div>\n          </div>\n        {% endif %}\n        <div class=\"header__search header--element\">\n          {% module 'site_search' path='@hubspot/search_input',\n            label='Search',\n            field_label='Search',\n            placeholder=''\n          %}\n        </div>\n      </div>\n      {# End header navigation row one #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Appliquer la recherche sur des sites web multilingues"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-search",
        children: "recherche de contenu"
      }), " prend en charge la recherche de contenu dans les différentes langues de votre site. Le paramètre de filtre de langue peut être utilisé lorsque vous appuyez sur", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "/contentsearch/v2/search"
      }), " pour renvoyer des résultats dans les langues spécifiées uniquement. Ainsi, vous pouvez créer des expériences de recherche pour chaque langue de votre site web ou permettre aux visiteurs de rechercher dans plusieurs langues sur votre site web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utiliser des modules et des partiels globaux"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez les champs de module pour restituer le texte modifiable dans les en-têtes, les pieds de page et les barres latérales. Placez ces modules dans des partiels globaux. Non seulement les créateurs de contenu bénéficieront de la facilité d'édition, mais les partiels globaux prendront en charge la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/cos-general/how-to-manage-multi-language-content-with-hubspots-cos#edit-global-content-in-a-multi-language-page",
        children: "configuration de leurs paramètres pour chaque langue"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/edit-multilanguage-global-content.png",
        alt: "Capture d'écran de l'éditeur de page montrant le partiel d'en-tête"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}