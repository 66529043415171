"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611094;
const slug = exports.slug = 'guides/cms/tools/design-manager';
const title = exports.title = 'Design-Manager';
const name = exports.name = '[neu] Design-Manager';
const metaDescription = exports.metaDescription = 'Der Design-Manager ist eine webbasierte integrierte Entwicklungsumgebung, die optional zum Erstellen von Vorlagen, Modulen, CSS und JavaScript verwendet werden kann.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/module%20editor.png';
const featuredImageAltText = exports.featuredImageAltText = 'Der Design-Manager';
const toc = exports.toc = [{
  "depth": 0,
  "id": "design-manager-einstellungen",
  "label": "Design-Manager-Einstellungen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      img: "img",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Design-Manager"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Design-Manager ist eine webbasierte integrierte Entwicklungsumgebung, die optional zur Erstellung von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "Vorlagen"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Modulen"
      }), ", CSS und JavaScript verwendet werden kann. Den Design-Manager finden Sie, wenn Sie in der oberen Navigationsleiste zu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/design-manager/",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing > Dateien und Vorlagen > Design-Manager"
        })
      }), " navigieren."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Wenn Sie es vorziehen, lokal mit Ihrem bevorzugten Code-Editor, Workflows und Technologien zu entwickeln, können Sie stattdessen die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CMS CLI"
        }), " verwenden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Unabhängig davon, ob Sie lokal entwickeln oder den Design-Manager für die Erstellung von Ressourcen nutzen, wird das gesamte Entwickler-Dateisystem Ihres Accounts im Design-Manager dargestellt und kann dort bearbeitet werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/designmanager%203-20-2020.png",
        alt: "Screenshot des HubSpot-Design-Managers"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Einige Entwickler oder Teams entscheiden sich für einen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "Workflow, der auf eine Mischung aus Design-Manager und lokaler Entwicklung"
      }), " setzt. Sie können den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch"
        })
      }), "-Befehl des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), " verwenden, um Design-Manager-Bearbeitungen in Ihre lokale Umgebung zu übertragen. Alternativ können Sie ein Bearbeitn verhindern und Ihre gesamte Codebasis ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "kontinuierlich in die Versionskontrolle"
      }), " integrieren lassen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sehen Sie sich diesen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager",
        children: "Artikel der Wissensdatenbank"
      }), " an, um einen umfassenden Überblick über den Design-Manager und seine verschiedenen Komponenten zu erhalten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Design-Manager-Einstellungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Design-Manager verfügt über grundlegende IDE-Einstellungen, die Sie so konfigurieren können, dass sie zu Ihnen und Ihren Workflows passen. Neben der Hilfe-Schaltfläche in der unteren Leiste erscheint eine Einstellungen-Schaltfläche, mit der Sie die Einstellungen konfigurieren können."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "Sie können Einstellungen konfigurieren für:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Editor-Design"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Schriftart"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Tabulator-Größe"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Einheit des Einzugs"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Soft-Tabs"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Intelligenter Einzug"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Einzug bei Formatierung der Eingabe"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Einzug mit Tabulatoren"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Zeilenumbruch"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Klammern automatisch schließen"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Klammern abgleichen"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Tags abgleichen"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "ESLint"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["und mehr – eine genauere Aufschlüsselung der Einstellungen finden Sie in ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://knowledge.hubspot.com/cos-general/customize-design-manager-settings",
                children: "diesem Artikel der Wissensdatenbank"
              }), "."]
            }), "\n"]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Wenn Ihnen diese Funktionen nicht genügen, empfehlen wir Ihnen, unsere ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/local-development-cli",
              children: "Tools für die lokale Entwicklung"
            }), " und unseren ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/creating-an-efficient-development-workflow",
              children: "Workflow"
            }), " auszuprobieren."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/Design%20Manger%20Settings-1.png",
            alt: "Screenshot des Bildschirms mit den Einstellungen des Design-Managers"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}