"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694140;
const slug = exports.slug = 'reference/cms/hubl/tags/dnd-areas';
const title = exports.title = 'Drag and Drop Areas HubL Tags';
const name = exports.name = 'Drag and Drop Area HubL tags';
const metaDescription = exports.metaDescription = 'The HubL tags used to build drag and drop areas that allow developers to create sections of pages that support layout, stylistic and content changes directly within the content editors.';
const position = exports.position = 2;
const toc = exports.toc = [{
  "depth": 0,
  "id": "dnd_area",
  "label": "dnd_area",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_section",
  "label": "dnd_section",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_column",
  "label": "dnd_column",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_row",
  "label": "dnd_row",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_module",
  "label": "dnd_module",
  "parent": null
}, {
  "depth": 0,
  "id": "background",
  "label": "Background",
  "parent": null
}, {
  "depth": 1,
  "id": "background_color",
  "label": "background_color",
  "parent": "background"
}, {
  "depth": 1,
  "id": "background_linear_gradient",
  "label": "background_linear_gradient",
  "parent": "background"
}, {
  "depth": 1,
  "id": "background_image",
  "label": "background_image",
  "parent": "background"
}, {
  "depth": 0,
  "id": "how-dnd-style-parameters-translate-to-the-page",
  "label": "How dnd style parameters translate to the page",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      ul: "ul",
      li: "li",
      em: "em",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      HubspotVideoPlayer,
      Alert,
      Tabs,
      Tab,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Drag and Drop Area HubL tags"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Drag and drop areas"
      }), " allow developers to create sections of pages and global partials that support layout, stylistic and content changes directly within the content editors. See the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "creating a drag and drop area tutorial"
      }), " for an introduction to setting up drag and drop areas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Drag and drop areas are based on a 12 column responsive grid. Drag and drop tags render markup with class names designating columns and rows. You'll need to add a stylesheet to target those class names. An example of layout styles you could implement can be found in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "HubSpot CMS Boilerplate"
      }), ". Your stylesheet can be added to the template using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#require-css",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ require_css() }}"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
      videoId: "172854037902"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " drag and drop areas can't be used in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/blog",
          children: "blog post"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#email",
          children: "email templates"
        }), " at this time."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_area"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "drag and drop area"
      }), " is a container that makes a portion of the web page editable in terms of its structure, design, and content. The body of a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), " tag supplies the default content for the drag and drop area."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Modules themselves cannot contain drag and drop areas. To provide content creators an interface for adding uniform content within a module, use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#repeaters",
        children: "repeatable fields and groups"
      }), " instead."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " tag can contain the following parameters:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "class"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A class added to the wrapping div of a dnd_area"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Used in the editor to label the area in the sidebar."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_area \"unique_name\", class=\"main\" %}\n\n{% end_dnd_area %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"container-fluid main\">\n  <div class=\"row-wrapper\">\n    <div class=\"row-fluid\">\n      <div\n        class=\"span12 widget-span widget-type-cell \"\n        style=\"\"\n        data-widget-type=\"cell\"\n        data-x=\"0\"\n        data-w=\"12\"\n      ></div>\n    </div>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " tags can also contain ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " tags."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " a content creator can swap a page's template for another template of the same type, depending on whether it has ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), " tags."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Templates built with the visual drag and drop layout editor can be swapped for other drag and drop templates or coded templates with or without ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " tags."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Coded templates with ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " tags can only be swapped for other coded templates with ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " tags."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Coded templates ", (0, _jsxRuntime.jsx)("u", {
            children: "without"
          }), " ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " tags can only be swapped for other coded templates without ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), " tags."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_section"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_section %}"
        })
      }), " is a top-level row, and must be nested within a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), " tag. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#section-templates",
        children: "Sections can also be defined as a template"
      }), ", and then ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#section-context",
        children: "included"
      }), " into a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ", making them ideal for quickly scaffolding out a template."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " tag can contain the following parameters:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "background color"
            }), ". Can also be provided as a string."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "background image"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "linear gradient background"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "full_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A boolean which determines if the section is intended to be full width or constrained by an inner container."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying margin values in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". When no unit of measure is provided, the default of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " is applied."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A pixel value which sets a content max-width on a wrapping dict."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying padding values in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". When no unit of measure is provided, the default of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " is applied."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Vertical alignment of child content. Available options include:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you can only use one background parameter per ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), " tag."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_section\n  background_image={\n    \"backgroundPosition\": \"MIDDLE_CENTER\",\n    \"backgroundSize\": \"cover\",\n    \"imageUrl\": \"https://example.com/path/to/image.jpg\"\n  },\n  margin={\n    \"top\": 32,\n    \"bottom\": 32\n  },\n  padding={\n    \"top\": \"1em\",\n    \"bottom\": \"1em\",\n    \"left\": \"1em\",\n    \"right\": \"1em\"\n  },\n  max_width=1200,\n  vertical_alignment=\"MIDDLE\"\n%}\n\n{% end_dnd_section %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"row-fluid-wrapper row-depth-1 row-number-1 unique_name-row-0-background-image dnd-section unique_name-row-0-max-width-section-centering unique_name-row-0-margin unique_name-row-0-padding\"\n>\n  <div class=\"row-fluid \"></div>\n  <!--end row-->\n</div>\n<!--end row-wrapper -->\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " tags can also contain the following tags:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_column"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#column",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_column %}"
        })
      }), " is a vertical structural building block that occupies one or more layout columns defined by its parent row."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This HubL tag must be nested within a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), " tag."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), " tag can contain the following parameters:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "background color"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "background image"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "linear gradient background"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying margin values in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". When no unit of measure is provided, the default of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " is applied."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A pixel value which sets a content max-width on a wrapping dict."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying padding values in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". When no unit of measure is provided, the default of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " is applied."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Vertical alignment of child content. Available options include:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you can only use one background parameter per ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), " tag."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_column\n  offset=0,\n  width=12,\n  background_color={\n    r: 255,\n    g: 0,\n    b: 0,\n    a: 1\n  },\n  margin={\n    \"top\": \"1em\",\n    \"bottom\": \"1em\"\n  },\n%}\n\n{% end_dnd_column %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"span12 widget-span widget-type-cell unique_name-column-1-margin unique_name-column-1-background-color unique_name-column-1-vertical-alignment dnd-column\"\n  style=\"\"\n  data-widget-type=\"cell\"\n  data-x=\"0\"\n  data-w=\"12\"\n></div>\n<!--end widget-span -->\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), " tag can also contain ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_row"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#row",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_row %}"
        })
      }), " is a horizontal structural building block that creates a nested 12-column layout grid in which columns and modules can be placed."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This HubL tag must be nested within a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), " tag."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), " tag can include the following parameters:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "background color"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "background image"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "linear gradient background"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying margin values in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". When no unit of measure is provided, the default of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " is applied."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A pixel value which sets a content max-width on a wrapping dict."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dict which supports specifying padding values in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), ". When no unit of measure is provided, the default of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " is applied."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Vertical alignment of child content. Available options include:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you can only use one background parameter per ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), " tag."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_row\n  background_color={\n    r: 123,\n    g: 123,\n    b: 123,\n    a: 1.0\n  },\n  margin={\n    \"top\": 20,\n    \"bottom\": 200\n  },\n  padding={\n    \"top\": 20,\n    \"bottom\": 200,\n    \"left\": 20,\n    \"right\": 20\n  }\n%}\n\n{% end_dnd_row %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"row-fluid-wrapper row-depth-1 row-number-1 main-row-0-padding main-row-0-background-color main-row-0-margin\"\n>\n  <div class=\"row-fluid \"></div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A dnd_row can also contain the following tags:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#module",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_module %}"
        })
      }), " is a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "module"
      }), " wrapped within a div where layout, styles and content can be added. The module is specified by referencing its path, which can either be a HubSpot default module (using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/"
      }), " namespace), or modules you have built, specifying their path within the design manager file tree."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This HubL tag must be nested within a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), " tag."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " tag can contain the following parameters:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The path to a module."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Horizontal positioning, supports:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LEFT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CENTER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RIGHT"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The offset from 0 in the 12 column grid."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The number of columns occupying the 12 column grid."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "flexbox_positioning"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Deprecated"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Deprecated do not use."
            }), " Instead, use ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal_alignment"
            }), " in tandem with the row or section's ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            }), " instead.", (0, _jsxRuntime.jsx)("br", {}), "Flexbox position value for the module. Supported a string indicating vertical position followed by horizontal:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_RIGHT"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Have an old module which has a field name that matches one of the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " parameters above? You can ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates#passing-fields-that-have-dnd-associated-parameters",
          children: "pass default values through a fields parameter"
        }), ", much like you would a field group."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_module\n  path=\"@hubspot/rich_text\",\n  offset=0,\n  width=8,\n%}\n  {% module_attribute \"html\" %}\n    <h1>Hello, world!</h1>\n  {% end_module_attribute %}\n{% end_dnd_module %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"span8 widget-span widget-type-custom_widget\"\n  style=\"\"\n  data-widget-type=\"custom_widget\"\n  data-x=\"0\"\n  data-w=\"12\"\n>\n  <div\n    id=\"hs_cos_wrapper_main-module-1\"\n    class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module\"\n    style=\"\"\n    data-hs-cos-general-type=\"widget\"\n    data-hs-cos-type=\"module\"\n  >\n    <span\n      id=\"hs_cos_wrapper_module-1_\"\n      class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text\"\n      style=\"\"\n      data-hs-cos-general-type=\"widget\"\n      data-hs-cos-type=\"rich_text\"\n    >\n      <h1>Hello, world!</h1>\n    </span>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Background"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There are a few ways to set backgrounds on column, section and row dnd elements, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-image",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_image"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-linear-gradient",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_linear_gradient"
        })
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-color",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_color"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_color"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The column, section, and row dnd tags support background colors. You can set the default background color for a drag and drop element using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_color"
      }), ". This parameter is a string based parameter and can include the following formats outlined in the example below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section %}\n  // Hex Value (both 3 and 6 char length)\n  {% dnd_column background_color=\"#F7F7F7\" %}\n  {% end_dnd_column %}\n  {% dnd_column background_color=\"#FFF\" %}\n  {% end_dnd_column %}\n// Both RGB and RGBA\n  {% dnd_column background_color=\"rgb(255,255,255)\" %}\n  {% end_dnd_column %}\n  {% dnd_column background_color=\"rgba(0,0,0,.25)\" %}\n  {% end_dnd_column %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_linear_gradient"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The column, section and row dnd elements support background linear gradients. You can set a default gradient using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_linear_gradient"
      }), " parameter. The parameter expects a dict. Currently only supports two color stops."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "direction"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The direction of the gradient.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to bottom"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to top"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to left"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to right"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "colors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Array of color strings. Currently supports 2 values, the start and end. Values are provided as strings, and the following formats are supported:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "RGB"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "RGBA"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "3 char hex"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "6 char hex"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "8 char hex"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n  background_linear_gradient={\n    \"direction\": \"to bottom\",\n    \"colors\": [\n      \"#1EB6C3\",\n      \"#2A2859\"\n    ]\n  }\n  %}\n  {% dnd_module path=\"@hubspot/rich_text\" width=\"6\" %}\n  {% end_dnd_module %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_image"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The column, section and row dnd elements support background images. You can provide a default background image by using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_image"
      }), " parameter which expects a dict."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Key"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "backgroundPosition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The background position of the image. Supports a string indicating vertical position followed by horizontal.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_RIGHT"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "backgroundSize"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The CSS background size property used for the image.", (0, _jsxRuntime.jsx)("br", {}), "Supported values are:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "cover"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "contain"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "auto"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "imageUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Absolute URL to the image."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n  background_image = {\n      \"backgroundPosition\": \"MIDDLE_CENTER\",\n      \"backgroundSize\": \"cover\",\n      \"imageUrl\": \"https://www.example.com/bg-image.jpg\"\n    },\n%}\n  {% dnd_module path=\"@hubspot/rich_text\" width=\"6\" %}\n  {% end_dnd_module %}\n\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "How dnd style parameters translate to the page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you are using style based parameters such as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background",
        children: "backgrounds"
      }), ", margins, or padding, the class names are automatically computed for your sections, columns, rows, and modules. The property values you have assigned are then added to those automatically created class names and the resulting CSS code is then placed before the closing ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</body>"
      }), " tag on the page in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), " tag."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/responsive-breakpoints",
        children: "Drag and drop styles can also be different at different breakpoints"
      }), " to offer a responsive look."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}