'use es6';

import { createSelector } from 'reselect';
import extensions from '../../../lib/api-explorer/oas-extensions';
import { getSpecificationWithExtensions } from './DocSelectors';
import { LANGUAGE } from '../constants/LocalStorageKeys';
import * as ProtectedLocalStorage from 'developer-experience-shared-components/utils/protectedLocalStorage';
export const getDefaultLanguage = createSelector([getSpecificationWithExtensions], spec => {
  const localLanguage = ProtectedLocalStorage.getItem(LANGUAGE);
  if (localLanguage) {
    return localLanguage;
  }
  try {
    const firstOas = Object.keys(spec)[0];
    return spec[firstOas][extensions.SAMPLES_LANGUAGES][0];
  } catch (e) {
    return 'curl';
  }
});