"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 142116065450;
const slug = exports.slug = 'guides/api/analytics-and-events/custom-events/custom-event-definitions';
const title = exports.title = 'カスタムイベントの定義';
const name = exports.name = 'カスタムイベントの定義';
const metaDescription = exports.metaDescription = 'HubSpotのAPIを使用してカスタムイベントを作成する方法の詳細をご確認ください。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%AE%9A%E7%BE%A9%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "イベント定義を作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "イベントプロパティー",
  "parent": null
}, {
  "depth": 1,
  "id": "hubspot%E3%81%AE%E6%97%A2%E5%AE%9A%E3%81%AE%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "HubSpotの既定のイベントプロパティー",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC"
}, {
  "depth": 1,
  "id": "%E6%96%B0%E3%81%97%E3%81%84%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E5%AE%9A%E7%BE%A9%E3%81%99%E3%82%8B",
  "label": "新しいプロパティーを定義する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC"
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AE%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "既存のカスタムプロパティーを更新する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC"
}, {
  "depth": 1,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "プロパティーを削除する",
  "parent": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC"
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "イベントを更新する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "イベントを削除する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AE%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%AE%9A%E7%BE%A9%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "既存のイベント定義を取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%81%AE%E4%BE%8B",
  "label": "カスタムイベントの例",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%AE%9A%E7%BE%A9%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "カスタムイベント定義を作成する",
  "parent": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%81%AE%E4%BE%8B"
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E5%AE%9A%E7%BE%A9%E3%81%A7%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "カスタムイベント定義でプロパティーを作成する",
  "parent": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%81%AE%E4%BE%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      ul: "ul",
      li: "li",
      pre: "pre",
      h3: "h3",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      ProductTier,
      Table,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "カスタムイベントの定義"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'sales_hub-enterprise', 'cms-enterprise', 'service_hub-enterprise', 'operations_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カスタムイベントを使用すれば、サイト上でのイベントやアプリ内のイベントなど、ビジネスに固有のイベントを定義して追跡できます。情報をプロパティーに格納するようにイベントを構成し、そのイベントをHubSpotのツール全体で使用することができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "カスタムイベント"
      }), "のデータをHubSpotに送信するには、まずイベントを定義する必要があります。カスタムCRMオブジェクトと同様に、最初にカスタムオブジェクトを定義してからでないと、そのオブジェクトに関する個々のレコードを作成できません。イベント定義には、イベントのメタデータ、CRMオブジェクトの関連付け、プロパティーなどの詳細が含まれます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下にて、イベントAPIを使用してイベント定義を作成し、管理する方法をご説明します。APIを使用せずにイベント定義を作成する方法については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events-with-the-code-wizard",
        children: "HubSpotのナレッジベース"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベント定義を作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタム イベント スキーマを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions"
      }), "に送信します。リクエスト本文に、イベントスキーマの定義（イベントのラベル、名前、CRMオブジェクトの関連付け、カスタムプロパティーなど）を含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下に、パラメーターの定義と併せてリクエスト本文の例を示します。完全な例については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#custom-event-example",
        children: "「カスタムイベントの例」のセクション"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "label"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), "（必須）"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "HubSpotに表示する、人間が読んで理解できるイベントのラベル（最大100文字）。HubSpotのUIの特定の部分では、文字数の多いラベルが切り詰められる場合があります。"
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "name"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["イベントの一意の内部名。APIを介してイベントを参照する際は、この内部名を使用します。値が指定されていない場合、HubSpotは", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "label"
                    }), "に基づいて自動的に値を生成します。イベント定義が作成された後にこのプロパティーを変更することは", (0, _jsxRuntime.jsx)("u", {
                      children: "できません"
                    }), "。"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "命名には次の要件が適用されることに留意してください。"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "このプロパティーには、小文字の英字、数字、アンダースコア、ハイフンのみ使用できます（最大50文字）。"
                    }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "最初の文字は英字でなければなりません。"
                    }), "\n"]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "description"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "HubSpotに表示するイベントの説明。"
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "primaryObject"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["イベントを関連付けるCRMオブジェクト。イベント完了は、指定したオブジェクトのCRMレコードタイムラインに表示されます。", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "CONTACT"
                    }), "、", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "COMPANY"
                    }), "、", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "DEAL"
                    }), "、", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "TICKET"
                    }), "、", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "CUSTOM OBJECT NAME"
                    }), "のいずれかを指定できます。"]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["値が指定されていない場合、HubSpotは自動的にイベントをコンタクトに関連付けます。イベント定義が作成された後にこのプロパティーを変更することは", (0, _jsxRuntime.jsx)("u", {
                      children: "できません"
                    }), "。"]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "propertyDefinitions"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "array"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "HubSpotではカスタムイベント定義ごとに、イベントデータの送信時に使用できる既定のプロパティー一式を用意しています。これらの既定のプロパティー以外のプロパティーがイベントに必要な場合は、この配列を含めて、それらのカスタムプロパティーを定義します。イベントごとに最大50個のカスタムプロパティーを含めることができます。"
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "各プロパティーには、次のフィールドを含めます。"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "label"
                      }), "：プロパティーのアプリ内ラベル。"]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "name"
                      }), "：APIを介してプロパティーデータを送信するときに使用する、プロパティーの内部名。"]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "type"
                      }), "：", (0, _jsxRuntime.jsx)(_components.a, {
                        href: "#custom-property-types",
                        children: "プロパティーの型"
                      }), "。既定の値は", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "string"
                      }), "です。"]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "options"
                      }), "：", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "enumeration"
                      }), "型プロパティーの場合、定義済みの値の配列。定義済みの値はそれぞれ", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "label"
                      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
                        children: "value"
                      }), "からなります。"]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: [(0, _jsxRuntime.jsx)(_components.code, {
                        children: "description"
                      }), "：プロパティーを説明するオプションのテキスト。"]
                    }), "\n"]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.a, {
                      href: "#event-properties",
                      children: "カスタム イベント プロパティー"
                    }), "の詳細については以下をご覧ください。"]
                  })]
                })
              })]
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST request body\n{\n  \"label\": \"My event label\",\n  \"name\": \"unique_event_name\",\n  \"description\": \"An event description that helps users understand what the event tracks.\",\n  \"primaryObject\": \"COMPANY\",\n  \"propertyDefinitions\": [\n    {\n      \"name\": \"choice-property\",\n      \"label\": \"Choice property\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"label\": \"Option 1\",\n          \"value\": \"1\"\n        },\n        {\n          \"label\": \"Option 2\",\n          \"value\": \"2\"\n        }\n      ]\n    },\n    {\n      \"name\": \"string-property\",\n      \"label\": \"String property\",\n      \"type\": \"string\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベントプロパティー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カスタム イベント プロパティーは、個々のカスタムイベント完了に関する情報を格納するために使用します。イベント完了を送信する際に適切であれば、カスタム イベント プロパティーを使用すべきですが、イベント完了を有効にするために、これらのプロパティーが必須となるわけではありません。HubSpotではイベント定義ごとに、32個の既定のプロパティーを用意しています。既定のプロパティーに加え、イベント定義ごとに最大50個のカスタムプロパティーを含めることができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プロパティーには、次のいずれかの型を指定できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "string"
        }), "：プレーンテキスト文字列を受け取るプロパティー。プロパティー名に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "url"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "referrer"
        }), "、または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "link"
        }), "という単語が含まれる場合、プロパティー値は最大1,024文字にすることができます。それ以外の場合、プロパティー値は最大256文字に制限されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "number"
        }), "：小数第1位までの数値を受け取るプロパティー。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "enumeration"
        }), "：定義済みのオプションを使用するプロパティー。この型のプロパティーを作成する際は、使用可能な値を設定するために", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), "配列を含めます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "datetime"
        }), "：タイムスタンプを表すエポックミリ秒またはISO8601の値を受け取るプロパティー。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以降のセクションで、HubSpotの既定のイベントプロパティーと、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#define-new-properties",
        children: "既存のイベントに新しいプロパティーを定義する"
      }), "方法、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#update-existing-custom-properties",
        children: "既存のカスタム イベント プロパティーを更新する"
      }), "方法について説明します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubSpotの既定のイベントプロパティー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_asset_description"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_asset_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_browser"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_campaign_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_city"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_country"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_device_name"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_device_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_class"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_element_text"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_language"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_link_href"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_operating_system"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_operating_version"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_content_type"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_title"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_page_url"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_parent_module_id"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_referrer"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_region"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_screen_height"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_screen_width"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_touchpoint_source"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_tracking_name"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_user_agent"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_campaign"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_content"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_medium"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_source"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_utm_term"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "新しいプロパティーを定義する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既存のカスタムイベントの新しいプロパティーを定義するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property"
      }), "に送信します。リクエスト本文に、プロパティーの定義を含めます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//\n{\n  \"name\": \"property-name\",\n  \"label\": \"Property name\",\n  \"type\": \"enumeration\",\n  \"options\": [\n    {\n      \"label\": \"label\",\n      \"value\": \"value\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プロパティーに名前を付ける際は、次の点に留意してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "プロパティーを作成した後に、プロパティーの名前を変更することはできません。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "名前に使用できるのは、小文字の英字、数字、アンダースコア、ハイフンのみです。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "プロパティー名の最初の文字は英字でなければなりません。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "プロパティーの名前とラベルは、それぞれ最大50文字に制限されています。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "プロパティー名が指定されていない場合、プロパティーのラベルを使用してプロパティー名が自動生成されます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotのUIの特定の部分では、文字数の多いラベルが切り詰められる場合があります。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既存のカスタムプロパティーを更新する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタムイベントの既存のプロパティーを更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property"
      }), "に送信します。プロパティーで更新できるフィールドは、列挙プロパティーの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "description"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), "のみです。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**プロパティーの型を変更するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DELETE"
        }), "エンドポイントを使用してプロパティーを削除してから、適切な型でプロパティーを再作成します。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "プロパティーを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタムイベントの既存のプロパティーを削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}/property/{propertyName}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プロパティーが削除されると、そのプロパティーは以降のイベント完了で使用できなくなります。ただし、削除前のイベント完了では、削除されたプロパティーの値が維持されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**イベントを削除する場合："
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["1.そのイベント定義の全てのイベントが削除されて、復元できなくなります。2. 削除済みの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "eventName"
        }), "'を再び使用することはできません。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベントを更新する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["イベント定義で更新できるフィールドは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "description"
      }), "のみです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既存のカスタムイベントスキーマを更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "イベントを削除する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カスタムイベントを削除すると、ワークフローやレポートなどの他のHubSpotツールでそのイベントが参照されていても、イベントが使用されなくなります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタムイベントを削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "既存のイベント定義を取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["単一のイベント定義を取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions/{eventName}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定の条件でイベント定義を検索するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "events/v3/event-definitions"
      }), "に送信します。次のクエリーパラメーターを指定して、検索を絞り込むことができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "searchString"
        }), "：指定した文字列が", (0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), "フィールドに含まれているイベントを検索します。検索はあいまい検索では", (0, _jsxRuntime.jsx)("u", {
          children: "なく"
        }), "、単純な「含まれているかどうか」__の検索です。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "after"
        }), "：ページ処理されたレスポンスに表示される、検索結果の次のページを表示するためのハッシュ化された文字列。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), "：取得する結果の最大数。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "includeProperties"
        }), "：返される結果にイベントプロパティーを含めるかどうかを指定するブール値。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "カスタムイベントの例"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下は、サンプル カスタム イベントの作成方法を説明するチュートリアルです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このチュートリアルの内容は次のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "カスタムイベント定義を作成します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "イベント定義で新しいプロパティーを作成します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**目標：**CarSpotという自動車販売代理店では、店舗にある全ての自動車をオンライン在庫一覧表で管理しています。CarSpotは、サイトの訪問者が店舗にある特定の自動車をいつ閲覧したかを追跡したいと考えています。そのために、CarSpotはカスタムイベントを作成し、自動車掲載情報を閲覧したユーザーを追跡する目的で、作成したカスタムイベントをコンタクトレコードに関連付けます。作成するイベントには、各イベントで閲覧された自動車掲載情報の詳細を格納するための一連のカスタムプロパティーも必要になります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カスタムイベント定義を作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["イベントを呼び出す条件、トリガーされた各イベントとともに含めるプロパティー、イベントを関連付けるCRMオブジェクトを決定した後、CarSpotはイベントスキーマを作成するために、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/event-definitions"
      }), "に送信します。このリクエストの本文は次のようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body for event definitions\n\n{\n  \"label\": \"Viewed Car\",\n  \"name\": \"viewed_car\",\n  \"description\": \"An event that fires when visitor views a car listing in the online inventory\",\n  \"primaryObject\": \"CONTACT\",\n  \"propertyDefinitions\": [\n    {\n      \"name\": \"condition\",\n      \"label\": \"Condition\",\n      \"type\": \"enumeration\",\n      \"options\": [\n        {\n          \"label\": \"New\",\n          \"value\": \"new\"\n        },\n        {\n          \"label\": \"Used\",\n          \"value\": \"used\"\n        }\n      ]\n    },\n    {\n      \"name\": \"year\",\n      \"label\": \"Year\",\n      \"type\": \"number\"\n    },\n    {\n      \"name\": \"make\",\n      \"label\": \"Make\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"model\",\n      \"label\": \"Model\",\n      \"type\": \"string\"\n    },\n    {\n      \"name\": \"mileage\",\n      \"label\": \"Mileage\",\n      \"type\": \"number\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カスタムイベント定義でプロパティーを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ウェブサイトでイベントを実装した直後、CarSpotは掲載価格がクリック率に影響するかどうかを確認することにしました。これを追跡するために、掲載価格を格納する新しいプロパティーを作成します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["新しいプロパティーを定義するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/event-definitions/viewed_car/property"
      }), "に送信します。このリクエストの本文は次のようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body for creating a property\n{\n  \"name\": \"price\",\n  \"label\": \"Price\",\n  \"type\": \"number\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタムイベントが定義された今、CarSpotは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "このカスタムイベント定義を使用してイベントデータをHubSpotに送信"
      }), "できるようになりました。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}