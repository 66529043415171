"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 59605144696;
const slug = exports.slug = 'guides/api/crm/engagements/notes';
const title = exports.title = 'Interacciones | Notas';
const name = exports.name = 'Interacciones | Notas';
const metaDescription = exports.metaDescription = 'Los registros de contactos almacenan información sobre personas. Los puntos de terminación de contactos te permiten administrar estos datos y sincronizarlos entre HubSpot y otros sistemas.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-una-nota",
  "label": "Crear una nota",
  "parent": null
}, {
  "depth": 1,
  "id": "propiedades",
  "label": "Propiedades",
  "parent": "crear-una-nota"
}, {
  "depth": 1,
  "id": "asociaciones",
  "label": "Asociaciones",
  "parent": "crear-una-nota"
}, {
  "depth": 0,
  "id": "recuperar-notas",
  "label": "Recuperar notas",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizar-notas",
  "label": "Actualizar notas",
  "parent": null
}, {
  "depth": 1,
  "id": "asociar-notas-existentes-con-registros",
  "label": "Asociar notas existentes con registros",
  "parent": "actualizar-notas"
}, {
  "depth": 1,
  "id": "eliminar-una-asociaci%C3%B3n",
  "label": "Eliminar una asociación",
  "parent": "actualizar-notas"
}, {
  "depth": 0,
  "id": "anclar-una-nota-a-un-registro",
  "label": "Anclar una nota a un registro",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-notas",
  "label": "Eliminar notas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      em: "em",
      h2: "h2",
      code: "code",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Notas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes registrar notas en registros del CRM para agregar información a la cronología del registro o asociar un archivo adjunto a un registro. Por ejemplo, si necesitas hacer seguimiento de una conversación offline que tuviste con un contacto, puedes agregar una nota a su registro de contacto con detalles y documentos relacionados con la conversación. Los demás usuarios de la cuenta podrán ver esa nota y hacer referencia a ella."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes gestionar las notas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "en HubSpot"
      }), " o a través de la API de notas. A continuación, aprende los métodos básicos de gestión de notas a través de la API. Puedes revisar todos los puntos de terminación disponibles en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una nota"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear una nota, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el cuerpo de la solicitud, agrega detalles de la nota en un objeto ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "propiedades"
      }), ". También puedes agregar un objeto ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "asociaciones"
      }), " para asociar la nueva nota con un registro existente (por ejemplo, contactos, empresas)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propiedades"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el objeto propiedades, puedes incluir los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Requerido. Este campo marca la hora de creación de la nota y determina dónde se encuentra la nota en la cronología del registro. Puedes usar una marca de tiempo Unix en milisegundos o en formato UTC."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_note_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El contenido del texto de la nota, limitado a 65.536 caracteres."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID del propietario"
            }), " asociado con la nota. Este campo determina el usuario que aparece como creador de la nota en la cronología del registro en HubSpot."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Los ID de los archivos adjuntos de la nota. Los ID de varios archivos adjuntos están separados por un punto y coma."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para crear y asociar una nota con registros existentes, incluye un objeto asociaciones en tu solicitud. Por ejemplo, para crear una nota y asociarla con una empresa y un negocio, el cuerpo de tu solicitud podría tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to https://api.hubspot.com/crm/v3/objects/notes\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-11-12T15:48:22Z\",\n    \"hs_note_body\": \"Spoke with decision maker Carla. Attached the proposal and draft of contract.\",\n    \"hubspot_owner_id\": \"14240720\",\n    \"hs_attachment_ids\": \"24332474034;24332474044\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 190\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 401\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 214\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el objeto asociaciones, debes incluir lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El registro que deseas asociar con la nota, especificado por su valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " único."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de asociación entre la nota y el registro. Incluye la ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " y el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Los ID de los tipos de asociación predeterminados se enumeran ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "aquí"
            }), ", o puedes recuperar el valor de los tipos de asociación personalizados (es decir, etiquetas) a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API de asociaciones"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la creación de notas por lotes en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar notas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes recuperar notas individualmente o por lotes. Para recuperar una nota individual, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para solicitar una lista de todas las notas, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En ambos puntos de terminación, puedes incluir los siguientes parámetros de consulta en la URL de la solicitud:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una lista separada por comas de las propiedades que se devolverán en la respuesta. Si la nota solicitada no tiene un valor para una propiedad, no aparecerá en la respuesta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una lista separada por comas de los tipos de objetos para recuperar los ID asociados. No se devolverán en la respuesta las asociaciones especificadas que no existan. Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API de asociaciones."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, para recuperar notas con su contenido de texto y cualquier ID de contacto asociado, la URL de solicitud puede tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/notes?limit=10&properties=hs_note_body&associations=contact&archived=false"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre cómo recuperar un lote de notas por ID interno o valor de propiedad único en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar notas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes actualizar las notas individualmente o por lotes. Para actualizar una nota individual por su ID de nota, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el cuerpo de la solicitud, incluye las propiedades de nota que deseas actualizar:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example PATCH request to https://api.hubspot.com/crm/v3/objects/notes/{noteID}\n{\n  \"properties\": {\n    \"hs_note_body\": \"Spoke with decision maker Carla.\",\n    \"hs_attachment_ids\": \"24332474034;24332474044\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ignorará los valores de las propiedades de solo lectura e inexistentes. Para borrar un valor de propiedad, pasa una cadena vacía para la propiedad en el cuerpo de la solicitud."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre cómo actualizar notas por lotes en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociar notas existentes con registros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para asociar una nota con otros registros de CRM, como un contacto, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". La URL de la solicitud contiene los siguientes campos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "noteId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de la nota."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El tipo de objeto con el que deseas asociar la nota (por ejemplo, contacto o empresa)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID del registro con el que deseas asociar la nota."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un identificador único para indicar el tipo de asociación entre la nota y el otro objeto. El ID se puede representar numéricamente o con palabras separadas por guion bajo (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "note_to_contact"
            }), "). Puedes recuperar el valor a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "API de asociaciones"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, la URL de tu solicitud puede tener un aspecto similar al siguiente:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/notes/17147287858/associations/contact/581751/202"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eliminar una asociación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una asociación entre una nota y un registro, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a la misma URL que la anterior:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anclar una nota a un registro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "anclar una nota"
      }), " en un registro para que permanezca en la parte superior de la cronología del registro. La nota ya debe estar asociada con el registro antes de anclar, y solo puedes anclar una actividad por registro. Para anclar una nota, incluye el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de la nota en el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " al crear o actualizar un registro a través de las API de objetos. Obtén más información sobre el uso de las API de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "empresas"
      }), ",", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contactos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "negocios"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objetos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar notas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes eliminar notas individualmente o por lotes, lo que agregará la nota a la papelera de reciclaje en HubSpot. Más tarde puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "restaurar la nota desde la cronología del registro"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para eliminar una nota individual por su ID de nota, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la eliminación de notas por lotes en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}