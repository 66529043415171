"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742919;
const slug = exports.slug = 'reference/api/conversations/chat-widget-sdk';
const title = exports.title = 'Conversaciones | SDK de widget de chat';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Chat Widget SDK';
const metaDescription = exports.metaDescription = 'El widget chat en directo de HubSpot te permite chatear con clientes en tu sitio web. Con el SDK de widget de chat, puedes personalizar el comportamiento del widget de chat.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "inicializar",
  "label": "Inicializar",
  "parent": null
}, {
  "depth": 0,
  "id": "establecer-la-configuraci%C3%B3n-de-tus-conversaciones",
  "label": "Establecer la configuración de tus conversaciones",
  "parent": null
}, {
  "depth": 1,
  "id": "estilo-de-incrustaci%C3%B3n-en-l%C3%ADnea",
  "label": "Estilo de incrustación en línea",
  "parent": "establecer-la-configuraci%C3%B3n-de-tus-conversaciones"
}, {
  "depth": 0,
  "id": "widget-behavior",
  "label": "Widget behavior",
  "parent": null
}, {
  "depth": 1,
  "id": "widget.load",
  "label": "widget.load",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.refresh",
  "label": "widget.refresh",
  "parent": "widget-behavior"
}, {
  "depth": 2,
  "id": "ejemplo",
  "label": "Ejemplo",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.open",
  "label": "widget.open",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.close",
  "label": "widget.close",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.remove",
  "label": "widget.remove",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.status",
  "label": "widget.status",
  "parent": "widget-behavior"
}, {
  "depth": 0,
  "id": "borrar-cookies-del-chat",
  "label": "Borrar cookies del chat",
  "parent": null
}, {
  "depth": 0,
  "id": "eventos-de-chat",
  "label": "Eventos de chat",
  "parent": null
}, {
  "depth": 1,
  "id": "conversationstarted",
  "label": "conversationStarted",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "conversationclosed",
  "label": "conversationClosed",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "userselectedthread",
  "label": "userSelectedThread",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "unreadconversationcountchanged",
  "label": "unreadConversationCountChanged",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "contactassociated",
  "label": "contactAssociated",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "userinteractedwithwidget",
  "label": "userInteractedWithWidget",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "widgetloaded",
  "label": "widgetLoaded",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "quickreplybuttonclick",
  "label": "quickReplyButtonClick",
  "parent": "eventos-de-chat"
}, {
  "depth": 1,
  "id": "widgetclosed",
  "label": "widgetClosed",
  "parent": "eventos-de-chat"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      img: "img",
      h4: "h4",
      br: "br",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "SDK de conversaciones"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para chatear con clientes y leads en tu sitio web utilizando la bandeja de conversación de HubSpot, puedes configurar un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/chat-with-your-website-visitors",
        children: "widget de chat en vivo"
      }), ". Con el SDK de conversaciones, puedes proporcionarles una experiencia más personalizada a los visitantes adaptando el comportamiento del widget de chat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En un nivel alto, el SDK de conversaciones te permite hacer lo siguiente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#configure-conversations-settings",
          children: "Configurar el widget de chat"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-behavior",
          children: "Controlar el comportamiento del widget"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#clear-chat-cookies",
          children: "Borrar cookies del chat"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#chat-events",
          children: "Escuchar y responder a los eventos del widget"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-status"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Inicializar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La API se encuentra en el objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.HubSpotConversations"
      }), ", que proporciona acceso a todos los métodos disponibles. El objeto se crea con el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code",
        children: "código de seguimiento de HubSpot"
      }), ", pero es posible que no esté disponible inmediatamente al cargar la página. Para diferir el acceso a la API hasta que esté inicializado, puedes usar el ayudante ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), " es un campo opcional que puedes definir en el objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window"
      }), ", el cual te permite especificar el código que se ejecutará tan pronto como el widget esté disponible. Este campo requiere que se ejecute una función de matriz una vez que se ha inicializado la API."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<script type=\"text/javascript\">\n  function onConversationsAPIReady() {\n    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);\n  }\n  /*\n    configure window.hsConversationsSettings if needed.\n  */\n  window.hsConversationsSettings = {};\n  /*\n   If external API methods are already available, use them.\n  */\n  if (window.HubSpotConversations) {\n    onConversationsAPIReady();\n  } else {\n    /*\n      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.\n      These callbacks will be called once the external API has been initialized.\n    */\n    window.hsConversationsOnReady = [onConversationsAPIReady];\n  }\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Establecer la configuración de tus conversaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este objeto te permite proporcionar algunas opciones de configuración al widget antes de inicializar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n  inlineEmbedSelector: '#some-id',\n  enableWidgetCookieBanner: true,\n  disableAttachment: true,\n};\nwindow.hsConversationsOnReady = [\n  () => {\n    window.HubSpotConversations.widget.load();\n  },\n];\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loadImmediately"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the widget should implicitly load or wait until the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.load"
            }), " method is called."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inlineEmbedSelector"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Specify a selector (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "#some-id"
            }), ") to embed the chat widget in a specific location on the page. Widget will be embedded inline within that DOM node and will remain open until it is removed with ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.remove"
            }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#inline-embed-styling",
              children: "styling embedded chat widgets"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableWidgetCookieBanner"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Control behavior of the cookie banner for all chat widgets on the page. Options include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), " (default): uses the ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/chatflows/create-a-live-chat#4-options",
                  children: "chat widget's settings"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": presents cookie banners when the widget is loaded."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ON_WIDGET_LOAD"
                }), ": same as ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ON_EXIT_INTENT"
                }), ": enable cookie banners when the user exhibits an exit intent."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disableAttachment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether to hide the upload attachment button in the chat widget."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disableInitialInputFocus"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether to automatically prevent focusing on the widget's input field after an inline embedded widget is initially loaded."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "avoidInlineStyles"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", injects a link tag with externally hosted CSS instead of a direct dynamic insertion of a style tag."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estilo de incrustación en línea"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando el widget se incrusta en una ubicación específica utilizando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inlineEmbedSelector"
      }), ", se agregan varios elementos DOM y se pueden modificar (por ejemplo, altura, ancho, borde)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, si incrustas el widget de chat utilizando el selector ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "# some-id"
      }), ", se cargará con los siguientes contenedores e ID:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div id=\"some-id\">\n  <div id=\"hubspot-conversations-inline-parent\">\n    <iframe id=\"hubspot-conversations-inline-iframe\">\n      <!-- rest of iframe content -->\n    </iframe>\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "You can then customize the chat widget using those selectors, such as:"
          })
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "#hubspot-conversations-inline-iframe {\n  width: 300px;\n  height: 500px;\n  border: none;\n}\n"
          })
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://developers.hubspot.com/hs-fs/hubfs/livechat_after.png?width=600&name=livechat_after.png",
              alt: "livechat_after"
            })
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Widget behavior"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotConversations.widget"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The widget object contains a number of methods that allow you to manipulate the chat widget on your page, including:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-load",
          children: "widget.load"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-refresh",
          children: "widget.refresh"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-open",
          children: "widget.open"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-close",
          children: "widget.close"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-remove",
          children: "widget.remove"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-status",
          children: "widget.status"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about each method."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.load"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.load"
      }), " method handles the initial load on the page. This method is only necessary if you set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loadImmediately"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), ". Otherwise, the widget will load itself automatically."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This method is throttled to one call per second."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.load();\n\n/* ... */\n\n// Force the widget to load in an open state\nwindow.HubSpotConversations.widget.load({ widgetOpen: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "widgetOpen"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Si el widget debe cargarse en un estado abierto."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.refresh"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El método ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.refresh"
      }), " maneja la actualización y la nueva representación de la información del widget, dada la URL de la página actual. Este método puede ser útil para widgets de chat integrados en aplicaciones de una sola página cuando necesites actualizar el widget en los cambios de ruta. Este método también te permite ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#example",
        children: "especificar diferentes widgets de chat en diferentes rutas de página"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si llamas a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.refresh"
      }), " en una ruta donde no hay widget de chat y el usuario no interactúa en un chat, se eliminará el widget. ", (0, _jsxRuntime.jsx)("u", {
        children: "No"
      }), " eliminará el widget cuando haya un chat actualmente activo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este método se limita a una llamada por segundo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.refresh();\n\n/* ... */\n\n// Force the widget to open to a specific chat flow\nwindow.HubSpotConversations.widget.refresh({ openToNewThread: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "openToNewThread"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ya sea para forzar la creación de un nuevo hilo."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ejemplo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con este método, puedes crear botones y enlaces para abrir chatflows específicos en una página agregando parámetros de consulta a la URL de la página."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/conversations-chat-widget-open-crop.gif",
        alt: "conversations-chat-widget-open-crop"
      }), "Por ejemplo, podrías agregar el siguiente código a tus páginas para generar los botones:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div class=\"chat-buttons\">\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_sales', '?sales_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to sales\n  </button>\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_customer_support', '?cs_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to customer support\n  </button>\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_the_ceo', '?ceo_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to the CEO\n  </button>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Luego, en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/chatflows/create-a-live-chat#2-target---decide-where-the-live-chat-should-appear",
        children: "configuración objetivo"
      }), " de cada chat, establecerías que el chat se muestre cuando el parámetro de consulta coincida con el que has establecido en tu código de botón.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/conversations-target-rule.png",
        alt: "conversaciones-target-rule"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.open"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El método ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.open"
      }), " abre el widget si aún no está abierto o no está cargado actualmente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.open();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.close"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El método ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.close"
      }), " cierra el widget si aún no está cerrado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.close();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.remove"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El método ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.remove"
      }), " elimina el widget de la página. Si el widget no está presente en la página, este método no hace nada. El widget se mostrará de nuevo al actualizar la página o si se invoca ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.load"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.remove();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.status"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El método ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.status"
      }), " devuelve un objeto que contiene propiedades relacionadas con el estado actual del widget."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const status = window.HubSpotConversations.widget.status();\n\nif (status.loaded) {\n  window.HubSpotConversations.widget.refresh();\n} else {\n  window.HubSpotConversations.widget.load();\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loaded"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ya sea que el widget iframe se haya cargado."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Borrar cookies del chat"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El método ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "clear"
      }), " elimina las cookies relacionadas con el widget de chat y lo devuelve a su estado predeterminado en la carga posterior."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El widget de chat crea varias cookies para preservar su estado durante las visitas al sitio y las actualizaciones de la página. Estas cookies se limitan al dominio de la página que aloja el widget y se utilizan para admitir las siguientes funciones:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Referenciar conversaciones históricas"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Persistencia del estado abierto del widget de chat a través de cargas de página."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Persistencia del estado abierto del mensaje de bienvenida a través de cargas de página."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las siguientes cookies se borran con este método:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs-messages-is-open"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs-messages-hide-welcome-message"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener más información sobre estas cookies, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser",
        children: "consulta la Base de conocimientos de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.clear();\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además, puedes pasar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{resetWidget:true}"
      }), " a la función ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "clear()"
      }), " para borrar todas las cookies relacionadas con chat, eliminar el widget de la página y crear una nueva instancia del widget de chat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.clear({ resetWidget: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eventos de chat"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El widget de chat emite varios eventos que puedes escuchar y responder a lo largo de su ciclo de vida. Estos eventos incluyen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#conversationstarted",
          children: "conversationStarted"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#conversationclosed",
          children: "conversationClosed"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#userselectedthread",
          children: "userSelectedThread"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#unreadconversationcountchanged",
          children: "unreadConversationCountChanged"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#contactassociated",
          children: "contactAssociated"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#userinteractedwithwidget",
          children: "userInteractedWithWidget"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widgetloaded",
          children: "widgetLoaded"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#quickreplybuttonclick",
          children: "quickReplyButtonClick"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widgetclosed",
          children: "widgetClosed"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para registrar y eliminar oyentes de eventos, los usarás ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "on"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "off"
      }), ", como se muestra a continuación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const handleEvent = (eventPayload) => console.log(eventPayload);\n\nwindow.HubSpotConversations.on('conversationStarted', handleEvent);\n\n/* ... */\n\nwindow.HubSpotConversations.off('conversationStarted', handleEvent);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Obtén más información sobre cada evento a continuación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "conversationStarted"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversationStarted"
      }), " se desencadena cuando se ha iniciado correctamente una nueva conversación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('conversationStarted', (payload) => {\n  console.log(\n    `Started conversation with id ${payload.conversation.conversationId}`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID del hilo de la conversación que se inició. Puedes usar este ID al realizar llamadas a la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "API de conversaciones"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "conversationClosed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversationClosed"
      }), " se desencadena cuando una nueva conversación se ha ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/use-the-conversations-inbox#reply-and-comment:~:text=To%20mark%20the%20conversation%20as%20closed",
        children: "marcado como cerrada"
      }), " desde la bandeja de conversaciones."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los visitantes del sitio que minimicen o cierren el widget de chat no desencadenarán este evento. Para ese evento, utiliza ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#widgetclosed",
        children: "widgetClosed"
      }), " en su lugar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('conversationClosed', (payload) => {\n  console.log(\n    `Conversation with id ${payload.conversation.conversationId} has been closed!`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID del hilo de la conversación que se inició. Puedes usar este ID al realizar llamadas a la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "API de conversaciones"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "userSelectedThread"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userSelectedThread"
      }), " se activa al crear una cadena o seleccionar una cadena existente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('userSelectedThread', (payload) => {\n  console.log(\n    `User selected thread with ID ${payload.conversation.conversationId}!`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El ID del hilo de la conversación que se inició. Puedes usar este ID al realizar llamadas a la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "API de conversaciones"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "unreadConversationCountChanged"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unreadConversationCountChanged"
      }), " se desencadena cuando aumenta o disminuye el número de conversaciones con mensajes no leídos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('unreadConversationCountChanged', (payload) => {\n  console.log(`New unread count is ${payload.unreadCount}!`);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unreadCount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número total de conversaciones con al menos un mensaje no leído."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "contactAssociated"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contactAssociated"
      }), " se desencadena cuando el visitante está asociado a un contacto en el CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('contactAssociated', (payload) => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un mensaje de confirmación de que el visitante ha sido asociado con un contacto."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "userInteractedWithWidget"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userInteractedWithWidget"
      }), " se desencadena cuando el visitante interactúa con el widget, como hacer clic para abrir el widget o cerrar el mensaje de bienvenida inicial."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on(‘userInteractedWithWidget’, payload => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un mensaje de confirmación de que el visitante ha interactuado con el widget."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widgetLoaded"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgetLoaded"
      }), " se desencadena cuando se carga el iframe del widget."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on(‘widgetLoaded’, payload => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un mensaje de confirmación de que el iframe del widget se ha cargado."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "quickReplyButtonClick"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "quickReplyButtonClick"
      }), " se desencadena cuando el visitante hace clic en una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/chatflows/a-guide-to-bot-actions#ask-a-question",
        children: "respuesta rápida"
      }), " en una conversación de bot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una matriz que contiene el texto de la opción de respuesta rápida en la que se hizo clic."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('quickReplyButtonClick', (event) => {\n  console.log(`The text content of the clicked button is ${payload.value[0]}`);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/quick-reply-options-in-bot-conversation.png",
        alt: "quick-reply-options-in-bot-conversation"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la captura de pantalla de ejemplo anterior, el chatflow del bot contiene tres opciones de respuesta rápida. Si el usuario selecciona ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Más información"
      }), ", la carga útil del evento resultante sería:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example event payload when a quick reply option is selected\n{\n  \"name\": \"QUICK_REPLIES\",\n  \"multiSelect\": false,\n  \"value\": [\"Learn more\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widgetClosed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgetClosed"
      }), " se desencadena cuando el visitante cierra el widget de chat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('widgetClosed', (event) => {\n  console.log(event);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un mensaje de confirmación de que el visitante ha cerrado el widget de chat."
          })]
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}