"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 21611793414;
const slug = exports.slug = 'guides/api/analytics-and-events/email-analytics';
const title = exports.title = 'Visão geral da API de eventos de e-mail | API de eventos de e-mail';
const name = exports.name = 'Visão geral da API de eventos de e-mail';
const metaDescription = exports.metaDescription = 'A API de eventos de e-mail é usada para obter informações sobre eventos gerados por e-mails de marketing ou campanhas de e-mail enviadas por meio de uma conta da HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tipos-de-evento",
  "label": "Tipos de evento",
  "parent": null
}, {
  "depth": 1,
  "id": "eventos-de-envio",
  "label": "Eventos de envio",
  "parent": "tipos-de-evento"
}, {
  "depth": 2,
  "id": "motivos-de-descarte",
  "label": "Motivos de descarte",
  "parent": "tipos-de-evento"
}, {
  "depth": 1,
  "id": "eventos-de-entrega",
  "label": "Eventos de entrega",
  "parent": "tipos-de-evento"
}, {
  "depth": 2,
  "id": "entregue",
  "label": "Entregue",
  "parent": "tipos-de-evento"
}, {
  "depth": 2,
  "id": "diferido",
  "label": "Diferido",
  "parent": "tipos-de-evento"
}, {
  "depth": 2,
  "id": "rejei%C3%A7%C3%A3o",
  "label": "Rejeição",
  "parent": "tipos-de-evento"
}, {
  "depth": 2,
  "id": "categorias-de-rejei%C3%A7%C3%A3o",
  "label": "Categorias de rejeição",
  "parent": "tipos-de-evento"
}, {
  "depth": 1,
  "id": "eventos-de-engajamento-do-usu%C3%A1rio",
  "label": "Eventos de engajamento do usuário",
  "parent": "tipos-de-evento"
}, {
  "depth": 1,
  "id": "eventos-de-status-do-usu%C3%A1rio",
  "label": "Eventos de status do usuário",
  "parent": "tipos-de-evento"
}, {
  "depth": 2,
  "id": "fontes-de-altera%C3%A7%C3%A3o-de-assinatura",
  "label": "Fontes de alteração de assinatura",
  "parent": "tipos-de-evento"
}, {
  "depth": 1,
  "id": "-eventos-de-cancelamento-de-rejei%C3%A7%C3%A3o",
  "label": "'Eventos de cancelamento de rejeição",
  "parent": "tipos-de-evento"
}, {
  "depth": 0,
  "id": "refer%C3%AAncias-de-eventos",
  "label": "Referências de eventos",
  "parent": null
}, {
  "depth": 1,
  "id": "-sentby-",
  "label": "'sentBy'",
  "parent": "refer%C3%AAncias-de-eventos"
}, {
  "depth": 1,
  "id": "-obsoletedby-",
  "label": "'obsoletedBy'",
  "parent": "refer%C3%AAncias-de-eventos"
}, {
  "depth": 1,
  "id": "-causedby-",
  "label": "'causedBy'",
  "parent": "refer%C3%AAncias-de-eventos"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h3: "h3",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      img: "img",
      em: "em",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral da API de eventos de e-mail"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A API de eventos de e-mail é usada para obter informações sobre eventos gerados por e-mails de marketing ou campanhas de e-mail enviadas por meio de uma conta da HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Caso de uso para esta API:"
      }), " se um membro da sua equipe de marketing quiser otimizar as campanhas de e-mail, você poderá usar a API de eventos de e-mail para coletar dados e alimentar um modelo de aprendizado de máquina que determina o melhor momento para enviar e-mails para diferentes segmentos de contatos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eventos de e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada e-mail enviado por meio do HubSpot gera uma série de eventos que especificam seu ciclo de vida e como o destinatário interage com o conteúdo. Um evento é identificado exclusivamente por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "EventId"
      }), ", que é composto pelas seguintes propriedades:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um ID gerado aleatoriamente para este evento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "created"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número inteiro de 64 bits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A data/hora (em milissegundos de era) em que este evento foi criado."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Essas propriedades podem ser usadas para procurar um evento específico utilizando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/email-analytics#get-email-event-by-id",
        children: "este ponto de extremidade"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além disso, todos, exceto um tipo de evento (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), "), têm as seguintes propriedades:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "type"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string (enumeração)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O tipo de evento. Veja abaixo mais informações sobre os tipos de eventos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "recipient"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O endereço de e-mail do destinatário da mensagem de e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "portalId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número inteiro de 32 bits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um ID que faz referência à conta da HubSpot que enviou a mensagem de e-mail. Corresponderá à sua conta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "appId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número inteiro de 32 bits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um ID que faz referência ao aplicativo HubSpot que enviou a mensagem de e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "appName"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome do aplicativo HubSpot que enviou a mensagem de e-mail. Observe que este é um valor derivado e pode ser modificado a qualquer momento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "emailCampaignId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número inteiro de 64 bits"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um ID que faz referência à campanha de e-mail da qual a mensagem de e-mail faz parte. Informações adicionais sobre a campanha de e-mail podem ser encontradas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/email-analytics#get-campaign-data-for-a-given-campaign",
              children: "neste ponto de extremidade"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os eventos podem ser consultados em massa usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/email-analytics#get-email-events",
        children: "este ponto de extremidade"
      }), " com as propriedades ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'recipient'"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'appId'"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'campaignId'"
      }), ", ou qualquer combinação das propriedades acima."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As propriedades adicionais a seguir também estão disponíveis para todos os tipos de eventos (incluindo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), "):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "sentBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O EventId que identifica exclusivamente o evento ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT"
            }), " da mensagem de e-mail. Se não for aplicável, essa propriedade será omitida."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "obsoletedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O EventId que identifica exclusivamente o evento subsequente que torna este evento obsoleto. Se não for aplicável, essa propriedade será omitida."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "causedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O EventId que identifica exclusivamente o evento que causou diretamente este evento. Se não for aplicável, essa propriedade será omitida."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As propriedades de referência de evento -- ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'sentBy'"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), " -- são discutidas em detalhes mais adiante neste documento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de evento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Existem 12 tipos de eventos que podem ser gerados pela API de e-mail da HubSpot durante o ciclo de vida de uma mensagem de e-mail. Eles são amplamente agrupados em categorias: Envio, Entrega, Engajamento do usuário e Status do usuário. Os tipos de eventos, categorias de eventos e seus relacionamentos estão diagramados abaixo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://static.hsappstatic.net/developer_site_web/static-2.153/img/events.svg",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de evento"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A mensagem foi enviada e recebida pelo nosso provedor de entrega, que a colocou na fila para processamento posterior."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DROPPED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A mensagem foi rejeitada pelo HubSpot ou pelo nosso provedor de entrega e nenhuma tentativa será feita para entregá-la."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PROCESSED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A mensagem foi recebida pelo nosso provedor de entrega, que indicou que tentará entregar a mensagem ao servidor de e-mail do destinatário."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DELIVERED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O servidor de e-mail do destinatário aceitou a mensagem e a mensagem foi entregue com sucesso ao destinatário."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DEFERRED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O servidor de e-mail do destinatário rejeitou temporariamente a mensagem. Serão feitas tentativas subsequentes para entregar a mensagem."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BOUNCE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O servidor de e-mail do destinatário não pôde ou não quis aceitar a mensagem e nenhuma outra tentativa será feita para entregá-la."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "OPEN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O destinatário abriu a mensagem."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CLICK"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O destinatário clicou em um link na mensagem."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "STATUSCHANGE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O destinatário alterou suas assinaturas de e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SPAMREPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O destinatário marcou a mensagem como spam."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SUPPRESSION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A mensagem não foi enviada; o destinatário não se envolveu com e-mails de marketing anteriores."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " alguns tipos de eventos, como eventos de bot, podem ser\nincluídos na resposta à API ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/events"
        }), ", mas não aparecerá quando você estiver\nanalisando a guia ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "https://knowledge.hubspot.com/email/analyze-your-marketing-email-campaign-performance",
          children: [(0, _jsxRuntime.jsx)(_components.em, {
            children: "Recipients"
          }), " de um e-mail de marketing em sua conta da\nHubSpot\n"]
        }), ",\nque filtra certos tipos de eventos brutos e limita o total de eventos\nmostrados a 30 eventos por tipo de evento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eventos de envio"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando uma mensagem de e-mail é criada e enviada pelo HubSpot em nome de um cliente, primeiro verificamos se o destinatário está qualificado para recebê-la. Se ele não estiver qualificado, rejeitamos a mensagem, acionando a criação de um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), ". Caso contrário, nós enviamos a mensagem ao nosso provedor de entrega para processamento posterior, acionando um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), ". Uma mensagem de e-mail quase sempre terá exatamente um evento de envio associado a ela; por exemplo, nunca haverá vários eventos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " para uma mensagem."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fazemos o possível para rejeitar mensagens antes de encaminhá-las ao nosso provedor de entrega. No entanto, por vezes o nosso provedor de entrega decide rejeitar uma mensagem mesmo depois de termos verificado sua qualificação. Essa rejeição subsequente resulta na criação de um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), ", além do evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " criado anteriormente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Todos os eventos de envio compartilham as seguintes propriedades:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "subject"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A linha de assunto da mensagem de e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "from"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O campo \"from\" da mensagem de e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "replyTo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lista de strings"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O campo \"reply-to\"' da mensagem de e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "cc"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lista de strings"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O campo \"cc\" da mensagem de e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "bcc"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lista de cordas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O campo \"bcc\" da mensagem de e-mail."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além disso, eventos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " têm as seguintes propriedades:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "dropReason"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string (enumeração)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O motivo pelo qual a mensagem de e-mail foi descartada. Veja abaixo os valores possíveis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "dropMessage"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A mensagem bruta que descreve por que a mensagem de e-mail foi descartada. Geralmente fornecerá detalhes adicionais além de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropReason'"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Motivos de descarte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Motivo de descarte"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_BOUNCED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma mensagem anterior ao destinatário resultou em uma rejeição."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUS_SPAM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma mensagem anterior ao destinatário foi marcada como spam."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_UNSUBSCRIBED_MESSAGE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O destinatário cancelou anteriormente a inscrição dessa assinatura."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_UNSUBSCRIBED_PORTAL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O destinatário cancelou anteriormente todas as assinaturas da conta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "INVALID_TO_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O endereço de e-mail no campo \"para\" não passou na validação."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "INVALID_FROM_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O endereço de e-mail no campo \"de\" não passou na validação."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BLOCKED_DOMAIN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O domínio do destinatário estava bloqueado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BLOCKED_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O destinatário solicitou explicitamente para não receber nenhum e-mail através do HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "EMAIL_UNCONFIRMED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O opt-in duplo foi ativado e o destinatário não confirmou ainda sua assinatura."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CAMPAIGN_CANCELLED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A campanha de e-mail associada foi cancelada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MTA_IGNORE"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Nosso provedor de entrega decidiu desistir da mensagem. Detalhes adicionais serão incluídos em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropMessage'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PORTAL_OVER_LIMIT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sua conta ultrapassou o limite mensal de envio de e-mails."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PORTAL_SUSPENDED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sua conta foi suspensa por problemas de não conformidade ou capacidade de entrega."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "QUARANTINED_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O destinatário foi colocado em quarentena devido a repetidas taxas de hard bounce."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ADDRESS_LIST_BOMBED"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" O destinatário foi colocado em quarentena devido a atividades suspeitas no formulário. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/email/remediate-list-bombing",
              children: "Saiba mais"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eventos de entrega"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando nosso provedor de entrega aceitar uma mensagem de e-mail, criaremos um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROCESSED"
      }), ". Neste ponto, o provedor de entrega colocou a mensagem na fila para entrega. Se tudo ocorrer bem, o provedor de entrega removerá a mensagem da fila e a entregará ao servidor de e-mail do destinatário, gerando um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELIVERED"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Às vezes, as coisas não saem como o planejado e ocorrem uma de duas situações: a entrega é adiada devido a uma rejeição temporária ou a entrega falha e não é tentada novamente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No primeiro caso, a mensagem não pode ser entregue ao servidor de e-mail do destinatário por algum motivo não fatal (geralmente transitório, como um falso tempo limite). O provedor de entrega colocará a mensagem novamente na fila para entrega posterior e criaremos um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DEFERRED"
      }), ". Uma mensagem pode ser adiada diversas vezes antes de concluir a fase de entrega, com um novo evento criado a cada tentativa."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se a entrega falhar, nenhuma outra tentativa será feita para entregar a mensagem e criaremos um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), ". Isso pode ocorrer por vários motivos, como o destinatário ser desconhecido pelo servidor de e-mail."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os tipos de eventos de entrega específicos incluem as seguintes propriedades:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Entregue"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A resposta completa do servidor de e-mail do destinatário."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "smtpId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um ID anexado à mensagem pela HubSpot."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Diferido"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A resposta completa do servidor de e-mail do destinatário."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "attempt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número inteiro de 32 bits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número da tentativa de entrega."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Rejeição"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "category"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string (enumeração)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A melhor estimativa encontrada para o tipo de rejeição. Se uma categoria apropriada não puder ser determinada, esta propriedade será omitida. Veja abaixo os valores possíveis. Observe que este é um valor derivado e pode ser modificado a qualquer momento para melhorar a precisão da classificação."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A resposta completa do servidor de e-mail do destinatário."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "status"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O código de status retornado do servidor de e-mail do destinatário."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Categorias de rejeição"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Categoria de rejeição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UNKNOWN_USER"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O destinatário não existe."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MAILBOX_FULL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A caixa postal do destinatário estava cheia e não podia receber mais mensagens."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CONTENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os filtros do destinatário identificaram o conteúdo do corpo do e-mail como suspeito ou como spam."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SPAM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A mensagem foi marcada como spam."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "POLICY"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A mensagem foi marcada como spam."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "GREYLISTING"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O servidor de e-mail requer um histórico mais longo de atividades de e-mail do remetente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MAILBOX_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Foi detectada uma configuração incorreta da caixa de correio."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ISP_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Foi detectada uma configuração de ISP incorreta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DOMAIN_REPUTATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O domínio de envio tem uma reputação ruim ou uma reputação que não atende aos padrões do servidor do destinatário."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DMARC"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O domínio do remetente não passou na verificação de DMARC. Revise suas políticas de SPF e DMARC."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SENDING_DOMAIN_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A autenticação do domínio falhou devido a uma política do destinatário."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "TIMEOUT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O servidor de recebimento de e-mail expirou e não aceita mais e-mails."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "THROTTLED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O servidor de e-mail do destinatário estava limitando mensagens."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UNCATEGORIZED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Foi detectado um erro não categorizado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "IP_REPUTATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A mensagem originou-se de um endereço IP suspeito (ou previamente desconhecido)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DNS_FAILURE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "As configurações do servidor de nome de domínio do destinatário estavam configuradas incorretamente no momento em que o e-mail foi enviado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "TEMPORARY_PROBLEM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ocorreu algum problema temporário."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eventos de engajamento do usuário"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando uma mensagem de e-mail chega ao destinatário, quatro tipos diferentes de eventos podem ocorrer: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PRINT"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORWARD"
      }), ". Eles representam a interação do destinatário com a mensagem e seu conteúdo, e cada um deles pode ocorrer diversas vezes. Por exemplo, sempre que um URL é clicado, um novo evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), " é criado, mesmo que esse URL tenha sido clicado anteriormente e gerado tal evento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Todos os eventos de engajamento do usuário compartilham as seguintes propriedades:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "userAgent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O agente de usuário responsável pelo evento, por exemplo, “Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_4) AppleWebKit/537.36 (KHTML, como Gecko) Chrome/28.0.1500.95 Safari/537.36”"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "browser"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um objeto JSON que representa o navegador que atendeu o evento. É composto pelas propriedades: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'name'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'family'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'producer'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'producer_url'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'type'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'url'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'version'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "location"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um objeto JSON que representa o local onde ocorreu o evento. É composto pelas propriedades: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'city'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'state'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'country'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "filteredEvent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um booleano que representa se o evento foi filtrado dos relatórios com base nas configurações dos relatórios do cliente."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além disso, eventos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), " têm as seguintes propriedades:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "url"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URL na mensagem em que o destinatário clicou."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "referer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URL da página da Web vinculada ao URL clicado. O cliente de e-mail do destinatário é que determina se é fornecido e seu valor."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além disso, eventos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), " podem ter a seguinte propriedade:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "duration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número inteiro de 64 bits (milissegundos)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se fornecido e diferente de zero, o número aproximado de milissegundos em que o usuário abriu o e-mail."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eventos de status do usuário"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um destinatário também pode atualizar suas preferências de comunicação por meio da mensagem de e-mail. Ao clicar no link de preferências de assinatura na mensagem, ele pode alterar suas assinaturas, assinando ou cancelando a assinatura de várias listas, o que aciona um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STATUSCHANGE"
      }), ". Observe que uma alteração de status pode ser para qualquer lista, não apenas aquela que está associada à mensagem de e-mail atual."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma mensagem de e-mail também pode ser marcada como spam pelo destinatário, resultando em um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SPAMREPORT"
      }), ". Observe que isso independe do status da assinatura – marcar uma mensagem como spam não cancela a inscrição do destinatário na lista em questão. Em vez disso, o status da assinatura permanece inalterado e um sinalizador é definido indicando que o destinatário nunca deve receber outra mensagem de e-mail da HubSpot. Quando isso acontecer, você precisará da intervenção manual da HubSpot para remover a marcação."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STATUSCHANGE"
      }), " tem as seguintes propriedades adicionais:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "source"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string (enumeração)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A origem da alteração da assinatura. Veja abaixo os valores possíveis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "requestedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O endereço de e-mail da pessoa que solicitou a alteração em nome do destinatário. Se não for aplicável, essa propriedade será omitida."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "portalSubscriptionStatus"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["string (enumeração, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'SUBSCRIBED'"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ")"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O status de assinatura do portal do destinatário. (Observe que, se for ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ", a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'subscriptions'"
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "não"
            }), " será necessariamente uma matriz vazia, nem todas as assinaturas contidas nela terão necessariamente seus status definidos como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ".)"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "subscriptions"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma matriz de objetos JSON que representa o status das assinaturas do destinatário. Cada objeto de assinatura JSON é composto pelas propriedades: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'id'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'status'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "bounced"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["booleano (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ou totalmente omitido)"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um funcionário da HubSpot iniciou explicitamente a mudança de status para bloquear mensagens ao destinatário. (Observe que este uso foi descontinuado em favor da eliminação de mensagens com um ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropReason'"
            }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOCKED\\_ADDRESS"
            }), ".)"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Fontes de alteração de assinatura"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Fonte"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_LIST_UNSUBSCRIBE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O destinatário usou um cabeçalho list-unsubscribe."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_RECIPIENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O destinatário usou a interface de assinatura."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_IMPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O cliente importou as assinaturas em seu portal."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT_CUSTOMER"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O cliente usou a interface de assinatura."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_SPAM_REPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Foi recebido um relatório de spam gerado por um sistema automatizado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_NON_DELIVERY_REPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Foi recebido um relatório de não entrega (normalmente uma rejeição)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_DIRECT_COMPLAINT"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Foi recebida uma queixa direta via ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "abuse@hubspot.com"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_MTA_RECORD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nosso provedor de entrega forneceu a mudança durante nossa sincronização normal com seu sistema de registro."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um funcionário da HubSpot fez a mudança."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_MIGRATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A HubSpot migrou as assinaturas de uma versão anterior do produto."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT_CLEANUP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A HubSpot limpou as assinaturas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_KNOWN_SPAM_TRAP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Este endereço de destinatário é uma armadilha de spam conhecida e não deve receber e-mails."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'Eventos de cancelamento de rejeição"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Existe ainda mais um tipo de evento, que não está relacionado a uma mensagem de e-mail específica. Eventos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), " ocorrem quando um determinado endereço de e-mail tem sua ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "rejeição cancelada"
      }), " de forma automática ou manual pela HubSpot. Redefine o status de rejeição do destinatário, permitindo que ele receba e-mails do seu portal."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "user"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O endereço de e-mail do usuário que enviou a solicitação de cancelamento de rejeição."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Referências de eventos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Muitos eventos estão relacionados a outros eventos que ocorreram antes ou depois dele. Conforme descrito na primeira seção acima, usamos EventIds para criar esta cadeia de referência."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Observe que as referências de eventos são relativamente novas e podem não estar preenchidas em eventos mais antigos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'sentBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Conforme discutido anteriormente, cada mensagem de e-mail tem um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " (ou um de cada) associado a ela. Este será o primeiro evento gerado para qualquer mensagem. Se uma mensagem gerar um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), ", todos os eventos gerados posteriormente farão referência a esse evento por meio da propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'sentBy'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Essa referência retroativa pode ser útil para obter mais informações sobre o evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " principal ou para localizar manualmente todos os eventos associados a uma determinada mensagem."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'obsoletedBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Às vezes, ocorre um evento subsequente para uma determinada mensagem, o que significa que um evento anterior deve ser ignorado. Esse relacionamento é capturado em uma referência futura na propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, quando geramos um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " e um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " subsequente, o evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " é, em última análise, irrelevante e é ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "substituído pelo"
      }), " evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), ". Assim, o evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " fará referência ao evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'causedBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Certos eventos ocorrem precisamente por causa de algum evento anterior, muitas vezes por causa de uma mensagem diferente. Esse relacionamento é capturado em uma referência retroativa na propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), ". Pode ser usado para obter detalhes adicionais sobre por que um evento específico causou o evento seguinte."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " ocorrerá quando houver um evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), " anterior para o mesmo destinatário. Neste caso, o evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " terá seu ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'dropReason'"
      }), " definido como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PREVIOUSLY\\_BOUNCED"
      }), " e seu ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), " fará referência ao evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), " anterior."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}