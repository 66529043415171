"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921783;
const slug = exports.slug = 'reference/api/conversations/chat-widget-sdk';
const title = exports.title = 'Conversations | Chat Widget SDK';
const name = exports.name = 'Conversations SDK';
const metaDescription = exports.metaDescription = "HubSpot's Live Chat widget allows you to chat with customers on your website. With the Chat Widget SDK, you can customize the behavior of the chat widget.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "initializing",
  "label": "Initializing",
  "parent": null
}, {
  "depth": 0,
  "id": "configure-conversations-settings",
  "label": "Configure conversations settings",
  "parent": null
}, {
  "depth": 1,
  "id": "inline-embed-styling",
  "label": "Inline embed styling",
  "parent": "configure-conversations-settings"
}, {
  "depth": 0,
  "id": "widget-behavior",
  "label": "Widget behavior",
  "parent": null
}, {
  "depth": 1,
  "id": "widget.load",
  "label": "widget.load",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.refresh",
  "label": "widget.refresh",
  "parent": "widget-behavior"
}, {
  "depth": 2,
  "id": "example",
  "label": "Example",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.open",
  "label": "widget.open",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.close",
  "label": "widget.close",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.remove",
  "label": "widget.remove",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.status",
  "label": "widget.status",
  "parent": "widget-behavior"
}, {
  "depth": 0,
  "id": "clear-chat-cookies",
  "label": "Clear chat cookies",
  "parent": null
}, {
  "depth": 0,
  "id": "chat-events",
  "label": "Chat events",
  "parent": null
}, {
  "depth": 1,
  "id": "conversationstarted",
  "label": "conversationStarted",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "conversationclosed",
  "label": "conversationClosed",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "userselectedthread",
  "label": "userSelectedThread",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "unreadconversationcountchanged",
  "label": "unreadConversationCountChanged",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "contactassociated",
  "label": "contactAssociated",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "userinteractedwithwidget",
  "label": "userInteractedWithWidget",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "widgetloaded",
  "label": "widgetLoaded",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "quickreplybuttonclick",
  "label": "quickReplyButtonClick",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "widgetclosed",
  "label": "widgetClosed",
  "parent": "chat-events"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      img: "img",
      h4: "h4",
      br: "br",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Conversations SDK"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To chat with customers and leads on your website using HubSpot's conversation inbox, you can set up a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/chat-with-your-website-visitors",
        children: "live chat widget"
      }), ". With the conversations SDK, you can provide a more tailored experience for visitors by customizing the behavior of the chat widget."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "At a high level, the conversations SDK enables you to do the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#configure-conversations-settings",
          children: "Configure chat widget settings"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-behavior",
          children: "Control the widget's behavior"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#clear-chat-cookies",
          children: "Clear chat cookies"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#chat-events",
          children: "Listen for and respond to widget events"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-status"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Initializing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The API is housed in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.HubSpotConversations"
      }), " object, which provides access to all available methods. The object is created by the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code",
        children: "HubSpot tracking code"
      }), ", but may not be available immediately on page load. To defer accessing the API until it's initialized, you can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), " helper."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), " is an optional field you can define on the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window"
      }), " object which enables you to specify code to be executed as soon as the widget becomes available. This field takes an array functions to be executed once the API has been initialized."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<script type=\"text/javascript\">\n  function onConversationsAPIReady() {\n    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);\n  }\n  /*\n    configure window.hsConversationsSettings if needed.\n  */\n  window.hsConversationsSettings = {};\n  /*\n   If external API methods are already available, use them.\n  */\n  if (window.HubSpotConversations) {\n    onConversationsAPIReady();\n  } else {\n    /*\n      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.\n      These callbacks will be called once the external API has been initialized.\n    */\n    window.hsConversationsOnReady = [onConversationsAPIReady];\n  }\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configure conversations settings"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This optional object enables you to provide some configuration options to the widget before it initializes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n  inlineEmbedSelector: '#some-id',\n  enableWidgetCookieBanner: true,\n  disableAttachment: true,\n};\nwindow.hsConversationsOnReady = [\n  () => {\n    window.HubSpotConversations.widget.load();\n  },\n];\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loadImmediately"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the widget should implicitly load or wait until the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.load"
            }), " method is called."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inlineEmbedSelector"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Specify a selector (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "#some-id"
            }), ") to embed the chat widget in a specific location on the page. Widget will be embedded inline within that DOM node and will remain open until it is removed with ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.remove"
            }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#inline-embed-styling",
              children: "styling embedded chat widgets"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableWidgetCookieBanner"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Control behavior of the cookie banner for all chat widgets on the page. Options include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), " (default): uses the ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/chatflows/create-a-live-chat#4-options",
                  children: "chat widget's settings"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": presents cookie banners when the widget is loaded."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ON_WIDGET_LOAD"
                }), ": same as ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ON_EXIT_INTENT"
                }), ": enable cookie banners when the user exhibits an exit intent."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disableAttachment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether to hide the upload attachment button in the chat widget."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disableInitialInputFocus"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether to automatically prevent focusing on the widget's input field after an inline embedded widget is initially loaded."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "avoidInlineStyles"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", injects a link tag with externally hosted CSS instead of a direct dynamic insertion of a style tag."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Inline embed styling"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When the widget is embedded in a specific location using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inlineEmbedSelector"
      }), ", several DOM elements are added and can be styled (e.g. height, width, border)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you embed the chat widget using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "#some-id"
      }), " selector, it would be loaded with the following containers and IDs:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div id=\"some-id\">\n  <div id=\"hubspot-conversations-inline-parent\">\n    <iframe id=\"hubspot-conversations-inline-iframe\">\n      <!-- rest of iframe content -->\n    </iframe>\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "You can then customize the chat widget using those selectors, such as:"
          })
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "#hubspot-conversations-inline-iframe {\n  width: 300px;\n  height: 500px;\n  border: none;\n}\n"
          })
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://developers.hubspot.com/hs-fs/hubfs/livechat_after.png?width=600&name=livechat_after.png",
              alt: "livechat_after"
            })
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Widget behavior"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotConversations.widget"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The widget object contains a number of methods that allow you to manipulate the chat widget on your page, including:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-load",
          children: "widget.load"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-refresh",
          children: "widget.refresh"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-open",
          children: "widget.open"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-close",
          children: "widget.close"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-remove",
          children: "widget.remove"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-status",
          children: "widget.status"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about each method."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.load"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.load"
      }), " method handles the initial load on the page. This method is only necessary if you set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loadImmediately"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), ". Otherwise, the widget will load itself automatically."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This method is throttled to one call per second."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.load();\n\n/* ... */\n\n// Force the widget to load in an open state\nwindow.HubSpotConversations.widget.load({ widgetOpen: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "widgetOpen"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether the widget should load in an open state."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.refresh"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.refresh"
      }), " method handles refreshing and re-rendering the widget's information, given the current page URL. This method can be useful for chat widgets embedded in single-page applications when you need to refresh the widget on route changes. This method also enables you to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#example",
        children: "specify different chat widgets on different page routes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you call ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.refresh"
      }), " on a route where there is no chat widget, and the user isn't engaged in a chat, the widget will be removed. It will ", (0, _jsxRuntime.jsx)("u", {
        children: "not"
      }), " remove the widget when there is a currently active chat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This method is throttled to one call per second."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.refresh();\n\n/* ... */\n\n// Force the widget to open to a specific chat flow\nwindow.HubSpotConversations.widget.refresh({ openToNewThread: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "openToNewThread"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether to force a new thread to be created."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Example"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using this method, you could create buttons and links to open specific chatflows on a page by adding query parameters to the page URL."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/conversations-chat-widget-open-crop.gif",
        alt: "conversations-chat-widget-open-crop"
      }), "For example, you could add the following code to your pages to generate the buttons:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div class=\"chat-buttons\">\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_sales', '?sales_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to sales\n  </button>\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_customer_support', '?cs_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to customer support\n  </button>\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_the_ceo', '?ceo_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to the CEO\n  </button>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Then, in each chat's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/chatflows/create-a-live-chat#2-target---decide-where-the-live-chat-should-appear",
        children: "target settings"
      }), ", you would set the chat to display when the query parameter matches the one you've set in your button code.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/conversations-target-rule.png",
        alt: "conversations-target-rule"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.open"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.open"
      }), " method opens the widget if it is not already open or isn't currently loaded."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.open();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.close"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.close"
      }), " method closes the widget if it isn't already closed."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.close();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.remove"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.remove"
      }), " method removes the widget from the page. If the widget isn't present on the page, this method does nothing. The widget will display again on page refresh or if ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.load"
      }), " is invoked."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.remove();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.status"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.status"
      }), " method returns an object containing properties related to the current status of the widget."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const status = window.HubSpotConversations.widget.status();\n\nif (status.loaded) {\n  window.HubSpotConversations.widget.refresh();\n} else {\n  window.HubSpotConversations.widget.load();\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loaded"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether the widget iframe has loaded."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Clear chat cookies"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "clear"
      }), " method deletes cookies related to the chat widget and returns it to its default state on subsequent load."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The chat widget creates several cookies to preserve its state across site visits and page refreshes. These cookies are scoped to the domain of the page hosting the widget, and are used to support the following features:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Referencing historical conversations."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Persisting the open state of the chat widget across page loads."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Persisting the open state of the welcome message across page loads."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following cookies are cleared with this method:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs-messages-is-open"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs-messages-hide-welcome-message"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For more information about these cookies, see ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser",
        children: "check out HubSpot's Knowledge Base"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.clear();\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Additionally, you can pass ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{resetWidget:true}"
      }), " to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "clear()"
      }), " function to clear all chat related cookies, remove the widget from the page, and create a new instance of the chat widget."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.clear({ resetWidget: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Chat events"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The chat widget emits various events you can listen and respond to throughout its lifecycle. These events include:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#conversationstarted",
          children: "conversationStarted"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#conversationclosed",
          children: "conversationClosed"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#userselectedthread",
          children: "userSelectedThread"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#unreadconversationcountchanged",
          children: "unreadConversationCountChanged"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#contactassociated",
          children: "contactAssociated"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#userinteractedwithwidget",
          children: "userInteractedWithWidget"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widgetloaded",
          children: "widgetLoaded"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#quickreplybuttonclick",
          children: "quickReplyButtonClick"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widgetclosed",
          children: "widgetClosed"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To register and remove event listeners, you'll use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "on"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "off"
      }), ", as shown below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const handleEvent = (eventPayload) => console.log(eventPayload);\n\nwindow.HubSpotConversations.on('conversationStarted', handleEvent);\n\n/* ... */\n\nwindow.HubSpotConversations.off('conversationStarted', handleEvent);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Learn more about each event below."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "conversationStarted"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversationStarted"
      }), " event triggers when a new conversation has been successfully started."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('conversationStarted', (payload) => {\n  console.log(\n    `Started conversation with id ${payload.conversation.conversationId}`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The thread ID of the conversation that was started. You can use this ID when making calls to the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "conversations API"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "conversationClosed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversationClosed"
      }), " event triggers when a new conversation has ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/use-the-conversations-inbox#reply-and-comment:~:text=To%20mark%20the%20conversation%20as%20closed",
        children: "marked as closed"
      }), " from the conversations inbox."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Site visitors minimizing or closing the chat widget will not trigger this event. For that event, use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#widgetclosed",
        children: "widgetClosed"
      }), " instead."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('conversationClosed', (payload) => {\n  console.log(\n    `Conversation with id ${payload.conversation.conversationId} has been closed!`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The thread ID of the conversation that was started. You can use this ID when making calls to the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "conversations API"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "userSelectedThread"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userSelectedThread"
      }), " event triggers when creating a thread or selecting an existing thread."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('userSelectedThread', (payload) => {\n  console.log(\n    `User selected thread with ID ${payload.conversation.conversationId}!`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The thread ID of the conversation that was started. You can use this ID when making calls to the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "conversations API"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "unreadConversationCountChanged"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unreadConversationCountChanged"
      }), " event is triggered when the number of conversations with unread messages increases or decreases."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('unreadConversationCountChanged', (payload) => {\n  console.log(`New unread count is ${payload.unreadCount}!`);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unreadCount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The total number of conversations with at least one unread message."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "contactAssociated"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contactAssociated"
      }), " event is triggered when the visitor is associated with a contact in the CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('contactAssociated', (payload) => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A confirmation message that the visitor has been associated with a contact."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "userInteractedWithWidget"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userInteractedWithWidget"
      }), " event is triggered when the visitor interacts with the widget, such as clicking to open the widget or closing the initial welcome message."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on(‘userInteractedWithWidget’, payload => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A confirmation message that the visitor has been interacted with the widget."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widgetLoaded"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgetLoaded"
      }), " event is triggered when the widget iframe is loaded."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on(‘widgetLoaded’, payload => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A confirmation message that the widget iframe has loaded."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "quickReplyButtonClick"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "quickReplyButtonClick"
      }), " event is triggered when the visitor clicks a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/chatflows/a-guide-to-bot-actions#ask-a-question",
        children: "quick reply"
      }), " in a bot conversation."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An array containing the text of the quick reply option that was clicked."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('quickReplyButtonClick', (event) => {\n  console.log(`The text content of the clicked button is ${payload.value[0]}`);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/quick-reply-options-in-bot-conversation.png",
        alt: "quick-reply-options-in-bot-conversation"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the example screenshot above, the bot chatflow contains three quick reply options. If the user selects ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Learn more"
      }), ", the resulting event payload would be:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example event payload when a quick reply option is selected\n{\n  \"name\": \"QUICK_REPLIES\",\n  \"multiSelect\": false,\n  \"value\": [\"Learn more\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widgetClosed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgetClosed"
      }), " event is triggered when the visitor closes the chat widget."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('widgetClosed', (event) => {\n  console.log(event);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A confirmation message that the visitor has closed the chat widget."
          })]
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}