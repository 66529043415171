"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 83455480087;
const slug = exports.slug = 'guides/cms/content/fields/custom-fonts';
const title = exports.title = 'Add custom fonts to a theme';
const name = exports.name = 'Add custom fonts to a theme';
const metaDescription = exports.metaDescription = 'Learn how to include custom fonts in a theme that content creators can use in rich text fields.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "font-files",
  "label": "Font files",
  "parent": null
}, {
  "depth": 0,
  "id": "set-up-the-font-config-font.json",
  "label": "Set up the font config (font.json)",
  "parent": null
}, {
  "depth": 1,
  "id": "variable-fonts",
  "label": "Variable fonts",
  "parent": "set-up-the-font-config-font.json"
}, {
  "depth": 0,
  "id": "add-fonts-to-the-theme-config-theme.json",
  "label": "Add fonts to the theme config (theme.json)",
  "parent": null
}, {
  "depth": 0,
  "id": "limits",
  "label": "Limits",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      h2: "h2",
      img: "img",
      strong: "strong",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      Table
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Table) _missingMdxReference("Table", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Add custom fonts to a theme"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When developing a theme, you can add custom fonts to enable content creators to use them in font fields and rich text fields when building website content. After adding a custom font to the theme, HubSpot will ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#generated-css",
        children: "generate CSS"
      }), " to render the font styling."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "At a high level, to add a custom font to a theme you'll need to add the following to your theme files:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fonts"
        }), " folder that contain the custom font files and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "font.json"
        }), " config file. This folder and its contents can be named and structured in any way."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "font.json"
        }), " file within the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fonts"
        }), " folder that defines the font and its variations for use in the theme."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), ", a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "custom_fonts"
        }), " array containing a list of paths to the font folders within the theme."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about adding fonts to your theme files."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Font files"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your theme folder, you'll first add a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "fonts"
          }), " folder to store the needed font files if you're hosting them locally. While there's no set way to structure the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "fonts"
          }), " folder and its contents, it's important to include several font formats to optimize browser compatibility and to provide content creators with various font weights."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You can include font variations by adding individual files for each variation, or by using a ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#variable-fonts",
            children: "variable font"
          }), " where a single file contains all variations. The method you choose will depend on the font files available to you, and the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "font.json"
          }), " file will need to be configured to match."]
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/font-file-structure.png",
            alt: "font-file-structure"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set up the font config (font.json)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fonts"
      }), " folder, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "font.json"
      }), " file that defines the font and its variations for use in the theme."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "name"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The name of the font."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "default"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["The name of the variant to use as the default, as specified in the ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "variants"
                    }), " array. If no default is specified, HubSpot will automatically designate the first variant in the ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "variants"
                    }), " array as the default."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "fallback"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "Comma-separated fallback fonts to be appended to the end of the custom font CSS. For example:"
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "\"``fallback\": “'Times New Roman', serif”"
                    }), " renders as:"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "font-family: \"Custom font\", \"Times New Roman\", serif"
                    })
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "variants"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "array"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "List of font variants, including the default font, which use the fields below."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px 10px 22px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "name"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The name of the variant."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px 10px 22px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "files"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "array"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["An array containing a list of file paths and formats for the variant fonts. Paths can be relative or absolute. You can reference font files stored locally or external URLs, but you cannot reference stylesheets containing ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "@font-face"
                    }), " declarations."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px 10px 22px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "styles"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "object"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Specifies which CSS styles should be applied to the font variant. If not included, no additional CSS will be applied. Overrides conflicting styles from the top-level ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "styles"
                    }), " object."]
                  })]
                })
              })]
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"name\": \"Noah\",\n  \"default\": \"Regular\",\n  \"fallback\": \"arial, sans-serif\",\n  \"variants\": [\n    {\n      \"name\": \"Regular\",\n      \"files\": [\n        {\n          \"file\": \"./TTF/Noah-Regular.ttf\",\n          \"format\": \"truetype\"\n        },\n        {\n          \"file\": \"./WEB/Noah-Regular.woff\",\n          \"format\": \"woff\"\n        },\n        {\n          \"file\": \"./WEB/Noah-Regular.woff2\",\n          \"format\": \"woff2\"\n        }\n      ],\n      \"styles\": {\n        \"font-weight\": 400\n      }\n    },\n    {\n      \"name\": \"Bold\",\n      \"files\": [\n        {\n          \"file\": \"./TTF/Noah-Bold.ttf\",\n          \"format\": \"truetype\"\n        },\n        {\n          \"file\": \"./WEB/Noah-Bold.woff\",\n          \"format\": \"woff\"\n        },\n        {\n          \"file\": \"./WEB/Noah-Bold.woff2\",\n          \"format\": \"woff2\"\n        }\n      ],\n      \"styles\": {\n        \"font-weight\": \"bold\"\n      }\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Variable fonts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your custom font is a variable font, where the main font and its variations are contained in one file, you'll define your font variants in the same way as a non-variable font where each variant is defined separately. Each variant will then reference the same file within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "file"
      }), " property."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you had a single variable font file, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "myfont.ttf"
      }), ", which contained the bold and italic versions, you would format your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "font.json"
      }), " file as follows:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// font.json\n{\n  \"name\": \"My font\",\n  \"default\": \"Regular\",\n  \"fallback\": \"arial, sans-serif\",\n  \"variants\": [\n    {\n      \"name\": \"Regular\",\n      \"files\": [\n        {\n          \"file\": \"./TTF/myfont.ttf\",\n          \"format\": \"truetype-variations\"\n        }\n      ],\n      \"styles\": {\n        \"font-weight\": 400\n      }\n    },\n    {\n      \"name\": \"Bold\",\n      \"files\": [\n        {\n          \"file\": \"./TTF/myfont.ttf\",\n          \"format\": \"truetype-variations\"\n        }\n      ],\n      \"styles\": {\n        \"font-weight\": \"bold\"\n      }\n    },\n    {\n      \"name\": \"Italics\",\n      \"files\": [\n        {\n          \"file\": \"./TTF/myfont.ttf\",\n          \"format\": \"truetype-variations\"\n        }\n      ],\n      \"styles\": {\n        \"font-style\": \"italic\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To include a variant not contained in the variable font file, you can include a separate font file within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files"
      }), " array for that variant instead of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "styles"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// font.json\n{\n  \"name\": \"My font\",\n  \"files\": [\n    {\n      \"file\": \"./myfont.ttf\",\n      \"format\": \"truetype-variations\"\n    }\n  ],\n  \"variants\": [\n    {\n      \"name\": \"Bold\",\n      \"styles\": { \"font-weight\": 700 }\n    },\n    {\n      \"name\": \"Italic\",\n      \"styles\": { \"font-style\": \"italic\" }\n    },\n    {\n      \"name\": \"My special variant\",\n      \"files\": [\n        {\n          \"file\": \"./myfontvariant.ttf\",\n          \"format\": \"truetype\"\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Add fonts to the theme config (theme.json)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After adding your font files and setting up your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "font.json"
      }), " file, you'll add a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "custom_fonts"
      }), " array listing the paths to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "font"
      }), " folder within the theme."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n \"label\" : \"Atomic_Lite\",\n  …\n \"custom_fonts\": [ \"./fonts/Noah\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limits"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When using the rich text editor in the design manager, font options will ", (0, _jsxRuntime.jsx)("u", {
        children: "not"
      }), " appear in the font selector dropdown menu."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}