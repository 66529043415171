"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 174036411985;
const slug = exports.slug = 'guides/api/marketing/campaigns';
const title = exports.title = 'Marketing API | Campaigns ';
const name = exports.name = 'vNext Docs DP - Campaigns';
const metaDescription = exports.metaDescription = 'These endpoints are used to create and manage campaigns in your HubSpot account.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "scope-requirements",
  "label": "Scope requirements",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-campaign",
  "label": "Create a campaign",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieve-a-campaign",
  "label": "Retrieve a campaign",
  "parent": null
}, {
  "depth": 0,
  "id": "list-assets",
  "label": "List assets",
  "parent": null
}, {
  "depth": 0,
  "id": "manage-asset-associations",
  "label": "Manage asset associations",
  "parent": null
}, {
  "depth": 0,
  "id": "update-campaign",
  "label": "Update campaign",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-a-campaign",
  "label": "Delete a campaign",
  "parent": null
}, {
  "depth": 0,
  "id": "search-for-campaigns",
  "label": "Search for campaigns",
  "parent": null
}, {
  "depth": 0,
  "id": "campaign-properties",
  "label": "Campaign properties",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      em: "em",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Campaigns"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/campaigns/understand-campaigns",
        children: "campaigns tool"
      }), " to campaigns to create, manage, and report on a single marketing campaign using multiple assets in one place. For example, you can create a campaign using marketing emails and social posts and raise awareness of a new product or an event. To use the campaigns tool and its corresponding endpoints, you'll need a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Professional"
      }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " subscription."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use campaign endpoints to create, read, update, and delete marketing campaign data. For example, you can use these endpoints to create and manage HubSpot campaigns from your external applications. You can also transfer campaign data to external data warehouses for analytics."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also create, read, update, and delete multiple campaigns with the associated batch endpoints, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/marketing/campaigns",
        children: "reference documentation"
      }), " for a full list of endpoints and the associated parameters available."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Scope requirements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following scopes are required to use the campaigns API, based on the endpoints you're using:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "marketing.campaigns.read"
        }), ": provides access to view details about marketing campaigns and their associated assets."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "marketing.campaigns.write"
        }), ": grants access to create, delete, and modify marketing campaigns."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "marketing.campaigns.revenue.read"
        }), ": provides access to view revenue details and deal amounts attributed to a marketing campaign."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a campaign"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a campaign, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/campaigns"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Create a campaign with the given properties and return the campaign object, including the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "campaignGuid"
      }), " and created properties. For example, to create a new campaign, you would make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request with the following request body."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "//Example create request body\n\n{\n  \"properties\": {\n    \"hs_name\": \"Inbound\",\n    \"hs_start_date\": \"2024-10-30\",\n    \"hs_notes\": \"Campaign notes\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response will include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "campaignGuid"
      }), ", a unique identifier for the campaign. This will be formatted as a UUID. In the following example, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "campaignGuid"
      }), " is ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "edb9b6c3-d2e2-4ca8-8396-832262aed0d4"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "//Example create response body\n\n{\n  \"id\": \"edb9b6c3-d2e2-4ca8-8396-832262aed0d4\",\n  \"properties\": {\n    \"hs_name\": \"Inbound\",\n    \"hs_start_date\": \"2024-10-30\",\n    \"hs_notes\": \"Campaign notes\"\n  },\n  \"createdAt\": \"2024-10-30T03:30:17.883Z\",\n  \"updatedAt\": \"2024-12-07T16:50:06.678Z\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve a campaign"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve the details of an existing campaign, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/campaigns/{campaignGuid}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This endpoint will return the campaign information. Depending on the query parameters used, this can also be used to return information about the assets and the corresponding assets' metrics. When using this endpoint, you can use the following query parameters."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " a comma-separated list of the properties to be returned in the response. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_name"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_campaign_status"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_notes"
        }), ". If any of the specified properties has an empty value on the requested object, it will be ignored and not returned in response. If the parameter is empty, the response will include an empty properties map."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "startDate"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the start date to fetch asset metrics, formatted as YYYY-MM-DD. This is used to fetch the metrics associated with the assets for a specified period. If no date is specified, no asset metrics will be retrieved."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "endDate"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the end date to fetch asset metrics, formatted as YYYY-MM-DD. This is used to fetch the metrics associated with the assets for a specified period. If no date is specified, no asset metrics will be retrieved."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you made a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/campaigns/edb9b6c3-d2e2-4ca8-8396-832262aed0d4?properties=hs_name,hs_start_date"
      }), ", this would result in the following response body:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "//Example get response body\n\n{\n  \"id\": \"edb9b6c3-d2e2-4ca8-8396-832262aed0d4\",\n  \"properties\": {\n    \"hs_name\": \"Inbound\",\n    \"hs_start_date\": \"2024-10-30\"\n  },\n  \"assets\": {\n    \"EMAIL\": {\n      \"paging\": {\n        \"next\": {\n          \"link\": \"?after=NTI1Cg%3D%3D\",\n          \"after\": \"NTI1Cg%3D%3D\"\n        },\n        \"results\": [\n          {\n            \"id\": \"832\",\n            \"name\": \"My email\"\n          }\n        ]\n      }\n    }\n  },\n  \"createdAt\": \"2024-10-30T03:30:17.883Z\",\n  \"updatedAt\": \"2024-12-07T16:50:06.678Z\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "List assets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve the assets associated with a campaign make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/campaigns/{campaignId}/assets/{assetType}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This endpoint lists all assets of the campaign by asset type. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "assetType"
      }), " parameter is required, and each request can only fetch assets of a single type. The following are required:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignGuid"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the UUID of the campaign."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "assetType"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the type of asset to be fetched."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use the following query parameters with the endpoint:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "after"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " a cursor for pagination. If provided, the results will start after the given cursor. For example, you can use the value NTI1Cg%3D%3D."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " define the maximum number of results to return. The allowed values range from 1 to 100. If no limit is specified, it will be set to 10 assets by default."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "startDate"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the start date to fetch asset metrics, formatted as YYYY-MM-DD. This is used to fetch the metrics associated with the assets for a specified period. If no date is specified, no asset metrics will be retrieved."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "endDate"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the end date to fetch asset metrics, formatted as YYYY-MM-DD. This is used to fetch the metrics associated with the assets for a specified period. If no date is specified, no asset metrics will be retrieved."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following is a list of available asset types and metrics related to the assets that are returned in response.:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Asset"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Asset type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Metrics"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ad campaigns"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "AD_CAMPAIGN"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CLICKS, CONTACTS, and COST"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog posts"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CONTACTS\\_FIRST\\_TOUCH, CONTACTS\\_LAST\\_TOUCH, SUBMISSIONS, and VIEWS"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Social posts"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SOCIAL_BROADCAST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "FACEBOOK\\_CLICKS, LINKEDIN\\_CLICKS, and TWITTER\\_CLICKS"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTAs"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEB_INTERACTIVE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CLICKS and VIEWS"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTAs (Legacy)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CTA"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CLICKS, SUBMISSIONS, and VIEWS"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "External website pages"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL_WEB_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "VIEWS"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Forms"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FORM"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CONVERSION\\_RATE, SUBMISSIONS, and VIEWS"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Landing pages"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LANDING_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CONTACTS\\_FIRST\\_TOUCH, CONTACTS\\_LAST\\_TOUCH, CUSTOMERS, SUBMISSIONS, and VIEWS"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Emails"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CLICKS, OPEN, and SENT"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Marketing events"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ATTENDEES, CANCELLATIONS, and REGISTRATIONS"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Static lists"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "OBJECT_LIST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CONTACTS"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Website pages"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SITE_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CONTACTS\\_FIRST\\_TOUCH, CONTACTS\\_LAST\\_TOUCH, CUSTOMERS, SUBMISSIONS, and VIEWS."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Workflows"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "AUTOMATION_PLATFORM_FLOW"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CURRENTLY\\_ENROLLED and STARTED"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Marketing SMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_SMS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "SENT, DELIVERED, and UNIQUE\\_CLICKS"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Manage asset associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To associate an asset with a campaign make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/campaigns/{campaignGuid}/assets/{assetType}/{assetId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove an asset from a campaign make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/campaigns/{campaignGuid}/assets/{assetType}/{assetId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the endpoints above to create and remove associations for the following asset types. For other asset types, learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/campaigns/associate-assets-and-content-with-a-campaign",
        children: "associate assets and content with a campaign"
      }), " in HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FORM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "OBJECT_LIST"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "EXTERNAL_WEB_URL"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update campaign"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To perform a partial update of a campaign identified by the specified campaignGuid, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/campaigns/{campaignGuid}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the following properties to update your campaign. The provided property values will be overwritten. If your request includes read-only or non-existent properties, you will encounter a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "400 error"
      }), ". Learn more about which properties can be used."]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "//Example update request\n\n{\n  \"properties\": {\n    \"hs_name\": \"Inbound 2024\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "//Example update response\n\n{\n  \"id\": \"edb9b6c3-d2e2-4ca8-8396-832262aed0d4\",\n  \"properties\": {\n    \"hs_name\": \"Inbound 2024\"\n  },\n  \"createdAt\": \"2024-10-30T03:30:17.883Z\",\n  \"updatedAt\": \"2024-12-07T16:50:06.678Z\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete a campaign"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete a campaign, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/campaigns?"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This call will always return a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "204 No Content"
      }), " response, regardless of whether the campaignGuid provided corresponds to an existing campaign or not."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Search for campaigns"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To search for campaigns based on query parameters, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/campaigns/{campaignGuid}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When using this endpoint, you can use the following query parameters."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " return all campaigns whose names contain the specified substring. This allows partial matching of campaign names, returning all campaigns that include the given substring in their name. If this parameter is not provided, the search will return all campaigns."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "sort"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " the field by which to sort the results. Allowed values are hs_name, createdAt, updatedAt. An optional '-' before the property name will denote descending order. For example, you can use -CREATED_AT to sort your campaigns from newest to oldest. If this is not specified, the list of campaigns will be sorted in alphabetical order by campaign name."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "after"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " a cursor for pagination. If provided, the results will start after the given cursor. For example, you can use the value NTI1Cg%3D%3D."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " define the maximum number of results to return. The allowed values range from 1 to 100. If no limit is specified, it will be set to 50 campaigns by default."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "properties:"
        }), " a comma-separated list of the properties to be returned in the response. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_name"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_campaign_status"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_notes"
        }), ". If any of the specified properties has an empty value on the requested object, it will be ignored and not returned in response. If the parameter is empty, the response will include an empty properties map."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "//Example reponse body\n// for GET request to https://api.hubapi.com/marketing/v3/campaigns?properties=hs_name&limit=2\n{\n  \"total\": 14,\n  \"results\": [\n    {\n      \"id\": \"972e2774-7409-43c2-a8b9-581732dff5a7\",\n      \"properties\": {\n        \"hs_name\": \"Inbound 2023\"\n      },\n      \"createdAt\": \"2023-09-07T10:18:06.320Z\",\n      \"updatedAt\": \"2023-09-07T10:18:06.320Z\"\n    },\n    {\n      \"id\": \"703bc2f0-f000-4840-b6ae-b2d74bc09fa0\",\n      \"properties\": {\n        \"hs_name\": \"Inbound 2024\"\n      },\n      \"createdAt\": \"2024-06-25T10:57:26.232Z\",\n      \"updatedAt\": \"2024-06-25T10:57:26.232Z\"\n    }\n  ],\n  \"paging\": {\n    \"next\": {\n      \"after\": \"Mg%3D%3D\",\n      \"link\": \"https://api.hubspotqa.com/marketing/v3/campaigns? properties=hs_name&limit=2&after=Mg%3D%3D\"\n    }\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campaign properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When using the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "properties"
      }), " query parameter for your campaign endpoints, you can use the following properties:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property Name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Read Only"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Format"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read & write"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The campaign's name. This should be unique, with a max size of 256 characters."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read & write"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "date (YYYY-MM-DD)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The campaign's start date."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_end_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read & write"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "date (YYYY-MM-DD)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The campaign's end date."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_notes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read & write"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Any notes about the campaign."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_audience"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read & write"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The campaign's audience."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_goal"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read & write"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The campaign's goal."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_currency_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read & write"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ISO currency code"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The currency code used for the campaign."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_campaign_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read & write"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "predefined values"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The status of the campaign. This includes ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "planned"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in_progress"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "active"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "paused"
            }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "completed"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_owner"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "user id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The user id of the user that owns the campaign"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_color_hex"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "color id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The color of the campaign in hex color code."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_created_by_user_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "user id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The user id of the user that originally created the campaign"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The internal CRM object id."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_budget_items_sum_amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "monetary value"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The sum of all budget items. Learn more about managing your ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/campaigns/manage-your-campaign-budget",
              children: "campaign budget"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_spend_items_sum_amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "read"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "monetary value"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The sum of all spend items. Learn more about managing your ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/campaigns/manage-your-campaign-budget",
              children: "campaign budget"
            }), "."]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}