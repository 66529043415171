"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79933035647;
const slug = exports.slug = 'guides/cms/marketplace/template-guidelines';
const title = exports.title = 'Directives du marketplace des modèles HubSpot';
const name = exports.name = 'EMEA (FR) Marketplace Requirements Overview';
const metaDescription = exports.metaDescription = "Le marketplace des modèles HubSpot aide des milliers de clients sans accès direct à un développeur à développer leur présence en ligne. Il est propulsé par des développeurs comme vous, qui créent des thèmes, modèles et modules beaux et faciles d'emploi.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "pourquoi-devrais-je-vendre-des-mod%C3%A8les-sur-le-marketplace-%3F",
  "label": "Pourquoi devrais-je vendre des modèles sur le marketplace ?",
  "parent": null
}, {
  "depth": 0,
  "id": "pr%C3%A9sentation-et-exigences",
  "label": "Présentation et exigences",
  "parent": null
}, {
  "depth": 0,
  "id": "lister-et-mettre-%C3%A0-jour-les-th%C3%A8mes-sur-le-marketplace-des-mod%C3%A8les",
  "label": "Lister et mettre à jour les thèmes sur le marketplace des modèles",
  "parent": null
}, {
  "depth": 0,
  "id": "passerelles-de-paiement-prises-en-charge",
  "label": "Passerelles de paiement prises en charge",
  "parent": null
}, {
  "depth": 0,
  "id": "demandes-de-remboursement",
  "label": "Demandes de remboursement",
  "parent": null
}, {
  "depth": 0,
  "id": "g%C3%A9rer-vos-transactions",
  "label": "Gérer vos transactions",
  "parent": null
}, {
  "depth": 0,
  "id": "suppression-de-listing-de-mod%C3%A8le",
  "label": "Suppression de listing de modèle",
  "parent": null
}, {
  "depth": 0,
  "id": "foire-aux-questions",
  "label": "Foire aux questions",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      em: "em",
      br: "br",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ContentCardsRow,
      ContentCard,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ContentCard) _missingMdxReference("ContentCard", true);
  if (!ContentCardsRow) _missingMdxReference("ContentCardsRow", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Directives du marketplace des modèles HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "marketplace des modèles HubSpot"
      }), " aide des milliers de clients sans accès direct à un développeur à développer leur présence en ligne. Il est alimenté par des développeurs qui créent des modèles faciles à utiliser pour les clients."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez vendre deux types de modèles sur le marketplace des modèles HubSpot : des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/website",
        children: "thèmes"
      }), " et des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/modules",
        children: "modules"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pourquoi devrais-je vendre des modèles sur le marketplace ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le marketplace HubSpot a beaucoup à offrir aux développeurs ou aux agences qui cherchent à créer une nouvelle source de revenus."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Non exclusif :"
        }), " Vous avez travaillé dur pour créer vos modèles. Votre modèle ne devrait pas être uniquement disponible sur notre marketplace. Vous êtes également libre d'utiliser vos modèles en dehors du marketplace pour vos propres projets."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créez une fois, vendez plusieurs fois :"
        }), " une fois que votre modèle est listé sur le marketplace, il devient une occasion multiple. Il vous suffit de créer une seule fois votre modèle pour ensuite le vendre à plusieurs clients."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Accélérer la croissance de votre entreprise :"
        }), " beaucoup de développeurs qui vendent leurs modèles (gratuits et payants) sur notre marketplace ont vu leurs entreprises croître à la fois grâce aux revenus du marketplace et aux services supplémentaires demandés par les acheteurs."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rejoignez une communauté de créateurs et mettez en valeur vos compétences :"
        }), " connectez-vous à un écosystème en pleine croissance et favorable pour aider à façonner l'avenir du marketplace des modèles HubSpot tout en apportant plus de visibilité à vos offres et services."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Présentation et exigences"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour commencer à vendre sur le marketplace de modèles, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "créez un compte fournisseur de marketplace de modèles"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Toute soumission de modèle doit respecter les exigences de conformité, de conception et de qualité de code. Chaque type de modèle a également ses propres exigences, qui doivent être remplies afin d'être listé sur le marketplace. Vous pouvez consulter ces critères ci-dessous :"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Avant de commencer à créer des modèles pour le marketplace, HubSpot recommande vivement de consulter ses directives sur la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/template-providers",
          children: "création pour le marketplace des modèles HubSpot"
        }), ", le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/themes/branding-guidelines",
          children: "branding"
        }), ", l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/tm-usage-guidelines",
          children: "utilisation des marques de commerce"
        }), " et l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/content-usage-guidelines",
          children: "utilisation du contenu"
        }), ". Les modèles qui enfreignent ces directives seront immédiatement supprimés du marketplace, comme décrit dans les conditions supplémentaires de HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(ContentCardsRow, {
      children: [(0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Exigences en matière de thème"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Un ensemble portable et contenu de modèles, de modules, de contenus globaux et de paramètres de style conçus pour fonctionner ensemble et permettre une expérience d'édition de contenu conviviale pour les professionnels du marketing."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "en-us/guides/cms/marketplace/theme-requirements",
            children: "Consulter les exigences en matière de thème"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Exigences en matière de module"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Les thèmes soumis contiennent des modules, qui sont des composants réutilisables pouvant être utilisés dans des modèles ou ajoutés à des pages via des zones de glisser-déposer et des colonnes flexibles. Vous pouvez également soumettre des modules indépendants. Ce sont des modules qui peuvent être ajoutés à n'importe quel thème dans l'éditeur de contenu de HubSpot."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "en-us/guides/cms/marketplace/module-requirements",
            children: "Consulter les exigences en matière de module"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Conditions relatives aux pages de listing"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Les conditions auxquelles il faut se conformer lors de la soumission de modèles et de leurs pages de listing sur le marketplace des modèles HubSpot. Ces normes s'appliquent à tous les modèles présents sur le marketplace."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/marketplace/general-requirements",
            children: "Afficher les conditions relatives aux pages de listing"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Politiques du marketplace des modèles"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Conditions et détails de conformité pour les modèles de listing sur le marketplace des modèles."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/marketplace/template-policies",
            children: "Afficher les politiques du marketplace des modèles"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lister et mettre à jour les thèmes sur le marketplace des modèles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que vous avez créé votre modèle, soumettez-le pour approbation afin qu'il soit répertorié sur le marketplace des modèles HubSpot. Une fois soumis, les modèles seront soumis à un processus d'examen et d'approbation par l'équipe du marketplace des modèles HubSpot. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "Découvrez comment lister et mettre à jour vos modèles dans le marketplace des modèles."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Passerelles de paiement prises en charge"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot offre actuellement la possibilité aux fournisseurs du marketplace de se connecter aux passerelles de paiement suivantes pour recevoir des paiements :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["PayPal (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.paypal.com/fr/webapps/mpp/country-worldwide",
          children: "pays pris en charge"
        }), " | ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.paypal.com/us/smarthelp/article/what-are-the-cross-border-fees-when-selling-international-faq2927",
          children: "paiements internationaux"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stripe (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/global",
          children: "pays pris en charge"
        }), " | ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/docs/connect/cross-border-payouts",
          children: "paiements internationaux"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les paiements des clients sont reçus par la méthode de paiement connectée à votre compte de fournisseur de marketplace :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Informations sur le fournisseur"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Mode de paiement"
        }), ", consultez ou mettez à jour les informations de paiement."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot n'est pas responsable des problèmes de passerelle de paiement qui peuvent survenir pendant le processus d'achat. HubSpot ne touche rien sur les transactions et toutes les transactions sont traitées indépendamment de la plateforme HubSpot. Si votre mode de paiement cesse de fonctionner pour quelque raison que ce soit, vos ressources peuvent être temporairement radiées de la liste pour éviter une expérience client négative."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " HubSpot a des clients dans le monde entier. Lors de la configuration de vos passerelles de paiement, nous vous recommandons vivement d'accepter les paiements provenant de devises internationales (paiements internationaux)."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["HubSpot encourage fortement les fournisseurs du marketplace des modèles à utiliser la passerelle de paiement Stripe si elle est disponible dans leur pays. Si Stripe n'est pas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/global",
          children: "disponible dans votre pays"
        }), ", nous recommandons l'utilisation de la passerelle de paiement PayPal."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Demandes de remboursement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous êtes susceptible de recevoir des demandes de remboursement de la part d'acheteurs. HubSpot recommande d'indiquer clairement votre politique de remboursement dans la description d'un listing et de fournir un lien vers la documentation relative à votre politique de remboursement. HubSpot encourage les fournisseurs à honorer les demandes de remboursement valides. Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/resources/marketplace-refund-guidelines",
        children: "directives générales relatives aux demandes de remboursement"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gérer vos transactions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fiche d'informations de transaction est créée dans votre compte fournisseur du marketplace des modèles pour chaque modèle téléchargé par un client. Toutes les transactions seront acheminées dans un pipeline de transactions appelé ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Transactions du marketplace"
      }), " avec la phase ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Transactions"
      }), ". Lorsqu'un remboursement est émis pour une transaction, la propriété ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Remboursé à"
      }), " sera définie sur la fiche d'informations de transaction."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fournisseurs peuvent ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "personnaliser le pipeline de transactions ainsi que les phases de transaction"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows/choose-your-workflow-actions#property-management",
        children: "utiliser des propriétés de transaction dans des workflows"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suppression de listing de modèle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot se réserve le droit de supprimer les listings de marketplace pour des raisons comprenant, sans s'y limiter :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Des bugs sont présents dans le modèle."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Des modèles tiers sont présents dans la soumission."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le modèle est incompatible avec les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/resources/which-browsers-are-supported-by-hubspot",
          children: "navigateurs pris en charge par HubSpot."
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "L'utilisation de contenu protégé par des droits d'auteur, comme des images, des icônes, des polices, des fichiers audio et des vidéos non concédés sous licence."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Des modèles suivent les utilisateurs sans leur consentement éclairé et explicite."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Des modèles (y compris un lien vers du contenu à partir du marketplace des modèles ou de votre démo du modèle) qui comprennent :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "la promotion ou l'engagement à des activités illégales ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "du contenu péjoratif, dénigrant et/ou malveillant ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "des revendications malhonnêtes ou fausses ;"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "du contenu offensant sur la race, la religion, l'âge, le sexe ou l'orientation sexuelle."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les modules seront supprimés s'ils affectent d'autres éléments sur la page/l'e-mail auquel ils sont ajoutés."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Foire aux questions"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Does the European Union P2B regulation (July 12,2020) apply to the App and Template Marketplaces?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No, the most recent European Union P2B regulation that took effect on July 12, 2020, does not apply to the HubSpot App and Template Marketplaces. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ec.europa.eu/digital-single-market/en/business-business-trading-practices",
          children: "Learn More"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Is there a specific HubSpot account type I need to submit to the Marketplace?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://offers.hubspot.com/free-marketplace-account",
          children: "Marketplace provider account"
        }), " is needed to submit to the Template Marketplace."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Some partners manage their Marketplace listings in their Partner account to use the templates in the same account. Other partners manage their listings in a separate Marketplace account to keep the templates separate from other templates in their design manager tool."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Do products published in the Marketplace live in my account or in the HubSpot Marketplace system?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Products submitted to or published in the Marketplace live in the HubSpot Marketplace system. This ensures that providers can continue to edit them in their own account without the changes affecting customers’ purchases. To update a Marketplace listing, a provider must resubmit the listing so it can be reviewed and republished."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Customers who have purchased previous versions of a product in a listing can ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/articles/kcs_article/resources/marketplace-customers-frequently-asked-questions#update",
          children: "update to the latest version"
        }), " free of charge."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How do I update my company name?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Once you have listed a template in the marketplace, you will need to contact ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://help.hubspot.com/",
          children: "HubSpot Support"
        }), "  to change your company name."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Why don't I see all of my products when trying to create a listing?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Possible reasons you may not see a product when trying to create a listing include:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template is currently listed in the Marketplace or contains elements from a currently listed template."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template isn't supported in the Marketplace (transactional emails, ticket emails, etc.)"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template is a clone of a template from the Marketplace."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "What should I use as a preview link?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["When creating preview URLs you must use your own domain name if available. If you don't have a domain available to use, HubSpot provides each account with a system domain in the following URL structure:", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[AccountID].hs-sites.com"
        })]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": HubSpot does not support template previews that use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "preview.hs-sites.com"
        }), " subdomain."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Can a single template be used in multiple themes?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Yes, a single template can be used in multiple themes. A common example of when a template may be used in multiple themes is if a provider offers a Starter and Premium version of the same theme."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Do I have to resubmit a theme or module if I make changes to it?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Yes. All changes to a theme or module must be re-submitted to the Marketplace for pre-validation and approval by the quality team. Once the changes are approved, the listing and template will be updated in the Marketplace for customers."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How do I adjust the price of a theme or module?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "To adjust the price of a submitted product, you must resubmit it to the Marketplace with updated information. Prices cannot be changed from free to paid, or vice versa once a template is published."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "How do I receive Marketplace payments?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Payments are received through the payment method that you've connected in your Marketplace Provider Profile. In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Provider Info"
        }), ". In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Payment Method"
        }), " section, view or update your payment information."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot doesn’t take a cut of any transactions, and all transactions are handled independently of the HubSpot platform."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Where do I go to get peer-to-peer feedback or connect with other Marketplace providers? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Join the #marketplace channel in HubSpot's Developer Slack by signing up at ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "https://developers.hubspot.com/slack"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "When a customer requests a refund, do their downloaded files remain in their HubSpot account?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "All files associated with the template as well as any dependencies (e.g. pages created with the template, cloned templates, and modules) will be removed once the refund is initiated."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How can I stay informed on the latest developer requirements and changes? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To stay informed of the latest changes, visit and subscribe to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "HubSpot Developer Changelog"
        }), "."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}