import { GRID_BREAKPOINT_EXTRA_LARGE, GRID_BREAKPOINT_LARGE, GRID_BREAKPOINT_SMALL } from 'HubStyleTokens/sizes';

/**
 * Viewport sizes from the original design. Generally used with
 * the `mediaQueries` CSS string helper.
 */
export const viewportSizes = {
  mobile: 360,
  tablet: 834,
  laptop: 1440,
  desktop: 1920,
  widescreen: 2560
};

/** The breakpoint where the primary nav transitions to a hamburger menu. */
export const MOBILE_MENU_BREAKPOINT = 1024;
export const LANDING_PAGE_WIDTH = 1096;

/**
 * The numerical equivalent of `GRID_BREAKPOINT_EXTRA_LARGE` from
 * `HubStyleTokens/sizes`.
 */
// eslint-disable-next-line radix
export const NUM_GRID_BREAKPOINT_EXTRA_LARGE = parseInt(GRID_BREAKPOINT_EXTRA_LARGE, 0);

/**
 * The numerical equivalent of `GRID_BREAKPOINT_LARGE` from
 * `HubStyleTokens/sizes`.
 */
// eslint-disable-next-line radix
export const NUM_GRID_BREAKPOINT_LARGE = parseInt(GRID_BREAKPOINT_LARGE, 0);

/**
 * The numerical equivalent of `GRID_BREAKPOINT_SMALL` from
 * `HubStyleTokens/sizes`.
 */
// eslint-disable-next-line radix
export const NUM_GRID_BREAKPOINT_SMALL = parseInt(GRID_BREAKPOINT_SMALL, 0);
function aboveWidth(width) {
  return `@media screen and (min-width: ${width}px)`;
}
function belowWidth(width) {
  return `@media screen and (max-width: ${width - 1}px)`;
}
function betweenWidths(minWidth, maxWidth) {
  return `@media screen and (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
}

/**
 * Use `belowVIEWPORT` and `aboveVIEWPORT` to output a media query, where
 * `VIEWPORT` is one of the available viewports in the `viewportSizes` object
 * (`Mobile`, `Tablet`, etc.).
 *
 * To use custom viewport widths, use `mediaQueries.belowWidth`,
 * `mediaQueries.aboveWidth`, and `mediaQueries.betweenWidths`.
 *
 * @example
 * ```tsx
 * const CoolComponent = styled.div`
 *   margin-top: 5px; // above tablet viewport width (the default)
 *   ${mediaQueries.belowTablet} {
 *      margin-top: 50px;  // below tablet viewport width
 *   }
 * `;
 * ```
 */
export const mediaQueries = {
  /**
   * Targets screens narrower than 360px.
   * @see `mediaQueries` for usage.
   */
  belowMobile: belowWidth(viewportSizes.mobile),
  /**
   * Targets screens narrower than 834px.
   * @see `mediaQueries` for usage.
   */
  belowTablet: belowWidth(viewportSizes.tablet),
  /**
   * Targets screens narrower than 1440px.
   * @see `mediaQueries` for usage.
   */
  belowLaptop: belowWidth(viewportSizes.laptop),
  /**
   * Targets screens narrower than 1920px.
   * @see `mediaQueries` for usage.
   */
  belowDesktop: belowWidth(viewportSizes.desktop),
  /**
   * Targets screens narrower than 2560px.
   * @see `mediaQueries` for usage.
   */
  belowWidescreen: belowWidth(viewportSizes.widescreen),
  /**
   * Targets screens with a minimum viewport width of 360px.
   * @see `mediaQueries` for usage.
   */
  aboveMobile: aboveWidth(viewportSizes.mobile),
  /**
   * Targets screens with a minimum viewport width of 834px.
   * @see `mediaQueries` for usage.
   */
  aboveTablet: aboveWidth(viewportSizes.tablet),
  /**
   * Targets screens with a minimum viewport width of 1440px.
   * @see `mediaQueries` for usage.
   */
  aboveLaptop: aboveWidth(viewportSizes.laptop),
  /**
   * Targets screens with a minimum viewport width of 1920px.
   * @see `mediaQueries` for usage.
   */
  aboveDesktop: aboveWidth(viewportSizes.desktop),
  /**
   * Targets screens with a minimum viewport width of 2560px.
   * @see `mediaQueries` for usage.
   */
  aboveWidescreen: aboveWidth(viewportSizes.widescreen),
  /**
   * Targets screens narrower than a certain viewport.
   *
   * @example
   * ```tsx
   * const CoolComponent = styled.div`
   *   margin-top: 5px; // >=500px viewport width
   *   ${mediaQueries.belowWidth(500)} {
   *      margin-top: 50px;  // <500px viewport width
   *   }
   * `;
   * ```
   */
  belowWidth,
  /**
   * Targets screens wider than a certain viewport.
   *
   * @example
   * ```tsx
   * const CoolComponent = styled.div`
   *   margin-top: 5px; // below 500px viewport width
   *   ${mediaQueries.aboveWidth(500)} {
   *      margin-top: 50px;  // above 500px viewport width
   *   }
   * `;
   * ```
   */
  aboveWidth,
  /**
   * Targets screens between two viewport widths.
   *
   * @example
   * ```tsx
   * const CoolComponent = styled.div`
   *   ${mediaQueries.betweenWidths(360, 1440)} {
   *      font-size: 2vw;
   *   }
   * `;
   * ```
   */
  betweenWidths,
  /**
   * A media query that detects if hover is the primary input mechanism.
   * Don't forget to target via `&:hover` as seen in example below.
   *
   * @example
   * ```@tsx
   * const CoolComponent = styled(UIRouterLink)`
   *   color: ${HEFFALUMP};
   *   ${mediaQueries.hover} {
   *     &:hover {
   *       color: ${CALYPSO};
   *     }
   *   }
   * `;
   * ```
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover
   */
  hover: `@media (hover: hover)`
};