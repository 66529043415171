"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 36996094881;
const slug = exports.slug = 'guides/cms/quickstart';
const title = exports.title = 'Quickstart guide to developing on the HubSpot CMS';
const name = exports.name = 'Quickstart guide to developing on the HubSpot CMS';
const metaDescription = exports.metaDescription = 'Get started building websites on CMS Hub. Get your development environment set up, and deploy your first project.';
const position = exports.position = 3;
const toc = exports.toc = [{
  "depth": 0,
  "id": "before-you-begin",
  "label": "Before you begin",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-install-the-hubspot-cli",
  "label": "1. Install the HubSpot CLI",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-configure-the-local-development-tools",
  "label": "2. Configure the local development tools",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-create-a-theme",
  "label": "3. Create a theme",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-upload-your-theme-to-hubspot",
  "label": "4. Upload your theme to HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-create-a-website-page",
  "label": "5. Create a website page",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-edit-a-css-file",
  "label": "6. Edit a CSS file",
  "parent": null
}, {
  "depth": 0,
  "id": "what-s-next%3F",
  "label": "What's next?",
  "parent": null
}, {
  "depth": 0,
  "id": "join-the-hubspot-cms-developer-community",
  "label": "Join the HubSpot CMS developer community",
  "parent": null
}, {
  "depth": 1,
  "id": "developer-slack-community",
  "label": "Developer Slack community",
  "parent": "join-the-hubspot-cms-developer-community"
}, {
  "depth": 1,
  "id": "developer-forums",
  "label": "Developer forums",
  "parent": "join-the-hubspot-cms-developer-community"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      ol: "ol",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      IFrame
    } = _components;
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot CMS quickstart guide"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot's CMS is a powerful, flexible platform for creating HubSpot websites, including website pages, blogs, and lightweight apps. It features built-in security and reliability features, along with a globally distributed Content Delivery Network (CDN) that ensures fast page load times."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When developing on the HubSpot CMS, you can use your preferred tools, technologies, and workflows, such as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "GitHub"
      }), ", while developing websites. Content creators can then create pages and publish content using drag and drop editors. And because the CMS is integrated with the CRM, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "create dynamic website experiences"
      }), " for your visitors based on the data you already have."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Before you begin"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before you start, ensure you've done the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Create a free test account to build without impacting a production environment. You can use either of the following types of accounts:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Create a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/getting-started/account-types#app-developer-accounts",
              children: "developer account"
            }), ", then create a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/getting-started/account-types#developer-test-accounts",
              children: "test account"
            }), " within it. Because you can also build private apps in developer test accounts, along with building public apps in developer accounts, you'll have one home for both CMS and app development."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Create a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/getting-started/account-types#cms-sandbox-accounts",
              children: "CMS developer sandbox account"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Install ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://nodejs.org/en/",
            children: "Node.js"
          }), ", which enables HubSpot's local development tools. Versions 18 or higher are supported."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Install the HubSpot CLI"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you're ready to begin, open a terminal window and create or navigate to the directory where you want your local HubSpot files to live. This working directory is where the theme and its associated files will be placed."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Next, run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "npm install -g @hubspot/cli@latest"
      }), " to install the HubSpot CLI, which introduces an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs"
      }), " command that allows you to easily interact with your HubSpot account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Configure the local development tools"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs init"
      }), " to connect the tools to your HubSpot account. This command will walk you through the following steps:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["First you’ll be guided to create a personal access key to enable authenticated access to your account via the local development tools. You’ll be prompted to press \"Enter\" when you’re ready to open the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/personal-access-key",
          children: "Personal Access Key page"
        }), " in your default browser. This page will allow you to view or generate your personal access key, if necessary. (Note: You’ll need to select at least the \"Design Manager\" permission in order to complete this tutorial.) Copy your access key and paste it in the terminal."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Next, you’ll enter a name for the account. This name is only seen and used by you, For example, you might use \"sandbox\" if you're using a developer sandbox or \"company.com\" if you’re using a full customer account. This name will be used when running commands."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you've completed this simple ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "init"
      }), " flow, you'll see a success message confirming that a configuration file, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: "hubspot.config.yml"
      }), ", has been created in your current directory."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Create a theme"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create website-theme my-website-theme"
      }), " to create a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-website-theme"
      }), " directory populated with files from the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "CMS theme boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/172845081773",
      width: "543px",
      height: "370.41461100569256px"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Upload your theme to HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-website-theme my-website-theme"
      }), " to upload your new theme to a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-website-theme"
      }), " folder in your HubSpot account."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once this task has completed, you can view these files in the design manager of your HubSpot account. The design manager is an in-app code editor that displays the developer file system, and can be found by navigating to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/design-manager/",
        children: "Content > Design Manger"
      }), " in the left sidebar of your account."]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/172854537757",
      width: "628px",
      height: "430px"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Create a website page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To experience how content creators will use your templates and modules, create a website page using the theme you just uploaded."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/website",
          children: "Content > Website Pages"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website page"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " for your page, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create page"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the next page, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-website-theme"
        }), " theme if it's not already selected. Then, hover over the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Homepage"
        }), " template and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select template"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You'll then be brought to the website page editor where you can explore all of the options that content creators will have when working with the template. Learn more about using the editor to build and customize pages on ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/create-and-customize-pages",
          children: "HubSpot's Knowledge Base"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Settings"
        }), " tab in the editor, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "General"
        }), ". Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Page title"
        }), ", then set a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Content slug"
        }), " to finalize the page's URL. Then, close out the dialog box by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "X"
        }), " or pressing the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Escape key"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publish"
        }), " to take your page live."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://play.hubspotvideo.com/v/53/id/172854544538",
      width: "536px",
      height: "366px"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Edit a CSS file"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch my-website-theme my-website-theme"
      }), ". While ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " is running, every time you save a file, it’ll be automatically uploaded. Open your theme's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/css/components/_footer.css"
      }), " file in your editor, make a change (such as updating the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".footer__copyright"
      }), " selector to have ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "color: red;"
      }), "), and save your changes. Your terminal will show that the saved file has been uploaded."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Reload your published page to see the CSS change reflected on your website."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "What's next?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You're encouraged to continue to explore and experiment with the boilerplate theme and the page-building experience. The sandbox account you created is yours to play around in and experiment with."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can checkout ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://designers.hubspot.com/inspire/",
        children: "HubSpot's Inspire gallery"
      }), " to see websites, landing pages, and web apps built on HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You might also want to check out the following documentation:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms",
          children: "CMS developer tutorials"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "HubSpot CMS overview"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Join the HubSpot CMS developer community"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Learning is easier when you can learn from those who came before you."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot is driven by its ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://blog.hubspot.com/blog/tabid/6307/bid/34234/the-hubspot-culture-code-creating-a-company-we-love.aspx",
        children: "Culture Code"
      }), ", embodied by the attributes in HEART: ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "H"
      }), "umble, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "E"
      }), "mpathetic, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "A"
      }), "daptable, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "R"
      }), "emarkable, and ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "T"
      }), "ransparent. This culture extends to our ever-growing developer community, with thousands of brilliant and helpful developers around the world."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Developer Slack community"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Join the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/developer-slack",
        children: "Developer Slack"
      }), " to collaborate with 9,000+ developers and members of the HubSpot product team."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Developer forums"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ask questions, learn from fellow developers, and submit ideas in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/CMS-Development/bd-p/designers_support",
        children: "CMS developer forums"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}