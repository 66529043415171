'use es6';

import CodeMirror from 'codemirror';
import 'codemirror/addon/mode/multiplex';
import 'codemirror/addon/mode/overlay';
import 'codemirror/mode/css/css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/htmlmixed/htmlmixed';
import HublKeywords from '../constant/HublKeywords';

/*
 * Adds 4 custom HubL modes to CodeMirror.
 *   hubl
 *   hubl-css
 *   hubl-html
 *   hubl-javascript
 *
 * Adds 4 custom HubL mime modes to CodeMirror.
 *   text/x-hubl
 *   text/x-hubl-css
 *   text/x-hubl-html
 *   text/x-hubl-javascript
 *
 * These modes will use the hubl parser within and including code between open/close.
 * The following classes will be appended to each tag within. The hubl parser is a
 * modified version of the jinja2 parser.
 *   cm-hubl-comment
 *   cm-hubl-statement
 *   cm-hubl-expression
 */

CodeMirror.defineMode('hubl', () => {
  const operator = /^[+\-*&%=<>!?|~^]/;
  const sign = /^[:[({]/;
  const _atom = ['true', 'false'];
  const number = /^(\d[+\-*/])?\d+(\.\d+)?/;
  const keywords = new RegExp(`((${HublKeywords.join(')|(')}))\\b`);
  const atom = new RegExp(`((${_atom.join(')|(')}))\\b`);
  function tokenBase(stream, state) {
    let ch = stream.peek();

    //Comment
    if (state.incomment) {
      if (!stream.skipTo('#}')) {
        stream.skipToEnd();
      } else {
        stream.eatWhile(/#|}/);
        state.incomment = false;
      }
      return 'comment';
      //Tag
    } else if (state.intag) {
      //After operator
      if (state.operator) {
        state.operator = false;
        if (stream.match(atom)) {
          return 'atom';
        }
        if (stream.match(number)) {
          return 'number';
        }
      }
      //After sign
      if (state.sign) {
        state.sign = false;
        if (stream.match(atom)) {
          return 'atom';
        }
        if (stream.match(number)) {
          return 'number';
        }
      }
      if (state.instring) {
        if (ch === state.instring) {
          state.instring = false;
        }
        stream.next();
        return 'string';
      } else if (ch === "'" || ch === '"') {
        state.instring = ch;
        stream.next();
        return 'string';
      } else if (stream.match(`${state.intag}}`) || stream.eat('-') && stream.match(`${state.intag}}`)) {
        state.intag = false;
        return 'tag';
      } else if (stream.match(operator)) {
        state.operator = true;
        return 'operator';
      } else if (stream.match(sign)) {
        state.sign = true;
      } else {
        if (stream.eat(' ') || stream.sol()) {
          if (stream.match(keywords)) {
            return 'keyword';
          }
          if (stream.match(atom)) {
            return 'atom';
          }
          if (stream.match(number)) {
            return 'number';
          }
          if (stream.sol()) {
            stream.next();
          }
        } else {
          stream.next();
        }
      }
      return 'variable';
    } else if (stream.eat('{')) {
      if (stream.eat('#')) {
        state.incomment = true;
        if (!stream.skipTo('#}')) {
          stream.skipToEnd();
        } else {
          stream.eatWhile(/#|}/);
          state.incomment = false;
        }
        return 'comment';
        //Open tag
      } else if (ch.match(/\{|%/)) {
        ch = stream.eat(/\{|%/);
        //Cache close tag
        state.intag = ch;
        if (ch === '{') {
          state.intag = '}';
        }
        stream.eat('-');
        return 'tag';
      }
    }
    stream.next();
    return null;
  }
  return {
    startState() {
      return {
        tokenize: tokenBase
      };
    },
    token(stream, state) {
      return state.tokenize(stream, state);
    },
    blockCommentStart: '{#',
    blockCommentEnd: '#}'
  };
});
function createHublCommentMode(config) {
  return {
    open: '{#',
    close: '#}',
    mode: CodeMirror.getMode(config, 'hubl'),
    // For some reason, removing parseDelimiters and setting delimStyle
    // is needed to prevent statements/expressions from being marked as comments
    // if they occur after an opening {# tag
    // parseDelimiters: true,
    delimStyle: 'hubl-comment',
    innerStyle: 'hubl-comment'
  };
}
function createHublStatementMode(config) {
  return {
    open: '{%',
    close: '%}',
    mode: CodeMirror.getMode(config, 'hubl'),
    parseDelimiters: true,
    innerStyle: 'hubl-statement'
  };
}
function createHublExpressionMode(config) {
  return {
    open: '{{',
    close: '}}',
    mode: CodeMirror.getMode(config, 'hubl'),
    parseDelimiters: true,
    innerStyle: 'hubl-expression'
  };
}
function createMultiplex(config, basemode) {
  return CodeMirror.multiplexingMode(CodeMirror.getMode(config, basemode), createHublCommentMode(config), createHublStatementMode(config), createHublExpressionMode(config));
}
CodeMirror.defineMode('hubl-css', config => createMultiplex(config, 'css'));
CodeMirror.defineMode('hubl-html', config => createMultiplex(config, 'htmlmixed'));
CodeMirror.defineMode('hubl-javascript', config => createMultiplex(config, 'javascript'));
CodeMirror.defineMIME('text/x-hubl', 'hubl');
CodeMirror.defineMIME('text/x-hubl-css', 'hubl-css');
CodeMirror.defineMIME('text/x-hubl-html', 'hubl-html');
CodeMirror.defineMIME('text/x-hubl-javascript', 'hubl-javascript');