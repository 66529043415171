"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31316289074;
const slug = exports.slug = 'guides/apps/public-apps/overview';
const title = exports.title = '公開アプリ';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Page | Creating and installing apps | 202304';
const metaDescription = exports.metaDescription = 'HubSpotのアプリを作成してインストールする方法を説明します。アプリは、OAuthでの接続プロセスを通じて顧客アカウント上にインストールできる連携機能です。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "公開アプリを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E3%81%99%E3%82%8B",
  "label": "アプリをインストールする",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%A1%A7%E5%AE%A2%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%A7%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E8%AA%8D%E8%A8%BC%E3%81%99%E3%82%8B",
  "label": "顧客アカウントでアプリを認証する",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E5%88%9D%E5%9B%9E%E3%81%AEoauth%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%82%92%E7%94%9F%E6%88%90%E3%81%99%E3%82%8B",
  "label": "初回のOAuthトークンを生成する",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "hubspot%E3%81%A7%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B",
  "label": "HubSpotで公開アプリを管理する",
  "parent": null
}, {
  "depth": 1,
  "id": "find-an-app-s-id",
  "label": "Find an app's ID",
  "parent": "hubspot%E3%81%A7%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E5%8B%95%E4%BD%9C%E3%81%AE%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%AA%E3%83%B3%E3%82%B0",
  "label": "アプリ動作のモニタリング",
  "parent": "hubspot%E3%81%A7%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E8%AA%8D%E8%A8%BC%E6%B8%88%E3%81%BF%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "認証済みドメインを追加する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E8%AA%8D%E8%A8%BC%E6%B8%88%E3%81%BF%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "認証済みドメインを追加する",
  "parent": "%E8%AA%8D%E8%A8%BC%E6%B8%88%E3%81%BF%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B"
}, {
  "depth": 2,
  "id": "%E8%BF%BD%E5%8A%A0%E3%81%AE%E3%83%A1%E3%83%A2",
  "label": "追加のメモ",
  "parent": "%E8%AA%8D%E8%A8%BC%E6%B8%88%E3%81%BF%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      img: "img",
      code: "code",
      h3: "h3",
      strong: "strong",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "公開アプリ"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotの公開アプリは、顧客アカウント上にインストールしたり、アプリマーケットプレイスに掲載したりできる連携機能です。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), "による認証を必要とします。ユーザーがアプリをHubSpotアカウントに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#installing-apps",
        children: "インストール"
      }), "したら、そのアカウントに対して", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuthアクセストークン"
      }), "を使用したAPI呼び出しを行えるようになります。アプリは、アカウントの設定画面にある［接続されたアプリ］にも表示されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、接続されたアプリでは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/api/webhookhttps://developers.hubspot.com/docs/api/webhooks",
        children: "Webhookを使用して変更を受信"
      }), "したり、カスタムの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline",
        children: "タイムラインイベント"
      }), "を作成したりすることもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このページでは、次の方法についてご紹介します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-public-app",
          children: "公開アプリを作成する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#install-an-app",
          children: "アカウントに公開アプリをインストールする"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#manage-public-apps-in-hubspot",
          children: "アプリを管理する（使用状況のモニタリングを含む）"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-a-verified-domain",
          children: "認証済みドメインをアプリに追加する"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "公開アプリを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでアプリを作成する場合は作成したアプリを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "開発者アカウント"
      }), "に関連付けます。HubSpotアプリの作成を開始するには、次の手順で進めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ開発者アカウントで、上部のナビゲーションバーにある［アプリ］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上の［アプリを作成］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "次に、アプリの基本情報と設定を入力します。このページに記入した名前、説明、ロゴ、問い合わせ先情報が、HubSpotアカウントで貴社のアプリを認証したユーザーに示されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**アプリ名は、アプリがHubSpotユーザーに示される全ての場合に使用されます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "CRMカード"
        }), "と", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/timeline",
          children: "タイムラインイベント"
        }), "の「Powered by」フッターだけでなく、ユーザーがアプリをインストールする場面も含まれます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/new_app_setup.png?width=600&name=new_app_setup.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［認証］タブをクリックして、クライアントIDとクライアントシークレット、およびアプリに割り当てられているスコープを表示します。この情報は、アプリとHubSpotの間で", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth接続を開始"
        }), "する際に必要になります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/app_auth_settings.png?width=600&name=app_auth_settings.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["新しい", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "スコープ"
        }), "をアプリに追加するには、［認証］タブで、ページの下部にある［スコープ］ドロップダウンメニューをクリックします。必要なスコープは認証URLの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope="
        }), "パラメーターに含める必要があります。含めていない場合、アプリ認証時にユーザーに対してエラーが表示されます。認証URLの作成とスコープの使用の詳細は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuthのドキュメント"
        }), "を参照してください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/app_scopes.png?width=600&name=app_scopes.png",
        alt: "app_scopes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［リダイレクトURL］フィールドに注意してください。これは、HubSpotアカウントへのアクセス権をアプリに付与したユーザーに表示されるページです。リダイレクトURLがない場合、ユーザーに接続エラーが表示されます。localhost上を除き、リダイレクトURLにはHTTPSを使用する必要があります。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリが作成されたら、インストール手順を開始できます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**アプリに", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-a-verified-domain",
          children: "認証済みドメインを追加する"
        }), "ことで、アプリをインストールするユーザー向けに更なる信頼レベルを追加することをお勧めします。追加しない場合、アプリがまだ認証されていないことを示すバナーがアプリに表示されます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリをインストールする"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**アプリをインストールする前に、次の点に留意してください。"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "インストールしたアプリは、最初のアクセストークンとリフレッシュトークンが作成されるまでは、アカウントの［接続されたアプリ］ページに表示されません。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "アプリをインストールできるのは、アプリが必要とするスコープにアクセスできるユーザーに限られます。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["開発者アカウントにはアプリをインストールできません。アプリをテストするには、アプリ開発者アカウントで", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types",
            children: "テストアカウント"
          }), "を作成し、そのアカウントにアプリをインストールする必要があります。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリのインストール手順は、権限付与とトークン生成の2つのステップに分けることができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "顧客アカウントでアプリを認証する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotアカウント上でアプリを認証するには、認証URLを作成する必要があります。そのためには、アプリのクライアントIDを取得し、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuthプロセスを開始"
        }), "します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "URLの準備ができたら、ブラウザーでそれを開くと、該当する全てのHubSpotアカウントのリストが表示されるのを確認できます。このURLをユーザーに案内した場合にも同様の画面が表示されます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリをインストールする", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "アカウント"
        }), "を選択します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/select_account-1.webp",
        alt: "select_account-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アカウントを選択すると、認証URLに設定した", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&scope="
        }), "パラメーターと", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&optional_scope="
        }), "パラメーターに基づくスコープのリストが表示されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), "を含めていても、選択したアカウントにアクセス権が付与されていない場合（CRMのみのアカウントに対するコンテンツスコープなど）は、リストに表示されません。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "接続を認証するには、［アクセス権を付与］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/approve_scopes-1.webp",
        alt: "approve_scopes-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アクセス権を付与すると、元の認証URLの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&redirect_uri="
        }), "パラメーターに基づくページが表示され、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?code="
        }), "パラメーターがそのURLの末尾に付加されます。このコードを次のステップで使用して、アクセストークンを生成します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "初回のOAuthトークンを生成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リフレッシュトークンと初回アクセストークンを生成するには、認証URLの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?code="
      }), "パラメーターのコード、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "redirect_url"
      }), "、クライアントID、およびクライアントシークレットが必要です。詳しい手順は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "こちらのページ"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリを認証し、初回のトークンを生成したら、インストールは完了です。アプリが［接続されたアプリ］", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/integrations-beta"
      }), "ページに表示され、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "Webhook"
      }), "および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "CRMカード"
      }), "のフェッチリクエストを受信するようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/connected_apps-1.webp",
        alt: "connected_apps-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpotで公開アプリを管理する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Find an app's ID"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下のいずれかの方法を使用して、アプリ開発者アカウントで公開アプリのIDを見つけることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "開発者アカウントで、メイン ナビゲーション バーの［アプリ］に移動して、アプリの名前の下に記載されている「アプリID」を確認します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/find-app-id.png",
        alt: "find-app-id"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "開発者アカウントで、メイン ナビゲーション バーの［アプリ］に移動して、アプリ名をクリックします。［基本情報］ページで［認証］タブをクリックして、「アプリID」を確認します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/find-app-id-auth-settings.png",
        alt: "find-app-id-auth-settings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "アプリ動作のモニタリング"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuthのアクセストークン"
      }), "を使用した受信リクエストやWebhookまたはCRMカードの発信リクエストを含め、接続されているアプリとの間でやり取りするリクエストが全て記録されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このリクエストのログを表示するには、次のように進みます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "開発者アカウントで、メイン ナビゲーション バーにある［アプリ］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ名をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左のサイドバーメニューで［モニタリング］に移動します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "タブ"
        }), "を切り替えることで、さまざまなタイプのアプリに対するリクエストやアプリからのリクエストを表示できます。これらのログが表示された状態で、個々のリクエストをクリックすると、そのリクエストに関する次の詳細情報が表示されます。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)("u", {
              children: "成功"
            }), "\nしたリクエストについては、リクエストのメソッド、パス、リクエストが行われた時間が表示されます。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)("u", {
              children: "失敗"
            }), "\nしたリクエストについては、応答ヘッダーや本文などの追加のエラー情報が表示されます。"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/request_details.webp",
        alt: "request_details"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［モニタリング］ページの各タブについて詳しくは、以下をご確認ください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "API呼び出し："
        }), "［API呼び出し］タブには、OAuthアクセストークンを使ってアプリに対して行われた全てのリクエストが表示されます。HTTPメソッド、レスポンスコード、期間、またはリクエストURLで絞り込むことができます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhook："
        }), "［Webhook］タブには、アプリの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks/overview",
          children: "Webhook通知"
        }), "に関するHubSpotリクエストが表示されます。レスポンス（タイムアウトや接続失敗など）、ステータス（成功、再試行、失敗）、通知タイプ、期間、試行、バッチ、イベント、またはアカウントIDで絞り込むことができます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**試行IDは、特定のリクエストの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriptionId"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "eventId"
        }), "、および", (0, _jsxRuntime.jsx)(_components.code, {
          children: "attemptNumber"
        }), "を組み合わせたものです。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM拡張機能："
        }), "［CRM拡張機能］タブには、アプリの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "CRMカード"
        }), "に関する要求が表示されます。拡張オブジェクトタイプ、CRMオブジェクトタイプ（コンタクト、会社、チケット、または取引）、エラーまたは警告のタイプ、期間、リクエストID、あるいはCRMレコードID（特定のコンタクトID）で絞り込むことができます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "アプリの設定："
        }), "［アプリの設定］タブでは、アプリに付属している", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/create-an-app-settings-page",
          children: "設定ページ"
        }), "を構成できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "各タブで、過去30日間に関連イベントが発生した場合（Webhookトリガーが発生した場合やAPI呼び出しが行われた場合など）、**［ログをエクスポート］**をクリックして関連イベントデータをCSVにエクスポートできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ダイアログボックスで、何日分のデータをエクスポートするか（最大30日）を設定します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［エクスポート］をクリックします。Eメール通知は、HubSpot設定でユーザーに関連付けられたメールアドレスに送信されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "認証済みドメインを追加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotユーザーがアプリをインストールするとき、自分のアカウントデータへのアクセスをアプリ開発者に付与することに同意しなければなりません。そのため、インストールを続けるかどうかユーザーが決定する上で、開発者の身元と評判はどちらも重要な役割を果たします。アプリのインストール時にユーザーによって完全に同意してもらえるように、HubSpotはアプリの認証レベルおよびアプリマーケットプレイス上の掲載を示すメッセージをアプリのインストール画面に次のように表示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "アプリに認証済みドメインがない場合、HubSpotはアプリがまだ認証されていないことを示すバナーをインストール画面に表示します。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/not-verified.png",
            alt: "not-verified"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["アプリに認証済みドメインが存在するものの、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/listing-your-app",
            children: "アプリマーケットプレイスに掲載"
          }), "されていない場合、HubSpotは認証済みドメインを表示するとともに、アプリがHubSpotによってレビューおよび認証されていないというバナーをインストール画面に表示します。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verified-not-listed.png",
        alt: "verified-not-listed"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "アプリがHubSpotのアプリ審査プロセスに合格してマーケットプレイスに掲載されると、HubSpotは上記のバナーのどちらも表示しません。アプリが既にアプリマーケットプレイスに掲載されている場合は、ドメインを確認する必要はありません。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verified-and-listed.png",
            alt: "verified-and-listed"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "認証済みドメインを追加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "認証済みドメインをアプリに追加するには、まずアプリの設定にドメインを追加し、次にドメインのDNS設定にTXTレコードを追加する必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ開発者アカウントで、メイン ナビゲーション バーの［アプリ］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ名をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左側のサイドバーで、［コンタクトとサポート］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［会社ドメイン］フィールドにドメインを入力して、［保存］をクリックします。［会社ドメイン］の下に、ドメインがまだ未確認であることを示すメッセージが表示されます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［今すぐ確認］をクリックすると、確認プロセスが開始します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/domain-verification-for-app.png",
        alt: "domain-verification-for-app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "右側のパネルで、ドメインが正しく入力されていることを確認し、［次へ］をクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［値］列で［コピー］をクリックすると、必要なTXTレコード値がコピーされます。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verify-app-domain-copy-value.png",
            alt: "verify-app-domain-copy-value"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "DNSプロバイダーで、コピーされた値を使用してTXTレコードを作成します。いくつかの一般的なDNSプロバイダーでの手順を以下に示します。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.godaddy.com/help/add-a-txt-record-19232",
              children: "GoDaddy"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://my.bluehost.com/hosting/help/559#add",
              children: "BlueHost"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.namecheap.com/support/knowledgebase/article.aspx/317/2237",
              children: "Namecheap"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://support.cloudflare.com/hc/en-us/articles/360019093151-Managing-DNS-records-in-Cloudflare#h_60566325041543261564371",
              children: "Cloudflare"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://help.hover.com/hc/en-us/articles/217282457-How-to-Edit-DNS-records-A-AAAA-CNAME-MX-TXT-SRV-",
              children: "Hover"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.name.com/support/articles/115004972547-Adding-a-TXT-Record",
              children: "Name"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://help.uniteddomains.com/hc/en-us/articles/115000887125-How-to-set-up-a-TXT-record-on-a-domain-name",
              children: "United Domains"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "DNS設定を更新したら、HubSpotに戻り、右側のパネルで［次へ］をクリックします。DNSレコードの更新には最大48時間かかる場合があるため、HubSpotは変更をすぐに認識できないかもしれません。［会社情報］設定ページから［今すぐ認証］を再び選択すると、いつでもこの画面に戻ることができます。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "認証が完了すると、［会社ドメイン］フィールドの下に成功ステータスインジケーターが表示されます。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Domain%20verified__export.png",
        alt: "Domain verified__export"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "追加のメモ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ドメインの継続的な所有を確かめる目的で、HubSpotはTXTレコードが存在することを定期的に確認し続けます。TXTレコードが除去または変更された場合、インストールの警告が再び表示されるようになります。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "現在、1つの開発者アカウントにつき1つの認証済みドメインしか持つことができません。アカウント内の全てのアプリは、認証済みドメインを共有します。インストールページ上のドメインはルートドメインにリンクされます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "認証済みドメインを削除すると、開発者アカウント内の全てのアプリでインストール警告が再び表示されます。別のドメインを確認することはできますが、この処理には数時間かかります。"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}