"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611096;
const slug = exports.slug = 'reference/cms/modules/using-modules-in-templates';
const title = exports.title = 'Utilisation de modules dans les modèles';
const name = exports.name = '[nouveau] Utilisation des modules dans les modèles';
const metaDescription = exports.metaDescription = "Les modules sont des zones modifiables des pages ou des e-mails HubSpot. Si vous pouvez ajouter des modules à une mise en page de modèle à l'aide de la fonction glisser-déposer de l'outil de création de modèles, vous pouvez également créer des modules à l'aide de HubL. Les modules HubL peuvent être définis dans un fichier de modèle codé, un module de modèle HubL ou un module de contenu de blog. Vous trouverez ci-dessous une liste complète des modules et de leurs paramètres.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "syntaxe-de-base-des-modules",
  "label": "Syntaxe de base des modules",
  "parent": null
}, {
  "depth": 0,
  "id": "transmission-de-dictionnaires-aux-param%C3%A8tres-du-module",
  "label": "Transmission de dictionnaires aux paramètres du module",
  "parent": null
}, {
  "depth": 1,
  "id": "transmission-de-champs-ayant-des-param%C3%A8tres-dnd-associ%C3%A9s",
  "label": "Transmission de champs ayant des paramètres dnd associés",
  "parent": "transmission-de-dictionnaires-aux-param%C3%A8tres-du-module"
}, {
  "depth": 0,
  "id": "d%C3%A9finition-de-valeurs-par-d%C3%A9faut-au-niveau-du-mod%C3%A8le-pour-les-champs",
  "label": "Définition de valeurs par défaut au niveau du modèle pour les champs",
  "parent": null
}, {
  "depth": 1,
  "id": "d%C3%A9finir-des-valeurs-par-d%C3%A9faut-pour-les-groupes-de-champs-imbriqu%C3%A9s",
  "label": "Définir des valeurs par défaut pour les groupes de champs imbriqués",
  "parent": "d%C3%A9finition-de-valeurs-par-d%C3%A9faut-au-niveau-du-mod%C3%A8le-pour-les-champs"
}, {
  "depth": 1,
  "id": "d%C3%A9finir-des-valeurs-par-d%C3%A9faut-pour-les-champs-r%C3%A9p%C3%A9titifs",
  "label": "Définir des valeurs par défaut pour les champs répétitifs",
  "parent": "d%C3%A9finition-de-valeurs-par-d%C3%A9faut-au-niveau-du-mod%C3%A8le-pour-les-champs"
}, {
  "depth": 1,
  "id": "d%C3%A9finir-des-valeurs-par-d%C3%A9faut-pour-les-groupes-de-champs-r%C3%A9p%C3%A9titifs",
  "label": "Définir des valeurs par défaut pour les groupes de champs répétitifs",
  "parent": "d%C3%A9finition-de-valeurs-par-d%C3%A9faut-au-niveau-du-mod%C3%A8le-pour-les-champs"
}, {
  "depth": 1,
  "id": "d%C3%A9finir-des-valeurs-par-d%C3%A9faut-pour-les-champs-de-style",
  "label": "Définir des valeurs par défaut pour les champs de style",
  "parent": "d%C3%A9finition-de-valeurs-par-d%C3%A9faut-au-niveau-du-mod%C3%A8le-pour-les-champs"
}, {
  "depth": 0,
  "id": "syntaxe-de-bloc",
  "label": "Syntaxe de bloc",
  "parent": null
}, {
  "depth": 0,
  "id": "content_attribute",
  "label": "content_attribute",
  "parent": null
}, {
  "depth": 0,
  "id": "param%C3%A8tres-disponibles-pour-tous-les-modules",
  "label": "Paramètres disponibles pour tous les modules",
  "parent": null
}, {
  "depth": 0,
  "id": "param%C3%A8tres-bas%C3%A9s-sur-les-champs",
  "label": "Paramètres basés sur les champs",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Utilisation des modules dans les modèles"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modules peuvent être ajoutés directement à un modèle ou ajoutés à des pages individuelles avec des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "zones de glisser-déposer"
      }), " et des colonnes flexibles. Lorsqu'un module est ajouté à un modèle, le module apparaîtra à cet emplacement par défaut. Les modules dans les zones de glisser-déposer et les colonnes flexibles peuvent être déplacés et supprimés, et d'autres modules peuvent être ajoutés autour d'eux. Ce sont des instances de module."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois qu'un module a été défini, vous pouvez obtenir ses valeurs de champ au niveau du modèle via le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context#retrieving-parameters-from-modules-already-rendered-on-the-template",
        children: "dictionnaire content.widgets"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Syntaxe de base des modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les balises de module HubL sont délimitées par ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% %}"
      }), " et doivent spécifier le ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ", un nom unique et le chemin du gestionnaire de conception du module. Un module peut également inclure des paramètres pour des paramètres supplémentaires."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nom du module :"
        }), " donne au module une identité unique dans le contexte du modèle.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Le nom doit être entre guillemets après le type de module et doit utiliser des traits de soulignement au lieu d'espaces ou de tirets."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ce nom est utilisé pour faire correspondre le contenu défini dans l'éditeur de page/d'e-mail avec la balise de module HubL correspondante. Par exemple, si vous codez une balise de module HubL avec le même nom dans deux zones différentes d'un modèle, les utilisateurs n'auront qu'un seul module à modifier dans l'éditeur, mais les modifications apportées à ce module s'appliqueront aux deux endroits."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Chemin :"
        }), " en fonction de la balise, définit l'emplacement du module dans le gestionnaire de conception.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            }), " désigne la racine du lecteur actuel ;"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "./"
            }), " désigne le répertoire actuel ;"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "../"
            }), " désigne le parent du répertoire actuel."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Le chemin des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "modules par défaut de HubSpot"
        }), " doit toujours commencer par ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot/"
        }), " suivi du type de module."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Paramètres :"
        }), " paramètres supplémentaires pour l'instance de module, spécifiant son comportement et son rendu. Inclut des valeurs par défaut au niveau du modèle pour les champs de module.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les paramètres sont des paires clé-valeur séparées par des virgules."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Les paramètres peuvent être de différents types : chaîne de caractères, booléen, entier, énumération et objet de liste JSON. Les valeurs des paramètres de chaîne doivent être entre guillemets simples ou doubles, tandis que les paramètres booléens ne nécessitent pas de guillemets autour de leurs valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            }), ". Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/modules/using-modules-in-templates#parameters-available-for-all-modules",
              children: "paramètres disponibles pour tous les modules"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Notez qu'il n'y a pas de validation dans l'éditeur pour les valeurs de champ par rapport aux paramètres de champ du module. Par exemple, si un module possède un champ numérique dont la valeur minimale est fixée à ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), " et que vous transmettez au paramètre la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), ", aucun avertissement indiquant que la valeur n'est pas valide ne s'affichera."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Basic syntax #}\n{% module \"unique_module_name\" path=\"@hubspot/module_type\",\n  parameterString='String parameter value',\n  parameterBoolean=True\n%}\n\n{# Sample of a default HubSpot text module #}\n{% module \"job_title\" path=\"@hubspot/text\",\n  label=\"Job Title\",\n  value=\"Chief Morale Officer\"\n%}\n\n{# Sample of a custom module #}\n{% module \"faqs\" path=\"/myWebsite/modules/faq_module\",\n  label=\"Custom FAQ Module\"\n  faq_group_title=\"Commonly Asked Questions\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Transmission de dictionnaires aux paramètres du module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour les modules dont les champs attendent des dictionnaires, vous pouvez les transmettre comme d'autres paramètres. Si cela vous permet une meilleure organisation ou si vous prévoyez de réutiliser les valeurs, vous pouvez définir le dictionnaire sur une variable et transmettre la variable au paramètre à la place."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_buttons\",\n  path=\"@hubspot/social_sharing\",\n  email={\n    \"default\": true,\n    \"enabled\": false,\n    \"img_src\": \"https://...\"\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Transmission de champs ayant des paramètres dnd associés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "balises de glisser-déposer"
      }), ", telles que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ", sont fournies avec un ensemble de paramètres par défaut, tels que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), ". Bien que le gestionnaire de conception vous empêche de créer de nouveaux champs qui utilisent l'un de ces paramètres réservés, les modules créés avant l'introduction des balises de glisser-déposer peuvent déjà utiliser un paramètre réservé."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour résoudre ce problème, vous pouvez utiliser le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields"
      }), ". Tout comme vous transmettez des données de champ à un groupe, vous pouvez transmettre le nom du champ en tant que clé sur l'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields"
      }), ". Sa valeur doit être conforme au format attendu par le type de champ."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"main_content\"%}\n     {% dnd_section %}\n        {% dnd_column %}\n          {% dnd_row %}\n            {% dnd_module path=\"@hubspot/divider\",\n               fields={width: \"90\"}\n            %}\n            {% end_dnd_module %}\n        {% end_dnd_row %}\n      {%end_dnd_column%}\n    {% end_dnd_section %}\n  {% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Définition de valeurs par défaut au niveau du modèle pour les champs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez définir des valeurs par défaut pour les champs de module au niveau du modèle en incluant des paramètres dans les balises ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ". Découvrez ci-dessous comment définir les valeurs de champ par défaut dans les groupes de champs imbriqués, les champs répétitifs, les groupes de champs répétitifs et les champs de style."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir des valeurs par défaut pour les groupes de champs imbriqués"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous trouverez ci-dessous un exemple de module de glisser-déposer personnalisé avec un groupe de champs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "style"
      }), " personnalisé contenant d'autres groupes de champs imbriqués. Comparez sa configuration au niveau du modèle avec la façon dont ce même regroupement apparaîtrait dans le gestionnaire de conception."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_module\n path=\"/Nested_Module.module\"\n style={\n  \"group\":{\n  \"section\":{\n   \"color_field\":{\n   \"color\" : \"#000\",\n   \"opacity\" : 100\n    }\n   }\n  }\n }\n%}\n{% end_dnd_module %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/multi-level%20field%20nesting%20.png",
            alt: "Imbrication de champs à plusieurs niveaux "
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir des valeurs par défaut pour les champs répétitifs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez définir des valeurs par défaut au niveau du modèle pour les champs répétés en transmettant un tableau au paramètre du champ. Les éléments du tableau doivent avoir le format attendu en fonction du ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#field-based-parameters",
        children: "type de champ"
      }), ". Par exemple :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un champ de texte simple attend une chaîne uniquement."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Un champ de répétition d'image attend un objet de champ d'image. Ceci s'applique à ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#field-based-parameters",
          children: "tous les autres types de champs"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module path='../modules/recipe_card.module',\n  ingredients=[\"Eggs\",\"Ham\",\"Cheese\"]\n%}\n\n{% module \"my_repeater_module\" path=\"/img_repeater_module\", label=\"img_repeater_module\",\nimage=[\n  {\n    \"src\" : \"https://cdn2.hubspot.net/hubfs/428357/Developer%20Site/assets/logo/Developers-LOGO.svg\",\n    \"alt\" : \"HubSpot Developers\",\n    \"width\" : 254,\n    \"height\" : 31\n  },{\n    \"src\" : \"https://www.hubspot.com/hs-fs/hub/53/file-733888614-jpg/assets/hubspot.com/about/management/dharmesh-home.jpg\",\n    \"alt\" : \"Dharmesh\",\n    \"width\" : 394,\n    \"height\" : 394\n  }\n]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir des valeurs par défaut pour les groupes de champs répétitifs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les modules qui contiennent des groupes de champs répétés - comme ceux d'un module de diaporama ou d'un module de FAQ - peuvent avoir une valeur par défaut au niveau du modèle pour ces groupes. Pour ce faire, vous transmettez un tableau d'objets au paramètre de votre groupe de champs. Les paires (clé, valeur) de l'objet sont les noms des champs et leurs valeurs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module path='../modules/slideshow.module',\n  slides=[\n    {\n      \"caption\":\"Cute dog looking up\",\n      \"image_url\":\"http://example.com/image.jpg\",\n    },\n    {\n      \"caption\":\"Cuter cat not looking amused\",\n      \"image_url\":\"http://example.com/image2.jpg\",\n    }\n  ]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Définir des valeurs par défaut pour les champs de style"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["vous pouvez définir explicitement des valeurs par défaut pour les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "champs de style"
      }), " à l'aide du paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "styles"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cela fonctionne comme les autres groupes, où le paramètre est le nom du groupe. Vous transmettez un objet à ce paramètre avec tous les champs que vous souhaitez définir."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n    path=\"./path/to/module\",\n    styles={\n      \"background_color\":{\n          \"color\":\"#123\",\n          \"opacity\":50\n       }\n    }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Syntaxe de bloc"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Alors que la plupart des modules ont des paramètres qui contrôlent le contenu par défaut, il peut y avoir des situations où vous devez ajouter d'importants blocs de code au contenu par défaut d'un module. Par exemple, vous souhaiterez peut-être inclure un important bloc HTML comme contenu par défaut d'un module de texte enrichi ou HTML. Plutôt que d'essayer d'écrire ce code dans un paramètre de valeur, vous pouvez utiliser la syntaxe de bloc HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module_block module \"my_rich_text_module\" path=\"/My Rich Text Field Module\",\n  label=\"My Rich Text Field Module\"\n%}\n    {% module_attribute \"rich_text_field_variable\" %}\n       <div>My HTML block</div>\n    {% end_module_attribute %}\n{% end_module_block %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Avant la syntaxe ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module_block"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), " était utilisé. Il suit le même modèle mais les balises d'ouverture étaient ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_attribute"
        }), ". Les balises de fermeture étaient ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "end_widget_attribute"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "end_widget_block"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si la syntaxe ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), " est obsolète, vous n'avez pas besoin de mettre à jour votre ancien code."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le paramètre qui suit immédiatement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_block"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), "(obsolète) est le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans presque toute notre documentation, vous noterez que nous utilisons ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ". Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "modules HubSpot de deuxième version"
      }), " sont des modules normaux, comme ceux que vous pouvez créer. Il n'est donc plus nécessaire d'utiliser un autre élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alors que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " est obsolète, vous devriez utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_block"
      }), ". Si vous héritez d'un site web d'un autre développeur, il peut contenir un ancien code utilisant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), " prend en charge les noms de modules HubSpot de première version, par exemple ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rich_text"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "raw_html"
      }), ". Des paramètres supplémentaires peuvent être ajoutés à la première ligne de HubL. La deuxième ligne définit le paramètre auquel le contenu du bloc sera appliqué. Par exemple, pour un module ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rich_text"
      }), ", il s'agit du paramètre html. Pour un module ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "raw_html"
      }), ", il s'agirait du paramètre value (voir les deux exemples ci-dessous)."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# widget_block is deprecated, use module_block instead. This example is only to\nexplain what type_of_module was used for, for those with legacy code. #}\n{% widget_block rich_text \"my_rich_text_module\" overrideable=True, label='My rich-text module'  %}\n        {% widget_attribute \"html\" %}\n            <h2>New Module</h2>\n            <p>Add content here.</p>\n        {% end_widget_attribute %}\n{% end_widget_block %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Exemple de sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<span\n  id=\"hs_cos_wrapper_my_rich_text_module\"\n  class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text\"\n  style=\"\"\n  data-hs-cos-general-type=\"widget\"\n  data-hs-cos-type=\"rich_text\"\n>\n  <h2>New Module</h2>\n  <p>Add content here.</p>\n</span>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "content_attribute"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En plus de la syntaxe normale et de la syntaxe de bloc, il existe certains cas où vous souhaiterez spécifier un important bloc de contenu par défaut pour une variable de contenu prédéfinie. L'exemple le plus courant est la variable ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#email-variables",
        children: "content.email_body"
      }), ". Cette variable imprime un corps d'e-mail standard qui peut être modifié dans l'éditeur de contenu. Comme il ne s'agit pas d'un module HubL standard, nous utilisons une balise ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "content_attribute"
      }), " pour spécifier un bloc de contenu par défaut. L'exemple ci-dessous montre que la variable du corps de l'e-mail est renseignée avec un bloc de code de contenu par défaut."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% content_attribute \"email_body\" %}\n        <p>Hi {{ contact.firstname }},</p>\n        <p>Describe what you have to offer the customer. Why should they read? What did you promise them in the subject line? Tell them something cool. Make them laugh. Make them cry. Well, maybe don't do that...</p>\n        <p>Use a list to:</p>\n        <ul>\n            <li>Explain the value of your offer</li>\n            <li>Remind the reader what they’ll get out of taking action</li>\n            <li>Show off your skill with bullet points</li>\n            <li>Make your content easy to scan</li>\n        </ul>\n        <p><a href=\"http://hubspot.com\">LINK TO A LANDING PAGE ON YOUR SITE</a> (This is the really important part.)</p>\n        <p>Now wrap it all up with a pithy little reminder of how much you love them.</p>\n        <p>Aw. You silver-tongued devil, you.</p>\n        <p>Sincerely,</p>\n        <p>Your name</p>\n{% end_content_attribute %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paramètres disponibles pour tous les modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bien que certains modules ont des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "paramètres spéciaux"
      }), ", vous trouverez ci-dessous une liste des paramètres pris en charge par tous les modules."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Par défaut"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom du module affiché dans l'éditeur de contenu. Ce paramètre peut également être utilisé pour donner des instructions supplémentaires aux utilisateurs."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overrideable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contrôle si le module peut être modifié ou non dans l'éditeur de contenu, ce qui équivaut au paramètre ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Empêcher la modification dans les éditeurs de contenu"
            }), " dans le gestionnaire de conception."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "no_wrapper"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Lorsqu'il est défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", supprime le balisage d'encadrement autour du contenu d'un module.Sur les pages, les modules sont toujours encadrés dans ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<div>"
            }), " avec des classes spéciales. Grâce à ce balisage, lorsque vous cliquez sur le module dans le volet de prévisualisation, l'éditeur défile vers ce module. Vous souhaiterez peut-être supprimer le wrapper, par exemple si vous souhaitez utiliser un module de texte pour renseigner la destination de l'attribut ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "href"
            }), " d'une balise d'ancrage."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "extra_classes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ajoute des classes au wrapper de module. Vous pouvez ajouter plusieurs classes à la fois, en les séparant par des espaces. Par exemple :", (0, _jsxRuntime.jsx)(_components.code, {
              children: "extra_classes='full-width panel'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "export_to_template_context"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Si la valeur est définie sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", au lieu de rendre le HTML, les paramètres de ce widget seront disponibles dans le contexte du modèle. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/modules/export-to-template-context",
              children: "Découvrez comment utiliser ce paramètre et la balise widget_data."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unique_in_loop"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Lorsque le module est défini dans une boucle, ajoute le nom du module à loop.index. Lorsque la valeur est définie sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", une version différente du module s'imprimera à chaque itération de la boucle. Ajoute le nom du module à loop.index."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour voir une liste complète de tous les types de modules et de leurs paramètres, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "cliquez ici."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paramètres basés sur les champs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous les paramètres de module basés sur les champs que vous pouvez utiliser."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Clés"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier (ID de blog)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "True/false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Choix"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"option_1\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Couleur"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"color\" : \"#ffffff\",  \"opacity\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["couleur ", (0, _jsxRuntime.jsx)("br", {}), "format hexadécimal à 6 caractères ", (0, _jsxRuntime.jsx)("br", {}), "opacité ", (0, _jsxRuntime.jsx)("br", {}), "entier 0 - 100 ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTA"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne (ID de CTA)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"fb9c0055-6beb-489d-8dda-3e1222458750\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Date"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Horodatage"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1566360000000"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Horodatage"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1566360000000"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Adresse e-mail"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau (chaînes d'adresses e-mail)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[\"develop@hubspot.com\", \"design@hubspot.com\"]"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Fichier"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne (URL du fichier)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://cdn2.hubspot.net/hubfs/file.pdf\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "E-mail de suivi"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier (ID d'e-mail de suivi)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Police"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"size\" : 12,  \"size_unit\" : \"px\",  \"color\" : \"#000\",  \"styles\" :{    \"text-decoration\" : \"underline\"  },  \"font\" : \"Alegreya\",  \"fallback\" : \"serif\",  \"variant\" : \"regular\",  \"font_set\" : \"GOOGLE\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["taille ", (0, _jsxRuntime.jsx)("br", {}), "taille de la police sans unité type ", (0, _jsxRuntime.jsx)("br", {}), "size_unit ", (0, _jsxRuntime.jsx)("br", {}), "chaîne d'unités de taille de police", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"px\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"pt\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"em\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"rem\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"%\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"ex\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"ch\""
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "couleur ", (0, _jsxRuntime.jsx)("br", {}), "chaîne de codes couleur hex ", (0, _jsxRuntime.jsx)("br", {}), "styles ", (0, _jsxRuntime.jsx)("br", {}), "propriétés prises en charge \"font-weight\" ", (0, _jsxRuntime.jsx)("br", {}), "\"normal\" / \"bold\" ", (0, _jsxRuntime.jsx)("br", {}), "\"font-style\" ", (0, _jsxRuntime.jsx)("br", {}), "\"normal\" / \"italic\" ", (0, _jsxRuntime.jsx)("br", {}), "\"font-style\" ", (0, _jsxRuntime.jsx)("br", {}), "\"none\" / \"underline\" ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Formulaire"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"form_id\" : \"9aa2e5f3-a46d-4774-897e-0bc37478521c\",  \"response_type\" : \"redirect\",  \"redirect_url\" : \"http://www.hubspot.com\",  \"redirect_id\" : null,  \"form_type\" : \"HUBSPOT\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["form_id ", (0, _jsxRuntime.jsx)("br", {}), "L'ID du formulaire. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/forms/find-your-form-guid",
              children: "Comment obtenir l'ID d'un formulaire."
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "response_type ", (0, _jsxRuntime.jsx)("br", {}), "\"redirect\" / \"inline\" ", (0, _jsxRuntime.jsx)("br", {}), "message ", (0, _jsxRuntime.jsx)("br", {}), "Message affiché si vous utilisez response_type \"inline\". Chaîne prenant en charge le HTML. ", (0, _jsxRuntime.jsx)("br", {}), "redirect_url ", (0, _jsxRuntime.jsx)("br", {}), "Chaîne, URL absolue d'une page web ", (0, _jsxRuntime.jsx)("br", {}), "redirect_id ", (0, _jsxRuntime.jsx)("br", {}), "ID de page/d'article de redirection ", (0, _jsxRuntime.jsx)("br", {}), "form_type ", (0, _jsxRuntime.jsx)("br", {}), "\"HUBSPOT\" / \"TICKET_FORM\" ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau HubDB"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier (ID de tableau HubDB)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "123456789"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Icône"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"name\" : \"align-center\",  \"unicode\" : \"f037\",  \"type\" : \"SOLID\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["nom ", (0, _jsxRuntime.jsx)("br", {}), "Le nom de l'icône ", (0, _jsxRuntime.jsx)("br", {}), "unicode ", (0, _jsxRuntime.jsx)("br", {}), "Le symbole Unicode de la police dont l'icône est issue du style de symbole ", (0, _jsxRuntime.jsx)("br", {}), "type ", (0, _jsxRuntime.jsx)("br", {}), ". \"SOLID\" / \"REGULAR\" ", (0, _jsxRuntime.jsx)("br", {}), "Il est recommandé de définir un champ d'icône et de visualiser les valeurs de cette manière afin de définir les paramètres correctement."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Image"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"src\" : \"https://cdn2.hubspot.net/hubfs/image.jpeg\",  \"alt\" : \"an_image\",  \"width\" : 100,  \"height\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["src ", (0, _jsxRuntime.jsx)("br", {}), "URL d'image ", (0, _jsxRuntime.jsx)("br", {}), "alt ", (0, _jsxRuntime.jsx)("br", {}), "Texte alternatif de l'image, utilisé par les lecteurs d'écran et les moteurs de recherche ", (0, _jsxRuntime.jsx)("br", {}), "largeur ", (0, _jsxRuntime.jsx)("br", {}), "La largeur à laquelle l'image doit être affichée ", (0, _jsxRuntime.jsx)("br", {}), "hauteur ", (0, _jsxRuntime.jsx)("br", {}), "La hauteur à laquelle l'image doit être affichée ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Lien"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"url\" : {     \"type\" : \"EXTERNAL\",    \"href\" : \"www.hubspot.com\",    \"content_id\" : null   },  \"open_in_new_tab\" : false,  \"no_follow\" : false }"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["url ", (0, _jsxRuntime.jsx)("br", {}), "objet stockant les données d'URL data.type ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"EXTERNAL\" pour les URL de HubSpot (hors e-mail)"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"CONTENT\" pour les pages, les articles de blog et les pages de destination"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"FILE\" pour les fichiers téléchargés dans le gestionnaire de fichiers"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"EMAIL_ADDRESS\" pour les adresses e-mail"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"BLOG\" pour les pages de listing de blog"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "href ", (0, _jsxRuntime.jsx)("br", {}), "L'URL vers laquelle vous établissez un lien. ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)("br", {}), "open_in_new_tab ", (0, _jsxRuntime.jsx)("br", {}), "\"true\"/\"false\", détermine si ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "target=\"_blank\""
            }), " doit être ajouté ", (0, _jsxRuntime.jsx)("br", {}), "no_follow ", (0, _jsxRuntime.jsx)("br", {}), "\"true\"/\"false\", détermine si ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rel=\"nofollow\""
            }), " doit être utilisé ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Logo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"override_inherited_src\" : true,  \"src\" : \"https://cdn2.hubspot.net/hubfs/logo.png\",  \"alt\" : \"best_logo_ever\",  \"width\" : 100,  \"height\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["override_inherited_src ", (0, _jsxRuntime.jsx)("br", {}), "true/false remplace les valeurs par défaut du portail ", (0, _jsxRuntime.jsx)("br", {}), "src ", (0, _jsxRuntime.jsx)("br", {}), "URL d'image ", (0, _jsxRuntime.jsx)("br", {}), "alt ", (0, _jsxRuntime.jsx)("br", {}), "Texte alternatif de l'image, utilisé par les lecteurs d'écran et les moteurs de recherche ", (0, _jsxRuntime.jsx)("br", {}), "largeur ", (0, _jsxRuntime.jsx)("br", {}), "largeur à laquelle l'image doit être affichée ", (0, _jsxRuntime.jsx)("br", {}), "hauteur ", (0, _jsxRuntime.jsx)("br", {}), "hauteur à laquelle l'image doit être affichée ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Réunion"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne (lien de prise de rendez-vous)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://app.hubspot.com/meetings/developers-r-kewl\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Menu"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier (ID de menu)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "123456789"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Page"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier (ID de page)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "texte enrichi"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne (peut contenir du HTML)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"# Hello, world! <RelatedApiLink />\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Campagne Salesforce"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne (ID de campagne Salesforce)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"7016A0000005S0tQAE\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Menu simple"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau d'objets d'éléments de menu"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[   {     \"isPublished\" : true,    \"pageLinkId\" : 123456789,    \"pageLinkName\" : \"My page\",    \"isDeleted\" : false,    \"categoryId\" : 1,    \"subCategory\" : \"site_page\",    \"contentType\" : \"site_page\",    \"state\" : \"PUBLISHED_OR_SCHEDULED\",    \"linkLabel\" : \"This is a page\",    \"linkUrl\" : null,    \"linkParams\" : null,    \"linkTarget\" : null,    \"type\" : \"PAGE_LINK\",    \"children\" : [ ]  } ]"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["isPublished ", (0, _jsxRuntime.jsx)("br", {}), "true/false – La page de l'élément de menu est-elle publiée ? ", (0, _jsxRuntime.jsx)("br", {}), "pageLinkId ", (0, _jsxRuntime.jsx)("br", {}), "ID de page dans le CMS ", (0, _jsxRuntime.jsx)("br", {}), "pageLinkName ", (0, _jsxRuntime.jsx)("br", {}), "Le véritable nom de la page dans le CMS ", (0, _jsxRuntime.jsx)("br", {}), "isDeleted ", (0, _jsxRuntime.jsx)("br", {}), "true/false ", (0, _jsxRuntime.jsx)("br", {}), "categoryId ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "1 - Page de site"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "3 - Article de blog"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "subCategory ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "site_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "landing_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "blog"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "normal_blog_post"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "contentType ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "site_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "landing_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "blog"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "état", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "DRAFT"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "DRAFT_AB"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED_OR_SCHEDULED"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED_AB"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "SCHEDULED"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "linkLabel ", (0, _jsxRuntime.jsx)("br", {}), "texte que l'utilisateur lit et sur lequel il clique ", (0, _jsxRuntime.jsx)("br", {}), "linkUrl ", (0, _jsxRuntime.jsx)("br", {}), "URL véritable vers laquelle l'utilisateur est redirigé après avoir cliqué ", (0, _jsxRuntime.jsx)("br", {}), "linkParams ", (0, _jsxRuntime.jsx)("br", {}), "\\# liens ou ? paramètres de requête ", (0, _jsxRuntime.jsx)("br", {}), "linkTarget ", (0, _jsxRuntime.jsx)("br", {}), "\"_blank\" si l'ouverture dans un nouvel onglet est activée, sinon \"null\" ", (0, _jsxRuntime.jsx)("br", {}), "type ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"PAGE_LINK\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"PAGE_LINK_WITH_PARAMS\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"NO_LINK\""
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "enfants", (0, _jsxRuntime.jsx)("br", {}), "tableau d'objets d'éléments de menu identiques aux éléments de menu individuels. ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Balise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entier (ID de balise ou slug, ID recommandé)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Texte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"it's like any other string\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"type\" : \"CONTENT\",  \"href\" : null,  \"content_id\" : 123456789}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["type ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"EXTERNAL\" pour les URL de HubSpot (hors e-mail)"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"CONTENT\" pour les pages, les articles de blog et les pages de destination"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"FILE\" pour les fichiers téléchargés dans le gestionnaire de fichiers"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"EMAIL_ADDRESS\" pour les adresses e-mail"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"BLOG\" pour les pages de listing de blog"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "href ", (0, _jsxRuntime.jsx)("br", {}), "Chaîne, l'URL vers laquelle vous établissez un lien. ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}