"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79942329381;
const slug = exports.slug = 'guides/cms/marketplace/template-guidelines';
const title = exports.title = 'HubSpotテンプレート マーケットプレイス ガイドライン';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Pages | Marketplace Requirements Overview | 202209';
const metaDescription = exports.metaDescription = 'HubSpotテンプレートマーケットプレイスでは、開発者に直接依存することなくオンラインプレゼンスを強化できるよう、何千ものお客さまを支援しています。アセットマーケットプレイスは、美しく使いやすいテーマ、テンプレート、モジュールを作成している開発者の皆さまによって運営されています。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%83%E3%83%88%E3%83%97%E3%83%AC%E3%82%A4%E3%82%B9%E3%81%A7%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E8%B2%A9%E5%A3%B2%E3%81%99%E3%82%8B%E6%84%8F%E7%BE%A9",
  "label": "マーケットプレイスでテンプレートを販売する意義",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%A6%82%E8%A6%81%E3%81%A8%E8%A6%81%E4%BB%B6",
  "label": "概要と要件",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%83%E3%83%88%E3%83%97%E3%83%AC%E3%82%A4%E3%82%B9%E3%81%A7%E3%81%AE%E3%83%86%E3%83%BC%E3%83%9E%E3%81%AE%E6%8E%B2%E8%BC%89%E3%81%A8%E6%9B%B4%E6%96%B0",
  "label": "テンプレートマーケットプレイスでのテーマの掲載と更新",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E6%94%AF%E6%89%95%E3%81%84%E3%82%B2%E3%83%BC%E3%83%88%E3%82%A6%E3%82%A7%E3%82%A4",
  "label": "サポートされている支払いゲートウェイ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%89%95%E3%81%84%E6%88%BB%E3%81%97%E7%94%B3%E8%AB%8B",
  "label": "払い戻し申請",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%88%E3%83%A9%E3%83%B3%E3%82%B6%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AE%E7%AE%A1%E7%90%86",
  "label": "トランザクションの管理",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E6%8E%B2%E8%BC%89%E6%83%85%E5%A0%B1%E3%81%AE%E5%89%8A%E9%99%A4",
  "label": "テンプレート掲載情報の削除",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F",
  "label": "よくある質問",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      br: "br",
      code: "code",
      strong: "strong",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ContentCardsRow,
      ContentCard,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ContentCard) _missingMdxReference("ContentCard", true);
  if (!ContentCardsRow) _missingMdxReference("ContentCardsRow", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpotテンプレート マーケットプレイス ガイドライン"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "HubSpotテンプレートマーケットプレイス"
      }), "では、開発者に直接依存することなくオンラインプレゼンスを強化できるよう、何千ものお客さまを支援しています。テンプレートマーケットプレイスは、ユーザー向けの使いやすいテンプレートを作成している開発者によって運営されています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotテンプレートマーケットプレイスで販売できるテンプレートの種類には、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/website",
        children: "テーマ"
      }), "と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/modules",
        children: "モジュール"
      }), "の2つがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "マーケットプレイスでテンプレートを販売する意義"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "新しい収益源を創出しようと目指している開発者や代理店にとって、HubSpotマーケットプレイスは大きなチャンスになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**非排他的：**皆さまの多大な労力を注いで生み出されたテンプレートを、1つのマーケットプレイスだけに縛り付けるべきではありません。HubSpotのマーケットプレイスに掲載するテンプレートは、このマーケットプレイスの他でも独自のプロジェクトのために自由に使用していただけます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**一度作成すれば何度でも販売可能：**テンプレートがマーケットプレイスに掲載されると、1対多の機会が生まれます。つまり、テンプレートを一度作成するだけで、複数の顧客に再販することができます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**ビジネスの成長：**テンプレート（無料と有料）を作成してHubSpotマーケットプレイスで販売している多くの開発者は、マーケットプレイスでの収益と、購入者からリクエストされる追加のサービスでの収益によってビジネスを成長させています。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**制作者のコミュニティーに参加して、自分のスキルをアピール：**成長を続ける協力的なエコシステムとつながって、HubSpotテンプレートマーケットプレイスの未来の形作りを支援しながら、提供するサービスに注目を集めることができます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "概要と要件"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テンプレートマーケットプレイスでの販売を開始するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "テンプレート マーケットプレイス プロバイダー アカウントを作成"
      }), "します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ご提出いただくテンプレートは、コンプライアンス、設計、およびコード品質の要件を満たしている必要があります。テンプレートの種類ごとに、マーケットプレイスに掲載するために満たさなければならない固有の要件もあります。以下で、これらの要件を確認できます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**マーケットプレイス用のテンプレートを作成する前に、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/template-providers",
          children: "HubSpotテンプレートマーケットプレイス用のテンプレートの作成"
        }), "（英語）、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/themes/branding-guidelines",
          children: "ブランディング"
        }), "（英語）、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/tm-usage-guidelines",
          children: "HubSpot商標の使用"
        }), "、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/content-usage-guidelines",
          children: "コンテンツの使用"
        }), "に関するガイドラインを必ずお読みください。これらのガイドラインに違反しているテンプレートは、HubSpotの追加規約に記載されているとおり、直ちにマーケットプレイスから削除されます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(ContentCardsRow, {
      children: [(0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "テーマの要件"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "マーケティング担当者が快適にコンテンツを編集できるよう、テーマには、連携して機能するように設計されたテンプレート、モジュール、グローバルコンテンツ、スタイルの設定が移植可能な形で統合されています。"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "en-us/guides/cms/marketplace/theme-requirements",
            children: "テーマの要件を確認"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "モジュールの要件"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "提出するテーマにはモジュールを含めます。モジュールとは、テンプレートで使用したり、ドラッグ＆ドロップエリアおよびフレキシブルカラムを使用してページに追加したりできる、再利用可能なコンポーネントのことです。スタンドアロンのモジュールを提出することもできます。スタンドアロンのモジュールとは、HubSpotのコンテンツエディターで任意のテーマに追加できるモジュールのことです。"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "en-us/guides/cms/marketplace/module-requirements",
            children: "モジュールの要件を確認"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "リストページの要件"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "HubSpotテンプレートマーケットプレイスに提出する時点で、テンプレートとそのリストページが従っていなければならない要件。これらの基準は、マーケットプレイスの全てのテンプレートに適用されます。"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/marketplace/general-requirements",
            children: "リストページの要件を確認"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "テンプレート マーケットプレイス ポリシー"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "テンプレートマーケットプレイスにテンプレートを掲載するための要件とコンプライアンスの詳細。"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/marketplace/template-policies",
            children: "テンプレート マーケットプレイス ポリシーを確認"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレートマーケットプレイスでのテーマの掲載と更新"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テンプレートを作成したら、HubSpotテンプレートマーケットプレイスに掲載するための承認を得るために、テンプレートを送信してください。送信されたテンプレートに対して、HubSpotテンプレート マーケットプレイス チームが審査と承認プロセスを実施します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "テンプレートマーケットプレイスでのテンプレートの掲載方法と更新方法をご確認ください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サポートされている支払いゲートウェイ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotは現在、マーケットプレイスプロバイダーが次の支払いゲートウェイに接続して支払いを受け取れるようにしています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["PayPal（", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.paypal.com/us/webapps/mpp/country-worldwide",
          children: "サポートされている国"
        }), " | ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.paypal.com/us/smarthelp/article/what-are-the-cross-border-fees-when-selling-international-faq2927",
          children: "国際決済"
        }), "）"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stripe（", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/global",
          children: "サポートされている国"
        }), " | ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/docs/connect/cross-border-payouts",
          children: "国際決済"
        }), "）"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "顧客からの支払いは、マーケットプレイス プロバイダー アカウントで接続されている支払い方法を通じて受け取ります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントで、［マーケットプレイス］>［プロバイダー情報］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［お支払い情報］セクションで、支払い情報を確認し、必要に応じて更新できます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "購入プロセス中に発生する可能性のある支払いゲートウェイの問題について、HubSpotは責任を負いません。HubSpotは取引手数料を頂いていません。全ての取引はHubSpotプラットフォームとは関係なく処理されます。何らかの理由で支払い方法が機能しなくなった場合は、ネガティブな顧客体験にならないようにするために、アセットがリストから一時的に除外されることがあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**HubSpotは、世界中のお客さまに活用していただいています。そのため、支払いゲートウェイを設定する際には、国際通貨による支払い（国際決済）を許可することを強くお勧めします。"
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["HubSpotは、テンプレート マーケットプレイス プロバイダーが自国でStripe支払いゲートウェイを利用いただける場合には、Stripe支払いゲートウェイを使用することを強く推奨します。Stripeが", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/global",
          children: "お住まいの国で利用いただけない"
        }), "場合は、PayPal支払いゲートウェイの使用をお勧めします。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "払い戻し申請"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["場合によっては購入者から払い戻しが請求されることがあります。掲載情報で返金ポリシーについて明確に説明し、返金ポリシーに関するドキュメントへのリンクを記載してください。HubSpotでは、正当な返金請求に誠意を持って対応するよう、プロバイダーの皆さまに奨励しています。詳細は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/resources/marketplace-refund-guidelines",
        children: "返金請求のガイドライン"
      }), "でご確認いただけます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "トランザクションの管理"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "顧客がダウンロードしたテンプレートごとに、テンプレート マーケットプレイス プロバイダー アカウントに取引レコードが作成されます。全ての取引は、「マーケットプレイストランザクション」という取引パイプラインの「トランザクション」取引ステージに取り込まれます。トランザクションに対する払い戻しが申請されると、取引レコードに［Refunded at（払い戻し日時）］プロパティーが設定されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プロバイダーは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "取引パイプラインと取引ステージをカスタマイズ"
      }), "し、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows/choose-your-workflow-actions#property-management",
        children: "取引のプロパティーをワークフローで使用"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages"
      }), "できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレート掲載情報の削除"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotは、以下に示す理由（ただしこれらに限られません）により、マーケットプレイスの掲載情報を削除する権利を留保します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テンプレートにバグがある。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "提出物に含まれるサードパーティーのテンプレートが破損している。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["テンプレートに、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/resources/which-browsers-are-supported-by-hubspot",
          children: "HubSpotのサポート対象ブラウザー"
        }), "との互換性がない。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "配布ライセンスのない画像、アイコン、フォント、音声、動画など、著作権で保護された素材を使用している。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ユーザーに十分な情報を提供した上で明示的なオプトインの同意を得ずに、ユーザーを追跡するテンプレート。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["以下を含むテンプレート（テンプレートマーケットプレイスまたはテンプレートのデモからのコンテンツへのリンクを含む）：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "違法行為の促進や関与"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "名誉棄損や中傷を含むコンテンツ、悪意のあるコンテンツ"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "不誠実な、または虚偽の記載"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "特定の人種、宗教、年齢、性別、性的指向を侮辱するようなコンテンツ"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "モジュールが追加先のページ／メール上の他の要素に影響を与える場合、モジュールは削除されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "よくある質問"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Does the European Union P2B regulation (July 12,2020) apply to the App and Template Marketplaces?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No, the most recent European Union P2B regulation that took effect on July 12, 2020, does not apply to the HubSpot App and Template Marketplaces. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ec.europa.eu/digital-single-market/en/business-business-trading-practices",
          children: "Learn More"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Is there a specific HubSpot account type I need to submit to the Marketplace?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://offers.hubspot.com/free-marketplace-account",
          children: "Marketplace provider account"
        }), " is needed to submit to the Template Marketplace."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Some partners manage their Marketplace listings in their Partner account to use the templates in the same account. Other partners manage their listings in a separate Marketplace account to keep the templates separate from other templates in their design manager tool."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Do products published in the Marketplace live in my account or in the HubSpot Marketplace system?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Products submitted to or published in the Marketplace live in the HubSpot Marketplace system. This ensures that providers can continue to edit them in their own account without the changes affecting customers’ purchases. To update a Marketplace listing, a provider must resubmit the listing so it can be reviewed and republished."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Customers who have purchased previous versions of a product in a listing can ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/articles/kcs_article/resources/marketplace-customers-frequently-asked-questions#update",
          children: "update to the latest version"
        }), " free of charge."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How do I update my company name?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Once you have listed a template in the marketplace, you will need to contact ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://help.hubspot.com/",
          children: "HubSpot Support"
        }), "  to change your company name."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Why don't I see all of my products when trying to create a listing?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Possible reasons you may not see a product when trying to create a listing include:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template is currently listed in the Marketplace or contains elements from a currently listed template."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template isn't supported in the Marketplace (transactional emails, ticket emails, etc.)"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template is a clone of a template from the Marketplace."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "What should I use as a preview link?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["When creating preview URLs you must use your own domain name if available. If you don't have a domain available to use, HubSpot provides each account with a system domain in the following URL structure:", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[AccountID].hs-sites.com"
        })]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": HubSpot does not support template previews that use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "preview.hs-sites.com"
        }), " subdomain."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Can a single template be used in multiple themes?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Yes, a single template can be used in multiple themes. A common example of when a template may be used in multiple themes is if a provider offers a Starter and Premium version of the same theme."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Do I have to resubmit a theme or module if I make changes to it?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Yes. All changes to a theme or module must be re-submitted to the Marketplace for pre-validation and approval by the quality team. Once the changes are approved, the listing and template will be updated in the Marketplace for customers."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How do I adjust the price of a theme or module?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "To adjust the price of a submitted product, you must resubmit it to the Marketplace with updated information. Prices cannot be changed from free to paid, or vice versa once a template is published."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "How do I receive Marketplace payments?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Payments are received through the payment method that you've connected in your Marketplace Provider Profile. In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Provider Info"
        }), ". In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Payment Method"
        }), " section, view or update your payment information."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot doesn’t take a cut of any transactions, and all transactions are handled independently of the HubSpot platform."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Where do I go to get peer-to-peer feedback or connect with other Marketplace providers? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Join the #marketplace channel in HubSpot's Developer Slack by signing up at ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "https://developers.hubspot.com/slack"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "When a customer requests a refund, do their downloaded files remain in their HubSpot account?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "All files associated with the template as well as any dependencies (e.g. pages created with the template, cloned templates, and modules) will be removed once the refund is initiated."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How can I stay informed on the latest developer requirements and changes? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To stay informed of the latest changes, visit and subscribe to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "HubSpot Developer Changelog"
        }), "."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}