"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358753;
const slug = exports.slug = 'guides/api/crm/objects/deals';
const title = exports.title = 'CRM-API | Deals';
const name = exports.name = 'vNext Docs DP - Deals';
const metaDescription = exports.metaDescription = 'Ein Deal speichert Daten zu einer laufenden Transaktion. Deals-Endpunkte ermöglichen es Ihnen, diese Daten zu verwalten und zwischen HubSpot und anderen Systemen zu synchronisieren. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "deals-erstellen",
  "label": "Deals erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "eigenschaften",
  "label": "Eigenschaften",
  "parent": "deals-erstellen"
}, {
  "depth": 1,
  "id": "zuordnungen",
  "label": "Zuordnungen",
  "parent": "deals-erstellen"
}, {
  "depth": 0,
  "id": "deals-abrufen",
  "label": "Deals abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "deals-aktualisieren",
  "label": "Deals aktualisieren",
  "parent": null
}, {
  "depth": 1,
  "id": "bestehende-deals-mit-datens%C3%A4tzen-oder-aktivit%C3%A4ten-verkn%C3%BCpfen",
  "label": "Bestehende Deals mit Datensätzen oder Aktivitäten verknüpfen",
  "parent": "deals-aktualisieren"
}, {
  "depth": 1,
  "id": "eine-zuordnung-entfernen",
  "label": "Eine Zuordnung entfernen",
  "parent": "deals-aktualisieren"
}, {
  "depth": 0,
  "id": "eine-aktivit%C3%A4t-an-einen-deal-datensatz-anpinnen",
  "label": "Eine Aktivität an einen Deal-Datensatz anpinnen",
  "parent": null
}, {
  "depth": 0,
  "id": "deals-l%C3%B6schen",
  "label": "Deals löschen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Deals"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot stehen Deals für Transaktionen mit Kontakten oder Unternehmen. Deals werden während Ihres Vertriebsprozesses über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "Pipeline-Phasen"
      }), " nachverfolgt, bis sie gewonnen oder verloren werden. Die Deals-Endpunkte ermöglichen es Ihnen, Deal-Datensätze zu erstellen und zu verwalten sowie Deal-Daten zwischen HubSpot und anderen Systemen zu synchronisieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über Objekte, Datensätze, Eigenschaften und Zuordnungen-APIs im Leitfaden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Grundlegendes zum CRM"
      }), ". Weitere allgemeine Informationen zu Objekten und Datensätzen in HubSpot finden Sie in diesem Artikel über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: "Verwalten Ihrer CRM-Datenbank"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Deals erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um neue Deals zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Schließen Sie in Ihrer Anfrage Ihre Deal-Daten in ein properties-Objekt ein. Sie können auch ein associations-Objekt hinzufügen, um Ihren neuen Deal vorhandenen Datensätzen (z. B. Kontakte, Unternehmen) oder Aktivitäten (z. B. Meetings, Notizen) zuzuordnen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Deal-Details werden in Deal-Eigenschaften gespeichert. Es gibt ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/hubspots-default-deal-properties",
        children: "Standard-HubSpot-Deal-Eigenschaften"
      }), ", Sie können jedoch auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "benutzerdefinierte Eigenschaften erstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Erstellen eines neuen sollten Sie die folgenden Eigenschaften in Ihre Anfrage einschließen: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealname"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealstage"
      }), " und, wenn Sie mehrere haben, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pipeline"
      }), ". Wenn keine Pipeline angegeben ist, wird die Standard-Pipeline verwendet."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um alle verfügbaren Eigenschaften anzuzeigen, können Sie eine Liste der Deal-Eigenschaften Ihres Accounts abrufen, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/deals"
      }), " durchführen. Erfahren Sie mehr über die", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: " Eigenschaften-API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Sie müssen die interne ID einer Deal-Phase oder -Pipeline verwenden, wenn Sie einen Deal über die API erstellen. Die interne ID wird auch zurückgegeben, wenn Sie Deals über die API abrufen. Sie können die interne ID einer Deal-Phase oder -Pipeline in Ihren ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages#to-customize-your-deal-stages:~:text=To%20view%20the%20internal%20name%20for%20a%20deal%20stage%2C%20hover%20over%20the%20stage%20and%20click%20the%20code%20code%20icon.%20The%20internal%20name%20is%20used%20by%20integrations%20and%20the%20API.",
          children: "Deal-Pipeline-Einstellungen finden"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um beispielsweise einen neuen Deal zu erstellen, kann Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"amount\": \"1500.00\",\n    \"closedate\": \"2019-12-07T16:50:06.678Z\",\n    \"dealname\": \"New deal\",\n    \"pipeline\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hubspot_owner_id\": \"910901\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zuordnungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Erstellen eines neuen Deals können Sie den Deal auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "bestehenden Datensätzen"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "Aktivitäten"
      }), " in einem associations-Objekt zuordnen. Um beispielsweise einen neuen Deal mit einem bestehenden Kontakt und Unternehmen zu verknüpfen, müsste Ihre Anfrage wie folgt aussehen:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"amount\": \"1500.00\",\n    \"closedate\": \"2019-12-07T16:50:06.678Z\",\n    \"dealname\": \"New deal\",\n    \"pipeline\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hubspot_owner_id\": \"910901\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 201\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 5\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 3\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im associations-Objekt sollten Sie Folgendes einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Datensatz oder die Aktivität, den bzw. die Sie dem Deal zuordnen möchten, angegeben durch seinen eindeutigen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "-Wert."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ der Zuordnung zwischen Deal und Datensatz/Aktivität. Beziehen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), " ein. Standardzuordnungstyp-IDs sind ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "hier"
            }), " aufgeführt. Sie können den Wert für benutzerdefinierte Zuordnungstypen (d. h. Label) über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "Zuordnungen-API"
            }), " abrufen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Deals abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Deals einzeln oder mehrere gleichzeitig aktualisieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um einen einzelnen Deal abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/{dealId}"
        }), " durch."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um eine Liste aller Deals anzufordern, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals"
        }), " durch."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können für diese Endpunkte die folgenden Abfrageparameter in die Anfrage-URL einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der Eigenschaften, die in der Antwort zurückgegeben werden sollen. Wenn das angefragte Deal keinen Wert für eine Eigenschaft hat, wird er nicht in der Antwort angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der aktuellen und historischen Eigenschaften, die in der Antwort zurückgegeben werden sollen. Wenn das angefragte Deal keinen Wert für eine Eigenschaft hat, wird er nicht in der Antwort angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine durch Kommas getrennte Liste von Objekten, für die zugehörige IDs abgerufen werden sollen. Alle angegebenen Zuordnungen, die nicht vorhanden sind, werden nicht in der Antwort zurückgegeben. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "Zuordnungen-API."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um einen Batch von bestimmten Deals nach Datensatz-ID oder einer ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "benutzerdefinierten „Eindeutige ID“-Eigenschaft abzurufen"
        }), ", führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/deals/batch/read"
        }), " durch. Der Batch-Endpunkt kann Zuordnungen ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " abrufen. Erfahren Sie, wie Sie Zuordnungen mit der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "Zuordnungen-API"
        }), " stapelweise lesen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für den Batch-Leseendpunkt können Sie auch den optionalen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "-Parameter verwenden, um Deals anhand einer benutzerdefinierten ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "„Eindeutige ID“-Eigenschaft"
      }), " abzurufen. Standardmäßig beziehen sich die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "-Werte in der Anfrage auf die Datensatz-ID (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), sodass der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "-Parameter beim Abrufen nach Datensatz-ID nicht erforderlich ist. Um eine benutzerdefinierte „Eindeutiger Wert“-Eigenschaft zum Abrufen von Deals zu verwenden, müssen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "-Parameter einschließen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um beispielsweise einen Batch von Deals abzurufen, könnte Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with record ID\n{\n  \"properties\": [\"dealname\", \"dealstage\", \"pipeline\"],\n  \"inputs\": [\n    {\n      \"id\": \"7891023\"\n    },\n    {\n      \"id\": \"987654\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"dealname\", \"dealstage\", \"pipeline\"],\n  \"idProperty\": \"uniqueordernumber\",\n  \"inputs\": [\n    {\n      \"id\": \"0001111\"\n    },\n    {\n      \"id\": \"0001112\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um Deals mit aktuellen und historischen Werten für eine Eigenschaft abzurufen, könnte Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"dealstage\"],\n  \"inputs\": [\n    {\n      \"id\": \"7891023\"\n    },\n    {\n      \"id\": \"987654\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Deals aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Deals einzeln oder mehrere gleichzeitig aktualisieren. Bei vorhandenen Deals ist die Deal-ID ein eindeutiger Standardwert, mit dem Sie en Deal über die API aktualisieren können. Sie können Deals jedoch auch mithilfe ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "benutzerdefinierter „Eindeutige ID“-Eigenschaften"
      }), " identifizieren und aktualisieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um einen einzelnen Deal anhand seiner Datensatz-ID zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/{dealId}"
        }), " durch und schließen Sie die Daten ein, die Sie aktualisieren möchten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um mehrere Deals zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/batch/update"
        }), " durch. Fügen Sie im Anfragetext ein Array mit den IDs für die Deals und die Eigenschaften hinzu, die Sie aktualisieren möchten."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bestehende Deals mit Datensätzen oder Aktivitäten verknüpfen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen Deal anderen CRM-Datensätzen oder einer Aktivität zuzuordnen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Um den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), "-Wert abzurufen, verweisen Sie auf ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "diese Liste"
        }), " der Standardwerte oder führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), " durch."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das Zuordnen von Datensätzen mit der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "Zuordnungen-API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine Zuordnung entfernen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Zuordnung zwischen einem Deal und einem Datensatz oder einer Aktivität zu entfernen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an die folgende URL durch: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Aktivität an einen Deal-Datensatz anpinnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können eine Aktivität über die API an einen Deal-Datensatz anpinnen, indem Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "-Feld in Ihre Anfrage aufnehmen. Geben Sie im Feld die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " der anzupinnenden Aktivität an, die über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "Interaktionen-APIs"
      }), " abgerufen werden kann. Sie können eine Aktivität pro Datensatz anpinnen, und die Aktivität muss bereits vor dem Anheften mit dem Deal verknüpft sein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um die angepinnten Aktivitäten eines Deals festzulegen oder zu aktualisieren, könnte Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body PATCH /crm/v3/objects/deals/{dealId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können auch ein Deal erstellen, ihn mit einer vorhandenen Aktivität verknüpfen und die Aktivität in derselben Anfrage anpinnen. Zum Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body POST /crm/v3/objects/deals\n{\n  \"properties\": {\n    \"dealname\": \"New deal\",\n    \"pipelines\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 213\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Deals löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Deals einzeln oder mehrere gleichzeitig löschen, wodurch der Deal in den Papierkorb in HubSpot verschoben wird. Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "den Deal später in HubSpot wiederherstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um einen einzelnen Deal anhand seiner ID zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das gleichzeitige Löschen von mehreren Deals, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Endpunkte"
      }), " klicken."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}