"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921778;
const slug = exports.slug = 'guides/api/files';
const title = exports.title = 'API du CMS | Gestionnaire de fichiers';
const name = exports.name = 'vNext Docs DP | Gestionnaire de fichiers';
const metaDescription = exports.metaDescription = 'Les points de terminaison des fichiers sont utilisés pour obtenir et gérer des données dans votre gestionnaire de fichiers.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "charger-un-fichier",
  "label": "Charger un fichier",
  "parent": null
}, {
  "depth": 0,
  "id": "consulter-le-statut-de-t%C3%A9l%C3%A9chargement-d-un-fichier",
  "label": "Consulter le statut de téléchargement d'un fichier",
  "parent": null
}, {
  "depth": 0,
  "id": "afficher-les-d%C3%A9tails-d-un-fichier",
  "label": "Afficher les détails d'un fichier",
  "parent": null
}, {
  "depth": 0,
  "id": "supprimer-un-fichier",
  "label": "Supprimer un fichier",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-un-dossier",
  "label": "Créer un dossier",
  "parent": null
}, {
  "depth": 0,
  "id": "modifications-de-la-version%C2%A03",
  "label": "Modifications de la version 3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Gestionnaire de fichiers"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez l'outil Fichiers de HubSpot pour gérer et stocker des fichiers dans HubSpot. Les fichiers hébergés dans HubSpot peuvent être chargés et utilisés dans HubSpot et dans le contenu externe. Ils peuvent également être joints à des fiches d'informations à l'aide de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API des engagements"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si votre entreprise conçoit son site web à l'aide du CMS Hub, vous pouvez utiliser l'API de fichiers pour charger et stocker des éléments dans HubSpot. Ces fichiers peuvent ensuite être proposés et partagés via CMS Hub."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez accéder à l'outil Fichiers depuis ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/files/upload-files-to-use-in-your-hubspot-content",
        children: "HubSpot"
      }), " ou via l'API de fichiers. Découvrez ci-dessous l'API de fichiers et apprenez à charger et supprimer des fichiers. Pour une liste complète des points de terminaison de l'API de fichiers, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " ci-dessus."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Charger un fichier"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fichiers peuvent être chargés à l'aide d'une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " multipart/form-data à", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files"
      }), " avec les champs suivants. Si un ID de dossier spécifique n'est pas requis lors du chargement, il est recommandé de charger des fichiers dans un dossier et non dans le répertoire racine. Les exigences de dossier lors du chargement peuvent changer à l'avenir."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le fichier à charger (obligatoire)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un objet JSON qui contrôle la confidentialité et l'indexabilité du fichier et contient deux champs : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "access"
            }), ", qui est obligatoire, et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ttl"
            }), ", qui indique une période après laquelle le fichier sera automatiquement supprimé. Si vous utilisez le champ ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ttl"
            }), " :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "La période minimale qui doit être définie est de 1 jour."
              }), (0, _jsxRuntime.jsx)("li", {
                children: "La période maximale qui peut être fixée est de 1 an."
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Après la période définie, le fichier sera définitivement supprimé. Après la suppression, le fichier ne pourra pas être récupéré ou restauré."
              })]
            }), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'ID du dossier dans lequel le fichier sera téléchargé. Ce champ ", (0, _jsxRuntime.jsx)("u", {
              children: "ou"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderPath"
            }), " doit être fourni dans votre demande (mais ", (0, _jsxRuntime.jsx)("u", {
              children: "pas"
            }), " les deux)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderPath"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le chemin du dossier dans lequel le fichier sera téléchargé. Ce champ ", (0, _jsxRuntime.jsx)("u", {
              children: "ou"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderId"
            }), " doit être fourni dans votre demande (mais ", (0, _jsxRuntime.jsx)("u", {
              children: "pas"
            }), " les deux)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fileName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom du fichier. Si aucun nom n'est spécifié, un nom sera généré à partir du contenu du fichier."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "charsetHunch"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un encodage défini de caractères pour le fichier chargé. Si ce champ n'est pas renseigné, il sera dérivé du fichier."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, si vous souhaitez télécharger un fichier avec les critères suivants sur votre compte HubSpot :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nom du fichier :"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cat.png"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Dossier de destination dans le gestionnaire de fichiers HubSpot :"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/library/cat_archive"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Accessibilité des fichiers dans HubSpot :"
        }), " accès privé"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les en-têtes et le corps de la demande suivants doivent faire partie de votre demande :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url 'https://api.hubapi.com/files/v3/files?=' \\\n  --header 'Authorization: Bearer pat-na1-00000000-0000-0000-0000-000000000000' \\\n  --header 'Content-type: multipart/form-data' \\\n  --form file=@/Users/person/Downloads/cat.png \\\n  --form 'options={\"access\": \"PRIVATE\"}' \\\n  --form folderPath=/library/cat_archive\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La réponse résultante inclura les éléments ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "parentFolderId"
      }), " du fichier téléchargé, que vous pouvez utiliser pour récupérer le fichier via une demande GET."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// 201 Response from successful file upload\n{\n  \"id\": \"122692044085\",\n  \"createdAt\": \"2023-06-28T17:56:45.393Z\",\n  \"updatedAt\": \"2023-06-28T17:56:45.393Z\",\n  \"archived\": false,\n  \"parentFolderId\": \"122692510820\",\n  \"name\": \"cat\",\n  \"path\": \"/library/cat_archive/cat.png\",\n  \"size\": 24574,\n  \"height\": 219,\n  \"width\": 225,\n  \"encoding\": \"png\",\n  \"type\": \"IMG\",\n  \"extension\": \"png\",\n  \"defaultHostingUrl\": \"https://12345.fs1.hubspotusercontent-na1.net/hubfs/12345/library/cat_archive/cat.png\",\n  \"url\": \"https://12345.fs1.hubspotusercontent-na1.net/hubfs/12345/library/cat_archive/cat.png\",\n  \"isUsableInContent\": true,\n  \"access\": \"PRIVATE\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Consulter le statut de téléchargement d'un fichier"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous importez un fichier à partir d'une URL vers votre gestionnaire de fichiers via une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " vers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/import-from-url/async"
      }), ", vous pouvez consulter le statut de téléchargement du fichier."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour ce faire, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " vers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/import-from-url/async/tasks/{taskId}/status"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Après avoir fait cette demande, vous recevrez l'une des réponses suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING"
        }), " : le fichier est dans la file d'attente pour être téléchargé. Le processus d'import n'a pas encore commencé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), " : le fichier est en cours de téléchargement."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELED"
        }), " : le téléchargement a été annulé et le fichier ne sera pas téléchargé. Pour importer le fichier dans votre compte HubSpot, vous devrez le télécharger à nouveau."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "COMPLETE"
        }), " : le fichier a correctement été téléchargé vers l'outil Fichiers. Le fichier téléchargé apparaîtra dans votre outil Fichiers."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Afficher les détails d'un fichier"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour consulter les détails d'un fichier chargé dans l'outil Fichiers, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}"
      }), ". Cela renverra le fichier avec des informations telles que le nom, la hauteur et la largeur, l'encodage ou encore l'URL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, pour récupérer les détails d'un fichier :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si un fichier est défini comme privé, l'URL renverra une erreur 404. Pour obtenir une URL visible du fichier, vous pouvez effectuer une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/files/v3/files/{fileId}/signed-url"
      }), ". Lors de cette demande, vous pouvez inclure des paramètres ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property"
      }), " pour renvoyer des spécifiques telles que la hauteur et la largeur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supprimer un fichier"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer un fichier, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}"
      }), ". Le fichier sera marqué comme supprimé et son contenu sera inaccessible."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer définitivement un fichier, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}/gdpr-delete"
      }), ". Le contenu et les métadonnées du fichier seront définitivement supprimés sous 7 jours."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si un fichier n'est pas supprimé conformément au RGPD, son contenu restera sur les serveurs de HubSpot avec un statut privé et personne ne pourra y accéder. Pour assurer la suppression complète du contenu du fichier, utilisez la fonctionnalité de suppression conformément au RGPD."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un dossier"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un dossier, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/folders"
      }), ". Lors de la demande, vous pouvez inclure les champs ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Obligatoire"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Oui"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : le nom du dossier que vous souhaitez créer."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Non"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : pour créer le dossier dans un dossier existant, incluez ce champ avec l'ID du dossier existant. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            }), " ne peuvent pas être définis en même temps."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Non"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : pour créer le dossier dans un dossier existant, incluez ce champ avec le chemin du dossier existant. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            }), " ne peuvent pas être définis en même temps."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body of POST request to /files/v3/folders\n{\n  \"name\": \"myNewFolder\",\n  \"parentFolderId\": 12345\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modifications de la version 3"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si vous utilisez la version précédente de cette API, la version 3 présente les modifications suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Tous les fichiers chargés via l'API seront visibles dans le tableau de bord des fichiers et le sélecteur de fichiers. Les fichiers cachés ne peuvent pas être créés. Cependant, les fichiers privés et non indexables peuvent toujours être créés."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Les fichiers de listing ne renverront pas de fichiers masqués ou supprimés. Toutefois, un vaste éventail de filtres peut être appliqué. Les fichiers masqués peuvent toujours être consultés selon l'ID, mais nécessitent un nouveau domaine : ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "files_ui_hidden.read."
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Plusieurs fichiers ne peuvent pas être téléchargés avec une seule demande."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Les actions de mise à jour de dossier, comme le déplacement et le renommage, sont désormais asynchrones. Chaque demande renvoie un jeton qui peut être utilisé pour vérifier le statut de la modification du dossier."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Les points de terminaison qui créent ou remplacent des fichiers nécessitent des niveaux d'accès pour les fichiers. Ces niveaux d'accès sont les suivants :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLIC_INDEXABLE"
            }), "** :** le fichier est accessible publiquement par toute personne disposant de l'URL. Les moteurs de recherche peuvent indexer le fichier."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLIC_NOT_INDEXABLE"
            }), "** :** le fichier est accessible publiquement par toute personne disposant de l'URL. X-Robots-Tag : l'en-tête non indexable sera envoyé lors de la récupération du fichier et les moteurs de recherche n'indexeront pas le fichier."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsxs)(_components.strong, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "PRIVATE"
              }), " :"]
            }), " le fichier n'est pas accessible publiquement. Nécessite une URL signée pour afficher le contenu. Les moteurs de recherche ne peuvent pas indexer le fichier."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Les points de terminaison qui créent des fichiers permettent la détection de doublons dans le cadre des options de chargement du fichier."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "ENTIRE_PORTAL"
            }), "** :** permet de rechercher un fichier en double dans le compte."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "EXACT_FOLDER"
            }), "** :** permet de rechercher un fichier en double dans le dossier indiqué."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "NONE"
            }), "** :** n'exécutez aucune aucune vérification de doublon."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "REJECT"
            }), "** :** rejetez le chargement si un doublon est trouvé."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "RETURN_EXISTING"
            }), "** :** si un fichier en double est trouvé, ne chargez aucun nouveau fichier et renvoyez le doublon."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["La détection des doublons fonctionne selon ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duplicateValidationScope"
            }), ", qui détermine la recherche d'un doublon."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Elle applique également ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duplicateValidationStrategy"
            }), ", qui précise ce qui se passe si un doublon est trouvé."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}