'use es6';

import { SpecificationActionTypes } from '../actions';
import { TryItActionTypes } from '../../TryIt/actions';
import { Map as ImmutableMap } from 'immutable';
import RequestStatus from '../../../lib/models/RequestStatus';
import { getSpecificationWithExtensions, getOasDocs, getFilteredAndSortedOasDocs, getOas, createHarStateFromDocs, updateHarStateWithAuthChange, updateHarStateWithFormData, getRequestBodyPaths, denormalizeRequestBody } from '../utils/SpecificationUtils';
const defaultState = new ImmutableMap({
  docDetails: new ImmutableMap({}),
  error: null,
  filteredOasDocs: null,
  oas: null,
  oasDocs: null,
  specificationWithExtensions: null,
  status: RequestStatus.uninitialized,
  availableClientLibraries: null
});
export default function (state = defaultState, action) {
  switch (action.type) {
    case SpecificationActionTypes.SPECIFICATION_FETCH_STARTED:
      return state.set('status', RequestStatus.pending);
    case SpecificationActionTypes.SPECIFICATION_FETCH_SUCCEEDED:
      {
        // The `requestBody` needs pre-parsing from SwaggerClient
        // to make the coding examples and form inputs usable
        const examplesToParse = getRequestBodyPaths(action.payload.data);
        examplesToParse.forEach(path => {
          denormalizeRequestBody(path, action.payload.data);
        });
        const specificationWithExtensions = getSpecificationWithExtensions(action.payload.data);
        const oasDocs = getOasDocs(specificationWithExtensions);
        const oas = getOas(specificationWithExtensions);
        const tags = specificationWithExtensions.tags || [];
        const filteredOasDocs = getFilteredAndSortedOasDocs(oasDocs, tags.map(t => t.name));
        const harState = createHarStateFromDocs(filteredOasDocs, specificationWithExtensions);

        // When there are no client libs, the serialization process returns nothing
        const availableClientLibraries = specificationWithExtensions['x-hubspot-available-client-libraries'] ? specificationWithExtensions['x-hubspot-available-client-libraries'].map(lib => lib.toLowerCase()) : [];
        return new ImmutableMap({
          docDetails: harState,
          error: null,
          filteredOasDocs,
          oas,
          oasDocs,
          specificationWithExtensions,
          status: RequestStatus.succeeded,
          availableClientLibraries,
          publicApiSpec: {
            info: action.payload && action.payload.data ? action.payload.data.info : undefined
          }
        });
      }
    case SpecificationActionTypes.SPECIFICATION_FETCH_FAILED:
      return state.merge({
        error: action.payload.data,
        status: new RequestStatus({
          failed: true,
          errorDetails: action.payload.error
        })
      });
    case SpecificationActionTypes.FORM_DATA_UPDATED:
      {
        return state.updateIn(['docDetails'], oldDetails => updateHarStateWithFormData(action.payload.formData, action.payload.docId, oldDetails));
      }
    case TryItActionTypes.SEND_REQUEST_STARTED:
      {
        if (action.payload) {
          // Auth details have changed, so recalculate HAR objects
          return state.updateIn(['docDetails'], oldDetails => {
            const auth = Object.assign({}, action.payload.auth, {
              authType: action.payload.authDetails.authType
            });
            return updateHarStateWithAuthChange(auth, oldDetails);
          });
        }
        return state;
      }
    default:
      return state;
  }
}