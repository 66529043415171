"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816620899;
const slug = exports.slug = 'guides/api/overview';
const title = exports.title = 'Cómo usar las API de HubSpot';
const name = exports.name = "LATAM (ES) vNext Docs DP - How to use HubSpot's APIs";
const metaDescription = exports.metaDescription = 'La API de HubSpot permite a los desarrolladores crear aplicaciones e integraciones personalizadas. Comienza leyendo nuestro resumen de API y crea una cuenta de desarrollador.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "creaci%C3%B3n-de-aplicaciones",
  "label": "Creación de aplicaciones",
  "parent": null
}, {
  "depth": 1,
  "id": "aplicaciones-p%C3%BAblicas",
  "label": "Aplicaciones públicas",
  "parent": "creaci%C3%B3n-de-aplicaciones"
}, {
  "depth": 1,
  "id": "aplicaciones-privadas",
  "label": "Aplicaciones privadas",
  "parent": "creaci%C3%B3n-de-aplicaciones"
}, {
  "depth": 0,
  "id": "autentificaci%C3%B3n",
  "label": "Autentificación",
  "parent": null
}, {
  "depth": 0,
  "id": "uso-y-l%C3%ADmites",
  "label": "Uso y límites",
  "parent": null
}, {
  "depth": 0,
  "id": "partners-de-aplicaciones-y-mercado-de-aplicaciones",
  "label": "Partners de aplicaciones y Mercado de aplicaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "descubre-c%C3%B3mo-usar-las-api-y-a-crear-aplicaciones-en-hubspot-academy",
  "label": "Descubre cómo usar las API y a crear aplicaciones en HubSpot Academy",
  "parent": null
}, {
  "depth": 2,
  "id": "enlaces-r%C3%A1pidos",
  "label": "Enlaces rápidos",
  "parent": "descubre-c%C3%B3mo-usar-las-api-y-a-crear-aplicaciones-en-hubspot-academy"
}, {
  "depth": 2,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": "descubre-c%C3%B3mo-usar-las-api-y-a-crear-aplicaciones-en-hubspot-academy"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      a: "a",
      h1: "h1",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["¿Buscando una API más antigua? Siguen siendo estables y compatibles. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api",
          children: "Ir a documentos heredados"
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Guías y recursos para desarrolladores de API"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las API de HubSpot, cuya ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "documentación de referencia puedes encontrar aquí"
      }), ", te permiten crear una aplicación o una integración funcional de forma rápida y sencilla. Este es un resumen de lo que necesitas usarlas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Creación de aplicaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de comenzar, debes decidir qué tipo de aplicación quieres crear."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicaciones públicas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si buscas crear una aplicación que otros usuarios de HubSpot fuera de tu organización instalarán, como una aplicación que aparece en el mercado de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "aplicaciones"
      }), ", debes crear una aplicación pública."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si estás creando una aplicación pública, deberás crear una cuenta de desarrollador de aplicaciones:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En primer lugar, navega a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/get-started",
          children: "esta página"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear cuenta de desarrollador de aplicaciones"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Autentifícate con tu cuenta de Google o Microsoft, o escribe tu correo electrónico."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sigue las instrucciones de configuración para crear tu cuenta de desarrollador."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Desde allí, puedes crear una aplicación, configurar OAuth y crear un entorno de prueba. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview",
        children: "Comienza a crear aplicaciones ahora."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicaciones privadas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si tu objetivo es crear una integración que solo puedan aprovechar otros usuarios de tu cuenta de HubSpot, como una aplicación interna que pueda acceder o modificar los datos de contacto de tu cuenta, puedes crear una aplicación privada."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre cómo crear una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "aplicación privada"
      }), " en este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autentificación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La mayoría de los puntos de terminación de HubSpot son compatibles con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "tokens de acceso de aplicación privada"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " desde el 30 de noviembre de 2022, las claves de API de HubSpot están obsoletas y ya no son compatibles. El uso continuado de las claves de API de HubSpot es un riesgo de seguridad para tu cuenta y tus datos. Durante esta fase de obsolescencia, HubSpot puede desactivar tu clave en cualquier momento."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["En su lugar, debes autentificarte usando un token de acceso de una aplicación privada u OAuth. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "este cambio"
        }), " y cómo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migrar una integración de claves de API"
        }), " para usar una aplicación privada en su lugar."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si quieres incluir tu aplicación en nuestro Mercado de aplicaciones o hacer que varios usuarios la instalen, debes usar OAuth."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si estás creando una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "aplicación privada"
        }), ", puedes hacer llamadas usando el token de acceso de tu aplicación, que también implementa OAuth."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uso y límites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Descubre nuestras ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "pautas de uso"
      }), ", límites de tasa y cómo verificar el uso de tus llamadas a la API."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Partners de aplicaciones y Mercado de aplicaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Revisa los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "requisitos para el anuncio de aplicaciones"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "crea un anuncio para una aplicación"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Descubre cómo usar las API y a crear aplicaciones en HubSpot Academy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre las API de HubSpot, las cuentas de desarrollador y cómo comenzar a realizar llamadas usando claves de OAuth y de API con estos cortos (y gratis ) ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/courses/integrating-with-hubspot-foundations",
        children: "videos"
      }), " de HubSpot Academy."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Enlaces rápidos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/signup-v2/developers/step/join-hubspot",
            children: "Crear una cuenta de desarrollador"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types",
            children: "Usar una cuenta de prueba"
          }), " para instalar tu aplicación y probar las llamadas a la API"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Mantente actualizado suscribiéndote al ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/changelog",
            children: "Registro de cambios"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Únete a la conversación o haz preguntas en los ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
            children: "foros de la comunidad de desarrolladores"
          }), " de HubSpot"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Conviértete en miembro de nuestra ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/slack/developer-slack",
            children: "comunidad de desarrolladores de Slack"
          })]
        }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "Documentos de referencia de API"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}