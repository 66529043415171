"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611088;
const slug = exports.slug = 'getting-started/slack/developer-slack';
const title = exports.title = 'Rejoindre la communauté Slack pour les développeurs';
const name = exports.name = `S'inscrire sur Slack - Développeurs design`;
const metaDescription = exports.metaDescription = 'Slack pour les développeurs est une communauté dédiée aux développeurs de HubSpot qui se réunissent pour un soutien et une collaboration entre développeurs autour de la plateforme HubSpot. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/Blog%20-%20Right%20CTA%20-%20Slack-1.png';
const featuredImageAltText = exports.featuredImageAltText = 'Collaborer dans Slack pour les développeurs';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%C3%A0-qui-s-adresse-la-communaut%C3%A9-slack-pour-les-d%C3%A9veloppeurs%C2%A0%3F",
  "label": "À qui s'adresse la communauté Slack pour les développeurs ?",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A0-quoi-dois-je-m-attendre-une-fois-que-j-aurai-rejoint-la-communaut%C3%A9%C2%A0%3F",
  "label": "À quoi dois-je m'attendre une fois que j'aurai rejoint la communauté ?",
  "parent": null
}, {
  "depth": 0,
  "id": "rejoignez-slack-pour-les-d%C3%A9veloppeurs-en-vous-inscrivant-ci-dessous",
  "label": "Rejoignez Slack pour les développeurs en vous inscrivant ci-dessous",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Form
    } = _components;
  if (!Form) _missingMdxReference("Form", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Rejoindre la communauté Slack pour les développeurs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slack pour les développeurs est une communauté dédiée aux développeurs de HubSpot qui se réunissent pour un soutien et une collaboration entre développeurs autour de la plateforme HubSpot. Lancée en 2016, la communauté Slack pour les développeurs s'est agrandie et compte aujourd'hui des centaines de développeurs actifs du monde entier, réunis pour partager leurs connaissances et leur expertise."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "À qui s'adresse la communauté Slack pour les développeurs ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Que vous commenciez à développer sur la plateforme HubSpot ou que vous soyez un expert chevronné, la communauté Slack pour les développeurs est faite pour vous. Nous disposons de canaux dédiés à de multiples aspects de notre plateforme : API, développement d'e-mails, développement local à l'aide de notre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "ILC"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modèles"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "thèmes"
      }), " et bien plus encore."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "À quoi dois-je m'attendre une fois que j'aurai rejoint la communauté ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous trouverez une communauté active de développeurs qui se soutiennent et s'enrichissent mutuellement de connaissances. Il s'agit avant tout d'une communauté : plus vous vous y investirez, plus vous en tirerez profit. Cette communauté est mondiale, mais la majorité de ses membres se trouvent dans l'hémisphère occidental, ce qui signifie que si vous vivez dans l'hémisphère oriental, vous risquez de ne pas recevoir de réponses rapides. Cela ne signifie pas que l'on vous ignore ou que l'on ne voit pas vos messages, c'est probablement que les gens dorment. Le moyen de changer cela est de faire partie des personnes de votre fuseau horaire qui sont actives et qui aident les autres. Ainsi, la communauté se développera et vous sera plus utile. Chez HubSpot, nous sommes très attachés à cette communauté et nous en faisons partie. Traitez les autres avec respect, traitez les HubSpotters avec respect. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/slack/code-of-conduct",
        children: "Consultez le Code de conduite de notre communauté Slack"
      }), ". Grandissons, tous ensemble."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rejoignez Slack pour les développeurs en vous inscrivant ci-dessous"
    }), "\n", ' ', "\n", (0, _jsxRuntime.jsx)(Form, {
      formId: "7fd756b1-d981-4b81-9caf-8a79a25bffb2",
      responseType: "inline",
      message: `<br/><button target="_blank" href="https://cta-redirect.hubspot.com/cta/redirect/53/18a2bcd4-227f-46b9-b7e4-52dba8955ae5">Enter the Slack community</button>`
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}