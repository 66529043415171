"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 163738998606;
const slug = exports.slug = 'reference/ui-components/standard-components/toggle';
const title = exports.title = 'Toggle';
const name = exports.name = 'Toggle | UI components';
const metaDescription = exports.metaDescription = 'Learn about the Toggle component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variants",
  "label": "Variants",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tag
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Toggle | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Toggle"
      }), " component renders a boolean toggle switch that can be configured with sizing, label position, read-only, and more."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/toggle-example.png",
        alt: "toggle-example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Toggle } from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  return (\n    <Toggle\n      size=\"md\"\n      label=\"My toggle\"\n      labelDisplay=\"top\"\n      initialIsChecked={true}\n    />\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The input's unique identifier."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The text that displays above the input."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "size"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'xs'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'extra-small'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'sm'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'small'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'md'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'medium'"
            }), " (default)"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The size of the toggle. Only ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'md'"
            }), " / ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'medium'"
            }), " sized toggles can display text on the toggle (ON, OFF). All other sizes will hide checked/unchecked text."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "labelDisplay"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'inline'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'top'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'hidden'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The display option for the toggle label."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checked"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the toggle is selected. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "initialIsChecked"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", the toggle will be selected by default. Sets the default ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "checked"
            }), " state when the component is uncontrolled."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "textChecked"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The text that displays on the toggle when checked. Default is ON. Extra small and small toggles will not display any text."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "textUnchecked"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The text that displays on the toggle when not checked. Default is ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "OFF"
            }), ". Extra small and small toggles will not display any text."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "readonly"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", users will not be able to select the toggle. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onChange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(checked: boolean) => void"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A function that is invoked when the toggle is clicked."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "labelDisplay"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "size"
      }), " props, you can customize toggle appearance."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "labelDisplay"
          }), ": set to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'inline'"
          }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'top'"
          }), " to configure the label position, or set to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'hidden'"
          }), " to hide the label."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/toggledisplay-examples.png",
            alt: "toggledisplay-examples"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "size"
          }), ": by default, toggles are set to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'medium'"
          }), ". Shrink the toggle size by setting this prop to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'xs'"
          }), "/", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'extra-small'"
          }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'sm'"
          }), "/", (0, _jsxRuntime.jsx)(_components.code, {
            children: "'small'"
          }), ". Note that only medium toggles will display ON/OFF status text."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/toggle-size-exaples.png",
            alt: "toggle-size-exaples"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/tag",
          children: "Tag"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/toggle-group",
          children: "ToggleGroup"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/progress-bar",
          children: "ProgressBar"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}