"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125505;
const slug = exports.slug = 'reference/cms/hubl/loops';
const title = exports.title = 'Boucles for';
const name = exports.name = 'EMEA FR For loops';
const metaDescription = exports.metaDescription = "Les boucles for peuvent être utilisées dans HubL pour itérer dans des séquences d'objets. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "propri%C3%A9t%C3%A9s-de-boucle",
  "label": "Propriétés de boucle",
  "parent": null
}, {
  "depth": 0,
  "id": "boucles-imbriqu%C3%A9es",
  "label": "Boucles imbriquées",
  "parent": null
}, {
  "depth": 0,
  "id": "cycle",
  "label": "cycle",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-dans-les-boucles",
  "label": "Variables dans les boucles",
  "parent": null
}, {
  "depth": 0,
  "id": "paires-cl%C3%A9%2C-valeur-dans-les-boucles",
  "label": "Paires (clé, valeur) dans les boucles",
  "parent": null
}, {
  "depth": 0,
  "id": "it%C3%A9rer-un-nombre-d%C3%A9fini-de-fois",
  "label": "Itérer un nombre défini de fois",
  "parent": null
}, {
  "depth": 0,
  "id": "utilisation-des-balises-hubl-dans-des-boucles",
  "label": "Utilisation des balises HubL dans des boucles",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      pre: "pre",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Boucles for"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les boucles for peuvent être utilisées dans HubL pour itérer dans des séquences d'objets. Elles seront le plus souvent utilisées pour rendre le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "contenu du blog"
      }), " dans un format de liste, mais elles peuvent également être utilisées pour trier d'autres variables de séquence."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les boucles for commencent par une fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% for %}"
      }), " et se terminent par une fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% endfor %}"
      }), ". Dans la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% for %}"
      }), ", un seul élément de séquence est nommé, suivi de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "in"
      }), ", puis du nom de la séquence. Le code entre les fonctions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "for"
      }), " d'ouverture et de fermeture est imprimé à chaque itération, et comprend généralement la variable imprimée de l'élément de séquence individuel. Vous trouverez ci-dessous la syntaxe de base d'une boucle for :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for item in items %}\n\t{{ item }}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous un exemple de base qui montre comment imprimer une séquence de valeurs de variable dans une liste."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL et HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set languages = [\"HTML\", \"CSS\", \"Javascript\", \"Python\", \"Ruby\", \"PHP\", \"Java\"] %}\n\n<h1>Languages</h1>;\n<ul>\n    {% for language in languages %}\n    <li>{{ language }}</li>\n    {% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h1>Languages</h1>\n<ul>\n  <li>HTML</li>\n  <li>CSS</li>\n  <li>Javascript</li>\n  <li>Python</li>\n  <li>Ruby</li>\n  <li>PHP</li>\n  <li>Java</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propriétés de boucle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'une boucle est itérative, vous pouvez utiliser la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "logique conditionnelle"
      }), " pour définir son comportement. La propriété de variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loop.index"
      }), " tient un compte du nombre actuel d'itérations de la boucle. Il existe plusieurs autres propriétés de variable de boucle qui comptabilisent les itérations de différentes manières. Ces propriétés sont décrites ci-dessous :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.cycle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une fonction d'aide pour faire un cycle entre une liste de séquences. Consultez l'explication ci-dessous."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.depth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique à quelle profondeur d'une boucle récursive se trouve actuellement le rendu. Commence au niveau 1."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.depth0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique à quelle profondeur d'une boucle récursive se trouve actuellement le rendu. Commence au niveau 0."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.first"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cette variable est définie sur true s'il s'agit de la première itération de la boucle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.index"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'itération actuelle de la boucle. Cette variable commence la comptabilisation à 1."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.index0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'itération actuelle de la boucle. Cette variable commence la comptabilisation à 0."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.last"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cette variable est définie sur true s'il s'agit de la dernière itération de la boucle."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.length"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre d'éléments dans la séquence."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.revindex"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre d'itérations à partir de la fin de la boucle. Compte à rebours jusqu'à 1."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.revindex0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre d'itérations à partir de la fin de la boucle. Compte à rebours jusqu'à 0."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous trouverez ci-dessous quelques exemples qui utilisent différentes variables de boucle. L'exemple de base suivant utilise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loop.index"
      }), " pour conserver un compte qui est imprimé à chaque itération."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL et HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set loopy = [\"Content\", \"Social\", \"Contacts\", \"Reports\"] %}\n{% for app in loopy %}\n    {{ loop.index }}. {{app}} <br>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1. Content <br />\n2. Social <br />\n3. Contacts <br />\n4. Reports <br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'exemple suivant utilise une logique conditionnelle pour vérifier si la longueur de la boucle est ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "divisible par"
      }), " certains nombres. La largeur de la division est alors restituée en conséquence. L'exemple utilise la boucle standard des articles de blog et suppose qu'il y a 6 articles dans la boucle."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL et HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n{% if loop.length is divisibleby 4 %}\n<div style=\"width:25%\">Post content</div>\n\n{% elif loop.length is divisibleby 3 %}\n<div style=\"width:33.33332%\">Post content</div>\n\n{% else %}\n<div style=\"width:50%\">Post content</div>\n\n{% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Boucles imbriquées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les boucles peuvent également être imbriquées dans d'autres boucles. La boucle for enfant sera exécutée à chaque itération de la boucle for parent. Dans l'exemple ci-dessous, une liste d'éléments enfants est imprimée dans un élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<ul>"
      }), " imbriqué dans un élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<ul>"
      }), " d'éléments parents."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL et HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set parents = [\"Parent item 1\", \"Parent item 2\", \"Parent item 3\"] %}\n{% set children = [\"Child item 1\", \"Child item 2\", \"Child item 3\"] %}\n<ul>\n{% for parent in parents %}\n<li>{{parent}}<ul>\n    {% for child in children %}\n    <li>{{child}}</li>\n    {% endfor %}\n    </ul>\n</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>\n    Parent item 1\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n\n  <li>\n    Parent item 2\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n\n  <li>\n    Parent item 3\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "cycle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La balise cycle peut être utilisée dans une boucle for pour parcourir une série de valeurs de chaîne et les imprimer à chaque itération. L'une des applications les plus pratiques de cette technique consiste à appliquer des classes alternées à vos articles de blog dans une liste. Cette balise peut être utilisée sur plus de deux valeurs et répétera le cycle s'il y a plus d'itérations de boucle que de valeurs de cycle. Dans l'exemple ci-dessous, une classe d'éléments ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "odd"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "even"
      }), " est appliquée aux articles d'une liste (l'exemple suppose qu'il y a 5 articles dans la boucle)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veuillez noter qu'il n'y a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "pas d'espace"
      }), " entre les valeurs des chaînes de cycle séparées par des virgules."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL et HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n    <div class=\"post-item {% cycle \"odd\",\"even\" %}\">Blog post content</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie HTML rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd\">Blog post content</div>\n<div class=\"post-item even\">Blog post content</div>\n<div class=\"post-item odd\">Blog post content</div>\n<div class=\"post-item even\">Blog post content</div>\n<div class=\"post-item odd\">Blog post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables dans les boucles"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Toutes les variables définies dans les boucles sont limitées à la portée de cette boucle et ne peuvent pas être exécutées depuis l'extérieur de la boucle."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez exécuter des variables définies en dehors d'une boucle, à partir d'une boucle, mais pas l'inverse."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paires (clé, valeur) dans les boucles"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si le dictionnaire d'informations que vous parcourez en boucle comporte des paires (clé, valeur), une simple boucle for n'aurait accès qu'aux valeurs. Si vous souhaitez avoir accès à la fois aux clés et aux valeurs dans la boucle for, le HubL sera formaté comme suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrée",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set dict_var = {\"name\": \"Cool Product\", \"price\": \"$20\", \"size\":\"XL\"} %}\n{% for key, val in dict_var.items() %}\n    {{ key }}: {{ val }}<br>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "name: Cool Product <br />\nprice: $20 <br />\nsize: XL <br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Itérer un nombre défini de fois"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Parfois, vous souhaitez itérer un nombre défini de fois, ce qui peut être utile pour générer du HTML ou des CSS. Pour ce faire, vous pouvez utiliser la fonction de plage."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL et HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for x in range(0,5) %}\n\t{{loop.index}}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie rendue",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1 2 3 4 5\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisation des balises HubL dans des boucles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous ajoutez une balise à une page, HubSpot attribue automatiquement un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " au HTML d'encadrement. Cette balise est unique par nom de balise. Si vous devez utiliser une balise dans une boucle for, la définition de noms uniques n'est pas pratique. Ajoutez le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unique_in_loop"
      }), " à votre balise pour générer des identifiants uniques. Ce paramètre ajoute le nom du module au numéro d'itération de la boucle en cours, ce qui garantit qu'il est unique. Les identifiants uniques ne sont pas seulement nécessaires à la validité du code HTML. Ils sont également importants pour l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "accessibilité"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for item in module.icon_field %}\n\t{% icon\n\t\tname=\"{{ item.name }}\",\n\t\tstyle=\"{{ item.type }}\",\n\t\tunicode=\"{{ item.unicode }}\",\n        unique_in_loop=True\n\t%}\n{% endfor %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}