"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79935313498;
const slug = exports.slug = 'guides/cms/marketplace/template-guidelines';
const title = exports.title = 'Pautas del Mercado de plantillas de HubSpot';
const name = exports.name = 'LATAM (ES) Marketplace Requirements Overview';
const metaDescription = exports.metaDescription = 'El mercado de plantillas de HubSpot ayuda a miles de clientes sin acceso directo a un desarrollador a aumentar su presencia en línea. Está impulsado por desarrolladores, como tú, que crean temas, plantillas y módulos magníficos y fáciles de usar.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%C2%BFpor-qu%C3%A9-debo-vender-plantillas-en-el-mercado%3F",
  "label": "¿Por qué debo vender plantillas en el mercado?",
  "parent": null
}, {
  "depth": 0,
  "id": "resumen-y-requisitos",
  "label": "Resumen y requisitos",
  "parent": null
}, {
  "depth": 0,
  "id": "anunciar-y-actualizar-temas-en-el-mercado-de-plantillas",
  "label": "Anunciar y actualizar temas en el Mercado de plantillas",
  "parent": null
}, {
  "depth": 0,
  "id": "pasarelas-de-pago-admitidas",
  "label": "Pasarelas de pago admitidas",
  "parent": null
}, {
  "depth": 0,
  "id": "solicitudes-de-reembolso",
  "label": "Solicitudes de reembolso",
  "parent": null
}, {
  "depth": 0,
  "id": "administrar-tus-transacciones",
  "label": "Administrar tus transacciones",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminaci%C3%B3n-de-anuncios-de-plantillas",
  "label": "Eliminación de anuncios de plantillas",
  "parent": null
}, {
  "depth": 0,
  "id": "preguntas-frecuentes",
  "label": "Preguntas frecuentes",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      em: "em",
      br: "br",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ContentCardsRow,
      ContentCard,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ContentCard) _missingMdxReference("ContentCard", true);
  if (!ContentCardsRow) _missingMdxReference("ContentCardsRow", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Pautas del Mercado de plantillas de HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "mercado de plantillas de HubSpot"
      }), " ayuda a miles de clientes sin acceso directo a un desarrollador a aumentar su presencia en línea. Está impulsado por desarrolladores que crean plantillas fáciles de usar para los clientes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes vender dos tipos de plantillas en el Mercado de plantillas de HubSpot: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/website",
        children: "temas"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/modules",
        children: "módulos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Por qué debo vender plantillas en el mercado?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El mercado de materiales de HubSpot tiene mucho que ofrecer a los desarrolladores o agencias que buscan crear un nuevo flujo de ingresos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "No exclusivo:"
        }), " Has trabajado duro en la creación de tus materiales. No deberías estar limitado solo a tener tu plantilla en nuestro mercado y en ningún otro lugar. También eres libre de utilizar tus plantillas fuera del mercado para tus propios proyectos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Construir una vez, vender varias veces:"
        }), " Una vez que tu plantilla aparece en el mercado, se convierte en una oportunidad que se multiplica. Simplemente desarrollas tu plantilla una vez y se puede revender a varios clientes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hacer crecer tu negocio:"
        }), " Muchos de los desarrolladores que venden sus plantillas (tanto gratuitas como de pago) en nuestro mercado han visto crecer sus negocios tanto a partir de los ingresos del mercado como de los servicios adicionales solicitados por los compradores."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Únete a una comunidad de creadores y muestra tus habilidades:"
        }), " conéctate con un ecosistema creciente y solidario para ayudar a dar forma al futuro de HubSpot Template Marketplace y, al mismo tiempo, brindar más visibilidad a tus ofertas y servicios."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Resumen y requisitos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para comenzar a vender en el Mercado de plantillas, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "crea una cuenta de proveedor de Mercado de plantillas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cualquier envío de plantillas debe cumplir con los requisitos de cumplimiento, diseño y calidad del código. Cada tipo de plantilla también tiene sus propios requisitos que deben cumplirse para que se anuncie en el mercado. Puedes consultar estos requisitos a continuación:"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " antes de comenzar a crear plantillas para el mercado, HubSpot recomienda encarecidamente revisar las pautas de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/template-providers",
          children: "Generar para el Mercado de plantillas de HubSpot"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/themes/branding-guidelines",
          children: "Marca"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/tm-usage-guidelines",
          children: "Uso de marcas comerciales"
        }), " y Uso de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/content-usage-guidelines",
          children: "contenido"
        }), ". Las plantillas que infrinjan estas pautas se eliminarán inmediatamente del mercado como se describe en los términos adicionales de HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(ContentCardsRow, {
      children: [(0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Requisitos de los temas"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Un conjunto portátil y contenido de plantillas, módulos, contenido global y configuración de estilo diseñados para trabajar juntos y permitir una experiencia de edición de contenido amigable para el vendedor."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "en-us/guides/cms/marketplace/theme-requirements",
            children: "Ver requisitos de los temas"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Requisitos de los módulos"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Los temas enviados contendrán módulos, que son componentes reutilizables que se pueden usar en plantillas o agregarse a páginas por medio de áreas de arrastrar y soltar y columnas flexibles. También puedes enviar módulos independientes. Estos son módulos que se pueden agregar a cualquier tema en el editor de contenido de HubSpot."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "en-us/guides/cms/marketplace/module-requirements",
            children: "Ver requisitos de los módulos"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Requisitos de la página del anuncio"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Requisitos que se deben cumplir al enviar las plantillas y las páginas de sus anuncios a HubSpot Template Marketplace. Estas normas se aplican a todas las plantillas materiales en el mercado."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/marketplace/general-requirements",
            children: "Consulta los requisitos de la página del anuncio"
          })
        })]
      }), (0, _jsxRuntime.jsxs)(ContentCard, {
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Políticas de mercado de plantillas"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Requisitos y detalles de cumplimiento para las plantillas de anuncios en Template Marketplace."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/marketplace/template-policies",
            children: "Ver las políticas del mercado de plantillas"
          })
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anunciar y actualizar temas en el Mercado de plantillas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que hayas creado tu plantilla, envíala para su aprobación para que aparezca en el Mercado de plantillas de HubSpot. Una vez enviadas, las plantillas se someterán a un proceso de revisión y aprobación por parte del equipo de Mercado de plantillas de HubSpot. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "Aprende cómo anunciar y actualizar tus plantillas en el Mercado de plantillas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pasarelas de pago admitidas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Actualmente, HubSpot ofrece a los proveedores del mercado la posibilidad de conectar las siguientes pasarelas de pago para recibir pagos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["PayPal (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.paypal.com/us/webapps/mpp/country-worldwide",
          children: "países admitidos"
        }), " | ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.paypal.com/us/smarthelp/article/what-are-the-cross-border-fees-when-selling-international-faq2927",
          children: "pagos transfronterizos"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Stripe (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/global",
          children: "países admitidos"
        }), " | ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/docs/connect/cross-border-payouts",
          children: "pagos transfronterizos"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los pagos de los clientes se reciben a través del pago conectado en tu cuenta de proveedor de Marketplace:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mercado"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Información del proveedor"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Método de pago"
        }), " puedes ver o actualizar la información de pago."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot no es responsable de los problemas del portal de pago que pueden ocurrir durante el proceso de compra. HubSpot no toma una parte de ninguna transacción y todas la transacciones se manejan independientemente de la plataforma de HubSpot. Si tu método de pago deja de funcionar por cualquier motivo, es posible que tus materiales se eliminen temporalmente para evitar una experiencia negativa para el cliente."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " HubSpot tiene clientes en todo el mundo. Al configurar tus pasarelas de pago, te recomendamos que aceptes pagos en monedas internacionales (pagos transfronterizos)."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["HubSpot recomienda a los proveedores del Mercado de plantillas que utilicen el portal de pago Stripe si está disponible en su país. Si Stripe no está ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://stripe.com/global",
          children: "disponible en tu país"
        }), ", recomendamos usar el portal de pago de PayPal."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Solicitudes de reembolso"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Podrías encontrarte con la solicitud ocasional de reembolso por parte de un comprador. HubSpot recomienda establecer claramente la política de reembolso en una descripción del anuncio y proporcionar un enlace a la documentación sobre la política de reembolso. HubSpot anima a los proveedores a respetar las solicitudes de reembolso válidas. Más información sobre las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/resources/marketplace-refund-guidelines",
        children: "pautas generales para solicitudes de reembolso"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Administrar tus transacciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se crea un registro de negocio en tu cuenta de proveedor del Mercado de plantillas para cada plantilla que descarga un cliente. Todos los negocios se completarán en una cartera de negocios llamada ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Transacciones del mercado"
      }), " con la etapa del negocio ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Transacciones"
      }), ". Cuando se emite un reembolso por una transacción, la propiedad ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Reembolsado en"
      }), " se establecerá en el registro del negocio."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los proveedores pueden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "personalizar la pipeline de negocios y las etapas del negocio,"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows/choose-your-workflow-actions#property-management",
        children: "usar las propiedades del negocio en los workflows"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminación de anuncios de plantillas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot se reserva el derecho de eliminar anuncios del mercado por razones que incluyen, pero no se limitan a:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Errores presentes en la plantilla."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Plantillas de terceros en la pausa de envío."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La plantilla es incompatible con los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/resources/which-browsers-are-supported-by-hubspot",
          children: "navegadores admitidos por HubSpot."
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Uso de material con derechos de autor, como imágenes, iconos, fuentes, audio y video, que no estén autorizados para la distribución."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Plantillas que hacen seguimiento a usuarios sin su consentimiento informado y de inclusión voluntaria explícita."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Plantillas (incluido el enlace al contenido del Mercado de plantillas o de su demostración de plantillas) que incluyen:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Promoción de o interacción con actividades ilegales."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Contenido despectivo, despreciativo y/o malintencionado."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Reclamaciones deshonestas o falsas."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Contenido ofensivo sobre la raza, la religión, la edad, el sexo o la orientación sexual."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Los módulos se eliminarán si el módulo afecta a otros elementos en la página/el correo electrónico a los que se agrega."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Preguntas frecuentes"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Does the European Union P2B regulation (July 12,2020) apply to the App and Template Marketplaces?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No, the most recent European Union P2B regulation that took effect on July 12, 2020, does not apply to the HubSpot App and Template Marketplaces. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ec.europa.eu/digital-single-market/en/business-business-trading-practices",
          children: "Learn More"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Is there a specific HubSpot account type I need to submit to the Marketplace?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["A ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://offers.hubspot.com/free-marketplace-account",
          children: "Marketplace provider account"
        }), " is needed to submit to the Template Marketplace."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Some partners manage their Marketplace listings in their Partner account to use the templates in the same account. Other partners manage their listings in a separate Marketplace account to keep the templates separate from other templates in their design manager tool."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Do products published in the Marketplace live in my account or in the HubSpot Marketplace system?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Products submitted to or published in the Marketplace live in the HubSpot Marketplace system. This ensures that providers can continue to edit them in their own account without the changes affecting customers’ purchases. To update a Marketplace listing, a provider must resubmit the listing so it can be reviewed and republished."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Customers who have purchased previous versions of a product in a listing can ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/articles/kcs_article/resources/marketplace-customers-frequently-asked-questions#update",
          children: "update to the latest version"
        }), " free of charge."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How do I update my company name?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Once you have listed a template in the marketplace, you will need to contact ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://help.hubspot.com/",
          children: "HubSpot Support"
        }), "  to change your company name."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "Why don't I see all of my products when trying to create a listing?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Possible reasons you may not see a product when trying to create a listing include:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template is currently listed in the Marketplace or contains elements from a currently listed template."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template isn't supported in the Marketplace (transactional emails, ticket emails, etc.)"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "The template is a clone of a template from the Marketplace."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "What should I use as a preview link?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["When creating preview URLs you must use your own domain name if available. If you don't have a domain available to use, HubSpot provides each account with a system domain in the following URL structure:", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[AccountID].hs-sites.com"
        })]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": HubSpot does not support template previews that use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "preview.hs-sites.com"
        }), " subdomain."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Can a single template be used in multiple themes?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Yes, a single template can be used in multiple themes. A common example of when a template may be used in multiple themes is if a provider offers a Starter and Premium version of the same theme."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Do I have to resubmit a theme or module if I make changes to it?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Yes. All changes to a theme or module must be re-submitted to the Marketplace for pre-validation and approval by the quality team. Once the changes are approved, the listing and template will be updated in the Marketplace for customers."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How do I adjust the price of a theme or module?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "To adjust the price of a submitted product, you must resubmit it to the Marketplace with updated information. Prices cannot be changed from free to paid, or vice versa once a template is published."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "How do I receive Marketplace payments?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Payments are received through the payment method that you've connected in your Marketplace Provider Profile. In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Provider Info"
        }), ". In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Payment Method"
        }), " section, view or update your payment information."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot doesn’t take a cut of any transactions, and all transactions are handled independently of the HubSpot platform."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Where do I go to get peer-to-peer feedback or connect with other Marketplace providers? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Join the #marketplace channel in HubSpot's Developer Slack by signing up at ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "https://developers.hubspot.com/slack"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "When a customer requests a refund, do their downloaded files remain in their HubSpot account?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "All files associated with the template as well as any dependencies (e.g. pages created with the template, cloned templates, and modules) will be removed once the refund is initiated."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "How can I stay informed on the latest developer requirements and changes? ",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To stay informed of the latest changes, visit and subscribe to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "HubSpot Developer Changelog"
        }), "."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}