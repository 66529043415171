"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358688;
const slug = exports.slug = 'guides/api/marketing/forms';
const title = exports.title = 'マーケティングAPI | フォーム ';
const name = exports.name = 'vNext Docs DP - フォーム';
const metaDescription = exports.metaDescription = 'このページで紹介しているエンドポイントを使うと、新しいフォームの作成やフォームの管理が可能です。';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "フォーム"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotフォームを使用して、訪問者やコンタクトに関する情報を集めます。このページでご紹介しているエンドポイントを使い、新しいフォームを設定したり、以前に作成したフォームの詳細を取得したりできます。また、HubSpotへフォーム送信データを送る場合は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/marketing/forms/v3-legacy#submit-data-to-a-form-supporting-authentication",
        children: "フォームへのデータ送信エンドポイント"
      }), "（英語）をご参照ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["タイプはフォームの目的を示し、既定では", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot"
      }), "に設定されます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "formType"
      }), "には次の値を使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot"
        }), "：さまざまなフィールドタイプとスタイリングオプションがあり、HubSpotページまたは外部ページのいずれに埋め込んでも使用できます。このタイプのフォームは、本ページで紹介しているエンドポイントで作成および編集可能です。またHubSpotアカウント内で作成することもできます。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/create-forms",
          children: "HubSpotフォームの作成方法"
        }), "をご覧ください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "captured"
        }), "：外部ウェブサイトのHTMLフォームに対応しています。HubSpot以外のフォームを使用していて、［ウェブサイトフォームからデータを収集］機能がオンになっている場合、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code",
          children: "トラッキングされたページ"
        }), "でフォーム送信があるとHubSpotでフォームが自動的に作成されます。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/use-non-hubspot-forms",
          children: "HubSpot以外のフォームを使用する方法"
        }), "をご覧ください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "flow"
        }), "：HubSpotページまたは外部ページのいずれでも使用できるポップアップフォームです。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/create-pop-up-forms",
          children: "HubSpotのポップアップフォームの作成方法"
        }), "をご参照ください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_comment"
        }), "：これらのフォームは、HubSpotブログページでブログ記事へのコメントを収集する目的で自動的に作成されます。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/blog/set-up-and-moderate-your-blog-comments",
          children: "ブログのコメントの設定と管理"
        }), "をご覧ください。"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}