"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937497;
const slug = exports.slug = 'guides/api/crm/objects/products';
const title = exports.title = 'API du CRM | Produits';
const name = exports.name = 'vNext Docs DP - Produits';
const metaDescription = exports.metaDescription = 'Dans HubSpot, les produits représentent les biens ou les services que vous vendez. Les points de terminaison des produits vous permettent de gérer et de synchroniser ces données.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-un-produit",
  "label": "Créer un produit",
  "parent": null
}, {
  "depth": 0,
  "id": "associer-des-produits",
  "label": "Associer des produits",
  "parent": null
}, {
  "depth": 1,
  "id": "cr%C3%A9er-et-associer-une-ligne-de-produit-appels-multiples",
  "label": "Créer et associer une ligne de produit (appels multiples)",
  "parent": "associer-des-produits"
}, {
  "depth": 1,
  "id": "cr%C3%A9er-et-associer-une-ligne-de-produit-appel-unique",
  "label": "Créer et associer une ligne de produit (appel unique)",
  "parent": "associer-des-produits"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Produits"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans HubSpot, les produits représentent les biens ou les services que vous vendez. La création d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/how-do-i-use-products",
        children: "bibliothèque de produits"
      }), " vous permet d'ajouter rapidement votre produit aux transactions et de générer des devis ainsi que des rapports sur les performances du produit. Les points de terminaison des produits vous permettent de gérer ces données et de les synchroniser entre HubSpot et d'autres systèmes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les produits ainsi que les entreprises, les contacts, les transactions, les lignes de produit et les devis sont des objets dans le HubSpot CRM. Découvrez-en davantage sur les propriétés d'objet, les associations, les relations et bien plus encore dans le guide ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Comprendre les objets de CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de cas d'utilisation :"
      }), " afin que les commerciaux puissent facilement ajouter des biens et des services aux offres, devis, etc., utilisez l'API des produits pour importer votre catalogue de produits dans HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un produit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un produit, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/products"
      }), ". Dans le corps de la demande, incluez un objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " contenant les propriétés du produit que vous souhaitez définir lors de la création. Vous pouvez ensuite mettre à jour les propriétés d'un produit via une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " vers le même point de terminaison."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour voir toutes les propriétés de produit disponibles, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à l'API ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "Propriétés"
      }), ". Pour récupérer les propriétés du produit, l'URL de la demande sera ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/products"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to crm/v3/objects/products\n{\n  \"properties\": {\n    \"name\": \"Implementation Service\",\n    \"price\": \"6000.00\",\n    \"hs_sku\": \"123456\",\n    \"description\": \"Onboarding service for data product\",\n    \"hs_cost_of_goods_sold\": \"600.00\",\n    \"hs_recurring_billing_period\": \"P12M\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Notez que la valeur de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_recurring_billing_period"
        }), " est formatée comme ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "P#M"
        }), ", où # est le nombre de mois."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associer des produits"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les produits eux-mêmes ne peuvent pas être associés à d'autres objets de CRM. Cependant, pour associer les informations d'un produit à une transaction ou à un devis, vous pouvez créer une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/line-items",
        children: "ligne de produit"
      }), " en fonction de ce produit. Les lignes de produit sont des instances individuelles de produits, et sont un objet distinct des produits afin que vous puissiez personnaliser les biens et services sur une transaction ou un devis sans avoir besoin de mettre à jour le produit lui-même."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, si vous préparez une transaction où l'un de vos produits est vendu, vous devez d'abord créer une ligne de produit à partir du produit, puis l'associer à la transaction. Vous pouvez le faire soit avec deux appels distincts, soit avec un appel qui crée et associe la ligne de produit. Les deux options sont présentées ci-dessous."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " les lignes de produit appartiennent à un seul objet parent. Si vous associez des objets, les lignes de produit doivent être individuelles à chaque objet. Par exemple, si vous créez une transaction et un devis, vous devez créer un ensemble de lignes de produit pour la transaction et un autre ensemble pour le devis. Cela aidera à rationaliser les données CRM entre les objets et à prévenir la perte de données inattendue lorsque vous devez modifier des lignes de produit. Par exemple, la suppression d'un devis supprimera également les lignes de produit du devis. Si ces lignes de produit sont également associées à une transaction, les lignes de produit de la transaction seront également supprimées."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer et associer une ligne de produit (appels multiples)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tout d'abord, vous allez créer une ligne de produit basée sur un produit avec l'ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234567"
      }), ". Pour obtenir une liste complète des propriétés de ligne de produit disponibles, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à l'API ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "Propriétés"
      }), ". L'URL pour les lignes de produit sera ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/properties/line_items"
      }), ". Étant donné que vous créez la ligne de produit à partir d'un produit existant, elle héritera des valeurs de propriété du produit, telles que le prix."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"quantity\": 1,\n    \"hs_product_id\": \"1234567\",\n    \"name\": \"New line item (product-based)\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La réponse renverra un ID de ligne de produit que vous pouvez utiliser pour l'associer à une transaction à l'aide de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API des associations"
      }), ". Pour cet exemple, supposons que l'ID de ligne de produit renvoyé est ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "7791176460"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour associer la ligne de produit à une transaction existante (ID : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "14795354663"
      }), "), vous devez effectuer une demande", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/line_items/7791176460/associations/default/deals/14795354663"
      }), ". Cette demande utilise le type d'association par défaut."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une réponse ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " renverra des informations similaires à celles-ci :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PUT request to crm/v4/objects/line_items/7791176460/associations/default/deals/14795354663\n\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"14795354663\"\n      },\n      \"to\": {\n        \"id\": \"7791176460\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 19\n      }\n    },\n    {\n      \"from\": {\n        \"id\": \"7791176460\"\n      },\n      \"to\": {\n        \"id\": \"14795354663\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 20\n      }\n    }\n  ],\n  \"startedAt\": \"2023-12-21T20:06:52.083Z\",\n  \"completedAt\": \"2023-12-21T20:06:52.192Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans HubSpot, la fiche d'informations de transaction affichera la ligne de produit dans la carte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Lignes de produit"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deal-record-line-item-association.png",
        alt: "deal-record-line-item-association"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer et associer une ligne de produit (appel unique)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer une ligne de produit à partir d'un produit existant et l'associer à une transaction à l'aide d'un seul appel, vous pouvez inclure un tableau ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " dans la demande de création de ligne de produit."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer la ligne de produit, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/line_item"
      }), ". Le corps de votre demande ressemblera à ce qui suit. Notez que le ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " pour l'association ligne de produit-transaction est ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "20"
      }), ". Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations#association-type-id-values",
        children: "types d'association entre les différents types de fiche d'informations de CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"quantity\": 1,\n    \"hs_product_id\": \"1234567\",\n    \"name\": \"New line item (product-based)\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": \"14795354663\"\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 20\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une réponse ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " renverra des détails sur la nouvelle ligne de produit. Dans HubSpot, la fiche d'informations de transaction affichera la ligne de produit dans la carte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Lignes de produit"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deal-record-line-item-association.png",
        alt: "deal-record-line-item-association"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}