"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 59619794102;
const slug = exports.slug = 'guides/api/crm/engagements/calls';
const title = exports.title = 'Engajamentos | Chamadas';
const name = exports.name = 'Engajamentos | Chamadas';
const metaDescription = exports.metaDescription = 'Use a API de envolvimentos de chamadas para registrar e gerenciar chamadas em registros do CRM. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-envolvimento-de-chamada",
  "label": "Criar envolvimento de chamada",
  "parent": null
}, {
  "depth": 1,
  "id": "propriedades",
  "label": "Propriedades",
  "parent": "criar-envolvimento-de-chamada"
}, {
  "depth": 1,
  "id": "associa%C3%A7%C3%B5es",
  "label": "Associações",
  "parent": "criar-envolvimento-de-chamada"
}, {
  "depth": 0,
  "id": "recuperar-chamadas",
  "label": "Recuperar chamadas",
  "parent": null
}, {
  "depth": 0,
  "id": "diferenciar-mensagens-de-voz-e-chamadas-gravadas",
  "label": "Diferenciar mensagens de voz e chamadas gravadas",
  "parent": null
}, {
  "depth": 0,
  "id": "atualizar-chamadas",
  "label": "Atualizar chamadas",
  "parent": null
}, {
  "depth": 1,
  "id": "associar-chamadas-existentes-a-registros",
  "label": "Associar chamadas existentes a registros",
  "parent": "atualizar-chamadas"
}, {
  "depth": 1,
  "id": "remover-uma-associa%C3%A7%C3%A3o",
  "label": "Remover uma associação",
  "parent": "atualizar-chamadas"
}, {
  "depth": 0,
  "id": "fixar-uma-chamada-em-um-registro",
  "label": "Fixar uma chamada em um registro",
  "parent": null
}, {
  "depth": 0,
  "id": "excluir-chamadas",
  "label": "Excluir chamadas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Chamadas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use a API de envolvimentos de chamadas para registrar e gerenciar chamadas nos registros do CRM e na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "página do índice de chamadas"
      }), ". Você pode registrar chamadas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "no HubSpot"
      }), " ou por meio da API de chamadas. Abaixo, aprenda os métodos básicos de gerenciamento de chamadas por meio da API. Para visualizar todos os pontos de extremidade disponíveis e seus requisitos, clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pontos de extremidade"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar envolvimento de chamada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar um envolvimento de chamada, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No corpo da solicitação, adicione detalhes da chamada em um objeto de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "propriedades"
      }), ". Você também pode adicionar um objeto de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associações"
      }), " para associar sua nova chamada a um registro existente (por exemplo, contatos, empresas)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriedades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja abaixo uma lista de propriedades de chamada padrão do HubSpot que podem ser incluídas no objeto de propriedades. Também é possível criar propriedades personalizadas utilizando a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/api/crm/properties",
        children: "API de propriedades"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obrigatório. Este campo marca o tempo de criação da chamada e determina onde ela fica na linha do tempo do registro. Você pode usar um carimbo de data e hora do Unix em milissegundos ou no formato UTC."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A descrição da chamada, incluindo todas as notas que você deseja adicionar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_callee_object_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ID do registro do HubSpot associado à chamada. Este será o destinatário para chamadas de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), " ou o discador para chamadas de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_callee_object_type_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ID do objeto ao qual o registro associado à chamada pertence (por exemplo, especifica se o registro é um contato ou empresa). Este será o objeto do destinatário para chamadas de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), " ou o objeto do discador para chamadas de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_direction"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A direção da chamada da perspectiva do usuário da HubSpot. Se o usuário for o destinatário da chamada, a direção deve ser definida como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), ". Se o usuário iniciou a chamada, a direção deve ser definida como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_disposition"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O resultado da chamada. Para definir a disposição da chamada, você precisa usar o valor GUID interno. Se sua conta tiver configurado ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/calling/create-custom-call-and-meeting-outcomes",
              children: "resultados de chamadas personalizados"
            }), ", você poderá encontrar seus GUIDs de disposição usando ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/engagements/engagement-details#get-call-engagement-dispositions",
              children: "essa API"
            }), ". Os rótulos de resultado padrão do HubSpot e seus valores internos são:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Ocupado: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "9d9162e7-6cf3-4944-bf63-4dff82258764"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Conectado: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "f240bbac-87c9-4f6e-bf70-924b57d47db7"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Deixou mensagem ativa: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "a4c4c377-d246-4b32-a13b-75a56a4cd0ff"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Deixou mensagem de voz: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "b2cf5968-551e-4856-9783-52b3da59a7d0"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Sem resposta: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "73a0d17f-1163-4015-bdd5-ec830791da20"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Número errado: ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "17b47fee-58de-441e-a44c-c6300d46f273"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_duration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A duração da chamada, em milissegundos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_from_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número de telefone de onde a chamada foi feita."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_recording_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A URL que armazena a gravação de chamadas. As URLS para arquivos .mp3 ou .wav podem ser reproduzidas em registros do CRM. Apenas HTTPS, URLs seguros serão aceitos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O status da chamada. Os status são ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BUSY"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CALLING_CRM_USER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CANCELED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMPLETED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONNECTING"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FAILED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN_PROGRESS"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NO_ANSWER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "QUEUED"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RINGING"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O título da chamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_source"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A origem da chamada. Isso não é obrigatório, mas é necessário para utilizar o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/extensions/calling-extensions/recordings-and-transcriptions#log-a-call-with-your-app-s-endpoint-using-the-engagements-api",
              children: "pipeline de gravação e transcrições"
            }), ". Se a propriedade estiver definida, ela deverá ser definida como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATIONS_PLATFORM"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_to_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número de telefone que recebeu a chamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID do proprietário"
            }), " associado à chamada. Este campo determina o usuário listado como o criador da chamada na linha do tempo do registro."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_activity_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de chamada. As opções se baseiam nos ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/meetings-tool/how-do-i-create-and-use-call-and-meeting-types",
              children: "tipos de chamada definidos na sua conta da HubSpot."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os Ids dos anexos da chamada. Vários IDs de anexo são separados por ponto e vírgula."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associações"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar e associar uma chamada a registros existentes, inclua um objeto de associações na solicitação. Por exemplo, para criar uma chamada e associá-la a um contato e a um ticket, o corpo da solicitação pode ser semelhante ao seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-17T01:32:44.872Z\",\n    \"hs_call_title\": \"Support call\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_call_body\": \"Resolved issue\",\n    \"hs_call_duration\": \"3800\",\n    \"hs_call_from_number\": \"(857) 829 5489\",\n    \"hs_call_to_number\": \"(509) 999 9999\",\n    \"hs_call_recording_url\": \"https://api.twilio.com/2010-04-01/Accounts/AC890b8e6fbe0d989bb9158e26046a8dde/Recordings/RE3079ac919116b2d22\",\n    \"hs_call_status\": \"COMPLETED\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 500\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 194\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 1234\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 220\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No objeto de associações, você deve incluir o seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O registro que deseja associar à chamada, especificado por seu valor de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " exclusivo."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de associação entre a chamada e o registro. Inclua ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Os IDs de tipo de associação padrão são listados ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "aqui"
            }), ", ou você pode recuperar o valor de tipos de associação personalizados (ou seja, rótulos) por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API de associações"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre a criação de chamadas em lote clicando na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pontos de extremidade"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar chamadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode recuperar chamadas individualmente ou em massa. Saiba mais sobre a recuperação em massa clicando na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Pontos de extremidade"
      }), " na parte superior deste artigo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar uma chamada individual pelo seu ID, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), ". Você pode incluir os seguintes parâmetros na URL da solicitação:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma lista separada por vírgulas das ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#call-properties-1",
              children: "propriedades"
            }), " a serem retornadas."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma lista separada por vírgulas de tipos de objeto para recuperar IDs associados. Todas as associações especificadas que não existem não serão retornadas na resposta. Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API de associações."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para solicitar uma lista de todas as chamadas, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls"
      }), ". Você pode incluir os seguintes parâmetros na URL da solicitação:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número máximo de resultados a serem exibidos por página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma lista separada por vírgulas das ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#call-properties-1",
              children: "propriedades"
            }), " a serem retornadas."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao fazer uma solicitação bem-sucedida, a resposta incluirá o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callId"
      }), " que você pode usar para recuperar, atualizar e excluir a chamada."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Diferenciar mensagens de voz e chamadas gravadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para chamadas gravadas e mensagens de voz, uma gravação é armazenada na propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_recording_url"
      }), ". Se sua conta tiver acesso a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/receive-calls-in-hubspot",
        children: "chamada recebida"
      }), ", para diferenciar entre chamadas concluídas e gravadas versus chamadas recebidas com uma mensagem de voz, inclua as seguintes propriedades na solicitação: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_status"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se uma chamada tiver uma mensagem de voz, o valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_status"
      }), " será ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "missed"
      }), " e o valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), " será ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". O valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), " será ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " para uma chamada recebida, onde não foi deixado uma mensagem de voz, ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "null"
      }), " se a chamada tiver um status diferente de perdida."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Atualizar chamadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode atualizar chamadas individualmente ou em massa. Para atualizar uma chamada individual pelo seu ID, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No corpo da solicitação, inclua as propriedades da chamada que deseja atualizar:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example PATCH request to https://api.hubspot.com/crm/v3/objects/calls/{callID}\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-17T01:32:44.872Z\",\n    \"hs_call_title\": \"Discovery call\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_call_body\": \" Decision maker out, will call back tomorrow\",\n    \"hs_call_duration\": \"3800\",\n    \"hs_call_from_number\": \"(857) 829 5489\",\n    \"hs_call_to_number\": \"(509) 999 9999\",\n    \"hs_call_recording_url\": \"https://api.twilio.com/2010-04-01/Accounts/AC890b8e6fbe0d989bb9158e26046a8dde/Recordings/RE3079ac919116b2d22\",\n    \"hs_call_status\": \"COMPLETED\"\n  }\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot ignorará valores para propriedades somente leitura e inexistentes. Para limpar um valor de propriedade, passe uma string vazia para a propriedade no corpo da solicitação."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre a atualização em massa clicando na guia** Pontos de extremidade** na parte superior deste artigo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associar chamadas existentes a registros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para associar uma chamada a registros, como um contato e suas empresas associadas, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". A URL da solicitação contém os campos a seguir:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da chamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O tipo de objeto ao qual você deseja associar a chamada (por exemplo, contato ou empresa)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do registro ao qual você deseja associar a chamada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um identificador exclusivo para indicar o tipo de associação entre a chamada e o outro objeto. O ID pode ser representado numericamente ou em maiúsculas (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "call_to_contact"
            }), "). Você pode recuperar o valor por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "API de associações"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, a URL da sua solicitação pode ser parecida com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/calls/17591596434/associations/contact/104901/194"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remover uma associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para remover uma associação entre uma chamada e um registro, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para o mesmo URL acima:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fixar uma chamada em um registro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "fixar uma chamada"
      }), " em um registro para que permaneça no topo da linha do tempo do registro. A chamada já deve estar associada ao registro antes da fixação e você só pode fixar uma atividade por registo. Para fixar uma chamada, inclua o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " da chamada no campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " ao criar ou atualizar um registro por meio das APIs de objeto. Saiba mais sobre como usar as APIs de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "empresas,"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contatos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "negócios"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objetos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Excluir chamadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode excluir chamadas individualmente ou em massa, o que adicionará a chamada à lixeira no HubSpot. Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "restaurar posteriormente a chamada da linha do tempo do registro"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir uma chamada individual pelo seu ID, faça uma solicitação", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre a exclusão em massa clicando na guia** Pontos de extremidade** na parte superior deste artigo."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}