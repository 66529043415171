"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937497;
const slug = exports.slug = 'guides/api/crm/objects/products';
const title = exports.title = 'API de CRM | Productos';
const name = exports.name = 'vNext Docs DP - Productos';
const metaDescription = exports.metaDescription = 'En HubSpot, los productos representan los bienes o servicios que vendes. Los puntos de terminación de los productos te permiten administrar y sincronizar estos datos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-un-producto",
  "label": "Crear un producto",
  "parent": null
}, {
  "depth": 0,
  "id": "asociar-productos",
  "label": "Asociar productos",
  "parent": null
}, {
  "depth": 1,
  "id": "crear-y-asociar-un-elemento-de-pedido-varias-llamadas",
  "label": "Crear y asociar un elemento de pedido (varias llamadas)",
  "parent": "asociar-productos"
}, {
  "depth": 1,
  "id": "crear-y-asociar-un-elemento-de-pedido-llamada-%C3%BAnica",
  "label": "Crear y asociar un elemento de pedido (llamada única)",
  "parent": "asociar-productos"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Productos"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En HubSpot, los productos representan los bienes o servicios que vendes. Crear una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/deals/how-do-i-use-products",
        children: "biblioteca de productos"
      }), " te permite agregar rápidamente productos a negocios, generar cotizaciones e informar sobre el rendimiento del producto. Los puntos de terminación de los productos te permiten administrar estos datos y sincronizarlos entre HubSpot y otros sistemas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los productos, junto con empresas, contactos, negocios, tickets, elementos de pedido y cotizaciones, son objetos en el CRM de HubSpot. Aprende más sobre las propiedades de los objetos, las asociaciones, las relaciones y más en nuestra guía ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Comprender los objetos de CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ejemplo de caso de uso:"
      }), " para que los representantes de ventas puedan agregar fácilmente bienes y servicios a negocios, cotizaciones y más, usa la API de productos para importar tu catálogo de productos a HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear un producto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear un producto, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/products"
      }), ". En el cuerpo de la solicitud, incluye un objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " que contenga las propiedades de producto que quieras establecer al crear. Más tarde puedes actualizar las propiedades de un producto a través de una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " al mismo punto de terminación."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver todas las propiedades disponibles del producto, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a la API de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "propiedades"
      }), ". Para recuperar las propiedades del producto, la URL de la solicitud será ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/products"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to crm/v3/objects/products\n{\n  \"properties\": {\n    \"name\": \"Implementation Service\",\n    \"price\": \"6000.00\",\n    \"hs_sku\": \"123456\",\n    \"description\": \"Onboarding service for data product\",\n    \"hs_cost_of_goods_sold\": \"600.00\",\n    \"hs_recurring_billing_period\": \"P12M\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Ten en cuenta que el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_recurring_billing_period"
        }), " tiene el formato ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "P#M"
        }), ", donde # es el número de meses."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Asociar productos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los productos en sí no se pueden asociar con otros objetos de CRM. Sin embargo, para asociar la información de un producto con un negocio o cotización, puedes crear un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/line-items",
        children: "elemento de pedido"
      }), " basado en ese producto. Los elementos de pedido son instancias individuales de productos y son un objeto separado de los productos para que puedas adaptar los bienes y servicios en un negocio o cotización según sea necesario sin necesidad de actualizar el producto en sí."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, si estás armando un negocio en el que se vende uno de tus productos, primero crearías un elemento de pedido a partir del producto y luego lo asociarías con el negocio. Puedes hacerlo con dos llamadas separadas o con una llamada que crea y asocia el elemento de pedido. Ambas opciones se muestran a continuación."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " los elementos de pedido pertenecen a un único objeto padre. Si se asocian objetos, los elementos de pedido deben ser individuales para cada objeto. Por ejemplo, si estás creando un negocio y una cotización, debe crear un conjunto de elementos de pedido para el negocio y otro conjunto para la cotización. Esto ayudará a agilizar los datos de CRM en todos los objetos y evitará la pérdida inesperada de datos cuando sea necesario modificar los elementos de pedido. Por ejemplo, eliminar una cotización también eliminará los elementos de pedido de la cotización. Si esos elementos de pedido también están asociados con un negocio, los elementos de pedido del negocio también se eliminarán."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear y asociar un elemento de pedido (varias llamadas)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Primero, crearás un elemento de pedido basado en un producto con el ID de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234567"
      }), ". Para obtener una lista completa de las propiedades disde los elementos de pedido disponibles, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API de propiedades"
      }), ". La URL de los elementos de pedido sería ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/properties/line_items"
      }), ". Debido a que creas el elemento de pedido a partir de un producto existente, heredará los valores de propiedad del producto, como el precio."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"quantity\": 1,\n    \"hs_product_id\": \"1234567\",\n    \"name\": \"New line item (product-based)\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La respuesta devolverá un ID de elemento de pedido que puedes usar para asociarlo con un negocio utilizando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de asociaciones"
      }), ". Para este ejemplo, supongamos que el ID de elemento de pedido devuelto es ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "7791176460"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para asociar el elemento de pedido con un negocio existente (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "14795354663"
      }), "), realizarás una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/line_items/7791176460/associations/default/deals/14795354663"
      }), ". Esta solicitud utiliza el tipo de asociación predeterminado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una respuesta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " devolverá información similar a lo siguiente:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PUT request to crm/v4/objects/line_items/7791176460/associations/default/deals/14795354663\n\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"14795354663\"\n      },\n      \"to\": {\n        \"id\": \"7791176460\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 19\n      }\n    },\n    {\n      \"from\": {\n        \"id\": \"7791176460\"\n      },\n      \"to\": {\n        \"id\": \"14795354663\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 20\n      }\n    }\n  ],\n  \"startedAt\": \"2023-12-21T20:06:52.083Z\",\n  \"completedAt\": \"2023-12-21T20:06:52.192Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En HubSpot, el registro de negocio mostrará el elemento de pedido en la tarjeta ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Elementos de pedido"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deal-record-line-item-association.png",
        alt: "deal-record-line-item-association"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear y asociar un elemento de pedido (llamada única)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear un elemento de pedido a partir de un producto existente y asociarlo con un negocio mediante una sola llamada, puedes incluir una matriz ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " en la solicitud de creación de elementos de pedido."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear el elemento de pedido, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/line_item"
      }), ". El cuerpo de tu solicitud será similar al siguiente. Ten en cuenta que el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " para la asociación elemento de pedido de negocio es ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "20"
      }), ". Aprende más sobre los tipos de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations#association-type-id-values",
        children: "asociación entre diferentes tipos de registros del CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"quantity\": 1,\n    \"hs_product_id\": \"1234567\",\n    \"name\": \"New line item (product-based)\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": \"14795354663\"\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 20\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una respuesta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " devolverá detalles sobre el nuevo elemento de pedido. En HubSpot, el registro de negocio mostrará el elemento de pedido en la tarjeta ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Elementos de pedido"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deal-record-line-item-association.png",
        alt: "deal-record-line-item-association"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}