"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044618;
const slug = exports.slug = 'guides/cms/storage/file-manager';
const title = exports.title = 'Gestionnaire de fichiers';
const name = exports.name = 'EMEA FRANCE (fr) File Manager';
const metaDescription = exports.metaDescription = "Utilisez le gestionnaire de fichiers HubSpot pour héberger des images sur le réseau de diffusion de contenu de CMS Hub et permettre aux créateurs de contenu d'ajouter facilement des fichiers au contenu. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "quand-utiliser-le-gestionnaire-de-fichiers",
  "label": "Quand utiliser le gestionnaire de fichiers",
  "parent": null
}, {
  "depth": 0,
  "id": "t%C3%A9l%C3%A9charger-des-fichiers-dans-le-gestionnaire-de-fichiers",
  "label": "Télécharger des fichiers dans le gestionnaire de fichiers",
  "parent": null
}, {
  "depth": 0,
  "id": "utilisation-des-fichiers-du-gestionnaire",
  "label": "Utilisation des fichiers du gestionnaire",
  "parent": null
}, {
  "depth": 0,
  "id": "optimisations",
  "label": "Optimisations",
  "parent": null
}, {
  "depth": 0,
  "id": "restituer-des-fichiers-html-et-js-%C3%A0-partir-du-gestionnaire",
  "label": "Restituer des fichiers HTML et JS à partir du gestionnaire",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Gestionnaire de fichiers"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En plus du système de fichiers de développeur, le gestionnaire de fichiers HubSpot peut être utilisé pour stocker et restituer des fichiers. Les fichiers dans le gestionnaire de fichiers sont restitués sur le réseau de diffusion de contenu (CDN) mondial de HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par défaut, tous les fichiers chargés dans le gestionnaire de fichiers sont accessibles au public et peuvent être indexés dans les moteurs de recherche. Après avoir téléchargé vos fichiers, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/files/organize-edit-and-delete-files#edit-your-file-s-visibility-settings",
        children: "gérer les paramètres de visibilité de votre fichier"
      }), " pour empêcher l'indexation ou l'accès aux fichiers."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le gestionnaire de fichiers se trouve dans ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/files/",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing > Fichier et modèles > Fichiers"
        })
      }), " dans le menu de navigation supérieur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/image-png-May-24-2023-05-49-31-9738-AM.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Quand utiliser le gestionnaire de fichiers"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le gestionnaire de fichiers est généralement utilisé pour les fichiers destinés à être utilisés dans les sélecteurs de fichiers à travers HubSpot. Par exemple, pour sélectionner une image dans un module d'image ou de texte enrichi."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avant de charger des fichiers à utiliser avec le gestionnaire de fichiers :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Certaines ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/fr/files/supported-file-types#files-tool",
          children: "limites de taille et de type de fichier"
        }), " s'appliquent. Découvrez les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/fr/files/upload-files-to-use-in-your-hubspot-content#before-you-get-started",
          children: "éléments à prendre en compte avant de charger des fichiers"
        }), " dans le gestionnaire de fichiers."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les fichiers téléchargés dans le gestionnaire de fichiers ne peuvent pas être modifiés dans l'application HubSpot, à l'exception de modifications mineures pour des fichiers d'image."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si vous avez l'intention de modifier des fichiers textuels, ces derniers doivent être stockés dans le gestionnaire de conception."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les fichiers de texte téléchargés dans le gestionnaire de fichiers ne seront en aucune manière minimisés ou modifiés. Pour profiter de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview#javascript-minification",
          children: "minimisation JavaScript"
        }), " ainsi que de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "minimisation et de la combinaison CSS"
        }), ", stockez ces fichiers dans le gestionnaire de conception."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Télécharger des fichiers dans le gestionnaire de fichiers"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les fichiers peuvent être chargés dans le gestionnaire de fichiers via les options suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour charger des fichiers directement dans HubSpot, découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/fr/files/upload-files-to-use-in-your-hubspot-content",
          children: "charger des fichiers dans le gestionnaire de fichiers"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour télécharger des fichiers via l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "ILC de CMS,"
        }), " utilisez la commande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload filemanager"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour télécharger des fichiers à l'aide d'une API, découvrez l'", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/api/files/v3#post-%2Ffiles%2Fv3%2Ffiles",
          children: ["API ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Charger un nouveau fichier"
          }), " de HubSpot"]
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Après avoir chargé vos fichiers dans le gestionnaire de fichiers, découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/files/organize-edit-and-delete-files",
        children: "organiser et gérer vos fichiers ainsi que leurs détails"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisation des fichiers du gestionnaire"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les fichiers chargés dans le gestionnaire de fichiers sont accessibles via les options suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les fichiers téléchargés dans le gestionnaire de fichiers sont accessibles dans les différents sélecteurs de fichiers à travers HubSpot et son CMS, comme dans les modules de texte enrichi ou d'image sur les pages."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les fichiers chargés dans le gestionnaire de fichiers sont accessibles via un lien de téléchargement direct. Découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/fr/files/provide-a-direct-download-link-to-a-file-hosted-on-the-files-tool",
          children: "récupérer le lien de téléchargement direct d'un fichier"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Optimisations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fichiers du gestionnaire de fichiers sont automatiquement ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview#browser-and-server-caching",
        children: "mis en cache"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview#image-compression-optimization-and-automatic-image-resizing",
        children: "compressés et redimensionnés pour être restitués efficacement"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview#domain-rewriting",
        children: "accessibles dans tous vos domaines hébergés afin de réduire les demandes d'origines multiples"
      }), ". Découvrez-en davantage sur le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "réseau de diffusion de contenu, la sécurité et la performance"
      }), " de CMS Hub."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Restituer des fichiers HTML et JS à partir du gestionnaire"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les fichiers HTML et JavaScript téléchargés dans le gestionnaire de fichiers et restitués à l'aide d'un domaine HubSpot par défaut (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "f.hubspotusercontentXX.net"
      }), "), utilisez le type de contenu ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "text/plain"
      }), ". Ainsi, les navigateurs ne restitueront et n'évalueront pas le code."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si un utilisateur accède directement à un fichier HTML, le code HTML s'affichera. Pour éviter cela, vous devez restituer ces fichiers depuis l'un de vos domaines connectés plutôt que depuis un domaine par défaut HubSpot."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}