"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 61045964892;
const slug = exports.slug = 'guides/api/crm/engagements/meetings';
const title = exports.title = 'Interaktionen | Meetings';
const name = exports.name = 'Interaktionen | Meetings';
const metaDescription = exports.metaDescription = 'Verwenden Sie die Meetings-Interaktion-API, um Meetings in CRM-Datensätzen zu protokollieren und zu verwalten. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "ein-meeting-erstellen",
  "label": "Ein Meeting erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "eigenschaften",
  "label": "Eigenschaften",
  "parent": "ein-meeting-erstellen"
}, {
  "depth": 1,
  "id": "zuordnungen",
  "label": "Zuordnungen",
  "parent": "ein-meeting-erstellen"
}, {
  "depth": 0,
  "id": "meetings-abrufen",
  "label": "Meetings abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "meetings-aktualisieren",
  "label": "Meetings aktualisieren",
  "parent": null
}, {
  "depth": 1,
  "id": "vorhandene-meetings-zu-datens%C3%A4tzen-zuordnen",
  "label": "Vorhandene Meetings zu Datensätzen zuordnen",
  "parent": "meetings-aktualisieren"
}, {
  "depth": 1,
  "id": "eine-zuordnung-entfernen",
  "label": "Eine Zuordnung entfernen",
  "parent": "meetings-aktualisieren"
}, {
  "depth": 0,
  "id": "ein-meeting-in-einen-datensatz-anpinnen",
  "label": "Ein Meeting in einen Datensatz anpinnen",
  "parent": null
}, {
  "depth": 0,
  "id": "meetings-l%C3%B6schen",
  "label": "Meetings löschen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Meetings"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie die Meetings-Interaktion-API, um Meetings in CRM-Datensätzen zu protokollieren und zu verwalten. Sie können Meeting-Aktivitäten entweder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "in HubSpot"
      }), " oder über die Meetings-API protokollieren. Sie können Meeting-Interaktionen abrufen, aktualisieren oder löschen, die manuell in einem Datensatz protokolliert sind, mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/meetings-tool/use-meetings",
        children: "Meetings-Tool"
      }), " geplant wurden oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/integrations/schedule-a-meeting-with-a-contact-in-a-record",
        children: "mithilfe der Google Kalender- oder Office 365-Kalenderintegration geplant"
      }), " wurden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Folgenden erfahren Sie, wie Sie Meetings über die API verwalten können. Um alle verfügbaren Endpunkte und deren Anforderungen anzuzeigen, klicken Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein Meeting erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Meeting-Interaktion zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fügen Sie im Anfragetext Meeting-Details in einem ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), "-Objekt hinzu. Sie können auch ein ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associations"
      }), "-Objekt hinzufügen, um Ihr neues Meeting einem vorhandenen Datensatz (z. B. Kontakten, Unternehmen) zuzuordnen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im properties-Objekt können Sie die folgenden Felder einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Erforderlich. Dieses Feld markiert das Datum und die Uhrzeit des Meetings. Sie können entweder einen Unix-Zeitstempel im Millisekunden- oder UTC-Format verwenden. (BETA) Wenn der Eigenschaftswert fehlt, wird er standardmäßig auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_start_time."
            }), " gesetzt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Titel des Meetings."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID des zuständigen Mitarbeiters"
            }), ", der dem Meeting zugeordnet ist. Dieser Feld bestimmt den Benutzer, der in der Datensatzchronik als Meeting-Ersteller aufgeführt ist."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Beschreibung des Meetings."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_internal_meeting_notes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die internen Notizen, die Sie während eines Meetings für Ihr Team machen und die nicht in der Meeting-Beschreibung des Teilnehmers enthalten sind."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_external_URL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die externe URL für das Kalender-Event. Dies kann beispielsweise ein Google-Kalenderlink oder ein Microsoft Outlook-Kalenderlink sein."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_location"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wo das Meeting stattfindet Der Wert kann eine physische Adresse, ein Konferenzraum, ein Videokonferenzlink oder eine Telefonnummer sein. Dies wird in der Kalendereinladung im Kalender des Teilnehmers angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_start_time"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Zeitpunkt (Datum und Uhrzeit), zu dem das Meeting beginnt. Der Wert für diese Eigenschaft sollte mit dem Wert für ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            }), " übereinstimmen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_end_time"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Zeitpunkt (Datum und Uhrzeit), zu dem das Meeting endet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_outcome"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Ergebnis des Meetings. Die Ergebniswerte sind „Geplant“, „Abgeschlossen“, „Termin geändert“, „Nicht erschienen“ und „Storniert“."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_activity_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ des Meetings. Die Optionen basieren auf den ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/meetings-tool/how-do-i-create-and-use-call-and-meeting-types",
              children: "in Ihrem HubSpot-Account festgelegten Meeting-Typen."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die IDs der Anhänge des Meetings. Mehrere Anhang-IDs sind durch ein Semikolon getrennt."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zuordnungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um ein Meeting zu erstellen und bestehenden Datensätzen zuzuordnen, schließen Sie ein associations-Objekt in Ihre Anfrage ein. Um beispielsweise ein Meeting zu erstellen und Kontakten zuzuordnen, kann Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-23T01:02:44.872Z\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_meeting_title\": \"Intro meeting\",\n    \"hs_meeting_body\": \"The first meeting to discuss options\",\n    \"hs_internal_meeting_notes\": \"These are the meeting notes\",\n    \"hs_meeting_external_url\": \"https://Zoom.com/0000\",\n    \"hs_meeting_location\": \"Remote\",\n    \"hs_meeting_start_time\": \"2021-03-23T01:02:44.872Z\",\n    \"hs_meeting_end_time\": \"2021-03-23T01:52:44.872Z\",\n    \"hs_meeting_outcome\": \"SCHEDULED\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 101\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 200\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 102\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 200\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das associations-Objekt sollte Folgendes enthalten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Datensatz, den Sie dem Meeting zuordnen möchten, angegeben durch seinen eindeutigen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "-Wert."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ der Zuordnung zwischen Meeting und Datensatz. Beziehen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), " ein. Standardzuordnungstyp-IDs sind ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "hier"
            }), " aufgeführt. Sie können den Wert für benutzerdefinierte Zuordnungstypen (d. h. Label) über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "Zuordnungen-API"
            }), " abrufen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das batchweise Erstellen von Meetings, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " klicken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Meetings abrufen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Meetings einzeln oder mehrere gleichzeitig (batchweise) abrufen. Erfahren Sie mehr über das batchweise Abrufen, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " klicken."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein einzelnes Meeting anhand seiner Meeting-ID abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), " durch. Sie können auch die folgenden Parameter in der Anfrage-URL berücksichtigen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der Eigenschaften, die zurückgegeben werden sollen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste von Objekten, von denen Sie zugehörige Datensatz-IDs abrufen möchten."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Liste aller Meetings anzufordern, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/meetings"
      }), " durch. Sie können die folgenden Parameter in die Anfrage-URL einschließen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die maximale Anzahl an Ergebnissen, die pro Seite angezeigt werden können."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der Eigenschaften, die zurückgegeben werden sollen."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Meetings aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Meetings einzeln oder mehrere gleichzeitig (batchweise) aktualisieren. Um ein einzelnes Meeting anhand seiner Meeting-ID zu aktualisieren, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fügen Sie im Anfragetext die Meeting-Eigenschaften ein, die Sie aktualisieren möchten. Ihr Anfragetext kann beispielsweise wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example PATCH request to https://api.hubspot.com/crm/v3/objects/meetings/{meetingId}\n{\n  \"properties\": {\n     \"hs_timestamp\": \"2019-10-30T03:30:17.883Z\",\n     \"hubspot_owner_id\": \"11349275740\",\n     \"hs_meeting_title\": \"Intro meeting\",\n     \"hs_meeting_body\": \"The first meeting to discuss options\",\n     \"hs_internal_meeting_notes\": \"These are the meeting notes\",\n     \"hs_meeting_external_url\":\n     \"https://Zoom.com/0000\",\n     \"hs_meeting_location\": \"Remote\",\n     \"hs_meeting_start_time\": \"2021-03-23T01:02:44.872Z\",\n     \"hs_meeting_end_time\": \"2021-03-23T01:52:44.872Z\",\n     \"hs_meeting_outcome\": \"SCHEDULED\"\n  }\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ignoriert Werte für schreibgeschützte und nicht vorhandene Eigenschaften. Um einen Eigenschaftswert zu löschen, übergeben Sie eine leere Zeichenfolge für die Eigenschaft im Anfragetext."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über batchweise Aktualisierungen, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " klicken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vorhandene Meetings zu Datensätzen zuordnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Meeting Datensätzen zuzuordnen, z. B. einem Kontakt und seinen zugeordneten Unternehmen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), " durch. Die Anfrage-URL enthält die folgenden Felder:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meetingId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Meetings."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Typ des Objekts, dem Sie das Meeting zuordnen möchten (z. B. Kontakt oder Unternehmen)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die ID des Datensatzes, dem Sie das Meeting zuordnen möchten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die ID des Typs der Zuordnung zwischen Meeting und dem anderen Objekttyp. Sie können diesen Wert über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "Zuordnungs-API"
            }), " abrufen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ihre Anfrage-URL könnte beispielsweise wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/meetings/17612479134/associations/contact/104901/200"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine Zuordnung entfernen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Zuordnung zwischen einem Anruf und einem Datensatz zu entfernen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an dieselbe URL wie oben durch:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein Meeting in einen Datensatz anpinnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "ein Meeting an einen Datensatz anpinnen"
      }), ", damit es oben in der Chronik des Datensatzes bleibt. Der Meeting muss bereits vor dem Anpinnen dem Datensatz zugeordnet sein, und Sie können nur eine Aktivität pro Datensatz anpinnen. Um ein Meeting anzupinnen, fügen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " des Meetings in das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "-Feld ein, wenn Sie einen Datensatz über die Objekt-APIs erstellen oder aktualisieren. Erfahren Sie mehr über das Verwenden der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "Unternehmen"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "Kontakte"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "Deals"
      }), "-, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "Tickets"
      }), "- und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "Benutzerdefinierte Objekte"
      }), "-APIs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Meetings löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Meetings einzeln oder mehrere gleichzeitig (batchweise) löschen, wodurch das Meeting dem Papierkorb in HubSpot hinzugefügt wird. Sie können später ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "das Meeting über die Datensatzchronik wiederherstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein einzelnes Meeting anhand seiner Meeting-ID zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/meetings/{meetingId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über batchweise Löschungen, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " klicken."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}