"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937480;
const slug = exports.slug = 'guides/api/crm/owners';
const title = exports.title = 'API do CRM | Proprietários';
const name = exports.name = 'vNext DP de documentos - Proprietários';
const metaDescription = exports.metaDescription = 'A HubSpot usa proprietários para atribuir objetos de CRM a pessoas específicas. Esses pontos de extremidade são usados para obter uma lista de proprietários disponíveis para uma conta. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "recuperar-uma-lista-de-propriet%C3%A1rios",
  "label": "Recuperar uma lista de proprietários",
  "parent": null
}, {
  "depth": 0,
  "id": "recuperar-informa%C3%A7%C3%B5es-sobre-um-propriet%C3%A1rio-individual",
  "label": "Recuperar informações sobre um proprietário individual",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Proprietários"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os proprietários do HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-to-set-an-owner",
        children: "atribuem"
      }), " usuários específicos a registros, atividades ou tarefas de marketing e podem ser usados em tokens de personalização para o seu conteúdo. Os proprietários são criados e atualizados automaticamente no HubSpot quando novos usuários são adicionados ou quando os proprietários existentes são sincronizados a partir do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/salesforce/how-will-a-salesforce-owner-will-be-recognized-by-hubspot",
        children: "Salesforce"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os pontos de extremidade de API dos proprietários são somente leitura. Você pode usá-los para recuperar os detalhes de identificação de um proprietário, incluindo o ID. Esse identificador pode ser usado para atribuir propriedade aos registros do CRM no HubSpot, por meio de uma integração ou por meio de chamadas de API de alteração de propriedade."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar uma lista de proprietários"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar os proprietários atuais em sua conta, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/owners"
      }), ". A resposta retornará o nome de cada usuário, e-mail, valores de ID, datas de criação/atualização e, se aplicável, informações da equipe. Dois valores de ID são retornados e eles são usados para fins diferentes:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), ": o ID do proprietário. Esse valor deve ser usado ao recuperar informações sobre um proprietário específico e ao atribuir um proprietário a um registro ou atividade."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), ": o ID do usuário. Esse valor pode ser usado para especificar usuários na ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-provisioning",
          children: "API de configurações"
        }), ", mas resultará em um erro se for usado para atribuir propriedade."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sua resposta será semelhante ao seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response GET crm/v3/owners\n{\n  \"results\": [\n    {\n      \"id\": \"41629779\",\n      \"email\": \"email@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"HubSpot\",\n      \"lastName\": \"Test Owner\",\n      \"userId\": 9586504,\n      \"userIdIncludingInactive\": 9586504,\n      \"createdAt\": \"2019-12-25T13:01:35.228Z\",\n      \"updatedAt\": \"2023-08-22T13:40:26.790Z\",\n      \"archived\": false,\n      \"teams\": [\n        {\n          \"id\": \"368389\",\n          \"name\": \"Sales Team\",\n          \"primary\": true\n        }\n      ]\n    },\n    {\n      \"id\": \"60158084\",\n      \"email\": \"email@gmail.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"Test\",\n      \"lastName\": \"Email\",\n      \"userId\": 9274996,\n      \"userIdIncludingInactive\": 9274996,\n      \"createdAt\": \"2021-02-10T17:59:04.891Z\",\n      \"updatedAt\": \"2023-02-09T17:41:52.767Z\",\n      \"archived\": false,\n      \"teams\": [\n        {\n          \"id\": \"368389\",\n          \"name\": \"Sales Team\",\n          \"primary\": true\n        }\n      ]\n    },\n    {\n      \"id\": \"81538190\",\n      \"email\": \"salesmanager@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"Sales\",\n      \"lastName\": \"Manager Example\",\n      \"userId\": 3892666,\n      \"userIdIncludingInactive\": 3892666,\n      \"createdAt\": \"2021-05-27T16:55:57.242Z\",\n      \"updatedAt\": \"2022-08-02T18:34:35.039Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode recuperar proprietários arquivados para exibir usuários que foram desativados. Para fazer isso, adicione o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "archived"
      }), " com o valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". Para usuários arquivados, ainda há um valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", mas o valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userId"
      }), " será ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "null"
      }), ". O ID do usuário é armazenado no campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userIdIncludingInactive"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response GET crm/v3/owners/?archived=true\n{\n  \"results\": [\n    {\n      \"id\": \"42103462\",\n      \"email\": \"useremail@hubspot.com\",\n      \"type\": \"PERSON\",\n      \"firstName\": \"\",\n      \"lastName\": \"\",\n      \"userId\": null,\n      \"userIdIncludingInactive\": 9685555,\n      \"createdAt\": \"2020-01-09T20:28:50.080Z\",\n      \"updatedAt\": \"2020-01-09T20:28:50.080Z\",\n      \"archived\": true\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar informações sobre um proprietário individual"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar um proprietário específico, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/owners/{ownerId}"
      }), ". Você deve usar o valor ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " para especificar o proprietário para o qual deseja obter mais informações."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": o valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "updatedAt"
        }), " na resposta é alterado com base nas atualizações do próprio objeto Proprietário. Ele não será atualizado para alterações no objetoUsuário. Por exemplo, alterar as permissões de um usuário ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " atualizará o valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "updatedAt"
        }), "."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}