"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611103;
const slug = exports.slug = 'guides/cms/content/global-content';
const title = exports.title = 'グローバルコンテンツ';
const name = exports.name = 'グローバルコンテンツ';
const metaDescription = exports.metaDescription = 'グローバルコンテンツは、一貫した情報表示のために複数のテンプレートで再利用可能なコンテンツです。例として、ウェブサイトのヘッダー、フッター、サイドバー、ロゴなどのコンポーネントがあります。';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/5Cdocs/global-content/global-content-editor-4.png';
const featuredImageAltText = exports.featuredImageAltText = 'グローバル コンテンツ エディター';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%A6%82%E8%A6%81",
  "label": "概要",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%81%A8%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E6%AF%94%E8%BC%83",
  "label": "グローバルパーシャルとグローバルモジュールの比較",
  "parent": "%E6%A6%82%E8%A6%81"
}, {
  "depth": 0,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB",
  "label": "グローバルパーシャル",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "グローバルパーシャルを作成する",
  "parent": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB"
}, {
  "depth": 1,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%81%AB%E3%83%89%E3%83%A9%E3%83%83%E3%82%B0%EF%BC%86%E3%83%89%E3%83%AD%E3%83%83%E3%83%97%E3%82%A8%E3%83%AA%E3%82%A2%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "グローバルパーシャルにドラッグ＆ドロップエリアを追加する",
  "parent": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB"
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AE%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AB%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "既存のテンプレートにグローバルパーシャルを追加する",
  "parent": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%91%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB"
}, {
  "depth": 0,
  "id": "%E3%82%B0%E3%83%AD%E3%83%BC%E3%83%90%E3%83%AB%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "グローバルモジュール",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "グローバルコンテンツ"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["グローバルコンテンツとは、ウェブサイト全体で共有されるコンテンツです。一般的な例としては、ウェブサイトのヘッダー、フッター、サイドバーが挙げられます。開発者はどのコンポーネントをグローバルとして扱うかを指定するために、グローバルパーシャルを使用するか、モジュールをグローバルに指定します。HubSpotを使用すると、グローバルコンテンツを扱うコンテンツエディターは、グローバルコンテンツを編集し、全てのページで編集内容をプレビューする作業を簡単に行えます。グローバルコンテンツの編集方法については、HubSpotナレッジベースで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
        children: "複数のテンプレートにわたってグローバルコンテンツを使用する方法"
      }), "に関するナレッジベース記事を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/global-content/global-content-editor-4.png",
        alt: "グローバル コンテンツ エディター"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "概要"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "グローバルコンテンツの使用は、複数のページにわたって同じ情報を表示する場合に最も効果的です。例えば、以下に示すページの上部にあるヘッダーなど、ウェブサイトのヘッダーやフッターに使用できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubspot-developers-header.png",
        alt: "hubspot-developers-header"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "グローバルコンテンツは次のような領域にも使用できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ウェブサイトのセクション別のセカンダリーナビゲーション"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "著作権のフッター（またはサブフッター）"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ブログ記事のサイドバー（最近の投稿、執筆者リストなどの表示に使用）"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["グローバルコンテンツはウェブサイトの複数の場所で使用されるので、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "アクセシビリティーを考慮してグローバルパーシャルとグローバルモジュールをデザインし、作成する"
        }), "ことが特に重要になります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "グローバルパーシャルとグローバルモジュールの比較"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者は、グローバルパーシャルとグローバルモジュールを作成できます。この2つの間の主な違いは次のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "グローバルパーシャルは、HTMLとHubLを使用して作成されたテンプレートの一種であり、ウェブサイト全体にわたって再利用できます。典型的なパーシャルは、ウェブサイトのヘッダー、サイドバー、フッターです。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "グローバルモジュールは、ウェブサイト上の複数のページで使用可能な1つまたは複数のコンテンツ要素で構成されたモジュールです。典型的なグローバルモジュールとしては、ブログの配信登録フォーム、セカンダリーナビゲーション要素、Call-To-Action（CTA）などがあります。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "グローバルパーシャル内にグローバルモジュールを含めることは避けてください。コンテンツの編集作業に悪影響を及ぼす可能性があります。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["グローバルパーシャルとグローバルモジュールに含まれる全てのモジュールとフィールドは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
          children: "グローバル コンテンツ エディター"
        }), "内で簡単に編集できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "グローバルパーシャル"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "グローバルパーシャルを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["グローバルパーシャルはテンプレートの一種であり、HubSpot CLIで以下に示す", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#create",
        children: "createコマンド"
      }), "を使用することで、ローカルに作成できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template <partial-file-name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テンプレートのタイプを選択するように求められたら、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global partial"
      }), "を選択します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このコマンドを実行すると、指定したディレクトリーにテンプレートが作成され、そのHTMLファイルには以下のテンプレートアノテーションが含まれます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: global_partial\n  label: Page Header\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["グローバル コンテンツ パーシャルのサンプルを確認するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/partials/header.html",
        children: "GitHubにあるHubSpotのボイラープレート"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "グローバルパーシャルにドラッグ＆ドロップエリアを追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["グローバルパーシャル内でコンテンツのドラッグ＆ドロップ機能を有効にするには、ページテンプレートで有効にする場合と同様に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "タグを追加します。詳しくは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "ドラッグ＆ドロップエリアのドキュメント"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既存のテンプレートにグローバルパーシャルを追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既存のテンプレートのいずれかにグローバルパーシャルを追加するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global_partial"
      }), " HubLタグを使用してパーシャルへのパスを参照します。以下に示しているのは、このタグを使用した", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html#L21",
        children: "CMSボイラープレート"
      }), "の例です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% global_partial path=\"../partials/header.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["グローバルパーシャルが出力の場合は、グローバルパーシャルを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), "で囲みます。これは、そのコンテンツがグローバルパーシャルであることを識別するためにページエディターで使用されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div\n  data-global-resource-path=\"cms-theme-boilerplate/templates/partials/header.html\"\n>\n  <!-- Your header.html code is output here -->\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**テンプレートの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), "内では", (0, _jsxRuntime.jsx)(_components.code, {
          children: "global_partial"
        }), "を使用しないでください。使用すると、無効なHTMLになります。"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["ヘッダー内でグローバルパーシャルを使用するような場合は通常、代わりにグローバルモジュールで", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-head",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{%require_head%}"
          })
        }), "を使用してカスタムコードをheadに挿入し、モジュールフィールドも追加する方法が適切です。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "グローバルモジュール"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["グローバルモジュールを作成するには、CLIを使用する他のモジュールを作成する場合と同様に、以下に示す", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create"
      }), "コマンドを実行します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create module <module_name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["グローバルモジュールは、モジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "ファイル内にある", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global"
      }), "フラグによって区別されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json file\n{\n  \"css_assets\": [],\n  \"external_js\": [],\n  \"global\": true,\n  \"help_text\": \"\",\n  \"host_template_types\": [\"PAGE\"],\n  \"js_assets\": [],\n  \"other_assets\": [],\n  \"smart_type\": \"NOT_SMART\",\n  \"tags\": [],\n  \"is_available_for_new_content\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/create-and-edit-modules",
        children: "HubSpotでデザインマネージャーを使用してグローバルモジュールを作成"
      }), "することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールの操作方法についての詳細は、以下の関連リソースをご確認ください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "モジュールの概要"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "HubSpotの既定のモジュール"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "モジュールを構成する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "テンプレートでモジュールを使用する"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}