"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 176029454941;
const slug = exports.slug = 'guides/cms/content/memberships/google-sign-in';
const title = exports.title = 'Google Sign-In for Memberships';
const name = exports.name = 'Google Sign-In for Memberships';
const metaDescription = exports.metaDescription = 'Learn more about setting up memberships for private content to allow visitors to sign in with their Google account.  ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-project",
  "label": "Create a project",
  "parent": null
}, {
  "depth": 0,
  "id": "add-app-information",
  "label": "Add app information",
  "parent": null
}, {
  "depth": 0,
  "id": "create-credentials",
  "label": "Create credentials",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Google Sign-In for Memberships"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise', 'cms-professional']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can allow members to access your private content using Google Authentication. You will need to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://console.cloud.google.com/",
        children: "create a Google Cloud Console account"
      }), " to link Google Authentication with your HubSpot account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a project"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In your Google Cloud Console account, you can create a project to manage sign-ins to your private content in HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://console.cloud.google.com/",
          children: "Google Cloud Console"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top left, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select a project"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "New project"
        }), " in the top right."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "location"
        }), " for your project, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Add app information"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've set up your project, you can add information from your HubSpot account to allow it to process private content sign-in data."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://console.cloud.google.com/",
          children: "Google Cloud Console"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top left, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select a project"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "project"
        }), " you just created."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top left, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "navigation menu"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "API & Services > Oauth consent screen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/select-oauth-consent-screen.png",
        alt: "select-oauth-consent-screen"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "User Type"
        }), " section, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "type of user"
        }), " who will be accessing your private content.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Internal"
            }), ": only employees of your organization will be access your content."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "External"
            }), ": other visitors will be accessing your content."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you select ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "External"
        }), ", you will be prompted to enter an ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "app name"
        }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "support email"
        }), " for members to contact you, and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "developer contact information"
        }), " for Google to contact you."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Scopes"
        }), " section, select the following scopes:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [".../auth/", (0, _jsxRuntime.jsx)(_components.a, {
              href: "http://userinfo.email/",
              children: "userinfo.email"
            })]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: ".../auth/userinfo.profile"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "openid"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Publish your app."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create credentials"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've set up your project, you will need to link the project to your HubSpot private content."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://console.cloud.google.com/",
          children: "Google Cloud Console"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top left, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "navigation menu"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "API & Services > Credentials"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["At the top of the page, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create credentials"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/create-credentials.png",
          alt: "create-credentials"
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "OAuth client ID"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "web application"
        }), ", then enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " for your application."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In a separate tab, locate your credentials in HubSpot:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "settings icon"
            }), " in the top navigation bar."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Content > Private Content"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Sign-in Options"
            }), " section, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Set up social sign-on"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the right panel, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Copy"
            }), " next to the redirect URI."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In Google Cloud Console, paste the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "redirect URI"
        }), " into the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Authorized redirect URIs"
        }), " section."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Copy the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Client ID"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Client Secret"
        }), " fields from Google Cloud Console."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In HubSpot, paste this information into the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Client ID"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Client Secret"
        }), " fields in your social sign-on settings."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/set-up-google-social-sign-on-in-hubspot.png",
        alt: "set-up-google-social-sign-on-in-hubspot"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In HubSpot, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Verify"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Repeat this process for each separate domain in your HubSpot account. To access a different domain's redirect URI, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "default domain name"
        }), " dropdown menu in your HubSpot social sign-on settings and select a different ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "domain"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/set-up-social-sign-on-for-google.png",
        alt: "set-up-social-sign-on-for-google"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}