"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742904;
const slug = exports.slug = 'guides/api/marketing/subscriptions-preferences';
const title = exports.title = 'Marketing-API | Abo-Einstellungen';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Subscriptions Preferences';
const metaDescription = exports.metaDescription = ' Die Endpunkte für Abonnementeinstellungen ermöglichen es Ihnen, Abonnementdetails für Kontakte in einem HubSpot-Portal zu verwalten.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "kontakt-abonnementstatus-abrufen",
  "label": "Kontakt-Abonnementstatus abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "kontakt-abonnieren",
  "label": "Kontakt abonnieren",
  "parent": null
}, {
  "depth": 0,
  "id": "kontakt-abmelden",
  "label": "Kontakt abmelden",
  "parent": null
}, {
  "depth": 0,
  "id": "abonnementtypen-abrufen",
  "label": "Abonnementtypen abrufen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Abo-Einstellungen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Abonnementtypen stellen die rechtliche Grundlage für eine Kommunikation per E-Mail dar.Kontakte können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/articles/kcs_article/email/how-to-send-contacts-an-email-with-an-opt-out-link-so-they-can-manage-their-preferences",
        children: "ihre E-Mail-Einstellungen verwalten"
      }), ", damit Sie nur E-Mails erhalten, die Sie per Opt-in abonniert haben."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Die Abonnementeinstellungen-API unterstützt derzeit ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " das Abrufen oder Aktualisieren von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/inbox/collect-consent-for-whatsapp-conversations",
          children: "WhatsApp"
        }), "-Abonnementinformationen für einen Kontakt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kontakt-Abonnementstatus abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Endpunkt „Kontakt-Abonnementstatus“ ermöglicht es Benutzern, die Abonnementstatus für eine E-Mail-Adresse in einem Account abzurufen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieser Endpunkt ist ideal, wenn Sie über ein externes Einstellungscenter bzw. eine Integration verfügen und die Abonnementstatus für jede gegebene E-Mail-Adresse in Ihrem Account kennen müssen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kontakt abonnieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Endpunkt „Kontakt abonnieren“ ermöglicht es Ihnen, eine E-Mail-Adresse zu einem beliebigen Abonnementtyp in einem Account zu abonnieren, aber ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Sie können Kontakte, die sich abgemeldet haben, nicht erneut abonnieren."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispiel für einen Anwendungsfall:"
      }), " Dieser Endpunkt ist ideal, wenn Sie über eine Integration oder ein externes Formular verfügen, die bzw. das Kontakte in einen Abonnementtyp abonnieren muss."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Bitte beachten"
      }), ": Der Endpunkt „Kontakt abonnieren“ sollte nur verwendet werden, um Anfragen von Kontakten zu berücksichtigen, die Ihnen eine Berechtigung zum Abonnement erteilt haben. Bitte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/contacts/how-do-subscription-preferences-and-types-work",
        children: "überprüfen Sie vor dem Abonnement eines Kontakts die geltenden Gesetze und Vorschriften"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Kontakt abmelden"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Endpunkt „Kontakt abmelden“ ermöglicht es Ihnen, eine E-Mail-Adresse in einem Account aus einem bestimmten Abonnementtyp abzumelden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispiel für einen Anwendungsfall:"
      }), " Dieser Endpunkt ist ideal, wenn Sie über eine Integration oder ein externes Formular verfügen, die bzw. das Kontakte von einem Abonnementtyp abmelden muss."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Abonnementtypen abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Endpunkt „Abonnementinformationen“ ermöglicht es Benutzern, alle Abonnementtypen in ihrem Account abzurufen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispiel für einen Anwendungsfall:"
      }), " Dieser Endpunkt ist ideal, wenn Sie über ein externes Einstellungscenter, eine Integration bzw. ein Formular verfügen und die Abonnementtypen in Ihrem Account kennen müssen, damit Sie die Abonnementstatus für Ihre Kontakte entsprechend aktualisieren müssen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {})]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}