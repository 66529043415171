"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 129242476384;
const slug = exports.slug = 'guides/crm/ui-extensions/sample-extensions/deals-summary-tutorial';
const title = exports.title = 'Deals summary tutorial';
const name = exports.name = 'Deals summary tutorial';
const metaDescription = exports.metaDescription = 'Learn how to set up the deals summary sample project in your HubSpot account.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "update-your-cli-and-authenticate-your-account",
  "label": "Update your CLI and authenticate your account",
  "parent": null
}, {
  "depth": 0,
  "id": "clone-the-sample-project-and-upload-to-your-account",
  "label": "Clone the sample project and upload to your account",
  "parent": null
}, {
  "depth": 0,
  "id": "create-two-deals",
  "label": "Create two deals",
  "parent": null
}, {
  "depth": 0,
  "id": "start-local-development",
  "label": "Start local development",
  "parent": null
}, {
  "depth": 0,
  "id": "add-the-average-margin-component",
  "label": "Add the average margin component",
  "parent": null
}, {
  "depth": 1,
  "id": "update-the-serverless-function",
  "label": "Update the serverless function",
  "parent": "add-the-average-margin-component"
}, {
  "depth": 1,
  "id": "update-the-react-frontend",
  "label": "Update the React frontend",
  "parent": "add-the-average-margin-component"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      strong: "strong",
      em: "em",
      img: "img",
      br: "br",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Deals summary sample project tutorial (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To better understand how to build UI extensions and get the most out of CRM data, extension components, and project development, HubSpot provides a set of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview",
        children: "sample UI extensions"
      }), " featuring a variety of functionalities, including this deal summary sample extension. These sample projects can server as inspiration, reference, or a starting point for your own UI extensions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "By the end of this tutorial, you will have:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Uploaded the deal summary sample project to your account."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Created two deal records to supply the custom card with example data."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Customized the card with a component to display calculated deal data."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For the purposes of this tutorial, it's recommended to complete these steps in a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/setup#create-and-use-development-sandboxes",
        children: "development sandbox"
      }), " account to keep this example data separate from the production account."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["If you haven't created a UI extension yet, it's recommended to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/quickstart",
          children: "follow the quickstart guide"
        }), " first."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update your CLI and authenticate your account"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get started, you'll first need to ensure that your CLI is on the latest version that your account is authenticated. If this is your first time setting up your local environment, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/setup",
        children: "projects setup guide"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Update to the latest CLI version by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install -g @hubspot/cli@next"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you haven't yet connected your production account to the CLI, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs init"
        }), " to create the necessary ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        }), " file. You can either create this file in your working directory or in one of its parent directories. When working locally, the CLI will use whichever config file is closest, meaning you can also have multiple config files depending on your preferred workflow."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Clone the sample project and upload to your account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With your local environment set up, you'll now download the sample project and upload it to your account. You'll then be able to view the extension's custom card on any contact record in your account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clone the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/deals-summary",
            children: "sample app"
          }), " to your working directory."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Upload the sample app to your account by running ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs project upload"
          }), ", then follow the CLI prompts to complete the upload."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "With the project uploaded, log in to your HubSpot account."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Contacts"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Contacts"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of any contact record. If you're in a new account, you can click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Brian Halligan"
          }), " sample contact, or click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create contact"
          }), " in the upper right to create a new test contact."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["On the contact record, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Custom"
          }), " tab at the top of the record. On this tab, you'll see the uploaded sample project card titled ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Deals summary"
          }), ". Because you haven't yet created any deals associated with the contact, the card will show no data."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deals-summary-tutorial-empty-state.png",
            alt: "deals-summary-tutorial-empty-state"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if the project uploaded successfully but you're not seeing the custom card on the contact record, you may need to click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Customize tabs"
        }), " to add the card to the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Custom"
        }), " tab first."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Next, you'll create two deals associated with the contact to populate the card with data."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create two deals"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If your test contact has no deals associated with it, you'll need to create two deals to fill the custom card with data."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Deals"
          }), " card in the right sidebar of the contact record, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "+ Add"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/contact-record-create-deal.png",
            alt: "contact-record-create-deal"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the right panel, give the deal a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Deal name"
          }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "amount"
          }), ". Then, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create and add another"
          }), " at the bottom of the panel."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Repeat the same step as above to give the second deal a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Deal name"
          }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "amount"
          }), ". Then, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create"
          }), " at the bottom of the panel."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Because you created these deals from the contact record, they'll automatically be associated with the contact. If you now refresh the page, the card will populate with the aggregated deal data.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/sample-app-contact-with-deals.png",
        alt: "sample-app-contact-with-deals"
      }), "Next, you'll start the local development server and update your project with authentication so that you can fetch more deal data using HubSpot's API."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Start local development"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By starting a local development server, you'll be able to update the card's frontend and see your saved changes without needing to upload first. And by adding the private app access token to the project, you'll enable the serverless function to make authenticated requests to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals",
        children: "HubSpot deals API"
      }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/quickstart#start-local-development",
        children: "running the local development server"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions#managing-secrets",
        children: "managing secrets"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Run ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "npm install"
          }), " to install dependencies needed for running the local development server."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Inside the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "src/app/app.functions"
          }), " folder, create a new file named ", (0, _jsxRuntime.jsx)(_components.code, {
            children: ".env"
          }), " to store your private app access token."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Retrieve the private app's access token:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CRM Development"
              }), "."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Private apps"
              }), "."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Deals summary app"
              }), " to navigate to the app's overview page."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Auth"
              }), " tab."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Access token"
              }), " section, click ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Show token"
              }), ". Then, click ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Copy"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/private-app-show-token.png",
                alt: "private-app-show-token"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Paste the token into your ", (0, _jsxRuntime.jsx)(_components.code, {
                children: ".env"
              }), " file using the following format:"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "PRIVATE_APP_ACCESS_TOKEN=your-access-token\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Save the file."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Start the local development server by running ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs project dev"
          }), ", then following the prompts to select the account to develop in."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Return the contact record in HubSpot and refresh the browser. You should now see an icon on the card that says ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "DEVELOPING LOCALLY"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deals-summary-card-developing-locally.png",
            alt: "deals-summary-card-developing-locally"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Next, you'll update your local extension files to fetch deal data with the API, calculate average deal margin, and add a new component to the card."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Add the average margin component"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You'll now update the card with a new component to display the calculated average deal margin. You'll first update the card's backend (serverless function), then the frontend React files."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update the serverless function"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your project files, open the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get-data.js"
        }), " file. This serverless function is using the HubSpot API client to fetch associated deal data through the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "getAssociatedDeals"
        }), " function."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add the following function to the bottom of the file, below the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "calculateTotalAmounts"
        }), " function."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "function calculateAverageAmount(deals) {\n  const totalCount = deals.length;\n\n  const amounts = deals.map((deal) => parseFloat(deal.properties.amount));\n  const totalDeals = amounts.reduce((sum, amount) => sum + amount, 0);\n\n  const average = Math.ceil(totalDeals / totalCount);\n  return -Math.round(-average);\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To store the result of this function, update ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "exports.main"
        }), " with a new variable, then add that variable to the existing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sendResponse"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const avgAmount = calculateAverageAmount(deals);\n\nsendResponse({ dealsCount: deals.length, totalAmount, avgAmount });\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update the React frontend"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Open the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DealsSummary.jsx"
        }), " file, then add the following state variable to define the initial state of the average amount."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const [avgAmount, setAvgAmount] = useState(0);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Update the state within the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "useEffect"
        }), " function."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "setAvgAmount(serverlessResponse.response.avgAmount);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lastly, add a new ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/ui-components/overview#statistics",
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "StatisticsItem"
          }), " component"]
        }), " to display the averaged amount in the card."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<StatisticsItem label=\"AVG MARGIN\" number={avgAmount}>\n  <Text>Low End</Text>\n</StatisticsItem>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After making the above changes, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DealsSummary.jsx"
      }), " file should contain the following code:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// for HubSpot API calls\nconst hubspot = require('@hubspot/api-client');\n\nexports.main = async (context = {}, sendResponse) => {\n  const { hs_object_id } = context.propertiesToSend;\n\n  const deals = await getAssociatedDeals(hs_object_id);\n  const totalAmount = calculateTotalAmount(deals);\n  const avgAmount = calculateAverageAmount(deals);\n\n  sendResponse({ dealsCount: deals.length, totalAmount, avgAmount });\n};\n\nasync function getAssociatedDeals(hs_object_id) {\n  const hubSpotClient = new hubspot.Client({\n    accessToken: process.env['PRIVATE_APP_ACCESS_TOKEN'],\n  });\n  const objectData = await hubSpotClient.crm.contacts.basicApi.getById(\n    hs_object_id,\n    null,\n    null,\n    ['deals']\n  );\n  const dealIds = objectData.associations.deals.results.map((deal) => deal.id);\n  const deals = await hubSpotClient.crm.deals.batchApi.read({\n    inputs: dealIds.map((id) => ({ id })),\n  });\n  return deals.results;\n}\n\nfunction calculateTotalAmount(deals) {\n  const amounts = deals.map((deal) => parseFloat(deal.properties.amount));\n  return amounts.reduce((sum, amount) => sum + amount, 0);\n}\n\nfunction calculateAverageAmount(deals) {\n  const totalCount = deals.length;\n\n  const amounts = deals.map((deal) => parseFloat(deal.properties.amount));\n  const totalDeals = amounts.reduce((sum, amount) => sum + amount, 0);\n\n  const average = Math.ceil(totalDeals / totalCount);\n  return -Math.round(-average);\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With your changes saved, you should now see the new component in the card displaying the calculation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/deals-summary-card-changes.png",
        alt: "deals-summary-card-changes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To quit the local development server and upload your finished work, press ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "q"
        }), " in the terminal, then run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload"
        }), "."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}