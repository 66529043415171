"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 48816364594;
const slug = exports.slug = 'guides/cms/content/fields/brand-and-settings-inheritance';
const title = exports.title = 'Brand settings inheritance';
const name = exports.name = 'Brand settings inheritance';
const metaDescription = exports.metaDescription = 'Learn how to use brand settings, such as logos and brand colors, within a theme or across HubL/HTML and CSS files. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "brand-settings-variables",
  "label": "Brand settings variables",
  "parent": null
}, {
  "depth": 1,
  "id": "colors",
  "label": "Colors",
  "parent": "brand-settings-variables"
}, {
  "depth": 1,
  "id": "logos",
  "label": "Logos",
  "parent": "brand-settings-variables"
}, {
  "depth": 1,
  "id": "favicons",
  "label": "Favicons",
  "parent": "brand-settings-variables"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Brand settings inheritance"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/customize-branding-for-your-hubspot-content#customize-your-company-logo-and-colors",
        children: "brand settings"
      }), ", users can set up the company's brand colors, logos, and favicons to be used across HubSpot content. This enables you to access those brand settings with tokens in a theme's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file and within HTML/HubL and CSS files. You can also access brand colors within a module's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After adding these tokens within a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file, content creators can edit their values within the theme settings editor. When adding these tokens in a HTML, HubL, or CSS, the values will be hardcoded and cannot be modified in the page editor by content creators."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about the available brand setting variables along with examples of implementation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Brand settings variables"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following is a list of options that can be accessed from the brand settings area within the value of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property_value_paths"
      }), " object or within HTML/HubL and CSS files."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Colors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Brand colors can be accessed both within a theme or module's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json-color",
        children: "fields.json file"
      }), ", and within HTML/HubL and CSS files by using the following HubL tokens:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Primary:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.primaryColor}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[0]}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-primary.png",
                alt: "brand-colors-primary"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Secondary:"
          }), " ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{brand_settings.secondaryColor}}"
          })]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-secondary.png",
            alt: "brand-colors-secondary"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Accent colors:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor1}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor2}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor3}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-accent.png",
                alt: "brand-colors-accent"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Additional colors:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[1]}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[2]}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[3]}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-additional.png",
                alt: "brand-colors-additional"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To access a color's hex code directly, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hex"
      }), " filter in the token. For example: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.primaryColor.hex}}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To include brand settings colors in a theme or module's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file, use the following format:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example of using the primary color in within a theme's\n// field.json file\n{\n  \"name\": \"branding_color\",\n  \"label\": \"branding_color\",\n  \"type\": \"color\",\n  \"default\": {\n    \"color\": \"#26ff55\",\n    \"opacity\": 60\n  },\n  \"inherited_value\": {\n    \"property_value_paths\": {\n      \"color\": \"brand_settings.primaryColor\"\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "There are times when accounts may not have any additional colors configured in its brand settings. If your code is referencing an inherited color that does not exist in brand settings, the following fallback logic is used:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "secondaryColor"
          }), " falls back to the first additional color (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[1]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor1"
          }), " falls back to the second additional color (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[2]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor2"
          }), " falls back to the third additional color (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[3]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor3"
          }), " falls back to the fourth additional color (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[4]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Additional colors (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[3]"
          }), ") will fall back to the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "default"
          }), " value. If there is no default property color set, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "primaryColor"
          }), " will be used."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Logos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Brand logos can be accessed within a module's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file, and within HTML/HubL and CSS files."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use the following tokens to access the primary logo set within brand settings:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo}}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.logos[0]}}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/brand-settings-logo0.png",
        alt: "brand-settings-logo0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All additional logos can be accessed by using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.logos[1-19]}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition, you can access the following logo attributes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logo URL:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.link}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logo alt text:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.alt}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logo height:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.height}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logo width:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.width}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Link to the logo's image:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.src}}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Favicons"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Brand favicons can be accessed only within HTML/HubL and CSS files."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use the following tokens to access the primary logo set within brand settings:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryFavicon}}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.favicons[0]}}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/brand-settings-favicon0.png",
        alt: "brand-settings-favicon0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All additional favicons can be accessed by using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.favicons[1-19]}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can access the URL of the logo directly by including an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " filter. For example: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.primaryFavicon.src}}"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}