"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42817161729;
const slug = exports.slug = 'guides/apps/authentication/oauth-quickstart-guide';
const title = exports.title = 'Trabalhando com o OAuth | Guia do Quickstart do OAuth';
const name = exports.name = 'LATAM (PT) vNext Docs DP - OAuth Quickstart';
const metaDescription = exports.metaDescription = 'Conecte-se ao HubSpot com OAuth usando nosso Guia do Quickstart e o aplicativo de exemplo node.js.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "antes-de-come%C3%A7ar",
  "label": "Antes de começar",
  "parent": null
}, {
  "depth": 1,
  "id": "como-ele-funciona",
  "label": "Como ele funciona",
  "parent": "antes-de-come%C3%A7ar"
}, {
  "depth": 1,
  "id": "neste-guia",
  "label": "Neste guia",
  "parent": "antes-de-come%C3%A7ar"
}, {
  "depth": 0,
  "id": "aplicativo-quickstart",
  "label": "Aplicativo Quickstart",
  "parent": null
}, {
  "depth": 0,
  "id": "obtendo-tokens-do-oauth-2.0",
  "label": "Obtendo tokens do OAuth 2.0",
  "parent": null
}, {
  "depth": 1,
  "id": "etapa-1-crie-o-url-de-autoriza%C3%A7%C3%A3o-e-direcione-o-usu%C3%A1rio-para-o-servidor-oauth-2.0-da-hubspot",
  "label": "Etapa 1: crie o URL de autorização e direcione o usuário para o servidor OAuth 2.0 da HubSpot",
  "parent": "obtendo-tokens-do-oauth-2.0"
}, {
  "depth": 3,
  "id": "exemplos",
  "label": "Exemplos",
  "parent": "obtendo-tokens-do-oauth-2.0"
}, {
  "depth": 3,
  "id": "codificar-um-estado-de-redirecionamento-do-usu%C3%A1rio-adicional",
  "label": "Codificar um estado de redirecionamento do usuário adicional",
  "parent": "obtendo-tokens-do-oauth-2.0"
}, {
  "depth": 1,
  "id": "etapa-2-o-hubspot-solicita-o-consentimento-ao-usu%C3%A1rio",
  "label": "Etapa 2: o HubSpot solicita o consentimento ao usuário",
  "parent": "obtendo-tokens-do-oauth-2.0"
}, {
  "depth": 1,
  "id": "etapa-3-tratar-a-resposta-do-servidor-oauth-2.0",
  "label": "Etapa 3: tratar a resposta do servidor OAuth 2.0",
  "parent": "obtendo-tokens-do-oauth-2.0"
}, {
  "depth": 3,
  "id": "exemplo",
  "label": "Exemplo:",
  "parent": "obtendo-tokens-do-oauth-2.0"
}, {
  "depth": 1,
  "id": "etapa-4-trocar-o-c%C3%B3digo-de-autoriza%C3%A7%C3%A3o-para-tokens",
  "label": "Etapa 4: trocar o código de autorização para tokens",
  "parent": "obtendo-tokens-do-oauth-2.0"
}, {
  "depth": 3,
  "id": "exemplo",
  "label": "Exemplo:",
  "parent": "obtendo-tokens-do-oauth-2.0"
}, {
  "depth": 0,
  "id": "usando-tokens-do-oauth-2.0",
  "label": "Usando tokens do OAuth 2.0",
  "parent": null
}, {
  "depth": 3,
  "id": "exemplo",
  "label": "Exemplo:",
  "parent": "usando-tokens-do-oauth-2.0"
}, {
  "depth": 0,
  "id": "atualizando-tokens-de-oauth-2.0",
  "label": "Atualizando tokens de OAuth 2.0",
  "parent": null
}, {
  "depth": 3,
  "id": "exemplo",
  "label": "Exemplo:",
  "parent": "atualizando-tokens-de-oauth-2.0"
}, {
  "depth": 2,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": "atualizando-tokens-de-oauth-2.0"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      hr: "hr",
      h3: "h3",
      ol: "ol",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      h5: "h5",
      pre: "pre",
      img: "img",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Guia do Quickstart do OAuth"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Antes de começar"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de começar a usar o OAuth com o HubSpot, você precisará ter:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/developers",
          children: "Uma conta de desenvolvedor"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "Um aplicativo"
        }), " associado à sua conta de desenvolvedor"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Uma conta da HubSpot*para instalar seu aplicativo (você pode usar uma conta existente ou ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/api/creating-test-accounts",
          children: "criar uma conta de teste"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "* Você precisa ser um Superadministrador para instalar um aplicativo em uma conta da HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Como ele funciona"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubSpot oferece suporte ao ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.okta.com/blog/2018/04/10/oauth-authorization-code-grant-type",
        children: "tipo de concessão do Código de autorização do OAuth 2.0"
      }), ", que pode estar dividido em quatro etapas básicas:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O aplicativo abre uma janela do navegador para direcionar o usuário ao servidor OAuth 2.0 do HubSpot"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O usuário avalia as permissões solicitadas e concede acesso ao aplicativo"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O usuário é redirecionado de volta ao aplicativo com um código de autorização na Query String da URL"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O aplicativo envia uma solicitação ao servidor OAuth 2.0 para trocar o código de autorização por um token de acesso"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Neste guia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#quick-start-app",
          children: "Aplicativo de início rápido"
        }), ": um aplicativo de demonstração Node.js que faz a autenticação com o servidor OAuth 2.0 do HubSpot"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#getting_tokens",
          children: "Obter tokens de OAuth 2.0"
        }), ": como autorizar seu aplicativo com usuários"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#using_tokens",
          children: "Usar tokens do OAuth 2.0"
        }), ": como fazer consultas com um token"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#refreshing_tokens",
          children: "Atualizar tokens do OAuth 2.0"
        }), ": como usar o token de atualização fornecido pela HubSpot"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Observação:"
      }), " todos os exemplos de código neste guia são desenvolvidos em JavaScript (Node.js)"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Aplicativo Quickstart"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se essa é a primeira vez que você está usando a autenticação OAuth com APIs da HubSpot, consulte o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
        children: "Aplicativo Quickstart do OAuth 2.0"
      }), " desenvolvido em Node.js. Esse aplicativo de exemplo tem por finalidade ensinar você começar a usar o OAuth 2.0 o mais rápido possível. Ele demonstra todas as etapas descritas abaixo em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/oauth/tokens",
        children: "Obtendo tokens do OAuth 2.0"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
          children: "Obtenha o aplicativo no Github"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtendo tokens do OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Etapa 1: crie o URL de autorização e direcione o usuário para o servidor OAuth 2.0 da HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando um usuário é direcionado para o servidor OAuth 2.0 da HubSpot, a primeira etapa consiste na criação do URL de autorização. Isso identificará seu aplicativo e definirá os recursos (escopos) cujo acesso ele está solicitando em nome do usuário. Os parâmetros de consulta que você pode passar como parte de um URL de autorização são mostrados abaixo. Para obter informações mais detalhadas sobre essa etapa, leia o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "documento de referência"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Obrigatório?"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sim"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do cliente identifica seu aplicativo. Encontre-o na página de configurações do seu aplicativo."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sim"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/pt/docs/api/working-with-oauth#scopes",
              children: "escopos"
            }), " que seu aplicativo está solicitando, separados espaços codificados por URL."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contacts%20social"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sim"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O URL para o qual o usuário será redirecionado depois que ele autorizar seu aplicativo para os escopos solicitados. ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Para aplicativos de produção, o https é obrigatório."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "optional_scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os escopos que são opcionais para o aplicativo e serão ignorados se o portal do HubSpot selecionado não tiver acesso a esses produtos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um valor de string exclusivo que pode ser usado para manter o estado do usuário quando ele for redirecionado de volta ao aplicativo."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WeHH_yy2irpl8UYAvv-my"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de criar seu URL, comece o processo do OAuth 2.0 direcionando o usuário a ele."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Exemplos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando um redirecionamento no lado do servidor:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Build the auth URL\nconst authUrl =\n  'https://app.hubspot.com/oauth/authorize' +\n  `?client_id=${encodeURIComponent(CLIENT_ID)}` +\n  `&scope=${encodeURIComponent(SCOPES)}` +\n  `&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;\n+`&state=${encodeURIComponent(STATE)}`;\n\n// Redirect the user\nreturn res.redirect(authUrl);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usando um link HTML:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a\n  href=\"https://app.hubspot.com/oauth/authorize?scope=contacts%20social&redirect_uri=https://www.example.com/auth-callback&client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&state=xxxxxxxx\"\n  >Install</a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Codificar um estado de redirecionamento do usuário adicional"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alguns aplicativos podem precisar redirecionar o usuário para locais diferentes. Por exemplo, um aplicativo pode querer redirecionar os usuários para diferentes subdomínios da sua integração (por exemplo, userA.integration.com e userB.integration.com). Para fazer isso, use o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "state"
      }), " para codificar mais informações sobre o estado do usuário:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "1. Gerencie e armazene um valor nonce para o parâmetro state."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "2. Armazene o estado do usuário em um armazenamento de dados local usando o nonce como sua chave."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "3. Inclua o valor nonce como o parâmetro state no URL de autorização."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "4. Quando o usuário for autenticado e redirecionado para o URL de redirecionamento, valide o parâmetro state e use-o como a chave para recuperar o estado do usuário que foi armazenado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "5. Depois, redirecione o usuário conforme necessário (por exemplo, redirecionar novamente para um URL específico do usuário)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Etapa 2: o HubSpot solicita o consentimento ao usuário"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubSpot exibe uma janela de consentimento para o usuário mostrando o nome do aplicativo e uma descrição resumida dos serviços de API do HubSpot a que está solicitando permissão de acesso. O usuário pode conceder acesso ao aplicativo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/%5BExample%5D%20Install%20Screen.png",
        alt: "[Exemplo] Tela de instalação"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Observação:"
      }), " o usuário que está instalando o aplicativo deve ter acesso a todos os escopos solicitados. Se ele não tiver o acesso exigido, a instalação falhará e ele será direcionado a uma página de erro. Se esta página de erro de permissões for exibida para um usuário, ele precisará ser um Superadministrador para instalar o aplicativo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Neste estágio, seu aplicativo não faz nada. Assim que o acesso for concedido, o servidor OAuth 2.0 da HubSpot enviará uma solicitação para o URI de retorno definido no URL de autorização."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Etapa 3: tratar a resposta do servidor OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois que o usuário concluir a solicitação de consentimento na Etapa 2, o servidor OAuth 2.0 enviará uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para o URI especificado em seu URL de autenticação. Se não houver problemas e o usuário aprovar a solicitação de acesso, a solicitação para o URI de redirecionamento será devolvida com um parâmetro Query de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "código"
      }), " anexado. Se o usuário não conceder acesso, nenhuma solicitação será enviada."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Exemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "app.get('/oauth-callback', async (req, res) => {\n  if (req.query.code) {\n    // Handle the received code\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Etapa 4: trocar o código de autorização para tokens"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Após seu aplicativo receber um código de autorização do servidor OAuth 2.0, ele poderá trocar esse código para um token de acesso e atualização enviando uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " codificada por formulário para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/oauth/v1/token"
      }), " com os valores mostrados abaixo. Para obter informações mais detalhadas sobre essa etapa, reserve um tempo para ler este ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/oauth/tokens",
        children: "documento de referência"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Deve ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID do cliente de seu aplicativo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O segredo do cliente de seu aplicativo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7c3ce02c-0f0c-4c9f-9700-92440c9bdf2d"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URI de redirecionamento de quando o usuário autorizou seu aplicativo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O código de autorização recebido do servidor OAuth 2.0"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5771f587-2fe7-40e8-8784-042fb4bc2c31"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Exemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const formData = {\n  grant_type: 'authorization_code',\n  client_id: CLIENT_ID,\n  client_secret: CLIENT_SECRET,\n  redirect_uri: REDIRECT_URI,\n  code: req.query.code\n};\n\nrequest.post('https://api.hubapi.com/oauth/v1/token', { form: formData }, (err, data) => {\n  // Handle the returned tokens\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O corpo da resposta do token será dados JSON:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"refresh_token\": \"6f18f21e-a743-4509-b7fd-1a5e632fffa1\",\n  \"access_token\": \"CN2zlYnmLBICAQIYgZXFLyCWp1Yoy_9GMhkAgddk-zDc-H_rOad1X2s6Qv3fmG1spSY0Og0ACgJBAAADAIADAAABQhkAgddk-03q2qdkwdXbYWCoB9g3LA97OJ9I\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Observação:"
      }), " o token de acesso expirará após o número de segundos fornecido no campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " da resposta; atualmente 30 minutos. Para obter informações sobre como obter um novo token de acesso, consulte Atualizando tokens do OAuth 2.0."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usando tokens do OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Assim que o fluxo de código de autorização for concluído, seu aplicativo estará autorizado a fazer solicitações em nome do usuário. Para fazer isso, forneça o token como um token bearer no cabeçalho HTTP de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Autorização"
      }), " . Detalhes específicos podem ser encontrados no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/oauth/tokens",
        children: "documento de referência"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Exemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "request.get(\n  'https://api.hubapi.com/contacts/v1/lists/all/contacts/all?count=1',\n  {\n    headers: {\n      Authorization: `Bearer ${ACCESS_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " os tokens de acesso refletem os escopos solicitados ao aplicativo e não refletem as permissões ou limitações do que um usuário pode fazer em sua conta da HubSpot. Por exemplo, se um usuário tiver permissões para visualizar apenas contatos de propriedade, mas autorizar uma solicitação para o escopo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.read"
        }), ", o token de acesso resultante poderá visualizar todos os contatos na conta e não apenas aqueles de propriedade do usuário que está autorizando."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Atualizando tokens de OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os tokens de acesso do OAuth expiram periodicamente. A finalidade é garantir que, se ele estiver comprometido, os participantes só terão acesso por um curto período. O tempo de vida útil do token em segundos é especificado no campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " quando um código de autorização é trocado para um token de acesso."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Seu aplicativo pode trocar o token de atualização recebido por um novo token de acesso enviando uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/oauth/v1/token"
      }), " com os valores abaixo. Para obter informações mais detalhadas sobre essa etapa, consulte o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/oauth/tokens",
        children: "documento de referência"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemplo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Deve ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID do cliente de seu aplicativo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O segredo do cliente de seu aplicativo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7c3ce02c-0f0c-4c9f-9700-92440c9bdf2d"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URI de redirecionamento de quando o usuário autorizou seu aplicativo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O token de atualização recebeu quando o usuário autorizou seu aplicativo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "b9443019-30fe-4df1-a67e-3d75cbd0f726"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Exemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const formData = {\n  grant_type: 'refresh_token',\n  client_id: CLIENT_ID,\n  client_secret: CLIENT_SECRET,\n  redirect_uri: REDIRECT_URI,\n  refresh_token: REFRESH_TOKEN\n};\n\nrequest.post('https://api.hubapi.com/oauth/v1/token', { form: formData }, (err, data) => {\n  // Handle the returned tokens\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O corpo da resposta do token será dados JSON:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Sample response\n{\n  \"refresh_token\": \"6f18f21e-a743-4509-b7fd-1a5e632fffa1\",\n  \"access_token\": \"CN2zlYnmLBICAQIYgZXFLyCWp1Yoy_9GMhkAgddk-zDc-H_rOad1X2s6Qv3fmG1spSY0Og0ACgJBAAADAIADAAABQhkAgddk-03q2qdkwdXbYWCoB9g3LA97OJ9I\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O novo token de acesso pode ser usado para fazer chamadas em nome do usuário. Quando o novo token expirar, siga as mesmas etapas novamente para recuperar um novo token."
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/intro-to-auth",
        children: "Métodos de Autenticação no HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/working-with-oauth",
        children: "Trabalhando com o OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/api/oauth/tokens",
        children: "Gerenciar tokens"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}