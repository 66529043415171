"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492284323;
const slug = exports.slug = 'guides/cms/debugging/troubleshooting';
const title = exports.title = 'Debugging und Fehler';
const name = exports.name = 'EMEA DACH (de) Debugging and Errors_DE';
const metaDescription = exports.metaDescription = 'Verstehen, wie Fehler bei der Entwicklung von Websites im CMS von HubSpot CMS behoben und überprüfen werden. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/fatal%20error%20-%20CMS%20CLI.png';
const featuredImageAltText = exports.featuredImageAltText = 'Error publishing template';
const toc = exports.toc = [{
  "depth": 0,
  "id": "fehler",
  "label": "Fehler",
  "parent": null
}, {
  "depth": 1,
  "id": "schwerwiegende-fehler",
  "label": "Schwerwiegende Fehler",
  "parent": "fehler"
}, {
  "depth": 1,
  "id": "warnungen",
  "label": "Warnungen",
  "parent": "fehler"
}, {
  "depth": 0,
  "id": "debug-modus-auf-live-seiten",
  "label": "Debug-Modus auf Live-Seiten",
  "parent": null
}, {
  "depth": 0,
  "id": "entwickler-modus-im-seiten-editor",
  "label": "Entwickler-Modus im Seiten-Editor",
  "parent": null
}, {
  "depth": 0,
  "id": "hubl-ausgabe-anzeigen",
  "label": "HubL-Ausgabe anzeigen",
  "parent": null
}, {
  "depth": 0,
  "id": "%7Cpprint",
  "label": "|pprint",
  "parent": null
}, {
  "depth": 0,
  "id": "entwicklerinfo",
  "label": "Entwicklerinfo",
  "parent": null
}, {
  "depth": 0,
  "id": "website-performance-und-fehlerhafte-links-%C3%BCberpr%C3%BCfen",
  "label": "Website-Performance und fehlerhafte Links überprüfen",
  "parent": null
}, {
  "depth": 0,
  "id": "verbesserung-der-website-geschwindigkeit",
  "label": "Verbesserung der Website-Geschwindigkeit",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      code: "code",
      img: "img",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab,
      CTA
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Debugging und Fehler"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Debuggen von Code und das Verständnis dafür, wo und wie Fehler angezeigt werden können, ist ein wichtiger Teil der Entwicklung mit dem CMS von HubSpot. Es gibt eine Reihe von Tools, die Sie verwenden können, um die Effizienz beim Erstellen und Deguggen zu erhöhen und um sicherzustellen, dass Ihre Website beim Weiterentwickeln optimiert wird."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fehler"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/overview#developer-file-system",
        children: "CMS-Entwicklerdateisystem"
      }), " von HubSpot bietet viele Formen der Validierung, um sicherzustellen, dass Ihre Vorlagen und Module auf den Seiten korrekt dargestellt werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Schwerwiegende Fehler"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Schwerwiegende Fehler sind Fehler, die das erfolgreiche Rendern einer Seite verhindern würden. Um sicherzustellen, dass Live-Inhalte korrekt wiedergegeben werden, verhindert das CMS von HubSpot die Veröffentlichung von Vorlagen, die schwerwiegende Fehler aufweisen. Ein Beispiel für einen schwerwiegenden Fehler wäre das Fehlen erforderlicher HubL-Variablen, wie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), ". Dies führt zu Fehlern beim Entwickeln im Design-Manager oder beim Hochladen von Dateien über das CMS CLI. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://marketplace.visualstudio.com/items",
        children: "Die VS-Code-Erweiterung"
      }), " unterstützt HubL-Linting und kann die schwerwiegenden Fehler im Kontext anzeigen, bevor die Datei hochgeladen wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/fatal%20error%20-%20design%20manager.png",
        alt: "Screenshot eines schwerwiegenden Fehlers – Design-Manager"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/fatal%20error%20-%20CMS%20CLI.png",
        alt: "Screenshot eines schwerwiegenden Fehlers – CMS-CLI"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Schwerwiegende Fehler müssen behoben werden, um Dateien zu veröffentlichen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Warnungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Warnungen sind Fehler oder Probleme, die die Veröffentlichung von Dateien nicht verhindern. Warnungen sind oft Vorschläge zur Syntax oder zu möglichen Problemen, die ein Entwickler übersehen könnte. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://marketplace.visualstudio.com/items",
        children: "Die VS-Code-Erweiterung"
      }), " unterstützt HubL-Linting und kann die die Warnungen im Kontext anzeigen, bevor die Datei hochgeladen wird. Wenn Sie z. B. versuchen, eine Datei einzubinden, die nicht existiert, wird eine Warnung ausgegeben, um den Entwickler zu warnen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/warning%20-design%20manager.png",
        alt: "Warnung – Design-Manager"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Warnungen können die Veröffentlichung von Dateien nicht verhindern, es wird jedoch empfohlen, Warnungen zu untersuchen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Debug-Modus auf Live-Seiten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können den Debug-Modus auf einer Live-Seite aktivieren, indem Sie die Seite mit einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebug=true"
      }), "-Abfragezeichenfolge in der URL laden."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hinweis"
        }), " Der Debug-Modus wird auf ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#system-pages",
          children: "Systemseiten"
        }), ", z. B. 404- und Passwortseiten, nicht unterstützt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beim Laden einer Live-Seite mit dieser Abfragezeichenfolge wird die Seite gerendert:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "mit nicht minimierten Dateien."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "mit nicht kombinierten CSS-Dateien (einzelne CSS-Dateien werden bedient)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ohne Zwischenspeichern von Dateien."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine Seite mit ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebug=true"
      }), " laden, werden außerdem Debugging-Informationen am Ende des Seitenquellcodes hinzugefügt, einschließlich:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ob die Seite ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "vorgerendert"
        }), " werden kann, und die Gründe, warum sie nicht vorgerendert werden kann."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Eine Aufschlüsselung des Timings der Rendering-Anfrage, die hilfreich sein kann, um zu wissen, welche Seitenkomponenten länger dauern. Diese Aufschlüsselung wird auch der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "„Timing“"
        }), " in der Entwicklerkonsole Ihres Browsers unter ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Doc"
        }), "-Anfragen hinzugefügt."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/timing-tab-request-details.png",
        alt: "timing-tab-request-details"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fehler und Warnungen, wie HubL-Funktionseinschränkungen oder fehlende Dateien."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/debug-page-source-elements-tab.png",
        alt: "debug-page-source-elements-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Entwickler-Modus im Seiten-Editor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können den Seiten-Editor in HubSpot auch mit der Abfragezeichenfolge laden, um Entwicklerfunktionen zu aktivieren, z. B. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#copy-section-hubl",
        children: "Abschnitte als HubL kopieren"
      }), " und die Möglichkeit, bestimmte Module im Design-Manager über den Seiten-Editor öffnen zu können."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie im Seiten-Editor den folgenden Parameter zur URL hinzu und drücken Sie dann die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eingabetaste"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?developerMode=true"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn die Seite neu geladen wird, befinden Sie sich jetzt im Entwicklermodus. Sie können den Entwicklermodus jederzeit beenden, indem Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Entwicklermodus beenden“"
        }), " klicken."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-top-navigation-bar.png",
        alt: "developer-mode-top-navigation-bar"
      }), "Während Sie sich im Entwicklermodus befinden, können Sie zum Code für ein bestimmtes Modul gehen, indem Sie auf das zugehörige Modul klicken und dann im Seitenleisten-Editor auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "„Im Design-Manager öffnen“"
      }), " klicken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-open-design-manager-for-module.png",
        alt: "developer-mode-open-design-manager-for-module"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können auch alle unveröffentlichten Änderungen auf den Standardinhalt der Vorlage zurücksetzen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Inhalt“"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie rechts neben dem Vorlagennamen auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Content zurücksetzen“"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-reset-contents.png",
        alt: "developer-mode-reset-contents"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im Dialogfeld auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Ja, zurücksetzen“"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubL-Ausgabe anzeigen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Innerhalb des Design-Managers haben Code-Dateien den Schalter „Ausgabe anzeigen“, der einen zweiten Code-Editor-Bereich mit einem konvertierten Code der Datei, die Sie betrachten, öffnet. Dies ist hilfreich, um zu sehen, wie Ihr HubL-Code in CSS, HTML oder JavaScript konvertiert wird, anstatt die Live-Seiten, in denen die Datei enthalten ist, neu zu laden. Es ist auch ein hilfreiches Tool, um neue Funktionen von HubL zu erkunden oder die Grundlagen von HubL zu erlernen, da Sie leicht sehen können, was Ihre HubL-Eingabe ausgeben wird."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/View%20HubL%20output.png",
        alt: "HubL-Ausgabe anzeigen"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h2, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "|pprint"
      }), "-HubL-Filter"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der HubL-Filter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|pprint"
      }), " kann für HubL-Variablen verwendet werden, um wertvolle Debugging-Informationen zu drucken. Sie gibt den Typ der HubL-Variable aus, was nützlich sein kann, um zu verstehen, mit welchen Ausdrücken, Filtern, Operatoren oder Funktionen sie verwendet werden kann."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zum Beispiel gibt ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{%{{ local_dt }}endraw %}"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2020-02-21 12:52:20"
      }), " aus. Wenn wir diese Variable als pretty print liefern, können wir sehen, dass der Wert ein Datum ist: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "(PyishDate: 2020-02-21 12:52:20)"
      }), ". Das bedeutet, dass wir HubL-Filter verwenden können, die Datumsobjekte bearbeiten oder formatieren, z. B. den HubL-Filter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|datetimeformat"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ local_dt }}\n{{ local_dt|pprint }}\n{{ local_dt|datetimeformat('%B %e, %Y') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2020-02-21 12:55:13 (PyishDate: 2020-02-21 12:55:13) February 21, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Entwicklerinfo"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Viele der in den Entwicklerinformationen enthaltenen Daten werden intern verwendet und können sich ändern, wenn sie nicht anderweitig dokumentiert sind."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Entwicklerinformation für eine Seite ist der Kontext aller Daten, die verfügbar sind, wenn eine Seite gerendert wird. Dieser Rendering-Kontext ist überall über HubL zugänglich. Um auf die Entwicklerinformationen für eine Seite zuzugreifen, wählen Sie das ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubSpot-Symbol in der oberen rechten Ecke der Live-Seiten und dann „Entwicklerinfo“."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Developer%20info%20sprocket%20menu.png",
        alt: "HubSpot-Menü „Entwicklerinfo“"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dadurch wird eine neue Registerkarte geöffnet, die den Rendering-Kontext für eine bestimmte Seite in Form von JSON zurückgibt. Es wird empfohlen, einen JSON-Formatter in Ihrem Browser zu installieren, um die Entwicklerinformationen leichter lesen zu können, z. B. diese ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://chrome.google.com/webstore/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa",
        children: "Chrome-Erweiterung eines JSON-Formatter"
      }), ". Obwohl viele der im Seitenkontext enthaltenen Informationen für interne Zwecke bestimmt sind, kann dieses Tool nützlich sein, um zu sehen, welche Daten bei der Erstellung von Vorlagen über HubL verfügbar sind."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das folgende Bild zeigt zum Beispiel die Entwicklerinformationen für ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "https://desigers.hubspot.com/docs/developer-reference/cdn"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Developer%20info%20example.png",
        alt: "Beispiel für Entwicklerinformationen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Werte dieser Daten werden auf der Registerkarte „Einstellungen“ des Content-Editors festgelegt:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/content%20editor%20-%20settings.png",
        alt: "Content-Editor – Einstellungen"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Werte sind dann über HubL zum Rendern auf Seiten zugänglich. Um den Titel und die Meta-Beschreibung in einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "Basisvorlage"
      }), " zu drucken, würden Sie den folgenden HubL-Code verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<title>{{ page_meta.html_title }}</title>\n<meta name=\"description\" content=\"{{ page_meta.meta_description }}\">\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Daten im Rendering-Kontext sind über HubL verfügbar, und der JSON-Baum kann mit der Punktnotation durchlaufen werden. Zu den Daten in den Entwicklerinformationen, die Entwickler häufig drucken, gehören Modulfeldwerte und Tags, die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: "in den Vorlagenkontext exportiert wurden"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Website-Performance und fehlerhafte Links überprüfen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es muss unbedingt sichergestellt sein, dass die Besucher Ihrer Website nicht auf fehlerhafte Links geleitet werden. Es gibt zwei Tools, mit denen Sie sicherstellen können, dass Ihre Website-Besucher an die richtige Stelle gelangen. Sie können die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/deprecated/overview",
        children: "Website-Performance-API"
      }), " verwenden, um HTTP-Statusmeldungen wie 404-Fehler zu erhalten und die Betriebszeit Ihrer Website anzuzeigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie 404-Fehler sehen, sollte der Besucher zu einer relevanten URL umgeleitet werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können auch das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/seo/view-seo-recommendations-in-hubspot",
        children: "Tool für SEO-Empfehlungen"
      }), " verwenden, um fehlerhafte Links in Ihrem Seiteninhalt zu identifizieren und schnell zu beheben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verbesserung der Website-Geschwindigkeit"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beim Optimieren und Testen der Website-Geschwindigkeit spielen viele Faktoren eine Rolle. Tools und Tipps zur Optimierung der Geschwindigkeit Ihrer Website finden Sie in unserem Leitfaden."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "841bbe22-70a6-4222-a6f0-85dd019711ae",
      label: "Optimize your CMS Hub site speed"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}