"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 2143974321;
const slug = exports.slug = 'guides/api/automation/create-manage-workflows';
const title = exports.title = 'Workflows API Overview | Workflows API';
const name = exports.name = 'Workflows API Overview';
const metaDescription = exports.metaDescription = 'Workflows are one of the most essential pieces to the HubSpot marketing software, because it, along with Contacts and email, is at the heart of marketing automation. Using workflows, users can set up sophisticated marketing automation campaigns that rely on triggering relevant and timely actions (like emails) for contacts in their database.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "using-the-workflows-api",
  "label": "Using the Workflows API",
  "parent": null
}, {
  "depth": 0,
  "id": "supported-actions",
  "label": "Supported Actions",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Workflows API Overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use workflows to automate your marketing, sales, and service processes. Set enrollment criteria to enroll specific records or manually enroll your contacts. Add actions for your contacts, companies, deals, quotes, and tickets. You can also take action on associated records. For example, updating an enrolled contact’s associated company."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To test the workflows tool or the Workflows API, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "sign up for a developer portal"
      }), ". This will give you access to a sandboxed instance of the tool. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/chat-and-automation/topics#workflows",
        children: "creating and using workflows"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please Note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["This API is currently in ", (0, _jsxRuntime.jsx)("u", {
            children: "maintenance mode;"
          }), " there will not be any updates to the API and many features are not supported. For a list of supported actions, refer to the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#supported-actions",
            children: "Supported Actions table"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["The Workflows API currently only supports ", (0, _jsxRuntime.jsx)("u", {
            children: "contact-based"
          }), " workflows."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Using the Workflows API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The Workflows API allows for full CRUD access to any workflow in a given portal. The most common use case for the API is to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/create-manage-workflows/v3#create-a-workflow",
        children: "create a workflow"
      }), " for marketing automation. Then, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/create-manage-workflows/v3#create-a-workflow",
        children: "enroll contacts"
      }), " in the workflow based on triggers in the external application."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/methods/workflows/webhook_information",
        children: "webhooks in workflows"
      }), " to trigger notifications to external applications.. If polling the API becomes too much of a hassle while developing an integration with HubSpot, this is a great alternative."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For questions about using the Workflows API, connect with HubSpot's product team and other developers working in HubSpot on the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "HubSpot Developer's Forum."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supported Actions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When using this API, only certain actions are supported. Any actions not in the following list will be considered an UNSUPPORTED_ACTION."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/automation/create-manage-workflows#create-a-workflow",
          children: "Create a workflow API"
        }), ", if an UNSUPPORTED_ACTION is included, the workflow will not be created."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/automation/create-manage-workflows#get-a-workflow",
          children: "Get workflow API"
        }), ", if unsupported actions are included, the action type returned will be UNSUPPORTED_ACTION."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A list of supported actions can be found below:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Definition"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ADD_SUBTRACT_PROPERTY"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Increase or decrease a value in a number type property for the enrolled contact."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "COPY_COMPANY_PROPERTY"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Copy a property value from the enrolled contact's associated company to another property in the same contact."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "COPY_PROPERTY"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Copy a property value of the enrolled contact to another property in the same record."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CREATE_SFDC_TASK"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Set a customized Salesforce task for the enrolled contact."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DEAL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create a new deal record. This deal will be associated with the enrolled contact."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "EMAIL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Send marketing emails that have been saved for automation to the enrolled contact."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "LEAD_ASSIGNMENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Assign the enrolled contacts to users equally within a selected team or between specified users. This action is only compatible with activated, paid users."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "NOTIFICATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Send internal marketing emails that have been saved for automation to specified email addresses, or a contact property that stores an email address, including any custom properties."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "NOTIFICATION_STATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Send an in-app notification to specified teams or users."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SET_COMPANY_PROPERTY"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Set a property value on the enrolled contact's associated company."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SET_CONTACT_PROPERTY"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Set a property value on the enrolled contact."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SET_SALESFORCE_CAMPAIGN_MEMBERSHIP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Set a Salesforce campaign for the enrolled contact."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SMS_NOTIFICATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Send a text message to a HubSpot user or specified number."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "TASK"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create a new task. This task will be associated with the enrolled contact."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "TICKET"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Create a new ticket record. This ticket will be associated with the enrolled contact."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UPDATE_EMAIL_SUBSCRIPTION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Customize the subscription status of the enrolled contact."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UPDATE_LIST"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Add or remove contacts from a static list."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "WEBHOOK"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Trigger a webhook to an external application."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "WORKFLOW_ENROLLMENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enroll the contact in another workflow."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}