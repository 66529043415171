"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 43178489217;
const slug = exports.slug = 'reference/cms/hubl/if-statements';
const title = exports.title = 'if文';
const name = exports.name = 'APAC JAPAN (JA) | [new] if statements | 202104';
const metaDescription = exports.metaDescription = 'HubSpotのHubL if文に関する、CMS開発者向けの実践的なガイド。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "if%E6%96%87%E3%81%AE%E5%9F%BA%E6%9C%AC%E6%A7%8B%E6%96%87",
  "label": "if文の基本構文",
  "parent": null
}, {
  "depth": 0,
  "id": "elif%E3%81%A8else%E3%81%AE%E4%BD%BF%E7%94%A8",
  "label": "elifとelseの使用",
  "parent": null
}, {
  "depth": 0,
  "id": "unless%E6%96%87",
  "label": "unless文",
  "parent": null
}, {
  "depth": 0,
  "id": "ifchanged",
  "label": "ifchanged",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%B3%E3%83%A9%E3%82%A4%E3%83%B3if%E6%96%87",
  "label": "インラインif文",
  "parent": null
}, {
  "depth": 0,
  "id": "%E4%B8%89%E9%A0%85%E6%BC%94%E7%AE%97%E5%AD%90",
  "label": "三項演算子",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "if文"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールやテンプレートに条件付きロジックを含めるには、HubLの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#basic-if-statement-syntax",
        children: "if文"
      }), "と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#unless-statements",
        children: "unless文"
      }), "を使用します。if文では、HubLで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "サポートされる演算子"
      }), "を使用して、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
        children: "式の検証"
      }), "を行うことができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**Eメールモジュールの条件文の中で", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#personalization-token",
          children: "パーソナライズトークン"
        }), "を使用する場合、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
          children: "モジュールのプログラマブルEメールをオンにする"
        }), "必要があります。"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/marketing/emails/transactional-emails#single-send-api",
          children: "v3"
        }), "または", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/marketing/emails/single-send-api",
          children: "v4"
        }), "の1回送信APIを介して渡される情報は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if"
        }), "文の中では機能しません。これは、情報が入る前にテンプレートがコンパイルされるためです。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "if文の基本構文"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubLでは、テンプレートのロジックの構築にif文を使用します。HubLのif文の構文は、Pythonの条件付きロジックと似ています。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "文は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "文の区切り文字"
      }), "に囲まれ、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "文で始まり", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "で終わります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "if文の基本構文の例を以下に示します。「condition」は、trueまたはfalseを評価するブール値の条件で置き換えます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if condition %}\n\tIf the condition is true print this to template.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["基本構文の次は、基本的なif文の実例を示します。以下の例に示すif文では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_module"
      }), "という名前のHubLモジュールと", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_module"
      }), "という名前の変数がテンプレートに含まれているかどうかをチェックしています。演算子を指定しないif文では、テンプレートのコンテキストにおいてモジュールが定義されているかどうかが評価されることに注意してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"my_module\" path=\"@hubspot/rich_text\", label=\"My rich text module\", html=\"Default module text\" export_to_template_context=true %}\n\n{% if widget_data.my_module %}\n\tA module named \"my_module\" is defined in this template.\n{% endif %}\n\n\n{% set my_variable = \"A string value for my variable\" %}\n{% if my_variable %}\n\tThe variable named my_variable is defined in this template.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubLモジュールの評価時には", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "文内のモジュール名が引用符で囲まれている一方で、変数の検証時には変数名が引用符で囲まれていない点に注意してください。上記の例では、モジュールも変数もテンプレート上に存在するため、文が評価された結果、マークアップが出力されます。これらの例では、モジュールと変数が定義済みかどうかだけが確認され、モジュールと変数に値が含まれているかどうかは確認されないことに注意してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["次に、モジュールがテンプレート上に存在しているかどうかではなく、モジュールに値が含まれているかどうかを評価する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "文について説明します。この場合は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: "export_to_template_context"
      }), "パラメーターを使用する必要があります。以下の例では、コンテンツエディター上でテキストモジュールに値が割り当てられている場合に、マークアップが出力されます。モジュールのテキストフィールドがクリアされている場合は、マークアップがレンダリングされません。カスタムモジュールについては、簡略化された", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.widget_name"
      }), "構文があります。この構文については", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "この例"
      }), "で説明しています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"product_names\" path=\"@hubspot/text\", label=\"Enter the product names that you would like to render the coupon ad for\", value=\"all of our products\", export_to_template_context=True %}\n\n{% if widget_data.product_names.value %}\n<div class=\"coupon-ad\">\n<h3>For a limited time, get 50% off {{ widget_data.product_names.value}}! </h3>\n</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"coupon-ad\">\n  <h3>For a limited time get 50% off all of our products!</h3>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "elifとelseの使用"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["追加の条件文や、1つ以上の条件がfalseの場合に実行されるルールを使用した高度な", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "文を作成できます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "文を使用すると、直前の条件の後に評価される条件をロジックに追加できます。**", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "**文は、他の全ての条件がfalseの場合に実行されるルールを定義します。1つのif文の中で使用できる", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "文の数に制限はありませんが、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "文の使用は1つに限られます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#comparison",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "<="
        }), "演算子"]
      }), "を使用して変数の値をチェックするif文の基本構文の例を以下に示します。この例では、テンプレートで「Variable named number is less than or equal to 6.（変数numberは6以下です）」と出力されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set number = 5 %}\n\n{% if number <= 2 %}\n\tVariable named number is less than or equal to 2.\n{% elif number <= 4 %}\n\tVariable named number is less than or equal to 4.\n{% elif number <= 6 %}\n\tVariable named number is less than or equal to 6.\n{% else %}\n\tVariable named number is greater than 6.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["もう1つ、choiceモジュールを使用して、ユーザーが選択した部門に従って経歴ページの見出しをレンダリングする例を紹介します。この例では、choiceモジュールの特定の事前定義値のチェックに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#comparison",
        children: "== 演算子"
      }), "を使用しています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% choice \"department\" label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?!</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unless文"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), "文は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "文と似た条件文ですが、if文とは逆のロジックとして機能します。1つのブール値条件がtrueと評価されない場合に、初めのタグと終わりのタグで囲まれたコードがレンダリングおよびコンパイルされます。unless文は**", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "で始まり"
      }), (0, _jsxRuntime.jsx)(_components.code, {
        children: "endunless"
      }), "で終わります。**", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), "文では", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "を使用できますが、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "は使用できません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の例では、リッチ テキスト フィールドに値が割り当てられていない場合に「Under construction」（準備中）という見出しが出力されます。リッチ テキスト フィールドに値が含まれている場合は、その値が表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"my_page_content\" path=\"@hubspot/rich_text\", label=\"Enter your page content\", html=\"\" export_to_template_context=true %}\n\n{{ widget_data.my_page_content.html }}\n\n{% unless widget_data.my_page_content.html %}\n<h1>This page is under construction.</h1>\n<h3>Come back soon!</h3>\n{% endunless %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ifchanged"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubLではif文とunless文の他に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ifchanged"
      }), "文があります。この文は、このタグを前回呼び出した後で特定の変数が変更された場合にだけマークアップをレンダリングする場合に使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "インラインif文"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubLではインライン", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "文がサポートされています。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "演算子と式の検証"
      }), "による簡潔な条件ロジックを作成する際に使用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set color = \"Blue\" if is_blue is truthy else \"Red\" %}     // color == \"blue\"\n\n{{ \"Blue\" if is_blue is truthy else \"Red\" }}     // \"Blue\"\n\n{% set dl = true %}\n<a href=\"http://example.com/some.pdf\" {{\"download\" if dl }} >Download PDF</a>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "三項演算子"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#logical",
        children: "演算子と式の検証"
      }), "による条件文を簡単に記述する際には、三項演算子を使用することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// If the variable is_blue is true, output \"blue\", otherwise output\"red\"\n{{ is_blue is truthy ? \"blue\" : \"red\" }}\n\n// Set the variable is_red to false if is_blue is true, otherwise set to true\n{% set is_red = is_blue is truthy ? false : true %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}