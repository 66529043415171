"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42761605796;
const slug = exports.slug = 'guides/api/overview';
const title = exports.title = 'So verwenden Sie APIs von HubSpot';
const name = exports.name = "EMEA DACH (DE) vNext Docs DP - How to use HubSpot's APIs";
const metaDescription = exports.metaDescription = 'Die HubSpot-API ermöglicht Entwicklern die Erstellung benutzerdefinierter Apps und Integrationen. Legen Sie los, indem Sie sich unsere API-Übersicht durchlesen und einen Entwickler-Account erstellen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "erstellen-von-apps",
  "label": "Erstellen von Apps",
  "parent": null
}, {
  "depth": 1,
  "id": "%C3%B6ffentliche-apps",
  "label": "Öffentliche Apps",
  "parent": "erstellen-von-apps"
}, {
  "depth": 1,
  "id": "private-apps",
  "label": "Private Apps",
  "parent": "erstellen-von-apps"
}, {
  "depth": 0,
  "id": "authentifizierung",
  "label": "Authentifizierung",
  "parent": null
}, {
  "depth": 0,
  "id": "nutzung-und-beschr%C3%A4nkungen",
  "label": "Nutzung und Beschränkungen",
  "parent": null
}, {
  "depth": 0,
  "id": "app-partner-und-der-app-marketplace",
  "label": "App-Partner und der App Marketplace",
  "parent": null
}, {
  "depth": 0,
  "id": "erfahren-sie-in-der-hubspot-academy%2C-wie-sie-apis-verwenden-und-apps-erstellen.",
  "label": "Erfahren Sie in der HubSpot Academy, wie Sie APIs verwenden und Apps erstellen.",
  "parent": null
}, {
  "depth": 2,
  "id": "hilfreiche-links",
  "label": "Hilfreiche Links",
  "parent": "erfahren-sie-in-der-hubspot-academy%2C-wie-sie-apis-verwenden-und-apps-erstellen."
}, {
  "depth": 2,
  "id": "verwandte-dokumente",
  "label": "Verwandte Dokumente",
  "parent": "erfahren-sie-in-der-hubspot-academy%2C-wie-sie-apis-verwenden-und-apps-erstellen."
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API-Entwickleranleitungen & -ressourcen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot-APIs, für die Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "Referenzdokumentation hier finden"
      }), ", ermöglichen das schnelle und einfache Erstellen einer funktionierenden App oder Integration. Hier finden Sie eine Übersicht darüber, was Sie zu deren Verwendung benötigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erstellen von Apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bevor Sie loslegen, sollten Sie entscheiden, welche Art von App Sie erstellen möchten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Öffentliche Apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie eine App erstellen möchten, die andere HubSpot-Benutzer außerhalb Ihrer Organisation installieren, z. B. eine im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/de/marketplace/apps",
        children: "App Marketplace"
      }), " gelistete App, sollten Sie eine öffentliche App erstellen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie eine öffentliche App erstellen, müssen Sie einen App-Entwickler-Account erstellen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigieren Sie zuerst zu ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/get-started",
          children: "dieser Seite"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App-Entwickler-Account erstellen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Authentifizieren Sie sich mit Ihrem Google- oder Microsoft-Konto oder geben Sie Ihre E-Mail-Adresse ein."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Befolgen Sie weiterhin die Installationsanweisungen, um Ihren Entwickler-Account zu erstellen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dort können Sie dann eine neue App erstellen, OAuth konfigurieren und eine Testumgebung erstellen. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview",
        children: "Legen Sie jetzt mit dem Erstellen los."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Private Apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Ihr Ziel darin besteht, eine Integration zu erstellen, die nur von anderen Benutzern in Ihrem HubSpot-Account genutzt wird, z. B. eine interne App, die von Ihrem Account aus auf Kontaktdaten zugreifen oder diese ändern kann, können Sie eine private App erstellen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie in diesem Artikel mehr über das Erstellen einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "privaten App"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Authentifizierung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die meisten HubSpot-API-Endpunkte unterstützen sowohl ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " als auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "Zugriffstoken von privaten Apps"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Seit dem 30. November 2022 sind HubSpot-API-Schlüssel veraltet und werden nicht mehr unterstützt. Die fortgesetzte Verwendung von HubSpot API-Schlüsseln stellt ein Sicherheitsrisiko für Ihren Account und Ihre Daten dar. Während dieser Phase bis zur endgültigen Einstellung kann HubSpot Ihren Schlüssel jederzeit deaktivieren."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Sie sollten sich stattdessen mit einem Zugriffstoken für private Apps oder über OAuth authentifizieren. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "diese Änderung"
        }), " und wie Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "API-Schlüssel-Integration migrieren"
        }), ", um stattdessen eine private App zu verwenden."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Wenn Sie Ihre App im HubSpot App Marketplace listen möchten oder zulassen wollen, dass mehrere Benutzer sie installieren, müssen Sie OAuth verwenden."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "private App"
        }), " erstellen, können Sie Aufrufe mithilfe des Zugriffstokens Ihrer App vornehmen, das auch OAuth implementiert."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Nutzung und Beschränkungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über unsere ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "Nutzungsrichtlinien"
      }), ", Beschränkungen und wie Sie die Nutzung Ihrer API-Aufrufe überprüfen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "App-Partner und der App Marketplace"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Überprüfen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "App-Listing-Voraussetzungen"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "erstellen Sie Ihr App-Listing"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erfahren Sie in der HubSpot Academy, wie Sie APIs verwenden und Apps erstellen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie in diesen kurzen (und kostenlosen!) ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/courses/integrating-with-hubspot-foundations",
        children: "Videos"
      }), " der HubSpot Academy mehr über die HubSpot-APIs, Entwickler-Accounts und wie Sie sowohl mithilfe von OAuth als auch API-Schlüsseln Aufrufe vornehmen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Hilfreiche Links"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/signup-v2/developers/step/join-hubspot",
            children: "Einen Entwickler-Account erstellen"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types#developer-test-accounts",
            children: "Einen Entwickler-Test-Account einrichten"
          }), ", um Ihre App zu installieren und API-Aufrufe zu testen."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Durch Abonnieren des ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/changelog",
            children: "Änderungsprotokolls"
          }), " auf dem neuesten Stand bleiben"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Sich in den ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
            children: "Foren der Entwickler-Community"
          }), " an Diskussionen beteiligen oder Fragen stellen"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Ein Mitglied unserer Entwickler-", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/slack/developer-slack",
            children: "Slack Community"
          }), " werden"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Verwandte Dokumente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "API-Referenzdokumentation"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}