"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29865107554;
const slug = exports.slug = 'guides/apps/authentication/validating-requests';
const title = exports.title = 'Webhooks | Validation des demandes';
const name = exports.name = 'vNext Docs DP - Validation des demandes par HubSpot';
const metaDescription = exports.metaDescription = "Profitez d'une vue d'ensemble de la validation des demandes provenant de HubSpot vers une intégration. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "valider-les-demandes-%C3%A0-l-aide-de-la-signature-de-demande-v1",
  "label": "Valider les demandes à l'aide de la signature de demande v1",
  "parent": null
}, {
  "depth": 0,
  "id": "exemples-de-signatures-de-demande-v1%C2%A0",
  "label": "Exemples de signatures de demande v1 :",
  "parent": null
}, {
  "depth": 0,
  "id": "valider-les-demandes-%C3%A0-l-aide-de-la-signature-de-demande-v2",
  "label": "Valider les demandes à l'aide de la signature de demande v2",
  "parent": null
}, {
  "depth": 1,
  "id": "exemple-pour-une-demande-get%C2%A0",
  "label": "Exemple pour une demande GET :",
  "parent": "valider-les-demandes-%C3%A0-l-aide-de-la-signature-de-demande-v2"
}, {
  "depth": 1,
  "id": "exemple-de-demande-post",
  "label": "Exemple de demande POST",
  "parent": "valider-les-demandes-%C3%A0-l-aide-de-la-signature-de-demande-v2"
}, {
  "depth": 0,
  "id": "valider-les-signatures-de-demande-v3",
  "label": "Valider les signatures de demande v3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      code: "code",
      a: "a",
      h2: "h2",
      strong: "strong",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Validation des demandes par HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour vérifier que les demandes que votre intégration reçoit de HubSpot proviennent bien de HubSpot, plusieurs en-têtes sont renseignés dans la demande. Vous pouvez utiliser ces en-têtes ainsi que les champs de la demande entrante pour vérifier la signature de la demande."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La méthode utilisée pour vérifier la signature dépend de la version de la signature :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour valider une demande à l'aide de la dernière version de la signature HubSpot, utilisez l'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature-V3"
        }), " et suivez les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests#validate-the-v3-request-signature",
          children: "instructions associées pour valider la version 3 de la signature"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour la rétrocompatibilité, les demandes de HubSpot incluent également les anciennes versions de la signature. Pour valider une ancienne version de la signature, consultez l'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature-Version"
        }), ", puis suivez les instructions associées ci-dessous selon la version ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v1"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v2"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans les instructions ci-dessous, découvrez comment obtenir une valeur de hachage à partir du secret client de votre application et des champs d'une demande entrante. Une fois que vous calculez la valeur de hachage, comparerez-la à la signature. Si les deux sont égales, la demande a été validée avec succès. Autrement, cette demande peut avoir été modifiée en transit ou quelqu'un peut usurper les demandes à votre point de terminaison."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Valider les demandes à l'aide de la signature de demande v1"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si votre application est abonnée aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/webhooks/overview",
        children: "événements d'objet CRM via l'API des webhooks"
      }), ", les demandes de HubSpot seront envoyées avec l'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-Version"
      }), " défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v1"
      }), ". L'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " sera un hachage SHA-256 conçu à l'aide du secret de client de votre application associé à des détails de la demande."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour vérifier la version de cette signature, effectuez les étapes suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Créez une chaîne qui regroupe les éléments suivants : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Client secret"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request body"
        }), " (le cas échéant)."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Créez un hachage SHA-256 de la chaîne résultante."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Comparez la valeur de hachage à la valeur de l'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Signature"
        }), " :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si elle est égale, cette demande a été validée."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si ces valeurs sont différentes, cette demande peut avoir été modifiée en transit ou quelqu'un peut usurper les demandes à votre point de terminaison."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de demande avec un corps :"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Client secret : yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy\n// Request body: [\n{\"eventId\":1,\"subscriptionId\":12345,\"\nportalId\":62515\",\noccurredAt\":1564113600000\",\nsubscriptionType\":\"contact.creation\",\n\"attemptNumber\":0,\n\"objectId\":123,\n\"changeSource\":\"CRM\",\n\"changeFlag\":\"NEW\",\n\"appId\":54321}\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exemples de signatures de demande v1 :"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\n>>> import hashlib\n\n>>> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n>>> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n>>> source_string = client_secret + request_body\n>>> source_string\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n>>> hashlib.sha256(source_string).hexdigest()\n'232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de'\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\nirb(main):003:0> require 'digest'\n=> true\nirb(main):004:0> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n=> \"yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy\"\nirb(main):005:0> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n=> \"[{\\\"eventId\\\":1,\\\"subscriptionId\\\":12345,\\\"portalId\\\":62515,\\\"occurredAt\\\":1564113600000,\\\"subscriptionType\\\":\\\"contact.creation\\\",\\\"attemptNumber\\\":0,\\\"objectId\\\":123,\\\"changeSource\\\":\\\"CRM\\\",\\\"changeFlag\\\":\\\"NEW\\\",\\\"appId\\\":54321}]\"\nirb(main):006:0> source_string = client_secret + request_body\n=> \"yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\\\"eventId\\\":1,\\\"subscriptionId\\\":12345,\\\"portalId\\\":62515,\\\"occurredAt\\\":1564113600000,\\\"subscriptionType\\\":\\\"contact.creation\\\",\\\"attemptNumber\\\":0,\\\"objectId\\\":123,\\\"changeSource\\\":\\\"CRM\\\",\\\"changeFlag\\\":\\\"NEW\\\",\\\"appId\\\":54321}]\"\nirb(main):007:0> Digest::SHA256.hexdigest source_string\n=> \"232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de\"\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Node.js",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "NOTE: This is only an example for generating the expected hash.\nYou will need to compare this expected hash with the actual hash in the\nX-HubSpot-Signature header.\n\n> const crypto = require('crypto')\nundefined\n> client_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\n> request_body = '[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n'[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n> source_string = client_secret + request_body\n'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy[{\"eventId\":1,\"subscriptionId\":12345,\"portalId\":62515,\"occurredAt\":1564113600000,\"subscriptionType\":\"contact.creation\",\"attemptNumber\":0,\"objectId\":123,\"changeSource\":\"CRM\",\"changeFlag\":\"NEW\",\"appId\":54321}]'\n> hash = crypto.createHash('sha256').update(source_string).digest('hex')\n'232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le hachage résultant serait : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "232db2615f3d666fe21a8ec971ac7b5402d33b9a925784df3ca654d05f4817de"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Valider les demandes à l'aide de la signature de demande v2"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si votre application gère des données provenant d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows/how-do-i-use-webhooks-with-hubspot-workflows",
        children: "action de webhook dans un workflow"
      }), " ou si vous renvoyez des données pour une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "carte CRM personnalisée"
      }), ", la demande de HubSpot est envoyée avec l'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-Version"
      }), " défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "v2"
      }), ". L'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " sera un hachage SHA-256 conçu à l'aide du secret de client de votre application associé à des détails de la demande."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour vérifier cette signature, effectuez les étapes suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Créez une chaîne qui regroupe les éléments suivants : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Client secret"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "http method"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "URI"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "request body"
        }), " (le cas échéant)"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Créez un hachage SHA-256 de la chaîne résultante."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Comparer la valeur de hachage à la signature.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si elle est égale, cette demande a été validée."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si ces valeurs sont différentes, cette demande peut avoir été modifiée en transit ou quelqu'un peut usurper les demandes à votre point de terminaison."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Remarques :"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "L'URI utilisé pour créer la chaîne source doit correspondre exactement à la demande initiale, y compris le protocole. Si vous rencontrez des difficultés lors de la validation de la signature, assurez-vous que tous les paramètres de demande sont dans le même ordre que dans la demande initiale."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La chaîne source doit être encodée en UTF-8 avant de calculer le hachage SHA-256."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exemple pour une demande GET :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), ", vous aurez besoin du secret client de votre application et des champs spécifiques des métadonnées de votre demande. Ces champs sont répertoriés ci-dessous avec des valeurs d’espace réservé incluses :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Secret du client"
        }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Méthode HTTP"
        }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URI"
        }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.example.com/webhook_uri"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Corps de la demande : ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"\""
          })]
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La chaîne concaténée résultante serait : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyGEThttps://www.example.com/webhook_uri"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Après avoir calculé un hachage SHA-256 de la chaîne concaténée ci-dessus, la signature résultante que vous vous attendez à faire correspondre à celle de l’en-tête serait : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eee2dddcc73c94d699f5e395f4b9d454a069a6855fbfa152e91e88823087200e"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exemple de demande POST"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour une demande", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), ", vous aurez besoin du secret client de votre application, de champs spécifiques des métadonnées de votre demande et d’une représentation sous forme de chaîne du corps de la demande (par exemple : utilisation ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "JSON.stringify(request.body)"
      }), " pour un service Node.js). Ces champs sont répertoriés ci-dessous avec des valeurs d’espace réservé incluses :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Secret du client"
        }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Méthode HTTP"
        }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URI"
        }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.example.com/webhook_uri"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Corps de la demande : ** ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{\"example_field\":\"example_value\"}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La chaîne concaténée résultante serait : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"example_value\"}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Après avoir calculé un hachage SHA-256 de la chaîne concaténée ci-dessus, la signature résultante que vous vous attendez à faire correspondre à celle de l’en-tête serait : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "9569219f8ba981ffa6f6f16aa0f48637d35d728c7e4d93d0d52efaa512af7900"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Après [SHA-ing] la signature, vous pouvez comparer la signature attendue résultante à celle fournie dans l’en-tête x-hubspot-signature de la demande :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le bloc de code Node.js ci-dessous détaille comment vous pouvez incorporer la validation des demandes pour une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " si vous exécutiez un serveur Express pour gérer les demandes entrantes. Gardez à l’esprit que le bloc de code ci-dessous est un exemple et omet certaines dépendances dont vous pourriez avoir besoin pour exécuter un service Express complet. Confirmez que vous utilisez les dernières bibliothèques stables et sécurisées lors de la mise en œuvre de la validation des demandes pour votre service spécifique."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Introduce any dependencies. Only several dependencies related to this example are included below:\nconst express = require('express');\nconst bodyParser = require('body-parser');\nconst crypto = require('crypto');\nconst app = express();\n\n// Add any custom handling or setup code for your Node.js service here.\napp.use(bodyParser.urlencoded({ extended: false }));\napp.use(bodyParser.json());\n\n// Example Node.js request validation code.\napp.get('/example-service', (request, response, next) => {\n  const { url, method, headers, hostname } = request;\n\n  const requestSignature = headers['x-hubspot-signature'];\n\n  // Compute expected signature\n  const uri = `https://${hostname}${url}`;\n  const encodedString = Buffer.from(\n    `${process.env.CLIENT_SECRET}${method}${uri}`,\n    'ascii'\n  ).toString('utf-8');\n  const expectedSignature = crypto\n    .createHash('sha256')\n    .update(encodedString)\n    .digest('hex');\n\n  console.log('Expected signature: %s', requestSignature);\n  console.log('Request signature: %s', expectedSignature);\n\n  // Add your custom handling to compare request signature to expected signature\n  if (requestSignature !== expectedSignature) {\n    console.log('Request of signature does NOT match!');\n    response.status(400).send('Bad request');\n  } else {\n    console.log('Request of signature matches!');\n    response.status(200).send();\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Valider les signatures de demande v3"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-v3"
      }), " sera un hachage HMAC SHA-256 conçu à l'aide du secret de client de votre application associé à des détails de la demande. Il comprendra également un en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Request-Timestamp"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors de la validation d'une demande à l'aide de l'en-tête X-HubSpot-Signature-v3, vous devrez"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rejeter la demande si l'horodatage est supérieur à 5 minutes."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Dans l'URI de demande, décoder les caractères encodés en URL répertoriés dans le tableau ci-dessous. Vous n'avez pas besoin de décoder le point d'interrogation qui indique le début de la chaîne de demande."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Valeur encodée"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Valeur décodée"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3A"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ":"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2F"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3F"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "?"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%40"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "@"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%21"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "!"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%24"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "$"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%27"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "'"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%28"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "("
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%29"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ")"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2A"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "*"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%2C"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ","
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "%3B"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: ";"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Créez une chaîne encodé en UTF-8 qui regroupe les éléments suivants : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestMethod"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestUri"
        }), " + ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "requestBody"
        }), " + horodatage. L'horodatage est fourni par l'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "X-HubSpot-Request-Timestamp"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Créez un hachage HMAC SHA-256 de la chaîne résultante en utilisant le secret d'application comme secret pour la fonction HMAC SHA-256."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Encoder le résultat de la fonction HMAC en Base64."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Comparer la valeur de hachage à la signature. Si elle est égale, cette demande a été validée comme provenant de HubSpot. Il est recommandé d'utiliser une comparaison de chaîne à durée fixe pour éviter les attaques temporelles."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "#NOTE: This is only an example for generating the expected hash.\n# You will need to compare this expected hash with the actual hash in the\n# X-HubSpot-Signature header.\n\n# Using Python 3\nimport hashlib\n\nclient_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\nhttp_method = 'POST'\nhttp_uri = 'https://www.example.com/webhook_uri'\nrequest_body = '{\"example_field\":\"サンプルデータ\"}'\n\nsource_string = client_secret + http_method + http_uri + request_body\nprint('source_string: {}'.format(source_string))\n\nhash_result = hashlib.sha256(source_string.encode('utf-8')).hexdigest()\nprint('hash_result: {}'.format(hash_result))\n\n# output:\n# source_string: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n# hash_result: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "# NOTE: This is only an example for generating the expected hash.\n# You will need to compare this expected hash with the actual hash in the\n# X-HubSpot-Signature header.\n\nrequire 'digest'\n\nclient_secret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy'\nhttp_method = 'POST'\nhttp_uri = 'https://www.example.com/webhook_uri'\nrequest_body = '{\"example_field\":\"サンプルデータ\"}'\n\nsource_string = client_secret + http_method + http_uri + request_body\nputs \"source_string: %s\" % [source_string]\n\nhash_result = Digest::SHA256.hexdigest source_string\nputs \"hash_result: %s\" % [hash_result]\n\n# output:\n# source_string: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n# hash_result: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Node.js",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "// NOTE: This is only an example for generating the expected hash.\n// You will need to compare this expected hash with the actual hash in the\n// X-HubSpot-Signature header.\n\nconst crypto = require('crypto');\n\nclientSecret = 'yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyy';\nhttpMethod = 'POST';\nhttpURI = 'https://www.example.com/webhook_uri';\nrequestBody = '{\"example_field\":\"サンプルデータ\"}';\n\nsourceString = clientSecret + httpMethod + httpURI + requestBody;\nconsole.log('sourceString: ' + sourceString);\n\nhash = crypto.createHash('sha256').update(sourceString).digest('hex');\nconsole.log('hash: ' + hash);\n\n// output:\n// sourceString: yyyyyyyy-yyyy-yyyy-yyyy-yyyyyyyyyyyyPOSThttps://www.example.com/webhook_uri{\"example_field\":\"サンプルデータ\"}\n// hash: 373fa7e3af2ca3c1c71ea803f093405969e0336950a60b56ceaf54768dc6f090\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}