"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492355371;
const slug = exports.slug = 'guides/cms/content/content-search';
const title = exports.title = 'Búsqueda de contenido';
const name = exports.name = 'LATAM (ES) Content Search';
const metaDescription = exports.metaDescription = 'Utilizando la funcionalidad de búsqueda nativa del sitio dentro del CMS de HubSpot, Búsqueda de contenido. El CMS de HubSpot tiene incorporada la funcionalidad de búsqueda en el sitio para permitir a tus visitantes encontrar fácilmente el contenido que están buscando. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "buscando-contenido",
  "label": "Buscando contenido",
  "parent": null
}, {
  "depth": 0,
  "id": "implementaci%C3%B3n-de-la-b%C3%BAsqueda-en-tu-sitio-web",
  "label": "Implementación de la búsqueda en tu sitio web",
  "parent": null
}, {
  "depth": 1,
  "id": "entrada-de-b%C3%BAsqueda-en-el-sitio",
  "label": "Entrada de búsqueda en el sitio",
  "parent": "implementaci%C3%B3n-de-la-b%C3%BAsqueda-en-tu-sitio-web"
}, {
  "depth": 1,
  "id": "resultados-de-la-b%C3%BAsqueda-del-sitio",
  "label": "Resultados de la búsqueda del sitio",
  "parent": "implementaci%C3%B3n-de-la-b%C3%BAsqueda-en-tu-sitio-web"
}, {
  "depth": 0,
  "id": "plantilla-de-resultados-de-b%C3%BAsqueda",
  "label": "Plantilla de resultados de búsqueda",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFc%C3%B3mo-se-determina-la-precedencia-de-la-b%C3%BAsqueda%3F",
  "label": "¿Cómo se determina la precedencia de la búsqueda?",
  "parent": null
}, {
  "depth": 0,
  "id": "controlar-la-indexaci%C3%B3n-durante-el-desarrollo",
  "label": "Controlar la indexación durante el desarrollo",
  "parent": null
}, {
  "depth": 0,
  "id": "comportamiento-de-indexaci%C3%B3n-por-opci%C3%B3n-predeterminada",
  "label": "Comportamiento de indexación por opción predeterminada",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFc%C3%B3mo-puedo-excluir-p%C3%A1ginas-para-que-no-aparezcan-en-los-resultados-de-la-b%C3%BAsqueda%3F",
  "label": "¿Cómo puedo excluir páginas para que no aparezcan en los resultados de la búsqueda?",
  "parent": null
}, {
  "depth": 0,
  "id": "c%C3%B3mo-excluir-secciones-de-una-p%C3%A1gina-para-que-no-se-indexen-en-la-b%C3%BAsqueda",
  "label": "Cómo excluir secciones de una página para que no se indexen en la búsqueda",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      code: "code",
      pre: "pre",
      h3: "h3",
      img: "img",
      strong: "strong",
      ol: "ol",
      li: "li",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Búsqueda de contenido"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El CMS de HubSpot tiene integrada la funcionalidad de búsqueda en el sitio para permitir a tus visitantes encontrar fácilmente el contenido que están buscando. Todo tu contenido de HubSpot se indexa automáticamente en el motor de búsqueda nativo de HubSpot, eliminando la molestia de configurar una herramienta de búsqueda de terceros para tu sitio web."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Buscando contenido"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El motor de búsqueda es accesible a través de la api ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "API de búsqueda del sitio"
      }), ". Esta API admite numerosas opciones de filtrado para permitirte crear una experiencia de búsqueda altamente personalizada y potente en tu sitio web. Por ejemplo, si quieres construir una búsqueda en tu blog, puedes consultar por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type=BLOG_POST"
      }), " para que solo te devuelva las publicaciones del blog. O, si quieres construir la búsqueda en la versión española de tu sitio web, podrías consultar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "language=es"
      }), " para devolver solo las páginas en español."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La API devuelve JSON que puede ser analizado con JavaScript para mostrar los resultados en tu sitio web. Todos los tipos de contenido devolverán el dominio de la página, el título, la url y el idioma. La descripción devuelta es una muestra del texto del contenido que mejor se ajusta al término de búsqueda. Un elemento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<span class=\"hs-search-highlight hs-highlight-html\">"
      }), " envolverá perfectamente el texto coincidente, permitiéndote resaltar el texto coincidente con CSS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dependiendo del tipo de contenido buscado, los resultados devuelven una información ligeramente diferente, por lo que puede mostrar los resultados para tipos de contenido únicos de manera diferente. Por ejemplo, las publicaciones del blog devolverán información sobre las etiquetas que tiene la publicación, quién es el autor y cuándo se publicó."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// json\n{\n  \"total\": 850,\n  \"offset\": 0,\n  \"limit\": 10,\n  \"results\": [\n    {\n      \"id\": 3761238962,\n      \"score\": 0.30858153,\n      \"type\": \"SITE_PAGE\",\n      \"domain\": \"designers.hubspot.com\",\n      \"url\": \"https://developers.hubspot.com/docs/cms/search\",\n      \"language\": \"en\",\n      \"title\": \"Content Search\",\n      \"description\": \"Using HubSpot’s native search engine, content search, to implement <span class=\"hs-search-highlight hs-highlight-html\">search on your website</span>\"\n    },\n    {\n      \"id\": 3254581958,\n      \"score\": 0.30858153,\n      \"type\": \"BLOG_POST\",\n      \"domain\": \"designers.hubspot.com\",\n      \"url\": \"https://designers.hubspot.com/blog/getting-started-with-local-development\",\n      \"featuredImageUrl\":\"https://designers.hubspot.com/hubfs/local-development.jpg\"\n      \"title\": \"Getting Started with Local Development\",\n      \"description\":\"The beta Local Development Tooling connects your local workflow to HubSpot, allowing you to utilize <span class=\"hs-search-highlight hs-highlight-html\">version control</span>, your favorite text editor and various web development technologies when developing on the HubSpot CMS.\"\n      \"authorFullName\": \"William Spiro\",\n      \"tags\": [\n        \"Website Development\",\n        \"Local Development\"\n      ],\n      \"publishedDate\": 1447938000000\n    }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Implementación de la búsqueda en tu sitio web"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hay dos módulos por opción predeterminada que puedes utilizar para implementar fácilmente la búsqueda en tu sitio web, que utilizan las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "API de búsqueda del sitio"
      }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "search_input"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "search_results"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Entrada de búsqueda en el sitio"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un campo de entrada para que los visitantes introduzcan los términos de búsqueda. Este módulo puede existir en cualquier lugar de tu sitio web. Este módulo puede incluirse en una plantilla con ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% module \"search_input\" path=\"@hubspot/search_input\" %}"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_input\" path=\"@hubspot/search_input\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Resultados de la búsqueda del sitio"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Devuelve un listado de contenido basado en el término de búsqueda. Este módulo puede existir en cualquier lugar de tu sitio web. Este módulo puede incluirse en una plantilla con"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_results\" path=\"@hubspot/search_results\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si la funcionalidad de estos módulos no es la que deseas que funcione la búsqueda en tu sitio web, puedes crear tus propios módulos o funcionalidad de búsqueda. Los módulos de búsqueda por opción predeterminada están diseñados para ser ampliados en función de tus necesidades de búsqueda. Puedes ver y clonar estos módulos en el Administrador de diseño buscando sus nombres en el Finder o puedes recuperarlos en tu entorno local utilizando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI del CMS"
      }), " ejecutando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/search_input.module"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/search_results.module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/default%20search%20module.png",
        alt: "módulo de búsqueda por opción predeterminada"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Plantilla de resultados de búsqueda"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada dominio tiene su propia página de resultados de búsqueda por opción predeterminada. La plantilla y la ruta de esta página se establecen en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Configuración > Sitio web > Páginas"
      }), " en la pestaña ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/pages/all-domains/system-pages",
        children: "Páginas del sistema"
      }), " para dominios específicos. Consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/system/search-results.html",
        children: "plantilla de resultados de búsqueda del tema CMS"
      }), " como ejemplo. El dominio establecido para la página de resultados de búsqueda se conecta automáticamente a los módulos de búsqueda por opción predeterminada. Sin embargo, puedes usar la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/site-search",
        children: "API de búsqueda del sitio"
      }), " para crear tus resultados de búsqueda como quieras en cualquier página de tu sitio web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Cómo se determina la precedencia de la búsqueda?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El orden de los resultados de la búsqueda se determina por una serie de comparaciones ponderadas del contenido de la página con el término de búsqueda del visitante. El contenido de la página se separa en campos de comparación con una ponderación distinta según dónde reside el contenido dentro de las HTML de tus páginas. Los campos de comparación se agrupan en orden de ponderación:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Título HTML"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Metadescripción"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Elementos HTML H1"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Elementos HTML H2"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Elementos HTML H3"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Otros elementos HTML"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ten en cuenta que esta lista está sujeta a cambios."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si deseas indicar a nuestro indexador que potencie específicamente ciertos elementos de una página, puedes envolver el contenido en una clase ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-search-keyword"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Controlar la indexación durante el desarrollo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mientras se desarrolla un nuevo sitio, es útil poder probar la búsqueda en el sitio sin preocuparse de que éste sea indexado por motores de búsqueda como Google."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En tu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cos-general/customize-your-robots-txt-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        })
      }), " puedes decirle a HubSpot que rastree todo, mientras bloquea otros bots."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Block all bots\nUser-agent: *\nDisallow: /\n\n# Allow HubSpot Site Search Indexing\nUser-agent: HubSpotContentSearchBot\nAllow: /\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si alguna de tus páginas tiene una metaetiqueta que especifica que no se indexe, la página seguirá sin ser indexada, aunque se permita en el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Recuerda también revisar tu ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), " antes del lanzamiento para asegurarse de que todo se indexa como quieres."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comportamiento de indexación por opción predeterminada"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dado que el CMS conoce la intención de ciertos tipos de páginas, la búsqueda de contenido puede limitar de forma segura la indexación para permitir la indexación de páginas de tipo contenido. Ejemplos de páginas de contenido:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Páginas de sitio"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Páginas de destino"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Artículos de la base de conocimientos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "publicaciones de blog"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Las páginas del sistema y las páginas protegidas por contraseña no se indexan."
      }), " Las páginas restringidas de CMS Membership solo se mostrarán en la búsqueda de contenido de hubspot para los usuarios que hayan iniciado sesión y tengan acceso a las páginas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿Cómo puedo excluir páginas para que no aparezcan en los resultados de la búsqueda?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si bloqueas una página para que no sea indexada a los motores de búsqueda a través del archivo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cos-general/customize-your-robots-txt-file",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        })
      }), " de tu sitio web o a través de las ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "metaetiquetas"
      }), ", no se indexarán para la búsqueda del sitio."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En tu ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "robots.txt"
      }), " agrega un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "disallow"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "# Block all bots\nUser-agent: *\nDisallow: /path-to-page\n\n# Block just HubSpot Site Search Indexing\nUser-agent: HubSpotContentSearchBot\nDisallow: /path-to-page\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes agregar una metaetiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "NOINDEX, NOFOLLOW"
      }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " a nivel de página o plantilla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<meta name=\"ROBOTS\" content=\"NOINDEX, NOFOLLOW\" />\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No es necesario bloquear los robots utilizando tanto el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "robots.txt"
        }), " como la metaetiqueta. Hacerlo puede hacer que sea confuso después si decides permitir la indexación de una página."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cómo excluir secciones de una página para que no se indexen en la búsqueda"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A veces hay regiones de una página que no son útiles para aparecer en los resultados de búsqueda. Puede ser el contenido de un encabezado, un pie de página, una barra lateral, etc. En este caso, puedes agregar la clase ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs-search-hidden"
      }), " a tu HTML para que la búsqueda ignore el contenido de esas regiones."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}