"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77668268320;
const slug = exports.slug = 'guides/api/marketing/marketing-events';
const title = exports.title = 'Marketing-API | Marketingevents';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Marketing Events';
const metaDescription = exports.metaDescription = 'Eine Übersicht über das Marketingevents-Objekt in HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "in-this-article",
  "label": "In this article",
  "parent": null
}, {
  "depth": 0,
  "id": "anforderungen-an-den-bereich",
  "label": "Anforderungen an den Bereich",
  "parent": null
}, {
  "depth": 0,
  "id": "events-erstellen-und-aktualisieren",
  "label": "Events erstellen und aktualisieren",
  "parent": null
}, {
  "depth": 0,
  "id": "event-teilnahme-endpunkte",
  "label": "Event-Teilnahme-Endpunkte",
  "parent": null
}, {
  "depth": 0,
  "id": "app-einstellungen-konfigurieren",
  "label": "App-Einstellungen konfigurieren",
  "parent": null
}, {
  "depth": 1,
  "id": "schritt-1-eine-api-in-ihrer-app-erstellen",
  "label": "Schritt 1: Eine API in Ihrer App erstellen",
  "parent": "app-einstellungen-konfigurieren"
}, {
  "depth": 1,
  "id": "schritt-2-hubspot-den-url-pfad-zu-ihrer-api-bereitstellen",
  "label": "Schritt 2: HubSpot den URL-Pfad zu Ihrer API bereitstellen",
  "parent": "app-einstellungen-konfigurieren"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      hr: "hr",
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      code: "code",
      strong: "strong",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Marketingevents"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "„Marketingevents“ ist ein CRM-Objekt, ähnlich wie Kontakte und Unternehmen, mit dem Sie Marketingevents wie ein Webinar nachverfolgen und anderen HubSpot CRM-Objekten zuordnen können. Im Folgenden erfahren Sie mehr über die Zusammenarbeit mit der Marketingevent-API, um Marketingevents in eine App zu integrieren."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "In this article"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#scope-requirements",
          children: "Anforderungen an den Bereich"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#scope-requirements",
          children: "Event-Teilnahme-Endpunkte"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#configure-app-settings",
          children: "App-Einstellungen konfigurieren"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#step-1-create-an-api-in-your-app",
              children: "Schritt 1: Eine API in Ihrer App erstellen"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#step-2-provide-hubspot-with-the-url-path-to-your-api",
              children: "Schritt 2: HubSpot den URL-Pfad zu Ihrer API bereitstellen"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anforderungen an den Bereich"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine API-Anfrage an einen der Marketingevent-Endpunkte durchzuführen, sind die folgenden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "Bereiche"
      }), " erforderlich:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.marketing_events.read"
        }), ": erteilt die Berechtigung zum Abrufen von Marketingevent- und -Teilnahmedaten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.marketing_events.write"
        }), " Wenn Sie Marketingevent-Informationen erstellen, löschen oder ändern möchten."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie von Ihrer App durchgeführte Aufrufe authentifizieren, können Sie entweder das", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "Zugriffstoken einer privaten App"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth"
      }), " verwenden. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "Authentifizierungsmethoden"
      }), ". Für die vollständige Liste der verfügbaren Endpunkte klicken Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Events erstellen und aktualisieren"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Marketingevents erstellen oder aktualisieren, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/events/upsert"
      }), "-Endpunkt durchführen. Sie können alle ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), ", die den Events entsprechen, die Sie aktualisieren möchten, zusammen mit allen anderen Details Ihres Events (einschließlich Name, Startzeit und Beschreibung) im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), "-Feld Ihres Anfragetextes berücksichtigen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn bereits ein Marketingevent mit der angegebenen ID in Ihrer Anfrage vorhanden ist, wird es aktualisiert. Andernfalls wird ein neues Event erstellt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zum Beispiel würde die folgende Anfrage ein Event mit einer ID von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "4"
      }), " mit dem Namen „Virtueller Kochkurs“ erstellen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for POST request to /marketing/v3/marketing-events/events/upsert\n{\n  \"inputs\": [\n    {\n      \"customProperties\": [\n        {\n          \"name\": \"property1\",\n          \"value\": \"1234\"\n        }\n      ],\n      \"eventName\": \"Virtual cooking class\",\n      \"startDateTime\": \"2023-11-30T17:46:20.461Z\",\n      \"eventOrganizer\": \"Chef Joe\",\n      \"eventDescription\": \"Join us for a virtual cooking class! Yum.\"\n      \"eventCancelled\": false,\n      \"externalAccountId\": \"CookingCo\",\n      \"externalEventId\": \"4\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können eine vollständige Liste aller verfügbaren Felder überprüfen, die Sie in Ihre Anfrage aufnehmen können, indem Sie oben in diesem Artikel auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " klicken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Event-Teilnahme-Endpunkte"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Event-Teilnahmestatus-Endpunkte ermöglichen es Ihnen, einen Abonnementstatus zwischen einem HubSpot-Kontakt und einem Marketingevent zu protokollieren. Sie können beispielsweise diesen Endpunkt verwenden, um zu protokollieren, dass sich ein HubSpot-Kontakt für ein Marketingevent registriert hat."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt zwei Endpunkte, die Sie verwenden können, um den Teilnahmestatus eines Kontakts zu aktualisieren. Wenn Sie zuvor die Endpunkte ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/upsert"
      }), " oder", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/email-upsert"
      }), " verwendet haben, um den Status eines Teilnehmers zu aktualisieren, können Sie stattdessen die unten aufgeführten Endpunkte verwenden, um die gleiche Funktionalität beizubehalten und gleichzeitig die Teilnahmedauer des Kontakts in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/crm-setup/customize-activities-on-a-contact-company-deal-ticket-record-timeline",
        children: "Kontaktchronik"
      }), " auszufüllen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie die Kontakt-IDs von bestehenden Kontakten verwenden möchten:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " -Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/marketing/v3/marketing-events/attendance/{externalEventId}/{subscriberState}/create"
            }), " durch, indem Sie die ID des Events aus Ihrer externen Anwendung als ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalEventId"
            }), " verwenden."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Geben Sie im Anforderungstext ein ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), "-Objekt an, das die folgenden Felder enthält:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "interactionDateTime"
                }), ": Datum und Uhrzeit, zu dem Kontakt das Event abonniert hat."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "vid"
                }), ": die Kontakt-ID eines vorhandenen Kontakts."]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie die E-Mail-Adresse eines der Teilnehmer der Veranstaltung verwenden möchten:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/marketing/v3/marketing-events/attendance/{externalEventId}/{subscriberState}/email-create"
            }), " durch."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Geben Sie im Anforderungstext ein ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), "-Objekt an, das die folgenden Felder enthält:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "interactionDateTime"
                }), ": Datum und Uhrzeit, zu dem Kontakt das Event abonniert hat."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "email"
                }), ": die E-Mail-Adresse des Teilnehmers als Wert des E-Mail-Felds in einer Eingabe."]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Stimmt die von Ihnen angegebene E-Mail-Adresse nicht mit der Adresse eines vorhandenen Kontakts überein, wird ein neuer Kontakt erstellt."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Geben Sie für beide oben genannten Endpunkte die folgenden Werte für die entsprechenden Pfadparameter an:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), ": die ID des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/de/integrations/use-marketing-events#view-edit-and-analyze-marketing-events",
          children: "Marketingevents"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriberState"
        }), ": eine Enumeration, die dem neuen Teilnehmerstatus des Kontakts entspricht:"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "REGISTERED"
        }), ": gibt an, dass sich der HubSpot-Kontakt für das Event registriert hat."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "ATTENDED"
        }), ": gibt an, dass der HubSpot-Kontakt am Event teilgenommen hat. Wenn Sie den Status eines Kontakts auf ATTENDED aktualisieren, können Sie auch die Zeitstempel ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "joinedAt"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "leftAt"
        }), " als Parameter in den Anfragetext einfügen, der im ISO8601 Instant-Format angegeben ist."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELLED"
        }), ": gibt an, dass der HubSpot-Kontakt, der sich zuvor für das Event registriert hatte, seine Registrierung storniert hat."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald Ihr Event abgeschlossen ist, können Sie es als abgeschlossen markieren und die Teilnehmerkennzahlen (z. B. die Dauer der Teilnahme für alle Teilnehmer) berechnen, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/events/{externalEventId}/complete"
      }), " durchführen. Beachten Sie, dass diese Aktion ", (0, _jsxRuntime.jsx)("u", {
        children: "nicht rückgängig gemacht"
      }), " werden kann. Daher sollten Sie diesen Endpunkt erst aufrufen, wenn alle Änderungsevents bezüglich des Teilnehmerstatus bereits erfolgt sind."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Diese APIs sind idempotent, solange sich die ID des Kontakts und der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "interactionDateTime"
        }), "-Wert im Event nicht geändert haben. Auf diese Weise können Sie den Abonnentenstatus mehrmals auf sichere Weise festlegen, ohne dass HubSpot doppelte Events in den Marketingevent-Eigenschaften erstellt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "App-Einstellungen konfigurieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es ist ein gewisses Setup erforderlich, damit Marketingevents ordnungsgemäß mit HubSpot synchronisiert werden können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie HubSpot eine Änderung des Abonnentenstatus übermitteln (z. B. eine Registrierung oder ein storniertes Event), prüft HubSpot zunächst, ob ein Marketingevent für die angegebene Event-ID vorhanden ist. Ist dies nicht der Fall, ruft HubSpot den konfigurierten Endpunkt für Ihre App auf, um die Details des Marketingevents abzurufen, erstellt dann das Event in HubSpot und veröffentlicht die Änderung des Abonnentenstatus."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dies geschieht, um Ihnen die Arbeit zu erleichtern. Es wird jedoch weiterhin empfohlen, die Marketingevents selbst über die auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Endpunkte"
      }), " oben in diesem Artikel angegebenen CRUD-Methoden zu erstellen und sich nicht auf diese Funktionalität zu verlassen, um Ihre Marketingevents in HubSpot zu erstellen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Schritt 1: Eine API in Ihrer App erstellen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um dies zu unterstützen, verlangt HubSpot, dass jede App, die Marketingevents verwendet, eine API definiert, um Informationen zu einem bestimmtem Marketingevent abzurufen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Anforderungen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Akzeptiert:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "externalAccountId"
            }), ": ein Abfrageparameter, der die accountId des Kunden in der externen App angibt."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            }), ": ein Abfrageparameter, der die ID der HubSpot-Anwendung angibt, die die Event-Details anfordert. Dies ist dann die ID Ihrer App."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "externalEventId"
            }), ": ein Pfadparameter in der URL der Anfrage, der die ID des Events in der externen App angibt, zu dem HubSpot Details benötigt."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gibt zurück:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ein JSON-Objekt, das Details für das Marketingevent bereitstellt und die folgenden Felder enthält, die in der folgenden Tabelle aufgeführt sind:"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feldname"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Erforderlich"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Feldbeschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name des Marketingevents"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventOrganizer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name des Organisators des Marketingevents"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Beschreibt, um welchen Typ von Event es sich handelt. Zum Beispiel: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEBINAR"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONFERENCE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WORKSHOP"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startDateTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string($date-time)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Startdatum und die Startuhrzeit des Marketingevents."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endDateTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string($date-time)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das Enddatum und die Enduhrzeit des Marketingevents."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventDescription"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Beschreibung des Marketingevents."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine URL in der externen Event-Anwendung, in der das Marketingevent stattfindet."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventCancelled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "bool"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt an, ob das Marketingevent storniert wurde. Die Standardeinstellung ist ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot sendet auch einen", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-v3"
      }), "\\-Header, mit dem Sie überprüfen können, ob die Anfrage von HubSpot stammt. Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "Anfragesignaturen"
      }), ", um weitere Informationen zur Signatur zu erhalten und wie Sie diese validieren."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Schritt 2: HubSpot den URL-Pfad zu Ihrer API bereitstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie nun die API in Ihrer App erstellt haben, die ein Objekt zurückgibt, das die Details eines bestimmten Marketingevents enthält, müssen Sie HubSpot den URL-Pfad zu Ihrer API bereitstellen, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/{appId}/settings"
      }), " durchführen. Auf diese Weise kann HubSpot ermitteln, wie Anfragen an Ihre App gestellt werden müssen, um die Details eines Marketingevents abzurufen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Geben Sie im Text Ihrer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage über das Feld ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), " Ihre URL an. Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), " muss die folgenden beiden Voraussetzungen erfüllen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sie enthält eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "%s"
        }), "-Zeichensequenz enthalten, die HubSpot zum Ersetzen in der ID des Events (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), ") als Pfadparameter verwendet."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Es muss der vollständige Pfad zur API-Ressource sein, einschließlich des Präfixes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://"
        }), " und des Domain-Namens (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my.event.app"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie beispielsweise eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), " von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://my.event.app/events/%s"
      }), " konfigurieren und eine Anfrage zum Abrufen von Details eines Events mit der ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234-event-XYZ"
      }), " durchführen müssen, führt HubSpot für die HubSpot-App mit der ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "app-101"
      }), " und den Account mit der ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ABC-account-789"
      }), " eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage durch:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://my.event.app/events/1234-event-XYZ?appId=app-101&externalAccountId=ABC-account-789"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}