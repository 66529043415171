"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 34643778126;
const slug = exports.slug = 'guides/apps/marketplace/applying-for-app-certification';
const title = exports.title = 'アプリ認定の申請';
const name = exports.name = 'APAC JAPAN (JA) | Applying for app certification | 202010';
const metaDescription = exports.metaDescription = 'アプリ認定は、HubSpotによる審査で承認された確かな品質の証明となるため、利用を検討しているユーザーからの信頼の獲得につながります。';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h3: "h3",
      p: "p",
      a: "a",
      img: "img",
      ul: "ul",
      li: "li",
      strong: "strong",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "アプリ認定の申請"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "アプリ認定とは？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリ認定では、マーケットプレイスに掲載されるアプリがセキュリティー、プライバシー、信頼性、パフォーマンス、ユーザビリティー、アクセシビリティー、価値について", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "必要な条件"
      }), "を満たしているかどうかをHubSpotエコシステム品質チームが審査して確認します。承認されると、アプリの掲載情報ページに「HubSpot Certified App」バッジが表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh5.googleusercontent.com/CsSeky353aK17dYyX63spJgvA0INloEKH5z9zbmc2VgWG9QExch_b8skZ6ozu8K5H6Q3k0UWSScb2z16WwiIlkcx4Rhsmr0JexBRIYAJLoVNzb7D6DI3_296A5nIiAiolEkvhkl4",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "重要な理由"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリ掲載ページに認定バッジがあれば、HubSpotエコシステム品質チームが審査し承認したアプリであることを顧客が確認できます。利用を検討しているユーザーにとって優れた品質の証しとなり、アプリに対する信頼の獲得につながります。アプリ認定を受けて顧客の信頼を獲得する方法を", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/how-to-build-customer-trust-through-certification",
        children: "こちら"
      }), "（英語）からご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "仕組み"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "対象となる"
      }), "アプリパートナーは、ご自身のアプリ開発者アカウントを通じて認定を申請できます。その後、HubSpotエコシステム品質チームが審査を行い、フィードバックまたはアプリの認定についてご連絡いたします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "申請の対象となるアプリは？"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "認定に必要な条件"
      }), "を参照して、アプリが認定申請の対象となるかどうかを確認してください。アプリのアクティブインストール数が60以上、かつAPIトラフィックが所定の量以上でない場合は、申請いただけません。アクティブインストール数は、過去30日以内に成功したアプリのアクティビティーを示す、貴社とは関係のない、HubSpotの本番アカウントのユニーク数です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "認定の申請方法は？"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "一度に1つのアプリのみを認定申請に提出できます。同時に複数のアプリを認定申請すると、申請の順番に基づいて却下されます。アプリが認定されたら、別の認定申請を送信できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "認定を申請するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["開発者アカウントに", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/account/why-can-t-i-log-into-hubspot",
            children: "ログイン"
          }), "し、［アプリマーケットプレイス］>［掲載情報］に進みます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "申請するアプリの右側で、［アプリを認定］をクリックします。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/undefined-Jun-28-2021-07-51-13-13-PM.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["デモ動画の", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL"
        }), "を入力します。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-demo",
          children: "以下"
        }), "にあるアプリのデモ動画の要件を確認してください。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotエコシステム品質チームがアプリの機能を評価できるように、アプリのテスト用ユーザー資格情報を提供してください。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-certification.png",
        alt: "app-certification"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［認定申請を送信］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "アプリのデモ動画の要件"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**デモ動画は、HubSpotエコシステム品質チームがアプリをテストするのに役立ちます。すべての要件が満たされたデモ動画が提出されない限り、チームはアプリを審査", (0, _jsxRuntime.jsx)("u", {
          children: "しません"
        }), "。セールスプロモーション動画およびマーケティング動画は却下されます。HubSpotはデモ動画の共有または公開はしません。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デモ動画は以下の要件を満たしている必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "3分以上である。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "オーディオ（推奨）またはテキストの説明が含まれている。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリの目的と一般的なユースケースの説明が含まれている。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "例：「ACMEアプリは、営業担当者とオンボーディング担当者がCRM全体で調整を行うのに役立ちます。ACMEのサポートされたCRMのいずれかで成立した取引は、HubSpotでチケットとオンボーディングタスクを自動的に生成することができます。」"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["以下のアクションについて、新規ユーザーがどのようにすべきかを実演および説明している：", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["アプリのインストール。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "例：「HubSpot App MarketplaceのACMEアプリの一覧から、［アプリをインストール］をクリックし、CRMを選択し、資格情報を入力し、［完了］をクリックし、HubSpotアカウントを選択し、要求されたスコープを確認し、［アプリを接続］をクリックします。」"
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["インストール後のアプリの設定。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "例：「アプリをインストールしたら、［設定］>［統合］>［接続されたアプリ］>［ACMEアプリ］>［チケットパイプライン］に移動してチケットパイプラインを選択します。次に、［タスクテンプレート］セクションで最大10のデフォルトタスクを構成します。同期を有効にする準備ができたら、［チケット同期］をオンに切り替えます。」"
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["一般的なユースケースを実行するためにアプリの主な機能を使用する。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "例：「接続されたCRMで成立した各取引について、チケット同期機能は、関連する連絡先と設定されたタスクを含むチケットレコードをHubSpotに作成します。これにより、オンボーディングチームはすぐに新しい顧客とつながることができます。」"
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["一般的なユースケースを実行するために、HubSpotアカウント内でアプリを操作する（該当する場合）。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "例：「HubSpotの取引からオンボーディングチケットを作成するには、取引ベースのワークフローで［ACMEチケットの作成］カスタム ワークフロー アクションを使用します。これらのアクションは、他のCRMのアプリ設定よりも多くのカスタマイズを可能にします。」"
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubSpotアカウントからアプリを接続解除する。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubSpotアカウントからアプリをアンインストールし、これがユーザーのHubSpotアカウントとデータにどのように影響するかを説明する。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**ヒント：**デモ動画の録画には、無料ツールの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.loom.com/",
          children: "Loom"
        }), "を使用できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "認定CTAがアプリに表示されない場合"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["［アプリを認定］ボタンは、アプリが申請の対象となる場合にのみ表示されます。ご不明な点がございましたら、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "認定に必要な条件"
      }), "を参照いただくか、または担当の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#app-partner-manager",
        children: "アプリ パートナー マネージャー"
      }), "までお問い合わせください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ユーザーに対する認定アプリの明示"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "認定されると、アプリマーケットプレイスの掲載情報に「HubSpot Certified App」バッジが明示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh3.googleusercontent.com/dw7gvVRNzsrmtUwOYBsLVhpAEniaAZ5Z-_G70J-Bzs596yGR_ZzLXJyw821l7FVCV53XmKoI1VVEFxX9YKAJxtI-4NjwvCJadqfWyLG8IyFOWROZ6k8Nx-1307mp6cE0T8p980-N",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "バッジの上で、アプリの認定に関する追加情報を顧客が確認できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh5.googleusercontent.com/CsSeky353aK17dYyX63spJgvA0INloEKH5z9zbmc2VgWG9QExch_b8skZ6ozu8K5H6Q3k0UWSScb2z16WwiIlkcx4Rhsmr0JexBRIYAJLoVNzb7D6DI3_296A5nIiAiolEkvhkl4",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["開発者アカウントにログインし、［アプリマーケットプレイス］>［掲載情報］の順に進み、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "アプリ パートナー マネージャー"
        }), "とそのEメール情報を検索します。アプリの上で、［その他］>［掲載情報の詳細を表示］をクリックします。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "関連ドキュメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "アプリの認定に必要な条件"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "アプリの掲載に必要な条件"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}