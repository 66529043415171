"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529622;
const slug = exports.slug = 'reference/cms/modules/configuration';
const title = exports.title = 'Konfigurieren eines Moduls';
const name = exports.name = 'EMEA DACH (DE) Configuring a Module';
const metaDescription = exports.metaDescription = 'Erfahren Sie, wie Sie ein benutzerdefiniertes Modul konfigurieren.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "hinzuf%C3%BCgen-eines-symbols",
  "label": "Hinzufügen eines Symbols",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A4ndern-des-labels",
  "label": "Ändern des Labels",
  "parent": null
}, {
  "depth": 0,
  "id": "ein-modul-global-machen",
  "label": "Ein Modul global machen",
  "parent": null
}, {
  "depth": 0,
  "id": "steuern%2C-wo-ein-modul-zur-verwendung-verf%C3%BCgbar-ist",
  "label": "Steuern, wo ein Modul zur Verwendung verfügbar ist",
  "parent": null
}, {
  "depth": 0,
  "id": "hinzuf%C3%BCgen-von-css--und-javascript-abh%C3%A4ngigkeiten",
  "label": "Hinzufügen von CSS- und JavaScript-Abhängigkeiten",
  "parent": null
}, {
  "depth": 0,
  "id": "hinzuf%C3%BCgen-von-kategorien-und-tags",
  "label": "Hinzufügen von Kategorien und Tags",
  "parent": null
}, {
  "depth": 1,
  "id": "kategorien",
  "label": "Kategorien",
  "parent": "hinzuf%C3%BCgen-von-kategorien-und-tags"
}, {
  "depth": 1,
  "id": "tags",
  "label": "Tags",
  "parent": "hinzuf%C3%BCgen-von-kategorien-und-tags"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ol: "ol",
      li: "li",
      strong: "strong",
      pre: "pre",
      em: "em",
      ul: "ul",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Konfigurieren eines Moduls"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "Beim Erstellen eines Moduls stehen eine Reihe von Optionen zur Verfügung, die sich darauf auswirken, wo ein Modul sichtbar ist, wie es identifiziert wird und wie es bearbeitet wird."
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Bei der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/getting-started-with-local-development",
              children: "lokalen Entwicklung"
            }), " werden Module als Ordner mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".module"
            }), "-Suffix gespeichert. Diese Ordner enthalten die Dateien, aus denen die Einstellungen des Moduls bestehen, sowie der Code, der zum Rendern des Moduls verwendet wird. Die Konfiguration eines Moduls wird in der ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#meta-json",
              children: "meta.json"
            }), "-Datei gespeichert, während die Felder separat in einer ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/modules/overview#fields-json",
              children: "fields.json"
            }), "-Datei konfiguriert werden."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Die meisten Konfigurationen können auch über den Modul-Editor im ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/design-manager",
              children: "Design-Manager"
            }), " geändert werden."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/module%20structure.png",
            alt: "Lokal angezeigte Modulordnerstruktur"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Konfigurieren Sie auf einer übergeordneten Ebene die Moduloptionen lokal in der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "-Datei, die die folgenden Eigenschaften enthalten kann:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL zu einem Bild, das als Symbol für ein Modul verwendet werden soll."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Label, das verwendet wird, wenn Module in den Content Editoren angezeigt werden"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eindeutige ID für das Modul, die unabhängig vom Pfad ist."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_available_for_new_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Wert für den Umschalter in der oberen rechten Ecke des Modul-Editors in HubSpot. Bestimmt, ob das Modul im Inhalt verwendet werden kann."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "global"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gibt an, ob das Modul global ist oder nicht"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "host_template_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " von ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview",
              children: "Inhaltstypen"
            }), ", in denen das Modul verwendet werden kann. Eine oder mehrere von ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ \"PAGE\", \"BLOG_POST\", \"BLOG_LISTING\", \"EMAIL\" ]"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css_assets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " von CSS-Dateien, von denen das Modul abhängt. Unterstützt relative Pfade.z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"css_assets\": [{ \"path\": \"../path/to/file.css\" }]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css_render_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Legt mit ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "async"
            }), " fest, ob das Modul CSS asynchron gerendert wird: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"async\": false}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "js_assets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " von JavaScript-Dateien, von denen das Modul abhängt. Unterstützt relative Pfade.z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"js_assets\": [{ \"path\": \"../path/to/file.js\" }]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "js_render_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ändert das JavaScript-Tag des Moduls, das der gerenderten Seite hinzugefügt wurde. Die Optionen umfassen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "position"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "head"
                }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "footer"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "async"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "defer"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "type"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "string"
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"position\":\"footer\"}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inline_help_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Hilfetext, der am oberen Rand des Moduls in einem blauen Infokasten angezeigt wird (maximal 300 Zeichen).Stellt die für die Verwendung des Moduls erforderlichen Informationen bereit. Wenn Sie feldspezifische Hilfetextinformationen übermitteln möchten, lesen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#properties-used-by-all-fields",
              children: "Hilfetextfelddokumentation"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "master_language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Bei ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/design-manager/create-translations-of-your-modules#:~:text=To%20create%20translations%20of%20a,the%20right%2C%20click%20Add%20translations.",
              children: "aktivierten Übersetzung"
            }), " der Sprachcode der Sprache, in der die Felder des Moduls ursprünglich geschrieben wurden.z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "en"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Legt ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/tags/standard-tags#editor-placeholders",
              children: "Platzhalterinhalt"
            }), " für das Modul fest. Umfasst die folgenden Eigenschaften:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "show_module_icon"
                }), ": ob das Modulsymbol angezeigt wird. ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), ": der Titel, der im Editor für das Modul erscheint. Zeichenfolge."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), ": die Beschreibung, die im Editor für das Modul erscheint. Zeichenfolge."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "categories"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Array mit bis zu drei ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-categories",
              children: "Modulkategorien"
            }), ".Zum Beispiel: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"categories\":[\"FORMS_AND_BUTTONS\"]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_tags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Array von ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#adding-categories-and-tags",
              children: "Modul-Tag-Objekten"
            }), ", das den Tag-Namen und die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "source"
            }), " von ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"USER\""
            }), " enthält.Zum Beispiel: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"content_tags\": [{"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\" : \"BUTTONS\","
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"source\" : \"USER\"``}]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie mehr über die Konfigurationsoptionen der einzelnen Module."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Hinzufügen eines Symbols"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Module können ein Symbol enthalten, das im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Design-Manager"
      }), " und in den Seiten- und E-Mail-Editoren angezeigt wird, um den Erstellern von Inhalten visuellen Kontext zu bieten. Es wird empfohlen, verschiedene Symbole für die verschiedenen Modultypen in Ihrem Design zu verwenden. Symbole ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "für Marketplace-Anbieter verpflichtend"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt zwei Möglichkeiten, ein Symbol hinzuzufügen: über den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-an-icon-using-the-design-manager",
        children: "Design-Manager"
      }), " oder das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-an-icon-using-the-cli",
        children: "CMS CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Modulsymbole müssen als ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".svg"
      }), "-Datei vorliegen und dürfen nicht größer als 10 KB sein. Um ein optimales Ergebnis zu erzielen, sollte Ihr Symbol einfach sein und nur eine Farbe verwenden. Symbole, die mehr als eine Farbe verwenden, werden automatisch für Sie konvertiert. Das standardmäßig angezeigte Modulsymbol ist ein Schraubenschlüssel- und Pinselsymbol."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "So fügen Sie ein Symbol mit dem Design-Manager hinzu:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.ol, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Files and Templates"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Design Tools"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Use the left sidebar to locate the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "module"
              }), " you want to edit, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "module name"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In the right sidebar of the module editor, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "icon"
              }), " next to the module title."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Upload/select your icon. After publishing your changes, the icon will appear within the page editors and design manager."
            }), "\n"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-icon-crop.png",
            alt: "edit-module-icon-crop"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um bei der lokalen Entwicklung ein Symbol hinzuzufügen, öffnen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "-Datei des Moduls und fügen Sie den Wert des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "icon"
      }), "-Parameters hinzu oder bearbeiten Sie ihn so, dass er ein SVG vom Datei-Manager ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"global\": false,\n  \"host_template_types\": [\"PAGE\"],\n  \"icon\": \"http://example.com/hubfs/code.svg\",\n  \"label\": \"Code block\",\n  \"smart_type\": \"NOT_SMART\",\n  \"is_available_for_new_content\": true,\n  \"inline_help_text\": \"This module is for code snippets.\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ändern des Labels"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "Das Label, das bei der Anzeige von Modulen im Editor verwendet wird, kann unabhängig vom Namen des Moduls angepasst werden. Dies ist nützlich, wenn Sie lokal entwickeln, da Sie einen Namen verwenden können, der für Sie sinnvoll ist, während Sie einen anderen Namen haben, den die Autoren der Inhalte sehen."
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Der ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            }), "-Parameter wird lokal geändert, um das Label festzulegen. Im Design-Manager gibt es ein Feld im Modul-Editor unterhalb des Modulnamens."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-label.png",
            alt: "edit-module-label"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein Modul global machen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei normalen Modulen ist der Inhalt jeder Instanz eines Moduls in einer Seite, E-Mail oder Vorlage unabhängig. In einigen Anwendungsfällen ist es sinnvoll, dass alle Instanzen eines Moduls den gleichen Inhalt haben. Bei der lokalen Entwicklung können Sie ein Modul global machen, indem Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global"
      }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), " festlegen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können mit dem Design-Manager auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/use-global-content-across-multiple-templates#create-new-global-content-in-the-design-manager:~:text=To%20convert%20an%20existing%20local%20module%20or%20group",
        children: "Module in einer Drag-&-Drop-Vorlage in eine globale Vorlage konvertieren"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Steuern, wo ein Modul zur Verwendung verfügbar ist"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bei der lokalen Entwicklung können Sie über die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hostTemplateTypes"
      }), "-Eigenschaft steuern, in welchen Inhaltstypen ein Modul verwendet werden kann. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#template-types",
        children: "verfügbaren Vorlagentypen"
      }), ". Module können außerdem ausgeblendet werden, sodass sie nicht direkt zu Seiten hinzugefügt werden können, indem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "is_available_for_new_content"
      }), " auf ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " festgelegt wird. Dies kann beispielsweise für Module hilfreich sein, die für Navigationsmenüs und die Suche erstellt wurden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können dies im Design-Manager aktualisieren, indem Sie auf die Option ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Vorlagentyp"
      }), " in der rechten Seitenleiste klicken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-template-type.png",
        alt: "edit-module-template-type"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Hinzufügen von CSS- und JavaScript-Abhängigkeiten"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zusätzlich zur Verwendung von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " zum Hinzufügen von CSS und JavaScript, das zu allen Seiten hinzugefügt wird, die eine Modulinstanz enthalten, können Abhängigkeiten, die zwischen Modulen geteilt werden, mithilfe von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css_assets"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "js_assets"
      }), " hinzugefügt werden. Die Pfade können absolut oder relativ zur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "-Datei sein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"css_assets\": [{ \"path\": \"../path/to/file.css\" }],\n  \"js_assets\": [{ \"path\": \"../path/to/file.js\" }]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Warnung:"
        }), " Wenn relative Pfade verwendet werden, um auf Abhängigkeiten zu verweisen, überschreibt die Ausführung von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch --overwrite"
        }), " zur Aktualisierung des Moduls für die lokale Entwicklung die relativen Pfade mit absoluten Pfaden."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit dem Design-Manager können Sie CSS- und JavaScript-Dateien mit einem Modul verknüpfen, indem Sie den Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Verlinkte Dateien"
      }), " in der rechten Seitenleiste des Modul-Editors verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-linked-files.png",
        alt: "edit-module-linked-files"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Hinzufügen von Kategorien und Tags"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Modulen Kategorien und Tags zuweisen, um sie in HubSpot-Tools zu organisieren:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#categories",
          children: "Kategorien"
        }), ": Weisen Sie einem Modul Kategorien zu, um sie in Gruppen innerhalb der Content-Editor-Benutzeroberfläche zu organisieren. Dies ermöglicht es Content-Autoren, Module einfacher zu finden, während sie Inhalte in HubSpot erstellen. Beachten Sie Folgendes zu den Kategorien:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ein Modul kann bis zu drei Kategorien haben, die vordefiniert sind und nicht angepasst werden können."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Derzeit werden Kategorien nicht in der Content-Editor-Benutzeroberfläche angezeigt. Sie können jedoch Kategorien zuweisen, wenn die Kategorisierung in Editoren verfügbar ist."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Nicht kategorisierte Module sind unter der Kategorie ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Alle"
            }), " verfügbar sein."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#tags",
          children: "Tags"
        }), ": Weisen Sie Tags zu, um Module innerhalb des Design-Managers zu organisieren. Auf diese Weise können Sie Module beim Erstellen von Vorlagen leichter finden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Design-Manager können Sie Kategorien und Tags über den Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Filter-Tags"
      }), " der rechten Seitenleiste im Modul-Editor zuweisen. Erfahren Sie unten mehr über die verfügbaren Kategorien und Tags."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-filter-tags.png",
        alt: "edit-module-filter-tags"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lokal können Sie Kategorien und Tags wie folgt zu der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "-Datei eines Moduls hinzufügen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"label\": \"Custom module\",\n  \"categories\": [\"image_and_video\", \"commerce\"],\n  \"content_tags\": [\n    {\n      \"name\": \"BUTTONS\",\n      \"source\": \"USER\"\n    },\n    {\n      \"name\": \"CTA\",\n      \"source\": \"USER\"\n    }\n  ],\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Kategorien"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "categories"
      }), "-Array eines Moduls kann bis zu drei der folgenden Kategorien enthalten (ohne Berücksichtigung von Groß- und Kleinschreibung):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Kategorie"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blogspezifische Module, z. B. eine aktuelle Beitragsliste."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Module, die formatiert sind, um Inhalte grafisch darzustellen, z. B. eine Bildergalerie."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Handelsspezifische Module wie Preiskarten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "design"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Module, die die Inhaltsstruktur und das Layout beeinflussen, wie Accordions."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "functionality"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Module, die dynamische Reaktionen oder Verhaltensweisen auf der Seite enthalten, z. B. Menüs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms_and_buttons"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Module, die es Website-Besuchern ermöglichen, Daten einzugeben und zu übermitteln."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Module, die Elemente wie Bilder, Symbole, Videos und Banner enthalten."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Social-Media-spezifische Module wie Social-Media-Sharing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Module, die nur Text enthalten."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tags"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content_tags"
      }), "-Array eines Moduls kann eines der folgenden Modul-Tag-Objekte enthalten (Groß-/Kleinschreibung beachten):"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Inhaltstypen:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ACCORDION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ANIMATION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOG_POST"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BUTTONS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CODE"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CTA"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FEED"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FORM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ICON"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "IMAGE"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LISTS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LOGO"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MENU"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "RICH_TEXT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SLIDER"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "TEXT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "VIDEO"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Funktionen:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BANNER"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOG"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BRANDING"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CALCULATOR"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CONVERSION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "EMAIL"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "GALLERY"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "HEADERS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "INTERACTION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LAYOUT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MAP"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MULTIMEDIA"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "NAVIGATION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "PROGRESS_BAR"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SEARCH"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SETTINGS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SOCIAL"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "TRANSLATION"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}