import { createTracker } from 'usage-tracker';
import events from '../../../events.yaml';
import { createContainer } from 'usage-tracker-multi';
import { publicBrowserHistory } from '../../routing/history';
import { genericClient } from 'usage-tracker-core/client';
let usageTracker;
const container = createContainer({
  history: publicBrowserHistory,
  storeUuidOnUrl: false
});
export const getTrackerType = hstc => {
  return hstc && hstc.length ? 'public' : 'anonymous';
};
export const initializePublicUsageTracker = () => {
  usageTracker = container.createTracker({
    events,
    trackerType: getTrackerType(genericClient.getHstc())
  });
};
export const updatePublicUsageTracker = () => {
  usageTracker = container.createTracker({
    events,
    trackerType: getTrackerType(genericClient.getHstc())
  });
};
export const initializeInAppUsageTracker = () => {
  usageTracker = createTracker({
    events,
    isExternalHost: false
  });
};
export const getUsageTracker = () => {
  return usageTracker;
};