"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937504;
const slug = exports.slug = 'guides/api/crm/objects/companies';
const title = exports.title = 'CRM-API | Unternehmen';
const name = exports.name = 'vNext Docs DP - Unternehmen';
const metaDescription = exports.metaDescription = 'In Unternehmensdatensätzen werden Daten zu Unternehmen gespeichert. Unternehmen-Endpunkte ermöglichen es Ihnen, diese Daten zu verwalten und zwischen HubSpot und anderen Systemen zu synchronisieren. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "unternehmen-erstellen",
  "label": "Unternehmen erstellen",
  "parent": null
}, {
  "depth": 1,
  "id": "eigenschaften",
  "label": "Eigenschaften",
  "parent": "unternehmen-erstellen"
}, {
  "depth": 1,
  "id": "zuordnungen",
  "label": "Zuordnungen",
  "parent": "unternehmen-erstellen"
}, {
  "depth": 0,
  "id": "unternehmen-abrufen",
  "label": "Unternehmen abrufen",
  "parent": null
}, {
  "depth": 0,
  "id": "unternehmen-aktualisieren",
  "label": "Unternehmen aktualisieren",
  "parent": null
}, {
  "depth": 1,
  "id": "bestehende-unternehmen-mit-datens%C3%A4tzen-und-aktivit%C3%A4ten-verkn%C3%BCpfen",
  "label": "Bestehende Unternehmen mit Datensätzen und Aktivitäten verknüpfen",
  "parent": "unternehmen-aktualisieren"
}, {
  "depth": 1,
  "id": "eine-zuordnung-entfernen",
  "label": "Eine Zuordnung entfernen",
  "parent": "unternehmen-aktualisieren"
}, {
  "depth": 0,
  "id": "eine-aktivit%C3%A4t-in-einem-unternehmensdatensatz-anpinnen",
  "label": "Eine Aktivität in einem Unternehmensdatensatz anpinnen",
  "parent": null
}, {
  "depth": 0,
  "id": "unternehmen-l%C3%B6schen",
  "label": "Unternehmen löschen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Unternehmen"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In HubSpot speichern Unternehmen Informationen zu Organisationen gespeichert, die mit Ihrer Geschäft interagieren. Die Unternehmen-Endpunkte ermöglichen es Ihnen, Unternehmensdatensätze zu erstellen und zu verwalten sowie Unternehmensdaten zwischen HubSpot und anderen Systemen zu synchronisieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über Objekte, Datensätze, Eigenschaften und Zuordnungen-APIs im Leitfaden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Grundlegendes zum CRM"
      }), ". Weitere allgemeine Informationen zu Objekten und Datensätzen in HubSpot finden Sie in diesem Artikel über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: "Verwalten Ihrer CRM-Datenbank"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unternehmen erstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um neue Unternehmen zu erstellen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Schließen Sie in Ihrer Anfrage Ihre Unternehmensdaten in ein properties-Objekt ein. Sie können auch ein associations-Objekt hinzufügen, um Ihrem neuen Unternehmen vorhandene Datensätze (z. B. Kontakte, Deals) oder Aktivitäten (z. B. Meetings, Notizen) zuzuordnen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eigenschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Unternehmensdetails werden in Unternehmenseigenschaften gespeichert. Es gibt ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
        children: "Standard-HubSpot-Unternehmenseigenschaften"
      }), ", Sie können jedoch auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "benutzerdefinierte Eigenschaften erstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie ein neues Unternehmen erstellen, sollten Sie ", (0, _jsxRuntime.jsx)("u", {
        children: "mindestens eine"
      }), " der folgenden Eigenschaften in Ihre Anfrage aufnehmen: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), ". Es wird empfohlen, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " immer einzuschließen, da Domain-Namen die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/deduplication-of-contacts-companies-deals-tickets#automatic-deduplication-in-hubspot",
        children: "primäre eindeutige ID"
      }), " sind, um doppelte Unternehmen in HubSpot zu vermeiden. Wenn ein Unternehmen über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/companies/add-multiple-domain-names-to-a-company-record",
        children: "mehrere Domains"
      }), " verfügt, können Sie diese über die API hinzufügen, indem Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_additional_domains"
      }), "-Feld mit Semikolons zur Trennung der einzelnen Domains verwenden. Zum Beispiel: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"hs_additional_domains\" : \"domain.com; domain2.com; domain3.com\""
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um alle verfügbaren Eigenschaften anzuzeigen, können Sie eine Liste der Unternehmenseigenschaften Ihres Accounts abrufen, indem Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/companies"
      }), " durchführen. Erfahren Sie mehr über die", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: " Eigenschaften-API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Wenn Sie ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lifecyclestage"
        }), " in Ihre Anfrage aufgenommen haben, müssen sich die Werte auf den internen Namen der Lifecycle-Phase beziehen. Die internen Namen der Standardphasen sind Textwerte und ändern sich auch nicht, wenn Sie das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties#:~:text=the%20properties%20settings.-,Label/Name%3A,-enter%20a%20unique",
          children: "Label"
        }), " der Phase bearbeiten (z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriber"
        }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "marketingqualifiedlead"
        }), "). Die internen Namen von ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages",
          children: "benutzerdefinierten Phasen"
        }), " sind numerische Werte. Sie können die interne ID einer Phase in Ihren ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages#:~:text=To%20edit%20a%20lifecycle%20stage%2C%20hover%20over%20the%20stage%20and%20click%20Edit.%20In%20the%20right%20panel%2C%20edit%20the%20Stage%20name%2C%20then%20click%20Edit%20lifecycle%20stage%20to%20confirm.%20Click%20the%20code%20codcode%20icon%20to%20view%20the%20stage%27s%20internal%20ID%2C%20which%20is%20used%20by%20integrations%20and%20APIs.",
          children: "Einstellungen für Lifecycle-Phasen"
        }), " finden, oder indem Sie die Lifecycle-Phase-Eigenschaft über die API abrufen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um beispielsweise ein neues Unternehmen zu erstellen, kann Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"name\": \"HubSpot\",\n    \"domain\": \"hubspot.com\",\n    \"city\": \"Cambridge\",\n    \"industry\": \"Technology\",\n    \"phone\": \"555-555-555\",\n    \"state\": \"Massachusetts\",\n    \"lifecyclestage\": \"51439524\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Zuordnungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Beim Erstellen eines neuen Unternehmens können Sie das Unternehmen auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "bestehenden Datensätzen"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "Aktivitäten"
      }), " in einem associations-Objekt zuordnen. Um beispielsweise ein neues Unternehmen mit einem bestehenden Kontakt und einer vorhandenen E-Mail zu verknüpfen, würde Ihre Anfrage wie folgt aussehen:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"name\": \"HubSpot\",\n    \"domain\": \"hubspot.com\",\n    \"city\": \"Cambridge\",\n    \"industry\": \"Technology\",\n    \"phone\": \"555-555-555\",\n    \"state\": \"Massachusetts\",\n    \"lifecyclestage\": \"51439524\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 101\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 280\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 556677\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 185\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im associations-Objekt sollten Sie Folgendes einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Datensatz oder die Aktivität, den bzw. die Sie dem Unternehmen zuordnen möchten, angegeben durch seinen eindeutigen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "-Wert."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Der Typ der Zuordnung zwischen Unternehmen und Datensatz/Aktivität. Beziehen Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), " ein. Standardzuordnungstyp-IDs sind ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "hier"
            }), " aufgeführt. Sie können den Wert für benutzerdefinierte Zuordnungstypen (d. h. Label) über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "Zuordnungen-API"
            }), " abrufen."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unternehmen abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Unternehmen einzeln oder mehrere gleichzeitig aktualisieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um ein einzelnes Unternehmen abzurufen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/companies/{companyId}"
        }), " durch."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um eine Liste aller Unternehmen anzufordern, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/companies"
        }), " durch."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können für diese Endpunkte die folgenden Abfrageparameter in die Anfrage-URL einschließen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der Eigenschaften, die in der Antwort zurückgegeben werden sollen. Wenn das angefragte Unternehmen keinen Wert für eine Eigenschaft hat, wird es nicht in der Antwort angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine durch Kommas getrennte Liste der aktuellen und historischen Eigenschaften, die in der Antwort zurückgegeben werden sollen. Wenn das angefragte Unternehmen keinen Wert für eine Eigenschaft hat, wird es nicht in der Antwort angezeigt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Eine durch Kommas getrennte Liste von Objekten, für die zugehörige IDs abgerufen werden sollen. Alle angegebenen Zuordnungen, die nicht vorhanden sind, werden nicht in der Antwort zurückgegeben. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "Zuordnungen-API."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um einen Batch von bestimmten Unternehmen nach Datensatz-ID oder einer ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "benutzerdefinierten „Eindeutige ID“-Eigenschaft abzurufen"
        }), ", führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/companies/batch/read"
        }), " durch. Der Batch-Endpunkt kann Zuordnungen ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " abrufen. Erfahren Sie, wie Sie Zuordnungen mit der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "Zuordnungen-API"
        }), " batchweise lesen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für den Batch-Leseendpunkt können Sie auch den optionalen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "-Parameter verwenden, um Unternehmen anhand einer benutzerdefinierten ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "„Eindeutige ID“-Eigenschaft"
      }), " abzurufen. Standardmäßig beziehen sich die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "-Werte in der Anfrage auf die Datensatz-ID (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), sodass der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "-Parameter beim Abrufen nach Datensatz-ID nicht erforderlich ist. Um eine benutzerdefinierte „Eindeutiger Wert“-Eigenschaft zum Abrufen von Unternehmen zu verwenden, müssen Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "-Parameter einschließen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um beispielsweise einen Batch von Unternehmen abzurufen, könnte Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with record ID\n{\n  \"properties\": [\"name\", \"domain\"],\n  \"inputs\": [\n    {\n      \"id\": \"56789\"\n    },\n    {\n      \"id\": \"23456\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"name\", \"domain\"],\n  \"idProperty\": \"uniquepropertyexample\",\n  \"inputs\": [\n    {\n      \"id\": \"abc\"\n    },\n    {\n      \"id\": \"def\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um Unternehmen mit aktuellen und historischen Werten für eine Eigenschaft abzurufen, könnte Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"name\"],\n  \"inputs\": [\n    {\n      \"id\": \"56789\"\n    },\n    {\n      \"id\": \"23456\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unternehmen aktualisieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Unternehmen einzeln oder mehrere gleichzeitig aktualisieren. Für bestehende Unternehmen ist die Datensatz-ID des Unternehmens ein eindeutiger Wert, den Sie verwenden können, um das Unternehmen über die API zu aktualisieren."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein einzelnes Unternehmen anhand seiner Unternehmens-ID zu aktualisieren,führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}"
      }), " durch und schließen Sie die Daten ein, die Sie aktualisieren möchten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bestehende Unternehmen mit Datensätzen und Aktivitäten verknüpfen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein Unternehmen anderen CRM-Datensätzen oder einer Aktivität zuzuordnen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Um den ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), "-Wert abzurufen, verweisen Sie auf ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "diese Liste"
        }), " der Standardwerte oder führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), " durch."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das Zuordnen von Datensätzen mit der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "Zuordnungen-API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eine Zuordnung entfernen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine Zuordnung zwischen einem Unternehmen und einem Datensatz oder einer Aktivität zu entfernen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an die folgende URL durch: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Aktivität in einem Unternehmensdatensatz anpinnen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können eine Aktivität über die API an einen Unternehmensdatensatz anpinnen, indem Sie das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "-Feld in Ihre Anfrage aufnehmen. Geben Sie im Feld die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " der anzupinnenden Aktivität an, die über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "Interaktionen-APIs"
      }), " abgerufen werden kann. Sie können eine Aktivität pro Datensatz anpinnen, und die Aktivität muss bereits vor dem Anpinnen mit dem Unternehmen verknüpft sein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um die angepinnten Aktivitäten eines Unternehmens festzulegen oder zu aktualisieren, könnte Ihre Anfrage wie folgt aussehen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body PATCH /crm/v3/objects/companies/{companyId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können auch ein Unternehmen erstellen, es mit einer vorhandenen Aktivität verknüpfen und die Aktivität in derselben Anfrage anpinnen. Zum Beispiel:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body POST /crm/v3/objects/companies\n{\n  \"properties\": {\n    \"domain\": \"example.com\",\n    \"name\": \"Example Company\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 189\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unternehmen löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Unternehmen einzeln oder mehrere gleichzeitig löschen, wodurch das Unternehmen in den Papierkorb in HubSpot verschoben wird. Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "das Unternehmen später in HubSpot wiederherstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ein einzelnes Unternehmen anhand seiner ID zu löschen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "-Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/companies/{companyId}"
      }), " durch."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Erfahren Sie mehr über das batchweise Löschen von Unternehmen, indem Sie oben in diesem Artikel auf die Registerkarte* Endpunkte* klicken."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}