"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29837710045;
const slug = exports.slug = 'guides/cms/storage/hubdb/overview';
const title = exports.title = 'HubDB';
const name = exports.name = 'HubDB';
const metaDescription = exports.metaDescription = "Build data-driven content with HubDB, HubSpot's native database tool.";
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/HubDB%20Image%20for%20Building%20Dynamic%20Pages%20with%20HubDB.png';
const featuredImageAltText = exports.featuredImageAltText = 'HubDB';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "hubdb-architecture",
  "label": "HubDB architecture",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb-technical-limits",
  "label": "HubDB technical limits",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-hubdb-table",
  "label": "Create a HubDB table",
  "parent": null
}, {
  "depth": 0,
  "id": "join-multiple-hubdb-tables",
  "label": "Join multiple HubDB tables",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-add-a-foreign-id-column-to-the-main-table",
  "label": "1. Add a Foreign ID column to the main table",
  "parent": "join-multiple-hubdb-tables"
}, {
  "depth": 1,
  "id": "2.-add-foreign-table-rows-to-your-table-s-rows",
  "label": "2. Add foreign table rows to your table's rows",
  "parent": "join-multiple-hubdb-tables"
}, {
  "depth": 1,
  "id": "3.-render-your-joined-hubdb-table-data",
  "label": "3. Render your joined HubDB table data",
  "parent": "join-multiple-hubdb-tables"
}, {
  "depth": 0,
  "id": "access-hubdb-data-using-hubl",
  "label": "Access HubDB data using HubL",
  "parent": null
}, {
  "depth": 1,
  "id": "getting-rows",
  "label": "Getting rows",
  "parent": "access-hubdb-data-using-hubl"
}, {
  "depth": 2,
  "id": "row-attributes",
  "label": "Row attributes",
  "parent": "access-hubdb-data-using-hubl"
}, {
  "depth": 1,
  "id": "getting-table-metadata",
  "label": "Getting table metadata",
  "parent": "access-hubdb-data-using-hubl"
}, {
  "depth": 1,
  "id": "table-attributes",
  "label": "Table attributes",
  "parent": "access-hubdb-data-using-hubl"
}, {
  "depth": 1,
  "id": "getting-column-metadata",
  "label": "Getting column metadata",
  "parent": "access-hubdb-data-using-hubl"
}, {
  "depth": 2,
  "id": "column-attributes",
  "label": "Column attributes",
  "parent": "access-hubdb-data-using-hubl"
}, {
  "depth": 2,
  "id": "column-methods",
  "label": "Column methods",
  "parent": "access-hubdb-data-using-hubl"
}, {
  "depth": 2,
  "id": "rich-text-columns",
  "label": "Rich Text columns",
  "parent": "access-hubdb-data-using-hubl"
}, {
  "depth": 0,
  "id": "hubdb-tutorials-and-resources",
  "label": "HubDB tutorials and resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      em: "em",
      img: "img",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      h3: "h3",
      pre: "pre",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDB is a tool that allows you to create tables to store data in rows, columns, and cells, much like a spreadsheet. You can customize a HubDB table's columns, rows, and other settings based on your needs. For example, you could use a HubDB table to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Store feedback from an external mechanism to retrieve at a later time."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Store structured data that you can use to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "build dynamic CMS pages"
        }), " (", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "CMS Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " only)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Store data to use in a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-programmable-emails",
          children: "programmable email"
        }), " (", (0, _jsxRuntime.jsx)(_components.em, {
          children: "**_Marketing Hub"
        }), "**_ ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " only)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-table-example0.png",
        alt: "hubdb-table-example0"
      }), "HubDB tables can be accessed both ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb",
        children: "within HubSpot"
      }), " and through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb",
        children: "HubDB API"
      }), ", and you can retrieve a table's data in multiple ways, depending on your use case. To get data from a HubDB table, you can:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Query the data externally via the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/cms/hubdb",
          children: "HubDB API"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use HubSpot’s HubL markup tags to pull data into the CMS as structured content."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use the HubDB API with ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "serverless functions"
        }), " to provide an interactive web app experience."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["To ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
            children: "use HubDB data in pages"
          }), ", you need ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "CMS Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["To use HubDB data in ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/email/create-programmable-emails",
            children: "programmable emails"
          }), ", you need ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Publishing, editing, and viewing existing tables requires ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#website-tools",
            children: "HubDB permissions"
          }), ". Creating, clone, deleting, and editing a HubDB table's settings requires ", (0, _jsxRuntime.jsxs)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#settings",
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: "HubDB table settings"
            }), " permissions"]
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB architecture"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A HubDB table consists of rows, columns, and cells, similar to a spreadsheet."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tables:"
        }), " a table is a 2-dimensional arrangement of rows and columns. When a table is created, it is assigned a globally unique ID which you can use when needing to specify a table in HubL or through the API."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rows:"
        }), " rows are horizontal slices of a table. In a spreadsheet application, rows are represented by numbers, starting with 1. Each table row is given a unique ID on creation. Each row in a table comes with a few columns by default:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Column"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An automatically assigned, globally unique, numeric ID for this row."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A timestamp of when this row was created."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When used with dynamic pages, this string is the last segment of the URL's path for the page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When used with dynamic pages, this is the title of the page."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " rich text area columns in HubDB are limited to 65,000 characters. For more information, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/hubdb-rich-text-area-limited-to-65000-characters-and-a-new-meetings-module",
          children: "view the changelog announcement"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Columns:"
        }), " columns are vertical slices of a table. Each column has a type, which is used to store kinds of data. A table can include as many columns as you'd like, and each is assigned a globally unique ID on creation. Column ID start at a value of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1"
        }), ", but are not necessarily sequential, and cannot be reused. Column types include:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Text"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Rich text"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "URL"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Image"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Select"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Multi-select"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Date"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Date and time"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Number"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Currency"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Checkbox"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Location (longitude, latitude)"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Foreign ID"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Video"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cells:"
        }), " Cells store the values where a row and column intersect. Cells can be read or updated individually or as part of a row. Setting the value of a cell to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " is equivalent to deleting the cell's value."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB technical limits"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Note the following HubDB technical limits:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Account limits:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1,000 HubDB tables per account."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1 million HubDB rows per account."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Table limits:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "250 columns per table."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "10,000 rows per HubDB table."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 characters per table name."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 characters per table label."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Column limits:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "65,000 characters per rich text column."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "10,000 characters in each text column."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 characters per column name."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 characters per label."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "300 characters per column description."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 characters per selectable option within a column."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Page limits:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["10 calls to the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb_table_rows"
            }), " HubL function per CMS page."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["10 ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "dynamic pages"
            }), " using the same HubDB table."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubDB's with dynamic pages turned on must have lowercase paths so that URLs to these pages can be case insensitive."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a HubDB table"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can create HubDB tables either ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb",
        children: "through HubSpot's UI"
      }), " or through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#create-a-new-table",
        children: "HubDB API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All new tables created are set with a status of draft. They cannot be used to output data via HubL or API until you publish the table. When creating a table, you can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "manage its settings"
      }), ", such as allowing public API access and whether its data will be used to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "create dynamic pages"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Join multiple HubDB tables"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDB tables can be joined using the Foreign ID column type, which allows you to render the combined data from multiple tables. This can be helpful when some data might be shared across multiple data stores, allowing one centralized data table of this information, which can then be accessed across multiple other HubDB table data stores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn how to join multiple HubDB tables."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Add a Foreign ID column to the main table"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Files and Templates"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Locate the table you want to add a table join to, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), " dropdown menu, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Edit"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Edit"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add column"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "label"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " for the new column."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Column type"
        }), " dropdown menu and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Foreign ID"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select table"
        }), " dropdown menu and select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "table"
        }), " you want to join with your current table."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select column"
        }), " dropdown menu, then select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "column"
        }), " from the joining table you have selected to be visible in the Foreign ID field."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add column"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the value you chose as the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Select column"
        }), " only dictates which column value you see in the Foreign ID field in the HubDB UI. All table columns are available when rendering the joined HubDB tables."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb_foreign_id.png",
        alt: "hubdb_foreign_id"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Add foreign table rows to your table's rows"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now that you have a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Foreign ID"
      }), " column, you will have a multi-select column field on every row in your HubDB table, which allows you to select a foreign table's rows."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Select column"
      }), " field you chose will be used in this multi-select field to identify which row you are selecting from the foreign table. In the example below, the multi-select values for the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Expertise table join"
      }), " field are the values available from ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Name"
      }), " column of the foreign HubDB table."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb_foreign_id_ui.png",
        alt: "hubdb_foreign_id_ui"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " it's safe to edit the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Select column"
        }), " field of your ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Foreign ID"
        }), " column, and will simply update which column's values will display in the HubDB UI."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Render your joined HubDB table data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All of a foreign table's row data is accessible via HubL for rendering, not just the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Select column"
      }), " field. HubDB foreign row data is accessible by using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "nested for loop"
      }), ", looping through all of the foreign rows associated with an individual row."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for row in hubdb_table_rows(tableId, filterQuery) %}\n  the name for row {{ row.hs_id }} is {{ row.name }}\n  {% for foreign_row in row.foreign_table %}\n  \tthe name for foreign row {{ foreign_row.hs_id }} is {{ foreign_row.name }}\n  {% endfor %}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Access HubDB data using HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using HubL, you can pull HubDB data as to use as structured content on website pages. Below, learn more about how to retrieve table, row, and column data using HubL."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " drafted HubDB table data will appear in the page editor and live previews, but only published HubDB content will appear on the live page. If you're seeing table data appear in the editor or preview that isn't appearing on the live page, confirm that the table has been published since adding that table data."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Getting rows"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To list rows of a table, use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-rows",
        children: "hubdb_table_rows()"
      }), " HubL function. You can either access a table by its ID or name. It is recommended to reference a HubDB table by name, as this can help with code portability across HubSpot accounts. The immutable table name is set when creating a new table and can be found at any time by selecting ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Actions > Manage Settings"
      }), " within the table editor. A table's ID can be found in the address bar of the table editor or in the HubDB tables dashboard under the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ID"
      }), " column."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Table%20Create.png",
        alt: "Screenshot of create table modal"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below is an example of using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows()"
      }), " to fetch data."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for row in hubdb_table_rows(<tableId or name>, <filterQuery>) %}\n  the value for row {{ row.hs_id }} is {{ row.<column name> }}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " by default, the maximum number of rows returned is 1,000. To retrieve more rows, specify a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), " in the function query. For example:"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_rows(12345, \"orderBy=random()&limit=1500\")"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), " uses the same syntax as the HTTP API. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows(123, \"employees__gt=10&orderBy=count\")"
      }), " would return a list of rows where the \"employees\" column is greater than 10, ordered by the \"count\" column. A complete list of optional ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), " parameters ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#get-table-rows",
        children: "can be found here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Instead of using multiple row queries with different ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), " parameters, you should make one query and use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "selectattr()"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rejectattr()"
      }), " filters to filter your rows:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set all_cars = hubdb_table_rows(<tableId or name>) %}\n\n{% set cars_with_windows = all_cars|selectattr('windows') %}\n\n{% set teslas = all_cars|selectattr('make','equalto','tesla') %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get a single row, use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_row()"
      }), " HubL function."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set row = hubdb_table_row(<tableId or name>, <rowId>) %}\nthe value for {{ row.hs_id }} is {{ row.<column name> }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Built-in and custom column names are case insensitive. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HS_ID"
      }), " will work the same as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_id"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Row attributes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Attribute"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The globally unique id for this row."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When using dynamic pages, this string is the Page Path column value and the last segment of the url's path."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When using dynamic pages, this string is the Page Title column value for the row."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unix timestamp for when the row was created."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_child_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When using dynamic pages, this is the ID of the other table that is populating data for the row."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.column_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get the value of the custom column by the name of the column."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row[\"column name\"]"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Get the value of the custom column by the name of the column."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Getting table metadata"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get a table's metadata, including its name, columns, last updated, etc, use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table()"
      }), " function."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_info = hubdb_table(<tableId or name>) %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Table attributes"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The attributes listed below are in reference to the variable that ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table()"
        }), " was assigned to in the above code. Your variable may differ. ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: ["Note: It is recommended assigning this to a variable for easier use. If you don't want to do that, you can use ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ hubdb_table(<tableId>).attribute }}"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Attribute"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The id of the table."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the table."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.columns"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["List of column information. You can use a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/loops",
              children: "for loop"
            }), " to iterate through the information available in this attribute."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Timestamp of when the table was first created."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.published_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Timestamp of when this table was published."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.updated_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Timestamp of when this table was last updated."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.row_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number of rows in the table."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Getting column metadata"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_info = hubdb_table_column(<tableId or name>, <columnId or column name>) %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get information on a column in table such as its label, type and options, use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_column()"
      }), " function"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Column attributes"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The attributes listed below are in reference to the variable that ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_column()"
        }), " was assigned to in the above code. Your variable may differ. ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: ["Note: It is recommended assigning this to a variable for easier use. If you don't want to do that, you can use ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ hubdb_table_column(<tableId>,<columnId or column name>).attribute }}"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Attribute"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the column."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the column."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The label to be used for the column."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Type of this column."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.options"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["For select column type, this is a map of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "optionId"
            }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "option"
            }), " information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.foreignIds"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["For foreignId column types, a list of foreignIds (with ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " properties)."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Column methods"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Method"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "getOptionByName(\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "For select column types, get option information by the options name."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Rich Text columns"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "richtext"
      }), " column type functions similar to the rich text field you see for modules."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The data is stored as HTML, and the HubDB UI provides a text editing interface. However, when editing a HubDB table through HubSpot's UI, you cannot edit source code directly. This prevents situations where a non-technical user may input invalid HTML, preventing unintended issues with the appearance or functionality of your site. For situations where you need an embed code or more custom HTML you can use the embed feature in the rich text editor to place your custom code."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB tutorials and resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "Event Registration App"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "How to build a dynamic team member page with HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "How to add videos to dynamic pages"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "How to build multilevel dynamic pages using HubDB"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}