"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 99308094270;
const slug = exports.slug = 'guides/apps/extensions/overview';
const title = exports.title = 'Extensions overview';
const name = exports.name = 'Extensions overview';
const metaDescription = exports.metaDescription = 'The HubSpot API allows developers to build custom apps and integrations. Get started by reading our API overview and creating a developer account.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "extension-support-in-apps",
  "label": "Extension support in apps",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Extensions overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using extensions, you can customize the functionality of the HubSpot CRM. HubSpot offers a variety of extensions, such as creating custom events for CRM record timelines or enabling custom calling options with the calling SDK. You can also create UI extensions if you’re enrolled in the CRM development tools beta, which enables you to create custom cards with a wide variety of customizable components."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is a list of the extensions that HubSpot currently offers:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/calling-sdk",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Calling extensions SDK"
          })
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " enable users to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/calling/use-the-calling-tool",
          children: "make calls"
        }), " using custom calling options."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/timeline",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Custom timeline events"
          })
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " create custom events that display information from other systems on CRM record timelines."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Classic CRM cards"
          })
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " create cards to pull external data into CRM records. These types of cards are separate from UI extensions, and don't offer as much customization options."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/create",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "UI extensions (BETA)"
          })
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " customize CRM record pages with custom cards that can send and receive HubSpot and external data using a wide variety of customizable components."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/video-conferencing",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Video conference extension"
          })
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " integrate video conferencing into the meetings tool."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Extensions are powered by apps, which means you’ll first need to create a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "public app"
      }), " or a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/creating-private-apps",
        children: "private app in projects (BETA)"
      }), " before you can add an extension to an account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Extension support in apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Because private apps and public apps support different extensions, you’ll first need to decide what type of app to create. Review the table below to understand which extensions are supported by which app types."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about the differences between these types of apps in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview",
        children: "building apps overview"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "App type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Supported extensions"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Private app"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/ui-extensions/overview",
              children: "UI extensions"
            }), " are supported in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/private-apps/creating-private-apps",
              children: "private apps created with projects (BETA)"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Public app"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/calling-sdk",
                  children: "Calling SDK"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "Classic CRM cards"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/timeline",
                  children: "Timeline events"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/video-conferencing",
                  children: "Video conference extension"
                })
              })]
            }), (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/ui-extensions/overview",
              children: "UI extensions"
            }), " are supported in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/public-apps/creating-public-apps",
              children: "public apps created with projects (BETA)"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Classic CRM cards are different from the app cards you can create as ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/create",
          children: "UI extensions with projects (BETA)"
        }), ". UI extensions offer more advanced functionality and customizable components."]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}