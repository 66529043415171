"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129093048;
const slug = exports.slug = 'guides/cms/features';
const title = exports.title = 'Características del CMS de HubSpot';
const name = exports.name = 'EMEA ES CMS Features';
const metaDescription = exports.metaDescription = 'Los desarrolladores y profesionales de marketing pueden trabajar con una serie de potentes características del CMS de HubSpot para construir su sitio y respaldarlo con CRM de clase mundial de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "entorno-de-pruebas-para-desarrolladores-de-cms",
  "label": "Entorno de pruebas para desarrolladores de CMS",
  "parent": null
}, {
  "depth": 0,
  "id": "b%C3%BAsqueda-de-contenido",
  "label": "Búsqueda de contenido",
  "parent": null
}, {
  "depth": 0,
  "id": "multiling%C3%BCe",
  "label": "Multilingüe",
  "parent": null
}, {
  "depth": 0,
  "id": "administrador-de-archivos",
  "label": "Administrador de archivos",
  "parent": null
}, {
  "depth": 0,
  "id": "preparaci%C3%B3n-de-contenido",
  "label": "Preparación de contenido",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb",
  "label": "HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "afiliaci%C3%B3n",
  "label": "Afiliación",
  "parent": null
}, {
  "depth": 0,
  "id": "funciones-sin-servidor",
  "label": "Funciones sin servidor",
  "parent": null
}, {
  "depth": 0,
  "id": "api-de-hubspot",
  "label": "API de HubSpot",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      strong: "strong",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Características del CMS"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los desarrolladores y profesionales de marketing pueden trabajar con una serie de potentes características del CMS de HubSpot para construir su sitio y respaldarlo con CRM de clase mundial de HubSpot. Organiza tu sitio de forma segura mientras creas con HubDB, proporcionando contenido específico dirigido al usuario y asegurándote de que tu contenido se presente en un idioma con el que el lector se sienta cómodo. Todo dentro del CMS de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Entorno de pruebas para desarrolladores de CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Crea un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "Entorno de pruebas para desarrolladores del CMS"
      }), " gratuito para obtener acceso a todas las herramientas y características para desarrolladores de HubSpot. Usa tu entorno de pruebas para aprender a crear excelentes sitios en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/cms",
        children: "CMS Hub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Búsqueda de contenido"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No hay necesidad de una herramienta de búsqueda de terceros. Usando el motor de búsqueda nativo de HubSpot, la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-search",
        children: "característica de búsqueda de sitios integrada del CMS de HubSpot"
      }), " permitirá a tus visitantes encontrar el contenido que están buscando."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Multilingüe"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con CMS de HubSpot, los usuarios pueden crear ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/multi-language-content",
        children: "variantes en varios idiomas"
      }), " de su contenido. Esto permitirá a los usuarios finales ver el contenido en el idioma con el que se sientan más cómodos. Además, HubSpot proporciona herramientas para ayudar a los desarrolladores a garantizar que el idioma correcto esté disponible para el usuario final."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Administrador de archivos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/file-manager",
        children: "Administrador de archivos de HubSpot"
      }), " se puede usar para almacenar y servir archivos para tu sitio web. Los archivos se pueden almacenar en el Administrador de archivos a través de la interfaz de usuario de la aplicación o a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI de CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Preparación de contenido"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-staging",
        children: "Preparación de contenido"
      }), " permite a los creadores de sitios web actualizar el diseño y el contenido de las páginas mediante la puesta en escena de los cambios. Estos cambios preparados pueden publicarse en tu sitio de producción. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "La preparación de contenido es una característica de CMS Hub Pro."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), " es un almacén de datos relacional, muy parecido a una hoja de cálculo. Puedes crear tablas y manipular datos en HubDB a través de una interfaz de usuario, o puedes usar la API de HubDB. Puedes usar las etiquetas de marcado HubL de HubSpot para extraer datos directamente en el CMS y generar páginas dinámicamente basadas en filas de tablas de HubDB. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubDB es una característica de CMS Hub Pro."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Afiliación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usando la característica de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/overview",
        children: "Afiliación"
      }), " de CMS Hub Enterprise, puedes requerir que tus contactos de CRM estén registrados para ver el contenido específico de tu sitio. El contenido detrás de las páginas de afiliación puede ser altamente personalizado para el contacto conectado, e incluso puede generar datos de Contactos, Empresas, Negocios y Productos desde el CRM.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "La característica Afiliación es una función de CMS Hub Enterprise."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Funciones sin servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "funciones sin servidor"
      }), " ofrecen una manera de escribir código del lado del servidor que se relaciona con HubSpot y servicios de terceros a través de las API. Utiliza funciones sin servidor para agregar funciones avanzadas a tu sitio web de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.es/products/cms",
        children: "CMS Hub"
      }), ". ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Las funciones sin servidor son una característica de CMS Hub Enterprise."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "API de HubSpot"
      }), " proporcionan acceso seguro y autenticado a tus datos de CMS y CRM. Las API están diseñadas para permitirte crear una aplicación o integración funcional de forma rápida y sencilla. Todas las API de HubSpot están organizadas en torno a REST; si ya has interactuado con una API RESTful, muchos de los conceptos te resultarán familiares."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}