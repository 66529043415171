"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29907021628;
const slug = exports.slug = 'guides/apps/authentication/working-with-oauth';
const title = exports.title = 'Travailler avec OAuth';
const name = exports.name = 'vNext Docs DP - Travailler avec OAuth';
const metaDescription = exports.metaDescription = "OAuth est un moyen sécurisé d'authentifier votre application. Il utilise des jetons d'autorisation plutôt qu'un mot de passe pour connecter votre application à un compte utilisateur. ";
const toc = exports.toc = [{
  "depth": 0,
  "id": "lancer-une-int%C3%A9gration-avec-oauth%C2%A02.0",
  "label": "Lancer une intégration avec OAuth 2.0",
  "parent": null
}, {
  "depth": 0,
  "id": "param%C3%A8tres-de-requ%C3%AAte",
  "label": "Paramètres de requête",
  "parent": null
}, {
  "depth": 0,
  "id": "configurer-des-domaines",
  "label": "Configurer des domaines",
  "parent": null
}, {
  "depth": 0,
  "id": "documents-associ%C3%A9s",
  "label": "Documents associés",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h5: "h5",
      h2: "h2",
      em: "em",
      img: "img",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Travailler avec OAuth"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["OAuth est une méthode d'authentification sécurisée qui utilise des jetons d'autorisation plutôt qu'un mot de passe pour connecter votre application à un compte utilisateur. Le lancement de l'accès OAuth est la première étape pour permettre aux utilisateurs ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#install-an-app",
        children: "d'installer votre application"
      }), " dans leurs comptes HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "toute application conçue pour une installation par plusieurs comptes HubSpot ou pour un listing sur le marketplace des applications doit utiliser OAuth."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["les utilisateurs qui installent des applications dans leur compte HubSpot doivent être un ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
            children: "super administrateur"
          }), " ou disposer d'autorisations d' ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#settings",
            children: "accès aux marketplace des applications"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Ressources recommandées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/oauth-quickstart-guide",
          children: "Guide de démarrage rapide pour OAuth"
        }), " vous offrira davantage d'informations avec un exemple d'application."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ce ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/academy/tracks/71/593/2967",
          children: "tutoriel HubSpot Academy"
        }), " propose une présentation rapide quant à l'utilisation d'OAuth avec HubSpot, y compris une répartition du flux HubSpot-OAuth et des explications sur l'actualisation d'un jeton d'accès."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lancer une intégration avec OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour lancer une intégration avec OAuth 2.0 :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Tout d'abord, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "créez une application"
        }), " dans un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/developers/",
          children: "compte de développeur HubSpot"
        }), ". Après avoir créé l'application, vous trouverez l'ID du client et le secret client pour votre application sur la page d'", (0, _jsxRuntime.jsx)(_components.em, {
          children: "authentification"
        }), " de vos paramètres d'application."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/MyHubSpotApp.png",
        alt: "MyHubSpotApp"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Utilisez l'ID du client et le secret client ainsi que les ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-parameters",
            children: "paramètres de requête"
          }), " et les ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#scopes",
            children: "domaines"
          }), " décrits ci-dessous pour créer votre URL d'autorisation."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Envoyez les utilisateurs qui installent votre application vers l'URL d'autorisation, où ils verront un écran qui leur permettra de sélectionner un compte et d'autoriser l'accès à votre intégration. Vous pouvez définir l'URL d'autorisation comme étant destinée à un compte HubSpot spécifique en ajoutant l'ID du compte entre ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/oauth/"
          }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/authorize"
          }), ", comme indiqué ci-dessous. Après avoir autorisé l'accès, ils seront redirigés vers votre demande via un ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "redirect_url"
          }), ", qui sera associé à un paramètre de requête de code. Vous utiliserez ce code et le secret client pour obtenir un ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/oauth/tokens",
            children: "access_token et un refresh_token"
          }), " depuis HubSpot."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Exemples d'URL d'autorisation"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Tout compte :"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "https://app.hubspot.com/oauth/authorize?client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&scope=contacts%20automation&redirect_uri=https://www.example.com/"
                })]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Compte spécifique (ID 123456 :"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "https://app.hubspot.com/oauth/123456/authorize?client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&scope=contacts%20automation&redirect_uri=https://www.example.com/"
                })]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Exemple d'URL de redirection :"
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://example.com/?code=xxxx"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Exemple d'erreur :"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/?error=error_code&error_description=Human%20readable%20description%20of%20the%20error"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Utilisez le ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), " pour authentifier tous les appels d'API effectués pour ce compte HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Une fois que ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), " expire, utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "refresh_token"
          }), " pour générer un nouveau ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["votre application ne s'affichera pas comme une ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "application connectée"
          }), " dans le compte d'un utilisateur tant que vous ne générerez pas le jeton d'actualisation et le jeton d'accès initial."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["les jetons d'accès reflètent les périmètres d'accès demandés à l'application et ", (0, _jsxRuntime.jsx)("u", {
            children: "pas"
          }), " les autorisations ou les limites de ce qu'un utilisateur peut faire dans son compte HubSpot. Par exemple, si un utilisateur dispose des autorisations pour afficher uniquement les contacts détenus, mais autorise une demande pour le périmètre d'accès ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "crm.objects.contacts.read"
          }), ", le jeton d'accès résultant peut afficher tous les contacts du compte et pas seulement ceux détenus par l'utilisateur autorisant."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paramètres de requête"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les paramètres suivants sont obligatoires lors de la création d'une URL d'autorisation pour votre compte :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètre"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Mode d'emploi"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ID du client"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id=x"
            }), "Utilisé dans l'URL"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obtenez cela depuis la page des paramètres d'authentification de votre application (telle que décrite ci-dessus)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri=x"
            }), "de redirection : l'URL vers laquelle seront redirigés les visiteurs après avoir accordé l'accès à votre application."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Vous pouvez également désigner cette page sur la page des paramètres d'authentification de votre application.", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Remarque :"
            }), " Pour des raisons de sécurité, cette URL ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "doit"
            }), " utiliser HTTPS en production. (lorsque vous effectuez un test avec localhost, HTTP peut être utilisé). Vous ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "devez"
            }), " également utiliser un domaine, car les adresses IP ne sont pas prises en charge."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Domaine"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope=x%20x"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un ensemble d'autorisations séparées par des espaces auxquelles votre application doit avoir accès. Tous les domaines que vous avez vérifiés dans les paramètres d'authentification de votre application seront considérés comme obligatoires et vous devrez les inclure dans ce paramètre. Autrement, la page d'autorisation affichera une erreur. De plus, les utilisateurs recevront une erreur s'ils tentent d'installer votre application sur un compte qui n'a pas accès à un domaine inclus. Consultez le tableau Domaines ci-dessous pour en savoir plus sur les points de terminaison disponibles pour des domaines spécifiques."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les paramètres suivants sont facultatifs :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Paramètre"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Mode d'emploi"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Domaines facultatifs"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "&optional_scope=x%20x"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un ensemble d'autorisations séparées par des espaces pour votre application. Les domaines facultatifs seront automatiquement supprimés de la demande d'autorisation si l'utilisateur sélectionne un compte HubSpot qui n'a pas accès à cet outil (par exemple : la demande du domaine social sur un CRM uniquement). Si vous utilisez des domaines facultatifs, vous devez vérifier le jeton d'accès ou le jeton d'actualisation pour consulter ceux qui ont été accordés. Consultez le tableau ci-dessous pour en savoir plus sur les domaines."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "État"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "&state=y"
            }), "Si ce paramètre est inclus dans l'URL d'autorisation, la valeur sera incluse dans un paramètre de requête d'état lorsque l'utilisateur sera dirigé vers le ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_url"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une valeur de chaîne pouvant être utilisée pour maintenir l'état de l'utilisateur lorsqu'il est redirigé vers votre application."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurer des domaines"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "OAuth exige que vous définissiez des domaines, ou des autorisations, pour votre application. Chaque domaine fournit un accès à un ensemble de points de terminaison d'API HubSpot et permet aux utilisateurs d'accorder à votre application l'accès à des outils spécifiques dans leur compte HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/granular-scopes-selection.png",
        alt: "granular-scopes-selection"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'accès à des API ou points de terminaison spécifiques dépend du niveau de compte HubSpot. Vous pouvez trouver une liste complète des domaines disponibles et des points de terminaison accessibles dans le tableau ci-dessous. Si votre application peut fonctionner avec plusieurs types de comptes HubSpot, vous pouvez utiliser le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "optional_scope"
      }), " pour inclure des domaines de travail. Ainsi, les clients utilisant uniquement des comptes de CRM peuvent toujours autoriser votre application, même s'ils ne peuvent pas accéder à tous ses domaines. Votre application doit vérifier et traiter tous les domaines pour lesquels elle ne reçoit pas d'autorisation."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Domaine"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Donne accès au"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Niveau de compte requis"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les intégrateurs peuvent répertorier les domaines CMS dans le compte d'un client."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API du CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les intégrateurs peuvent créer, mettre à jour et supprimer des domaines personnalisés CMS."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API du CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les intégrateurs peuvent afficher toutes les fonctions sans serveur CMS, tous les secrets associés et les résultats d'exécution de fonction."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API du CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Entreprise"]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les intégrateurs peuvent écrire des fonctions sans serveur CMS et des secrets."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API du CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Afficher les détails concernant des articles de connaissances."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API du CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permet d'accéder à la mise à jour d'articles de connaissances."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API du CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Pro"]
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.publish"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permet d'accéder à la mise à jour et à la publication d'articles de connaissance."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API du CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Pro"]
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les paramètres généraux et de la base de connaissances, tels que l'URL du domaine ou de la racine."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API du CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Pro"]
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour mettre à jour les paramètres généraux et de la base de connaissances. Cela inclut les droits d'écriture sur les articles de connaissances."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API du CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Pro"]
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.performance.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les intégrateurs peuvent afficher les données sur les performances CMS pour tous vos sites."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API du CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les détails concernant des listes de contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison de listes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour créer, supprimer ou apporter des modifications à des listes de contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison de listes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les propriétés et d'autres détails sur les entreprises."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des entreprises"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les propriétés et créer, supprimer ou apporter des modifications aux entreprises."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des entreprises"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les propriétés et d'autres détails sur les contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des contacts"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les propriétés et créer, supprimer ou apporter des modifications aux contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des contacts"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les détails des objets personnalisés dans le CRM d’HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des objets personnalisés"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tout compte ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour créer, supprimer ou apporter des modifications à des objets personnalisés dans le CRM d’HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des objets personnalisés"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tout compte ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les propriétés et d'autres détails sur les transactions."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des transaction"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les propriétés et créer, supprimer ou apporter des modifications aux transactions."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des transaction"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.feedback_submission.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les détails des soumissions pour n'importe laquelle de vos enquêtes de feedback."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des enquêtes de feedback"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.goals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher tous les types d'objectifs."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des objectifs"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Sales Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter"
            }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les propriétés et d'autres détails sur les lignes de produits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des lignes de produit"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour créer, supprimer ou apporter des modifications à des lignes de produits."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des lignes de produit"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les détails des événements marketing."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des événements"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour créer, supprimer ou apporter des modifications à des événements marketing."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des événements marketing"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.owners.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher des détails concernant les utilisateurs attribués à une fiche d'informations du CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des propriétaires"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les propriétés et d'autres détails sur les devis et modèles de devis."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des devis"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour créer, supprimer ou apporter des modifications à des devis."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des devis"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher des détails concernant les paramètres de propriété pour des entreprises."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des propriétés"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour créer, supprimer ou apporter des modifications aux paramètres de propriété concernant des entreprises."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des propriétés"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher des détails concernant les paramètres de propriété pour des contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des propriétés"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour créer, supprimer ou apporter des modifications aux paramètres de propriété concernant des contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des propriétés"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les détails des définitions d'objets personnalisés dans le CRM d’HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des objets personnalisés"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tout compte ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher des détails concernant les paramètres de propriété pour des transactions."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des propriétés"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour créer, supprimer ou apporter des modifications aux paramètres de propriété concernant des transactions."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des propriétés"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les détails sur les lignes de produits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des lignes de produit"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les détails sur les devis et modèles de devis."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des devis"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.billing.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour modifier les paramètres de facturation de votre compte. Cela inclut la gestion et l'attribution de licences payantes pour les utilisateurs."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des paramètres"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour lire un des taux de change existants avec la devise d'entreprise actuellement associée à votre portail."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des informations de compte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour créer, mettre à jour et supprimer les taux de change ainsi que la mise à jour de la devise de l'entreprise associée à votre portail."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des informations de compte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les détails concernant les utilisateurs du compte et leurs autorisations."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison de provisionnement des utilisateurs"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour gérer les utilisateurs et les autorisations des utilisateurs sur votre compte HubSpot. Cela inclut la création de nouveaux utilisateurs, l'attribution d'autorisations et de rôles et la suppression d'utilisateurs existants."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison de provisionnement des utilisateurs"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.teams.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour consulter les détails des équipes du compte."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison de provisionnement des utilisateurs"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.team.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour attribuer des utilisateurs à des équipes sur votre compte HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison de provisionnement des utilisateurs"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "account-info.security.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprend l'accès aux journaux d'activité du compte et à d'autres informations sur la sécurité du compte."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de l'activité du compte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accounting"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permet à HubSpot et à l'intégration de comptabilité de partager des informations sur les factures, les produits et les contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API pour les extensions comptables"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "actions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour ajouter des formulaires aux pages du contact qui effectuent des actions personnalisées."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API des extensions CRM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "analytics.behavioral_events.send"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclut l'accès à l'envoi d'événements comportementaux personnalisés."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API Analytics"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cela inclut les workflows."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API Automatisation (points de terminaison des workflows)"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "behavioral_events.event_definitions.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour créer, lire, mettre à jour ou supprimer des événements comportementaux. Cela inclut les propriétés des événements comportementaux."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API Analytics"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business_units.view.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les données sur l'unités commerciale, y compris les informations sur le logo."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API d'unités commerciales"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Module complémentaire pour les unités d'entreprise"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business-intelligence"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprend les points de terminaison situés sur les sources et les e-mails."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API Analytics"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_query.execute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Demander des données depuis votre compte HubSpot via le point de terminaison d'API GraphQL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Point de terminaison d'API GraphQL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_schema.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Effectuer des demandes d'introspection via des clients d'application GraphQL tels que GraphiQL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GraphiQL et autres clients GraphQL tiers"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Pro"]
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les détails des préférences d'abonnement de vos contacts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API des préférences d'abonnement"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour abonner/désabonner des contacts de vos types d'abonnement. Cela n'abonnera pas les contacts qui se sont désabonnés."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API des préférences d'abonnement"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour abonner/désabonner des contacts de vos types d'abonnement. Cela n'abonnera pas les contacts qui se sont désabonnés."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API des préférences d'abonnement"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprend les sites, les pages de destination, les e-mails, les blogs et les campagnes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison de l'API CMS et du calendrier, des e-mails et des événements par e-mail"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise,"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affichez des détails concernant les fils dans la boîte de réception des conversations."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de boîte de réception des conversations et de messages"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.visitor_identification.tokens.create"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Récupérez des jetons d'identification pour les visiteurs de sites web authentifiés qui interagissent avec le widget de chat HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API Identification des visiteurs"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tout compte ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour envoyer des messages dans les conversations, Créez et mettez à jour des fils de discussion."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de boîte de réception des conversations et de messages"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.export"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour exporter les fiches d'informations de votre CRM pour tous les types de données CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API d'exports CRM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.import"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vous permet d'importer des fiches d'informations dans votre CRM. Cela inclut la création de nouvelles fiches d'informations ou la modification de vos fiches d'informations existantes pour tous les types de données du CRM (contacts, entreprises, transactions, tickets, etc.). Cela n'inclut pas l'archivage ou la suppression de données."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API Imports de CRM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ctas.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permet l'accès en lecture des CTA."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aucun publicAPI disponible"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })]
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter, Pro ou Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprend l'accès aux fonctionnalités de e-commerce."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des produits et des lignes de produit"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "external_integrations.forms.access"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclut la possibilité de renommer, supprimer et cloner des formulaires existants."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des formulaires"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprend l'accès au gestionnaire de fichiers."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des fichiers (gestionnaire de fichiers) et du mappeur de fichiers (modèles, modules et mises en page CMS)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files.ui_hidden.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pour afficher les détails ou télécharger des fichiers utilisateur, des pièces jointes ou des fichiers système à partir de l'ensemble des outils de HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des fichiers (gestionnaire de fichiers) et du mappeur de fichiers (modèles, modules et mises en page CMS)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprend l'accès aux points de terminaison des formulaires."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des formulaires"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms-uploaded-files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Téléchargez des fichiers soumis via un formulaire."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obtenir un fichier chargé via le point de terminaison de soumission de formulaire"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprend l'accès à HubDB."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison HubDB"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            }), ", ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            }), " avec ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/website",
              children: "module complémentaire Site web"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "integration-sync"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Expose l'API de synchronisation, qui permet la synchronisation de la plupart des objets de CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API Bridge e-commerce"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permet d'accéder aux événements et aux objets depuis la passerelle multimédia."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de passerelle multimédia"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permet d'accéder à la création et à la mise à jour d'événements et d'objets à partir de la passerelle multimédia."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de passerelle multimédia"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "oauth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le domaine de base nécessaire pour OAuth. Ce périmètre d'accès est ajouté par défaut à toutes les applications."
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sales-email-read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permet d'accéder à tous les détails des e-mails individuels envoyés aux contacts."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Points de terminaison des engagements", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Remarque :"
            }), " Ce domaine est obligatoire pour obtenir le contenu des engagements par e-mail. Consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/engagement-details#email-content",
              children: "vue d'ensemble des engagements"
            }), " pour plus de détails."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cela inclut la boîte de réception des réseaux sociaux"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API Réseaux sociaux"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pro"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Entreprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprend l'accès aux tickets."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des tickets"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permet d'accéder à la gestion d’événements personnalisés sur les fiches d'informations du CRM d’HubSpot. Cela inclut la création ou la mise à jour des fiches d'informations."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des événements chronologiques"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tout compte"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transactional-email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Comprend les e-mails transactionnels et les points de terminaison des e-mails transactionnels."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Points de terminaison des e-mails transactionnels"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["**Marketing Hub** Pro ou Entreprise avec le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/email/transactional-email",
              children: "module complémentaire E-mails transactionnels"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Documents associés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "Méthodes d'authentification sur HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/oauth-quickstart-guide",
        children: "Guide de démarrage rapide pour OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/oauth/tokens",
        children: "Gestion des jetons"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}