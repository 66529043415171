"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42366481072;
const slug = exports.slug = 'guides/api/library/files';
const title = exports.title = 'CMS API | ファイルマネージャー';
const name = exports.name = 'APAC JAPAN (JA) | vNext Docs DP - Files | 202104';
const metaDescription = exports.metaDescription = 'ファイルエンドポイントは、ファイルマネージャー上のデータの取得および管理に使用します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E3%82%A2%E3%83%83%E3%83%97%E3%83%AD%E3%83%BC%E3%83%89%E3%81%99%E3%82%8B",
  "label": "ファイルをアップロードする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E3%82%A2%E3%83%83%E3%83%97%E3%83%AD%E3%83%BC%E3%83%89%E3%82%B9%E3%83%86%E3%83%BC%E3%82%BF%E3%82%B9%E3%82%92%E7%A2%BA%E8%AA%8D%E3%81%99%E3%82%8B",
  "label": "ファイルのアップロードステータスを確認する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E8%A9%B3%E7%B4%B0%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B",
  "label": "ファイルの詳細を表示する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "ファイルを削除する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A9%E3%83%AB%E3%83%80%E3%83%BC%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "フォルダーを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "v3%E3%81%A7%E3%81%AE%E5%A4%89%E6%9B%B4%E7%82%B9",
  "label": "v3での変更点",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "ファイルマネージャー"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot内でファイルを管理および保存するには、HubSpotのファイルツールを使用します。HubSpotでホスティングされているファイルは、HubSpotと外部コンテンツの両方にアップロードして使用できます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "エンゲージメントAPI"
      }), "を使用して、ファイルをレコードに添付することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのCMSを使用してウェブサイトを構築する場合、ファイルAPIを使用してアセットをHubSpotにアップロードして保存できます。これらのファイルは、HubSpot CMSを介して提供および共有することができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ファイルツールは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/files/upload-files-to-use-in-your-hubspot-content",
        children: "HubSpot内"
      }), "で、またはファイルAPI経由で利用できます。以下では、ファイルAPIについてと、ファイルのアップロード方法および削除方法について説明します。ファイルAPIエンドポイントの一覧を確認するには、ページ上部にある［エンドポイント］タブをクリックしてください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルをアップロードする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ファイルをアップロードするには、以下のフィールドを指定したmultipart/form-data ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files"
      }), "に送信します。アップロード時に特定のフォルダーIDは必須ではありませんが、ファイルのアップロード先はルートディレクトリーではなくフォルダーにすることが推奨されます。アップロード時のフォルダーが必要かどうかは、今後変更になる可能性があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ファイル"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アップロードするファイル（必須）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ファイルの公開設定とインデックス登録を制御するJSONオブジェクトで、2つのフィールドが含まれています。必須の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "access"
            }), "と、ファイルが自動的に削除されるまでの期間を指定する", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ttl"
            }), "です。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ttl"
            }), "フィールドを使用する場合：", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "設定できる最短期間は1日です。"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "設定できる最長期間は1年です。"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "設定された期間が経過すると、ファイルは完全に削除されます。削除された後、ファイルを回復または復元することはできません。"
              })]
            }), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ファイルのアップロード先とするフォルダーのID。リクエストには、このフィールド", (0, _jsxRuntime.jsx)("u", {
              children: "または"
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderPath"
            }), "のいずれかを指定する必要があります（両方を指定することは", (0, _jsxRuntime.jsx)("u", {
              children: "できません"
            }), "）。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderPath"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ファイルのアップロード先とするフォルダーのパス。リクエストには、このフィールド", (0, _jsxRuntime.jsx)("u", {
              children: "または"
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderId"
            }), "のいずれかを指定する必要があります（両方を指定することは", (0, _jsxRuntime.jsx)("u", {
              children: "できません"
            }), "）。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fileName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ファイルの名前。名前が指定されていない場合は、ファイルの内容に基づく名前が生成されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "charsetHunch"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アップロードするファイルのエンコーディングとして使用されている文字セット。指定されていない場合、ファイルから取得されます。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、次の条件を満たすファイルをHubSpotアカウントにアップロードする場合："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ファイル名："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "cat.png"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubSpotファイルマネージャーの移動先フォルダー："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "/library/cat_archive"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**HubSpotでのファイルのアクセシビリティー：**非公開でアクセス可能"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のヘッダーとリクエスト本文は、リクエストの一部である必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url 'https://api.hubapi.com/files/v3/files?=' \\\n  --header 'Authorization: Bearer pat-na1-00000000-0000-0000-0000-000000000000' \\\n  --header 'Content-type: multipart/form-data' \\\n  --form file=@/Users/person/Downloads/cat.png \\\n  --form 'options={\"access\": \"PRIVATE\"}' \\\n  --form folderPath=/library/cat_archive\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["結果のレスポンスには、アップロードされたファイルの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "parentFolderId"
      }), "が含まれます。これを使用して、GETリクエストを介してファイルを取得できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// 201 Response from successful file upload\n{\n  \"id\": \"122692044085\",\n  \"createdAt\": \"2023-06-28T17:56:45.393Z\",\n  \"updatedAt\": \"2023-06-28T17:56:45.393Z\",\n  \"archived\": false,\n  \"parentFolderId\": \"122692510820\",\n  \"name\": \"cat\",\n  \"path\": \"/library/cat_archive/cat.png\",\n  \"size\": 24574,\n  \"height\": 219,\n  \"width\": 225,\n  \"encoding\": \"png\",\n  \"type\": \"IMG\",\n  \"extension\": \"png\",\n  \"defaultHostingUrl\": \"https://12345.fs1.hubspotusercontent-na1.net/hubfs/12345/library/cat_archive/cat.png\",\n  \"url\": \"https://12345.fs1.hubspotusercontent-na1.net/hubfs/12345/library/cat_archive/cat.png\",\n  \"isUsableInContent\": true,\n  \"access\": \"PRIVATE\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルのアップロードステータスを確認する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/import-from-url/async"
      }), "への", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを使用して、ファイルをURLからファイルマネージャーにインポートする場合は、ファイルのアップロードステータスを確認できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これを行うには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/import-from-url/async/tasks/{taskId}/status"
      }), "への", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このリクエストを送信すると、次のいずれかの返信が届きます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING"
        }), "：ファイルはアップロード待ちのキューにあります。インポートプロセスはまだ開始されていません。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), "：ファイルはアップロード中です。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELED"
        }), "：アップロードはキャンセルされ、ファイルはアップロードされません。ファイルをHubSpotアカウントにインポートするには、ファイルを再度アップロードする必要があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "COMPLETE"
        }), "：ファイルがファイルツールに正常にアップロードされました。アップロードされたファイルがファイルツールに表示されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルの詳細を表示する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ファイルツールにアップロードされたファイルの詳細を確認するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}"
      }), "に送信します。これにより、名前、高さと幅、エンコード、URLなどの詳細情報と共にファイルが返されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、ファイルの詳細を取得するには、次のようにします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ファイルが非公開に設定されている場合、URLの結果として404エラーが返されます。ファイルの表示可能なURLを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/files/v3/files/{fileId}/signed-url"
      }), "に送信します。このリクエストを送信するときには、高さと幅などの特定のプロパティーが返されるように", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property"
      }), "パラメーターを含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ファイルを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ファイルを削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}"
      }), "に送信します。これにより、ファイルは削除済みになり、ファイルの内容にアクセスできなくなります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ファイルを完全に削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}/gdpr-delete"
      }), "に送信します。これにより、ファイルの内容とメタデータが7日以内に完全に削除されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "GDPRを適用しない削除方法の場合、ファイルの内容は、誰もアクセスできない非公開の状態でHubSpotのサーバーに残ります。ファイルの内容を完全に削除するには、GDPR削除機能を使用します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フォルダーを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["フォルダーを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/folders"
      }), "に送信します。このリクエストには、以下のフィールドを含めることができます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "必須"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Yes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "作成するフォルダーの名前。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["既存のフォルダー内にフォルダーを作成するには、このフィールドを含め、フィールドの値としてその既存のフォルダーのIDを設定します。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            }), "と", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            }), "を同時に設定することはできません。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["既存のフォルダー内にフォルダーを作成するには、このフィールドを含めて、フィールドの値としてその既存のフォルダーのパスを設定します。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            }), "と", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            }), "を同時に設定することはできません。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body of POST request to /files/v3/folders\n{\n  \"name\": \"myNewFolder\",\n  \"parentFolderId\": 12345\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "v3での変更点"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ファイルAPIの旧バージョンを使用している場合、v3には次の変更点があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "このAPI経由でアップロードした全てのファイルは、ファイルダッシュボードおよびファイル選択時に表示されます。非表示ファイルを作成することはできません。ただし、非公開ファイルとインデックス登録不可のファイルは引き続き作成できます。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["ファイルのリストでは、非表示のファイルや削除済みのファイルが返されません。ただし、適用できるフィルターの種類が大幅に増えました。非表示のファイルは引き続きIDを指定して取得できますが、その場合は新しいスコープ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "files_ui_hidden.read"
          }), "が必要になります。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "1回のリクエストで複数のファイルをアップロードすることはできません。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "移動や名前変更などのフォルダー更新アクションは非同期になりました。リクエストごとに、フォルダーの編集ステータスの確認に使用できるトークンが返されます。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "ファイルを作成または置換するエンドポイントを使用するには、ファイルのアクセスレベルを指定する必要があります。このアクセスレベルを以下に示します。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLIC_INDEXABLE"
            }), "**：**ファイルが一般に公開され、URLを知っている全てのユーザーがアクセスできます。このファイルは、検索エンジンによるインデックス登録が可能です。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLIC_NOT_INDEXABLE"
            }), "**：**ファイルが一般に公開され、URLを知っている全てのユーザーがアクセスできます。ファイルの取得時には常に「X-Robots-Tag: noindex」ヘッダーが送信され、検索エンジンに対し、ファイルをインデックス登録しないよう通知されます。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["**", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PRIVATE"
            }), "：**ファイルは一般公開されません。コンテンツを表示するには署名付きURLが必要です。検索エンジンはこのファイルのインデックス登録を行うことができません。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "ファイルを作成するエンドポイントでは、ファイルのアップロードオプションの一部として、重複検出レベルを指定できます。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "ENTIRE_PORTAL"
            }), "**：**アカウント内で重複するファイルを検索します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "EXACT_FOLDER"
            }), "**：**指定されたフォルダー内で重複するファイルを検索します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "NONE"
            }), "**：**重複の検証を実行しません。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "REJECT"
            }), "**：**重複が検出された場合はアップロードを拒否します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "RETURN_EXISTING"
            }), "**：**重複するファイルが検出された場合は、新しいファイルをアップロードせずに、検出された重複ファイルを返します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["重複検出は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duplicateValidationScope"
            }), "を基に機能するため、重複の検索にも影響します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["重複が検出された場合の処理を指示する", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duplicateValidationStrategy"
            }), "も必要です。"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}