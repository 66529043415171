"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42296314968;
const slug = exports.slug = 'guides/api/crm/extensions/video-conferencing';
const title = exports.title = 'CRM API | ビデオ会議拡張機能';
const name = exports.name = 'APAC JAPAN (JA) | WEB | VCE | 202207';
const metaDescription = exports.metaDescription = 'これらのエンドポイントは、HubSpot内のミーティング作成フローに接続し、ビデオ会議情報を追加するために使用します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E8%A8%AD%E5%AE%9Aapi",
  "label": "設定API",
  "parent": null
}, {
  "depth": 1,
  "id": "%E8%A8%AD%E5%AE%9A%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AE%E5%AE%9A%E7%BE%A9",
  "label": "設定オブジェクトの定義",
  "parent": "%E8%A8%AD%E5%AE%9Aapi"
}, {
  "depth": 1,
  "id": "%E3%83%93%E3%83%87%E3%82%AA%E4%BC%9A%E8%AD%B0%E6%8B%A1%E5%BC%B5%E6%A9%9F%E8%83%BD%E3%81%AE%E8%A8%AD%E5%AE%9A%E3%82%92%E4%BD%9C%E6%88%90%E3%81%BE%E3%81%9F%E3%81%AF%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "ビデオ会議拡張機能の設定を作成または更新する",
  "parent": "%E8%A8%AD%E5%AE%9Aapi"
}, {
  "depth": 1,
  "id": "%E3%83%93%E3%83%87%E3%82%AA%E4%BC%9A%E8%AD%B0%E6%8B%A1%E5%BC%B5%E6%A9%9F%E8%83%BD%E3%81%AE%E8%A8%AD%E5%AE%9A%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "ビデオ会議拡張機能の設定を取得する",
  "parent": "%E8%A8%AD%E5%AE%9Aapi"
}, {
  "depth": 1,
  "id": "%E3%83%9F%E3%83%BC%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0webhook%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "ミーティングWebhookを作成する",
  "parent": "%E8%A8%AD%E5%AE%9Aapi"
}, {
  "depth": 1,
  "id": "%E3%83%9F%E3%83%BC%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0%E6%9B%B4%E6%96%B0webhook",
  "label": "ミーティング更新Webhook",
  "parent": "%E8%A8%AD%E5%AE%9Aapi"
}, {
  "depth": 1,
  "id": "%E3%83%9F%E3%83%BC%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0%E5%89%8A%E9%99%A4webhook",
  "label": "ミーティング削除Webhook",
  "parent": "%E8%A8%AD%E5%AE%9Aapi"
}, {
  "depth": 1,
  "id": "%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E7%A2%BA%E8%AA%8Dwebhook",
  "label": "ユーザー確認Webhook",
  "parent": "%E8%A8%AD%E5%AE%9Aapi"
}, {
  "depth": 1,
  "id": "webhook%E7%BD%B2%E5%90%8D",
  "label": "Webhook署名",
  "parent": "%E8%A8%AD%E5%AE%9Aapi"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ol: "ol",
      li: "li",
      h2: "h2",
      a: "a",
      h3: "h3",
      code: "code",
      strong: "strong",
      pre: "pre",
      ul: "ul",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "ビデオ会議拡張機能"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ビデオ会議APIを使用することにより、HubSpot内のミーティングの作成フローに接続し、ビデオ会議情報を追加できます。このAPIを使用するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ビデオ会議拡張機能Webhookを使用してアプリをセットアップします。顧客がミーティングを作成または更新した際の通知に使用するURIを、HubSpotに提供します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ミーティング作成Webhookと、必要に応じてミーティング更新Webhookの処理を行います。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "必要に応じてユーザーID検証Webhookの処理を行います。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "設定API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発者はこの設定APIを使用して既存のアプリケーションをセットアップします。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "非公開アプリ"
      }), "のアクセストークンを使用して、リクエストを認証できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "設定オブジェクトの定義"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "設定オブジェクトには次のフィールドがあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "createMeetingUrl"
      }), "：新しいビデオ会議のリクエストを送信する際のURL。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https"
      }), "プロトコルを使用する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "updateMeetingUrl"
      }), "：（任意）既存のミーティングに対する更新を送信する際にHubSpotが使用するURL。このURIは通常、ユーザーがミーティングのトピックや時間を変更すると、呼び出されます。httpsプロトコルを使用する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "deleteMeetingUrl"
      }), "：（任意）ミーティングが削除されたことを通知する際にHubSpotが使用するURL。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "userVerifyUrl"
      }), "：（任意）ユーザーがシステム上に存在することを確認する際にHubSpotが使用するURL。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ビデオ会議拡張機能の設定を作成または更新する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "リクエストの例："
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT /crm/v3/extensions/videoconferencing/settings/{appId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update-meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "レスポンスの例："
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example 200 response\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update-meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "レスポンスの例："
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "任意の値はリクエストから除外してください。空の文字列や他の値を指定すると、望ましくない動作になる可能性があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ビデオ会議拡張機能の設定を取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "リクエストの例："
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET /crm/v3/extensions/videoconferencing/settings/{appId}   "
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "レスポンスの例："
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example 200 response\n{\n\"createMeetingUrl\": \"https://example.com/create-meeting\",\n\"updateMeetingUrl\": \"https://example.com/update-meeting\"\n\"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n\"userVerifyUrl\": \"https://example.com/user-verify\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ミーティングWebhookを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ミーティングが作成されると、HubSpotが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createMeetingUri"
      }), "にリクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "リクエストの例："
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request\n{\n\"portalId\": 123123,\n\"userId\": 123,\n\"userEmail\": \"test.user@example.com\",\n\"topic\": \"A Test Meeting\",\n\"source\": \"MEETINGS\"\n\"startTime\": 1534197600000,\n\"endTime\": 1534201200000\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このリクエストのフィールドを以下に示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "portalId"
        }), "：（ポータルと呼ばれる）HubSpotアカウントのID。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), "：ミーティングを所有するHubSpotユーザーの固有ID。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userEmail"
        }), "：ミーティングを所有するHubSpotユーザーのEメールアドレス。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "topic"
        }), "：ミーティングのトピック／タイトル。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "source"
        }), "：ミーティングが作成されたHubSpotアプリ内のミーティング機能を示す、MEETINGSまたはMANUALのいずれか。", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "MEETINGSは、", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "「ミーティングリンク」機能を示します。MANUALは、", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "CRMのコミュニケーション機能で作成されたミーティングを示します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "startTime"
        }), "：ミーティングの開始時刻（エポックミリ秒）。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "endTime"
        }), "：ミーティングの終了時刻（エポックミリ秒）。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このWebhookを正常に処理するには、開発者がこのミーティング用のビデオ会議を生成（または、既存の会議にリンク）し、その会議に関する情報をレスポンスとして返す必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "レスポンスの例："
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example response\n{\n  \"conferenceId\": \"some-unique-id\",\n  \"conferenceUrl\": \"https://example.com/join\",\n  \"conferenceDetails\": \"Click here to join: https://example.com/join\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このレスポンスで想定されるフィールドを以下に示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceId"
        }), "：このイベントの会議に関連付けられた固有のID。", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "このIDはシステム全体で重複しない値にする必要があります。HubSpotはこのIDを", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "更新Webhookを通じて返します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceUrl:"
        }), "：作成された会議に参加するためのリンク。これは", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "イベントの「ロケーション」フィールドに配置できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceDetails"
        }), "：「招待」情報のプレーンテキスト。ここでは、", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "イベントの出席者に対し、このビデオ会議にアクセスする方法を", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "説明します。このテキスト内では改行は維持されますが、", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "その他の書式はサポートされません。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ミーティング更新Webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "updateMeetingUri"
      }), "を指定した場合、ミーティング関連の情報が変更されるたびに、HubSpotはこのURIにリクエストを送信します。ビデオ会議のトピックまたは開催時間を最新の状態に維持する場合には、この通知が欠かせません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "リクエストの例："
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"conferenceId\": \"some-unique-id\",\n  \"userId\": 123,\n  \"userEmail\": \"test.user@example.com\",\n  \"portalId\": 123123,\n  \"topic\": \"A Test Meeting (updated)\",\n  \"startTime\": 1534197600000,\n  \"endTime\": 1534201200000\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このレスポンスで想定されるフィールドを以下に示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceId"
        }), "：ミーティング作成Webhookへのレスポンスとして、貴社の連携から提供される会議の固有ID。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), "：ミーティングを所有するHubSpotユーザーの固有ID。このIDは常に、ミーティング作成リクエストで指定されたuserIdの値と同じになります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userEmail"
        }), "：ミーティングを所有するHubSpotユーザーのEメールアドレス。このEメールアドレスは常に、ミーティング作成リクエストで指定されたuserEmailの値と同じになります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "topic"
        }), "：ミーティングのトピック／タイトル。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "startTime"
        }), "：ミーティングの開始時刻（エポックミリ秒）。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "endTime"
      }), "：ミーティングの終了時刻（エポックミリ秒）。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらのリクエストに応答する際のレスポンスに、本文は必要ありません。必要なのは、", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "このWebhookが正常に受信されたかどうかを通知する200または204のレスポンスコードのみ", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ミーティング削除Webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ミーティングが削除されると、HubSpotはリクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "deleteMeetingUri"
      }), "に送信します。", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "リクエストの例："
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"conferenceId\": \"some-unique-id\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このリクエストには、削除されたミーティングのconferenceIdだけが格納されます。", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "これらのリクエストに応答する際のレスポンスに、本文は必要ありません。必要なのは、", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "このWebhookが正常に受信されたかどうかを通知する200または204のレスポンスコードのみ", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ユーザー確認Webhook"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotのシステムでは、ユーザーのHubSpot上のユーザーIDとHubSpotアカウントのEメールアドレスが常に貴社のシステムと確認されます。HubSpotのシステム内のユーザーが、貴社のシステム上で別のEメールアドレスまたはIDとして存在する可能性があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotはウェブ会議のリンクを作成、更新、または削除するために貴社のシステムを呼び出す前に、貴社の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userVerifyUri"
      }), "が設定されているかどうかを確認します。このURIが設定されている場合はそれを呼び出して、ネイティブユーザーIDを取得します。取得したIDをユーザーのEメールアドレスとして", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "以降の呼び出しで送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotアプリケーションでの箇所によっては、ユーザーが操作できるUIコンポーネントを表示する前に、事前検証の一環として、ユーザーが貴社のシステム上に存在することを確認するための呼び出しが行われます。ユーザー確認用のURIが設定されていない場合、HubSpotは常に、ユーザーのIDを確認済みと見なします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この機能への対応は任意です。システム内でユーザーマッピングを維持する必要があれば、呼び出しごとにHubSpotユーザーのIDまたはEメールアドレスを内部ユーザーIDにマッピングすることも可能です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "このリクエストの例を次に示します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"portalId\": 123123,\n  \"userEmail\": \"test.user@example.com\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "200でも、エラーコード（404が適切です）でも返してかまいません。200を返す場合は、「Eメールアドレス」として使用できる新しいIDを格納したペイロードを返してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example response\n{\n  \"id\": \"any-string-id\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhook署名"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotから送信される全てのWebhookには、貴社のアプリケーションの「アプリシークレット」を使用したHMACの署名が付けられます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "このページ"
      }), "の「セキュリティー」セクションを参照してください：（このWebhook［概要］ページの残りの部分は、これらのビデオ会議拡張機能Webhookには当てはまりません）。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}