"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501378057;
const slug = exports.slug = 'guides/api/analytics-and-events/event-analytics';
const title = exports.title = 'Eventos de API | Evento Analíticas';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Web Analytics';
const metaDescription = exports.metaDescription = 'El punto de terminación Eventos de analíticas te permite buscar y filtrar eventos asociados con un objeto de CRM de cualquier tipo. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "consultar-eventos-individuales-completados",
  "label": "Consultar eventos individuales completados",
  "parent": null
}, {
  "depth": 1,
  "id": "seleccionar-por-id-de-objeto",
  "label": "Seleccionar por ID de objeto",
  "parent": "consultar-eventos-individuales-completados"
}, {
  "depth": 1,
  "id": "seleccionar-por-propiedad-de-objeto",
  "label": "Seleccionar por propiedad de objeto",
  "parent": "consultar-eventos-individuales-completados"
}, {
  "depth": 0,
  "id": "consulta-y-filtrado-de-tipos-de-eventos",
  "label": "Consulta y filtrado de tipos de eventos",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Evento Analíticas"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza la API de análisis de eventos para buscar eventos asociados con registros de CRM de cualquier tipo (solo ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sales Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Service Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " o ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), "). Esto incluye eventos estándar, como visitas a páginas web y aperturas de correos electrónicos, además de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "eventos personalizados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, usa esta API para ver una cronología de las interacciones que un contacto ha tenido con tu contenido. Luego puedes usar esta cronología para crear un conjunto de datos para analíticas personalizadas o presentar una cronología de contacto en una aplicación externa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Consultar eventos individuales completados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta API devuelve eventos para un registro de CRM a la vez. Puedes seleccionar el registro especificando el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " e incluir el parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seleccionar por ID de objeto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para especificar un registro por su ID, agrega el parámetro de consulta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), ". Por ejemplo, para especificar un registro de contacto con el ID de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "2832"
      }), ", deberías hacer la siguiente solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/?objectType=contact&objectId=224834"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seleccionar por propiedad de objeto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para especificar un registro por una propiedad única en lugar de ID de contacto, agrega el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty"
      }), ". Consulta la propiedad incluyendo el nombre de la propiedad y el valor en el siguiente formato:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty.{propname}={propvalue}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, para especificar un contacto por su dirección de correo electrónico, deberías hacer la siguiente solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/?objectType=contact&objectProperty.email=user12@dev.com"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Consulta y filtrado de tipos de eventos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando buscas para los eventos asociados con un objeto de CRM determinado, la respuesta incluirá todos los tipos de eventos, incluidos eventos de comportamiento personalizados."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para devolver solo finalizaciones de eventos para un tipo de evento específico, puedes incluir un parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), ", seguido del nombre del evento. Para obtener una lista de todos los tipos de eventos disponibles, puedes hacer una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/event-types"
      }), ". La respuesta devolverá todos los tipos de eventos en tu cuenta. A continuación, puedes usar uno de estos nombres de eventos como parámetro de consulta en una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " al punto de terminación ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/eventType={EVENT_NAME}&objectType=contact&objectId=224834"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}