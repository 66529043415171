"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 102629641400;
const slug = exports.slug = 'guides/crm/overview';
const title = exports.title = 'Resumen de las herramientas de desarrollo CRM (BETA)';
const name = exports.name = 'Resumen de las herramientas de desarrollo CRM (BETA)';
const metaDescription = exports.metaDescription = 'La API de HubSpot permite a los desarrolladores crear aplicaciones e integraciones personalizadas. Comienza leyendo nuestro resumen de API y crea una cuenta de desarrollador.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "herramientas-de-desarrollo-de-crm",
  "label": "Herramientas de desarrollo de CRM",
  "parent": null
}, {
  "depth": 1,
  "id": "proyectos",
  "label": "Proyectos",
  "parent": "herramientas-de-desarrollo-de-crm"
}, {
  "depth": 1,
  "id": "aplicaciones-privadas",
  "label": "Aplicaciones privadas",
  "parent": "herramientas-de-desarrollo-de-crm"
}, {
  "depth": 1,
  "id": "extensiones-de-iu",
  "label": "Extensiones de IU",
  "parent": "herramientas-de-desarrollo-de-crm"
}, {
  "depth": 1,
  "id": "funciones-sin-servidor",
  "label": "Funciones sin servidor",
  "parent": "herramientas-de-desarrollo-de-crm"
}, {
  "depth": 1,
  "id": "entornos-de-pruebas-de-desarrollo",
  "label": "Entornos de pruebas de desarrollo",
  "parent": "herramientas-de-desarrollo-de-crm"
}, {
  "depth": 1,
  "id": "integraci%C3%B3n-de-github",
  "label": "Integración de GitHub",
  "parent": "herramientas-de-desarrollo-de-crm"
}, {
  "depth": 0,
  "id": "primeros-pasos",
  "label": "Primeros pasos",
  "parent": null
}, {
  "depth": 0,
  "id": "m%C3%A1s-recursos",
  "label": "Más Recursos",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      img: "img",
      h2: "h2",
      h3: "h3",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      code: "code",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Resumen de las herramientas de desarrollo CRM (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-enterprise', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las herramientas de desarrollo de CRM de HubSpot te permiten personalizar tu CRM con extensiones de IU, con características como entornos de pruebas de desarrollo, control de versiones e integración con GitHub para agilizar tu proceso de desarrollo. A partir de tu entorno local, puedes utilizar estas herramientas para crear e implementar extensiones de IU en registros de CRM para personalizar la experiencia del usuario junto con el envío y recuperación de datos según sea necesario."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, puedes crear una tarjeta de aplicación personalizada que obtenga datos de una fuente externa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/custom-extension-example.png",
        alt: "custom-extension-example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, obtén más información sobre las herramientas de desarrollo de CRM y cómo empezar a utilizarlas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Herramientas de desarrollo de CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Proyectos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los proyectos son el contenedor de más alto nivel que crearás utilizando las herramientas de desarrollo de CRM. Los proyectos te permiten crear e implementar localmente aplicaciones privadas, extensiones de IU y funciones sin servidor utilizando la CLI. Una vez implementado en una cuenta, puedes ver y gestionar el proyecto y sus aplicaciones y extensiones de IU en HubSpot. Esto incluye ver el historial de generación y monitorizar las llamadas a la API."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-a-project",
        children: "creación de proyectos"
      }), ", y echa un vistazo a la creciente lista de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "proyectos de muestra"
      }), " de HubSpot para ver ejemplos de lo que es posible."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicaciones privadas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las aplicaciones privadas te permiten enviar y recuperar datos utilizando el token de acceso de la aplicación privada para la autenticación. Las aplicaciones privadas también ejecutan funciones sin servidor que sirven de back-end para las extensiones de IU. Un proyecto puede contener una aplicación privada, y cada aplicación privada puede contener varias extensiones de IU y funciones sin servidor."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-private-apps-with-projects",
        children: "creación de aplicaciones privadas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Las aplicaciones privadas creadas con proyectos solo admiten actualmente la creación de extensiones de IU. Si quieres crear una aplicación privada para trabajar con otras extensiones de HubSpot, obtén más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "diferentes tipos de aplicaciones y lo que admiten"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Extensiones de IU"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las extensiones de IU son elementos de interfaz de usuario personalizados que los usuarios de la cuenta de HubSpot pueden ver y con los que pueden interactuar. En este momento, las posibles extensiones de la interfaz de usuario incluyen tarjetas de aplicaciones para los registros del CRM y el centro de ayuda. Por ejemplo, puedes crear una tarjeta que permita a los usuarios enviar datos de formularios a una base de datos externa desde cualquier registro de contacto o una tarjeta que los representantes de asistencia al cliente puedan usar para tomar medidas en cuanto a tickets abiertos en la IU del centro de ayuda."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las extensiones de IU se construyen mediante proyectos y constan de un front-end y un back-end:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Front-end de la extensión de la interfaz de usuario:"
        }), " la parte de la extensión orientada al usuario en forma de tarjeta de aplicación que consta de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/overview",
          children: "componentes proporcionados por HubSpot"
        }), ". Además de mostrar información, los usuarios pueden interactuar con los componentes para realizar diversas acciones. Crearás el front-end con React o TypeScript."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Back-end de extensión IU:"
        }), " la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/serverless-functions",
          children: "función sin servidor"
        }), " que permite a una extensión de IU enviar y recuperar datos para mostrarlos en los componentes. Con el respaldo de la aplicación privada del proyecto, una función sin servidor puede ser reutilizada por múltiples componentes según sea necesario."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las tarjetas de aplicaciones que puedes crear con los proyectos son independientes de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/extensions/overview",
        children: "la otra extensión de la API de tarjetas del CRM de HubSpot"
      }), ", y no se pueden crear indistintamente."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/overview",
        children: "cómo funcionan las extensiones de IU"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Funciones sin servidor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una función sin servidor ejecuta JavaScript del lado del servidor para proporcionar una función de back-end a una extensión de IU. Las funciones sin servidor están contenidas en aplicaciones privadas. Las funciones sin servidor consisten en una carpeta que contiene uno o varios archivos JavaScript que exportan una función ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "main"
      }), ", y un archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), " que registra y configura tus funciones."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/serverless-functions",
        children: "creación de funciones sin servidor con proyectos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Aunque similares en concepto, las funciones sin servidor que se usan en los proyectos son diferentes de las funciones sin servidor que utiliza el CMS de HubSpot. Algunas de estas diferencias son"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["No puedes crear una función sin servidor en un proyecto con el comando ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs create function"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Las funciones sin servidor creadas en proyectos no pueden utilizarse para páginas de CMS."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Las funciones sin servidor creadas en proyectos no aparecen en el administrador de diseño."
        }), "\n"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para crear funciones sin servidor para páginas de CMS, consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "documentación para desarrolladores de CMS"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Entornos de pruebas de desarrollo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los entornos de pruebas de desarrollo te permiten desarrollar proyectos en un entorno de pruebas ligero para asegurarte de que los componentes de tu proyecto funcionan como se espera antes de implementarlo en un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "entorno de pruebas estándar o en una cuenta de producción"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los entornos de pruebas de desarrollo se crean a través de la CLI y se puede acceder a ellos desde la cuenta de producción de HubSpot. Los entornos de pruebas de desarrollo sincronizan algunos recursos de cuentas en el momento de la creación, pero no todos, y tienen límites adicionales en comparación con los entornos de pruebas estándar."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/developer-projects-setup",
        children: "configuración de un entorno de pruebas de desarrollo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Integración de GitHub"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si prefieres utilizar GitHub para el control de versiones, puedes conectar un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/link-a-github-repository-to-a-project",
        children: "repositorio de GitHub a un proyecto"
      }), " para desencadenar automáticamente la creación del proyecto cuando introduzcas un cambio en el repositorio conectado. Esto te permite utilizar las herramientas y workflows de GitHub para agilizar el desarrollo, tanto si trabajas solo como con un equipo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Primeros pasos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si eres ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/user-management/hubspot-user-permissions-guide#super-admin",
        children: "superadministrador"
      }), " en una cuenta de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Sales Hub"
      }), " o ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: ["Service Hub ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enterprise"
        })]
      }), ", puedes unirte a esta beta desde la página ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Actualizaciones de productos"
      }), " en tu cuenta de HubSpot:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En tu cuenta de HubSpot, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre de tu cuenta"
          }), " en la esquina superior derecha y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Actualizaciones de productos"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la barra lateral izquierda, selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "En beta"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la lista de versiones beta, busca o desplázate hasta la versión beta de ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Herramientas de desarrollo de CRM para crear extensiones de IU con React como front-end"
          }), ", y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Unirme a la beta"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/crm-development-tools-join-beta.png",
            alt: "crm-development-tools-join-beta"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Después de unirte a la versión beta, comienza con cualquiera de las siguientes opciones:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sigue la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/quickstart",
          children: "guía de inicio rápido"
        }), " para crear e implementar rápidamente una tarjeta de aplicación de ejemplo que funcione."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Echa un vistazo a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/sample-projects/overview",
          children: "Proyectos de muestra de HubSpot"
        }), " para ver ejemplos de lo que es posible."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crea tu proyecto desde cero comenzando con la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "guía de proyectos de HubSpot"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Más Recursos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/CRM-Development-Tools-Beta/gh-p/crm-development-tools-beta",
          children: "Herramientas de desarrollo de CRM en la Comunidad de HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/crm-development-tools-for-hubspot-developers-beta",
          children: "Herramientas de desarrollo de CRM en el blog para desarrolladores de HubSpot"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}