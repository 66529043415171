"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 131362553127;
const slug = exports.slug = 'guides/crm/ui-extensions/sample-extensions/overview';
const title = exports.title = 'Sample UI extensions';
const name = exports.name = 'Sample UI extensions';
const metaDescription = exports.metaDescription = 'Learn about the sample UI extensions that HubSpot provides to get started with developing UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "bidirectional-property-refresh",
  "label": "Bidirectional property refresh",
  "parent": null
}, {
  "depth": 0,
  "id": "build-a-multi-step-flow",
  "label": "Build a multi-step flow",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-deals-summary",
  "label": "Create a deals summary",
  "parent": null
}, {
  "depth": 0,
  "id": "custom-logger-example",
  "label": "Custom logger example",
  "parent": null
}, {
  "depth": 0,
  "id": "display-an-iframe-modal",
  "label": "Display an iframe modal",
  "parent": null
}, {
  "depth": 0,
  "id": "duplicate-contact",
  "label": "Duplicate contact",
  "parent": null
}, {
  "depth": 0,
  "id": "generate-multiple-quotes",
  "label": "Generate multiple quotes",
  "parent": null
}, {
  "depth": 0,
  "id": "create-line-and-bar-charts",
  "label": "Create line and bar charts",
  "parent": null
}, {
  "depth": 0,
  "id": "manage-layouts-flex-and-box",
  "label": "Manage layouts: Flex and Box",
  "parent": null
}, {
  "depth": 0,
  "id": "overlay-example",
  "label": "Overlay example",
  "parent": null
}, {
  "depth": 0,
  "id": "use-crm-data-components",
  "label": "Use CRM data components",
  "parent": null
}, {
  "depth": 0,
  "id": "view-nearby-companies-mapbox-api",
  "label": "View nearby companies: Mapbox API",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      a: "a",
      h2: "h2",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag,
      LinkWithTracking
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!LinkWithTracking) _missingMdxReference("LinkWithTracking", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Sample UI extensions (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Check out HubSpot's sample UI extensions to get familiar with what's possible when building UI extensions. These sample projects can be used as inspiration, reference, or starting points for your own projects."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Unless otherwise noted, these sample UI extensions are built for private apps, which require a ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Sales Hub"
            })
          }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Service Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), " subscription to install in a standard HubSpot account."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["A paid subscription is ", (0, _jsxRuntime.jsx)("u", {
            children: "not"
          }), " required to install sample apps in a developer test account. All of these sample extensions can be used as a inspiration for a public app built in a free developer account."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#bidirectional-property-refresh",
          children: "Bidirectional property refresh"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-a-multi-step-flow",
          children: "Build a multi-step flow"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-deals-summary",
          children: "Create a deals summary"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#custom-logger-example",
          children: "Custom logger example"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#display-an-iframe-modal",
          children: "Display an iframe modal"
        }), (0, _jsxRuntime.jsx)(Tag, {
          children: "Beginner"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#duplicate-contact",
          children: "Duplicate contact"
        }), (0, _jsxRuntime.jsx)(Tag, {
          children: "Beginner"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#generate-multiple-quotes",
          children: "Generate multiple quotes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-line-and-bar-charts",
          children: "Create line and bar charts"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#manage-layouts-flex-and-box",
          children: "Manage layouts: Flex and Box"
        }), (0, _jsxRuntime.jsx)(Tag, {
          children: "Beginner"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#overlay-example",
          children: "Overlay example"
        }), (0, _jsxRuntime.jsx)(Tag, {
          children: "Beginner"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#use-crm-data-components",
          children: "Use CRM data components"
        }), (0, _jsxRuntime.jsx)(Tag, {
          children: "Beginner"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#view-nearby-companies-mapbox-api",
          children: "View nearby companies: Mapbox API"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bidirectional property refresh"
    }), "\n", (0, _jsxRuntime.jsx)(LinkWithTracking, {
      href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/bi-directional-property-refresh",
      linkText: "View the source code in GitHub"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Provides bidirectional refresh of contact property changes between a custom card and the CRM record page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " a salesperson in your account makes frequent changes to a contact's properties and associated fields on a custom card and requires a seamless editing experience to avoid manual page refreshes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "What's included:"
      }), " a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Refresh properties between custom card and CRM page"
      }), " card that displays the latest ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastname"
      }), " property values when changed on the CRM record page, as well as a lifecycle stage dropdown menu that you can update on the card and immediately see the change reflected on the CRM record page."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/bi-directional-crm-record-card-sync.gif",
        alt: "bi-directional-crm-record-card-sync"
      }), (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What you'll learn:"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["How you can use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetchCrmObjectProperties"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "onCrmPropertiesUpdate"
        }), " actions to listen to changes on the CRM record page and fetch the latest values."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["How you can ensure data in the custom card stays up to date with the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "refreshObjectProperties"
        }), " action."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Build a multi-step flow"
    }), "\n", (0, _jsxRuntime.jsx)(LinkWithTracking, {
      href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/multi-step-flow",
      linkText: "View the source code in GitHub"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Send a meal from a local restaurant to one of your contacts."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " with one of their agency partners working overtime to close an upgrade deal, an account manager wants a way to order them dinner from the CRM as a thank you."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "What's included:"
      }), " an example card for contact records featuring search, data validation, custom components, and more."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-sample-multi-step-flow-with-panel.gif",
        alt: "ui-extension-sample-multi-step-flow-with-panel"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What you'll learn:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Searching a table, including pagination."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Implementing realtime form validation."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fetching data asynchronously with HubSpot serverless functions."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Including empty, error, and loading states."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/panel",
          children: "Panel component"
        }), " to display a form."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Getting current contact properties."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Getting current user properties."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Triggering alerts in the CRM outside of the card 's boundaries."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a deals summary"
    }), "\n", (0, _jsxRuntime.jsx)(LinkWithTracking, {
      href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/deals-summary",
      linkText: "View the source code in GitHub"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["View a high-level summary of data from associated deals. Follow the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/deals-summary-tutorial",
        children: "tutorial"
      }), " (also included in the project's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/ui-extensions-examples/blob/main/deals-summary/README.md",
        children: "README.md"
      }), " file) to walk through how to add the extension to your account, create two example deal records to display data from, then customize the extension with additional components."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " a salesperson needs a way to quickly view important deal information from their contact records so that they can plan, prioritize, and report on their efforts."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "What's included:"
      }), " an example card for contact records that aggregates and displays associated CRM record data."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extensions-deals-summary-sample-extension.png",
          alt: "ui-extensions-deals-summary-sample-extension"
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "What you'll learn:"
      }), " how to surface data from associated CRM records and how to customize extensions with additional components."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Custom logger example"
    }), "\n", (0, _jsxRuntime.jsx)(LinkWithTracking, {
      href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/custom-logger-example",
      linkText: "View the source code in GitHub"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Explore the various ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "logger"
      }), " API methods by sending custom logs from deployed extensions. When used in local development mode, logs will be sent to the browser console. In cases where the extensions fail to load, a trace ID will be generated which you can then use to trace the log within the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/creating-private-apps#logs",
        children: "app's logs in HubSpot"
      }), ". Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk#send-custom-log-messages-for-debugging",
        children: "custom logger API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What's included:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Middle column card:"
          }), " an example card for the middle column of contact records, which includes custom logging for serverless function success/failure, logging different types of information, and logging for extension failure."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/custom-logger-example-middle-panel-card.png",
            alt: "custom-logger-example-middle-panel-card"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sidebar card:"
          }), " an example card for the right sidebar of contact records, which includes custom logging for serverless function success/failure, logging different types of information, and logging for extension failure."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/sidebar-card-logging-example.png",
        alt: "sidebar-card-logging-example"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "What you'll learn:"
      }), " how to use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk#send-custom-log-messages-for-debugging",
        children: "custom logger API"
      }), " to enable more in-depth troubleshooting of UI extensions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Display an iframe modal"
    }), "\n", (0, _jsxRuntime.jsx)(LinkWithTracking, {
      href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/display-iframe-modal",
      linkText: "View the source code in GitHub"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Open a popup iframe modal on button click to embed external content on CRM records. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk#open-an-iframe-in-a-modal",
        children: "opening iframe modals in UI extensions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " you want to embed a section of your company's ERP so that sales reps can check inventory levels when putting together potential deals."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "What's included:"
      }), " an example card for contact records that includes descriptive text and a button that loads Wikipedia in an iframe modal."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extensions-iframe-modal-example.gif",
          alt: "ui-extensions-iframe-modal-example"
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "What you'll learn:"
      }), " how to launch a modal that contains an iframe for displaying external content."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Duplicate contact"
    }), "\n", (0, _jsxRuntime.jsx)(LinkWithTracking, {
      href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/duplicate-contact",
      linkText: "View the source code in GitHub"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Duplicate a contact along with some of its properties and associated deals and companies."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " after an in-person consultation, a marketer needs a way to quickly create several contact records for a new group of clients who share similar data."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "What's included:"
      }), " an example card for contact records featuring data display and form submission."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/sample-project-contact-duplication.gif",
        alt: "sample-project-contact-duplication"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What you'll learn:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fetching data asynchronously using HubSpot serverless functions."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Using loading and error states."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Making GraphQL calls inside serverless functions."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Generate multiple quotes"
    }), "\n", (0, _jsxRuntime.jsx)(LinkWithTracking, {
      href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/generate-quotes",
      linkText: "View the source code in GitHub"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Match a customer with a service based on specified criteria, then generate a sales quote. The extension is built on top of the HubSpot quotes tool and uses the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes",
        children: "quotes API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " a fictional shuttle bus rental company with several service options needs a way to match customers with the most appropriate service, then generate a quote for them."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "What's included:"
      }), " a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Shuttle bus quotes sample"
      }), " card for deal records featuring a multi-part form and quote generator."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extensions-multiple-quotes-example.png",
        alt: "ui-extensions-multiple-quotes-example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What you'll learn:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Building multi-page forms."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fetching data asynchronously using HubSpot serverless functions."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Customized sales quote generation."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create line and bar charts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/overview",
        children: "building UI extensions for a public app,"
      }), " the source code for this example is available ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/uie-beta-public-app-samples/tree/main/charts-example",
        children: "here"
      }), ". Otherwise, if you're building your extensions ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/quickstart",
        children: "using a private app"
      }), ", check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/charts-example",
        children: "this repository"
      }), " instead."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Render two example charts based on customer purchase history and revenue distribution over time."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " you want to analyze revenue data associated with a contact so you can reach out to them with other related products they might be interested in."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "What's included:"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LineChart"
      }), " component and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BarChart"
      }), " component that render based on randomly generated test data."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/sample-charts-for-ui-extensions-updated.png",
        alt: "sample-charts-for-ui-extensions-updated"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What you'll learn:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Configuring line and bar chart display options."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Formatting test data to render correctly in the context of the two chart types."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Manage layouts: Flex and Box"
    }), "\n", (0, _jsxRuntime.jsx)(LinkWithTracking, {
      href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/flex-and-box",
      linkText: "View the source code in GitHub"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn how the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " components can be used to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/manage-ui-extension-layout",
        children: "manage extension layout"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " you want to customize the structure of your card and its various components to better organize information and improve UX."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What's included:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Flex playground card:"
        }), " a card for deal records where you can experiment with various ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Flex"
        }), " props to better understand how they work together."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/flex-playground-card-demo.gif",
        alt: "flex-playground-card-demo"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Flex and box example card:"
        }), " a real estate listing card for deal records that uses ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Flex"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Box"
        }), " to arrange information on each listing. This card does not include any real data handling functionality. The example data is hardcoded for component demonstration purposes only."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/flex-and-box-example-card-demo.gif",
        alt: "flex-and-box-example-card-demo"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What you'll learn:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Flex"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Box"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/manage-ui-extension-layout",
          children: "layout components"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Improving ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Form"
        }), " component arrangement."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Optimizing the space of your cards through a variety of components."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Using ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Tile"
        }), " components to group information."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Including multiple extensions in one project."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Overlay example"
    }), "\n", (0, _jsxRuntime.jsx)(LinkWithTracking, {
      href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/overlay-example",
      linkText: "View the source code in GitHub"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Explore the types of extension overlays available, including all variants available for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/modal",
        children: "Modal"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/panel",
        children: "Panel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "What's included:"
      }), " a card for contact records that lists all available ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Modal"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Panel"
      }), " variants in an interactive table."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/overlay-sample-project.gif",
        alt: "overlay-sample-project"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["What you'll learn: how to launch and close ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Modal"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Panel"
        }), " components from an extension."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Use CRM data components"
    }), "\n", (0, _jsxRuntime.jsx)(LinkWithTracking, {
      href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/crm-data-components",
      linkText: "View the source code in GitHub"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "View a contact's associated deal information in a table, then navigate to the deal record to view and modify its progress in the sales pipeline."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " a salesperson needs a way to quickly view a contact's associated deal information, then navigate to the deal record to confirm the deal's progress and close it."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What's included:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Association Table Card:"
        }), " a card for contact records to display high-level associated deal information in a table."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extensions-association-table-sample.png",
        alt: "ui-extensions-association-table-sample"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Stage Tracker Card:"
        }), " a card for deal records to view and update pipeline stage progress."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extensions-deal-stage-tracker-sample.png",
        alt: "ui-extensions-deal-stage-tracker-sample"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What you'll learn:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/overview#crm-data-components",
          children: "CRM data components"
        }), ", which are pre-configured to display CRM data."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Flex"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/manage-ui-extension-layout",
          children: "layout component"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Making API calls to the HubSpot API using serverless functions."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fetching properties for the currently displaying record."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View nearby companies: Mapbox API"
    }), "\n", (0, _jsxRuntime.jsx)(LinkWithTracking, {
      href: "https://github.com/HubSpot/ui-extensions-examples/tree/main/mapbox-api",
      linkText: "View the source code in GitHub"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Find and display companies that are located near the currently displaying company record."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use case:"
      }), " a salesperson will be traveling to visit client and wants to know if there are other companies they can visit along the way."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What's included:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Top value companies within radius:"
          }), " a card for company records to show the companies nearest to the currently displaying record."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extensions-search-by-radius.png",
            alt: "ui-extensions-search-by-radius"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Nearest companies to currency company record:"
          }), " a card for company records to search for nearby companies by mile radius."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extensions-promixity-current-company.png",
            alt: "ui-extensions-promixity-current-company"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "What you'll learn:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Including multiple UI extensions in one project using one private app."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Working with third-party data."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}