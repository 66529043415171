"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611087;
const slug = exports.slug = 'guides/cms/content/templates/types/drag-and-drop-templates';
const title = exports.title = 'Modèles de glisser-déposer';
const name = exports.name = '[nouveau] Modèles - Glisser-déposer';
const metaDescription = exports.metaDescription = `Les modèles du gestionnaire de conception en glisser-déposer sont des modèles créés à l'aide d'un outil de conception visuel. Ils ne sont pas recommandés pour les nouveaux modèles car les modèles codés offrent une meilleure expérience.`;
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/image2-3.png';
const featuredImageAltText = exports.featuredImageAltText = 'Interface du gestionnaire de conception avec modules sélectionnés';
const toc = exports.toc = [{
  "depth": 0,
  "id": "outil-de-cr%C3%A9ation-de-mod%C3%A8les-de-glisser-d%C3%A9poser",
  "label": "Outil de création de modèles de glisser-déposer",
  "parent": null
}, {
  "depth": 1,
  "id": "modules",
  "label": "Modules",
  "parent": "outil-de-cr%C3%A9ation-de-mod%C3%A8les-de-glisser-d%C3%A9poser"
}, {
  "depth": 1,
  "id": "groupes",
  "label": "Groupes",
  "parent": "outil-de-cr%C3%A9ation-de-mod%C3%A8les-de-glisser-d%C3%A9poser"
}, {
  "depth": 1,
  "id": "groupes-globaux",
  "label": "Groupes globaux",
  "parent": "outil-de-cr%C3%A9ation-de-mod%C3%A8les-de-glisser-d%C3%A9poser"
}, {
  "depth": 1,
  "id": "colonnes-flexibles",
  "label": "Colonnes flexibles",
  "parent": "outil-de-cr%C3%A9ation-de-mod%C3%A8les-de-glisser-d%C3%A9poser"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      code: "code",
      a: "a",
      h2: "h2",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Modèles glisser-déposer"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Les modèles de glisser-déposer ne sont pas recommandés pour les nouveaux modèles."
        }), " Ces modèles ne peuvent pas faire partie d'un thème et ne prennent donc pas en charge les fonctionnalités de thème telles que les champs de thème et les modules de thème. Les modèles de glisser-déposer ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "NOT"
        }), " pris en charge dans CMS Hub Starter. Utilisez plutôt ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), ". Les modèles de glisser-déposer ne prennent pas en charge plusieurs des nouvelles fonctionnalités du CMS (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/memberships/overview",
          children: "adhésions"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "zones de glisser-déposer"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections",
          children: "sections réutilisables"
        }), ", GraphQL et de nombreuses autres fonctionnalités). Ils manquent d'extensibilité et ne fournissent pas une expérience de création et de développement de contenu aussi bonne que les modèles codés. Nous recommandons plutôt des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "modèles HTML + HubL"
        }), " codés avec des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "zones de glisser-déposer"
        }), ", qui offrent une meilleure expérience pour les développeurs et les spécialistes du marketing. Pour un démarrage rapide sur le CMS, nous vous recommandons de consulter le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate",
          children: "boilerplate du thème HubSpot"
        }), ", qui a été créé à l'aide de modèles codés."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'marketing_hub-professional', 'cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modèles"
      }), " de glisser-déposer ont été conçus pour permettre aux utilisateurs moins techniques de créer facilement un site web sur le CMS Hub. Les modèles de glisser-déposer tirent parti d'un outil de conception de modèles visuel, qui génère du code HTML + HubL en arrière-plan."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Afin de faire fonctionner l'outil de conception visuel, la sortie HTML est contrôlée par HubSpot et vous n'avez pas de contrôle direct sur ses parties structurelles. En outre, un fichier ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "layout.css est chargé sur la page, ce qui permet une grille de base à 12 colonnes"
      }), " basée sur bootstrap 2. Cela rend tous les sites créés avec des modèles de glisser-déposer réactifs par défaut, ce qui permet aux lignes de contenu de s'empiler verticalement. Pour une réactivité plus complexe et personnalisée, vous souhaiterez ajouter vos propres styles réactifs."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles de glisser-déposer sont créés dans le gestionnaire de conception. Lors d'interactions avec ces derniers via l'API ou les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "outils de développement locaux"
      }), ", ils sont représentés au format JSON. En raison de la nature des modèles de glisser-déposer, la seule façon recommandée de les modifier est via l'interface du gestionnaire de conception. **Si vous préférez coder, utiliser le contrôle des versions ou simplement être en mesure d'éditer via vos outils préférés localement, les modèles codés HTML + HubL offrent la meilleure expérience pour les développeurs.La fonctionnalité ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area"
      }), " pour les modèles HTML + HubL offre également une meilleure expérience aux créateurs de contenu que l'interface en glisser-déposer du gestionnaire de conception, car elle les conserve dans l'éditeur de contenu visuel."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Outil de création de modèles de glisser-déposer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un modèle de glisser-déposer, ouvrez le gestionnaire de conception et dans l'outil de recherche, créez un nouveau fichier, choisissez un modèle et le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#template-types",
        children: "type de modèle"
      }), " que vous créez."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les modèles de glisser-déposer sont constitués de blocs de construction, qui sont des modules, des groupes, des groupes globaux et des colonnes flexibles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts",
        children: "Découvrez comment utiliser l'outil de création de modèles de glisser-déposer."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modules sont des composants réutilisables qui sont des parties modifiables d'une page. Les modules sont constitués d'un fragment de modèle HTML + HubL, de CSS, de JS et de champs. Les modules peuvent être placés à l'intérieur de colonnes flexibles et de zones dnd_areas par les éditeurs de contenu. Ils constituent la principale méthode pour les éditeurs de contenu de créer le contenu de leur site web. Vous pouvez créer des modules pour gérer un certain nombre de fonctions, la recherche, les galeries d'images, les menus, les animations marketing complexes, les calculatrices, les comparaisons de produits et tout ce qui selon vous offre une bonne expérience pour les créateurs de contenu. Les champs sont la façon dont l'éditeur de contenu contrôle leur sortie. Les modules ne sont pas spécifiques aux modèles de glisser-déposer, ils sont un élément de base très important pour le CMS Hub. Dans les modèles de glisser-déposer, les valeurs par défaut des champs de module peuvent être configurées dans ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template",
        children: "l'inspecteur"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Découvrez-en davantage sur les modules"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image2-3.png",
        alt: "Interface du gestionnaire de conception avec modules mis en évidence"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Groupes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les groupes sont des encadreurs pour d'autres groupes et modules. Ils peuvent avoir des classes CSS et du HTML d'encadrement. Les groupes se manifestent sous forme de HTML d'encadrement avec des classes de mise en page pour faciliter le placement et le dimensionnement des groupes. Les groupes peuvent également avoir un nom interne uniquement. Vous pouvez ainsi ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts#group-modules",
        children: "regrouper des modules"
      }), " dans le gestionnaire de conception, ce qui facilite l'identification visuelle des parties d'une page. Un exemple de ceci pourrait être pour les barres latérales ou les bannières."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Étant donné que les fichiers ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML + HubL"
        }), " sont le chemin recommandé pour les nouveaux sites, les colonnes, sections et lignes de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "zones de glisser-déposer"
        }), " remplacent largement les objectifs des groupes."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["En outre, un développeur peut créer des partials et des partials ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/global-content",
          children: "globaux"
        }), ", qui peuvent contenir du code de forme libre en plus des zones de glisser-déposer."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Les zones de glisser-déposer, les partials et les partials globaux ne sont pas pris en charge dans les modèles de glisser-déposer."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hubfs/image4-1.png",
        alt: "Capture d'écran du gestionnaire de conception avec groupe de barres latérales sélectionné"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Groupes globaux"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les groupes globaux sont des groupes qui, lorsqu'ils sont modifiés, affectent l'ensemble du site web et pas seulement la page sur laquelle ils se trouvent. Les groupes globaux peuvent exister dans plusieurs modèles et sont le plus souvent utilisés pour les en-têtes et les pieds de page de site. Les groupes globaux sont similaires aux partials, mais sont limités à la structure que les modèles de glisser-déposer appliquent. Ils peuvent être intégrés dans des fichiers HTML + HubL si nécessaire, mais la plupart du temps, il est plus logique d'utiliser un partial global à la place."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image1-3.png",
        alt: "Interface du gestionnaire de conception avec les groupes globaux d'en-tête et de pied de page mis en évidence"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Colonnes flexibles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les colonnes flexibles sont un type particulier de groupe. Elles peuvent contenir un ensemble de modules par défaut, mais les éditeurs de contenu peuvent ajouter, supprimer et déplacer des modules en leur sein. Les colonnes flexibles permettent de réorganiser les modules d'une manière unidimensionnelle, verticalement vers le haut et vers le bas. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Contrairement aux balises dnd_area"
      }), ", les ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "colonnes flexibles ne prennent pas en charge les sections ou les fonctionnalités de style proposées pour les modules qui les composent."
      }), " Les colonnes flexibles ne sont pas uniques aux modèles de glisser-déposer. Il existe une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
        children: "balise HubL"
      }), " qui peut être utilisée dans les modèles ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts#add-a-flexible-column",
        children: "Découvrez comment ajouter une colonne flexible à un modèle de glisser-déposer."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Il est généralement recommandé d'utiliser des zones de glisser-déposer, car elles sont la plupart du temps plus utiles et offrent toutes les fonctionnalités des colonnes flexibles."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "L'utilisation d'une colonne flexible peut cependant rester utile, comme pour une barre latérale. Pour les principales zones de contenu, les zones de glisser-déposer sont beaucoup plus flexibles."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hubfs/image3-2.png",
        alt: "Capture d'écran du gestionnaire de conception avec le groupe de contenu principal sélectionné"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pourquoi utiliser des colonnes flexibles ? Les sites web ne sont généralement pas rigides. Ils sont créés et gérés pendant de longues périodes. Une page d'accueil, par exemple pour une entreprise, peut présenter des produits en vedette et changer fréquemment à mesure que les besoins marketing de l'entreprise évoluent. Étant donné que du contenu dans une colonne flexible peut être ajouté/supprimé et modifié par les éditeurs de contenu, l'expérience est moins difficile pour les spécialistes du marketing et permet aux développeurs de se concentrer sur ce qu'ils aiment, en créant des éléments attrayants plutôt que de procéder à des ajustements de page mineurs."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "De même, les pages d'un site peuvent avoir des besoins structurels différents. Les colonnes flexibles offrent du contrôle aux spécialistes du marketing pour créer ces pages à l'aide de modules personnalisés."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 8,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Ajouter du code personnalisé à vos modèles de glisser-déposer"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Il existe plusieurs façons d'ajouter du code personnalisé à vos modèles de glisser-déposer. La principale méthode consiste à utiliser des modules personnalisés. Cependant, vous pourrez parfois ajouter du code qui encadre les modules ou les groupes. Pour ce faire, cliquez sur le module ou le groupe pour le visualiser dans l'inspecteur et trouver le champ HTML d'encadrement. Ajoutez votre HTML ici."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Parfois, vous devrez peut-être ajouter du code à l'en-tête de votre HTML ou juste au-dessus de ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "</body>"
          }), ". Lorsque votre modèle est ouvert, assurez-vous que rien n'est sélectionné. L'inspecteur affichera des champs pour le modèle lui-même. Ici, vous pouvez lier des feuilles de style et des fichiers JavaScript tout en ajoutant du HTML supplémentaire dans l'en-tête ou juste avant la balise ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "</body>"
          }), "."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Pour cela, vous utiliserez ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template",
            children: "l'inspecteur"
          }), ". L'inspecteur présente des champs au niveau du modèle pour les ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template##attach-stylesheets-and-javascript-files",
            children: "feuilles de style, le JavaScript"
          }), ", ", (0, _jsxRuntime.jsxs)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template#customize-your-template-s-head-and-body-options",
            children: ["le balisage ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<head>"
            })]
          }), ", etc."]
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://designers.hubspot.com/hubfs/image5.png",
            alt: "Panneau d'inspecteur de code personnalisé du gestionnaire de conception"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}