"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831415;
const slug = exports.slug = 'reference/cms/fields/rich-text-editor';
const title = exports.title = "Configurer l'éditeur de texte enrichi";
const name = exports.name = 'EMEA FR Configuring the Rich Text Editor';
const metaDescription = exports.metaDescription = "L'éditeur de texte enrichi à l'intérieur des modules personnalisés permet désormais aux développeurs de supprimer des composants de la barre d'outils de configuration.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "comment-utiliser-enabled_features",
  "label": "Comment utiliser enabled_features",
  "parent": null
}, {
  "depth": 0,
  "id": "liste-de-fonctionnalit%C3%A9s",
  "label": "Liste de fonctionnalités",
  "parent": null
}, {
  "depth": 1,
  "id": "groupes-de-contr%C3%B4les",
  "label": "Groupes de contrôles",
  "parent": "liste-de-fonctionnalit%C3%A9s"
}, {
  "depth": 1,
  "id": "formatage-de-texte",
  "label": "Formatage de texte",
  "parent": "liste-de-fonctionnalit%C3%A9s"
}, {
  "depth": 1,
  "id": "ins%C3%A9rer-des-boutons",
  "label": "Insérer des boutons",
  "parent": "liste-de-fonctionnalit%C3%A9s"
}, {
  "depth": 1,
  "id": "options-avanc%C3%A9es",
  "label": "Options avancées",
  "parent": "liste-de-fonctionnalit%C3%A9s"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      h2: "h2",
      pre: "pre",
      img: "img",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configurer l'éditeur de texte enrichi"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En tant que développeur, il arrive que les éditeurs WYSIWYG fournissent des fonctionnalités qui, lorsqu'elles sont mal utilisées, peuvent entraver l'objectif d'une marque unifiée et causer des problèmes de conception et de flux de contenu. L'éditeur de texte enrichi à l'intérieur des modules personnalisés permet désormais aux développeurs de supprimer des composants de la barre d'outils de configuration via la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), " à l'intérieur du fichier fields.json."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Remarque : Ce qui suit s'applique aux modules personnalisés utilisant le champ de texte enrichi en développement local uniquement. L'utilisation de cette fonctionnalité ne désactivera pas la fonctionnalité des options supprimées de l'éditeur de texte enrichi, uniquement l'affichage des options. Cela s'explique par des raisons de rétrocompatibilité, de sorte que le contenu existant n'est pas affecté."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comment utiliser enabled_features"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans votre fichier fields.json où vous avez votre objet de champ de texte enrichi, vous pouvez activer certaines fonctionnalités en ajoutant les options valides de la barre d'outils dans un tableau à la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), ", comme indiqué ci-dessous :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Rich text field with only Bold, Link, and Image available in the Toolbar\n{\n  \"name\": \"description\",\n  \"label\": \"Description\",\n  \"required\": false,\n  \"locked\": false,\n  \"type\": \"richtext\",\n  \"default\": null,\n  \"enabled_features\": [\"bold\", \"link\", \"image\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'éditeur de contenu verra alors l'éditeur de texte enrichi avec uniquement les options incluses activées, comme illustré dans l'image ci-dessous :"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Remarque : Certaines fonctionnalités, telles que le bouton Effacer les styles qui vous permet de revenir au style par défaut de l'éditeur, seront toujours activées et ne peuvent pas être supprimées. Si la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "enabled_features"
        }), " est omise, toutes les fonctionnalités apparaîtront."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/custom_rte_toolbar.jpg",
        alt: "Un exemple de barre d'outils RTE avec les fonctionnalités activées."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Liste de fonctionnalités"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Voici une liste des fonctionnalités qui peuvent être activées individuellement lors de l'utilisation de la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Groupes de contrôles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Option"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "colors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contrôles de la couleur du texte et de l'arrière-plan."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fonts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contrôles de la famille de polices et de la taille de police."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "indents"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contrôles du retrait négatif et du retrait."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lists"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contrôles de listes à puces et numérotées."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_emphasis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contrôles du texte gras, italique et souligné."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "advanced_emphasis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contrôles de texte barré, d'exposant, d'indice et de format de code."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "glyphs"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contrôles d'émojis, de caractères spéciaux et d'icônes. Non pris en charge dans les modules d'e-mail. Pour ajouter le sélecteur d'emoji aux modules d'e-mail, utilisez plutôt ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#insert-buttons",
              children: "emoji"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formatage de texte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Option"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "block"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le menu déroulant du sélecteur de style."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "font_family"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le menu déroulant du sélecteur de police."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "font_size"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le menu déroulant de la taille de la police."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bold"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de texte en gras."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "italic"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de texte en italique."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "underline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de texte souligné."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de couleur du texte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de couleur d'arrière-plan."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton d'alignement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bulleted_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de liste à puces."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numbered_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de liste numérotée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lineheight"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de hauteur de ligne."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "outdent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de retrait négatif."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "indent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de retrait."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "strikethrough"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de texte barré."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "superscript"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton d'exposant."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscript"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton d'indice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "code_format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de format de code."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Insérer des boutons"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Option"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton de lien."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton d'image. Non pris en charge dans les modules d'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "emoji"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche le bouton d'émoji."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "personalize"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche l'élément de barre d'outils de personnalisation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cta"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche l'élément de menu de call-to-action sous le menu d'insertion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche l'élément de menu d'intégration sous le menu d'insertion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche l'élément de menu vidéo sous le menu d'insertion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche l'élément de menu de tableau sous le menu d'insertion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "charmap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche l'élément de menu de caractères spéciaux sous le menu d'insertion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "anchor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche l'élément de menu d'ancrage sous le menu d'insertion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hr"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche l'élément de menu de ligne horizontale sous le menu d'insertion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nonbreaking_space"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche l'élément de menu d'espace insécable sous le menu d'insertion."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche l'élément de menu d'icône sous le menu d'insertion."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Options avancées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Option"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "source_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche l'élément de menu de code source sous le menu avancé."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "visual_blocks"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Affiche l'élément de menu d'affichage des blocs sous le menu avancé."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}