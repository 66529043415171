"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45935558250;
const slug = exports.slug = 'reference/cms/hubl/loops';
const title = exports.title = 'forループ';
const name = exports.name = 'APAC JAPAN (ja) | [new] For loops_JA | 202106';
const metaDescription = exports.metaDescription = 'forループは、HubLでのオブジェクトのシーケンスに対する反復処理に使用できます。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%AB%E3%83%BC%E3%83%97%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "ループプロパティー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%8D%E3%82%B9%E3%83%88%E3%81%97%E3%81%9F%E3%83%AB%E3%83%BC%E3%83%97",
  "label": "ネストしたループ",
  "parent": null
}, {
  "depth": 0,
  "id": "cycle",
  "label": "cycle",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AB%E3%83%BC%E3%83%97%E5%86%85%E3%81%AE%E5%A4%89%E6%95%B0",
  "label": "ループ内の変数",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AB%E3%83%BC%E3%83%97%E3%81%A7%E3%81%AE%E3%82%AD%E3%83%BC%E3%81%A8%E5%80%A4%E3%81%AE%E3%83%9A%E3%82%A2",
  "label": "ループでのキーと値のペア",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A8%AD%E5%AE%9A%E3%81%97%E3%81%9F%E5%9B%9E%E6%95%B0%E3%81%AE%E5%8F%8D%E5%BE%A9",
  "label": "設定した回数の反復",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AB%E3%83%BC%E3%83%97%E3%81%A7hubl%E3%82%BF%E3%82%B0%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "ループでHubLタグを使用する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      pre: "pre",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "forループ"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["forループは、HubLでのオブジェクトのシーケンスに対する反復処理に使用できます。主に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/blog",
        children: "ブログコンテンツ"
      }), "をリスト形式でレンダリングする場合に使用されますが、他のシーケンス変数を並べ替える場合にも使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["forループは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% for %}"
      }), "文で始まり", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% endfor %}"
      }), "文で終わります。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% for %}"
      }), "文では、シーケンス項目の名前に続き、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "in"
      }), "を使ってシーケンス名を指定します。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "for"
      }), "文の始まりから終わりまでの間のコードが、反復の度に出力されます。このコードには通常、各シーケンス項目の出力変数が含まれます。forループの基本構文は以下のとおりです。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for item in items %}\n\t{{ item }}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "変数値のシーケンスをリストに出力する方法を以下の例に示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set languages = [\"HTML\", \"CSS\", \"Javascript\", \"Python\", \"Ruby\", \"PHP\", \"Java\"] %}\n\n<h1>Languages</h1>;\n<ul>\n    {% for language in languages %}\n    <li>{{ language }}</li>\n    {% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h1>Languages</h1>\n<ul>\n  <li>HTML</li>\n  <li>CSS</li>\n  <li>Javascript</li>\n  <li>Python</li>\n  <li>Ruby</li>\n  <li>PHP</li>\n  <li>Java</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ループプロパティー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ループの反復中に、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "条件文"
      }), "を使用してループの動作を定義できます。変数プロパティー", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loop.index"
      }), "によって、ループの現在の反復数（イテレーション）がカウントされます。他にも反復数をさまざまな方法でカウントするloop変数プロパティーがあります。これらのプロパティーについては以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "変数"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.cycle"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "シーケンスリスト間を循環するヘルパー関数。後述の説明を参照してください。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.depth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "再帰ループでのレンダリングの現在の深さを示します。レベル1から開始します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.depth0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "再帰ループでのレンダリングの現在の深さを示します。レベル0から開始します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.first"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この変数は、ループの最初の反復である場合にtrueとして評価されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.index"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ループの現在の反復数。この変数はカウントを1から開始します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.index0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ループの現在の反復数。この変数はカウントを0から開始します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.last"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この変数は、ループの最後の反復である場合にtrueとして評価されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.length"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "シーケンスの項目の数。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.revindex"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ループの終わりまでの反復数。1までカウントダウンします。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop.revindex0"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ループの終わりまでの反復数。0までカウントダウンします。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["さまざまなループ変数の使用例を以下にいくつか示します。以下の基本的な例では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loop.index"
      }), "を使用して、反復の度に出力されるカウントを行います。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set loopy = [\"Content\", \"Social\", \"Contacts\", \"Reports\"] %}\n{% for app in loopy %}\n    {{ loop.index }}. {{app}} <br>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1. Content <br />\n2. Social <br />\n3. Contacts <br />\n4. Reports <br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["次の例では、条件文を使用して、ループの長さが特定の数で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "divisibleby"
      }), "（割り切れる）かどうかをチェックします。次に、記事項目divの幅をレンダリングします。この例では標準のブログ記事ループを使用し、ループに6件の記事があると仮定しています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n{% if loop.length is divisibleby 4 %}\n<div style=\"width:25%\">Post content</div>\n\n{% elif loop.length is divisibleby 3 %}\n<div style=\"width:33.33332%\">Post content</div>\n\n{% else %}\n<div style=\"width:50%\">Post content</div>\n\n{% endif %}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n<div style=\"width:33.33332%\">Post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ネストしたループ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ループを他のループでネストすることもできます。子forループは、親forループが反復される度に実行されます。以下の例では、親項目の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<ul>"
      }), "の中でネストしている", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<ul>"
      }), "に子項目のリストが出力されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set parents = [\"Parent item 1\", \"Parent item 2\", \"Parent item 3\"] %}\n{% set children = [\"Child item 1\", \"Child item 2\", \"Child item 3\"] %}\n<ul>\n{% for parent in parents %}\n<li>{{parent}}<ul>\n    {% for child in children %}\n    <li>{{child}}</li>\n    {% endfor %}\n    </ul>\n</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>\n    Parent item 1\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n\n  <li>\n    Parent item 2\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n\n  <li>\n    Parent item 3\n    <ul>\n      <li>Child item 1</li>\n      <li>Child item 2</li>\n      <li>Child item 3</li>\n    </ul>\n  </li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "cycle"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["反復の度に一連の文字列値を循環して出力するには、forループでcycleタグを使用します。この手法の実用例として、リスト内のブログ記事に適用するクラスを交互に切り替える処理が挙げられます。このタグは3つ以上の値に使用でき、ループの反復数がcycleの値よりも多い場合にcycleが繰り返されます。以下の例では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "odd"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "even"
      }), "というクラスがリスト内の記事に適用されます（この例ではループに5件の記事が含まれていると仮定しています）。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カンマ区切りのcycle文字列値の間に", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "スペースがない"
      }), "点に注意してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n    <div class=\"post-item {% cycle \"odd\",\"even\" %}\">Blog post content</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item odd\">Blog post content</div>\n<div class=\"post-item even\">Blog post content</div>\n<div class=\"post-item odd\">Blog post content</div>\n<div class=\"post-item even\">Blog post content</div>\n<div class=\"post-item odd\">Blog post content</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ループ内の変数"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "ループ内で定義した変数は、そのループの範囲内に制限され、ループの外側から呼び出すことはできません。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ループ外で定義した変数をループ内から呼び出すことはできますが、その逆はできません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ループでのキーと値のペア"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ループの対象にするディクショナリー（辞書型）にキーと値が含まれている場合、単純なforループで参照できるのは値に限られます。forループ内でキーと値の両方を参照するには、次のような形式のHubLを使用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set dict_var = {\"name\": \"Cool Product\", \"price\": \"$20\", \"size\":\"XL\"} %}\n{% for key, val in dict_var.items() %}\n    {{ key }}: {{ val }}<br>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "name: Cool Product <br />\nprice: $20 <br />\nsize: XL <br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "設定した回数の反復"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "設定した回数だけを反復する処理は、HTMLやCSSを生成するときに便利です。この処理はrange関数を使用すると実現できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for x in range(0,5) %}\n\t{{loop.index}}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1 2 3 4 5\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ループでHubLタグを使用する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページにタグを追加すると、HubSpotにより、ラッパー側のHTMLに自動的に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "が割り当てられます。このタグはタグの「name」ごとに固有です。特定のタグをforループ内で使用する場合に、固有のnameを設定することは現実的ではありません。タグに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unique_in_loop"
      }), "パラメーターを追加して、固有idを生成してください。このパラメーターによってモジュール名に現在のループの反復数が追加されることで、項目が重複しなくなります。固有idは、HTMLの有効性に必要なだけでなく、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "アクセシビリティー"
      }), "においても重要です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for item in module.icon_field %}\n\t{% icon\n\t\tname=\"{{ item.name }}\",\n\t\tstyle=\"{{ item.type }}\",\n\t\tunicode=\"{{ item.unicode }}\",\n        unique_in_loop=True\n\t%}\n{% endfor %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}