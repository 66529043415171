"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694158;
const slug = exports.slug = 'guides/cms/content/templates/types/html-hubl-templates';
const title = exports.title = 'HTML + HubL Templates';
const name = exports.name = 'HTML + HubL templates';
const metaDescription = exports.metaDescription = 'HubSpot CMS templates are written in HTML and HubL. These templates are capable of containing regions that are friendly for content creators to edit themselves.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/image1-2.png';
const featuredImageAltText = exports.featuredImageAltText = 'Screenshot of a basic HTML + HubL template';
const toc = exports.toc = [{
  "depth": 0,
  "id": "familiarity-and-tooling",
  "label": "Familiarity and tooling",
  "parent": null
}, {
  "depth": 0,
  "id": "template-annotations",
  "label": "Template annotations",
  "parent": null
}, {
  "depth": 0,
  "id": "header-and-footer-includes",
  "label": "Header and footer includes",
  "parent": null
}, {
  "depth": 0,
  "id": "partials",
  "label": "Partials",
  "parent": null
}, {
  "depth": 1,
  "id": "standard-partials",
  "label": "Standard partials",
  "parent": "partials"
}, {
  "depth": 1,
  "id": "global-partials",
  "label": "Global partials",
  "parent": "partials"
}, {
  "depth": 1,
  "id": "blocks-and-extends",
  "label": "Blocks and extends",
  "parent": "partials"
}, {
  "depth": 1,
  "id": "global-groups",
  "label": "Global groups",
  "parent": "partials"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      HubspotVideoPlayer,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HTML + HubL templates"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["HTML + HubL templates can be used for ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/templates/overview#template-types",
            children: "every type of template"
          }), " on the HubSpot CMS. These templates are .html files that support the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/cms/hubl/overview",
            children: "HubL templating language"
          }), ". Because these coded templates support HubL the best previewing experience is using the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/review-your-hubspot-template-setup",
            children: "template preview in the Design Manager"
          }), " or viewing pages on a sandbox account. HTML + HubL templates can contain ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#partials",
            children: "partials"
          }), ", which can be used to separate commonly used chunks of code, such as a header or footer."]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172844649367"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HTML + HubL templates give greater control to developers than ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "visual design manager drag and drop templates"
      }), ". Developers in-turn can provide better experiences for content creators through ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "drag and drop functionality"
      }), ", which is only possible with HTML + HubL templates."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image1-2.png",
        alt: "image1-2"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The above template is the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/layouts/base.html",
          children: "base.html template"
        }), " included in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "HubSpot CMS boilerplate"
        }), ", which is a great way to get started developing with HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Familiarity and tooling"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Since HTML + HubL templates are coded files, you can use your preferred tools to edit them locally. It's recommended to use HubSpot's own ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "local development tools"
      }), " so that you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "upload"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "fetch"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "watch"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#create",
        children: "create"
      }), " and otherwise securely manage files in the developer file system as well as the file manager."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Building HTML + HubL templates with HubSpot is similar to using other templating language you may have used before. The core difference is that HubSpot takes an opinionated stance on the best ways to do some things to offer the best experience for content creators, and also takes much of the maintenance and performance optimization work off of the developer."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you want to load CSS file on a page for certain modules, instead of using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<link rel=\"stylesheet\" type=\"text/css\" href=\"theme.css\">"
      }), ", you should include the stylesheet through ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css_assets"
      }), " in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-css-and-javascript-dependencies",
        children: "module's meta.json file"
      }), ". This enables HubSpot to conditionally load the CSS only when the module is present on a page, minimizing the amount of unnecessary CSS loaded."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "optimizing your HubSpot development workflow"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Template annotations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Template annotations, included at the top of a template, configure important template settings, such as the template type and whether it can be used to create new content. Template annotations can be changed at any time during the development process. Below, learn more about available template annotations."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: page\n  isAvailableForNewContent: false\n  enableDomainStylesheets: false\n  label: Homepage\n  screenshotPath: ../images/template-previews/home.png\n-->\n\n<!doctype html>\n\n<html>\n  ...\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Annotation"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "templateType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Specifies which template type a file is. Values include:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Standard templates:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#page",
                      children: "page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email",
                      children: "email"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog-listing",
                      children: "blog_listing"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog-post",
                      children: "blog_post"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog",
                      children: "blog"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Partials: ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
                  children: "global_partial"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["System templates:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#error-pages",
                      children: "error_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#password-prompt",
                      children: "password_prompt_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-login",
                      children: "membership_login_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-register",
                      children: "membership_register_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-password-reset",
                      children: "membership_reset_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-reset-request",
                      children: "membership_reset_request_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-subscription-preferences",
                      children: "email_subscription_preferences_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-backup-unsubscribe",
                      children: "email_backup_unsubscribe_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-subscription-unsubscribe-confirmation",
                      children: "email_subscriptions_confirmation_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#search-results-page",
                      children: "search_results_page"
                    })
                  })]
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isAvailableForNewContent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Specifies if a template is available for selection in the content creation process. Values include: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ".Templates set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " do not need to include the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/variables#required-page-template-variables",
              children: "required variables"
            }), ". Templates of the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "page"
            }), " type that are set to false can also be used as ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#partials",
              children: "standard partials"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableDomainStylesheets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Specifies if the template should load ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/design-manager/create-edit-and-attach-css-files-to-style-your-site#attach-or-remove-stylesheets-on-a-domain-level",
              children: "domain stylesheets"
            }), ". Values include: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["User-friendly description of the template, displayed in the template selection screen. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "About Page"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Homepage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Pricing"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshotPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The screenshot to display when a content creator is selecting a template. This screenshot should make it easy to differentiate between your templates."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Header and footer includes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot's templates require two tags to be present unless the file is a template partial. The two tags are:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ standard_header_includes }}"
        }), " - Used to intelligently add combined and minified required CSS."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ standard_footer_includes }}"
        }), " - Used to intelligently add javascript to the bottom of a page dynamically, for things like the HubSpot tracking script, and modules."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["These tags must be present in a template or it's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#partials",
        children: "partial"
      }), " children to be published and used."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Partials"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Template partials are HTML + HubL files that can be included in other coded files. Partials enable you to take a more modular approach by sharing markup between multiple templates. For example, a header can be made into a partial so that you can easily include it as a component without needed to code it again."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Standard partials"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A standard partial is a reusable template or component containing content that can be edited on individual pages. This enables content creators to change the content as needed, as opposed to global partials which always share content. For example, the following code would pull the sidebar module into another template file."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Standard partials must include the following ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "annotations"
      }), " at the top of the template file:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateType: page"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "isAvailableForNewContent: false"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"../partial/sidebar.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Global partials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A global partial is a type of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content#global-partials",
        children: "global content"
      }), " that can be used across multiple templates with content that is shared across all instances of the partial. Global partials are often used for headers and footers, which you can see an example of in the HubSpot CMS Boilerplate ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/partials/header.html",
        children: "header"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/partials/footer.html",
        children: "footer"
      }), ". These partials are then called in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "base.html"
      }), " using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global_partial"
      }), " tag."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Global partials must include the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "annotation"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: global_partial"
      }), " at the top of the file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% global_partial path=\"../partials/header.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blocks and extends"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When creating complex templates, you can create compartmentalized blocks that extend a parent template."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, you can create a parent template that includes the required ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        })
      }), " variables. Within that template, you define a unique block using the following syntax where ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body"
      }), " is a unique name:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% block body %}\n<!-- Content to display -->\n{% endblock body %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Then, in the child template, you can extend the parent template, then insert more content into the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body"
      }), " block."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% extends \"./layouts/base.html\" %}\n{% block body %}\n<h3>Page Content</h3>\n<ul>\n  <li>Bullet 1<li>\n  <li>Bullet 2<li>\n  <li>Bullet 3<li>\n</ul>\n{% endblock %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This method is used in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/layouts/base.html",
        children: "base.html"
      }), " template of the HubSpot CMS boilerplate, which then is extended by the other templates in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/main/src/templates",
        children: "templates folder"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Global groups"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
        children: "Global groups"
      }), " created using the drag and drop template builder in the Design Manager, can also be included. The syntax is displayed below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"/path/to/global_header.template.json\" %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}