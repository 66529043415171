import { TRACK_EVENT } from './constants';

// Creates a type that is compatible with partial Tracker instances
// As we only need the track property from the Tracker instance
const getTrackHandler = trackerInstance => {
  return ({
    eventKey,
    eventProperties
  }) => {
    trackerInstance.track(eventKey, eventProperties);
  };
};
const isActionTrackAction = action => action.meta && action.meta[TRACK_EVENT];

// The second argument type allows `any` as TypeScript cannot inference correctly in some complex cases where { "my-action-type": MyAction, ... }
// Since TypeScript will attempt to extract from the union of Actions (Eg.: AppActions = MyAction | YourAction ... )
// which results on it infering towards the first Action and first ActionType from the Union.
// There's no easy workaround with using generics here (Eg.: AnyAction or Action from Redux) as if we attempt to Extract<T, { type: myActionType }>
// It will still do the inference for the first Action. Inferencing as an Union is complex as we cannot provide an union ourselves whose fits all requirements
const trackerMiddleware = (t, h) => {
  const track = getTrackHandler(t);
  const createMiddleware = ({
    getState,
    dispatch
  }) => next => action => {
    const prevState = getState();
    const handler = h && h[action.type];
    const result = next(action);
    if (isActionTrackAction(action)) {
      track(action.meta[TRACK_EVENT]);
    } else if (typeof handler === 'function') {
      handler(action, getState(), dispatch, prevState);
    } else if (typeof handler === 'object') {
      track(handler);
    }
    return result;
  };
  return createMiddleware;
};
export default trackerMiddleware;