"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 51261306631;
const slug = exports.slug = 'guides/api/crm/associations';
const title = exports.title = 'CRM API |  関連付けv4';
const name = exports.name = 'vNext Docs DP - 関連付けv4';
const metaDescription = exports.metaDescription = 'CRM関連付けエンドポイントは、チケット、製品、商品項目と、それらに関連するコンタクト、会社、取引の間の関連付けを管理するために使用されます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "hubspot%E3%81%A7%E5%AE%9A%E7%BE%A9%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%BF%E3%82%A4%E3%83%97",
  "label": "HubSpotで定義されている関連付けタイプ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%8D%98%E4%B8%80%E3%81%AE%E3%83%A9%E3%83%99%E3%83%AB%E3%81%A8%E3%83%9A%E3%82%A2%E3%81%AE%E3%83%A9%E3%83%99%E3%83%AB",
  "label": "単一のラベルとペアのラベル",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%BF%E3%82%A4%E3%83%97%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "関連付けタイプを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%BF%E3%82%A4%E3%83%97%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "関連付けタイプを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "レコードを関連付ける",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%A9%E3%83%99%E3%83%AB%E3%81%AA%E3%81%97%E3%81%A7%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "ラベルなしでレコードを関連付ける",
  "parent": "%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%83%A9%E3%83%99%E3%83%AB%E4%BB%98%E3%81%8D%E3%81%A7%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "ラベル付きでレコードを関連付ける",
  "parent": "%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%81%AE%E5%88%B6%E9%99%90%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%8A%E3%82%88%E3%81%B3%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B",
  "label": "関連付けの制限を設定および管理する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%81%AE%E5%88%B6%E9%99%90%E3%82%92%E4%BD%9C%E6%88%90%E3%81%BE%E3%81%9F%E3%81%AF%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "関連付けの制限を作成または更新する",
  "parent": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%81%AE%E5%88%B6%E9%99%90%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%8A%E3%82%88%E3%81%B3%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E5%88%B6%E9%99%90%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "関連付け制限を取得する",
  "parent": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%81%AE%E5%88%B6%E9%99%90%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%8A%E3%82%88%E3%81%B3%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%81%AE%E5%88%B6%E9%99%90%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "関連付けの制限を削除する",
  "parent": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%81%AE%E5%88%B6%E9%99%90%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%8A%E3%82%88%E3%81%B3%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E9%AB%98%E3%81%84%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E4%BD%BF%E7%94%A8%E7%8E%87%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E3%83%AC%E3%83%9D%E3%83%BC%E3%83%88",
  "label": "高い関連付け使用率に関するレポート",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%BF%E3%82%A4%E3%83%97id%E5%80%A4",
  "label": "関連付けタイプID値",
  "parent": null
}, {
  "depth": 0,
  "id": "v1%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%EF%BC%88%E3%83%AC%E3%82%AC%E3%82%B7%E3%83%BC%EF%BC%89",
  "label": "v1関連付け（レガシー）",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      code: "code",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "関連付けv4"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["以前のバージョンについては、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/associations/association-details/v3",
          children: "v3の関連付けAPI"
        }), "のドキュメントを参照してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "関連付けは、HubSpotのCRM上のさまざまなオブジェクトとアクティビティー間の関係を表します。異なるオブジェクトのレコード間（例：コンタクトから会社）でも、同じオブジェクト内（例：会社から会社）でも、レコードを関連付けることができます。関連付けエンドポイントを使用することで、レコード、アクティビティー、またはレコード間の関連付けを作成、取得、更新、削除できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["関連付けスキーマエンドポイントを使用すると、アカウントでサポートされている関連付けタイプを表示したり、独自の関連付けタイプを作成したり、レコードの関係にラベルを割り当てたりできます。関連付けラベルは、コンタクト、会社、取引、チケット、カスタムオブジェクト間でサポートされており、リストやワークフローなどの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels#use-association-labels-in-hubspot-tools",
        children: "HubSpotツール全体で使用"
      }), "できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["オブジェクト、レコード、プロパティー、および関連付けAPIの詳細については、「", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "CRMについて"
      }), "」のガイドをご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**v4関連付けAPI は、NodeJS HubSpot Clientのバージョン9.0.0以降でサポートされています。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpotで定義されている関連付けタイプ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotには、あらかじめ定義された一連の関連付けタイプが備わっています（例：会社へのコンタクトのラベルなしの関連付け）。しかし、アカウント管理者は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "独自の関連付けラベルを定義"
      }), "して、レコード関係の追加情報を提供できます（マネージャー、従業員など）。HubSpot定義の関連付けタイプは次の2つです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**プライマリー：**他のレコードが関連付けられるメインの会社。プライマリー関連付けは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/associations-enhancements-beta#use-associations-in-hubspot-tools",
          children: "リストやワークフロー"
        }), "などのHubSpotツールで使用できます。複数の会社が関連付けられたレコードの場合、このAPIでは、プライマリーと見なされる会社の変更がサポートされます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["ラベルなし：", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "コンタクト、会社、取引、チケット、またはカスタムオブジェクトのレコードが関連付けられたときに追加される関連付けタイプ。このタイプは関連付けが存在することを示し、レスポンスで"
          }), "ラベル"]
        }), "の値が常に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), "と返されます。レコードにプライマリー関連付けまたはカスタム関連付けラベルがある場合、これらのタイプは、ラベルなし関連付けタイプの横に表示されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotで定義されている全ての関連付けタイプを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#association-type-id-values",
        children: "このセクション"
      }), "で参照できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "単一のラベルとペアのラベル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レコード間の関係を表すために使用できる", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "関連付けラベル"
      }), "には、次の2種類があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**単一：**関係における両方のレコードに適用される1つのラベル。例えば「友人」、「同僚」などです。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["ペア：", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "1組のラベル。関連付けられたレコードのそれぞれの側の関係性を表すために異なる語が使用される場合です。例えば"
          }), "「親」と「子」"]
        }), "、「雇用主**」と「従業員**」などです。ペアのラベルを作成するには、リクエストに", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inverseLabel"
        }), "フィールドを含めて、ペアの2番目のラベルを指定する必要があります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連付けタイプを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタム関連付けタイプは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "HubSpot"
      }), "内で、または関連付けスキーマAPIエンドポイントを使用して作成できます。各オブジェクトペア間に最大で10個の関連付けタイプを作成できます（例：コンタクトと会社、コンタクトとコンタクト）。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["APIを使用して関連付けタイプを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
      }), "に送信し、リクエストに次の情報を含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), "：関連付けタイプの内部名。この値にハイフンを含めたり、値の先頭を数字にしたりすることは", (0, _jsxRuntime.jsx)("u", {
          children: "できません"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "label"
        }), "：", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
          children: "HubSpotで表示される関連付けラベル"
        }), "の名前。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "inverseLabel"
        }), "（ペアラベルのみ）：ラベルのペアにおける2番目のラベルの名前。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、リクエストは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "///Example request body - Single label\n{\n  \"label\": \"Partner\",\n  \"name\": \"partner\"\n}\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "///Example request body - Paired labels\n{\n  \"label\": \"Manager\",\n  \"inverseLabel\": \"Employee\",\n  \"name\": \"manager_employee\"\n}\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連付けタイプを取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定のオブジェクト間の関連付けタイプを表示するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "各項目に以下が含まれる配列が返されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**category：**関連付けタイプがHubSpotとユーザーのどちらによって作成されたか（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "HUBSPOT_DEFINED"
        }), "または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "USER_DEFINED"
        }), "）を示します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**typeId：**その関連付けタイプの数値ID。これを使用して、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#associate-with-label",
          children: "レコードを関連付けるときにラベルを設定"
        }), "します。HubSpotで定義されている全ての", (0, _jsxRuntime.jsx)(_components.code, {
          children: "typeId"
        }), "値については、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#association-type-id-values",
          children: "このリスト"
        }), "を参照してください。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**label：**英数字のラベル。「ラベルなし」関連付けタイプの場合、これは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), "になります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、これらの値はHubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/create-and-use-association-labels#association-label-api-details",
        children: "関連付け設定"
      }), "にもあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "レコードを関連付ける"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ラベルなしでレコードを関連付ける"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["2つのレコード間に既定のラベルなし関連付けを作成したり、レコードにラベルなし関連付けを一括で設定したりできます。2つのレコード間に既定の関連付けを個別に設定するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/{fromObjectType}/{fromObjectId}/associations/default/{toObjectType}/{toObjectId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエストURLには、次の情報を含めます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectType"
        }), "**：**関連付けるオブジェクトのID。ID値を見つけるには、この", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "オブジェクトタイプIDのリスト"
        }), "を参照してください。コンタクト、会社、取引、チケット、メモには、オブジェクト名（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        }), "など）を使用できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectId"
        }), "**：**関連付けるレコードのID。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectType"
        }), "**：**レコードの関連付け先となるオブジェクトのID。ID値を見つけるには、この", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "オブジェクトタイプIDのリスト"
        }), "を参照してください。コンタクト、会社、取引、チケット、メモには、オブジェクト名（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        }), "など）を使用できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectId"
        }), "**：**関連付け先となるレコードのID。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、IDが12345であるコンタクトレコードを、IDが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "67891"
      }), "である会社レコードに関連付ける場合、リクエストURLは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/contact/12345/associations/default/company/67891"
      }), "になります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既定の関連付けを一括で設定するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v4/associations/{fromObjectType}/{toObjectType}/batch/associate/default"
      }), "に送信します。リクエスト本文に、関連付けるレコードの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), "値を含めます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**1つのレコードに含めることができる関連付けの数は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/hubspot-product-and-services-catalog#TechnicalLimits:~:text=CRM%20Record%20Association%20Limits",
          children: "オブジェクトとHubSpotの配信登録"
        }), "によって異なります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ラベル付きでレコードを関連付ける"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["2つのレコードを関連付けて、その関連付けを記述するラベルを設定するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/{objectType}/{objectId}/associations/{toObjectType}/{toObjectId}"
      }), "に送信します。リクエスト本文に、作成する関連付けのタイプを示す", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), "を含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ラベルのない関連付けを作成する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#associate-records-without-a-label",
        children: "上記のセクション"
      }), "で概説した、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), "や", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), "を必要としない既定のエンドポイントを使用できます。ラベルを使った関連付けを作成する場合は、この", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#association-type-id-values",
        children: "既定のタイプIDのリスト"
      }), "を参照できます。または、これらのオブジェクト間のカスタム関連付けタイプを取得する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**クロスオブジェクトおよびペアになったラベルの関係の場合は、正しい方向を示す", (0, _jsxRuntime.jsx)(_components.code, {
          children: "typeId"
        }), "を必ず使用してください（例：コンタクトから会社、会社からコンタクト、従業員からマネージャー、マネージャーから従業員）。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、カスタムラベルを使用してコンタクトを取引に関連付けるには、次のようにします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1.", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/contact/deal/labels"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["2.応答の中で、ラベルの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), "値と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), "値を確認します。IDは数値で（例えば", (0, _jsxRuntime.jsx)(_components.code, {
        children: "36"
      }), "）、カスタムラベルの場合、カテゴリーは常に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "USER_DEFINED"
      }), "です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["3.次のリクエスト本文を使用して、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/contact/{objectId}/associations/deal/{toObjectId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "/// Example request body\n[\n  {\n    \"associationCategory\": \"USER_DEFINED\",\n    \"associationTypeId\": 36\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連付けの制限を設定および管理する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["オブジェクト間で関連付けられるレコードの数や、ラベルを使って関連付けを記述できる頻度に対して、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/set-limits-for-record-associations",
        children: "制限"
      }), "を設定することができます。また、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "技術的な制限やご利用のHubSpotの配信登録に応じた制限"
      }), "もあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付けの制限を作成または更新する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "オブジェクト間の関連付けの制限を新規作成したり、既存の制限を更新したりできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["制限を作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/create"
        }), "に送信します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["既存の制限を更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/update"
        }), "に送信します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リクエスト本文に、以下のものを使用した", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), "を含めます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "category"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["制限を設定する対象の関連付けのカテゴリー。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_DEFINED"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "USER_DEFINED"
            }), "のいずれかです。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "typeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["制限を設定する対象となる関連付けタイプの数値ID。既定の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "typeId"
            }), "値の", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#association-type-id-values",
              children: "リスト"
            }), "を参照するか、カスタムラベルの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#retrieve-association-types",
              children: "値を取得"
            }), "してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxToObjectIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "関連付けタイプで許容される関連付けの最大数。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、1つの取引に関連付けられるコンタクトは最大で5つ、取引の「Point of contact」というラベルを付けられるコンタクトは1つだけ、という制限を設定する場合には、次のようなリクエストになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request POST crm/v4/associations/definitions/configurations/deal/contact/batch/create\n{\n  \"inputs\": [\n    {\n      \"category\": \"HUBSPOT_DEFINED\",\n      \"typeId\": 3,\n      \"maxToObjectIds\": 5\n    },\n    {\n      \"category\": \"USER_DEFINED\",\n      \"typeId\": 35,\n      \"maxToObjectIds\": 1\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付け制限を取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["定義されている全ての関連付け制限を読み取るには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/definitions/configurations/all"
        }), "に送信します。これにより、全てのオブジェクトで定義されているカスタム関連付け制限が返されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["特定の2つのオブジェクト間の関連付け制限を読み取るには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}"
        }), "に送信します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["どちらのリクエストの場合も、応答では関連付けの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "maxToObjectIds"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), "の値が返されます。例えば、取引とコンタクトの間の制限を取得する場合、応答は次のようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example response GET crm/v4/associations/definitions/configurations/deal/contact\n{\n  \"results\": [\n    {\n      \"category\": \"HUBSPOT_DEFINED\",\n      \"typeId\": 3,\n      \"userEnforcedMaxToObjectIds\": 5,\n      \"label\": null\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付けの制限を削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["特定の関連付け制限を削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/purge"
      }), "に送信します。リクエスト本文に、制限を削除する対象の関連付けタイプの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), "値と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), "値を含めます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、取引とコンタクトの間の「Point of contact」制限を削除するには、次のようなリクエストになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request POST crm/v4/associations/definitions/configurations/deal/contact/batch/purge\n{\n  \"inputs\": [\n    {\n      \"category\": \"USER_DEFINED\",\n      \"typeId\": 35\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これが成功すると204レスポンスが返され、含まれる制限はシステムの既定値に戻ります（つまり多くのコンタクトに", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Point ofcontact"
      }), "というラベルを使用できます）。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "高い関連付け使用率に関するレポート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["１つのレコードに可能な関連付けの数には、技術的な", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "制限"
      }), "があります。関連付けAPIを使用すると、関連付けの上限に近づいている、または上限に達したレコードのレポートを取得できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レポートを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v4/associations/usage/high-usage-report/{userID}"
      }), "に送信します。ファイルには、関連付け制限の80%以上を使用しているレコードが含まれます。例えば、1つの会社を最大50,000件のコンタクトに関連付けることができる場合、既に40,000件以上のコンタクトが関連付けられている会社がファイルに含まれます。ファイルは、リクエストURLにIDが含まれていたユーザーのEメールに送信されます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/users/user-provisioning",
        children: "ユーザーAPI"
      }), "を使用してユーザーIDを取得する方法をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連付けタイプID値"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotで定義された、関連付けのタイプを指定する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), "値を以下の表に示します。関連付けタイプは、含まれるオブジェクトと関連付けの方向によって異なります（例えば「コンタクトから会社」は「会社からコンタクト」とは異なります）。カスタムオブジェクトまたはカスタム関連付けラベルを作成する場合、該当する関連付けタイプには固有の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), "値があり、これらの値を", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#retrieve-association-type",
        children: "取得する"
      }), "か、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/create-and-use-association-labels#association-label-api-details",
        children: "HubSpotの関連付け設定"
      }), "でこれらの値を見つける必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注"
        }), "：既定の会社関連付けタイプには、ラベルなし関連付けタイプとプライマリー関連付けタイプが含まれています。レコードに複数の会社が関連付けられている場合、1つだけをプライマリー会社にすることができます。その他の関連付けには、ラベルがないか、またはカスタム関連付けラベルを付けることができます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "コンタクトからオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "449"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトからコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "279"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトから会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトから会社へ（プライマリー）"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "4"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトから取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "15"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトからチケットへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "193"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトからコールへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "197"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトからEメールへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "199"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトからミーティングへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "201"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトからメモへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "203"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトからタスクへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "82"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトからコミュニケーション機能へ（SMS、WhatsApp、またはLinkedInメッセージ）"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "454"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトから郵便へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "587"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトからカートへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "508"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトから注文へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "178"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトから請求書へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "388"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトから支払いへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "296"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクトから配信登録へ"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "会社からオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "450"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社から会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "14"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "子会社から親会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "13"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "親会社から子会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "280"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社からコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "2"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社からコンタクトへ（プライマリー）"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "342"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社から取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "6"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社から取引へ（プライマリー）"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "340"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社からチケットへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "25"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社からチケットへ（プライマリー）"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "181"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社からコールへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "185"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社からEメールへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "187"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社からミーティングへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "189"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社からメモへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "191"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社からタスクへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "88"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社からコミュニケーション機能へ（SMS、WhatsApp、またはLinkedInメッセージ）"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "460"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社から郵便へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "180"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社から請求書へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "510"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社から注文へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "390"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社から支払いへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "298"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "会社から配信登録へ"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "取引からオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "451"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引から取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "3"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引からコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "341"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引から会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "5"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引から会社へ（プライマリー）"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "27"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引からチケットへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "205"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引からコールへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "209"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引からEメールへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "211"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引からミーティングへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "213"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引からメモへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "215"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引からタスクへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "86"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引からコミュニケーション機能へ（SMS、WhatsApp、またはLinkedInメッセージ）"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "458"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引から郵便へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "313"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引から取引分割へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "19"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引から商品項目へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "176"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引から請求書へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "511"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引から注文へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "392"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引から支払へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "630"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引から製品へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "63"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引から見積もりへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "300"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "取引から配信登録へ"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "チケットからオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "452"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットからチケットへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "16"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットからコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "339"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットから会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "26"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットから会社へ（プライマリー）"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "28"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットから取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "219"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットからコールへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "223"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットからEメールへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "225"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットからミーティングへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "227"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットからメモへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "229"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットからタスクへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "84"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットからコミュニケーション機能へ（SMS、WhatsApp、またはLinkedInメッセージ）"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "456"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットから郵便へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "32"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットからスレッドへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "278"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットからコミュニケーションへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "526"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "チケットから注文へ"
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsx)(_components.h3, {
          children: "リードからオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "578"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "リードからプライマリーコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "596"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "リードからコールへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "598"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "リードからEメールへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "600"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "リードからミーティングへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "602"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "リードからコミュニケーション機能へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "608"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "リードからコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "610"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "リードから会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "646"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "リードからタスクへ"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "コールからオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "194"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コールからコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "182"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コールから会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "206"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コールから取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "220"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コールからチケットへ"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Eメールからオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "198"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Eメールからコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "186"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Eメールから会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "210"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Eメールから取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "224"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Eメールからチケットへ"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "ミーティングからオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "200"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "ミーティングからコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "188"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "ミーティングから会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "212"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "ミーティングから取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "226"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "ミーティングからチケットへ"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "メモからオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "202"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "メモからコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "190"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "メモから会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "214"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "メモから取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "228"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "メモからチケットへ"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "郵便からオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "453"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "郵便からコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "459"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "郵便から会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "457"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "郵便から取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "455"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "郵便からチケットへ"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "見積もりからオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "69"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりからコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "71"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりから会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "64"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりから取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "67"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりから商品項目へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "286"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりから見積もりテンプレートへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "362"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりから割引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "364"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりから料金へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "366"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりから税金へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "702"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コンタクト署名者（電子署名の場合）"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "733"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりからカートへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "408"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりから請求書へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "731"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりから注文へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "398"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりから支払いへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "304"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "見積もりから配信登録へ"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "タスクからオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "204"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "タスクからコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "192"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "タスクから会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "216"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "タスクから取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "230"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "タスクからチケットへ"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "コミュニケーション機能（SMS、WhatsApp、またはLinkedInメッセージ）からオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "81"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コミュニケーション機能（SMS、WhatsApp、またはLinkedInメッセージ）からコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "87"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コミュニケーション機能（SMS、WhatsApp、またはLinkedInメッセージ）から会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "85"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コミュニケーション機能（SMS、WhatsApp、またはLinkedInメッセージ）から取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "83"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "コミュニケーション機能（SMS、WhatsApp、またはLinkedInメッセージ）からチケットへ"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "注文からオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "593"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文からカートへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "507"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文からコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "509"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文から会社へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "512"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文から取引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "519"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文から割引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "521"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文から割引コードへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "518"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文から請求書へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "513"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文から商品項目へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "523"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文から支払へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "730"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文から見積もりへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "516"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文から配信登録へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "726"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文からタスクへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "525"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "注文からチケットへ"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "カートからオブジェクトへ"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "タイプID"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "関連付けタイプ"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "586"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "カートからコンタクトへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "588"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "カートから割引へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "590"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "カートから商品項目へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "592"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "カートから注文へ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "732"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "カートから見積もりへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "728"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "カートからタスクへ"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "594"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "カートからチケットへ"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "v1関連付け（レガシー）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "v1関連付けAPIを使用している場合、レコードを関連付けるときに使用するIDについて、下記の表を参照してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "関連付けタイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "ID"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトから会社へ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "会社からコンタクトへ（既定）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "2"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "会社からコンタクトへ（全ラベル）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "280"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "取引からコンタクトへ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "3"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトから取引へ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "4"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "取引から会社へ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "5"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "会社から取引へ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "6"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "会社からエンゲージメントへ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "7"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "エンゲージメントから会社へ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "8"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトからエンゲージメントへ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "9"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "エンゲージメントからコンタクトへ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "10"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "取引からエンゲージメントへ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "11"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "エンゲージメントから取引へ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "12"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "親会社から子会社へ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "13"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "子会社から親会社へ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "14"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "コンタクトからチケットへ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "15"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "チケットからコンタクトへ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "16"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "チケットからエンゲージメントへ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "17"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "エンゲージメントからチケットへ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "18"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "取引から商品項目へ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "19"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目から取引へ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "20"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "会社からチケットへ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "25"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "チケットから会社へ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "26"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "取引からチケットへ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "27"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "チケットから取引へ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}