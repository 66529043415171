"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76425848696;
const slug = exports.slug = 'guides/apps/private-apps/overview';
const title = exports.title = '非公開アプリ';
const name = exports.name = 'APAC JAPAN (JA) | Private Apps |202210';
const metaDescription = exports.metaDescription = 'HubSpotで非公開アプリを作成する方法について説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "非公開アプリを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%A7api%E5%91%BC%E3%81%B3%E5%87%BA%E3%81%97%E3%82%92%E8%A1%8C%E3%81%86",
  "label": "アプリのアクセストークンでAPI呼び出しを行う",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E6%83%85%E5%A0%B1%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B",
  "label": "非公開アプリのアクセストークン情報を表示する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%82%92%E3%83%AD%E3%83%BC%E3%83%86%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%99%E3%82%8B",
  "label": "アクセストークンをローテーションする",
  "parent": null
}, {
  "depth": 0,
  "id": "api%E5%91%BC%E3%81%B3%E5%87%BA%E3%81%97%E3%83%AD%E3%82%B0%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B",
  "label": "API呼び出しログを表示する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E5%88%B6%E9%99%90",
  "label": "非公開アプリに関する制限",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "非公開アプリを削除する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img",
      pre: "pre",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Private apps"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "非公開アプリ（Private App）では、HubSpotのAPIを使用して、HubSpotアカウント上の特定のデータにアクセスできます。非公開アプリで何をリクエストまたは変更できるかは、アカウント側で非公開アプリごとに管理できます。権限が付与されると、そのアプリ専用のアクセストークンが生成されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotアカウント内で非公開アプリにアクセスするには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "スーパー管理者"
      }), "権限が必要です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**非公開アプリでは、カスタム タイムライン イベントがサポートされません。Webhookは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
          children: "非公開アプリでサポート"
        }), "されていますが、配信登録については、APIを介してプログラムで編集することはできません。代わりに、非公開アプリの設定で編集する必要があります。"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["カスタム タイムライン イベントを使用するアプリを作成する予定の場合は、代わりに公開アプリを作成してください。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "非公開アプリと公開アプリの違い"
        }), "をご覧ください。"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "非公開アプリを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントにて、メイン ナビゲーション バーにある設定アイコンをクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左のサイドバーメニューで、［連携］＞［非公開アプリ］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［非公開アプリを作成］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［基本情報］タブで、アプリの詳細を設定します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "アプリの名前を入力します。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "プレースホルダーロゴの上にカーソルを合わせると表示されるアップロードアイコンをクリックして、アプリのロゴとして使用する正方形の画像をアップロードします。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["アプリの", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "説明"
            }), "を入力します。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［スコープ］タブをクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["非公開アプリがアクセスできるように設定するスコープごとに、［読み取り］または［書き込み］チェックボックスをオンにします。［スコープを検索］検索バーを使用して、特定のスコープを検索することもできます。使用可能なスコープを網羅したリストは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/other-resources/scopes",
          children: "こちらのリファレンス記事"
        }), "でご確認いただけます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリの設定が完了したら、右上の［アプリを作成］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/updated-read-write-scope-config-private-apps.png",
        alt: "updated-read-write-scope-config-private-apps"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ダイアログボックスで、アプリのアクセストークンの情報を確認してから、［作成を続行］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリの作成が完了したら、アプリのアクセストークンを使用して、API呼び出しを開始できます。アプリの情報を編集したりアプリのスコープを変更したりする必要がある場合は、［詳細を編集］をクリックします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/edit-details-of-private-app.png",
        alt: "edit-details-of-private-app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリのアクセストークンでAPI呼び出しを行う"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "**注意：**HubSpotアカウントがダウングレードされ、機能が利用できなくなると、非公開アプリはスコープを使用できなくなります。例えば、アカウントがHubDBにアクセスできない場合、非公開アプリはHubDBスコープにアクセスできません。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "非公開アプリのアクセストークンは、HubSpotアカウントで利用可能なスコープと非公開アプリ用に設定した内容を反映するように更新されますが、トークン文字列自体は変更されません。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "API呼び出しを開始するには、アプリの詳細ページを開きます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［アクセストークン］カード内の［トークンを表示］をクリックして、アクセストークンを表示します。［コピー］をクリックして、トークンをクリップボードにコピーします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-1.png",
        alt: "show-private-app-access-token-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アクセストークンを貼り付けて、開発者に提供したり、ご自身でアプリを開発する際に使用したりできます。HubSpot APIエンドポイントに対して呼び出しを行う際には、Authorizationフィールドに「Bearer （自分のトークン名）」という値を設定します。例えば、Node.jsと", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), "（英語）を使用して", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "コンタクトAPI"
        }), "の呼び出しを行う場合、リクエストは次のようになります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "axios.get(\n  'https://api.hubapi.com/crm/v3/objects/contacts',\n  {\n    headers: {\n      Authorization: `Bearer ${YOUR_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["非公開アプリのアクセストークンはOAuthを基盤として実装されているため、HubSpotのクライアントライブラリーを使用して、アクセストークンによる認証済みの呼び出しを行うこともできます。例えば、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/hubspot-api-nodejs",
          children: "Node.jsクライアントライブラリー"
        }), "（英語）を使用する場合は、アプリのアクセストークンを渡すことにより、OAuthクライアントをインスタンス化できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspotClient = new hubspot.Client({ accessToken: YOUR_ACCESS_TOKEN });\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "非公開アプリのアクセストークン情報を表示する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["非公開アプリのアクセストークンに関する情報（Hub IDや、トークンに関連付けられているスコープなど）を表示するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/oauth/v2/private-apps/get/access-token-info"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを送信します。リクエスト本文にアクセストークンを含めます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request response body\n{\n  \"tokenKey\": {{accessToken}}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "レスポンスには、トークンを作成したユーザー、アカウントのHub ID、非公開アプリのID、トークンに関連付けられているスコープに関する情報が格納されています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response body\n{\n  \"userId\": 123456,\n  \"hubId\": 1020304,\n  \"appId\": 2011410,\n  \"scopes\": [\"oauth\", \"crm.schemas.companies.write\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アクセストークンをローテーションする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アクセストークンが分からなくなった場合や侵害された場合は、トークンをローテーション（変更）できます。新しいアクセストークンが作成され、元のアクセストークンは失効します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントにて、メインのナビゲーションバーに表示される設定アイコンをクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［連携］＞［非公開アプリ］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "非公開アプリの名前をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アクセストークンの横にある［変更（ローテーション）］をクリックします。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "トークンが侵害され、直ちにアクセスを取り消す場合は、［今すぐこのトークンを変更（ローテーション）して期限切れにする］をクリックします。"
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["特に侵害が生じていなくても、トークンは6か月ごとに変更することが推奨されます。トークンを定期的に変更する準備ができている場合は、［後でこのトークンを変更（ローテーション）して期限切れにする］をクリックします。これにより、7日後にトークンが失効します。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "アプリを早く移行する準備ができている場合は、［今すぐ期限切れにする］をクリックします。"
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "もっと時間が必要だと判断した場合は、［変更（ローテーション）をキャンセル］をクリックし、元のトークンの失効を取り消して、新しいアクセストークンを無効にすることができます。"
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/rotate-private-app-access-token.png",
        alt: "rotate-private-app-access-token"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、HubSpotは、アクセストークンのローテーションステータスやその他の関連するアラートに関するリマインダーを含むメール通知を", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "スーパー管理者"
      }), "に送信します。HubSpotアカウントのスーパー管理者は、次のイベントとリマインダーの通知を受け取ります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "スーパー管理者がローテーションを開始したとき（開始直後または7日後にスケジュール）。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "スーパー管理者が保留中のローテーションをキャンセルしたとき。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "スーパー管理者が、トークンの有効期限が切れるまで7日待つのではなく、［今すぐ期限切れにする］をクリックして、アクセストークンをすぐに期限切れにすることを選択したとき。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリのアクセストークンは24時間後に期限切れになるとき。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリのアクセストークンがローテーションされ、7日後に期限切れになったとき。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アクセストークンを180日以上ローテーションしていない場合に、スーパー管理者にもアプリのアクセストークンをローテーションするリマインダーメールが届くとき。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API呼び出しログを表示する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリによる過去30日間のAPI呼び出しを確認するには、以下の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリの詳細ページで、［ログ］タブをクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["非公開アプリのAPI呼び出しの確認および絞り込みを行うには、以下の手順に従います。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［メソッド］または［レスポンス］ドロップダウンメニューをクリックして、リクエストメソッドまたはレスポンスコードでAPI呼び出しの履歴を絞り込みます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［開始日］または［終了日］ドロップダウンメニューをクリックして、特定の期間でAPI呼び出しログを絞り込みます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "［リクエストURLで検索］検索ボックスで、URLを指定して特定の呼び出しを検索することもできます。"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/private-app-logs-tab-updated.png",
        alt: "private-app-logs-tab-updated"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["API呼び出しログをエクスポートするには、［ログをエクスポート（CSV）］をクリックします。次に、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "日付範囲"
        }), "（最長で過去30日まで）を指定し、［エクスポート］をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ポップアップボックスで、エクスポートするAPI呼び出しの", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "日付範囲"
        }), "を選択し、［エクスポート］をクリックします。エクスポートの準備ができると、ダウンロードリンクが記載されたEメールが届きます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "非公開アプリに関する制限"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotアカウント1件につき、最大20件の非公開アプリを作成できます。各非公開アプリには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "HubSpotのAPI利用ガイドライン"
      }), "が適用されます。非公開アプリから実行できる呼び出しの回数は、アカウントのご契約内容とAPI追加オプションのご購入状況に基づきます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {}), (0, _jsxRuntime.jsx)(_components.th, {
            children: "製品のエディション"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "10秒当たり"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "1日当たり"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "非公開アプリ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "（全ての製品の）無料ツール、Starter"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "非公開アプリ1件につき100回"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウント1件につき250,000回"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "（全ての製品の）Professional、Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "非公開アプリ1件につき150回"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウント1件につき500,000回"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "非公開アプリ（API追加オプション購入時）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "（全ての製品の）無料ツール、Starter、Professional、Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "非公開アプリ1件につき200回"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アカウント1件につき1,000,000回"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "StarterプランとProfessionalプランの両方を持っている場合、上位プラン（Professional）の制限がアカウントに適用されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "/account-info/v3/api-usage/daily/private-apps"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信して、HubSpotアカウントの全ての非公開アプリの毎日のAPI使用状況を確認できます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/account-information-api",
        children: "アカウント情報API"
      }), "の使用方法について詳しくはこちら。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "非公開アプリを削除する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "非公開アプリを削除すると、そのアプリのアクセストークンは永久に取り消され、API呼び出しにも使用できなくなります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリを削除するには、以下の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotアカウントで、メイン ナビゲーション バーにある", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "設定アイコン"
        }), "をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "非公開アプリの名前をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ページの上部にある［認証］タブをクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ページの最下部に表示される［（アプリ名）を削除］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ダイアログボックスにアプリの名前を入力して削除を確認し、［削除］をクリックします。"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}