"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831405;
const slug = exports.slug = 'guides/cms/content/overview';
const title = exports.title = 'Bloques de construcción del CMS de HubSpot';
const name = exports.name = 'EMEA ES CMS building blocks overview';
const metaDescription = exports.metaDescription = 'Los desarrolladores pueden usar una serie de bloques de construcción para crear sitios web en el CMS de HubSpot. Los bloques de construcción se pueden usar para crear un sistema de diseño para que los creadores de contenido trabajen, con diversos grados de flexibilidad y normas dependiendo de las necesidades de tu negocio.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "temas",
  "label": "Temas",
  "parent": null
}, {
  "depth": 0,
  "id": "plantillas",
  "label": "Plantillas",
  "parent": null
}, {
  "depth": 0,
  "id": "m%C3%B3dulos",
  "label": "Módulos",
  "parent": null
}, {
  "depth": 0,
  "id": "campos-de-m%C3%B3dulo-y-tema",
  "label": "Campos de módulo y tema",
  "parent": null
}, {
  "depth": 0,
  "id": "contenido-global",
  "label": "Contenido global",
  "parent": null
}, {
  "depth": 0,
  "id": "men%C3%BAs-y-navegaci%C3%B3n",
  "label": "Menús y navegación",
  "parent": null
}, {
  "depth": 0,
  "id": "formularios",
  "label": "Formularios",
  "parent": null
}, {
  "depth": 0,
  "id": "configuraci%C3%B3n-del-sitio-web",
  "label": "Configuración del sitio web",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Resumen de bloques de construcción del CMS"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los desarrolladores utilizan una serie de bloques de construcción para crear sitios web en el software de CMS de HubSpot. Los bloques de construcción se pueden usar para crear un sistema de diseño para que los creadores de contenido trabajen, con diversos grados de flexibilidad y normas dependiendo de las necesidades de tu negocio."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Temas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "tema"
      }), " es una colección portátil y contenida de materiales para desarrolladores diseñada para permitir una experiencia de edición de contenido amigable para los profesionales de marketing. Un tema es un bloque de construcción que define el aspecto de un sitio web y crea una experiencia de edición de contenidos fácil de usar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Plantillas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "plantillas"
      }), " definen el marcador base y el estilo de un conjunto de páginas que utilizan una plantilla. Cuando los creadores de contenido crean una página o un correo electrónico, seleccionan una plantilla para usar antes de completarla con contenido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), " son componentes reutilizables que puedes colocar en plantillas y páginas en el CMS de HubSpot. Incluyen controles para los profesionales de marketing y contienen marcas HubL/HTML, CSS y JavaScript que, en conjunto, construyen componentes reutilizables y editables de un sitio web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campos de módulo y tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "campos"
      }), " son los controles que los creadores de contenido utilizan para ajustar los parámetros pasados a sus temas y módulos. Los campos permiten a los desarrolladores que los bloques de construcción que crean sean reutilizables a través de diferentes piezas de contenido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contenido global"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "contenido global"
      }), " permite que el marcado y el contenido se compartan globalmente en un sitio web. El contenido global simplifica la gestión de elementos como encabezados, pies de página, barras laterales u otros elementos globales de un sitio web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Menús y navegación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/menus-and-navigation",
        children: "menús"
      }), " te permiten crear fácilmente una jerarquía de navegación y una arquitectura de información para navegar por tu sitio web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Formularios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/forms",
        children: "formularios"
      }), " te permiten capturar múltiples tipos de información de los usuarios (como información de dirección, direcciones de correo electrónico y comentarios) que luego puedes usar en todo el ecosistema de HubSpot en áreas como contenido inteligente, workflows, personalización de contenido y más en tus operaciones diarias."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuración del sitio web"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/website-settings",
        children: "configuración del sitio web"
      }), " es un único lugar donde se pueden configurar varias configuraciones globales y a nivel de sistema para tu sitio web, como tu logotipo, favicon, plantillas de sistema y más."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}