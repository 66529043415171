"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 40978690152;
const slug = exports.slug = 'guides/cms/content/themes/child-themes';
const title = exports.title = 'Child Themes';
const name = exports.name = '[Developers] Child Themes';
const metaDescription = exports.metaDescription = 'A child theme is a copy of an original parent theme. You can edit the child theme without altering the parent theme. You can create child themes from HubSpot themes, marketplace themes, as well as custom themes.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-child-theme-in-the-design-manager",
  "label": "Create a child theme in the Design Manager",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-child-theme-with-the-cli",
  "label": "Create a child theme with the CLI",
  "parent": null
}, {
  "depth": 0,
  "id": "limitations",
  "label": "Limitations",
  "parent": null
}, {
  "depth": 0,
  "id": "faqs",
  "label": "FAQs",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      a: "a",
      em: "em",
      pre: "pre",
      ol: "ol",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Child Themes"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise', 'cms-professional', 'cms-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "child theme"
      }), " is a copy of an original ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "parent theme"
      }), ". You can edit the child theme without altering the parent theme. You can create child themes from default HubSpot themes, Asset Marketplace themes, and custom themes."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " creating a child theme lets you customize its theme settings without impacting the parent theme settings. However, creating a child theme will not create clones of each of the theme's templates, modules, or files such as stylesheets and JavaScript. You'll need to manually clone these assets to create copies for the child theme."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a child theme in the Design Manager"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can create a child theme in the Design Manager. You will be able to edit the child theme even if the original theme is not editable. The following files will be added to your child theme:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), " - this will include an extends statement for linking to the parent theme."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "child.css"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "child.js"
        }), " - these are empty CSS and JS files. Code added to these files will only affect the child theme."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Any files that contain the ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/cms/hubl/variables#required-page-template-variables",
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "standard_header_includes"
          }), " HubL variable"]
        }), ". This usually includes a \"base\" or \"main\" template file. You can ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/main/src/templates/layouts",
          children: "view an example of this on our boilerplate"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn how to create a child theme from an Asset Marketplace or default HubSpot theme in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/use-themes#create-a-child-theme",
        children: "Knowledge Base"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a child theme from a custom theme:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing > Files and Templates > Design Tools."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the finder, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "File"
        }), " dropdown menu and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "New theme."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Theme Starting Point"
        }), " dropdown menu and select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Blank Theme"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " in the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Theme Label"
        }), " field."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To change where the child theme will be saved, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Change"
        }), " in the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "File location"
        }), " section. Click a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "folder"
        }), ", then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Select"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "theme.json"
        }), " file, enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "comma"
        }), " at the end of line 5, then add a new line below line 5."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On line 6, add an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "extends"
        }), " statement, using a value of the path of the parent theme."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"extends\": \"path/to/theme\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["By default, the child theme will inherit ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "fields.json"
        }), " from the parent theme, so you do not need to create a separate ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "fields.json"
        }), " for the child theme if you don't plan on updating it."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a child theme with the CLI"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can create a child theme with the CLI. You must complete ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "the setup process for the CLI"
      }), " before creating a child theme. Your child theme will inherit the templates and files from the parent theme. You will be able to edit the new theme within the CLI and in the Design Manager."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Create a new directory for your theme."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "mkdir cms-project\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the new directory, create a copy of the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "theme.json"
        }), " file of the parent theme."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd cms-project\ncp ~/src/cms-sprout-theme/src/theme.json\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the copied ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "theme.json"
        }), " file, add a comma to the end of line 5 and a new line below line 5 (or below the final line of the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "responsive_breakpoints"
        }), ", if they are present)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Add an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "extends"
        }), " statement to line 6 (or 14, or the appropriate line for your theme), using a value of the path of the parent theme."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"extends\": \"@hubspot/barricade\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["By default, the child theme will inherit ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "fields.json"
        }), " from the parent theme. Consequently, you don't need to create a separate ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "fields.json"
        }), " for the child theme if you don't plan on updating it."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Upload your new theme and files to your HubSpot account."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs upload cms-project cms-project\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limitations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The total number of child themes you can have is based on your subscription:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing Hub"
          }), " Professional"]
        }), " or ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "CMS Hub"
          }), " Professional"]
        }), ": five child themes"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing Hub"
          }), " Enterprise"]
        }), " or ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "CMS Hub"
          }), " Enterprise:"]
        }), " 10 child themes"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpot's free tools and ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "CMS Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Starter"
        }), ": one child theme"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "FAQs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Can I copy a child theme to another HubSpot account if the parent theme is from the Asset Marketplace?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Yes, as long as the “extends” path is the same in the other HubSpot account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "What assets are inherited from the parent theme?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "All files are inherited from the parent theme unless they are overwritten in the child theme."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "How can I override a particular asset from the parent theme?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "A file in the same relative path of a child theme will overwrite the equivalent file from the parent theme. So, for instance, to overwrite ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@marketplace/parent/theme/templates/about.html"
        }), " you can create ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/child/theme/templates/about.html"
        }), " and make your edits to the new file. The new file will take effect instead of the inherited file. This applies to your ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "fields.json"
        }), " file as well as other files in the theme."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "How can I create new pages using the child theme?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "When you create a new page, your child theme will appear as an option under ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Your Themes"
        }), " on the theme selection screen. Learn more about creating pages using themes in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/create-website-content-using-a-theme",
          children: "Knowledge Base."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "How can I edit an existing page to use a child theme instead of the parent theme?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "You can replace your page template with the equivalent template from the new theme. For instance, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/cos-general/how-do-i-edit-my-blog-email-page-template#use-a-different-template",
          children: "replace the template"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "landing-page.html"
        }), " (in the parent theme) with the template ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "landing-page.html"
        }), " (in the new theme)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "How can I edit a template’s label on the page creation screen?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "You can change the label of your template by editing the HTML file. The label is located in a comment at the top of your theme file."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Can I create a child theme from a child theme?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Currently you cannot create a child theme from a child theme."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}