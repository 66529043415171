"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816705711;
const slug = exports.slug = 'guides/apps/overview';
const title = exports.title = 'Descripción general de la creación de aplicaciones';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Developer tools and accounts';
const metaDescription = exports.metaDescription = 'Descubre las herramientas y las cuentas de desarrollador de HubSpot. Úsalas para crear una integración, anunciar una aplicación en el Mercado o crear una cuenta de prueba.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tipos-de-aplicaciones",
  "label": "Tipos de aplicaciones",
  "parent": null
}, {
  "depth": 1,
  "id": "aplicaciones-privadas",
  "label": "Aplicaciones privadas",
  "parent": "tipos-de-aplicaciones"
}, {
  "depth": 2,
  "id": "aplicaciones-privadas-en-proyectos-versi%C3%B3n-beta",
  "label": "Aplicaciones privadas en proyectos (versión beta)",
  "parent": "tipos-de-aplicaciones"
}, {
  "depth": 1,
  "id": "aplicaciones-p%C3%BAblicas",
  "label": "Aplicaciones públicas",
  "parent": "tipos-de-aplicaciones"
}, {
  "depth": 1,
  "id": "extensiones-compatibles-por-tipo-de-aplicaci%C3%B3n",
  "label": "Extensiones compatibles por tipo de aplicación",
  "parent": "tipos-de-aplicaciones"
}, {
  "depth": 0,
  "id": "tipos-de-cuentas-para-el-desarrollo-de-aplicaciones",
  "label": "Tipos de cuentas para el desarrollo de aplicaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "autentificaci%C3%B3n",
  "label": "Autentificación",
  "parent": null
}, {
  "depth": 1,
  "id": "claves-de-api-de-la-cuenta-de-desarrollador",
  "label": "Claves de API de la cuenta de desarrollador",
  "parent": "autentificaci%C3%B3n"
}, {
  "depth": 0,
  "id": "delete-a-developer-account",
  "label": "Delete a developer account",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      ul: "ul",
      li: "li",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Descripción general de la creación de aplicaciones"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las aplicaciones te permiten autenticar llamadas de API a tu cuenta de HubSpot, suscribirte a eventos con webhooks y extender la interfaz de usuario de HubSpot, como con tarjetas personalizadas. HubSpot ofrece varios tipos de aplicaciones y extensiones según las necesidades de tu integración. A continuación, obtén más información sobre los tipos de aplicaciones y extensiones que puedes crear con HubSpot, cómo comenzar a crearlas y cómo administrarlas en HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de aplicaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dependiendo del tipo de integración que desees construir, deberás elegir el tipo correcto de aplicación. A continuación, descubre más información sobre los tipos de aplicaciones que puedes crear y las funcionalidades que admiten."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para las extensiones de creación, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#supported-extensions-by-app-type",
        children: "ve la tabla de referencia a continuación"
      }), " para obtener una descripción rápida de qué extensiones se pueden construir con qué tipos de aplicaciones."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicaciones privadas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las aplicaciones privadas se pueden crear para una sola cuenta de HubSpot y son más adecuadas para scripts únicos o extensiones de un solo propósito. Por ejemplo, puedes crear una aplicación privada para tu cuenta de HubSpot para:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Crear un nuevo objeto personalizado a través de la API."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Importa registros del CRM desde un archivo CSV."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Autenticar solicitudes de API en acciones de automatización personalizadas y bots de chat."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
          children: "Crea y edita suscripciones a webhook"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En general, las aplicaciones privadas son más fáciles de implementar que las aplicaciones públicas. Las aplicaciones privadas se autentican con tokens de acceso y no se pueden incluir en el Mercado de aplicaciones de HubSpot. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/hubspot-integration-choosing-private-public-hubspot-apps",
        children: "cuándo crear aplicaciones privadas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si este tipo de aplicación se adapta a tus necesidades, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "empieza a crear una aplicación privada en tu cuenta de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Aplicaciones privadas en proyectos (versión beta)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las aplicaciones privadas creadas con proyectos permiten crear extensiones de interfaz de usuario para registros del CRM. La diferencia al crear una aplicación privada a través de este método es que los proyectos solo admiten la creación de extensiones de interfaz de usuario, las cuales no pueden crear las aplicaciones privadas fuera de los proyectos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si este tipo de aplicación se ajusta a tus necesidades, echa un vistazo a la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/quickstart",
        children: "guía de inicio rápido de proyectos"
      }), " para comenzar a crear una aplicación privada y un proyecto utilizando la CLI."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicaciones públicas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las aplicaciones públicas se pueden instalar en varias cuentas. Además de los tipos de extensiones que puedes crear con una aplicación privada, las aplicaciones públicas admiten funciones avanzadas, como:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Suscribirse a eventos de toda la cuenta usando la API de webhooks."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Crear eventos de línea de tiempo personalizados en registros del CRM usando la API de eventos de línea de tiempo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Crear páginas de configuración de aplicaciones personalizadas en HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las aplicaciones públicas se autentican con OAuth y se pueden anunciar en el Mercado de aplicaciones de HubSpot. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/hubspot-integration-choosing-private-public-hubspot-apps",
        children: "cuándo crear aplicaciones públicas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si este tipo de aplicación se ajusta a tus necesidades, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "comienza a crear una aplicación pública en tu cuenta de desarrollador de aplicaciones"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Extensiones compatibles por tipo de aplicación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de aplicación"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Extensiones compatibles"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicaciones privadas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/video-conferencing",
                  children: "Extensión de conferencia en video"
                })
              })
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicación pública"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/calling-sdk",
                  children: "Llamadas con SDK"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "Tarjetas CRM"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/timeline",
                  children: "Eventos de línea de tiempo"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: "Extensión de conferencia en video"
              })]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicación privada creada con proyectos (versión beta)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/crm/ui-extensions/create",
                  children: "Extensiones de la IU"
                })
              })
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["* Las tarjetas CRM que puedes crear con aplicaciones públicas son diferentes de las tarjetas personalizadas que puedes crear como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/create",
          children: "extensiones de interfaz de usuario con proyectos (versión beta)"
        }), ". Las extensiones de interfaz de usuario ofrecen una funcionalidad más avanzada y componentes personalizables"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de cuentas para el desarrollo de aplicaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aunque el desarrollador de aplicaciones y las cuentas de prueba funcionen juntos, cada uno de ellos tiene un propósito diferente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Las cuentas de desarrollador de aplicaciones están destinadas a crear y listar aplicaciones en el mercado de aplicaciones."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Prueba las cuentas destinadas a probar las API y las aplicaciones que estás creando sin afectar los datos en una cuenta real de HubSpot. Esto es independiente de una cuenta de entorno de pruebas que puedes crear dentro de las cuentas estándar de HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "tipos de cuenta de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autentificación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si quieres crear una integración personalizada con una sola cuenta de HubSpot, puedes crear una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "aplicación privada"
      }), " y usar su ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "token de acceso"
      }), " para autenticar llamadas de API o usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), " con una aplicación pública. Cualquier aplicación diseñada para ser instalada por varias cuentas de HubSpot o que se anuncie en el Mercado de aplicaciones debe usar OAuth."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " desde el 30 de noviembre de 2022, las claves de API de HubSpot están obsoletas y ya no son compatibles. El uso continuado de las claves de API de HubSpot es un riesgo de seguridad para tu cuenta y tus datos. Durante esta fase de obsolescencia, HubSpot puede desactivar tu clave en cualquier momento."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["En su lugar, debes autentificarte usando un token de acceso de una aplicación privada u OAuth. Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "este cambio"
        }), " y cómo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migrar una integración de claves de API"
        }), " para usar una aplicación privada en su lugar."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si quieres suscribirte a webhooks o configurar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth para tu aplicación"
      }), ", debes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "crear una cuenta de desarrollador"
      }), ". Con las cuentas de desarrollador, también puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "anunciar tus aplicaciones"
      }), " en el Mercado de aplicaciones o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "crear una cuenta de prueba"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Claves de API de la cuenta de desarrollador"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para administrar la configuración de tu aplicación a través de la API, puedes usar una clave de API de desarrollador. Esta clave de API está separada de las claves de API estándar, que han quedado obsoletas. Las API de desarrollador se pueden usar para administrar suscripciones para la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "API de Webhooks"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline",
        children: "crear o actualizar tipos de eventos para la característica eventos de la línea de tiempo"
      }), ". Todas las demás llamadas a la API deben realizarse usando un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "token de acceso a la aplicación privada"
      }), " u OAuth."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para acceder a la clave de API de tu cuenta de desarrollador de aplicaciones:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de desarrollador de aplicaciones, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones"
        }), " en la barra de navegación superior."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Obtén la clave de API de HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el cuadro de diálogo, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mostrar clave"
        }), ". La clave se revelará y luego puedes hacer clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copiar"
        }), " junto a la clave."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["También puedes desactivar la clave de API anterior y generar una nueva haciendo clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Volver a generar clave"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/show_dev_api_key.webp",
        alt: "show_dev_api_key"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete a developer account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes eliminar las cuentas de desarrollador de aplicaciones si no contienen aplicaciones con instalaciones o anuncios de mercado activos. Si tu cuenta tiene aplicaciones con instalaciones o anuncios de mercado activos y te gustaría eliminar tu cuenta, ponte en contacto con asistencia técnica para obtener ayuda."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que eliminas tu cuenta, ya no podrás acceder a esa cuenta. Si puedes alternar entre varias cuentas de HubSpot, la cuenta eliminada ya no aparecerá."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En tu cuenta de desarrollador de la API de HubSpot, haz clic en el nombre de tu cuenta en la esquina superior derecha y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Cuenta."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/account.jpeg",
        alt: "account"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Eliminar cuenta."
      }), " Si tu cuenta tiene aplicaciones con instalaciones o anuncios activos en el mercado, este botón estará desactivado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Account2.jpg",
        alt: "Account2"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el cuadro de diálogo, escribe el ID de tu cuenta y luego haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Eliminar cuenta de desarrollador"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Confirm2.jpg",
        alt: "Confirm2"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}