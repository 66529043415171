"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129093145;
const slug = exports.slug = 'guides/cms/tools/personal-access-key';
const title = exports.title = 'Chaves de acesso pessoal do CMS';
const name = exports.name = 'PT-BR Personal CMS Access Keys';
const metaDescription = exports.metaDescription = 'As chaves de acesso CMS pessoais são uma forma de autenticação que deve ser usada pelos desenvolvedores que trabalham no HubSpot. Elas estão vinculadas a um usuário específico em uma conta.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h3: "h3",
      h4: "h4",
      code: "code",
      img: "img",
      a: "a",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Chave de acesso pessoal"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Com as ferramentas de desenvolvimento local, é recomendado usar as chaves de acesso pessoal como forma de autenticação."
      }), " As chaves de acesso pessoal funcionam de maneira semelhante às chaves de API, mas estão vinculadas a um usuário específico em uma conta. As chaves de acesso pessoal funcionam apenas com as ferramentas de desenvolvimento local."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Chaves de acesso pessoal em comparação com outros métodos de autenticação"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A vantagem das chaves de acesso pessoal em relação a implementações como chaves de API é que estas têm permissões de superadministrador. As chaves de acesso pessoal estão limitadas às permissões que o usuário individual tem no portal. Se o usuário tiver permissões de Superadministrador, ele não verá diferença em sua funcionalidade, mas a vantagem é que, se um desenvolvedor individual precisar ser removido de uma conta, bastará desativar seu usuário na conta para desativar suas capacidades de desenvolvimento local."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Como as chaves de acesso pessoal estão vinculadas ao usuário individual em uma conta, podemos exibir informações mais úteis, por exemplo, se um desenvolvedor alterar ou enviar um arquivo usando as ferramentas de desenvolvimento local usando uma chave de acesso pessoal, podemos atribuir a alteração no aplicativo a esse usuário. Isso facilita o trabalho em equipe e a compreensão de quem fez o quê."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As chaves de acesso pessoal estão vinculadas ao usuário individual na conta específica da HubSpot, e não diretamente ao usuário. O que isso significa é que, usando as ferramentas de desenvolvimento local, você precisará gerar uma nova chave de acesso pessoal para cada conta na qual deseja usar as ferramentas de desenvolvimento. Assim, é adicionada uma camada de segurança para as contas, pois caso um agente malicioso obtivesse sua chave de acesso, ele somente poderia afetar os portais individuais e o usuário individual."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Semelhança com OAuth2"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Por trás dos bastidores, as chaves de acesso pessoal agem como OAuth2. Ao gerar uma chave de acesso pessoal, você escolhe as permissões que deseja que essa chave tenha. Você pode ter apenas uma chave de acesso por usuário por conta da HubSpot. Depois de gerar sua chave de acesso, um aplicativo será conectado à sua conta da HubSpot chamado \"Ferramentas de desenvolvimento local da HubSpot\". Este aplicativo interno da HubSpot facilita a autenticação das ferramentas de desenvolvimento local usando uma chave de acesso pessoal. A desconexão deste aplicativo excluirá qualquer chave de acesso gerada anteriormente, de modo que suas ferramentas de desenvolvimento local instantaneamente deixem de se conectar por meio dessas chaves de acesso. Você precisará gerar uma nova chave e atualizar o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot.config.yml"
            }), "."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/personalcmsaccesskey.png",
            alt: "personalcmsaccesskey"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Proteja suas credenciais"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Proteja suas chaves de acesso pessoal como se fossem uma senha de conta; não as compartilhe com ninguém. Elas permitem que o portador se autentique como se fosse você e tome qualquer ação que você pessoalmente possa tomar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usar chaves de acesso pessoal com as ferramentas de desenvolvimento local"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As chaves de acesso pessoal foram criadas para serem usadas com as ferramentas de desenvolvimento local."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "Comece a usar as ferramentas de desenvolvimento local"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://app.hubspot.com/l/personal-access-key",
        children: "Veja sua chave de acesso pessoal do CMS."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando usado para autenticação nas ferramentas de desenvolvimento local, seu arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " será semelhante a este:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: production\nportals:\n  - name: production\n    portalId: <portalId>\n    authType: personalaccesskey\n    personalAccessKey: >-\n      CJDVnLanLRICEQIYyLu8LyDh9E4opf1GMhkAxGuU5XN_O2O2QhX0khw7cwIkPkBRHye-OfIADgLBAAADAIADAAAAAAAAAAJCGQC8a5TlhtSU8T-2mVLxOBpxS18aM42oGKk\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          CJDVnLanLRICEQIYyLu8LyDh9E4opf1GMhkAxGuU5XN_O2O2QhX0khw7cwIkPkBRHye-OfIADgLBAAADAIADAAAAAAAAAAJCGQC8a5TlhtSU8T-2mVLxOBpxS18aM42oGKk\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}