"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50631386772;
const slug = exports.slug = 'reference/cms/fields/rich-text-editor';
const title = exports.title = 'リッチ テキスト エディターの設定';
const name = exports.name = 'APAC JAPAN (ja) | [Developers] Configuring the Rich Text Editor_JA | 202108';
const metaDescription = exports.metaDescription = 'カスタムモジュール内のリッチ テキスト エディターの機能として、設定ツールバーのコンポーネントを開発者が削除できる機能が用意されました。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "enabled_features%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95",
  "label": "enabled_featuresを使用する方法",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%A9%9F%E8%83%BD%E3%83%AA%E3%82%B9%E3%83%88",
  "label": "機能リスト",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%AB%E3%81%AE%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97",
  "label": "コントロールのグループ",
  "parent": "%E6%A9%9F%E8%83%BD%E3%83%AA%E3%82%B9%E3%83%88"
}, {
  "depth": 1,
  "id": "%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E6%9B%B8%E5%BC%8F%E8%A8%AD%E5%AE%9A",
  "label": "テキスト書式設定",
  "parent": "%E6%A9%9F%E8%83%BD%E3%83%AA%E3%82%B9%E3%83%88"
}, {
  "depth": 1,
  "id": "%E6%8C%BF%E5%85%A5%E3%83%9C%E3%82%BF%E3%83%B3",
  "label": "挿入ボタン",
  "parent": "%E6%A9%9F%E8%83%BD%E3%83%AA%E3%82%B9%E3%83%88"
}, {
  "depth": 1,
  "id": "%E8%A9%B3%E7%B4%B0%E3%82%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "詳細オプション",
  "parent": "%E6%A9%9F%E8%83%BD%E3%83%AA%E3%82%B9%E3%83%88"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      h2: "h2",
      pre: "pre",
      img: "img",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "リッチ テキスト エディターの設定"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発者の方なら、WYSIWYGエディターの機能によっては使い方を誤ると、ブランドの一貫性を確保できなくなり、コンテンツのデザインとフローに影響が生じたという経験があるはずです。カスタムモジュール内のリッチ テキスト エディターの機能として、fields.jsonファイルの中の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), "プロパティーを使用して、設定ツールバーのコンポーネントを開発者が削除できる機能が用意されました。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "注：以下は、ローカル開発においてリッチ テキスト フィールドを使用するカスタムモジュールにのみ該当します。この機能を使用しても、リッチ テキスト エディターから削除されたオプションの機能が無効になることはなく、オプションが表示されなくなるだけです。既存のコンテンツが影響を受けないように下位互換性を維持することが目的です。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "enabled_featuresを使用する方法"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リッチ テキスト フィールド オブジェクトを使用しているfields.jsonファイルの中で、以下のように、有効なツールバーオプションを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), "プロパティーの配列に追加することにより、特定の機能を有効化できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Rich text field with only Bold, Link, and Image available in the Toolbar\n{\n  \"name\": \"description\",\n  \"label\": \"Description\",\n  \"required\": false,\n  \"locked\": false,\n  \"type\": \"richtext\",\n  \"default\": null,\n  \"enabled_features\": [\"bold\", \"link\", \"image\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これにより制作担当者には、以下の画像のように、追加されたオプションのみが有効化された状態でリッチ テキスト エディターが表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["注：エディターの既定のスタイルに戻す［スタイルをクリア］ボタンなど、一部の機能は常に有効で、削除はできません。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "enabled_features"
        }), "プロパティーを省略すると、全ての機能が表示されます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/custom_rte_toolbar.jpg",
        alt: "さまざまな機能を有効にしたRTEツールバーの例。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "機能リスト"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), "プロパティーを使用する場合に、個別に有効化できる機能を示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "コントロールのグループ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "オプション"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "colors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト色と背景色のコントロール。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fonts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォントファミリーとフォントサイズのコントロール。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "indents"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アウトデントとインデントのコントロール。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lists"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "箇条書きと番号リストのコントロール。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_emphasis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "太字、イタリック、下線のコントロール。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "advanced_emphasis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "打ち消し線、上付き文字、下付き文字、コード書式設定のコントロール。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "glyphs"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "絵文字、特殊文字、アイコンのコントロール。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "テキスト書式設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "オプション"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "block"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "スタイル切り替えのドロップダウンメニューを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "font_family"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォント切り替えのドロップダウンメニューを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "font_size"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォントサイズのドロップダウンメニューを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bold"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "太字ボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "italic"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "イタリックのボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "underline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "下線ボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト色ボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "背景色ボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "位置合わせボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bulleted_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "箇条書きリストボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numbered_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "番号リストボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lineheight"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "行の高さボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "outdent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アウトデントボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "indent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "インデントボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "strikethrough"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "取り消し線ボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "superscript"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "上付き文字ボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscript"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "下付き文字ボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "code_format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コード書式設定ボタンを表示します。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "挿入ボタン"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "オプション"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リンクボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "画像ボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "emoji"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "絵文字ボタンを表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "personalize"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "パーソナライズのツールバー項目を表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cta"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "挿入メニューの下にCTAメニュー項目を表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "挿入メニューの下に埋め込みメニュー項目を表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "挿入メニューの下に動画メニュー項目を表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "挿入メニューの下にテーブルメニュー項目を表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "charmap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "挿入メニューの下に特殊文字メニュー項目を表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "anchor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "挿入メニューの下にアンカーメニュー項目を表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hr"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "挿入メニューの下に水平線メニュー項目を表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nonbreaking_space"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "挿入メニューの下に、改行しないスペース（nbsp）のメニュー項目を表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "挿入メニューの下にアイコンメニュー項目を表示します。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "詳細オプション"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "オプション"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "source_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "詳細メニューの下にソース コード メニュー項目を表示します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "visual_blocks"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "詳細メニューの下にブロック表示メニュー項目を表示します。"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}