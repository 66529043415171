"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 51896141129;
const slug = exports.slug = 'guides/apps/private-apps/overview';
const title = exports.title = 'Private apps';
const name = exports.name = 'Private apps';
const metaDescription = exports.metaDescription = 'Learn how to create private apps in HubSpot.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-private-app",
  "label": "Create a private app",
  "parent": null
}, {
  "depth": 0,
  "id": "make-api-calls-with-your-app-s-access-token",
  "label": "Make API calls with your app's access token",
  "parent": null
}, {
  "depth": 0,
  "id": "view-private-app-access-token-information",
  "label": "View private app access token information",
  "parent": null
}, {
  "depth": 0,
  "id": "rotate-your-access-token",
  "label": "Rotate your access token",
  "parent": null
}, {
  "depth": 0,
  "id": "view-api-call-logs",
  "label": "View API call logs",
  "parent": null
}, {
  "depth": 0,
  "id": "private-app-limits",
  "label": "Private app limits",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-a-private-app",
  "label": "Delete a private app",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      img: "img",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Private apps"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Private apps allow you to use HubSpot's APIs to access specific data from your HubSpot account. You can authorize what each private app can request or change in your account, which will generate an access token that's unique to your app."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You must be a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "super admin"
      }), " to access private apps in your HubSpot account."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " private apps do not support custom timeline events. Webhooks are ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
          children: "supported in private apps"
        }), ", but subscriptions cannot be edited programmatically via an API, and must instead be edited in your private app settings."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["If you plan on building an app using custom timeline events, you should create a public app instead. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "differences between private and public apps"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a private app"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create private app"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Basic Info"
        }), " tab, configure the details of your app:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Enter your app's ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "name"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Hover over the placeholder logo and click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "upload icon"
            }), " to upload a square image that will serve as the logo for your app."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "description"
            }), " for your app."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Scopes"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["At the top of the page, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add new scope"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right panel, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "checkbox"
        }), " for each scope you want your private app to be able to access.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["You can also search for a specific scope using the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Find a scope"
            }), " search bar. You can review a full list of available scopes in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/scopes",
              children: "this reference article"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Update"
            }), " when you're done adding scopes. If you later decide that your app requires additional scopes, you can also configure them after your app is created."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/updated-scope-configuration-panel-in-private-apps.png",
        alt: "updated-scope-configuration-panel-in-private-apps"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review the scopes you've selected for your app. If you decide your app does not require a specific scope, you can click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Delete"
        }), " next to that scope to remove it. You can also click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Summary of selected scopes"
        }), " to view a breakdown of your app's scopes and the associated access granted for each one."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/updated-scope-configuration-page-for-private-apps.png",
        alt: "updated-scope-configuration-page-for-private-apps"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "To subscribe to events triggered by changes to CRM objects in your account, you can set up webhook subscriptions for your private app:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["At the top of the page, Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Webhooks"
              }), " tab."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Target URL"
              }), ", enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "URL"
              }), " that HubSpot will make a ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "POST"
              }), " request to when events trigger."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Create subscription"
              }), "."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["In the right panel, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "object types"
              }), " that you want to subscribe to, then select the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "events"
              }), " associated with those objects (e.g., created, merged, deleted, etc.) that will trigger HubSpot to send a request to the endpoint you configured."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: "If you select an object type that requires additional scopes you haven't authorized, you'll be prompted to add those scopes to your app."
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["If you select ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Property changed"
              }), " for the event type, you can then select any of the associated object properties that you want to subscribe to changes for."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Subscribe"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/create-new-webhook-subscription-in-private-app.png",
                alt: "create-new-webhook-subscription-in-private-app"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["If you don't want your webhook subscriptions to be active immediately, or if you want to delete a subscription you mistakenly created, you can hover over the webhook and manage its status, unsubscribe to delete it, or review the subscription details. Learn more about using webhook subscriptions in a private app in ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
                children: "this article"
              }), "."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/edit-webhook-settings-in-private-app-1.png",
        alt: "edit-webhook-settings-in-private-app-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After you're done configuring your app, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create app"
        }), " in the top right."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, review the info about your app's access token, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Continue creating"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you've created your app, you can start making API calls using the app's access token. If you need to edit your app's info or change its scopes, click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Edit app"
      }), " in the top right of the app details page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/edit-private-app-details-after-creation.png",
        alt: "edit-private-app-details-after-creation"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Make API calls with your app's access token"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " private apps will lose access to scopes when your HubSpot account is downgraded and loses access to functionality. For example, if your account does not have access to HubDB, your private app will not have access to the HubDB scope."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Private app access tokens will be updated to reflect available scopes in your HubSpot account and what you configured for the private app, but the token string itself will not change."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To start making API calls, navigate to the details page of your app."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of your app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Auth"
        }), " tab, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Show token"
        }), " to reveal your access token. Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copy"
        }), " to copy the token to your clipboard."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/show-access-token-for-private-app-updated.png",
        alt: "show-access-token-for-private-app-updated"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You can then paste the access token to provide it to your developers, or use it yourself as you develop your app. When making a call to one of the HubSpot API endpoints, set the value of the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Authorization"
        }), " field to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bearer [YOUR_TOKEN]"
        }), ". For example, if you're making a call to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "Contacts API"
        }), " using Node.js and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), ", the request would look like the following:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "axios.get(\n  'https://api.hubapi.com/crm/v3/objects/contacts',\n  {\n    headers: {\n      Authorization: `Bearer ${YOUR_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Private app access tokens are implemented on top of OAuth, so you can also make authenticated calls with your access token using one of HubSpot's client libraries. For example, if you're using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/hubspot-api-nodejs",
          children: "Node.js client library"
        }), ", you can instantiate an OAuth client by passing in your app's access token:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspotClient = new hubspot.Client({ accessToken: YOUR_ACCESS_TOKEN });\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View private app access token information"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view information about a private app's access token, such as the Hub ID and scopes associated with the token, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/oauth/v2/private-apps/get/access-token-info"
      }), ". In the request body, include your access token:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request response body\n{\n  \"tokenKey\": {{accessToken}}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include information about the user who created the token, the Hub ID of the account, the private app ID, and the scopes associated with the token."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response for GET request to /oauth/v2/private-apps/get/access-token-info\n{\n  \"userId\": 123456,\n  \"hubId\": 1020304,\n  \"appId\": 2011410,\n  \"scopes\": [\"oauth\", \"crm.schemas.companies.write\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rotate your access token"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you access token is lost or otherwise compromised, you can rotate the token. A new access token will be created and the original access token will expire."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Settings"
        }), " page in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of your private app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Next to your access token, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rotate"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If your token is compromised and you want to immediately revoke access, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Rotate and expire now"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If there's no imminent threat to your token, it's still recommended that you rotate your token every six months. If you're ready to initiate a regular rotation of your token, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Rotate and expire later"
            }), ", which will trigger an expiration of the token in 7 days.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["If your app is ready to transition earlier, you can click ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Expire now"
                }), "."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["If you decide you need more time, you can click ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Cancel rotation"
                }), ", which will cancel the expiration of the original token and revoke the new access token."]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/rotate-private-app-access-token-updated.png",
        alt: "rotate-private-app-access-token-updated"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot will also send email notifications to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "super admins"
      }), " with reminders about access token rotation status, as well as other related alerts. Super admins in your HubSpot account will receive notifications for the following events and reminders:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A super admin initiated a rotation (either immediately or scheduled for 7 days from now)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A super admin canceled a pending rotation."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A super admin opted to expire an access token immediately by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Expire now"
        }), " instead of waiting 7 days for the token to expire."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The app's access token is about to expire in 24 hours."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The app's access token has been rotated and expired after 7 days."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If you haven't rotated your access token in over 180 days, super admins will also receive a reminder email to rotate your app's access token."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View API call logs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To review the API calls your app has made in the past 30 days:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the details page of your app, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logs"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review and filter your private app API calls:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Method"
            }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Response"
            }), " dropdown menus to filter your historical API calls by request method or response code."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "start date"
            }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "end date"
            }), " dropdown menus to narrow your call logs to a specific time range."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["You can also search for specific calls by URL in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Search by request URL"
            }), " search box."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/private-app-logs-tab-updated.png",
        alt: "private-app-logs-tab-updated"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To export the API call logs, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Export logs (CSV)"
        }), ". Then, specify a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "date range"
        }), " (up to the past 30 days) and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Export"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the pop-up box, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "date range"
        }), " of API calls you want to export and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Export"
        }), ". You will receive an email with a download link when the export is ready."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Private app limits"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can create up to 20 private apps in your HubSpot account. Each private app is subject to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "HubSpot's API usage guidelines"
      }), ". The number of calls your private app can make is based on your account subscription and whether you've purchased the API add-on:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {}), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Product Tier"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Per 10 Seconds"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Per Day"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Private Apps"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Any Hub)Free and Starter"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "100 / private app"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "250,000 / account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Any Hub)Professional"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "190 / private app"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "625,000 / account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Any Hub)Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "190 / private app"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1,000,000 / account"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Private Apps with ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://legal.hubspot.com/hubspot-product-and-services-catalog#Addons",
              children: "API Limit Increase"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Any Hub)Free, Starter, Professional, and Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "200 / private app"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1,000,000 / account on top of your base subscription, for each limit increase. You can purchase a maximum of two API limit increase."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you have both a Starter and Professional plan, limits for the higher tier (Professional) apply to your account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/account-info/v3/api-usage/daily/private-apps"
      }), " to review the daily API usage for all private apps in your HubSpot account. Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/account-information-api",
        children: "account information API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " purchasing an API Limit Increase will increase the maximum number of requests you can make to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4",
          children: "associations API"
        }), " to 1,000,000 daily requests and 200 requests per 10 seconds, but these limits ", (0, _jsxRuntime.jsx)("u", {
          children: "cannot"
        }), " be increased further with an additional API Limit Increase purchase."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete a private app"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When you delete one of your private apps, its access token will be permanently revoked and you'll no longer be able to use it to make API calls."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To delete an app:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of your private app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["At the top of the page, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Auth"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["At the bottom of the page, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Delete [name of your app]"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, type the name of your app to confirm its deletion, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Delete"
        }), "."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}