"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694136;
const slug = exports.slug = 'guides/cms/content/forms';
const title = exports.title = 'HubSpotフォーム';
const name = exports.name = 'HubSpotフォーム';
const metaDescription = exports.metaDescription = 'モジュール、HubL、または埋め込みコードを使用して、HubSpotのウェブサイトまたは外部ページにフォームを追加する方法について説明します。次に、それらにスタイルを設定する方法を説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "既定のフォームモジュール",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E8%A4%87%E8%A3%BD%E3%81%99%E3%82%8B",
  "label": "既定のモジュールを複製する",
  "parent": "%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB"
}, {
  "depth": 0,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89",
  "label": "カスタムモジュールのフォームフィールド",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%A8%E3%83%87%E3%82%A3%E3%82%BF%E3%83%BC%E3%81%A7%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%82%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%92%E5%88%B6%E9%99%90%E3%81%99%E3%82%8B",
  "label": "エディターでフォームオプションを制限する",
  "parent": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89"
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E5%9F%8B%E3%82%81%E8%BE%BC%E3%81%BF%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "フォーム埋め込みコードを使用する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E5%9B%BD%E9%9A%9B%E5%8C%96%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "国際化を追加する",
  "parent": "%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E5%9F%8B%E3%82%81%E8%BE%BC%E3%81%BF%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88",
  "label": "フォームイベント",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%81%AE%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E8%A8%AD%E5%AE%9A",
  "label": "フォームのスタイル設定",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AE%9A%E3%81%AE%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%BE%E3%81%9F%E3%81%AFhubl%E3%82%BF%E3%82%B0%E3%82%92%E4%BD%BF%E3%81%A3%E3%81%A6%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%81%AE%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "既定のフォームモジュールまたはHubLタグを使ってフォームのスタイルを設定する",
  "parent": "%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%81%AE%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%BF%E3%81%A3%E3%81%A6%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%81%AE%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "カスタムモジュールを使ってフォームのスタイルを設定する",
  "parent": "%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%81%AE%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 1,
  "id": "%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E5%9F%8B%E3%82%81%E8%BE%BC%E3%81%BF%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E4%BD%BF%E3%81%A3%E3%81%A6%E8%BF%BD%E5%8A%A0%E3%81%97%E3%81%9F%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%81%AE%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "フォーム埋め込みコードを使って追加したフォームのスタイルを設定する",
  "parent": "%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%81%AE%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E8%A8%AD%E5%AE%9A"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      strong: "strong",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpotフォーム"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotフォームを使用して、HubSpot全体でアクセスできる情報をウェブサイト訪問者から取得します。フォームへのリンクをユーザーと直接共有し、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/forms",
        children: "APIを介してフォームデータを送信"
      }), "して、CMSを使用してウェブサイトページに埋め込むことができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "フォームはHubSpotの中核となる要素であり、どのサブスクリプションレベルのHubSpotアカウントでも作成できます。フォームは顧客のコンバージョンにとって重要であるだけでなく、フォームデータはスマートコンテンツ、リスト、ワークフロー、コンテンツのパーソナライズなど、他のHubSpotツールやアセットでも使用できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms",
        children: "作成したHubSpotフォーム"
      }), "は、テンプレートやページに追加できます。ユースケースに応じて、フォームをテンプレートに追加する方法はいくつかあります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#the-default-form-module",
          children: "既定のフォームモジュールを使用する"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#form-fields-in-custom-modules",
          children: "カスタムモジュールにフォームフィールドを追加する"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-the-form-hubl-tag"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-the-form-embed-code",
          children: "フォームの埋め込みコードを使用して埋め込む"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "既定のフォームモジュール"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テンプレートに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "ドラッグ＆ドロップエリア"
      }), "がある場合、コンテンツクリエイターはページエディターから", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#form",
        children: "既定のフォームモジュール"
      }), "をページに追加し、左側のサイドバーでフォームオプションを設定できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["フォームモジュールをドラッグ＆ドロップエリアを持つテンプレートに直接コード化するには、フォームモジュールを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "として参照します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"dnd_area\" class='body-container body-container__landing', label='Main section' %}\n {% dnd_section vertical_alignment='MIDDLE' %}\n  {% dnd_column width=6, offset=6 %}\n   {% dnd_row %}\n\n    <!-- Form module tag for use in templates -->\n    {% dnd_module path='@hubspot/form' %}\n    {% end_dnd_module %}\n\n   {% end_dnd_row %}\n  {% end_dnd_column %}\n {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テンプレートのドラッグ＆ドロップエリアの外側にフォームを追加するには、代わりに標準の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), "として参照します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"form\"\n path=\"@hubspot/form\"\n form={\n  \"form_id\": \"9e633e9f-0634-498e-917c-f01e355e83c6\",\n  \"response_type\": \"redirect\",\n  \"message\": \"Thanks for submitting the form.\",\n  \"redirect_id\": null,\n  \"redirect_url\": \"http://www.google.com\"\n }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["いずれの実装でも、上記のコード例に示すように、モジュールタグにパラメーターを追加して、使用するフォームやリダイレクトオプションなどの設定を指定できます。利用可能なパラメーターの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#form",
        children: "既定のモジュールのドキュメント"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既定のモジュールを複製する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既定のモジュールをそのまま使用することに加えて、複製して編集可能にすることで、必要に応じてカスタマイズすることができます。例えば、既定のフォームモジュールを複製し、色フィールドを追加してから、モジュールのHTMLをスタイル付きの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section>"
      }), "タグでラップして、背景として色を追加できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["左側のサイドバーのデザインマネージャーで、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "@hubspot"
        }), "フォルダーに移動し、［form.module］を右クリックして、［モジュールを複製］を選択します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/clone-form-module.png",
        alt: "clone-form-module"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右側のサイドバーで、［フィールドの追加］をクリックして［カラー］を選択します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HTMLコンテンツの周りに", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<section>"
        }), "タグを追加し、次のように色フィールドを参照するスタイルを含めます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "<section style=\"background:{{ module.color_field.color }}\">"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/default-form-module-clone-section.png",
        alt: "default-form-module-clone-section"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "カスタムモジュールのフォームフィールド"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタムモジュールを作成するときは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#form",
        children: "フォームフィールド"
      }), "を追加し、そのフィールドのコードスニペットをモジュールHTMLに追加することで、フォームを含めることができます。例えば、製品の画像と価値ある提案の説明を含むモジュールに相談依頼フォームを追加することができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャーからカスタムモジュールにフォームフィールドを追加するには、以下の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "モジュールエディターの右側のサイドバーで、［フィールドの追加］をクリックし、［フォーム］を選択します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/design-manager-select-form-field.png",
        alt: "design-manager-select-form-field"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "フィールドを追加したら、右側のサイドバーのフィールドにカーソルを合わせ、［アクション］をクリックして［スニペットをコピー］****を選択します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-field-copy-snippet.png",
        alt: "module-field-copy-snippet"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "スニペットをモジュールのHTMLフィールドに貼り付けます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-field-paste-snippet.png",
        alt: "module-field-paste-snippet"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "エディターでフォームオプションを制限する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページに追加されると、コンテンツ作成者は通常、使用するフォームやフォームフィールド自体など、フォームのさまざまな特徴を制御できます。ただし、フォームモジュールの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイルを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "ローカル"
      }), "に変更して次のフィールドを含めることで、ページエディターで指定される制御の量を制限できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disable_inline_form_editing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["フォームモジュール内の全てのインラインフォーム編集コントロールを非表示にするには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "disable_inline_form_editing"
            }), "プロパティーを", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定します。これには、フォームフィールド、送信ボタンのテキスト、データのプライバシーと同意のオプション、CAPTCHAが含まれます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "required_property_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["フォームフィールドのプロパティータイプに基づいて選択できるフォームを指定する配列。値には、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"CONTACT\""
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"COMPANY\""
            }), "、および", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"TICKET\""
            }), "を使用できます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、訪問者が会社のさまざまなサービス部門に連絡できるフォームのみに使用するモジュールを構築した場合、コンテンツ作成者がチケットプロパティーを使用するフォームのみを選択できるようにすることができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Form field\n{\n  \"id\": \"843b4f0f-0ed7-5b10-e86a-5cc8a0f11a0c\",\n  \"name\": \"form_field_1\",\n  \"display_width\": null,\n  \"label\": \"Form\",\n  \"required\": false,\n  \"locked\": false,\n  \"type\": \"form\",\n  \"disable_inline_form_editing\": true,\n  \"required_property_types\": [\"TICKET\"],\n  \"default\": {\n    \"response_type\": \"inline\",\n    \"message\": \"Thanks for submitting the form.\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フォーム埋め込みコードを使用する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["モジュールやHubLタグを使用するのが理想的ではない場合や、外部ページにフォームを追加する場合は、代わりに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#add-the-form-embed-code",
        children: "フォーム埋め込みコード"
      }), "を使用してフォームを埋め込むことができます。フォーム埋め込みコードをカスタマイズして、フォームの機能を拡張することもできます。全てのフォーム埋め込みコードのカスタマイズオプションなどの詳細は、フォーム リファレンス ガイドをご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["フォームのカスタマイズオプションは、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#with-css-in-your-external-stylesheet-marketing-hub-professional-enterprise-or-legacy-marketing-hub-basic-only",
            children: "未加工のHTMLとして設定"
          }), "されている、HubSpotで作成されたフォームでのみ使用できます。HubSpotアカウントで、", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            })
          }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Content Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), " 配信登録を使用している必要があります。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubSpotフォームの読み込みには、HubSpotでホスティングされているJavaScriptファイルを使用する必要があります。フォーム埋め込みコードのコピーを作成して、自社サイトでホスティングすることは", (0, _jsxRuntime.jsx)("u", {
            children: "できません"
          }), "。HubSpotフォーム埋め込みコードを自社サイトでホスティングした場合、セキュリティー、スパム対策、アクセシビリティー、パフォーマンスを向上させるためにHubSpotで行った改善がご使用のフォームに反映されません。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下に、カスタマイズを行っていないフォーム埋め込みコードのサンプルを示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script\n  charset=\"utf-8\"\n  type=\"text/javascript\"\n  src=\"//js.hsforms.net/forms/embed/v2.js\"\n></script>\n<script>\n  hbspt.forms.create({\n    region: 'na1',\n    portalId: '123456',\n    formId: 'df93f0c1-2919-44ef-9446-6a29f9a7f',\n  });\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "必須"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値または文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォーム多作成されたHubSpotアカウントのID。これは、フォーム定義を取得するために使用されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "formId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "必須"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォームのID。フォーム定義を取得するために使用されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "region"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["フォームが作成されたアカウントの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-cloud-infrastructure-and-data-hosting-frequently-asked-questions",
              children: "地域"
            }), "。これは、フォーム定義を取得するために使用されます。指定可能な値は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "na1"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eu1"
            }), "です。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "国際化を追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでは既定で、さまざまな言語で検証メッセージが表示されます。言語は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "locale"
      }), "パラメーターを使用して指定できます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "translation"
      }), "パラメーターを使用して、カスタム言語を追加したり、フォームに表示されるエラーメッセージや日付入力の月や日を上書きしたりすることもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["国際化とフォーム検証のエラーメッセージに関する詳細は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/forms",
        children: "フォーム リファレンス ドキュメント"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hbspt.forms.create({\n  portalId: '',\n  formId: '',\n  locale: 'en',\n  translations: {\n    en: {\n      fieldLabels: {\n        email: 'Electronic mail',\n      },\n      required: \"Hey! That's required!\",\n      submitText: 'Custom Submit Button Text',\n      submissionErrors: {\n        MISSING_REQUIRED_FIELDS: 'Please fill in all required fields!',\n      },\n    },\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フォームイベント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["フォームでは、HubSpotフォーム埋め込みコードのコールバックとグローバル フォーム イベントの2つの方法で機能をイベントにバインドできます。利用可能なフォーム埋め込みコードのコールバックとグローバル フォーム イベントの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/forms",
        children: "フォーム リファレンス ドキュメント"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example form embed code callback\nhbspt.forms.create({\n  portalId: '',\n  formId: '',\n  onBeforeFormSubmit: function ($form) {\n    // YOUR SCRIPT HERE\n  },\n});\n\n// Example listener\nwindow.addEventListener('message', (event) => {\n  if (\n    event.data.type === 'hsFormCallback' &&\n    event.data.eventName === 'onFormSubmitted'\n  ) {\n    someAnalyticsLib('formSubmitted');\n    console.log('Form Submitted! Event data: ${event.data}');\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フォームのスタイル設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotには", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/set-global-form-colors-and-fonts",
        children: "グローバル設定のフォームスタイル"
      }), "と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms#style-and-preview-your-form",
        children: "フォーム固有の設定"
      }), "がありますが、フォームをどのようにCMSページに追加するかに応じてフォームのスタイルを設定することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注意：**CMS Hub上に生成された全てのフォーム（フォーム埋め込みコードを使用した場合を除く）では、グローバルフォーム設定またはフォーム固有の設定に指定された全てのスタイルが無視されます。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既定のフォームモジュールまたはHubLタグを使ってフォームのスタイルを設定する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotページに追加されたHubSpotフォームのスタイルを設定するには、ウェブサイトのCSSを使用します。HubSpotには、生成されるフォームにさまざまなクラスと属性が用意されていて、スタイルを適用できます。出発点として、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/elements/_forms.css",
        children: "HubSpotボイラープレートのフォームCSS"
      }), "を参照してください。フォームのスタイル設定に関するベストプラクティスが示されています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カスタムモジュールを使ってフォームのスタイルを設定する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カスタムモジュール内のフォームのスタイルを設定するには、デザインマネージャー内のモジュールのCSSペインでCSSを使用します。CSSをモジュールに適用したままにすることで、モジュールがページに追加された場合には必ず、スタイル設定も伴うようになります。フォームにラッパーを追加し、そのラッパーをCSSの最上位のセレクターとして使用することをお勧めします。その結果、ウェブサイトのメインのスタイルシートに定義されている追加のスタイルによって、カスタムモジュールのフォームスタイルが上書きされることを防止できます。以下に、上記のカスタムモジュールのスクリーンショットを示します。フォームスタイル設定がCSSペインに追加されています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/form-module-css-pane-4.jpg",
        alt: "CSSを使用したカスタムモジュール"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "フォーム埋め込みコードを使って追加したフォームのスタイルを設定する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["フォーム埋め込みコードを使用する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/set-global-form-colors-and-fonts",
        children: "グローバル フォーム スタイル設定"
      }), "またはウェブサイトのCSSを使用してフォームのスタイルを設定できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["グローバル フォーム スタイル設定を使用すると、アカウント内の全てのフォームの既定の設定を構成できます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/create-forms#style-and-preview-your-form",
        children: "フォームエディター内の個別のフォームに対し、これらのスタイルを上書き"
      }), "することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/global-form-styling-options-4.png",
        alt: "グローバル フォーム スタイル"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        })
      }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Professional"
      }), " または ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " 配信登録をご利用の場合、フォーム作成時に［未加工のHTMLフォームとして設定］オプションを選択できます。この設定により、フォームはiframeではなくHTMLとして表示されるため、CSSを使用して埋め込みフォームのスタイルを設定するのが簡単になります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site",
        children: "ナレッジベースで埋め込みフォームのスタイル設定"
      }), "に関する詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/forms/set-as-raw-html-form-4.jpg",
        alt: "未加工のHTMLフォームとして設定する"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}