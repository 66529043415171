"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279886;
const slug = exports.slug = 'guides/cms/content/content-staging';
const title = exports.title = 'Preparación de contenido';
const name = exports.name = 'EMEA ES Content Staging';
const metaDescription = exports.metaDescription = 'Preparación de contenido es un entorno de desarrollo integrado en la aplicación que te permite rediseñar o crear páginas preparadas antes de publicarlas en tu sitio de producción.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Preparación de contenido"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Preparación de contenido es un entorno de contenido independiente en la aplicación que te permite actualizar o crear páginas preparadas antes de publicarlas en tu sitio de producción."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La preparación de contenido se puede usar durante un proyecto de rediseño de sitios web para preparar páginas existentes usando una nueva plantilla. Los análisis vinculados a la página se trasladarán a la nueva página en la publicación desde la preparación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dado que los rediseños generalmente implican que se actualice más de una página, puedes organizar todas las páginas que necesites y publicar todas ellas en el sitio activo a la vez."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consulta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cms-general/redesign-and-relaunch-your-site-with-content-staging",
        children: "Uso de la preparación de contenido para rediseñar un sitio web"
      }), " para obtener instrucciones detalladas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener un tutorial completo sobre cómo usar Preparación de contenido, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cms-general/redesign-and-relaunch-your-site-with-content-staging",
        children: "echa un vistazo a este artículo de la Guía del usuario del sitio web"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear y probar cambios en los materiales como temas, plantillas y módulos, recomendamos usar una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "cuenta de entorno de pruebas para desarrolladores"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "Más información sobre cómo crear un workflow de desarrollo eficiente."
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}