"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 75327506237;
const slug = exports.slug = 'reference/api/other-resources/error-handling';
const title = exports.title = 'Gestion des erreurs';
const name = exports.name = 'Gestion des erreurs';
const metaDescription = exports.metaDescription = "Découvrez comment gérer les erreurs d'API courantes lors du développement avec les API HubSpot.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "erreurs-%C3%A0-statuts-multiples",
  "label": "Erreurs à statuts multiples",
  "parent": null
}, {
  "depth": 0,
  "id": "nouvelles-tentatives",
  "label": "Nouvelles tentatives",
  "parent": null
}, {
  "depth": 1,
  "id": "webhooks",
  "label": "Webhooks",
  "parent": "nouvelles-tentatives"
}, {
  "depth": 1,
  "id": "actions-de-workflow-%C3%A0-code-personnalis%C3%A9",
  "label": "Actions de workflow à code personnalisé",
  "parent": "nouvelles-tentatives"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      pre: "pre",
      h2: "h2",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Traitement des erreurs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sauf mention contraire, la plupart des points de terminaison de HubSpot renverront une réponse OK ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " en cas de réussite. Tous les points de terminaison qui renvoient un code de statut différent indiqueront la réponse renvoyée dans leurs documentations respectives."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En outre, HubSpot propose plusieurs réponses d'erreur communes à plusieurs API :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "207 Multi-Status"
        }), " : renvoyée lorsqu'il existe différents statuts (par exemple : erreurs et réussites), ce qui se produit lorsque vous avez activé ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#multi-status-errors",
          children: "la gestion des erreurs à statuts multiples"
        }), " pour les points de terminaison de création par lots d'API d'objets."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "401 Unauthorized"
        }), " : renvoyée lorsque l'authentification est non valide. Consultez notre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "présentation de l'authentification"
        }), " pour plus de détails sur l'authentification des demandes d'API."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "403 Forbidden"
        }), " : renvoyée lorsque l'authentification fournie ne possède pas les autorisations nécessaires pour accéder à l'URL spécifique. Par exemple, un jeton OAuth qui dispose uniquement d'un accès au contenu recevra une réponse ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " lors de l'accès à l'API de transactions (qui nécessite l'accès aux contacts). Si vous avez confirmé que votre clé API ou votre application privée dispose des autorisations nécessaires, veuillez contacter le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "support HubSpot"
        }), " pour obtenir de l'aide."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "429 Too many requests"
        }), " : renvoyée lorsque votre compte ou application dépasse ses ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details",
          children: "limites"
        }), " quant aux API. Pour en savoir plus sur ces limites, cliquez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/working-within-the-hubspot-api-rate-limits",
          children: "ici"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "477 Migration in Progress"
        }), " : renvoyée lorsqu'un compte HubSpot est en cours de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/eu-data-centre",
          children: "migration entre différents sites d'hébergement de données"
        }), ". HubSpot renverra un en-tête de réponse ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Retry-After",
          children: "Retry-After"
        }), " indiquant le nombre de secondes à attendre avant de retenter la demande (généralement jusqu'à 24 heures)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "502/504 timeouts"
        }), " : renvoyée lorsque les limites de traitement de HubSpot ont été atteintes. Ces limites de traitement existent afin d'empêcher une dégradation des performances pour un client. Les réponses d'expiration se produisent lorsque vous effectuez un grand nombre de demandes sur une période prolongée. Si vous obtenez l'une de ces réponses, vous devez suspendre vos demandes pendant quelques secondes, puis réessayer."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "503 service temporarily unavailable"
        }), " : renvoyée lorsque HubSpot est temporairement indisponible. Si vous obtenez cette réponse, vous devez suspendre vos demandes pendant quelques secondes, puis réessayer."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "521 web server is down"
        }), " : renvoyée lorsque le serveur de HubSpot est en panne, cela devrait être un problème temporaire. Si vous obtenez cette réponse, vous devez suspendre vos demandes pendant quelques secondes, puis réessayer."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "522 connnection timed out"
        }), " : renvoyée lorsque la connexion entre HubSpot et votre application a expiré. Si vous avez reçu cette réponse, veuillez contacter le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "support HubSpot"
        }), " pour obtenir de l'aide."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "523 origin is unreachable"
        }), " : renvoyée lorsque HubSpot n'est pas en mesure de contacter votre application. Si vous obtenez cette réponse, vous devez suspendre vos demandes pendant quelques secondes, puis réessayer."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "524 timeout"
        }), " : renvoyée lorsqu'une réponse n'est pas reçue dans les 100 secondes. Cela peut se produire lorsque le serveur du HubSpot est surchargé, par exemple avec une requête de données volumineuse. Si vous obtenez cette réponse, vous devez suspendre vos demandes pendant quelques secondes, puis réessayer."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "525/526 SSL issues"
        }), " : renvoyée lorsque le certificat SSL n'est pas valide ou que la liaison SSL échoue. Si vous avez reçu cette réponse, veuillez contacter le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "support HubSpot"
        }), " pour obtenir de l'aide."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Outre ces erreurs générales, les réponses d'erreur de HubSpot sont conçues pour être concises et lisibles. La plupart des points de terminaison ne renvoient pas les codes d'erreur, mais une réponse JSON avec des détails sur l'erreur. Plus de détails concernant les erreurs spécifiques à chaque point de terminaison sont disponibles sur les pages de documentation des points de terminaison."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " les champs dans l'exemple de réponse ci-dessous doivent être traités comme facultatifs dans n'importe quelle analyse d'erreur. Les champs spécifiques inclus peuvent varier selon les API. Toute analyse d'erreur doit permettre l'absence de champs spécifiques de la réponse."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Structure of an example error from HubSpot\n{\n  \"status\": \"error\",\n  \"message\": \"This will be a human readable message with details about the error.\",\n  \"errors\": [\n    {\n      \"message\": \"This will be a message with additional details about the error\",\n      \"in\": \"name\"\n    }\n  ],\n  \"category\": \"VALIDATION_ERROR\",\n  \"correlationId\": \"a43683b0-5717-4ceb-80b4-104d02915d8c\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erreurs à statuts multiples"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les points de terminaison de création par lots d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "API d'objets'"
      }), ", vous pouvez activer des réponses à statuts multiples qui incluent des échecs partiels. Cela signifie que la réponse indiquera quelles fiches d'informations ont été créées et lesquelles ne l'ont pas été. Pour ce faire, incluez une valeur unique ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectWriteTraceId"
      }), " pour chaque entrée dans votre demande. Il peut s'agir de n'importe quelle chaîne unique pour ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectWriteTraceId"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, une demande de création de tickets peut ressembler à ceci :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request to POST crm/v3/objects/tickets/batch/create\n{\n  \"inputs\": [\n    {\n      \"properties\": {\n        \"objectWriteTraceId\": \"549b1c2a9350\",\n        \"hs_pipeline_stage\": \"1\"\n      },\n      \"properties\": {\n        \"objectWriteTraceId\": \"549b1c2a9351\",\n        \"missing\": \"1\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans la réponse, les statuts sont regroupés afin que vous puissiez voir quelles créations ont réussi et lesquelles ont échoué. Pour la demande ci-dessus, votre réponse ressemblerait à ceci :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"id\": \"1145814089\",\n      \"properties\": {\n        \"createdate\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_helpdesk_sort_timestamp\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_last_message_from_visitor\": \"false\",\n        \"hs_lastmodifieddate\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_object_id\": \"1145814089\",\n        \"hs_object_source\": \"API\",\n        \"hs_object_source_label\": \"INTERNAL_PROCESSING\",\n        \"hs_pipeline\": \"0\",\n        \"hs_pipeline_stage\": \"1\",\n        \"hs_ticket_id\": \"1145814089\"\n      },\n      \"createdAt\": \"2024-08-15T17:09:13.648Z\",\n      \"updatedAt\": \"2024-08-15T17:09:13.648Z\",\n      \"archived\": false\n    }\n  ],\n  \"numErrors\": 1,\n  \"errors\": [\n    {\n      \"status\": \"error\",\n      \"category\": \"VALIDATION_ERROR\",\n      \"message\": \"Property values were not valid: [{\\\"isValid\\\":false,\\\"message\\\":\\\"Property \\\\\\\"missing\\\\\\\" does not exist\\\",\\\"error\\\":\\\"PROPERTY_DOESNT_EXIST\\\",\\\"name\\\":\\\"missing\\\",\\\"localizedErrorMessage\\\":\\\"Property \\\\\\\"missing\\\\\\\" does not exist\\\",\\\"portalId\\\":891936587}]\",\n      \"context\": {\n        \"objectWriteTraceId\": [\"549b1c2a9351\"]\n      }\n    }\n  ],\n  \"startedAt\": \"2024-08-15T17:09:13.610Z\",\n  \"completedAt\": \"2024-08-15T17:09:13.910Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Nouvelles tentatives"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si votre application ou intégration fournit un point de terminaison que HubSpot appellera, comme des abonnements webhook, toute erreur que votre point de terminaison lance amènera HubSpot à réessayer la requête."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhooks"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si votre service rencontre des problèmes de traitement des notifications, HubSpot tentera d'envoyer à nouveau les notifications jusqu'à 10 fois."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot procèdera à de nouvelles tentatives dans les cas suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "La connexion a échoué :"
        }), " HubSpot ne parvient pas à ouvrir une connexion HTTP de l'URL de webhook fournie."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Expiration :"
        }), " votre service prend plus de 5 secondes pour renvoyer une réponse à un lot de notifications."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Codes d'erreur :"
        }), " votre service répond avec un code de statut HTTP (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4xx"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "5xx"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les workflows ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "ne seront pas"
      }), " réexécutés après la réception de codes de statut de réponse 4XX. Une exception à cette règle est l'erreur 429 de limite de débit ; les workflows seront automatiquement réexécutés après la réception d'une réponse 429 et respecteront l’en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Retry-After"
      }), ", le cas échéant. Notez que la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Retry-After"
      }), " est exprimée en millisecondes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les notifications seront renvoyées jusqu'à 10 fois. Ces tentatives seront réparties sur les 24 heures suivantes, avec des délais différents entre les demandes. Afin d'éviter la récupération au même moment d'un grand nombre d'échecs concomitants, les notifications individuelles feront l'objet d'une distribution au hasard."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Actions de workflow à code personnalisé"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous créez une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/custom-code-actions",
        children: "action de code personnalisé"
      }), " dans un workflow et si un appel d'API échoue en raison d'une erreur de limite ou d'une erreur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "5XX"
      }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "axios"
      }), " ou de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/api-client"
      }), ", HubSpot tentera à nouveau d'exécuter votre action pendant trois jours maximum, en commençant une minute après l'échec. Les tentatives après les échecs ultérieurs seront effectuées à intervalles croissants, avec un écart maximum de huit heures entre les tentatives."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}