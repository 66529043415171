"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 43177444197;
const slug = exports.slug = 'guides/api/app-management/oauth/tokens';
const title = exports.title = 'OAuth | トークンの管理';
const name = exports.name = 'APAC JAPAN (ja) | vNext Docs DP - OAuth Tokens_JA | 202007';
const metaDescription = exports.metaDescription = 'HubSpot APIでCRUD操作を確実に安全に実行できるようにするには、OAuthのアクセストークンとリフレッシュトークンを取得します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "oauth-2.0%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%A8%E3%83%AA%E3%83%95%E3%83%AC%E3%83%83%E3%82%B7%E3%83%A5%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B%EF%BC%9A",
  "label": "OAuth 2.0のアクセストークンとリフレッシュトークンを取得する：",
  "parent": null
}, {
  "depth": 0,
  "id": "oauth-2.0%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%BE%E3%81%9F%E3%81%AF%E3%83%AA%E3%83%95%E3%83%AC%E3%83%83%E3%82%B7%E3%83%A5%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E6%83%85%E5%A0%B1%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B%EF%BC%9A",
  "label": "OAuth 2.0のアクセストークンまたはリフレッシュトークンに関する情報を取得する：",
  "parent": null
}, {
  "depth": 0,
  "id": "oauth-2.0%E3%83%AA%E3%83%95%E3%83%AC%E3%83%83%E3%82%B7%E3%83%A5%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B%EF%BC%9A",
  "label": "OAuth 2.0リフレッシュトークンを削除する：",
  "parent": null
}, {
  "depth": 0,
  "id": "oauth-2.0%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E4%BD%BF%E7%94%A8%EF%BC%9A",
  "label": "OAuth 2.0アクセストークンの使用：",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 10,
        children: [(0, _jsxRuntime.jsx)(_components.h1, {
          children: "トークンの管理"
        }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth 2.0のアクセストークンとリフレッシュトークンを取得する："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アクセストークンとリフレッシュトークンを取得するには、ユーザーによるアプリ認証後に取得できるコードを使用します。アクセストークンは、アプリが発行するリクエストの認証に使用します。アクセストークンの利用は短時間です。利用可能な秒数は、アクセストークンを生成したときに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), "パラメーターで確認できます。最初のアクセストークンの有効期限が切れるときにリフレッシュ トークン エンドポイントを使用して、最新のアクセストークンを取得できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "新しいアクセストークンの生成には、以前に取得済みのリフレッシュトークンを使用します。HubSpotデータへのオフラインアクセスが必要な場合は、OAuth連携の初期化時に取得したリフレッシュトークンを保存しておき、最初のアクセストークンの有効期限が切れた時点で新しいアクセストークンを生成する際に使用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "注"
      }), "：HubSpotのアクセストークンのサイズは、エンコードされる情報量の変化に応じて変動します。トークンのサイズには余裕を持って、最大300文字分確保することをお勧めします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth 2.0のアクセストークンまたはリフレッシュトークンに関する情報を取得する："
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アクセストークンまたはリフレッシュトークンのメタデータを取得します。トークンが作成されたHubSpotユーザーのEメールアドレスと、トークンが関連付けられているHub IDを取得する際に使用します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth 2.0リフレッシュトークンを削除する："
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リフレッシュトークンを削除します。特定のユーザーがアプリをアンインストールする場合のリフレッシュトークンの削除に使用します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**注：**この処理で削除されるのはリフレッシュトークンのみです。リフレッシュトークンを利用して生成されたアクセストークンは影響を受けません。さらに、HubSpotアカウント上からアプリがアンインストールされるわけではありません。また、アカウントと接続アプリの間のデータ同期も停止されません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth 2.0アクセストークンの使用："
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "// Authorization:Bearer {token}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "curl --request GET \\"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "--url 'https://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false' \\"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "--header 'authorization:Bearer CJSP5qf1KhICAQEYs-gDIIGOBii1hQIyGQAf3xBKmlwHjX7OIpuIFEavB2-qYAGQsF4'"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{contacts: .....}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この例では、次の部分がアクセストークンに該当します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "CJSP5qf1KhICAQEYs-gDIIGOBii1hQIyGQAf3xBKmlwHjX7OIpuIFEavB2-qYAGQsF4"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["注：", (0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["OAuth 2.0アクセストークンを使用する場合は、リクエストURLに", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hapikey="
          }), "を"]
        }), "含めない"]
      }), "でください。このクエリーパラメーターの代わりとして、認証ヘッダーを使用します。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}