"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694158;
const slug = exports.slug = 'guides/cms/content/templates/types/html-hubl-templates';
const title = exports.title = 'HTML + HubL-Vorlagen';
const name = exports.name = 'HTML- + HubL-Vorlagen';
const metaDescription = exports.metaDescription = 'CMS Hub-Vorlagen sind in HTML und HubL geschrieben. Diese Vorlagen können Bereiche enthalten, die von den Content-Autoren leicht selbst bearbeitet werden können.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/image1-2.png';
const featuredImageAltText = exports.featuredImageAltText = 'Screenshot einer einfachen HTML + HubL-Vorlage';
const toc = exports.toc = [{
  "depth": 0,
  "id": "vertrautheit-und-tools",
  "label": "Vertrautheit und Tools",
  "parent": null
}, {
  "depth": 0,
  "id": "vorlagenannotationen",
  "label": "Vorlagenannotationen",
  "parent": null
}, {
  "depth": 0,
  "id": "header--und-footer-incudes",
  "label": "Header- und Footer-Incudes",
  "parent": null
}, {
  "depth": 0,
  "id": "teilvorlagen",
  "label": "Teilvorlagen",
  "parent": null
}, {
  "depth": 1,
  "id": "standardteilvorlagen",
  "label": "Standardteilvorlagen",
  "parent": "teilvorlagen"
}, {
  "depth": 1,
  "id": "globale-teilvorlagen",
  "label": "Globale Teilvorlagen",
  "parent": "teilvorlagen"
}, {
  "depth": 1,
  "id": "bl%C3%B6cke-und-erweiterungen",
  "label": "Blöcke und Erweiterungen",
  "parent": "teilvorlagen"
}, {
  "depth": 1,
  "id": "globale-gruppen",
  "label": "Globale Gruppen",
  "parent": "teilvorlagen"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      HubspotVideoPlayer,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HTML- und HubL-Vorlagen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["HTML + HubL-Vorlagen können für ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/templates/overview#template-types",
            children: "jede Art von Vorlage"
          }), " in CMS Hub verwendet werden. Diese Vorlagen sind .html-Dateien, die die ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/cms/hubl/overview",
            children: "HubL-Vorlagensprache"
          }), " unterstützen. Da diese Code-Vorlagen HubL unterstützen, empfiehlt sich für das Anzeigen einer Vorschau die Verwendung der", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/review-your-hubspot-template-setup",
            children: "Vorlagenvorschau im Design-Manager"
          }), " oder das Anzeigen von Seiten in einem Sandbox-Account. HTML + HubL-Vorlagen können ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#partials",
            children: "Teilvorlagen"
          }), " enthalten, mit denen häufig verwendete Code-Abschnitte, wie Header und Footer, voneinander getrennt werden können."]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172844649367"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HTML + HubL-Vorlagen bieten Entwicklern eine größere Kontrolle als ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "Drag-&-Drop-Vorlagen eines visuellen Design-Managers"
      }), ". Entwickler wiederum können Content-Autoren durch die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "Drag-&-Drop-Funktionalität"
      }), ", die nur mit HTML + HubL-Vorlagen möglich ist, ein besseres Erlebnis bieten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image1-2.png",
        alt: "image1-2"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Die obige Vorlage ist die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/layouts/base.html",
          children: "base.html-Vorlage"
        }), ", die in der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "HubSpot CMS Boilerplate"
        }), " enthalten ist, die eine großartige Möglichkeit ist, mit der Entwicklung mit HubSpot zu beginnen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vertrautheit und Tools"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Da es sich bei den HTML + HubL-Vorlagen um Code-Dateien handelt, können Sie diese mithilfe Ihrer bevorzugten Tools lokal bearbeiten. Es wird empfohlen, die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "Tools für lokale Entwicklung"
      }), " von HubSpot zu verwenden, damit Sie Dateien ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "hochladen"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "abrufen"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "überwachen"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#create",
        children: "erstellen"
      }), " und anderweitig sicher im Entwickler-Dateisystem sowie im Datei-Manager verwalten können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Erstellen von HTML + HubL-Vorlagen mit HubSpot ähnelt der Verwendung einer anderen Vorlagensprache, die Sie möglicherweise bereits verwendet haben. Der Hauptunterschied besteht darin, dass HubSpot seine ganz eigenen Vorstellungen hat, wie man Content-Autoren am besten ein optimales Erlebnis bietet, und dem Entwickler einen Großteil der Wartungs- und Performanceoptimierungsarbeit abnimmt."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie beispielsweise die CSS-Datei für bestimmte Module auf eine Seite laden möchten, sollten Sie, statt ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<link rel=\"stylesheet\" type=\"text/css\" href=\"theme.css\">"
      }), " zu verwenden, das Stylesheet über ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css_assets"
      }), " in die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-css-and-javascript-dependencies",
        children: "meta.json-Datei des Moduls"
      }), " einfügen. Dies ermöglicht HubSpot, das CSS nur dann bedingt zu laden, wenn das Modul auf einer Seite vorhanden ist. Auf diese Weise wird die Menge an unnötigem geladenen CSS minimiert."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "Optimieren Ihres HubSpot-Entwicklungsworkflows"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vorlagenannotationen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vorlagenannotationen, die oben in einer Vorlage enthalten sind, konfigurieren wichtige Vorlageneinstellungen, z. B. den Vorlagentyp und ob er zum Erstellen neuer Inhalte verwendet werden kann. Vorlagenannotationen können während des Entwicklungsprozesses jederzeit geändert werden. Im Folgenden erfahren Sie mehr über verfügbare Vorlagenannotationen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: page\n  isAvailableForNewContent: false\n  enableDomainStylesheets: false\n  label: Homepage\n  screenshotPath: ../images/template-previews/home.png\n-->\n\n<!doctype html>\n\n<html>\n  ...\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Annotation"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "templateType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt an, welcher Vorlagentyp eine Datei ist. Werte sind:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Standardvorlagen:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#page",
                      children: "page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email",
                      children: "email"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog-listing",
                      children: "blog_listing"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog-post",
                      children: "blog_post"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog",
                      children: "blog"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Teilvorlagen: ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
                  children: "global_partial"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["SSystemvorlagen:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#error-pages",
                      children: "error_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#password-prompt",
                      children: "password_prompt_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-login",
                      children: "membership_login_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-register",
                      children: "membership_register_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-password-reset",
                      children: "membership_reset_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-reset-request",
                      children: "membership_reset_request_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-subscription-preferences",
                      children: "email_subscription_preferences_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-backup-unsubscribe",
                      children: "email_backup_unsubscribe_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-subscription-unsubscribe-confirmation",
                      children: "email_subscriptions_confirmation_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#search-results-page",
                      children: "search_results_page"
                    })
                  })]
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isAvailableForNewContent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt an, ob eine Vorlage bei der Erstellung von Inhalten zur Auswahl steht. Werte sind: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ".Auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " festgelegte Vorlagen müssen die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/variables#required-page-template-variables",
              children: "erforderlichen Variablen"
            }), " nicht enthalten. Vorlagen vom Typ ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "page"
            }), ", die auf „false“ festgelegt sind, können ebenfalls als ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#partials",
              children: "Standardteilvorlagen"
            }), " verwendet werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableDomainStylesheets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Gibt an, ob die Vorlage ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/design-manager/create-edit-and-attach-css-files-to-style-your-site#attach-or-remove-stylesheets-on-a-domain-level",
              children: "Domain-Stylesheets"
            }), " laden soll. Die Werte umfassen: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Benutzerfreundliche Beschreibung der Vorlage, die im Bildschirm für die Vorlagenauswahl angezeigt wird. Zum Beispiel: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "About Page"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Homepage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Pricing"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshotPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Screenshot, der angezeigt wird, wenn ein Content-Autor eine Vorlage auswählt. Anhand dieses Screenshots sollten Sie Ihre Vorlagen leicht unterscheiden können."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Header- und Footer-Incudes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei HubSpot-Vorlagen müssen zwei Tags vorhanden sein, es sei denn, die Datei ist eine Teilvorlage. Die beiden Tags sind:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ standard_header_includes }}"
        }), " - Wird verwendet, um auf intelligente Weise kombinierte und minifizierte erforderliche CSS-Dateien hinzuzufügen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ standard_footer_includes }}"
        }), " - Wird verwendet, um JavaScript auf intelligente Weise dynamisch am unteren Rand einer Seite hinzuzufügen, z. B. für das HubSpot-Tracking-Skript und Module."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Tags müssen in einer Vorlage oder ihren untergeordneten ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#partials",
        children: "Teilvorlagen"
      }), " vorhanden sein, damit sie veröffentlicht und verwendet werden können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Teilvorlagen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Teilvorlagen sind HTML + HubL-Dateien, die in andere Code-Dateien eingefügt werden können. Mit Teilvorlagen können Sie einen modulareren Ansatz verfolgen, indem Sie Markups zwischen mehreren Vorlagen teilen. Ein Header kann beispielsweise zu einer Teilvorlage gemacht werden, sodass Sie ihn einfach als Komponente einbinden können, ohne ihn erneut codieren zu müssen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Standardteilvorlagen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eine Standardvorlage ist eine wiederverwendbare Vorlage oder Komponente, die Inhalte enthält, die auf einzelnen Seiten bearbeitet werden können. Dies ermöglicht es Content-Autoren, den Inhalt nach Bedarf zu ändern, im Gegensatz zu globalen Teilvorlagen, die immer Inhalte teilen. Beispielsweise würde der folgende Code das Seitenleistenmodul in eine andere Vorlagendatei ziehen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Standardteilvorlagen müssen die folgenden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "Annotationen"
      }), " oben in der Vorlagendatei enthalten:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateType: page"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "isAvailableForNewContent: false"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"../partial/sidebar.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Globale Teilvorlagen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine globale Teilvorlage ist ein Typ von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content#global-partials",
        children: "globalem Inhalt"
      }), ", der in mehreren Vorlagen verwendet werden kann, wobei der Inhalt in allen Instanzen des Teils gemeinsam genutzt wird. Globale Teilvorlagen werden häufig für Header und Footer verwendet, was Sie beispielsweise im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/partials/header.html",
        children: "Header"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/partials/footer.html",
        children: "Footer"
      }), " der HubSpot CMS Boilerplate sehen können. Diese Teilvorlagen werden dann in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "base.html"
      }), "-Datei mithilfe des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global_partial"
      }), "-Tags aufgerufen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Globale Teilvorlagen müssen die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "Annotation"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: global_partial"
      }), " oben in der Datei enthalten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% global_partial path=\"../partials/header.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blöcke und Erweiterungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei der Erstellung komplexer Vorlagen können Sie unterteilte Blöcke erstellen, die eine übergeordnete Vorlage erweitern."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können beispielsweise eine übergeordnete Vorlage erstellen, die die erforderlichen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), "- und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        })
      }), "-Variablen enthält. Innerhalb dieser Vorlage definieren Sie einen eindeutigen Block unter Verwendung der folgenden Syntax, wobei ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body"
      }), " ein eindeutiger Name ist:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% block body %}\n<!-- Content to display -->\n{% endblock body %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dann können Sie in der untergeordneten Vorlage die übergeordnete Vorlage erweitern und dann mehr Inhalt in den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body"
      }), "-Block einfügen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% extends \"./layouts/base.html\" %}\n{% block body %}\n<h3>Page Content</h3>\n<ul>\n  <li>Bullet 1<li>\n  <li>Bullet 2<li>\n  <li>Bullet 3<li>\n</ul>\n{% endblock %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Methode wird in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/layouts/base.html",
        children: "base.html"
      }), "-Vorlage der CMS Hub Boilerplate verwendet, die dann um die anderen Vorlagen im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/main/src/templates",
        children: "Vorlagen-Ordner"
      }), " erweitert wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Globale Gruppen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
        children: "Globale Gruppen"
      }), ", die mit der Drag-&-Drop-Vorlagendesigner im Design-Manager erstellt wurden, können ebenfalls einbezogen werden. Die Syntax ist unten dargestellt:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"/path/to/global_header.template.json\" %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}