"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79942329359;
const slug = exports.slug = 'guides/cms/content/themes/quotes-themes';
const title = exports.title = 'Primeros pasos desde el tema de cotizaciones de CMS';
const name = exports.name = 'EMEA ES Getting started from the CMS quotes theme';
const metaDescription = exports.metaDescription = 'Crea un tema de cotización personalizado para tu negocio basado en el tema de cotización de CMS proporcionado por HubSpot. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "requisitos-previos",
  "label": "Requisitos previos",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-obtener-el-tema-en-tu-directorio-local",
  "label": "1. Obtener el tema en tu directorio local",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-cargar-y-ver-cambios",
  "label": "2. Cargar y ver cambios",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-abrir-una-vista-preliminar-de-la-plantilla",
  "label": "3. Abrir una vista preliminar de la plantilla",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-hacer-un-cambio-a-nivel-local",
  "label": "4. Hacer un cambio a nivel local",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-cambiar-la-etiqueta-de-la-plantilla",
  "label": "5. Cambiar la etiqueta de la plantilla",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-personalizar-la-plantilla-de-cotizaci%C3%B3n-en-hubspot",
  "label": "6. Personalizar la plantilla de cotización en HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-crea-una-cotizaci%C3%B3n-usando-tu-nueva-plantilla",
  "label": "7. Crea una cotización usando tu nueva plantilla",
  "parent": null
}, {
  "depth": 0,
  "id": "pasos-siguientes",
  "label": "Pasos siguientes",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      code: "code",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Primeros pasos desde el tema de cotizaciones de CMS"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con un tema de cotizaciones de CMS, puedes crear un tema de cotización personalizado para que los representantes de ventas lo usen durante el proceso de compra. Esta guía te guiará a través de la clonación del tema predeterminado de cotizaciones de CMS localmente usando la CLI, la carga del clon a tu cuenta y, luego, los ajustes necesarios. A continuación, crearás una cotización usando la plantilla para ver tu trabajo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Requisitos previos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Debes sentirte seguro escribiendo HTML y CSS."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Debes tener la última versión de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "CLI de HubSpot instalada y configurada para tu portal"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " aunque este tutorial utiliza la CLI de HubSpot, puedes hacer todo esto en HubSpot usando el administrador de diseño si lo prefieres. Para completar este proceso en HubSpot, solo necesitarás clonar el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cms-quotes-theme"
        }), " en la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot"
        }), " en lugar de ejecutar el comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetch."
        }), " mostrado en el paso 1."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Obtener el tema en tu directorio local"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abre tu terminal, navega al directorio al que deseas descargar los archivos. Este será tu directorio de trabajo principal para el resto de este tutorial."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para descargar el tema de cotizaciones predeterminado, ejecuta lo siguiente en tu terminal:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch @hubspot/cms-quotes-theme \"my-quotes-theme\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora deberías ver una carpeta llamada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-quotes-theme"
      }), " en tu sistema de archivos local. Esta carpeta contiene todos los activos necesarios para el tema de cotización, incluidos los datos simulados y los valores predeterminados del módulo dentro de la carpeta de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "imports"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Cargar y ver cambios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con la carpeta descargada, cárgala en HubSpot. Si bien puedes usar el comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/developer-reference/local-development-cli#upload",
        children: "hs upload"
      }), " para realizar una sola subida, puedes usar el comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " para activar subidas automáticas en cada archivo guardar:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch \"my-quotes-theme\" \"my-quotes-theme\" --initial-upload\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de cargar, ahora puedes ver la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-quotes-theme"
      }), " en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/design-manager/",
        children: "administrador de diseño"
      }), ". Para abrir el administrador de diseño desde la terminal, abre una nueva pestaña o ventana de terminal y ejecuta el comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs open dm"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Se necesita una nueva pestaña o ventana de terminal porque no se pueden ejecutar comandos mientras se está ejecutando el proceso ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch"
        }), ". También puedes pulsar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "q"
        }), " para finalizar el reloj, ejecutar otro comando y volver a ejecutar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Abrir una vista preliminar de la plantilla"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para obtener una vista preliminar de la plantilla de cotización:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el administrador de diseño, navega a mis cotizaciones: ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > templates > bold.html."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha del editor de código, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vista preliminar"
        }), " y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vista preliminar en directo con opciones de presentación"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con la vista previa de la plantilla abierta, realizarás un cambio localmente, que se cargará automáticamente al guardar debido a que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch"
      }), " se está ejecutando."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Hacer un cambio a nivel local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu editor de códigos local, abre ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > css > bold.css."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Agrega el siguiente código a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "bold.css"
        }), " y luego guarda tus cambios:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".line-items__table tbody tr:nth-child(odd) {\n  background-color: #d6d6d6;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Actualiza la vista previa de la plantilla en tu navegador para ver tus cambios de CSS. Ahora deberías ver que cada fila impar en el cuerpo de la tabla tiene un fondo gris."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A medida que construyes tu tema de cotización personalizada, puedes repetir este workflow para confirmar rápidamente los cambios que estás haciendo localmente."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " debido a la complejidad del sistema de firmas, las firmas no se mostrarán en las vistas previas."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Cambiar la etiqueta de la plantilla"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al preparar un tema de cotizaciones personalizado para uso en la vida real, debes tener en cuenta la etiqueta de la plantilla para que los representantes de ventas puedan encontrarlo fácilmente entre las opciones de cotización predeterminadas de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para cambiar la etiqueta de una plantilla de cotización:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el editor de códigos, abre ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > templates > bold.html"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "En la parte superior del archivo, ve la anotación de la plantilla:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n templateType: quote\n isAvailableForNewContent: true\n label: Bold\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Actualiza el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Bold"
        }), " a un nombre de tu elección, como ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "Mi plantilla de cotización personalizada"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Guarda el archivo para cargarlo en HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Personalizar la plantilla de cotización en HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Antes de que un representante de ventas pueda usar su plantilla de cotización, debe personalizarse en HubSpot. Esto normalmente lo haría un gerente de ventas para que pueda ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/deals/create-custom-quote-templates",
        children: "crear cotizaciones listas para su equipo de ventas"
      }), ". Sin embargo, en este tutorial, recorrerás este proceso tú mismo para que puedas entender cómo es la experiencia de creación de contenido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para personalizar la plantilla de cotización y hacer que esté disponible para los representantes de ventas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, haz clic en elsettings ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ícono de configuración"
        }), " en la barra de navegación principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el menú de la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Objetos > Cotizaciones"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Plantillas de cotización"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Arriba a la derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Personalizar plantilla de cotización"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Coloca el cursor sobre tu nueva ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "plantilla"
        }), " y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Elegir"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usando el panel izquierdo, puedes editar los módulos incluidos en la plantilla. Por ejemplo, puedes hacer clic en un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "módulo"
        }), " para editar sus propiedades o alternar la visibilidad."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/quote-template-toggle-visibility.png",
        alt: "quote-template-toggle-visibility"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Guardar"
        }), " cuando hayas terminado de hacer cambios."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Crea una cotización usando tu nueva plantilla"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con tus cambios guardados, ahora puedes crear una cotización con la plantilla, simulando la experiencia del representante de ventas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ventas > Cotización"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear cotización"
        }), ". Serás redirigido a un asistente de creación de cotizaciones."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la primera pantalla, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Asociar con un negocio"
        }), " y, a continuación, selecciona un negocio existente o selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear un nuevo negocio"
        }), " si quieres utilizar un negocio de prueba."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte inferior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Siguiente"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la pantalla siguiente, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Cotización"
        }), " y luego selecciona tu plantilla de cotización personalizada."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Continúa por el resto del asistente de cotización para crear tu cotización."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Después de publicar la cotización, aparecerá un cuadro de diálogo con un enlace para ver la cotización. Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copiar"
        }), " para copiar la URL y luego pégala en tu navegador para ver la cotización completada."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-quote-url.png",
        alt: "copy-quote-url"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pasos siguientes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con tu plantilla de cotización creada, cargada y personalizada, ahora deberías tener una mejor comprensión del proceso de edición de la plantilla de cotización y la experiencia del creador de contenido."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A medida que creas plantillas de cotizaciones para satisfacer las necesidades de tu negocio, puedes intentar agregar tus propios módulos personalizados a la cotización junto con los módulos predeterminados de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " se recomienda ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " editar el JavaScript de los módulos de pago, firma y descarga, ya que esto podría provocar la rotura de los módulos. Si se rompe, es posible que el usuario final no pueda firmarlo, descargarlo o incluso realizar un pago."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/quotes",
          children: "Personalizar plantillas de cotización"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/quote-variables",
          children: "Referencia de variable de cotización personalizada"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/es/deals/create-custom-quote-templates-beta",
          children: "Crear y usar plantillas de cotización personalizadas (desde la perspectiva de ventas, operaciones de ventas/gerente)"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}