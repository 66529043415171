"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125393;
const slug = exports.slug = 'reference/cms/modules/export-to-template-context';
const title = exports.title = 'export_to_template_context';
const name = exports.name = 'LATAM (ES) export_to_template_context';
const metaDescription = exports.metaDescription = 'export_to_template_context es un parámetro que hace que los parámetros de un módulo estén disponibles para el entorno de la plantilla sin tener que renderizar el módulo. Este parámetro se puede utilizar en todas las etiquetas del módulo HubL. La etiqueta widget_data se utiliza para recuperar estos parámetros, almacenarlos en variables, y/o incorporarlos a la lógica de tu plantilla.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "uso-en-m%C3%B3dulos-personalizados",
  "label": "Uso en módulos personalizados",
  "parent": null
}, {
  "depth": 0,
  "id": "im%C3%A1genes-de-fondo-seleccionables-por-el-usuario",
  "label": "Imágenes de fondo seleccionables por el usuario",
  "parent": null
}, {
  "depth": 0,
  "id": "campo-de-elecci%C3%B3n-para-renderizar-el-marcado-predefinido",
  "label": "Campo de elección para renderizar el marcado predefinido",
  "parent": null
}, {
  "depth": 0,
  "id": "recuperaci%C3%B3n-de-par%C3%A1metros-de-m%C3%B3dulos-ya-renderizados-en-la-plantilla",
  "label": "Recuperación de parámetros de módulos ya renderizados en la plantilla",
  "parent": null
}, {
  "depth": 0,
  "id": "impresi%C3%B3n-de-informaci%C3%B3n-del-m%C3%B3dulo-hubl-en-el-listado-del-blog",
  "label": "Impresión de información del módulo HubL en el listado del blog",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      pre: "pre",
      h2: "h2",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "export_to_template_context"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " es un parámetro que hace que los parámetros de una etiqueta HubL estén disponibles para el entorno de la plantilla sin tener que renderizar la etiqueta HubL. Este parámetro se puede utilizar con todas las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "etiquetas HubL"
      }), ". El diccionario ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " se utiliza para recuperar estos parámetros, almacenarlos en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "variables"
      }), ", y/o incorporarlos a la lógica de tu plantilla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al hacer que los parámetros de una etiqueta HubL estén disponibles en el contexto de la plantilla, sin renderizar realmente la etiqueta HubL, puedes permitir que los usuarios tomen decisiones en el editor de contenido que afecten a cómo se renderiza la plantilla. Por ejemplo, digamos que quieres renderizar un determinado bloque de código solo cuando el usuario da un valor a un campo. Esto es posible con este parámetro."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Primero, debes agregar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context=True"
      }), " a la etiqueta HubL. Luego, debes utilizar un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data.module.parameter_you_want_to_retreive"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"job_title\" path=\"@hubspot/text\", label=\"Enter a Job Title\", value=\"Chief Morale Officer\", export_to_template_context=True %}\n{# Makes the parameters available to be stored and used in template logic #}\n\n{{ widget_data.job_title.body.value }}\n{# Prints the value of the HubL tag but can also be used in template logic #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, se presentan algunas aplicaciones de este concepto."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uso en módulos personalizados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context=True"
      }), " se está admite en los módulos personalizados, ya que no tiene ningún propósito real para ellos. No es necesario utilizar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " para obtener el valor de un módulo dentro de una plantilla, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#retrieving-parameters-from-modules-already-rendered-on-the-template",
        children: "ya puedes acceder a él"
      }), ". Si necesitas ocultar visualmente la salida del módulo, puedes construir el módulo para que no muestre nada, o incluir un campo booleano que active o desactive si el módulo muestra algo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Imágenes de fondo seleccionables por el usuario"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En este ejemplo, se crea una etiqueta HubL imagen pero se exporta al contexto de la plantilla en lugar de renderizarse. El parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " se recupera con la etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " y se representa como la fuente de una imagen de fondo en una etiqueta de estilo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"background_image\" path=\"@hubspot/image\" label='Select a background image',\nsrc='http://cdn2.hubspot.net/hub/428357/file-2117441560-jpg/img/dev-bg-compressed.jpg',\nexport_to_template_context=True %}\n\n\n<!--Sample markup -->\n<div class=\"bg-image-section\" style=\"background:url('{{ widget_data.background_image.src }}'); background-size:cover; min-height: 500px;\">\n    <h1>Supercharge your app with HubSpot</h1>\n    <h3>Build powerful integrations for a global community of users</h3>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aunque esto es posible hacerlo en plantillas codificadas, generalmente es mejor construir un módulo personalizado para darles a los usuarios la mejor experiencia en el editor de páginas. Este tipo de etiquetas de HubL aparecen como campos individuales, mientras que puedes tener varios campos relacionados. Al utilizar un módulo personalizado, todos sus campos se muestran agrupados en el editor de páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campo de elección para renderizar el marcado predefinido"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El siguiente ejemplo utiliza el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " junto con un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#choice",
        children: "módulo de elección"
      }), " para cambiar un mensaje de banner en una página de carreras. El usuario selecciona un departamento a través de la interfaz de usuario y el título cambia sin que el usuario tenga que editar el contenido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"department\" path=\"@hubspot/choice\", label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?!</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta misma funcionalidad se puede reproducir utilizando un campo de elección dentro de un módulo personalizado. La interfaz de usuario del módulo personalizado hace que las opciones de elección con un valor y una etiqueta sean bastante fáciles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperación de parámetros de módulos ya renderizados en la plantilla"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si se deseas recuperar un parámetro de un módulo o etiqueta que ya se está renderizando en una página, puedes acceder al módulo dentro de un diccionario llamado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgets"
      }), ". El parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " no es necesario. La sintaxis es la siguiente:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// HubL\n{{ content.widgets.name_of_module.body.parameter }}\n\n{{ content.widgets.my_text.body.value }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " el método anterior no admite la recuperación de valores de campos en módulos globales, ya que ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content.widgets"
        }), " no accederá a los módulos globales."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Debido a que los diferentes campos almacenan datos en diferentes formatos, a menudo es útil hacer uso de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting#developer-info",
        children: "developer Info"
      }), " para ver cómo se accede a los datos específicos que se quieren mostrar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Impresión de información del módulo HubL en el listado del blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aunque las plantillas de blog se utilizan generalmente para los blogs, también se pueden reutilizar para crear otros tipos de listados. Para ello, puedes utilizar las técnicas descritas anteriormente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, es posible que desees crear un diseño de listado de la prensa que ha recibido su empresa, pero en lugar de enlazar a los posts, quieres que el listado enlace a otra página."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes ver este concepto en acción en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/projects",
        children: "academy.hubspot.com/projects"
      }), ". La página de listado de proyectos es una plantilla de listado de blog, pero cada post enlaza con una página regular de HubSpot. El creador de contenidos especifica el enlace de destino en el editor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dentro del encabezado del código del post de blog individual, se definiría un campo de texto. Si no quieres que el texto se renderice en el post, debes usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"custom_blog_link\" path=\"@hubspot/text\", label=\"Link to external press item\", export_to_template_context=True %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este campo de texto es editable en cada post del blog. A continuación, tendríamos que definir un enlace en nuestro listado. Pero como el widget_data solo existe en el contexto del post, tenemos que utilizar una sintaxis diferente para obtener los datos del widget y rellenar el enlace. En este caso, utilizaríamos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content.widgets.custom_blog_link.body.value"
      }), ". Aunque los ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " no están disponibles para el listado del blog, el valor de ese campo sigue almacenado en el contexto de los widgets del contenido individual."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación se muestra un bucle básico de listado de blogs que muestra este enlace personalizado con cada post. Si utilizas esta técnica, deberás asegurarte de agregar el subdirectorio creado automáticamente para cada post del blog a tu archivo robots.txt para evitar que esos posts vacíos sean rastreados por Google y otros rastreadores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n<a href=\"{{ content.widgets.custom_blog_link.body.value }}\">\n    Click here to see this press feature!\n</a>\n{% endfor %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}