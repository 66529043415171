"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 170224636736;
const slug = exports.slug = 'reference/api/crm/sensitive-data';
const title = exports.title = 'Access and manage Sensitive Data';
const name = exports.name = 'Access and manage Sensitive Data';
const metaDescription = exports.metaDescription = 'Learn how to gain access to and manage Sensitive Data via API.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "sensitive-data-scopes",
  "label": "Sensitive Data scopes",
  "parent": null
}, {
  "depth": 1,
  "id": "sensitive-scopes",
  "label": "Sensitive scopes",
  "parent": "sensitive-data-scopes"
}, {
  "depth": 1,
  "id": "highly-sensitive-scopes-beta",
  "label": "Highly sensitive scopes (BETA)",
  "parent": "sensitive-data-scopes"
}, {
  "depth": 0,
  "id": "turn-on-sensitive-data-access-for-your-app",
  "label": "Turn on Sensitive Data access for your app",
  "parent": null
}, {
  "depth": 1,
  "id": "private-apps",
  "label": "Private apps",
  "parent": "turn-on-sensitive-data-access-for-your-app"
}, {
  "depth": 1,
  "id": "public-apps",
  "label": "Public apps",
  "parent": "turn-on-sensitive-data-access-for-your-app"
}, {
  "depth": 1,
  "id": "request-authorization-from-app-users",
  "label": "Request authorization from app users",
  "parent": "turn-on-sensitive-data-access-for-your-app"
}, {
  "depth": 0,
  "id": "manage-sensitive-data",
  "label": "Manage Sensitive Data",
  "parent": null
}, {
  "depth": 1,
  "id": "retrieve-sensitive-data-properties",
  "label": "Retrieve Sensitive Data properties",
  "parent": "manage-sensitive-data"
}, {
  "depth": 1,
  "id": "create-or-update-sensitive-data-properties",
  "label": "Create or update Sensitive Data properties",
  "parent": "manage-sensitive-data"
}, {
  "depth": 1,
  "id": "retrieve-sensitive-data-property-values",
  "label": "Retrieve Sensitive Data property values",
  "parent": "manage-sensitive-data"
}, {
  "depth": 1,
  "id": "set-or-update-sensitive-data-property-values",
  "label": "Set or update Sensitive Data property values",
  "parent": "manage-sensitive-data"
}, {
  "depth": 1,
  "id": "crm-search-api",
  "label": "CRM Search API",
  "parent": "manage-sensitive-data"
}, {
  "depth": 1,
  "id": "forms-api",
  "label": "Forms API",
  "parent": "manage-sensitive-data"
}, {
  "depth": 1,
  "id": "webhooks-api",
  "label": "Webhooks API",
  "parent": "manage-sensitive-data"
}, {
  "depth": 0,
  "id": "errors",
  "label": "Errors",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      em: "em",
      h2: "h2",
      strong: "strong",
      h3: "h3",
      ul: "ul",
      li: "li",
      code: "code",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Access and manage Sensitive Data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/store-sensitive-data-beta",
        children: "Sensitive Data properties"
      }), ", HubSpot users in ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " accounts can store Sensitive Data and use the data in HubSpot tools. For example, you can create a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Passport Number"
      }), " property and filter contacts in a list based on its values. If you have access to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/properties/highly-sensitive-data",
        children: "Highly Sensitive Data beta,"
      }), " you can also store highly sensitive information, such as social security numbers. Refer to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/sensitive-data-terms",
        children: "Sensitive Data terms"
      }), " page to understand which types of Sensitive Data you can store and which HubSpot tools can access each type of Sensitive Data."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Existing HubSpot APIs and apps will continue to work as expected, but if you have an app that needs access to Sensitive Data, you must update the app’s scopes and require app users to authorize and install the app. Once your app gains access, you can use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manage-sensitive-data",
        children: "certain APIs"
      }), " to manage your Sensitive Data."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sensitive Data scopes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For an app to access Sensitive Data, it must have the sensitive scopes for each applicable object. For example, if your app needs to read and update Sensitive Data on companies and contacts, you’ll need to add the company and contact read and write sensitive scopes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to access Highly Sensitive Data (", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/properties/highly-sensitive-data",
        children: "BETA"
      }), "), you'll need ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#highly-sensitive-scopes-beta",
        children: "additional scopes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": sensitive and highly sensitive scopes are not required to retrieve schema information for a Sensitive Data or Highly Sensitive Data property, but are required to create or edit Sensitive Data or Highly Sensitive data properties and to access the property values."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sensitive scopes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The sensitive scopes are:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.sensitive.read"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.sensitive.write"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.sensitive.read"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.sensitive.write"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.sensitive.read"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.sensitive.write"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.custom.sensitive.read"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.custom.sensitive.write"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "tickets.sensitive"
        }), " (Both read and write access)"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Highly sensitive scopes (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The highly sensitive scopes are:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.highly_sensitive.read"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.highly_sensitive.write"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.highly_sensitive.read"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.highly_sensitive.write"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.highly_sensitive.read"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.highly_sensitive.write"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.custom.highly_sensitive.read"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.custom.highly_sensitive.write"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "tickets.highly_sensitive"
        }), " (Both read and write access)"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/properties/highly-sensitive-data",
        children: "storing Highly Sensitive Data (BETA)"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Turn on Sensitive Data access for your app"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The process for gaining access to Sensitive Data scopes depends on whether you have a private or public app."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Private apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For private apps, you can add the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#sensitive-data-scopes",
        children: "scopes"
      }), " in your private app settings."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the top navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the private app, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Edit"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/edit-private-app.png",
        alt: "edit-private-app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Scopes"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "checkboxes"
        }), " of the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#sensitive-data-scopes",
          children: "sensitive scopes"
        }), " you want to add."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/sensitive-data-scopes.png",
        alt: "sensitive-data-scopes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Commit changes"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your private app's access token will immediately have access to read and/or write Sensitive Data properties on the selected objects."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Public apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For public apps, you’ll need to request access to specific sensitive scopes from HubSpot’s Ecosystem Quality team. If approved, the team will allowlist the sensitive scopes to test your app and will help you publish your app with the required scopes following a period of testing and compliance checks. You’ll then need to notify app users of the change and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#request-authorization-from-app-users",
        children: "request re-authorization of your app"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Navigate to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/sensitive-data#getStarted",
        children: "this page"
      }), " to view the full process and fill out the form to request Sensitive Data access for public apps."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Request authorization from app users"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once the sensitive ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#sensitive-data-scopes",
        children: "scopes"
      }), " have been added to your app, you’ll need to send ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "authorization URLs"
      }), " to app users so they can authorize and install the app, granting access to Sensitive Data scopes. If they’re existing users of the app, they’ll need to reauthorize with the updated scopes for Sensitive Data access to apply."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you’ve notified your app users, a Super admin in their account will need to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "authorization URL"
        }), " with Sensitive Data scopes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "account"
        }), " into which to install the app. The HubSpot account must have an ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " subscription to access Sensitive Data functionality."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review the updated list of scopes, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Connect App"
        }), " to install."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once authorized and installed, the app can access Sensitive Data via APIs for the account."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Manage Sensitive Data"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once your app has access to Sensitive Data, you can use the following APIs to manage Sensitive Data:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Properties API"
        }), ": create, edit, or archive Sensitive Data properties, and retrieve Sensitive Data property information."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Object APIs"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "(Contacts"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "Companies"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ","
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "Deals"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/tickets",
          children: "Tickets"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ","
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "Custom objects"
        }), "): retrieve or update Sensitive Data property values."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM Search V3 API"
        }), ": search for records with values for Sensitive Data properties. This API is not available for use with Highly Sensitive Data."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Forms API"
        }), ": send form submissions containing Sensitive Data."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks API"
        }), ": create ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyChange"
        }), " event type webhook subscriptions for contact, company, deal, and ticket Sensitive Data properties. This API is not available for use with Highly Sensitive Data."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieve Sensitive Data properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), " to view Sensitive Data property definitions and schema information."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve all Sensitive Data properties for an object, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dataSensitivity"
      }), " query parameter with the value ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sensitive"
      }), ". To retrieve all Highly Sensitive Data properties (", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/properties/highly-sensitive-data",
        children: "BETA"
      }), "), use the value ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "highly_sensitive"
      }), ". If you don't include this parameter when retrieving properties, only non-sensitive properties will be returned. For example, to retrieve all contact properties that store Sensitive Data, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/contacts?dataSensitivity=sensitive"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve an individual property, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}/{propertyName}"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If a property contains Sensitive Data, in the response, the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dataSensitivity"
            }), " property will have the value ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sensitive"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If a property contains Highly Sensitive data (", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/properties/highly-sensitive-data",
              children: "BETA"
            }), "), the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dataSensitivity"
            }), " property will have the value ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "highly_sensitive"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If a returned Sensitive Data property stores protected health information, the returned ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sensitiveDataCategories"
            }), " field will have a value of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "[\"HIPAA\"]"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If it’s not a Sensitive Data property, the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dataSensitivity"
            }), " property value will be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "non_sensitive"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you you have a ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Passport Number"
      }), " Sensitive Data property, when you retrieve it, your response would look like:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response for GET /crm/v3/properties/contacts/passport_number\n{\n  \"updatedAt\": \"2021-11-03T15:24:08.528Z\",\n  \"createdAt\": \"2021-04-16T18:17:14.911Z\",\n  \"name\": \"passport_number\",\n  \"label\": \"Passport Number\",\n  \"type\": \"string\",\n  \"fieldType\": \"text\",\n  \"description\": \"\",\n  \"groupName\": \"contactinformation\",\n  \"options\": [],\n  \"createdUserId\": \"9586504\",\n  \"updatedUserId\": \"9586504\",\n  \"displayOrder\": -1,\n  \"dataSensitivity\": \"sensitive\",\n  \"calculated\": false,\n  \"externalOptions\": false,\n  \"archived\": false,\n  \"hasUniqueValue\": false,\n  \"hidden\": false,\n  \"showCurrencySymbol\": false,\n  \"modificationMetadata\": {\n    \"archivable\": true,\n    \"readOnlyDefinition\": false,\n    \"readOnlyValue\": false\n  },\n  \"formField\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create or update Sensitive Data properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/api/crm/properties",
        children: "properties API"
      }), " to create or update Sensitive Data properties. You must have the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sensitive.write"
      }), " scope for the given object to create or edit its properties."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To create a property and mark it as sensitive, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{object}"
        }), " and include the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dataSensitivity"
        }), " field with the value ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sensitive"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To create a property and mark it as highly sensitive (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/properties/highly-sensitive-data",
          children: "BETA"
        }), "), make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{object}"
        }), " and include the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dataSensitivity"
        }), " field with the value ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "highly_sensitive"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": once you've created a property as sensitive, you ", (0, _jsxRuntime.jsx)("u", {
          children: "cannot"
        }), " change the sensitivity setting."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to create a Sensitive Data property for contacts, your request would look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example response for POST /crm/v3/properties/contacts\n{\n  \"groupName\": \"contactinformation\",\n  \"name\": \"sensitive_property\",\n  \"label\": \"Sensitive Property\",\n  \"type\": \"string\",\n  \"fieldType\": \"text\",\n  \"dataSensitivity\": \"sensitive\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To edit a Sensitive Data property, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/properties/{objectType}/{propertyName}"
        }), ". You ", (0, _jsxRuntime.jsx)("u", {
          children: "cannot"
        }), " edit the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dataSensitivity"
        }), " field."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To archive a Sensitive Data property, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DELETE"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/properties/{objectType}/{propertyName}"
        }), ". Once archived, properties will be permanently deleted after 90 days."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Retrieve Sensitive Data property values"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can retrieve a record’s value for a Sensitive Data property. To do this, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/{object}/{recordId}"
      }), " and include the Sensitive Data property in your query parameters."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to retrieve a contact’s ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Passport Number"
      }), " value, your request URL would look like: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/contacts/1234567?properties=passport_number"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Set or update Sensitive Data property values"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "object APIs"
      }), " to create or edit records to set values for Sensitive Data properties."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To create a record and set a value for a Sensitive Data property, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/{object}"
        }), ". In your request body, include the required properties and the Sensitive Data property."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To update a record’s value for a Sensitive Data property, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/{object}/{objectId}"
        }), ". In your request body, include the Sensitive Data property with the new value. To clear the value, set the value to an empty string in your request body."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you want to create a contact and set a value for the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Passport Number"
      }), " property, your request would look like:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body for POST /crm/v3/objects/contacts\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"company\": \"HubSpot\",\n    \"website\": \"hubspot.com\",\n    \"lifecyclestage\": \"marketingqualifiedlead\",\n    \"passport_number\": \"1234567\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you wanted to update the property’s value later on, your request would look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body for PATCH crm/v3/objects/contacts/{contactID}\n{\n  \"properties\": {\n    \"passport_number\": \"89101112\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CRM Search API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your app has the Sensitive Data scopes, you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search",
        children: "V3 CRM search API"
      }), " to search for records with values for Sensitive Data properties. To do so, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/{object}/search"
      }), " and include the Sensitive Data properties you want to search for."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, the following search would return contacts with their first name, last name, and passport number values:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "/// Example POST request to /crm/v3/objects/contacts/search\n{\n  \"properties\": [\"firstname\", \"lastname\", \"passport_number\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Forms API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To send form submissions containing Sensitive Data to HubSpot, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hsforms.com/submissions/v3/integration/secure/submit/{HubID}/:formGuid"
      }), ". Learn more about using this ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/marketing/forms/v3-legacy#submit-data-to-a-form-supporting-authentication",
        children: "endpoint."
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This is currently the only forms submission API endpoint that can be used with Sensitive Data because it supports authentication. Any other requests to the forms API will result in an error or hide Sensitive Data values."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhooks API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can create ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyChange"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks#webhook-settings",
        children: "event type webhook subscriptions"
      }), " for Sensitive Data properties on contacts, companies, deals, and tickets."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For non-sensitive property ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyChange"
      }), " events, the webhook payload has the new value in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyValue"
      }), " field. For Sensitive Data property ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyChange"
      }), " events, the webhook payload will have the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyValue"
      }), " set as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"REDACTED\""
      }), ". To view the value, you'll need to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#retrieve-sensitive-data-property-values",
        children: "retrieve the record's value"
      }), " for the Sensitive Data property."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, a webhook payload for a Sensitive Data property ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propertyChange"
      }), " event contact subscription would look like the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example webhook payload\n[\n  {\n    \"eventId\": 3029365631,\n    \"subscriptionId\": 686627,\n    \"portalId\": 891472211,\n    \"appId\": 7906213,\n    \"occurredAt\": 1715203101896,\n    \"subscriptionType\": \"contact.propertyChange\",\n    \"attemptNumber\": 0,\n    \"objectId\": 847297356,\n    \"propertyName\": \"passport_number\",\n    \"propertyValue\": \"REDACTED\",\n    \"changeSource\": \"CRM_UI\",\n    \"sourceId\": \"userId:882761034\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Errors"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following errors and redactions are expected:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you don’t have the required ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#sensitive-data-scopes",
          children: "scope"
        }), " to read or update Sensitive Data for a given object, you’ll receive a 403 Forbidden error when you make a request containing a Sensitive Data property to that object's API."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sensitive Data access is only supported for the V3 properties, object, CRM search, and forms APIs.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If you use legacy versions of the properties and object APIs, your request will appear successful, but Sensitive Data values won't be updated and will be hidden in the response body."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If you use legacy versions of the CRM search API, you'll receive a 403 Forbidden error citing support in version 3 or later."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If you use the unauthorized form submission API, you'll receive a 403 Forbidden error. If you try to retrieve form submissions containing Sensitive Data, the submissions will be returned but the Sensitive Data properties and their values will be hidden."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}