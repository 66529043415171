"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694133;
const slug = exports.slug = 'guides/cms/debugging/troubleshooting';
const title = exports.title = 'Debugging methods and error types';
const name = exports.name = 'Debugging methods and error types';
const metaDescription = exports.metaDescription = 'Understanding how to debug and look into errors when developing websites on the HubSpot CMS. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "errors",
  "label": "Errors",
  "parent": null
}, {
  "depth": 1,
  "id": "fatal-errors",
  "label": "Fatal errors",
  "parent": "errors"
}, {
  "depth": 1,
  "id": "warnings",
  "label": "Warnings",
  "parent": "errors"
}, {
  "depth": 0,
  "id": "debug-mode-on-live-pages",
  "label": "Debug mode on live pages",
  "parent": null
}, {
  "depth": 0,
  "id": "developer-mode-in-the-page-editor",
  "label": "Developer mode in the page editor",
  "parent": null
}, {
  "depth": 0,
  "id": "view-hubl-output",
  "label": "View HubL output",
  "parent": null
}, {
  "depth": 0,
  "id": "%7Cpprint",
  "label": "|pprint",
  "parent": null
}, {
  "depth": 0,
  "id": "developer-info",
  "label": "Developer info",
  "parent": null
}, {
  "depth": 0,
  "id": "review-website-performance-and-broken-links",
  "label": "Review website performance and broken links",
  "parent": null
}, {
  "depth": 0,
  "id": "improving-website-speed",
  "label": "Improving website speed",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      code: "code",
      img: "img",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab,
      CTA
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Debugging methods and error types"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Debugging code and understanding where and how to view errors is an important part of development on the HubSpot CMS. There are a number of tools you can use to increase efficiency when building and debugging and to make sure your website is optimized as you continue to build it out."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Errors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubSpot CMS ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/overview#developer-file-system",
        children: "developer file system"
      }), " has many forms of validation to ensure your templates and modules render correctly on pages."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fatal errors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Fatal errors are errors that would prevent a page from successfully rendering. To ensure live content renders correctly, the HubSpot CMS prevents publishing templates that have fatal errors. An example of a fatal error would be missing required HubL variables, such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "standard_header_includes"
      }), ". This will cause errors when developing in the Design Manager or when uploading files through the CMS CLI. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://marketplace.visualstudio.com/items",
        children: "The VS Code Extension"
      }), " supports HubL linting, and can display the fatal errors in-context ahead of uploading the file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/fatal%20error%20-%20design%20manager.png",
        alt: "screenshot of a fatal error - design manager"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/fatal%20error%20-%20CMS%20CLI.png",
        alt: "screenshot of a fatal error - CMS CLI"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Fatal errors must be resolved in order to publish files."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Warnings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Warnings are errors or issues which do not prevent the publishing of files. Warnings are often suggestions in syntax or potential issues a developer might be missing. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://marketplace.visualstudio.com/items",
        children: "The VS Code Extension"
      }), " supports HubL linting, and can display the warnings in-context ahead of uploading the file. For example, if you try to include a file which does not exist, this throws a warning to alert the developer."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/warning%20-design%20manager.png",
        alt: "warning - design manager"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Warnings will never prevent the publishing of files, however, it is recommended to investigate warnings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Debug mode on live pages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can enable debug mode on a live page by loading the page with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebug=true"
      }), " query string in the URL."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " debug mode isn't supported on ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#system-pages",
          children: "system pages"
        }), ", such as 404 and password pages."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When loading a live page with this query string, the page will be rendered:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "with non-minified files."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "with non-combined CSS files (individual CSS files served)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "without serving cached files."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition, when you load a page with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsDebug=true"
      }), ", debugging information will be added to the bottom of the page source code, including:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Whether the page can be ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "prerendered"
        }), ", and the reasons why if it cannot be prerendered."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A breakdown of rendering request timing, which can be helpful for knowing which page components take longer to render. This breakdown will also be added to the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Timing"
        }), " tab in your browser's developer console under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Doc"
        }), " requests."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/timing-tab-request-details.png",
        alt: "timing-tab-request-details"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Errors and warnings, such as HubL function limits or missing files."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/debug-page-source-elements-tab.png",
        alt: "debug-page-source-elements-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Developer mode in the page editor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also load the page editor in HubSpot with the query string to enable developer features, such as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#copy-section-hubl",
        children: "copy sections as HubL"
      }), " and the ability to open specific modules in the design manager from the page editor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the page editor, add the following parameter to the URL, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enter"
        }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?developerMode=true"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["With the page reloaded, you'll now be in developer mode. You can exit developer mode at any time by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exit developer mode"
        }), " in the upper right."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-top-navigation-bar.png",
        alt: "developer-mode-top-navigation-bar"
      }), "While you're in developer mode, you can navigate to the code for a specific module by clicking the associated module, then clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Open in design manager"
      }), " in the sidebar editor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-open-design-manager-for-module.png",
        alt: "developer-mode-open-design-manager-for-module"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also reset any unpublished changes back to the default content of the template:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contents"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To the right of template name, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Reset content"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-mode-reset-contents.png",
        alt: "developer-mode-reset-contents"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the dialog box, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Yes, reset"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View HubL output"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Within the Design Manager, coded files have a “Show output” toggle which open up a second code editor panel, with a transpiled code of the file you are looking at. This is helpful to see how your HubL code will transpile into CSS, HTML or JavaScript, rather than reloading live pages the file is included on. It is also a helpful tool to use when exploring new features of HubL, or learning the basics of HubL as you can easily see what your HubL input will output to."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/View%20HubL%20output.png",
        alt: "View HubL output"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.h2, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "|pprint"
      }), " HubL filter"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|pprint"
      }), " HubL filter can be used on HubL variables to print valuable debugging information. It will print the type of HubL variable, which can be useful in understanding what expressions, filters, operators or functions it can be used with."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ local_dt }}"
      }), " will print ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2020-02-21 12:52:20"
      }), ". If we pretty print this variable, we can see the value is a date ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "(PyishDate: 2020-02-21 12:52:20)"
      }), ". This means we can use HubL filters that operate or format date objects, such as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|datetimeformat"
      }), " HubL filter."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ local_dt }}\n{{ local_dt|pprint }}\n{{ local_dt|datetimeformat('%B %e, %Y') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2020-02-21 12:55:13 (PyishDate: 2020-02-21 12:55:13) February 21, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Developer info"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Much of the data found in developer info is used internally, and is subject to change if not otherwise documented."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The developer info for a page is the context of all data available when a page is being rendered. This rendering context is all accessible via HubL. To access the developer info for a page, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubSpot sprocket icon in the top right hand corner of live pages > Developer Info."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Developer%20info%20sprocket%20menu.png",
        alt: "Developer info sprocket menu"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This will open up a new tab that returns the rendering context for a given page in the form of JSON. It is recommended to have a JSON formatter installed in your browser to make the developer info easier to read, such as this ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://chrome.google.com/webstore/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa",
        children: "JSON formatter Chrome extension"
      }), ". While much of the information contained in the page's context is for internal purposes, this tool can be valuable to see what data is available via HubL when templating."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the following image is of the developer info for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/overview",
        children: "https://desigers.hubspot.com/docs/developer-reference/cdn"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Developer%20info%20example.png",
        alt: "Developer info example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The values of this data are being set through the Settings tab of the Content Editor:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/content%20editor%20-%20settings.png",
        alt: "content editor - settings"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The values are then accessible to render on pages through HubL. To print the title and meta description in a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "base template"
      }), ", you would use the following HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<title>{{ page_meta.html_title }}</title>\n<meta name=\"description\" content=\"{{ page_meta.meta_description }}\">\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The data in the rendering context is available through HubL, and the JSON tree can be traversed using dot notation. Data in the developer info that developers frequently print out include module field values and tags that have been ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: "exported to template context"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Review website performance and broken links"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["It's important to verify that your site's visitors are not going to broken links. There are two tools you can use to help ensure your site visitors are getting to the correct place. You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/deprecated/overview",
        children: "website performance API"
      }), " to get HTTP Statuses like 404s and see your sites uptime."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you're seeing 404 errors it's a good idea to redirect the visitor to a relevant URL."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/seo/view-seo-recommendations-in-hubspot",
        children: "SEO Recommendations tool"
      }), " to identify broken links within your page content and quickly fix them."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Improving website speed"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are a lot of factors that go into optimizing and testing website speed. For tools and tips to optimizing your site's speed see our guide."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "841bbe22-70a6-4222-a6f0-85dd019711ae",
      label: "Optimize your CMS Hub site speed"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}