"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66496400687;
const slug = exports.slug = 'reference/cms/hubl/variables-macros-syntax';
const title = exports.title = 'Variables HubL et syntaxe des macros';
const name = exports.name = 'EMEA FR - HubL variables & macros syntax';
const metaDescription = exports.metaDescription = 'Apprenez à utiliser les variables HubL et les macros pour créer des pages dynamiques et des e-mails sur HubSpots CMS. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variables",
  "label": "Variables",
  "parent": null
}, {
  "depth": 0,
  "id": "macros",
  "label": "Macros",
  "parent": null
}, {
  "depth": 0,
  "id": "appel",
  "label": "Appel",
  "parent": null
}, {
  "depth": 0,
  "id": "import",
  "label": "Import",
  "parent": null
}, {
  "depth": 0,
  "id": "depuis",
  "label": "depuis",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-dans-les-boucles",
  "label": "Variables dans les boucles",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      pre: "pre",
      h2: "h2",
      strong: "strong",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Variables HubL et syntaxe des macros"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubL utilise des variables pour stocker et sortir des valeurs vers le modèle. Les variables peuvent être utilisées dans la logique des modèles ou faire l'objet d'une itération avec des boucles. Outre les variables, les macros sont un autre outil utile pour imprimer des sections de code répétitives et dynamiques dans vos modèles."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les variables sont des expressions délimitées par ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "}}"
      }), ". La syntaxe de base des variables est la suivante :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// variables\n{{ variable }}\n{{ dict.attribute }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les variables sont soit un mot unique dans une expression, soit une entrée de dictionnaire. HubL utilise des structures de données basées sur Python appelées dictionnaires ou ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "dicts"
      }), " pour stocker divers ensembles de variables. Par exemple, HubSpot utilise un dictionnaire « content » pour contenir de nombreux attributs qui se rapportent au contenu créé avec ce modèle. Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content.absolute_url"
      }), " imprime l'URL de l'élément de contenu spécifique."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot dispose de nombreuses variables prédéfinies pouvant être utilisées dans vos modèles de pages, de blogs et d'e-mails. Nous disposons d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "liste de référence des variables"
      }), ", vous pouvez également consulter les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting#developer-info",
        children: "informations sur le développeur"
      }), " lorsque vous naviguez sur n'importe quelle page de votre compte pour voir les variables disponibles sur cette page..."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Outre l'impression des valeurs des variables et des entrées du dictionnaire dans un modèle, vous pouvez également définir vos propres variables. Vous pouvez stocker des chaînes de caractères, des booléens, des entiers, des séquences ou créer des dictionnaires dans une seule variable. Les variables sont définies à l'intérieur des délimiteurs d'instructions en utilisant le mot « set ». Une fois stockées, les variables peuvent ensuite être imprimées en indiquant le nom de la variable sous la forme d'expression. Vous pouvez voir ci-dessous différents types d'informations stockées dans des variables, puis imprimées."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les variables doivent être des mots simples ou utiliser des traits de soulignement pour les espaces (ex : my_variable). ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubL ne prend pas en charge pas les noms de variables avec un trait d'union."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set string_var = \"This is a string value stored in a variable\" %}\n{{ string_var}}\n\n{% set bool_var = True %}\n{{ bool_var}}\n\n{% set int_var = 53 %}\n{{ int_var}}\n\n{% set seq_var = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{{ seq_var}}\n\n{% set var_one = \"String 1\" %}\n{% set var_two = \"String 2\" %}\n{% set sequence = [var_one,  var_two] %}\n{{ sequence }}\n\n{% set dict_var = {\"name\": \"Item Name\", \"price\": \"$20\", \"size\":\"XL\"} %}\n{{ dict_var.price }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This is a string value stored in a variable True 53 [Item1, Item2, Item3]\n[String 1, String 2] $20\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Chaque exemple ci-dessus stocke un type de variable différent, le dernier exemple stockant deux variables différentes dans une séquence."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Outre l'impression de valeurs, des variables peuvent être utilisées dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "instructions SI"
      }), " , comme paramètres de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "filtre"
      }), " de , comme paramètres de fonction", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions"
      }), ", mais aussi itérées avec les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "boucles de"
      }), " (variables de séquence uniquement)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'utilisation courante consiste à utiliser des variables pour définir des valeurs CSS communes dans votre feuille de style. Par exemple, s'il y a une couleur que vous utilisez toujours dans votre fichier CSS. Ainsi, si vous devez changer cette couleur, vous pouvez modifier la valeur de la variable, et toutes ses références seront mises à jour la prochaine fois que vous publierez le fichier."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<!-- defines variable and assigns HEX color -->\n{% set primaryColor = \"#F7761F\" %}\n\na {\n    color: {{ primaryColor }}; {# prints variable HEX value #}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  color: #f7761f;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Macros"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les macros HubL vous permettent d'imprimer plusieurs déclarations avec une valeur dynamique. Par exemple, si vous avez un bloc de code que vous écrivez systématiquement, alors, une macro peut être une bonne solution, car elle imprimera le bloc de code tout en échangeant certains arguments que vous lui passez."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La macro est définie, nommée et dotée d'arguments dans une instruction HubL. La macro est ensuite appelée dans une instruction qui passe ses valeurs dynamiques, ce qui imprime le bloc de code final avec les arguments dynamiques. La syntaxe de base d'une macro est la suivante :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% macro name_of_macro(argument_name, argument_name2)  %}\n    {{ argument_name }}\n    {{ argument_name2 }}\n{% endmacro %}\n{{ name_of_macro(\"value to pass to argument 1\", \"value to pass to argument 2\")  }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si votre macro renvoie des espaces blancs sous la forme de nouvelles lignes, vous pouvez les supprimer manuellement dans les modèles. Si vous ajoutez un signe moins (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), ") au début ou à la fin d'un bloc, d'un commentaire ou d'une expression variable, les espaces avant ou après ce bloc seront supprimés."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% macro name_of_macro(argument_name, argument_name2) -%}\n    {{ argument_name }}\n    {{ argument_name2 }}\n{%- endmacro %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous trouverez ci-dessous un cas d'application pratique d'une macro permettant d'imprimer une propriété CSS3 avec les différents préfixes du fournisseur, avec une valeur dynamique. Cela vous permet d'imprimer 5 lignes de code avec une seule balise macro."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro trans(value) %}\n-webkit-transition: {{value}};\n-moz-transition: {{value}};\n-o-transition: {{value}};\n-ms-transition: {{value}};\ntransition: {{value}};\n{% endmacro %}\n\n\na {\n    {{ trans(\"all .2s ease-in-out\") }}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  -webkit-transition: all 0.2s ease-in-out;\n  -moz-transition: all 0.2s ease-in-out;\n  -o-transition: all 0.2s ease-in-out;\n  -ms-transition: all 0.2s ease-in-out;\n  transition: all 0.2s ease-in-out;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Les macros introduisent la possibilité d'avoir du code récursif. Pour éviter les problèmes de fiabilité et de performances, vous ne pouvez imbriquer les macros que sur 20 niveaux. Si vous dépassez cette limite, le message d'erreur suivant s'affichera : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "limite de récursivité de 20 atteinte pour la macro <your macro name>"
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Appel"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans certains cas, vous pouvez souhaiter renvoyer des informations dynamiques supplémentaires dans le bloc macro. Par exemple, vous pouvez avoir une grande partie de code que vous souhaitez réinjecter dans la macro, en plus des arguments. Vous pouvez le faire en utilisant le bloc d'appel et caller(). Un bloc d'appel fonctionne essentiellement comme une macro, sans avoir son propre nom. L'expression caller() indique où le contenu du bloc d'appel sera rendu."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l'exemple ci-dessous, un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<p>"
      }), "est ajouté dans une macro en plus des deux arguments."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro render_dialog(title, class) %}\n  <div class=\"{{ class }}\">\n      <h2>{{ title }}</h2>\n      <div class=\"contents\">\n          {{ caller() }}\n      </div>\n  </div>\n {% endmacro %}\n\n {% call render_dialog(\"Hello World\", \"greeting\") %}\n     <p>This is a paragraph tag that I want to render in my.</p>\n {% endcall %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"greeting\">\n  <h2>Hello World</h2>\n  <div class=\"contents\">\n    <p>This is a simple dialog rendered by using a macro and a call block.</p>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Import"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une autre fonctionnalité intéressante des macros est qu'elles peuvent être utilisées dans plusieurs modèles en important un fichier modèle dans un autre. Pour ce faire, vous devez utiliser la balise ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "import"
      }), " . La balise import vous permettra de spécifier un chemin d'accès au Gestionnaire de conception vers le modèle qui contient vos macros et de leur donner un nom dans le modèle dans lequel vous les incluez. Vous pouvez alors passer des valeurs dans ces macros sans avoir besoin de les redéfinir."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, imaginons que vous avez un fichier modèle.html qui contient les 2 macros suivantes : Une macro est définie pour configurer une balise d'en-tête et une autre est définie pour générer une balise de pied de page. Ce fichier est enregistré dans le Gestionnaire de conception sous le nom ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- my_macros.html file -->\n{% macro header(tag, title_text) %}\n    <header> <{{ tag }}>{{ title_text }} </{{tag}}> </header>\n{% endmacro %}\n\n{% macro footer(tag, footer_text) %}\n     <footer> <{{ tag }}>{{ footer_text }} </{{tag}}> </footer>\n{% endmacro %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le modèle qui utilisera ces macros, on utilise une balise d'import qui spécifie le chemin d'accès au fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), ". Il nomme également le groupe de macros (dans cet exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "header_footer"
      }), "). Les macros peuvent ensuite être exécutées en ajoutant le nom de la macro au nom donné au modèle importé. Voir l'exemple ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% import \"custom/page/web_page_basic/my_macros.html\" as header_footer %}\n{{ header_footer.header(\"h1\", \"My page title\") }}\n<p>Some content</p>\n{{ header_footer.footer(\"h3:\", \"Company footer info\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<header><h1>My page title</h1></header>\n<p>Some content</p>\n<footer><h3>Company footer info</h3></footer>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "depuis"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous souhaitez importer uniquement des macros spécifiques, au lieu de toutes les macros contenues dans un fichier.html distinct, vous pouvez utiliser la balise ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "from"
      }), " . Avec la balise from, spécifiez uniquement les macros que vous souhaitez importer. En général, l'utilisation de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "import"
      }), " offre plus de flexibilité, mais cette alternative est également prise en charge."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'exemple ci-dessous accède au même fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), " que celui de la section précédente de cet article. Mais cette fois, au lieu d'importer toutes les macros, il n'a accès qu'à la macro de pied de page."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% from \"custom/page/web_page_basic/my_macros.html\" import footer %}\n{{ footer(\"h2\", \"My footer info\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<footer><h2>My footer info</h2></footer>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables dans les boucles"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Toutes les variables définies dans les boucles sont limitées à la portée de cette boucle et ne peuvent pas être exécutées depuis l'extérieur de la boucle."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez exécuter des variables définies en dehors d'une boucle, à partir d'une boucle, mais pas l'inverse."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également utiliser des fonctions pour modifier des objets et définir les paramètres des valeurs sur des dictionnaires, ou effectuer des opérations sur des listes. L'exemple suivant utilise l'opération de liste ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/hubl/functions#update",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: ".update"
        }), " :"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrée",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set obj = {val : 0} %}\n{% for i in range(0, 10) %}\n   {% do obj.update({val: obj.val + i }) %}\n{% endfor %}\n{{ obj.val }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Sortie",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "45\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}