"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694158;
const slug = exports.slug = 'guides/cms/content/templates/types/html-hubl-templates';
const title = exports.title = 'Modèles HTML et HubL';
const name = exports.name = 'Modèles HTML et HubL';
const metaDescription = exports.metaDescription = 'Les modèles HubSpot CMS sont écrits en HTML et en HubL. Ces modèles sont capables de contenir des régions que les créateurs de contenu peuvent facilement modifier eux-mêmes.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/image1-2.png';
const featuredImageAltText = exports.featuredImageAltText = 'Capture d’écran d’un modèle HTML + HubL de base';
const toc = exports.toc = [{
  "depth": 0,
  "id": "familiarit%C3%A9-et-outils",
  "label": "Familiarité et outils",
  "parent": null
}, {
  "depth": 0,
  "id": "annotations-de-mod%C3%A8les",
  "label": "Annotations de modèles",
  "parent": null
}, {
  "depth": 0,
  "id": "en-t%C3%AAte-et-pied-de-page-inclus",
  "label": "En-tête et pied de page inclus",
  "parent": null
}, {
  "depth": 0,
  "id": "partiels",
  "label": "Partiels",
  "parent": null
}, {
  "depth": 1,
  "id": "partiels-standards",
  "label": "Partiels standards",
  "parent": "partiels"
}, {
  "depth": 1,
  "id": "partials-globaux",
  "label": "Partials globaux",
  "parent": "partiels"
}, {
  "depth": 1,
  "id": "blocs-et-extensions",
  "label": "Blocs et extensions",
  "parent": "partiels"
}, {
  "depth": 1,
  "id": "groupes-globaux",
  "label": "Groupes globaux",
  "parent": "partiels"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      HubspotVideoPlayer,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Modèles HTML et HubL"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Les modèles HTML et HubL peuvent être utilisés pour ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/templates/overview#template-types",
            children: "chaque type de modèle"
          }), " sur HubSpot CMS. Ces modèles sont des fichiers .html qui prennent en charge le ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/cms/hubl/overview",
            children: "langage de modélisation HubL"
          }), ". Comme ces modèles codés prennent en charge HubL, la meilleure expérience de prévisualisation consiste à utiliser ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/review-your-hubspot-template-setup",
            children: "l'aperçu du modèle dans le gestionnaire de conception "
          }), "ou à visualiser les pages sur un compte sandbox. Les modèles HTML et HubL peuvent contenir des ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#partials",
            children: "partials"
          }), ", qui peuvent être utilisés pour séparer des morceaux de code couramment utilisés, tels qu'un en-tête ou un pied de page."]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172844649367"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modèles HTML et HubL donnent un plus grand contrôle aux développeurs que les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "modèles de type glisser-déposer du gestionnaire de conception visuelle"
      }), ". À leur tour, les développeurs peuvent offrir de meilleures expériences aux créateurs de contenu grâce à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "la fonctionnalité de glisser-déposer"
      }), ", ce qui n'est possible qu'avec les modèles HTML et HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image1-2.png",
        alt: "image1-2"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Le modèle ci-dessus est le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/layouts/base.html",
          children: "modèle base.html"
        }), " inclus dans le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "boilerplate du CMS HubSpot"
        }), ", qui permet de bien démarrer dans le développement avec HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Familiarité et outils"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Étant donné que les modèles HTML et HubL sont des fichiers codés, vous pouvez utiliser vos outils préférés pour les modifier localement. Il est recommandé d'utiliser les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "outils de développement local"
      }), " de HubSpot afin que vous puissiez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "charger"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "récupérer"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "consulter"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#create",
        children: "créer"
      }), " ou gérer de manière sécurisée des fichiers dans le système de fichiers du développeur ainsi que dans le gestionnaire de fichiers."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La création de modèles HTML et HubL avec HubSpot est similaire à l'utilisation d'un autre langage de modélisation que vous avez peut-être utilisé auparavant. La principale différence réside dans le fait que HubSpot adopte une position bien arrêtée sur les meilleures façons de faire certaines choses afin d'offrir la meilleure expérience possible aux créateurs de contenu et de décharger le développeur d'une grande partie du travail de maintenance et d'optimisation des performances."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, si vous souhaitez charger un fichier CSS sur une page pour certains modules, au lieu d'utiliser", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<link rel=\"stylesheet\" type=\"text/css\" href=\"theme.css\">"
      }), ", vous devez inclure la feuille de style via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css_assets"
      }), " dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-css-and-javascript-dependencies",
        children: "fichier meta.json du module"
      }), ". Cela permet à HubSpot de charger de façon conditionnelle la CSS uniquement lorsque le module est présent sur une page, minimisant ainsi la quantité de CSS inutiles chargées."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "l'optimisation de votre workflow de développement HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Annotations de modèles"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les annotations de modèles, incluses en haut d'un modèle, configurent des paramètres de modèle importants, tels que le type de modèle et s'il peut être utilisé pour créer un nouveau contenu. Les annotations de modèles peuvent être modifiées à tout moment au cours du processus de développement. Découvrez-en davantage sur les annotations de modèles disponibles ci-dessous."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: page\n  isAvailableForNewContent: false\n  enableDomainStylesheets: false\n  label: Homepage\n  screenshotPath: ../images/template-previews/home.png\n-->\n\n<!doctype html>\n\n<html>\n  ...\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Annotation"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "templateType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'indiquer le type de modèle d'un fichier. Les valeurs sont les suivantes :", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Modèles standards :", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#page",
                      children: "page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email",
                      children: "e-mail"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog-listing",
                      children: "blog_listing"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog-post",
                      children: "blog_post"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog",
                      children: "blog"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Partiels : ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
                  children: "global_partial"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Modèles système :", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#error-pages",
                      children: "error_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#password-prompt",
                      children: "password_prompt_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-login",
                      children: "membership_login_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-register",
                      children: "membership_register_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-password-reset",
                      children: "membership_reset_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-reset-request",
                      children: "membership_reset_request_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-subscription-preferences",
                      children: "email_subscription_preferences_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-backup-unsubscribe",
                      children: "email_backup_unsubscribe_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-subscription-unsubscribe-confirmation",
                      children: "email_subscriptions_confirmation_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#search-results-page",
                      children: "search_results_page"
                    })
                  })]
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isAvailableForNewContent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'indiquer si un modèle est disponible à la sélection dans le processus de création de contenu. Les valeurs sont : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ". Les modèles définis sur la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " n’ont pas besoin d’inclure les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/variables#required-page-template-variables",
              children: "variables requises"
            }), ". Les modèles de type ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "page"
            }), " qui sont définis sur la valeur false peuvent également être utilisés comme ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#partials",
              children: "partiel standard"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableDomainStylesheets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : permet d'indiquer si le modèle doit charger les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/design-manager/create-edit-and-attach-css-files-to-style-your-site#attach-or-remove-stylesheets-on-a-domain-level",
              children: "feuilles de style de domaine"
            }), ". Les valeurs comprennent :", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", ."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" : la description conviviale du modèle, affichée dans l'écran de sélection du modèle. Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "About Page"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Homepage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Pricing"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshotPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " : la capture d'écran à afficher lorsqu'un créateur de contenu sélectionne un modèle. Cette capture d'écran devrait permettre de différencier facilement vos modèles."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "En-tête et pied de page inclus"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les modèles de HubSpot exigent la présence de deux balises, sauf si le fichier est un partial de modèle. Les deux balises sont :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ standard_header_includes }}"
        }), " - Permet d’ajouter intelligemment la CSS requise combinée et minifiée."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ standard_footer_includes }}"
        }), " - Permet d’ajouter intelligemment du JavaScript au bas d’une page de façon dynamique, pour le script de suivi HubSpot et les modules par exemple."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ces balises doivent être présentes dans un modèle ou ses enfants ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#partials",
        children: "partials"
      }), " pour être publiés et utilisés."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Partiels"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les partials de modèles sont des fichiers HTML et HubL qui peuvent être inclus dans d'autres fichiers codés. Les partials vous permettent d'adopter une approche plus modulaire en partageant le même balisage pour plusieurs modèles. Par exemple, un en-tête peut être rendu en tant que partial afin que vous puissiez facilement l'inclure comme composant sans avoir à le coder à nouveau."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Partiels standards"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un partial standard est un modèle ou un composant réutilisable contenant du contenu qui peut être modifié sur des pages individuelles. Cela permet aux créateurs de contenu de modifier le contenu au besoin, par opposition aux partials globaux qui partagent toujours le même contenu. Par exemple, le code suivant extrairait le module de barre latérale dans un autre fichier de modèle."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les partials standards doivent inclure les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "annotations"
      }), " suivantes en haut du fichier de modèle :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateType: page"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "isAvailableForNewContent: false"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"../partial/sidebar.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Partials globaux"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un partial global est un type de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content#global-partials",
        children: "contenu global"
      }), " qui peut être utilisé sur plusieurs modèles avec du contenu qui est partagé sur toutes les instances du partial. Les partials globaux sont souvent utilisés pour les en-têtes et les pieds de page, dont vous pouvez voir un exemple dans l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/partials/header.html",
        children: "en-tête"
      }), " et le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/partials/footer.html",
        children: "pied de page"
      }), " du boilerplate de CMS Hub. Ces partiels sont ensuite appelés dans ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "base.html"
      }), " à l’aide de la balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global_partial"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les partiels globaux doivent inclure l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "annotation"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: global_partial"
      }), " en haut du fichier."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% global_partial path=\"../partials/header.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Blocs et extensions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors de la création de modèles complexes, vous pouvez créer des blocs compartimentés qui étendent un modèle parent."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, vous pouvez créer un modèle parent qui inclut les variables ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        })
      }), " requises. Dans ce modèle, vous définissez un bloc unique à l’aide de la syntaxe suivante où ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body"
      }), " est un nom unique :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% block body %}\n<!-- Content to display -->\n{% endblock body %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ensuite, dans le modèle enfant, vous pouvez étendre le modèle parent, puis insérer plus de contenu dans le bloc ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% extends \"./layouts/base.html\" %}\n{% block body %}\n<h3>Page Content</h3>\n<ul>\n  <li>Bullet 1<li>\n  <li>Bullet 2<li>\n  <li>Bullet 3<li>\n</ul>\n{% endblock %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette méthode est utilisée dans le modèle ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/layouts/base.html",
        children: "base.html"
      }), " du boilerplate de CMS Hub, qui est ensuite étendu par les autres modèles dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/main/src/templates",
        children: "dossier de modèles"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Groupes globaux"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
        children: "groupes globaux"
      }), " créés à l'aide du générateur de modèles par glisser-déposer dans le gestionnaire de conception peuvent également être inclus. La syntaxe est affichée ci-dessous :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"/path/to/global_header.template.json\" %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}