"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694124;
const slug = exports.slug = 'guides/cms/content/multi-language-content';
const title = exports.title = 'Multi-language Content';
const name = exports.name = '[new] Multi-language';
const metaDescription = exports.metaDescription = 'Developers building websites on HubSpot can easily support a multi-language website. Some development effort may be required based on your unique business.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "hubspot-s-default-multi-language-functionalities",
  "label": "HubSpot's default multi-language functionalities",
  "parent": null
}, {
  "depth": 1,
  "id": "what-hubspot-does-not-do",
  "label": "What HubSpot does not do",
  "parent": "hubspot-s-default-multi-language-functionalities"
}, {
  "depth": 0,
  "id": "set-language-variables",
  "label": "Set language variables",
  "parent": null
}, {
  "depth": 0,
  "id": "use-page-editable-modules",
  "label": "Use page-editable modules",
  "parent": null
}, {
  "depth": 0,
  "id": "include-field-translations-in-custom-modules-and-themes",
  "label": "Include field translations in custom modules and themes",
  "parent": null
}, {
  "depth": 1,
  "id": "local-development",
  "label": "Local development",
  "parent": "include-field-translations-in-custom-modules-and-themes"
}, {
  "depth": 1,
  "id": "design-manager",
  "label": "Design Manager",
  "parent": "include-field-translations-in-custom-modules-and-themes"
}, {
  "depth": 0,
  "id": "translate-system-pages",
  "label": "Translate system pages",
  "parent": null
}, {
  "depth": 0,
  "id": "include-a-language-switcher",
  "label": "Include a language switcher",
  "parent": null
}, {
  "depth": 0,
  "id": "implementing-search-on-multi-language-websites",
  "label": "Implementing search on multi-language websites",
  "parent": null
}, {
  "depth": 0,
  "id": "use-global-partials-and-modules",
  "label": "Use global partials and modules",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      h3: "h3",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Multi-language Content"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Any company which does business across regions or with a customer-base that speaks multiple languages needs to be able to connect with their audience in the audience’s language. With HubSpot’s CMS, users are able to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-to-manage-multi-language-content-with-hubspots-cos",
        children: "create multi-language variations"
      }), " of their content that enable the end-user to view the content in the language with which they are most comfortable."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot sets a number of facets of a multi-language website up for you automatically, but there is also a number steps developers should take to ensure their website is multi-language ready."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot's default multi-language functionalities"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Whenever a multi-language variant is created for a page in HubSpot, we will automatically:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Create a new entry in the XML sitemap indicating the translated page’s name and URL."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Specify the language of the content within the page ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "<head>"
          }), " for templates built using drag and drop functionality."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Identify other pages within the multi-language content group following the appropriate standardized format, which marks the other pages as alternates to avoid duplicate content errors and also identifies the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes",
            children: "ISO 639-1"
          }), " code associated with the language translation(s):"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "<link rel=\"alternate\" hreflang=\"[**_lang_code_**](https://support.google.com/webmasters/answer/189077#language-codes)\" href=\"_url_of_page_\" />"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Re-write links on language pages to navigate to intra-language versions of the linked page to help visitors stay in-language, and prevent the need for you to have to update every single link on every page translation. For a given element, you can disable this rewrite by adding the class \"hs-skip-lang-url-rewrite\" to the element."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "What HubSpot does not do"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With the HubSpot CMS, HubSpot does not automatically:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "translate the content of the page for you."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "direct users to a multi-language variation based upon their GeoIP."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "include a language-switcher module within your header or website."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "specify the language of a page for coded files."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "set the content directional attribute for translations using a language that reads right-to-left as opposed to left-to-right for coded files."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Set language variables"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Because coded files do not automatically include language declarations or content language directional attributes, this will need to be manually set up for coded templates. Language variables can be set in HTML or populated via HubL, such as in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html#L2",
        children: "CMS Boilerplate template"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Setting these properties using HubL will allow this data to populate dynamically within a page’s HTML based upon the language set for the page within the HubSpot CMS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<html lang=\"{{ html_lang }}\" {{ html_lang_dir }}>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Use page-editable modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In order to ensure that content can be localized across each instance of a template’s use, leverage ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "custom modules"
      }), " in place of hard-coded HTML content whenever possible. Creating modules that can be edited at the page level will enable content creators to set the specific content that should appear on each page without having the adjust template code. It also allows for unique content to be used across pages which share a template."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Include field translations in custom modules and themes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To support your global team, you can publish translations of modules you've created in HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After you've translated the content in the module and published in the languages of your team members, users will see the field labels for that module in their account default language. The content in a translated module will not be translated automatically; you will need to do this. You can create translations of your module in any supported language."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can set translations using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview#local-module-file-structure",
        children: "local development tools"
      }), " or through the Design Manager."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Local development"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To set translations using local development tooling, every module folder and every theme folder can contain a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_locales"
      }), " folder, with language locale subfolders, each with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "messages.json"
      }), " file, containing module field translations."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/local%20module%20translations.png",
        alt: "Screenshot of editing module translations locally in VS Code"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Design Manager"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To set field translations through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Design Manager"
      }), ", when viewing the module, navigate to the “Add Translations” option on the right hand side of the screen. Select the languages in which your team works from the dropdown menu. From there, you are able to select each language and specify the labeling conventions for each field in each language."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/field_translations.gif",
        alt: "Screenshot of field translations in the design manager"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Theme field translations do not have an interface in the design manager and need to be edited through the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".json"
        }), " files."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Translate system pages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To set up translations for system pages, including password reset and email subscription pages, you can customize module and HubL tag fields with your translated content. Learn more about the available fields for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules",
        children: "modules"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#system-page-tags",
        children: "system page HubL tags"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Include a language switcher"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To enable end-users to toggle between available translations, it is advised that a language-switcher module be added to your website."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["An ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/09ad0b2c3bbc8400e64c5617268cb0504392e8e5/src/templates/partials/header.html#L26-L47",
        children: "example of of how to implement a language switcher"
      }), " can be found in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "CMS Theme Boilerplate"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Header navigation row one #}\n      <div class=\"header__row-1\">\n        {% if content.translated_content.values()|selectattr('published')|length || is_listing_view && group.translations %}\n          <div class=\"header__language-switcher header--element\">\n            <div class=\"header__language-switcher--label\">\n              {% module 'language-switcher' path='@hubspot/language_switcher',\n                label='Language switcher',\n                display_mode='localized'\n              %}\n              <div class=\"header__language-switcher--label-current\"> {{ locale_name(locale) }}</div>\n            </div>\n          </div>\n        {% endif %}\n        <div class=\"header__search header--element\">\n          {% module 'site_search' path='@hubspot/search_input',\n            label='Search',\n            field_label='Search',\n            placeholder=''\n          %}\n        </div>\n      </div>\n      {# End header navigation row one #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Implementing search on multi-language websites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-search",
        children: "Content Search"
      }), " supports querying for content across the various languages on your website. The language filter parameter can be used when hitting the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "/contentsearch/v2/search"
      }), " to return only specified languages, which allows you to create search experiences for each language on your website, or let visitors search across multiple languages on your website."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Use global partials and modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use module fields to make text in headers, footers, and sidebars editable. Place these modules into global partials. Not only will content creators benefit from ease of editing, global partials support ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-to-manage-multi-language-content-with-hubspots-cos#edit-global-content-in-a-multi-language-page",
        children: "configuring their settings for each language"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/edit-multilanguage-global-content.png",
        alt: "Screenshot of page editor showing header partial"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}