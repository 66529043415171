"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 127249252657;
const slug = exports.slug = 'reference/ui-components/manage-ui-extension-layout';
const title = exports.title = 'Manage UI extension layout (BETA)';
const name = exports.name = 'Manage UI extension layout (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to customize UI extension layout using Flex and Box components.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "flex",
  "label": "Flex",
  "parent": null
}, {
  "depth": 1,
  "id": "horizontal-layout",
  "label": "Horizontal layout",
  "parent": "flex"
}, {
  "depth": 1,
  "id": "wrap",
  "label": "Wrap",
  "parent": "flex"
}, {
  "depth": 1,
  "id": "vertical-layout",
  "label": "Vertical layout",
  "parent": "flex"
}, {
  "depth": 1,
  "id": "spacing",
  "label": "Spacing",
  "parent": "flex"
}, {
  "depth": 1,
  "id": "using-flex-in-flex",
  "label": "Using Flex in Flex",
  "parent": "flex"
}, {
  "depth": 0,
  "id": "box",
  "label": "Box",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      img: "img",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Manage UI extension layout (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, UI extension components will arrange themselves based on their content, order, and any layout-related props included with the component, such as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), " prop for images. But you can further configure your extension's layout using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " components, which are based on the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://css-tricks.com/snippets/css/a-guide-to-flexbox/",
        children: "CSS flexbox layout"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " are both used as wrappers around other components. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " can be used on its own, while ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " can be used as a wrapper around ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " child items to fine tune spacing of individual components. Below, learn more about each component along with usage examples."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Check out HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview#manage-layouts-flex-and-box",
        children: "Managing layout: Flex and Box sample project"
      }), " to view a full example of using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/flex-playground-card-demo.gif?width=600&height=564&name=flex-playground-card-demo.gif",
        alt: "flex-playground-card-demo"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Flex"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/ui-components/overview#layout-flex",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Flex"
        }), " component"]
      }), " renders an empty ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "div"
      }), " container set to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "display=flex"
      }), ". When wrapped around other components, this enables those child components to be arranged using props."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below are the available ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " props. To review all ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " prop definitions, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/overview#layout-flex",
        children: "components reference guide"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Values"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "direction"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "row"
                }), " (default)"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "column"
                })
              })]
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Arranges components horizontally or vertically by setting the main axis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "justify"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "center"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "start"
                }), " (default)"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "end"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "around"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "between"
                })
              })]
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Distributes components along the main axis using the available free space."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "align"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "start"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "center"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "baseline"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "end"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "stretch"
                }), " (default)"]
              })]
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Distributes components along the cross-axis using the available free space."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignSelf"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "start"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "center"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "baseline"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "end"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "stretch"
                })
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Distributes a child component along the cross-axis using the available free space. Use this prop for nested child ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Flex"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Box"
            }), " components to align them differently from other child components in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Flex"
            }), " group."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "wrap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "wrap"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nowrap"
                })
              })]
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether components will wrap instead of trying to fit on one line."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "flush"
                }), " (default)"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "extra-small"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "small"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "large"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "extra-large"
                })
              })]
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sets the spacing between components."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Review the examples below to see how the ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/ui-components/overview#flex",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Flex"
        }), " component"]
      }), " can be used to arrange components in various ways."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Horizontal layout"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To arrange components horizontally, set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "direction"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "row"
      }), ". Then, use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "justify"
      }), " to configure the horizontal distribution. By default, components will stretch across the container if ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "justify"
      }), " is not specified."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To arrange components horizontally and evenly spaced:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/flex-tiles-justify-between.png",
        alt: "flex-tiles-justify-between"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'between'}>\n  <Tile>Tile 1</Tile>\n  <Tile>Tile 2</Tile>\n  <Tile>Tile 3</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To arrange components horizontally, evenly spaced, and with equal spacing on the left and right margins:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/flex-tiles-justify-around.png",
        alt: "flex-tiles-justify-around"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'around'}>\n  <Tile>Tile 1</Tile>\n  <Tile>Tile 2</Tile>\n  <Tile>Tile 3</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To arrange components horizontally at the end of the extension container:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-justify-end.png",
        alt: "ui-extrensions-layout-tile-justify-end"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'end'}>\n  <Tile>Tile 1</Tile>\n  <Tile>Tile 2</Tile>\n  <Tile>Tile 3</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To arrange components horizontally at the center of the extension container:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-justify-center.png",
        alt: "ui-extrensions-layout-tile-justify-center"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'center'}>\n  <Tile>Tile 1</Tile>\n  <Tile>Tile 2</Tile>\n  <Tile>Tile 3</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To arrange components horizontally at the start of the extension container:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-justify-start.png",
        alt: "ui-extrensions-layout-tile-justify-start"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'start'}>\n  <Tile>Tile 1</Tile>\n  <Tile>Tile 2</Tile>\n  <Tile>Tile 3</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Wrap"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, components in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "row"
      }), " will be arranged on one line when possible. Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "wrap"
      }), " prop to wrap components onto new lines when needed."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-row-wrap.png",
        alt: "ui-extrensions-layout-tile-row-wrap"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'between'} wrap={'wrap'} gap={'medium'}>\n  <Tile>One</Tile>\n  <Tile>Two</Tile>\n  <Tile>Three</Tile>\n  <Tile>Four</Tile>\n  <Tile>Five</Tile>\n  <Tile>Six</Tile>\n  <Tile>Seven</Tile>\n  <Tile>Eight</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vertical layout"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To arrange components vertically, set direction to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "column"
      }), ", then use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "align"
      }), " prop to distribute them. By default, components will stretch across the extension container width when ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "align"
      }), " is not specified."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To arrange components vertically at the start of the extension container:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-column-align-start.png",
        alt: "ui-extrensions-layout-tile-column-align-start"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'column'} align={'start'}>\n  <Tile>Tile 1</Tile>\n  <Tile>Tile 2</Tile>\n  <Tile>Tile 3</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To arrange components vertically at the center of the extension container:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-column-align-center.png",
        alt: "ui-extrensions-layout-tile-column-align-center"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'column'} align={'center'}>\n  <Tile>Tile 1</Tile>\n  <Tile>Tile 2</Tile>\n  <Tile>Tile 3</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To arrange components vertically at the end of the extension container:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-column-align-end.png",
        alt: "ui-extrensions-layout-tile-column-align-end"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'column'} align={'end'}>\n  <Tile>Tile 1</Tile>\n  <Tile>Tile 2</Tile>\n  <Tile>Tile 3</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Spacing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " component, you can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "gap"
      }), " prop to apply even spacing between the tiles. This prop will apply spacing equally for both ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "row"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "column"
      }), " directions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/flex-tiles-gap.png",
        alt: "flex-tiles-gap"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex\n  direction={'row'}\n  justify={'start'}\n  gap={'flush' | 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'}\n>\n  <Tile>Tile 1</Tile>\n  <Tile>Tile 2</Tile>\n  <Tile>Tile 3</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Using Flex in Flex"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can wrap child ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " components with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " to set more specific rules for individual components. A child ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " component will not inherit props specified in the parent ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " component, so you'll need to repeat any props you've previously defined to maintain them."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-extension-components-flex-child.png",
        alt: "ui-extension-components-flex-child"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'end'} wrap={'wrap'} gap={'small'}>\n  <Tile>Left</Tile>\n  <Tile>Right</Tile>\n  <Flex direction={'column'}>\n    <Tile>Bottom</Tile>\n  </Flex>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Box"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When wrapping components with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), ", you can further configure individual component layout by wrapping a child of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " in a ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/ui-components/overview#layout-box",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Box"
        }), " component"]
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This component supports the following props. To review all ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " prop definitions, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/overview#layout-box",
        children: "components reference guide"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Values"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignSelf"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "start"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "center"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "baseline"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "end"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "stretch"
                })
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Distributes a child component along the cross-axis using the available free space. Use this prop for nested child ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Flex"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Box"
            }), " components to align them differently from other child components in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Flex"
            }), " group."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flex"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "number value"
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "initial"
                }), " (default)"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "auto"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "none"
                })
              })]
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Distributes components based on the available empty space around them."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "flex"
      }), " prop in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " component to assign any extra spacing to components using either a default value (e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "auto"
      }), ") or a specific number. When using a number, the components will be distributed based on the ratio of their assigned numbers."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the four tiles below take up an increasing amount of space based on their ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "flex"
      }), " values."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-extrensions-layout-box.png",
        alt: "ui-extrensions-layout-box"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'start'} gap={'small'}>\n  <Box flex={1}>\n    <Tile>flex = 1</Tile>\n  </Box>\n  <Box flex={2}>\n    <Tile>flex = 2</Tile>\n  </Box>\n  <Box flex={3}>\n    <Tile>flex = 3</Tile>\n  </Box>\n  <Box flex={4}>\n    <Tile>flex = 4</Tile>\n  </Box>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), ", you only need to wrap components that you want to adjust. For example, if you wrap one component in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "flex"
      }), " value, only that one component will have its width adjusted based on the available empty space."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-extension-components-box-flex.png",
        alt: "ui-extension-components-box-flex"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'start'} gap={'small'}>\n  <Box flex={1}>\n    <Tile>Tile 1</Tile>\n  </Box>\n  <Tile>Tile 2</Tile>\n  <Tile>Tile 3</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["When setting a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "flex"
        }), " value for only one ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Box"
        }), ", you can use any number. This is because any number on its own will result in all available space being assigned to that one component."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "alignSelf"
      }), " prop to override alignment rules for individual ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " components."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/ui-extensions-layout-box-alignself.png",
        alt: "ui-extensions-layout-box-alignself"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'column'} gap={'small'} align={'start'}>\n  <Box alignSelf={'end'}>\n    <Tile>Top right</Tile>\n  </Box>\n  <Box alignSelf={'center'}>\n    <Tile>Middle</Tile>\n  </Box>\n  <Tile>Bottom left</Tile>\n</Flex>\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}