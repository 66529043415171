"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45280489379;
const slug = exports.slug = 'guides/api/crm/objects/line-items';
const title = exports.title = 'API do CRM | Itens de linha';
const name = exports.name = 'LATAM BR (PT) vNext Docs DP - Line Items';
const metaDescription = exports.metaDescription = 'Quando um produto é anexado a um negócio, ele se torna um item de linha. Os pontos de extremidade dos itens de linha permitem gerenciar e sincronizar esses dados.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-um-item-de-linha",
  "label": "Criar um item de linha",
  "parent": null
}, {
  "depth": 0,
  "id": "recuperar-um-item-de-linha",
  "label": "Recuperar um item de linha",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-um-item-de-linha",
  "label": "Criar um item de linha",
  "parent": null
}, {
  "depth": 0,
  "id": "excluir-um-item-de-linha",
  "label": "Excluir um item de linha",
  "parent": null
}, {
  "depth": 0,
  "id": "propriedades-do-item-da-linha",
  "label": "Propriedades do item da linha",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      em: "em",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Itens de linha"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No HubSpot, os itens de linha são instâncias individuais de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/products",
        children: "produtos"
      }), ". Quando um produto é anexado a um negócio, ele se torna um item de linha. Você pode criar itens de linha exclusivos para um orçamento individual, mas eles não serão adicionados à biblioteca de produtos. Os pontos de extremidade dos itens de linha permitem gerenciar esses dados e sincronizá-los entre o HubSpot e outros sistemas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemplo de caso de uso:"
      }), " ao criar um conjunto de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes",
        children: "orçamentos"
      }), " para os representantes de vendas enviarem aos compradores em potencial, você pode usar essa API para criar itens de linha independentes por orçamento, bem como itens de linha anexados a produtos existentes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um item de linha"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar um item de linha, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items"
      }), ". No corpo do post, inclua os detalhes do item de linha, como nome, quantidade e preço."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar um item de linha com base em um produto existente (criado por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/products",
        children: "API de produtos"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
        children: "no HubSpot"
      }), "), inclua ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_product_id"
      }), " no corpo do post."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode associar o item de linha a negócios, orçamentos, faturas, links de pagamento ou assinaturas, incluindo uma matriz de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations"
      }), " no corpo da solicitação post. Por exemplo, o corpo do post abaixo criaria um item de linha chamado \"Novo item de linha independente\", associado a um negócio (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "12345"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/line_item\n{\n  \"properties\": {\n    \"price\": 10,\n    \"quantity\": 1,\n    \"name\": \"New standalone line item\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 12345\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 20\n        }\n      ]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Os itens de linha pertencem a um único objeto principal. Ao associar objetos, os itens de linha devem ser individuais para cada objeto. Por exemplo, se estiver criando um negócio e um orçamento, você deverá criar um conjunto de itens de linha para o negócio e outro conjunto para o orçamento. Isso ajudará a otimizar os dados do CRM em todos os objetos e evitará a perda inesperada de dados se for necessário modificar os itens de linha (por exemplo, excluir um orçamento excluirá os itens de linha dele e, se esses itens de linha estiverem associados a um negócio, os itens de linha do negócio também serão excluídos)."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["O ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "price"
          }), " especificado no campo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "properties"
          }), " ", (0, _jsxRuntime.jsx)("u", {
            children: "não pode"
          }), " ser negativo."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["A propriedade ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Termo"
          }), " dos itens de linha (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_recurring_billing_period"
          }), ") aceita os ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://docs.oracle.com/javase/8/docs/api/java/time/Period.html#:~:text=exceeds%20an%20int-,parse,-public%20static%C2%A0",
            children: "formatos de período ISO-8601"
          }), " de PnYnMnD e PnW."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar um item de linha"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode recuperar itens de linha individualmente ou em massa."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar um item de linha específico, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items/{lineItemId}"
        }), ", onde ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lineItemId"
        }), " é o ID do item de linha."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar todos os itens de linha, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/line_items"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No URL de solicitação, você pode incluir os seguintes parâmetros:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propriedades"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista separada por vírgulas das propriedades a serem retornadas em resposta. Se alguma das propriedades especificadas não estiver presente nos objetos solicitados, ela será ignorada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista separada por vírgulas das propriedades a serem retornadas, junto com seu histórico de valores anteriores. Se alguma das propriedades especificadas não estiver presente nos objetos solicitados, ela será ignorada."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um item de linha"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para atualizar um item de linha, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items/{lineItemId}"
      }), ", onde ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lineItemId"
      }), " é o ID do item de linha."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No corpo do post, inclua os valores de propriedade que você deseja atualizar. Você ", (0, _jsxRuntime.jsx)("u", {
        children: "não pode"
      }), " atualizar associações com esse método. Em vez disso, você precisará usar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de associações"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, o corpo da sua solicitação pode ser parecido com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// PATCH request to https://api.hubapi.com/crm/v3/objects/line_item/{lineItemId}\n{\n  \"properties\": {\n    \"price\": 25,\n    \"quantity\": 3,\n    \"name\": \"Updated line item\"\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Excluir um item de linha"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir um item de linha, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/line_items/{lineItemId}"
      }), ", onde ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lineItemId"
      }), " é o ID do item de linha."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propriedades do item da linha"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao gerenciar os dados do item de linha, convém usar algumas das propriedades comuns da tabela abaixo. Para obter todas as propriedades de item de linha, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/line_item"
      }), ". Saiba mais sobre como usar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API de propriedades"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome da propriedade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Rótulo na UI"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nome"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome do item de linha."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Descrição completa do produto"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sku"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "SKU"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Identificador exclusivo do produto"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_billing_period_start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Data de início da cobrança"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A data de início do período de cobrança definido"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_billing_period_end_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Data de término da cobrança"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A data de término do período de cobrança definido"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recurringbillingfrequency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Frequência de cobrança"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Com que frequência um item de linha com cobrança recorrente é cobrado. Informa o cálculo de preços para negócios e orçamentos. Itens de linha com cobrança única não estão incluídos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quantity"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quantidade"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quantas unidades de um produto estão incluídas nesse item de linha"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Preço unitário"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O custo do produto"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Preço líquido"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O custo total do item de linha (ou seja, a quantidade vezes o preço unitário)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "currency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Moeda"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Código da moeda do item de linha"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}