"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358695;
const slug = exports.slug = 'guides/api/crm/understanding-the-crm';
const title = exports.title = 'API du CRM |  : comprendre le CRM';
const name = exports.name = 'vNextDocs DP -  : comprendre le CRM';
const metaDescription = exports.metaDescription = "Le CRM de HubSpot est un système de gestion de la relation client et des données. Découvrez les types d'objets de CRM, les propriétés, les associations, les propriétaires et les engagements.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "objets",
  "label": "Objets",
  "parent": null
}, {
  "depth": 1,
  "id": "relations-entre-les-objets",
  "label": "Relations entre les objets",
  "parent": "objets"
}, {
  "depth": 1,
  "id": "activit%C3%A9s-et-pi%C3%A8ces-jointes",
  "label": "Activités et pièces jointes",
  "parent": "objets"
}, {
  "depth": 1,
  "id": "synchronisation-des-donn%C3%A9es",
  "label": "Synchronisation des données",
  "parent": "objets"
}, {
  "depth": 1,
  "id": "id-de-type-d-objet",
  "label": "ID de type d'objet",
  "parent": "objets"
}, {
  "depth": 0,
  "id": "actions-par-lot",
  "label": "Actions par lot",
  "parent": null
}, {
  "depth": 0,
  "id": "propri%C3%A9t%C3%A9s",
  "label": "Propriétés",
  "parent": null
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s-par-d%C3%A9faut",
  "label": "Propriétés par défaut",
  "parent": "propri%C3%A9t%C3%A9s"
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s-personnalis%C3%A9es",
  "label": "Propriétés personnalisées",
  "parent": "propri%C3%A9t%C3%A9s"
}, {
  "depth": 1,
  "id": "propri%C3%A9taires-de-fiches-d-informations",
  "label": "Propriétaires de fiches d'informations",
  "parent": "propri%C3%A9t%C3%A9s"
}, {
  "depth": 0,
  "id": "identifiants-uniques-et-id-de-fiche-d-informations",
  "label": "Identifiants uniques et ID de fiche d'informations",
  "parent": null
}, {
  "depth": 1,
  "id": "identifiants-uniques-par-d%C3%A9faut",
  "label": "Identifiants uniques par défaut",
  "parent": "identifiants-uniques-et-id-de-fiche-d-informations"
}, {
  "depth": 1,
  "id": "cr%C3%A9er-vos-propres-identifiants-uniques",
  "label": "Créer vos propres identifiants uniques",
  "parent": "identifiants-uniques-et-id-de-fiche-d-informations"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Comprendre le CRM"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Votre compte HubSpot repose sur une base de données de vos relations et processus\ncommerciaux, appelés CRM (Customer Relationship Management). Pour gérer ces données\nles comptes HubSpot disposent d'objets qui représentent des types de relations ou\nde processus. Les instances individuelles d'objets, appelées fiche d'informations,\nreprésentent les entités individuelles sous chaque type d'objet (par exemple : John\nSmith est une fiche d'informations d'un contact). Pour stocker des données dans chaque\nfiche d'informations, vous utiliserez des propriétés (par exemple : la propriété\ne-mail), et pour représenter les relations entre les entités individuelles, vous\npouvez associer des fiches d'informations les unes aux autres (par exemple : associer\nJohn Smith à une entreprise Smith & Co.) En outre, les fiches d'informations CRM\npeuvent également stocker des informations sur les interactions via des missions/activités\nassociées, telles que des e-mails, des appels et des réunions. Découvrez ci-dessous\nles objets CRM, les fiches d'informations, les propriétés et les fonctionnalités\nsupplémentaires. Pour en savoir plus sur la gestion de votre base de données CRM\nà partir de HubSpot, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/get-started/manage-your-crm-database",
        children: "Base de connaissances de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'API de CRM permet d'accéder aux objets, aux fiches d'informations et aux activités. La liste ci-dessous explique les objets disponibles dans HubSpot. Les objets suivants ont chacun une page ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "index"
      }), " dans HubSpot et peuvent tous être associés les uns aux autres :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Contacts"
        }), " : permet de stocker des informations sur un contact donné. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/contacts",
          children: "Afficher les points de terminaison des contacts"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Entreprises"
        }), " : permet de stocker des informations sur une entreprise ou une organisation donnée. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/companies",
          children: "Afficher les points de terminaison des entreprises"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Transactions"
        }), " : permet de représenter des opportunités de chiffre d'affaires avec un contact ou une entreprise. Elle sont suivies via des étapes d'un pipeline jusqu'à ce qu'elles soient gagnées ou perdues. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/deals",
          children: "Afficher les points de terminaison des transactions"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
          children: "Tickets"
        }), " : permet de représenter des demandes d'aide ou de support. Ils sont suivis via des statuts de pipeline jusqu'à leur fermeture. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/tickets",
          children: "Afficher les points de terminaison des tickets"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/calling/use-the-calling-tool",
          children: "Appels"
        }), " : permet de stocker des informations sur les appels avec des contacts, mais peut également être associé à d'autres objets en tant qu'engagement. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/engagements/calls",
          children: "Affichez les points de terminaison des appels."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-quotes",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Devis"
          })
        }), " : permet de représenter les informations sur les prix partagées avec les acheteurs potentiels. Les devis peuvent être associés à des contacts, des entreprises, des transactions et des lignes de produits. Affichez les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/commerce/quotes",
          children: "points de terminaison des devis"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/use-custom-objects",
          children: "Objets personnalisés"
        }), " (", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Entreprise"
        }), " uniquement) : créez un objet personnalisé pour stocker tous les types de données dans HubSpot, notamment des données qui ne correspondent pas aux objets standards répertoriés ci-dessus. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/custom-objects",
          children: "Afficher les points de terminaison des objets personnalisés"
        }), "Les objets suivants n'ont pas de page index dans HubSpot, mais peuvent être associés à certains autres objets :"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
          children: "Activités/Engagements"
        }), " (appels, e-mails, réunions, notes, tâches, SMS, LinkedIn, WhatsApp, courrier postal) : permet de représenter les interactions associées à vos fiches d'informations. Vous pouvez associer des activités à des contacts, entreprises, transactions, tickets et objets personnalisés. Affichez les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/engagements/engagement-details",
          children: "points de terminaison des engagements."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/how-do-i-use-products",
          children: "Produits"
        }), " : permet de représenter des biens ou des services à vendre. Les produits ne peuvent pas être associés à d'autres objets CRM, mais vous pouvez créer des lignes de produit en fonction des produits et les associer à des transactions et à des devis. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/products",
          children: "Afficher les points de terminaison des produits"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
          children: "Lignes de produit"
        }), " : permet de représenter les produits et services individuels vendus dans une transaction. Les lignes de produit peuvent être créées à partir de produits existants dans votre bibliothèque de produits ou en tant que lignes de produit indépendantes. Les lignes de produits indépendantes ne seront pas ajoutées à votre bibliothèque de produits. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/line-items",
          children: "Afficher les points de terminaison des lignes de produit"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties",
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Soumissions de feedback"
          })
        }), " : permet de stocker les informations soumises lors d'une enquête de feedback. Les commentaires sont associés aux fiches d'informations des contacts. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/feedback-submissions",
          children: "Afficher les points de terminaison de soumission de feedback"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/integrations/use-marketing-events",
          children: "Événements marketing"
        }), " : permet de représenter les événements liés à vos initiatives marketing, notamment les événements issus des intégrations connectées. Vous pouvez spécifier si un contact a assisté, s'est inscrit ou a annulé sa participation à un événement marketing. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/marketing/marketing-events",
          children: "Afficher les points de terminaison des événements marketing"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/invoices",
          children: "Factures"
        }), " : permet de représenter les factures que vous envoyez pour les ventes réalisées. Les factures peuvent être associées à ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "des contacts"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "des entreprises"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "des transactions"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "des lignes de produits"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "des remises"
        }), ",", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: " des frais"
          })
        }), ", et des**", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: " taxes"
        }), "**."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "Paiements"
        }), " : les paiements effectués par les acheteurs via des factures, des liens de paiement et des devis. Les paiements peuvent être associés à", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: " des contacts"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "des entreprises"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "des transactions"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/invoices",
          children: "des factures"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes",
          children: "des devis"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "des lignes de produits"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/subscriptions",
          children: "des abonnements"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "des remises"
        }), ",", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: " des frais"
          })
        }), " et des**", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: " taxes"
        }), ".**"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/subscriptions",
          children: "Abonnements"
        }), " : les paiements récurrents programmés via des liens de paiement et des devis. Les factures peuvent être associées à", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: " des contacts"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/companies",
          children: "des entreprises"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/deals",
          children: "des transactions"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes",
          children: "des devis"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "des lignes de produit"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "des paiements"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/discounts",
          children: "des remises"
        }), ",", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/commerce/fees",
            children: " des frais"
          })
        }), ", et des**", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/taxes",
          children: " taxes"
        }), "**."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-details",
          children: "Utilisateurs"
        }), " : permet de représenter les utilisateurs dans votre compte HubSpot. Les utilisateurs ne peuvent pas être associés à d'autres objets CRM, mais peuvent être récupérés et mis à jour via l'API utilisateur. Vous pouvez également ajouter des utilisateurs à un compte avec ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/settings/users/user-provisioning",
          children: "l'API de provisionnement des utilisateurs"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Relations entre les objets"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans HubSpot, vous pouvez montrer comment les objets sont liés les uns aux autres par des fiches d'informations associées. Par exemple, vous pouvez associer plusieurs contacts à une entreprise, puis associer l'entreprise et les contacts pertinents à une transaction. Tous les comptes HubSpot ont des contacts, des entreprises, des transactions, des tickets et des activités, qui peuvent être associés les uns aux autres, comme indiqué dans le modèle ci-dessous. Si vous avez accès à un compte HubSpot, vous pouvez consulter les relations d'objets uniques de votre compte en accédant à ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-a-model-of-your-crm-object-and-activity-relationships",
        children: "l'outil Modèle de données."
      }), "![ Selon votre abonnement, vous pouvez décrire la relation spécifique entre les fiches d'informations à l'aide de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "libellés d'associations"
      }), ", et votre compte peut avoir des objets personnalisés supplémentaires, qui peuvent être associés aux autres objets standard. D'autres objets, tels que des produits et des lignes de produit, ne peuvent être associés qu'à certains objets. Découvrez-en davantage sur ces ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-objects",
        children: "objets supplémentaires et leurs associations"
      }), ". [Découvrez-en davantage sur les relations d'objet et la gestion des associations en utilisant les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "points de terminaison des associations"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Activités et pièces jointes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "Les engagements"
      }), ", également appelés activités, stockent des données d'interaction avec des fiches d'informations. Par exemple, si vous appelez un prospect, vous pouvez enregistrer un appel dans la fiche d'informations du contact et associer l'appel à son entreprise associée. Les activités possibles comprennent des notes, des tâches, des réunions, des e-mails, des appels, du courrier postal, des SMS, des messages LinkedIn et des messages WhatsApp. Vous pouvez également stocker des pièces jointes sur des fiches d'informations pour suivre les fichiers pertinents. Elles sont souvent liées à des engagements. Découvrez-en davantage sur les API ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "d'engagement."
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Synchronisation des données"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La synchronisation des données d'engagement n'est pas requise pour synchroniser les données d'objet. Comme un objet peut être associé à de nombreux engagements, il est également important de veiller aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "limites d'API"
      }), " avant la synchronisation. Cependant, vous souhaiterez peut-être synchroniser des engagements plutôt que des propriétés lorsqu'une intégration est un précurseur d'une migration complète. Dans ce cas, la synchronisation des engagements sur les deux systèmes garantira que tous les utilisateurs disposent des données nécessaires au cours de la transition. Par exemple, lorsqu'une équipe de développement commercial travaillant dans HubSpot remet des transactions à un représentant commercial interne travaillant dans un autre CRM, vous devez synchroniser les engagements afin que les deux équipes disposent des informations contextuelles nécessaires pour conclure la transaction."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ID de type d'objet"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de l'utilisation de certaines API, vous devrez utiliser le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypeId"
      }), ". Voici les valeurs d'ID pour chaque objet ou activité :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Contacts **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-1"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Entreprises **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-2"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Transactions **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-3"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Tickets **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-5"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Objets personnalisés"
        }), " : pour trouver la valeur d'ID de votre objet personnalisé, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/schemas"
        }), ". La valeur se présente comme suit : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "2-3453932"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Appels **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-48"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**E-mails **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-49"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Réunions **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-47"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Remarques **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-46"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Tâches **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-27"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Produits **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-7"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Factures **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-52"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Lignes de produits **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-8"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Paiements **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-101"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Devis **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-14"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Abonnements **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-69"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Communications"
        }), " (SMS, LinkedIn, messages WhatsApp) : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-18"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Courrier postal **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-116"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Événements marketing"
        }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-54"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Soumissions de feedback **: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-19"
        }), "Bien que vous puissiez toujours utiliser la valeur d'ID numérique, dans certains cas, vous pouvez également utiliser le nom de l'objet pour les contacts, les entreprises, les transactions, les tickets ou les notes. Par exemple :"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lors du démarrage d'un import avec", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/imports",
          children: " l'API d'import"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnObjectTypeId"
        }), " spécifie à quel objet appartiennent les données de votre fichier. Pour importer des données pour les contacts, votre valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnObjectTypeId"
        }), " peut être ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "0-1"
        }), ". Lors de l'utilisation de l'API ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "d'associations"
        }), ", les valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectType"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectType"
        }), " spécifient les objets et la direction de l'association. Pour afficher les types d'associations pour les contacts avec les entreprises, l'URL de votre requête ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " peut être ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/contact/company/labels"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/0-1/0-2/labels"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actions par lot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Chaque objet fournit des points de terminaison de lot qui vous permettent de créer, de lire, de mettre à jour et d'archiver plusieurs fiches d'informations d'objet dans une seule requête. Les points de terminaison de lot ont une limite de 100 fiches d'informations par appel. Par exemple, la demande suivante mettrait à jour par lots deux fiches d'informations d'un contact identifiées par leur ID.", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/batch/update"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body\n{\n  \"inputs\": [\n    {\n      \"id\": \"609051\",\n      \"properties\": {\n        \"custom_text_property\": \"A string value.\"\n      }\n    },\n    {\n      \"id\": \"569751\",\n      \"properties\": {\n        \"custom_text_property\": \"A string value.\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les actions de création par lots, vous pouvez activer les erreurs à statuts multiples qui vous indiquent quelles fiches d’informations ont été correctement créées et lesquelles ne l’ont pas été. Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/other-resources/error-handling#multi-status-errors",
        children: "configuration de la gestion des erreurs à statuts multiples"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Propriétés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les informations sur les fiches d'informations sont stockées dans des champs appelés propriétés, lesquelles sont ensuite organisées en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "groupes"
      }), ". HubSpot fournit un ensemble de propriétés par défaut pour chaque objet. Outre les propriétés par défaut pour chaque objet, vous pouvez stocker des données personnalisées en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "créant des propriétés personnalisées"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés par défaut"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les objets de CRM sont définis par un ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "type"
      }), " principal et un ensemble de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "propriétés"
      }), ". Chaque type contient un ensemble de propriétés standards, représenté par une carte de noms et de valeurs. Découvrez-en davantage sur les propriétés par défaut pour différents objets :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/contacts/hubspots-default-contact-properties",
          children: "Contacts"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/companies/hubspot-crm-default-company-properties",
          children: "Entreprises"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/deals/hubspots-default-deal-properties",
          children: "Transactions"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/tickets/hubspots-default-ticket-properties",
          children: "Tickets"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties",
          children: "Soumissions de feedback"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/invoices/hubspots-default-invoice-properties",
          children: "Factures"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties",
          children: "Outils de paiement"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/hubspots-payments-and-subscriptions-properties",
          children: "Abonnements"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés personnalisées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Créez des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "propriétés personnalisées"
      }), " pour stocker des informations spécialisées pour un objet. Les propriétés personnalisées peuvent être gérées via les points de terminaison des propriétés d'objet de CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétaires de fiches d'informations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez attribuer des utilisateurs HubSpot en tant que ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/how-to-set-an-owner",
        children: "propriétaires"
      }), " de fiches d'informations. Tout utilisateur HubSpot ayant accès à un objet peut être défini en tant que propriétaire et plusieurs propriétaires peuvent être attribués à un objet en créant une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/how-can-i-create-a-custom-owner-property",
        children: "propriété personnalisée"
      }), " à cet effet. Les propriétaires ne peuvent être créés que dans HubSpot, mais vous pouvez utiliser le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/owners",
        children: "point de terminaison des propriétaires"
      }), " pour obtenir des informations sur leur identité, comme des ID ou des adresses e-mail. Ces données peuvent ensuite être attribuées à des fiches d'informations de CRM dans HubSpot ou via des appels d'API de modification de propriété."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identifiants uniques et ID de fiche d'informations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un identifiant unique est une valeur qui différencie une fiche d'information d'une autre dans une base de données, même si celles-ci présentent des informations identiques. Par exemple, une base de données peut avoir des fiches d'informations pour deux personnes appelées John Smith. Pour éviter l'envoi accidentel d'argent au mauvais John Smith, chaque fiche d'informations s'accompagne d'un numéro, d'un ID unique. Dans HubSpot, vous utiliserez ces identifiants uniques pour envoyer vos données vers les bonnes fiches d'informations et gérer la déduplication. Découvrez comment HubSpot gère la déduplication dans la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/deduplication-of-contacts-companies-deals-tickets",
        children: "base de connaissances"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Identifiants uniques par défaut"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'une fiche d'informations est créée dans HubSpot, un ID unique est généré automatiquement et doit être considéré comme une chaîne. Ces ID sont uniques seulement pour chaque type d'objet. Un contact et une entreprise peuvent donc avoir le même ID. Pour les contacts et les entreprises, il existe des identifiants uniques supplémentaires, y compris les éléments ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " d'un contact et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " d'une entreprise."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer vos propres identifiants uniques"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans de nombreux cas, vous pouvez utiliser l'ID de fiche d'informations (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ") généré par HubSpot pour la logique de votre intégration. Cependant, vos données peuvent nécessiter d'autres propriétés avec des valeurs uniques, ou il peut y avoir des moments où l'ID de fiche d'informations ne peut pas être utilisé ou complique la logique d'intégration de votre application. Dans ces cas, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "créer une propriété d'identifiant unique personnalisée via l'API Propriétés."
      }), " Une fois que vous avez créé une propriété d'ID unique personnalisée, vous pouvez l'utiliser dans les appels d'API pour identifier et mettre à jour des fiches d'informations spécifiques de la même manière que vous pouvez utiliser", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " pour les contacts ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "domain"
      }), " pour les entreprises. Par exemple, pour récupérer une transaction en fonction de sa valeur pour une propriété d'ID unique personnalisée, l'URL de votre demande pourrait ressembler à ceci :", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://api.hubapi.com/crm/v3/objects/deals/unique_string",
        children: " https://api.hubapi.com/crm/v3/objects/deals/abc?idProperty=system_a_unique"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}