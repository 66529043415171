"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 48816364594;
const slug = exports.slug = 'guides/cms/content/fields/brand-and-settings-inheritance';
const title = exports.title = 'Herencia de configuración de marca';
const name = exports.name = 'Herencia de configuración de marca';
const metaDescription = exports.metaDescription = 'Aprende a utilizar la configuración de marcas, como logotipos y colores de marcas, dentro de un tema o en archivos HubL/HTML y CSS. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "variables-de-configuraci%C3%B3n-de-marca",
  "label": "Variables de configuración de marca",
  "parent": null
}, {
  "depth": 1,
  "id": "colores",
  "label": "Colores",
  "parent": "variables-de-configuraci%C3%B3n-de-marca"
}, {
  "depth": 1,
  "id": "logotipos",
  "label": "Logotipos",
  "parent": "variables-de-configuraci%C3%B3n-de-marca"
}, {
  "depth": 1,
  "id": "favicons",
  "label": "Favicons",
  "parent": "variables-de-configuraci%C3%B3n-de-marca"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li",
      strong: "strong",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Herencia de configuración de marca"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/customize-branding-for-your-hubspot-content#customize-your-company-logo-and-colors",
        children: "la configuración de marca"
      }), ", los usuarios pueden configurar los colores , logotipos y favicons de la marca de la empresa que se utilizarán en todo el contenido de HubSpot. Esto te permite acceder a esa configuración de marca con tokens en el archivo de un tema ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " y dentro de los archivos HTML/HubL y CSS. También puedes acceder a los colores de las marcas dentro del archivo de un módulo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de agregar estos tokens dentro de un archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ", los creadores de contenido pueden editar sus valores dentro del editor de configuración del tema. Al agregar estas fichas en un HTML, HubL o CSS, los valores estarán codificados de forma rígida y los creadores de contenido no podrán modificarlos en el editor de la página."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, conoce las variables de configuración de marca disponibles junto con ejemplos de implementación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables de configuración de marca"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La siguiente es una lista de opciones a las que se puede acceder desde el área de configuración de la marca dentro del valor del objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property_value_paths"
      }), " o dentro de los archivos HTML/HubL y CSS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Colores"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los colores de la marca se pueden acceder tanto dentro del ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json-color",
        children: "archivo fields.json "
      }), " de un tema o módulo, como dentro de los archivos HTML/HubL y CSS utilizando los siguientes tokens HubL:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Principal:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.primaryColor}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[0]}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-primary.png",
                alt: "marca-colores-primarios"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Secundario:"
          }), " ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{brand_settings.secondaryColor}}"
          })]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-secondary.png",
            alt: "marca-colores-secundario"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Colores de contraste:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor1}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor2}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.accentColor3}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-accent.png",
                alt: "marca-colores-acento"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Colores adicionales:"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[1]}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[2]}}"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.code, {
                children: "{{brand_settings.colors[3]}}"
              })
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/brand-colors-additional.png",
                alt: "marca-colores-adicionales"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para acceder directamente al código hexadecimal de un color, incluye un filtro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hex"
      }), " en el token. Por ejemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.primaryColor.hex}}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para incluir colores de configuración de la marca en el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " de un tema o módulo, utiliza el siguiente formato:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example of using the primary color in within a theme's\n// field.json file\n{\n  \"name\": \"branding_color\",\n  \"label\": \"branding_color\",\n  \"type\": \"color\",\n  \"default\": {\n    \"color\": \"#26ff55\",\n    \"opacity\": 60\n  },\n  \"inherited_value\": {\n    \"property_value_paths\": {\n      \"color\": \"brand_settings.primaryColor\"\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Hay ocasiones en que las cuentas pueden no tener colores adicionales configurados en su configuración de marcas. Si el código hace referencia a un color heredado que no existe en la configuración de la marca, se utiliza la siguiente lógica de reserva:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "secondaryColor"
          }), " vuelve al primer color adicional (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[1]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor1"
          }), " vuelve al segundo color adicional (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[2]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor2"
          }), " vuelve al tercer color adicional (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[3]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "accentColor3"
          }), " vuelve al cuarto color adicional (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[4]"
          }), ")."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Los colores adicionales (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "colors[3]"
          }), ") volverán al valor ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "default"
          }), ". Si no hay una propiedad predeterminada color establecido, se utilizará ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "primaryColor"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Logotipos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se puede acceder a los logotipos de marcas dentro del archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " de un módulo y dentro de los archivos HTML / HubL y CSS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes usar los siguientes tokens para acceder al conjunto de logotipos principales dentro de la configuración de marcas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo}}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.logos[0]}}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/brand-settings-logo0.png",
        alt: "logotipo-de-configuración-de-marca0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se puede acceder a todos los logotipos adicionales mediante ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.logos[1-19]}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además, puede acceder a los siguientes atributos del logotipo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL del logotipo:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.link}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Texto alternativo del logotipot:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.alt}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Altura del logotipo:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.height}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ancho del logotipo:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.width}}"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enlace a la imagen del logotipo:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryLogo.src}}"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Favicons"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sólo se puede acceder a los favicons de marca dentro de archivos HTML/HubL y CSS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes usar los siguientes tokens para acceder al conjunto de logotipos principales dentro de la configuración de marcas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.primaryFavicon}}"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{brand_settings.favicons[0]}}"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/brand-settings-favicon0.png",
        alt: "marca-configuración-favicon0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se puede acceder a todos los favicons adicionales usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.favicons[1-19]}}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes acceder a la URL del logotipo directamente incluyendo un filtro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), ". Por ejemplo:", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{brand_settings.primaryFavicon.src}}"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}