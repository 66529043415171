"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129093135;
const slug = exports.slug = 'guides/cms/setup/github-integration';
const title = exports.title = "Configurer l'intégration continue avec un référentiel GitHub à l'aide des actions GitHub";
const name = exports.name = 'EMEA (FR) Setting up continuous integration with a GitHub repository using GitHub Actions';
const metaDescription = exports.metaDescription = "Configurez l'intégration continue avec un référentiel GitHub à l'aide des actions GitHub.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "envoyer-des-fichiers-locaux-%C3%A0-github",
  "label": "Envoyer des fichiers locaux à GitHub",
  "parent": null
}, {
  "depth": 0,
  "id": "utiliser-l-action-github-de-d%C3%A9ploiement-de-cms-hub-recommand%C3%A9",
  "label": "Utiliser l'action GitHub de déploiement de CMS Hub (recommandé)",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-et-fusionner-une-demande-d-extraction-dans-la-branche-principale",
  "label": "Créer et fusionner une demande d'extraction dans la branche principale",
  "parent": null
}, {
  "depth": 0,
  "id": "verrouiller-votre-ressource-dans-le-gestionnaire-de-conception",
  "label": "Verrouiller votre ressource dans le gestionnaire de conception",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configurer l'intégration continue avec un référentiel GitHub à l'aide des actions GitHub"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le cadre de votre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "workflow de développement"
      }), ", vous préférerez peut-être conserver la source de données de votre base de code de production dans le contrôle des versions. Cela serait particulièrement utile si vous travaillez au sein d'une équipe de développement afin de pouvoir suivre les changements et les annuler rapidement si nécessaire."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À l'aide des actions ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/features/actions",
        children: "GitHub"
      }), ", vous pouvez configurer une intégration continue avec un référentiel GitHub. Ce guide parcourt le processus d'intégration et suppose que vous connaissez :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.github.com/en/get-started/using-git",
          children: "utilisation de Git"
        }), " et GitHub ;"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["la création de sites web à l'aide de l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "interface de ligne de commande HubSpot"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous comment configurer l'intégration à l'aide de l'action GitHub de déploiement de CMS Hub (recommandée) ou manuellement."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Envoyer des fichiers locaux à GitHub"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avant de pouvoir intégrer GitHub, vous devez d'abord rassembler vos fichiers localement."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous disposez d'un actif CMS existant qui réside dans HubSpot, tel qu'un thème ou un ensemble de modèles, vous pouvez le récupérer en exécutant la commande ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#fetch",
          children: "fetch"
        }), " comme suit : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch <HubSpot_src> <local_dest>"
        }), ". Vous pouvez également télécharger tous les fichiers du ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview#developer-file-system",
          children: "système de fichiers développeur"
        }), " du compte en exécutant ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch /"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour créer un nouveau projet local, il est recommandé de commencer par le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "boilerplate de thème CMS"
        }), ". Si vous n'avez jamais travaillé avec le boilerplate de thème CMS, consultez le ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "guide de démarrage rapide"
        }), ". Si vous avez déjà installé l'interface de ligne de commande HubSpot et configuré votre environnement local, vous pouvez créer un nouveau thème local à partir du boilerplate en exécutant ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs create website-theme<new-theme-name>"
        }), ". Vous devrez ensuite télécharger vos fichiers sur HubSpot à l'aide de la commande ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#upload",
          children: "hs upload"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois votre code disponible localement, vous l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://docs.github.com/en/get-started/importing-your-projects-to-github/importing-source-code-to-github/adding-locally-hosted-code-to-github",
        children: "ajouterez à un référentiel GitHub"
      }), ". Après avoir ajouté vos fichiers à GitHub, passez à l'étape suivante pour installer l'action GitHub prédéfinie de HubSpot (recommandée) ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manually-configure-the-action",
        children: "configurer l'action manuellement"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utiliser l'action GitHub de déploiement de CMS Hub (recommandé)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour simplifier le processus, HubSpot a créé une action GitHub que vous pouvez installer sur votre projet GitHub pour gérer automatiquement le déploiement des modifications d'une branche vers votre compte de production HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "e0132707-395d-4617-bd9d-0b21c8b129d5",
      external: true,
      label: "Install automatic CMS deploy GitHub action"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer et fusionner une demande d'extraction dans la branche principale"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Avec vos secrets, vos workflows et vos scripts dans votre référentiel GitHub, créez une demande d'extraction et fusionnez-la dans la branche principale."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Après avoir fusionné la demande d'extraction, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), ". Vous devriez voir votre action de déploiement s'exécuter, ce qui déploiera ensuite votre code sur votre compte HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verrouiller votre ressource dans le gestionnaire de conception"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Maintenant que votre source de données réside dans GitHub, vous devez verrouiller votre ressource dans HubSpot pour empêcher les modifications. Cela garantit que les modifications ne sont apportées que par l'action de déploiement."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour verrouiller des ressources dans le gestionnaire de conception :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fichiers et modèles"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Outils de conception"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Localisez le dossier de votre ressource dans la barre latérale de gauche, puis ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "effectuez un clic droit"
        }), " et sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Verrouiller le dossier"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-lock-folder.png",
        alt: "design-manager-lock-folder"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}