'use es6';

import parse from './parse.js';
import { argumentType } from '../../common/constants.js';
import { formatArgumentKey, formatArgumentValue } from './formatters.js';
import { toSnakeCase } from '../../common/stringutils';
const getAuthParam = ({
  authTypes,
  apiKey,
  oAuthToken
}) => {
  if (oAuthToken) {
    return `access_token="${oAuthToken}"`;
  } else if (apiKey) {
    return `api_key="${apiKey}"`;
  } else {
    if (authTypes.includes('oauth2') || authTypes.includes('oauth2_legacy') || authTypes.includes('private_apps') || authTypes.includes('private_apps_legacy')) {
      return 'access_token="YOUR_ACCESS_TOKEN"';
    } else if (authTypes.includes('developer_hapikey')) {
      return 'api_key="YOUR_DEVELOPER_API_KEY"';
    } else {
      return 'api_key="YOUR_API_KEY"';
    }
  }
};
export default function generate(openApiSpecification, operationId, formData, additionalParams) {
  const {
    sdkCall,
    assignments
  } = parse(openApiSpecification, operationId, formData);
  const imports = assignments.map(el => el.model);
  imports.push('ApiException');
  const sdkMethodPath = [...sdkCall.path, sdkCall.apiName, sdkCall.methodName];
  assignments.push({
    variable: 'api_response',
    model: `client.${sdkMethodPath.map(p => toSnakeCase(p)).join('.')}`,
    args: sdkCall.args
  });
  const authParam = getAuthParam(Object.assign({}, sdkCall, additionalParams));
  const lines = [];
  lines.push('import hubspot');
  lines.push('from pprint import pprint');
  lines.push(`from hubspot.${sdkCall.path.map(p => toSnakeCase(p)).join('.')} import ${Array.from(imports).join(', ')}`);
  lines.push('');
  lines.push(`client = hubspot.Client.create(${authParam})`);
  lines.push('');
  for (const assignment of assignments) {
    assignment.args.filter(arg => arg.type === argumentType.OBJECT).forEach(arg => {
      const objectAssignment = `${formatArgumentKey(arg.key)} = ${JSON.stringify(arg.value || {}, null, 4)}`;
      lines.push(objectAssignment);
    });
    let line = `${formatArgumentKey(assignment.variable)} = ${assignment.model}(`;
    line += assignment.args.map(arg => {
      const key = formatArgumentKey(arg.key);
      const value = formatArgumentValue(arg);
      return `${key}=${value}`;
    }).join(', ');
    line += ')';
    lines.push(line);
  }
  const sdkCallLine = lines.pop();
  lines.push('try:');
  lines.push(`    ${sdkCallLine}`);
  lines.push('    pprint(api_response)');
  lines.push('except ApiException as e:');
  lines.push(`    print("Exception when calling ${toSnakeCase(sdkCall.apiName)}->${toSnakeCase(sdkCall.methodName)}: %s\\n" % e)`);
  return lines.join('\n');
}