import { css } from 'styled-components';

/**
 * The ID for the main content element. Used for a 'skip to main
 * content' link.
 * @see https://www.w3.org/TR/WCAG20-TECHS/G1.html
 */
export const MAIN_CONTENT_ID = 'main-content';

/**
 * Hides element visually but remains readable to assistive tech.
 */
export const visuallyHiddenCss = css(["clip:rect(0 0 0 0);clip-path:inset(50%);height:1px;overflow:hidden;position:absolute;white-space:nowrap;width:1px;"]);