"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79935313525;
const slug = exports.slug = 'guides/cms/content/themes/quotes-themes';
const title = exports.title = 'CMS見積もりテーマを使ってみる | HubSpot（ハブスポット）';
const name = exports.name = 'APAC JAPAN (ja) | Dev. Page | Getting started from the CMS quotes theme | 202209';
const metaDescription = exports.metaDescription = 'HubSpotが提供するCMS見積もりテーマに基づいて、ビジネスに対応したカスタム見積もりテーマを作成でする方法をご覧ください。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%BF%85%E8%A6%81%E6%9D%A1%E4%BB%B6",
  "label": "必要条件",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E3%83%87%E3%82%A3%E3%83%AC%E3%82%AF%E3%83%88%E3%83%AA%E3%83%BC%E3%81%AB%E3%83%86%E3%83%BC%E3%83%9E%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "1. ローカルディレクトリーにテーマを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-%E3%82%A2%E3%83%83%E3%83%97%E3%83%AD%E3%83%BC%E3%83%89%E3%81%97%E3%81%A6%E5%A4%89%E6%9B%B4%E3%82%92%E7%9B%A3%E8%A6%96%E3%81%99%E3%82%8B",
  "label": "2. アップロードして変更を監視する",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%81%AE%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC%E3%82%92%E9%96%8B%E3%81%8F",
  "label": "3. テンプレートのプレビューを開く",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB%E7%92%B0%E5%A2%83%E3%81%A7%E5%A4%89%E6%9B%B4%E3%82%92%E8%A1%8C%E3%81%86",
  "label": "4. ローカル環境で変更を行う",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%83%A9%E3%83%99%E3%83%AB%E3%82%92%E5%A4%89%E6%9B%B4%E3%81%99%E3%82%8B",
  "label": "5. テンプレートラベルを変更する",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-hubspot%E3%81%A7%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%9E%E3%82%A4%E3%82%BA%E3%81%99%E3%82%8B",
  "label": "6. HubSpotで見積もりテンプレートをカスタマイズする",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-%E6%96%B0%E3%81%97%E3%81%84%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "7. 新しいテンプレートを使用して見積もりを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%AC%A1%E3%81%AE%E3%82%B9%E3%83%86%E3%83%83%E3%83%97",
  "label": "次のステップ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84",
  "label": "関連コンテンツ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      code: "code",
      pre: "pre",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS見積もりテーマを使ってみる"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CMS見積もりテーマを使用すると、営業担当者が購入プロセスで使用するカスタム見積もりテーマを作成できます。このガイドでは、CLIを使用して既定のCMS見積もりテーマをローカルで複製し、アカウントに複製したテーマをアップロードし、必要に応じて調整する方法を説明します。また、テンプレートを使用して見積もりを作成し、作業内容を確認する方法をご紹介します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "必要条件"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTMLとCSSを作成するスキル"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["最新バージョンの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/guides/getting-started-with-local-development",
          children: "HubSpot CLIをインストールし、ポータル向けに設定している"
        }), "こと"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**このチュートリアルではHubSpot CLIを使用していますが、必要に応じてデザインマネージャーを使用してHubSpotで全ての作業を行うこともできます。HubSpotでこのプロセスを実行するには、ステップ1に示されている", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetch"
        }), "コマンドを実行する代わりに、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot"
        }), "フォルダーに", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cms-quotes-theme"
        }), "を複製する必要があります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. ローカルディレクトリーにテーマを取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ターミナルを開き、ファイルをダウンロードするディレクトリーに移動します。これは、このチュートリアルの以降の作業で使用する主な作業ディレクトリーです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "既定の見積もりテーマをダウンロードするため、ターミナルで以下を実行します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch @hubspot/cms-quotes-theme \"my-quotes-theme\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これで、ローカル ファイル システムに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-quotes-theme"
      }), "という名前のフォルダーが表示されます。このフォルダーには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "imports"
      }), "フォルダー内のモックデータやモジュールの既定設定など、見積もりテーマに必要な全てのアセットが含まれています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. アップロードして変更を監視する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["フォルダーをダウンロードしたら、HubSpotにアップロードします。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "hs upload"
      }), "コマンドを使用して1回のアップロードを実行できますが、代わりに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), "コマンドを使用して、ファイルを保存するたびに自動アップロードをトリガーすることも可能です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch \"my-quotes-theme\" \"my-quotes-theme\" --initial-upload\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アップロードが完了すると、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/design-manager/",
        children: "デザインマネージャー"
      }), "で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-quotes-theme"
      }), "フォルダーを確認できます。ターミナルからデザインマネージャーを開くには、新しいターミナルタブまたはウィンドウを開き、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs open dm"
      }), "コマンドを実行します。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch"
        }), "プロセスの実行中はコマンドを実行できないため、新しいターミナルタブまたはウィンドウが必要です。また、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "q"
        }), "を押して監視を終了し、別のコマンドを実行してから", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch"
        }), "を再実行することもできます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. テンプレートのプレビューを開く"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "見積もりテンプレートをプレビューするには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "デザインマネージャーで［my-quotes-theme］>［templates］>［bold.html］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "コードエディターの右上にある［プレビュー］クリックし、［表示オプションを使用したライブプレビュー］を選択します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["テンプレートのプレビューが開いたら、ローカルで変更を行います。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch"
      }), "が実行されているため、保存時に自動的にアップロードされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. ローカル環境で変更を行う"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ローカル コード エディターで、［my-quotes-theme］>［css］>［bold.css］を開きます。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["以下のコードを", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "bold.css"
        }), "に追加し、変更を保存します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".line-items__table tbody tr:nth-child(odd) {\n  background-color: #d6d6d6;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ブラウザーでテンプレートプレビューを更新して、CSSの変更を表示します。テーブル本文の全ての奇数行の背景が灰色になっているのが見えます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カスタム見積もりテーマを作成するときに、このワークフローを繰り返すことで、ローカルで行っている変更を迅速に確認できます。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**署名システムは複雑なため、署名はプレビューに表示されません。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. テンプレートラベルを変更する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カスタム見積もりテーマを実際に使用できるように準備する際には、営業担当者がHubSpotの既定の見積もりオプションの中から簡単に見つけることができるように、テンプレートラベルに配慮する必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "見積もりテンプレートのラベルを変更するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "コードエディターで［my-quotes-theme］>［templates］>［bold.html］を開きます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ファイルの先頭でテンプレートアノテーションを確認します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n templateType: quote\n isAvailableForNewContent: true\n label: Bold\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Bold"
        }), "となっている", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), "パラメーターを、使用する名前（**", (0, _jsxRuntime.jsx)(_components.code, {
          children: "My custom quote template"
        }), "**など）に更新します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ファイルを保存してHubSpotにアップロードします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. HubSpotで見積もりテンプレートをカスタマイズする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["営業担当者が見積もりテンプレートを使用する前に、HubSpotでテンプレートをカスタマイズしておく必要があります。この作業は通常、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/deals/create-custom-quote-templates",
        children: "営業チーム向けの既成の見積もりを作成"
      }), "できるようにするために、営業マネージャーが行います。ただしこのチュートリアルでは、コンテンツの作成作業について誰もが理解できるように、このプロセスを行う方法を説明します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "見積もりテンプレートをカスタマイズして営業担当者が利用できるようにするには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotアカウントで、メイン ナビゲーション バーにある", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "設定アイコン"
        }), "をクリックします。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左側のサイドバーメニューで、［オブジェクト］＞［見積もり］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［見積もりテンプレート］タブをクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上にある［見積もりテンプレートをカスタマイズ］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["新しい", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "テンプレート"
        }), "にカーソルを合わせ、［選択］を選択します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["左側のパネルで、テンプレートに含まれるモジュールを編集できます。例えば、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "モジュール"
        }), "をクリックしてそのプロパティーを編集したり、表示を切り替えたりできます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/quote-template-toggle-visibility.png",
        alt: "quote-template-toggle-visibility"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "変更が完了したら、右上の［保存］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. 新しいテンプレートを使用して見積もりを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "変更を保存したら、テンプレートを使用して見積もりを作成できます。これにより、営業担当者の作業をシミュレートします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントで［セールス］＞［見積もり］の順に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上にある［見積もりを作成］をクリックします。その後、見積もり作成ウィザードにリダイレクトされます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "最初の画面で［取引と関連付ける］ドロップダウンメニューをクリックしてから、既存の取引を選択するか、テスト取引を使用する場合は［新規の取引を作成］を選択します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右下の［次へ］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "次の画面で［見積もり］ドロップダウンメニューをクリックし、カスタム見積もりテンプレートを選択します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "見積もりウィザードの残りの部分に進み、見積もりを作成します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "見積もりを公開すると、見積もりを表示するためのリンクを含むダイアログボックスが表示されます。［コピー］をクリックしてURLをコピーし、ブラウザーに貼り付けます。完了した見積もりが表示されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-quote-url.png",
        alt: "copy-quote-url"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "次のステップ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "見積もりテンプレートを作成、アップロード、カスタマイズしました。これで、見積もりテンプレートの編集プロセスとコンテンツ作成者の作業を理解できました。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ビジネスニーズに合わせて見積もりテンプレートを作成する際には、HubSpotの既定のモジュールとともに、独自のカスタムモジュールを見積もりに追加することがあります。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**支払い、署名、およびダウンロードモジュールのJavaScriptは編集", (0, _jsxRuntime.jsx)("u", {
          children: "しない"
        }), "ことをお勧めします。編集するとモジュールが破損する可能性があります。破損した場合、エンドユーザーは署名やダウンロードを実行できず、さらには支払いを行うことができない可能性があります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連コンテンツ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/quotes",
          children: "見積もりテンプレートをカスタマイズ"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/quote-variables",
          children: "カスタム見積もり変数のリファレンス"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/ja/quotes/create-custom-quote-templates",
          children: "カスタマイズされた見積もりテンプレートの作成と使用（セールス、セールスオペレーション／マネージャー向け）"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}