"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358754;
const slug = exports.slug = 'guides/api/crm/objects/contacts';
const title = exports.title = 'CRM API | Contacts';
const name = exports.name = 'vNext Docs DP - Contacts';
const metaDescription = exports.metaDescription = 'Contact records store information about individuals. The contacts endpoints allow you to manage this data and sync it between HubSpot and other systems.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-contacts",
  "label": "Create contacts",
  "parent": null
}, {
  "depth": 1,
  "id": "properties",
  "label": "Properties",
  "parent": "create-contacts"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "create-contacts"
}, {
  "depth": 0,
  "id": "retrieve-contacts-by-record-id%2C-email%2C-or-custom-unique-value-property",
  "label": "Retrieve contacts by record ID, email, or custom unique value property",
  "parent": null
}, {
  "depth": 0,
  "id": "update-contacts",
  "label": "Update contacts",
  "parent": null
}, {
  "depth": 0,
  "id": "upsert-contacts",
  "label": "Upsert contacts",
  "parent": null
}, {
  "depth": 0,
  "id": "associate-existing-contacts-with-records-or-activities",
  "label": "Associate existing contacts with records or activities",
  "parent": null
}, {
  "depth": 1,
  "id": "remove-an-association",
  "label": "Remove an association",
  "parent": "associate-existing-contacts-with-records-or-activities"
}, {
  "depth": 0,
  "id": "pin-an-activity-on-a-contact-record",
  "label": "Pin an activity on a contact record",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-contacts",
  "label": "Delete contacts",
  "parent": null
}, {
  "depth": 0,
  "id": "secondary-emails",
  "label": "Secondary emails",
  "parent": null
}, {
  "depth": 0,
  "id": "limits",
  "label": "Limits",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Contacts"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In HubSpot, contacts store information about the individual people that interact with your business. The contacts endpoints allow you to create and manage contact records in your HubSpot account, as well as sync contact data between HubSpot and other systems."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about objects, records, properties, and associations APIs in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Understanding the CRM"
      }), " guide. For more general information about objects and records in HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://knowledge.hubspot.com/contacts-user-guide",
        children: "learn how to manage your CRM database"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create contacts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create new contacts, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In your request, include your contact data in a properties object. You can also add an associations object to associate your new contact with existing records (e.g., companies, deals), or activities (e.g., meetings, notes)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Contact details are stored in contact properties. There are ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/hubspots-default-contact-properties",
        children: "default HubSpot contact properties"
      }), ", but you can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "create custom contact properties"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When creating a new contact, you should include ", (0, _jsxRuntime.jsx)("u", {
        children: "at least one"
      }), " of the following properties in your request: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastname"
      }), ". It is recommended to always include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", because email address is the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/deduplication-of-contacts-companies-deals-tickets#automatic-deduplication-in-hubspot",
        children: "primary unique identifier"
      }), " to avoid duplicate contacts in HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all available properties, you can retrieve a list of your account's contact properties by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/contacts"
      }), ". Learn more about the the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": if you've included ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lifecyclestage"
        }), " in your request, values must refer to the lifecycle stage's internal name. The internal names of default stages are ", (0, _jsxRuntime.jsx)("u", {
          children: "text"
        }), " values, and do not change even if you edit the stage's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/manage-your-properties#:~:text=the%20properties%20settings.-,Label/Name%3A,-enter%20a%20unique",
          children: "label"
        }), "(e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriber"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "marketingqualifiedlead"
        }), "). The internal names of ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages",
          children: "custom stages"
        }), "are ", (0, _jsxRuntime.jsx)("u", {
          children: "numeric"
        }), " values. You can find a stage's internal ID in your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-customize-lifecycle-stages#:~:text=To%20edit%20a%20lifecycle%20stage%2C%20hover%20over%20the%20stage%20and%20click%20Edit.%20In%20the%20right%20panel%2C%20edit%20the%20Stage%20name%2C%20then%20click%20Edit%20lifecycle%20stage%20to%20confirm.%20Click%20the%20code%20codcode%20icon%20to%20view%20the%20stage%27s%20internal%20ID%2C%20which%20is%20used%20by%20integrations%20and%20APIs.",
          children: "lifecycle stage settings,"
        }), "or by retrieving the lifecycle stage property via API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to create a new contact, your request may look similar to the following:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"company\": \"HubSpot\",\n    \"website\": \"hubspot.com\",\n    \"lifecyclestage\": \"marketingqualifiedlead\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When creating a new contact, you can also associate the contact with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "existing records"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "activities"
      }), " by including an associations object. For example, to associate a new contact with an existing company and email, your request would look like the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"company\": \"HubSpot\",\n    \"website\": \"hubspot.com\",\n    \"lifecyclestage\": \"marketingqualifiedlead\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 279\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 556677\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 197\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the associations object, you should include the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The record or activity you want to associate with the contact, specified by its unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " value."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of the association between the contact and the record/activity. Include the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Default association type IDs are listed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
              children: "here"
            }), ", or you can retrieve the value for custom association types (i.e. labels) via the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#retrieve-association-types",
              children: "associations API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve contacts by record ID, email, or custom unique value property"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can retrieve contacts individually or in batches."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve an individual contact, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{contactId} or"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{email}?idProperty=email"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To request a list of all contacts, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For these endpoints, you can include the following query parameters in the request URL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the properties to be returned in the response. If the requested contact doesn't have a value for a property, it will not appear in the response."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the current and historical properties to be returned in the response. If the requested contact doesn't have a value for a property, it will not appear in the response."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A comma separated list of objects to retrieve associated IDs for. Any specified associations that don't exist will not be returned in the response. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4",
              children: "associations API."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a batch of specific contacts by record ID, email address, or a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "custom unique identifier property"
        }), ", make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/contacts/batch/read"
        }), ". The batch endpoint ", (0, _jsxRuntime.jsx)("u", {
          children: "cannot"
        }), " retrieve associations. Learn how to batch read associations with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4",
          children: "associations API"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For the batch read endpoint, you can use the optional ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " parameter to retrieve contacts by ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " or a custom ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "unique identifier property"
      }), ". By default, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " values in the request refer to the record ID (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), so the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " parameter is not required when retrieving by record ID. If you're using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " or a custom unique value property to retrieve contacts, you must include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to retrieve a batch of contacts based on their record ID values, your request could look like the following (current values only, or current and historical values):"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body with record ID (current values)\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"inputs\": [\n    {\n      \"id\": \"1234567\"\n    },\n    {\n      \"id\": \"987456\"\n    }\n  ]\n}\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"lifecyclestage\", \"hs_lead_status\"],\n  \"inputs\": [\n    {\n      \"id\": \"1234567\"\n    },\n    {\n      \"id\": \"987456\"\n    }\n  ]\n}\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To retrieve contacts based on email address or a custom unique identifier property (e.g., a customer ID number unique for your business), your request would look like:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with email address\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"idProperty\": \"email\",\n  \"inputs\": [\n    {\n      \"id\": \"lgilmore@thedragonfly.com\"\n    },\n    {\n      \"id\": \"sstjames@thedragonfly.com\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example request body with a unique value property\n{\n  \"properties\": [\"email\", \"lifecyclestage\", \"jobtitle\"],\n  \"idProperty\": \"internalcustomerid\",\n  \"inputs\": [\n    {\n      \"id\": \"12345\"\n    },\n    {\n      \"id\": \"67891\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update contacts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can update contacts individually or in batches."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update ", (0, _jsxRuntime.jsx)("u", {
        children: "individual"
      }), " contacts, you can use record ID (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ") or the contact's email address (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To update an individual contact by its record ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{contactId}"
        }), ", and include the data you want to update."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To update an individual contact by its email, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " request to", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/contacts/{email}?idProperty=email"
        }), ", and include the data you want to update."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body with record ID\n// PATCH /crm/v3/objects/contacts/123456789\n{\n  \"properties\": {\n    \"favorite_food\": \"burger\",\n    \"jobtitle\": \"Manager\",\n    \"lifecyclestage\": \"Customer\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": if updating the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "lifecyclestage"
        }), " property, you can only set the value ", (0, _jsxRuntime.jsx)("u", {
          children: "forward"
        }), " in the stage order. To set the lifecycle stage backward, you'll first need to clear the record's existing lifecycle stage value. The value can be ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/records/update-a-property-value-for-a-record",
          children: "cleared manually"
        }), ", or may be automatically cleared via a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/records/change-record-lifecycle-stages-in-bulk",
          children: "workflow"
        }), " or an integration that syncs contact data."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update contacts in ", (0, _jsxRuntime.jsx)("u", {
        children: "batches"
      }), ", you can use the contacts' record ID values (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "). To update multiple contacts, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/batch/update"
      }), ". In your request body, include each contact's record ID as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " ​and include the properties you want to update."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n// POST /crm/v3/objects/contacts/batch/update\n{\n  \"inputs\": [\n    {\n      \"id\": \"123456789\",\n      \"properties\": {\n        \"favorite_food\": \"burger\"\n      }\n    },\n    {\n      \"id\": \"56789123\",\n      \"properties\": {\n        \"favorite_food\": \"Donut\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Upsert contacts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also batch create and update contacts at the same time using the upsert endpoint. For this endpoint, you can use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " or a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "custom unique identifier property"
      }), ". Following the request, if the contacts already exist, they'll be updated and if the contacts don't exist, they'll be created."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To upsert contacts, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/batch/upsert"
      }), ". In your request body, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " parameter to identify whether you're using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " or a custom unique identifier property. Include that property's value as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " ​and add the other properties you want to set or update."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, your request could look like the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body with email\n// POST /crm/v3/objects/contacts/batch/upsert\n{\n  \"inputs\": [\n    {\n      \"properties\": {\n        \"phone\": \"5555555555\"\n      },\n      \"id\": \"test@test.com\",\n      \"idProperty\": \"email\"\n    },\n    {\n      \"properties\": {\n        \"phone\": \"7777777777\"\n      },\n      \"id\": \"example@hubspot.com\",\n      \"idProperty\": \"email\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associate existing contacts with records or activities"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To associate a contact with other CRM records or an activity, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To retrieve the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), " value, refer to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
          children: "this list"
        }), " of default values, or make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "associations API."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remove an association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove an association between a contact and a record or activity, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to the following URL: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactID}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pin an activity on a contact record"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "pin an activity"
      }), " on a contact record by including the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " field in your request. In the field, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " of the activity to pin, which can be retrieved via the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "engagements APIs"
      }), ". You can pin one activity per record, and the activity must already be associated with the contact prior to pinning."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To set or update a contact's pinned activity, your request could look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body PATCH /crm/v3/objects/contacts/{contactId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also create a contact, associate it with an existing activity, and pin the activity in the same request. For example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body POST /crm/v3/objects/contacts\n{\n  \"properties\": {\n    \"email\": \"example@hubspot.com\",\n    \"firstname\": \"Jane\",\n    \"lastname\": \"Doe\",\n    \"phone\": \"(555) 555-5555\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 201\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete contacts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can delete contacts individually or in batches, which will add the contact to the recycling bin in HubSpot. You can later ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "restore the contact within HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete an individual contact by its ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch deleting contacts in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/crm/objects/contacts",
        children: "reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Secondary emails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/add-multiple-email-addresses-to-a-contact",
        children: "Secondary email addresses"
      }), " are used when a contact has more than one email. Additional emails are still unique identifiers for contacts, so multiple contacts cannot have the same secondary email address."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To view secondary emails for contacts, when you retrieve all or individual contacts, include the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), " parameter with the properties ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "email"
        }), " and ", (0, _jsxRuntime.jsxs)(_components.strong, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_additional_emails"
          }), "."]
        }), " A contact's primary email address will be displayed in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "email"
        }), " field and additional emails will be displayed in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_additional_emails"
        }), " field."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To add or manage secondary emails for a contact, you can use the V1 secondary email endpoints in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/beta-docs/reference/api/crm/objects/contacts/v1",
          children: "this reference guide"
        }), ". In the V3 contacts endpoints, the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_additional_emails"
        }), " field is read only."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limits"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Batch operations are limited to 100 records at a time. For example, you cannot batch update more than 100 contacts in one request. There are also limits for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/api/faq#limits_contacts",
        children: "contacts and form submissions"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}