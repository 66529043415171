"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 82807981634;
const slug = exports.slug = 'guides/cms/content/fields/write-fields-using-javascript';
const title = exports.title = 'Write module and theme fields using JavaScript';
const name = exports.name = 'Write module and theme fields using JavaScript';
const metaDescription = exports.metaDescription = "Learn how to write a module's fields.json file using JavaScript.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "overview",
  "label": "Overview",
  "parent": null
}, {
  "depth": 0,
  "id": "requirements",
  "label": "Requirements",
  "parent": null
}, {
  "depth": 0,
  "id": "cli-commands",
  "label": "CLI commands",
  "parent": null
}, {
  "depth": 1,
  "id": "check-javascript-file-locally",
  "label": "Check JavaScript file locally",
  "parent": "cli-commands"
}, {
  "depth": 1,
  "id": "upload-to-hubspot",
  "label": "Upload to HubSpot",
  "parent": "cli-commands"
}, {
  "depth": 0,
  "id": "examples",
  "label": "Examples",
  "parent": null
}, {
  "depth": 1,
  "id": "regular-object",
  "label": "Regular object",
  "parent": "examples"
}, {
  "depth": 1,
  "id": "common-fields",
  "label": "Common fields",
  "parent": "examples"
}, {
  "depth": 1,
  "id": "change-fields-based-on-passed-options",
  "label": "Change fields based on passed options",
  "parent": "examples"
}, {
  "depth": 1,
  "id": "add-json-from-other-files",
  "label": "Add JSON from other files",
  "parent": "examples"
}, {
  "depth": 1,
  "id": "make-async-calls",
  "label": "Make async calls",
  "parent": "examples"
}, {
  "depth": 1,
  "id": "use-external-field-object-libraries",
  "label": "Use external field object libraries",
  "parent": "examples"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      ol: "ol",
      a: "a",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Write module and theme fields using JavaScript"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Typically, module and theme fields are configured in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file. However, if you prefer to not use JSON, you can configure your fields with a JavaScript file instead. Writing fields with JavaScript enables you to abstract fields that you use often, dynamically generate new fields, and more easily update existing fields."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, when building a set of modules, you can abstract your module fields into partials which are then pulled into individual modules. By building modules that pull from one source of truth, you’ll no longer need to update those common fields in each module individually."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn how to write fields using JavaScript, including the necessary CLI commands, along with some examples to get you started."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Overview"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "At a high level, to write fields using JavaScript:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the module folder or project root, include a JavaScript fields file instead of the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " file. You can use any of the following extensions:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fields.js"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fields.cjs"
            })
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "fields.mjs"
            }), " (requires Node 13.2.0+ to be installed)."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Optionally, check your JavaScript before uploading to HubSpot by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs cms convert-fields"
        }), ", which will save the output of the fields file locally as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.output.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Upload the asset to HubSpot through the CLI by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch"
        }), " with a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "--convertFields"
        }), " flag. On upload, HubSpot will convert the JavaScript file to a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " file."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " when HubSpot converts the JavaScript file into the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " file, it does ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " store the original JavaScript file. To maintain a source of truth, it's recommended to use a version control system like Git."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Requirements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To use a JavaScript field file to generate fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The JavaScript fields file must be contained in a module folder or at the project root to be treated as the global ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " file."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The module or theme must not also include a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " file. Including both will prompt you to select one or the other at upload."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The JavaScript fields file must export a function as its default export. The exported function can accept an array of strings called ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fieldOptions"
        }), ", and must return an array of objects. Each entry in the returned array must be one of the following:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A JavaScript object with ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields",
          children: "valid field key-value pairs"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A JavaScript object with a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".toJSON()"
        }), " method. When run, this method must return a value that meets criterion 1."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Promise"
        }), " that resolves to a JavaScript object that meets criterion 1."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CLI commands"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use the following CLI commands when writing module fields with JavaScript."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Check JavaScript file locally"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Check your work locally by running ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs cms convert-fields"
      }), ", which saves the output of your JavaScript fields file as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.output.json"
      }), " in the same directory as the JavaScript fields file."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hs cms convert-fields"
      }), " accepts the following flags:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Flag"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "--src"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The location of the JavaScript fields file, or a directory."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "--fieldOptions=[options]"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Accepts a space-separated list that will then be passed to every exported JavaScript fields function as an array before compile-time.For example, you can configure fields to have different labels depending on whether a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--fieldOptions=qa"
            }), " flag is set.", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#change-fields-based-on-passed-options",
              children: "View an example of this below"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Upload to HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Upload to HubSpot to begin the process of converting the JavaScript file to a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file by running either ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch"
      }), " accept the following flags:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Flag"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "--convertFields"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enables JavaScript fields functionality."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "--saveOutput"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Saves the JavaScript fields file output as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fields.output.json"
            }), " in the same directory as the JavaScript fields file. If not included, HubSpot will save the output of the JavaScript fields file to a temporary directory then delete it after upload."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "--fieldOptions=[options]"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Accepts a space-separated list that will then be passed to every exported JavaScript fields function as an array before compile-time.For example, you can configure fields to have different labels depending on whether a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--fieldOptions=qa"
            }), " flag is set.", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#change-fields-based-on-passed-options",
              children: "View an example of this below"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Examples"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, review examples of using JavaScript to write fields files."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Regular object"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.js"
      }), " file writes a regular object:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "module.exports = (fieldOptions) => {\n  return [\n    {\n      required: true,\n      locked: false,\n      help_text: '',\n      inline_help_text: '',\n      name: 'button_text',\n      label: 'Button text',\n      type: 'text',\n      default: 'Add a button link here',\n    },\n  ];\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Common fields"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.js"
      }), " file creates a set of common fields to be used across multiple modules:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const setFieldParams = (field, params) => {\n  return { ...field, ...params };\n};\n\nconst defaultRichTextField = {\n  type: 'richtext',\n  enabled_features: [\n    'font_size',\n    'standard_emphasis',\n    'block',\n    'font_family',\n    'alignment',\n  ],\n  display_width: null,\n  required: false,\n  locked: false,\n};\n\nmodule.exports = (fieldOptions) => {\n  let fields = [\n    setFieldParams(defaultRichTextField, {\n      name: 'tier',\n      label: 'Product tier',\n      default: '<h2>Free</h2>',\n    }),\n\n    setFieldParams(defaultRichTextField, {\n      name: 'description',\n      label: 'Product description',\n      default:\n        '<p>For teams that need additional security, control, and support.</p>',\n    }),\n  ];\n\n  return fields;\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Change fields based on passed options"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.js"
      }), " file changes the module's fields based on whether the"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "--fieldOptions=[qa]"
      }), " flag was included when running ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs cms convert-fields"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "module.exports = (fieldOptions) => {\n\tlet fields = [...]\n\n\tif(fieldOptions.includes('qa')) {\n\t    fields = fields.map((field) => {\n\t      field[\"name\"] += \"_qa\";\n\t      return field;\n\t    })\n\t  }\n\t}\n\n\treturn fields\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add JSON from other files"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.js"
      }), " file includes styles from a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "styles.json"
      }), " file:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const fs = require('fs')\n\nmodule.exports = (fieldOptions) => {\n\tconst fields = [...]\n\n\tconst styles = JSON.parse(fs.readFileSync('../../json/styles.json'))\n\n\treturn [fields, styles]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Make async calls"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.js"
      }), " file includes an asynchronous call by setting the exported function as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "async"
      }), ". If you return a Promise, the CLI will wait for the Promise to resolve before writing the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "module.exports = async (fieldOptions) => {\n  const httpField = fetch('https://example.org/example.json').then((resp) =>\n    resp.json()\n  );\n\n  return [httpField];\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Use external field object libraries"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.js"
      }), " file includes an external library. Libraries such as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/iGoMoon/hubspot-tools/tree/main/packages/hubspot-fields-js",
        children: "@iGoMoon/hubspot-fields-js"
      }), " are supported, as their fields objects expose a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".toJSON()"
      }), " function."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const { Field, Group } = require('@iGoMoon/hubspot-fields-js');\n\nmodule.exports = (fieldOptions) => {\n  return [\n    Field.text()\n      .name('button_text', 'Button text')\n      .required(true)\n      .default('Add a button link here'),\n\n    new Group().children([\n      Field.boolean()\n        .id('1')\n        .name('enable', 'Enable Field')\n        .set('display', 'toggle')\n        .default(true),\n      Field.number().name('number', 'Number Fields'),\n      Field.text()\n        .name('css_class_name', 'CSS Class')\n        .set('validation_regex', '-?[a-zA-Z]+[a-zA-Z0-9- ]+')\n        .inlineHelpText('Enter a CSS class for additional styling'),\n    ]),\n  ];\n};\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}