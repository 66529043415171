"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921778;
const slug = exports.slug = 'guides/api/library/files';
const title = exports.title = 'CMS API | File Manager';
const name = exports.name = 'vNext Docs DP - File Manager';
const metaDescription = exports.metaDescription = 'The files endpoints are used to get and manage data in your file manager .';
const toc = exports.toc = [{
  "depth": 0,
  "id": "upload-a-file",
  "label": "Upload a file",
  "parent": null
}, {
  "depth": 0,
  "id": "check-a-file-s-upload-status",
  "label": "Check a file's upload status",
  "parent": null
}, {
  "depth": 0,
  "id": "view-a-file-s-details",
  "label": "View a file's details",
  "parent": null
}, {
  "depth": 0,
  "id": "delete-a-file",
  "label": "Delete a file",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-folder",
  "label": "Create a folder",
  "parent": null
}, {
  "depth": 0,
  "id": "changes-in-v3",
  "label": "Changes in v3",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "File Manager"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use HubSpot’s files tool to manage and store files in HubSpot. Files hosted in HubSpot can be uploaded and used in both HubSpot and external content. They can also be attached to records using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "engagements API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If your company is building its website using HubSpot's CMS, you can use the files API to upload and store assets in HubSpot. These files can then be served and shared through the HubSpot CMS."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can access the files tool from ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/files/upload-files-to-use-in-your-hubspot-content",
        children: "within HubSpot"
      }), " or via the files API. Below, learn about the files API and how to upload and delete files. For a full list of files API endpoints, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/library/files",
        children: "endpoints reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Upload a file"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Files can be uploaded using a multipart/form-data ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files"
      }), " with the following fields. While a specific folder ID is not required at upload, it's recommend to upload files into a folder and not the root directory. Folder requirements at upload are subject to change in the future."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The file to upload, this is required."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A JSON object that controls the file's privacy and indexability. This contains two fields: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "access"
            }), ", which is required, and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ttl"
            }), ", which specifies the time period after which the file will be automatically deleted.", (0, _jsxRuntime.jsx)("br", {}), "If you're using the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ttl"
            }), " field:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "The minimum period that must be set is 1 day."
              }), (0, _jsxRuntime.jsx)("li", {
                children: "The maximum period that can be set is 1 year."
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["After the set period, the file will be deleted and moved to ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/files/archive-delete-restore-files#restore-deleted-files",
                  children: "files trash"
                }), ". You can still restore files from files trash within 30 days of deletion. After 30 days, deleted files will be permanently deleted and cannot be restored. "]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the folder that the file will be uploaded to. Either this field ", (0, _jsxRuntime.jsx)("u", {
              children: "or"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderPath"
            }), " must be provided in your request (but ", (0, _jsxRuntime.jsx)("u", {
              children: "not"
            }), " both)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderPath"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The path of the folder that the file will be uploaded to. Either this field ", (0, _jsxRuntime.jsx)("u", {
              children: "or"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "folderId"
            }), " must be provided in your request (but ", (0, _jsxRuntime.jsx)("u", {
              children: "not"
            }), " both)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fileName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the file. If no name is specified, a name will be generated from the file's content."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "charsetHunch"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Character set encoding for the uploaded file. If not provided, it will be derived from the file."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As an example, if you wanted to upload a file with the following criteria to your HubSpot account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "File name:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cat.png"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Destination folder in the HubSpot file manager:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/library/cat_archive"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "File accessibility in HubSpot:"
        }), " privately accessible"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following headers and request body would need to be part of your request:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl --request POST \\\n  --url 'https://api.hubapi.com/files/v3/files?=' \\\n  --header 'Authorization: Bearer pat-na1-00000000-0000-0000-0000-000000000000' \\\n  --header 'Content-type: multipart/form-data' \\\n  --form file=@/Users/person/Downloads/cat.png \\\n  --form 'options={\"access\": \"PRIVATE\"}' \\\n  --form folderPath=/library/cat_archive\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The resulting response will include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "parentFolderId"
      }), " of the uploaded file, which you can use to retrieve the file via a GET request."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// 201 Response from successful file upload\n{\n  \"id\": \"122692044085\",\n  \"createdAt\": \"2023-06-28T17:56:45.393Z\",\n  \"updatedAt\": \"2023-06-28T17:56:45.393Z\",\n  \"archived\": false,\n  \"parentFolderId\": \"122692510820\",\n  \"name\": \"cat\",\n  \"path\": \"/library/cat_archive/cat.png\",\n  \"size\": 24574,\n  \"height\": 219,\n  \"width\": 225,\n  \"encoding\": \"png\",\n  \"type\": \"IMG\",\n  \"extension\": \"png\",\n  \"defaultHostingUrl\": \"https://12345.fs1.hubspotusercontent-na1.net/hubfs/12345/library/cat_archive/cat.png\",\n  \"url\": \"https://12345.fs1.hubspotusercontent-na1.net/hubfs/12345/library/cat_archive/cat.png\",\n  \"isUsableInContent\": true,\n  \"access\": \"PRIVATE\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Check a file's upload status"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're importing a file from a URL to your file manager using a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/import-from-url/async"
      }), ", you can review the upload status of the file."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To do so, use a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/import-from-url/async/tasks/{taskId}/status"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After making this request, you will receive one of the following replies:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING"
        }), ": the file is in the queue to be uploaded. The import process has not yet started."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), ": the file is in the process of being uploaded."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELED"
        }), ": the upload has been canceled and the file will not be uploaded. To import the file to your HubSpot account, you will need to upload the file again."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "COMPLETE"
        }), ": the file has been uploaded to the files tool successfully. The uploaded file will appear in your files tool."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View a file's details"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To review the details of a file that's been uploaded to the files tool, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}"
      }), ". This will return the file with details such as name, height and width, encoding, the URL, and more."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, to retrieve the details of a file:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If a file is set to private, the returned URL will result in a 404 error. To get a viewable URL of the file, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/files/v3/files/{fileId}/signed-url"
      }), ". When making this request, you can include ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property"
      }), " parameters to return specific properties such as height and width."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete a file"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete a file, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}"
      }), ". This will mark the file as deleted and make the content of the file inaccessible."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To permanently delete a file, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/files/{fileId}/gdpr-delete"
      }), ". This will permanently delete the file’s content and metadata within 7 days."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If a file is not GDPR deleted, its contents will remain on HubSpot's servers in a private state where no one can access it. To ensure file contents are fully deleted, use the GDPR delete functionality."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a folder"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create a folder, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "files/v3/folders"
      }), ". When making the request, you can include the below fields."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Required"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Yes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Name of the folder you want to create."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["To create the folder within an existing folder, include this field with the existing folder's ID. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            }), " cannot be set at the same time."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["To create the folder within an existing folder, include this field with the existing folder's path. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderId"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "parentFolderPath"
            }), " cannot be set at the same time."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request body of POST request to /files/v3/folders\n{\n  \"name\": \"myNewFolder\",\n  \"parentFolderId\": 12345\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Changes in v3"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you’ve been using the previous version of this API, v3 has the following changes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "All files uploaded through the API will be visible in the files dashboard and the files picker. Hidden files cannot be created. However, private files and non-indexable files can still be created."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Listing files will not return hidden or deleted files. However, a much broader range of filters can be applied. Hidden files can still be fetched by ID, but require a new scope: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "files_ui_hidden.read."
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Multiple files cannot be uploaded with a single request."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Folder update actions like moving and renaming are now asynchronous. Each request will return a token that can be used to check the status of the folder edit."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Endpoints that create or replace files require you to provide access levels for the files. These access levels are:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLIC_INDEXABLE"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " file is publicly accessible by anyone who has the URL. Search engines can index the file."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLIC_NOT_INDEXABLE"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " file is publicly accessible by anyone who has the URL. The X-Robots-Tag: noindex header will be sent whenever the file is retrieved, instructing search engines not to index the file."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsxs)(_components.strong, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "PRIVATE"
              }), ":"]
            }), " file is not publicly accessible. Requires a signed URL to display content. Search engines cannot index the file."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Endpoints that create files allow for a level of duplicate detections as part of the file’s upload options."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "ENTIRE_PORTAL"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " search for a duplicate file in the account."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "EXACT_FOLDER"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " search for a duplicate file in the provided folder."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "NONE"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " do not run any duplicate validation."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "REJECT"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " reject the upload if a duplicate is found."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "RETURN_EXISTING"
            }), (0, _jsxRuntime.jsx)(_components.strong, {
              children: ":"
            }), " if a duplicate file is found, do not upload a new file and return the found duplicate instead."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Duplicate detection works on a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duplicateValidationScope"
            }), ", which affects how we search for a duplicate."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["This also requires a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duplicateValidationStrategy"
            }), ", which dictates what happens if a duplicate is found."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}