"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611096;
const slug = exports.slug = 'reference/cms/modules/using-modules-in-templates';
const title = exports.title = 'Usar módulos en plantillas';
const name = exports.name = '[nuevo] Usar módulos en plantillas';
const metaDescription = exports.metaDescription = 'Los módulos son áreas editables de las páginas o correos electrónicos de HubSpot. Aunque puedes agregar módulos a un diseño de plantilla utilizando el creador de plantillas drag-and-drop, también puedes crear módulos utilizando HubL. Los módulos de HubL pueden definirse en un archivo de plantilla codificado, en un módulo de plantilla de HubL o en un módulo de contenido de blog. A continuación, se presenta una lista completa de módulos y sus parámetros.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "sintaxis-de-m%C3%B3dulo-b%C3%A1sico",
  "label": "Sintaxis de módulo básico",
  "parent": null
}, {
  "depth": 0,
  "id": "pasar-diccionarios-a-los-par%C3%A1metros-del-m%C3%B3dulo",
  "label": "Pasar diccionarios a los parámetros del módulo",
  "parent": null
}, {
  "depth": 1,
  "id": "pasar-campos-que-tienen-par%C3%A1metros-asociados-a-dnd",
  "label": "Pasar campos que tienen parámetros asociados a dnd",
  "parent": "pasar-diccionarios-a-los-par%C3%A1metros-del-m%C3%B3dulo"
}, {
  "depth": 0,
  "id": "establecer-valores-predeterminados-a-nivel-de-plantilla-en-campos",
  "label": "Establecer valores predeterminados a nivel de plantilla en campos",
  "parent": null
}, {
  "depth": 1,
  "id": "establecer-valores-predeterminados-para-grupos-de-campos-anidados",
  "label": "Establecer valores predeterminados para grupos de campos anidados",
  "parent": "establecer-valores-predeterminados-a-nivel-de-plantilla-en-campos"
}, {
  "depth": 1,
  "id": "establecer-valores-predeterminados-para-campos-repetitivos",
  "label": "Establecer valores predeterminados para campos repetitivos",
  "parent": "establecer-valores-predeterminados-a-nivel-de-plantilla-en-campos"
}, {
  "depth": 1,
  "id": "establecer-valores-predeterminados-para-grupos-de-campos-repetitivos",
  "label": "Establecer valores predeterminados para grupos de campos repetitivos",
  "parent": "establecer-valores-predeterminados-a-nivel-de-plantilla-en-campos"
}, {
  "depth": 1,
  "id": "establecer-valores-predeterminados-en-campos-de-estilo",
  "label": "Establecer valores predeterminados en campos de estilo",
  "parent": "establecer-valores-predeterminados-a-nivel-de-plantilla-en-campos"
}, {
  "depth": 0,
  "id": "sintaxis-de-bloque",
  "label": "Sintaxis de bloque",
  "parent": null
}, {
  "depth": 0,
  "id": "content_attribute",
  "label": "content_attribute",
  "parent": null
}, {
  "depth": 0,
  "id": "par%C3%A1metros-disponibles-para-todos-los-m%C3%B3dulos",
  "label": "Parámetros disponibles para todos los módulos",
  "parent": null
}, {
  "depth": 0,
  "id": "par%C3%A1metros-basados-en-campos",
  "label": "Parámetros basados en campos",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Usar módulos en plantillas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los módulos se pueden agregar directamente a una plantilla o a páginas individuales con áreas de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "arrastrar y soltar"
      }), " y columnas flexibles. Cuando se agrega un módulo a una plantilla, el módulo aparecerá en esa ubicación por opción predeterminada. Los módulos en áreas de arrastrar y soltar y columnas flexibles se pueden mover y eliminar, y se pueden agregar otros módulos a su alrededor. Estas son instancias del módulo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de que se haya definido un módulo, puedes obtener sus valores de campo en el nivel de plantilla a través del ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context#retrieving-parameters-from-modules-already-rendered-on-the-template",
        children: "dictado content.widgets"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sintaxis de módulo básico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las etiquetas de módulo de HubL están delimitadas por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% %}"
      }), " y deben especificar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ", un nombre único y la ruta de administrador de diseño del módulo. Un módulo también puede incluir parámetros para ajustes adicionales."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nombre del módulo:"
        }), " da al módulo una identidad única en el contexto de la plantilla.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "El nombre debe estar entre comillas siguiendo el tipo de módulo, y debe usar guiones bajos en lugar de espacios o guiones."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Este nombre se utiliza para hacer coincidir el contenido establecido en el editor de páginas/correo electrónico con la etiqueta del módulo de HubL correspondiente. Por ejemplo, si codificas una etiqueta de módulo de HubL con el mismo nombre en dos áreas diferentes de una plantilla, los usuarios solo tendrán un módulo para editar en el editor, pero los cambios en ese módulo se aplicarán en ambas ubicaciones."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ruta:"
        }), " dependiendo de la etiqueta, define la ubicación del módulo en el administrador de diseño.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            }), " significa la raíz de la unidad actual;"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "./"
            }), " significa el directorio actual;"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "../"
            }), " significa el elemento principal del directorio actual."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["La ruta de los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "módulos predeterminados de HubSpot"
        }), " siempre comienza con ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot/"
        }), " seguido del tipo de módulo."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Parámetros:"
        }), " ajustes adicionales para la instancia del módulo, especificando su comportamiento y cómo se renderiza. Incluye valores predeterminados a nivel de plantilla para los campos del módulo.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Los parámetros son pares clave-valor separados por comas."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Los parámetros tienen diferentes tipos, como cadena, booleano, entero, enumeración y objeto de lista JSON. Los valores de los parámetros de cadena deben estar entre comillas simples o dobles, mientras que los parámetros booleanos no requieren comillas alrededor de sus valores ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            }), ". Más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/modules/using-modules-in-templates#parameters-available-for-all-modules",
              children: "parámetros disponibles para todos los módulos"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ten en cuenta que no hay validación en el editor para los valores de campo en comparación con la configuración de campo del módulo. Por ejemplo, si un módulo tiene un campo numérico que tiene un valor mínimo establecido en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), ", y pasas en el parámetro un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), ", no verás una advertencia de que el valor no es válido."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Basic syntax #}\n{% module \"unique_module_name\" path=\"@hubspot/module_type\",\n  parameterString='String parameter value',\n  parameterBoolean=True\n%}\n\n{# Sample of a default HubSpot text module #}\n{% module \"job_title\" path=\"@hubspot/text\",\n  label=\"Job Title\",\n  value=\"Chief Morale Officer\"\n%}\n\n{# Sample of a custom module #}\n{% module \"faqs\" path=\"/myWebsite/modules/faq_module\",\n  label=\"Custom FAQ Module\"\n  faq_group_title=\"Commonly Asked Questions\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pasar diccionarios a los parámetros del módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En los módulos con campos que esperan diccionarios, puedes pasarlos como lo harías con otros parámetros. Si es más limpio para ti o planeas reutilizar los valores, puedes en cambio establecer el diccionario en una variable y pasar la variable al parámetro."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_buttons\",\n  path=\"@hubspot/social_sharing\",\n  email={\n    \"default\": true,\n    \"enabled\": false,\n    \"img_src\": \"https://...\"\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pasar campos que tienen parámetros asociados a dnd"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "Las etiquetas de arrastrar y soltar"
      }), ", tales como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ", vienen con un conjunto de parámetros predeterminados, como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), ". Si bien el administrador de diseño evitará que crees nuevos campos que usen uno de estos parámetros reservados, los módulos creados antes de que se introdujeran las etiquetas de arrastrar y soltar ya pueden usar un parámetro reservado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para solucionar esto, puedes usar el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields"
      }), ". De la misma manera que pasarías los datos de un campo a un grupo, puedes pasar el nombre del campo como una clave en el objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields"
      }), ". Su valor debe ser coherente con el formato que espera el tipo de campo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"main_content\"%}\n     {% dnd_section %}\n        {% dnd_column %}\n          {% dnd_row %}\n            {% dnd_module path=\"@hubspot/divider\",\n               fields={width: \"90\"}\n            %}\n            {% end_dnd_module %}\n        {% end_dnd_row %}\n      {%end_dnd_column%}\n    {% end_dnd_section %}\n  {% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Establecer valores predeterminados a nivel de plantilla en campos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes establecer valores predeterminados para los campos del módulo a nivel de plantilla incluyendo parámetros en las etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ". A continuación, descubre cómo establecer valores de campo predeterminados en grupos de campos anidados, campos repetitivos, grupos de campos repetitivos y campos de estilo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Establecer valores predeterminados para grupos de campos anidados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación se muestra un ejemplo de un módulo de arrastrar y soltar personalizado con un grupo de campos personalizados ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "style"
      }), " que contiene otros grupos de campos anidados. Compara su configuración a nivel de plantilla con la forma en que aparecería esta misma agrupación en el administrador de diseño."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_module\n path=\"/Nested_Module.module\"\n style={\n  \"group\":{\n  \"section\":{\n   \"color_field\":{\n   \"color\" : \"#000\",\n   \"opacity\" : 100\n    }\n   }\n  }\n }\n%}\n{% end_dnd_module %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/multi-level%20field%20nesting%20.png",
            alt: "multi-level field nesting "
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Establecer valores predeterminados para campos repetitivos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes establecer valores predeterminados a nivel de plantilla para los campos repetitivos pasando una matriz al parámetro del campo. Los elementos de la matriz deben tener el formato esperado según el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#field-based-parameters",
        children: "tipo de campo"
      }), ". Por ejemplo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un campo de texto simple solo espera una cadena"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Un campo repetidor de imagen espera un objeto de campo de imagen. Esto se aplica a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#field-based-parameters",
          children: "todos los demás tipos de campos"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module path='../modules/recipe_card.module',\n  ingredients=[\"Eggs\",\"Ham\",\"Cheese\"]\n%}\n\n{% module \"my_repeater_module\" path=\"/img_repeater_module\", label=\"img_repeater_module\",\nimage=[\n  {\n    \"src\" : \"https://cdn2.hubspot.net/hubfs/428357/Developer%20Site/assets/logo/Developers-LOGO.svg\",\n    \"alt\" : \"HubSpot Developers\",\n    \"width\" : 254,\n    \"height\" : 31\n  },{\n    \"src\" : \"https://www.hubspot.com/hs-fs/hub/53/file-733888614-jpg/assets/hubspot.com/about/management/dharmesh-home.jpg\",\n    \"alt\" : \"Dharmesh\",\n    \"width\" : 394,\n    \"height\" : 394\n  }\n]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Establecer valores predeterminados para grupos de campos repetitivos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los módulos que contienen grupos de campos que se repiten - como podrías ver en un módulo de presentación de diapositivas o en un módulo de preguntas frecuentes - pueden tener un conjunto predeterminado a nivel de plantilla para esos grupos. Para ello se pasa una matriz de objetos al parámetro del grupo de campos. Los pares de claves y valores del objeto son los nombres de los campos y sus valores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module path='../modules/slideshow.module',\n  slides=[\n    {\n      \"caption\":\"Cute dog looking up\",\n      \"image_url\":\"http://example.com/image.jpg\",\n    },\n    {\n      \"caption\":\"Cuter cat not looking amused\",\n      \"image_url\":\"http://example.com/image2.jpg\",\n    }\n  ]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Establecer valores predeterminados en campos de estilo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se pueden establecer explícitamente valores predeterminados en los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "campos de estilo"
      }), " utilizando el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "styles"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esto funciona igual que los demás grupos, donde el parámetro es el nombre del grupo. A ese parámetro se le pasa un objeto con todos los campos que se desean establecer."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n    path=\"./path/to/module\",\n    styles={\n      \"background_color\":{\n          \"color\":\"#123\",\n          \"opacity\":50\n       }\n    }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sintaxis de bloque"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aunque la mayoría de los módulos tienen parámetros que controlan el contenido predeterminado, puede haber situaciones en las que se necesite agregar grandes bloques de código al contenido predeterminado de un módulo. Por ejemplo, es conveniente que incluyas un gran bloque de HTML como contenido predeterminado en un módulo de texto enriquecido o HTML. En lugar de intentar escribir ese código en un parámetro de valor, puedes utilizar la sintaxis de bloque de HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module_block module \"my_rich_text_module\" path=\"/My Rich Text Field Module\",\n  label=\"My Rich Text Field Module\"\n%}\n    {% module_attribute \"rich_text_field_variable\" %}\n       <div>My HTML block</div>\n    {% end_module_attribute %}\n{% end_module_block %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Antes de la sintaxis ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module_block"
        }), ", se utilizó ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), ". Sigue el mismo patrón, pero las etiquetas de apertura fueron ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_attribute"
        }), ". Las etiquetas de cierre fueron ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "end_widget_attribute"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "end_widget_block"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["La sintaxis ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), " está obsoleta, pero no es necesario actualizar el código antiguo."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El parámetro que sigue a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_block"
      }), " inmediatamente o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " (en desuso) es el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En casi toda nuestra documentación encontrarás que utilizamos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ". Los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "módulos de HubSpot V2"
      }), " son módulos normales, como los que puedes crear. Por lo tanto, ya no es necesario utilizar un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), " diferente."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aunque ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " está en desuso, y deberías usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_block"
      }), ", si se hereda un sitio web de otro desarrollador, este puede contener código antiguo que utiliza ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), " admite nombres de módulos de HubSpot V1, por ejemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rich_text"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "raw_html"
      }), ". Se pueden agregar parámetros adicionales a la primera línea de HubL. La segunda línea define a qué parámetro se aplicará el contenido del bloque. Por ejemplo, para un módulo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rich_text"
      }), ", este debería ser el parámetro html. En el caso de un módulo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "raw_html"
      }), ", éste sería el parámetro de valor (consulta los dos ejemplos siguientes)."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# widget_block is deprecated, use module_block instead. This example is only to\nexplain what type_of_module was used for, for those with legacy code. #}\n{% widget_block rich_text \"my_rich_text_module\" overrideable=True, label='My rich-text module'  %}\n        {% widget_attribute \"html\" %}\n            <h2>New Module</h2>\n            <p>Add content here.</p>\n        {% end_widget_attribute %}\n{% end_widget_block %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ejemplo de salida",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<span\n  id=\"hs_cos_wrapper_my_rich_text_module\"\n  class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text\"\n  style=\"\"\n  data-hs-cos-general-type=\"widget\"\n  data-hs-cos-type=\"rich_text\"\n>\n  <h2>New Module</h2>\n  <p>Add content here.</p>\n</span>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "content_attribute"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además de la sintaxis regular y de bloque, hay ciertos casos en los que es aconsejable que especifiques un gran bloque de contenido predeterminado para una variable de contenido predefinida. El ejemplo más común de esto resulta ser la variable ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#email-variables",
        children: "content.email_body"
      }), ". Esta variable imprime un cuerpo de correo electrónico estándar que se puede modificar en el editor de contenido. Como este no es un módulo estándar de HubL, usamos una etiqueta ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "content_attribute"
      }), " para especificar un bloque de contenido predeterminado. El ejemplo siguiente muestra la variable del cuerpo del correo electrónico rellenada con un bloque de código de contenido predeterminado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% content_attribute \"email_body\" %}\n        <p>Hi {{ contact.firstname }},</p>\n        <p>Describe what you have to offer the customer. Why should they read? What did you promise them in the subject line? Tell them something cool. Make them laugh. Make them cry. Well, maybe don't do that...</p>\n        <p>Use a list to:</p>\n        <ul>\n            <li>Explain the value of your offer</li>\n            <li>Remind the reader what they’ll get out of taking action</li>\n            <li>Show off your skill with bullet points</li>\n            <li>Make your content easy to scan</li>\n        </ul>\n        <p><a href=\"http://hubspot.com\">LINK TO A LANDING PAGE ON YOUR SITE</a> (This is the really important part.)</p>\n        <p>Now wrap it all up with a pithy little reminder of how much you love them.</p>\n        <p>Aw. You silver-tongued devil, you.</p>\n        <p>Sincerely,</p>\n        <p>Your name</p>\n{% end_content_attribute %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parámetros disponibles para todos los módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Aunque algunos módulos tienen ciertos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "parámetros especiales"
      }), ", a continuación, se encuentra una lista de los parámetros que son compatibles con todos los módulos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parámetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Predeterminado"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: ": el nombre del módulo que se muestra en el editor de contenido. Este parámetro también se puede utilizar para proporcionarles a los usuarios instrucciones adicionales."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overrideable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Controla si el módulo se puede editar o no en el editor de contenido, equivalente al ajuste ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Evitar la edición en editores de contenido"
            }), " en el administrador de diseño."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "no_wrapper"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [": Cuando se establece en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", elimina el marcado de agrupación del contenido de un módulo. En las páginas, los módulos siempre están envueltos en un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<div>"
            }), " con clases especiales. Este marcado de agrupación permite que el editor se desplace hasta un módulo cuando haces clic en él dentro del panel de vista preliminar. Puede haber instancias en las que quieras eliminar el envoltorio, como si quieres utilizar un módulo de texto para rellenar el destino de un atributo de etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "href"
            }), " de anclaje."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "extra_classes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Agrega clases al envoltorio del módulo. Puedes agregar varias clases a la vez separándolas con espacios. Por ejemplo:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "extra_classes='full-width panel'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "export_to_template_context"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cuando se establece en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", en lugar de renderizar el HTML, los parámetros de este widget estarán disponibles en el contexto de la plantilla. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/modules/export-to-template-context",
              children: "Aprende a utilizar este parámetro y la etiqueta widget_data."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unique_in_loop"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cuando el módulo se define dentro de un bucle, agrega el nombre del módulo con el loop.index. Cuando se establece en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", se imprimirá una versión diferente del módulo dentro de cada iteración del bucle. Agrega el nombre del módulo con el loop.index."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver una lista completa de todos los tipos de módulos y sus parámetros, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "haz clic aquí."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parámetros basados en campos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, descubre los parámetros de módulo basados en campos que puedes usar."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["| Campo | Tipo | Ejemplo | Claves |\n| --- | --- | --- | --- | --- |\n| Blog | Entero (ID de blog) | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234567890"
      }), " |  |\n| Booleano | Verdadero/falso", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " |  |  |\n| Elección | Cadena | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"option_1\""
      }), " |  |\n| Color | Objeto | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{  \"color\" : \"#ffffff\",  \"opacity\" : 100}"
      }), " | color ", (0, _jsxRuntime.jsx)("br", {}), "formato hexadecimal de 6 caracteres ", (0, _jsxRuntime.jsx)("br", {}), "opacidad ", (0, _jsxRuntime.jsx)("br", {}), "entero 0 - 100 ", (0, _jsxRuntime.jsx)("br", {}), " |\n| CTA | Cadena (ID de CTA) | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"fb9c0055-6beb-489d-8dda-3e1222458750\""
      }), " |  |\n| Fecha | Marca de tiempo | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1566360000000"
      }), " |  |\n| Fecha y hora | Marca de tiempo | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1566360000000"
      }), " |  |\n| Dirección de correo | Matriz (cadenas de direcciones de correo electrónico) | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[\"develop@hubspot.com\", \"design@hubspot.com\"]"
      }), " |  |\n| Archivo | Cadena (URL de archivo) | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"https://cdn2.hubspot.net/hubfs/file.pdf\""
      }), " |  |\n| Correo de seguimiento | Entero (ID de correo electrónico de seguimiento) | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234567890"
      }), " |  |\n| Fuente | Objeto | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{  \"size\" : 12,  \"size_unit\" : \"px\",  \"color\" : \"#000\",  \"styles\" :{    \"text-decoration\" : \"underline\"  },  \"font\" : \"Alegreya\",  \"fallback\" : \"serif\",  \"variant\" : \"regular\",  \"font_set\" : \"GOOGLE\"}"
      }), " | tamaño ", (0, _jsxRuntime.jsx)("br", {}), "tamaño de letra sin tipo de unidad ", (0, _jsxRuntime.jsx)("br", {}), "size_unit ", (0, _jsxRuntime.jsx)("br", {}), "cadena de unidad de tamaño de letra", (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: "\"px\""
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"pt\""
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"em\""
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"rem\""
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"%\""
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"ex\""
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"ch\""
        })]
      }), " ", (0, _jsxRuntime.jsx)("br", {}), "color ", (0, _jsxRuntime.jsx)("br", {}), "cadena de código de color hexadecimal ", (0, _jsxRuntime.jsx)("br", {}), "estilos ", (0, _jsxRuntime.jsx)("br", {}), "propiedades compatibles\"font-weight\" ", (0, _jsxRuntime.jsx)("br", {}), "\"normal\" / \"bold\" ", (0, _jsxRuntime.jsx)("br", {}), "\"font-style\" ", (0, _jsxRuntime.jsx)("br", {}), "\"normal\" / \"italic\" ", (0, _jsxRuntime.jsx)("br", {}), "\"font-style\" ", (0, _jsxRuntime.jsx)("br", {}), "\"none\" / \"underline\" ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)("br", {}), " |\n| Formulario | Objeto | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{  \"form_id\" : \"9aa2e5f3-a46d-4774-897e-0bc37478521c\",  \"response_type\" : \"redirect\",  \"redirect_url\" : \"http://www.hubspot.com\",  \"redirect_id\" : null,  \"form_type\" : \"HUBSPOT\"}"
      }), " | form_id ", (0, _jsxRuntime.jsx)("br", {}), "El ID del formulario. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/forms/find-your-form-guid",
        children: "Cómo obtener el ID de un formulario."
      }), " ", (0, _jsxRuntime.jsx)("br", {}), "response_type ", (0, _jsxRuntime.jsx)("br", {}), "\"redirección\" / \"en línea\" ", (0, _jsxRuntime.jsx)("br", {}), "message ", (0, _jsxRuntime.jsx)("br", {}), "Mensaje que se muestra si se usa response_type \"inline\". Cadena que admite html. ", (0, _jsxRuntime.jsx)("br", {}), "redirect_url", (0, _jsxRuntime.jsx)("br", {}), "Cadena, URL absoluta a una página ", (0, _jsxRuntime.jsx)("br", {}), "redirect_id web ", (0, _jsxRuntime.jsx)("br", {}), "Id. de la página/publicación a la que se debe redireccionar ", (0, _jsxRuntime.jsx)("br", {}), "form_type ", (0, _jsxRuntime.jsx)("br", {}), "\"HUBSPOT\" / \"TICKET_FORM\" ", (0, _jsxRuntime.jsx)("br", {}), " |\n| Tabla de HubDB | Entero (ID de tabla de HubDB) | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456789"
      }), " |  |\n| Icono | Objeto | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{   \"name\" : \"align-center\",  \"unicode\" : \"f037\",  \"type\" : \"SOLID\"}"
      }), " | name ", (0, _jsxRuntime.jsx)("br", {}), "El nombre del icono ", (0, _jsxRuntime.jsx)("br", {}), "unicode ", (0, _jsxRuntime.jsx)("br", {}), "El símbolo unicode de la fuente de la que procede el icono ", (0, _jsxRuntime.jsx)("br", {}), "type ", (0, _jsxRuntime.jsx)("br", {}), "Estilo de símbolo. \"SOLID\" / \"REGULAR\" ", (0, _jsxRuntime.jsx)("br", {}), "Se recomienda establecer un campo de icono y ver los valores de esa manera, para establecer los parámetros correctamente. |\n| Imagen | Objeto | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{  \"src\" : \"https://cdn2.hubspot.net/hubfs/image.jpeg\",  \"alt\" : \"an_image\",  \"width\" : 100,  \"height\" : 100}"
      }), " | src ", (0, _jsxRuntime.jsx)("br", {}), "Image URL ", (0, _jsxRuntime.jsx)("br", {}), "alt ", (0, _jsxRuntime.jsx)("br", {}), "Texto alternativo de la imagen, utilizado por los lectores de pantalla y los motores de búsqueda ", (0, _jsxRuntime.jsx)("br", {}), "width ", (0, _jsxRuntime.jsx)("br", {}), "La anchura a la que debe mostrarse la imagen ", (0, _jsxRuntime.jsx)("br", {}), "height ", (0, _jsxRuntime.jsx)("br", {}), "La altura a la que se debe mostrar la imagen ", (0, _jsxRuntime.jsx)("br", {}), " |\n| Enlace | Objeto | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{   \"url\" : {     \"type\" : \"EXTERNAL\",    \"href\" : \"www.hubspot.com\",    \"content_id\" : null   },  \"open_in_new_tab\" : false,  \"no_follow\" : false }"
      }), " | url ", (0, _jsxRuntime.jsx)("br", {}), "objeto que almacena datos de la URL.type ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: "\"EXTERNAL\" para las URL que no son de HubSpot"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"CONTENT\" para páginas, publicaciones de blog y landing pages"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"FILE\" para los archivos cargados en el administrador de archivos"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"EMAIL_ADDRESS\" para direcciones de correo electrónico"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"BLOG\" para páginas de contenido de blog"
        })]
      }), " ", (0, _jsxRuntime.jsx)("br", {}), "href ", (0, _jsxRuntime.jsx)("br", {}), "La URL con la cual se hace el enlace. ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)("br", {}), "open_in_new_tab ", (0, _jsxRuntime.jsx)("br", {}), "\"true\"/\"false\", determina si si ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "target=\"_blank\""
      }), " debe agregarse ", (0, _jsxRuntime.jsx)("br", {}), "no_follow ", (0, _jsxRuntime.jsx)("br", {}), "\"true\"/\"false\", determina si si ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rel=\"nofollow\""
      }), " debe usarse ", (0, _jsxRuntime.jsx)("br", {}), " |\n| Logotipo | Objeto | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{  \"override_inherited_src\" : true,  \"src\" : \"https://cdn2.hubspot.net/hubfs/logo.png\",  \"alt\" : \"best_logo_ever\",  \"width\" : 100,  \"height\" : 100}"
      }), " | override_inherited_src ", (0, _jsxRuntime.jsx)("br", {}), " true/false anula los valores predeterminados del portal", (0, _jsxRuntime.jsx)("br", {}), "src ", (0, _jsxRuntime.jsx)("br", {}), "URL de imagen", (0, _jsxRuntime.jsx)("br", {}), "alt ", (0, _jsxRuntime.jsx)("br", {}), "Texto alternativo, utilizado por los lectores de pantalla y los motores de búsqueda. ", (0, _jsxRuntime.jsx)("br", {}), "width ", (0, _jsxRuntime.jsx)("br", {}), "ancho con el que se mostrará la imagen ", (0, _jsxRuntime.jsx)("br", {}), "height ", (0, _jsxRuntime.jsx)("br", {}), "altura a la que se debe mostrar la imagen ", (0, _jsxRuntime.jsx)("br", {}), " |\n| Reunión | Cadena (enlace de reunión) | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"https://app.hubspot.com/meetings/developers-r-kewl\""
      }), " |  |\n| Menú | Entero (ID del menú) | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456789"
      }), " |  |\n| Número | Entero | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1"
      }), " |  |\n| Página | Entero (ID de página) | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234567890"
      }), " |  |\n| texto enriquecido | Cadena (puede contener html) | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"# Hello, world! <RelatedApiLink />\""
      }), " |  |\n| Campaña de Salesforce | Cadena (ID de campaña de Salesforce) | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"7016A0000005S0tQAE\""
      }), " |  |\n| Menú simple | Matriz de objetos de elementos del menú | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[   {     \"isPublished\" : true,    \"pageLinkId\" : 123456789,    \"pageLinkName\" : \"My page\",    \"isDeleted\" : false,    \"categoryId\" : 1,    \"subCategory\" : \"site_page\",    \"contentType\" : \"site_page\",    \"state\" : \"PUBLISHED_OR_SCHEDULED\",    \"linkLabel\" : \"This is a page\",    \"linkUrl\" : null,    \"linkParams\" : null,    \"linkTarget\" : null,    \"type\" : \"PAGE_LINK\",    \"children\" : [ ]  } ]"
      }), " | isPublished ", (0, _jsxRuntime.jsx)("br", {}), "¿true/false se publica la página del elemento de menú? ", (0, _jsxRuntime.jsx)("br", {}), "pageLinkId", (0, _jsxRuntime.jsx)("br", {}), "ID de página en el CMS ", (0, _jsxRuntime.jsx)("br", {}), "pageLinkName ", (0, _jsxRuntime.jsx)("br", {}), "El nombre real de la página en el CMS ", (0, _jsxRuntime.jsx)("br", {}), "isDeleted ", (0, _jsxRuntime.jsx)("br", {}), "verdadero/falso ", (0, _jsxRuntime.jsx)("br", {}), "categoryId ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: "1 - Página del sitio"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "3 - Publicación de blog"
        })]
      }), "subCategory", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: "site_page"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "landing_page"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "blog"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "normal_blog_post"
        })]
      }), "contentType", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: "site_page"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "landing_page"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "blog"
        })]
      }), "blog Estado", (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: "DRAFT"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "DRAFT_AB"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "PUBLISHED"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "PUBLISHED_OR_SCHEDULED"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "PUBLISHED_AB"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "SCHEDULED"
        })]
      }), (0, _jsxRuntime.jsx)("br", {}), "linkLabel", (0, _jsxRuntime.jsx)("br", {}), "texto que el usuario lee y en el que hace clic ", (0, _jsxRuntime.jsx)("br", {}), "linkUrl ", (0, _jsxRuntime.jsx)("br", {}), "URL real a la que se envía al usuario al hacer clic ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "linkParams en \\# links o ? parámetros de consulta ", (0, _jsxRuntime.jsx)("br", {}), "linkTarget ", (0, _jsxRuntime.jsx)("br", {}), "si está abierto en una nueva pestaña está habilitada \"_blank\" de lo contrario \"null", (0, _jsxRuntime.jsx)("br", {}), "tipo", (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: "\"PAGE_LINK\""
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"PAGE_LINK_WITH_PARAMS\""
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"NO_LINK\""
        })]
      }), "children", (0, _jsxRuntime.jsx)("br", {}), "Matriz de objetos de elementos de menú secundario, idénticos a los elementos de menú individuales. ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), " |  |\n| Etiqueta | Entero (se recomienda ID de etiqueta o slug, ID) | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234567890"
      }), " |  |\n| Texto | Cadena | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"it's like any other string\""
      }), " |  |\n| URL | Objeto | ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{   \"type\" : \"CONTENT\",  \"href\" : null,  \"content_id\" : 123456789}"
      }), " | tipo", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
        children: [(0, _jsxRuntime.jsx)("li", {
          children: "\"\"EXTERNAL\" para las URL que no son de HubSpot"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"CONTENT\" para páginas, publicaciones de blog y páginas de destino"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"FILE\" para archivos cargados en el administrador de archivos"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"EMAIL_ADDRESS\" para direcciones de correo electrónico"
        }), (0, _jsxRuntime.jsx)("li", {
          children: "\"BLOG\" para páginas de contenido de blog"
        })]
      }), " ", (0, _jsxRuntime.jsx)("br", {}), "href ", (0, _jsxRuntime.jsx)("br", {}), "Cadena, la URL a la que se enlaza. ", (0, _jsxRuntime.jsx)("br", {}), " |"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}