"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29907021629;
const slug = exports.slug = 'guides/apps/api-usage/usage-details';
const title = exports.title = 'APIs da HubSpot | Diretrizes de uso';
const name = exports.name = 'Diretrizes de uso de APIs';
const metaDescription = exports.metaDescription = 'As diretrizes de utilização de APIs da HubSpot abrangem nossa Política de Uso Aceitável, Termos de API, limites de taxa, autenticação e segurança.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "autentica%C3%A7%C3%A3o-e-seguran%C3%A7a",
  "label": "Autenticação e segurança",
  "parent": null
}, {
  "depth": 0,
  "id": "verificando-o-uso-da-api",
  "label": "Verificando o uso da API",
  "parent": null
}, {
  "depth": 1,
  "id": "aplicativos-privados",
  "label": "Aplicativos privados",
  "parent": "verificando-o-uso-da-api"
}, {
  "depth": 1,
  "id": "aplicativos-p%C3%BAblicos-que-usam-oauth",
  "label": "Aplicativos públicos que usam OAuth",
  "parent": "verificando-o-uso-da-api"
}, {
  "depth": 0,
  "id": "limites-de-taxa",
  "label": "Limites de taxa",
  "parent": null
}, {
  "depth": 2,
  "id": "aplicativos-p%C3%BAblicos",
  "label": "Aplicativos públicos",
  "parent": "limites-de-taxa"
}, {
  "depth": 2,
  "id": "aplicativos-privados",
  "label": "Aplicativos privados",
  "parent": "limites-de-taxa"
}, {
  "depth": 0,
  "id": "outros-limites",
  "label": "Outros limites",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-de-solicita%C3%A7%C3%A3o",
  "label": "Limites de solicitação",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-de-servi%C3%A7o",
  "label": "Limites de serviço",
  "parent": null
}, {
  "depth": 0,
  "id": "respostas-de-erro",
  "label": "Respostas de erro",
  "parent": null
}, {
  "depth": 1,
  "id": "usar-apis-em-lote-e-resultados-em-cache-quando-poss%C3%ADvel",
  "label": "Usar APIs em lote e resultados em cache quando possível",
  "parent": "respostas-de-erro"
}, {
  "depth": 1,
  "id": "usar-webhooks-para-obter-dados-atualizados-do-hubspot",
  "label": "Usar webhooks para obter dados atualizados do HubSpot",
  "parent": "respostas-de-erro"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      ul: "ul",
      li: "li",
      img: "img",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Diretrizes de uso de APIs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A HubSpot monitora o uso de nossas APIs públicas para garantir uma experiência de qualidade para todos os usuários. Todos os desenvolvedores de apps e integrações devem cumprir a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/acceptable-use",
        children: "Política de Utilização Responsável da HubSpot"
      }), " e os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/developer-terms",
        children: "Termos de APIs"
      }), ". Embora a HubSpot se reserve o direito de alterar as APIs ou torná-las obsoletas ao longo do tempo, as atualizações sempre serão fornecidas por meio do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "log de alterações do desenvolvedor"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autenticação e segurança"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para segurança ideal, todos os apps devem usar o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "protocolo OAuth"
      }), " da HubSpot diretamente ou o token de acesso do app se você estiver criando um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "app privado"
      }), ". Os aplicativos são responsáveis por armazenar dados de tempo de vida (TTL) e atualizar os tokens de acesso do usuário de acordo com esse protocolo. Quando um token de acesso for gerado, ele incluirá um parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " que indica por quanto tempo ele poderá ser usado para fazer chamadas de API antes de precisar ser atualizado. As solicitações ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Unauthorized (401)"
      }), " não são um indicador válido de que um novo token de acesso deve ser recuperado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verificando o uso da API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicativos privados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Para visualizar o uso da API para um aplicativo privado:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ícone de configurações"
        }), " na barra de navegação principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No menu lateral esquerdo, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrações"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicativos privados"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " do aplicativo privado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na página de detalhes do aplicativo, clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Registros"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revise as chamadas de API listadas na tabela. Você também pode usar ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "barra de pesquisa"
        }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "filtros"
        }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "seletores de datas"
        }), " para refinar ainda mais as chamadas de API exibidas."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/Screenshot%202023-08-31%20at%205.28.03%20PM.png",
        alt: "Captura de tela 2023-08-31 às 17:28:03"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsxs)(_components.strong, {
        children: ["Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#view-api-call-logs",
          children: "verificar o uso da API em aplicativos privados"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Aplicativos públicos que usam OAuth"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para visualizar o uso da API para um aplicativo público usando OAuth:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta de desenvolvedor, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicativos"
        }), " na barra de navegação principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " do aplicativo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No menu da barra lateral esquerda, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Monitoramento"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use as ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "guias"
        }), " para visualizar diferentes tipos de solicitações feitas para ou por meio do aplicativo. Ao visualizar esses registros, você pode clicar em uma ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "solicitação individual"
        }), " para visualizar mais informações."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/6-request_details.png",
        alt: "6-request_details"
      }), "Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#monitor-app-behavior",
        children: "monitorar o uso da API para aplicativos públicos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites de taxa"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Aplicativos públicos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para aplicativos OAuth, cada conta da HubSpot que instala o aplicativo está limitada a 100 solicitações a cada 10 segundos. Isso exclui a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/search",
        children: "API de pesquisa"
      }), ", conforme observado na seção ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-limits",
        children: "Outros limites"
      }), " abaixo. Limites relacionados ao complemento para API não se aplicam."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Aplicativos privados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada aplicativo privado está sujeito às ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "diretrizes de uso de APIs da HubSpot"
      }), ". O número de chamadas que seu aplicativo privado pode fazer se baseia na assinatura da sua conta e se você comprou o complemento de API:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {}), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Categoria do produto"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Por 10 segundos"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Por dia"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicativos privados"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Qualquer Hub)Gratuito e Starter"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "100 / aplicativo privado"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "250.000 / conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Qualquer Hub)Pro e Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "150 /aplicativo privado"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "500.000 /conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicativos privados com o complemento de API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Qualquer Hub)Gratuito, Starter, Professional e Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "200 / aplicativo privado"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1.000.000 / conta"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Outros limites"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Você pode criar até 100 apps por conta de desenvolvedor."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Você pode criar até 20 apps privados por conta da HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Você pode criar até 1.000 assinaturas de webhook por app."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Você pode criar até 25 configurações de extensão de CRM por app."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Você pode criar até 750 tipos de evento de linha do tempo por app."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Você pode criar até 500 propriedades por tipo de evento de linha do tempo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites de solicitação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lembre-se dos seguintes limites gerais ao fazer solicitações aos pontos de extremidade da API do HubSpot. Algumas APIs também podem ter seus próprios limites específicos, que serão listados na página de referência da API em ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Limites"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/single-send-limits-example.png",
        alt: "single-send-limits-example"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os pontos de extremidade da ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search",
          children: "API de pesquisa"
        }), " são limitados a quatro solicitações por segundo por token de autenticação. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search#limitations",
          children: "limites de pesquisa"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["As solicitações de API que estão isentas dos limites diários ou secundários ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " serão registrados no HubSpot. Se quiser armazenar estas solicitações isentas, você terá que as registrar externamente."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "As solicitações em lote para pontos de extremidade de objeto do CRM estão limitadas a 100 registros por solicitação. Por exemplo, você não pode ler em lote mais de 100 contatos por solicitação."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites de serviço"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre os limites e preços de serviços ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/pricing/service-limits",
        children: "aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Respostas de erro"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Qualquer aplicativo ou integração que exceda seus limites de taxa receberá uma resposta de erro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " para todas as chamadas de API subsequentes. As solicitações que resultam em resposta de erro não devem exceder 5% das solicitações diárias. Se você planeja listar seu aplicativo no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "Marketplace de aplicativos da HubSpot"
      }), ", ele deverá estar abaixo desse limite de 5% para que seja certificado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A resposta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " terá o seguinte formato:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example\n{\n  \"status\": \"error\",\n  \"message\": \"You have reached your daily limit.\",\n  \"errorType\": \"RATE_LIMIT\",\n  \"correlationId\": \"c033cdaa-2c40-4a64-ae48-b4cec88dad24\",\n  \"policyName\": \"DAILY\",\n  \"requestId\": \"3d3e35b7-0dae-4b9f-a6e3-9c230cbcf8dd\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "message"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "policyName"
      }), " indicarão o limite que você atingiu (diariamente ou por segundo)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O limite** diário** é redefinido meia-noite de acordo com sua", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-set-your-time-zone-in-hubspot",
        children: " configurações do fuso horário"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A tabela a seguir detalha os cabeçalhos de limite de taxa incluídos na resposta de cada solicitação de API para o HubSpot, sujeito às exceções listadas abaixo da tabela."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Cabeçalho"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Descrição"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Daily"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O número de solicitações de API permitidas por dia. Observe que este cabeçalho não está incluído na resposta a solicitações de API autorizadas usando ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth",
              children: "OAuth"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Daily-Remaining"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O número de solicitações de API ainda permitidas no dia atual. Observe que este cabeçalho não está incluído na resposta a solicitações de API autorizadas usando ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth",
              children: "OAuth"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A janela de tempo à qual os cabeçalhos ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Max"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Remaining"
            }), " se aplicam.Por exemplo, o valor 10.000 seria uma janela de 10 segundos."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Max"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O número de solicitações permitidas na janela especificado em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            }), ". Por exemplo, se o valor desse cabeçalho fosse 100 e o cabeçalho ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            }), " fosse 10.000, o limite aplicado seria de 100 solicitações por 10 segundos."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Remaining"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O número de solicitações de API ainda permitidas para a janela especificada em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "X-HubSpot-RateLimit-Interval-Milliseconds"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os cabeçalhos ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "X-HubSpot-RateLimit-Secondly"
          }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "X-HubSpot-RateLimit-Secondly-Remaining"
          }), " ainda estão incluídos e terão dados precisos, mas o limite ao qual eles fazem referência não será mais aplicado, por isso deverão ser considerados obsoletos."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["As respostas dos ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/crm/search",
            children: "pontos de extremidade da API de pesquisa"
          }), " ", (0, _jsxRuntime.jsx)("u", {
            children: "não"
          }), " incluirão nenhum dos cabeçalhos de limite de taxa listados acima."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode verificar o número de chamadas usadas durante o dia atual usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/account-information-api",
        children: "este ponto de extremidade"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você se deparar com ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TEN_SECONDLY_ROLLING"
      }), ", acelere as solicitações que o aplicativo está fazendo para se manter abaixo desse limite. Confira as sugestões abaixo para acelerar as solicitações ou se você estiver atingindo o limite diário."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você perceber que, mesmo após ler essas sugestões, continua atingindo os limites de chamada, publique nos", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://integrate.hubspot.com/",
        children: " fóruns de desenvolvedores"
      }), " da HubSpot. Você deve incluir o máximo de detalhes possível sobre as APIs que está usando, como elas estão sendo usadas e qual o limite atingido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usar APIs em lote e resultados em cache quando possível"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se seu site ou aplicativo usar dados do HubSpot em cada carregamento de página, esses dados deverão ser armazenados no cache e carregados dele, em vez de serem solicitados todas as vezes nas APIs da HubSpot. Se você estiver fazendo chamadas repetidas para obter configurações sobre sua conta para um trabalho em lote (como obter as propriedades do objeto, os proprietários ou as configurações de um formulário), essas configurações também deverão armazenadas no cache sempre que possível."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usar webhooks para obter dados atualizados do HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se tiver uma assinatura do HubSpot Operations Professional, você poderá usar ações de webhook em fluxos de trabalho para que os dados de registros de contatos sejam enviados para o seu sistema. Os webhooks podem ser disparados como uma ação em qualquer fluxo de trabalho. Dessa forma, é possível usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/workflows-user-guide-v2/how-to-choose-the-workflow-type-and-the-starting-condition-best-suited-for-your-goal",
        children: "condições iniciais"
      }), " de qualquer fluxo de trabalho como os critérios para que os dados de contato sejam enviados para o sistema. Encontre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/workflows/how-do-i-use-webhooks-with-hubspot-workflows",
        children: "aqui"
      }), " mais detalhes sobre como usar webhooks e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/create-manage-workflows/v3#create-a-workflow",
        children: "aqui"
      }), " um exemplo de dados de webhooks. As chamadas de webhook feitas por meio de fluxos de trabalho não contam para o limite de taxa da API."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}