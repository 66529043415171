"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const slug = exports.slug = 'reference/cms/overview';
const title = exports.title = 'CMSリファレンスの概要';
const metaDescription = exports.metaDescription = 'HubSpotコンテンツ マネジメント システム（CMS）に関する開発者リファレンスドキュメント。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cms%E9%96%8B%E7%99%BA%E3%82%AC%E3%82%A4%E3%83%89",
  "label": "CMS開発ガイド",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    h2: "h2"
  }, (0, _react.useMDXComponents)(), props.components);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMSリファレンスの概要"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このセクションには、HubSpotコンテンツ マネジメント システム（CMS）に関する開発者リファレンスドキュメントが含まれています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "モジュール"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL"
      }), "テンプレート言語、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "フィールド"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions",
        children: "サーバーレス関数"
      }), "に関連したリファレンスドキュメントに素早くアクセスできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CMS開発ガイド"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot CMSでの開発の概要については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms",
        children: "「CMS開発ガイド」"
      }), "セクションを参照してください。CMSの機能やツールの概要に加えて、クイックスタートガイドが含まれています。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;