"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79935313448;
const slug = exports.slug = 'guides/cms/content/themes/quotes-themes';
const title = exports.title = 'Premiers pas avec le thème des devis CMS';
const name = exports.name = 'EMEA FRANCE (fr) Getting started from the CMS quotes theme';
const metaDescription = exports.metaDescription = 'Créez un thème de devis personnalisé pour votre entreprise en fonction du thème de devis CMS fourni par HubSpot. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "conditions-pr%C3%A9alables",
  "label": "Conditions préalables",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-rapporter-le-th%C3%A8me-vers-votre-r%C3%A9pertoire-local",
  "label": "1. Rapporter le thème vers votre répertoire local",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-t%C3%A9l%C3%A9charger-et-consulter-les-modifications",
  "label": "2. Télécharger et consulter les modifications",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-ouvrir-un-aper%C3%A7u-du-mod%C3%A8le",
  "label": "3. Ouvrir un aperçu du modèle",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-faire-un-changement-local",
  "label": "4. Faire un changement local",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-modifier-le-libell%C3%A9-du-mod%C3%A8le",
  "label": "5. Modifier le libellé du modèle",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-personnaliser-le-mod%C3%A8le-de-devis-dans-hubspot",
  "label": "6. Personnaliser le modèle de devis dans HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-cr%C3%A9ez-un-devis-%C3%A0-l-aide-de-votre-nouveau-mod%C3%A8le",
  "label": "7. Créez un devis à l'aide de votre nouveau modèle",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A9tapes-suivantes",
  "label": "Étapes suivantes",
  "parent": null
}, {
  "depth": 0,
  "id": "autres-ressources",
  "label": "Autres ressources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      code: "code",
      pre: "pre",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Premiers pas avec le thème des devis CMS"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avec un thème de devis CMS, vous pouvez créer un thème de devis personnalisé pour les représentants commerciaux, à utiliser pendant le processus d'achat. Ce guide vous indiquera comment cloner localement le thème de devis CMS par défaut à l'aide de la CLI, télécharger ce clone sur votre compte, puis faire les ajustements nécessaires. Vous allez ensuite créer un devis à l'aide du modèle pour consulter votre travail."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Conditions préalables"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Vous devriez être à l'aise pour coder en HTML et CSS."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La dernière version de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "CLI HubSpot doit être installée et configurée pour votre portail"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " bien que ce tutoriel utilise la CLI HubSpot, vous pouvez réaliser tout cela dans HubSpot en utilisant le gestionnaire de conception si vous le préférez. Pour terminer ce processus dans HubSpot, il vous suffit de cloner le thème ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cms-quotes"
        }), " dans le dossier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot"
        }), " au lieu d'exécuter la commande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetch"
        }), " montrée à l'étape 1."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Rapporter le thème vers votre répertoire local"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ouvrez votre terminal, accédez au répertoire vers lequel vous souhaitez télécharger les fichiers. Ceci sera votre répertoire de travail principal pour la suite de ce tutoriel."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour télécharger le thème de devis par défaut, exécutez ce qui suit dans votre terminal :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch @hubspot/cms-quotes-theme \"my-quotes-theme\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous devriez maintenant voir un dossier nommé ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-quotes-theme"
      }), " dans votre système de fichiers local. Ce dossier contient l'ensemble des ressources nécessaires pour le thème de devis, y compris les données simulées et les valeurs par défaut du module dans le dossier d'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "importation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Télécharger et consulter les modifications"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois le dossier téléchargé, chargez-le sur HubSpot. Bien que vous puissiez utiliser la commande ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "hs upload"
      }), " pour effectuer un téléchargement unique, vous pouvez également utiliser la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " pour déclencher des téléchargements automatiques à chaque enregistrement de fichier :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs watch \"my-quotes-theme\" \"my-quotes-theme\" --initial-upload\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois le téléchargement terminé, vous pouvez afficher le dossier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-quotes-theme"
      }), " dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/design-manager/",
        children: "gestionnaire de conception"
      }), ". Pour ouvrir le gestionnaire de conception depuis le terminal, ouvrez un nouvel onglet ou une nouvelle fenêtre de terminal et exécutez la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs open dm"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Un nouvel onglet ou une nouvelle fenêtre de terminal est nécessaire, car il n'est pas possible d'exécuter de commandes pendant que le processus ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch"
        }), " est en cours d'exécution. Vous pouvez également appuyer sur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "q"
        }), " pour terminer la surveillance, exécuter une autre commande, puis relancer la commande ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Ouvrir un aperçu du modèle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour prévisualiser le modèle de devis :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le gestionnaire de conception, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > templates > bold.html."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le coin supérieur droit de l'éditeur de code, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aperçu"
        }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aperçu en direct avec options d'affichage"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois l'aperçu du modèle ouvert, vos modifications seront effectuées localement, et seront automatiquement téléchargées lors de l'enregistrement en raison de l'exécution de la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Faire un changement local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre éditeur de code local, ouvrez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > css > bold.css."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ajoutez le code ci-dessous à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "bold.css"
        }), ", puis enregistrez vos modifications :"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".line-items__table tbody tr:nth-child(odd) {\n  background-color: #d6d6d6;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Actualisez l'aperçu du modèle dans votre navigateur pour afficher vos modifications CSS. Vous devriez maintenant constater que chaque ligne impaire dans le corps de la table a un fond gris."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous créez votre thème de devis personnalisé, vous pouvez répéter ce flux de travail pour confirmer rapidement les modifications que vous effectuez localement."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " en raison de la complexité du système de signature, les signatures ne s'afficheront pas dans les aperçus."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Modifier le libellé du modèle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous préparez un thème de devis personnalisé pour une utilisation réelle, vous devez garder à l'esprit le libellé du modèle, afin que les représentants commerciaux puissent facilement le trouver parmi les options de devis par défaut de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour modifier le libellé d'un modèle de devis :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre éditeur de code, ouvrez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "my-quotes-theme > templates > bold.html"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "En haut du fichier, consultez l'annotation du modèle :"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n templateType: quote\n isAvailableForNewContent: true\n label: Bold\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Remplacez le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "label"
        }), " depuis la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Bold"
        }), " à un nom de votre choix, comme par exemple ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "Mon modèle de devis personnalisé"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Enregistrez le fichier pour le télécharger sur HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Personnaliser le modèle de devis dans HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Avant qu'un représentant commercial puisse utiliser votre modèle de devis, il doit être personnalisé dans HubSpot. En général, un responsable des ventes s'en chargera pour ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/deals/create-custom-quote-templates",
        children: "créer des devis prêts à l'emploi pour son équipe de vente"
      }), ". Cependant, dans ce tutoriel, vous ferez vous-même ce processus, afin de découvrir l'expérience de création de contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour personnaliser le modèle de devis et le mettre à disposition des commerciaux :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, cliquez sur l'icône ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Paramètres"
        }), " dans la barre de navigation principale."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Objets > Devis"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modèles de devis"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Personnaliser le modèle de devis"
        }), " dans l'angle supérieur droit."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Passez le curseur de votre souris sur votre nouveau ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "modèle"
        }), ", puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Choisir"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["À l'aide du panneau de gauche, vous pouvez modifier les modules inclus dans le modèle. Par exemple, vous pouvez cliquer sur un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "module"
        }), " pour modifier ses propriétés ou activer/désactiver sa visibilité."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/quote-template-toggle-visibility.png",
        alt: "quote-template-toggle-visibility"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans l'angle supérieur droit, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sauvegarder"
        }), " lorsque vous avez terminé d'apporter des modifications."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Créez un devis à l'aide de votre nouveau modèle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois vos modifications enregistrées, vous pouvez désormais créer un devis avec le modèle, pour simuler l'expérience d'un représentant commercial."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ventes > Devis"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer un devis"
        }), " dans l'angle supérieur droit. Vous allez alors être redirigé vers un assistant de création de devis."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la première fenêtre, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Associer à une transaction"
        }), ", puis sélectionnez une transaction existante ou sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer une transaction"
        }), " si vous souhaitez utiliser une transaction test à la place."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans l'angle inférieur droit, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suivant"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sur l'écran suivant, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Devis"
        }), ", puis sélectionnez votre modèle de devis personnalisé."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Passez en revue le reste de l'assistant de devis pour créer votre devis."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Après la publication du devis, une boîte de dialogue apparaîtra avec un lien pour afficher le devis. Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copier"
        }), " pour copier l'URL, puis collez-la dans votre navigateur pour afficher le devis complété."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-quote-url.png",
        alt: "copy-quote-url"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Étapes suivantes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avec votre modèle de devis créé, téléchargé et personnalisé, vous devriez maintenant avoir une meilleure compréhension du processus de modification d'un modèle de devis ainsi que de l'expérience d'un créateur de contenu."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque vous créez des modèles de devis adaptés aux besoins de votre entreprise, vous pouvez essayer d'ajouter au devis vos propres modules personnalisés ainsi que les modules par défaut de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " il est recommandé de ", (0, _jsxRuntime.jsx)("u", {
          children: "ne pas"
        }), " modifier le JavaScript des modules de paiement, de signature et de téléchargement, car cela pourrait entraîner le dysfonctionnement des modules. Si ce processus n'est pas fonctionnel, l'utilisateur final risque de ne pas être en mesure de le signer, de le télécharger ou même d'effectuer un paiement."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Autres ressources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/quotes",
          children: "Modèles de devis personnalisés"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/quote-variables",
          children: "Référence de variable de devis personnalisé"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/fr/deals/create-custom-quote-templates",
          children: "Créer et utiliser des modèles de devis personnalisés (du point de vue des commerciaux, des opérations de vente/du manager)"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}