"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742902;
const slug = exports.slug = 'guides/api/crm/extensions/video-conferencing';
const title = exports.title = 'CRM-API | Videokonferenz-Erweiterung';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - VCE';
const metaDescription = exports.metaDescription = 'Diese Endpunkte werden verwendet, um den Flow zur Meetingerstellung in HubSpot zu verknüpfen und Videokonferenz-Informationen hinzuzufügen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "einstellungen-api",
  "label": "Einstellungen-API",
  "parent": null
}, {
  "depth": 1,
  "id": "einstellungen-objektdefinition",
  "label": "Einstellungen-Objektdefinition",
  "parent": "einstellungen-api"
}, {
  "depth": 1,
  "id": "einstellungen-f%C3%BCr-die-videokonferenz-erweiterung-erstellen-oder-aktualisieren",
  "label": "Einstellungen für die Videokonferenz-Erweiterung erstellen oder aktualisieren",
  "parent": "einstellungen-api"
}, {
  "depth": 1,
  "id": "einstellungen-f%C3%BCr-die-videokonferenz-erweiterung-abrufen",
  "label": "Einstellungen für die Videokonferenz-Erweiterung abrufen",
  "parent": "einstellungen-api"
}, {
  "depth": 1,
  "id": "%E2%80%9Emeeting-erstellen%E2%80%9C-webhook",
  "label": "„Meeting erstellen“-Webhook",
  "parent": "einstellungen-api"
}, {
  "depth": 1,
  "id": "%E2%80%9Emeeting-aktualisieren%E2%80%9C-webhook",
  "label": "„Meeting aktualisieren“-Webhook",
  "parent": "einstellungen-api"
}, {
  "depth": 1,
  "id": "%E2%80%9Emeeting-l%C3%B6schen%E2%80%9C-webhook",
  "label": "„Meeting löschen“-Webhook",
  "parent": "einstellungen-api"
}, {
  "depth": 1,
  "id": "%E2%80%9Ebenutzer-verifizieren%E2%80%9C-webhook",
  "label": "„Benutzer verifizieren“-Webhook",
  "parent": "einstellungen-api"
}, {
  "depth": 1,
  "id": "webhook-signatur",
  "label": "Webhook-Signatur",
  "parent": "einstellungen-api"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ol: "ol",
      li: "li",
      h2: "h2",
      a: "a",
      h3: "h3",
      code: "code",
      strong: "strong",
      pre: "pre",
      ul: "ul",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Videokonferenz-Erweiterung"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Videokonferenz-API bietet Integratoren die Möglichkeit, den Flow zur Meetingerstellung in HubSpot zu verknüpfen und Videokonferenz-Informationen zum Meeting hinzuzufügen. Die Verwendung dieser API umfasst folgende Schritte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Richten Sie Ihre App mit Webhooks zu Videokonferenz-Erweiterungen ein. Sie stellen HubSpot URIs bereit, die HubSpot verwendet, um Sie zu benachrichtigen, wenn Kunden Meetings erstellen oder aktualisieren."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Verarbeiten Sie die „Meeting erstellen“-Webhooks und optional die „Meeting aktualisieren“-Webhooks."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Verarbeiten Sie Webhooks zur Verifizierung der Benutzeridentität, falls erforderlich."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einstellungen-API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Entwickler verwenden diese Einstellungen-API, um eine vorhandene Anwendung einzurichten. Sie können das Zugriffstoken von Ihrer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "privaten App"
      }), " verwenden, um die Anfrage zu authentifizieren."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einstellungen-Objektdefinition"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Einstellungen-Objekt hat die folgenden Felder:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "createMeetingUrl:"
      }), " die URL, in der wir Anfragen für neue Videokonferenzen senden. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https"
      }), "-Protokoll erforderlich."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "updateMeetingUrl:"
      }), " (optional) die URL, in der wir Aktualisierungen an vorhandenen Meetings senden. Wird in der Regel aufgerufen, wenn der Benutzer das Thema oder die Zeiten eines Meetings ändert. https-Protokoll erforderlich."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "deleteMeetingUrl:"
      }), " (optional) die URL, in der wir Sie über die in HubSpot gelöschten Meetings benachrichtigen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "userVerifyUrl:"
      }), " (optional) die URL, die wir verwenden, um zu überprüfen, ob ein Benutzer in Ihrem System existiert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einstellungen für die Videokonferenz-Erweiterung erstellen oder aktualisieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispielanfrage:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT /crm/v3/extensions/videoconferencing/settings/{appId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update-meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispielantwort:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example 200 response\n{\n  \"createMeetingUrl\": \"https://example.com/create-meeting\",\n  \"updateMeetingUrl\": \"https://example.com/update-meeting\",\n  \"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispielantwort:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Optionale Werte sollten aus der Anfrage ausgeschlossen werden, da leere Zeichenfolgen oder andere Werte wahrscheinlich zu einem unerwünschten Verhalten führen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einstellungen für die Videokonferenz-Erweiterung abrufen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispielanfrage:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET /crm/v3/extensions/videoconferencing/settings/{appId}   "
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispiel-Antwort:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example 200 response\n{\n\"createMeetingUrl\": \"https://example.com/create-meeting\",\n\"updateMeetingUrl\": \"https://example.com/update-meeting\"\n\"deleteMeetingUrl\": \"https://example.com/delete-meeting\"\n\"userVerifyUrl\": \"https://example.com/user-verify\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "„Meeting erstellen“-Webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn ein Meeting erstellt wird, sendet HubSpot eine Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createMeetingUri"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispielanfrage:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example request\n{\n\"portalId\": 123123,\n\"userId\": 123,\n\"userEmail\": \"test.user@example.com\",\n\"topic\": \"A Test Meeting\",\n\"source\": \"MEETINGS\"\n\"startTime\": 1534197600000,\n\"endTime\": 1534201200000\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Felder in dieser Anfrage sind:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "portalId"
        }), ": die ID des HubSpot-Accounts (als Portal bezeichnet)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), ": die eindeutige ID für den HubSpot-Benutzer, der das Meeting moderiert."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userEmail"
        }), ": die E-Mail-Adresse des HubSpot-Benutzers, der das Meeting moderiert."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "topic"
        }), ": das Thema bzw. der Titel des Meetings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "source"
        }), ": entweder MEETINGS oder MANUAL, verweist auf die Meeting-Funktion innerhalb", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "der HubSpot-App, in der das Meeting erstellt wurde; MEETING entspricht", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "der „Meetinlink“-Funktion, MANUAL entspricht einem Meeting,", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "das in der CRM-Kommunikation erstellt wurde."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "startTime"
        }), ": die Startzeit des Meetings (in Epoche-Millisekunden)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "endTime"
        }), ": die Endzeit des Meetings (in Epoche-Millisekunden)"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Um diesen Webhook erfolgreich zu verarbeiten, sollte der Entwickler eine Videokonferenz für dieses Meeting erstellen (oder es mit einer bestehenden Konferenz verlinken) und mit Informationen zu dieser Konferenz antworten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispiel-Antwort:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example response\n{\n  \"conferenceId\": \"some-unique-id\",\n  \"conferenceUrl\": \"https://example.com/join\",\n  \"conferenceDetails\": \"Click here to join: https://example.com/join\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die in dieser Antwort erwarteten Felder sind:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceId"
        }), ": eine eindeutige ID, die der Konferenz in diesem Ereignis zugeordnet ist.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Diese ID muss in Ihrem gesamten System eindeutig sein. Wir geben diese ID", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "im Aktualisieren-Webhook an Sie zurück."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceUrl:"
        }), " der Join-Link für die erstellte Konferenz. Dieser kann", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "im Feld „location“ der Ereignisse platziert werden."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceDetails"
        }), ": Informationen zu „invitation“, nur Text. Sollte beschreiben,", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "wie Teilnehmer des Ereignisses auf die Videokonferenz", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "für dieses Ereignis zugreifen können. Neue Zeilen werden in Darstellungen dieses Texts in unser", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "System gepflegt. Es werden jedoch keine anderen Formatierungen unterstützt."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "„Meeting aktualisieren“-Webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "updateMeetingUri"
      }), " spezifiziert haben, sendet HubSpot dieser URI immer dann ein Anfrage, wenn sich meetingrelevante Details geändert haben. Diese Benachrichtigung ist notwendig, wenn Sie das aktuelle Thema oder die aktuellen Zeiten für eine Videokonferenz pflegen müssen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispielanfrage:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"conferenceId\": \"some-unique-id\",\n  \"userId\": 123,\n  \"userEmail\": \"test.user@example.com\",\n  \"portalId\": 123123,\n  \"topic\": \"A Test Meeting (updated)\",\n  \"startTime\": 1534197600000,\n  \"endTime\": 1534201200000\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die in dieser Antwort erwarteten Felder sind:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "conferenceId"
        }), ": die eindeutige ID für die Konferenz, die von Ihrer Integration in der Antwort auf den „Meeting erstellen“-Webhook bereitgestellt wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userId"
        }), ": die eindeutige ID für den HubSpot-Benutzer, der das Meeting moderiert. Dies ist immer die gleiche userId wie die Anfrage zur Erstellung von Meetings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "userEmail"
        }), ": die E-Mail-Adresse des HubSpot-Benutzers, der das Meeting moderiert. Dies ist immer die gleiche userEmail wie die Anfrage zur Erstellung von Meetings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "topic"
        }), ": das Thema bzw. der Titel des Meetings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "startTime"
        }), ": die Startzeit des Meetings (in Epoche-Millisekunden)"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "endTime"
      }), ": die Endzeit des Meetings (in Epoche-Millisekunden)"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie auf diese Anfragen antworten, ist ein „No response“-Text erforderlich. Wir erfordern nur", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "einen 200- oder 204-Antwortcode, um uns zu informieren, dass dieser Webhook", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "erfolgreich empfangen wurde."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "„Meeting löschen“-Webhook"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn ein Meeting in HubSpot gelöscht wird, sendet HubSpot eine Anfrage an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "deleteMeetingUri"
      }), ".", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Beispielanfrage:"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"conferenceId\": \"some-unique-id\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Anfrage enthält nur die conferenceId des Meetings, das gelöscht wurde.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Wenn Sie auf diese Anfragen antworten, ist ein „No response“-Text erforderlich. Wir erfordern nur", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "einen 200- oder 204-Antwortcode, um uns zu informieren, dass dieser Webhook", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "erfolgreich empfangen wurde."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "„Benutzer verifizieren“-Webhook"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Systeme von HubSpot kommunizieren ständig Informationen über die Benutzer, z. B. die HubSpot-ID und die E-Mail-Adresse des HubSpot-Accounts. Es besteht die Möglichkeit, dass ein Benutzer im System von HubSpot in Ihrem System mit einer anderen E-Mail-Adresse oder einer anderen ID vorhanden ist."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Bevor wir einen Aufruf für Ihr System starten, um einen Web-Konferenz-Link zu erstellen, zu aktualisieren oder zu löschen, werden zunächst Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userVerifyUri"
      }), "-Einstellungen überprüft. Wenn diese URI festgelegt wurde, starten wir einen Aufruf, um Ihre native Benutzer-ID abzurufen. Anschließend senden wir diese ID als E-Mail-Adresse des Benutzers im", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "nachfolgenden Aufruf."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt Orte in der HubSpot-App, an denen wir mit diesem Aufruf überprüfen können, ob der Benutzer in Ihrem System vorhanden ist, bevor wir die UI-Komponenten anzeigen, mit denen dieser interagieren kann. Es ist eine Art Vorüberprüfung. Wenn Sie diese URI nicht konfigurieren, gehen wir immer davon aus, dass die Identität des Benutzers verifiziert ist."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es ist Ihre Entscheidung, ob Sie diese Funktion unterstützen. Wenn Sie eine Benutzerzuordnung in Ihrem System beibehalten möchten, können Sie möglicherweise die HubSpot-Benutzer-ID oder E-Mail an Ihre interne Benutzer-ID in jedem Aufruf zuordnen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Die Anfrage sieht wie folgt aus:"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example request\n{\n  \"portalId\": 123123,\n  \"userEmail\": \"test.user@example.com\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können einen 200- oder einen beliebigen Fehlercode (z. B. 404) zurückgeben. Bei einem 200-Fehlercode sollten Sie eine Payload mit der neuen ID zurückgeben, die wir anstelle von „email“ verwenden sollen:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//example response\n{\n  \"id\": \"any-string-id\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhook-Signatur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle Webhooks, die von HubSpot gesendet wurden, sind mit HMAC unter Verwendung Ihres „App-Geheimnisses“ signiert. Weitere Informationen finden Sie auch im Abschnitt „Sicherheit“ ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "auf dieser Seite"
      }), ": (Der Rest dieser Webhook-Übersichtsseite gilt nicht für Webhooks für diese Videokonferenz-Erweiterung.)"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}