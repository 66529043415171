"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160601074021;
const slug = exports.slug = 'getting-started/overview';
const title = exports.title = 'はじめに：概要';
const name = exports.name = 'はじめに：概要（開発者ドキュメント2024）';
const metaDescription = exports.metaDescription = 'HubSpotで開発を始める方法について学びましょう。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E7%B5%B1%E5%90%88%E3%83%97%E3%83%A9%E3%83%83%E3%83%88%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E3%81%AE%E5%88%A9%E7%82%B9",
  "label": "統合プラットフォームの利点",
  "parent": null
}, {
  "depth": 0,
  "id": "1.%E4%BD%9C%E6%88%90%E3%81%AB%E5%BD%B9%E7%AB%8B%E3%81%A4%E3%83%84%E3%83%BC%E3%83%AB%E3%82%92%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E3%81%99%E3%82%8B",
  "label": "1.作成に役立つツールをインストールする",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-%E4%BD%95%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B%E3%81%8B%E3%82%92%E6%B1%BA%E3%82%81%E3%82%8B",
  "label": "2. 何を作成するかを決める",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-%E4%BB%B2%E9%96%93%E3%81%AB%E5%8A%A0%E3%82%8F%E3%82%8A%E3%81%BE%E3%81%97%E3%82%87%E3%81%86",
  "label": "3. 仲間に加わりましょう",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-%E8%87%AA%E5%88%86%E3%81%AB%E5%90%88%E3%81%A3%E3%81%9F%E6%96%B9%E6%B3%95%E3%81%A7%E5%AD%A6%E7%BF%92%E3%82%92%E7%B6%9A%E3%81%91%E3%81%BE%E3%81%99",
  "label": "4. 自分に合った方法で学習を続けます",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code",
      strong: "strong",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "はじめに：概要"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "初めてHubSpotの開発者になった人も、スキルを伸ばそうとしている開発者も、このセクションのガイドを参考にして使い始めることができます。ここでは、利用可能なさまざまな開発ルートについて、また目標の達成に必要な具体的なツールやアカウントについて説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "統合プラットフォームの利点"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "まず、HubSpotが開発者にとって強力なプラットフォームであると言える理由を見ていきましょう。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全てのHubSpotアカウントの基盤は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "CRM"
      }), " （顧客関係管理）プラットフォーム、つまりさまざまなビジネス関係とプロセスから成るデータベースです。HubSpotには、外部の開発者やパートナーがCRMと連携するためのさまざまなルートが備わっているので、HubSpotエンドユーザー向けにより多くの価値を生み出すことができます。次に例を示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotのAPIとの連携機能／拡張機能を作成して、HubSpotと外部プラットフォームの間でデータを同期します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpot CMS（コンテンツ管理システム）を使用してカスタムウェブサイトを作成します。コンテンツ管理ツールのスイート全体に加えて、CMSは完全にCRMと連携します。これにより、キャンペーンの開始、リード（見込み客）の獲得、顧客との継続的なコミュニケーションを管理しやすくなります。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "UI拡張機能を使用してCRMをカスタマイズする。開発者はReactを使用して、HubSpotとシームレスに連携する柔軟なカスタムカードを作成できます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のセクションでは、HubSpot開発者が利用できるツールの概要について説明します。その後、ルートを選んでHubSpot開発者ジャーニーを進んで行くことができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1.作成に役立つツールをインストールする"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot開発者向けに幅広いツールが用意されています。開発者の具体的な目標に応じて、必要なツールの組み合わせが異なります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**HubSpot CLI：**多くの開発タスクでは、ローカル開発用のHubSpot CLIをインストールすることが効果的な出発点となります。UI拡張機能とCMSアセットを作成するときにはCLIを使用し、シークレット、HubDBテーブル、およびカスタム オブジェクト データをアカウントから作成／管理するときにもCLIを使用できます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: (0, _jsxRuntime.jsx)("u", {
            children: "Node.jsが"
          })
        }), "インストールされている必要があります（必須バージョンは18以上、推奨されるバージョンはLTSです）。CLIをグローバルにインストールするには、次のコマンドを実行します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i -g @hubspot/cli\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Visual Studioコード："
          }), (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://code.visualstudio.com/",
            children: "Visual Studio Code"
          }), "をインストールすると、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
            children: "HubSpot Visual Studio Code拡張機能"
          }), "を利用して開発ワークフローを一元化できます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "クライアントライブラリー"
          }), "： HubSpotには、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-nodejs",
            children: "Node"
          }), "、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-php",
            children: "PHP"
          }), "、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-ruby",
            children: "Ruby"
          }), "、および", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-python",
            children: "Python"
          }), "用のクライアントライブラリーが備わっています。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Postman："
          }), (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/",
            children: "Postman"
          }), "を使用して、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/hubspot",
            children: "HubSpotのPostmanコレクション"
          }), "にアクセスします。HubSpotのAPIドキュメント全体にわたり、コレクションを使って呼び出しを実行するためのボタンがあります。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/run-in-postman-current-docs-site.png",
            alt: "run-in-postman-current-docs-site"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/tools-to-help-you-build",
        children: "HubSpotの開発者ツール"
      }), "について詳細をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. 何を作成するかを決める"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotで開発するためのルートは画一的ではありません。幅広いニーズに対応できる柔軟なプラットフォームです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下に挙げる4つの広範なカテゴリーのいずれかで、大部分のニーズが満たされるでしょう。現時点で最も該当するものを1つ選んでください。簡単な紹介と、すぐに開始できるステップが説明されます。また、ジョブに最適なツールについて理解し、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "特定の種類のHubSpotアカウント"
      }), "が必要かどうか確認することもできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#use-apis-and-build-integrations",
          children: "APIを使用して連携を作成する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#build-for-the-app-marketplace",
          children: "マーケットプレイス向けのアプリを作成する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#customize-the-hubspot-ui",
          children: "HubSpot UIをカスタマイズする"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#build-custom-websites",
          children: "カスタムウェブサイトを作成する"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. 仲間に加わりましょう"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "既にHubSpotで作成を行っている仲間とつながりましょう。質問をしたり、知識を共有したり、コミュニティーを作ったりして、やがて友達ができるかもしれません。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "開発者Slack"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
          children: "開発者フォーラム"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/hubspotdev",
          children: "開発者アドボカシーGitHub"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. 自分に合った方法で学習を続けます"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["始める準備が整ったら、より深く掘り下げるのに役立つ多くのリソースが用意されています。ご希望の学習スタイルに応じて、詳細なガイド、詳しい参考資料、または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/courses",
        children: "HubSpotアカデミーコース"
      }), "にアクセスできます。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}