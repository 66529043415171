"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 43178489215;
const slug = exports.slug = 'reference/cms/serverless-functions';
const title = exports.title = 'サーバーレスリファレンス';
const name = exports.name = 'APAC JAPAN (JA) | Serverless Reference | 202104';
const metaDescription = exports.metaDescription = 'serverless.json、関数ファイル、エンドポイント、CLIコマンド、パッケージの一元管理に関する参照情報。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "serverless.json",
  "label": "serverless.json",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%A8%E3%83%B3%E3%83%89%E3%83%9D%E3%82%A4%E3%83%B3%E3%83%88",
  "label": "エンドポイント",
  "parent": "serverless.json"
}, {
  "depth": 0,
  "id": "%E9%96%A2%E6%95%B0%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB",
  "label": "関数ファイル",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88",
  "label": "コンテキストオブジェクト",
  "parent": "%E9%96%A2%E6%95%B0%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB"
}, {
  "depth": 1,
  "id": "%E3%83%98%E3%83%83%E3%83%80%E3%83%BC",
  "label": "ヘッダー",
  "parent": "%E9%96%A2%E6%95%B0%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB"
}, {
  "depth": 2,
  "id": "%E3%83%98%E3%83%83%E3%83%80%E3%83%BC%E3%81%AE%E9%80%81%E4%BF%A1%E3%81%AB%E3%82%88%E3%82%8B%E3%83%AA%E3%83%80%E3%82%A4%E3%83%AC%E3%82%AF%E3%83%88",
  "label": "ヘッダーの送信によるリダイレクト",
  "parent": "%E9%96%A2%E6%95%B0%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB"
}, {
  "depth": 1,
  "id": "%E3%82%A8%E3%83%B3%E3%83%89%E3%83%9D%E3%82%A4%E3%83%B3%E3%83%88%E3%81%8B%E3%82%89cookie%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "エンドポイントからCookieを設定する",
  "parent": "%E9%96%A2%E6%95%B0%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB"
}, {
  "depth": 1,
  "id": "1%E3%81%A4%E3%81%AE%E3%83%98%E3%83%83%E3%83%80%E3%83%BC%E3%81%AB%E8%A4%87%E6%95%B0%E3%81%AE%E5%80%A4%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "1つのヘッダーに複数の値を設定する",
  "parent": "%E9%96%A2%E6%95%B0%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB"
}, {
  "depth": 0,
  "id": "%E3%82%B7%E3%83%BC%E3%82%AF%E3%83%AC%E3%83%83%E3%83%88",
  "label": "シークレット",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E8%A6%81%E7%B4%A0%E3%81%8B%E3%82%89%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%83%AC%E3%82%B9%E9%96%A2%E6%95%B0%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B",
  "label": "フォーム要素からサーバーレス関数を使用する",
  "parent": null
}, {
  "depth": 0,
  "id": "cors",
  "label": "CORS",
  "parent": null
}, {
  "depth": 1,
  "id": "get%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88",
  "label": "GETリクエスト",
  "parent": "cors"
}, {
  "depth": 0,
  "id": "%E4%BA%8B%E5%89%8D%E3%81%AB%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BE%E3%82%8C%E3%82%8B%E3%83%91%E3%83%83%E3%82%B1%E3%83%BC%E3%82%B8",
  "label": "事前に読み込まれるパッケージ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%88%B6%E9%99%90",
  "label": "制限",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      a: "a",
      code: "code",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      h4: "h4",
      em: "em",
      ul: "ul",
      li: "li",
      br: "br",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "サーバーレス関数リファレンス"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "開発者プロジェクト"
        }), "の一部としてサーバーレス関数を作成する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "開発者プロジェクトのサーバーレス関数のドキュメント"
        }), "を参照してください。以下のドキュメントは、開発者プロジェクトプラットフォーム以外でサーバーレス関数を作成する場合に使用します。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この記事では、", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview#serverless-function-folders",
        children: ["サーバーレスの", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".functions"
        }), "フォルダー"]
      }), "内にあるファイルと、サーバーレス関数で使用できるCLIコマンドについて説明します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サーバーレス関数の概要については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "サーバーレス関数の概要"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/cms-js-building-block-examples/",
        children: "JavaScriptのレンダリングされたモジュールとパーシャル"
      }), "のプロジェクトを使用してサーバーレス関数を作成する方法の詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/private-apps/serverless-functions",
        children: "開発者プロジェクトのドキュメント"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "serverless.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview#serverless-function-folders",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: ".functions"
        }), "フォルダー"]
      }), "の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "ファイルには、サーバーレス関数構成が保存されます。これは必須ファイルであり、これによって関数が", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#endpoints",
        children: "エンドポイント"
      }), "にマッピングされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// serverless.json\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"environment\": {\n    \"globalConfigKey\": \"some-value\"\n  },\n  \"secrets\": [\"secretName\"],\n  \"endpoints\": {\n    \"events\": {\n      \"file\": \"function1.js\",\n      \"method\": \"GET\"\n    },\n    \"events/update\": {\n      \"method\": \"POST\",\n      \"file\": \"action2.js\",\n      \"environment\": {\n        \"CONFIG_KEY\": \"some-other-value\"\n      },\n      \"secrets\": [\"googleKeyName\", \"otherkeyname\"]\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "キー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "runtime"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "必須"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ランタイム環境。次の", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://nodejs.org/en/about/",
              children: "Node.js"
            }), "バージョンをサポートします。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["ノード20（", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nodejs20.x"
                }), "）（推奨）"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["ノード18（", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "nodejs18.x"
                }), "）"]
              })]
            }), "2024年7月12日以降、HubSpotは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/changelog/deprecation-of-node-v16-in-all-serverless-functions",
              children: "ノード16をサポートしなくなります"
            }), "。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "version"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "必須"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotサーバーレス関数スキーマバージョン。（現在のバージョン1.0）"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "environment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["実行環境で、実行される関数に", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
              children: "環境変数"
            }), "として渡される設定用変数。環境変数に基づいて、実際のAPIの代わりにテストバージョンを使用するロジックを追加する場合などに使用します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "secrets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サーバーレス関数が認証に使用するシークレットの名前を含む配列。このファイルにはシークレット値を直接入力しないで、シークレット名の参照にとどめてください。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoints"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "必須"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "エンドポイントでは、functionsフォルダーにおいて、公開されるパスおよび特定のJavaScriptファイルへのマッピングが定義されます。エンドポイントの詳細については、以下で説明しています。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**シークレットと環境変数に同じ名前を割り当てないでください。同じ名前を割り当てると、関数で値が返されるときに競合が発生します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "エンドポイント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["各エンドポイントには独自の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/docs/latest-v10.x/api/process.html#process_process_env",
        children: "環境変数"
      }), "とシークレットを設定できます。エンドポイント外で指定した変数は、全ての関数とエンドポイントに適用する設定として使用されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "\"events/update\": {\n      \"method\": \"POST\",\n      \"file\": \"action2.js\",\n      \"environment\": {\n        \"configKey\": \"some-other-value\"\n      },\n      \"secrets\": [\"googleAPIKeyName\",\"otherKeyName\"]\n    }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "エンドポイントにはいくつかの固有のキーがあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "キー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "method"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列または文字列からなる配列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["このエンドポイントによってサポートされる", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Methods",
              children: "HTTPメソッド"
            }), "（複数可）。既定では", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Methods/GET",
              children: "GET"
            }), "です。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "必須"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "エンドポイントの実装があるJavaScript関数ファイルへのパス。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サーバーレス関数は、HubSpot CMSアカウントのドメインのパスを通して公開されます。これには、既定の", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".hs-sites.com"
      }), "サブドメインも含まれます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらの関数には、次のURLでアクセスできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://{domainName}/_hcms/api/{endpoint-name/path}?portalid={hubId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下では、各URLコンポーネントについて説明します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "domainName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "自分のドメイン名。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/_hcms/api/"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サーバーレス関数用に予約されたパス。このパスには、全てのエンドポイントが含まれます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endpoint-name/path"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "serverless.json"
            }), "ファイルで指定されているエンドポイント名またはパス。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "自分のHub ID。これをリクエストに含めることにより、モジュールおよびテンプレートのプレビュー時に関数をテストできます。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関数ファイル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "構成ファイルに加え、", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".functions"
      }), "フォルダーにも、関数を定義する", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://nodejs.org/en/",
        children: "Node.js"
      }), " JavaScriptファイルが含まれています。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/request/request#readme",
        children: "request"
      }), "ライブラリーを利用して、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "HubSpot API"
      }), "や他のAPIへのHTTPリクエストを行うこともできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例として、以下のような場合が挙げられます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Require axios library, to make API requests.\nconst axios = require('axios');\n// Environment variables from your serverless.json\n// process.env.globalConfigKey\n\nexports.main = (context, sendResponse) => {\n  // your code called when the function is executed\n\n  // context.params\n  // context.body\n  // context.accountId\n  // context.limits\n\n  // secrets created using the CLI are available in the environment variables.\n  // process.env.secretName\n\n  //sendResponse is what you will send back to services hitting your serverless function.\n  sendResponse({ body: { message: 'my response' }, statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "コンテキストオブジェクト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンテキストオブジェクトには、次のパラメーターに格納された、関数の実行に関するコンテキスト情報が含まれています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accountId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "関数が含まれているHubSpotアカウントのID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["コンテンツタイプ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "application/json"
            }), "の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), "リクエストとして送信する場合に入力します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Cookieに紐付けられたコンタクトから送信されたリクエストの場合、コンタクトオブジェクトには、基本的なコンタクトプロパティーに加え、次の情報が含められます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "vid"
                }), "：コンタクトの訪問者ID。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "isLoggedIn"
                }), "：CMSのアクセス権設定（メンバーシップ機能）を使用した状態で、コンタクトがドメインにログインしている場合は", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "になります。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "listMemberships"
                }), "：このコンタクトがメンバーとして含まれる、コンタクトリストIDの配列。"]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "headers"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["エンドポイントに対してクライアントから送信された", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#headers",
              children: "ヘッダー"
            }), "が含まれます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "params"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["クエリー文字列値、およびHTMLフォームによるPOST値が入ります。文字列によるキーと、文字列の配列による値から成るマップとして構成されます。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "context.params.yourvalue"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limits"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/serverless-functions/overview#know-your-limits",
              children: "サーバーレス関数のレート制限"
            }), "にどの程度近づいているかを返します。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "executionsRemaining"
                }), "：残っている実行数（1分あたり）。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "timeRemaining"
                }), "：残っている実行可能時間。"]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ヘッダー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["エンドポイントにアクセスしているクライアントのヘッダーを知る必要がある場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.body"
      }), "を使用して情報にアクセスする場合と同様に、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "context.headers"
      }), "を使用してこれらのヘッダーにアクセスできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下で、HubSpotが提供する一般的なヘッダーのいくつかについて確認します。完全なリストについては、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Headers",
        children: "MDNのHTTPヘッダーのドキュメント"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "ヘッダー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["クライアント側で処理できるコンテンツタイプを", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Basics_of_HTTP/MIME_types",
              children: "MIMEタイプ"
            }), "として示します。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Headers/Accept",
              children: "MDN"
            }), "を参照してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept-encoding"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["クライアント側で処理できるコンテンツエンコーディングを示します。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Headers/Accept-encoding",
              children: "MDN"
            }), "を参照してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accept-language"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["使用される言葉とロケールを示します。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Headers/Accept-language",
              children: "MDN"
            }), "を参照してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cache-control"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["キャッシュ用のディレクティブを保持します。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Headers/Cache-Control",
              children: "MDN"
            }), "を参照してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "connection"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ネットワーク接続が開いたままかどうかを示します。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Headers/connection",
              children: "MDN"
            }), "を参照してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cookie"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["クライアントから送信されたCookieが含まれます。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Headers/cookie",
              children: "MDN"
            }), "を参照してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "host"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["リスニングサーバーのドメイン名とTCPポート番号を示します。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Headers/host",
              children: "MDN"
            }), "を参照してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true-client-ip"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["エンドユーザーのIPアドレス。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://support.cloudflare.com/hc/en-us/articles/206776727-What-is-True-Client-IP-",
              children: "Cloudflare true-client-ip"
            }), "を参照してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "upgrade-insecure-requests"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["レスポンスの暗号化および認証について、クライアント側の設定を示します。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Headers/Upgrade-Insecure-Requests",
              children: "MDN"
            }), "を参照してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "user-agent"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アプリケーション、オペレーティングシステム、アプリケーションベンダー、バージョンを識別するためのベンダー定義文字列。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Headers/User-Agent",
              children: "MDN"
            }), "を参照してください。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "x-forwarded-for"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["プロキシーまたはロードバランサーを通過するクライアントの発信元IPアドレスを識別します。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Headers/X-Forwarded-For",
              children: "MDN"
            }), "を参照してください。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "ヘッダーの送信によるリダイレクト"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.em, {
        children: "location"
      }), "ヘッダーとstatusCode ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "301"
      }), "を含むレスポンスを送信することで、サーバーレス関数からリダイレクトを実行できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "sendResponse({\n  statusCode: 301,\n  headers: {\n    Location: 'https://www.example.com',\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "エンドポイントからCookieを設定する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サーバーレス関数によって、クライアント（ウェブブラウザー）にCookieを設定するように指示できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n    sendResponse({\n      body: { ... },\n      'Set-Cookie': 'myCookie1=12345; expires=...; Max-Age=...',\n      statusCode: 200\n    });\n  }\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1つのヘッダーに複数の値を設定する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["複数の値をサポートするヘッダーには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multiValueHeaders"
      }), "を使用して値を渡すことができます。例えば、ブラウザーに複数のCookieを設定するように指示できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "exports.main = (context, sendResponse) => {\n  sendResponse({\n    body: { ... },\n    multiValueHeaders: {\n      'Set-Cookie': [\n        'myCookie1=12345; expires=...; Max-Age=...',\n        'myCookie2=56789; expires=...; Max-Age=...'\n       ]\n    },\n    statusCode: 200\n  });\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "シークレット"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サーバーレス関数リクエストを認証する必要がある場合は、シークレットを使用して、APIキーや非公開アプリのアクセストークンなどの値を保存します。CLIを使用して、それらの値を保存するシークレットをHubSpotアカウントに追加することができます。その後でこれらの値にアクセスするには、環境変数（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "process.env.secretName"
      }), "）を使用します。シークレットの管理には、HubSpot CLIで以下のコマンドを使用します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#list-secrets",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets list"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#add-secret",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets add"
          })
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#remove-secret",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs secrets delete"
          })
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CLIを使用して追加されたシークレットは、そのシークレット名を含む", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secrets"
      }), "配列を含めることで、関数で使用できるようになります。これにより、関数コードを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "バージョン管理"
      }), "に保存し、シークレットを公開せずに使用できるようになります。ただし、コンソールログを使用して、またはレスポンスとして、シークレットの値を", (0, _jsxRuntime.jsx)("u", {
        children: "返さないでください"
      }), "。そうすると、ログや、サーバーレス関数を呼び出すフロントエンドページで、シークレットが公開される恐れがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**キャッシュ保存のため、更新されたシークレット値が表示されるまでには、1分ほどかかる場合があります。シークレットを更新したばかりで、まだ古い値が表示されている場合は、1分ほどしてからもう一度ご確認ください。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フォーム要素からサーバーレス関数を使用する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["サーバーレス関数の送信時には、JavaScriptを使ってフォーム送信を処理し、リクエストに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"contentType\" : \"application/json\""
      }), "ヘッダーを使用します。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<form>"
      }), "要素の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "action"
      }), "属性は使用しないでください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CORS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/CORS",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CORS（オリジン間リソース共有）"
        })
      }), "はブラウザーのセキュリティー機能です。ブラウザーでは既定で、JavaScriptによって開始されたクロスオリジンリクエストが制限されます。これにより、異なるドメイン間で有害コードが実行され、貴社のサイトに影響を与えることを防止します。これは同一オリジンポリシーと呼ばれます。他のサーバーとのデータの送受信が必要な場合には、ブラウザーからの情報の読み取りをどのオリジン（発信元）に許可するかを示すHTTPヘッダーが外部サーバーから提供されることがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot内にホスティングされたページでのサーバーレス関数の呼び出しにおいては、CORSの問題は発生しません。発生する場合、正しいプロトコルを使用していることを確認してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "次のCORSエラーが発生している場合"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.em, {
          children: "「発信元［リクエスト元ページ］から［関数URL］へのフェッチ（取得）アクセスが、CORSポリシーによってブロックされました：プリフライトリクエストへのレスポンスが、アクセス制御検査に合格しません：要求されたリソースにはAccess-Control-Allow-Originヘッダーがありません。複雑なレスポンスを使用する場合は、リクエストのモードをno-corsに設定して、CORSが無効化されたリソースを取得してください。」"
        })]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "呼び出し元サイトとは異なるオリジンへのリクエストとは？"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "ドメイン名が異なる場合は、該当します。"
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "使用しているプロトコル（http、https）が異なる場合は、該当します。"
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "使用しているプロトコルが異なる場合は、プロトコルを合わせて変更するだけで解決します。"
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["現時点では、HubSpotの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Access-Control-Allow-Origin"
        }), "ヘッダーを変更することはできません。"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/CORS/Errors",
          children: "CORSエラーのトラブルシューティングに関する詳細はMDNを参照してください。"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "GETリクエスト"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["クライアントによっては、GETリクエストで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/CORS",
        children: "CORSリクエスト"
      }), "を行うことが可能な場合があります。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/ja-JP/docs/Web/HTTP/Methods/GET",
        children: "GET"
      }), "リクエストでは書き込みを行わないで、データを返すだけにしてください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "事前に読み込まれるパッケージ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "現在、HubSpotのサーバーレス関数には、以下の事前読み込みパッケージがあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/@hubspot/api-client",
          children: "@hubspot/api-client"
        }), "：1.0.0-beta以降"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), "：0.19.2以降"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/request",
          children: "request"
        }), "：2.88.0以降"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/requests",
          children: "requests"
        }), "：0.2.2以降"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サポートされる最新バージョンの事前読み込み済みパッケージ、または新たに追加されたパッケージを使用するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "関数ファイルを複製またはコピーします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "serverless.json"
        }), "ファイル内の関数のエンドポイントを変更して、新しい関数ファイルを指すようにします。旧バージョンは問題なく削除できます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["事前に読み込まれる一連のパッケージには含まれないパッケージをインクルードするには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://webpack.js.org/",
        children: "webpack"
      }), "を使用してノードモジュールを結合し、バンドルされたファイルを関数ファイルとして使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "制限"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "サーバーレス関数は、高速の特定用途を対象として設計されています。迅速な呼び出しとレスポンスを可能にするために、HubSpotのサーバーレス関数には、次のような制限があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "1アカウントあたり50件のシークレット。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "128MBのメモリー。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアカウントあたり100件以下のエンドポイント。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["関数の呼び出しには", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contentType"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "application/json"
        }), "を使用する必要があります。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "サーバーレス関数ログの保存期間は90日。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "AWS Lambda呼び出しでのペイロードは6MB。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "実行制限"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "各関数の実行時間は最大10秒に制限されます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "各アカウントでは、1分あたり実行時間が合計600秒に制限されています。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "つまり、次のいずれかのような状況での利用が可能です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "10秒で完了する関数を60回実行する。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "100ミリ秒で完了する関数を6,000回実行する。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらの制限を超える関数は、エラーになります。実行数と時間の制限には、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), "のレスポンスが返されます。各関数の実行時間は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#logs",
        children: "サーバーレス関数ログ"
      }), "に記録されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["上記の制限を回避できるよう、実行中に自動で制限データが", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/serverless-functions#function-file",
        children: "関数コンテキスト"
      }), "に提供されます。その情報に基づいて、アプリケーションを制限内にとどめるような調整を行うことができます。例えばアプリケーションからエンドポイントへのポーリングが必要な場合、ポーリング頻度を調整するための変数をデータと共に返すことができます。これにより、トラフィックの増大時にも制限に到達しないようにポーリング速度を抑え、トラフィックの減少後に元に戻すことが可能になります。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}