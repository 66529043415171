"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279882;
const slug = exports.slug = 'reference/cms/fields/rich-text-editor';
const title = exports.title = 'Configurar el Editor de texto enriquecido';
const name = exports.name = 'EMEA ES Configuring the Rich Text Editor';
const metaDescription = exports.metaDescription = 'El Editor de texto enriquecido dentro de los módulos personalizados ahora proporciona a los desarrolladores la capacidad de eliminar componentes de la barra de herramientas de configuración.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "c%C3%B3mo-usar-enabled_features",
  "label": "Cómo usar enabled_features",
  "parent": null
}, {
  "depth": 0,
  "id": "lista-de-caracter%C3%ADsticas",
  "label": "Lista de características",
  "parent": null
}, {
  "depth": 1,
  "id": "grupos-de-controles",
  "label": "Grupos de controles",
  "parent": "lista-de-caracter%C3%ADsticas"
}, {
  "depth": 1,
  "id": "formato-de-texto",
  "label": "Formato de texto",
  "parent": "lista-de-caracter%C3%ADsticas"
}, {
  "depth": 1,
  "id": "insertar-botones",
  "label": "Insertar botones",
  "parent": "lista-de-caracter%C3%ADsticas"
}, {
  "depth": 1,
  "id": "opciones-avanzadas",
  "label": "Opciones avanzadas",
  "parent": "lista-de-caracter%C3%ADsticas"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      h2: "h2",
      pre: "pre",
      img: "img",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configurar el Editor de texto enriquecido"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como desarrollador, hay veces que los editores de WYSIWYG proporcionan funciones que, cuando se usan incorrectamente, pueden dificultar el objetivo de una marca unificada y causar problemas de diseño y flujo de contenido. El Editor de texto enriquecido dentro de los módulos personalizados ahora proporciona a los desarrolladores la capacidad de eliminar componentes de la barra de herramientas de configuración a través de la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), " dentro del archivo fields.json."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Nota: lo siguiente se aplica a los módulos personalizados que utilizan el campo de texto enriquecido solo en desarrollo local. El uso de esta función no deshabilitará la funcionalidad de las opciones eliminadas del Editor de texto enriquecido, solo la presentación de las opciones. Esto es por razones de compatibilidad con versiones anteriores para que el contenido existente no se vea afectado."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cómo usar enabled_features"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En tu archivo fields.json donde tienes tu objeto de campo de texto enriquecido, puedes activar ciertas características agregando las opciones válidas de la barra de herramientas en una matriz a la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), " como se ilustra a continuación:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Rich text field with only Bold, Link, and Image available in the Toolbar\n{\n  \"name\": \"description\",\n  \"label\": \"Description\",\n  \"required\": false,\n  \"locked\": false,\n  \"type\": \"richtext\",\n  \"default\": null,\n  \"enabled_features\": [\"bold\", \"link\", \"image\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El editor de contenido luego vería el editor de texto enriquecido con solo las opciones incluidas habilitadas como se ilustra en la imagen a continuación:"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Nota: algunas características, como el botón \"Borrar estilos\" que te permite volver al estilo predeterminado para el editor, siempre estarán habilitadas y no se pueden eliminar. Si se omite la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "enabled_features"
        }), ", se mostrarán todas las características."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/custom_rte_toolbar.jpg",
        alt: "Un ejemplo de una barra de herramientas de RTE con funciones habilitadas."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lista de características"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación se muestra una lista de características que se pueden habilitar individualmente al usar la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enabled_features"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Grupos de controles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Opción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "colors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Controles de color de texto y color de fondo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fonts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Familia de fuentes y controles de tamaño de fuente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "indents"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Controles de sangría."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lists"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Controles de listas numeradas y con viñetas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard_emphasis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Controles de negrita, cursiva y subrayado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "advanced_emphasis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Controles de tachado, superíndice, subíndice y formato de código."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "glyphs"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Controles de emojis, caracteres especiales e iconos. No se admite en los módulos de correo electrónico. Para agregar el selector de emojis a los módulos de correo electrónico, usa ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#insert-buttons",
              children: "emojis"
            }), " en su lugar."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formato de texto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Opción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "block"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el menú desplegable del conmutador de estilo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "font_family"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el menú desplegable del conmutador de fuentes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "font_size"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el menú desplegable de tamaño de la fuente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bold"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de negrita."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "italic"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de cursiva."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "underline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de subrayado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de color del texto."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de color de fondo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de alineación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bulleted_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de lista con viñetas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numbered_list"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de listas numeradas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lineheight"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de altura de línea."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "outdent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de atenuación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "indent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de sangría."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "strikethrough"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de tachado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "superscript"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de superíndice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscript"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de subíndice."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "code_format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de formato de código."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Insertar botones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Opción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de enlace."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de imagen. No se admite en los módulos de correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "emoji"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el botón de emoji."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "personalize"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el elemento de barra de herramientas de personalización."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cta"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el elemento del menú de llamada a la acción en el menú de inserción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el elemento de menú de incrustación en el menú de inserción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el elemento del menú de video en el menú de inserción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el elemento del menú de la tabla en el menú de inserción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "charmap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el elemento del menú de caracteres especiales en el menú de inserción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "anchor"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el elemento del menú de anclaje en el menú de inserción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hr"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra la posición del menú de línea horizontal en el menú de inserción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nonbreaking_space"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el elemento de menú de espacio de no ruptura en el menú de inserción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el elemento del menú de icono en el menú de inserción."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Opciones avanzadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Opción"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "source_code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el elemento del menú de código fuente en el menú avanzado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "visual_blocks"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Muestra el elemento de menú mostrar bloques en el menú avanzado."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}