"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79942329388;
const slug = exports.slug = 'guides/cms/marketplace/listing-templates';
const title = exports.title = 'HubSpotテンプレートマーケットプレイスでのテンプレートの掲載と更新';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Pages | Marketplace Requirements Overview > Listing CMS Assets | 202209';
const metaDescription = exports.metaDescription = 'テンプレート マーケットプレイス プロバイダー アカウントを作成した後、それを使用してテンプレート掲載情報を作成し、承認に向けてテンプレートを提出することができます。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E6%8E%B2%E8%BC%89%E6%83%85%E5%A0%B1%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "テンプレート掲載情報を作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E6%8E%B2%E8%BC%89%E6%83%85%E5%A0%B1%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "テンプレート掲載情報を更新する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      img: "img",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpotテンプレートマーケットプレイスでのテンプレートの掲載と更新"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "テンプレート マーケットプレイス プロバイダー アカウント"
      }), "を作成した後、それを使用してテンプレート掲載情報を作成し、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/templates",
        children: "HubSpotテンプレートマーケットプレイス"
      }), "での掲載の承認に向けてテンプレートを提出できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["注：", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "各タイプ（モジュールまたはテンプレート）の新しい提出物は、一度のレビューにつき"
          }), "1つ"]
        }), "のみ提出可能です。現在公開または承認されていない場合、サブミッションは「新規」と見なされます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テンプレートマーケットプレイスにテンプレートを掲載する前に、次のようにプロバイダープロファイルを入力します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テンプレート マーケットプレイス プロバイダー アカウントで、［テンプレートマーケットプレイス］>［プロバイダープロファイル］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［会社情報］セクションの全てのフィールドに入力します。これらのフィールドはテンプレート掲載情報に表示されます。会社名が選択された後は、会社名を編集できなくなります。会社名を変更するには、HubSpotサポートに連絡する必要があります。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["［コンタクト情報］セクションにある［主な連絡先］セクションと［開発者の連絡先］セクションの両方で、全てのフィールドに入力します。ここに入力された情報は、掲載情報に関してHubSpotから連絡させていただく場合にのみ使用され、掲載情報ページには", (0, _jsxRuntime.jsx)("u", {
          children: "表示されません"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［支払い情報］セクションで、サポートされている支払い方法を追加します。PayPalまたはStripeを支払いゲートウェイとして接続できます。支払い情報が原因で支払いゲートウェイが機能しなくなった場合は、顧客に悪い影響が及ばないようにするために、テンプレートがリストから一時的に除外されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレート掲載情報を作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テーマまたはモジュールの掲載情報を作成して送信するには："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テンプレート マーケットプレイス プロバイダー アカウントで、［テンプレートマーケットプレイス］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上の［掲載情報を作成］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［テーマの掲載情報を作成］または［モジュールの掲載情報を作成］を選択します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［プロバイダー情報］ページの情報を確認し、［次へ］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［支払い情報］ページで支払い情報を確認し、［次へ］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["掲載したい", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "テーマまたはモジュール"
        }), "を選択し、［保存して続行］をクリックします。これにより、掲載情報エディターが表示されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/theme-listing-marketplace.png",
        alt: "theme-listing-marketplace"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["掲載情報エディターは、［掲載情報］、［テーマ／モジュールの詳細］、［カテゴリー］、［サポート］、［レビュー］の5つのタブに分かれています。特に明記されていない限り、全ての情報が必要です。リストページの全ての要件は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/docs/cms/marketplace-guidelines/general-requirements",
        children: "こちら"
      }), "でご確認いただけます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［レビュー］タブでテンプレートのコードを検証し、掲載情報をプレビューします。問題がないようであれば、［審査に提出］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**提出後にテンプレートフォルダー名を変更しないでください。テンプレート掲載情報は、提出時にこのフォルダーに直接リンクされます。フォルダー名を変更すると、テンプレートを再提出したり更新したりできなくなります。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テンプレート掲載情報を更新する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "作成後のテンプレート掲載情報は、テンプレート マーケットプレイス プロバイダー アカウントで更新できます。テンプレートに変更を加えた場合は、それらの変更がプッシュアウトされる前にテンプレートを再検証する必要があります。再検証しない場合、マーケットプレイスの掲載情報ページ上のコンテンツのみが更新されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "テンプレート掲載情報を更新するには："
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "テンプレート マーケットプレイス プロバイダー アカウントで、［テンプレートマーケットプレイス］>［リスティング］に移動します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["更新する掲載情報の", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "名前"
          }), "をクリックします。掲載情報エディターにリダイレクトされます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "掲載情報、テンプレートの詳細、カテゴリー、サポートオプションを必要に応じて変更した後、［レビュー］タブをクリックして変更内容を確認します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［レビュー］タブで、最後の検証以降にテンプレートのコードが変更されたかどうかを次のように確認します。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "テンプレートが変更されていない場合、検証が最新状態であることを示す確認メッセージがHubSpotの［テーマのソースコード］タイルまたは［モジュールのソースコード］タイルに表示されます。"
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/asset-marketplace-validation-up-to-date.png",
            alt: "asset-marketplace-validation-up-to-date"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "テンプレートに変更を加えた場合、HubSpotのソース コード タイルにアラートが表示されます。これらの変更をマーケットプレイスに送信するには、まず［検証を実行］をクリックする必要があります。"
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/asset-marketplace-run-validation.png",
            alt: "asset-marketplace-run-validation"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**5.**右上の［審査に提出］をクリックします。"
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}