"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 63063391003;
const slug = exports.slug = 'guides/apps/extensions/calling-extensions/recordings-and-transcriptions';
const title = exports.title = 'Extending the CRM | Recordings and transcriptions';
const name = exports.name = 'vNext Docs DP - Recordings and transcriptions';
const metaDescription = exports.metaDescription = 'Learn how to log call recordings and their associated transcriptions in HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "requirements",
  "label": "Requirements",
  "parent": null
}, {
  "depth": 0,
  "id": "create-an-endpoint-to-provide-an-authenticated-recording-url-for-a-call",
  "label": "Create an endpoint to provide an authenticated recording URL for a call",
  "parent": null
}, {
  "depth": 0,
  "id": "register-your-app-s-endpoint-with-hubspot-using-the-calling-settings-api",
  "label": "Register your app's endpoint with HubSpot using the calling settings API",
  "parent": null
}, {
  "depth": 0,
  "id": "log-a-call-with-your-app-s-endpoint-using-the-engagements-api",
  "label": "Log a call with your app's endpoint using the engagements API",
  "parent": null
}, {
  "depth": 0,
  "id": "mark-a-call-recording-as-ready",
  "label": "Mark a call recording as ready",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      code: "code",
      strong: "strong",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Recordings and transcripts"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to make call recordings playable in your HubSpot account, or you want to build on top of HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/manage-phone-numbers-registered-for-calling#turn-on-conversation-intelligence-sales-hub-or-service-hub-enterprise-only",
        children: "Conversation Intelligence"
      }), " functionality, you can use the endpoints to automatically transcribe calls and log them within HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Requirements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpot will only transcribe calls associated with ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "https://knowledge.hubspot.com/account/manage-sales-hub-and-service-hub-paid-users",
          children: ["users with a paid ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Sales"
          }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Services"
          }), " hub seat"]
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Only .WAV, .FLAC, and .MP4 audio files will be transcribed."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The audio file must be downloadable as an octet-stream."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the transcription system, HubSpot splits the audio file into its different channels and treats each channel as a separate speaker. If all of the speakers are on the same audio channel, or if the caller or recipient are on an unexpected channel, HubSpot will ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " be able to transcribe the audio recording. Therefore, each speaker in an audio file should be on a separate channel. For calls with two channels, the caller should be on channel 1, and the call recipient should be on channel 2, regardless of whether the call is inbound or outbound."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If your users want to fast forward or rewind a call recording in the HubSpot app, the recording URL needs to respect the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "range"
        }), " header and return a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "206 partial content"
        }), "​(not a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "200"
        }), "server code)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create an endpoint to provide an authenticated recording URL for a call"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To list and transcribe calls on a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/view-the-history-of-an-activity-in-a-timeline",
        children: "record's timeline"
      }), " in HubSpot, create an endpoint that will be invoked to retrieve the authenticated call URLs associated with each engagement."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your endpoint should accept the following parameters:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "externalId:"
        }), " the unique ID associated with a call URL, provided as a path parameter. This will correspond to the same parameter you include in the metadata of your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to the engagements API, which you can then use in your app's backend to associate with the recording URL."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "externalAccountId:"
        }), " a unique ID associated with the HubSpot account that made the call engagement, provided as a query parameter. You can use this parameter along with the externalId to identify the call recording."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "appId:"
        }), " the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/how-do-i-find-the-app-id",
          children: "ID of your app"
        }), ", provided as a query parameter."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Your endpoint should return a JSON response with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "authenticatedUrl"
      }), " field that provides the recording URL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Response to GET request to your app's endpoint\n{\n  \"authenticatedUrl\": \"https://app-test.com/retrieve/authenticated/recordings/test-call-01\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Register your app's endpoint with HubSpot using the calling settings API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once your endpoint is ready, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request using your app's ID to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/extensions/calling/{appId}/settings/recording"
      }), " and provide the URL of your endpoint with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "urlToRetrieveAuthedRecording"
      }), " parameter in the body of your request."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Your endpoint's URL must contain the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "%s"
        }), " character sequence, which HubSpot will substitute with the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "externalId"
        }), " of the engagement when calling your endpoint. The ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "%s"
        }), " character sequence can be located anywhere in your URL."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Provide the full path of your endpoint URL in your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request, including the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "https://"
        }), " prefix."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to configure your app's endpoint\n{\n  \"urlToRetrieveAuthedRecording\": \"https://app-test.com/retrieve/authenticated/recordings/%s\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you change the location of your endpoint, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to the same HubSpot endpoint above and provide an updated value for ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "urlToRetrieveAuthedRecording"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Log a call with your app's endpoint using the engagements API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After you've registered your calling app's endpoint with HubSpot, you can log a call by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/calls",
        children: "endpoint"
      }), ", and including the engagement data within the properties field in the body of your request."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_external_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_external_account_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_app_id"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_source"
      }), " properties are required to ensure that HubSpot can fetch the authenticated recording URL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The body of an example request is shown below:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request to https://api.hubapi.com/crm/v3/objects/calls\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-17T01:32:44.872Z\",\n    \"hs_call_title\": \"Test v3 API\",\n    \"hubspot_owner_id\": \"11526487\",\n    \"hs_call_body\": \"Decision maker out, will call back tomorrow\",\n    \"hs_call_duration\": \"3800\",\n    \"hs_call_from_number\": \"(555) 555 5555\",\n    \"hs_call_to_number\": \"(555) 555 5555\",\n    \"hs_call_source\": \"INTEGRATIONS_PLATFORM\", // this has to be INTEGRATIONS_PLATFORM\n    \"hs_call_status\": \"COMPLETED\",\n    \"hs_call_app_id\": \"test-app-01\",\n    \"hs_call_external_id\": \"test-call-01\",\n    \"hs_call_external_account_id\": \"test-account-01\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Next, you'll need to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/calls#associate-calls-with-records",
        children: "associate the call with a record type"
      }), " to ensure the transcript appears on the record timeline."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To make this association, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PUT"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/calls/{callId}/associations/{toObjectType}/{toObjectId}/{associationType}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For example, if the ID of the logged call you created above is ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "17591596434"
        }), ", the ID of the contact you wanted to associate it with is ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "104901"
        }), ", and the ID of the associationType is ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "194"
        }), ", your request URL would be:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/calls/17591596434/associations/contacts/104901/194"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When one of your app's users navigates to the associated record timeline to view the engagement, HubSpot will call the endpoint you configured to serve the authenticated recording URL. For example, to retrieve the recording URL associated with the example engagement above, HubSpot would make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://app-test.com/retrieve/authenticated/recordings/test-call-01?appId=app-101&externalAccountId=test-account-01"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mark a call recording as ready"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Create the call object as shown ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-a-call-with-your-app-s-endpoint",
        children: "above"
      }), ", and then do the following:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/extensions/calling/recordings/ready"
      }), " with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "engagementId"
      }), " for the call that was created. This will notify HubSpot that the recording is ready and transcription can begin."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The body of an example request is shown below:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to log a recording as being ready for a call\n{\n  \"engagementId\": 17591596434\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "If you're using the legacy approach of logging call recordings without authentication, please update to the authenticated approach before September 2024. After this time, the unauthenticated approach will no longer be supported."
        }), "\n"]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}