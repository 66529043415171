import _objectDestructuringEmpty from "@babel/runtime/helpers/esm/objectDestructuringEmpty";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["dependencies"],
  _excluded2 = ["oneOf"],
  _excluded3 = ["$ref"];
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

/*eslint no-use-before-define: ["error", { "functions": false }]*/
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

// https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/core/src/utils.js

import { union, uniq } from 'underscore';
import memoizeOne from 'react-utils/memoizeOne';
import Raven from 'raven-js';
import { isObject, guessType } from './typeUtils';
import validateFormData, { isValid } from './validate';
import getComponentTypes from '../constants/ComponentTypes';
export const ADDITIONAL_PROPERTY_FLAG = '__additional_property';

/* Gets the type of a given schema. */
/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function getSchemaType(schema) {
  const {
    type
  } = schema;
  if (!type && schema.const) {
    return guessType(schema.const);
  }
  if (!type && schema.enum) {
    return 'string';
  }
  if (type === 'array' && schema.items.oneOf) {
    return 'oneOf.array';
  }

  // When there's only one option, don't need a picker between options and can just use 'object'
  if (!type && schema.oneOf && schema.oneOf.length > 1) {
    return 'oneOf.object';
  }
  if (!type && (schema.properties || schema.additionalProperties)) {
    return 'object';
  }
  if (type === 'string' && schema.enum) {
    return 'enum';
  }
  if (type instanceof Array && type.length === 2 && type.includes('null')) {
    return type.find(_type => _type !== 'null');
  }
  if (type && schema.format && `${type}.${schema.format}` in getComponentTypes()) {
    return `${type}.${schema.format}`;
  }
  return type;
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function getModelName(schema) {
  let ref;
  if (schema.hasOwnProperty('$ref')) {
    ref = schema.$ref;
  } else if (schema.hasOwnProperty('$$ref')) {
    ref = schema.$$ref;
  }
  if (ref) {
    const parts = ref.split('/');
    return parts[parts.length - 1];
  }
  return null;
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function findSchemaDefinition($ref, definitions = {}) {
  // Extract and use the referenced definition if we have it.
  const match = /^#(?:\/definitions)?\/(.*)$/.exec($ref);
  if (match && match[1]) {
    const parts = match[1].split('/');
    let current = definitions;
    for (let part of parts) {
      part = part.replace(/~1/g, '/').replace(/~0/g, '~');
      while (current.hasOwnProperty('$ref')) {
        /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
        current = findSchemaDefinition(current.$ref, definitions);
      }
      if (current.hasOwnProperty(part)) {
        /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
        current = current[part];
      } else {
        // No matching definition found, that's an error (bogus schema?)
        // throw new Error(`Could not find a definition for ${$ref}.`);
        Raven.captureMessage(`Could not find a definition for ${$ref}.`);
      }
    }
    return current;
  }
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
function resolveDependencies(schema, definitions, formData) {
  // Drop the dependencies from the source schema.
  // eslint-disable-next-line prefer-const
  let {
      dependencies = {}
    } = schema,
    resolvedSchema = _objectWithoutPropertiesLoose(schema, _excluded);
  if ('oneOf' in resolvedSchema) {
    resolvedSchema = resolvedSchema.oneOf[getMatchingOption(formData, resolvedSchema.oneOf, definitions)];
  } else if ('anyOf' in resolvedSchema) {
    resolvedSchema = resolvedSchema.anyOf[getMatchingOption(formData, resolvedSchema.anyOf, definitions)];
  }
  return processDependencies(dependencies, resolvedSchema, definitions, formData);
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
function withDependentProperties(schema, additionallyRequired) {
  if (!additionallyRequired) {
    return schema;
  }
  const required = Array.isArray(schema.required) ? uniq([...schema.required, ...additionallyRequired]) : additionallyRequired;
  return Object.assign({}, schema, {
    required
  });
}

// Recursively merge deeply nested schemas.
// The difference between mergeSchemas and mergeObjects
// is that mergeSchemas only concats arrays for
// values under the "required" keyword, and when it does,
// it doesn't include duplicate values.
/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function mergeSchemas(obj1, obj2) {
  const acc = Object.assign({}, obj1); // Prevent mutation of source object.
  return Object.keys(obj2).reduce((_acc, key) => {
    const left = obj1 ? obj1[key] : {};
    const right = obj2[key];
    if (obj1 && obj1.hasOwnProperty(key) && isObject(right)) {
      _acc[key] = mergeSchemas(left, right);
    } else if (obj1 && obj2 && (getSchemaType(obj1) === 'object' || getSchemaType(obj2) === 'object') && key === 'required' && Array.isArray(left) && Array.isArray(right)) {
      // Don't include duplicate values when merging
      // "required" fields.
      _acc[key] = union(left, right);
    } else {
      _acc[key] = right;
    }
    return _acc;
  }, acc);
}
function withExactlyOneSubschema( /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
schema, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
definitions, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
formData, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
dependencyKey, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
oneOf) {
  /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
  const validSubschemas = oneOf.filter(subschema => {
    if (!subschema.properties) {
      return false;
    }
    const {
      [dependencyKey]: conditionPropertySchema
    } = subschema.properties;
    if (conditionPropertySchema) {
      const conditionSchema = {
        type: 'object',
        properties: {
          [dependencyKey]: conditionPropertySchema
        }
      };
      /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
      const {
        errors
      } = validateFormData(formData, conditionSchema);
      return errors.length === 0;
    }
  });
  if (validSubschemas.length !== 1) {
    console.warn("ignoring oneOf in dependencies because there isn't exactly one subschema that is valid");
    return schema;
  }
  const subschema = validSubschemas[0];
  const _subschema$properties = subschema.properties,
    dependentSubschema = _objectWithoutPropertiesLoose(_subschema$properties, [dependencyKey].map(_toPropertyKey));
  const dependentSchema = Object.assign({}, subschema, {
    properties: dependentSubschema
  });
  return mergeSchemas(schema, retrieveSchema(dependentSchema, definitions, formData));
}
function withDependentSchema( /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
schema, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
definitions, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
formData, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
dependencyKey, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
dependencyValue) {
  const _retrieveSchema = retrieveSchema(dependencyValue, definitions, formData),
    {
      oneOf
    } = _retrieveSchema,
    dependentSchema = _objectWithoutPropertiesLoose(_retrieveSchema, _excluded2);
  schema = mergeSchemas(schema, dependentSchema);
  // Since it does not contain oneOf, we return the original schema.
  if (oneOf === undefined) {
    return schema;
  } else if (!Array.isArray(oneOf)) {
    throw new Error(`invalid: it is some ${typeof oneOf} instead of an array`);
  }
  // Resolve $refs inside oneOf.
  const resolvedOneOf = oneOf.map(subschema => subschema.hasOwnProperty('$ref') ? resolveReference(subschema, definitions, formData) : subschema);
  return withExactlyOneSubschema(schema, definitions, formData, dependencyKey, resolvedOneOf);
}
function processDependencies( /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
dependencies, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
resolvedSchema, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
definitions, /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
formData) {
  // Process dependencies updating the local schema properties as appropriate.
  for (const dependencyKey in dependencies) {
    // Skip this dependency if its trigger property is not present.
    if (formData[dependencyKey] === undefined) {
      continue;
    }
    // Skip this dependency if it is not included in the schema (such as when dependencyKey is itself a hidden dependency.)
    if (resolvedSchema.properties && !(dependencyKey in resolvedSchema.properties)) {
      continue;
    }
    const {
        [dependencyKey]: dependencyValue
      } = dependencies,
      remainingDependencies = _objectWithoutPropertiesLoose(dependencies, [dependencyKey].map(_toPropertyKey));
    if (Array.isArray(dependencyValue)) {
      resolvedSchema = withDependentProperties(resolvedSchema, dependencyValue);
    } else if (isObject(dependencyValue)) {
      resolvedSchema = withDependentSchema(resolvedSchema, definitions, formData, dependencyKey, dependencyValue);
    }
    return processDependencies(remainingDependencies, resolvedSchema, definitions, formData);
  }
  return resolvedSchema;
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
function resolveReference(schema, definitions, formData) {
  // Retrieve the referenced schema definition.
  const $refSchema = findSchemaDefinition(schema.$ref, definitions);
  // Drop the $ref property of the source schema.
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const localSchema = _objectWithoutPropertiesLoose(schema, _excluded3);
  // Update referenced schema definition with local schema properties.
  return retrieveSchema(Object.assign({}, $refSchema, localSchema), definitions, formData);
}

// This function will create new "properties" items for each key in our formData
export function stubExistingAdditionalProperties( /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
schema, definitions = {}, formData = {}) {
  // Clone the schema so we don't ruin the consumer's original
  schema = Object.assign({}, schema, {
    properties: Object.assign({}, schema.properties)
  });
  Object.keys(formData).forEach(key => {
    if (schema.properties.hasOwnProperty(key)) {
      // No need to stub, our schema already has the property
      return;
    }
    let additionalProperties;
    if (schema.additionalProperties.hasOwnProperty('type')) {
      additionalProperties = Object.assign({}, schema.additionalProperties);
    } else if (schema.additionalProperties.hasOwnProperty('$ref')) {
      additionalProperties = retrieveSchema(schema.additionalProperties, definitions, formData);
    } else {
      /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
      additionalProperties = {
        type: guessType(formData[key])
      };
    }

    // The type of our new key should match the additionalProperties value;
    schema.properties[key] = additionalProperties;
    // Set our additional property flag so we know it was dynamically added
    schema.properties[key][ADDITIONAL_PROPERTY_FLAG] = true;
  });
  return schema;
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function resolveSchema(schema, definitions = {}, formData = {}) {
  if (schema.hasOwnProperty('$ref')) {
    return resolveReference(schema, definitions, formData);
  } else if (schema.hasOwnProperty('dependencies')) {
    const resolvedSchema = resolveDependencies(schema, definitions, formData);
    return retrieveSchema(resolvedSchema, definitions, formData);
  } else {
    // No $ref or dependencies attribute found, returning the original schema.
    return schema;
  }
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function retrieveSchema(schema, definitions = {}, formData = {}) {
  /*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
  const resolvedSchema = resolveSchema(schema, definitions, formData);
  const hasAdditionalProperties = resolvedSchema.hasOwnProperty('additionalProperties') && resolvedSchema.additionalProperties !== false;
  if (hasAdditionalProperties) {
    return stubExistingAdditionalProperties(resolvedSchema, definitions, formData);
  }
  return resolvedSchema;
}
export const memoizedRetrieveSchema = memoizeOne(retrieveSchema);

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function getMatchingOption(formData, options, definitions) {
  for (let i = 0; i < options.length; i++) {
    // Assign the definitions to the option, otherwise the match can fail if
    // the new option uses a $ref
    const option = Object.assign({
      definitions
    }, options[i]);

    // If the schema describes an object then we need to add slightly more
    // strict matching to the schema, because unless the schema uses the
    // "requires" keyword, an object will match the schema as long as it
    // doesn't have matching keys with a conflicting type. To do this we use an
    // "anyOf" with an array of requires. This augmentation expresses that the
    // schema should match if any of the keys in the schema are present on the
    // object and pass validation.
    if (option.properties) {
      // Create an "anyOf" schema that requires at least one of the keys in the
      // "properties" object
      const requiresAnyOf = {
        anyOf: Object.keys(option.properties).map(key => ({
          required: [key]
        }))
      };
      let augmentedSchema;

      // If the "anyOf" keyword already exists, wrap the augmentation in an "allOf"
      if (option.anyOf) {
        // Create a shallow clone of the option
        const shallowClone = Object.assign({}, (_objectDestructuringEmpty(option), option));
        if (!shallowClone.allOf) {
          shallowClone.allOf = [];
        } else {
          // If "allOf" already exists, shallow clone the array
          shallowClone.allOf = shallowClone.allOf.slice();
        }
        shallowClone.allOf.push(requiresAnyOf);
        augmentedSchema = shallowClone;
      } else {
        augmentedSchema = Object.assign({}, option, requiresAnyOf);
      }

      // Remove the "required" field as it's likely that not all fields have
      // been filled in yet, which will mean that the schema is not valid
      delete augmentedSchema.required;
      if (isValid(augmentedSchema, formData)) {
        return i;
      }
    } else if (isValid(options[i], formData)) {
      return i;
    }
  }
  return 0;
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function getIsPropertyRequired(schema, name) {
  return Array.isArray(schema.required) && schema.required.indexOf(name) !== -1;
}