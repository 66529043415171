"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611094;
const slug = exports.slug = 'guides/cms/tools/design-manager';
const title = exports.title = 'Gerenciador de design';
const name = exports.name = '[novo] Gerenciador de design';
const metaDescription = exports.metaDescription = 'O Gerenciador de design é um ambiente de desenvolvimento integrado baseado na web que pode ser usado opcionalmente para construir modelos, módulos, CSS e JavaScript.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/module%20editor.png';
const featuredImageAltText = exports.featuredImageAltText = 'O Gerenciador de design';
const toc = exports.toc = [{
  "depth": 0,
  "id": "configura%C3%A7%C3%B5es-do-gerenciador-de-design",
  "label": "Configurações do Gerenciador de design",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      img: "img",
      code: "code",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Gerenciador de design"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O Gerenciador de design é um ambiente de desenvolvimento integrado baseado na web que pode ser usado opcionalmente para construir ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modelos"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), ", CSS e JavaScript. O Gerenciador de design pode ser encontrado navegando até ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/design-manager/",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing > Arquivos e modelos > Ferramentas de design"
        })
      }), " na barra de navegação superior."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Se preferir desenvolver localmente usando seu editor de código, fluxos de trabalho e tecnologias preferidos, você pode usar a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CLI do CMS"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Independentemente de você desenvolver localmente ou utilizar o Gerenciador de design para construir ativos, todo o sistema de arquivos do desenvolvedor da sua conta será representado e poderá ser editado no Gerenciador de design."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/designmanager%203-20-2020.png",
        alt: "Captura de tela do Gerenciador de design da HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alguns desenvolvedores ou equipes optam por usar um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "fluxo de trabalho que combina o Gerenciador de design e o desenvolvimento local"
      }), ". Você pode utilizar o comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch"
        })
      }), " da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI do CMS"
      }), " para transferir as edições do Gerenciador de design para o seu ambiente local. Alternativamente, você pode impedir a edição e ter toda sua ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "base de código continuamente integrada ao controle de origem"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Confira este ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager",
        children: "Artigo da central de conhecimento"
      }), " para ter uma visão abrangente do Gerenciador de design e seus diferentes componentes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurações do Gerenciador de design"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O Gerenciador de design possui configurações básicas de IDE que você pode configurar para que a experiência se adapte a você e aos seus fluxos de trabalho. Um botão de configurações aparece ao lado do botão de ajuda na barra inferior e pode ser usado para definir as configurações."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "Você pode definir configurações para:"
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Tema do editor"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Fonte"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Tamanho da tabulação"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Recuar unidade"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Soft tabs"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Recuo inteligente"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Recuar na entrada de formatação"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Recuar com tabulações"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Quebra de linha"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Fechar automaticamente colchetes"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Corresponder colchetes"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Corresponder tags"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "ESLint"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["e muito mais, veja ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://knowledge.hubspot.com/cos-general/customize-design-manager-settings",
                children: "este artigo da central de conhecimento"
              }), " para ver uma descrição das configurações e obter mais informações."]
            }), "\n"]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Se esses recursos não atenderem às suas necessidades, é altamente recomendável experimentar nossas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/local-development-cli",
              children: "ferramentas de desenvolvimento local"
            }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/creating-an-efficient-development-workflow",
              children: "fluxo de trabalho"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/Design%20Manger%20Settings-1.png",
            alt: "Captura de tela da tela de configurações do Gerenciador de design"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}