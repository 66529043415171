"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831380;
const slug = exports.slug = 'guides/cms/content/performance/overview';
const title = exports.title = 'CDN-, Sicherheits- und Performance-Übersicht';
const name = exports.name = 'EMEA DE CDN, security, and performance overview';
const metaDescription = exports.metaDescription = 'CMS Hub bietet Sicherheits-, Zuverlässigkeits-, Performances- und Wartungstools sowie ein globales und schnelles CDN';
const toc = exports.toc = [{
  "depth": 0,
  "id": "content-delivery-network-cdn",
  "label": "Content Delivery Network (CDN)",
  "parent": null
}, {
  "depth": 0,
  "id": "secure-sockets-layer-ssl",
  "label": "Secure Sockets Layer (SSL)",
  "parent": null
}, {
  "depth": 0,
  "id": "http%2F2",
  "label": "HTTP/2",
  "parent": null
}, {
  "depth": 0,
  "id": "ipv6",
  "label": "IPv6",
  "parent": null
}, {
  "depth": 0,
  "id": "javascript--und-css-minifizierung",
  "label": "JavaScript- und CSS-Minifizierung",
  "parent": null
}, {
  "depth": 0,
  "id": "browser--und-server-caching",
  "label": "Browser- und Server-Caching",
  "parent": null
}, {
  "depth": 0,
  "id": "domain-%C3%BCbertragung",
  "label": "Domain-Übertragung",
  "parent": null
}, {
  "depth": 0,
  "id": "textkomprimierung",
  "label": "Textkomprimierung",
  "parent": null
}, {
  "depth": 0,
  "id": "bildkomprimierung%2F-optimierung-und-automatische-bildgr%C3%B6%C3%9Fenanpassung",
  "label": "Bildkomprimierung/-optimierung und automatische Bildgrößenanpassung",
  "parent": null
}, {
  "depth": 0,
  "id": "accelerated-mobile-pages-amp",
  "label": "Accelerated Mobile Pages (AMP)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CDN-, Sicherheits- und Performance-Übersicht"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub von HubSpot ist eines der fortschrittlichsten und am besten optimierten Content-Management-Systeme auf dem Markt. Da CMS Hub eine Software as a Service (SaaS)-Lösung ist, kümmern wir uns um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/security",
        children: "Sicherheit"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/reliability",
        children: "Zuverlässigkeit"
      }), ", Wartung und mehr, damit Ihr Team sich auf das Schreiben von Code und das Ermöglichen eines angenehmen Nutzererlebnisses konzentrieren kann."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot übernimmt eine Menge Performance-Verbesserungen für Sie, aber es gibt noch mehr, das Sie tun können. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "So optimieren Sie die Performance in CMS Hub."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Content Delivery Network (CDN)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das global verteilte Content Delivery Network von CMS Hub sorgt für extrem schnelle Seitenladezeiten, unabhängig davon, wo sich Ihre Besucher befinden. Für das Hosten von Medien oder Seiten sind keine Konfiguration, Einrichtung oder zusätzliche Accounts erforderlich, um das CDN zu nutzen. Das System kümmert sich für Sie um die Verteilung und die Invalidierung des Caches, sodass Sie sich auf die Erstellung einer ansprechenden Website konzentrieren können, während eine Web Application Firewall und integrierte Sicherheitsmaßnahmen Sie vor Online-Angriffen schützen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Secure Sockets Layer (SSL)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "SSL ist in allen verknüpften Domains in CMS Hub- oder Marketing Hub-Accounts enthalten und wird automatisch kostenlos bereitgestellt. Jede Domain verfügt über ein eigenes SAN-ZERTIFIKAT, mit konfigurierbaren Optionen wie dem Deaktivieren der Unterstützung für ausgewählte TLS-Versionen, dem Umleiten von über HTTP durchgeführten Anfragen zu HTTPS und dem Bereitstellen des HSTS-Headers, sodass zukünftige Anfragen über https erfolgen. Auf Anfrage können benutzerdefinierte SSL-Zertifikate gegen eine zusätzliche monatliche Gebühr gehostet werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HTTP/2"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Traffic auf den von HubSpot gehosteten Websites erfolgt mit dem Protokoll HTTP/2. Das Dateiübertragungsprotokoll ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://http2.github.io/",
        children: "HTTP/2"
      }), " ist der Nachfolger von „HTTP/1.1“. Es handelt sich nicht um eine grundlegende Umschreibung des Protokolls; HTTP-Methoden, Statuscodes und Semantik sind identisch und es sollte möglich sein, die gleichen APIs wie HTTP/1.x (möglicherweise mit einigen kleinen Zusätzen) zu verwenden, um das Protokoll darzustellen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit HTTP/2 soll die Performance optimiert werden, speziell die vom Endbenutzer wahrgenommene Latenz und die Netzwerk- und Serverressourcenauslastung. Ein Hauptziel ist es, die Verwendung einer einzigen Verbindung von Browsern zu einer Website zu ermöglichen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "IPv6"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle von HubSpot gehosteten Websites enthalten IPv6-Adressen, sodass sie nativ über IPv6 aufgerufen werden können. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.google.com/intl/en/ipv6/index.html",
        children: "IPv6"
      }), " ist die neueste Version des Internetprotokolls und erweitert die Anzahl der verfügbaren Adressen auf eine praktisch unbegrenzte Menge von 340 Billionen Billionen Billionen Adressen. Dem Internet gehen die IPv4-Adressen aus. Durch die Umstellung auf IPv6 kann das Internet weiter wachsen und es können neue, innovative Dienste entwickelt werden, da sich mehr Geräte mit dem Internet verbinden können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "JavaScript- und CSS-Minifizierung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Beim Schreiben von JavaScript und CSS für HubSpot-Seiten können Sie das folgende Minifizierungsverhalten erwarten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpot minifiziert automatisch JavaScript und CSS, die im Design-Manager enthalten sind, um unnötige Leerzeichen, Zeilenumbrüche und Kommentare zu entfernen. Dies gilt auch für JavaScript und CSS, die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "über das CLI in den Design-Manager hochgeladen werden"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Da HubSpot JavaScript und CSS im Design-Manager automatisch minifiziert, sollten Sie minifizierten Code ", (0, _jsxRuntime.jsx)("u", {
          children: "nicht"
        }), " direkt zum Design-Manager hinzufügen."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Um minimierten Code einzubeziehen, sollten Sie stattdessen die Datei in den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/file-manager",
          children: "Datei-Manager"
        }), " hochladen und die Datei dann über den Design-Manager anhängen. Um eine minifizierte Datei lokal zu einem Modul zu verlinken, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration#adding-css-and-javascript-dependencies",
          children: "die meta.json des Moduls aktualisieren, um css_assets oder js_assets zu berücksichtigen"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpot minifiziert keine JavaScript- oder CSS-Dateien, die in den Datei-Manager hochgeladen oder über externe Links referenziert werden. Sie sollten sicherstellen, dass diese Dateien vor dem Hochladen minifiziert werden."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Jedes Mal, wenn Sie eine JavaScript- oder CSS-Datei im Design-Manager oder durch lokalen Upload aktualisieren, minifiziert HubSpot sie automatisch. Dies kann zu einer kurzen Verzögerung führen, bevor die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".min.js"
        }), "-Version Ihrer Datei auf Live-Seiten bereitgestellt wird. Während dieses Zeitraums stellt HubSpot die nicht minifizierte Version bereit, um sicherzustellen, dass Website-Besucher immer noch die neueste Version Ihrer Dateien erhalten."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Syntaxfehler können verhindern, dass HubSpot eine Datei minifizieren kann."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zusätzlich zur Minifizierung können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#including-files-in-files",
        children: "HubL-includes verwenden, um mehrere CSS-Dateien in einer Datei zu kombinieren,"
      }), " um die Leistung weiter zu erhöhen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Browser- und Server-Caching"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot speichert Seiten und Dateien automatisch sowohl auf Server- als auch auf Browserebene im Cache, um die schnellstmögliche Bereitstellung aller Inhaltselemente für Ihre Website-Besucher zu gewährleisten. Wenn sich Ihre Seite oder eine Abhängigkeit davon (z. B. eine Vorlage oder ein Modul) ändert, werden die Server-Caches für diese Seite automatisch geleert."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Domain-Übertragung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Jede zusätzliche Domain, die auf Ihrer Website verwendet wird, erhält eine zusätzliche DNS-Suche und -Verbindung. Je weniger Domains Sie verwenden, desto schneller wird Ihre Website geladen. HTTP/2 unterstützt das gleichzeitige Laden mehrerer Dateien über dieselbe Verbindung, sodass die alten Richtlinien für das „Sharding“ Ihrer Elemente unter mehreren Domains nicht mehr gelten."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die URLs von Elementen, auf die in CMS-Seiten verwiesen wird, wie Entwicklerdateisystemdateien, CSS, JS und Bilder, werden nach Möglichkeit automatisch neu geschrieben, um der Domain der aktuellen Seite zu entsprechen. Wenn Sie also auf ein Bild unter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/53/HubSpot_Logos/HSLogo_gray.svg",
        children: "http://cdn2.hubspot.net/hubfs/53/HubSpot_Logos/HSLogo_gray.svg"
      }), " auf einer Seite verweisen, die auf ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/",
        children: "www.hubspot.com"
      }), " bereitgestellt wird, wird die URL automatisch zu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/hubfs/HubSpot_Logos/HSLogo_gray.svg",
        children: "https://www.hubspot.com/hubfs/HubSpot_Logos/HSLogo_gray.svg"
      }), " aktualisiert."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Textkomprimierung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Textbasierte Dateien wie HTML, CSS und JS werden alle mithilfe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/google/brotli",
        children: "brotli"
      }), " komprimiert, bevor sie an Browser gesendet werden. Kompression mit Brotli ist noch wichtiger als gzip. Wenn der Client nicht anzeigt, dass Brotli-Komprimierung unterstützt wird, wird die gzip-Komprimierung angewendet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Während Minifizierung die Analysezeit von CSS- und JS-Dateien im Browser beschleunigt, gelangen diese Dateien bei Komprimierung schneller zum Browser."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bildkomprimierung/-optimierung und automatische Bildgrößenanpassung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie ein Bild in den Datei-Manager hochladen, werden die Bilder automatisch optimiert. Insbesondere werden JPEGs und PNGs von ihren Metadaten befreit (EXIF und andere Dinge, die Bilder größer machen). Alle Bilder (außer GIF-Dateien) werden außerdem neu komprimiert, um visuell verlustfrei zu sein. Darüber hinaus können wir Bilder in einer anderen Codierung bereitstellen, wenn sie als kleineres PNG als ein JPEG dargestellt werden können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Bilder werden mit 72 dpi gespeichert, unabhängig von ihrer ursprünglichen Auflösung."
      }), " Wenn Sie eine Datei mit 300 dpi hochladen, die ursprünglich für den Druck erstellt wurde, wird sie in 72 dpi konvertiert, was der Standard für das Web ist."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Wir verbessern Bilder schrittweise, um das WebP-Format für Browser zu verwenden, die es unterstützen."
      }), " Bilder werden im WebP-Format bereitgestellt, wenn die Dateigröße dieser Version des Bildes kleiner ist. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Diese Konvertierung erfolgt serverseitig und ändert die Dateierweiterung in der URL nicht"
      }), ". Ein Bild, das als ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".jpg"
      }), " hochgeladen wurde, wird weiterhin als ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".jpg"
      }), " in der URL angezeigt, aber als WebP bereitgestellt. Dadurch wird sichergestellt, dass Links zu diesem Bild unabhängig von der WebP-Unterstützung für alle funktionieren."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Von HubSpot gehostete Bilder, die in CMS-Inhalten platziert werden, werden von HubSpot automatisch in der Größe angepasst"
      }), ". Dadurch wird verhindert, dass der Browser Ressourcen dafür aufwendet. Um dies zu erreichen, hängt HubSpot Höhen- und/oder Breitenabfrageparameter an die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), "-URL aller Bilder an, die ein Breiten- oder Höhenattribut haben. Hinweis: Die Größenänderung des Bildes dauert weniger als eine Minute. Wird eine Seite angefragt, bevor das Bild verkleinert wird, wird das nicht verkleinerte Bild für diese Anfrage bereitgestellt. Browser können auf mehrere Optionen für die zu ladende Bildauflösung zurückgreifen, sodass Ihre Bilder sowohl auf Standard- als auch auf hochauflösenden Bildschirmen gestochen scharf aussehen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bildoptimierung/-komprimierung und automatische Bildgrößenanpassung sind standardmäßig für alle von HubSpot gehosteten Seiten/Dateien aktiviert."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/img_opt.png",
        alt: "Screenshot des img-Elements mit srcset, automatisch mit unterschiedlichen Größenänderungs-URLs hinzugefügt"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Bei einzelnen JPG-Dateien wird das Bild , wenn die Bild-URL den Abfrageparameter ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "quality=high"
        }), " enthält, nicht komprimiert."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Entwickler, die auf CMS Hub aufbauen, können HubSpot auch zusätzlichen Kontext zu Bildern bereitstellen, um die Bildgröße weiter zu steuern. Dies geschieht über die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#resize-image-url",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "resize_image_url()"
        })
      }), "-Funktion. Dies ermöglicht es Entwicklern, „Richtlinien“ in Module zu integrieren, um zu verhindern, dass Content-Autoren übergroße Bilder auf Seiten und in E-Mails anzeigen. Die Funktion eignet sich auch für Situationen, in denen die Größe eines Bildes nicht durch Höhen- und Breitenattribute im HTML-Code vorgegeben ist, z. B. bei einem Hintergrundbild."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Accelerated Mobile Pages (AMP)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["AMP (Accelerated Mobile Pages) ist ein mobilgerätespezifisches Seitenformat, das Inhalte nahezu sofort lädt. AMP kann in Ihren von HubSpot gehosteten Blog-Beiträgen mit einem Schalter aktiviert werden. Weitere Informationen finden Sie unter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cos-general/how-to-use-accelerated-mobile-pages-amp-in-hubspot",
        children: "Accelerated Mobile Pages (AMP) in Ihrem Blog verwenden"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}