"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 158168247659;
const slug = exports.slug = 'reference/ui-components/standard-components/image';
const title = exports.title = 'Image';
const name = exports.name = 'Image | UI components';
const metaDescription = exports.metaDescription = 'Learn about the Image component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "image-guidelines",
  "label": "Image guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      strong: "strong",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tag
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Image | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Image"
      }), " component renders an image. Use this component to add a logo or other visual brand identity asset, or to accentuate other content in the extension."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Images cannot exceed the width of the extension's container at various screen sizes, and values beyond that maximum width will not be applied to the image."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-image-component.png",
        alt: "ui-extension-image-component"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Image } from '@hubspot/ui-extensions';\n\nconst Extension = () => {\n  return (\n    <Image\n      alt=\"A picture of a welcome sign\"\n      src=\"https://picsum.photos/id/237/200/300\"\n      href={{\n        url: 'https://www.wikipedia.org',\n        external: true,\n      }}\n      onClick={() => {\n        console.log('Someone clicked the image!');\n      }}\n      width={200}\n    />\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Prop"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Type"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Description"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "src"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The source of the image display. You can specify a URL or you can ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "import"
            }), " the image directly if it's within your project. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#image-guidelines",
              children: "image sources"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The alt text for the image, similar to the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "alt"
            }), " attribute for the HTML ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attributes",
              children: "img tag"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "href"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When provided, sets the URL that will open when the image is clicked. Contains the following fields:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "url"
                }), " (string): the URL that will open on click."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "external"
                }), " (boolean): set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to open the URL in a new tab. By default:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: "Links to HubSpot app pages will open in the same tab."
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: "Links to non-HubSpot app pages will open in a new tab."
                  })]
                })]
              })]
            }), "When an image includes both ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "href"
            }), " and an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "onClick"
            }), " action, both will be executed on button click."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onClick"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "() => void"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A function that will be called when the image is clicked. This function will receive no arguments and any returned values will be ignored."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The pixel width of the image."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The pixel height of the image."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overlay"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Include a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/ui-components/standard-components/modal",
              children: "Modal"
            }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/reference/ui-components/standard-components/panel",
              children: "Panel"
            }), " component in this object to open it as an overlay on click. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/beta-docs/guides/crm/ui-extensions/sdk#open-an-overlay",
              children: "using overlays"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Image guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Supported types: the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Image"
        }), " component supports the following file types: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".jpg"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".jpeg"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".png"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".gif"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".svg"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".webp"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Supported sources: the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "src"
        }), " prop specifies the source of the image, which can be a URL (shown above) or you can import the file if it's included in your project. To import images, first add the files to your project, then import them by relative path (shown below). While there's no specific file size limit for images bundled in projects, the total size of your project cannot exceed 50MB."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Image } from \"@hubspot/ui-extensions\";\nimport myImage from './images/myImage.png'\nimport myImage2 from './images/myImage2.png'\n\nconst Extension = () => {\n  return (\n    <>\n      <Image src={myImage} width={300}></Image>\n      <Image src={myImage2} width={300}></Image>\n    &lt;/>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/link",
          children: "Link"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/text",
          children: "Text"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/heading",
          children: "Heading"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}