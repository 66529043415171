"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358694;
const slug = exports.slug = 'guides/apps/marketplace/listing-your-app';
const title = exports.title = 'Mercado de aplicaciones | Anuncio de venta de tu aplicación';
const name = exports.name = 'vNext Docs DP - Anuncio de venta de tu aplicación';
const metaDescription = exports.metaDescription = 'Sigue estos pasos para enviar una aplicación para el anuncio de venta en el Mercado de aplicaciones de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crea-y-env%C3%ADa-un-anuncio-de-venta-de-aplicaci%C3%B3n",
  "label": "Crea y envía un anuncio de venta de aplicación",
  "parent": null
}, {
  "depth": 0,
  "id": "informaci%C3%B3n-de-anuncio-de-venta",
  "label": "Información de anuncio de venta",
  "parent": null
}, {
  "depth": 0,
  "id": "detalles-de-la-aplicaci%C3%B3n",
  "label": "Detalles de la aplicación",
  "parent": null
}, {
  "depth": 0,
  "id": "precios",
  "label": "Precios",
  "parent": null
}, {
  "depth": 0,
  "id": "caracter%C3%ADsticas-de-la-aplicaci%C3%B3n",
  "label": "Características de la aplicación",
  "parent": null
}, {
  "depth": 0,
  "id": "informaci%C3%B3n-de-asistencia-t%C3%A9cnica",
  "label": "Información de asistencia técnica",
  "parent": null
}, {
  "depth": 0,
  "id": "rese%C3%B1a-la-informaci%C3%B3n-y-env%C3%ADa-tu-anuncio-de-venta",
  "label": "Reseña la información y envía tu anuncio de venta",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-y-actualizar-un-anuncio-localizado-de-un-anuncio-de-venta-de-aplicaci%C3%B3n-existente",
  "label": "Crear y actualizar un anuncio localizado de un anuncio de venta de aplicación existente",
  "parent": null
}, {
  "depth": 0,
  "id": "editar-un-anuncio-de-aplicaci%C3%B3n-activo",
  "label": "Editar un anuncio de aplicación activo",
  "parent": null
}, {
  "depth": 0,
  "id": "anular-la-publicaci%C3%B3n-de-un-anuncio-de-aplicaci%C3%B3n-activo",
  "label": "Anular la publicación de un anuncio de aplicación activo",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Anunios de venta de tu aplicación"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Después de crear una aplicación en tu cuenta de desarrollador, que cumpla con los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "requisitos de los anuncios del mercado de materiales"
      }), ", puedes enviar un anuncio para agregarlo al ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "mercado de aplicaciones de HubSpot"
      }), ". El equipo de HubSpot Ecosystem Quality revisará tu envío y hará un seguimiento por correo electrónico si la aplicación ha sido aprobada o rechazada."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " debes ser ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "superadministrador"
        }), " para actualizar y enviar un anuncio de venta de aplicación."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En este artículo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-submit-an-app-listing",
          children: "Crea y envía un anuncio de venta de aplicación"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-update-a-localized-listing-for-an-existing-app-listing",
          children: "Crea y actualiza un anuncio localizado de un anuncio de venta de aplicación existente"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-submit-an-app-listing",
          children: "Agrega puntos de contacto para el App Partner Program"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "[Edita un anuncio de venta de aplicación activo"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#unpublish-a-live-app-listing",
          children: "Anula la publicación de un anuncio de venta de aplicación activo"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crea y envía un anuncio de venta de aplicación"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Nota: **antes de enviar un anuncio de venta de aplicación, revisa la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
          children: "página de requisitos de anuncios de venta de aplicaciones"
        }), " para saber qué colocar en el anuncio."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "cuenta de desarrollador de aplicaciones"
        }), ", navega hasta ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Marketplace"
        }), " >** Anuncios**."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear anuncio de venta"
        }), ". Si este botón está atenuado, los anuncios de venta ya han sido creados para todas tus aplicaciones existentes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecciona la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "aplicación"
        }), " para la que deseas crear un anuncio y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Siguiente"
        }), ". Las aplicaciones que ya figuran en el mercado de aplicaciones no aparecerán aquí."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la siguiente pantalla, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Selecciona los idiomas en los que está disponible tu aplicación"
        }), " y selecciona los ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "idiomas"
        }), " en los que se ofrece tu software de aplicación. El mercado de aplicaciones está disponible en ocho idiomas: inglés, español, francés, alemán, portugués, japonés, holandés e italiano. Puedes crear anuncios de venta de aplicación en tantos idiomas como desees, siempre que el software de tu aplicación esté disponible en ellos. Haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Selecciona el idioma principal del anuncio de [nombre de la aplicación]"
        }), " y selecciona el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "idioma predeterminado"
        }), " que verán los usuarios cuando naveguen por el mercado de aplicaciones."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Siguiente"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El asistente para el anuncio de venta de la aplicación tiene cinco pestañas de información que rellenar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Información de anuncio de venta"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Información de anuncio de venta"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Información de aplicación"
          }), ", agrega el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre de tu aplicación pública"
          }), ", el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre de la empresa"
          }), ", el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "eslogan de la aplicación"
          }), " y la ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL del botón de conexión"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Icono de la aplicación"
          }), ", carga un icono de 800 px por 800 px para tu aplicación. Aparecerá en el mercado de aplicaciones y en las cuentas de usuarios conectados. Las mejores prácticas para los iconos son:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Debes"
            }), ": usar un archivo JPG, JPEG o PNG, rellena todo el espacio (800 px por 800 px) - tu imagen debería tocar al menos dos bordes, y utilizar una imagen de alta resolución sin píxeles."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "No debes"
            }), ": incluir texto en tu icono, ni utilizar una marca de palabra, ni dejar espacios en blanco adicionales alrededor de tu icono."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/dev-doc-logo.png",
        alt: "dev-doc-logo"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Clasifica tu aplicación"
        }), ", selecciona una ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "categoría"
        }), " para tu aplicación y cualquier ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "término de búsqueda"
        }), " que pueda utilizarse para encontrar tu aplicación en el mercado de aplicaciones."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Detalles de la aplicación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Detalles de la aplicación"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Vídeo de demostración"
        }), ", sube un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "vídeo"
        }), " para mostrar cómo funciona tu aplicación. Consulta la página ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/apps/resources/how-to-make-a-great-app-demo-video",
          children: "Cómo crear un gran video de demostración de aplicaciones"
        }), " para conocer las mejores prácticas y ejemplos de cómo crear un video de demostración."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Capturas de pantalla"
        }), ", agrega ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "imágenes"
        }), " y ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "texto alt"
        }), "que muestren el funcionamiento de tu aplicación. Puedes agregar hasta ocho imágenes.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Haz clic en Agregar ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "datos compartidos"
            }), ". En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Datos compartidos"
            }), ", informa a usuarios cómo circularán los datos entre tu aplicación y HubSpot."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["En el panel de la derecha, selecciona qué objeto de la aplicación se sincroniza con qué objeto de HubSpot, y la dirección de la sincronización. En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Descripción de la aplicación"
            }), ", haz una breve ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "descripción"
            }), " de cómo tu aplicación puede ayudar a los usuarios a llevar a cabo sus objetivos."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para agregar otra sincronización de objetos, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Agregar otro objeto"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/shared-data-app-listing.png",
        alt: "shared-data-app-listing"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Características de HubSpot con las que funciona tu aplicación"
        }), ", agrega hasta ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "10 herramientas y características de HubSpot"
        }), " con las que funciona tu aplicación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Otras herramientas con las que se integra tu software"
        }), ", selecciona hasta ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "seis herramientas o aplicaciones"
        }), " con las que se integra tu software."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Idiomas en los que está disponible tu aplicación"
        }), ", selecciona todos los ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "idiomas"
        }), " disponibles para tu aplicación. Solo puedes crear anuncios de venta adicionales en el mercado de aplicaciones en estos idiomas."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Precios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Precios"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la sección superior, selecciona la ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "moneda"
          }), " en la que deseas anunciar la venta de la aplicación. Puedes elegir entre más de 100 monedas."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["También puedes configurar los ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "planes de fijación de precios"
          }), " para tu aplicación agregando el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "modelo de fijación de precios"
          }), ", el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nombre del plan"
          }), ", el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "eslogan"
          }), ", el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "detalle de precios"
          }), " y la ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "lista de características"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Dependiendo del modelo de precios seleccionado, es posible que tengas que agregar más información, como la frecuencia de pago, tarifas únicas o precios mensuales. Coloca el cursor sobre el ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "icono de información"
              }), " para obtener más información sobre cada modelo de precios."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/pricing-model-app-listing.png",
                alt: "pricing-model-app-listing"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Puedes agregar hasta cinco planes de precios. Para agregar planes de precios adicionales, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Agregar otro plan."
              })]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Crea enlaces a los planes de precios de tu software"
          }), ", introduce la ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " donde los usuarios pueden encontrar más información sobre tus planes de precios."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Planes de precios para agencias"
          }), ", introduce la ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " donde los usuarios pueden obtener más información sobre los precios de los servicios de partner o de consultoría."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Características de la aplicación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Características de la aplicación"
      }), ", agrega las características y orienta a los clientes sobre cómo usarlas. No hay límite en el número de características que se pueden crear para tu aplicación."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Agregar característica"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la página ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Detalles de la característica"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Introduce el ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nombre de la característica"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Selecciona todos los ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "grupos de alcance"
            }), " que un cliente necesita que tengan esta característica. Los grupos de alcance se utilizan para determinar si la cuenta de HubSpot del cliente es compatible con las características de la aplicación."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Introduce una ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "descripción"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Selecciona una ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "imagen"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Selecciona dónde deseas mostrar la característica: ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "En el anuncio de venta del mercado de tu aplicación"
            }), " o ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Como tarjeta de descubrimiento de características"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes mostrar un máximo de cinco características de aplicaciones en el anuncio de venta del mercado de aplicaciones y un máximo de 10 características de aplicaciones como tarjetas de descubrimiento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si selecciona mostrar la característica como una tarjeta de descubrimiento de características:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecciona un botón principal:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Vincular a una característica:"
            }), " selecciona a qué característica de HubSpot debe enlazarse el botón."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Crear personalizado"
            }), ": introduce ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "el texto del botón"
            }), " y la ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "URL del botón"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Sin botón principal"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecciona una guía práctica para incorporar a tus clientes:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Crear una guía desde cero:"
            }), " introduce un ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "título"
            }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "una descripción"
            }), " y una ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "imagen o video"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Enlace externo al tutorial:"
            }), " introduce una ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "URL del tutorial"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Guía de solo video:"
            }), " sube un ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "video"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para agregar otro tutorial, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Agregar otra sección"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/feature-discovery-card.png",
        alt: "feature-discovery-card"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Información de asistencia técnica"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Información de asistencia técnica"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Información de contacto"
        }), ", agrega un método de contacto de asistencia técnica para los usuarios que tengan preguntas al utilizar tu aplicación. Agrega tu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "correo electrónico de asistencia técnica"
        }), ", el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "sitio web de la empresa"
        }), " y los ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "idiomas"
        }), " en los que se ofrece la asistencia técnica al cliente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Recursos de asistencia técnica"
        }), ", incluye ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "enlaces"
        }), " a la documentación de configuración de tu aplicación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Términos de servicio y política de privacidad"
        }), ", agrega ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "enlaces"
        }), " a tu documentación sobre privacidad."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reseña la información y envía tu anuncio de venta"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En la pestaña _ Información de la reseña_:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Instrucciones de revisión de la aplicación"
        }), ", introduce cualquier ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "instrucción de revisión de la aplicación"
        }), " que el equipo de HubSpot Ecosystem Quality requiera para probar y revisar tu aplicación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Puntos de contacto del App Partner Program"
        }), ", incluye la información de contacto de cualquier persona con la que sea necesario comunicarse en relación con el anuncio de venta de la aplicación y con el desarrollador de la aplicación. Debes incluir ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "rol"
        }), " de la persona, el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre y el apellido"
        }), ", el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "correo electrónico"
        }), " y el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "país sede"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para agregar otro punto de contacto, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Agregar otro punto de contacto"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para enviar el anuncio de venta para su revisión, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Enviar para revisión"
      }), " en la esquina superior derecha. Si el botón ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enviar para revisión"
      }), " está atenuado, revisa que hayas completado todos los campos obligatorios y que tengas permisos de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "superadministrador"
      }), ". Si has omitido algún campo obligatorio, verás un número en el encabezado de la pestaña que indica el número de campos omitidos. Haz clic en cada pestaña para introducir la información que falta, luego vuelve a la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Información de revisión"
      }), " y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Enviar para revisión"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-features.png",
        alt: "app-features"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear y actualizar un anuncio localizado de un anuncio de venta de aplicación existente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para poder crear anuncios en otros idiomas, tendrás que establecer un idioma principal en el anuncio actual de la aplicación y hacer que se publique el anuncio principal en el mercado de HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En tu cuenta de desarrollador de aplicaciones de HubSpot, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Mercado de aplicaciones"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Anuncios"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Si ya tienes una aplicación en el mercado, verás un cartel amarillo encima de la aplicación listada que te pedirá que establezcas el idioma principal del anuncio de venta. Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Configúralo ahora"
          }), ". Tendrás que establecer el idioma principal del anuncio de venta de aplicación antes de poder crear nuevos anuncios de venta de idiomas."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En el cuadro de diálogo, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Selecciona los idiomas en los que está disponible tu aplicación"
          }), " y selecciona los ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "idiomas"
          }), " en los que está disponible tu software de aplicación."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Selecciona el idioma principal del anuncio de [nombre de la aplicación]"
          }), " y selecciona el ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "idioma predeterminado"
          }), " que verán los usuarios cuando naveguen por el mercado de aplicaciones."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Guardar."
          })]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que hayas establecido un idioma principal, podrás agregar un nuevo anuncio localizado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Coloca el cursor sobre el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "anuncio de la aplicación"
        }), " y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Más"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear anuncio en otro idioma"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Idioma de este anuncio"
        }), " y selecciona el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "idioma"
        }), " en el que deseas crear este anuncio."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sigue los pasos para crear y enviar un anuncio de venta en el idioma seleccionado."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Todas las partes de tu anuncio localizado deben estar en el mismo idioma, incluidas las capturas de pantalla de los productos y los videos de demostración."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Al actualizar el anuncio principal, no se actualizan automáticamente los anuncios localizados. Cada uno debe actualizarse de forma independiente."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Editar un anuncio de aplicación activo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que el anuncio de venta de aplicación está activo en el mercado de aplicaciones, para editar el anuncio de venta:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de desarrollador, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mercado de aplicaciones > Anuncios de venta"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Coloca el cursor sobre el anuncio de venta que deseas editar y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Más"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Editar"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Realiza los cambios y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enviar para revisión"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anular la publicación de un anuncio de aplicación activo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de desarrollador, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mercado de aplicaciones"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anuncios de venta"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Coloca el cursor sobre el anuncio del que deseas anular la publicación y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Más"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anular publicación de anuncio activo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el cuadro de diálogo, escribe el motivo de la anulación de la publicación de la aplicación y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enviar solicitud de anulación de la publicación"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las solicitudes de anulación de publicaciones son procesadas por el equipo del mercado de HubSpot en los 10 días hábiles posteriores al envío."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}