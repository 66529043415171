"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125382;
const slug = exports.slug = 'guides/cms/storage/hubdb';
const title = exports.title = 'HubDB';
const name = exports.name = 'LATAM (ES) HubDB';
const metaDescription = exports.metaDescription = 'Construye contenido basado en datos con HubDB, la herramienta de base de datos nativa de HubSpot. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "arquitectura-de-hubdb",
  "label": "Arquitectura de HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "limitaciones-t%C3%A9cnicas-de-hubdb",
  "label": "Limitaciones técnicas de HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-una-tabla-de-hubdb",
  "label": "Crear una tabla de HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "unir-varias-tablas-de-hubdb",
  "label": "Unir varias tablas de HubDB",
  "parent": null
}, {
  "depth": 1,
  "id": "1-1.-agrega-una-columna-id-externo-a-la-tabla-principal",
  "label": "1 1. Agrega una columna ID externo a la tabla principal",
  "parent": "unir-varias-tablas-de-hubdb"
}, {
  "depth": 1,
  "id": "2.-agregar-filas-de-tablas-externas-a-las-filas-de-tu-tabla",
  "label": "2. Agregar filas de tablas externas a las filas de tu tabla",
  "parent": "unir-varias-tablas-de-hubdb"
}, {
  "depth": 1,
  "id": "3.-renderiza-los-datos-de-tu-tabla-de-hubdb-unida",
  "label": "3. Renderiza los datos de tu tabla de HubDB unida",
  "parent": "unir-varias-tablas-de-hubdb"
}, {
  "depth": 0,
  "id": "acceder-a-los-datos-de-hubdb-mediante-hubl",
  "label": "Acceder a los datos de HubDB mediante HubL",
  "parent": null
}, {
  "depth": 1,
  "id": "obtenci%C3%B3n-de-filas",
  "label": "Obtención de filas",
  "parent": "acceder-a-los-datos-de-hubdb-mediante-hubl"
}, {
  "depth": 2,
  "id": "atributos-de-las-filas",
  "label": "Atributos de las filas",
  "parent": "acceder-a-los-datos-de-hubdb-mediante-hubl"
}, {
  "depth": 1,
  "id": "obtener-los-metadatos-de-una-tabla",
  "label": "Obtener los metadatos de una tabla",
  "parent": "acceder-a-los-datos-de-hubdb-mediante-hubl"
}, {
  "depth": 1,
  "id": "atributos-de-la-tabla",
  "label": "Atributos de la tabla",
  "parent": "acceder-a-los-datos-de-hubdb-mediante-hubl"
}, {
  "depth": 1,
  "id": "obtener-los-metadatos-de-una-columna",
  "label": "Obtener los metadatos de una columna",
  "parent": "acceder-a-los-datos-de-hubdb-mediante-hubl"
}, {
  "depth": 2,
  "id": "atributos-de-las-columnas",
  "label": "Atributos de las columnas",
  "parent": "acceder-a-los-datos-de-hubdb-mediante-hubl"
}, {
  "depth": 2,
  "id": "m%C3%A9todos-de-columna",
  "label": "Métodos de columna",
  "parent": "acceder-a-los-datos-de-hubdb-mediante-hubl"
}, {
  "depth": 2,
  "id": "columnas-de-texto-enriquecido",
  "label": "Columnas de texto enriquecido",
  "parent": "acceder-a-los-datos-de-hubdb-mediante-hubl"
}, {
  "depth": 0,
  "id": "tutoriales-y-recursos-relacionados",
  "label": "Tutoriales y recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      em: "em",
      img: "img",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      h3: "h3",
      pre: "pre",
      h4: "h4",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDB es una herramienta que permite crear tablas para almacenar datos en filas, columnas y celdas, de forma similar a una hoja de cálculo. Puedes personalizar las columnas, filas y otros ajustes de una tabla de HubDB en función de tus necesidades. Por ejemplo, podrías usar una tabla de HubDB para:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Almacenar feedback de un mecanismo externo para recuperarla posteriormente."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Almacenar datos estructurados que puedes usar para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "crear páginas dinámicas del CMS"
        }), " (", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "CMS Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Pro"
        }), " y ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " únicamente)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Almacena los datos para usarlos en un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-programmable-emails",
          children: "correo electrónico programable"
        }), " (solo ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "**_Marketing Hub"
        }), "**_ ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-table-example0.png",
        alt: "hubdb-table-example0"
      }), "Se puede acceder a las tablas de HubDB tanto ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb",
        children: "dentro de HubSpot"
      }), " como a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb",
        children: "API de HubDB"
      }), ", y puedes recuperar los datos de una tabla de varias maneras, dependiendo del uso. Para obtener datos de una tabla de HubDB, puedes:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Consultar los datos externamente a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/cms/hubdb",
          children: "API de HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utilizar las etiquetas de marcado de HubL de HubSpot para introducir datos en el CMS como contenido estructurado."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utilizar la API de HubDB con ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "funciones sin servidor"
        }), " para ofrecer una experiencia de aplicación web interactiva."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Para ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
            children: "utilizar los datos de HubDB en las páginas"
          }), ", necesitas ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "CMS Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Pro"
          }), " o ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Para utilizar los datos de HubDB en ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/email/create-programmable-emails",
            children: "correos electrónicos programables"
          }), ", necesitas ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Publicar, editar y ver las tablas existentes requiere ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/es/hubspot-user-permissions-guide#website-tools",
            children: "permisos de HubDB"
          }), ". La creación, clonación, eliminación y edición de la configuración de una tabla de HubDB requiere permisos de ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#settings",
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "configuración de tabla de HubDB"
            })
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Arquitectura de HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una tabla de HubDB consta de filas, columnas y celdas, similar a una hoja de cálculo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tablas:"
        }), " una tabla es una disposición bidimensional de filas y columnas. Cuando se crea una tabla, se le asigna un ID único a nivel global que puedes usar cuando necesites especificar una tabla en HubL o a través de la API."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Filas:"
        }), " las filas son cortes horizontales de una tabla. En una aplicación de hoja de cálculo, las filas se representan con números, empezando por el 1. Cada fila de la tabla recibe un ID único en el momento de su creación. Por opción predeterminada, cada fila de una tabla viene con algunas columnas:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Columna"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un ID numérico asignado automáticamente, único en el mundo, para esta fila."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una marca de tiempo de cuándo esta fila fue creada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cuando se utiliza con páginas dinámicas, esta cadena es el último segmento de la ruta de la URL de la página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cuando se utiliza con páginas dinámicas, es el título de la página."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " las columnas del área de texto enriquecido en HubDB están limitadas a 65.000 caracteres. Para obtener más información, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/hubdb-rich-text-area-limited-to-65000-characters-and-a-new-meetings-module",
          children: "consulta el anuncio de registro de cambios"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Columnas:"
        }), " las columnas son cortes verticales de una tabla. Cada columna tiene un tipo, que se utiliza para almacenar tipos de datos. Una tabla puede incluir tantas columnas como quieras, y a cada una se le asigna un ID único global al crearla. El ID de columna comienza con un valor de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1"
        }), ", pero no son necesariamente secuenciales y no se pueden reutilizar. Los tipos de columna incluyen:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Texto"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Texto enriquecido"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "URL"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Imagen"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Selección"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Selección múltiple"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Fecha"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Fecha y hora"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Number"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Moneda"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Casilla de verificación"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ubicación (longitud, latitud)"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ID externo"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Video"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Celdas:"
        }), " las celdas almacenan los valores donde se cruzan una fila y una columna. Las celdas pueden leerse o actualizarse individualmente o como parte de una fila. Establecer el valor de una celda en ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " equivale a eliminar el valor de la celda."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limitaciones técnicas de HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tenga en cuenta los siguientes límites técnicos de HubDB:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Límites de cuenta"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1000 tablas de HubDB por cuenta."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1.000.000 de filas en tablas HubDB por cuenta."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Límites de tabla:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "250 columnas por tabla."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "10000 filas por tabla de HubDB."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caracteres por nombre de tabla."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caracteres por etiqueta de tabla."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Límites de columna:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "65.000 caracteres por columna de texto enriquecido."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "10.000 caracteres en cada columna de texto."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caracteres por nombre de columna."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caracteres por etiqueta."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "300 caracteres por descripción de columna."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caracteres por opción seleccionable dentro de una columna."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Límites de página:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["10 llamadas a la función ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb_table_rows"
            }), " de HubL por página CMS."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["10 ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "páginas dinámicas"
            }), " usando la misma tabla de HubDB."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Los HubDB con páginas dinámicas activadas deben tener rutas en minúsculas para que las URL de estas páginas no distingan mayúsculas de minúsculas."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una tabla de HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes crear tablas de HubDB ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb",
        children: "a través de la interfaz de usuario de HubSpot"
      }), " o a través de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#create-a-new-table",
        children: "API de HubDB"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todas las nuevas tablas creadas se establecen con un estado de borrador. No se pueden utilizar para dar salida a los datos a través de HubL o la API hasta que se publique la tabla. Al crear una tabla, también puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "administrar su configuración"
      }), ", como permitir el acceso a la API pública y si sus datos se utilizarán para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "crear páginas dinámicas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unir varias tablas de HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las tablas de HubDB se pueden unir utilizando el tipo de columna ID externo, que te permite representar los datos combinados de varias tablas. Esto puede ser útil cuando algunos datos pueden compartirse entre varios almacenes de datos, lo que permite una tabla de datos centralizada de esta información, a la que luego se puede acceder a través de varios otros almacenes de datos de tablas de HubDB."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, aprende cómo unir varias tablas de HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1 1. Agrega una columna ID externo a la tabla principal"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, navega hasta ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Archivos y plantillas"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Busca la tabla a la que quieres agregar una unión de tabla, haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Acciones"
        }), " y, luego, selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Editar"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior derecha, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Editar"
        }), " y, luego, selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Agregar columna"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Introduce una ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "etiqueta"
        }), " y un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " para la nueva columna."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tipo de columna"
        }), " y selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ID externo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Seleccionar tabla"
        }), " y selecciona la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tabla"
        }), " que quieres unir con tu tabla actual."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el menú desplegable ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Seleccionar columna"
        }), " y, luego, selecciona la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "columna"
        }), " de la tabla que se une que elegiste para que sea visible en el campo ID externo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Agregar columna"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " el valor que elegiste como ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Seleccionar columna"
        }), " solo dicta qué valor de columna ves en el campo ID externo en la interfaz de usuario de HubDB. Todas las columnas de la tabla están disponibles al representar las tablas unidas de HubDB."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb_foreign_id.png",
        alt: "hubdb_foreign_id"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Agregar filas de tablas externas a las filas de tu tabla"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora que tienes una columna ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "ID externo"
      }), ", tendrás un campo de columna de selección múltiple en cada fila de tu tabla de HubDB, el cual te permite seleccionar las filas de una tabla externa."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El campo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Seleccionar columna"
      }), " que elegiste se utilizará en este campo de selección múltiple para identificar qué fila estás seleccionando de la tabla externa. En el siguiente ejemplo, los valores de selección múltiple para el campo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Unión de tablas Experiencia"
      }), " son los valores disponibles en la columna ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Nombre"
      }), " de la tabla de HubDB externa."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb_foreign_id_ui.png",
        alt: "hubdb_foreign_id_ui"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " es seguro editar el campo ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Seleccionar columna"
        }), " de tu columna ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "ID externo"
        }), " y de una manera simple se actualizarán los valores de la columna que se mostrarán en la interfaz de usuario de HubDB."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Renderiza los datos de tu tabla de HubDB unida"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se puede acceder a todos los datos de filas de una tabla externa a través de HubL para la representación, no solo al campo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Seleccionar columna"
      }), ". Se puede acceder a los datos de filas externas de HubDB mediante un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "bucle anidado for"
      }), ", que recorre todas las filas externas asociadas con una fila individual."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for row in hubdb_table_rows(tableId, filterQuery) %}\n  the name for row {{ row.hs_id }} is {{ row.name }}\n  {% for foreign_row in row.foreign_table %}\n  \tthe name for foreign row {{ foreign_row.hs_id }} is {{ foreign_row.name }}\n  {% endfor %}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Acceder a los datos de HubDB mediante HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con HubL, puedes extraer datos de HubDB para usarlos como contenido estructurado en páginas del sitio web. A continuación, obtén más información sobre cómo recuperar datos de tablas, filas y columnas con HubL."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " los datos redactados de la tabla de HubDB aparecerán en el editor de páginas y en las vistas previas en vivo, pero solo aparecerá el contenido publicado de HubDB en la página activa. Si ves que en el editor o en la vista previa aparecen datos de la tabla que no aparecen en la página activa, confirma que la tabla se ha publicado desde que se agregaron esos datos de la tabla."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obtención de filas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para listar las filas de una tabla, utiliza la función de Hubl ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-rows",
        children: "Hubdb_table_rows()"
      }), ". Puedes acceder a una tabla por su ID o por su nombre. Se recomienda hacer referencia a una tabla de HubDB por su nombre, ya que esto puede ayudar a la portabilidad del código entre las cuentas de HubSpot. El nombre de la tabla inmutable se establece cuando se crea una nueva tabla y se puede encontrar en cualquier momento seleccionando ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Acciones > Administrar configuración"
      }), " dentro del editor de tablas. El ID de una tabla se puede encontrar en la barra de direcciones del editor de tablas o en el panel de tablas de HubDB bajo la columna ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ID"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Table%20Create.png",
        alt: "Captura de pantalla del modal de creación de tablas"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación se muestra un ejemplo de uso de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows()"
      }), " para obtener datos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for row in hubdb_table_rows(<tableId or name>, <filterQuery>) %}\n  the value for row {{ row.hs_id }} is {{ row.<column name> }}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " por opción predeterminada, el número máximo de filas devueltas es 1.000. Para recuperar más filas, especifica un ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), " en la consulta de función. Por ejemplo:"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hudb_table_rows (12345, \"random()&limit=1500\")"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), " utiliza la misma sintaxis que la API HTTP. Por ejemplo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows(123, \"employees__gt=10&orderBy=count\")"
      }), " devolvería una lista de filas en las que la columna \"employees\" es mayor que 10, ordenadas por la columna \"count\". La lista completa de parámetros opcionales ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#get-table-rows",
        children: "se puede encontrar aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En lugar de utilizar varias consultas de filas con diferentes parámetros ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), ", se debe hacer una consulta y utilizar los filtros ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "selectattr()"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rejectattr()"
      }), " para filtrar las filas:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set all_cars = hubdb_table_rows(<tableId or name>) %}\n\n{% set cars_with_windows = all_cars|selectattr('windows') %}\n\n{% set teslas = all_cars|selectattr('make','equalto','tesla') %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener una sola fila, utiliza la función de Hubl ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Hubdb_table_row()"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set row = hubdb_table_row(<tableId or name>, <rowId>) %}\nthe value for {{ row.hs_id }} is {{ row.<column name> }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los nombres de las columnas incorporadas y personalizadas no distinguen entre mayúsculas y minúsculas. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HS_ID"
      }), " funcionará igual que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_id"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Atributos de las filas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Atributo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El identificador único global de esta fila."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cuando se utilizan páginas dinámicas, esta cadena es el valor de la columna Page Path y el último segmento de la ruta de la url."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cuando se utilizan páginas dinámicas, esta cadena es el valor de la columna Título de la página para la fila."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marca de tiempo Unix de cuándo la fila fue creada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_child_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cuando se utilizan páginas dinámicas, este es el ID de la otra tabla que está poblando los datos para la fila."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.column_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obtén el valor de la columna personalizada por el nombre de la columna."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row[\"column name\"]"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obtén el valor de la columna personalizada por el nombre de la columna."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obtener los metadatos de una tabla"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener los metadatos de una tabla, incluyendo su nombre, columnas, última actualización, etc., utiliza la función ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table()"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_info = hubdb_table(<tableId or name>) %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Atributos de la tabla"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Los atributos enumerados a continuación hacen referencia a la variable a la que se asignó ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table()"
        }), " en el código anterior. Tu variable puede ser diferente.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsxs)(_components.em, {
          children: ["Nota: se recomienda asignar esto a una variable para facilitar su uso. Si no quieres hacerlo, puedes utilizar", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ hubdb_table(<tableId>).attribute }}"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Atributo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de la tabla."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre de la tabla."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.columns"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Lista de información de las columnas. Puedes utilizar un ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/loops",
              children: "bucle for"
            }), " para iterar a través de la información disponible en este atributo."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marca de tiempo de cuándo la tabla fue creada por primera vez."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.published_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marca de tiempo de cuándo se publicó esta tabla."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.updated_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marca de tiempo de cuando esta tabla fue actualizada por última vez."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.row_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El número de filas de la tabla."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obtener los metadatos de una columna"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_info = hubdb_table_column(<tableId or name>, <columnId or column name>) %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener información sobre una columna de la tabla, como su etiqueta, tipo y opciones, La utiliza la función ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_column()"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Atributos de las columnas"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Los atributos enumerados a continuación hacen referencia a la variable que se asignó ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_column()"
        }), " en el código anterior. Tu variable puede ser diferente.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsxs)(_components.em, {
          children: ["Nota: se recomienda asignar esto a una variable para facilitar su uso. Si no quieres hacerlo, puedes utilizar", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ hubdb_table_column(<tableId>,<columnId or column name>).attribute }}"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Atributo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de la columna."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre de la columna."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La etiqueta que se utilizará para la columna."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tipo de esta columna."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.options"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para el tipo de columna select, se trata de un mapa de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "optionId"
            }), " para información de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "option"
            }), " ."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.foreignIds"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para los tipos de columna foreignId, una lista de foreignIds (con propiedades de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nombre"
            }), " )."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Métodos de columna"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Método"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "getOptionByName(\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Para los tipos de columna select, obtén la información de la opción por el nombre de las opciones."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Columnas de texto enriquecido"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El tipo de columna ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "richtext"
      }), " funciona de forma similar al campo de texto enriquecido que se ve en los módulos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los datos se almacenan como HTML, y la interfaz de usuario de HubDB proporciona una interfaz de edición de texto. Sin embargo, al editar una tabla de HubDB a través de la interfaz de usuario de HubSpot, no puedes editar el código fuente directamente. Esto previene situaciones en las que un usuario no técnico pueda introducir un HTML no válido, evitando problemas involuntarios con la apariencia o funcionalidad de tu sitio. Para situaciones en las que necesites un código de inserción o más HTML personalizado, puedes utilizar la función de inserción en el editor de texto enriquecido para colocar tu código personalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriales y recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "Aplicación de registro en eventos"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "Cómo construir una página dinámica de miembros del equipo con HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "Cómo agregar videos a páginas dinámicas"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "Cómo construir páginas dinámicas multinivel usando HubDB"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}