"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694148;
const slug = exports.slug = 'guides/cms/content/website-settings';
const title = exports.title = 'Configuración del sitio web';
const name = exports.name = '[nuevo] Configuración del sitio web';
const metaDescription = exports.metaDescription = 'La configuración del sitio web es un único lugar donde se pueden configurar varios ajustes de contenido global y de nivel de sistema para el blog, la navegación, las páginas y los temas de tu sitio web.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/blog-settings-screen-4.jpg';
const featuredImageAltText = exports.featuredImageAltText = ' Área de configuración del sitio web';
const toc = exports.toc = [{
  "depth": 0,
  "id": "configuraci%C3%B3n-del-blog",
  "label": "Configuración del blog",
  "parent": null
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-general",
  "label": "Pestaña General",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "nombre-del-blog",
  "label": "Nombre del blog",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "encabezado-del-blog",
  "label": "Encabezado del blog",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "t%C3%ADtulo-de-la-p%C3%A1gina",
  "label": "Título de la página",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "metadescripci%C3%B3n",
  "label": "Metadescripción",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "url-ra%C3%ADz-del-blog",
  "label": "URL raíz del blog",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "controlar-el-acceso-de-la-audiencia",
  "label": "Controlar el acceso de la audiencia",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-plantillas",
  "label": "Pestaña Plantillas",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "plantilla-actual",
  "label": "Plantilla actual",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "plantilla-para-p%C3%A1ginas-de-listas-opcional",
  "label": "Plantilla para páginas de listas (opcional)",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "n%C3%BAmero-de-posts-por-p%C3%A1gina-de-%C3%ADndice",
  "label": "Número de posts por página de índice",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "html-de-encabezado",
  "label": "HTML de encabezado",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "html-del-pie-de-p%C3%A1gina",
  "label": "HTML del pie de página",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-suscripciones",
  "label": "Pestaña Suscripciones",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "correos-de-notificaci%C3%B3n-para-suscriptores-de-blogs",
  "label": "Correos de notificación para suscriptores de blogs",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "fuente-rss",
  "label": "Fuente RSS",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-formatos-de-fecha",
  "label": "Pestaña Formatos de fecha",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "idioma-de-las-fechas",
  "label": "Idioma de las fechas",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "formato-de-fecha-de-publicaci%C3%B3n",
  "label": "Formato de fecha de publicación",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "publicaciones-por-formato-de-mes",
  "label": "Publicaciones por formato de mes",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-comentarios",
  "label": "Pestaña Comentarios",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "activar-o-desactivar-los-comentarios-del-blog",
  "label": "Activar o desactivar los comentarios del blog",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "notificaciones-de-comentarios-del-blog",
  "label": "Notificaciones de comentarios del blog",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-compartir-en-redes-sociales",
  "label": "Pestaña Compartir en redes sociales",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "cuenta-de-twitter-predeterminada",
  "label": "Cuenta de Twitter predeterminada",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "botones-para-compartir-en-las-redes-sociales",
  "label": "Botones para compartir en las redes sociales",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 1,
  "id": "pesta%C3%B1a%C2%A0google-amp",
  "label": "Pestaña Google AMP",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "activar-o-desactivar-google-amp",
  "label": "Activar o desactivar Google AMP",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 2,
  "id": "configuraci%C3%B3n-espec%C3%ADfica-de-amp-para-un-logotipo%2C-formato-de-encabezado",
  "label": "Configuración específica de AMP para un logotipo, formato de encabezado",
  "parent": "configuraci%C3%B3n-del-blog"
}, {
  "depth": 0,
  "id": "configuraci%C3%B3n-de-navegaci%C3%B3n",
  "label": "Configuración de navegación",
  "parent": null
}, {
  "depth": 0,
  "id": "configuraci%C3%B3n-de-la-p%C3%A1gina",
  "label": "Configuración de la página",
  "parent": null
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-plantillas",
  "label": "Pestaña Plantillas",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "html-de-encabezado-del-sitio",
  "label": "HTML de encabezado del sitio",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "html-de-pie-de-p%C3%A1gina-del-sitio",
  "label": "HTML de pie de página del sitio",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "jquery",
  "label": "jQuery",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-marca",
  "label": "Pestaña Marca",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "favicon",
  "label": "Favicon",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "logotipo-texto-alternativo%2C-dimensiones%2C-enlace",
  "label": "Logotipo (texto alternativo, dimensiones, enlace)",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-personalizaci%C3%B3n",
  "label": "Pestaña Personalización",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "contactos-y-valores-predeterminados-de-la-empresa",
  "label": "Contactos y valores predeterminados de la empresa",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-integraciones",
  "label": "Pestaña Integraciones",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "integraciones-js",
  "label": "Integraciones JS",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-seo-y-rastreadores.",
  "label": "Pestaña SEO y rastreadores.",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "url-can%C3%B3nicas",
  "label": "URL canónicas",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "dominio-de-alojamiento-de-archivos-predeterminado",
  "label": "Dominio de alojamiento de archivos predeterminado",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 2,
  "id": "robots.txt",
  "label": "Robots.txt",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 1,
  "id": "pesta%C3%B1a-p%C3%A1ginas-del-sistema",
  "label": "Pestaña Páginas del sistema",
  "parent": "configuraci%C3%B3n-de-la-p%C3%A1gina"
}, {
  "depth": 0,
  "id": "p%C3%A1ginas-de-errores-404-y-500",
  "label": "Páginas de errores 404 y 500",
  "parent": null
}, {
  "depth": 0,
  "id": "p%C3%A1gina-de-solicitud-de-contrase%C3%B1a",
  "label": "Página de solicitud de contraseña",
  "parent": null
}, {
  "depth": 2,
  "id": "url-de-p%C3%A1gina-de-resultados-de-b%C3%BAsqueda",
  "label": "URL de página de resultados de búsqueda",
  "parent": "p%C3%A1gina-de-solicitud-de-contrase%C3%B1a"
}, {
  "depth": 0,
  "id": "configuraci%C3%B3n-de-tema",
  "label": "Configuración de tema",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      h3: "h3",
      h4: "h4",
      code: "code",
      em: "em",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configuración del sitio web"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La configuración del sitio web es un único lugar donde se pueden configurar varios ajustes de contenido global y de nivel de sistema para el blog, la navegación, las páginas y los temas de tu sitio web. Navega a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/blogs/",
        children: "Configuración > Sitio web"
      }), " y elige el área de contenido para la que deseas acceder a tu Configuración de contenido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuración del blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En esta área, controlarás la configuración de contenido para los blogs de tus sitios. Si tienes varios blogs, puedes cambiar entre ellos usando el menú desplegable bajo el encabezado «Seleccionar blog para modificarlo»."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/blog-settings-screen-4.jpg",
        alt: "Pantalla de configuración del blog"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña General"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Nombre del blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las variables HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ content.blog }}"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.public_title }}"
      }), " representará el nombre establecido aquí."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Encabezado del blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La variable HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.header }}"
      }), " mostrará el conjunto de encabezados aquí."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Título de la página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La variable HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.html_title }}"
      }), " mostrará el título establecido aquí."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Metadescripción"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La variable HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.description }}"
      }), " mostrará la descripción establecida aquí. Esta metadescripción se utilizará en las páginas de listas de blogs."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "URL raíz del blog"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La URL raíz del blog precederá a los slugs individuales de la publicación del blog. La variable HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ group.absolute_url }}"
      }), " mostrará la URL establecida aquí."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Controlar el acceso de la audiencia"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes controlar el acceso de la audiencia a todo un blog a través de esta configuración. Más información ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-pages-editor/control-audience-access-to-pages",
        children: "aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Plantillas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Plantilla actual"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta es la plantilla utilizada para todas las publicaciones de blog en un blog en particular. La misma plantilla se utilizará para las páginas de listas de blogs también por opción predeterminada. El contenido variable para las páginas de listas frente a las publicaciones se puede escribir dentro del módulo Contenido de la publicación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Plantilla para páginas de listas (opcional)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta configuración habilita una plantilla diferente para páginas de listas de blogs que no sea la plantilla utilizada para publicaciones de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Número de posts por página de índice"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esto determina el número de elementos de publicación que aparecen en la página de listas de un blog de por opción predeterminada."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTML de encabezado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cualquier código agregado al HTML del encabezado de la lista del blog se agregará a todas las páginas de la lista a través de la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), ". Cualquier código agregado al HTML del encabezado de la entrada del blog se agregará a todas las entradas del blog a través de la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTML del pie de página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cualquier código agregado al HTML del pie de página de listas de blog se agregará a todas las páginas de listas a través de la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), ". Cualquier código agregado al HTML del pie de página de la publicación del blog se agregará a todas las publicaciones del blog a través de la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Suscripciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Correos de notificación para suscriptores de blogs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los correos electrónicos de notificación de blog instantáneos, diarios, semanales y mensuales se pueden habilitar y editar a través de esta configuración. Estos correos electrónicos se envían automáticamente si se publicaron nuevos posts de blog en un plazo determinado. Obtén más información ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-blog/set-up-your-blog-subscription-features-in-hubspot",
        children: "aquí"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Fuente RSS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El número de elementos de publicación en el feed RSS del blog se puede determinar a través de esta configuración. Hay un máximo de 50 publicaciones."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Formatos de fecha"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Idioma de las fechas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta configuración determina el idioma de los meses y días que aparecen en las fechas del blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Formato de fecha de publicación"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El formato establecido aquí determina el orden y el patrón de las fechas de publicación en los blogs. Utilizando el Idioma local de marcado de datos, es posible especificar un formato de fecha personalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Publicaciones por formato de mes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El formato establecido aquí determina el orden y el patrón de las publicaciones por mes. Utilizando el Idioma local de marcado de datos, es posible especificar un formato de fecha personalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Comentarios"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Activar o desactivar los comentarios del blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es posible activar o desactivar los comentarios del blog a través de esta configuración. Los comentarios pueden requerir moderación o tener un plazo establecido después del cual los comentarios se cierran automáticamente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Notificaciones de comentarios del blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los comentarios del blog pueden desencadenar notificaciones por correo electrónico a usuarios específicos a través de esta configuración."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Compartir en redes sociales"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Cuenta de Twitter predeterminada"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La cuenta de Twitter especificada aquí se utilizará para Twitter Cards cuando el contenido se comparta en Twitter."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Botones para compartir en las redes sociales"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los botones para compartir en redes sociales para Twitter, LinkedIn y Facebook se pueden agregar automáticamente a las publicaciones del blog a través de esta configuración."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Google AMP"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las páginas móviles aceleradas (Accelerated Mobile Pages, AMP) cargan tu contenido al instante. Obtén más información ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-to-use-accelerated-mobile-pages-amp-in-hubspot",
        children: "aquí"
      }), ". Para cargar contenido tan rápidamente, se requiere una experiencia de página simplificada. EL contenido de AMP tiene un control de estilo limitado por esta razón."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Activar o desactivar Google AMP"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las páginas con formato de Google AMP se pueden habilitar a través de esta configuración. Configuración de logotipo, fuente y color de AMP"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Configuración específica de AMP para un logotipo, formato de encabezado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para entregar contenido de AMP, se requiere un estilo simplificado. Determina los estilos específicos de AMP para el logotipo, el formato de encabezado, las fuentes y los colores a través de esta configuración."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuración de navegación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes administrar los enlaces y etiquetas de tu menú en esta área. Puedes alternar entre los menús que deseas configurar eligiendo el menú desplegable y seleccionando el que desees. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/set-up-your-site-s-navigation-menus",
        children: "Más información sobre cómo configurar los menús de navegación de tus sitios aquí."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/navigation-settings-screen-4.jpg",
        alt: "Pantalla de configuración de navegación"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuración de la página"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La configuración se desglosa por dominios y se pueden establecer valores predeterminados para todos los dominios. La configuración «", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Predeterminada para todos los dominiosdomains"
      }), "” se mostrará al navegar a Configuración de páginas. Hay un interruptor en la parte superior de la pantalla para ver y modificar la configuración de subdominios específicos. La configuración aplicada a subdominios específicos anulará la configuración predeterminada para todos los dominios."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Solo los usuarios con el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "permiso de marketing «Editar la configuración del sitio web»"
      }), " pueden acceder y editar la configuración de contenido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/page-settings-screen-4.jpg",
        alt: "Pantalla Configuración de páginas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Plantillas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTML de encabezado del sitio"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cualquier código agregado al campo HTML del encabezado del sitio en Configuración de páginas se incluirá en la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_header_includes }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "HTML de pie de página del sitio"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cualquier código agregado al campo HTML del pie de página del sitio en Configuración de páginas se incluirá en la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ standard_footer_includes }}"
      }), ". Por lo general, este es un buen lugar para agregar códigos de seguimiento y otros scripts que son «no esenciales» para que tu sitio funcione o se vea bien. De esa manera no afectará negativamente a ninguna de tus plantillas o páginas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes modificar la carga de la versión de jQuery en tu página a través de Configuración de la página."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes optar por cargar jQuery desde tu pie de página a través de esta configuración. Anexar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?hsMoveJQueryToFooter=True"
      }), " a la URL de tu página te permitirá probar este cambio y asegurarte de que no afecte negativamente a la apariencia de tu sitio."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La opción para desactivar jQuery también se encuentra en Configuración de páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Marca"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La imagen del logotipo establecida para cada dominio aquí se utilizará automáticamente en el módulo predeterminado «Logotipo»."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Favicon"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Logotipo (texto alternativo, dimensiones, enlace)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La URL de origen de tu imagen de favicon se puede extraer del diccionario ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "site_settings"
      }), " y usar en tus archivos codificados:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if site_settings.favicon_src %}<link rel=\"icon\" href=\"{{ site_settings.favicon_src }}\" />{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Personalización"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Contactos y valores predeterminados de la empresa"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Estos son los valores predeterminados utilizados para tokens de personalización utilizados en páginas cuando el visitante es desconocido."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Integraciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Integraciones JS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Habilita las integraciones JS (como Google Analytics o AdRoll) para todos los dominios o selecciona dominios aquí. Si utilizas otros scripts de seguimiento o Google Tag Manager en lugar de Google Analytics, ese código debe agregarse al ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#site-footer-html",
        children: "HTML del pie de página del sitio"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña SEO y rastreadores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "URL canónicas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Selecciona tu configuración de canonicalización predeterminada para páginas y publicaciones individuales, así como páginas de listas, aquí."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Dominio de alojamiento de archivos predeterminado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esto controla el dominio en el que parecen estar alojados los activos del administrador de archivos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Robots.txt"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Modifica tu archivo robots.txt para cada dominio aquí."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Pestaña Páginas del sistema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para varias páginas del sistema, puedes seleccionar las plantillas en Configuración de páginas. No existe un editor de páginas para estas páginas, solo las plantillas en el Administrador de diseño que se crean con el tipo de plantilla «Sistema». ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Nota"
        })
      }), (0, _jsxRuntime.jsx)(_components.em, {
        children: ": Las preferencias de correo electrónico y las plantillas de suscripción se encuentran en Configuración de correo electrónico, no en Configuración de páginas."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Páginas de errores 404 y 500"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Estas son las páginas que se devuelven para los códigos de estado 404 o 500. Al crear una nueva plantilla, selecciona el tipo de plantilla ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#error-pages",
        children: "«Página de error»"
      }), " en el Administrador de diseño para hacer que una plantilla esté disponible para estas páginas del sistema."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Página de solicitud de contraseña"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta es la página que se devuelve para las páginas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-pages-editor/how-can-i-password-protect-my-pages",
        children: "protegidas por contraseña"
      }), " cuando se solicita a un visitante que introduzca una contraseña. Al crear una nueva plantilla, selecciona el tipo de plantilla ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#password-prompt",
        children: "«Página de solicitud de contraseña»"
      }), " en el Administrador de diseño para hacer que una plantilla esté disponible para este tipo de página del sistema."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "URL de página de resultados de búsqueda"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta es la página que enumera los resultados de búsqueda para las consultas introducidas en el módulo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/how-do-i-set-up-a-results-page-for-my-search-field-in-hubspot",
        children: "Búsqueda en el sitio"
      }), " de HubSpot. Lee más sobre cómo personalizar tu búsqueda ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/site-search/v2#search-your-site",
        children: "aquí"
      }), ". Al crear una nueva plantilla, selecciona el tipo de plantilla ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#search-results-page",
        children: "«Página de resultados de búsqueda»"
      }), " en el Administrador de diseño para crear una plantilla para este tipo de página del sistema. También puedes determinar la URL de tu página de resultados de búsqueda en Configuración de páginas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuración de tema"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aquí puedes encontrar todos los temas agregados a tu sitio. Puedes ir al editor de temas haciendo clic en uno de los temas disponibles en tu sitio."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/website-settings/theme-settings-screen-4.jpg",
        alt: "Pantalla de configuración del tema"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}