import enviro from 'enviro';
import { getFullUrl } from 'hubspot-url-utils';
export const getRequestOptionsForSpecCall = () => {
  /**
   * Use PROD data locally and on PROD, but not on the deployed QA env where
   * the QA rollout ID is different. Translations currently only exist on
   * PROD.
   *
   * @see https://hubspot.slack.com/archives/C6PL2HX0C/p1718054517626199
   */

  const isProdData = enviro.isProd() || enviro.isQa() && !enviro.deployed();
  const prodUrl = getFullUrl('api', {
    envOverride: 'prod'
  }).replace('https://', '');
  if (isProdData) {
    return {
      api: {
        local: {
          qa: prodUrl,
          prod: prodUrl
        },
        deployed: {
          qa: prodUrl,
          prod: prodUrl
        }
      }
    };
  }
  return {};
};