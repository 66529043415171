"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694134;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/tutorial';
const title = exports.title = 'Creación de un área de arrastrar y soltar';
const name = exports.name = '[nuevo] Primeros pasos con las áreas de arrastrar y soltar';
const metaDescription = exports.metaDescription = 'Uso de áreas de arrastrar y soltar para ayudar a los creadores de contenidos a realizar fácilmente cambios de diseño, estilo y contenido';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-crear-una-plantilla-html-nueva",
  "label": "1. Crear una plantilla HTML nueva",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-%C3%A1reas-de-arrastrar-y-soltar",
  "label": "2. Áreas de arrastrar y soltar",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-crear-una-secci%C3%B3n-con-un-m%C3%B3dulo",
  "label": "3. Crear una sección con un módulo",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-incluir-varios-m%C3%B3dulos",
  "label": "4. Incluir varios módulos",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-incorporar-columnas-y-filas",
  "label": "5. Incorporar columnas y filas",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-establecer-estilos-gen%C3%A9ricos-de-componentes-para-arrastrar-y-soltar",
  "label": "6. Establecer estilos genéricos de componentes para arrastrar y soltar",
  "parent": null
}, {
  "depth": 0,
  "id": "tutoriales-relacionados",
  "label": "Tutoriales relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      em: "em",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Primeros pasos con las áreas de arrastrar y soltar"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Las áreas de arrastrar y soltar"
      }), " permiten a los desarrolladores crear secciones de páginas que admiten cambios de diseño, estilo y contenido directamente en los editores de contenido. Esto permite a los desarrolladores crear unas pocas plantillas con estructura global, que apoyan a los creadores de contenido haciendo una multitud de páginas con varios propósitos y diseños, sin tener que lidiar con el código o requerir nuevas plantillas para pequeños cambios de diseño."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/a%20new%20approach.gif",
        alt: "Animación de módulos arrastrados a una página, con ajuste de columnas y filas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los desarrolladores pueden especificar zonas vacías para las áreas de arrastrar y soltar, donde los creadores de contenido construyen su propio contenido y diseño de la página, o bien, los desarrolladores pueden rellenar previamente las áreas de arrastrar y soltar con varios módulos, diseños, estilos y contenido para actuar como un punto de partida para los creadores de contenido para trabajar."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este tutorial te guiará en la configuración de un área de arrastrar y soltar simple. Para obtener más recursos para desarrolladores sobre las áreas de arrastrar y soltar, consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/home.html",
        children: "plantilla"
      }), " para las mejores prácticas de implementación, así como la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "documentación de referencia de la etiqueta HubL del área de arrastrar y soltar"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " Un creador de contenido puede cambiar la plantilla de una página por otra del mismo tipo, dependiendo de si tiene etiquetas ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/cms/hubl/tags/dnd-areas",
            children: "dnd_area"
          })
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas construidas con el editor de disposición visual de arrastrar y soltar pueden cambiarse por otras plantillas de arrastrar y soltar o plantillas codificadas con o sin etiquetas ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas codificadas con etiquetas ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " solo pueden cambiarse por otras plantillas codificadas con etiquetas ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas codificadas sin etiquetas ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " solo pueden cambiarse por otras plantillas codificadas sin etiquetas ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Crear una plantilla HTML nueva"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Crea una nueva plantilla html para alojar el HubL y el HTML que conformarán tu sección de arrastrar y soltar."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Las áreas de arrastrar y soltar se basan en una cuadricula responsiva de 12 columnas. Las etiquetas de arrastrar y soltar generan marcas con nombres de clase que designan columnas y filas. Tú eres responsable de agregar una hoja de estilo para orientar esos nombres de clase. Un ejemplo de estilos de diseño que podrías implementar se encuentra en: ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
          children: "CMS-Theme-Boilerplate"
        }), ". Tu hoja de estilo puede agregarse a la plantilla usando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-css",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ require_css() }}"
          })
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Áreas de arrastrar y soltar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " es el contenedor que hace que una parte de la página web sea editable en cuanto a su estructura, diseño y contenido. El cuerpo de una etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " suministra el contenido por opción predeterminada para el área de arrastrar y soltar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta etiqueta por sí sola generará una zona de caída para que los creadores de contenidos arrastren los módulos dentro del creador de contenidos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n\t<!-- generates an empty drag and drop area drop-section -->\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Crear una sección con un módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " es una fila de nivel superior, y solo puede ser elemento secundario directo de una ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ". Las secciones admiten una variedad de parámetros que controlan los valores por opción predeterminada de los controles estilísticos que los creadores de contenido tienen para las secciones dentro de los creadores de contenido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Establezcamos una imagen de fondo, y establezcamos una alineación vertical centrada y un ancho máximo de 1000px para el contenido secundario. Para obtener una lista completa de los parámetros admitidos en las etiquetas HubL de arrastrar y soltar, consulta la documentación de referencia de la etiqueta HubL de arrastrar y soltar."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para rellenar la sección con contenido, podemos utilizar la etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " para incluir un módulo haciendo referencia a su ruta. En este ejemplo, estamos haciendo referencia a un módulo predeterminado de HubSpot, pero puedes incluir adicionalmente módulos que hayas construido, especificando su ruta dentro de tu árbol de archivos de Herramientas de Diseño."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para especificar un valor predeterminado para nuestro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ", podemos utilizar la etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_attribute"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n    {% dnd_section\n        background_image = {\n            'backgroundPosition': 'MIDDLE_CENTER',\n            'backgroundSize': 'cover',\n            'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n          },\n          max_width=1000,\n          vertical_alignment='MIDDLE'\n    %}\n        {% dnd_module path='@hubspot/rich_text' %}\n            {% module_attribute \"html\"%}\n                This is your main headline.\n                Use this space to tell everyone about what you have to offer.\n            {% end_module_attribute %}\n        {% end_dnd_module %}\n    {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora, podemos ver que nuestra área de arrastrar y soltar contiene un módulo que los creadores de contenido pueden editar dentro del editor de contenido. También podemos ver cómo fijar una ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "max_width"
      }), " en la ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " afecta a nuestro contenido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_rich_text.png",
        alt: "captura de pantalla del editor de páginas con la barra de herramientas del módulo mostrando"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Incluir varios módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para incluir más de un módulo, podemos utilizar varias etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ". Al definir los parámetros ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), " que se basan en una cuadrícula de 12 columnas, podemos colocar un módulo de imagen junto a nuestro módulo de texto enriquecido."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n    {% dnd_section\n        background_image={\n            'backgroundPosition': 'MIDDLE_CENTER',\n            'backgroundSize': 'cover',\n            'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n        },\n        max_width=1000,\n        vertical_alignment='MIDDLE'\n    %}\n        {% dnd_module path='@hubspot/rich_text', width=8, offset=0, label=\"Rich Text\" %}\n            {% module_attribute \"html\"%}\n            \t<h1>This is your main headline.</h1>\n            \t<p>Use this space to tell everyone about what you have to offer.</p>\n            {% end_module_attribute %}\n        {% end_dnd_module %}\n        {% dnd_module path='@hubspot/linked_image',\n          width=4,\n          offset=8,\n          img={\n            \"src\": \"https://www.dragndrop.com/placeholder-image.jpg\",\n            \"alt\": \"Stock placeholder image\"\n          }\n        %}\n        {% end_dnd_module %}\n    {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora, también tenemos un módulo de imagen editable, así como una manilla de arrastre, que permite a los creadores de contenido cambiar el ancho y el desplazamiento de los módulos. También podemos ver cómo fijar una ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vertical_alignment"
      }), " en la ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " centra el contenido de forma vertical."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_rich_image.png",
        alt: "captura de pantalla del editor de páginas mostrando un módulo de imagen agregado a una sección"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Incorporar columnas y filas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para hacer más compleja nuestra zona de arrastre, podemos incorporar filas y columnas utilizando las etiquetas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), ". Las filas y columnas actúan de forma similar a las secciones en el editor de contenido, donde los creadores de contenido pueden arrastrarlas, así como clonar, eliminar y dar estilo a las filas y columnas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n  {% dnd_section\n    background_image={\n        'backgroundPosition': 'MIDDLE_CENTER',\n        'backgroundSize': 'cover',\n        'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n    },\n    max_width=1000,\n    vertical_alignment='MIDDLE'\n  %}\n    {% dnd_module path='@hubspot/linked_image',\n      width=6,\n      img={\n        \"src\": \"https://www.dragndrop.com/placeholder-image.jpg\",\n        \"alt\": \"Stock placeholder image\"\n      }\n    %}\n    {% end_dnd_module %}\n    {% dnd_column width=6, offset=6 %}\n      {% dnd_row\n        padding={\n            'bottom': 15\n        }\n      %}\n        {% dnd_module path='@hubspot/rich_text' %}\n          {% module_attribute \"html\"%}\n              <h1>This is your main headline.</h1>\n              <p>Use this space to tell everyone about what you have to offer.</p>\n          {% end_module_attribute %}\n        {% end_dnd_module %}\n      {% end_dnd_row %}\n      {% dnd_row %}\n        {% dnd_module path='@hubspot/form' %}\n        {% end_dnd_module %}\n      {% end_dnd_row %}\n    {% end_dnd_column %}\n  {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ahora, nuestros creadores de contenido tendrán un mayor control estilístico y de diseño sobre filas y columnas específicas, además de módulos y secciones."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_area_full.png",
        alt: "captura de pantalla del editor de páginas mostrando una fila con dos columnas, un módulo de imagen a la izquierda, un módulo de texto enriquecido y un módulo de formulario a la derecha."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Establecer estilos genéricos de componentes para arrastrar y soltar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los distintos componentes de las áreas de arrastrar y soltar, las secciones, las columnas, las filas y los módulos tienen clases que pueden ser estilizadas usando CSS. Los estilos y opciones editables de estos componentes pueden establecerse utilizando CSS en lugar de HubL. Por ejemplo, el relleno predeterminado se puede definir en las ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_sections"
      }), " con el CSS:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".dnd-section {\n  padding: 80px 20px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los selectores CSS genéricos para los componentes del área de arrastrar y soltar son ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-section"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-column"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-row"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-module"
      }), ". Aparte de estas clases con prefijo dnd, los nombres de las clases de la cuadrícula en el marcado se basan en los nombres de bootstrap 2. Esto no significa que tengas que usar bootstrap 2 con áreas de arrastrar y soltar. Cuando agregas una ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " a la página, eres responsable de proporcionar los estilos que hacen que la cuadrícula funcione. Un ejemplo de estilos de diseño que podrías implementar se encuentra en: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/css/objects",
        children: "CMS-Theme-Boilerplate"
      }), ". Tu hoja de estilo puede agregarse a la plantilla usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#require-css",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ require_css() }}"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener más recursos para desarrolladores sobre las áreas de arrastrar y soltar, consulta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "la plantilla"
      }), " para las mejores prácticas de implementación, así como la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "documentación de referencia de la etiqueta HubL del área de arrastrar y soltar de"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriales relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Primeros pasos para usar temas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Primeros pasos con los módulos personalizados"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites",
          children: "Cómo agregar capacidades temáticas a los sitios existentes"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}