import { NORMAN_DARK, OZ_DARK, SORBET, THUNDERDOME_DARK } from 'HubStyleTokens/colors';
/**
 * Given a `use` string, returns its color hex string.
 */
export const getBadgeColorByMethodName = use => {
  switch (use.toLowerCase()) {
    case 'get':
      return THUNDERDOME_DARK;
    case 'post':
      return OZ_DARK;
    case 'delete':
      return NORMAN_DARK;
    default:
      // PUT and PATCH
      return SORBET;
  }
};