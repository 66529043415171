"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 21611793414;
const slug = exports.slug = 'guides/api/analytics-and-events/email-analytics';
const title = exports.title = 'Visión general de la API Eventos de correo electrónico | API de Eventos de Correo Electrónico';
const name = exports.name = 'Visión general de la API Eventos de correo electrónico';
const metaDescription = exports.metaDescription = 'La API Eventos de correo electrónico se utiliza para obtener información sobre eventos generados por correos electrónicos de marketing o campañas de correo electrónico enviados a través de una cuenta de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tipos-de-eventos",
  "label": "Tipos de eventos",
  "parent": null
}, {
  "depth": 2,
  "id": "motivos-de-la-p%C3%A9rdida",
  "label": "Motivos de la pérdida",
  "parent": "tipos-de-eventos"
}, {
  "depth": 1,
  "id": "eventos-de-entrega",
  "label": "Eventos de entrega",
  "parent": "tipos-de-eventos"
}, {
  "depth": 2,
  "id": "entregado",
  "label": "Entregado",
  "parent": "tipos-de-eventos"
}, {
  "depth": 2,
  "id": "diferidos",
  "label": "Diferidos",
  "parent": "tipos-de-eventos"
}, {
  "depth": 2,
  "id": "rebote",
  "label": "Rebote",
  "parent": "tipos-de-eventos"
}, {
  "depth": 2,
  "id": "categor%C3%ADas-de-rebote",
  "label": "Categorías de rebote",
  "parent": "tipos-de-eventos"
}, {
  "depth": 1,
  "id": "eventos-de-interacci%C3%B3n-con-usuarios",
  "label": "Eventos de interacción con usuarios",
  "parent": "tipos-de-eventos"
}, {
  "depth": 1,
  "id": "eventos-de-estado-del-usuario",
  "label": "Eventos de estado del usuario",
  "parent": "tipos-de-eventos"
}, {
  "depth": 2,
  "id": "fuentes-de-cambio-de-suscripci%C3%B3n",
  "label": "Fuentes de cambio de suscripción",
  "parent": "tipos-de-eventos"
}, {
  "depth": 1,
  "id": "eventos--eliminar-rebote-",
  "label": "Eventos 'Eliminar rebote'",
  "parent": "tipos-de-eventos"
}, {
  "depth": 0,
  "id": "referencias-de-eventos",
  "label": "Referencias de eventos",
  "parent": null
}, {
  "depth": 1,
  "id": "-sentby-",
  "label": "'sentBy'",
  "parent": "referencias-de-eventos"
}, {
  "depth": 1,
  "id": "-obsoletedby-",
  "label": "'obsoletedBy'",
  "parent": "referencias-de-eventos"
}, {
  "depth": 1,
  "id": "-causedby-",
  "label": "'causedBy'",
  "parent": "referencias-de-eventos"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h3: "h3",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      img: "img",
      em: "em",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visión general de la API Eventos de correo electrónico"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La API Eventos de correo electrónico se utiliza para obtener información sobre eventos generados por correos electrónicos de marketing o campañas de correo electrónico enviados a través de una cuenta de HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Caso práctico de esta API:"
      }), " si un miembro de tu equipo de marketing quiere optimizar las campañas de correo electrónico, puedes utilizar la API Eventos de correo electrónico para recopilar datos y alimentar un modelo de aprendizaje automático que determine el mejor momento para enviar correos electrónicos a diferentes segmentos de contactos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eventos de correo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada correo electrónico enviado a través de HubSpot genera una serie de eventos que detallan su ciclo de vida y cómo interactúa el destinatario con su contenido. Un evento se identifica de manera única por su ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "EventId"
      }), ", que se compone de las siguientes propiedades:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un ID generado aleatoriamente para este evento."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "creado"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entero de 64 bits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Marca de tiempo (en milisegundos desde la época) en que se creó este evento."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Estas propiedades pueden utilizarse para buscar un evento específico a través de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/email-analytics#get-email-event-by-id",
        children: "este punto de terminación"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además, todos los tipos de eventos menos uno (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), ") tienen las siguientes propiedades:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "tipo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena (enumeración)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El tipo de evento. Ver más información sobre los tipos de eventos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "destinatario"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La dirección de correo electrónico del destinatario del mensaje de correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "portalId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "entero de 32 bits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un ID que hace referencia a la cuenta de HubSpot que envió el mensaje de correo electrónico. Esto corresponderá a tu cuenta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "appId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "entero de 32 bits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un ID que hace referencia a la aplicación HubSpot que envió el mensaje de correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "appName"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre de la aplicación de HubSpot que envió el mensaje de correo electrónico. Ten en cuenta que se trata de un valor derivado y que puede modificarse en cualquiera de los casos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "emailCampaignId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "entero de 64 bits"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un ID que hace referencia a la campaña de correo electrónico de la que forma parte el mensaje. Puedes encontrar información adicional sobre la campaña de correo electrónico a través de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/email-analytics#get-campaign-data-for-a-given-campaign",
              children: "este punto de terminación"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los eventos se pueden buscar de forma masiva a través de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/email-analytics#get-email-events",
        children: "este punto de terminación"
      }), " utilizando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'recipient'"
      }), ", tanto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'appId'"
      }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'campaignId'"
      }), ", o cualquiera de las combinaciones de las propiedades anteriores."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las siguientes propiedades adicionales también están disponibles para todos los tipos de eventos (incluido ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), "):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "sentBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El EventId que identifica de forma única el evento ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT"
            }), " del mensaje de correo electrónico. Si no procede, se omite esta propiedad."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "obsoletedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El EventId que identifica de manera única el evento de seguimiento que hace obsoleto este evento actual. Si no procede, se omite esta propiedad."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "causedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El EventId que identifica de forma única el evento que causó directamente este evento. Si no procede, se omite esta propiedad."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las propiedades de referencia del evento -- ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'sentBy'"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), " -- se tratan en detalle más adelante en este documento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de eventos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay 12 tipos de eventos que pueden ser generados por la API Correo electrónico de HubSpot durante el ciclo de vida de un mensaje de correo. A grandes rasgos, se agrupan en categorías: Envío, Entrega, Interacción con el usuario y Estado del usuario. A continuación se muestran los tipos de eventos, las categorías de eventos y sus relaciones."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://static.hsappstatic.net/developer_site_web/static-2.153/img/events.svg",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de evento"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El mensaje fue enviado y recibido por nuestro proveedor de entrega, lo que se ha puesto en cola para más manejo adicional."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DROPPED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El mensaje fue rechazado, por HubSpot o por nuestro proveedor de entrega, y no se hará ningún intento de entregar el mensaje."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PROCESSED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El mensaje fue recibido por nuestro proveedor de entrega, lo que indica que intentará entregar el mensaje al servidor de correo electrónico del destinatario."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DELIVERED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El servidor de correo electrónico del destinatario aceptó el mensaje y el mensaje se entregó correctamente al destinatario."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DEFERRED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El servidor de correo electrónico del destinatario rechazó temporalmente el mensaje, pero se harán intentos posteriores para entregarlo."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BOUNCE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El servidor de correo electrónico del destinatario no pudo o no quiso aceptar el mensaje, y no se harán más intentos de entregar el mensaje."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "OPEN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El destinatario abrió el mensaje."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CLICK"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El destinatario hizo clic en un enlace dentro del mensaje."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "STATUSCHANGE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El destinatario cambió sus suscripciones de correo de alguna manera."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SPAMREPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El destinatario marcó el mensaje como spam."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SUPPRESSION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El mensaje no se ha enviado, el destinatario no ha participado en los correos de marketing anteriores."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " algunos tipos de eventos, como los eventos de bots, pueden incluirse\nen la respuesta a la API ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/events"
        }), " pero no aparecerá cuando estés analizando\nla ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "https://knowledge.hubspot.com/email/analyze-your-marketing-email-campaign-performance",
          children: [(0, _jsxRuntime.jsx)(_components.em, {
            children: "pestaña Destinatarios"
          }), " para un correo de marketing en tu Cuenta de\nHubSpot"]
        }), ",\nque filtra determinados tipos de eventos sin procesar y limita el total de\neventos mostrados a 30 eventos por tipo de evento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eventos de envío"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando HubSpot crea y envía un mensaje de correo electrónico en nombre de un cliente, primero verificamos si el destinatario cumple los requisitos para recibirlo. Si no, rechazamos el mensaje, desencadenando la creación de un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), ". De lo contrario, lo enviamos a nuestro proveedor de entregas para que lo siga gestionando, desencadenando un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), ". Un mensaje de correo electrónico casi siempre tendrá asociado exactamente un evento de envío; por ejemplo, nunca habrá varios eventos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " para un mensaje."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hacemos todo lo posible por rechazar los mensajes antes de pasarlos a nuestro proveedor de entrega. Sin embargo, a veces nuestro proveedor de entrega decide rechazar un mensaje incluso después de que hayamos verificado su elegibilidad. Este rechazo posterior provoca la creación de un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), ", además del evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " creado anteriormente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Todos los eventos de envío comparten las siguientes propiedades:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "asunto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El asunto del mensaje de correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "de"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El campo \"de\" del mensaje de correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "replyTo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lista de cadenas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Campo \"responder a\" del mensaje de correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "cc"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lista de cadenas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El campo \"cc\" del mensaje de correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "bcc"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "lista de cadenas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El campo \"bcc\" del mensaje de correo electrónico."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además, los eventos de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " tienen las siguientes propiedades:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "dropReason"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena (enumeración)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El motivo por el que se eliminó el mensaje de correo. Consulta a continuación los posibles valores."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "dropMessage"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El mensaje sin procesar que describe por qué se eliminó el mensaje de correo. Esto suele proporcionar detalles adicionales más allá de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropReason'"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Motivos de la pérdida"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Motivo de la pérdida"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_BOUNCED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un mensaje anterior al destinatario resultó en un rebote."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUS_SPAM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un mensaje anterior al destinatario fue marcado como spam."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_UNSUBSCRIBED_MESSAGE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El destinatario previamente canceló esta suscripción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_UNSUBSCRIBED_PORTAL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El destinatario se dio de baja previamente de todas las suscripciones de la cuenta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "INVALID_TO_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La dirección de correo del campo \"Para\" no pasó la validación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "INVALID_FROM_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La dirección de correo en el campo \"De\" no pasó la validación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BLOCKED_DOMAIN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El dominio del destinatario estaba bloqueado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BLOCKED_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El destinatario pidió explícitamente no recibir e-mails a través de HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "EMAIL_UNCONFIRMED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se activó la confirmación doble y el receptor aún no había confirmado su suscripción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CAMPAIGN_CANCELLED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La campaña de correo electrónico asociada fue cancelada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MTA_IGNORE"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Nuestro proveedor de entrega decidió abandonar el mensaje. Cualquiera de los detalles adicionales se incluirá en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropMessage'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PORTAL_OVER_LIMIT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tu cuenta ha superado el límite mensual de envíos de correo electrónico."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PORTAL_SUSPENDED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tu cuenta ha sido suspendida por incumplimiento o problemas de capacidad de entrega."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "QUARANTINED_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El destinatario ha sido puesto en cuarentena debido a repetidos rebotes duros."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ADDRESS_LIST_BOMBED"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" El destinatario ha sido puesto en cuarentena debido a una actividad sospechosa en los formularios. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/email/remediate-list-bombing",
              children: "Más información"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eventos de entrega"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que nuestro proveedor de entrega ha aceptado un mensaje de correo electrónico, creamos un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROCESSED"
      }), ". En este punto, el proveedor de la entrega ha puesto el mensaje en cola para su entrega. Si todo va bien, el proveedor de entrega pondrá el mensaje en cola y lo entregará al servidor de correo electrónico del destinatario, generando un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELIVERED"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "De vez en cuando, las cosas no salen bien y ocurre una de estas dos cosas: la entrega se aplaza debido a un rechazo temporal, o la entrega falla y no se vuelve a intentar."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el primer caso, el mensaje no pudo entregarse al servidor de correo electrónico del destinatario por algún motivo no fatal (normalmente transitorio, como un tiempo de espera falso). El proveedor de la entrega volverá a poner en cola el mensaje para entregarlo más tarde, y creamos un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DEFERRED"
      }), ". Un mensaje puede aplazarse varias veces antes de completar la fase de entrega, con un nuevo evento creado en cada intento."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si la entrega falla, no se harán más intentos de entregar el mensaje, y crearemos un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), ". Esto puede ocurrir por diversos motivos, como que el destinatario sea desconocido por el servidor de correo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los tipos de eventos de entrega específicos tienen las siguientes propiedades:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Entregado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "respuesta"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La respuesta completa del servidor de correo electrónico del destinatario."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "smtpId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un ID adjuntado al mensaje por HubSpot."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Diferidos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "respuesta"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La respuesta completa del servidor de correo electrónico del destinatario."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "intento"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entero de 32 bits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número de intento de entrega."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Rebote"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "categoría"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena (enumeración)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La mejor suposición del tipo de rebote encontrado. Si no se ha podido determinar una categoría adecuada, se omite esta propiedad. Consulta a continuación los posibles valores. Ten en cuenta que se trata de un valor derivado y que puede modificarse en cualquiera de los casos para mejorar la exactitud de la clasificación."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "respuesta"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La respuesta completa del servidor de correo electrónico del destinatario."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "estado"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El código de estado devuelto por el servidor de correo electrónico del destinatario."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Categorías de rebote"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Categoría de rebote"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UNKNOWN_USER"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El destinatario no existía."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El buzón del destinatario estaba lleno y no podía recibir mensajes. | CONTENT | Los filtros del destinatario identificaron el contenido del cuerpo del correo electrónico como sospechoso o spam. | | SPAM | El mensaje fue marcado como spam. | | POLICY | El mensaje fue marcado como spam. | | GREYLISTING | El servidor de correo electrónico requiere un historial más largo de la actividad de correo electrónico del remitente. | | MAILBOX_MISCONFIGURATION | Se ha detectado una configuración incorrecta del buzón. | Se detectó un error de configuración del ISP. | DOMAIN_REPUTATION | El dominio de envío tiene una mala reputación o una reputación que no cumple los estándares del servidor del destinatario. | | DMARC | El dominio del remitente no pasa una marca DMARC. Revisa tus políticas SPF y DMARC. | | SENDING_DOMAIN_MISCONFIGURATION | Falló la autenticación de dominio debido a una política en el lado del destinatario. | | TIMEOUT | El servidor de correo electrónico receptor ha agotado el tiempo de espera y ya no acepta más correos. | | THROTTLED | El servidor de correo electrónico del destinatario estaba regulando el procesamiento de los mensajes. | | UNCATEGORIZED | Se ha detectado un error no categorizado. | El mensaje se originó a partir de una dirección IP sospechosa (o previamente desconocida). | DNS_FAILURE | La configuración del servidor de nombres de dominio del destinatario estaba mal configurada en el momento en que se envió el correo electrónico. | Ha ocurrido algún problema temporal."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eventos de interacción con usuarios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que un mensaje de correo electrónico llega a su destinatario, pueden producirse cuatro tipos de eventos diferentes: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PRINT"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORWARD"
      }), ". Representan la interacción del destinatario con el mensaje y su contenido, y cada una de ellas puede ocurrir varias veces. Por ejemplo, cada vez que se hace clic en cualquier URL, se crea un nuevo evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), ", aunque ya se haya hecho clic anteriormente en esa URL y se haya generado un evento de este tipo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Todos los eventos de interacción con el usuario comparten las siguientes propiedades:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "userAgent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El agente del usuario responsable del evento, por ejemplo. \"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_4) AppleWebKit/537.36 (KHTML, como Gecko) Chrome/28.0.1500.95 Safari/537.36”"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "navegador"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un objeto JSON que representa al navegador que atendió el evento. Se compone de las propiedades: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'name'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'family'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'producer'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'producer_url'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'type'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'url'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'version'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ubicación"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un objeto JSON que representa la ubicación donde se produjo el evento. Está compuesto por las propiedades: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'city'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'state'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'country'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "filteredEvent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un booleano que representa si el evento se ha filtrado de los informes basándose en la configuración de los informes de cliente o no."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además, los eventos de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), " tienen las siguientes propiedades:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "url"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La URL dentro del mensaje en la que hizo clic el destinatario."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "referente"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La URL de la página web que enlazaba con la URL en la que se ha hecho clic. Si se proporciona o no, y qué valor se le asigna, lo determina el cliente de correo electrónico del destinatario."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Y los eventos de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), " pueden tener la siguiente propiedad:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "duración"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Entero de 64 bits (milisegundos)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Si se proporciona y es distinto de cero, el número aproximado de milisegundos que el usuario había abierto el correo electrónico."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eventos de estado del usuario"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El destinatario también puede actualizar sus preferencias de comunicación a través del mensaje de correo. Al hacer clic en el enlace de preferencias de suscripción del mensaje, pueden cambiar sus suscripciones, suscribiéndose o dándose de baja de varias listas, desencadenando un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STATUSCHANGE"
      }), ". Ten en cuenta que un cambio de estado puede ser para cualquiera de las listas(s), no sólo para la que está asociada al mensaje de correo actual."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un mensaje de correo electrónico también puede ser marcado como spam por el destinatario, lo que da lugar a un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SPAMREPORT"
      }), ". Ten en cuenta que esto es independiente del estado de la suscripción: marcar un mensaje como spam no anula simplemente la suscripción del destinatario a la lista en cuestión. Por el contrario, el estado de la suscripción no se modifica y se establece un indicador que señala que el destinatario no debe recibir nunca más mensajes de correo electrónico de HubSpot. Una vez que esto ocurra, necesitarás la intervención manual de HubSpot para eliminar el indicador."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un evento de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STATUSCHANGE"
      }), " tiene las siguientes propiedades adicionales:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "fuente"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena (enumeración)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fuente del cambio de suscripción. Consulta a continuación los posibles valores."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "requestedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La dirección de correo electrónico de la persona que solicita el cambio en nombre del destinatario. Si no procede, se omite esta propiedad."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "portalSubscriptionStatus"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["cadena (enumeración, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'SUBSCRIBED'"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ")"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Estado de suscripción al portal del destinatario. (Ten en cuenta que si se trata de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ", la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'subscriptions'"
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "no"
            }), " es necesariamente una matriz vacía, ni todas las suscripciones contenidas en ella van a tener necesariamente sus estados establecidos en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ")"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "suscripciones"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una matriz de objetos JSON que representan el estado de las suscripciones para el destinatario. Cada objeto de suscripción JSON se compone de las propiedades: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'id'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'status'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "rebotado"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["booleano (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", u omitido por completo)"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un empleado de HubSpot inició explícitamente el cambio de estado para bloquear los mensajes al destinatario. (Ten en cuenta que este uso ha quedado obsoleto en favor de la eliminación de mensajes con un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropReason'"
            }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOCKED\\_ADDRESS"
            }), ".)"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Fuentes de cambio de suscripción"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Fuente"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_LIST_UNSUBSCRIBE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El destinatario utilizó un encabezamiento de lista de cancelación de suscripción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_RECIPIENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El destinatario utilizó la IU de suscripción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_IMPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El cliente importó las suscripciones a su portal."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT_CUSTOMER"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El cliente utilizó la IU de suscripción."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_SPAM_REPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se recibió un informe de spam generado por un sistema automatizado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_NON_DELIVERY_REPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se recibió un informe de no entrega (normalmente un rebote)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_DIRECT_COMPLAINT"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Se recibió una queja directa a través de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "abuse@hubspot.com"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_MTA_RECORD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nuestro proveedor de entrega proporcionó el cambio, durante nuestra sincronización normal con su sistema de registros."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un empleado de HubSpot realizó el cambio."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_MIGRATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpot migró las suscripciones de una versión anterior del producto."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT_CLEANUP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpot limpió las suscripciones."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_KNOWN_SPAM_TRAP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esta dirección de destinatario es una trampa de spam conocida, y no debería recibir correos electrónicos."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eventos 'Eliminar rebote'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsxs)(_components.em, {
        children: ["Hay un decimotercer tipo de evento, que no está relacionado con un mensaje de correo electrónico específico. Los eventos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "UNBOUNCE"
        }), " se producen cuando una dirección de correo electrónico específica es anunciada automática o manualmente por HubSpot en"]
      }), ". Esto restablece el estado de rebote del destinatario, permitiéndole potencialmente recibir correos electrónicos de tu portal."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de la propiedad"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "usuario"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La dirección de correo electrónico del usuario que envió la solicitud de rebote."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Referencias de eventos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Muchos eventos están relacionados con otros eventos que ocurrieron antes o después de él. Como se describe en la primera sección, utilizamos EventIds para construir esta cadena de referencia."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ten en cuenta que las referencias a eventos son relativamente nuevas, y es posible que los eventos más antiguos no las tengan rellenas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'sentBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como ya se ha dicho, cada mensaje de correo tiene asociado un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " (o uno de cada uno). Éste será el primer evento generado para cualquiera de los mensajes. Si un mensaje genera un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), ", todos los eventos generados posteriormente harán referencia a ese evento a través de la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'sentBy'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esta referencia hacia atrás puede ser útil para obtener más información sobre el evento padre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), ", o para encontrar manualmente todos los eventos asociados a un mensaje determinado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'obsoletedBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A veces, se produce un evento de seguimiento para un mensaje determinado, lo que significa que debe ignorarse un evento anterior. Esta relación se captura en una referencia hacia adelante en la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, en el caso de que generemos tanto un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " como un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " posterior, el evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " es en última instancia irrelevante, y queda ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "obsoleto por"
      }), " el evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), ". En consecuencia, el evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " hará referencia al evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " a través de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'causedBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ciertos eventos ocurren precisamente a causa de algún evento anterior, a menudo por un mensaje diferente. Esta relación se recoge en una referencia hacia atrás en la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), ". Puede utilizarse para obtener detalles adicionales sobre por qué un evento concreto causó el siguiente evento."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, se producirá un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " cuando haya habido un evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), " anterior para el mismo destinatario. En este caso, el evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " tendrá su ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'dropReason'"
      }), " establecido en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PREVIOUSLY\\_BOUNCED"
      }), ", y su ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), " hará referencia a ese evento ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), " anterior."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}