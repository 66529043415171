"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 51199955888;
const slug = exports.slug = 'getting-started/account-types';
const title = exports.title = 'Types de comptes HubSpot';
const name = exports.name = 'Types de comptes HubSpot';
const metaDescription = exports.metaDescription = 'Découvrez les différents types de comptes HubSpot et ce à quoi chacun sert.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "comptes-hubspot-standards",
  "label": "Comptes HubSpot standards",
  "parent": null
}, {
  "depth": 0,
  "id": "comptes-de-d%C3%A9veloppeurs-d-applications",
  "label": "Comptes de développeurs d'applications",
  "parent": null
}, {
  "depth": 0,
  "id": "compte-de-test-d%C3%A9veloppeur",
  "label": "Compte de test développeur",
  "parent": null
}, {
  "depth": 1,
  "id": "cr%C3%A9er-un-compte-de-test-pour-d%C3%A9veloppeur",
  "label": "Créer un compte de test pour développeur",
  "parent": "compte-de-test-d%C3%A9veloppeur"
}, {
  "depth": 1,
  "id": "renouveler-un-compte-de-test-de-d%C3%A9veloppeur",
  "label": "Renouveler un compte de test de développeur",
  "parent": "compte-de-test-d%C3%A9veloppeur"
}, {
  "depth": 0,
  "id": "comptes-sandbox",
  "label": "Comptes sandbox",
  "parent": null
}, {
  "depth": 1,
  "id": "comptes-sandbox-standard",
  "label": "Comptes sandbox standard",
  "parent": "comptes-sandbox"
}, {
  "depth": 1,
  "id": "comptes-sandbox-cms",
  "label": "Comptes sandbox CMS",
  "parent": "comptes-sandbox"
}, {
  "depth": 1,
  "id": "comptes-sandbox-de-d%C3%A9veloppement-b%C3%AAta",
  "label": "Comptes sandbox de développement (BÊTA)",
  "parent": "comptes-sandbox"
}, {
  "depth": 0,
  "id": "comptes-fournisseurs-du-marketplace",
  "label": "Comptes fournisseurs du marketplace",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      em: "em",
      img: "img",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Types de comptes HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe plusieurs types de comptes HubSpot, chacun ayant un objectif distinct. Ci-dessous, découvrez chaque type de compte et à quoi il est destiné."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comptes HubSpot standards"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les comptes HubSpot standards représentent le type de compte le plus courant. C'est là que vous trouverez tous les outils, fonctionnalités et paramètres inclus dans votre abonnement HubSpot. Il peut être gratuit ou payant, et fait office d'environnement de production."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un compte HubSpot standard aura accès à tous les outils et fonctionnalités inclus dans votre abonnement."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comptes de développeurs d'applications"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les comptes gratuits et de développeurs d'applications sont destinés à la création et à la gestion des applications, des intégrations et des comptes de test de développeurs. C'est également là que vous pouvez créer et gérer des listings du marketplace des applications. Cependant, les comptes de développeurs d'applications et les comptes de test associés ne sont pas connectés à un compte HubSpot standard. Ils ne peuvent pas synchroniser les données ou les ressources vers ou à partir d'un autre compte HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les comptes de développeurs d'applications se distinguent par une bannière située en haut de n'importe quelle page, qui indique ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ceci est"
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "un compte de développeur"
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "d'applications"
      }), ". ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://paper-attachments.dropbox.com/s_FE997E185BD47B083EA9B7C65FEC2822D18406AA9C2CBDB7FA3CA77217D16F19_1626814303990_App+dev+account+info+alert+option+3.png",
        alt: ""
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Commencez par créer un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "compte de développeur d'applications"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Compte de test développeur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#app-developer-accounts",
        children: "comptes de développeurs d'applications"
      }), ", vous pouvez créer des comptes de test de développeurs pour tester des applications et des intégrations sans affecter les données réelles de HubSpot. Les comptes de test de développeur ne reflètent pas les comptes de production, mais sont plutôt des comptes HubSpot gratuits avec accès à un essai de 90 jours de nombreuses fonctionnalités d'entreprise, avec les limitations suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hub :"
        }), " vous pouvez envoyer des e-mails marketing uniquement aux adresses des utilisateurs que vous avez ajoutés à votre compte de test de développeur."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub :"
        }), " le nombre de pages que vous pouvez créer est soumis aux limites suivantes :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Pages de site web"
            }), " : 25"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Outils de blog"
            }), " : 1 blog comportant un maximum de 100 articles"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Pages de destination"
            }), " : 25"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Workflows"
        }), " : un maximum de 100 000 fiches d'informations peuvent être inscrites chaque jour dans des workflows créés dans un compte de test de développeur. Si cette limite quotidienne est atteinte :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Toute tentative d'inscription supplémentaire au-delà de cette limite sera annulée."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Les utilisateurs seront informés dans l'application qu'ils ont atteint la limite d'inscription quotidienne, ainsi que l'heure à laquelle la limite sera actualisée."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez créer jusqu'à 10 comptes de test par compte de développeur. Ce type de compte ne peut pas synchroniser les données avec d'autres comptes, et ils ne peuvent pas être connectés à un compte HubSpot standard. Les comptes de test se distinguent par une bannière en haut de la page, qui indique le nombre de jours restants avant son expiration."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/trial-banner.png",
        alt: "trial-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous comment créer et gérer des comptes de test."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer un compte de test pour développeur"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour créer un compte de test de développeur :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la barre latérale de navigation de gauche de votre compte de développeur d’applications, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Comptes de test"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/left-sidebar-test-accounts.png",
            alt: "left-sidebar-test-accounts"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Créer un compte de test d'applications"
          }), " dans l'angle supérieur droit."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-test-account-create.png",
            alt: "developer-test-account-create"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Saisissez un ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nom de compte"
          }), ", puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Créer"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour accéder à vos comptes de test de développeur et les gérer :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la barre de navigation supérieure de votre compte de développeur d'applis, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Test"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Passez le curseur sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "compte"
        }), " que vous souhaitez gérer, puis sélectionnez une action :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Pour supprimer le compte, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Supprimer"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Pour copier l'ID du compte, le renouveler ou le renommer, cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Plus"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-account-test-accounts.png",
        alt: "developer-account-test-accounts"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Renouveler un compte de test de développeur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les comptes de test de développeur expireront au bout de 90 jours si aucun appel d'API n'est effectué sur le compte. Vous pouvez soit renouveler manuellement le compte à partir de la page ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Test"
      }), " dans HubSpot, soit effectuer un appel d'API vers le compte. Gardez à l'esprit ce qui suit si vous souhaitez renouveler le compte via un appel d'API :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cela s'applique uniquement aux demandes d'API effectuées à l'aide des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "jetons OAuth"
        }), " générés par une application dans le même compte de développeur que le compte de test que vous souhaitez renouveler."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les renouvellements doivent être effectués au maximum 30 jours avant la date d'expiration du compte de test."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comptes sandbox"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les comptes de sandbox vous permettent de tester vos modifications sans affecter votre compte standard. Découvrez-en davantage sur les différents types de comptes de sandbox dans les sections ci-dessous."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comptes sandbox standard"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous disposez d'un abonnement ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), ", vous pouvez créer des comptes de sandbox qui font office d'environnements sûrs et sécurisés où vous pouvez tester de nouveaux workflows, intégrations, pages web et autres changements importants sans avoir d'impact sur votre compte standard. Ces environnements de test copient la structure de votre compte standard."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ces comptes peuvent être identifiés par une bannière jaune en haut de la page qui inclut le libellé : ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "vous êtes dans [nom du sandbox], qui est un compte sandbox standard"
      }), ". La bannière comprend également un lien vers votre compte de production pour y passer plus facilement."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/standard-sandbox-banner.png",
        alt: "standard-sandbox-banner"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur les comptes d'environnement de test standard dans la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/set-up-a-hubspot-standard-sandbox-account",
        children: "base de connaissances de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comptes sandbox CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les environnements de test CMS et les comptes gratuits sont destinés à la création et au test de modifications de sites web sans impacter votre compte standard ni le site web en ligne. Tout comme les comptes développeurs d'applications, les comptes sandbox CMS ne sont ", (0, _jsxRuntime.jsx)("u", {
        children: "pas"
      }), " connectés à votre compte HubSpot standard."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "créer gratuitement un compte d'environnement de test CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les sandbox CMS n'ont pas de bannière, mais ils n'ont accès qu'aux outils gratuits de HubSpot et à ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), " (et ne peuvent pas se connecter à un domaine)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comptes sandbox de développement (BÊTA)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous disposez d'un abonnement ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sales Hub"
        })
      }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Service Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Entreprise"
      }), ", vous pouvez créer un compte sandbox de développement via l'ILC à des fins de développement local. Vous pouvez accéder aux comptes sandbox des développeurs en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/opt-your-hubspot-account-into-a-beta-feature",
        children: "optant pour la version bêta des outils de développement CRM dans votre compte HubSpot standard."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les sandbox de développement peuvent être identifiés par une bannière jaune en haut de la page qui inclut le libellé : ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "vous êtes dans [nom de sandbox], qui est un compte sandbox de développement"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/development-sandboxes-banner.png",
        alt: "development-sandboxes-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comptes fournisseurs du marketplace"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les comptes fournisseurs du marketplace sont destinés à la création et à la gestion des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "listes et des transactions du marketplace des modèles"
      }), ". Pour commencer à vendre sur le marketplace de modèles, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "créez un compte fournisseur de marketplace de modèles"
      }), ". Si vous êtes un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners",
        children: "partenaire HubSpot"
      }), ", vous disposez déjà de la fonctionnalité de fournisseur de marketplace dans votre compte partenaire."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un compte fournisseur de marketplace se distingue par un élément ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Marketplace"
      }), " des ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "ressources"
      }), " dans le menu de navigation supérieur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/template-marketplace-acct.png",
        alt: "template-marketplace-acct"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}