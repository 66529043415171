"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160601074021;
const slug = exports.slug = 'getting-started/overview';
const title = exports.title = 'Visão geral de primeiros passos';
const name = exports.name = 'Visão geral de primeiros passos (documentos do desenvolvedor 2024)';
const metaDescription = exports.metaDescription = 'Saiba como começar a desenvolver no HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "as-vantagens-de-uma-plataforma-conectada",
  "label": "As vantagens de uma plataforma conectada",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-instale-ferramentas-para-ajud%C3%A1-lo-a-criar",
  "label": "1. Instale ferramentas para ajudá-lo a criar",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-decida-o-que-criar",
  "label": "2. Decida o que criar",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-junte-se-aos-seus-colegas",
  "label": "3. Junte-se aos seus colegas",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-saiba-mais%2C-do-seu-jeito",
  "label": "4. Saiba mais, do seu jeito",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral de primeiros passos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você é um desenvolvedor novo no HubSpot ou busca expandir suas habilidades, esta seção mostrará como começar. Você explorará as diversas opções de desenvolvimento disponíveis, assim como as ferramentas específicas e contas necessárias para atingir seus objetivos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "As vantagens de uma plataforma conectada"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Primeiramente, é importante entender o que torna o HubSpot uma plataforma poderosa para desenvolvedores."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A base de cada conta do HubSpot é a plataforma de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "CRM"
      }), " (gerenciamento de relacionamento com o cliente), um banco de dados de relacionamentos e processos comerciais. O HubSpot oferece diferentes formas para desenvolvedores externos e parceiros trabalharem com o CRM, permitindo criar valor adicional para os usuários finais. Isso inclui:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Criação de integrações e/ou extensões com as APIs da HubSpot para sincronizar dados entre o HubSpot e plataformas externas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Criação de sites personalizados usando o CMS (sistema de gerenciamento de conteúdo) da HubSpot. Além de um conjunto completo de ferramentas de gerenciamento de conteúdo, o CMS integra-se totalmente ao CRM. Isso facilita a gestão de lançamentos de campanhas, capturas de leads e comunicações contínuas com os clientes."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Personalização do CRM com extensões de UI. Os desenvolvedores podem usar o React para criar cartões personalizados flexíveis que se integram perfeitamente ao HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Na próxima seção, você terá uma visão geral das ferramentas disponíveis para desenvolvedores do HubSpot. Em seguida, você escolherá um caminho para continuar sua jornada como desenvolvedor do HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Instale ferramentas para ajudá-lo a criar"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há uma ampla gama de ferramentas disponíveis para os desenvolvedores do HubSpot. A combinação de ferramentas necessárias dependerá de seus objetivos específicos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CLI da HubSpot:"
        }), " instalar a CLI da HubSpot para desenvolvimento local é um excelente ponto de partida para muitas tarefas de desenvolvimento. A CLI é usada ao criar extensões de UI e ativos do CMS e pode ser usada para criar e gerenciar segredos, tabelas HubDB e dados de objetos personalizados da sua conta. Você precisará ter o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: (0, _jsxRuntime.jsx)("u", {
            children: "Node.js"
          })
        }), " instalado (é necessário ter a versão 18 ou superior e a versão LTS é recomendada). Para instalar a CLI globalmente, execute o seguinte comando:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "npm i -g @hubspot/cli\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Visual Studio Code:"
          }), " instalar o ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://code.visualstudio.com/",
            children: "Visual Studio Code"
          }), " significa que você pode aproveitar a ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
            children: "Extensão do Visual Studio Code da HubSpot"
          }), ", o que permite centralizar seu fluxo de trabalho de desenvolvimento."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Bibliotecas de cliente"
          }), ": o HubSpot fornece bibliotecas de clientes para ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-nodejs",
            children: "Node"
          }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-php",
            children: "PHP"
          }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-ruby",
            children: "Ruby"
          }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/hubspot-api-python",
            children: "Python"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Postman:"
          }), " use ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/",
            children: "Postman"
          }), " para acessar as ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://www.postman.com/hubspot",
            children: "Coleções Postman da HubSpot"
          }), ". Em toda a documentação da API da HubSpot, você encontrará botões para executar chamadas usando a coleção."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/run-in-postman-current-docs-site.png",
            alt: "run-in-postman-current-docs-site"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/tools-to-help-you-build",
        children: "ferramentas para desenvolvedores da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Decida o que criar"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Não existe um caminho único para desenvolver com o HubSpot. Trata-se de uma plataforma flexível que pode atender a uma ampla gama de necessidades."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma das quatro grandes categorias listadas abaixo deverá abordar a maioria dessas necessidades. Escolha a mais relevante para você agora e veja uma introdução rápida, juntamente com alguns passos iniciais para começar. Descubra também quais são as melhores ferramentas para o trabalho e se é necessário um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "tipo específico de conta da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#use-apis-and-build-integrations",
          children: "Usar APIs e criar integrações"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#build-for-the-app-marketplace",
          children: "Crie aplicativos para o marketplace"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#customize-the-hubspot-ui",
          children: "Personalizar a interface do HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/what-to-build#build-custom-websites",
          children: "Criar sites personalizados"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Junte-se aos seus colegas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Conecte-se com seus colegas que já estão desenvolvendo no HubSpot. Faça perguntas, compartilhe conhecimentos, construa uma comunidade e, quem sabe, até faça alguns amigos pelo caminho."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/slack/developer-slack",
          children: "Slack de desenvolvedores"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
          children: "Fóruns de desenvolvedores"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/hubspotdev",
          children: "GitHub de promoção de desenvolvedores"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Saiba mais, do seu jeito"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando estiver pronto, há muitos recursos disponíveis para ajudar você a explorar mais a fundo. Você pode acessar guias aprofundados, documentos de referência detalhados ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/courses",
        children: "cursos do HubSpot Academy"
      }), ", dependendo do seu estilo de aprendizagem preferido."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}