"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32390990539;
const slug = exports.slug = 'guides/cms/content/data-driven-content/crm-objects';
const title = exports.title = 'CRM objects in CMS pages';
const name = exports.name = 'CRM objects in CMS pages';
const metaDescription = exports.metaDescription = 'CRM objects can be queried and rendered on HubSpot hosted content, allowing data to be shared between your business operations, website, and emails.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "supported-crm-object-types",
  "label": "Supported CRM object types",
  "parent": null
}, {
  "depth": 1,
  "id": "crm-object-data-available-for-all-pages",
  "label": "CRM object data available for all pages",
  "parent": "supported-crm-object-types"
}, {
  "depth": 1,
  "id": "crm-object-data-available-for-private-pages",
  "label": "CRM object data available for private pages",
  "parent": "supported-crm-object-types"
}, {
  "depth": 0,
  "id": "display-data-from-a-single-crm-record-with-the-crm_object-function",
  "label": "Display data from a single CRM record with the crm_object function",
  "parent": null
}, {
  "depth": 0,
  "id": "display-data-from-multiple-crm-records-with-the-crm_objects-function",
  "label": "Display data from multiple CRM records with the crm_objects function",
  "parent": null
}, {
  "depth": 0,
  "id": "display-associated-records",
  "label": "Display associated records",
  "parent": null
}, {
  "depth": 0,
  "id": "getting-a-custom-object-type-s-details",
  "label": "Getting a custom object type's details",
  "parent": null
}, {
  "depth": 0,
  "id": "crm-object-module-field",
  "label": "CRM Object Module field",
  "parent": null
}, {
  "depth": 0,
  "id": "crm-object-tutorials-and-resources",
  "label": "CRM Object tutorials and resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h3: "h3",
      em: "em",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      pre: "pre",
      img: "img",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Use CRM object data in CMS pages"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can query CRM objects to use data from the object's records on HubSpot hosted content, allowing data to be shared between your business operations, website, and emails. Using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects"
        })
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        })
      }), " HubL functions, you can display and control logic based on your CRM object data."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using CRM data on your website means that your sales, marketing, and website data all live in the same place and will always reflect the same information. In addition, because you can associate CRM records with one another, you can also pull in associated data onto your website pages."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Similarly, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "create sets of dynamic pages that automatically generate using CRM object or HubDB data"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["You can learn more about building data-based CMS pages in HubSpot Academy's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "CMS Data-Driven Content course"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Example use case"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["One example of using CRM object data in pages is a real estate listing page. With a custom object called ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "property"
      }), ", individual object records can be created for each house that needs to be listed. Real estate agents can then add information to object properties to store details, such as location, number of bedrooms, and asking prices."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Website pages can then pull in that record data for each property to create a listing page and details pages for each property."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-custom-objects-example",
        children: "Check out the GitHub repo"
      }), " to view the full example."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For an overview of this example, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/developer-day-2020",
        children: "recording of HubSpot Developer Day 2020"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supported CRM object types"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are the types of CRM objects that you can pull data from for your CMS pages. Whether you can use the data across all pages or only on private pages depends on the object type."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the tables below, learn about which object types are available for CMS content along with their object type names and fully qualified names."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CRM object data available for all pages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Data from the following CRM objects can be used on any CMS page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Object type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "object_type_name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Fully qualified name"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "Products"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PRODUCT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "Marketing events"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "marketing_event"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/custom-objects",
              children: "Custom objects"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Content Hub"
              }), " Enterprise"]
            }), " only. You can either use the object's ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "fully qualified name"
            }), ", or the name that was entered at the time of creation. For example, if you create an object named ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Cars"
            }), ", you cannot reference it with ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cars"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Car"
            }), ".You must use the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/crm-objects#getting-a-custom-object-type-s-details",
              children: "fully qualified name"
            }), " if the custom object shares a name with a standard object. It's also strongly recommended to use the FQN if the object name is fully uppercased to avoid potential conflicts with HubSpot's standard objects."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CRM object data available for private pages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Data from the following CRM objects can be used only on pages that require either a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/password-protect-a-page",
        children: "password"
      }), " or a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/require-member-registration-to-access-private-content",
        children: "membership login."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Object type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "object_type_name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Fully qualified name"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "Contacts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "Companies"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMPANY"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/deals",
              children: "Deals"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DEAL"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/tickets",
              children: "Tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TICKET"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/quotes",
              children: "Quotes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quote"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "QUOTE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/line-items",
              children: "Line items"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_item"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LINE_ITEM"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Display data from a single CRM record with the crm_object function"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), " function to get a single record from the HubSpot CRM by query or by CRM record ID."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Object records are returned as a dict of properties and values."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# Render custom object by query #}\n{% set event = crm_object(\"event\", \"name=Defensive Health\") %}\n{{ event.name }}\n\n{# Render custom objects specifying the id of the object #}\n{% set event = crm_object(\"event\", 289236) %}\n{{ event.name }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>Defensive Heatlh</p>\n\n<p>Defensive Heatlh</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "If a query returns a collection of records, the function will return the first record in the collection."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Display data from multiple CRM records with the crm_objects function"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects()"
        })
      }), " function to get CRM records by object type from the HubSpot CRM by query or by record ID. Records are returned as a dict of properties and values."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The record returned contains a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), " property that can be looped through to display the information in the record's items."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# Render custom objects by query #}\n{% set events = crm_objects(\"event\", \"limit=3&type=virtual\") %}\n<h3>{{events.total}} New Events:<h3>\n<ul>\n{% for event in events.results %}\n\t<li>Name: {{ event.name }}</li>\n{% endfor %}\n<ul>\n\n{# Render custom objects by ids #}\n{% set events = crm_objects(\"event\", [289236,289237,289238]) %}\n<h3>{{events.total}} New Events:<h3>\n<ul>\n{% for event in events.results %}\n\t<li>Name: {{ event.name }}</li>\n{% endfor %}\n<ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>\n  3 New Events:\n  <h3>\n    <ul>\n      <li>Name: Defensive Health</li>\n      <li>Name: Body Balance</li>\n      <li>Name: Happy Heart</li>\n      <ul>\n        <h3>\n          3 New Events:\n          <h3>\n            <ul>\n              <li>Name: Defensive Health</li>\n              <li>Name: Body Balance</li>\n              <li>Name: Happy Heart</li>\n              <ul></ul>\n            </ul>\n          </h3>\n        </h3>\n      </ul>\n    </ul>\n  </h3>\n</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Display associated records"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        })
      }), " HubL function to get a list of associated records from the HubSpot CRM based on the given record ID, association category, and association definition ID.", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#getting-a-custom-object-s-details"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Records are returned as a dict of properties and values."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set associated_objects = crm_associations(289236, \"USER_DEFINED\", 3) %}\n<h3>Contacts Associated With Event</h3>\n<ul>\n{% for contact in associated_objects.results %}\n\t<li>Name: {{ contact.firstname }} {{ contact.lastname }}</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>\n  Contacts Associated With Event\n  <h3>\n    <ul>\n      <li>Name: Brian Halligan</li>\n\n      <li>Name: Dharmesh Shah</li>\n\n      <li>Name: Yamini Rangan</li>\n    </ul>\n  </h3>\n</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Getting a custom object type's details"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get a custom object type's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fullyQualifiedName"
      }), ", association IDs, and other details, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/crm/objects/custom-objects",
        children: "CRM Objects schema API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fullyQualifiedName"
        }), " for account-specific object types includes the HubSpot account ID, so it's recommended to avoid using it when developing your code for multiple HubSpot accounts."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRM Object Module field"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To provide a way for content creators to select CRM records to display or execute logic, you can build modules that include the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/en/docs/cms/building-blocks/module-theme-fields#crm-object",
        children: "CRM object field"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, you may want to display information from a specific product, contact, company, deal, quote, ticket, or custom object."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/CRM%20Object%20Field.png",
        alt: "CRM Object Field"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRM Object tutorials and resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/essentials-for-getting-started-with-custom-objects",
          children: "Essentials of getting started with Custom Objects"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-to-think-like-an-architect-by-building-scalable-custom-objects",
          children: "Think like an architect: Build scalable Custom Objects"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/crm-objects",
          children: "Build Dynamic Pages with CRM Objects"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}