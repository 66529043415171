"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 49334172328;
const slug = exports.slug = 'guides/cms/content/fields/best-practices';
const title = exports.title = 'モジュールとテーマのフィールドに関するベストプラクティス';
const name = exports.name = 'モジュールとテーマのフィールドに関するベストプラクティス';
const metaDescription = exports.metaDescription = 'フィールドの作成とグループ化が、ユーザーエクスペリエンス（UX）の問題を招く恐れがあります。制作担当者に直感的な操作性を提供するために、このページの情報をガイドとしてご活用ください。';
const featuredImage = exports.featuredImage = 'https://www.hubspot.com/hubfs/options%20and%20style%20tabs.png';
const featuredImageAltText = exports.featuredImageAltText = 'CMS Hubページエディターでのモジュールのスタイルフィールドのタブ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%B9%E3%82%BF%E3%82%A4%E3%83%AB%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%A8%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E9%81%95%E3%81%84",
  "label": "スタイルフィールドとコンテンツフィールドの違い",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%92%E7%B7%A8%E6%88%90%E3%81%99%E3%82%8B",
  "label": "フィールドを編成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E4%BE%8B",
  "label": "例",
  "parent": "%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%82%92%E7%B7%A8%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E5%BF%85%E9%A0%88%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89",
  "label": "必須フィールド",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%BF%E3%82%A4%E3%83%9D%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%BC",
  "label": "タイポグラフィー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%96%E3%83%BC%E3%83%AB%E5%9E%8B%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AE%E5%88%87%E3%82%8A%E6%9B%BF%E3%81%88%E3%82%B9%E3%82%A4%E3%83%83%E3%83%81%E3%81%A8%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9",
  "label": "ブール型フィールドの切り替えスイッチとチェックボックス",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E8%A8%98%E4%BA%8B",
  "label": "関連記事",
  "parent": null
}, {
  "depth": 2,
  "id": "%E3%83%81%E3%83%A5%E3%83%BC%E3%83%88%E3%83%AA%E3%82%A2%E3%83%AB",
  "label": "チュートリアル",
  "parent": "%E9%96%A2%E9%80%A3%E8%A8%98%E4%BA%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      h2: "h2",
      a: "a",
      img: "img",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "モジュールとテーマのフィールドに関するベストプラクティス"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールやテーマにフィールドを組み込む際に留意すべきベストプラクティスとしては、例えば次のものがあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "フィールドをグループ化する"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "複数のモジュール全体にわたって論理的にフィールドを編成する"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "スタイルフィールドを使用してスタイル設定に一貫性を持たせる"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この記事では、コンテンツ制作担当者のために効率的で一貫性のあるモジュールとテーマのフィールド編集環境を創出するために推奨されるベストプラクティスについて説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "スタイルフィールドとコンテンツフィールドの違い"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "スタイルフィールド"
      }), "は、モジュールとテーマの両方に追加できます。制作担当者はスタイルフィールドを使用して、モジュールの外観（境界線やテキストの背景画像など）を調整できます。スタイルフィールドは、意味を伝えることも、ページのコンテンツを理解するために必須になることもありません。例えば、テキスト付き画像モジュールを使用する場合、テキストの内容を理解するために画像が必要だとしたら、その画像をスタイルフィールドではなくコンテンツフィールドにしてください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールに追加したスタイルオプションは、ページエディターの［スタイル］タブに表示されます。テーマに追加したスタイルオプションは、テーマエディターの左のサイドバーに表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/style-field-module-editor0.png",
        alt: "style-field-module-editor0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "画像フィールドと背景画像フィールドのどちらを使用するかを決定する際には、アクセシビリティーを考慮してください。制作担当者が代替テキストを追加できるようにする必要がある場合は、背景画像フィールドではなく画像フィールドを使用します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フィールドを編成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンテンツ制作担当者が簡単に変更を加えられるかどうかは、フィールドをどのように編成するかによって大きく左右されます。フィールドを編成する際のベストプラクティスは以下のとおりです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "スタイルフィールドのグループ化は、スタイル要素ではなく、スタイルフィールドにより制御されるコンポーネントを基準に行います。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "最上位の重要なフィールドに限っては、グループに含まれないようにします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "ネストしていないグループよりも、コンポーネントグループを作成することを心掛けます。後でモジュールに機能を追加する必要が生じた場合に、ページ上のモジュールの全てのインスタンスを手動で更新しなければ、モジュールをグループに移動できなくなるためです。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "サイトを保守することになる制作担当者の大半が使用する第一（プライマリー）言語に基づくコンポーネントの表示順で、フィールドグループを配置します。例えば、英語を読む順序は左から右、上から下です。サイトを保守するユーザーが一般的に右から左に読む言語を使用している場合は、その順序に合わせます。不明な場合は、サイトのコンテンツの第一言語に基づいて決定します。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#field-groups",
        children: "フィールドグループ"
      }), "について詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "例"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カードモジュールの例を以下に示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/card%20and%20style%20settings.png",
        alt: "アイコン、テキスト、ボタンからなる一般的なカードレイアウト。スタイル設定オプションは、アイコン、ボタン、カードという分類でグループ化されて右側に表示されます。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "スタイルパネルでは、カードでスタイル設定可能なコンポーネントに基づいてモジュールフィールドが3つのグループに分けられています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アイコン"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ボタン"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "カード"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カードを表示すると、アイコンが最初に表示され、その後にテキストとボタンが表示されます。アイコングループとそのスタイル設定オプションは、ボタングループとそのスタイル設定オプションよりも前に表示されます。したがって、アイコンの色フィールドは［アイコン**］グループに含まれ、ボタンの背景色フィールドは［ボタン**］グループに含まれます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "必須フィールド"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "必須フィールドとは、モジュールを表示してページを公開するためにコンテンツ制作担当者が設定しなければならないフィールドのことです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "値を設定しないとモジュールが機能しなくなる場合にのみ、フィールド値の指定を必須にします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "必須フィールドを使用しなければならない場合は、可能な限りフィールドの既定値を指定します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、同時に表示されるスライドの枚数を設定できる画像カルーセルモジュールを作成しているとします。最小値として1が必要になるので、値が指定されていない場合には画像カルーセルをどう表示するかが不明です。このような状況では、値を必須にし、既定値として1または2を設定することが適切です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "タイポグラフィー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リッチ テキスト フィールドを使用すると、タイポグラフィーを細かく制御できるため、可能な場合はテキストフィールドとフォントフィールドを組み合わせるのではなく、リッチ テキスト フィールドを使用することをお勧めします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、同じモジュール内で、コンテンツの複数の要素（ヘッダーとして意図された", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#text",
        children: "テキストフィールド"
      }), "など）に適用するタイポグラフィースタイルの指定が必要になる場合があります。このような場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#font",
        children: "フォント"
      }), "と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#color",
        children: "色"
      }), "のスタイルフィールドを提供することで、制作担当者の作業を効率化できる可能性があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リッチ テキスト フィールドを含める場合は、フィールドのタイポグラフィーコントロールによって、手動によって追加されたスタイルフィールドを変更できるようにすることをお勧めします。スタイルフィールドによってリッチ テキスト フィールドを制御する場合、制作担当者向けにヘルプテキストを設定しておくと効果的です。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブール型フィールドの切り替えスイッチとチェックボックス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#boolean",
        children: "ブール型フィールド"
      }), "を含める場合は、切り替えスイッチまたはチェックボックスとして表示されるように設定できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ブール型フィールドがデザインやレイアウトの主要なオプションを制御する場合は、切り替えスイッチとして設定します。例えば、カードのレイアウトを縦長から横長に変える場合などです。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ブール型フィールドによる変更がそれほど大きくない場合は、チェックボックスとして設定します。例えば、特集ブログ記事モジュール内で、ブログ記事の公開日の非表示と非表示を切り替える場合などです。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連記事"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields",
          children: "モジュールとテーマのフィールドタイプ"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "モジュールとテーマのフィールドの概要"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "モジュールの概要"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/overview",
          children: "テーマの概要"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "チュートリアル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "モジュールを使ってみる"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "テーマを使ってみる"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}