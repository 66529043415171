"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 163939733306;
const slug = exports.slug = 'reference/ui-components/standard-components/panel';
const title = exports.title = 'Panel';
const name = exports.name = 'Panel | UI components';
const metaDescription = exports.metaDescription = 'Learn about the Panel component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "panel-props",
  "label": "Panel props",
  "parent": null
}, {
  "depth": 0,
  "id": "panelsection-props",
  "label": "PanelSection props",
  "parent": null
}, {
  "depth": 0,
  "id": "opening-and-closing-panels",
  "label": "Opening and closing panels",
  "parent": null
}, {
  "depth": 0,
  "id": "variants",
  "label": "Variants",
  "parent": null
}, {
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      img: "img",
      pre: "pre",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Panel | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Panel"
      }), " component renders a panel overlay on the right side of the page and contains other components. Like the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/modal",
        children: "Modal"
      }), " component, you'll include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Panel"
      }), " component in an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "overlay"
      }), " prop within a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/button",
        children: "Button"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/loading-button",
        children: "LoadingButton"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/link",
        children: "Link"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/tag",
        children: "Tag"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/image",
        children: "Image"
      }), " component."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To see an example of using a panel in an extension, check out HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview#build-a-multi-step-flow",
        children: "Build a multi-step flow"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview#overlay-example",
        children: "Overlay example project"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " as of June 24, 2024, the method for including ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Panel"
        }), " components has changed. Moving forward:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Panels are nested within the components that open them."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Opening the panel is automatically handled by the parent component, rather than requiring reactions."
        }), "\n"]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Extensions using the old method will continue to function, but it's recommended to review the new functionality below and update your extensions as needed."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/panel-example-gif.gif?width=700&height=595&name=panel-example-gif.gif",
        alt: "panel-example-gif"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Panel"
      }), " component uses three subcomponents to control its design and content, which follows the general structure below:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "<Panel>"
        }), ": the outermost container. It must be a top-level component. You cannot put a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Panel"
        }), " inside another component, such as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Flex"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "<PanelBody>"
            }), ": the container that wraps the panel's content and makes it scrollable. Include only one ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PanelBody"
            }), " per ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Panel"
            }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "<PanelSection>"
                }), ": a container that adds padding and bottom margin to provide spacing between content. You can use ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/ui-components/standard-components/flex",
                  children: "Flex"
                }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/ui-components/standard-components/box",
                  children: "Box"
                }), " to further customize content layout."]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "<PanelFooter>"
            }), ": a sticky footer component at the bottom of the panel. Include only one ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PanelFooter"
            }), " per ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Panel"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Button, Panel, PanelSection, PanelBody, PanelFooter, Text, hubspot } from '@hubspot/ui-extensions';\n\nhubspot.extend(() => <OverlayExampleCard />);\n\nconst OverlayExampleCard = () => {\n  return (\n    <>\n      <Button\n        overlay={\n        <Panel id=\"my-panel\" title=\"Example panel\">\n          <PanelBody>\n            <PanelSection>\n            <Text>Welcome to my panel. Thanks for stopping by!</Text>\n            <Text>Close the panel by clicking the X in the top right.</Text>\n            </PanelSection>\n          </PanelBody>\n          <PanelFooter>\n          </PanelFooter>\n        </Panel>\n        }\n      >\n        Open panel\n      </Button>\n    &lt;/>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below are the props available for ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Panel"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PanelSection"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Panel props"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A unique ID for the panel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onOpen"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "onOpen() => void"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A function that will be invoked when the panel has finished opening."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onClose"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "onClose() => void"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A function that will be invoked when the panel has finished closing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'sm'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'small'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'md'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'medium'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'lg'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'large'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The width of the panel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The text that displays at the top of the panel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "variant"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'modal'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'default'"
            }), " (default)"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The panel variant. The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "modal"
            }), " variant includes better screen reader focus on the panel and is recommended for visual and motor accessibility and tab navigation. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#variants",
              children: "variants"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "aria-label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The panel's accessibility label."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "PanelSection props"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flush"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", the section will have no bottom margin. Default is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Opening and closing panels"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, HubSpot handles opening the panel when the user clicks the parent ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/button",
        children: "Button"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/loading-button",
        children: "LoadingButton"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/link",
        children: "Link"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/tag",
        children: "Tag"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/image",
        children: "Image"
      }), " component. A close button will also be included in the top right of the panel."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-component-panel-close.png",
        alt: "ui-extension-component-panel-close"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition, you can add a close mechanism to a panel using a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Button"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LoadingButton"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Link"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Tag"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Image"
      }), " with an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onClick"
      }), " event that triggers the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "closeOverlay"
      }), " action. To use this action, you'll need to include the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk#registering-the-extension",
        children: "actions argument"
      }), " in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.extend()"
      }), " as seen in the example code below."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sdk#open-an-overlay",
        children: "opening and closing overlays"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Button, Panel, PanelSection, PanelBody, PanelFooter, Text, hubspot } from '@hubspot/ui-extensions';\n\nhubspot.extend(({ actions }) => <OverlayExampleCard actions={actions} />);\n\nconst OverlayExampleCard = ({ actions }) => {\n  return (\n    <>\n      <Button\n        overlay={\n        <Panel id=\"my-panel\" title=\"Example panel\">\n          <PanelBody>\n            <PanelSection>\n            <Text>Welcome to my panel. Thanks for stopping by!</Text>\n            <Text>Close the panel by clicking the X in the top right, or using the button below</Text>\n            </PanelSection>\n          </PanelBody>\n          <PanelFooter>\n            <Button\n              variant=\"secondary\"\n              onClick={() => {actions.closeOverlay('my-panel');}}\n            >\n              Close\n            </Button>\n          </PanelFooter>\n        </Panel>\n        }\n      >\n        Open panel\n      </Button>\n    &lt;/>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "You can only have one panel open at a time. Opening a panel while another is already open will cause the first one to close."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Modal"
          }), " can be opened from a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Panel"
          }), ", but a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Panel"
          }), " cannot be opened from a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "Modal"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, the panel will only obscure the content on the right side of the page where it opens. Using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "variants"
      }), " prop, you can add an additional overlay behind the panel to blur the rest of the page. This variant puts more focus on the panel and improves accessibility for users with screen readers. Because the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "modal"
      }), " variant obscures the rest of the page's content, use it only when users don't need other context from the page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/ui-extension-panel-modal-variant.png",
        alt: "ui-extension-panel-modal-variant"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use a panel when a user needs to submitting an order form for a customer."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use this component when users need to fill out a longer or multi-step form."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/ui-components/standard-components/box",
            children: "Box"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/ui-components/standard-components/button",
            children: "Button"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/ui-components/standard-components/loading-button",
            children: "LoadingButton"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/ui-components/standard-components/divider",
            children: "Divider"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/ui-components/standard-components/link",
            children: "Link"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/ui-components/standard-components/image",
            children: "Image"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/ui-components/standard-components/modal",
            children: "Modal"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/ui-components/standard-components/tag",
            children: "Tag"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/ui-components/standard-components/tile",
            children: "Tile"
          }), (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/ui-components/standard-components/divider"
          })]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}