import { CLEAR_SEARCH, SEARCH_FAILED, SEARCH_STARTED, SEARCH_SUCCEEDED, SET_SEARCH_QUERY } from '../actions/ActionTypes';
// @ts-expect-error Untyped
import RequestStatus from 'developer-docs-shared-components/lib/models/RequestStatus';
import produce from 'immer';
export const DEFAULT_SEARCH_STATE = {
  status: RequestStatus.uninitialized,
  query: '',
  results: [],
  total: 0
};
const searchState = (state = DEFAULT_SEARCH_STATE, action) => produce(state, draft => {
  switch (action.type) {
    case SET_SEARCH_QUERY:
      draft.query = action.query;
      if (!action.query) {
        draft.results = [];
      }
      break;
    case SEARCH_STARTED:
      draft.status = RequestStatus.pending;
      break;
    case SEARCH_SUCCEEDED:
      draft.status = RequestStatus.succeeded;
      draft.results = action.results;
      draft.total = action.total;
      break;
    case SEARCH_FAILED:
      draft.status = new RequestStatus({
        failed: true,
        errorDetails: action.error
      });
      draft.results = [];
      break;
    case CLEAR_SEARCH:
      draft.query = '';
      draft.results = [];
      draft.status = RequestStatus.uninitialized;
      break;
    default:
      break;
  }
});
export default searchState;