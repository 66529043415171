"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 164653255347;
const slug = exports.slug = 'reference/ui-components/crm-data-components/crm-association-table';
const title = exports.title = 'CrmAssociationTable';
const name = exports.name = 'CrmAssociationTable | UI components';
const metaDescription = exports.metaDescription = 'Learn about the CrmAssociationTable component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tag,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CrmAssociationTable | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CrmAssociationTable"
      }), " component renders a table of associated records with optional filtering, sorting, and search methods. You'll specify the type of records that you want to appear along with the properties to display as columns."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-ext-components-associationstable.png",
        alt: "ui-ext-components-associationstable"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { CrmAssociationTable } from '@hubspot/ui-extensions/crm';\n\nconst Extension = () => {\n  return (\n    <CrmAssociationTable\n      objectTypeId=\"0-3\"\n      propertyColumns={['dealname', 'amount', 'description']}\n      quickFilterProperties={['createdate']}\n      pageSize={10}\n      preFilters={[\n        {\n          operator: 'EQ',\n          property: 'dealstage',\n          value: 'contractsent',\n        },\n      ]}\n      sort={[\n        {\n          direction: 1,\n          columnName: 'amount',\n        },\n      ]}\n      searchable={true}\n      pagination={true}\n    />\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyColumns"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The properties to display as table columns."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypeId"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The numeric ID of the type of associated object to display (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0-1"
            }), " for contacts). See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/understanding-the-crm#object-type-id",
              children: "complete list"
            }), " of object IDs."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quickFilterProperties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The properties that appear as filters above the table. When included, the \"Association label\" quick filter will always display. See note below for more details on this prop."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationLabelFilter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", hides the \"Association label\" quick filter above the table."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "searchable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", hides the search bar above the table."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pagination"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", hides the pagination navigation below the table."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageSize"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The number of rows to include per page of results. Include the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pagination"
            }), " property to enable users to navigate through returned results."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "preFilters"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Filters the data by specific values of the associated records. Review the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-data-components/overview#filtering-data",
              children: "CRM data filter options"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sort"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The default sorting behavior for the table. In each sort object in the array, you'll specify the following:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "columnName"
                }), ": the column to sort by."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "direction"
                }), ": the direction to sort by. Can be either ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                }), " (ascending) or ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "-1"
                }), " (descending). By default, order is ascending."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " for ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "quickFilterProperties"
        }), ":"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["By default, four quick filters will display automatically depending on the object type.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsxs)(_components.strong, {
                children: ["Contacts (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "0-1"
                }), "):"]
              }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "[ 'hubspot_owner_id', 'createdate', 'hs_lead_status', 'notes_last_updated' ]"
              })]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsxs)(_components.strong, {
                children: ["Companies (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "0-2"
                }), "):"]
              }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "[ 'hubspot_owner_id', 'hs_lead_status', 'notes_last_updated', 'createdate' ]"
              })]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsxs)(_components.strong, {
                children: ["Deals (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "0-3"
                }), "):"]
              }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "[ 'hubspot_owner_id', 'closedate', 'createdate', 'dealstage' ]"
              })]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsxs)(_components.strong, {
                children: ["Tickets (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "0-5"
                }), "):"]
              }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "[ 'hubspot_owner_id', 'createdate', 'hs_pipeline_stage', 'hs_lastactivitydate' ]"
              })]
            }), "\n"]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Custom objects do not have default quick filters."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["An empty array (", (0, _jsxRuntime.jsx)(_components.code, {
            children: "[]"
          }), ") will remove any default quick filters except for \"Association label.\""]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-association-pivot",
          children: "CrmAssociationPivot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-association-property-list",
          children: "CrmAssociationPropertyList"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-data-components/crm-report",
          children: "CrmReport"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}