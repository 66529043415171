"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358753;
const slug = exports.slug = 'guides/api/crm/objects/deals';
const title = exports.title = 'API do  | Negócios';
const name = exports.name = 'vNext DP de documentos - Negócios';
const metaDescription = exports.metaDescription = 'Um negócio armazena dados de uma transação em andamento. Os pontos de extremidade de negócios permitem que você gerencie e sincronize esses dados entre o HubSpot e outros sistemas. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-neg%C3%B3cios",
  "label": "Criar negócios",
  "parent": null
}, {
  "depth": 1,
  "id": "propriedades",
  "label": "Propriedades",
  "parent": "criar-neg%C3%B3cios"
}, {
  "depth": 1,
  "id": "associa%C3%A7%C3%B5es",
  "label": "Associações",
  "parent": "criar-neg%C3%B3cios"
}, {
  "depth": 0,
  "id": "recuperar-neg%C3%B3cios",
  "label": "Recuperar negócios",
  "parent": null
}, {
  "depth": 0,
  "id": "atualizar-neg%C3%B3cios",
  "label": "Atualizar negócios",
  "parent": null
}, {
  "depth": 1,
  "id": "associar-neg%C3%B3cios-existentes-a-registros-ou-atividades",
  "label": "Associar negócios existentes a registros ou atividades",
  "parent": "atualizar-neg%C3%B3cios"
}, {
  "depth": 1,
  "id": "remover-uma-associa%C3%A7%C3%A3o",
  "label": "Remover uma associação",
  "parent": "atualizar-neg%C3%B3cios"
}, {
  "depth": 0,
  "id": "fixar-uma-atividade-em-um-registro-de-neg%C3%B3cio",
  "label": "Fixar uma atividade em um registro de negócio",
  "parent": null
}, {
  "depth": 0,
  "id": "excluir-neg%C3%B3cios",
  "label": "Excluir negócios",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Negócios"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No HubSpot, os negócios representam transações com contatos ou empresas. Os negócios são rastreados durante o processo de vendas nas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages",
        children: "fases do pipeline"
      }), " até serem conquistados ou perdidos. Os pontos de extremidade de negócios permitem controlar a criação e o gerenciamento de registros de negócios, bem como sincronizar dados de negócios entre o HubSpot e outros sistemas."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre objetos, registros, propriedades e APIs de associações no guia ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Noções básicas do CRM"
      }), ". Para obter informações mais gerais sobre objetos e registros no HubSpot,", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/a-guide-to-using-records",
        children: " saiba como gerenciar seu banco de dados do CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar negócios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar novos negócios, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Na solicitação, inclua os dados do negócio em um objeto de propriedades. Você também pode adicionar um objeto de associações para associar seu novo negócio a registros (por exemplo, contatos, empresas) ou atividades (por exemplo, reuniões, observações) existentes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriedades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os detalhes do negócio são armazenados nas propriedades do negócio. Existem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-deals/hubspots-default-deal-properties",
        children: "propriedades de negócio padrão do HubSpot"
      }), ", mas você também pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "criar propriedades personalizadas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao criar um novo negócio, você deve incluir as seguintes propriedades na solicitação: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealname"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dealstage"
      }), " e, caso tenha vários pipelines, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pipeline"
      }), ". Se um pipeline não for especificado, o pipeline padrão será usado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para exibir todas as propriedades disponíveis, você pode recuperar uma lista das propriedades de negócios da sua conta, fazendo uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/deals"
      }), ". Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "API de propriedades"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": você deve usar o ID interno de uma fase ou pipeline de negócios ao criar um negócio por meio da API. O ID interno também será retornado quando você recuperar negócios por meio da API. Você pode encontrar o ID interno de uma fase ou pipeline de negócios em nas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-deals/set-up-and-customize-your-deal-pipelines-and-deal-stages#to-customize-your-deal-stages:~:text=To%20view%20the%20internal%20name%20for%20a%20deal%20stage%2C%20hover%20over%20the%20stage%20and%20click%20the%20code%20code%20icon.%20The%20internal%20name%20is%20used%20by%20integrations%20and%20the%20API.",
          children: "configurações do pipeline de negócios."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, para criar um novo negócio, a solicitação pode ser semelhante à seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"amount\": \"1500.00\",\n    \"closedate\": \"2019-12-07T16:50:06.678Z\",\n    \"dealname\": \"New deal\",\n    \"pipeline\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hubspot_owner_id\": \"910901\"\n  }\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao criar um novo negócio, você também pode associá-lo a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "registros"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "atividades"
      }), " existentes. Por exemplo, para associar um novo negócio a um contato e uma empresa existentes, a solicitação seria parecida com a seguinte:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body\n{\n  \"properties\": {\n    \"amount\": \"1500.00\",\n    \"closedate\": \"2019-12-07T16:50:06.678Z\",\n    \"dealname\": \"New deal\",\n    \"pipeline\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hubspot_owner_id\": \"910901\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 201\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 5\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 3\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No objeto de associações, você deve incluir o seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O registro ou a atividade que você deseja associar ao negócio especificado por seu valor de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " exclusivo."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de associação entre o negócio e o registro/atividade. Inclua ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Os IDs de tipo de associação padrão são listados ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "aqui"
            }), ", ou você pode recuperar o valor de tipos de associação personalizados (ou seja, rótulos) por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API de associações"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar negócios"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode recuperar negócios individualmente ou em lotes."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar um negócio individual, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/{dealId}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para solicitar uma lista de todos os negócios, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ambos os pontos de extremidade, você pode incluir os seguintes parâmetros de consulta no URL da solicitação:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista separada por vírgulas das propriedades a serem retornadas em resposta. Se o negócio solicitado não tiver um valor para uma propriedade, ele não será exibido na resposta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma lista separada por vírgulas das propriedades atuais e do histórico a serem retornadas em resposta. Se o negócio solicitado não tiver um valor para uma propriedade, ele não será exibido na resposta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma lista separada por vírgulas de objetos para recuperar IDs associados. Todas as associações especificadas que não existem não serão retornadas na resposta. Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API de associações."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para recuperar um lote de negócios específicos por ID de registro ou uma ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/properties#create-unique-identifier-properties",
          children: "propriedade de identificador exclusivo personalizada"
        }), ", faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/deals/batch/read"
        }), ". O ponto de extremidade em lote ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " pode recuperar associações. Saiba como fazer associações de leitura em lote com a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "API de associações"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para o ponto de extremidade de leitura em lote, você também pode usar o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " opcional para recuperar negócios por uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "propriedade de identificador exclusivo personalizada"
      }), ". Por padrão, os valores de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " na solicitação referem-se ao ID do registro (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "); portanto, o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " não é necessário ao recuperar pelo ID do registro. Para usar uma propriedade de valor exclusivo personalizada para recuperar negócios, você deve incluir o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, para recuperar um lote de negócios, sua solicitação pode ser parecida com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with record ID\n{\n  \"properties\": [\"dealname\", \"dealstage\", \"pipeline\"],\n  \"inputs\": [\n    {\n      \"id\": \"7891023\"\n    },\n    {\n      \"id\": \"987654\"\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "///Example request body with a unique value property\n{\n  \"properties\": [\"dealname\", \"dealstage\", \"pipeline\"],\n  \"idProperty\": \"uniqueordernumber\",\n  \"inputs\": [\n    {\n      \"id\": \"0001111\"\n    },\n    {\n      \"id\": \"0001112\"\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para recuperar negócios com valores atuais e do histórico de uma propriedade, sua solicitação pode ser parecida com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body with record ID (current and historical values)\n{\n  \"propertiesWithHistory\": [\"dealstage\"],\n  \"inputs\": [\n    {\n      \"id\": \"7891023\"\n    },\n    {\n      \"id\": \"987654\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Atualizar negócios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode atualizar negócios individualmente ou em massa. Para negócios existentes, o ID do negócio é um valor exclusivo que você pode usar para atualizar o negócio por meio da API, mas você também identificar negócios usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "propriedades de identificador exclusivo personalizadas."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para atualizar um negócio individual por seu ID de registro, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PATCH"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/{dealId}"
        }), " e inclua os dados que deseja atualizar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para atualizar vários negócios, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/deals/batch/update"
        }), ". No corpo da solicitação, inclua uma matriz com os identificadores dos negócios e as propriedades que deseja atualizar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associar negócios existentes a registros ou atividades"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para associar um negócio a outros registros do CRM ou uma atividade, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para recuperar o valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), ", consulte ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations#association-type-id-values",
          children: "esta lista"
        }), " de valores padrão ou envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como associar registros com a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de associações"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remover uma associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para remover uma associação entre um negócio e um registro ou uma atividade, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para o seguinte URL: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fixar uma atividade em um registro de negócio"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode fixar uma atividade em um registro de negócio por meio da API, incluindo o campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " na sua solicitação. No campo, inclua o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " da atividade a ser fixada, que pode ser recuperado por meio das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "APIs de engajamentos"
      }), ". Você pode fixar uma atividade por registro. No entanto, a atividade já deve estar associada ao negócio antes da fixação."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para definir ou atualizar a atividade fixada de um negócio, sua solicitação pode ser parecida com o seguinte:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body PATCH /crm/v3/objects/deals/{dealId}\n{\n  \"properties\": {\n    \"hs_pinned_engagement_id\": 123456789\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você também pode criar um negócio, associá-lo a uma atividade existente e fixar a atividade na mesma solicitação. Por exemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request body POST /crm/v3/objects/deals\n{\n  \"properties\": {\n    \"dealname\": \"New deal\",\n    \"pipelines\": \"default\",\n    \"dealstage\": \"contractsent\",\n    \"hs_pinned_engagement_id\": 123456789\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 123456789\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 213\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Excluir negócios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode excluir negócios individualmente ou em massa, o que adicionará o negócio à lixeira no HubSpot. Posteriormente, você pode", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: " restaurar o negócio no HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir um negócio individual pelo seu ID, faça uma solicitação", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/deals/{dealId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre a exclusão em massa de negócios na guia* Pontos de extremidade* na parte superior deste artigo."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}