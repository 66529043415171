"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77654816936;
const slug = exports.slug = 'guides/api/marketing/marketing-events';
const title = exports.title = 'API de Marketing | Eventos de marketing';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Marketing Events';
const metaDescription = exports.metaDescription = 'Un resumen del objeto Eventos de marketing en HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "in-this-article",
  "label": "In this article",
  "parent": null
}, {
  "depth": 0,
  "id": "requisitos-de-alcance",
  "label": "Requisitos de alcance",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-y-actualizar-eventos",
  "label": "Crear y actualizar eventos",
  "parent": null
}, {
  "depth": 0,
  "id": "puntos-de-terminaci%C3%B3n-de-asistencia-a-eventos",
  "label": "Puntos de terminación de asistencia a eventos",
  "parent": null
}, {
  "depth": 0,
  "id": "configurar-valores-de-la-aplicaci%C3%B3n",
  "label": "Configurar valores de la aplicación",
  "parent": null
}, {
  "depth": 1,
  "id": "paso-1-crea-una-api-en-tu-aplicaci%C3%B3n",
  "label": "Paso 1: Crea una API en tu aplicación",
  "parent": "configurar-valores-de-la-aplicaci%C3%B3n"
}, {
  "depth": 1,
  "id": "paso-2-proporciona-a-hubspot-la-ruta-de-url-a-tu-api",
  "label": "Paso 2: Proporciona a HubSpot la ruta de URL a tu API",
  "parent": "configurar-valores-de-la-aplicaci%C3%B3n"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      hr: "hr",
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      a: "a",
      code: "code",
      strong: "strong",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Eventos de marketing"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un evento de marketing es un objeto de CRM, similar a los contactos y las empresas, que te permite hacer seguimiento y asociar eventos de marketing, como un webinario, a otros objetos de CRM de HubSpot. A continuación, obtén más información sobre cómo trabajar con la API de eventos de marketing para integrarlos en una aplicación."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "In this article"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#scope-requirements",
          children: "Requisitos de alcance"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#scope-requirements",
          children: "Puntos de terminación de asistencia a eventos"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#configure-app-settings",
          children: "Configurar valores de la aplicación"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#step-1-create-an-api-in-your-app",
              children: "Paso 1: Crea una API en tu aplicación"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#step-2-provide-hubspot-with-the-url-path-to-your-api",
              children: "Paso 2: Proporciona a HubSpot la ruta de URL a tu API"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Requisitos de alcance"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para realizar una solicitud de API a uno de los puntos de terminación de eventos de marketing, se requieren los siguientes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "alcances"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.marketing_events.read"
        }), ": otorga permiso para recuperar datos de eventos de marketing y asistencia."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.marketing_events.write"
        }), ": otorga permisos para crear, eliminar o realizar cambios en la información del evento de marketing."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al autenticar las llamadas realizadas por tu aplicación, puedes usar un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "token de acceso privado a la aplicación"
      }), " u ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth"
      }), ". Más información sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "métodos de autentificación"
      }), ". Para obtener la lista completa de puntos de terminación disponibles, haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear y actualizar eventos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes crear o actualizar eventos de marketing realizando una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " al punto de terminación ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/events/upsert"
      }), ". Puedes incluir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " que correspondan a los eventos que quieres actualizar en el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), " del cuerpo de tu solicitud, junto con cualquier otro detalle de tu evento (incluido el nombre, hora de inicio y descripción)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si ya existe un evento de marketing con el ID especificado en tu solicitud, se actualizará. De lo contrario, se creará un nuevo evento."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, la siguiente solicitud crearía un evento con un ID de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "4"
      }), " llamado \"Virtual cooking class\":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for POST request to /marketing/v3/marketing-events/events/upsert\n{\n  \"inputs\": [\n    {\n      \"customProperties\": [\n        {\n          \"name\": \"property1\",\n          \"value\": \"1234\"\n        }\n      ],\n      \"eventName\": \"Virtual cooking class\",\n      \"startDateTime\": \"2023-11-30T17:46:20.461Z\",\n      \"eventOrganizer\": \"Chef Joe\",\n      \"eventDescription\": \"Join us for a virtual cooking class! Yum.\"\n      \"eventCancelled\": false,\n      \"externalAccountId\": \"CookingCo\",\n      \"externalEventId\": \"4\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes revisar una lista completa de todos los campos disponibles que puedes incluir en tu solicitud haciendo clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Puntos de terminación de asistencia a eventos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los puntos de terminación de estado de asistencia a eventos te permiten registrar un estado de suscripción entre un contacto de HubSpot y un evento de marketing. Por ejemplo, puedes usar este punto de terminación para registrar que un contacto de HubSpot se registró para un evento de marketing."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hay dos puntos de terminación que puedes usar para actualizar el estado de asistencia de un contacto. Si anteriormente usabas los puntos de terminación ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/upsert"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/email-upsert"
      }), " para actualizar el estado de un asistente, puedes usar los puntos de terminación que se enumeran a continuación para mantener la misma funcionalidad y al mismo tiempo completar la duración de la asistencia del contacto en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/customize-activities-on-a-contact-company-deal-ticket-record-timeline",
        children: "cronología del contacto"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si deseas usar los ID de contacto de los contactos existentes:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/marketing/v3/marketing-events/attendance/{externalEventId}/{subscriberState}/create"
            }), ", utilizando el ID del evento desde tu aplicación externa como el ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalEventId"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["En el cuerpo de la solicitud, proporciona un objeto ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), " que incluya los siguientes campos:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "interactionDateTime"
                }), ": la fecha y la hora en que el contacto se suscribió al evento."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "vid"
                }), ": el ID de contacto de un contacto existente."]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si quieres usar la dirección de correo electrónico de uno de los asistentes al evento:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "POST"
            }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/marketing/v3/marketing-events/attendance/{externalEventId}/{subscriberState}/email-create"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["En el cuerpo de la solicitud, proporciona un objeto ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputs"
            }), " que incluya los siguientes campos:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "interactionDateTime"
                }), ": la fecha y la hora en que el contacto se suscribió al evento."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "email"
                }), ": la dirección de correo electrónico del asistente como el valor del campo de correo electrónico dentro de una entrada"]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si la dirección de correo electrónico que incluyes no coincide con la dirección de un contacto existente, se creará un nuevo contacto."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para ambos puntos de terminación anteriores, proporciona los siguientes valores para los parámetros de ruta correspondientes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), ": el ID del ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/integrations/use-marketing-events#view-edit-and-analyze-marketing-events",
          children: "evento de marketing"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriberState"
        }), ": una enumeración que coincide con el nuevo estado de asistencia del contacto:"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "REGISTERED"
        }), ": indica que el contacto de HubSpot se registró para el evento."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "ATTENDED"
        }), ": indica que el contacto de HubSpot asistió al evento. Si estás actualizando el estado de un contacto a ATTENDED, también puedes incluir las marcas de tiempo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "joinedAt"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "leftAt"
        }), " como parámetros en el cuerpo de la solicitud, especificados en el formato ISO8601 Instant."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELLED"
        }), ": indica que el contacto de HubSpot, que se había registrado previamente para el evento, ahora ha cancelado su registro para el evento."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que tu evento se haya completado, puedes marcar el evento como completo y calcular las métricas de asistencia (por ejemplo, la duración de la asistencia para todos los asistentes) haciendo una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/events/{externalEventId}/complete"
      }), ". Ten en cuenta que esta acción es ", (0, _jsxRuntime.jsx)("u", {
        children: "irreversible"
      }), ", por lo que solo debes invocar este punto de terminación una vez que ya se hayan producido todos los eventos de cambio de estado de asistencia."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " estas API son idempotentes siempre que el ID del contacto y el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "interactionDateTime"
        }), " en el evento no hayan cambiado. Esto te permite configurar de forma segura el estado del suscriptor varias veces sin que HubSpot cree eventos duplicados en las propiedades de eventos de marketing."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurar valores de la aplicación"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se requiere cierta configuración para permitir que los eventos de marketing se sincronicen correctamente con HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si envías a HubSpot un cambio de estado del suscriptor (por ejemplo, un registro o evento cancelado), HubSpot primero comprobará si existe un evento de marketing para el ID de evento especificado. Si no es así, HubSpot llamará al punto de conexión configurado de tu aplicación para recuperar los detalles del evento de marketing, luego creará el evento en HubSpot y, a continuación, publicará el cambio de estado del suscriptor."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Esto se proporciona por conveniencia; sin embargo, se recomienda que crees los Eventos de marketing tu mismo a través de nuestros métodos CRUD proporcionados en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo y no confíes en esta funcionalidad para crear tus eventos de marketing en HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Paso 1: Crea una API en tu aplicación"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para respaldar esto, HubSpot requiere que cada aplicación que use Eventos de marketing defina una API para obtener información sobre un evento de marketing específico."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Requisitos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Acepta:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "externalAccountId"
            }), ": un parámetro de consulta que especifica el accountId del cliente en la aplicación externa."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            }), ": un parámetro de consulta que especifica el id de la aplicación de HubSpot que solicita los detalles del evento. Este será el ID de tu aplicación."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "externalEventId"
            }), ": un parámetro de ruta en la URL de la solicitud que especifica el ID del evento en la aplicación externa sobre la que HubSpot requiere detalles."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Devuelve:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Un objeto JSON que proporciona detalles para el evento de marketing, que incluye los siguientes campos detallados en la siguiente tabla:"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre de campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Requerido"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción del campo"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre del evento de marketing"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventOrganizer"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "true"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El nombre del organizador del evento de marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Describe qué tipo de evento es este. Por ejemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEBINAR"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONFERENCE"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "WORKSHOP"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "startDateTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string($date-time)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fecha y la hora de inicio del evento de marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "endDateTime"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string($date-time)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La fecha y la hora de finalización del evento de marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventDescription"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La descripción del evento de marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una URL en la aplicación de evento externo donde se realiza el evento de marketing."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventCancelled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "bool"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Indica si el evento de marketing se canceló. El valor predeterminado es ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot también enviará un encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature-v3"
      }), " que puedes usar para verificar que la solicitud proviene de HubSpot. Más información sobre las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "firmas de solicitud"
      }), " para obtener detalles adicionales sobre la firma y cómo validarla."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Paso 2: Proporciona a HubSpot la ruta de URL a tu API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora que has creado la API en tu aplicación que devolverá un objeto que proporciona los detalles de un evento de marketing específico, deberás proporcionar a HubSpot la ruta de URL a tu API realizando una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/marketing-events/{appId}/settings"
      }), ". Esto permitirá a HubSpot determinar cómo realizar solicitudes a tu aplicación para obtener los detalles de un evento de marketing."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el cuerpo de tu solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), ", especifica tu URL utilizando el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), ". El ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), " debe cumplir con los siguientes requisitos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Contener por lo menos una secuencia de caracteres ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "%s"
        }), ", los cuales usamos para sustituir en el id del evento (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "externalEventId"
        }), ") como parámetro de ruta."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Debe ser la ruta completa al recurso API, incluido el prefijo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://"
        }), " y el nombre de dominio (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "my.event.app"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, si configuras un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventDetailsURL"
      }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://my.event.app/events/%s"
      }), " y necesitas hacer una solicitud para obtener detalles de un evento con id ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "1234-event-XYZ"
      }), ", para la aplicación HubSpot con id ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "app-101"
      }), " y la cuenta con id ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ABC-account-789"
      }), ", HubSpot hará una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://my.event.app/events/1234-event-XYZ?appId=app-101&externalAccountId=ABC-account-789"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}