"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29837710048;
const slug = exports.slug = 'guides/cms/setup/getting-started-with-local-development';
const title = exports.title = 'Primeros pasos con el desarrollo local';
const name = exports.name = 'Primeros pasos con el desarrollo local';
const metaDescription = exports.metaDescription = 'Comienza con el desarrollo local en el CMS de HubSpot. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/local_development.png';
const featuredImageAltText = exports.featuredImageAltText = 'Primeros pasos con el desarrollo local';
const toc = exports.toc = [{
  "depth": 0,
  "id": "instalar-dependencias",
  "label": "Instalar dependencias",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-crear-un-directorio-de-trabajo",
  "label": "1. Crear un directorio de trabajo",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-configurar-las-herramientas-de-desarrollo-local",
  "label": "2. Configurar las herramientas de desarrollo local",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-crear-un-recurso-para-recuperarlo-en-hubspot",
  "label": "3. Crear un recurso para recuperarlo en HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-llevar-el-m%C3%B3dulo-a-su-entorno-local",
  "label": "4. Llevar el módulo a su entorno local",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-realizar-cambios-y-subir",
  "label": "5. Realizar cambios y subir",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-ejecutar-un-comando-watch-para-cargar-autom%C3%A1ticamente-los-cambios",
  "label": "6. Ejecutar un comando watch para cargar automáticamente los cambios",
  "parent": null
}, {
  "depth": 0,
  "id": "pasos-siguientes",
  "label": "Pasos siguientes",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      h2: "h2",
      ol: "ol",
      strong: "strong",
      br: "br",
      em: "em",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Primeros pasos con el desarrollo local"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI (interfaz de línea de comandos) de HubSpot"
      }), " conecta tu entorno local a HubSpot, lo que significa que tendrás copias locales de tus recursos web de HubSpot. Esto te permite utilizar el control de versiones, tu editor de texto favorito y varias tecnologías de desarrollo web al desarrollar en el CMS Hub."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Esta guía es mejor para quienes ya están familiarizados con el CMS pero quiere aprender a trabajar con la CLI. Si recién estás comenzando con el desarrollo en CMS Hub, te animamos a consultar la guía de inicio rápido:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "Inicio rápido del desarrollo de CMS Hub"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En este tutorial, aprenderás:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cómo instalar la CLI y conectarla a tu cuenta de HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cómo obtener un módulo de tu cuenta de HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Cómo actualizar el módulo localmente y luego cargar los cambios."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cómo usar el comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        }), " para continuar subiendo los cambios guardados."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para conocer más comandos y formatos de archivos locales, consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "referencia de herramientas de desarrollo local"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Instalar dependencias"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para desarrollar en HubSpot localmente, deberás:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Instalar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/",
          children: "Node.js"
        }), ", un entorno de tiempo de ejecución de Javascript que permite las herramientas locales. Las versiones 16 o superiores de Node son compatibles, pero recomendamos la versión de soporte a largo plazo (LTS)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ejecuta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install -g @hubspot/cli"
        }), " en tu línea de comandos para instalar las CLI de HubSpot globalmente. Para instalar las herramientas únicamente en tu directorio actual, ejecuta ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install @hubspot/cli"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si lo prefieres, también puedes utilizar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://classic.yarnpkg.com/en/docs/install",
        children: "Yarn"
      }), ". Si estás utilizando Yarn, los comandos se ejecutan con el prefijo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yarn"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "¿Se produce un error de EACCES al instalar?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Consulta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.npmjs.com/resolving-eacces-permissions-errors-when-installing-packages-globally",
          children: "NPM Resolver errores de permisos EACCESS al instalar paquetes de forma global"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Crear un directorio de trabajo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Crea una carpeta para el trabajo que harás a continuación. Para fines de este tutorial, dale un nombre a la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "local-dev-tutorial"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes hacerlo localmente ejecutando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mkdir local-dev-tutorial"
      }), " en la línea de comandos, que creará el directorio. Luego, ejecuta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cd local-dev-tutorial"
      }), " para ir a ese directorio."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Configurar las herramientas de desarrollo local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, ejecuta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs init"
      }), " para conectar las herramientas a tu cuenta HubSpot. Este comando te guiará por los siguientes pasos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Para conectar la CLI a una cuenta de HubSpot, deberás copiar la clave de acceso personal de la cuenta. Cuando se te solicite, presiona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Entrar"
          }), " para abrir hubspot.com en la ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/l/personal-access-key",
            children: "página clave de acceso personal de la cuenta"
          }), ". Si tienes varias cuentas, se te pedirá en el navegador que selecciones una cuenta primero."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "En la página de claves de acceso personal, puedes generar una nueva clave de acceso personal o copiar el valor de la clave existente si ya existe."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si estás creando una clave por primera vez, selecciona a qué ámbitos tiene acceso la clave. Deberás seleccionar al menos el permiso de ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Design Manager"
            }), " para interactuar con las herramientas de diseño de la cuenta."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Después de seleccionar los permisos de la clave, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Generar clave de acceso personal"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Una vez que se haya generado una clave, copia su valor haciendo clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Mostrar"
          }), " debajo de la clave y luego en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copiar"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Pega la tecla en la línea de comandos y luego presiona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Intro"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "A continuación, introduce un nombre para la cuenta. Este nombre solo será visto y utilizado por ti cuando ejecutes comandos. Este nombre sólo lo ves y lo utilizas tú. Por ejemplo, puedes utilizar \"entornodepruebas\" si utilizas un entorno de pruebas de desarrollador o \"empresa.com\" si utilizas una cuenta de cliente completa. Este nombre no puede contener espacios."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Presiona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Intro"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez que hayas completado el flujo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "init"
      }), ", verás un mensaje de éxito confirmando que se creó el archivo de configuración ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: "hubspot.config.yml"
      }), " en tu directorio actual."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tu ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " tendrá el siguiente aspecto:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: mainProd\nportals:\n  - name: mainProd\n    portalId: 123456\n    defaultMode: publish\n    authType: personalaccesskey\n    auth:\n      tokenInfo:\n        accessToken: >-\n          {accessTokenValue}\n\n\n        expiresAt: '2023-06-27T19:45:58.557Z'\n    personalAccessKey: >-\n      {personalAccessKeyValue}\n\n\n    sandboxAccountType: null\n    parentAccountId: null\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultPortal"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La cuenta con la que se interactúa de forma predeterminada al ejecutar comandos de la CLI. Para interactuar con una cuenta autenticada que no está configurada como predeterminada, puedes agregar la marca ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account="
            }), " al comando, seguida del nombre o ID de la cuenta. Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account=12345"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account=mainProd"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["En ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "portals"
            }), " encontrarás una entrada para cada cuenta conectada. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " especifica el nombre de la cuenta. Puedes usar este nombre al ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/local-development-cli#set-default-account",
              children: "configurar una nueva cuenta predeterminada"
            }), " o al especificar una cuenta con la marca ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de la cuenta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultMode"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Al subir contenido en la cuenta, define el estado predeterminado como Subir contenido como. Puede ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "draft"
            }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "authType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obligatorio"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La forma de autenticación utilizada para autenticar la cuenta."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "sandboxAccountType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Si la cuenta es una cuenta de entorno de pruebas, indica el ID de la cuenta de producción principal."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "parentAccountId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Opcional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "parentAccountId"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["El archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        }), " admite varias cuentas. Para autenticar más cuentas, ejecuta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#auth",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs auth"
          })
        }), " y sigue las indicaciones."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Crear un recurso para recuperarlo en HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para los fines de este tutorial, primero crearás un nuevo recurso en HubSpot para que puedas recuperarlo en tu entorno local utilizando la CLI."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En tu cuenta de HubSpot, navega hasta ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Archivos y plantillas"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Herramientas de diseño"
          }), ". Esto abrirá el gestor de diseño, que es donde puedes acceder a los archivos que subes usando la CLI. Este árbol de archivos y carpetas también se conoce como el ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/overview#developer-file-system",
            children: "sistema de archivos del desarrollador"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la barra lateral izquierda del administrador de diseño, selecciona la carpeta ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "@hubspot"
          }), " para ver los recursos predeterminados de HubSpot, como los temas y los módulos."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/design-manager-hubspot-folder.png",
            alt: "design-manager-hubspot-folder"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la barra lateral izquierda, desplázate hacia abajo y haz clic con el botón derecho en el módulo ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "icono"
          }), ", y luego selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Clonar módulo"
          }), ". El módulo se clonará en la raíz del sistema de archivos del desarrollador y la nueva copia del módulo se abrirá a la derecha."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/default-icon-module-cloned.png",
            alt: "default-icon-module-cloned"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En la parte superior de la barra lateral izquierda, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Acciones"
          }), " y, a continuación, selecciona ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copiar ruta"
          }), ". Esto copiará la ruta relativa al módulo en el sistema de archivos del desarrollador, la cual utilizarás en el siguiente paso para obtener el módulo localmente."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-actions-copy-path.png",
            alt: "module-actions-copy-path"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Llevar el módulo a su entorno local"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con el módulo clonado, ahora usarás el comando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetch"
        })
      }), " para llevarlo a tu entorno local."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el terminal, ejecuta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch '/icon copy.module'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch '/icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El módulo se descargará como un directorio que contiene cinco archivos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "fields.json"
          }), ": contiene el código de los distintos campos del módulo. En este caso, esto incluye el campo de icono, dos campos de opción de accesibilidad y un conjunto de campos de estilo. Puedes ver estos campos en la barra lateral derecha del editor de módulos en HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "meta.json"
          }), ": contiene la información básica del módulo, como su etiqueta, el ID y los tipos de plantillas en las que se puede utilizar. Esta información se mostrará en la barra lateral derecha del editor de módulos."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.css"
          }), ": contiene el CSS del módulo, que también puedes ver en el panel CSS del editor de módulos en HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.html"
          }), ": contiene el HTML del módulo, el cual también puedes ver en el panel HubL + HTML del editor de módulos en HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.js"
          }), ": contiene el JavaScript del módulo, el cual también puedes ver en el panel de JS del editor de módulos en HubSpot."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-directory-local.png",
            alt: "module-directory-local"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, actualizarás el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " del módulo, lo subirás a tu cuenta y verás el cambio en HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Realizar cambios y subir"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Primero, haz un cambio en el módulo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["En tu editor de código preferido, abre el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "meta.json"
          }), " del módulo."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-meta-json-file-open.png",
            alt: "module-meta-json-file-open"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Actualiza el campo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "label"
          }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"Icon\""
          }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"CMS tutorial module\""
          }), ". Luego, guarda el archivo."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-meta-json-file-label-updated.png",
            alt: "module-meta-json-file-label-updated"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Antes de ejecutar el siguiente comando, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload"
      }), ", desglosemos el comando y los argumentos que necesita. Este comando tiene dos argumentos: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload <src> <dest>"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "src"
        }), ": la ruta relativa de la carpeta de origen que estás subiendo desde tu entorno local."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "dest"
        }), ": la ruta del directorio de destino en HubSpot, local respecto a la raíz del sistema de archivos de desarrollador. Puedes crear un nuevo directorio en la cuenta especificando el nombre del directorio, como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload <src> /new-directory"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Teniendo esto en cuenta, debido a que estás cargando en la raíz del sistema de archivos del desarrollador, ejecuta el siguiente comando:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "hs upload 'icon copy.module' 'icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Después de que la CLI confirme que el módulo se ha cargado correctamente, actualiza el administrador de diseño para ver tu cambio en HubSpot. Ahora deberías ver que el campo ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Etiqueta"
        }), " muestra tu nuevo valor."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-label-updated.png",
        alt: "module-label-updated"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Ejecutar un comando watch para cargar automáticamente los cambios"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora que has utilizado el comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), " para ejecutar una subida única de tus archivos locales, utilizarás el comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " para subir continuamente los cambios guardados. Este comando toma los mismos argumentos que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), ", por lo que puedes especificar el mismo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<src>"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<dest>"
      }), " que antes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ejecutar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch 'icon copy.module' 'icon copy.module'"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "hs watch 'icon copy.module' 'icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Con el comando watch en funcionamiento, los cambios guardados se cargarán automáticamente en la cuenta de HubSpot. A modo de demostración, haz el siguiente cambio local en el módulo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "meta.json"
        }), ", actualiza el campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "host_template_types"
        }), " para eliminar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"BLOG_LISTING\""
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"BLOG_POST\""
        }), " para que el módulo solo esté disponible en las páginas ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"host_template_types\"``:[\"PAGE\"]"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Luego, guarda el archivo. Esto debería hacer que la CLI cargue automáticamente el archivo en HubSpot."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Una vez subido el archivo, actualiza el administrador de diseño en HubSpot para ver el cambio. La sección ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Tipos de plantilla"
        }), " de la barra lateral derecha ahora solo debe incluir ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Página"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-editor-template-type.png",
        alt: "module-editor-template-type"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para finalizar el comando watch, presiona ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Control + C"
        }), ". Es importante tener en cuenta que no podrás ejecutar otros comandos en la misma ventana de terminal en la que se está ejecutando el comando watch. Para ejecutar otros comandos mientras ejecuta un watch, debes abrir otra ventana de terminal y ejecutar tus comandos allí."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pasos siguientes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ahora que has visto cómo usar los comandos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fetch"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), ", es aconsejable que consultes toda la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "guía de referencia de comandos de la CLI"
      }), " para saber qué más puedes hacer con la CLI."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "También se recomienda consultar los siguientes tutoriales:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Creación de un workflow de desarrollo eficiente"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/github-integration",
          children: "¿Cómo establecer una integración continua con GitHub?"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Primeros pasos a los módulos personalizados"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Primeros pasos para usar temas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "Primeros pasos con las áreas de arrastrar y soltar"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}