"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816620895;
const slug = exports.slug = 'guides/apps/marketplace/certification-requirements';
const title = exports.title = 'Mercado de aplicaciones | Requisitos de certificación de la aplicación';
const name = exports.name = 'LATAM (ES) App Certification Requirements';
const metaDescription = exports.metaDescription = 'Esto es lo que deben hacer los Partners de aplicaciones para obtener la certificación de la aplicación en el Mercado de aplicaciones de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "resumen",
  "label": "Resumen",
  "parent": null
}, {
  "depth": 0,
  "id": "beneficios-de-la-certificaci%C3%B3n-de-ganancias",
  "label": "Beneficios de la certificación de ganancias",
  "parent": null
}, {
  "depth": 0,
  "id": "criterios-de-revisi%C3%B3n",
  "label": "Criterios de revisión",
  "parent": null
}, {
  "depth": 1,
  "id": "seguridad-y-privacidad",
  "label": "Seguridad y privacidad",
  "parent": "criterios-de-revisi%C3%B3n"
}, {
  "depth": 2,
  "id": "ejemplos-de-feedback",
  "label": "Ejemplos de feedback",
  "parent": "criterios-de-revisi%C3%B3n"
}, {
  "depth": 1,
  "id": "confiabilidad-y-rendimiento",
  "label": "Confiabilidad y rendimiento",
  "parent": "criterios-de-revisi%C3%B3n"
}, {
  "depth": 2,
  "id": "ejemplos-de-comentarios",
  "label": "Ejemplos de comentarios",
  "parent": "criterios-de-revisi%C3%B3n"
}, {
  "depth": 1,
  "id": "usabilidad-y-accesibilidad-%7C-aplicaci%C3%B3n",
  "label": "Usabilidad y accesibilidad | Aplicación",
  "parent": "criterios-de-revisi%C3%B3n"
}, {
  "depth": 2,
  "id": "ejemplos-de-feedback",
  "label": "Ejemplos de feedback",
  "parent": "criterios-de-revisi%C3%B3n"
}, {
  "depth": 1,
  "id": "usabilidad-y-accesibilidad-%7C-anuncio-de-mercado-de-aplicaciones",
  "label": "Usabilidad y Accesibilidad | Anuncio de mercado de aplicaciones",
  "parent": "criterios-de-revisi%C3%B3n"
}, {
  "depth": 2,
  "id": "ejemplos-de-comentarios",
  "label": "Ejemplos de comentarios",
  "parent": "criterios-de-revisi%C3%B3n"
}, {
  "depth": 1,
  "id": "usabilidad-y-accesibilidad-%7C-documentaci%C3%B3n-de-asistencia-t%C3%A9cnica",
  "label": "Usabilidad y accesibilidad | Documentación de asistencia técnica",
  "parent": "criterios-de-revisi%C3%B3n"
}, {
  "depth": 2,
  "id": "ejemplos-de-feedback",
  "label": "Ejemplos de feedback",
  "parent": "criterios-de-revisi%C3%B3n"
}, {
  "depth": 1,
  "id": "valor",
  "label": "Valor",
  "parent": "criterios-de-revisi%C3%B3n"
}, {
  "depth": 2,
  "id": "ejemplos-de-feedback",
  "label": "Ejemplos de feedback",
  "parent": "criterios-de-revisi%C3%B3n"
}, {
  "depth": 0,
  "id": "el-proceso-de-revisi%C3%B3n-de-la-certificaci%C3%B3n-y-recertificaci%C3%B3n-de-la-aplicaci%C3%B3n",
  "label": "El proceso de revisión de la certificación y recertificación de la aplicación",
  "parent": null
}, {
  "depth": 0,
  "id": "preguntas-frecuentes",
  "label": "Preguntas frecuentes",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      h3: "h3",
      code: "code",
      h4: "h4",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Quote,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!Alert) _missingMdxReference("Alert", true);
  if (!Quote) _missingMdxReference("Quote", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Obtener certificación en el Mercado de aplicaciones"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La certificación de la aplicación implica que el equipo de Calidad del ecosistema de HubSpot revise y confirme que tu ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "aplicación anunciada"
      }), " cumple con los siguientes requisitos de seguridad, privacidad, confiabilidad, rendimiento, usabilidad, accesibilidad y valor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las aplicaciones certificadas se destacan en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "Mercado de aplicaciones"
      }), " con una reputación de calidad y confiabilidad. Tu aplicación también obtendrá ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#certification-benefits",
        children: "beneficios especiales"
      }), " y recibirá comentarios constructivos del equipo de Calidad del ecosistema de HubSpot durante la revisión de la certificación de la aplicación."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Estos requisitos están sujetos a cambios, ya que HubSpot realiza continuamente mejoras en el mercado de aplicaciones y el ecosistema. HubSpot puede rechazar la solicitud de certificación de una aplicación a su discreción si esta no cumple con los estándares establecidos."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubSpot ", (0, _jsxRuntime.jsx)("u", {
            children: "no"
          }), " revisará tu aplicación a menos que envíes un video de demostración, como se indica al ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/applying-for-app-certification",
            children: "solicitar la certificación de la aplicación"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Solo puedes enviar una aplicación a la vez para la certificación. Si envías más de una aplicación para la certificación al mismo tiempo, se rechazarán en función del orden de envío."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Resumen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación, encontrarás un resumen de los requisitos de certificación de aplicaciones. Para obtener más detalles, consulta la sección ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#review-criteria",
        children: "Criterios de revisión"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tu aplicación debe:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Estar asociada con un ID único de aplicación de HubSpot"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Tu aplicación pública anunciada debe ser única. Si ya has publicado una aplicación y quieres reemplazarla, debes actualizar la aplicación existente en lugar de publicar una nueva."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "No crees varias aplicaciones que resuelvan el mismo caso de uso. Las aplicaciones con funcionalidades similares y que utilizan las mismas API deben consolidarse en una sola aplicación."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Utilizar la ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "autentificación de OAuth"
          }), " y todos los alcances que requiere."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Estar asociada con un ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview",
            children: "dominio verificado"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Los materiales públicos asociados con tu aplicación deben cumplir con las mejores prácticas de seguridad."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#security-privacy",
        children: "lista detallada de requisitos de seguridad y privacidad"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["La actividad de la aplicación se define mediante solicitudes autenticadas por OAuth a las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "API"
        }), " de HubSpot y las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests",
          children: "solicitudes firmadas"
        }), " de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks/overview",
          children: "suscripciones y extensiones de webhook"
        }), " de HubSpot (por ejemplo, solicitudes de obtención de datos de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "tarjetas del CRM"
        }), ")."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Las instalaciones activas son la cantidad de cuentas de producción únicas de HubSpot, no afiliadas a tu organización, que muestran una actividad exitosa de la ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "aplicación"
        }), " en los últimos 30 días."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beneficios de la certificación de ganancias"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además de los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners/app",
        children: "beneficios de publicar tu aplicación"
      }), ", las aplicaciones certificadas reciben:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Una insignia de \"Aplicación certificada por HubSpot\", que se muestra en el anuncio del mercado de aplicaciones."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Visibilidad adicional en el Mercado de aplicaciones de HubSpot:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Inclusión en el filtro de búsqueda \"Aplicación certificada por HubSpot\"."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Elegibilidad para inclusión en las colecciones seleccionadas del mercado de aplicaciones."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Acceso a la insignia \"HubSpot Certified App\" e imágenes de redes sociales para compartir el logro de la certificación de la aplicación."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Consideración favorable en las iniciativas de asociación y amplificación de HubSpot."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criterios de revisión"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para obtener la certificación, tu aplicación debe demostrar calidad al cumplir con las medidas cuantitativas y los descriptores cualitativos de seguridad, privacidad, confiabilidad, rendimiento, usabilidad, accesibilidad y valor. Los requisitos a continuación están organizados por estas categorías e incluyen ejemplos de comentarios constructivos que puede recibir."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seguridad y privacidad"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tu aplicación debe:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Estar asociada con un ID único de aplicación de HubSpot. Tu aplicación debe autorizar las solicitudes de API con el ID público de aplicación de HubSpot (y el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "ID de cliente de OAuth"
        }), ") asociado con el anuncio de la aplicación.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Un anuncio no debe redireccionar hacia una aplicación pública o privada diferente."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "La aplicación pública que aparece en tu anuncio no debe requerir otra aplicación pública o privada para funcionar."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ser autenticada por el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "flujo de código de autorización de OAuth"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Está prohibido pedir a los usuarios que copien y peguen códigos o tokens de OAuth. Solo se debe pedir a los usuarios que otorguen acceso"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Las aplicaciones deben solicitar, administrar y actualizar los tokens de acceso sin la participación del usuario"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utiliza todos los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "alcances"
        }), " que solicite para la instalación (es decir, tanto en el parámetro de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), " requerido como en el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), ").", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ten activada la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "configuración avanzada del alcance"
            }), " y selecciona todos los alcances requeridos, condicionalmente requeridos y opcionales que la aplicación solicita para la instalación."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Los alcances irrelevantes deben eliminarse."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si ciertos alcances solo se aplican a un subconjunto de la base de usuarios de la aplicación, deben incluirse como alcances requeridos condicionalmente u opcionales."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Estar asociada con un ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "dominio verificado"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Tus materiales públicos serán evaluados en función de las mejores prácticas de seguridad relacionadas con software obsoleto y varias vulnerabilidades y hallazgos del servidor web."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ejemplos de feedback"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app currently requires four scopes: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contacts"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "timeline"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "forms"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), ". According to our logs, however, it only made requests to the CRM Contacts and Timeline Events APIs in the last 30 days. Since the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "forms"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), " scopes are not required for either of these functions, please remove them as required from the app’s settings to minimize the permissions users must accept."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Confiabilidad y rendimiento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tu aplicación debe:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Estar en buen estado, lo que significa:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Cumplir con todos los términos aplicables."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "No haber sido rechazada para la certificación en los últimos seis meses."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "No tener derivaciones de asistencia técnica sin resolver con clientes en común."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Utilizar versiones públicas y estables de las API y extensiones de HubSpot."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se recomienda el uso de las últimas versiones públicas."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Las API de vista previa de versión beta y de desarrollador sin documentar se consideran inestables y no se deben usar en tu aplicación de producción."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Nota: si tu aplicación utiliza API que no son estables ni públicas, indícalo durante la revisión de la certificación o al equipo del administrador de partners de aplicaciones para analizar las opciones."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Mantener un volumen razonable de ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#activity",
            children: "actividad"
          }), " en las cuentas de clientes de HubSpot no afiliadas a tu organización."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Tu aplicación debe cumplir con las ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/api-usage/usage-details",
            children: "pautas de uso de la API"
          }), " y las mejores prácticas, que incluyen:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Respeto de los límites de tasa (es decir, 100 solicitudes entrantes cada 10 segundos por cuenta conectada de HubSpot)."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Actualización de tokens de acceso de OAuth antes de que venzan."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Captación de datos de llamadas recurrentes cuando sea posible."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Uso de suscripciones por lotes de API y webhooks para reducir el volumen de solicitudes siempre que sea posible."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Usar API para crear propiedades, workflows y acciones de workflow personalizadas en lugar de solicitar acciones por parte del usuario."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Tu aplicación debe mantener una tasa de éxito media superior al ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "95%"
          }), " en todas las ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#activity",
            children: "actividades"
          })]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Las solicitudes que resultan en respuestas de error cuentan contra esta tasa de éxito."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Algunos errores inevitables o esperados pueden excluirse al calcular las tasas de éxito en todas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/marketplace/certification-requirements#activity",
              children: "las actividades"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Es posible que tu aplicación tenga una extensión de navegador para ofrecer funcionalidad y valor adicionales a los clientes:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Las extensiones del navegador no deben crearse específicamente para la interfaz de usuario de HubSpot o como una solución alternativa a las API de HubSpot."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Las extensiones del navegador no deben inyectar capacidades o componentes en la interfaz de usuario de HubSpot.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Las extensiones de la interfaz de usuario oficialmente compatibles (por ejemplo, ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "tarjetas de CRM"
                }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/automation/custom-workflow-actions",
                  children: "acciones de workflow personalizadas"
                }), ") proporcionan experiencias de usuario más consistentes para los clientes"]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Tu aplicación será sometida a una evaluación de seguridad adicional si incluyes una extensión de navegador."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ejemplos de comentarios"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app’s API success rate falls below the 95% threshold required for certification. Our logs show a 83% success rate in the last 30 days. The vast majority of these requests returned ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "429"
        }), " burst rate limit errors. To reduce this error rate, we recommend throttling requests to 100 requests per 10 seconds for each account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is generating errors around trying to update contacts using an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undefined"
        }), " email address, which will not work with this endpoint. Your app should skip these requests if a record does not have an email address."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is making requests with expired OAuth tokens and receiving ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "401"
        }), " errors before refreshing the token. To minimize these errors, we recommend that your app keep track of when tokens expire or refresh tokens before making requests. If you start seeing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "401"
        }), " errors for 100% of requests and are unable to refresh the access token, consider the app uninstalled and stop making requests for the account until a user re-authenticates your app."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is generating ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " errors from trying to use the Contact Lists API with Marketing Hub Free accounts, which do not have access to contact lists. If your app repeatedly gets ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " errors for missing the proper scopes, it should stop making calls to retrieve lists from that account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app’s webhook subscriptions frequently fail with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "500"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "503"
        }), " errors. Make sure that your server can handle the volume of requests (currently limited to 150 per second) so that customer data is not lost."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is pulling many contacts one at a time instead of pulling them in batches. We recommend using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "batch endpoints"
        }), " instead."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usabilidad y accesibilidad | Aplicación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Tu aplicación debe estar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
          children: "anunciada"
        }), " en el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps",
          children: "mercado de aplicaciones"
        }), " de HubSpot durante al menos seis meses."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Tu aplicación debe demostrar las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.nngroup.com/articles/ten-usability-heuristics/",
          children: "mejores prácticas en cuanto a la usabilidad"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "La instalación y el uso no deberían causar confusión o frustración a los clientes en común ni afectar negativamente la esencia de la experiencia de los usuarios con HubSpot."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ejemplos de feedback"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your app currently requires users to manually configure workflow webhook actions to send text messages. Consider creating custom workflow actions via the app which are flexible enough to accommodate many use cases."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usabilidad y Accesibilidad | Anuncio de mercado de aplicaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tu anuncio en el Mercado de aplicaciones debe:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Describir con precisión la funcionalidad actual de tu aplicación.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si la funcionalidad cambia según el producto de un usuario o el nivel de suscripción, ya sea para HubSpot o tu solución, se deben dejar claras las diferencias."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Contener información clara y precisa de precios, incluyendo un enlace a tu página de precios actual.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Si hay varios paquetes compatibles con tu aplicación, el anuncio del mercado de aplicaciones debe, como mínimo, incluir la opción menos costosa."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utiliza datos de marcadores de posición u oculta los datos para no mostrar información de identificación personal (PII)."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Incluir:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ayudas visuales informativas actualizadas, que pueden incluir capturas de pantalla o un video. Consulta la página ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/partners/apps/resources/how-to-make-a-great-app-demo-video",
              children: "Cómo crear un gran video de demostración de aplicaciones"
            }), " para conocer las mejores prácticas y ejemplos de cómo crear un video de demostración."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Una \"URL de documentación de configuración\" actualizada que contiene un subdominio y conduce directamente a la documentación de alojamiento de la página de tu aplicación. Este enlace no debe conducir a tu página principal."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No incluir:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Datos o estadísticas, a menos que se proporcione un caso de éxito como recurso."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ejemplos de comentarios"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your App Marketplace listing includes few specific details about your app’s functionality. Please enhance the listing with screenshots which depict app functionality and include more thorough descriptions of common use cases and in-app behavior."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot customers are used to a “try before you buy” experience when purchasing our products and services. For this reason, we recommend your app provide a free trial or freemium sign-up experience. Some app partners who do not have pricing pages or free trials have created “HubSpot plans,” offering mutual customers transparent pricing, touchless sign-up, and other benefits."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usabilidad y accesibilidad | Documentación de asistencia técnica"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La documentación de asistencia técnica para tu aplicación debe:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Existir en una URL activa, de acceso público (es decir, no se requiere ningún pago ni inicio de sesión) y cumplir con los estándares actuales de accesibilidad, privacidad y el RGPD."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Estar actualizada y corresponderse con la versión actual de tu aplicación."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Describir claramente:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Qué hace la aplicación."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Cómo instalar tu aplicación y conectar una cuenta de HubSpot con capturas de pantalla de cada paso, incluida la pantalla de aprobación del alcance."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Cómo configurar la aplicación una vez que esté instalada."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Cómo usar la aplicación, incluyendo interacciones manuales y automatizadas."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Cómo desconectar HubSpot de tu aplicación."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Cómo desinstalar tu aplicación de una cuenta de HubSpot."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Cómo desconectar y desinstalar podría afectar las cuentas y los datos de HubSpot de los usuarios."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Incluir imágenes. Cualquier imagen que contenga capturas de pantalla de la interfaz de usuario de HubSpot debe estar actualizada y ser acorde a nuestro ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://canvas.hubspot.com/",
          children: "sistema de diseño"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se recomiendan videos, pero no son obligatorios. Los videos deben actualizarse regularmente y reflejar la versión actual de tu aplicación."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ejemplos de feedback"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The setup guide for your app includes a screenshot depicting the scopes your app requires for installation. This screenshot does not show the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "business-intelligence"
        }), " scope, which is selected in your app’s settings. Please update the screenshot so that it reflects the current required scopes."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Valor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "El total de instalaciones activas, la retención y las revisiones del mercado de aplicaciones de HubSpot de tu aplicación se evalúan como indicadores del valor que los clientes en común encuentran en tu aplicación."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Tu aplicación debe tener al menos 60 instalaciones ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#activity",
              children: "activas"
            }), " y únicas para ser apta para la certificación y conservarla. Las cuentas con instalaciones no deben estar afiliadas a tu organización. También se excluirán las cuentas de prueba.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Si tu aplicación tiene menos de 60 instalaciones activas, se te solicitará que canceles la solicitud de certificación."
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Si tu aplicación tiene menos de las tres instalaciones activas necesarias para anunciarse, es posible que tu aplicación se elimine del mercado de aplicaciones."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "El anuncio de tu aplicación debe tener respuestas de tu equipo para cualquier evaluación negativa de tu aplicación."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Ejemplos de feedback"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your app has not maintained at least 60 active installs over the trailing six month period. As such, its certified status will be removed. You may re-apply for certification in six months."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "El proceso de revisión de la certificación y recertificación de la aplicación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El equipo de Calidad del ecosistema de HubSpot responde a las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "solicitudes de certificación de aplicaciones"
      }), " en un plazo de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "10 días laborables"
      }), ". Todo el proceso de revisión y comentarios de la aplicación no debe tardar más de ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "60 días"
      }), " desde el momento en que se comparten los comentarios. Revisa los criterios enumerados ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/testing-credentials",
        children: "aquí"
      }), " para proporcionar credenciales de prueba a tu aplicación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si tu aplicación cumple con todos los requisitos, obtendrá un estado certificado y se mostrará una insignia de \"Aplicación certificada por HubSpot\" a los clientes y prospectos en el Mercado de aplicaciones. Tu aplicación también aparecerá cuando los usuarios seleccionen el filtro «Aplicación certificada por HubSpot»."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si tu aplicación no completa la revisión correctamente, puedes volver a solicitarla en seis meses."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Después de haber sido certificada por un año, el equipo de Calidad del ecosistema de HubSpot revisará tu aplicación para asegurarse de que cumpla con los rigurosos requisitos de certificación. Si tu aplicación ya no cumple con estos estándares, el equipo colaborará contigo hasta 60 días para resolver tus inquietudes."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si en cualquier momento tu aplicación no cumple con los requisitos de certificación enumerados anteriormente, HubSpot podría iniciar inmediatamente un proceso de recertificación, incluso si tu aplicación ha estado certificada por menos de un año. Tal como se indica en el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/app-program-agreement",
          children: "Acuerdo del App Partner Program"
        }), ", HubSpot también se reserva el derecho de anular la publicación de tu aplicación en cualquier momento."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Preguntas frecuentes"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Are there any costs associated with app certification?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "No, we do not charge you a fee to list or certify your apps in the App Marketplace, nor a fee for installs generated through the App Marketplace. There is no revenue sharing. We are here to support you to make your app of higher quality."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Will I be notified when my app will be eligible again for certification if it was previously rejected?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No. At this time we do not have notifications enabled to notify you if and when you will be eligible to re-apply at this time. Your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " would be the best resource to contact and ask if you are eligible before applying."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "How should I promote my certified app to my customers?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Feel free to use the press release template on ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/resources",
          children: "this page"
        }), " to share the news that your app has earned certification."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "If you plan to post on social media, be sure to tag HubSpot — we love to celebrate alongside our app partners!"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "I enjoy experimenting with the newest beta APIs. Is app certification right for me?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["We recommend you reach out to your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " to see if app certification is right for your app."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Our goal is to ensure your app is well built for our mutual customers and limits breaking changes, which requires your app uses the latest stable APIs. We also love seeing and supporting entrepreneurs, early adopters, and developers who are eager to experiment with the newest beta APIs."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "I'd like my app to be featured on the App Marketplace. Is this what app certification is for?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The benefits of being featured in collections and for customers to easily filter for a certified app within the App Marketplace are continuing to evolve. We’d like to learn more about how you would find being featured the most helpful (e.g. App Marketplace, HubSpot community, HubSpot curated newsletters or other forms)."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " would be the best contact to discuss potential future benefits and start this conversation."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Why and how will my public assets be assessed for security best practices?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "With the average customer using more than five integrations, it’s imperative apps are monitored and held to privacy, security, and quality standards over time. Any public assets will be assessed using information already provided during a listing process and findings will be analyzed using a non-invasive method."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "My app was certified over a year ago. What do I have to do to keep my certification status and what does it mean to get recertified?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The HubSpot Ecosystem Quality team will reach out if your app is out of compliance or due for annual recertification with next steps."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["We encourage you to monitor your app’s performance, certification requirements, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "Developer Changelog"
        }), ", and any additional HubSpot resources related to any changes in technology used and how your app could stay up to date."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Encuentra tu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "mánager de partners de aplicaciones"
        }), " y su información de correo electrónico iniciando sesión en tu cuenta de desarrollador y navegando a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mercado de aplicaciones"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anuncios"
        }), ". Coloca el cursor sobre tu aplicación y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Más"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ver detalles del anuncio"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "Cómo solicitar la certificación de la aplicación"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "Cómo anunciar tu aplicación"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "Requisitos de anuncio de aplicación"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/APIs-Integrations/bd-p/integrations",
        children: "Foro de la comunidad de desarrolladores"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/contact-our-partnerships-team",
        children: "Ponte en contacto con el equipo de partners de aplicaciones"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}