"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694131;
const slug = exports.slug = 'guides/cms/content/themes/hubspot-cms-boilerplate';
const title = exports.title = 'Boilerplate CMS HubSpot';
const name = exports.name = '[nouveau] Le Boilerplate HubSpot CMS';
const metaDescription = exports.metaDescription = 'Le Boilerplate HubSpot CMS illustre les meilleures pratiques et aide les développeurs à créer rapidement un site Web sur HubSpot CMS. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "pourquoi-les-d%C3%A9veloppeurs-devraient-ils-utiliser-le-boilerplate-hubspot-cms%C2%A0%3F",
  "label": "Pourquoi les développeurs devraient-ils utiliser le Boilerplate HubSpot CMS ?",
  "parent": null
}, {
  "depth": 0,
  "id": "comment-utiliser-le-boilerplate-hubspot-cms%C2%A0%3F",
  "label": "Comment utiliser le Boilerplate HubSpot CMS ?",
  "parent": null
}, {
  "depth": 0,
  "id": "structure-du-boilerplate-hubspot-cms",
  "label": "Structure du Boilerplate HubSpot CMS",
  "parent": null
}, {
  "depth": 0,
  "id": "jquery",
  "label": "jQuery",
  "parent": null
}, {
  "depth": 0,
  "id": "ressources-associ%C3%A9es",
  "label": "Ressources associées",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      strong: "strong",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      HubspotVideoPlayer
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Boilerplate CMS HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 9,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate",
              children: "Le boilerplate HubSpot CMS"
            }), " sert de point de départ pour aider les développeurs à concevoir et publier rapidement un site Web sur HubSpot CMS tout en illustrant les meilleures pratiques applicables en termes de développement sur la plateforme HubSpot CMS. Ce boilerplate est un projet GitHub open source. Chacun peut suggérer des modifications ou s'en servir pour son propre usage. Si vous n'avez jamais utilisé HubSpot CMS et que vous souhaitez démarrer un nouveau projet sur la base du boilerplate, suivez le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/quickstart",
              children: "guide de démarrage rapide du développement sur HubSpot CMS"
            }), "."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 3,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate/subscription",
              children: "Consulter"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate/fork",
              children: "Fourchette"
            }), " ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://github.com/HubSpot/cms-theme-boilerplate",
              children: "Étoile"
            })]
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pourquoi les développeurs devraient-ils utiliser le Boilerplate HubSpot CMS ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le Boilerplate HubSpot CMS est développé et activement maintenu par HubSpot. Lors de l'élaboration du boilerplate, nous avons incorporé les meilleures pratiques influencées par la manière dont les développeurs ont créé la meilleure expérience de création de sites Web, puis nous les avons appliquées à la création d'un site Web sur HubSpot CMS. Les développeurs disposent ainsi d'un site Web propre, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "performant"
      }), " et prêt à être modifié, ce qui leur fait gagner un temps considérable de développement. Le boilerplate fournit également une CSS complète pour les ressources associées à HubSpot, telles que les formulaires, les modules de menu, les classes de base, etc. Retrouvez une démonstration en direct du boilerplate sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/",
        children: "https://boilerplate.hubspotcms.com/"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comment utiliser le Boilerplate HubSpot CMS ?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour commencer à utiliser le boilerplate dans votre environnement de développement local, suivez simplement notre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart",
        children: "guide « Développer des sites Web sur HubSpot CMS »."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Structure du Boilerplate HubSpot CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Les développeurs peuvent travailler avec le boilerplate à l'aide d'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/getting-started-with-local-development",
              children: "outils de développement locaux"
            }), " ou directement dans le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/cos-general/get-started-with-the-design-manager",
              children: "Gestionnaire de conception."
            }), " Ce boilerplate utilise des références de chemin d'accès relatives pour toutes les ressources, ce qui permet de l'adapter facilement à votre projet, quel qu'il soit. Cela permet également de rendre le boilerplate complètement transférable entre les comptes de la plateforme HubSpot. Vous trouverez ci-dessous une capture d'écran de la structure du dossier des ressources du boilerplate. Le boilerplate répartit ses ressources dans plusieurs répertoires pour les retrouver facilement."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172844633478"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/boilerplate/boilerplate-structure-tree-view-4.png",
        alt: "Structure des dossiers du Boilerplate du HubSpot CMS"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La structure sous-jacente du boilerplate du CMS Hub s'articule autour d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "mise en page de base commune"
      }), ", située dans le dossier ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "modèles > mises en page"
      }), ", qui est ensuite étendue avec la balise ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% extends %}"
      }), " et en faisant référence au bloc ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% block body %}"
      }), " pour son contenu principal. Un exemple de l'utilisation de la balise extend et des blocs peut être visualisé dans ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "n'importe quel fichier HTML du répertoire des modèles."
      }), " Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview#blocks-and-extends",
        children: "blocs et extensions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il s'agit d'une méthode courante de développement sur les systèmes CMS où vous disposez d'un modèle de base (parfois appelé modèle principal/parent) qui contient tous les principaux éléments structurels communs du contenu de votre site. Il s'agit souvent d'éléments qui se trouvent à l'intérieur de l'élément", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " sur votre site, comme les méta-propriétés communes (ex. : Title et Meta Description), les liens Favicon, les liens CSS et les scripts tiers."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html lang=\"{{ html_lang }}\" {{ html_lang_dir }}>\n <head>\n   <meta charset=\"utf-8\">\n   <title>{{ page_meta.html_title }}</title>\n   {% if site_settings.favicon_src %}<link rel=\"shortcut icon\" href=\"{{ site_settings.favicon_src }}\" />{% endif %}\n   <meta name=\"description\" content=\"{{ page_meta.meta_description }}\">\n   {{ require_css(get_asset_url(\"../../css/layout.css\")) }}\n   {{ require_css(get_asset_url(\"../../css/main.css\")) }}\n   {{ require_css(\"https://fonts.googleapis.com/css?family=Merriweather:400,700|Lato:400,700&display=swap\") }}\n   {{ require_js(get_asset_url(\"../../js/main.js\")) }}\n   {{ standard_header_includes }}\n </head>\n <body>\n   <div class=\"body-wrapper {{ builtin_body_classes }}\">\n     {% block header %}\n       {% global_partial path=\"../partials/header.html\" %}\n     {% endblock header %}\n\n     {% block body %}\n       <!-- Nothing to see here -->\n     {% endblock body %}\n\n     {% global_partial path=\"../partials/footer.html\" %}\n   </div>\n   {{ standard_footer_includes }}\n </body>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À l'intérieur de cette mise en page de base, il y a également des appels à nos partials globaux d'en-tête et de pied de page. Cela nous permet de conserver le code de ces partials dans des fichiers distincts pour des raisons de modularité et, comme il s'agit de partials globaux, ils peuvent être facilement modifiés par vos créateurs de contenu à l'aide de notre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "éditeur de contenu global"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur les ressources incluses dans le modèle, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/wiki",
        children: "consultez le wiki du boilerplate sur GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Le boilerplate du thème HubSpot n'a pas besoin de jQuery pour pouvoir fonctionner"
      }), ". Pour les anciens comptes jQuery, HubSpot est chargé par défaut. Sur les comptes HubSpot les plus récents, jQuery est désactivé par défaut."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Historiquement, les scripts HubSpot avaient besoin de jQuery pour pouvoir fonctionner correctement. Le paramètre de domaine était donc là pour assurer la compatibilité. Les scripts HubSpot n'utilisent plus jQuery. Comme JQuery n'est pas nécessaire et qu'il existe de meilleures façons pour les développeurs d'inclure des bibliothèques qui fonctionnent également avec le contrôle de la source. Il est conseillé de désactiver les paramètres jQuery pour les nouveaux sites Web."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Faites attention si vous désactivez jQuery sur un domaine qui a un site Web existant - toutes les pages de destination ou les pages Web existantes que vous pouvez avoir pourraient être cassées si elles sont liées à jQuery."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Si vous souhaitez utiliser jQuery sur votre nouveau site Web, il est recommandé d'utiliser la dernière version de jQuery."
      }), " Il y a deux façons simples de le faire :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Téléchargez la dernière version de jQuery dans le système de fichiers de votre développeur et utilisez la commande ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " pour le charger où et quand vous en avez besoin."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utilisez un réseau de diffusion de contenu de confiance et ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_js"
          })
        }), " pour charger jQuery où et quand vous en avez besoin."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ressources associées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Premiers pas avec les thèmes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Comment optimiser les performances de votre site CMS Hub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Premiers pas avec l'accessibilité"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}