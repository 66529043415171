"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279875;
const slug = exports.slug = 'guides/cms/content/memberships/overview';
const title = exports.title = 'Zugriffsberechtigungen anhand von Mitgliedschaften';
const name = exports.name = 'EMEA DE Memberships';
const metaDescription = exports.metaDescription = 'Wenn Sie Zugriffsberechtigungen anhand von Mitgliedschaften verwenden, müssen Website-Besucher über ein Konto verfügen und sich dort anmelden, um bestimmte Inhalte aufzurufen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "so-erhalten-benutzer-zugriffsberechtigungen-anhand-von-mitgliedschaften",
  "label": "So erhalten Benutzer Zugriffsberechtigungen anhand von Mitgliedschaften",
  "parent": null
}, {
  "depth": 0,
  "id": "mitgliedschafts-hubl-variablen",
  "label": "Mitgliedschafts-HubL-Variablen",
  "parent": null
}, {
  "depth": 0,
  "id": "crm-objekt-hubl-funktionen",
  "label": "CRM-Objekt-HubL-Funktionen",
  "parent": null
}, {
  "depth": 0,
  "id": "registrieren%2C-anmelden-und-abmelden",
  "label": "Registrieren, anmelden und abmelden",
  "parent": null
}, {
  "depth": 0,
  "id": "mitgliedschaftsvorlagen",
  "label": "Mitgliedschaftsvorlagen",
  "parent": null
}, {
  "depth": 0,
  "id": "audit-protokollierung-f%C3%BCr-mitgliedschaften",
  "label": "Audit-Protokollierung für Mitgliedschaften",
  "parent": null
}, {
  "depth": 0,
  "id": "sso-f%C3%BCr-mitgliedschaften",
  "label": "SSO für Mitgliedschaften",
  "parent": null
}, {
  "depth": 1,
  "id": "social-media-logins",
  "label": "Social-Media-Logins",
  "parent": "sso-f%C3%BCr-mitgliedschaften"
}, {
  "depth": 0,
  "id": "artikel-und-ressourcen-im-zusammenhang-zur-mitgliedschaftsfunktion",
  "label": "Artikel und Ressourcen im Zusammenhang zur Mitgliedschaftsfunktion",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Zugriffsberechtigungen anhand von Mitgliedschaften"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie Zugriffsberechtigungen anhand von Mitgliedschaften verwenden, müssen Website-Besucher über ein Konto verfügen und sich dort anmelden, um bestimmte Inhalte aufzurufen. Das kontobasierte System nutzt das HubSpot CRM und CRM-Listen und bietet Besuchern die Möglichkeit, ein Passwort für ihr Konto einzurichten. Marketer können mit dieser Funktion ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cms-pages-editor/control-audience-access-to-pages",
        children: "Seiten erstellen, auf die Kontakte nur dann zugreifen können, wenn sie auf einer bestimmten CRM-Liste stehen"
      }), ". Mit Zugriffsberechtigungen anhand von Mitgliedschaften können Sie auch den Zugriff auf ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cms-pages-editor/control-audience-access-to-pages#set-up-membership-registration-for-your-knowledge-base",
        children: "Wissensdatenbank-Artikel"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cms-pages-editor/control-audience-access-to-pages#set-up-membership-registration-for-your-blog",
        children: "Blogs"
      }), " einschränken."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "So erhalten Benutzer Zugriffsberechtigungen anhand von Mitgliedschaften"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Kontakten Zugriff auf Inhalte gewährt wird, was beim Beitritt zu Listen oder durch manuelle Zuweisung geschehen kann, erhalten sie eine E-Mail, um sich für Ihre Website zu registrieren. Dort müssen sie ein Passwort festlegen, um auf die Inhalte zuzugreifen, für die sie eine Zugriffsberechtigung haben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Stellen Sie sich ein Fitnessstudio vor, bei dem sich Besucher für Kurse registrieren können und sich die Kurse ansehen können sollen, für die sie sich registriert haben. Wenn sich ein Besucher für einen Kurs anmeldet, erstellt die Formulareinsendung einen Kontakt im HubSpot CRM und dieser Kontakt wird basierend auf der Formulareinsendung zu einer Liste hinzugefügt, anhand der Zugriff auf eine „Meine Veranstaltungen“-Seite gewährt wird."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/signup.gif",
        alt: "Ein Beispiel für die Registrierung bei einem Fitnessstudio."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der Besucher erhält eine E-Mail für die Registrierung einer Mitgliedschaft, mit der er ein Passwort für sein Mitgliedschaftskonto erstellen kann."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/register%20account.png",
        alt: "Registrierungsformular"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn sich nun die Besucher bei ihrem Konto anmelden, kann sich der Benutzer auf der privaten „Meine Veranstaltungen“-Seite mit der von ihm festgelegten E-Mail-Adresse und dem Passwort anmelden. Da der Besucher angemeldet ist, kann der Entwickler, der den privaten Content erstellt hat, mithilfe von Daten aus dem CRM Daten über den angemeldeten Kontakt rendern."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/my_events.gif",
        alt: "Ein Besucher nutzt sein Konto, um sich anzumelden und sich die Kurse anzusehen, für die er sich registriert hat."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mitgliedschafts-HubL-Variablen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Für einige Unternehmen kann es sinnvoll sein, unterschiedliche Inhalte anzuzeigen, je nachdem, ob ein Benutzer angemeldet ist oder nicht. Es gibt HubL-Variablen, mit denen Entwickler überprüfen können, ob ein Kontakt derzeit auf einer Website angemeldet ist."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die HubL-Variable ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#website-pages-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact.is_logged_in"
        })
      }), " gibt an, ob der aktuelle Besucher über Mitgliedschaften auf der Website angemeldet ist. Sie kann innerhalb einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "-Anweisung verwendet werden, um bestimmte Inhalte bedingt wiederzugeben, sodass Sie das Erlebnis Ihres Besuchers individuell gestalten können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request_contact.is_logged_in %}\n    You're signed in!\n{% else %}\n    <a href=\"/_hcms/mem/login\">Log In</a>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie basierend auf der Listenmitgliedschaft unterschiedliche Inhalte auf derselben Seite anzeigen möchten, können Sie die Listenmitgliedschaften der angemeldeten Kontakte mithilfe der HubL-Variablen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#website-pages-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact.list_memberships"
        })
      }), " überprüfen, die ein Dictionary der Listen-IDs zurückgibt, in denen der angemeldete Kontakt Mitglied ist."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Um Inhalte zu personalisieren, ohne Mitgliedschaften zu verwenden, können Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/variables#general-variables",
          children: "Kontaktvariable"
        }), " verwenden, wenn ein Besucher ein Formular auf Ihrer Website eingesendet hat."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRM-Objekt-HubL-Funktionen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Zusätzlich zu den allgemeinen Inhalten, die bedingt auf einer Seite angezeigt werden, ist es möglich, Informationen zu Objekten innerhalb Ihres HubSpot-Accounts wie Kontakte/Firmen/Angebote/Produkte mithilfe folgender Funktionen abzurufen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-associations",
          children: "CRM-Zuordnungen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-object",
          children: "CRM-Objekt"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-objects",
          children: "CRM-Objekte"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aus Sicherheitsgründen können nur Produkt- und Marketingevent-Objekte auf einer öffentlich zugänglichen Seite abgerufen werden; für Informationen zu anderen Objekttypen muss eine Seite nur per Mitgliedschaft zugänglich sein."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request_contact.is_logged_in %}\n  {% set membership_contact = crm_object('contact', request.contact.contact_vid, 'firstname,lastname') %}\n  Welcome back, {{ membership_contact.firstname }} {{ membership_contact.lastname }}\n{% else %}\n    <a href=\"/_hcms/mem/login\">Log In</a>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Registrieren, anmelden und abmelden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn einem Kontakt anhand einer Mitgliedschaft Zugriff auf Inhalte auf Ihrer Website gewährt wird, erhält er eine E-Mail, um sich für Ihre Website zu registrieren. Dort muss er dann ein Passwort festlegen, um die Inhalte aufzurufen, zu deren Anzeige er berechtigt ist. Falls ein Kontakt einen neuen Link zum Registrieren benötigt, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/de/cms-pages-editor/control-audience-access-to-pages#resend-the-registration-email-to-a-specific-contact",
        children: "dessen Registrierungs-E-Mail erneut senden"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die URL-Pfade für die Benutzeranmeldung/-abmeldung sind für alle HubSpot-CMS-Websites mit der Mitgliedschaftsfunktion konsistent."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "<your domain>/_hcms/mem/login"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "<your domain>/_hcms/mem/logout"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn sich ein Besucher bei seiner Website anmeldet, wird ein Cookie an seinen Browser gesendet. Dies ermöglicht ihm, Ihre Website zu durchsuchen und Seiten aufzurufen, auf die er anhand von Mitgliedschaftsberechtigungen zugreifen kann, ohne sich erneut anmelden zu müssen. Wenn sich ein Besucher abmeldet oder noch nie in seinem Browser bei Ihrer Website angemeldet hat, wird er aufgefordert, sich anzumelden, bevor er den Inhalt anzeigen kann."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mitgliedschaftsvorlagen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Websites mit Mitgliedschaften haben einige spezielle Seiten, die benötigt werden, um die Funktionalität der Mitgliedschaften zu erleichtern. Diese werden durch spezielle Systemvorlagen vorgegeben. Diese Systemvorlagen sind bearbeitbar, sodass Sie das Erscheinungsbild der verschiedenen Mitgliedschaftsschritte selbst bestimmen können. Um festzulegen, welche Vorlagen Sie verwenden, gehen Sie zu ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "„Einstellungen“ >"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/general",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Privater Content“"
        })
      }), " und wählen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/templates",
        children: "Registerkarte „Vorlagen“"
      }), " aus. Um eine Vorlage zu erstellen, die in diesen Einstellungen festgelegt werden soll, gehen Sie zu ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "„Marketing“ > „Dateien und Vorlagen“ > „Design-Tools“"
      }), ". Klicken Sie dann oben links auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "„Datei“ > „Neue Datei“ > „HTML & HUBL“"
      }), " und wählen Sie den entsprechenden Vorlagentyp im Dropdown-Menü aus."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine vollständige Liste der Mitgliedschaftsvorlagen finden Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#membership",
        children: "Abschnitt über Mitgliedschaft in der Dokumentation zu Vorlagen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Nur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML + HubL-Vorlagen"
        }), " können Mitgliedschaftsvorlagen sein."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Audit-Protokollierung für Mitgliedschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Unter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/general",
        children: "„Einstellungen“ > „Privater Content“"
      }), " können Sie ein Audit-Protokoll anzeigen, welche Besucher mit Inhalten hinter Mitgliedschaften interagiert haben. Dadurch können Sie sehen, welche Besucher private Inhalte ansehen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-02-22%20at%2012.24.54%20PM.png",
        alt: "Protokoll der Anzeige von privatem Content"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SSO für Mitgliedschaften"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können auch alle Zugriffsberechtigungen und Authentifizierungsanforderungen Ihres Unternehmens mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/sso",
        children: "Single-Sign-On (SSO) für Mitgliedschaften"
      }), " in einem einzigen System verwalten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Social-Media-Logins"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Benutzern in Ihrer Liste eine Möglichkeit bieten, sich mit Google oder Facebook anzumelden, anstatt ihre E-Mail-Adresse und ihr Passwort einzugeben. Der Social-Login-Anbieter sendet die E-Mail-Adresse, die dem angemeldeten Social-Media-Konto zugeordnet ist. Anhand dieser E-Mail-Adresse wird dann überprüft, ob sich dieser Kontakt in einer Kontaktliste mit Zugriff auf den Inhalt befindet. Für diese Funktion müssen keine SSO-Einstellungen konfiguriert sein."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie müssen eine Seite auf „Privat – Registrierung erforderlich“ mit einer Kontaktliste festgelegt haben. Darüber hinaus muss Ihre Anmeldevorlage über das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "membership_social_logins"
      }), "-Modul verfügen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/social",
        children: "Social-Media-Login zu Ihren Mitgliedschaftsseiten hinzufügen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Artikel und Ressourcen im Zusammenhang zur Mitgliedschaftsfunktion"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/essentials-for-getting-started-with-memberships",
          children: "HubSpot-Grundlagen für Entwickler: Erste Schritte mit Mitgliedschaften"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/creating-a-header-nav-that-adapts-to-if-the-contact-is-logged-in",
          children: "Erstellen von Menüs, die sich daran anpassen, ob der Benutzer angemeldet ist oder nicht"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}