export let PlayerStatus;
(function (PlayerStatus) {
  PlayerStatus["INITIAL"] = "INITIAL";
  PlayerStatus["LOADING"] = "LOADING";
  PlayerStatus["READY"] = "READY";
  PlayerStatus["PLAYING"] = "PLAYING";
  PlayerStatus["PAUSED"] = "PAUSED";
  PlayerStatus["ENDED"] = "ENDED";
  PlayerStatus["CONVERSION_ASSET_OVERLAY"] = "CONVERSION_ASSET_OVERLAY";
  PlayerStatus["ERROR"] = "ERROR";
})(PlayerStatus || (PlayerStatus = {}));