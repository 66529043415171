"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31196331913;
const slug = exports.slug = 'guides/api/crm/imports';
const title = exports.title = 'API de CRM | Importaciones';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Imports';
const metaDescription = exports.metaDescription = 'Las importaciones se utilizan para rellenar una cuenta de HubSpot con datos de objetos que se pueden usar con las herramientas de ventas, marketing y servicio.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "iniciar-una-importaci%C3%B3n",
  "label": "Iniciar una importación",
  "parent": null
}, {
  "depth": 1,
  "id": "formatear-los-datos-de-importrequest",
  "label": "Formatear los datos de importRequest",
  "parent": "iniciar-una-importaci%C3%B3n"
}, {
  "depth": 1,
  "id": "asignar-columnas-de-archivo-a-propiedades-de-hubspot",
  "label": "Asignar columnas de archivo a propiedades de HubSpot",
  "parent": "iniciar-una-importaci%C3%B3n"
}, {
  "depth": 0,
  "id": "importar-un-archivo-con-un-objeto",
  "label": "Importar un archivo con un objeto",
  "parent": null
}, {
  "depth": 0,
  "id": "importar-un-archivo-con-varios-objetos",
  "label": "Importar un archivo con varios objetos",
  "parent": null
}, {
  "depth": 0,
  "id": "importar-varios-archivos",
  "label": "Importar varios archivos",
  "parent": null
}, {
  "depth": 0,
  "id": "obtener-importaciones-anteriores",
  "label": "Obtener importaciones anteriores",
  "parent": null
}, {
  "depth": 0,
  "id": "cancel-an-import",
  "label": "Cancel an import",
  "parent": null
}, {
  "depth": 0,
  "id": "view-and-troubleshoot-import-errors",
  "label": "View and troubleshoot import errors",
  "parent": null
}, {
  "depth": 0,
  "id": "l%C3%ADmites",
  "label": "Límites",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      img: "img",
      h3: "h3",
      strong: "strong",
      em: "em",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Importaciones"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza la API de importaciones para importar registros y actividades de CRM en tu cuenta de HubSpot, como contactos, empresas y notas. Una vez importado, puedes acceder y actualizar registros y actividades a través de los diversos puntos de terminación de la API de CRM, incluida la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts",
        children: "API de contactos,"
      }), " la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de asociaciones"
      }), " y las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/engagements/engagement-details",
        children: "API de compromisos."
      }), " También puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/crm-setup/import-objects",
        children: "importar registros y actividades usando la herramienta de importación guiada en HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Antes de iniciar la importación, obtén más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/crm-setup/set-up-your-import-file",
        children: "objetos y las actividades que se pueden importar, así como los requisitos de archivos y propiedades."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Iniciar una importación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes iniciar una importación realizando una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports"
      }), " con un cuerpo de solicitud que especifique cómo asignar las columnas de su archivo de importación a las propiedades de CRM asociadas en HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las importaciones de API se envían como solicitudes de tipo de datos de formulario, con el cuerpo de la solicitud que contiene los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-the-importrequest-data",
          children: "importRequest"
        }), ": un campo de texto que contiene la solicitud JSON."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#map-file-columns-to-hubspot-properties",
          children: "files"
        }), ": un campo de archivo que contiene el archivo de importación."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para el encabezado de solicitud, agrega un encabezado ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Content-Type"
      }), " con un valor de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "multipart/form-data"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La siguiente captura de pantalla muestra cómo podría verse tu solicitud cuando usas una aplicación como Postman:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/postman-import-request-no-response0.png",
        alt: "postman-import-request-no-response0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Formatear los datos de importRequest"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En la solicitud JSON, define los detalles del archivo de importación, incluida la asignación de las columnas de la hoja de cálculo a los datos de HubSpot. Tu solicitud JSON debe incluir los siguientes campos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), ": el nombre del campo. En HubSpot, este es el nombre que ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/es/crm-setup/view-and-analyze-previous-imports",
            children: "se muestra en la herramienta de importaciones"
          }), ", así como el nombre al que puedes hacer referencia en otras herramientas, como listas."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "importOperations"
          }), ": un campo opcional utilizado para indicar si la importación debe ", (0, _jsxRuntime.jsxs)(_components.a, {
            href: "https://knowledge.hubspot.com/es/crm-setup/import-objects#choose-how-to-import",
            children: ["crear y actualizar,", (0, _jsxRuntime.jsx)("u", {
              children: "solo"
            }), " crear o ", (0, _jsxRuntime.jsx)("u", {
              children: "solo"
            }), " actualizar registros"]
          }), " para un determinado objeto o actividad. Incluye el ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "objectTypeId"
          }), " para el objeto/actividad y si deseas ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPSERT"
          }), " (crear y actualizar), ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CREATE"
          }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPDATE"
          }), " registros. Por ejemplo, el campo se vería así en tu solicitud: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"importOperations\": {\"0-1\": \"CREATE\"}"
          }), ". Si no incluyes este campo, el valor predeterminado utilizado para la importación es ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "UPSERT"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "dateFormat"
          }), ": el formato para las fechas incluidas en el archivo. Por opción predeterminada, esto se establece en ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "MONTH_DAY_YEAR"
          }), ", pero también puedes usar ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "DAY_MONTH_YEAR"
          }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "YEAR_MONTH_DAY"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "marketableContactImport"
          }), ": un campo opcional para indicar el ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/es/contacts/marketing-contacts",
            children: "estado de marketing"
          }), " de los contactos en tu archivo de importación. Esto se usa solo cuando se importan contactos a cuentas que tienen ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/es/contacts/marketing-contacts#check-your-access-to-marketing-contacts",
            children: "acceso a contactos de marketing"
          }), ". Para establecer los contactos en el archivo como marketing, usa el valor ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), ". Para establecer los contactos en el archivo como no marketing, usa el valor ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "false"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "createContactListFromImport"
          }), ": un campo opcional para ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/es/crm-setup/import-objects#create-list-from-import",
            children: "crear una lista estática de los contactos"
          }), " de tu importación. Para crear una lista a partir de tu archivo, usa el valor ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "true"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "files"
          }), ": una matriz que contiene la información del archivo de importación."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileName"
            }), ": el nombre del archivo de importación."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileFormat:"
            }), " el formato del archivo de importación. Para los archivos CSV, usa un valor de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CSV"
            }), ". Para los archivos de Excel, usa un valor de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SPREADSHEET"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "fileImportPage"
            }), ": contiene la matriz ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnMappings"
            }), " necesaria para asignar datos de tu archivo de importación a datos de HubSpot. Más información sobre el mapeo de columnas a continuación."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asignar columnas de archivo a propiedades de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dentro de la matriz ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "columnMappings"
      }), ", incluye una entrada para cada columna en tu archivo de importación, que coincida con el orden de tu hoja de cálculo. Para cada columna, incluye los siguientes campos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "columnObjectTypeId:"
        }), " el nombre o el valor ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), " del objeto o la actividad a la que pertenecen los datos. Consulta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "este artículo"
        }), " para obtener una lista completa de valores ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "columnName:"
        }), " el nombre del título de la columna."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "propertyName:"
        }), " el nombre interno de la propiedad de HubSpot a la que se asignarán los datos. Para la columna común en las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "importaciones de varios archivos"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyName"
        }), " debe ser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " cuando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "se utiliza el campo"
        }), " toColumnObjectTypeId."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "columnType:"
        }), " se utiliza para especificar que una columna contiene una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/es/crm-setup/set-up-your-import-file#:~:text=Name%2C%20Email%20Address",
          children: "propiedad de identificador único"
        }), ".-,Unique%20identifier,-%3A%20a%20property). Dependiendo de la propiedad y el objetivo de la importación, puedes utilizar uno de los siguientes valores:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "HUBSPOT_OBJECT_ID:"
            }), " el ID de un registro. Por ejemplo, tu archivo de importación de contactos puede contener una columna ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "ID de registro"
            }), " que almacena el ID de la empresa con la que deseas asociar los contactos."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "HUBSPOT_ALTERNATE_ID:"
            }), " un identificador único distinto del ID de registro. Por ejemplo, el archivo de importación de contactos puede contener una columna ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Correo electrónico"
            }), " que almacena las direcciones de correo electrónico de los contactos."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "FLEXIBLE_ASSOCIATION_LABEL"
            }), ": incluye este tipo de columna para indicar que la columna contiene etiquetas de asociación."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "ASSOCIATION_KEYS"
            }), ": solo para las importaciones de asociación del mismo objeto, incluye este tipo de columna para el identificador único de los mismos registros de objeto que estás asociando. Por ejemplo, en tu solicitud de importación de una asociación de contactos, la columna ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Contacto asociado [email/Record ID]"
            }), " debe tener un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "columnType"
            }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ASSOCIATION_KEYS"
            }), ". Obtén más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/es/crm-setup/set-up-your-import-file#same-object-association-imports",
              children: "configurar tu archivo de importación para una misma importación de asociación de objetos"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "toColumnObjectTypeId"
        }), ": para importaciones de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "varios archivos"
        }), " o varios objetos, el nombre o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), " del objeto al que pertenece la propiedad de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/set-up-your-import-file#sample-import-files:~:text=To%20import%20and%20associate%20multiple%20objects%20in%20one%20file%2C%20include%20information%20about%20associated%20records%20and/or%20activities%20in%20the%20same%20row.%20In%20two%20files%2C%20use%20a%20common%20column%20to%20connect%20the%20records%20in%20each%20file.%20You%20can%20refer%20to%20the%20example%20files%20for%20more%20help%20importing%20and%20associating%20records.",
          children: "columna común"
        }), " o la etiqueta de asociación. Incluye este campo para la propiedad de columna común en el archivo del objeto al que ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " pertenece la propiedad. Por ejemplo, si estás asociando contactos y empresas en dos archivos con la propiedad de contacto ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Correo electrónico"
        }), " como columna común, incluye el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "toColumnObjectTypeId"
        }), " para la columna ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Correo electrónico"
        }), " en el archivo de ", (0, _jsxRuntime.jsx)("u", {
          children: "empresa"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "foreignKeyType"
        }), ": solo para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "importaciones de varios archivos"
        }), ", el tipo de asociación que debe usar la columna común, especificado por ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationCategory"
        }), ". Incluye este campo para la propiedad de columna común en el archivo del objeto al que ", (0, _jsxRuntime.jsx)("u", {
          children: "no"
        }), " pertenece la propiedad. Por ejemplo, si estás asociando contactos y empresas en dos archivos con la propiedad de contacto ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Correo electrónico"
        }), " como columna común, incluye el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "foreignKeyType"
        }), " para la columna ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Correo electrónico"
        }), " en el archivo de ", (0, _jsxRuntime.jsx)("u", {
          children: "empresa"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "associationIdentifierColumn"
        }), ": solo para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#import-multiple-files",
          children: "importaciones de varios archivos"
        }), ", indica la propiedad utilizada en la columna común para asociar los registros. Incluye este campo para la propiedad de columna común en el archivo del objeto al que pertenece la propiedad. Por ejemplo, si estás asociando contactos y empresas en dos archivos con la propiedad de contacto ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Correo electrónico"
        }), " como columna común, establece la ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationIdentifierColumn"
        }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), " para la columna ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Correo electrónico"
        }), " en el archivo de ", (0, _jsxRuntime.jsx)("u", {
          children: "contacto"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Importar un archivo con un objeto"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, se muestra un cuerpo de solicitud de ejemplo de importación para crear contactos:"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"Nov-event-leads.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "# This example a local file named 'test_import.csv'\n# This file contains a list of contact records to import.\n\nimport requests\nimport json\nimport os\n\nurl = \"https://api.hubapi.com/crm/v3/imports\"\n\nYOUR_ACCESS_TOKEN = 'xxxxxxx';\n\n# Content-Type header will be set automatically by the requests library\nheaders = {\n  'authorization': 'Bearer %s' % YOUR_ACCESS_TOKEN\n}\n\ndata = {\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"Nov-event-leads.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": True,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\n\ndatastring = json.dumps(data)\n\npayload = {\"importRequest\": datastring}\n\ncurrent_dir = os.path.dirname(__file__)\nrelative_path = \"./test_import.csv\"\n\nabsolute_file_path = os.path.join(current_dir, relative_path)\n\nfiles = [\n    ('files', open(absolute_file_path, 'rb'))\n]\nprint(files)\n\nresponse = requests.request(\"POST\", url, data=payload, files=files, headers=headers)\n\nprint(response.text.encode('utf8'))\nprint(response.status_code)\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "# Using this endpoint requires using sending multi-part form encoded data. Here is an example curl request:\n# importing a file named import_file.csv\n\n# create a variable for the importRequest JSON\nmyJSON=$(cat <<EOF\n{\n  \"name\": \"November Marketing Event Leads\",\n  \"importOperations\": {\n    \"0-1\": \"CREATE\"\n  },\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"import_file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First Name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last Name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          }\n        ]\n      }\n    }\n  ]\n}\nEOF\n)\n\nYOUR_ACCESS_TOKEN=\"xxx-xxx-xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx\"\n\ncurl -v \\\n  -F \"files=@import_file.csv;type=text/csv\" \\\n  -F \"importRequest=$myJSON;type=application/json\" \\\n  -H \"Authorization: Bearer $YOUR_ACCESS_TOKEN\" \\\n  https://api.hubapi.com/crm/v3/imports\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Importar un archivo con varios objetos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación se muestra un ejemplo de cuerpo de solicitud de importación y asociación de contactos y empresas en un archivo con etiquetas de asociación:"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"Association Label Import\",\n  \"dateFormat\": \"DAY_MONTH_YEAR\",\n  \"files\": [\n    {\n      \"fileName\": \"association label example.xlsx\",\n      \"fileFormat\": \"SPREADSHEET\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Domain\",\n            \"propertyName\": \"domain\"\n          },\n          {\n            \"columnName\": \"Association Label\",\n            \"columnType\": \"FLEXIBLE_ASSOCIATION_LABEL\",\n            \"columnObjectTypeId\": \"0-1\",\n            \"toColumnObjectTypeId\": \"0-2\"\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Importar varios archivos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación se muestra un ejemplo de cuerpo de solicitud de importación y asociación de contactos y empresas en dos archivos, donde la propiedad de contacto ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Correo electrónico"
      }), " es la columna común en los archivos:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST to https://api.hubspot.com/crm/v3/imports\n// Content-Type header set to multipart/form-data\n\n{\n  \"name\": \"Contact Company import\",\n  \"dateFormat\": \"YEAR_MONTH_DAY\",\n  \"files\": [\n    {\n      \"fileName\": \"contact-import-file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"First name\",\n            \"propertyName\": \"firstname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Last name\",\n            \"propertyName\": \"lastname\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": \"email\",\n            \"associationIdentifierColumn\": true\n          }\n        ]\n      }\n    },\n    {\n      \"fileName\": \"company-import-file.csv\",\n      \"fileFormat\": \"CSV\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Company name\",\n            \"propertyName\": \"name\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"columnName\": \"Company domain name\",\n            \"propertyName\": \"domain\",\n            \"columnType\": \"HUBSPOT_ALTERNATE_ID\"\n          },\n          {\n            \"columnObjectTypeId\": \"0-2\",\n            \"toColumnObjectTypeId\": \"0-1\",\n            \"columnName\": \"Email\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationTypeId\": 280,\n              \"associationCategory\": \"HUBSPOT_DEFINED\"\n            }\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En una solicitud exitosa, la respuesta incluirá un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "importId"
      }), " que puedes usar para recuperar o cancelar la importación. Una vez completada, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/import-and-export/view-and-analyze-previous-imports",
        children: "ver la importación en HubSpot"
      }), ", pero las importaciones completadas a través de la API no estarán disponibles como opción al filtrar los registros por importación en vistas, listas, informes o workflows."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obtener importaciones anteriores"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar todas las importaciones desde tu cuenta de HubSpot, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/import/"
      }), ". Para recuperar información para una importación específica, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/import/{importId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando recuperes las importaciones, se devolverá la información, incluido el nombre de la importación, el origen, el formato de archivo, el idioma, el formato de fecha y las asignaciones de columnas. También se devolverá el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "state"
      }), " de la importación, que puede ser cualquiera de los siguientes:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "STARTED"
        }), ": HubSpot reconoce que la importación existe, pero la importación aún no ha comenzado a procesarse."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), ": la importación se está procesando activamente."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DONE"
        }), ": la importación está completa. Todos los objetos, actividades o asociaciones han sido actualizados o creados."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "FAILED"
        }), ": se produjo un error que no se detectó cuando se inició la importación. La importación no se completó."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "CANCELED"
        }), ": el usuario canceló la exportación mientras estaba en cualquiera de los estados ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "STARTED"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PROCESSING"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DEFERRED"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DEFERRED"
        }), ": el número máximo de importaciones (tres) se procesan al mismo tiempo. La importación comenzará una vez que una de las otras importaciones termine de procesarse."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtén más información sobre la paginación y la limitación de resultados en la pestaña ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Puntos de terminación"
      }), " en la parte superior de este artículo."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota"
        }), ": cuando recuperes importaciones usando un token de acceso a una aplicación privada, la respuesta solo incluirá las importaciones realizadas por esa aplicación privada. No se devolverán las importaciones realizadas en HubSpot o a través de otra aplicación privada."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cancel an import"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To cancel an active import, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}/cancel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View and troubleshoot import errors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view errors for a specific import, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/imports/{importId}/errors"
      }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/troubleshoot-import-errors",
        children: "common import errors and how to resolve them"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For errors such as ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Incorrect number of columns"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Unable to parse"
      }), " JSON or ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "404 text/html is not accepted"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that there is a column header for each column in your file, and that the request body contains a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "columnMapping"
        }), " entry for each column. The following criteria should be met:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "The column order in the request body and import file should match. If the column order doesn't match, the system will attempt to automatically reorder but may be unsuccessful, resulting in an error when the import is started."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Every column needs to be mapped. If a column is not mapped, the import request may still be successful, but would result in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Incorrect number of columns"
            }), " error when the import is started."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that the file's name and the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fileName"
        }), " field in your request JSON match, and that you've included the file extension in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fileName"
        }), " field. For example, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "import_name.csv."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ensure that your header includes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Content-Type"
        }), " with a value of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "multipart/form-data"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "Example POST URL:\nhttps://api.hubapi.com/crm/v3/imports?\n\nExample importRequest JSON data:\nThis example contains 3 columns:\n - First name, mapped to the firstname contact property\n - Email, mapped to the email contact property\n - Company ID, which contains a list of company record IDs\n   that the contact will be assocated with.\n{\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"ignored\": false,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": null,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          }\n        ]\n      }\n    }\n  ]\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "# This example a local file named 'final_emails.csv'\n# This file contains a list of contact records to import.\n# Each row in the file contains a column named \"Company ID\" that contains the companyId\n# that the contact should be associated with.\n\nimport requests\nimport json\n\npost_url = 'http://api.hubapi.com/crm/v3/imports?hapikey=4324...5432'\n\nimportRequest = {\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": True,\n        \"columnMappings\": [\n          {\n            \"ignored\": False,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": None,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": None,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          },\n          {\n            \"ignored\": False,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": None,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          },\n          {\n            \"ignored\": False,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": None,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": False\n          }\n        ]\n      }\n    }\n  ]\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "<?php\n// This example imports a local file named 'import_file.csv'\n\n    $post_url = \"http://api.hubapi.com/crm/v3/imports?hapikey=1234...5342\";\n\n    $csv_file = new CURLFile('import_file.csv','text/csv');\n\n    $config_json = '{\"name\":\"test_import\",\"files\":[{\"fileName\":\"final_emails.csv\",\"fileImportPage\":{\"hasHeader\":true,\"columnMappings\":[{\"ignored\":false,\"columnName\":\"First Name\",\"idColumnType\":null,\"propertyName\":\"firstname\",\"foreignKeyType\":null,\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false},{\"ignored\":false,\"columnName\":\"Email\",\"idColumnType\":\"HUBSPOT_ALTERNATE_ID\",\"propertyName\":\"email\",\"foreignKeyType\":null,\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false},{\"ignored\":false,\"columnName\":\"Company ID\",\"idColumnType\":\"HUBSPOT_OBJECT_ID\",\"propertyName\":null,\"foreignKeyType\":{\"associationCategory\":\"HUBSPOT_DEFINED\",\"associationTypeId\":1},\"columnObjectType\":\"CONTACT\",\"associationIdentifierColumn\":false}]}}]};type=application/json';\n\n    $post_data = array(\n        \"files\" => $csv_file,\n        \"importRequest\" => $config_json\n    );\n\n    $ch = curl_init();\n    @curl_setopt($ch, CURLOPT_POST, true);\n    @curl_setopt($ch, CURLOPT_URL, $post_url);\n    @curl_setopt($ch, CURLOPT_POSTFIELDS, $post_data);\n\n    $response    = @curl_exec($ch); //Log the response from HubSpot as needed.\n    $status_code = @curl_getinfo($ch, CURLINFO_HTTP_CODE); //Log the response status code\n    @curl_close($ch);\n    echo $status_code . \" \" . $response;\n?>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "# Using this endpoint requires using sending multi-part form encoded data. Here is an example curl request:\n# importing a file named import_file.csv\n\n# create a variable for the importRequest JSON\nmyJSON=$(cat <<EOF\n{\n  \"name\": \"test_import\",\n  \"files\": [\n    {\n      \"fileName\": \"final_emails.csv\",\n      \"fileImportPage\": {\n        \"hasHeader\": true,\n        \"columnMappings\": [\n          {\n            \"ignored\": false,\n            \"columnName\": \"First Name\",\n            \"idColumnType\": null,\n            \"propertyName\": \"firstname\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Email\",\n            \"idColumnType\": \"HUBSPOT_ALTERNATE_ID\",\n            \"propertyName\": \"email\",\n            \"foreignKeyType\": null,\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          },\n          {\n            \"ignored\": false,\n            \"columnName\": \"Company ID\",\n            \"idColumnType\": \"HUBSPOT_OBJECT_ID\",\n            \"propertyName\": null,\n            \"foreignKeyType\": {\n              \"associationCategory\": \"HUBSPOT_DEFINED\",\n              \"associationTypeId\": 1\n            },\n            \"columnObjectType\": \"CONTACT\",\n            \"associationIdentifierColumn\": false\n          }\n        ]\n      }\n    }\n  ]\n}\nEOF\n)\n\ncurl -v \\\n  -F \"files=@import_file.csv;type=text/csv\" \\\n  -F \"importRequest=$myJSON;type=application/json\" \\\n  http://api.hubapi.com/crm/v3/imports?hapikey=4123...4321\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si recibes un error, comprueba si hay encabezados duplicados, como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Content-Type"
        }), ". Esto puede ocurrir si estás usando Postman o si está incluido en el encabezado de tu script de Python. Elimina el duplicado antes de completar la solicitud."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al usar la API de importaciones, puedes importar hasta 80.000.000 filas por día. Sin embargo, los archivos de importación individuales están limitados a 1.048.576 filas o 512 MB, lo que se alcance primero."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si tu solicitud supera el límite de fila o tamaño, HubSpot responderá con un error HTTP 429. Cuando te acerques a estos límites, se recomienda dividir tu importación en varias solicitudes."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}