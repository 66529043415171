"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742936;
const slug = exports.slug = 'reference/api/conversations/chat-widget-sdk';
const title = exports.title = 'Conversations | Kit de développement logiciel pour widget de chat';
const name = exports.name = 'EMEA (FR) vNext Docs DP - Chat Widget SDK';
const metaDescription = exports.metaDescription = 'Le widget de chat en direct de HubSpot vous permet de discuter avec vos clients sur votre site web. Grâce au kit de développement logiciel pour widget de chat, vous pouvez personnaliser le comportement du widget de chat.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "initialisation",
  "label": "Initialisation",
  "parent": null
}, {
  "depth": 0,
  "id": "configurer-les-param%C3%A8tres-des-conversations",
  "label": "Configurer les paramètres des conversations",
  "parent": null
}, {
  "depth": 1,
  "id": "style-d-int%C3%A9gration-en-ligne",
  "label": "Style d'intégration en ligne",
  "parent": "configurer-les-param%C3%A8tres-des-conversations"
}, {
  "depth": 0,
  "id": "widget-behavior",
  "label": "Widget behavior",
  "parent": null
}, {
  "depth": 1,
  "id": "widget.load",
  "label": "widget.load",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.refresh",
  "label": "widget.refresh",
  "parent": "widget-behavior"
}, {
  "depth": 2,
  "id": "exemple",
  "label": "Exemple",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.open",
  "label": "widget.open",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.close",
  "label": "widget.close",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.remove",
  "label": "widget.remove",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.status",
  "label": "widget.status",
  "parent": "widget-behavior"
}, {
  "depth": 0,
  "id": "effacer-les-cookies-de-chat",
  "label": "Effacer les cookies de chat",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A9v%C3%A9nements-de-chat",
  "label": "Événements de chat",
  "parent": null
}, {
  "depth": 1,
  "id": "conversationstarted",
  "label": "conversationStarted",
  "parent": "%C3%A9v%C3%A9nements-de-chat"
}, {
  "depth": 1,
  "id": "conversationclosed",
  "label": "conversationClosed",
  "parent": "%C3%A9v%C3%A9nements-de-chat"
}, {
  "depth": 1,
  "id": "userselectedthread",
  "label": "userSelectedThread",
  "parent": "%C3%A9v%C3%A9nements-de-chat"
}, {
  "depth": 1,
  "id": "unreadconversationcountchanged",
  "label": "unreadConversationCountChanged",
  "parent": "%C3%A9v%C3%A9nements-de-chat"
}, {
  "depth": 1,
  "id": "contactassociated",
  "label": "contactAssociated",
  "parent": "%C3%A9v%C3%A9nements-de-chat"
}, {
  "depth": 1,
  "id": "userinteractedwithwidget",
  "label": "userInteractedWithWidget",
  "parent": "%C3%A9v%C3%A9nements-de-chat"
}, {
  "depth": 1,
  "id": "widgetloaded",
  "label": "widgetLoaded",
  "parent": "%C3%A9v%C3%A9nements-de-chat"
}, {
  "depth": 1,
  "id": "quickreplybuttonclick",
  "label": "quickReplyButtonClick",
  "parent": "%C3%A9v%C3%A9nements-de-chat"
}, {
  "depth": 1,
  "id": "widgetclosed",
  "label": "widgetClosed",
  "parent": "%C3%A9v%C3%A9nements-de-chat"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      img: "img",
      h4: "h4",
      br: "br",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Kit de développement logiciel pour les conversations"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour discuter avec des clients et des leads sur votre site Web à l'aide de la boîte de réception conversation de HubSpot, vous pouvez configurer un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/chat-with-your-website-visitors",
        children: "widget de chat en direct"
      }), ". Grâce au Kit de développement logiciel (SDK) pour les conversations, vous pouvez offrir une expérience plus personnalisée pour les visiteurs en adaptant le comportement du widget de chat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "À un niveau élevé, le SDK pour les conversations vous permet de faire ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#configure-conversations-settings",
          children: "Configurer les paramètres du widget de chat"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-behavior",
          children: "gérer le comportement du widget"
        }), " ;"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#clear-chat-cookies",
          children: "Effacer les cookies de chat"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#chat-events",
          children: "Participer et répondre aux événements de widgets"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-status"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Initialisation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'API est hébergée dans l'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.HubSpotConversations"
      }), ", qui donne accès à toutes les méthodes disponibles. L'objet est créé par le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code",
        children: "code de suivi HubSpot"
      }), ", mais il peut ne pas être disponible immédiatement lors du chargement de la page. Pour reporter l'accès à l'API jusqu'à son initialisation, vous pouvez utiliser l'assistant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), " est un champ facultatif que vous pouvez définir sur l'objet ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window"
      }), " qui vous permet de spécifier le code à exécuter dès que le widget est disponible. Ce champ prend un tableau de fonctions à exécuter une fois l'API initialisée."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<script type=\"text/javascript\">\n  function onConversationsAPIReady() {\n    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);\n  }\n  /*\n    configure window.hsConversationsSettings if needed.\n  */\n  window.hsConversationsSettings = {};\n  /*\n   If external API methods are already available, use them.\n  */\n  if (window.HubSpotConversations) {\n    onConversationsAPIReady();\n  } else {\n    /*\n      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.\n      These callbacks will be called once the external API has been initialized.\n    */\n    window.hsConversationsOnReady = [onConversationsAPIReady];\n  }\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurer les paramètres des conversations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cet objet facultatif vous permet de fournir des options de configuration au widget avant son initialisation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n  inlineEmbedSelector: '#some-id',\n  enableWidgetCookieBanner: true,\n  disableAttachment: true,\n};\nwindow.hsConversationsOnReady = [\n  () => {\n    window.HubSpotConversations.widget.load();\n  },\n];\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loadImmediately"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the widget should implicitly load or wait until the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.load"
            }), " method is called."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inlineEmbedSelector"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Specify a selector (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "#some-id"
            }), ") to embed the chat widget in a specific location on the page. Widget will be embedded inline within that DOM node and will remain open until it is removed with ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.remove"
            }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#inline-embed-styling",
              children: "styling embedded chat widgets"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableWidgetCookieBanner"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Control behavior of the cookie banner for all chat widgets on the page. Options include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), " (default): uses the ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/chatflows/create-a-live-chat#4-options",
                  children: "chat widget's settings"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": presents cookie banners when the widget is loaded."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ON_WIDGET_LOAD"
                }), ": same as ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ON_EXIT_INTENT"
                }), ": enable cookie banners when the user exhibits an exit intent."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disableAttachment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether to hide the upload attachment button in the chat widget."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disableInitialInputFocus"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether to automatically prevent focusing on the widget's input field after an inline embedded widget is initially loaded."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "avoidInlineStyles"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", injects a link tag with externally hosted CSS instead of a direct dynamic insertion of a style tag."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Style d'intégration en ligne"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque le widget est intégré à un emplacement spécifique à l'aide de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inlineEmbedSelector"
      }), ", plusieurs éléments DOM sont ajoutés et peuvent être stylisés (par exemple, hauteur, largeur, bordure)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, si vous intégrez le widget de chat à l'aide du sélecteur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "#some-id"
      }), ", il sera chargé avec les conteneurs et les ID suivants :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div id=\"some-id\">\n  <div id=\"hubspot-conversations-inline-parent\">\n    <iframe id=\"hubspot-conversations-inline-iframe\">\n      <!-- rest of iframe content -->\n    </iframe>\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "You can then customize the chat widget using those selectors, such as:"
          })
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "#hubspot-conversations-inline-iframe {\n  width: 300px;\n  height: 500px;\n  border: none;\n}\n"
          })
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://developers.hubspot.com/hs-fs/hubfs/livechat_after.png?width=600&name=livechat_after.png",
              alt: "livechat_after"
            })
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Widget behavior"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotConversations.widget"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The widget object contains a number of methods that allow you to manipulate the chat widget on your page, including:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-load",
          children: "widget.load"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-refresh",
          children: "widget.refresh"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-open",
          children: "widget.open"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-close",
          children: "widget.close"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-remove",
          children: "widget.remove"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-status",
          children: "widget.status"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about each method."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.load"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.load"
      }), " method handles the initial load on the page. This method is only necessary if you set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loadImmediately"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), ". Otherwise, the widget will load itself automatically."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This method is throttled to one call per second."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.load();\n\n/* ... */\n\n// Force the widget to load in an open state\nwindow.HubSpotConversations.widget.load({ widgetOpen: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Par défaut"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "widgetOpen"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique si le widget doit être chargé avec le statut ouvert."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.refresh"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La méthode ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.refresh"
      }), " gère l'actualisation et la restitution des informations du widget, en fonction de l'URL de la page actuelle. Cette méthode peut être utile pour les widgets de chat intégrés dans des applications d'une seule page lorsque vous devez actualiser le widget selon les modifications des chemins. Cette méthode vous permet également de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#example",
        children: "spécifier différents widgets de chat sur différents chemins de page"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous appelez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.refresh"
      }), " sur un chemin où il n'y a pas de widget de chat et que l'utilisateur n'est pas engagé dans une conversation, le widget sera supprimé. Cela ne supprimera ", (0, _jsxRuntime.jsx)("u", {
        children: "pas"
      }), " le widget s'il y a un chat actuellement actif."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cette méthode est limitée à un appel par seconde."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.refresh();\n\n/* ... */\n\n// Force the widget to open to a specific chat flow\nwindow.HubSpotConversations.widget.refresh({ openToNewThread: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Par défaut"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "openToNewThread"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique s'il convient de forcer la création d'un nouveau fil."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Exemple"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En utilisant cette méthode, vous pouvez créer des boutons et des liens pour ouvrir des chatflows spécifiques sur une page en ajoutant des paramètres de requête à l'URL de la page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/conversations-chat-widget-open-crop.gif",
        alt: "conversations-chat-widget-open-crop"
      }), "Par exemple, vous pouvez ajouter le code suivant à vos pages pour générer les boutons :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div class=\"chat-buttons\">\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_sales', '?sales_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to sales\n  </button>\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_customer_support', '?cs_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to customer support\n  </button>\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_the_ceo', '?ceo_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to the CEO\n  </button>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ensuite, dans les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/chatflows/create-a-live-chat#2-target---decide-where-the-live-chat-should-appear",
        children: "paramètres cibles"
      }), " de chaque chat, vous paramétrez le chat pour qu'il s'affiche lorsque le paramètre de requête correspond à celui que vous avez défini dans votre code de bouton.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/conversations-target-rule.png",
        alt: "conversations-target-rule"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.open"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La méthode ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.open"
      }), " ouvre le widget s'il n'est pas déjà ouvert ou s'il n'est pas actuellement chargé."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.open();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.close"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La méthode ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.close"
      }), " ferme le widget s'il n'est pas déjà fermé."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.close();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.remove"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La méthode ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.remove"
      }), " supprime le widget de la page. Si le widget n'est pas présent sur la page, cette méthode ne fait rien. Le widget s'affichera à nouveau lors de l'actualisation de la page ou si ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.load"
      }), " est appelé."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.remove();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.status"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La méthode ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.status"
      }), " renvoie un objet contenant des propriétés liées au statut actuel du widget."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const status = window.HubSpotConversations.widget.status();\n\nif (status.loaded) {\n  window.HubSpotConversations.widget.refresh();\n} else {\n  window.HubSpotConversations.widget.load();\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Par défaut"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loaded"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique si l'iframe de widget est chargé ou non."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Effacer les cookies de chat"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La méthode ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "effacer"
      }), " supprime les cookies liés au widget de chat et le renvoie à son statut par défaut lors du chargement ultérieur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le widget de chat crée plusieurs cookies pour préserver son statut à travers les visites et les actualisations. Ces cookies sont étendus au domaine de la page hébergeant le widget et sont utilisés pour prendre en charge les fonctionnalités suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Mentionner des conversations historiques."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rendre le statut ouvert du widget de chat persistant à travers les chargements de page."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rendre le statut ouvert du message de bienvenue persistant à travers les chargements de page."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les cookies suivants sont effacés avec cette méthode :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs-messages-is-open"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs-messages-hide-welcome-message"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour plus d'informations sur ces cookies, consultez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser",
        children: "la Base de connaissances de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.clear();\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également transmettre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{resetWidget:true}"
      }), " à la fonction ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "clear()"
      }), " pour effacer tous les cookies associés aux chats, supprimer le widget de la page et créer une nouvelle instance du widget de chat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.clear({ resetWidget: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Événements de chat"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le widget de chat envoie différents événements auxquels vous pouvez participer et répondre tout au long de son cycle de vie. Ces événements incluent :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#conversationstarted",
          children: "conversationStarted"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#conversationclosed",
          children: "conversationClosed"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#userselectedthread",
          children: "userSelectedThread"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#unreadconversationcountchanged",
          children: "unreadConversationCountChanged"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#contactassociated",
          children: "contactAssociated"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#userinteractedwithwidget",
          children: "userInteractedWithWidget"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widgetloaded",
          children: "widgetLoaded"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#quickreplybuttonclick",
          children: "quickReplyButtonClick"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widgetclosed",
          children: "widgetClosed"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour enregistrer et supprimer des participants à un événement, utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "activer"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "désactiver"
      }), ", comme indiqué ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const handleEvent = (eventPayload) => console.log(eventPayload);\n\nwindow.HubSpotConversations.on('conversationStarted', handleEvent);\n\n/* ... */\n\nwindow.HubSpotConversations.off('conversationStarted', handleEvent);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En savoir plus sur chaque événement ci-dessous."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "conversationStarted"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversationStarted"
      }), " se déclenche lorsqu'une nouvelle conversation est correctement démarrée."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('conversationStarted', (payload) => {\n  console.log(\n    `Started conversation with id ${payload.conversation.conversationId}`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'ID de fil de la conversation qui a été démarrée. Vous pouvez utiliser cet ID lorsque vous effectuez des appels vers ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "l'API conversations"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "conversationClosed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversationClosed"
      }), " se déclenche lorsqu'une nouvelle conversation est ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/use-the-conversations-inbox#reply-and-comment:~:text=To%20mark%20the%20conversation%20as%20closed",
        children: "marquée comme fermée"
      }), " à partir de la boîte de réception conversations."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les visiteurs du site qui réduisent ou ferment le widget de chat ne déclencheront pas cet événement. Pour cet événement, utilisez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#widgetclosed",
        children: "widgetClosed"
      }), " à la place."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('conversationClosed', (payload) => {\n  console.log(\n    `Conversation with id ${payload.conversation.conversationId} has been closed!`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'ID de fil de la conversation qui a été démarrée. Vous pouvez utiliser cet ID lorsque vous effectuez des appels vers ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "l'API conversations"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "userSelectedThread"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userSelectedThread"
      }), " se déclenche lors de la création d'un fil ou de la sélection d'un fil existant."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('userSelectedThread', (payload) => {\n  console.log(\n    `User selected thread with ID ${payload.conversation.conversationId}!`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'ID de fil de la conversation qui a été démarrée. Vous pouvez utiliser cet ID lorsque vous effectuez des appels vers ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "l'API conversations"
            }), "."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "unreadConversationCountChanged"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unreadConversationCountChanged"
      }), " est déclenché lorsque le nombre de conversations avec des messages non lus augmente ou diminue."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('unreadConversationCountChanged', (payload) => {\n  console.log(`New unread count is ${payload.unreadCount}!`);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unreadCount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre total de conversations avec au moins un message non lu."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "contactAssociated"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contactAssociated"
      }), " est déclenché lorsque le visiteur est associé à un contact dans le CRM."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('contactAssociated', (payload) => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un message de confirmation indiquant que le visiteur est associé à un contact."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "userInteractedWithWidget"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userInteractedWithWidget"
      }), " est déclenché lorsque le visiteur interagit avec le widget, par exemple en cliquant pour ouvrir le widget ou en fermant le message de bienvenue initial."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on(‘userInteractedWithWidget’, payload => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un message de confirmation indiquant que le visiteur a interagi avec le widget."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widgetLoaded"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgetLoaded"
      }), " est déclenché lorsque l'iframe du widget est chargé."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on(‘widgetLoaded’, payload => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Message de confirmation indiquant que l'iframe du widget est chargé."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "quickReplyButtonClick"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "quickReplyButtonClick"
      }), " est déclenché lorsque le visiteur clique sur une réponse ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/chatflows/a-guide-to-bot-actions#ask-a-question",
        children: "rapide"
      }), " dans une conversation de bot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Valeur"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un tableau contenant le texte de l'option de réponse rapide qui a été sélectionnée."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('quickReplyButtonClick', (event) => {\n  console.log(`The text content of the clicked button is ${payload.value[0]}`);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/quick-reply-options-in-bot-conversation.png",
        alt: "quick-reply-options-in-bot-conversation"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans l'exemple de capture d'écran ci-dessus, le chatflow du bot contient trois options de réponse rapide. Si l'utilisateur sélectionne ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "En savoir plus"
      }), ", la charge utile de l'événement résultant sera :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example event payload when a quick reply option is selected\n{\n  \"name\": \"QUICK_REPLIES\",\n  \"multiSelect\": false,\n  \"value\": [\"Learn more\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widgetClosed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgetClosed"
      }), " est déclenché lorsque le visiteur ferme le widget de chat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('widgetClosed', (event) => {\n  console.log(event);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Champ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un message de confirmation indiquant que le visiteur a fermé le widget de chat."
          })]
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}