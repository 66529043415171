"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 59619794102;
const slug = exports.slug = 'guides/api/crm/engagements/calls';
const title = exports.title = "Appels | d'engagements";
const name = exports.name = "Appels | d'engagements";
const metaDescription = exports.metaDescription = "Utilisez l'API d'engagement des appels pour enregistrer et gérer les appels dans les fiches d'informations CRM.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "cr%C3%A9er-un-engagement-d-appel",
  "label": "Créer un engagement d'appel",
  "parent": null
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s",
  "label": "Propriétés",
  "parent": "cr%C3%A9er-un-engagement-d-appel"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "cr%C3%A9er-un-engagement-d-appel"
}, {
  "depth": 0,
  "id": "r%C3%A9cup%C3%A9rer-des-appels",
  "label": "Récupérer des appels",
  "parent": null
}, {
  "depth": 0,
  "id": "identifier-les-messages-vocaux-par-rapport-aux-appels-enregistr%C3%A9s",
  "label": "Identifier les messages vocaux par rapport aux appels enregistrés",
  "parent": null
}, {
  "depth": 0,
  "id": "mettre-%C3%A0-jour-les-appels",
  "label": "Mettre à jour les appels",
  "parent": null
}, {
  "depth": 1,
  "id": "associer-des-appels-existants-%C3%A0-des-fiches-d-informations",
  "label": "Associer des appels existants à des fiches d'informations",
  "parent": "mettre-%C3%A0-jour-les-appels"
}, {
  "depth": 1,
  "id": "supprimer-une-association",
  "label": "Supprimer une association",
  "parent": "mettre-%C3%A0-jour-les-appels"
}, {
  "depth": 0,
  "id": "%C3%A9pingler-un-appel-sur-une-fiche-d-informations",
  "label": "Épingler un appel sur une fiche d'informations",
  "parent": null
}, {
  "depth": 0,
  "id": "supprimer-des-appels",
  "label": "Supprimer des appels",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Appels"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez l'API d'engagement des appels pour documenter et gérer les appels sur les fiches d'informations CRM et sur la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-customize-and-manage-your-saved-views",
        children: "page index des appels"
      }), ". Vous pouvez enregistrer des appels soit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "dans HubSpot"
      }), " soit via l'API d'appels. Ci-dessous, découvrez les méthodes de base pour gérer les appels via l'API. Pour afficher tous les points de terminaison disponibles et leurs exigences, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer un engagement d'appel"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un engagement d'appel, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le corps de la requête, ajoutez les détails d'appel dans un objet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), ". Vous pouvez également ajouter un objet d'", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "association"
      }), " pour associer votre nouvel appel à une fiche d'informations existante (par exemple, des contacts ou des entreprises)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous trouverez ci-dessous une liste des propriétés d'appel par défaut de HubSpot que vous pouvez inclure dans l'objet de propriétés. Vous pouvez également créer des propriétés personnalisées à l'aide de l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/api/crm/properties",
        children: "API des propriétés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "du champ"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obligatoire. Ce champ indique l'heure de création de l'appel et détermine où se trouve l'appel sur la chronologie de la fiche d'informations. Vous pouvez utiliser soit un horodatage Unix en millisecondes, soit un format UTC."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La description de l'appel, y compris les notes que vous souhaitez ajouter."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_callee_object_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'ID de la fiche d'informations HubSpot associée à l'appel. Il s'agit du destinataire de l'appel pour les appels ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), ", ou de la personne ayant composé le numéro pour les appels ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_callee_object_type_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'ID de l'objet auquel la fiche d'informations associée à l'appel appartient (par exemple, spécifie si la fiche d'informations est un contact ou une entreprise). Il s'agit de l'objet du destinataire pour les appels ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), ", ou de l'objet de la personne ayant composé le numéro pour les appels ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_direction"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La direction de l'appel du point de vue de l'utilisateur HubSpot. Si l'utilisateur est le destinataire de l'appel, la direction doit être définie sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INBOUND"
            }), ". Si l'utilisateur a lancé l'appel, la direction doit être définie sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OUTBOUND"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_disposition"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le résultat de l'appel. Pour définir la disposition des appels, vous devez utiliser la valeur interne du GUID. Si votre compte a configuré des ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/calling/create-custom-call-and-meeting-outcomes",
              children: "résultats d'appel personnalisés"
            }), ", vous pouvez trouver leurs GUID de disposition à l'aide de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/engagements/engagement-details#get-call-engagement-dispositions",
              children: "cette API"
            }), ". Par défaut, les libellés de résultat HubSpot et leurs valeurs internes sont :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Occupé(e) : ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "9d9162e7-6cf3-4944-bf63-4dff82258764"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Connecté(e) : ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "f240bbac-87c9-4f6e-bf70-924b57d47db7"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["A laissé un message en direct : ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "a4c4c377-d246-4b32-a13b-75a56a4cd0ff"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["A laissé un message vocal : ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "b2cf5968-551e-4856-9783-52b3da59a7d0"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Pas de réponse : ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "73a0d17f-1163-4015-bdd5-ec830791da20"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Mauvais numéro : ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "17b47fee-58de-441e-a44c-c6300d46f273"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_duration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La durée de l'appel en millisecondes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_from_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le numéro de téléphone à partir duquel l'appel a été effectué."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_recording_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'URL qui stocke la fiche d'informations de l'appel. LES URL des fichiers .mp3 ou .wav peuvent être lues sur des fiches d'information CRM. Seules les URL HTTPS sécurisées seront acceptées."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_status"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le statut de l'appel. Les statuts sont ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BUSY"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CALLING_CRM_USER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CANCELED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMPLETED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONNECTING"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FAILED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN_PROGRESS"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NO_ANSWER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "QUEUED"
            }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RINGING"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le titre de l'appel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_source"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La source de l'appel. Cette information n'est pas obligatoire, sauf pour exploiter le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/extensions/calling-extensions/recordings-and-transcriptions#log-a-call-with-your-app-s-endpoint-using-the-engagements-api",
              children: "pipeline des enregistrements et des transcriptions"
            }), ". Si la propriété est définie, elle doit être définie sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "INTEGRATIONS_PLATFORM"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_to_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le numéro de téléphone qui a reçu l'appel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "ID du propriétaire"
            }), " associé à l'appel. Ce champ détermine l'utilisateur listé comme le créateur d'appel sur la chronologie de la fiche d'informations."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_activity_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type d'appel. Les options sont basées sur les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/meetings-tool/how-do-i-create-and-use-call-and-meeting-types",
              children: "types d'appels définis dans votre compte HubSpot."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les ID des pièces jointes de l'appel. Les ID de pièces jointes multiples sont séparés par un point-virgule."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour créer et associer un appel à des fiches d'informations existantes, incluez un objet d'association dans votre demande. Par exemple, pour créer un appel et l'associer à un contact et à un ticket, votre corps de demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-17T01:32:44.872Z\",\n    \"hs_call_title\": \"Support call\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_call_body\": \"Resolved issue\",\n    \"hs_call_duration\": \"3800\",\n    \"hs_call_from_number\": \"(857) 829 5489\",\n    \"hs_call_to_number\": \"(509) 999 9999\",\n    \"hs_call_recording_url\": \"https://api.twilio.com/2010-04-01/Accounts/AC890b8e6fbe0d989bb9158e26046a8dde/Recordings/RE3079ac919116b2d22\",\n    \"hs_call_status\": \"COMPLETED\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 500\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 194\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 1234\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 220\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans l'objet d'association, vous devez ajouter les éléments suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "du champ"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["La fiche d'informations à laquelle vous souhaitez associer l'appel, en fonction de sa valeur unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le type d'association entre l'appel et la fiche d'informations. Inclure la ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "et le ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Les ID de types d'association par défaut sont répertoriés ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "ici"
            }), ", ou vous pouvez récupérer la valeur des types d'associations personnalisés (c'est-à-dire les libellés) via l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "API des associations"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la création d'appels par lots, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Récupérer des appels"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez récupérer des appels individuellement ou en lot. Pour en savoir plus sur la récupération par lot, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour récupérer un appel individuel par son ID d'appel, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), ". Vous pouvez inclure les paramètres suivants dans l'URL de la requête :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une liste séparée par des virgules ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#call-properties-1",
              children: "des propriétés"
            }), " à renvoyer."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une liste séparée par des virgules des types d'objets pour lesquels récupérer les ID associés. Les associations spécifiées qui n'existent pas ne seront pas renvoyées dans la réponse. Découvrez-en davantage sur l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "API des associations."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour demander une liste de tous les appels, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls"
      }), ". Vous pouvez inclure les paramètres suivants dans l'URL de la requête :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Paramètre"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nombre maximum de résultats à afficher par page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une liste séparée par des virgules ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#call-properties-1",
              children: "des propriétés"
            }), " à renvoyer."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous effectuez une requête réussie, la réponse inclut l'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callId"
      }), " que vous pouvez utiliser pour récupérer, mettre à jour et supprimer l'appel."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identifier les messages vocaux par rapport aux appels enregistrés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les appels enregistrés et les messages vocaux, un enregistrement est stocké dans la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_recording_url"
      }), ". Si votre compte a accès aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/calling/receive-calls-in-hubspot",
        children: "appels entrants"
      }), ", pour différencier appels terminés et enregistrés des appels entrants avec messages vocaux, incluez les propriétés suivantes dans votre demande : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_status"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si un appel a un message vocal, la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_status"
      }), " sera ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "missed"
      }), " et la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), " sera ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". La valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_call_has_voicemail"
      }), " sera ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " pour un appel entrant où aucun message vocal n'a été laissé, ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "null"
      }), " si l'appel a un statut autre que Manqué."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mettre à jour les appels"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez mettre à jour les appels individuellement ou par lots. Pour mettre à jour un appel individuel par son ID d'appel, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans le corps de la requête, incluez les propriétés d'appel que vous souhaitez mettre à jour :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example PATCH request to https://api.hubspot.com/crm/v3/objects/calls/{callID}\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-03-17T01:32:44.872Z\",\n    \"hs_call_title\": \"Discovery call\",\n    \"hubspot_owner_id\": \"11349275740\",\n    \"hs_call_body\": \" Decision maker out, will call back tomorrow\",\n    \"hs_call_duration\": \"3800\",\n    \"hs_call_from_number\": \"(857) 829 5489\",\n    \"hs_call_to_number\": \"(509) 999 9999\",\n    \"hs_call_recording_url\": \"https://api.twilio.com/2010-04-01/Accounts/AC890b8e6fbe0d989bb9158e26046a8dde/Recordings/RE3079ac919116b2d22\",\n    \"hs_call_status\": \"COMPLETED\"\n  }\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot ignorera les valeurs des propriétés inexistantes ou en lecture seule. Pour effacer une valeur de propriété, passez une chaîne vide comme valeur de propriété dans le corps de la requête."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la mise à jour par lots, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associer des appels existants à des fiches d'informations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour associer un appel à des fiches d'informations, comme un contact et ses entreprises associées, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), ". L'URL de la requête contient les champs suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "du champ"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de l'appel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le type d'objet auquel vous souhaitez associer l'appel (par exemple, contact ou entreprise)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'ID de la fiche d'informations avec laquelle vous souhaitez associer l'appel."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un identifiant unique pour indiquer le type d'association entre l'appel et l'autre objet. L'ID peut être représenté numériquement ou en snake case (ex : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "call_to_contact"
            }), "). Vous pouvez récupérer la valeur via l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "API des associations"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, l'URL de votre requête peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/calls/17591596434/associations/contact/104901/194"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Supprimer une association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer une association entre un appel et une fiche d'informations, faites une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à la même URL que ci-dessus :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Épingler un appel sur une fiche d'informations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "épingler un appel"
      }), " sur une fiche d'informations afin qu'il reste en haut de la chronologie de la fiche d'informations. L'appel doit déjà être associé à la fiche d'informations avant d'être épinglé, et vous ne pouvez épingler qu'une seule activité par fiches d'informations. Pour épingler un appel, incluez l'", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " de l'appel dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), " lors de la création ou de la mise à jour d'une fiche d'informations via les API objet. Découvrez-en davantage sur l'utilisation des API d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "entreprises"
      }), ", de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "contacts"
      }), ", de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "transactions"
      }), ", de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "tickets"
      }), " et d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "objets personnalisés"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supprimer des appels"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez supprimer les appels individuellement ou par lots, ce qui placera l'appel à la corbeille dans HubSpot. Vous pouvez ensuite ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "restaurer l'appel à partir de la chronologie des fiches d'informations"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer un appel individuel par son ID d'appel, effectuez une requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/calls/{callId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur la suppression par lot, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Points de terminaison"
      }), " en haut de cet article."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}