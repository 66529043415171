"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92695279906;
const slug = exports.slug = 'guides/cms/content/overview';
const title = exports.title = 'Blocos de construção do HubSpot CMS';
const name = exports.name = 'PT BR CMS building blocks overview';
const metaDescription = exports.metaDescription = 'Os desenvolvedores podem usar uma série de blocos de construção para criar sites no HubSpot CMS. Os blocos de construção podem ser usados para criar um sistema de design no qual os criadores de conteúdo podem trabalhar, com vários graus de flexibilidade e limites, dependendo das necessidades da sua empresa.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "temas",
  "label": "Temas",
  "parent": null
}, {
  "depth": 0,
  "id": "modelos",
  "label": "Modelos",
  "parent": null
}, {
  "depth": 0,
  "id": "m%C3%B3dulos",
  "label": "Módulos",
  "parent": null
}, {
  "depth": 0,
  "id": "campos-de-tema-e-m%C3%B3dulo",
  "label": "Campos de tema e módulo",
  "parent": null
}, {
  "depth": 0,
  "id": "conte%C3%BAdo-global",
  "label": "Conteúdo global",
  "parent": null
}, {
  "depth": 0,
  "id": "menus-e-navega%C3%A7%C3%A3o",
  "label": "Menus e navegação",
  "parent": null
}, {
  "depth": 0,
  "id": "formul%C3%A1rios",
  "label": "Formulários",
  "parent": null
}, {
  "depth": 0,
  "id": "as-configura%C3%A7%C3%B5es-do-site",
  "label": "As configurações do site",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Visão geral dos blocos de construção do CMS"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os desenvolvedores usam uma série de blocos de construção para criar sites no software de CMS da HubSpot. Os blocos de construção podem ser usados para criar um sistema de design no qual os criadores de conteúdo podem trabalhar, com vários graus de flexibilidade e limites, dependendo das necessidades da sua empresa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Temas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "tema"
      }), " é um conjunto transferível e independente de ativos para desenvolvedores que permite uma experiência flexível de edição de conteúdo. Trata-se de um bloco de construção de alto nível que define a aparência de um site e cria uma experiência de edição de conteúdo amigável para o profissional de marketing."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modelos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modelos"
      }), " definem a marcação e o estilo básico do conjunto de páginas que os utilizam. Quando os criadores de conteúdo criam uma página ou e-mail, eles selecionam um modelo para usar antes de preenchê-lo com conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "módulos"
      }), " são componentes reutilizáveis que você pode adicionar a modelos e páginas no HubSpot CMS. Eles incluem controles para profissionais de marketing e contêm marcação HubL/HTML, CSS e JavaScript que, juntos, criam os componentes reutilizáveis e editáveis de um site."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campos de tema e módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "campos"
      }), " são os controles que os criadores de conteúdo utilizam para ajustar os parâmetros que são passados para seus temas e módulos. Os campos permitem que os blocos de construção criados pelos desenvolvedores sejam reutilizados em diferentes partes do conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Conteúdo global"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "conteúdo global"
      }), " permite que a marcação e o conteúdo sejam compartilhados globalmente em um site. O conteúdo global simplifica o gerenciamento de elementos como cabeçalhos, rodapés, barras laterais ou outros elementos globais de um site."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Menus e navegação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/menus-and-navigation",
        children: "menus"
      }), " permitem criar facilmente uma hierarquia de navegação e uma arquitetura de informações para navegar no site."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Formulários"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/forms",
        children: "formulários"
      }), " permitem capturar vários tipos de informações dos usuários (como endereços, endereços de e-mail e feedback) que você pode usar em todo o ecossistema da HubSpot, como em conteúdo inteligente, fluxos de trabalho, personalização de conteúdo e muito mais em suas operações diárias."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "As configurações do site"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/website-settings",
        children: "configurações do site"
      }), " são um local central onde várias configurações globais e em nível de sistema podem ser definidas para o site, como logotipo, favicon, modelos de sistema e muito mais."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}