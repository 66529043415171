"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 79942329346;
const slug = exports.slug = 'reference/cms/hubl/quote-variables';
const title = exports.title = 'Variables de plantilla de cotización';
const name = exports.name = 'EMEA ES Quote template variables';
const metaDescription = exports.metaDescription = 'Utiliza HubL para acceder y mostrar datos de CRM dentro de tu plantilla de cotización personalizada. El objeto template_data contiene la mayoría de los datos asociados a la cotización. Para cualquier cosa que no se encuentre allí, puedes asociar el registro de objeto a la cotización o usar las funciones de HubL CRM_object.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "objeto-template_data",
  "label": "Objeto Template_data",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-de-cotizaci%C3%B3n",
  "label": "Variables de cotización",
  "parent": null
}, {
  "depth": 0,
  "id": "objetos-asociados",
  "label": "Objetos asociados",
  "parent": null
}, {
  "depth": 0,
  "id": "objetos-personalizados",
  "label": "Objetos personalizados",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      pre: "pre",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      br: "br",
      a: "a",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Variables de plantilla de cotización"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las plantillas de cotización personalizadas pueden acceder a los datos de cotización y a algunos objetos asociados directamente desde las plantillas. Los datos disponibles dependen de los datos que tengas en tu CRM, así como de los datos agregados a la propia cotización."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mientras desarrollas una plantilla de cotización, puedes usar datos simulados proporcionados por HubSpot para completar la plantilla, lo que puede ayudar a obtener una vista previa de esta. En la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot"
      }), ", navega a la carpeta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cms-quotes-theme"
      }), ". En la carpeta de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templates"
      }), ", consulta las plantillas ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bold.html"
      }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "minimal.html"
      }), ". Estas plantillas contienen el siguiente bloque de código en la parte superior:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% from \"../imports/mock_data.html\" import SAMPLE_TEMPLATE_DATA as mock_data %}\n{% from \"../imports/module_defaults.html\" import MODULE_DEFAULTS as module_defaults %}\n{% set QUOTE = template_data.quote || mock_data.quote %}\n{% set CURRENCY = QUOTE.hs_currency || \"USD\" %}\n{% set LOCALE = QUOTE.hs_locale || \"en-US\" %}\n{% set ASSOCIATED_OBJECTS = QUOTE.associated_objects %}\n{% set LINE_ITEMS = ASSOCIATED_OBJECTS.line_items %}\n{% set ADDITIONAL_FEES = ASSOCIATED_OBJECTS.additional_fees %}\n{% set TOTALS = ASSOCIATED_OBJECTS.totals || ASSOCIATED_OBJECTS.totals %}\n{% set QUOTE_TOTAL = TOTALS.total %}\n{% set SUB_TOTALS = TOTALS.subtotals %}\n{% set DEAL = ASSOCIATED_OBJECTS.deal %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los datos simulados se importan primero desde el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mock_data.html"
      }), " y luego se establecen en la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), " para usar los datos que se encuentran en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " si están disponibles. La variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), " también se usa para rellenar las otras variables en esta lista, como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ASSOCIATED_OBJECTS"
      }), ", para hacer que el acceso a esos datos sea menos verbal. Sin embargo, puedes estructurar tus datos de manera diferente, según tus preferencias."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el código anterior, notarás que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " también se usa para establecer la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "QUOTE"
      }), " principal. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " es un objeto que contiene todos los datos reales para la cotización y el negocio en la página. Si no se encuentra ese objeto en la plantilla, HubSpot carga los datos desde ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mock_data.html"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objeto Template_data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A la gran mayoría de los datos se puede acceder directamente a través del objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), ". Puedes usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ template_data|pprint }}"
      }), " en tu plantilla para ver el objeto completo proporcionado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un diccionario que contiene la cotización, quote.associated_objects y totaliza los diccionarios."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables de cotización"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La información específica de esta cotización individual."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Variable"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "dict"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Diccionario que contiene todos los datos para la cotización en sí."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.associated_objects.deal.hs_object_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID del negocio"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID del propietario del negocio"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_all_owner_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "integer or array of integers"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID de propietarios de negocios"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_created_by_user_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Usuario que creó la cotización."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_lastmodifieddate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fecha en que se modificó por última vez la cotización. En formato de época."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hubspot_owner_assigneddate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fecha en que se asignó un propietario a la cotización. En formato epoch."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_createdate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fecha y hora en que se creó la cotización. En formato epoch."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_expiration_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fecha en que vence. En formato epoch."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Título de la cotización"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_template_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "\"CUSTOMIZABLE_QUOTE_TEMPLATE\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_slug"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Slug de URL para la página web de la cotización."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_proposal_template_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ruta del sistema de archivos del desarrollador a la plantilla. (incluye extensión de archivo)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_quote_amount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cantidad de dinero"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_currency"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Moneda en la que se encuentra el importe de la cotización en código de moneda ISO 4217 de 3 caracteres.\"USD\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Código de idioma\"es\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_locale"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Código de configuración regional\"es-es\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_terms"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto de términos proporcionado por el creador de cotizaciones"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_firstname"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre de la persona que envía la cotización."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre de la empresa de la persona que envía la cotización"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_sender_company_image_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Logotipo de la empresa para la persona que envía la cotización."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_status"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Estado de la cotización.\"APPROVAL_NOT_NEEDED\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_primary_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string/hex color code"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "\"#425b76\""
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_quote_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número de identificación de cotización único."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_payment_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se utiliza para probar si se deben mostrar los campos de pago."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "template_data.quote.hs_esign_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Se usa para probar si se deben mostrar los campos de firma electrónica."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "¿No puedes encontrar una variable que estás buscando?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "Hay más variables a las que puedes acceder dentro de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "template_data"
        }), ". Usa ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "|pprint"
        }), " para verlas. Además, algunas variables en las asociaciones de cotización solo pueden estar disponibles en función de la cotización/negocio."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Estaremos repasando esta documentación para mostrar y explicar más datos a los que tienes acceso. Aparte de una bonita impresión, puedes ver el archivo de datos simulado dentro del cms-quote-theme, para ver lo que está disponible y la estructura en la que viene."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objetos asociados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En una plantilla de cotización, puedes acceder a los datos de los registros asociados de una cotización, como operaciones o empresas, usando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associated_objects"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, puedes agregar el logotipo del registro de empresa asociada del destinatario de la cotización a una cotización usando el siguiente código:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set company_avatar_url = template_data.quote.associated_objects.company.hs_avatar_filemanager_key %}\n{% if company_avatar_url %}\n  <img src=\"{{ template_data.quote.associated_objects.company.hs_avatar_filemanager_key }}\" width=\"400\" alt=\"{{ template_data.quote.associated_objects.company.name }}\">\n{% else %}\n  <!-- company does not have an assigned image-->\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " solo aparecerán logotipos establecidos manualmente. Los logotipos detectados automáticamente no aparecerán para evitar que aparezcan logotipos no intencionales en la plantilla de cotización."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El código anterior establece en primer lugar una variable que busca el logotipo de la empresa asociada a la cotización. Luego, usando una sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", la plantilla muestra ese logotipo, si está disponible. Si no se ha establecido manualmente ningún logotipo para la empresa, no se muestra ninguno."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objetos personalizados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los datos de objetos personalizados se pueden mostrar o usar dentro de una cotización de un par de maneras diferentes. Debido a que la estructura de cada objeto personalizado puede variar, deberás obtener propiedades específicas en función de cómo hayas estructurado tu objeto personalizado."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La cotización ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), " por opción predeterminada tiene objetos asociados personalizados en ella. Por ejemplo, se incluyen objetos personalizados asociados con negocios."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para acceder a ellos, puedes usar el siguiente código:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set quote_associated_custom_objects = template_data.quote.associated_objects.deal.associated_objects.custom_objects %}\n\n{{ quote_associated_custom_objects|pprint }}\n{# |pprint is useful for understanding the structure of the data, you can leave it off when outputting values for display. #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " debido a que los objetos personalizados son únicos para cada cuenta, los datos simulados no incluyen un objeto personalizado de ejemplo. Esto significa que en la vista previa de la plantilla en el administrador de diseño puede ver un error o los datos del objeto personalizado simplemente no se muestran. En cambio, deberás obtener una vista previa de la plantilla con tus datos reales de CRM, lo que puedes hacer creando una cotización a partir de la plantilla."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes entonces acceder a cada tipo de objeto personalizado agregando su ID de tipo de objeto personalizado con formato de guiones bajos. Por ejemplo:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data.quote.associated_objects.deal.associated_objects.custom_objects._2_2193031"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes buscar un objeto personalizado usando las funciones ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations()"
        })
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/es/docs/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects()"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, si quisieras buscar un objeto personalizado asociado con un negocio, podrías pasar datos de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "template_data"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set quote_associated_object = crm_associations(template_data.quote.associated_objects.deal.hs_object_id, \"USER_DEFINED\", 152) %}\n{# 152 is an example of an association type id, you would need to use the appropriate id for your use-case. #}\n{{ quote_associated_object }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/quotes",
          children: "Plantillas de cotización personalizadas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/getting-started-from-the-cms-quotes-theme-beta",
          children: "Primeros pasos con el tema CMS quotes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/es/deals/create-custom-quote-templates-beta",
          children: "Crear y usar plantillas de cotización personalizadas (desde la perspectiva de ventas, operaciones de ventas/gerente)"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}