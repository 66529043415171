"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358694;
const slug = exports.slug = 'guides/apps/marketplace/listing-your-app';
const title = exports.title = 'App Marketplace | Listing Ihrer App';
const name = exports.name = 'vNext Docs DP - Listing Ihrer App';
const metaDescription = exports.metaDescription = 'Befolgen Sie diese Schritte, um eine App für ein Listing im HubSpot Marketplace einzureichen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "ein-app-listing-erstellen-und-einreichen",
  "label": "Ein App-Listing erstellen und einreichen",
  "parent": null
}, {
  "depth": 0,
  "id": "listing-informationen",
  "label": "Listing-Informationen",
  "parent": null
}, {
  "depth": 0,
  "id": "app-details",
  "label": "App-Details",
  "parent": null
}, {
  "depth": 0,
  "id": "preise",
  "label": "Preise",
  "parent": null
}, {
  "depth": 0,
  "id": "app-funktionen",
  "label": "App-Funktionen",
  "parent": null
}, {
  "depth": 0,
  "id": "support-informationen",
  "label": "Support-Informationen",
  "parent": null
}, {
  "depth": 0,
  "id": "die-informationen-%C3%BCberpr%C3%BCfen-und-ihr-listing-einreichen",
  "label": "Die Informationen überprüfen und Ihr Listing einreichen",
  "parent": null
}, {
  "depth": 0,
  "id": "ein-lokalisiertes-listing-f%C3%BCr-ein-bestehendes-app-listing-erstellen-und-aktualisieren",
  "label": "Ein lokalisiertes Listing für ein bestehendes App-Listing erstellen und aktualisieren",
  "parent": null
}, {
  "depth": 0,
  "id": "ein-live-app-listing-bearbeiten",
  "label": "Ein Live-App-Listing bearbeiten",
  "parent": null
}, {
  "depth": 0,
  "id": "die-ver%C3%B6ffentlichung-eines-live-app-listing-r%C3%BCckg%C3%A4ngig-machen",
  "label": "Die Veröffentlichung eines Live-App-Listing rückgängig machen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      em: "em",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Listing Ihrer App"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem Sie eine App in Ihrem Entwickler-Account erstellt haben, die die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "Anforderungen an Listings im App Marketplace"
      }), " erfüllen, können Sie ein Listing einreichen, um es dem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "App Marketplace von HubSpot"
      }), " hinzuzufügen. Das HubSpot Ecosystem Quality-Team überprüft Ihre Einsendung und meldet sich per E-Mail, wenn die App genehmigt oder abgelehnt wurde."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Sie müssen ein ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "Super-Admin"
        }), " sein, um ein App-Listing zu aktualisieren und einzureichen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In diesem Artikel:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-submit-an-app-listing",
          children: "Ein App-Listing erstellen und einreichen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-update-a-localized-listing-for-an-existing-app-listing",
          children: "Ein lokalisiertes Listing für ein bestehendes App-Listing erstellen und aktualisieren"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-and-submit-an-app-listing",
          children: "Ansprechpartner für das App-Partnerprogramm hinzufügen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#edit-localized-listing",
          children: "Ein Live-App-Listing bearbeiten"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#unpublish-a-live-app-listing",
          children: "Die Veröffentlichung eines Live-App-Listing rückgängig machen"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein App-Listing erstellen und einreichen"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Bevor Sie ein App-Listing einreichen, sehen Sie sich die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
          children: "Seite mit den App-Listing-Voraussetzungen"
        }), " an, um zu verstehen, was Sie für Ihr Listing angeben müssen."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Gehen Sie in Ihrem ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "App-Entwickler-Account"
        }), " zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie oben rechts auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listing erstellen"
        }), ". Wenn diese Schaltfläche ausgegraut ist, wurden bereits Listings für alle Ihre vorhandenen Apps erstellt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App"
        }), " aus, für die Sie ein Listing erstellen möchten, und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Weiter"
        }), ". Apps, die bereits im App Marketplace gelistet sind, werden hier nicht angezeigt."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie im nächsten Bildschirm auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sprachen auswählen, in denen Ihre App verfügbar ist"
        }), " und wählen Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sprachen"
        }), " aus, in denen Ihre App-Software angeboten wird. Der App Marketplace ist in acht Sprachen verfügbar: Deutsch, Englisch, Spanisch, Französisch, Portugiesisch, Japanisch, Niederländisch und Italienisch. Sie können App-Listings in beliebig vielen dieser Sprachen erstellen, solange die Software Ihrer App in diesen Sprachen verfügbar ist."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Primäre Listing-Sprache für [Name der App] auswählen"
        }), " und wählen Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Standardsprache"
        }), " aus, die Benutzer dann beim Durchsuchen des App Marketplace sehen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Weiter"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Der App-Listing-Assistent enthält fünf Registerkarten mit Informationen zum Ausfüllen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listing-Informationen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Listing-Informationen"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Fügen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "App-Informationen"
          }), " den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Namen Ihrer öffentlichen App"
          }), ", den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Unternehmensnamen"
          }), ", den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Slogan"
          }), " und die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL der Installationsschaltfläche"
          }), " hinzu."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Laden Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "App-Symbol"
          }), " ein Symbol von 800 x 800 Pixel für Ihre App hoch. Dieses wird im App Marketplace und in den Konten verknüpfter Benutzer angezeigt. Bewährte Methoden für Symbole sind:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Richtig"
            }), ": eine JPG-, JPEG- oder PNG-Datei verwenden, den gesamten Bereich (800 x 800 Pixel) ausfüllen – Ihr Bild sollte mindestens zwei Ränder berühren, und ein unverpixeltes Bild in einer höheren Auflösung verwenden."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Falsch"
            }), ": Text in Ihr Symbol einfügen, eine Wortmarke verwenden oder zusätzlichen Leerraum um Ihr Symbol herum lassen."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/dev-doc-logo.png",
        alt: "dev-doc-logo"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App kategorisieren"
        }), " eine ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Kategorie"
        }), " für Ihre App aus sowie alle ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Suchbegriffe"
        }), ", mit denen Ihre App im App Marketplace gefunden werden kann."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "App-Details"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "App-Details"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Laden Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Demo-Video"
        }), " ein ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Video"
        }), " hoch, das zeigt, wie Ihre App funktioniert. Auf der Seite ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/apps/resources/how-to-make-a-great-app-demo-video",
          children: "How to Make a Great App Demo Video"
        }), " (So machen Sie ein großartiges App-Demo-Video) finden Sie Best Practices und Beispiele zum Erstellen eines Demo-Videos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Screenshots"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bilder"
        }), " und ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ALT-Text"
        }), " hinzu, die zeigen, wie Ihre App funktioniert. Sie können bis zu acht Bilder hinzufügen.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Geteilte Daten hinzufügen"
            }), ". Informieren Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Geteilte Daten"
            }), " die Benutzer über den Datenfluss zwischen Ihrer App und HubSpot."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wählen Sie im rechten Bereich aus, welches App-Objekt mit welchem HubSpot-Objekt synchronisiert wird und in welche Richtung die Synchronisierung erfolgen soll. Stellen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "App-Übersicht"
            }), " eine kurze ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Beschreibung"
            }), " bereit, wie Ihre App Benutzern helfen kann, ihre Ziele umzusetzen."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Um ein weiteres Objekt zu synchronisieren, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Weiteres Objekt hinzufügen"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/shared-data-app-listing.png",
        alt: "shared-data-app-listing"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "HubSpot-Funktionen, mit denen Ihre App arbeitet"
        }), ", bis zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "10 HubSpot-Tools und -Funktionen"
        }), " hinzu, mit denen Ihre App arbeitet."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sonstige Tools, mit denen Ihre Software integriert werden kann"
        }), " bis zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "sechs andere Tools oder Apps"
        }), " aus, in die Ihre Software integriert werden kann."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sprachen, in denen Ihre App verfügbar ist"
        }), " alle ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sprachen"
        }), " aus, die für Ihre App verfügbar sind. Zusätzliche App Marketplace-Listings können nur in diesen Sprachen erstellt werden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Preise"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Preise"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wählen Sie im oberen Abschnitt die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Währung"
          }), " aus, in der Sie die App listen möchten. Sie können aus über 100 Währungen auswählen."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Sie können auch ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Preispläne"
          }), " für Ihre App einrichten, indem Sie das ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Preismodell"
          }), ", den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Namen des Plans"
          }), ", den ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Slogan"
          }), ", die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Preisdetails"
          }), " und die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Liste der Funktionen"
          }), " hinzufügen."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Je nach gewähltem Preismodell müssen Sie möglicherweise weitere Informationen hinzufügen, z. B. das Zahlungsintervall, einmalige Gebühren oder monatliche Preise. Bewegen Sie den Mauszeiger über das ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Info-Symbol"
              }), ", um mehr über die einzelnen Preismodelle zu erfahren."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/pricing-model-app-listing.png",
                alt: "pricing-model-app-listing"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Sie können bis zu fünf Preispläne hinzufügen. Um weitere Preispläne hinzuzufügen, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Weiteren Plan hinzufügen"
              }), "."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Geben Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Link zu den Preisplänen Ihrer Software"
          }), " die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " ein, unter der Benutzer weitere Informationen zu Ihren Preisplänen finden können."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Geben Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Agenturpreispläne"
          }), " die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " ein, unter der die Benutzer mehr über die Preise für Partner- oder Beratungsdienste erfahren können."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "App-Funktionen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "App-Funktionen"
      }), " können Sie Funktionen hinzufügen und Kunden erklären, wie sie diese nutzen können. Es gibt kein Limit für die Anzahl der App-Funktionen, die für Ihre App erstellt werden können."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eine Funktion hinzufügen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Auf der Seite ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Details der Funktion"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Geben Sie den ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Namen Ihrer Funktion"
            }), " ein."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wählen Sie alle ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Bereichsgruppen"
            }), " aus, die ein Kunde benötigt, um diese Funktion zu nutzen. Bereichsgruppen werden verwendet, um zu bestimmen, ob der HubSpot-Account des Kunden mit den App-Funktionen kompatibel ist."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Geben Sie eine ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Beschreibung"
            }), " ein."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wählen Sie ein ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Bild"
            }), " aus."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Wählen Sie aus, wo Sie die Funktion anzeigen möchten: ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Im Marketplace-Listing Ihrer App"
            }), " oder ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "als „Funktionen entdecken“-Karte"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können maximal fünf App-Funktionen im App Marketplace-Listing und maximal 10 App-Funktionen als „Funktionen entdecken“-Karten anzeigen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie auswählen, dass die Funktion als „Funktionen entdecken“-Karte anzuzeigen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie eine primäre Schaltfläche aus:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Link zu einer Funktion:"
            }), " Wählen Sie aus, zu welcher HubSpot-Funktion die Schaltfläche verlinken soll."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Benutzerdefinierte erstellen"
            }), ": Geben Sie den ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Schaltflächentext"
            }), " und die ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "URL der Schaltfläche"
            }), " ein."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Keine primäre Schaltfläche"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wählen Sie eine Anleitung für das Onboarding Ihrer Kunden aus:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Komplett neue Anleitung erstellen:"
            }), " Geben Sie einen ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Titel"
            }), ", eine ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Beschreibung"
            }), " und ein ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Bild oder Video"
            }), " ein."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Externer Link zur Anleitung:"
            }), " Geben Sie eine ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Leitfaden-URL"
            }), " ein."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nur-Video-Anleitung:"
            }), " Laden Sie ein ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Video"
            }), " hoch."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um eine weitere Anleitung hinzuzufügen, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Einen weiteren Abschnitt hinzufügen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/feature-discovery-card.png",
        alt: "feature-discovery-card"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Support-Informationen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Support-Informationen"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Kontaktinformationen"
        }), " eine Support-Kontaktmethode für Benutzer hinzu, die bei der Verwendung Ihrer App Fragen haben. Fügen Sie Ihre ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Support-E-Mail-Adresse"
        }), ", die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Website des Unternehmens"
        }), " und die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sprachen"
        }), " hinzu, in denen Kundenservice angeboten wird."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Support-Ressourcen"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Links"
        }), " zur Setup-Dokumentation Ihrer App an."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fügen Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Nutzungsbedingungen und Datenschutzrichtlinie"
        }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Links"
        }), " zu Ihrer Datenschutzdokumentation hinzu."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die Informationen überprüfen und Ihr Listing einreichen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Auf der Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Überprüfungsinformationen"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Anweisungen zur App-Überprüfung"
        }), " alle ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "entsprechenden Anweisungen"
        }), " ein, die das HubSpot Ecosystem Quality-Team benötigt, um Ihre App zu testen und zu überprüfen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie im Abschnitt ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Ansprechpartner für das App-Partnerprogramm"
        }), " die Kontaktinformationen aller Personen, mit denen bezüglich des App-Listings kommuniziert werden muss, sowie den Entwickler der App an. Sie müssen die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rolle"
        }), " der Person, den ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vor- und Nachnamen"
        }), ", die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "E-Mail-Adresse"
        }), " und das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Land"
        }), ", in dem sie ansässig ist, angeben."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um einen weiteren Ansprechpartner hinzuzufügen, klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Weiteren Ansprechpartner hinzufügen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um das Listing zur Überprüfung einzureichen, klicken Sie in der oberen rechten Ecke auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Zur Überprüfung einreichen"
      }), ". Ist die Schaltfläche ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Zur Überprüfung einreichen"
      }), " ausgegraut, müssen Sie sicherstellen, dass Sie alle erforderlichen Felder ausgefüllt haben und über ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Super-Admin"
      }), "-Berechtigungen verfügen. Wenn Sie ein Pflichtfeld übersehen haben, wird in der Registerkartenüberschrift eine Zahl angezeigt, die die Anzahl der übersehenen Felder angibt. Klicken Sie auf jede Registerkarte, um die fehlenden Informationen einzugeben. Kehren Sie dann zur Registerkarte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Überprüfungsinformationen"
      }), " zurück und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Zur Überprüfung einreichen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-features.png",
        alt: "app-features"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein lokalisiertes Listing für ein bestehendes App-Listing erstellen und aktualisieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie müssen eine primäre Sprache für Ihr bestehendes App-Listing festlegen und Ihr primäres Listing muss bereits im HubSpot Marketplace veröffentlicht sein, um Listings in anderen Sprachen erstellen zu können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie in Ihrem App-Entwickler-Account von HubSpot zu ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "App Marketplace"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listings"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wenn Sie bereits eine App im Marketplace gelistet haben, sehen Sie ein gelbes Banner über der gelisteten App, in dem Sie aufgefordert werden, die Sprache Ihres primären Listings festzulegen. Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Jetzt festlegen"
          }), ". Sie müssen die primäre Listing-Sprache für das App-Listing festlegen, bevor Sie Listings in neuen Sprachen erstellen können."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie im Dialogfeld auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sprachen auswählen, in denen Ihre App verfügbar ist"
          }), " und wählen Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Sprachen"
          }), " aus, in denen Ihre App-Software verfügbar ist."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Primäre Listing-Sprache für [Name der App] auswählen"
          }), " und wählen Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Standardsprache"
          }), " aus, die Benutzer dann beim Durchsuchen des App Marketplace sehen."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Speichern als."
          })]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sobald Sie eine primäre Sprache festgelegt haben, können Sie ein neues, lokalisiertes Listing hinzufügen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bewegen Sie den Mauszeiger über das ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App-Listing"
        }), " und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mehr"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listing in einer anderen Sprache erstellen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf das Dropdown-Menü ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sprache für dieses Listing"
        }), " und wählen Sie die ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sprache"
        }), " aus, in der Sie das Listing erstellen möchten."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Erstellen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Folgen Sie den Schritten, um ein Listing in der ausgewählten Sprache zu erstellen und einzureichen."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Alle Teile Ihres lokalisierten Listings sollten in derselben Sprache sein, einschließlich Produkt-Screenshots und Demo-Videos."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Wenn Sie Ihr primäres Listing aktualisieren, werden Ihre lokalisierten Listings nicht automatisch aktualisiert. Jedes muss unabhängig aktualisiert werden."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ein Live-App-Listing bearbeiten"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sobald ein App-Listing im App Marketplace veröffentlicht ist, können Sie das Listing bearbeiten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in Ihrem Entwickler-Account zu ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Marketplace > Listings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bewegen Sie den Mauszeiger über das Listing, das Sie bearbeiten möchten, und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mehr"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bearbeiten"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Nehmen Sie Ihre Änderungen vor und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Zur Überprüfung einreichen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Die Veröffentlichung eines Live-App-Listing rückgängig machen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Klicken Sie in Ihrem Entwickler-Account auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bewegen Sie den Mauszeiger über das Listing, das Sie veröffentlichen möchten, und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mehr"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Veröffentlichung von Live-Listing rückgängig machen"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Geben Sie im Dialogfeld den Grund für das Rückgängigmachen der Veröffentlichung der App ein und klicken Sie auf ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Anfrage zum Rückgängigmachen der Veröffentlichung übermitteln"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Anfrage zum Rückgängigmachen einer Veröffentlichung werden vom HubSpot Marketplace-Team innerhalb von 10 Werktagen nach Einreichung bearbeitet."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}