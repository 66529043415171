"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76633369011;
const slug = exports.slug = 'guides/cms/marketplace/theme-requirements';
const title = exports.title = 'HubSpot Template Marketplace theme requirements';
const name = exports.name = 'Template Marketplace | Theme requirements (2022)';
const metaDescription = exports.metaDescription = 'Learn more about the requirements that a theme needs to meet to be submitted to the Asset Marketplace.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "theme-limits",
  "label": "Theme limits",
  "parent": null
}, {
  "depth": 0,
  "id": "overall-theme-requirements",
  "label": "Overall theme requirements",
  "parent": null
}, {
  "depth": 1,
  "id": "theme-file-structure",
  "label": "Theme file structure",
  "parent": "overall-theme-requirements"
}, {
  "depth": 1,
  "id": "relative-local-file-paths-for-templates",
  "label": "Relative local file paths for templates",
  "parent": "overall-theme-requirements"
}, {
  "depth": 0,
  "id": "theme-performance",
  "label": "Theme performance",
  "parent": null
}, {
  "depth": 1,
  "id": "preview-urls-for-themes",
  "label": "Preview URLs for themes",
  "parent": "theme-performance"
}, {
  "depth": 0,
  "id": "using-jquery",
  "label": "Using jQuery",
  "parent": null
}, {
  "depth": 0,
  "id": "theme-configuration-theme.json",
  "label": "Theme configuration (Theme.json)",
  "parent": null
}, {
  "depth": 0,
  "id": "theme-settings-fields.json",
  "label": "Theme settings (Fields.json)",
  "parent": null
}, {
  "depth": 0,
  "id": "templates-css%2C-sections%2C-page-templates%2C-etc.",
  "label": "Templates (CSS, Sections, Page Templates, etc.)",
  "parent": null
}, {
  "depth": 1,
  "id": "sections",
  "label": "Sections",
  "parent": "templates-css%2C-sections%2C-page-templates%2C-etc."
}, {
  "depth": 1,
  "id": "page-templates",
  "label": "Page templates",
  "parent": "templates-css%2C-sections%2C-page-templates%2C-etc."
}, {
  "depth": 1,
  "id": "page-template-naming",
  "label": "Page template naming",
  "parent": "templates-css%2C-sections%2C-page-templates%2C-etc."
}, {
  "depth": 0,
  "id": "modules",
  "label": "Modules",
  "parent": null
}, {
  "depth": 0,
  "id": "global-content",
  "label": "Global content",
  "parent": null
}, {
  "depth": 1,
  "id": "global-partials",
  "label": "Global partials",
  "parent": "global-content"
}, {
  "depth": 0,
  "id": "multi-language-support",
  "label": "Multi-Language support",
  "parent": null
}, {
  "depth": 0,
  "id": "mobile-and-responsive-elements",
  "label": "Mobile and responsive elements",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      Tabs,
      Tab,
      IFrame
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot Template Marketplace theme requirements"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Learn more about the requirements a theme must meet for submission to the HubSpot Template Marketplace."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you are just starting out on your theme creation journey, we highly recommend using our free CMS theme boilerplate, which can be downloaded from our ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "Git Repository"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.youtube.com/watch",
        children: "imported within your account’s design manager UI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition, review the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "template listing page requirements"
      }), " when submitting your template to the HubSpot Template Marketplace."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Theme limits"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Keep in mind the following limits per theme:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Free ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "CMS Hub"
            })
          }), " accounts cannot use site search, CTA functionality, or native HubSpot video. Learn more about what's included with ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "CMS Hub"
            })
          }), " subscriptions in ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
            children: "HubSpot's Product & Services catalog"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Themes cannot contain more than:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 templates"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 modules"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 sections"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Themes ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "must not"
          }), " contain:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Email templates"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubDB functionality"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Serverless functions"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "CRM object fields"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/drag-and-drop-templates#flexible-columns",
              children: "Flexible columns in templates"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modules vs. Sections"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Sections are helpful because content creators can only drop them in full-width drop zones on the page, which helps you as a developer guarantee a great finished product."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "In particular, fixed-layout sections, where the content creator cannot move elements within the section around, are a great tool to provide creative formatting and layout that couldn’t otherwise be achieved using the drag-and-drop editor."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Sections also offer extra usability benefits for the content creator because they can select individual modules inside the section, making it so that their module forms are shorter and more targeted to the element they’re editing."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Overall theme requirements"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["All submitted themes must be distinct and original. For example, the same theme with different copy or placeholder content does not count as a distinct theme. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/template-policies#template-marketplace-compliance",
          children: "Learn more about HubSpot Template Marketplace compliance"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A theme must be built with HTML and HubL templates, and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), " tags."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Themes must respect a 12-column grid."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Theme file structure"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All themes should contain a proper folder structure and be grouped under one parent folder, which should describe your product listing. For example, if you build a theme named “SuperAwesome” for the marketplace, your structure should look similar to the image below. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-file-structure",
        children: "theme file structure"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/template-name.png",
          alt: "template-name"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/file-structure.png",
          alt: "file-structure"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Relative local file paths for templates"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You ", (0, _jsxRuntime.jsx)("u", {
        children: "must"
      }), " use relative local file paths when referring to theme assets. The best way to include these is to use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#get-asset-url",
        children: "get_asset_url function"
      }), ", which returns the public URL of an asset, file, or template. You can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#get-asset-url",
        children: "generate this function"
      }), " by either right-clicking a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "file"
      }), " and selecting ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copy public URL"
      }), ", or by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Actions"
      }), ", then selecting ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copy public URL"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, a stylesheet referenced by ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "get_asset_url"
      }), " must be formatted as follows:"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{require_css(get_asset_url('../../css/main.css')) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "//cdn2.hubspot.net/hub/1234567/hub_generated/template_assets/1565970767575/custom/styles/style.min.css\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the video below, review the differences in file structure in your developer account versus files delivered to a marketplace customer:"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/SSOdhqOciuk?feature=oembed",
      maxHeight: "144.64px",
      maxWidth: "256px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Theme performance"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using Google Lighthouse, a theme must score ", (0, _jsxRuntime.jsx)("u", {
        children: "higher"
      }), " than the following thresholds:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Desktop accessibility:"
        }), " 65"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Desktop best practices:"
        }), " 80"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Desktop performance:"
        }), " 70"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mobile performance:"
        }), " 40"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#evaluate-themes-and-templates-for-seo-and-accessibility",
        children: "generate a Google Lighthouse report for your theme using the CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Theme files should be able to be minified."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "All image files should be under 1MB in size."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["All image tags should have an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "alt"
        }), " attribute (a value of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"\""
        }), " is acceptable)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["All image tags should have a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "loading"
        }), " attribute (a value of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"\""
        }), " is acceptable)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Preview URLs for themes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You must use your own domain name when creating preview URLs. You cannot use the HubSpot-provided domain with this URL structure: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[AccountID].hs-sites.com"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A live website and not an image of the demo site must be used."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If at any point your live demo becomes inaccessible, HubSpot reserves the right, with notification to the provider, to delist/remove your theme until the live demo becomes accessible again."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Using jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["jQuery is ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/include-jquery-across-your-hubspot-pages",
        children: "not enabled by default"
      }), " in a customer's HubSpot account. If your theme relies on jQuery, a version of the jQuery must be included to ensure the theme works as expected."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you include a module that requires jQuery when the rest of the site doesn’t, you need to use the following code to load jQuery:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# this checks if the \"Include jQuery\" option in Settings > CMS > Pages is checked #}\n{% if not site_settings.include_jquery %}\n  {{ require_js(\"../jquery-3.4.1.js\", \"footer\") }}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Theme configuration (Theme.json)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " file must include the following parameters:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// theme.json\n{\n  \"label\": \"Cool Theme\",\n  \"preview_path\": \"./templates/home-page.html\",\n  \"screenshot_path\": \"./images/templates/homepage.jpg\",\n  \"enable_domain_stylesheets\": false,\n  \"version\": \"1.0\",\n  \"author\": {\n    \"name\": \"Jon McLaren\",\n    \"email\": \"noreply@hubspot.com\",\n    \"url\": \"https://theme-provider.com/\"\n  },\n  \"documentation_url\": \"https://theme-provider.com/cool-theme/documentation\",\n  \"license\": \"./license.txt\",\n  \"example_url\": \"https://theme-provider.com/cool-theme/demo\",\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Please check your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " file and ensure the following:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The label name matches the name in your theme listing."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If you're using HubSpot's free CMS theme boilerplate, boilerplate values must not be present. This includes author information, documentation URL, example URL, etc."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The documentation URL resolves and has documentation on how to use your theme."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The preview path is a valid file in your theme."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The screenshot path is a valid file and is related to your theme."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The example URL resolves and leads to a demo of your theme. Do not use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "preview.hs-sites.com"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[AccountID].hs-sites.com"
        }), " subdomains for the example URL."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-json",
        children: "theme.json parameters"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Theme settings (Fields.json)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " file controls the available fields and fields groups in the theme editor, including style fields. The fields you include will depend on how much control you want content creators to have in the page editor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " file must contain at least three color fields."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To ensure compatibility between themes and independent modules, themes must include the following font and color standard naming conventions: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "heading_font"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "body_font"
        }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/beta-docs/guides/cms/marketplace/template-policies#module-compatibility-with-themes",
          children: "module-theme compatibility"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If theme fields do not have ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primary_color"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secondary_color"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "heading_font"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body_font"
      }), " fields, they can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "alternate_names"
      }), " field."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about these ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#fields-json",
        children: "fields.json parameters"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/fields.json",
        children: "review an example fields.json file"
      }), " from the HubSpot CMS boilerplate."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Theme settings must also:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Not conflict with your editor styles or styles set through a module. For example, do not use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "!important"
        }), " in your CSS stylesheet as it makes it difficult for end users to override and would cause a conflict."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use descriptive labels for each setting so that content creators know what they're updating."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Apply to all templates in a theme, unless there's a specific use case for additional styles. For example, changes to the style and size of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " headings in theme settings must apply across all ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " tags in the theme."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["At a minimum, a theme ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "must"
      }), " include the following theme fields:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Typography fields:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Body text font fields (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), " tags)"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " through ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h6"
        }), " font fields"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Hyperlink color (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "a"
        }), " tags), including hover styling"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Form fields:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Form background color"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Form border color"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Form label color"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Form field border color"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Form button - this includes settings for button text, background color, and hover styling."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Fields inside of your theme must be grouped logically where appropriate. For example, multiple fields related to typography should be grouped under a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Typography"
        }), " group."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Theme fields should have separate color and font controls for buttons and forms, as well as separate color, logo, and font controls for the header and footer."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A portion of the theme's color and logo fields must inherit from the account's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/brand-and-settings-inheritance",
          children: "brand settings"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "At a minimum, two color fields must inherit colors from the account's brand settings. Additional color fields can default to other colors, including black and white."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If modules within a theme are using logos, at least one logo field must inherit from the account's brand settings. If using an image field to render a logo, the image field does not have to inherit from the brand settings."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "How brand colors impact your theme's aesthetics"
      })
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/voyJupcZYeQ?feature=oembed",
      maxHeight: "144.64px",
      maxWidth: "256px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Templates (CSS, Sections, Page Templates, etc.)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sections"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You must use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections#reusable-sections",
          children: "sections"
        }), " wherever applicable. There ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "must"
        }), " be a minimum of five sections in a theme."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sections must have unique and working screenshots."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sections and modules should not be redundant."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Page templates"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["At a minimum, a theme ", (0, _jsxRuntime.jsx)("u", {
        children: "must"
      }), " include the following template types:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "A website page template or landing page template."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["When including multiple-page templates, each template must have a distinct purpose. For example, a home page, an ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "About Us"
            }), " page, a full-width landing page, and a landing page with a right sidebar."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "It is recommended to include at least eight page templates in a theme."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Separate blog listing and blog post templates."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Blog listing template:"
            }), " the page that shows all blog posts in a listing format (known as the blogroll). The template title must reflect that it's for the listing page."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Blog post template:"
            }), " the blog post detail page that displays individual blog posts. The template title must reflect that it's for the blog post page."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "In addition, blog comments and blog author boxes must be styled to match the theme."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "The following system page templates:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "404 error template:"
            }), " shown when visitors hit a page that doesn't exist."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "500 error template:"
            }), " shown when the site encounters an internal error."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Password prompt template:"
            }), " shown when a page is password protected."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Subscription template:"
            }), " a subscription preferences page where email recipients can manage the types of emails they're subscribed to."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Subscriptions update template:"
            }), " a confirmation page that appears when an email recipient updates their email subscription preferences."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Backup unsubscribe template:"
            }), " the page that appears for email recipients who are trying to unsubscribe if HubSpot is unable to determine their email address."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Search results template:"
            }), " displays search results returned when using the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/content-search",
              children: "site search"
            }), ". Available for paid ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "CMS Hub"
              })
            }), " accounts only."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Page template naming"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If you have templates with similar names, add descriptive words that denote the difference between them."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Keep capitalization consistent, remove hyphens, and avoid using shorthand (e.g. spell out background instead of using bg)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Your company name or theme name does not need to be included in the template name."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about the requirements for theme modules and individual modules ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/module-requirements",
        children: "here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Global content"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Global partials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "Global partials"
      }), " are a type of template built using HTML and HubL to can be reused across your entire website. The most common type of partials are website headers, page sidebars, and website footers. Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content#creating-a-global-content-partial-template-using-local-development-tools",
        children: "create global partials"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Themes ", (0, _jsxRuntime.jsx)("u", {
          children: "must"
        }), " include global partials."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Global partials ", (0, _jsxRuntime.jsx)("u", {
          children: "must"
        }), " include usable ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "drag and drop areas"
        }), ". For example, you cannot hide the drag and drop area with a \"hide\" class."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You ", (0, _jsxRuntime.jsx)("u", {
          children: "must"
        }), " incorporate usable drag-and-drop areas in headers and footers."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For menus that are used globally throughout a site, users must also be able to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/menus-and-navigation",
          children: "select a HubSpot navigation menu"
        }), " they've created in their account settings."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": avoid including global modules within global partials, as it can create a negative end-user experience."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Multi-Language support"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Themes ", (0, _jsxRuntime.jsx)("u", {
        children: "must"
      }), " be able to support multiple language versions and should specify the languages that they support. This can be done by adding the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#language-switcher",
        children: "language switcher module"
      }), " in a global header, which allows customers to easily locate the language options and choose their desired language."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You ", (0, _jsxRuntime.jsx)("u", {
          children: "must"
        }), " only display one language at a time. For example, avoid having both English and Spanish in the UI at the same time."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Avoid using hard-coded text. For example, rather than hard-coding a blog listing button’s text as ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Read More"
        }), ", set the text within a field so that the end user can update the text without having to go into the code."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mobile and responsive elements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Themes should be capable of adapting their content to the device it is being viewed on. They should also provide a good user experience across various devices. This includes, but is not limited to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Main navigation"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sliders and tabs"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Large images"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Avoiding horizontal scrolling (unless intentional)"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}