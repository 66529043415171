"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831430;
const slug = exports.slug = 'reference/cms/hubl/tags/related-blog-posts';
const title = exports.title = 'Criar uma listagem de posts de blog relacionados com a tag de posts de blog relacionados do HubL';
const name = exports.name = 'PT BR Creating a related blog post listing with the blog related posts HubL tag';
const metaDescription = exports.metaDescription = 'A tag related_blog_posts do HubL pode ser usada para criar uma listagem dinâmica de posts de blog relacionados com base em uma variedade de parâmetros. Ela permite gerar listagens de posts de blog, com a capacidade de filtrar por tags, autores, caminhos de posts e datas de publicação.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "par%C3%A2metros",
  "label": "Parâmetros",
  "parent": null
}, {
  "depth": 0,
  "id": "exemplos-de-usos-da-tag-related_blog_posts-do-hubl",
  "label": "Exemplos de usos da tag related_blog_posts do hubL",
  "parent": null
}, {
  "depth": 1,
  "id": "exibir-os-posts-de-um-autor-espec%C3%ADfico-em-v%C3%A1rios-blogs",
  "label": "Exibir os posts de um autor específico em vários blogs",
  "parent": "exemplos-de-usos-da-tag-related_blog_posts-do-hubl"
}, {
  "depth": 1,
  "id": "exibir-posts-com-a-tag-%22sales-enablement%22%2C-restritos-a-um-intervalo-de-datas-de-publica%C3%A7%C3%A3o-espec%C3%ADfico",
  "label": "Exibir posts com a tag \"sales enablement\", restritos a um intervalo de datas de publicação específico",
  "parent": "exemplos-de-usos-da-tag-related_blog_posts-do-hubl"
}, {
  "depth": 1,
  "id": "exibir-posts-usando-um-retorno-de-chamada-js-para-controlar-a-sa%C3%ADda-html",
  "label": "Exibir posts usando um retorno de chamada JS para controlar a saída HTML",
  "parent": "exemplos-de-usos-da-tag-related_blog_posts-do-hubl"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Criar uma listagem de posts de blog relacionados com a tag de posts de blog relacionados do HubL"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A tag ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#blog-related-posts",
        children: "related_blog_posts"
      }), " do HubL pode ser usada para criar uma listagem dinâmica de posts de blog relacionados com base em uma variedade de parâmetros. Ela permite gerar listagens de posts de blog, com a capacidade de filtrar por tags, autores, caminhos de posts e datas de publicação. Os desenvolvedores também podem especificar a saída HTML do módulo usando uma macro. Esta tag do HubL pode ser usada em posts de blog e páginas. Este tutorial mostrará os parâmetros e opções de uso para a tag de posts de blog relacionados do HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Observe que a tag ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "related_blog_posts"
        }), " do HubL não gera um módulo editável em nível de post/página; ela é totalmente configurada com HubL."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parâmetros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A lista de posts é gerada a partir de uma pontuação de relevância com base em uma comparação entre os valores dos parâmetros definidos e os posts correspondentes a esses parâmetros ou relacionados ao post na qual a tag do HubL é exibida. Nenhum dos parâmetros é obrigatório; no entanto, especificá-los permitirá controlar ainda mais quais posts são retornados. Para parâmetros separados por vírgula, quanto mais valores você definir, mais diversificada será a listagem retornada. O parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "post_formatter"
      }), " permite especificar uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "macro"
      }), " para gerar a saída HTML do módulo. Para obter uma lista completa dos parâmetros e exemplos da saída HTML padrão, consulte a especificação de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#blog-related-posts",
        children: "related_blog_posts"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número máximo de posts de blog para listar."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "'default' or blog id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do blog do qual incluir os posts."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "As tags que devem ser usadas para determinar se um post é relevante (separadas por vírgula). Se um post de blog tiver uma dessas tags ou uma tag semelhante, a relevância dele será aumentada, melhorando sua classificação na listagem."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_authors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os nomes dos autores dos quais incluir os posts(separados por vírgula)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_post_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID de post de blog para usar ao encontrar posts de blog relevantes para listar (separadas por vírgula). Este parâmetro somente deve ser usado quando o widget for exibido nas páginas, pois nos posts de blog, o padrão será o post no qual o widget é exibido."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "post_formatter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O nome de uma macro personalizada para renderizar os posts de blog retornados. A macro recebe três parâmetros que são o objeto de post de blog para formatar, a contagem na iteração de posts de blog e a contagem total de posts de blog nos resultados. Se não for especificado ou definido como \"padrão\", o formatador interno será usado para formatar cada post.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nota: é recomendado usar o parâmetro callback abaixo no lugar do parâmetro \"post_formatter\", pois o HTML da tag será renderizado mais rapidamente, diminuindo os tempos de carregamento de página."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "callback"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome de uma função javascript para renderizar os posts de blog retornados. A função recebe um array de objetos de post de blog para formatar. Se os parâmetros callback ou post_formatter forem especificados, a tag gerará HTML em um formato padrão."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "path_prefixes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Caminhos de URL ou subdiretórios dos quais incluir os posts (separados por vírgula). Se um post de blog tiver um prefixo semelhante em seu caminho, a relevância dele será aumentada, melhorando sua classificação na listagem."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "start_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "date/time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permite a filtragem de posts publicados após uma data/hora."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "end_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Date/Time"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permite a filtragem de posts publicados antes de uma data/hora."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog_post_override"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os Ids de um post de blog que sempre devem ser exibidos na listagem retornada, apesar de todos os outros valores de parâmetros e filtros (separados por vírgula)."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "none"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Recomendamos fortemente o uso do parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "callback"
        }), " em vez do parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "post_formatter"
        }), " para garantir tempos de carregamento de página mais rápidos."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Observe que, se a tag ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "related_blog_posts"
        }), " do HubL for usada em um post, o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_post_ids"
        }), " não deverá ser especificado, pois nos posts do blog, o padrão será o post no qual o widget é exibido."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exemplos de usos da tag related_blog_posts do hubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo estão alguns exemplos de maneiras de usar essa tag para aprimorar seu site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exibir os posts de um autor específico em vários blogs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Neste exemplo, geramos uma lista de posts escritos por um dos três ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_authors"
      }), " especificados em dois blogs diferentes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts blog_ids=\"3241539189,3261083894\", limit=6, blog_authors=\"John Smith,Joe Smith,Frank Smith\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exibir posts com a tag \"sales enablement\", restritos a um intervalo de datas de publicação específico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Neste exemplo, geramos uma lista de 10 posts relacionados a um post de blog específico, com a tag \"sales enablement\" e restritos a um intervalo de datas de publicação específico. Este exemplo especifica o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "blog_post_ids"
      }), ", então ele seria usado em uma página."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts blog_post_ids=\"3267910554\", limit=10, tags=\"sales enablement\", start_date=\"2018-02-05\", end_date=\"2018-06-10\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exibir posts usando um retorno de chamada JS para controlar a saída HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Neste exemplo, geramos uma listagem de 5 posts usando o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "callback"
      }), " para controlar a saída HTML da listagem de posts. (Em vez do parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "post_formatter"
      }), " usando uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "macro"
      }), ".)"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% related_blog_posts limit=5, callback=\"blog_post_formatter\" %}\n\n<script>\n  var blog_post_formatter = function(blogposts) {\n\n    var formatted = \"<div>\";\n    for (var i = 0; i < blogposts.length; i++) {\n      var blogpost = blogposts[i];\n      formatted += '<div class=\"related-blog-item\">';\n      formatted += `<span>Related Post ${i + 1}/${blogposts.length}</span><br>`;\n      formatted += `<a class=\"related-blog-title\" href=\"${blogpost.url}\"><span>${blogpost.name}</span></a>`;\n      formatted += `<div class=\"hs-related-blog-byline\">by <span class=\"related-blog-author\">${blogpost.blogAuthor.fullName}</span><span class=\"related-blog-posted-at\"> posted on </span><span class=\"related-blog-date\">${new Date(blogpost.publishDate).toLocaleDateString()}</span></div>`;\n      formatted += `<p class=\"related-blog-post-summary\">${blogpost.postSummary}<a href=\"${blogpost.url}\">Read more</a></p>`;\n      formatted += '<div class=\"related-blog-tags\">';\n      if (blogpost.tagList.length > 0) {\n        formatted += `Tags: ${blogpost.tagList.map(tag => tag.label).join(\", \")}`;\n      }\n      formatted += '</div>';\n\n      if (blogpost.featuredImage) {\n        formatted += `<img src=\"${blogpost.featuredImage}\" alt=\"${blogpost.featuredImageAltText}\">`;\n      }\n      formatted += '</div>';\n    }\n    formatted += '</div>';\n    return formatted;\n  }\n</script>\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}