"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129044610;
const slug = exports.slug = 'guides/cms/tools/personal-access-key';
const title = exports.title = "Clé d'accès CMS personnelle";
const name = exports.name = 'EMEA FR Personal CMS Access Keys';
const metaDescription = exports.metaDescription = "Les clés d'accès CMS personnelles sont une forme d'authentification destinée à être utilisée par des développeurs travaillant sur HubSpot. Elles sont liées à un utilisateur spécifique dans un compte.";
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h3: "h3",
      h4: "h4",
      code: "code",
      img: "img",
      a: "a",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Clé d'accès personnelle"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Les clés d'accès personnelles sont le moyen recommandé pour s'authentifier avec les outils de développement local."
      }), " Les clés d'accès personnelles fonctionnent de la même manière que les clés d'API, mais sont liées à un utilisateur spécifique dans un compte. Les clés d'accès personnelles sont compatibles uniquement avec des outils de développement local."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comparaison entre les clés d'accès personnelles et les autres méthodes d'authentification"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'avantage des clés d'accès personnelles sur des implémentations telles que les clés d'API est que ces dernières ont des autorisations de super administrateur. Les clés d'accès personnelles sont limitées aux autorisations dont dispose l'utilisateur individuel dans le portail. Si l'utilisateur est un super administrateur, il ne voit aucune différence dans leurs fonctionnalités, mais l'avantage est que si, par exemple, un développeur individuel doit être supprimé d'un compte, le fait de désactiver son utilisateur sur le compte désactivera ses capacités de développement local."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Étant donné que les clés d'accès personnelles sont liées à l'utilisateur individuel dans un compte, nous sommes en mesure d'afficher des informations plus utiles, par exemple, si un développeur modifie ou télécharge un fichier à l'aide des outils de développement local tout en utilisant une clé d'accès personnelle, nous pouvons attribuer le changement dans l'application à cet utilisateur. Cela permet de travailler plus facilement avec les équipes et de comprendre qui a fait quoi."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les clés d'accès personnelles sont liées à l'utilisateur individuel dans le compte HubSpot spécifique et non à l'utilisateur directement. Autrement dit, en utilisant les outils de développement local, vous devrez générer une nouvelle clé d'accès personnelle pour chaque compte avec lequel vous souhaitez utiliser les outils de développement. Cela fournit une couche de sécurité pour les comptes, car un acteur malveillant obtenant votre clé d'accès ne serait alors en mesure d'affecter que les portails individuels et en tant qu'utilisateur individuel."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Similitude avec OAuth2"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Les clés d'accès personnelles agissent en fait comme OAuth2. Lorsque vous générez une clé d'accès personnelle, vous choisissez les autorisations que vous souhaitez que cette clé possède. Vous ne pouvez avoir qu'une seule clé d'accès par utilisateur et par compte HubSpot. Une fois que vous aurez généré votre clé d'accès, une application sera connectée à votre compte HubSpot, appelée « Outils de développement local HubSpot ». Cette application HubSpot de premier niveau facilite l'authentification pour les outils de développement local lors de l'utilisation d'une clé d'accès personnelle. La déconnexion de cette application supprimera toutes les clés d'accès que vous avez générées précédemment. Ainsi, vos outils de développement local ne pourront plus se connecter via ces clés d'accès. Vous devrez générer une nouvelle clé et mettre à jour votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot.config.yml"
            }), "."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/personalcmsaccesskey.png",
            alt: "personalcmsaccesskey"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Protégez vos identifiants"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Protégez vos clés d'accès personnelles comme s'il s'agissait du mot de passe de votre compte. Ne les partagez avec personne. Toute personne disposant de ces clés peut s'authentifier comme s'il s'agissait de vous et mener toutes les actions que vous pouvez effectuer en votre nom."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Utilisation des clés d'accès personnelles avec les outils de développement local"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les clés d'accès personnelles ont été conçues pour être utilisées avec des outils de développement local."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "Découvrez les outils de développement local"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://app.hubspot.com/l/personal-access-key",
        children: "Consultez votre clé d'accès CMS personnelle."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'il est utilisé pour l'authentification dans les outils de développement local, votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " ressemblera à ceci :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: production\nportals:\n  - name: production\n    portalId: <portalId>\n    authType: personalaccesskey\n    personalAccessKey: >-\n      CJDVnLanLRICEQIYyLu8LyDh9E4opf1GMhkAxGuU5XN_O2O2QhX0khw7cwIkPkBRHye-OfIADgLBAAADAIADAAAAAAAAAAJCGQC8a5TlhtSU8T-2mVLxOBpxS18aM42oGKk\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          CJDVnLanLRICEQIYyLu8LyDh9E4opf1GMhkAxGuU5XN_O2O2QhX0khw7cwIkPkBRHye-OfIADgLBAAADAIADAAAAAAAAAAJCGQC8a5TlhtSU8T-2mVLxOBpxS18aM42oGKk\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}