"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844827005;
const slug = exports.slug = 'reference/cms/modules/export-to-template-context';
const title = exports.title = 'export_to_template_context';
const name = exports.name = '[new] export_to_template_context';
const metaDescription = exports.metaDescription = "export_to_template_context is a parameter that makes a module's parameters available to the template environment without actually rendering the module. This parameter can be used all HubL module tags. The widget_data tag is used to retrieve these parameters, store them in variables, and/or incorporate them into your template's logic.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "usage-within-custom-modules",
  "label": "Usage within custom modules",
  "parent": null
}, {
  "depth": 0,
  "id": "user-selectable-background-images",
  "label": "User selectable background images",
  "parent": null
}, {
  "depth": 0,
  "id": "choice-field-to-render-pre-defined-markup",
  "label": "Choice field to render pre-defined markup",
  "parent": null
}, {
  "depth": 0,
  "id": "retrieving-parameters-from-modules-already-rendered-on-the-template",
  "label": "Retrieving parameters from modules already rendered on the template",
  "parent": null
}, {
  "depth": 0,
  "id": "printing-hubl-module-info-on-blog-listing",
  "label": "Printing HubL module info on blog listing",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      pre: "pre",
      h2: "h2",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "export_to_template_context"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " is a parameter that makes a HubL tag's parameters available to the template environment without actually rendering the HubL tag. This parameter can be used with all ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "HubL tags"
      }), ". The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " dict is used to retrieve these parameters, store them in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "variables"
      }), ", and/or incorporate them into your template's logic."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "By making a HubL tag's parameters available in the template context, without actually rendering the HubL tag, you can allow users to make decisions in the content editor that affect how the template renders. For example, let's say that you want to only render a certain code block when the user gives a value to a field. This becomes possible with this parameter."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["First you must add ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context=True"
      }), " to the HubL tag. Then you must use a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data.module.parameter_you_want_to_retreive"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"job_title\" path=\"@hubspot/text\", label=\"Enter a Job Title\", value=\"Chief Morale Officer\", export_to_template_context=True %}\n{# Makes the parameters available to be stored and used in template logic #}\n\n{{ widget_data.job_title.body.value }}\n{# Prints the value of the HubL tag but can also be used in template logic #}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below are a few applications of this concept."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage within custom modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context=True"
      }), " is not supported in custom modules, as it serves no real purpose for them. You do not need to use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " to get the value of a module within a template, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#retrieving-parameters-from-modules-already-rendered-on-the-template",
        children: "you can already access it"
      }), ". If you need to visually hide the module's output you could build the module to not output anything, or include a boolean field that enables or disables whether the module renders anything."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "User selectable background images"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In this example, an image HubL tag is created but then exported to the context of the template rather than rendered. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src"
      }), " parameter is retrieved with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " tag and rendered as the source of a background image in a style tag."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"background_image\" path=\"@hubspot/image\" label='Select a background image',\nsrc='http://cdn2.hubspot.net/hub/428357/file-2117441560-jpg/img/dev-bg-compressed.jpg',\nexport_to_template_context=True %}\n\n\n<!--Sample markup -->\n<div class=\"bg-image-section\" style=\"background:url('{{ widget_data.background_image.src }}'); background-size:cover; min-height: 500px;\">\n    <h1>Supercharge your app with HubSpot</h1>\n    <h3>Build powerful integrations for a global community of users</h3>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While this is possible to do in coded templates, generally it is better to build a custom module to give users in the page editor the best experience. HubL tags like this show up as individual fields, whereas you may have multiple related fields. Using a custom module all of its fields display grouped in the page editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Choice field to render pre-defined markup"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following example uses the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " parameter in conjunction with a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#choice",
        children: "choice module"
      }), " to change a banner message on a careers page. The user selects a department via the UI and the heading changes without the user having to actually edit content."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"department\" path=\"@hubspot/choice\", label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?!</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This same functionality can actually be reproduced using a choice field inside of a custom module. The custom module UI actually makes choice options with both a value and a label pretty easy."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieving parameters from modules already rendered on the template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to retrieve a parameter from a module or tag that is already rendering on a page, the module can be accessed within a dict named ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgets"
      }), ". The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), " parameter is not required. The syntax is as follows:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// HubL\n{{ content.widgets.name_of_module.body.parameter }}\n\n{{ content.widgets.my_text.body.value }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the above method does not support retrieving values from fields in global modules, as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content.widgets"
        }), " won't access global modules."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Because different fields store data in different formats it is often handy to make use of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting#developer-info",
        children: "developer Info"
      }), " to see how you access the specific data you want to display."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Printing HubL module info on blog listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While blog templates are generally used for blogs, they can also be repurposed to create various other types of listings. You can use the techniques described above to achieve this."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, you may want to create a listing layout of press that your company has received, but rather than linking to posts, you want the listing to link to another page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can see this concept in action at ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/projects",
        children: "academy.hubspot.com/projects"
      }), ". The projects listing page is a blog listing template, but each post links to a regular HubSpot page. The content creator specifies the destination link in the editor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Within the head of the individual blog post's code, you would define a text field. If you don't want the text to render on the post, you would use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "export_to_template_context"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"custom_blog_link\" path=\"@hubspot/text\", label=\"Link to external press item\", export_to_template_context=True %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This text field is editable in each blog post. Next, we would need to define a link in our listing. But because the widget_data only exists in the context of the post, we need to use different syntax to fetch the widget data to populate the link. In this case, we would use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content.widgets.custom_blog_link.body.value"
      }), ". While the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_data"
      }), " is not available to the blog listing, the value of that field is still stored within the context of the individual content's widgets."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A basic blog listing loop that renders this custom link with each post is shown below. If using this technique, you would want to ensure that you add the subdirectory automatically created for each blog post to your robots.txt file to prevent those empty posts from being crawled by Google and other crawlers."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n<a href=\"{{ content.widgets.custom_blog_link.body.value }}\">\n    Click here to see this press feature!\n</a>\n{% endfor %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}