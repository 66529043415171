"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816705823;
const slug = exports.slug = 'guides/apps/marketplace/app-marketplace-listing-requirements';
const title = exports.title = 'Marketplace de aplicativos | Requisitos da lista do aplicativo';
const name = exports.name = 'LATAM BR (PT) App Listing Requirements';
const metaDescription = exports.metaDescription = 'Agora, os Parceiros de aplicativos podem ver em um só lugar as diretrizes e os requisitos da HubSpot necessários para que um aplicativo seja listado no Marketplace de aplicativos. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "requisitos-m%C3%ADnimos",
  "label": "Requisitos mínimos",
  "parent": null
}, {
  "depth": 1,
  "id": "requisitos-de-marca",
  "label": "Requisitos de marca",
  "parent": "requisitos-m%C3%ADnimos"
}, {
  "depth": 1,
  "id": "requisitos-da-lista",
  "label": "Requisitos da lista",
  "parent": "requisitos-m%C3%ADnimos"
}, {
  "depth": 0,
  "id": "revis%C3%A3o%2C-feedback-e-aprova%C3%A7%C3%A3o",
  "label": "Revisão, feedback e aprovação",
  "parent": null
}, {
  "depth": 0,
  "id": "recompensas-para-parceiros-de-aplicativos-listados",
  "label": "Recompensas para parceiros de aplicativos listados",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Requisitos para a listagem do Marketplace de aplicativos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os envios da lista de aplicativos são revisados manualmente pela nossa equipe de Qualidade do ecossistema da HubSpot e serão rejeitados se não atenderem aos critérios descritos abaixo. Quando o aplicativo atender a esses requisitos, você poderá ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "criar sua lista de aplicativos"
      }), " da conta de desenvolvedor de aplicativos, acessando ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Marketplace de aplicativos > Listas > Criar lista"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Requisitos mínimos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "ID único do aplicativo HubSpot:"
          }), " seu aplicativo deve autorizar solicitações de API com o ID público do aplicativo HubSpot (e o ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "ID do cliente OAuth"
          }), "), associado à sua lista de aplicativos."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Uma lista não deve ser redirecionada para um aplicativo público ou privado diferente."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "O seu aplicativo público listado não deve exigir o funcionamento de outro aplicativo público ou privado."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "O seu aplicativo público listado deve ser único. Se você já tiver listado um aplicativo e quiser substituí-lo, deverá atualizar o aplicativo existente em vez de listar um novo."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Não crie vários aplicativos que podem ser usados para resolver um mesmo caso de uso. Os aplicativos com funcionalidades semelhantes e que utilizam as mesmas APIs devem ser consolidadas em um único aplicativo."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "OAuth:"
          }), " o aplicativo deve usar OAuth como seu único método de autorização. Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "trabalhar com OAuth"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Instalações:"
          }), " o aplicativo deve ter, no mínimo, três ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/certification-requirements#:~:text=Active%20installs%20are%20the%20number%20of%20unique%20HubSpot%20production%20accounts%2C%20unaffiliated%20with%20your%20organization%2C%20showing%20successful%20app%20activity%20within%20the%20past%2030%20days.",
            children: "instalações ativas exclusivas"
          }), ". Sem isso, você não poderá enviar a lista do aplicativo."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Escopos:"
          }), " você só deve solicitar os escopos do seu aplicativo. ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth#scopes",
            children: "Analise seus escopos"
          }), " e certifique-se de que não esteja solicitando um acesso desnecessário. Aplicativos que fazem isso tendem a ter melhores taxas de conversão."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["O seu aplicativo precisa ter as ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "configurações avançadas de escopo"
            }), " ativadas. Todos os escopos obrigatórios, condicionalmente obrigatórios e opcionais devem ser selecionados para evitar erros. Essas configurações podem ser encontradas na ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/getting-started/account-types#app-developer-accounts",
              children: "conta de desenvolvedor"
            }), " que gerencia seu aplicativo."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Termos:"
          }), " revise e aceite os termos do ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/app-program-agreement",
            children: "Acordo do Programa de Parceiros de Aplicativos da HubSpot"
          }), ". Dessa forma, você, a HubSpot e nossos clientes compartilhados estarão protegidos. Você só poderá enviar a lista do aplicativo depois de concluir esta etapa."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Setores restritos:"
          }), " seu aplicativo não deve se adequar ou fornecer funcionalidades que atenderiam exclusivamente aos clientes de um dos ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/acceptable-use#Restricted-Industries",
            children: "setores restritos"
          }), " da HubSpot."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Requisitos de marca"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O aplicativo e o respectivo material de apoio associado (documentação, landing pages etc.) devem atender às ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/branding-guidelines",
          children: "Diretrizes de marca da HubSpot"
        }), ". Por exemplo, coloque o \"S\" em letra maiúscula em \"HubSpot\" sempre que estiver se referindo à HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O aplicativo e o respectivo material de apoio associado (documentação, páginas iniciais etc.) não deve infringir as ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/tm-usage-guidelines",
          children: "Diretrizes de marca da HubSpot"
        }), ". Por exemplo, não combine o nome da HubSpot (incluindo \"Hub\" e \"HubSpot\") com o nome ou logotipo do seu aplicativo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Requisitos da lista"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depois de atender aos requisitos mínimos, você poderá enviar a lista de aplicativos. Ao enviar sua lista de aplicativos, você deve preencher de maneira completa e precisa todas as informações. Estes campos são particularmente importantes e o não cumprimento destes requisitos fará com que a sua lista seja definida apenas para o modo Rascunho:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O conteúdo da sua lista deve ser específico para a integração, em vez de informações gerais do produto. Ele deve conter informações sobre o valor que os clientes podem esperar especificamente do download e do uso dessa integração. Exemplos bons incluem: ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/calling/aircall",
          children: "Aircall"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/sales-enablement/cloudfiles",
          children: "CloudFiles"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/partner-relationship-management/reveal-191193",
          children: "Reveal"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um link para uma Documentação de instalação disponível publicamente (sem login ou acesso pago), específica para a sua integração com o HubSpot.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "O Guia de instalação não pode ser simplesmente a sua página inicial ou uma base de conhecimento geral."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Em vez disso, ele deve conter as etapas para instalar e configurar a integração."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para obter um exemplo, consulte o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://orgcharthub.com/guides/setup",
              children: "Guia de instalação do OrgChartHub"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Inclua um URL de botão de Instalação relevante que leve os clientes a uma página na qual eles possam conectar facilmente seu aplicativo ao HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os URLs para os recursos de suporte do seu aplicativo (site de suporte, fórum da comunidade da HubSpot, estudo de caso) devem ser ativos, atualizados e publicamente disponíveis."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "É necessário que as URLs para os Termos de Serviço e a Política de Privacidade do aplicativo estejam no ar e atualizadas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Todos os campos de URL têm um limite de 250 caracteres."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: "Dados compartilhados"
        }), ", que permitem que os usuários saibam como será o fluxo de informações entre o seu aplicativo e o HubSpot; devem ser exatos e estar atualizados e refletir os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements#:~:text=Webhooks%20API.-,Scopes,-%3A%20You%20must",
          children: "escopos"
        }), " das solicitações do aplicativo.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Todos os objetos selecionados nos escopos de OAuth devem ser documentados na tabela de ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Dados compartilhados"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Se o aplicativo estiver solicitando escopos de objeto de leitura e gravação, a sincronização de dados deverá ser informada como bidirecional para esses objetos específicos."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A listagem do Marketplace de aplicativos deve conter informações de preços claras e precisas.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Pelo menos um plano de preços relevante para sua integração com o HubSpot, que precisa corresponder às informações publicadas em seu site."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Os planos de preços gratuitos devem ser usados apenas para modelos de preços Gratuitos para sempre ou Freemium."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Você deve incluir pelo menos um método de contato de suporte."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Siga as diretrizes listadas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/testing-credentials",
          children: "aqui"
        }), " para fornecer credenciais de teste para a listagem do aplicativo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Revisão, feedback e aprovação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de enviar sua listagem, a equipe de Qualidade do ecossistema da HubSpot fará uma análise inicial em até 10 dias úteis. Se qualquer informação fornecida estiver incorreta, for ambígua ou estiver incompleta, entraremos em contato com você para fornecer esse feedback. Todo o processo de análise e feedback do aplicativo não deve demorar mais de 60 dias a partir do momento em que o feedback é compartilhado. Conforme indicado nos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/app-program-agreement",
        children: "Termos do Marketplace de aplicativos"
      }), ", a HubSpot se reserva o direito de cancelar ou recusar a publicação da sua lista de aplicativos a qualquer momento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recompensas para parceiros de aplicativos listados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Lista dedicada do Marketplace de aplicativos da HubSpot"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Acesso prioritário ao suporte do desenvolvedor por meio de um alias de suporte dedicado"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Recursos da comunidade de desenvolvedor, incluindo webinars, fóruns e muito mais"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Recursos de marketing especializados, incluindo modelos de solicitação de envio e guias de lançamento"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Desconto em patrocínios, exposições e tickets de eventos INBOUND"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Software com desconto por meio do Programa Seed Stage da HubSpot para startups"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Informativos mensais com atualizações de marketing, lançamentos de produtos e muito mais"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/listing-your-app",
          children: "Como listar seu aplicativo"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/certification-requirements",
          children: "Requisitos de certificação de aplicativos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "Documentação de referência de API"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/overview",
          children: "Como usar as APIs da HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/APIs-Integrations/bd-p/integrations",
          children: "Fórum de comunidade para desenvolvedores"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/contact-our-partnerships-team",
          children: "Entre em contato com a equipe de parceiros de aplicativos"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}