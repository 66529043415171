"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29837710048;
const slug = exports.slug = 'guides/cms/setup/getting-started-with-local-development';
const title = exports.title = 'Getting started with local development';
const name = exports.name = 'Getting started with local development';
const metaDescription = exports.metaDescription = 'Getting started with local development on the HubSpot CMS. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/327485/local_development.png';
const featuredImageAltText = exports.featuredImageAltText = 'Getting started with local development';
const toc = exports.toc = [{
  "depth": 0,
  "id": "install-dependencies",
  "label": "Install dependencies",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-create-a-working-directory",
  "label": "1. Create a working directory",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-configure-the-local-development-tools",
  "label": "2. Configure the local development tools",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-create-an-asset-to-fetch-in-hubspot",
  "label": "3. Create an asset to fetch in HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-fetch-the-module-to-your-local-environment",
  "label": "4. Fetch the module to your local environment",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-make-changes-and-upload",
  "label": "5. Make changes and upload",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-run-a-watch-to-automatically-upload-changes",
  "label": "6. Run a watch to automatically upload changes",
  "parent": null
}, {
  "depth": 0,
  "id": "next-steps",
  "label": "Next steps",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      h2: "h2",
      ol: "ol",
      strong: "strong",
      br: "br",
      em: "em",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Getting started with local development"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "HubSpot CLI"
      }), " (Command Line Interface) connects your local environment to HubSpot, meaning you'll have local copies of your HubSpot web assets. This allows you to use version control, your favorite text editor and various web development technologies when developing on the HubSpot CMS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This guide is best for those who are already familiar with the CMS but want to learn about working with the CLI. If you are completely new to HubSpot CMS Hub development, we encourage you to follow the quickstart guide."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "28bfd0e9-ec05-48a5-b069-ce20015f54ac",
      label: "Quick start to CMS Hub development"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In this tutorial, you'll learn:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "How to install the CLI and connect it to your HubSpot account."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "How to fetch a module from your HubSpot account."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "How to update the module locally, then upload your changes."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["How to use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "watch"
        }), " command to continue uploading saved changes."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For more commands and local file formats, see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "Local Development Tooling Reference"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Install dependencies"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To develop on HubSpot locally, you'll need to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Install ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://nodejs.org/en/download/package-manager",
          children: "Node.js"
        }), ", which enables HubSpot's local development tools. Versions 18 or higher are supported. It's recommended to use a package manager like ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://brew.sh/",
          children: "Homebrew"
        }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/nvm-sh/nvm",
          children: "nvm"
        }), " to install Node."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install -g @hubspot/cli"
        }), " in your command line to install the HubSpot CLI globally. To install the tools in only your current directory instead, run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "npm install @hubspot/cli"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you prefer, you can also use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://classic.yarnpkg.com/en/docs/install",
        children: "Yarn"
      }), ". If you are using Yarn, commands are run with the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "yarn"
      }), " prefix."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Getting an EACCES error when installing?"
        }), (0, _jsxRuntime.jsx)(_components.br, {}), "\n", "See ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://docs.npmjs.com/resolving-eacces-permissions-errors-when-installing-packages-globally",
          children: "NPM Resolving EACCESS permissions errors when installing packages globally"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Create a working directory"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Create a folder for the work you'll be doing below. For the purposes of this tutorial, name the folder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "local-dev-tutorial"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can do this locally by running ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mkdir local-dev-tutorial"
      }), " in the command line, which will create the directory. Then, run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cd local-dev-tutorial"
      }), " to navigate to that directory."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Configure the local development tools"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Next, run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs init"
      }), " to connect the tools to your HubSpot account. This command will walk you through the steps below."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["To connect the CLI to a HubSpot account, you'll need to copy the account's personal access key. When prompted, press ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enter"
          }), " to open hubspot.com to the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/l/personal-access-key",
            children: "personal access key page of the account"
          }), ". If you have multiple accounts, you'll be prompted in the browser to select an account first."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "On the personal access key page, you can generate a new personal access key or copy the existing key value if one already exists."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you're creating a key for the first time, select which scopes the key has access to. You'll need to select at least the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Design Manager"
            }), " permission to interact with the account's design tools."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["After selecting the key's permissions, click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Generate personal access key"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Once a key has been generated, copy its value by first clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Show"
          }), " under the key, then clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copy"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Paste the key into the command line, then press ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enter"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Next, enter a name for the account. This name is only seen and used by you when running commands. For example, you might use \"sandbox\" if you're using a developer sandbox or \"company.com\" if you’re using a standard account. This name can't contain spaces."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Press ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enter"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "init"
      }), " flow complete, you'll see a success message confirming that a configuration file, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#authentication",
        children: "hubspot.config.yml"
      }), ", has been created in your current directory."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.config.yml"
      }), " will look something like this:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: mainProd\nportals:\n  - name: mainProd\n    portalId: 123456\n    defaultMode: publish\n    authType: personalaccesskey\n    auth:\n      tokenInfo:\n        accessToken: >-\n          {accessTokenValue}\n\n\n        expiresAt: '2023-06-27T19:45:58.557Z'\n    personalAccessKey: >-\n      {personalAccessKeyValue}\n\n\n    sandboxAccountType: null\n    parentAccountId: null\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultPortal"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The account that is interacted with by default when running CLI commands.To interact with an authenticated account that is not set as the default, you can add a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account="
            }), " flag to the command, followed by the account name or ID. For example, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account=12345"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account=mainProd"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Under ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "portals"
            }), " you'll find an entry for each connected account. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " specifies the given name for the account. You can use this name when ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/local-development-cli#set-default-account",
              children: "setting a new default account"
            }), " or specifying an account with the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "--account"
            }), " flag."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The account ID."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultMode"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When uploading to the account, sets the default state to upload content as. Can be either ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "draft"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "authType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The form of authentication used to auth the account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "sandboxAccountType"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "If the account is a sandbox account, indicates the ID of the parent production account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "parentAccountId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "parentAccountId"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot.config.yml"
        }), " file supports multiple accounts. To authenticate more accounts, run ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#auth",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs auth"
          })
        }), " and follow the prompts."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Create an asset to fetch in HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For the purpose of this tutorial, you'll first create a new asset in HubSpot so that you can fetch it to your local environment using the CLI."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Files and Templates"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Design tools"
          }), ". This will open the design manager, which is where you can access the files you upload using the CLI. This tree of files and folders is also referred to as the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/overview#developer-file-system",
            children: "developer file system"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar of the design manager, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "@hubspot"
          }), " folder to view HubSpot's default assets, such as themes and modules."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/design-manager-hubspot-folder.png",
            alt: "design-manager-hubspot-folder"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar, scroll down to and right-click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "icon"
          }), " module, then select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Clone module"
          }), ". The module will be cloned to the root of the developer file system, and your new module copy will be opened on the right."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/default-icon-module-cloned.png",
            alt: "default-icon-module-cloned"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["At the top of the left sidebar, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Actions"
          }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copy path"
          }), ". This will copy the relative path to the module in the developer file system, which you'll use in the next step to fetch the module locally."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-actions-copy-path.png",
            alt: "module-actions-copy-path"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Fetch the module to your local environment"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With the module cloned, you'll now use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "fetch"
        })
      }), " command to bring it into your local environment."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the terminal, run ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch '/icon copy.module'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs fetch '/icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The module will be downloaded as a directory containing five files:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "fields.json"
          }), ": contains the code for the module's various fields. In this case, this includes the icon field, two accessibility option fields, and a set of style fields. You can see these fields in the right sidebar of the module editor in HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "meta.json"
          }), ": contains the module's basic information, such as its label, ID, and the types of templates it can be used in. This information will be displayed in the right sidebar of the module editor."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.css"
          }), ": contains the module's CSS, which you can also see in the CSS pane of the module editor in HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.html"
          }), ": contains the module's HTML, which you can also see in the HubL + HTML pane of the module editor in HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "module.js"
          }), ": contains the module's JavaScript, which you can also see in the JS pane of the module editor in HubSpot."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-directory-local.png",
            alt: "module-directory-local"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Next, you'll make an update to the module's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " file, then upload it to your account and view the change in HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Make changes and upload"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "First, make a change to the module:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your preferred code editor, open the module's ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "meta.json"
          }), " file."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-meta-json-file-open.png",
            alt: "module-meta-json-file-open"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Update the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "label"
          }), " field from ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"Icon\""
          }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "\"CMS tutorial module\""
          }), ". Then, save the file."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-meta-json-file-label-updated.png",
            alt: "module-meta-json-file-label-updated"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before running the next command, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload"
      }), ", let's break down the command and the arguments it takes. This command takes two arguments: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload <src> <dest>"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "src"
        }), ": the relative path of the source folder that you're uploading from your local environment."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "dest"
        }), ": the path of the destination directory in HubSpot, local to the root of the developer file system. You can create a new directory in the account by specifying the directory name, such as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload <src> /new-directory"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "With that in mind, because you're uploading to the root of the developer file system, run the following command:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "hs upload 'icon copy.module' 'icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After the CLI confirms that the module has been successfully uploaded, refresh the design manager to view your change in HubSpot. You should now see that the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Label"
        }), " field shows your new value."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-label-updated.png",
        alt: "module-label-updated"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Run a watch to automatically upload changes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now that you've used the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), " command to run a one-time upload of your local files, you'll now use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " command to continuously upload saved changes. This command takes the same arguments as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), ", so you can specify the same ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<src>"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<dest>"
      }), " as above."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs watch 'icon copy.module' 'icon copy.module'"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "hs watch 'icon copy.module' 'icon copy.module'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With the watch now running, saved changes will automatically upload to the HubSpot account. As a demonstration, make the following local change to the module:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "meta.json"
        }), " file, update the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "host_template_types"
        }), " field to remove ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"BLOG_LISTING\""
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"BLOG_POST\""
        }), " so that the module is only available for pages: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"host_template_types\"``:[\"PAGE\"]"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ten, save the file. This should prompt the CLI to automatically upload the file to HubSpot."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["With the file uploaded, refresh the design manager in HubSpot to view your change. The ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Template types"
        }), " section of the right sidebar should now only include ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Page"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/module-editor-template-type.png",
        alt: "module-editor-template-type"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To end the watch, press ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Control + C"
        }), ". It's important to note that you won't be able to run other commands in the same terminal window that the watch command is running in. To run other commands while running a watch, you should instead open another terminal window and execute your commands there."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Next steps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now that you've walked through how to use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fetch"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "upload"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "watch"
      }), " commands, you may want to check out the full ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI command reference guide"
      }), " to learn what else you can do with the CLI."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "It's also recommended to check out the following tutorials:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/creating-an-efficient-development-workflow",
          children: "Creating an efficient development workflow"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/github-integration",
          children: "How to set up continuous integration with GitHub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Getting started with custom modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Getting started with themes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/tutorial",
          children: "Getting started with drag and drop areas"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}