"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694130;
const slug = exports.slug = 'guides/cms/content/themes/getting-started';
const title = exports.title = 'Premiers pas avec les thèmes';
const name = exports.name = '[nouveau] Premiers pas avec les thèmes';
const metaDescription = exports.metaDescription = 'Faites vos premiers pas avec les thèmes sur le CMS Hub. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-d%C3%A9marrer-un-projet-de-th%C3%A8me-avec-boilerplate",
  "label": "1. Démarrer un projet de thème avec boilerplate",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-t%C3%A9l%C3%A9charger-le-boilerplate-cms-sur-votre-compte-hubspot",
  "label": "2. Télécharger le boilerplate CMS sur votre compte HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-cr%C3%A9er-une-page",
  "label": "3. Créer une page",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-modifier-les-champs-de-th%C3%A8me",
  "label": "4. Modifier les champs de thème",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-pr%C3%A9parez-vous-%C3%A0-apporter-des-modifications-locales",
  "label": "5. Préparez-vous à apporter des modifications locales",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-ajouter-un-champ-de-th%C3%A8me",
  "label": "6. Ajouter un champ de thème",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-r%C3%A9f%C3%A9rencer-le-champ-dans-votre-css",
  "label": "7. Référencer le champ dans votre CSS",
  "parent": null
}, {
  "depth": 0,
  "id": "8.-tester-les-modifications",
  "label": "8. Tester les modifications",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A9tapes-suivantes",
  "label": "Étapes suivantes",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      img: "img",
      a: "a",
      strong: "strong",
      h2: "h2",
      code: "code",
      em: "em",
      ul: "ul",
      li: "li",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Premiers pas avec les thèmes"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un thème est un ensemble portable et contenu de ressources de développement conçues pour fonctionner ensemble afin d'offrir une expérience flexible en matière de modification de contenu. Ces ressources peuvent comprendre des modèles, des modules, des fichiers CSS, des fichiers JavaScript, des images, etc. Les thèmes permettent aux développeurs de créer un ensemble de champs de thème, à l'image des champs de module, ce qui permet aux créateurs de contenu de contrôler les styles globaux du site web sans avoir à modifier les CSS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez utiliser HubL pour accéder aux valeurs des champs du thème dans la CSS du thème. Les créateurs de contenu peuvent alors utiliser l'éditeur de thème pour modifier les champs du thème, prévisualiser ces modifications par rapport aux modèles existants dans un thème et publier leurs modifications."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/cms-themes-animation.gif",
        alt: "Animation de l'interface utilisateur de modification des paramètres de thème montrant la sélection d'une couleur pour les éléments d'un thème."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ce document vous guide dans la création de votre premier thème basé sur le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "boilerplate du CMS Hub"
      }), ". Pour en savoir plus sur les thèmes, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "documentation de référence relative aux thèmes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "S'il s'agit de votre première expérience de développement avec le CMS Hub, il est recommandé de passer par là :"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "Démarrage rapide du développement CMS Hub"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " avant de commencer, vous devrez installer l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "ILC HubSpot"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Démarrer un projet de thème avec boilerplate"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Exécuter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create website-theme my-website-theme"
      }), " pour créer un répertoire ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my-website-theme"
      }), " composé de fichiers provenant du ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "boilerplate du thème CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Télécharger le boilerplate CMS sur votre compte HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Exécutez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-website-theme my-website-theme"
      }), ". Le modèle sera téléchargé dans le gestionnaire de conception de votre compte, dans un dossier intitulé ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "my-website-theme"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Créer une page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour créer une page à partir du thème téléchargé :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Site web"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Pages de site web"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer"
        }), " dans l'angle supérieur droit, puis sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Page de site web"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la boîte de dialogue, sélectionnez le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "domaine"
        }), " sur lequel la page sera publiée, puis saisissez un ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom de page"
        }), ". Cliquez ensuite sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Créer une page"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/create-page-from-dashboard.gif",
        alt: "create-page-from-dashboard"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sur l'écran de sélection des modèles, les modèles de votre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/use-themes#use-an-active-theme",
          children: "thème actif"
        }), " apparaissent en haut de la page.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si vous n'avez pas sélectionné de thème actif, passez la souris sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "boilerplate du thème CMS"
            }), " et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Définir comme thème actif"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si vous avez déjà défini un thème actif, sélectionnez votre nouveau thème en cliquant sur le menu déroulant du ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "sélecteur de thème"
            }), ", puis en sélectionnant ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modifier le thème"
            }), ". Ensuite, passez le curseur sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Boilerplate du thème CMS"
            }), " et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Définir comme thème actif"
            }), ". Sur l'écran suivant, sélectionnez un ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "modèle"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/theme-selector.gif",
        alt: "theme-selector"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous serez redirigé vers l'éditeur de page où vous pourrez modifier les champs du thème."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Modifier les champs de thème"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la barre latérale gauche de l'éditeur de page, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Thèmes"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Thèmes"
        }), ", cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modifier les paramètres de thème"
        }), ". C'est ici que vous pouvez modifier les paramètres de votre thème existant. La publication des modifications apportées aux paramètres du thème mettra à jour les styles sur toutes vos pages utilisant le thème mis à jour."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-theme-settings.gif",
        alt: "edit-theme-settings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Préparez-vous à apporter des modifications locales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Revenez à votre terminal, puis exécutez ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs watch my-website-theme my-website-theme"
      }), ". Cette ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "commande"
      }), " consulte votre répertoire local et télécharge automatiquement les changements suivants vers votre compte HubSpot lors de l'enregistrement des fichiers."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Ajouter un champ de thème"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Maintenant que nous sommes face à des modifications locales, ajoutez un nouveau champ de thème :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ouvrez le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " dans votre éditeur. Ce fichier contrôle les champs disponibles dans la barre latérale de l'éditeur de thème. Nous allons ajouter un nouveau ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/overview#fields-json",
          children: "champ"
        }), " pour spécifier la hauteur du pied de page."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vers le bas du fichier, localisez le groupe ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "footer"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Copiez le code ci-dessous et collez le JSON dans le fichier au-dessus du premier élément du tableau enfant pour le groupe footer."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n      {\n      \"id\" : \"\",\n      \"name\" : \"height\",\n      \"label\" : \"Footer height\",\n      \"required\" : false,\n      \"locked\" : false,\n      \"display\" : \"text\",\n      \"step\" : 1,\n      \"type\" : \"number\",\n      \"min\" : 10,\n      \"max\" : 900,\n      \"help_text\":\"This footer will expand in height to accomodate any content added to the footer. You are setting the minimum height in px\",\n      \"default\" : 100\n      },\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enregistrez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " et actualisez l'aperçu du thème dans HubSpot. Votre nouveau champ devrait s'afficher dans la barre latérale de gauche."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Référencer le champ dans votre CSS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre éditeur de code, ouvrez le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-overrides.css"
        }), ". Localisez ensuite le sélecteur CSS de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".footer"
        }), ". Nous allons maintenant ajouter une ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "min-height"
        }), " à ce sélecteur."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour accéder à une valeur dans un thème, utilisez l'objet ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme"
        }), ". Par exemple, vous pouvez utiliser ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ theme.footer.height }}"
        }), " pour accéder à la valeur de hauteur définie dans votre champ de hauteur."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Remplacez la déclaration ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".footer"
        }), " dans theme-overrides.css par la suivante :"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".footer {\n  background-color: {{ footer_bg_color }};\n  min-height: {{theme.footer.height}}px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enregistrez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme-overrides.css"
        }), " pour le télécharger sur votre compte HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "8. Tester les modifications"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Revenez à l'éditeur de thème et actualisez la page pour voir votre nouveau champ apparaître sous le pied de page. Mettez à jour la valeur de la hauteur pour qu'elle soit immédiatement reflétée dans l'aperçu. Il peut être judicieux de définir une couleur d'arrière-plan pour le pied de page afin que vous puissiez voir la modification plus facilement."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Étapes suivantes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Maintenant que vous avez créé et mis à jour votre thème, vous pouvez créer d'autres champs de thème et les personnaliser pour vos projets. Pour plus d'options de personnalisation, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "présentation des thèmes"
      }), ". Pendant la création de votre thème, il peut être utile de consulter les bonnes pratiques en matière d'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "optimisation des sites web sur le CMS Hub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot compte plusieurs ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/default-themes",
        children: "thèmes par défaut"
      }), " qui sont proposés avec CMS Hub. Ces thèmes peuvent être consultés, clonés et mis à jour afin de savoir comment utiliser un thème dans une situation concrète."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que vous maîtrisez les thèmes, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "découvrez comment créer votre premier module personnalisé"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}