'use es6';

import { argumentType } from './constants.js';
import { get } from './objutils';
import { toCamelCase } from './stringutils';
export function extractSdkPathFromUrlPath(urlPath) {
  const urlReplaces = new Map();
  urlReplaces.set('/business-units/v3/business-units/user/{userId}', ['settings', 'business-units']);
  urlReplaces.set('/cms/v3/blogs/posts', ['cms', 'blogs', 'blog-posts']);
  urlReplaces.set('/cms/v3/blogs/posts', ['cms', 'blogs', 'blog-posts']);
  urlReplaces.set('/cms/v3/hubdb/tables', ['cms', 'hubdb']);
  urlReplaces.set('/cms/v3/site-search/search', ['cms', 'site-search']);
  urlReplaces.set('/cms/v3/pages/site-pages', ['cms', 'pages']);
  urlReplaces.set('/cms/v3/source-code/extract/async', ['cms', 'source-code']);
  urlReplaces.set('/conversations/v3/visitor-identification/tokens/create', ['conversations', 'visitor-identification']);
  urlReplaces.set('/crm/v3/associations/{fromObjectType}/{toObjectType}/batch/read', ['crm', 'associations']);
  urlReplaces.set('/crm/v3/associations/{fromObjectType}/{toObjectType}/types', ['crm', 'associations', 'schema']);
  urlReplaces.set('/crm/v4/associations/usage/high-usage-report/{userId}', ['crm', 'associations', 'v4']);
  urlReplaces.set('/crm/v4/associations/definitions/configurations/all', ['crm', 'associations', 'v4', 'schema']);
  urlReplaces.set('/crm/v3/objects/contacts', ['crm', 'contacts']);
  urlReplaces.set('/crm/v3/objects/companies', ['crm', 'companies']);
  urlReplaces.set('/crm/v3/objects/deals', ['crm', 'deals']);
  urlReplaces.set('/crm/v3/objects/goal_targets', ['crm', 'objects', 'goals']);
  urlReplaces.set('/crm/v3/extensions/calling/{appId}/settings', ['crm', 'extensions', 'calling']);
  urlReplaces.set('/crm/v3/extensions/cards-dev/{appId}', ['crm', 'extensions', 'cards']);
  urlReplaces.set('/crm/v3/extensions/videoconferencing/settings/{appId}', ['crm', 'extensions', 'videoconferencing']);
  urlReplaces.set('/crm/v3/objects/products', ['crm', 'products']);
  urlReplaces.set('/crm/v3/objects/tickets', ['crm', 'tickets']);
  urlReplaces.set('/crm/v3/objects/quotes', ['crm', 'quotes']);
  urlReplaces.set('/crm/v3/objects/line_items', ['crm', 'line_items']);
  urlReplaces.set('/oauth/v1/token', ['oauth']);
  urlReplaces.set('/communication-preferences/v3/subscribe', ['communication-preferences']);
  urlReplaces.set('/integrators/timeline/v3/events', ['crm', 'timeline']);
  urlReplaces.set('/marketing/v3/marketing-events/events', ['marketing', 'events']);
  urlReplaces.set('/marketing/v3/transactional/smtp-tokens', ['marketing', 'transactional']);
  urlReplaces.set('/files/v3/files', ['files']);
  urlReplaces.set('/webhooks/v3/{appId}/settings', ['webhooks']);
  const sdkPath = urlPath.split('/');
  const pathParameterRe = /\{.*\}/;
  const redundantPaths = ['v1', 'v3', 'v4'];
  return urlReplaces.get(urlPath) || [...new Set(sdkPath.filter(p => p.length > 0).filter(p => !pathParameterRe.test(p)).filter(p => !redundantPaths.includes(p)))];
}
export function extractModelNameFromRef(ref) {
  return ref.split('/').pop();
}
export function extractApiNameFromRequest(request) {
  return toCamelCase(get(request, 'tags[0]', 'default').toLowerCase());
}
export function extractOASRequest(openApiSpecification, operationId) {
  const [apiName, methodName] = operationId.includes('.') ? operationId.split('.') : [undefined, operationId];
  const basePath = Object.keys(openApiSpecification.paths).reduce((a, b) => a.length <= b.length ? a : b);
  const sdkPath = extractSdkPathFromUrlPath(basePath);
  for (const urlPath in openApiSpecification.paths) {
    if (Object.prototype.hasOwnProperty.call(openApiSpecification.paths, urlPath)) {
      for (const request of Object.values(openApiSpecification.paths[urlPath])) {
        if (apiName === undefined || apiName === extractApiNameFromRequest(request)) {
          if (request.operationId === methodName || request.__originalOperationId === methodName) {
            return {
              sdkPath,
              request
            };
          }
        }
      }
    }
  }
  throw new Error(`Request with operationId=${operationId} not found`);
}
export function generateArgumentValue(name, type) {
  switch (type) {
    case argumentType.STRING:
      return name;
    case argumentType.BOOLEAN:
      return false;
    case argumentType.INTEGER:
      return 100;
    case argumentType.OBJECT:
      return {};
    default:
      return '';
  }
}