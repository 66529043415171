"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 163879382024;
const slug = exports.slug = 'reference/ui-components/standard-components/flex';
const title = exports.title = 'Flex';
const name = exports.name = 'Flex | UI components';
const metaDescription = exports.metaDescription = 'Learn about the Flex component for configuring UI extension layout.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "usage-examples",
  "label": "Usage examples",
  "parent": null
}, {
  "depth": 1,
  "id": "horizontal-layout",
  "label": "Horizontal layout",
  "parent": "usage-examples"
}, {
  "depth": 1,
  "id": "wrap",
  "label": "Wrap",
  "parent": "usage-examples"
}, {
  "depth": 1,
  "id": "vertical-layout",
  "label": "Vertical layout",
  "parent": "usage-examples"
}, {
  "depth": 1,
  "id": "spacing",
  "label": "Spacing",
  "parent": "usage-examples"
}, {
  "depth": 1,
  "id": "using-flex-in-flex",
  "label": "Using Flex in Flex",
  "parent": "usage-examples"
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Flex | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " component renders an empty ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "div"
      }), " container set to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "display=flex"
      }), ". When wrapped around other components, it enables those child components to be arranged using props. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " can contain other ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " components."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To see an example of how ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Box"
      }), " can be used for layout, check out HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview#managing-layouts-flex-and-box",
        children: "Manage layouts: Flex and Box sample project"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extension-components-flex-child.png?width=521&height=207&name=ui-extension-components-flex-child.png",
        alt: "ui-extension-components-flex-child"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { Flex, Tile } from '@hubspot/ui-extensions';\nconst Extension = () => {\n  return (\n    <Flex direction={'row'} justify={'end'} wrap={'wrap'} gap={'small'}>\n      <Tile>Left</Tile>\n      <Tile>Right</Tile>\n      <Flex direction={'column'}>\n        <Tile>Bottom</Tile>\n      </Flex>\n    </Flex>\n  );\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "direction"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'row'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'column'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Arranges the components horizontally or vertically by setting the main axis."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "justify"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'start'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'center'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'end'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'around'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'between'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Distributes components along the main axis using the available free space."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "align"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'start'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'center'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'end'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'baseline'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'stretch'"
            }), " (default)"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Distributes components along the cross-axis using the available free space."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignSelf"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'start'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'center'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'end'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'baseline'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'stretch'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Distributes a child component along the cross-axis using the available free space. Use this prop for nested ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Flex"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Box"
            }), " components to align them differently from other child components in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Flex"
            }), " group."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "wrap"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'wrap'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'nowrap'"
            }), " (default)"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether components will wrap rather than trying to fit on one line."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "gap"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'flush'"
            }), " (default) | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'extra-small'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'small'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'medium'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'large'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'extra-large'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sets the spacing between components."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usage examples"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Horizontal layout"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To arrange components horizontally, set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "direction"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "row"
      }), ". Then, use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "justify"
      }), " to configure the horizontal distribution. By default, components will stretch across the container if ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "justify"
      }), " is not specified."]
    }), "\n", (0, _jsxRuntime.jsx)("table", {
      children: (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("img", {
              alt: "flex-tiles-justify-between",
              src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/flex-tiles-justify-between.png"
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "justify={'between'}"
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: (0, _jsxRuntime.jsx)("img", {
                alt: "flex-tiles-justify-around",
                src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/flex-tiles-justify-around.png"
              })
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "justify={'around'}"
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: (0, _jsxRuntime.jsx)("img", {
                alt: "ui-extrensions-layout-tile-justify-start",
                src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-justify-start.png"
              })
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "justify={'start'}"
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: (0, _jsxRuntime.jsx)("img", {
                alt: "ui-extrensions-layout-tile-justify-center",
                src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-justify-center.png"
              })
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "justify={'center'}"
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: (0, _jsxRuntime.jsx)("img", {
                alt: "ui-extrensions-layout-tile-justify-end",
                src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-justify-end.png"
              })
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "justify={'end'}"
            })
          })
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Wrap"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, components in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "row"
      }), " will be arranged on one line when possible. Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "wrap"
      }), " prop to wrap components onto new lines when needed."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-row-wrap.png",
        alt: "ui-extrensions-layout-tile-row-wrap"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'between'} wrap={'wrap'} gap={'medium'}>\n  <Tile>One</Tile>\n  <Tile>Two</Tile>\n  <Tile>Three</Tile>\n  <Tile>Four</Tile>\n  <Tile>Five</Tile>\n  <Tile>Six</Tile>\n  <Tile>Seven</Tile>\n  <Tile>Eight</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Vertical layout"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To arrange components vertically, set direction to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "column"
      }), ", then use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "align"
      }), " prop to distribute them. By default, components will stretch across the extension container width when ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "align"
      }), " is not specified."]
    }), "\n", (0, _jsxRuntime.jsx)("table", {
      children: (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("img", {
              alt: "ui-extrensions-layout-tile-column-align-start",
              src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-column-align-start.png?width=500&height=268&name=ui-extrensions-layout-tile-column-align-start.png"
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "align={'start'}"
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: (0, _jsxRuntime.jsx)("img", {
                alt: "ui-extrensions-layout-tile-column-align-center",
                src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-column-align-center.png?width=500&height=267&name=ui-extrensions-layout-tile-column-align-center.png"
              })
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "align={'center'}"
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("p", {
              children: (0, _jsxRuntime.jsx)("img", {
                alt: "ui-extrensions-layout-tile-column-align-end",
                src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extrensions-layout-tile-column-align-end.png?width=500&height=262&name=ui-extrensions-layout-tile-column-align-end.png"
              })
            })
          })
        }), (0, _jsxRuntime.jsx)("tr", {
          children: (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("code", {
              children: "align={'end'}"
            })
          })
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Spacing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " component, you can use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "gap"
      }), " prop to apply even spacing between the tiles. This prop will apply spacing equally for both ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "row"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "column"
      }), " directions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/flex-tiles-gap.png?width=528&height=701&name=flex-tiles-gap.png",
        alt: "flex-tiles-gap"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex\n  direction={'row'}\n  justify={'start'}\n  gap={'flush' | 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'}\n>\n  <Tile>Tile 1</Tile>\n  <Tile>Tile 2</Tile>\n  <Tile>Tile 3</Tile>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Using Flex in Flex"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can wrap child ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " components with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " to set more specific rules for individual components. A child ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " component will not inherit props specified in the parent ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Flex"
      }), " component, so you'll need to repeat any props you've previously defined to maintain them."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extension-components-flex-child.png?width=521&height=207&name=ui-extension-components-flex-child.png",
        alt: "ui-extension-components-flex-child"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<Flex direction={'row'} justify={'end'} wrap={'wrap'} gap={'small'}>\n  <Tile>Left</Tile>\n  <Tile>Right</Tile>\n  <Flex direction={'column'}>\n    <Tile>Bottom</Tile>\n  </Flex>\n</Flex>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/tile",
          children: "Tile"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/box",
          children: "Box"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/divider",
          children: "Divider"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}