"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 167415169202;
const slug = exports.slug = 'guides/crm/public-apps/overview';
const title = exports.title = 'Build UI extensions for public apps (BETA)';
const name = exports.name = 'Build UI extensions for public apps (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to build UI extensions for public apps.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "ui-extensions-in-public-apps-vs-private-apps",
  "label": "UI extensions in public apps vs private apps",
  "parent": null
}, {
  "depth": 0,
  "id": "creating-and-configuring-public-apps",
  "label": "Creating and configuring public apps",
  "parent": null
}, {
  "depth": 0,
  "id": "ui-extension-development",
  "label": "UI extension development",
  "parent": null
}, {
  "depth": 0,
  "id": "local-development",
  "label": "Local development",
  "parent": null
}, {
  "depth": 0,
  "id": "fetching-data",
  "label": "Fetching data",
  "parent": null
}, {
  "depth": 0,
  "id": "working-with-webhooks",
  "label": "Working with webhooks",
  "parent": null
}, {
  "depth": 0,
  "id": "app-marketplace-guidelines",
  "label": "App Marketplace guidelines",
  "parent": null
}, {
  "depth": 1,
  "id": "compliance",
  "label": "Compliance",
  "parent": "app-marketplace-guidelines"
}, {
  "depth": 1,
  "id": "security-and-privacy",
  "label": "Security and privacy",
  "parent": "app-marketplace-guidelines"
}, {
  "depth": 1,
  "id": "reliability-and-performance",
  "label": "Reliability and performance",
  "parent": "app-marketplace-guidelines"
}, {
  "depth": 1,
  "id": "usability-and-accessibility",
  "label": "Usability and accessibility",
  "parent": "app-marketplace-guidelines"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      em: "em",
      a: "a",
      h1: "h1",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      h3: "h3",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The features described in this guide are in Early Access beta, separate from the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "CRM development tools to build UI extensions with React as frontend"
        }), " beta for private app UI extension development."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/build-app-cards",
          children: "Request access to the UI extensions for public apps beta"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Build UI extensions for public apps (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To customize the HubSpot UI, from CRM record views to the help desk preview panels, you can build UI extensions. UI extensions are built locally using the developer projects framework, and are powered by a public or private app, depending on your needs. The toolset for developing UI extensions is largely the same whether you're building for a public or private app, with a few differences in authentication, installation, and feature support."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn about building UI extensions for public apps at a high level. For more detail, follow the links in each section."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023_2024/ui-extension-sample-multi-step-flow-with-panel.gif?width=700&height=399&name=ui-extension-sample-multi-step-flow-with-panel.gif",
        alt: "ui-extension-sample-multi-step-flow-with-panel"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "UI extensions in public apps vs private apps"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["UI extensions can be built for both private apps and public apps, and follow the same ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/overview",
        children: "principles and limitations"
      }), " in both types of apps. However, while most UI extension concepts are transferable from private apps to public apps, there are two key differences:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "When developing a UI extension using a private app, you install the app directly in the target production account. When developing using a public app, you instead build the app in a developer account, then its install URL can be used to install it into other accounts. So while private apps are created on a per-account basis, public apps are intended to be installed across multiple accounts."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["UI extensions in private apps use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/private-apps/serverless-functions",
          children: "serverless functions"
        }), " and the private app's access token to fetch external data. UI extensions in public apps instead require you to bring your own self-hosted back end and use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/fetching-data",
          children: "hubspot.fetch() API"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-apps-vs-private-apps.png",
        alt: "public-apps-vs-private-apps"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Creating and configuring public apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using the CLI, you can create a project with a public app using any of the following methods:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Follow the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/quickstart",
          children: "quickstart guide"
        }), " to create an app from a boilerplate project template, which includes a card for contact records."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/migrate-a-public-app-to-projects",
          children: "Migrate an existing public app"
        }), " to the developer projects framework. If an app hasn't been migrated yet, you can also copy it into a developer project by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project clone-app"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/creating-public-apps#create-an-app",
          children: "Manually create a public app"
        }), " in a project."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Whichever method you choose, you'll handle app configuration in the app’s ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "public-app.json"
      }), " file Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/creating-public-apps",
        children: "creating and configuring apps"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "UI extension development"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When building UI extensions, check out the following resources to learn more about the utilities, functionalities, and components available to you:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/overview",
          children: "UI extensions overview"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/create",
          children: "Create UI extensions"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sdk",
          children: "UI extensions SDK"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/overview",
          children: "List of available UI components"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/sample-extensions/overview",
          children: "Sample UI extensions"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Local development"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "While developing UI extensions, you can run a local development server to see your saved changes in real-time without needing to re-upload the project. The local development server is only available for the front end portion of the app, and assumes the backend is either running locally or is hosted. Because public apps require you to bring your own back end, there are a few differences for running local development for public apps, depending on your setup."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/local-development",
        children: "local development"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fetching data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While private apps can use serverless functions to fetch data, when you build UI extensions inside public apps, you need to bring your own REST-based backend and use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.fetch()"
      }), " API to fetch data. You'll specify the URLs that the app can request data from in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "allowedUrls"
      }), " array in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "public-app.json"
      }), " file."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/fetching-data",
        children: "fetching data with hubspot.fetch"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Working with webhooks"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Build webhooks into your app to subscribe to events happening in the account that the app is installed in. Webhooks in project-based public apps can use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/create-generic-webhook-subscriptions#parse-generic-webhook-payloads",
        children: "generic webhook subscriptions"
      }), " to subscribe to events for a wide range of HubSpot objects. However, you can still subscribe to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks#webhook-subscriptions",
        children: "classic webhook subscriptions"
      }), " by including a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "legacyCrmObjects"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubEvents"
      }), ", depending on the subscription type."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Webhook subscriptions are defined in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks.json"
      }), " file within a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "webhooks"
      }), " folder in the same directory as your app (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "src/app"
      }), "). You'll also need to update your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "public-app.json"
      }), " configuration file to reference that file."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/public-apps/webhooks",
        children: "webhooks in public apps"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "App Marketplace guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All apps built for the App Marketplace are subject to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "App Marketplace listing requirements"
      }), ", and certified apps are subject to the additional ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "App Marketplace certification requirements"
      }), ". If you're adding a UI extension to an app intended for the App Marketplace, you'll need to adhere to the additional criteria below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Compliance"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Naming:"
        }), " per the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/branding-guidelines",
          children: "App Partner Program branding guidelines"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Do not modify, imitate, or abbreviate any HubSpot brands or names (e.g., \"HubSpot,\" \"Hub,\" etc.) anywhere in the name of your UI extension."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Do not use a generic product name + any HubSpot brands or names (e.g., \"UI extension for HubSpot\")."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Do not brand your UI extension using the word \"inbound\" in a way that would tie it to HubSpot's INBOUND event (e.g., \"Inbound Sales UI extension\")."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logos and icons:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Per the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/partners/app/branding-guidelines",
              children: "App Partner Program branding guidelines"
            }), ", do not use the HubSpot company logo or sprocket without permission."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Do not use company or brand logos other than your own as icons."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sensitive data:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Your app must not be participating in the current sensitive data for public apps private beta."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Your UI extension must not display sensitive information, as defined in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://legal.hubspot.com/terms-of-service",
              children: "HubSpot's Terms of Service"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Security and privacy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Your app must have ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/creating-public-apps#app-configuration",
          children: "advanced scope settings"
        }), " turned on (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"advancedScopeSettingsEnabled\": true"
        }), " in ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "public-app.json"
        }), "). All ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview#configure-scopes",
          children: "required, conditionally required, and optional scopes"
        }), " should be selected to prevent errors."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Your app must use all of the scopes that it requests during installation. Scopes that are not used must be removed. If certain scopes only apply to a subset of your app's user base, they should be included as conditionally required or optional scopes."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Reliability and performance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For linked assets such as images and JavaScript, avoid using absolute links. Instead, use relative links and include the assets in your files. Exceptions may only be made if you use a reputable CDN."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usability and accessibility"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Buttons:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/form",
              children: "Forms"
            }), " must include submit ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/button",
              children: "Buttons"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Ensure destructive button styles denote a destructive behavior."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Include only one primary button per surface (app card, modal, or panel)."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Text:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "It's recommended to not use underline formatting for text that's next to a hyperlink, as it will also appear clickable."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Do not use ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/tag",
              children: "Tags"
            }), " in place of Buttons or ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/link",
              children: "Links"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}