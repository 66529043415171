"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125381;
const slug = exports.slug = 'guides/cms/content/templates/types/blog';
const title = exports.title = 'Blog-Vorlagen-Markup';
const name = exports.name = 'EMEA DACH (de) Blog template markup';
const metaDescription = exports.metaDescription = 'Blog-Listing- und Blog-Beitragsvorlagen können verwendet werden, um das Erscheinungsbild des Blogs einer Website anzupassen. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/Boilerplate%20Blog%20Templates.png';
const featuredImageAltText = exports.featuredImageAltText = 'Blog settings template selection';
const toc = exports.toc = [{
  "depth": 0,
  "id": "listing-seiten-und-beitr%C3%A4ge-mit-einer-einzelnen-vorlage-darstellen",
  "label": "Listing-Seiten und Beiträge mit einer einzelnen Vorlage darstellen",
  "parent": null
}, {
  "depth": 0,
  "id": "blog-listing-f%C3%BCr-schleife",
  "label": "Blog-Listing für Schleife",
  "parent": null
}, {
  "depth": 0,
  "id": "listing-vorlagen-markup",
  "label": "Listing-Vorlagen-Markup",
  "parent": null
}, {
  "depth": 0,
  "id": "if-blog_author-anweisung",
  "label": "if blog_author-Anweisung",
  "parent": null
}, {
  "depth": 0,
  "id": "if-tag--anweisung",
  "label": "if tag -Anweisung",
  "parent": null
}, {
  "depth": 0,
  "id": "if-not-simple_list_page-anweisung",
  "label": "if not simple_list_page-Anweisung",
  "parent": null
}, {
  "depth": 0,
  "id": "seitennummerierung-von-listings",
  "label": "Seitennummerierung von Listings",
  "parent": null
}, {
  "depth": 0,
  "id": "blog-beitrags-markup",
  "label": "Blog-Beitrags-Markup",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      code: "code",
      a: "a",
      pre: "pre",
      strong: "strong",
      img: "img",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier
    } = _components;
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Blog-Vorlagen-Markup"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot-Blogs bestehen aus Blog-Listing-Seiten und den einzelnen Blog-Beiträgen. Neben dem Listing der einzelnen Blog-Beiträge wird die Blog-Listing-Vorlage auch für die Darstellung der Autoren- und Tag-Listing-Seiten verwendet. Sie können entweder eine einzige Vorlage erstellen, um alle Seiten für Listing- und Blog-Beitragsseiten darzustellen, oder Sie können zwei separate Vorlagen erstellen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie mehr über Blog-Vorlagen-Markup, Vorlagenkomponenten und Anpassungsoptionen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listing-Seiten und Beiträge mit einer einzelnen Vorlage darstellen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine einzelne Vorlage zu erstellen, die die Listing- und Beitragsseiten darstellt, fügen Sie die Annotation ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: blog"
      }), " am Anfang Ihrer Vorlagendatei hinzu. Wenn Sie eine Vorlage verwenden, um beides darzustellen, verwenden Sie eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if-Anweisung"
      }), ", die auswertet, ob sich der Benutzer eine Listing-Seite oder einen einzelnen Beitrag anschaut. Wenn Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "Layouts des Drag-&-Drop-Design-Managers"
      }), " verwenden, ist diese ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "-Anweisung in die Benutzeroberfläche von Blog-Inhaltsmodulschaltflächen integriert."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Innerhalb der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "-Anweisung schreiben Sie sowohl Ihren Beitrags- als auch Listing-Code."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if is_listing_view %}\n    Markup for blog listing template\n{% else %}\n    Markup for blog post template\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alternativ können Sie auch eine separate Vorlage für ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#blog-post",
        children: "Blog-Beiträge"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#blog-listing",
        children: "Listing-Seiten"
      }), " verwenden. Dadurch wird Ihr Code übersichtlicher und für den Entwickler leichter lesbar, und die Vorlagen können von Content-Autoren einfacher ausgewählt werden. Die CMS-Boilerplate hat eine separate ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-index.html",
        children: "Listing-Vorlage"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-post.html",
        children: "Beitragsvorlage"
      }), ". Wenn Sie separate Listing- und Beitragsvorlagen erstellen, ist diese ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "is_listing_view"
      }), "-Prüfung nicht erforderlich, Sie müssen nur sicherstellen, dass Sie die separaten Vorlagen unter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/website/blogs/templates",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "„Einstellungen“ > „Website“ > „Blog“ > „Vorlagen“"
        })
      }), " auswählen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Boilerplate%20Blog%20Templates.png",
        alt: "Auswahl der Vorlagenoptionen"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blog-Listing für Schleife"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Auflistung von Beiträgen wird durch eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "for-Schleife"
      }), " generiert, die Ihre Blog-Beiträge durchläuft. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Contents"
      }), " ist eine vordefinierte Sequenz von Inhalten, die alle in diesem Blog enthaltenen Beiträge enthält."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n    <div class=\"post-item\">\n        Post item markup that renders with each iteration.\n    </div>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listing-Vorlagen-Markup"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Inhalte der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/blog",
        children: "Boilerplate-Blog-Listing-Seite"
      }), " für die Schleife werden mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-index.html",
        children: "folgendem Markup"
      }), " gerendert:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for content in contents %}\n    {# On the blog homepage the first post will be featured above older posts #}\n    {% if (loop.first && current_page_num == 1 && !topic) %}\n    <div class=\"blog-index__post blog-index__post--large\">\n        <a class=\"blog-index__post-image blog-index__post-image--large\"\n        {% if content.featured_image %}\n            style=\"background-image: url('{{ content.featured_image }}')\";\n        {% endif %}\n        href=\"{{ content.absolute_url }}\"></a>\n        <div class=\"blog-index__post-content  blog-index__post-content--large\">\n        <h2><a href=\"{{ content.absolute_url }}\">{{ content.name }}</a></h2>\n        {{ content.post_list_content }}\n        </div>\n    </div>\n    {% else %}\n    <div class=\"blog-index__post blog-index__post--small\">\n        <a class=\"blog-index__post-image blog-index__post-image--small\"\n        {% if content.featured_image %}\n            style=\"background-image: url('{{ content.featured_image }}')\";\n        {% endif %}\n        href=\"{{ content.absolute_url }}\"></a>\n        <div class=\"blog-index__post-content  blog-index__post-content--small\">\n        <h2><a href=\"{{ content.absolute_url }}\">{{ content.name }}</a></h2>\n        {{ content.post_list_content|truncatehtml(100) }}\n        </div>\n    </div>\n    {% endif %}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "if blog_author-Anweisung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im Standard-Blog-Listing-Markup von HubSpot gibt es eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if blog_author"
      }), "-Anweisung. Diese Anweisung wird als true ausgewertet, wenn Sie sich eine Seite mit Autoren-Listing-Seite ansehen. Eine Autoren-Listing-Seite ist eine Seite mit Beiträgen eines einzelnen Autors. Die Boilerplate-Vorlage enthält den Namen des Autors, seinen Lebenslauf und seine Konten in den sozialen Medien."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if blog_author %}\n  <div class=\"blog-header\">\n    <div class=\"blog-header__inner\">\n      {% if blog_author.avatar %}\n      <div class=\"blog-header__author-avatar\" style=\"background-image: url('{{ blog_author.avatar }}');\"></div>\n      {% endif %}\n      <h1 class=\"blog-header__title\">{{ blog_author.display_name }}</h1>\n      <h4 class=\"blog-header__subtitle\">{{ blog_author.bio }}</h4>\n      {% if blog_author.has_social_profiles %}\n        <div class=\"blog-header__author-social-links\">\n          {% if blog_author.website %}\n            <a href=\"{{ blog_author.website }}\" target=\"_blank\">\n              {% icon name=\"link\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n          {% if blog_author.facebook %}\n            <a href=\"{{ blog_author.facebook }}\" target=\"_blank\">\n              {% icon name=\"facebook-f\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n          {% if blog_author.linkedin %}\n            <a href=\"{{ blog_author.linkedin }}\" target=\"_blank\">\n              {% icon name=\"linkedin-in\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n          {% if blog_author.twitter %}\n            <a href=\"{{ blog_author.twitter }}\" target=\"_blank\">\n              {% icon name=\"twitter\" style=\"SOLID\" width=\"10\" %}\n            </a>\n          {% endif %}\n        </div>\n      {% endif %}\n    </div>\n  </div>\n{% else %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "if tag -Anweisung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zusätzlich zu der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if blog_author"
      }), "-Anweisung, die Markup definiert, das nur auf Autoren-Listing-Seiten gerendert werden soll, gibt es eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "tag"
      }), "-Variable, die verwendet werden kann, um Code nur auf einer Seite mit Blog-Thema-Listing-Seite zu rendern. Das folgende Beispiel ist ein Snippet, das die Seitentitelvariable verwendet, um den Tag-Namen automatisch am Anfang einer Tag-Listing-Seite zu drucken. Dieses Snippet könnte in den Code Ihres Blog-Listings eingefügt werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if tag %}\n    <h3>Posts about {{ page_meta.html_title|split(\" | \")|last }}</h3>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "if not simple_list_page-Anweisung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Innerhalb der for-Schleife gibt es eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "-Anweisung, die festlegt, was in einem einfachen und was in einem regulären Listing dargestellt werden soll."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ein einfaches Listing ist ein Listing aller Ihrer Beiträge und unterstützt keine Seitennummerierung. Das einfache Listing ist von der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/website-settings#number-of-posts-per-listing-page",
          children: "Blog-Einstellung für das Beitragslimit"
        }), " nicht betroffen und enthält in der Regel nur Links zu den letzten 200 Blog-Beiträgen."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Das reguläre Listing durchläuft die in der Beitrags-Listing-Blog-Einstellung angegebene Anzahl von Beiträgen und nummeriert Seiten entsprechend."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Adresse Ihrer einfachen Listing-Seite ist die URL Ihres Blogs mit dem Zusatz ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/all"
      }), " am Ende des Pfades."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der folgende Code ist eine vereinfachte Version dieser ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "-Anweisung, die definiert, was in einer einfachen Listing-Seite durchlaufen werden soll. Beachten Sie, dass die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "-Anweisung umgekehrte Logik verwendet; daher definiert die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "-Anweisung die einfache Listing-Ansicht. Optional können Sie stattdessen eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements#unless-statements",
        children: "unless-Anweisung"
      }), " verwenden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if not simple_list_page %}\n    Iterated post markup for regular listing\n{% else %}\n    <h2 class=\"post-listing-simple\"><a href=\"{{content.absolute_url}}\">{{ content.name }}</a></h2>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Seitennummerierung von Listings"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Blog-Listing-Seiten haben eine automatisch generierte Seitennummerierung. Ihre Listing-Vorlage kann Logik enthalten, die es den Besuchern ermöglicht, einfach durch Ihre Blog-Beiträge zu blättern. Der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/blog",
        children: "Boilerplate-Blog"
      }), " erhält durch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/modules/blog-pagination.module/module.html",
        children: "folgendes Markup"
      }), " eine einfache Seitennummerierung:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if contents.total_page_count > 1 %}\n<div class=\"blog-pagination\">\n    {% set page_list = [-2, -1, 0, 1, 2] %}\n    {% if contents.total_page_count - current_page_num == 1 %}{% set offset = -1 %}\n    {% elif contents.total_page_count - current_page_num == 0 %}{% set offset = -2 %}\n    {% elif current_page_num == 2 %}{% set offset = 1 %}\n    {% elif current_page_num == 1 %}{% set offset = 2 %}\n    {% else %}{% set offset = 0 %}{% endif %}\n\n    <a class=\"blog-pagination__link blog-pagination__prev-link {{ \"blog-pagination__prev-link--disabled\" if !last_page_num }}\" href=\"{{ blog_page_link(last_page_num) }}\">\n    {% icon name=\"chevron-left\" style=\"SOLID\", width=\"13\", no_wrapper=True %}\n    Prev\n    </a>\n    {% for page in page_list %}\n    {% set this_page = current_page_num + page + offset %}\n    {% if this_page > 0 and this_page <= contents.total_page_count %}\n        <a class=\"blog-pagination__link blog-pagination__number-link {{ \"blog-pagination__link--active\" if this_page == current_page_num }}\" href=\"{{ blog_page_link(this_page) }}\">{{ this_page }}</a>\n    {% endif %}\n    {% endfor %}\n    <a class=\"blog-pagination__link blog-pagination__next-link {{ \"blog-pagination__next-link--disabled\" if !next_page_num }}\" href=\"{{ blog_page_link(current_page_num + 1) }}\">\n    Next\n    {% icon name=\"chevron-right\" style=\"SOLID\", width=\"13\", no_wrapper=True %}\n    </a>\n</div>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Blog-Beitrags-Markup"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Alle Blog-Beiträge in einem Blog werden mit einer einzigen Blog-Vorlage erstellt. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content"
      }), " ist ein vordefiniertes Datenobjekt, das Informationen zum angeforderten Blog-Beitrag enthält. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://boilerplate.hubspotcms.com/blog",
        children: "Boilerplate-Beiträge"
      }), " werden mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/blog-post.html",
        children: "folgendem Markup"
      }), " gerendert:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<div class=\"content-wrapper\">\n  <div class=\"blog-post\">\n    <h1>{{ content.name }}</h1>\n    <div class=\"blog-post__meta\">\n      <a href=\"{{ blog_author_url(group.id, content.blog_post_author.slug) }}\">\n        {{ content.blog_post_author.display_name }}\n      </a>\n      <div class=\"blog-post__timestamp\">\n        {{ content.publish_date_localized }}\n      </div>\n    </div>\n    <div class=\"blog-post__body\">\n      {{ content.post_body }}\n    </div>\n    {% if content.tag_list %}\n    <div class=\"blog-post__tags\">\n      {% icon name=\"tag\" style=\"SOLID\" %}\n      {% for tag in content.tag_list %}\n        <a class=\"blog-post__tag-link\" href=\"{{ blog_tag_url(group.id, tag.slug) }}\">{{ tag.name }}</a>{% if not loop.last %},{% endif %}\n      {% endfor %}\n    </div>\n    {% endif %}\n  </div>\n  <div class=\"blog-comments\">\n    {% module \"blog_comments\" path=\"@hubspot/blog_comments\", label=\"Blog Comments\" %}\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Informationen zum Autor des Blog-Beitrags sind ebenfalls in den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content"
      }), "-Daten enthalten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<img alt=\"{{ content.blog_post_author.display_name }}\" src=\"{{ content.blog_post_author.avatar }}\">\n<h3>Written by <a class=\"author-link\" href=\"{{ blog_author_url(group.id, content.blog_post_author.slug) }}\">{{ content.blog_post_author.display_name }}</a></h3>\n<p>{{ content.blog_post_author.bio }}</p>\n{% if content.blog_post_author.has_social_profiles %}\n    <div class=\"hs-author-social-section\">\n        <div class=\"hs-author-social-links\">\n            {% if content.blog_post_author.facebook %}\n                <a href=\"{{ content.blog_post_author.facebook }}\" target=\"_blank\" class=\"hs-author-social-link hs-social-facebook\">Facebook</a>\n            {% endif %}\n            {% if content.blog_post_author.linkedin %}\n                <a href=\"{{ content.blog_post_author.linkedin }}\" target=\"_blank\" class=\"hs-author-social-link hs-social-linkedin\">LinkedIn</a>\n            {% endif %}\n            {% if content.blog_post_author.twitter %}\n                <a href=\"{{ content.blog_post_author.twitter }}\" target=\"_blank\" class=\"hs-author-social-link hs-social-twitter\">Twitter</a>\n            {% endif %}\n            {% if content.blog_post_author.google_plus %}\n                <a href=\"{{ content.blog_post_author.google_plus }}?rel=author\" target=\"_blank\" class=\"hs-author-social-link hs-social-google-plus\">Google+</a>\n            {% endif %}\n        </div>\n    </div>\n{% endif %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}