"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32924700882;
const slug = exports.slug = 'guides/apps/marketplace/measuring-app-performance';
const title = exports.title = `Évaluation des performances de l'application`;
const name = exports.name = 'Mesurer les performances de l’application';
const metaDescription = exports.metaDescription = "Découvrez-en davantage sur l'évaluation des performances de votre application via les détails de la page de listing de l'application.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "passez-en-revue-les-analytics-de-votre-application",
  "label": "Passez en revue les analytics de votre application",
  "parent": null
}, {
  "depth": 1,
  "id": "analytics-du-marketplace",
  "label": "Analytics du Marketplace",
  "parent": "passez-en-revue-les-analytics-de-votre-application"
}, {
  "depth": 0,
  "id": "feedback-des-clients",
  "label": "Feedback des clients",
  "parent": null
}, {
  "depth": 0,
  "id": "ressources-cl%C3%A9s",
  "label": "Ressources clés",
  "parent": null
}, {
  "depth": 0,
  "id": "mesurez-l%E2%80%99engagement-avec-les-param%C3%A8tres-utm",
  "label": "Mesurez l’engagement avec les paramètres UTM",
  "parent": null
}, {
  "depth": 2,
  "id": "documents-associ%C3%A9s",
  "label": "Documents associés",
  "parent": "mesurez-l%E2%80%99engagement-avec-les-param%C3%A8tres-utm"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      em: "em",
      img: "img",
      code: "code",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Mesurer les performances de l’application"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez consulter les indicateurs de performance pour toute application répertoriée dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "marketplace des applications"
      }), " dans votre compte de développeur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Passez en revue les analytics de votre application"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "compte de développeur d’applications"
        }), ", accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings d’applications"
        }), ". Vous accéderez alors à un tableau de bord contenant tous vos listings du Marketplace des applications."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "application"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Analytics du Marketplace"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Installations"
      }), ", consultez les indicateurs détaillés sur les performances de votre application. Pour analyser les données sur une période spécifique, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Fréquence"
      }), ", le menu déroulant en haut de la page et sélectionnez une période."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez analyser les données suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Installations d'applications"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Désinstallations d'applications"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Comparaison des installations et des désinstallations d'applications"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Comparaison des installations gratuites et payantes"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Comparaison des désinstallations gratuites et payantes"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Installation par pays"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Installations par hub"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/marketplace-app-analytics.png",
        alt: "marketplace-app-analytics"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Analytics de listing"
      }), ", consultez les indicateurs de page de listing de l'application. Pour analyser les données sur une période spécifique, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Fréquence"
      }), ", le menu déroulant en haut de la page et sélectionnez une période."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez analyser les données suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pages consultées"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Source de la page consultée"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utilisation de CTA"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Comparaison des pages consultées gratuites et payantes"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pages consultées par pays"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pages consultées par Hub"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-analytics.png",
        alt: "listing-analytics"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Feedback des clients"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour consulter tous les avis laissés par les clients pour votre application, cliquez sur l’onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Feedback"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/customer-feedback-tab.png",
        alt: "customer-feedback-tab"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Évaluations et avis"
      }), ", vous pouvez filtrer les avis en fonction du nombre d'étoiles attribuées, du secteur d'activité du client et de la taille de l'entreprise du client."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour répondre à un avis :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Répondre"
        }), " sur un avis."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la fenêtre contextuelle, saisissez votre ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "réponse"
        }), " au client, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Envoyer"
        }), ". Cette réponse sera visible par le public sur le listing de votre application."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour modifier les notifications pour les avis reçus :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), ", le menu déroulant, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modifier les notifications"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sur la page Notifications, recherchez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " et sélectionnez ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nouveaux avis de listing"
        }), " pour être averti lorsqu'un client laisse un avis."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour envoyer votre lien d'avis à un client :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le menu déroulant ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), ", puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copier le lien d'avis"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Envoyez un e-mail ou ce lien à votre client pour qu'il laisse un avis."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Désinstaller les réponses à l'enquête"
      }), " , vérifiez toutes les réponses à l’enquête."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pour exporter les réponses, cliquez sur **Exporter ** et sélectionnez le format de fichier."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Feedback privé"
      }), ", examinez toutes les réponses de feedback privé à votre équipe."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ressources clés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ressources clés"
      }), ", passez en revue les ressources suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le guide des avantages du Programme Partenaires d'applications qui répertorie tous les avantages dont vous bénéficiez en tant que partenaire avec une application listée sur le marketplace des applications HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Une enquête de feedback que vous pouvez remplir pour donner votre feedback sur l'expérience avec le partenaire d'application."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Informations de contact de votre responsable des partenaires d'application."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mesurez l’engagement avec les paramètres UTM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Outre les indicateurs ci-dessus, vous pouvez également mesurer l'engagement en incluant des paramètres UTM dans les URL de la page de listing de votre application. Cela vous permet d'évaluer le trafic qui arrive sur votre site web à partir de votre page de listing, en utilisant HubSpot, Google Analytics ou d'autres plateformes d'analyse de votre choix."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il est recommandé d'ajouter des paramètres UTM aux URL suivantes incluses sur votre page de listing :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Contenu d'aide sur votre page de listing du marketplace des applications, comme le site web de votre entreprise, la documentation d'aide, l'étude de cas et la politique de confidentialité."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "L'URL d'installation OAuth que les clients utilisent lors de l'installation de l'application."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, vous pouvez ajouter la chaîne de paramètres UTM suivante à la fin de l'URL de votre documentation :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "?utm_campaign=appmarketplacelisting&utm_medium=referral&utm_source=hubspot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " il est recommandé d'utiliser des paramètres UTM compatibles avec les autres suivis UTM que vous ou votre équipe marketing pouvez utiliser. Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/customers/understanding-basics-utm-parameters",
          children: "bases des paramètres UTM"
        }), " et sur la création d'URL de suivi UTM avec ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/how-do-i-create-a-tracking-url",
          children: "HubSpot"
        }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/what-are-utm-tracking-codes-ht",
          children: "Google Analytics"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ajouter les paramètres UTM à l'URL d'installation d'OAuth :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte de développeur d'applications, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " de l'application pour modifier ses détails."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Informations de base"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Authentification"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "URL de redirection"
        }), ", mettez à jour votre URL de redirection pour qu'elle contienne vos paramètres UTM. Cela mettra à jour l'URL d'installation de l'application après l'enregistrement. Vous devrez donc vous assurer que vous avez mis à jour tous les liens utilisateur pour utiliser la nouvelle URL."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/update-redirect-url.png",
        alt: "update-redirect-url"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enregistrer"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " le champ d'URL du bouton d'installation a une limite de 250 caractères. Si vos paramètres UTM entraînent un dépassement de cette limite, vous devrez peut-être utiliser un raccourcisseur d'URL, tel que ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://bitly.com/",
          children: "Bitly"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ajouter des paramètres UTM au contenu d'aide de l'application :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans votre compte de développeur d'application, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketplace des applications"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listings"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le tableau ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Listings du marketplace"
          }), ", cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nom"
          }), " de l'application."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Informations sur le listing"
          }), ", mettez à jour l'URL dans le ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "champ Installer l'URL"
          }), " du bouton avec vos paramètres UTM."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/install-button-url.png",
            alt: "install-button-url"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Informations sur le support"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans les sections ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Coordonnées"
          }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Ressources de support"
          }), " et ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Conditions d'utilisation et politique de confidentialité"
          }), ", mettez à jour les URL avec vos paramètres UTM."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/support-resources-section.png",
            alt: "support-resources-section"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Une fois que vous avez mis à jour vos URL, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Soumettre pour analyse"
          }), " dans l'angle supérieur droit."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois examinées et approuvées, les URL de la page de listing de votre application seront mises à jour avec vos paramètres UTM. Vous pourrez ensuite utiliser des outils d'analyse, tels que ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-your-site-traffic-with-the-traffic-analytics-tool",
        children: "HubSpot"
      }), " ou Google Analytics, pour afficher le trafic provenant de vos URL classées selon vos paramètres UTM."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " étant donné que votre listing d'application peut être trouvé via Google et d'autres moteurs de recherche, il est également important de vous assurer que votre listing est adapté au référencement. Une stratégie recommandée pour améliorer votre SEO consiste à utiliser des liens entrants. Que vous écriviez du contenu de site web, que vous envoyiez des bulletins d'information par e-mail ou que vous rédigiez des messages sur les réseaux sociaux, pensez à ajouter des liens vers votre page de listing ainsi que des informations pertinentes sur votre intégration. Vous pouvez élargir votre portée grâce à des stratégies telles que les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/guest-blogging",
          children: "blogs d'invités"
        }), " pour améliorer l'autorité du référencement."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/backlink-strategies",
          children: "façons originales de générer des liens entrants"
        }), " et consultez ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/lessons/link-building-tutorial",
          children: "le module gratuit de HubSpot Academy sur le link building"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documents associés"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "Conditions relatives à la certification des applications"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "Conditions relatives aux listings"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}