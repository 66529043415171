"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 21611793414;
const slug = exports.slug = 'guides/api/analytics-and-events/email-analytics';
const title = exports.title = "Présentation de l'API d'événements d'e-mail | API d'événements d'e-mail";
const name = exports.name = "Présentation de l'API des événements d'e-mail";
const metaDescription = exports.metaDescription = "L'API des événements d'e-mail est utilisée pour obtenir des informations sur les événements générés par des e-mails marketing ou des campagnes d'e-mails envoyées via un compte HubSpot.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "types-d-%C3%A9v%C3%A9nements",
  "label": "Types d'événements",
  "parent": null
}, {
  "depth": 1,
  "id": "%C3%A9v%C3%A9nements-de-soumission",
  "label": "Événements de soumission",
  "parent": "types-d-%C3%A9v%C3%A9nements"
}, {
  "depth": 2,
  "id": "motifs-d-abandon",
  "label": "Motifs d'abandon",
  "parent": "types-d-%C3%A9v%C3%A9nements"
}, {
  "depth": 1,
  "id": "%C3%A9v%C3%A9nements-de-remise",
  "label": "Événements de remise",
  "parent": "types-d-%C3%A9v%C3%A9nements"
}, {
  "depth": 2,
  "id": "remis",
  "label": "Remis",
  "parent": "types-d-%C3%A9v%C3%A9nements"
}, {
  "depth": 2,
  "id": "report%C3%A9",
  "label": "Reporté",
  "parent": "types-d-%C3%A9v%C3%A9nements"
}, {
  "depth": 2,
  "id": "rejet",
  "label": "Rejet",
  "parent": "types-d-%C3%A9v%C3%A9nements"
}, {
  "depth": 2,
  "id": "cat%C3%A9gorie-de-rejet",
  "label": "Catégorie de rejet",
  "parent": "types-d-%C3%A9v%C3%A9nements"
}, {
  "depth": 1,
  "id": "%C3%A9v%C3%A9nements-d-engagement-des-utilisateurs",
  "label": "Événements d'engagement des utilisateurs",
  "parent": "types-d-%C3%A9v%C3%A9nements"
}, {
  "depth": 1,
  "id": "%C3%A9v%C3%A9nements-de-statut-des-utilisateurs",
  "label": "Événements de statut des utilisateurs",
  "parent": "types-d-%C3%A9v%C3%A9nements"
}, {
  "depth": 2,
  "id": "source-de-la-modification-de-l-abonnement",
  "label": "Source de la modification de l'abonnement",
  "parent": "types-d-%C3%A9v%C3%A9nements"
}, {
  "depth": 1,
  "id": "-%C3%A9v%C3%A9nements-de-r%C3%A9activation-des-envois-d-e-mails",
  "label": "'Événements de réactivation des envois d'e-mails",
  "parent": "types-d-%C3%A9v%C3%A9nements"
}, {
  "depth": 0,
  "id": "r%C3%A9f%C3%A9rences-de-l-%C3%A9v%C3%A9nement",
  "label": "Références de l'événement",
  "parent": null
}, {
  "depth": 1,
  "id": "-sentby-",
  "label": "'sentBy'",
  "parent": "r%C3%A9f%C3%A9rences-de-l-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "-obsoletedby-",
  "label": "'obsoletedBy'",
  "parent": "r%C3%A9f%C3%A9rences-de-l-%C3%A9v%C3%A9nement"
}, {
  "depth": 1,
  "id": "-causedby-",
  "label": "'causedBy'",
  "parent": "r%C3%A9f%C3%A9rences-de-l-%C3%A9v%C3%A9nement"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h3: "h3",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      img: "img",
      em: "em",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Présentation de l'API des événements d'e-mail"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'API des événements d'e-mail est utilisée pour obtenir des informations sur les événements générés par des e-mails marketing ou des campagnes d'e-mails envoyées via un compte HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Cas d'utilisation pour cette API :"
      }), " si un membre de votre équipe marketing souhaite optimiser les campagnes d'e-mail, vous pouvez utiliser l'API d'événements d'e-mail pour collecter des données et alimenter un modèle d'apprentissage automatique qui détermine le meilleur moment pour envoyer des e-mails à différents segments de contacts."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Événements d'e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Chaque e-mail envoyé via HubSpot génère un certain nombre d'événements détaillant son cycle de vie et la manière dont le destinataire interagit avec son contenu. Un événement est identifié de manière unique par son ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "EventId"
      }), ", qui est composé des propriétés suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID généré aléatoirement pour cet événement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "created"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "entier 64 bits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'horodatage (epoch en millisecondes) de la création de cet événement."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ces propriétés peuvent être utilisées pour rechercher un événement spécifique via ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/email-analytics#get-email-event-by-id",
        children: "ce point de terminaison"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["De plus, tous les types d'événements (sauf ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), ") ont les propriétés suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "type"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne (énumération)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le type d'événement. Consultez la section ci-dessous pour plus d'informations sur les types d'événements."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "recipient"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'adresse e-mail du destinataire de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "portalId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "entier 32 bits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un ID référençant le compte HubSpot qui a envoyé l'e-mail. Cela correspondra à votre compte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "appId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "entier 32 bits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un ID référençant l'application HubSpot ayant envoyé l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "appName"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le nom de l'application HubSpot ayant envoyé l'e-mail. Notez qu'il s'agit d'une valeur dérivée qui peut être modifiée à tout moment."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "emailCampaignId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "entier 64 bits"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un ID référençant la campagne d'e-mails dont fait partie l'e-mail. Des informations supplémentaires sur la campagne d'e-mail peuvent être trouvées via ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/email-analytics#get-campaign-data-for-a-given-campaign",
              children: "ce point de terminaison"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les événements peuvent être recherchés en masse via ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/email-analytics#get-email-events",
        children: "ce point de terminaison"
      }), " en utilisant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'recipient'"
      }), ", à la fois ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'appId'"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'campaignId'"
      }), " ou n'importe quelle combinaison des propriétés ci-dessus."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les propriétés supplémentaires suivantes sont également disponibles pour tous les types d'événements (y compris ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), ") :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "sentBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["L'eventId qui identifie de manière unique l'événement ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT"
            }), " de l'e-mail. Si elle n'est pas applicable, cette propriété est omise."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "obsoletedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'élément eventId qui identifie de manière unique l'événement de suivi qui rend l'événement actuel obsolète. Si elle n'est pas applicable, cette propriété est omise."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "causedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'élément qui identifie de manière unique l'événement qui a directement causé cet événement. Si elle n'est pas applicable, cette propriété est omise."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les propriétés de référence d'événement -- ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'sentBy'"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), " -- sont abordées en détail plus loin dans ce document."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Types d'événements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe 12 types d'événements qui peuvent être générés par l'API d'e-mail de HubSpot au cours du cycle de vie d'un e-mail. Ils sont regroupés en plusieurs catégories : soumission, distribution, engagement de l'utilisateur et statut de l'utilisateur. Les types d'événements, les catégories d'événements et leurs relations sont présentés ci-dessous."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://static.hsappstatic.net/developer_site_web/static-2.153/img/events.svg",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Type d'événement"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le message a été envoyé et reçu par notre fournisseur de livraison, qui l'a mis en attente pour le traiter plus tard."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DROPPED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le message a été rejeté, soit par HubSpot, soit par notre prestataire de distribution, et aucune tentative ne sera faite pour livrer le message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PROCESSED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le message a été reçu par notre fournisseur de livraison, qui informe d'une future remise du message sur le serveur de messagerie du destinataire."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DELIVERED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le serveur de messagerie du destinataire a accepté le message et le message a été correctement remis au destinataire."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DEFERRED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le serveur de messagerie du destinataire a temporairement rejeté le message et les tentatives suivantes seront apportées à la remise du message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BOUNCE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le serveur de messagerie du destinataire n'a pas pu ou voulu accepter ce message et aucune autre tentative ne sera faite pour le remettre."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "OPEN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le destinataire a ouvert le message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CLICK"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le destinataire a cliqué sur un lien dans le message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "STATUSCHANGE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le destinataire a modifié les abonnements de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SPAMREPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le destinataire a classé le message comme spam."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SUPPRESSION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le message n'a pas été envoyé, le destinataire n'a pas répondu aux e-mails marketing précédents."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Certains types d'événements, tels que les événements de bot,\npeuvent être inclus dans la réponse à l'API ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/events"
        }), " mais n'apparaîtront pas\nlors de l'analyse de l'onglet ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "https://knowledge.hubspot.com/email/analyze-your-marketing-email-campaign-performance",
          children: [(0, _jsxRuntime.jsx)(_components.em, {
            children: "Recipients"
          }), " pour un e-mail marketing dans\nvotre\ncompte"]
        }), ",\nqui filtre certains types d'événements bruts et limite le nombre total\nd'événements à 30 événements par type d'événement."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Événements de soumission"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'un e-mail est créé et envoyé par HubSpot au nom d'un client, nous vérifions d'abord si le destinataire est habilité à le recevoir. Si ce n'est pas le cas, nous rejetons le message, ce qui déclenche la création d'un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), ". Dans le cas contraire, nous le soumettons à notre prestataire de services de distribution pour qu'il le traite, ce qui déclenche un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), ". Un e-mail est presque toujours associé à un seul événement de soumission ; par exemple, il n'y aura jamais plusieurs événements ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " pour un message."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nous nous efforçons de rejeter les messages avant de les transmettre à notre prestataire de distribution. Cependant, il arrive que notre prestataire de services de distribution décide de rejeter un message même après que nous en ayons vérifié l'admissibilité. Ce rejet consécutif entraîne la création d'un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), ", en plus de l'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " créé précédemment."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les événements de soumission partagent tous les propriétés suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "subject"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'objet de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "from"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le champ de destinataire de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "replyTo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "liste de chaînes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le champ de réponse de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "cc"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "liste de chaînes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le champ de copie de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "bcc"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "liste de chaînes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le champ de copie cachée de l'e-mail."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["De plus, les événements ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " ont les propriétés suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "dropReason"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne (énumération)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La raison pour laquelle l'e-mail a été abandonné. Consultez ci-dessous les valeurs possibles."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "dropMessage"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le message brut décrivant la raison pour laquelle l'e-mail a été abandonné. Ceci fournira généralement des détails supplémentaires à ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropReason'"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Motifs d'abandon"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Motif d'abandon"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_BOUNCED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un précédent message envoyé au destinataire a été rejeté."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUS_SPAM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un précédent message envoyé au destinataire a été signalé comme indésirable."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_UNSUBSCRIBED_MESSAGE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le destinataire s'est précédemment désabonné de cette inscription."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_UNSUBSCRIBED_PORTAL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le destinataire s'est précédemment désabonné de toutes les inscriptions."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "INVALID_TO_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'adresse e-mail dans le champ « destinataire » n'a pas passé la validation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "INVALID_FROM_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'adresse e-mail dans le champ « de » n'a pas pu être validée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BLOCKED_DOMAIN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le domaine du destinataire a été bloqué."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BLOCKED_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le destinataire a expressément demandé de ne pas recevoir d'e-mails envoyés via HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "EMAIL_UNCONFIRMED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La confirmation d'inscription aux e-mails a été activée et le destinataire n'a pas encore confirmé son inscription."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CAMPAIGN_CANCELLED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La campagne e-mail associée a été annulée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MTA_IGNORE"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Notre fournisseur a décidé d'abandonner le message. Tout détail supplémentaire sera inclus dans ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropMessage'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PORTAL_OVER_LIMIT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Votre compte a dépassé sa limite mensuelle d'envoi d'e-mails."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PORTAL_SUSPENDED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Votre compte a été suspendu pour cause de non-conformité ou suite à des problèmes de délivrabilité."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "QUARANTINED_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le destinataire a été mis en quarantaine en raison de taux de rejet répétés."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ADDRESS_LIST_BOMBED"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" Le destinataire a été mis en quarantaine en raison d'une activité de formulaire suspecte. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/email/remediate-list-bombing",
              children: "Découvrez-en davantage"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Événements de remise"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que notre fournisseur de messagerie a accepté un e-mail, nous créons un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROCESSED"
      }), ". À ce stade, le prestataire de services de distribution a mis le message en file d'attente. Si tout se passe bien, le fournisseur de messagerie mettra le message en file d'attente et le remettra au serveur de messagerie du destinataire, générant ainsi un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELIVERED"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il arrive parfois que les choses ne se passent pas bien et que la distribution soit différée en raison d'un rejet temporaire ou qu'elle échoue sans nouvelle tentative."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le premier cas, le message n'a pas pu être remis au serveur de messagerie du destinataire pour une raison non définitive (généralement temporaire), telle qu'un dépassement de délai intempestif. Le fournisseur de services de distribution remettra le message en file d'attente pour une distribution ultérieure, et nous créons un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DEFERRED"
      }), ". Un message peut être reporté plusieurs fois avant de terminer la phase de distribution, un nouvel événement étant créé à chaque tentative."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En cas d'échec, il n'y aura plus de tentative de distribution du message et nous créons un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), ". Cela peut se produire pour diverses raisons, par exemple si le destinataire n'est pas connu du serveur de messagerie."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les types d'événements de distribution spécifiques ont les propriétés suivantes :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Remis"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La réponse complète du serveur de messagerie du destinataire."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "smtpId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un identifiant joint au message par HubSpot."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Reporté"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La réponse complète du serveur de messagerie du destinataire."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "attempt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "entier 32 bits"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le numéro de la tentative de distribution."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Rejet"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "category"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne (énumération)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La meilleure estimation du type de rejet rencontré. Si une catégorie appropriée n'a pas pu être déterminée, cette propriété est omise. Consultez ci-dessous les valeurs possibles. Notez qu'il s'agit d'une valeur dérivée, qui peut être modifiée à tout moment pour améliorer la précision de la classification."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La réponse complète du serveur de messagerie du destinataire."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "status"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le code de statut renvoyé par le serveur de messagerie du destinataire."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Catégorie de rejet"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Catégorie de rejet"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UNKNOWN_USER"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le destinataire n'existait pas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MAILBOX_FULL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La boîte de réception du destinataire est pleine et ne peut plus recevoir de message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CONTENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les filtres du destinataire ont identifié le contenu du corps de l'e-mail comme suspect ou indésirable."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SPAM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le message a été marqué comme indésirable."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "POLICY"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le message a été marqué comme indésirable."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "GREYLISTING"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le serveur de messagerie exige un historique plus long de l'activité de l'e-mail de la part de l'expéditeur."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MAILBOX_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une mauvaise configuration de la boîte de réception a été détectée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ISP_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une mauvaise configuration de l'ISP a été détectée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DOMAIN_REPUTATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le domaine d'envoi a une mauvaise réputation ou une réputation qui ne répond pas aux normes du serveur destinataire."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DMARC"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le domaine de l'expéditeur ne répond pas à la vérification DMARC. Veuillez consulter vos politiques SPF et DMARC."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SENDING_DOMAIN_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'authentification du domaine a échoué en raison d'une politique du côté du destinataire."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "TIMEOUT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le serveur de messagerie de réception a dépassé le temps imparti et n'accepte plus d'e-mails."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "THROTTLED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le serveur de messagerie du destinataire a limité les messages."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UNCATEGORIZED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Une erreur non catégorisée a été détectée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "IP_REPUTATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le message provient d'une adresse IP suspecte (ou précédemment inconnue)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DNS_FAILURE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les paramètres du serveur concernant le nom de domaine du destinataire ont été mal configurés au moment de l'envoi de l'e-mail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "TEMPORARY_PROBLEM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un problème temporaire s'est produit."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Événements d'engagement des utilisateurs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsqu'un e-mail atteint son destinataire, quatre types d'événements différents peuvent se produire : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PRINT"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORWARD"
      }), ". Ils représentent l'interaction du destinataire avec le message et son contenu, et chacun d'entre eux peut se produire plusieurs fois. Par exemple, chaque fois qu'une URL est cliquée, un nouvel événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), " est créé, même si cette URL a déjà été cliquée et a généré un tel événement."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les événements d'engagement des utilisateurs partagent tous les propriétés suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "userAgent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'agent utilisateur responsable de l'événement, par exemple “Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/28.0.1500.95 Safari/537.36”"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "browser"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un objet JSON représentant le navigateur qui a géré l'événement. Il est composé des propriétés suivantes : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'name'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'family'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'producer'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'producer_url'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'type'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'url'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'version'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "location"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un objet JSON représentant l'emplacement où l'événement s'est produit. Il est composé des propriétés suivantes : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'city'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'state'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'country'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "filteredEvent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un booléen indiquant si l'événement a été filtré ou non en dehors des rapports en tant qu'événement de robot selon les paramètres des rapports des clients."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["De plus, les événements ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), " ont les propriétés suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "url"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'URL d'origine dans le message sur laquelle le destinataire a cliqué."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "referer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'URL de la page web qui renvoie à l'URL cliquée. Le client de messagerie du destinataire détermine s'il y a lieu de fournir cette information et quelle est sa valeur."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par ailleurs, les événements ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), " peuvent avoir la propriété suivante :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "duration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "entier 64 bits (millisecondes)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Si elle est fournie et non nulle, la durée approximative, en millisecondes, pendant laquelle l'utilisateur a ouvert l'e-mail."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Événements de statut des utilisateurs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le destinataire peut également mettre à jour ses préférences de communication via l'e-mail. En cliquant sur le lien des préférences d'abonnement dans le message, ils peuvent modifier leurs abonnements, en s'abonnant ou en se désabonnant de différentes listes, ce qui déclenche un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STATUSCHANGE"
      }), ". Notez qu'un changement de statut peut concerner n'importe quelle liste, et pas seulement celle qui est associée à l'e-mail en cours."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un e-mail peut également être signalé comme indésirable par le destinataire, ce qui entraîne un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SPAMREPORT"
      }), ". Notez que cela est indépendant du statut de l'abonnement. Le fait de signaler un message comme étant indésirable ne désabonne pas simplement le destinataire de la liste en question. Au contraire, le statut de l'abonnement reste inchangé et un indicateur est activé pour indiquer que le destinataire ne doit plus jamais recevoir d'e-mail de la part de HubSpot. Dans ce cas, vous aurez besoin d'une intervention manuelle de la part de HubSpot pour supprimer l'indicateur."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STATUSCHANGE"
      }), " possède les propriétés supplémentaires suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "source"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne (énumération)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La source de la modification de l'abonnement. Consultez ci-dessous les valeurs possibles."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "requestedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'adresse e-mail de la personne qui demande la modification au nom du destinataire. Si elle n'est pas applicable, cette propriété est omise."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "portalSubscriptionStatus"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["chaîne (énumération, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'SUBSCRIBED'"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ")"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Le statut de l'abonnement au portail du destinataire. (Pour ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ", la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'subscriptions'"
            }), " n'est ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "pas"
            }), " nécessairement un tableau vide et tous les abonnements qu'il contient n'auront pas nécessairement leur statut défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ")"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "subscriptions"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un tableau d'objets JSON représentant le statut des abonnements pour le destinataire. Chaque objet d'abonnement JSON est composé des propriétés suivantes : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'id'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'status'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "bounced"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["booléen (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ou totalement omis)"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un employé de HubSpot a explicitement initié le changement de statut pour bloquer les messages au destinataire. (Notez que cette utilisation a été supprimée en faveur de l'abandon des messages dont ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropReason'"
            }), " est ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOCKED\\_ADDRESS"
            }), ")."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Source de la modification de l'abonnement"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Source"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_LIST_UNSUBSCRIBE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le destinataire a utilisé un en-tête liste/désabonnement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_RECIPIENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le destinataire a utilisé l'interface utilisateur de l'abonnement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_IMPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le client a importé les abonnements dans son portail."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT_CUSTOMER"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Le client a utilisé l'interface utilisateur de l'abonnement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_SPAM_REPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un rapport de spam généré par un système automatisé a été reçu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_NON_DELIVERY_REPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un rapport de non-remise (généralement un rejet) a été reçu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_DIRECT_COMPLAINT"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Une plainte directe via ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "abuse@hubspot.com"
            }), " a été reçue."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_MTA_RECORD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Notre prestataire de services a fourni le changement lors de notre synchronisation normale avec leur système d'enregistrement."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un employé de HubSpot a effectué la modification."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_MIGRATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpot a migré les abonnements d'une version précédente du produit."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT_CLEANUP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpot a nettoyé les abonnements."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_KNOWN_SPAM_TRAP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cette adresse de destinataire est un piège à spam connu et ne doit pas recevoir d'e-mail."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'Événements de réactivation des envois d'e-mails"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il existe un 13e type d'événement, qui n'est pas lié à un e-mail spécifique. Les événements ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), " se produisent lors de la ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "réactivation automatique ou manuelle des envois d'e-mails"
      }), " pour une adresse e-mail particulière de la part de HubSpot. Cette opération réinitialise le statut de rejet du destinataire, ce qui lui permet éventuellement de recevoir des e-mails de votre portail."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Nom de la propriété"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "user"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "L'adresse e-mail de l'utilisateur qui a soumis la demande de réactivation des envois d'e-mails."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Références de l'événement"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "De nombreux événements sont liés à d'autres événements qui se sont produits avant ou après eux. Comme décrit dans la première section ci-dessus, nous utilisons les éléments eventId pour créer cette chaîne de référence."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Notez que les références d'événements sont relativement nouvelles et que les événements plus anciens peuvent ne pas en être pourvus."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'sentBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Comme nous l'avons vu précédemment, chaque e-mail est associé à un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " (ou à un événement de chaque type). Il s'agit du premier événement généré pour un message donné. Si un message génère un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), ", tous les événements générés par la suite feront référence à cet événement via la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'sentBy'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cette référence rétrospective peut être utile pour obtenir plus d'informations sur l'événement parent ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " ou pour trouver manuellement tous les événements associés à un message donné."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'obsoletedBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Parfois, un événement de suivi se produit pour un message donné, ce qui signifie qu'un événement antérieur doit être ignoré. Cette relation est capturée dans une référence en amont dans la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, dans le cas où nous générons à la fois un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " et un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " ultérieur, l'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " n'est finalement pas pertinent et est ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "obsoleted by"
      }), " par l'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), ". En conséquence, l'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " fera référence à l'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'causedBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Certains événements se produisent précisément en raison d'un événement antérieur, souvent pour un message différent. Cette relation est capturée dans une référence en aval dans la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), ". Il peut être utilisé pour obtenir des détails supplémentaires sur la raison pour laquelle un événement particulier a provoqué l'événement suivant."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " se produira s'il y a eu un événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), " précédent pour le même destinataire. Dans ce cas, l'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " aura l'élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'dropReason'"
      }), " défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PREVIOUSLY\\_BOUNCED"
      }), " et l'élément ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), " fera référence à l'événement ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), " précédent."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}