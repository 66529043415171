"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 49468701815;
const slug = exports.slug = 'guides/cms/content/data-driven-content/dynamic-pages/overview';
const title = exports.title = 'Dynamic pages overview';
const name = exports.name = '[Developers] Dynamic pages overview';
const metaDescription = exports.metaDescription = 'Learn how to create CMS pages that use content from a structured data source, such as HubDB or CRM objects.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "crm-object-dynamic-pages",
  "label": "CRM object dynamic pages",
  "parent": null
}, {
  "depth": 1,
  "id": "requirements",
  "label": "Requirements",
  "parent": "crm-object-dynamic-pages"
}, {
  "depth": 1,
  "id": "content-creation",
  "label": "Content creation",
  "parent": "crm-object-dynamic-pages"
}, {
  "depth": 1,
  "id": "start-building",
  "label": "Start building",
  "parent": "crm-object-dynamic-pages"
}, {
  "depth": 1,
  "id": "more-resources",
  "label": "More resources",
  "parent": "crm-object-dynamic-pages"
}, {
  "depth": 0,
  "id": "hubdb-dynamic-pages",
  "label": "HubDB dynamic pages",
  "parent": null
}, {
  "depth": 1,
  "id": "prerequisites",
  "label": "Prerequisites",
  "parent": "hubdb-dynamic-pages"
}, {
  "depth": 1,
  "id": "content-creation",
  "label": "Content creation",
  "parent": "hubdb-dynamic-pages"
}, {
  "depth": 1,
  "id": "start-building",
  "label": "Start building",
  "parent": "hubdb-dynamic-pages"
}, {
  "depth": 1,
  "id": "more-resources",
  "label": "More resources",
  "parent": "hubdb-dynamic-pages"
}, {
  "depth": 0,
  "id": "creating-dynamic-referral-urls-with-query-string-parameters",
  "label": "Creating dynamic referral URLs with query string parameters",
  "parent": null
}, {
  "depth": 1,
  "id": "crm-objects",
  "label": "CRM objects",
  "parent": "creating-dynamic-referral-urls-with-query-string-parameters"
}, {
  "depth": 1,
  "id": "hubdb-table",
  "label": "HubDB table",
  "parent": "creating-dynamic-referral-urls-with-query-string-parameters"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      em: "em",
      ul: "ul",
      li: "li",
      h3: "h3",
      strong: "strong",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Dynamic pages overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dynamic pages are CMS pages that get their content from a structured data source, such as HubDB or CRM objects. Based on how you configure your dynamic page template or modules, HubSpot will use that data to automatically create and populate a set of pages. This includes a listing page that displays summaries of the data, and individual pages for each data source entry (HubDB row or CRM object record)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depending on the data source you choose, there are different prerequisites, advantages, and content creation experiences. Below, read about each data source and how to choose which one is best for you."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["You can learn more about building data-based CMS pages in HubSpot Academy's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "CMS Data-Driven Content course"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRM object dynamic pages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot, CRM objects represent the different types of records and relationships your business has. Standard CRM objects include contacts, companies, deals, and tickets. With an ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " subscription, you can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "create custom objects"
      }), ". Using any of these CRM objects, you can create a listing page and individual details pages for each record of the object type you choose."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, a car dealership could store their inventory as records with a custom ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Car"
      }), " object. Then, using CRM object dynamic pages, they could list their inventory online with a unique page automatically created for each car. When a new record is created under the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Car"
      }), " object, a new page will be created automatically, keeping the inventory and website in sync."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You may want to use CRM objects as your data source if:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You want to associate records to other CRM objects, such as contacts."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You want to create automation or personalization based on the object."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "It simplifies your businesses processes and record keeping."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Requirements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To build CRM object dynamic pages, you’ll need:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Content Hub Professional"
          })
        }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enterprise"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To build using Custom Objects you'll need either ", (0, _jsxRuntime.jsxs)(_components.strong, {
          children: [(0, _jsxRuntime.jsx)(_components.em, {
            children: "Content"
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Hub"
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          })]
        }), ", or ", (0, _jsxRuntime.jsxs)(_components.strong, {
          children: [(0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketing Hub"
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          })]
        }), " with ", (0, _jsxRuntime.jsxs)(_components.strong, {
          children: [(0, _jsxRuntime.jsx)(_components.em, {
            children: "Content Hub"
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          })]
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["An understanding of how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "create custom modules"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A standard or custom object as a data source."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you can create up to 10 dynamic pages per data source. For CRM object dynamic pages, this means up to 10 dynamic pages per CRM object. For example, you can create up to 10 dynamic pages using the contacts object and 10 dynamic pages using the companies object."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Content creation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After you create your dynamic content modules, they can be inserted into any page that includes a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "drag and drop area"
      }), ". To edit how the data is displayed, you'll need to update the modules or template you've created. If the template includes any other drag and drop areas, you can edit the page's non-dynamic content within the page editor. Any updates made to the dynamic or non-dynamic content will apply to both the listing page and the details pages, similar to editing a template."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Because the dynamic page content is coming from ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/use-custom-objects",
        children: "CRM object records"
      }), ", you manage dynamic page content the same way you would manage other CRM records. For example, you can edit dynamic page content by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm/update-a-property-value-for-a-record",
        children: "editing"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/delete-crm-records",
        children: "deleting"
      }), " individual records in HubSpot. Similarly, you can manage content in bulk by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/import-objects",
        children: "importing"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/how-can-i-bulk-edit-contacts-companies-deals-or-tasks",
        children: "bulk editing records"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The type of pages you can create depends on the object you choose:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Public pages can be built using the following objects:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Products"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Marketing events"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Custom objects"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Private pages (", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/website-pages/password-protect-a-page",
            children: "password protected"
          }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/website-pages/require-member-registration-to-access-private-content",
            children: "member registration"
          }), ") can be built using the following objects:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Contacts"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Companies"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Deals"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Quotes"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Tickets"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "It’s important to be aware of automation that’s set up for the CRM object you choose. For example, if you have a workflow that automatically updates a custom object record’s name based on associated deal stage, your dynamic page content will also be updated any time the record’s name changes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Start building"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To start building CRM object dynamic pages, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/crm-objects",
        children: "developer guide for building CRM object dynamic pages"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you plan to use custom objects as your data source, learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "create and manage custom objects through HubSpot’s API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "More resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/crm-objects",
          children: "How to build dynamic pages using CRM objects"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/crm-objects",
          children: "CRM objects in CMS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/get-started/manage-your-crm-database",
          children: "Manage your CRM database"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB dynamic pages"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), ", you can generate dynamic pages from the rows of a HubDB table. You can also use child tables to create nested pages that map to separate blogs or listing pages of your website. When you enable a HubDB table as a data source, you can select which columns to use for the meta description, featured image, and canonical URL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You may want to use HubDB as the data source for your dynamic pages if:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You don’t need to associate data from your tables with your CRM data."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview#hubdb-technical-limits",
          children: "HubDB technical limits"
        }), " are not an issue for your use-case."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Prerequisites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To build HubDB dynamic pages, you’ll need:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Content Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["An existing HubDB table, or learn how to get started and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview#creating-your-first-table",
          children: "create your first HubDB table"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["An understanding of how to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "create custom modules"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you can create up to 10 dynamic pages per data source. For HubDB dynamic pages, this means up to 10 dynamic pages per HubDB table. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview#hubdb-technical-limits",
          children: "HuDB technical limits"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Content creation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After you create and enable your HubDB table for dynamic page content, you manage the content of your pages by creating, editing, and deleting HubDB rows. You can edit HubDB tables directly in your HubSpot account, or you can edit your schema externally then upload the data via a CSV or through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb",
        children: "HubDB API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To edit how the data is displayed, you'll need to update the modules or template you've created. If the template includes any other drag and drop areas, you can edit the page's non-dynamic content within the page editor. Any updates made to the dynamic or non-dynamic content will apply to both the listing page and the details pages, similar to editing a template."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Start building"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To start building dynamic pages using HubDB,check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "developer guide for building HubDB dynamic pages"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "More resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "How to build multi-level dynamic pages with HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "How to add videos to HubDB dynamic pages"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "How to join multiple HubDB tables"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Creating dynamic referral URLs with query string parameters"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you have a setup where you'll need unique versions of each page URL while the content of the page remains the same across all versions, you should use query string parameters instead of dynamic pages."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When using dynamic pages, instances of your content are generated for every instance of a HubDB table or CRM object, but if the content is identical across all your pages, you will be flagged for duplicate content by search engines. This will lead to a scattered sitemap, a confusing website search experience, and extra entries in your website's page analytics."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "By using query string parameters, you can accomplish the same goal of having a unique URL per referrer. You can even dynamically show unique content on a page if needed, but there won't be a dynamically generated page per instance of a HubDB table or CRM object; instead, there will only be one page with dynamic content that's shown based on the query string parameter."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Depending on whether you're using a HubDB table or CRM object as your data source, you'll need to use a different HubL function to query for the associated data in your account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Consult the code snippets below, which both parse the logo ID from a URL such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://example.com/my-page?logo_identifier=123"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CRM objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubL code below checks whether a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "logo_identifier"
      }), " parameter is present in the query string, then uses the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm_object"
      }), " HubL function to fetch the data that corresponds to the associated logo record, which was created as a custom object."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request.query_dict.logo_identifier %}\n<h3> The logo query string is present</h3>\n\n{% set logo_identifier = request.query_dict.logo_identifier|string %}\n{% set query = \"logo_id=\" + logo_identifier %}\n{% set logo = crm_object(\"logos\", query, \"logo_name, logo_id, logo_url\") %}\nLogo data: {{ logo }} <br>\nLogo id: {{ logo.logo }} <br>\nLogo name: {{ logo.name }}\n\n{% else %}\n<h3>The logo query string is NOT present</h3>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm_object"
      }), " HubL function in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "this article"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubDB table"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubL code below parses the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "logo_identifier"
      }), " parameter in the query string, then uses the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows"
      }), " HubL function to fetch the logo's URL from the associated HubDB table (e.g., a table with an ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "10181541"
      }), ") using the logo ID from the query string."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# get logo ID from query string#}\n{% set logo_identifier = request.query_dict.logo_identifier|string %}\n{% set query = \"identifier=\" + logo_identifier %}\n{% set logo_row = hubdb_table_rows(10181541, query)[0] %}\n<img src=\"{{ logo_row.logo.url }}\" style=\"max-width: 500px;\">\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Or you can also invoke the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_row"
      }), " function, though you'll need to pass the row ID in the query parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set row_id = request.query_dict.logo_identifier|string %}\n{% set logo_row = hubdb_table_row(10181541, row_id) %}\n<img src=\"{{ logo_row.logo.url }}\" style=\"max-width: 500px;\">\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_row"
      }), " HubL function in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-row",
        children: "this article"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}