"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 41185540909;
const slug = exports.slug = 'guides/cms/setup/creating-an-efficient-development-workflow';
const title = exports.title = 'HubSpot開発ワークフローを最適化する';
const name = exports.name = 'APAC JAPAN (JA) | [Developers] Development Workflow | 202103';
const metaDescription = exports.metaDescription = 'HubSpotのCMSでウェブサイトを作成する際の効率的な開発ワークフローの構築。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E7%A7%BB%E6%A4%8D%E3%82%92%E5%BF%B5%E9%A0%AD%E3%81%AB%E7%BD%AE%E3%81%84%E3%81%9F%E9%96%8B%E7%99%BA",
  "label": "移植を念頭に置いた開発",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%8B%E7%99%BA%E7%92%B0%E5%A2%83%E3%81%AE%E8%A8%AD%E5%AE%9A",
  "label": "開発環境の設定",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%B3%E3%83%BC%E3%83%89%E3%82%A8%E3%83%87%E3%82%A3%E3%82%BF%E3%83%BC%E3%81%AE%E8%A8%AD%E5%AE%9A",
  "label": "コードエディターの設定",
  "parent": "%E9%96%8B%E7%99%BA%E7%92%B0%E5%A2%83%E3%81%AE%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "vs-code",
  "label": "VS Code",
  "parent": "%E9%96%8B%E7%99%BA%E7%92%B0%E5%A2%83%E3%81%AE%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 2,
  "id": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AE%E3%82%B3%E3%83%BC%E3%83%89%E3%82%A8%E3%83%87%E3%82%A3%E3%82%BF%E3%83%BC%E3%82%84ide",
  "label": "その他のコードエディターやIDE",
  "parent": "%E9%96%8B%E7%99%BA%E7%92%B0%E5%A2%83%E3%81%AE%E8%A8%AD%E5%AE%9A"
}, {
  "depth": 0,
  "id": "%E3%83%86%E3%82%B9%E3%83%88",
  "label": "テスト",
  "parent": null
}, {
  "depth": 2,
  "id": "%E3%82%A8%E3%83%87%E3%82%A3%E3%82%BF%E3%83%BC",
  "label": "エディター",
  "parent": "%E3%83%86%E3%82%B9%E3%83%88"
}, {
  "depth": 2,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%83%97%E3%83%AC%E3%83%93%E3%83%A5%E3%83%BC",
  "label": "モジュールのプレビュー",
  "parent": "%E3%83%86%E3%82%B9%E3%83%88"
}, {
  "depth": 2,
  "id": "%E3%83%87%E3%83%90%E3%83%83%E3%82%B0",
  "label": "デバッグ",
  "parent": "%E3%83%86%E3%82%B9%E3%83%88"
}, {
  "depth": 2,
  "id": "%E3%82%B5%E3%83%B3%E3%83%89%E3%83%9C%E3%83%83%E3%82%AF%E3%82%B9",
  "label": "サンドボックス",
  "parent": "%E3%83%86%E3%82%B9%E3%83%88"
}, {
  "depth": 0,
  "id": "%E3%83%87%E3%83%97%E3%83%AD%E3%82%A4",
  "label": "デプロイ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      h4: "h4",
      img: "img",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot開発ワークフローを最適化する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "効率的な開発者ワークフローを確立すると、HubSpotのCMSでウェブサイトを構築する際の作業を効率化できます。ウェブ開発チームやプロジェクトの性質によっては、ワークフローが異なる場合もあります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、新しいCMS Hubアカウントで1人の開発者が新しいサイトを構築する場合は、テストや共同作業についてほとんど考慮する必要がありません。一方、チームで大規模なウェブサイトの構築に取り組む場合は、開発とステージングプロセスの明確化、実装のワークフロー、ソースコードの管理を通じて作業を効率化する必要になります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このガイドでは、ニーズに合わせて調整できる、効率的な開発者ワークフローの確立について説明します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["なお、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "ローカル開発を開始する"
      }), "チュートリアルに従って、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), "を使用してウェブサイトを構築することを前提としています。また、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart#quick_start",
        children: "HubSpotのCMS上での開発手順に関するクイックスタートガイド"
      }), "を完了していることも前提としています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "移植を念頭に置いた開発"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発者ワークフローを確立する前に、効率的な開発者ワークフローを作成するには、移植のしやすさが重要になることを認識しておくことが大切です。移植が容易なプロジェクトは、ほとんど摩擦や説明なしに環境間でスムーズに移行でき、本稼働前の変更のテストとステージングも簡単です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "CMSテーマボイラープレート"
      }), "は、相対ファイルパスなどを活用し、移植を念頭に置いたプロジェクトの例です。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), "を使用したプロジェクト内の全てのアセットの実際のファイルフォーマットとして、ソース管理が可能で、どのようなHubSpotアカウントでも作業できる状態になっています。新しいプロジェクトに着手する開発者にとって、このプロジェクトは特に参考になります。HubSpotの既定のテーマはいずれもこのボイラープレートを使用して構築されているため、移植が容易で、作業を始める際にも役立ちます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "開発環境の設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["チーム内の開発者1人ひとりが、各自の開発環境として無料の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "CMS開発者用サンドボックスアカウント"
      }), "を作成しておいてください。このアカウントには有効期限がなく、HubSpotの有料CMSアカウントの全機能が用意されています（ただし、カスタムドメインへの接続を除きます）。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS CLIを使用することで、複数のCMSアカウントを簡単に利用できます。ご使用のCMS開発者用サンドボックスアカウントに、新しい", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "設定"
      }), "を作成します。サンドボックスの設定には「DEV」や「SANDBOX」のような名前を付けて、このアカウントが開発環境であることを明確にします。さらに、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "defaultPortal"
      }), "にサンドボックスアカウントを指定しておけば、CMS CLIを使用してコマンドを実行する際に自動的に自分のサンドボックスを利用できるので、誤って本番環境に展開されなくなります。この時点で、設定ファイルは次のようになっています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: DEV\nportals:\n  - name: PROD\n    portalId: 123\n    authType: personalaccesskey\n    personalAccessKey: >-\n      xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n  - name: DEV\n    portalId: 456\n    authType: personalaccesskey\n    personalAccessKey: >-\n      xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この状態で、CMS CLIで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload"
        })
      }), "のようなコマンドを実行すると、ポータルを指定しない場合はファイルが「DEV」アカウントにアップロードされます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "コードエディターの設定"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでの開発時には、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#vs-code",
        children: "VS Code"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-code-editors-and-ides",
        children: "他のコードエディターやIDE"
      }), "など、使い慣れたコードエディターを使用できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "VS Code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot上で作業する多くの開発者は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://code.visualstudio.com/",
        children: "Visual Studio Code"
      }), "を使用しています。そこから、HubSpot VS Code拡張機能が着想されました。この拡張機能によって、便利なIntelliSenseスニペット、HubLのコード補完、HubL Lint（静的解析）を強調表示するHubL構文が追加されます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode",
        children: "オープンソース"
      }), "のプロジェクトですので、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/blob/master/CONTRIBUTING.md",
        children: "皆さまのご参加をお待ちしています"
      }), "。フィードバックについては、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/issues",
        children: "リポジトリー上で問題をご提出"
      }), "ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "c0ab15c7-27f7-4f4d-a884-5468e852577a",
      external: true,
      label: "Get VS Code Extension"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/vs%20code%20extension%20-%20hubl%20variable%20suggestion.gif",
        alt: "HubL変数の候補を示す、VS Code拡張機能のスクリーン キャプチャー アニメ"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "その他のコードエディターやIDE"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "公式の拡張機能が用意されているのはVS Codeですが、他のエディターもご使用になれます。HubLは、JinjaベースのJinjavaを基にしたHubSpotによるプライベートフォークです。構文が似ているJinja構文の強調表示拡張機能がある程度機能します。利用可能な拡張機能とアドオンツールはエディターによって異なります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "テスト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "変更をテストするには、主に2つの方法があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**watch／アップロードを使用したテスト：**開発環境で作業する際には", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#watch",
          children: "watch"
        }), "コマンドを使用するのが適切です。これにより、テキストエディターでファイルを保存すると変更が自動的にアップロードされるので、迅速な開発が可能になります。デザインマネージャーの［表示オプションを使用したライブプレビュー］ツールをテンプレートで使用した場合、変更を保存すると、テンプレートプレビューのレンダリング出力に変更が自動的に反映されます。テンプレートのライブプレビューを表示するには、デザインマネージャーのテンプレートエディター内で、［プレビュー］>［表示オプションを使用したライブプレビュー］を選択します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**ローカルでのテスト：**アカウントにアップロードせずに変更内容をローカルでプレビューするには、テーマのルートディレクトリーで", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs theme preview"
        }), "コマンドを実行できます。このコマンドは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://hslocal.net:3000/",
          children: "https://hslocal.net:3000/"
        }), "でローカル プロキシ サーバーを実行します。これを使用してテーマのテンプレートとモジュールをプレビューできます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#locally-preview-theme",
          children: "hs theme previewコマンド"
        }), "の詳細についてご覧ください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "エディター"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開発の段階では、コンテンツ作成ツールで変更をテストすることも非常に重要です。コンテンツエディターで操作するモジュールまたはテンプレートを作成する場合には、開発環境で実際にページを作成して、コンテンツを編集できることを確認します。モジュールを不適切な設定にドラッグ操作したり、ダミーコンテンツを入力したりして、ページ作成の際にマーケティング担当者がモジュールを「破損」できないことを確かめます。コンテンツエディターを使用すると、テンプレートやモジュールにどのような安全策を組み込む必要があるかが分かります。現時点では、ページやブログ記事などのコンテンツをHubSpotアカウント間で移動することはできません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "モジュールのプレビュー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "デザインマネージャー内のモジュールエディターで、［プレビュー］ボタンを選択します。これによりプレビューエディターが開き、モジュールとモジュール内のフィールドがコンテンツエディターでどのように動作するかが分かります。この安全な環境でダミーコンテンツを使用して、モジュールのフィールド、グループ、リピーターをテストできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/module-preview.gif",
        alt: "モジュールのプレビュー"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "デバッグ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ウェブサイトの問題に関するデバッグとトラブルシューティングの方法を理解しておくことは、ウェブサイトの安定稼働と成功に欠かせません。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting",
        children: "HubSpotのCMSで開発する際のデバッグ手法"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "サンドボックス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["開発環境の設定に関連して前述したように、安全な開発環境として、テストに使用する無料の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "CMS開発者用サンドボックス"
      }), "アカウントを作成できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "デプロイ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["変更をテストして、公開する準備ができたら、変更を本番ポータルにデプロイ（実装）します。ローカル設定によっては、本番アカウントを利用できるように、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "--portal"
      }), "引数を指定してCMS CLIコマンドを実行する必要があります（例：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-theme/src my-theme --portal=PROD"
      }), "）。本番アカウントにファイルをアップロードする際には、診断が必要なエラーがないことに注意すると共に、公開されたウェブサイトを簡単に目視して、意図しない状態になっていないことを確認してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ウェブ開発チームの一員として作業している場合は、本番コードベース全体を一元的にバージョン管理し、変更がメインコードにマージされると本番ポータルに実装されるようにすることをおすすめします。こうすることで、開発チームが使い慣れたバージョン管理システムを共同作業、変更の追跡、変更のロールバックに活用できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["gitリポジトリーでのCI（継続的インテグレーション）の設定については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "GitHubアクションを使用した、メインコードへの変更のマージに基づく本番アカウントへの実装"
      }), "に関するガイドに従ってください。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}