"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501742926;
const slug = exports.slug = 'reference/api/deprecated';
const title = exports.title = 'API de HubSpot | API obsolètes';
const name = exports.name = 'EMEA (FR) vNext Docs DP - Deprecated APIs';
const metaDescription = exports.metaDescription = 'Découvrez la liste des API obsolètes de HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "api-caduques",
  "label": "API caduques",
  "parent": null
}, {
  "depth": 0,
  "id": "api-obsol%C3%A8tes",
  "label": "API obsolètes",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      br: "br",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API caduques et obsolètes"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les API répertoriées dans les sections ci-dessous ne recevront ", (0, _jsxRuntime.jsx)("u", {
        children: "pas"
      }), " de mises à jour futures des fonctionnalités et, dans certains cas, seront entièrement désactivées et indisponibles pour vos intégrations."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il est fortement recommandé de vous abonner au ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "journal des modifications des développeurs HubSpot"
      }), " pour suivre les dernières mises à jour, les modifications de dernière minute et d'autres modifications importantes apportées aux fonctionnalités."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API caduques"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les API suivantes ont été entièrement supprimées et renverront une erreur en cas d'appel à leurs points de terminaison associés :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["À partir du 20 novembre 2022, les clés d'API seront supprimées en tant que méthode d'authentification. Découvrez-en davantage ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "sur cette modification"
        }), " et sur la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migration d'une intégration de clé d'API pour utiliser une application privée"
        }), " à la place."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Depuis le 28 février 2023, les API suivantes ne sont plus disponibles :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated",
              children: "API Passerelle e-commerce"
            }), " : si vous avez déjà créé une intégration à l'aide de ces API, vous pouvez suivre ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration",
              children: "ce guide de migration"
            }), " pour basculer vers votre intégration et utiliser des applications privées."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated",
              children: "API d'extension de comptabilité"
            }), " : si vous avez déjà créé une intégration à l'aide de ces API, vous pouvez suivre ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-account-extension-integration",
              children: "ce guide de migration"
            }), " pour basculer vers votre intégration et utiliser des applications privées.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Depuis le 31 août 2023, les API de calendrier ne sont plus disponibles. Cela comprend les points de terminaison suivants :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Lister les événements de contenu"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Lister les événements sur les réseaux sociaux"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Lister des événements de tâche de calendrier"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Lister tous les événements du calendrier"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Créer une tâche de calendrier"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Obtenir la tâche d calendrier par ID"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Mettre à jour la tâche de calendrier"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Supprimer une tâche de calendrier"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API obsolètes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les points de terminaison hérités répertoriés ci-dessous ne recevront ", (0, _jsxRuntime.jsx)("u", {
        children: "pas"
      }), " de mise à jour de version. Ces points de terminaison sont fonctionnels et stables, mais ne seront pas mis à jour au-delà de leur version actuelle. HubSpot continuera de fournir une assistance à court terme et communiquera suffisamment à l'avance toute modification future dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "journal des modifications des développeurs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "API Réseaux sociaux :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-publishing-channels",
              children: "Obtenir des canaux de publication"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-broadcast-messages",
              children: "Obtenir des messages de diffusion"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-a-broadcast-message",
              children: "Obtenir un message de diffusion"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message",
              children: "Créer un message de diffusion"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message",
              children: "Annuler un message de diffusion"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}