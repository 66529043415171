export const APP_STARTED = 'APP_STARTED';
export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';
export const SET_DOCS_VERSION = 'SET_DOCS_VERSION';
export const SET_HAS_INITIALIZED_DOCS_VERSION = 'SET_HAS_INITIALIZED_DOCS_VERSION';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SEARCH_STARTED = 'SEARCH_STARTED';
export const SEARCH_SUCCEEDED = 'SEARCH_SUCCEEDED';
export const SEARCH_FAILED = 'SEARCH_FAILED';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const FETCH_PUBLIC_API_SPEC_SUCCEEDED = 'FETCH_PUBLIC_API_SPEC_SUCCEEDED';
export const FETCH_PUBLIC_API_SPEC_FAILED = 'FETCH_PUBLIC_API_SPEC_FAILED';
export const NO_PUBLIC_API_SPEC = 'NO_PUBLIC_API_SPEC';
export const FETCH_ALL_SPECS_STARTED = 'FETCH_ALL_SPECS_STARTED';
export const FETCH_ALL_SPECS_SUCCEEDED = 'FETCH_ALL_SPECS_SUCCEEDED';
export const FETCH_ALL_SPECS_FAILED = 'FETCH_ALL_SPECS_FAILED';