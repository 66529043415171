"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 21611793414;
const slug = exports.slug = 'guides/api/analytics-and-events/email-analytics';
const title = exports.title = 'Email Events API Overview | Email Events API';
const name = exports.name = 'Email Events API Overview';
const metaDescription = exports.metaDescription = 'The Email Events API is used to get information about events generated by marketing emails or email campaigns sent through a HubSpot account.';
const position = exports.position = 2;
const toc = exports.toc = [{
  "depth": 0,
  "id": "event-types",
  "label": "Event types",
  "parent": null
}, {
  "depth": 1,
  "id": "submission-events",
  "label": "Submission events",
  "parent": "event-types"
}, {
  "depth": 2,
  "id": "drop-reasons",
  "label": "Drop reasons",
  "parent": "event-types"
}, {
  "depth": 1,
  "id": "delivery-events",
  "label": "Delivery events",
  "parent": "event-types"
}, {
  "depth": 2,
  "id": "delivered",
  "label": "Delivered",
  "parent": "event-types"
}, {
  "depth": 2,
  "id": "deferred",
  "label": "Deferred",
  "parent": "event-types"
}, {
  "depth": 2,
  "id": "bounce",
  "label": "Bounce",
  "parent": "event-types"
}, {
  "depth": 2,
  "id": "bounce-categories",
  "label": "Bounce categories",
  "parent": "event-types"
}, {
  "depth": 1,
  "id": "user-engagement-events",
  "label": "User engagement events",
  "parent": "event-types"
}, {
  "depth": 1,
  "id": "user-status-events",
  "label": "User status events",
  "parent": "event-types"
}, {
  "depth": 2,
  "id": "subscription-change-sources",
  "label": "Subscription change sources",
  "parent": "event-types"
}, {
  "depth": 1,
  "id": "-unbounce--events",
  "label": "'Unbounce' events",
  "parent": "event-types"
}, {
  "depth": 0,
  "id": "event-references",
  "label": "Event references",
  "parent": null
}, {
  "depth": 1,
  "id": "-sentby-",
  "label": "'sentBy'",
  "parent": "event-references"
}, {
  "depth": 1,
  "id": "-obsoletedby-",
  "label": "'obsoletedBy'",
  "parent": "event-references"
}, {
  "depth": 1,
  "id": "-causedby-",
  "label": "'causedBy'",
  "parent": "event-references"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      h3: "h3",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      a: "a",
      h2: "h2",
      img: "img",
      em: "em",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Email Events API Overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The Email Events API is used to get information about events generated by marketing emails or email campaigns sent through a HubSpot account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Use case for this API:"
      }), " if a member of your marketing team wants to optimize email campaigns, you can use the Email Events API to gather data and power a machine learning model that determines the best time to send emails to different contact segments."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Email events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Every email sent via HubSpot generates a number of events detailing its lifecycle and how the recipient interacts with its content. An event is uniquely identified by its ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "EventId"
      }), ", which is comprised of the following properties:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "id"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A randomly-generated ID for this event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "created"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "64-bit integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The timestamp (in milliseconds since epoch) when this event was created."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["These properties can be used to look up a specific event via ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/email-analytics#get-email-event-by-id",
        children: "this endpoint"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Further, all but one event type (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), ") have the following properties:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "type"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string (enumeration)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The type of event. See below for more information on event types."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "recipient"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email address of the recipient of the email message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "portalId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "32-bit integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An ID referencing the HubSpot account that sent the email message. This will correspond to your account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "appId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "32-bit integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An ID referencing the HubSpot application that sent the email message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "appName"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The name of the HubSpot application that sent the email message. Note that this is a derived value, and may be modified at any time."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "emailCampaignId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "64-bit integer"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An ID referencing the email campaign which the email message is part of. Additional information on the email campaign can be found via ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/analytics-and-events/email-analytics#get-campaign-data-for-a-given-campaign",
              children: "this endpoint"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Events can be looked up in bulk via ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/email-analytics#get-email-events",
        children: "this endpoint"
      }), " using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'recipient'"
      }), ", both ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'appId'"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'campaignId'"
      }), ", or any combination of the above properties."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following additional properties are also available for all event types (including ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), "):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "sentBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The EventId which uniquely identifies the email message's ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SENT"
            }), " event. If not applicable, this property is omitted."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "obsoletedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The EventId which uniquely identifies the follow-on event which makes this current event obsolete. If not applicable, this property is omitted."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "causedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The EventId which uniquely identifies the event which directly caused this event. If not applicable, this property is omitted."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The event reference properties -- ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'sentBy'"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), " -- are discussed in detail later in this document."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Event types"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are 12 event types that can be generated by HubSpot's Email API during the lifecycle of an email message. They are broadly grouped into categories: Submission, Delivery, User Engagement, and User Status. Event types, event categories, and their relationships are diagrammed below."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://static.hsappstatic.net/developer_site_web/static-2.153/img/events.svg",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Event type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The message was sent to and received by our delivery provider, which has queued it for further handling."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DROPPED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The message was rejected, either by HubSpot or by our delivery provider, and no attempt will be made to deliver the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PROCESSED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The message has been received by our delivery provider, which has indicated it will attempt to deliver the message to the recipient's email server."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DELIVERED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient's email server has accepted the message and the message has been successfully delivered to the recipient."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DEFERRED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient’s email server has temporarily rejected message, and subsequent attempts will be made to deliver the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BOUNCE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient's email server couldn't or wouldn't accept the message, and no further attempts will be made to deliver the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "OPEN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient opened the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CLICK"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient clicked on a link within the message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "STATUSCHANGE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient changed their email subscriptions in some way."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SPAMREPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient flagged the message as spam."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SUPPRESSION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The message was not sent, the recipient has been unengaged with previous marketing emails."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " some event types, such as bot events, may be included in the\nresponse to the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/events"
        }), " API but won't appear when you're analyzing the\n", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "https://knowledge.hubspot.com/email/analyze-your-marketing-email-campaign-performance",
          children: [(0, _jsxRuntime.jsx)(_components.em, {
            children: "Recipients"
          }), " tab for a marketing email in your HubSpot\naccount"]
        }), ",\nwhich filters out certain raw event types and limits the total events shown to\n30 events per event type."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Submission events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When an email message is created and sent by HubSpot on behalf of a customer, we first verify whether the recipient is eligible to receive it. If not, we reject the message, triggering the creation of a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " event. Otherwise, we submit it to our delivery provider for further handling, triggering a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " event. An email message will almost always have exactly one submission event associated with it; for example, there will never be multiple ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " events for a message."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["We make every effort to reject messages before passing them along to our delivery provider. However, sometimes our delivery provider will decide to reject a message even after we have verified its eligibility. This follow-on rejection results in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " event being created, in addition to the previously-created ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " event."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Submission events all share the following properties:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "subject"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The subject line of the email message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "from"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The 'from' field of the email message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "replyTo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "list of strings"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The 'reply-to' field of the email message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "cc"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "list of strings"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The 'cc' field of the email message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "bcc"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "list of strings"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The 'bcc' field of the email message."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Additionally, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " events have the following properties:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "dropReason"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string (enumeration)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The reason why the email message was dropped. See below for the possible values."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "dropMessage"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The raw message describing why the email message was dropped. This will usually provide additional details beyond ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropReason'"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Drop reasons"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Drop reason"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_BOUNCED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A previous message to the recipient resulted in a bounce."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUS_SPAM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A previous message to the recipient was flagged as spam."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_UNSUBSCRIBED_MESSAGE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient previously unsubscribed from this subscription."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PREVIOUSLY_UNSUBSCRIBED_PORTAL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient previously unsubscribed from all subscriptions from the account."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "INVALID_TO_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email address in the 'to' field failed validation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "INVALID_FROM_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email address in the 'from' field failed validation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BLOCKED_DOMAIN"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient's domain was blocked."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "BLOCKED_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient explicitly requested to not receive any emails via HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "EMAIL_UNCONFIRMED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Double opt-in was enabled and the recipient had not yet confirmed their subscription."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CAMPAIGN_CANCELLED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The associated email campaign was canceled."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MTA_IGNORE"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Our delivery provider decided to drop the message. Any additional details will be included in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropMessage'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PORTAL_OVER_LIMIT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Your account went over its monthly limit for email sends."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "PORTAL_SUSPENDED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Your account was suspended for non-compliance or deliverability issues."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "QUARANTINED_ADDRESS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient has been quarantined due to repeated hard bounce rates."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ADDRESS_LIST_BOMBED"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [" The recipient has been quarantined due to suspicious form activity. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/email/remediate-list-bombing",
              children: "Learn more"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Delivery events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once our delivery provider has accepted an email message, we create a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PROCESSED"
      }), " event. At this point, the delivery provider has queued the message for delivery. If everything goes smoothly, the delivery provider will dequeue the message and deliver it to the recipient's email server, generating a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELIVERED"
      }), " event."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Occasionally, things don't go smoothly, and one of two things happens: delivery is deferred because of a temporary rejection, or delivery fails and won't be retried."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the first case, the message could not be delivered to the recipient's email server for some non-fatal (usually transient reason, such as a spurious time-out. The delivery provider will re-queue the message for later delivery, and we create a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DEFERRED"
      }), " event. A message can be deferred multiple times before it completes the delivery phase, with a new event created on each attempt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If delivery fails, no further attempts will be made to deliver the message, and we create a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), " event. This can occur for a variety of reasons, such as the recipient being unknown by the email server."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The specific delivery event types have the following properties:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Delivered"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The full response from the recipient's email server."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "smtpId"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An ID attached to the message by HubSpot."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Deferred"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The full response from the recipient's email server."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "attempt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "32-bit integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The delivery attempt number."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Bounce"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "category"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string (enumeration)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The best-guess of the type of bounce encountered. If an appropriate category couldn't be determined, this property is omitted. See below for the possible values. Note that this is a derived value, and may be modified at any time to improve the accuracy of classification."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "response"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The full response from the recipient's email server."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "status"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The status code returned from the recipient's email server."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Bounce categories"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Bounce category"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UNKNOWN_USER"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient didn't exist."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MAILBOX_FULL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient's mailbox was full and couldn't receive any messages."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CONTENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient's filters identified content in the body of the email as suspicious or spammy."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SPAM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The message was flagged as spam."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "POLICY"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The message was flagged as spam."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "GREYLISTING"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email server requires a longer history of email activity from the sender."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "MAILBOX_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A mailbox misconfiguration was detected."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ISP_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An ISP misconfiguration was detected."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DOMAIN_REPUTATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The sending domain has a poor reputation or a reputation that doesn't meet the standards of the recipient server."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DMARC"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The sender’s domain does not pass a DMARC check. Please review your SPF and DMARC policies."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SENDING_DOMAIN_MISCONFIGURATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Domain authentication failed due to a policy on the recipient's end."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "TIMEOUT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The receiving email server timed out and is no longer accepting email."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "THROTTLED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient's email server was throttling messages."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "UNCATEGORIZED"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An uncategorized error was detected."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "IP_REPUTATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The message originated from a suspicious (or previously unknown) IP address."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "DNS_FAILURE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient’s domain name server settings were misconfigured at the time the email was sent."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "TEMPORARY_PROBLEM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Some temporary problem occurred."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "User engagement events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once an email message reaches its recipient, there are four different event types that can occur: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PRINT"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORWARD"
      }), ". These represent the recipient's interaction with the message and its content, and each can occur multiple times. For example, each time any URL is clicked, a new ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), " event is created, even if that URL has previously been clicked and generated such an event."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "User engagement events all share the following properties:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "userAgent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The user agent responsible for the event, e.g. “Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/28.0.1500.95 Safari/537.36”"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "browser"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A JSON object representing the browser which serviced the event. Its comprised of the properties: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'name'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'family'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'producer'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'producer_url'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'type'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'url'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'version'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "location"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A JSON object representing the location where the event occurred. It's comprised of the properties: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'city'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'state'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'country'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "filteredEvent"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A boolean representing whether the event has been filtered out of reporting based on customer reports settings or not."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Additionally, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CLICK"
      }), " events have the following properties:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "url"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The URL within the message that the recipient clicked."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "referer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The URL of the webpage that linked to the URL clicked. Whether this is provided, and what its value is, is determined by the recipient's email client."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["And ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "OPEN"
      }), " events may have the following property:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "duration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "64-bit integer (milliseconds)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "If provided and nonzero, the approximate number of milliseconds the user had opened the email."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "User status events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A recipient can also update their communication preferences via the email message. By clicking on the subscription preferences link in the message, they can change their subscriptions, either subscribing or unsubscribing from various lists, triggering a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STATUSCHANGE"
      }), " event. Note that a status change can be for any list(s), not just the one which is associated with the current email message."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["An email message may also be flagged as spam by the recipient, resulting in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SPAMREPORT"
      }), " event. Note that this is independent of subscription status — flagging a message as spam does not simply unsubscribe the recipient from the list in question. Rather, the subscription status is left unchanged, and a flag is set indicating that recipient should never receive another email message from HubSpot. Once this happens, you'll need manual intervention by HubSpot to remove the flag."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STATUSCHANGE"
      }), " event has the following additional properties:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "source"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string (enumeration)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The source of the subscription change. See below for the possible values."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "requestedBy"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email address of the person requesting the change on behalf of the recipient. If not applicable, this property is omitted."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "portalSubscriptionStatus"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["string (enumeration, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'SUBSCRIBED'"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ")"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The recipient's portal subscription status. (Note that if this is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ", the property ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'subscriptions'"
            }), " is ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "not"
            }), " necessarily an empty array, nor are all subscriptions contained in it necessarily going to have their statuses set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'UNSUBSCRIBED'"
            }), ".)"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "subscriptions"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "JSON"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array of JSON objects representing the status of subscriptions for the recipient. Each JSON subscription object is comprised of the properties: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'id'"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'status'"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "bounced"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["boolean (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", or omitted entirely)"]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A HubSpot employee explicitly initiated the status change to block messages to the recipient. (Note this usage has been deprecated in favor of dropping messages with a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropReason'"
            }), " of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOCKED\\_ADDRESS"
            }), ".)"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Subscription change sources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Source"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_LIST_UNSUBSCRIBE"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient used a list-unsubscribe header."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_RECIPIENT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The recipient used the subscription UI."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_IMPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The customer imported the subscriptions into their portal."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT_CUSTOMER"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The customer used the subscription UI."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_SPAM_REPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A spam report generated by an automated system was received."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_NON_DELIVERY_REPORT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A non-delivery report (typically a bounce) was received."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_DIRECT_COMPLAINT"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A direct complaint via ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "abuse@hubspot.com"
            }), " was received."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_MTA_RECORD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Our delivery provider provided the change, during our normal synchronization with their system-of-record."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A HubSpot employee made the change."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_MIGRATION"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpot migrated the subscriptions from a previous version of the product."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_HUBSPOT_CLEANUP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpot cleaned up the subscriptions."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "SOURCE_KNOWN_SPAM_TRAP"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This recipient address is a known spam trap, and should not receive emails."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'Unbounce' events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There is a 13th event type, which is unrelated to a specific email message. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "UNBOUNCE"
      }), " events occur when a particular email address is either automatically or manually ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "unbounced"
      }), " by HubSpot. This resets the bounce status of the recipient, potentially allowing them to receive emails from your portal."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Property name"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "user"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The email address of the user who submitted the unbounce request."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Event references"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Many events are related to other events that occurred either before or after it. As described in the first section above, we use EventIds to build this reference chain."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Note that event references are relatively new, and older events may not have them populated."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'sentBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As discussed previously, each email message has either a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " event (or one of each) associated with it. This will be the first event generated for any given message. If a message generates a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " event, all subsequently generated events will reference that event via the property ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'sentBy'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This backward-reference can be useful to get more information on the parent ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " event, or to manually find all events associated with a given message."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'obsoletedBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sometimes, a follow-on event occurs for a given message, signifying that an earlier event should be ignored. This relationship is captured in a forward-reference in the property ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For instance, in the case where we generate both a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " event and a subsequent ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " event, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " event is ultimately irrelevant, and is ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "obsoleted by"
      }), " the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " event. Accordingly, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SENT"
      }), " event will reference the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " event via ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'obsoletedBy'"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "'causedBy'"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Certain events occur precisely because of some previous event, often for a different message. This relationship is captured in a backward-reference in the property ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), ". It can be used to get additional details on why a particular event caused the following event."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " event will occur when there was a previous ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), " event for the same recipient. In this case, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DROPPED"
      }), " event will have its ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'dropReason'"
      }), " set to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PREVIOUSLY\\_BOUNCED"
      }), ", and it's ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "'causedBy'"
      }), " will reference that previous ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BOUNCE"
      }), " event."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}