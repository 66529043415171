"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125389;
const slug = exports.slug = 'reference/cms/modules/configuration';
const title = exports.title = 'Configurar un módulo';
const name = exports.name = 'LATAM (ES) Configuring a Module';
const metaDescription = exports.metaDescription = 'Más información sobre cómo configurar un módulo personalizado.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "adici%C3%B3n-de-un-icono",
  "label": "Adición de un icono",
  "parent": null
}, {
  "depth": 0,
  "id": "cambio-de-la-etiqueta",
  "label": "Cambio de la etiqueta",
  "parent": null
}, {
  "depth": 0,
  "id": "hacer-global-un-m%C3%B3dulo",
  "label": "Hacer global un módulo",
  "parent": null
}, {
  "depth": 0,
  "id": "controlar-d%C3%B3nde-se-puede-utilizar-un-m%C3%B3dulo",
  "label": "Controlar dónde se puede utilizar un módulo",
  "parent": null
}, {
  "depth": 0,
  "id": "adici%C3%B3n-de-dependencias-de-css-y-javascript",
  "label": "Adición de dependencias de CSS y JavaScript",
  "parent": null
}, {
  "depth": 0,
  "id": "agregar-categor%C3%ADas-y-etiquetas",
  "label": "Agregar categorías y etiquetas",
  "parent": null
}, {
  "depth": 1,
  "id": "categor%C3%ADas",
  "label": "Categorías",
  "parent": "agregar-categor%C3%ADas-y-etiquetas"
}, {
  "depth": 1,
  "id": "etiquetas",
  "label": "Etiquetas",
  "parent": "agregar-categor%C3%ADas-y-etiquetas"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ol: "ol",
      li: "li",
      strong: "strong",
      pre: "pre",
      em: "em",
      ul: "ul",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configurar un módulo"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "Al crear un módulo, hay una serie de opciones disponibles que influyen en el lugar en el que el módulo es visible, en cómo se identifica y en cómo se edita."
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Cuando se ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/getting-started-with-local-development",
              children: "desarrollan localmente"
            }), ", los módulos se almacenan como carpetas con un sufijo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".module"
            }), ". Estas carpetas contienen los archivos que componen la configuración del módulo, y el código utilizado para renderizarlo. La configuración de un módulo se mantiene en el archivo ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#meta-json",
              children: "meta.json"
            }), ", mientras que los campos se configuran por separado en un archivo ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/modules/overview#fields-json",
              children: "fields.json"
            }), "."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["La mayor parte de la configuración también puede modificarse mediante el editor de módulos del ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/design-manager",
              children: "Administrador de diseño"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/module%20structure.png",
            alt: "Estructura de las carpetas de los módulos mostrada localmente"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En un nivel alto, configuras las opciones del módulo localmente dentro del archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), ", que puede incluir las siguientes propiedades:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL de una imagen para usar como icono de un módulo."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Etiqueta utilizada cuando los módulos se muestran en los editores de contenido"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Id único para el módulo que es independiente de la ruta."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_available_for_new_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El valor del interruptor en la esquina superior derecha del editor de módulos en HubSpot. Determina si el módulo puede ser utilizado en el contenido."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "global"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indica si el módulo es global o no"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "host_template_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview",
              children: "tipos de contenido"
            }), " en los que se puede utilizar el módulo. Uno o más de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ \"PAGE\", \"BLOG_POST\", \"BLOG_LISTING\", \"EMAIL\" ]"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css_assets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " de archivos CSS de los que depende el módulo. Admite rutas relativas.por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"css_assets\": [{\"path\": \"../path/to/file.css\" }]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css_render_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objetos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Establece si el módulo CSS se renderiza de forma asíncrona con ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "async"
            }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"async\": false}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "js_assets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " de archivos JavaScript de los que depende el módulo. Admite rutas relativas.por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"js_assets\": [{ \"path\": \"../path/to/file.js\" }]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "js_render_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objetos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Modifica la etiqueta JavaScript del módulo agregado a la página renderizada. Las opciones incluyen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "position"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "head"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "footer"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "async"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "defer"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "type"
                }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "string"
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"position\":\"footer\"}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inline_help_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Texto de ayuda que se mostrará en la parte superior del módulo en un cuadro de información azul (límite de 300 caracteres).Proporciona la información necesaria para utilizar el módulo. Si tienes información de texto de ayuda específica del campo para transmitir, consulta la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#properties-used-by-all-fields",
              children: "documentación del campo de texto de ayuda"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "master_language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Con ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/design-manager/create-translations-of-your-modules#:~:text=To%20create%20translations%20of%20a,the%20right%2C%20click%20Add%20translations.",
              children: "traducciones activadas"
            }), ", el código del idioma en el que se escribieron originalmente los campos del módulo.por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "en"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objetos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Establece el ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/tags/standard-tags#editor-placeholders",
              children: "contenido del marcador de posición"
            }), " para el módulo. Incluye las siguientes propiedades:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "show_module_icon"
                }), ": si se muestra el icono del módulo. ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), ": el título que aparece en el módulo en el editor. String."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), ": la descripción que aparece en el módulo en el editor. String."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "categories"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una matriz que contiene hasta tres ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-categories",
              children: "categorías de módulos"
            }), ".Por ejemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"categories\":[\"FORMS_AND_BUTTONS\"]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_tags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Una matriz de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#adding-categories-and-tags",
              children: "objetos de etiqueta de módulo"
            }), " que contiene el nombre de la etiqueta y la ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fuente"
            }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"USUARIO\""
            }), ".Por ejemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"content_tags\": [{"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\": \"BOTONES\","
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"source\" : \"USER\"``}]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, obtén más información sobre las opciones de configuración de módulos individuales."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adición de un icono"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los módulos pueden incluir un icono que aparece en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "Administrador de diseño"
      }), " y en los editores de páginas y correos electrónicos para proporcionar un contexto visual a los creadores de contenido. Se recomienda tener diferentes iconos para los diferentes tipos de módulos en tu tema. Los iconos son ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "necesarios para los proveedores del mercado"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Hay dos maneras de agregar un icono, a través del ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-an-icon-using-the-design-manager",
        children: "administrador de diseño"
      }), " o de la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-an-icon-using-the-cli",
        children: "CLI del CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los iconos de los módulos deben ser un archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".svg"
      }), " y tener un tamaño no superior a 10kb. Para obtener los mejores resultados, tu icono debe ser sencillo y utilizar un solo color. Los iconos que usen más de un color se convertirán automáticamente para ti. El icono del módulo predeterminado que se muestra es el de una llave inglesa y un pincel."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para agregar un icono mediante el administrador de diseño:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.ol, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Files and Templates"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Design Tools"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Use the left sidebar to locate the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "module"
              }), " you want to edit, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "module name"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In the right sidebar of the module editor, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "icon"
              }), " next to the module title."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Upload/select your icon. After publishing your changes, the icon will appear within the page editors and design manager."
            }), "\n"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-icon-crop.png",
            alt: "edit-module-icon-crop"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para agregar un icono al desarrollar localmente, abre el archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " del módulo y agrega o edita el valor del parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "icon"
      }), " para que sea un SVG desde el administrador de archivos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"global\": false,\n  \"host_template_types\": [\"PAGE\"],\n  \"icon\": \"http://example.com/hubfs/code.svg\",\n  \"label\": \"Code block\",\n  \"smart_type\": \"NOT_SMART\",\n  \"is_available_for_new_content\": true,\n  \"inline_help_text\": \"This module is for code snippets.\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cambio de la etiqueta"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "La etiqueta que se utiliza cuando se muestran los módulos en el editor puede ajustarse por separado del nombre del módulo. Esto es útil cuando se desarrolla localmente, ya que puedes tener un nombre que tenga sentido para ti y a la vez tener uno diferente que vean los creadores de contenido."
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Localmente, cambia el parámetro ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            }), " para establecer la etiqueta. En el administrador de diseño hay un campo en el editor de módulos debajo del nombre del módulo."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-label.png",
            alt: "edit-module-label"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Hacer global un módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para los módulos normales, el contenido de cada instancia de un módulo en una página, correo electrónico o plantilla es independiente. Para algunos casos de uso, es útil poder hacer que todas las instancias de un módulo compartan el mismo contenido. Cuando se desarrolla localmente, se puede hacer que un módulo sea global estableciendo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global"
      }), " como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/use-global-content-across-multiple-templates#create-new-global-content-in-the-design-manager:~:text=To%20convert%20an%20existing%20local%20module%20or%20group",
        children: "convertir módulos en una plantilla de arrastrar y soltar a global"
      }), " utilizando el administrador de diseño."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Controlar dónde se puede utilizar un módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando se desarrolla localmente, se puede controlar en qué tipos de contenido se puede utilizar un módulo a través de la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hostTemplateTypes"
      }), ". Más información sobre los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#template-types",
        children: "tipos de plantillas disponibles"
      }), ". Los módulos también se pueden ocultar para que no se puedan agregar directamente a las páginas mediante la configuración de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "is_available_for_new_content"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), ". Por ejemplo, esto puede ser útil para los módulos creados para los menús de navegación y la búsqueda."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes actualizar esto en el administrador de diseño haciendo clic en la opción ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Tipo de plantilla"
      }), " en la barra lateral derecha."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-template-type.png",
        alt: "edit-module-template-type"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Adición de dependencias de CSS y JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Además de utilizar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " para agregar CSS y JavaScript que se agregarán a todas las páginas que incluyan una instancia de módulo, las dependencias que se comparten entre módulos pueden adjuntarse utilizando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css_assets"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "js_assets"
      }), ". Las rutas pueden ser absolutas o relativas al archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"css_assets\": [{ \"path\": \"../path/to/file.css\" }],\n  \"js_assets\": [{ \"path\": \"../path/to/file.js\" }]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Advertencia:"
        }), " cuando se utilizan rutas relativas para referenciar dependencias, al ejecutar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch --overwrite"
        }), " para actualizar el módulo para el desarrollo local se sobrescribirán las rutas relativas con rutas absolutas."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con el administrador de diseño, puedes vincular archivos CSS y JavaScript a un módulo utilizando la sección ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Archivos vinculados"
      }), " en la barra lateral derecha del editor de módulos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-linked-files.png",
        alt: "edit-module-linked-files"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Agregar categorías y etiquetas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes asignar categorías y etiquetas a los módulos para organizarlos dentro de las herramientas de HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#categories",
          children: "Categorías"
        }), ": asigna categorías a módulos para organizarlos en grupos dentro de la interfaz de usuario del editor de contenido. Esto les permite a los creadores de contenido encontrar módulos más fácilmente mientras crean contenido en HubSpot. Ten en cuenta lo siguiente sobre las categorías:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Un módulo puede tener hasta tres categorías, que están predefinidas y no se pueden personalizar."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Actualmente, las categorías no aparecen en la interfaz de usuario del editor de contenido. Sin embargo, puedes asignar categorías para cuando la categorización esté disponible en los editores."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Los módulos sin categorizar estarán disponibles en una categoría ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Todos"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#tags",
          children: "Etiquetas"
        }), ": asigna etiquetas para organizar módulos dentro del administrador de diseño. Esto te permite encontrar módulos más fácilmente mientras creas plantillas."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el administrador de diseño, puedes asignar categorías y etiquetas utilizando la sección ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Filtrar etiquetas"
      }), " de la barra lateral derecha en el editor de módulos. Obtén más información sobre las categorías y etiquetas disponibles a continuación."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-filter-tags.png",
        alt: "edit-module-filter-tags"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A nivel local, puedes agregar categorías y etiquetas al archivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " de un módulo de la siguiente manera:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"label\": \"Custom module\",\n  \"categories\": [\"image_and_video\", \"commerce\"],\n  \"content_tags\": [\n    {\n      \"name\": \"BUTTONS\",\n      \"source\": \"USER\"\n    },\n    {\n      \"name\": \"CTA\",\n      \"source\": \"USER\"\n    }\n  ],\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Categorías"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La matriz de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "categorías"
      }), " de un módulo puede contener hasta tres de las siguientes categorías (no distingue entre mayúsculas y minúsculas):"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Categoría"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos específicos del blog, como una publicación reciente."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos que están formateados para mostrar gráficamente el contenido, como una galería de imágenes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos específicos de comercio, como tarjetas de precios."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "design"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos que afectan la estructura y el diseño del contenido, como los acordeones."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "functionality"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos que incluyen respuestas dinámicas o comportamiento en la página, como menús."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms_and_buttons"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos que les permiten a los visitantes del sitio introducir y enviar datos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos que contienen elementos como imágenes, iconos, video y banners."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos específicos para redes sociales, como compartir en redes sociales."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Módulos que contienen solo texto."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Etiquetas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La matriz ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content_tags"
      }), " de un módulo puede contener cualquiera de los siguientes objetos de etiqueta de módulo (distingue entre mayúsculas y minúsculas):"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Tipos de contenido:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ACCORDION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ANIMATION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOG_POST"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BUTTONS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CODE"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CTA"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FEED"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FORM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ICON"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "IMAGE"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LISTS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LOGO"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MENU"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "RICH_TEXT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SLIDER"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "TEXT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "VIDEO"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Funciones:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BANNER"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOG"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BRANDING"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CALCULATOR"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CONVERSION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "EMAIL"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "GALLERY"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "HEADERS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "INTERACTION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LAYOUT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MAP"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MULTIMEDIA"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "NAVIGATION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "PROGRESS_BAR"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SEARCH"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SETTINGS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SOCIAL"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "TRANSLATION"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}