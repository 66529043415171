"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611098;
const slug = exports.slug = 'guides/cms/content/data-driven-content/dynamic-pages/hubdb';
const title = exports.title = 'Crie páginas dinâmicas usando HubDB';
const name = exports.name = 'Crie páginas dinâmicas usando HubDB';
const metaDescription = exports.metaDescription = 'Saiba como criar páginas dinâmicas do site usando dados do HubDB.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-criar-uma-tabela-hubdb",
  "label": "1. Criar uma tabela HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-crie-um-modelo",
  "label": "2. Crie um modelo",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-criar-a-p%C3%A1gina-din%C3%A2mica",
  "label": "3. Criar a página dinâmica",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-exibir-p%C3%A1gina-ativas",
  "label": "4. Exibir página ativas",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-adicionar-uma-nova-linha-de-tabela",
  "label": "5. Adicionar uma nova linha de tabela",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-exibir-dados-da-p%C3%A1gina-din%C3%A2mica",
  "label": "6. Exibir dados da página dinâmica",
  "parent": null
}, {
  "depth": 0,
  "id": "mais-tutoriais-focados-no-hubdb",
  "label": "Mais tutoriais focados no HubDB",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      h2: "h2",
      code: "code",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Crie páginas dinâmicas usando HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "As páginas dinâmicas"
      }), " são páginas do CMS que obtêm seu conteúdo a partir de uma fonte de dados estruturada. Com base em como você configura sua página dinâmica, o HubSpot extrai os dados da fonte selecionada e cria automaticamente um conjunto de páginas. Isso inclui uma página de listagem que exibe resumos dos dados e páginas individuais para cada entrada da fonte de dados."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Com uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "tabela HubDB"
      }), " como fonte de dados, você pode criar uma página dinâmica que gera uma página para cada linha da tabela. Cada página dinâmica inclui um URL preparado para SEO e oferece análises de dados para as páginas específicas."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Você pode criar até 10 páginas dinâmicas por fonte de dados. Para páginas dinâmicas do HubDB, isso significa que até 10 páginas dinâmicas podem usar a mesma tabela HubDB. O número de linhas na tabela (e, portanto, as páginas secundárias) não é considerado nesse limite. Saiba mais sobre os ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/storage/hubdb/overview#hubdb-technical-limits",
            children: "limites técnicos do HubDB"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["As páginas dinâmicas do HubDB estão limitadas a 50.000 atualizações de índice de ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/content-search",
            children: "pesquisa de conteúdo"
          }), " por conta por dia. Quaisquer atualizações dessas páginas além desse limite não serão refletidas na pesquisa de conteúdo naquele dia."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este tutorial explica como criar um conjunto de páginas dinâmicas usando o HubDB como fonte de dados. Para seguir esse tutorial, será necessário:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Content Hub"
          }), " Professional"]
        }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Algum conhecimento prévio de HTML e CSS."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Saiba mais sobre como criar páginas do CMS baseadas em dados no curso de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "Conteúdo baseado em dados do CMS"
        }), " no HubSpot Academy."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Criar uma tabela HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar uma nova tabela HubDB:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta HubSpot, navegue até ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Conteúdo"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar a tabela"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na caixa de diálogo, insira um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "rótulo"
        }), " para a tabela e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com a tabela criada, você pode defini-la para ser usada para o conteúdo dinâmico da página:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ações"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Gerenciar configurações"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No painel direito, clique para ativar a opção ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ativar criação de páginas dinâmicas usando dados de linha"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se quiser você pode selecionar a metadescrição, a imagem em destaque e a URL canônica das páginas dinâmicas individuais. Se você deixar esses valores vazios, cada página herdará os respectivos valores de sua página principal."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " para uma página usar os valores das colunas de metadescrição, imagem em destaque e URL canônica, ela deve incluir as seguintes variáveis ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "page_meta"
        }), " do HubL em vez de variáveis ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), ":"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.meta_description}}"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.featured_image_URL}}"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{page_meta.canonical_url}}"
          })
        }), "\n"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Por exemplo, os modelos do HubSpot obtêm a metadescrição a partir da tag ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{content.meta_description}}"
        }), " por padrão. Em vez disso, você precisará de usar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{page_meta.meta_description}}"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Salvar"
        }), " para salvar as alterações."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-table-settings-sidebar-save.png",
        alt: "hubdb-table-settings-sidebar-save"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de atualizar as configurações da tabela, as colunas _Título da página _ ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Caminho da página"
      }), " serão adicionadas à tabela."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**Título da página: **o nome desta página como visto na tag de título HTML."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Caminho da página:"
        }), " o último segmento da URL da página dinâmica criada por cada linha na tabela."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A tabela a seguir é um exemplo modelado após uma página \"Sobre nós\" para membros de uma equipe executiva da empresa. Esta tabela será usada para criar páginas dinâmicas com caminhos que terminam em ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cfo-harlow"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ceo-jeff"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "cto-bristow"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "pd-hugo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Título da página"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Caminho da página"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Função"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Biografia"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CFO Harlow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cfo-harlow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CFO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Harlow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Este é Harlow, que geralmente é cauteloso com os gastos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CEO Jeff"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ceo-jeff"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CEO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Jeff"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Jeff é o Diretor Executivo (CEO), o que significa que ele geralmente comanda as coisas por aqui."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTO Bristow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cto-bristow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CTO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bristow"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Este é o nosso Diretor de Tecnologia (CTO), Bristow, que gosta de experimentar."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CPD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pd-hugo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CPD"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hugo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hugo, nosso Diretor de Designer de Produtos (CPD), gosta de projetar produtos."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-hubdb-table.png",
        alt: "example-hubdb-table"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " embora você precise inserir caminhos de página em minúsculas, as URLs resultantes não diferenciam maiúsculas de minúsculas. No exemplo acima, quando alguém acessa ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/CEO-Jeff"
        }), ", vê a mesma página como em ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/ceo-jeff"
        }), " em vez de um erro 404."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando estiver pronto para usar os dados da tabela para criar suas páginas, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Publicar"
      }), " no canto superior direito."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Crie um modelo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Em seguida, você criará um modelo para a página de listagem e para as páginas de detalhes individuais para cada linha, seguindo uma abordagem semelhante à utilização de modelos de blog para páginas de listagem e de detalhes de posts. Para criar o modelo de página:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, vá para ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Conteúdo"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Gerenciador de design"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No menu da barra lateral esquerda, vá para a pasta na qual deseja criar o modelo. Para criar uma nova pasta, no canto superior esquerdo, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Arquivo"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nova pasta"
        }), ". Em seguida, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Arquivo"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Novo arquivo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na caixa de diálogo, use o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "menu suspenso"
        }), " para selecionar **HTML + HubL **como o tipo de arquivo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Próximo"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/create-html-hubl-template.jpg",
        alt: "Criar um novo modelo HTML + HubL"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No campo ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nome do arquivo"
        }), ", insira o nome do modelo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Em ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Localização do arquivo"
        }), ", você pode alterar onde o modelo está localizado em seu gerenciador de design clicando em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Alterar"
        }), (0, _jsxRuntime.jsx)(_components.em, {
          children: "."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar"
        }), " para criar o modelo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando uma página dinâmica é definida para usar este modelo e o final do URL da página corresponde à coluna de caminho, você pode acessar as variáveis ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_table_id"
      }), " no modelo. Por exemplo, para construir uma página de perfil executivo, o código abaixo demonstra como você pode usar os campos de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dynamic_page_hubdb_row"
      }), " para exibir as informações de um executivo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_name"
        }), ": o ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "título da página"
        }), " associada para a linha HubDB."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "name"
        }), ": o nome do executivo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "role"
        }), ": a função do executivo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n    <h1>{{ dynamic_page_hubdb_row.hs_name }}</h1>\n    <h2>{{ dynamic_page_hubdb_row.name }}</h2>\n    <h3>{{ dynamic_page_hubdb_row.role }}</h3>\n    <p>{{dynamic_page_hubdb_row.bio}}</p>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Em seguida, você pode adicionar tratamento para o caso em que alguém carrega sua página dinâmica, sem caminhos adicionais de sua tabela. Normalmente, isso é usado como uma página de listagem, para listar links para as páginas das linhas na tabela do HubDB. Substitua seu código por:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if dynamic_page_hubdb_row %}\n    <h1>{{ dynamic_page_hubdb_row.hs_name }}</h1>\n    <h2>{{ dynamic_page_hubdb_row.name }}</h2>\n    <h3>{{ dynamic_page_hubdb_row.role }}</h3>\n    <p>{{dynamic_page_hubdb_row.bio}}</p>\n{% elif dynamic_page_hubdb_table_id %}\n    <ul>\n    {% for row in hubdb_table_rows(dynamic_page_hubdb_table_id) %}\n        <li><a href=\"{{ request.path }}/{{ row.hs_path }}\">{{ row.hs_name }}</a></li>\n    {% endfor %}\n    </ul>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O código dentro do bloco ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), " itera todas as linhas na tabela do executivo e exibe cada entrada em uma lista, com um link para seu caminho exclusivo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No gerenciador de design, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visualizar"
        }), " para visualizar o modelo. A visualização estará em branco, pois depende do contexto da página para definir as variáveis ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_row"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dynamic_page_hubdb_table_id"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Para testar seu código no nível do modelo, adicione o seguinte código temporário à parte superior do modelo, certificando-se de removê-lo antes de publicar:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set dynamic_page_hubdb_table_id = <YOUR_HUBDB_TABLE_ID> %}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Depois de adicionar o código acima, o modelo deverá renderizar uma lista de hiperlinks, extraindo dados da tabela HubDB que você criou."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-template-preview.png",
        alt: "hubdb-template-preview"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Depois de visualizar o modelo, remova o código temporário acima. Em seguida, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publicar"
        }), ", no canto superior direito, para disponibilizá-lo para a criação de páginas."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Criar a página dinâmica"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar uma página dinâmica a partir de seu modelo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Com seu novo modelo aberto no gerenciador de design, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Ações"
          }), ", no canto superior do localizador, e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Criar página"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/create-page-from-design-manager.png",
            alt: "create-page-from-design-manager"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na caixa de diálogo, selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Página do site"
          }), " e insira o ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome da página"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Criar página"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Configurações"
          }), ", na parte superior do editor de páginas."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No campo ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Título da página"
          }), ", insira um nome de página, que poderá ser usado posteriormente para pesquisar análises de tráfego."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No campo ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), ", insira um ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), "URL de ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/executives"
          }), ". O URL será o URL base para a sua página dinâmica."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Opções avançadas"
          }), " para expandir as configurações adicionais."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Role a tela para baixo até a seção ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Páginas dinâmicas"
          }), " e clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Fontes de dados"
          }), ". Selecione a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "tabela do HubDB"
          }), " que você criou."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/page%20editor%20hubdb%20dynamic%20.png",
        alt: "Opções avançadas nas configurações de página para vincular à tabela HubDB"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Quando terminar, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publicar"
        }), " no canto superior direito. As páginas já estão prontas para serem exibidas."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Exibir página ativas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Agora você pode visitar a nova página dinâmica e todos os seus caminhos, conforme definido pela tabela HubDB."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Acesse à página de listagem dinâmica no URL que definiu no editor de página. Este tutorial usa ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/executives"
        }), " para o seu URL de página dinâmica, portanto, neste caso, o acesso seria: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://www.yourdomain.com/executives"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na página de listagem, clique nos ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nomes"
        }), " na lista de itens para exibir a página de detalhes desse executivo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/2022-11-28_15-55-47%20(1).gif",
        alt: "2022-11-28_15-55-47 (1)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Adicionar uma nova linha de tabela"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com sua página dinâmica carregando dados do HubDB, volte para a tabela e adicione uma nova linha. Depois de publicar a tabela, você verá sua página ativa ser atualizada dinamicamente com os novos dados do HubDB."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, vá para ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Arquivos e modelos"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " da tabela que você criar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Adicionar linha"
        }), " e preencha cada coluna. Veja abaixo um exemplo de conjunto de dados."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Título da página"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Caminho da página"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Função"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Nome"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Biografia"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CMO Hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "cmo-hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CMO"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hobbes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hobbes é o nosso amante de gatos."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Publicar"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Em outra guia, volte para a página de listagem (neste exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/executives"
        }), ". Agora, o novo executivo deve ser exibido na página de listagem e clicar no nome dele revelará a página de detalhes."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Exibir dados da página dinâmica"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando houver visitas à sua página dinâmica, você poderá ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/analyze-performance-for-individual-pages-and-blog-posts",
        children: "medir o desempenho individual da página "
      }), "ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-your-site-traffic-with-the-traffic-analytics-tool",
        children: "visualizar todos os dados da página na ferramenta de análise de tráfego"
      }), ". Mesmo que as páginas de executivos individuais sejam criadas da mesma página dinâmica, os dados de tráfego, como visualizações de página, serão atribuídos a cada página."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para exibir seus dados de visita de página no HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na sua conta da HubSpot, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Relatórios"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Análises de marketing"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na barra lateral esquerda, navegue até ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Análise de tráfego da web"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Páginas"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Exiba a tabela para ver os dados de tráfego para as páginas principal e secundária individuais. As páginas secundárias serão indicadas com ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "> ícones de seta"
          }), " para mostrar sua relação com as páginas principais."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-traffic-data-2.png",
            alt: "example-traffic-data-2"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lembre-se do seguinte caso não veja os dados de tráfego esperados:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se você ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/exclude-traffic-from-your-site-analytics",
          children: "excluiu seus endereços IP nas configurações de relatório da conta"
        }), ", verifique se está acessando suas páginas de fora da rede para que as visualizações da página sejam registradas."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pode levar", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/how-often-do-analytics-in-hubspot-update",
          children: " até 40 minutos"
        }), " para que os novos dados da página sejam exibidos no HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mais tutoriais focados no HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Como unir várias tabelas HubDB"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "Como criar uma página dinâmica de membro da equipe com o HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "Como adicionar vídeos a páginas dinâmicas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "Como criar páginas dinâmicas multiníveis usando o HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "Vamos construir uma página com um mapa usando HubDB"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}