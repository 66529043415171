"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 77668268365;
const slug = exports.slug = 'guides/cms/setup/js-frameworks';
const title = exports.title = 'Como usar estruturas JavaScript no HubSpot';
const name = exports.name = 'LATAM BR (PT) How to use JavaScript frameworks on HubSpot';
const metaDescription = exports.metaDescription = 'Com o HubSpot CMS, você pode criar apps da Web avançados baseados em JS. Existem boilerplates disponíveis para facilitar a instalação e a execução. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "qual-camada-do-hubspot-cms-%C3%A9-necess%C3%A1ria%3F",
  "label": "Qual camada do HubSpot CMS é necessária?",
  "parent": null
}, {
  "depth": 0,
  "id": "estruturas-e-bibliotecas",
  "label": "Estruturas e bibliotecas",
  "parent": null
}, {
  "depth": 0,
  "id": "o-que-deve-saber",
  "label": "O que deve saber",
  "parent": null
}, {
  "depth": 1,
  "id": "coisas-a-considerar-ao-criar",
  "label": "Coisas a considerar ao criar",
  "parent": "o-que-deve-saber"
}, {
  "depth": 2,
  "id": "renderiza%C3%A7%C3%A3o-do-lado-do-servidor-e-renderiza%C3%A7%C3%A3o-do-lado-do-cliente",
  "label": "Renderização do lado do servidor e renderização do lado do cliente",
  "parent": "o-que-deve-saber"
}, {
  "depth": 2,
  "id": "an%C3%A1lise-de-app-de-p%C3%A1gina-%C3%BAnica-spa",
  "label": "Análise de app de página única (SPA)",
  "parent": "o-que-deve-saber"
}, {
  "depth": 2,
  "id": "cria%C3%A7%C3%A3o-de-app-utilizando-m%C3%B3dulos-hubspot",
  "label": "Criação de app utilizando módulos HubSpot",
  "parent": "o-que-deve-saber"
}, {
  "depth": 2,
  "id": "cria%C3%A7%C3%A3o-de-um-modelo-completo",
  "label": "Criação de um modelo completo",
  "parent": "o-que-deve-saber"
}, {
  "depth": 2,
  "id": "delimitadores",
  "label": "Delimitadores",
  "parent": "o-que-deve-saber"
}, {
  "depth": 0,
  "id": "vuejs",
  "label": "VueJS",
  "parent": null
}, {
  "depth": 1,
  "id": "sem-uma-etapa-de-cria%C3%A7%C3%A3o",
  "label": "Sem uma etapa de criação",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "adicione-a-biblioteca-vue-ao-seu-m%C3%B3dulo",
  "label": "Adicione a biblioteca Vue ao seu módulo",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "adicionar-o-c%C3%B3digo-html",
  "label": "Adicionar o código HTML",
  "parent": "vuejs"
}, {
  "depth": 2,
  "id": "adicione-seu-c%C3%B3digo-javascript",
  "label": "Adicione seu código JavaScript",
  "parent": "vuejs"
}, {
  "depth": 1,
  "id": "com-uma-etapa-de-cria%C3%A7%C3%A3o",
  "label": "Com uma etapa de criação",
  "parent": "vuejs"
}, {
  "depth": 1,
  "id": "trabalho-com-formul%C3%A1rios-e-ctas-da-hubspot-nos-componentes-vue",
  "label": "Trabalho com formulários e CTAs da HubSpot nos componentes Vue",
  "parent": "vuejs"
}, {
  "depth": 0,
  "id": "reactjs",
  "label": "ReactJS",
  "parent": null
}, {
  "depth": 0,
  "id": "outras-bibliotecas-javascript",
  "label": "Outras bibliotecas JavaScript",
  "parent": null
}, {
  "depth": 1,
  "id": "usar-require_js-em-vez-de-tags-de-script",
  "label": "Usar require_js em vez de tags de script",
  "parent": "outras-bibliotecas-javascript"
}, {
  "depth": 0,
  "id": "tutoriais-e-guias-recomendados",
  "label": "Tutoriais e guias recomendados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      em: "em",
      h3: "h3",
      h4: "h4",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Como usar estruturas e bibliotecas JavaScript no HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com o HubSpot CMS, você pode criar apps da Web baseados em JavaScript."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Qual camada do HubSpot CMS é necessária?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Caso o site exija um código do lado do servidor ou um mecanismo de assinatura de conteúdo, você pode utilizar as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/features/serverless-functions",
        children: "funções sem servidor"
      }), " e o recurso de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/data/memberships",
        children: "assinatura de conteúdo"
      }), " do HubSpot se tiver uma assinatura do ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ". No entanto, você pode criar seu próprio sistema usando provedores de terceiros, como o AWS Lambda, combinado com um gateway de API, para executar o código do lado do servidor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estiver criando um aplicativo da Web que precise atingir enpoints de API que exijam autenticação, como um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "token de acesso a app privado"
      }), ", não execute esse código no navegador. Você estaria expondo suas credenciais a qualquer pessoa que visualiza a página. A abordagem correta é criar uma camada de abstração entre o navegador e a API autenticada: um ponto de extremidade de API personalizado que não exige a exposição de suas credenciais e é atendido do mesmo domínio que o site que o chama."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Acionar o ponto de extremidade de API personalizado executará o código do lado do servidor que pode fazer a solicitação autenticada. Em seguida, você pode fazer qualquer formatação dos dados ou lógica de negócio que deseja manter em segredo e enviar o resultado para o navegador."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Geralmente, as funções sem servidor são usadas para fazer isso, porque têm escalabilidade incrível e não exigem gerenciamento e manutenção do seu próprio servidor. Você pode usar provedores, como o AWS Lambda, combinados a um gateway de API ou usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "as funções sem servidor primárias"
      }), " da HubSpot. A vantagem das funções sem servidor da HubSpot é que você não precisa gerenciar vários serviços separados. A experiência é simplificada e diretamente integrada com o mesmo sistema de arquivos do desenvolvedor que possui todos os temas, modelos e módulos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você não precisa fazer chamadas de API autenticadas, então, você não precisa de empresa para o seu app. React e Vue são estruturas front-end que não precisam de funções sem servidor para funcionar, é o que você faz com elas que importa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Estruturas e bibliotecas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para aplicações Web, os desenvolvedores geralmente utilizam estruturas JavaScript que ajudam a gerir o estado e a interface de usuário (UI)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O CMS Hub não foi desenvolvido especificamente para trabalhar com uma estrutura específica em mente, mas muitos frameworks JavaScript comuns funcionam no HubSpot CMS. Com base na HubSpot, talvez seja necessário pensar em como você trabalha com essas estruturas de forma diferente. Mas o principal para trabalhar com essas estruturas está disponível: a capacidade de escrever ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modelos personalizados"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "módulos"
      }), " e JavaScript. Também é possível fazer a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "codificação localmente"
      }), ", para que possa usar uma etapa de compilação."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "O que deve saber"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Estamos colaborando com nossa comunidade de desenvolvedores para estabelecer as práticas recomendadas para a criação com estruturas JavaScript comuns na HubSpot. Embora seja possível fazer isso, existem aspectos de como o HubSpot CMS funciona que podem exigir que você configure conscientemente seu projeto de forma diferente do que você poderia em uma página HTML simples."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Também pode haver algumas partes do seu fluxo de trabalho de desenvolvimento que são diferentes do que você está acostumado. Pedimos que você dê seu feedback para que possamos melhorar a experiência de todos os desenvolvedores. Atualmente, o melhor lugar para fazer isso é em nosso ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/pt/t5/HubSpot-Developers/ct-p/developers",
        children: "Fórum para desenvolvedores"
      }), ". Nós continuaremos a atualizar nossa documentação à medida que experimentamos e aprendemos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Coisas a considerar ao criar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubSpot CMS tem um eficiente ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "sistema de módulos"
      }), " que permite que você crie partes reutilizáveis de CSS, JavaScript e HTML com acesso ao ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL, a linguagem de modelos da HubSpot"
      }), ". Os módulos HubSpot oferecem uma maneira de fornecer controle e empoderar criadores de conteúdo. Estruturas JavaScript modernas, geralmente, têm seus próprios sistemas de módulos. Todos esses sistemas são criados de forma independente uns dos outros e, como resultado, muitas vezes têm soluções diferentes para os problemas que você pode encontrar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Renderização do lado do servidor e renderização do lado do cliente"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A renderização do lado do servidor é quando o HTML para uma página é gerado por meio da lógica de modelos no servidor antes de enviar qualquer HTML para um navegador."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A renderização do lado do cliente é quando uma versão mais leve ou \"incompleta\" do HTML é enviada do servidor e o JavaScript é usado para gerar o HTML. Isso transfere o processamento da lógica do servidor para o navegador da Web (o cliente)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A hidratação é o ato de combinar ambas as técnicas. Primeiro, é gerado, no servidor, o maior número de HTML possível. Em seguida, o JavaScript avalia o HTML fornecido e faz alterações menores nele conforme necessário quando o usuário interage com a página ou os dados são recebidos. Isso reduz a carga no cliente e, potencialmente, reduz o tempo necessário para o usuário ver o conteúdo carregado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No HubSpot CMS, o HubL é processado no lado do servidor e, em seguida, armazenado em cache no nível CDN. Você pode usar o JavaScript para hidratar ou renderizar no lado do cliente o HTML que o navegador fornece ao visitante do site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Análise de app de página única (SPA)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A análise é importante para a capacidade da sua empresa de crescer e se adaptar para resolver problemas para seus clientes e prospects. Ao criar um app de página única que contém várias visualizações, você pode querer controlar o visitante que vê diferentes visualizações, como páginas."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A maioria das plataformas de análise fornece uma maneira de fazer isso com JavaScript, a HubSpot não é diferente. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/tracking-code#tracking-in-single-page-apps",
        children: "Envie a visualização de página por push"
      }), " quando o app alterar as visualizações."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Criação de app utilizando módulos HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O sistema de módulos da HubSpot é um sistema de módulos do lado do servidor que cria um documento HTML por meio de parciais HubL + HTML e cria CSS e JavaScript minificados para cada módulo em uma página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há vários benefícios na criação com módulos HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os criadores de conteúdo podem adicionar seu módulo a páginas que tenham áreas de arrastar e soltar ou colunas flexíveis. Eles também podem mover e remover o módulo sozinhos."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Você pode fornecer campos ao criador de conteúdo que permitem definir as configurações do app."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Seu código somente é renderizado para a página se o módulo for realmente usado."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "Module.css"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.js"
        }), " são automaticamente minificados."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O custo de usar o sistema de módulo HubSpot é que ele exige que os módulos sejam compostos de arquivos específicos e em lugares diferentes em que você normalmente colocaria seu código."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Criação de um modelo completo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você também pode criar seu aplicativo como um modelo em vez criá-lo dentro da estrutura do módulo. Isso oferece mais flexibilidade para a sua estrutura de arquivos. Mas você não obtém os benefícios que os módulos oferecem. Os criadores de conteúdo não poderão adicionar esse aplicativo a páginas em áreas de arrastar e soltar e colunas flexíveis."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Delimitadores"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Algumas estruturas JavaScript usam chaves ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{ }"
      }), " para delimitar o código. A linguagem HubL também usa essas chaves. Existem três estratégias que podem ser usadas para garantir que não haja conflitos entre sua estrutura e o HubL: você pode usar a tag HubL bruta para envolver seu JSX, definir a estrutura para usar um delimitador diferente ou usar uma etapa de criação que compila o JavaScript de antemão."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "VueJS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A popular estrutura ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/",
        children: "Vue.js"
      }), " pode ser usada com e sem uma etapa de criação. Consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/installation.html",
        children: "própria documentação da Vue"
      }), " para obter uma análise mais detalhada dos prós e contras de cada método. Na HubSpot, há prós e contras específicos que você também deve considerar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sem uma etapa de criação"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "É fácil integrar o Vue.js sem uma etapa de criação em um módulo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Adicione a biblioteca Vue ao seu módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No seu arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ", use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " para adicionar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/installation.html#Direct-lt-script-gt-Include",
        children: "biblioteca Vue"
      }), ", garantindo que ela seja carregada apenas uma vez quando o módulo for adicionado a uma página."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Durante o desenvolvimento, use a compilação de desenvolvimento para obter informações úteis para depuração. Uma vez em produção, é recomendável usar o URL CDN para a versão Vue específica, ou fazer o download esse arquivo e hospedá-lo como um arquivo JavaScript no ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview#developer-file-system",
          children: "sistema de arquivos do desenvolvedor"
        }), " da HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Adicionar o código HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Copie o código HTML da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/index.html#Declarative-Rendering",
        children: "introdução do Vue.js"
      }), " e cole-o no arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), ". Coloque esse código entre tags brutas HubL para evitar que ele seja avaliado como HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# raw prevents code within it from being evaluated as HubL #}\n\n<div id=\"app\">\n  {{ message }}\n</div>\n\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Adicione seu código JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Copie o JavaScript da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://vuejs.org/v2/guide/index.html#Declarative-Rendering",
        children: "introdução do Vue.js"
      }), " e cole-o em seu ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), ". Coloque esse código entre um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Web/API/Window/DOMContentLoaded_event",
        children: "ouvinte de eventos para garantir que ele seja executado assim que o conteúdo DOM terminar de carregar"
      }), ". Publicar seu módulo e visualizá-lo. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Agora, você deve ver o seu app Vue básico funcionando."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var app = new Vue({\n  el: '#app',\n  data: {\n    message: 'Hello Vue!',\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Com uma etapa de criação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Criamos um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate",
        children: "boilerplate"
      }), " [BETA] para ajudá-lo a começar a trabalhar com a abordagem do módulo HubSpot para criar um aplicativo VueJS. A maneira mais fácil de aproveitar isso é executar o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create vue-app"
      }), " com a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI do CMS"
      }), ". As instruções estão no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate/",
        children: "repositório"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este boilerplate é novo e gostaríamos de ouvir o seu feedback! Diga o que pode melhorar e quaisquer problemas que encontrar. A melhor maneira de fornecer feedback é ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-vue-boilerplate/issues",
        children: "enviando problemas ao repositório do GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Trabalho com formulários e CTAs da HubSpot nos componentes Vue"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os CTAs e formulários da HubSpot têm suas próprias tags de script e gerenciam seus próprios HTML. Para garantir que seu componente Vue não modifique o formulário ou o CTA, crie um elemento HTML em torno do código incorporado do CTA/formulário. ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://vuejs.org/v2/api/#v-once",
        children: ["Aplique ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "v-once"
        }), " a esse elemento."]
      }), " Isso garante que o código será renderizado uma vez e, em seguida, ignorado pelo seu componente Vue."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ReactJS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em vez de usar HubL para criar módulos e parciais, você pode usar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/modules/build-modules-and-partials-with-react",
        children: "JavaScript e React"
      }), ". Além de unir componentes React renderizados pelo servidor no HTML gerado pelo HubL, os módulos e parciais JavaScript suportam interatividade do lado do servidor e do cliente. Saiba mais na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.hubspot.com/pt/cms-js-building-block-examples/",
        children: "Introdução aos blocos de criação de JS"
      }), " da HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode conferir o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/pt/cms-react-boilerplate",
        children: "boilerplate React"
      }), " para começar a usar rapidamente com um aplicativo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://reactjs.org/",
        children: "React"
      }), " em um módulo do HubSpot. A maneira mais fácil de aproveitar isso é executar o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create react-app"
      }), " da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CLI do CMS"
      }), ". Então, seguir as instruções no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate",
        children: "repositório"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este boilerplate é novo e gostaríamos de ouvir o seu feedback! Diga o que pode melhorar e quaisquer problemas que encontrar. A melhor maneira de fornecer feedback é ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react-boilerplate/issues",
        children: "enviando problemas ao repositório do GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Outras bibliotecas JavaScript"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Há muitas bibliotecas JavaScript e é impossível documentá-las individualmente. Existem algumas práticas recomendadas essenciais que você deve conhecer e entender ao usar bibliotecas JavaScript na HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usar require_js em vez de tags de script"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode ter dezenas de módulos e modelos que usam bibliotecas JavaScript compartilhadas e não se preocupar em carregar essas bibliotecas várias vezes. Para fazer isso, você precisa usar a função ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        })
      }), " do HubL. Os scripts carregados usando esta função só carregarão uma vez por página, independentemente de quantos módulos, parciais e modelos sejam necessários."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{{ require_js(get_asset_url('/js/jquery-latest.js')) }}\n\n{{ require_js(\"https://cdnjs.cloudflare.com/ajax/libs/d3/6.2.0/d3.min.js\") }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "get_asset_url()"
        }), " para exigir arquivos armazenados no sistema de arquivos do desenvolvedor. A vantagem, além de apenas colocalizar seus arquivos de desenvolvimento e consolidar a segurança desses arquivos, é que isso resultará em menos pesquisas de DNS."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Usar a exigência pode ser incrível para o desempenho, porque você não apenas carregará o arquivo uma vez. Se os ativos de uma página não precisarem dessa biblioteca, ela não será carregada. Você pode até mesmo usar requisitos com a lógica HubL para carregar recursos apenas quando realmente precisar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriais e guias recomendados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/speed",
          children: "Otimização para o desempenho"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "Acessibilidade não é um recurso"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/use-web-components-in-hubspot-cms-development",
          children: "Como usar componentes Web no HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Introdução aos módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/guides/getting-started-with-serverless-functions",
          children: "Introdução às funções sem servidor"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/guides/creating-an-efficient-development-workflow",
          children: "Criar um fluxo de trabalho de desenvolvedor eficaz"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "Criar páginas dinâmicas com o HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/building-blocks/modules/build-modules-and-partials-with-react",
          children: "Criar módulos e parciais com JavaScript"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}