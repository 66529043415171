"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501377944;
const slug = exports.slug = 'reference/api/conversations/chat-widget-sdk';
const title = exports.title = 'Konversationen | Chat-Widget-SDK';
const name = exports.name = 'EMEA DACH (DE) vNext Docs DP - Chat Widget SDK';
const metaDescription = exports.metaDescription = 'Mit dem Live-Chat-Widget von HubSpot können Sie mit Kunden auf Ihrer Website chatten. Mit dem Chat-Widget-SDK können Sie das Verhalten des Chats-Widgets anpassen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "initialisierung",
  "label": "Initialisierung",
  "parent": null
}, {
  "depth": 0,
  "id": "einstellungen-f%C3%BCr-konversationen-konfigurieren",
  "label": "Einstellungen für Konversationen konfigurieren",
  "parent": null
}, {
  "depth": 1,
  "id": "inline-einbettungsstile",
  "label": "Inline-Einbettungsstile",
  "parent": "einstellungen-f%C3%BCr-konversationen-konfigurieren"
}, {
  "depth": 0,
  "id": "widget-behavior",
  "label": "Widget behavior",
  "parent": null
}, {
  "depth": 1,
  "id": "widget.load",
  "label": "widget.load",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.refresh",
  "label": "widget.refresh",
  "parent": "widget-behavior"
}, {
  "depth": 2,
  "id": "beispiel",
  "label": "Beispiel",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.open",
  "label": "widget.open",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.close",
  "label": "widget.close",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.remove",
  "label": "widget.remove",
  "parent": "widget-behavior"
}, {
  "depth": 1,
  "id": "widget.status",
  "label": "widget.status",
  "parent": "widget-behavior"
}, {
  "depth": 0,
  "id": "chat-cookies-l%C3%B6schen",
  "label": "Chat-Cookies löschen",
  "parent": null
}, {
  "depth": 0,
  "id": "chat-events",
  "label": "Chat-Events",
  "parent": null
}, {
  "depth": 1,
  "id": "conversationstarted",
  "label": "conversationStarted",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "conversationclosed",
  "label": "conversationClosed",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "userselectedthread",
  "label": "userSelectedThread",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "unreadconversationcountchanged",
  "label": "unreadConversationCountChanged",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "contactassociated",
  "label": "contactAssociated",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "userinteractedwithwidget",
  "label": "userInteractedWithWidget",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "widgetloaded",
  "label": "widgetLoaded",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "quickreplybuttonclick",
  "label": "quickReplyButtonClick",
  "parent": "chat-events"
}, {
  "depth": 1,
  "id": "widgetclosed",
  "label": "widgetClosed",
  "parent": "chat-events"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      img: "img",
      h4: "h4",
      br: "br",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Konversationen-SDK"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um über das Konversationen-Postfach von HubSpot mit Kunden und Leads auf Ihrer Website zu chatten, können Sie ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/chat-with-your-website-visitors",
        children: "Livechat-Widget"
      }), " einrichten. Mit dem Konversationen-SDK können Sie Besuchern ein individuelleres Erlebnis bieten, indem Sie das Verhalten des Chat-Widgets anpassen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Auf einer allgemeinen Ebene ermöglicht Ihnen das Konversationen-SDK Folgendes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#configure-conversations-settings",
          children: "Chat-Widget-Einstellungen konfigurieren"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-behavior",
          children: "Das Verhalten des Widgets steuern"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#clear-chat-cookies",
          children: "Chat-Cookies löschen"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#chat-events",
          children: "Auf Widget-Events überwachen und darauf reagieren"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-status"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Initialisierung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die API befindet sich im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.HubSpotConversations"
      }), "-Objekt, das Zugriff auf alle verfügbaren Methoden bietet. Das Objekt wird durch den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code",
        children: "HubSpot-Tracking-Code"
      }), " erstellt, ist jedoch möglicherweise beim Laden der Seite nicht sofort verfügbar. Um den Zugriff auf die API zu verzögern, bis sie initialisiert ist, können Sie die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), "Hilfsfunktion verwenden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "window.hsConversationsOnReady"
      }), " ist ein optionales Feld, das Sie im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "window"
      }), "-Objekt definieren können. Damit können Sie Code angeben, der ausgeführt wird, sobald das Widget verfügbar ist. Dieses Feld übernimmt eine Array-Funktion, die ausgeführt wird, sobald die API initialisiert wurde."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<script type=\"text/javascript\">\n  function onConversationsAPIReady() {\n    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);\n  }\n  /*\n    configure window.hsConversationsSettings if needed.\n  */\n  window.hsConversationsSettings = {};\n  /*\n   If external API methods are already available, use them.\n  */\n  if (window.HubSpotConversations) {\n    onConversationsAPIReady();\n  } else {\n    /*\n      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.\n      These callbacks will be called once the external API has been initialized.\n    */\n    window.hsConversationsOnReady = [onConversationsAPIReady];\n  }\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einstellungen für Konversationen konfigurieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsConversationsSettings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieses optionale Objekt ermöglicht es Ihnen, vor dem Initialisieren einige Konfigurationsoptionen für das Widget anzugeben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.hsConversationsSettings = {\n  loadImmediately: false,\n  inlineEmbedSelector: '#some-id',\n  enableWidgetCookieBanner: true,\n  disableAttachment: true,\n};\nwindow.hsConversationsOnReady = [\n  () => {\n    window.HubSpotConversations.widget.load();\n  },\n];\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loadImmediately"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the widget should implicitly load or wait until the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.load"
            }), " method is called."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inlineEmbedSelector"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"\""
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Specify a selector (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "#some-id"
            }), ") to embed the chat widget in a specific location on the page. Widget will be embedded inline within that DOM node and will remain open until it is removed with ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "widget.remove"
            }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#inline-embed-styling",
              children: "styling embedded chat widgets"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableWidgetCookieBanner"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Control behavior of the cookie banner for all chat widgets on the page. Options include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), " (default): uses the ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://knowledge.hubspot.com/chatflows/create-a-live-chat#4-options",
                  children: "chat widget's settings"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ": presents cookie banners when the widget is loaded."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ON_WIDGET_LOAD"
                }), ": same as ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "ON_EXIT_INTENT"
                }), ": enable cookie banners when the user exhibits an exit intent."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disableAttachment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether to hide the upload attachment button in the chat widget."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disableInitialInputFocus"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Whether to automatically prevent focusing on the widget's input field after an inline embedded widget is initially loaded."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "avoidInlineStyles"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", injects a link tag with externally hosted CSS instead of a direct dynamic insertion of a style tag."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Inline-Einbettungsstile"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn das Widget mit ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inlineEmbedSelector"
      }), " an einer bestimmten Stelle eingebettet wird, werden mehrere DOM-Elemente hinzugefügt, die formatiert werden können (z. B. Höhe, Breite, Rahmen)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie beispielsweise das Chat-Widget mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "#some-id"
      }), "-Selektor einbetten, wird es mit den folgenden Containern und IDs geladen:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div id=\"some-id\">\n  <div id=\"hubspot-conversations-inline-parent\">\n    <iframe id=\"hubspot-conversations-inline-iframe\">\n      <!-- rest of iframe content -->\n    </iframe>\n  </div>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "You can then customize the chat widget using those selectors, such as:"
          })
        }), (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "#hubspot-conversations-inline-iframe {\n  width: 300px;\n  height: 500px;\n  border: none;\n}\n"
          })
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://developers.hubspot.com/hs-fs/hubfs/livechat_after.png?width=600&name=livechat_after.png",
              alt: "livechat_after"
            })
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Widget behavior"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "HubSpotConversations.widget"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The widget object contains a number of methods that allow you to manipulate the chat widget on your page, including:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-load",
          children: "widget.load"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-refresh",
          children: "widget.refresh"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-open",
          children: "widget.open"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-close",
          children: "widget.close"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-remove",
          children: "widget.remove"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widget-status",
          children: "widget.status"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about each method."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.load"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.load"
      }), " method handles the initial load on the page. This method is only necessary if you set ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loadImmediately"
      }), " to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), ". Otherwise, the widget will load itself automatically."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This method is throttled to one call per second."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.load();\n\n/* ... */\n\n// Force the widget to load in an open state\nwindow.HubSpotConversations.widget.load({ widgetOpen: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "widgetOpen"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ob das Widget in einem geöffneten Zustand geladen werden soll."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.refresh"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.refresh"
      }), "-Methode übernimmt unter Berücksichtigung der aktuellen Seiten-URL das Aktualisieren und erneute Darstellen der Informationen des Widgets. Diese Methode kann für Chat-Widgets nützlich sein, die in Einzelseitenanwendungen eingebettet sind, wenn Sie das Widget bei Routenänderungen aktualisieren müssen. Mit dieser Methode können Sie auch ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#example",
        children: "verschiedene Chat-Widgets auf verschiedenen Seitenrouten angeben"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.refresh"
      }), " auf einer Route aufrufen, auf der kein Chat-Widget vorhanden ist und der Benutzer keinen Chat führt, wird das Widget entfernt. Das Widget wird ", (0, _jsxRuntime.jsx)("u", {
        children: "nicht"
      }), " entfernt, wenn ein derzeit aktiver Chat vorhanden ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Diese Methode wird auf einen Aufruf pro Sekunde gedrosselt."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.refresh();\n\n/* ... */\n\n// Force the widget to open to a specific chat flow\nwindow.HubSpotConversations.widget.refresh({ openToNewThread: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "openToNewThread"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ob das Erstellen eines neuen Threads erzwungen werden soll."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Beispiel"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit dieser Methode können Sie Schaltflächen und Links zum Öffnen bestimmter Chatflows auf einer Seite erstellen, indem Sie der Seiten-URL Abfrageparameter hinzufügen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/conversations-chat-widget-open-crop.gif",
        alt: "conversations-chat-widget-open-crop"
      }), "Sie könnten beispielsweise den folgenden Code zu Ihren Seiten hinzufügen, um die Schaltflächen zu generieren:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div class=\"chat-buttons\">\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_sales', '?sales_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to sales\n  </button>\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_customer_support', '?cs_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to customer support\n  </button>\n  <button\n    onclick=\"window.history.pushState({}, 'talk_to_the_ceo', '?ceo_chat=true'); window.HubSpotConversations.widget.refresh({openToNewThread: true});\"\n  >\n    Talk to the CEO\n  </button>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In den ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/chatflows/create-a-live-chat#2-target---decide-where-the-live-chat-should-appear",
        children: "Zieleinstellungen"
      }), " jedes Chats legen Sie dann fest, dass der Chat angezeigt wird, wenn der Abfrageparameter mit dem Parameter übereinstimmt, den Sie in Ihrem Schaltflächencode festgelegt haben.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/conversations-target-rule.png",
        alt: "conversations-target-rule"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.open"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.open"
      }), "-Methode öffnet das Widget, wenn es noch nicht geöffnet oder aktuell nicht geladen ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.open();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.close"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.close"
      }), "-Methode schließt das Widget, wenn es noch nicht geschlossen ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.close();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.remove"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.remove"
      }), "-Methode entfernt das Widget von der Seite. Wenn das Widget nicht auf der Seite vorhanden ist, bewirkt diese Methode nichts. Das Widget wird beim Aktualisieren der Seite oder beim Aufrufen von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.load"
      }), " erneut angezeigt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.widget.remove();\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widget.status"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.status"
      }), "-Methode gibt ein Objekt zurück, das Eigenschaften enthält, die sich auf den aktuellen Status des Widgets beziehen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const status = window.HubSpotConversations.widget.status();\n\nif (status.loaded) {\n  window.HubSpotConversations.widget.refresh();\n} else {\n  window.HubSpotConversations.widget.load();\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loaded"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ob der Widget-iframe geladen wurde oder nicht."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Chat-Cookies löschen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "clear"
      }), "-Methode löscht Cookies im Zusammenhang mit dem Chat-Widget und setzt es beim nachfolgenden Laden in seinen Standardzustand zurück."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Chat-Widget erstellt mehrere Cookies, um seinen Zustand über Website-Besuche und Seitenaktualisierungen hinweg beizubehalten. Der Bereich dieser Cookies ist auf die Domain der Seite angepasst, die das Widget hosten, und die Cookies werden zur Unterstützung der folgenden Eigenschaften verwendet:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Verweisen auf historische Konversationen."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Aufrechterhalten des geöffneten Zustands des Chat-Widgets über das Laden von Seiten hinweg."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Aufrechterhalten des geöffneten Zustands der Willkommensnachricht über das Laden von Seiten hinweg."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Cookies werden mit dieser Methode gelöscht:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "messagesUtk"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs-messages-is-open"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs-messages-hide-welcome-message"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Weitere Informationen zu diesen Cookies ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser",
        children: "finden Sie in der Wissensdatenbank von HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.clear();\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können auch ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{resetWidget:true}"
      }), " an die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "clear()"
      }), "-Funktion übergeben, um alle Cookies im Zusammenhang mit Chats zu löschen, das Widget von der Seite zu entfernen und eine neue Instanz des Chat-Widgets zu erstellen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.clear({ resetWidget: true });\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Chat-Events"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Das Chat-Widget gibt verschiedene Events aus, auf die Sie während deren gesamten Lebenszyklus überwachen und reagieren können. Zu diesen Events gehören:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#conversationstarted",
          children: "conversationStarted"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#conversationclosed",
          children: "conversationClosed"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#userselectedthread",
          children: "userSelectedThread"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#unreadconversationcountchanged",
          children: "unreadConversationCountChanged"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#contactassociated",
          children: "contactAssociated"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#userinteractedwithwidget",
          children: "userInteractedWithWidget"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widgetloaded",
          children: "widgetLoaded"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#quickreplybuttonclick",
          children: "quickReplyButtonClick"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#widgetclosed",
          children: "widgetClosed"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Event-Listener zu registrieren und zu entfernen, verwenden Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "on"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "off"
      }), ", wie unten gezeigt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const handleEvent = (eventPayload) => console.log(eventPayload);\n\nwindow.HubSpotConversations.on('conversationStarted', handleEvent);\n\n/* ... */\n\nwindow.HubSpotConversations.off('conversationStarted', handleEvent);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Erfahren Sie unten mehr über die einzelnen Events."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "conversationStarted"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversationStarted"
      }), "-Event wird ausgelöst, wenn eine neue Konversation erfolgreich gestartet wurde."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('conversationStarted', (payload) => {\n  console.log(\n    `Started conversation with id ${payload.conversation.conversationId}`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Thread-ID der gestarteten Konversation. Sie können diese ID verwenden, wenn Sie Aufrufe an die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "Konversationen-API"
            }), " vornehmen."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "conversationClosed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "conversationClosed"
      }), "-Event wird ausgelöst, wenn eine neue Konversation vom Konversationen-Postfach als ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/use-the-conversations-inbox#reply-and-comment:~:text=To%20mark%20the%20conversation%20as%20closed",
        children: "geschlossen markiert wurde"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Website-Besucher, die das Chat-Widget minimieren oder schließen, lösen dieses Event nicht aus. Verwenden Sie für dieses Event stattdessen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#widgetclosed",
        children: "widgetClosed"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('conversationClosed', (payload) => {\n  console.log(\n    `Conversation with id ${payload.conversation.conversationId} has been closed!`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Thread-ID der gestarteten Konversation. Sie können diese ID verwenden, wenn Sie Aufrufe an die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "Konversationen-API"
            }), " vornehmen."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "userSelectedThread"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userSelectedThread"
      }), " -Event wird ausgelöst, wenn ein Thread erstellt oder ein vorhandener Thread ausgewählt wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('userSelectedThread', (payload) => {\n  console.log(\n    `User selected thread with ID ${payload.conversation.conversationId}!`\n  );\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payload.conversation.conversationId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Thread-ID der gestarteten Konversation. Sie können diese ID verwenden, wenn Sie Aufrufe an die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "Konversationen-API"
            }), " vornehmen."]
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "unreadConversationCountChanged"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unreadConversationCountChanged"
      }), "-Event wird ausgelöst, wenn die Anzahl der Konversationen mit ungelesenen Nachrichten zunimmt oder abnimmt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('unreadConversationCountChanged', (payload) => {\n  console.log(`New unread count is ${payload.unreadCount}!`);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unreadCount"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Gesamtzahl der Konversationen mit mindestens einer ungelesenen Nachricht."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "contactAssociated"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contactAssociated"
      }), "-Event wird ausgelöst, wenn der Besucher einem Kontakt im CRM zugeordnet ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('contactAssociated', (payload) => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Bestätigungsnachricht, dass der Besucher mit einem Kontakt verknüpft wurde."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "userInteractedWithWidget"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "userInteractedWithWidget"
      }), "-Event wird ausgelöst, wenn der Besucher mit dem Widget interagiert, z. B. durch Klicken, um das Widget zu öffnen oder die erste Willkommensnachricht zu schließen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on(‘userInteractedWithWidget’, payload => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Bestätigungsnachricht, dass der Besucher mit dem Widget interagiert hat."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widgetLoaded"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgetLoaded"
      }), "-Event wird ausgelöst, wenn der Widget-iframe geladen wird."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on(‘widgetLoaded’, payload => {\n  console.log(payload.message);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Bestätigungsnachricht, dass der Widget-iframe geladen wurde."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "quickReplyButtonClick"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "quickReplyButtonClick"
      }), "-Event wird ausgelöst, wenn der Besucher auf eine ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/chatflows/a-guide-to-bot-actions#ask-a-question",
        children: "Schnellantwort"
      }), " in einer Bot-Konversation klickt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Array, das den Text der Schnellantwortoption enthält, auf die geklickt wurde."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('quickReplyButtonClick', (event) => {\n  console.log(`The text content of the clicked button is ${payload.value[0]}`);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/quick-reply-options-in-bot-conversation.png",
        alt: "quick-reply-options-in-bot-conversation"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Im obigen Beispiel-Screenshot enthält der Bot-Chatflow drei Schnellantwortoptionen. Wenn der Benutzer ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Mehr erfahren"
      }), " auswählt, lautet die resultierende Event-Payload wie folgt:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example event payload when a quick reply option is selected\n{\n  \"name\": \"QUICK_REPLIES\",\n  \"multiSelect\": false,\n  \"value\": [\"Learn more\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "widgetClosed"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widgetClosed"
      }), "-Event wird ausgelöst, wenn der Besucher das Chat-Widget schließt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "window.HubSpotConversations.on('widgetClosed', (event) => {\n  console.log(event);\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine Bestätigungsnachricht, dass der Besucher das Chat-Widget geschlossen hat."
          })]
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}