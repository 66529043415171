"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 41185684469;
const slug = exports.slug = 'reference/cms/hubl/overview';
const title = exports.title = 'HubL構文';
const name = exports.name = 'APAC JAPAN (JA) | [new] HubL Syntax | 202103';
const metaDescription = exports.metaDescription = 'HubLはjinjaベースのテンプレート作成言語で、HubSpotのCMS上でテンプレートとモジュールを作成する際に使用します。if文、forループ、includeなどについて説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%8C%BA%E5%88%87%E3%82%8A%E6%96%87%E5%AD%97%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97",
  "label": "区切り文字のタイプ",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%96%87",
  "label": "文",
  "parent": "%E5%8C%BA%E5%88%87%E3%82%8A%E6%96%87%E5%AD%97%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E5%BC%8F",
  "label": "式",
  "parent": "%E5%8C%BA%E5%88%87%E3%82%8A%E6%96%87%E5%AD%97%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 2,
  "id": "do%E3%82%BF%E3%82%B0",
  "label": "doタグ",
  "parent": "%E5%8C%BA%E5%88%87%E3%82%8A%E6%96%87%E5%AD%97%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E3%82%B3%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "コメント",
  "parent": "%E5%8C%BA%E5%88%87%E3%82%8A%E6%96%87%E5%AD%97%E3%81%AE%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 0,
  "id": "%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB",
  "label": "モジュール",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%A4%89%E6%95%B0%E3%81%A8%E3%83%9E%E3%82%AF%E3%83%AD",
  "label": "変数とマクロ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC%E3%81%A8%E9%96%A2%E6%95%B0",
  "label": "フィルターと関数",
  "parent": null
}, {
  "depth": 0,
  "id": "if%E6%96%87",
  "label": "if文",
  "parent": null
}, {
  "depth": 0,
  "id": "for%E3%83%AB%E3%83%BC%E3%83%97",
  "label": "forループ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AEhubl%E6%A9%9F%E8%83%BD",
  "label": "その他のHubL機能",
  "parent": null
}, {
  "depth": 1,
  "id": "hubl%E5%8C%BA%E5%88%87%E3%82%8A%E6%96%87%E5%AD%97%E3%81%AE%E3%82%A8%E3%82%B9%E3%82%B1%E3%83%BC%E3%83%97",
  "label": "HubL区切り文字のエスケープ",
  "parent": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AEhubl%E6%A9%9F%E8%83%BD"
}, {
  "depth": 1,
  "id": "%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AB%E5%88%A5%E3%81%AE%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%92%E3%82%A4%E3%83%B3%E3%82%AF%E3%83%AB%E3%83%BC%E3%83%89%E3%81%99%E3%82%8B",
  "label": "ファイルに別のファイルをインクルードする",
  "parent": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AEhubl%E6%A9%9F%E8%83%BD"
}, {
  "depth": 1,
  "id": "%E3%83%96%E3%83%AD%E3%83%83%E3%82%AF%E3%81%A8%E6%8B%A1%E5%BC%B5",
  "label": "ブロックと拡張",
  "parent": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AEhubl%E6%A9%9F%E8%83%BD"
}, {
  "depth": 1,
  "id": "%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%81%AEhubl%E3%82%92%E3%82%B3%E3%83%94%E3%83%BC%E3%81%99%E3%82%8B",
  "label": "セクションのHubLをコピーする",
  "parent": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AEhubl%E6%A9%9F%E8%83%BD"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      pre: "pre",
      code: "code",
      h3: "h3",
      h4: "h4",
      ul: "ul",
      li: "li",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubL構文の概要"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCMSではHubSpot Markup Language、略してHubL（「ハブル」と発音）を使用します。HubLは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/jinjava",
        children: "Jinjava"
      }), "（英語）という", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://jinja.palletsprojects.com/en/latest/",
        children: "Jinja"
      }), "（英語）ベースのテンプレートエンジンをHubSpotが拡張した言語です。HubLは数多くのHubSpot固有のマークアップを採用しています。また、Jinjaの一部の機能はサポートされていません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この記事では、HubLの機能と構文の基本について説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "区切り文字のタイプ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubLは、広く利用されている他のテンプレート言語（PHPなど）と同様に、コード テンプレート ファイル、つまりHubLテンプレートモジュール内でHTMLに組み込むことができます。HubLの開始位置と終了位置を見分けられるように、区切り文字として機能する主な記号を理解しておいてください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% %} - statement delimiters\n{{ }} - expression delimiters\n{# #} - comment delimiters\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "コード内ではコメントのネスト化に注意してください。結果として末尾のコメントタグがテキストとしてレンダリングされる可能性があります。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "文"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL文は、編集可能なモジュールの作成、条件付きテンプレートロジックの定義、forループの構成、変数の定義などに使用します。文は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{%"
      }), "で区切られます。これによるページ出力はありません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "式"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["式は、テンプレートのコンテキストに格納されている値を出力します。式は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{"
      }), "で区切られます。例えば、変数は文として定義する必要がありますが、変数を出力するにはHubL式を使用します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "doタグ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["「do」タグは、通常の文", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% ... %}"
      }), "とまったく同様に機能します。これを使用して、リストやディクショナリーを変更できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["注：配列への追加には", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#append",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".append()"
        })
      }), "を使用し、オブジェクトへの追加には", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#update",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: ".update()"
        })
      }), "を使用します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "# Arrays\n{% set navigation = [\"Home\", \"About\"] %}\n{% do navigation.append(\"Contact Us\") %}\n{{navigation}}\n\n# Objects\n{% set book = {\"name\" : \"Rocking HubL\", \"author\" : \"John Smith\" }%}\n{% do book.update({\"ebook\" : \"yes\"}) %}\n{{book}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "# Arrays [Home, About, Contact Us] # Objects {name=Rocking HubL, author=John\nSmith, ebook=yes}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "コメント"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["最後に紹介する区切り文字は、HubLコメントです。HubLでの開発時に見かけるだけでなく、実際に使用する機会もあるはずです。コメントは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{#"
      }), "によって定義されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "モジュール"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュール"
      }), "は、エンドユーザーがコンテンツエディター上でカスタマイズできるテンプレート内の動的な領域です。例えば、テンプレートファイルをゼロからコーディングする場合に、HubLタグを使用してモジュールをテンプレートに追加することにより、制作担当者がページの特定の領域を編集できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールタグは以下の要素で構成されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**モジュールのタイプ：**レンダリングするモジュールを指定します。使用可能な各種モジュールタイプの一覧については、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags",
          children: "HubLでサポートされているタグ"
        }), "のページを参照してください。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**モジュールの固有名：**テンプレートのコンテキスト内で重複しないIDをモジュールに指定します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**パス：**タグに応じて、デザインマネージャーでのモジュールの位置を定義します。HubSpotの既定モジュールのパスは常に@hubspot/で始まり、その後にモジュールのタイプが続きます。詳しくは、以下の例と", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "テンプレートページでのモジュールの使用"
        }), "のページを参照してください。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**パラメーター：**追加のモジュール情報を指定します（任意）。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"unique_module_name\",\n  path=\"@hubspot/text\",\n  label=\"Single Text Line\",\n  value=\"This is a single text line\"\n%}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text widget-type-text\"\n  data-hs-cos-general-type=\"widget\"\n  data-hs-cos-type=\"module\"\n  id=\"hs_cos_wrapper_text\"\n  style=\"\"\n>\n  <span\n    class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text\"\n    data-hs-cos-general-type=\"widget\"\n    data-hs-cos-type=\"text\"\n    id=\"hs_cos_wrapper_text_\"\n    style=\"\"\n    >This is a single text line</span\n  >\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記の例は、labelパラメーターとvalueパラメーターが指定されたテキストモジュールを示しています。labelはエディターのヘルプテキストを定義し、valueはそのモジュールの既定のテキストを設定します。エディター内でどのように表示されるかについては、以下のサンプルgifを参照してください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/module-label-value-min.gif",
        alt: "module-label-value-min"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates",
        children: "テンプレートでモジュールを使用する方法については、こちらをご覧ください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "変数とマクロ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubLには、アプリから有用な値を出力する", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "定義済みのHubSpot変数"
      }), "が数多く用意されています。また、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "テンプレートで独自の変数を定義する"
      }), "こともできます。以下の例では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primaryColor"
      }), "という変数を文で定義し、HubL式により出力しています。この例では、HubL変数とCSSを組み合わせています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set primaryColor = '#F7761F' %} {# defines variable and assigns HEX color #}\n\na {\n  color: {{ primaryColor }}; {# prints variable HEX value #}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  color: #f7761f;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubLマクロでは、動的な値を使用して複数の文を出力できます。この手法は、同様の基本的なコードを繰り返し記述するものの、特定の値だけは変更する場合に役立ちます。以下の例では、マクロを使用して、全てのベンダープレフィックスが含まれるCSS3 transitionプロパティーを出力しています。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax#macros",
        children: "マクロについて詳しくは、こちらをご覧ください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro trans(value) %}\n-webkit-transition: {{value}};\n-moz-transition: {{value}};\n-o-transition: {{value}};\n-ms-transition: {{value}};\ntransition: {{value}};\n{% endmacro %}\n\n\na {\n  {{ trans(\"all .2s ease-in-out\") }}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  -webkit-transition: all 0.2s ease-in-out;\n  -moz-transition: all 0.2s ease-in-out;\n  -o-transition: all 0.2s ease-in-out;\n  -ms-transition: all 0.2s ease-in-out;\n  transition: all 0.2s ease-in-out;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フィルターと関数"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubLにフィルターを追加することで、テンプレート変数の値を変換または変更できます。日付変数の書式を設定するシンプルな例を以下に示します。フィルターでは|（パイプライン記号）を使用し、スペースなしで変数に適用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下の例では、ブログ記事が4月29日に公開されたことを想定しています。記事の公開日の書式が、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "datetimeformat"
      }), "フィルターにより設定されます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "全てのフィルターの一覧については、こちらを参照してください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date_local_time|datetimeformat(\"%B %e, %Y\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "April 29, 2020\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "フィルターは変数のレンダリングに影響しますが、関数では値とアカウント情報を処理し、その情報をレンダリングできます。例えば、特定のブログの合計記事数を取得する場合や、色変数を特定の量だけ明るくするか暗くする場合には、関数を使用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この例では、designers.hubspot.com/blogのブログ記事の合計数が出力されます。（ブログダッシュボードのURLに含まれる）ブログIDパラメーターを使用して、ターゲットにするブログを指定します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "全ての関数の一覧については、こちらを参照してください"
      }), "。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "// blog_total_post_count\n{{ blog_total_post_count(359485112) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "if文"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if文"
      }), "では、条件ロジックを使用して、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "のHubL文の条件ロジックがテンプレートによってどのようにレンダリングされるかを指示できます。if文は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "で始まり", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "で終わります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下の例では、エンドユーザーがドロップダウンから部門を選択できるchoiceモジュールを定義しています。ユーザーがエディターで選択した内容に基づいて、テンプレート上で採用情報ページの見出しが動的に作成されます。この例では、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "export_to_template_context"
        })
      }), "パラメーターを使用する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% choice \"department\" label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "forループ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "forループ"
      }), "を使用すると、シーケンスとして格納されている項目に対し、繰り返し処理を行うことができます。主に、ブログコンテンツをリスト形式でレンダリングする場合に使用されます。forループは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "for"
      }), "文で始まり", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endfor"
      }), "文で終わります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下の例では、熊の種類の配列を変数", (0, _jsxRuntime.jsx)(_components.code, {
        children: "bears"
      }), "に格納しています。次に、forループを使用してさまざまな熊の種類を順に処理し、種類ごとにリスト項目として出力しています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set bears = [\"panda bear\", \"polar bear\", \"black bear\", \"grizzly bear\", \"koala bear\"] %}\n\n<h1>Types of bears</h1>\n<ul>\n  {% for bear in bears %}\n  <li>{{ bear }}</li>\n  {% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h1>Types of bears</h1>\n<ul>\n  <li>panda bear</li>\n  <li>polar bear</li>\n  <li>black bear</li>\n  <li>grizzly bear</li>\n  <li>koala bear</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "その他のHubL機能"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubLでの開発に役立つその他のテンプレート機能を以下で説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubL区切り文字のエスケープ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubLと同じ区切り文字を使用する言語は他にも多数存在しています。このことが原因で、CMSでコードファイルを処理する際に問題が生じることがあります。別の言語でHubLの区切り文字を使用する場合は、その言語のコードを次のように囲む必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: " {{\"Code you want to escape\"}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{{\"Code you want to escape\"}}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ファイルに別のファイルをインクルードする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#standard-partials",
        children: "includeタグ"
      }), "を使用することで、複数の", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".html"
      }), "ファイルを1つの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HubLテンプレート"
      }), "にインクルードできます。標準テンプレート変数を必要としないHTMLファイルを作成するには、［このテンプレートを新しいコンテンツで利用できるようにする］オプションをオフにする必要があります。以下に構文を示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"custom/page/web_page_basic/my_footer.html\" %}\n{% include \"hubspot/styles/patches/recommended.css\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "また、同じincludeタグを使用して、複数のCSSファイルを1つのCSSファイルにコンパイルすることもできます。親ファイルを公開すると、親のコードによって子ファイルが1つの縮小CSSファイルとしてコンパイルされます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ブロックと拡張"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "複雑なテンプレートを作成するときは、親テンプレートを拡張する区画となるブロックを作成して使用できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["まず、必須の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), "変数と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        })
      }), "変数を含む親テンプレートを作成します。このテンプレート内で、以下の構文を使用して一意のブロックを定義する必要があります。この", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_sidebar"
      }), "は重複しない名前にします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% extends \"custom/page/web_page_basic/my_template.html\" %}\n{% block my_sidebar %}\n<h3>Sidebar title</h3>\n<ul>\n  <li>Bullet 1<li>\n  <li>Bullet 2<li>\n  <li>Bullet 3<li>\n</ul>\n{% endblock %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["次に、そのブロックにデータを提供する子HTMLファイルを作成します。まず、親のパスを参照する", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#blocks-and-extends",
        children: "extends文"
      }), "を宣言する必要があります。このコードブロックは親テンプレートでレンダリングされますが、扱いやすい小さいファイルとして管理できます。この手法は全てのユーザー向けではありませんが、複雑なEメールテンプレートやページテンプレートをコーディングする際の整理に役立ちます。この手法を使用する場合、コンテンツの作成時に子テンプレートを選択する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "セクションのHubLをコピーする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページエディターでは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections",
        children: "ドラッグ＆ドロップセクション"
      }), "のHubLマークアップをコピーして、コードを必要に応じて再利用できます。これは、コードファイル内でドラッグ＆ドロップセクションを再作成する場合に便利です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-section-hubl-menu.png",
        alt: "copy-section-hubl-menu"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#copy-section-hubl",
        children: "セクションのHubLのコピー"
      }), "をご確認ください。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}