"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694158;
const slug = exports.slug = 'guides/cms/content/templates/types/html-hubl-templates';
const title = exports.title = 'Plantillas HTML + HubL';
const name = exports.name = 'Plantillas HTML + HubL';
const metaDescription = exports.metaDescription = 'Las plantillas de CMS de HubSpot están escritas en HTML y HubL. Estas plantillas son capaces de contener regiones fáciles de editar por los propios creadores de contenido.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/image1-2.png';
const featuredImageAltText = exports.featuredImageAltText = 'Captura de pantalla de una plantilla básica de HTML + HubL';
const toc = exports.toc = [{
  "depth": 0,
  "id": "familiaridad-y-herramientas",
  "label": "Familiaridad y herramientas",
  "parent": null
}, {
  "depth": 0,
  "id": "anotaciones-de-la-plantilla",
  "label": "Anotaciones de la plantilla",
  "parent": null
}, {
  "depth": 0,
  "id": "incluye-encabezado-y-pie-de-p%C3%A1gina",
  "label": "Incluye encabezado y pie de página",
  "parent": null
}, {
  "depth": 0,
  "id": "parciales",
  "label": "Parciales",
  "parent": null
}, {
  "depth": 1,
  "id": "parciales-est%C3%A1ndar",
  "label": "Parciales estándar",
  "parent": "parciales"
}, {
  "depth": 1,
  "id": "parciales-globales",
  "label": "Parciales globales",
  "parent": "parciales"
}, {
  "depth": 1,
  "id": "bloques-y-extensiones",
  "label": "Bloques y extensiones",
  "parent": "parciales"
}, {
  "depth": 1,
  "id": "grupos-globales",
  "label": "Grupos globales",
  "parent": "parciales"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      HubspotVideoPlayer,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Plantillas HTML + HubL"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Las plantillas HTML + HubL se pueden utilizar para ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/templates/overview#template-types",
            children: "cualquier tipo de plantilla"
          }), " en el CMS de HubSpot. Estas plantillas son archivos .html que admiten el ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/cms/hubl/overview",
            children: "lenguaje de plantillas HubL"
          }), ". Como estas plantillas codificadas son compatibles con HubL, la mejor experiencia de previsualización es utilizar la ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/review-your-hubspot-template-setup",
            children: "vista preliminar de la plantilla en el Administrador de diseño"
          }), " o ver las páginas en una cuenta de entorno de pruebas. Las plantillas HTML + HubL pueden contener ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#partials",
            children: "parciales"
          }), ", que se pueden utilizar para separar trozos de código de uso común, como un encabezado o un pie de página."]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
          videoId: "172844649367"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas HTML + HubL dan un mayor control a los desarrolladores que ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/drag-and-drop-templates",
        children: "las plantillas de arrastrar y soltar del administrador de diseño visual"
      }), ". Los desarrolladores, a su vez, pueden ofrecer mejores experiencias a los creadores de contenido mediante la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "funcionalidad de arrastrar y soltar"
      }), ", lo que solo es posible con las plantillas HTML + HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image1-2.png",
        alt: "image1-2"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["La plantilla anterior es la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/layouts/base.html",
          children: "plantilla base.html"
        }), " incluida en la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "biblioteca de recursos de CMS Hub"
        }), ", que es una excelente manera de comenzar a desarrollar con HubSpot."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Familiaridad y herramientas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dado que las plantillas HTML + HubL son archivos codificados, puedes usar tus herramientas preferidas para editarlas localmente. Recomendamos usar las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "herramientas de desarrollo local"
      }), " de HubSpot para que puedas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: "cargar"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch",
        children: "recuperar"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#watch",
        children: "ver"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#create",
        children: "crear"
      }), " y administrar de forma segura archivos en el sistema de archivos del desarrollador, así como en el administrador de archivos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Crear plantillas HTML + HubL con HubSpot es similar a usar otro lenguaje de plantillas que hayas usado antes. La diferencia principal es que HubSpot adopta una postura de opinión sobre las mejores formas de hacer algunas cosas para ofrecer la mejor experiencia a los creadores de contenido, y también quita gran parte del trabajo de mantenimiento y optimización del rendimiento al desarrollador."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, si quieres cargar un archivo CSS en una página para ciertos módulos, en lugar de usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<link rel=\"stylesheet\" type=\"text/css\" href=\"theme.css\">"
      }), ", debes incluir la hoja de estilo a través de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css_assets"
      }), " en el archivo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-css-and-javascript-dependencies",
        children: "meta.json del módulo"
      }), ". Esto permite a HubSpot cargar condicionalmente el CSS solo cuando el módulo está presente en una página, minimizando la cantidad de CSS innecesario cargado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/creating-an-efficient-development-workflow",
        children: "optimizar el workflow de desarrollo de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Anotaciones de la plantilla"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las anotaciones de la plantilla, incluidas en la parte superior de una plantilla, configuran ajustes importantes de la plantilla, como el tipo de plantilla y si se puede usar para crear contenido nuevo. Las anotaciones de la plantilla se pueden cambiar en cualquier momento durante el proceso de desarrollo. A continuación, descubre más información sobre las anotaciones de plantilla disponibles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: page\n  isAvailableForNewContent: false\n  enableDomainStylesheets: false\n  label: Homepage\n  screenshotPath: ../images/template-previews/home.png\n-->\n\n<!doctype html>\n\n<html>\n  ...\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Anotación"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "templateType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Especifica qué tipo de plantilla es un archivo. Los valores incluyen:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: ["Plantillas estándar:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#page",
                      children: "página"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email",
                      children: "correo electrónico"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog-listing",
                      children: "blog_listing"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog-post",
                      children: "blog_post"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#blog",
                      children: "blog"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Parciales: ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/cms/content/templates/types/html-hubl-templates#global-partials",
                  children: "global_partial"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["Plantillas del sistema:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#error-pages",
                      children: "error_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#password-prompt",
                      children: "password_prompt_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-login",
                      children: "membership_login_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-register",
                      children: "membership_register_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-password-reset",
                      children: "membership_reset_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#membership-reset-request",
                      children: "membership_reset_request_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-subscription-preferences",
                      children: "email_subscription_preferences_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-backup-unsubscribe",
                      children: "email_backup_unsubscribe_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#email-subscription-unsubscribe-confirmation",
                      children: "email_subscriptions_confirmation_page"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/cms/content/templates/overview#search-results-page",
                      children: "search_results_page"
                    })
                  })]
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "isAvailableForNewContent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Especifica si una plantilla está disponible para su selección en el proceso de creación de contenido. Los valores incluyen: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ". Las plantillas definidas como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " no necesitan incluir las ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/variables#required-page-template-variables",
              children: "variables requeridas"
            }), ". Las plantillas del tipo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "page"
            }), " que se definen como falso también se pueden usar como ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#partials",
              children: "parciales estándar"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enableDomainStylesheets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Especifica si la plantilla debe cargar ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/design-manager/create-edit-and-attach-css-files-to-style-your-site#attach-or-remove-stylesheets-on-a-domain-level",
              children: "hojas de estilo de dominio"
            }), ". Los valores incluyen: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Descripción sencilla de la plantilla, que se muestra en la pantalla de selección de plantillas. Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "About Page"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Homepage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Pricing"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshotPath"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cadena"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La captura de pantalla que se muestra cuando un creador de contenido está seleccionando una plantilla. Esta captura de pantalla debería facilitar la diferenciación de tus plantillas."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Incluye encabezado y pie de página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las plantillas de HubSpot requieren la presencia de dos etiquetas a menos que el archivo sea una plantilla parcial. Estas son las etiquetas básicas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ standard_header_includes }}"
        }), " - Se utiliza para agregar de forma inteligente el CSS requerido combinado y minimizado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ standard_footer_includes }}"
        }), " - Se utiliza para agregar de forma inteligente javascript en la parte inferior de una página de forma dinámica, para cosas como el script de seguimiento de HubSpot y los módulos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Estas etiquetas deben estar presentes en una plantilla o en sus hijos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#partials",
        children: "parciales"
      }), " para poder publicarse y utilizarse."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parciales"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las plantillas parciales son archivos HTML + HubL que pueden incluirse en otros archivos codificados. Los parciales te permiten adoptar un enfoque más modular al compartir el marcador entre múltiples plantillas. Por ejemplo, un encabezado se puede convertir en parcial para que pueda incluirlo fácilmente como componente sin necesidad de volver a codificarlo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Parciales estándar"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un parcial estándar es una plantilla o componente reutilizable con contenido que se puede editar en páginas individuales. Esto permite a los creadores de contenido cambiar el contenido según sea necesario, a diferencia de los parciales globales que siempre comparten contenido. Por ejemplo, el siguiente código arrastraría el módulo de la barra lateral a otro archivo de plantilla."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los parciales estándar deben incluir las siguientes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "anotaciones"
      }), " en la parte superior del archivo de plantilla:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "templateType: page"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "isAvailableForNewContent: false"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"../partial/sidebar.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Parciales globales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un parcial global es un tipo de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content#global-partials",
        children: "contenido global"
      }), " que se puede usar en múltiples plantillas con contenido que se comparte en todas las instancias del parcial. Los parciales globales a menudo se usan para encabezados y pies de página, que puedes ver un ejemplo en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/partials/header.html",
        children: "encabezado"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/partials/footer.html",
        children: "pie de página"
      }), " de la biblioteca de recursos de CMS Hub. Estos parciales se llaman entonces en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html",
        children: "base.html"
      }), " utilizando la etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global_partial"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los parciales globales deben incluir la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates#template-annotations",
        children: "anotación"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: global_partial"
      }), " en la parte superior del archivo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% global_partial path=\"../partials/header.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Bloques y extensiones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Al crear plantillas complejas, puedes crear bloques compartimentados que amplíen una plantilla principal."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por ejemplo, puedes crear una plantilla principal que incluya las variables requeridas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_header_includes"
        })
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#required-page-template-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "standard_footer_includes"
        })
      }), ". Dentro de esa plantilla, se define un bloque único utilizando la siguiente sintaxis, donde el ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body"
      }), " es un nombre único:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% block body %}\n<!-- Content to display -->\n{% endblock body %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En la plantilla secundaria, puedes extender la plantilla principal e insertar más contenido en el bloque ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% extends \"./layouts/base.html\" %}\n{% block body %}\n<h3>Page Content</h3>\n<ul>\n  <li>Bullet 1<li>\n  <li>Bullet 2<li>\n  <li>Bullet 3<li>\n</ul>\n{% endblock %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este método se utiliza en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/templates/layouts/base.html",
        children: "plantilla base.html"
      }), " de la biblioteca de recursos de CMS Hub, que luego se extiende por las otras plantillas en la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/main/src/templates",
        children: "carpeta de plantillas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Grupos globales"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También se pueden incluir los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
        children: "grupos globales"
      }), " creados con el creador de plantillas de arrastrar y soltar en el administrador de diseño. La sintaxis se muestra a continuación:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% include \"/path/to/global_header.template.json\" %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}