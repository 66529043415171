"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611105;
const slug = exports.slug = 'reference/cms/hubl/filters';
const title = exports.title = 'HubL filters';
const name = exports.name = 'HubL filters';
const metaDescription = exports.metaDescription = 'HubL filter list and examples for HubSpot CMS developers.';
const position = exports.position = 3;
const toc = exports.toc = [{
  "depth": 0,
  "id": "abs",
  "label": "abs",
  "parent": null
}, {
  "depth": 0,
  "id": "add",
  "label": "add",
  "parent": null
}, {
  "depth": 0,
  "id": "attr",
  "label": "attr",
  "parent": null
}, {
  "depth": 0,
  "id": "batch",
  "label": "batch",
  "parent": null
}, {
  "depth": 0,
  "id": "between_times",
  "label": "between_times",
  "parent": null
}, {
  "depth": 0,
  "id": "bool",
  "label": "bool",
  "parent": null
}, {
  "depth": 0,
  "id": "capitalize",
  "label": "capitalize",
  "parent": null
}, {
  "depth": 0,
  "id": "center",
  "label": "center",
  "parent": null
}, {
  "depth": 0,
  "id": "convert_rgb",
  "label": "convert_rgb",
  "parent": null
}, {
  "depth": 0,
  "id": "cut",
  "label": "cut",
  "parent": null
}, {
  "depth": 0,
  "id": "datetimeformat-deprecated",
  "label": "datetimeformat (deprecated)",
  "parent": null
}, {
  "depth": 0,
  "id": "default",
  "label": "default",
  "parent": null
}, {
  "depth": 0,
  "id": "dictsort",
  "label": "dictsort",
  "parent": null
}, {
  "depth": 0,
  "id": "difference",
  "label": "difference",
  "parent": null
}, {
  "depth": 0,
  "id": "divide",
  "label": "divide",
  "parent": null
}, {
  "depth": 0,
  "id": "divisible",
  "label": "divisible",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_html",
  "label": "escape_html",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_attr",
  "label": "escape_attr",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_jinjava",
  "label": "escape_jinjava",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_js",
  "label": "escape_js",
  "parent": null
}, {
  "depth": 0,
  "id": "escape_url",
  "label": "escape_url",
  "parent": null
}, {
  "depth": 0,
  "id": "escapejson",
  "label": "escapejson",
  "parent": null
}, {
  "depth": 0,
  "id": "filesizeformat",
  "label": "filesizeformat",
  "parent": null
}, {
  "depth": 0,
  "id": "first",
  "label": "first",
  "parent": null
}, {
  "depth": 0,
  "id": "float",
  "label": "float",
  "parent": null
}, {
  "depth": 0,
  "id": "forceescape",
  "label": "forceescape",
  "parent": null
}, {
  "depth": 0,
  "id": "format",
  "label": "format",
  "parent": null
}, {
  "depth": 0,
  "id": "format_currency-deprecated",
  "label": "format_currency (deprecated)",
  "parent": null
}, {
  "depth": 0,
  "id": "format_currency_value",
  "label": "format_currency_value",
  "parent": null
}, {
  "depth": 0,
  "id": "format_date",
  "label": "format_date",
  "parent": null
}, {
  "depth": 0,
  "id": "format_datetime",
  "label": "format_datetime",
  "parent": null
}, {
  "depth": 0,
  "id": "format_time",
  "label": "format_time",
  "parent": null
}, {
  "depth": 0,
  "id": "fromjson",
  "label": "fromjson",
  "parent": null
}, {
  "depth": 0,
  "id": "geo_distance",
  "label": "geo_distance",
  "parent": null
}, {
  "depth": 0,
  "id": "groupby",
  "label": "groupby",
  "parent": null
}, {
  "depth": 0,
  "id": "indent",
  "label": "indent",
  "parent": null
}, {
  "depth": 0,
  "id": "int",
  "label": "int",
  "parent": null
}, {
  "depth": 0,
  "id": "intersect",
  "label": "intersect",
  "parent": null
}, {
  "depth": 0,
  "id": "ipaddr",
  "label": "ipaddr",
  "parent": null
}, {
  "depth": 0,
  "id": "join",
  "label": "join",
  "parent": null
}, {
  "depth": 0,
  "id": "last",
  "label": "last",
  "parent": null
}, {
  "depth": 0,
  "id": "length",
  "label": "length",
  "parent": null
}, {
  "depth": 0,
  "id": "list",
  "label": "list",
  "parent": null
}, {
  "depth": 0,
  "id": "log",
  "label": "log",
  "parent": null
}, {
  "depth": 0,
  "id": "lower",
  "label": "lower",
  "parent": null
}, {
  "depth": 0,
  "id": "map",
  "label": "map",
  "parent": null
}, {
  "depth": 0,
  "id": "md5",
  "label": "md5",
  "parent": null
}, {
  "depth": 0,
  "id": "minus_time",
  "label": "minus_time",
  "parent": null
}, {
  "depth": 0,
  "id": "multiply",
  "label": "multiply",
  "parent": null
}, {
  "depth": 0,
  "id": "plus_time",
  "label": "plus_time",
  "parent": null
}, {
  "depth": 0,
  "id": "pprint",
  "label": "pprint",
  "parent": null
}, {
  "depth": 0,
  "id": "random",
  "label": "random",
  "parent": null
}, {
  "depth": 0,
  "id": "regex_replace",
  "label": "regex_replace",
  "parent": null
}, {
  "depth": 0,
  "id": "reject",
  "label": "reject",
  "parent": null
}, {
  "depth": 0,
  "id": "rejectattr",
  "label": "rejectattr",
  "parent": null
}, {
  "depth": 0,
  "id": "render",
  "label": "render",
  "parent": null
}, {
  "depth": 0,
  "id": "replace",
  "label": "replace",
  "parent": null
}, {
  "depth": 0,
  "id": "reverse",
  "label": "reverse",
  "parent": null
}, {
  "depth": 0,
  "id": "root",
  "label": "root",
  "parent": null
}, {
  "depth": 0,
  "id": "round",
  "label": "round",
  "parent": null
}, {
  "depth": 0,
  "id": "safe",
  "label": "safe",
  "parent": null
}, {
  "depth": 0,
  "id": "sanitize_html",
  "label": "sanitize_html",
  "parent": null
}, {
  "depth": 0,
  "id": "select",
  "label": "select",
  "parent": null
}, {
  "depth": 0,
  "id": "selectattr",
  "label": "selectattr",
  "parent": null
}, {
  "depth": 0,
  "id": "shuffle",
  "label": "shuffle",
  "parent": null
}, {
  "depth": 0,
  "id": "slice",
  "label": "slice",
  "parent": null
}, {
  "depth": 0,
  "id": "sort",
  "label": "sort",
  "parent": null
}, {
  "depth": 0,
  "id": "split",
  "label": "split",
  "parent": null
}, {
  "depth": 0,
  "id": "string",
  "label": "string",
  "parent": null
}, {
  "depth": 0,
  "id": "striptags",
  "label": "striptags",
  "parent": null
}, {
  "depth": 0,
  "id": "strtotime",
  "label": "strtotime",
  "parent": null
}, {
  "depth": 0,
  "id": "sum",
  "label": "sum",
  "parent": null
}, {
  "depth": 0,
  "id": "symmetric_difference",
  "label": "symmetric_difference",
  "parent": null
}, {
  "depth": 0,
  "id": "title",
  "label": "title",
  "parent": null
}, {
  "depth": 0,
  "id": "tojson",
  "label": "tojson",
  "parent": null
}, {
  "depth": 0,
  "id": "trim",
  "label": "trim",
  "parent": null
}, {
  "depth": 0,
  "id": "truncate",
  "label": "truncate",
  "parent": null
}, {
  "depth": 0,
  "id": "truncatehtml",
  "label": "truncatehtml",
  "parent": null
}, {
  "depth": 0,
  "id": "unescape_html",
  "label": "unescape_html",
  "parent": null
}, {
  "depth": 0,
  "id": "union",
  "label": "union",
  "parent": null
}, {
  "depth": 0,
  "id": "unique",
  "label": "unique",
  "parent": null
}, {
  "depth": 0,
  "id": "unixtimestamp",
  "label": "unixtimestamp",
  "parent": null
}, {
  "depth": 0,
  "id": "upper",
  "label": "upper",
  "parent": null
}, {
  "depth": 0,
  "id": "urlencode",
  "label": "urlencode",
  "parent": null
}, {
  "depth": 0,
  "id": "urldecode",
  "label": "urldecode",
  "parent": null
}, {
  "depth": 0,
  "id": "urlize",
  "label": "urlize",
  "parent": null
}, {
  "depth": 0,
  "id": "wordcount",
  "label": "wordcount",
  "parent": null
}, {
  "depth": 0,
  "id": "wordwrap",
  "label": "wordwrap",
  "parent": null
}, {
  "depth": 0,
  "id": "xmlattr",
  "label": "xmlattr",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      strong: "strong",
      a: "a",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubL filters"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filters affect the ultimate output of your HubL. They can be applied to various HubL statements and expressions to alter the template markup outputted by the server."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The basic syntax of a filter is ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|filtername"
      }), ". The filter is added directly following the statement or the expression, within its delimiters. Some filters have additional parameters that can be added in parentheses. The basic syntax of a filter with a string, a number, and a boolean parameters is: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "|filtername(\"stringParameter\", 10, true)"
      }), ". Notice that string parameters should be written in quotes. Also note that HubL filters have an alias that can be used to serve the same purpose as the primary filter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following article contains all of the supported HubL filters."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you can apply HubL filters to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/personalize-your-content",
          children: "personalization tokens"
        }), ", such as contact and company tokens, on HubSpot CMS and blog pages, but ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " in emails."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "abs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Gets the absolute value of a number. You can use this filter to ensure that a number is positive."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_number = -53 %}\n{{ my_number|abs }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "add"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Adds a numeric value to another numeric value. This filter functions the same as the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#math",
        children: "+ operator"
      }), ". The parameter in parentheses is the addend that you are combining with your initial numeric value."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_num = 40 %}\n{{ my_num|add(13) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "attr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Renders the attribute of a dictionary. This filter is the equivalent of printing a variable that exists within a dictionary, such as content.absolute_url."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Specifies which attribute to print"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content|attr(\"absolute_url\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "https://developers.hubspot.com/docs/cms/hubl/filters\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "batch"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A batch filter groups up items within a sequence."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "linecount"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The number of items to include in the batch"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "fill_with"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Specifies what to include in order to fill up any missing items"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the example below, there is variable containing a sequence of types of fruits. The batch filter is applied to a loop that iterates through the sequence. The nested loop runs three times to print 3 types of fruit per row, before the outer loop runs again. Notice in the final output that since there are only 5 types of fruit, the final item is replaced by a &nbsp (the second parameter)."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set rows = [\"apples\", \"oranges\", \"pears\", \"grapes\", \"blueberries\"] %}\n\n<table>\n{% for row in rows|batch(3, \" \") %}\n   <tr>\n    {% for column in row %}\n        <td>{{ column }}</td>\n    {% endfor %}\n    </tr>\n{% endfor %}\n</table>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<table>\n  <tbody>\n    <tr>\n      <td>apples</td>\n      <td>oranges</td>\n      <td>pears</td>\n    </tr>\n    <tr>\n      <td>grapes</td>\n      <td>blueberries</td>\n      <td></td>\n    </tr>\n  </tbody>\n</table>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "between_times"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calculates the time between two datetime objects in a specified time unit."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you should use this filter ", (0, _jsxRuntime.jsx)("u", {
          children: "only"
        }), " with variables that return a date. Starting September 30, 2024, this filter will no longer return the current date when a null value is passed. After that date, a null value in the filter will return September 30, 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ending datetime object"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Valid time units are ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " , and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set begin = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{% set end = \"2018-07-20T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ begin|between_times(end, \"days\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "6\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "bool"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converts a text string value to a boolean."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if \"true\"|bool == true %}hello world{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "hello world\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "capitalize"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Capitalize the first letter of a variable value. The first character will be uppercase, all others letters will be lowercased. Subsequent words separated by spaces or hyphens will not have their first letter uppercased."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set sentence = \"the first letter of a sentence should always be capitalized.\" %}\n{{ sentence|capitalize }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The first letter of a sentence should always be capitalized.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "center"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The center filter uses whitespace to center text within a given field length. This filter is not recommended or particularly useful since HubSpot's HTML compiler will automatically strip out the white space; however, it is included here for the sake of comprehensiveness."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Specifies the length of whitespace to center the text in."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The example below shows a center filter being applied to a variable in a pre tag, so the whitespace isn't stripped out."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<pre>\n{% set var = \"string to center\" %}\nbefore{{ var|center(80) }}after\n</pre>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<pre>\nbefore                                string to center                                after\n</pre>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "convert_rgb"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converts a HEX value to an RGB string. This is useful if you need to convert color variables to RGB to be used with a RGBA CSS declaration. In the example below, the value set by a color module is converted to an RGB value and used in an RGBA CSS declaration."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_color = \"#FFFFFF\" %}\n{{ my_color|convert_rgb }}\n\n{% color \"my_color\" color=\"#000000\", export_to_template_context=True %}\n<div style=\"background: rgba({{ widget_data.my_color.color|convert_rgb }}, .5)\"></div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "255, 255, 255\n\n<div style=\"background: rgba(0, 0, 0, .5)\"></div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "cut"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Removes a string from a value. This filter can be used to match and cut out a specific part of a string. The parameter specifies the part of the string that should be removed. The example below removes the space and the word world from the original variable value."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "characters_to_cut"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The part of the string that should be removed."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_string = \"Hello world.\" %}\n{{ my_string|cut(\" world\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Hello.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "datetimeformat (deprecated)"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": this filter has been ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/deprecated#datetimeformat",
          children: "deprecated"
        }), ". Instead, use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-datetime",
          children: "format_datetime"
        }), " filter, which has a more standardized syntax."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "default"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If the value is undefined it will return the first parameter, otherwise the value of the variable will be printed. If you want to use default with variables that evaluate to false, you have to set the second parameter to ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "true"
      }), ". The first example below would print the message if the variable is not defined. The second example applied the filter to an empty string, which is not undefined, but it prints a message due to the second parameter."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default_variable"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Value to return if the variable is undefined. If the variable is defined, the value of the variable will be returned instead."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Returns the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "default_value"
            }), " if the variable is an empty string"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ my_variable|default(\"my_variable is not defined\") }}\n{{ \"\"|default(\"the string was empty\", true) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "my_variable is not defined the string was empty\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dictsort"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sort a dict and yield (key, value) pairs. Dictionaries are unsorted by default, but you can print a dictionary, sorted by key or value. The first parameter is a boolean to determine, whether or not the sorting is case sensitive. The second parameter determines whether to sort the dict by key or value. The example below prints a sorted contact dictionary, with all the known details about the contact."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "case_sensitive"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Determines if sorting is case sensitive"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "sort_by"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Determines whether to sort by ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "key"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for item in contact|dictsort(false, \"value\") %}\n    {{item}}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A sorted contact dictionary\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "difference"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This filter returns the difference of two sets or lists. The list returned from the filter contains all unique elements that are in the first list but not the second."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The second list to compare to for use in finding differences from the original list."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|difference([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "divide"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Divide the current value by a divisor. The parameter passed is the divisor. This filter is an alternative to the / operator."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "divisor"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The number to divide the variable by."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set numerator = 106 %}\n{{ numerator|divide(2) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "divisible"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "An alternative to the divisibleby expression test, the filter divisible will evaluate to true if the value is divisible by the given number."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "divisor"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The number to use when evaluating if the value is divisible."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set num = 10 %}\n{% if num|divisible(2) %}\nThe number is divisble by 2\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The number is divisible by 2\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_html"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Escapes the content of an HTML input. Accepts a string and converts the characters ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ">"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "‘"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "”"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " into HTML-safe sequences. Use this filter for HubL variables that are used in HTML but should not allow any HTML."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<div>This markup is printed as text</div>\" %}\n{{ escape_string|escape_html }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div>This markup is printed as text</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_attr"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Escapes the content of an HTML attribute input. Accepts a string and converts the characters ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "&"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "‘"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "”"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " into HTML-safe sequences. Use this filter for HubL variables that are being added to HTML attributes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note that when escaping values of attributes that accept URLs, such as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "href"
      }), ", you should use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_url"
      }), " filter instead."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"This <br> markup is printed as text\" %}\n<img src=\"test.com/imageurl\" alt=\"{{escape_string|escape_attr}}\">\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<img src=\"test.com/imageurl\" alt=\"This <br> markup is printed as text\" />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_jinjava"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Converts the characters ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "}"
      }), " in strings to Jinjava-safe sequences. Use this filter if you need to display text that might contain such characters in Jinjava."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"{{This markup is printed as text}}\" %}\n{{ escape_string|escape_jinjava }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{{This markup is printed as text}}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_js"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Escapes strings, including ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), ", so that they can be safely inserted into a JavaScript variable declaration. Use this filter for HubL variables that are used inside HTML script elements."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"\\tThey said 'This string can safely be inserted into JavaScript.'\" %}\n{{ escape_string|escape_js }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "\\tThey said \\x27This string can safely be inserted into JavaScript.\\x27\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escape_url"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Escapes the content of a URL input, enforcing specified protocols, stripping invalid and dangerous characters, and encodes HTML entities. Returns empty if a URL is valid. Use this filter for HubL variables that are used within HTML attributes that should be valid URLs."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"https://www.google.com<\" %}\n <a href=\"{{ escape_string|escape_url }}\">\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<a href=\"\"></a>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "escapejson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Escapes strings so that they can be used as JSON values."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<script>alert('oh no!')</script>\" %}\n{% require_js position=\"head\" %}\n<script data-search_input-config=\"config_{{ name }}\" type=\"application/json\">\n{\n  \"autosuggest_results_message\": \"{{ escape_string|escapejson }}\"\n}\n</script>\n{% end_require_js %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<script\n  data-search_input-config=\"config_widget_1234567\"\n  type=\"application/json\"\n>\n  {\n    \"autosuggest_results_message\": \"<script>alert('oh no!')<\\/script>\"\n  }\n</script>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "filesizeformat"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Format the value like a ‘human-readable’ file size (i.e. 13 kB, 4.1 MB, 102 Bytes, etc). Per default decimal prefixes are used (Mega, Giga, etc.), if the parameter is set to True the binary prefixes are used (Mebi, Gibi)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "If set to true, binary prefixes are used such as Mebi & Gibi."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set bytes = 100000 %}\n{{ bytes|filesizeformat }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "100.0 KB\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "first"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Returns the first item in a sequence."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{{ my_sequence|first }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Item 1\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "float"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convert the value into a floating point number. If the conversion doesn’t work it will return 0.0. You can override this default using the first parameter."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer to return if the conversion doesn't work."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_text\" value=\"25\", export_to_template_context=True %}\n{{ widget_data.my_text.value|float + 28 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "forceescape"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Strictly enforce HTML escaping. In HubSpot's environment there isn't really a use case for double escaping, so this is generally behaves the same as the escape filter."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"<div>This markup is printed as text</div>\" %}\n{{ escape_string|forceescape }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div>This markup is printed as text</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Apply Python string formatting to an object. %s can be replaced with another variable."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"Hi %s %s\"|format(contact.firstname, contact.lastname) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Hi Brian Halligan\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_currency (deprecated)"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " this filter has been ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/deprecated",
          children: "deprecated"
        }), ". Instead, use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#format-currency-value",
          children: "format_currency_value"
        }), " filter."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_currency_value"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Formats a given number as a currency based on portal's default currency and locale passed in as a parameter. Replaces the deprecated ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/deprecated#format-currency",
        children: "format_currency filter"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 100 | format_currency_value(locale='en-GB', currency='EUR', maxDecimalDigits=6, minDecimalDigits=1) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "€100.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.oracle.com/java/technologies/javase/jdk8-jre8-suported-locales.html",
              children: "The Java local Language tag"
            }), ". The default is the page's locale.Format : ISO639LanguageCodeInLowercase-ISO3166CountryCodeInUppercase"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "currency"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://en.wikipedia.org/wiki/ISO_4217",
              children: "alphabetic ISO 4217 code"
            }), " of the currency, default is the portals default currency. Numeric codes are not accepted."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "minDecimalDigits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The minimum number of decimal digits to use. Defaults to the currency's default number of decimal digits."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "maxDecimalDigits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The maximum number of decimal digits to use. Defaults to the currency's default number of decimal digits."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_date"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formats the date component of a date object."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you should use this filter ", (0, _jsxRuntime.jsx)("u", {
          children: "only"
        }), " with variables that return a date. Starting September 30, 2024, this filter will no longer return the current date when a null value is passed. After that date, a null value in the filter will return September 30, 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The format to use. Can be one of:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["a custom pattern following ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "Unicode LDML"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The time zone of the output date in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "IANA TZDB format"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The locale to use for locale-aware formats."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date | format_date('long') }}\n{{ content.publish_date | format_date('yyyyy.MMMM.dd') }}\n{{ content.publish_date | format_date('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "November 28, 2022 02022.November.28 28.11.2022\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_datetime"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Formats both the date and time components of a date object. This filter replaces the deprecated ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/deprecated#datetimeformat-nbsp-",
        children: "datetimeformat"
      }), " filter. By default, returns a datetime in the UTC-00:00 time zone."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you should use this filter ", (0, _jsxRuntime.jsx)("u", {
          children: "only"
        }), " with variables that return a date. Starting September 30, 2024, this filter will no longer return the current date when a null value is passed. After that date, a null value in the filter will return September 30, 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The format to use. Can be one of:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["a custom pattern following ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "Unicode LDML"
                })]
              })]
            }), "When using ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "long"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "full"
            }), ", timestamp will include a Z to denote zero offset UTC time (i.e., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2:23:00 PM Z"
            }), "). To remove the Z designator, specify a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The time zone of the output date in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "IANA TZDB format"
            }), ". By default, returns UTC time."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The locale to use for locale-aware formats."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.publish_date | format_datetime('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "12/31/69 7:00 PM\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "format_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Formats the time component of a date object."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you should use this filter ", (0, _jsxRuntime.jsx)("u", {
          children: "only"
        }), " with variables that return a date. Starting September 30, 2024, this filter will no longer return the current date when a null value is passed. After that date, a null value in the filter will return September 30, 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "format"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The format to use. Can be one of:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "full"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: ["a custom pattern following ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://unicode.org/reports/tr35/tr35-dates.html#Date_Format_Patterns",
                  children: "Unicode LDML"
                })]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeZone"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The time zone of the output date in ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://data.iana.org/time-zones/tzdb/",
              children: "IANA TZDB format"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The locale to use for locale-aware formats."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.updated | format_time('long') }}\n{{ content.updated | format_time('hh:mm a') }}\n{{ content.updated | format_time('medium', 'America/New_York', 'de-DE') }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "3:25:06 PM Z 03:25 PM 10:25:44\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "fromjson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converts a JSON string to an object."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set obj =\"{ \"name\":\"Brian\",\"role\":\"Owner\" }\" %}\n{{ obj|fromjson }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "{role=Owner, name=Brian}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "geo_distance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calculates the ellipsoidal 2D distance between two points on Earth."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<!-- in the example below\nthe HubDB Location =\n42.3667, -71.1060 (Cambridge, MA) |\nChicago, IL = 37.3435, -122.0344 -->\n{{ row.location | geo_distance(37.3435, -122.0344, \"mi\") }} MI\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "861.1655563461395 MI\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "groupby"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The groupby filter groups a sequence of objects by a common attribute. The parameter sets the common attribute to group by."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The attribute to group by."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<ul>\n{% for group in contents|groupby(\"blog_post_author\") %}\n    <li>{{ group.grouper }}\n      <ul>\n        {% for content in group.list %}\n          <li>{{ content.name }}</li>\n        {% endfor %}\n      </ul>\n    </li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>\n    Blog author 1\n    <ul>\n      <li>Post by Blog author 1</li>\n      <li></li>\n      <li>Post by Blog author 1</li>\n      <li></li>\n      <li>Post by Blog author 1</li>\n      <li></li>\n    </ul>\n  </li>\n  <li>\n    Blog author 2\n    <ul>\n      <li>Post by Blog author 2</li>\n      <li></li>\n      <li>Post by Blog author 2</li>\n      <li></li>\n      <li>Post by Blog author 2</li>\n      <li></li>\n    </ul>\n  </li>\n  <li>\n    Blog author 3\n    <ul>\n      <li>Post by Blog author 3</li>\n      <li></li>\n      <li>Post by Blog author 3</li>\n      <li></li>\n      <li>Post by Blog author 3</li>\n      <li></li>\n    </ul>\n  </li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "indent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The indent filter uses whitespace to indent text within a given field length. This filter is not recommended or particularly useful since HubSpot's HTML compiler will automatically strip out the white space; however, it is included here for the sake of comprehensiveness. The example below shows a center filter being applied to a variable in a pre tag, so the whitespace isn't stripped out. The first parameter controls the amount of whitespace and the second boolean toggles whether to indent the first line."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The amount of whitespace to be applied."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "boolean"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A boolean value on whether to indent the first line."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<pre>\n{% set var = \"string to indent\" %}\n{{ var|indent(2, true) }}\n</pre>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "string to indent\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "int"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convert the value into an integer. If the conversion doesn’t work it will return 0. You can override this default using the first parameter."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "default"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer to return if the conversion doesn't work."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_text\" value=\"25\", export_to_template_context=True %}\n{{ widget_data.my_text.value|int + 28 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "intersect"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This filter returns the intersection of two sets or lists. The list returned from the filter contains all unique elements that are contained in both lists."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The second list to compare to for use in finding where the list intersects with the original list."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|intersect([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[2, 3]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ipaddr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Evaluates to true if the value is a valid IPv4 or IPv6 address."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set ip = \"1.0.0.1\" %}\n{% if ip|ipaddr %}\n    The string is a valid IP address\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "The string is a valid IP address\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "join"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Return a string which is the concatenation of the strings in the sequence. The separator between elements is an empty string per default, you can define it with the optional parameter. The second parameter can be used to specify an attribute to join."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "delimiter"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The delimiter to use when concatenating strings."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubL Variable"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attribute of value to join in an object."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_list = [1, 2, 3] %}\n{% set sep = \"---\" %}\n{{ my_list|join }}\n{{ my_list|join(\"|\") }}\n{{ my_list|join(sep) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "123 1|2|3 1---2---3\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "last"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Returns the last item of a sequence."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{% my_sequence|last %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Item 3\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "length"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Return the number of items of a sequence or mapping."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set services = [\"Web design\", \"SEO\", \"Inbound Marketing\", \"PPC\"] %}\n{{ services|length }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "4\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "list"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convert the number values into a list. If it was a string the returned list will be a list of characters. To add strings to a sequence, simply add them to the string variables to the sequence delimiters [ ]."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set one = 1 %}\n{% set two = 2 %}\n{% set three = 3 %}\n{% set four = [\"four\"] %}\n{% set list_num = one|list + two|list + three|list + four|list %}\n{{ list_num }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1,2,3]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "log"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calculates the natural logarithm of a number."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "base"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Calculate the logarithm at the nth base."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 10|log }}\n{{ 65536|log(2) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2.302585092994046 16.0\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "lower"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convert a value to all lowercase letters."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ text \"text\" value=\"Text to MAKE Lowercase\", export_to_template_context=True }}\n{{ widget_data.text.value|lower }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "text to make lowercase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "map"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Applies a filter on a sequence of objects or looks up an attribute. This is useful when dealing with lists of objects but you are really only interested in a certain value of it."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The basic usage is mapping on an attribute. For example, if you want to use conditional logic to check if a value is present in a particular attribute of a dict. Alternatively, you can let it invoke a filter by passing the name of the filter and the arguments afterwards."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attribute to return in the sequence of objects."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "filter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Filter to apply to the sequence of objects."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set seq = [\"item1\", \"item2\", \"item3\"] %}\n{{ seq|map(\"upper\") }}\n{{ content|map(\"currentState\")}}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[ITEM1, ITEM2, ITEM3] DRAFT\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "md5"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Calculates the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/MD5",
        children: "md5 hash"
      }), " of the given object"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.absolute_url|md5 }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "923adb4ce05a4c6342c04c80be88d15e\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "minus_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Subtracts an amount of time to a datetime object."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "diff"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Amount to subtract."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Valid time units are ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " , and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set date = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ date }}\n{{ date|minus_time(2, \"months\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2018-07-14 14:31:30 2018-05-14 14:31:30\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "multiply"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Multiplies a value with a number. Functions the same as the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "* operator"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set n = 20 %}\n{{ n|multiply(3) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "60\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "plus_time"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Adds an amount of time to a datetime object."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "diff"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Amount to add."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "timeunit"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Valid time units are ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "nanos"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "micros"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millis"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "seconds"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "minutes"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hours"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "half_days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "days"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "weeks"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "months"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "years"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "decades"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "centuries"
            }), " , ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "millennia"
            }), " , and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eras"
            }), " ."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set date = \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\") %}\n{{ date }}\n{{ date|plus_time(5, \"days\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2018-07-14 14:31:30 2018-07-19 14:31:30\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "pprint"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pretty print a variable. This prints the type of variable and other info that is useful for debugging."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set this_var =\"Variable that I want to debug\" %}\n{{ this_var|pprint }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(String: Variable that I want to debug)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "random"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Return a random item from the sequence."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " when using this filter, the page will be ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "prerendered"
        }), " periodically rather than every time the page content is updated. This means that the filtered content will ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " be updated on every page reload."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "This may not be an issue for certain types of content, such as displaying a random list of blog posts. However, if you need content to change randomly on every page load, you should instead use JavaScript to randomize the content client-side."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|random %}\n<div class=\"post-item\">Post item markup</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Random post</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "regex_replace"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Searches for a regex pattern and replaces with a sequence of characters. The first argument is a RE2-style regex pattern, the second is the replacement string."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Information on the RE2 regex syntax can be found ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/google/re2/wiki/Syntax",
        children: "here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"contact-us-2\"|regex_replace(\"[^a-zA-Z]\", \"\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "contactus\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "reject"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Filters a sequence of objects by applying an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "expression test"
      }), " to the object and rejecting the ones with the test succeeding."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The expression test to apply to the object."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_numbers = [10, 12, 13, 3, 5, 17, 22] %}\n{{ some_numbers|reject(\"even\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[13, 3, 5, 17]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "rejectattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filters a sequence of objects by applying a test to an attribute of an object and rejecting the ones with the test succeeding."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Specifies the attribute to select. You can access nested attributes using dot notation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_test"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The expression to test"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "val"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Value to test against."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|rejectattr(\"post_list_summary_featured_image\") %}\n<div class=\"post-item\">\n{% if content.post_list_summary_featured_image %}\n    <div class=\"hs-featured-image-wrapper\">\n            <a href=\"{{content.absolute_url}}\" title=\"\" class=\"hs-featured-image-link\">\n            <img src=\"{{ content.post_list_summary_featured_image }}\" class=\"hs-featured-image\">\n            </a>\n    </div>\n{% endif %}\n{{ content.post_list_content|safe }}\n</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Post with no featured image</div>\n<div class=\"post-item\">Post with no featured image</div>\n<div class=\"post-item\">Post with no featured image</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "render"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Render strings containing HubL early so that the output can be passed into other filters."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ personalization_token(\"contact.lastname\", \"default value\")|render|lower }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "mclaren\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "replace"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Replace all instances of a substring with a new one."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "old"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The substring that should be replaced."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "new"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Replacement string."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "count"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "If provided, only the firstcount occurrences are replaced."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% if topic %}\n<h3>Posts about {{ page_meta.html_title|replace(\"Blog | \", \"\") }}</h3>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>Posts about topic name</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "reverse"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Reverse the object or return an iterator the iterates over it the other way round. To reverse a list use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#reverse",
        children: ".reverse()"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] %}\n{% for num in nums|reverse %}\n{{ num }}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "10 9 8 7 6 5 4 3 2 1\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "root"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Calculates the square root of a value."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "nth_root"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Calculate the nth root of a number."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 16|root }}\n{{ 625|root(4) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "4 5\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "round"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Round the number to a given precision."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "precision"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Specifies the precision of the rounding."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "rounding_method"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Options include ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "common"
            }), " round either up or down (default); ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ceil"
            }), " always rounds up; ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "floor"
            }), " always rounds down.If you don’t specify a method ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "common"
            }), " is used."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ 52.5|round }}\n{{ 52.5|round(0, \"floor\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "53 52\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "safe"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mark the value as safe which means that in an environment with automatic escaping enabled this variable will not be escaped."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ content.post_list_content|safe }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>HTML post content that is not escaped.</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sanitize_html"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sanitizes the content of an HTML input for the output of rich text content. Accepts a string, then strips HTML tags that are not allowed. Use this filter for HubL variables that are used in HTML that should allow safe HTML."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When using this filter, you can include the following parameters for allowing specific types of HTML tags: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "FORMATTING"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BLOCKS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STYLES"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LINKS"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "TABLES"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IMAGES"
      }), ". For example, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sanitize_html(IMAGES)"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sanitize_html"
      }), " will include all parameters in the filter."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can also include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "STRIP"
      }), " parameter to strip ", (0, _jsxRuntime.jsx)("u", {
        children: "all"
      }), " HTML. All content is run through ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "escape_jinjava"
      }), " as well to prevent nested interpretation."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"This <br> <div>markup is <img src='test.com/image'> <span>printed</span> as text.</div>\" %}\n{{ escape_string|sanitize_html(\"IMAGES\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This  markup is <img src=\"test.com/image\"> printed as text.</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "select"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filters a sequence of objects by applying a test to the object and only selecting the ones with the test succeeding."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The expression test to apply to the object."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_numbers = [10, 12, 13, 3, 5, 17, 22] %}\n{{ some_numbers|select(\"even\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[10, 12, 22]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "selectattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Filters a sequence of objects by applying a test to an attribute of an object and only selecting the ones with the test succeeding."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute_name"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Specifies the attribute to select. You can access nested attributes using dot notation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "exp_test"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The expression to test"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "val"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Value to test against."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|selectattr(\"post_list_summary_featured_image\") %}\n<div class=\"post-item\">\n  {% if content.post_list_summary_featured_image %}\n    <div class=\"hs-featured-image-wrapper\">\n       <a href=\"{{content.absolute_url}}\" title=\"\" class=\"hs-featured-image-link\">\n         <img src=\"{{ content.post_list_summary_featured_image }}\" class=\"hs-featured-image\">\n       </a>\n    </div>\n  {% endif %}\n{{ content.post_list_content|safe }}\n</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">\n  <div class=\"hs-featured-image-wrapper\">\n    <a\n      href=\"http://blog.hubspot.com/marketing/how-to-get-a-job\"\n      title=\"\"\n      class=\"hs-featured-image-link\"\n    >\n      <img\n        src=\"//cdn2.hubspot.net/hub/53/hubfs/00-Blog-Related_Images/landing-a-job-featured-image.png?t=1431452322770&width=761\"\n        class=\"hs-featured-image\"\n      />\n    </a>\n  </div>\n  Post with featured image\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "shuffle"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Randomizes the order of iteration through a sequence. The example below shuffles a standard blog loop."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " when using this filter, the page will be ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "prerendered"
        }), " periodically rather than every time the page content is updated. This means that the filtered content will ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " be updated on every page reload."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "This may not be an issue for certain types of content, such as displaying a random list of blog posts. However, if you need content to change randomly on every page load, you should instead use JavaScript to randomize the content client-side."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents|shuffle %}\n<div class=\"post-item\">Markup of each post</div>\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"post-item\">Markup of each post 5</div>\n<div class=\"post-item\">Markup of each post 3</div>\n<div class=\"post-item\">Markup of each post 1</div>\n<div class=\"post-item\">Markup of each post 2</div>\n<div class=\"post-item\">Markup of each post 4</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "slice"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Slice an iterator and return a list of lists containing those items. The first parameter specifies how many items will be sliced, and the second parameter specifies characters to fill in empty slices."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "slices"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "How many items will be sliced."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "filler"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Specifies characters to fill in empty slices."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set items = [\"laptops\", \"tablets\", \"smartphones\", \"smart watches\", \"TVs\"] %}\n<div class=\"columwrapper\">\n  {% for column in items|slice(3,\" \") %}\n    <ul class=\"column-{{ loop.index }}\">\n    {% for item in column %}\n      <li>{{ item }}</li>\n    {% endfor %}\n    </ul>\n  {% endfor %}\n</div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"columwrapper\">\n  <ul class=\"column-1\">\n    <li>laptops</li>\n    <li>tablets</li>\n    <li>smartphones</li>\n  </ul>\n\n  <ul class=\"column-2\">\n    <li>smart watches</li>\n    <li>TVs</li>\n    <li></li>\n  </ul>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sort"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sorts an iterable. This filter requires all parameters to sort by an attribute in HubSpot. The first parameter is a boolean to reverse the sort order. The second parameter determines whether or not the sorting is case sensitive. And the final parameter specifies an attribute to sort by. In the example below, posts from a blog are rendered and alphabetized by name."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "reverse"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean value to reverse the sort order."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "case_sensitive"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean value that determines if the sorting is case sensitive."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attribute to sort by. Omit when sorting a list."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_posts = blog_recent_posts(\"default\", limit=5) %}\n\n{% for item in my_posts|sort(False, False, \"name\") %}\n{{ item.name }}<br>\n\n\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "A post<br />\nB post<br />\nC post<br />\nD post<br />\nE post<br />\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "split"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Splits the input string into a list on the given separator. The first parameter specifies the separator to split the variable by. The second parameter determines how many times the variable should be split. Any remaining items would remained group. In the example below, a string of names is split at the \";\" for the first 4 names."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "character_to_split_by"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Specifies the separator to split the variable by."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_splits"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Determines how many times the variable should be split. Any remaining items would remain grouped."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set string_to_split = \"Stephen; David; Cait; Nancy; Mike; Joe; Niall; Tim; Amanda\" %}\n{% set names = string_to_split|split(\";\", 4) %}\n<ul>\n{% for name in names %}\n  <li>{{ name }}</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<ul>\n  <li>Stephen</li>\n  <li>David</li>\n  <li>Cait</li>\n  <li>Nancy; Mike; Joe; Niall; Tim; Amanda</li>\n</ul>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "string"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converts a different variable type to a string. In the example below, a integer is converted into a string (pprint is used to confirm the change in variable type)."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set number_to_string = 45 %}\n{{ number_to_string|string|pprint }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "(String: 45)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "striptags"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Strip SGML/XML tags and replace adjacent whitespace by one space. This filter can be used to remove any HTML tags from a variable."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set some_html = \"<div><strong>Some text</strong></div>\" %}\n{{ some_html|striptags }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "some text\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "strtotime"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converts a datetime string and a datetime format into a datetime object."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "datetimeFormat"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://docs.oracle.com/javase/7/docs/api/java/text/SimpleDateFormat.html",
              children: "Date and time patterns."
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"2018-07-14T14:31:30+0530\"|strtotime(\"yyyy-MM-dd'T'HH:mm:ssZ\")|unixtimestamp }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "1531558890000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "sum"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Adds numeric values in a sequence. The first parameter can specify an optional attribute and the second parameter sets a value to return if there is nothing in the variable to sum."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attribute"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Attribute to sum."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "return_if_nothing"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Value to return if there is nothing in the variable to sum."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set sum_this = [1, 2, 3, 4, 5] %}\n{{ sum_this|sum }}\nTotal: {{ items|sum(attribute=\"price:\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "15 Total: 20\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "symmetric_difference"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This filter returns the symmetric difference of two sets or lists. The list returned from the filter contains all unique elements that are in the first list but not the second, or are in the second list but not the first"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The second list to compare to for use in finding the symmetric difference with the original list."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|symmetric_difference([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1, 4, 5]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "title"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Return a titlecased version of the value. I.e. words will start with uppercase letters, all remaining characters are lowercase."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"my_title\" label=\"Enter a title\", value=\"My title should be titlecase\", export_to_template_context=True %}\n{{ widget_data.my_title.value|title }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "My Title Should Be Titlecase\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "tojson"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Writes an object as a JSON string."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% for content in contents %}\n  {{ content.blog_post_author|tojson }}\n{% endfor %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered JSON Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "{\n  \"portalId\": 1234567,\n  \"id\": 12312253109,\n  \"created\": 1566413741989,\n  \"updated\": 1566414012799,\n  \"deletedAt\": 0,\n  \"fullName\": \"Sample User\",\n  \"email\": \"sampleUser@example.com\",\n  \"userId\": null,\n  \"username\": null,\n  \"slug\": \"sample-user\",\n  \"jsonBody\": {\n    \"avatar\": \"https://app.hubspot.com/settings/avatar/109d6874a0cb066c1c7263ac5df6ce7a\",\n    \"bio\": \"Sample Bio\",\n    \"facebook\": \"\",\n    \"linkedin\": \"\",\n    \"twitter\": \"\",\n    \"website\": \"https://www.hubspot.com\"\n  },\n  \"bio\": \"Sample Bio\",\n  \"facebook\": \"\",\n  \"linkedin\": \"\",\n  \"avatar\": \"https://app.hubspot.com/settings/avatar/109d6874a0cb066c1c7263ac5df6ce7a\",\n  \"gravatarUrl\": \"https://app.hubspot.com/settings/avatar/108bb5ac667ded34796271437dfe8d58\",\n  \"twitterUsername\": \"\",\n  \"hasSocialProfiles\": false,\n  \"website\": \"https://www.hubspot.com\",\n  \"twitter\": \"\",\n  \"displayName\": \"Sample User\"\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "trim"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Strips leading and trailing whitespace. HubSpot already trims whitespace from markup, but this filter is documented for the sake of comprehensiveness."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \" remove whitespace \" }}\n{{ \" remove whitespace \"|trim }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "remove whitespace remove whitespace\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "truncate"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuts off text after a certain number of characters. The default is 255. Please note that HTML characters are included in this count. The length is specified with the first parameter which defaults to 255. If the second parameter is true the filter will cut the text at length. Otherwise it will discard the last word. If the text was in fact truncated it will append an ellipsis sign (\"...\"). If you want a different ellipsis sign than \"...\" you can specify it using the third parameter."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_characters"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number of characters to truncate the text by. Default is 255."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "breakword"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean value. If true, the filter will cut the text at length. If false, it will discard the last word."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Override the default '...' trailing characters after the truncation."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"I only want to show the first sentence. Not the second.\"|truncate(40) }}\n{{ \"I only want to show the first sentence. Not the second.\"|truncate(35, True, \"..........\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "I only want to show the first sentence. I only want to show the first\nsente..........\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "truncatehtml"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Truncates a given string, respecting html markup (i.e. will properly close all nested tags). This will prevent a tag from remaining open after truncation. HTML characters do not count towards the character total. This filter has a length parameter and a truncation symbol parameter. There is a third boolean parameter that specifies whether words will be broken at length. This parameter is false by default in order to preserve the length of words. If using only one of the optional parameters, use keyword arguments, such as truncatehtml(70, breakwords = false)."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_characters"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number of characters to truncate the text by. Default is 255."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "end"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Override the default '...' trailing characters after the truncation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "breakword"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean value. If true, the filter will cut the text at length. If false, it will discard the last word."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set html_text = \"<p>I want to truncate this text without breaking my HTML<p>\" %}\n{{ html_text|truncatehtml(28, \"...\" , false) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>I want to truncate this..</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unescape_html"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converts text with HTML-encoded entities to their Unicode equivalents."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set escape_string = \"me & you\" %}\n{{ escape_string|unescape_html }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "me & you\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "union"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This filter returns the union of two sets or lists. The list returned from the filter contains all unique elements that are in either list."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "list"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The second list to union with the original list."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ [1, 2, 3]|union([2, 3, 4, 5]) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[1, 2, 3, 4, 5]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unique"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This filter extracts a unique set from a sequence or dict of objects. When filtering a dict, such as a list of posts returned by a function, you can specify which attribute is used to deduplicate items in the dict."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "attr"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Specifies the attribute that should be used when filtering a dict value"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set my_sequence = [\"one\", \"one\", \"two\", \"three\" ] %}\n{{ my_sequence|unique }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "[one, two, three]\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "unixtimestamp"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This filter converts a datetime object into a unix timestamp."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you should use this filter ", (0, _jsxRuntime.jsx)("u", {
          children: "only"
        }), " with variables that return a date. Starting September 30, 2024, this filter will no longer return the current date when a null value is passed. After that date, a null value in the filter will return September 30, 2024."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ local_dt }}\n{{ local_dt|unixtimestamp }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "2017-01-30 17:11:44 1485814304000\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "upper"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Convert a value to all uppercase letters."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"text\" value=\"text to make uppercase\", export_to_template_context=True %}\n{{ widget_data.text.value|upper }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "TEXT TO MAKE UPPERCASE\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urlencode"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Escapes and URL encodes a string using UTF-8 formatting. Accepts both dictionaries and regular strings as well as pairwise iterables."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"encode\" value=\"Escape & URL encode this string\", label=\"Enter slug\", export_to_template_context=True %}\n{{ widget_data.encode.value|urlencode }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Escape+%26+URL+encode+this+string\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urldecode"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Decodes encoded URL strings back to the original URL. Accepts both dictionaries and regular strings as well as pairwise iterables."
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% text \"decode\" value=\"Escape+%26+URL+decode+this+string\", label=\"Enter slug\", export_to_template_context=True %}\n{{ widget_data.decode.value|urldecode }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Escape & URL encode this string\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "urlize"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Converts URLs in plain text into clickable links. If you pass the filter an additional integer it will shorten the urls to that number. The second parameter is a boolean that dictates whether the link is rel=\"no follow\". The final parameter lets you specify whether the link will open in a new tab."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "shorten_text"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer that will shorten the urls to desired number."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "no_follow"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean value to indicate whether the link is rel=\"no follow\"."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "target=\"_blank\""
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Optional"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Specifies whether the link will open in a new tab."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{ \"http://hubspot.com/\"|urlize }}\n{{ \"http://hubspot.com/\"|urlize(10,true) }}\n{{ \"http://hubspot.com/\"|urlize(\"\",true) }}\n{{ \"http://hubspot.com/\"|urlize(\"\",false,target=\"_blank\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<a href=\"//hubspot.com/\">http://hubspot.com/</a>\n<a href=\"//hubspot.com/\" rel=\"nofollow\">http://...</a>\n<a href=\"//hubspot.com/\" rel=\"nofollow\">http://hubspot.com/</a>\n<a href=\"//hubspot.com/\" target=\"_blank\">http://hubspot.com/</a>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "wordcount"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Count the number of words in a string."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "If the string contains HTML, use the striptags filter to get an accurate count."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{%  set count_words = \"Count the number of words in this variable\"  %}\n{{ count_words|wordcount }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "8\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "wordwrap"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Causes words to wrap at a given character count. This works best in a <pre> because HubSpot strips whitespace by default."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "character_count"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number of characters to wrap the content at."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set wrap_text = \"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam efficitur, ipsum non sagittis euismod, ex risus rhoncus lectus, vel maximus leo enim sit amet dui. Ut laoreet ultricies quam at fermentum.\" %}\n{{ wrap_text|wordwrap(10) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam efficitur, ipsum\nnon sagittis euismod, ex risus rhoncus lectus, vel maximus leo enim sit amet\ndui. Ut laoreet ultricies quam at fermentum.\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "xmlattr"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Create an HTML/XML attribute string, based on the items in a dict. All values that are neither none nor undefined are automatically escaped. It automatically prepends a space in front of the item if the filter returned something unless the first parameter is false."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "autospace"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean value that will automatically prepend a space in front of the item unless set to false."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL & HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set html_attributes = {\"class\": \"bold\", \"id\": \"sidebar\"} %}\n<div {{ html_attributes|xmlattr }}></div>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Rendered HTML Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"bold\" id=\"sidebar\"></div>\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}