/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

// https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/core/src/utils.js

import { retrieveSchema } from './schemaUtils';

/**
 * This function checks if the given schema matches a single
 * constant value.
 */
/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */

export function isConstant(schema) {
  return Array.isArray(schema.enum) && schema.enum.length === 1 || schema.hasOwnProperty('const');
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function isSelect(_schema, definitions = {}) {
  const schema = retrieveSchema(_schema, definitions);
  const altSchemas = schema.oneOf || schema.anyOf;
  if (Array.isArray(schema.enum)) {
    return true;
  } else if (Array.isArray(altSchemas)) {
    return altSchemas.every(altSchema => isConstant(altSchema));
  }
  return false;
}
/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function isMultiSelect(schema, definitions = {}) {
  if (!schema.uniqueItems || !schema.items) {
    return false;
  }
  return isSelect(schema.items, definitions);
}

/*  @ts-expect-error Sep-25-2024, 17:26UTC FixMe: Complete Migration to TypeScript  */
export function isFilesArray(schema, definitions = {}) {
  if (schema.items) {
    const itemsSchema = retrieveSchema(schema.items, definitions);
    return itemsSchema.type === 'string' && itemsSchema.format === 'data-url';
  }
  return false;
}