"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 45279037471;
const slug = exports.slug = 'guides/api/crm/commerce/quotes';
const title = exports.title = 'API de CRM | Cotizaciones';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Quotes';
const metaDescription = exports.metaDescription = 'Las cotizaciones se utilizan para compartir información de precios con prospectos. Los puntos de terminación de cotizaciones te permiten recuperar estos datos y sincronizarlos entre HubSpot y otros sistemas.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "resumen",
  "label": "Resumen",
  "parent": null
}, {
  "depth": 0,
  "id": "crear-una-cotizaci%C3%B3n",
  "label": "Crear una cotización",
  "parent": null
}, {
  "depth": 1,
  "id": "habilitar-firmas-electr%C3%B3nicas",
  "label": "Habilitar firmas electrónicas",
  "parent": "crear-una-cotizaci%C3%B3n"
}, {
  "depth": 0,
  "id": "agregar-asociaciones",
  "label": "Agregar asociaciones",
  "parent": null
}, {
  "depth": 1,
  "id": "recuperar-id-para-asociaciones",
  "label": "Recuperar ID para asociaciones",
  "parent": "agregar-asociaciones"
}, {
  "depth": 1,
  "id": "crear-asociaciones",
  "label": "Crear asociaciones",
  "parent": "agregar-asociaciones"
}, {
  "depth": 1,
  "id": "asociar-firmantes-de-cotizaciones",
  "label": "Asociar firmantes de cotizaciones",
  "parent": "agregar-asociaciones"
}, {
  "depth": 0,
  "id": "crear-una-cotizaci%C3%B3n-con-asociaciones-solicitud-%C3%BAnica",
  "label": "Crear una cotización con asociaciones (solicitud única)",
  "parent": null
}, {
  "depth": 0,
  "id": "actualizar-estado-de-cotizaci%C3%B3n",
  "label": "Actualizar estado de cotización",
  "parent": null
}, {
  "depth": 1,
  "id": "propiedades-establecidas-por-estado-de-cotizaci%C3%B3n",
  "label": "Propiedades establecidas por estado de cotización",
  "parent": "actualizar-estado-de-cotizaci%C3%B3n"
}, {
  "depth": 0,
  "id": "alcances",
  "label": "Alcances",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      h2: "h2",
      ol: "ol",
      li: "li",
      code: "code",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Table,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Table) _missingMdxReference("Table", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Cotizaciones"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utiliza la API de cotizaciones para crear, gestionar y recuperar cotizaciones de ventas para compartir información sobre precios con compradores potenciales. Una vez configurado, una cotización se puede compartir con un comprador, ya sea en una URL especificada o a través de PDF. Los usuarios también pueden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/use-quotes#manage-quotes",
        children: "gestionar cotizaciones en HubSpot"
      }), " para agregar detalles, actualizar asociaciones y mucho más."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/example-quote-api.png",
        alt: "example-quote-api"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ejemplo de caso de uso:"
      }), " debes crear una propuesta de contrato para un cliente que esté interesado en comprar uno de sus paquetes anuales de servicios de auditoría SEO."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A continuación, aprende cómo crear un presupuesto a través de la API y configurar sus diversas propiedades, asociaciones, estados y más."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " la API de cotizaciones no admite la creación de cotizaciones con firma electrónica o el procesamiento de pagos a través de Stripe o HubSpot. Tampoco puedes configurar descuentos, tarifas o impuestos recurrentes a nivel de cotización a través de la API. Para utilizar estas funciones, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-quotes",
          children: "crear la cotización en HubSpot"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Resumen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El proceso de creación de cotizaciones se puede dividir en los siguientes pasos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear una cotización:"
        }), " crea una cotización con algunos detalles, como el nombre y la fecha de vencimiento. También puedes configurar la cotización para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes",
          children: "habilitar firmas electrónicas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configurar asociaciones:"
        }), " asocia la cotización con otros tipos de objetos de CRM, como elementos de pedido, una plantilla de cotización, un negocio y más. Durante el siguiente paso, la cotización heredará los valores de propiedad de algunos de estos registros asociados, así como la configuración de la cuenta."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Establecer el estado de la cotización:"
        }), " establece el estado de la cotización para que refleje que está lista para ser compartida con los compradores. Cuando estableces el estado de la cotización, como convertirla en un borrador editable o en una cotización totalmente publicada y de acceso público, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#properties-set-by-quote-state",
          children: "heredará ciertas propiedades"
        }), " de sus registros de CRM asociados y de la configuración de la cuenta."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Compartir la cotización:"
        }), " una vez que se ha publicado una cotización, puedes compartirla con tus compradores."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una cotización"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear una cotización, primero debes configurar sus detalles básicos haciendo una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quotes"
      }), ". Más tarde, harás una llamada por separado para asociar la cotización con otros objetos, como la plantilla de cotización, un negocio y sus elementos de pedido."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Dependiendo de tu workflow preferido, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-quote-with-associations-single-request-",
          children: "crear una cotización con asociaciones a través de una solicitud POST"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En el cuerpo de la publicación, incluye las siguientes propiedades obligatorias para configurar sus detalles básicos:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100.018%",
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The name of the quote."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100.018%",
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The date that the quote expires."
                  })]
                })
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The above are just the minimum required properties to get a quote started. To see all available quote properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm/v3/properties/quotes"
            }), ". Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/properties",
              children: "properties API"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\"\n  }\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La respuesta incluirá un ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ", que utilizarás para seguir configurando la cotización. Puedes actualizar las propiedades de la cotización en cualquier momento realizando una solicitud de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quotes/{quoteId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Habilitar firmas electrónicas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para habilitar firmas electrónicas en la cotización, incluye la propiedad booleana ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_esign_enabled"
      }), " en el cuerpo de tu solicitud con un valor de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". Ten en cuenta que no podrás agregar refrendantes a través de la API, por lo que deberán agregarse en HubSpot antes de publicar la cotización. Tampoco puedes publicar una cotización con firma electrónica habilitada si has ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes#monitor-e-signature-usage",
        children: "excedido tu límite mensual de firma electrónica"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\",\n    \"hs_esign_enabled\": \"true\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Más adelante, tendrás que asociar la cotización con sus firmantes. Si bien los contactos que firman la cotización existen como contactos en HubSpot, se almacenan como un tipo de asociación independiente de los contactos. Más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#associating-quotes-with-quote-signers",
        children: "asociar cotizaciones con firmantes de cotización"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Agregar asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear una cotización completa, deberás asociarla con otros registros de CRM, como elementos de pedido, utilizando la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de asociaciones"
      }), ". La siguiente tabla muestra qué asociaciones de registros de CRM se requieren para una cotización completa y cuáles son opcionales. Sigue leyendo para obtener más información sobre cómo recuperar ID y usarlas para crear las asociaciones necesarias."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de objeto"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Requerido"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descripción"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/line-items",
              children: "Elementos de pedido"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sí"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Los bienes y/o servicios que se venden a través de la cotización. Puedes crear elementos de pedido a partir de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "productos de tu biblioteca de productos"
            }), " o crear elementos de pedido independientes personalizados."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/quotes/create-custom-quote-templates",
              children: "Plantilla de cotización"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sí"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La plantilla que representa la cotización, además de proporcionar algunos ajustes de configuración predeterminados para la cotización, como el idioma. Cada cotización se puede asociar con una plantilla."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/deals",
              children: "Negocio"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sí"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El registro de negocio para el seguimiento de los ingresos y el ciclo de vida de las ventas. Una cotización hereda valores del negocio asociado, incluidos el responsable y la moneda. Cada cotización se puede asociar a un negocio."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "Contacto"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Compradores específicos a los que te estás dirigiendo en la cotización."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "Empresa"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Una empresa específica a la que te estás dirigiendo en la cotización. Cada cotización se puede asociar a una empresa."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/discounts",
              children: "Descuentos"
            }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/fees",
              children: "cargos"
            }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/taxes",
              children: "impuestos"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Todos los descuentos, tarifas e impuestos que se apliquen al momento de pagar. Al determinar la cantidad total de la cotización, HubSpot aplica primero los descuentos, seguidos de las tarifas y los impuestos. Puedes usar el campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sort_order"
            }), " para reordenar objetos del mismo tipo. Se puede establecer en valores o porcentajes fijos estableciendo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_type"
            }), " en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FIXED"
            }), " o en ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PERCENT"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Recuperar ID para asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para realizar cada asociación, primero tendrás que recuperar el ID de cada objeto que quieras asociar. Para recuperar cada ID, realizarás una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " al punto de terminación del objeto correspondiente, que sigue el mismo patrón en cada objeto de CRM. Al realizar cada solicitud, también puedes incluir un parámetro de consulta de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propiedades"
      }), " para devolver propiedades específicas cuando sea necesario. A continuación se muestran ejemplos de solicitudes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para cada tipo de objeto."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "GET request for line items\n/crm/v3/objects/line_items?properties=name\n\nGET request for quote templates\n/crm/v3/objects/quote_template?properties=hs_name\n\nGET request for deals\n/crm/v3/objects/deals?properties=dealname\n\nGET request for contacts\n/crm/v3/objects/contacts?properties=email\n\nGET request for companies\n/crm/v3/objects/companies?properties=name\n\nGET request for discounts\ncrm/v3/objects/discounts?properties=hs_type,hs_value\n\nGET request for fees\ncrm/v3/objects/fees?properties=hs_type,hs_value\n\nGET request for taxes\ncrm/v3/objects/taxes?properties=hs_type,hs_value\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "#GET request for line items\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/line_items?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for quote templates\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/quote_templates?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for deals\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/deals?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for contacts\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/contacts?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for companies\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/companies?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for discounts\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/discounts?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for fees\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/fees?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for taxes\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/taxes?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada llamada exitosa devolverá una respuesta de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " con detalles para cada tipo de objeto obtenido. Usarás el valor en el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " para establecer asociaciones en el siguiente paso."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example quote template GET response\n{\n  \"results\": [\n    {\n      \"id\": \"235425923863\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Basic\",\n        \"hs_object_id\": \"235425923863\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"235425923864\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Modern\",\n        \"hs_object_id\": \"235425923864\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"235425923865\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Original\",\n        \"hs_object_id\": \"235425923865\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Crear asociaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con tus ID recuperados, ahora puedes realizar llamadas a la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "API de asociaciones"
      }), " para crear asociaciones."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para cada tipo de objeto que desees asociar con una cotización, deberás realizar una llamada por separado haciendo una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " utilizando la siguiente estructura de URL:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/quotes/{fromObjectId}/associations/default/{toObjectType}/{toObjectId}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID de la cotización."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["El tipo de objeto con el que te estás asociando. Por ejemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_items"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deals"
            }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "quote_template"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "El ID del objeto con el que asocias la cotización."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A continuación se muestran ejemplos de solicitudes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para cada tipo de objeto."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "PUT request to associate a line item\n/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{lineItemId}\n\nPUT request to associate a quote template\n/crm/v4/objects/quotes/{quoteId}/associations/default/quote_template/{quoteTemplateId}\n\nPUT request to associate a deal\n/crm/v4/objects/quotes/{quoteId}/associations/default/deals/{dealId}\n\nPUT request to associate contacts\n/crm/v4/objects/quotes/{quoteId}/associations/default/contacts/{contactId}\n\nPUT request to associate companies\n/crm/v4/objects/quotes/{quoteId}/associations/default/companies/{companyId}\n\nPUT request to associate discounts\n/crm/v4/objects/quotes/{quoteId}/associations/default/discounts/{discountId}\n\nPUT request to associate fees\n/crm/v4/objects/quotes/{quoteId}/associations/default/fees/{feeId}\n\nPUT request to associate taxes\n/crm/v4/objects/quotes/{quoteId}/associations/default/taxes/{taxId}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "#PUT request to associate line items\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{lineItemId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a quote template\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quote_ID}/associations/default/quote_template/{quoteTemplateId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a deal\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/deals/{dealId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate contacts\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/contacts/{contactId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a company\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/companies/{companyId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate discounts\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/discounts/{discountId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate fees\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/fees/{feeId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate taxes\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{taxId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As an example, if your quote has an ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456"
      }), ", the requests to associate the quote might include the following:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Line items (IDs: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "55555"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "66666"
          }), "):"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/objects/quotes/123456/associations/default/line_items/55555"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/objects/quotes/123456/associations/default/line_items/66666"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Quote template (ID:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "987654"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: "):"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/objects/quotes/123456/associations/default/quote_template/987654"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Deal (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "345345"
          }), "):"]
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/objects/quotes/123456/associations/default/deals/345345"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada asociación exitosa devolverá una respuesta de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " con detalles sobre la asociación. Las llamadas anteriores asociarán los objetos en ambas direcciones, con cada dirección teniendo su propio ID. Por ejemplo, si asocias la cotización con una plantilla de cotización, la respuesta describirá la asociación desde ambos extremos. En la respuesta de ejemplo a continuación, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "286"
      }), " es el ID de tipo de asociación de plantilla de cotización a cotización, y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "285"
      }), " es el ID de tipo de asociación de plantilla de cotización a cotización."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"115045534742\"\n      },\n      \"to\": {\n        \"id\": \"102848290\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 285\n      }\n    },\n    {\n      \"from\": {\n        \"id\": \"102848290\"\n      },\n      \"to\": {\n        \"id\": \"115045534742\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 286\n      }\n    }\n  ],\n  \"startedAt\": \"2023-10-12T16:48:40.624Z\",\n  \"completedAt\": \"2023-10-12T16:48:40.712Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " al asociar una cotización con una plantilla de cotización, ten en cuenta los siguientes límites:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Las plantillas de cotizaciones deben ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/quotes/create-custom-quote-templates",
            children: "crearse"
          }), " antes de que puedan asociarse con una cotización."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Una cotización solo se puede asociar con una plantilla de cotización."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Esta API no admite cotizaciones preexistentes o propuestas. Solo se puede usar el tipo de plantilla ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CUSTOMIZABLE_QUOTE_TEMPLATE"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Asociar firmantes de cotizaciones"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si estás ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#enable-e-signatures",
        children: "habilitando la cotización para firmas electrónicas"
      }), ", también deberás crear una asociación entre la cotización y los contactos que están firmando mediante el uso de una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations#associate-records-with-a-label",
        children: "etiqueta de asociación"
      }), " específica de cotización a contacto."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En lugar de utilizar los puntos de terminación de asociación predeterminados que se muestran arriba, deberás realizar una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " a la siguiente URL:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/quote/{quoteId}/associations/contact/{contactId}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En el cuerpo de la solicitud, deberás especificar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), ", como se muestra a continuación:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n[\n  {\n    \"associationCategory\": \"HUBSPOT_DEFINED\",\n    \"associationTypeId\": 702\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una cotización con asociaciones (solicitud única)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El siguiente cuerpo de solicitud creará una nueva cotización con asociaciones a una plantilla de cotización, un negocio, dos elementos de pedido y un contacto."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quote"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%",
              "height": "590.032px"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "135.672px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px",
                    "height": "135.672px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "properties"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "object"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Quote details, which can be retrieved through the ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/api/crm/properties",
                      children: "properties API"
                    }), ". Required properties are: ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), "."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The name of the quote."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The date that the quote expires."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "135.672px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "135.672px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_status"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "#update-quote-status",
                      children: "quote status"
                    }), ". Omitting this property on create will prevent users from being able to edit the quote in HubSpot."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "associations"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "array"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["The quote's associated records. For a quote to be publishable, it must have an associated deal and quote template. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/api/crm/associations#association-type-id-values",
                      children: "association type IDs"
                    }), "."]
                  })]
                })
              })]
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// POST request to https://api.hubapi.com/crm/v3/objects/quote\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-09-30\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 115045534742\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 286\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 14795354663\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 64\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 75895447\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 67\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 256143985\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 67\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Actualizar estado de cotización"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El estado de una cotización describe qué tan avanzado está en el proceso de creación, desde la configuración inicial hasta su publicación y acceso público. El estado de la cotización también puede reflejar el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/approve-quotes",
        children: "proceso de aprobación de la cotización"
      }), ", si las aprobaciones de cotizaciones están habilitadas para la cuenta. Al establecer el estado de una cotización, HubSpot completará ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#properties-set-by-quote-state",
        children: "automáticamente ciertas propiedades"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes actualizar el estado de una cotización haciendo una solicitud de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quote/{quoteId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El estado de una cotización se basa en el campo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_status"
      }), ". Ciertos estados de cotización permiten a los usuarios editar, publicar y usar la cotización en los workflows de aprobación de cotizaciones. A continuación se muestran los estados de cotización disponibles."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sin estado:"
        }), " si no se proporciona ningún valor para el campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_status"
        }), ", la cotización estará en el estado ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Mínimo"
        }), ". La cotización aparecerá en la página de índice de la herramienta Cotizaciones, pero no se podrá editar directamente. Las cotizaciones en este estado todavía se pueden usar en la automatización, como la herramienta de secuencias, y también están disponibles para analizar dentro de la herramienta de informes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DRAFT"
        }), ": permite editar la cotización en HubSpot. Este estado puede ser útil cuando la cotización no está completamente configurada o si prefieres permitir que los representantes de ventas completen el proceso de configuración de la cotización en HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "APPROVAL_NOT_NEEDED"
        }), ": publica la cotización en una URL de acceso público (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), ") sin necesidad de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "aprobación"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING_APPROVAL"
        }), ": indica que la cotización está a la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "espera de ser aprobada"
        }), " antes de que pueda ser publicada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "APPROVED"
        }), ": la cotización ha sido ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "aprobada"
        }), " y ahora está publicada en una URL de acceso público (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "REJECTED"
        }), ": indica que la cotización ha sido configurada pero no ha sido ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "aprobada"
        }), " para su publicación, y debe editarse antes de que pueda enviarse de nuevo para su aprobación."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " si ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#enabling-e-signatures",
          children: "habilitas firmas electrónicas"
        }), " en la cotización, no podrás publicarla si has superado tu ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes#monitor-e-signature-usage",
          children: "límite mensual de firmas electrónicas"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por ejemplo, la siguiente solicitud publicaría la cotización en una URL de acceso público."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PATCH request to https://api.hubapi.com/crm/v3/objects/quote/{QUOTE_ID}\n{\n  \"properties\": {\n    \"hs_status\": \"APPROVAL_NOT_NEEDED\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " por opción predeterminada, HubSpot establecerá la propiedad ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_template_type"
        }), " de la cotización en ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CUSTOMIZABLE_QUOTE_TEMPLATE"
        }), " después de que ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#update-quote-state",
          children: "actualices el estado de la cotización"
        }), ". Este tipo de plantilla es compatible con la API v3, mientras que los siguientes tipos de plantilla son plantillas preexistentes que ya no son compatibles:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "QUOTE"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROPOSAL"
          })
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propiedades establecidas por estado de cotización"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Actualizar el estado de la cotización actualizará las siguientes propiedades:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_amount"
        }), ": se calcula en función de cualquier elemento de línea asociado, impuesto, descuento y tarifa."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_domain"
        }), ": se establece a partir de la plantilla de cotización asociada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_slug"
        }), ": generado aleatoriamente si no se proporciona explícitamente uno."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_total_preference"
        }), ": se establece en función de la configuración de tu cuenta. Si no has configurado este ajuste, por opción predeterminada será el valor del campo total_first_payment."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_timezone"
        }), ": es la zona horaria predeterminada de tu cuenta de HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_locale"
        }), ": se establece a partir de la plantilla de cotización asociada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_number"
        }), ": se establece en función de la fecha y hora actuales, a menos que se proporcione una."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_language"
        }), ": se establece a partir de la plantilla de cotización asociada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_currency"
        }), ": se establece en función del negocio asociado. Si no has asociado un negocio con la cotización, esta cambiará por opción predeterminada a la moneda predeterminada de tu cuenta de HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además, las propiedades a continuación se calcularán cuando la cotización se establece en un estado publicado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_pdf_download_link"
        }), ": se rellena con una URL de un PDF para la cotización."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_locked"
        }), ": establecido en ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), ". Para modificar cualquier propiedad después de haber publicado una cotización, primero debes actualizar el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_status"
        }), " de la cotización a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DRAFT"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING_APPROVAL"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "REJECTED"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), ": la URL de acceso público de la cotización. Esta es una propiedad de solo lectura y no se puede configurar a través de la API después de la publicación."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_esign_num_signers_required"
        }), ": si has ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#enable-e-signatures",
          children: "habilitado firmas electrónicas"
        }), ", muestra el número de firmas necesarias."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alcances"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se requieren los siguientes alcances para que una aplicación cree una cotización publicable válida:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.quotes.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.quotes.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.line_items.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.line_items.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.owners.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.write"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.read"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.quote.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.line_items.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.contacts.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.deals.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.companies.read"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}