"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31316286992;
const slug = exports.slug = 'guides/apps/overview';
const title = exports.title = 'アプリ開発の概要';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Page | Developer tools and accounts | 202304';
const metaDescription = exports.metaDescription = 'HubSpotの開発者ツールと開発者アカウントについてご紹介しています。連携の開発、マーケットプレイスへのアプリ掲載、テストアカウントの作成の際にご活用ください。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E7%A8%AE%E9%A1%9E",
  "label": "アプリの種類",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA",
  "label": "非公開アプリ",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E7%A8%AE%E9%A1%9E"
}, {
  "depth": 2,
  "id": "%E3%83%97%E3%83%AD%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E5%86%85%E3%81%AE%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA%EF%BC%88%E3%83%99%E3%83%BC%E3%82%BF%E7%89%88%EF%BC%89",
  "label": "プロジェクト内の非公開アプリ（ベータ版）",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E7%A8%AE%E9%A1%9E"
}, {
  "depth": 1,
  "id": "%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA",
  "label": "公開アプリ",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E7%A8%AE%E9%A1%9E"
}, {
  "depth": 1,
  "id": "%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%95%E3%82%8C%E3%82%8B%E6%8B%A1%E5%BC%B5%E6%A9%9F%E8%83%BD%EF%BC%88%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E7%A8%AE%E9%A1%9E%E5%88%A5%EF%BC%89",
  "label": "サポートされる拡張機能（アプリの種類別）",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E7%A8%AE%E9%A1%9E"
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E9%96%8B%E7%99%BA%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AE%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%AE%E7%A8%AE%E9%A1%9E",
  "label": "アプリ開発のためのアカウントの種類",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%AA%8D%E8%A8%BC",
  "label": "認証",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%96%8B%E7%99%BA%E8%80%85%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88api%E3%82%AD%E3%83%BC",
  "label": "開発者アカウントAPIキー",
  "parent": "%E8%AA%8D%E8%A8%BC"
}, {
  "depth": 0,
  "id": "delete-a-developer-account",
  "label": "Delete a developer account",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      h3: "h3",
      ul: "ul",
      li: "li",
      h4: "h4",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "アプリ開発の概要"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリを使用することで、HubSpotアカウントへのAPI呼び出しを認証したり、Webhookを使ってイベントを通知したり、カスタムカードなどでHubSpot UIを拡張したりできます。HubSpotは、連携のニーズに応じて数種類のアプリと拡張機能を提供しています。このページでは、HubSpotで開発できるアプリと拡張機能の種類、その開発を開始する方法、開発したアプリや拡張機能をHubSpotで管理する方法について詳しくご紹介します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリの種類"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "どんな種類の連携機能を構築しようとしているかに応じて、適切な種類のアプリを選択する必要があります。以下では、開発できるアプリの種類とそれらがサポートする機能について詳しく説明します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["拡張機能の開発については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#supported-extensions-by-app-type",
        children: "以下の参照表"
      }), "で、どの種類のアプリでどの拡張機能を開発できるのかについての概要をご確認いただけます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "非公開アプリ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "非公開アプリは、1つのHubSpotアカウント用に作成することができ、1回限りのスクリプトや単一目的の拡張機能に最適です。例えば、次のような目的でHubSpotアカウント用の非公開アプリを開発できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "APIを介して新しいカスタムオブジェクトを作成する。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CSVファイルからCRMレコードをインポートする。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "カスタム自動化アクションとチャットボットでAPI要求を認証する。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
          children: "Webhookサブスクリプションを作成および編集"
        }), "します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["一般的に、非公開アプリの実装は公開アプリよりも単純です。非公開アプリはアクセストークンで認証され、HubSpot App Marketplaceには掲載不可です。どのようなときに非公開アプリを開発するかの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/hubspot-integration-choosing-private-public-hubspot-apps",
        children: "ブログ記事"
      }), "（英語）をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この種類のアプリがニーズに合っている場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "HubSpotアカウントで非公開アプリを作成する"
      }), "ことができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "プロジェクト内の非公開アプリ（ベータ版）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プロジェクトで非公開アプリを開発すると、CRMレコード用のUI拡張機能を作成できます。この手法で非公開アプリを開発する場合の違いは、プロジェクト外の非公開アプリでは作成できないUI拡張機能の作成だけがプロジェクトでサポートされることです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この種類のアプリがニーズに合っている場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/quickstart",
        children: "プロジェクトのクイックスタートガイド"
      }), "（英語）を確認し、CLIを使用してプライベートアプリとプロジェクトを作成してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "公開アプリ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "公開アプリは複数のアカウントにインストール可能です。非公開アプリで開発できるような拡張機能に加えて、公開アプリでは次のような高度な機能がサポートされます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Webhooks APIを使用してアカウント全体のイベントを通知する。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "タイムラインイベントAPIを使用して、CRMレコードでカスタム タイムライン イベントを作成する。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotでカスタムアプリ設定ページを作成する。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["公開アプリはOAuthで認証され、HubSpot App Marketplaceに掲載可能になります。どのような場合に公開アプリを開発するかの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/hubspot-integration-choosing-private-public-hubspot-apps",
        children: "ブログ記事"
      }), "（英語）をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この種類のアプリがニーズに合っている場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "アプリ開発者アカウントで公開アプリを作成する"
      }), "ことができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "サポートされる拡張機能（アプリの種類別）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "アプリの種類"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "サポートされる拡張機能"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "非公開アプリ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/video-conferencing",
                  children: "ビデオ会議拡張機能"
                })
              })
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "公開アプリ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/calling-sdk",
                  children: "電話拡張SDK"
                })
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "CRMカード"
                }), "*"]
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/timeline",
                  children: "タイムラインイベント"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: "ビデオ会議拡張機能"
              })]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "プロジェクトで開発された非公開アプリ（ベータ版）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)("ul", {
              children: (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/crm/ui-extensions/create",
                  children: "UI拡張機能"
                })
              })
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["*公開アプリで開発できるCRMカードは、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/create",
          children: "プロジェクトでUI拡張機能"
        }), "として作成できるカスタムカード（ベータ版）とは異なります。UI拡張機能には、一層高度な機能とカスタマイズ可能なコンポーネントがあります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリ開発のためのアカウントの種類"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリ開発者アカウントとテストアカウントは互いに関連しますが、その用途は異なります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ開発者アカウントは、アプリを開発し、アプリマーケットプレイスに掲載するために使用します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テストアカウントは、実際のHubSpotアカウントのデータに影響を与えずに、開発したAPIやアプリをテストするために使用します。このアカウントは、標準のHubSpotアカウント内で作成できるサンドボックスアカウントとは別のものです。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのアカウントタイプに関する詳細は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "こちらのページ"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "認証"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1つのHubSpotアカウントとのカスタム連携を開発する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "非公開アプリ"
      }), "を作成し、その", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "アクセストークン"
      }), "を使ってAPI呼び出しを認証するか、公開アプリで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), "を使用することができます。複数のHubSpotアカウントでインストールされるように設計されたアプリ、またはアプリマーケットプレイスに掲載されるアプリでは、OAuthを使用する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**2022年11月30日をもってHubSpot APIキーは廃止され、サポートも終了しています。HubSpot APIキーの使用を続けると、アカウントやデータに対するセキュリティー上のリスクが生じます。なお、HubSpotによってこの廃止段階中にお客さまのキーが無効となった可能性があります。"
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["認証には、代わりに非公開アプリのアクセストークンまたはOAuthを使用する必要があります。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "この変更"
        }), "の詳細と、非公開アプリを使用するように", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "APIキーを移行"
        }), "する方法をご確認ください。"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Webhookに登録するか、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "アプリのOAuth"
      }), "を設定する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "開発者アカウントを作成する"
      }), "必要があります。開発者アカウントでは、アプリマーケットプレイスに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "アプリを掲載"
      }), "したり、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "テストアカウントを作成"
      }), "したりすることもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "開発者アカウントAPIキー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["APIを介してアプリの設定を管理するには、開発者APIキーを使用できます。このAPIキーは、非推奨の標準APIキーとは別のものです。開発者APIキーを使用すると、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "Webhooks API"
      }), "の登録を管理したり、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline",
        children: "タイムラインイベント機能用のイベントタイプを作成、更新"
      }), "したりできます。その他全てのAPI呼び出しは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "非公開アプリのアクセストークン"
      }), "またはOAuthを使用して行う必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アプリ開発者アカウントのAPIキーにアクセスするには、次の手順で進めます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリ開発者アカウントで、上部のナビゲーションバーにある［アプリ］に移動します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上の［HubSpot APIキーを取得］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "表示されるダイアログボックスで［キーを表示］をクリックします。キーが表示されたら、キーの横にある［コピー］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "以前のAPIキーを無効にして、新しいキーを生成する場合は、［キーの再生成］をクリックします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/show_dev_api_key.webp",
        alt: "show_dev_api_key"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete a developer account"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "インストール済みのアプリ、またはマーケットプレイスに掲載されているアプリがアカウントに存在しない場合は、そのアプリ開発者アカウントを削除できます。インストール済みのアプリ、またはマーケットプレイスに掲載されているアプリが存在するアカウントの削除をご希望の場合は、カスタマーサポートまでご連絡ください。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "一旦削除したアカウントには、アクセスできなくなります。複数のHubSpotアカウントを切り替えて使用している場合は、削除済みのアカウントが表示されなくなります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot API開発者アカウントで、右上のアカウント名をクリックし、［アカウント］をクリックします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/account.jpeg",
        alt: "アカウント"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［アカウントを削除］をクリックします。インストール済みのアプリ、またはマーケットプレイスに掲載されているアプリがアカウントに存在する場合、このボタンは無効になります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Account2.jpg",
        alt: "Account2"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ダイアログボックスにアカウントIDを入力して［開発者アカウントを削除］をクリックします"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Confirm2.jpg",
        alt: "Confirm2"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}