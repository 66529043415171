"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 52099237784;
const slug = exports.slug = 'guides/cms/content/performance/lazy-loading';
const title = exports.title = 'パフォーマンス向上のためのアセットの遅延読み込み';
const name = exports.name = 'APAC JAPAN (ja) | [Developers] Guide > Optimize Performance > Lazy Loading_JA | 202109';
const metaDescription = exports.metaDescription = 'アセット（コンテンツ）の遅延読み込みとは、アセットが実際に必要になるまでアセットの読み込みを遅らせることです。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E9%81%85%E5%BB%B6%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%81%AE%E7%94%BB%E5%83%8F",
  "label": "遅延読み込みの画像",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%96%E3%83%A9%E3%82%A6%E3%82%B6%E3%83%BC%E3%81%AE%E4%BA%92%E6%8F%9B%E6%80%A7",
  "label": "ブラウザーの互換性",
  "parent": "%E9%81%85%E5%BB%B6%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%81%AE%E7%94%BB%E5%83%8F"
}, {
  "depth": 1,
  "id": "cli%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E7%94%BB%E5%83%8F%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AB%E9%81%85%E5%BB%B6%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "CLIを使用して画像フィールドに遅延読み込みを追加する",
  "parent": "%E9%81%85%E5%BB%B6%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%81%AE%E7%94%BB%E5%83%8F"
}, {
  "depth": 1,
  "id": "%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E7%94%BB%E5%83%8F%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89%E3%81%AB%E9%81%85%E5%BB%B6%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "デザインマネージャーを使用して画像フィールドに遅延読み込みを追加する",
  "parent": "%E9%81%85%E5%BB%B6%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%81%AE%E7%94%BB%E5%83%8F"
}, {
  "depth": 2,
  "id": "%E7%94%BB%E5%83%8F%E3%81%AE%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF",
  "label": "画像の読み込み",
  "parent": "%E9%81%85%E5%BB%B6%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%81%AE%E7%94%BB%E5%83%8F"
}, {
  "depth": 2,
  "id": "%E4%BD%BF%E7%94%A8%E5%8F%AF%E8%83%BD%E3%81%AA%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%82%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3",
  "label": "使用可能な読み込みオプション",
  "parent": "%E9%81%85%E5%BB%B6%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%81%AE%E7%94%BB%E5%83%8F"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      img: "img",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "パフォーマンス向上のためのアセットの遅延読み込み"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アセット（コンテンツ）の遅延読み込みとは、アセットが実際に必要になるまでアセットの読み込みを遅らせることです。ウェブの場合、これはHTMLドキュメントのアセットの位置に近い部分をユーザーが閲覧した時点でそのコンテンツがダウンロードされることを意味します。これは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/performance/speed",
        children: "ページパフォーマンスの最適化"
      }), "のために推奨されるさまざまな手法の1つです。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "遅延読み込みの画像"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["遅延読み込みオプションは、HubLタグ内で使用するカスタムモジュールの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/building-blocks/module-theme-fields#image",
          children: "画像フィールド"
        }), "と、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/design-manager/use-default-modules-in-your-template#image",
          children: "既定の画像モジュール"
        }), "で使用できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタムモジュールを作成する場合、画像フィールドに対し", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/browser-level-image-lazy-loading/",
        children: "ブラウザー組み込みの遅延読み込み"
      }), "を有効にするオプションがあります。有効にすると、コントロールを表示または非表示にするかを選択でき、制作担当者がページエディター内で読み込み動作を変更できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ブラウザーの互換性"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "loading"
      }), "属性を使用した画像の遅延読み込みは、ほとんどのChromiumベースのブラウザー（Chrome、Edge、Opera）とFirefoxでサポートされています。サポートされるブラウザーの詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://caniuse.com/#feat=loading-lazy-attr",
        children: "caniuse.com"
      }), "をご覧ください。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loading"
      }), "属性をサポートしていないブラウザーではこの属性は単に無視されるだけで、他の影響はありません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "CLIを使用して画像フィールドに遅延読み込みを追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS CLIを使用した開発時に画像の遅延読み込みを有効にするには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "ファイルの画像フィールドに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "show_loading"
      }), "キーと", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loading"
      }), "キーを追加します。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/building-blocks/module-theme-fields#image",
        children: "モジュールとテーマのフィールドタイプ"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json file\n{\n  \"id\": \"357bacfa-2bb8-e996-4589-f55e10d4f1d4\",\n  \"name\": \"image_field\",\n  \"label\": \"Image\",\n  \"required\": false,\n  \"locked\": false,\n  \"responsive\": true,\n  \"resizable\": true,\n  \"show_loading\": false,\n  \"type\": \"image\",\n  \"default\": {\n    \"size_type\": \"auto\",\n    \"src\": \"\",\n    \"alt\": null,\n    \"loading\": \"disabled\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_loading"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "制作担当者への遅延読み込みコントロールの表示／非表示を制御します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loading"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["遅延読み込みを使用するかどうかを指定します。オプションは", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"disabled\""
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"lazy\""
            }), "です。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"disabled\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["その後、以下の構文を使用して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "ファイル内でこれらの変数を参照できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set loadingAttr = module.image_field.loading != 'disabled' ? 'loading=\"{{ module.image_field.loading }}\"' : '' %}\n<img src=\"{{ module.image_field.src }}\" alt=\"{{ module.image_field.alt }}\" {{ loadingAttr }}>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "デザインマネージャーを使用して画像フィールドに遅延読み込みを追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["遅延読み込みを有効にするには、カスタムモジュールに画像フィールドを追加し、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cos-general/a-quick-tour-of-the-design-manager#inspector",
        children: "インスペクターペイン"
      }), "の［コンテンツオプション］セクションに進みます。このセクションには、［画像の読み込み］と［使用可能な読み込みオプション］の両方の選択メニューがあります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/lazy-loading/lazy-loading-image-controls-dm.png",
        alt: "デザインマネージャーの遅延読み込みコントロール"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "画像の読み込み"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["［画像の読み込み］オプションは、ブラウザーの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "loading"
      }), "属性の値を設定します。このオプションの1つに［既定］（既定オプション）があります。これは、アセットの既定のブラウザー読み込み動作です。遅延読み込みを有効にすると、画像からビューポートまでの距離が", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/browser-level-image-lazy-loading/#distance-from-viewport-thresholds",
        children: "distance-from-viewportしきい値"
      }), "に設定された一定の距離になると、画像が読み込まれます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "使用可能な読み込みオプション"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "［使用可能な読み込みオプション］は、コンテンツエディターのページ、グローバル、およびテーマの各ペイン内で、［画像の読み込み］オプションを表示および設定できるかどうかを制御します。このオプションには［コントロールを表示しない］（既定のオプション）と［全てのコントロールを表示］があります。［全てのコントロールを表示］が選択されている場合のページエディターの外観を以下に示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Developer%20Site/assets/images/lazy-loading/lazy-loading-image-controls-page-editor.png",
        alt: "ページエディターの遅延読み込みコントロール"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["その後、以下の構文を使用して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), "ファイル内でこれらの変数を参照できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set loadingAttr = module.image_field.loading != 'disabled' ? 'loading=\"{{ module.image_field.loading }}\"' : '' %}\n<img src=\"{{ module.image_field.src }}\" alt=\"{{ module.image_field.alt }}\" {{ loadingAttr }}>\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}