import I18n from 'I18n';
import { keyToNavData } from 'developer-docs-shared-components/config/navData/keys';
import { overrideLabelOnItemAndCheckBadge } from '../navUtils';
import { PageTypes, Paths } from '../../../components/RoutesPublic';
import memoize from 'react-utils/memoize';
import { navigation } from 'developer-docs-source/navigation';

/**
 * 'Guides' > 'Apps' nav config.
 */
export const appsNavConfig = memoize(() => {
  const commonPath = `${Paths.GUIDES}/apps`;
  const appsNavData = keyToNavData[commonPath];
  const nav = navigation[commonPath];
  const navigationItems = nav.items.map(item => overrideLabelOnItemAndCheckBadge(item, appsNavData, commonPath));
  const config = {
    locatorPageType: PageTypes.GUIDES,
    hasMdxOverview: true,
    sectionLabel: I18n.text('developer-docs-ui.navigation.guides.apps'),
    commonPath,
    items: navigationItems
  };
  return config;
});