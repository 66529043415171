"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611103;
const slug = exports.slug = 'guides/cms/content/global-content';
const title = exports.title = 'Conteúdo global';
const name = exports.name = 'Conteúdo global';
const metaDescription = exports.metaDescription = 'O conteúdo global pode ser reutilizado em vários modelos para criar fragmentos de informação consistentes, tais como cabeçalhos de sites, rodapés, barras laterais, logotipos ou outros componentes.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/5Cdocs/global-content/global-content-editor-4.png';
const featuredImageAltText = exports.featuredImageAltText = 'Editor de conteúdo global';
const toc = exports.toc = [{
  "depth": 0,
  "id": "vis%C3%A3o-geral",
  "label": "Visão geral",
  "parent": null
}, {
  "depth": 1,
  "id": "parciais-globais-vs.-m%C3%B3dulos-globais",
  "label": "Parciais globais vs. Módulos globais",
  "parent": "vis%C3%A3o-geral"
}, {
  "depth": 0,
  "id": "parciais-globais",
  "label": "Parciais globais",
  "parent": null
}, {
  "depth": 1,
  "id": "criar-uma-parcial-global",
  "label": "Criar uma parcial global",
  "parent": "parciais-globais"
}, {
  "depth": 1,
  "id": "adicionar-%C3%A1reas-de-arrastar-e-soltar-%C3%A0s-parciais-globais",
  "label": "Adicionar áreas de arrastar e soltar às parciais globais",
  "parent": "parciais-globais"
}, {
  "depth": 1,
  "id": "incluir-uma-parcial-global-no-seu-modelo-existente",
  "label": "Incluir uma parcial global no seu modelo existente",
  "parent": "parciais-globais"
}, {
  "depth": 0,
  "id": "m%C3%B3dulos-globais",
  "label": "Módulos globais",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      pre: "pre",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Conteúdo global"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O conteúdo global é o conteúdo que é compartilhado em diferentes partes de um site. Exemplos comuns são cabeçalhos, rodapés e barras laterais do site. Como desenvolvedor, você especificará quais componentes devem ser globais, usando parciais globais ou tornando os módulos globais. A HubSpot fornece uma experiência de edição diferente para os editores de conteúdo, o que facilita a edição do conteúdo global e a visualização das alterações nas páginas antes da publicação. Para saber mais sobre como editar o conteúdo global, consulte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
        children: "como usar o conteúdo global em vários modelos"
      }), " na Central de Conhecimento da HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/5Cdocs/global-content/global-content-editor-4.png",
        alt: "Editor de conteúdo global"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visão geral"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O conteúdo global é melhor usado para mostrar as mesmas informações em várias páginas. Por exemplo, o cabeçalho e o rodapé do seu site, como o cabeçalho na parte superior desta página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubspot-developers-header.png",
        alt: "hubspot-developers-header"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo estão alguns exemplos adicionais de áreas em que você pode usar conteúdo global:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Navegação secundária para diferentes seções do site"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Rodapés com direitos autorais (ou sub-rodapés)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Barras laterais em posts de blog (para mostrar posts recentes, listagens de autores etc.)"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Como o conteúdo global é usado em vários lugares em um site, é fundamental ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/accessibility",
          children: "projetar e criar os parciais e módulos globais de forma a promover a acessibilidade"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Parciais globais vs. Módulos globais"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Como desenvolvedor, você pode criar parciais globais e módulos globais, com algumas diferenças importantes entre eles:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Parciais globais são um tipo de modelo criado usando HTML e HubL que pode ser reutilizado em todo o site. Os tipos mais comuns de parciais são cabeçalhos de sites, barras laterais e rodapés."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os Módulos globais são módulos compostos por um ou vários conteúdos que podem ser utilizados em diversas páginas no seu site. Alguns tipos comuns de módulos globais podem ser itens como formulários de assinatura de blogs, elementos secundários de navegação e calls to action."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você deve evitar a inclusão de módulos globais em parciais globais, pois isso pode criar uma experiência de edição de conteúdo negativa."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Todos os módulos e campos dos parciais globais e módulos globais podem ser editados com facilidade no ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/cms-general/use-global-content-across-multiple-templates",
          children: "editor de conteúdo global"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parciais globais"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar uma parcial global"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma parcial global é um tipo de modelo que você pode criar localmente por meio da CLI do HubSpot usando o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#create",
        children: "comando create"
      }), ", conforme mostrado abaixo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create template <partial-file-name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando solicitado a escolher um tipo de modelo, selecione ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global partial"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Isso criará o seu modelo no diretório desejado com as anotações a seguir incluídas no arquivo HTML."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<!--\n  templateType: global_partial\n  label: Page Header\n-->\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver uma amostra de um parcial global de conteúdo, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/partials/header.html",
        children: "consulte a nossa boilerplate no GitHub."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Adicionar áreas de arrastar e soltar às parciais globais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode habilitar os recursos de arrastar e soltar conteúdo dentro de seus parciais globais adicionando tags ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ", tal como faria nos modelos de página. Consulte a nossa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "documentação sobre a área de arrastar e soltar"
      }), " para obter mais informações."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Incluir uma parcial global no seu modelo existente"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para adicionar uma parcial global a um de seus modelos existentes, use a tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global_partial"
      }), " do HubL, fazendo referência ao caminho para sua parcial. Veja um exemplo do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/layouts/base.html#L21",
        children: "CMS Boilerplate"
      }), " usando essa tag."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% global_partial path=\"../partials/header.html\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando parciais globais são produzidas, elas são envolvidas por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<div>"
      }), ". Isso é usado pelo editor de páginas para identificar que o conteúdo é uma parcial global."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<div\n  data-global-resource-path=\"cms-theme-boilerplate/templates/partials/header.html\"\n>\n  <!-- Your header.html code is output here -->\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " não use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "global_partial"
        }), " dentro do ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), " de um modelo. Fazer isso resultaria em um HTML inválido."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para a maioria das situações em que você gostaria de usar uma parcial global no cabeçalho, pode fazer mais sentido usar um módulo global com ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-head",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{%require_head%}"
          })
        }), " para inserir código personalizado no cabeçalho e ainda fornecer campos de módulo."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Módulos globais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode criar módulos globais como qualquer outro módulo usando a CLI, executando o comando ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs create"
      }), ", como mostrado abaixo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs create module <module_name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um módulo global é diferenciado pelo sinalizador ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global"
      }), " no arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " do módulo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json file\n{\n  \"css_assets\": [],\n  \"external_js\": [],\n  \"global\": true,\n  \"help_text\": \"\",\n  \"host_template_types\": [\"PAGE\"],\n  \"js_assets\": [],\n  \"other_assets\": [],\n  \"smart_type\": \"NOT_SMART\",\n  \"tags\": [],\n  \"is_available_for_new_content\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/create-and-edit-modules",
        children: "criar módulos globais no HubSpot usando o Gerenciador de design"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre como trabalhar com módulos nos seguintes recursos relacionados:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Visão geral dos módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Módulos padrão da HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Configurar módulos"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Usar módulos em modelos"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}