"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = exports.badge = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 173996449805;
const slug = exports.slug = 'guides/apps/public-apps/create-generic-webhook-subscriptions';
const title = exports.title = 'Create generic webhook subscriptions (BETA)';
const name = exports.name = 'Create generic webhook subscriptions (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to create and manage generic webhook subscriptions (BETA)';
const badge = exports.badge = 'BETA';
const toc = exports.toc = [{
  "depth": 0,
  "id": "supported-object-types",
  "label": "Supported object types",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-generic-webhook-in-a-private-app",
  "label": "Create a generic webhook in a private app",
  "parent": null
}, {
  "depth": 0,
  "id": "create-a-generic-webhook-in-a-public-app",
  "label": "Create a generic webhook in a public app",
  "parent": null
}, {
  "depth": 0,
  "id": "parse-generic-webhook-payloads",
  "label": "Parse generic webhook payloads",
  "parent": null
}, {
  "depth": 1,
  "id": "general",
  "label": "General",
  "parent": "parse-generic-webhook-payloads"
}, {
  "depth": 1,
  "id": "property-change-events",
  "label": "Property change events",
  "parent": "parse-generic-webhook-payloads"
}, {
  "depth": 1,
  "id": "merge-events",
  "label": "Merge events",
  "parent": "parse-generic-webhook-payloads"
}, {
  "depth": 1,
  "id": "association-events",
  "label": "Association events",
  "parent": "parse-generic-webhook-payloads"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      em: "em",
      img: "img",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Create and manage generic webhook subscriptions (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Generic webhooks allow you to subscribe to events in a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "public app"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private app"
      }), " to listen and react to changes to any standard CRM object (e.g., contacts, companies, etc), engagement (call, engagement, etc.), or other objects in your account."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " this feature is currently in beta and is subject to change based on testing and feedback. By using this functionality, you agree to adhere to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/hs-developer-terms",
          children: "HubSpot's Developer Terms"
        }), " & ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "Developer Beta Terms"
        }), ". You also acknowledge the risk associated with testing an unstable API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Supported object types"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following object types are currently supported when creating generic webhook subscriptions, subject to the restrictions in the alert below:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Appointment"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Call"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Cart"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Commerce payment"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Communication"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Company"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Contact"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Course"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Deal"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Discount"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Email"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Fee"
            }), "\n"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Feedback submission"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Goal target"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Invoice"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Lead"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Line item"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Listing"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Meeting event"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Note"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Order"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Partner client"
            }), "\n"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Postal mail"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Product"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Quote"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Quote template"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Service"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Subscription"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Task"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Tax"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Ticket"
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "User"
            }), "\n"]
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Creating ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "EMAIL"
          }), " subscriptions for the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_email_html"
          }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_email_subject"
          }), " properties is not currently supported."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Creating ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMMUNICATION"
          }), " subscriptions for the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs_communication_body"
          }), " property is not currently supported."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "You can also create generic webhook subscriptions for custom objects, but only when developing a private app."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Generic webhook subscriptions are not currently supported for ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "conversation.*"
          }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "contact.privacyDeletion"
          }), " events. If you want to subscribe to these events, you should use the old format for webhooks detailed in ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/app-management/webhooks#webhook-subscriptions",
            children: "this article"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a generic webhook in a private app"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a generic webhook subscription in a private app:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "settings icon"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Private Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of your private app. If you haven't created a private app yet, follow the instructions in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "this article"
        }), " to create one."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the top right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Edit app"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks"
        }), " tab."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Target URL"
        }), ", enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "URL"
        }), " that HubSpot will make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to when an event triggers."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create subscription"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right panel, configure your subscription:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Click to toggle the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Use expanded object support"
            }), " switch on."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "object types"
            }), " that you want to subscribe to, then select the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "events"
            }), " associated with those objects (e.g., created, merged, deleted, etc.) that will trigger HubSpot to send a request to the endpoint you configured."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If you select an object type that requires scopes your app hasn't been authorized for, you'll be prompted to add those scopes to your app."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you select ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Property changed"
            }), " for the event type, you can select any of the associated object properties that you want to subscribe to changes for."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Subscribe"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/create-generic-webhook-subscription-panel.png",
        alt: "create-generic-webhook-subscription-panel"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["If you don't want your webhook subscriptions to be active immediately, or if you want to delete a subscription you mistakenly created, you can hover over the webhook and manage its status, unsubscribe to delete it, or review the subscription details. If you've configured multiple webhook subscriptions, you can edit their statuses in bulk by selecting the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "checkboxes"
          }), " next to each one then clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Activate"
          }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Pause"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["When you're ready to save your changes, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Commit changes"
          }), " in the top right."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After you've configured your webhook subscriptions, HubSpot will begin sending ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " requests to your ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Target URL"
      }), " once any of the events associated with your active subscriptions are triggered."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " for each private app, HubSpot sets a concurrency limit of 10 requests when sending subscription event data. This concurrency limit is the maximum number of in-flight requests that HubSpot will attempt at a time. Each request can contain up to 100 events."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a generic webhook in a public app"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a generic webhook subscription in your HubSpot developer account:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your developer account, navigate to the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Apps"
          }), " dashboard."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of an app."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Webhooks"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Target URL"
          }), ", enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "URL"
          }), " that HubSpot will make a ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "POST"
          }), " request to when events trigger."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create subscription"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "In the right panel, configure your subscription:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "object types"
            }), " that you want to subscribe to, then select the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "events"
            }), " associated with those objects (e.g., created, merged, deleted) that will trigger HubSpot to send a request to the endpoint you configured."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you select ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Property changed"
            }), " for the event type, you can then select any of the associated object properties that you want to subscribe to changes for."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Subscribe"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/create-generic-webhook-subscription-panel.png",
        alt: "create-generic-webhook-subscription-panel"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After you've created your subscription, hover over the associated object type and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "View subscriptions"
        }), ". When you're ready to activate the subscription, hover over the event subscription and click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Activate"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/activate-generic-webhook-subscription-in-public-app-settings.png",
        alt: "activate-generic-webhook-subscription-in-public-app-settings"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After you've configured your webhook subscriptions, HubSpot will begin sending ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " requests to your ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Target URL"
      }), " once any of the events associated with your active subscriptions are triggered in an account that's installed your app."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parse generic webhook payloads"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The endpoint at the target URL that you specify in your app's webhooks settings will receive ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " requests containing JSON formatted data from HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To ensure that the requests you're getting at your webhook endpoint are actually coming from HubSpot, HubSpot populates a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X-HubSpot-Signature"
      }), " header with a SHA-256 hash built using the client secret of your app combined with details of the request. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/validating-requests",
        children: "validating request signatures"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For generic webhook payloads, the subscriptionType will follow the format of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "object.*"
      }), " (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "object.propertyChange"
      }), " instead of the previous format that was specific to each object type like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact.propertyChange"
      }), "). For creation, deletion, merge, restore, and property change events, the associated object type will be provided in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectTypeId"
      }), " field of the payload, as defined in the table ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm#object-type-id",
        children: "here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The tables below define the fields provided in different event payloads."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "General"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following fields will appear in all webhook event payloads:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the object that was created, changed, or deleted. For example, for contact event changes this is the contact ID; for companies, the company ID; for deals, the deal ID; and for conversations the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/conversations/inbox-and-messages",
              children: "thread ID"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "eventId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the event that triggered this notification. This value is not guaranteed to be unique."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the subscription that triggered a notification about the event."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The specific ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/manage-multiple-hubspot-accounts#check-your-current-account",
              children: "HubSpot account ID"
            }), " where the event occurred."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "appId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of your public app or private app. This is used in case you have multiple applications pointing to the same webhook URL."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "When this event occurred as a unix timestamp (in milliseconds)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attemptNumber"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Starting at 0, which number attempt this is to notify your service of this event. If your service times-out or throws an error as describe in the ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Retries"
            }), " section below, HubSpot will attempt to send the notification again."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "changeSource"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The source of the change. This can be any of the change sources that appear in contact property histories."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The webhook subscription type. This can be one of the following types: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.creation"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.deletion"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.merge"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.restore"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.propertyChange"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "object.associationChange"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of the object that triggered the event. See the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/understanding-the-crm#object-type-ids",
              children: "table in this article"
            }), " for a full reference of each ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "objectTypeId"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sourceId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The user or source of the triggered webhook event (e.g., a user in the HubSpot account updating a value)."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Property change events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following fields are specifically included in the payload of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "object.propertyChange"
      }), " events:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyName"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The name of the object property that changed (e.g., the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "city"
            }), " property for a contact)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyValue"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The new value of the property."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Merge events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following fields are specifically included in the payload of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "object.merge"
      }), " events:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the merge winner, which is the record that remains after the merge. In the HubSpot merge UI, this is the record on the right."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mergedObjectIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An array of IDs that represent the records that are merged into the merge winner. In the HubSpot merge UI, this is the record on the left."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "newObjectId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the record that is created as a result of the merge. This is separate from ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "primaryObjectId"
            }), " because in some cases a new record is created as a result of the merge."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numberOfPropertiesMoved"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An integer representing how many properties were transferred during the merge."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Association events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The fields below are specifically included in the payload of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "object.associationChange"
      }), " events."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the object you're associating. To find the ID values, refer to this ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/understanding-the-crm#object-type-id",
              children: "list of object type IDs,"
            }), " or for contacts, companies, deals, tickets, and notes, you can use the object name (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ID of the object you're associating the record to. To find the ID values, refer to this ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/understanding-the-crm#object-type-id",
              children: "list of object type IDs,"
            }), " or for contacts, companies, deals, tickets, and notes, you can use the object name (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of association you want to create. A full list of association types is provided ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
              children: "here"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Whether the association type was created by HubSpot (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_DEFINED"
            }), ") or by a user (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "USER_DEFINED"
            }), ")."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Association change events will trigger on both incoming and outgoing associations. This means that an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "object.associationChange"
      }), " for an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectName"
      }), " of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contact"
      }), " will trigger on a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DEAL_TO_CONTACT"
      }), " association change with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "toObjectTypeId: 0-1"
      }), " and the corresponding ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " in the payload."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "associationChange"
          }), " event is not currently supported for custom objects in private apps."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Associations with user-defined labels are not currently supported with generic webhook subscriptions."
        }), "\n"]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}