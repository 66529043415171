"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866918685;
const slug = exports.slug = 'guides/apps/authentication/intro-to-auth';
const title = exports.title = 'HubSpot APIs | Authentication methods on HubSpot';
const name = exports.name = 'vNext Docs DP - Authentication methods on HubSpot';
const metaDescription = exports.metaDescription = 'Learn more about using OAuth or private access tokens to make authenticated API requests to HubSpot.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "oauth",
  "label": "OAuth",
  "parent": null
}, {
  "depth": 0,
  "id": "private-app-access-tokens",
  "label": "Private app access tokens",
  "parent": null
}, {
  "depth": 0,
  "id": "automatic-token-deactivation",
  "label": "Automatic token deactivation",
  "parent": null
}, {
  "depth": 2,
  "id": "related-docs",
  "label": "Related docs",
  "parent": "automatic-token-deactivation"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      pre: "pre",
      code: "code",
      img: "img",
      ul: "ul",
      li: "li",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Authentication methods on HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There are two ways to authenticate calls to HubSpot's APIs: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private app"
      }), " access tokens. Below, learn more about each method and how to include it in your code for authorization."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you were previously using an API key to authenticate, learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
        children: "migrate to using a private app access token"
      }), " instead."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " integrations designed for multi-customer use or listing on the App Marketplace must be built as an app using HubSpot’s OAuth protocol"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "OAuth"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To make a request using ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "OAuth"
      }), " when building a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview",
        children: "public app"
      }), ", include the OAuth access token in the authorization header:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "/~curl --header \"Authorization: Bearer C4d***sVq\"\nhttps://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Private app access tokens"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Similar to OAuth, to make a request using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "private app"
      }), " access token, include the token in the authorization header:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "/~curl --header \"Authorization: Bearer ***-***-*********-****-****-****-************\"\nhttps://api.hubapi.com/crm/v3/objects/contacts?limit=10&archived=false\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Automatic token deactivation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To protect developers from potential security incidents, HubSpot leverages the monitoring and secret scanning capabilities provided by GitHub to detect any HubSpot authentication tokens that are publicly exposed in GitHub repositories. Any detected tokens will automatically be deactivated, and you will be notified via email and in-app notification so you can generate a new token and update your integrations to replace the revoked token."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/revoked-access-token-in-app-banner.png",
        alt: "revoked-access-token-in-app-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The affected key and token types are listed below:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Developer API keys created within your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types#app-developer-accounts",
          children: "app developer account"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Personal Access Keys used to authenticate commands in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "HubSpot CLI"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Private access tokens used to authenticate requests in a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "private app"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["SMTP tokens used for sending ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/marketing-email/how-to-use-transactional-email-in-hubspot",
          children: "transactional emails"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " you will receive an email notification for exposed SMTP tokens, but an in-app banner will ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " appear for affected tokens."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The automatic deactivation process will be mandatory on April 7, 2025. If you want to opt-in to this feature proactively, you can opt your account into the beta by following the instructions in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account-management/opt-your-hubspot-account-into-a-public-beta-feature",
        children: "this Knowledge Base article"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Related docs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "Working with OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/oauth-quickstart-guide",
        children: "OAuth Quickstart Guide"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "Private Apps"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}