"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358719;
const slug = exports.slug = 'guides/apps/authentication/oauth-quickstart-guide';
const title = exports.title = 'Working with OAuth | OAuth Quickstart Guide';
const name = exports.name = 'vNext Docs DP - OAuth Quickstart';
const metaDescription = exports.metaDescription = 'Connect to HubSpot with OAuth using our Quickstart Guide and sample node.js app.';
const position = exports.position = 4;
const toc = exports.toc = [{
  "depth": 0,
  "id": "before-you-get-started",
  "label": "Before you get started",
  "parent": null
}, {
  "depth": 1,
  "id": "how-it-works",
  "label": "How it works",
  "parent": "before-you-get-started"
}, {
  "depth": 1,
  "id": "in-this-guide",
  "label": "In this guide",
  "parent": "before-you-get-started"
}, {
  "depth": 0,
  "id": "quickstart-app",
  "label": "Quickstart app",
  "parent": null
}, {
  "depth": 0,
  "id": "getting-oauth-2.0-tokens",
  "label": "Getting OAuth 2.0 tokens",
  "parent": null
}, {
  "depth": 1,
  "id": "step-1-create-the-authorization-url-and-direct-the-user-to-hubspot-s-oauth-2.0-server",
  "label": "Step 1: Create the authorization URL and direct the user to HubSpot's OAuth 2.0 server",
  "parent": "getting-oauth-2.0-tokens"
}, {
  "depth": 3,
  "id": "examples",
  "label": "Examples",
  "parent": "getting-oauth-2.0-tokens"
}, {
  "depth": 3,
  "id": "encoding-an-additional-redirect-user-state",
  "label": "Encoding an additional redirect user state",
  "parent": "getting-oauth-2.0-tokens"
}, {
  "depth": 1,
  "id": "step-2-hubspot-prompts-user-for-consent",
  "label": "Step 2: HubSpot prompts user for consent",
  "parent": "getting-oauth-2.0-tokens"
}, {
  "depth": 1,
  "id": "step-3-handle-the-oauth-2.0-server-response",
  "label": "Step 3: Handle the OAuth 2.0 server response",
  "parent": "getting-oauth-2.0-tokens"
}, {
  "depth": 3,
  "id": "example",
  "label": "Example:",
  "parent": "getting-oauth-2.0-tokens"
}, {
  "depth": 1,
  "id": "step-4-exchange-authorization-code-for-tokens",
  "label": "Step 4: Exchange authorization code for tokens",
  "parent": "getting-oauth-2.0-tokens"
}, {
  "depth": 3,
  "id": "example",
  "label": "Example:",
  "parent": "getting-oauth-2.0-tokens"
}, {
  "depth": 0,
  "id": "using-oauth-2.0-tokens",
  "label": "Using OAuth 2.0 tokens",
  "parent": null
}, {
  "depth": 3,
  "id": "example",
  "label": "Example:",
  "parent": "using-oauth-2.0-tokens"
}, {
  "depth": 0,
  "id": "refreshing-oauth-2.0-tokens",
  "label": "Refreshing OAuth 2.0 tokens",
  "parent": null
}, {
  "depth": 3,
  "id": "example",
  "label": "Example:",
  "parent": "refreshing-oauth-2.0-tokens"
}, {
  "depth": 2,
  "id": "related-docs",
  "label": "Related docs",
  "parent": "refreshing-oauth-2.0-tokens"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      hr: "hr",
      h3: "h3",
      ol: "ol",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      h5: "h5",
      pre: "pre",
      img: "img",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "OAuth Quickstart Guide"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Before you get started"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before you can start using OAuth with HubSpot, you'll need to have:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/developers",
          children: "A developer account"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "An app"
        }), " associated with your developer account"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A HubSpot account* to install your app in (you can use an existing account or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/account-types",
          children: "create a test account"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "*You must be a Super Admin to install an app in a HubSpot account"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "How it works"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot supports the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developer.okta.com/blog/2018/04/10/oauth-authorization-code-grant-type",
        children: "OAuth 2.0 Authorization Code grant type"
      }), ", which can be broken down into four basic steps:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Your app opens a browser window to send the user to the HubSpot OAuth 2.0 server"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The user reviews the requested permissions and grants the app access"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The user is redirected back to the app with an authorization code in the query string"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The app sends a request to the OAuth 2.0 server to exchange the authorization code for an access token"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "In this guide"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#quickstart-app",
          children: "Quickstart App"
        }), ": A Node.js demo app that authenticates with HubSpot's OAuth 2.0 server"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#getting-oauth-2.0-tokens",
          children: "Getting OAuth 2.0 tokens"
        }), ": How to authorize your app with users"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#using-oauth-2.0-tokens",
          children: "Using OAuth 2.0 tokens"
        }), ": How to make queries with a token"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#refreshing-oauth-2.0-tokens",
          children: "Refreshing OAuth 2.0 tokens"
        }), ": How to use the refresh token provided by HubSpot"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Note:"
      }), " All code examples in this guide are written in JavaScript (Node.js)"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Quickstart app"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If this is your first time using OAuth authentication with HubSpot's APIs, we strongly recommend checking out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
        children: "OAuth 2.0 Quickstart App"
      }), ", written in Node.js. This sample app is designed to get you started using OAuth 2.0 as quickly as possible by demonstrating all the steps outlined below in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "Getting OAuth 2.0 tokens"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
          children: "Get the app on Github"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Getting OAuth 2.0 tokens"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Step 1: Create the authorization URL and direct the user to HubSpot's OAuth 2.0 server"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When sending a user to HubSpot's OAuth 2.0 server, the first step is creating the authorization URL. This will identify your app and define the resources (scopes) it's requesting access to on behalf of the user. The query parameters you can pass as part of an authorization URL are shown below. For more detailed information on this step, read the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "reference doc"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Required?"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Yes"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The client ID identifies your app. Find it on your app's settings page."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Yes"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/working-with-oauth#scopes",
              children: "scopes"
            }), " your application is requesting, separated by URL-encoded spaces."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contacts%20social"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Yes"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The URL that the user will be redirected to after they authorize your app for the requested scopes. ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "For production applications, https is required."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "optional_scope"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The scopes that are optional for your app, and will be dropped if the selected HubSpot portal does not have access to those products"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "state"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "No"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A unique string value that can be used to maintain the user's state when they're redirected back to your app."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WeHH_yy2irpl8UYAvv-my"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you've created your URL, start the OAuth 2.0 process by sending the user to it."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Examples"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using a server-side redirect:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Build the auth URL\nconst authUrl =\n  'https://app.hubspot.com/oauth/authorize' +\n  `?client_id=${encodeURIComponent(CLIENT_ID)}` +\n  `&scope=${encodeURIComponent(SCOPES)}` +\n  `&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;\n+`&state=${encodeURIComponent(STATE)}`;\n\n// Redirect the user\nreturn res.redirect(authUrl);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using an HTML link:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<a\n  href=\"https://app.hubspot.com/oauth/authorize?scope=contacts%20social&redirect_uri=https://www.example.com/auth-callback&client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&state=xxxxxxxx\"\n  >Install</a\n>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Encoding an additional redirect user state"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Some apps may need to redirect the user to different locations. For example, an app may wish to redirect users to different subdomains of their integration (e.g. userA.integration.com and userB.integration.com). To do so, use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "state"
      }), " parameter to encode more information about the user state:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "1. Generate and store a nonce value for the state parameter."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "2. Store the user's state in a local datastore using the nonce as its key."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "3. Include the nonce value as the state parameter in the authorization URL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "4. When the user authenticates and is redirected to your redirect URL, validate the state parameter and use it as the key to retrieve the user state that was stored."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "5. From there, redirect the user as needed (e.g. redirecting again to a user specific URL)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Step 2: HubSpot prompts user for consent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot displays a consent window to the user showing the name of your app and a short description of the HubSpot API services it's requesting permission to access. The user can then grant access to your app."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/%5BExample%5D%20Install%20Screen.png",
        alt: "[Example] Install Screen"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Note:"
      }), " The user installing the app must have access to all requested scopes. If they don't have the required access, the installation will fail and they will be directed to an error page. If a user sees this permissions error page, they'll need to have a Super Admin install the app."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your application doesn't do anything at this stage. Once access is granted, the HubSpot OAuth 2.0 server will send a request to the callback URI defined in the authorization URL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Step 3: Handle the OAuth 2.0 server response"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When the user has completed the consent prompt from Step 2, the OAuth 2.0 server sends a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the redirect URI specified in your authentication URL. If there are no issues and the user approves the access request, the request to the redirect URI will be returned with a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "code"
      }), " query parameter attached. If the user doesn't grant access, no request will be sent."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "app.get('/oauth-callback', async (req, res) => {\n  if (req.query.code) {\n    // Handle the received code\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Step 4: Exchange authorization code for tokens"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["After your app receives an authorization code from the OAuth 2.0 server, it can exchange that code for an access and refresh token by sending a URL-form encoded ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/oauth/v1/token"
      }), " with the values shown below. For more detailed information on this step, take a minute to read this ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "reference doc"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Must be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "authorization_code"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Your app's client ID"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Your app's client secret"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7c3ce02c-0f0c-4c9f-9700-92440c9bdf2d"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The redirect URI from when the user authorized your app"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "code"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The authorization code received from the OAuth 2.0 server"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5771f587-2fe7-40e8-8784-042fb4bc2c31"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const formData = {\n  grant_type: 'authorization_code',\n  client_id: CLIENT_ID,\n  client_secret: CLIENT_SECRET,\n  redirect_uri: REDIRECT_URI,\n  code: req.query.code\n};\n\nrequest.post('https://api.hubapi.com/oauth/v1/token', { form: formData }, (err, data) => {\n  // Handle the returned tokens\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The body of the token response will be JSON data with the form:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"refresh_token\": \"6f18f21e-a743-4509-b7fd-1a5e632fffa1\",\n  \"access_token\": \"CN2zlYnmLBICAQIYgZXFLyCWp1Yoy_9GMhkAgddk-zDc-H_rOad1X2s6Qv3fmG1spSY0Og0ACgJBAAADAIADAAABQhkAgddk-03q2qdkwdXbYWCoB9g3LA97OJ9I\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Note:"
      }), " The access token will expire after the number of seconds given in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " field of the response, currently 30 minutes. For information on getting a new access token, see Refreshing OAuth 2.0 tokens."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Using OAuth 2.0 tokens"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once the authorization code flow is completed, your app is authorized to make requests on behalf of the user. To do this, provide the token as a bearer token in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), " HTTP header. Specific details can be found in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "reference doc"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "request.get(\n  'https://api.hubapi.com/contacts/v1/lists/all/contacts/all?count=1',\n  {\n    headers: {\n      Authorization: `Bearer ${ACCESS_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Please note:**access tokens reflect the scopes requested from the app and do not reflect the permissions or limitations of what a user can do in their HubSpot account. For example, if a user has permissions to view only owned contacts but authorizes a request for the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.read"
        }), " scope, the resulting access token can view all contacts in the account and not only those owned by the authorizing user."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Refreshing OAuth 2.0 tokens"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["OAuth access tokens expire periodically. This is to make sure that if they're compromised, attackers will only have access for a short time. The token's lifespan in seconds is specified in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "expires_in"
      }), " field when an authorization code is exchanged for an access token."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Your app can exchange the received refresh token for a new access token by sending a URL-form encoded ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/oauth/v1/token"
      }), " with the values below. For more detailed information on this step, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "reference doc"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Example"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grant_type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Must be ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Your app's client ID"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7fff1e36-2d40-4ae1-bbb1-5266d59564fb"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "client_secret"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Your app's client secret"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "7c3ce02c-0f0c-4c9f-9700-92440c9bdf2d"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The redirect URI from when the user authorized your app"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/auth-callback"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "refresh_token"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The refresh token received when the user authorized your app"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "b9443019-30fe-4df1-a67e-3d75cbd0f726"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const formData = {\n  grant_type: 'refresh_token',\n  client_id: CLIENT_ID,\n  client_secret: CLIENT_SECRET,\n  redirect_uri: REDIRECT_URI,\n  refresh_token: REFRESH_TOKEN\n};\n\nrequest.post('https://api.hubapi.com/oauth/v1/token', { form: formData }, (err, data) => {\n  // Handle the returned tokens\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The body of the token response will be JSON data with the form:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Sample response\n{\n  \"refresh_token\": \"6f18f21e-a743-4509-b7fd-1a5e632fffa1\",\n  \"access_token\": \"CN2zlYnmLBICAQIYgZXFLyCWp1Yoy_9GMhkAgddk-zDc-H_rOad1X2s6Qv3fmG1spSY0Og0ACgJBAAADAIADAAABQhkAgddk-03q2qdkwdXbYWCoB9g3LA97OJ9I\",\n  \"expires_in\": 1800\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The new access token can then be used to make calls on behalf of the user. When the new token expires, you can follow the same steps again to retrieve a new one."
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Related docs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "Auth methods on HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "Working with OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth-tokens",
        children: "Manage tokens"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}