"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 71346450332;
const slug = exports.slug = 'guides/cms/storage/hubdb/location-based-pages';
const title = exports.title = 'Build location-based pages with HubDB';
const name = exports.name = '[new] Build location-based pages with HubDB';
const metaDescription = exports.metaDescription = 'Learn how to build location-based website pages using HubDB.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "what-s-a-location%3F",
  "label": "What's a location?",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-find-the-visitor-s-location",
  "label": "1. Find the visitor's location",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-build-a-database",
  "label": "2. Build a database",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-create-a-simple-listing-page",
  "label": "3. Create a simple listing page",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-filter-to-a-single-listing",
  "label": "4. Filter to a single listing",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-determine-the-visitor-s-location",
  "label": "5. Determine the visitor's location",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-sort-the-list",
  "label": "6. Sort the list",
  "parent": null
}, {
  "depth": 0,
  "id": "7.-filter-by-distance",
  "label": "7. Filter by distance",
  "parent": null
}, {
  "depth": 0,
  "id": "8.-add-a-map",
  "label": "8. Add a map",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      h2: "h2",
      em: "em",
      pre: "pre",
      code: "code",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Build location-based pages with HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDB supports locations as a field type, which enables you to build location-based pages on HubSpot's CMS."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In this tutorial, learn how to create a page with a map on HubSpot’s CMS using HubDB. You can reference more detailed HubDB documentation ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You’ll need:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Approximately one hour."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Some prior knowledge of HubSpot's CMS, HTML and CSS will be needed to customize your page."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "What's a location?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you create a column on a HubDB table with the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Location"
      }), " field type, you're designating this column as a place to store coordinates that point to a specific location in the world."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A location is often represented as ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "coordinates"
      }), " (a pair of decimal numbers) such as 42.36, -71.11. The first number is the latitude, or the degrees North or South of the equator. The second number is the longitude, or the degrees from the Prime Meridian. The Prime Meridian is an imaginary line starting at the North Pole, running through Greenwich, England, France, Spain, Algeria, Ghana and ending at the South Pole. Longitudes east of that line are positive, west is negative. At 180 degrees, the positive and negative degree values meet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Find the visitor's location"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["With the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://en.wikipedia.org/wiki/Global_Positioning_System",
        children: "Global Positioning System"
      }), " (GPS), you can determine the location of a visitor to your site. Mobile phone devices have GPS chips that can receive GPS signals. These devices can also use nearby Wi-Fi networks or mobile network towers to determine their location. Laptop and desktop computers typically use Wi-Fi to determine their location."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Within a page loaded in the web browser, you can use JavaScript to request a device's location."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script>\n  navigator.geolocation.getCurrentPosition(function (position) {\n    console.log(\n      \"I'm at \" + position.coords.latitude + ', ' + position.coords.longitude\n    );\n  });\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This will first ask the visitor if it's OK for the page to get the visitor's location, and then respond with the coordinates. The response may appear like the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "I'm at 42.370354299999995, -71.0765916\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Build a database"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you know the visitor's location, you can help them find different locations in their general area."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Create a new table in HubDB and add two columns: 'location' (type: Location) an 'cuisine' (type: Text)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "It should look like this:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/location-example.png",
        alt: "location-example"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can build your own database of local eateries or you can import data for lunch spots near HubSpot in Cambridge, Massachusetts. Here's a sample file you can use: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://cdn2.hubspot.net/hubfs/327485/lunch-spots.csv",
        children: "lunch-spots.csv"
      }), ". Upload the file to HubDB, and map the columns as shown."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/map-fields-location-table.png",
        alt: "map-fields-location-table"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once you finish the import, you should have a list of eateries that looks like this:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/list-of-cambridge-eateries.png",
        alt: "list-of-cambridge-eateries"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Publish your table, make a note of the table ID (the last number in the URL) and you're ready to start using your data."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Create a simple listing page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Create a simple list for this data using HubL and the HubDB functions."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the design manager, create a new template using the code editor. You can name it something like \"lunchspots.html\"."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Add this code just after ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<body>"
      }), " tag, using the table ID you noted above."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_id = YOUR_TABLE_ID %}\n\n     <table>\n        {% for row in hubdb_table_rows(table_id) %}\n          <tr>\n              <td>{{ row.name }}</td>\n              <td>{{ row.cuisine }}</td>\n          </tr>\n        {% endfor %}\n    </table>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When you preview the template, a simple list of restaurants and a description of their cuisine should appear. If nothing appears, double-check that your table ID is correct and that the table is published."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Filter to a single listing"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Your listing page can also double as a detail page. You can link the restaurant name to a page specific to that restaurant. Make sure to leave in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "set table_id..."
      }), " line here and in all other code samples on this page."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<table>\n     {% for row in hubdb_table_rows(table_id) %}\n       <tr>\n           <td><a href=\"?{{ request.query }}&row_id={{ row.hs_id }}\">{{ row.name }}</a></td>\n           <td>{{ row.cuisine }}</td>\n       </tr>\n     {% endfor %}\n</table>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each row will now be linked back to the same page with a query string parameter in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "row_id"
      }), " which is the unique ID of this row. Note the use of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "request.query"
      }), " to make sure any existing query string parameters are preserved on the current URL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Now we'll add the details:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request.query_dict['row_id'] %}\n   {% set row = hubdb_table_row(table_id, request.query_dict['row_id']) %}\n   <h1>{{ row.name }}</h1>\n   <h3>{{ row.cuisine }}</h3>\n   <p>{{ row.location['lat'] }}, {{ row.location['lon'] }}</p>\n{% else %}\n   <table>\n      {% for row in hubdb_table_rows(table_id) %}\n        <tr>\n            <td><a href=\"?{{ request.query }}&row_id={{ row.hs_id }}\">{{ row.name }}</a></td>\n            <td>{{ row.cuisine }}</td>\n        </tr>\n      {% endfor %}\n </table>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The if statement (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% if request.query_dict['row_id'] %}"
      }), ") determines whether to show the details or skip down to the block to show the listing. It will look something like this: ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/restaurant-example.png",
        alt: "restaurant-example"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Determine the visitor's location"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can also ask for the visitor's location, so you can show them how far away each restaurant is. Change your code to the following."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request.query_dict['row_id'] %}\n  {% set row = hubdb_table_row(table_id, request.query_dict['row_id']) %}\n  <h1>{{ row.name }}</h1>\n  <h3>{{ row.cuisine }}</h3>\n  <p>{{ row.location['lat'] }}, {{ row.location['lon'] }}</p>\n{% elif request.query_dict['lat'] %}\n      <table>\n      {% for row in hubdb_table_rows(table_id) %}\n        <tr>\n            <td><a href=\"?{{ request.query }}&row_id={{ row.hs_id }}\">{{ row.name }}</a></td>\n            <td>{{ row.cuisine }}</td>\n            <td>{{ row.location|geo_distance(request.query_dict['lat'], request.query_dict['lon'], \"mi\")|round(3) }} mi away</td>\n        </tr>\n      {% endfor %}\n      </table>\n{% else %}\n  Please allow us to read your location so we can show you the closest lunch spots.\n  <script>\n    navigator.geolocation.getCurrentPosition(function(position) {\n      window.location = window.location.href.split('?')[0] + \"?lat=\" + position.coords.latitude + \"&lon=\" + position.coords.longitude;\n    });\n  </script>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This code introduces an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " statement. In the default case, it asks the visitor to share their location. If they accept, it redirects the page with ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lat"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lon"
      }), " query parameters. The second time the page loads, it shows the list, now with a third column that is the calculated distance from the provided location."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Since ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "row.location"
      }), " just returns the restaurant's coordinates, HubSpot uses the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), " filter to calculate the distance. It takes the visitor's latitude, longitude and the units of the distance. The units default to meters (\"M\"), but also accept kilometers (\"KM\"), miles (\"MI\"), and feet (\"FT\"). Lastly, HubSpot round the number a bit to make it more readable."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Sort the list"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If the list got much bigger, it would be helpful to show the closest restaurants first. You can do so by adding sorting options to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows"
      }), " function. Change this section as follows:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% elif request.query_dict['lat'] %}\n      {% set params = \"orderBy=geo_distance(location,\" ~ request.query_dict['lat'] ~ \",\" ~ request.query_dict['lon'] ~ \")\" %}\n      <table>\n      {% for row in hubdb_table_rows(table_id, params) %}\n        <tr>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Now the list will be sorted by the distance between the location column and the visitor's coordinates. The key is building the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), " ordering function which ends up looking like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "orderBy=geo_distance(42.37,-71.076)"
      }), ". To sort the list in reverse order, with the restaurants the furthest away first, you can prepend a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), " (minus sign) to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "7. Filter by distance"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can filter the list by distance as well. To find all restaurants less than one mile away, use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), " as a query filter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% elif request.query_dict['lat'] %}\n      {% set params = \"orderBy=geo_distance(location,\" ~ request.query_dict['lat'] ~ \",\" ~ request.query_dict['lon'] ~ \")\" %}\n      {% set params = \"geo_distance(location,\" ~ request.query_dict['lat'] ~ \",\" ~ request.query_dict['lon'] ~ \",mi)__lt=1.0&\" ~ params %}\n\n      <table>\n      {% for row in hubdb_table_rows(table_id, params) %}\n        <tr>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This constructs another parameter to the HubDB query which looks like ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance(location,42.37,-71.076,mi)__lt=1.0"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), " query filter takes four arguments. First is the location column that you want to filter by. The next three parameters are latitude, longitude, and distance units. After the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), " operator, specify the filter operator, \"less than\" or \"lt\". Finally, after the equal sign is the value to compare with the result of the function."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "8. Add a map"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.google.com/maps/documentation/javascript/",
        children: "Google Maps APIs"
      }), " to create a visual map of the list. Replace the block of code inside the second if condition (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif request.query_dict['lat']"
      }), ") with this:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<style>\n  #map {\n    width: 100%;\n    height: 1200px;\n  }\n</style>\n<div id=\"map\" height=\"500\"></div>\n<script>\n    var map;\n    function initMap() {\n      map = new google.maps.Map(document.getElementById('map'), {\n        center: {lat: 42.3665171, lng: -71.0820328}, zoom: 16\n      });\n\n    var marker_origin = new google.maps.Marker({\n       map: map,\n       title: \"you are here\",\n       icon: { scaledSize: {width:50, height:50}, url: \"http://maps.google.com/mapfiles/kml/paddle/blu-blank.png\"},\n       position: {lat: {{ request.query_dict['lat'] }}, lng: {{ request.query_dict['lon'] }}}\n    });\n\n  {% for row in hubdb_table_rows(table_id) %}\n    var marker_{{ row.hs_id }} = new google.maps.Marker({\n        map: map,\n        position: {lat: {{ row.location[\"lat\"] }}, lng: {{ row.location[\"lon\"] }}},\n        title: '{{ row.name }}',\n        icon: { scaledSize: {width:40, height:40}, url: \"http://maps.google.com/mapfiles/kml/shapes/dining.png\"}\n    });\n\n    marker_{{ row.hs_id }}.addListener('click', function() {\n      new google.maps.InfoWindow({\n          content: '<div> {{ row.name }} is {{ row.location|geo_distance(request.query_dict['lat'], request.query_dict['lon'], \"mi\")|round(3) }} miles away</div>'\n      }).open(map, marker_{{ row.hs_id }});\n    });\n  {% endfor %}\n    }\n</script>\n<script\n  src=\"https://maps.googleapis.com/maps/api/js?key=YOUR_GOOGLE_API_KEY&callback=initMap\"\n  async\n  defer\n></script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Add in your table ID and your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.google.com/maps/documentation/javascript/get-api-key",
        children: "Google API key"
      }), " and you should now see an interactive map in place of the listing. Your map should look like this (with your blue origin marker wherever you are).", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/sample-hubdb-map.png",
        alt: "sample-hubdb-map"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["That's it! You've built a page that uses a visitor's location to find a local place to eat. The key is the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "geo_distance"
      }), " function which can be used to filter, order, and calculate the distance. This simple page could be enhanced by adding a toggle between the listing and map view, a map and more info on the details page, and perhaps a link to each restaurant's website."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If the visitor's browser cannot determine their location, or they want to look for places in a different area, you can allow them to search for a location using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.google.com/maps/documentation/geocoding/start",
        children: "Google Maps Geocoding API"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}