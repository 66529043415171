"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 180059731260;
const slug = exports.slug = 'reference/ui-components/standard-components/charts/line-chart';
const title = exports.title = 'LineChart | UI components (BETA)';
const name = exports.name = 'LineChart | UI components (BETA)';
const metaDescription = exports.metaDescription = 'Learn about the LineChart component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "colors",
  "label": "Colors",
  "parent": null
}, {
  "depth": 0,
  "id": "stacking",
  "label": "Stacking",
  "parent": null
}, {
  "depth": 0,
  "id": "guidelines",
  "label": "Guidelines",
  "parent": null
}, {
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      img: "img",
      ol: "ol",
      li: "li",
      strong: "strong",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tag,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "LineChart | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "LineChart"
      }), " component renders a line chart for visualizing data. This type of chart is best suited for time series plots or trend data. Alternatively, you can use a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/charts/bar-chart",
        children: "BarChart"
      }), " component for comparing categorical data. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/charts/overview",
        children: "charts"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To see an example of how to implement charts in a UI extension, check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview#create-line-and-bar-charts",
        children: "HubSpot's Charts example projects"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/linechart-diagram.png",
        alt: "linechart-diagram"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Title:"
        }), " the title of the chart."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Legend:"
        }), " lists the data categories with their corresponding color for readability."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Axis label:"
        }), " the label for the axis."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Data labels:"
        }), " labels for data points."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { LineChart } from '@hubspot/ui-extensions';\n\nconst salesOverTimeSample = [\n  {\n    Date: '2024-08-01',\n    Sales: 10,\n  },\n  {\n    Date: '2024-08-02',\n    Sales: 30,\n  },\n  {\n    Date: '2024-08-03',\n    Sales: 60,\n  },\n];\n\nreturn (\n  <LineChart\n    data={salesOverTimeSample}\n    axes={{\n      x: { field: 'Date', fieldType: 'datetime' },\n      y: { field: 'Sales', fieldType: 'linear' },\n    }}\n    options={{\n      title: 'My chart',\n      showLegend: true,\n      showDataLabels: true,\n      showTooltips: true,\n    }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "data"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An object containing the chart's data in an array. Data should be formatted as comma-separated objects containing key-value pairs. Data will be displayed in the order it's provided, so any sorting will need to be done before passing it to the component.While it's recommended to pre-format your data to be human-readable, you can also provide the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertyLabels"
            }), " parameter via this prop's ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            }), " to relabel data values. See example in the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#stacking",
              children: "Stacking section"
            }), " below.Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/charts/overview#formatting-data",
              children: "formatting data"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "axes"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configures the chart's axes. Using the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "x"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "y"
            }), " fields, you'll configure each axis individually with ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "field"
            }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fieldType"
            }), " parameters, along with an optional ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            }), " parameter:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "field"
                }), " (Required): the field from your dataset to use. This value will be used as the displayed axis label if no ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "label"
                }), " is specified."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fieldType"
                }), " (Required): the type of field. Can be ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "category"
                }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "datetime"
                }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "linear"
                }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/ui-components/standard-components/charts/overview#configuring-axes",
                  children: "field types"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "label"
                }), ": the axis label. If not specified, the ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "field"
                }), " value will be used."]
              })]
            }), "You can also include an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            }), " field to further configure the axes with the following options:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "groupFieldByColor"
                }), " (string): specify a field to ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#colors",
                  children: "apply color"
                }), " to for visual clarity.", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#color-grouping"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "stacking"
                }), " (boolean): ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#stacking",
                  children: "stack grouped data"
                }), " instead of always rendering separated lines."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "colors"
                }), " (object): ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "#specify-colors-per-field-value",
                  children: "specify colors for values"
                }), " in the field specified in ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "groupFieldByColor"
                }), "."]
              })]
            }), "Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/charts/overview#configuring-axes",
              children: "chart axes"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Additional chart configuration options. Options include:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), " (string): a title for the chart."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "showLegend"
                }), " (boolean): set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to display a legend above the chart. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "showDataLabels"
                }), " (boolean): set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to display labels above data points."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "showTooltips"
                }), " (boolean): set to ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " to display tooltips for data points on hover."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "colorList"
                }), " (array): specify a custom order for colors to used in the report."]
              })]
            }), "Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/standard-components/charts/overview#chart-options",
              children: "chart options"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Colors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To apply ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/charts/overview#colors",
        children: "colors"
      }), " to a chart for visual clarity, you can group data fields by color using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "groupFieldByColor"
      }), " parameter within the axes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), ". For example, the line chart below use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "groupFieldByColor"
      }), " to add colors to each ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Breakdown"
      }), " category defined in the dataset."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/linechart-with-colors.png",
        alt: "linechart-with-colors"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const VisitsPerSourceOverTime = [\n  {\n    'Session Date': '2019-09-01',\n    Breakdown: 'Direct',\n    Visits: 1277,\n  },\n  {\n    'Session Date': '2019-09-01',\n    Breakdown: 'Referrals',\n    Visits: 1882,\n  },\n  {\n    'Session Date': '2019-09-01',\n    Breakdown: 'Email',\n    Visits: 1448,\n  },\n  {\n    'Session Date': '2019-09-02',\n    Breakdown: 'Direct',\n    Visits: 1299,\n  },\n  {\n    'Session Date': '2019-09-02',\n    Breakdown: 'Referrals',\n    Visits: 1869,\n  },\n  {\n    'Session Date': '2019-09-02',\n    Breakdown: 'Email',\n    Visits: 1408,\n  },\n  {\n    'Session Date': '2019-09-03',\n    Breakdown: 'Direct',\n    Visits: 1357,\n  },\n  {\n    'Session Date': '2019-09-03',\n    Breakdown: 'Referrals',\n    Visits: 1931,\n  },\n  {\n    'Session Date': '2019-09-03',\n    Breakdown: 'Email',\n    Visits: 1391,\n  },\n];\n\nreturn (\n  <LineChart\n    data={VisitsPerSourceOverTime}\n    axes={{\n      x: { field: 'Session Date', fieldType: 'category' },\n      y: { field: 'Visits', fieldType: 'linear' },\n      options: { groupFieldByColor: 'Breakdown' },\n    }}\n    options={{ showLegend: true }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Colors will be automatically assigned in a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/charts/overview#colors",
        children: "preset order"
      }), ". To customize the color selection order, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "colorList"
      }), " field in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), " prop, then specify the colors to pick from as shown below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/line-chart-with-updated-colorlist.png",
        alt: "line-chart-with-updated-colorlist"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const VisitsPerSourceOverTime = [\n  {\n    'Session Date': '2019-09-01',\n    Breakdown: 'Direct',\n    Visits: 1277,\n  },\n  {\n    'Session Date': '2019-09-01',\n    Breakdown: 'Referrals',\n    Visits: 1882,\n  },\n  {\n    'Session Date': '2019-09-01',\n    Breakdown: 'Email',\n    Visits: 1448,\n  },\n  {\n    'Session Date': '2019-09-02',\n    Breakdown: 'Direct',\n    Visits: 1299,\n  },\n  {\n    'Session Date': '2019-09-02',\n    Breakdown: 'Referrals',\n    Visits: 1869,\n  },\n  {\n    'Session Date': '2019-09-02',\n    Breakdown: 'Email',\n    Visits: 1408,\n  },\n  {\n    'Session Date': '2019-09-03',\n    Breakdown: 'Direct',\n    Visits: 1357,\n  },\n  {\n    'Session Date': '2019-09-03',\n    Breakdown: 'Referrals',\n    Visits: 1931,\n  },\n  {\n    'Session Date': '2019-09-03',\n    Breakdown: 'Email',\n    Visits: 1391,\n  },\n];\n\nreturn (\n  <LineChart\n    data={VisitsPerSourceOverTime}\n    axes={{\n      x: { field: 'Session Date', fieldType: 'category' },\n      y: { field: 'Visits', fieldType: 'linear' },\n      options: { groupFieldByColor: 'Breakdown' },\n    }}\n    options={{\n      showLegend: true,\n      colorList: ['purple', 'green', 'darkBlue'],\n    }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Or you can specify colors to use for specific values in the field specified in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "groupFieldByColor"
      }), ". To do so, include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "colors"
      }), " field within the axes ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "options"
      }), ", then specify each field value and color, as shown below. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/standard-components/charts/overview#colors",
        children: "colors"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/linechart-with-specified-colors.png",
        alt: "linechart-with-specified-colors"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "return (\n  <LineChart\n    data={VisitsPerSourceOverTime}\n    axes={{\n      x: { field: 'Session Date', fieldType: 'category' },\n      y: { field: 'Visits', fieldType: 'linear' },\n      options: {\n        groupFieldByColor: 'Breakdown',\n        colors: {\n          Direct: 'blue',\n          Email: 'orange',\n          Referrals: 'yellow',\n        },\n      },\n    }}\n    options={{ showLegend: true }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Stacking"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "stacking"
      }), " axes option to stack grouped data for visual comparison. For example, the following line chart displays website visits over time broken down by source. To help users compare data within each breakdown category, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "stacking"
      }), " has been set to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023_2024/line-chart-stacked-data.png?width=492&height=417&name=line-chart-stacked-data.png",
        alt: "line-chart-stacked-data"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const visitsPerSourceOverTime = [\n  {\n    sessionDate: '2019-09-01',\n    breakdown: 'direct',\n    visits: 1277,\n  },\n  {\n    sessionDate: '2019-09-01',\n    breakdown: 'referrals',\n    visits: 1882,\n  },\n  {\n    sessionDate: '2019-09-01',\n    breakdown: 'email',\n    visits: 1448,\n  },\n  {\n    sessionDate: '2019-09-02',\n    breakdown: 'direct',\n    visits: 1299,\n  },\n  {\n    sessionDate: '2019-09-02',\n    breakdown: 'referrals',\n    visits: 1869,\n  },\n  {\n    sessionDate: '2019-09-02',\n    breakdown: 'email',\n    visits: 1408,\n  },\n  {\n    sessionDate: '2019-09-03',\n    breakdown: 'direct',\n    visits: 1357,\n  },\n  {\n    sessionDate: '2019-09-03',\n    breakdown: 'referrals',\n    visits: 1931,\n  },\n  {\n    sessionDate: '2019-09-03',\n    breakdown: 'email',\n    visits: 1391,\n  },\n];\n\nreturn (\n  <LineChart\n    data={{\n      data: visitsPerSourceOverTime,\n      options: {\n        propertyLabels: {\n          breakdown: {\n            direct: 'Direct',\n            email: 'Email',\n            referrals: 'Referrals',\n          },\n        },\n      },\n    }}\n    axes={{\n      x: { field: 'sessionDate', fieldType: 'category', label: 'Session Date' },\n      y: { field: 'visits', fieldType: 'linear', label: 'Visits' },\n      options: { groupFieldByColor: 'breakdown', stacking: true },\n    }}\n    options={{ showLegend: true }}\n  />\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Because the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "breakdown"
        }), " field data is all lowercase (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "direct"
        }), "), the data prop includes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "propertyLabels"
        }), " in its ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), " to convert the labels. Note that the data prop formatting is slightly different to accommodate both the dataset and its ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "options"
        }), ". Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/charts/overview#data-options",
          children: "data options"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Guidelines"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " title your data categories with human-readable text so they are easy to understand."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " use sentence-casing for the data categories and chart title (only first letter capitalized)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " sort your data in ascending/descending order of your x-axis field to prevent unordered rendering prior to passing it to a charting component. If you intend to display information over time, your data will be displayed in the order you provide it."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " display the chart legend if you’re graphing more than one category of data. This prevents your users from having to rely only on color to identify different data on your chart."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DO:"
        }), " for readability, use larger surfaces to showcase charts, such as the record page middle column. Avoid using charts with many data points on smaller surfaces such as the preview panel or sidebar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON’T:"
        }), " use more than 14 data categories unless it cannot be avoided for your use case."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "DON’T:"
        }), " use the same colors to indicate different data categories."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/charts/line-chart",
          children: "LineChart"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/statistics",
          children: "Statistics"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/table",
          children: "Table"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}