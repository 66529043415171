"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35219164725;
const slug = exports.slug = 'guides/cms/content/data-driven-content/emails-with-programmable-content';
const title = exports.title = 'プログラム可能なコンテンツを使用してEメールを作成する';
const name = exports.name = 'プログラム可能なコンテンツを使用してEメールを作成する';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E9%80%81%E4%BF%A1%E3%81%AE%E4%B8%8A%E9%99%90",
  "label": "Eメール送信の上限",
  "parent": null
}, {
  "depth": 0,
  "id": "1.%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9E%E3%83%96%E3%83%ABe%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AE%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "1.プログラマブルEメールのモジュールを作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%A8%99%E6%BA%96%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88",
  "label": "標準オブジェクト",
  "parent": "1.%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9E%E3%83%96%E3%83%ABe%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AE%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88",
  "label": "カスタムオブジェクト",
  "parent": "1.%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9E%E3%83%96%E3%83%ABe%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AE%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "hubdb",
  "label": "HubDB",
  "parent": "1.%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9E%E3%83%96%E3%83%ABe%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AE%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "2.%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92e%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AB%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "2.モジュールをEメールに追加する",
  "parent": null
}, {
  "depth": 0,
  "id": "hubdb%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AE%E3%83%81%E3%83%A5%E3%83%BC%E3%83%88%E3%83%AA%E3%82%A2%E3%83%AB",
  "label": "HubDBに関するその他のチュートリアル",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot%E3%82%A2%E3%82%AB%E3%83%87%E3%83%9F%E3%83%BC",
  "label": "HubSpotアカデミー",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      h2: "h2",
      code: "code",
      img: "img",
      h3: "h3",
      pre: "pre",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "プログラム可能なコンテンツを使用してEメールを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["現在のところ、この機能はベータ版として提供されています。この機能を使用することにより、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "開発者ベータ規約"
        }), "（英語）に同意したことになります。このガイドでは、このベータ版でのみ使用可能な機能について説明します。ベータ版を使用するには", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-programmable-emails",
          children: "テンプレートまたはモジュールでオプトイン"
        }), "します。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プログラム可能なコンテンツを使用すると、HubLを使用してHubSpotアカウントからデータを取得し、Eメールをパーソナライズできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "プログラマブルEメールで使用できるデータソースは、HubSpotサブスクリプションに応じて異なります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "__Marketing Hub"
        }), " __Professionalサブスクリプションをご利用の場合、標準CRMオブジェクト（コンタクト、会社、製品など）のデータを使用できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "__Marketing Hub"
        }), " __Enterpriseサブスクリプションをご利用の場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "HubDB"
        }), "テーブルや", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/use-custom-objects",
          children: "カスタムオブジェクト"
        }), "などの構造化データソースを使用することもできます。このデータは、受信者のコンタクトプロパティーに基づいて絞り込むことができます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、不動産のウェブサイトに、探している物件の条件を入力するフォームを設置する場合を考えてみましょう。見込み客がフォームを送信した際に、その入力内容に基づいて見込み客が関心を持ちそうな物件を案内するEメールを送信するよう、ワークフローを設定することができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eメール送信の上限"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["プログラマブルEメールには、CRM HubL関数", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "crm_object"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "crm_objects"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: "crm_associations"
      }), "を含めることができますが、これらの関数を含むEメールには、送信先の受信者数に基づく次の制限が適用されます。"]
    }), "\n", (0, _jsxRuntime.jsx)("table", {
      children: (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("strong", {
              children: "受信者数合計"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("strong", {
              children: "CRM HubL関数の最大数"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "500,000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "1"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "250,000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "2"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "165,000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "3"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "125,000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "4"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "100,000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "5"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記の制限のいずれかに達する、または超過するEメールを送信すると、Eメールの送信が遅延またはキャンセルされます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記の制限の他に、以下の注意事項にも留意してください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "プログラマブルEメールを複製した場合、元のEメールがまだ処理状態にある間は送信できません。各Eメールの送信間隔として少なくとも1時間を空ける必要があります。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubL関数", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        }), "が含まれるプログラマブルEメールでは、A/Bテストを実施できません。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1.プログラマブルEメールのモジュールを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDBまたはカスタムオブジェクトのデータにアクセスするためのEメールモジュールを作成するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["HubSpotアカウントにて、［マーケティング］*", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "*＞［ファイルとテンプレート］**"
          }), "＞［デザインツール］****の順に進みます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["左上の［ファイル］*", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "*をクリックし、［新規ファイル］**"
          }), "を選択します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["表示されるダイアログボックスで、ファイルタイプとして［モジュール］*", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "*を選択し、［次へ］**"
          }), "をクリックします。次に、［Eメール］*", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "*チェックボックスをオンにして、ファイルの"
          }), "名前**を入力します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［作成］****をクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "作成したモジュールで、プログラマブルEメールを有効にするには、次の手順に従います。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "右側のインスペクターで、**「モジュールをプログラム可能なEメールのベータに対応させる」**スイッチをオンに切り替えます。"
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/programmable-email-switch0.png",
            alt: "programmable-email-switch0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["また、コード化されたEメールテンプレートでは、ファイルの先頭に", (0, _jsxRuntime.jsx)(_components.code, {
            children: "isEnabledForEmailV3Rendering: true"
          }), "を追加することで、プログラマブルEメールを有効にできます。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hs-fs/hubfs/Imported%20sitepage%20images/enable-design-manager-template-for-programmable-email.png?width=510&name=enable-design-manager-template-for-programmable-email.png",
            alt: "enable-design-manager-template-for-programmable-email"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "モジュールを作成したら、CRMのデータにアクセスするためのコードを追加します。次の例は、さまざまなデータソースからクエリーを実行する方法を示しています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "標準オブジェクト"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "crm_object"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "crm_objects"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: "crm_associations"
      }), "などのHubL関数を使用して、コンタクト、会社、製品など、アカウント内の標準オブジェクトのデータを照会できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下のコードは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), " HubL関数を使用して、IDが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2444498793"
      }), "の製品のデータを照会し、名前、説明、価格をレンダリングします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set product = crm_object(\"product\", 2444498793, \"name,description,price\") %}\n\n<div>\n  <p>\n    <span>Name: </span>\n    <span>{{ product.name }}</span>\n  </p>\n  <p>\n    <span>Description: </span>\n    <span>{{ product.description }}</span>\n  </p>\n  <p>\n    <span>Price: </span>\n    <span>{{ product.price }}</span>\n  </p>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "カスタムオブジェクト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "__**Marketing Hub **Enterpriseアカウントをお持ちの場合は、アカウント内に作成したカスタムオブジェクトのデータを照会できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下のコードは、「Property」__という名前のカスタムオブジェクトからデータを取得し、カスタムオブジェクトのプロパティーに格納されている値（場所、価格など）を返します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下の例では、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "HubL関数"
      }), (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm_objects"
      }), "を呼び出す際に、最初の引数としてカスタムオブジェクトの完全修飾名を使用しています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["完全修飾名はHubSpotアカウントID（プレフィックスは", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), "）の後にアンダースコアとカスタムオブジェクトの名前（小文字、複数形）が続きます（例：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), "）。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["オブジェクトの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fullyQualifiedName"
        }), "を取得するには、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/custom-objects",
          children: "CRMオブジェクトスキーマAPI"
        }), "に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを送信します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set real_estate_listings = crm_objects(\"p2990812_properties\", \"\", \"listing_name,location, price, address, type\") %}\n\n{% for home in real_estate_listings.results %}\n    {{ home.address}} <br>\n\t{{ home.price }} <br>\n\t<img\n\talt=\"{{ home.name }}\"\n\tsrc=\"{{ home.hero_image }}\"\n\tstyle=\"outline: none; max-width: 100%;\"\n\twidth=\"260px\"\n\t/>\n\t<br>\n\t<hr>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**カスタムオブジェクトの名前にハイフンが含まれている場合（例：", (0, _jsxRuntime.jsx)(_components.em, {
          children: "My-Custom-Object"
        }), "）、このオブジェクトのプロパティーはプログラマブルEメールでレンダリング", (0, _jsxRuntime.jsx)("u", {
          children: "できません"
        }), "。ハイフンを省略してカスタムオブジェクトを", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-custom-objects",
          children: "HubSpotアカウントで直接"
        }), "作成し直すか、または", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "カスタムオブジェクトAPI"
        }), "を使用することができます。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["返されるデータを受信者ごとに絞り込むには、受信者のコンタクトプロパティーで結果を絞り込むための", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), "パラメーターを追加します。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#get-table-rows",
        children: "フィルターオプションの一覧"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set query = \"price__lte=\"~contact.budget_max|int~\"&price__gte=\"~contact.budget_min|int~\"&city=\"~contact.city\"&order=listing_name\" %}\n{% set real_estate_listings = crm_objects(\"p2990812_Property\", query, \"listing_name,location, price, address, type\") %}\n{% for home in real_estate_listings.results %}\n\n  ...\n\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "__Marketing Hub"
      }), " __Enterpriseアカウントをお持ちの場合は、EメールでHubDBテーブルのデータを使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下のコードでは、HubL関数", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-rows",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_rows"
        })
      }), "を使用してテーブルから全てのデータを取得します。これにより、Eメールに全ての不動産物件が一覧表示され、各物件の画像と併せてその詳細が出力されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set real_estate_listings = hubdb_table_rows(1234567) %}\n\n{% for home in real_estate_listings%}\n    {{ home.address}} <br>\n\t{{ home.price }} <br>\n\t<img\n\talt=\"{{ home.name }}\"\n\tsrc=\"{{ home.hero_image.url }}\"\n\tstyle=\"outline: none; max-width: 100%;\"\n\twidth=\"260px\"\n\t/>\n\t<br>\n\t<hr>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["返されるデータを受信者ごとに絞り込むには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), "パラメーターを追加します。このパラメーターで指定したコンタクトプロパティーを基準に、結果を絞り込むことができます。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb#filter-returned-rows",
        children: "フィルターオプションの一覧"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set query = \"price__lte=\"~contact.budget_max|int~\"&price__gte=\"~contact.budget_min|int~\"&persona=\"~contact.hs_persona.value~\"&order=listing_name\" %}\n\n{% for home in real_estate_listings %}\n\n\t...\n\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["上記の例では、「Budget max（予算上限）」**のコンタクトプロパティーを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.budget_max"
        }), "で参照し、「Persona（ペルソナ）」**を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.hs_persona.value"
        }), "で参照しています。これは、「Persona（ペルソナ）」__が", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/property-field-types-in-hubspot",
          children: "列挙型プロパティー"
        }), "であり、プロパティーの値を解析するには", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".value"
        }), "を追加する必要があるためです。一方、他のプロパティータイプには追加する必要はありません。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2.モジュールをEメールに追加する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "公開された状態のモジュールを、ドラッグ＆ドロップEメールの本文に追加します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["HubSpotアカウントで、［マーケティング］*", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "*＞［Eメール］**"
        }), "に移動します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["作成した", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eメール"
        }), "を選択します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左のサイドバーにある［コンテンツ］__で、［その他］****をクリックします。プログラマブルEメールのモジュールをEメールの本文にドラッグします。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/drag-and-drop-email-editor-more-modules.png",
        alt: "drag-and-drop-email-editor-more-modules"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "特定のコンタクトプロパティーを基準にデータを絞り込むようにモジュールを設定した場合、Eメールのプレビューは空白になります。これは、Eメールツールが特定のコンタクトを基準にEメールのプレビューを表示するようには設定されていないためです。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "特定のコンタクトに対してEメールがどのように表示されるのかをプレビューで確認するには、次の手順に従います。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["右上の［アクション］*", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "*をクリックし、［プレビュー］**"
        }), "を選択します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/email-actions-menu0.png",
        alt: "email-actions-menu0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["次の画面で、［特定のコンタクトとしてプレビュー］*", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "*ドロップダウンメニューをクリックし、プレビュー対象の"
        }), "コンタクト**を選択します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/preview-email-as-specific-contact0.png",
        alt: "preview-email-as-specific-contact0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これで、受信者のコンタクトプロパティーを基に、その受信者に関連するデータのみが表示されるようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/preview%20window.png",
        alt: "Eメールプレビューのスクリーンショット"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eメールテンプレートまたはモジュールに、HubLが未解決のカスタムコードが含まれている場合は、その位置にプレースホルダーモジュールが表示されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/placeholder-module-in-programmable-email.png",
        alt: "placeholder-module-in-programmable-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "設定した基準を満たすHubDBの行やカスタムオブジェクトのレコードがない場合に備え、常にフォールバックデータが送信されるようにすることが重要です。そうでない場合、Eメールが空白のまま送信される恐れがあります。"
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "既存のテンプレートでは、このベータ版によって問題が発生する恐れがあります。Eメールを公開して送信する前に、全ての変更を十分に検証することが重要です。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDBに関するその他のチュートリアル"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "HubDBを使って動的ページを作成する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "複数のHubDBテーブルを結合する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "HubDBを使用して動的なチーム メンバー ページを作成する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "HubDBを使ってマルチレベルの動的ページを作成する方法"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpotアカデミー"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/lessons/other_data_sources_hubdb_and_custom_objects",
          children: "CMS HubでHubDBとカスタムオブジェクトを使用する"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}