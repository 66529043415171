"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611096;
const slug = exports.slug = 'reference/cms/modules/using-modules-in-templates';
const title = exports.title = 'Usar módulos em modelos';
const name = exports.name = '[novo] Usando módulos em modelos';
const metaDescription = exports.metaDescription = 'Os módulos são áreas editáveis das páginas ou e-mails da HubSpot. Embora você possa adicionar módulos a um layout de modelo usando o Construtor de modelos de arrastar e soltar, também pode criar módulos usando o HubL. Os módulos do HubL podem ser definidos em um arquivo de modelo codificado, um módulo de modelo do HubL ou um módulo de conteúdo de blog. Veja abaixo uma lista abrangente de módulos e seus parâmetros.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "sintaxe-de-m%C3%B3dulo-b%C3%A1sico",
  "label": "Sintaxe de módulo básico",
  "parent": null
}, {
  "depth": 0,
  "id": "passar-dicts-para-par%C3%A2metros-do-m%C3%B3dulo",
  "label": "Passar dicts para parâmetros do módulo",
  "parent": null
}, {
  "depth": 1,
  "id": "passar-campos-com-par%C3%A2metros-associados-ao-dnd",
  "label": "Passar campos com parâmetros associados ao dnd",
  "parent": "passar-dicts-para-par%C3%A2metros-do-m%C3%B3dulo"
}, {
  "depth": 0,
  "id": "definir-valores-padr%C3%A3o-em-n%C3%ADvel-de-modelo-para-campos",
  "label": "Definir valores padrão em nível de modelo para campos",
  "parent": null
}, {
  "depth": 1,
  "id": "definir-valores-padr%C3%A3o-para-grupos-de-campos-aninhados",
  "label": "Definir valores padrão para grupos de campos aninhados",
  "parent": "definir-valores-padr%C3%A3o-em-n%C3%ADvel-de-modelo-para-campos"
}, {
  "depth": 1,
  "id": "definir-valores-padr%C3%A3o-para-campos-que-se-repetem",
  "label": "Definir valores padrão para campos que se repetem",
  "parent": "definir-valores-padr%C3%A3o-em-n%C3%ADvel-de-modelo-para-campos"
}, {
  "depth": 1,
  "id": "definir-valores-padr%C3%A3o-para-grupos-de-campos-que-se-repetem",
  "label": "Definir valores padrão para grupos de campos que se repetem",
  "parent": "definir-valores-padr%C3%A3o-em-n%C3%ADvel-de-modelo-para-campos"
}, {
  "depth": 1,
  "id": "definir-valores-padr%C3%A3o-para-campos-de-estilo",
  "label": "Definir valores padrão para campos de estilo",
  "parent": "definir-valores-padr%C3%A3o-em-n%C3%ADvel-de-modelo-para-campos"
}, {
  "depth": 0,
  "id": "sintaxe-de-bloco",
  "label": "Sintaxe de bloco",
  "parent": null
}, {
  "depth": 0,
  "id": "content_attribute",
  "label": "content_attribute",
  "parent": null
}, {
  "depth": 0,
  "id": "par%C3%A2metros-dispon%C3%ADveis-para-todos-os-m%C3%B3dulos",
  "label": "Parâmetros disponíveis para todos os módulos",
  "parent": null
}, {
  "depth": 0,
  "id": "par%C3%A2metros-baseados-em-campo",
  "label": "Parâmetros baseados em campo",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Usar módulos em modelos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os módulos podem ser adicionados diretamente a um modelo ou a páginas individuais com ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "áreas de arrastar e soltar"
      }), " e colunas flexíveis. Quando um módulo é adicionado a um modelo, ele aparece nesse local por padrão. Módulos em áreas de arrastar e soltar e colunas flexíveis podem ser movidos e removidos, e outros módulos podem ser adicionados ao redor deles. Estas são instâncias de módulo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois que um módulo foi definido, você pode obter seus valores de campo em nível de modelo por meio do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context#retrieving-parameters-from-modules-already-rendered-on-the-template",
        children: "dict content.widgets"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sintaxe de módulo básico"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As tags de módulo do HubL são delimitadas por ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% %}"
      }), " e devem especificar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ", um nome exclusivo e o caminho do módulo no gerenciador de design. Um módulo também pode incluir parâmetros para configurações adicionais."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nome do módulo:"
        }), " dá ao módulo uma identidade exclusiva no contexto do modelo.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "O nome deve estar entre aspas após o tipo de módulo e deve usar sublinhados em vez de espaços ou traços."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Esse nome é usado para corresponder ao conteúdo definido no editor de página/e-mail com a tag de módulo do HubL correspondente. Por exemplo, se você codificar uma tag de módulo do HubL com o mesmo nome em duas áreas diferentes de um modelo, os usuários terão apenas um módulo para editar no editor, mas as alterações nesse módulo serão aplicadas em ambos os locais."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Caminho:"
        }), " dependendo da tag, define a localização do módulo no gerenciador de design.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            }), " significa a raiz da unidade atual;"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "./"
            }), " significa o diretório atual;"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "../"
            }), " significa o diretório principal do diretório atual."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["O caminho dos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "módulos padrão do HubSpot"
        }), " sempre começa com ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot/"
        }), " seguido do tipo de módulo."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Parâmetros: **configurações adicionais para a instância do módulo que especificam seu comportamento e como ele é renderizado. Inclui valores padrão em nível de modelo para campos de módulo.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Os parâmetros são pares de chaves e valores separados por vírgulas."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Os parâmetros têm tipos diferentes, incluindo: string, booleano, inteiro, enumeração e objeto de lista JSON. Os valores dos parâmetros de string devem estar entre aspas simples ou duplas, enquanto que os parâmetros booleanos não exigem aspas para seus valores ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            }), ". Saiba mais sobre os ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/modules/using-modules-in-templates#parameters-available-for-all-modules",
              children: "parâmetros que estão disponíveis para todos os módulos"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Observe que não há validação no editor para valores de campo em relação às configurações de campo do módulo. Por exemplo, se o módulo tiver um campo de número que tenha um valor mínimo definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), " e você passar um ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " para o parâmetro, você não verá um aviso de que o valor é inválido."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Basic syntax #}\n{% module \"unique_module_name\" path=\"@hubspot/module_type\",\n  parameterString='String parameter value',\n  parameterBoolean=True\n%}\n\n{# Sample of a default HubSpot text module #}\n{% module \"job_title\" path=\"@hubspot/text\",\n  label=\"Job Title\",\n  value=\"Chief Morale Officer\"\n%}\n\n{# Sample of a custom module #}\n{% module \"faqs\" path=\"/myWebsite/modules/faq_module\",\n  label=\"Custom FAQ Module\"\n  faq_group_title=\"Commonly Asked Questions\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Passar dicts para parâmetros do módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para módulos com campos que esperam dicts, você pode passá-los como faria com outros parâmetros. Se for mais simples para você, ou caso planeje reutilizar os valores, você poderá definir o dict como uma variável e passar a variável para o parâmetro."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_buttons\",\n  path=\"@hubspot/social_sharing\",\n  email={\n    \"default\": true,\n    \"enabled\": false,\n    \"img_src\": \"https://...\"\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Passar campos com parâmetros associados ao dnd"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "As tags de arrastar e soltar"
      }), ", como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ", vêm com um conjunto de parâmetros padrão, como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), ". Embora o gerenciador de design impeça a criação de novos campos que usem um desses parâmetros reservados, os módulos criados antes da introdução das tags de arrastar e soltar podem já usar um parâmetro reservado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para corrigir isso, você pode usar o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields"
      }), ". Assim como você passaria dados de campo para um grupo, você pode passar o nome do campo como uma chave no objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields"
      }), ". O valor deve ser consistente com o formato esperado pelo tipo de campo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"main_content\"%}\n     {% dnd_section %}\n        {% dnd_column %}\n          {% dnd_row %}\n            {% dnd_module path=\"@hubspot/divider\",\n               fields={width: \"90\"}\n            %}\n            {% end_dnd_module %}\n        {% end_dnd_row %}\n      {%end_dnd_column%}\n    {% end_dnd_section %}\n  {% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Definir valores padrão em nível de modelo para campos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode definir valores padrão para campos de módulo em nível de modelo incluindo parâmetros nas tags ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ". Abaixo, saiba como definir valores de campo padrão em grupos de campos aninhados, campos que se repetem, grupos de campos que se repetem e campos de estilo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir valores padrão para grupos de campos aninhados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja abaixo um exemplo de um módulo de arrastar e soltar personalizado com um grupo de campos personalizados ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "style"
      }), " contendo outros grupos de campos aninhados. Compare a configuração em nível de modelo com a aparência desse mesmo grupo no gerenciador de design."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_module\n path=\"/Nested_Module.module\"\n style={\n  \"group\":{\n  \"section\":{\n   \"color_field\":{\n   \"color\" : \"#000\",\n   \"opacity\" : 100\n    }\n   }\n  }\n }\n%}\n{% end_dnd_module %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/multi-level%20field%20nesting%20.png",
            alt: "aninhamento de campos multinível"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir valores padrão para campos que se repetem"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode definir valores padrão em nível de modelo para campos que se repetem passando um array para o parâmetro do campo. Os itens do array devem estar no formato esperado com base no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#field-based-parameters",
        children: "tipo de campo"
      }), ". Por exemplo:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um campo de texto simples somente espera uma string."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um campo repetidor de imagem espera um objeto de campo de imagem. Isso se aplica a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#field-based-parameters",
          children: "todos os outros tipos de campo"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module path='../modules/recipe_card.module',\n  ingredients=[\"Eggs\",\"Ham\",\"Cheese\"]\n%}\n\n{% module \"my_repeater_module\" path=\"/img_repeater_module\", label=\"img_repeater_module\",\nimage=[\n  {\n    \"src\" : \"https://cdn2.hubspot.net/hubfs/428357/Developer%20Site/assets/logo/Developers-LOGO.svg\",\n    \"alt\" : \"HubSpot Developers\",\n    \"width\" : 254,\n    \"height\" : 31\n  },{\n    \"src\" : \"https://www.hubspot.com/hs-fs/hub/53/file-733888614-jpg/assets/hubspot.com/about/management/dharmesh-home.jpg\",\n    \"alt\" : \"Dharmesh\",\n    \"width\" : 394,\n    \"height\" : 394\n  }\n]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir valores padrão para grupos de campos que se repetem"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os módulos que contêm grupos de campos que se repetem - como você pode ver em um módulo de apresentação de slides ou de perguntas frequentes - podem ter um padrão em nível de modelo definido para esses grupos. Para fazer isso, você passa um array de objetos para o parâmetro do seu grupo de campos. Os pares de chave e valor do objeto são os nomes dos campos e seus valores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module path='../modules/slideshow.module',\n  slides=[\n    {\n      \"caption\":\"Cute dog looking up\",\n      \"image_url\":\"http://example.com/image.jpg\",\n    },\n    {\n      \"caption\":\"Cuter cat not looking amused\",\n      \"image_url\":\"http://example.com/image2.jpg\",\n    }\n  ]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Definir valores padrão para campos de estilo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["você pode definir valores padrão de forma explícita para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "campos de estilo"
      }), " usando o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "styles"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Isso funciona exatamente como outros grupos, nos quais o parâmetro é o nome do grupo. Você passa um objeto para esse parâmetro com todos os campos que deseja definir."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n    path=\"./path/to/module\",\n    styles={\n      \"background_color\":{\n          \"color\":\"#123\",\n          \"opacity\":50\n       }\n    }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sintaxe de bloco"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Embora a maioria dos módulos tenha parâmetros que controlam o conteúdo padrão, pode haver situações em que você precise adicionar blocos de código grandes ao conteúdo padrão de um módulo. Por exemplo, você pode querer incluir um grande bloco de HTML como conteúdo padrão para um módulo HTML ou rich text. Em vez de tentar gravar esse código em um parâmetro de valor, você pode usar a sintaxe de bloco do HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module_block module \"my_rich_text_module\" path=\"/My Rich Text Field Module\",\n  label=\"My Rich Text Field Module\"\n%}\n    {% module_attribute \"rich_text_field_variable\" %}\n       <div>My HTML block</div>\n    {% end_module_attribute %}\n{% end_module_block %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Antes da sintaxe ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module_block"
        }), ", foi usado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), ". Ele segue o mesmo padrão, mas as tags de abertura foram ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_attribute"
        }), ". As tags de fechamento foram ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "end_widget_attribute"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "end_widget_block"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["A sintaxe ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), " está obsoleta, mas você não precisa atualizar o código antigo."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O parâmetro que segue imediatamente ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_block"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " (obsoleto) é o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em quase toda a nossa documentação, você descobrirá que usamos ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ". Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "Módulos V2 do HubSpot"
      }), " são módulos normais, como o que você pode criar. Portanto, não há mais a necessidade de usar um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), " diferente."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " foi descontinuado, você deve usar ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_block"
      }), ". Ao herdar um site de outro desenvolvedor, ele poderá conter código antigo que usa ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), " permite nomes de módulos V1 da HubSpot, por exemplo: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rich_text"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "raw_html"
      }), ". Parâmetros adicionais podem ser adicionados à primeira linha do HubL. A segunda linha define a qual parâmetro o conteúdo do bloco será aplicado. Por exemplo, para um módulo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rich_text"
      }), ", deve ser o parâmetro html. Para um módulo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "raw_html"
      }), ", seria o parâmetro de valor (veja os dois exemplos abaixo)."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# widget_block is deprecated, use module_block instead. This example is only to\nexplain what type_of_module was used for, for those with legacy code. #}\n{% widget_block rich_text \"my_rich_text_module\" overrideable=True, label='My rich-text module'  %}\n        {% widget_attribute \"html\" %}\n            <h2>New Module</h2>\n            <p>Add content here.</p>\n        {% end_widget_attribute %}\n{% end_widget_block %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Exemplo de saída",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<span\n  id=\"hs_cos_wrapper_my_rich_text_module\"\n  class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text\"\n  style=\"\"\n  data-hs-cos-general-type=\"widget\"\n  data-hs-cos-type=\"rich_text\"\n>\n  <h2>New Module</h2>\n  <p>Add content here.</p>\n</span>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "content_attribute"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além da sintaxe regular e de bloco, há determinadas instâncias em que você pode querer especificar um conteúdo padrão de bloco grande para uma variável de conteúdo predefinida. O exemplo mais comum disso é a variável ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#email-variables",
        children: "content.email_body"
      }), ". Essa variável imprime um corpo de e-mail padrão que pode ser alterado no editor de conteúdo. Como este não é um módulo padrão do HubL, usamos uma tag ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "content_attribute"
      }), " para especificar um bloco de conteúdo padrão. O exemplo abaixo mostra a variável do corpo do e-mail preenchida com um bloco de código de conteúdo padrão."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% content_attribute \"email_body\" %}\n        <p>Hi {{ contact.firstname }},</p>\n        <p>Describe what you have to offer the customer. Why should they read? What did you promise them in the subject line? Tell them something cool. Make them laugh. Make them cry. Well, maybe don't do that...</p>\n        <p>Use a list to:</p>\n        <ul>\n            <li>Explain the value of your offer</li>\n            <li>Remind the reader what they’ll get out of taking action</li>\n            <li>Show off your skill with bullet points</li>\n            <li>Make your content easy to scan</li>\n        </ul>\n        <p><a href=\"http://hubspot.com\">LINK TO A LANDING PAGE ON YOUR SITE</a> (This is the really important part.)</p>\n        <p>Now wrap it all up with a pithy little reminder of how much you love them.</p>\n        <p>Aw. You silver-tongued devil, you.</p>\n        <p>Sincerely,</p>\n        <p>Your name</p>\n{% end_content_attribute %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parâmetros disponíveis para todos os módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Embora alguns módulos tenham ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "parâmetros especiais"
      }), ", veja abaixo uma lista de parâmetros compatíveis com todos os módulos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome do módulo exibido no editor de conteúdo. Esse parâmetro também pode ser usado para dar mais instruções aos usuários."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overrideable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Controla se o módulo pode ou não ser editado no editor de conteúdo, equivalente à configuração ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Impeça a edição em editores de conteúdo"
            }), " no gerenciador de design."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "no_wrapper"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Quando está definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", remove a marcação do wrapper de conteúdo de um módulo.Nas páginas, os módulos são sempre envolvidos por ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<div>"
            }), " com classes especiais. A marcação do wrapping acontece de tal forma que quando você clica no módulo no painel de visualização o editor vai para aquele módulo. Pode haver casos em que você queira remover o wrapper, por exemplo, para usar um módulo de texto para preencher o destino de um atributo de tag de âncora ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "href"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "extra_classes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Adiciona classes ao wrapper do módulo. Você pode adicionar várias classes, separando-as com espaços. Por exemplo:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "extra_classes='full-width panel'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "export_to_template_context"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Quando definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), "True, em vez de renderizar o HTML, os parâmetros desse widget estarão disponíveis no contexto do modelo. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/modules/export-to-template-context",
              children: "Saiba como usar esse parâmetro e a tag widget_data."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unique_in_loop"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Quando o módulo é definido dentro de um loop, anexa o nome do módulo com loop.index. Quando definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), ", uma versão diferente do módulo será impressa em cada iteração do loop. Anexa o nome do módulo com loop.index."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver uma lista completa de todos os tipos de módulos e seus parâmetros, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "clique aqui."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parâmetros baseados em campo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba mais sobre os parâmetros de módulo baseados em campo que você pode usar."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Campo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Exemplo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Chaves"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro (ID do blog)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Verdadeiro/falso"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Opção"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"option_1\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Cor"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"color\" : \"#ffffff\",  \"opacity\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["color ", (0, _jsxRuntime.jsx)("br", {}), "formato hexadecimal de 6 caracteres ", (0, _jsxRuntime.jsx)("br", {}), "opacity ", (0, _jsxRuntime.jsx)("br", {}), "integer 0 - 100 ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTA"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String (ID da CTA)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"fb9c0055-6beb-489d-8dda-3e1222458750\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Data"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Carimbo de data/hora"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1566360000000"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Data/hora"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Carimbo de data/hora"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1566360000000"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Endereço de e-mail"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz (strings de endereço de e-mail)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[\"develop@hubspot.com\", \"design@hubspot.com\"]"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Arquivo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String (URL do arquivo)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://cdn2.hubspot.net/hubfs/file.pdf\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "E-mail de acompanhamento"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro (ID do e-mail de acompanhamento)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Fonte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"size\" : 12,  \"size_unit\" : \"px\",  \"color\" : \"#000\",  \"styles\" :{    \"text-decoration\" : \"underline\"  },  \"font\" : \"Alegreya\",  \"fallback\" : \"serif\",  \"variant\" : \"regular\",  \"font_set\" : \"GOOGLE\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["size ", (0, _jsxRuntime.jsx)("br", {}), "tamanho da fonte sem tipo de unidade ", (0, _jsxRuntime.jsx)("br", {}), "size_unit ", (0, _jsxRuntime.jsx)("br", {}), "string de unidade de tamanho de fonte", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"px\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"pt\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"em\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"rem\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"%\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"ex\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"ch\""
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "color ", (0, _jsxRuntime.jsx)("br", {}), "string de código de cor hexadecimal ", (0, _jsxRuntime.jsx)("br", {}), "styles ", (0, _jsxRuntime.jsx)("br", {}), "propriedades compatíveis\"font-weight\" ", (0, _jsxRuntime.jsx)("br", {}), "\"normal\" / \"bold\" ", (0, _jsxRuntime.jsx)("br", {}), "\"font-style\" ", (0, _jsxRuntime.jsx)("br", {}), "\"normal\" / \"italic\" ", (0, _jsxRuntime.jsx)("br", {}), "\"font-style\" ", (0, _jsxRuntime.jsx)("br", {}), "\"none\" / \"underline\" ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Formulário"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"form_id\" : \"9aa2e5f3-a46d-4774-897e-0bc37478521c\",  \"response_type\" : \"redirect\",  \"redirect_url\" : \"http://www.hubspot.com\",  \"redirect_id\" : null,  \"form_type\" : \"HUBSPOT\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["form_id ", (0, _jsxRuntime.jsx)("br", {}), "A ID do formulário. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/pt/forms/find-your-form-guid",
              children: "Como obter a ID de um formulário."
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "response_type ", (0, _jsxRuntime.jsx)("br", {}), "\"redirect\" / \"inline\" ", (0, _jsxRuntime.jsx)("br", {}), "message ", (0, _jsxRuntime.jsx)("br", {}), "Mensagem exibida ao usar response_type \"inline\". String compatível com html. ", (0, _jsxRuntime.jsx)("br", {}), "redirect_url ", (0, _jsxRuntime.jsx)("br", {}), "String, URL absoluto de uma página da web ", (0, _jsxRuntime.jsx)("br", {}), "redirect_id ", (0, _jsxRuntime.jsx)("br", {}), "ID da página/post à qual redirecionar ", (0, _jsxRuntime.jsx)("br", {}), "form_type ", (0, _jsxRuntime.jsx)("br", {}), "\"HUBSPOT\" / \"TICKET_FORM\" ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Tabela do HubDb"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro (ID da tabela do HubDB)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "123456789"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ícone"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"name\" : \"align-center\",  \"unicode\" : \"f037\",  \"type\" : \"SOLID\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["name ", (0, _jsxRuntime.jsx)("br", {}), "O nome do ícone ", (0, _jsxRuntime.jsx)("br", {}), "unicode ", (0, _jsxRuntime.jsx)("br", {}), "O símbolo unicode para a fonte de origem do ícone ", (0, _jsxRuntime.jsx)("br", {}), "type ", (0, _jsxRuntime.jsx)("br", {}), "O estilo do símbolo. \"SOLID\" / \"REGULAR\" ", (0, _jsxRuntime.jsx)("br", {}), "Para definir os parâmetros corretamente, é recomendado definir um campo de ícone e visualizar os valores dessa maneira."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Imagem"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"src\" : \"https://cdn2.hubspot.net/hubfs/image.jpeg\",  \"alt\" : \"an_image\",  \"width\" : 100,  \"height\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["src ", (0, _jsxRuntime.jsx)("br", {}), "URL da imagem ", (0, _jsxRuntime.jsx)("br", {}), "alt ", (0, _jsxRuntime.jsx)("br", {}), "Texto alternativo da imagem, usado por leitores de tela e mecanismos de pesquisa ", (0, _jsxRuntime.jsx)("br", {}), "width ", (0, _jsxRuntime.jsx)("br", {}), "A largura na qual a imagem deve ser exibida ", (0, _jsxRuntime.jsx)("br", {}), "height ", (0, _jsxRuntime.jsx)("br", {}), "A altura na qual a imagem deve ser exibida ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Link"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"url\" : {     \"type\" : \"EXTERNAL\",    \"href\" : \"www.hubspot.com\",    \"content_id\" : null   },  \"open_in_new_tab\" : false,  \"no_follow\" : false }"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["url ", (0, _jsxRuntime.jsx)("br", {}), "objeto que armazena dados de URL.type ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"EXTERNAL\" para URLs externas"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"CONTENT\" para páginas, posts de blog e landing pages"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"FILE\" para arquivos carregados no gerenciador de arquivos"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"EMAIL_ADDRESS\" para endereços de e-mail"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"BLOG\" para páginas de listagem de blogs"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "href ", (0, _jsxRuntime.jsx)("br", {}), "O URL ao qual você está vinculando. ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)("br", {}), "open_in_new_tab ", (0, _jsxRuntime.jsx)("br", {}), "\"true\"/\"false\", determina se ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "target=\"_blank\""
            }), " deve ser adicionado ", (0, _jsxRuntime.jsx)("br", {}), "no_follow ", (0, _jsxRuntime.jsx)("br", {}), "\"true\"/\"false\", determina se ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rel=\"nofollow\""
            }), " deve ser usado ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Logotipo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"override_inherited_src\" : true,  \"src\" : \"https://cdn2.hubspot.net/hubfs/logo.png\",  \"alt\" : \"best_logo_ever\",  \"width\" : 100,  \"height\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["override_inherited_src ", (0, _jsxRuntime.jsx)("br", {}), "verdadeiro/falso substitui os padrões do portal ", (0, _jsxRuntime.jsx)("br", {}), "src ", (0, _jsxRuntime.jsx)("br", {}), "URL da imagem ", (0, _jsxRuntime.jsx)("br", {}), "alt ", (0, _jsxRuntime.jsx)("br", {}), "Texto alternativo, usado para leitores de tela e mecanismos de pesquisa. ", (0, _jsxRuntime.jsx)("br", {}), "width ", (0, _jsxRuntime.jsx)("br", {}), "largura em que a imagem deve ser exibida ", (0, _jsxRuntime.jsx)("br", {}), "height ", (0, _jsxRuntime.jsx)("br", {}), "altura em que a imagem deve ser exibida ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Reunião"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String (link de reunião)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://app.hubspot.com/meetings/developers-r-kewl\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Menu"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro (ID do menu)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "123456789"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Página"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro (ID da página)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "richtext"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String (pode conter HTML)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"# Hello, world! <RelatedApiLink />\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Campanha do Salesforce"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String (ID da campanha do Salesforce)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"7016A0000005S0tQAE\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Menu simples"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array de objetos de item de menu"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[   {     \"isPublished\" : true,    \"pageLinkId\" : 123456789,    \"pageLinkName\" : \"My page\",    \"isDeleted\" : false,    \"categoryId\" : 1,    \"subCategory\" : \"site_page\",    \"contentType\" : \"site_page\",    \"state\" : \"PUBLISHED_OR_SCHEDULED\",    \"linkLabel\" : \"This is a page\",    \"linkUrl\" : null,    \"linkParams\" : null,    \"linkTarget\" : null,    \"type\" : \"PAGE_LINK\",    \"children\" : [ ]  } ]"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["isPublished ", (0, _jsxRuntime.jsx)("br", {}), "verdadeiro/falso a página do item de menu está publicada? ", (0, _jsxRuntime.jsx)("br", {}), "pageLinkId ", (0, _jsxRuntime.jsx)("br", {}), "ID da página no CMS ", (0, _jsxRuntime.jsx)("br", {}), "pageLinkName ", (0, _jsxRuntime.jsx)("br", {}), "O nome real da página no CMS ", (0, _jsxRuntime.jsx)("br", {}), "isDeleted ", (0, _jsxRuntime.jsx)("br", {}), "true/false ", (0, _jsxRuntime.jsx)("br", {}), "categoryId ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "1 - Página do site"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "3 - Post de blog"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "subCategory ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "site_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "landing_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "blog"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "normal_blog_post"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "contentType ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "site_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "landing_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "blog"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "estado ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "DRAFT"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "DRAFT_AB"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED_OR_SCHEDULED"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED_AB"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "SCHEDULED"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "linkLabel ", (0, _jsxRuntime.jsx)("br", {}), "texto que o usuário lê e clica ", (0, _jsxRuntime.jsx)("br", {}), "linkUrl ", (0, _jsxRuntime.jsx)("br", {}), "URL real para o qual o usuário é enviado ao clicar ", (0, _jsxRuntime.jsx)("br", {}), "linkParams ", (0, _jsxRuntime.jsx)("br", {}), "links # ou parâmetros de consulta ? ", (0, _jsxRuntime.jsx)("br", {}), "linkTarget ", (0, _jsxRuntime.jsx)("br", {}), "se abrir em nova guia estiver habilitado, \"_blank\"; caso contrário, \"null\" ", (0, _jsxRuntime.jsx)("br", {}), "type ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"PAGE_LINK\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"PAGE_LINK_WITH_PARAMS\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"NO_LINK\""
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "children ", (0, _jsxRuntime.jsx)("br", {}), "array de objetos de item de menu, idênticos aos itens de menu individuais. ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Tag"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inteiro (ID ou slug de tag; ID é recomendado)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"it's like any other string\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"type\" : \"CONTENT\",  \"href\" : null,  \"content_id\" : 123456789}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["type ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"EXTERNAL\" para URLs externas"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"CONTENT\" para páginas, posts de blog e landing pages"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"FILE\" para arquivos carregados no gerenciador de arquivos"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"EMAIL_ADDRESS\" para endereços de e-mail"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"BLOG\" para páginas de listagem de blogs"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "href ", (0, _jsxRuntime.jsx)("br", {}), "String, o URL para o qual você está vinculando. ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}