"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501377957;
const slug = exports.slug = 'reference/api/deprecated';
const title = exports.title = 'API de HubSpot | API obsoletas';
const name = exports.name = 'LATAM (ES) vNext Docs DP - Deprecated APIs';
const metaDescription = exports.metaDescription = 'Una lista de las API de HubSpot obsoletas';
const toc = exports.toc = [{
  "depth": 0,
  "id": "api-eliminadas",
  "label": "API eliminadas",
  "parent": null
}, {
  "depth": 0,
  "id": "api-obsoletas",
  "label": "API obsoletas",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      br: "br",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API eliminadas y obsoletas"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las API que se indican en las secciones a continuación ", (0, _jsxRuntime.jsx)("u", {
        children: "no"
      }), " recibirán futuras actualizaciones de la funcionalidad y, en algunos casos, se eliminarán por completo y no estarán disponibles para su uso en tus integraciones."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es recomendable que te suscribas al ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Changelog de desarrollador de HubSpot"
      }), " para seguir las últimas actualizaciones, cambios de última hora y otros cambios significativos en la funcionalidad."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API eliminadas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las siguientes API se han eliminado por completo y devolverán un error al realizar una llamada a sus puntos de terminación asociados:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A partir del 20 de noviembre de 2022, las claves de API se eliminarán como método de autenticación. Más información sobre esta actualización en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "este anuncio de registro de cambios"
        }), " y cómo ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "migrar una integración de claves de API para usar una aplicación privada"
        }), " en su lugar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A partir del 28 de febrero de 2023, las siguientes API ya no están disponibles:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated",
              children: "API de puente de comercio electrónico"
            }), ": si creaste una integración utilizando estas API, puedes seguir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration",
              children: "esta guía de migración"
            }), " para cambiar tu integración a aplicaciones privadas."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["API de extensión de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated",
              children: "contabilidad"
            }), ": si creaste una integración utilizando estas API, puedes seguir ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-account-extension-integration",
              children: "esta guía de migración"
            }), " para cambiar tu integración a aplicaciones privadas.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A partir del 31 de agosto de 2023, las API de Calendario ya no están disponibles. Esto incluye los siguientes puntos de terminación:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Indicar eventos de contenido"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Indicar eventos en redes sociales"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Indicar eventos de tareas de calendario"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Indicar todos los eventos del calendario"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Crear tarea de calendario"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Obtener tarea de calendario por ID"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Actualizar tarea de calendario"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Eliminar una tarea de calendario"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API obsoletas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los puntos de terminación preexistentes que se indican a continuación ", (0, _jsxRuntime.jsx)("u", {
        children: "no"
      }), " recibirán una actualización de versión. Estos puntos finales son funcionales y estables, pero no se actualizarán más allá de su versión actual. HubSpot seguirá ofreciendo asistencia técnica para el futuro previsible y anunciaremos cualquier cambio venidero con un aviso detallado en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Changelog de desarrollador"
      }), " de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "API de redes sociales:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-publishing-channels",
              children: "Obtener canales de publicación"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-broadcast-messages",
              children: "Obtener mensajes de transmisión"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#get-a-broadcast-message",
              children: "Recibir un mensaje de transmisión"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#create-a-broadcast-message",
              children: "Crear un mensaje de transmisión"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/social-media/v1#cancel-a-broadcast-message",
              children: "Cancelar un mensaje de transmisión"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}