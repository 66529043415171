"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 52099237786;
const slug = exports.slug = 'guides/cms/content/data-driven-content/dynamic-pages/overview';
const title = exports.title = '動的ページの概要';
const name = exports.name = 'APAC JAPAN (ja) | [Developers] Dynamic pages overview_JA | 202109';
const metaDescription = exports.metaDescription = 'HubDBまたはCRMオブジェクトなどの構造化データソースからのコンテンツを使用してCMSページを作成する方法を説明します。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AB%E3%82%88%E3%82%8B%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8",
  "label": "CRMオブジェクトによる動的ページ",
  "parent": null
}, {
  "depth": 1,
  "id": "%E5%89%8D%E6%8F%90%E6%9D%A1%E4%BB%B6",
  "label": "前提条件",
  "parent": "crm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AB%E3%82%88%E3%82%8B%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8"
}, {
  "depth": 1,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%AE%E4%BD%9C%E6%88%90",
  "label": "コンテンツの作成",
  "parent": "crm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AB%E3%82%88%E3%82%8B%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8"
}, {
  "depth": 1,
  "id": "%E4%BD%9C%E6%88%90%E3%82%92%E9%96%8B%E5%A7%8B%E3%81%99%E3%82%8B",
  "label": "作成を開始する",
  "parent": "crm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AB%E3%82%88%E3%82%8B%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8"
}, {
  "depth": 1,
  "id": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AE%E8%B3%87%E6%96%99",
  "label": "その他の資料",
  "parent": "crm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AB%E3%82%88%E3%82%8B%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8"
}, {
  "depth": 0,
  "id": "hubdb%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8",
  "label": "HubDB動的ページ",
  "parent": null
}, {
  "depth": 1,
  "id": "%E5%89%8D%E6%8F%90%E6%9D%A1%E4%BB%B6",
  "label": "前提条件",
  "parent": "hubdb%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8"
}, {
  "depth": 1,
  "id": "%E3%82%B3%E3%83%B3%E3%83%86%E3%83%B3%E3%83%84%E3%81%AE%E4%BD%9C%E6%88%90",
  "label": "コンテンツの作成",
  "parent": "hubdb%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8"
}, {
  "depth": 1,
  "id": "%E4%BD%9C%E6%88%90%E3%82%92%E9%96%8B%E5%A7%8B%E3%81%99%E3%82%8B",
  "label": "作成を開始する",
  "parent": "hubdb%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8"
}, {
  "depth": 1,
  "id": "%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%AE%E8%B3%87%E6%96%99%EF%BC%88%E8%8B%B1%E8%AA%9E%EF%BC%89",
  "label": "その他の資料（英語）",
  "parent": "hubdb%E5%8B%95%E7%9A%84%E3%83%9A%E3%83%BC%E3%82%B8"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      h3: "h3",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "動的ページの概要"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "動的ページは、HubDBまたはCRMオブジェクトなどの構造化データソースからコンテンツを取得したCMSページです。このデータが、データを要約して示するリストページと、各データソース項目（HubDB行またはCRMオブジェクトレコード）による個別の詳細ページの作成と出力に使用されます。制作担当者はその後、ドラッグ＆ドロップ ページ エディターを使用してリストページと詳細ページのレイアウトと表示を変更できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "選択するデータソースによって、前提条件、メリット、コンテンツ制作の利便性が異なります。ここからは、各データソースについて説明し、最適なデータソースを選択する方法を説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRMオブジェクトによる動的ページ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCRMオブジェクトは、ビジネス上のさまざまな種類のレコードと関係を表します。標準のCRMオブジェクトとしては、コンタクト、会社、取引、チケットなどがあります。Enterpriseをご契約の場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "カスタムオブジェクトを作成する"
      }), "こともできます。こうしたCRMオブジェクトを使用して、必要なオブジェクトタイプを基にリストページと個別レコードの詳細ページを作成できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、自動車ディーラーは「Car」カスタムオブジェクトを使用して在庫をレコードとして記録できます。次に、CRMオブジェクトによる動的ページを使用することで、各車両に固有のページを自動作成し、オンラインの在庫リストを公開できます。「Car」オブジェクトの新しいレコードを作成すると、新しいページが自動作成され、在庫とウェブサイトが同期されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CRMオブジェクトは、次のような場合にデータソースとして使用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "他のCRMオブジェクト（コンタクトなど）にレコードを関連付ける必要がある。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "オブジェクトに基づいて自動化またはパーソナライズする必要がある。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ビジネスプロセスとデータ記録を簡素化する。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "前提条件"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CRMオブジェクトによる動的ページを作成するための要件を次に示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub Professional"
        }), "または", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enterprise"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["カスタムオブジェクトを使用する場合、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub Enterprise"
        }), "。または", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub Enterprise"
        }), "と", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub Professional"
        }), "が必要です。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/guides/getting-started-with-modules",
          children: "カスタムモジュールを作成する"
        }), "方法に関する理解。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "データソースとして使用する標準オブジェクトまたはカスタムオブジェクト。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "コンテンツの作成"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["動的コンテンツモジュールを作成したら、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/guides/creating-a-drag-and-drop-area",
        children: "ドラッグ＆ドロップエリア"
      }), "を備えるページにそのモジュールを挿入できます。制作担当者はページエディター上で、ページモジュールを追加または編集することで、ページのレイアウトとコンテンツ全般を編集できます。テンプレートと同様に、ページのレイアウトまたはコンテンツ全般に対する変更は、リストページと詳細ページの両方に適用されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["動的ページのコンテンツは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/crm-setup/use-custom-objects",
        children: "CRMオブジェクトレコード"
      }), "から取得されるため、CRMレコードを管理する場合と同様に管理します。例えば、動的ページのコンテンツを編集するには、HubSpot上の個別のレコードを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/crm/update-a-property-value-for-a-record",
        children: "編集"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/contacts/delete-crm-records",
        children: "削除"
      }), "します。同様に、コンテンツを一括管理するには、レコードを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/crm-setup/import-objects",
        children: "インポート"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/crm-setup/how-can-i-bulk-edit-contacts-companies-deals-or-tasks",
        children: "一括編集"
      }), "します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "作成できるページのタイプは、選択するオブジェクトによって異なります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "公開ページは次のオブジェクトを使用して作成できます。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "製品"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "マーケティングイベント"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "カスタムオブジェクト"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["非公開ページ（", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/jp/website-pages/password-protect-a-page",
            children: "パスワード保護"
          }), "または", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/jp/website-pages/require-member-registration-to-access-private-content",
            children: "メンバー登録"
          }), "）は、次のオブジェクトを使用して作成できます。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "コンタクト"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "会社"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "取引"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "見積もり"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "チケット"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "選択するCRMオブジェクトに対して設定されている自動化を理解しておくことが重要です。例えば、関連付けられている取引ステージに基づいてカスタム オブジェクト レコードの名前を自動的に更新するワークフローがある場合、動的ページのコンテンツも、レコードの名前が変更されるたびに更新されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "作成を開始する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRMオブジェクトによる動的ページの作成を開始するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/guides/dynamic-pages/crm-objects",
        children: "CRMオブジェクトによる動的ページの作成に関する開発者ガイド"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["データソースとしてカスタムオブジェクトを使用する予定の場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "HubSpotのAPIを使用してカスタムオブジェクトを作成および管理する"
      }), "方法をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "その他の資料"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/guides/dynamic-pages/crm-objects",
          children: "CRMオブジェクトを使用した動的ページの作成方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/features/custom-objects",
          children: "CMSのCRMオブジェクト"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/jp/get-started/manage-your-crm-database",
          children: "CRMデータベースを管理する"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubDB動的ページ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/features/hubdb",
        children: "HubDB"
      }), "を使用すると、HubDBテーブルの行から動的ページを生成できます。また、子テーブルを使用して、ウェブサイトの個々のブログやリストページに結び付けるネストした（入れ子構造の）ページを作成することもできます。HubDBテーブルをデータソースとして有効にすると、メタディスクリプション、キービジュアル、canonical URLに使用する列を選択できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDBは、次のような場合に動的ページのデータソースとして使用します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "テーブルからのデータをCRMデータに関連付ける必要がない。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["運用上、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/features/hubdb#hubdb-technical-limits",
          children: "HubDBの技術的な制限事項"
        }), "が問題にならない。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "前提条件"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubDBによる動的ページを作成するための要件を次に示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "CMS Hub ProfessionalまたはEnterprise。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["既存のHubDBテーブル。または", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/features/hubdb#creating-your-first-table",
          children: "最初のHubDBテーブルを作成する方法"
        }), "を習得する。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.jp/docs/cms/guides/getting-started-with-modules",
          children: "カスタムモジュールを作成する"
        }), "方法に関する理解。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "コンテンツの作成"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "動的ページのコンテンツ用のHubDBテーブルを作成して有効にすると、HubDBの行を作成、編集、削除することでページのコンテンツを管理できます。HubSpotアカウント上でHubDBテーブルを直接編集したり、外部でスキーマを編集してからCSVまたはHubSpot APIを使用してデータをアップロードしたりできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "作成を開始する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubDBを使用して動的ページの作成を開始するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/guides/dynamic-pages/hubdb",
        children: "HubDB動的ページの作成に関する開発者ガイド"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "その他の資料（英語）"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "HubDBで階層状の動的ページを作成する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "HubDB動的ページに動画を追加する方法"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "複数のHubDBテーブルを結合する方法"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}