"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 140611080051;
const slug = exports.slug = 'guides/api/marketing/emails/single-send-api';
const title = exports.title = 'Marketing API | Single send API';
const name = exports.name = 'vNext Docs DP - Marketing single-send API';
const metaDescription = exports.metaDescription = 'An overview of the marketing single-send API in HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "requirements",
  "label": "Requirements",
  "parent": null
}, {
  "depth": 0,
  "id": "create-an-email-and-send-it-via-the-single-send-api",
  "label": "Create an email and send it via the single-send API",
  "parent": null
}, {
  "depth": 1,
  "id": "request-properties",
  "label": "Request properties",
  "parent": "create-an-email-and-send-it-via-the-single-send-api"
}, {
  "depth": 2,
  "id": "emailid",
  "label": "emailId",
  "parent": "create-an-email-and-send-it-via-the-single-send-api"
}, {
  "depth": 2,
  "id": "message",
  "label": "message",
  "parent": "create-an-email-and-send-it-via-the-single-send-api"
}, {
  "depth": 2,
  "id": "contactproperties",
  "label": "contactProperties",
  "parent": "create-an-email-and-send-it-via-the-single-send-api"
}, {
  "depth": 2,
  "id": "customproperties",
  "label": "customProperties",
  "parent": "create-an-email-and-send-it-via-the-single-send-api"
}, {
  "depth": 1,
  "id": "query-the-status-of-an-email-send",
  "label": "Query the status of an email send",
  "parent": "create-an-email-and-send-it-via-the-single-send-api"
}, {
  "depth": 2,
  "id": "sendresult",
  "label": "sendResult",
  "parent": "create-an-email-and-send-it-via-the-single-send-api"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      strong: "strong",
      code: "code",
      img: "img",
      h3: "h3",
      h4: "h4",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Marketing Single Send API"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The single-send API allows you to send template emails created in the HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/create-marketing-emails-in-the-drag-and-drop-email-editor",
        children: "marketing email tool"
      }), " using a JSON-formatted POST request."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["All contacts receiving marketing content must be ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/set-contacts-as-marketing",
        children: "set as marketing"
      }), ". Any marketing emails sent through the single-send API will automatically be associated with contact records based on their email address, and update non-marketing contacts and set them to marketing contacts . If there's no contact with a matching email address, a new contact record with that email will be created, and the contact will be set as marketing."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Requirements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To use the marketing single send API, the following requirements must be met:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You must have a ", (0, _jsxRuntime.jsxs)(_components.em, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing Hub"
          }), " Enterprise"]
        }), " account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "private app"
        }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "public app"
        }), " you're using to make API requests has been granted the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "marketing-email"
        }), " scope."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create an email and send it via the single-send API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["First, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
        children: "set up your email in HubSpot"
      }), ". After you create the email, you can set the recipient details, including any contact or custom properties set up in the email template, in the body of the API request. Before you can make the API request, you'll need the ID of the email:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you leave the email drafted without publishing it, you can get the email ID from the URL when you're in the email editor. The ID is the final numeric value before the final slash character (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/"
        }), ") in the URL (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/email/{PORTAL_ID}/edit/{EMAIL_ID}/settings"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/email-id-for-drafted-single-send-api-email.png",
        alt: "email-id-for-drafted-single-send-api-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If you publish your email, you can copy the email ID from the email details page."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-15%20at%201.00.37%20PM.png",
        alt: "Screen Shot 2020-04-15 at 1.00.37 PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " HubSpot does not save the HTML/JSON sent through this API. You can review the email template from the recipient contact's timeline, but if you want to keep a record of the email's contents, it's recommended to add a BCC to the email."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To send an email with the Single-Send API, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v4/email/single-send"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response contains the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), ": the timestamp of when the send was requested."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), ": an identifier that can be used to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-the-status-of-an-email-send",
            children: "query the status of the send"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), ": the status of the send request. Includes ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELED"
          }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMPLETE"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Request properties"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The request body must be a JSON-formatted object with the following properties:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "message"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "contactProperties"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "emailId"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "emailId"
      }), " field contains the email's content ID, which can be found in HubSpot's email tool."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "message"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The message field is a JSON object containing anything that you want to override. At the minimum, you must include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "to"
      }), " field."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Message object fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "to"
        }), ": the recipient of the email"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "from"
        }), ": the \"From\" header for the email. You can define a from name with the following format: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"from\":\"Sender Name <sender@hubspot.com>\""
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), ": the ID of a particular send. Only one email with a given ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), " will be sent per account, so you can include this field to prevent duplicate email sends."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "replyTo"
        }), ": a JSON list of \"Reply-To\" header values for the email."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "cc"
        }), ": a JSON list of email addresses to send as Cc."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "bcc"
        }), ": a JSON list of email addresses to send as Bcc."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "contactProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contactProperties"
      }), " field is a JSON map of contact property values. Each contact property value contains a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "value"
      }), ". Each property will be set on the contact record and will be visible in the template under:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/name-token-in-template-for-transactional-email.png",
        alt: "name-token-in-template-for-transactional-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use these properties when you want to set a contact property while you’re sending the email. For example, when sending a receipt you may want to set a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "last_paid_date"
      }), " property, as the sending of the receipt will have information about the last payment."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"contactProperties\": {\n    \"last_paid_date\": \"2022-03-01\",\n    \"firstname\": \"jane\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "customProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " field is a JSON map of key-value properties. These properties are generally related to the email itself, not the contact receiving the email. They will not appear in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/create-a-web-version-of-your-marketing-email",
        children: "web page version of the email"
      }), ", or in the view of the email from the contact's timeline. These properties are also not stored in HubSpot and will only be included in the sent email."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each key in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " field can be referenced in the template using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL expression"
      }), " for fields contained within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "custom"
      }), " variable (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ custom.NAME_OF_PROPERTY }}"
      }), " )."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if your email template references two properties, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "purchaseUrl"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "productName"
      }), ", you could provide the associated values for these properties with the following request body:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"customProperties\": {\n    \"purchaseUrl\": \"https://example.com/link-to-product\",\n    \"productName\": \"vanilla\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can then reference these properties in your email template:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Congrats on purchasing some of the best ice cream around.\n  </p>\n  <a href={{custom.purchaseUrl}}>{{custom.productName}}</a>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " field only supports arrays when used with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "programmable email content"
      }), ". In your email template, you can reference the items defined in your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " field by using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL expression"
      }), " (e.g., using a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "for loop"
      }), " to render each item in a list). For example, if the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " you included in your request body was structured like the following JSON snippet below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643122,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"7\"\n  },\n  \"customProperties\": {\n    \"exampleArray\": [\n      {\"firstKey\": \"someValue\", \"secondKey\": \"anotherValue\"},\n      {\"firstKey\": \"value1\", \"secondKey\": \"value2\" }\n    ]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You could then reference the values for each item in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exampleArray"
      }), " with the following HubL code:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Thanks for your recent purchase! Here are the details of the items you'll be receiving:\n  </p>\n  <ul>\n    {% for item in custom.exampleArray %}\n      <li>First key: {{ item.firstKey }}, Second key: {{item.secondKey}}</li>\n    {% endfor %}\n  </ul>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Once sent, the email would render the contents of the associated programmable email template as follows:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-transactional-email-with-customProperties-array.png",
        alt: "example-transactional-email-with-customProperties-array"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Query the status of an email send"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get the status of the email send, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/marketing/v3/email/send-statuses/{statusId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response contains the following fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "sendResult"
          }), ": an enumeration that represents the email's send status. The possible values are ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#sendresult",
            children: "listed below"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), ": the timestamp from when the send was requested."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "startedAt"
          }), ": the timestamp when the send began processing."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "completedAt"
          }), ": the timestamp when the send completed."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), ": an identifier that can be used to query the status of the send."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), ": the status of the send request. Includes ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELED"
          }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMPLETE"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "eventId"
          }), ": if sent, the ID and created timestamp of the sent event."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "sendResult"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "sendResult"
      }), " is an enumeration that reflects the result of an email send attempt. Its possible values are:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "SENT"
        }), ": the email was sent successfully."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "QUEUED"
        }), ": the email was queued and will send as the queue gets processed."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PORTAL_SUSPENDED"
        }), ": due to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/acceptable-use",
          children: "Acceptable Use Policy"
        }), " violations, the HubSpot customer's email has been suspended."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_TO_ADDRESS"
        }), ": the recipient address is invalid. This error will also occur if you attempt to send an email with any of the following role-based prefixes in the email address: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "abuse"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "no-reply"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "noreply"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "root"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "spam"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "security"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undisclosed-recipients"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "unsubscribe"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inoc"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "postmaster"
        }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "privacy"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOCKED_DOMAIN"
        }), ": the domain cannot receive emails from HubSpot at this time."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUSLY_BOUNCED"
        }), ": the recipient has previously bounced, and the sending logic resulted in no send."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUS_SPAM"
        }), ": the recipient has previously marked similar email as spam."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_FROM_ADDRESS"
        }), ": the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "From"
        }), " address is invalid."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_CONTENT"
        }), ": the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), " is invalid, or the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), " corresponds to an email that wasn't set up for Single-Send."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_TEMPLATE_PROPERTIES"
        }), ": there are properties set up in the template that have not been included in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        }), " sent in the request."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all available endpoints and their requirements, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/marketing/emails/single-send-api",
        children: "reference documentation"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}