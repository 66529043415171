"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 33812077248;
const slug = exports.slug = 'guides/cms/content/themes/responsive-breakpoints';
const title = exports.title = 'Responsive Breakpoints for Themes';
const name = exports.name = '[Developers] Responsive Breakpoints for Themes';
const metaDescription = exports.metaDescription = 'Learn about creating responsive breakpoints in your themes configuration settings.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "define-breakpoints-in-a-theme",
  "label": "Define breakpoints in a theme",
  "parent": null
}, {
  "depth": 0,
  "id": "define-responsive-styles-in-dnd_area-tags",
  "label": "Define responsive styles in dnd_area tags",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      code: "code",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Set responsive breakpoints for themes"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While developing a theme, you can define responsive breakpoints to optimize styling for mobile and desktop. Below, learn more about how to set breakpoints and define responsive styles in ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area tags"
      }), " using HubL."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use these breakpoints to define the following styling for specific asset types:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), " support margin and padding across breakpoints."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Default modules in a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " support margin, padding, and visibility."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Default modules outside a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " support only margin and padding."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Custom modules in a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " support visibility."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Custom modules outside a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " don't support breakpoint based customizations."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition, only two breakpoints are possible at this time: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mobile"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "default"
      }), " (optional for desktop)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Define breakpoints in a theme"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can define a set of breakpoints in your theme by adding the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "responsive_breakpoints"
      }), " object to your ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "themes.json"
      }), " file. Inside of this object is a set of key/value pairs that will contain information about your breakpoint."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// themes.json\n{\n  \"label\": \"My Theme\",\n  \"preview_path\": \"./path/to/preview.html\",\n  \"screenshot_path\": \"./images/template-previews/home.png\",\n  \"responsive_breakpoints\": [\n    {\n      \"name\": \"mobile\",\n      \"mediaQuery\": \"@media (max-width: 767px)\",\n      \"previewWidth\": {\n        \"value\": 520\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below are the properties you can include within ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "responsive_breakpoints"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Key"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The name of the breakpoint. At this time only ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"mobile\""
            }), " is available for use."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaQuery"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A media query string for the renderer/editors to use when generating responsive CSS. e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"@media (max-width: 767px)\""
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "previewWidth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Key/Value Pair"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["To give clues to the editor as to what size we should show our preview iframe at.e.g. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"value\": 520}"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Define responsive styles in dnd_area tags"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["While building ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "drag and drop areas"
      }), ", you can including responsive styling using HubL. This functionality currently works in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), " tags."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Take the following example of a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas#drag-and-drop-section-code-dnd-section-code-",
        children: "dnd_section"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section padding={ 'top': 100, 'bottom': 100 } %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To change the padding for the mobile breakpoint, you can include values for both the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "default"
      }), " (desktop view) and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "mobile"
      }), " breakpoints as illustrated below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section padding={\n    'default': { 'top': 100, 'bottom': 100 },\n    'mobile': { 'top': 20, 'bottom': 20 }\n  }\n%}\n{% end_dnd_section %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}