import I18n from 'I18n';
import { PageTypes, Paths } from '../../../components/RoutesPublic';
import memoize from 'react-utils/memoize';
import { keyToNavData } from 'developer-docs-shared-components/config/navData/keys';
import { breadcrumbs } from 'developer-docs-shared-components/config/navData/translations/breadcrumbs';

/**
 * 'Guides' > 'CRM Customization' nav config.
 *
 * Hard-coded data for the guides left hand navigation. Currently, labels and
 * breadcrumbs for guides are based off of the same key used for the API
 * Reference endpoints pages and align with a partial chunk of the guides urls.
 * While we are hardcoding these keys for now (see label property) below, we may
 * need to consider how we will determine Guides keys from the BE in the long run
 */
const crmCustomizationNavData = keyToNavData['/guides/crm'];
export const crmCustomizationNavConfig = memoize(() => {
  const commonPath = `${Paths.GUIDES}/crm`;
  const config = {
    locatorPageType: PageTypes.GUIDES,
    commonPath,
    badge: 'BETA',
    hasMdxOverview: true,
    sectionLabel: I18n.text('developer-docs-ui.navigation.guides.crmCustomization'),
    items: [{
      label: crmCustomizationNavData['/overview'].label(),
      url: `${commonPath}/overview`
    }, {
      label: crmCustomizationNavData['/setup'].label(),
      url: `${commonPath}/setup`
    }, {
      // eslint-disable-next-line hubspot-dev/no-private-classes
      label: breadcrumbs['private-apps'](),
      items: [{
        label: crmCustomizationNavData['/private-apps/quickstart'].label(),
        url: `${commonPath}/private-apps/quickstart`
      }, {
        label: crmCustomizationNavData['/private-apps/creating-private-apps'].label(),
        url: `${commonPath}/private-apps/creating-private-apps`
      }, {
        label: crmCustomizationNavData['/private-apps/serverless-functions'].label(),
        url: `${commonPath}/private-apps/serverless-functions`
      }]
    }, {
      label: breadcrumbs['public-apps'](),
      items: [{
        label: crmCustomizationNavData['/public-apps/overview'].label(),
        url: `${commonPath}/public-apps/overview`
      }, {
        label: crmCustomizationNavData['/public-apps/quickstart'].label(),
        url: `${commonPath}/public-apps/quickstart`
      }, {
        label: crmCustomizationNavData['/public-apps/creating-public-apps'].label(),
        url: `${commonPath}/public-apps/creating-public-apps`
      }, {
        label: crmCustomizationNavData['/public-apps/fetching-data'].label(),
        url: `${commonPath}/public-apps/fetching-data`
      }, {
        label: crmCustomizationNavData['/public-apps/webhooks'].label(),
        url: `${commonPath}/public-apps/webhooks`
      }, {
        label: crmCustomizationNavData['/public-apps/migrate-a-public-app-to-projects'].label(),
        url: `${commonPath}/public-apps/migrate-a-public-app-to-projects`
      }]
    }, {
      label: breadcrumbs['ui-extensions'](),
      items: [{
        label: crmCustomizationNavData['/ui-extensions/overview'].label(),
        url: `${commonPath}/ui-extensions/overview`
      }, {
        label: crmCustomizationNavData['/ui-extensions/create'].label(),
        url: `${commonPath}/ui-extensions/create`
      }, {
        label: crmCustomizationNavData['/ui-extensions/sdk'].label(),
        url: `${commonPath}/ui-extensions/sdk`
      }, {
        label: breadcrumbs['sample-extensions'](),
        items: [{
          label: crmCustomizationNavData['/ui-extensions/sample-extensions/overview'].label(),
          url: `${commonPath}/ui-extensions/sample-extensions/overview`
        }, {
          label: crmCustomizationNavData['/ui-extensions/sample-extensions/deals-summary-tutorial'].label(),
          url: `${commonPath}/ui-extensions/sample-extensions/deals-summary-tutorial`
        }]
      }, {
        label: crmCustomizationNavData['/ui-extensions/local-development'].label(),
        url: `${commonPath}/ui-extensions/local-development`
      }]
    }, {
      label: breadcrumbs['developer-projects'](),
      items: [{
        label: crmCustomizationNavData['/developer-projects/create-a-project'].label(),
        url: `${commonPath}/developer-projects/create-a-project`
      }, {
        label: crmCustomizationNavData['/developer-projects/link-a-github-repository-to-a-project'].label(),
        url: `${commonPath}/developer-projects/link-a-github-repository-to-a-project`
      }, {
        label: crmCustomizationNavData['/developer-projects/project-cli-commands'].label(),
        url: `${commonPath}/developer-projects/project-cli-commands`
      }, {
        label: crmCustomizationNavData['/developer-projects/platform-versioning'].label(),
        url: `${commonPath}/developer-projects/platform-versioning`
      }]
    }]
  };
  return config;
});