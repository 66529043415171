"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42816620893;
const slug = exports.slug = 'guides/apps/marketplace/app-marketplace-listing-requirements';
const title = exports.title = 'Mercado de aplicaciones | Requisitos de anuncios de venta de aplicaciones';
const name = exports.name = 'LATAM (ES) App Listing Requirements';
const metaDescription = exports.metaDescription = 'Los Partners de aplicaciones ahora pueden ver las pautas y los requisitos de HubSpot para anunciar una aplicación en el Mercado de aplicaciones en un solo lugar. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "requisitos-m%C3%ADnimos",
  "label": "Requisitos mínimos",
  "parent": null
}, {
  "depth": 1,
  "id": "requisitos-de-marca",
  "label": "Requisitos de marca",
  "parent": "requisitos-m%C3%ADnimos"
}, {
  "depth": 1,
  "id": "requisitos-de-los-anuncios",
  "label": "Requisitos de los anuncios",
  "parent": "requisitos-m%C3%ADnimos"
}, {
  "depth": 0,
  "id": "revisi%C3%B3n%2C-comentarios-y-aprobaci%C3%B3n",
  "label": "Revisión, comentarios y aprobación",
  "parent": null
}, {
  "depth": 0,
  "id": "recompensas-para-partners-de-aplicaciones-anunciadas",
  "label": "Recompensas para Partners de aplicaciones anunciadas",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-relacionados",
  "label": "Recursos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      h3: "h3",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Requisitos de anuncios de venta de aplicaciones"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El equipo de Calidad del ecosistema de HubSpot revisa manualmente los envíos de anuncios de aplicaciones. Estos anuncios se rechazarán si no cumplen con los criterios que se detallan a continuación. Una vez que tu aplicación cumpla con estos requisitos, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "crear el anuncio"
      }), " de la aplicación desde tu cuenta de desarrollador de aplicaciones yendo hasta ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Mercado de aplicaciones > Anuncios > Crear anuncio"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Requisitos mínimos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "ID único de aplicación de HubSpot:"
          }), " tu aplicación debe autorizar las solicitudes de API con el ID público de aplicación de HubSpot (y el ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "ID de cliente de OAuth"
          }), ") asociado con el anuncio de la aplicación."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Un anuncio no debe redireccionar hacia una aplicación pública o privada diferente."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "La aplicación pública que aparece en tu anuncio no debe requerir otra aplicación pública o privada para funcionar."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Tu aplicación pública anunciada debe ser única. Si ya has publicado una aplicación y quieres reemplazarla, debes actualizar la aplicación existente en lugar de publicar una nueva."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "No crees varias aplicaciones que resuelvan el mismo caso de uso. Las aplicaciones con funcionalidades similares y que utilizan las mismas API deben consolidarse en una sola aplicación."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "OAuth:"
          }), " tu aplicación debe usar OAuth como su único método de autorización. Más información sobre cómo ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "trabajar con OAuth"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Instalaciones:"
          }), " tu aplicación debe tener al menos tres ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/certification-requirements#:~:text=Active%20installs%20are%20the%20number%20of%20unique%20HubSpot%20production%20accounts%2C%20unaffiliated%20with%20your%20organization%2C%20showing%20successful%20app%20activity%20within%20the%20past%2030%20days.",
            children: "instalaciones activas y únicas"
          }), ". No podrás enviar tu anuncio de venta de aplicaciones sin esto."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Alcances:"
          }), " solo debes solicitar los alcances que tu aplicación necesite. ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth#scopes",
            children: "Revisa tus alcances"
          }), " y asegúrate de no solicitar acceso innecesario. Las aplicaciones que hacen esto tienden a tener mejores tasas de conversión."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Tu aplicación debe tener activada la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "configuración avanzada de alcance"
            }), ". Se deben seleccionar todos los alcances requeridos, condicionalmente requeridos y opcionales para evitar errores. Esta configuración se puede encontrar en la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/getting-started/account-types#app-developer-accounts",
              children: "cuenta de desarrollador"
            }), " que gestiona tu aplicación."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Términos:"
          }), " debes revisar y aceptar los términos del ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/app-program-agreement",
            children: "Acuerdo del App Partner Program de HubSpot"
          }), ". Esto te protege a ti, a HubSpot y a nuestros clientes compartidos. No podrás enviar tu anuncio de venta de aplicaciones sin completar este paso."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Industrias restringidas:"
          }), " tu aplicación no debe adaptarse ni ofrecer funcionalidades que sirvan exclusivamente a clientes dentro de ninguna de las ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/acceptable-use#Restricted-Industries",
            children: "industrias restringidas"
          }), " de HubSpot."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Requisitos de marca"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Tu aplicación y sus materiales asociados (documentación, páginas de destino, etc.) deben cumplir con las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/branding-guidelines",
          children: "Pautas de marca de HubSpot"
        }), ". Por ejemplo, coloca la \"S\" de \"HubSpot\" en mayúscula cada vez que te refieras a HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Tu aplicación y sus materiales asociados (documentación, páginas de destino, etc.) no deben infringir las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/tm-usage-guidelines",
          children: "pautas de uso de la marca comercial de HubSpot"
        }), ". Por ejemplo, no combines el nombre de HubSpot (incluidos \"Hub\" y \"HubSpot\") con el nombre o logotipo de tu aplicación."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Requisitos de los anuncios"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Una vez que hayas alcanzado los requisitos mínimos, puedes enviar el anuncio de la aplicación. Al enviar el anuncio de la aplicación, debes suministrar la información de manera completa y exacta. Estos campos son especialmente importantes, y si no cumples con estos requisitos, tu anuncio se configurará solo en el modo Borrador:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El contenido de tu anuncio debe ser específico para la integración, en lugar de información general del producto. Debe contener información sobre el valor que los clientes pueden esperar específicamente de la descarga y el uso de esta integración. Algunos ejemplos incluyen: ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/calling/aircall",
          children: "Aircall"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/sales-enablement/cloudfiles",
          children: "CloudFiles"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps/sales/partner-relationship-management/reveal-191193",
          children: "Reveal"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Un enlace a documentación de configuración disponible públicamente (sin necesidad de iniciar sesión ni hacer pagos) específica para tu integración de HubSpot.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Tu guía de configuración no puede ser simplemente tu página de inicio o una base de conocimientos general."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "En cambio, debe contener los pasos para instalar y configurar la integración."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Por ejemplo, echa un vistazo a la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://orgcharthub.com/guides/setup",
              children: "guía de configuración de OrgChartHub"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Incluye una URL de botón de instalación correspondiente que lleve a los clientes a una página donde puedan conectar fácilmente tu aplicación con HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Las URL para los recursos de asistencia técnica de la aplicación (sitio web de asistencia técnica, foro de la comunidad de HubSpot, caso de éxito) deben estar activas, actualizadas y disponibles públicamente."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Las URL para los Términos de servicio y la Política de Privacidad de la aplicación deben estar disponibles y actualizadas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Todos los campos de URL tienen un límite de 250 caracteres."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: "Los datos compartidos"
        }), ", que permiten a los usuarios saber cómo fluirá la información entre tu aplicación y HubSpot, deben ser correctos, estar actualizados y reflejar los ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements#:~:text=Webhooks%20API.-,Scopes,-%3A%20You%20must",
          children: "alcances"
        }), " que solicita tu aplicación.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Todos los objetos seleccionados en los alcances de OAuth deben documentarse en la tabla ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Datos compartidos"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si tu aplicación solicita alcances de objetos de lectura y escritura, la sincronización de datos debe anunciarse como bidireccional para estos objetos específicos."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["El anuncio del mercado de aplicaciones debe contener información de precios clara y precisa.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Al menos un plan de precios correspondiente a tu integración de HubSpot, que debe coincidir con la información publicada en tu sitio web."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Los planes gratuitos solo se deben usar para modelos Gratis para siempre o Freemium."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Debes incluir al menos un método de contacto de asistencia técnica."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sigue las pautas que se indican ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/testing-credentials",
          children: "aquí"
        }), " para proporcionar credenciales de prueba para tu anuncio de la aplicación."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Revisión, comentarios y aprobación"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando envíes tu anuncio, el equipo de Calidad del Ecosistema de HubSpot realizará una revisión inicial en el transcurso de 10 días laborables. Si la información proporcionada es incorrecta, es engañosa o incompleta, te contactaremos con ese feedback. Todo el proceso de revisión y comentarios de la aplicación no debe tardar más de 60 días desde el momento en que se comparten los comentarios. Tal como se indica en los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/app-program-agreement",
        children: "Términos del mercado de aplicaciones"
      }), ", HubSpot se reserva el derecho de cancelar o rechazar la publicación del anuncio de tu aplicación en cualquier momento."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recompensas para Partners de aplicaciones anunciadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Anuncio dedicado para el mercado de aplicaciones de HubSpot"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Acceso prioritario a la asistencia al desarrollador mediante un alias de asistencia técnica dedicado"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Recursos de la comunidad de desarrolladores, incluidos seminarios web, foros y más"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Recursos de marketing seleccionados, incluidas plantillas de relaciones públicas y guías de lanzamiento"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Patrocinio de eventos INBOUND con descuento, stands y boletos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Software descontado a través del programa de etapa semilla de HubSpot para Startups"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Boletín mensual con actualizaciones de marketing, lanzamientos de productos y mucho más"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/listing-your-app",
          children: "Cómo anunciar tu aplicación"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/certification-requirements",
          children: "Requisitos de certificación de aplicación"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "Documentación de referencia de API"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/overview",
          children: "Cómo usar las API de HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/APIs-Integrations/bd-p/integrations",
          children: "Foro de comunidades de desarrolladores"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/contact-our-partnerships-team",
          children: "Contacto con el equipo de partners de aplicaciones"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}