"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358688;
const slug = exports.slug = 'guides/api/marketing/forms';
const title = exports.title = 'API marketing | Formulaires ';
const name = exports.name = 'vNext Docs DP - Formulaires';
const metaDescription = exports.metaDescription = 'Ces points de terminaison sont utilisés pour créer et gérer des formulaires dans votre compte HubSpot.';
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Formulaires"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez un formulaire HubSpot pour recueillir des informations de leads sur vos visiteurs et vos contacts. Vous pouvez utiliser les points de terminaison indiqués ici pour configurer de nouveaux formulaires ou obtenir des détails sur des formulaires que vous avez précédemment créés. Si vous souhaitez envoyer des données de soumission de formulaire, vous pouvez utiliser l'option ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/marketing/forms/v3-legacy#submit-data-to-a-form-supporting-authentication",
        children: "Soumettre les données à un point de terminaison de formulaire"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le type du formulaire indique son objectif et est défini sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot"
      }), " par défaut. Vous pouvez utiliser les valeurs suivantes pour votre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "formType"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspot"
        }), " : ces formulaires offrent une variété de types de champs et d'options de styles et peuvent être utilisés intégrés dans des pages HubSpot ou des pages externes. Ces formulaires peuvent être créés et modifiés à l'aide des points de terminaison décrits ici. Vous pouvez également créer ces formulaires dans votre compte HubSpot, découvrez-en davantage sur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/create-forms",
          children: "la création de formulaires HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "captured"
        }), " : ces formulaires correspondent aux formulaires HTML de sites web externes. Si l'outil formulaire externe à HubSpot est activé et que le formulaire contient des soumissions sur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code",
          children: "une page suivie"
        }), ", le formulaire est automatiquement créé dans HubSpot. En savoir plus sur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/use-non-hubspot-forms",
          children: "l'utilisation de formulaires externe à HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "flow"
        }), " : il s'agit de formulaires contextuels utilisables dans des pages HubSpot ou externes. En savoir plus sur ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/forms/create-pop-up-forms",
          children: "l'outil de formulaires contextuels de HubSpot"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "blog_comment"
        }), " : ces formulaires sont créés automatiquement pour que les pages de blog HubSpot collectent des commentaires sur les articles de blog. En savoir plus sur la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/blog/set-up-and-moderate-your-blog-comments",
          children: "configuration et la modération des commentaires de blog"
        }), "."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}