"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831407;
const slug = exports.slug = 'guides/cms/content/memberships/overview';
const title = exports.title = 'Membresía';
const name = exports.name = 'EMEA ES Memberships';
const metaDescription = exports.metaDescription = 'Membresías es una característica que te permite establecer que los visitantes tengan una cuenta para acceder a contenido.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "flujo-de-usuarios-de-membres%C3%ADa",
  "label": "Flujo de usuarios de membresía",
  "parent": null
}, {
  "depth": 0,
  "id": "variables-hubl-de-membres%C3%ADa",
  "label": "Variables HubL de membresía",
  "parent": null
}, {
  "depth": 0,
  "id": "funciones-de-hubl-de-objetos-de-crm",
  "label": "Funciones de HubL de objetos de CRM",
  "parent": null
}, {
  "depth": 0,
  "id": "registrarse%2C-iniciar-sesi%C3%B3n-y-cerrar-sesi%C3%B3n",
  "label": "Registrarse, iniciar sesión y cerrar sesión",
  "parent": null
}, {
  "depth": 0,
  "id": "plantillas-de-membres%C3%ADa",
  "label": "Plantillas de membresía",
  "parent": null
}, {
  "depth": 0,
  "id": "registro-de-auditor%C3%ADa-de-membres%C3%ADa",
  "label": "Registro de auditoría de membresía",
  "parent": null
}, {
  "depth": 0,
  "id": "sso-para-membres%C3%ADas",
  "label": "SSO para membresías",
  "parent": null
}, {
  "depth": 1,
  "id": "inicios-de-sesi%C3%B3n-en-redes-sociales",
  "label": "Inicios de sesión en redes sociales",
  "parent": "sso-para-membres%C3%ADas"
}, {
  "depth": 0,
  "id": "art%C3%ADculos-y-recursos-relacionados-con-la-membres%C3%ADa",
  "label": "Artículos y recursos relacionados con la membresía",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Membresías"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Membresías es una característica que te permite establecer que los visitantes tengan una cuenta para acceder a contenido. El sistema de cuentas aprovecha HubSpot CRM y las listas de CRM con la capacidad de un visitante de crear una contraseña para tu cuenta. Los profesionales de marketing pueden ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cms-pages-editor/control-audience-access-to-pages",
        children: "crear páginas fácilmente en sus sitios a las que solo puedan acceder contactos de listas específicas en el CRM"
      }), ". Además, puedes usar la gestión de miembros para restringir el acceso a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cms-pages-editor/control-audience-access-to-pages#set-up-membership-registration-for-your-knowledge-base",
        children: "artículos de la base de conocimientos"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cms-pages-editor/control-audience-access-to-pages#set-up-membership-registration-for-your-blog",
        children: "blogs"
      }), " usando membresías."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Flujo de usuarios de membresía"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando los contactos tienen acceso al contenido, lo cual puede ocurrir cuando se unen a las listas o a través de asignación manual, se les envía un correo electrónico para registrarse en tu sitio web, donde establecen una contraseña para acceder al contenido al que tienen permiso de acceso."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Imagina un gimnasio, que desea permitir a los visitantes inscribirse en las clases y ver las clases en las que se han inscrito. Cuando un visitante se registra para una clase, el envío del formulario crea un contacto en HubSpot CRM y ese contacto se agrega a una lista basada en el envío del formulario, que se usa para otorgar acceso a una página \"Mis eventos\"."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/signup.gif",
        alt: "Un ejemplo de registro de gimnasio."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El visitante recibe un correo electrónico de registro de membresía que le permite crear una contraseña para su cuenta de membresía."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/register%20account.png",
        alt: "Formulario de registro"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ahora, cuando los visitantes inician sesión en su cuenta, el usuario puede iniciar sesión en la página privada \"Mis eventos\" utilizando el correo electrónico y la contraseña que establecieron. Debido a que el visitante ha iniciado sesión, el desarrollador que creó el contenido privado puede renderizar datos sobre el contacto registrado utilizando datos del CRM."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/my_events.gif",
        alt: "Un visitante usa su cuenta para iniciar sesión y ver las clases para las que se registró."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Variables HubL de membresía"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para algunas empresas, puede tener sentido mostrar contenido diferente según si un usuario ha iniciado sesión o no. Hay variables HubL que los desarrolladores pueden usar para verificar si un contacto está conectado actualmente en un sitio web."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La variable de HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#website-pages-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact.is_logged_in"
        })
      }), " indica si el visitante actual ha iniciado sesión en el sitio web a través de membresías. Se puede usar dentro de una sentencia ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " para representar condicionalmente cierto contenido, lo que te permite atender individualmente la experiencia de tu visitante."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request_contact.is_logged_in %}\n    You're signed in!\n{% else %}\n    <a href=\"/_hcms/mem/login\">Log In</a>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si quieres mostrar contenido diferente en la misma página en función de la membresía de la lista, puedes comprobar las membresías de la lista de contactos registrados utilizando la variable HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#website-pages-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact.list_memberships"
        })
      }), ", que devuelve un dictado de los ID de lista de los que el contacto registrado es miembro."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para personalizar el contenido sin usar suscripciones, puedes usar la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/variables#general-variables",
          children: "variable contacto"
        }), " si un visitante ha enviado un formulario en tu sitio web."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Funciones de HubL de objetos de CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Además del contenido general que se muestra condicionalmente en una página, es posible extraer información sobre los objetos dentro de tu cuenta de HubSpot, como Contactos/Empresas/Negocios/Productos, utilizando las siguientes funciones:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-associations",
          children: "Asociaciones del CRM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-object",
          children: "Objeto del CRM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-objects",
          children: "Objetos del CRM"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por razones de seguridad, solo los objetos de producto y de evento de marketing se pueden recuperar en una página de acceso público; para obtener información sobre otros tipos de objetos, debe haber una página detrás de la membresía."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request_contact.is_logged_in %}\n  {% set membership_contact = crm_object('contact', request.contact.contact_vid, 'firstname,lastname') %}\n  Welcome back, {{ membership_contact.firstname }} {{ membership_contact.lastname }}\n{% else %}\n    <a href=\"/_hcms/mem/login\">Log In</a>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Registrarse, iniciar sesión y cerrar sesión"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cuando un contacto tiene acceso a cualquier contenido en tu sitio web a través de membresías, recibirá un correo electrónico para registrarse en tu sitio web donde puede establecer una contraseña para acceder al contenido que tiene permiso para ver. En el caso de que necesites volver a enviar a un contacto un enlace para registrarte, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/cms-pages-editor/control-audience-access-to-pages#resend-the-registration-email-to-a-specific-contact",
        children: "volver a enviar su correo electrónico de registro"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las rutas de URL para el inicio/cierre de sesión del usuario son consistentes para todos los sitios de CMS de HubSpot con la funcionalidad de membresía."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "<your domain>/_hcms/mem/login"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "<your domain>/_hcms/mem/logout"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando un visitante inicia sesión en su sitio web, se envía una cookie a su navegador, lo que le permite navegar a través de tu sitio web y acceder a las páginas a las que tiene acceso a través de suscripciones sin tener que iniciar sesión de nuevo. Si un visitante cierra sesión o nunca ha iniciado sesión en tu sitio web en su navegador, se le pedirá que inicie sesión antes de poder ver el contenido."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Plantillas de membresía"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los sitios con membresías tienen algunas páginas especiales que se necesitan para facilitar la funcionalidad de membresías. Estas son dictadas por plantillas especiales del sistema. Estas plantillas de sistema son editables, lo que te permite controlar la apariencia de los diversos pasos de membresía. Para establecer qué plantillas estás usando, ve a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Configuración >"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/general",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contenido privado"
        })
      }), " y elige la pestaña ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/templates",
        children: "\"Plantillas\""
      }), ". Para crear una plantilla para configurar en estos ajustes, ve a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Marketing > Archivos y plantillas > Herramientas de diseño"
      }), ", luego en la parte superior izquierda haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Archivo > Archivo nuevo > HTML y HUBL"
      }), " y selecciona el Tipo de plantilla apropiado en el menú desplegable."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener una lista completa de las plantillas de membresía, consulta la sección de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#membership",
        children: "membresía de la documentación de plantillas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Solo las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "plantillas HTML + HubL"
        }), " pueden ser plantillas de afiliación."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Registro de auditoría de membresía"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/general",
        children: "Configuración > Contenido privado"
      }), ", puedes ver un registro de auditoría de qué visitantes han estado interactuando con el contenido detrás de las membresías. Esto te permite ver qué visitantes están viendo contenido privado."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-02-22%20at%2012.24.54%20PM.png",
        alt: "Registro de auditoría de la audiencia de contenido privado"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SSO para membresías"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["También puedes administrar todas las necesidades de permisos de acceso y autenticación de tu empresa en un solo sistema con inicio de sesión ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/sso",
        children: "único (SSO) para suscripciones."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Inicios de sesión en redes sociales"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Puedes proporcionar a los usuarios de tu lista una forma de iniciar sesión con Google o Facebook en lugar de ingresar su dirección de correo electrónico y contraseña. El proveedor de inicio de sesión en redes sociales envía la dirección de correo electrónico asociada con la cuenta de redes registrada. Esa dirección de correo electrónico se utiliza para validar si ese contacto está en una lista de contactos con acceso al contenido. Esta característica no requiere que hayas configurado la configuración de SSO."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Debes tener una página configurada en \"Registro privado requerido\" con una lista de contactos. Además, tu plantilla de inicio de sesión debe tener el módulo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "membership_social_logins"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/social",
        children: "Agregar el inicio de sesión en redes sociales a tus páginas de membresía"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Artículos y recursos relacionados con la membresía"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/essentials-for-getting-started-with-memberships",
          children: "Fundamentos de HubSpot para desarrolladores: Primeros pasos con las membresías"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/creating-a-header-nav-that-adapts-to-if-the-contact-is-logged-in",
          children: "Crear menús que se adapten a si el usuario está registrado o no"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}