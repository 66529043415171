"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611087;
const slug = exports.slug = 'guides/cms/content/templates/types/drag-and-drop-templates';
const title = exports.title = 'Plantillas de arrastrar y soltar';
const name = exports.name = '[nuevo] Plantillas - Arrastrar y soltar';
const metaDescription = exports.metaDescription = 'Las plantillas de Administrador de diseño de arrastrar y soltar son plantillas creadas con un generador visual. No se recomiendan para nuevas plantillas, ya que las plantillas codificadas brindan una mejor experiencia.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/image2-3.png';
const featuredImageAltText = exports.featuredImageAltText = 'Interfaz del administrador de diseño con módulos seleccionados';
const toc = exports.toc = [{
  "depth": 0,
  "id": "creador-de-plantillas-de-arrastrar-y-soltar",
  "label": "Creador de plantillas de arrastrar y soltar",
  "parent": null
}, {
  "depth": 1,
  "id": "m%C3%B3dulos",
  "label": "Módulos",
  "parent": "creador-de-plantillas-de-arrastrar-y-soltar"
}, {
  "depth": 1,
  "id": "grupos",
  "label": "Grupos",
  "parent": "creador-de-plantillas-de-arrastrar-y-soltar"
}, {
  "depth": 1,
  "id": "grupos-globales",
  "label": "Grupos globales",
  "parent": "creador-de-plantillas-de-arrastrar-y-soltar"
}, {
  "depth": 1,
  "id": "columnas-flexibles",
  "label": "Columnas flexibles",
  "parent": "creador-de-plantillas-de-arrastrar-y-soltar"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      code: "code",
      a: "a",
      h2: "h2",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Plantillas de arrastrar y soltar"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "No se recomiendan las plantillas de arrastrar y soltar para las nuevas plantillas."
        }), " Estas plantillas no pueden ser parte de un tema, por lo que no son compatibles con la funcionalidad del tema, como los campos de tema y los módulos de tema. Las plantillas de arrastrar y soltar ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "NOT"
        }), " son compatibles con CMS Hub Starter. Usa ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " en su lugar. Las plantillas de arrastrar y soltar no admiten varias de las características más nuevas del CMS (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/memberships/overview",
          children: "membresías"
        }), ", áreas de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "arrastrar y soltar"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections",
          children: "secciones reutilizables"
        }), ", GraphQL y muchas otras características). Carecen de extensibilidad y no proporcionan una experiencia tan buena de creador de contenido y desarrollador como las plantillas codificadas. En su lugar, recomendamos plantillas ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML + HubL codificadas"
        }), " con ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "áreas de arrastrar y soltar"
        }), ", que ofrecen una mejor experiencia para desarrolladores y profesionales de marketing. Para un inicio rápido en el CMS, recomendamos consultar el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate",
          children: "Boilerplate de temas de HubSpot,"
        }), " que se crea utilizando plantillas codificadas."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'marketing_hub-professional', 'cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "plantillas"
      }), " de arrastrar y soltar fueron diseñadas para que los usuarios menos técnicos puedan crear fácilmente un sitio web en el CMS de HubSpot. Las plantillas de arrastrar y soltar aprovechan un creador de plantillas visuales, que genera HTML + HubL de manera discreta."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Con el fin de hacer que el creador visual funcione, la salida HTML es controlada por HubSpot y no tienes control directo sobre las partes estructurales de la misma. Además, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "se carga un archivo layout.css en la página que permite una cuadrícula básica de 12 columnas"
      }), " basada en bootstrap 2. Esto hace que todos los sitios creados con plantillas de arrastrar y soltar respondan por opción predeterminada, lo que hace que las filas de contenido se apilen verticalmente, para una capacidad de respuesta más complicada y personalizada, querrás agregar tus propios estilos de respuesta."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las plantillas de arrastrar y soltar se crean en el Administrador de diseño. Cuando interactúan con ellas a través de la API o las ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "herramientas de desarrollo local"
      }), " se representan como JSON. Debido a la naturaleza de las plantillas de arrastrar y soltar, la única forma recomendada de editarlas es a través de la interfaz del Administrador de diseño. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Si prefieres codificar, usar el control de versiones o simplemente quieres poder editar usando tus herramientas preferidas localmente, las plantillas codificadas HTML + HubL ofrecen la mejor experiencia para los desarrolladores."
      }), " La ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area"
      }), " funcionalidad de las plantillas HTML + HubL también proporciona una mejor experiencia para los creadores de contenido que la interfaz de arrastrar y soltar del administrador de diseño, ya que los mantiene en el editor de contenido visual."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Creador de plantillas de arrastrar y soltar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para crear una plantilla de arrastrar y soltar, abre el Administrador de diseño y, en el buscador, crea un nuevo archivo, elige una plantilla y el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#template-types",
        children: "tipo de plantilla"
      }), " que estás creando."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las plantillas de arrastrar y soltar se componen de bloques de construcción, estos bloques son módulos, grupos, grupos globales y columnas flexibles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts",
        children: "Descubre cómo usar el creador de plantillas de arrastrar y soltar."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los módulos son componentes reutilizables que son partes editables de una página. Los módulos se componen de un fragmento de plantilla HTML + HubL, CSS, JS y campos. Los módulos los pueden colocar dentro de columnas flexibles y dnd_areas los editores de contenido, y son la forma principal en que estos administran el contenido de su sitio web. Puedes crear módulos para manejar cualquier número de funciones, búsqueda, galerías de imágenes, menús, animaciones de marketing complejas, calculadoras, comparaciones de productos, etc. Las posibilidades dependen de tu imaginación y lo que crees que proporciona una buena experiencia para los creadores de contenido. Los campos son cómo el editor de contenido controla su salida. Los módulos no son únicos para arrastrar y soltar plantillas, son un bloque de construcción central muy importante para el CMS de HubSpot. En las plantillas de arrastrar y soltar, los valores predeterminados para los campos del módulo se pueden configurar en el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template",
        children: "inspector"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Más información sobre módulos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image2-3.png",
        alt: "Interfaz de Administrador de diseño con módulos resaltados"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Grupos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los grupos son envolturas para otros grupos y módulos, pueden tener clases CSS y HTML envolvente. Los grupos se manifiestan como HTML envolvente con clases de diseño para facilitar la colocación y el tamaño de los grupos. Los grupos también pueden tener un nombre solo interno. Con esto, puedes ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts#group-modules",
        children: "agrupar los módulos"
      }), " en el Administrador de diseño, lo que facilita la identificación visual de las partes de una página. Un ejemplo de esto podría ser para barras laterales o banners."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Dado que los archivos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML + HubL"
        }), " son la ruta recomendada para nuevos sitios, columnas, secciones y filas de ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "áreas de arrastrar y soltar"
        }), ", reemplazan en gran medida los propósitos de los grupos."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Además, un desarrollador puede crear parciales y ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/global-content",
          children: "parciales globales"
        }), ", los cuales pueden contener código de forma libre en adición a las áreas de arrastrar y soltar."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Las áreas de arrastrar y soltar, los parciales y los parciales globales no son compatibles con las plantillas de arrastrar y soltar."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hubfs/image4-1.png",
        alt: "Captura de pantalla del Administrador de diseño con el grupo de barras laterales seleccionado"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Grupos globales"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los grupos globales son grupos que cuando se editan afectan a todo el sitio web y no solo a la página en la que se encuentran. Los grupos globales pueden existir en múltiples plantillas y se utilizan con mayor frecuencia para encabezados y pies de página de sitios. Los grupos globales son similares a los parciales, pero se limitan a la estructura que aplican las plantillas de arrastrar y soltar. Se pueden incrustar en archivos HTML + HubL si es necesario, pero la mayoría de las veces tiene más sentido usar un parcial global en su lugar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image1-3.png",
        alt: "Interfaz de Administrador de diseño con grupos globales de encabezado y pie de página resaltados"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Columnas flexibles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Las columnas flexibles son un tipo especial de grupo. Pueden contener un conjunto predeterminado de módulos, pero los editores de contenido pueden agregar, eliminar y mover módulos dentro de ellas. Las columnas flexibles permiten reordenar los módulos de una manera unidimensional, verticalmente hacia arriba y hacia abajo. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "A diferencia de las etiquetas dnd_area"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "las columnas flexibles no admiten secciones o las capacidades de diseño que se ofrecen a los módulos que haya dentro de ellas."
      }), " Las columnas flexibles no son exclusivas para arrastrar y soltar plantillas, hay una ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
        children: "etiqueta HubL"
      }), " que se puede usar en plantillas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts#add-a-flexible-column",
        children: "Descubre cómo agregar una columna flexible a una plantilla de arrastrar y soltar."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "En general, se recomienda usar áreas de arrastrar y soltar, ya que la mayoría de las veces son más útiles y proporcionan todas las capacidades que proporcionan las columnas flexibles."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Todavía puede haber momentos útiles para usar una columna flexible, para algo así como una barra lateral. Para las áreas de contenido principal, las áreas de arrastrar y soltar son mucho más flexibles."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hubfs/image3-2.png",
        alt: "Captura de pantalla del Administrador de diseño con el grupo de contenido principal seleccionado"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "¿Por qué usar columnas flexibles? Los sitios web no suelen ser rígidos. Se construyen y mantienen durante largos períodos de tiempo. Una página de inicio, por ejemplo, para una empresa, puede mostrar productos destacados y, con frecuencia, puede cambiar a medida que las necesidades de marketing de la empresa cambian con el tiempo. Dado que el contenido dentro de una columna flexible puede ser agregado/eliminado y modificado por los editores de contenido, la experiencia es menos dolorosa para los profesionales de marketing y permite a los desarrolladores centrarse en lo que disfrutan: crear cosas geniales en lugar de hacer ajustes menores en la página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Del mismo modo, las páginas en un sitio pueden tener diferentes necesidades estructurales. Las columnas flexibles dan a los profesionales de marketing el control para crear esas páginas utilizando módulos personalizados."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 8,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Agregar código personalizado a tus plantillas de arrastrar y soltar"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Hay un puñado de maneras de agregar código personalizado a tus plantillas de arrastrar y soltar. El método principal es a través de módulos personalizados. Sin embargo, a veces es posible que debas agregar código que envuelva módulos o grupos. Para ello, haz clic en el módulo o grupo para verlo en el inspector y encontrar el campo HTML envolvente. Agrega tu HTML allí."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["A veces es posible que debas agregar código al encabezado de tu HTML o código justo encima del ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "</body>"
          }), ". Con tu plantilla abierta, asegúrate de no tener nada seleccionado. El inspector mostrará campos para la plantilla en sí. Aquí puedes enlazar hojas de estilo y archivos javascript al mismo tiempo que agregas HTML adicional en el encabezado o justo antes de la etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "</body>"
          }), "."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Para esto usarás el ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template",
            children: "inspector"
          }), ". El inspector tiene campos en el nivel de plantilla para ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template##attach-stylesheets-and-javascript-files",
            children: "hojas de estilo, javascript"
          }), ", ", (0, _jsxRuntime.jsxs)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template#customize-your-template-s-head-and-body-options",
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "<head>"
            }), " marcado"]
          }), ", etc."]
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://designers.hubspot.com/hubfs/image5.png",
            alt: "panel de inspectores de código personalizado de Administrador de diseño"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}