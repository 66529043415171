"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921781;
const slug = exports.slug = 'guides/api/crm/search';
const title = exports.title = 'CRM-API | Suche';
const name = exports.name = 'vNext Docs DP - Suche';
const metaDescription = exports.metaDescription = 'Mit CRM-Suche-Endpunkten können Sie das Abrufen von Daten effizienter machen, indem Sie Entwicklern die Möglichkeit geben, über alle CRM-Objekttypen hinweg zu filtern, sortieren und suchen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "eine-suchanfrage-durchf%C3%BChren",
  "label": "Eine Suchanfrage durchführen",
  "parent": null
}, {
  "depth": 0,
  "id": "durchsuchbare-crm-objekte-und--interaktionen",
  "label": "Durchsuchbare CRM-Objekte und -Interaktionen",
  "parent": null
}, {
  "depth": 1,
  "id": "objekte",
  "label": "Objekte",
  "parent": "durchsuchbare-crm-objekte-und--interaktionen"
}, {
  "depth": 1,
  "id": "verlobungen",
  "label": "Verlobungen",
  "parent": "durchsuchbare-crm-objekte-und--interaktionen"
}, {
  "depth": 0,
  "id": "standardm%C3%A4%C3%9Fig-durchsuchbare-eigenschaften-durchsuchen",
  "label": "Standardmäßig durchsuchbare Eigenschaften durchsuchen",
  "parent": null
}, {
  "depth": 0,
  "id": "suchergebnisse-filtern",
  "label": "Suchergebnisse filtern",
  "parent": null
}, {
  "depth": 0,
  "id": "zuordnungen-durchsuchen",
  "label": "Zuordnungen durchsuchen",
  "parent": null
}, {
  "depth": 0,
  "id": "suchergebnisse-sortieren",
  "label": "Suchergebnisse sortieren",
  "parent": null
}, {
  "depth": 0,
  "id": "durchbl%C3%A4ttern-von-ergebnissen",
  "label": "Durchblättern von Ergebnissen",
  "parent": null
}, {
  "depth": 0,
  "id": "beschr%C3%A4nkungen",
  "label": "Beschränkungen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Suche"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit den CRM-Suche-Endpunkten können Sie Objekte, Datensätze und Interaktionen überall\nin Ihrem CRM-System filtern, sortieren und suchen. Verwenden Sie beispielsweise die\nEndpunkte, um eine Liste mit Kontakten in Ihrem Account oder eine Liste aller offenen\nDeals abzurufen. Um diese Endpunkte über eine App zu verwenden, ist ein CRM-Bereich\nerforderlich. In dieser ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "Liste der verfügbaren Bereiche"
      }), "\nerfahren Sie, welche granularen CRM-Bereiche verwendet werden können, um Ihr Ziel\nzu erreichen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eine Suchanfrage durchführen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um Ihr CRM-System zu durchsuchen, führen Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "-Anfrage an den Suche-Endpunkt des Objekts durch. CRM-Suche-Endpunkte weisen das folgende Format auf: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/{object}/search"
      }), ". Im Anfragetext berücksichtigen Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#filter-search-results",
        children: "Filter"
      }), ", um Ihre Suche nach CRM-Eigenschaften einzugrenzen. Beispielsweise würde das folgende Code-Snippet eine Liste aller Kontakte abrufen, die eine bestimmte E-Mail-Adresse des Unternehmens haben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to /crm/v3/objects/contacts/search\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"email\",\n          \"operator\": \"CONTAINS_TOKEN\",\n          \"value\": \"*@hubspot.com\"\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Jedes Objekt, das Sie suchen, enthält eine Reihe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#crm-objects",
        children: "Standardeigenschaften"
      }), ", die zurückgegeben werden. Eine Kontaktsuche gibt zum Beispiel ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdate"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastmodifieddate"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastname"
      }), " zurück. Um einen bestimmten Satz von Eigenschaften zurückzugeben, fügen Sie ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), "-Array in den Anfragetext ein."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to /crm/v3/objects/companies/search\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"annualrevenue\",\n          \"operator\": \"GT\",\n          \"value\": \"10000000\"\n        }\n      ]\n    }\n  ],\n  \"properties\": [\"annualrevenue\", \"name\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Wenn Sie ein ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), "-Array einfügen, werden die standardmäßig zurückgegebenen Eigenschaften außer Kraft gesetzt, daher müssen Sie diese im Array angeben, wenn Sie sie zurückgeben möchten."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Durchsuchbare CRM-Objekte und -Interaktionen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objekte"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgenden Tabellen enthalten die Suche-Endpunkte für Objekte, die Objekte, auf die sie sich beziehen, und die Eigenschaften, die standardmäßig zurückgegeben werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Suche-Endpunkt"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standardmäßig zurückgegebene Eigenschaften"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/carts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Warenkörbe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/companies/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unternehmen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "domain"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/contacts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Kontakte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "firstname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deals/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deals"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "dealname"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "closedate,``pipeline"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealstage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deal_split/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deal-Aufteilungen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/discounts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rabatte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/feedback_submissions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feedback-Einsendungen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/fees/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gebühren"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/invoices/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rechnungen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/leads/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Leads"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/line_items/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Artikel"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "quantity"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/orders/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bestellungen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/commerce_payments/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zahlungen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/products/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Produkte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), " ,", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/quotes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Angebote"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_expiration_date"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_public_url_key"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_status"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/subscriptions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abonnements (Commerce)"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/taxes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Steuern"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tickets/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline_stage"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_category"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_priority"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "subject"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Verlobungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die folgende Tabelle enthält die Suche-Endpunkte für Interaktionen, die Interaktionen auf die sie sich beziehen, und die Eigenschaften, die standardmäßig zurückgegeben werden."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Suche-Endpunkt"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Interaktion"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standardmäßig zurückgegebene Eigenschaften"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/calls/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anrufe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/emails/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "E-Mails"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/meetings/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Meetings"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/notes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Notizen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tasks/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aufgaben"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Standardmäßig durchsuchbare Eigenschaften durchsuchen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Durchsuchen Sie alle Standardtexteigenschaften in Datensätzen des angegebenen Objekts, um alle Datensätze zu finden, die einen Wert haben, der die angegebene Zeichenfolge enthält. Standardmäßig werden die Ergebnisse in der Reihenfolge der Objekterstellung zurückgegeben (älteste erste), aber Sie können diese mithilfe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#sorting",
        children: "Sortierung"
      }), " überschreiben. Beispielsweise wird mit der Anfrage unten nach allen Kontakten mit einem Standardtext-Eigenschaft-Wert gesucht, der den Buchstaben ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X"
      }), " enthält."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"query\": \"x\"\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachfolgend finden Sie die Eigenschaften, die standardmäßig mit der oben genannten Methode gesucht werden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Suche-Endpunkt"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standardmäßig durchsuchbare Eigenschaften"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/calls/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Anrufe"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_body_preview"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/companies/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Unternehmen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "website"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phone"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "domain"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/contacts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Kontakte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "firstname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phone"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_additional_emails"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fax"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mobilephone"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_marketable_until_renewal"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/{objectType}/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Benutzerdefinierte Objekte"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bis zu 20 ausgewählte Eigenschaften."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deals/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Deals"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "dealname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pipeline"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealstage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealtype"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/emails/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "E-Mails"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_subject"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/feedback_submissions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Feedback-Einsendungen"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_submission_name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_content"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/meetings/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Meetings"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_body"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/notes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Notizen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_note_body"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/products/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Produkte"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), " ,", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sku"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/quotes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Angebote"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_firstname"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_lastname"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_proposal_slug"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_company_name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_email"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_quote_number"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_public_url_key"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tasks/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Aufgaben"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_task_body"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_task_subject"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tickets/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "subject"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline_stage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_category"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suchergebnisse filtern"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Verwenden Sie Filter („filters“) im Anfragetext, um die Ergebnisse nur auf Datensätze mit übereinstimmenden Eigenschaftswerten zu begrenzen. Beispielsweise sucht die untenstehende Anfrage nach allen Kontakten mit dem Vornamen von ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Alice"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten"
        }), ": Beim Filtern von Suchergebnissen nach Anrufen, Konversationen, E-Mails, Meetings, Notizen oder Aufgaben wird die Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_body_preview_html"
        }), " nicht unterstützt. Bei E-Mails werden die Eigenschaften ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_email_html"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_body_preview"
        }), " ebenfalls nicht unterstützt."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n\n  --data '{\n    \"filterGroups\":[\n      {\n        \"filters\":[\n          {\n            \"propertyName\": \"firstname\",\n            \"operator\": \"EQ\",\n            \"value\": \"Alice\"\n          }\n        ]\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um mehrere Filterkriterien einzubeziehen, können Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filters"
      }), " innerhalb von ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        })
      }), " gruppieren:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "AND"
        }), "-Logik anzuwenden, fügen Sie eine durch Kommata getrennte Liste von Bedingungen innerhalb eines Satzes von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " hinzu."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "OR"
        }), "-Logik anzuwenden, berücksichtigen Sie mehrere ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " in einer ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroup"
        }), ". Sie können maximal fünf ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " mit bis zu 10 ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " in jede Gruppe aufnehmen, mit insgesamt maximal 25 Filtern. Wenn Sie zu viele Gruppen oder Filter eingeschlossen haben, erhalten Sie eine ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "VALIDATION_ERROR"
        }), "-Fehlerantwort. Beispielsweise sucht die unten stehende Anfrage nach Kontakten mit dem Vornamen ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Alice"
        }), " UND einem anderen Nachnamen als ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Smith"
        }), " ODER nach Kontakten, die keinen Wert für die Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "email"
        }), " aufweisen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"firstname\",\n          \"operator\": \"EQ\",\n          \"value\": \"Alice\"\n        },\n        {\n          \"propertyName\": \"lastname\",\n          \"operator\": \"NEQ\",\n          \"value\": \"Smith\"\n        }\n      ]\n    },\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"email\",\n          \"operator\": \"NOT_HAS_PROPERTY\"\n        }\n      ]\n    }\n  ]\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können Operatoren in Filtern verwenden, um anzugeben, welche Datensätze zurückgegeben werden sollen. Bei den Werten in den Filtern wird nicht zwischen Groß- und Kleinschreibung unterschieden, mit den folgenden beiden Ausnahmen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Beim Filtern nach einer Aufzählungseigenschaft wird bei der Suche für alle Filter Operator zwischen Groß- und Kleinschreibung unterschieden."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Wenn Sie mithilfe von ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IN"
        }), "- und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "NOT_IN"
        }), "-Operatoren nach einer Zeichenfolgeneigenschaft filtern, müssen die gesuchten Werte in Kleinbuchstaben geschrieben werden. Nachfolgend sind die verfügbaren Filteroperatoren aufgeführt:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operator"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Kleiner als der angegebene Wert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LTE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Kleiner als oder gleich dem angegebenen Wert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Größer als der angegebene Wert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GTE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Größer als oder gleich dem angegebenen Wert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EQ"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gleich dem angegebenen Wert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NEQ"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ungleich dem angegebenen Wert."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BETWEEN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Innerhalb des angegebenen Bereichs. Verwenden Sie in Ihrer Anfrage Schlüssel-Wert-Paare, um ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "highValue"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            }), " festzulegen. Beachten Sie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#between-operator",
              children: "das Beispiel unten"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["In der angegebenen Liste enthalten. Suchvorgänge nach genauer Übereinstimmung. Fügen Sie in Ihrer Anfrage die Listenwerte in ein ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            }), "-Array ein. Beim Durchsuchen einer Zeichenfolgeneigenschaft mit diesem Operator müssen die Werte in Kleinbuchstaben geschrieben werden. Beachten Sie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#in-operator",
              children: "das Beispiel unten"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_IN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Nicht in der angegebenen Liste enthalten Fügen Sie in Ihrer Anfrage die Listenwerte in ein ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            }), "-Array ein. Beim Durchsuchen einer Zeichenfolgeneigenschaft mit diesem Operator müssen die Werte in Kleinbuchstaben geschrieben werden."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_PROPERTY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hat einen Wert für die angegebene Eigenschaft."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_HAS_PROPERTY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Hat keinen Wert für die angegebene Eigenschaft."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTAINS_TOKEN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Enthält ein Token. In Ihrer Anfrage können Sie Platzhalter (*) verwenden, um eine teilweise Suche durchzuführen. Verwenden Sie beispielsweise den Wert ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "*@hubspot.com"
            }), ", um Kontakte mit einer HubSpot-E-Mail-Adresse abzurufen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_CONTAINS_TOKEN"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enthält kein Token."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können zum Beispiel den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BETWEEN"
      }), "-Operator verwenden, um nach allen Aufgaben zu suchen, die zuletzt innerhalb eines bestimmten Zeitraums geändert wurden:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/tasks/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n   \"filterGroups\":[{\n      \"filters\":[\n        {\n          \"propertyName\":\"hs_lastmodifieddate\",\n          \"operator\":\"BETWEEN\",\n          \"highValue\": \"1642672800000\",\n          \"value\":\"1579514400000\"\n        }\n      ]\n    }]\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Als weiteres Beispiel können Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IN"
      }), "-Operator verwenden, um nach Unternehmen zu suchen, für die bestimmte Werte in einer Dropdown-Eigenschaft ausgewählt sind."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/companies/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"filterGroups\":[\n      {\n        \"filters\":[\n          {\n           \"propertyName\":\"enumeration_property\",\n           \"operator\":\"IN\",\n          \"values\": [\"value_1\", \"value_2\"]\n        }\n        ]\n      }\n    ],\n   \"properties\": [\"annualrevenue\", \"enumeration_property\", \"name\"]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zuordnungen durchsuchen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Suchen Sie nach Datensätzen, die anderen spezifischen Datensätzen zugeordnet sind, indem Sie die Pseudo-Eigenschaft ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations.{objectType}"
      }), " verwenden. Beispielsweise sucht die folgende Anfrage nach allen Tickets, die einem Kontakt zugeordnet sind, der die Kontakt-ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123"
      }), " hat."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/tickets/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"filters\": [\n      {\n        \"propertyName\": \"associations.contact\",\n        \"operator\": \"EQ\",\n        \"value\": \"123\"\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Die Option zum Durchsuchen benutzerdefinierter Objektzuordnungen wird derzeit nicht über Suche-Endpunkte unterstützt. Um benutzerdefinierte Objektzuordnungen zu finden, können Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "Zuordnungs-API"
        }), " verwenden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Suchergebnisse sortieren"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Verwenden Sie eine Sortierungsregel im Anfragetext, um Ergebnisse in aufsteigender oder absteigender Reihenfolge aufzulisten. Bei jeder Suche kann nur jeweils eine Sortierungsregel angewendet werden. Beispielsweise sortiert die untenstehende Anfrage zurückgegebene Kontakte mit den zuletzt erstellten zuerst:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"sorts\": [\n      {\n        \"propertyName\": \"createdate\",\n        \"direction\": \"DESCENDING\"\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Durchblättern von Ergebnissen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Standardmäßig geben Suche-Endpunkte Seiten mit jeweils 10 Datensätzen zurück. Dies kann mithilfe des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "limit"
      }), "-Parameters im Anfragetext geändert werden. Die maximale Anzahl unterstützter Objekte pro Seite beträgt 100. Die folgende Anfrage gibt beispielsweise Seiten mit jeweils 20 Ergebnissen zurück."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"limit\": 20\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um die nächste Seite der Ergebnisse aufzurufen, müssen Sie einen in der ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "paging.next.after"
        })
      }), "-Eigenschaft der vorherigen Antwort angegebenen ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "after"
        })
      }), "-Parameter übergeben. Wenn die ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "paging.next.after"
        })
      }), "-Eigenschaft nicht angegeben wurde, gibt es keine zusätzlichen Ergebnisse zum Anzeigen. Sie müssen den Wert im ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "after"
      }), "-Parameter als Ganzzahl formatieren. Beispielsweise gibt die folgende Anfrage die nächste Seite der Ergebnisse zurück:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"after\": \"20\"\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Beschränkungen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Es kann einige Momente dauern, bis neu erstellte oder aktualisierte CRM-Objekte in Suchergebnissen angezeigt werden."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Archivierte CRM-Objekte werden nicht in Suchergebnissen angezeigt."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bei Suche-Endpunkten gibt es eine ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details",
          children: "Ratenbegrenzung"
        }), " von vier Anfragen pro Sekunde."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Eine Abfrage kann maximal 3.000 Zeichen enthalten. Wenn der Hauptteil Ihrer Anfrage 3.000 Zeichen überschreitet, wird ein 400-Fehler zurückgegeben."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Die Suche-Endpunkte sind für jede Abfrage auf 10.000 Gesamtergebnisse beschränkt. Wenn Sie versuchen, über 10.000 Seiten zu blättern, tritt ein 400-Fehler auf."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Beim ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#filter-search-results",
          children: "Filtern"
        }), " können Sie maximal fünf ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " mit bis zu 10 ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " in jede Gruppe aufnehmen, mit insgesamt maximal 25 Filtern."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Bei der Suche nach Telefonnummern verwendet HubSpot spezielle berechnete Eigenschaften, um das Format zu standardisieren. Diese Eigenschaften beginnen alle mit ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_searchable_calculated_*"
        }), ". Im Rahmen dieser Standardisierung verwendet HubSpot nur die Ortsvorwahl und die lokale Nummer. Sie sollten darauf verzichten, den Ländercode in Ihren Such- oder Filterkriterien zu berücksichtigen."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}