"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694134;
const slug = exports.slug = 'guides/cms/content/templates/drag-and-drop/tutorial';
const title = exports.title = 'Criar uma área de arrastar e soltar';
const name = exports.name = '[novo] introdução às áreas de arrastar e soltar';
const metaDescription = exports.metaDescription = 'Use áreas de arrastar e soltar para permitir que os criadores de conteúdo façam alterações de layout, estilo e conteúdo com facilidade';
const toc = exports.toc = [{
  "depth": 0,
  "id": "1.-criar-um-novo-modelo-html",
  "label": "1. Criar um novo modelo HTML",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-criar-uma-%C3%A1rea-de-arrastar-e-soltar",
  "label": "2. Criar uma área de arrastar e soltar",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-criar-uma-se%C3%A7%C3%A3o-com-um-m%C3%B3dulo",
  "label": "3. Criar uma seção com um módulo",
  "parent": null
}, {
  "depth": 0,
  "id": "4.-incluir-v%C3%A1rios-m%C3%B3dulos",
  "label": "4. Incluir vários módulos",
  "parent": null
}, {
  "depth": 0,
  "id": "5.-incorporar-colunas-e-linhas",
  "label": "5. Incorporar colunas e linhas",
  "parent": null
}, {
  "depth": 0,
  "id": "6.-definir-estilos-de-componente-gen%C3%A9ricos-de-arrastar-e-soltar",
  "label": "6. Definir estilos de componente genéricos de arrastar e soltar",
  "parent": null
}, {
  "depth": 0,
  "id": "tutoriais-relacionados",
  "label": "Tutoriais relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      strong: "strong",
      em: "em",
      ul: "ul",
      li: "li",
      h2: "h2",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Introdução às áreas de arrastar e soltar"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "áreas de arrastar e soltar"
      }), " permitem aos desenvolvedores criar seções de páginas que possibilitam fazer mudanças de layout, estilo e conteúdo diretamente nos editores de conteúdo. Isso permite que os desenvolvedores criem alguns modelos com estrutura global, que ajudam os criadores de conteúdo a criar uma variedade de páginas com várias finalidades e layouts, sem precisarem se preocupar com código ou exigir novos modelos para pequenas alterações de layout."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/a%20new%20approach.gif",
        alt: "Animação dos módulos arrastados para uma página, com colunas e linhas sendo ajustadas"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os desenvolvedores podem especificar zonas de arrastar e soltar vazias para áreas de arrastar e soltar, onde os criadores de conteúdo criam seu próprio conteúdo e layout de página, ou os desenvolvedores podem preencher previamente as áreas de arrastar e soltar com vários módulos, layouts, estilos e conteúdo para os criadores de conteúdo usares como ponto de partida."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este tutorial o guiará pela configuração de uma área de arrastar e soltar simples. Para obter mais recursos de desenvolvedor sobre áreas de arrastar e soltar, consulte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/templates/home.html",
        children: "o boilerplate"
      }), " de práticas recomendadas sobre a implementação, bem como a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "documentação de referência de tags do HubL para áreas de arrastar e soltar"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " um criador de conteúdo pode trocar o modelo de uma página por outro do mesmo tipo, dependendo se tem tags ", (0, _jsxRuntime.jsx)(_components.em, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/cms/hubl/tags/dnd-areas",
            children: "dnd_area"
          })
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos de arrastar e soltar criados com o editor visual de layout podem ser trocados por outros modelos de arrastar e soltar ou codificados com ou sem as tags ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos codificados com tags ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " somente podem ser trocados por outros modelos codificados com tags ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Os modelos codificados sem tags ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), " somente podem ser trocados por outros modelos codificados sem tags ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Criar um novo modelo HTML"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Crie um novo modelo html para hospedar o HubL e o HTML que formarão sua seção de arrastar e soltar."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["As áreas de arrastar e soltar se baseiam em uma grade de 12 colunas responsivas. As tags de arrastar e soltar renderizam marcações com nomes de classe designando as colunas e as linhas. Você é responsável por adicionar uma folha de estilos para identificar esses nomes de classe. Um exemplo de estilos de layout que você pode implementar pode ser encontrado em: ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
          children: "HubSpot CMS Boilerplate"
        }), ". Sua folha de estilos pode ser adicionada ao modelo usando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#require-css",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ require_css() }}"
          })
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Criar uma área de arrastar e soltar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " é um container que torna uma parte da página web editável em termos de estrutura, design e conteúdo. O corpo de uma tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " fornece o conteúdo padrão para a área de arrastar e soltar."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Essa tag por si só gerará uma zona onde os criadores de conteúdo poderão arrastar módulos ao criar conteúdo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n\t<!-- generates an empty drag and drop area drop-section -->\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Criar uma seção com um módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " é uma linha de nível superior e só pode ser um filho direto de um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ". As seções oferecem suporte a uma variedade de parâmetros que controlam os valores padrão dos controles de estilo que os criadores de conteúdo têm para as seções dentro do conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vamos definir uma imagem de plano de fundo, um alinhamento vertical centralizado e largura máxima de 1000 px para conteúdo secundário. Para obter uma lista completa dos parâmetros suportados nas tags de arrastar e soltar do HubL, consulte a documentação de referência de tags do HubL da área arrastar e soltar."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para preencher previamente a seção com conteúdo, podemos usar a tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), " para incluir um módulo fazendo referência ao seu caminho. Neste exemplo, estamos fazendo referência a um módulo HubSpot padrão, mas é possível incluir adicionalmente módulos que você construiu, especificando seu caminho dentro da árvore de arquivos de Ferramentas de design."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para especificar um valor padrão para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ", podemos usar a tag ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_attribute"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n    {% dnd_section\n        background_image = {\n            'backgroundPosition': 'MIDDLE_CENTER',\n            'backgroundSize': 'cover',\n            'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n          },\n          max_width=1000,\n          vertical_alignment='MIDDLE'\n    %}\n        {% dnd_module path='@hubspot/rich_text' %}\n            {% module_attribute \"html\"%}\n                This is your main headline.\n                Use this space to tell everyone about what you have to offer.\n            {% end_module_attribute %}\n        {% end_dnd_module %}\n    {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora, podemos ver que nossa área de arrastar e soltar contém um módulo que os criadores de conteúdo podem editar no editor de conteúdo. Também podemos ver como definir um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "max_width"
      }), " em ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " afeta nosso conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_rich_text.png",
        alt: "captura de tela do editor de página com a barra de ferramentas do módulo sendo exibida"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "4. Incluir vários módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para incluir mais de um módulo, podemos usar várias tags ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), ". Ao definir os parâmetros ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "offset"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), ", que se baseiam em uma grade de 12 colunas, podemos colocar um módulo de imagem ao lado do nosso módulo de rich text."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n    {% dnd_section\n        background_image={\n            'backgroundPosition': 'MIDDLE_CENTER',\n            'backgroundSize': 'cover',\n            'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n        },\n        max_width=1000,\n        vertical_alignment='MIDDLE'\n    %}\n        {% dnd_module path='@hubspot/rich_text', width=8, offset=0, label=\"Rich Text\" %}\n            {% module_attribute \"html\"%}\n            \t<h1>This is your main headline.</h1>\n            \t<p>Use this space to tell everyone about what you have to offer.</p>\n            {% end_module_attribute %}\n        {% end_dnd_module %}\n        {% dnd_module path='@hubspot/linked_image',\n          width=4,\n          offset=8,\n          img={\n            \"src\": \"https://www.dragndrop.com/placeholder-image.jpg\",\n            \"alt\": \"Stock placeholder image\"\n          }\n        %}\n        {% end_dnd_module %}\n    {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora, também temos um módulo de imagem editável, bem como uma alça para arrastar, permitindo que os criadores de conteúdo mudem a largura e o deslocamento dos módulos. Também podemos ver como definir um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "vertical_alignment"
      }), " em ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), " afeta nosso conteúdo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_rich_image.png",
        alt: "captura de tela do editor de página mostrando um módulo de imagem adicionado a uma seção"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "5. Incorporar colunas e linhas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para tornar a área de arrastar e soltar mais complexa, podemos incorporar linhas e colunas usando as tags ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), ". As linhas e colunas atuam de forma semelhante às seções no editor de conteúdo, onde os criadores de conteúdo podem arrastá-las, bem como clonar, excluir e estilizar suas linhas e colunas."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"body_dnd_area\" %}\n  {% dnd_section\n    background_image={\n        'backgroundPosition': 'MIDDLE_CENTER',\n        'backgroundSize': 'cover',\n        'imageUrl': 'https://www.dragndrop.com/bg-image.jpg'\n    },\n    max_width=1000,\n    vertical_alignment='MIDDLE'\n  %}\n    {% dnd_module path='@hubspot/linked_image',\n      width=6,\n      img={\n        \"src\": \"https://www.dragndrop.com/placeholder-image.jpg\",\n        \"alt\": \"Stock placeholder image\"\n      }\n    %}\n    {% end_dnd_module %}\n    {% dnd_column width=6, offset=6 %}\n      {% dnd_row\n        padding={\n            'bottom': 15\n        }\n      %}\n        {% dnd_module path='@hubspot/rich_text' %}\n          {% module_attribute \"html\"%}\n              <h1>This is your main headline.</h1>\n              <p>Use this space to tell everyone about what you have to offer.</p>\n          {% end_module_attribute %}\n        {% end_dnd_module %}\n      {% end_dnd_row %}\n      {% dnd_row %}\n        {% dnd_module path='@hubspot/form' %}\n        {% end_dnd_module %}\n      {% end_dnd_row %}\n    {% end_dnd_column %}\n  {% end_dnd_section %}\n{% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Agora, nossos criadores de conteúdo terão mais controle sobre o estilo e o layout de linhas e colunas específicas, bem como de módulos e seções."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/dnd_area_full.png",
        alt: "captura de tela do editor de página mostrando uma linha com duas colunas, um módulo de imagem à esquerda, um módulo de rich text e um módulo de formulário à direita."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "6. Definir estilos de componente genéricos de arrastar e soltar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os vários componentes de áreas de arrastar e soltar, seções, colunas, linhas e módulos têm classes que podem ser estilizadas usando CSS. Os estilos e opções editáveis desses componentes podem ser definidos usando CSS em vez de HubL. Por exemplo, o preenchimento padrão pode ser definido em ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_sections"
      }), " com o CSS:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: ".dnd-section {\n  padding: 80px 20px;\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os seletores CSS genéricos dos componentes da área de arrastar e soltar são ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-section"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-column"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-row"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".dnd-module"
      }), ". Além dessas classes dnd prefixadas, os nomes de classes de grade reais na marcação são baseados em nomes bootstrap 2. Isso não significa que você precisa usar o bootstrap 2 com áreas de arrastar e soltar. Quando adiciona um ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " à sua página, você é responsável por fornecer os estilos que fazem a grade funcionar. Um exemplo de estilos de layout que você pode implementar pode ser encontrado em: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/css/objects",
        children: "HubSpot CMS Boilerplate"
      }), ". Sua folha de estilos pode ser adicionada ao modelo usando ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#require-css",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ require_css() }}"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter mais recursos de desenvolvedor sobre áreas de arrastar e soltar, consulte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/tree/master/src/templates",
        children: "o boilerplate"
      }), " de práticas recomendadas sobre a implementação, bem como a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "documentação de referência de tags do HubL para áreas de arrastar e soltar"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriais relacionados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Introdução aos temas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Introdução aos módulos personalizados"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites",
          children: "Como adicionar recursos de tema a um site existente"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}