"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = exports.badge = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 169438423159;
const slug = exports.slug = 'guides/api/marketing/subscriptions';
const title = exports.title = 'Marketing API | Subscriptions v4';
const name = exports.name = 'vNext Docs - Subscriptions v4';
const metaDescription = exports.metaDescription = 'The v4 subscription preferences endpoints allow you to manage subscriptions details for contacts in your account.';
const badge = exports.badge = 'BETA';
const toc = exports.toc = [{
  "depth": 0,
  "id": "scope-requirements",
  "label": "Scope requirements",
  "parent": null
}, {
  "depth": 0,
  "id": "get-all-subscription-types",
  "label": "Get all subscription types",
  "parent": null
}, {
  "depth": 0,
  "id": "get-subscription-preferences-for-a-specific-contact",
  "label": "Get subscription preferences for a specific contact",
  "parent": null
}, {
  "depth": 0,
  "id": "get-contacts-who-unsubscribed-from-all-email-communications",
  "label": "Get contacts who unsubscribed from all email communications",
  "parent": null
}, {
  "depth": 0,
  "id": "get-a-specific-contact-who-unsubscribed-from-all-email-communications",
  "label": "Get a specific contact who unsubscribed from all email communications",
  "parent": null
}, {
  "depth": 0,
  "id": "update-subscription-preferences-for-a-specific-contact",
  "label": "Update subscription preferences for a specific contact",
  "parent": null
}, {
  "depth": 0,
  "id": "update-a-contact-s-%22opted-out-of-all-email%22-status",
  "label": "Update a contact's \"Opted out of all email\" status",
  "parent": null
}, {
  "depth": 0,
  "id": "using-batch-subscription-endpoints",
  "label": "Using batch subscription endpoints",
  "parent": null
}, {
  "depth": 1,
  "id": "get-%22opted-out-of-all-communication%22-subscription-status-for-a-list-of-contacts",
  "label": "Get \"Opted out of all communication\" subscription status for a list of contacts",
  "parent": "using-batch-subscription-endpoints"
}, {
  "depth": 1,
  "id": "get-specific-subscription-statuses-for-multiple-contacts",
  "label": "Get specific subscription statuses for multiple contacts",
  "parent": "using-batch-subscription-endpoints"
}, {
  "depth": 1,
  "id": "update-the-%22opted-out-of-all-email%22-status-for-multiple-contacts",
  "label": "Update the \"Opted out of all email\" status for multiple contacts",
  "parent": "using-batch-subscription-endpoints"
}, {
  "depth": 1,
  "id": "unsubscribe-multiple-contacts-from-specific-subscription-types",
  "label": "Unsubscribe multiple contacts from specific subscription types",
  "parent": "using-batch-subscription-endpoints"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      em: "em",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Subscriptions v4 API"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Subscription types represent the lawful basis to communicate with your contacts through email. Contacts can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/articles/kcs_article/email/how-to-send-contacts-an-email-with-an-opt-out-link-so-they-can-manage-their-preferences",
        children: "manage their email preferences"
      }), " so they're only opted in to emails they want to receive. The v4 subscriptions APIs allow you to programmatically subscribe or unsubscribe contacts from your email subscription types, or unsubscribe a contact from all email communication. These APIs also provide support for ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/branding/associate-your-assets-with-business-units",
        children: "business units"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Scope requirements"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following scopes are required to use the v4 subscription API endpoints, based on the endpoint you're using:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "communication_preferences.read"
        }), ": provides access to fetch subscription type definitions and subscription preferences for a contact."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "communication_preferences.write"
        }), ": provides access to update subscription preferences for a contact."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "communication_preferences.read_write"
        }), ": provides access to both fetch and update subscription preferences for a contact and fetch all subscription definitions in your account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "communication_preferences.statuses.batch.read"
        }), ": provides access to fetch subscription statuses in bulk."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "communication_preferences.statuses.batch.write"
        }), ": provides access to manage and update subscription statuses in bulk."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For a full list of available endpoints and example requests and responses, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/marketing/subscriptions/v4",
        children: "endpoint reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get all subscription types"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get a list of all email subscription types in your account, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/definitions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/business-units-api",
        children: "Business Units add-on"
      }), ", you can filter subscription types by business unit by including the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "businessUnitId"
      }), " as a query parameter in your request. The default ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Account"
      }), " business unit ID will always use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"businessUnitId\": 0"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The subscription types will be returned within the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), " field of the response."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response for GET request to /communication-preferences/v4/definitions\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"businessUnitId\": 41857,\n      \"id\": \"33583163\",\n      \"name\": \"Marketing Information\",\n      \"description\": \"Marketing offers and updates.\",\n      \"purpose\": \"Marketing\",\n      \"communicationMethod\": \"Email\",\n      \"isActive\": true,\n      \"isDefault\": true,\n      \"isInternal\": false,\n      \"createdAt\": \"2022-02-09T21:06:59.247Z\",\n      \"updatedAt\": \"2022-02-09T21:06:59.247Z\"\n    },\n    {\n      \"businessUnitId\": 0,\n      \"id\": \"39644612\",\n      \"name\": \"New recipe newsletter\",\n      \"description\": \"Subscription for new recipes and kitchen updates\",\n      \"purpose\": \"Marketing\",\n      \"communicationMethod\": \"Email\",\n      \"isActive\": true,\n      \"isDefault\": false,\n      \"isInternal\": false,\n      \"createdAt\": \"2022-04-14T20:37:03.073Z\",\n      \"updatedAt\": \"2022-04-14T20:37:03.073Z\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can optionally include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "includeTranslations=true"
      }), " query parameter in your request to retrieve any subscription translations associated with each definition."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you made a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/definitions?includeTranslations=true"
      }), ", the response would resemble the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response for GET request to /communication-preferences/v4/definitions with subscription translations included\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"subscriptionTranslations\": [\n        {\n          \"subscriptionId\": 88249125,\n          \"languageCode\": \"ar\",\n          \"name\": \"test\",\n          \"description\": \"test\",\n          \"updatedAt\": 1724702359758,\n          \"createdAt\": 0\n        }\n      ],\n      \"businessUnitId\": 0,\n      \"id\": \"88249125\",\n      \"name\": \"test\",\n      \"description\": \"test\",\n      \"purpose\": \"\",\n      \"communicationMethod\": \"\",\n      \"isActive\": false,\n      \"isDefault\": false,\n      \"isInternal\": false,\n      \"createdAt\": \"2022-12-22T21:06:03.522Z\",\n      \"updatedAt\": \"2024-08-26T19:59:39.926Z\"\n    }\n  ],\n  \"startedAt\": \"2024-08-30T20:17:36.744Z\",\n  \"completedAt\": \"2024-08-30T20:17:36.753Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get subscription preferences for a specific contact"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get the current subscription preferences for a specific contact, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/statuses/{subscriberIdString}?channel=EMAIL"
      }), " where the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "subscriberIdString"
      }), " is the email address of the contact."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to get the subscription preferences for a contact with an email address of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "jdoe@example.com"
      }), ", you'd make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/preferences/v4/statuses/jdoe@example.com?channel=EMAIL"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The response will include a full list of the current subscription preferences for the contact in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), " field. An example response is included below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response for GET request to /communication-preferences/v4/statuses/jdoe@example.com?channel=EMAIL\n{\n  \"status\": \"SUCCESS\",\n  \"results\": [\n    {\n      \"businessUnitId\": 41857,\n      \"channel\": \"EMAIL\",\n      \"subscriberIdString\": \"jdoe@example.com\",\n      \"subscriptionId\": 33583163,\n      \"status\": \"NOT_SPECIFIED\",\n      \"source\": \"Not specified\",\n      \"legalBasis\": null,\n      \"legalBasisExplanation\": null,\n      \"setStatusSuccessReason\": null,\n      \"timestamp\": \"2024-06-05T13:39:29.495Z\"\n    },\n    {\n      \"businessUnitId\": 0,\n      \"channel\": \"EMAIL\",\n      \"subscriberIdString\": \"jdoe@example.com\",\n      \"subscriptionId\": 39644612,\n      \"status\": \"SUBSCRIBED\",\n      \"source\": \"Self Service Resubscription\",\n      \"legalBasis\": \"CONSENT_WITH_NOTICE\",\n      \"legalBasisExplanation\": \"Contact provided explicit consent via form.\",\n      \"setStatusSuccessReason\": null,\n      \"timestamp\": \"2023-02-09T20:13:19.046Z\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Based on whether the contact explicitly opted in or opted out to a given a subscription, they can have the following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "status"
      }), " for a subscription type:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "SUBSCRIBED"
        }), ": contact opted into the subscription type."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "UNSUBSCRIBED"
        }), ": contact opted out of the subscription type."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "NOT_SPECIFIED"
        }), ": contact hasn't provided opt-in preference for the subscription type."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/marketing-email/understand-opt-in-consent-for-email",
        children: "opt-in consent for email"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get contacts who unsubscribed from all email communications"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Contacts can also opt out of all email communications from your business. To get a list of all contacts who are currently opted out of all email subscription types, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/statuses/batch/unsubscribe-all/read"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/branding/manage-brands-with-business-unit",
        children: "Business Units add-on"
      }), ", you'll see the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "wideStatusType: \"BUSINESS_UNIT_WIDE\""
      }), " field in the response. Note that the default ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Account"
      }), " business unit will always use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"businessUnitId\": 0"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get a specific contact who unsubscribed from all email communications"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To check whether a specific contact is unsubscribed from all email subscription types, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/statuses/{subscriberIdString}/unsubscribe-all"
      }), ", where the subscriberIdString is the email address of the contact."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/branding/manage-brands-with-business-unit",
        children: "Business Units add-on"
      }), ", you'll see the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "wideStatusType: \"BUSINESS_UNIT_WIDE\""
      }), " field in the response. Note that the default ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Account"
      }), " business unit will always use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "\"businessUnitId\": 0"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to check whether a contact with an email address of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "jdoe@example.com"
      }), " has opted out of all email communications, you'd make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/statuses/jdoe@example.com/unsubscribe-all"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update subscription preferences for a specific contact"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update the subscription preferences for a contact, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/statuses/{subscriberIdString}"
      }), ", where the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "subscriberIdString"
      }), " is the email address of the contact. In the request body, you'll need to include the fields listed the table below:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subscriptionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The internal ID of the subscription type. You can get a full list of subscription IDs by making a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "/communication-preferences/v4/statuses/&#123;subscriberIdString&#125;"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "statusState"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The opt-in or opt-out state that you want to update the contact's subscription to. The possible values are ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SUBSCRIBED"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "UNSUBSCRIBED"
            }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_SPECIFIED"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "legalBasis"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The legal reason for changing the subscriber's status. If you data privacy settings turned on, this field is required, along with the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "legalBasisExplanation"
            }), " field."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "legalBasisExplanation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An explanation for the legal basis you provided for updating the subscriber status."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "channel"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "string"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The channel type for the subscription type. Currently, the only supported channel type is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, the request body below would subscribe a contact into the subscription associated with the internal ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "39644612"
      }), ". You can fetch a list of all subscription types available to get their IDs by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/definitions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for POST request to /communication-preferences/v4/statuses/jdoe@exampl.ecom\n{\n  \"subscriptionId\": 39644612,\n  \"statusState\": \"SUBSCRIBED\",\n  \"legalBasis\": \"LEGITIMATE_INTEREST_OTHER\",\n  \"legalBasisExplanation\": \"Contact mentioned that they mistakenly unsubscribed and they'd like to opt back into our newsletter.\",\n  \"channel\": \"EMAIL\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update a contact's \"Opted out of all email\" status"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To unsubscribe a contact from all email communication in an account or specific business unit (i.e., \"Opted out of all\"), make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communications-preferences/v4/statuses/{subscriberIdString}/unsubscribe-all"
      }), ", where the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "subscriberIdString"
      }), " is the email address of the contact."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you have the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/branding/manage-brands-with-business-unit",
          children: "Business Units add-on"
        }), ", you'll also need to include the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "businessUnitId"
        }), " query parameter in your request. Note that the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Account"
        }), " business unit ID will always use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"businessUnitId\": 0"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You can optionally include the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "verbose"
        }), " query parameter to include the details of the updated subscription statuses the contact has unsubscribed from in the response. If you don't use the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "verbose"
        }), " query parameter, the resulting response will be empty."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Following a successful ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request, the contact will be unsubscribed from all email communication from your account. If you have the Business Units add-on, the contact will be unsubscribed from all email from the business unit specified in your request, but will still be eligible to receive email from other business units in your account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Using batch subscription endpoints"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have an ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " account, you can use the bulk subscription endpoints detailed below to fetch and manage subscription statuses for multiple contacts in a single API request."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get \"Opted out of all communication\" subscription status for a list of contacts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get a list of the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Opted out of all communication"
      }), " statuses for multiple contacts across an account or for a specific business unit, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/statuses/batch/unsubscribe-all/read"
      }), ", and provide the following query parameters:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "businessUnitId"
        }), ": if you have the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/branding/manage-brands-with-business-unit",
          children: "Business Units add-on"
        }), ", you can include this parameter to specify which business unit your contacts will be opted out of all subscription types from. If you don't provide this query parameter in the URL of your request, then all statuses for the account will be returned across all business units."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "channel"
        }), ": the communication type to unsubscribe all contacts out of. Currently, the only the supported channel is ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "EMAIL"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the body of your request, provide a list of the email addresses for the associated contacts you want to retrieve using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), " field:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for unsubscribing multiple contacts from all subscriptions in an account or business unit\n{\n  \"inputs\": [\"test1@hubspot.com\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you made a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/statuses/batch/unsubscribe-all/read?channel=EMAIL"
      }), ", the resulting response would resemble the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response body for POST request to /communication-preferences/v4/statuses/batch/unsubscribe-all/read\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"subscriberIdString\": \"test1@husbpot.com\",\n      \"wideStatuses\": [\n        {\n          \"businessUnitId\": 0,\n          \"wideStatusType\": \"PORTAL_WIDE\",\n          \"subscriberIdString\": \"string\",\n          \"status\": \"SUBSCRIBED\",\n          \"channel\": \"EMAIL\",\n          \"timestamp\": \"2024-08-02T21:37:58.597Z\"\n        }\n      ]\n    },\n    {\n      \"subscriberIdString\": \"test2@hubspot.com\",\n      \"wideStatuses\": [\n        {\n          \"businessUnitId\": 0,\n          \"wideStatusType\": \"PORTAL_WIDE\",\n          \"subscriberIdString\": \"string\",\n          \"status\": \"SUBSCRIBED\",\n          \"channel\": \"EMAIL\",\n          \"timestamp\": \"2024-05-22T12:151:01.145Z\"\n        }\n      ]\n    }\n  ],\n  \"startedAt\": \"2024-08-02T19:25:35.063Z\",\n  \"completedAt\": \"2024-08-02T19:25:35.114Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get specific subscription statuses for multiple contacts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To get the subscription statuses of multiple contacts in an account or for a specific business unit, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/statuses/batch/read"
      }), ". If you have the Business Units add-on, you can include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "businessUnitId"
      }), " query parameter to specify which business unit your contacts will be opted out of all subscription types from."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the body of your request, provide a list of the email addresses for the associated contacts you want to opted out of all email communications using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), " field:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for unsubscribing multiple contacts from all subscriptions in an account or business unit\n{\n  \"inputs\": [\"test1@hubspot.com\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you made a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/statuses/batch/read?channel=EMAIL"
      }), ", the resulting response would resemble the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response body for POST request to /communication-preferences/v4/statuses/batch/read\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"subscriberIdString\": \"test@husbpot.com\",\n      \"statuses\": [\n        {\n          \"businessUnitId\": 0,\n          \"channel\": \"EMAIL\",\n          \"subscriberIdString\": \"test@husbpot.com\",\n          \"subscriptionId\": 88221657,\n          \"status\": \"UNSUBSCRIBED\",\n          \"source\": \"Public status API\",\n          \"legalBasis\": null,\n          \"legalBasisExplanation\": null,\n          \"setStatusSuccessReason\": null,\n          \"timestamp\": \"2024-08-02T19:28:39.390Z\"\n        }\n      ]\n    }\n  ],\n  \"startedAt\": \"2024-08-02T21:50:28.203Z\",\n  \"completedAt\": \"2024-08-02T21:50:28.245Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Update the \"Opted out of all email\" status for multiple contacts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To unsubscribe multiple contacts from all subscription types in an account or for a specific business unit, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/statuses/batch/unsubscribe-all"
      }), ", and provide the following query parameters in your request:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "businessUnitId"
        }), ": if you have the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/branding/manage-brands-with-business-unit",
          children: "Business Units add-on"
        }), ", you can include this parameter to specify which business unit your contacts will be opted out of all subscription types from."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "channel"
        }), ": the communication type to unsubscribe all contacts out of. Currently, the only the supported channel is ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "EMAIL"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "verbose"
        }), ": an optional boolean value that controls if the endpoint returns all the subscriptions that were impacted for all contacts."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the body of your request, provide a list of the email addresses for the associated contacts you want to opted out of all email communications using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), " field:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for unsubscribing multiple contacts from all subscriptions in an account or business unit\n{\n  \"inputs\": [\"test1@hubspot.com\", \"test2@hubspot.com\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if you made a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/statuses/batch/unsubscribe-all?channel=EMAIL&verbose=true"
      }), ", the resulting response would resemble the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response for POST request to /communication-preferences/v4/statuses/batch/unsubscribe-all?channel=EMAIL&verbose=true\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"subscriberIdString\": \"test1@husbpot.com\",\n      \"statuses\": [\n        {\n          \"businessUnitId\": 0,\n          \"channel\": \"EMAIL\",\n          \"subscriberIdString\": \"test@husbpot.com\",\n          \"subscriptionId\": 87914424,\n          \"status\": \"UNSUBSCRIBED\",\n          \"source\": \"Public status API\",\n          \"legalBasis\": null,\n          \"legalBasisExplanation\": null,\n          \"setStatusSuccessReason\": \"UNSUBSCRIBE_FROM_ALL_OCCURRED\",\n          \"timestamp\": \"2024-08-02T19:28:39.390Z\"\n        }\n      ]\n    },\n    {\n      \"subscriberIdString\": \"test2@husbpot.com\",\n      \"statuses\": [\n        {\n          \"businessUnitId\": 0,\n          \"channel\": \"EMAIL\",\n          \"subscriberIdString\": \"test2@husbpot.com\",\n          \"subscriptionId\": 87914424,\n          \"status\": \"UNSUBSCRIBED\",\n          \"source\": \"Public status API\",\n          \"legalBasis\": null,\n          \"legalBasisExplanation\": null,\n          \"setStatusSuccessReason\": \"UNSUBSCRIBE_FROM_ALL_OCCURRED\",\n          \"timestamp\": \"2024-08-02T19:28:39.390Z\"\n        }\n      ]\n    }\n  ],\n  \"startedAt\": \"2024-08-02T19:25:35.063Z\",\n  \"completedAt\": \"2024-08-02T19:25:35.114Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Unsubscribe multiple contacts from specific subscription types"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update the specific subscription types of multiple contacts in an account or for a specific business unit, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/communication-preferences/v4/statuses/batch/write"
      }), ", and provide the details of the subscription updates in the inputs field in the body of your request. For example, the following request body would subscribe the contact with an email address of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "test@hubspot.com"
      }), (0, _jsxRuntime.jsx)(_components.a, {
        href: "mailto:test@hubspot.com"
      }), " to the subscription with an ID of 123:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body for POST request to /communication-preferences/v4/statuses/batch/write\n{\n  \"inputs\": [\n    {\n      \"subscriptionId\": 123,\n      \"statusState\": \"SUBSCRIBED\",\n      \"legalBasis\": \"LEGITIMATE_INTEREST_PQL\",\n      \"legalBasisExplanation\": \"string\",\n      \"channel\": \"EMAIL\",\n      \"subscriberIdString\": \"test@hubspot.com\"\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For the example request body above, the resulting response would resemble the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response for POST request to /communication-preferences/v4/statuses/batch/write\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"businessUnitId\": 0,\n      \"channel\": \"EMAIL\",\n      \"subscriberIdString\": \"test@husbpot.com\",\n      \"subscriptionId\": 63722844,\n      \"status\": \"UNSUBSCRIBED\",\n      \"source\": \"Public status API\",\n      \"legalBasis\": null,\n      \"legalBasisExplanation\": null,\n      \"setStatusSuccessReason\": \"RESUBSCRIBE_OCCURRED\",\n      \"timestamp\": \"2024-08-02T21:46:29.110Z\"\n    }\n  ],\n  \"startedAt\": \"2024-08-02T21:46:29.088Z\",\n  \"completedAt\": \"2024-08-02T21:46:29.228Z\"\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}