"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694132;
const slug = exports.slug = 'guides/cms/content/modules/overview';
const title = exports.title = 'Resumen de los módulos';
const name = exports.name = 'Resumen de los módulos';
const metaDescription = exports.metaDescription = 'Entender los módulos es clave para entender el CMS de HubSpot y su potencia. Los módulos son componentes reutilizables que se pueden usar en plantillas o agregarse a páginas por medio de áreas de arrastrar y soltar y columnas flexibles.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/team-member-editor.png';
const featuredImageAltText = exports.featuredImageAltText = 'Editor del módulo de miembros del equipo';
const toc = exports.toc = [{
  "depth": 0,
  "id": "un-ejemplo",
  "label": "Un ejemplo",
  "parent": null
}, {
  "depth": 1,
  "id": "la-interfaz-de-usuario-para-la-edici%C3%B3n",
  "label": "La interfaz de usuario para la edición",
  "parent": "un-ejemplo"
}, {
  "depth": 0,
  "id": "m%C3%B3dulo-frente-a-instancia-de-m%C3%B3dulo",
  "label": "Módulo frente a instancia de módulo",
  "parent": null
}, {
  "depth": 0,
  "id": "fields.json",
  "label": "Fields.json",
  "parent": null
}, {
  "depth": 1,
  "id": "uso-de-los-datos-de-los-campos-del-m%C3%B3dulo-para-la-representaci%C3%B3n-de-html",
  "label": "Uso de los datos de los campos del módulo para la representación de HTML",
  "parent": "fields.json"
}, {
  "depth": 0,
  "id": "usar-m%C3%B3dulos-en-plantillas",
  "label": "Usar módulos en plantillas",
  "parent": null
}, {
  "depth": 0,
  "id": "los-m%C3%B3dulos-son-una-gran-herramienta-en-la-caja-de-herramientas-de-la-accesibilidad",
  "label": "Los módulos son una gran herramienta en la caja de herramientas de la accesibilidad",
  "parent": null
}, {
  "depth": 0,
  "id": "los-m%C3%B3dulos-pueden-facilitar-la-localizaci%C3%B3n",
  "label": "Los módulos pueden facilitar la localización",
  "parent": null
}, {
  "depth": 0,
  "id": "primeros-pasos",
  "label": "Primeros pasos",
  "parent": null
}, {
  "depth": 0,
  "id": "ir-m%C3%A1s-all%C3%A1",
  "label": "Ir más allá",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ol: "ol",
      li: "li",
      h2: "h2",
      img: "img",
      h3: "h3",
      ul: "ul",
      strong: "strong",
      pre: "pre",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Resumen de los módulos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Entender los módulos es clave para entender el CMS de HubSpot y su potencia. Los módulos son componentes reutilizables que se pueden usar en plantillas o agregarse a páginas por medio de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "áreas de arrastrar y soltar"
      }), " y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
        children: "columnas flexibles"
      }), ". Además de utilizar los módulos que HubSpot proporciona, los desarrolladores pueden crear sus propios módulos para todo, desde testimonios hasta galerías de fotos. Los módulos se crean ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "con las herramientas de desarrollo locales"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/create-and-edit-modules",
        children: "con el Administrador de diseño"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un módulo consta de dos partes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Una ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#the-user-interface-for-editing",
          children: "interfaz de usuario"
        }), " creada a través de una lista de campos que los usuarios verán al editar una instancia del módulo."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un fragmento de plantilla HTML+HubL con CSS y JS asociados que define cómo se generará el HTML"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Un ejemplo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para entender mejor lo que es un módulo, veamos un simple módulo de \"Miembro del equipo\". El módulo consiste en una foto, el nombre del miembro del equipo, su título y una breve biografía, y cuando forma parte de una página web del CMS:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/team-member-module-instance.png",
        alt: "Instancia del módulo de miembros del equipo"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "La interfaz de usuario para la edición"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "El desarrollador construye la interfaz de usuario (UI) de los módulos mediante campos. A continuación, el desarrollador elige qué campos utilizar en función del tipo de módulo que se está construyendo, los datos que se necesitan y la experiencia de edición. En este caso, el módulo incluye:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un campo de imagen, para la foto de un miembro del equipo"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Dos campos de texto, para el nombre y el cargo del miembro del equipo"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Y un campo de texto enriquecido, para una breve biografía."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando un creador de contenido edita un módulo, la interfaz de usuario se construye basándose en los campos que el desarrollador ha agregado al módulo y en cómo está configurado cada campo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/team-member-editor.png",
        alt: "Editor del módulo de miembros del equipo"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Módulo frente a instancia de módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay dos términos que se utilizan con frecuencia en relación con los módulos. Es importante entender la diferencia entre ellos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Módulo"
        }), " - bloques de construcción reutilizables que se pueden agregar a las plantillas y páginas."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Instancia de módulo"
        }), " - los módulos individuales renderizados en la página. Pueden tener valores de campo separados y, como resultado, tener un aspecto diferente de otras instancias de módulos que son para el mismo módulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fields.json"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los campos de un módulo se definen en JSON como una matriz de objetos. Cada campo tiene un nombre, un tipo y un valor predeterminado. También hay otras propiedades disponibles en función del tipo de campo que controla la experiencia de edición."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n[\n  {\n    \"name\": \"team_member_photo\",\n    \"label\": \"Team Member Photo\",\n    \"required\": true,\n    \"responsive\": true,\n    \"resizable\": true,\n    \"type\": \"image\",\n    \"default\": {\n      \"src\": \"\",\n      \"alt\": \"\"\n    }\n  },\n  {\n    \"name\": \"team_member_name\",\n    \"label\": \"Team member name\",\n    \"required\": true,\n    \"type\": \"text\",\n    \"default\": \"Joshua Beck\"\n  },\n  {\n    \"name\": \"team_member_position\",\n    \"label\": \"Team member position\",\n    \"required\": true,\n    \"type\": \"text\",\n    \"default\": \"CEO, Co-Founder\"\n  },\n  {\n    \"name\": \"team_member_bio\",\n    \"label\": \"Team member bio\",\n    \"required\": true,\n    \"type\": \"richtext\",\n    \"default\": \"<p>Joshua has over 20 years of experience in the tech industry. He helped start this company in 2015 with the mission of helping people grow. In his spare time he loves hanging out with his kids, going to the beach, and cooking.</p>\"\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obtener más información sobre todos los campos disponibles, consulta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "Campos de Módulos y Temas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Uso de los datos de los campos del módulo para la representación de HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los valores de cada campo están disponibles en el fragmento HTML+HubL de un módulo a través de una variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ". Se puede acceder a los datos de cada campo a través de las propiedades de la variable del módulo. Utilizando el módulo de miembros del equipo como ejemplo, se puede acceder al nombre del miembro del equipo a través de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ module.team_member_name }}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<section class=\"team-member\">\n  <img class=\"team-member__image\" src=\"{{ module.team_member_image.src }}\" alt=\"{{ module.team_member_image.alt }}\">\n  <h3 class=\"team-member__name\">{{ module.team_member_name }}</h3>\n  <p class=\"team-member__position\">{{ module.team_member_position }}</p>\n  <div class=\"team-member__bio\">{{ module.team_member_bio }}</div>\n</section>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar módulos en plantillas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los módulos se agregan a las plantillas utilizando la etiqueta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates#basic-module-syntax",
        children: "module"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/using-modules-in-templates#block-syntax",
        children: "module_block"
      }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_module"
      }), " y especificando la ruta del módulo como parámetro. Los valores predeterminados de los campos de un módulo también pueden anularse a nivel de plantilla agregando parámetros a la etiqueta del módulo que corresponde al nombre del campo, como se muestra en la segunda parte del ejemplo siguiente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"unique_identifier\" path=\"/modules/team-member.module\" %}\n\n\n{# override default values in a module instance #}\n\n{% module \"unique_identifier\"\n  path=\"/modules/team-member.module\",\n  team_member_name=\"Brian Halligan\",\n  team_member_position=\"CEO\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Los módulos no pueden agregarse unos dentro de otros. La mayoría de las veces que se quiere hacer esto, suele ser por razones de diseño. Las secciones en las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "áreas de arrastrar y soltar"
        }), ", son a menudo el mejor curso de acción."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Los módulos son una gran herramienta en la caja de herramientas de la accesibilidad"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Los módulos se utilizan en todo un sitio web, a veces en varias páginas, incluso varias veces en una página. Por ello, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "construir el HTML, el CSS y el JS de tu módulo teniendo en cuenta la accesibilidad"
      }), " puede tener un efecto profundo en la facilidad de uso de tu sitio para las personas con y sin discapacidades o impedimentos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Los módulos pueden facilitar la localización"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En un sentido similar al de la accesibilidad, la construcción de módulos de manera que todo el contenido del módulo se base en campos, hace posible la localización posterior. Por ejemplo, podrías tener un módulo como \"Artículos destacados\". En lugar de codificar el texto \"Artículos destacados\", utiliza un campo de texto o de texto enriquecido. A continuación, se puede cambiar el texto para otros idiomas. Para saber más sobre la localización en el CMS, consulta el apartado ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/multi-language-content",
        children: "varios idiomas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Primeros pasos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para empezar, consulta nuestro tutorial de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/quickstart",
        children: "Primeros pasos con módulos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ir más allá"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "Configurar un módulo"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates",
          children: "Usar módulos en plantillas"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "Módulos predeterminados"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Editor de módulos"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}