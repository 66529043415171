"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 102629641400;
const slug = exports.slug = 'guides/crm/overview';
const title = exports.title = 'Übersicht über Tools zur CRM-Entwicklung (BETA)';
const name = exports.name = 'Übersicht über Tools zur CRM-Entwicklung (BETA)';
const metaDescription = exports.metaDescription = 'Die HubSpot-API ermöglicht Entwicklern die Erstellung benutzerdefinierter Apps und Integrationen. Legen Sie los, indem Sie sich unsere API-Übersicht durchlesen und einen Entwickler-Account erstellen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tools-zur-crm-entwicklung",
  "label": "Tools zur CRM-Entwicklung",
  "parent": null
}, {
  "depth": 1,
  "id": "projekte",
  "label": "Projekte",
  "parent": "tools-zur-crm-entwicklung"
}, {
  "depth": 1,
  "id": "private-apps",
  "label": "Private Apps",
  "parent": "tools-zur-crm-entwicklung"
}, {
  "depth": 1,
  "id": "ui-erweiterungen",
  "label": "UI-Erweiterungen",
  "parent": "tools-zur-crm-entwicklung"
}, {
  "depth": 1,
  "id": "serverlose-funktionen",
  "label": "Serverlose Funktionen",
  "parent": "tools-zur-crm-entwicklung"
}, {
  "depth": 1,
  "id": "entwicklungs-sandboxes",
  "label": "Entwicklungs-Sandboxes",
  "parent": "tools-zur-crm-entwicklung"
}, {
  "depth": 1,
  "id": "github-integration",
  "label": "GitHub-Integration",
  "parent": "tools-zur-crm-entwicklung"
}, {
  "depth": 0,
  "id": "erste-schritte",
  "label": "Erste Schritte",
  "parent": null
}, {
  "depth": 0,
  "id": "weitere-ressourcen",
  "label": "Weitere Ressourcen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      img: "img",
      h2: "h2",
      h3: "h3",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      code: "code",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Übersicht über Tools zur CRM-Entwicklung (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['sales_hub-enterprise', 'service_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Mit den Tools zur CRM-Entwicklung von HubSpot können Sie Ihr CRM mit UI-Erweiterungen anpassen. Funktionen wie Entwicklungs-Sandboxes, Versionskontrolle und GitHub-Integration optimieren Ihren Entwicklungsprozess. Beginnend in Ihrer lokalen Umgebung können Sie diese Tools nutzen, um UI-Erweiterungen für CRM-Datensätze zu erstellen und bereitzustellen, um das Nutzererlebnis anzupassen und Daten nach Bedarf zu senden und abzurufen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sie können beispielsweise eine App-Karte erstellen, die Daten aus einer externen Quelle abruft."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/custom-extension-example.png",
        alt: "custom-extension-example"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie mehr über die Tools zur CRM-Entwicklung und wie Sie mit ihrer Verwendung beginnen können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tools zur CRM-Entwicklung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Projekte"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Projekte sind die allgemeinen Container, die Sie mit den Tools zur CRM-Entwicklung erstellen. Projekte ermöglichen es Ihnen, private Apps, UI-Erweiterungen und serverlose Funktionen mithilfe des CLI lokal zu erstellen und bereitzustellen. Nach der Bereitstellung in einem Account können Sie das Projekt und seine Apps und UI-Erweiterungen in HubSpot anzeigen und verwalten. Dazu gehören das Anzeigen des Build-Verlaufs und die Überwachung von API-Aufrufen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-a-project",
        children: "Erstellen von Projekten"
      }), " und sehen Sie sich die wachsende Liste von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "Beispielprojekten"
      }), " von HubSpot an, um Beispiele für die Möglichkeiten zu sehen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Private Apps"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Private Apps ermöglichen Ihnen das Senden und Abrufen von Daten unter Verwendung des Zugriffstokens der privaten App zur Authentifizierung. Private Apps führen auch serverlose Funktionen aus, die als Backend für UI-Erweiterungen dienen. Ein Projekt kann eine private App enthalten, und jede private App kann mehrere UI-Erweiterungen und serverlose Funktionen enthalten."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-private-apps-with-projects",
        children: "Erstellen von privaten Apps"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Private Apps, die mit Projekten erstellt werden, unterstützen derzeit nur die Erstellung von UI-Erweiterungen. Wenn Sie eine private App erstellen möchten, die mit den anderen Erweiterungen von HubSpot zusammenarbeitet, erfahren Sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "in diesem Artikel"
        }), " mehr über die verschiedenen Arten von Apps und was sie unterstützen."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "UI-Erweiterungen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "UI-Erweiterungen sind benutzerdefinierte UI-Elemente, die Benutzer im HubSpot-Account anzeigen und mit denen sie interagieren können. Zu den möglichen UI-Erweiterungen gehören derzeit App-Karten für CRM-Datensätze und den Helpdesk. Sie können z. B. eine benutzerdefinierte Karte erstellen, die es Benutzern ermöglicht, von jedem Kontaktdatensatz aus Formulardaten an eine externe Datenbank zu übermitteln, oder eine Karte, mit der Kundendienstmitarbeiter offene Tickets auf der Helpdesk-Benutzeroberfläche bearbeiten können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "UI-Erweiterungen werden mithilfe von Projekten erstellt und bestehen aus einem Front-End und Back-End:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "UI-Erweiterungs-Frontend:"
        }), " der benutzerorientierte Teil der Erweiterung in Form einer App-Karte, die aus ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/overview",
          children: "von HubSpot bereitgestellten Komponenten"
        }), " besteht. Neben der Anzeige von Informationen können die Benutzer mit den Komponenten interagieren, um eine Reihe von Aktionen durchzuführen. Sie erstellen das Frontend entweder mit React oder TypeScript."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "UI-Erweiterungs-Backend:"
        }), " die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/serverless-functions",
          children: "serverlose Funktion"
        }), ", die es einer UI-Erweiterung ermöglicht, Daten zur Anzeige in Komponenten zu senden und abzurufen. Auf Basis der privaten App des Projekts kann eine serverlose Funktion bei Bedarf von mehreren Komponenten wiederverwendet werden."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die App-Karten, die Sie mit Projekten erstellen können, sind getrennt von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/extensions/overview",
        children: "HubSpots anderer API-Erweiterung für CRM-Karten"
      }), ". Sie können nicht untereinander austauschbar erstellt werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/overview",
        children: "die Funktionsweise von UI-Erweiterungen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Serverlose Funktionen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine serverlose Funktion führt serverseitiges JavaScript aus, um Backend-Funktionalität für eine UI-Erweiterung bereitzustellen. Serverlose Funktionen sind in privaten Apps enthalten. Serverlose Funktionen bestehen aus einem Ordner, der eine oder mehrere JavaScript-Dateien enthält, die eine ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "main"
      }), " Funktion exportieren, und einer ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "serverless.json"
      }), "-Datei, die Ihre Funktionen registriert und konfiguriert."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/serverless-functions",
        children: "Erstellen von serverlosen Funktionen mit Projekten"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Obwohl das Konzept ähnlich ist, unterscheiden sich die in Projekten verwendeten serverlosen Funktionen von den serverlosen Funktionen, die von CMS Hub verwendet werden. Einige dieser Unterschiede sind:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Sie können eine serverlose Funktion in einem Projekt nicht mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "hs create function"
          }), "-Befehl erstellen."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Serverlose Funktionen, die in Projekten erstellt werden, können nicht für CMS-Seiten verwendet werden."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "In Projekten erstellte serverlose Funktionen werden nicht im Design-Manager angezeigt."
        }), "\n"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Um serverlose Funktionen für CMS-Seiten zu erstellen, sehen Sie sich die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions",
          children: "CMS-Entwicklerdokumentation"
        }), " an."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Entwicklungs-Sandboxes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit Entwicklungs-Sandboxes können Sie Projekte in einer leichtgewichtigen Testumgebung entwickeln, um sicherzustellen, dass die Komponenten Ihres Projekts wie erwartet funktionieren, bevor Sie sie in einer ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "Standard-Sandbox oder einem Produktions-Account bereitstellen"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Entwicklungs-Sandboxes werden über das CLI erstellt und können innerhalb des HubSpot-Produktions-Accounts aufgerufen werden. Entwicklungs-Sandboxes synchronisieren bei der Erstellung einige, aber nicht alle Account-Elemente und unterliegen im Vergleich zu Standard-Sandboxes zusätzlichen Beschränkungen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Erfahren Sie mehr über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/developer-projects-setup",
        children: "Einrichten einer Entwicklungs-Sandbox"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "GitHub-Integration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie GitHub für die Versionskontrolle bevorzugen, können Sie ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/link-a-github-repository-to-a-project",
        children: "GitHub-Repository mit einem Projekt"
      }), " verknüpfen, um automatisch Projekt-Builds auszulösen, wenn Sie eine Änderung in das verknüpfte Repository übertragen. So können Sie GitHub-Tools und -Workflows nutzen, um das Entwickeln zu optimieren, egal ob Sie allein oder im Team arbeiten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Erste Schritte"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/user-management/hubspot-user-permissions-guide#super-admin",
        children: "Super-Admin"
      }), " in einem ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Sales Hub"
      }), " oder _ Service Hub ** Enterprise **_-Account sind, können Sie über die ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Produkt-Updates-Seite"
      }), " in Ihrem HubSpot-Account an dieser Beta-Version teilnehmen:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Klicken Sie in Ihrem HubSpot-Account auf Ihren ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Account-Namen"
          }), " in der oberen rechten Ecke und dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Produkt-Updates"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Wählen Sie in der linken Seitenleiste ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Beta-Version"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Suchen Sie in der Liste der Beta-Versionen nach der Beta ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "CRM-Entwicklungstools zur Erstellung von UI-Erweiterungen mit React als Frontend"
          }), " oder blättern Sie dorthin und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "An Beta teilnehmen"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/crm-development-tools-join-beta.png",
            alt: "crm-development-tools-join-beta"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Nachdem Sie der Beta-Version beigetreten sind, können Sie mit einer der folgenden Optionen beginnen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Befolgen Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/ui-extensions/quickstart",
          children: "Schnellstartanleitung"
        }), ", um schnell eine funktionierende Beispiel-App-Karte zu erstellen und bereitzustellen."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Werfen Sie einen Blick auf ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/sample-projects/overview",
          children: "HubSpots Beispielprojekte"
        }), ", um zu sehen, was alles möglich ist."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Erstellen Sie Ihr Projekt von Grund neu, indem Sie mit der ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/intro/create-a-project",
          children: "Anleitung für HubSpot-Projekte"
        }), " beginnen."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Weitere Ressourcen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://community.hubspot.com/t5/CRM-Development-Tools-Beta/gh-p/crm-development-tools-beta",
          children: "Tools zur CRM-Entwicklung in der HubSpot-Community"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/crm-development-tools-for-hubspot-developers-beta",
          children: "Tools zur CRM-Entwicklung im HubSpot-Entwicklerblog"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}