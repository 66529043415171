"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31235400502;
const slug = exports.slug = 'guides/cms/improve-older-sites/add-theme-features-to-existing-sites';
const title = exports.title = 'How to add theme capabilities to an existing HubSpot CMS website';
const name = exports.name = '[NEW] Add theme capabilities to an existing HubSpot CMS website';
const metaDescription = exports.metaDescription = 'Theme features can be added in an additive fashion to older sites. Think about the future of the website and how these features can enhance it.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "theme",
  "label": "Theme",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-place-existing-files-in-a-containing-folder",
  "label": "1. Place existing files in a containing folder",
  "parent": "theme"
}, {
  "depth": 1,
  "id": "2.-make-containing-folder-a-theme",
  "label": "2. Make containing folder a theme",
  "parent": "theme"
}, {
  "depth": 1,
  "id": "3.-add-theme-fields",
  "label": "3. Add theme fields",
  "parent": "theme"
}, {
  "depth": 0,
  "id": "clone-design-manager-drag-and-drop-as-html",
  "label": "Clone design manager drag and drop as HTML",
  "parent": null
}, {
  "depth": 0,
  "id": "drag-and-drop-areas",
  "label": "Drag and drop areas",
  "parent": null
}, {
  "depth": 2,
  "id": "does-it-make-sense-to-convert-every-existing-page-to-use-drag-and-drop-areas%3F",
  "label": "Does it make sense to convert every existing page to use drag and drop areas?",
  "parent": "drag-and-drop-areas"
}, {
  "depth": 1,
  "id": "converting-existing-templates",
  "label": "Converting existing templates",
  "parent": "drag-and-drop-areas"
}, {
  "depth": 1,
  "id": "how-do-i-migrate-existing-pages%3F",
  "label": "How do I migrate existing pages?",
  "parent": "drag-and-drop-areas"
}, {
  "depth": 0,
  "id": "global-partials",
  "label": "Global partials",
  "parent": null
}, {
  "depth": 0,
  "id": "code-alerts",
  "label": "Code Alerts",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      h3: "h3",
      strong: "strong",
      ol: "ol",
      code: "code",
      img: "img",
      h4: "h4",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "How to add theme capabilities to an existing HubSpot CMS website"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "One of the benefits of HubSpot is that you don't need to perpetually update it. CMS Hub is always using the latest version. The new features introduced with CMS Hub are additive. So the templates, modules, etc. for existing HubSpot CMS websites work the same as you built them. They're better even, as behind the scenes the CMS is always getting better, faster, and easier to edit."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are some specific features of CMS Hub that a developer needs to add. You may implement some of these features different than you would for a brand new website. This tutorial will guide you in adding these features into your existing website(s)."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before you continue:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "key concepts of CMS Hub"
        }), ", even if you've been building on HubSpot for a long time. You will gain a better understanding of how all the pieces fit."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You will also need to use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "CMS CLI"
        }), ", so if you don't have it installed, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "go set it up"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can add and use these features independently. Start at the heading for specific features you need. You do not need to follow this tutorial completely linearly."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Theme"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CMS Hub ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "themes"
      }), " are a package of templates, modules, CSS, JSON, and JS files. Themes being a package has meaning throughout the content editor experience."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When creating a new website or landing page the content creator is presented with a grid of the themes in their account. The select the theme they're using and then are shown a grid of just the templates in that theme."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When editing a page using a template from a theme, the modules in that theme get special treatment making them stand out in the add module panel."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Place existing files in a containing folder"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You make your templates, modules, CSS and JS files a theme if they are within the same containing folder. It does not matter if they are organized into sub-folders, it only matters that those assets are contained within a folder. If they are already stored that way great, if not create this folder and move your assets into it. Name that folder what you want to name your theme. The name is fully up to you, but perhaps naming it after the company's brand that the site reflects, and the year of the redesign or update."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Make containing folder a theme"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Themes require two JSON files, and only one of them needs actual content initially."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Theme.json"
        }), " - contains your theme's settings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fields.json"
        }), " - contains fields that can be used to make design changes across an entire site."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JSON files are special and can't currently be created in the design manager. These files CAN be uploaded through the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS CLI"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fetch the containing folder you created in step \"place existing files in a containing folder\" to your computer."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Inside of the folder on your computer, create a new file name it ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Copy the ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/guides/cms/content/themes/overview#theme-json",
          children: ["example ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "theme.json"
          }), " file on the themes doc"]
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Paste the example code into your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), " file."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Change the value of ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"label\""
        }), " to be the name of the theme, as you want content creators to see it."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Change ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"preview_path\""
        }), " to the path of either your homepage template or your most frequently used template."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Take a screenshot of the website's homepage. Name the image ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "thumb.jpg"
        }), ". Place the image inside of the same folder as your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), " file."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Change ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"screenshot_path\""
        }), " in theme.json to the path of your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "thumb.png"
        }), " image."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Save the file."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Inside of the same folder as your ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), " create a new file, name it fields.json. Inside of this file enter just ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[]"
        }), " and save the file."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To see your changes in the design manager run the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload command"
      }), ". You now have a basic theme. Modules and templates within that theme will display associated to the theme."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Add theme fields"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Theme fields are controls you can provide to a content creator to enable them to make theme-wide styling changes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "During the initial build out of a website these theme controls enable content creators to be involved helping nail down the site wide branding. Teams may find this frees the developer up to focus on the more technical aspects of the site development."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For existing websites, theme fields may be unnecessary. After-all if the website was already custom built, there likely is no purpose to adding site wide color, and typography controls. If a site's branding is changing significantly then it may be more appropriate to do a redesign than add fields retroactively. Ultimately though this is a decision you should make mutually with the other stakeholders involved in the website."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add fields to the theme, add their JSON to your fields.json file. The fields follow the same structure as module fields."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ensure that the users that should have access to change the theme field values, have the \"Edit global content and themes\" permission. Users who you do not want to be able to edit these settings you should make sure they do NOT have this enabled."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/327485/global%20content%20theme%20permissions.png",
        alt: "Screenshot of global content and themes setting in user permissions"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Clone design manager drag and drop as HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Drag and drop areas"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "global partials"
      }), " require the use of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "coded HTML + HubL"
      }), " files. If your existing website may be built using the old drag and drop design manager system, and you want to use those new features, those templates may need to be cloned as HTML."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To clone these templates as HTML + HubL template:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Open the design manager, and find the template in the finder."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Right click the template."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the context menu that appears, choose \"Clone as HTML\""
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You're now ready to work on adding the drag and drop areas and global partials."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Drag and drop areas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Drag and drop areas"
      }), " can be added to templates to provide content creators a way to place modules both horizontally and vertically on a page. Drag and drop areas also provide additional styling control for the content creator. Because a template with a drag and drop area can be used to create many different layouts, this frees up the developer to focus on the technical aspects of site creation and updating."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Drag and drop areas are a new feature and are not the same as ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/drag-and-drop-templates",
          children: "Design Manager drag and drop templates"
        }), ". They are also not supported in Design Manager drag and drop templates. To add them to templates previously built using the drag and drop template builder see clone design manager drag and drop as HTML."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Does it make sense to convert every existing page to use drag and drop areas?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "That completely depends on if the page is meeting your business goals. In other words the old adage of \"If it isn't broken, don't fix it\" applies. If the layout of the page needs changing, then yes it's probably wise to convert the page to use drag and drop areas. If the page is serving its purpose and doesn't need layout changes - it's probably fine as is."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Converting existing templates"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["It's relatively easy to create a clone of an old template, and change the flexible column to a drag and drop area. That is a good idea, as that opens up a lot of possibilities for the content creators. That gives content creators a lot of creative control for new pages. If your template is a design manager drag and drop template see ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#clone-design-manager-drag-and-drop-as-html",
        children: "clone design manager drag and drop as HTML"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The easiest solution is to find the instances of flexible columns, which use the HubL tag ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{% widget_container \"my_unique_column_name\" %}"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Replace each instance as necessary with ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{% dnd_area \"my_unique_area_name\" %}"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you do not care to set the default modules, leave the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " empty. If you want to carry over the default modules for the region or set new useful defaults, within the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " add a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), ", and inside of that a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["For each module tag within the old ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_container"
        }), " you will want to create a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), ", containing a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " with a matching path to the modules you want to display by default. This will create the same vertical layout the flexible column had."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "How do I migrate existing pages?"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["If you are changing templates built with flexible columns to now use drag and drop areas there's a few things you should understand. ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Flexible columns are not the same as drag and drop areas, you can't swap from a template that only has a flex column to one that only has a drag and drop area"
        }), ". We don't allow this as a safety precaution. The content would not map from the flex column to the drag and drop area. To illustrate why this is, suppose you built your new template so you have a sidebar and a main content area. Your sidebar is a flexible column, your main content is a drag and drop area. The swapping tool would map the flexible column to the flexible column."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you want to convert a page to using drag and drop areas the safest way to do it, is to use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-staging",
        children: "content staging"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/content/staging/domain",
          children: "Open the content staging tool"
        }), ", find the existing page, stage it. Choose \"Stage blank page\"."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Select your new template that uses the drag and drop area."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Copy and paste the information from the original version of the page, creating the layout you want."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "When done publish to staging."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the content staging tool, navigate to the \"Publish\" tab. Select your page, and publish from staging."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Global partials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "Global Partials"
      }), " are pieces of HTML & HubL content that can be reused across your entire website. The most common types of global partials are website headers, sidebars, and footers. For content creators global partials manifest themselves in the content editor as regions they can select to edit in the global content editor. The global content editor looks just like the page editor but is used to edit global content."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To illustrate, you might implement the header as a global partial with modules for the navigation and other content. To the content creator if they need to add a link to the navigation, they could click on the header in the page editor, then click on the menu module and update the menu."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ensure that the users that ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "should"
      }), " have access to edit global content have the \"Edit global content and themes\" permission."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/327485/global%20content%20theme%20permissions.png",
        alt: "global content & theme setting in user permissions"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Code Alerts"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For accounts on CMS Hub Enterprise, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/code-alerts",
        children: "Code Alerts"
      }), " provides a centralized location for developers and IT managers to see an overview of issues that affect performance and rendering of pages and assets on the account."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Since you are actively optimizing the site to take advantage of the new features. It's a great idea to review the code alerts for your account and resolve any that exist."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}