import Raven from 'raven-js';

/**
 * Sends Sentries for unexpected exceptions, swallows ones that we do expect
 * @param {Object} e
 */
function handleException(e) {
  // SecurityErrors result from browser settings blocking cookies
  if (e.name !== 'SecurityError') {
    // Send Sentries on unexpected errors.
    Raven.captureException(e);
  }
}

/**
 * Wraps `localStorage.setItem(key, value)` in a try/catch. Returns true if value
 * was set successfully. If an exception was thrown while setting, it is
 * logged to Raven and false is returned.
 * @param {String} key
 * @param {String} value
 */
export function setItem(key, value) {
  try {
    if (localStorage) {
      localStorage.setItem(key, value);
      return true;
    }
  } catch (e) {
    // @ts-expect-error Typing the e argument in the catch statement is not supported by the parsers according to the linter
    handleException(e);
  }
  return false;
}

/**
 * Wraps `localStorage.getItem(key)` in a try/catch and returns its value.
 * If the value is null, `defaultValue` is returned. If an exception occurred
 * it is logged to Raven and the `defaultValue` is returned.
 * @param {String} key
 */
export function getItem(key, defaultValue = null) {
  try {
    let item = defaultValue;
    if (localStorage) {
      item = localStorage.getItem(key);
    }
    return item;
  } catch (e) {
    // @ts-expect-error Typing the e argument in the catch statement is not supported by the parsers according to the linter
    handleException(e);
    return defaultValue;
  }
}

/**
 * Wraps `localStorage.removeItem(key)` in a try/catch and returns true if the call
 * succeeded. If an exception occurred it is logged to Raven and false is returned.
 * @param {String} key
 */
export function removeItem(key) {
  try {
    if (localStorage) {
      localStorage.removeItem(key);
      return true;
    }
  } catch (e) {
    // @ts-expect-error Typing the e argument in the catch statement is not supported by the parsers according to the linter
    handleException(e);
  }
  return false;
}