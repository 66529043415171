"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76685378311;
const slug = exports.slug = 'guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app';
const title = exports.title = "Migrer une intégration de clé d'API vers une application privée";
const name = exports.name = 'EMEA (FR) Migrate an API key integration to a private app';
const metaDescription = exports.metaDescription = "Découvrez comment migrer une intégration de clé d'API vers une application privée.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "dans-ce-guide",
  "label": "Dans ce guide",
  "parent": null
}, {
  "depth": 0,
  "id": "cr%C3%A9er-une-nouvelle-application-priv%C3%A9e",
  "label": "Créer une nouvelle application privée",
  "parent": null
}, {
  "depth": 0,
  "id": "mettre-%C3%A0-jour-la-m%C3%A9thode-d-autorisation-des-demandes-d-api-de-votre-int%C3%A9gration",
  "label": "Mettre à jour la méthode d'autorisation des demandes d'API de votre intégration",
  "parent": null
}, {
  "depth": 0,
  "id": "v%C3%A9rifier-les-demandes-et-suivre-les-journaux",
  "label": "Vérifier les demandes et suivre les journaux",
  "parent": null
}, {
  "depth": 0,
  "id": "exemples-d-impl%C3%A9mentation",
  "label": "Exemples d'implémentation",
  "parent": null
}, {
  "depth": 1,
  "id": "fonctions-sans-serveur",
  "label": "Fonctions sans serveur",
  "parent": "exemples-d-impl%C3%A9mentation"
}, {
  "depth": 1,
  "id": "t%C3%A2ches-ponctuelles",
  "label": "Tâches ponctuelles",
  "parent": "exemples-d-impl%C3%A9mentation"
}, {
  "depth": 1,
  "id": "cr%C3%A9er-des-objets-personnalis%C3%A9s",
  "label": "Créer des objets personnalisés",
  "parent": "exemples-d-impl%C3%A9mentation"
}, {
  "depth": 1,
  "id": "actions-de-workflow-%C3%A0-code-personnalis%C3%A9",
  "label": "Actions de workflow à code personnalisé",
  "parent": "exemples-d-impl%C3%A9mentation"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      em: "em",
      h2: "h2",
      strong: "strong",
      img: "img",
      code: "code",
      pre: "pre",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Migrate an API key integration to a private app"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Si vous voyez une bannière dans votre compte pour désactiver votre clé d'API :"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Assurez-vous d'avoir migré toutes les intégrations affectées, puis ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/fr/integrations/how-do-i-get-my-hubspot-api-key#deactivate-your-api-key",
              children: "désactivez la clé d'API"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Pour vérifier si la clé d'API du compte a été utilisée au cours des sept derniers jours, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/fr/integrations/how-do-i-get-my-hubspot-api-key#view-your-api-key-call-log",
              children: "consulter l'historique du journal d'appels de votre clé d'API"
            }), ". Le journal d'appels n'affichera aucune demande faite avec la clé il y a plus de sept jours."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Les applications répertoriées sur la page ", (0, _jsxRuntime.jsxs)(_components.a, {
              href: "https://knowledge.hubspot.com/fr/integrations/connect-apps-to-hubspot#:~:text=You%20can%20view%20all%20your,Connected%20Apps%20page.",
              children: [(0, _jsxRuntime.jsx)(_components.em, {
                children: "Applications connectées"
              }), " des paramètres de votre compte"]
            }), " ne doivent pas être migrées, car elles s'authentifient avec OAuth."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Les clés d'API de développeur sont distinctes des clés d'API HubSpot standards et ne sont ", (0, _jsxRuntime.jsx)("u", {
              children: "pas"
            }), " obsolètes. Les clés d'API pour développeurs sont utilisées pour gérer les paramètres liés à vos applications HubSpot, notamment les abonnements à ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/app-management/webhooks/overview",
              children: "l'API de webhooks"
            }), " et les types d'événements de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/extensions/timeline",
              children: "l'API des événements de la chronologie"
            }), "."]
          }), "\n"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous avez créé une intégration interne qui utilise une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/integrations/how-do-i-get-my-hubspot-api-key",
        children: "clé d'API HubSpot"
      }), ", votre clé d'API fournit à la fois un accès en lecture et en écriture à toutes vos données de CRM HubSpot, ce qui peut constituer un risque de sécurité si votre clé d'API est corrompue. En migrant vers une application privée, vous pouvez autoriser les domaines spécifiques dont votre intégration a besoin, ce qui génère un jeton d'accès qui limite les données que votre intégration peut demander ou modifier dans votre compte."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Suivez les étapes ci-dessous pour migrer une intégration de clé d'API existante vers une application privée. Il est recommandé d'utiliser d'abord un environnement de test, tel qu'un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/getting-started/account-types",
        children: "compte de test développeur"
      }), " ou un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/account/set-up-a-hubspot-sandbox-account",
        children: "compte sandbox"
      }), ", avant d'apporter des modifications en production. Si vous avez des questions lors de la migration de votre application, consultez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
        children: "communauté des développeurs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour une présentation vidéo de la migration d'une intégration de clé d'API vers une application privée, consultez la vidéo ci-dessous pour les développeurs HubSpot :"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/F3Qd9Hp4giM?start=29&feature=oembed",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Dans ce guide"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-new-private-app",
          children: "Créer une nouvelle application privée"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#update-the-authorization-method-of-your-integration-s-api-requests",
          children: "Mettre à jour la méthode d'autorisation de la demande d'API de votre intégration"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#verify-requests-and-monitor-logs",
          children: "Vérifier les demandes et suivre les journaux"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#implementation-examples",
          children: "Exemples d'implémentation"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Les applications privées ne prennent pas en charge les webhooks et ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/extensions/overview",
          children: "certains types d'extensions"
        }), ". Si votre intégration existante utilise l'une de ces fonctionnalités, vous devez créer une application publique via ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth"
        }), " à la place."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Créer une nouvelle application privée"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans votre compte HubSpot, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "l'icône Paramètres"
          }), " dans la barre de navigation principale."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Intégrations"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Applications privées"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Créer une application privée"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Informations de base"
          }), ", configurez les détails de votre application :"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Saisissez le ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "nom"
            }), " de votre application."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Passez le curseur de la souris sur le logo de variable et cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "l'icône de téléchargement"
            }), " pour télécharger une image carrée qui servira de logo pour votre application."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Saisissez une ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "description"
            }), " pour votre application."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Domaines"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Puis, sélectionnez les domaines à autoriser en fonction des API que votre intégration utilise. Pour identifier les domaines dont votre application aura besoin :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Compilez une liste des API HubSpot que votre intégration existante utilise."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Pour chaque demande d'API, accédez à la documentation de développeur associée (par exemple, ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "API de contacts"
            }), ")."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Points de terminaison"
            }), ", puis faites défiler jusqu'au point de terminaison utilisé par votre intégration."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Dans la section ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Exigences"
            }), ", identifiez les domaines requis pour utiliser le point de terminaison. Dans la mesure du possible, vous devez opter pour les domaines répertoriés sous ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Domaines granulaires"
            }), " au lieu de ceux listés sous ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Domaines standard"
            }), ". Si aucun domaine granulaire n'est répertorié, utilisez les domaines standard."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/locate-scope-in-endpoints-tab-for-private-app-migration.png",
            alt: "locate-scope-in-endpoints-tab-for-private-app-migration"
          })
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["De retour dans les paramètres de votre application privée, sélectionnez les cases à cocher ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Lecture"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Écriture"
            }), " à côté des domaines correspondants. Vous pouvez également rechercher un domaine via la barre de recherche ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Rechercher un domaine"
            }), "."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/select-matching-scope-for-private-app.png",
            alt: "select-matching-scope-for-private-app"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Une fois que vous avez sélectionné vos domaines, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Créer une application"
          }), " dans l'angle supérieur droit. Vous pourrez toujours apporter des modifications à votre application après l'avoir créée."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans la boîte de dialogue, vérifiez les informations sur le jeton d'accès de votre application, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Continuer de créer"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois votre application privée créée, vous pouvez commencer à effectuer des demandes d'API via son jeton d'accès. Dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Détails"
      }), " de la page des paramètres de votre application privée, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Afficher le jeton"
      }), " sous votre jeton d'accès pour le révéler."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-migration-guide.png",
        alt: "show-private-app-access-token-migration-guide"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Mettre à jour la méthode d'autorisation des demandes d'API de votre intégration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Au lieu d'utiliser un paramètre de demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hapiKey"
      }), " pour effectuer des demandes d'API, des jetons d'accès d'application privée sont inclus dans l'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), " de votre demande. Lorsque vous effectuez une demande, définissez la valeur de l'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Authorization"
      }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Bearer VOTRE_JETON"
      }), ". Sauf indication contraire, cette méthode d'autorisation est compatible avec tous les points de terminaison des API publiques, y compris les API existantes répertoriées dans l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api",
        children: "ancienne documentation de HubSpot pour les développeurs"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Votre demande peut ressembler à ce qui suit :"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "axios.get(\n  'https://api.hubapi.com/crm/v3/objects/contacts',\n  {\n    headers: {\n      Authorization: `Bearer ${YOUR_ACCESS_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "$headers = [\n    'Content-Type: application/json',\n    'Authorization: Bearer ' . YOUR_ACCESS_TOKEN,\n];\n\n$curl = curl_init();\n\ncurl_setopt($curl, CURLOPT_HTTPHEADER, $headers);\ncurl_setopt($curl, CURLOPT_URL, 'https://api.hubapi.com/crm/v3/objects/contacts');\ncurl_setopt($curl, CURLOPT_RETURNTRANSFER, true);\n\n$contacts = curl_exec($curl);\ncurl_close($curl);\n\nvar_dump($contacts);\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "require 'uri'\nrequire 'net/http'\nrequire 'openssl'\n\nurl = URI(\"https://api.hubapi.com/crm/v3/objects/contacts\")\n\nhttp = Net::HTTP.new(url.host, url.port)\nhttp.use_ssl = true\nhttp.verify_mode = OpenSSL::SSL::VERIFY_NONE\n\nrequest = Net::HTTP::Get.new(url)\nrequest['content-type'] = 'application/json'\ntoken = 'YOUR_ACCESS_TOKEN'\nrequest['authorization'] = \"Bearer #{token}\"\n\nresponse = http.request(request)\nputs response.read_body\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "import requests\n\nurl = \"https://api.hubapi.com/crm/v3/objects/contacts\"\n\nheaders = {\n  'content-type': 'application/json',\n  'authorization': 'Bearer %s' % YOUR_ACCESS_TOKEN\n}\n\nresponse = requests.request(\"GET\", url, headers=headers)\n\nprint(response.text)\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les jetons d'accès des applications privées sont implémentés sur OAuth. Ainsi, vous pouvez également effectuer des appels authentifiés avec votre jeton d'accès à l'aide de l'une des bibliothèques clientes de HubSpot. Par exemple, si vous utilisez la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-nodejs",
        children: "bibliothèque cliente Node.js"
      }), ", vous pouvez instancier un client OAuth en transmettant le jeton d'accès de votre application :"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JavaScript",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "const hubspotClient = new hubspot.Client({ accessToken: YOUR_ACCESS_TOKEN });\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "PHP",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-php",
            children: "$hubSpot = \\HubSpot\\Factory::createWithAccessToken('access-token');\n$response = $hubSpot->crm()->contacts()->basicApi()->getPage();\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "2",
        title: "Ruby",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-ruby",
            children: "# Load the gem\nrequire 'hubspot-api-client'\n\n# Setup client\nclient = Hubspot::Client.new(access_token: 'YOUR_ACCESS_TOKEN')\n\n# Get contacts\ncontacts = client.crm.contacts.basic_api.get_page\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "3",
        title: "Python",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-py",
            children: "from hubspot import HubSpot\n\napi_client = HubSpot(access_token='YOUR_ACCESS_TOKEN')\napi_client.crm.contacts.get_page()\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour terminer la migration vers votre application privée, supprimez toutes les références à la clé d'API HubSpot de votre code et utilisez plutôt l'approche ci-dessus pour utiliser le jeton d'accès de votre application privée. En fonction de la demande effectuée, vous pouvez créer un secret pour stocker votre jeton, plutôt que de le coder en dur dans vos demandes. L'utilisation d'un secret empêchera votre jeton d'être exposé, comme lors de l'utilisation d'un jeton dans une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#serverless-functions",
        children: "fonction sans serveur"
      }), ". Pour stocker le jeton d'accès en tant que secret :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le terminal, exécutez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs secrets add secretName"
        }), ". Il est recommandé de nommer le secret simplement afin que vous puissiez facilement le mentionner ultérieurement."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Collez le jeton d'accès dans le terminal, puis appuyez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Entrée"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez ensuite accéder à votre secret en tant que variable d'environnement. Découvrez-en davantage dans l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#serverless-functions",
        children: "exemple des fonctions sans serveur ci-dessous"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour vérifier que toutes les références à votre clé d'API ont été supprimées, vous pouvez consulter le journal des appels dans votre compte HubSpot :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, cliquez sur l'", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "icône Paramètres"
        }), " dans la barre de navigation principale."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la barre latérale de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Intégrations > Clé d'API"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Consultez les demandes les plus récentes dans l'onglet ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Journal des appels"
        }), " pour vérifier qu'aucune demande récente n'a été effectuée depuis la suppression de toutes les références précédentes pour utiliser le jeton d'accès de votre application privée."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/check-api-key-call-log-after-migration.png",
        alt: "check-api-key-call-log-after-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous disposez d'un compte ", (0, _jsxRuntime.jsx)(_components.em, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        })
      }), " payant avec des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/contacts/marketing-contacts",
        children: "contacts marketing"
      }), " et que vous avez précédemment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/integrations/manage-marketing-contacts-settings-for-your-integrations#set-contacts-created-by-api-key-apps-as-marketing-contacts",
        children: "défini des contacts créés par des intégrations utilisant votre clé d'API comme contacts marketing"
      }), ", vous devez également faire de même pour votre application privée :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "l'icône Paramètres"
        }), " dans la barre de navigation principale."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans la barre latérale de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Intégrations> Contacts marketing"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Sous ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Vos applications connectées"
        }), ", utilisez la barre de recherche pour trouver votre application privée, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Activer pour synchroniser des contacts comme contacts marketing"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/set-private-app-contacts-as-marketing-contacts.png",
        alt: "set-private-app-contacts-as-marketing-contacts"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Une fois que vous avez configuré votre application privée et vérifié que toutes les références à votre clé d'API ont été supprimées dans votre code, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/fr/integrations/how-do-i-get-my-hubspot-api-key#deactivate-your-api-key",
        children: "désactiver la clé"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vérifier les demandes et suivre les journaux"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois que vous avez supprimé toutes les références à la clé d'API HubSpot dans votre code et que vous les avez remplacées par des références au jeton d'accès de votre application privée, aucune autre modification de code n'est requise."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous avez suivi les étapes ci-dessus dans un compte de test développeur ou sandbox, répétez le même processus dans votre compte de production. Ensuite, suivez les journaux d'appels d'API de votre application privée et vérifiez qu'aucune des demandes de votre application ne renvoie d'erreurs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "400"
      }), " :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans votre compte HubSpot, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "l'icône Paramètres"
        }), " dans la barre de navigation principale."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le menu latéral de gauche, accédez à ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Intégrations"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Applications privées"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur le ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nom"
        }), " de votre application privée."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Journaux"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Toute demande d'API ayant échoué en raison d'un domaine manquant apparaîtra comme une erreur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), ". Si vous accédez aux journaux d'exécution de votre intégration, la réponse de la demande d'API correspondante doit inclure un message d'erreur avec des détails sur les domaines manquants."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/403-error-after-private-app-migration.png",
        alt: "403-error-after-private-app-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous devez inclure un nouveau domaine pour votre application privée :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Détails"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modifier les détails"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["En haut de la page, cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Domaines"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Sélectionnez la ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "case à cocher"
            }), " à côté des domaines manquants, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Engager les modifications"
            }), " dans l'angle supérieur droit lorsque vous avez terminé."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/select-missing-scopes-private-app-migration.png",
        alt: "select-missing-scopes-private-app-migration"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur la création et la gestion d'applications privées ainsi que sur leurs limites dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "guide des applications privées"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exemples d'implémentation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez ci-dessous les utilisations courantes des clés d'API et la migration vers des jetons d'accès d'application privée."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Fonctions sans serveur"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous utilisez une clé d'API dans une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "fonction sans serveur"
      }), ", vous pouvez également utiliser le jeton d'accès de l'application privée pour l'authentification. Vous devrez vous assurer que l'application privée dispose des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "domaines"
      }), " nécessaires pour que la fonction s'exécute."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour authentifier une fonction sans serveur avec un jeton d'accès d'application privée :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Sur la carte ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Jeton"
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "d'accès"
          }), ", cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Afficher le jeton"
          }), " pour afficher votre jeton d'accès. Cliquez ensuite sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copier"
          }), " pour copier le jeton dans le presse-papiers."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-1.png",
            alt: "show-private-app-access-token-1"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Une fois votre jeton d'accès copié, créez un nouveau secret pour stocker le jeton :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Dans le terminal, exécutez ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs secrets add secretName"
            }), ". Il est recommandé de nommer le secret simplement afin que vous puissiez facilement le mentionner ultérieurement."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Collez le jeton d'accès dans le terminal, puis appuyez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Entrée"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "serverless.json"
          }), " de votre fonction sans serveur, ajoutez le nom du secret au tableau ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "secret"
          }), " :"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example serverless.json file\n\n{\n  \"runtime\": \"nodejs18.x\",\n  \"version\": \"1.0\",\n  \"secrets\": [\"secretName\"],\n  \"endpoints\": {\n    \"getPrompts\": {\n      \"method\": \"GET\",\n      \"file\": \"serverlessFunction.js\"\n    }\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le fichier JavaScript de votre fonction sans serveur, définissez la valeur de l'en-tête ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Authorization"
        }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Bearer secretName"
        }), ". Par exemple, si vous effectuez un appel vers l' ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "API de contacts"
        }), " via Node.js et ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), ", la demande ressemblera à ceci :"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example serverless function\nconst axios = require('axios');\n\nexports.main = (context, sendResponse) => {\n  axios.get(`https://api.hubapi.com/crm/v3/objects/contacts`, {\n    headers: {\n      Authorization: `Bearer ${process.env.secretName}`,\n      'Content-Type': 'application/json',\n    },\n  });\n  sendResponse({ statusCode: 200 });\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "passer des appels d'API avec le jeton de votre application"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Tâches ponctuelles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous utilisez une clé d'API pour exécuter des tâches ponctuelles, telles que la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "création d'une propriété"
      }), ", vous pouvez créer une application privée et utiliser son jeton d'accès pour authentifier l'appel à la place. Une fois qu'une application privée est créée, vous pouvez réutiliser son jeton d'accès pour n'importe quelle tâche ponctuelle, tant que l'application privée dispose des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "domaines"
      }), " appropriés. Vous pouvez mettre à jour les domaines d'une application privée à tout moment depuis les paramètres de l'application privée dans HubSpot. Vous pouvez également supprimer l'application privée et en créer une nouvelle spécifique à la tâche à exécuter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/private-app-edit-scopes.png",
        alt: "private-app-edit-scopes"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer des objets personnalisés"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Au lieu d'utiliser une clé d'API pour ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "créer un objet personnalisé"
      }), ", vous pouvez créer une application privée et utiliser son jeton d'accès pour authentifier l'appel, tant que l'application dispose des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "domaines"
      }), " nécessaires. Par exemple, lorsque vous utilisez Postman pour créer un objet personnalisé, définissez le type d'autorisation sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Jeton Bearer"
      }), ", puis saisissez le jeton dans le champ ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Jeton"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/postman-private-app-access-token-field.png",
        alt: "postman-private-app-access-token-field"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur la création d'un objet personnalisé à l'aide d'une application privée sur le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/blog/how-to-build-a-custom-object-using-private-apps",
        children: "blog pour les développeurs de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Actions de workflow à code personnalisé"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous utilisez une clé d'API dans une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/custom-code-actions#create-a-custom-code-action",
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "action de workflow à code personnalisé"
        })
      }), ", vous pouvez utiliser le jeton d'accès de l'application privée, tant que l'application dispose des", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "domaines"
      }), " nécessaires. Pour effectuer la mise à jour, ouvrez l'action personnalisée dans l'éditeur de workflow, puis effectuez les mises à jour suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Tout d'abord, ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/conversations/working-with-chatbots/code-snippets",
            children: "ajoutez un nouveau secret"
          }), " qui contient le jeton d'accès de l'application privée."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/workflow-action-add-secret.png",
            alt: "workflow-action-add-secret"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Ensuite, ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/reference/api/automation/custom-code-actions#secret",
            children: "mettez à jour le code d'action"
          }), " avec le nouveau secret."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspotClient = new hubspot.Client({\n  accessToken: process.env.secretName,\n});\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}