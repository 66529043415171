"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921777;
const slug = exports.slug = 'guides/apps/public-apps/overview';
const title = exports.title = 'Public apps';
const name = exports.name = 'Public apps';
const metaDescription = exports.metaDescription = 'Learn how to create and install an app in HubSpot. Apps are integrations that can be installed on customer accounts through an OAuth connection process.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-a-public-app",
  "label": "Create a public app",
  "parent": null
}, {
  "depth": 1,
  "id": "configure-scopes",
  "label": "Configure scopes",
  "parent": "create-a-public-app"
}, {
  "depth": 2,
  "id": "scope-types",
  "label": "Scope types",
  "parent": "create-a-public-app"
}, {
  "depth": 2,
  "id": "add-scopes",
  "label": "Add scopes",
  "parent": "create-a-public-app"
}, {
  "depth": 0,
  "id": "install-an-app",
  "label": "Install an app",
  "parent": null
}, {
  "depth": 1,
  "id": "authorize-your-app-with-a-customer-account",
  "label": "Authorize your app with a customer account",
  "parent": "install-an-app"
}, {
  "depth": 1,
  "id": "generate-the-initial-oauth-tokens",
  "label": "Generate the initial OAuth tokens",
  "parent": "install-an-app"
}, {
  "depth": 0,
  "id": "manage-public-apps-in-hubspot",
  "label": "Manage public apps in HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "find-an-app-s-id",
  "label": "Find an app's ID",
  "parent": "manage-public-apps-in-hubspot"
}, {
  "depth": 1,
  "id": "monitor-app-behavior",
  "label": "Monitor app behavior",
  "parent": "manage-public-apps-in-hubspot"
}, {
  "depth": 0,
  "id": "add-a-verified-domain",
  "label": "Add a verified domain",
  "parent": null
}, {
  "depth": 1,
  "id": "add-a-verified-domain",
  "label": "Add a verified domain",
  "parent": "add-a-verified-domain"
}, {
  "depth": 2,
  "id": "additional-notes",
  "label": "Additional Notes",
  "parent": "add-a-verified-domain"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      em: "em",
      ul: "ul",
      li: "li",
      h2: "h2",
      strong: "strong",
      img: "img",
      code: "code",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Public apps"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot, a public app is a type of integration that can be installed on customer accounts or listed on the App Marketplace. It requires authentication via ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), ". Once a user ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#installing-apps",
        children: "installs"
      }), " your app on their HubSpot account, you’ll be able to make API calls to that account using an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth access token"
      }), ". Your app will also appear in the account’s ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Connected Apps"
      }), " settings."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Connected apps are also able to take advantage of ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "subscribing to changes using webhooks"
      }), " and creating custom ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/timeline",
        children: "timeline events"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn how to:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-public-app",
          children: "Create a public app"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#install-an-app",
          children: "Install a public app in an account"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#manage-public-apps-in-hubspot",
          children: "Manage the app, including monitoring usage"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-a-verified-domain",
          children: "Add a verified domain to the app"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create a public app"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you create a public app in HubSpot, you're essentially associating an app you've built with an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup/developers",
        children: "app developer account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To get started with creating your public app:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navigate to your app developer account:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["To create a new app developer account, click ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.com/get-started",
              children: "here"
            }), ", then click ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Create App Developer account"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["If you already created an app developer account, navigate to the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://app.hubspot.com/myaccounts-beta",
              children: "account selection page"
            }), ", then locate and click your ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "account."
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your app developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create app"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Next, enter your ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App name"
        }), ", and provide other basic information about your app, including a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "description"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "logo"
        }), ". When users authenticate your app with their HubSpot account, they’ll see the name, description, logo, and any support contact info you provide on this page."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": the app name will be used wherever your app displays in HubSpot. This includes when installing the app as well as the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Powered by"
        }), " footer for ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "CRM cards"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/timeline",
          children: "timeline events"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/basic-app-info-for-new-public-app.png",
        alt: "basic-app-info-for-new-public-app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Auth"
        }), " tab."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": your app credentials (i.e., your ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App ID"
        }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Client ID"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Client secret"
        }), ") as well as your ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sample install URL"
        }), " are ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " accessible while your app is in a draft state, and will only be available after you create your app."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Redirect URL"
        }), " for your app. Users will be redirected to this URL after granting access and the associated account permissions to your app. If you're just getting started and you don't have an official redirect URL ready yet, you can also use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "http://localhost"
        }), " for local development as you build out and test your app."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/redirect-url-in-public-app-settings-draft-mode-1.png",
        alt: "redirect-url-in-public-app-settings-draft-mode-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Next, you'll configure the scopes your app will require."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configure scopes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Scopes determine your app's permissions to access data sources or tools in an account that's installed your app. The scopes you configure will appear as the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "scope"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "optional_scope"
      }), " query parameters in an install URL that you can then provide to users."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Scope types"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Auth"
      }), " tab, there are three different scope types available for you to configure. You must specify the scopes your app will require for installation, but you can also specify two other scope types: conditionally required scopes and optional scopes."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Required scopes:"
        }), " scopes that must be authorized by the user ", (0, _jsxRuntime.jsx)("u", {
          children: "and"
        }), " must be present in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), " query parameter in your app's install URL for successful installation."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Conditionally required scopes:"
        }), " scopes that must be authorized by the user only if they're present in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), " query parameter in your app's install URL for successful installation.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["This scope type allows you to be flexible and provide a separate install URL for tiered features or scopes that are only required when users enable certain features in your app. For example, you could offer two install URLs to your users: one install URL could include the conditionally required scope in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            }), " query parameter for users with access to a feature, while another install URL omits that scope in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope"
            }), " query parameter for users without access."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If a conditionally required scope is present in your app install URL and a user without access to the associated feature attempts to install your app using that URL, the installation will fail."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Optional scopes:"
        }), " scopes that are ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " required to successfully install your app. These scopes are specified in the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), " query parameter in your app's install URL. For example, if you want your app to be able to fetch ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "custom object"
        }), " data (which is only available to ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " HubSpot accounts), you could add the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.custom.read"
        }), " scope as an optional scope. Then, if an account has access to the custom objects, the scope will be authorized. Otherwise, they’ll still be able to install the app without the custom objects scope."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Add scopes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To customize your scope settings and add new scopes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To configure conditionally required or optional scopes, click to the toggle the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Turn on advanced scope settings"
        }), " switch on."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/turn-on-advanced-scope-settings-toggle-in-public-app-auth-tab.png",
        alt: "turn-on-advanced-scope-settings-toggle-in-public-app-auth-tab"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " starting October 21, 2024, advanced scope settings will be required for all apps. Learn more on ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/advanced-auth-and-scope-settings-for-public-apps",
          children: "HubSpot's Developer Changelog"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Scopes"
        }), " section, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Add new scope"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the right panel, use the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "search bar"
        }), " to search for a scope, then select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "checkbox"
        }), " next to any scope you want the user to authorize. If you turned on advanced scope settings, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "dropdown menu"
        }), " next to the scope and select a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "scope type"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Update"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/add-new-scope-panel-in-public-app-setup.png",
        alt: "add-new-scope-panel-in-public-app-setup"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Review your configured scopes. If you turned on advanced scope settings, you can switch the scope type of any scope by clicking the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "dropdown menu"
        }), " next to the scope. You can also click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Delete"
        }), " to remove one of your app's scopes."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/review-new-scope-settings-in-public-app-setup.png",
        alt: "review-new-scope-settings-in-public-app-setup"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Once you've finished setting up your app settings, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create app"
        }), " in the bottom left."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With your app created, you can now walk through the installation process and access your app credentials."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " it's recommended to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#add-a-verified-domain",
          children: "add a verified domain"
        }), " to the app to add another level of trust for users installing your app. Otherwise, the app will display a banner stating that the app is not verified."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Install an app"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " before installing your app, keep in mind the following:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["An app won’t appear on an account’s ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Connected Apps"
          }), " page until the initial access and refresh tokens are created."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Only users with with access to an app’s required or conditionally required scopes can install an app."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Apps can’t be installed on developer accounts. To test your app, you’ll need to create a ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types",
            children: "test account"
          }), " in your app developer account and install it there."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "App installation can be broken down into two steps: authorization and token generation."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Authorize your app with a customer account"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To authorize your app with a HubSpot account, you’ll need to create an authorization URL. Do this by getting the client ID for your app and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "initiating the OAuth process"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Once your URL is ready, open it in your browser to see a list of all your HubSpot accounts. This is also what users will see once you begin directing them to this URL."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "account"
        }), " where you want to install your app."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/select_account-1.webp",
        alt: "select_account-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After choosing an account, you'll be presented with a list of scopes based on the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&scope="
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&optional_scope="
        }), " parameters you set for the authorization URL."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if you include an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), " and the selected account doesn't have access to it (such as the content scope for a CRM-only account), it will not be listed."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Grant access"
        }), " to authorize the connection."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/approve_scopes-1.webp",
        alt: "approve_scopes-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After granting access, you'll be redirected based on the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "&redirect_uri="
        }), " parameter in the original authorization URL, and a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "?code="
        }), " parameter will be appended to the URL. Use that code in the next step to generate an access token."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Generate the initial OAuth tokens"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To generate your refresh and initial access tokens, you’ll need the code from the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?code="
      }), " parameter of the authorization URL, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "redirect_url"
      }), ", client ID, and client secret. Detailed instructions are ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "here"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Once you’ve authorized your app and generated the initial tokens, installation is complete. It’ll be listed on your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/integrations-beta",
        children: "Connected Apps"
      }), " page, and you’ll start getting ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/webhooks/overview",
        children: "webhook"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/extensions/crm-cards",
        children: "CRM Cards"
      }), " fetch requests."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/connected_apps-1.webp",
        alt: "connected_apps-1"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Manage public apps in HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Find an app's ID"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can find a public app's ID in your app developer account using either of the methods below:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), " in the main navigation bar, then view the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App ID"
        }), " listed below the name of your app."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/find-app-id.png",
        alt: "find-app-id"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), " in the main navigation bar, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of the app. On the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Basic info"
        }), " page, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Auth"
        }), " tab, then view the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App ID"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/find-app-id-auth-settings.png",
        alt: "find-app-id-auth-settings"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Monitor app behavior"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot logs all requests made to or from a connected app, including incoming requests using an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/app-management/oauth/tokens",
        children: "OAuth access token"
      }), " or outgoing requests for webhooks or CRM cards."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To view this request log:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), " in the main navigation bar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of the app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Monitoring"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tabs"
        }), " to view different types of requests being made to or from the app. While viewing these logs, you can click an individual request to view more information about it, including:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["for ", (0, _jsxRuntime.jsx)("u", {
              children: "successful"
            }), " requests, the request method, path, and time of request."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["for ", (0, _jsxRuntime.jsx)("u", {
              children: "unsuccessful"
            }), " requests, additional error information such as response headers and body."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/428357/request_details.webp",
        alt: "request_details"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below, learn more about each tab of the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Monitoring"
      }), " page."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "API calls:"
        }), " the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "API calls"
        }), " tab shows all requests made to your app using an OAuth access token. It can be filtered by HTTP method, response code, timeframe, or request URL."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Webhooks:"
        }), " the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Webhooks"
        }), " tab shows HubSpot requests for any of your app’s ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks/overview",
          children: "webhook subscriptions"
        }), ". Filter by response (including timeouts and connection failures), status (success, will retry, or failure), subscription type, time frame, attempt, batch, event, or account ID."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the attempt ID is a combination of the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "subscriptionId"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "eventId"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "attemptNumber"
        }), " from a specific request."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CRM extensions:"
        }), " the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "CRM extensions"
        }), " tab shows requests for your app’s ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "CRM cards"
        }), ". Filter by extension object type, CRM object type (contact, company, ticket, or deal), error or warning type, time frame, request ID, or CRM record ID (i.e. a specific contact ID)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "App settings:"
        }), " the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "App settings"
        }), " tab enables you to configure the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/create-an-app-settings-page",
          children: "settings page"
        }), " that comes with your app."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On each tab, if any associated events occurred in the last 30 days (e.g., a webhook trigger occurred or an API call was made), you can click ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Export logs"
      }), " to export the associated event data to a CSV:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the dialog box, configure how many days' worth of data to export (up to 30 days)."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Export"
        }), ". An email notification will be sent to the email address associated with your user in your HubSpot settings."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Add a verified domain"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When HubSpot users install an app, they consent to give the app’s developer access to their account data. The developer’s identity and reputation each play an important role in a user’s decision to continue with the install. To ensure full user consent when installing an app, HubSpot will display a message on the app install screen to indicate the app's level of verification and App Marketplace listing:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "When an app doesn't have a verified domain, HubSpot will display a banner on the install screen that says the app has not been verified."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/not-verified.png",
            alt: "not-verified"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["When app has a verified domain but is not ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/listing-your-app",
            children: "listed on the App Marketplace"
          }), ", HubSpot will display the verified domain along with a banner on the install screen that says the app has not been reviewed or approved by HubSpot."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verified-not-listed.png",
        alt: "verified-not-listed"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "When an app has been listed on the marketplace, passing HubSpot's app review process, HubSpot will not display either of the above banners. You're not required to verify the domain if your app has been listed on the App Marketplace."
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verified-and-listed.png",
            alt: "verified-and-listed"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Add a verified domain"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To add a verified domain to the app, you'll need to first add the domain to the app's settings, then add a TXT record to the domain's DNS settings:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your app developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of the app."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contact & support"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Company domain"
        }), " field, enter your domain, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save"
        }), ". A message will appear below the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Company domain"
        }), " stating that the domain has not yet been verified."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Verify it now"
        }), " to begin the verification process."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/domain-verification-for-app.png",
        alt: "domain-verification-for-app"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the right panel, confirm that the domain has been entered correctly, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Next"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Copy the required TXT record value by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Copy"
          }), " in the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Value"
          }), " column."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/verify-app-domain-copy-value.png",
            alt: "verify-app-domain-copy-value"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "In your DNS provider, create a TXT record with the copied value. Below are instructions for some common DNS providers:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.godaddy.com/help/add-a-txt-record-19232",
              children: "GoDaddy"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://my.bluehost.com/hosting/help/559#add",
              children: "BlueHost"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.namecheap.com/support/knowledgebase/article.aspx/317/2237",
              children: "Namecheap"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://support.cloudflare.com/hc/en-us/articles/360019093151-Managing-DNS-records-in-Cloudflare#h_60566325041543261564371",
              children: "Cloudflare"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://help.hover.com/hc/en-us/articles/217282457-How-to-Edit-DNS-records-A-AAAA-CNAME-MX-TXT-SRV-",
              children: "Hover"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.name.com/support/articles/115004972547-Adding-a-TXT-Record",
              children: "Name"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://help.uniteddomains.com/hc/en-us/articles/115000887125-How-to-set-up-a-TXT-record-on-a-domain-name",
              children: "United Domains"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["After updating your DNS settings, navigate back to HubSpot, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Next"
          }), " in the right panel. DNS records can take up to 48 hours to update, so HubSpot might not recognize the change immediately. You can get back to this screen any time by selecting ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Verify it now"
          }), " again from the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Company info"
          }), " settings page."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Once verified, you'll see a success status indicator under the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Company domain"
          }), " field."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/Domain%20verified__export.png",
        alt: "Domain verified__export"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Additional Notes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "To ensure continued ownership of the domain, HubSpot will continue to verify that the TXT record is present on a regular basis. The install warning will return if the TXT record is removed or modified."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Currently, you can only have one verified domain per developer account. All apps in an account share the verified domain. The domain on the install page will link to your root domain."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If you delete your verified domain, all apps in your developer account will get the install warning again. You can verify another domain, but the process will take a couple hours."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}