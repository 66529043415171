import { useContext } from 'react';
import { LocaleContext } from '../context/LocaleContext';

/**
 * Contextual hook for descendants of a locale's `LayoutPublic` template.
 *
 * @example
 * ```tsx
 * import useLocale from 'developer-docs-shared-components/hooks/useLocale';
 * const currentLocale = useLocale();
 * ```
 */
const useLocale = () => {
  const locale = useContext(LocaleContext);
  return locale;
};
export default useLocale;