"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937501;
const slug = exports.slug = 'reference/api/deprecated/overview';
const title = exports.title = 'HubSpot APIs | Deprecated APIs';
const name = exports.name = 'vNext Docs DP - Deprecated APIs';
const metaDescription = exports.metaDescription = 'A list of deprecated HubSpot APIs';
const toc = exports.toc = [{
  "depth": 0,
  "id": "sunsetted-apis",
  "label": "Sunsetted APIs",
  "parent": null
}, {
  "depth": 0,
  "id": "deprecated-apis",
  "label": "Deprecated APIs",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Sunsetted and deprecated APIs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The APIs listed in the sections below will ", (0, _jsxRuntime.jsx)("u", {
        children: "not"
      }), " receive future updates to functionality, and in some cases, will be fully sunsetted and unavailable for use in your integrations."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["It's highly recommended that you subscribe to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "HubSpot Developer Changelog"
      }), " to follow along with the latest updates, breaking changes, and other significant changes to functionality."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sunsetted APIs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following APIs have been fully removed, and will return an error when making a call to their associated endpoints."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "API"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Sunset date"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Notes"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CMS performance"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "April 9, 2024"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["For website analytics data, use the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/analytics-and-events/reporting",
              children: "Analytics API"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ecommerce bridge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "February 28, 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["If you previously built an integration using this API, you can follow ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration",
              children: "the migration guide"
            }), " to switch over your integration to use private apps."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Accounting extension"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "February 28, 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["If you previously built an integration using this API, you can follow ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/deprecated/migrations/migrate-an-existing-account-extension-integration",
              children: "this migration guide"
            }), " to switch over your integration to use private apps."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Marketing calendar"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "August 31, 2023"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["You can continue to ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/campaigns/use-your-marketing-calendar",
              children: "use the marketing calendar in HubSpot"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Deprecated APIs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The legacy endpoints listed below will ", (0, _jsxRuntime.jsx)("u", {
        children: "not"
      }), " be getting a version update. These endpoints are functional and stable, but won’t be updated beyond their current version. HubSpot will continue to support them for the foreseeable future and will announce any future changes with ample notice on HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog",
        children: "Developer Changelog"
      }), ".", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/deprecated/overview"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "API"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Notes"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Social media"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Includes the following endpoints:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/overview",
                  children: "Get publishing channels"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-broadcast-messages",
                  children: "Get broadcast messages"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/social-media/v1#get-a-broadcast-message",
                  children: "Get a broadcast message"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/overview",
                  children: "Create a broadcast message"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/reference/api/deprecated/overview",
                  children: "Cancel a broadcast message"
                })
              })]
            })]
          })]
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}