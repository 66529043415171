"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29913023567;
const slug = exports.slug = 'reference/cms/modules/default-modules';
const title = exports.title = 'Módulos da Web padrão';
const name = exports.name = 'Módulos da Web padrão';
const metaDescription = exports.metaDescription = 'Saiba mais sobre os módulos padrão que o HubSpot fornece para criar páginas do site, posts de blog e modelos de listagem de blog. Esses módulos são separados dos módulos de e-mail padrão, que são para modelos de e-mail.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "coment%C3%A1rios-do-blog",
  "label": "Comentários do blog",
  "parent": null
}, {
  "depth": 0,
  "id": "assinatura-de-e-mail-do-blog",
  "label": "Assinatura de e-mail do blog",
  "parent": null
}, {
  "depth": 0,
  "id": "posts-do-blog",
  "label": "Posts do blog",
  "parent": null
}, {
  "depth": 0,
  "id": "filtro-de-post-de-blog",
  "label": "Filtro de post de blog",
  "parent": null
}, {
  "depth": 0,
  "id": "listagem-de-posts-de-blog",
  "label": "Listagem de posts de blog",
  "parent": null
}, {
  "depth": 0,
  "id": "bot%C3%A3o",
  "label": "Botão",
  "parent": null
}, {
  "depth": 0,
  "id": "call-to-action",
  "label": "Call to action",
  "parent": null
}, {
  "depth": 0,
  "id": "excluir-dados",
  "label": "Excluir dados",
  "parent": null
}, {
  "depth": 0,
  "id": "divisor",
  "label": "Divisor",
  "parent": null
}, {
  "depth": 0,
  "id": "fazer-download-de-dados",
  "label": "Fazer download de dados",
  "parent": null
}, {
  "depth": 0,
  "id": "formul%C3%A1rio",
  "label": "Formulário",
  "parent": null
}, {
  "depth": 0,
  "id": "cabe%C3%A7alho",
  "label": "Cabeçalho",
  "parent": null
}, {
  "depth": 0,
  "id": "espa%C3%A7ador-horizontal",
  "label": "Espaçador horizontal",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%ADcone",
  "label": "Ícone",
  "parent": null
}, {
  "depth": 0,
  "id": "imagem",
  "label": "Imagem",
  "parent": null
}, {
  "depth": 0,
  "id": "grade-de-imagem",
  "label": "Grade de imagem",
  "parent": null
}, {
  "depth": 0,
  "id": "galeria-de-imagens",
  "label": "Galeria de imagens",
  "parent": null
}, {
  "depth": 0,
  "id": "galeria-com-controle-deslizante-de-imagens",
  "label": "Galeria com controle deslizante de imagens",
  "parent": null
}, {
  "depth": 0,
  "id": "troca-de-idiomas",
  "label": "Troca de idiomas",
  "parent": null
}, {
  "depth": 0,
  "id": "logotipo",
  "label": "Logotipo",
  "parent": null
}, {
  "depth": 0,
  "id": "grid-de-logotipo",
  "label": "Grid de logotipo",
  "parent": null
}, {
  "depth": 0,
  "id": "reuni%C3%B5es",
  "label": "Reuniões",
  "parent": null
}, {
  "depth": 0,
  "id": "logins-sociais-de-associa%C3%A7%C3%B5es",
  "label": "Logins sociais de associações",
  "parent": null
}, {
  "depth": 0,
  "id": "menu",
  "label": "Menu",
  "parent": null
}, {
  "depth": 0,
  "id": "rodap%C3%A9-da-p%C3%A1gina",
  "label": "Rodapé da página",
  "parent": null
}, {
  "depth": 0,
  "id": "solicita%C3%A7%C3%A3o-de-senha",
  "label": "Solicitação de senha",
  "parent": null
}, {
  "depth": 0,
  "id": "pagamentos",
  "label": "Pagamentos",
  "parent": null
}, {
  "depth": 0,
  "id": "produto",
  "label": "Produto",
  "parent": null
}, {
  "depth": 0,
  "id": "download-de-or%C3%A7amento",
  "label": "Download de orçamento",
  "parent": null
}, {
  "depth": 0,
  "id": "pagamento-de-or%C3%A7amento",
  "label": "Pagamento de orçamento",
  "parent": null
}, {
  "depth": 0,
  "id": "assinatura-do-or%C3%A7amento",
  "label": "Assinatura do orçamento",
  "parent": null
}, {
  "depth": 0,
  "id": "itens-de-linha",
  "label": "Itens de linha",
  "parent": null
}, {
  "depth": 0,
  "id": "rich-text",
  "label": "Rich text",
  "parent": null
}, {
  "depth": 0,
  "id": "lista-de-rss",
  "label": "Lista de RSS",
  "parent": null
}, {
  "depth": 0,
  "id": "entrada-de-pesquisa-do-site",
  "label": "Entrada de pesquisa do site",
  "parent": null
}, {
  "depth": 0,
  "id": "resultados-de-pesquisa",
  "label": "Resultados de pesquisa",
  "parent": null
}, {
  "depth": 0,
  "id": "cabe%C3%A7alho-da-sess%C3%A3o",
  "label": "Cabeçalho da sessão",
  "parent": null
}, {
  "depth": 0,
  "id": "menu-simples",
  "label": "Menu simples",
  "parent": null
}, {
  "depth": 0,
  "id": "seguir-na-rede-social",
  "label": "Seguir na rede social",
  "parent": null
}, {
  "depth": 0,
  "id": "compartilhamento-social",
  "label": "Compartilhamento social",
  "parent": null
}, {
  "depth": 0,
  "id": "cart%C3%A3o-com-guias",
  "label": "Cartão com guias",
  "parent": null
}, {
  "depth": 0,
  "id": "uma-linha-de-texto",
  "label": "Uma linha de texto",
  "parent": null
}, {
  "depth": 0,
  "id": "v%C3%ADdeo",
  "label": "Vídeo",
  "parent": null
}, {
  "depth": 0,
  "id": "incorpora%C3%A7%C3%A3o-de-v%C3%ADdeo-landing-page",
  "label": "Incorporação de vídeo (landing page)",
  "parent": null
}, {
  "depth": 0,
  "id": "link-do-whatsapp",
  "label": "Link do WhatsApp",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      img: "img",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Módulos da Web padrão"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba mais sobre os módulos padrão que a HubSpot fornece para criar modelos para páginas de site, posts de blog e páginas de listagem de blog. Você também encontrará módulos padrão que podem ser usados para criar modelos de orçamento."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao desenvolver localmente, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch-files",
        children: "buscar"
      }), " um módulo padrão específico usando o caminho do módulo (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/linked_image.module"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para exibir o código de um módulo padrão, você pode exibir e clonar o módulo na pasta ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot"
      }), " do gerenciador de design."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-default-modules.png",
        alt: "design-manager-default-modules"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " os módulos da web padrão são independentes dos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules",
          children: "módulos de e-mail padrão"
        }), ", que servem para modelos de e-mail. Se seus modelos de e-mail incluírem um dos módulos da Web padrão a seguir, você deverá ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/default-email-modules",
          children: "substituí-los pelo módulo específico de e-mail correspondente"
        }), ":"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "cta"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "header"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "linked_image"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "logo"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "post_filter"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "post_listing"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "section_header"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "social_sharing"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "text"
          })
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Comentários do blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em posts de blog e listagens de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_comments\" path=\"@hubspot/blog_comments\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Assinatura de e-mail do blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, posts de blog e listagens de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_subscribe\" path=\"@hubspot/blog_subscribe\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O blog que deve ser usado para o módulo."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Título para o módulo (envolto em uma tag h3)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Subscribe Here!\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "response_message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A mensagem mostrada ao enviar o formulário."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Thanks for subscribing!"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Posts do blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Adicione este módulo às páginas de listagem de blog para exibir visualizações de posts de blog contendo o título, a imagem em destaque, o autor, a data de publicação de cada post e muito mais com um botão clicável que navega para o post."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Este módulo padrão foi criado usando React, e você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react/tree/main/default-react-modules/src/components/modules/BlogPosts-V0",
        children: "exibir seu código fonte no GitHub"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Este módulo não pode ser acessado a partir do gerenciador de design. O módulo pode ser referenciado com HubL em modelos codificados e adicionado ao ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/blog/edit-a-blog-listing-page",
            children: "editor de páginas de listagem do blog"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Este módulo substitui o módulo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "blog_listing"
          }), " anterior, que foi descontinuado."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_posts\"\n  path=\"@hubspot/blog_posts\"\n  layout=\"grid\",\n  columns=4,\n  displayForEachListItem=[\n   \"image\",\n   \"title\",\n   \"authorName\",\n   \"tags\",\n   \"publishDate\",\n   \"description\",\n   \"button\"\n  ]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "layout"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Obrigatório"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O layout do conteúdo de cada visualização de post de blog.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "grid"
                }), " (padrão): alinha os posts em uma grade básica."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "singleColumn"
                }), ": alinha os posts em uma única coluna, com imagens em destaque em sua própria linha acima do restante do conteúdo do post."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "sideBySide"
                }), ": alinha posts em uma coluna com imagens em destaque alinhadas horizontalmente com o conteúdo do post."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columns"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ao usar o layout ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            }), ", o número de posts por linha. Pode ser ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alternateImage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ao usar o layout ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "sideBySide"
            }), ", defina como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " para alinhar as imagens em destaque à direita e à esquerda da visualização da publicação."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fullImage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ao usar os layouts ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "singleColumn"
            }), ", defina este campo como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " para tornar a imagem em destaque o plano de fundo da visualização do post."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "displayForEachListItem"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O conteúdo a ser incluído em cada visualização de post de blog. As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "image"
                }), ": a imagem em destaque do post."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), ": o título do post."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "authorImage"
                }), ": a imagem do autor do post."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "authorName"
                }), ": o nome do autor do post."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "tags"
                }), ": as tags de blog do post."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "publishDate"
                }), ": a data de publicação do post."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), ": a metadescrição do post."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button"
                }), ": o botão ler mais associado ao post de blog."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ 'image', 'title', 'authorImage', 'authorName', 'tags', 'publishDate', 'description', 'button' ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "buttonText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto exibido no botão Leia mais, se incluído."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Read more"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filtro de post de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"post_filter\" path=\"@hubspot/post_filter\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Selecione um blog para exibir. O padrão usará o blog atual quando usado em um modelo de blog ou o blog principal quando usado em outro lugar."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "filter_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tipo de links de filtragem a serem exibidos. As opções incluem: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "tag"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "month"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "author"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tag"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "order_by"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Classificação dos valores dos links de filtro. As opções incluem: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "post_count"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "name"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "post_count"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "list_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um título H3."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Posts by Tag\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número de links de filtro para mostrar. Deixe em branco para mostrar todos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "expand_link_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O texto a ser exibido se mais do que o valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            }), " estiver disponível."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"See all\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Listagem de posts de blog"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " uma nova versão deste módulo foi lançada no final de março de 2024. Saiba mais sobre o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-module-versioning#post-listing",
          children: "novo módulo de listagem de posts padrão"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"post_listing\" path=\"@hubspot/post_listing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Selecione um blog para exibir. O padrão usará o blog atual quando usado em um modelo de blog ou o blog principal quando usado em outro lugar."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "listing_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de listagem para seus posts. As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "recent"
                }), ": mais recente."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_all_time"
                }), ": mais popular de todos os tempos."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_year"
                }), ": mais popular no ano passado."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_six_months"
                }), ": mais popular nos últimos seis meses."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_month"
                }), ": mais popular no mês passado."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recent"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "list_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um título H3."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Recent Posts\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número máximo de publicações para exibir."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Botão"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, posts de blog e listagens de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"button\" path=\"@hubspot/button\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URL para o qual o botão redireciona."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"url\": { \"type\": \"EXTERNAL\", \"href\": \"www.hubspot.com\", \"content_id\": null }, \"open_in_new_tab\": false, \"no_follow\": false }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto que será exibido no botão."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Add a button link here\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_default_style"
                }), " (Booleano)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_font"
                }), " (Fonte)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_color"
                }), " (Cor)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "text_hover_color"
                }), " (Cor)", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_hover_color"
                }), " (Cor)"]
              })]
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"override_default_style\": false, \"button_font\": { \"color\": \"#FFFFFF\", \"size_unit\": \"px\" }, \"button_color\": { \"color\": \"#000000\", \"opacity\": 100 }, \"text_hover_color\": { \"color\": \"#000000\", \"opacity\": 100 }, \"button_hover_color\": { \"color\": \"#CCCCCC\", \"opacity\": 100 } }"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Call to action"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"cta\" path=\"@hubspot/cta\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "guid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O identificador globalmente exclusivo da CTA."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Excluir dados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode adicionar este módulo à sua página de preferências de assinatura para permitir que os contatos solicitem que seus dados sejam excluídos. Esta função é necessária sob certos dados privados leis. Assim que um contato solicita que seus dados sejam excluídos, ele tem 30 minutos para confirmar em um e-mail que será enviado automaticamente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Usuários com ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "permissões de superadministrador"
      }), " receberão um e-mail de notificação sobre essas solicitações. Saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#download-data",
        children: "permitir que os contatos solicitem o download de seus dados"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"delete_data\" path=\"@hubspot/delete_data\", label=\"delete_data.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensagem exibida acima do botão."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ### Delete Data  Permanently delete your personal data stored by {{ site_settings.company_name}}. Personal data is information that can be used to identify you and doesn't include anonymized data.  You'll get a follow-up email where you'll need to verify your request.  "
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto do botão."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Request data deletion"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_alerts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O grupo de campos de alerta de êxito e falha. Incluindo os seguintes grupos de campos:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_success_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": conteúdo de rich text do alerta de sucesso."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_fail_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": conteúdo de rich text do alerta de falha."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_close_icon"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "icon"
                    }), ": o ícone para fechar o alerta."]
                  })
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Divisor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Suportado em páginas. Há uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-module-versioning#divider",
        children: "nova versão deste módulo"
      }), " disponível para contas criadas após 25 de agosto de 2022. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog/divider-module-v1",
        children: "Saiba mais sobre essa alteração"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"divider\" path=\"@hubspot/divider\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Altura em pixels (px) da linha divisória."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Largura percentual (%) da linha divisória."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "50"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cor"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cor (hexadecimal) e opacidade (número) da linha divisória."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"color\": \"#000000\", \"opacity\": 100 }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tipo de linha. As opções incluem: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "solid"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "dotted"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "dashed"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "solid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Alinhamento horizontal da linha divisória. As opções incluem: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "left"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "center"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "right"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "center"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostre/oculte as margens superior e inferior no divisor."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Valor de pixel (px) para a margem na parte superior e inferior da linha divisória. Opção disponível quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_padding"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fazer download de dados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode adicionar este módulo à sua página de preferências de assinatura para permitir que os contatos solicitem uma cópia de seus dados. Esta função é necessária sob certos dados privados leis. Usuários com ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "permissões de superadministrador"
      }), " receberão um e-mail de notificação sobre essas solicitações. Saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#delete-data",
        children: "permitir que os contatos solicitem que seus dados sejam excluídos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"download_data\" path=\"@hubspot/download_data\", label=\"download_data.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensagem exibida acima do botão."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ### Download Data  Download your personal data stored by {{ site_settings.company_name}}.  Personal data is information that can be used to identify you and doesn't include anonymized data.  "
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto do botão."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Request data download"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_alerts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O grupo de campos de alerta de êxito e falha. Incluindo os seguintes grupos de campos:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_success_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": conteúdo de rich text do alerta de sucesso."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_fail_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), ": conteúdo de rich text do alerta de falha."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_close_icon"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "icon"
                    }), ": o ícone para fechar o alerta."]
                  })
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Formulário"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, posts de blog e listagens de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"form\"\n path=\"@hubspot/form\"\n form={\n  \"form_id\": \"9e633e9f-0634-498e-917c-f01e355e83c6\",\n  \"response_type\": \"redirect\",\n  \"message\": \"Thanks for submitting the form.\",\n  \"redirect_id\": null,\n  \"redirect_url\": \"http://www.google.com\"\n }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Cabeçalho H3"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "form"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto de formulário incluindo:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "form_id"
                }), ": ID do formulário a ser usado"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), ": o que o visitante verá depois de enviar o formulário:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "inline"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "redirect"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "message"
                }), ": mensagem integrada se ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), " estiver definido como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "inline"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect_id"
                }), ": ID da página para redirecionar se ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), " estiver definido como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect_url"
                }), ": URL para redirecionar se ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), " estiver definido como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect"
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"form_id\": \"\", \"response_type\": \"redirect\", \"message\": \"Thanks for submitting the form.\", \"redirect_id\": null, \"redirect_url\": \"http://www.google.com\" }"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_are_overridden"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "E-mail para o qual enviar a notificação de formulário em vez das configurações padrão."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_override_email_addresses"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "E-mail"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Lista separada por vírgulas de e-mails a serem enviados quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_are_overridden"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "follow_up_type_simple"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envio de um e-mail de acompanhamento ativado."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "simple_email_for_live_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Followupemail"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ID do e-mail de acompanhamento. Disponível quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "follow_up_type_simple"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sfdc_campaign"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Salesforcecampaign"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Quando a integração com o Salesforce está ativa, o ID da campanha."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cabeçalho"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"header\" path=\"@hubspot/header\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto do cabeçalho."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A clear and bold header\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header_tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Escolha um nível de título. Opções incluem de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Espaçador horizontal"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, posts de blog, listagens de blog e e-mails."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"horizontal_spacer\" path=\"@hubspot/horizontal_spacer\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ícone"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog. Os ícones podem ser extraídos dos conjuntos de ícones Font Awesome 5.0.10 e 5.14.0."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"icon\" path=\"@hubspot/icon\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome do ativo."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"hubspot\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "purpose"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma opção de acessibilidade que categoriza a finalidade do ícone para os leitores de tela. Os valores disponíveis são:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "decorative:"
                }), " ignorado pelo leitor de tela."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "semantic:"
                }), " lido pelo leitor de tela."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"decorative\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma opção de acessibilidade que atribui um título ao ícone."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["o tipo de ícone. Pode ser um dos seguintes: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "solid"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "regular"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "light"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thin"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duotone"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"solid\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unicode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O valor unicode do ícone."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "f3b2"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Imagem"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"linked_image\" path=\"@hubspot/linked_image\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "img"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Imagem"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto de imagem contendo:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), ": url da imagem"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), ": texto alternativo da imagem"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "loading"
                }), ": as opções de carregamento lento incluem:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "disabled"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "lazy"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "width"
                }), ": valor px"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "height"
                }), ": valor px"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"src\": \"https://static.hubspot.com/final/img/content/email-template-images/placeholder_200x200.png\", \"alt\": \"placeholder_200x200\", \"loading\": \"disabled\", \"width\": 200, \"height\": 200 }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link opcional para a imagem."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "target"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abre o link em uma nova guia."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Grade de imagem"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"image_grid\" path=\"@hubspot/image_grid\", label=\"image_grid.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contém informações para cada slide. O objeto contém:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": URL da imagem."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": URL para onde o slide deve ser direcionado."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hover_messages"
                }), ": texto que se sobrepõe à imagem ao passar o mouse."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ { \"show_caption\": false, \"open_in_new_tab\": false } ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Modo de exibição da Galeria de imagens. As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "standard"
                }), ": controle deslizante padrão."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "thumbnail"
                }), ": navegador de miniaturas."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "lightbox"
                }), ": galeria lightbox."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightboxRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Número de linhas na galeria Lightbox quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightbox"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop_slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Habilita o loop pelos slides com próximo/anterior quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "auto_advance"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Avança automaticamente para o próximo slide quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "num_seconds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tempo (segundos) entre o avanço para o próximo slide quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_pagination"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Mostre os botões de navegação quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sizing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Define a altura dos slides quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "static"
                }), ": altura fixa."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "resize"
                }), ": altura variável."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "static"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Estilos de transição de slides quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": transição de slides."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fade"
                }), ": transição de desvanecimento."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slide"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "caption_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Posição das legendas do slide quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "below"
                }), ": sempre manter legendas abaixo da imagem."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "superimpose"
                }), ": sobrepor legendas em cima das imagens."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Galeria de imagens"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, posts de blog e listagens de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"gallery\" path=\"@hubspot/gallery\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contém informações para cada slide. O objeto contém:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": URL da imagem."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "show_caption"
                }), ": booleano para mostrar a legenda do slide."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "caption"
                }), ": rich text da legenda."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": URL para onde o slide deve ser direcionado."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "open_in_new_tab"
                }), ": booleano para direcionar se o link precisar ser aberto em uma nova guia."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ { \"show_caption\": false, \"open_in_new_tab\": false } ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Modo de exibição da Galeria de imagens. As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "standard"
                }), ": controle deslizante padrão."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "thumbnail"
                }), ": navegador de miniaturas."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "lightbox"
                }), ": galeria lightbox."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightboxRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Número de linhas na galeria Lightbox quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightbox"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop_slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Habilita o loop pelos slides com próximo/anterior quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "auto_advance"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Avança automaticamente para o próximo slide quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "num_seconds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tempo (segundos) entre o avanço para o próximo slide quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_pagination"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Mostre os botões de navegação quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sizing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Define a altura dos slides quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "static"
                }), ": altura fixa."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "resize"
                }), ": altura variável."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "static"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Estilos de transição de slides quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": transição de slides."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fade"
                }), ": transição de desvanecimento."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slide"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "caption_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Posição das legendas do slide quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), ". As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "below"
                }), ": sempre manter legendas abaixo da imagem."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "superimpose"
                }), ": sobrepor legendas em cima das imagens."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Galeria com controle deslizante de imagens"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, posts de blog e listagens de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"unique_name\"\n    path=\"@hubspot/image_slider_gallery\",\n    slides=[\n        {\n        \"img\": {\n            \"src\": \"\",\n            \"alt\": \"Default image alt text\"\n        },\n        \"caption\": \"<strong>1</strong> An optional caption for the image that will be added to the gallery. Enter any descriptive text for this image that you would like visitors to be able to read.\",\n        \"link_url\": \"\"\n        },\n        {\n        \"img\": {\n            \"src\": \"\",\n            \"alt\": \"Default image alt text\"\n        },\n        \"caption\": \"<strong>2</strong> An optional caption for the image that will be added to the gallery. Enter any descriptive text for this image that you would like visitors to be able to read.\",\n        \"link_url\": \"\"\n        }\n    ]\n    slideshow_settings={\n        \"slides\": {\n        \"per_page\": 1,\n        \"sizing\": \"natural\",\n        \"aspect_ratio\": \"16/9\",\n        \"show_captions\": true,\n        \"caption_position\": \"below\"\n        },\n        \"movement\": {\n        \"transition\": \"slide\",\n        \"loop_slides\": false,\n        \"auto_advance\": false,\n        \"auto_advance_speed_seconds\": 5\n        },\n        \"navigation\": {\n        \"show_main_arrows\": true,\n        \"show_thumbnails\": false,\n        \"show_dots\": false\n        }\n    }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um grupo de campos que contém os seguintes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), ": URL da imagem."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "caption"
                }), ": rich text para a legenda da imagem."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), ": URL ao qual o slide está vinculado."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slideshow_settings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O grupo de campos de configurações do controle deslizante de imagens. Inclui os seguintes grupos de campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slides"
                }), ": configurações do slide, incluindo:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "per_page"
                    }), ": número de slides por página."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "sizing"
                    }), ": dimensionamento de imagem."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "aspect_ratio"
                    }), ": proporção da imagem."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "show_captions"
                    }), ": um booleano"]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "movement"
                }), ": configurações de comportamento de deslizamento da imagem."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "navigation"
                }), ": configurações de navegação pelas imagens."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "default_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Os elementos de texto padrão do módulo, incluindo:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "default_caption"
                }), ": legenda da imagem."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "default_image_alt_text"
                }), ": texto da imagem."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slider_aria_label"
                }), ": o rótulo aria padrão do módulo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "arial_label_thumbnails"
                }), ": a miniatura ária padrão do módulo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slider_nav_aria_label"
                }), ": o rótulo aria padrão da navegação do módulo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "prev"
                }), ": texto do slide anterior."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "next"
                }), ": texto do próximo slide."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "first"
                }), ": ir para o texto do primeiro slide."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slideX"
                }), ": ir para o texto do slide X."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pageX"
                }), ": ir para o texto da página X."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "play"
                }), ": iniciar texto com reprodução automática."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pause"
                }), ": pausar texto com reprodução automática."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "carousel"
                }), ": texto do carrossel."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "select"
                }), ": texto para selecionar um slide a ser mostrado."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), ": texto do slide."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slidelabel"
                }), ": rótulo do slide."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Troca de idiomas"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"language_switcher\" path=\"@hubspot/language_switcher\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O idioma do texto no seletor de idiomas. Os valores são:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pagelang"
                }), ": os nomes dos idiomas serão exibidos no idioma da página em que o interruptor está ligado."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "localized"
                }), ": o nome de cada idioma será exibido nesse idioma."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hybrid"
                }), ": uma combinação dos dois."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "localized"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Logotipo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo\" path=\"@hubspot/logo\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "img"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Imagem"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto de imagem contendo:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_inherited_src"
                }), ": substitua o logotipo padrão nas configurações"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), ": url da imagem"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), ": texto alternativo para o logotipo"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"override_inherited_src\": false, \"src\": null, \"alt\": null }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Link opcional para o logotipo. Se nenhum URL for especificado, o logotipo vai encaminhar para o ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Logotipo URL"
            }), " definido nas ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors#edit-your-logo",
              children: "configurações da marca"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "open_in_new_tab"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Abre o link em uma nova guia."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "suppress_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Oculte o nome da empresa quando uma imagem não estiver selecionada."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Escolha um nível de título quando nenhuma imagem estiver selecionada e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "suppress_company_name"
            }), " for igual ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ". As opções incluem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), " até ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Grid de logotipo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Uma grade personalizável de contêineres para exibir logotipos de forma uniforme. Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo grid\"\n  path=\"@hubspot/logo_grid\"\n  group_logos=[\n    {\n      \"logo\": {\n        \"loading\": \"lazy\",\n        \"alt\": \"company_logo\",\n        \"src\": \"https://www.example.com/Logos/color/logo.png\"\n      }\n    },\n    {\n      \"logo\": {\n        \"loading\": \"lazy\",\n        \"alt\": \"company2_logo\",\n        \"src\": \"https://www.example.com/Logos/color/logo2.png\"\n      }\n    },\n    {\n      \"logo\": {\n        \"alt\": \"lorem-logo\",\n        \"height\": 40,\n        \"loading\": \"lazy\",\n        \"max_height\": 40,\n        \"max_width\": 175,\n        \"src\": \"https://www.example.com/Logos/color/logo3.png\",\n        \"width\": 175\n      }\n    }\n  ],\n  styles={\n    \"group_logo\": {\n      \"group_background\": {\n        \"aspect_ratio\": \"1/1\",\n        \"background_color\": {\n          \"color\": \"#8E7CC3\",\n          \"opacity\": 100\n        }\n      },\n      \"group_spacing\": {\n        \"padding\": {\n          \"padding\": {\n            \"bottom\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"left\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"right\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"top\": {\n              \"units\": \"px\",\n              \"value\": 75\n            }\n          }\n        }\n      },\n      \"max_logo_height\": 85\n    },\n    \"group_logo_grid\": {\n      \"column_count\": 3,\n      \"grid_gap\": 54\n    }\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_logos"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Uma matriz que contém um objeto para cada logotipo na grade."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "logo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Em ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_logos"
            }), ", um objeto para cada logotipo na grade. Cada logotipo pode incluir os seguintes parâmetros:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), ": o caminho de arquivo do logotipo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "loading"
                }), ": o comportamento de ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/cms/content/performance/lazy-loading",
                  children: "carregamento lento"
                }), " do logotipo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), ": o texto alternativo do logotipo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "height"
                }), ": a altura do logotipo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "width"
                }), ": a largura do logotipo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "max_height"
                }), ": a altura máxima do logotipo."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "max_width"
                }), ": a largura máxima do logotipo."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "styles"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Matriz"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma matriz que contém os campos de estilo que afetam o layout de grade, os contêineres de logotipo e as imagens de logotipo. Essa matriz contém os seguintes grupos de estilos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_logo"
                }), ": estilos que afetam os contêineres e as imagens de logotipo. Contém o seguinte:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "group_background"
                    }), ": estilos que definem a taxa de proporção e a cor do plano de fundo dos contêineres de grade. As proporções incluem: ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "1/1"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "4/3"
                    }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "16/9"
                    }), "."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "group_spacing"
                    }), ": estilos que definem o deslocamento interno do contêiner de logotipo. Para logotipos maiores, um valor de deslocamento maior pode reduzir a largura do logotipo."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "max_logo_height"
                    }), ": a altura máxima de cada logotipo."]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_logo_grid"
                }), ": estilos que definem o layout da grade, incluindo:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "column_count"
                    }), ": o número de colunas na grade."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "grid_gap"
                    }), ": o espaçamento entre os itens da grade."]
                  })]
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reuniões"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, posts de blog e listagens de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"meetings\" path=\"@hubspot/meetings\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meeting"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Reunião"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Selecione um link de reunião."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Logins sociais de associações"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Este módulo fornece o recurso de login do Google e do Facebook para sites de associação. O usuário deve entrar com uma conta vinculada ao e-mail do contato no CRM. Você pode escolher quais logins de redes sociais devem ser habilitados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Compatível nas páginas de login de membros."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social\" path=\"@hubspot/membership_social_logins\",\n        clientid=\"\"\n        appid=\"\"\n        facebook_enabled=true\n        google_enabled=true\n        %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["O Facebook requer uma ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.facebook.com/docs/development/register",
          children: "Conta de desenvolvedor do Facebook"
        }), " e um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.facebook.com/docs/development/create-an-app",
          children: "aplicativo Facebook criado"
        }), " com configurações básicas. Depois de ter feito isso, o id do aplicativo é o que você transmite para o módulo."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["O Google requer uma conta do Google e a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/identity/sign-in/web/sign-in#create_authorization_credentials",
          children: "criação de credenciais de autorização "
        }), "; com isso, você transmitirá o ID do cliente do seu aplicativo para o módulo."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_appid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID do aplicativo do Facebook."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ative o botão para login do Facebook. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_appid"
            }), " é obrigatório**.**"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_clientid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID de cliente do Google."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ative o botão para login do Google. ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_clientid"
            }), " é necessário**.**"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Menu"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, posts de blog e listagens de blog."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Deseja criar seu próprio menu personalizado? Experimente nossa ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#menu",
        children: "função menu()"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"menu\" path=\"@hubspot/menu\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menu"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ID do menu."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "root_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tipo de menu avançado. As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "site_root"
                }), ": sempre mostrar páginas de nível superior no menu."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "top_parent"
                }), ": mostrar páginas no menu que estão relacionadas com a seção que está sendo visualizada."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "parent"
                }), ": mostrar páginas no menu que estão relacionadas com a página que está sendo visualizada."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "breadcrumb"
                }), ": menu de caminho com estilo de navegação horizontal (breadcrumb)."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_root"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_levels"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Determina o número de filhos da árvore do menu que podem ser expandidos no menu. As opções incluem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), " até ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "10"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A orientação do menu. As opções incluem: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "horizontal"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "vertical"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flyouts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Funcionalidade de passar o mouse habilitada para itens do menu secundário."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rodapé da página"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"page_footer\" path=\"@hubspot/page_footer\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Solicitação de senha"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, posts de blog e listagens de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"password_prompt\" path=\"@hubspot/password_prompt\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submit_button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto que aparece no botão Enviar."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Submit\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "password_placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto temporário para o campo de senha."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Password\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bad_password_message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensagem a ser exibida quando uma senha é inserida incorretamente."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Sorry, please try again. \""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pagamentos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, posts de blog e listagens de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"payments\" path=\"@hubspot/payments\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para configurar o módulo para usar um link de pagamento específico, inclua o ID do link de pagamento. Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#link#find-a-payment-link-s-id",
              children: "localizar o ID"
            }), " no URL ao editar o link de pagamento."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout_location"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Defina se o formulário de pagamento abre em uma nova guia ou em uma sobreposição. Os valores disponíveis são:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "new_tab"
                }), ": abre o formulário de pagamento em uma nova guia."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "overlay"
                }), ": abre o formulário de pagamento em uma sobreposição deslizante."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"new_tab\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Define o texto do botão de finalização da compra."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Checkout\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_target"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Se o botão usa um link de pagamento da HubSpot ou um link externo. As opções incluem: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "payment_link"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"payment_link\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Link"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_target"
            }), " está definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_link"
            }), ", especifica o destino do link externo. Os tipos compatíveis incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "EXTERNAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTENT"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Produto"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Exibe um produto da conta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
        children: "biblioteca de produtos"
      }), ". Suportado em páginas, posts de blog e listagens de blog."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module\n  path=\"@hubspot/product\",\n  product={\n    \"id\" : 2124070179\n  },\n  group_button={\n    \"button_text\" : \"Buy\",\n    \"override_product_button\" : true,\n    \"button_override\" : {\n      \"no_follow\" : false,\n      \"open_in_new_tab\" : false,\n      \"sponsored\" : false,\n      \"url\" : {\n        \"href\" : \"https://www.test.com\",\n        \"type\" : \"EXTERNAL\"\n      }\n  },\n  group_description={\n    \"description_override\" : \"Monthly gym membership with access to shared locker facilities.\",\n    \"override_product_description\" : true\n  },\n  group_name={\n    \"heading_level\" : \"h3\",\n    \"name_override\" : \"Gym membership\",\n    \"override_product_name\" : true\n  },\n  group_image={\n    \"image_override\" : {\n      \"alt\" : \"360_F_317724775_qHtWjnT8YbRdFNIuq5PWsSYypRhOmalS\",\n      \"height\" : 360,\n      \"loading\" : \"lazy\",\n      \"src\" : \"https://2272014.fs1.hubspotusercontent-na1.net/hubfs/2272014/360_F_317724775_qHtWjnT8YbRdFNIuq5PWsSYypRhOmalS.jpg\",\n      \"width\" : 640\n    },\n    \"override_product_image\" : true\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Produto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O produto a ser exibido, especificado pelo ID do produto."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Por padrão, o módulo inclui um botão que direciona os usuários para o URL definido do produto. Para personalizar o destino do botão, inclua este grupo de campos opcionais junto com os seguintes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_text"
                }), ": uma string que define o texto do botão."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_button"
                }), ": definido como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " para substituir as configurações padrão do link do botão. Em seguida, inclua um", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_override"
                }), " objeto. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_override"
                }), " abaixo."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_override"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["No grupo de campos ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_button"
            }), ", isso define o comportamento do URL do botão quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "override_product_button"
            }), " está definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ". Inclui os seguintes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "no_follow"
                }), ": campo booleano para o comportamento de ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://ahrefs.com/blog/nofollow-links",
                  children: "no_follow"
                }), " do link."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "open_link_in_new_tab"
                }), ": campo booleano para o comportamento de abertura do link."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "url"
                }), ": um objeto que especifica o destino do botão."]
              })]
            }), "No campo", (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            }), ", você pode definir o tipo de destino por meio do campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            }), ", que suporta os seguintes tipos de conteúdo:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "EXTERNAL"
                }), ": um URL padrão. Inclua o URL em um campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTENT"
                }), ": uma página da HubSpot. Inclua o ID da página em um campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content_id"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "PAYMENT"
                }), ": um link de pagamento. Inclua o link de pagamento em um campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "EMAIL_ADDRESS"
                }), ": um endereço de e-mail. Inclua o endereço em um campo ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), "."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Por padrão, o módulo exibirá o nome do produto na parte superior do módulo como um h3. Para personalizar o nome, inclua este grupo de campos opcionais junto com os seguintes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "heading_level"
                }), ": o tamanho do título. Pode ser ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "h1"
                }), " - ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "h6"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_name"
                }), ": defina como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " para especificar o texto em vez do nome do produto."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "name_override"
                }), ": a string que você deseja exibir na parte superior do módulo."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Por padrão, o módulo exibirá a descrição do conjunto do produto. Para personalizar a descrição, inclua este grupo de campos opcionais junto com os seguintes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_description"
                }), ": definido como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " para personalizar a descrição do produto."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description_override"
                }), ": uma string contendo sua nova descrição."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Por padrão, o módulo exibirá a imagem definida do produto. Para personalizar esta imagem, inclua este grupo de campos opcionais com os seguintes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_image"
                }), ": definido como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), " para especificar uma nova imagem."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "image_override"
                }), ": um objeto que especifica a nova imagem, incluindo as seguintes propriedades:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "alt"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "height"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "loading"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "src"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "width"
                    })
                  })]
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Download de orçamento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em modelos de orçamento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"download\" path=\"@hubspot/quote_download\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto exibido no botão de download."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Download"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "download_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensagem de erro exibida se o download falhar."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem downloading the quote. Please try again."
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pagamento de orçamento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em modelos de orçamento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"payment\" path=\"@hubspot/quote_payment\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O cabeçalho exibido acima da seção de pagamento de um modelo de orçamento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de cabeçalho usado para exibição de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_text"
            }), ". Os valores incluem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h2"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h3"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h4"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h5"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto do botão de pagamento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Pay now"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "needs_signature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto do botão quando uma assinatura é necessária."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment can't be made because the quote isn't fully signed."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensagem que é exibida quando ocorre um erro durante a finalização da compra."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem setting up checkout. Please contact the person who sent you this quote."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment_status_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mensagem que é exibida quando ocorre um erro ao tentar fazer um pagamento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem loading the payment status for this quote. Please try refreshing the page."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "paid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um indicador de status de pagamento bem-sucedido."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Paid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment_processing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um indicador de status de processamento de pagamento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment processing"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Assinatura do orçamento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em modelos de orçamento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"signature\" path=\"@hubspot/quote_signature\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando um orçamento requer uma assinatura eletrônica, os seguintes campos estão disponíveis em um objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "esignature"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pretext"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto de explicação da assinatura eletrônica."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Before you sign this quote, an email must be sent to you to verify your identity. Find your profile below to request a verification email."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "verify_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto exibido no botão de verificação."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Verify to sign"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "failure"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto de alerta que é exibido quando as informações de assinatura não podem ser recuperadas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem retrieving the signature information. Please reload the page."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "verification_sent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um indicador de status que aparece quando a solicitação de verificação foi enviada com sucesso ao signatário do orçamento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Verification sent"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "signed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto que é exibido quando o orçamento foi assinado com êxito."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signed"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Quando um orçamento requer uma assinatura por escrito, os seguintes campos estão disponíveis em um objeto ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "print_signature"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto exibido na parte superior da seção de assinatura."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signature"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "signature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto exibido ao lado do campo de assinatura."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signature"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto exibido ao lado do campo de data."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "printed_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto exibido ao lado do campo de nome por extenso."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Printed name"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "countersignature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um objeto que contém conteúdo para a seção de assinatura. Este objeto pode conter os seguintes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "header"
                }), ": texto que é exibido na parte superior da seção de assinatura."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "countersignature"
                }), ": texto que é exibido ao lado do campo de assinatura."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "date"
                }), ": texto que é exibido ao lado do campo de data."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "printed_name"
                }), ": texto que é exibido ao lado do campo de nome por extenso."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signed"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Itens de linha"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em modelos de orçamento. Este módulo também inclui o texto padrão usado em orçamentos personalizados."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"module_165350106057652\" path=\"@hubspot/line_items\", label=\"line_items.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O título da coluna de item de linha."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Column description"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "use_additional_product_property"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exibir caixa de seleção para permitir que os usuários selecionem colunas de item de linha adicionais nas propriedades do produto."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "additional_product_properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Uma propriedade de produto. As opções incluem: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "price"
                }), ": preço do item de linha. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "item_description"
                }), ": descrição do item de linha. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "quantity"
                }), ": número de itens de linha. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "amount"
                }), ": custo total dos itens de linha. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hs_recurring_billing_start_date"
                }), ": data de início de cobrança para itens de linha recorrentes. "]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "discount"
                }), ": o valor do desconto se aplica ao item de linha. "]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm_product_property"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Propriedade do objeto do CRM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Selecione qualquer propriedade de produto a ser exibida como um cabeçalho de coluna de item de linha."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rich text"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em todos os tipos de modelo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"rich_text\" path=\"@hubspot/rich_text\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Rich Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Bloco HTML."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ## Something Powerful  \\n  ### Tell The Reader More  \\n  The headline and subheader tells us what you're [offering](\\\"#\\\"), and the form header closes the deal. Over here you can explain why your offer is so great it's worth filling out a form for.  \\n  Remember:  \\n<ul>\\n<li>Bullets are great</li>\\n<li>For spelling out [benefits](\\\"#\\\") and</li>\\n<li>Turning visitors into leads.</li>\\n</ul>"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Lista de RSS"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"rss_listing\" path=\"@hubspot/rss_listing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um título H3."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Test\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Tipos de feeds RSS. As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog"
                }), " - blog hospedado na HubSpot."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "external"
                }), " - Um feed RSS externo."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["URL RSS a ser usado quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "external"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_group_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ID do blog a ser usado quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "topic_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tag"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["(opcional) Id da tag a ser filtrada quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_items"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número máximo de publicações para exibir."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "include_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclua a imagem em destaque"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostre o nome do autor."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attribution_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O texto que atribui um autor a uma publicação Disponível quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_author"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"by\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostre o Texto de resumo do post."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit_to_chars"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Limita o comprimento do texto de resumo quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            }), " é igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "200"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "click_through_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O texto que será exibido para o link de clickthrough no final de um resumo de post quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Read more\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostre a data de publicação."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish_date_format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Formato da data de publicação quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ". As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                }), " (ex: 06/11/06 12:00PM)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                }), " (ex: 6 de jun 2006 12:00:00 pm)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                }), " (ex: 6 de junho de 2017 12:00:00 pm EDT)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MMMM d, yyyy 'at' h:mm a"
                }), " (ex: 6 de junho de 2006 às 12:00 pm)"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "h:mm a 'on' MMMM d, yyyy"
                }), " (ex: 12:00 pm em 6 de junho de 2006)"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "short"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish_date_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O texto que indica quando um post foi publicado quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"posted at\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Entrada de pesquisa do site"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, posts de blog e listagens de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_input\" path=\"./local-search_input\"\n  placeholder=\"Search\"\n  include_search_button=true\n  results={\n   \"use_custom_search_results_template\": \"true\",\n   \"path_id\": \"77977569400\"\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "field_label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto do rótulo de entrada de pesquisa"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto temporário para o campo de entrada."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Search\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "include_search_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclui um botão de pesquisa."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Quais tipos de conteúdo devem ser incluídos nos resultados da pesquisa. O objeto contém as seguintes chaves com valores booleanos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "website_pages"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "landing_pages"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_posts"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "knowledge_articles"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"website_pages\": true, \"landing_pages\": false, \"blog_posts\": true, \"knowledge_articles\": false }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "results"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um objeto que permite controles para usar uma página de resultados de pesquisa personalizada. Inclui as seguintes propriedades:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "use_custom_search_results_template"
                }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "(booleano):"
                }), " quando definido como ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), ", os usuários podem selecionar uma página de resultados de pesquisa personalizada. O padrão é ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "path_id"
                }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "(string):"
                }), " o ID da página que será usado nos resultados da pesquisa. A página referenciada deve conter o módulo de resultados de pesquisa."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Resultados de pesquisa"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, posts de blog e listagens de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_results\" path=\"@hubspot/search_results\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_featured_images"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exiba imagens em destaque para itens."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cabeçalho da sessão"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"section_header\" path=\"@hubspot/section_header\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Conteúdo do cabeçalho da seção."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A clear and bold header\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Nível de título para ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            }), ". As opções incluem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), " até ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subheader"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto do parágrafo de subtítulo da seção."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A more subdued subheader\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Menu simples"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"simple_menu\" path=\"@hubspot/simple_menu\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "menu_tree"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menu simples"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto de menu simples."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "orientation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A orientação do menu. As opções incluem: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "horizontal"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "vertical"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Seguir na rede social"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Adicione links aos seus perfis de mídias sociais, com a reordenação de arrastar e soltar no editor de conteúdo. Insere ícones automaticamente com base no URL social, mas os ícones podem ser substituídos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_follow\" path=\"@hubspot/social_follow\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O link de destino para a página social adicionada no editor."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "open_in_new_window"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Quando definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", o link será aberto na mesma guia do navegador."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_podcast"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Defina como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " para exibir um ícone de podcast em vez de um ícone padrão ou personalizado."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Por padrão, o texto alternativo é gerado automaticamente. Quando definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", você pode substituir o texto alternativo padrão por um valor personalizado usando o campo ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "aria_label"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "aria-label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_alt_text"
            }), " é ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", este campo define o ícone de texto alternativo (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Follow us on Facebook"
            }), "). É usado por leitores de tela para descrever o ícone para usuários com deficiência visual."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Por padrão, os ícones são preenchidos automaticamente com base na URL do link. Quando definido como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", você pode usar o ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_icon"
            }), " para selecionar um dos outros ícones fornecidos (Font Awesome 6.4.2)."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ícone"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_icon"
            }), " é ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_podcast"
            }), " é ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", use este campo para especificar um ícone personalizado do conjunto fornecido. Os ícones disponíveis são do Font Awesome 6.4.2."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Compartilhamento social"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_sharing\" path=\"@hubspot/social_sharing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Observação: a variável ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "social_link_url"
      }), " na coluna padrão abaixo é o mesmo valor que o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "link"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Esse é o link de destino que será encurtado para facilitar o compartilhamento nas redes sociais."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto contendo:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": booleano para ativar o item social"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": URL personalizado para compartilhamento de URL nas redes sociais"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://www.facebook.com/share.php?u={{ social_link_url }}\" }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "twitter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contém:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": booleano para habilitar item social"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": URL personalizado para URL de compartilhamento de redes sociais"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("code", {
              children: ["{ \"enabled\": false, \"custom_link_format\": \"", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://twitter.com/intent/tweet?original_referer=%5C%7B%5C%7B",
                children: "https://twitter.com/intent/tweet?original_referer=\\{\\{"
              }), " social_link_url }}&url={{ social_link_url }}&source=tweetbutton&text={{ social_page_title|urlencode }}\" }"]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "linkedin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto contendo:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": booleano para ativar o item social"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": URL personalizado para compartilhamento de URL nas redes sociais"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://www.linkedin.com/shareArticle?mini=true&url={{ social_link_url }}\" }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pinterest"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contém:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": booleano para habilitar item social."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": URL personalizado para URL de compartilhamento social."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pinterest_media"
                }), ": objeto de imagem incluindo:", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "src"
                    }), ": URL da imagem."]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "alt"
                    }), ": texto alternativo para a imagem."]
                  })]
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://pinterest.com/pin/create/button/?url={{ social_link_url }}&media={{ pinterest_media }}\", \"pinterest_media\": { \"src\": \"\", \"alt\": null } }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto contendo:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), ": booleano para ativar o item social"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), ": URL personalizado para compartilhamento de URL nas redes sociais"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"mailto:?subject=Check out {{ social_link_url }} &body=Check out {{ social_link_url }}\" }"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cartão com guias"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module\n  path=\"@hubspot/tabbed_card\",\n  tabs=[\n   {\n    \"content\" : \"<p>This is the descriptive text contained in tab 1.</p>\",\n    \"tab_label\" : \"Tab 1 label\"\n   },\n   {\n    \"content\" : \"<p>This is the descriptive text contained in tab 2.</p>\",\n    \"tab_label\" : \"Tab 2 label\"\n   }\n  ],\n  fixed_height=false\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tabs"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Grupo de campos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Um grupo de campos que contém o rótulo da guia e o conteúdo do texto de cada guia. Contém os seguintes campos:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "tab_label"
                }), ": o rótulo da guia."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "content"
                }), ": o conteúdo rich text da guia."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Some additional information in one line\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fixed_height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Por padrão, as guias serão definidas em uma altura fixa para que a altura do contêiner permaneça a mesma ao alternar entre guias. Você pode definir isso como ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), " se quiser que a altura do contêiner do cartão com guias se baseie no conteúdo da guia ativa."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Uma linha de texto"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"text\" path=\"@hubspot/text\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Adicione seu texto a este parâmetro."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Some additional information in one line\""
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Vídeo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas, listagens de blog e posts de blog."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"video\" path=\"@hubspot/video\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O tipo de vídeo. As opções incluem:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "embed"
                }), ": código de incorporação de uma fonte externa."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hubspot_video"
                }), ": vídeo hospedado pela HubSpot."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_video"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Reprodutor de vídeo"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Vídeo hospedado pela HubSpot. Quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_video"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed_field"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Incorporar"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Suporta tipos de incorporação:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "oembed"
                }), (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "video"
                    }), ": URL de vídeo."]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "html"
                }), ": código de incorporação de vídeo."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed_thumbnail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Imagem"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Substitua a imagem de miniatura oembed quando ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed_field"
            }), " for igual a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contém ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed_thumbnail_play_button_color"
            }), " - Campo de cor."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"oembed_thumbnail_play_button_color\":\"#ffffff\"}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder_fields"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objeto que contém:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "placeholder_title"
                }), ": campo de texto."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "placeholder_description"
                }), ": campo de texto."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"placeholder_title\":\"No video selected\", \"placeholder_description\":\"Select a video type in the sidebar.\"}"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Incorporação de vídeo (landing page)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Suportado em páginas."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"video_embed_lp\" path=\"@hubspot/video_embed_lp\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Texto"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O URL do vídeo. URLs do Vimeo e do YouTube são suportados."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://www.youtube.com/watch?v=X1Rr5BFO5rg\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_full_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booleano"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Define o vídeo para largura total (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "), ou defina manualmente a largura e a altura (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ")."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Largura máxima (px)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "800"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Número"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Altura máxima (px)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "450"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Link do WhatsApp"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Suportado em páginas, posts de blog e listagens de blog. Requer um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/connect-whatsapp-to-the-conversations-inbox",
        children: "canal do WhatsApp conectado"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"whatsapp_link\" path=\"@hubspot/whatsapp_link\", label=\"whatsapp_link\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Selecione nos ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/inbox/connect-whatsapp-to-the-conversations-inbox",
              children: "Canais do WhatsApp conectados à conta"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "optin_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/inbox/collect-consent-for-whatsapp-conversations#subscribe-a-contact-using-opt-in-keywords-in-a-whatsapp-thread",
              children: "texto de opt-in e opt-out"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_display"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mostrar:"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["selecione se o botão apresenta texto, um ícone do WhatsApp ou ambos. As opções incluem: ", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "text_and_icon"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "text"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "icon"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text_and_icon"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escolha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A posição do ícone no botão. As opções incluem ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "left"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "right"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "left"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto do botão."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Chat on WhatsApp"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_icon_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O texto do ícone para leitores de tela."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WhatsApp Icon"
            })
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}