"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35878035775;
const slug = exports.slug = 'reference/cms/hubl/variables-macros-syntax';
const title = exports.title = 'HubLの変数とマクロ構文';
const name = exports.name = 'APAC JAPAN (ja) | [new] HubL variables & macros syntax_JA | 202011';
const metaDescription = exports.metaDescription = 'HubLの変数とマクロを使用してHubSpots CMSとEメールで動的なページを作成する方法について説明します。 ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E5%A4%89%E6%95%B0",
  "label": "変数",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9E%E3%82%AF%E3%83%AD",
  "label": "マクロ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%91%BC%E3%81%B3%E5%87%BA%E3%81%97",
  "label": "呼び出し",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A4%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%88",
  "label": "インポート",
  "parent": null
}, {
  "depth": 0,
  "id": "from",
  "label": "from",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AB%E3%83%BC%E3%83%97%E5%86%85%E3%81%AE%E5%A4%89%E6%95%B0",
  "label": "ループ内の変数",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      pre: "pre",
      h2: "h2",
      strong: "strong",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubLの変数とマクロ構文"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubLでは、値の保存やテンプレートへの出力に変数を使用します。変数はテンプレートのロジックで使用することも、forループで繰り返し使用することもできます。テンプレートで繰り返し使用されるコードの動的な部分を出力するには、変数のほかに、マクロを使用することもできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["変数は", (0, _jsxRuntime.jsx)(_components.code, {
        children: "}}"
      }), "で囲まれた式です。変数の基本的な構文を次に示します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// variables\n{{ variable }}\n{{ dict.attribute }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "変数"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["変数は、式の中の単一の語、またはディクショナリー（辞書型）の属性のいずれかです。HubLでは、ディクショナリー（", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "dicts"
      }), "）と呼ばれるPythonベースのデータ構造を使用して、さまざまな変数のセットを保存します。例えば、HubSpotではディクショナリー「content」を使用して、そのテンプレートで作成されたコンテンツに関する多くの属性を格納しています。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content.absolute_url"
      }), "と指定した場合、特定のコンテンツのURLが出力されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotには、ページやブログ、Eメールのテンプレートに使用できる多数の定義済み変数が用意されています。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables",
        children: "変数のリファレンスリスト"
      }), "があります。アカウントからページを閲覧するときに", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting#developer-info",
        children: "開発者情報"
      }), "を表示することもでき、そのページ内で利用可能な変数が表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "変数の値やディクショナリーの属性をテンプレートに出力するほかに、ユーザー独自の変数を定義することもできます。1つの変数内に文字列、ブール値、整数、シーケンスを格納したり、ディクショナリーを作成したりできます。変数は、文の区切り文字の内側で、「set」を使って定義します。格納された変数を出力するには、変数名を式として記述します。以下では、さまざまなタイプの情報を変数に格納し、出力しています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["変数は1つの単語にするか、スペースの代わりに下線を使用する（my_variableなど）必要があります。", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "HubLでは、ハイフンで連結した変数名はサポートされません。"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set string_var = \"This is a string value stored in a variable\" %}\n{{ string_var}}\n\n{% set bool_var = True %}\n{{ bool_var}}\n\n{% set int_var = 53 %}\n{{ int_var}}\n\n{% set seq_var = [\"Item 1\", \"Item 2\", \"Item 3\"] %}\n{{ seq_var}}\n\n{% set var_one = \"String 1\" %}\n{% set var_two = \"String 2\" %}\n{% set sequence = [var_one,  var_two] %}\n{{ sequence }}\n\n{% set dict_var = {\"name\": \"Item Name\", \"price\": \"$20\", \"size\":\"XL\"} %}\n{{ dict_var.price }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "This is a string value stored in a variable True 53 [Item1, Item2, Item3]\n[String 1, String 2] $20\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "上記の例はそれぞれに異なる型の変数を格納しています。最後の例では2つの別々の変数を1つのシーケンスとして格納しています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["変数は、値を出力するほかにも、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/if-statements",
        children: "if文"
      }), "内、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/filters",
        children: "filter"
      }), "パラメーターや", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions",
        children: "function"
      }), "パラメーターとして使用したり、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "forループ"
      }), "で反復処理（シーケンス変数のみ）に使用したりできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "一般的な使用例の1つに、スタイルシートでよく使われるCSSの値を変数を使用して定義する方法があります。例えば、ある色をCSSファイル全体で繰り返し使用しているとします。この色を変数を使用して定義しておけば、変数の値を変更するだけで、色を変更できます。次にファイルを公開したときに、その変数に対する参照が全て更新されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "<!-- defines variable and assigns HEX color -->\n{% set primaryColor = \"#F7761F\" %}\n\na {\n    color: {{ primaryColor }}; {# prints variable HEX value #}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  color: #f7761f;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "マクロ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubLマクロでは、動的な値を使用して複数の文を出力できます。例えば、同じコードブロックを何度も記述するような場合は、マクロを使用すると便利です。マクロに渡す引数を切り替えることで、そのコードブロックを出力できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "マクロは、HubL文の中で定義し、名前を付け、引数を指定します。このマクロを別の文で呼び出して動的な値を渡すと、動的な引数を持つ最終的なコードブロックが出力されます。マクロの基本的な構文を次に示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% macro name_of_macro(argument_name, argument_name2)  %}\n    {{ argument_name }}\n    {{ argument_name2 }}\n{% endmacro %}\n{{ name_of_macro(\"value to pass to argument 1\", \"value to pass to argument 2\")  }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["マクロから空白文字が改行として返される場合は、テンプレート内の空白文字を手動で取り除くことができます。ブロック、コメント、変数の式の先頭または末尾にマイナス記号（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "-"
      }), "）を追加すると、そのブロックの前または後の空白文字が削除されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% macro name_of_macro(argument_name, argument_name2) -%}\n    {{ argument_name }}\n    {{ argument_name2 }}\n{%- endmacro %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の実用例では、さまざまなベンダープレフィックスを含むCSS3プロパティーを動的な値と合わせて出力するために、マクロを使用しています。この例では、1つのmacroタグで5行のコードを出力できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + CSS",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro trans(value) %}\n-webkit-transition: {{value}};\n-moz-transition: {{value}};\n-o-transition: {{value}};\n-ms-transition: {{value}};\ntransition: {{value}};\n{% endmacro %}\n\n\na {\n    {{ trans(\"all .2s ease-in-out\") }}\n}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-css",
            children: "a {\n  -webkit-transition: all 0.2s ease-in-out;\n  -moz-transition: all 0.2s ease-in-out;\n  -o-transition: all 0.2s ease-in-out;\n  -ms-transition: all 0.2s ease-in-out;\n  transition: all 0.2s ease-in-out;\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["マクロにおいて、再帰コードを利用できるようになります。信頼性とパフォーマンスの問題を防ぐため、マクロのネスト可能な階層は最大20になっています。この上限を超えると、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "max recursion limit of 20 reached for macro <your macro name>"
        }), "というエラーが返されます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "呼び出し"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "場合によっては、動的な追加情報をマクロブロックに渡す必要があるかもしれません。例えば、引数のほかに、長いコードをマクロに挿入する場合です。これを行うには、callブロックとcaller()を使用します。callブロックは基本的にマクロと同じように機能しますが、callブロックに名前は指定しません。caller()式で、callブロックの内容をどこに表示するかを指定します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下の例では、2つの引数に加えて、1つの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<p>"
      }), "がマクロに追加されています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% macro render_dialog(title, class) %}\n  <div class=\"{{ class }}\">\n      <h2>{{ title }}</h2>\n      <div class=\"contents\">\n          {{ caller() }}\n      </div>\n  </div>\n {% endmacro %}\n\n {% call render_dialog(\"Hello World\", \"greeting\") %}\n     <p>This is a paragraph tag that I want to render in my.</p>\n {% endcall %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"greeting\">\n  <h2>Hello World</h2>\n  <div class=\"contents\">\n    <p>This is a simple dialog rendered by using a macro and a call block.</p>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "インポート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["マクロには、あるテンプレートファイルから別のテンプレートファイルにインポートして使用できる便利な機能もあります。これには、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "import"
      }), "タグを使用する必要があります。importタグでは、マクロが含まれているテンプレートへのDesign Manager（デザインマネージャー）ファイルパスを指定し、インポート先テンプレート内でのマクロ名を指定することができます。その後に、それらのマクロに値を渡すことができます。マクロを再定義する必要はありません。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、次の2つのマクロが含まれている.htmlテンプレートファイルがあるとします。1つのマクロはheaderタグを設定するために定義し、もう1つはfooterタグを生成するために定義しています。このファイルはデザインマネージャーに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), "という名前で保存されています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!-- my_macros.html file -->\n{% macro header(tag, title_text) %}\n    <header> <{{ tag }}>{{ title_text }} </{{tag}}> </header>\n{% endmacro %}\n\n{% macro footer(tag, footer_text) %}\n     <footer> <{{ tag }}>{{ footer_text }} </{{tag}}> </footer>\n{% endmacro %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらのマクロを使用するテンプレートでは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), "ファイルへのファイルパスを指定したimportタグを使用します。また、マクロのグループには名前を付けます（この例では", (0, _jsxRuntime.jsx)(_components.code, {
        children: "header_footer"
      }), "）。そして、そのインポートしたテンプレートに付けた名前にマクロ名を追加することでマクロを実行できます。以下の例を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% import \"custom/page/web_page_basic/my_macros.html\" as header_footer %}\n{{ header_footer.header(\"h1\", \"My page title\") }}\n<p>Some content</p>\n{{ header_footer.footer(\"h3:\", \"Company footer info\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<header><h1>My page title</h1></header>\n<p>Some content</p>\n<footer><h3>Company footer info</h3></footer>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "from"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["別の.htmlファイルに含まれている全てのマクロではなく、特定のマクロのみをインポートする場合は、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "from"
      }), "タグを使用します。fromタグでは、インポートするマクロのみを指定します。通常、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "import"
      }), "を使用する方が柔軟性は向上しますが、この代替手段もサポートされます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["次の例では、この記事の前のセクションと同じ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_macros.html"
      }), "ファイルを利用します。ただし、今回は、全てのマクロをインポートするのではなく、footerマクロのみを利用します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL + HTML",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% from \"custom/page/web_page_basic/my_macros.html\" import footer %}\n{{ footer(\"h2\", \"My footer info\") }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<footer><h2>My footer info</h2></footer>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ループ内の変数"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "ループ内で定義した変数は、そのループの範囲内に制限され、ループの外側から呼び出すことはできません。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ループ外で定義した変数をループ内から呼び出すことはできますが、その逆はできません。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["また、dictに値を設定する場合やリスト操作を実行する場合にオブジェクトを変化させるために、関数を使用できます。以下の例では", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/reference/cms/hubl/functions#update",
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: ".update"
        }), "リスト操作"]
      }), "を使用しています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set obj = {val : 0} %}\n{% for i in range(0, 10) %}\n   {% do obj.update({val: obj.val + i }) %}\n{% endfor %}\n{{ obj.val }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "45\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}