"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125514;
const slug = exports.slug = 'reference/cms/hubl/if-statements';
const title = exports.title = 'Instruções If';
const name = exports.name = 'LATAM BR (PT) [new] if statements';
const metaDescription = exports.metaDescription = 'Um guia prático para as instruções if do HubL da HubSpot para programadores de CMS.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "sintaxe-b%C3%A1sica-da-instru%C3%A7%C3%A3o-if",
  "label": "Sintaxe básica da instrução if",
  "parent": null
}, {
  "depth": 0,
  "id": "usar-elif-e-else",
  "label": "Usar elif e else",
  "parent": null
}, {
  "depth": 0,
  "id": "instru%C3%A7%C3%B5es-unless",
  "label": "Instruções Unless",
  "parent": null
}, {
  "depth": 0,
  "id": "ifchanged",
  "label": "ifchanged",
  "parent": null
}, {
  "depth": 0,
  "id": "instru%C3%A7%C3%B5es-if-em-linha",
  "label": "Instruções if em linha",
  "parent": null
}, {
  "depth": 0,
  "id": "operadores-tern%C3%A1rios",
  "label": "Operadores ternários",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      code: "code",
      h2: "h2",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Instruções If"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode incluir lógica condicional nos seus módulos e modelos utilizando instruções ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#basic-if-statement-syntax",
        children: "if"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#unless-statements",
        children: "unless"
      }), " do HubL. As instruções If geralmente contêm ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "operadores compatíveis"
      }), " com HubL e podem ser utilizadas para executar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#expression-tests",
        children: "testes de expressão"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observe:"
        }), " se estiver utilizando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#personalization-token",
          children: "tokens de personalização"
        }), " dentro de uma instrução condicional do seu módulo de e-mail, você terá que ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
          children: "ativar o e-mail programável para o módulo"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["As informações transmitidas por meio das APIs ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/marketing/emails/transactional-emails#single-send-api",
          children: "v3"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/marketing/emails/single-send-api",
          children: "v4"
        }), " de envio único não funcionarão nas instruções ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "if"
        }), ", uma vez que os modelos são compilados antes de as informações serem preenchidas."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Sintaxe básica da instrução if"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubL utiliza instruções if para ajudar a definir a lógica de um modelo. A sintaxe das instruções if do HubL é muito semelhante à lógica condicional em Python. As instruções ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " são envolvidas por ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables-macros-syntax",
        children: "delimitadores"
      }), ", começando com uma instrução ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " de abertura e terminando com uma instrução ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "endif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O exemplo abaixo fornece a sintaxe básica de uma instrução if, em que a \"condição\" seria substituída pela regra booleana que você avaliaria como verdadeira ou falsa."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if condition %}\n\tIf the condition is true print this to template.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora que você já viu a sintaxe básica, vamos analisar alguns exemplos reais de instruções if básicas. Os exemplos abaixo mostram instruções if que verificam se um módulo HubL com o nome ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_module"
      }), " e se uma variável com o nome ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "my_module"
      }), " estão presentes em um modelo. Observe que, sem operadores, a instrução if avalia se o módulo está ou não definido no contexto do modelo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"my_module\" path=\"@hubspot/rich_text\", label=\"My rich text module\", html=\"Default module text\" export_to_template_context=true %}\n\n{% if widget_data.my_module %}\n\tA module named \"my_module\" is defined in this template.\n{% endif %}\n\n\n{% set my_variable = \"A string value for my variable\" %}\n{% if my_variable %}\n\tThe variable named my_variable is defined in this template.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Repare que, ao avaliar o módulo HubL, o nome do módulo é deixado entre aspas dentro da instrução ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " e, ao testar a variável, não são utilizadas aspas no nome da variável. Em ambos os exemplos acima, o módulo e a variável existem no modelo; portanto, as instruções são avaliadas para imprimir a marcação. Observe que estes exemplos estão apenas testando se o módulo e a variável estão definidos, e não se eles contêm um valor."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vejamos agora uma instrução ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " que avalia se um módulo tem um valor, em vez de avaliar se ele existe no modelo. Para fazer isso, é necessário utilizar o parâmetro ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context",
        children: "export_to_template_context"
      }), ". No exemplo abaixo, se o módulo de rich text contivesse um valor no editor de conteúdo, a marcação seria impressa. Se o campo de texto do módulo de rich text fosse desativado, nenhuma marcação seria exibida. Se estiver trabalhando com módulos personalizados, existe uma sintaxe simplificada, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget.widget_name"
      }), ", descrita ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "neste exemplo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% module \"product_names\" path=\"@hubspot/text\", label=\"Enter the product names that you would like to render the coupon ad for\", value=\"all of our products\", export_to_template_context=True %}\n\n{% if widget_data.product_names.value %}\n<div class=\"coupon-ad\">\n<h3>For a limited time, get 50% off {{ widget_data.product_names.value}}! </h3>\n</div>\n{% endif %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"coupon-ad\">\n  <h3>For a limited time get 50% off all of our products!</h3>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar elif e else"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As instruções ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " podem ser sofisticadas com instruções condicionais adicionais ou com uma regra que é executada quando a condição ou condições são falsas. A instrução ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), " permite adicionar condições à sua lógica que serão avaliadas depois da condição anterior. A instrução ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "else"
        })
      }), " define uma regra que é executada quando todas as outras condições são falsas. Você pode ter um número ilimitado de instruções ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), " dentro de uma instrução if, mas apenas uma instrução ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), " ."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja abaixo um exemplo de sintaxe básica da instrução if que usa o operador ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#comparison",
        children: "<="
      }), " para verificar o valor de uma variável. Neste exemplo, o modelo imprimiria: \"A variável chamada number é menor ou igual a 6.\""]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set number = 5 %}\n\n{% if number <= 2 %}\n\tVariable named number is less than or equal to 2.\n{% elif number <= 4 %}\n\tVariable named number is less than or equal to 4.\n{% elif number <= 6 %}\n\tVariable named number is less than or equal to 6.\n{% else %}\n\tVariable named number is greater than 6.\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja abaixo mais um exemplo que utiliza um módulo de escolha para mostrar diferentes cabeçalhos em uma página de carreiras, com base no departamento escolhido pelo usuário. O exemplo utiliza o operador ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#comparison",
        children: "=="
      }), ", para verificar a existência de certos valores predefinidos no módulo de escolha."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% choice \"department\" label=\"Choose department\", value=\"Marketing\", choices=\"Marketing, Sales, Dev, Services\" export_to_template_context=True %}\n\n{% if widget_data.department.value == \"Marketing\" %}\n\n<h3>Want to join our amazing Marketing team?!</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Sales\" %}\n\n<h3>Are you a Sales superstar?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% elif widget_data.department.value == \"Dev\" %}\n\n<h3>Do you love to ship code?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% else %}\n\n<h3>Want to work with our awesome customers?</h3>\n<h4>We have exciting career opportunities on the {{ widget_data.department.value }} team.</h4>\n\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Instruções Unless"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As instruções ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), " são condicionais, assim como as instruções ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), ", mas funcionam com a lógica inversa. Elas processam e compilam o código entre as tags de abertura e fechamento, a menos que a condição booleana única seja avaliada como verdadeira. As instruções unless começam com**", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), "** e terminam com ", (0, _jsxRuntime.jsxs)(_components.strong, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "endunless"
        }), ". As instruções"]
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "unless"
      }), " dão suporte a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "else"
      }), " mas não a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "elif"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja abaixo um exemplo que imprime um cabeçalho \"Em construção\", a menos que o campo de rich text tenha um valor. Se o campo de rich text tiver conteúdo, esse conteúdo será mostrado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"my_page_content\" path=\"@hubspot/rich_text\", label=\"Enter your page content\", html=\"\" export_to_template_context=true %}\n\n{{ widget_data.my_page_content.html }}\n\n{% unless widget_data.my_page_content.html %}\n<h1>This page is under construction.</h1>\n<h3>Come back soon!</h3>\n{% endunless %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ifchanged"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Além das instruções if e unless, o HubL dá suporte a instruções ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ifchanged"
      }), " . Estas instruções podem ser utilizadas para mostrar apenas a marcação quando uma variável tiver sido alterada desde uma invocação anterior desta tag."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Instruções if em linha"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubL dá suporte a instruções ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), " em linha. Estas podem ser utilizadas para escrever lógica condicional de forma concisa com ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests",
        children: "operadores e testes de expressão"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set color = \"Blue\" if is_blue is truthy else \"Red\" %}     // color == \"blue\"\n\n{{ \"Blue\" if is_blue is truthy else \"Red\" }}     // \"Blue\"\n\n{% set dl = true %}\n<a href=\"http://example.com/some.pdf\" {{\"download\" if dl }} >Download PDF</a>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Operadores ternários"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Também é possível utilizar operadores ternários para escrever rapidamente lógica condicional com ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/operators-and-expression-tests#logical",
        children: "operadores e testes de expressão"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "// If the variable is_blue is true, output \"blue\", otherwise output\"red\"\n{{ is_blue is truthy ? \"blue\" : \"red\" }}\n\n// Set the variable is_red to false if is_blue is true, otherwise set to true\n{% set is_red = is_blue is truthy ? false : true %}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}