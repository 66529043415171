"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 75327506237;
const slug = exports.slug = 'reference/api/other-resources/error-handling';
const title = exports.title = 'Error handling';
const name = exports.name = 'Error handling';
const metaDescription = exports.metaDescription = "Learn how to handle common API errors when developing with HubSpot's APIs.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "multi-status-errors",
  "label": "Multi-status errors",
  "parent": null
}, {
  "depth": 0,
  "id": "retries",
  "label": "Retries",
  "parent": null
}, {
  "depth": 1,
  "id": "webhooks",
  "label": "Webhooks",
  "parent": "retries"
}, {
  "depth": 1,
  "id": "custom-code-workflow-actions",
  "label": "Custom code workflow actions",
  "parent": "retries"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      pre: "pre",
      h2: "h2",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Handling errors"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Unless specified otherwise, most HubSpot endpoints will return a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), " OK response upon success. Any endpoints returning a different status code will specify the returned response in its documentation."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition, HubSpot has several error responses that are common to multiple APIs:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "207 Multi-Status"
        }), ": returned when there are different statuses (e.g., errors and successes), which occurs when you've enabled ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#multi-status-errors",
          children: "multi-status error handling"
        }), " for the object API batch create endpoints."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "401 Unauthorized"
        }), ": returned when the authentication provided is invalid. See our ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/oauth-tokens",
          children: "Authentication Overview"
        }), " for details on authenticating API requests."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "403 Forbidden"
        }), ": returned when the authentication provided does not have the proper permissions to access the specific URL. As an example, an OAuth token that only has content access would get a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " when accessing the Deals API (which requires contacts access). If you've confirmed that your API key or private app has the necessary permissions, please reach out to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "HubSpot support"
        }), " for assistance."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "423 Locked"
        }), ": returned when attempting to sync a large volume of data (e.g., upserting thousands of company records in a very short period of time). Locks will last for 2 seconds, so if you receive a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "423"
        }), " error, you should include a delay of at least 2 seconds between your API requests."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "429 Too many requests"
        }), ": returned when your account or app is over its API ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details",
          children: "rate limits"
        }), ". Find suggestions on working within those limits ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/docs/faq/working-within-the-hubspot-api-rate-limits",
          children: "here"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "477 Migration in Progress"
        }), ": returned when a HubSpot account is currently being ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/eu-data-centre",
          children: "migrated between data hosting locations"
        }), ". HubSpot will return a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Retry-After",
          children: "Retry-After"
        }), " response header indicating how many seconds to wait before retrying the request (typically up to 24 hours)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "502/504 timeouts"
        }), ": returned when HubSpot's processing limits have been met. These limits are in place to prevent a single client from causing degraded performance. These timeout responses occur when making a large number of requests over a sustained period. If you get one of these responses, you should pause your requests for a few seconds, then retry."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "503 service temporarily unavailable"
        }), ": returned when HubSpot is temporarily unavailable. If you receive this response, you should pause your requests for a few seconds, then retry."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "521 web server is down"
        }), ": returned when HubSpot's server is down, this should be a temporary issue. If you receive this response, you should pause your requests for a few seconds, then retry."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "522 connnection timed out"
        }), ": returned when the connection between HubSpot and your application has timed out. If you've received this response, please reach out to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "HubSpot support"
        }), " for assistance."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "523 origin is unreachable"
        }), ": returned when HubSpot is unable to contact your application. If you receive this response, you should pause your requests for a few seconds, then retry."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "524 timeout"
        }), ": returned when a response is not received within 100 seconds. This can occur when the HubSpot's server is overloaded, such as with a large data query. If you receive this response, you should pause your requests for a few seconds, then retry."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "525/526 SSL issues"
        }), ": returned when the SSL certificate is invalid or the SSL handshake fails. If you've received this response, please reach out to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/get-help-with-hubspot",
          children: "HubSpot support"
        }), " for assistance."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Aside from these general errors, HubSpot error responses are intended to be human-readable. Most endpoints don't return error codes, but return a JSON formatted response with details about the error. More details for endpoint-specific errors can be found on the documentation pages for the endpoint."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the fields in the example response below should all be treated as optional in any error parsing. The specific fields included can vary between different APIs, so any error parsing should allow for specific fields to be missing from the response."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Structure of an example error from HubSpot\n{\n  \"status\": \"error\",\n  \"message\": \"This will be a human readable message with details about the error.\",\n  \"errors\": [\n    {\n      \"message\": \"This will be a message with additional details about the error\",\n      \"in\": \"name\"\n    }\n  ],\n  \"category\": \"VALIDATION_ERROR\",\n  \"correlationId\": \"a43683b0-5717-4ceb-80b4-104d02915d8c\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Multi-status errors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "object APIs'"
      }), " batch create endpoints, you can enable multi status responses that include partial failures. This means the response will show which records were created and which were not. To do so, include a unique ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectWriteTraceId"
      }), " value for each input in your request. The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectWriteTraceId"
      }), " can be any unique string."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, a request to create tickets could look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example request to POST crm/v3/objects/tickets/batch/create\n{\n  \"inputs\": [\n    {\n      \"objectWriteTraceId\": \"549b1c2a9350\",\n      \"properties\": {\n        \"hs_pipeline_stage\": \"1\"\n      },\n      \"objectWriteTraceId\": \"549b1c2a9351\",\n      \"properties\": {\n        \"missing\": \"1\"\n      }\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the response, statuses are grouped so you can see which creates were successful and which failed. For the above request, your response would look like:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "///Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"id\": \"1145814089\",\n      \"properties\": {\n        \"createdate\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_helpdesk_sort_timestamp\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_last_message_from_visitor\": \"false\",\n        \"hs_lastmodifieddate\": \"2024-08-15T17:09:13.648Z\",\n        \"hs_object_id\": \"1145814089\",\n        \"hs_object_source\": \"API\",\n        \"hs_object_source_label\": \"INTERNAL_PROCESSING\",\n        \"hs_pipeline\": \"0\",\n        \"hs_pipeline_stage\": \"1\",\n        \"hs_ticket_id\": \"1145814089\"\n      },\n      \"createdAt\": \"2024-08-15T17:09:13.648Z\",\n      \"updatedAt\": \"2024-08-15T17:09:13.648Z\",\n      \"archived\": false\n    }\n  ],\n  \"numErrors\": 1,\n  \"errors\": [\n    {\n      \"status\": \"error\",\n      \"category\": \"VALIDATION_ERROR\",\n      \"message\": \"Property values were not valid: [{\\\"isValid\\\":false,\\\"message\\\":\\\"Property \\\\\\\"missing\\\\\\\" does not exist\\\",\\\"error\\\":\\\"PROPERTY_DOESNT_EXIST\\\",\\\"name\\\":\\\"missing\\\",\\\"localizedErrorMessage\\\":\\\"Property \\\\\\\"missing\\\\\\\" does not exist\\\",\\\"portalId\\\":891936587}]\",\n      \"context\": {\n        \"objectWriteTraceId\": [\"549b1c2a9351\"]\n      }\n    }\n  ],\n  \"startedAt\": \"2024-08-15T17:09:13.610Z\",\n  \"completedAt\": \"2024-08-15T17:09:13.910Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retries"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If your app or integration provides an endpoint that HubSpot will call, such as webhook subscriptions, any errors that your endpoint throws will cause HubSpot to retry the request."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Webhooks"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If your service has problems handling notifications at any time, HubSpot will attempt to resend failed notifications up to 10 times."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot will retry in the following cases:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Connection failed:"
        }), " HubSpot cannot open an HTTP connection to the provided webhook URL."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Timeout:"
        }), " Your service takes longer than 5 seconds to send back a response to a batch of notifications"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Error codes:"
        }), " Your service responds with any HTTP status code (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "4xx"
        }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "5xx"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Workflows ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "will not"
      }), " retry after receiving 4XX series response status codes. One exception to this rule is 429 rate limit errors; workflows will automatically retry after receiving a 429 response, and will respect the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Retry-After"
      }), " header if present. Note that the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Retry-After"
      }), " value is in miliseconds."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Notifications will be retried up to 10 times. These retries will be spread out over the next 24 hours, with varying delays between requests. Individual notifications will have some randomization applied, to prevent a large number of concurrent failures from being retried at the exact same time."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Custom code workflow actions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you're creating a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/automation/custom-code-actions",
        children: "custom code action"
      }), " in a workflow, and an API call in your action fails due to a rate limiting error, or a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "429"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "5XX"
      }), " error from ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "axios"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/api-client"
      }), ", HubSpot will reattempt to execute your action for up to three days, starting one minute after failure. Subsequent failures will be retried at increasing intervals, with a maximum gap of eight hours between tries."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}