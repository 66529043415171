"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359125513;
const slug = exports.slug = 'guides/cms/improve-older-sites/add-theme-features-to-existing-sites';
const title = exports.title = 'Comment ajouter des fonctionnalités de thème à un site web CMS Hub existant';
const name = exports.name = 'EMEA (FR) Add theme capabilities to an existing HubSpot CMS website';
const metaDescription = exports.metaDescription = "Les fonctionnalités de thème peuvent être ajoutées de manière additive aux anciens sites. Pensez à l'avenir du site web et à la manière dont ces fonctionnalités peuvent l'améliorer.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "th%C3%A8me",
  "label": "Thème",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-placer-les-fichiers-existants-dans-un-dossier",
  "label": "1. Placer les fichiers existants dans un dossier",
  "parent": "th%C3%A8me"
}, {
  "depth": 1,
  "id": "2.-faire-du-dossier-un-th%C3%A8me",
  "label": "2. Faire du dossier un thème",
  "parent": "th%C3%A8me"
}, {
  "depth": 1,
  "id": "3.-ajouter-des-champs-de-th%C3%A8me",
  "label": "3. Ajouter des champs de thème",
  "parent": "th%C3%A8me"
}, {
  "depth": 0,
  "id": "cloner-le-gestionnaire-de-conception-par-glisser-d%C3%A9poser-en-html",
  "label": "Cloner le gestionnaire de conception par glisser-déposer en HTML",
  "parent": null
}, {
  "depth": 0,
  "id": "zones-de-glisser-d%C3%A9poser",
  "label": "Zones de glisser-déposer",
  "parent": null
}, {
  "depth": 2,
  "id": "est-il-utile-de-convertir-toutes-les-pages-existantes-pour-utiliser-les-zones-de-glisser-d%C3%A9poser-%3F",
  "label": "Est-il utile de convertir toutes les pages existantes pour utiliser les zones de glisser-déposer ?",
  "parent": "zones-de-glisser-d%C3%A9poser"
}, {
  "depth": 1,
  "id": "conversion-de-mod%C3%A8les-existants",
  "label": "Conversion de modèles existants",
  "parent": "zones-de-glisser-d%C3%A9poser"
}, {
  "depth": 1,
  "id": "comment-migrer-les-pages-existantes-%3F",
  "label": "Comment migrer les pages existantes ?",
  "parent": "zones-de-glisser-d%C3%A9poser"
}, {
  "depth": 0,
  "id": "partials-globaux",
  "label": "Partials globaux",
  "parent": null
}, {
  "depth": 0,
  "id": "alertes-de-code",
  "label": "Alertes de code",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      h3: "h3",
      strong: "strong",
      ol: "ol",
      code: "code",
      img: "img",
      h4: "h4",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Comment ajouter des fonctionnalités de thème à un site web CMS Hub existant"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "L'un des avantages de HubSpot est que vous n'avez pas besoin de le mettre à jour en permanence. CMS Hub utilise toujours la dernière version. Les nouvelles fonctionnalités de CMS Hub sont additives. Les modèles, modules, etc. des sites web CMS Hub existants fonctionnent donc de la même manière que vous les avez créés. Ils sont même meilleurs, car en arrière-plan, le CMS est toujours plus performant, plus rapide et plus facile à modifier."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il y a certaines caractéristiques spécifiques de CMS Hub qu'un développeur doit ajouter. Vous pouvez mettre en œuvre certaines de ces fonctionnalités différemment de ce que vous feriez pour un tout nouveau site web. Ce tutoriel vous guidera dans l'ajout de ces fonctionnalités à votre ou vos sites web existants."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Avant de continuer :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Consultez les ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "concepts clés de CMS Hub"
        }), ", même si vous utilisez HubSpot depuis longtemps. Vous comprendrez mieux comment tout cela fonctionne."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous devrez également utiliser l'", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli",
          children: "ILC CMS"
        }), ". Si vous ne l'avez pas encore installée, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "faites-le"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez ajouter et utiliser ces fonctionnalités de manière indépendante. Commencez par le début et identifiez des caractéristiques spécifiques dont vous avez besoin. Vous ne devez pas suivre ce tutoriel de manière complètement linéaire."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Thème"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "thèmes"
      }), " de CMS Hub sont un ensemble de modèles, de modules ainsi que de fichiers CSS, JSON et JS. L'inclusion des thèmes dans un ensemble a son importance tout au long de l'expérience de l'éditeur de contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors de la création d'un nouveau site web ou d'une page de destination, le créateur de contenu se voit présenter une grille des thèmes de son compte. Il sélectionne le thème qu'il utilise, puis une grille contenant uniquement les modèles de ce thème s'affiche."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors de la modification d'une page utilisant un modèle d'un thème, les modules de ce thème reçoivent un traitement spécial qui les fait ressortir dans le panneau d'ajout de module."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Placer les fichiers existants dans un dossier"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vos modèles, modules, fichiers CSS et JS constituent un thème s'ils se trouvent dans le même dossier. Peu importe qu'ils soient organisés en sous-dossiers, du moment que ces éléments sont inclus dans un même dossier. S'ils sont déjà stockés de cette façon, c'est parfait. Sinon, créez ce dossier et déplacez-y vos éléments. Renommez ce dossier avec le nom que vous souhaitez donner à votre thème. Le nom dépend entièrement de vous, mais vous pouvez peut-être le nommer en fonction de la marque de l'entreprise que le site reflète et de l'année de la refonte ou de la mise à jour."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Faire du dossier un thème"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les thèmes nécessitent deux fichiers JSON, et seul l'un d'entre eux a besoin d'un contenu réel au départ."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Theme.json"
        }), " – Il contient les paramètres de votre thème."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Fields.json"
        }), " – Il contient des champs qui peuvent être utilisés pour apporter des modifications de conception à l'ensemble d'un site."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les fichiers JSON sont spéciaux et ne peuvent actuellement pas être créés dans le gestionnaire de conception. Ces fichiers PEUVENT être téléchargés via l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "ILC CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Récupérez le dossier que vous avez créé à l'étape Placer les fichiers existants dans un dossier sur votre ordinateur."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["À l'intérieur du dossier sur votre ordinateur, créez un nouveau fichier nommé ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Copiez le fichier ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/guides/cms/content/themes/overview#theme-json",
          children: ["d'exemple ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "theme.json"
          }), " sur le document de thèmes"]
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Collez le code de l'exemple dans votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Modifiez la valeur de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"label\""
        }), " pour qu'elle corresponde au nom du thème, tel que vous souhaitez que les créateurs de contenu le voient."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Remplacez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"preview_path\""
        }), " par le chemin de votre modèle de page d'accueil ou de votre modèle le plus fréquemment utilisé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Faites une capture d'écran de la page d'accueil du site. Nommez l'image ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "thumb.jpg"
        }), ". Placez l'image dans le même dossier que votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Remplacez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"screenshot_path\""
        }), " dans theme.json par le chemin de votre image ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "thumb.png"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Enregistrez le fichier."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Dans le même dossier que votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "theme.json"
        }), ", créez un nouveau fichier et nommez-le fields.json. À l'intérieur de ce fichier, saisissez simplement ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[]"
        }), " et enregistrez le fichier."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour voir vos modifications dans le gestionnaire de conception, exécutez la commande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload"
      }), ". Vous avez maintenant un thème de base. Les modules et les modèles de ce thème s'afficheront comme associés au thème."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Ajouter des champs de thème"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les champs de thème sont des contrôles que vous pouvez fournir à un créateur de contenu pour lui permettre d'apporter des modifications de style à l'échelle du thème."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lors de la création initiale d'un site web, ces contrôles de thème permettent aux créateurs de contenu de participer à la définition de l'image de marque du site. Les équipes peuvent constater que cela permet au développeur de se concentrer sur les aspects plus techniques du développement du site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour les sites web existants, les champs de thème peuvent s'avérer inutiles. Si le site web a déjà été créé sur mesure, il n'y a probablement aucun intérêt à ajouter des contrôles de couleur et de typographie à l'échelle du site. Si l'image de marque d'un site change de manière significative, il peut être plus approprié de procéder à une refonte que d'ajouter des champs rétroactivement. En fin de compte, c'est une décision que vous devez prendre d'un commun accord avec les autres parties prenantes du site."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ajouter des champs au thème, ajoutez leur JSON à votre fichier fields.json. Les champs suivent la même structure que les champs de module."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Assurez-vous que les utilisateurs qui doivent avoir accès à la modification des valeurs des champs de thème disposent de l'autorisation Modifier le contenu global et les thèmes. Pour les utilisateurs qui ne doivent pas être en mesure de modifier ces paramètres, assurez-vous que cette option est désactivée."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/327485/global%20content%20theme%20permissions.png",
        alt: "Capture d'écran du paramètre de contenu global et de thèmes dans les autorisations de l'utilisateur"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Cloner le gestionnaire de conception par glisser-déposer en HTML"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "zones de glisser-déposer"
      }), " et les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "partials globaux"
      }), " nécessitent l'utilisation de fichiers ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "codés HTML + HubL"
      }), ". Si votre site web existant a été créé à l'aide de l'ancien système de gestion de conception par glisser-déposer et que vous souhaitez utiliser ces nouvelles fonctionnalités, ces modèles devront peut-être être clonés en HTML."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour cloner ces modèles en tant que modèles HTML + HubL :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ouvrez le gestionnaire de conception et trouvez le modèle dans l'outil de recherche."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Effectuez un clic droit sur le modèle."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Dans le menu contextuel qui apparaît, choisissez Cloner en tant que HTML."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous êtes maintenant prêt à travailler sur l'ajout des zones de glisser-déposer et de partials globaux."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Zones de glisser-déposer"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "zones de glisser-déposer"
      }), " peuvent être ajoutées aux modèles pour offrir aux créateurs de contenu un moyen de placer les modules à la fois horizontalement et verticalement sur une page. Les zones de glisser-déposer offrent également un contrôle supplémentaire du style pour le créateur de contenu. Comme un modèle avec une zone de glisser-déposer peut être utilisé pour créer de nombreuses mises en page différentes, cela permet au développeur de se concentrer davantage sur les aspects techniques de la création et de la mise à jour du site."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Les zones de glisser-déposer sont une nouvelle fonctionnalité et ne sont pas similaires aux ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/drag-and-drop-templates",
          children: "modèles de glisser-déposer du gestionnaire de conception"
        }), ". Elles ne sont pas non plus prises en charge dans les modèles de type glisser-déposer du gestionnaire de conception. Pour les ajouter à des modèles précédemment créés à l'aide du générateur de modèles en glisser-déposer, consultez Cloner le gestionnaire de conception par glisser-déposer en HTML."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Est-il utile de convertir toutes les pages existantes pour utiliser les zones de glisser-déposer ?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cela dépend entièrement des objectifs commerciaux de votre page. En d'autres termes, le vieil adage « On ne change pas une équipe qui gagne » s'applique. Si la mise en page doit être modifiée, il est probablement judicieux de convertir la page pour utiliser les zones de glisser-déposer. Si la page remplit sa fonction et ne nécessite pas de modification de mise en page, elle est probablement très bien ainsi."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conversion de modèles existants"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il est relativement facile de créer un clone d'un ancien modèle et de transformer la colonne flexible en une zone de glisser-déposer. C'est une bonne idée, car cela ouvre de nombreuses possibilités pour les créateurs de contenu. Cela leur offre une grande liberté de création pour les nouvelles pages. Si votre modèle est un modèle de gestionnaire de conception en glisser-déposer, consultez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#clone-design-manager-drag-and-drop-as-html",
        children: "Cloner le gestionnaire de conception par glisser-déposer en HTML"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["La solution la plus simple est de trouver les instances de colonnes flexibles, qui utilisent la balise HubL ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{% widget_container \"my_unique_column_name\" %}"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si nécessaire, remplacez chaque instance par ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "{% dnd_area \"my_unique_area_name\" %}"
          })
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous ne souhaitez pas définir les modules par défaut, laissez le champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), " vide. Si vous souhaitez reprendre les modules par défaut pour la région ou définir de nouveaux modules par défaut utiles, dans ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), ", ajoutez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), " et à l'intérieur de cette section, ajoutez ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour chaque balise de module dans l'ancien élément ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_container"
        }), ", vous souhaiterez créer un élément ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), " contenant ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), " avec un chemin correspondant aux modules que vous souhaitez afficher par défaut. Cela créera la même disposition verticale que la colonne flexible."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Comment migrer les pages existantes ?"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si vous changez des modèles construits avec des colonnes flexibles pour utiliser des zones de glisser-déposer, gardez à l'esprit certains éléments. ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Les colonnes flexibles ne sont pas les mêmes que les zones de glisser-déposer. Vous ne pouvez pas passer d'un modèle qui ne comporte qu'une colonne flexible à un modèle qui ne comporte qu'une zone de glisser-déposer"
        }), ". Par mesure de sécurité, cette option n'est pas autorisée. Le contenu ne peut pas être transféré de la colonne flexible à la zone de glisser-déposer. Pour illustrer ce phénomène, supposons que vous ayez construit votre nouveau modèle de manière à avoir une barre latérale et une zone de contenu principal. Votre barre latérale est une colonne flexible, votre contenu principal est une zone de glisser-déposer. L'outil de permutation fait correspondre la colonne flexible à la colonne flexible."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous souhaitez convertir une page pour l'utilisation des zones de glisser-déposer, la manière la plus sûre de le faire est d'utiliser la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/content-staging",
        children: "préparation de contenu"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/content/staging/domain",
          children: "Ouvrez l'outil de préparation de contenu"
        }), ", trouvez la page existante et préparez-la. Choisissez Préparer une page vierge."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sélectionnez votre nouveau modèle qui utilise la zone de glisser-déposer."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Copiez et collez les informations de la version originale de la page, en créant la mise en page que vous souhaitez."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Quand vous avez terminé, publiez sur l'environnement de testing."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Dans l'outil de préparation de contenu, accédez à l'onglet Publier. Sélectionnez votre page et publiez-la sur l'environnement de testing."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Partials globaux"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "Partials globaux"
      }), " sont des morceaux de contenu HTML et HubL qui peuvent être réutilisés sur l'ensemble de votre site web. Les types de partials globaux les plus courants sont les en-têtes de sites web, les barres latérales et les pieds de page. Pour les créateurs de contenu, les partials globaux se manifestent dans l'éditeur de contenu comme des régions qu'ils peuvent sélectionner pour les modifier dans l'éditeur de contenu global. L'éditeur de contenu global ressemble à l'éditeur de page, mais il est utilisé pour modifier le contenu global."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "À titre d'exemple, vous pouvez implémenter l'en-tête sous la forme d'un partial global avec des modules pour la navigation et les autres contenus. Si le créateur de contenu a besoin d'ajouter un lien à la navigation, il peut cliquer sur l'en-tête dans l'éditeur de page, puis sur le module de menu et mettre à jour le menu."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Assurez-vous que les utilisateurs qui ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "doivent"
      }), " avoir accès à la modification du contenu global disposent de l'autorisation Modifier le contenu global et les thèmes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/327485/global%20content%20theme%20permissions.png",
        alt: "paramètre de contenu global et de thème dans les autorisations de l'utilisateur"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alertes de code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les comptes CMS Hub Entreprise, les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/code-alerts",
        children: "alertes de code"
      }), " offrent un espace centralisé pour que les développeurs et les responsables informatiques puissent consulter un aperçu des problèmes qui affectent la performance et le rendu des pages ainsi que des éléments sur le compte."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous optimisez activement le site pour tirer parti des nouvelles fonctionnalités. Il est donc judicieux d'examiner les alertes de code pour votre compte et de les résoudre, le cas échéant."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}