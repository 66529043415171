"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32783059956;
const slug = exports.slug = 'guides/api/crm/commerce/quotes';
const title = exports.title = 'CRM API | 見積もり';
const name = exports.name = 'APAC JAPAN (JA) | Dev. Page | vNext Docs DP - Quotes | 202209';
const metaDescription = exports.metaDescription = '見積もりツールはプロスペクトに価格情報を提供するために使用します。見積もりエンドポイントを使用することにより、見積もりデータを取得したり、HubSpotと他のシステムとの間で同期したりできます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E6%A6%82%E8%A6%81",
  "label": "概要",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%81%AE%E4%BD%9C%E6%88%90",
  "label": "見積もりの作成",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%9B%BB%E5%AD%90%E7%BD%B2%E5%90%8D%E3%82%92%E6%9C%89%E5%8A%B9%E3%81%AB%E3%81%99%E3%82%8B",
  "label": "電子署名を有効にする",
  "parent": "%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%81%AE%E4%BD%9C%E6%88%90"
}, {
  "depth": 1,
  "id": "%E6%94%AF%E6%89%95%E3%81%84%E3%82%92%E6%9C%89%E5%8A%B9%E3%81%AB%E3%81%99%E3%82%8B",
  "label": "支払いを有効にする",
  "parent": "%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%81%AE%E4%BD%9C%E6%88%90"
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B",
  "label": "関連付けを追加する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%81%AEid%E3%81%AE%E5%8F%96%E5%BE%97",
  "label": "関連付けのIDの取得",
  "parent": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%81%AE%E6%9B%B4%E6%96%B0",
  "label": "関連付けの更新",
  "parent": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E7%BD%B2%E5%90%8D%E8%80%85%E3%82%92%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "見積もり署名者を関連付ける",
  "parent": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%81%AE%E3%81%82%E3%82%8B%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B%EF%BC%881%E3%81%A4%E3%81%AE%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%EF%BC%89",
  "label": "関連付けのある見積もりを作成する（1つのリクエスト）",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%82%B9%E3%83%86%E3%83%BC%E3%82%BF%E3%82%B9%E3%81%AE%E6%9B%B4%E6%96%B0",
  "label": "見積もりステータスの更新",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "見積もりを取得する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%81%AE%E3%82%B9%E3%83%86%E3%83%BC%E3%82%BF%E3%82%B9%E3%81%A7%E8%A8%AD%E5%AE%9A%E3%81%95%E3%82%8C%E3%81%9F%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "見積もりのステータスで設定されたプロパティー",
  "parent": "%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%82%B9%E3%82%B3%E3%83%BC%E3%83%97",
  "label": "スコープ",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      img: "img",
      h2: "h2",
      ol: "ol",
      li: "li",
      code: "code",
      strong: "strong",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert,
      Table,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Table) _missingMdxReference("Table", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "見積もり"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["見積もりAPIを使用して、潜在顧客と価格情報を共有するための見積書を作成、管理、および取得します。設定が完了すると、指定されたURLまたはPDFのいずれかで、購入者と見積もりを共有できます。ユーザーは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/use-quotes#manage-quotes",
        children: "HubSpotで見積もりを管理"
      }), "して、詳細の追加や関連付けの更新などを行うこともできます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/set-up-payments",
        children: "HubSpot決済機能"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/payments/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
        children: "Stripe決済処理"
      }), "のいずれかを設定している場合は、このAPIを介して見積もりを支払い可能に設定できます。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/commerce/quotes#enable-payments",
        children: "支払い可能な見積もり"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/example-quote-api.png",
        alt: "example-quote-api"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "**使用例：**ある顧客が貴社の年間SEO監査サービスパッケージの1つを購入することに関心があり、その顧客のために契約提案書を作成する必要があるとします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下では、APIを使用して見積もりを作成し、さまざまなプロパティー、関連付け、ステータスなどを構成する方法について説明します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "概要"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "見積もり作成プロセスのステップは、次のように分割できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**見積もりの作成：**名前や有効期限など、いくつかの詳細を含む見積もりを作成します。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#enable-e-signatures",
          children: "電子署名"
        }), "と", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#enable-payments",
          children: "支払い"
        }), "を有効にするために見積もりの設定を変更することもできます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**関連付けの設定：**見積もりと他のタイプのCRMオブジェクトに関連付けます。例えば、商品項目、見積もりテンプレート、取引などを関連付けます。次のステップで、見積もりはこれらの関連付けられたレコードの一部とアカウントの設定から、プロパティー値を継承します。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**見積もりステータスの設定：**見積もりのステータスを設定し、購入者と共有できる状態かどうかを示します。見積もりのステータスを、下書き、または一般公開済み、などに設定すると、関連するCRMレコードとアカウント設定から", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#properties-set-by-quote-state",
          children: "特定のプロパティーが継承"
        }), "されます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**見積もりの共有：**見積もりが公開されると、購入者と共有できるようになります。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "見積もりの作成"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["見積もりを作成するには、まず", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quotes"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを送信して、基本的な詳細を設定します。後で、見積もりテンプレート、商品項目、取引などの他のオブジェクトを見積もりに関連付けるために、別の呼び出しを行います。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["ご希望のワークフローに応じて、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-quote-with-associations-single-request-",
          children: "1つのPOSTリクエストで関連付けのある見積もりを作成"
        }), "できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "POSTリクエスト本文には、次の必須プロパティーを含めて、基本的な詳細を設定します。"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100.018%",
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The name of the quote."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100.018%",
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The date that the quote expires."
                  })]
                })
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The above are just the minimum required properties to get a quote started but ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/quotes#adding-associations",
              children: "other properties"
            }), " are required to publish a quote. To see all available quote properties, make a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "GET"
            }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm/v3/properties/quotes"
            }), ". Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/properties",
              children: "properties API"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\"\n  }\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["応答には、見積もりの設定を続行するために使用する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "が含まれます。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quotes/{quoteId}"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを送信することで、引用プロパティーをいつでも更新できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "電子署名を有効にする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["見積もりで電子署名を有効にするには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "の値を持つ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_esign_enabled"
      }), "ブール値プロパティーをリクエスト本文に含めます。APIを使用して副署名者を追加することはできないため、副署名者は見積もりを公開する前にHubSpotに追加する必要があることに注意してください。また、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes#monitor-e-signature-usage",
        children: "毎月の電子署名数の制限を超えた"
      }), "場合は、電子署名を有効にして見積もりを公開することはできません。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\",\n    \"hs_esign_enabled\": \"true\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["後で、見積もりを見積もり署名者に関連付ける必要があります。見積もりに署名したコンタクトはHubSpotにコンタクトとして存在しますが、コンタクトとは別の関連タイプとして保存されます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#associating-quote-signers",
        children: "見積もりを見積もり署名者に関連付ける"
      }), "方法について詳細をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "支払いを有効にする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["見積もりで支払いを有効にするには、リクエスト本文に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_enabled"
      }), "ブール値プロパティーを含めてその値を", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "に設定します。決済代行事業者と受け入れられる支払い方法に応じて、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_type"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_allowed_payment_methods"
      }), "も設定する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**この機能を使用する前に、HubSpotアカウントに", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/set-up-payments",
          children: "HubSpot決済機能"
        }), "または", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/payments/connect-your-stripe-account-as-a-payment-processor-in-hubspot",
          children: "Stripe決済処理"
        }), "のいずれかが設定されている必要があります。"]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_payment_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定すると、見積もりでHubSpot決済機能またはStripe決済処理のいずれかを使用して支払いを回収できるようになります。既定値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "です。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_payment_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["使用する決済代行事業者を決定します。値は", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "BYO_STRIPE"
            }), "のいずれかです。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_allowed_payment_methods"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Enumeration"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "使用する支払い方法（クレジットカードなど）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_collect_billing_address"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定すると、購入者は注文の確認時に請求先住所を入力できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_collect_shipping_address"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定すると、購入者は注文の確認時に発送先住所を入力できます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば見積もりを作成し、クレジットカード/デビットカードまたはACHによるHubSpot決済機能を有効にする場合、リクエストは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// example POST request body\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-12-10\",\n    \"hs_payment_enabled\": \"true\",\n    \"hs_payment_type\": \"HUBSPOT\",\n    \"hs_allowed_payment_methods\": \"CREDIT_OR_DEBIT_CARD;ACH\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["支払いを追跡するために、HubSpotは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_status"
      }), "プロパティーと", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_payment_date"
      }), "プロパティーを自動的に更新します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["支払いを有効にして見積もりを公開すると、HubSpotは自動的に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), "プロパティーをPENDINGに設定します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ACHを使用している場合、支払いが処理されると、HubSpotは自動的に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), "プロパティーをPROCESSINGに設定します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["支払いが確認されると、HubSpotは自動的に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), "プロパティーをPAIDに設定します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["見積もりが支払われると、HubSpotは自動的に", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_date"
        }), "を支払いが確認された日時に設定します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["支払いが確認されると、支払いは見積もりに自動的に関連付けられます。支払いの詳細を取得する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/payments",
          children: "Payments API"
        }), "をご確認ください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連付けを追加する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["完全な見積もりを作成するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "関連付けAPI"
      }), "を使用して、商品項目などの他のCRMレコードと関連付ける必要があります。次の表は、完全な見積もりに必要なCRMレコードの関連付けと、オプションの関連付けを示しています。IDの取得と、IDを使用して必要な関連付けを作成する方法の詳細については、続きをお読みください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "オブジェクトタイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "必須"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/line-items",
              children: "商品項目"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "はい"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["見積もりをとおして販売されている商品および/またはサービス。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "製品ライブラリーの製品"
            }), "から商品項目を作成したり、カスタムスタンドアロン商品項目を作成したりすることができます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/quotes/create-custom-quote-templates",
              children: "見積もりテンプレート"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "はい"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりを表示するテンプレート。見積もりの既定設定（言語など）が含まれます。各見積もりは1つのテンプレートに関連付けることができます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/deals",
              children: "取引"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "はい"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "収益と営業ライフサイクルを追跡するために使用される取引レコード。見積もりは、関連付けられた取引からの値（担当者、通貨など）を継承します。各見積もりは、1つの取引に関連付けることができます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "コンタクト"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "いいえ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりの対象とする特定の顧客。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "会社"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "いいえ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりの対象とする特定の会社。各見積もりは1つの会社に関連付けることができます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/discounts",
              children: "割引"
            }), "、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/fees",
              children: "手数料"
            }), "、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/taxes",
              children: "税金"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "いいえ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["決済時に適用されるあらゆる割引、手数料、税金。合計見積もり金額を決定する際、HubSpotはまず割引を適用し、次に手数料、その次に税金を適用します。同じタイプのオブジェクトを並べ替えるには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sort_order"
            }), "フィールドを使用します。固定値またはパーセンテージで指定できます。そのためには、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_type"
            }), "の値を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "FIXED"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PERCENT"
            }), "に設定します。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付けのIDの取得"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["各関連付けを作成するには、まず関連付ける各オブジェクトのIDを取得する必要があります。各IDを取得するには、関連するオブジェクトエンドポイントに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信します。各CRMオブジェクトで同様の処理を行います。各リクエストを送信するときは、必要に応じて特定のプロパティーを返すようにするために、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "プロパティー"
      }), " クエリー パラメーターを含めることもできます。以下は、各タイプのオブジェクトに対する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストの例です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "GET request for line items\n/crm/v3/objects/line_items?properties=name\n\nGET request for quote templates\n/crm/v3/objects/quote_template?properties=hs_name\n\nGET request for deals\n/crm/v3/objects/deals?properties=dealname\n\nGET request for contacts\n/crm/v3/objects/contacts?properties=email\n\nGET request for companies\n/crm/v3/objects/companies?properties=name\n\nGET request for discounts\ncrm/v3/objects/discounts?properties=hs_type,hs_value\n\nGET request for fees\ncrm/v3/objects/fees?properties=hs_type,hs_value\n\nGET request for taxes\ncrm/v3/objects/taxes?properties=hs_type,hs_value\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "#GET request for line items\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/line_items?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for quote templates\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/quote_templates?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for deals\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/deals?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for contacts\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/contacts?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for companies\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/companies?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for discounts\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/discounts?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for fees\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/fees?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#GET request for taxes\ncurl --request GET \\\n  --url 'https://api.hubapi.com/crm/v3/properties/taxes?archived=false' \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["成功した呼び出しごとに、取得された各オブジェクトタイプの詳細を含む", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), "の応答が返されます。次のステップでは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "フィールドの値を使用して関連付けを設定します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example quote template GET response\n{\n  \"results\": [\n    {\n      \"id\": \"235425923863\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Basic\",\n        \"hs_object_id\": \"235425923863\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"235425923864\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Modern\",\n        \"hs_object_id\": \"235425923864\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    },\n    {\n      \"id\": \"235425923865\",\n      \"properties\": {\n        \"hs_createdate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_lastmodifieddate\": \"2023-06-12T16:27:32.794Z\",\n        \"hs_name\": \"Default Original\",\n        \"hs_object_id\": \"235425923865\"\n      },\n      \"createdAt\": \"2023-06-12T16:27:32.794Z\",\n      \"updatedAt\": \"2023-06-12T16:27:32.794Z\",\n      \"archived\": false\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付けの更新"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["IDが取得されると、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "関連付けAPI"
      }), "を呼び出して関連付けを作成できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["見積もりに関連付けるオブジェクトのタイプごとに、以下のURL構造を使用して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを送信することで、個別の呼び出しを行う必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/quotes/{fromObjectId}/associations/default/{toObjectType}/{toObjectId}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fromObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりのID"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["関連付けているオブジェクトのタイプ。例えば、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_items"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "deals"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "quote_template"
            }), "などです。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりを関連付けるオブジェクトのID。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下は、各タイプのオブジェクトに対する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストの例です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "PUT request to associate a line item\n/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{lineItemId}\n\nPUT request to associate a quote template\n/crm/v4/objects/quotes/{quoteId}/associations/default/quote_template/{quoteTemplateId}\n\nPUT request to associate a deal\n/crm/v4/objects/quotes/{quoteId}/associations/default/deals/{dealId}\n\nPUT request to associate contacts\n/crm/v4/objects/quotes/{quoteId}/associations/default/contacts/{contactId}\n\nPUT request to associate companies\n/crm/v4/objects/quotes/{quoteId}/associations/default/companies/{companyId}\n\nPUT request to associate discounts\n/crm/v4/objects/quotes/{quoteId}/associations/default/discounts/{discountId}\n\nPUT request to associate fees\n/crm/v4/objects/quotes/{quoteId}/associations/default/fees/{feeId}\n\nPUT request to associate taxes\n/crm/v4/objects/quotes/{quoteId}/associations/default/taxes/{taxId}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Shell script",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-shell",
            children: "#PUT request to associate line items\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{lineItemId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a quote template\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quote_ID}/associations/default/quote_template/{quoteTemplateId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a deal\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/deals/{dealId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate contacts\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/contacts/{contactId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate a company\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/companies/{companyId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate discounts\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/discounts/{discountId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate fees\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/fees/{feeId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n\n#PUT request to associate taxes\ncurl --request PUT \\\n  --url https://api.hubapi.com/crm/v4/objects/quotes/{quoteId}/associations/default/line_items/{taxId} \\\n  --header 'authorization: Bearer YOUR_ACCESS_TOKEN'\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As an example, if your quote has an ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123456"
      }), ", the requests to associate the quote might include the following:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Line items (IDs: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "55555"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "66666"
          }), "):"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/objects/quotes/123456/associations/default/line_items/55555"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v4/objects/quotes/123456/associations/default/line_items/66666"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Quote template (ID:"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "987654"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: "):"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/objects/quotes/123456/associations/default/quote_template/987654"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsxs)(_components.strong, {
          children: ["Deal (ID: ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "345345"
          }), "):"]
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/objects/quotes/123456/associations/default/deals/345345"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["成功した関連付けごとに、その関連付けの詳細を含む", (0, _jsxRuntime.jsx)(_components.code, {
        children: "200"
      }), "の応答が返されます。上記の呼び出しは、オブジェクトを両方向に関連付け、各方向に独自のIDがあります。例えば、見積もりを見積もりテンプレートに関連付けると、レスポンスには両方からの関連付けが記述されます。以下のレスポンスの例では、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "286"
      }), "は「見積もり－見積もりテンプレート」関連付けタイプＩＤで、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "285"
      }), "は「見積もりテンプレート－見積もり」関連付けタイプＩＤである。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"status\": \"COMPLETE\",\n  \"results\": [\n    {\n      \"from\": {\n        \"id\": \"115045534742\"\n      },\n      \"to\": {\n        \"id\": \"102848290\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 285\n      }\n    },\n    {\n      \"from\": {\n        \"id\": \"102848290\"\n      },\n      \"to\": {\n        \"id\": \"115045534742\"\n      },\n      \"associationSpec\": {\n        \"associationCategory\": \"HUBSPOT_DEFINED\",\n        \"associationTypeId\": 286\n      }\n    }\n  ],\n  \"startedAt\": \"2023-10-12T16:48:40.624Z\",\n  \"completedAt\": \"2023-10-12T16:48:40.712Z\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**見積もりを見積もりテンプレートに関連付ける場合は、次の制限に注意してください。"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["見積もりテンプレートに見積もりを関連付ける前に、見積もりテンプレートを", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/quotes/create-custom-quote-templates",
            children: "作成"
          }), "しておく必要があります。"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "見積もりは見積もりテンプレートでのみ関連付けることができます。"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["このAPIは、従来の見積もりと見積もり提案をサポートしていません。", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CUSTOMIZABLE_QUOTE_TEMPLATE"
          }), "テンプレートタイプのみを使用できます。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "見積もり署名者を関連付ける"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "#enable-e-signatures",
        children: "見積もりで電子署名の使用を有効にする"
      }), "場合は、特定の見積もりとコンタクト間の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations#associate-records-with-a-label",
        children: "関連付けラベル"
      }), "を使用して、見積もりと署名者であるコンタクトとの間に関連付けを作成する必要もあります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["上記の既定の関連付けエンドポイントを使用するのではなく、次のURLに", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを行う必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/quote/{quoteId}/associations/contact/{contactId}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リクエスト本文で、次のように", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), "を指定する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body\n[\n  {\n    \"associationCategory\": \"HUBSPOT_DEFINED\",\n    \"associationTypeId\": 702\n  }\n]\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "// Example request body\n[\n  {\n    \"associationCategory\": \"HUBSPOT_DEFINED\",\n    \"associationTypeId\": 702\n  }\n]\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連付けのある見積もりを作成する（1つのリクエスト）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のリクエスト本文では、見積もりテンプレート、取引、2つの商品項目、および連絡先に関連付けられた、新しい見積もりを作成します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quote"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsx)(Table, {
            style: {
              "borderCollapse": "collapse",
              "tableLayout": "fixed",
              "marginLeft": "auto",
              "marginRight": "auto",
              "width": "100%",
              "height": "590.032px"
            },
            bordered: false,
            children: (0, _jsxRuntime.jsxs)("tbody", {
              children: [(0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "135.672px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px",
                    "height": "135.672px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "properties"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "object"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Quote details, which can be retrieved through the ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/api/crm/properties",
                      children: "properties API"
                    }), ". Required properties are: ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), "."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_title"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The name of the quote."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_expiration_date"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    }), " (required)"]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The date that the quote expires."
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "135.672px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "width": "100%",
                    "padding": "10px 4px 10px 22px",
                    "height": "135.672px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["⮑ ", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "hs_status"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "string"
                    })]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "#update-quote-status",
                      children: "quote status"
                    }), ". Omitting this property on create will prevent users from being able to edit the quote in HubSpot."]
                  })]
                })
              }), (0, _jsxRuntime.jsx)("tr", {
                style: {
                  "height": "79.6719px"
                },
                children: (0, _jsxRuntime.jsxs)("td", {
                  style: {
                    "padding": "10px 4px",
                    "height": "79.6719px"
                  },
                  children: [(0, _jsxRuntime.jsxs)(_components.p, {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "associations"
                    }), " ", (0, _jsxRuntime.jsx)(_components.strong, {
                      children: "array"
                    })]
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "The quote's associated records. For a quote to be publishable, it must have an associated deal and quote template. The line items should be created separately from the line items on the associated deal."
                  }), (0, _jsxRuntime.jsx)(_components.p, {
                    children: "To set each association, include a separate object in the associations array with the following fields:"
                  }), (0, _jsxRuntime.jsxs)(_components.ul, {
                    children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                      children: "to: the ID of the record to associate with the quote."
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: ["associationCategory: the ", (0, _jsxRuntime.jsx)(_components.a, {
                        href: "/guides/api/crm/associations#retrieve-association-types",
                        children: "type of association"
                      }), ". Can be HUBSPOT_DEFINED or USER_DEFINED."]
                    }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                      children: ["associationTypeId: the ID of the type of association being made:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
                        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                          children: "286: quote to quote template"
                        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                          children: "64: quote to deal"
                        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                          children: "67: quote to line item"
                        }), "\n"]
                      }), "\n"]
                    }), "\n"]
                  }), (0, _jsxRuntime.jsxs)(_components.p, {
                    children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
                      href: "/guides/api/crm/associations#association-type-id-values",
                      children: "association type IDs"
                    }), "."]
                  })]
                })
              })]
            })
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// POST request to https://api.hubapi.com/crm/v3/objects/quote\n{\n  \"properties\": {\n    \"hs_title\": \"CustomerName - annual SEO audit\",\n    \"hs_expiration_date\": \"2023-09-30\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 115045534742\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 286\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 14795354663\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 64\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 75895447\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 67\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 256143985\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 67\n        }\n      ]\n    }\n  ]\n}\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**これらの商品項目は、関連付けられている場合でも（見積もりを取引に関連付ける場合など）、他のオブジェクトの商品項目とは異なる必要があります。詳細については、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/line-items",
          children: "商品項目APIのドキュメント"
        }), "を参照してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "見積もりステータスの更新"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["見積もりのステータスは、初期設定段階から一般公開までの作成プロセスの中で、どの位置にいるかを表すものです。見積もり承認がアカウントに対して有効になっている場合、見積もりのステータスは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/quotes/approve-quotes",
        children: "見積もりの承認プロセス"
      }), "を反映することもできます。見積もりのステータスを設定すると、HubSpotは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#properties-set-by-quote-state",
        children: "特定のプロパティーを自動的に入力"
      }), "します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["見積もりのステータスを更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/quote/{quoteId}"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["見積もりのステータスは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_status"
      }), "フィールドに基づきます。特定の見積もりのステータスで、ユーザーは見積もり承認ワークフローで見積もりを編集、公開、使用できます。以下の見積もりのステータスを利用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "ステータスなし："
        }), (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_status"
        }), "フィールドに値が指定されていない場合、見積もりは「最小限」ステータスになります。この場合、見積もりは見積もりツールのインデックスページに表示されますが、直接編集することはできません。このステータスの見積もりも、シーケンスツールなどの自動化やレポートツール内での分析に使用できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "DRAFT"
        }), "：HubSpotで見積もりを編集できます。このステータスは、見積もりが完全に設定されていない場合や、HubSpotでの見積もり設定プロセスを営業担当者によって完了できるようにしたい場合に役立ちます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "APPROVAL_NOT_NEEDED"
        }), "：", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "承認"
        }), "を必要とせずに、誰でもアクセス可能なURL（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), "）で見積もりを公開します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING_APPROVAL"
        }), "：見積もりが公開される前の", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "承認待ち"
        }), "であることを示します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "APPROVED"
        }), "：見積もりが", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "承認"
        }), "され、誰でもアクセス可能なURL（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), "）に公開されました。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "REJECTED"
        }), "：見積もりが設定されたものの、公開が承認されておらず、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/approve-quotes",
          children: "承認"
        }), "のために再送信する前に、編集が必要であることを示します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**見積もりで", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#enabling-e-signatures",
          children: "電子署名を有効"
        }), "にしている場合、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/quotes/use-e-signatures-with-quotes#monitor-e-signature-usage",
          children: "毎月の電子署名数の制限を超える"
        }), "と見積もりが公開できなくなります。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば次のリクエストは、誰でもアクセス可能なURLで見積もりを公開します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// PATCH request to https://api.hubapi.com/crm/v3/objects/quote/{QUOTE_ID}\n{\n  \"properties\": {\n    \"hs_status\": \"APPROVAL_NOT_NEEDED\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**既定では、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#update-quote-state",
          children: "見積もりの状態が更新"
        }), "された後で、見積もりの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_template_type"
        }), "プロパティーが", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CUSTOMIZABLE_QUOTE_TEMPLATE"
        }), "に設定されます。このテンプレートタイプはv3 APIでサポートされていますが、次のテンプレートタイプはレガシーテンプレートであり、サポートが終了しています。"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "QUOTE"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROPOSAL"
          })
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "見積もりを取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "見積もりを個別に、または一括で取得できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["個々の見積もりを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/quotes/{quoteID}"
        }), "に送信します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["全ての見積もりのリストを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/quotes"
        }), "に送信します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "これらのエンドポイントに対し、リクエストのURLに次のクエリーパラメーターを含めることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "レスポンスで取得するプロパティーのカンマ区切りリスト。リクエスト対象の見積もりで、指定したプロパティーに値が設定されていない場合、そのプロパティーはレスポンスに含まれません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "レスポンスで取得する現在および過去のプロパティーのカンマ区切りリスト。リクエスト対象の見積もりで、指定したプロパティーに値が設定されていない場合、そのプロパティーはレスポンスに含まれません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["関連付けられているIDが取得されるオブジェクトの、カンマ区切りリスト。存在しない関連付けを指定した場合、その関連付けはレスポンスで返されません。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "関連付けAPI"
            }), "をご確認ください。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["IDを基準にして特定の見積もりを一括で取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/quotes/batch/read"
      }), "に送信し、このときリクエスト本文にIDを含めます。返すプロパティーを指定する", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), "配列を含めることもできます。このバッチエンドポイントは関連付けを取得できません。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations",
        children: "関連付けAPI"
      }), "を使用してバッチが関連付けを読み取る方法について説明します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example request body\n{\n \"inputs\": [\n  {\"id\": \"342007287\"},\n  {\"id\":\"81204505203\"}\n ],\n \"properties\": [\n  \"hs_content\", \"hs_sentiment\",\n  \"hs_submission_timestamp\"\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "見積もりのステータスで設定されたプロパティー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "見積もりのステータスを更新すると、次のプロパティーが更新されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_amount"
        }), "：関連付けられている商品項目、税金、割引、手数料に基づいて計算されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_domain"
        }), "：関連付けられている見積もりテンプレートから設定されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_slug"
        }), "：指定されていない場合は無作為に生成されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_total_preference"
        }), "：アカウントの設定に基づいて設定されます。この設定が指定されていない場合には、既定値としてtotal_first_paymentフィールドの値が使用されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_timezone"
        }), "：既定値としてHubSpotアカウントのタイムゾーンが使用されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_locale"
        }), "：関連付けられている見積もりテンプレートから設定されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_number"
        }), "：指定されていない場合には現在の日付と時刻に基づいて設定されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_language"
        }), "：関連付けられている見積もりテンプレートから設定されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_currency"
        }), "：関連付けられている取引に基づいて設定されます。見積もりに取引を関連付けていない場合、HubSpotアカウントの既定の通貨が既定値として使用されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "見積もりが「公開中」ステータスに設定されるときには、次のプロパティーも計算されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_pdf_download_link"
        }), "：見積もりのPDFのURLが取り込まれます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_locked"
        }), "：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "true"
        }), "に設定されます。見積もりを公開した後にプロパティーを変更するには、まず見積もりの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_status"
        }), "を", (0, _jsxRuntime.jsx)(_components.code, {
          children: "DRAFT"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "PENDING_APPROVAL"
        }), "、または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "REJECTED"
        }), "に更新する必要があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_quote_link"
        }), "：見積もりの一般公開URL。これは読み取り専用プロパティーであり、公開後にAPIを介して設定することはできません。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_esign_num_signers_required"
        }), "：", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#enable-e-signatures",
          children: "電子署名を有効"
        }), "にしている場合、必要な署名の数が表示されます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_payment_status"
        }), "：支払いを有効にしている場合の支払い回収のステータス。支払いを有効にして公開すると、このプロパティーはPENDINGに設定されます。購入者が見積もりを通じて支払いを送信すると、ステータスはそれに応じて自動的に更新されます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/commerce/quotes#enable-payments",
          children: "支払いを有効にする"
        }), "方法について詳細をご確認ください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "スコープ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "有効で公開可能な見積もりを作成するには、次のスコープが必要です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.quotes.write"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.quotes.read"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.line_items.write"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.line_items.read"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.owners.read"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.write"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.contacts.read"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.write"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.deals.read"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.write"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.objects.companies.read"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.quote.read"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.line_items.read"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.contacts.read"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.deals.read"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm.schemas.companies.read"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}