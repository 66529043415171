"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29913023567;
const slug = exports.slug = 'reference/cms/modules/default-modules';
const title = exports.title = '既定のウェブモジュール';
const name = exports.name = '既定のウェブモジュール';
const metaDescription = exports.metaDescription = 'ウェブサイトページ、ブログ記事、ブログ リスト テンプレートを作成するためにHubSpotが提供している既定のモジュールをご紹介します。これらのモジュールは、Eメールテンプレートで使用する既定のEメールモジュールとは別のものです。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E3%82%B3%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "ブログコメント",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E3%81%AEe%E3%83%A1%E3%83%BC%E3%83%AB%E9%85%8D%E4%BF%A1%E7%99%BB%E9%8C%B2",
  "label": "ブログのEメール配信登録",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%98%E4%BA%8B",
  "label": "ブログ記事",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%98%E4%BA%8B%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E3%83%BC",
  "label": "ブログ記事フィルター",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%96%E3%83%AD%E3%82%B0%E8%A8%98%E4%BA%8B%E3%83%AA%E3%82%B9%E3%83%88",
  "label": "ブログ記事リスト",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9C%E3%82%BF%E3%83%B3",
  "label": "ボタン",
  "parent": null
}, {
  "depth": 0,
  "id": "cta%EF%BC%88call-to-action%EF%BC%89",
  "label": "CTA（Call-To-Action）",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E5%89%8A%E9%99%A4",
  "label": "データを削除",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%8C%BA%E5%88%87%E3%82%8A",
  "label": "区切り",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E3%83%80%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%89",
  "label": "データをダウンロード",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0",
  "label": "フォーム",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%98%E3%83%83%E3%83%80%E3%83%BC",
  "label": "ヘッダー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%B0%B4%E5%B9%B3%E6%96%B9%E5%90%91%E3%82%B9%E3%83%9A%E3%83%BC%E3%82%B5%E3%83%BC",
  "label": "水平方向スペーサー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%82%A4%E3%82%B3%E3%83%B3",
  "label": "アイコン",
  "parent": null
}, {
  "depth": 0,
  "id": "%E7%94%BB%E5%83%8F",
  "label": "画像",
  "parent": null
}, {
  "depth": 0,
  "id": "%E7%94%BB%E5%83%8F%E3%82%B0%E3%83%AA%E3%83%83%E3%83%89",
  "label": "画像グリッド",
  "parent": null
}, {
  "depth": 0,
  "id": "%E7%94%BB%E5%83%8F%E3%82%AE%E3%83%A3%E3%83%A9%E3%83%AA%E3%83%BC",
  "label": "画像ギャラリー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E7%94%BB%E5%83%8F%E3%82%B9%E3%83%A9%E3%82%A4%E3%83%80%E3%83%BC%E3%82%AE%E3%83%A3%E3%83%A9%E3%83%AA%E3%83%BC",
  "label": "画像スライダーギャラリー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A8%80%E8%AA%9E%E5%88%87%E3%82%8A%E6%9B%BF%E3%81%88%E6%A9%9F%E8%83%BD",
  "label": "言語切り替え機能",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AD%E3%82%B4",
  "label": "ロゴ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AD%E3%82%B4%E3%82%B0%E3%83%AA%E3%83%83%E3%83%89",
  "label": "ロゴグリッド",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9F%E3%83%BC%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0",
  "label": "ミーティング",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%BD%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E6%A8%A9%E8%A8%AD%E5%AE%9A",
  "label": "ソーシャルログインのアクセス権設定",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC",
  "label": "メニュー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%9A%E3%83%BC%E3%82%B8%E3%83%95%E3%83%83%E3%82%BF%E3%83%BC",
  "label": "ページフッター",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%91%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E3%83%97%E3%83%AD%E3%83%B3%E3%83%97%E3%83%88",
  "label": "パスワードプロンプト",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%94%AF%E6%89%95%E3%81%84",
  "label": "支払い",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A3%BD%E5%93%81",
  "label": "製品",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%81%AE%E3%83%80%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%89",
  "label": "見積もりのダウンロード",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%81%AE%E6%94%AF%E6%89%95%E3%81%84",
  "label": "見積もりの支払い",
  "parent": null
}, {
  "depth": 0,
  "id": "%E8%A6%8B%E7%A9%8D%E3%82%82%E3%82%8A%E3%81%AE%E7%BD%B2%E5%90%8D",
  "label": "見積もりの署名",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%95%86%E5%93%81%E9%A0%85%E7%9B%AE",
  "label": "商品項目",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%AA%E3%83%83%E3%83%81%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88",
  "label": "リッチテキスト",
  "parent": null
}, {
  "depth": 0,
  "id": "rss%E3%83%AA%E3%82%B9%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0",
  "label": "RSSリスティング",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B5%E3%82%A4%E3%83%88%E6%A4%9C%E7%B4%A2%E5%85%A5%E5%8A%9B",
  "label": "サイト検索入力",
  "parent": null
}, {
  "depth": 0,
  "id": "%E6%A4%9C%E7%B4%A2%E7%B5%90%E6%9E%9C",
  "label": "検索結果",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%BB%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%98%E3%83%83%E3%83%80%E3%83%BC",
  "label": "セクションヘッダー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%B7%E3%83%B3%E3%83%97%E3%83%AB%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC",
  "label": "シンプルメニュー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%BD%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%83%95%E3%82%A9%E3%83%AD%E3%83%BC",
  "label": "ソーシャルフォロー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%BD%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%82%B7%E3%82%A7%E3%82%A2%E3%83%AA%E3%83%B3%E3%82%B0",
  "label": "ソーシャルシェアリング",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%BF%E3%83%96%E4%BB%98%E3%81%8D%E3%82%AB%E3%83%BC%E3%83%89",
  "label": "タブ付きカード",
  "parent": null
}, {
  "depth": 0,
  "id": "1%E8%A1%8C%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88",
  "label": "1行テキスト",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%8B%95%E7%94%BB",
  "label": "動画",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%8B%95%E7%94%BB%E5%9F%8B%E3%82%81%E8%BE%BC%E3%81%BF%EF%BC%88%E3%83%A9%E3%83%B3%E3%83%87%E3%82%A3%E3%83%B3%E3%82%B0%E3%83%9A%E3%83%BC%E3%82%B8%EF%BC%89",
  "label": "動画埋め込み（ランディングページ）",
  "parent": null
}, {
  "depth": 0,
  "id": "whatsapp%E3%83%AA%E3%83%B3%E3%82%AF",
  "label": "WhatsAppリンク",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      img: "img",
      ul: "ul",
      li: "li",
      h2: "h2",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "既定のウェブモジュール"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "この記事では、ウェブサイトページ、ブログ記事、ブログ リスト ページのテンプレートを作成するためにHubSpotが提供している既定のモジュールをご紹介します。見積もりテンプレートを作成するために使用できる既定のモジュールもあります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ローカルで開発する際は、モジュールのパス（例：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs fetch @hubspot/linked_image.module"
      }), "）を使用して特定の既定のモジュールを", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#fetch-files",
        children: "取得"
      }), "できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["既定のモジュールのコードを確認するには、デザインマネージャーの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot"
      }), "フォルダーに格納されているモジュールを表示して複製します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/design-manager-default-modules.png",
        alt: "design-manager-default-modules"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**既定のウェブモジュールは、Eメールテンプレートで使用する", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-email-modules",
          children: "既定のEメールモジュール"
        }), "とは別のものです。Eメールテンプレートに次のいずれかの既定のモジュールが含まれている場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/default-email-modules",
          children: "対応するEメール固有のモジュールに置き換える"
        }), "必要があります。"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "cta"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "header"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "linked_image"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "logo"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "post_filter"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "post_listing"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "section_header"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "social_sharing"
          })
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "text"
          })
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブログコメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ブログ記事やブログリストでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_comments\" path=\"@hubspot/blog_comments\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブログのEメール配信登録"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログ記事、ブログリストでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_subscribe\" path=\"@hubspot/blog_subscribe\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "モジュールに使用するブログ。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "モジュールのタイトル（h3タグで囲みます）。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Subscribe Here!\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "response_message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リッチテキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォームの送信時に表示されるメッセージ。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Thanks for subscribing!"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブログ記事"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このモジュールをブログ リスト ページに追加すると、クリックするとブログ記事に移動するボタンを使用して、各記事のタイトル、キービジュアル、執筆者、公開日などを含むブログ記事のプレビューを表示できるようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この既定のモジュールはReactを使用して作成されており、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-react/tree/main/default-react-modules/src/components/modules/BlogPosts-V0",
        children: "ソースコードはGitHubで確認"
      }), "できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["デザインマネージャーからこのモジュールにアクセスすることはできません。このモジュールは、コードテンプレート内でHubLを使って参照し、", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/blog/edit-a-blog-listing-page",
            children: "ブログ リスト ページ エディター"
          }), "内に追加できます。"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["このモジュールは、非推奨になった以前の", (0, _jsxRuntime.jsx)(_components.code, {
            children: "blog_listing"
          }), "モジュールを置き換えるものです。"]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"blog_posts\"\n  path=\"@hubspot/blog_posts\"\n  layout=\"grid\",\n  columns=4,\n  displayForEachListItem=[\n   \"image\",\n   \"title\",\n   \"authorName\",\n   \"tags\",\n   \"publishDate\",\n   \"description\",\n   \"button\"\n  ]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "layout"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "必須"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["各ブログ記事プレビューのコンテンツレイアウト。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "grid"
                }), "（既定）：基本グリッドで記事を並べます。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "singleColumn"
                }), "：1列で記事を並べます。キービジュアルは、記事の他のコンテンツの上に独自の行で配置されます。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "sideBySide"
                }), "：1列で記事を並べます。キービジュアルは、記事のコンテンツと水平に並べます。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "columns"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            }), "レイアウトを使用する場合、1行あたりの記事の数。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "4"
            }), "のいずれかにできます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alternateImage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "sideBySide"
            }), "レイアウトを使用する場合、このブール値を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定すると、記事プレビューの右側と左側に交互にキービジュアルが並べられます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fullImage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "grid"
            }), "レイアウトまたは", (0, _jsxRuntime.jsx)(_components.code, {
              children: "singleColumn"
            }), "レイアウトを使用する場合、このブール値を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定すると、キービジュアルが記事プレビューの背景になります。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "displayForEachListItem"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["各ブログ記事プレビューに含めるコンテンツ。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "image"
                }), "：記事のキービジュアル。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), "：記事のタイトル。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "authorImage"
                }), "：記事の執筆者の画像。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "authorName"
                }), "：記事の執筆者の名前。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "tags"
                }), "：記事のブログタグ。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "publishDate"
                }), "：記事の公開日。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), "：記事のメタディスクリプション。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button"
                }), "：ブログ記事にリンクする、続きを読むボタン。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ 'image', 'title', 'authorImage', 'authorName', 'tags', 'publishDate', 'description', 'button' ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "buttonText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "続きを読むボタンに表示されるテキスト（このボタンが含まれる場合）。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Read more"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブログ記事フィルター"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"post_filter\" path=\"@hubspot/post_filter\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "表示するブログを選択します。既定では、ブログテンプレートでの使用時には現在のブログを使用し、他での使用時にはプライマリーブログを使用します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "filter_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["表示するフィルターリンクのタイプ。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "tag"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "month"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "author"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tag"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "order_by"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["フィルターリンクの値の順序。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "post_count"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "name"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "post_count"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "list_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "H3見出し。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Posts by Tag\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "表示するフィルターリンクの数。全て表示する場合は空白のままにします。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "expand_link_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            }), "の値を超える数のリンクが使用可能な場合に表示するテキスト。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"See all\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ブログ記事リスト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**このモジュールの新しいバージョンが2024年3月末にリリースされました。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-module-versioning#post-listing",
          children: "新しい既定の記事リストモジュール"
        }), "について詳細をご確認ください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"post_listing\" path=\"@hubspot/post_listing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "select_blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログ"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "表示するブログを選択します。既定では、ブログテンプレートでの使用時には現在のブログを使用し、他での使用時にはプライマリーブログを使用します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "listing_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["記事一覧のタイプ。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "recent"
                }), "：最新。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_all_time"
                }), "：最も人気の高い記事。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_year"
                }), "：過去1年間で最も人気の高い記事。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_six_months"
                }), "：過去6か月間で最も人気の高い記事。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "popular_past_month"
                }), "：過去1か月間で最も人気の高い記事。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "recent"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "list_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "H3見出し。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Recent Posts\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_links"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "表示する記事の最大数。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ボタン"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログ記事、ブログリストでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"button\" path=\"@hubspot/button\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リンク"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ボタンによるリダイレクト先URL。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"url\": { \"type\": \"EXTERNAL\", \"href\": \"www.hubspot.com\", \"content_id\": null }, \"open_in_new_tab\": false, \"no_follow\": false }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ボタンに表示されるテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Add a button link here\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_default_style"
                }), "（ブール値）", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_font"
                }), "（フォント）", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_color"
                }), "（色）", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "text_hover_color"
                }), "（色）", (0, _jsxRuntime.jsx)("br", {})]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_hover_color"
                }), "（色）"]
              })]
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"override_default_style\": false, \"button_font\": { \"color\": \"#FFFFFF\", \"size_unit\": \"px\" }, \"button_color\": { \"color\": \"#000000\", \"opacity\": 100 }, \"text_hover_color\": { \"color\": \"#000000\", \"opacity\": 100 }, \"button_hover_color\": { \"color\": \"#CCCCCC\", \"opacity\": 100 } }"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CTA（Call-To-Action）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"cta\" path=\"@hubspot/cta\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "guid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CTAのグローバル固有ID。"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "データを削除"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このモジュールを配信設定ページに追加すると、コンタクトが自分のデータの削除をリクエストできるようになります。特定の個人情報保護法においては、この機能が必要です。コンタクトはデータの削除をリクエストした後、自動的に送信されるEメールで30分以内に削除を確定する必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "スーパー管理者権限"
      }), "を持つユーザーには、これらのリクエストについて通知するEメールが届きます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#download-data",
        children: "コンタクトが自分のデータのダウンロードをリクエストできるようにする"
      }), "方法をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"delete_data\" path=\"@hubspot/delete_data\", label=\"delete_data.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リッチテキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ボタンの上に表示されるメッセージ。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ### Delete Data  Permanently delete your personal data stored by {{ site_settings.company_name}}. Personal data is information that can be used to identify you and doesn't include anonymized data.  You'll get a follow-up email where you'll need to verify your request.  "
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ボタンテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Request data deletion"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_alerts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィールドグループ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["正常完了アラートおよび失敗アラートのフィールドグループ。次のフィールドグループがあります。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_success_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), "：正常完了アラートのリッチ テキスト コンテンツ。"]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_fail_alert"
                }), "：", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), "：失敗アラートのリッチ テキスト コンテンツ。"]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_close_icon"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "icon"
                    }), "：アラートを閉じるためのアイコン。"]
                  })
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "区切り"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページでサポートされています。2022年8月25日以降に作成されたアカウントでは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-module-versioning#divider",
        children: "このモジュールの新しいバージョン"
      }), "（英語）を利用できます。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/changelog/divider-module-v1",
        children: "この更新に関する情報"
      }), "（英語）をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"divider\" path=\"@hubspot/divider\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "区切り線の高さ（px）。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "区切り線の幅のパーセンテージ（%）。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "50"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "色"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "区切り線の色（16進数）と不透明度（数値）。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"color\": \"#000000\", \"opacity\": 100 }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "line_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["線のタイプ。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "solid"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "dotted"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "dashed"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "solid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["区切り線の水平方向の位置合わせ。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "left"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "center"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "right"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "center"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "区切りの上下に設けられる余白を表示するか、非表示にするかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["区切り線の上下に設けられる余白のピクセル（px）値。このオプションは、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_padding"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定されている場合に使用可能になります。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "データをダウンロード"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["このモジュールを配信設定ページに追加すると、コンタクトが自分のデータのコピーをリクエストできるようになります。特定の個人情報保護法においては、この機能が必要です。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/settings/hubspot-user-permissions-guide",
        children: "スーパー管理者権限"
      }), "を持つユーザーには、これらのリクエストについて通知するEメールが届きます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#delete-data",
        children: "コンタクトが自分のデータの削除をリクエストできるようにする"
      }), "方法をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"download_data\" path=\"@hubspot/download_data\", label=\"download_data.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リッチテキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ボタンの上に表示されるメッセージ。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ### Download Data  Download your personal data stored by {{ site_settings.company_name}}.  Personal data is information that can be used to identify you and doesn't include anonymized data.  "
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ボタンテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Request data download"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_alerts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィールドグループ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["正常完了アラートおよび失敗アラートのフィールドグループ。次のフィールドグループがあります。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_success_alert"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), "：正常完了アラートのリッチ テキスト コンテンツ。"]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_fail_alert"
                }), "：", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "content"
                    }), "：失敗アラートのリッチ テキスト コンテンツ。"]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_close_icon"
                }), ":", (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "icon"
                    }), "：アラートを閉じるためのアイコン。"]
                  })
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "フォーム"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログ記事、ブログリストでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"form\"\n path=\"@hubspot/form\"\n form={\n  \"form_id\": \"9e633e9f-0634-498e-917c-f01e355e83c6\",\n  \"response_type\": \"redirect\",\n  \"message\": \"Thanks for submitting the form.\",\n  \"redirect_id\": null,\n  \"redirect_url\": \"http://www.google.com\"\n }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "H3見出し"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "form"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["次のフィールドを含むフォームオブジェクト。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "form_id"
                }), "：使用するフォームのID。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), "：フォーム送信後に訪問者に表示する内容。", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "inline"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "redirect"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "message"
                }), "：インラインメッセージ（", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), "が", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "inline"
                }), "に設定されている場合）。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect_id"
                }), "：リダイレクト先ページのID（", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), "が", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect"
                }), "に設定されている場合）。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect_url"
                }), "：リダイレクト先URL（", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "response_type"
                }), "が", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirect"
                }), "に設定されている場合）。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"form_id\": \"\", \"response_type\": \"redirect\", \"message\": \"Thanks for submitting the form.\", \"redirect_id\": null, \"redirect_url\": \"http://www.google.com\" }"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_are_overridden"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォームの既定ではなく、フォームの通知Eメールを送信するかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_override_email_addresses"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eメール"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "notifications_are_overridden"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "の場合に送信するEメールのカンマ区切りリスト。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "follow_up_type_simple"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フォローアップEメールの送信を有効にするかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "simple_email_for_live_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Followupemail"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["フォローアップEメールのID。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "follow_up_type_simple"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "の場合に使用可能になります。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sfdc_campaign"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Salesforcecampaign"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Salesforce連携がアクティブな場合のキャンペーンID。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ヘッダー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"header\" path=\"@hubspot/header\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見出しのテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A clear and bold header\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header_tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["見出しのレベルを選択します。選択肢は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), "から", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "までです。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "水平方向スペーサー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事、およびEメールでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"horizontal_spacer\" path=\"@hubspot/horizontal_spacer\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アイコン"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。アイコンは、Font Awesome 5.0.10および5.14.0のアイコンセットから取得できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"icon\" path=\"@hubspot/icon\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アイコンの名前。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"hubspot\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "purpose"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アイコンの目的を分類するスクリーンリーダー用のアクセシビリティーオプション。有効な値は次のとおりです。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "decorative:"
                }), "スクリーンリーダーで読み上げられません。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "semantic:"
                }), "スクリーンリーダーで読み上げられます。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"decorative\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アイコンにタイトルを割り当てるアクセシビリティーオプション。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アイコンのタイプ。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "solid"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "regular"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "light"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thin"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "duotone"
            }), "のいずれかを指定できます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"solid\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unicode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アイコンのUnicode値。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "f3b2"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "画像"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"linked_image\" path=\"@hubspot/linked_image\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "img"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "画像"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["次のフィールドを含む画像オブジェクト。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), "：画像のURL。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), "：画像の代替テキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "loading"
                }), "：次の遅延読み込みオプションがあります。", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "disabled"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "lazy"
                    })
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "width"
                }), "：px値。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "height"
                }), "：px値。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"src\": \"https://static.hubspot.com/final/img/content/email-template-images/placeholder_200x200.png\", \"alt\": \"placeholder_200x200\", \"loading\": \"disabled\", \"width\": 200, \"height\": 200 }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "画像のリンク（オプション）。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "target"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "新しいタブでリンクを開くかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "画像グリッド"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"image_grid\" path=\"@hubspot/image_grid\", label=\"image_grid.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["各スライドの情報を含むオブジェクト。オブジェクトには次のフィールドが含まれます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), "：画像のURL。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), "：スライドのリンク先URL。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hover_messages"
                }), "：画像にカーソルを合わせると表示されるテキスト。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ { \"show_caption\": false, \"open_in_new_tab\": false } ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["画像ギャラリーの表示モード。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "standard"
                }), "：標準スライダー。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "thumbnail"
                }), "：サムネイルナビゲーター。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "lightbox"
                }), "：Lightboxギャラリー。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightboxRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightbox"
            }), "の場合に表示するLightboxギャラリーの行数。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop_slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合にスライドを循環して表示するかどうかを指定します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "auto_advance"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合に自動的に次のスライドに進むかどうかを指定します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "num_seconds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合に次のスライドに進むまでの時間の間隔（秒数）。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_pagination"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合にナビゲーションボタンを表示するかどうかを指定します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sizing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合にスライドの高さを設定します。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "static"
                }), "：高さ固定。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "resize"
                }), "：高さ変更可能。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "static"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合に使用する、スライドの遷移スタイル。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), "：スライド切り替え。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fade"
                }), "：フェードによる遷移。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slide"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "caption_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合に表示する、スライドのキャプションの位置。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "below"
                }), "：常に画像の下にキャプションを表示します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "superimpose"
                }), "：画像の上にキャプションを重ねて表示します。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "画像ギャラリー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログ記事、ブログリストでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"gallery\" path=\"@hubspot/gallery\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["各スライドの情報を含むオブジェクト。オブジェクトには次の情報が含まれます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), "：画像のURL。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "show_caption"
                }), "：スライドのキャプションを表示するかどうかを指定するブール値。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "caption"
                }), "：キャプションのリッチテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), "：スライドのリンク先URL。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "open_in_new_tab"
                }), "：リンクを新しいタブで開くかどうかを指定するブール値。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ { \"show_caption\": false, \"open_in_new_tab\": false } ]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["画像ギャラリーの表示モード。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "standard"
                }), "：標準スライダー。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "thumbnail"
                }), "：サムネイルナビゲーター。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "lightbox"
                }), "：Lightboxギャラリー。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightboxRows"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lightbox"
            }), "の場合に表示するLightboxギャラリーの行数。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "loop_slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合にスライドを循環して表示するかどうかを指定します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "auto_advance"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合に自動的に次のスライドに進むかどうかを指定します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "num_seconds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合に次のスライドに進むまでの時間の間隔（秒数）。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_pagination"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合にナビゲーションボタンを表示するかどうかを指定します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sizing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合にスライドの高さを設定します。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "static"
                }), "：高さ固定。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "resize"
                }), "：高さ変更可能。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "static"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合に使用する、スライドの遷移スタイル。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), "：スライド切り替え。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "fade"
                }), "：フェードによる遷移。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slide"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "caption_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "standard"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "thumbnail"
            }), "の場合に表示する、スライドのキャプションの位置。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "below"
                }), "：常に画像の下にキャプションを表示します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "superimpose"
                }), "：画像の上にキャプションを重ねて表示します。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "below"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "画像スライダーギャラリー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログ記事、ブログリストでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"unique_name\"\n    path=\"@hubspot/image_slider_gallery\",\n    slides=[\n        {\n        \"img\": {\n            \"src\": \"\",\n            \"alt\": \"Default image alt text\"\n        },\n        \"caption\": \"<strong>1</strong> An optional caption for the image that will be added to the gallery. Enter any descriptive text for this image that you would like visitors to be able to read.\",\n        \"link_url\": \"\"\n        },\n        {\n        \"img\": {\n            \"src\": \"\",\n            \"alt\": \"Default image alt text\"\n        },\n        \"caption\": \"<strong>2</strong> An optional caption for the image that will be added to the gallery. Enter any descriptive text for this image that you would like visitors to be able to read.\",\n        \"link_url\": \"\"\n        }\n    ]\n    slideshow_settings={\n        \"slides\": {\n        \"per_page\": 1,\n        \"sizing\": \"natural\",\n        \"aspect_ratio\": \"16/9\",\n        \"show_captions\": true,\n        \"caption_position\": \"below\"\n        },\n        \"movement\": {\n        \"transition\": \"slide\",\n        \"loop_slides\": false,\n        \"auto_advance\": false,\n        \"auto_advance_speed_seconds\": 5\n        },\n        \"navigation\": {\n        \"show_main_arrows\": true,\n        \"show_thumbnails\": false,\n        \"show_dots\": false\n        }\n    }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slides"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィールドグループ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["次のフィールドが含まれるフィールドグループ。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "img"
                }), "：画像のURL。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "caption"
                }), "：画像のキャプションのリッチテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "link_url"
                }), "：スライドのリンク先URL。"]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "slideshow_settings"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィールドグループ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["画像スライダー設定フィールドグループ。次のフィールドグループがあります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slides"
                }), "：次の情報を含むスライド設定。", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "per_page"
                    }), "：1ページあたりのスライドの数。"]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "sizing"
                    }), "：画像のサイズ設定。"]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "aspect_ratio"
                    }), "：画像の縦横比。"]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "show_captions"
                    }), "：ブール値。"]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "movement"
                }), "：画像のスライド動作の設定。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "navigation"
                }), "：画像のナビゲーション設定。"]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "default_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィールドグループ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["モジュールの既定のテキスト要素。次の要素が含まれます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "default_caption"
                }), "：画像のキャプション。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "default_image_alt_text"
                }), "：画像のテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slider_aria_label"
                }), "：モジュールの既定のエリアラベル。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "arial_label_thumbnails"
                }), "：モジュールの既定のエリアサムネイル。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slider_nav_aria_label"
                }), "：モジュールナビゲーションの既定のエリアラベル。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "prev"
                }), "：前のスライドに移動することを示すテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "next"
                }), "：次のスライドに移動することを示すテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "first"
                }), "：最初のスライドに移動することを示すテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slideX"
                }), "：X番目のスライドに移動することを示すテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pageX"
                }), "：X番目のページに移動することを示すテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "play"
                }), "：自動再生を開始することを示すテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pause"
                }), "：自動再生を一時停止することを示すテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "carousel"
                }), "：カルーセルテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "select"
                }), "：表示するスライドを選択することを示すテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slide"
                }), "：スライドのテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "slidelabel"
                }), "：スライドのラベル。"]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "言語切り替え機能"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"language_switcher\" path=\"@hubspot/language_switcher\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_mode"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["言語切り替え機能のテキストの言語。次のオプションがあります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pagelang"
                }), "：言語切り替え機能が有効になっているページの言語で各言語の名前を表示します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "localized"
                }), "：各言語の名前をそれぞれの言語で表示します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hybrid"
                }), "：2つの組み合わせ。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "localized"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ロゴ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo\" path=\"@hubspot/logo\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "img"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "画像"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["次のフィールドを含む画像オブジェクト：", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_inherited_src"
                }), "：設定されている既定のロゴをオーバーライドします。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), "：画像のURL。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), "：ロゴの代替テキスト。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"override_inherited_src\": false, \"src\": null, \"alt\": null }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ロゴのリンク（オプション）。URLが指定されていない場合、ロゴがリンクする", (0, _jsxRuntime.jsx)(_components.em, {
              children: "ロゴURL"
            }), "は、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/branding/edit-your-logo-favicon-and-brand-colors#edit-your-logo",
              children: "ブランド設定"
            }), "で設定されているものです。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "open_in_new_tab"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "新しいタブでリンクを開くかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "suppress_company_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "画像が選択されていない場合に会社名を非表示にするかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["選択されている画像がなく、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "suppress_company_name"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "に設定されている場合の見出しのレベルを選択します。選択肢は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), "から", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "までです。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ロゴグリッド"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "コンテナーのカスタマイズ可能なグリッドで、ロゴを均一に表示できます。ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"logo grid\"\n  path=\"@hubspot/logo_grid\"\n  group_logos=[\n    {\n      \"logo\": {\n        \"loading\": \"lazy\",\n        \"alt\": \"company_logo\",\n        \"src\": \"https://www.example.com/Logos/color/logo.png\"\n      }\n    },\n    {\n      \"logo\": {\n        \"loading\": \"lazy\",\n        \"alt\": \"company2_logo\",\n        \"src\": \"https://www.example.com/Logos/color/logo2.png\"\n      }\n    },\n    {\n      \"logo\": {\n        \"alt\": \"lorem-logo\",\n        \"height\": 40,\n        \"loading\": \"lazy\",\n        \"max_height\": 40,\n        \"max_width\": 175,\n        \"src\": \"https://www.example.com/Logos/color/logo3.png\",\n        \"width\": 175\n      }\n    }\n  ],\n  styles={\n    \"group_logo\": {\n      \"group_background\": {\n        \"aspect_ratio\": \"1/1\",\n        \"background_color\": {\n          \"color\": \"#8E7CC3\",\n          \"opacity\": 100\n        }\n      },\n      \"group_spacing\": {\n        \"padding\": {\n          \"padding\": {\n            \"bottom\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"left\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"right\": {\n              \"units\": \"px\",\n              \"value\": 75\n            },\n            \"top\": {\n              \"units\": \"px\",\n              \"value\": 75\n            }\n          }\n        }\n      },\n      \"max_logo_height\": 85\n    },\n    \"group_logo_grid\": {\n      \"column_count\": 3,\n      \"grid_gap\": 54\n    }\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_logos"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "グリッド内の各ロゴのオブジェクトからなる配列。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "logo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "group_logos"
            }), "に含まれる、グリッド内の各ロゴのオブジェクト。各ロゴには、次のパラメーターを含めることができます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "src"
                }), "：ロゴのファイルパス。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "loading"
                }), "ロゴの", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/cms/content/performance/lazy-loading",
                  children: "遅延読み込み"
                }), "動作。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "alt"
                }), "：ロゴの代替テキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "height"
                }), "：ロゴの高さ。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "width"
                }), "：ロゴの幅。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "max_height"
                }), "：ロゴの最大高さ。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "max_width"
                }), "：ロゴの最大幅。"]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "styles"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "配列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["グリッドレイアウト、ロゴコンテナー、およびロゴ画像に影響するスタイルフィールドを含む配列。この配列には、次のスタイルグループが含まれています。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_logo"
                }), "：ロゴコンテナーとロゴ画像に影響するスタイル。次のフィールドが含まれます。", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "group_background"
                    }), "：グリッドコンテナーの縦横比と背景色を設定するスタイル。縦横比のオプションには、", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "1/1"
                    }), "、", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "4/3"
                    }), "、", (0, _jsxRuntime.jsx)(_components.code, {
                      children: "16/9"
                    }), "があります。"]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "group_spacing"
                    }), "：ロゴコンテナーの内側のパディングを設定するスタイル。幅の広いロゴの場合、パディングの値を大きくすると、ロゴの幅が狭くなります。"]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "max_logo_height"
                    }), "：各ロゴの最大高さ。"]
                  })]
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "group_logo_grid"
                }), "：グリッドのレイアウトを設定するスタイル。次のフィールドが含まれます。", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "column_count"
                    }), "：グリッドの列の数。"]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "grid_gap"
                    }), "：グリッド項目間の間隔。"]
                  })]
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ミーティング"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログ記事、ブログリストでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"meetings\" path=\"@hubspot/meetings\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "meeting"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ミーティング"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ミーティングリンクを選択します。"
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ソーシャルログインのアクセス権設定"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "このモジュールは、アクセス権設定を利用可能なウェブサイトにGoogleおよびFacebookを介してログインする機能を提供します。サインイン時は、CRM内のコンタクトのEメールアドレスに結び付いたアカウントを使用する必要があります。有効にするソーシャルログインを選択できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "アクセス権設定を利用可能なログインページでサポートされています。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social\" path=\"@hubspot/membership_social_logins\",\n        clientid=\"\"\n        appid=\"\"\n        facebook_enabled=true\n        google_enabled=true\n        %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Facebookを使用する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.facebook.com/docs/development/register",
          children: "Facebook開発者アカウント"
        }), "と、基本的な設定を使用して", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.facebook.com/docs/development/create-an-app",
          children: "作成したFacebookアプリ"
        }), "が必要です。これらの要件を満たした上で、作成したアプリのIDをモジュールに渡します。"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Googleを使用する場合は、Googleアカウントと", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/identity/sign-in/web/sign-in#create_authorization_credentials",
          children: "認証情報の作成"
        }), "が必要になります。これらの要件を満たした上で、アプリのクライアントIDをモジュールに渡します。"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_appid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "FacebookアプリのID。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Facebookログインのボタンを有効にするかどうかを指定します。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook_appid"
            }), "が必要です。****"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_clientid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GoogleクライアントのID。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_enabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Googleログインのボタンを有効にするかどうかを指定します。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "google_clientid"
            }), "が必要です。****"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メニュー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログ記事、ブログリストでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["独自のカスタムメニューを作成する場合は、HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#menu",
        children: "menu()関数"
      }), "をお試しください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"menu\" path=\"@hubspot/menu\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メニュー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メニューのID。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "root_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["詳細メニュータイプ。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "site_root"
                }), "：常に最上位ページをメニューに表示します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "top_parent"
                }), "：表示中のセクションに関連するページをメニューに表示します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "parent"
                }), "：表示中のページに関連するページをメニューに表示します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "breadcrumb"
                }), "：パンくず形式のパスメニュー（横フローを使用）。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "site_root"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_levels"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["メニューで展開可能なメニューツリーの子の数を指定します。選択肢は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), "から", (0, _jsxRuntime.jsx)(_components.code, {
              children: "10"
            }), "までです。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "2"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flow"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["メニューの方向。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "horizontal"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "vertical"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "flyouts"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "子メニュー項目にカーソルを合わせたときに詳細表示を有効にするかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ページフッター"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"page_footer\" path=\"@hubspot/page_footer\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "パスワードプロンプト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログ記事、ブログリストでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"password_prompt\" path=\"@hubspot/password_prompt\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submit_button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "送信ボタンに表示されるテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Submit\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "password_placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "パスワードフィールドのプレースホルダーテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Password\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "bad_password_message"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リッチテキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "パスワードが正しく入力されていない場合に表示されるメッセージ。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Sorry, please try again. \""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "支払い"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログ記事、ブログリストでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"payments\" path=\"@hubspot/payments\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["特定の支払いリンクを使用するようにモジュールを設定するには、支払いリンクのIDを含めます。支払いリンクを編集する際に、URLで", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#link#find-a-payment-link-s-id",
              children: "このIDを確認"
            }), "できます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout_location"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["支払いフォームを新しいタブで開くか、オーバーレイで開くかどうかを設定します。有効な値は次のとおりです。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "new_tab"
                }), "：新しいタブで支払いフォームを開きます。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "overlay"
                }), "：スライド式のオーバーレイで支払いフォームを開きます。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"new_tab\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ご注文の確認ボタンのテキストを設定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Checkout\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_target"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ボタンでHubSpot支払いリンクと外部リンクのどちらを使用するかを設定します。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "payment_link"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"payment_link\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リンク"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "button_target"
            }), "を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_link"
            }), "に設定した場合、外部リンクの宛先を設定します。次のリンクタイプがサポートされています。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "EXTERNAL"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTENT"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "製品"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アカウントの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/products/how-do-i-use-products",
        children: "製品ライブラリー"
      }), "に含まれている製品が表示されます。ページ、ブログ記事、ブログリストでサポートされています。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module\n  path=\"@hubspot/product\",\n  product={\n    \"id\" : 2124070179\n  },\n  group_button={\n    \"button_text\" : \"Buy\",\n    \"override_product_button\" : true,\n    \"button_override\" : {\n      \"no_follow\" : false,\n      \"open_in_new_tab\" : false,\n      \"sponsored\" : false,\n      \"url\" : {\n        \"href\" : \"https://www.test.com\",\n        \"type\" : \"EXTERNAL\"\n      }\n  },\n  group_description={\n    \"description_override\" : \"Monthly gym membership with access to shared locker facilities.\",\n    \"override_product_description\" : true\n  },\n  group_name={\n    \"heading_level\" : \"h3\",\n    \"name_override\" : \"Gym membership\",\n    \"override_product_name\" : true\n  },\n  group_image={\n    \"image_override\" : {\n      \"alt\" : \"360_F_317724775_qHtWjnT8YbRdFNIuq5PWsSYypRhOmalS\",\n      \"height\" : 360,\n      \"loading\" : \"lazy\",\n      \"src\" : \"https://2272014.fs1.hubspotusercontent-na1.net/hubfs/2272014/360_F_317724775_qHtWjnT8YbRdFNIuq5PWsSYypRhOmalS.jpg\",\n      \"width\" : 640\n    },\n    \"override_product_image\" : true\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "製品"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "表示する製品を製品IDで指定します。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィールドグループ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["既定では、このモジュールには製品に設定されているURLにユーザーを誘導するためのボタンが表示されます。このボタンによる誘導先をカスタマイズするには、このオプションのフィールドグループと併せて次のフィールドを含めます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_text"
                }), "：ボタンのテキストを設定する文字列。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_button"
                }), "：既定のボタンリンク設定をオーバーライドする場合は、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "に設定します。さらに、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_override"
                }), "オブジェクトを含めます。詳細については、以下の", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "button_override"
                }), "をご覧ください。"]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_override"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "group_button"
            }), "フィールドグループで、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "override_product_button"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定されている場合、このパラメーターによってボタンのURL動作が設定されます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "no_follow"
                }), "：リンクの", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://ahrefs.com/blog/nofollow-links",
                  children: "no_follow"
                }), "動作を指定するブール型フィールド。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "open_link_in_new_tab"
                }), "：リンクの開く動作を指定するブール型フィールド。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "url"
                }), "：ボタンの誘導先を指定するオブジェクト。"]
              })]
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            }), "フィールドでは、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            }), "フィールドを使用して誘導先のタイプを設定できます。このフィールドは、次のコンテンツタイプでサポートされています。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "EXTERNAL"
                }), "：標準URL。URLは、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), "フィールドに含めます。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "CONTENT"
                }), "：HubSpotページ。ページIDは、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "content_id"
                }), "フィールドに含めます。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "PAYMENT"
                }), "：支払いリンク。支払いリンクは、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), "フィールドに含めます。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "EMAIL_ADDRESS"
                }), "：Eメールアドレス。Eメールアドレスは、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "href"
                }), "フィールドに含めます。"]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィールドグループ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["既定では、このモジュールの上部にh3として製品名が表示されます。名前をカスタマイズするには、このオプションのフィールドグループと併せて次のフィールドを含めます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "heading_level"
                }), "：見出しのサイズ。", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "h1"
                }), " - ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "h6"
                }), "のいずれかを指定できます。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_name"
                }), "：製品名ではなくテキストを指定する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "に設定します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "name_override"
                }), "：モジュールの上部に表示する文字列。"]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィールドグループ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["既定では、このモジュールには製品に設定されている説明が表示されます。説明をカスタマイズするには、このオプションのフィールドグループと併せて次のフィールドを含めます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_description"
                }), "：製品の説明をカスタマイズする場合は、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "に設定します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description_override"
                }), "：新しい説明を含む文字列。"]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "group_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィールドグループ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["既定では、このモジュールには製品に設定されている画像が表示されます。この画像をカスタマイズするには、このオプションのフィールドグループと併せて次のフィールド含めます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "override_product_image"
                }), "：新しい画像を指定する場合は、", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "に設定します。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "image_override"
                }), "：新しい画像を指定するオブジェクト。このオブジェクトには次のプロパティーが含まれています。", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "alt"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "height"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "loading"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "src"
                    })
                  }), (0, _jsxRuntime.jsx)("li", {
                    children: (0, _jsxRuntime.jsx)(_components.code, {
                      children: "width"
                    })
                  })]
                })]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "見積もりのダウンロード"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "見積もりテンプレートでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"download\" path=\"@hubspot/quote_download\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ダウンロードボタンに表示されるテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Download"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "download_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ダウンロードに失敗した場合に表示されるエラーメッセージ。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem downloading the quote. Please try again."
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "見積もりの支払い"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "見積もりテンプレートでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"payment\" path=\"@hubspot/quote_payment\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりテンプレートの支払いセクションの上に表示される見出し。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_tag"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_text"
            }), "を表示するために使用する見出しのタイプ。有効な値は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h2"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h3"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h4"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h5"
            }), "、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "です。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h3"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "支払いボタンのテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Pay now"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "needs_signature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "署名が必要な場合のボタンテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment can't be made because the quote isn't fully signed."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "checkout_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リッチテキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "注文手続き中にエラーが発生した場合に表示されるメッセージ。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem setting up checkout. Please contact the person who sent you this quote."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment_status_error"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リッチテキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "支払いを試行中にエラーが発生した場合に表示されるメッセージ。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem loading the payment status for this quote. Please try refreshing the page."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "paid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "支払いが正常に完了したことを示すステータスインジケーター。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Paid"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "payment_processing"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "支払いが処理中であることを示すステータスインジケーター。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Payment processing"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "見積もりの署名"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "見積もりテンプレートでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"signature\" path=\"@hubspot/quote_signature\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["見積もりに電子署名が必要な場合は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "esignature"
      }), "オブジェクトに含まれる次のフィールドを使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pretext"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リッチテキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "電子署名の説明テキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Before you sign this quote, an email must be sent to you to verify your identity. Find your profile below to request a verification email."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "verify_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "確認ボタンに表示されるテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Verify to sign"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "failure"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "署名情報を取得できない場合に表示されるアラートテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "There was a problem retrieving the signature information. Please reload the page."
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "verification_sent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "認証リクエストが見積もり署名者に正常に送信されると表示されるステータスインジケーター。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Verification sent"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "signed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "見積もりが正常に署名されると表示されるテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signed"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["見積もりに印刷された署名が必要な場合、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "print_signature"
      }), "オブジェクトに含まれる次のフィールドを使用できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リッチテキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "署名セクションの上部に表示されるテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signature"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "signature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "署名フィールドの横に表示されるテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signature"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "日付フィールドの横に表示されるテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Date"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "printed_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "印刷された名前フィールドの横に表示されるテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Printed name"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "countersignature"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["オブジェクト連署セクションのコンテンツを含むオブジェクト。このオブジェクトには、次のフィールドを含めることができます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "header"
                }), "：連署セクションの上部に表示されるテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "countersignature"
                }), "：連署フィールドの横に表示されるテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "date"
                }), "：日付フィールドの横に表示されるテキスト。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "printed_name"
                }), "：印刷された名前フィールドの横に表示されるテキスト。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Signed"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "商品項目"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "見積もりテンプレートでサポートされています。このモジュールには、カスタム見積もりで使用される既定のテキストも含まれています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"module_165350106057652\" path=\"@hubspot/line_items\", label=\"line_items.module\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目列のタイトル。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Column description"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "use_additional_product_property"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザーが製品のプロパティーから追加の商品項目列を選択するために使用できるチェックボックスを表示するかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "additional_product_properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["製品プロパティー。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "price"
                }), "：商品項目の価格。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "item_description"
                }), "：商品項目の説明。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "quantity"
                }), "：商品項目の数。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "amount"
                }), "：商品項目の合計金額。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hs_recurring_billing_start_date"
                }), "：定期的な商品項目の請求開始日。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "discount"
                }), "：商品項目に適用される割引金額。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm_product_property"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CRMオブジェクトプロパティー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "商品項目列のヘッダーとして表示する製品プロパティーを選択します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "リッチテキスト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "全てのテンプレートタイプでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"rich_text\" path=\"@hubspot/rich_text\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "リッチテキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HTMLブロック。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "  ## Something Powerful  \\n  ### Tell The Reader More  \\n  The headline and subheader tells us what you're [offering](\\\"#\\\"), and the form header closes the deal. Over here you can explain why your offer is so great it's worth filling out a form for.  \\n  Remember:  \\n<ul>\\n<li>Bullets are great</li>\\n<li>For spelling out [benefits](\\\"#\\\") and</li>\\n<li>Turning visitors into leads.</li>\\n</ul>"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "RSSリスティング"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"rss_listing\" path=\"@hubspot/rss_listing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "H3見出し。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Test\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["RSSフィードのタイプ。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog"
                }), " - HubSpotでホスティングされているブログ。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "external"
                }), " - 外部のRSSフィード。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "external"
            }), "の場合に使用するRSS URL。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_group_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブログ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            }), "の場合に使用するブログのID。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "topic_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "タグ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["（オプション）", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rss_feed_type"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            }), "の場合にフィルター基準として使用するタグのID。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "number_of_items"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "表示する記事の最大数。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "5"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "include_featured_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "キービジュアルを含めるかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "執筆者名を表示するかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attribution_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["執筆者を記事に関連付けるテキスト。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_author"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "の場合に表示されます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"by\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "記事のサマリーテキストを表示するかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "limit_to_chars"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "の場合のサマリーテキストの長さを制限します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "200"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "click_through_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "show_detail"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "の場合に、記事サマリーの最後にあるクリックスルーリンクに表示されるテキスト。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Read more\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "公開日を表示するかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish_date_format"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "の場合に使用する、公開日の形式。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "short"
                }), "（例：06/11/06 12:00PM）"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "medium"
                }), "（例：Jun 6, 2006 12:00:00 pm）"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "long"
                }), "（例：June 6, 2017 12:00:00 pm EDT）"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "MMMM d, yyyy 'at' h:mm a"
                }), "（例：June 6, 2006 at 12:00 pm）"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "h:mm a 'on' MMMM d, yyyy"
                }), "（例：12:00 pm on June 6, 2006）"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "short"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "publish_date_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "show_date"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "の場合に使用する、記事の公開日時を示すテキスト。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"posted at\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サイト検索入力"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログ記事、ブログリストでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_input\" path=\"./local-search_input\"\n  placeholder=\"Search\"\n  include_search_button=true\n  results={\n   \"use_custom_search_results_template\": \"true\",\n   \"path_id\": \"77977569400\"\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "field_label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "検索入力のラベルテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "入力フィールドのプレースホルダーテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Search\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "include_search_button"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "検索ボタンを含めるかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["検索結果に含めるコンテンツのタイプを指定します。このオブジェクトには、ブール値を設定する次のキーが格納されます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "website_pages"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "landing_pages"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "blog_posts"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "knowledge_articles"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"website_pages\": true, \"landing_pages\": false, \"blog_posts\": true, \"knowledge_articles\": false }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "results"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["カスタム検索結果ページを使用するためのコントロールを有効にするオブジェクト。次のプロパティーが含まれます。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "use_custom_search_results_template"
                }), (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "（ブール値）："
                }), " このブール値を", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "に設定すると、ユーザーがカスタム検索結果ページを選択できるようになります。既定値は", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), "です。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "path_id"
                }), "**（文字列）：**検索結果に使用するページのID。ここで参照するページには、検索結果モジュールが含まれている必要があります。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "検索結果"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログ記事、ブログリストでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"search_results\" path=\"@hubspot/search_results\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "display_featured_images"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アイテムの特集項目を表示するかどうかを指定します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "セクションヘッダー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"section_header\" path=\"@hubspot/section_header\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "セクションヘッダーのコンテンツ。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A clear and bold header\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "heading_level"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "header"
            }), "の見出しレベル。選択肢は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            }), "から", (0, _jsxRuntime.jsx)(_components.code, {
              children: "h6"
            }), "までです。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "h1"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "subheader"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "セクションの小見出しの段落のテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"A more subdued subheader\""
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "シンプルメニュー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"simple_menu\" path=\"@hubspot/simple_menu\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "menu_tree"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "シンプルメニュー"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "シンプル メニュー オブジェクト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "orientation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["メニューの方向。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "horizontal"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "vertical"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ソーシャルフォロー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ソーシャルメディアのプロフィールへのリンクを追加して、コンテンツエディターでドラッグ＆ドロップ操作によってリンクを並べ替えることができます。ソーシャルURLに基づいて自動的にアイコンが挿入されますが、これらのアイコンはオーバーライドできます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_follow\" path=\"@hubspot/social_follow\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "エディターで追加したソーシャルページのリンク先。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "open_in_new_window"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["このブール値を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "に設定すると、リンクが同じブラウザータブで開きます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_podcast"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["このブール値を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定すると、既定のアイコンやカスタムアイコンではなく、ポッドキャストアイコンが表示されます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_alt_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["既定では、代替テキストが自動的に生成されます。このブール値を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定すると、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "aria_label"
            }), "フィールドを使用して、既定の代替テキストをカスタムの値でオーバーライドできます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "aria-label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_alt_text"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "の場合、このフィールドでアイコンの代替テキストを設定します（例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Follow us on Facebook"
            }), "）。この説明は、スクリーンリーダーが目の不自由なユーザーにアイコンについて説明するために使用されます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["既定では、アイコンはリンクURLに基づいて自動入力されます。このブール値を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "に設定すると、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_icon"
            }), "フィールドを使用して、提供されている他のアイコン（Font Awesome 6.4.2）のいずれかを選択できます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "custom_icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "アイコン"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "customize_icon"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "で、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_podcast"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "の場合、このフィールドを使用して、提供されているアイコンのセットからカスタムアイコンを指定します。使用可能なアイコンは、Font Awesome 6.4.2で提供されているものです。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "ソーシャルシェアリング"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_sharing\" path=\"@hubspot/social_sharing\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["注：以下に示されている既定の列に含まれる変数", (0, _jsxRuntime.jsx)(_components.code, {
        children: "social_link_url"
      }), "の値は、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "link"
      }), "パラメーターの値と同じです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "この宛先リンクは、ソーシャルネットワークで簡単に共有できるように短縮されることになります。"
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "facebook"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["このオブジェクトには次のフィールドが含まれます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), "：ソーシャルアイテムを有効にするためのブール値。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), "：ソーシャル共有者のURLとしてのカスタムURL。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://www.facebook.com/share.php?u={{ social_link_url }}\" }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "twitter"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["このオブジェクトには次のフィールドが含まれます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), "：ソーシャルアイテムを有効にするためのブール値。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), "：ソーシャル共有者のURLとしてのカスタムURL。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)("code", {
              children: ["{ \"enabled\": false, \"custom_link_format\": \"", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://twitter.com/intent/tweet?original_referer=%5C%7B%5C%7B",
                children: "https://twitter.com/intent/tweet?original_referer=\\{\\{"
              }), " social_link_url }}&url={{ social_link_url }}&source=tweetbutton&text={{ social_page_title|urlencode }}\" }"]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "linkedin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["このオブジェクトには次のフィールドが含まれます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), "：ソーシャルアイテムを有効にするためのブール値。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), "：ソーシャル共有者のURLとしてのカスタムURL。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://www.linkedin.com/shareArticle?mini=true&url={{ social_link_url }}\" }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pinterest"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["このオブジェクトには次のフィールドが含まれます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), "：ソーシャルアイテムを有効にするためのブール値ソーシャルアイテムを有効にするためのブール値。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), "：ソーシャル共有者のURLとしてのカスタムURL。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "pinterest_media"
                }), "：次のフィールドを含む画像オブジェクト。", (0, _jsxRuntime.jsxs)("ul", {
                  children: [(0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "src"
                    }), "：画像のURL。"]
                  }), (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "alt"
                    }), "：画像の代替テキスト。"]
                  })]
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"http://pinterest.com/pin/create/button/?url={{ social_link_url }}&media={{ pinterest_media }}\", \"pinterest_media\": { \"src\": \"\", \"alt\": null } }"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["このオブジェクトには次のフィールドが含まれます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "enabled"
                }), "：ソーシャルアイテムを有効にするためのブール値。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "custom_link_format"
                }), "：ソーシャル共有者のURLとしてのカスタムURL。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{ \"enabled\": false, \"custom_link_format\": \"mailto:?subject=Check out {{ social_link_url }} &body=Check out {{ social_link_url }}\" }"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "タブ付きカード"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module\n  path=\"@hubspot/tabbed_card\",\n  tabs=[\n   {\n    \"content\" : \"<p>This is the descriptive text contained in tab 1.</p>\",\n    \"tab_label\" : \"Tab 1 label\"\n   },\n   {\n    \"content\" : \"<p>This is the descriptive text contained in tab 2.</p>\",\n    \"tab_label\" : \"Tab 2 label\"\n   }\n  ],\n  fixed_height=false\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tabs"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "フィールドグループ"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["各タブのタブラベルとテキストコンテンツを含むフィールドグループ。次のフィールドがあります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "tab_label"
                }), "：タブのラベル。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "content"
                }), "：タブのリッチ テキスト コンテンツ。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Some additional information in one line\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fixed_height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["既定では、タブを切り替えてもコンテナーの高さが変わらないように、タブの高さは固定されます。このブール値を", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "に設定すると、タブ付きカードコンテナーの高さが、アクティブなタブのコンテンツに応じて調整されます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1行テキスト"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"text\" path=\"@hubspot/text\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このパラメーターにテキストを追加します。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"Some additional information in one line\""
            })
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "動画"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページ、ブログリスト、ブログ記事でサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"video\" path=\"@hubspot/video\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["動画のタイプ。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "embed"
                }), "：外部ソースからコードを埋め込みます。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "hubspot_video"
                }), "：HubSpotでホスティングされている動画。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_video"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "動画プレーヤー"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["HubSpotでホスティングされている動画。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_video"
            }), "に設定されている場合に使用されます。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed_field"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "埋め込み"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["次の埋め込みタイプを使用できます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "oembed"
                }), (0, _jsxRuntime.jsx)("ul", {
                  children: (0, _jsxRuntime.jsxs)("li", {
                    children: [(0, _jsxRuntime.jsx)(_components.code, {
                      children: "video"
                    }), "：動画のURL。"]
                  })
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "html"
                }), "：動画の埋め込みコード。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed_thumbnail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "画像"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "video_type"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed"
            }), "に、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "embed_field"
            }), "が", (0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed"
            }), "に設定されている場合に、埋め込みサムネイル画像をオーバーライドします。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "style_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "oembed_thumbnail_play_button_color"
            }), " - 色フィールドを含むオブジェクト。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"oembed_thumbnail_play_button_color\":\"#ffffff\"}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder_fields"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "オブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["このオブジェクトには次のフィールドが含まれます。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "placeholder_title"
                }), "：テキストフィールド。"]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "placeholder_description"
                }), "：テキストフィールド。"]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"placeholder_title\":\"No video selected\", \"placeholder_description\":\"Select a video type in the sidebar.\"}"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "動画埋め込み（ランディングページ）"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページでサポートされています。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"video_embed_lp\" path=\"@hubspot/video_embed_lp\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "テキスト"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "動画のURL。VimeoとYouTubeのURLがサポートされています。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://www.youtube.com/watch?v=X1Rr5BFO5rg\""
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_full_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ブール値"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["動画を全幅に設定（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "）、または手動で幅と高さを設定（", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "）するかを指定します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "最大幅（px）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "800"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_height"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "最大高さ（px）"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "450"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "WhatsAppリンク"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ページ、ブログ記事、ブログ リスト ページでサポートされています。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/inbox/connect-whatsapp-to-the-conversations-inbox",
        children: "WhatsAppチャネルの接続"
      }), "が必要です。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"whatsapp_link\" path=\"@hubspot/whatsapp_link\", label=\"whatsapp_link\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "型"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "既定値"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_number"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/inbox/connect-whatsapp-to-the-conversations-inbox",
              children: "アカウントに接続されているWhatsAppチャネル"
            }), "の中から選択します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "optin_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/inbox/collect-consent-for-whatsapp-conversations#subscribe-a-contact-using-opt-in-keywords-in-a-whatsapp-thread",
              children: "オプトインとオプトアウトのテキスト"
            }), "。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_display"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ボタンにテキストまたはWhatsAppアイコンのいずれかを表示するか、あるいはこの両方を表示するかを選択します。次の選択肢があります。", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "text_and_icon"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "text"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "icon"
                })
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text_and_icon"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon_position"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "選択肢"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ボタン上のアイコンの位置。選択肢には、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "left"
            }), "と", (0, _jsxRuntime.jsx)(_components.code, {
              children: "right"
            }), "があります。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "left"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "button_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ボタンのテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Chat on WhatsApp"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "whatsapp_icon_title"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "スクリーンリーダー用のアイコンのテキスト。"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WhatsApp Icon"
            })
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}