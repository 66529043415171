"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76425848705;
const slug = exports.slug = 'guides/apps/private-apps/overview';
const title = exports.title = 'Aplicativos privados';
const name = exports.name = 'LATAM BR (PT) Private Apps';
const metaDescription = exports.metaDescription = 'Saiba como criar aplicativos privados no HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "criar-um-aplicativo-privado",
  "label": "Criar um aplicativo privado",
  "parent": null
}, {
  "depth": 0,
  "id": "fazer-chamadas-de-api-com-seu-token-de-acesso",
  "label": "Fazer chamadas de API com seu token de acesso",
  "parent": null
}, {
  "depth": 0,
  "id": "visualizar-informa%C3%A7%C3%B5es-do-token-de-acesso-do-aplicativo-privado",
  "label": "Visualizar informações do token de acesso do aplicativo privado",
  "parent": null
}, {
  "depth": 0,
  "id": "revezar-token-de-acesso",
  "label": "Revezar token de acesso",
  "parent": null
}, {
  "depth": 0,
  "id": "exibir-logs-de-chamadas-de-api",
  "label": "Exibir logs de chamadas de API",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-de-aplicativos-privados",
  "label": "Limites de aplicativos privados",
  "parent": null
}, {
  "depth": 0,
  "id": "excluir-um-aplicativo-privado",
  "label": "Excluir um aplicativo privado",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      img: "img",
      pre: "pre",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Private apps"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os aplicativos privados permitem usar as APIs da HubSpot para acessar dados específicos da sua conta da HubSpot. Você pode autorizar o que cada aplicativo privado pode solicitar ou alterar em sua conta, o que gerará um token de acesso exclusivo para seu aplicativo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você precisa ser um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "superadministrador"
      }), " para acessar os aplicativos privados na sua conta da HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " aplicativos privados não oferecem suporte a eventos de linha do tempo personalizados. Os webhooks são ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
          children: "compatíveis com aplicativos privados"
        }), ", mas as assinaturas não podem ser editadas programaticamente por meio de uma API e, em vez disso, devem ser editadas nas configurações do aplicativo privado."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Se você planeja criar um aplicativo que usa eventos de linha do tempo personalizados, crie um aplicativo público. Saiba mais sobre a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "diferença entre aplicativos privados e públicos"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar um aplicativo privado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ícone de configurações"
        }), " na barra de navegação principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No menu lateral esquerdo, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrações"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicativos privados"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar aplicativo privado"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Informações básicas"
        }), ", configure os detalhes do aplicativo:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Insira o ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "nome"
            }), " do aplicativo."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Passe o cursor do mouse sobre o logotipo de espaço reservado e clique ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "no ícone de upload"
            }), " para carregar uma imagem quadrada que servirá como logotipo do seu aplicativo."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Insira uma ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "descrição"
            }), " para o aplicativo."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Escopos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Marque a caixa de seleção ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Leitura"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Gravação"
        }), " para cada escopo que você deseja que seu aplicativo privado possa acessar. Você também pode pesquisar um escopo específico usando a barra de pesquisa ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Encontrar um escopo"
        }), ". Você pode revisar uma lista completa dos escopos disponíveis ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/other-resources/scopes",
          children: "neste artigo de referência"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Quando terminar de configurar seu aplicativo, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Criar aplicativo"
        }), " no canto superior direito."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/updated-read-write-scope-config-private-apps.png",
        alt: "updated-read-write-scope-config-private-apps"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na caixa de diálogo, revise o as informações sobre o token de acesso do seu aplicativo e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Continuar criando"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de criar seu aplicativo, você pode começar a fazer chamadas de API usando o token de acesso do aplicativo. Se você precisar editar as informações do seu aplicativo ou alterar os escopos, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Editar detalhes"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/edit-details-of-private-app.png",
        alt: "edit-details-of-private-app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fazer chamadas de API com seu token de acesso"
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " os aplicativos privados perderão o acesso aos escopos quando sua conta da HubSpot sofrer o downgrade e perder o acesso à funcionalidade. Por exemplo, se a sua conta não tiver acesso ao HubDB, o seu aplicativo privado não terá acesso ao escopo do HubDB."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Os tokens de acesso ao aplicativo privado serão atualizados para refletir os escopos disponíveis na sua conta da HubSpot e o que você configurou para o aplicativo privado, mas a string do token não será alterada."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para começar a fazer chamadas de API, acesse a página de detalhes do aplicativo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No cartão ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Token de"
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "acesso"
        }), ", clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mostrar token"
        }), " para revelar seu token de acesso. Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copiar"
        }), " para copiar o token para a área de transferência."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-1.png",
        alt: "show-private-app-access-token-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Você pode colar o token de acesso para fornecê-lo aos desenvolvedores ou usá-lo para desenvolver seu aplicativo. Ao fazer uma chamada para um dos pontos de extremidade de API da HubSpot, defina o valor do campo ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Autorização"
        }), " como ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Portador [YOUR_TOKEN]"
        }), ". Por exemplo, se você estiver fazendo uma chamada para a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "API de contatos"
        }), " usando Node.js e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), ", a solicitação será parecida com a seguinte:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "axios.get(\n  'https://api.hubapi.com/crm/v3/objects/contacts',\n  {\n    headers: {\n      Authorization: `Bearer ${YOUR_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os tokens de acesso para aplicativos privados são implementados na OAuth, para que você também possa fazer chamadas autenticadas usando uma das bibliotecas de cliente do HubSpot. Por exemplo, se você estiver usando a", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/hubspot-api-nodejs",
          children: "biblioteca de clientes Node.js"
        }), ", poderá iniciar um cliente OAuth passando o token de acesso do seu aplicativo:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspotClient = new hubspot.Client({ accessToken: YOUR_ACCESS_TOKEN });\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Visualizar informações do token de acesso do aplicativo privado"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver informações sobre o token de acesso de um aplicativo privado, como o Hub ID e os escopos associados ao token, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/oauth/v2/private-apps/get/access-token-info"
      }), ". No corpo da solicitação, inclua o token de acesso:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request response body\n{\n  \"tokenKey\": {{accessToken}}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A resposta incluirá informações sobre o usuário que criou o token, o Hub ID da conta, o ID do aplicativo privado e os escopos associados ao token."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response body\n{\n  \"userId\": 123456,\n  \"hubId\": 1020304,\n  \"appId\": 2011410,\n  \"scopes\": [\"oauth\", \"crm.schemas.companies.write\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Revezar token de acesso"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se o token de acesso for perdido ou comprometido, você poderá revezá-lo. Um novo token de acesso será criado e o original expirará."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, clique na página ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configurações"
        }), " na barra de navegação principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integrações"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicativos privados"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " do seu aplicativo privado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao lado do token de acesso, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Revezar"
        }), ":", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se seu token for comprometido e você quiser revogar o acesso imediatamente, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Revezar e expirar agora"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Se não houver ameaça iminente ao seu token, ainda é recomendável revezar seu token a cada seis meses. Se você estiver pronto para iniciar um revezamento regular do seu token, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Revezar e expirar mais tarde"
            }), ", o que acionará a expiração do token em 7 dias.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Se seu aplicativo já estiver pronto para fazer a transição, você pode clicar em ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Expirar agora"
                }), "."]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Se você decidir que precisa de mais tempo, pode clicar em ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Cancelar revezamento"
                }), ", que cancelará a expiração do token original e revogará o novo token de acesso."]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/rotate-private-app-access-token.png",
        alt: "rotate-private-app-access-token"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubSpot também enviará notificações por e-mail aos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "superadministradores"
      }), " com lembretes sobre o status de revezamento do token de acesso, bem como outros alertas relacionados. Os superadministradores na sua conta da HubSpot receberão notificações para os seguintes eventos e lembretes:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um superadministrador iniciou um revezamento (imediatamente ou agendado para daqui a sete dias)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Um superadministrador cancelou um revezamento pendente."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Um superadministrador optou por expirar um token de acesso imediatamente, clicando em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Expirar agora"
        }), ", em vez de esperar sete dias para a expiração do token."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O token de acesso do aplicativo está prestes a expirar em 24 horas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O token de acesso do aplicativo teve revezamento e expirou após sete dias."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se não tiver revezado o seu token de acesso em mais de 180 dias, os superadministradores também receberão um e-mail de lembrete para revezar o token de acesso do seu aplicativo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Exibir logs de chamadas de API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para revisar as chamadas de API que seu aplicativo fez nos últimos 30 dias:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na página de detalhes do aplicativo, clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Logs"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revise e filtre suas chamadas de API do aplicativo privado:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Clique nos menus suspensos ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Método"
            }), " e ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Resposta"
            }), " para filtrar suas chamadas de API históricas por método de solicitação ou código de resposta."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "data de início"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "data de término"
            }), " para limitar os logs de chamada a um intervalo de tempo específico."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Você também pode pesquisar chamadas específicas por URL na caixa de pesquisa ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Pesquisar por URL de solicitação"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/private-app-logs-tab-updated.png",
        alt: "private-app-logs-tab-updated"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para exportar os registros das chamadas de API, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportar registros (CSV)"
        }), ". Em seguida, especifique um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "intervalo de datas"
        }), " (até os últimos 30 dias) e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportar"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na caixa pop-up, selecione o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "intervalo de datas"
        }), " das chamadas de API que deseja exportar e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportar"
        }), ". Você receberá um e-mail com um link de download quando a exportação estiver pronta."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites de aplicativos privados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode criar até 20 aplicativos privados na sua conta da HubSpot. Cada aplicativo privado está sujeito às ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "diretrizes de uso de APIs da HubSpot"
      }), ". O número de chamadas que seu aplicativo privado pode fazer se baseia na assinatura da sua conta e se você comprou o complemento de API:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {}), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Nível de produto"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Por 10 segundos"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Por dia"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicativos privados"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Qualquer Hub)Gratuito e Starter"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "100/aplicativo privado"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "250.000/conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Qualquer Hub)Pro e Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "150/aplicativo privado"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "500.000/conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicativos privados com o complemento de API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Qualquer Hub)Gratuito, Starter, Professional e Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "200/aplicativo privado"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1.000.000/conta"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você tiver um plano Starter e Professional, os limites para o nível mais alto (Professional) se aplicam à sua conta."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode enviar uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/account-info/v3/api-usage/daily/private-apps"
      }), " para revisar o uso diário da API para todos os aplicativos privados em sua conta da HubSpot. Saiba mais sobre como usar a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/account-information-api",
        children: "API de informações da conta"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Excluir um aplicativo privado"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Quando você exclui um aplicativo privado, seu token de acesso é revogado permanentemente e você não pode mais usá-lo para fazer chamadas de API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para excluir um aplicativo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ícone de configurações"
        }), " na barra de navegação principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " do seu aplicativo privado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior da página, clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Autenticação"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na parte inferior da página, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Excluir [nome do aplicativo]"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na caixa de diálogo, digite o nome do seu aplicativo para confirmar a exclusão e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Excluir"
        }), "."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}