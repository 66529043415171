"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694125;
const slug = exports.slug = 'guides/cms/content/themes/overview';
const title = exports.title = "Vue d'ensemble du thème";
const name = exports.name = "Vue d'ensemble du thème";
const metaDescription = exports.metaDescription = 'Développer des thèmes de CMS HubSpot qui permettent aux développeurs de construire des systèmes de conception dans lesquels les créateurs de contenu peuvent travailler. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/themes.gif';
const featuredImageAltText = exports.featuredImageAltText = 'Thèmes HubSpot';
const toc = exports.toc = [{
  "depth": 0,
  "id": "les-th%C3%A8mes-en-tant-que-package",
  "label": "Les thèmes en tant que package",
  "parent": null
}, {
  "depth": 1,
  "id": "cr%C3%A9ation-de-la-page",
  "label": "Création de la page",
  "parent": "les-th%C3%A8mes-en-tant-que-package"
}, {
  "depth": 1,
  "id": "champs-du-th%C3%A8me",
  "label": "Champs du thème",
  "parent": "les-th%C3%A8mes-en-tant-que-package"
}, {
  "depth": 1,
  "id": "modules-de-th%C3%A8me",
  "label": "Modules de thème",
  "parent": "les-th%C3%A8mes-en-tant-que-package"
}, {
  "depth": 0,
  "id": "structure-des-fichiers-du-th%C3%A8me",
  "label": "Structure des fichiers du thème",
  "parent": null
}, {
  "depth": 1,
  "id": "theme.json",
  "label": "theme.json",
  "parent": "structure-des-fichiers-du-th%C3%A8me"
}, {
  "depth": 1,
  "id": "fields.json",
  "label": "fields.json",
  "parent": "structure-des-fichiers-du-th%C3%A8me"
}, {
  "depth": 2,
  "id": "utilisation-des-valeurs-des-champs-de-th%C3%A8me",
  "label": "Utilisation des valeurs des champs de thème",
  "parent": "structure-des-fichiers-du-th%C3%A8me"
}, {
  "depth": 0,
  "id": "aper%C3%A7u-d-un-th%C3%A8me",
  "label": "Aperçu d'un thème",
  "parent": null
}, {
  "depth": 1,
  "id": "mode-de-test",
  "label": "Mode de test",
  "parent": "aper%C3%A7u-d-un-th%C3%A8me"
}, {
  "depth": 0,
  "id": "ressources-associ%C3%A9es",
  "label": "Ressources associées",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      h4: "h4",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      HubspotVideoPlayer,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Vue d'ensemble des thèmes"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un thème est un ensemble portable et contenu de ressources de développement conçues pour permettre d'éditer du contenu avec fluidité. Vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "créer des thèmes localement à l'aide de HubSpot CLI"
      }), " en utilisant les outils, les technologies et les workflows que vous préférez. Les environnements et les comptes disposent de la portabilité des thèmes et de leurs fichiers associés. Apprenez à développer des thèmes avec la vidéo de présentation de la HubSpot Academy ci-dessous :"]
    }), "\n", (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
      videoId: "172844605010"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Les thèmes en tant que package"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les thèmes étant un ensemble, ils se présentent en cascade dans l'application HubSpot, à différents endroits, pour permettre une expérience de création de contenu efficace. Les développeurs peuvent utiliser des thèmes pour créer un système de conception permettant aux créateurs de contenu de travailler. Il est possible d'intégrer une certaine souplesse et des directives dans un thème pour répondre aux besoins de votre entreprise."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Création de la page"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Lorsque les créateurs de contenu génèrent de nouvelles pages, ils sont invités à sélectionner le thème à partir duquel créer la page, puis à choisir le modèle à utiliser dans le thème."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/theme%20selection.gif",
        alt: "Choix du thème à l'intérieur de HubSpot"
      }), "L'image d'aperçu du thème, ainsi que d'autres configurations pour un thème, sont définies dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#theme-json",
        children: "fichier theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Champs du thème"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les thèmes permettent aux développeurs de créer un ensemble de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json",
        children: "champs de thème"
      }), ", similaires aux champs de module, qui donnent aux créateurs de contenu la possibilité de régler divers boutons et cadrans conçus par un développeur afin d'avoir un contrôle stylistique global d'un site web sans avoir à modifier le CSS. Les développeurs ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#using-theme-field-values",
        children: "utilisent HubL pour accéder aux valeurs des champs de thème"
      }), " dans l'ensemble de leur CSS. Les créateurs de contenu utilisent l'éditeur de thème pour modifier les champs du thème, prévisualiser ces modifications par rapport aux modèles existants dans un thème, et publier leurs modifications."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/themes.gif",
        alt: "thèmes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les champs du thème sont définis par le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json",
        children: "fichier fields.json d'un thème"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous modifiez un thème en ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#test-mode",
        children: "mode test"
      }), ", vous pouvez également copier les paramètres JSON du thème. Cela vous permet de coller toutes les mises à jour dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " local du thème.", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-theme-settings-test-mode.png",
        alt: "copy-theme-settings-test-mode"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modules de thème"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les modules d'un thème doivent être conçus spécifiquement pour être utilisés dans les modèles de ce thème. L'éditeur de contenu met l'accent sur ces modules de thème, ce qui permettra aux créateurs de contenu d'ajouter rapidement et facilement aux pages qu'ils construisent des modules conçus pour fonctionner correctement dans le contexte de cette page. Les modules par défaut et le reste des modules de votre compte HubSpot seront toujours disponibles."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/theme%20modules.png",
        alt: "Modules de thème au sein d'un thème"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors du développement d'un thème, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/hide-modules-and-sections",
        children: "masquer les modules et les sections de l'éditeur de page"
      }), " pour créer une expérience de création de contenu plus rationalisée."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Structure des fichiers du thème"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un thème est un répertoire unique de fichiers. Vous pouvez inclure des fichiers HTML, CSS et Javascript, des modules et des fichiers supplémentaires organisés de n'importe quelle manière dans des sous-répertoires du dossier du thème parent. Deux fichiers JSON sont nécessaires pour construire un thème : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ". Ces fichiers doivent être inclus dans le dossier racine du thème."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour commencer avec un exemple, voir le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "Boilerplate CMS HubSpot"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hs-fs/hubfs/theme%20file%20structure.png?width=599&height=452&name=theme%20file%20structure.png",
        alt: "structure des fichiers du thème"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Pour l'instant, les fichiers .json ne peuvent être créés et téléchargés sur un compte HubSpot que par le biais des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "outils de développement local"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "theme.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " contient les méta-informations relatives au répertoire de votre thème, telles que le libellé lisible du thème, sa capture d'écran de prévisualisation et diverses configurations relatives au comportement du thème. Votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " ressemblera à ce qui suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// theme.json\n{\n  \"label\": \"Cool Theme\",\n  \"preview_path\": \"./templates/home-page.html\",\n  \"screenshot_path\": \"./images/templates/homepage.jpg\",\n  \"enable_domain_stylesheets\": false,\n  \"version\": \"1.0\",\n  \"author\": {\n    \"name\": \"Jon McLaren\",\n    \"email\": \"noreply@hubspot.com\",\n    \"url\": \"https://theme-provider.com/\"\n  },\n  \"documentation_url\": \"https://theme-provider.com/cool-theme/documentation\",\n  \"license\": \"./license.txt\",\n  \"example_url\": \"https://theme-provider.com/cool-theme/demo\",\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["| Paramètre | Type | Description |\n| --- | --- | --- | --- |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), " | Chaîne |  : le libellé lisible du thème est utilisé là où le thème est affiché dans l'application HubSpot, comme dans l'écran de sélection des modèles et l'éditeur de thème. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "preview_path"
      }), " | Chaîne |  : un chemin relatif vers un fichier de modèle dans le thème qui devrait être le modèle par défaut est utilisé lors de la prévisualisation d'un thème dans l'éditeur de thème. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "screenshot_path"
      }), " | Chaîne |  : un chemin d'accès relatif vers un fichier image est utilisé pour donner une instantanée de l'aspect du thème là où la sélection du thème a lieu, comme dans l'écran de sélection du modèle. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "enable_domain_stylesheets"
      }), " | Booléen |  : une activation ou désactivation des feuilles de style attachées à des domaines dans les paramètres du site web et incluses dans les modèles du thème. La valeur par défaut est définie sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), ". |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "version"
      }), " | Chaîne |  : un numéro de version entier prenant en charge les versions ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "."
      }), ". | . |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Author"
      }), " | Objet |  : un champ d'objet pour fournir des informations personnelles en tant que fournisseur du thème.", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " ", (0, _jsxRuntime.jsx)("br", {}), "Le nom du fournisseur. ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " ", (0, _jsxRuntime.jsx)("br", {}), "L'adresse e-mail du service client du fournisseur. ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
        children: "url"
      }), " ", (0, _jsxRuntime.jsx)("br", {}), "Le site web du fournisseur. ", (0, _jsxRuntime.jsx)("br", {}), " |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "documentation_url"
      }), " | Chaîne |  : le lien vers la documentation associée au thème. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "example_url"
      }), " | Chaîne |  : le lien vers l'exemple réel du thème. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "license"
      }), " | String |  : un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://spdx.org/licenses/",
        children: "Identifiant SPDX"
      }), " valide ou chemin d'accès relatif à la licence dans votre thème. Cette licence dicte l'utilisation et la modification autorisées par le créateur du thème. Utile lors du téléchargement sur le marketplace. |\n| ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "is_available_for_new_content"
      }), " | Booléen |  : le booléen qui détermine si un thème s'affiche dans la page du créateur de contenu pour être sélectionné. La valeur par défaut est définie sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), ". |"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "fields.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " contrôle les champs et groupes de champs disponibles dans l'éditeur de thème, y compris les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "champs de style"
      }), ". Les champs que vous incluez dépendront du degré de contrôle que vous souhaitez donner aux créateurs de contenu dans l'éditeur de page. Le nombre de champs disponibles pour les thèmes est plus limité que pour les modules, car les champs de thème sont mieux adaptés aux options de style, tandis que le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "contenu global"
      }), " est mieux adapté au contenu du thème."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, plutôt que d'ajouter un champ de texte dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "field.json"
      }), " du thème pour le slogan de votre site, il devrait être ajouté en tant que module global afin que les créateurs de contenu puissent mettre à jour le slogan à partir de l'éditeur de page plutôt que de l'éditeur de thème."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les champs qui peuvent être utilisés dans les thèmes sont les suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#boolean",
          children: "Booléen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#border",
          children: "Bordure"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#choice",
          children: "Choix"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#color",
          children: "Couleur"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#font",
          children: "Police"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#image",
          children: "Image"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#number",
          children: "Nombre"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#spacing",
          children: "Espacement"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Obtenez plus d'informations sur les options possibles pour les champs de thème en consultant la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "documentation sur les modules et les champs de thème"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Utilisation des valeurs des champs de thème"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour accéder aux valeurs des champs, utilisez la notation par points et préfixez le chemin d'accès à la valeur dans ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " avec le thème. Vous pouvez utiliser la valeur d'un champ thématique dans vos feuilles de style en utilisant une syntaxe similaire à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ theme.path.to.value }}"
      }), ". Par exemple, le champ de police décrit ci-dessous :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n[\n  {\n    \"type\": \"group\",\n    \"name\": \"typography\",\n    \"label\": \"Typography\",\n    \"children\": [\n      {\n        \"type\": \"font\",\n        \"name\": \"h1_font\",\n        \"label\": \"Heading 1\",\n        \"load_external_fonts\": true,\n        \"default\": {\n          \"color\": \"#000\",\n          \"font\": \"Merriweather\",\n          \"font_set\": \"GOOGLE\",\n          \"variant\": \"700\",\n          \"size\": \"48\"\n        }\n      }\n    ]\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Serait référencé en CSS par :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "h1 {\n font-size: {{ theme.typography.h1_font.size }}px;\n font-family: {{ theme.typography.h1_font.font }};\n color: {{ theme.typography.h1_font.color }};\n text-decoration: {{ theme.typography.h1_font.styles.text-decoration }};\n font-style: {{ theme.typography.h1_font.styles.font-style }};\n font-weight: {{ theme.typography.h1_font.styles.font-weight }};\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Aperçu d'un thème"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les développeurs ont parfois besoin de s'assurer du bon fonctionnement de leurs champs sans avoir d'impact sur les pages réelles. C'est justement le but du mode de test des thèmes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Mode de test"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Le mode de test vous offre un environnement sûr pour tester les champs de votre thème et vous assurer qu'ils fonctionnent correctement. L'interface ressemble exactement à l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/edit-your-global-theme-settings#edit-theme-settings",
              children: "aperçu/éditeur de thème que les créateurs de contenu peuvent voir"
            }), ", mais elle garantit que vous ne modifierez pas les paramètres de votre site web. Pour se protéger contre les modifications accidentelles des paramètres du thème, la publication est bloquée lorsque vous êtes en mode test. Vous êtes en mode test si ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "?testmode=true"
            }), " apparaît dans votre barre d'adresse, et si une icône de mode test s'affiche dans l'en-tête de l'éditeur de thème."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://f.hubspotusercontent00.net/hubfs/53/theme-test-mode.png",
            alt: "Mode de test/prévisualisation de thème"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Deux méthodes permettent d'activer le mode test :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Pour activer le mode test depuis le gestionnaire de conception :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Dans le gestionnaire de conception, sélectionnez votre ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "thème"
              }), " dans le Finder."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["En haut du menu latéral de gauche, cliquez sur le bouton ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Aperçu"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-theme-settings-test-mode-design-manager%20(1).png",
                alt: "copy-theme-settings-test-mode-design-manager (1)"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Pour activer le mode test à partir de l'éditeur de la page :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Dans l'éditeur de page, cliquez sur l'onglet ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Conception"
              }), " dans le menu latéral de gauche, puis cliquez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Modifier les paramètres du thème"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/page-editor-edit-theme.png",
                alt: "page-editor-edit-theme"
              })
            }), "\n"]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Ajoutez ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "?testmode=true"
          }), " à l'URL, puis appuyez sur ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Entrée"
          }), ". Vous passerez alors en mode test."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez aussi ouvrir les paramètres de votre thème à partir de l'éditeur de page. Une fois entré, ajoutez le paramètre de requête ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?testmode=true"
      }), " à l'URL dans votre barre d'adresse."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ressources associées"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Premiers pas avec les thèmes"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites",
          children: "Comment ajouter des fonctionnalités de thème à des sites existants ?"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "Thème boilerplate HubSpot"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}