'use es6';

export class VariableIndexStorage {
  constructor() {
    this.variables = new Map();
  }
  add(name) {
    return this.getIndex(name);
  }
  getIndex(name) {
    let index = 0;
    if (this.variables.has(name)) {
      index = this.variables.get(name);
    }
    index++;
    this.variables.set(name, index);
    return this.variables.get(name);
  }
}