module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "subscreen": "string"
    },
    "namespace": "developer-docs-ui",
    "version": "1"
  },
  "tocClick": {
    "name": "toc-click",
    "class": "interaction",
    "properties": {
      "page": "string",
      "tocLink": "string"
    },
    "namespace": "developer-docs-ui",
    "version": "1"
  },
  "linkClick": {
    "name": "link-click",
    "class": "interaction",
    "properties": {
      "link": "string",
      "page": "string"
    },
    "namespace": "developer-docs-ui",
    "version": "1"
  },
  "search": {
    "name": "search",
    "class": "interaction",
    "properties": {
      "action": [
        "search submitted",
        "result selected",
        "search cleared",
        "redirect to results page"
      ],
      "origin": [
        "standard-search",
        "dropdown-search"
      ],
      "searchTerm": {
        "type": "string",
        "isOptional": true
      },
      "link": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "developer-docs-ui",
    "version": "1"
  },
  "developerHomePageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "developerhome"
  },
  "developerHomeNavInteraction": {
    "name": "developer-home-nav-interaction",
    "class": "interaction",
    "properties": {
      "navGroup": "string",
      "navLink": "string",
      "devHomeApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "developerhome"
  }
};