"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 90129093162;
const slug = exports.slug = 'guides/cms/storage/file-manager';
const title = exports.title = 'Gerenciador de arquivos';
const name = exports.name = 'EMEA PT (pt-BR) File Manager';
const metaDescription = exports.metaDescription = 'Use o Gerenciador de arquivos da HubSpot para hospedar imagens na rede de distribuição de conteúdo do HubSpot CMS e permita que os criadores de conteúdo adicionem facilmente arquivos ao conteúdo. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "quando-usar-o-gerenciador-de-arquivos",
  "label": "Quando usar o Gerenciador de arquivos",
  "parent": null
}, {
  "depth": 0,
  "id": "carregar-arquivos-no-gerenciador-de-arquivos",
  "label": "Carregar arquivos no Gerenciador de arquivos",
  "parent": null
}, {
  "depth": 0,
  "id": "usar-arquivos-do-gerenciador-de-arquivos",
  "label": "Usar arquivos do Gerenciador de arquivos",
  "parent": null
}, {
  "depth": 0,
  "id": "otimiza%C3%A7%C3%B5es",
  "label": "Otimizações",
  "parent": null
}, {
  "depth": 0,
  "id": "servir-arquivos-html-e-js-do-gerenciador-de-arquivos",
  "label": "Servir arquivos HTML e JS do gerenciador de arquivos",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      img: "img",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Gerenciador de arquivos"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além do sistema de arquivos do desenvolvedor, o gerenciador de arquivos do HubSpot pode ser usado para armazenar e veicular arquivos. Os arquivos no gerenciador de arquivos são veiculados pela rede global de distribuição de conteúdo (CDN) da HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por padrão, todos os arquivos carregados no gerenciador de arquivos são acessíveis ao público e podem ser indexados pelos mecanismos de pesquisa. Depois de carregar seus arquivos, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/files/organize-edit-and-delete-files#edit-your-file-s-visibility-settings",
        children: "gerenciar as configurações de visibilidade"
      }), "para evitar que os arquivos sejam indexados ou acessados."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O Gerenciador de arquivos pode ser encontrado em ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/files/",
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing > Arquivos e modelos > Arquivos"
        })
      }), " no menu de navegação superior."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/image-png-May-24-2023-05-49-31-9738-AM.png",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Quando usar o Gerenciador de arquivos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "De modo geral, o gerenciador de arquivos deve ser usado para arquivos que serão disponibilizados nos seletores de arquivos em todo o HubSpot. Por exemplo, ao selecionar uma imagem em um módulo de imagem ou rich text."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Antes de carregar arquivos para uso no gerenciador de arquivos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Determinados ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/pt/files/supported-file-types#files-tool",
          children: "limites de tamanho e tipo de arquivo"
        }), " serão aplicados. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/pt/files/upload-files-to-use-in-your-hubspot-content#before-you-get-started",
          children: "o que considerar antes de carregar arquivos"
        }), " no gerenciador de arquivos."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os arquivos enviados para o gerenciador de arquivos não podem ser editados no aplicativo HubSpot, exceto para pequenas edições em imagens."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Se você deseja editar arquivos baseados em texto, eles devem ser armazenados no gerenciador de design."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os arquivos baseados em texto carregados no gerenciador de arquivos não serão minificados ou modificados de forma alguma. Se você deseja aproveitar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/cdn#javascript-minification",
          children: "minificação de JavaScript"
        }), " e a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/cdn",
          children: "minificação e combinação de CSS"
        }), " do HubSpot, armazene esses arquivos no gerenciador de design."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Carregar arquivos no Gerenciador de arquivos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os arquivos podem ser carregados no gerenciador de arquivos por meio das seguintes opções:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para carregar arquivos diretamente no HubSpot, saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/pt/files/upload-files-to-use-in-your-hubspot-content",
          children: "carregar arquivos no gerenciador de arquivos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para carregar arquivos por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/local-development-cms-cli",
          children: "CLI do CMS,"
        }), " use o comando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload filemanager"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para fazer upload de arquivos usando uma API, saiba mais sobre a ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "/reference/api/files/v3#post-%2Ffiles%2Fv3%2Ffiles",
          children: ["API ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Carregar um novo arquivo"
          })]
        }), " da HubSpot."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de carregar seus arquivos no gerenciador de arquivos, saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/pt/files/organize-edit-and-delete-files",
        children: "organizar e gerenciar seus arquivos e as informações deles"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Usar arquivos do Gerenciador de arquivos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os arquivos carregados no gerenciador de arquivos podem ser acessados por meio das seguintes opções:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os arquivos carregados no gerenciador de arquivos ficam acessíveis nos diferentes seletores em todo o HubSpot e no CMS, como nos módulos rich text ou de imagem nas páginas."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os arquivos carregados no gerenciador de arquivos podem ser acessados através de um link de download direto. Saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/pt/files/provide-a-direct-download-link-to-a-file-hosted-on-the-files-tool",
          children: "obter o link de download direto de um arquivo"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Otimizações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os arquivos do Gerenciador de arquivos são automaticamente ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/cdn#browser-and-server-caching",
        children: "armazenados em cache"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/cdn#image-compression-optimization-and-automatic-image-resizing",
        children: "compactados e redimensionados para serem servidos de forma eficiente"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/cdn#domain-rewriting",
        children: "serem acessados em todos os domínios hospedados para reduzir as solicitações entre origens"
      }), ". Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/pt/docs/cms/developer-reference/cdn",
        children: "CDN, segurança e desempenho do HubSpot CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Servir arquivos HTML e JS do gerenciador de arquivos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para arquivos HTML e JavaScript carregados no Gerenciador de arquivos e veiculados usando um domínio padrão da HubSpot (como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "f.hubspotusercontentXX.net"
      }), "), use o Tipo de conteúdo: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "text/plain"
      }), ". Isso significa que os navegadores da Web não renderizarão nem avaliarão o código."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se um usuário acessar diretamente um arquivo HTML, o código HTML será exibido para ele. Para evitar isso, você deve servir esses arquivos de um de seus domínios conectados, em vez de um domínio da HubSpot padrão."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}