"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 50633310880;
const slug = exports.slug = 'guides/cms/content/memberships/overview';
const title = exports.title = 'アクセス権設定';
const name = exports.name = 'APAC JAPAN (ja) | [new] Memberships_JA | 202108';
const metaDescription = exports.metaDescription = 'アクセス権設定（メンバーシップ）は、コンテンツにアクセスしようとする訪問者にユーザーアカウントを作成してもらう機能です。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E6%A8%A9%E8%A8%AD%E5%AE%9A%E3%81%AE%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%83%95%E3%83%AD%E3%83%BC",
  "label": "アクセス権設定のユーザーフロー",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B7%E3%83%83%E3%83%97hubl%E5%A4%89%E6%95%B0",
  "label": "メンバーシップHubL変数",
  "parent": null
}, {
  "depth": 0,
  "id": "crm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88hubl%E9%96%A2%E6%95%B0",
  "label": "CRMオブジェクトHubL関数",
  "parent": null
}, {
  "depth": 0,
  "id": "%E7%99%BB%E9%8C%B2%E3%80%81%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E3%80%81%E3%83%AD%E3%82%B0%E3%82%A2%E3%82%A6%E3%83%88",
  "label": "登録、ログイン、ログアウト",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B7%E3%83%83%E3%83%97%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88",
  "label": "メンバーシップテンプレート",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E6%A8%A9%E8%A8%AD%E5%AE%9A%EF%BC%88%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B7%E3%83%83%E3%83%97%EF%BC%89%E7%9B%A3%E6%9F%BB%E3%83%AD%E3%82%B0",
  "label": "アクセス権設定（メンバーシップ）監査ログ",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E6%A8%A9%E8%A8%AD%E5%AE%9A%E3%81%AEsso",
  "label": "アクセス権設定のSSO",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%BD%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3",
  "label": "ソーシャルログイン",
  "parent": "%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E6%A8%A9%E8%A8%AD%E5%AE%9A%E3%81%AEsso"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      img: "img",
      code: "code",
      pre: "pre",
      ul: "ul",
      li: "li",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "アクセス権設定"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アクセス権設定（メンバーシップ）は、コンテンツにアクセスしようとする訪問者にユーザーアカウントを作成してもらう機能です。アカウントシステムとしてHubSpot CRM、CRMのリスト、さらに訪問者が自分のアカウントのパスワードを作成する機能を組み合わせて使用します。マーケティング担当者は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cms-pages-editor/control-audience-access-to-pages",
        children: "自社のウェブサイト上に、CRM内の特定のリストに含まれるコンタクトだけがアクセスできるページを簡単に作成"
      }), "できます。アクセス権設定により、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cms-pages-editor/control-audience-access-to-pages#set-up-membership-registration-for-your-knowledge-base",
        children: "ナレッジベース記事"
      }), "と", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cms-pages-editor/control-audience-access-to-pages#set-up-membership-registration-for-your-blog",
        children: "ブログ"
      }), "にアクセス制限を付けることもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アクセス権設定のユーザーフロー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リストへの参加または手動割り当てによってコンタクトにコンテンツへのアクセス権が付与されると、ウェブサイトに登録するためのEメールがコンタクトに送信されます。コンタクトは、アクセス許可を与えられたコンテンツにアクセスするためのパスワードを設定します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、あるジムでは、訪問者によるクラスへの登録と登録済みクラスの閲覧を実現したいと考えています。訪問者がクラスに登録すると、フォーム送信によってHubSpot CRMにコンタクトが作成されます。またこのコンタクトは、フォーム送信に基づいてリストに追加され、このリストが［マイイベント］ページへのアクセス権の付与に使用されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/signup.gif",
        alt: "ジム登録の例。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "訪問者は会員登録Eメールを受け取り、自分の会員アカウントのパスワードを作成できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/register%20account.png",
        alt: "登録フォーム"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "訪問者がアカウントにログインする際には、ユーザーは設定済みのEメールとパスワードを使って個人用のイベントページにログインできます。開発者は、ログインしている訪問者向けに非公開コンテンツを作成し、CRMからのデータを使ってログイン済みコンタクトに関するデータを表示できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/my_events.gif",
        alt: "訪問者は自分のアカウントを使ってログインし、登録済みのクラスを確認します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メンバーシップHubL変数"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ビジネスの内容によっては、ユーザーがサインイン済みかどうかに基づいて異なるコンテンツを表示することが適切な場合があります。開発者向けに、コンタクトがウェブサイトに現在ログインしているかどうかを確認するためのHubL変数が提供されています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL変数", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#website-pages-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact.is_logged_in"
        })
      }), "は、現在の訪問者がアクセス権設定を通してウェブサイトにサインインしているかどうかを示します。", (0, _jsxRuntime.jsx)(_components.code, {
        children: "if"
      }), "ステートメント内で使用すれば、特定のコンテンツを状況に応じて表示できるので、1人ひとりの訪問者に合わせた適切な対応が可能になります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request_contact.is_logged_in %}\n    You're signed in!\n{% else %}\n    <a href=\"/_hcms/mem/login\">Log In</a>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リストメンバーシップに基づいて同じページ上のコンテンツ表示を切り替えるには、サインイン済みコンタクト リスト メンバーシップを確認するHubL変数", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#website-pages-variables",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "request_contact.list_memberships"
        })
      }), "を使用できます。この変数では、ログイン済みコンタクトがメンバーとなっているリストIDのディクショナリー（辞書型）が返されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["アクセス権設定（メンバーシップ）機能を使用しないでコンテンツをパーソナライズするには、訪問者がウェブサイト上でフォームを送信済みの場合は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/variables#general-variables",
          children: "コンタクト変数"
        }), "を使用できます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRMオブジェクトHubL関数"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "条件付きでページに表示される一般的なコンテンツ以外にも、以下の関数を使用することで、HubSpotアカウント内のオブジェクト（コンタクト／会社／取引／製品など）に関する情報を取得できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-associations",
          children: "CRMの関連付け"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-object",
          children: "CRMオブジェクト"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#crm-objects",
          children: "CRMオブジェクト"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "セキュリティー上の観点から、パブリックアクセスのページでは製品オブジェクトとマーケティング イベント オブジェクトのみを取得できます。他のオブジェクトタイプの情報を取得するには、メンバーシップ機能の後ろにページを置く必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% if request_contact.is_logged_in %}\n  {% set membership_contact = crm_object('contact', request.contact.contact_vid, 'firstname,lastname') %}\n  Welcome back, {{ membership_contact.firstname }} {{ membership_contact.lastname }}\n{% else %}\n    <a href=\"/_hcms/mem/login\">Log In</a>\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "登録、ログイン、ログアウト"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンタクトはアクセス権設定によってウェブサイト上のコンテンツへのアクセス権を付与されると、ウェブサイトに登録するためのEメールを受信し、パスワードを設定することで、閲覧許可を与えられたコンテンツにアクセスできるようになります。登録用リンクをコンタクトに再送信する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/cms-pages-editor/control-audience-access-to-pages#resend-the-registration-email-to-a-specific-contact",
        children: "登録Eメールを再送信"
      }), "できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ユーザーのサインイン／サインアウト用のURLパスは、アクセス権設定機能を利用可能なHubSpot CMSサイト上で統一されています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "<your domain>/_hcms/mem/login"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "<your domain>/_hcms/mem/logout"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "訪問者がウェブサイトにログインするとCookieがブラウザーに送信されます。これにより、訪問者は再ログインしなくてもウェブサイトを閲覧し、アクセス権設定を通じて権限を与えられているページにアクセスできます。訪問者がログアウトするか、ブラウザー上でウェブサイトにまだログインしたことがない場合は、コンテンツを閲覧する前にログインすることを求められます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メンバーシップテンプレート"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アクセス権設定を利用可能なサイトの場合は、アクセス権設定に役立つ特殊なページが用意されています。これは、特殊なシステムテンプレートによって決定されます。このシステムテンプレートは編集可能で、さまざまなメンバーシップステップの外観や使用感を制御できます。どのテンプレートを使用するかを設定するには、［設定］＞［", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/general",
        children: "非公開コンテンツ"
      }), "］を開き、［", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/templates",
        children: "テンプレート"
      }), "］タブを選択します。この設定に指定するテンプレートを作成するには、［マーケティング］＞［ファイルとテンプレート］＞［デザインツール］の順に進み、左上の［ファイル］＞［新規ファイル］＞［HTML & HUBL］をクリックし、ドロップダウンから適切なテンプレートタイプを選択します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メンバーシップテンプレートの包括的なリストについては、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#membership",
        children: "テンプレート資料のメンバーシップセクション"
      }), "を参照してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["メンバーシップテンプレートとして設定できるのは", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML+HubLテンプレート"
        }), "のみです。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アクセス権設定（メンバーシップ）監査ログ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/l/settings/content-membership/all-domains/general",
        children: "［設定］＞［非公開コンテンツ］"
      }), "から、メンバーシップ機能の後ろにあるコンテンツに関する訪問者の監査ログを表示できます。これにより、どの訪問者が個人用の非公開コンテンツを閲覧しているかを確認できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-02-22%20at%2012.24.54%20PM.png",
        alt: "非公開コンテンツ閲覧の監査ログ"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アクセス権設定のSSO"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/sso",
        children: "アクセス権設定のSSO"
      }), "を使用して、業務に使用する全てのアクセス権限と認証を1つのシステムに集約して管理することもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "ソーシャルログイン"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リスト内のユーザーに、Eメールアドレスとパスワードの入力を求める代わりに、Google またはFacebookを使ってサインインする機能を提供できます。ソーシャル ログイン プロバイダーからは、ログインされたソーシャルアカウントに関連付けられているEメールアドレスが送信されます。このEメールアドレスが、コンテンツアクセス権を与えられたコンタクトリストに含まれているコンタクトかどうかの確認に使用されます。この機能を使用するには、SSOを設定する必要はありません。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["「非公開 - 登録が必要」に設定されたページとコンタクトリストが必要です。また、ログインテンプレートには", (0, _jsxRuntime.jsx)(_components.code, {
        children: "membership_social_logins"
      }), "モジュールを含める必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/memberships/social",
        children: "メンバーシップページにソーシャルログインを追加する"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}