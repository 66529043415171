"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 32924700882;
const slug = exports.slug = 'guides/apps/marketplace/measuring-app-performance';
const title = exports.title = 'Medir o desempenho do aplicativo';
const name = exports.name = 'Avaliar o desempenho do aplicativo';
const metaDescription = exports.metaDescription = 'Saiba mais sobre como medir o desempenho do aplicativo usando a visualização de detalhes da página Lista de aplicativos.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "revise-as-an%C3%A1lises-do-seu-aplicativo",
  "label": "Revise as análises do seu aplicativo",
  "parent": null
}, {
  "depth": 1,
  "id": "an%C3%A1lise-de-marketplace",
  "label": "Análise de marketplace",
  "parent": "revise-as-an%C3%A1lises-do-seu-aplicativo"
}, {
  "depth": 0,
  "id": "feedback-do-cliente",
  "label": "Feedback do cliente",
  "parent": null
}, {
  "depth": 0,
  "id": "principais-recursos",
  "label": "Principais recursos",
  "parent": null
}, {
  "depth": 0,
  "id": "medir-engajamento-com-par%C3%A2metros-utm",
  "label": "Medir engajamento com parâmetros UTM",
  "parent": null
}, {
  "depth": 2,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": "medir-engajamento-com-par%C3%A2metros-utm"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      em: "em",
      img: "img",
      code: "code",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Avaliar o desempenho do aplicativo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode visualizar métricas de desempenho de qualquer aplicativo listado no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "Marketplace de aplicativos"
      }), " em sua conta de desenvolvedor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Revise as análises do seu aplicativo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "conta de desenvolvedor de aplicativos"
        }), ", acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listas de aplicativos"
        }), ". Você será direcionado para um painel com todas as listagens de aplicativos da sua conta."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "aplicativo"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Análise de marketplace"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na secção ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Instalações"
      }), ", revise métricas detalhadas sobre o desempenho do seu aplicativo. Para analisar os dados durante um período específico, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Frequência"
      }), " na parte superior da página e selecione um período de tempo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode analisar os seguintes dados:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Instalações de aplicativos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Desinstalações de aplicativos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Instalações x desinstalações de aplicativos"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Instalações grátis x pagas"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Desinstalações grátis x pagas"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Instalações por país"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Instalações por hub"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/marketplace-app-analytics.png",
        alt: "marketplace-app-analytics"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Análises da listagem"
      }), ", consulte as métricas da página de listagem do aplicativo. Para analisar os dados durante um período específico, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Frequência"
      }), " na parte superior da página e selecione um período de tempo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode analisar os seguintes dados:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Visualizações de página"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Fonte da visualização da página"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utilização de CTAs"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Visualizações de página grátis x pagas"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Visualizações de página por país"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Visualizações de página por hub"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-analytics.png",
        alt: "listing-analytics"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Feedback do cliente"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver todas as avaliações que os clientes enviaram para seu aplicativo, clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Feedback do cliente"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/customer-feedback-tab.png",
        alt: "customer-feedback-tab"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Classificações e avaliações"
      }), ", você pode filtrar as avaliações pelo número de estrelas recebidas, pelo setor do cliente e pelo tamanho da empresa do cliente."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para responder a um avaliação:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Responder"
        }), " a uma avaliação."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na caixa pop-up, insira sua ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "resposta"
        }), " ao cliente e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enviar"
        }), ". Essa resposta ficará visível para todos na listagem do aplicativo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para editar as notificações de avaliações recebidas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ações"
        }), " e selecione **Editar notificações. **"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na página Notificações, pesquise por ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketplace"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Novas avaliações da listagem"
        }), " para ser notificado quando um cliente enviar uma avaliação."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para enviar o link de avaliação a um cliente:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ações"
        }), " e em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copiar link de avaliação"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Envie o link por e-mail ou para o cliente para que ele deixe uma avaliação."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na janela ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Respostas da pesquisa ao desinstalar"
      }), ", revise todas as respostas da pesquisa."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para exportar as respostas, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportar"
        }), " e selecione o formato do arquivo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na janela ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Feedback privado"
      }), ", revise as respostas de feedback privado para sua equipe."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Principais recursos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Recursos principais"
      }), ", analise os seguintes recursos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O guia de Benefícios do Programa de Parceiros de Aplicativo que lista todos os benefícios que você obtém como parceiro com um aplicativo listado no Marketplace de aplicativos da HubSpot."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Uma pesquisa que você pode preencher para dar feedback sobre a experiência do parceiro do aplicativo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "As informações de contato do seu Gerente de Parceiros de Aplicativos."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Medir engajamento com parâmetros UTM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Além das métricas acima, você pode avaliar ainda mais o engajamento incluindo parâmetros UTM nos URLs da página de listagem do seu aplicativo. Assim, você poderá ver quanto tráfego chega ao seu site a partir da página de listagem, usando o HubSpot, o Google Analytics ou outras plataformas de análise de sua escolha."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "É recomendado adicionar os parâmetros UTM aos seguintes URLs incluídos na página de listagem:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Conteúdo de apoio na página de listagem do Marketplace de aplicativos, como o site da sua empresa, documentação complementar, estudos de caso e política de privacidade."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "O URL de instalação da OAuth que os clientes usam ao instalar o aplicativo."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, você pode adicionar a seguinte sequência de parâmetros UTM ao final do URL da documentação:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "?utm_campaign=appmarketplacelisting&utm_medium=referral&utm_source=hubspot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " é recomendado usar parâmetros UTM que sejam consistentes com outros rastreadores UTM que você ou sua equipe de marketing usa. Saiba mais sobre os ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/customers/understanding-basics-utm-parameters",
          children: "parâmetros UTM"
        }), " e como criar URLs de rastreamento de UTM com o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/how-do-i-create-a-tracking-url",
          children: "HubSpot"
        }), " e o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/what-are-utm-tracking-codes-ht",
          children: "o Google Analytics"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para adicionar parâmetros UTM ao URL de instalação da OAuth:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta de desenvolvedor de aplicativos, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicativos"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " do aplicativo para editar seus detalhes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na barra lateral esquerda, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Informações básicas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Autenticação"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na seção ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "URLs de redirecionamento"
        }), ", atualize o URL de redirecionamento para conter seus parâmetros UTM. O URL de instalação do aplicativo será atualizado depois de salvar, portanto, você precisará ter certeza de que atualizou todos os links voltados para o usuário com o novo URL."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/update-redirect-url.png",
        alt: "update-redirect-url"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Salvar"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Observação: **o campo de URL do botão de instalação tem um limite de 250 caracteres. Se seus parâmetros UTM excederem esse limite, talvez seja necessário usar um encurtador de URL, como o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://bitly.com/",
          children: "Bitly"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para adicionar parâmetros UTM ao conteúdo de suporte do aplicativo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na sua conta de desenvolvedor de aplicativos, acesse ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketplace de aplicativos"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Listagens"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na tabela ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Listagens do marketplace"
          }), ", clique no ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome"
          }), " do aplicativo."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na guia ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Informações de listagem"
          }), ", atualize o no campo ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "URL"
          }), " do botão de instalação com os parâmetros UTM."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/install-button-url.png",
            alt: "install-button-url"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Clique na guia ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Informações de suporte"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Nas seções ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Informações de contato"
          }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Recursos de suporte"
          }), " e ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Termos de serviço e Política de privacidade"
          }), ", atualize os URLs com os parâmetros UTM."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/support-resources-section.png",
            alt: "support-resources-section"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Depois de atualizar os URLs, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Enviar para análise"
          }), " no canto superior direito."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Depois de revisados e aprovados, os URLs na página de listagem do seu aplicativo serão atualizados com os parâmetros UTM. Você pode usar ferramentas de análise, como o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/analyze-your-site-traffic-with-the-traffic-analytics-tool",
        children: "HubSpot"
      }), " ou o Google Analytics, para visualizar o tráfego proveniente de seus URLs, conforme classificado pelos parâmetros UTM."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**Observação: **como sua listagem de aplicativo pode ser encontrada por meio do Google e outros mecanismos de pesquisa, também é importante garantir que ela seja compatível com SEO. Uma estratégia recomendada para melhorar seu SEO é usar links de retorno. Se você está escrevendo conteúdo de site, enviando informativos por e-mail ou redigindo mensagens para redes sociais, considere adicionar links à sua página de listagem, juntamente com informações relevantes sobre sua integração. Você pode expandir seu alcance por meio de estratégias, como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/guest-blogging",
          children: "guest blogging"
        }), ", para melhorar a autoridade de SEO."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/backlink-strategies",
          children: "maneiras criativas de obter links de retorno"
        }), " e confira a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/lessons/link-building-tutorial",
          children: "lição gratuita do HubSpot Academy sobre criação de links"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/certification-requirements",
        children: "Requisitos de certificação de aplicativos"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "Requisitos de uma listagem de aplicativo"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}