"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501958312;
const slug = exports.slug = 'guides/api/marketing/emails/transactional-emails';
const title = exports.title = 'API marketing | E-mails transactionnels ';
const name = exports.name = 'EMEA (FR) vNext Docs DP - Transactional Emails';
const metaDescription = exports.metaDescription = "Profitez d'une vue d'ensemble de la fonctionnalité d'e-mail transactionnel dans HubSpot.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "m%C3%A9thodes-d-envoi-d-e-mails-transactionnels",
  "label": "Méthodes d'envoi d'e-mails transactionnels",
  "parent": null
}, {
  "depth": 0,
  "id": "api-smtp",
  "label": "API SMTP",
  "parent": null
}, {
  "depth": 1,
  "id": "cr%C3%A9er-un-nouveau-jeton-d-api-smtp",
  "label": "Créer un nouveau jeton d'API SMTP",
  "parent": "api-smtp"
}, {
  "depth": 1,
  "id": "r%C3%A9cup%C3%A9rer-des-jetons-smtp",
  "label": "Récupérer des jetons SMTP",
  "parent": "api-smtp"
}, {
  "depth": 2,
  "id": "lister-les-jetons-d-api-smtp-par-campagne",
  "label": "Lister les jetons d'API SMTP par campagne",
  "parent": "api-smtp"
}, {
  "depth": 2,
  "id": "demander-un-jeton-unique-d-api-smtp",
  "label": "Demander un jeton unique d'API SMTP",
  "parent": "api-smtp"
}, {
  "depth": 3,
  "id": "d%C3%A9tails-de-la-r%C3%A9ponse",
  "label": "Détails de la réponse",
  "parent": "api-smtp"
}, {
  "depth": 1,
  "id": "g%C3%A9rer-les-jetons-existants",
  "label": "Gérer les jetons existants",
  "parent": "api-smtp"
}, {
  "depth": 2,
  "id": "r%C3%A9initialiser-le-mot-de-passe",
  "label": "Réinitialiser le mot de passe",
  "parent": "api-smtp"
}, {
  "depth": 2,
  "id": "supprimer-un-jeton",
  "label": "Supprimer un jeton",
  "parent": "api-smtp"
}, {
  "depth": 1,
  "id": "se-connecter-au-serveur-smtp-de-hubspot",
  "label": "Se connecter au serveur SMTP de HubSpot",
  "parent": "api-smtp"
}, {
  "depth": 0,
  "id": "api-d-envoi-unique",
  "label": "API d'envoi unique",
  "parent": null
}, {
  "depth": 1,
  "id": "cr%C3%A9er-et-publier-votre-mod%C3%A8le-d-e-mail",
  "label": "Créer et publier votre modèle d'e-mail",
  "parent": "api-d-envoi-unique"
}, {
  "depth": 1,
  "id": "envoyer-votre-e-mail-%C3%A0-l-aide-de-l-api-%C3%A0-envoi-unique",
  "label": "Envoyer votre e-mail à l'aide de l'API à envoi unique",
  "parent": "api-d-envoi-unique"
}, {
  "depth": 1,
  "id": "propri%C3%A9t%C3%A9s-de-demande",
  "label": "Propriétés de demande",
  "parent": "api-d-envoi-unique"
}, {
  "depth": 2,
  "id": "emailid",
  "label": "emailId",
  "parent": "api-d-envoi-unique"
}, {
  "depth": 2,
  "id": "message",
  "label": "Message",
  "parent": "api-d-envoi-unique"
}, {
  "depth": 2,
  "id": "contactproperties",
  "label": "contactProperties",
  "parent": "api-d-envoi-unique"
}, {
  "depth": 2,
  "id": "customproperties",
  "label": "customProperties",
  "parent": "api-d-envoi-unique"
}, {
  "depth": 1,
  "id": "demander-le-statut-d-un-e-mail-envoy%C3%A9",
  "label": "Demander le statut d'un e-mail envoyé",
  "parent": "api-d-envoi-unique"
}, {
  "depth": 2,
  "id": "sendresult",
  "label": "sendResult",
  "parent": "api-d-envoi-unique"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      strong: "strong",
      tbody: "tbody",
      td: "td",
      em: "em",
      h3: "h3",
      code: "code",
      h4: "h4",
      h5: "h5",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "E-mails transactionnels"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous disposez du ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/email/transactional-email",
        children: "module complémentaire d'e-mails transactionnels"
      }), ", vous pouvez envoyer des e-mails avec une adresse IP dédiée pour des reçus commerciaux, des mises à jour de compte, des modifications des conditions d'utilisation et d'autres transactions essentielles."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les e-mails transactionnels sont destinés aux interactions relationnelles, contrairement aux ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/emails/marketing-emails",
        children: "e-mails marketing"
      }), " qui sont généralement utilisés pour promouvoir du contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Méthodes d'envoi d'e-mails transactionnels"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Il existe trois façons d'envoyer un e-mail transactionnel :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
          children: "configurer l'e-mail transactionnel dans l'application"
        }), " ;"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#smtp-api",
          children: "utiliser l'API SMTP"
        }), " ;"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#single-send-api",
          children: "utiliser l'API d'envoi unique"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Méthode"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Vue d'ensemble"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Exemple de cas d'utilisation"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "E-mail transactionnel dans l'application"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Créez des e-mails transactionnels à l'aide de l'éditeur d'e-mails de HubSpot.Vous profitez des mêmes avantages que les e-mails HubSpot standard, tels que le contenu intelligent, la personnalisation et les modèles.Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
              children: "configuration d'e-mails transactionnels dans l'application"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envoyez un e-mail de mise à jour de politique à vos clients avec un lien vers une nouvelle page de politique. Il s'agit d'une mise à jour du service client, et non d'un e-mail marketing. Vous n'avez donc pas à inclure les liens d'abonnement (par exemple, liens CAN-SPAM). Vous n'avez pas besoin d'utiliser toutes les propriétés personnalisées ou informations de systèmes externes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "API SMTP"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Envoyez un e-mail transactionnel à l'aide de votre propre site ou de votre application, tout en suivant les performances de l'e-mail et en créant des informations de contact dans HubSpot. La création facultative d'informations de contact repose sur la création du jeton SMTP.Découvrez-en davantage dans la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#smtp-api",
              children: "section API SMTP ci-dessous"
            }), " ."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envoyez un e-mail de confirmation de création de compte à partir d'un système d'e-mail transactionnel distinct, tout en suivant les performances de l'e-mail et en créant des contacts dans HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "API d'envoi unique"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Il s'agit d'une combinaison entre l'envoi d'e-mails transactionnels dans l'application et via l'API SMTP.Créez des e-mails transactionnels à l'aide de l'éditeur d'e-mails de HubSpot et ajoutez des jetons externes personnalisés à votre e-mail que vous pouvez envoyer à HubSpot via l'API.Découvrez-en davantage dans la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#single-send",
              children: "section API d'envoi unique ci-dessous"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envoyez un e-mail de reçu d'achat à votre client via HubSpot. L'e-mail est déclenché lorsque l'achat est effectué et les valeurs personnalisées sont transmises depuis un autre système (par exemple, l'élément acheté et le montant total des achats). Suivez également les performances de cet e-mail dans HubSpot."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Tous les contacts en copie sur un e-mail transactionnel ne seront pas suivis et l'e-mail n'apparaîtra pas sur la chronologie de la fiche d'informations d'un contact en copie."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API SMTP"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'e-mail transactionnel envoyé à l'aide de l'API SMTP est automatiquement déclenché par des critères spécifiques, comme un achat sur un site web d'e-commerce. Cette API s'intègre à tous les systèmes internes ou tiers afin de déclencher l'e-mail et d'intégrer des données stockées en dehors de HubSpot (par exemple, informations de livraison ou prix d'achat). L'e-mail est envoyé depuis votre système, mais est encadré par les codes de suivi HubSpot qui permettent ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/analyze-your-marketing-email-campaign-performance",
        children: "le suivi et l'évaluation des engagements"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour envoyer un e-mail à l'aide de l'API SMTP, vous devez utiliser un jeton d'API SMTP pour obtenir des identifiants de connexion au serveur SMTP de HubSpot. Une fois que vous vous connectez au serveur, vous pouvez envoyer l'e-mail sur SMTP. Si vous n'avez créé aucun jeton d'API SMTP, vous devez d'abord ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#create-a-new-smtp-api-token",
        children: "générer un nouveau jeton"
      }), ". Si vous avez déjà créé des jetons d'API SMTP, découvrez les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#get-existing-smtp-api-tokens",
        children: "différentes méthodes pour obtenir vos jetons via l'API"
      }), ". Après avoir obtenu votre jeton, découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-in",
        children: "vous connecter au serveur SMTP de HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tous les domaines que vous utilisez comme ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "adresses d'expéditeur"
      }), " pour vos e-mails doivent être connectés comme ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/domains-and-urls/connect-your-email-sending-domain",
        children: "domaines d'envoi d'e-mails"
      }), " dans HubSpot. Une erreur se produira si vous envoyez des e-mails transactionnels via l'API SMTP via un domaine qui n'est pas autorisé à envoyer des e-mails au nom de votre compte HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : Toutes les méthodes de l'API SMTP nécessitent un jeton ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/oauth-quickstart-guide",
          children: "OAuth"
        }), " pour l'authentification."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si vous préférez, toutes les méthodes ci-dessous pour créer, récupérer, supprimer et réinitialiser les mots de passe pour les jetons d'API SMTP peuvent être effectuées ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot#send-a-transactional-email-using-the-smtp-api",
          children: "dans votre compte HubSpot"
        }), ", plutôt que dans l'API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer un nouveau jeton d'API SMTP"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour créer un jeton d'API SMTP, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le corps de la demande doit être un objet JSON avec les propriétés suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), " : indique si un contact doit être créé pour les destinataires."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), " : un nom pour la campagne associée au jeton d'API SMTP."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La réponse comprend un objet de jeton SMTP, qui contient :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " : nom d'utilisateur pour la connexion au serveur SMTP de HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), " : adresse e-mail de l'utilisateur qui a envoyé la demande de création de jeton."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "password"
        }), " : le mot de passe pour la connexion au serveur SMTP de HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), " : l'identifiant affecté à la campagne fournie dans la demande de création de jeton."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), " : l'horodatage généré lors de la création d'un jeton."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), " : indique si un contact doit être créé pour les destinataires."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), " : le nom de la campagne associée au jeton."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Grâce à votre jeton créé, vous pouvez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-in",
        children: "vous connecter au serveur SMTP de HubSpot"
      }), " à l'aide des valeurs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "password"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le mot de passe du jeton ne peut être récupéré qu'au moment de la création. Si vous perdez le mot de passe ou que vous souhaitez définir un nouveau mot de passe, vous devez ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manage-existing-tokens",
        children: "réinitialiser le mot de passe du jeton"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " Les jetons d'API SMTP générés via l'API publique expirent après 12 mois. Une fois expirés, ils sont automatiquement supprimés. Les jetons créés directement ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot#send-a-transactional-email-using-the-smtp-api",
          children: "dans votre compte HubSpot"
        }), " ", (0, _jsxRuntime.jsx)("u", {
          children: "n'expirent pas"
        }), " automatiquement."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Récupérer des jetons SMTP"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Voici les méthodes disponibles pour obtenir les données de jeton en utilisant l'API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Lister les jetons d'API SMTP par campagne"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour obtenir une liste de jetons par nom de campagne ou obtenir un jeton unique selon son ID de campagne, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous devez également inclure un paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "campaignName"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "emailCampaignId"
      }), " avec la demande. Vous pouvez trouver tous les détails de la demande dans l'onglet Points de terminaison en haut de cet article."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Détails de la réponse"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La réponse contient ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "paging"
      }), " comme champs de premier niveau :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "results"
        }), " : un ensemble de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "SmtpApiTokenView"
        }), " contenant :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " : nom d'utilisateur pour la connexion au serveur SMTP de HubSpot."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            }), " : adresse e-mail de l'utilisateur qui a envoyé la demande de création de jeton."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "emailCampaignId"
            }), " : l'identifiant affecté à la campagne fournie dans la demande de création de jeton."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            }), " : l'horodatage généré lors de la création d'un jeton."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createContact"
            }), " : indique si un contact doit être créé pour les destinataires."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "campaignName"
            }), " : le nom de la campagne associée au jeton."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "paging"
        }), " : contient un champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "next.after"
        }), " qui peut être utilisé pour demander plus de résultats."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Demander un jeton unique d'API SMTP"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour modifier un jeton d'API SMTP par ID, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Détails de la réponse"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La réponse comprend ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SmtpApiTokenView"
      }), ", qui contient :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " : nom d'utilisateur pour la connexion au serveur SMTP de HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), " : adresse e-mail de l'utilisateur qui a envoyé la demande de création de jeton."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), " : l'identifiant affecté à la campagne fournie dans la demande de création de jeton."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), " : l'horodatage généré lors de la création d'un jeton."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), " : indique si un contact doit être créé pour les destinataires."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), " : le nom de la campagne associée au jeton."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Gérer les jetons existants"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Après avoir créé des jetons, vous pouvez réinitialiser un mot de passe ou supprimer le jeton en utilisant l'API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Réinitialiser le mot de passe"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour réinitialiser un mot de passe de jeton, effectuez une demande POST à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}/password-reset"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La réponse comprend ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SmtpApiTokenView"
      }), ", qui contient :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), " : nom d'utilisateur pour la connexion au serveur SMTP de HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), " : adresse e-mail de l'utilisateur qui a envoyé la demande de création de jeton."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), " : l'identifiant affecté à la campagne fournie dans la demande de création de jeton."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), " : l'horodatage généré lors de la création d'un jeton."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), " : indique si un contact doit être créé pour les destinataires."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), " : le nom de la campagne associée au jeton."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Supprimer un jeton"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour supprimer un jeton d'API SMTP, effectuez une demande DELETE à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La réponse ne comprend aucun contenu."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Se connecter au serveur SMTP de HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Voici les détails pour la connexion au serveur SMTP de HubSpot, en utilisant le nom d'utilisateur (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), ") et le mot de passe fourni par votre jeton."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nom d'hôte SMTP"
        }), " :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si vous ne résidez pas dans l'UE, utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "smtp.hubapi.com"
            }), " pour le nom d'hôte."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si vous résidez dans l'UE, utilisez ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "smtp-eu1.hubapi.com"
            }), " pour le nom d'hôte."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Port SMTP"
        }), " :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Pour STARTTLS, vous pouvez utiliser le port 25 ou 587."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Pour le TLS direct, utilisez le port 465."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nom utilisateur SMTP"
        }), " : fourni dans le champ d'ID"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mot de passe SMTP"
        }), " : fourni dans le champ de mot de passe"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "API d'envoi unique"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'API d'envoi unique envoie des e-mails reposant sur des modèles créés dans l'outil E-mails de HubSpot à l'aide d'une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " au format JSON. Tous les e-mails envoyés via l'API seront automatiquement associés à des fiches d'informations de contact en fonction de l'adresse e-mail. S'il n'y a aucun contact avec une adresse e-mail correspondante, un nouveau contact avec cette adresse e-mail sera créé. Si vous souhaitez envoyer des e-mails sans créer de contacts, utilisez l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#SMTP-API",
        children: "API SMTP"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Créer et publier votre modèle d'e-mail"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'API d'envoi unique envoie des e-mails reposant sur des modèles créés dans l'outil E-mails de HubSpot à l'aide d'une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " au format JSON. Tous les e-mails envoyés via l'API seront automatiquement associés à des fiches d'informations de contact en fonction de l'adresse e-mail. S'il n'y a aucun contact avec une adresse e-mail correspondante, un nouveau contact avec cette adresse e-mail sera créé. Si vous souhaitez envoyer des e-mails sans créer de contacts, utilisez l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#SMTP-API",
        children: "API SMTP"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Commencez par ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
        children: "configurer votre e-mail dans HubSpot"
      }), ". Après la création de l'e-mail, vous pouvez définir les détails du destinataire, y compris les propriétés de contact ou personnalisées configurées dans le modèle d'e-mail, dans le corps de la demande de l'API. Avant de pouvoir effectuer la demande d'API, vous aurez besoin de l'ID de l'e-mail :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si vous laissez l'e-mail en tant que brouillon sans le publier, vous pouvez obtenir l'ID d'e-mail à partir de l'URL lorsque vous êtes dans l'éditeur d'e-mail. L'ID est la valeur numérique finale avant la barre oblique finale (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/"
        }), ") dans l'URL (par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/email/{PORTAL_ID}/edit/{EMAIL_ID}/settings"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/email-id-for-drafted-single-send-api-email.png",
        alt: "email-id-for-drafted-single-send-api-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si vous publiez votre e-mail, vous pouvez copier l'identifiant de l'e-mail à partir de la page de détails de l'e-mail."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-15%20at%201.00.37%20PM.png",
        alt: "Screen Shot 2020-04-15 at 1.00.37 PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque :"
        }), " HubSpot n'enregistre pas le code HTML/JSON envoyé via cette API. Vous pouvez consulter le modèle d'e-mail à partir de la chronologie du contact destinataire, mais si vous souhaitez conserver un enregistrement du contenu de l'e-mail, il est recommandé d'ajouter un contact en copie cachée à l'e-mail."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Envoyer votre e-mail à l'aide de l'API à envoi unique"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour envoyer un e-mail avec l'API d'envoi unique, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/single-email/send"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La réponse contient les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), " : l'horodatage de la demande de l'envoi."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), " : un identifiant qui peut être utilisé pour ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-the-status-of-an-email-send",
            children: "interroger le statut de l'envoi"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), " : le statut de la demande d'envoi. Comprend ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELED"
          }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMPLETE"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Propriétés de demande"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Le corps de la demande doit être un objet JSON avec les propriétés suivantes :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "message"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "contactProperties"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "emailId"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "emailId"
      }), " contient l'ID de contenu de l'e-mail transactionnel, qui peut être trouvé dans l'outil E-mails de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Message"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le champ message est un objet JSON contenant tout élément que vous souhaitez remplacer. Au minimum, vous devez inclure le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "to"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Champs d'objet du message :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "to"
        }), " : le destinataire de l'e-mail."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "from"
        }), " : l'en-tête de l'e-mail. Vous pouvez définir un nom d'expéditeur avec le format suivant : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"from\":\"Sender Name <sender@hubspot.com>\""
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), " : l'ID d'un envoi particulier. Un seul e-mail avec un champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), " sera envoyé par compte. Vous pouvez donc inclure ce champ pour éviter les envois en double."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "replyTo"
        }), " : une liste JSON des valeurs de l'en-tête Répondre à pour l'e-mail."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "cc"
        }), " : une liste JSON des adresses e-mail à mettre en copie."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "bcc"
        }), " : une liste JSON des adresses e-mail à mettre en copie cachée."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "contactProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "contactProperties"
      }), " est un mappage JSON de valeurs de propriétés de contact. Chaque propriété de contact contient les champs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "value"
      }), ". Chaque propriété sera définie sur la fiche d'informations du contact et visible dans le modèle sous :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/name-token-in-template-for-transactional-email.png",
        alt: "name-token-in-template-for-transactional-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Utilisez ces propriétés si vous souhaitez définir une propriété de contact tout en envoyant l'e-mail. Par exemple, lors de l'envoi d'un reçu, vous souhaiterez peut-être définir une propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "last_paid_date"
      }), ", puisque l'envoi du reçu contiendra des informations sur le dernier paiement."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"contactProperties\": {\n    \"last_paid_date\": \"2022-03-01\",\n    \"firstname\": \"jane\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "customProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " est un mappage JSON de propriétés clé-valeur. Ces propriétés sont généralement liées à l'adresse e-mail elle-même, et non au contact qui reçoit l'e-mail. Elles n'apparaîtront pas dans la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/create-a-web-version-of-your-marketing-email",
        children: "version web de l'e-mail"
      }), " ni dans la vue de l'e-mail depuis la chronologie du contact. Ces propriétés ne sont pas non plus stockées dans HubSpot et seront uniquement incluses dans l'e-mail envoyé."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Chaque clé du champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " peut être référencée dans le modèle à l'aide d'une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "expression HubL"
      }), " pour les champs contenus dans la variable ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "custom"
      }), " (par exemple,", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ custom.NAME_OF_PROPERTY }}"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, si votre modèle d'e-mail fait référence à deux propriétés, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "purchaseUrl"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "productName"
      }), ", vous pouvez fournir les valeurs associées à ces propriétés avec le corps de demande suivant :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"customProperties\": {\n    \"purchaseUrl\": \"https://example.com/link-to-product\",\n    \"productName\": \"vanilla\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez ensuite référencer ces propriétés dans votre modèle d'e-mail :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Congrats on purchasing some of the best ice cream around.\n  </p>\n  <a href={{custom.purchaseUrl}}>{{custom.productName}}</a>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " ne prend en charge que les tableaux utilisés avec ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "un contenu d'e-mail programmable"
      }), ". Dans votre modèle d'e-mail, vous pouvez référencer les éléments définis dans votre champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), " en utilisant une ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "expression HubL"
      }), " (par exemple, en utilisant une boucle ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "for"
      }), " pour restituer chaque élément dans une liste). Par exemple, si les ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "propriétés personnalisées"
      }), " que vous avez incluses dans votre corps de demande ont été structurées comme le bloc de texte JSON ci-dessous :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643122,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"7\"\n  },\n  \"customProperties\": {\n    \"exampleArray\": [\n      {\"firstKey\": \"someValue\", \"secondKey\": \"anotherValue\"},\n      {\"firstKey\": \"value1\", \"secondKey\": \"value2\" }\n    ]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez ensuite référencer les valeurs de chaque élément dans ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exampleArray"
      }), " avec le code HubL suivant :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Thanks for your recent purchase! Here are the details of the items you'll be receiving:\n  </p>\n  <ul>\n    {% for item in custom.exampleArray %}\n      <li>First key: {{ item.firstKey }}, Second key: {{item.secondKey}}</li>\n    {% endfor %}\n  </ul>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Une fois envoyé, l'e-mail transactionnel rendra le contenu du modèle d'e-mail programmable associé comme suit :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-transactional-email-with-customProperties-array.png",
        alt: "example-transactional-email-with-customProperties-array"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Demander le statut d'un e-mail envoyé"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour obtenir le statut de l'envoi d'e-mail, effectuez une demande ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/marketing/v3/email/send-statuses/{statusId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La réponse contient les champs suivants :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "sendResult"
          }), " : une énumération qui représente le statut d'envoi de l'e-mail. Les valeurs possibles sont ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#sendresult-values",
            children: "énumérées ci-dessous"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), " : l'horodatage de la demande de l'envoi."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "startedAt"
          }), " : l'horodatage du début du traitement de l'envoi."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "completedAt"
          }), " : l'horodatage de la finalisation de l'envoi."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), " : un identifiant qui peut être utilisé pour interroger le statut de l'envoi."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), " : le statut de la demande d'envoi. Comprend ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSING"
          }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELED"
          }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMPLETE"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "eventId"
          }), " : en cas d'envoi, l'ID et l'horodatage de création de l'événement d'envoi."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "sendResult"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "sendResult"
      }), " est une énumération qui reflète le résultat d'une tentative d'envoi d'e-mail. Les valeurs possibles sont :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "SENT"
        }), " : l'e-mail a été correctement envoyé."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "QUEUED"
        }), " : l'e-mail a été placé dans la file d'attente et sera envoyé lors du traitement de celle-ci."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PORTAL_SUSPENDED"
        }), " : en raison d'une violation de la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/acceptable-use",
          children: "Politique d'utilisation acceptable"
        }), ", l'e-mail du client HubSpot a été suspendu."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_TO_ADDRESS"
        }), " : l'adresse du destinataire n'est pas valide. Cette erreur se produira également si vous tentez d'envoyer un e-mail avec l'un des préfixes basés sur les rôles suivants dans l'adresse e-mail : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "abuse"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "no-reply"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "noreply"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "root"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "spam"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "security"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undisclosed-recipients"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "unsubscribe"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inoc"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "postmaster"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "privacy"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOCKED_DOMAIN"
        }), " : le domaine ne peut pas recevoir d'e-mails de HubSpot pour le moment."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUSLY_BOUNCED"
        }), " : le destinataire a précédemment fait l'objet d'un rejet et la logique d'envoi n'a entraîné aucun envoi."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUS_SPAM"
        }), " : le destinataire a précédemment été marqué comme spam."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_FROM_ADDRESS"
        }), " : l'adresse d'", (0, _jsxRuntime.jsx)(_components.em, {
          children: "expéditeur"
        }), " n'est pas valide."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_CONTENT"
        }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), " n'est pas valide ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), " correspond à un e-mail qui n'a pas été défini pour l'envoi unique."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_TEMPLATE_PROPERTIES"
        }), " : des propriétés définies dans le modèle n'ont pas été incluses dans le champ ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        }), " envoyé dans la demande."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}