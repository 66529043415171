"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866921781;
const slug = exports.slug = 'guides/api/crm/search';
const title = exports.title = 'API do CRM | Pesquisa';
const name = exports.name = 'vNext DP de documentos - Pesquisar';
const metaDescription = exports.metaDescription = 'Os pontos de extremidade de pesquisa do CRM torna a obtenção de dados mais eficiente, permitindo que os desenvolvedores filtrem, classifiquem e pesquisem em qualquer tipo de objeto do CRM.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "fazer-um-pedido-de-pesquisa",
  "label": "Fazer um pedido de pesquisa",
  "parent": null
}, {
  "depth": 0,
  "id": "objetos-e-engajamentos-pesquis%C3%A1veis-do-crm",
  "label": "Objetos e engajamentos pesquisáveis do CRM",
  "parent": null
}, {
  "depth": 1,
  "id": "objetos",
  "label": "Objetos",
  "parent": "objetos-e-engajamentos-pesquis%C3%A1veis-do-crm"
}, {
  "depth": 1,
  "id": "compromissos",
  "label": "Compromissos",
  "parent": "objetos-e-engajamentos-pesquis%C3%A1veis-do-crm"
}, {
  "depth": 0,
  "id": "pesquisar-propriedades-de-pesquisa-padr%C3%A3o",
  "label": "Pesquisar propriedades de pesquisa padrão",
  "parent": null
}, {
  "depth": 0,
  "id": "filtrar-resultados-de-pesquisa",
  "label": "Filtrar resultados de pesquisa",
  "parent": null
}, {
  "depth": 0,
  "id": "pesquisar-por-associa%C3%A7%C3%B5es",
  "label": "Pesquisar por associações",
  "parent": null
}, {
  "depth": 0,
  "id": "classificar-resultados-de-pesquisa",
  "label": "Classificar resultados de pesquisa",
  "parent": null
}, {
  "depth": 0,
  "id": "percorrendo-p%C3%A1ginas-de-resultados",
  "label": "Percorrendo páginas de resultados",
  "parent": null
}, {
  "depth": 0,
  "id": "limita%C3%A7%C3%B5es",
  "label": "Limitações",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Pesquisar"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use os pontos de extremidade de pesquisa no CRM para filtrar, classificar e pesquisar\nobjetos, registros e engajamentos em seu CRM. Por exemplo, use os pontos de extremidade\npara obter uma lista de contatos em sua conta ou uma lista de todos os negócios abertos.\nPara usar esses ponto de extremidade a partir de um aplicativo, é necessário um escopo\nde CRM. Consulte esta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth#scopes",
        children: "lista de escopos disponíveis"
      }), "\npara saber quais escopos granulares do CRM podem ser usados para atingir seu objetivo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Fazer um pedido de pesquisa"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para pesquisar seu CRM, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para o ponto de extremidade de pesquisa do objeto. Os pontos de extremidade de pesquisa do CRM são construídos usando o seguinte formato: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/{object}/search"
      }), ". No corpo da solicitação, você incluirá ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#filter-search-results",
        children: "filtros"
      }), " para restringir sua pesquisa por valores de propriedade do CRM.Por exemplo, o snippet de código abaixo recuperaria uma lista de todos os contatos que têm um endereço de e-mail de empresa específico."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to /crm/v3/objects/contacts/search\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"email\",\n          \"operator\": \"CONTAINS_TOKEN\",\n          \"value\": \"*@hubspot.com\"\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cada objeto pesquisado incluirá um conjunto de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#crm-objects",
        children: "propriedades padrão"
      }), " que é devolvido. Por exemplo, uma pesquisa de contatos retornará ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "createdate"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "firstname"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastmodifieddate"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "lastname"
      }), ". Para retornar um conjunto específico de propriedades, inclua uma matriz ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " no corpo da solicitação."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to /crm/v3/objects/companies/search\n{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"annualrevenue\",\n          \"operator\": \"GT\",\n          \"value\": \"10000000\"\n        }\n      ]\n    }\n  ],\n  \"properties\": [\"annualrevenue\", \"name\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " incluir uma matriz ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), " substituirá as propriedades retornadas por padrão; portanto, você precisará especificá-las na matriz se desejar retorná-las."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Objetos e engajamentos pesquisáveis do CRM"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Objetos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As tabelas abaixo contêm os pontos de extremidade de pesquisa de objetos, os objetos aos quais eles se referem e as propriedades que são retornadas por padrão."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Pesquisar ponto de extremidade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriedades padrão retornadas"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/carts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Carrinhos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/companies/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "domain"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/contacts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contatos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "firstname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deals/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Negócios"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "dealname"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "closedate,``pipeline"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealstage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deal_split/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Divisões de negócio"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/discounts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Descontos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/feedback_submissions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envios de feedback"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/fees/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tarifas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/invoices/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Faturas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/leads/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Leads"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/line_items/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Itens de linha"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "quantity"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "amount"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/orders/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pedidos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/commerce_payments/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pagamentos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/products/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Produtos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), " ,", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/quotes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Orçamentos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_expiration_date"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_public_url_key"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_status"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/subscriptions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Assinaturas (Comércio)"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/taxes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Impostos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tickets/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline_stage"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_category"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_priority"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "subject"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "createdate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Compromissos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A tabela abaixo contém os pontos de extremidade de pesquisa de engajamento, os engajamentos aos quais eles se referem e as propriedades que são retornadas por padrão."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Pesquisar ponto de extremidade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Engajamento"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriedades padrão retornadas"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/calls/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chamadas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/emails/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "E-mails"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/meetings/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Reuniões"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/notes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Observações"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tasks/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tarefas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_createdate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_lastmodifieddate"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_object_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pesquisar propriedades de pesquisa padrão"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pesquise todas as propriedades de texto padrão nos registros do objeto especificado para encontrar todos os registros que têm um valor que contém a sequência de caracteres especificada. Por padrão, os resultados serão retornados na ordem de criação do objeto (primeiro o mais antigo), mas você pode substituir isso com ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#sorting",
        children: "classificar"
      }), ". Por exemplo, a solicitação abaixo pesquisa todos os contatos com um valor de propriedade de texto padrão que contém a letra ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "X"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"query\": \"x\"\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo estão as propriedades que são pesquisadas por padrão pelo método acima:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Pesquisar ponto de extremidade"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Objeto"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Propriedades de pesquisa padrão"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/calls/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chamadas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_call_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_body_preview"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/companies/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "website"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phone"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "domain"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/contacts/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Contatos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "firstname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lastname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "phone"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_additional_emails"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "fax"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mobilephone"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_marketable_until_renewal"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/{objectType}/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objetos personalizados"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Até 20 propriedades selecionadas."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/deals/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Negócios"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "dealname"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pipeline"
            }), ",", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealstage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dealtype"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/emails/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "E-mails"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_email_subject"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/feedback_submissions/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envios de feedback"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_submission_name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_content"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/meetings/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Reuniões"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_meeting_body"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/notes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Observações"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_note_body"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/products/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Produtos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            }), " ,", (0, _jsxRuntime.jsx)(_components.code, {
              children: "price"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sku"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/quotes/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Orçamentos"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_firstname"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_lastname"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_proposal_slug"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_company_name"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_sender_email"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_quote_number"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_public_url_key"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tasks/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tarefas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_task_body"
            }), ": ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_task_subject"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "/crm/v3/objects/tickets/search"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tickets"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "subject"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_pipeline_stage"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_category"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_ticket_id"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Filtrar resultados de pesquisa"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use filtros no corpo da solicitação para limitar os resultados apenas aos registros com valores de propriedade correspondentes. Por exemplo, a solicitação abaixo pesquisa todos os contatos com o nome ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Alice."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": ao filtrar resultados de pesquisa para chamadas, conversas, e-mails, reuniões, Observações ou tarefas, a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_body_preview_html"
        }), " não é suportada. Para e-mails, as propriedades ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_email_html"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_body_preview"
        }), " também não são suportadas."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n\n  --data '{\n    \"filterGroups\":[\n      {\n        \"filters\":[\n          {\n            \"propertyName\": \"firstname\",\n            \"operator\": \"EQ\",\n            \"value\": \"Alice\"\n          }\n        ]\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para incluir vários critérios de filtro, você pode agrupar os ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "filters"
      }), " em ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        })
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para aplicar a lógica ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "AND"
        }), ", inclua uma lista de condições separadas por vírgulas dentro de um conjunto de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para aplicar a lógica ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "OR"
        }), ", inclua vários ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " em um ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroup"
        }), ".Você pode incluir um máximo de cinco ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " com até 10 ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " em cada grupo, para um máximo de 25 filtros no total. Se você incluiu muitos grupos ou filtros, receberá uma resposta de erro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "VALIDATION_ERROR"
        }), ". Por exemplo, a solicitação abaixo procura contatos com o primeiro nome ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Alice"
        }), " E um sobrenome que não seja ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Smith"
        }), ", OU contatos que não tenham um valor para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "email"
        }), " da propriedade."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n  \"filterGroups\": [\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"firstname\",\n          \"operator\": \"EQ\",\n          \"value\": \"Alice\"\n        },\n        {\n          \"propertyName\": \"lastname\",\n          \"operator\": \"NEQ\",\n          \"value\": \"Smith\"\n        }\n      ]\n    },\n    {\n      \"filters\": [\n        {\n          \"propertyName\": \"email\",\n          \"operator\": \"NOT_HAS_PROPERTY\"\n        }\n      ]\n    }\n  ]\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode usar operadores em filtros para especificar quais registros devem ser retornados. Os valores nos filtros não diferenciam letras maiúsculas e minúsculas, exceto nos dois casos seguintes casos:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ao filtrar uma propriedade de enumeração, a pesquisa diferencia maiúsculas e minúsculas para todos os operadores de filtro."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao filtrar uma propriedade de string usando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "IN"
        }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "NOT_IN"
        }), ", os valores pesquisados devem estar em letra minúscula. Abaixo estão os operadores de filtro disponíveis:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Operador"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menor que o valor especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LTE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Menor ou igual ao valor especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GT"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Maior que o valor especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GTE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Maior ou igual ao valor especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EQ"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Igual ao valor especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NEQ"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não é igual ao valor especificado."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BETWEEN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["No intervalo especificado. Na sua solicitação, use pares de chave-valor para definir ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "highValue"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "value"
            }), ". Veja o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#between-operator",
              children: "exemplo acima"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "IN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Incluído na lista especificada. Pesquisa por correspondência exata. Em sua solicitação, inclua os valores da lista em uma matriz de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            }), ". Ao pesquisar uma propriedade de string com este operador, os valores devem ser escritos em letra minúscula. Veja o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#in-operator",
              children: "exemplo abaixo"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_IN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Não incluído na lista especificada. Em sua solicitação, inclua os valores da lista em uma matriz de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "values"
            }), ". Ao pesquisar uma propriedade de string com este operador, os valores devem ser escritos em letra minúscula."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HAS_PROPERTY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tem um valor para a propriedade especificada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_HAS_PROPERTY"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não tem um valor para a propriedade especificada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTAINS_TOKEN"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Contém um token. Em sua solicitação, você pode usar curingas (*) para realizar uma pesquisa parcial. Por exemplo, use o valor ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "_@hubspot.com"
            }), " para recuperar contatos com um endereço de e-mail da HubSpot."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "NOT_CONTAINS_TOKEN"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Não contém um token."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, você pode usar o operador ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "BETWEEN"
      }), " para pesquisar todas as tarefas que foram modificadas pela última vez em um intervalo de tempo específico:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/tasks/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n   \"filterGroups\":[{\n      \"filters\":[\n        {\n          \"propertyName\":\"hs_lastmodifieddate\",\n          \"operator\":\"BETWEEN\",\n          \"highValue\": \"1642672800000\",\n          \"value\":\"1579514400000\"\n        }\n      ]\n    }]\n}'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para outro exemplo, você pode usar o operador ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "IN"
      }), " para pesquisar empresas que tenham valores especificados selecionados em uma propriedade de menu suspenso."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/companies/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"filterGroups\":[\n      {\n        \"filters\":[\n          {\n           \"propertyName\":\"enumeration_property\",\n           \"operator\":\"IN\",\n          \"values\": [\"value_1\", \"value_2\"]\n        }\n        ]\n      }\n    ],\n   \"properties\": [\"annualrevenue\", \"enumeration_property\", \"name\"]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Pesquisar por associações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Procure registros associados a outros registros específicos usando pseudo-propriedades ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associations.{objectType}"
      }), ". Por exemplo, a solicitação abaixo procura todos os tickets associados a um contato que tenha o ID de contato ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "123"
      }), ":"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/tickets/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"filters\": [\n      {\n        \"propertyName\": \"associations.contact\",\n        \"operator\": \"EQ\",\n        \"value\": \"123\"\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " no momento, a opção de pesquisar por associações de objetos personalizados não é suportada por meio de pontos de extremidade de pesquisa. Para encontrar associações de objetos personalizados, você pode usar a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations",
          children: "API de associações"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Classificar resultados de pesquisa"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use uma regra de classificação no corpo da solicitação para listar os resultados em ordem crescente ou decrescente. Somente uma regra de classificação pode ser aplicada a uma pesquisa. Por exemplo, a solicitação abaixo classifica os contatos retornados pelos primeiros criados mais recentemente."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"sorts\": [\n      {\n        \"propertyName\": \"createdate\",\n        \"direction\": \"DESCENDING\"\n      }\n    ]\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Percorrendo páginas de resultados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por padrão, os pontos de extremidade de pesquisa retornarão as páginas com 10 objetos por vez. Isso pode ser alterado definindo o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "limit"
      }), " no corpo da solicitação. O número máximo de objetos suportados por página é 100. Por exemplo, a solicitação abaixo retornaria páginas contendo 20 resultados cada."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"limit\": 20\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para acessar a próxima página dos resultados, você deve informar o parâmetro ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "after"
        })
      }), " fornecido na propriedade ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "paging.next.after"
        })
      }), " da resposta anterior. Se a propriedade ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "paging.next.after"
        })
      }), " não for fornecida, não haverá resultados adicionais para serem exibidos. Você deve formatar o valor no parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "after"
      }), " como um número inteiro.Por exemplo, a solicitação abaixo retornaria a próxima página dos resultados:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "curl https://api.hubapi.com/crm/v3/objects/contacts/search \\\n  --request POST \\\n  --header \"Content-Type: application/json\" \\\n  --header \"authorization: Bearer YOUR_ACCESS_TOKEN\" \\\n  --data '{\n    \"after\": \"20\"\n  }'\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limitações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Pode levar algum tempo para que os objetos do CRM recém-criados ou atualizados sejam exibidos nos resultados de pesquisa."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os objetos do CRM arquivados não aparecerão nos resultados de pesquisa."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os pontos de extremidade de pesquisa são ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/api-usage/usage-details",
          children: "limitados "
        }), "a quatro solicitações por segundo."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Uma consulta pode conter no máximo 3.000 caracteres. Se o corpo da solicitação exceder 3.000 caracteres, um erro 400 será retornado."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os pontos de extremidade de pesquisa estão limitados a um total de 10.000 resultados por consulta. Uma tentativa de exibir mais de 10 mil resultados gerará um erro 400."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#filter-search-results",
          children: "filtrar"
        }), ", você pode incluir no máximo cinco ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filterGroups"
        }), " com até 10 ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "filters"
        }), " em cada grupo, para um máximo de 25 filtros no total."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao pesquisar números de telefone, a HubSpot usa propriedades calculadas especiais para padronizar o formato. Todas essas propriedades começam com ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs_searchable_calculated_*"
        }), ". Como parte dessa padronização, a HubSpot usa apenas o código de área e o número local. Você não deve incluir o código do país nos critérios de pesquisa ou filtro."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}