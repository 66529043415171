"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = exports.badge = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 102582231820;
const slug = exports.slug = 'guides/api/crm/objects/goals';
const title = exports.title = 'CRM API | Goals';
const name = exports.name = 'vNext Docs DP - Goals';
const metaDescription = exports.metaDescription = 'Goals are used to create user-specific quotas for their sales and services teams based on templates provided by HubSpot. The goals endpoints allow you to sync this data between HubSpot and other systems. ';
const badge = exports.badge = 'NEW';
const toc = exports.toc = [{
  "depth": 0,
  "id": "retrieve-goals",
  "label": "Retrieve goals",
  "parent": null
}, {
  "depth": 0,
  "id": "goals-properties",
  "label": "Goals properties",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Goals"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In HubSpot, goals are used to create user-specific quotas for their sales and services teams based on templates provided by HubSpot. The goals API allow you to retrieve goals data in your HubSpot account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/create-goals",
        children: "using goals in HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve goals"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can retrieve an individual goal or all goals in your account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To request all goals, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/goal_targets"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve an individual goal, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/goal_targets/{goalTargetId}/"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve goals that meet a specific set of criteria, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to the search endpoint and include filters in the request body. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/search",
          children: "searching the CRM"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to retrieve a goal with an ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "44027423340"
      }), ", the request URL would be the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/goal_targets/44027423340/"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response will include a few default properties, including the create date, last modified date."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"87504620389\",\n  \"properties\": {\n    \"hs_createdate\": \"2021-11-30T22:18:49.923Z\",\n    \"hs_lastmodifieddate\": \"2023-12-11T19:21:32.851Z\",\n    \"hs_object_id\": \"87504620389\"\n  },\n  \"createdAt\": \"2021-11-30T22:18:49.923Z\",\n  \"updatedAt\": \"2023-12-11T19:21:32.851Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To return specific properties, include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " query parameter in the request URL along with comma-separated property names. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#goal-properties",
        children: "user properties below"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the following URL would result in the response below:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v3/objects/users?properties=hs_job_title,hs_additional_phone"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response\n{\n  \"id\": \"87504620389\",\n  \"properties\": {\n    \"hs_createdate\": \"2021-11-30T22:18:49.923Z\",\n    \"hs_lastmodifieddate\": \"2023-12-11T19:21:32.851Z\",\n    \"hs_object_id\": \"87504620389\"\n  },\n  \"createdAt\": \"2021-11-30T22:18:49.923Z\",\n  \"updatedAt\": \"2023-12-11T19:21:32.851Z\",\n  \"archived\": false\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Goals properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the Goals API, you can also request specific goal properties:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_goal_name:"
        }), " This is a string that denotes the name of a goal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_target_amount:"
        }), " Number that denotes the goal target value."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_start_datetime:"
        }), " Goal's start date as a UTC timestamp."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_end_datetime:"
        }), " Goal's end date as a UTC timestamp."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "hs_created_by_user_id:"
        }), " HubSpot UserId of the person who created the goal, not the one assigned to a goal."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, if you wanted to include all properties listed above, the request URL may resemble the following:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/goal_targets/44027423340?properties=hs_goal_name,hs_target_amount,hs_start_datetime,hs_end_datetime,hs_created_by_user_id"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The response may look similar to the JSON excerpt below:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response for GET request to /crm/v4/objects/goal_targets/{goal_target_id}/\n{\n  \"id\": \"44027423340\",\n  \"properties\": {\n    \"hs_created_by_user_id\": \"885536\",\n    \"hs_createdate\": \"2023-02-15T15:53:07.080Z\",\n    \"hs_end_datetime\": \"2024-01-01T00:00:00Z\",\n    \"hs_goal_name\": \"Revenue Goal 2023\",\n    \"hs_lastmodifieddate\": \"2023-02-16T10:02:21.131Z\",\n    \"hs_object_id\": \"44027423340\",\n    \"hs_start_datetime\": \"2023-12-01T00:00:00Z\",\n    \"hs_target_amount\": \"2000.00\"\n  },\n  \"createdAt\": \"2023-02-15T15:53:07.080Z\",\n  \"updatedAt\": \"2023-02-16T10:02:21.131Z\",\n  \"archived\": false\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}