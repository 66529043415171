"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 37833970855;
const slug = exports.slug = 'guides/api/cms/media-bridge';
const title = exports.title = 'CMS API | メディアブリッジ';
const name = exports.name = 'vNext Docs DP - メディアブリッジ';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%83%96%E3%83%AA%E3%83%83%E3%82%B8api%E3%81%AE%E4%BD%BF%E7%94%A8",
  "label": "メディアブリッジAPIの使用",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E5%AE%9A%E7%BE%A9%E3%82%92%E4%BD%9C%E6%88%90%E3%81%97%E3%81%A6%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%9E%E3%82%A4%E3%82%BA%E3%81%99%E3%82%8B",
  "label": "メディアオブジェクト定義を作成してカスタマイズする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2-%E3%83%96%E3%83%AA%E3%83%83%E3%82%B8-%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92hubspot%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%AE%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%AB%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "メディア ブリッジ アプリをHubSpotユーザーのアカウントに関連付ける",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "メディアオブジェクトを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%82%92%E5%9F%8B%E3%82%81%E8%BE%BC%E3%82%80%E3%81%9F%E3%82%81%E3%81%AEcms%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E4%BD%9C%E6%88%90",
  "label": "メディアを埋め込むためのCMSモジュールの作成",
  "parent": null
}, {
  "depth": 1,
  "id": "oembed%E3%83%89%E3%83%A1%E3%82%A4%E3%83%B3%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B",
  "label": "oEmbedドメインを設定する",
  "parent": "%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%82%92%E5%9F%8B%E3%82%81%E8%BE%BC%E3%82%80%E3%81%9F%E3%82%81%E3%81%AEcms%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E4%BD%9C%E6%88%90"
}, {
  "depth": 0,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "カスタムモジュールを作成する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E9%80%81%E4%BF%A1%E3%81%99%E3%82%8B",
  "label": "メディアイベントを送信する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E3%82%B3%E3%83%B3%E3%82%BF%E3%82%AF%E3%83%88%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%AB%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "イベントをコンタクトレコードに関連付ける",
  "parent": "%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E9%80%81%E4%BF%A1%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%81%AB%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "イベントをメディアに関連付ける",
  "parent": "%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E9%80%81%E4%BF%A1%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%AB%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "イベントをページに関連付ける",
  "parent": "%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88%E3%82%92%E9%80%81%E4%BF%A1%E3%81%99%E3%82%8B"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      code: "code",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "メディアブリッジAPI"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "メディアブリッジAPIを使用すると、インテグレーターは動画や音声ファイルなどのメディアオブジェクトとメディア消費データをHubSpotにプッシュできます。また、ユーザーのHubSpotアカウントで以下の機能が利用できるようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotのドラッグ＆ドロップエディターでページやEメールにメディアオブジェクトを埋め込むためのモジュール。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "プロスペクトまたは顧客が動画、音声、およびその他のメディアタイプを操作したときに表示されるCRMタイムラインイベント。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ターゲットを絞り込み、パーソナライズされた体験を提供するためのセグメント化されたリスト。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "メディア消費イベントに基づいてインタラクションを自動化するワークフロー。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "メディアアセットの効果を測定するためのレポート。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メディアブリッジは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "カスタムオブジェクト"
      }), "と統合イベント（HubSpotのイベント トラッキング システム）の両方を使用します。つまり、メディアブリッジAPIとカスタムオブジェクトAPIの両方を使用して、連携を構築できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メディアブリッジAPIの使用"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メディア ブリッジ アプリをHubSpotユーザーのアカウントに関連付けるには、その前に、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "HubSpot開発者アカウント"
      }), "を使用して、メディア ブリッジ アプリを登録し、最初のメディアオブジェクト定義を設定する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メディアオブジェクト定義を作成してカスタマイズする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メディアオブジェクトを定義するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/settings/object-definitions"
      }), "に送信します。", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaTypes"
      }), "パラメーターを使用して、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "VIDEO"
      }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AUDIO"
      }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "DOCUMENT"
      }), "、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "IMAGE"
      }), "、または", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "OTHER"
      }), "オブジェクトを定義します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メディアオブジェクトを定義したら、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/schemas/{objectType}"
      }), "に送信し、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/properties/{objectType}"
      }), "に送信して、メディア オブジェクト プロパティーを作成して変更します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全てのAPI呼び出しには、ご使用の開発者アカウントIDが", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "portalID"
      }), "クエリーパラメーターとして含められます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メディア ブリッジ アプリをHubSpotユーザーのアカウントに関連付ける"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "メディア ブリッジ アプリをHubSpotユーザーのアカウントに関連付けるには、HubSpot開発者のアカウントでそのためのアプリ定義を作成する必要があります。アプリの定義には、以下の情報を含めます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "連携でHubSpotユーザーのアカウントに対して最初に関連付けを行う際に、ユーザーに表示されるロゴやテキストなどの詳細。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "連携で必要となる、ユーザーのHubSpotアカウントに対するスコープ。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "メディア ブリッジ アプリをHubSpotユーザーのアカウントに関連付けるには、次のようにします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["メディア ブリッジ アプリの開発者アカウントで、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "アプリケーション定義"
        }), "を作成します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリケーションを定義する際には、以下のスコープを含めます。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["アプリからの呼び出しを認証する際は、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "OAuth"
        }), "認証を使用します。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/intro-to-auth",
          children: "認証方式"
        }), "の詳細をご確認ください。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "お客様のポータルにアプリが正しくインストールされたことを確認するには、次のようにします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/media-bridge-demo/{HubID}"
        }), "の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "{HubID}"
        }), "をアカウントIDに置き換えてアクセスします。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右上の［アプリ］ドロップダウンメニューをクリックし、ご使用のメディア ブリッジ アプリを選択します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "アプリでは、アプリでサポートされるメディアタイプの確認や、サンプルメディアの作成ができます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "メディア ブリッジ アプリがお客様のポータルにインストールされたら、以下のことを行えるようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-your-media-objects",
          children: "メディアオブジェクトを作成する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-cms-modules-to-embed-media",
          children: "メディアオブジェクトを埋め込むためのCMSモジュールを作成する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#send-your-media-events",
          children: "メディアイベントを送信する"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メディアオブジェクトを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メディアオブジェクトの定義を作成し、メディア ブリッジ アプリをユーザーのアカウントにインストールしたら、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuthトークン"
      }), "を使用して、アカウントでメディアオブジェクトを作成し、変更できるようになります。メディアオブジェクトはカスタムオブジェクトなので、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects#tab-2",
        children: "カスタムオブジェクトAPIエンドポイント"
      }), "を使用して作成します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/media-bridge/v1/{appId}/settings/object-definitions/{mediaType}"
        }), "に送信して、", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "objectType"
        }), "を見つけます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/{objectType}"
        }), "に送信して、ユーザーのアカウントにメディアオブジェクトを作成します。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "メディアオブジェクトは、サードパーティーのシステム内の埋め込み可能なコンテンツを表します。メディアブリッジに追加したメディアオブジェクトは、HubSpotのCMSコンテンツに埋め込み、メディアイベントと関連付けることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "VIDEO"
      }), "および", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "AUDIO"
      }), "メディアオブジェクトの全ての既定および必須のプロパティー（*が付いているものは必須）は、以下の表でご確認いただけます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotのメディア ブリッジ システム内の特定のメディアを識別するために使用されるID。このIDはHubSpotによって自動生成されます。開発者が設定することはできません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_duration"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メディアの再生時間（ミリ秒）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_oembed_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://oembed.com/#section2",
              children: "oEmbed仕様"
            }), "に準拠した有効なoEmbedレスポンスを返す必要があるURL。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), "にiframeが含まれている", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rich"
            }), "タイプが必要です。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_file_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "未加工のメディアファイルのURL。ソーシャル埋め込み機能をサポートするために後で利用できます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_thumbnail_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "コンテンツにメディアを埋め込むためのサムネイルとして使用される画像のURL。このサムネイルの望ましいサイズは640x480ピクセルです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_poster_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メディアを表す画像のURL。この画像は元のメディアと同じ寸法にする必要があり、画像のプレースホルダーが必要な場所で使用することができます（例えば、メディアがEメールに挿入される場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サードパーティーのシステム内のメディアのID。これにより、インテグレーターは自身のシステムで使用しているものと同じIDに基づいて、メディアブリッジからメディアを取得できます。（これは、このマッピングを利用するAPIエンドポイントです）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_folder_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロバイダー指定のオブジェクトへのパス。サードパーティーのフォルダーシステムにあるオブジェクトの場所（存在する場合）を示すことを目的としています。HubSpotは、これらのオブジェクトをユーザーに表示する際にこのディレクトリー構造を表示しようと試みますが、プロバイダーに関連した名前が付いている最上位フォルダー内で各プロバイダーのオブジェクトとフォルダーをネストする場合があります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メディアの名前。メディアピッカーなどの場所でHubSpot UI内に表示されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_details_page_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザーがメディアプロバイダーのシステムにあるメディアを表示または操作できるURL。HubSpot UI内で使用され、ユーザーがタイトルのみに頼らずにメディアを識別することを可能にします。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "IMAGE"
      }), "メディアオブジェクトの全ての既定および必須のプロパティー（*が付いているものは必須）は、以下の表でご確認いただけます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "タイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "数値"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotのメディア ブリッジ システム内の特定のメディアを識別するために使用されるID。このIDはHubSpotによって自動生成されます。開発者が設定することはできません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_oembed_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "https://oembed.com/#section2",
              children: "oEmbed仕様"
            }), "に準拠した有効なoEmbedレスポンスを返す必要があるURL。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "html"
            }), "にiframeが含まれている", (0, _jsxRuntime.jsx)(_components.code, {
              children: "video"
            }), "または", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rich"
            }), "タイプが必要です。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_file_url*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "未加工のメディアファイルのURL。ソーシャル埋め込み機能をサポートするために後で利用できます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_thumbnail_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メディアピッカーなどの場所にあるコンテンツにメディアを埋め込むためのサムネイルとして使用される画像のURL。このサムネイルの望ましいサイズは640x480ピクセルです。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_poster_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メディアを表す画像のURL。この画像は元のメディアと同じ寸法にする必要があり、画像のプレースホルダーが必要な場所で使用することができます（例えば、メディアがEメールに挿入される場合）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_external_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "サードパーティーのシステム内のメディアのID。これにより、インテグレーターは自身のシステムで使用しているものと同じIDに基づいて、メディアブリッジからメディアを取得できます。（これは、このマッピングを利用するAPIエンドポイントです）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_folder_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "プロバイダー指定のオブジェクトへのパス。サードパーティーのフォルダーシステムにあるオブジェクトの場所（存在する場合）を示すことを目的としています。HubSpotは、これらのオブジェクトをユーザーに表示する際にこのディレクトリー構造を表示しようと試みますが、プロバイダーに関連した名前が付いている最上位フォルダー内で各プロバイダーのオブジェクトとフォルダーをネストする場合があります。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_title*"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メディアの名前。メディアピッカーなどの場所でHubSpot UI内に表示されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_details_page_link"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザーがメディアプロバイダーのシステムにあるメディアを表示または操作できるURL。HubSpot UI内で使用され、ユーザーがタイトルのみに頼らずにメディアを識別することを可能にします。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メディアを埋め込むためのCMSモジュールの作成"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのCMSでメディアをレンダリングするための", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "モジュール"
      }), "は、各メディア ブリッジ アプリ プロバイダーが作成する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メディア ブリッジ アプリがHubSpotアカウントにインストールされている場合、モジュール上の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#embed",
        children: "埋め込みフィールド"
      }), "に、追加の「メディア連携」ソースタイプが示されます。これにより、ユーザーはインストールされたアプリからウェブサイトページに埋め込むメディアを選択できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["ユーザーが埋め込むメディアを選択すると、メディアの", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "oembed_url"
      }), "および", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "oembed_response"
      }), "がHubLで使用可能になり、プレーヤーを簡単にレンダリングできるようになります。さらに、選択したメディアの", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "id"
      }), "と", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "media_type"
      }), "が保存され、基盤となるCRMオブジェクトを", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "crm_objects"
      }), " HubL関数でクエリーすることが可能になります。これにより、メディアオブジェクトに含まれる全てのプロパティーまたはプロパティーの一部を取得できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メディアオブジェクトのIDが", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "459"
      }), "と", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "922"
      }), "の場合のcrm_objects HubL関数の使用例："]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set objects = crm_objects(\"a123_Videos\", [459,922]) %} {{ objects }}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["同じオブジェクトの特定の画像を取得する場合：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% set object = crm_object(\"a123_Images\", 459) %} {{ object }}"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["アプリでは", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/{appId}/settings/object-definitions/{mediaType}"
      }), "に送信することで、オブジェクトタイプ（この例では「a123_Videos」）を取得できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["顧客がoAuthを介して接続した後、開発者は", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/source-code",
        children: "CMSソースコードAPI"
      }), "エンドポイントを使用して、カスタム モジュール コードを顧客のアカウントにプッシュする必要があります。モジュールコードが顧客のアカウントにプッシュされると、顧客は開発者が作成したモジュールをコンテンツ内で自動的に使用できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "oEmbedドメインを設定する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#oembed",
        children: "oEmbed"
      }), " HubL関数を使用するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v1/{appId}/settings/oembed-domains"
      }), "にリクエストを送信して、oEmbedレスポンスの取得に使用されるドメインを登録する必要があります。以下のパラメーターが含まれている必要があります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["**スキーム：**メディアURLのURLパターン。このURLパターンは、oEmbed HubL関数に渡されたURLをoEmbed APIと照合するために使用されます。", (0, _jsxRuntime.jsx)(_components.code, {
            children: "*"
          }), "を使用したワイルドカード値を使用できます（例：", (0, _jsxRuntime.jsx)(_components.code, {
            children: "www.domain.com/*"
          }), "）。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["**URL：**oEmbed APIのURL。メディアは、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "URL"
          }), "パラメーターを使用してこのURLに渡されます。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["**Discovery（ブール値）：**oEmbed APIでoEmbedの", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://oembed.com/#section4",
            children: "Discovery"
          }), "機能がサポートされるかどうかを決定します。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "既定では、登録されたoEmbedドメインは、アプリをインストールした全てのお客様が使用できるようになります。お客様ごとに固有のカスタムドメインがある場合は、oEmbedドメインを設定するときに、APIリクエストにportalId値を渡して、oEmbedドメインの使用を許可するアカウントを指定できます。これにより、そのoEmbedドメインを使用できるアカウントが、指定されたHubSpotアカウントのみに制限されます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "カスタムモジュールを作成する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "カスタムモジュールを作成するには、次のようにします。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［マーケティング］＞［ファイルとテンプレート］＞［デザインツール］の順に進みます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "左上の［ファイル］＞［新規ファイル］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "ダイアログボックスで、［今日は何を作成しますか？］ドロップダウンメニューをクリックし、［モジュール］を選択します。-［次へ］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "モジュールを使用するコンテンツのタイプ（［ページ］、［ブログ記事］、［ブログリスト］、［Eメール］、または［引用］）の隣にあるチェックボックスをオンにします。Eメールテンプレートで使用されるモジュールに、CSSおよびJavaScriptを含めることはできません。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["このモジュールを", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ローカルモジュール"
        }), "または", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "グローバルモジュール"
        }), "のどちらにするかを選択します。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/design-manager-user-guide-v2/how-to-use-global-content-across-multiple-templates",
          children: "グローバルモジュール"
        }), "として作成した場合、このモジュールのコンテンツを編集すると、モジュールが使用されている全ての場所に変更が反映されます。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "モジュールのファイル名を入力して、［作成］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "右側の［フィールド］セクションで、［フィールドの追加］ドロップダウンメニューをクリックし、［埋め込み］を選択します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://lh3.googleusercontent.com/_cARgVZZLUD-nsJUIaG8XMUdSm1GRLr4Gfw96HeZ6SyXFG0t6yiiPKt5ToSe7nq8arSnG569_wg5X2XsD0XEtmEGiX9sbQxH7deUIJKGshXamME6vXrLN2oVSAt-cmrkTN2nggDsdkChDnEOxKrw5mo",
        alt: ""
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［サポートされるソースタイプ］セクションで、［メディアとの連携］を選択します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［既定の埋め込みコンテンツ］セクションで、［[メディア ブリッジ アプリ]から選択］をクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["右側のパネルで、モジュールに埋め込む", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "メディア"
          }), "を選択します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "必要に応じて、エディターオプション、フィールドの表示条件、フィールドのリピーターオプションを設定します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "［HubL変数名］で、［コピー］＞［スニペットをコピー］をクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "「module.html」セクションにスニペットを貼り付けます。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "ページ上でのモジュールの表示をプレビューするには、［プレビュー］をクリックします。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "左側のセクションで、［[メディア ブリッジ アプリ]から選択］をクリックし、プレビューするメディアを選択します。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メディアイベントを送信する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "メディアイベントは、メディアオブジェクトに関連して発生するイベント（例：再生イベント）です。メディア ブリッジ アプリに送信されたメディアイベントは、レポートやタイムラインCRMカードで使用できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "メディアイベントには次の3種類があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "**再生イベント：**ユーザーがメディアの再生を開始した時間を表します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "**四分位数イベント：**ユーザーが閲覧しているメディアで、四分位数のマイルストーン（0%、25%、50%、75%、100%）に到達した時間を表します。"
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "**注意持続時間イベント：**ユーザーがメディアを完全に消費し終えた時間、またはユーザーがセッションを終了した時間を表します。"
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらのイベントを送信するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/media-played"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/media-played-percent"
      }), "、および", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/media-bridge/v2/events/attention-span respectively"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotでユーザーのコンタクトタイムラインにメディアイベントを表示するには、セッションごとに", (0, _jsxRuntime.jsx)("u", {
        children: "再生"
      }), "イベントをメディア ブリッジ アプリに送信する必要があります。単一のセッションからのイベントは、コンタクトのアクティビティータイムラインで1つのカードに表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "v2イベントエンドポイントを使用して送信されたイベントは、v1エンドポイントを使用して送信されたイベントとは異なり、非同期で処理されます。そのため、以下のようにすることを推奨しています。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "誤ったリクエストは即座にエラーになるため、v1バージョンのエンドポイントはテストに使用します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "v2バージョンのエンドポイントは、その非同期性により、イベントがメディアブリッジに書き込まれる間のクライアントの遅延を防ぐのに役立つため、本番環境で使用します。また、メディアブリッジのサーバーに一時的な障害が発生した場合にも、イベントは保持され、再試行されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "イベントをコンタクトレコードに関連付ける"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メディアイベントをコンタクトレコードに関連付けるには、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), "または", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactUtk"
      }), "のいずれかを提供する必要があります。", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactUtk"
      }), "のみが提供された場合は、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), "に変換されます。リクエストで両方が提供された場合は、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "contactId"
      }), "が信頼できる情報源として使用されます。このパラメーターにより、メディア ブリッジ アプリはコンタクトレコードとイベントの間に関連付けを作成できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["コンタクトレコードに関連付けられたメディアイベントは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/create-reports-with-the-custom-report-builder",
        children: "クロスオブジェクトのレポート"
      }), "で使用できます。これにより、お客様はメディアイベントとコンタクトレコード、および関連する会社と取引を関連付けることができます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "イベントをメディアに関連付ける"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メディアイベントをメディアに関連付けるには、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaID"
      }), "または", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "externalID"
      }), "のいずれかのパラメーターをリクエストに含める必要があります。両方が提供された場合は、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "mediaID"
      }), "が信頼できる情報源として使用されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "イベントをページに関連付ける"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "メディアイベントをHubSpotのページに関連付けるには、次のパラメーターをリクエストに含める必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ページがHubSpot CMSでホスティングされている場合は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageId"
        }), "を提供する必要があります。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["ページがHubSpot CMSでホスティングされて", (0, _jsxRuntime.jsx)("u", {
          children: "いない"
        }), "場合は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageName"
        }), "および", (0, _jsxRuntime.jsx)(_components.code, {
          children: "pageUrl"
        }), "を含める必要があります。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "3つのメディアイベントでサポートされているプロパティーの概要を次の表に示します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "プロパティー"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "イベントタイプ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全てのイベント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このイベントに関連するメディアのID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["サードパーティーのシステム内のメディアのID。これにより、開発者は自身のシステムで使用しているものと同じIDに基づいて、メディアブリッジ内のメディアを参照できます。これは、イベントで", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), "の代わりに使用できます。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "externalId"
            }), "と", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), "の両方が指定された場合は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaBridgeObjectId"
            }), "が使用され、externalIdは無視されます。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sessionId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全てのイベント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "表示セッションを表す一意の識別子。プロバイダーによって異なるものを意味する可能性があります。HubSpotは、セッションの定義をプロバイダーに委ねています。この識別子は、同じセッションで発生したイベントをグループ化するために使用されます。サードパーティーのシステムによって生成されることを想定しています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全てのイベント"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["メディアを消費した、HubSpotのシステム内のコンタクトのID。これは、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/objects/contacts/v1#get-a-contact-by-its-user-token",
              children: "HubSpotのユーザートークン（utk）でコンタクトを取得するAPI"
            }), "を使用して取得できます。APIはユーザートークンの提供もサポートしており、コンタクトIDへの自動変換を処理します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contactUtk"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全てのイベント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メディアを消費したコンタクトを識別するユーザートークン（utk）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全てのイベント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "イベントが発生したページのコンテンツID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageName"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全てのイベント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "イベントが発生したページの名前またはタイトル。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全てのイベント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "イベントが発生したページのURL。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "occurredTimestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "全てのイベント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "このイベントが発生した時間のタイムスタンプ（エポックからの経過ミリ秒数）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "attentionSpanMapString / attentionSpanMap"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "注意持続時間"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["メディアのスパンと、ユーザーが各スパンを消費した回数に関する最もきめ細かい情報を提供する未加工データです。例えば、10秒の動画があり、各スパンが1秒であるとします。訪問者が動画の最初の5秒を視聴した後、動画を再生しなおして再び最初の2秒を視聴した場合、結果として生成される", (0, _jsxRuntime.jsx)(_components.code, {
              children: "attentionSpanMapString"
            }), "は、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "“0=2;1=2;2=1;3=1;4=1;5=0;6=0;7=0;8=0;9=0;”"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalPercentPlayed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "注意持続時間"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "ユーザーがメディアを消費したパーセンテージ。プロバイダーは、メディアの同じ部分が繰り返し消費された場合をどのように扱うかに応じて、異なる方法でこの値を計算できます。したがって、APIはイベントの注意持続時間の情報に対して、totalPercentWatchedの検証を試みません。このプロパティーがない場合、HubSpotは、注意持続時間マップから（値が1以上のスパンの数）／（スパンの合計数）を計算してこの値を求めます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalSecondsPlayed"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "注意持続時間"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["ユーザーがメディアを消費した秒数。メディアブリッジは、", (0, _jsxRuntime.jsx)(_components.code, {
              children: "totalPercentPlayed"
            }), "*", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaDuration"
            }), "の式でこの値を求めます。プロバイダーが別の計算方法を希望する場合は、イベントの作成時に事前計算済みの値を提供できます。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "playedPercent"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "四分位数イベント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "これまでに消費されたメディアの量の四分位数のパーセント値（0、25、50、75、100）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "iframeUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "再生イベント"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "iFrameで外部システムからのデータを表示するために使用されるURL。このプロパティーが指定された場合、コンタクトのタイムライン上のイベントに、モーダルウィンドウを開くリンクが表示されます。このリンクをクリックすると、iFrameのコンテンツが表示されます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mediaType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "文字列"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["イベントが属するメディアタイプ（例：動画や音声）。単一のプロバイダーが複数のメディアタイプをサポートしている場合、このプロパティーを使用して、イベントを正しいオブジェクトに割り当てることができます。", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}