"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 164781652893;
const slug = exports.slug = 'reference/ui-components/crm-action-components/crm-card-actions';
const title = exports.title = 'CrmCardActions';
const name = exports.name = 'CrmCardActions | UI components';
const metaDescription = exports.metaDescription = 'Learn about the CrmCardActions component for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "related-components",
  "label": "Related components",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      a: "a",
      img: "img",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CrmCardActions | UI components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "CrmCardActions"
      }), " component renders a smaller standalone or dropdown menu button that can contain multiple ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-action-components/overview#available-actions",
        children: "CRM actions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This type of component is useful for enabling your extension to interact with other CRM entities, such as records and engagements. To learn more about how CRM action components work together, check out the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/ui-components/crm-action-components/overview",
        children: "CRM action components overview"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/2023-08-29_15-08-37%20(1).gif?width=323&height=280&name=2023-08-29_15-08-37%20(1).gif",
        alt: "2023-08-29_15-08-37 (1)"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import { CrmCardActions } from '@hubspot/ui-extensions/crm';\n\n<CrmCardActions\n  actionConfigs={[\n    {\n      type: 'action-library-button',\n      label: 'Preview',\n      actionType: 'PREVIEW_OBJECT',\n      actionContext: {\n        objectTypeId: '0-3',\n        objectId: 14795354663,\n      },\n      tooltipText: 'Preview this deal record.',\n    },\n    {\n      type: 'dropdown',\n      label: 'Activities',\n      options: [\n        {\n          type: 'action-library-button',\n          label: 'Send email',\n          actionType: 'SEND_EMAIL',\n          actionContext: {\n            objectTypeId: '0-1',\n            objectId: 769851,\n          },\n        },\n        {\n          type: 'action-library-button',\n          label: 'Add note',\n          actionType: 'ADD_NOTE',\n          actionContext: {\n            objectTypeId: '0-1',\n            objectId: 769851,\n          },\n        },\n      ],\n    },\n  ]}\n/>;\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Unlike ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-action-button",
          children: "CrmActionButton"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-action-link",
          children: "CrmActionLink"
        }), " where props such as ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "actionType"
        }), " are accepted at the top level, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "CrmCardActions"
        }), " includes an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "actionConfigs"
        }), " prop which accepts fields for action configuration."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Prop"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "actionConfigs"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An array that stores fields for configuration button actions. See below for list of supported fields."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The button's label text."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onError"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(errors: string[]) => void"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An optional callback that will pass any error messages that were generated. Common errors include missing required context values or the user not having sufficient permissions to perform an action."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "actionConfigs"
      }), " array, you can include the following fields:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Field"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "type"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "'action-library-button'"
            }), " | ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'dropdown'"
            })]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of button to render:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "action-library-button"
                }), ": a standalone button that can perform one action."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "dropdown"
                }), ": a dropdown menu button containing multiple ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "'action-library-button'"
                }), " actions. When using this type, you'll need to include an ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "options"
                }), " array containing each action."]
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["For ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "dropdown"
            }), " type buttons, this array stores objects for each action in the dropdown menu. Each action should be set to the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "'action-library-button'"
            }), " type."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "actionType"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of action to perform. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/overview#available-actions",
              children: "list of available actions"
            }), " for more information."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "actionContext"
            }), " ", (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An object containing the CRM object and record context for performing the action. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/ui-components/crm-action-components/overview#available-actions",
              children: "list of available actions"
            }), " for required context values."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "disabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ", the button or dropdown menu option will render in a disabled, greyed-out state and can't be clicked."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tooltipText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tooltip text that appears when hovering over the button or dropdown menu option."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related components"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-action-link",
          children: "CrmActionLink"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button",
          children: "Button"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/standard-components/button-row",
          children: "ButtonRow"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}