"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42841502905;
const slug = exports.slug = 'guides/cms/content/memberships/social';
const title = exports.title = 'How to add social login for membership pages';
const name = exports.name = '[Developers] How to add social login for membership pages';
const metaDescription = exports.metaDescription = 'In this guide you will add social login capability to your login template.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "what-you-need-before-you-begin",
  "label": "What you need before you begin",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-open-your-login-template",
  "label": "1. Open your login template",
  "parent": null
}, {
  "depth": 0,
  "id": "2.-add-the-membership-social-login-module",
  "label": "2. Add the membership social login module",
  "parent": null
}, {
  "depth": 0,
  "id": "3.-test-the-social-login",
  "label": "3. Test the social login",
  "parent": null
}, {
  "depth": 0,
  "id": "you-re-done!",
  "label": "You're done!",
  "parent": null
}, {
  "depth": 0,
  "id": "related-resources",
  "label": "Related resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      pre: "pre",
      h3: "h3",
      strong: "strong",
      ol: "ol",
      a: "a",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "How to add social login for membership pages"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Remembering your password across the hundreds of sites we interact with day to day can be hard. Especially if you follow best practices and have separate passwords for all of your accounts. You can make it easier on users who have memberships with your website by providing the ability to login using social accounts like Facebook and Google."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In this guide you will add social login capability to your login template"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "What you need before you begin"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Memberships functionality (requires CMS Enterprise)"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A membership login template"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A website page you wish to restrict access to"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A contact list you will give page access to (a list which just contains your email would be good for testing before using a real list)"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Open your login template"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In your theme, find and open your login template. Membership login templates are required to have the template annotation ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "templateType: membership_login_page"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Add the membership social login module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Add the code for the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "membership_social_logins"
      }), " module to your template where you want the button(s) to appear."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social\" path=\"@hubspot/membership_social_logins\" %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "The module supports both Google and Facebook login. You can add both, or just one of them to your login page."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The module supports both Google and Facebook login. You can add both, or just one of them to your login page."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Add Google login button"
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["You will create credentials in the Google developer console. You will be given a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "client ID"
            }), " which you will then use in your module tag."]
          }), (0, _jsxRuntime.jsxs)(_components.ol, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Go to the ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://console.developers.google.com/apis/credentials",
                children: "Credentials page"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "\"Create credentials > OAuth client ID\""
              })]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "\"Web application\""
              }), " application type."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Name your application something that communicates what your users will be logging into on your HubSpot site."
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "\"Create\""
              })]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Copy the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Client ID"
              }), " of your newly created OAuth client."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In your template file, find the social login module, add the parameter ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "google_clientid=\"YourAppID\""
              }), " replace \"YourAppID\" with the app ID you just copied."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Add the parameter ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "google_enabled=true"
              }), ". This will make the Google login button appear."]
            }), "\n"]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Add Facebook login button"
        }), (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["To add the facebook login button you will create an app in Facebook's developer dashboard. The app will be given a ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Facebook App Id"
            }), "."]
          }), (0, _jsxRuntime.jsxs)(_components.ol, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.facebook.com/apps/",
                children: "Log into your Facebook developer account"
              }), " (", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.facebook.com/docs/development/register",
                children: "Register a new account"
              }), " if you don't have one)"]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Use the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "\"Create App\""
              }), " button."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "\"Build connected experiences\""
              }), ", then ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "\"continue\""
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Name your app something that communicates what your users will be logging into on your HubSpot site. Select ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "\"Create app\""
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "You should now see a screen that says \"add products to your app\"."
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Select ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "\"Facebook Login\""
              })]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Open ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "\"Settings\""
              }), " in the left side navigation panel and under Client OAuth Settings, enter your redirect URL in the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "\"Valid OAuth Redirect URIs\""
              }), " field."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In the top bar you will see your ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "app id"
              }), ". Copy it to your clipboard."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In your template file, find the social login module, add the parameter ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "facebook_appid=\"YourAppID\""
              }), " replace \"YourAppID\" with the app ID you just copied."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Add the parameter ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "facebook_enabled=true"
              }), ". This will make the facebook login button appear."]
            }), "\n"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot does not have control over the UI that Google and Facebook provide. Should their UI change these instructions may become confusing or no longer work. The most important part is that you create a client/app, then get it's ID. Provide that id through the default module's parameter for that provider and their respective \"enabled\" parameter."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is an example of what your code may look like. If you are only adding one of the providers, you would not need to include an id, and the enabled parameter for services you are not supporting."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social\" path=\"@hubspot/membership_social_logins\",\n        clientid=\"1234567890-abc123def456.apps.googleusercontent.com\"\n        appid=\"12345678\"\n        facebook_enabled=true\n        google_enabled=true\n        %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "3. Test the social login"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/lists/create-active-or-static-lists#set-up-a-new-list",
          children: "Create a contact list"
        }), " with just your email address in it. ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Email address must also be used for your Google or Facebook account."
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/website-pages/require-member-registration-to-access-private-content#set-up-membership-registration-for-pages",
          children: "Set a page to \"Private registration required\","
        }), " choose your newly created test list."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Visit one of these pages using incognito mode so you are not signed in. You should see your login template with the social login functionality."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Attempt to log in using the social login buttons."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you're seeing any issues, look back through the instructions, ensure your client ID or app ID is entered correctly and passed to the module. Ensure if you have security set up for it that your site's domain is set as a trusted domain in the app settings."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "You're done!"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Congratulations you successfully added social login functionality to your login template! Your users can now use their Google and/or Facebook to log in."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related resources"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/memberships/overview",
          children: "Memberships"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/memberships/sso",
          children: "Memberships SSO"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/crm-objects",
          children: "CRM Objects in CMS Hub"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Membership social logins module reference"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}