"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 51199955888;
const slug = exports.slug = 'getting-started/account-types';
const title = exports.title = 'Tipos de conta da HubSpot';
const name = exports.name = 'Tipos de conta da HubSpot';
const metaDescription = exports.metaDescription = 'Saiba sobre os diferentes tipos de contas da HubSpot e para que cada uma é usada.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "contas-padr%C3%A3o-da-hubspot",
  "label": "Contas padrão da HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "contas-de-desenvolvedores-de-aplicativos",
  "label": "Contas de desenvolvedores de aplicativos",
  "parent": null
}, {
  "depth": 0,
  "id": "contas-de-teste-do-desenvolvedor",
  "label": "Contas de teste do desenvolvedor",
  "parent": null
}, {
  "depth": 1,
  "id": "criar-uma-conta-de-teste-de-desenvolvedor",
  "label": "Criar uma conta de teste de desenvolvedor",
  "parent": "contas-de-teste-do-desenvolvedor"
}, {
  "depth": 1,
  "id": "renove-uma-conta-de-teste-do-desenvolvedor",
  "label": "Renove uma conta de teste do desenvolvedor",
  "parent": "contas-de-teste-do-desenvolvedor"
}, {
  "depth": 0,
  "id": "contas-de-sandbox",
  "label": "Contas de sandbox",
  "parent": null
}, {
  "depth": 1,
  "id": "contas-de-ambiente-de-testes-padr%C3%A3o",
  "label": "Contas de ambiente de testes padrão",
  "parent": "contas-de-sandbox"
}, {
  "depth": 1,
  "id": "contas-de-ambientes-de-testes-do-cms",
  "label": "Contas de ambientes de testes do CMS",
  "parent": "contas-de-sandbox"
}, {
  "depth": 1,
  "id": "contas-de-ambientes-de-testes-de-desenvolvimento-beta",
  "label": "Contas de ambientes de testes de desenvolvimento (BETA)",
  "parent": "contas-de-sandbox"
}, {
  "depth": 0,
  "id": "contas-de-provedor-do-marketplace",
  "label": "Contas de provedor do Marketplace",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      em: "em",
      img: "img",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Tipos de conta da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Existem vários tipos de contas da HubSpot, cada uma com uma finalidade distinta. Abaixo, saiba mais sobre cada tipo de conta e sua finalidade."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contas padrão da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Uma conta padrão da HubSpot é o tipo mais comum de conta. É aqui que você encontrará todas as ferramentas, recursos e configurações incluídos no seu plano da HubSpot. Ele pode ser gratuito ou pago e é usado como seu ambiente de produção."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Uma conta padrão da HubSpot terá acesso a todas as ferramentas e recursos incluídos no seu plano."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contas de desenvolvedores de aplicativos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As contas de desenvolvedores de aplicativos são gratuitas e servem para criar e gerenciar aplicativos, integrações e contas de teste de desenvolvedores. Também servem para criar e gerenciar anúncios do Marketplace de aplicativos. No entanto, as contas de desenvolvedores de aplicativos e suas contas de teste associadas não estão conectadas a uma conta padrão da HubSpot. Elas não podem sincronizar dados ou ativos de ou para outra conta da HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As contas de desenvolvedores de aplicativos podem ser identificadas por um banner no canto superior de qualquer página que diga ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Esta é uma conta de desenvolvedores de aplicativos"
      }), ". ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://paper-attachments.dropbox.com/s_FE997E185BD47B083EA9B7C65FEC2822D18406AA9C2CBDB7FA3CA77217D16F19_1626814303990_App+dev+account+info+alert+option+3.png",
        alt: ""
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Comece criando uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/get-started",
        children: "conta de desenvolvedor de aplicativos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contas de teste do desenvolvedor"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#app-developer-accounts",
        children: "contas de desenvolvedores de aplicativos"
      }), ", você pode criar contas de teste para testar aplicativos e integrações sem afetar dados reais do HubSpot. As contas de teste de desenvolvedor não espelham as contas de produção, mas são contas gratuitas da HubSpot com acesso a uma avaliação de 90 dias de muitos recursos corporativos, com as seguintes limitações:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub:"
        }), " você só pode enviar e-mails de marketing para endereços de usuários adicionados à sua conta de teste de desenvolvedor."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub:"
        }), " o número de páginas que você pode criar está sujeito aos seguintes limites:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**Páginas do site: **25"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**Ferramentas de blog: **1 blog com até 100 posts"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "**Landing pages: **25"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["**Fluxos de trabalho: **até 100.000 registros podem ser inscritos por dia em fluxos de trabalho criados em uma conta de teste de desenvolvedor. Se este limite diário for atingido:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Qualquer tentativa de inscrição adicional além desse limite será descartada."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Os usuários serão informados no aplicativo de que atingiram o limite diário de inscrições, bem como o horário em que o limite será atualizado."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode criar até 10 contas de teste por conta de desenvolvedor. Esse tipo de conta não pode sincronizar dados com outras contas e não podem ser conectadas a uma conta da HubSpot padrão. As contas de teste podem ser identificadas por um banner na parte superior da página, que inclui quantos dias faltam para que ele expire."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/trial-banner.png",
        alt: "trial-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba mais sobre como criar e gerenciar contas de teste."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar uma conta de teste de desenvolvedor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para criar uma conta de teste de desenvolvedor:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Na barra de navegação esquerda da sua conta de desenvolvedor de aplicativos, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Testar contas"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/left-sidebar-test-accounts.png",
            alt: "left-sidebar-test-accounts"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["No canto superior direito, clique em", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Criar uma conta de teste do aplicativo"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-test-account-create.png",
            alt: "developer-test-account-create"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Introduza um ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "nome de conta"
          }), " e clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Criar"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para acessar e gerenciar suas contas de teste de desenvolvedor:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na barra de navegação superior da sua conta de desenvolvedor de aplicativos, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Teste"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Passe o cursor do mouse sobre a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "conta"
        }), " que deseja gerenciar e selecione uma ação:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para excluir a conta, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Excluir"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Para copiar o ID da conta, renovar a conta ou renomeá-la, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Mais"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/developer-account-test-accounts.png",
        alt: "developer-account-test-accounts"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Renove uma conta de teste do desenvolvedor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As contas de teste do desenvolvedor expirarão após 90 dias se nenhuma chamada de API for feita para a conta. Você pode renovar manualmente a conta na página _Testes _no HubSpot ou fazer uma chamada de API para a conta. Lembre-se do seguinte ao tentar renovar a conta por meio da chamada de API:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Isso se aplica apenas às solicitações de API feitas usando ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/oauth/tokens",
          children: "tokens do OAuth"
        }), " gerados em um aplicativo na mesma conta de desenvolvedor que a conta de teste que você deseja renovar."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "As renovações não devem ser feitas mais de 30 dias antes da data de expiração da conta de teste."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contas de sandbox"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As contas de sandbox permitem testar as alterações sem afetar a conta padrão. Saiba mais sobre os diferentes tipos de contas de sandbox nas seções abaixo."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Contas de ambiente de testes padrão"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se tiver uma assinatura ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", você pode criar uma conta de ambiente de testes padrão que fornece um ambiente seguro no qual é possível testar novos fluxos de trabalho, integrações, páginas do site e outras alterações importantes sem afetar nada na sua conta padrão. Esses ambientes de testes copiam a estrutura da sua conta padrão."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Essas contas podem ser identificadas por um banner amarelo na parte superior da página que inclui o rótulo: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Você está em [[nome do sandbox], que é uma conta de ambiente de testes padrão"
      }), ". O banner também incluirá um link de volta à sua conta de produção para facilitar a troca."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/standard-sandbox-banner.png",
        alt: "standard-sandbox-banner"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre as contas de ambientes de testes padrão na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/set-up-a-hubspot-standard-sandbox-account",
        children: "Central de Conhecimento da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Contas de ambientes de testes do CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ambientes de testes do CMS são contas gratuitas e servem para criar e testar alterações no site sem afetar sua conta padrão ou site ativo. Semelhante às contas de desenvolvedor de aplicativos, as contas de ambientes de testes do CMS ", (0, _jsxRuntime.jsx)("u", {
        children: "não"
      }), " estão conectadas à sua conta padrão da HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "criar uma conta de ambiente de testes de CMS gratuitamente"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os sandboxes do CMS não têm banner, mas só têm acesso às ferramentas gratuitas da HubSpot e ao ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", sem a capacidade de conectar um domínio."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Contas de ambientes de testes de desenvolvimento (BETA)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você tiver uma assinatura do ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "_Sales Hub _"
      }), "ou ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Service Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", poderá criar uma conta de sandbox de desenvolvimento por meio da CLI para desenvolvimento local. Você pode obter acesso a contas de ambientes de testes de desenvolvimento, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/account/opt-your-hubspot-account-into-a-beta-feature",
        children: "optando pela versão beta das ferramentas para desenvolvedores do CRM em sua conta padrão da HubSpot. "
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ambientes de testes de desenvolvimento podem ser identificados por um banner amarelo na parte superior da página que informa: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Você está em [nome do sandbox], que é uma conta de ambiente de testes padrão."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/development-sandboxes-banner.png",
        alt: "development-sandboxes-banner"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contas de provedor do Marketplace"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As contas de fornecedor do Marketplace servem para criar e gerenciar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "listagens e transações do Marketplace de modelos"
      }), ". Para começar a vender no Marketplace de modelos, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://app.hubspot.com/signup-hubspot/asset-provider",
        children: "crie uma conta de provedor do Marketplace de modelos"
      }), ". Se você for um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners",
        children: "Parceiro da HubSpot"
      }), ", já terá a funcionalidade de fornecedor do Marketplace em sua conta de Parceiro."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Uma conta de fornecedor do Marketplace pode ser identificada por um item do ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Marketplace de modelos"
      }), " no menu de navegação superior."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/template-marketplace-acct.png",
        alt: "template-marketplace-acct"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}