"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66491233043;
const slug = exports.slug = 'reference/cms/hubl/tags/dnd-areas';
const title = exports.title = 'Drag-&-Drop-Bereich-HubL-Tags';
const name = exports.name = 'EMEA DACH (de) HubL Supported Tags | dnd_areas';
const metaDescription = exports.metaDescription = 'Die HubL-Tags werden zum Erstellen von Drag-&-Drop-Bereichen verwendet, mit denen Entwickler Seitenabschnitte erstellen können, die Layout-, Stil- und Inhaltsänderungen direkt in den Content-Editoren unterstützen.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "dnd_area",
  "label": "dnd_area",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_section",
  "label": "dnd_section",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_column",
  "label": "dnd_column",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_row",
  "label": "dnd_row",
  "parent": null
}, {
  "depth": 0,
  "id": "dnd_module",
  "label": "dnd_module",
  "parent": null
}, {
  "depth": 0,
  "id": "hintergrund",
  "label": "Hintergrund",
  "parent": null
}, {
  "depth": 1,
  "id": "background_color",
  "label": "background_color",
  "parent": "hintergrund"
}, {
  "depth": 1,
  "id": "background_linear_gradient",
  "label": "background_linear_gradient",
  "parent": "hintergrund"
}, {
  "depth": 1,
  "id": "background_image",
  "label": "background_image",
  "parent": "hintergrund"
}, {
  "depth": 0,
  "id": "wie-drag-%26-drop-stilparameter-auf-der-seite-angezeigt-werden",
  "label": "Wie Drag-&-Drop-Stilparameter auf der Seite angezeigt werden",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      ul: "ul",
      li: "li",
      em: "em",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      HubspotVideoPlayer,
      Alert,
      Tabs,
      Tab,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Drag-&-Drop-Bereich-HubL-Tags"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mit", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Drag-&-Drop-Bereichen"
      }), " können Entwickler Seitenabschnitte und globale Teilvorlagen erstellen, die Layout-, Stil- und Inhaltsänderungen direkt in den Content-Editoren unterstützen. Siehe das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/tutorial",
        children: "Tutorial zum Erstellen eines Drag-&-Drop-Bereichs"
      }), " für eine Einführung in das Einrichten von Drag-&-Drop-Bereichen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Drag-&-Drop-Bereiche basieren auf einem 12-spaltigen, responsiven Raster. Drag-&-Drop-Tags rendern Markup mit Klassennamen, die Spalten und Zeilen bezeichnen. Sie müssen ein Stylesheet hinzufügen, das auf diese Klassennamen ausgerichtet ist. Ein Beispiel für Layout-Stile, die Sie implementieren können, finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/master/src/css/objects/_layout.css",
        children: "CMS Hub Boilerplate"
      }), ". Ihr Stylesheet kann mithilfe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#require-css",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{{ require_css() }}"
        })
      }), " zur Vorlage hinzugefügt werden."]
    }), "\n", (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
      videoId: "172854037902"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Drag-&-Drop-Bereiche können zurzeit noch nicht in ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/blog",
          children: "Blog-Beiträgen"
        }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/overview#email",
          children: "E-Mail-Vorlagen"
        }), " verwendet werden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_area"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Drag-&-Drop-Bereich"
      }), " ist ein Container, der einen Teil der Webseite in Bezug auf Struktur, Design und Inhalt bearbeitbar macht. Der Text eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), "-Tags liefert den Standardinhalt für den Drag-&-Drop-Bereich."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Module selbst können keine Drag-&-Drop-Bereiche enthalten. Um Content-Autoren eine Oberfläche zum Hinzufügen einheitlicher Inhalte innerhalb eines Moduls zu bieten, sollten Sie stattdessen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#repeaters",
        children: "Wiederholfelder und -gruppen"
      }), " verwenden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "-Tag kann die folgenden Parameter enthalten:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "class"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Eine dem umschließenden div eines dnd_area hinzugefügte Klasse"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wird im Editor zur Kennzeichnung des Bereichs in der Seitenleiste verwendet."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Eingabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_area \"unique_name\", class=\"main\" %}\n\n{% end_dnd_area %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div class=\"container-fluid main\">\n  <div class=\"row-wrapper\">\n    <div class=\"row-fluid\">\n      <div\n        class=\"span12 widget-span widget-type-cell \"\n        style=\"\"\n        data-widget-type=\"cell\"\n        data-x=\"0\"\n        data-w=\"12\"\n      ></div>\n    </div>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), "-Tags können auch ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "-Tags enthalten."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten Sie:"
        }), " Ein Content-Autor kann die Vorlage einer Seite gegen eine andere Vorlage desselben Typs austauschen, je nachdem, ob sie ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "-Tags enthält."]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Mit dem visuellen Drag-&-Drop-Layout-Editor erstellte Vorlagen können gegen andere Drag-&-Drop-Vorlagen oder Code-Vorlagen mit oder ohne ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "-Tags ausgetauscht werden."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Code-Vorlagen mit ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "dnd_area"
          }), "-Tags können nur gegen andere Code-Vorlagen mit ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "-Tags ausgetauscht werden."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Code-Vorlagen ", (0, _jsxRuntime.jsx)("u", {
            children: "ohne"
          }), " ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area-"
          }), "-Tags können nur gegen andere Code-Vorlagen ohne ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "dnd_area"
          }), "-Tags ausgetauscht werden."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_section"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_section %}"
        })
      }), " ist eine Zeile der obersten Ebene und muss innerhalb eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), "-Tags verschachtelt werden. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#section-templates",
        children: "Abschnitte können auch als Vorlage definiert"
      }), " und dann in einen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/sections#section-context",
        children: "aufgenommen"
      }), " werden. So eignen sie sich ideal für die schnelle Erstellung einer einfachen Vorlage."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "-Tag kann die folgenden Parameter enthalten:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe einer ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "Hintergrundfarbe"
            }), " unterstützt. Kann auch als Zeichenfolge angegeben sein."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe eines ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "Hintergrundbildes"
            }), " unterstützt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe eines ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "linearen Farbverlaufshintergrundes"
            }), " unterstützt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "full_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein boolescher Wert, der festlegt, ob der Abschnitt die volle Breite haben soll oder durch einen inneren Container begrenzt wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe von Werten für den Rand in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), " unterstützt. Wenn keine Maßeinheit angegeben wird, wird die Standardeinheit ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " angewendet."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Pixelwert, der die maximale Breite eines Inhalts in einem Wrapping-Dictionary festlegt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe von Auffüllungswerten in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), " unterstützt. Wenn keine Maßeinheit angegeben wird, wird die Standardeinheit ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " angewendet."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Vertikale Ausrichtung des untergeordneten Inhalts. Zu den verfügbaren Optionen gehören:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Sie können nur einen Hintergrundparameter pro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_section"
        }), "-Tag verwenden."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Eingabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_section\n  background_image={\n    \"backgroundPosition\": \"MIDDLE_CENTER\",\n    \"backgroundSize\": \"cover\",\n    \"imageUrl\": \"https://example.com/path/to/image.jpg\"\n  },\n  margin={\n    \"top\": 32,\n    \"bottom\": 32\n  },\n  padding={\n    \"top\": \"1em\",\n    \"bottom\": \"1em\",\n    \"left\": \"1em\",\n    \"right\": \"1em\"\n  },\n  max_width=1200,\n  vertical_alignment=\"MIDDLE\"\n%}\n\n{% end_dnd_section %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"row-fluid-wrapper row-depth-1 row-number-1 unique_name-row-0-background-image dnd-section unique_name-row-0-max-width-section-centering unique_name-row-0-margin unique_name-row-0-padding\"\n>\n  <div class=\"row-fluid \"></div>\n  <!--end row-->\n</div>\n<!--end row-wrapper -->\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_section"
      }), "-Tags können auch die folgenden Tags enthalten:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_column"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#column",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_column %}"
        })
      }), " ist ein vertikaler Strukturbaustein, der eine oder mehrere durch seine übergeordnete Zeile definierten Layoutspalten einnimmt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["DieseS HubL-Tag muss innerhalb eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), "-Tags verschachtelt sein."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), "-Tag kann die folgenden Parameter enthalten:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe einer ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "Hintergrundfarbe"
            }), " unterstützt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe eines ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "Hintergrundbildes"
            }), " unterstützt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe eines ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "linearen Farbverlaufshintergrundes"
            }), " unterstützt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe von Werten für den Rand in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), " unterstützt. Wenn keine Maßeinheit angegeben wird, wird die Standardeinheit ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " angewendet."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Pixelwert, der die maximale Breite eines Inhalts in einem Wrapping-Dictionary festlegt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe von Auffüllungswerten in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), " unterstützt. Wenn keine Maßeinheit angegeben wird, wird die Standardeinheit ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " angewendet."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Vertikale Ausrichtung des untergeordneten Inhalts. Zu den verfügbaren Optionen gehören:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Sie können nur einen Hintergrundparameter pro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        }), "-Tag verwenden."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Eingabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_column\n  offset=0,\n  width=12,\n  background_color={\n    r: 255,\n    g: 0,\n    b: 0,\n    a: 1\n  },\n  margin={\n    \"top\": \"1em\",\n    \"bottom\": \"1em\"\n  },\n%}\n\n{% end_dnd_column %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"span12 widget-span widget-type-cell unique_name-column-1-margin unique_name-column-1-background-color unique_name-column-1-vertical-alignment dnd-column\"\n  style=\"\"\n  data-widget-type=\"cell\"\n  data-x=\"0\"\n  data-w=\"12\"\n></div>\n<!--end widget-span -->\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_column"
      }), "-Tag kann auch ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), "enthalten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_row"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#row",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_row %}"
        })
      }), " ist ein horizontaler Strukturbaustein, der ein verschachteltes 12-spaltiges Layout-Gitter erzeugt, in dem Spalten und Module platziert werden können."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["DieseS HubL-Tag muss innerhalb eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), "-Tags verschachtelt sein."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_row"
      }), "-Tag kann die folgenden Parameter enthalten:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_color"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe einer ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-color",
              children: "Hintergrundfarbe"
            }), " unterstützt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_image"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe eines ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-image",
              children: "Hintergrundbildes"
            }), " unterstützt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "background_linear_gradient"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe eines ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#background-linear-gradient",
              children: "linearen Farbverlaufshintergrundes"
            }), " unterstützt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "margin"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe von Werten für den Rand in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), " unterstützt. Wenn keine Maßeinheit angegeben wird, wird die Standardeinheit ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " angewendet."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "max_width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein Pixelwert, der die maximale Breite eines Inhalts in einem Wrapping-Dictionary festlegt."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "padding"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dict"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein Dictionary, das die Angabe von Auffüllungswerten in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "mm"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "Q"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "in"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pc"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "em"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ex"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ch"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rem"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "lh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vw"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vh"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmin"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vmax"
            }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "%"
            }), " unterstützt. Wenn keine Maßeinheit angegeben wird, wird die Standardeinheit ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "px"
            }), " angewendet."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Vertikale Ausrichtung des untergeordneten Inhalts. Zu den verfügbaren Optionen gehören:", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bitte beachten:"
        }), " Sie können nur einen Hintergrundparameter pro ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_row"
        }), "-Tag verwenden."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Eingabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_row\n  background_color={\n    r: 123,\n    g: 123,\n    b: 123,\n    a: 1.0\n  },\n  margin={\n    \"top\": 20,\n    \"bottom\": 200\n  },\n  padding={\n    \"top\": 20,\n    \"bottom\": 200,\n    \"left\": 20,\n    \"right\": 20\n  }\n%}\n\n{% end_dnd_row %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"row-fluid-wrapper row-depth-1 row-number-1 main-row-0-padding main-row-0-background-color main-row-0-margin\"\n>\n  <div class=\"row-fluid \"></div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Eine dnd_row kann auch die folgenden Tags enthalten:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_column"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "dnd_module"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview#module",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "{% dnd_module %}"
        })
      }), " ist ein ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Modul"
      }), ", das von einem div umschlossen wird, dem Layout, Stile und Inhalte hinzugefügt werden können. Das Modul wird durch Verweis auf seinen Pfad angegeben, bei dem es sich entweder um ein HubSpot-Standardmodul (unter Verwendung des ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/"
      }), "-Namespace) oder um von Ihnen erstellte Module handeln kann, deren Pfad im Dateiverzeichnisbaum des Design-Managers angegeben wird."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["DieseS HubL-Tag muss innerhalb eines ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% dnd_area %}"
      }), "-Tags verschachtelt sein."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "-Tag kann die folgenden Parameter enthalten:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "path"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Erforderlich"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Pfad zu einem Modul."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal_alignment"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Horizontale Positionierung, unterstützt:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "LEFT"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "CENTER"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RIGHT"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "offset"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Abstand von 0 im 12-Spalten-Raster."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "width"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Integer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Die Anzahl der Spalten, die das 12-Spalten-Raster belegen."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "flexbox_positioning"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "warning",
              children: "Veraltet"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Veraltet, nicht verwenden."
            }), " Verwenden Sie stattdessen ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "horizontal_alignment"
            }), " in Verbindung mit der ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "vertical_alignment"
            }), " der Zeile oder des Abschnitts.", (0, _jsxRuntime.jsx)("br", {}), "Flexbox-Positionswert für das Modul. Unterstützt eine Zeichenfolge zur Angabe der vertikalen Position, gefolgt von der horizontalen:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_RIGHT"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Haben Sie ein altes Modul, dessen Feldname mit einem der oben genannten ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_module"
        }), "-Parameter übereinstimmt? Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/using-modules-in-templates#passing-fields-that-have-dnd-associated-parameters",
          children: "Standardwerte über einen Feldparameter übergeben"
        }), ", ähnlich wie bei einer Feldgruppe."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Eingabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_module\n  path=\"@hubspot/rich_text\",\n  offset=0,\n  width=8,\n%}\n  {% module_attribute \"html\" %}\n    <h1>Hello, world!</h1>\n  {% end_module_attribute %}\n{% end_dnd_module %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Ausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<div\n  class=\"span8 widget-span widget-type-custom_widget\"\n  style=\"\"\n  data-widget-type=\"custom_widget\"\n  data-x=\"0\"\n  data-w=\"12\"\n>\n  <div\n    id=\"hs_cos_wrapper_main-module-1\"\n    class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module\"\n    style=\"\"\n    data-hs-cos-general-type=\"widget\"\n    data-hs-cos-type=\"module\"\n  >\n    <span\n      id=\"hs_cos_wrapper_module-1_\"\n      class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text\"\n      style=\"\"\n      data-hs-cos-general-type=\"widget\"\n      data-hs-cos-type=\"rich_text\"\n    >\n      <h1>Hello, world!</h1>\n    </span>\n  </div>\n</div>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Hintergrund"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Es gibt mehrere Möglichkeiten, Hintergründe für Drag-&-Drop-Elemente in Spalten, Abschnitten und Zeilen festzulegen: ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-image",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_image"
        })
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-linear-gradient",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_linear_gradient"
        })
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background-color",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "background_color"
        })
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_color"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Drag-&-Drop-Tags für Spalten, Abschnitte und Zeilen unterstützen Hintergrundfarben. Sie können die Standard-Hintergrundfarbe für ein Drag-&-Drop-Element mithilfe von ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_color"
      }), " festlegen. Dieser Parameter basiert auf einer Zeichenfolge und kann die folgenden Formate enthalten, die im folgenden Beispiel beschrieben werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section %}\n  // Hex Value (both 3 and 6 char length)\n  {% dnd_column background_color=\"#F7F7F7\" %}\n  {% end_dnd_column %}\n  {% dnd_column background_color=\"#FFF\" %}\n  {% end_dnd_column %}\n// Both RGB and RGBA\n  {% dnd_column background_color=\"rgb(255,255,255)\" %}\n  {% end_dnd_column %}\n  {% dnd_column background_color=\"rgba(0,0,0,.25)\" %}\n  {% end_dnd_column %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_linear_gradient"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Drag-&-Drop-Elemente für Spalten, Abschnitte und Zeilen unterstützen lineare Hintergrundverläufe. Mit dem Parameter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_linear_gradient"
      }), " können Sie einen Standardfarbverlauf festlegen. Der Parameter erwartet ein Dictionary. Derzeit werden nur zwei Farbstopps unterstützt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "direction"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Richtung des Farbverlaufs.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to bottom"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to top"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to left"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "to right"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "colors"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Array von Farbzeichenfolgen. Derzeit werden 2 Werte unterstützt, der Anfang und das Ende. Die Werte werden als Zeichenfolgen bereitgestellt, und die folgenden Formate werden unterstützt:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "RGB"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "RGBA"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "3 char hex"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "6 char hex"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "8 char hex"
                })
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n  background_linear_gradient={\n    \"direction\": \"to bottom\",\n    \"colors\": [\n      \"#1EB6C3\",\n      \"#2A2859\"\n    ]\n  }\n  %}\n  {% dnd_module path=\"@hubspot/rich_text\" width=\"6\" %}\n  {% end_dnd_module %}\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "background_image"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die Drag-&-Drop-Elemente für Spalten, Abschnitte und Zeilen unterstützen Hintergrundbilder. Sie können ein Standard-Hintergrundbild angeben, indem Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "background_image"
      }), "-Parameter verwenden, der ein Dictionary erwartet."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Schlüssel"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "backgroundPosition"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die Hintergrundposition des Bildes. Unterstützt eine Zeichenfolge zur Angabe der vertikalen Position, gefolgt von der horizontalen.", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "TOP_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "MIDDLE_RIGHT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_LEFT"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_CENTER"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "BOTTOM_RIGHT"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "backgroundSize"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Die CSS-Eigenschaft für die Hintergrundgröße, die für das Bild verwendet wird.", (0, _jsxRuntime.jsx)("br", {}), "Unterstützte Werte sind:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "cover"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "contain"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "auto"
                })
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "imageUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Absolute URL zum Bild."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_section\n  background_image = {\n      \"backgroundPosition\": \"MIDDLE_CENTER\",\n      \"backgroundSize\": \"cover\",\n      \"imageUrl\": \"https://www.example.com/bg-image.jpg\"\n    },\n%}\n  {% dnd_module path=\"@hubspot/rich_text\" width=\"6\" %}\n  {% end_dnd_module %}\n\n{% end_dnd_section %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Wie Drag-&-Drop-Stilparameter auf der Seite angezeigt werden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie stilbasierte Parameter wie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#background",
        children: "Hintergründe"
      }), ", Ränder oder Auffüllungen verwenden, werden die Klassennamen automatisch für Ihre Abschnitte, Spalten, Zeilen und Module berechnet. Die von Ihnen zugewiesenen Eigenschaftswerte werden dann zu diesen automatisch erstellten Klassennamen hinzugefügt, und der daraus resultierende CSS-Code wird dann vor dem abschließenden ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "</body>"
      }), "-Tag auf der Seite in einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<style>"
      }), "-Tag platziert."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/responsive-breakpoints",
        children: "Drag-&-Drop-Stile können auch an verschiedenen Haltepunkten unterschiedlich sein"
      }), ", um eine responsive Umgebung zu bieten."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}