"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35802824787;
const slug = exports.slug = 'guides/api/crm/objects/feedback-submissions';
const title = exports.title = 'CRM API | Feedback Submissions';
const name = exports.name = 'vNext Docs DP - Feedback Submissions';
const metaDescription = exports.metaDescription = 'Feedback submission records store information about individual survey submissions. The feedback submissions endpoints allow you to manage this data and sync it between HubSpot and other systems.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "retrieve-feedback-survey-submissions",
  "label": "Retrieve feedback survey submissions",
  "parent": null
}, {
  "depth": 0,
  "id": "feedback-submission-properties",
  "label": "Feedback submission properties",
  "parent": null
}, {
  "depth": 0,
  "id": "associations",
  "label": "Associations",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      strong: "strong",
      a: "a",
      h1: "h1",
      h2: "h2",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " this API is currently under development and is subject to change based on testing and feedback. By using these endpoints you agree to adhere to HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developer-terms",
          children: "Developer Terms"
        }), " & ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "Developer Beta Terms"
        }), ". You also acknowledge and understand the risk associated with testing an unstable API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Feedback Submissions (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot, feedback submissions store information submitted to a feedback survey. Surveys in HubSpot include ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/customer-feedback/how-do-i-send-a-customer-loyalty-survey",
        children: "Net Promoter Score (NPS)"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/customer-feedback/create-and-send-customer-satisfaction-surveys",
        children: "Customer Satisfaction (CSAT)"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/customer-feedback/how-do-i-send-a-customer-support-survey",
        children: "Customer Effort Score (CES)"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/customer-feedback/create-a-custom-survey",
        children: "custom surveys"
      }), ". Using the feedback submission endpoints, you can retrieve submission data about your feedback surveys."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about objects, properties, and associations APIs in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Understanding the CRM"
      }), " guide."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note"
        }), ": the feedback submissions endpoints are currently read only. Feedback submissions cannot be submitted or edited through the API."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve feedback survey submissions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To view details about your feedback survey submissions, you can retrieve submission data in bulk for multiple surveys, or for an individual survey. For example, you can use the API to see all survey responses for a specific NPS survey."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To retrieve submissions, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/feedback_submissions/{feedbackSubmissionId}"
      }), ". By default, the following properties are returned for each submission: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_createdate"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_lastmodifieddate"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), ", but you also can retrieve additional ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties",
        children: "properties"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to retrieve survey submissions with the source and sentiment of the submissions, your request URL would look like: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/feedback_submissions?properties=hs_sentiment,hs_survey_channel"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Feedback submission properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Feedback submissions have ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties#default-feedback-submission-properties",
        children: "default properties"
      }), " that contain information about the survey, submission answers, and the date the survey was submitted. You can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/customer-feedback/feedback-submission-properties#custom-feedback-submission-properties",
        children: "create custom submissions properties"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Feedback submissions properties ", (0, _jsxRuntime.jsx)("u", {
        children: "cannot"
      }), " be created or edited via API. You can only create properties in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/customer-feedback/create-a-custom-survey#survey",
        children: "feedback surveys tool within HubSpot"
      }), ", and the properties cannot be edited after creation."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Feedback submissions can be associated with contact and ticket records. Learn how to associate objects with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "associations API."
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}