"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 99666557052;
const slug = exports.slug = 'guides/cms/marketplace/module-requirements';
const title = exports.title = 'Marketplace de modelos | Requisitos para módulos';
const name = exports.name = 'LATAM BR (PT) Asset Marketplace | Module requirements';
const metaDescription = exports.metaDescription = 'Saiba mais sobre os requisitos que os módulos de um tema devem cumprir para serem enviados para o Marketplace de ativos da HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "restri%C3%A7%C3%B5es-de-m%C3%B3dulo",
  "label": "Restrições de módulo",
  "parent": null
}, {
  "depth": 0,
  "id": "conte%C3%BAdo-do-m%C3%B3dulo",
  "label": "Conteúdo do módulo",
  "parent": null
}, {
  "depth": 1,
  "id": "r%C3%B3tulos-do-m%C3%B3dulo-e-texto-de-ajuda",
  "label": "Rótulos do módulo e texto de ajuda",
  "parent": "conte%C3%BAdo-do-m%C3%B3dulo"
}, {
  "depth": 1,
  "id": "conte%C3%BAdo-padr%C3%A3o",
  "label": "Conteúdo padrão",
  "parent": "conte%C3%BAdo-do-m%C3%B3dulo"
}, {
  "depth": 1,
  "id": "%C3%ADcones-do-m%C3%B3dulo",
  "label": "Ícones do módulo",
  "parent": "conte%C3%BAdo-do-m%C3%B3dulo"
}, {
  "depth": 1,
  "id": "m%C3%B3dulos-que-exigem-contas-de-terceiros",
  "label": "Módulos que exigem contas de terceiros",
  "parent": "conte%C3%BAdo-do-m%C3%B3dulo"
}, {
  "depth": 0,
  "id": "campos-do-m%C3%B3dulo",
  "label": "Campos do módulo",
  "parent": null
}, {
  "depth": 1,
  "id": "configura%C3%A7%C3%A3o-de-fields.json-e-module.html",
  "label": "Configuração de fields.json e module.html",
  "parent": "campos-do-m%C3%B3dulo"
}, {
  "depth": 0,
  "id": "qualidade-do-c%C3%B3digo-do-m%C3%B3dulo",
  "label": "Qualidade do código do módulo",
  "parent": null
}, {
  "depth": 1,
  "id": "os-m%C3%B3dulos-devem-ser-aut%C3%B4nomos",
  "label": "Os módulos devem ser autônomos",
  "parent": "qualidade-do-c%C3%B3digo-do-m%C3%B3dulo"
}, {
  "depth": 2,
  "id": "m%C3%B3dulos-do-tema",
  "label": "Módulos do tema",
  "parent": "qualidade-do-c%C3%B3digo-do-m%C3%B3dulo"
}, {
  "depth": 2,
  "id": "m%C3%B3dulos-independentes",
  "label": "Módulos independentes",
  "parent": "qualidade-do-c%C3%B3digo-do-m%C3%B3dulo"
}, {
  "depth": 1,
  "id": "restri%C3%A7%C3%B5es-de-c%C3%B3digo-para-m%C3%B3dulos-independentes",
  "label": "Restrições de código para módulos independentes",
  "parent": "qualidade-do-c%C3%B3digo-do-m%C3%B3dulo"
}, {
  "depth": 1,
  "id": "categorias",
  "label": "Categorias",
  "parent": "qualidade-do-c%C3%B3digo-do-m%C3%B3dulo"
}, {
  "depth": 1,
  "id": "seletores-de-nome-de-classe",
  "label": "Seletores de nome de classe",
  "parent": "qualidade-do-c%C3%B3digo-do-m%C3%B3dulo"
}, {
  "depth": 1,
  "id": "estilos-e-javascript",
  "label": "Estilos e JavaScript",
  "parent": "qualidade-do-c%C3%B3digo-do-m%C3%B3dulo"
}, {
  "depth": 0,
  "id": "organiza%C3%A7%C3%A3o-de-campo",
  "label": "Organização de campo",
  "parent": null
}, {
  "depth": 1,
  "id": "guia-conte%C3%BAdo",
  "label": "Guia Conteúdo",
  "parent": "organiza%C3%A7%C3%A3o-de-campo"
}, {
  "depth": 1,
  "id": "guia-estilos",
  "label": "Guia Estilos",
  "parent": "organiza%C3%A7%C3%A3o-de-campo"
}, {
  "depth": 1,
  "id": "exemplos-de-organiza%C3%A7%C3%A3o-de-campo",
  "label": "Exemplos de organização de campo",
  "parent": "organiza%C3%A7%C3%A3o-de-campo"
}, {
  "depth": 2,
  "id": "predefini%C3%A7%C3%B5es",
  "label": "Predefinições",
  "parent": "organiza%C3%A7%C3%A3o-de-campo"
}, {
  "depth": 2,
  "id": "agrupamento-multin%C3%ADvel",
  "label": "Agrupamento multinível",
  "parent": "organiza%C3%A7%C3%A3o-de-campo"
}, {
  "depth": 2,
  "id": "agrupamento-de-campos-individuais",
  "label": "Agrupamento de campos individuais",
  "parent": "organiza%C3%A7%C3%A3o-de-campo"
}, {
  "depth": 0,
  "id": "aliases",
  "label": "Aliases",
  "parent": null
}, {
  "depth": 1,
  "id": "implementa%C3%A7%C3%A3o-simples",
  "label": "Implementação simples",
  "parent": "aliases"
}, {
  "depth": 0,
  "id": "example-of-a-v0-module",
  "label": "Example of a v0 module",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v1-module",
  "label": "Example of a v1 module",
  "parent": null
}, {
  "depth": 0,
  "id": "complex-implementation",
  "label": "Complex implementation",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v0-module",
  "label": "Example of a v0 module",
  "parent": null
}, {
  "depth": 0,
  "id": "example-of-a-v1-module",
  "label": "Example of a v1 module",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a",
      ul: "ul",
      li: "li",
      h3: "h3",
      em: "em",
      img: "img",
      code: "code",
      h4: "h4",
      strong: "strong",
      pre: "pre",
      ol: "ol"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      Alert,
      DndModule,
      IFrame,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Requisitos para módulos do Marketplace de modelos da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre os requisitos para enviar um módulo para o Marketplace de modelos. Esses requisitos se aplicam aos módulos de um tema e aos módulos independentes."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Restrições de módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os módulos não devem conter ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/storage/hubdb/overview",
        children: "HubDB"
      }), ", chamadas para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
        children: "funções sem servidor"
      }), " ou o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#crm-object",
        children: "campo de objeto do CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os seguintes tipos de módulos não devem ser criados como módulos independentes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos de largura inteira"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Formulários e formulários de várias etapas"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos espaçadores ou que criam uma estrutura de página não relacionada à interface do usuário"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos que duplicam a funcionalidade do módulo padrão"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos específicos de comércio"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Módulos específicos de e-mail"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Conteúdo do módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Saiba mais sobre os requisitos para rótulos do módulo e texto de ajuda, campos e conteúdo padrão."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Rótulos do módulo e texto de ajuda"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os módulos devem ter rótulos descritivos que transmitam sua finalidade. O rótulo ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hero Banner com rolagem Parallax"
        }), " é descritivo, ao contrário dos rótulos ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hero Banner"
        }), " e ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Galeria"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os rótulos do módulo não devem conter números, como em ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Hero Banner 01"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Os rótulos dos módulos não devem conter sublinhados."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os rótulos do módulo não devem conter abreviações, como ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Col"
        }), " em vez de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Coluna"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os módulos devem conter ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration#meta-json",
          children: "texto de ajuda em linha"
        }), " (quando aplicável) para informar melhor como usar o módulo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os módulos não devem ser nomeados da mesma forma que um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "módulo padrão"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para módulos independentes, o rótulo do módulo deve corresponder ao nome na listagem do modelo. Por exemplo, se a listagem do seu modelo for ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "SuperAwesome Banner with Scrolling"
        }), ", o rótulo do módulo deverá ser o mesmo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/listing-name.png",
          alt: "listing-name"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/module-label.png",
          alt: "module-label"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Conteúdo padrão"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O campo padrão não pode incluir texto ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Lorem ipsum"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O conteúdo padrão do campo deve representar o propósito do campo:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ao incluir campos de menu, os módulos devem usar ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Selecionar um menu"
            }), " como opção de conteúdo padrão."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ao incluir campos de formulário, os módulos devem usar ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Selecionar um formulário"
            }), " como opção de conteúdo padrão."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Ao incluir campos de seletor de blog, os módulos devem usar ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Selecionar um blog"
            }), " como a opção de conteúdo padrão."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Se não fizer sentido adicionar conteúdo padrão a um módulo, use um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/standard-tags#editor-placeholders",
          children: "espaço reservado"
        }), " para ajudar o criador de conteúdo a visualizar o espaço que ele preencherá com conteúdo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Ícones do módulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os módulos devem incluir um ícone personalizado atribuído ao módulo (substituindo o ícone padrão). Não use logotipos de empresas como ícones, como logotipos da Apple ou da Amazon. Saiba mais sobre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration#adding-an-icon",
        children: "ícones do módulo"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Módulos que exigem contas de terceiros"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para módulos individuais, se o módulo exigir uma conta de terceiros, isso deve ser incluído na descrição do modelo. Por exemplo, se o seu módulo usar a Plataforma do Google Maps, você precisará incluir uma observação: ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "\"O uso deste módulo requer uma conta do Google Cloud (Plataforma do Google Maps)\"."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Campos do módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Revise os requisitos específicos para os módulos de um tema e os módulos independentes abaixo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para módulos de um tema:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Por exemplo, os módulos devem conter texto de ajuda integrado e conteúdo padrão específico para determinados campos."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Uma parte da ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#color",
              children: "cor"
            }), " e do ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#logo",
              children: "logotipo"
            }), " do tema deve ser herdada das ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/fields/brand-and-settings-inheritance",
              children: "configurações de marca"
            }), " da conta.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Pelo menos três campos de cor devem herdar cores das configurações de marca da conta. Campos de cor extras podem ser predefinidos para outras cores, incluindo preto e branco."
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "Pelo menos um campo de logotipo deve ser herdado das configurações de marca da conta. Se estiver usando um campo de imagem para renderizar um logotipo, o campo de imagem não precisa ser herdado das configurações da marca."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para ambos os módulos em um tema e módulos independentes:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Os nomes dos campos do módulo devem descrever a intenção do campo. Por exemplo, se um campo de texto é usado para incluir o cargo de uma pessoa, ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Cargo"
            }), " seria uma descrição adequada, ao contrário de ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Título"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Todos os módulos padrão da HubSpot devem ser estilizados e exibidos corretamente em todos os modelos enviados."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Configuração de fields.json e module.html"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para garantir uma funcionalidade compatível entre temas e módulos independentes, os módulos devem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#inherited-fields",
        children: "herdar"
      }), " os campos de cor e fonte com a definição de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "default_value_path"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "property_value_paths"
      }), ", ou ambos, no arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ", e uma referência aos campos do tema no arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.html"
      }), " deve ser adicionada. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/general-requirements",
        children: "Saiba mais sobre esses requisitos."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Qualidade do código do módulo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Os módulos devem ser autônomos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Módulos do tema"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos os arquivos necessários para o módulo do tema, como CSS ou JavaScript, devem estar contidos na pasta do tema e incluídos no diretório do tema. Você pode usar o recurso Arquivados vinculados no Gerenciador de design. Ou inclua os arquivos usando as funções ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: "require_js()"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-css",
        children: "require_css()"
      }), " com um caminho relativo para o arquivo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para bibliotecas comuns, como slick.js, você pode incluí-las usando as funções ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js()"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css()"
      }), " com um URL absoluto para a CDN onde estão hospedadas."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " não use URLs absolutos para ativos contidos no seu portal de desenvolvimento, pois as referências entre portais não serão resolvidas."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Módulos independentes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para módulos independentes, todos os arquivos CSS e Javascript devem estar contidos em ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), ". Como alternativa, inclua os arquivos usando as funções ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js()"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css()"
      }), " com um URL absoluto para a CDN onde eles estão hospedados. Não é possível usar o recurso Arquivos vinculados no Gerenciador de design, pois ele está disponível apenas para módulos de tema."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Como ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " está incluído no DOM antes de qualquer arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_js"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), ", o JavaScript contido na seção ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " deve ser adiado usando a anotação abaixo:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "document.addEventListener('DOMContentLoaded', function () {\n  // Put Javascript here\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos os scripts e arquivos devem ser renderizados no cabeçalho do ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#require-js",
        children: "HTML"
      }), " do módulo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Restrições de código para módulos independentes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As restrições a seguir se aplicam apenas a módulos independentes:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["É recomendado usar ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "http://vanilla-js.com/",
          children: "Vanilla JS"
        }), " sempre que possível. Adicionar uma biblioteca jQuery a um site que não usa jQuery pode causar conflitos e tornar a página mais lenta."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ao usar uma biblioteca jQuery, use a função ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/functions#require-js",
          children: "require_js()"
        }), " para incluir a biblioteca, caso a caixa de seleção (Booleano) para jQuery esteja desmarcada nas configurações do portal, para evitar conflitos de várias bibliotecas."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "{% if not site_settings.include_jquery %}\n{{ require_js(\"https://code.jquery.com/jquery-3.7.0.min.js\", \"footer\") }}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Categorias"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Todos os módulos independentes devem ter pelo menos uma categoria. Os módulos enviados como parte de um tema não precisam ter categorias, mas é recomendado incluir pelo menos uma. Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "adicionar categorias aos módulos"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seletores de nome de classe"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Qualquer seletor de nome de classe deve incluir como prefixo o nome do módulo, substituindo espaços por hifens. Por exemplo, abaixo está o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " de um botão chamado ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "example-button"
        }), ", com cada nome de classe e seletor CSS refletindo seu nome."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<style>\n{% scope_css %}\n{# Button wrapper #}\n {% if module.styles.group_alignment.alignment %}\n  .example-button-wrapper {\n   text-align: {{ module.styles.group_alignment.alignment.horizontal_align }};\n   }\n {% endif %}\n\n{# Button #}\n\n.example-button {\n {% if module.styles.group_background.color.color %}\n  background-color: rgba({{ module.styles.group_background.color.color|convert_rgb }}, {{ module.styles.group_background.color.opacity / 100 }});\n {% endif %}\n }\n {% end_scope_css %}\n</style>\n{% end_require_css %}\n\n{##### Module HTML #####}\n\n<div class=\"example-button-wrapper\">\n <a href=\"{{ href }}\" class=\"example-button\"\n {% if module.button_link.open_in_new_tab %}target=\"_blank\"{% endif %}\n {% if rel %}rel=\"{{ rel|join(\" \") }}\"{% endif %}\n >\n  {{ module.button_text }}\n </a>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Estilos e JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Estilos:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Os módulos devem ter um grupo de estilos não vazio."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Não é recomendável codificar estilos inline dentro de módulos. Em vez disso, use estilos inline dinâmicos, permitindo que os campos controlem o estilo."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["JavaScript:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "O JavaScript deve poder representar as várias instâncias de um módulo. O Javascript no Painel JS só carregará uma vez por página, independentemente do número de ocorrências do módulo."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "O Javascript deve fazer referência aos elementos DOM pelos nomes de classe específicos do módulo para garantir que os elementos externos não sejam afetados acidentalmente."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ao criar módulos, você pode usar uma variável interna chamada ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{name}}"
      }), ". Essa variável obtém o ID da instância do módulo (que pode ser usado somente no painel HTML+HubL) para ajudar na marcação CSS e JS em módulos complexos. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/files#require-css-block",
        children: "Saiba mais sobre isso na documentação para desenvolvedores."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Organização de campo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os requisitos de organização e agrupamento de campos a seguir devem ser atendidos."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Conteúdo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Quando houver pelo menos um controle dentro de um grupo de campos, todos os controles devem ser agrupados em categorias rotuladas por função."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Os campos do módulo adicionados à guia ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Conteúdo"
        }), " devem permitir personalizar o conteúdo de um módulo. Por exemplo, controles para imagem, ícone, texto alternativo e controles de link."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Guia Estilos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os grupos de campos de estilo do módulo devem ser consistentes e seguir um padrão. Veja abaixo uma ordem recomendada para grupos de campos de estilo. Esses grupos podem estar no nível superior ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#multi-level-grouping",
        children: "aninhados em um grupo"
      }), ". Grupos vazios também podem ser removidos:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#presets",
          children: "Predefinições"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Texto"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Plano de fundo"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Borda"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Passar o mouse"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Canto"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Espaçamento"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Alinhamento"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Grupos de estilos personalizados que não se encaixam nos itens acima"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Avançado"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os seguintes tipos de campos devem estar contidos na guia de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Estilos"
      }), ", se presente:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#alignment",
          children: "Alinhamento"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#background-image",
          children: "Imagem de fundo"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#border",
          children: "Borda"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#color",
          children: "Cor"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#font",
          children: "Fonte"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#gradient",
          children: "Gradiente"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#spacing",
          children: "Espaçamento"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#text-alignment",
          children: "Alinhamento de texto"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Ao mover campos da guia ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Conteúdo"
        }), " para a guia ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Estilos"
        }), ", aprenda como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#aliases",
          children: "usar o mapeamento de alias"
        }), " para preservar o estilo dos módulos que já estão em uso nas páginas ativas."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "As opções de animação devem estar sempre posicionadas na parte inferior da lista de grupos de campos."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["As opções que permitem que os criadores de conteúdo adicionem snippets de código ou CSS devem ser agrupadas no final da lista de grupos de campos em um campo rotulado como ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Avançado"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Os controles devem ser padronizados em todos os módulos. Por exemplo, todos os elementos que podem ter um controle de raio de borda devem oferecer esse controle. Evite oferecer controles em alguns módulos que estão ausentes em outros."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Os campos do módulo adicionados à guia ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Estilo"
          }), " devem permitir adicionar estilos ao módulo. Por exemplo:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Opções de estilo, como cor, estilo de texto, alinhamento, espaçamento, borda e raio de canto."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Animações como, por exemplo, efeitos deslizantes e ao passar o mouse."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Predefinições como temas escuros e claros que devem alterar muitos estilos ao mesmo tempo."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Exemplos de organização de campo"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Predefinições"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As predefinições podem ser usadas para fornecer aos criadores de conteúdo um conjunto limitado de opções, muitas vezes vinculadas às configurações do tema. Por exemplo, o módulo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ícone"
      }), " incluído no tema Crescimento contém predefinições para cores ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Escuras"
      }), " e ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Claras"
      }), ", o que permite manter a consistência quando usado em todo o site."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Agrupamento multinível"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ao decidir se deseja manter os campos de estilo no nível superior ou aninhá-los, considere o exemplo a seguir."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["O módulo ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Ícone"
            }), " incluído no tema Crescimento lista todos os estilos no nível superior porque trata-se de um componente e, portanto, suas opções de estilo afetam somente ele."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/growth-theme-icon-module.png",
            alt: "growth-theme-icon-module"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["O módulo de ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Cartão do palestrante"
            }), " incluído no tema Crescimento contém vários componentes: a imagem do cartão e seu conteúdo de texto. Os estilos dos módulos são, portanto, agrupados por componente para que o criador de conteúdo tenha um processo mais claro para aplicar estilos a cada componente."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/growth-theme-speaker-card.png",
            alt: "growth-theme-speaker-card"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Agrupamento de campos individuais"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O módulo de botão abaixo contém agrupamentos para ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Predefinições"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Texto"
      }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Plano de fundo"
      }), " e muito mais. Embora o grupo de campos ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Canto"
      }), " contenha apenas o controle de raio do canto, ele ainda está agrupado para criar uma experiência de criação de conteúdo uniforme."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/module-requirements-2_1.png",
        alt: "module-requirements-2_1"
      }), (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/button-styles.png",
        alt: "button-styles"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Aliases"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O mapeamento de aliases permite criar mapeamentos de campo em um módulo para que você possa mover, renomear ou substituir seus campos sem impactar as páginas que estão usando o módulo."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, um módulo está sendo usado em uma página ativa. Você deseja mover alguns campos para a guia ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: [(0, _jsxRuntime.jsx)(_components.em, {
          children: "Estilos"
        }), ","]
      }), " como cor ou fonte, mas um criador de conteúdo já selecionou valores para esses campos no editor. Se você movesse esses campos sem configurar o mapeamento de aliases, o HubSpot não seria capaz de realocar esses campos e eles seriam revertidos para seus valores padrão, o que iria desfazer o estilo na página ativa."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em vez disso, você pode adicionar uma propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "aliases_mapping"
      }), " a um campo para mapeá-la para outro. Então, quando um valor não houver sido definido para o campo original, o HubSpot verificará se existe um valor no campo mapeado. Se também não existir nenhum valor no campo mapeado, ele usará o valor padrão. Essa propriedade pode ser usada para mapear valores de campo entre versões diferentes de um módulo somente quando o tipo de dados armazenado do campo antigo for igual ao tipo de dados armazenado do novo campo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para um passo a passo visual desse recurso, confira o vídeo abaixo."
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/SvESTX8SLJQ",
      maxHeight: "367px",
      maxWidth: "700px",
      allowFullScreen: true
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para migrar campos existentes para aliases:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Crie novos campos e mapeie-os para campos antigos usando a propriedade ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "aliases_mapping"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Remova a definição do campo antigo."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Atualize o arquivo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module.html"
        }), " para usar a definição dos campos novos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Você não pode mapear campos que são de um tipo de dados diferente entre si. Por exemplo, você não pode mapear um campo de gradiente de plano de fundo para um campo de imagem. O valor armazenado tem que ser um valor válido para o tipo do novo campo."
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "Ao criar um novo campo com um mapeamento de alias para um campo antigo, os valores padrão e as propriedades necessárias de ambos os campos devem ser os mesmos."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja abaixo exemplos de implementação disso para mudanças simples e complexas:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#simple-implementation",
          children: "Implementação simples"
        }), ": mapear um campo de cor para outro novo campo de cor."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#complex-implementation",
          children: "Implementação complexa"
        }), ": mapear um campo numérico para o subcampo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "tamanho"
        }), " de um campo de fonte para controlar o tamanho da fonte."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Implementação simples"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Em situações simples, o tipo de campo do campo antigo e o tipo de campo do novo campo devem ser os mesmos. Por exemplo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Campo de cor antigo para novo campo de cor."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Campo de texto antigo para novo campo de texto."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Campo de espaçamento antigo para novo campo de espaçamento."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja abaixo um exemplo de uso de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "aliases_mapping"
      }), " ao mover um campo de cor da guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Conteúdo"
      }), " do módulo para a guia ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Estilos"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v0 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"label\": \"Button Color\",\n    \"name\": \"old_button_color_field\",\n    \"type\": \"color\",\n    \"required\": true,\n    \"default\": {\n      \"color\": \"#FFFFFF\",\n      \"opacity\": 100\n    }\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v1 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"label\": \"Styles\",\n    \"name\": \"styles\",\n    \"type\": \"group\",\n    \"tab\": \"STYLE\",\n    \"children\": [\n      {\n        \"label\": \"Button Color\",\n        \"name\": \"new_button_color_field\",\n        \"type\": \"color\",\n        \"required\": true,\n        \"aliases_mapping\": {\n          \"property_aliases_paths\": {\n            \"new_button_color_field\": [\"old_button_color_field\"]\n          }\n        },\n        \"default\": {\n          \"color\": \"#FFFFFF\",\n          \"opacity\": 100\n        }\n      }\n    ]\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Complex implementation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Em situações mais complexas, você também pode mapear campos para subcampos ou outros tipos de campo de módulos, desde que o tipo de dados seja o mesmo e o tipo de subcampo do novo campo corresponda. Subcampos são as propriedades dentro do objeto de valor armazenado do campo. Por exemplo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Mapear um campo de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Rich text"
        }), " para um campo de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Texto"
        }), ", pois os valores em ambos os campos são armazenados como strings."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Consolidar campos de tipografia, como mudar de um campo numérico para tamanho de fonte, para usar um campo de fonte (que possui um subcampo de tamanho de fonte). Você pode adicionar um alias para o subcampo de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "tamanho"
        }), " para mapeá-lo para o campo numérico antigo usando a notação de ponto."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Veja abaixo um exemplo de alteração da opção de tamanho de fonte de um campo numérico para um campo de fonte que possui um subcampo de tamanho de fonte."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v0 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"name\": \"my_number_field\",\n    \"label\": \"Number field\",\n    \"required\": false,\n    \"locked\": false,\n    \"display\": \"text\",\n    \"step\": 1,\n    \"type\": \"number\",\n    \"min\": null,\n    \"max\": null,\n    \"inline_help_text\": \"\",\n    \"help_text\": \"\",\n    \"default\": null\n  }\n]\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Example of a v1 module"
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "[\n  {\n    \"name\": \"my_font_field\",\n    \"label\": \"font_field\",\n    \"required\": false,\n    \"locked\": false,\n    \"inline_help_text\": \"\",\n    \"help_text\": \"\",\n    \"load_external_fonts\": true,\n    \"type\": \"font\",\n    \"aliases_mapping\": {\n      \"property_aliases_paths\": {\n        \"my_font_field.size\": [\"my_number_field\"]\n      }\n    },\n    \"default\": {\n      \"size\": 12,\n      \"font\": \"Merriweather\",\n      \"font_set\": \"GOOGLE\",\n      \"size_unit\": \"px\",\n      \"color\": \"#000\",\n      \"styles\": {}\n    }\n  }\n]\n"
          })
        })
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}