"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 160601073957;
const slug = exports.slug = 'getting-started/tools-to-help-you-build';
const title = exports.title = 'Ferramentas para ajudá-lo a construir';
const name = exports.name = 'Ferramentas para ajudá-lo a construir (documentos do desenvolvedor 2024)';
const metaDescription = exports.metaDescription = 'Saiba mais sobre as diferentes ferramentas e recursos disponíveis para ajudá-lo a criar no HubSpot.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "cli-da-hubspot",
  "label": "CLI da HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "extens%C3%A3o-para-vs-code-da-hubspot",
  "label": "Extensão para VS Code da HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "bibliotecas-de-clientes",
  "label": "Bibliotecas de clientes",
  "parent": null
}, {
  "depth": 0,
  "id": "postman",
  "label": "Postman",
  "parent": null
}, {
  "depth": 0,
  "id": "projetos-beta",
  "label": "Projetos (BETA)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Ferramentas para ajudá-lo a construir"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A HubSpot oferece uma variedade de ferramentas para ajudá-lo a desenvolver na sua plataforma de desenvolvedores. As APIs da HubSpot são flexíveis e você sempre pode utilizar seu ambiente de desenvolvimento preferido para criar integrações, mas as ferramentas listadas abaixo estão disponíveis para todos os desenvolvedores para aumentar a produtividade e ajudar a simplificar o fluxo de trabalho de desenvolvimento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CLI da HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você está desenvolvendo no HubSpot CMS, pode instalar a CLI para desenvolver localmente no HubSpot. Depois de instaladas, você pode usar suas ferramentas preferidas em seu ambiente preferido. Além disso, é possível configurar integração contínua com uma conta do GitHub para rastrear alterações e colaborar com outros desenvolvedores na sua organização."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "instalar a CLI da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Extensão para VS Code da HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["A extensão para Visual Studio Code da HubSpot permite centralizar seu fluxo de trabalho de desenvolvimento sem precisar sair do VS Code. Depois de instalada, você pode usar a CLI da HubSpot para autenticar e gerenciar suas contas conectadas, bem como aproveitar o destaque de sintaxe do HubL e o suporte ao preenchimento automático. Você também pode sincronizar arquivos com o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/a-quick-tour-of-the-design-manager",
        children: "gerenciador de design"
      }), " da sua conta padrão."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
        children: "instalar e usar a extensão para VS Code da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bibliotecas de clientes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "A HubSpot oferece bibliotecas de clientes para que você possa usar sua linguagem de programação preferida ao utilizar as APIs. As bibliotecas de clientes estão disponíveis em JavaScript, Ruby, Python e PHP. Cada biblioteca permite invocar chamadas de função para ler, editar, criar e excluir dados na sua conta da HubSpot, sem precisar criar solicitações de API manualmente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como configurar e usar as bibliotecas de cliente ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-nodejs",
        children: "Node"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-php",
        children: "PHP"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-ruby",
        children: "Ruby"
      }), " e ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-api-python",
        children: "Python"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Postman"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Postman é um cliente de API completo que você pode usar para enviar solicitações autenticadas para o HubSpot. Você também pode navegar por coleções selecionadas de todos os pontos de extremidade públicos da API da HubSpot. Essas coleções são monitoradas regularmente pela HubSpot para manter as especificações de todos os campos de solicitação e resposta atualizados."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Baixe o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/",
        children: "cliente Postman"
      }), " e navegue pelas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.postman.com/hubspot",
        children: "coleções oficiais de Postman da HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Projetos (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os projetos são um contêiner de alto nível que você pode usar para personalizar o CRM da HubSpot. Com a CLI da HubSpot, você pode construir e implantar aplicativos privados, criar extensões de UI e desenvolver funções sem servidor para ler ou gravar dados dinamicamente em componentes personalizados no CRM. Depois de implantado, você pode gerenciar seu projeto no HubSpot, permitindo revisar o histórico de compilação e monitorar chamadas de API."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre como ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/intro/create-a-project",
        children: "criar um projeto do zero"
      }), ", ou siga o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/quickstart",
        children: "Guia de início rápido de extensões de UI"
      }), ". Se não souber por onde começar, você também pode conferir os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/sample-projects/overview",
        children: "exemplos de projetos"
      }), " da HubSpot."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}