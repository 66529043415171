"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 49334172328;
const slug = exports.slug = 'guides/cms/content/fields/best-practices';
const title = exports.title = 'Module and Theme Field Best Practices';
const name = exports.name = 'Module and theme field best practices';
const metaDescription = exports.metaDescription = 'The ability to create fields and group fields poses a User Experience (UX) issue. We encourage you to use this page as a guide to providing an intuitive experience for content creators.';
const featuredImage = exports.featuredImage = 'https://www.hubspot.com/hubfs/options%20and%20style%20tabs.png';
const featuredImageAltText = exports.featuredImageAltText = 'Module style fields tab in CMS Hub page editor';
const position = exports.position = 2;
const toc = exports.toc = [{
  "depth": 0,
  "id": "style-fields-vs.-content-fields",
  "label": "Style fields vs. content fields",
  "parent": null
}, {
  "depth": 0,
  "id": "organizing-fields",
  "label": "Organizing fields",
  "parent": null
}, {
  "depth": 1,
  "id": "example",
  "label": "Example",
  "parent": "organizing-fields"
}, {
  "depth": 0,
  "id": "required-fields",
  "label": "Required fields",
  "parent": null
}, {
  "depth": 0,
  "id": "typography",
  "label": "Typography",
  "parent": null
}, {
  "depth": 0,
  "id": "toggle-vs-checkbox-in-boolean-fields",
  "label": "Toggle vs checkbox in boolean fields",
  "parent": null
}, {
  "depth": 0,
  "id": "related-articles",
  "label": "Related articles",
  "parent": null
}, {
  "depth": 2,
  "id": "tutorials",
  "label": "Tutorials",
  "parent": "related-articles"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      h2: "h2",
      a: "a",
      em: "em",
      img: "img",
      h3: "h3",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Module and theme fields best practices"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When incorporating fields into your module or theme, there are a few best practices to keep in mind, including:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Grouping fields together"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Organizing fields logically across modules"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Providing a consistent styling experience with style fields"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In this article, learn about some recommended best practices to create an efficient and consistent module and theme field editing experience for content creators."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Style fields vs. content fields"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can add ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "style fields"
      }), " to both modules and themes, and they enable the content creator to adjust the module's appearance, such as borders or background images behind text. Style fields should not communicate meaning or be required to understand the page's content. For example, if you have a text and image module and the image is required to understand the text content, the image should be a content field rather than a style field."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When added to a module, the style options will appear in the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Styles"
      }), " tab of the page editor. When added to a theme, style options will appear in the left sidebar of the theme editor."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/style-field-module-editor0.png",
        alt: "style-field-module-editor0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Keep accessibility in mind when deciding between using an image or background image field. If the content creator should have the ability to add alt text, use an image field rather than a background image field."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Organizing fields"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "How you organize fields plays a significant role in the content creator’s ability to quickly make changes. Below are recommended best practices for organizing fields:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Group style fields based on the component they control rather than the stylistic element."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Leave only the highest-level, most important fields outside of groups."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Favor creating a component group over unnested groups. If you ever need to add functionality to your module later, you can't move your modules to a group later without manually updating all of the instances of the module on pages."
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Order field groups in the order in which the components appear based on the primary language of the majority of the content creators that will be maintaining the site. Example: English is read from left to right, top to bottom. If the users maintaining the site typically read right to left in their language, you should provide it in that order. When in doubt, base this off of the primary language of the content for your site."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#field-groups",
        children: "field groups"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Example"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below is an example card module."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/card%20and%20style%20settings.png",
        alt: "A typical card layout containing an icon, text, and a button. Styling options are shown grouped on the right into Icon, Button, and Card categories."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The styles panel groups the module fields into 3 groups based on components in the card that can be styled."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Icon"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Button"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Card"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When viewing the card, the icon is seen first, then the text and button. The Icon group and its styling options appear before the button group and its styling options. Therefor, the color field for the icon would be found in the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Icon"
      }), " group, while the color field for the button's background color would be found within the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Button"
      }), " group."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Required fields"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Required fields are fields that the content creator must set in order to display the module and publish the page."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Only require fields to have a value if it breaks the module to not have a value."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If you need to have a required field, provide a default value if possible."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, you're building an image carousel module which allows you to configure how many slides to display at the same time. A minimum value of 1 is needed, and without a value, you don't know how to display the image carousel. This is a situation where requiring a value, but setting a default value of one or two, might make sense."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Typography"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Because rich text fields provide more typographical control, it's recommended to use rich text fields over a combination of text field and font field when possible."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["There may be cases where you need to be able to provide typographic styles that apply across multiple pieces of content within the same module, such as a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#text",
        children: "text field"
      }), " intended for headers. In that case, you may be able to make the content creator's work more efficient by providing ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#font",
        children: "font"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#color",
        children: "color"
      }), " style fields in the text field."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When including rich text fields, it's recommended to allow the field's typographic controls to override manually added style fields. If a style field does control a rich text field, it may be worthwhile setting help text, to ensure the content creator is aware."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Toggle vs checkbox in boolean fields"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When including a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields#boolean",
        children: "boolean field"
      }), ", you can set it to display as either a toggle or a checkbox."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Set the boolean to a toggle when the field controls a major design or layout option. For example, converting the layout of a card from vertical to a horizontal orientation."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Set the boolean to a checkbox when the change is less drastic. For example, hiding or showing a publish date for a blog post in a featured blog posts module."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related articles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields",
          children: "Module and theme field types"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/overview",
          children: "Module and theme fields overview"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/overview",
          children: "Modules overview"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/overview",
          children: "Themes overview"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Tutorials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/modules/quickstart",
          children: "Getting started with modules"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Getting started with themes"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}