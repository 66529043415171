"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529761;
const slug = exports.slug = 'guides/cms/storage/hubdb';
const title = exports.title = 'HubDB';
const name = exports.name = 'LATAM BR (PT) HubDB';
const metaDescription = exports.metaDescription = 'Crie conteúdo baseado em dados com o HubDB, a ferramenta de banco de dados nativa da HubSpot. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "arquitetura-do-hubdb",
  "label": "Arquitetura do HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "limites-t%C3%A9cnicos-do-hubdb",
  "label": "Limites técnicos do HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-uma-tabela-hubdb",
  "label": "Criar uma tabela HubDB",
  "parent": null
}, {
  "depth": 0,
  "id": "unir-v%C3%A1rias-tabelas-hubdb",
  "label": "Unir várias tabelas HubDB",
  "parent": null
}, {
  "depth": 1,
  "id": "1.-adicionar-uma-coluna-de-id-externo-%C3%A0-tabela-principal",
  "label": "1. Adicionar uma coluna de ID externo à tabela principal",
  "parent": "unir-v%C3%A1rias-tabelas-hubdb"
}, {
  "depth": 1,
  "id": "2.-adicionar-linhas-de-tabela-externa-%C3%A0s-linhas-da-sua-tabela",
  "label": "2. Adicionar linhas de tabela externa às linhas da sua tabela",
  "parent": "unir-v%C3%A1rias-tabelas-hubdb"
}, {
  "depth": 1,
  "id": "3.-renderizar-os-dados-da-tabela-hubdb-associada",
  "label": "3. Renderizar os dados da tabela HubDB associada",
  "parent": "unir-v%C3%A1rias-tabelas-hubdb"
}, {
  "depth": 0,
  "id": "acessar-dados-do-hubdb-usando-o-hubl",
  "label": "Acessar dados do HubDB usando o HubL",
  "parent": null
}, {
  "depth": 1,
  "id": "obter-linhas",
  "label": "Obter linhas",
  "parent": "acessar-dados-do-hubdb-usando-o-hubl"
}, {
  "depth": 2,
  "id": "atributos-da-linha",
  "label": "Atributos da linha",
  "parent": "acessar-dados-do-hubdb-usando-o-hubl"
}, {
  "depth": 1,
  "id": "obter-os-metadados-da-tabela",
  "label": "Obter os metadados da tabela",
  "parent": "acessar-dados-do-hubdb-usando-o-hubl"
}, {
  "depth": 1,
  "id": "atributos-da-tabela",
  "label": "Atributos da tabela",
  "parent": "acessar-dados-do-hubdb-usando-o-hubl"
}, {
  "depth": 1,
  "id": "obter-metadados-da-coluna",
  "label": "Obter metadados da coluna",
  "parent": "acessar-dados-do-hubdb-usando-o-hubl"
}, {
  "depth": 2,
  "id": "atributos-da-coluna",
  "label": "Atributos da coluna",
  "parent": "acessar-dados-do-hubdb-usando-o-hubl"
}, {
  "depth": 2,
  "id": "m%C3%A9todos-de-coluna",
  "label": "Métodos de coluna",
  "parent": "acessar-dados-do-hubdb-usando-o-hubl"
}, {
  "depth": 2,
  "id": "colunas-de-rich-text",
  "label": "Colunas de rich text",
  "parent": "acessar-dados-do-hubdb-usando-o-hubl"
}, {
  "depth": 0,
  "id": "tutoriais-e-recursos-do-hubdb",
  "label": "Tutoriais e recursos do HubDB",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      strong: "strong",
      em: "em",
      img: "img",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      h3: "h3",
      pre: "pre",
      h4: "h4",
      br: "br"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O HubDB é uma ferramenta que permite criar tabelas para armazenar dados em linhas, colunas e células, muito parecido com uma planilha. Você pode personalizar as colunas, linhas e outras configurações de uma tabela HubDB de acordo com as suas necessidades. Por exemplo, você pode usar uma tabela HubDB para:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Armazenar feedback de um mecanismo externo para recuperar em um momento posterior."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Armazenar dados estruturados que podem ser usados para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "criar páginas dinâmicas do CMS"
        }), " (somente para ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "CMS Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), " e ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Armazenar dados para usar em um ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-programmable-emails",
          children: "e-mail programável"
        }), " (somente para ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "**_Marketing Hub"
        }), "**_ ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), ")."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/hubdb-table-example0.png",
        alt: "hubdb-table-example0"
      }), "As tabelas HubDB podem ser acessadas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb",
        children: "no HubSpot"
      }), " e na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb",
        children: "API do HubDB"
      }), ", e você pode recuperar os dados de uma tabela de várias maneiras, dependendo do caso de uso. Para obter dados de uma tabela HubDB, você pode:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Consultar os dados externamente por meio da ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/cms/hubdb",
          children: "API do HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Usar as tags de marcação HubL do HubSpot para extrair os dados para o CMS como conteúdo estruturado."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Usar a API do HubDB com ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
          children: "funções sem servidor"
        }), " para fornecer uma experiência interativa do aplicativo da Web."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Para ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
            children: "usar os dados do HubDB em páginas"
          }), ", você precisa do ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "CMS Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Para usar os dados do HubDB em ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/email/create-programmable-emails",
            children: "e-mails programáveis"
          }), ", você precisa do ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Publicar, editar e exibir tabelas existentes requer ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#website-tools",
            children: "permissões do HubDB"
          }), ". Criar, clonar, excluir e editar as configurações de uma tabela HubDB requer permissões de ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#settings",
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "Configurações da tabela HubDB"
            })
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Arquitetura do HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Uma tabela HubDB consiste em linhas, colunas e células, semelhantes a uma planilha."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tabelas:"
        }), " uma tabela é um arranjo bidimensional de linhas e colunas. Quando uma tabela é criada, ela recebe um ID globalmente exclusivo que você pode usar quando precisar especificar uma tabela no HubL ou por meio da API."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Linhas:"
        }), " as linhas são fatias horizontais de uma tabela. Em um aplicativo de planilha, as linhas são representadas por números, começando com 1. Cada linha da tabela recebe um ID exclusivo na criação. Cada linha de uma tabela vem com algumas colunas por padrão:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Coluna"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um ID numérico globalmente exclusivo e atribuído automaticamente para esta linha."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um registro de data e hora de quando esta linha foi criada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Em páginas dinâmicas, esta string será o último segmento do caminho do URL para a página."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Em páginas dinâmicas, este é o título da página."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " as colunas de área de rich text no HubDB são limitadas a 65.000 caracteres. Para obter mais informações, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/hubdb-rich-text-area-limited-to-65000-characters-and-a-new-meetings-module",
          children: "consulte o anúncio de log de alterações"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Colunas:"
        }), " as colunas são fatias verticais de uma tabela. Cada coluna tem um tipo, que é usado para armazenar tipos de dados. Uma tabela pode incluir quantas colunas você quiser, e cada uma recebe um ID globalmente exclusivo na criação. O ID da coluna começa com um valor de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "1"
        }), ", mas este número não precisa ser sequencial e não pode ser reutilizado. Os tipos de coluna incluem:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Texto"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Rich text"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "URL"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Imagem"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Selecionar"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Seleção múltipla"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Data"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Data e hora"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Número"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Moeda"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Caixa de seleção"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Localização (longitude, latitude)"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "ID externo"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Vídeo"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Células:"
        }), " as células armazenam os valores da interseção entre uma linha e uma coluna. As células podem ser lidas ou atualizadas individualmente ou como parte de uma linha. Definir o valor de uma célula como ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " é equivalente a excluir o seu valor."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites técnicos do HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Observe os seguintes limites técnicos do HubDB:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Limites da conta:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1.000 tabelas do HubDB por conta."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "1 milhão de linhas do HubDB por conta"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Limites da tabela:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "250 colunas por tabela."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "10.000 linhas por tabela do HubDB"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caracteres por nome de tabela."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caracteres por rótulo de tabela."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Limites da coluna:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "65.000 caracteres por coluna de rich text."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "10.000 caracteres em cada coluna de texto."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caracteres por nome de coluna."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caracteres por rótulo."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "300 caracteres por descrição de coluna."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "700 caracteres por opção selecionável dentro de uma coluna."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Limites de página:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["10 chamadas para a função do HubL ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb_table_rows"
            }), " por página do CMS."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["10 ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
              children: "páginas dinâmicas"
            }), " usando a mesma tabela HubDB."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "HubDBs com páginas dinâmicas ativadas devem ter caminhos em minúsculas para que os URLs dessas páginas não façam distinção entre maiúsculas e minúsculas."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar uma tabela HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode criar tabelas HubDB ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb",
        children: "por meio da interface do usuário do HubSpot"
      }), " ou da ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#create-a-new-table",
        children: "API do HubDB"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todas as novas tabelas criadas são definidas com um status de rascunho. Elas não podem ser usadas para gerar dados via HubL ou API até que você publique a tabela. Ao criar uma tabela, você também pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/create-and-populate-tables-in-hubdb#manage-table-settings-cms-hub-professional-and-enterprise-only",
        children: "gerenciar as configurações"
      }), ", como permitir o acesso público à API e se os seus dados serão utilizados para ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
        children: "criar páginas dinâmicas"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Unir várias tabelas HubDB"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As tabelas HubDB podem ser unidas usando o tipo de coluna ID externo, que permite renderizar os dados combinados de várias tabelas. Isso pode ser útil quando alguns dados são compartilhados entre vários repositórios de dados, permitindo uma tabela de dados centralizada e acessível em vários outros repositórios de dados de tabela HubDB."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Abaixo, saiba como unir várias tabelas HubDB."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1. Adicionar uma coluna de ID externo à tabela principal"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Na sua conta da HubSpot, vá para ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Arquivos e modelos"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "HubDB"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Localize a tabela à qual deseja adicionar uma associação, clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Ações"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Editar"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["No canto superior direito, clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Editar"
        }), " e, em seguida, selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Adicionar coluna"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Insira um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "rótulo"
        }), " e um ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), " para a nova coluna."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Tipo de coluna"
        }), " e selecione ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ID externo"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Selecionar tabela"
        }), " e selecione a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "tabela"
        }), " que pretende associar à tabela atual."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique no menu suspenso ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Selecionar coluna"
        }), " e, em seguida, selecione a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "coluna"
        }), " da tabela selecionada anteriormente para que fique visível no campo ID externo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clique em ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Adicionar coluna"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " o valor escolhido em ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Selecionar coluna"
        }), " determina apenas o valor da coluna que você vê no campo ID externo na interface de usuário do HubDB. Todas as colunas da tabela estão disponíveis ao renderizar as tabelas HubDB associadas."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb_foreign_id.png",
        alt: "hubdb_foreign_id"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "2. Adicionar linhas de tabela externa às linhas da sua tabela"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Agora que você tem uma coluna ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "ID externo"
      }), ", verá um campo de coluna de seleção múltipla em cada linha da sua tabela HubDB, o que lhe permitirá selecionar as linhas de uma tabela externo."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O campo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Selecionar coluna"
      }), " será utilizado neste campo de seleção múltipla para identificar qual linha você está selecionando na tabela externa. No exemplo abaixo, os valores de seleção múltipla para o campo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Associação avançada de tabela"
      }), " são os valores disponíveis na coluna ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Nome"
      }), " da tabela HubDB externa."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/hubdb_foreign_id_ui.png",
        alt: "hubdb_foreign_id_ui"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " é seguro editar o campo ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Selecionar coluna"
        }), " da sua coluna de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "ID externo"
        }), "; isso simplesmente atualizará os valores da coluna que serão exibidos na interface do usuário do HubDB."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "3. Renderizar os dados da tabela HubDB associada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Todos os dados de linha de uma tabela externa podem ser acessados via HubL para renderização, não apenas o campo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Selecionar coluna"
      }), ". Os dados da linha externa do HubDB podem ser acessados usando um ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "loop for aninhado"
      }), ", percorrendo todas as linhas externas associadas a uma linha individual."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for row in hubdb_table_rows(tableId, filterQuery) %}\n  the name for row {{ row.hs_id }} is {{ row.name }}\n  {% for foreign_row in row.foreign_table %}\n  \tthe name for foreign row {{ foreign_row.hs_id }} is {{ foreign_row.name }}\n  {% endfor %}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Acessar dados do HubDB usando o HubL"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Com o HubL, você pode extrair dados do HubDB para usar como conteúdo estruturado em páginas do site. Abaixo, saiba mais sobre como recuperar dados de tabela, linha e coluna usando o HubL."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " os dados da tabela HubDB definidos serão exibidos no editor de páginas e nas visualizações em tempo real, mas apenas o conteúdo HubDB publicado aparecerá na página ativa. Se os dados da tabela forem exibidos no editor ou na visualização e não na página ativa, confirme se a tabela foi publicada depois de adicionar esses dados."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obter linhas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para listar linhas de uma tabela, use a função do HubL ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-rows",
        children: "hubdb_table_rows()"
      }), ". Você pode acessar uma tabela pelo seu ID ou nome. É recomendável fazer referência a uma tabela HubDB pelo nome, pois isso pode ajudar na portabilidade de códigos em contas da HubSpot. O nome da tabela imutável é definido ao criar uma nova tabela e pode ser encontrado a qualquer momento, selecionando ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Ações > Gerenciar configurações"
      }), " no editor da tabela. O ID de uma tabela pode ser encontrado na barra de endereço do editor da tabela ou no painel de tabelas do HubDB, na coluna ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "ID"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://newinboundblog.hubspot.com/hubfs/Table%20Create.png",
        alt: "Captura de tela do modal da tabela de criação"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veja um exemplo do uso de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows()"
      }), " para buscar dados."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% for row in hubdb_table_rows(<tableId or name>, <filterQuery>) %}\n  the value for row {{ row.hs_id }} is {{ row.<column name> }}\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        }), " por padrão, o número máximo de linhas retornadas é 1.000. Para recuperar mais linhas, especifique um ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "limit"
        }), " na consulta de função. Por exemplo:"]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "hudb_table_rows (12345, \"random()&limit=1500\")"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), " usa a mesma sintaxe que a API de HTTP. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_rows(123, \"employees__gt=10&orderBy=count\")"
      }), " retornaria uma lista de linhas, onde a coluna \"funcionários\" é superior a 10, ordenada pela coluna \"contagem\". Uma lista completa de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), " parâmetros ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#get-table-rows",
        children: "opcionais pode ser encontrada aqui"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Em vez de usar várias consultas de linha com diferentes parâmetros ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<filterQuery>"
      }), ", você deve fazer uma consulta e usar os filtros ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "selectattr()"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rejectattr()"
      }), " para filtrar suas linhas:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set all_cars = hubdb_table_rows(<tableId or name>) %}\n\n{% set cars_with_windows = all_cars|selectattr('windows') %}\n\n{% set teslas = all_cars|selectattr('make','equalto','tesla') %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter uma única linha, use a função do HubL ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_row()"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set row = hubdb_table_row(<tableId or name>, <rowId>) %}\nthe value for {{ row.hs_id }} is {{ row.<column name> }}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nomes de colunas integradas e personalizadas não diferenciam letras maiúsculas de minúsculas. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "HS_ID"
      }), " funcionará da mesma forma que ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_id"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Atributos da linha"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Atributo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID exclusivo global desta linha."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ao usar páginas dinâmicas, esta string será o valor da coluna Caminho da página e o último segmento do caminho do URL."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ao usar páginas dinâmicas, esta string será o valor da coluna Título da página para a linha."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Registro de data e hora do Unix de quando a linha foi criada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.hs_child_table_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ao usar páginas dinâmicas, este será o ID da outra tabela que está preenchendo os dados da linha."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row.column_name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obtenha o valor da coluna personalizada pelo nome da coluna."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "row[\"column name\"]"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obtenha o valor da coluna personalizada pelo nome da coluna."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obter os metadados da tabela"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para obter os metadados de uma tabela, incluindo seu nome, colunas, última atualização etc., use a função ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table()"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_info = hubdb_table(<tableId or name>) %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Atributos da tabela"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Os atributos listados abaixo são referentes à variável à qual ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table()"
        }), " foi atribuída no código acima. Sua variável pode ser diferente.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsxs)(_components.em, {
          children: ["Observação: é recomendável atribuir isso a uma variável para facilitar o uso. Se você não quiser fazer isso, pode usar", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ hubdb_table(<tableId>).attribute }}"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Atributo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da tabela."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome da tabela."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.columns"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Lista de informações da coluna. Você pode usar um ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/loops",
              children: "loop \"for\""
            }), " para iterar as informações disponíveis neste atributo."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.created_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Registro de data e hora de quando a tabela foi criada pela primeira vez."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.published_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O carimbo de data/hora em que esta tabela foi publicada."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.updated_at"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O carimbo de data/hora em que esta tabela foi atualizada pela última vez."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.row_count"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número de linhas na tabela."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Obter metadados da coluna"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set table_info = hubdb_table_column(<tableId or name>, <columnId or column name>) %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ter as informações de uma coluna em uma tabela, como rótulo, tipo e opções, use a função ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubdb_table_column()"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Atributos da coluna"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Os atributos listados abaixo são referentes à variável à qual ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_column()"
        }), " foi atribuída no código acima. Sua variável pode ser diferente.", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsxs)(_components.em, {
          children: ["Observação: é recomendável atribuir isso a uma variável para facilitar o uso. Se você não quiser fazer isso, pode usar", (0, _jsxRuntime.jsx)(_components.br, {}), "\n", (0, _jsxRuntime.jsx)(_components.code, {
            children: "{{ hubdb_table_column(<tableId>,<columnId or column name>).attribute }}"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Atributo"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O ID da coluna."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O nome da coluna."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O rótulo a ser utilizado para a coluna."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.type"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tipo desta coluna."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.options"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para selecionar o tipo de coluna, este é um mapa de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "optionId"
            }), " para as informações de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "option"
            }), " ."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "table_info.foreignIds"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Para tipos de coluna foreignId, uma lista de foreignIds (com propriedades de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), ")."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Métodos de coluna"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Método"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_components.tbody, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "getOptionByName(\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Para selecionar tipos de coluna, obtenha informações de opção pelo nome das opções."
          })]
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Colunas de rich text"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O tipo de coluna ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "richtext"
      }), " funciona de modo semelhante ao campo de rich text que você encontra para os módulos."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os dados são armazenados como HTML e a interface de usuário do HubDB fornece uma interface de edição de texto. No entanto, ao editar uma tabela HubDB por meio da interface de usuário do HubSpot, você não pode editar o código-fonte diretamente. Isso evita situações em que um usuário não técnico pode inserir um HTML inválido, evitando problemas não intencionais com a aparência ou a funcionalidade do seu site. Para situações em que você precisa de um código incorporado ou mais HTML personalizado, é possível usar o recurso de incorporação no editor de rich text para colocar seu código personalizado."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tutoriais e recursos do HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/overview",
          children: "Aplicativo de registro em evento"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video"
        })]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "Como criar uma página dinâmica de membros da equipe com o HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/video",
          children: "Como adicionar vídeos a páginas dinâmicas"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "Como criar páginas dinâmicas multiníveis usando o HubDB"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}