/**
 * Utils pulled from here: https://git.hubteam.com/HubSpot/HubSpot-Developers-Website-2024/blob/70c12e0b75ad1d51ae1a41ae0e422fde9d3eefed/src/modules/morse-header.module/module.js
 */

export const morseCode = {
  A: '.-',
  B: '-...',
  C: '-.-.',
  D: '-..',
  E: '.',
  F: '..-.',
  G: '--.',
  H: '....',
  I: '..',
  J: '.---',
  K: '-.-',
  L: '.-..',
  M: '--',
  N: '-.',
  O: '---',
  P: '.--.',
  Q: '--.-',
  R: '.-.',
  S: '...',
  T: '-',
  U: '..-',
  V: '...-',
  W: '.--',
  X: '-..-',
  Y: '-.--',
  Z: '--..',
  '0': '-----',
  '1': '.----',
  '2': '..---',
  '3': '...--',
  '4': '....-',
  '5': '.....',
  '6': '-....',
  '7': '--...',
  '8': '---..',
  '9': '----.',
  ' ': ' / '
};
export function textToMorse(text) {
  text = text.toUpperCase();
  const morse = text.split('').map(char => morseCode[char]).join(' ');
  return morse;
}