"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 164772233121;
const slug = exports.slug = 'reference/ui-components/crm-action-components/overview';
const title = exports.title = 'CRM action components';
const name = exports.name = 'CRM action components';
const metaDescription = exports.metaDescription = 'Learn about CRM action components for use in UI extensions.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "available-components",
  "label": "Available components",
  "parent": null
}, {
  "depth": 0,
  "id": "available-actions",
  "label": "Available actions",
  "parent": null
}, {
  "depth": 1,
  "id": "preview-a-crm-record",
  "label": "Preview a CRM record",
  "parent": "available-actions"
}, {
  "depth": 1,
  "id": "create-a-note",
  "label": "Create a note",
  "parent": "available-actions"
}, {
  "depth": 1,
  "id": "create-a-task",
  "label": "Create a task",
  "parent": "available-actions"
}, {
  "depth": 1,
  "id": "send-a-one-to-one-email",
  "label": "Send a one-to-one-email",
  "parent": "available-actions"
}, {
  "depth": 1,
  "id": "schedule-a-meeting",
  "label": "Schedule a meeting",
  "parent": "available-actions"
}, {
  "depth": 1,
  "id": "create-an-associated-record",
  "label": "Create an associated record",
  "parent": "available-actions"
}, {
  "depth": 1,
  "id": "navigate-to-an-engagement",
  "label": "Navigate to an engagement",
  "parent": "available-actions"
}, {
  "depth": 1,
  "id": "navigate-to-a-crm-record",
  "label": "Navigate to a CRM record",
  "parent": "available-actions"
}, {
  "depth": 1,
  "id": "navigate-to-a-hubspot-page",
  "label": "Navigate to a HubSpot page",
  "parent": "available-actions"
}, {
  "depth": 1,
  "id": "navigate-to-an-external-page",
  "label": "Navigate to an external page",
  "parent": "available-actions"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      code: "code",
      pre: "pre",
      h2: "h2",
      img: "img",
      ol: "ol",
      li: "li",
      a: "a",
      strong: "strong",
      ul: "ul",
      h3: "h3",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CRM action components (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CRM action components provide a built-in set of CRM-related actions, including adding notes to records, opening a one-to-one email composition window, creating new records, and more. Each component can perform the same set of actions, so which component to choose will depend on your needs and preferences."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn more about CRM action components and actions available to each."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRM action components are imported from ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "@hubspot/ui-extensions/crm"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "import {\n  CrmActionButton,\n  CrmActionLink,\n  CrmCardActions,\n} from '@hubspot/ui-extensions/crm';\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Available components"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/crm-card-actions.png",
        alt: "crm-card-actions"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-action-button",
          children: "CrmActionButton"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " renders a button."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-action-link",
          children: "CrmActionLink"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " renders a clickable link."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/ui-components/crm-action-components/crm-card-actions",
          children: "CrmCardActions"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " renders dropdown menu buttons in the top right of the extension."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Users can only take actions through these components when they have the proper permissions. For example, if a user doesn't have permission to create deal records, they won't be able to use a CRM action component to create a deal record. Instead, an error message will be generated and returned through an optional ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onError"
      }), " callback."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Each action requires an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "actionType"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "actionContext"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "actionType"
        }), ": the type of action. See the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#available-actions",
          children: "available actions section"
        }), " below."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "actionContext"
        }), ": the CRM object and record context required for the action to be performed. For example, to include an action to open a preview sidebar for a specified record, you'll need to provide the record's ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectTypeId"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "objectId"
        }), " in ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "actionContext"
        }), ". See the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#available-actions",
          children: "available actions section"
        }), " for more information about what's required for each action."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Available actions"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following actions are available for CRM action components:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#preview-a-crm-record",
          children: "Preview a CRM record"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-note",
          children: "Create a note"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-a-task",
          children: "Create a task"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#send-a-one-to-one-email",
          children: "Send a one-to-one email"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#schedule-a-meeting",
          children: "Schedule a meeting"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#create-an-associated-record",
          children: "Create an associated CRM record"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#navigate-to-an-engagement",
          children: "Navigate to an engagement"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#navigate-to-a-crm-record",
          children: "Navigate to a CRM record"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#navigate-to-a-hubspot-page",
          children: "Navigate to a HubSpot page"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#navigate-to-an-external-page",
          children: "Navigate to an external page"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Preview a CRM record"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PREVIEW_OBJECT"
            }), " action opens a preview sidebar for the specified CRM record."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Requires the following ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "actionContext"
            }), ":"]
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectTypeId"
              }), ": the CRM record's object type (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "0-1"
              }), " for contacts). See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/api/crm/understanding-the-crm#object-type-id",
                children: "full list of object IDs"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectId"
              }), ": the ID of the CRM record to preview."]
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "<CrmActionButton\n  actionType=\"PREVIEW_OBJECT\"\n  actionContext={{\n    objectTypeId: '0-3',\n    objectId: 123456,\n  }}\n  variant=\"secondary\"\n>\n  Preview deal\n</CrmActionButton>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a note"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ADD_NOTE"
            }), " action opens a note composition window, enabling users to add a note to the specified CRM record."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Requires the following ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "actionContext"
            }), ":"]
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectTypeId"
              }), ": the CRM record's object type (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "0-1"
              }), " for contacts). See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/api/crm/understanding-the-crm#object-type-id",
                children: "full list of object IDs"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectId"
              }), ": the ID of the CRM record."]
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "<CrmActionButton\n  actionType=\"ADD_NOTE\"\n  actionContext={{\n    objectTypeId: '0-3',\n    objectId: 123456,\n  }}\n  variant=\"secondary\"\n>\n  Create note\n</CrmActionButton>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create a task"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ADD_TASK"
            }), " action opens a task composition window, enabling users to add a task to the specified CRM record."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Requires the following ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "actionContext"
            }), ":"]
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectTypeId"
              }), ": the CRM record's object type (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "0-1"
              }), " for contacts). See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/api/crm/understanding-the-crm#object-type-id",
                children: "full list of object IDs"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectId"
              }), ": the ID of the CRM record."]
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "<CrmActionButton\n  actionType=\"ADD_TASK\"\n  actionContext={{\n    objectTypeId: '0-1',\n    objectId: 123456,\n  }}\n  variant=\"secondary\"\n>\n  Create task\n</CrmActionButton>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Send a one-to-one-email"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SEND_EMAIL"
            }), " action opens a one-to-one email composition window, enabling users to send an email to the specified contact or the contacts associated with the specified record."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Requires the following ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "actionContext"
            }), ":"]
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectTypeId"
              }), ": the CRM record's object type (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "0-1"
              }), " for contacts). See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/api/crm/understanding-the-crm#object-type-id",
                children: "full list of object IDs"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectId"
              }), ": the ID of the CRM record to send the email to."]
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "<CrmActionButton\n  actionType=\"SEND_EMAIL\"\n  actionContext={{\n    objectTypeId: '0-3',\n    objectId: 123456,\n  }}\n  variant=\"secondary\"\n>\n  Send email\n</CrmActionButton>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Schedule a meeting"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "SCHEDULE_MEETING"
            }), " action opens a window for ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/meetings-tool/create-and-edit-scheduling-pages",
              children: "scheduling a meeting"
            }), "."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Requires the following ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "actionContext"
            }), ":"]
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectTypeId"
              }), ": the CRM record's object type (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "0-1"
              }), " for contacts). See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/api/crm/understanding-the-crm#object-type-id",
                children: "full list of object IDs"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectId"
              }), ": the ID of the CRM record to schedule the meeting with."]
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "<CrmActionButton\n  actionType=\"SCHEDULE_MEETING\"\n  actionContext={{\n    objectTypeId: '0-1',\n    objectId: 123456,\n  }}\n>\n  Schedule meeting\n</CrmActionButton>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Create an associated record"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "OPEN_RECORD_ASSOCIATION_FORM"
            }), " action opens a side panel for creating a new record to be associated with another."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Requires the following ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "actionContext"
            }), ":"]
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectTypeId"
              }), ": the type of CRM record to create (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "0-2"
              }), " for companies). See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/api/crm/understanding-the-crm#object-type-id",
                children: "full list of object IDs"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "association"
              }), ": an object containing information about the record that the new one will be associated with. This is typically the currently displaying record. Contains:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
                children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                  children: [(0, _jsxRuntime.jsx)(_components.code, {
                    children: "objectTypeId"
                  }), ": the type of CRM record to associate the new one with."]
                }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                  children: [(0, _jsxRuntime.jsx)(_components.code, {
                    children: "objectId"
                  }), ": the ID of the CRM record to associate the new one with."]
                }), "\n"]
              }), "\n"]
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "<CrmActionButton\n  actionType=\"OPEN_RECORD_ASSOCIATION_FORM\"\n  actionContext={{\n    objectTypeId: '0-2',\n    association: {\n      objectTypeId: '0-1',\n      objectId: 123456,\n    },\n  }}\n>\n  Create new record\n</CrmActionButton>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Navigate to an engagement"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "ENGAGEMENT_APP_LINK"
            }), " action navigates the user to a specific engagement on a CRM record timeline, such as a call or task."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Requires the following ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "actionContext"
            }), ":"]
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectTypeId"
              }), ": the type of CRM record to navigate to (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "0-2"
              }), " for companies). See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/api/crm/understanding-the-crm#object-type-id",
                children: "full list of object IDs"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectId"
              }), ": the ID of the CRM record to navigate to."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "engagementId"
              }), ": the ID of the engagement, such as a task or note."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "external"
              }), ": optionally, set to ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "true"
              }), " to navigate to the engagement in a new browser tab."]
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "<CrmActionButton\n  actionType=\"ENGAGEMENT_APP_LINK\"\n  actionContext={{\n    objectTypeId: '0-2',\n    objectId: 2763710643,\n    engagementId: 39361694368,\n  }}\n  variant=\"secondary\"\n>\n  Open note\n</CrmActionButton>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Navigate to a CRM record"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "RECORD_APP_LINK"
            }), " action navigates the user to a specific CRM record."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Requires the following ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "actionContext"
            }), ":"]
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectTypeId"
              }), ": the type of CRM record to navigate to (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "0-2"
              }), " for companies). See ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/guides/api/crm/understanding-the-crm#object-type-id",
                children: "full list of object IDs"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "objectId"
              }), ": the ID of the CRM record to navigate to."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "external"
              }), ": optionally, set to ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "true"
              }), " to navigate to the record in a new browser tab."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "includeEschref"
              }), ": optionally, set to ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "true"
              }), " to include a ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Back"
              }), " button in the top left corner of the opened CRM record to navigate the user back to the original record."]
            }), "\n"]
          }), (0, _jsxRuntime.jsx)(_components.p, {
            children: (0, _jsxRuntime.jsx)(_components.img, {
              src: "https://developers.hubspot.com/hs-fs/hubfs/Knowledge_Base_2023/ui-extensions-crm-action-back-button.png",
              alt: "ui-extensions-crm-action-back-button"
            })
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "<CrmActionButton\n  actionType=\"RECORD_APP_LINK\"\n  actionContext={{\n    objectTypeId: '0-2',\n    objectId: 2763710643,\n    includeEschref: true,\n  }}\n  variant=\"secondary\"\n>\n  View company\n</CrmActionButton>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Navigate to a HubSpot page"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "PAGE_APP_LINK"
            }), " navigates the user to any page within the HubSpot account. Use this action when a user would need to navigate to a non-CRM record account page, such as the email tool."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Requires the following ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "actionContext"
            }), ":"]
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "path"
              }), ": the URL path of the HubSpot page. This path is relative to ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "https://app.hubspot.com"
              }), " and should begin with ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "/"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "external"
              }), ": optionally, set to ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "true"
              }), " to navigate to the page in a new browser tab."]
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "<CrmActionButton\n  actionType=\"PAGE_APP_LINK\"\n  actionContext={{\n    path: '/email/123456/analyze?emailType=followup-2',\n    external: true,\n  }}\n  variant=\"secondary\"\n>\n  Open email dashboard\n</CrmActionButton>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Navigate to an external page"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsxs)(_components.p, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "EXTERNAL_URL"
            }), " action navigates the user to a website page in a new tab."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Requires the following ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "actionContext"
            }), ":"]
          }), (0, _jsxRuntime.jsxs)(_components.ul, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: [(0, _jsxRuntime.jsx)(_components.code, {
                children: "href"
              }), ": the URL, which must begin with ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "http"
              }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "https"
              }), ". When protocol is not specified, HubSpot will automatically prefix the URL with ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "https"
              }), "."]
            }), "\n"]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-js",
            children: "<CrmActionButton\n  actionType=\"EXTERNAL_URL\"\n  actionContext={{\n    href: 'https://www.google.com',\n  }}\n  variant=\"secondary\"\n>\n  Open Google\n</CrmActionButton>\n"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}