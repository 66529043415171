'use es6';

import parse from './parse.js';
import { argumentType, formatTypes } from '../../common/constants.js';
import { formatArgumentKey, formatArgumentValue } from './formatters.js';
import { patchSdkPath } from './helpers.js';
import { toCamelCase, toSnakeCase } from '../../common/stringutils';
import { jsonEquals } from '../../common/objutils';
const getAssignment = assignmentData => {
  const assignmentLines = [];

  // initiate object variable
  assignmentData.args.filter(arg => arg.type === argumentType.OBJECT).forEach(arg => {
    const objectAssignment = `${formatArgumentKey(arg.key)} = ${JSON.stringify(arg.value, null, 2)}`;
    assignmentLines.push(objectAssignment);
  });
  let line = 'body = { ';
  line += assignmentData.args.map(arg => {
    const key = toCamelCase(arg.key);
    const value = formatArgumentValue(arg);
    return `${key}: ${value}`;
  }).join(', ');
  line += ' }';
  assignmentLines.push(line);
  return assignmentLines;
};
const getSdkCallLines = sdkCall => {
  const sdkMethodPath = [...sdkCall.path, sdkCall.apiName, sdkCall.methodName];
  const lines = [];
  sdkCall.args.filter(arg => jsonEquals(arg.format, formatTypes.BINARY)).forEach(arg => {
    const filePath = `file_path = '${arg.key}'`;
    const binaryAssignment = `${formatArgumentKey(arg.key)} = File.open(file_path, 'rb')`;
    lines.push(filePath, binaryAssignment);
  });
  let sdkCallLine = '';
  sdkCallLine += `api_response = api_client.${sdkMethodPath.map(p => toSnakeCase(p)).join('.')}`;
  sdkCallLine += `(${sdkCall.args.map(arg => {
    const key = formatArgumentKey(arg.key);
    if (arg.format === 'binary') {
      return `${key}: ${arg.value}`;
    } else {
      const value = formatArgumentValue(arg);
      if (key === value) {
        return `body: body`;
      }
      return `${key}: ${value}`;
    }
  }).join(', ')})`;
  lines.push(sdkCallLine);
  return lines;
};
const getConfig = ({
  authTypes,
  apiKey,
  oAuthToken
}) => {
  if (oAuthToken) {
    return `access_token: '${oAuthToken}'`;
  } else if (apiKey) {
    if (authTypes.includes('developer_hapikey')) {
      return `developer_api_key: '${apiKey}'`;
    }
    return `api_key: '${apiKey}'`;
  } else {
    if (authTypes.includes('oauth2') || authTypes.includes('oauth2_legacy') || authTypes.includes('private_apps') || authTypes.includes('private_apps_legacy')) {
      return "access_token: 'YOUR_ACCESS_TOKEN'";
    } else if (authTypes.includes('developer_hapikey')) {
      return "developer_api_key: 'YOUR_DEVELOPER_API_KEY'";
    } else {
      return "api_key: 'YOUR_API_KEY'";
    }
  }
};
export default function generate(openApiSpecification, operationId, formData, additionalParams) {
  const {
    sdkCall,
    assignments
  } = parse(openApiSpecification, operationId, formData);
  const clientPath = Array.prototype.concat('api_client', patchSdkPath(sdkCall.path)).join('.');
  const configParam = getConfig(Object.assign({}, sdkCall, additionalParams));
  let lines = [];
  lines.push("require 'hubspot-api-client'");
  lines.push('');
  lines.push(`api_client = Hubspot::Client.new(${configParam})`);
  lines.push('');
  lines.push();
  for (const assignment of assignments) {
    lines.push(...getAssignment(assignment));
  }
  const sdkCallLines = getSdkCallLines(sdkCall, clientPath, additionalParams);
  lines.push(...sdkCallLines);
  const sdkCallLine = lines.pop();
  lines = lines.concat([`${sdkCallLine}`, 'puts api_response']);
  return lines.join('\n');
}