'use es6';

import generatePython from './langs/python/index.js';
import generateRuby from './langs/ruby/index.js';
import generateJavascript from './langs/javascript/index.js';
import generatePhp from './langs/php/index.js';
import keyMirror from 'react-utils/keyMirror';
import { get } from './common/objutils.js';
export const LANGUAGE_TYPE = keyMirror({
  PHP: null,
  PYTHON: null,
  RUBY: null,
  JAVASCRIPT: null
});
const langToSnippet = {
  PYTHON: generatePython,
  RUBY: generateRuby,
  JAVASCRIPT: generateJavascript,
  PHP: generatePhp
};
export default function (language, oas, operationId, har, additionalParams) {
  return langToSnippet[language](oas, operationId, get(har, 'log.entries[0].request.formData'), additionalParams);
}