"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 41185684483;
const slug = exports.slug = 'guides/cms/content/data-driven-content/crm-objects';
const title = exports.title = 'CMS HubのCRMオブジェクト';
const name = exports.name = 'APAC JAPAN (JA) | [Developers] CRM Custom Objects in CMS Hub | 202103';
const metaDescription = exports.metaDescription = 'CRMオブジェクトは問い合わせによって取得し、HubSpot上にホスティングされたコンテンツにレンダリングできます。これにより、業務、ウェブサイト、Eメールでのデータ共有が可能になります。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%95%E3%82%8C%E3%82%8Bcrm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97",
  "label": "サポートされるCRMオブジェクトタイプ",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%81%99%E3%81%B9%E3%81%A6%E3%81%AE%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%A7%E4%BD%BF%E7%94%A8%E3%81%A7%E3%81%8D%E3%82%8Bcrm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%83%87%E3%83%BC%E3%82%BF",
  "label": "すべてのページで使用できるCRMオブジェクトデータ",
  "parent": "%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%95%E3%82%8C%E3%82%8Bcrm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 1,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%83%9A%E3%83%BC%E3%82%B8%E3%81%A7%E4%BD%BF%E7%94%A8%E3%81%A7%E3%81%8D%E3%82%8Bcrm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%83%87%E3%83%BC%E3%82%BF",
  "label": "非公開ページで使用できるCRMオブジェクトデータ",
  "parent": "%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%95%E3%82%8C%E3%82%8Bcrm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%82%BF%E3%82%A4%E3%83%97"
}, {
  "depth": 0,
  "id": "crm_object%E9%96%A2%E6%95%B0%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E5%8D%98%E4%B8%80%E3%81%AEcrm%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%8B%E3%82%89%E3%81%AE%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B",
  "label": "crm_object関数を使用して単一のCRMレコードからのデータを表示する",
  "parent": null
}, {
  "depth": 0,
  "id": "crm_objects%E9%96%A2%E6%95%B0%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E8%A4%87%E6%95%B0%E3%81%AEcrm%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%8B%E3%82%89%E3%81%AE%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%99%E3%82%8B",
  "label": "crm_objects関数を使用して複数のCRMレコードからのデータを表示する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%89%E3%82%8C%E3%81%9F%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%AE%E8%A1%A8%E7%A4%BA",
  "label": "関連付けられたレコードの表示",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%AB%E3%82%B9%E3%82%BF%E3%83%A0-%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88-%E3%82%BF%E3%82%A4%E3%83%97%E3%81%AE%E8%A9%B3%E7%B4%B0%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "カスタム オブジェクト タイプの詳細を取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "crm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%83%A2%E3%82%B8%E3%83%A5%E3%83%BC%E3%83%AB%E3%81%AE%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E3%83%89",
  "label": "CRMオブジェクトモジュールのフィールド",
  "parent": null
}, {
  "depth": 0,
  "id": "crm%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AE%E3%83%81%E3%83%A5%E3%83%BC%E3%83%88%E3%83%AA%E3%82%A2%E3%83%AB%E3%81%A8%E8%B3%87%E6%96%99",
  "label": "CRMオブジェクトのチュートリアルと資料",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      h3: "h3",
      h2: "h2",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre",
      img: "img",
      ul: "ul",
      li: "li"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "CMS HubでCRMオブジェクトデータを使用する"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["CRMオブジェクトをクエリーすると、HubSpotでホスティングされるコンテンツ上のオブジェクトのレコードからのデータを使用して業務、ウェブサイト、Eメールの間でデータを共有できます。HubL関数", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects"
        })
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.jp/docs/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        })
      }), "を使用すると、CRMオブジェクトデータに基づいてロジックを表示および制御できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ウェブサイト上でCRMデータを使用する場合、セールス、マーケティング、ウェブサイトのデータがすべて同じ場所に存在して常に同じ情報を反映することになります。さらに、CRMレコードを相互に関連付けることができるので、関連するデータをウェブサイトページに取り込むこともできます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["同様に、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/dynamic-pages/overview",
        children: "CRMオブジェクトまたはHubDBデータを使用して自動生成される動的ページのセットを作成"
      }), "できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["データに基づくCMSページの構築について、詳しくはHubSpotアカデミーの", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/academy/tracks/1148948/intro",
          children: "CMSデータドリブン コンテンツ コース"
        }), "を参照してください。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "使用例："
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "ページでCRMオブジェクトデータを使用する例の1つとして、不動産リストページがあります。「物件」と呼ばれるカスタムオブジェクトを使用すると、リストする必要のある家屋ごとに個々のオブジェクトレコードを作成できます。次に、不動産業者は物件オブジェクトに情報を追加して、場所、寝室の数、希望価格などの詳細を保存することができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "その後、ウェブサイトページで各物件のレコードデータを取り込んで、リスティングページと各物件の詳細ページを作成できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この例全体を確認するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-custom-objects-example",
        children: "GitHubリポジトリー"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この例の概要については、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/developer-day-2020",
        children: "HubSpot Developer Day 2020の録画"
      }), "をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "サポートされるCRMオブジェクトタイプ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CMS Hubページのデータの取得元として可能なCRMオブジェクトの種類を以下に示します。すべてのページでデータを使用できるか、それとも非公開ページでのみデータを使用できるかは、オブジェクトタイプによって異なります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "以下の表は、CMSコンテンツで使用可能なオブジェクトタイプと、そのオブジェクトタイプ名および完全修飾名を示しています。"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "注："
        }), "「contact」などの標準的なオブジェクト名では大文字と小文字を区別しませんが、単数形でなければなりません。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "すべてのページで使用できるCRMオブジェクトデータ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次のCRMオブジェクトからのデータは、任意のCMSページで使用可能です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "オブジェクトタイプ"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "object_type名"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "完全修飾名"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/products",
              children: "製品"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "product"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PRODUCT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/marketing/marketing-events",
              children: "マーケティングイベント"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "marketing_event"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MARKETING_EVENT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/custom-objects",
              children: "カスタムオブジェクト"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["「", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "CMS Hub"
            }), " Enterprise」のみ。オブジェクトの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.jp/docs/cms/features/custom-objects#getting-a-custom-object-type-s-details",
              children: "完全修飾名"
            }), "、あるいは作成時に入力した名前を使用できます。たとえば「Cars」という名前のオブジェクトを作成した場合、それを「cars」や「Car」で参照することはできません。カスタムオブジェクトの名前が標準オブジェクトと同じである場合は、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.jp/docs/cms/features/custom-objects#getting-a-custom-object-type-s-details",
              children: "完全修飾名"
            }), "を使用する必要があります。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "非公開ページで使用できるCRMオブジェクトデータ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["以下のCRMオブジェクトからのデータは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/website-pages/password-protect-a-page",
        children: "パスワード"
      }), "または", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/jp/website-pages/require-member-registration-to-access-private-content",
        children: "メンバーシップログイン"
      }), "のいずれかを必要とするページでのみ使用可能です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "オブジェクトタイプ"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "object_type名"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "完全修飾名"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/contacts",
              children: "コンタクト"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "contact"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTACT"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/companies",
              children: "会社"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "company"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "COMPANY"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/deals",
              children: "取引"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "deal"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DEAL"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/objects/tickets",
              children: "チケット"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ticket"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TICKET"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/commerce/quotes",
              children: "見積もり"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "quote"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "QUOTE"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "インテグレーターオブジェクト"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["インテグレーターオブジェクトの名前を取得するには、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.jp/docs/cms/features/custom-objects#getting-a-custom-object-type-s-details",
              children: "CRMオブジェクトスキーマAPI"
            }), "を使用します。標準オブジェクトと同じ名前のインテグレーターオブジェクトでは、インテグレーターオブジェクトの", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developers.hubspot.jp/docs/cms/features/custom-objects#getting-a-custom-object-type-s-details",
              children: "完全修飾名"
            }), "を使用します。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "crm_object関数を使用して単一のCRMレコードからのデータを表示する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), "関数を使用し、クエリーまたはCRMレコードIDを使ってHubSpot CRMから単一のレコードを取得します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "オブジェクトレコードは、プロパティーと値からなるディクショナリーとして返されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# Render custom object by query #}\n{% set event = crm_object(\"event\", \"name=Defensive Health\") %}\n{{ event.name }}\n\n{# Render custom objects specifying the id of the object #}\n{% set event = crm_object(\"event\", 289236) %}\n{{ event.name }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<p>Defensive Heatlh</p>\n\n<p>Defensive Heatlh</p>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "クエリー結果としてレコードのコレクションが返された場合、この関数はコレクション内の最初のレコードを返します。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "crm_objects関数を使用して複数のCRMレコードからのデータを表示する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects()"
        })
      }), "関数を使用し、クエリーまたはレコードIDを使ってHubSpot CRMからオブジェクトタイプ別にCRMレコードを取得します。レコードは、プロパティーと値から成るディクショナリーとして返されます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["返されるオブジェクトには", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), "プロパティーが含まれ、これをループ処理することでレコード内の項目の情報を表示できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# Render custom objects by query #}\n{% set events = crm_objects(\"event\", \"limit=3&type=virtual\") %}\n<h3>{{events.total}} New Events:<h3>\n<ul>\n{% for event in events.results %}\n\t<li>Name: {{ event.name }}</li>\n{% endfor %}\n<ul>\n\n{# Render custom objects by ids #}\n{% set events = crm_objects(\"event\", [289236,289237,289238]) %}\n<h3>{{events.total}} New Events:<h3>\n<ul>\n{% for event in events.results %}\n\t<li>Name: {{ event.name }}</li>\n{% endfor %}\n<ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>\n  3 New Events:\n  <h3>\n    <ul>\n      <li>Name: Defensive Health</li>\n      <li>Name: Body Balance</li>\n      <li>Name: Happy Heart</li>\n      <ul>\n        <h3>\n          3 New Events:\n          <h3>\n            <ul>\n              <li>Name: Defensive Health</li>\n              <li>Name: Body Balance</li>\n              <li>Name: Happy Heart</li>\n              <ul></ul>\n            </ul>\n          </h3>\n        </h3>\n      </ul>\n    </ul>\n  </h3>\n</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "関連付けられたレコードの表示"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubL関数", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        })
      }), "を使用すると、特定のレコードID、関連付けカテゴリー、関連付け定義IDに基づき、関連付けられているレコードのリストをHubSpot CRMから取得できます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#getting-a-custom-object-s-details"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "レコードは、プロパティーと値から成るディクショナリーとして返されます。"
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Input",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% set associated_objects = crm_associations(289236, \"USER_DEFINED\", 3) %}\n<h3>Contacts Associated With Event</h3>\n<ul>\n{% for contact in associated_objects.results %}\n\t<li>Name: {{ contact.firstname }} {{ contact.lastname }}</li>\n{% endfor %}\n</ul>\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Output",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<h3>\n  Contacts Associated With Event\n  <h3>\n    <ul>\n      <li>Name: Brian Halligan</li>\n\n      <li>Name: Dharmesh Shah</li>\n\n      <li>Name: Yamini Rangan</li>\n    </ul>\n  </h3>\n</h3>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "カスタム オブジェクト タイプの詳細を取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["カスタム オブジェクト タイプの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fullyQualifiedName"
      }), "、関連付けID、その他の詳細を取得するには、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/crm/objects/custom-objects",
        children: "CRMオブジェクトスキーマAPI"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**アカウント固有のオブジェクトタイプの", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fullyQualifiedName"
        }), "にはHubSpotアカウントIDが含まれているので、複数のHubSpotアカウント用のコードを開発するときにはこれを使用しないことをお勧めします。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRMオブジェクトモジュールのフィールド"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/en/docs/cms/building-blocks/module-theme-fields#crm-object",
        children: "CRMオブジェクトフィールド"
      }), "を含むモジュールを構築すると、コンテンツ作成者は表示またはロジックを実行する対象のCRMレコードを選択できるようになります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "たとえば、特定の製品、コンタクト、会社、取引、見積もり、チケット、またはカスタムオブジェクトからの情報を表示できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/CRM%20Object%20Field.png",
        alt: "CRMオブジェクトフィールド"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "CRMオブジェクトのチュートリアルと資料"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/essentials-for-getting-started-with-custom-objects",
          children: "カスタムオブジェクトの基本"
        }), "（英語）"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-to-think-like-an-architect-by-building-scalable-custom-objects",
          children: "建築家のように考える：スケーラブルなカスタムオブジェクトを構築する"
        }), "（英語）"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/crm-objects",
          children: "CRMオブジェクトを使用した動的ページの作成方法"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}