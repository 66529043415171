"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 59605144696;
const slug = exports.slug = 'guides/api/crm/engagements/notes';
const title = exports.title = 'エンゲージメント | メモ';
const name = exports.name = 'エンゲージメント | メモ';
const metaDescription = exports.metaDescription = 'コンタクトレコードには、個人に関する情報が保存されます。コンタクトエンドポイントを使用することにより、コンタクトデータを管理したり、HubSpotと他のシステムとの間で同期したりできます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%A1%E3%83%A2%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B",
  "label": "メモを作成する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "プロパティー",
  "parent": "%E3%83%A1%E3%83%A2%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91",
  "label": "関連付け",
  "parent": "%E3%83%A1%E3%83%A2%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%A2%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "メモを取得する",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%A2%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B",
  "label": "メモを更新する",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AE%E3%83%A1%E3%83%A2%E3%82%92%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%AB%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%8B",
  "label": "既存のメモをレコードに関連付ける",
  "parent": "%E3%83%A1%E3%83%A2%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B"
}, {
  "depth": 1,
  "id": "%E9%96%A2%E9%80%A3%E4%BB%98%E3%81%91%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "関連付けを削除する",
  "parent": "%E3%83%A1%E3%83%A2%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%99%E3%82%8B"
}, {
  "depth": 0,
  "id": "%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%89%E3%81%A7%E3%83%A1%E3%83%A2%E3%82%92%E3%83%94%E3%83%B3%E7%95%99%E3%82%81%E3%81%99%E3%82%8B",
  "label": "レコードでメモをピン留めする",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%83%A1%E3%83%A2%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "メモを削除する",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      em: "em",
      h2: "h2",
      code: "code",
      strong: "strong",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "メモ"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "CRMレコードにメモを記録して、レコードのタイムラインに情報を追加したり、添付ファイルをレコードに関連付けたりすることができます。例えば、コンタクトとのオフラインコミュニケーションを追跡する必要がある場合は、そのコミュニケーションに関連する詳細情報やドキュメントをコンタクトレコードにメモとして追加できます。その後、アカウント内の他のユーザーがそのメモを表示して参照できるようになります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メモは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manually-log-a-call-email-or-meeting-on-a-record",
        children: "HubSpot"
      }), "内で、またはノートAPIを通じて管理できます。この記事では、APIを使用したノートの基本的な管理方法を説明します。利用可能な全てのエンドポイントは、この記事の上部にある［", (0, _jsxRuntime.jsx)(_components.em, {
        children: "エンドポイント"
      }), "］タブでご確認いただけます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メモを作成する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メモを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエスト", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["リクエスト本文で、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "properties"
      }), "オブジェクト内にメモの詳細を追加します。また、", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "associations"
      }), "オブジェクトを追加して、新しいメモを既存のレコード（コンタクト、会社など）に関連付けることもできます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "プロパティー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "propertiesオブジェクトには、次のフィールドを含めることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_timestamp"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "必須。このフィールドは、メモの作成時刻を記録し、レコードタイムラインでのそのメモの表示位置を決定します。ミリ秒単位またはUTC形式のUnixタイムスタンプを使用できます。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_note_body"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メモのテキストコンテンツ。65,536文字までに制限されています。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot_owner_id"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["メモに関連付けられている", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/owners",
              children: "所有者のID"
            }), "。このフィールドにより、HubSpotのレコードタイムラインにメモ作成者として表示されるユーザーが決まります。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hs_attachment_ids"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メモの添付ファイルのID。複数の添付ファイルIDはそれぞれセミコロンで区切られます。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付け"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "メモを作成して既存のレコードと関連付けるには、リクエストにassociationsオブジェクトを含めます。例えば、メモを作成して会社と取引に関連付ける場合、リクエスト本文は次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example POST request to https://api.hubspot.com/crm/v3/objects/notes\n{\n  \"properties\": {\n    \"hs_timestamp\": \"2021-11-12T15:48:22Z\",\n    \"hs_note_body\": \"Spoke with decision maker Carla. Attached the proposal and draft of contract.\",\n    \"hubspot_owner_id\": \"14240720\",\n    \"hs_attachment_ids\": \"24332474034;24332474044\"\n  },\n  \"associations\": [\n    {\n      \"to\": {\n        \"id\": 301\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 190\n        }\n      ]\n    },\n    {\n      \"to\": {\n        \"id\": 401\n      },\n      \"types\": [\n        {\n          \"associationCategory\": \"HUBSPOT_DEFINED\",\n          \"associationTypeId\": 214\n        }\n      ]\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "associationsオブジェクトには、次のものを含める必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["メモに関連付けるレコード。一意の", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "値で指定します。"]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["メモとレコードの間の関連付けのタイプ。", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "および", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), "が含まれます。既定の関連付けタイプIDは、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#association-type-id-values",
              children: "このページ"
            }), "で確認できます。または、", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations#retrieve-association-types",
              children: "関連付けAPI"
            }), "を使用して、カスタム関連付けタイプ（ラベル）の値を取得することもできます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メモを一括で作成する方法については、この記事の上部にある［", (0, _jsxRuntime.jsx)(_components.em, {
        children: "エンドポイント"
      }), "］タブでご確認いただけます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メモを取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メモを個別に、または一括で取得することができます。個々のメモを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["全てのメモのリストを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "どちらのエンドポイントに対しても、リクエストのURLに次のクエリーパラメーターを含めることができます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "パラメーター"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "レスポンスで返されるプロパティーのカンマ区切りリスト。リクエスト対象のメモで、指定したプロパティーに値が設定されていない場合、そのプロパティーはレスポンスに含まれません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["関連付けられているIDが取得されるオブジェクトタイプのカンマ区切りリスト。存在しない関連付けを指定した場合、その関連付けはレスポンスで返されません。詳しくは", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations",
              children: "関連付けAPI"
            }), "をご参照ください。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、特定のテキストコンテンツおよび関連付けられているコンタクトIDを含むメモを取得する場合、リクエストURLは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/crm/v3/objects/notes?limit=10&properties=hs_note_body&associations=contact&archived=false"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["内部IDまたは一意のプロパティー値によってノートを一括で取得する方法については、この記事の上部にある［", (0, _jsxRuntime.jsx)(_components.em, {
        children: "エンドポイント"
      }), "］タブでご確認いただけます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メモを更新する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メモを個別に、または一括で更新することができます。メモIDを指定して個別のメモを更新するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエスト本文に、更新するノートプロパティーを含めます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example PATCH request to https://api.hubspot.com/crm/v3/objects/notes/{noteID}\n{\n  \"properties\": {\n    \"hs_note_body\": \"Spoke with decision maker Carla.\",\n    \"hs_attachment_ids\": \"24332474034;24332474044\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpotは、読み取り専用プロパティーおよび存在しないプロパティーの値を無視します。プロパティー値をクリアするには、リクエストの本文でプロパティーの空の文字列を渡します。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メモを一括更で新する方法については、この記事の上部にある［", (0, _jsxRuntime.jsx)(_components.em, {
        children: "エンドポイント"
      }), "］タブでご確認いただけます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既存のメモをレコードに関連付ける"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メモをコンタクトなどの他のCRMレコードに関連付けるには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "に送信します。リクエストのURLは次のフィールドからなります。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "フィールド"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "説明"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "noteId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メモのID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectType"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メモに関連付けるオブジェクトのタイプ（コンタクト、会社など）。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "toObjectId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "メモに関連付けるレコードのID。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["メモと他のオブジェクトとの関連付けタイプを示す一意の識別子。このIDは、数値またはスネークケース（例：", (0, _jsxRuntime.jsx)(_components.code, {
              children: "note_to_contact"
            }), "）で表すことができます。該当する値は", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/api/crm/associations/association-details",
              children: "関連付けAPI"
            }), "を介して取得できます。"]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "例えば、リクエストのURLは次のようになります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubspot.com/crm/v3/objects/notes/17147287858/associations/contact/581751/202"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "関連付けを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メモとレコードの間の関連付けを削除するには、上記と同じ以下のURLに対して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "レコードでメモをピン留めする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レコードで", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/records/pin-an-activity-on-a-record",
        children: "メモをピン留め"
      }), "して、レコードのタイムラインの上部に常に表示されるようにすることができます。ピン留めする前に、メモが既にレコードに関連付けられている必要があり、1つのレコードにつき1つのアクティビティーのみをピン留めできます。メモをピン留めするには、オブジェクトAPIを介してレコードを作成または更新するときに、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_pinned_engagement_id"
      }), "フィールドにメモの", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "を含めます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/companies#pin-an-activity-on-a-company-record",
        children: "会社"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/contacts#pin-an-activity-on-a-contact-record",
        children: "コンタクト"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/deals#pin-an-activity-on-a-deal-record",
        children: "取引"
      }), "、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/tickets#pin-an-activity-on-a-ticket-record",
        children: "チケット"
      }), "、および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/objects/custom-objects",
        children: "カスタムオブジェクト"
      }), "APIの使用の詳細については、こちらをご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "メモを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メモを個別に、または一括で削除することができます。削除したメモは、HubSpot内のごみ箱に追加されます。そのため、必要に応じて後で", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/restore-deleted-activity-in-a-record",
        children: "レコードタイムラインからメモを復元"
      }), "することも可能です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メモIDを指定して個別のメモを削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), "リクエストを", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/notes/{noteId}"
      }), "に送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["メモを一括で削除する方法については、この記事の上部にある［", (0, _jsxRuntime.jsx)(_components.em, {
        children: "エンドポイント"
      }), "］タブでご確認いただけます。"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}