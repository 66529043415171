"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29871358726;
const slug = exports.slug = 'guides/api/analytics-and-events/event-analytics';
const title = exports.title = 'Events API | Event Analytics';
const name = exports.name = 'vNext Docs DP - Event Analytics';
const metaDescription = exports.metaDescription = 'Event Analytics endpoint allows you to find and filter events associated with a CRM object of any type. ';
const position = exports.position = 3;
const toc = exports.toc = [{
  "depth": 0,
  "id": "query-individual-event-completions",
  "label": "Query individual event completions",
  "parent": null
}, {
  "depth": 1,
  "id": "select-by-object-id",
  "label": "Select by object ID",
  "parent": "query-individual-event-completions"
}, {
  "depth": 1,
  "id": "select-by-object-property",
  "label": "Select by object property",
  "parent": "query-individual-event-completions"
}, {
  "depth": 0,
  "id": "querying-and-filtering-for-event-types",
  "label": "Querying and filtering for event types",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      em: "em",
      a: "a",
      h2: "h2",
      code: "code",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Event Analytics"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use the event analytics API to fetch events associated with CRM records of any type (", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Sales Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Service Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), ", or ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "CMS Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " only). This includes standard events, such as website page views and email opens, as well as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
        children: "custom events"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, use this API to view a timeline of the interactions that a contact has had with your content. You can then use this timeline to build a dataset for custom analytics or present a contact timeline in an external application."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Query individual event completions"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This API returns events for one CRM record at a time. You can select the record by specifying the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectType"
      }), " and including either the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty"
      }), " query parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Select by object ID"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To specify a record by its ID, add the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " query parameter. For example, to specify a contact record with the ID of ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "2832"
      }), ", you would make the following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/?objectType=contact&objectId=224834"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Select by object property"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To specify a record by a unique property instead of contact ID, add the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty"
      }), " parameter. Reference the property by including the property name and the value in the following format:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectProperty.{propname}={propvalue}"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, to specify a contact by their email address, you would make the following ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/?objectType=contact&objectProperty.email=user12@dev.com"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Querying and filtering for event types"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When querying for the events associated with a given CRM object, the response will include all event types, including custom behavioral events."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To only return event completions for a specific event type, you can include an ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "eventType"
      }), " parameter, followed by the event name. To get a list of all available event types, you can make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/event-types"
      }), ". The response will return all event types in your account. You can then use one of these event names as a query parameter in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events"
      }), " endpoint."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/events/v3/events/eventType={EVENT_NAME}&objectType=contact&objectId=224834"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}