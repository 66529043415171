"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 91311147012;
const slug = exports.slug = 'guides/api/crm/engagements/engagement-details';
const title = exports.title = 'CRM API | エンゲージメント | HubSpot（ハブスポット）';
const name = exports.name = 'APAC JAPAN (ja) | WEB | vNext Docs DP - Engagements_JA | 202212';
const metaDescription = exports.metaDescription = 'エンゲージメントのエンドポイントは、メモ、タスク、Eメール、ミーティング、コールなどCRMに関連するアクションからのデータを格納する、エンゲージメントレコードを管理するために使用されます。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "v3%E3%82%A8%E3%83%B3%E3%82%B2%E3%83%BC%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "V3エンゲージメント",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      h2: "h2",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "エンゲージメント"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "V3エンゲージメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "最新版のエンゲージメントAPIでは、エンゲージメントがオブジェクトとして管理されます。例えばメモ（Notes）APIを使用すると、メモを作成、更新、関連付け、削除できます。以下で各エンゲージメントをクリックして、それぞれのエンゲージメントのエンドポイントや詳細な情報をご覧ください。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/notes",
          children: "メモ"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/calls",
          children: "コール"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/tasks",
          children: "タスク"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/meetings",
          children: "ミーティング"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/email",
          children: "Eメール"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/engagements/postal-mail",
          children: "郵便"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}