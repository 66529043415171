"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937482;
const slug = exports.slug = 'reference/api/analytics-and-events/tracking-code';
const title = exports.title = 'Tracking code API overview';
const name = exports.name = 'vNext Docs DP - Tracking code';
const metaDescription = exports.metaDescription = 'Web Analytics endpoint allows you to find and filter events associated with a CRM object of any type. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "identifying-contacts",
  "label": "Identifying contacts",
  "parent": null
}, {
  "depth": 0,
  "id": "identify-a-visitor",
  "label": "Identify a visitor",
  "parent": null
}, {
  "depth": 0,
  "id": "tracking-in-single-page-applications",
  "label": "Tracking in single-page applications",
  "parent": null
}, {
  "depth": 1,
  "id": "set-page-path",
  "label": "Set page path",
  "parent": "tracking-in-single-page-applications"
}, {
  "depth": 1,
  "id": "track-page-view",
  "label": "Track page view",
  "parent": "tracking-in-single-page-applications"
}, {
  "depth": 0,
  "id": "privacy-policy-in-tracking",
  "label": "Privacy policy in tracking",
  "parent": null
}, {
  "depth": 0,
  "id": "get-cross-domain-linking-parameters",
  "label": "Get cross-domain linking parameters",
  "parent": null
}, {
  "depth": 0,
  "id": "reapply-analytics-event-handlers",
  "label": "Reapply analytics event handlers",
  "parent": null
}, {
  "depth": 0,
  "id": "tracking-custom-behavioral-events-marketing-hub-enterprise-only",
  "label": "Tracking custom behavioral events (Marketing Hub Enterprise only)",
  "parent": null
}, {
  "depth": 1,
  "id": "trackcustombehavioralevent",
  "label": "trackCustomBehavioralEvent",
  "parent": "tracking-custom-behavioral-events-marketing-hub-enterprise-only"
}, {
  "depth": 1,
  "id": "customize-your-tracking-code-to-send-custom-behavioral-event-data",
  "label": "Customize your tracking code to send custom behavioral event data",
  "parent": "tracking-custom-behavioral-events-marketing-hub-enterprise-only"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      pre: "pre",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Tracking code API overview"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["This page has been updated for working with new custom behavioral events. For legacy custom events, please ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code/v1",
          children: "see the legacy documentation"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition to tracking page views, the HubSpot tracking code allows you to identify visitors, track events, and manually track page views without reloading the page. The tracking code API allows you to dynamically create events and track event data in HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your site uses the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-enable-a-privacy-policy-alert-if-you-are-doing-business-in-europe",
        children: "privacy consent banner"
      }), ", learn how to manage the cookies that are added to a visitor's browser with the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner/cookie-banner-api",
        children: "cookie banner API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Function calls are pushed into the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), " array. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "var _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', { path string }])\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In this article, learn about how you can add functions to the tracking code to identify visitors, track page views, send event data, and more."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identifying contacts"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The HubSpot analytics tool identifies contacts using two pieces of data:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The usertoken, which is stored in the visitor's ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubspotutk"
        }), " browser cookie."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "The contact's email address."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When the HubSpot tracking code tracks a visitor's action, such as a page view, it automatically associates that action with the visitor's usertoken. When you use the tracking code API to identify a visitor by email address, the analytics system will tie that email to the usertoken, allowing HubSpot to update an existing contact record or create a new one. Analytics data associated with the usertoken, such as page views and original source, will then appear on the contact record."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When you know a visitor's identity (e.g., email address), you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#identify-a-visitor",
        children: "identify"
      }), " function to set identities in the tracker. You can then send the identity to HubSpot by making a separate ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#events-js-api",
        children: "trackCustomBehavioralEvent"
      }), " call."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When using this function keep in mind:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Avoid using a placeholder email for unknown visitors, as HubSpot will create a contact record with that placeholder email, and the visitor's ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "usertoken"
        }), " cookie will be associated with that record. This leads to all unknown visitors being associated with your placeholder contact."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If you set an identity to a contact record and have any properties that are unique, we will drop any properties from the identity that violate uniqueness."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Identify a visitor"
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if your account was created before September 8, 2021 and is set up to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/prevent-contact-properties-update-through-tracking-code-api",
          children: "allow contact properties to be updated through the tracking code"
        }), ", you can also include other contact properties to be updated with this function. For accounts created after September 8, 2021, this functionality is deprecated."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push([\"identify\", { {identity details} }]);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use this endpoint to identify website visitors and contacts."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Visitor:"
        }), " refers to any website visitor, regardless of whether they’re a HubSpot contact. HubSpot does not create records for visitors like it does for contacts."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Contact:"
        }), " refers to a visitor or offline contact that has a record in HubSpot. A contact can be identified by its unique email address."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To manually identify a visitor or contact, you can use either an email address or unique external ID:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "email:"
        }), " identify a visitor by email address when you want to update an existing contact or create a new one. If a contact with that email exists in HubSpot, their contact record will update with the analytics data. If no contact exists at that email address, a new record will be created."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "id:"
        }), " a custom external ID that identifies the visitor. Identifying a visitor with this ID will associate analytics data to that visitor. However, using the ID alone will not create a contact in HubSpot. Analytics data can only be associated with an existing contact through this method when:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "the visitor was previously identified by both ID and email."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "the visitor was previously identified by ID and also has a form submission associated with their record."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " this external ID can only be used with the HubSpot tracking code. This ID cannot be used to retrieve or update any records through any other HubSpot tools or APIs. If you know the visitor’s email address, it’s recommended to use that as the unique identifier. Similarly, you should only identify a visitor with by ID when you don’t know their email address."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "If you’ve previously sent analytics data to HubSpot using the visitor’s ID only, you can later include both the ID and an email address to associate the data from that ID with a contact. The existing contact will then be updated or created if no contact currently exists."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nThe below example gets the value of a query string parameter '?email='\nand uses that to identify the visitor\n*/\n\nfunction getParameterByName(name) {\n  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);\n  return match && decodeURIComponent(match[1].replace(/\\+/g, ' '));\n}\n\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: getParameterByName('email'),\n  },\n]);\n\n/*\nThe below example sets the email,\nas well as a custom property favorite_color\n*/\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: getParameterByName('email'),\n    favorite_color: 'orange',\n  },\n]);\n\n/*\nThe below example sets the email and a custom external ID for the visitor. \nThis assumes that your site includes a variable named \n'user' that includes that user's ID in an 'id' \nproperty, and their email in an 'email' property.\n*/\nvar _hsq = (window._hsq = window._hsq || []);\n_hsq.push([\n  'identify',\n  {\n    email: user.email,\n    id: user.id,\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When using this function, keep the following in mind:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["This function call stores the data in the tracker, but the data is not actually passed to HubSpot with this call. The data will only be passed when tracking a page view or an event (with either the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#track-page-view",
          children: "trackPageView"
        }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackEvent"
        }), " functions)."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A contact can only have one ID and/or email address associated with them. If you try to assign two IDs to one email, only the first ID will be associated with the email address."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "You must include an email address to tie the data to a contact."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If your account was created before September 8, 2021 and is set up to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/prevent-contact-properties-update-through-tracking-code-api",
          children: "allow contact properties to be updated through the tracking code"
        }), ", you can also include other contact properties to be updated with this function."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["This function will ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "not"
        }), " restore previously deleted contacts. These contacts must be ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/articles/kcs_article/contacts/restore-deleted-contacts-companies-deals-or-tickets",
          children: "restored in HubSpot"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tracking in single-page applications"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubSpot tracking code will automatically record a page view when the code is first loaded, but you can also manually track page views in a single-page application without reloading the tracking code. You can use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#set-path",
        children: "setPath"
      }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView"
      }), " functions to update and track the current page. For example:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "<!-- Set up the path for the initial page view -->\n<script>\n  var _hsq = window._hsq = window._hsq || [];\n  _hsq.push(['setPath', '/home']);\n</script>\n\n<!-- Load the HubSpot tracking code -->\n<!-- Start of HubSpot Embed Code -->\n  <script type=\"text/javascript\" id=\"hs-script-loader\" async defer src=\"//js.hs-scripts.com/{hubId}.js\">\n</script>\n<!-- End of HubSpot Embed Code -->\n\n<!-- Tracking subsequent page views -->\n<script>\n  var _hsq = window._hsq = window._hsq || [];\n  _hsq.push(['setPath', '/about-us']);\n  _hsq.push(['trackPageView']);\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Set page path"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "hsq.push(['setPath', { path string }])"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Update the path of the current page stored in the tracker. This function should be used by single-page applications to update the current page whenever a page is loaded. After using this function to update the path, you'll need to call the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "trackPageView function"
      }), " to track the view of the current page."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Single-page applications should push a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "setPath"
      }), " call into ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq"
      }), " before the tracking code loads to set the URL that gets tracked for the first page view. See the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#track-page-view",
        children: "track page view"
      }), " section below for an example."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When calling ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "setPath"
      }), ", you'll include the path of the current page. The set path will be treated as relative to the current domain being viewed. The path should always start with a slash. If your URL also contains parameters, these will need to be included in the path as well. View the above code for examples."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "When using this function, keep the following in mind:"
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Any path set using the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " function will override the data in the referrer header. If you call ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " once, you'll need to use ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " to update the path for each view you want to track."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Repeatedly calling ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "setPath"
          }), " will override the referrer, which can impact a contact's original source, depending on when a tracking request is made."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "This function can only update the path of the URL. The domain is set automatically based on the URL of the page on load, and the path that is set using this function is always treated as relative to that detected domain."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "Example usage:\n\n// These examples assume that the domain of the site is\n// www.mydomain.com\n\n// Set the path to '/' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/\n\n// Set the path to '/contact-us' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/contact-us']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/contact-us\n\n// Set the path to '/blog/post?utm_campaign=my-campaign' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/blog/post?utm_campaign=my-campaign']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/blog/post?utm_campaign=my-campaign\n\n// Set the path to '/#/about-us' and track the page\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/#/about-us']);\n_hsq.push(['trackPageView']);\n// This will result in a view being recorded for\n// http://www.mydomain.com/#/about-us\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Track page view"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['trackPageView']);"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Track the page view for the current page. This function is automatically called when the tracking code is loaded on a page, but you can manually call this function to track subsequent views in a single page application."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " calling this function manually before or during the initial page load could lead to duplicate views being tracked."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This function does not support any arguments. The page title tracked will be the current value of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "document.title"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The URL that gets tracked is based on one of the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The path set using the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath function"
        }), ". If your site is built as a single-page application, this function is the preferred method of setting the tracked path. View the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), " section above for disclaimers about the function."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "setPath"
        }), " has never been called, the tracked URL will be the referrer HTTP header in the request being made by the visitor's browser to HubSpot's tracking servers (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/API/History_API#Adding_and_modifying_history_entries",
          children: "modifying the browser history state"
        }), " would update the value used for that header). The referrer header will not support URL fragments (anything after the # in the URL), as fragments are not included in the referrer header."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "Example usage:\n\n// Track a new page using setPath:\n// Update the path stored in the tracker:\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['setPath', '/#/new-page']);\n// Track the page view for the new page\n_hsq.push(['trackPageView']);\n\n\n// Track a new page by updating the browser state:\n// Update the browser state, showing \"updated.html\" in the browser address bar\nvar stateObj = { foo: 'updated' };\nhistory.pushState(stateObj, \"updated page\", \"updated.html\");\n//Track the page view for the new page, '/updated.html'\nvar _hsq = window._hsq = window._hsq || [];\n_hsq.push(['trackPageView']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When using this function, keep in mind the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["While you can't prevent this function from being automatically called when the tracking code loads, you can control the URL recorded for the page by pushing a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "setPath"
        }), " call into ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "_hsq"
        }), " before the tracking code is loaded."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If your site is a single-page application, you should avoid including a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"canonical\">"
        }), " tag in your page. If your page uses ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"canonical\">"
        }), " tags, you'll need to use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: "setPath"
        }), " function to update the path of your new pages, as the canonical URL set from the link tag will override any detected URL if you're only updating the browser history state."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Privacy policy in tracking"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If your site has a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/getting-started-with-hubspot-v2/how-to-enable-a-privacy-policy-alert-if-you-are-doing-business-in-europe",
        children: "privacy consent banner"
      }), " you can use functions to check and manage cookies placed into the visitor's browser. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/cookie-banner/cookie-banner-api",
        children: "managing privacy consent banner cookies"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Get cross-domain linking parameters"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['addIdentityListener', function(hstc, hssc, hsfp) {}])"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubSpot tracking code can be used across multiple sites with separate domains. This function will allow you to get the query parameters required to create links that will allow you to track your visitors ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/reports/set-up-your-site-domains#track-multiple-domains-with-cross-domain-linking",
        children: "across those separate domains"
      }), ". These query parameters are used by the HubSpot tracking code to identify a visitor across domains by ensuring that the separate cookies for the separate domains are merged to a single tracked visitor. You can also use cross-domain query parameters in links that are dynamically added to the page after the tracking code is loaded."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Cross-domain links are only needed when linking to a distinct domain (e.g., domain-one*.com* and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "domain-two.com"
      }), ") that is also being tracked for a single HubSpot account. You do not need cross-domain link parameters when tracking visits between subdomains (e.g., ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "http://www",
        children: "www"
      }), ".", (0, _jsxRuntime.jsx)(_components.em, {
        children: "domain-one.com"
      }), " and ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "blog.domain-one.com"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Get the cross-domain query parameters and store them in a string,\n//so that they can be appended to links as needed.\n_hsq.push([\n  'addIdentityListener',\n  function (hstc, hssc, hsfp) {\n    // Add these query parameters to any links that point to a separate tracked domain\n    crossDomainTrackingParams =\n      '&__hstc=' + hstc + '&__hssc=' + hssc + '&__hsfp=' + hsfp;\n  },\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Reapply analytics event handlers"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push(['refreshPageHandlers'])"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "This function reapplies any analytics event handlers that are set up in the analytics settings for the HubSpot account."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["This would include reapplying any ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/events/create-clicked-element-events",
        children: "clicked element events"
      }), " that have been set up."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can use this function to automatically reapply click handlers when content on the page is updated, such as updating a section of content or displaying a modal window on the page."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " this functionality is automatically triggered as part of the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/analytics-and-events/tracking-code",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "setPath"
          })
        }), " function, so you'll only need to use this function when updating the content without updating the tracked page URL."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Reapply event handlers after updating the page content\n_hsq.push(['refreshPageHandlers']);\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tracking custom behavioral events (Marketing Hub Enterprise only)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Using custom behavioral events, you can tie event completions to contacts records and populate event properties with metadata about the event. To retrieve custom behavioral events, use the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/analytics-and-events/event-analytics",
        children: "web analytics API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Through the API, events can be triggered using the event's internal name, which is assigned automatically when you create the event. You can find an event's internal name either ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events#define-the-api-call",
        children: "in HubSpot"
      }), " or by ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/analytics-and-events/reporting#get-events",
        children: "using the events API"
      }), ". Learn how to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-custom-behavioral-events#define-the-api-call",
        children: "find the internal name for an event"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Imported%20sitepage%20images/custom-event-internal-name.png",
        alt: "custom-event-internal-name"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are three types of events that you can create in HubSpot:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
          children: "Clicked element events"
        }), ":events tied to clickable elements on a website page. Will automatically populate a set of default HubSpot event properties through the tracking code. You can further customize these events with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackCustomBehavioralEvent"
        }), " function."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
          children: "Visited URL events"
        }), ": events tied to page loads at specified URLs. Will automatically populate a set of default HubSpot event properties through the tracking code. You can further customize these events with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#events-js-api",
          children: "trackCustomBehavioralEvent"
        }), " function."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
          children: "Manually tracked behavioral events"
        }), ": custom events that are unique to your business, as well as events that may not be automatically captured by HubSpot or by an integration. Manually send data to HubSpot events through the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/analytics-and-events/custom-events/custom-event-completions",
          children: "HTTP API"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For each event type, HubSpot includes a set of standard properties that can capture certain metadata at the time of completion, including UTM parameters or device and operating system metadata."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Since this function works alongside HubSpot's analytics tracking, any events triggered through the JavaScript API will automatically be associated with the visitor's ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "hubspotutk"
      }), " cookie, so the event would automatically be ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#identities-overview",
        children: "tied to the contact associated with that usertoken"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "trackCustomBehavioralEvent"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "_hsq.push([\"trackCustomBehavioralEvent\", { {event details} }]);"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use this function to track an ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/events-user-guide-v2/a-quick-tour-of-events",
        children: "event"
      }), " using JavaScript and HubSpot's tracking code. You can use events to track specific activities completed by visitors on your site. Tracked events can show up in contacts' timelines."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "/*\nExample code to fire a custom behavioral event using the name \"clicked Buy Now button\" when a visitor clicks an element with the 'buyNow' id.\n*/\n\ndocument.getElementById('buyNow').onclick = function () {\n  _hsq.push([\n    'trackCustomBehavioralEvent',\n    {\n      name: 'pe123456_course_registration',\n      properties: {\n        course_id: 'Math101',\n      },\n    },\n  ]);\n};\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Arguments"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "How to use"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "name:\"internal_name\""
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The event_id or internal name of the event that you created in HubSpot."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "property_name: \"property_value\""
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A list of key-value pairs, with one key-value pair per property.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_name"
            }), " is the internal name of the event property you’ve created for the event, and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "property_value"
            }), " is the value to add to the property. You can also track non-defined properties and go back to create them after event tracking."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Customize your tracking code to send custom behavioral event data"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, HubSpot creates a set of properties for each event you create. For ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-clicked-element-events",
        children: "clicked element"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/analytics-tools/create-visited-url-events",
        children: "visited URL"
      }), " events, HubSpot will auto-populate some of those properties with data. But you can also customize your tracking code to send data to the event's properties."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Reports"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Analytics tools"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Custom Behavioral Events"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " of the event that you want to track."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Properties"
        }), ", copy the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "internal name"
        }), " of the event."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-clicked-element-event-internal-name0.png",
        alt: "custom-clicked-element-event-internal-name0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Then, in the properties table, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of the event property that you want to send data to."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the right panel, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "</> source icon"
          }), " to view the property's internal name. You'll use this name when customizing the tracking code."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/custom-behavioral-event-property-internal-name0.png",
            alt: "custom-behavioral-event-property-internal-name0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Now that you have your event and event property data, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Settings"
          }), " icon to navigate to your account settings. Then, in the left sidebar menu, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Tracking & Analytics"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Tracking code."
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Customize javascript"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/tracking-code-customize-javascript0.png",
            alt: "tracking-code-customize-javascript0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Add custom JavaScript"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the right sidebar, enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " for your custom JavaScript, then enter the JavaScript, including the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#events-js-api",
            children: "trackCustomBehavioralEvent"
          }), " function. This JavaScript be executed after the tracking code loads for a page."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// example usage\n_hsq.push(['trackCustomBehavioralEvent',{\n   name: '((behavioral_event_internal_name))”,\n   properties: { internal_property_name: property_value}\n}\n]);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For example, if your event tracks a course registration when a button with the HTML ID ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "register_for_econ101"
      }), " is clicked, your JavaScript might look like the following: ", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/customize-tracking-code0-1.png",
        alt: "customize-tracking-code0-1"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Save"
        }), " to save your JavaScript. Your tracking code will now load with your custom JavaScript."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}