import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { keyToNavData } from 'developer-docs-shared-components/config/navData/keys';
import { getKeyFromUrl } from 'developer-docs-shared-components/config/navData/utils';
import { apiEndpointsNavConfig } from '../config/nav/reference/apiEndpoints';
import { apiGuidesNavConfig } from '../config/nav/guides/apiGuides';
import { cmsDevelopmentNavConfig } from '../config/nav/guides/cmsDevelopment';
import { getStartedNavConfig } from '../config/nav/getStarted/getStarted';
import { crmCustomizationNavConfig } from '../config/nav/guides/crmCustomization';
import { appsNavConfig } from '../config/nav/guides/apps';
import { getPathNames } from '../config/nav/navUtils';
import { uiComponentsNavConfig } from '../config/nav/reference/uiComponents';
import { cmsReferenceNavConfig } from '../config/nav/reference/cmsReference';

/**
 * @param config takes in the the title of the endpoint or guide which will be
 * the last entry in the breadcrumb matches static breadcrumbs defined for the
 * current route in navigation.ts keyToNavData, determines the current route
 *  using react router and the supplied title and pageType (guide vs api
 * reference page)
 * @returns an array with the breadcrumbs for the current route
 */
export const useGetBreadcrumbsByUrl = (config, pathname, includePageTitle) => {
  const location = useLocation();

  /**
   * Maps absolute base path for this nav section (typically consisting of
   * two levels in the format `/PRIMARY/SECONDARY`) to the section's link and
   * text to render as breadcrumbs.
   */
  const breadCrumbs = useMemo(() => {
    const apiPathByRoute = [cmsDevelopmentNavConfig(), apiGuidesNavConfig(), apiEndpointsNavConfig(), getStartedNavConfig(), crmCustomizationNavConfig(), appsNavConfig(), uiComponentsNavConfig(), cmsReferenceNavConfig()].reduce((acc, {
      commonPath,
      sectionLabel: headerLabel
    }) => {
      acc[commonPath] = {
        link: commonPath,
        name: headerLabel
      };
      return acc;
    }, {});
    const breadcrumbPath = getKeyFromUrl(pathname || location.pathname);
    const {
      primaryDir,
      secondaryDir
    } = getPathNames(breadcrumbPath);
    const currentPage = config && config.title ? [{
      name: config.title
    }] : [];
    const apiRoute = apiPathByRoute[`/${primaryDir}/${secondaryDir}`] ? apiPathByRoute[`/${primaryDir}/${secondaryDir}`].link : `/${primaryDir}`;
    const matchedPath = apiPathByRoute[apiRoute];
    if (!matchedPath) {
      return [...currentPage];
    }
    const {
      link,
      name
    } = matchedPath;
    const primaryDirs = [{
      link,
      name
    }];
    const keys = Object.keys(keyToNavData[link]);
    const matchingPath = keys.find(key => breadcrumbPath.replace(link, '') === key);
    if (!matchingPath) {
      return [...primaryDirs, ...currentPage];
    }
    const matchedRoute = keyToNavData[link][matchingPath];
    return [...primaryDirs, ...matchedRoute.path().map(path => ({
      name: path
    })), ...currentPage, ...(matchedRoute && includePageTitle ? [{
      name: matchedRoute.label()
    }] : [])];
  }, [pathname, location.pathname, config, includePageTitle]);
  return breadCrumbs;
};