"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 176399016038;
const slug = exports.slug = 'guides/crm/public-apps/creating-public-apps';
const title = exports.title = 'Creating public apps using projects (BETA)';
const name = exports.name = 'Create public apps using projects (BETA)';
const metaDescription = exports.metaDescription = 'Learn how to create a public app using a developer projects to create UI extensions and more.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "prerequisites",
  "label": "Prerequisites",
  "parent": null
}, {
  "depth": 0,
  "id": "create-an-app",
  "label": "Create an app",
  "parent": null
}, {
  "depth": 0,
  "id": "app-configuration",
  "label": "App configuration",
  "parent": null
}, {
  "depth": 1,
  "id": "managing-app-configuration",
  "label": "Managing app configuration",
  "parent": "app-configuration"
}, {
  "depth": 0,
  "id": "view-your-app-in-hubspot",
  "label": "View your app in HubSpot",
  "parent": null
}, {
  "depth": 1,
  "id": "view-ui-extension-logs",
  "label": "View UI extension logs",
  "parent": "view-your-app-in-hubspot"
}, {
  "depth": 1,
  "id": "log-traces",
  "label": "Log traces",
  "parent": "view-your-app-in-hubspot"
}, {
  "depth": 1,
  "id": "deleting-a-project-based-public-app",
  "label": "Deleting a project-based public app",
  "parent": "view-your-app-in-hubspot"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      p: "p",
      em: "em",
      a: "a",
      h1: "h1",
      h2: "h2",
      ul: "ul",
      li: "li",
      pre: "pre",
      code: "code",
      strong: "strong",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      Alert,
      RelatedApiLink
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The features described in this guide are in Early Access beta, separate from the ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "CRM development tools to build UI extensions with React as frontend"
        }), " beta for private app UI extension development."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/build-app-cards",
          children: "Request access to the UI extensions for public apps beta"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h1, {
      children: "Create public apps using projects (BETA)"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Public apps enable you to authenticate requests to HubSpot's APIs in your project using OAuth. Public apps can be installed in multiple accounts through a streamlined end-user flow, and can be submitted to HubSpot's App Marketplace. When creating using the developer projects framework, public apps can include UI extensions in addition to other app extensions, such as timeline extensions and workflow actions."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, learn how to create and configure a public app using projects."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prerequisites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Before getting started, ensure you've also set up the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/setup",
            children: "Set up your local environment"
          }), " for project development."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Create a ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types#app-developer-accounts",
            children: "developer account"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your developer account, ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types#create-a-developer-test-account",
            children: "create a test account"
          }), " that you can use to install and test the app."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Because you'll be using OAuth, you'll need to set up a self-hosted backend environment to complete the OAuth process. If you haven't set up with OAuth with HubSpot before, it's recommended to follow the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/oauth-quickstart-guide",
            children: "OAuth quickstart guide"
          }), ". That guide uses a HubSpot-provided ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://github.com/HubSpot/oauth-quickstart-nodejs",
            children: "Node.js OAuth quickstart app"
          }), " which is configured to work with the example project you'll be creating in this tutorial."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create an app"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using the CLI, you can create a project with a public app using any of the following methods:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Follow the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/quickstart",
          children: "quickstart guide"
        }), " to create an app from a boilerplate project template, which includes a card for contact records."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/migrate-a-public-app-to-projects",
          children: "Migrate an existing public app"
        }), " to the developer projects framework."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Create a public app from scratch locally with a developer project."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create a public app from scratch:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "In the terminal, navigate to the directory that you'll be storing your project files in."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd path/to/working/directory\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Since you'll need to deploy your project to your app developer account, set it as the default account by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs accounts use <accountName>"
        }), " and selecting your developer account."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Run ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project create"
        }), " to create a new project."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project create\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Follow the prompts in the terminal to enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "name"
        }), " and ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "location"
        }), " for the project."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["When prompted to choose a template, select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Create an empty project (no template)"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["With your project created, navigate into the newly created directory using the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "cd"
        }), " command."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "cd <project-directory-name>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create the following files and directories:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the src directory, create an ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "app"
            }), " directory."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "src/app"
            }), ", create a ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "public-app.json"
            }), " file. Below is the minimum schema required for uploading an app. View the full ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "public-app.json"
            }), " schema in the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#app-configuration",
              children: "App configuration"
            }), " section below."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "uid"
        }), " value must be a unique value, and cannot be changed after upload. Changing this name later will result in a new app being created and associated with the project on upload. This value is an internal name and won't be exposed to end-users."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example public-app.json\n{\n  \"name\": \"App name here\",\n  \"uid\": \"immutable-unique-app-id-here\",\n  \"description\": \"An example public app built with developer projects.\",\n  \"auth\": {\n    \"redirectUrls\": [\"http://localhost:3000/oauth-callback\"],\n    \"requiredScopes\": [\n      \"crm.objects.contacts.read\",\n      \"crm.objects.contacts.write\",\n      \"oauth\"\n    ]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["After saving the file, upload it to your developer account by running ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs project upload"
        }), ". Then, follow the prompts in the terminal to create the project in your account."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-shell",
        children: "hs project upload\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "App configuration"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Public apps built with projects include a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "public-app.json"
      }), " file to configure the app's details, such as its name, scopes, included extension files, and more."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "public-app.json"
      }), " file uses the following schema:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example public-app.json\n{\n  \"name\": \"Getting Started App\",\n  \"uid\": \"getting-started-app-uid\",\n  \"description\": \"An example project of how to build a Public App with Developer Projects\",\n  \"logo\": \"./my-company.png\",\n  \"allowedUrls\": [\n    \"https://app.example.com\",\n    \"https://app.example2.com:8080\",\n    \"https://api.example.com/user/\",\n    \"https://api.example2.com/user\"\n  ],\n  \"auth\": {\n    \"redirectUrls\": [\"http://localhost\"],\n    \"requiredScopes\": [\n      \"crm.objects.contacts.read\",\n      \"crm.objects.contacts.write\"\n    ],\n    \"optionalScopes\": [],\n    \"conditionallyRequiredScopes\": []\n  },\n  \"support\": {\n    \"supportEmail\": \"support@example.com\",\n    \"documentationUrl\": \"https://example.com/docs\",\n    \"supportUrl\": \"https://example.com/support\",\n    \"supportPhone\": \"+18005555555\"\n  },\n  \"extensions\": {\n    \"crm\": {\n      \"cards\": [\n        {\n          \"file\": \"./extensions/example-card.json\"\n        }\n      ]\n    }\n  },\n  \"webhooks\": {\n    \"file\": \"./webhooks.json\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The app's name. Can be any string up to 200 characters. Must not start or end with a whitespace character."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "uid"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The app's unique ID. Can be any string up to 64 characters. Characters can be uppercase or lowercase, and can include numbers, underscores (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "_"
            }), "), dashes (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "-"
            }), "), and periods (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "."
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "description"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Describes what the app does for the installing user. Can be any string up to 8192 characters."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "logo"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The file path for the app's logo, which appears next to the app's name. Must be a valid image with one of the following extensions:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "png"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "jpeg/jpg"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "gif"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "bmp"
              })]
            }), "The image must be less than 5,000,000 bytes (roughly 5MB)."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowedUrls"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array containing the URLs that the React app is allowed to call. URLs must use the HTTPS scheme and must contain an ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://developer.mozilla.org/en-US/docs/Learn/Common_questions/Web_mechanics/What_is_a_URL#authority",
              children: "authority"
            }), ", followed by an optional path prefix if needed. You do not need to add any override URLs specified in ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "local.json"
            }), " to this array.A request URL will match an allowed URL match when:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "The scheme is HTTPS"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "The authority is an exact match"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "The path starts with the allowed URL's path prefix if specified."
              })]
            }), "Query parameters are ignored. Regex is not supported.In the above example code, the following request URLs will be allowed:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://app.example.com",
                  children: "https://app.example.com"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://app.example.com/",
                  children: "https://app.example.com/"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://app.example.com/home",
                  children: "https://app.example.com/home"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://app.example2.com:8080/",
                  children: "https://app.example2.com:8080/"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://api.example.com/user/12345",
                  children: "https://api.example.com/user/12345"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://api.example2.com/users",
                  children: "https://api.example2.com/users"
                })
              })]
            }), "And the following request URLs will ", (0, _jsxRuntime.jsx)("u", {
              children: "not"
            }), " be allowed:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "http://localhost",
                  children: "http://localhost"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "http://app.example.com",
                  children: "http://app.example.com"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://example.com",
                  children: "https://example.com"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://app.example.com.us",
                  children: "https://app.example.com.us"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://app.example2.com",
                  children: "https://app.example2.com"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://api.example.com/users",
                  children: "https://api.example.com/users"
                })
              }), (0, _jsxRuntime.jsx)("li", {
                children: (0, _jsxRuntime.jsx)(_components.a, {
                  href: "https://api.example2.com/v2/users",
                  children: "https://api.example2.com/v2/users"
                })
              })]
            }), "The local development server will log a warning if the UI extension requests a URL that doesn't match any of the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "allowedUrls"
            }), ", but will allow the request through."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirectUrls"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array of strings containing the URLs that the OAuth process is allowed to reroute back to. Each app must have at least one auth redirect URL, and it must use HTTPS. The only exception is that ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "http://localhost"
            }), " is allowed for testing."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "advancedScopeSettingsEnabled"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolean"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Configures when ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "optional and conditionally required scopes"
            }), " can be added to your app's auth configuration. When set to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), ", new scopes for conditionally required or optional scopes cannot be added or enforced (default is ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), ").", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Please note:"
            }), " you must set this field to ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " (or leave unspecified) and declare your app's use of optional and conditionally required scopes when seeking update approval for your App Marketplace app after adding a UI extension."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "requiredScopes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array of strings listing your app's required scopes. Each app must include at least one scope, and the installing user must grant these scopes to successfully install the app. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/scopes",
              children: "full list of scopes"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "optionalScopes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An array of strings listing your app's optional scopes. These scopes can be excluded from the authorization during installation if the account or user installing the app doesn't have the proper permissions. In that case, the scope will not be included in the resulting refresh token or access token. See ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/authentication/scopes",
              children: "full list of scopes"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "supportEmail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A valid email address that users can contact for support."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "documentationUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The external URL that users can navigate to for supporting documentation. Must use HTTPS."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "supportUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The external URL that users can navigate to for additional support. Must use HTTPS."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "supportPhone"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The phone number that users can contact for support. Must start with a plus sign (", (0, _jsxRuntime.jsx)(_components.code, {
              children: "+"
            }), ")."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "extensions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An object that defines the path to the relevant UI extension configuration file for each app card. Multiple extensions can be included as comma-separated ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "file"
            }), " objects in the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "cards"
            }), " array."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "webhooks"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An object that defines the path to the app's webhooks configuration file."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Managing app configuration"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When building public apps using projects, some features must be updated locally in your project files, while others must be managed in the app settings UI in HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The following app details can only be managed in your local project files:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["App name, logo, and description (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-configuration",
          children: "public.app.json"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Contact and support information (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-configuration",
          children: "public.app.json"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Auth redirect URLs, scopes and scope settings (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-configuration",
          children: "public.app.json"
        }), (0, _jsxRuntime.jsx)(_components.a, {
          href: "#public-app-json-schema"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["UI extension functionality (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "ExtensionFile.jsx"
        }), "/", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".tsx"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Webhooks and webhook settings (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/public-apps/webhooks#defining-webhook-subscriptions",
          children: "webhooks.json"
        }), ")"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "All other public app features, such as timeline events, will need to be maintained using the in-app app settings page in HubSpot. When a setting can only be managed locally, the app settings page will disable the field and display a lock icon, which you can hover over for more information."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/app-settings-fields-ui-for-project-based-apps.png",
        alt: "app-settings-fields-ui-for-project-based-apps"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "View your app in HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To view and manage your app in HubSpot, including monitoring usage:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar of your developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Apps"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-account-apps-nav.png",
        alt: "developer-account-apps-nav"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of the app."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "App Info"
          }), " tab, you can view basic information about your app, such as the app name and description. Fields that are greyed out with a lock icon can only be updated locally."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-settings-page.png",
            alt: "public-app-settings-page"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Auth"
          }), " tab to view the app's authentication information, such as ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "App ID"
          }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Client ID"
          }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Client secret"
          }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Install URL"
          }), ", and scopes."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Contact & Support"
          }), " to view the app's company information and support details. On this tab, you can also ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview#add-a-verified-domain",
            children: "set up a verified domain"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Monitoring"
          }), " to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview#monitor-app-behavior",
            children: "monitor your app's usage data"
          }), ". For public apps built with projects, you can also ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#view-ui-extension-logs",
            children: "view detailed UI extension logs"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "In the left sidebar, you can also view the app's features, including:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/extensions/crm-cards",
              children: "Classic CRM cards"
            }), " (separate from the app cards you can build with UI extensions)"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/ui-extensions/create",
              children: "UI extensions"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/extensions/timeline",
              children: "Timeline events"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/public-apps/webhooks",
              children: "Webhooks"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/create-an-app-settings-page",
              children: "App settings page"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "View UI extension logs"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Monitoring"
      }), " page, you can view logs for the app's UI extensions, including logs for extension rendering and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hubspot.fetch()"
      }), " requests. To view an app's UI extension logs:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar of your developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Apps"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-account-apps-nav.png",
            alt: "developer-account-apps-nav"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of the app."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["On the app settings page, in the left sidebar, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Monitoring"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-monitoring.png",
        alt: "public-app-monitoring"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "UI extensions"
          }), " tab."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-monitoring-ui-extensions.png",
            alt: "public-app-monitoring-ui-extensions"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the table, you can view all logs for the app's included UI extensions. To filter logs by specific criteria, use the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Filter by"
          }), " dropdown menus:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Result:"
            }), " filter logs by the type of result, including error and success logs for extension rendering and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubspot.fetch()"
            }), " requests."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "CRM object type:"
            }), " filter logs by the type of CRM object associated with the event."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Extension name:"
            }), " filter logs by extension, which can be useful when your app includes multiple extensions."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["To view more details for an event, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "event"
          }), ", then view the right panel."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Log ID"
              }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Request ID"
              }), " to search for the event by ID on the ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "UI extensions"
              }), " tab."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-monitoring-ui-extensions-request-id.png",
                alt: "public-app-monitoring-ui-extensions-request-id"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["You can use the ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Trace ID"
              }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Traceparent"
              }), " value to search for the event on the ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Log traces"
              }), " tab. Alternatively, you can click ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "View log trace"
              }), " to navigate directly to the event on the ", (0, _jsxRuntime.jsx)(_components.em, {
                children: "Log traces"
              }), " tab."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-monitoring-ui-extensions-log-traces.png",
                alt: "public-app-monitoring-ui-extensions-log-traces"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Learn more about log traces below."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Log traces"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When troubleshooting errors, log traces are helpful for quickly debugging issues. You can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-traces",
        children: "navigate to log traces directly from an event"
      }), " on the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "UI extensions tab"
      }), " by clicking ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "View log trace"
      }), ", or by searching for the event by trace ID on the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Log traces"
      }), " tab."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-monitoring-ui-extensions-log-traces-tab.png",
        alt: "public-app-monitoring-ui-extensions-log-traces-tab"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When viewing the log trace for an event, you'll see a more detailed breakdown of the event, including error details, request timing, and more, in the ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Log details"
      }), " section."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/public-app-monitoring-ui-extensions-log-tracing-example-2.png",
        alt: "public-app-monitoring-ui-extensions-log-tracing-example-2"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Deleting a project-based public app"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To delete a public app that was created in a project, you'll first need to delete the project and its association to the app."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To delete a project associated with a public app:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar of your developer account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Projects"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-account-left-sidebar-navigation.png",
            alt: "developer-account-left-sidebar-navigation"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of the project."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Settings"
          }), " tab, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Delete [project name]"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/developer-account-delete-project.png",
            alt: "developer-account-delete-project"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the dialog box, enter the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "project name"
          }), " and click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Delete project"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With the project deleted, you can delete the app:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the left sidebar of your developer account, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Apps"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " of the app."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["On the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "App info"
          }), " tab, in the ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Delete this app"
          }), " section, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Delete [app name]"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/delete-this-public-app.png",
            alt: "delete-this-public-app"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the dialog box, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Export your logs"
          }), " link to export a record of the app's activity. Then, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "I've exported the logs"
          }), " checkbox and click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Delete"
          }), "."]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}