"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 92687831417;
const slug = exports.slug = 'guides/cms/content/overview';
const title = exports.title = 'Composants du CMS Hub';
const name = exports.name = 'EMEA FR CMS building blocks overview';
const metaDescription = exports.metaDescription = 'Les développeurs peuvent utiliser une série de composants pour créer des sites web sur le CMS Hub. Les composants peuvent être utilisés pour créer un système de conception dans lequel les créateurs de contenu peuvent travailler, avec différents degrés de flexibilité et garde-fous en fonction des besoins de votre entreprise.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "th%C3%A8mes",
  "label": "Thèmes",
  "parent": null
}, {
  "depth": 0,
  "id": "mod%C3%A8les",
  "label": "Modèles",
  "parent": null
}, {
  "depth": 0,
  "id": "modules",
  "label": "Modules",
  "parent": null
}, {
  "depth": 0,
  "id": "champs-de-module-et-de-th%C3%A8me",
  "label": "Champs de module et de thème",
  "parent": null
}, {
  "depth": 0,
  "id": "contenu-global",
  "label": "Contenu global",
  "parent": null
}, {
  "depth": 0,
  "id": "menus-et-navigation",
  "label": "Menus et navigation",
  "parent": null
}, {
  "depth": 0,
  "id": "formulaires",
  "label": "Formulaires",
  "parent": null
}, {
  "depth": 0,
  "id": "param%C3%A8tres-du-site-web",
  "label": "Paramètres du site web",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      h2: "h2",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink
    } = _components;
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Présentation des composants du CMS"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les développeurs utilisent une série de composants pour créer des sites web sur le logiciel de CMS de HubSpot. Les composants peuvent être utilisés pour créer un système de conception dans lequel les créateurs de contenu peuvent travailler, avec différents degrés de flexibilité et garde-fous en fonction des besoins de votre entreprise."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Thèmes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview",
        children: "thème"
      }), " est un ensemble portable et autonome de ressources de développement conçues pour fonctionner conjointement afin d'offrir une expérience marketing conviviale en matière de modification de contenu. Un thème est un composant de premier niveau qui définit l'aspect ainsi que la convivialité d'un site web et crée une expérience d'édition de contenu conviviale pour les spécialistes du marketing."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modèles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modèles"
      }), " définissent le balisage et le style de base d'un ensemble de pages qui utilisent un modèle. Lorsque les créateurs de contenu créent une page ou un e-mail, ils sélectionnent un modèle à utiliser avant d'y ajouter du contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "modules"
      }), " sont des composants réutilisables que vous pouvez placer sur des modèles et des pages dans CMS Hub. Ils comprennent des contrôles pour les spécialistes du marketing et contiennent des balises HubL/HTML, CSS et JavaScript qui, ensemble, construisent des composants réutilisables et modifiables d'un site web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Champs de module et de thème"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "champs"
      }), " sont les contrôles que les créateurs de contenu utilisent pour ajuster les paramètres transmis à vos thèmes et modules. Grâce aux champs, les composants créés par les développeurs peuvent être réutilisés à travers différents éléments de contenu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contenu global"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "contenu global"
      }), " permet de partager le balisage et le contenu sur un site web. Le contenu global simplifie la gestion d'éléments comme les en-têtes, les pieds de page, les barres latérales ou d'autres éléments globaux d'un site web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Menus et navigation"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/menus-and-navigation",
        children: "Les menus"
      }), " vous permettent de créer facilement une hiérarchie de navigation et une architecture d'informations pour naviguer sur votre site web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Formulaires"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/forms",
        children: "Les formulaires"
      }), " vous permettent de capturer plusieurs types d'informations auprès des utilisateurs (telles que des coordonnées, des adresses e-mail et des commentaires) que vous pouvez ensuite utiliser dans l'écosystème HubSpot dans le cadre d'activités quotidiennes telles que le contenu intelligent, les workflows, la personnalisation du contenu et bien plus encore."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paramètres du site web"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La section ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/website-settings",
        children: "Paramètres du site web"
      }), " est un espace centralisé où divers paramètres de contenu global et systémiques peuvent être configurés pour votre site web, comme votre logo, la favicon, les modèles de système et bien plus encore."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}