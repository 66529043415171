"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611087;
const slug = exports.slug = 'guides/cms/content/templates/types/drag-and-drop-templates';
const title = exports.title = 'Modelos de arrastar e soltar';
const name = exports.name = '[novo] Modelos - Arrastar e soltar';
const metaDescription = exports.metaDescription = 'Os Modelos de arrastar e soltar do Gerenciador de design são criados usando um construtor visual. Eles não são recomendados para novos modelos, pois os modelos codificados oferecem uma experiência melhor.';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/image2-3.png';
const featuredImageAltText = exports.featuredImageAltText = 'Interface do Gerenciador de design com módulos selecionados';
const toc = exports.toc = [{
  "depth": 0,
  "id": "o-criador-de-modelos-do-tipo-arrastar-e-soltar",
  "label": "O Criador de modelos do tipo arrastar e soltar",
  "parent": null
}, {
  "depth": 1,
  "id": "m%C3%B3dulos",
  "label": "Módulos",
  "parent": "o-criador-de-modelos-do-tipo-arrastar-e-soltar"
}, {
  "depth": 1,
  "id": "grupos",
  "label": "Grupos",
  "parent": "o-criador-de-modelos-do-tipo-arrastar-e-soltar"
}, {
  "depth": 1,
  "id": "grupos-globais",
  "label": "Grupos globais",
  "parent": "o-criador-de-modelos-do-tipo-arrastar-e-soltar"
}, {
  "depth": 1,
  "id": "colunas-flex%C3%ADveis",
  "label": "Colunas flexíveis",
  "parent": "o-criador-de-modelos-do-tipo-arrastar-e-soltar"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      strong: "strong",
      code: "code",
      a: "a",
      h2: "h2",
      h3: "h3",
      img: "img"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Modelos de arrastar e soltar"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modelos de arrastar e soltar não são recomendados para novos modelos."
        }), " Esses modelos não podem fazer parte de um tema e, portanto, não suportam a funcionalidade de tema, como campos de tema e módulos de tema. Modelos de arrastar e soltar ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "NOT"
        }), " são compatíveis com o CMS Hub Starter; em vez disso, use ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "dnd_area"
        }), ". Os Modelos de arrastar e soltar não são compatíveis com vários dos recursos mais recentes do CMS (", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/memberships/overview",
          children: "associações"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "áreas de arrastar e soltar"
        }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections",
          children: "seções reutilizáveis"
        }), ", GraphQL e muitos outros recursos). Eles não têm extensibilidade e não fornecem uma experiência de criador e desenvolvedor de conteúdo tão boa quanto os modelos codificados. Em vez disso, recomendamos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "modelos HTML + HubL codificados"
        }), " com ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "áreas de arrastar e soltar"
        }), ", que oferecem uma experiência melhor para desenvolvedores e profissionais de marketing. Para um início rápido no CMS, recomendamos verificar o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/cms-theme-boilerplate",
          children: "Boilerplate do tema do HubSpot"
        }), ", criado usando modelos codificados."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-enterprise', 'marketing_hub-professional', 'cms-professional', 'cms-enterprise']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview",
        children: "modelos"
      }), " de arrastar e soltar foram projetados para que usuários menos técnicos possam criar facilmente um site no HubSpot CMS. Os Modelos de arrastar e soltar aproveitam um criador de modelos visuais, que gera HTML + HubL de modo subjacente."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para que o construtor visual funcione, a saída HTML é controlada pelo HubSpot e você não tem controle direto sobre as partes estruturais dele. Além disso, ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "um arquivo layout.css é carregado na página, habilitando uma grade básica de 12 colunas"
      }), " com base no bootstrap 2. Isso faz com que todos os sites criados com Modelos de arrastar e soltar sejam, por padrão, responsivos, fazendo com que as linhas de conteúdo sejam empilhadas verticalmente. Para uma capacidade de resposta mais complexa e personalizada, adicione seus próprios estilos responsivos."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os Modelos de arrastar e soltar são criados no Gerenciador de design. Ao interagir com eles por meio da API ou das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "ferramentas de desenvolvimento local,"
      }), " eles são representados como JSON. Devido à natureza dos Modelos de arrastar e soltar, a única maneira recomendada de editá-los é por meio da interface do Gerenciador de design. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Se você preferir codificar, use o controle de versão ou, se simplesmente quiser editar localmente usando suas ferramentas preferidas, os modelos codificados HTML + HubL oferecem a melhor experiência para os desenvolvedores."
      }), " A funcionalidade ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "dnd_area"
      }), " para modelos HTML + HubL também oferece uma experiência melhor para os criadores de conteúdo do que a interface de arrastar e soltar do gerenciador de design, pois os mantém no editor de conteúdo visual."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "O Criador de modelos do tipo arrastar e soltar"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar um modelo de arrastar e soltar, abra o Gerenciador de design e, no localizador, crie um novo arquivo, escolha um modelo e o ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#template-types",
        children: "tipo de modelo"
      }), " que você está criando."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os Modelos de arrastar e soltar são compostos de blocos de criação. Esses blocos são módulos, grupos, grupos globais e colunas flexíveis."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts",
        children: "Saiba como usar o criador de modelos de arrastar e soltar."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Módulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os módulos são componentes reutilizáveis que são partes editáveis de uma página. Os módulos são compostos por um fragmento de modelo HTML + HubL, CSS, JS e campos. Os módulos podem ser colocados dentro de colunas flexíveis e dnd_areas por editores de conteúdo e são a principal maneira pela qual os editores de conteúdo gerenciam o conteúdo do site. Você pode criar módulos para lidar com qualquer número de funções, pesquisa, galerias de imagens, menus, animações de marketing complexas, calculadoras, comparações de produtos; as possibilidades ficam por conta da sua imaginação e o que você achar que fornece uma boa experiência para os criadores de conteúdo. Os campos são como o editor de conteúdo controla seu resultado. Os módulos não são exclusivos para Modelos de arrastar e soltar; eles são um bloco de criação central muito importante para o HubSpot CMS. Em Modelos de arrastar e soltar, os valores padrão para campos de módulo podem ser configurados no ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template",
        children: "inspetor"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/overview",
        children: "Saiba mais sobre os Módulos."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image2-3.png",
        alt: "Interface do Gerenciador de design com módulos em destaque"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Grupos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os grupos são envoltórios de outros grupos e módulos; eles podem ter classes de CSS e divisão de texto HTML. Os grupos se manifestam como divisão de texto HTML com classes de layout para facilitar o posicionamento e o dimensionamento dos grupos. Os grupos também podem ter um nome apenas interno. Com isso, você pode ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts#group-modules",
        children: "agrupar módulos"
      }), " no Gerenciador de design, facilitando a identificação visual de partes de uma página. Um exemplo disso pode ser para barras laterais ou banners."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Como os arquivos ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/types/html-hubl-templates",
          children: "HTML + HubL"
        }), " são o caminho recomendado para novos sites, as colunas, seções e linhas das ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "áreas de arrastar e soltar"
        }), " substituem em grande parte as finalidades dos grupos."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Além disso, um desenvolvedor pode criar parciais e ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/global-content",
          children: "parciais globais"
        }), ", que podem conter código de forma livre, além de áreas de arrastar e soltar."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "As áreas de arrastar e soltar, parciais e parciais globais não são compatíveis com Modelos de arrastar e soltar."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hubfs/image4-1.png",
        alt: "Captura de tela do gerenciador de design com o grupo da barra lateral selecionado"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Grupos globais"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os grupos globais são grupos que, quando editados, afetam todo o site, e não apenas a página em que estão. Os grupos globais podem existir em vários modelos e são normalmente usados para cabeçalhos e rodapés de sites. Os grupos globais são semelhantes aos parciais, mas estão limitados à estrutura aplicada pelos Modelos de arrastar e soltar. Eles podem ser incorporados em arquivos HTML + HubL, se necessário, mas na maioria das vezes faz mais sentido usar uma parcial global."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/image1-3.png",
        alt: "Interface do gerenciador de design com grupos globais de cabeçalho e rodapé destacados"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Colunas flexíveis"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As colunas flexíveis são um tipo especial de grupo. Elas podem conter um conjunto padrão de módulos, mas os editores de conteúdo podem adicionar, remover e mover módulos dentro delas. As colunas flexíveis permitem o reordenamento dos módulos de forma unidimensional, verticalmente para cima e para baixo. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Ao contrário das tags dnd_area"
      }), ", **as colunas flexíveis não são compatíveis com seções ou recursos de estilo, fornecidos aos módulos dentro delas.**As colunas flexíveis não são exclusivas para Modelos de arrastar e soltar; há uma ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags#flexible-column",
        children: "tag HubL"
      }), " que pode ser usada nos modelos ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/types/html-hubl-templates",
        children: "HTML + HubL"
      }), ". ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/structure-and-customize-template-layouts#add-a-flexible-column",
        children: "Saiba como adicionar uma coluna flexível a um modelo de arrastar e soltar."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Geralmente, é recomendável usar áreas de arrastar e soltar, pois na maioria das vezes elas são mais úteis e fornecem todos os recursos das colunas flexíveis."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Ainda pode haver momentos úteis para usar uma coluna flexível, para algo como uma barra lateral. Para as principais áreas de conteúdo, as áreas de arrastar e soltar são muito mais flexíveis."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hubfs/image3-2.png",
        alt: "Captura de tela do Gerenciador de design com o grupo de conteúdo principal selecionado"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por que usar colunas flexíveis? Os sites geralmente não são rígidos. Eles são criados e mantidos por longos períodos de tempo. Por exemplo, uma página inicial para uma empresa pode mostrar produtos em destaque e mudar frequentemente à medida que as necessidades de marketing da empresa mudam ao longo do tempo. Como o conteúdo de uma coluna flexível pode ser adicionado/removido e modificado por editores de conteúdo, a experiência é menos trabalhosa para os profissionais de marketing e permite que os desenvolvedores se concentrem no que gostam, criando materiais interessantes, em vez de fazer pequenos ajustes na página."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Da mesma forma, as páginas em todo o site podem ter necessidades estruturais diferentes. As colunas flexíveis dão aos profissionais de marketing o controle para criar essas páginas usando módulos personalizados."
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 8,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Adicionar código personalizado aos Modelos de arrastar e soltar"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Existem algumas maneiras de adicionar um código personalizado aos Modelos de arrastar e soltar. O método principal é por meio de módulos personalizados. Às vezes, você pode precisar adicionar código que envolva módulos ou grupos. Para fazer isso, clique no módulo ou grupo para visualizá-lo no inspetor e encontrar o campo de divisão de texto HTML. Adicione o HTML."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Às vezes, você pode precisar adicionar um código ao cabeçalho do seu HTML ou logo acima de ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "</body>"
          }), ". Com o modelo aberto, certifique-se de que você não tenha nada selecionado. O inspetor mostrará os campos para o próprio modelo. Aqui, você pode vincular folhas de estilo e arquivos javascript, além de incluir HTML adicional no cabeçalho ou logo antes da tag ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "</body>"
          }), "."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Para isso, use o", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template",
            children: " inspetor"
          }), ". O inspetor tem campos no nível do modelo para ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template##attach-stylesheets-and-javascript-files",
            children: "folhas de estilo, javascript"
          }), ", marcação ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/cos-general/use-the-inspector-to-style-your-template#customize-your-template-s-head-and-body-options",
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "<head>"
            })
          }), " etc."]
        })]
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://designers.hubspot.com/hubfs/image5.png",
            alt: "painel do inspetor de código personalizado do gerenciador de design"
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}