"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76633369011;
const slug = exports.slug = 'guides/cms/marketplace/theme-requirements';
const title = exports.title = 'Exigences relatives aux thèmes du marketplace des modèles HubSpot';
const name = exports.name = 'Marketplace des modèles | Exigences relatives aux thèmes (2022)';
const metaDescription = exports.metaDescription = 'Découvrez-en davantage sur les exigences auxquelles un thème doit répondre pour être soumis sur le marketplace des ressources.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "limites-concernant-les-th%C3%A8mes",
  "label": "Limites concernant les thèmes",
  "parent": null
}, {
  "depth": 0,
  "id": "conditions-globales-en-mati%C3%A8re-de-th%C3%A8me",
  "label": "Conditions globales en matière de thème",
  "parent": null
}, {
  "depth": 1,
  "id": "structure-des-fichiers-du-th%C3%A8me",
  "label": "Structure des fichiers du thème",
  "parent": "conditions-globales-en-mati%C3%A8re-de-th%C3%A8me"
}, {
  "depth": 1,
  "id": "chemin-de-fichier-local-relatif-pour-les-mod%C3%A8les",
  "label": "Chemin de fichier local relatif pour les modèles",
  "parent": "conditions-globales-en-mati%C3%A8re-de-th%C3%A8me"
}, {
  "depth": 0,
  "id": "performances-du-th%C3%A8me",
  "label": "Performances du thème",
  "parent": null
}, {
  "depth": 1,
  "id": "pr%C3%A9visualiser-les-url-des-th%C3%A8mes",
  "label": "Prévisualiser les URL des thèmes",
  "parent": "performances-du-th%C3%A8me"
}, {
  "depth": 0,
  "id": "utilisant-jquery",
  "label": "Utilisant jQuery",
  "parent": null
}, {
  "depth": 0,
  "id": "configuration-du-th%C3%A8me-theme.json",
  "label": "Configuration du thème (Theme.json)",
  "parent": null
}, {
  "depth": 0,
  "id": "param%C3%A8tres-du-th%C3%A8me-fields.json",
  "label": "Paramètres du thème (Fields.json)",
  "parent": null
}, {
  "depth": 0,
  "id": "mod%C3%A8les-css%2C-sections%2C-mod%C3%A8les-de-page%2C-etc.",
  "label": "Modèles (CSS, Sections, Modèles de page, etc.)",
  "parent": null
}, {
  "depth": 1,
  "id": "sections",
  "label": "Sections",
  "parent": "mod%C3%A8les-css%2C-sections%2C-mod%C3%A8les-de-page%2C-etc."
}, {
  "depth": 1,
  "id": "mod%C3%A8les-de-page",
  "label": "Modèles de page",
  "parent": "mod%C3%A8les-css%2C-sections%2C-mod%C3%A8les-de-page%2C-etc."
}, {
  "depth": 1,
  "id": "nom-du-mod%C3%A8le-de-page",
  "label": "Nom du modèle de page",
  "parent": "mod%C3%A8les-css%2C-sections%2C-mod%C3%A8les-de-page%2C-etc."
}, {
  "depth": 0,
  "id": "modules",
  "label": "Modules",
  "parent": null
}, {
  "depth": 0,
  "id": "contenu-global",
  "label": "Contenu global",
  "parent": null
}, {
  "depth": 1,
  "id": "partials-globaux",
  "label": "Partials globaux",
  "parent": "contenu-global"
}, {
  "depth": 0,
  "id": "prise-en-charge-multilingue",
  "label": "Prise en charge multilingue",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%A9l%C3%A9ments-mobiles-et-r%C3%A9actifs",
  "label": "Éléments mobiles et réactifs",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      Tabs,
      Tab,
      IFrame
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!IFrame) _missingMdxReference("IFrame", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Exigences relatives aux thèmes du marketplace des modèles HubSpot"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Découvrez-en davantage sur les exigences qu'un thème doit remplir pour être soumis dans le marketplace des modèles HubSpot."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si vous débutez dans la création de thèmes, nous vous recommandons vivement d'utiliser notre boilerplate de thème CMS gratuit, qui peut être téléchargé à partir de notre ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "référentiel Git"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.youtube.com/watch",
        children: "importé dans l'interface utilisateur du gestionnaire de conception de votre compte"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En outre, vérifiez les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/listing-templates",
        children: "conditions relatives aux pages de listing du modèle"
      }), " lorsque vous soumettez votre modèle au marketplace des modèles HubSpot."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limites concernant les thèmes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tenez compte des limites suivantes par thème :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Les comptes ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "CMS Hub"
            })
          }), " gratuits ne peuvent pas utiliser la recherche sur le site web, les fonctionnalités CTA, ou les vidéos natives HubSpot. Découvrez-en davantage sur ce qui est inclus dans les abonnements ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "CMS Hub"
            })
          }), " dans le ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
            children: "catalogue de produits et de services de HubSpot"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Les thèmes sont soumis aux limites suivantes :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 modèles"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 modules"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "50 sections"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Les thèmes ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "ne doivent pas"
          }), " contenir les éléments suivants :"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Modèles d'e-mails"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Fonctionnalité HubDB"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Fonctions sans serveur"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Champs d'objet CRM"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/types/drag-and-drop-templates#flexible-columns",
              children: "Colonnes flexibles dans les modèles"
            })
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Comparaison entre les modules et les sections"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Les sections sont utiles, car les créateurs de contenu ne peuvent les déposer que dans des zones de dépôt pleine largeur sur la page, ce qui vous aide en tant que développeur à garantir un excellent produit fini."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "En particulier, les sections à disposition fixe, où le créateur de contenu ne peut pas déplacer d'éléments dans la section, sont un excellent outil pour fournir une mise en forme et une mise en page créatives qui ne pourraient pas être obtenues autrement à l'aide de l'éditeur en glisser-déposer."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Les sections offrent également des avantages supplémentaires en termes de convivialité pour le créateur de contenu, car il peut sélectionner des modules individuels à l'intérieur de la section, ce qui permet à leurs formulaires de modules d'être plus courts et plus ciblés sur l'élément qu'ils modifient."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Conditions globales en matière de thème"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Tous les thèmes soumis doivent être distincts et originaux. Par exemple, le même thème avec un contenu de copie ou de variable différent n'est pas comptabilisé comme un thème distinct. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/marketplace/template-policies#template-marketplace-compliance",
          children: "En savoir plus sur la conformité du marketplace des modèles HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Un thème doit être conçu avec des modèles HTML et HubL et des balises ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/hubl/tags/dnd-areas",
          children: "dnd_area"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les thèmes doivent respecter une grille de 12 colonnes."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Structure des fichiers du thème"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Tous les thèmes doivent contenir une structure de dossier appropriée et être regroupées sous un dossier « parent », qui doit décrire le listing de vos produits. Par exemple, si vous créez un thème nommé « SuperGénial » pour le marketplace, votre structure doit ressembler à l'image ci-dessous. Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-file-structure",
        children: "structure des fichiers de thème"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/template-name.png",
          alt: "template-name"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.img, {
          src: "https://knowledge.hubspot.com/hubfs/Knowledge_Base_2023_2024/file-structure.png",
          alt: "file-structure"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Chemin de fichier local relatif pour les modèles"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lorsque vous faites référence à des ressources du thème, vous ", (0, _jsxRuntime.jsx)("u", {
        children: "devez"
      }), " utiliser un chemin de fichier local relatif. La meilleure façon de les inclure est d'utiliser la fonction ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#get-asset-url",
        children: "get_asset_url "
      }), ", qui renvoie l'URL publique d'une ressource, d'un fichier ou d'un modèle. Vous pouvez également ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#get-asset-url",
        children: "générer cette fonction"
      }), " soit en faisant un clic droit sur un ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "fichier"
      }), " et en sélectionnant ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copier l'URL publique"
      }), ", soit en cliquant sur ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Actions"
      }), ", puis en sélectionnant ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Copier l'URL publique"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Par exemple, une feuille de style référencée par ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "require_css"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "get_asset_url"
      }), " doit être formatée comme suit :"]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "Entrée",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{{require_css(get_asset_url('../../css/main.css')) }}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Résultat",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "//cdn2.hubspot.net/hub/1234567/hub_generated/template_assets/1565970767575/custom/styles/style.min.css\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dans la vidéo ci-dessous, examinez les différences dans la structure des fichiers de votre compte développeur par rapport aux fichiers livrés à un client de marketplace :"
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/SSOdhqOciuk?feature=oembed",
      maxHeight: "144.64px",
      maxWidth: "256px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Performances du thème"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un thème doit obtenir un score ", (0, _jsxRuntime.jsx)("u", {
        children: "supérieur"
      }), " aux seuils suivants via Google Lighthouse :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Accessibilité sur ordinateur :"
        }), " 65"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bonnes pratiques sur ordinateur :"
        }), " 80"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Performances sur ordinateur :"
        }), " 70"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Performances sur mobile :"
        }), " 40"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#evaluate-themes-and-templates-for-seo-and-accessibility",
        children: "générer un rapport Google Lighthouse pour votre thème à l'aide de l'LC"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les fichiers de thème doivent pouvoir être minifiés."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "La taille des fichiers d'image ne doit pas dépasser 1 Mo."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Toutes les balises d'image doivent avoir un attribut ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "alt"
        }), " (la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"\""
        }), " est acceptable)."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Toutes les balises d'image doivent avoir un attribut ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "loading"
        }), " (la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"\""
        }), " est acceptable)."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Prévisualiser les URL des thèmes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors de la création d'une URL de prévisualisation, vous devez utiliser votre propre nom de domaine. Vous ne pouvez pas utiliser le domaine fourni par HubSpot avec cette structure d'URL : ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "[AccountID].hs-sites.com"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Un site Web en ligne, et non une image du site de démonstration, doit être utilisé."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si, à un moment donné, votre démo en direct devient inaccessible, HubSpot se réserve le droit, moyennant notification au fournisseur, de supprimer votre thème jusqu'à ce que la démo en direct redevienne accessible."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Utilisant jQuery"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["jQuery ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/website-pages/include-jquery-across-your-hubspot-pages",
        children: "n'est pas activé par défaut"
      }), " dans le compte HubSpot d'un client. Si votre thème repose sur jQuery, une version de jQuery doit être incluse pour s'assurer que le thème fonctionne comme prévu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Par exemple, si vous incluez un module qui nécessite jQuery alors que le reste du site ne le nécessite pas, vous devrez utiliser le code suivant pour charger jQuery :"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# this checks if the \"Include jQuery\" option in Settings > CMS > Pages is checked #}\n{% if not site_settings.include_jquery %}\n  {{ require_js(\"../jquery-3.4.1.js\", \"footer\") }}\n{% endif %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configuration du thème (Theme.json)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " doit inclure les paramètres suivants :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// theme.json\n{\n  \"label\": \"Cool Theme\",\n  \"preview_path\": \"./templates/home-page.html\",\n  \"screenshot_path\": \"./images/templates/homepage.jpg\",\n  \"enable_domain_stylesheets\": false,\n  \"version\": \"1.0\",\n  \"author\": {\n    \"name\": \"Jon McLaren\",\n    \"email\": \"noreply@hubspot.com\",\n    \"url\": \"https://theme-provider.com/\"\n  },\n  \"documentation_url\": \"https://theme-provider.com/cool-theme/documentation\",\n  \"license\": \"./license.txt\",\n  \"example_url\": \"https://theme-provider.com/cool-theme/demo\",\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Veuillez vérifier votre fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " et vous assurer de ce qui suit :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le nom du libellé correspond au nom de votre listing de thèmes."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si vous utilisez le CMS theme boilerplate gratuit de HubSpot, les valeurs du boilerplate ne doivent pas être présentes. Cela inclut les informations sur l'auteur, l'URL de la documentation, l'URL de l'exemple, etc."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "L'URL de la documentation résout et contient de la documentation sur l'utilisation de votre thème."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le chemin d'aperçu est un fichier valide dans votre thème."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le chemin de capture d'écran est un fichier valide et est lié à votre thème."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["L'exemple d'URL résout et conduit à une démonstration de votre thème. N'utilisez pas les sous-domaines ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "preview.hs-sites.com"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "[AccountID].hs-sites.com"
        }), " pour l'exemple d'URL."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En savoir plus sur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#theme-json",
        children: "les paramètres theme.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Paramètres du thème (Fields.json)"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " contrôle les champs et groupes de champs disponibles dans l'éditeur de thème, y compris les champs de style. Les champs que vous incluez dépendront du degré de contrôle que vous souhaitez donner aux créateurs de contenu dans l'éditeur de page."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fields.json"
        }), " doit contenir au moins trois champs de couleur."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour garantir la compatibilité entre des thèmes et des modules indépendants, les thèmes doivent inclure les conventions d'appellation de police et de couleur standards suivantes : ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "primary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "secondary_color"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "heading_font"
        }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "body_font"
        }), ". Découvrez-en davantage sur la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/beta-docs/guides/cms/marketplace/template-policies#module-compatibility-with-themes",
          children: "compatibilité module-thème"
        })]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Si les champs de thème ne disposent pas de champs ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "primary_color"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "secondary_color"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "heading_font"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "body_font"
      }), ", ils peuvent utiliser le champ ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "alternate_names"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Découvrez-en davantage sur ces ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#fields-json",
        children: "paramètres fields.json"
      }), " et ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate/blob/main/src/fields.json",
        children: "consultez un exemple de fichier fields.json"
      }), " à partir du boilerplate de CMS Hub."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les paramètres du thème doivent également :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ne pas entrer en conflit avec des styles d'éditeur ou des styles définis via un module. Par exemple, n'utilisez pas ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "!important"
        }), " dans votre feuille de style CSS, car il est difficile pour les utilisateurs finaux de le remplacer et cela provoquerait un conflit."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utilisez des libellés descriptifs pour chaque paramètre afin que les créateurs de contenu sachent ce qu'ils mettent à jour."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Appliquez à tous les modèles à l'intérieur d'un thème, sauf s'il existe un cas d'utilisation spécifique pour les styles supplémentaires. Par exemple, les modifications apportées au style et à la taille des en-têtes ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " dans les paramètres du thème doivent s'appliquer à toutes les balises ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " du thème."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un thème ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "doit"
      }), " inclure au minimum les champs de thème suivants :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Champs de typographie :"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Champ de police du corps du texte (balises ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), ")"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["champs de police ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h1"
        }), " à ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "h6"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Couleur du lien hypertexte (balises ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "a"
        }), "), y compris le style au survol"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Champs de formulaire :"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Arrière-plan du titre du formulaire"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Texte du titre du formulaire, y compris au moins les styles de couleur de police"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Couleur de l'arrière-plan du formulaire"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Couleur de la bordure du formulaire"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Couleur de libellé des formulaires"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Couleur de la bordure des champs de formulaire"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Bouton de formulaire - , y compris les paramètres pour le texte du bouton, la couleur d'arrière-plan et le style au survol."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "En outre :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les champs de votre thème doivent être regroupés de manière logique. Par exemple, plusieurs champs liés à la typographie doivent être réunis sous un groupe ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "Typography"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les champs de thème doivent avoir des contrôles de couleur et de police distincts pour les boutons et les formulaires, ainsi que des contrôles de couleur, de logo et de police distincts pour l'en-tête et le pied de page."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Une partie des champs de couleur et de logo du thème doit hériter des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/fields/brand-and-settings-inheritance",
          children: "paramètres de marque"
        }), " du compte :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Au minimum, deux champs de couleur doivent hériter des couleurs des paramètres de marque du compte. Les champs de couleur supplémentaires peuvent être remplacés par défaut par d'autres couleurs, y compris le noir et le blanc."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Si les modules d'un thème utilisent des logos, au moins un champ de logo doit hériter des paramètres de marque du compte. Si vous utilisez un champ d'image pour afficher un logo, le champ d'image n'a pas à hériter des paramètres de marque."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Comment les couleurs de la marque influencent-elles l'esthétique de votre thème"
      })
    }), "\n", (0, _jsxRuntime.jsx)(IFrame, {
      src: "https://www.youtube.com/embed/voyJupcZYeQ?feature=oembed",
      maxHeight: "144.64px",
      maxWidth: "256px",
      allowFullScreen: false
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modèles (CSS, Sections, Modèles de page, etc.)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Sections"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous devez utiliser des ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/sections#reusable-sections",
          children: "sections"
        }), ", le cas échéant. Un thème ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "doit"
        }), " inclure au minimum cinq sections."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les sections doivent avoir des captures d'écran uniques et fonctionnelles."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Les sections et les modules ne doivent pas être redondants."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Modèles de page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Un thème ", (0, _jsxRuntime.jsx)("u", {
        children: "doit"
      }), " inclure au minimum les types de modèle suivants :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Un modèle de page de site web ou de page de destination."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Lorsque vous incluez des modèles à plusieurs pages, chaque modèle doit avoir un objectif distinct. Par exemple, une page d'accueil, une page ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "À propos de nous"
            }), ", une page de destination pleine largeur et une page de destination avec une barre latérale droite."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Il est recommandé d'inclure au moins huit modèles de page dans un thème."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Des modèles de listing de blog et d'article de blog distincts."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modèle de listing de blog :"
            }), " la page qui affiche tous les articles de blog dans un format de listing (appelé blogroll). Le titre du modèle doit indiquer qu'il s'agit de la page de listing."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modèle d'article de blog :"
            }), " la page de détail d'articles de blog qui affiche les différents articles de blog. Le titre du modèle doit indiquer qu'il s'agit de la page d'article de blog."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Par ailleurs, les commentaires de blog et les cases d'auteur de blog doivent être mis en forme pour correspondre au thème."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Les modèles de page système suivants :"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modèle d'erreur 404 :"
            }), " affiché lorsque les visiteurs atteignent une page qui n'existe pas."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modèle d'erreur 500 :"
            }), " affiché lorsque le site rencontre une erreur interne."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modèle d'invite de mot de passe :"
            }), " affiché lorsqu'une page est protégée par un mot de passe."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modèle des abonnements :"
            }), " page de gestion des inscriptions permettant aux destinataires d'e-mails de gérer les types d'e-mails auxquels ils s'abonnent."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modèle de mise à jour des abonnements"
            }), " : la page de confirmation qui apparaît lorsque le destinataire des e-mails met à jour ses préférences d'abonnement."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modèle d'enregistrement des désabonnements"
            }), " : la page qui apparaîtra pour les destinataires des e-mails qui essayent de se désabonner, si HubSpot ne peut déterminer leur adresse e-mail."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Modèle de résultats de recherche"
            }), " : affiche les résultats de recherche qui sont retournés lors de l'utilisation de la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/content-search",
              children: "recherche du site"
            }), ". Disponible pour les comptes ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "CMS Hub"
              })
            }), " payants uniquement."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Nom du modèle de page"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si vous avez des modèles avec des noms similaires, ajoutez des mots descriptifs qui indiquent la différence entre eux."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Maintenez la cohérence de la majuscule, supprimez les traits d'union et évitez d'utiliser des raccourcis (par exemple, utilisez arrière-plan au lieu de ap)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Le nom de votre entreprise ou le nom du thème n'a pas besoin d'être inclus dans le nom du modèle."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modules"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour en savoir plus sur les conditions relatives aux modules de thème et aux modules individuels, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/module-requirements",
        children: "cliquez ici"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contenu global"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Partials globaux"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "partials globaux"
      }), " sont un type de modèle créé à l'aide de HTML et HubL qui peut être réutilisé sur l'ensemble de votre site web. Les types de partials les plus courants sont les en-têtes de sites Web, les barres latérales et les pieds de page. Découvrez comment ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content#creating-a-global-content-partial-template-using-local-development-tools",
        children: "créer des partiels globaux"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Les thèmes ", (0, _jsxRuntime.jsx)("u", {
          children: "doivent"
        }), " inclure des partials globaux. Les - partials globaux ", (0, _jsxRuntime.jsx)("u", {
          children: "doivent"
        }), " inclure une ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/templates/drag-and-drop/overview",
          children: "zone de glisser-déposer."
        }), " Par exemple, vous ne pouvez pas masquer la zone de glisser-déposer avec une classe « masquer »."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous ", (0, _jsxRuntime.jsx)("u", {
          children: "devez"
        }), " incorporer des zones de glisser-déposer utilisables dans les en-têtes et les pieds de page."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Pour les menus utilisés globalement à travers un site, les utilisateurs doivent également pouvoir ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/menus-and-navigation",
          children: "sélectionner un menu de navigation HubSpot"
        }), " qu'ils ont créé dans les paramètres de leur compte."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Remarque"
        }), " : Évitez d'inclure des modules globaux dans les partiels globaux, car cela peut créer une expérience utilisateur négative."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Prise en charge multilingue"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les thèmes ", (0, _jsxRuntime.jsx)("u", {
        children: "doivent"
      }), " pouvoir prendre en charge plusieurs versions linguistiques et doivent spécifier les langues qu'ils prennent en charge. Pour ce faire, vous pouvez ajouter un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/default-modules#language-switcher",
        children: "module de sélecteur de langue"
      }), " dans un en-tête global, ce qui permet aux clients de localiser facilement les options de langue et de choisir la langue de leur choix."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Vous ne ", (0, _jsxRuntime.jsx)("u", {
          children: "devez"
        }), " afficher qu'une seule langue à la fois. Par exemple, évitez d'avoir à la fois l'anglais et l'espagnol dans l'interface utilisateur en même temps."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Évitez d'utiliser du texte codé en dur. Par exemple, plutôt que de coder le texte d'un bouton de listing de blog comme ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "En savoir plus"
        }), ", définissez le texte dans un champ afin que l'utilisateur final puisse mettre à jour le texte sans avoir à entrer dans le code."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Éléments mobiles et réactifs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Les thèmes doivent être capables d'adapter leur contenu à l'appareil sur lequel il est consulté. Ils doivent également offrir une bonne expérience utilisateur sur différents appareils. Cela comprend, mais n'est pas limité :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Menu de navigation principal"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Curseurs et onglets"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Grandes images"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Éviter le défilement horizontal (sauf intention)"
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}