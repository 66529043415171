"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 42297043873;
const slug = exports.slug = 'guides/api/marketing/emails/transactional-emails';
const title = exports.title = 'マーケティングAPI | トランザクションEメール ';
const name = exports.name = 'APAC JAPAN (JA) | vNext Docs DP - Transactional Emails_JA | 202104';
const metaDescription = exports.metaDescription = 'HubSpotのトランザクションEメール機能の概要。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%83%88%E3%83%A9%E3%83%B3%E3%82%B6%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3e%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%AE%E9%80%81%E4%BF%A1%E6%96%B9%E6%B3%95",
  "label": "トランザクションEメールの送信方法",
  "parent": null
}, {
  "depth": 0,
  "id": "smtp-api",
  "label": "SMTP API",
  "parent": null
}, {
  "depth": 1,
  "id": "%E6%96%B0%E3%81%97%E3%81%84smtp-api%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E4%BD%9C%E6%88%90",
  "label": "新しいSMTP APIトークンの作成",
  "parent": "smtp-api"
}, {
  "depth": 1,
  "id": "smtp%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "SMTPトークンを取得する",
  "parent": "smtp-api"
}, {
  "depth": 2,
  "id": "%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3%E3%81%94%E3%81%A8%E3%81%ABsmtp-api%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E3%83%AA%E3%82%B9%E3%83%88%E3%82%92%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B",
  "label": "キャンペーンごとにSMTP APIトークンのリストを取得する",
  "parent": "smtp-api"
}, {
  "depth": 2,
  "id": "%E5%8D%98%E4%B8%80%E3%81%AEsmtp-api%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AE%E7%85%A7%E4%BC%9A",
  "label": "単一のSMTP APIトークンの照会",
  "parent": "smtp-api"
}, {
  "depth": 3,
  "id": "%E3%83%AC%E3%82%B9%E3%83%9D%E3%83%B3%E3%82%B9%E3%81%AE%E8%A9%B3%E7%B4%B0",
  "label": "レスポンスの詳細",
  "parent": "smtp-api"
}, {
  "depth": 1,
  "id": "%E6%97%A2%E5%AD%98%E3%81%AE%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%82%92%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B",
  "label": "既存のトークンを管理する",
  "parent": "smtp-api"
}, {
  "depth": 2,
  "id": "%E3%83%91%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E3%82%92%E3%83%AA%E3%82%BB%E3%83%83%E3%83%88%E3%81%99%E3%82%8B",
  "label": "パスワードをリセットする",
  "parent": "smtp-api"
}, {
  "depth": 2,
  "id": "%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B",
  "label": "トークンを削除する",
  "parent": "smtp-api"
}, {
  "depth": 1,
  "id": "hubspot%E3%81%AEsmtp%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%81%AB%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E3%81%99%E3%82%8B",
  "label": "HubSpotのSMTPサーバーにログインする",
  "parent": "smtp-api"
}, {
  "depth": 0,
  "id": "1%E5%9B%9E%E9%80%81%E4%BF%A1api",
  "label": "1回送信API",
  "parent": null
}, {
  "depth": 1,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88%E3%82%92%E4%BD%9C%E6%88%90%E3%81%97%E3%81%A6%E5%85%AC%E9%96%8B%E3%81%99%E3%82%8B",
  "label": "Eメールテンプレートを作成して公開する",
  "parent": "1%E5%9B%9E%E9%80%81%E4%BF%A1api"
}, {
  "depth": 1,
  "id": "1%E5%9B%9E%E9%80%81%E4%BF%A1api%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6e%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%92%E9%80%81%E4%BF%A1%E3%81%99%E3%82%8B",
  "label": "1回送信APIを使用してEメールを送信する",
  "parent": "1%E5%9B%9E%E9%80%81%E4%BF%A1api"
}, {
  "depth": 1,
  "id": "%E3%83%AA%E3%82%AF%E3%82%A8%E3%82%B9%E3%83%88%E3%81%AE%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3%E3%83%BC",
  "label": "リクエストのプロパティー",
  "parent": "1%E5%9B%9E%E9%80%81%E4%BF%A1api"
}, {
  "depth": 2,
  "id": "emailid",
  "label": "emailId",
  "parent": "1%E5%9B%9E%E9%80%81%E4%BF%A1api"
}, {
  "depth": 2,
  "id": "%E3%83%A1%E3%83%83%E3%82%BB%E3%83%BC%E3%82%B8",
  "label": "メッセージ",
  "parent": "1%E5%9B%9E%E9%80%81%E4%BF%A1api"
}, {
  "depth": 2,
  "id": "contactproperties",
  "label": "contactProperties",
  "parent": "1%E5%9B%9E%E9%80%81%E4%BF%A1api"
}, {
  "depth": 2,
  "id": "customproperties",
  "label": "customProperties",
  "parent": "1%E5%9B%9E%E9%80%81%E4%BF%A1api"
}, {
  "depth": 1,
  "id": "e%E3%83%A1%E3%83%BC%E3%83%AB%E9%80%81%E4%BF%A1%E3%81%AE%E3%82%B9%E3%83%86%E3%83%BC%E3%82%BF%E3%82%B9%E3%82%92%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E3%82%8B",
  "label": "Eメール送信のステータスを問い合わせる",
  "parent": "1%E5%9B%9E%E9%80%81%E4%BF%A1api"
}, {
  "depth": 2,
  "id": "sendresult",
  "label": "sendResult",
  "parent": "1%E5%9B%9E%E9%80%81%E4%BF%A1api"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      strong: "strong",
      tbody: "tbody",
      td: "td",
      h3: "h3",
      code: "code",
      h4: "h4",
      h5: "h5",
      img: "img",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "トランザクションEメール"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/products/email/transactional-email",
        children: "トランザクションEメール追加オプション"
      }), "（英語）を利用している場合は、領収書、アカウントの更新、利用規約の変更、その他の重要なビジネス取引についてのEメールを専用IPアドレス経由で送信できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["トランザクションEメールは、主にコンテンツのプロモーションに使用される", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/emails/marketing-emails",
        children: "マーケティングEメール"
      }), "（英語）と異なり、関係性に基づいたコミュニケーションに使用されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "トランザクションEメールの送信方法"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "トランザクションEメールの実装には、以下の3つの方法があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
          children: "アプリ内でトランザクションEメールを設定する"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#smtp-api",
          children: "SMTP API"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#single-send-api",
          children: "1回送信API"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "方法"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "概要"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "使用例"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "アプリ内トランザクションEメール"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["HubSpotのEメールエディターを使用して、トランザクションEメールを作成します。スマートコンテンツ、パーソナライズ、テンプレートなど、標準のHubSpot Eメールと同様のメリットがあります。", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
              children: "アプリ内でトランザクションEメールを設定する方法"
            }), "をご確認ください。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "新しいポリシーのページへのリンクを記載した、ポリシーの更新に関するEメールを顧客に送信する。これはサービスに関する更新情報であってマーケティングEメールではないので、配信設定関連のリンク（CAN-SPAMリンクなど）を含める必要はありません。カスタムプロパティーや外部システムに含まれる情報を使用する必要もありません。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "SMTP API"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["独自のサイトまたはアプリからトランザクションEメールを送信して、Eメールのパフォーマンスを追跡したり、HubSpot内にコンタクト情報を作成したりします。コンタクト情報を作成できるかどうかは、SMTPトークンの作成に基づいています。詳細については", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#smtp-api",
              children: "後述のSMTP APIセクション"
            }), "をご確認ください。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "別のトランザクションEメールシステムからアカウント登録の確認Eメールを送信した上で、Eメールのパフォーマンスを追跡し、HubSpot内にコンタクトを作成する。"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "1回送信API"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["アプリ内トランザクションEメールとSMTP APIの組み合わせです。HubSpotのEメールエディターを使用してトランザクションEメールを作成し、API経由でHubSpotに送信できるカスタム外部トークンをEメールに追加します。詳細については", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#single-send",
              children: "後述の1回送信APIセクション"
            }), "をご確認ください。"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "HubSpotを使用して、領収書Eメールを顧客に送信する。このEメールは購入が行われるとトリガーされ、別のシステムからのカスタム値（購入品目、合計購入金額など）を渡します。さらに、このEメールのパフォーマンスをHubSpotで追跡します。"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**トランザクションEメールにCCされているコンタクトは追跡されず、そのEメールはCCされたコンタクトのレコードタイムラインには表示されません。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "SMTP API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["SMTP APIを使用して送信するトランザクションEメールは、特定の条件（eコマースウェブサイトでの購入の実行など）に基づいて自動的にトリガーされます。このAPIはあらゆる内部システムまたはサードパーティーシステムとの連携に利用可能で、Eメールのトリガーと、HubSpotの外部に保存されているデータ（配送情報、購入価格など）の取り込みに使用できます。Eメールの送信は貴社のシステムから行われますが、HubSpotトラッキングコードでラッピングされるため、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/analyze-your-marketing-email-campaign-performance",
        children: "エンゲージメントの追跡と測定"
      }), "が可能です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["SMTP API経由でEメールを送信するには、SMTP APIトークンを使用してHubSpot SMTPサーバーのログイン資格情報を取得する必要があります。サーバーにログインすると、SMTP経由でEメールを送信できます。SMTP APIトークンをまだ作成していない場合は、最初に", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#create-a-new-smtp-api-token",
        children: "新しいトークンを生成する"
      }), "必要があります。SMTP APIトークンを作成済みの場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#get-existing-smtp-api-tokens",
        children: "API経由でトークンを取得する方法"
      }), "をご確認ください。トークンを取得したら、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-in",
        children: "HubSpotのSMTPサーバーにログインする方法"
      }), "をご確認ください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメールの送信元アドレスとして使用するドメインは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/domains-and-urls/connect-your-email-sending-domain",
        children: "Eメール送信ドメイン"
      }), "としてHubSpotに接続されている必要があります。SMTP API経由でトランザクションEメールを送信する場合に、貴社のHubSpotアカウントに代わって送信できる権限のないドメインを使用すると、エラーが発生します。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**SMTP APIの全てのメソッドに、認証用の", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/oauth-quickstart-guide",
          children: "OAuthトークン"
        }), "が必要です。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "tip",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["必要に応じて、SMTP APIトークンのパスワードを作成、取得、削除、およびリセットするための以下の全ての方法は、APIではなく", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot#send-a-transactional-email-using-the-smtp-api",
          children: "HubSpotアカウント内"
        }), "で行うことができます。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "新しいSMTP APIトークンの作成"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["新しいSMTP APIトークンを作成するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエストの本文には、以下のプロパティーから成るJSON形式のオブジェクトが必要です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), "：Eメールの受信者に対応するコンタクトを作成するかどうかを示します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), "：SMTP APIトークンに関連付けるキャンペーンの名前。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "レスポンスには、以下の要素から成るSMTPトークンオブジェクトが含まれます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), "：HubSpot SMTPサーバーにログインするためのユーザー名。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), "：トークン作成リクエストを送信したユーザーのEメールアドレス。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "password"
        }), "：HubSpot SMTPサーバーにログインするためのパスワード。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), "：キャンペーンに割り当てられたID（トークン作成リクエスト内で提供）。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), "：トークン作成時に生成されたタイムスタンプ。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), "：Eメールの受信者に対応するコンタクトを作成するかどうかを示します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), "：トークンに関連付けられたキャンペーンの名前。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["トークンが作成されたら、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "password"
      }), "の値を使用して、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#log-in",
        children: "HubSpotのSMTPサーバーにログイン"
      }), "できます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["トークンのパスワードは、作成時にのみ取得できます。パスワードを忘れた場合、または新しいパスワードを設定する場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#manage-existing-tokens",
        children: "トークンのパスワードをリセットする"
      }), "必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["**注：**公開APIで生成したSMTP APIトークンは、12か月が経過すると有効期限が切れます。有効期限が切れると、自動的に削除されます。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot#send-a-transactional-email-using-the-smtp-api",
          children: "HubSpotアカウント内"
        }), "で直接作成されたトークンは、自動的には失効", (0, _jsxRuntime.jsx)("u", {
          children: "しません"
        }), "。"]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "SMTPトークンを取得する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "API経由でトークンデータを取得する方法を以下に示します。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "キャンペーンごとにSMTP APIトークンのリストを取得する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["キャンペーン名に基づいてトークンのリストを取得するか、キャンペーンIDに基づいて1つのトークンを取得するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "campaignName"
      }), "または", (0, _jsxRuntime.jsx)(_components.code, {
        children: "emailCampaignId"
      }), "のパラメーターもリクエストに含める必要があります。リクエストの詳細については、この記事の上部の［エンドポイント］タブでご確認いただけます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "レスポンスの詳細"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レスポンスには、最上位のフィールドとして", (0, _jsxRuntime.jsx)(_components.code, {
        children: "results"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "paging"
      }), "が含まれます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "results"
        }), "：以下の要素を含む", (0, _jsxRuntime.jsx)(_components.code, {
          children: "SmtpApiTokenView"
        }), "のコレクション。", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), "：HubSpot SMTPサーバーにログインするためのユーザー名。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdBy"
            }), "：トークン作成リクエストを送信したユーザーのEメールアドレス。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "emailCampaignId"
            }), "：キャンペーンに割り当てられたID（トークン作成リクエスト内で提供）。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createdAt"
            }), "：トークン作成時に生成されたタイムスタンプ。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "createContact"
            }), "：Eメールの受信者に対応するコンタクトを作成するかどうかを示します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "campaignName"
            }), "：トークンに関連付けられたキャンペーンの名前。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "paging"
        }), "：さらに結果をリクエストするための", (0, _jsxRuntime.jsx)(_components.code, {
          children: "next.after"
        }), "フィールドが含まれます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "単一のSMTP APIトークンの照会"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["IDに基づいて単一のSMTP APIトークンを照会するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "レスポンスの詳細"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レスポンスには、以下の要素から成る", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SmtpApiTokenView"
      }), "が含まれます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), "：HubSpot SMTPサーバーにログインするためのユーザー名。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), "：トークン作成リクエストを送信したユーザーのEメールアドレス。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), "：キャンペーンに割り当てられたID（トークン作成リクエスト内で提供）。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), "：トークン作成時に生成されたタイムスタンプ。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), "：Eメールの受信者に対応するコンタクトを作成するかどうかを示します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), "：トークンに関連付けられたキャンペーンの名前。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "既存のトークンを管理する"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "トークンの作成後に、API経由でパスワードをリセットしたり、トークンを削除したりすることができます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "パスワードをリセットする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["トークンのパスワードをリセットするには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}/password-reset"
      }), "にPOSTリクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["レスポンスには、以下の要素から成る", (0, _jsxRuntime.jsx)(_components.code, {
        children: "SmtpApiTokenView"
      }), "が含まれます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "id"
        }), "：HubSpot SMTPサーバーにログインするためのユーザー名。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdBy"
        }), "：トークン作成リクエストを送信したユーザーのEメールアドレス。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "emailCampaignId"
        }), "：キャンペーンに割り当てられたID（トークン作成リクエスト内で提供）。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createdAt"
        }), "：トークン作成時に生成されたタイムスタンプ。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "createContact"
        }), "：Eメールの受信者に対応するコンタクトを作成するかどうかを示します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "campaignName"
        }), "：トークンに関連付けられたキャンペーンの名前。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "トークンを削除する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["単一のSMTP APIトークンを削除するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/smtp-tokens/{tokenId}"
      }), "にDELETEリクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "レスポンスに含まれるコンテンツはありません。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubSpotのSMTPサーバーにログインする"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["トークンのユーザー名（", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), "）とパスワードを使用してHubSpotのSMTPサーバーにログインする方法を以下に示します。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "SMTPホスト名"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["EUを拠点にしていない場合は、ホスト名として", (0, _jsxRuntime.jsx)(_components.code, {
              children: "smtp.hubapi.com"
            }), "を使用します。"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["EUを拠点にしている場合は、ホスト名として", (0, _jsxRuntime.jsx)(_components.code, {
              children: "smtp-eu1.hubapi.com"
            }), "を使用します。"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "SMTPポート"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "STARTTLSの場合は、ポート25または587を使用できます。"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "直接TLSの場合は、ポート465を使用します。"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**SMTPユーザー名：**idフィールドに提供されます。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "**SMTPパスワード：**passwordフィールドに提供されます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1回送信API"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1回送信APIでは、JSON形式の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを使用して、HubSpotのEメールツールで作成したテンプレートEメールを送信できます。このAPI経由で送信するEメールは、Eメールアドレスに基づいて自動的にコンタクトレコードに関連付けられます。Eメールアドレスが一致するコンタクトがない場合、そのEメールアドレスのコンタクトが新たに作成されます。Eメールを送信する際にコンタクトを作成しない場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#SMTP-API",
        children: "SMTP API"
      }), "を使用してください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eメールテンプレートを作成して公開する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1回送信APIでは、JSON形式の", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを使用して、HubSpotのEメールツールで作成したテンプレートEメールを送信できます。このAPI経由で送信するEメールは、Eメールアドレスに基づいて自動的にコンタクトレコードに関連付けられます。Eメールアドレスが一致するコンタクトがない場合、そのEメールアドレスのコンタクトが新たに作成されます。Eメールを送信する際にコンタクトを作成しない場合は、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#SMTP-API",
        children: "SMTP API"
      }), "を使用してください。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["まず、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/how-to-use-transactional-email-in-hubspot",
        children: "HubSpot上でEメールを設定"
      }), "します。Eメールが作成されたら、APIリクエストの本文で受信者の詳細（Eメールテンプレートに設定済みのコンタクトやカスタムプロパティーを含む）を設定できます。APIリクエストを行う前に、EメールのIDが必要です。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Eメールを公開せずに下書きしたままにした場合、Eメールエディターを使用しているときにURLからEメールIDを取得できます。IDは、URLの最後のスラッシュ文字（", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/"
        }), "）の前の最後の数字です（例：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "https://app.hubspot.com/email/{PORTAL_ID}/edit/{EMAIL_ID}/settings"
        }), "）。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/email-id-for-drafted-single-send-api-email.png",
        alt: "email-id-for-drafted-single-send-api-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Eメールを公開する場合は、Eメールの詳細ページからEメールIDをコピーできます。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/Screen%20Shot%202020-04-15%20at%201.00.37%20PM.png",
        alt: "Screen Shot 2020-04-15 at 1.00.37 PM"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**HubSpotは、このAPIを介して送信されたHTML/JSONを保存しません。受信者のコンタクトのタイムラインからEメールテンプレートを確認できますが、Eメールの内容を記録したい場合は、EメールにBCCを追加することをお勧めします。"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "1回送信APIを使用してEメールを送信する"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1回送信APIを使ってEメールを送信するには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/marketing/v3/transactional/single-email/send"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "レスポンスには以下のフィールドが含まれます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), "：送信がリクエストされた時刻のタイムスタンプ。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), "：", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-the-status-of-an-email-send",
            children: "送信のステータスを問い合わせる"
          }), "際に使用できる識別子。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), "：送信リクエストのステータス。", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDING"
          }), "、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSING"
          }), "、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELED"
          }), "、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMPLETE"
          }), "があります。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "リクエストのプロパティー"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "リクエストの本文には、以下のプロパティーから成るJSON形式のオブジェクトが必要です。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "message"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "contactProperties"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "emailId"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "emailId"
      }), "フィールドには、トランザクションEメールのコンテンツIDを指定します。このIDは、HubSpotのEメールツールで確認できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "メッセージ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["messageフィールドは、明示的に変更したい項目を含むJSONオブジェクトです。少なくとも", (0, _jsxRuntime.jsx)(_components.code, {
        children: "to"
      }), "フィールドを含める必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "messageオブジェクトに含まれるフィールドは以下の通りです。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "to"
        }), "：Eメールの受信者。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "from"
        }), "：Eメールの「From」ヘッダー。送信者名は、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "\"from\":\"送信者名 <sender@hubspot.com>\""
        }), "の形式で定義できます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), "：特定の送信のID。所定の", (0, _jsxRuntime.jsx)(_components.code, {
          children: "sendId"
        }), "が割り当てられたEメールは、アカウントごとに1回だけ送信されます。このフィールドは、Eメールの重複送信を防止する目的で含めることができます。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "replyTo"
        }), "：Eメールの「Reply-To」ヘッダーの値のJSONリスト。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "cc"
        }), "：CCとして送信するEメールアドレスのJSONリスト。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "bcc"
        }), "：BCCとして送信するEメールアドレスのJSONリスト。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "contactProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "contactProperties"
      }), "フィールドは、コンタクトのプロパティー値のJSONマップです。各コンタクトのプロパティー値には、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "name"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "value"
      }), "があります。各プロパティーはコンタクトレコード上に設定され、以下のものの下にあるテンプレートに表示されます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/name-token-in-template-for-transactional-email.png",
        alt: "name-token-in-template-for-transactional-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["これらのプロパティーは、Eメールの送信時にコンタクトのプロパティーを設定する場合に使用します。例えば、領収書の送信時に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "last_paid_date"
      }), "プロパティーを設定すると、前回の支払いに関する情報を提供できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"contactProperties\": {\n    \"last_paid_date\": \"2022-03-01\",\n    \"firstname\": \"jane\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "customProperties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), "フィールドは、キーと値のプロパティーのJSONマップです。一般的に、これらのプロパティーはEメールを受信するコンタクトではなく、Eメール自体に関連しています。これらのプロパティーは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/email/create-a-web-version-of-your-marketing-email",
        children: "ウェブページ形式のEメール"
      }), "にも、コンタクトのタイムライン上のEメールのビューにも表示されません。このようなプロパティーはHubSpot上にも保存されません。送信Eメールにのみ含められます。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "CustomProperties"
      }), "フィールドの各キーは、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "custom"
      }), "変数に含まれるフィールドの", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL式"
      }), "を使用して、テンプレート内で参照できます（例：", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ custom.NAME_OF_PROPERTY }}"
      }), "）。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["例えば、Eメールテンプレートが", (0, _jsxRuntime.jsx)(_components.code, {
        children: "purchaseUrl"
      }), "と", (0, _jsxRuntime.jsx)(_components.code, {
        children: "productName"
      }), "の2つのプロパティーを参照している場合、以下のリクエスト本文によって、これらのプロパティーに関連付ける値を指定できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643121,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"6\"\n  },\n  \"customProperties\": {\n    \"purchaseUrl\": \"https://example.com/link-to-product\",\n    \"productName\": \"vanilla\"\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "次に、Eメールテンプレートでこれらのプロパティーを参照できます。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Congrats on purchasing some of the best ice cream around.\n  </p>\n  <a href={{custom.purchaseUrl}}>{{custom.productName}}</a>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/data-driven-content/emails-with-programmable-content",
        children: "プログラマブルEメールコンテンツ"
      }), "で", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), "フィールドを使用する場合、このフィールドの値としてサポートされるのは配列のみとなります。Eメールテンプレートでは", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/overview",
        children: "HubL式"
      }), "を使用して、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), "フィールドで定義されたアイテムを参照できます（たとえば、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/loops",
        children: "forループ"
      }), "を使用してリスト内の各アイテムをレンダリングします）。たとえば、要求本文に含めた", (0, _jsxRuntime.jsx)(_components.code, {
        children: "customProperties"
      }), "が以下のJSONスニペットのように構成されているとします。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "{\n  \"emailId\": 4126643122,\n  \"message\": {\n    \"to\": \"jdoe@hubspot.com\"\n    \"sendId\": \"7\"\n  },\n  \"customProperties\": {\n    \"exampleArray\": [\n      {\"firstKey\": \"someValue\", \"secondKey\": \"anotherValue\"},\n      {\"firstKey\": \"value1\", \"secondKey\": \"value2\" }\n    ]\n  }\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["この場合、以下のHubLコードを使用して", (0, _jsxRuntime.jsx)(_components.code, {
        children: "exampleArray"
      }), "の各項目の値を参照できます。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "<!doctype html>\n<html>\n  <p>\n    Thanks for your recent purchase! Here are the details of the items you'll be receiving:\n  </p>\n  <ul>\n    {% for item in custom.exampleArray %}\n      <li>First key: {{ item.firstKey }}, Second key: {{item.secondKey}}</li>\n    {% endfor %}\n  </ul>\n</html>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "送信されると、以下のように、トランザクションEメールは、関連付けられたプログラム可能なEメールテンプレートの内容を次のようにレンダリングします。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/example-transactional-email-with-customProperties-array.png",
        alt: "example-transactional-email-with-customProperties-array"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Eメール送信のステータスを問い合わせる"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eメール送信のステータスを調べるには、", (0, _jsxRuntime.jsx)(_components.code, {
        children: "https://api.hubapi.com/marketing/v3/email/send-statuses/{statusId}"
      }), "に", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), "リクエストを送信します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "レスポンスには以下のフィールドが含まれます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "sendResult"
          }), "：Eメールの送信ステータスを表す列挙フィールド。値については", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#sendresult-values",
            children: "後述"
          }), "します。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "requestedAt"
          }), "：送信がリクエストされた時刻のタイムスタンプ。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "startedAt"
          }), "：送信の処理が開始された時刻のタイムスタンプ。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "completedAt"
          }), "：送信が完了した時刻のタイムスタンプ。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "statusId"
          }), "：送信のステータスを問い合わせる際に使用できる識別子。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "status"
          }), "：送信リクエストのステータス。", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PENDING"
          }), "、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "PROCESSING"
          }), "、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "CANCELED"
          }), "、", (0, _jsxRuntime.jsx)(_components.code, {
            children: "COMPLETE"
          }), "があります。"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.code, {
            children: "eventId"
          }), "：送信された場合、送信イベントのIDと送信時に生成されたタイムスタンプ。"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "sendResult"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.code, {
        children: "sendResult"
      }), "は、Eメールの送信試行の結果を反映する列挙フィールドです。使用される値は以下の通りです。"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "SENT"
        }), "：Eメールが正常に送信されました。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "QUEUED"
        }), "：Eメールがキューに追加され、キューによる送信処理を待機中です。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PORTAL_SUSPENDED"
        }), "：", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/acceptable-use",
          children: "利用規定"
        }), "の違反により、このHubSpot利用企業のEメールが停止されています。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_TO_ADDRESS"
        }), "：受信者のアドレスが無効です。このエラーは、メールアドレスに次の役割ベースの先頭文字のいずれかを含むメールを送信しようとすると発生します。", (0, _jsxRuntime.jsx)(_components.code, {
          children: "abuse"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "no-reply"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "noreply"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "root"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "spam"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "security"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undisclosed-recipients"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "unsubscribe"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inoc"
        }), "、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "postmaster"
        }), "、または", (0, _jsxRuntime.jsx)(_components.code, {
          children: "privacy"
        }), "。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOCKED_DOMAIN"
        }), "：現時点では、ドメインがHubSpotからのEメールを受信できません。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUSLY_BOUNCED"
        }), "：以前この受信者にバウンスが発生したため、送信ロジックに基づき送信が行われませんでした。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "PREVIOUS_SPAM"
        }), "：この受信者は以前に同様のEメールをスパム扱いにしました。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "INVALID_FROM_ADDRESS"
        }), "：送信元アドレスが無効です。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_CONTENT"
        }), "：", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), "が無効か、", (0, _jsxRuntime.jsx)(_components.code, {
          children: "emailId"
        }), "が1回送信用として設定されていないEメールと一致します。"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "MISSING_TEMPLATE_PROPERTIES"
        }), "：リクエストとして送信した", (0, _jsxRuntime.jsx)(_components.code, {
          children: "customProperties"
        }), "に含まれていないプロパティーが、テンプレートに設定されています。"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}