"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492335030;
const slug = exports.slug = 'guides/cms/setup/creating-an-efficient-development-workflow';
const title = exports.title = 'Ihren HubSpot-Entwicklungsworkflow optimieren';
const name = exports.name = 'EMEA DACH (DE) Development Workflow';
const metaDescription = exports.metaDescription = 'Erstellen eines effizienten Entwicklungsworkflows beim Erstellen von Websites mit CMS Hub. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "entwickeln-mit-blick-auf-%C3%BCbertragbarkeit",
  "label": "Entwickeln mit Blick auf Übertragbarkeit",
  "parent": null
}, {
  "depth": 0,
  "id": "einrichten-ihrer-entwicklungsumgebung",
  "label": "Einrichten Ihrer Entwicklungsumgebung",
  "parent": null
}, {
  "depth": 1,
  "id": "einrichten-ihres-code-editors",
  "label": "Einrichten Ihres Code-Editors",
  "parent": "einrichten-ihrer-entwicklungsumgebung"
}, {
  "depth": 2,
  "id": "vs-code",
  "label": "VS-Code",
  "parent": "einrichten-ihrer-entwicklungsumgebung"
}, {
  "depth": 2,
  "id": "andere-code-editoren-und-ides",
  "label": "Andere Code-Editoren und IDEs",
  "parent": "einrichten-ihrer-entwicklungsumgebung"
}, {
  "depth": 0,
  "id": "testen",
  "label": "Testen",
  "parent": null
}, {
  "depth": 2,
  "id": "editor",
  "label": "Editor",
  "parent": "testen"
}, {
  "depth": 2,
  "id": "modulvorschau",
  "label": "Modulvorschau",
  "parent": "testen"
}, {
  "depth": 2,
  "id": "debugging",
  "label": "Debugging",
  "parent": "testen"
}, {
  "depth": 2,
  "id": "sandboxes",
  "label": "Sandboxes",
  "parent": "testen"
}, {
  "depth": 0,
  "id": "bereitstellung",
  "label": "Bereitstellung",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      pre: "pre",
      h3: "h3",
      h4: "h4",
      img: "img",
      ul: "ul",
      li: "li",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Ihren HubSpot-Entwicklungsworkflow optimieren"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Einrichtung eines effizienten Entwicklungsworkflows hilft Ihnen, effektiver Websites mithilfe von CMS Hub zu erstellen. Je nach der Art Ihres Web-Entwicklungsteams oder der Art eines bestimmten Projekts kann sich Ihr Workflow unterscheiden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein einzelner Entwickler, der eine neue Website in einem neuen HubSpot CMS-Account erstellt, muss sich zum Beispiel weniger Gedanken über Tests und Zusammenarbeit machen. Andererseits benötigt ein Entwicklerteam, das an einer größeren Website arbeitet, einen eindeutigeren Entwicklungs- und Staging-Prozess, einen Bereitstellungsworkflow sowie Code, der einer Versionskontrolle unterliegt, um effizient arbeiten zu können."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dieser Leitfaden soll Ihnen helfen, einen effizienten Workflow für Entwickler einzurichten, den Sie an Ihre Bedürfnisse anpassen können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Diese Anleitung geht davon aus, dass Sie Websites mit dem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS-CLI"
      }), " erstellen. Folgen Sie dem Tutorial ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "Erste Schritte mit lokaler Entwicklung"
      }), ", um alles einzurichten. In dieser Anleitung wird außerdem davon ausgegangen, dass Sie die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/quickstart#quick_start",
        children: "Schnellstartanleitung für die Entwicklung mit CMS Hub"
      }), " durchgelesen haben."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Entwickeln mit Blick auf Übertragbarkeit"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bevor wir mit der Einrichtung unseres Entwickler-Workflows beginnen, ist es wichtig, die Portabilität als Schlüsselkonzept für einen effizienten Entwickler-Workflow zu erkennen. Die Portabilität Ihres Projekts sorgt dafür, dass es leicht und ohne große Reibungsverluste zwischen verschiedenen Umgebungen verschoben werden kann, sodass Sie unkompliziert Änderungen testen und einführen können, bevor Sie sie live schalten."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "CMS Theme Boilerplate"
      }), " ist ein Beispielprojekt, das portabel ist und Funktionen wie relative Dateipfade und ein echtes Dateiformat für alle Elemente im Projekt nutzt, die das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "CMS-CLI"
      }), " verwenden. Dadurch kann es in der Versionskontrolle unterliegen und funktioniert in jedem HubSpot-Account. Dieses Projekt ist ein hervorragender Ausgangspunkt oder Bezugspunkt für Entwickler, die an einem neuen Projekt arbeiten. Alle HubSpot-Standarddesigns wurden auf der Grundlage dieses Boilerplate erstellt und können auch als portabler und effektiver Ausgangspunkt verwendet werden."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Einrichten Ihrer Entwicklungsumgebung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Für Ihre individuelle Entwicklungsumgebung sollte jeder Entwickler in Ihrem Team einen kostenlosen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "CMS-Entwickler-Sandbox-Account"
      }), " erstellen. Diese Accounts laufen nie ab und verfügen über alle Funktionen kostenpflichtiger CMS Hub-Accounts (außer der Möglichkeit, benutzerdefinierte Domains zu verknüpfen)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Das CMS-CLI erleichtert die Interaktion mit mehreren CMS Hub-Accounts. Erstellen Sie einen neuen ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli",
        children: "Konfigurationseintrag"
      }), " für Ihren CMS-Entwickler-Sandbox-Account. Legen Sie den Namen des Eintrags für Ihre Sandbox so fest, dass er in etwa „DEV“ oder „SANDBOX“ lautet, damit klar ist, dass es sich bei diesem Account um eine Entwicklungsumgebung handelt. Legen Sie außerdem fest, dass das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "defaultPortal"
      }), " Ihr Sandbox-Account sein soll, sodass bei der Ausführung von Befehlen mit dem CMS-CLI automatisch mit Ihrer Sandbox interagiert wird, um versehentliche Produktionsimplementierungen zu vermeiden. Zu diesem Zeitpunkt sieht Ihre Konfigurationsdatei etwa so aus:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-yaml",
        children: "defaultPortal: DEV\nportals:\n  - name: PROD\n    portalId: 123\n    authType: personalaccesskey\n    personalAccessKey: >-\n      xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n  - name: DEV\n    portalId: 456\n    authType: personalaccesskey\n    personalAccessKey: >-\n      xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n    auth:\n      tokenInfo:\n        accessToken: >-\n          xxxxx-xxxxxx-xxxxxxx-xxxxxx-xxxxx-xxxxxxx-xxxxxxxx\n\n\n        expiresAt: '2020-01-01T00:00:00.000Z'\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie nun Befehle im CMS-CLI ausführen, wie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/local-development-cli#upload",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs upload"
        })
      }), ", werden die Dateien, wenn Sie kein Portal angeben, in Ihren „DEV“-Account hochgeladen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Einrichten Ihres Code-Editors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Ihren bevorzugten Code-Editor verwenden, wenn Sie auf Grundlage von HubSpot entwickeln, egal ob Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#vs-code",
        children: "VS-Code"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#other-code-editors-and-ides",
        children: "andere Code-Editoren und IDEs"
      }), " bevorzugen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "VS-Code"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein großer Teil der Entwickler, die auf Grundlage von HubSpot entwickeln, verwendet ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://code.visualstudio.com/",
        children: "Visual Studio-Code"
      }), ". Das war die Inspiration für die VS-Code-Erweiterung von HubSpot. Die Erweiterung bietet praktische IntelliSense-Snippets, HubL-Code-Vervollständigung, HubL-Syntaxhervorhebung und HubL-Linting. Das Projekt ist ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode",
        children: "quelloffen"
      }), " und ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/blob/master/CONTRIBUTING.md",
        children: "Beiträge sind willkommen"
      }), ". Wenn Sie Feedback haben, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/hubspot-cms-vscode/issues",
        children: "eröffnen Sie bitte ein Thema im Repository"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "c0ab15c7-27f7-4f4d-a884-5468e852577a",
      external: true,
      label: "Get VS Code Extension"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/vs%20code%20extension%20-%20hubl%20variable%20suggestion.gif",
        alt: "VS-Code-Erweiterung – animierte Bildschirmaufnahme mit Vorschlag für Hubl-Variable"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Andere Code-Editoren und IDEs"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt zwar eine offizielle VS-Code-Erweiterung, aber Sie können auch gerne einen anderen bevorzugten Editor verwenden. HubL ist HubSpots private Ableitung von Jinjava, das auf Jinja basiert. Aufgrund der Ähnlichkeiten in der Syntax funktionieren die Erweiterungen zur Syntaxhervorhebung in Jinja in der Regel gut. Erweiterungen und Add-on-Tools variieren je nach Editor."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Testen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt zwei Hauptmethoden zum Testen von Änderungen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Testing with watch/upload:"
        }), " Wenn Sie in Ihrer Entwicklungsumgebung arbeiten, können Sie ohne Bedenken Änderungen automatisch mithilfe des Befehls ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#watch",
          children: "watch"
        }), " hochladen, wenn Sie Dateien in Ihrem Text-Editor speichern, um schnell zu entwickeln. Wenn Sie das „Live-Vorschau mit Anzeigeoptionen“-Tool des Design-Managers für eine Vorlage verwenden, werden die Änderungen beim Speichern automatisch in der gerenderten Ausgabe der Vorlagenvorschau angezeigt. Um die Live-Vorschau einer Vorlage anzuzeigen, wählen Sie ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Vorschau > Live-Vorschau mit Anzeigeoptionen"
        }), " im Vorlagen-Editor des Design-Managers."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lokales Testen:"
        }), " Um eine Vorschau Ihrer Änderungen lokal anzuzeigen, ohne sie in den Account hochzuladen, können Sie den Befehl ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs theme preview"
        }), " im Stammverzeichnis des Designs ausführen. Mit diesem Befehl wird ein lokaler Proxy-Server unter ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://hslocal.net:3000/",
          children: "https://hslocal.net:3000/"
        }), "ausgeführt, mit dem Sie dann eine Vorschau der Vorlagen und Module des Designs anzeigen können. Erfahren Sie mehr über den ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/tools/local-development-cli#locally-preview-theme",
          children: "Befehl „hs theme preview“"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Editor"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ein weiterer wichtiger Teil der Entwicklungsphase ist das Testen Ihrer Änderungen in den Content-Tools. Wenn Sie Module oder Vorlagen erstellen, die im Content-Editor bearbeitet werden sollen, erstellen Sie die Seiten in Ihrer Entwicklungsumgebung, um sicherzustellen, dass die Bearbeitung der Inhalte so funktioniert, wie Sie es sich vorstellen. Ziehen Sie Module in ungerade Konfigurationen und geben Sie Dummy-Inhalte ein, um sicherzustellen, dass Marketer Ihre Module beim Aufbau von Seiten nicht „beschädigen“ können. Mithilfe der Content Editoren können Sie besser die Leitlinien veranschaulichen, die Sie in Ihre Vorlagen und Module einbauen sollten. Derzeit ist es nicht möglich, Inhalte, wie Seiten oder Blog-Beiträge, zwischen HubSpot-Accounts zu verschieben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Modulvorschau"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wählen Sie im Modul-Editor des Design-Managers die Schaltfläche „Vorschau“ aus. Dadurch öffnet sich ein Vorschau-Editor, der zeigt, wie sich das Modul und seine Felder in den Content Editoren verhält. So können Sie die Felder, Gruppen und Repeater in Ihrem Modul mit Dummy-Inhalten in einer sicheren Umgebung testen."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/module-preview.gif",
        alt: "Modulvorschau"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Debugging"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Zu wissen, worauf es bei der Fehlersuche und Behebung bei Problemen mit Ihrer Website ankommt, ist entscheidend für den dauerhaften Erfolg Ihrer Website. Machen Sie sich mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/troubleshooting",
        children: "Debugging-Techniken bei der Entwicklung mit CMS Hub"
      }), " vertraut."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Sandboxes"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wie oben im Abschnitt über die Einrichtung Ihrer Entwicklungsumgebung erwähnt, können Sie kostenlose ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://offers.hubspot.com/free-cms-developer-sandbox",
        children: "CMS-Entwickler-Sandbox-Accounts"
      }), " erstellen, die Sie zum Testen und als sichere Entwicklungsumgebung nutzen können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Bereitstellung"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sobald Sie Ihre Änderungen getestet haben und bereit sind, sie zu veröffnetlichen, ist es an der Zeit, die Änderungen in Ihrem Produktionsportal bereitzustellen. Basierend auf Ihrer lokalen Konfiguration müssen Sie den CMS-CLI-Befehl mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "--portal"
      }), "-Argument ausführen, um mit Ihrem Produktions-Account zu interagieren, z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs upload my-theme/src my-theme --portal=PROD"
      }), ". Achten Sie beim Hochladen von Dateien auf Ihren Produktions-Accouint darauf, ob Fehler auftreten, und gehen Sie kurz auf Ihre Live-Website, um sicherzustellen, dass keine unbeabsichtigten Folgen der Bereitstellung auftreten."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Sie in einem Web-Entwicklungsteam arbeiten, ist es empfehlenswert, Ihre gesamte Produktionscodebasis in der Versionskontrolle zu haben und sie in Ihrem Produktportal bereitzustellen, wenn Änderungen in Master zusammengeführt werden. Auf diese Weise kann Ihr Entwicklerteam Ihr bevorzugtes Versionskontrollsystem nutzen, um zusammenzuarbeiten, Änderungen nachzuverfolgen und Änderungen einfach zurückzunehmen."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie mehr über die Einrichtung der kontinuierlichen Integration mit Git-Repositories erfahren möchten, folgen Sie dieser Anleitung zur ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "Verwendung von GitHub-Aktionen für die Bereitstellung in Ihrem Produktions-Account, wenn Änderungen in Master zusammengeführt werden"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}