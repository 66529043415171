"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 158721871211;
const slug = exports.slug = 'getting-started/what-to-build';
const title = exports.title = 'What to build';
const name = exports.name = 'What to build (developer docs 2024)';
const metaDescription = exports.metaDescription = 'Learn about the different ways to develop on HubSpot.';
const position = exports.position = 1;
const toc = exports.toc = [{
  "depth": 0,
  "id": "use-apis-and-build-integrations",
  "label": "Use APIs and build integrations",
  "parent": null
}, {
  "depth": 1,
  "id": "get-started-authenticating-calls-with-a-private-app",
  "label": "Get started authenticating calls with a private app",
  "parent": "use-apis-and-build-integrations"
}, {
  "depth": 1,
  "id": "build-for-the-app-marketplace",
  "label": "Build for the app marketplace",
  "parent": "use-apis-and-build-integrations"
}, {
  "depth": 0,
  "id": "customize-the-hubspot-ui",
  "label": "Customize the HubSpot UI",
  "parent": null
}, {
  "depth": 0,
  "id": "build-custom-websites",
  "label": "Build custom websites",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      ul: "ul",
      li: "li",
      a: "a",
      h2: "h2",
      strong: "strong",
      em: "em",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "What to build"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "There are several different development routes possible with HubSpot, depending on your goals. You’ll find a high-level overview of each route on this page, along with the tooling and accounts you’ll need for each. You'll also find links to detailed docs to give you more context, as well as relevant quickstart guides."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#use-apis-and-build-integrations",
          children: "Use APIs and build integrations"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#get-started-authenticating-calls-with-a-private-app",
              children: "Get started authenticating calls with a private app"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "#build-for-the-app-marketplace",
              children: "Build for the app marketplace"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#customize-the-hubspot-ui",
          children: "Customize the HubSpot UI (BETA)"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-custom-websites",
          children: "Build custom websites"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Use APIs and build integrations"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Use HubSpot's APIs to build custom solutions, such as sharing data between HubSpot and external systems, using webhooks to listen for changes in the account, and creating custom objects to store data specific to your business."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Example use cases:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use custom objects to customize how the CRM stores data so it best represents your business."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Sync data from external systems to provide a richer picture of go-to-market activities."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Extend the capabilities of the CRM UI to best fit your processes."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Two ways to build apps"
        })
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["There are two ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "types of apps"
        }), ", depending on what you’re building. If you’re getting started with APIs and integrations, it's recommended to start with a private app, as they're faster to set up and use for authentication. Once you’re familiar with building apps, you may want to learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#build-for-the-app-marketplace",
          children: "public apps"
        }), ", which can be installed in multiple accounts and enable you to build other types of extensions."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Example use case for private apps:"
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "“I want to build an application for my company/team.”"
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Get started authenticating calls with a private app"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Most API calls require authentication to interact with the data in your HubSpot account. To get started making calls to your account, create a private app and use its access token for authentication."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
          children: "Super Admin"
        }), " permissions are required to build private apps in your HubSpot account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "How to get started:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "private app"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
          children: "making authenticated API calls with private apps"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You can also use ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/getting-started/overview",
          children: "client libraries"
        }), " to make API calls"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Build for the app marketplace"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Develop integrations for HubSpot's App Marketplace with a public app, to enable HubSpot users to install your integration into their account. Building public apps for the marketplace requires adherence to HubSpot's App Marketplace guidelines, and an app developer account."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Public apps authenticate with OAuth. Public apps can be installed on multiple accounts and can be distributed on the marketplace."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Example use cases for public apps:"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "“I’m a HubSpot partner who wants to build a reusable app that I can adapt for my clients.”"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "“I’m a HubSpot partner who wants to make an app available on the marketplace to promote our capabilities to as many HubSpot customers as possible.”"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "How to get started:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/signup-hubspot/developers",
            children: "Create an app developer account"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Quickstart:"
          }), " ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview",
            children: "Create a public app"
          })]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/oauth-quickstart-guide",
            children: "Follow the OAuth quickstart guide"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
            children: "Review the App Marketplace listing requirements"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/listing-your-app",
            children: "Submit your app to the App Marketplace"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Customize the HubSpot UI"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Build UI extensions to customize the CRM and help desk UI. UI extensions are built locally using the developer projects framework, which enables you to build and deploy to HubSpot using the CLI. The UI extensions SDK provides a toolbox of methods, functionalities, tools, and components to customize your extension. To see examples of what you can build, check out ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/crm/ui-extensions/sample-extensions/overview",
        children: "HubSpot's sample extensions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "Projects enable you to locally build and deploy UI extensions for private apps and public apps using the HubSpot CLI."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Example use cases for UI extensions:"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "“I want to add a custom form to contact and company records that enables our customer support team to create Jira tickets while on customer calls.”"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "“I need to surface detailed sales pipeline summaries across deal records so that our managing partners can find the information they need at a glance.”"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "How to get started:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/crm/overview#get-started",
          children: "Enroll your account"
        }), " into the available betas"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " there are a few ways to use HubSpot's UI customization tools, depending on your HubSpot subscription:"]
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Building UI extensions for private apps in a standard HubSpot account requires a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "Sales Hub"
            })
          }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "Service Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), " subscription. However, a paid subscription is not required to create UI extensions for private apps in ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types#developer-test-accounts",
            children: "developer test accounts"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Public apps are built in ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types#app-developer-accounts",
            children: "developer accounts"
          }), ", which don't require a paid subscription. If you're enrolled in the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/build-app-cards",
            children: "UI extensions for public apps beta"
          }), ", learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/public-apps/overview",
            children: "building UI extensions for public apps"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Follow the quickstart guides:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/private-apps/quickstart",
              children: "UI extensions for private apps quickstart"
            })
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/crm/public-apps/quickstart",
              children: "UI extensions for public apps quickstart"
            })
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/ui-extensions/create",
            children: "creating UI extensions"
          }), " and using the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/crm/ui-extensions/sdk",
            children: "UI extensions SDK"
          })]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Build custom websites"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using HubSpot's CMS (Content Management System) software, you can create powerful websites and landing pages that adapt and tailor themselves to the individuals coming to your site. By building on the CMS, every website visitor interaction is directly connected to the account’s CRM, the same tool that the Sales, Marketing, Services, and RevOps teams are using to do their jobs more efficiently."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Build HubSpot websites locally in your preferred environment with the HubSpot CLI, using technologies such as:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HTML, CSS, and JavaScript"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubL, which is HubSpot's Jinjava-based templating language"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "React, which can be used to build JavaScript-rendered modules and partials"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "GraphQL, which you can use to query HubSpot data"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["And if you prefer to use GitHub for version control, you can ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/github-integration",
        children: "set up an integration using GitHub Actions"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "tip",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The CMS enables you to build websites locally with the HubSpot CLI. You’ll have access to an array of versatile and adaptable tools, such as multi-language support, CTAs, forms, memberships, and more. HubSpot also handles security, server-side performance, CDN, hosting, and uptime assurance for you."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Example use cases for the CMS:"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "“I want to build out product listing pages that pull information directly from inventory maintained in the CRM.”"
        })
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "“I need a website that features a public blog and a members-only blog.”"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Create a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup-hubspot/cms-developers",
          children: "CMS Sandbox account"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Install the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "HubSpot CLI"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Install the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/install-and-use-hubspot-code-extension",
          children: "HubSpot Visual Studio Code extension"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Quickstart:"
        }), " ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/quickstart",
          children: "HubSpot CMS quickstart guide"
        })]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Check out the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms",
          children: "CMS guides"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms",
          children: "reference docs"
        })]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}