"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 74359529730;
const slug = exports.slug = 'reference/cms/modules/configuration';
const title = exports.title = 'Configurer un module';
const name = exports.name = 'EMEA (FR) Configuring a Module';
const metaDescription = exports.metaDescription = 'Découvrez comment configurer un module personnalisé.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "ajout-d-une-ic%C3%B4ne",
  "label": "Ajout d'une icône",
  "parent": null
}, {
  "depth": 0,
  "id": "modification-du-libell%C3%A9",
  "label": "Modification du libellé",
  "parent": null
}, {
  "depth": 0,
  "id": "transformer-en-module-global",
  "label": "Transformer en module global",
  "parent": null
}, {
  "depth": 0,
  "id": "contr%C3%B4le-de-l-endroit-o%C3%B9-un-module-peut-%C3%AAtre-utilis%C3%A9",
  "label": "Contrôle de l'endroit où un module peut être utilisé",
  "parent": null
}, {
  "depth": 0,
  "id": "ajout-de-d%C3%A9pendances-css-et-javascript",
  "label": "Ajout de dépendances CSS et JavaScript",
  "parent": null
}, {
  "depth": 0,
  "id": "ajouter-des-cat%C3%A9gories-et-des-balises",
  "label": "Ajouter des catégories et des balises",
  "parent": null
}, {
  "depth": 1,
  "id": "cat%C3%A9gories",
  "label": "Catégories",
  "parent": "ajouter-des-cat%C3%A9gories-et-des-balises"
}, {
  "depth": 1,
  "id": "balises",
  "label": "Balises",
  "parent": "ajouter-des-cat%C3%A9gories-et-des-balises"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      code: "code",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h2: "h2",
      ol: "ol",
      li: "li",
      strong: "strong",
      pre: "pre",
      em: "em",
      ul: "ul",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      DndSection,
      DndModule,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Configurer un module"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 8,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "Lorsque vous créez un module, vous disposez d'un certain nombre d'options qui ont un impact sur l'endroit où le module est visible, la façon dont il est identifié et la façon dont il est modifié."
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Lors du ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/setup/getting-started-with-local-development",
              children: "développement local"
            }), ", les modules sont stockés sous forme de dossiers avec un suffixe ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".module"
            }), ". Ces dossiers contiennent les fichiers qui constituent les paramètres du module et le code utilisé pour le restituer. La configuration d'un module est conservée dans le fichier ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#meta-json",
              children: "meta.json"
            }), ", tandis que les champs sont configurés séparément dans un fichier ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/modules/overview#fields-json",
              children: "fields.json"
            }), "."]
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["La plupart des configurations peuvent également être modifiées à l'aide de l'éditeur de module dans le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/tools/design-manager",
              children: "gestionnaire de conception"
            }), "."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 4,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://cdn2.hubspot.net/hubfs/53/module%20structure.png",
            alt: "Structure du dossier du module affichée localement"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À un niveau élevé, vous configurez les options de module localement dans le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), ", qui peut inclure les propriétés suivantes :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "icon"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "URL d'une image à utiliser comme icône pour un module."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Label utilisé lorsque les modules sont affichés dans les éditeurs de contenu."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "module_id"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nombre"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Identifiant unique pour le module qui est indépendant du chemin."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_available_for_new_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La valeur du bouton à bascule dans l'angle supérieur droit de l'éditeur de module dans HubSpot. Détermine si le module peut être utilisé dans le contenu."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "global"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Booléen"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Indique si le module est global ou non."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "host_template_types"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un tableau ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " des types de contenu dans lesquels le module peut être utilisé. Un ou plusieurs des éléments ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "[ \"PAGE\", \"BLOG_POST\", \"BLOG_LISTING\", \"EMAIL\" ]"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css_assets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un tableau ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " des fichiers CSS dont dépend le module. Les chemins relatifs sont pris en charge.Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"css_assets\" : [{ \"path\" : \"../path/to/file.css\" }]"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css_render_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Définissez si le module CSS est restitué de manière asynchrone avec ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "async"
            }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"async\": false}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "js_assets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un tableau ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "array"
            }), " des fichiers JavaScript dont dépend le module. Les chemins relatifs sont pris en charge.Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"js_assets\" : [{ \"path\" : \"../path/to/file.js\" }]"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[]"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "js_render_options"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Modifie la balise JavaScript du module ajoutée à la page restituée. Les options comprennent :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "position"
                }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "head"
                }), "/", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "footer"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "async"
                }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "/", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "defer"
                }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "/", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                })]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "type"
                }), " : ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "string"
                })]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{\"position\":\"footer\"}"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inline_help_text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Texte d'aide qui sera affiché en haut du module dans une boîte d'information bleue (limite de 300 caractères).Fournit les informations nécessaires à l'utilisation du module. Si vous avez des informations de texte d'aide spécifiques au champ à transmettre, reportez-vous à la ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/fields/module-theme-fields#properties-used-by-all-fields",
              children: "documentation sur le champ de texte d'aide"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "null"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "master_language"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chaîne"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Lorsque les ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/design-manager/create-translations-of-your-modules#:~:text=To%20create%20translations%20of%20a,the%20right%2C%20click%20Add%20translations.",
              children: "traductions sont activées"
            }), ", le code de la langue dans laquelle les champs du module ont été écrits à l'origine.Par exemple, ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "en"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "placeholder"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objet"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Définit le ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/hubl/tags/standard-tags#editor-placeholders",
              children: "contenu de variable"
            }), " pour le module. Comprend les propriétés suivantes :", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "show_module_icon"
                }), " : indique si l'icône du module s'affiche (", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "true"
                }), "/", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "false"
                }), ")."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "title"
                }), " : le titre qui apparaît sur le module dans l'éditeur. Chaîne."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "description"
                }), " : la description qui apparaît sur le module dans l'éditeur. Chaîne."]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "categories"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un tableau contenant jusqu'à trois ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#add-categories",
              children: "catégories de module"
            }), ".Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"categories\":[\"FORMS_AND_BUTTONS\"]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content_tags"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tableau"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Un tableau d'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#adding-categories-and-tags",
              children: "objets de balise de module"
            }), " contenant le nom de balise et la ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "source"
            }), " de ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"USER\""
            }), ".Par exemple : ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"content_tags\": [{"
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"name\" : \"BUTTONS\","
            }), " ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"source\" : \"USER\"``}]"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Ci-dessous, découvrez-en davantage sur les options de configuration des modules individuels."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ajout d'une icône"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les modules peuvent inclure une icône qui apparaît dans le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/tools/design-manager",
        children: "gestionnaire de conception"
      }), " ainsi que dans les éditeurs de page et d'e-mail pour fournir un contexte visuel aux créateurs de contenu. Il est recommandé d'avoir différentes icônes pour les différents types de module de votre thème. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/marketplace/template-guidelines",
        children: "Pour les fournisseurs de marketplace"
      }), ", ces icônes sont obligatoires."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Il y a deux façons d'ajouter une icône : via le ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-an-icon-using-the-design-manager",
        children: "gestionnaire de conception"
      }), " ou l'", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#add-an-icon-using-the-cli",
        children: "ILC CMS"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Les icônes de module doivent être des fichiers ", (0, _jsxRuntime.jsx)(_components.code, {
        children: ".svg"
      }), " et leur taille ne doit pas dépasser 10 ko. Pour de meilleurs résultats, votre icône doit être simple et n'utiliser qu'une seule couleur. Les icônes qui utilisent plus d'une couleur seront automatiquement converties pour vous. L'icône du module qui s'affiche par défaut est une icône de clé à molette et de pinceau."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Pour ajouter une icône à l'aide du gestionnaire de conception :"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.ol, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Files and Templates"
              }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Design Tools"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["Use the left sidebar to locate the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "module"
              }), " you want to edit, then click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "module name"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
              children: ["In the right sidebar of the module editor, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "icon"
              }), " next to the module title."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
              children: "Upload/select your icon. After publishing your changes, the icon will appear within the page editors and design manager."
            }), "\n"]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-icon-crop.png",
            alt: "edit-module-icon-crop"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour ajouter une icône lors du développement local, ouvrez le fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " du module et ajoutez ou modifiez la valeur du paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "icon"
      }), " pour qu'il soit au format SVG à partir du gestionnaire de fichiers."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"global\": false,\n  \"host_template_types\": [\"PAGE\"],\n  \"icon\": \"http://example.com/hubfs/code.svg\",\n  \"label\": \"Code block\",\n  \"smart_type\": \"NOT_SMART\",\n  \"is_available_for_new_content\": true,\n  \"inline_help_text\": \"This module is for code snippets.\"\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Modification du libellé"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.p, {
            children: "Le libellé utilisé lorsque les modules sont affichés dans l'éditeur peut être modifié séparément du nom du module. Cela est utile lors du développement local, car vous pouvez avoir un nom qui a du sens pour vous tout en ayant un nom différent pour les créateurs de contenu."
          }), (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Localement, vous modifiez le paramètre ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            }), " pour définir le libellé. Dans le gestionnaire de conception, un champ dans l'éditeur de module est présent en dessous du nom du module."]
          })]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-label.png",
            alt: "edit-module-label"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Transformer en module global"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Pour les modules normaux, le contenu de chaque instance d'un module dans une page, un e-mail ou un modèle est indépendant. Pour certains cas d'utilisation, il est utile de pouvoir faire en sorte que toutes les instances d'un module partagent le même contenu. Lors du développement local, vous pouvez rendre un module global en définissant ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "global"
      }), " sur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "true"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez également ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/design-manager/use-global-content-across-multiple-templates#create-new-global-content-in-the-design-manager:~:text=To%20convert%20an%20existing%20local%20module%20or%20group",
        children: "convertir des modules dans un modèle de glisser-déposer en global"
      }), " via le gestionnaire de conception."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Contrôle de l'endroit où un module peut être utilisé"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lors du développement local, vous pouvez contrôler les types de contenu dans lesquels un module peut être utilisé via la propriété ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hostTemplateTypes"
      }), ". Découvrez-en davantage sur les ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/overview#template-types",
        children: "types de modèles disponibles"
      }), ". Il est également possible de masquer les modules afin qu'ils ne puissent pas être ajoutés directement aux pages en définissant la valeur ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "false"
      }), " pour ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "is_available_for_new_content"
      }), ". Par exemple, cela peut être utile pour les modules conçus pour les menus de navigation et la recherche."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Vous pouvez mettre à jour cela dans le gestionnaire de conception en cliquant sur l'option ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Type de modèle"
      }), " dans la barre latérale droite."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-template-type.png",
        alt: "edit-module-template-type"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ajout de dépendances CSS et JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["En plus de l'utilisation de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.css"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module.js"
      }), " pour ajouter des éléments CSS et JavaScript qui seront ajoutés à toutes les pages qui incluent une instance de module, les dépendances qui sont partagées entre les modules peuvent être jointes à l'aide de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "css_assets"
      }), " et ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "js_assets"
      }), ". Les chemins peuvent être absolus ou relatifs au fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"css_assets\": [{ \"path\": \"../path/to/file.css\" }],\n  \"js_assets\": [{ \"path\": \"../path/to/file.js\" }]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Attention :"
        }), " Lorsque vous utilisez des chemins relatifs pour référencer les dépendances, l'exécution de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "hs fetch --overwrite"
        }), " pour mettre à jour le module pour le développement local remplacera les chemins relatifs par des chemins absolus."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["À l'aide du gestionnaire de conception, vous pouvez lier des fichiers CSS et JavaScript à un module à l'aide de la section ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Fichiers liés"
      }), " dans la barre latérale droite de l'éditeur de module."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-linked-files.png",
        alt: "edit-module-linked-files"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ajouter des catégories et des balises"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Vous pouvez affecter des catégories et des balises aux modules pour les organiser dans les outils HubSpot :"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#categories",
          children: "Catégories"
        }), " : assignez des catégories à un module pour les organiser en groupes au sein de l'interface utilisateur de l'éditeur de contenu. Cela permet aux créateurs de contenu de trouver des modules plus facilement tout en créant du contenu dans HubSpot. Notez ce qui suit à propos des catégories :", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Un module peut avoir jusqu'à trois catégories, qui sont prédéfinies et ne peuvent pas être personnalisées."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Actuellement, les catégories ne sont pas affichées dans l'interface utilisateur de l'éditeur de contenu. Cependant, vous pouvez attribuer des catégories lorsque la catégorisation est disponible dans les éditeurs."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Les modules non classés seront disponibles dans une catégorie ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Tous"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "#tags",
          children: "Balises"
        }), " : assignez des balises pour organiser les modules dans le gestionnaire de conception. Cela vous permet de trouver des modules plus facilement lors de la création de modèles."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Dans le gestionnaire de conception, vous pouvez attribuer des catégories et des balises à l'aide de la section ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Filtrer les balises"
      }), " de la barre latérale droite dans l'éditeur de module. Découvrez-en davantage sur les catégories et les balises disponibles ci-dessous."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/edit-module-filter-tags.png",
        alt: "edit-module-filter-tags"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Localement, vous pouvez ajouter des catégories et des balises au fichier ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "meta.json"
      }), " d'un module comme suit :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// meta.json\n{\n  \"label\": \"Custom module\",\n  \"categories\": [\"image_and_video\", \"commerce\"],\n  \"content_tags\": [\n    {\n      \"name\": \"BUTTONS\",\n      \"source\": \"USER\"\n    },\n    {\n      \"name\": \"CTA\",\n      \"source\": \"USER\"\n    }\n  ],\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Catégories"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le tableau ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "categories"
      }), " d'un module peut contenir jusqu'à trois des catégories suivantes (insensibles à la casse) :"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Catégorie"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "blog"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules spécifiques au blog, tels qu'un listing des publications récentes."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "body_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules formatés pour présenter graphiquement le contenu, comme une galerie d'images."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules spécifiques au domaine commercial, tels que les cartes de prix."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "design"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules qui affectent la structure et la mise en page du contenu, tels que les accordéons."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "functionality"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules qui incluent des réponses dynamiques ou un comportement sur la page, tels que des menus."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms_and_buttons"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules qui permettent aux visiteurs du site de saisir et soumettre des données."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules contenant des éléments tels que des images, des icônes, des vidéos et des bannières."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules spécifiques aux réseaux sociaux, tels que le partage social."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "text"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Modules qui ne contiennent que du texte."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Balises"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Le tableau ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "content_tags"
      }), " d'un module peut contenir l'un des objets de balise de module suivants (sensible à la casse) :"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Types de contenu :"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ACCORDION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ANIMATION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOG_POST"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BUTTONS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CODE"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CTA"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FEED"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "FORM"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "ICON"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "IMAGE"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LISTS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LOGO"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MENU"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "RICH_TEXT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SLIDER"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "TEXT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "VIDEO"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Fonctions :"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BANNER"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BLOG"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "BRANDING"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CALCULATOR"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "CONVERSION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "email"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "GALLERY"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "HEADERS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "INTERACTION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "LAYOUT"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MAP"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "MULTIMEDIA"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "NAVIGATION"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "PROGRESS_BAR"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SEARCH"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "SETTINGS"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "social"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "TRANSLATION"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}