"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 31316286993;
const slug = exports.slug = 'guides/api/overview';
const title = exports.title = 'HubSpot APIの使い方';
const name = exports.name = "APAC JAPAN (JA) | WEB | How to use HubSpot's APIs | 202007";
const metaDescription = exports.metaDescription = 'HubSpot APIを使うと、カスタムアプリを開発したり、カスタム連携を行ったりすることができます。まずはAPIの概要と開発者アカウントの作成についてご確認ください。';
const toc = exports.toc = [{
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E9%96%8B%E7%99%BA",
  "label": "アプリの開発",
  "parent": null
}, {
  "depth": 1,
  "id": "%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA",
  "label": "公開アプリ",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E9%96%8B%E7%99%BA"
}, {
  "depth": 1,
  "id": "%E9%9D%9E%E5%85%AC%E9%96%8B%E3%82%A2%E3%83%97%E3%83%AA",
  "label": "非公開アプリ",
  "parent": "%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E9%96%8B%E7%99%BA"
}, {
  "depth": 0,
  "id": "%E8%AA%8D%E8%A8%BC",
  "label": "認証",
  "parent": null
}, {
  "depth": 0,
  "id": "%E5%88%A9%E7%94%A8%E3%81%A8%E5%88%B6%E9%99%90",
  "label": "利用と制限",
  "parent": null
}, {
  "depth": 0,
  "id": "%E3%82%A2%E3%83%97%E3%83%AA%E3%83%91%E3%83%BC%E3%83%88%E3%83%8A%E3%83%BC%E3%81%A8%E3%82%A2%E3%83%97%E3%83%AA%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%83%E3%83%88%E3%83%97%E3%83%AC%E3%82%A4%E3%82%B9",
  "label": "アプリパートナーとアプリマーケットプレイス",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot%E3%82%A2%E3%82%AB%E3%83%87%E3%83%9F%E3%83%BC%E3%81%A7api%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6%E5%AD%A6%E3%81%B6",
  "label": "HubSpotアカデミーでAPIを使用してアプリを作成する方法について学ぶ",
  "parent": null
}, {
  "depth": 2,
  "id": "%E3%82%AF%E3%82%A4%E3%83%83%E3%82%AF%E3%83%AA%E3%83%B3%E3%82%AF",
  "label": "クイックリンク",
  "parent": "hubspot%E3%82%A2%E3%82%AB%E3%83%87%E3%83%9F%E3%83%BC%E3%81%A7api%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6%E5%AD%A6%E3%81%B6"
}, {
  "depth": 2,
  "id": "%E9%96%A2%E9%80%A3%E3%83%89%E3%82%AD%E3%83%A5%E3%83%A1%E3%83%B3%E3%83%88",
  "label": "関連ドキュメント",
  "parent": "hubspot%E3%82%A2%E3%82%AB%E3%83%87%E3%83%9F%E3%83%BC%E3%81%A7api%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%82%A2%E3%83%97%E3%83%AA%E3%82%92%E4%BD%9C%E6%88%90%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6%E5%AD%A6%E3%81%B6"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      ul: "ul",
      li: "li",
      hr: "hr",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "API開発者向け各種ガイドおよび資料"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpotのAPIについては、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "こちらにリファレンスドキュメント"
      }), "があります。APIを活用することにより、実用的なアプリや連携をスムーズかつ簡単に開発できます。活用の面で必要な事項を以下に概説します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリの開発"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "開始する前に、開発するアプリの種類を決定する必要があります。"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "公開アプリ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "アプリマーケットプレイス"
      }), "にリストされているアプリなど、組織外の他のHubSpotユーザーがインストールするアプリを作成する予定の場合は、公開アプリを作成する必要があります。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "公開アプリを開発する場合は、アプリ開発者アカウントを次のように作成する必要があります。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["まず、", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/get-started",
          children: "このページに移動"
        }), "します。"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "［アプリ開発者アカウントを作成］をクリックします。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "GoogleアカウントまたはMicrosoftアカウントを使用して認証するか、Eメールを入力します。"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "セットアップ手順に従って、開発者アカウントを作成します。"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["その後、新しいアプリを作成し、OAuthを構成し、テスト環境を構築することができます。", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/overview",
        children: "今すぐ開発してみましょう。"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "非公開アプリ"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "アカウントからコンタクトデータを表示／変更できる内部アプリなど、HubSpotアカウント内の他のユーザーのみが利用できる連携を作成することが目標である場合は、非公開アプリを作成できます。"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview",
        children: "非公開アプリ"
      }), "の作成について詳しくは、こちらの記事をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "認証"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot APIエンドポイントのほとんどは、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/working-with-oauth",
        children: "OAuth"
      }), "および", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/private-apps/overview#make-api-calls-with-your-app-s-access-token",
        children: "非公開アプリ アクセス トークン"
      }), "の両方に対応しています。"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "**注：**2022年11月30日をもってHubSpot APIキーは廃止され、サポートも終了しています。HubSpot APIキーの使用を続けると、アカウントやデータに対するセキュリティー上のリスクが生じます。なお、HubSpotによってこの廃止段階中にお客さまのキーが無効となった可能性があります。"
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["認証には、代わりに非公開アプリのアクセストークンまたはOAuthを使用する必要があります。", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog/upcoming-api-key-sunset",
          children: "この変更"
        }), "の詳細と、非公開アプリを使用するように", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/migrate-an-api-key-integration-to-a-private-app",
          children: "APIキーを移行"
        }), "する方法をご確認ください。"]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "HubSpotアプリマーケットプレイスにアプリを掲載したり、複数のユーザーによるインストールを可能にしたりする場合は、OAuthを使用する必要があります。"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/overview",
          children: "非公開アプリ"
        }), "を開発する場合は、アプリのアクセストークンを使用した呼び出しが可能ですが、この場合もOAuthが実装されます。"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "利用と制限"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "利用上のガイドライン"
      }), "、レート制限、およびAPI呼び出し利用状況の確認方法をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "アプリパートナーとアプリマーケットプレイス"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "アプリの掲載に必要な条件"
      }), "を確認し、", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "アプリ掲載情報を作成"
      }), "します。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpotアカデミーでAPIを使用してアプリを作成する方法について学ぶ"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot API、開発者アカウント、OAuthとAPIキー両方の呼び出し方法については、HubSpotアカデミーの短い無料の", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://academy.hubspot.com/courses/integrating-with-hubspot-foundations",
        children: "動画"
      }), "（英語）をご覧ください。"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "クイックリンク"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://app.hubspot.com/signup-v2/developers/step/join-hubspot",
            children: "開発者アカウントを作成"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/account-types#developer-test-accounts",
            children: "開発者テストアカウントをセットアップ"
          }), "してアプリをインストールし、API呼び出しをテストする"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/changelog",
            children: "変更ログ"
          }), "（英語）の受信を申し込んで最新情報を把握"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["HubSpotの", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://community.hubspot.com/t5/HubSpot-Developers/ct-p/developers",
            children: "開発者コミュニティーフォーラム"
          }), "（英語）でコミュニケーションに参加したり、質問したりする"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["当社の開発者", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/getting-started/slack/developer-slack",
            children: "Slackコミュニティー"
          }), "（英語）に参加する"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "関連ドキュメント"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/overview",
        children: "APIリファレンスドキュメント"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}