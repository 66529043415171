"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.position = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 172847313051;
const slug = exports.slug = 'reference/cms/forms';
const title = exports.title = 'HubSpot forms';
const name = exports.name = 'Forms reference (draft)';
const metaDescription = exports.metaDescription = 'View reference information for forms, including available form events, validation error messages, and internationalization options.';
const position = exports.position = 3;
const toc = exports.toc = [{
  "depth": 0,
  "id": "form-embed-configuration",
  "label": "Form embed configuration",
  "parent": null
}, {
  "depth": 1,
  "id": "embed-code-callbacks",
  "label": "Embed code callbacks",
  "parent": "form-embed-configuration"
}, {
  "depth": 0,
  "id": "internationalization",
  "label": "Internationalization",
  "parent": null
}, {
  "depth": 0,
  "id": "validation-messages",
  "label": "Validation messages",
  "parent": null
}, {
  "depth": 1,
  "id": "live-validation-errors",
  "label": "Live validation errors",
  "parent": "validation-messages"
}, {
  "depth": 1,
  "id": "client-side-validation-errors",
  "label": "Client-side validation errors",
  "parent": "validation-messages"
}, {
  "depth": 1,
  "id": "server-side-validation-errors",
  "label": "Server-side validation errors",
  "parent": "validation-messages"
}, {
  "depth": 0,
  "id": "form-events",
  "label": "Form events",
  "parent": null
}, {
  "depth": 1,
  "id": "onbeforeforminit",
  "label": "onBeforeFormInit",
  "parent": "form-events"
}, {
  "depth": 1,
  "id": "onformready",
  "label": "onFormReady",
  "parent": "form-events"
}, {
  "depth": 1,
  "id": "onbeforeformsubmit",
  "label": "onBeforeFormSubmit",
  "parent": "form-events"
}, {
  "depth": 1,
  "id": "onformsubmit",
  "label": "onFormSubmit",
  "parent": "form-events"
}, {
  "depth": 1,
  "id": "onformsubmitted",
  "label": "onFormSubmitted",
  "parent": "form-events"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      strong: "strong",
      ul: "ul",
      li: "li",
      em: "em",
      pre: "pre",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h3: "h3"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Tag
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tag) _missingMdxReference("Tag", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "HubSpot forms reference"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/guides/cms/content/forms",
        children: "HubSpot forms"
      }), " to capture information from website visitors, which you can then access throughout HubSpot. You can share links to forms directly with users, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/marketing/forms",
        children: "submit form data via the API"
      }), ", and embed them on your website pages using the CMS."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Below, find reference documentation for forms, including form embed configuration options, internationalization and validation error messages, and form events."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Form embed configuration"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "When embedding a form, you can include the following customization options in the form embed code."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["Form customization options are only available for forms created in HubSpot that have been ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#with-css-in-your-external-stylesheet-marketing-hub-professional-enterprise-or-legacy-marketing-hub-basic-only",
            children: "set as raw HTML"
          }), ". The HubSpot account must have a ", (0, _jsxRuntime.jsx)(_components.em, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Marketing Hub"
            })
          }), " or ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: (0, _jsxRuntime.jsx)(_components.em, {
              children: "Content Hub"
            })
          }), " ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Professional"
          }), " or ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Enterprise"
          }), " subscription."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubSpot forms should only be loaded using the HubSpot-hosted JavaScript file. Making a copy of the form embed code and self-hosting it is ", (0, _jsxRuntime.jsx)("u", {
            children: "not"
          }), " supported. Any improvements that HubSpot makes to improve security, anti-spam, accessibility, and performance will not propagate to your form if you decide to self-host the HubSpot form embed code."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script\n  charset=\"utf-8\"\n  type=\"text/javascript\"\n  src=\"//js.hsforms.net/forms/embed/v2.js\"\n></script>\n<script>\n  hbspt.forms.create({\n    region: 'na1',\n    portalId: '123456',\n    formId: 'df93f0c1-2919-44ef-9446-6a29f9a7f',\n  });\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "portalId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number or string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the HubSpot account that the form was created in. This is used to retrieve the form definition."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "formId"
            }), (0, _jsxRuntime.jsx)(Tag, {
              type: "error",
              children: "Required"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The form's ID, which is used to retrieve the form definition."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "region"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/account/hubspot-cloud-infrastructure-and-data-hosting-frequently-asked-questions",
              children: "region"
            }), " of the account where the form was created. This is used to retrieve the form definition. Possible values are ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "na1"
            }), " or ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "eu1"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "target"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A valid CSS selector string, specifying an existing element on the page into which the form will be embedded.If you're including multiple forms on the page, it is strongly recommended that you include a separate, specific target for each form. It's strongly recommended you specify this property, otherwise the form is injected after the script location."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirectUrl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The URL that the form will redirect to upon successful submission. Cannot be used with ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "inlineMessage"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inlineMessage"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The message to display in place of the form upon successful submission. Cannot be used with ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirectUrl"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pageId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Number or string"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The ID of the HubSpot page that you're embedding the form on. Associates the form submissions with the page."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cssRequired"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "CSS string specific to validation error messages and form styling. Set this property to an empty string if you don't want to apply default HubSpot styling to the form elements."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cssClass"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The class that will be applied to the form."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "css"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A CSS string that, when defined, is used instead of the built-in CSS theme. This can be used for setting your own CSS styling."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submitText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String that overrides the submit button text."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submitButtonClass"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["CSS class that will be applied to the submit button instead of the default ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".hs-button.primary.large"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "errorClass"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["CSS class that will be applied to the inputs with validation errors instead of the default ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".invalid.error"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "errorMessageClass"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["CSS class that will be applied to error messages instead of the default ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ".hs-error-msgs.inputs-list"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The form's locale, which is used to customize language for form errors, date pickers, labels, and links. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#internationalization",
              children: "adding internationalized error messages"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "translations"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An object containing additional translation languages or to override field labels and messages for existing languages. Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#internationalization",
              children: "adding internationalization"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "manuallyBlockedEmailDomain"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array of domains, specified as strings, to block in email input fields."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "formInstanceId"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Required when embedding the same form on the same page multiple times. This ID can be arbitrary, so long as it's unique."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Embed code callbacks"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The form embed code includes event callbacks that you can use to extend form functionality when the events below occur. These are separate from the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#form-events",
        children: "global form events"
      }), " that you can hook into."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-html",
        children: "<script\n  charset=\"utf-8\"\n  type=\"text/javascript\"\n  src=\"//js.hsforms.net/forms/embed/v2.js\"\n></script>\n<script>\n  hbspt.forms.create({\n    portalId: '',\n    formId: '',\n    onBeforeFormSubmit: function ($form) {\n      // YOUR SCRIPT HERE\n    },\n  });\n</script>\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onBeforeFormInit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Function"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A callback that executes before the form builds. Takes form configuration object as a single argument: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "onBeforeFormInit(ctx)"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onFormReady"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Function"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A callback that executes just before the form render. This callback should be used for any logic that needs to execute when the form has fully rendered on the page. Takes the jQuery form object as the argument: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "onFormReady($form)"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onFormSubmit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Function"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A callback that executes after the form is validated and before the form is submitted (i.e., right before the data is actually sent). This is for any logic that needs to execute before submission is initiated. Any changes made to form fields will not be validated. Takes the jQuery form object as the argument: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "onFormSubmit($form)"
            }), ".", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "It is ", (0, _jsxRuntime.jsx)("u", {
              children: "not"
            }), " recommended to perform a browser redirect in this callback, as it could prevent the form submission. For any custom redirects, use the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "onFormSubmitted"
            }), " callback.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onBeforeFormSubmit"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Function"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A callback that executes after the form is validated, before the form is submitted i.e. before the data is actually sent. This is for any logic that needs to execute before submission is initiated. Any changes made to fields will not be validated.", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)("br", {}), "This callback behaves in the same way as ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "onFormSubmit"
            }), ", but is preferred due to the accurate naming of when it is triggered. Takes the jQuery form object as the argument and an array of submission values: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "onBeforeFormSubmit($form, submissionValues)"
            }), ". As in the case of ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "onFormSubmit"
            }), ", It is ", (0, _jsxRuntime.jsx)("u", {
              children: "not"
            }), " recommended to perform a browser redirect in this callback as this could prevent before the submission is initiated, thus preventing form submissions. For any custom redirects, please use the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "onFormSubmitted"
            }), " callback."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "onFormSubmitted"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Function"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Callback that executes after form submission. This is for executing actions when the submission is fully complete, such as displaying a confirmation or thank you message, or performing a custom redirect. Takes the jQuery form object as the argument and an array of submission values: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "onFormSubmitted($form, data)"
            }), ".The callback's arguments are the form element and an object containing two properties:", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "redirectUrl"
                }), ": a string containing a redirect URL, if set."]
              }), (0, _jsxRuntime.jsxs)("li", {
                children: [(0, _jsxRuntime.jsx)(_components.code, {
                  children: "submissionValues"
                }), ": an object containing the submitted values from the form."]
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Internationalization"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["By default, HubSpot provides a set of translated date picker field labels and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#validation-messages",
        children: "validation messages"
      }), " for a set of supported languages. You can also add custom languages or override specific error messages and date picker months/days displayed on the form ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#custom-internationalization",
        children: "using the translation parameter"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To include the default translated strings for a supported language, add the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "locale"
      }), " parameter to the embed code, followed by one of the languages in the table below."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hbspt.forms.create({\n  portalId: '',\n  formId: '',\n  locale: 'fi',\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Value"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Language"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "en"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "English"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "da"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Danish"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "de"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "German"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "es"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Spanish (Spain)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "es-mx"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Spanish (Mexico)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fi"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Finnish"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fr"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "French"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "it"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Italian"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ja"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Japanese"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Dutch"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pl"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Polish"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "pt-br"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Portuguese (Brazil)"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sv"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Swedish"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "zh-cn"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chinese"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "zh-hk"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Chinese (Hong Kong)"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To add custom languages or override field labels and default translated strings, you can pass language objects into the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "translations"
      }), " parameter that correspond to the desired ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "locale"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For supported locales, you only need to provide the keys and messages you wish to override. For example, the code below configures the form to replace the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "email"
      }), " field label, required field validation message, and submit button text with custom strings."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#validation",
        children: "validation below"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hbspt.forms.create({\n  portalId: '',\n  formId: '',\n  locale: 'en',\n  translations: {\n    en: {\n      fieldLabels: {\n        email: 'Electronic mail',\n      },\n      required: \"Hey! That's required!\",\n      submitText: 'Custom Submit Button Text',\n    },\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Value"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Type"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Language"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "translations"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An object containing additional translation languages or to override field labels and messages for existing languages."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "en"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["An object containing custom translations for the specified ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "locale"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fieldLabels"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Object"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "An object containing field label replacement values. In this object, you'll specify the name of the form field followed by your custom label."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "required"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Replaces the validation message for required field errors with a custom message."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submitText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "String"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Replaces the submit button text with a custom string."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to the supported locales, you can register new locale codes in the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "locale"
      }), " parameter. In this case, make sure to specify messages for ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "all"
      }), " of the keys listed in the table below. Omitted keys will show a \"missing translation\" message in their place."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Key"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "English default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "learnMore"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Learn more"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submitText"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Submit"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "previousMonth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Previous Month"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "nextMonth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Next Month"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "january"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "January"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "february"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "February"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "march"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "March"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "april"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "April"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "may"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "May"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "june"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "June"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "july"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "July"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "august"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "August"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "september"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "September"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "october"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "October"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "november"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "November"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "december"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "December"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sunday"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sunday"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "monday"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Monday"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tuesday"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tuesday"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "wednesday"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wednesday"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "thursday"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Thursday"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "friday"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Friday"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "saturday"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Saturday"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sundayShort"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sun"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "mondayShort"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Mon"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tuesdayShort"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Tue"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "wednesdayShort"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Wed"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "thursdayShort"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Thu"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fridayShort"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Fri"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "saturdayShort"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sat"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fallbackDescription"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "We had some trouble loading this form."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fallbackCta"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Click here to continue."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fallbackGenericDescription"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This form didn't load. Please try again later."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fileTooLarge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Selected file is too large. Maximum allowed size is 100MB."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "defaultSelectOptionLabel"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please Select"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "notYou"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Not you?"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "resetTheForm"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Click here to reset."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Validation messages"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "HubSpot provides three layers of form validation:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Live validation:"
        }), " validation that occurs while a visitor is filling out a form."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Client-side validation:"
        }), " validation that occurs after the visitor attempts to submit the form, but before the submission request has been sent to the server."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Server-side validation:"
        }), " validation that occurs after form submission."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["During each step of validation, HubSpot provides a set of default error messages. These messages can be overridden by using the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "locale"
      }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "translation"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#internationalization",
        children: "parameters"
      }), " in the form embed code. Below, learn more about the default validation error messages and how to override them depending on when they occur."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Live validation errors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below are the currently supported live validation field error keys for contextual overriding. These error keys can be specified in the language object of the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "translations"
      }), " parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hbspt.forms.create({\n  portalId: '',\n  formId: '',\n  locale: 'en',\n  translations: {\n    en: {\n      required: \"Hey! That's required!\",\n      invalidEmailFormat: 'Email address formatted incorrectly.',\n      invalidNumber: 'Not a valid number.',\n    },\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Error key"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "English default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "requiredField"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please complete this required field."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "required"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please complete this required field."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "missingSelect"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please complete this required field."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "missingOptionSelection"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please complete this required field."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "fileTooLarge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Selected file is too large. Maximum allowed size is 100MB."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "invalidEmailFormat"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Email must be formatted correctly."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "phoneInvalidCharacters"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A valid phone number may only contain numbers and any of the following characters: ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "+"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "("
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: ")"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "-"
            }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "x"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "invalidNumber"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please enter a valid number."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "invalidDate"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please enter a valid date."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "invalidEmail"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please enter a valid email address."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "invalidNumberRangeTooSmall"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please enter a number that's greater than or equal to ."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "invalidNumberRangeTooLarge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please enter a number that's less than or equal to ."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forbiddenEmailDomain"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Please enter a different email address. This form does not accept addresses from ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{domain}"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "manuallyBlockedEmailDomain"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Please enter a different email address. This form does not accept addresses from ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "{domain}"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "numberOutOfRange"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The number you entered is not in range."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "inputTooLarge"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please use fewer than 65536 characters."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Client-side validation errors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following errors may be generated and displayed client-side. They can be contextually overridden using a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "submissionErrors"
      }), " object."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hbspt.forms.create({\n  portalId: '',\n  formId: '',\n  locale: 'en',\n  translations: {\n    en: {\n      submissionErrors: {\n        MISSING_REQUIRED_FIELDS: 'Please fill in all required fields.',\n      },\n    },\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Error key"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "English default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submissionErrors.MISSING_REQUIRED_FIELDS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please complete all required fields."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submissionErrors.BLOCKED_EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Please change your email address to continue."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submissionErrors.TOO_MANY_REQUESTS"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "There was an issue submitting your form. Please wait a few seconds and try again."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Server-side validation errors"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The following errors may be generated on the server after a submission request has been sent and displayed client-side when a response is received. They can be contextually overridden using a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "submissionErrors"
      }), " object."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "hbspt.forms.create({\n  portalId: '',\n  formId: '',\n  locale: 'en',\n  translations: {\n    en: {\n      submissionErrors: {\n        SERVER_ERROR: 'Sorry, something went wrong. Please try again.',\n      },\n    },\n  },\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Error key"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "English default"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submissionErrors.SERVER_ERROR"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Sorry, something went wrong and the form was not submitted. Please try again later."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submissionErrors.RECAPTCHA_VALIDATION_ERROR"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Failed to validate Captcha. Please try again."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submissionErrors.OUT_OF_DATE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This form is no longer current. Please refresh the page and try again."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submissionErrors.FORM_NOT_PUBLISHED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This form is no longer active. Please refresh the page and try again."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submissionErrors.MISSING_SCOPE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This form cannot be submitted. Please contact the site owner."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submissionErrors.FORM_NEVER_EXISTED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This form cannot be submitted. Please contact the site owner."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submissionErrors.FORM_TYPE_MISMATCH"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "This form cannot be submitted. Please contact the site owner."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "submissionErrors.FILE_TOO_LARGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Selected file is too large. Maximum allowed size is 100MB."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Form events"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Forms allow two ways to bind functionality onto events, including ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#embed-code-callbacks",
        children: "callbacks in the HubSpot form embed code"
      }), " and global form events."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Use these events to trigger custom JavaScript. If you need complete control over the styles and actions of your form, it's recommended that you build your own custom form and submit the data using the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/marketing/forms/v3-legacy",
        children: "Forms API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// Example event listener:\n\nwindow.addEventListener('message', (event) => {\n  if (\n    event.data.type === 'hsFormCallback' &&\n    event.data.eventName === 'onFormSubmitted'\n  ) {\n    someAnalyticsLib('formSubmitted');\n    console.log('Form Submitted! Event data: ${event.data}');\n  }\n});\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["These events are non-blocking, so it's not possible to prevent a form submission using the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "onFormSubmit"
          }), " callback."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["You cannot change form submission data using ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "onBeforeFormSubmit"
          }), ". When using ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "onBeforeFormSubmit"
          }), ", the form is submitted as the event is emitted to the browser. Any listeners hooked to the events do not block the main thread of the form's execution. For synchronous changes to the form, it is recommended to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/beta-docs/guides/cms/content/forms",
            children: "customize the form embed code"
          }), " instead."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "onBeforeFormInit"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Called before the form has been inserted into DOM."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// onBeforeFormInit event arguments\n{\n  type: 'hsFormCallback',\n  eventName: 'onBeforeFormInit',\n  id: 'Id of form submitted',\n  data: {}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "onFormReady"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Called after the form has been inserted into DOM."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// onFormReady event arguments\n{\n  type: 'hsFormCallback',\n  eventName: 'onFormReady',\n  id: 'Id of form submitted',\n  data: {}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "onBeforeFormSubmit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Called at the start of form submission, but before submission has been persisted. Behaves the same as ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onFormSubmit"
      }), ", but is preferred due to more accurate naming indicating when this event is triggered."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When performing custom redirects, use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#onformsubmitted",
        children: "onFormSubmitted"
      }), " instead, as this event may prevent submissions being initiated, thus preventing form submissions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// onBeforeFormSubmit event arguments\n{\n  type: 'hsFormCallback',\n  eventName: 'onBeforeFormSubmit',\n  id: 'ID of the form to submit',\n  data: [\n   // Array containing the names and values\n   // of the fields about to be submitted\n    {\n        name: 'email',\n        value: 'test@example.com'\n    },\n    {\n        name: 'firstName',\n        value: 'Jane'\n    },\n    {\n        name: 'lastName',\n        value: 'Doe'\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "onFormSubmit"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Called at the start of form submission, but before submission has been persisted. Please use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "onBeforeFormSubmit"
      }), " instead."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When performing custom redirects, use ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#onformsubmitted",
        children: "onFormSubmitted"
      }), " instead, as this event may prevent submissions being initiated, thus preventing form submissions."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// onFormSubmit event arguments\n{\n  type: 'hsFormCallback',\n  eventName: 'onFormSubmit',\n  id: 'ID of the form to submit',\n  data: [\n  // Array containing the names and values\n  // of the fields about to be submitted\n    {\n          name: 'email',\n          value: 'test@example.com'\n      },\n      {\n          name: 'firstName',\n          value: 'Jane'\n      },\n      {\n          name: 'lastName',\n          value: 'Doe'\n      }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "onFormSubmitted"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Called after the form has been submitted and the submission has persisted. Recommended for performing custom redirects."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "// onFormSubmitted event arguments\n{\n  type: 'hsFormCallback',\n  eventName: 'onFormSubmitted',\n  id: 'ID of form submitted',\n  data: {\n    // String containing redirect url, if set\n    redirectUrl: \"https://example-url.com\",\n    // Object containing key-value pairs of submitted data\n    submissionValues: {\n      'email': 'test@example.com',\n      'firstName': 'Jane',\n      'lastName': 'Doe'\n    }\n  }\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}