"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.orderedTitles = exports.orderedHttpMethods = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 2;
const slug = exports.slug = 'reference/api/oauth/v1';
const title = exports.title = 'OAuth - V1';
const name = exports.name = 'OAuth - V1';
const metaDescription = exports.metaDescription = 'OAuth - V1';
const orderedTitles = exports.orderedTitles = ['Initiate an integration with OAuth 2.0', 'Get Information for OAuth 2.0 Access Token', 'Get Information for OAuth 2.0 Refresh Token', 'Get OAuth 2.0 access and refresh tokens', 'Refresh OAuth 2.0 access token', 'Delete OAuth 2.0 Refresh Token'];
const orderedHttpMethods = exports.orderedHttpMethods = ['GET', 'GET', 'GET', 'POST', 'POST', 'DELETE'];
const toc = exports.toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
      h4: "h4",
      p: "p",
      a: "a",
      h3: "h3",
      code: "code",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      EndpointCard,
      EndpointLeftColumn,
      EndpointRequirements,
      EndpointParameters,
      EndpointParameter,
      EndpointRightColumn,
      EndpointExample,
      Endpoint
    } = _components;
  if (!Endpoint) _missingMdxReference("Endpoint", true);
  if (!EndpointCard) _missingMdxReference("EndpointCard", true);
  if (!EndpointExample) _missingMdxReference("EndpointExample", true);
  if (!EndpointLeftColumn) _missingMdxReference("EndpointLeftColumn", true);
  if (!EndpointParameter) _missingMdxReference("EndpointParameter", true);
  if (!EndpointParameters) _missingMdxReference("EndpointParameters", true);
  if (!EndpointRequirements) _missingMdxReference("EndpointRequirements", true);
  if (!EndpointRightColumn) _missingMdxReference("EndpointRightColumn", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Initiate an integration with OAuth 2.0"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Initiating OAuth access is the first step towards allowing users to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/docs/faq/installing-an-app",
            children: "install your app"
          }), " in their HubSpot account(s)."]
        }), (0, _jsxRuntime.jsx)(_components.h3, {
          children: "Initiating OAuth access"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Before you can initiate an OAuth connection, you have to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/docs/faq/how-do-i-create-an-app-in-hubspot",
            children: "create a HubSpot app"
          }), ". The client ID that you'll need to include in the authorization URL can be found in the app settings. Navigate to these settings by clicking the name of your app from your developer account dashboard."]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "To initiate OAuth access, you'll need to send HubSpot users to your authorization URL. Use the query parameters detailed below to identify your app and outline its required scopes to users who land on the authorization page."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Users must be signed into HubSpot to grant your app access. Any user that isn't logged into HubSpot will be directed to a login screen before being directed back to the authorization page. The authorization screen will show the details for your app and the permissions being requested (based on the scopes you include in the URL). Users will have the option to select the Hub ID for the account they wish to grant access to."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["After the user grants access, they will be redirected to the specified ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "redirect_uri."
          }), " A code query parameter will be appended to the URL, which you'll use to get an ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#get-oauth-2.0-access-and-refresh-tokens",
            children: "access token from HubSpot"
          }), "."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "client_id",
            required: true,
            type: "Query",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The client ID from ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.hubspot.com/docs/faq/how-do-i-create-an-app-in-hubspot",
                children: "your app settings"
              }), "."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "redirect_uri",
            required: true,
            type: "Query",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The URL visitors will be redirected to after granting access to your app. Please note: For security reasons, this URL ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "must"
              }), " use https in production. When testing using localhost, http ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "can"
              }), " be used. Also, you ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "must"
              }), " use a domain, as IP addresses are not supported."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "scope",
            required: true,
            type: "Query",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["A space-separated set of scopes that your app will need access to. Scopes listed in this parameter will be treated as required for your app, and the user will see an error if they select an account that does not have access to the scope you've included.Any scopes that you have checked in your ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "https://developers.hubspot.com/docs/faq/how-do-i-create-an-app-in-hubspot",
                children: "app settings"
              }), " will be treated as required scopes, and you'll need to include any selected scopes in this parameter or the authorization page will display an error.See below for more details about which APIs are accessed by specific scopes."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "optional_scope",
            required: false,
            type: "Query",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["A space separated set of scopes that are optional for your app. Optional scopes will be automatically dropped from the authorization request if the user selects a HubSpot account that does not have access to that tool (such as requesting the social scope on a CRM only portal). If you're using optional scopes, you will need to check the ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/doc/methods/oauth2/get-access-token-information",
                children: "access token"
              }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/doc/methods/oauth2/get-refresh-token-information",
                children: "refresh token"
              }), " to see which scopes were granted. See the table below for more details about scopes."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "state",
            required: false,
            type: "Query",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["A string value that can be used to maintain the state of the user when they are redirected back to your application. If this parameter is included in the authorization URL, the value will be included in a ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "state"
              }), " query parameter when the user is directed to the ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "redirect_uri"
              }), "."]
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgQXV0aG9yaXphdGlvbiBVUkw6ClNlbmRpbmcgYSB1c2VyIHRvIHRoaXMgVVJMIHdpbGwgYXNrIHRoZSB1c2VyIHRvIGFwcHJvdmUgYWNjZXNzIHRvIGNvbnRhY3RzIGFuZCB3b3JrZmxvd3M6Cmh0dHBzOi8vYXBwLmh1YnNwb3QuY29tL29hdXRoL2F1dGhvcml6ZT9jbGllbnRfaWQ9eHh4eHh4eHgteHh4eC14eHh4LXh4eHgteHh4eHh4eHh4eHh4JnNjb3BlPWNvbnRhY3RzJTIwYXV0b21hdGlvbiZyZWRpcmVjdF91cmk9aHR0cHM6Ly93d3cuZXhhbXBsZS5jb20vCgpJZiB0aGV5IGdyYW50IGFjY2VzcywgdGhlIHVzZXIgd291bGQgYmUgcmVkaXJlY3RlZCB0byB0aGlzIFVSTDoKaHR0cHM6Ly93d3cuZXhhbXBsZS5jb20vP2NvZGU9eHh4eAoKSWYgdGhlcmUgYXJlIGFueSBwcm9ibGVtcyB3aXRoIHRoZSBhdXRob3JpemF0aW9uLCB5b3UnbGwgZ2V0IHRoZSBlcnJvciBwYXJhbWV0ZXJzIGluc3RlYWQgb2YgdGhlIGNvZGU6Cmh0dHBzOi8vd3d3LmV4YW1wbGUuY29tLz9lcnJvcj1lcnJvcl9jb2RlJmVycm9yX2Rlc2NyaXB0aW9uPUh1bWFuJTIwcmVhZGFibGUlMjBkZXNjcmlwdGlvbiUyMG9mJTIwdGhlJTIwZXJyb3I="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Get Information for OAuth 2.0 Access Token"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/oauth/v1/access-tokens/:token"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Get the meta data for an access token. This can be used to get the email address of the HubSpot user that the token was created for, as well as the Hub ID that the token is associated with."
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Note: HubSpot access tokens will fluctuate in size as we change the information that is encoded the tokens. We recommend allowing for tokens to be up to 300 characters to account for any changes we may make."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsx)(EndpointParameters, {
          children: (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "token",
            required: true,
            type: "Path",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The access token that you want to get the information for."
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgR0VUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9vYXV0aC92MS9hY2Nlc3MtdG9rZW5zL0NKU1A1cWYxS2hJQ0FRRVlzLWdESUlHT0JpaTFoUUl5R1FBZjN4QkttbHdIalg3T0lwdUlGRWF2QjItcVlBR1FzRjQKCgpFeGFtcGxlIFJlc3BvbnNlOgp7CiAgInRva2VuIjogIkNKU1A1cWYxS2hJQ0FRRVlzLWdESUlHT0JpaTFoUUl5R1FBZjN4QkttbHdIalg3T0lwdUlGRWF2QjItcVlBR1FzRjQiLAogICJ1c2VyIjogInRlc3RAaHVic3BvdC5jb20iLAogICJodWJfZG9tYWluIjogImRlbW8uaHViYXBpLmNvbSIsCiAgInNjb3BlcyI6IFsKICAgICJjb250YWN0cyIsCiAgICAiYXV0b21hdGlvbiIsCiAgICAib2F1dGgiCiAgXSwKICAiaHViX2lkIjogNjI1MTUsCiAgImFwcF9pZCI6IDQ1NiwKICAiZXhwaXJlc19pbiI6IDIxNTg4LAogICJ1c2VyX2lkIjogMTIzLAogICJ0b2tlbl90eXBlIjogImFjY2VzcyIKfQ=="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Get Information for OAuth 2.0 Refresh Token"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "GET",
          path: "/oauth/v1/refresh-tokens/:token"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Get the meta data for a refresh token. This can be used to get the email address of the HubSpot user that the token was created for, as well as the Hub ID that the token is associated with."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Note:"
          }), " For a more elaborate walkthrough on initiating Oauth, ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://developers.hubspot.com/docs/methods/oauth2/oauth2-quickstart",
            children: "visit this link"
          }), "."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsx)(EndpointParameters, {
          children: (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "token",
            required: true,
            type: "Path",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The refresh token that you want to get the information for."
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgR0VUIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9vYXV0aC92MS9yZWZyZXNoLXRva2Vucy94eHh4eHh4eC14eHh4LXh4eHgteHh4eC14eHh4eHh4eHh4eHgKCgpFeGFtcGxlIFJlc3BvbnNlOgp7CiAgInRva2VuIjogInh4eHh4eHh4LXh4eHgteHh4eC14eHh4LXh4eHh4eHh4eHh4eCIsCiAgInVzZXIiOiAidGVzdEBodWJzcG90LmNvbSIsCiAgImh1Yl9kb21haW4iOiAiZGVtby5odWJhcGkuY29tIiwKICAic2NvcGVzIjogWwogICAgImF1dG9tYXRpb24iLAogICAgImNvbnRhY3RzIiwKICAgICJvYXV0aCIKICBdLAogICJodWJfaWQiOiA2MjUxNSwKICAiY2xpZW50X2lkIjogInl5eXl5eXl5LXl5eXkteXl5eS15eXl5LXl5eXl5eXl5eXl5eSIsCiAgInVzZXJfaWQiOiAxMjMsCiAgInRva2VuX3R5cGUiOiAicmVmcmVzaCIKfQ=="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Get OAuth 2.0 access and refresh tokens"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/oauth/v1/token"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Use the code you get after a ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#initiate-an-integration-with-oauth-2.0",
            children: "user authorizes your app"
          }), " to get an access token and refresh token. The access token will be used to authenticate requests that your app makes. Access tokens are short lived, so you can use the refresh token to ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#refresh-oauth-2.0-access-token",
            children: "get a new access token"
          }), " when the current access token expires."]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Note: HubSpot access tokens will fluctuate in size as we change the information that is encoded. We recommend allowing for tokens to be up to 300 characters to account for any changes."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "grant_type",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The grant type of the request, must be authorization_code for the initial request to get the access and refresh tokens."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "client_id",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The Client ID of your app."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "client_secret",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The Client Secret of your app."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "redirect_uri",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["The redirect URI that was used when the user authorized your app. This must ", (0, _jsxRuntime.jsx)("u", {
                children: "exactly match"
              }), " the redirect_uri used when ", (0, _jsxRuntime.jsx)(_components.a, {
                href: "/doc/methods/oauth2/initiate-oauth-integration",
                children: "initiating the OAuth 2.0 connection"
              }), "."]
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "code",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The code parameter returned to your redirect URI when the user authorized your app."
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "ClBPU1QgVVJMOgpodHRwczovL2FwaS5odWJhcGkuY29tL29hdXRoL3YxL3Rva2VuCkhlYWRlcnM6CkNvbnRlbnQtVHlwZTogYXBwbGljYXRpb24veC13d3ctZm9ybS11cmxlbmNvZGVkO2NoYXJzZXQ9dXRmLTgKRGF0YToKZ3JhbnRfdHlwZT1hdXRob3JpemF0aW9uX2NvZGUmY2xpZW50X2lkPXh4eHh4eHh4LXh4eHgteHh4eC14eHh4LXh4eHh4eHh4eHh4eCZjbGllbnRfc2VjcmV0PXl5eXl5eXl5LXl5eXkteXl5eS15eXl5LXl5eXl5eXl5eXl5eSZyZWRpcmVjdF91cmk9aHR0cHM6Ly93d3cuZXhhbXBsZS5jb20vJmNvZGU9enp6enp6enotenp6ei16enp6LXp6enotenp6enp6enp6enp6CgpJZiBzdWNjZXNzZnVsLCB5b3Ugd2lsbCByZWNlaXZlIGEgSlNPTiByZXNwb25zZSB3aXRoIHRoZSB0b2tlbnM6CnsKICAiYWNjZXNzX3Rva2VuIjogInh4eHgiLAogICJyZWZyZXNoX3Rva2VuIjogInl5eXl5eXl5LXl5eXkteXl5eS15eXl5LXl5eXl5eXl5eXl5eSIsCiAgImV4cGlyZXNfaW4iOiAyMTYwMAp9CgpJZiB0aGVyZSBhcmUgYW55IHByb2JsZW1zIHdpdGggdGhlIHJlcXVlc3QsIHlvdSdsbCByZWNlaXZlIGEgNDAwIHJlc3BvbnNlIHdpdGggYW4gZXJyb3IgbWVzc2FnZS4KewogICJzdGF0dXMiOiAiRVhBTVBMRV9FUlJPUl9DT0RFIiwKICAibWVzc2FnZSI6ICJBIGh1bWFuIHJlYWRhYmxlIGVycm9yIG1lc3NhZ2UiLAogICJjb3JyZWxhdGlvbklkIjogImRhMWUxZDJmLWZhNmItNDcyYS1iZTdiLTdhYjliOTYwNWQ1OSIsCiAgInJlcXVlc3RJZCI6ICJlY2MwYjUwNjU5ODE0ZjdjYTM3ZjVkNDljZGY5Y2JkMyIKfQ=="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Refresh OAuth 2.0 access token"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "POST",
          path: "/oauth/v1/token"
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Use a ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#get-oauth-2.0-access-and-refresh-tokens",
            children: "previously obtained refresh token"
          }), " to generate a new access token. Access tokens are short lived; you can check the ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "expires_in"
          }), " parameter when generating an access token to determine its lifetime (in seconds). If you need offline access to HubSpot data, store the refresh token you get when ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#initiate-an-integration-with-oauth-2.0",
            children: "initiating your OAuth integration"
          }), " and use it to generate a new access token once the initial one expires."]
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Note: HubSpot access tokens will fluctuate in size as we change the information that is encoded the tokens. We recommend allowing for tokens to be up to 300 characters to account for any changes we may make."
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsxs)(EndpointParameters, {
          children: [(0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "grant_type",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The grant type of the request, must be refresh_token when refreshing an access token."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "client_id",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The client ID of your app."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "client_secret",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The client secret of your app."
            })
          }), (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "refresh_token",
            required: true,
            type: "Body",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The refresh token obtained when initially authenticating your OAuth integration."
            })
          })]
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "ClBPU1QgVVJMOgpodHRwczovL2FwaS5odWJhcGkuY29tL29hdXRoL3YxL3Rva2VuCkhlYWRlcnM6CkNvbnRlbnQtVHlwZTogYXBwbGljYXRpb24veC13d3ctZm9ybS11cmxlbmNvZGVkO2NoYXJzZXQ9dXRmLTgKRGF0YToKZ3JhbnRfdHlwZT1yZWZyZXNoX3Rva2VuJmNsaWVudF9pZD14eHh4eHh4eC14eHh4LXh4eHgteHh4eC14eHh4eHh4eHh4eHgmY2xpZW50X3NlY3JldD15eXl5eXl5eS15eXl5LXl5eXkteXl5eS15eXl5eXl5eXl5eXkmcmVmcmVzaF90b2tlbj16enp6enp6ei16enp6LXp6enotenp6ei16enp6enp6enp6enoKCklmIHN1Y2Nlc3NmdWwsIHlvdSB3aWxsIHJlY2VpdmUgYSBKU09OIHJlc3BvbnNlIHdpdGggYSBuZXcgYWNjZXNzX3Rva2VuOgp7CiAgImFjY2Vzc190b2tlbiI6ICJ4eHh4IiwKICAicmVmcmVzaF90b2tlbiI6ICJ6enp6enp6ei16enp6LXp6enotenp6ei16enp6enp6enp6enoiLAogICJleHBpcmVzX2luIjogMjE2MDAKfQoKSWYgdGhlcmUgYXJlIGFueSBwcm9ibGVtcyB3aXRoIHRoZSByZXF1ZXN0LCB5b3UnbGwgcmVjZWl2ZSBhIDQwMCByZXNwb25zZSB3aXRoIGFuIGVycm9yIG1lc3NhZ2UuCnsKICAic3RhdHVzIjogIkVYQU1QTEVfRVJST1JfQ09ERSIsCiAgIm1lc3NhZ2UiOiAiQSBodW1hbiByZWFkYWJsZSBlcnJvciBtZXNzYWdlIiwKICAiY29ycmVsYXRpb25JZCI6ICJkYTFlMWQyZi1mYTZiLTQ3MmEtYmU3Yi03YWI5Yjk2MDVkNTkiLAogICJyZXF1ZXN0SWQiOiAiZWNjMGI1MDY1OTgxNGY3Y2EzN2Y1ZDQ5Y2RmOWNiZDMiCn0="
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(EndpointCard, {
      children: [(0, _jsxRuntime.jsxs)(EndpointLeftColumn, {
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Delete OAuth 2.0 Refresh Token"
        }), (0, _jsxRuntime.jsx)(Endpoint, {
          method: "DELETE",
          path: "/oauth/v1/refresh-tokens/:token"
        }), (0, _jsxRuntime.jsx)(_components.p, {
          children: "Deletes a refresh token. You can use this to delete your refresh token if a user uninstalls your app."
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Note:"
          }), " This will only delete the refresh token. Access tokens generated with the refresh token will not be affected."]
        }), (0, _jsxRuntime.jsxs)(_components.p, {
          children: [(0, _jsxRuntime.jsx)(_components.strong, {
            children: "Note:"
          }), " This will not uninstall an application from a HubSpot account or inhibit data syncing between an account and a connected application."]
        }), (0, _jsxRuntime.jsx)(EndpointRequirements, {}), (0, _jsxRuntime.jsx)(EndpointParameters, {
          children: (0, _jsxRuntime.jsx)(EndpointParameter, {
            name: "token",
            required: true,
            type: "Path",
            children: (0, _jsxRuntime.jsx)(_components.p, {
              children: "The refresh token that you want to delete."
            })
          })
        })]
      }), (0, _jsxRuntime.jsx)(EndpointRightColumn, {
        children: (0, _jsxRuntime.jsx)(EndpointExample, {
          children: (0, _jsxRuntime.jsx)(_components.p, {
            children: "CkV4YW1wbGUgREVMRVRFIFVSTDoKaHR0cHM6Ly9hcGkuaHViYXBpLmNvbS9vYXV0aC92MS9yZWZyZXNoLXRva2Vucy94eHh4eHh4eC14eHh4LXh4eHgteHh4eC14eHh4eHh4eHh4eHgKClRoaXMgcmVxdWVzdCB3aWxsIHJldHVybiBhIDIwNCByZXNwb25zZSB3aGVuIHRoZSBkZWxldGlvbiBpcyBzdWNjZXNzZnVsLg=="
          })
        })
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}