"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844611096;
const slug = exports.slug = 'reference/cms/modules/using-modules-in-templates';
const title = exports.title = 'Verwenden von Modulen in Vorlagen';
const name = exports.name = '[neu] Verwenden von Modulen in Vorlagen';
const metaDescription = exports.metaDescription = 'Module sind bearbeitbare Bereiche von HubSpot-Seiten oder -E-Mails. Sie können Module mit dem Drag-&-Drop-Vorlagendesigner zu einem Vorlagenlayout hinzufügen, können diese aber auch mit HubL erstellen. HubL-Module können in einer Code-Vorlagendatei, in einem HubL-Vorlagenmodul oder in einem Blog-Inhaltsmodul definiert werden. Im Folgenden finden Sie eine umfassende Liste der Module und ihrer Parameter.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "grundlegende-modul-syntax",
  "label": "Grundlegende Modul-Syntax",
  "parent": null
}, {
  "depth": 0,
  "id": "%C3%BCbergabe-von-dictionarys-an-modulparameter",
  "label": "Übergabe von Dictionarys an Modulparameter",
  "parent": null
}, {
  "depth": 1,
  "id": "%C3%BCbergabe-von-feldern-mit-parametern%2C-denen-drag-%26-drop-bereiche-zugeordnet-sind",
  "label": "Übergabe von Feldern mit Parametern, denen Drag-&-Drop-Bereiche zugeordnet sind",
  "parent": "%C3%BCbergabe-von-dictionarys-an-modulparameter"
}, {
  "depth": 0,
  "id": "festlegen-von-standardwerten-auf-vorlagenebene-f%C3%BCr-felder",
  "label": "Festlegen von Standardwerten auf Vorlagenebene für Felder",
  "parent": null
}, {
  "depth": 1,
  "id": "festlegen-von-standardwerten-f%C3%BCr-verschachtelte-feldgruppen",
  "label": "Festlegen von Standardwerten für verschachtelte Feldgruppen",
  "parent": "festlegen-von-standardwerten-auf-vorlagenebene-f%C3%BCr-felder"
}, {
  "depth": 1,
  "id": "festlegen-von-standardwerten-f%C3%BCr-wiederholfelder",
  "label": "Festlegen von Standardwerten für Wiederholfelder",
  "parent": "festlegen-von-standardwerten-auf-vorlagenebene-f%C3%BCr-felder"
}, {
  "depth": 1,
  "id": "festlegen-von-standardwerten-f%C3%BCr-sich-wiederholende-feldgruppen",
  "label": "Festlegen von Standardwerten für sich wiederholende Feldgruppen",
  "parent": "festlegen-von-standardwerten-auf-vorlagenebene-f%C3%BCr-felder"
}, {
  "depth": 1,
  "id": "festlegen-von-standardwerten-f%C3%BCr-stilfelder",
  "label": "Festlegen von Standardwerten für Stilfelder",
  "parent": "festlegen-von-standardwerten-auf-vorlagenebene-f%C3%BCr-felder"
}, {
  "depth": 0,
  "id": "block-syntax",
  "label": "Block-Syntax",
  "parent": null
}, {
  "depth": 0,
  "id": "content_attribute",
  "label": "content_attribute",
  "parent": null
}, {
  "depth": 0,
  "id": "f%C3%BCr-alle-module-verf%C3%BCgbare-parameter",
  "label": "Für alle Module verfügbare Parameter",
  "parent": null
}, {
  "depth": 0,
  "id": "feldbasierte-parameter",
  "label": "Feldbasierte Parameter",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      strong: "strong",
      pre: "pre",
      h3: "h3",
      img: "img",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      em: "em"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      DndSection,
      DndModule,
      Tabs,
      Tab
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Verwenden von Modulen in Vorlagen"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Module können entweder direkt zu einer Vorlage oder zu einzelnen Seiten mit ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/templates/drag-and-drop/overview",
        children: "Drag- & -Drop-Bereichen"
      }), " und flexiblen Spalten hinzugefügt werden. Wird ein Modul zu einer Vorlage hinzugefügt, wird das Modul standardmäßig an diesem Ort angezeigt. Module in Drag-&-Drop-Bereichen und flexiblen Spalten können verschoben und entfernt und um sie herum können weitere Module hinzugefügt werden. Dies sind Modulinstanzen."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Nachdem ein Modul definiert wurde, können Sie seine Feldwerte auf Vorlagenebene über das ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/export-to-template-context#retrieving-parameters-from-modules-already-rendered-on-the-template",
        children: "content.widgets -Dictionary"
      }), " abrufen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Grundlegende Modul-Syntax"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubLTags werden durch ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{% %}"
      }), " getrennt und müssen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), ", einen eindeutigen Namen und den Design-Manager-Pfad des Moduls angeben. Ein Modul kann auch Parameter für zusätzliche Einstellungen enthalten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Modulname:"
        }), " gibt dem Modul eine eindeutige Identität im Kontext der Vorlage.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Der Name muss in Anführungszeichen nach dem Typ des Moduls stehen und Unterstriche anstelle von Leerzeichen oder Bindestrichen verwenden."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Dieser Name wird verwendet, um den im Seiten-/E-Mail-Editor festgelegten Inhalt mit dem entsprechenden HubL-Modul-Tag abzugleichen. Wenn Sie z. B. ein HubL-Modul-Tag mit demselben Namen in zwei verschiedenen Bereichen einer Vorlage kodieren, können Benutzer nur ein Modul im Editor bearbeiten, aber Änderungen an diesem Modul werden an beiden Stellen übernommen."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Pfad:"
        }), " definiert je nach Tag den Ort, an dem sich das Modul im Design-Manager befindet.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "/"
            }), " steht für das Stammverzeichnis des aktuellen Laufwerks;"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "./"
            }), " steht für das aktuelle Verzeichnis;"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "../"
            }), " steht für das übergeordnete Verzeichnis des aktuellen Verzeichnisses."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Der Pfad für ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/default-modules",
          children: "HubSpot-Standardmodule"
        }), " beginnt immer mit ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "@hubspot/"
        }), ", gefolgt vom Typ des Moduls."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Parameter:"
        }), " zusätzliche Einstellungen für die Modulinstanz, die ihr Verhalten und die Art und Weise, wie sie gerendert wird, angeben. Enthält Standardwerte auf Vorlagenebene für Modulfelder.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Parameter sind durch Kommas getrennte Schlüssel-Wert-Paare."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Parameter haben verschiedene Typen, darunter Zeichenfolge, boolesch, ganze Zahl, Enumeration und JSON-Listenobjekt. Zeichenfolgenparameterwerte müssen in einfachen oder doppelten Anführungszeichen stehen, während boolesche Parameter keine Anführungszeichen um ihre ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), "- oder ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            }), "-Werte erfordern. Erfahren Sie mehr über die ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/modules/using-modules-in-templates#parameters-available-for-all-modules",
              children: "Parameter, die für alle Module verfügbar sind"
            }), "."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Beachten Sie, dass es im Vergleich zu den Feldeinstellungen des Moduls keine Überprüfung im Editor für Feldwerte gibt. Wenn beispielsweise ein Modul ein Zahlenfeld hat, dessen Mindestwert auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            }), " gesetzt ist, und Sie dem Parameter eine ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "0"
            }), " übergeben, wird keine Warnung angezeigt, dass der Wert ungültig ist."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{# Basic syntax #}\n{% module \"unique_module_name\" path=\"@hubspot/module_type\",\n  parameterString='String parameter value',\n  parameterBoolean=True\n%}\n\n{# Sample of a default HubSpot text module #}\n{% module \"job_title\" path=\"@hubspot/text\",\n  label=\"Job Title\",\n  value=\"Chief Morale Officer\"\n%}\n\n{# Sample of a custom module #}\n{% module \"faqs\" path=\"/myWebsite/modules/faq_module\",\n  label=\"Custom FAQ Module\"\n  faq_group_title=\"Commonly Asked Questions\"\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Übergabe von Dictionarys an Modulparameter"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei Modulen mit Feldern, die Dictionarys erwarten, können Sie diese wie andere Parameter übergeben. Wenn es für Sie übersichtlicher ist oder Sie die Werte wiederverwenden wollen, können Sie das Dictionary auf eine Variable festlegen und die Variable stattdessen an den Parameter übergeben."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module \"social_buttons\",\n  path=\"@hubspot/social_sharing\",\n  email={\n    \"default\": true,\n    \"enabled\": false,\n    \"img_src\": \"https://...\"\n  }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Übergabe von Feldern mit Parametern, denen Drag-&-Drop-Bereiche zugeordnet sind"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/dnd-areas",
        children: "Drag-&-Drop-Tags"
      }), ", z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_area"
      }), ", enthalten eine Reihe von Standardparametern, z. B. ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "width"
      }), ". Während der Design-Manager verhindert, dass Sie neue Felder erstellen, die einen dieser reservierten Parameter verwenden, können Module, die vor der Einführung von Drag-&-Drop-Tags erstellt wurden, bereits einen reservierten Parameter verwenden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um dies zu beheben, können Sie den ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields"
      }), "-Parameter verwenden. Genau wie bei der Übergabe von Felddaten an eine Gruppe können Sie den Feldnamen als Schlüssel für das ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields"
      }), "-Objekt übergeben. Sein Wert muss mit dem Format übereinstimmen, das der Feldtyp erwartet."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_area \"main_content\"%}\n     {% dnd_section %}\n        {% dnd_column %}\n          {% dnd_row %}\n            {% dnd_module path=\"@hubspot/divider\",\n               fields={width: \"90\"}\n            %}\n            {% end_dnd_module %}\n        {% end_dnd_row %}\n      {%end_dnd_column%}\n    {% end_dnd_section %}\n  {% end_dnd_area %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Festlegen von Standardwerten auf Vorlagenebene für Felder"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Standardwerte für Modulfelder auf Vorlagenebene festlegen, indem Sie Parameter in die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "dnd_module"
      }), "-Tags einbeziehen. Im Folgenden erfahren Sie, wie Sie Standardfeldwerte in verschachtelten Feldgruppen, Wiederholungsfeldern, Wiederholfeldgruppen und Stilfeldern festlegen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Festlegen von Standardwerten für verschachtelte Feldgruppen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Unten sehen Sie ein Beispiel für ein benutzerdefiniertes Drag-&-Drop-Modul mit einer  benutzerdefinierten ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "style"
      }), "-Feldgruppe, die andere verschachtelte Feldgruppen enthält. Vergleichen Sie die Konfiguration auf Vorlagenebene mit der Darstellung der gleichen Gruppierung im Design-Manager."]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{% dnd_module\n path=\"/Nested_Module.module\"\n style={\n  \"group\":{\n  \"section\":{\n   \"color_field\":{\n   \"color\" : \"#000\",\n   \"opacity\" : 100\n    }\n   }\n  }\n }\n%}\n{% end_dnd_module %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023/multi-level%20field%20nesting%20.png",
            alt: "Feldverschachtelung mit mehreren Ebenen"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Festlegen von Standardwerten für Wiederholfelder"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können Standardwerte auf Vorlagenniveau für sich Wiederholfelder festlegen, indem Sie ein Array an den Parameter des Feldes übergeben. Die Elemente des Arrays müssen in dem Format vorliegen, das aufgrund des ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#field-based-parameters",
        children: "Feldtyps"
      }), " erwartet wird. Zum Beispiel:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Ein einfaches Textfeld erwartet nur einen String"
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Ein Bildwiederholfeld erwartet ein Bildfeldobjekt. Dies gilt auch für ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#field-based-parameters",
          children: "alle anderen Feldtypen"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module path='../modules/recipe_card.module',\n  ingredients=[\"Eggs\",\"Ham\",\"Cheese\"]\n%}\n\n{% module \"my_repeater_module\" path=\"/img_repeater_module\", label=\"img_repeater_module\",\nimage=[\n  {\n    \"src\" : \"https://cdn2.hubspot.net/hubfs/428357/Developer%20Site/assets/logo/Developers-LOGO.svg\",\n    \"alt\" : \"HubSpot Developers\",\n    \"width\" : 254,\n    \"height\" : 31\n  },{\n    \"src\" : \"https://www.hubspot.com/hs-fs/hub/53/file-733888614-jpg/assets/hubspot.com/about/management/dharmesh-home.jpg\",\n    \"alt\" : \"Dharmesh\",\n    \"width\" : 394,\n    \"height\" : 394\n  }\n]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Festlegen von Standardwerten für sich wiederholende Feldgruppen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Bei Modulen, die sich wiederholende Gruppen von Feldern enthalten – z. B. bei einem Diashow-Modul oder einem FAQ-Modul – kann für diese Gruppen ein Standardwert auf Vorlagenebene festgelegt werden. Dazu übergeben Sie ein Array von Objekten an den Parameter Ihrer Feldgruppe. Die Schlüssel- und Wertepaare des Objekts sind die Feldnamen und ihre Werte."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module path='../modules/slideshow.module',\n  slides=[\n    {\n      \"caption\":\"Cute dog looking up\",\n      \"image_url\":\"http://example.com/image.jpg\",\n    },\n    {\n      \"caption\":\"Cuter cat not looking amused\",\n      \"image_url\":\"http://example.com/image2.jpg\",\n    }\n  ]\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Festlegen von Standardwerten für Stilfelder"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Sie können mit dem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "styles"
      }), "-Parameter explizit Standardwerte für ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "Stilfelder"
      }), " festlegen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Dies funktioniert genau wie bei anderen Gruppen, bei denen der Parameter der Name der Gruppe ist. Sie übergeben diesem Parameter ein Objekt mit allen Feldern, die Sie fenstlegen möchten."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% dnd_module\n    path=\"./path/to/module\",\n    styles={\n      \"background_color\":{\n          \"color\":\"#123\",\n          \"opacity\":50\n       }\n    }\n%}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Block-Syntax"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Während die meisten Module über Parameter verfügen, die Standardinhalte steuern, kann es Situationen geben, in denen Sie große Codeblöcke zum Standardinhalt eines Moduls hinzufügen müssen. Sie können zum Beispiel einen großen HTML-Block als Standardinhalt für ein Rich-Text- oder HTML-Modul einfügen. Anstatt zu versuchen, diesen Code in einen Wertparameter zu schreiben, können Sie die HubL-Block-Syntax verwenden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% module_block module \"my_rich_text_module\" path=\"/My Rich Text Field Module\",\n  label=\"My Rich Text Field Module\"\n%}\n    {% module_attribute \"rich_text_field_variable\" %}\n       <div>My HTML block</div>\n    {% end_module_attribute %}\n{% end_module_block %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Vor der ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "module_block"
        }), "-Syntax wurde ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), " verwendet. Sie folgt dem gleichen Muster, aber die öffnenden Tags waren ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_attribute"
        }), ". Schließende Tags waren ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "end_widget_attribute"
        }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "end_widget_block"
        }), "."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "widget_block"
        }), "-Syntax ist veraltet, aber Sie müssen den alten Code nicht zu aktualisieren."]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der Parameter, der unmittelbar auf ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_block"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " (veraltet) folgt, ist der Parameter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In fast allen unseren Dokumentationen werden Sie feststellen, dass wir ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module"
      }), " verwenden. ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "V2 HubSpot-Module"
      }), " sind normale Module, wie Sie sie erstellen können. Daher besteht keine Notwendigkeit mehr, einen anderen ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), " zu verwenden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Während ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " veraltet ist, sollten Sie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "module_block"
      }), " verwenden. Wenn Sie eine Website von einem anderen Entwickler geerbt haben, kann sie alten Code mit ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "widget_block"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), " enthalten."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Der ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "type_of_module"
      }), " unterstützt V1 HubSpot-Modulnamen, zum Beispiel: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rich_text"
      }), " oder ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "raw_html"
      }), ". In der ersten Zeile von HubL können zusätzliche Parameter hinzugefügt werden. In der zweiten Zeile wird festgelegt, auf welchen Parameter die Inhalte des Blocks angewendet werden sollen. Bei einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "rich_text"
      }), "-Modul sollte dies beispielsweise der HTML-Parameter sein. Bei einem ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "raw_html"
      }), "-Modul wäre dies der Wertparameter (siehe beide Beispiele unten)."]
    }), "\n", (0, _jsxRuntime.jsxs)(Tabs, {
      defaultSelected: "0",
      children: [(0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "HubL",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-hubl",
            children: "{# widget_block is deprecated, use module_block instead. This example is only to\nexplain what type_of_module was used for, for those with legacy code. #}\n{% widget_block rich_text \"my_rich_text_module\" overrideable=True, label='My rich-text module'  %}\n        {% widget_attribute \"html\" %}\n            <h2>New Module</h2>\n            <p>Add content here.</p>\n        {% end_widget_attribute %}\n{% end_widget_block %}\n"
          })
        })
      }), (0, _jsxRuntime.jsx)(Tab, {
        tabId: "1",
        title: "Beispielausgabe",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-html",
            children: "<span\n  id=\"hs_cos_wrapper_my_rich_text_module\"\n  class=\"hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text\"\n  style=\"\"\n  data-hs-cos-general-type=\"widget\"\n  data-hs-cos-type=\"rich_text\"\n>\n  <h2>New Module</h2>\n  <p>Add content here.</p>\n</span>\n"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "content_attribute"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Neben der regulären und der Block-Syntax gibt es bestimmte Fälle, in denen Sie einen großen Block Standardinhalt für eine vordefinierte Inhaltsvariable angeben möchten. Das häufigste Beispiel hierfür ist die Variable ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/variables#email-variables",
        children: "content.email_body"
      }), ". Diese Variable druckt einen Standard-E-Mail-Text, der im Content-Editor geändert werden kann. Da es sich nicht um ein Standard-HubL-Modul handelt, verwenden wir ein ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "content_attribute"
      }), "-Tag, um einen Block mit Standardinhalten anzugeben. Das folgende Beispiel zeigt die E-Mail-Textvariable, die mit einem Standard-Inhaltscodeblock gefüllt ist."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% content_attribute \"email_body\" %}\n        <p>Hi {{ contact.firstname }},</p>\n        <p>Describe what you have to offer the customer. Why should they read? What did you promise them in the subject line? Tell them something cool. Make them laugh. Make them cry. Well, maybe don't do that...</p>\n        <p>Use a list to:</p>\n        <ul>\n            <li>Explain the value of your offer</li>\n            <li>Remind the reader what they’ll get out of taking action</li>\n            <li>Show off your skill with bullet points</li>\n            <li>Make your content easy to scan</li>\n        </ul>\n        <p><a href=\"http://hubspot.com\">LINK TO A LANDING PAGE ON YOUR SITE</a> (This is the really important part.)</p>\n        <p>Now wrap it all up with a pithy little reminder of how much you love them.</p>\n        <p>Aw. You silver-tongued devil, you.</p>\n        <p>Sincerely,</p>\n        <p>Your name</p>\n{% end_content_attribute %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Für alle Module verfügbare Parameter"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine Module verfügen zwar über bestimmte ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "spezielle Parameter"
      }), ", die Parameter in der folgenden Liste werden jedoch von allen Modulen unterstützt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Standard"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Name des Moduls, der im Content-Editor angezeigt wird. Dieser Parameter kann auch verwendet werden, um dem Benutzer zusätzliche Anweisungen zu geben."
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "overrideable"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolescher Wert"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Steuert, ob das Modul im Content-Editor bearbeitet werden kann oder nicht. Dies entspricht der Einstellung ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "„Bearbeiten in Content-Editoren verhindern“"
            }), " im Design-Manager."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "no_wrapper"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolescher Wert"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Bei Festlegung auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " wird das Wrapping-Markup um den Inhalt eines Moduls entfernt. Auf Seiten sind Module immer in ein ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "<div>"
            }), " mit speziellen Klassen umhülltes Format eingeschlossen. Wenn Sie bei diesem Wrapping-Markup im Vorschaufenster auf das Modul klicken, blättert der Editor zu diesem Modul. Es kann Fälle geben, in denen Sie den Wrapper entfernen sollten, z. B. wenn Sie ein Textmodul verwenden möchten, um das Ziel eines ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "href"
            }), "-Attributs eines Anchor-Tags zu füllen."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "extra_classes"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Fügt Klassen zum Modul-Wrapper hinzu. Sie können mehrere Klassen hinzufügen, indem Sie die Klassen durch Leerzeichen trennen. Zum Beispiel:", (0, _jsxRuntime.jsx)(_components.code, {
              children: "extra_classes='full-width panel'"
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "export_to_template_context"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolescher Wert"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Bei Festlegung auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " sind die Parameter dieses Widgets im Vorlagenkontext verfügbar, anstatt den HTML-Code zu rendern. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/reference/cms/modules/export-to-template-context",
              children: "Erfahren Sie, wie Sie diesen Parameter und das widget_data-Tag verwenden."
            })]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "unique_in_loop"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolescher Wert"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Hängt den Modulnamen mit dem loop.index an, wenn das Modul innerhalb einer Schleife definiert ist. Bei Festlegung auf ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "True"
            }), " wird eine andere Version des Moduls innerhalb jeder Iteration der Schleife gedruckt. Hängt den Modulnamen mit dem loop.index an."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "False"
            })
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um eine vollständige Liste aller Modultypen und ihrer Parameter zu sehen, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/tags/standard-tags",
        children: "klicken Sie hier."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Feldbasierte Parameter"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Im Folgenden erfahren Sie mehr über die feldbasierten Modulparameter, die Sie verwenden können."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Feld"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beispiel"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Schlüssel"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Blog"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl (Blog-ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolescher Wert"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "True/false"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Auswahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"option_1\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Farbe"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"color\" : \"#ffffff\",  \"opacity\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Farbe", (0, _jsxRuntime.jsx)("br", {}), "6 Zeichen im hexadezimalen Format", (0, _jsxRuntime.jsx)("br", {}), "Deckkraft", (0, _jsxRuntime.jsx)("br", {}), "Ganzzahl 0 - 100 ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "CTA"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge (CTA-ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"fb9c0055-6beb-489d-8dda-3e1222458750\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Datum"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeitstempel"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1566360000000"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Datetime"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeitstempel"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1566360000000"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "E-Mail-Adresse"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array (E-Mail-Adresszeichenfolgen)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[\"develop@hubspot.com\", \"design@hubspot.com\"]"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Datei"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge (URL der Datei)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://cdn2.hubspot.net/hubfs/file.pdf\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Follow-up-E-Mail"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl (ID der Follow-up-E-Mail)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Schriftart"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"size\" : 12,  \"size_unit\" : \"px\",  \"color\" : \"#000\",  \"styles\" :{    \"text-decoration\" : \"underline\"  },  \"font\" : \"Alegreya\",  \"fallback\" : \"serif\",  \"variant\" : \"regular\",  \"font_set\" : \"GOOGLE\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Größe", (0, _jsxRuntime.jsx)("br", {}), "Schriftgröße ohne Einheitentyp ", (0, _jsxRuntime.jsx)("br", {}), "size_unit ", (0, _jsxRuntime.jsx)("br", {}), "Zeichenfolge Schriftgrößeneinheit", (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"px\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"pt\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"em\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"rem\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"%\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"ex\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"ch\""
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "Farbe ", (0, _jsxRuntime.jsx)("br", {}), "hexadezimale Farbcodezeichenfolge ", (0, _jsxRuntime.jsx)("br", {}), "Stile ", (0, _jsxRuntime.jsx)("br", {}), "unterstützte Eigenschaften \"font-weight\" ", (0, _jsxRuntime.jsx)("br", {}), "\"normal\" / \"bold\" ", (0, _jsxRuntime.jsx)("br", {}), "\"font-style\" ", (0, _jsxRuntime.jsx)("br", {}), "\"normal\" / \"italic\" ", (0, _jsxRuntime.jsx)("br", {}), "\"font-style\" ", (0, _jsxRuntime.jsx)("br", {}), "\"none\" / \"underline\" ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Formular"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"form_id\" : \"9aa2e5f3-a46d-4774-897e-0bc37478521c\",  \"response_type\" : \"redirect\",  \"redirect_url\" : \"http://www.hubspot.com\",  \"redirect_id\" : null,  \"form_type\" : \"HUBSPOT\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["form_id ", (0, _jsxRuntime.jsx)("br", {}), "Die ID des Formulars. ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/forms/find-your-form-guid",
              children: "So rufen Sie die ID eines Formulars ab."
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "response_type ", (0, _jsxRuntime.jsx)("br", {}), "\"redirect\" / \"inline\" ", (0, _jsxRuntime.jsx)("br", {}), "Meldung ", (0, _jsxRuntime.jsx)("br", {}), "Bei Verwendung von response_type \"inline\" angezeigte Meldung. Zeichenfolge, die HTML unterstützt. ", (0, _jsxRuntime.jsx)("br", {}), "redirect_url ", (0, _jsxRuntime.jsx)("br", {}), "Zeichenfolge, absolute URL zu einer Webseite ", (0, _jsxRuntime.jsx)("br", {}), "redirect_id ", (0, _jsxRuntime.jsx)("br", {}), "Seiten-/Beitrags-ID, zu der weitergeleitet werden soll ", (0, _jsxRuntime.jsx)("br", {}), "form_type ", (0, _jsxRuntime.jsx)("br", {}), "\"HUBSPOT\" / \"TICKET_FORM\" ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "HubDB-Tabelle"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl (HubDB-Tabellen-ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "123456789"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Symbol"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"name\" : \"align-center\",  \"unicode\" : \"f037\",  \"type\" : \"SOLID\"}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Name ", (0, _jsxRuntime.jsx)("br", {}), "Der Name des Symbols ", (0, _jsxRuntime.jsx)("br", {}), "Unicode ", (0, _jsxRuntime.jsx)("br", {}), "Das Unicode-Symbol für die Schriftart, aus der das Symbol stammt ", (0, _jsxRuntime.jsx)("br", {}), "Typ ", (0, _jsxRuntime.jsx)("br", {}), "Symbol-Stil. \"SOLID\" / \"REGULAR\" ", (0, _jsxRuntime.jsx)("br", {}), "Es wird empfohlen, ein Symbolfeld festzulegen und die Werte auf diese Weise anzuzeigen, um die Parameter richtig festzulegen."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Bild"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"src\" : \"https://cdn2.hubspot.net/hubfs/image.jpeg\",  \"alt\" : \"an_image\",  \"width\" : 100,  \"height\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["src ", (0, _jsxRuntime.jsx)("br", {}), "Bild-URL ", (0, _jsxRuntime.jsx)("br", {}), "alt ", (0, _jsxRuntime.jsx)("br", {}), "Bild-ALT-Text, der von Bildschirmlesern und Suchmaschinen verwendet wird ", (0, _jsxRuntime.jsx)("br", {}), "width ", (0, _jsxRuntime.jsx)("br", {}), "Die Breite, mit der das Bild angezeigt werden soll ", (0, _jsxRuntime.jsx)("br", {}), "height ", (0, _jsxRuntime.jsx)("br", {}), "Die Höhe, mit der das Bild angezeigt werden soll ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Link"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"url\" : {     \"type\" : \"EXTERNAL\",    \"href\" : \"www.hubspot.com\",    \"content_id\" : null   },  \"open_in_new_tab\" : false,  \"no_follow\" : false }"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["url ", (0, _jsxRuntime.jsx)("br", {}), "Objekt, das die URL data.type speichert ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"EXTERNAL\" für Nicht-HubSpot-URLs, die nicht per E-Mail verschickt werden"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"CONTENT\" für Seiten, Blog-Beiträge und Landingpages"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"FILE\" für Dateien, die in den Datei-Manager hochgeladen werden"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"EMAIL_ADDRESS\" für E-Mail-Adressen "
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"BLOG\" für Blog-Listing-Seiten"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "href ", (0, _jsxRuntime.jsx)("br", {}), "Die URL, zu der Sie verlinken. ", (0, _jsxRuntime.jsx)("br", {}), " ", (0, _jsxRuntime.jsx)("br", {}), "open_in_new_tab ", (0, _jsxRuntime.jsx)("br", {}), "\"true\"/\"false\", bestimmt, ob ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "target=\"_blank\""
            }), " hinzugefügt werden soll ", (0, _jsxRuntime.jsx)("br", {}), "no_follow ", (0, _jsxRuntime.jsx)("br", {}), "\"true\"/\"false\", bestimmt, ob ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "rel=\"nofollow\""
            }), " verwendet werden soll ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Logo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{  \"override_inherited_src\" : true,  \"src\" : \"https://cdn2.hubspot.net/hubfs/logo.png\",  \"alt\" : \"best_logo_ever\",  \"width\" : 100,  \"height\" : 100}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["override_inherited_src ", (0, _jsxRuntime.jsx)("br", {}), "true/false, überschreibt die Portalstandardwerte ", (0, _jsxRuntime.jsx)("br", {}), "src ", (0, _jsxRuntime.jsx)("br", {}), "Bild-URL ", (0, _jsxRuntime.jsx)("br", {}), "alt ", (0, _jsxRuntime.jsx)("br", {}), "ALT-Text, der von Bildschirmlesern und Suchmaschinen verwendet wird. ", (0, _jsxRuntime.jsx)("br", {}), "width", (0, _jsxRuntime.jsx)("br", {}), "Breite, mit der das Bild angezeigt werden soll", (0, _jsxRuntime.jsx)("br", {}), "height ", (0, _jsxRuntime.jsx)("br", {}), "Höhe, mit der das Bild angezeigt werden soll ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Meeting"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge (Meetinglink)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"https://app.hubspot.com/meetings/developers-r-kewl\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Menü"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl (Menü-ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "123456789"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Nummer"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Seite"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl (Seiten-ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "richtext"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge (kann HTML enthalten)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"# Hello, world! <RelatedApiLink />\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Salesforce-Kampagne"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge (Salesforce-Kampagnen-ID)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"7016A0000005S0tQAE\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Einfaches Menü"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Array von Menüeintragsobjekten"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "[   {     \"isPublished\" : true,    \"pageLinkId\" : 123456789,    \"pageLinkName\" : \"My page\",    \"isDeleted\" : false,    \"categoryId\" : 1,    \"subCategory\" : \"site_page\",    \"contentType\" : \"site_page\",    \"state\" : \"PUBLISHED_OR_SCHEDULED\",    \"linkLabel\" : \"This is a page\",    \"linkUrl\" : null,    \"linkParams\" : null,    \"linkTarget\" : null,    \"type\" : \"PAGE_LINK\",    \"children\" : [ ]  } ]"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["isPublished ", (0, _jsxRuntime.jsx)("br", {}), "True/false, ist die Seite des Menüeintrags veröffentlicht? ", (0, _jsxRuntime.jsx)("br", {}), "pageLinkId ", (0, _jsxRuntime.jsx)("br", {}), "Seiten-ID im CMS ", (0, _jsxRuntime.jsx)("br", {}), "pageLinkName ", (0, _jsxRuntime.jsx)("br", {}), "Der tatsächliche Name der Seite im CMS ", (0, _jsxRuntime.jsx)("br", {}), "isDeleted ", (0, _jsxRuntime.jsx)("br", {}), "true/false ", (0, _jsxRuntime.jsx)("br", {}), "categoryId ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "1 - Website-Seite"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "3 - Blog-Beitrag"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "subCategory ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "site_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "landing_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "blog"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "normal_blog_post"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "contentType ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "site_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "landing_page"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "blog"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "Status ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "DRAFT"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "DRAFT_AB"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED_OR_SCHEDULED"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "PUBLISHED_AB"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "SCHEDULED"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "linkLabel ", (0, _jsxRuntime.jsx)("br", {}), "Text, den der Benutzer liest und anklickt ", (0, _jsxRuntime.jsx)("br", {}), "linkUrl ", (0, _jsxRuntime.jsx)("br", {}), "Tatsächliche URL, zu der der Benutzer beim Anklicken weitergeleitet wird ", (0, _jsxRuntime.jsx)("br", {}), "linkParams ", (0, _jsxRuntime.jsx)("br", {}), "\\# Anzahl der Links oder ?-Abfrageparameter ", (0, _jsxRuntime.jsx)("br", {}), "linkTarget ", (0, _jsxRuntime.jsx)("br", {}), "Wenn „In neuem Tab öffnen“ aktiviert ist \"_blank“, sonst \"null\" ", (0, _jsxRuntime.jsx)("br", {}), "Typ ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"PAGE_LINK\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"PAGE_LINK_WITH_PARAMS\""
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"NO_LINK\""
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "Untergeordnete Elemente ", (0, _jsxRuntime.jsx)("br", {}), "Array von Menüeintragsobjekten, identisch mit einzelnen Menüeinträgen. ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Tag"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ganzzahl (Tag-ID oder Slug, ID wird empfohlen)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "1234567890"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Text"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "\"it's like any other string\""
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {})]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "URL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "{   \"type\" : \"CONTENT\",  \"href\" : null,  \"content_id\" : 123456789}"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Typ ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsxs)("ul", {
              children: [(0, _jsxRuntime.jsx)("li", {
                children: "\"EXTERNAL\" für Nicht-HubSpot-URLs, die nicht per E-Mail verschickt werden"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"CONTENT\" für Seiten, Blog-Beiträge und Landing-Pages"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"FILE\" für Dateien, die in den Datei-Manager hochgeladen werden"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"EMAIL_ADDRESS\" für E-Mail-Adressen"
              }), (0, _jsxRuntime.jsx)("li", {
                children: "\"BLOG\" für Blog-Listing-Seiten"
              })]
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "href ", (0, _jsxRuntime.jsx)("br", {}), "Zeichenfolge, die URL, auf die Sie verlinken. ", (0, _jsxRuntime.jsx)("br", {})]
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}