"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29866937481;
const slug = exports.slug = 'guides/apps/marketplace/certification-requirements';
const title = exports.title = 'App Marketplace | App certification requirements';
const name = exports.name = 'App Certification Requirements';
const metaDescription = exports.metaDescription = "Here's what App Partners need to get their app certified in HubSpot's App Marketplace.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "overview",
  "label": "Overview",
  "parent": null
}, {
  "depth": 0,
  "id": "benefits-of-earning-certification",
  "label": "Benefits of earning certification",
  "parent": null
}, {
  "depth": 0,
  "id": "review-criteria",
  "label": "Review criteria",
  "parent": null
}, {
  "depth": 1,
  "id": "security-%26-privacy",
  "label": "Security & Privacy",
  "parent": "review-criteria"
}, {
  "depth": 2,
  "id": "feedback-example",
  "label": "Feedback example",
  "parent": "review-criteria"
}, {
  "depth": 1,
  "id": "reliability-%26-performance",
  "label": "Reliability & Performance",
  "parent": "review-criteria"
}, {
  "depth": 2,
  "id": "feedback-examples",
  "label": "Feedback examples",
  "parent": "review-criteria"
}, {
  "depth": 1,
  "id": "usability-%26-accessibility-%7C-app",
  "label": "Usability & Accessibility | App",
  "parent": "review-criteria"
}, {
  "depth": 2,
  "id": "feedback-example",
  "label": "Feedback example",
  "parent": "review-criteria"
}, {
  "depth": 1,
  "id": "usability-%26-accessibility-%7C-app-marketplace-listing",
  "label": "Usability & Accessibility | App Marketplace Listing",
  "parent": "review-criteria"
}, {
  "depth": 2,
  "id": "feedback-examples",
  "label": "Feedback examples",
  "parent": "review-criteria"
}, {
  "depth": 1,
  "id": "usability-%26-accessibility-%7C-supporting-documentation",
  "label": "Usability & Accessibility | Supporting Documentation",
  "parent": "review-criteria"
}, {
  "depth": 2,
  "id": "feedback-example",
  "label": "Feedback example",
  "parent": "review-criteria"
}, {
  "depth": 1,
  "id": "value",
  "label": "Value",
  "parent": "review-criteria"
}, {
  "depth": 2,
  "id": "feedback-example",
  "label": "Feedback example",
  "parent": "review-criteria"
}, {
  "depth": 0,
  "id": "the-app-certification-and-recertification-review-process",
  "label": "The app certification and recertification review process",
  "parent": null
}, {
  "depth": 0,
  "id": "frequently-asked-questions",
  "label": "Frequently asked questions",
  "parent": null
}, {
  "depth": 0,
  "id": "related-resources",
  "label": "Related resources",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h2: "h2",
      h3: "h3",
      code: "code",
      h4: "h4",
      hr: "hr"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      Quote,
      Accordion
    } = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!Alert) _missingMdxReference("Alert", true);
  if (!Quote) _missingMdxReference("Quote", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Getting certified in the App Marketplace"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["App certification involves the HubSpot Ecosystem Quality team reviewing and confirming that your ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "listed app"
      }), " meets the requirements below for security, privacy, reliability, performance, usability, accessibility, and value."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Certified apps stand out in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://ecosystem.hubspot.com/marketplace/apps",
        children: "App Marketplace"
      }), " with a reputation for quality and trustworthiness. Your app will also earn ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#certification-benefits",
        children: "special benefits"
      }), " and receive constructive feedback from the HubSpot Ecosystem Quality team during app certification review."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "These requirements are subject to change, as HubSpot is continuously making improvements to the HubSpot App Marketplace and Ecosystem. HubSpot can reject an app certification request at their discretion if it doesn't meet the set standards."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["HubSpot will ", (0, _jsxRuntime.jsx)("u", {
            children: "not"
          }), " review your app unless you submit a demo video as instructed when ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/marketplace/applying-for-app-certification",
            children: "applying for app certification"
          }), "."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "You can only submit one app at a time for certification. If you submit more than one app for certification at the same time, they will be rejected based on the order of submission."
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Overview"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Below is an overview of app certification requirements. For more detail, see the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#review-criteria",
        children: "review criteria section"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your app must:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Be associated with a single HubSpot app ID."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Your listed public app must be unique. If you have already listed an app and want to replace it, you should update the existing app instead of listing a new one."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Do not create multiple apps that solve for the same use case. Apps with similar functionality and use the same APIs should be consolidated into a single app."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Use ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/authentication/working-with-oauth",
            children: "OAuth authentication"
          }), " and all scopes it requires."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Be associated with a ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/public-apps/overview",
            children: "verified domain"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Public assets associated with your app must abide by security best practices."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["See the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#security-privacy",
        children: "detailed list of security and privacy requirements"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "info",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["App activity is defined by OAuth-authenticated requests to HubSpot's ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/overview",
          children: "APIs"
        }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/validating-requests",
          children: "signed requests"
        }), " from HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/app-management/webhooks",
          children: "webhook subscriptions"
        }), " and extensions (e.g. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/extensions/crm-cards",
          children: "CRM card"
        }), " data fetch requests)."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Active installs are the number of unique HubSpot production accounts, unaffiliated with your organization, showing successful ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "app activity"
        }), " within the past 30 days."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Benefits of earning certification"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In addition to the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://www.hubspot.com/partners/app",
        children: "benefits of listing your app"
      }), ", certified apps receive:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "A \"HubSpot Certified App\" badge displayed on its App Marketplace listing."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["More prominent visibility in the HubSpot App Marketplace:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Inclusion in the \"HubSpot Certified App\" search filter."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Eligibility for inclusion in curated App Marketplace collections."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Access to the \"HubSpot Certified App\" badge and social media images to share the app's certification achievement."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Favorable consideration in HubSpot's partnership and amplification initiatives."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Review criteria"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To earn certification, your app must demonstrate quality by meeting quantitative measures and qualitative descriptors of security, privacy, reliability, performance, usability, accessibility, and value. The requirements below are organized by these categories and include examples of constructive feedback you may receive."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Security & Privacy"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your app must:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Be associated with a single HubSpot app ID. Your app must authorize API requests with the public HubSpot app ID (and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth client ID"
        }), ") associated with your app listing.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "A listing must not redirect to a different public or private app."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Your listed public app must not require another public or private app to function."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Be authenticated by the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "OAuth authorization code flow"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Asking users to copy and paste OAuth codes or tokens is prohibited. Users should only be asked to grant access"
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Apps must request, manage, and refresh access tokens without user involvement"
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Use all ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/working-with-oauth",
          children: "scopes"
        }), " it requests for installation (i.e. both in the required ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "scope"
        }), " parameter and the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "optional_scope"
        }), " parameter).", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Have ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/public-apps/overview#configure-scopes",
              children: "advanced scope settings"
            }), " turned on and select all required, conditionally required, and optional scopes the app requests for installation."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Extraneous scopes must be removed."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If certain scopes only apply to a subset of your app's user base, they should be included as conditionally required or optional scopes."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Be associated with a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: "verified domain"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Your public assets will be assessed for security best practices related to outdated software and various web server vulnerabilities and findings."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feedback example"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app currently requires four scopes: ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contacts"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "timeline"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "forms"
        }), ", and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), ". According to our logs, however, it only made requests to the CRM Contacts and Timeline Events APIs in the last 30 days. Since the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "forms"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "content"
        }), " scopes are not required for either of these functions, please remove them as required from the app’s settings to minimize the permissions users must accept."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Reliability & Performance"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your app must:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Be in good standing, meaning:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "In compliance with all applicable terms."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Not have been rejected for certification in the last six months."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Not have any unresolved support escalations with mutual customers."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Use stable, public versions of HubSpot's APIs and extensions."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Using the latest public versions is recommended."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Undocumented, beta, and developer preview APIs are considered unstable and must not be used in your production app."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Note: If your app uses APIs that are not stable or public, please surface this during the certification review or to the App Partner Manager team to discuss options."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Maintain a reasonable volume of ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#activity",
            children: "activity"
          }), " from HubSpot customer accounts unaffiliated with your organization."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Your app must adhere to the ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/apps/api-usage/usage-details",
            children: "API usage guidelines"
          }), " and best practices, including:"]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Respecting rate limits (i.e. 100 inbound requests every 10 seconds per connected HubSpot account)."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Refreshing OAuth access tokens before they expire."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Caching data for repeat calls when possible."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Using batch APIs and webhook subscriptions to reduce request volume when possible."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Using APIs to create properties, workflows, and custom workflow actions instead of requiring user action."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Your app must maintain an average success rate above ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "95%"
          }), " across all ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#activity",
            children: "activities"
          })]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Requests resulting in error responses count against this success rate."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Some unavoidable or expected errors may be excluded when calculating success rates across all ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/apps/marketplace/certification-requirements#activity",
              children: "activities"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Your app may have a browser extension to deliver supplementary functionality and value to customers:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Browser extensions must not be created specifically for the HubSpot UI or as a workaround to HubSpot's APIs."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Browser extensions must not inject capabilities or components into HubSpot's UI.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Officially supported UI extensions (e.g. ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/crm/extensions/crm-cards",
                  children: "CRM cards"
                }), " and ", (0, _jsxRuntime.jsx)(_components.a, {
                  href: "/guides/api/automation/custom-workflow-actions",
                  children: "custom workflow actions"
                }), ") provide more consistent user experiences for customers"]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Your app will be subjected to an additional security assessment if it includes a browser extension."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feedback examples"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app’s API success rate falls below the 95% threshold required for certification. Our logs show a 83% success rate in the last 30 days. The vast majority of these requests returned ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "429"
        }), " burst rate limit errors. To reduce this error rate, we recommend throttling requests to 100 requests per 10 seconds for each account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is generating errors around trying to update contacts using an ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "undefined"
        }), " email address, which will not work with this endpoint. Your app should skip these requests if a record does not have an email address."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is making requests with expired OAuth tokens and receiving ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "401"
        }), " errors before refreshing the token. To minimize these errors, we recommend that your app keep track of when tokens expire or refresh tokens before making requests. If you start seeing ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "401"
        }), " errors for 100% of requests and are unable to refresh the access token, consider the app uninstalled and stop making requests for the account until a user re-authenticates your app."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is generating ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " errors from trying to use the Contact Lists API with Marketing Hub Free accounts, which do not have access to contact lists. If your app repeatedly gets ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "403"
        }), " errors for missing the proper scopes, it should stop making calls to retrieve lists from that account."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app’s webhook subscriptions frequently fail with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "500"
        }), " and ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "503"
        }), " errors. Make sure that your server can handle the volume of requests (currently limited to 150 per second) so that customer data is not lost."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your app is pulling many contacts one at a time instead of pulling them in batches. We recommend using ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "batch endpoints"
        }), " instead."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usability & Accessibility | App"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Your app must be ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
          children: "listed"
        }), " in the HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://ecosystem.hubspot.com/marketplace/apps",
          children: "App Marketplace"
        }), " for at least six months."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Your app must demonstrate ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.nngroup.com/articles/ten-usability-heuristics/",
          children: "usability best practices"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Installation and use should not cause confusion or frustration for mutual customers or otherwise negatively affect the core HubSpot user experience."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feedback example"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your app currently requires users to manually configure workflow webhook actions to send text messages. Consider creating custom workflow actions via the app which are flexible enough to accommodate many use cases."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usability & Accessibility | App Marketplace Listing"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Your App Marketplace listing must:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Accurately describe your app's current functionality.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "If functionality changes based on a user's product or subscription level, either for HubSpot or your solution, differences must be made clear."
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Contain clear and accurate pricing information, including a link to your current pricing page.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "If multiple packages support your app, the App Marketplace listing must, at minimum, include the least expensive option."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Use placeholder data or hide data to not display personal identifiable information (PII)."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Include:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Informative and up-to-date visual aids, which may include screenshots or a video. Refer to the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/partners/apps/resources/how-to-make-a-great-app-demo-video",
              children: "How to Make a Great App Demo Video"
            }), " page for best practices and examples of how to create a demo video."]
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "An up-to-date \"Setup documentation URL\" which contains a subdomain and leads directly to the page hosting documentation for your app. This link must not lead to your homepage."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Not include:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Any data or statistics, unless a case study is provided as a resource."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feedback examples"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your App Marketplace listing includes few specific details about your app’s functionality. Please enhance the listing with screenshots which depict app functionality and include more thorough descriptions of common use cases and in-app behavior."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "HubSpot customers are used to a “try before you buy” experience when purchasing our products and services. For this reason, we recommend your app provide a free trial or freemium sign-up experience. Some app partners who do not have pricing pages or free trials have created “HubSpot plans,” offering mutual customers transparent pricing, touchless sign-up, and other benefits."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Usability & Accessibility | Supporting Documentation"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Supporting documentation for your app must:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Exist on a live, publicly accessible URL (i.e. no paywalls or login required) and adhere to current accessibility, privacy, and GDPR standards."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Be up-to-date and consistent with the current version of your app."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Clearly describe:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "What your app does."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "How to install your app and connect a HubSpot account with screenshots of each step, including the scope approval screen."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "How to configure your app once it is installed."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "How to use your app, including both manual and automated interactions."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "How to disconnect HubSpot from your app."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "How to uninstall your app from a HubSpot account."
          }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "How disconnecting and uninstalling might affect users' HubSpot accounts and data."
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Include images. Any images containing screenshots of the HubSpot UI should be up-to-date and consistent with our ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://canvas.hubspot.com/",
          children: "design system"
        }), ".", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
            children: "Videos are also recommended, but not required. Videos should be updated regularly and reflect the current version of your app."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feedback example"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["The setup guide for your app includes a screenshot depicting the scopes your app requires for installation. This screenshot does not show the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "business-intelligence"
        }), " scope, which is selected in your app’s settings. Please update the screenshot so that it reflects the current required scopes."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Value"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Your app's active install count, retention, and HubSpot App Marketplace reviews are assessed as indicators of the value mutual customers find in your app."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Your app must have at least 60 ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#activity",
              children: "active"
            }), ", unique installs to qualify for and retain certification. The accounts with installs must be unaffiliated with your organization. Test accounts will also be excluded.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "If your app has fewer than 60 active installs, then you will be asked to cancel certification request."
              }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
                children: "If your app has fewer than the three active installs required to be listed, then your app may be removed from the App Marketplace."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "Your app listing must have responses from your team for any negative reviews of your app."
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Feedback example"
    }), "\n", (0, _jsxRuntime.jsx)(Quote, {
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "Your app has not maintained at least 60 active installs over the trailing six month period. As such, its certified status will be removed. You may re-apply for certification in six months."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "The app certification and recertification review process"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The HubSpot Ecosystem Quality team responds to ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "app Certification requests"
      }), " within ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "10 business days"
      }), ". The entire app review and feedback process should take no more than ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "60 days"
      }), " from the time feedback is shared. Review the criteria listed ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/testing-credentials",
        children: "here"
      }), " for providing testing credentials to your app."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Should your app meet all requirements, it will earn certified status and a “HubSpot Certified App” badge will be displayed to customers and prospects on the App Marketplace. Your app will also appear when users select the “HubSpot Certified App” filter."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Should your app not successfully complete the review, you may re-apply in six months."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "After being certified for one year, the HubSpot Ecosystem Quality team may review your app to ensure it still meets the rigorous certification requirements. If you app no longer meets these standards, the team will collaborate with you for up to sixty days to resolve concerns."
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if your app falls out of compliance with the certification requirements listed above at any time, HubSpot may immediately initiate a recertification process, even if your app has been certified for less than one year. As stated in the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/app-program-agreement",
          children: "App Partner Program Agreement"
        }), ", HubSpot also reserve the right to unpublish your app at any time."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Frequently asked questions"
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Are there any costs associated with app certification?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "No, we do not charge you a fee to list or certify your apps in the App Marketplace, nor a fee for installs generated through the App Marketplace. There is no revenue sharing. We are here to support you to make your app of higher quality."
      })
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Will I be notified when my app will be eligible again for certification if it was previously rejected?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["No. At this time we do not have notifications enabled to notify you if and when you will be eligible to re-apply at this time. Your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " would be the best resource to contact and ask if you are eligible before applying."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "How should I promote my certified app to my customers?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Feel free to use the press release template on ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.hubspot.com/partners/app/resources",
          children: "this page"
        }), " to share the news that your app has earned certification."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "If you plan to post on social media, be sure to tag HubSpot — we love to celebrate alongside our app partners!"
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "I enjoy experimenting with the newest beta APIs. Is app certification right for me?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: ["We recommend you reach out to your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " to see if app certification is right for your app."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Our goal is to ensure your app is well built for our mutual customers and limits breaking changes, which requires your app uses the latest stable APIs. We also love seeing and supporting entrepreneurs, early adopters, and developers who are eager to experiment with the newest beta APIs."
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "I'd like my app to be featured on the App Marketplace. Is this what app certification is for?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The benefits of being featured in collections and for customers to easily filter for a certified app within the App Marketplace are continuing to evolve. We’d like to learn more about how you would find being featured the most helpful (e.g. App Marketplace, HubSpot community, HubSpot curated newsletters or other forms)."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Your ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#app-partner-manager",
          children: "App Partner Manager"
        }), " would be the best contact to discuss potential future benefits and start this conversation."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Accordion, {
      title: "Why and how will my public assets be assessed for security best practices?",
      defaultOpen: false,
      children: (0, _jsxRuntime.jsx)(_components.p, {
        children: "With the average customer using more than five integrations, it’s imperative apps are monitored and held to privacy, security, and quality standards over time. Any public assets will be assessed using information already provided during a listing process and findings will be analyzed using a non-invasive method."
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Accordion, {
      title: "My app was certified over a year ago. What do I have to do to keep my certification status and what does it mean to get recertified?",
      defaultOpen: false,
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "The HubSpot Ecosystem Quality team will reach out if your app is out of compliance or due for annual recertification with next steps."
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["We encourage you to monitor your app’s performance, certification requirements, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/changelog",
          children: "Developer Changelog"
        }), ", and any additional HubSpot resources related to any changes in technology used and how your app could stay up to date."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Find your ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Partner Manager"
        }), " and their email information by logging into your developer account and navigating to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "App Marketplace"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Listings"
        }), ". Hover over your app, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "More"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "View Listing Details"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.hr, {}), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Related resources"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/applying-for-app-certification",
        children: "How to apply for app certification"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/listing-your-app",
        children: "How to list your app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/marketplace/app-marketplace-listing-requirements",
        children: "App listing requirements"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://community.hubspot.com/t5/APIs-Integrations/bd-p/integrations",
        children: "Developer community forum"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/contact-our-partnerships-team",
        children: "Contact the App Partner team"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}