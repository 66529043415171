"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 66492355338;
const slug = exports.slug = 'guides/cms/content/performance/speed';
const title = exports.title = 'Optimizar la velocidad de tu sitio CMS Hub';
const name = exports.name = 'EMEA ES Optimizing your CMS Hub site for Speed - original';
const metaDescription = exports.metaDescription = 'Consejos y estrategias para mejorar la velocidad de carga y renderización de tu sitio web. Haz tu tema o sitio CMS Hub rápido, y aprende lo que HubSpot ya hace por ti.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "empezar-con-una-buena-base",
  "label": "Empezar con una buena base",
  "parent": null
}, {
  "depth": 0,
  "id": "obst%C3%A1culos-comunes-en-el-rendimiento-del-sitio-web",
  "label": "Obstáculos comunes en el rendimiento del sitio web",
  "parent": null
}, {
  "depth": 1,
  "id": "im%C3%A1genes",
  "label": "Imágenes",
  "parent": "obst%C3%A1culos-comunes-en-el-rendimiento-del-sitio-web"
}, {
  "depth": 2,
  "id": "%C2%BFqu%C3%A9-puedes-hacer%3F",
  "label": "¿Qué puedes hacer?",
  "parent": "obst%C3%A1culos-comunes-en-el-rendimiento-del-sitio-web"
}, {
  "depth": 1,
  "id": "reproducci%C3%B3n-autom%C3%A1tica-de-v%C3%ADdeos",
  "label": "Reproducción automática de vídeos",
  "parent": "obst%C3%A1culos-comunes-en-el-rendimiento-del-sitio-web"
}, {
  "depth": 2,
  "id": "%C2%BFqu%C3%A9-puedes-hacer%3F",
  "label": "¿Qué puedes hacer?",
  "parent": "obst%C3%A1culos-comunes-en-el-rendimiento-del-sitio-web"
}, {
  "depth": 1,
  "id": "javascript",
  "label": "JavaScript",
  "parent": "obst%C3%A1culos-comunes-en-el-rendimiento-del-sitio-web"
}, {
  "depth": 2,
  "id": "%C2%BFqu%C3%A9-puedes-hacer%3F",
  "label": "¿Qué puedes hacer?",
  "parent": "obst%C3%A1culos-comunes-en-el-rendimiento-del-sitio-web"
}, {
  "depth": 0,
  "id": "herramienta-recomendaciones",
  "label": "Herramienta Recomendaciones",
  "parent": null
}, {
  "depth": 0,
  "id": "alertas-de-c%C3%B3digo",
  "label": "Alertas de código",
  "parent": null
}, {
  "depth": 0,
  "id": "%C2%BFde-qu%C3%A9-se-encarga-hubspot%3F",
  "label": "¿De qué se encarga HubSpot?",
  "parent": null
}, {
  "depth": 0,
  "id": "recursos-adicionales-para-mejorar-la-velocidad-de-tu-sitio-web",
  "label": "Recursos adicionales para mejorar la velocidad de tu sitio web",
  "parent": null
}, {
  "depth": 1,
  "id": "herramientas-de-rendimiento",
  "label": "Herramientas de rendimiento",
  "parent": "recursos-adicionales-para-mejorar-la-velocidad-de-tu-sitio-web"
}, {
  "depth": 2,
  "id": "optimizaci%C3%B3n-de-im%C3%A1genes",
  "label": "Optimización de imágenes",
  "parent": "recursos-adicionales-para-mejorar-la-velocidad-de-tu-sitio-web"
}, {
  "depth": 2,
  "id": "pruebas-de-rendimiento",
  "label": "Pruebas de rendimiento",
  "parent": "recursos-adicionales-para-mejorar-la-velocidad-de-tu-sitio-web"
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      ul: "ul",
      li: "li",
      strong: "strong",
      h3: "h3",
      h4: "h4",
      ol: "ol",
      code: "code"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      ProductTier,
      CTA
    } = _components;
  if (!CTA) _missingMdxReference("CTA", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Optimizar tu sitio en CMS Hub para mejorar el rendimiento"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['cms-professional', 'cms-enterprise', 'marketing_hub-professional', 'marketing_hub-enterprise', 'cms-starter']
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una gran experiencia de usuario es un factor de calidad de contenido, velocidad, seguridad y ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/accessibility",
        children: "accesibilidad."
      }), " Optimizar estos aspectos generalmente también mejora la optimización en motores de búsqueda (SEO)."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Tener un mejor desempeño se reduce a ofrecer una mejor experiencia para los usuarios finales. Lograr un mejor rendimiento se basa en resolver los problemas individuales de tu sitio."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Empezar con una buena base"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es más fácil construir a partir de una gran base que se construyó con el rendimiento en mente, que tratar de arreglar los problemas de rendimiento más tarde. Una metáfora: construir un coche rápido desde cero, es más fácil que comprar un coche lento y tratar de hacerlo rápido."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["La ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
        children: "Plantilla de CMS HubSpot"
      }), " fue construida para ser rápida, y fomentar las mejores prácticas. Consulta el ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "Léeme de GitHub"
      }), " para ver las puntuaciones actuales en Lighthouse y evaluador de sitios web."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando se construye a partir de la plantilla repetitiva se sabe que, sin ningún código que hayas agregado, alcanza esas puntuaciones. Eso significa que puedes centrar tu atención en el código que has agregado encima de la plantilla repetitiva."
    }), "\n", (0, _jsxRuntime.jsx)(CTA, {
      id: "bdf71810-13e6-494f-8dbd-f733d5451ad5",
      label: "Build a site based on boilerplate"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Obstáculos comunes en el rendimiento del sitio web"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La mayoría de las técnicas de optimización del rendimiento web y las mejores prácticas no son específicas de HubSpot. Esto se debe a que la mayoría de los obstáculos del rendimiento del sitio web no son específicos de HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La mayoría de los problemas de rendimiento se dividen en un par de categorías, la renderización y la carga."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rendimiento de carga"
        }), " es la eficacia de la transferencia de todos los archivos necesarios para tu página web al navegador del usuario."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rendimiento de renderizado"
        }), " es la eficiencia para que el navegador tome todo lo que descargó y muestre el resultado final al usuario."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["El rendimiento de la carga es un poco más fácil de entender: la cantidad de archivos, el tamaño de los mismos y la velocidad de entrega de esos archivos determinan el rendimiento de la carga. El rendimiento del renderizado es un tema complicado. Las hojas de estilo en cascada (", (0, _jsxRuntime.jsx)("abbr", {
        children: "CSS"
      }), "), el JavaScript (", (0, _jsxRuntime.jsx)("abbr", {
        children: "JS"
      }), "), las imágenes, los vídeos, el dispositivo que utiliza el visitante y el navegador que utiliza son factores importantes. CSS bloquea la representación. Un CSS mal escrito puede causar un Desplazamiento de diseño acumulativo (", (0, _jsxRuntime.jsx)("abbr", {
        children: "CLS"
      }), ") durante la representación de la página. Las imágenes pueden causar CLS, y ocupan la memoria RAM. Los reproductores de vídeo pueden causar CLS, algunos formatos de archivo requieren más trabajo de procesamiento. JS puede manipular el ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Glossary/DOM",
        children: ["Modelo de objetos del documento (", (0, _jsxRuntime.jsx)("abbr", {
          children: "DOM"
        }), ")"]
      }), " y el ", (0, _jsxRuntime.jsxs)(_components.a, {
        href: "https://developer.mozilla.org/en-US/docs/Glossary/CSSOM",
        children: ["Modelo de objetos de hoja de estilo en cascada (", (0, _jsxRuntime.jsx)("abbr", {
          children: "CSSOM"
        }), ")"]
      }), " de una página causando cualquiera de esos problemas. JS también puede consumir muchos recursos. Es necesario equilibrar todos estos factores y seguir las mejores prácticas para garantizar una experiencia rápida a todos los visitantes."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Imágenes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las imágenes están presentes en casi todas las páginas de la web. Las imágenes suelen ser los archivos más grandes de una página. Cuantas más imágenes, y cuanto más grandes sean, más tiempo tardará en cargarse tu página. Las imágenes animadas, como los gifs y los webp animados, también ocupan más espacio que las imágenes no animadas del mismo tamaño. Algunos formatos de imagen también son más eficaces que otros, y mejores para determinados escenarios."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "¿Qué puedes hacer?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Lo más importante que puedes hacer es ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/compress-image",
          children: "optimizar tus imágenes"
        }), " para la web. La optimización de las imágenes es una responsabilidad compartida entre los creadores de contenido y los desarrolladores."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utiliza menos imágenes por página."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/insiders/different-types-of-image-files",
          children: "Utiliza el formato de imagen adecuado para el caso de uso"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Utiliza gráficos vectoriales escalables (SVG) siempre que sea posible. Los SVG pueden ampliar su tamaño infinitamente sin perder calidad. Incluir los SVG tiene sentido cuando se hacen animaciones. En los gráficos estáticos, crear una hoja de sprite SVG o simplemente tratarla como un elemento img normal, o una imagen de fondo suele ser mejor para el rendimiento."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/lazy-loading",
          children: "Carga diferida"
        }), " inteligente de imágenes."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Asegúrate de que los elementos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "img"
        }), " contienen atributos HTML de altura y anchura. Esto hace que sea más fácil para los navegadores durante el tiempo de renderización y también hace que HubSpot pueda agregar inteligentemente un ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "srcset"
        }), " para ti. HubSpot no sólo ayuda a optimizar, sino que los navegadores web pueden optimizar de forma inteligente el ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/cls/",
          children: "cambio de diseño acumulado"
        }), " cuando se proporciona la anchura y la altura."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utiliza la propiedad ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio",
          children: "CSS aspect-ratio"
        }), " para reservar espacio cuando las dimensiones del img puedan cambiar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utiliza ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/hubl/functions#resize-image-url",
          children: (0, _jsxRuntime.jsx)(_components.code, {
            children: "resize_image_url"
          })
        }), " para forzar el cambio de tamaño de las imágenes a una resolución determinada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para las imágenes de fondo, ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/optimize-css-background-images-with-media-queries/",
          children: "utiliza las consultas de medios"
        }), " en combinación con ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "resize_image_url"
        }), " para entregar imágenes en tamaños que tengan sentido para el dispositivo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para las imágenes hero de gran tamaño, puedes precargarlas utilizando ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<link rel=\"preload\" as=\"image\" href=\"http://example.com/img_url.jpg\">"
        }), " dentro de una ", (0, _jsxRuntime.jsxs)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/hubl/tags#require-head",
          children: ["etiqueta ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "require_head"
          })]
        }), ". Utiliza esta técnica con moderación, ya que su uso excesivo puede perjudicar el rendimiento."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Reproducción automática de vídeos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Los fondos de vídeo y los vídeos que se reproducen automáticamente pueden marcar la diferencia en un sitio web. Por desgracia, tienen un costo. Los fondos de vídeo se utilizan a menudo para los encabezados de los sitios web. Cuando un vídeo se reproduce automáticamente, significa que el navegador tiene que empezar a cargar el vídeo de inmediato. Esto puede ser especialmente problemático para los usuarios con conexiones más lentas o que utilizan los datos del teléfono móvil"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "¿Qué puedes hacer?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Evita el uso de vídeos de reproducción automática. Si lo que muestras es una animación de fondo, considera utilizar animaciones CSS o animaciones javascript. Si debes mostrar un vídeo de reproducción automática:"
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Elige una resolución razonable para el vídeo en función de tu caso de uso, y aplica un efecto sobre el vídeo para que se note menos una resolución más baja."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Asegúrate de que la calidad del vídeo se adapte al dispositivo y a la conexión; la mejor manera de hacerlo es utilizando un servicio de alojamiento/compartición de vídeos como YouTube, Vidyard o Vimeo."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Desactiva la reproducción automática en el móvil, muestra en cambio una imagen de reserva."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "JavaScript"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["JavaScript (", (0, _jsxRuntime.jsx)("abbr", {
        children: "JS"
      }), ") es útil para agregar interactividad a tu sitio web. Cargar mucho código JS en general aumenta el tamaño de los archivos JS y el tiempo que tarda el navegador en renderizar los elementos interactivos. La carga de JS en ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "<head>"
      }), " también puede ser un problema ya que javaScript es ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://web.dev/render-blocking-resources/",
        children: "recurso de bloqueo de renderización"
      }), " por opción predeterminada. Además, JS se ejecuta en el dispositivo de los visitantes, lo que significa que está limitado a los recursos que tiene ese dispositivo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "¿Qué puedes hacer?"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ol, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Cuando el CMS de HubSpot salió por primera vez, jQuery se cargó en el ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<head>"
        }), " por opción predeterminada. Puedes eliminarlo por completo en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configuración > Página web > Páginas,"
        }), " o ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/upgrade-jquery",
          children: "actualizar a la última versión de jQuery"
        }), ". Ten cuidado al cambiar estas configuraciones en sitios web antiguos si no los construiste, ya que pueden haber sido construidos dependiendo de jQuery o basados en la carga de jQuery en el encabezado."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Asegúrate de que javascript se cargue justo antes de ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "</body>"
        }), " para evitar el bloqueo de procesamiento. Puedes usar ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), " para cargar js para módulos o plantillas sólo cuando sea necesario y sin cargar accidentalmente el javascript varias veces para múltiples instancias de un módulo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Considera la posibilidad de refactorizar tu JS para que sea más eficiente. Usa menos plugins JS, usa HTML semántico donde pueda ayudar. Por ejemplo, para los elementos desplegables, utiliza ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<details>"
        }), " y ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<summary>"
        }), ". Para los modales utiliza ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "<dialog>"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si estás usando una biblioteca JS gigante sólo para unas pocas funciones pequeñas, considera usar Vanilla JS o cargar un subconjunto de la biblioteca si es posible."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Utiliza ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/hubl/functions#require-js",
          children: "require_js"
        }), " para cargar JS sólo cuando sea necesario y sólo una vez por página. Cuando utilizas ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "require_js"
        }), ", utiliza los atributos ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "async"
        }), " o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "defer"
        }), " para mejorar el rendimiento de la página."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para controlar dónde y cuándo se carga el JavaScript de un módulo, utiliza ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/modules/configuration",
          children: "js_render_options"
        }), " en el archivo meta.json del módulo."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Si se cargan recursos externos, utiliza ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/preconnect-and-dns-prefetch/",
          children: "preconnect y DNS prefetch"
        }), " adecuadamente para ofrecer una experiencia más rápida."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Limita el número de scripts de seguimiento que utilizas. Las secuencias de comandos de seguimiento a menudo intentan comprender todas las acciones que un usuario realiza en una página para proporcionarle información. Eso es mucho código que analiza lo que el usuario está haciendo. Cada guión de seguimiento lo amplía."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Herramienta Recomendaciones"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La herramienta Recomendaciones de HubSpot es una gran manera de obtener información sobre el rendimiento y el SEO específica para tu sitio web."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/es/seo/view-seo-recommendations-in-hubspot",
        children: "Más información sobre la herramienta de recomendaciones"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Alertas de código"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las alertas de código son una característica de CMS Hub Enterprise que actúa como una visión general centralizada de los problemas que se identifican dentro de tu sitio web HubSpot CMS. Corregir los problemas identificados en las Alertas de código puede ayudar a optimizar el rendimiento de tu sitio web. Los problemas identificados abarcan varias áreas diferentes, desde los límites del HubL hasta los problemas del CSS."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/debugging/code-alerts",
        children: "Más información sobre las alertas de código."
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "¿De qué se encarga HubSpot?"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La mayoría de las mejores prácticas de rendimiento/velocidad no son específicas de HubSpot. El CMS de HubSpot maneja automáticamente muchos problemas de rendimiento comunes para ti. Estas dimensiones incluyen:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "CDN con optimización de imágenes y conversión automática a WebP"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "HTTP2"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "Javascript"
        }), " y la minificación de CSS"]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "Almacenamiento en caché del navegador y del servidor"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/prerendering",
          children: "Prerenderizado"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/performance/overview",
          children: "Reescritura de dominios"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/developer-reference/cdn#text-compression",
          children: "Compresión Brotli (con retroceso a la compresión GZIP)"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/es/docs/cms/developer-reference/cdn#accelerated-mobile-pages-amp-",
          children: "Las publicaciones de HubSpot Blog son compatibles con AMP"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Al incluir CSS en un módulo personalizado, HubSpot carga de forma inteligente module.css sólo cuando se utiliza un módulo en una página, y sólo lo carga una vez independientemente de cuántas instancias del módulo haya en la página. Por opción predeterminada, module.css no se carga de forma asíncrona, pero puedes cambiar esto incluyendo ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/modules/configuration",
        children: "css_render_options"
      }), " en el archivo meta.json del módulo."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recursos adicionales para mejorar la velocidad de tu sitio web"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay muchas cosas que se pueden hacer para optimizar la velocidad de un sitio web y muchos de los temas merecen un análisis más detallado. Hemos recopilado algunos recursos excelentes que te animamos a consultar cuando trabajes en la optimización de tu sitio."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://designers.hubspot.com/blog/site-speed-and-performance-what-we-do-and-what-you-can-do",
          children: "Velocidad y rendimiento del sitio: qué puedes hacer y cómo te ayuda HubSpot"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.hubspot.com/blog/how-we-improved-page-speed-on-hubspot.com",
          children: "Cómo mejoramos la velocidad de la página en HubSpot.com"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://designers.hubspot.com/blog/15-tips-to-speed-up-your-website",
          children: "15 consejos para acelerar tu sitio web"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/how-to-reduce-your-websites-page-speed",
          children: "5 formas sencillas de reducir el tiempo de carga de tu página web"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://blog.hubspot.com/marketing/google-page-speed",
          children: "Guía de 8 pasos para conseguir el 100% de velocidad de la página de Google"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/courses/website-optimization",
          children: "Optimización del sitio web - HubSpot Academy"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/learn/",
          children: "Web.dev"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "Cómo optimizamos el CMS de HubSpot - Jeff Boulter"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.smashingmagazine.com/2021/04/humble-img-element-core-web-vitals/",
          children: "El humilde elemento img y Core Web Vitals - Smashing Magazine"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Herramientas de rendimiento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Hay muchas herramientas que se pueden utilizar tanto para mejorar el rendimiento como para probarlo. Es útil tanto entender estas herramientas como utilizarlas. Esta lista no es exhaustiva ni mucho menos, y procede de nuestra comunidad de desarrolladores."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Optimización de imágenes"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Optimizar las imágenes para la web antes de subirlas y publicarlas ayuda a garantizar que no se publicará una imagen demasiado grande para la pantalla y el caso de uso."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Herramientas populares de optimización de imágenes:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://imageoptim.com/mac",
          children: "ImageOptim"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.adobe.com/products/photoshop.html",
          children: "Adobe Photoshop"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.adobe.com/products/illustrator.html",
          children: "Adobe Illustrator"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://imagecompressor.com/",
          children: "Optimizilla"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Pruebas de rendimiento"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Probar el rendimiento y optimizarlo debería ser parte de la construcción de cualquier sitio web. Hay una gran cantidad de herramientas disponibles para probar la velocidad de un sitio web. Es importante entender estas herramientas y su funcionamiento, para poder tomar decisiones informadas sobre las mejoras de rendimiento."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Algunas herramientas miden estrictamente, otras clasifican. Es importante entender cómo funciona esto realmente."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Las herramientas que miden"
      }), ", suelen probar el tiempo de carga, el tiempo de ejecución de los scripts, el tiempo hasta la primera pintura con contenido, los tiempos de red para la descarga de activos, etc. Estas herramientas suelen proporcionar resultados que indican tiempos específicos para cada una de estas métricas. Si vuelves a hacer la prueba, generalmente las mediciones cambiarán ligeramente porque no todas las cargas de páginas son exactamente iguales."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Las herramientas que califican,"
      }), " no sólo miden la velocidad, sino que te dicen si lo estás haciendo bien o mal, a menudo utilizando un porcentaje o una letra de calificación. Estas calificaciones pretenden ser una herramienta para motivar a los desarrolladores y profesionales de marketing a mejorar. Hay muchas métricas y aspectos diferentes del rendimiento que hay que tener en cuenta. No se puede basar el rendimiento global en una sola métrica, y además algunas métricas tienen diferentes niveles de afectación en el rendimiento percibido. Así que estas herramientas ponderan las métricas de forma diferente para calcular el rendimiento global. ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "No existe ninguna norma en el sector sobre cómo ponderar las métricas."
      }), " Con el tiempo esta ponderación puede cambiar, como ha ocurrido con ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://googlechrome.github.io/lighthouse/scorecalc/",
        children: "Google PageSpeed"
      }), ". Tampoco existe una aceptación generalizada en el sector de lo que se considera el valor mínimo o máximo \"bueno\" para las métricas individuales. Algunas herramientas se basan en un percentil de sitios web que han sido probados. Lo que significa que tus resultados se comparan con los de otros sitios que fueron probados. El resultado es que el rango de velocidad \"buena/excelente\" es cada vez más difícil de alcanzar con el tiempo. En cambio, algunas herramientas utilizan la experiencia del usuario, la retención de visitantes y la investigación basada en el ROI para determinar cuál debe ser el umbral de una buena puntuación. También hay que tener en cuenta que no todas las herramientas tienen en cuenta el rendimiento de la carga posterior de la página. Por ejemplo, el sistema de módulos de HubSpot separa el css y el JS para los módulos individuales, y sólo carga esos materiales cuando el módulo se coloca realmente en la página. Esto puede resultar en varios archivos css más pequeños, que podrían ser marcados por Google PageSpeed Insights. Sin embargo, la realidad es que en la próxima carga de la página, no necesitarás descargar ningún css o js para los módulos que se repiten en la siguiente página: se almacenarán en la caché. En cambio, sólo tendrás que descargar los archivos de los módulos que no hayas visto antes, y que serían kilobytes, en lugar de un archivo monolítico."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando se trata de herramientas calificadas, debes utilizar varias herramientas y esforzarte por obtener la mejor puntuación que puedas en cada una de ellas. Pero hay que entender que ellos ponderan las cosas de manera diferente. Los esfuerzos que pueden mejorar una puntuación en una herramienta pueden no mejorarla en otras."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Herramientas populares de pruebas de rendimiento:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://website.grader.com/",
          children: "Website Grader"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://gtmetrix.com/",
          children: "GTMetrix"
        })
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/speed/pagespeed/insights/",
          children: "Google PageSpeed Insights"
        }), " y otras ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://developers.google.com/web/fundamentals/performance/speed-tools",
          children: "Herramientas de rendimiento de Google"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.pingdom.com/",
          children: "Pingdom"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.webpagetest.org/",
          children: "WebPageTest"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Relacionado:"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://web.dev/performance-scoring/",
          children: "Cómo funciona la puntuación de rendimiento de Lighthouse"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.youtube.com/watch",
          children: "Hoja de ruta para la optimización de sitios web (Core Web Vitals) | Mark Ryba"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}