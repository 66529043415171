"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 76425848690;
const slug = exports.slug = 'guides/apps/private-apps/overview';
const title = exports.title = 'Aplicaciones privadas';
const name = exports.name = 'LATAM (ES) Private Apps';
const metaDescription = exports.metaDescription = 'Más información sobre cómo crear aplicaciones privadas en HubSpot';
const toc = exports.toc = [{
  "depth": 0,
  "id": "crear-una-aplicaci%C3%B3n-privada",
  "label": "Crear una aplicación privada",
  "parent": null
}, {
  "depth": 0,
  "id": "haz-llamadas-de-api-con-el-token-de-acceso-de-tu-aplicaci%C3%B3n.",
  "label": "Haz llamadas de API con el token de acceso de tu aplicación.",
  "parent": null
}, {
  "depth": 0,
  "id": "ver-informaci%C3%B3n-de-token-de-acceso-de-aplicaci%C3%B3n-privada",
  "label": "Ver información de token de acceso de aplicación privada",
  "parent": null
}, {
  "depth": 0,
  "id": "rotar-token-de-acceso",
  "label": "Rotar token de acceso",
  "parent": null
}, {
  "depth": 0,
  "id": "ver-los-registros-de-llamadas-de-api",
  "label": "Ver los registros de llamadas de API",
  "parent": null
}, {
  "depth": 0,
  "id": "l%C3%ADmites-de-las-aplicaciones-privadas",
  "label": "Límites de las aplicaciones privadas",
  "parent": null
}, {
  "depth": 0,
  "id": "eliminar-una-aplicaci%C3%B3n-privada",
  "label": "Eliminar una aplicación privada",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      em: "em",
      img: "img",
      pre: "pre",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Private apps"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Las aplicaciones privadas permiten utilizar las API de HubSpot para obtener acceso a datos específicos de tu cuenta de HubSpot. Puedes autorizar lo que puede solicitar o cambiar cada aplicación privada en tu cuenta, lo que generará un token de acceso que es único para tu aplicación."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Debes ser un ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "super admin"
      }), " para acceder a las aplicaciones privadas de tu cuenta de HubSpot."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " Las aplicaciones privadas no admiten, eventos de cronologías personalizadas o webhooks. Los webhooks son ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/private-apps/create-and-edit-webhook-subscriptions-in-private-apps",
          children: "compatibles con aplicaciones privadas"
        }), ", pero las suscripciones no se pueden editar mediante programación a través de una API, sino que se deben editar en la configuración de la aplicación privada."]
      }), (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Si planeas crear una aplicación usando eventos privados de la cronología, debes crear una aplicación pública en su lugar. Más información sobre las ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/overview",
          children: "diferencias entre aplicaciones privadas y públicas"
        }), "."]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Crear una aplicación privada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ícono de configuración"
        }), " en la barra de navegación principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el menú de la barra lateral izquierda, navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integraciones"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones privadas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear página privada"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la ficha ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Información básica"
        }), ", configura los detalles de tu aplicación:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Introduce el ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Nombre"
            }), " de tu aplicación."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Coloca el cursor sobre el logotipo del parámetro de sustitución y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "cargar icono"
            }), " para cargar una imagen cuadrada que funcionará como logotipo de tu aplicación."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Introduce una ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "descripción"
            }), " para tu aplicación."]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Alcances"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Selecciona la casilla de comprobación ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Lectura"
        }), " o ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Escritura"
        }), " para cada uno de los alcances a los que deseas que tu aplicación pueda acceder. También puedes buscar un alcance específico usando la barra de búsqueda ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Buscar un alcance"
        }), ". Puedes revisar una lista completa de los alcances disponibles en ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/other-resources/scopes",
          children: "este artículo de referencia"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Después de configurar tu aplicación, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Crear aplicación"
        }), " en la parte superior derecha."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/updated-read-write-scope-config-private-apps.png",
        alt: "updated-read-write-scope-config-private-apps"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el cuadro de diálogo, revisa el contenido y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Enviar enlace"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Una vez hayas creado tu aplicación, puedes comenzar a hacer llamadas de API usando el token de acceso de la aplicación. Si necesitas editar la información de tu aplicación o cambiar sus alcances, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: "Editar detalles"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/edit-details-of-private-app.png",
        alt: "edit-details-of-private-app"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Haz llamadas de API con el token de acceso de tu aplicación."
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Nota:"
        }), " las aplicaciones privadas perderán el acceso a los alcances cuando tu cuenta de HubSpot se reduzca y pierda el acceso a la funcionalidad. Por ejemplo, si tu cuenta no tiene acceso a HubDB, tu aplicación privada no tendrá acceso al alcance de HubDB."]
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "Los tokens de acceso a aplicaciones privadas se actualizarán para reflejar los alcances disponibles en tu cuenta de HubSpot y lo que configuraste para la aplicación privada, pero la cadena de tokens en sí no cambiará."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para hacer llamadas de API, navega a la página de detalles de tu aplicación."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la tarjeta de ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "token"
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Acceso"
        }), ", haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Mostrar token"
        }), " para revelar tu token de acceso. Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Copiar"
        }), " para copiar el token en tu portapapeles."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/show-private-app-access-token-1.png",
        alt: "show-private-app-access-token-1"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["A continuación, podrás pegar el token de acceso para suministrarlo a tus desarrolladores o para usarlo en el desarrollo de tu aplicación. Al hacer una llamada a uno de los puntos finales de la API de HubSpot, establece el valor del campo ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Autorización"
        }), " en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Bearer [YOUR_TOKEN]"
        }), ". Por ejemplo, si estás llamando a la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/contacts",
          children: "API de contactos"
        }), " usando Node.js y ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://www.npmjs.com/package/axios",
          children: "axios"
        }), ", la solicitud será similar a lo siguiente:"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "axios.get(\n  'https://api.hubapi.com/crm/v3/objects/contacts',\n  {\n    headers: {\n      Authorization: `Bearer ${YOUR_TOKEN}`,\n      'Content-Type': 'application/json',\n    },\n  },\n  (err, data) => {\n    // Handle the API response\n  }\n);\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Los tokens de acceso privado se implementan en la parte superior de OAuth, de manera que también puedas hacer llamadas autenticadas con tu token de acceso utilizando una de las bibliotecas de clientes de HubSpot. Por ejemplo, si estás utilizando la ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://github.com/HubSpot/hubspot-api-nodejs",
          children: "biblioteca de cliente Node.js"
        }), ", puedes crear instancias de un cliente de OAuth al pasar el token de acceso de tu aplicación."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-js",
        children: "const hubspotClient = new hubspot.Client({ accessToken: YOUR_ACCESS_TOKEN });\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ver información de token de acceso de aplicación privada"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ver información de token de acceso de aplicación privada, tales como hubId y los alcances asociados con el token, haz una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/oauth/v2/private-apps/get/access-token-info"
      }), ". En el cuerpo de la solicitud, incluye el token de acceso"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// POST request response body\n{\n  \"tokenKey\": {{accessToken}}\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "La respuesta incluirá información acerca del usuario que creó el token, el Hub ID de la cuenta, el ID de la aplicación privada y los alcances asociados con el token."
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// Example response body\n{\n  \"userId\": 123456,\n  \"hubId\": 1020304,\n  \"appId\": 2011410,\n  \"scopes\": [\"oauth\", \"crm.schemas.companies.write\"]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rotar token de acceso"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si tu token de acceso se pierde o está en riesgo, puedes rotar el token. Se creará un token de acceso nuevo y el token de acceso original se vencerá."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Configuración"
        }), " en la barra de navegación principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Navega a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Integraciones"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Aplicaciones privadas"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " de tu aplicación privada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Rotar"
        }), ", junto al token de acceso:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Si tu token está en riesgo y deseas revocar el acceso inmediatamente, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Rotar y vencimiento ahora"
            }), ":"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Recomendamos que rotes tu token cada seis meses, incluso sin que haya amenazas al token. Si estás listo para comenzar una rotación periódica de tu token, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Rotar y vencimiento más tarde"
            }), ", lo que activará el vencimiento del token en 7 días.", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Si la aplicación está lista para realizar la transición antes, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Vencimiento ahora"
                }), ":"]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: ["Si decides que necesitas más tiempo, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Cancelar rotación"
                }), ", lo que cancelará el vencimiento del token original y revocará el nuevo token de acceso:"]
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/rotate-private-app-access-token.png",
        alt: "rotate-private-app-access-token"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["HubSpot también enviará notificaciones por correo electrónico a los ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
        children: "superadministradores"
      }), " con recordatorios sobre el estado de rotación del token de acceso, así como otras alertas relacionadas. Los superadministradores de tu cuenta de HubSpot recibirán notificaciones de los siguientes eventos y recordatorios:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un superadministrador inició una rotación (ya sea inmediatamente o programada para dentro de 7 días)."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Un superadministrador canceló una rotación pendiente."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Un superadministrador optó por hacer que un token de acceso venciera inmediatamente haciendo clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Hacer que venza ahora"
        }), " en lugar de esperar 7 días para que venza el token."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "El token de acceso de la aplicación está a punto de vencer en 24 horas."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "El token de acceso de la aplicación ha rotado y vencido después de 7 días."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Si no has rotado tu token de acceso en más de 180 días, los superadministradores también recibirán un correo electrónico de recordatorio para rotar el token de acceso de tu aplicación."
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Ver los registros de llamadas de API"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para revisar las llamadas de API que realizó tu aplicación durante los últimos 30 días:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la página de detalles de tu aplicación, haz clic en la ficha ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Registros"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Revisa y filtra las llamadas de API de tu aplicación privada:", "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Haz clic en los menús desplegables ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Método"
            }), " y ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Respuesta"
            }), " para filtrar el historial de llamadas de API por método de solicitud o código de respuesta."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["Haz clic en los menús desplegables ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "fecha de inicio"
            }), " o ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "fecha final"
            }), " para limitar los registros de llamada a un intervalo de tiempo específico."]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["También puedes buscar llamadas específicas por URL en el cuadro de búsqueda ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Buscar por URL"
            }), "."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/private-app-logs-tab-updated.png",
        alt: "private-app-logs-tab-updated"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para exportar los registros de llamadas de API, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportar registros (CSV)"
        }), ". A continuación, especifica un intervalo de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "fechas"
        }), " (hasta los últimos 30 días) y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportar"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la casilla emergente, selecciona el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "rango de fechas"
        }), " de las llamadas a la API que deseas exportar y haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Exportar"
        }), ". Recibirás un correo electrónico con un enlace de descarga cuando haya finalizado la exportación."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Límites de las aplicaciones privadas"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes crear hasta 20 aplicaciones privadas. Cada aplicación privada se somete a las directrices de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/api-usage/usage-details",
        children: "directrices de uso de API de HubSpot"
      }), ". El número de llamadas que tu aplicación privada puede hacer se basa en la suscripción de tu cuenta y si compras el complemento para API:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {}), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Nivel de productos"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Por 10 segundos"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Por día"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicaciones privadas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Cualquier Hub)Free y Starter"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "100/cuenta"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "250.000/cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Cualquier Hub)Pro y Enterprise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "150/aplicación privada"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "500.000/cuenta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Aplicaciones privadas con complemento API"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "(Cualquier Hub)Gratuito, Starter, Pro y Enterprise."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "200/aplicación privada"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1.000.000/cuenta"
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Si tienes un plan Starter y Pro, se aplican límites para el nivel superior (Pro) a tu cuenta."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Puedes hacer una solicitud ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " a", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/account-info/v3/api-usage/daily/private-apps"
      }), " para revisar el uso diario de la API para todas las aplicaciones privadas en tu cuenta de HubSpot. Obtén más información sobre cómo usar la ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/account-information-api",
        children: "API de información de la cuenta"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Eliminar una aplicación privada"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Cuando elimines una de tus aplicaciones privadas, su token de acceso se revocará permanentemente y ya no podrás utilizarlo para hacer llamadas de API."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para eliminar una aplicación:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En tu cuenta de HubSpot, haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "ícono de configuración"
        }), " en la barra de navegación principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Haz clic en el ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "nombre"
        }), " de tu aplicación privada."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte superior de la página, haz clic en la pestaña ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Autorización"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En la parte inferior de la página, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eliminar [nombre de tu aplicación]"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["En el cuadro de diálogo, escribe el nombre de tu aplicación para confirmar su eliminación y luego, haz clic en ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Eliminar"
        }), "."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}