"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 65501378069;
const slug = exports.slug = 'guides/api/cms/content-audit';
const title = exports.title = "API d'audit de contenu CMS";
const name = exports.name = 'EMEA FR vNext Docs DP - CMS Content Audit API';
const metaDescription = exports.metaDescription = "L'API d'audit de contenu CMS vous permet de filtrer et de trier des modifications d'objet de contenu par type, par période ou par ID d'utilisateur HubSpot afin de rester au fait de chaque événement.";
const toc = exports.toc = [{
  "depth": 0,
  "id": "liste-des-types-d-%C3%A9v%C3%A9nement-cms",
  "label": "Liste des types d'événement CMS",
  "parent": null
}, {
  "depth": 0,
  "id": "liste-des-types-d-objet-cms",
  "label": "Liste des types d'objet CMS",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      em: "em",
      strong: "strong",
      h2: "h2",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      code: "code",
      a: "a"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink
    } = _components;
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Audit de contenu CMS"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["L'API Audit de contenu CMS vous permet de filtrer et de trier les modifications d'objet de contenu par type, par période ou par ID d'utilisateur HubSpot. Ce point de terminaison est uniquement disponible pour les comptes disposant d'un abonnement ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "CMS Hub"
        }), " Entreprise"]
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.strong, {
        children: "Exemple de cas d'utilisation :"
      }), " découvrez quel utilisateur a apporté les dernières modifications à une liste de pages."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Liste des types d'événement CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Type d'événement"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CREATED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un objet a été créé."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DELETED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un objet a été supprimé ou déconnecté."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "PUBLISHED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un objet a été publié."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "UNPUBLISHED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "La publication d'un objet a été annulée."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "UPDATED"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Un objet a été mis à jour."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Liste des types d'objet CMS"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Type d'objet"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les modifications apportées aux paramètres de votre blog dans les paramètres de votre compte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "BLOG_POST"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les articles de blog associés à votre blog."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CONTENT_SETTINGS"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les modifications apportées aux paramètres de votre site web dans les paramètres de votre compte. Les valeurs modifiées apparaitront dans le tableau JSON ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "meta"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "CTA"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les modifications apportées à vos ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/fr/cta/get-started-with-calls-to-action-ctas",
              children: "calls-to-action (CTA)"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "DOMAIN"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les modifications apportées aux domaines connectés dans vos paramètres Domaines et URL des paramètres de votre compte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "EMAIL"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les modifications apportées aux e-mails dans l'éditeur d'e-mails."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "FILE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les modifications apportées aux fichiers dans l'", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/fr/cos-general/organize-edit-and-delete-files",
              children: "outil Fichiers"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "GLOBAL_MODULE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les modifications apportées aux ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/fr/cos-general/can-i-make-changes-to-a-global-module-in-only-one-template",
              children: "modules globaux."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBDB_TABLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les modifications apportées aux tableaux HubDB."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_BASE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les modifications apportées aux paramètres de votre base de connaissances dans les paramètres de votre compte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "KNOWLEDGE_BASE_ARTICLE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les modifications apportées aux articles de base de connaissances dans l'éditeur de contenu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "LANDING_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les modifications apportées aux pages de destination dans l'éditeur de contenu."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "MODULE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les modifications apportées aux ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/modules/overview",
              children: "modules"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "SERVERLESS_FUNCTION"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les modifications apportées aux ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/data-driven-content/serverless-functions/overview",
              children: "fonctions sans serveur"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "TEMPLATE"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les modifications apportées aux ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/cms/content/templates/overview",
              children: "modèles"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "THEME"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Les modifications apportées aux ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/fr/website-pages/edit-your-global-theme-settings",
              children: "paramètres du thème"
            }), " et lors de la création de ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/fr/website-pages/edit-your-global-theme-settings",
              children: "thèmes"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "URL_MAPPING"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les modifications apportées à vos redirections d'URL dans les paramètres Redirections d'URL des paramètres de votre compte."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "WEBSITE_PAGE"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Les modifications apportées aux pages de site web dans l'éditeur de contenu."
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}