"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 166558216286;
const slug = exports.slug = 'guides/api/crm/objects/leads';
const title = exports.title = 'CRM API | Leads';
const name = exports.name = 'vNext Docs DP - Leads';
const metaDescription = exports.metaDescription = 'Leads are contacts or companies that are potential customers who have shown interest in your products or services. The leads endpoints allow you to manage this data and sync it between HubSpot and other systems.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "create-leads",
  "label": "Create leads",
  "parent": null
}, {
  "depth": 1,
  "id": "properties",
  "label": "Properties",
  "parent": "create-leads"
}, {
  "depth": 1,
  "id": "associations",
  "label": "Associations",
  "parent": "create-leads"
}, {
  "depth": 0,
  "id": "retrieve-leads",
  "label": "Retrieve leads",
  "parent": null
}, {
  "depth": 0,
  "id": "update-leads",
  "label": "Update leads",
  "parent": null
}, {
  "depth": 1,
  "id": "associate-existing-leads-with-records",
  "label": "Associate existing leads with records",
  "parent": "update-leads"
}, {
  "depth": 1,
  "id": "remove-an-association",
  "label": "Remove an association",
  "parent": "update-leads"
}, {
  "depth": 0,
  "id": "delete-leads",
  "label": "Delete leads",
  "parent": null
}, {
  "depth": 0,
  "id": "limits",
  "label": "Limits",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      code: "code",
      ul: "ul",
      li: "li",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Tabs,
      Tab,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Leads"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/prospecting/set-up-leads",
        children: "leads"
      }), " are contacts or companies that are potential customers who have shown interest in your products or services. The leads endpoints allow you to create and manage lead records in your HubSpot account, as well as sync lead data between HubSpot and other systems."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Before using the API, be sure leads have been ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/prospecting/set-up-leads",
        children: "set up in your account"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about objects, records, properties, and associations APIs in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Understanding the CRM"
      }), " guide. For more general information about objects and records in HubSpot, ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts-user-guide",
        children: "learn how to manage your CRM database"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Create leads"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To create new leads, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/leads"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["In the request body, include any details about the lead in a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "properties"
      }), " object. Your new lead:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Must have a lead name, specified using the hs_lead_name property."
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "Must be associated with an existing contacts."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Should only be assigned to a user with a ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account-management/manage-seats",
          children: "seat"
        }), ". (Leads can only be worked via the workspace)."]
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "For example, to create a warm lead named “Jane Doe” who has a type of New Business, your request body would resemble the following:"
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST request to /crm/v3/objects/leads\n\"associations\": [\n{\n\"types\": [\n{\n\"associationCategory\": \"HUBSPOT_DEFINED\",\n\"associationTypeId\":578\n}\n],\n\"to\": {\n\"id\": \"YOUR_CONTACT_ID\"\n}\n}\n]\n\"properties\": {\n  \"hs_lead_name\": \"Jane Doe\",\n  \"hs_lead_type\": \"NEW BUSINESS\",\n  \"hs_lead_label\": \"WARM\"\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Properties"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Lead details are stored in lead properties. There are ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/properties/hubspots-default-lead-properties",
        children: "default HubSpot lead properties"
      }), ", but you can also ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/manage-your-properties",
        children: "create custom lead properties"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To view all available properties, you can retrieve a list of your account's lead properties by making a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/properties/leads"
      }), ". Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties",
        children: "properties API"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "See the table below for some common properties for leads:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "PROPERTY"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "DESCRIPTION"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Lead name"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "The full name of the lead."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Lead type"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A dropdown list of lead types. You can edit or add new labels in your ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/properties/create-and-edit-properties#view-and-edit-properties",
              children: "lead property settings."
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Lead label"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The current status of the lead. You can edit or add new labels in your ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/properties/create-and-edit-properties#view-and-edit-properties",
              children: "lead property settings."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associations"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["When creating a new lead you can also associate the lead with ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-records",
        children: "existing records"
      }), " or ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/associate-activities-with-records",
        children: "activities"
      }), " in an associations object. For example, to associate a new lead with an existing contact with an ID of 12345, your request would look like the following:"]
    }), "\n", (0, _jsxRuntime.jsx)(Tabs, {
      defaultSelected: "0",
      children: (0, _jsxRuntime.jsx)(Tab, {
        tabId: "0",
        title: "JSON",
        children: (0, _jsxRuntime.jsx)(_components.pre, {
          children: (0, _jsxRuntime.jsx)(_components.code, {
            className: "language-json",
            children: "// Example POST request to /crm/v3/objects/leads\n\"properties\": {\n  \"hs_lead_name\": \"Jane Doe\",\n  \"hs_lead_type\": \"NEW BUSINESS\",\n  \"hs_lead_label\": \"WARM\",\n}\n"
          })
        })
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " once created, a lead's associated primary contact and company shouldn't be updated."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In the associations object, you should include the following:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "to"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The record or activity you want to associate with the lead, specified by its unique ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "id"
            }), " value."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "types"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["The type of the association between the lead and the record/activity. Include the ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationCategory"
            }), "and ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "associationTypeId"
            }), ". Default association type IDs are listed ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
              children: "here"
            }), ", or you can retrieve the value for custom association types (i.e. labels) via the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4#retrieve-association-types",
              children: "associations API"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Retrieve leads"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You can retrieve leads individually or in batches."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve an individual lead, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/leads/{leadsId}"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To request a list of all leads, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v3/objects/leads"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For these endpoints, you can include the following query parameters in the request URL:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Description"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "properties"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the properties to be returned in the response. If the requested contact doesn't have a value for a property, it will not appear in the response."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "propertiesWithHistory"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "A comma separated list of the current and historical properties to be returned in the response. If the requested contact doesn't have a value for a property, it will not appear in the response."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "associations"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A comma separated list of objects to retrieve associated IDs for. Any specified associations that don't exist will not be returned in the response. Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/associations/associations-v4",
              children: "associations API."
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["To retrieve a batch of specific leads by ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v3/objects/leads/batch/read"
        }), ". The batch endpoint ", (0, _jsxRuntime.jsx)("u", {
          children: "can't"
        }), " retrieve associations. Learn how to batch read associations with the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4",
          children: "associations API"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["For the batch read endpoint, you can use the optional ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "Property"
      }), " parameter to retrieve leads by ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "leadID"
      }), " or a custom ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/properties#create-unique-identifier-properties",
        children: "unique identifier property"
      }), ". By default, the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "id"
      }), " values in the request refer to the record ID (", (0, _jsxRuntime.jsx)(_components.code, {
        children: "hs_object_id"
      }), "), so the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " parameter isn't required when retrieving by record ID. If you're using a custom unique value property to retrieve leads, you must include the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "idProperty"
      }), " parameter."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Update leads"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can update leads individually or in batches. For existing leads, the leads ID is a unique value, so you can use ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "leadsId"
      }), " to update leads via the API."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To update an individual lead by its lead ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PATCH"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/leads/{leadsId}"
      }), ", and include the data you want to update in the request body."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associate existing leads with records"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To associate a lead with other CRM records or an activity, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/leads/{leadsId}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["To retrieve the ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "associationTypeId"
        }), " value, refer to ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/associations/associations-v4#association-type-id-values",
          children: "this list"
        }), " of default values, or make a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/associations/associations-v4",
        children: "associations API."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Remove an association"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To remove an association between a lead and a record or activity, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to the following URL: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/leads/{leadsID}/associations/{toObjectType}/{toObjectId}/{associationTypeId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you remove all primary associations to the lead, the lead will automatically be deleted."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Delete leads"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can delete leads individually or in batches, which will add the contact to the recycling bin in HubSpot. You can later ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/contacts/restore-deleted-contacts-companies-deals-or-tickets",
        children: "restore the contact within HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To delete an individual contact by its ID, make a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "DELETE"
      }), " request to ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v3/objects/contacts/{contactId}"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Learn more about batch deleting leads in the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://developers.hubspot.com/beta-docs/reference/api/crm/objects/leads",
        children: "reference documentation"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Limits"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Batch operations for creating, updating, and archiving are limited to batches of 100."
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}