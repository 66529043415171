"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 35219164725;
const slug = exports.slug = 'guides/cms/content/data-driven-content/emails-with-programmable-content';
const title = exports.title = 'Create emails with programmable content';
const name = exports.name = 'Create emails with programmable content';
const metaDescription = exports.metaDescription = '';
const toc = exports.toc = [{
  "depth": 0,
  "id": "email-sending-limits",
  "label": "Email sending limits",
  "parent": null
}, {
  "depth": 0,
  "id": "1.-create-a-programmable-email-module",
  "label": "1. Create a programmable email module",
  "parent": null
}, {
  "depth": 1,
  "id": "standard-objects",
  "label": "Standard objects",
  "parent": "1.-create-a-programmable-email-module"
}, {
  "depth": 1,
  "id": "custom-objects",
  "label": "Custom objects",
  "parent": "1.-create-a-programmable-email-module"
}, {
  "depth": 1,
  "id": "hubdb",
  "label": "HubDB",
  "parent": "1.-create-a-programmable-email-module"
}, {
  "depth": 0,
  "id": "2.-add-the-module-to-an-email",
  "label": "2. Add the module to an email",
  "parent": null
}, {
  "depth": 0,
  "id": "more-hubdb-focused-tutorials",
  "label": "More HubDB focused tutorials",
  "parent": null
}, {
  "depth": 0,
  "id": "hubspot-academy",
  "label": "HubSpot Academy",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      ul: "ul",
      li: "li",
      strong: "strong",
      em: "em",
      h2: "h2",
      code: "code",
      img: "img",
      h3: "h3",
      pre: "pre"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert,
      ProductTier
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!ProductTier) _missingMdxReference("ProductTier", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Create emails with programmable content"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["This feature is currently in beta. By using this functionality you agree to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/developerbetaterms",
          children: "developer beta terms"
        }), ". This guide refers to functionality available only through that beta. ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/email/create-programmable-emails",
          children: "Opt-into the beta in your templates or modules."
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(ProductTier, {
      tiers: ['marketing_hub-professional', 'marketing_hub-enterprise']
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Using programmable content to personalize emails with data from your HubSpot account using HubL."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "The data sources you can use in a programmable email depend on your HubSpot subscription:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you have a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketing Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Professional"
        }), " subscription, you can use data from standard CRM objects, such as contacts, companies, and products."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["If you have a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: (0, _jsxRuntime.jsx)(_components.em, {
            children: "Marketing Hub"
          })
        }), " ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Enterprise"
        }), " subscription, you can also use structured data sources such as ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "HubDB"
        }), " tables and ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/use-custom-objects",
          children: "custom objects"
        }), ". This data can be filtered based on the contact properties of the recipient."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "For example, a real estate website could have prospects fill out a form with their home needs. The form submission could then trigger a workflow that sends the prospect an email with homes they may be interested in."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Email sending limits"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can include the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "crm_object"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "crm_objects"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: "crm_associations"
      }), " CRM HubL functions in a programmable email, but any email that includes these specific functions are subject to the following limits that are based on the number of recipients you're sending to:"]
    }), "\n", (0, _jsxRuntime.jsx)("table", {
      children: (0, _jsxRuntime.jsxs)("tbody", {
        children: [(0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("strong", {
              children: "Total recipients"
            })
          }), (0, _jsxRuntime.jsx)("td", {
            children: (0, _jsxRuntime.jsx)("strong", {
              children: "Maximum number of CRM HubL functions"
            })
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "500,000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "1"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "250,000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "2"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "165,000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "3"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "125,000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "4"
          })]
        }), (0, _jsxRuntime.jsxs)("tr", {
          children: [(0, _jsxRuntime.jsx)("td", {
            children: "100,000"
          }), "\n", (0, _jsxRuntime.jsx)("td", {
            children: "5"
          })]
        })]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Sending an email that meets or exceeds one of the limits above will delay or cancel the sending of your email."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "In addition to the limits outlined above, be aware of the additional caveats listed below:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: "If you clone a programmable email, it cannot be sent while the original is still in a processing state. You should wait at least 1 hour between each email send."
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You cannot conduct an A/B test for a programmable email that includes a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_objects"
        }), ", or ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_associations"
        }), " HubL function."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "1. Create a programmable email module"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To create the email module to access your HubDB or custom object data:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Marketing"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Files and Templates"
          }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Design Tools"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the upper left, click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "File"
          }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "New file"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["In the dialog box, select ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Module"
          }), " for the file type, then click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Next"
          }), ". Then, select the ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Emails"
          }), " checkbox and enter a ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "name"
          }), " for the file."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Click ", (0, _jsxRuntime.jsx)(_components.strong, {
            children: "Create"
          }), "."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "To enable programmable email for the module:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["In the inspector on the right, toggle the ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Enable module for programmable email beta"
            }), " switch on."]
          }), "\n"]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/programmable-email-switch0.png",
            alt: "programmable-email-switch0"
          })
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["You can also enable programmable email for a coded email template by adding ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "isEnabledForEmailV3Rendering: true"
          }), " to the top of the file."]
        }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://knowledge.hubspot.com/hs-fs/hubfs/Imported%20sitepage%20images/enable-design-manager-template-for-programmable-email.png?width=510&name=enable-design-manager-template-for-programmable-email.png",
            alt: "enable-design-manager-template-for-programmable-email"
          })
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With the module created, you'll then add code to access data from your CRM. The following examples below demonstrate how to query from different data sources."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Standard objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["You can use the HubL functions such as ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: "crm_object"
      }), ", ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "crm_objects"
      }), ", and ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-associations",
        children: "crm_associations"
      }), " to query data from standard objects in your account, such as contacts, companies, or products."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The code below uses the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-object",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm_object"
        })
      }), " HubL function to query the data from a product with an ID of ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "2444498793"
      }), " and render the name, description, and price:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set product = crm_object(\"product\", 2444498793, \"name,description,price\") %}\n\n<div>\n  <p>\n    <span>Name: </span>\n    <span>{{ product.name }}</span>\n  </p>\n  <p>\n    <span>Description: </span>\n    <span>{{ product.description }}</span>\n  </p>\n  <p>\n    <span>Price: </span>\n    <span>{{ product.price }}</span>\n  </p>\n</div>\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Custom objects"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have a ", (0, _jsxRuntime.jsxs)(_components.em, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing Hub"
        }), " Enterprise"]
      }), " account, you can query data from a custom object you've created in your account."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The code below retrieves data from a custom object named ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Property"
      }), ", returning values (e.g. location, price) stored in the custom object's properties."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Note that the example below uses the custom object's fully qualified name as the first argument when invoking the ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm_objects"
      }), " ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#crm-objects",
        children: "HubL function"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["The fully qualified name begins with the HubSpot account ID (prefixed by ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "p"
        }), "), followed by an underscore and the lower-cased plural name of the custom object (e.g., ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "properties"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["You can retrieve an object's ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "fullyQualifiedName"
        }), " by making a ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " request to the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/objects/custom-objects",
          children: "CRM Objects schema API"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set real_estate_listings = crm_objects(\"p2990812_properties\", \"\", \"listing_name,location, price, address, type\") %}\n\n{% for home in real_estate_listings.results %}\n    {{ home.address}} <br>\n\t{{ home.price }} <br>\n\t<img\n\talt=\"{{ home.name }}\"\n\tsrc=\"{{ home.hero_image }}\"\n\tstyle=\"outline: none; max-width: 100%;\"\n\twidth=\"260px\"\n\t/>\n\t<br>\n\t<hr>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Please note:"
        }), " if the name of your custom object contains hyphens (e.g., ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "My-Custom-Object"
        }), "), its properties can ", (0, _jsxRuntime.jsx)("u", {
          children: "not"
        }), " be rendered in a programmable email. You can recreate the custom object with the hyphens omitted ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-custom-objects",
          children: "directly in your HubSpot account"
        }), ", or you can use the ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/objects/custom-objects",
          children: "custom object API"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To filter the data returned for each recipient, you can add a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), " parameter, which will filter the results by the recipient's contact properties. View the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/api/cms/hubdb/v2#get-table-rows",
        children: "full list of filter options"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set query = \"price__lte=\"~contact.budget_max|int~\"&price__gte=\"~contact.budget_min|int~\"&city=\"~contact.city\"&order=listing_name\" %}\n{% set real_estate_listings = crm_objects(\"p2990812_Property\", query, \"listing_name,location, price, address, type\") %}\n{% for home in real_estate_listings.results %}\n\n  ...\n\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "HubDB"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["If you have a ", (0, _jsxRuntime.jsx)(_components.strong, {
        children: (0, _jsxRuntime.jsx)(_components.em, {
          children: "Marketing Hub"
        })
      }), " ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Enterprise"
      }), " account, you can use data from a HubDB table in your email."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["The code below uses the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/hubl/functions#hubdb-table-rows",
        children: (0, _jsxRuntime.jsx)(_components.code, {
          children: "hubdb_table_rows"
        })
      }), " HubL function to retrieve all data from the table. This will list all the real estate properties in the email, outputting the details of each property along with their image."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set real_estate_listings = hubdb_table_rows(1234567) %}\n\n{% for home in real_estate_listings%}\n    {{ home.address}} <br>\n\t{{ home.price }} <br>\n\t<img\n\talt=\"{{ home.name }}\"\n\tsrc=\"{{ home.hero_image.url }}\"\n\tstyle=\"outline: none; max-width: 100%;\"\n\twidth=\"260px\"\n\t/>\n\t<br>\n\t<hr>\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["To filter the data returned for each recipient, you can add a ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "query"
      }), " parameter, which will filter results by the specified contact properties. View the ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/cms/hubdb#filter-returned-rows",
        children: "full list of filter options"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-hubl",
        children: "{% set query = \"price__lte=\"~contact.budget_max|int~\"&price__gte=\"~contact.budget_min|int~\"&persona=\"~contact.hs_persona.value~\"&order=listing_name\" %}\n\n{% for home in real_estate_listings %}\n\n\t...\n\n{% endfor %}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["In the above example, the contact property ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Budget max"
        }), " is referenced with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.budget_max"
        }), ", while ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Persona"
        }), " is referenced with ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact.hs_persona.value"
        }), ". This is because ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Persona"
        }), " is an ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/account/property-field-types-in-hubspot",
          children: "enumeration property"
        }), ", which requires an additional ", (0, _jsxRuntime.jsx)(_components.code, {
          children: ".value"
        }), " to parse the property's value, while other property types do not."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "2. Add the module to an email"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "With the module published, you'll now add it to the body of the drag and drop email."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In your HubSpot account, navigate to ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Marketing"
        }), " > ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Email"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Select the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "email"
        }), " that you created."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the left sidebar, under ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Content"
        }), ", click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "More"
        }), ". Find your programmable email module, then drag it into the email body."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/drag-and-drop-email-editor-more-modules.png",
        alt: "drag-and-drop-email-editor-more-modules"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If you've set up the module to filter data by specific contact properties, the email preview will appear blank. This is because the email tool hasn't been set to preview the email by a specific contact."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "To preview what the email will look like for a specific contact:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["In the upper right, click ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Actions"
        }), ", then select ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preview"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/email-actions-menu0.png",
        alt: "email-actions-menu0"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["On the next screen, click the ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Preview as a specific contact"
        }), " dropdown menu, then select a ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "contact"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/preview-email-as-specific-contact0.png",
        alt: "preview-email-as-specific-contact0"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "You should now see only the data relevant to the recipient, filtered by their contact properties."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://f.hubspotusercontent00.net/hubfs/53/preview%20window.png",
        alt: "Screenshot of Email Preview"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "If an email template or module contains custom code with unresolved HubL, a placeholder module will appear in its place."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2023_2024/placeholder-module-in-programmable-email.png",
        alt: "placeholder-module-in-programmable-email"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: "It's important to always have fallback data to send in the case that there are no HubDB rows or custom object records that meet the criteria you've set. Otherwise, the recipient might receive a blank email."
      }), (0, _jsxRuntime.jsx)(_components.p, {
        children: "This beta may cause issues with existing templates. It's important to test any changes thoroughly before publishing and sending a live email."
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "More HubDB focused tutorials"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/hubdb",
          children: "Building dynamic pages with HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/storage/hubdb/overview",
          children: "How to join multiple HubDB tables"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/dynamic-team-member-page",
          children: "How to build a dynamic team member page with HubDB"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/data-driven-content/dynamic-pages/multilevel",
          children: "How to build multilevel dynamic pages using HubDB"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "HubSpot Academy"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://academy.hubspot.com/lessons/other_data_sources_hubdb_and_custom_objects",
          children: "Using HubDB and Custom Objects in CMS Hub"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}