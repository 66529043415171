"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.featuredImageAltText = exports.featuredImage = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29844694125;
const slug = exports.slug = 'guides/cms/content/themes/overview';
const title = exports.title = 'Überblick über Design';
const name = exports.name = 'Überblick über Design';
const metaDescription = exports.metaDescription = 'Erstellung von CMS Hub-Designs, die es Entwicklern ermöglichen, Designsysteme für die Arbeit von Content-Autorenn zu erstellen. ';
const featuredImage = exports.featuredImage = 'https://cdn2.hubspot.net/hubfs/53/themes.gif';
const featuredImageAltText = exports.featuredImageAltText = 'HubSpot-Designs';
const toc = exports.toc = [{
  "depth": 0,
  "id": "designs-als-paket",
  "label": "Designs als Paket",
  "parent": null
}, {
  "depth": 1,
  "id": "seitenerstellung",
  "label": "Seitenerstellung",
  "parent": "designs-als-paket"
}, {
  "depth": 1,
  "id": "designfelder",
  "label": "Designfelder",
  "parent": "designs-als-paket"
}, {
  "depth": 1,
  "id": "designmodule",
  "label": "Designmodule",
  "parent": "designs-als-paket"
}, {
  "depth": 0,
  "id": "struktur-der-designdatei",
  "label": "Struktur der Designdatei",
  "parent": null
}, {
  "depth": 1,
  "id": "theme.json",
  "label": "theme.json",
  "parent": "struktur-der-designdatei"
}, {
  "depth": 1,
  "id": "fields.json",
  "label": "fields.json",
  "parent": "struktur-der-designdatei"
}, {
  "depth": 2,
  "id": "designfeldwerte-verwenden",
  "label": "Designfeldwerte verwenden",
  "parent": "struktur-der-designdatei"
}, {
  "depth": 0,
  "id": "designs-in-einer-vorschau-anzeigen",
  "label": "Designs in einer Vorschau anzeigen",
  "parent": null
}, {
  "depth": 1,
  "id": "testmodus",
  "label": "Testmodus",
  "parent": "designs-in-einer-vorschau-anzeigen"
}, {
  "depth": 0,
  "id": "verwandte-ressourcen",
  "label": "Verwandte Ressourcen",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      h2: "h2",
      h3: "h3",
      img: "img",
      code: "code",
      pre: "pre",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      ul: "ul",
      li: "li",
      h4: "h4",
      strong: "strong"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      HubspotVideoPlayer,
      Alert,
      DndSection,
      DndModule
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!HubspotVideoPlayer) _missingMdxReference("HubspotVideoPlayer", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Übersicht über Designs"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Design ist eine übertragbare und geschlossene Sammlung von Entwicklerelementen, die eine flexible Bearbeitung von Inhalten ermöglichen. Sie können ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/setup/getting-started-with-local-development",
        children: "Designs lokal mit dem HubSpot-CLI unter Verwendung der von Ihnen bevorzugten Tools, Verfahren und Arbeitsabläufe erstellen"
      }), ". Designs und alle ihre Dateien sind auch zwischen Umgebungen und Accounts übertragbar. Eine Videoanleitung für den Einstieg in die Entwicklung von Designs finden Sie im folgenden HubSpot Academy-Video:"]
    }), "\n", (0, _jsxRuntime.jsx)(HubspotVideoPlayer, {
      videoId: "172844605010"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Designs als Paket"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Da Designs ein Paket sind, werden sie in der HubSpot-App an verschiedenen Stellen kaskadiert, um eine effiziente Content-Erstellung zu ermöglichen. Entwickler können mithilfe von Designs ein System erstellen, in dem die Content-Autoren arbeiten können. Ein Design kann mit einem beliebigen Maß an Flexibilität ausgestattet werden, ebenso mit Leitlinien, um den Anforderungen Ihres Unternehmens gerecht zu werden."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Seitenerstellung"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Wenn Content-Autoren mit der Erstellung neuer Seiten beginnen, werden sie aufgefordert, zunächst das Design auszuwählen, mit dem sie eine Seite erstellen möchten, und dann die Vorlage auszuwählen, die sie innerhalb des Designs verwenden möchten."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: [(0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/theme%20selection.gif",
        alt: "Designauswahl innerhalb von HubSpot"
      }), "Das Vorschaubild des Designs sowie andere Konfigurationen für ein Design werden in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#theme-json",
        children: "theme.json"
      }), "-Datei festgelegt."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Designfelder"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Mithilfe von Designs können Entwickler eine Reihe von ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json",
        children: "Designfeldern"
      }), " erstellen, ähnlich wie Modulfelder, die es Content-Autoren ermöglichen, anhand verschiedener von einem Entwickler entworfenen Steuerelemente und Einstellungen eine globale stilistische Kontrolle über eine Website zu erlangen, ohne CSS bearbeiten zu müssen. Entwickler ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#using-theme-field-values",
        children: "verwenden HubL, um in ihrem CSS auf die Werte von Designfeldern zuzugreifen"
      }), ". Content-Autoren nutzen den Design-Editor, um Designfelder zu ändern, eine Vorschau dieser Änderungen anhand der vorhandenen Vorlagen innerhalb eines Designs anzuzeigen und ihre Änderungen zu veröffentlichen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/themes.gif",
        alt: "themes"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Designfelder werden durch die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#fields-json",
        children: "fields.json-Datei eines Designs"
      }), " festgelegt."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie ein Design im ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/themes/overview#test-mode",
        children: "Testmodus"
      }), " bearbeiten, können Sie auch die JSON-Einstellungen des Designs kopieren. Dadurch können Sie alle Aktualisierungen in die lokale ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "-Datei des Designs einfügen.", (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-theme-settings-test-mode.png",
        alt: "copy-theme-settings-test-mode"
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Designmodule"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Die Module eines Designs sollten speziell für die Verwendung in den Vorlagen dieses Designs konzipiert sein. Der Content-Editor hebt diese Designmodule hervor, sodass Content-Autoren schnell und einfach Module zu den von ihnen erstellten Seiten hinzufügen können, die für den Kontext der jeweiligen Seite geeignet sind. Die Standardmodule und die übrigen Module in Ihrem HubSpot-Account sind weiterhin verfügbar."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/theme%20modules.png",
        alt: "Designmodule innerhalb eines Designs"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Wenn Sie ein Design ausarbeiten, können Sie ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/modules/hide-modules-and-sections",
        children: "Module und Abschnitte im Seiten-Editor ausblenden"
      }), ", um eine optimierte Content-Erstellung zu ermöglichen."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Struktur der Designdatei"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Ein Design ist ein einzelnes Verzeichnis mit Dateien. Sie können HTML-, CSS- und JavaScript-Dateien, Module und zusätzliche Dateien einbinden, die in Unterverzeichnissen des übergeordneten Designordners auf beliebige Weise organisiert werden können. Zur Erstellung eines Designs sind zwei JSON-Dateien erforderlich: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), " und ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), ". Diese Dateien sollten in den Stammordner des Designs aufgenommen werden."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um mit einem Beispiel zu beginnen, sehen Sie sich die ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://github.com/HubSpot/cms-theme-boilerplate",
        children: "CMS Hub-Boilerplate"
      }), " an."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://designers.hubspot.com/hs-fs/hubfs/theme%20file%20structure.png?width=599&height=452&name=theme%20file%20structure.png",
        alt: "Struktur der Designdatei"
      })
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Zurzeit können .json-Dateien nur über die ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/setup/getting-started-with-local-development",
          children: "Tools für lokale Entwicklung"
        }), " erstellt und in einen HubSpot-Account hochgeladen werden."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "theme.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "-Datei enthält die Meta-Informationen für Ihr Designverzeichnis, z. B. das lesbare Label des Designs, seinen Vorschau-Screenshot und verschiedene Konfigurationen, wie sich das Design verhalten soll. Ihre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "theme.json"
      }), "-Datei sieht dann etwa so aus wie die folgende:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// theme.json\n{\n  \"label\": \"Cool Theme\",\n  \"preview_path\": \"./templates/home-page.html\",\n  \"screenshot_path\": \"./images/templates/homepage.jpg\",\n  \"enable_domain_stylesheets\": false,\n  \"version\": \"1.0\",\n  \"author\": {\n    \"name\": \"Jon McLaren\",\n    \"email\": \"noreply@hubspot.com\",\n    \"url\": \"https://theme-provider.com/\"\n  },\n  \"documentation_url\": \"https://theme-provider.com/cool-theme/documentation\",\n  \"license\": \"./license.txt\",\n  \"example_url\": \"https://theme-provider.com/cool-theme/demo\",\n  \"is_available_for_new_content\": true\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Parameter"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Typ"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Beschreibung"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "label"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Das lesbare Label des Designs, das an verschiedenen Stellen in der HubSpot-App verwendet wird, z. B. im Bildschirm zur Vorlagenauswahl und im Design-Editor."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "preview_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein relativer Pfad zu einer Vorlagendatei im Design, die die Standardvorlage sein soll, die bei der Vorschau eines Designs im Design-Editor verwendet wird."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "screenshot_path"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ein relativer Pfad zu einer Bilddatei, die verwendet wird, um eine Momentaufnahme davon zu erstellen, wie das Design an verschiedenen Stellen der Designauswahl aussieht, z. B. im Bildschirm für die Vorlagenauswahl."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "enable_domain_stylesheets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Aktivieren oder Deaktivieren von Stylesheets, die an Domains in den Website-Einstellungen angehängt sind und in Vorlagen innerhalb des Designs berücksichtigt werden. Der Standardwert ist ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "false"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "version"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ganzzahlige Versionsnummer, die ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "."
            }), "-Versionen unterstützt."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "Author"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Objekt"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Objekt, mit dem Informationen über sich selbst als Designanbieter bereitgestellt werden.", (0, _jsxRuntime.jsx)(_components.code, {
              children: "name"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "Der Name des Anbieters. ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "email"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "Die Support-E-Mail-Adresse des Anbieters. ", (0, _jsxRuntime.jsx)("br", {}), (0, _jsxRuntime.jsx)(_components.code, {
              children: "url"
            }), " ", (0, _jsxRuntime.jsx)("br", {}), "Die Website des Anbieters.", (0, _jsxRuntime.jsx)("br", {})]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "documentation_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Link zur Designdokumentation."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "example_url"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Der Live-Beispiel-Link des Designs."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "license"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Zeichenfolge"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Ein gültiger ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://spdx.org/licenses/",
              children: "SPDX-Identifikator"
            }), " oder der relative Pfad zur Lizenz innerhalb Ihres Designs. Diese Lizenz legt fest, welche Nutzung und Änderungen durch den Ersteller des Designs erlaubt sind. Nützlich bei der Einreichung beim Marketplace."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "is_available_for_new_content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Boolesch"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Boolescher Wert, der festlegt, ob ein Design auf der Seite des Content-Autors zur Auswahl angezeigt wird. Der Standardwert ist ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "true"
            }), "."]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "fields.json"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Die ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), "-Datei steuert die verfügbaren Felder und Feldgruppen im Design-Editor, einschließlich der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/fields/overview#style-fields",
        children: "Stilfelder"
      }), ". Welche Felder Sie berücksichtigen, hängt davon ab, wie viel Kontrolle die Content-Autoren über den Seiten-Editor haben sollen. Die Anzahl der Felder, die für Designs zur Verfügung stehen, ist begrenzter als bei Modulen, da sich Designfelder am besten für Formatierungsoptionen eignen, während ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/cms/content/global-content",
        children: "globale Inhalte"
      }), " besser für Designinhalte geeignet sind."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Anstatt beispielsweise ein Textfeld für den Slogan Ihrer Website zum ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "field.json"
      }), " des Designs hinzuzufügen, sollte es als globales Modul hinzugefügt werden, damit die Content-Autoren den Slogan über den Seiten-Editor und nicht über den Design-Editor aktualisieren können."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Diese Felder können in Designs verwendet werden:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#boolean",
          children: "Boolesch"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#border",
          children: "Rahmen"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#choice",
          children: "Auswahl"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#color",
          children: "Farbe"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#font",
          children: "Schriftart"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#image",
          children: "Bild"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#number",
          children: "Zahl"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/cms/fields/module-theme-fields#spacing",
          children: "Abstand"
        })
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine umfassende Dokumentation der möglichen Optionen für Designfelder finden Sie in der ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/reference/cms/fields/module-theme-fields",
        children: "Dokumentation zu Modulen und Designfeldern"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h4, {
      children: "Designfeldwerte verwenden"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Um auf Feldwerte zuzugreifen, verwenden Sie die Punktnotation und stellen dem Pfad zu dem Wert in ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "fields.json"
      }), " das Präfix „theme“ voran. Sie können einen Designfeldwert in Ihren Stylesheets mit einer Syntax wie ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "{{ theme.path.to.value }}"
      }), " verwenden. Zum Beispiel das unten dargestellte Schriftartfeld:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "// fields.json\n[\n  {\n    \"type\": \"group\",\n    \"name\": \"typography\",\n    \"label\": \"Typography\",\n    \"children\": [\n      {\n        \"type\": \"font\",\n        \"name\": \"h1_font\",\n        \"label\": \"Heading 1\",\n        \"load_external_fonts\": true,\n        \"default\": {\n          \"color\": \"#000\",\n          \"font\": \"Merriweather\",\n          \"font_set\": \"GOOGLE\",\n          \"variant\": \"700\",\n          \"size\": \"48\"\n        }\n      }\n    ]\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Würde in CSS referenziert folgendermaßen werden:"
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-css",
        children: "h1 {\n font-size: {{ theme.typography.h1_font.size }}px;\n font-family: {{ theme.typography.h1_font.font }};\n color: {{ theme.typography.h1_font.color }};\n text-decoration: {{ theme.typography.h1_font.styles.text-decoration }};\n font-style: {{ theme.typography.h1_font.styles.font-style }};\n font-weight: {{ theme.typography.h1_font.styles.font-weight }};\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Designs in einer Vorschau anzeigen"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Entwickler müssen manchmal testen können, ob ihre Designfelder ordnungsgemäß funktionieren, ohne dass dies Auswirkungen auf eine echte Seite hat. Hier kommt der Design-Testmodus ins Spiel."
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Testmodus"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)("div", {
          children: (0, _jsxRuntime.jsxs)(_components.p, {
            children: ["Der Testmodus bietet Ihnen eine sichere Umgebung, in der Sie mit den Feldern Ihres Designs experimentieren und sicherstellen können, dass sie so funktionieren, wie Sie es erwarten. Die Benutzeroberfläche sieht genauso aus wie ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://knowledge.hubspot.com/website-pages/edit-your-global-theme-settings#edit-theme-settings",
              children: "die Designvorschau/der Design-Editor, die bzw. den Content-Autoren sehen"
            }), ". Sie können jedoch sicher sein, dass Sie die Einstellungen Ihrer eigentlichen Website nicht ändern. Zum Schutz vor versehentlichen Aktualisierungen der Designeinstellungen ist die Veröffentlichung im Testmodus blockiert. Sie können feststellen, ob Sie sich im Testmodus befinden, da ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "?testmode=true"
            }), " in Ihrer Adressleiste angezeigt wird. Außerdem sehen Sie ein Testmodus-Symbol im Header des Design-Editors."]
          })
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 6,
        children: (0, _jsxRuntime.jsx)(_components.p, {
          children: (0, _jsxRuntime.jsx)(_components.img, {
            src: "https://f.hubspotusercontent00.net/hubfs/53/theme-test-mode.png",
            alt: "Design-Test-/Vorschaumodus"
          })
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Es gibt zwei Möglichkeiten, den Testmodus zu aktivieren:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "So aktivieren Sie den Testmodus über den Design-Manager:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Wählen Sie im Design-Manager Ihr ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Design"
              }), " im Finder aus."]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Klicken Sie oben in der linken Seitenleiste auf die Schaltfläche ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Vorschau"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/copy-theme-settings-test-mode-design-manager%20(1).png",
                alt: "copy-theme-settings-test-mode-design-manager (1)"
              })
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.p, {
          children: "So aktivieren Sie den Testmodus über den Seiten-Editor:"
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Klicken Sie im Seiten-Editor in der linken Seitenleiste auf die Registerkarte ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Design"
              }), " und klicken Sie dann auf ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Designeinstellungen bearbeiten"
              }), "."]
            }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
              children: (0, _jsxRuntime.jsx)(_components.img, {
                src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/page-editor-edit-theme.png",
                alt: "page-editor-edit-theme"
              })
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
              children: ["Fügen Sie der URL ", (0, _jsxRuntime.jsx)(_components.code, {
                children: "?testmode=true"
              }), " hinzu und drücken Sie dann die ", (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Eingabetaste"
              }), ". Sie befinden sich dann im Testmodus."]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Eine andere Methode besteht darin, die Designeinstellungen im Seiten-Editor zu öffnen. Fügen Sie dann den Abfrageparameter ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "?testmode=true"
      }), " zur URL in Ihrer Adressleiste hinzu."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Verwandte Ressourcen"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/getting-started",
          children: "Erste Schritte mit Designs"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/improve-older-sites/add-theme-features-to-existing-sites",
          children: "Hinzufügen von Designfunktionen zu bestehenden Websites"
        })
      }), "\n", (0, _jsxRuntime.jsx)(_components.li, {
        children: (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/cms/content/themes/hubspot-cms-boilerplate",
          children: "HubSpot Theme Boilerplate"
        })
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}