"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 29907021628;
const slug = exports.slug = 'guides/apps/authentication/working-with-oauth';
const title = exports.title = 'Trabalhando com OAuth';
const name = exports.name = 'vNext DP de documentos - Trabalhar com OAuth';
const metaDescription = exports.metaDescription = 'O OAuth é uma maneira segura de autenticação do aplicativo. Ele usa tokens de autorização, em vez de uma senha, para conectar seu aplicativo a uma conta de usuário. ';
const toc = exports.toc = [{
  "depth": 0,
  "id": "iniciando-uma-integra%C3%A7%C3%A3o-com-oauth-2.0",
  "label": "Iniciando uma integração com OAuth 2.0",
  "parent": null
}, {
  "depth": 0,
  "id": "par%C3%A2metros-de-consulta",
  "label": "Parâmetros de consulta",
  "parent": null
}, {
  "depth": 0,
  "id": "configurar-escopos",
  "label": "Configurar escopos",
  "parent": null
}, {
  "depth": 0,
  "id": "documentos-relacionados",
  "label": "Documentos relacionados",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      ul: "ul",
      li: "li",
      h5: "h5",
      h2: "h2",
      em: "em",
      img: "img",
      code: "code",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_components.h1, {
      children: "Trabalhar com OAuth"
    }), "\n", (0, _jsxRuntime.jsx)(RelatedApiLink, {}), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O OAuth é uma maneira segura de autenticação que usa tokens de autorização, em vez de uma senha, para conectar o aplicativo a uma conta de usuário. O primeiro passo para permitir que os usuários", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/public-apps/overview#install-an-app",
        children: " instalem o aplicativo "
      }), "em suas contas da HubSpot é iniciar o acesso ao OAuth."]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsx)(_components.li, {
          children: "aplicativos desenvolvidos para serem instalados em várias contas da HubSpot ou para listagem no Marketplace de aplicativos devem usar OAuth."
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["os usuários que instalam aplicativos em suas contas da HubSpot devem ser ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#super-admin",
            children: "superadministradores"
          }), " ou ter permissões de ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide#settings",
            children: "Acesso ao Marketplace de aplicativos"
          }), "."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h5, {
      children: "Recursos recomendados"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["O ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/authentication/oauth-quickstart-guide",
          children: "Guia de início rápido do OAuth"
        }), " ajudará você a começar a trabalhar usando um aplicativo de exemplo de trabalho."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Este ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/l/academy/tracks/71/593/2967",
          children: "tutorial da HubSpot Academy"
        }), " fornece uma rápida introdução sobre como usar o OAuth com o HubSpot. Ele detalha o fluxo do HubSpot-OAuth e mostra como atualizar um token de acesso."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Iniciando uma integração com OAuth 2.0"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Para iniciar uma integração com OAuth 2.0:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Primeiro,", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/apps/public-apps/overview",
          children: " crie um aplicativo"
        }), " em uma ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://app.hubspot.com/signup/developers/",
          children: "conta de desenvolvedor da HubSpot"
        }), ". Depois de criar o aplicativo, você poderá encontrar o ID do cliente do aplicativo e o segredo do cliente na página ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Autenticação"
        }), " das configurações do aplicativo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://cdn2.hubspot.net/hubfs/53/MyHubSpotApp.png",
        alt: "MyHubSpotApp"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Use o ID e o segredo do cliente, juntamente com os ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#query-parameters",
            children: "parâmetros de consulta"
          }), " e os ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "#scopes",
            children: "escopos"
          }), " descritos abaixo, para criar sua URL de autorização."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Direcione os usuários que estão instalando o aplicativo para a URL de autorização. Será exibida uma tela em que eles poderão selecionar uma conta e conceder acesso à integração. Você pode definir um URL de autorização específico para a conta da HubSpot adicionando o ID da conta entre ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/oauth/"
          }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "/authorize"
          }), ", como mostrado abaixo. Depois que o acesso for concedido, eles serão redirecionados ao aplicativo por meio de um ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "redirect_url"
          }), ", que terá um parâmetro de consulta de código vinculado a ele. Você usará esse código e o segredo do cliente para obter um ", (0, _jsxRuntime.jsx)(_components.a, {
            href: "/guides/api/oauth/tokens",
            children: " access_token e um refresh_token"
          }), " da HubSpot."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
          children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Exemplo de URLs de autorização"
            }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
              children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Qualquer conta:"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "https://app.hubspot.com/oauth/authorize?client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&scope=contacts%20automation&redirect_uri=https://www.example.com/"
                })]
              }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
                children: [(0, _jsxRuntime.jsx)(_components.strong, {
                  children: "Conta específica (ID 123456):"
                }), " ", (0, _jsxRuntime.jsx)(_components.code, {
                  children: "https://app.hubspot.com/oauth/123456/authorize?client_id=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx&scope=contacts%20automation&redirect_uri=https://www.example.com/"
                })]
              }), "\n"]
            }), "\n"]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Exemplo de URL de redirecionamento:"
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://example.com/?code=xxxx"
            })]
          }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: "Exemplo de erro:"
            }), (0, _jsxRuntime.jsx)(_components.code, {
              children: "https://www.example.com/?error=error_code&error_description=Human%20readable%20description%20of%20the%20error"
            })]
          }), "\n"]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Use ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), " para autenticar quaisquer chamadas API feitas para essa conta da HubSpot."]
        }), "\n"]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.p, {
          children: ["Uma vez que ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), " expirar, use ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "refresh_token"
          }), " para gerar um novo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "access_token"
          }), "."]
        }), "\n"]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(Alert, {
      type: "warning",
      children: [(0, _jsxRuntime.jsx)(_components.p, {
        children: (0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação:"
        })
      }), (0, _jsxRuntime.jsxs)(_components.ul, {
        children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["o aplicativo não aparecerá como um ", (0, _jsxRuntime.jsx)(_components.em, {
            children: "Aplicativo conectado"
          }), " na conta de um usuário, a menos que você gere o token de atualização e o token de acesso inicial."]
        }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
          children: ["os tokens de acesso refletem os escopos solicitados ao aplicativo e ", (0, _jsxRuntime.jsx)("u", {
            children: "não "
          }), "refletem as permissões ou limitações do que um usuário pode fazer em sua conta da HubSpot. Por exemplo, se um usuário tiver permissões para visualizar apenas contatos de propriedade, mas autorizar uma solicitação para o escopo ", (0, _jsxRuntime.jsx)(_components.code, {
            children: "crm.objects.contacts.read"
          }), ", o token de acesso resultante poderá visualizar todos os contatos na conta e não apenas aqueles de propriedade do usuário que está autorizando."]
        }), "\n"]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Parâmetros de consulta"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Estes são os parâmetros obrigatórios quando se cria uma URL de autorização para o aplicativo:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Parâmetro"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Descrição"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Como usar"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "ID do cliente"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "client_id=x"
            }), "Usado no URL"]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obtenha esse parâmetro na página de configurações de Autenticação do aplicativo (conforme descrito acima)."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "URL de redirecionamento"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_uri=x"
            }), "O URL para o qual os visitantes serão redirecionados depois que receberem acesso ao aplicativo."]
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Você também atribuirá esse parâmetro na página de configurações de Autenticação do aplicativo.", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Observação:"
            }), " Por motivos de segurança, esse URL ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "deve"
            }), " usar https na produção. (Ao testar o uso de localhost, o httpp pode ser usado.) Você também ", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "deve"
            }), " usar um domínio, pois endereços IP não são aceitos."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Escopo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "scope=x%20x"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um conjunto de permissões separadas por espaço para o qual o seu aplicativo precisa ter acesso. Qualquer escopo que você tenha marcado nas configurações de Autenticação do aplicativo será considerado obrigatório e precisará ser incluído neste parâmetro; caso contrário, a página de autorização exibirá um erro. Além disso, os usuários receberão um erro se tentarem instalar o aplicativo em uma conta que não tenha acesso a um escopo incluído. Consulte a tabela Escopos abaixo para obter mais detalhes sobre quais pontos de extremidade podem ser acessados por escopos específicos."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Os parâmetros a seguir são opcionais:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Parâmetro"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Como usar"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Descrição"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Escopos opcionais"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "&optional_scope=x%20x"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um conjunto de permissões opcionais separadas por espaço para o seu aplicativo. Os escopos opcionais serão retirados automaticamente da solicitação de autorização se o usuário selecionar uma conta da HubSpot que não tenha acesso a essa ferramenta (como solicitar o escopo social em um portal somente CRM). Se estiver usando escopos opcionais, você precisará verificar o token de acesso ou o de atualização para ver quais foram concedidos. Veja a tabela abaixo para obter mais detalhes sobre os escopos."
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Estado"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.code, {
              children: "&state=y"
            }), "Se esse parâmetro estiver incluído no URL de autorização, o valor será incluído em um parâmetro de consulta de estado quando o usuário for direcionado para ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "redirect_url"
            }), "."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Um valor de string que pode ser usado para manter o estado do usuário quando ele é redirecionado ao aplicativo."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Configurar escopos"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "O OAuth exige que você defina escopos, ou permissões, para o aplicativo. Cada escopo fornece acesso a um conjunto de pontos de extremidade da API do HubSpot e permite que os usuários concedam a seu aplicativo acesso a ferramentas específicas em suas contas da HubSpot."
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.img, {
        src: "https://www.hubspot.com/hubfs/Knowledge_Base_2021/Developer/granular-scopes-selection.png",
        alt: "granular-scopes-selection"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O acesso a APIs ou a pontos de extremidade depende da camada de conta da HubSpot. Veja na tabela abaixo uma lista completa dos escopos e pontos de extremidade acessíveis. Se seu aplicativo permitir o uso de vários tipos de contas da HubSpot, você pode usar o parâmetro ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "optional_scope"
      }), " para incluir quaisquer escopos em camadas que você usa. Dessa forma, os clientes que usarem contas apenas CRM poderão continuar a autorizar seu aplicativo, mesmo que não consigam acessar todos os escopos dele. O aplicativo deve verificar e lidar com quaisquer escopos para o qual não seja autorizado."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Escopo"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Descrição"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Oferece acesso a"
            })
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Categoria de conta necessária"
            })
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os integradores podem listar domínios do CMS na conta de um cliente."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API do CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.domains.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os integradores podem criar, atualizar e excluir domínios personalizados do CMS."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API do CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os integradores podem visualizar todas as funções sem servidor do CMS, quaisquer segredos relacionados e os resultados de execução das funções."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API do CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Enterprise"]
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.functions.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os integradores podem gravar funções e segredos sem servidor no CMS."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API do CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre os artigos de conhecimento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API do CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Concede acesso para atualizar artigos de conhecimento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API do CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.articles.publish"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Concede acesso para atualizar e publicar artigos de conhecimento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API do CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Vejas as configurações gerais e de modelo da central de conhecimento, como o domínio ou o URL raiz."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API do CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.knowledge_base.settings.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Concede acesso para atualizar as configurações gerais e de modelo da central de conhecimento. Isso inclui o acesso para escrever artigos da central de conhecimento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Api do CMS"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "Service Hub"
              }), " Professional"]
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "cms.performance.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Os integradores podem visualizar os dados de desempenho do CMS para todos os seus sites."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API do CMS"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre listas de contato"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de listas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.lists.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Criar, excluir ou fazer alterações nas listas de contatos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Listar pontos de extremidade"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja propriedades e outros detalhes sobre as empresas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de empresas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exiba as propriedades e crie, exclua ou altere as empresas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de empresas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja as propriedades e outros detalhes sobre os contatos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de contatos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Visualize propriedades e crie, exclua e faça alterações nos contatos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de contatos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre os objetos personalizados no CRM da HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de objetos personalizados"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Qualquer ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.custom.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crie, exclua ou faça alterações em objetos personalizados no CRM da HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de objetos personalizados"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Qualquer ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja propriedades e outros detalhes sobre negócios."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de negócios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exiba as propriedades e crie, exclua ou altere os negócios."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de negócios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.feedback_submission.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre os envios para qualquer uma de suas pesquisas de feedback."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de pesquisa de comentários"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "Service Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.goals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja todos os tipos de meta."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de metas"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Sales Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter"
            }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exibir propriedades e outros detalhes sobre itens de linha"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de itens de linha"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.line_items.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crie, exclua ou faça alterações no itens de linha."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de itens de linha"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre os eventos de marketing."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de eventos de marketing"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.marketing_events.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crie, exclua ou faça alterações em eventos de marketing."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de eventos de marketing"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.owners.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exiba detalhes sobre os usuários atribuídos a um registro de CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de proprietários"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja propriedades e outros detalhes sobre orçamentos e modelos de orçamento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de orçamentos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.objects.quotes.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crie, exclua ou faça alterações nos orçamentos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de orçamentos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre as configurações de propriedades para empresas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de propriedades"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.companies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crie, exclua ou faça alterações nas configurações de propriedades das empresas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de propriedades"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre as configurações de propriedade dos contatos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de propriedades"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.contacts.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crie, exclua ou altere as configurações de propriedade dos contatos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de propriedades"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.custom.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre as definições de objetos personalizados no CRM da HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de objetos personalizados"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Qualquer ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre as configurações de propriedade dos negócios."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de propriedades"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.deals.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crie, exclua ou altere as configurações de propriedades dos negócios."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de propriedades"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.line_items.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre itens de linha."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de itens de linha"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.schemas.quotes.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre orçamentos e modelos de orçamento."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de orçamentos"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.billing.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Faça alterações nas configurações de cobrança da sua conta. Isso inclui gerenciar e atribuir licenças pagas aos usuários."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de configurações"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Lê as taxas de câmbio existentes, juntamente com a moeda atual da empresa associada ao seu portal."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de informações da conta"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.currencies.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crie, atualize e exclua taxas de câmbio, juntamente com a atualização da moeda da empresa associada ao seu portal."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de informações da conta"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre os usuários da conta e suas permissões."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de provisionamento de usuários"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Gerencie usuários e permissões de usuários na sua conta da HubSpot. Isso inclui a criação de novos usuários, a atribuição de permissões e funções e a exclusão de usuários existentes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de provisionamento de usuários"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.teams.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre as equipes da conta."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de provisionamento de usuários"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "settings.users.team.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Atribua usuários a equipes em sua conta da HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de provisionamento de usuários"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "account-info.security.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclui acesso a registros de atividade da conta e outras informações de segurança da conta."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de atividade da conta"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "accounting"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permite que a HubSpot e a integração contábil compartilhem detalhes de faturas, produtos e contatos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de extensão de contabilidade"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "actions"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Adicione formulários às páginas do contato que realizam ações personalizadas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de extensões do CRM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer contato"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "analytics.behavioral_events.send"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclui acesso para enviar eventos comportamentais personalizados."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de análises"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "automation"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Isso inclui fluxos de trabalho."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de automação (Pontos de extremidade de fluxos de trabalho)"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "behavioral_events.event_definitions.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Crie, leia, atualize ou exclua eventos comportamentais. Inclui propriedades de eventos comportamentais."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de análises"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business_units.view.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja dados da unidade de negócios, incluindo as informações de logotipo."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de unidades de negócios"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Complemento de Unidades de negócios"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "business-intelligence"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclui pontos de extremidade que estão na parte superior de origens e e-mails."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de análise"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_query.execute"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Consulte dados da sua conta da HubSpot usando o ponto de extremidade da API do GraphQL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Ponto de extremidade da API do GraphQL"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.strong, {
              children: (0, _jsxRuntime.jsx)(_components.em, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "collector.graphql_schema.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Realize consultas de introspecção por meio de clientes de aplicativos GraphQL, como GraphiQL"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "GraphiQL e outros clientes GraphQL de terceiros"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsxs)(_components.em, {
              children: [(0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              }), " Professional"]
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes das preferências de assinatura de seus contatos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de preferências de assinatura"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.read_write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Assine/cancele a assinatura de contatos para os seus tipos de assinatura. Isso não inscreverá contatos que cancelaram a assinatura."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de preferências de assinatura"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "communication_preferences.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Assine/cancele a assinatura de contatos para os seus tipos de assinatura. Isso não inscreverá contatos que cancelaram a assinatura."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de preferências de assinatura"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "content"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclui sites, landing pages, e-mail, blog e campanhas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API do CMS e pontos de extremidade de calendário, e-mail e eventos de e-mail"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise,"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes sobre os threads na caixa de entrada de conversas."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de mensagens e caixa de entrada de Conversas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.visitor_identification.tokens.create"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: " Extraia os tokens de identificação para os visitantes do site autenticados que estão interagindo com o widget de chat da HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de identificação do visitante"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Qualquer ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "conversations.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Envie mensagens em conversas. Crie e atualize threads de mensagens."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de mensagens e caixa de entrada de conversas"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.export"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exporte registos do seu CRM para todos os tipos de dados de CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de exportações do CRM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "crm.import"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permite importar registros para o CRM. Inclui a criação de novos registros ou a modificação de qualquer um de seus registros existentes para todos os tipos de dados de CRM (contatos, empresas, negócios, tickets etc). Não inclui o arquivamento nem a exclusão de dados."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de importações do CRM"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "ctas.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Permite acesso de leitura para CTAs."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Nenhuma publicAPI disponível"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Starter"
            }), ", ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "e-commerce"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclui acesso aos recursos de e-commerce."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de produtos e itens de linha"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "external_integrations.forms.access"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclui a capacidade de renomear, excluir e clonar formulários existentes."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de formulários"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Isso inclui acesso ao Gerenciador de arquivos."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Arquivos (Gerenciador de arquivos) e pontos de extremidade do mapeador de arquivos (layout, módulos e modelos de CMS)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "files.ui_hidden.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Veja detalhes ou baixe arquivos de usuário, anexos e arquivos de sistema de todas as ferramentas do HubSpot."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Arquivos (Gerenciador de arquivos) e pontos de extremidade do mapeador de arquivos (modelos, módulos e layout CMS)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclui acesso aos pontos de extremidade de formulários."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de provisionamento de formulários"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "forms-uploaded-files"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Baixe arquivos enviados por meio de um formulário."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Obter um arquivo carregado por meio de um ponto de extremidade de envio de formulário"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "hubdb"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Isso inclui acesso ao HubDB."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade HubDB"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "CMS Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            }), " com o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/website",
              children: "Complemento de site"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "integration-sync"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Exibe a API de sincronização, que permite sincronizar a maioria dos objetos do CRM."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de ponte de comércio eletrônico"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Concede acesso a eventos e objetos do media bridge."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de Media Bridge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "media_bridge.write"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Concede acesso para criar e atualizar eventos e objetos do media bridge."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de Media Bridge"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "oauth"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Escopo básico obrigatório para OAuth. Este escopo é adicionado por padrão a todos os aplicativos."
          }), (0, _jsxRuntime.jsx)(_components.td, {}), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "sales-email-read"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Concede acesso para ler todos os detalhes de e-mails individuais enviados aos contatos."
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["Pontos de extremidade de engajamentos", (0, _jsxRuntime.jsx)(_components.strong, {
              children: "Observação:"
            }), " este escopo é necessário para obter o conteúdo dos engajamentos de e-mail. Consulte a ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "/guides/api/crm/engagements/engagement-details#email-content",
              children: "Visão geral de Engajamentos"
            }), " para obter mais informações."]
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "social"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclui a caixa de entrada social."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "API de mídia social"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: [(0, _jsxRuntime.jsx)(_components.em, {
              children: (0, _jsxRuntime.jsx)(_components.strong, {
                children: "Marketing Hub"
              })
            }), " ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Professional"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
              children: "Enterprise"
            })]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "tickets"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclui acesso a tickets."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de tickets"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "timeline"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Concede acesso para gerenciar eventos personalizados em registros do HubSpot CRM. Inclui a criação ou a atualização de registros."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de eventos da linha do tempo"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Qualquer conta"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "transactional-email"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Inclui e-mails transacionais e pontos de extremidade de e-mails transacionais."
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "Pontos de extremidade de e-mails transacionais"
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["**Marketing Hub** Professional ou Enterprise com o ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "https://www.hubspot.com/products/email/transactional-email",
              children: "Complemento de e-mail transacional"
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Documentos relacionados"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/intro-to-auth",
        children: "Métodos de Autenticação no HubSpot"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/apps/authentication/oauth-quickstart-guide",
        children: "Guia de início rápido do OAuth"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/oauth/tokens",
        children: "Gerenciando tokens"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}