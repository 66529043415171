"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toc = exports.title = exports.slug = exports.pageId = exports.name = exports.metaDescription = exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var _react = require("@mdx-js/react");
/*@jsxRuntime automatic @jsxImportSource react*/

const pageId = exports.pageId = 51261306631;
const slug = exports.slug = 'guides/api/crm/associations';
const title = exports.title = 'API do CRM |  Associações v4';
const name = exports.name = 'vNext DP de documentos - Associações v4';
const metaDescription = exports.metaDescription = 'Os pontos de extremidade de Associações do CRM são usados para gerenciar associações entre tickets, produtos, itens de linha e seus contatos, empresas e negócios relacionados.';
const toc = exports.toc = [{
  "depth": 0,
  "id": "tipos-de-associa%C3%A7%C3%A3o-definidos-pelo-hubspot",
  "label": "Tipos de associação definidos pelo HubSpot",
  "parent": null
}, {
  "depth": 0,
  "id": "r%C3%B3tulos-%C3%BAnicos-versus-pares-de-r%C3%B3tulos",
  "label": "Rótulos únicos versus pares de rótulos",
  "parent": null
}, {
  "depth": 0,
  "id": "criar-tipos-de-associa%C3%A7%C3%A3o",
  "label": "Criar tipos de associação",
  "parent": null
}, {
  "depth": 0,
  "id": "recuperar-tipos-de-associa%C3%A7%C3%A3o",
  "label": "Recuperar tipos de associação",
  "parent": null
}, {
  "depth": 0,
  "id": "associar-registros",
  "label": "Associar registros",
  "parent": null
}, {
  "depth": 1,
  "id": "associar-registros-sem-um-r%C3%B3tulo",
  "label": "Associar registros sem um rótulo",
  "parent": "associar-registros"
}, {
  "depth": 1,
  "id": "associar-registros-com-um-r%C3%B3tulo",
  "label": "Associar registros com um rótulo",
  "parent": "associar-registros"
}, {
  "depth": 0,
  "id": "definir-e-gerenciar-limites-de-associa%C3%A7%C3%A3o",
  "label": "Definir e gerenciar limites de associação",
  "parent": null
}, {
  "depth": 1,
  "id": "criar-ou-atualizar-limites-de-associa%C3%A7%C3%A3o",
  "label": "Criar ou atualizar limites de associação",
  "parent": "definir-e-gerenciar-limites-de-associa%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "recuperar-limites-de-associa%C3%A7%C3%A3o",
  "label": "Recuperar limites de associação",
  "parent": "definir-e-gerenciar-limites-de-associa%C3%A7%C3%A3o"
}, {
  "depth": 1,
  "id": "excluir-limites-de-associa%C3%A7%C3%A3o",
  "label": "Excluir limites de associação",
  "parent": "definir-e-gerenciar-limites-de-associa%C3%A7%C3%A3o"
}, {
  "depth": 0,
  "id": "gerar-relat%C3%B3rio-sobre-alto-uso-de-associa%C3%A7%C3%B5es",
  "label": "Gerar relatório sobre alto uso de associações",
  "parent": null
}, {
  "depth": 0,
  "id": "valores-de-id-de-tipo-de-associa%C3%A7%C3%A3o",
  "label": "Valores de ID de tipo de associação",
  "parent": null
}, {
  "depth": 0,
  "id": "associa%C3%A7%C3%B5es-v1-antigas",
  "label": "Associações v1 (antigas)",
  "parent": null
}];
function _createMdxContent(props) {
  const _components = Object.assign({
      h1: "h1",
      p: "p",
      a: "a",
      strong: "strong",
      h2: "h2",
      ul: "ul",
      li: "li",
      code: "code",
      em: "em",
      pre: "pre",
      h3: "h3",
      table: "table",
      thead: "thead",
      tr: "tr",
      th: "th",
      tbody: "tbody",
      td: "td",
      h4: "h4"
    }, (0, _react.useMDXComponents)(), props.components),
    {
      DndSection,
      DndModule,
      RelatedApiLink,
      Alert
    } = _components;
  if (!Alert) _missingMdxReference("Alert", true);
  if (!DndModule) _missingMdxReference("DndModule", true);
  if (!DndSection) _missingMdxReference("DndSection", true);
  if (!RelatedApiLink) _missingMdxReference("RelatedApiLink", true);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(DndModule, {
        numCols: 10,
        children: (0, _jsxRuntime.jsxs)("div", {
          children: [(0, _jsxRuntime.jsx)(_components.h1, {
            children: "Associações v4"
          }), (0, _jsxRuntime.jsx)(RelatedApiLink, {})]
        })
      }), (0, _jsxRuntime.jsx)(DndModule, {
        numCols: 2
      })]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: ["Para obter a versão anterior, consulte a documentação da ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/reference/api/crm/associations/association-details/v3",
          children: "API de Associações v3"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "As associações representam as relações entre objetos e atividades no CRM da HubSpot. Podem existir associações entre registros de diferentes objetos (por exemplo, contato para empresa), bem como dentro do mesmo objeto (por exemplo, empresa para empresa). Você pode usar os pontos de extremidade de associações para criar, recuperar, atualizar ou excluir associações entre registros ou entre registros e atividades."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Os pontos de extremidade do esquema de associação permitem que você exiba os tipos suportados de associações em sua conta, bem como crie seus próprios tipos de associação e atribua rótulos às suas relações de registros. Os rótulos de associação são aceitos entre contatos, empresas, negócios, tickets e objetos personalizados, e podem ser ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels#use-association-labels-in-hubspot-tools",
        children: "usados nas ferramentas do HubSpot"
      }), ", como listas e fluxos de trabalho."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Saiba mais sobre objetos, registros, propriedades e APIs de associações no guia ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/crm/understanding-the-crm",
        children: "Noções básicas do CRM"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": a API de associações v4 é compatível com a versão 9.0.0 ou posterior do NodeJS HubSpot Client."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Tipos de associação definidos pelo HubSpot"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["O HubSpot fornece um conjunto de tipos de associação predefinidos (por exemplo, contato sem rótulo com empresa), mas os administradores da conta podem ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "definir seus próprios rótulos de associação"
      }), " para fornecer contexto adicional para relacionamentos de registros (por exemplo, gerente e funcionário). Há dois tipos de associação definidos pelo HubSpot:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Principal:"
        }), " a empresa principal à qual o outro registro está associado. Associações principais podem ser usadas nas ferramentas da HubSpot, como ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/associations-enhancements-beta#use-associations-in-hubspot-tools",
          children: "listas e fluxos de trabalho"
        }), ". Para registros com várias empresas associadas, esta API aceita alterar qual empresa é considerada a principal."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Sem rótulo:"
        }), " um tipo de associação adicionado quando um registro de contato, empresa, negociação, ticket ou objeto personalizado é associado. Este tipo indica que existe uma associação e sempre será retornado em respostas com um valor de ", (0, _jsxRuntime.jsx)(_components.strong, {
          children: "rótulo"
        }), " ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), ". Quando um registro tem uma associação principal ou um rótulo de associação personalizado, esses tipos serão listados ao lado do tipo de associação sem rótulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode exibir todos os tipos de associação definidos pelo HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#association-type-id-values",
        children: "nesta seção"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Rótulos únicos versus pares de rótulos"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Há dois tipos de ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "rótulos de associação"
      }), " que você pode usar para descrever os relacionamentos entre os registros:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Único:"
        }), " um rótulo que se aplica a ambos os registros no relacionamento. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Amigo"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Colega"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Emparelhados"
        }), ": um par de rótulos para quando palavras diferentes são usadas para descrever cada lado do relacionamento dos registros associados. Por exemplo, ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Principal"
        }), " e ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Secundário"
        }), " ou ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Empregador"
        }), " e ", (0, _jsxRuntime.jsx)(_components.em, {
          children: "Funcionário"
        }), ". Para criar rótulos emparelhados, você deve incluir o campo ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "inverseLabel"
        }), " na sua solicitação para nomear o segundo rótulo no par."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Criar tipos de associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode criar tipos de associação personalizados ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
        children: "no HubSpot"
      }), " ou por meio do ponto de extremidade da API do esquema de associação. Você pode criar até 10 tipos de associação entre cada par de objetos (por exemplo, contatos e empresas, contatos e contatos)."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para criar um tipo de associação por meio da API, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
      }), " e inclua o seguinte em sua solicitação:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "nome"
        }), ": o nome interno do tipo de associação. Esse valor ", (0, _jsxRuntime.jsx)("u", {
          children: "não"
        }), " pode incluir hifens ou começar com um caractere numérico."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "rótulo"
        }), ": o nome do ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://knowledge.hubspot.com/crm-setup/create-and-use-association-labels",
          children: "rótulo de associação conforme mostrado no HubSpot"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "inverseLabel"
        }), " (somente rótulos emparelhados): o nome do segundo rótulo no par de rótulos."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, sua solicitação pode ser semelhante a esta:"
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "///Example request body - Single label\n{\n  \"label\": \"Partner\",\n  \"name\": \"partner\"\n}\n"
        })
      }), (0, _jsxRuntime.jsx)(_components.pre, {
        children: (0, _jsxRuntime.jsx)(_components.code, {
          className: "language-json",
          children: "///Example request body - Paired labels\n{\n  \"label\": \"Manager\",\n  \"inverseLabel\": \"Employee\",\n  \"name\": \"manager_employee\"\n}\n"
        })
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Recuperar tipos de associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para exibir os tipos de associação entre objetos específicos, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/{fromObjectType}/{toObjectType}/labels"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você receberá uma matriz, sendo que cada item conterá:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "categoria:"
        }), " se o tipo de associação foi criado pelo HubSpot (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "HUBSPOT_DEFINED"
        }), ") ou por um usuário (", (0, _jsxRuntime.jsx)(_components.code, {
          children: "USER_DEFINED"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "typeId:"
        }), " o ID numérico para esse tipo de associação. Isso é usado para ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#associate-with-label",
          children: "definir um rótulo ao associar registros"
        }), ". Consulte ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "#association-type-id-values",
          children: "esta lista"
        }), " para ver todos os valores ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "typeId"
        }), " definidos pelo HubSpot."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "rótulo:"
        }), " o rótulo alfanumérico. Isso será ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "null"
        }), " para o tipo de associação sem rótulo."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você também pode encontrar esses valores no HubSpot ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/create-and-use-association-labels#association-label-api-details",
        children: "em suas configurações de associação"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associar registros"
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associar registros sem um rótulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode criar uma associação padrão sem rótulo entre dois registros ou configurar associações sem rótulo para registros em massa. Para configurar uma associação padrão individual entre dois registros, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/{fromObjectType}/{fromObjectId}/associations/default/{toObjectType}/{toObjectId}"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "No URL da solicitação, inclua:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectType"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " o ID do objeto que você está associando. Para localizar os valores de ID, consulte esta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "lista de IDs de tipo de objeto,"
        }), " ou, para contatos, empresas, negócios, tickets e observações, você pode usar o nome do objeto (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "fromObjectId"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " o ID do registro a associar."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectType"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " o ID do objeto ao qual você está associando o registro. Para localizar os valores de ID, consulte esta ", (0, _jsxRuntime.jsx)(_components.a, {
          href: "/guides/api/crm/understanding-the-crm#object-type-id",
          children: "lista de IDs de tipo de objeto,"
        }), " ou, para contatos, empresas, negócios, tickets e observações, você pode usar o nome do objeto (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "contact"
        }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "company"
        }), ")."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: [(0, _jsxRuntime.jsx)(_components.code, {
          children: "toObjectId"
        }), (0, _jsxRuntime.jsx)(_components.strong, {
          children: ":"
        }), " o ID do registro ao qual associar."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, para associar um registro de contato cuja ID é 12345 a um registro de empresa cuja ID é ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "67891"
      }), ", o URL da solicitação seria: ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/contact/12345/associations/default/company/67891"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para configurar associações padrão em massa, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v4/associations/{fromObjectType}/{toObjectType}/batch/associate/default"
      }), ". No corpo da solicitação, inclua valores de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "objectId"
      }), " para os registros que você deseja associar."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": o número de associações que um registro pode ter depende do", (0, _jsxRuntime.jsx)(_components.a, {
          href: "https://legal.hubspot.com/hubspot-product-and-services-catalog#TechnicalLimits:~:text=CRM%20Record%20Association%20Limits",
          children: " objeto e da sua assinatura do HubSpot"
        }), "."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Associar registros com um rótulo"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para associar dois registros e definir um rótulo para descrever a associação, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/{objectType}/{objectId}/associations/{toObjectType}/{toObjectId}"
      }), ". No corpo da solicitação, inclua ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " para indicar o tipo de associação que você deseja criar."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se você estiver criando associações sem rótulo, poderá usar os pontos de extremidade padrão descritos na ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#associate-records-without-a-label",
        children: "seção acima"
      }), " que não exijam ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationCategory"
      }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), ". Se você estiver criando associações com um rótulo, poderá consultar esta ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#association-type-id-values",
        children: "lista de IDs de tipo padrão"
      }), ", ou precisará recuperar os tipos de associação personalizados entre esses objetos."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "info",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": para relações entre objetos e rótulos emparelhados, certifique-se de usar o ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "typeId"
        }), " que se refere à direção correta (por exemplo, Contato para Empresa vs. Empresa para Contato, Funcionário para Gerente vs. Gerente para Funcionário)."]
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Por exemplo, para associar um contato a um negócio usando um rótulo personalizado:"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["1. Faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "GET"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/contact/deal/labels"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["2. Na resposta, observe os valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), " para o rótulo. O ID será um número (por exemplo, ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "36"
      }), ") e a categoria será sempre ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "USER_DEFINED"
      }), " para rótulos personalizados."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["3. Envie uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "PUT"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/objects/contact/{objectId}/associations/deal/{toObjectId}"
      }), " com o seguinte corpo de solicitação:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "/// Example request body\n[\n  {\n    \"associationCategory\": \"USER_DEFINED\",\n    \"associationTypeId\": 36\n  }\n]\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Definir e gerenciar limites de associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Você pode configurar ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/set-limits-for-record-associations",
        children: "limites"
      }), " para o número de registros associados entre objetos ou a frequência com que um rótulo pode ser usado para descrever associações. Há também ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "limites técnicos e limites baseados na sua assinatura do HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Criar ou atualizar limites de associação"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Você pode criar limites de associação novos ou atualizar os limites de associação existentes entre objetos."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para criar limites, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/create"
        }), "."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para atualizar os limites existentes, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "POST"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/update"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["No corpo da solicitação, inclua ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "inputs"
      }), " com o seguinte:"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Descrição"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "Parâmetro"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "category"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["A categoria da associação para a qual você está definindo um limite ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "HUBSPOT_DEFINED"
            }), " ou ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "USER_DEFINED"
            }), "."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "typeId"
            })
          }), (0, _jsxRuntime.jsxs)(_components.td, {
            children: ["O ID numérico do tipo de associação para o qual você deseja definir um limite. Consulte ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#association-type-id-values",
              children: "esta lista"
            }), " de valores ", (0, _jsxRuntime.jsx)(_components.code, {
              children: "typeId"
            }), " padrão ou ", (0, _jsxRuntime.jsx)(_components.a, {
              href: "#retrieve-association-types",
              children: "recupere o valor"
            }), " para rótulos personalizados."]
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: (0, _jsxRuntime.jsx)(_components.code, {
              children: "maxToObjectIds"
            })
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "O número máximo de associações permitidas para o tipo de associação."
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, para definir limites em que um negócio pode ser associado a um máximo de cinco contatos com apenas um contato rotulado como ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ponto de contato"
      }), ", sua solicitação se pareceria com a seguinte:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request POST crm/v4/associations/definitions/configurations/deal/contact/batch/create\n{\n  \"inputs\": [\n    {\n      \"category\": \"HUBSPOT_DEFINED\",\n      \"typeId\": 3,\n      \"maxToObjectIds\": 5\n    },\n    {\n      \"category\": \"USER_DEFINED\",\n      \"typeId\": 35,\n      \"maxToObjectIds\": 1\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Recuperar limites de associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.ul, {
      children: ["\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para ler todos os limites de associação definidos, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/definitions/configurations/all"
        }), ". Isso retornará limites de associação personalizados definidos em todos os objetos."]
      }), "\n", (0, _jsxRuntime.jsxs)(_components.li, {
        children: ["Para ler os limites de associação entre dois objetos específicos, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "GET"
        }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
          children: "/crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}"
        }), "."]
      }), "\n"]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para ambas as solicitações, a resposta retornará os valores das associações para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), ", ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "maxToObjectIds"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "label"
      }), ". Por exemplo, ao recuperar limites entre negócios e contatos, a resposta seria semelhante à seguinte:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example response GET crm/v4/associations/definitions/configurations/deal/contact\n{\n  \"results\": [\n    {\n      \"category\": \"HUBSPOT_DEFINED\",\n      \"typeId\": 3,\n      \"userEnforcedMaxToObjectIds\": 5,\n      \"label\": null\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsx)(_components.h3, {
      children: "Excluir limites de associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para excluir limites de associação específicos, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "/crm/v4/associations/definitions/configurations/{fromObjectType}/{toObjectType}/batch/purge"
      }), ". No corpo da solicitação, inclua os valores de ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "category"
      }), " e ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), " dos tipos de associação para os quais você deseja remover os limites."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Por exemplo, para remover o limite de ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Pontos de contato"
      }), " entre negócios e contatos, a solicitação seria semelhante à seguinte:"]
    }), "\n", (0, _jsxRuntime.jsx)(_components.pre, {
      children: (0, _jsxRuntime.jsx)(_components.code, {
        className: "language-json",
        children: "//Example request POST crm/v4/associations/definitions/configurations/deal/contact/batch/purge\n{\n  \"inputs\": [\n    {\n      \"category\": \"USER_DEFINED\",\n      \"typeId\": 35\n    }\n  ]\n}\n"
      })
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Se for bem-sucedido, você receberá uma resposta 204 e o limite incluído retornará ao padrão do sistema (ou seja, muitos contatos podem ter o rótulo ", (0, _jsxRuntime.jsx)(_components.em, {
        children: "Ponto de contact"
      }), ")."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Gerar relatório sobre alto uso de associações"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Há ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://legal.hubspot.com/hubspot-product-and-services-catalog",
        children: "limites técnicos para o número de associações que um registro pode ter"
      }), ". Você pode usar a API de associações para recuperar um relatório de registros que estão se aproximando ou atingiram o limite máximo de associações."]
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["Para recuperar o relatório, faça uma solicitação ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "POST"
      }), " para ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "crm/v4/associations/usage/high-usage-report/{userID}"
      }), ". O arquivo inclui registros que usam 80% ou mais de seu limite de associação. Por exemplo, se uma empresa puder ser associada a até 50.000 contatos, ela será incluída no arquivo se tiver 40.000 contatos associados ou mais. O arquivo será enviado para o e-mail do usuário cujo ID foi incluído no URL da solicitação. Saiba como recuperar IDs de usuário com a ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "/guides/api/settings/users/user-provisioning",
        children: "API de usuários"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Valores de ID de tipo de associação"
    }), "\n", (0, _jsxRuntime.jsxs)(_components.p, {
      children: ["As tabelas a seguir incluem os valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "associationTypeId"
      }), " definidos pelo HubSpot que especificam o tipo de associação. Os tipos de associação variam dependendo dos objetos incluídos e da direção da associação (por exemplo, Contato para Empresa é diferente de Empresa para Contato). Se você criar objetos personalizados ou rótulos de associação personalizados, os tipos de associação relacionados terão valores ", (0, _jsxRuntime.jsx)(_components.code, {
        children: "typeId"
      }), " que precisarão ser ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "#retrieve-association-type",
        children: "recuperados"
      }), " ou localizados nas ", (0, _jsxRuntime.jsx)(_components.a, {
        href: "https://knowledge.hubspot.com/object-settings/create-and-use-association-labels#association-label-api-details",
        children: "configurações de associação no HubSpot"
      }), "."]
    }), "\n", (0, _jsxRuntime.jsx)(Alert, {
      type: "warning",
      children: (0, _jsxRuntime.jsxs)(_components.p, {
        children: [(0, _jsxRuntime.jsx)(_components.strong, {
          children: "Observação"
        }), ": os tipos de associação de empresa padrão incluem um tipo de associação sem rótulo e um tipo de associação principal. Se um registro tiver mais de uma empresa associada, somente uma poderá ser a empresa principal. As outras associações podem não ter rótulo ou ter rótulos de associação personalizados."]
      })
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Contato para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "449"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "279"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "1"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para empresa (Principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "4"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "15"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "193"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para chamada"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "197"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para e-mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "199"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para reunião"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "201"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para observação"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "203"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para tarefa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "82"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para comunicação (SMS, WhatsApp ou mensagem do LinkedIn)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "454"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para correio postal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "587"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para carrinho"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "508"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "178"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para fatura"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "388"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para pagamento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "296"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Contato para assinatura"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Empresa para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "450"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "14"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa afiliada para empresa matriz"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "13"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa matriz para afiliada"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "280"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "2"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para contato (Principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "342"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "6"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para negócio (Principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "340"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "25"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para ticket (Principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "181"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para chamada"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "185"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para e-mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "187"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para reunião"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "189"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para observação"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "191"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para tarefa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "88"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para comunicação (SMS, WhatsApp ou mensagem do LinkedIn)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "460"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para correio postal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "180"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para fatura"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "510"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "390"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para pagamento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "298"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para assinatura"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Negócio para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "451"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "3"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "341"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "5"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para empresa (Principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "27"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócios para ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "205"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para chamada"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "209"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para e-mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "211"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para reunião"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "213"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para observação"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "215"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para tarefa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "86"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para comunicação (SMS, WhatsApp ou mensagem do LinkedIn)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "458"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para correio postal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "313"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para divisão de negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "19"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para item de linha"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "176"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para fatura"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "511"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "392"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para pagamento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "630"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para produto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "63"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para orçamento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "300"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Negócio para assinatura"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Ticket para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "452"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "16"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "339"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Empresa para ticket"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "26"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para empresa (Principal)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "28"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "219"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para chamada"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "223"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para e-mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "225"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para reunião"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "227"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para observação"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "229"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para tarefa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "84"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para comunicação (SMS, WhatsApp ou mensagem do LinkedIn)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "456"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para correio postal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "32"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para thread"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "278"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para conversa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "526"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Ticket para pedido"
              })]
            })]
          })]
        }), (0, _jsxRuntime.jsx)(_components.h3, {
          children: "Lead para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "578"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead para contato principal"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "596"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead para chamada"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "598"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead para e-mail"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "600"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead para reunião"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "602"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead para comunicação"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "608"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "610"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "646"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Lead para tarefa"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Chamada para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "194"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Chamada para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "182"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Chamada para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "206"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Chamada para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "220"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Chamada para ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "E-mail para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "198"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "E-mail para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "186"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "E-mail para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "210"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "E-mail para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "224"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "E-mail para ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Reunião para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "200"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Reunião para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "188"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Reunião para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "212"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Reunião para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "226"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Reunião para ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Observação para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "202"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Observação para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "190"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Observação para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "214"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Observação para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "228"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Observação para ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Correio postal para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "453"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Correio postal para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "459"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Correio postal para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "457"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Correio postal para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "455"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Correio postal para ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Orçamento para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "69"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "71"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "64"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "67"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para item de linha"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "286"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para modelo de orçamento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "362"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para desconto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "364"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para taxa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "366"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para imposto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "702"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Signatário do contato (para assinaturas eletrônicas)"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "733"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para carrinho"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "408"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para fatura"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "731"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para pedido"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "398"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para pagamento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "304"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Orçamento para assinatura"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Tarefa para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "204"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Tarefa para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "192"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Tarefa para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "216"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Tarefa para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "230"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Tarefa para ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h4, {
          children: "Comunicação (SMS, WhatsApp ou mensagem do LinkedIn) para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "81"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Comunicação (SMS, WhatsApp ou mensagem do LinkedIn) para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "87"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Comunicação (SMS, WhatsApp ou mensagem do LinkedIn) para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "85"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Comunicação (SMS, WhatsApp ou mensagem do LinkedIn) para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "83"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Comunicação (SMS, WhatsApp ou mensagem do LinkedIn) para ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsxs)(DndSection, {
      children: [(0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Pedido para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "593"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para carrinho"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "507"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "509"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para empresa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "512"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para negócio"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "519"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para desconto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "521"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para código de desconto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "518"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para fatura"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "513"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para item de linha"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "523"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para pagamento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "730"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para orçamento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "516"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para assinatura"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "726"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para tarefa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "525"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Pedido para ticket"
              })]
            })]
          })]
        })]
      }), (0, _jsxRuntime.jsxs)(DndModule, {
        numCols: 6,
        children: [(0, _jsxRuntime.jsx)(_components.h3, {
          children: "Carrinho para objeto"
        }), (0, _jsxRuntime.jsxs)(_components.table, {
          children: [(0, _jsxRuntime.jsx)(_components.thead, {
            children: (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.th, {
                children: "ID DE TIPO"
              }), (0, _jsxRuntime.jsx)(_components.th, {
                children: "Tipo de associação"
              })]
            })
          }), (0, _jsxRuntime.jsxs)(_components.tbody, {
            children: [(0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "586"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrinho para contato"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "588"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrinho para desconto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "590"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrinho para item de linha"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "592"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrinho para objeto"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "732"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrinho para orçamento"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "728"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrinho para tarefa"
              })]
            }), (0, _jsxRuntime.jsxs)(_components.tr, {
              children: [(0, _jsxRuntime.jsx)(_components.td, {
                children: (0, _jsxRuntime.jsx)(_components.code, {
                  children: "594"
                })
              }), (0, _jsxRuntime.jsx)(_components.td, {
                children: "Carrinho para ticket"
              })]
            })]
          })]
        })]
      })]
    }), "\n", (0, _jsxRuntime.jsx)(_components.h2, {
      children: "Associações v1 (antigas)"
    }), "\n", (0, _jsxRuntime.jsx)(_components.p, {
      children: "Se você estiver usando a API de associações v1, exiba a tabela abaixo para obter informações sobre IDs a serem usadas ao associar registros."
    }), "\n", (0, _jsxRuntime.jsxs)(_components.table, {
      children: [(0, _jsxRuntime.jsx)(_components.thead, {
        children: (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.th, {
            children: "Tipo de associação"
          }), (0, _jsxRuntime.jsx)(_components.th, {
            children: "ID"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_components.tbody, {
        children: [(0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Contato para empresa"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "1"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa para contato (padrão)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "2"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa para contato (todos os rótulos)"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "280"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Negócio para contato"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "3"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Contato para negócio"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "4"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Negócio para empresa"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "5"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa para negócio"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "6"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa para engajamento"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "7"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Engajamento para empresa"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "8"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Contato para engajamento"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "9"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Engajamento para contato"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "10"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Negócio para engajamento"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "11"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Engajamento para negócio"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "12"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa matriz para empresa afiliada"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "13"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa afiliada para empresa matriz"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "14"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Contato para ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "15"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket para contato"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "16"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket para engajamento"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "17"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Engajamento para ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "18"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Negócio para item de linha"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "19"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Item de linha para negócio"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "20"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Empresa para ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "25"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket para empresa"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "26"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Negócio para ticket"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "27"
          })]
        }), (0, _jsxRuntime.jsxs)(_components.tr, {
          children: [(0, _jsxRuntime.jsx)(_components.td, {
            children: "Ticket para negócio"
          }), (0, _jsxRuntime.jsx)(_components.td, {
            children: "28"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {
    wrapper: MDXLayout
  } = Object.assign({}, (0, _react.useMDXComponents)(), props.components);
  return MDXLayout ? (0, _jsxRuntime.jsx)(MDXLayout, Object.assign({}, props, {
    children: (0, _jsxRuntime.jsx)(_createMdxContent, props)
  })) : _createMdxContent(props);
}
var _default = exports.default = MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}